import FunnelColumn from './FunnelColumn';
import GridCardBase from '../../../app/grid/GridCardBase';
import { Skeleton } from 'antd';
import FunnelUserCard from './FunnelUserCard';
import FunnelSourcingCard from './FunnelSourcingCard';

const FunnelSourcingColumn = (
	{
		title,
		details,
		filterOptions,
		cardOptions,
		style = {},
	},
) => {
	const className = 'FunnelSourcingColumn';

	const skeletonCard = (
		<GridCardBase style={{ padding: 30, height: 80, borderRadius: 5 }}>
			<Skeleton active style={{ width: '100%', height: 80, borderRadius: 5 }} />
		</GridCardBase>
	);

	return (
		<FunnelColumn
			title={title}
			details={details}
			cardRender={(row, isLoading) => (
				<FunnelSourcingCard
					sourcing={row}
					isLoading={row}
					withCard={true}
					{...cardOptions || {}}
				/>)
			}
			skeletonCard={skeletonCard}
			filterOptions={filterOptions}
			style={style}
		/>
	);
};

export default FunnelSourcingColumn;
