import CompanySettingsFormBase from './CompanySettingsFormBase';
import { useState } from 'react';
import FormBoxTitle from '../../app/form/FormBoxTitle';
import FormItem from '../form/FormItem';
import { Form } from 'antd';
import FormSubmitButton from '../../app/form/FormSubmitButton';
import BlockStaff from '../../app/blocker/BlockStaff';
import FieldItemMetas from '../../seo/FieldItemMetas';
import { ENGLISH_LANG } from '../../../constants/constant';
import { useTheme } from '@emotion/react';
import SelectLocalContent from '../../staff/content/articles/components/SelectLocalContent';
import CompanyModel from '../../../models/CompanyModel';

// TODO: move to styles file
const buttonClasses = (theme) => ({
	button: {
		'margin': 5,
		'background': theme.color.white,
		'borderColor': theme.color.blue,
		'color': theme.color.blue,
		'fontWeight': 'bold',
		'borderRadius': 5,
		':hover': {
			background: theme.color.white,
			borderColor: theme.color.blue,
			color: theme.color.blue,
			opacity: 0.7,
		},
	},
	activeButton: {
		'margin': 5,
		'background': `${theme.color.blue}!important`,
		'borderColor': `${theme.color.blue}!important`,
		'color': `${theme.color.white}!important`,
		':hover': {
			background: `${theme.color.blue}!important`,
			borderColor: `${theme.color.blue}!important`,
			color: `${theme.color.white}!important`,
			opacity: 0.7,
		},
	},
	previewButton: {
		'backgroundColor': theme.color.accent,
		'borderColor': theme.color.accent,
		'color': theme.color.white,
		'textTransform': 'uppercase',
		'fontSize': 18,
		'display': 'flex',
		'alignItems': 'center',
		'justifyContent': 'center',
		'margin': 'auto',
		'gap': 5,
		'borderRadius': '25px',
		'padding': '20px',
		':hover': {
			backgroundColor: theme.color.accent,
			borderColor: theme.color.accent,
			color: theme.color.white,
			opacity: 0.7,
		},
	},
	updateButton: {
		'color': theme.color.blue,
		'textTransform': 'uppercase',
		'fontSize': 18,
		'display': 'flex',
		'alignItems': 'center',
		'justifyContent': 'center',
		'margin': 'auto',
		'gap': 5,
		'borderRadius': '25px',
		'padding': '20px',
		'borderColor': theme.color.white,
		':hover': {
			color: theme.color.blue,
			borderColor: theme.color.white,
			opacity: 0.7,
		},
	},
});

const CompanySettingsSEOForm = (
	{
		company,
		title,
		details,
		onSuccess,
		labelCol = { span: 0 },
		wrapperCol = { span: 24 },
		...props
	},
) => {
	const className = 'CompanySettingsSEOForm';

	const [form] = Form.useForm();
	const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
	const [lang, setLang] = useState(ENGLISH_LANG);

	const theme = useTheme();
	const styles = buttonClasses(theme);
	return (
		<BlockStaff
			withBorder={false}
			tagStyle={{
				top: 5,
				left: 0,
				opacity: 1,
			}}
		>
			<FormBoxTitle
				title={title}
				details={details}
			/>
			<CompanySettingsFormBase
				form={form}
				formName='company-settings-seo-form'
				company={company}
				labelCol={labelCol}
				wrapperCol={wrapperCol}
				layout='horizontal'
				setIsSubmitDisabled={setIsSubmitDisabled}
				onSuccess={onSuccess}
				hiddenValues={{ forceRecompute: false }}
			>
				{/* Input fields for inserting the Meta tags */}
				<FieldItemMetas isEnVersion={lang === ENGLISH_LANG} metaHolder={new CompanyModel(company)} isTranslatable={company?.isTranslatable}/>

				{/* Language Selector */}
				{
					company?.isTranslatable
						&& <FormItem>
							<SelectLocalContent
								lang={lang}
								setLang={setLang}
								styles={styles}
							/>
						</FormItem>
				}

				{/* Submit button */}
				<FormItem>
					<FormSubmitButton disabled={isSubmitDisabled}>
							Save SEO settings
					</FormSubmitButton>
				</FormItem>

			</CompanySettingsFormBase>
		</BlockStaff>
	);
};

export default CompanySettingsSEOForm;
