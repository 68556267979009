import { useContext } from 'react';
import { Button, Popover, Space } from 'antd';
import UserAvatarList from '../widgets/UserAvatarList';
import UserButtonBadge from './UserButtonBadge';
import { documentId } from '../../../utils/common';
import { UserSourceModalContext } from '../../../contexts/modals/UserSourceModalProvider';
import GenderhireCandidatesMenu from '../../../menu/genderhire/GenderhireCandidatesMenu';
import GenderhireCandidatesSourcedMenu, { GenderhireCandidatesSourcedIcon } from '../../../menu/genderhire/GenderhireCandidatesSourcedMenu';
import GenderhireCandidatesAppliedMenu from '../../../menu/genderhire/GenderhireCandidatesAppliedMenu';
import GenderhireCandidatesArchivedMenu from '../../../menu/genderhire/GenderhireCandidatesArchivedMenu';

const UserSourcer = (
	{
		user,
		Component,
		withPrimaryStyle = true,
		popoverPlacement = 'top',
		onStart,
		onComplete,
		children,
		...props
	},
) => {
	const className = 'UserSourcer';

	const { setOpen, setUser, setOnComplete } = useContext(UserSourceModalContext);

	const sourcers = [];
	user?.sourced?.forEach((item) => {
		const { manager } = item;
		sourcers.push(...manager && !sourcers.some((s) => (documentId(s)?.toString?.()) === (documentId(manager)?.toString?.())) ? [manager] : []);
	});

	const archivers = [];
	user?.archived?.forEach((item) => {
		const { manager } = item;
		archivers.push(...manager && !archivers.some((s) => (documentId(s)?.toString?.()) === (documentId(manager)?.toString?.())) ? [manager] : []);
	});

	const sourcingsCount = (
		(user?.sourcedCount || 0)
		+ (user?.applicationsCount || 0)
		+ (user?.archivedCount || 0)
	) || 0;

	const handleClick = async (e) => {
		e?.stopPropagation();
		setOpen(true);
		setUser(user);
		setOnComplete(() => async () => onComplete?.());
		await props?.onClick?.();
	};

	const button = Component
		? (
			<Component
				{...props}
				onClick={handleClick}
				style={{ ...props?.style?.button }}
			>
				{children}
			</Component>
		)
		: (
			<Button
				{...user?.messagesToCount > 0 && !(sourcingsCount > 0) && withPrimaryStyle && { type: 'primary' }}
				onClick={handleClick}
				icon={<GenderhireCandidatesSourcedIcon />}
				size={props?.size || 10}
				style={{ ...props?.style?.button }}
			>
			&nbsp;{props?.title || 'Source'}
			</Button>
		);

	const candidatesMenu = new GenderhireCandidatesMenu();
	const candidatesSourcedMenu = new GenderhireCandidatesSourcedMenu();
	const candidatesAppliedMenu = new GenderhireCandidatesAppliedMenu();
	const candidatesArchivedMenu = new GenderhireCandidatesArchivedMenu();

	return (
		<div
			style={{
				textAlign: 'left',
			}}
		>
			{sourcingsCount
				? (
					<Popover
						title={<>{candidatesMenu.icon} {candidatesMenu.label}</>}
						placement={popoverPlacement}
						content={(
							<Space direction='vertical' size={4}>
								{user?.sourcedCount > 0 && (
									<div style={{ minHeight: 28 }}>
										<strong>{candidatesSourcedMenu.icon} Added </strong>
										&nbsp;{user?.sourcedCount} time{user?.sourcedCount > 1 ? 's' : ''}
										{sourcers?.length > 0 && (
											<>
												&nbsp;by&nbsp;
												<UserAvatarList
													users={sourcers}
													size={28}
													overflow={-9}
													withCurrentUser={true}
												/>
											</>
										)}
									</div>
								)}
								{user?.applicationsCount > 0 && (
									<div
										style={{
											minHeight: 28,
										}}
									>
										<strong>{candidatesAppliedMenu.icon} Applied</strong>
										&nbsp;{user?.applicationsCount} time{user?.applicationsCount > 1 ? 's' : ''}
									</div>
								)}
								{user?.archivedCount > 0 && (
									<div style={{
										minHeight: 28,
										opacity: 0.7,
									}}>
										<strong>{candidatesArchivedMenu.icon} Archived</strong>
										&nbsp;{user?.archivedCount} time{user?.archivedCount > 1 ? 's' : ''}
										{archivers?.length > 0 && (
											<>
												&nbsp;by&nbsp;
												<UserAvatarList
													users={archivers}
													size={28}
													overflow={-9}
													withCurrentUser={true}
												/>
											</>
										)}
									</div>
								)}
							</Space>
						)}
					>
						<UserButtonBadge
							count={sourcingsCount}
							style={{ ...props?.style?.badge }}
						>
							{button}
						</UserButtonBadge>
					</Popover>
				)
				: button
			}
		</div>
	);
};

export default UserSourcer;
