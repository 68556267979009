import { GENDERSCORE_PILLAR_VALUES, getPillarLabel } from '../../../../constants/genderscore-pillars';
import GenderScoreLayoutBox from '../layout/GenderScoreLayoutBox';
import { GENDERSCORE_LABEL } from '../../../../constants/constant';
import DiffDisplay from '../DiffDisplay';

const GenderScorePillarPerformanceBox = ({
	company,
	forceValue,
	pillar = 'globalScore',
	...props
}) => {
	const className = 'GenderScorePillarPerformanceBox';

	const genderScore = forceValue || company?.genderScore;

	const isPillar = GENDERSCORE_PILLAR_VALUES.includes(pillar);

	const pillarLabel = isPillar
		? <><strong>{getPillarLabel(pillar)}</strong> pillar</>
		: <strong>{GENDERSCORE_LABEL}</strong>;

	const pillarScore = genderScore?.[pillar];
	const pillarAvgScore = genderScore?.currentStatistics?.averages?.[pillar] || 0;
	const avgDiff = pillarScore - pillarAvgScore;
	const avgDiffAbs = Math.abs(avgDiff);
	const avgDiffNorm = avgDiff / (Math.abs(avgDiff) || 1);

	const avgDiffSentence = [
		`underperform by ${avgDiffAbs} points`,
		'are on par with',
		`overperform by ${avgDiffAbs} points`,
	][avgDiffNorm + 1];

	return (
		<GenderScoreLayoutBox
			title={<>Your Performance</>}
			style={{
				...props?.style,
			}}
		>

			<DiffDisplay
				previous={pillarAvgScore}
				current={pillarScore}
				style={{
					fontSize: 38,
					marginTop: -10,
					marginBottom: -10,
				}}
			/>

			<p
				style={{
					opacity: 0.5,
					fontSize: 12,
					marginBottom: 0,
				}}
			>
				You <strong>{avgDiffSentence}</strong> the
				average {pillarLabel}{isPillar ? ' score' : ''} of <strong>{pillarAvgScore}</strong>
			</p>

		</GenderScoreLayoutBox>
	);
};

export default GenderScorePillarPerformanceBox;
