import { useTheme } from '@emotion/react';
import { FaStar } from 'react-icons/fa';

const GenderScoreResource50Banner = ({
	...props
}) => {
	const className = 'GenderScoreResource50Banner';

	const theme = useTheme();

	return (
		<div
			style={{
				padding: '2px 5px',
				background: theme.color.fitBlueElectric,
				borderRadius: 3,
				color: 'white',
				fontSize: 12,
				fontWeight: 'bold',
				boxShadow: '0px 5px 20px -8px rgba(0,0,0,.3)',
				...props?.style,
			}}
		>
			<FaStar style={{ marginBottom: -1 }}/> by 50inTech
		</div>
	);
};

export default GenderScoreResource50Banner;
