import { Space, Typography } from 'antd';
import { EnvironmentOutlined } from '@ant-design/icons';
import { useTheme } from '@emotion/react';
import JobLinkDropdown from './JobLinkDropdown';
import AtsLogo from '../ats/AtsLogo';
import CompanyCaption from '../company/CompanyCaption';

const JobStub = (
	{
		job,
		withCompany = true,
		withLocation = true,
		withDropdown = true,
		withEditLink = false,
		withOpenLink = false,
		atsLogoPlacement = 'right',
		locationDisplay = 'wrap',
		jobActiveTab,
		onEditJob,
		onSelectJob,
		...props
	},
) => {
	const theme = useTheme();

	if (!job) {
		return null;
	}

	const component = job
		? <Space direction="vertical" size={2} {...props}>
			{withCompany && job.company && (
				<CompanyCaption
					company={job.company}
					style={{ ...props?.style?.company }}
				/>
			)}
			<Space direction='horizontal'>
				{job?.atsSync?.atsProvider
					&& atsLogoPlacement === 'left'
					&& (
						<AtsLogo
							ats={job?.atsSync?.atsProvider}
							size={16}
						/>
					)}
				<Typography
					style={{
						color: theme.color.black,
						lineHeight: 1.2,
						fontWeight: 'bold',
						fontSize: '14px',
						whiteSpace: 'normal',
						...props?.style?.title,
					}}
				>
					{job.title}
					{withLocation && job.location && locationDisplay === 'inline' && <span style={{
						fontWeight: 300,
					}}>&nbsp;({job.location})</span>
					}
				</Typography>
				{job?.atsSync?.atsProvider
					&& atsLogoPlacement === 'right'
					&& (
						<AtsLogo ats={job?.atsSync?.atsProvider} size={16} />
					)
				}
			</Space>
			{withLocation && job.location && locationDisplay === 'wrap' && <Typography
				style={{
					lineHeight: 1.2,
					color: theme.color.gray,
					fontSize: '12px',
					...props?.style?.location,
				}}
			>
				<EnvironmentOutlined /> {job.location}
			</Typography>
			}
		</Space>
		: null;

	return (withDropdown
		? <JobLinkDropdown
			job={job}
			jobActiveTab={jobActiveTab}
			onEditJob={onEditJob}
			onSelectJob={onSelectJob}
			withEditLink={withEditLink}
			withOpenLink={withOpenLink}
		>
			{component}
		</JobLinkDropdown>
		: component
	);
};

export default JobStub;
