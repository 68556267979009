import { documentId } from '../utils/common';
import { request } from './api';

const findAll = async (params, subPath = '') => {
	const dataParams = {
		params,
	};
	return request.get(`/acl${subPath}`, dataParams);
};

const create = (data) => {
	return request.post('/acl', data);
};

const update = (sourcingId, data) => {
	return request.put(`/acl/${sourcingId}`, data);
};

const remove = (acl) => {
	const dataParams = {
		data: acl,
	};
	return request.delete(`/acl/${documentId(acl)}`, dataParams);
};

const aclExists = (roleId, options = {}) => {
	const dataParams = {
		params: options,
	};
	return request.get(`/acl/${roleId}/exists`, dataParams);
};

const sendInvitedUser = (data) => {
	return request.post('/acl/invited/user', data);
};

const resendInvitation = (acl) => {
	return request.put('/acl/invited/user', acl);
};

const AclService = {
	findAll,
	create,
	update,
	remove,
	aclExists,
	sendInvitedUser,
	resendInvitation,
};

export default AclService;
