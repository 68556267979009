/* eslint-disable react-hooks/exhaustive-deps */
import chalk from 'chalk';
import { useEffect, useRef, useState } from 'react';
import { message, Table as AntTable, Button } from 'antd';
import AppService from '../../../services/app';
import FilterDropDownColumn from './FilterDropdownColumn';
import FilterDropDownSelect from './FilterDropdownSelect';
import TableHeader from './TableHeader';
import { useDebouncedEffect } from '../../../hooks/useDebounceEffect';
import { orderBy } from 'lodash';
import {
	documentId,
	hashValue, lastChars, sortOn, writeLog,
} from '../../../utils/common';
import { LocalDebug } from '../../../utils/LocalDebug';
import EmptyData from '../EmptyData';
import { writeRecruiterLog } from '../../../actions/logging';
import { COMPANY_RECRUITER_LOG } from '../../../constants/company-recruiter-log';

const Table = (
	{
		columns = [],
		ItemClass,
		withDynamicSearch,
		withSort,
		sortOptions,
		sortStyle,
		withRefresh = true,
		isRefresh = 0,
		path = '',
		delay = 1000,
		bordered = false,
		loadOnInit = true,
		paginationTitle = 'Items',
		withPagination = true,
		mandatoryFilters = [],
		onDataLoaded,
		onSearchDataColumn,
		defaultDataSource,
		withLoading = false,

		isTotalLimitEnabled = false,
		withTableHeader = true,
		filterOptions = {},
		defaultColumnsFilterOptions,

		columnsDisplayOptions = {},
		defaultColumnsDisplayOptions,

		limit = 10,
		totalLimit,
		expandable,
		rowClassName,
		withClientSidePagination = false,
		data,
		showSizeChanger = true,
		showTotal = true,
		empty,
		rowType = 'row',
		...props
	},
) => {
	const className = 'Table';

	const [dataSource, setDataSource] = useState(defaultDataSource || []);
	const [dataTotal, setDataTotal] = useState(null);
	const [pagination, setPagination] = useState({
		current: 1,
		pageSize: limit,
		position: ['bottomCenter', 'bottomCenter'],
	});
	const [isLoading, setIsLoading] = useState(false);
	const [searchColumn, setSearchColumn] = useState({});
	const [search, setSearch] = useState(null);
	// default dynamic filters column selected
	const [defaultFilterOptions, setDefaultFilterOptions] = useState({});
	const [columnsFilterOptions, setColumnsFilterOptions] = useState([]);
	const [defaultColumnsFilterSelected, setDefaultColumnsFilterSelected] = useState([]);
	const [defaultColumnsDisplaySelected, setDefaultColumnsDisplaySelected] = useState([]);
	// const [lastFetchedFilters, setLastFetchedFilters] = useState(JSON.stringify({}));
	const [bypassFetchedFilterCheck, setBypassFetchedFilterCheck] = useState(false);

	const lastFetchedFilters = JSON.stringify({});

	useEffect(() => {
		const customColumns = columns
			.filter((c) => (typeof c.title === 'string' || c.title instanceof String)
				&& c.title.toUpperCase() !== 'ACTIONS'
				&& c.title !== ''
				&& !c?.removeInColumnFilter)
			.map((c) => (c?.customOptions?.searchFields?.length > 0
				? c.customOptions.searchFields
				: { label: c.title, value: c?.customOptions?.searchField || c.dataIndex })).flat();

		// LocalDebug.logUseEffect({ className, effects: 'columns, defaultColumnsFilterOptions' }, { customColumns });
		// columns
		setColumnsFilterOptions(customColumns);

		// defaultSelected
		setDefaultColumnsFilterSelected(defaultColumnsFilterOptions || customColumns.map((c) => c.value));
	}, [columns, defaultColumnsFilterOptions]);

	// set loading
	useEffect(() => {
		setIsLoading(withLoading);
	}, [withLoading]);

	useEffect(() => {
		const customColumns = columns
			.filter((c) => (typeof c.title === 'string' || c.title instanceof String) && c.title.toUpperCase() !== 'ACTIONS' && c.title !== '' && !c?.removeInColumnFilter)
			.map((c) => ({ label: c.title, value: c?.customOptions?.searchField || c.dataIndex }));
		// defaultSelected
		// LocalDebug.logUseEffect({ className, effects: 'columns, defaultColumnsDisplayOptions' }, { defaultColumnsDisplayOptions });
		setDefaultColumnsDisplaySelected(defaultColumnsDisplayOptions || customColumns.map((c) => c.value));
	}, [columns, defaultColumnsDisplayOptions]);

	useEffect(() => {
		// LocalDebug.logUseEffect({ className, effects: 'defaultColumnsDisplaySelected' }, { defaultColumnsDisplaySelected });
	}, [defaultColumnsDisplaySelected]);

	const prepareDataSourceItems = (items) => items
		?.map((item) => (ItemClass ? new ItemClass(item) : item));

	// ----------------------------------------------------- //
	// --------------- dropDownFilter props ---------------- //
	// ----------------------------------------------------- //
	const getFilterDropdownProps = (column) => ({
		...(column.filters
			? {
				filterDropdown: ({
					setSelectedKeys, selectedKeys, confirm, clearFilters,
				}) => (
					<FilterDropDownSelect
						placeholder={`Search ${column.dataIndex}`}
						values={selectedKeys}
						onChange={(values) => {
							setSelectedKeys(values);
							LocalDebug.logInfo({
								className, method: 'FilterDropDownSelect.onChange',
							}, { setSelectedKeys });
						}}
						onFilter={() => handleFilter(selectedKeys, confirm, column.dataIndex)}
						onReset={() => handleResetFilter(clearFilters)}
						filters={column.filters}
					/>
				),
			}
			: {
				filterDropdown: ({
					setSelectedKeys, selectedKeys, confirm, clearFilters,
				}) => (
					<FilterDropDownColumn
						placeholder={`Search ${column.dataIndex}`}
						value={selectedKeys[0]}
						onChange={(value) => setSelectedKeys([value])}
						onFilter={() => handleFilter(selectedKeys, confirm, column?.customOptions?.searchField || column.dataIndex)}
						onReset={() => handleResetFilter(clearFilters)}
						onSelect={(value) => handleFilter([value], confirm, column?.customOptions?.searchField || column.dataIndex)}
						column={column}
						onSearchDataColumn={onSearchDataColumn}
					/>
				),
			}
		),
	});

	// -------------------------------------------- //
	// --------------- add Filter column ---------- //
	// -------------------------------------------- //
	const columnsWithFilter = columns
		.filter((c) => c)
		.map((column) => {
			const { isFilter, ...otherColumnProps } = column;
			return {
				...otherColumnProps,
				...(isFilter && getFilterDropdownProps(column)),
			};
		});

	// ------------------------------------------ //
	// ------------ handle pagination ----------- //
	// ------------------------------------------ //
	const handleTableChange = (pagination, filters, sorter) => {
		// LocalDebug.logInfo({ className, method: 'handleTableChange' }, { pagination, filters, sorter, withClientSidePagination });
		// sorting
		if (withClientSidePagination) {
			const { current, pageSize } = pagination;
			// setDataSource((prev) => orderBy(prev, [sorter?.field], [sorter?.order || 'desc']));
			const newDataSource = orderBy(defaultDataSource, [sorter?.field], [sorter?.order || 'desc'])
				.slice((current - 1) * pageSize, current * pageSize);
			setDataSource(prepareDataSourceItems(newDataSource));
			setDataTotal(defaultDataSource.length);
			setPagination({ current, pageSize, total: defaultDataSource.length });
			return;
		}

		fetchData({
			sortField: sorter.field,
			sortOrder: sorter.order,
			limit: pagination.pageSize,
			skip: (pagination.current - 1) * pagination.pageSize,
			...filters,
		});

		setDefaultFilterOptions((prev) => ({
			...prev,
			sortField: sorter.field,
			sortOrder: sorter.order,
			...filters,
		}));
	};

	const lastFetchedFiltersRef = useRef();
	const lastHash = useRef();

	const sortObj = (o) => Object.entries(o)
		.map(([key, value]) => ({ key, value }))
		.sort(sortOn({ key: 'key' }))
		.reduce((p, c) => ({ ...p, [c.key]: c.value }), {});

	// -------------------------------------------- //
	// -------------- retrieve data --------------- //
	// -------------------------------------------- //
	const fetchData = async (params = {}) => {
		const method = 'fetchData';
		// LocalDebug.logInfo({ className, method }, JSON.stringify(params), { params });
		if (!path || path === '') return;
		const jsonSortedParams = JSON.stringify(sortObj(params));

		if (bypassFetchedFilterCheck) {
			// LocalDebug.logInfo({ className, method }, 'continuing (bypass filters check)' );
		} else {
			const isSameFilters = jsonSortedParams === lastFetchedFiltersRef.current;
			// LocalDebug.logInfo({ className, method }, {
			//     isSameFilters,
			//     params: jsonSortedParams,
			//     lastFetchedFiltersRef: lastFetchedFiltersRef.current,
			//     bypassFetchedFilterCheck
			// });

			if (isSameFilters) {
				LocalDebug.logInfo({ className, method, styles: [chalk.red] }, 'stopping (same filters)');
				return;
			}
			// LocalDebug.logInfo({ className, method }, 'continuing (different filters)');
		}

		lastFetchedFiltersRef.current = jsonSortedParams;
		setBypassFetchedFilterCheck(false);

		// LocalDebug.logInfo({ className, method }, 'continuing');
		const mandatoryParamsCheck = checkMandatoryParams();
		if (mandatoryParamsCheck) return;
		// LocalDebug.logInfo({ className, method }, 'continuing (mandatory params check)');
		// when client side pagination
		if (withClientSidePagination) return;

		// add search
		// LocalDebug.logInfo({ className, method }, { searchColumn, defaultFilterOptions, filterOptions, params });
		const paramsOptions = {
			...searchColumn, ...defaultFilterOptions, ...params, ...filterOptions,
		};

		const hash = await hashValue(paramsOptions);
		lastHash.current = hash;

		LocalDebug.logAPICall({ className, method }, { paramsOptions, lastHash: lastChars(lastHash.current) });

		setIsLoading(true);
		try {
			const response = await AppService.getData(path, paramsOptions);
			const {
				data: {
					count, items, isHardCount, ...fieldsGroup
				},
			} = response
			|| {
				data: {
					count: 0,
					items: [],
					isHardCount: false,
				},
			};
			if (lastHash.current === hash) {
				LocalDebug.logAPIResult({ className, method }, {
					count, lastHash: lastChars(lastHash.current), hash: lastChars(hash), test: lastHash.current === hash,
				});
				lastHash.current = null;
				writeLog('fetch data table', { path, params: paramsOptions });
				writeRecruiterLog(COMPANY_RECRUITER_LOG.API_RESULT, {}, { params, count, isHardCount });
				setDataSource(prepareDataSourceItems(items));
				setDataTotal(count);
				setPagination({ current: (params.skip / params.limit) + 1, pageSize: params.limit, total: count });
				setIsLoading(false);
				onDataLoaded?.(response?.data);
			} else {
				LocalDebug.logAPIResult({
					className, method: `${method} (SKIPPED, hash not matching)`, styles: [chalk.red],
				}, {
					count, lastHash: lastChars(lastHash.current), hash: lastChars(hash), test: lastHash.current === hash,
				});
			}
		} catch (error) {
			// remove loading when error isn't cancelrequest
			if (!error?.message?.includes('canceled due to new request')) {
				setIsLoading(false);
			}
			LocalDebug.logError({
				className, method, __filename, error,
			});
		}
	};

	const checkMandatoryParams = () => {
		const filterKeys = Object.keys(filterOptions);
		const test = mandatoryFilters.filter((param) => !filterKeys.includes(param));
		// LocalDebug.logInfo({ className, method: 'checkMandatoryParams' }, { mandatoryFilters, filterKeys, test });
		return !!test.length;
	};

	// -------------------------------------------------------- //
	// --------------------- init data ------------------------ //
	// -------------------------------------------------------- //

	useEffect(() => {
		// LocalDebug.logUseEffect({ className, effects:'' }, { loadOnInit, filterOptions });
		lastFetchedFiltersRef.current = JSON.stringify({});
		if (loadOnInit) {
			fetchData({
				limit: pagination.pageSize, skip: (pagination.current - 1) * pagination.pageSize, ...filterOptions,
			});
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}
	}, []);

	useEffect(() => {
		if (!filterOptions) return;
		// LocalDebug.logUseEffect({ className, effects: 'filterOptions' }, { filterOptions });
		// if (Object.keys(filterOptions || {}).length !== 0) {
		fetchData({
			limit: pagination.pageSize,
			skip: 0, // (pagination.current - 1) * pagination.pageSize,
			// skip: (pagination.current - 1) * pagination.pageSize,
			...filterOptions,
		});
		// }
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [filterOptions]);

	useEffect(() => {
		if (!(isRefresh > 0)) return;
		// LocalDebug.logUseEffect({ className, effects: 'isRefresh' }, { isRefresh });
		lastFetchedFiltersRef.current = JSON.stringify({ isRefresh });
		fetchData({ limit: pagination.pageSize, skip: (pagination.current - 1) * pagination.pageSize });
	}, [isRefresh]);

	// set dataSource
	useEffect(() => {
		// LocalDebug.logUseEffect({ className, effects: 'data' }, { data });
		if (data) {
			setDataSource(prepareDataSourceItems(data));
		}
	}, [data]);

	// update default dataSource

	useEffect(() => {
		setDataSource(prepareDataSourceItems(defaultDataSource));
	}, [defaultDataSource]);

	useEffect(() => {
		// LocalDebug.logUseEffect({ className, effects: 'dataSource' }, { dataSource });
		if (!path || path === '') {
			setDataTotal(dataSource?.length || 0);
		}
	}, [dataSource]);

	const handleSortChange = (value) => {
		LocalDebug.logInfo({ className, method: 'handleSortChange' }, { value });
		const params = JSON.parse(value);
		fetchData({
			skip: 0,
			limit: pagination.pageSize,
			...params,
			...defaultFilterOptions,
		});
	};

	// ---------------------------------------------------- //
	// ---------------- handle filter --------------------- //
	// ---------------------------------------------------- //
	const handleFilter = (selectedKeys, confirm, dataIndex) => {
		LocalDebug.logInfo({ className, method: 'handleFilter' }, { selectedKeys, confirm, dataIndex });
		fetchData({
			skip: 0,
			limit: pagination.pageSize,
			search: selectedKeys,
			searchField: dataIndex,
			...defaultFilterOptions,
		});
		setSearchColumn({
			search: selectedKeys,
			searchField: dataIndex,
		});
	};

	// -------------------------------------------------- //
	// ---------------- reset filter -------------------- //
	// -------------------------------------------------- //
	const handleResetFilter = (clearFilters) => {
		LocalDebug.logInfo({ className, method: 'handleResetFilter' });
		setSearchColumn({});
		fetchData({ limit: pagination.pageSize, skip: 0, search: '' });
	};

	// ------------------------------------------ //
	// ------ handle search Dynamic ------------- //
	// ------------------------------------------ //
	useDebouncedEffect(async () => {
		// LocalDebug.logUseEffect({ className, effects: `search(debounced by ${delay}ms)` }, { search });
		let dynamicSearchObj = {};
		if (search && search !== '') {
			if (!defaultColumnsFilterSelected.length) {
				message.error('Please select a column');
				return;
			}
			dynamicSearchObj = {
				dynamicSearch: {
					text: search,
					fields: defaultColumnsFilterSelected,
				},
			};
		}

		setDefaultFilterOptions((prev) => {
			const { dynamicSearch, ...filterOptions } = prev;
			return { ...filterOptions, ...dynamicSearchObj };
		});

		if (search || search === '') {
			setBypassFetchedFilterCheck(true);
		}

		// await fetchData({ limit: pagination.pageSize, skip: 0, ...dynamicSearchObj, ...filterOptions });
	}, [search], delay);

	// ----------------------------------------- //
	// -------- handle click search ------------ //
	// ----------------------------------------- //
	const handleDynamicSearch = (search) => {
		if (!defaultColumnsFilterSelected.length) {
			message.error('Please select a column');
			return;
		}
		const dynamicSearch = {
			text: search,
			fields: defaultColumnsFilterSelected,
		};
		setDefaultFilterOptions((prev) => ({ ...prev, dynamicSearch }));
		fetchData({ limit: pagination.pageSize, skip: 0, dynamicSearch });
	};

	// ------------------------------------------- //
	// ----------- handle refresh data ----------- //
	// ------------------------------------------- //
	const handleRefreshData = () => {
		LocalDebug.logInfo({ className, method: 'handleRefreshData' });
		setBypassFetchedFilterCheck(true);
		// fetchData({
		//     limit: pagination.pageSize,
		//     skip: 0,
		//     ...defaultFilterOptions,
		//     ...searchColumn,
		// });
	};

	useEffect(() => {
		if (bypassFetchedFilterCheck) {
			LocalDebug.logUseEffect({ className, effects: 'bypassFetchedFilterCheck' }, { bypassFetchedFilterCheck });
			fetchData({
				limit: pagination.pageSize,
				skip: 0,
				...defaultFilterOptions,
				...searchColumn,
			});
		}
	}, [bypassFetchedFilterCheck]);
	return (
		<>
			<AntTable
				rowClassName={rowClassName}
				columns={((!isLoading && dataSource?.length === 0)) ? null : columnsWithFilter}
				rowKey={(record) => documentId(record)}
				dataSource={dataSource}
				pagination={withPagination && {
					...pagination,
					showSizeChanger,
					position: ['bottomCenter'],
				}}
				loading={isLoading}
				onChange={handleTableChange}
				expandable={expandable}
				showSorterTooltip={false}
				size="middle"
				bordered={bordered}
				scrollToFirstRowOnChange={true}
				style={{ border: '1px solid #eee', borderRadius: 5 }}
				locale={{
					emptyText: <EmptyData
						description={empty?.description}
						button={empty?.button}
						style={{ border: 0 }}
					/>,
				}}
				{...withTableHeader && {
					title: () => <TableHeader
						showTotal={showTotal}
						total={showTotal && dataTotal}
						totalLimit={totalLimit}
						rowType={rowType}
						isTotalLimitEnabled={isTotalLimitEnabled}
						onRefresh={handleRefreshData}
						onSearchChange={(input) => setSearch(input.target.value)}
						onSearch={(value) => handleDynamicSearch(value)}

						withDynamicSearch={withDynamicSearch}
						withRefresh={withRefresh}

						withSort={withSort}
						sortOptions={sortOptions}
						sortStyle={sortStyle}
						onSortChange={handleSortChange}

						columnsFilterOptions={columnsFilterOptions}
						onChangeColumnsFilterOptions={setDefaultColumnsFilterSelected}
						defaultColumnsFilterSelected={defaultColumnsFilterSelected}

						columnsDisplayOptions={columnsDisplayOptions}
						onChangeColumnsDisplayOptions={setDefaultColumnsDisplaySelected}
						defaultColumnsDisplaySelected={defaultColumnsDisplaySelected}
					/>,
				}
				}
				{...props}
			/>

		</>
	);
};

export default Table;
