import { useContext } from 'react';
import { Badge } from 'antd';
import { StaffToolsContext } from '../../contexts/StaffToolsProvider';

const UserSavedSearchBadge = (
	{
		count = 0,
		size = 'small',
		children,
		...props
	},
) => {
	const className = 'UserSavedSearchBadge';

	return (
		<Badge
			showZero={false}
			overflowCount={9999}
			size={size}
			{...props}
			count={count}
			style={{
				padding: '0 4px',
				zIndex: 2,
				fontWeight: 'bold',
				fontSize: 10,
				userSelect: 'none',
				...props?.style,
			}}
		>
			{children}
		</Badge>
	);
};

export default UserSavedSearchBadge;
