import StaffTalentDatabasePage from '../../../containers/staff/users/StaffTalentDatabasePage';
import { PERMS_GRANT_STAFF } from '../../menu.utils';
import MenuBase from '../../MenuBase';
import { TeamOutlined } from '@ant-design/icons';

export const StaffTalentDatabaseIcon = TeamOutlined;

class StaffTalentDatabaseMenu extends MenuBase {
	static props = {
		name: 'staff-users-talentdb',
		path: '/staff/users/talents',
		label: 'Talent Database',
		icon: <StaffTalentDatabaseIcon />,
		iconLegacy: '🦸‍♀️',
		Component: StaffTalentDatabasePage,
		perms: PERMS_GRANT_STAFF,
	};

	constructor() {
		super(StaffTalentDatabaseMenu.props);
	}
}

export default StaffTalentDatabaseMenu;
