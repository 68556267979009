import { Menu } from 'antd';
import { useContext, useState } from 'react';
import { useTheme } from '@emotion/react';
import { FaEye } from 'react-icons/fa';
import Link from '../../app/Link';
import { documentId } from '../../../utils/common';
import { UserModalContext } from '../../../contexts/modals/UserModalProvider';
import { writeRecruiterLog } from '../../../actions/logging';
import { COMPANY_RECRUITER_LOG } from '../../../constants/company-recruiter-log';
import { ThunderboltOutlined } from '@ant-design/icons';

const UserLinkDropdown = (
	{
		children,
		user,
		activeTab = null,
		handleQuickLook,
		onRefresh,
		...props
	},
) => {
	const theme = useTheme();

	const { setOpen, setUsers } = useContext(UserModalContext);

	const [openDrawer, setOpenDrawer] = useState(false);
	const [drawerUser, setDrawerUser] = useState(null);
	// const [container, setContainer] = useState<HTMLDivElement | null>(null);
	// const container = useRef();

	const handleClick = () => {
		if (!user) return;
		user.isViewed = true;

		setUsers([user]);
		setOpen(true);
		// localDebug('handleQuickLook2', user)

		// company recruiter log
		writeRecruiterLog(COMPANY_RECRUITER_LOG.TALENT_VIEW, { userTargetId: documentId(user) });
	};

	const handleCloseDrawer = () => {
		setOpenDrawer(false);
		setDrawerUser(null);
	};

	const dropdownMenu = (
		<Menu style={{ fontSize: 12, padding: 0, borderRadius: 0 }}>
			<Menu.Item key="edit" icon={<ThunderboltOutlined />} onClick={() => handleClick(user)}>
				Quick look
			</Menu.Item>
			<Menu.Item key="open" icon={<FaEye/>}>
				<Link to={`/user/${user?.slug}`}>Open profile</Link>
			</Menu.Item>
		</Menu>
	);

	return (
		<>
			<Link onClick={() => handleClick(user)}>
				{children}
			</Link>

		</>
	);
};

export default UserLinkDropdown;
