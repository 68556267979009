import { useTheme } from '@emotion/react';
import { Space } from 'antd';
import GenderScoreTitleBlock from './GenderScoreTitleBlock';
import GenderScoreComparisonLineBlock from './GenderScoreComparisonLineBlock';
import GenderScoreNotProvidedBlock from './GenderScoreNotProvidedBlock';

/**
 * @param {string} title - Graph title
 * @param {number} current - Current value.
 * @param {number} previous - Optional. Previous value.
 * @param {string} currentLabel - Optional. To render current value.
 * @param {number} suffix - Optional. Label of the value.
 * @param {number} [width] - Optional. Width of component. Default: 180
 * @param {number} [fontSize] - Optional. Font size of the current value. Default: 40
 * @returns
 */
const GenderScoreScalarBlock = ({
	title,
	current,
	previous,
	existsPreviousSurvey,
	currentLabel,
	suffix,
	justifyContent,
	width = 150,
	fontSize = 40,
	isPct = true,
}) => {
	const className = 'GenderScoreScalarBlock';

	const theme = useTheme();
	let content;
	if (isNaN(current)) {
		content = <>
			<GenderScoreNotProvidedBlock width={width}/>
		</>;
	} else {
		content = <>
			<div style={{
			}}>
				<span style={{
					fontSize,
					fontWeight: 700,
				}}>
					{currentLabel || current}
				</span>
				{suffix && <span style={{
					fontSize: fontSize * 0.6,
					fontWeight: 600,
				}}>
					{suffix}
				</span>}
			</div>
			<GenderScoreComparisonLineBlock
				previous={previous}
				current={current}
				isPct={isPct}
				justifyContent='start'
				diffLabel={suffix}
				existsPreviousSurvey={existsPreviousSurvey}
			/>
		</>;
	}
	return <Space
		direction='vertical'
		style={{
			width: '100%',
		}}>
		<GenderScoreTitleBlock
			title={title}
			isMainTitle={false}
		/>
		<div
			style={{
				display: 'grid',
				justifyContent,
			}}
		>
			{content}
		</div>

	</Space>;
};
export default GenderScoreScalarBlock;
