import templateService from '../services/template';
import { actionWithLoader } from './utils';
import { localDebug } from '../utils/LocalDebug';

export const createTemplate = async (data) => {
	try {
		const { data: { template } } = await templateService.create(data);
		localDebug('data', template);
	} catch (error) {
		localDebug(__filename.split('/').shift(), error);
	}
};

export const deleteTemplate = (template) => {
	return actionWithLoader(async () => {
		try {
			await templateService.remove(template);
		} catch (error) {
			localDebug(__filename.split('/').shift(), error);
		}
	});
};
