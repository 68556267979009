import { request } from './api';

const listSavedSearches = async (params = {}) => {
	return request.get('/recruiter/saved-search', { params });
};

const getMySavedSearches = async (userId, params = {}) => {
	return request.get(`/recruiter/${userId}/saved-search`, { params });
};

const createOrUpdateSavedSearch = async (data) => {
	return request.post('/recruiter/saved-search', data);
};

const cloneSavedSearch = async (id) => {
	return request.get(`/recruiter/saved-search/${id}/clone`);
};

const deleteSavedSearch = async (id) => {
	return request.delete(`/recruiter/saved-search/${id}`);
};

const discardSavedSearchAlerts = async (id) => {
	return request.delete(`/recruiter/saved-search/${id}/alerts`);
};

const RecruiterService = {
	listSavedSearches,
	getMySavedSearches,
	createOrUpdateSavedSearch,
	cloneSavedSearch,
	deleteSavedSearch,
	discardSavedSearchAlerts,
};

export default RecruiterService;
