import { getCompanySelected, getJobIds } from '../../reducers/app';
import {
	addMultipleQueryParams,
	documentId, getQueryParams, missingMandatoryFields, removeQueryParams, writeLog,
} from '../../utils/common';
import { LocalDebug } from '../../utils/LocalDebug';
import {
	JOB_FORM_MANDATORY_FIELDS,
	JOB_OFFERS_DETAILS,
	JOB_OFFERS_ICON,
	JOB_OFFERS_TITLE,
	JOB_TAG_CATEGORIES_STAFF,
} from '../../constants/constant';
import PageLayout from '../../components/app/layout/PageLayout';
import { Helmet } from 'react-helmet';
import { Space, message } from 'antd';
import JobsTable from '../../components/jobs/JobsTable';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { updateOrCreateJob } from '../../actions/jobs';
import AddButton from '../../components/app/button/AddButton';
import JobPopover from '../../components/jobs/creation/JobPopover';
import { useAuthContext } from '../../hooks/useAuthContext';
import SectionTitle from '../../components/app/SectionTitle';
import FilterButton from '../../components/app/filters/FilterButton';
import JobFilterModal from '../../components/jobs/JobFilterModal';
import HelpInfoNotifications from '../../components/app/help/HelpInfoNotifications';
import JobOpenAIPopover from '../../components/jobs/creation/JobOpenAIPopover';
import GenderhireJobsMenu from '../../menu/genderhire/GenderhireJobsMenu';

const JobsPage = () => {
	const className = 'JobsPage';
	const dispatch = useDispatch();
	// selector

	const jobIds = useSelector(getJobIds);
	const {
		isStaff, isAtsSyncable, isEditor, companyIdsUser,
	} = useAuthContext();

	const companySelected = useSelector(getCompanySelected);

	const queryParams = getQueryParams();

	// --------------------------------- //
	// ----------- state --------------- //
	// --------------------------------- //
	const [optionsFilterTable, setOptionsFilterTable] = useState({
		...jobIds ? { jobIds } : {},
		withCompanyAccess: true,
		companyId: documentId(companySelected),
	});
	const [isRefreshDataLayout, setIsRefreshDataLayout] = useState(0);

	// job creation
	const [openJobForm, setOpenJobForm] = useState(false);
	const [openJobAIForm, setOpenJobAIForm] = useState(false);
	const [initJobValues, setInitJobValues] = useState({});
	const [titleDialog, setTitleDialog] = useState('');

	useEffect(() => {
		// ----------------------------------------------------- //
		// ----- create tag filter with query params ----------- //
		// ----------------------------------------------------- //
		const tags = {};
		JOB_TAG_CATEGORIES_STAFF.forEach((element) => {
			if (queryParams[element.value]) {
				tags[element.value] = [queryParams[element.value]];
			}
		});

		setOptionsFilterTable((prev) => ({
			...prev,
			...queryParams,
			...Object.keys(tags).length > 0 && { tags },
			companyId: documentId(companySelected),
		}));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		setOptionsFilterTable((prev) => ({
			...prev,
			companyId: documentId(companySelected),
		}));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [companySelected]);

	const refreshData = () => {
		setIsRefreshDataLayout((prev) => prev + 1);
	};

	const onUpdateJob = async (dataToUpdate, job) => {
		const missingFields = missingMandatoryFields(JOB_FORM_MANDATORY_FIELDS, job);
		if (dataToUpdate.isPublished && job?.isCustomize && missingFields?.length) {
			let msgError = 'The job cannot be published, because some mandatory fields are missing.';
			msgError += ' Please, edit the job offer to publish it';
			message.error(msgError);
		} else {
			await dispatch(updateOrCreateJob(dataToUpdate));
			refreshData();
		}
	};

	const saveJobToDb = async ({ values, isDraft }) => {
		LocalDebug.logInfo({ className, method: 'saveJobToDb' }, { values });
		// const toDb = { ...values, isActive: true, companyId: documentId(company) };
		const job = await dispatch(updateOrCreateJob(values, { successMessage: `Job ${documentId(values) ? 'updated' : 'created'}` }));
		// Close the job if it's not a draft
		setOpenJobForm(isDraft);
		setInitJobValues(job);
		refreshData();
		writeLog('handle saveJobToDb', { data: values });
	};

	const handleAddJob = (job = {}) => {
		LocalDebug.logInfo({ className, method: 'handleAddJob' }, { job });
		const initFormValues = {
			...(!isStaff && { companyId: companyIdsUser[0] }),
			...job,
		};
		setInitJobValues(initFormValues);
		setOpenJobForm(true);
		setTitleDialog(documentId(job) ? 'Edit a job' : 'Add a job');
	};

	const handleJobAI = (job = {}) => {
		LocalDebug.logInfo({ className, method: 'handleJobAI' }, { job });
		const initFormValues = {
			...(!isStaff && { companyId: companyIdsUser[0] }),
			...job,
		};
		setInitJobValues(initFormValues);
		setOpenJobAIForm(true);
		setTitleDialog(documentId(job) ? 'Edit a job' : 'Add a job');
	};

	// --------------------------------------- //
	// ------------ handle filter ------------ //
	// --------------------------------------- //
	const handleFilter = (values) => {
		const { tags, ...rest } = values;
		addMultipleQueryParams(rest);
		setOptionsFilterTable((prev) => {
			return ({
				...prev,
				...rest,
				tags,
				companyId: documentId(companySelected),
			});
		});
	};

	const handleClearFilter = () => {
		setOptionsFilterTable({
			companyId: documentId(companySelected),
		});
		setIsRefreshDataLayout((prev) => prev + 1);
		removeQueryParams();
	};

	return (
		<PageLayout
			MenuClass={GenderhireJobsMenu}
			subtitle={<>
				{JOB_OFFERS_DETAILS}
				<HelpInfoNotifications style={{ marginTop: 8, label: { fontSize: 13 } }} />
			</>}
			rightActions={(
				!isAtsSyncable && (
					<AddButton
						style={{ marginTop: 1 }}
						onClick={() => handleAddJob()}
						title='Add a job offer manually'
					/>
				)
			)}
			filter={<FilterButton
				title='Filter jobs'
				modal={JobFilterModal}
				onFilter={handleFilter}
				onClearFilter={handleClearFilter}
				initialValues={optionsFilterTable}
			/>}
		>
			<Helmet>
				<meta charSet='utf-8' />
				<title>50inTech Admin - Jobs</title>
				<link rel='canonical' href='http://admin.50intech.com/jobs' />
			</Helmet>

			<JobsTable
				path='/jobs'
				filterOptions={optionsFilterTable}
				isRefresh={isRefreshDataLayout}
				isEditor={isEditor}
				onEditJob={handleAddJob}
				onEditAIJob={handleJobAI}
				withDynamicSearch
				onUpdateJob={onUpdateJob}
				defaultColumnsOptions={['title', 'location', 'department', 'team']}
			/>

			<JobPopover
				open={openJobForm}
				setOpen={setOpenJobForm}
				title={titleDialog}
				initialValues={initJobValues}
				onSubmit={saveJobToDb}
				onReset={() => setOpenJobForm(false)}
			/>
			<JobOpenAIPopover
				open={openJobAIForm}
				title='Open AI'
				initialValues={initJobValues}
				onSubmit={saveJobToDb}
				onReset={() => setOpenJobAIForm(false)}
			/>
		</PageLayout>
	);
};

export default JobsPage;
