const { default: CAREER_ROLE_GROUP } = require('./career-role-group');
const { isStringTypeValidator } = require('../../utils/isStringTypeValidator');

const CAREER_ROLE_KEY = 'career-role';

const CAREER_ROLE_ANSWER_CLEVEL_ENGINEERING = 'clevel-engineering';
const CAREER_ROLE_ANSWER_BACKEND_DEV = 'backend-dev';
const CAREER_ROLE_ANSWER_DEVOPS_INFRA = 'devops-infra';
const CAREER_ROLE_ANSWER_ENGINEERING_MGT = 'engineering-management';
const CAREER_ROLE_ANSWER_FRONTEND_DEV = 'frontend-dev';
const CAREER_ROLE_ANSWER_FULLSTACK_DEV = 'fullstack-dev';
const CAREER_ROLE_ANSWER_SOFTWARE_DEV = 'software-dev';
const CAREER_ROLE_ANSWER_GAME_DEV = 'game-dev';
const CAREER_ROLE_ANSWER_HARDWARE_DEV = 'hardware-dev';
const CAREER_ROLE_ANSWER_MOBILE_DEV = 'mobile-dev';
const CAREER_ROLE_ANSWER_QA_TESTING = 'qa-testing';
const CAREER_ROLE_ANSWER_SECURITY = 'security';
const CAREER_ROLE_ANSWER_NETWORK = 'network';
const CAREER_ROLE_ANSWER_IT_SUPPORT = 'it-support';

const CAREER_ROLE_ANSWER_CLEVEL_DATA = 'clevel-data';
const CAREER_ROLE_ANSWER_DATA_ENGINEERING = 'data-engineering';
const CAREER_ROLE_ANSWER_DATA_SCIENCE = 'data-science';
const CAREER_ROLE_ANSWER_DATA_ANALYSIS_BI = 'data-analysis-bi';
const CAREER_ROLE_ANSWER_MACHINE_LEARNING = 'machine-learning';

const CAREER_ROLE_ANSWER_CLEVEL_PRODUCT = 'clevel-product';
const CAREER_ROLE_ANSWER_CLEVEL_DESIGN = 'clevel-design';
const CAREER_ROLE_ANSWER_UI_DESIGN = 'ui-design';
const CAREER_ROLE_ANSWER_UX_DESIGN = 'ux-design';
const CAREER_ROLE_ANSWER_USER_RESEARCH = 'user-research';
const CAREER_ROLE_ANSWER_PRODUCT_ANALYSIS = 'product-analysis';
const CAREER_ROLE_ANSWER_PRODUCT_MANAGEMENT = 'product-management';
const CAREER_ROLE_ANSWER_PRODUCT_OWNERSHIP = 'product-ownership';

const CAREER_ROLE_ANSWER_CLEVEL_OPERATIONS = 'clevel-operations';
const CAREER_ROLE_ANSWER_OPERATIONS_MGT = 'operations-mgt';
const CAREER_ROLE_ANSWER_STRATEGY = 'strategy';
const CAREER_ROLE_ANSWER_PROGRAMME_MGT = 'programme-mgt';
const CAREER_ROLE_ANSWER_PROJECT_MGT = 'project-mgt';
const CAREER_ROLE_ANSWER_SCRUM_MASTER = 'scrum-master';

const CAREER_ROLE_ANSWER_CLEVEL_SALES = 'clevel-sales';
const CAREER_ROLE_ANSWER_SALES_B2B = 'sales-b2b';
const CAREER_ROLE_ANSWER_SALES_B2C = 'sales-b2c';
const CAREER_ROLE_ANSWER_SALES_BUSINESS_DEV = 'sales-business-development';
const CAREER_ROLE_ANSWER_PARTNERSHIPS_CHANNELS = 'partnerships-channels';
const CAREER_ROLE_ANSWER_SOLUTIONS_PRESALES = 'solutions-mgr-presales';
const CAREER_ROLE_ANSWER_CUSTOMER_SUCCESS = 'customer-success';
const CAREER_ROLE_ANSWER_BUSINESS_ANALYST = 'business-analyst';

const CAREER_ROLE_ANSWER_CLEVEL_MARKETING = 'clevel-marketing';
const CAREER_ROLE_ANSWER_BRAND_CREATIVE_MARKETING = 'brand-creative-marketing';
const CAREER_ROLE_ANSWER_CONTENT_MARKETING = 'content-marketing';
const CAREER_ROLE_ANSWER_COPYWRITING = 'copywriting';
const CAREER_ROLE_ANSWER_CRM = 'crm';
const CAREER_ROLE_ANSWER_GENERALIST_MARKETING = 'generalist-marketing';
const CAREER_ROLE_ANSWER_LEAD_GENERATION = 'lead-generation';
const CAREER_ROLE_ANSWER_GROWTH_ACQ = 'growth-acquisition';
const CAREER_ROLE_ANSWER_PERFORMANCE_MARKETING = 'performance-marketing';
const CAREER_ROLE_ANSWER_MARKET_ANALYSIS = 'market-analysis';
const CAREER_ROLE_ANSWER_LOYALTY_ANTICHURN = 'loyalty-antichurn';
const CAREER_ROLE_ANSWER_PR_COMMUNICATIONS = 'pr-communications';
const CAREER_ROLE_ANSWER_PRODUCT_MARKETING = 'product-marketing';
const CAREER_ROLE_ANSWER_SOCIAL_MEDIA_COMMUNITY = 'social-media-community';

const CAREER_ROLE_ANSWER_CLEVEL_HR = 'clevel-hr';
const CAREER_ROLE_ANSWER_CHIEF_OF_STAFF = 'chief-of-staff';
const CAREER_ROLE_ANSWER_HR_BUSINESS_PARTNER = 'hr-biz-partner';
const CAREER_ROLE_ANSWER_PEOPLE_OPERATIONS = 'people-operations';
const CAREER_ROLE_ANSWER_LEARNING_DEVELOPMENT = 'learning-development';
const CAREER_ROLE_ANSWER_RECRUITMENT = 'recruitment';

const CAREER_ROLE_ANSWER_CLEVEL_FINANCE = 'clevel-finance';
const CAREER_ROLE_ANSWER_ACCOUNTS_PAYROLL = 'accounts-payroll';
const CAREER_ROLE_ANSWER_ADMINISTRATION = 'administration';
const CAREER_ROLE_ANSWER_ACCOUNTING_FINANCIAL_PLANNING = 'accounting-financial-planning';
const CAREER_ROLE_ANSWER_COMPLIANCE = 'compliance';
const CAREER_ROLE_ANSWER_CORPORATE_FINANCE = 'corporate-finance';
const CAREER_ROLE_ANSWER_TAX_LEGAL = 'tax-legal';

const CAREER_ROLE_ANSWER_DATABASE_ADMINISTRATION = 'database-administration';
const CAREER_ROLE_ANSWER_EMBEDDED_SYSTEMS_ENGINEERING = 'embedded-systems-engineering';
const CAREER_ROLE_ANSWER_DATA_VISUALIZATION = 'data-visualization';
const CAREER_ROLE_ANSWER_GRAPHIC_MOTION_DESIGN = 'graphic-motion-design';
const CAREER_ROLE_ANSWER_FACILITIES_MANAGEMENT = 'facilities-management';
const CAREER_ROLE_ANSWER_INSIDE_SALES = 'inside-sales';
const CAREER_ROLE_ANSWER_VIDEO_PRODUCTION = 'video-production';
const CAREER_ROLE_ANSWER_COMPENSATION_BENEFITS = 'compensation-benefits';
const CAREER_ROLE_ANSWER_DIVERSITY_EQUITY_INCLUSION = 'diversity-equity-inclusion';
const CAREER_ROLE_ANSWER_RESEARCH_DEVELOPMENT = 'research-development';
const CAREER_ROLE_ANSWER_PRODUCT_DESIGN = 'product-design';
const CAREER_ROLE_ANSWER_IT_ARCHITECTURE = 'it-architecture';
const CAREER_ROLE_ANSWER_CLOUD_COMPUTING = 'cloud-computing';
const CAREER_ROLE_ANSWER_ARTIFICIAL_INTELLIGENCE = 'artificial-intelligence';
const CAREER_ROLE_ANSWER_BIG_DATA = 'big-data';
const CAREER_ROLE_ANSWER_COMPUTER_VISION = 'computer-vision';

const CAREER_ROLE_OPTIONS = [
	{
		group: CAREER_ROLE_GROUP.CAREER_ROLE_GROUP_ANSWER_ENGINEERING,
		value: CAREER_ROLE_ANSWER_CLEVEL_ENGINEERING,
		label: 'CTO / VP Engineering',
	},
	{
		group: CAREER_ROLE_GROUP.CAREER_ROLE_GROUP_ANSWER_ENGINEERING,
		value: CAREER_ROLE_ANSWER_ENGINEERING_MGT,
		label: 'Engineering Management',
	},
	{
		group: CAREER_ROLE_GROUP.CAREER_ROLE_GROUP_ANSWER_ENGINEERING,
		value: CAREER_ROLE_ANSWER_DEVOPS_INFRA,
		label: 'DevOps / Infra',
	},
	{
		group: CAREER_ROLE_GROUP.CAREER_ROLE_GROUP_ANSWER_ENGINEERING,
		value: CAREER_ROLE_ANSWER_SECURITY,
		label: 'Security',
	},
	{
		group: CAREER_ROLE_GROUP.CAREER_ROLE_GROUP_ANSWER_ENGINEERING,
		value: CAREER_ROLE_ANSWER_NETWORK,
		label: 'Network',
	},
	{
		group: CAREER_ROLE_GROUP.CAREER_ROLE_GROUP_ANSWER_ENGINEERING,
		value: CAREER_ROLE_ANSWER_BACKEND_DEV,
		label: 'Backend Development',
	},
	{
		group: CAREER_ROLE_GROUP.CAREER_ROLE_GROUP_ANSWER_ENGINEERING,
		value: CAREER_ROLE_ANSWER_FRONTEND_DEV,
		label: 'Frontend Development',
	},
	{
		group: CAREER_ROLE_GROUP.CAREER_ROLE_GROUP_ANSWER_ENGINEERING,
		value: CAREER_ROLE_ANSWER_FULLSTACK_DEV,
		label: 'Fullstack Development',
	},
	{
		group: CAREER_ROLE_GROUP.CAREER_ROLE_GROUP_ANSWER_ENGINEERING,
		value: CAREER_ROLE_ANSWER_SOFTWARE_DEV,
		label: 'Software Development',
	},
	{
		group: CAREER_ROLE_GROUP.CAREER_ROLE_GROUP_ANSWER_ENGINEERING,
		value: CAREER_ROLE_ANSWER_MOBILE_DEV,
		label: 'Mobile Development',
	},
	{
		group: CAREER_ROLE_GROUP.CAREER_ROLE_GROUP_ANSWER_ENGINEERING,
		value: CAREER_ROLE_ANSWER_GAME_DEV,
		label: 'Game Development',
	},
	{
		group: CAREER_ROLE_GROUP.CAREER_ROLE_GROUP_ANSWER_ENGINEERING,
		value: CAREER_ROLE_ANSWER_HARDWARE_DEV,
		label: 'Hardware Development',
	},
	{
		group: CAREER_ROLE_GROUP.CAREER_ROLE_GROUP_ANSWER_ENGINEERING,
		value: CAREER_ROLE_ANSWER_QA_TESTING,
		label: 'QA & Testing',
	},
	{
		group: CAREER_ROLE_GROUP.CAREER_ROLE_GROUP_ANSWER_ENGINEERING,
		value: CAREER_ROLE_ANSWER_IT_SUPPORT,
		label: 'IT Support',
	},

	{
		group: CAREER_ROLE_GROUP.CAREER_ROLE_GROUP_ANSWER_DATA,
		value: CAREER_ROLE_ANSWER_CLEVEL_DATA,
		label: 'CDO / VP Data',
	},
	{
		group: CAREER_ROLE_GROUP.CAREER_ROLE_GROUP_ANSWER_DATA,
		value: CAREER_ROLE_ANSWER_DATA_ENGINEERING,
		label: 'Data Engineering',
	},
	{
		group: CAREER_ROLE_GROUP.CAREER_ROLE_GROUP_ANSWER_DATA,
		value: CAREER_ROLE_ANSWER_DATA_SCIENCE,
		label: 'Data Science',
	},
	{
		group: CAREER_ROLE_GROUP.CAREER_ROLE_GROUP_ANSWER_DATA,
		value: CAREER_ROLE_ANSWER_DATA_ANALYSIS_BI,
		label: 'Data Analysis & BI',
	},
	{
		group: CAREER_ROLE_GROUP.CAREER_ROLE_GROUP_ANSWER_DATA,
		value: CAREER_ROLE_ANSWER_MACHINE_LEARNING,
		label: 'Machine Learning',
	},

	{
		group: CAREER_ROLE_GROUP.CAREER_ROLE_GROUP_ANSWER_PRODUCT_DESIGN,
		value: CAREER_ROLE_ANSWER_CLEVEL_PRODUCT,
		label: 'CPO / VP Product',
	},
	{
		group: CAREER_ROLE_GROUP.CAREER_ROLE_GROUP_ANSWER_PRODUCT_DESIGN,
		value: CAREER_ROLE_ANSWER_CLEVEL_DESIGN,
		label: 'VP or Head of Design',
	},
	{
		group: CAREER_ROLE_GROUP.CAREER_ROLE_GROUP_ANSWER_PRODUCT_DESIGN,
		value: CAREER_ROLE_ANSWER_PRODUCT_MANAGEMENT,
		label: 'Product Management',
	},
	{
		group: CAREER_ROLE_GROUP.CAREER_ROLE_GROUP_ANSWER_PRODUCT_DESIGN,
		value: CAREER_ROLE_ANSWER_PRODUCT_OWNERSHIP,
		label: 'Product Ownership',
	},
	{
		group: CAREER_ROLE_GROUP.CAREER_ROLE_GROUP_ANSWER_PRODUCT_DESIGN,
		value: CAREER_ROLE_ANSWER_PRODUCT_ANALYSIS,
		label: 'Product Analysis',
	},
	{
		group: CAREER_ROLE_GROUP.CAREER_ROLE_GROUP_ANSWER_PRODUCT_DESIGN,
		value: CAREER_ROLE_ANSWER_USER_RESEARCH,
		label: 'User Research',
	},
	{
		group: CAREER_ROLE_GROUP.CAREER_ROLE_GROUP_ANSWER_PRODUCT_DESIGN,
		value: CAREER_ROLE_ANSWER_UI_DESIGN,
		label: 'UI Design',
	},
	{
		group: CAREER_ROLE_GROUP.CAREER_ROLE_GROUP_ANSWER_PRODUCT_DESIGN,
		value: CAREER_ROLE_ANSWER_UX_DESIGN,
		label: 'UX Design',
	},

	{
		group: CAREER_ROLE_GROUP.CAREER_ROLE_GROUP_ANSWER_OPERATIONS,
		value: CAREER_ROLE_ANSWER_CLEVEL_OPERATIONS,
		label: 'COO / Head of Operations',
	},
	{
		group: CAREER_ROLE_GROUP.CAREER_ROLE_GROUP_ANSWER_OPERATIONS,
		value: CAREER_ROLE_ANSWER_OPERATIONS_MGT,
		label: 'Operations Management',
	},
	{
		group: CAREER_ROLE_GROUP.CAREER_ROLE_GROUP_ANSWER_OPERATIONS,
		value: CAREER_ROLE_ANSWER_PROGRAMME_MGT,
		label: 'Programme Management',
	},
	{
		group: CAREER_ROLE_GROUP.CAREER_ROLE_GROUP_ANSWER_OPERATIONS,
		value: CAREER_ROLE_ANSWER_STRATEGY,
		label: 'Strategy',
	},
	{
		group: CAREER_ROLE_GROUP.CAREER_ROLE_GROUP_ANSWER_OPERATIONS,
		value: CAREER_ROLE_ANSWER_PROJECT_MGT,
		label: 'Project Management',
	},
	{
		group: CAREER_ROLE_GROUP.CAREER_ROLE_GROUP_ANSWER_OPERATIONS,
		value: CAREER_ROLE_ANSWER_SCRUM_MASTER,
		label: 'Scrum Master',
	},

	{
		group: CAREER_ROLE_GROUP.CAREER_ROLE_GROUP_ANSWER_SALES,
		value: CAREER_ROLE_ANSWER_CLEVEL_SALES,
		label: 'VP / Head of Sales',
	},
	{
		group: CAREER_ROLE_GROUP.CAREER_ROLE_GROUP_ANSWER_SALES, value: CAREER_ROLE_ANSWER_SALES_B2B, label: 'Sales B2B',
	},
	{
		group: CAREER_ROLE_GROUP.CAREER_ROLE_GROUP_ANSWER_SALES, value: CAREER_ROLE_ANSWER_SALES_B2C, label: 'Sales B2C',
	},
	{
		group: CAREER_ROLE_GROUP.CAREER_ROLE_GROUP_ANSWER_SALES,
		value: CAREER_ROLE_ANSWER_SALES_BUSINESS_DEV,
		label: 'Sales Business Development',
	},
	{
		group: CAREER_ROLE_GROUP.CAREER_ROLE_GROUP_ANSWER_SALES,
		value: CAREER_ROLE_ANSWER_PARTNERSHIPS_CHANNELS,
		label: 'Partnerships & Channels',
	},
	{
		group: CAREER_ROLE_GROUP.CAREER_ROLE_GROUP_ANSWER_SALES,
		value: CAREER_ROLE_ANSWER_SOLUTIONS_PRESALES,
		label: 'Solutions / Pre-Sales',
	},
	{
		group: CAREER_ROLE_GROUP.CAREER_ROLE_GROUP_ANSWER_SALES,
		value: CAREER_ROLE_ANSWER_CUSTOMER_SUCCESS,
		label: 'Customer Success',
	},
	{
		group: CAREER_ROLE_GROUP.CAREER_ROLE_GROUP_ANSWER_SALES,
		value: CAREER_ROLE_ANSWER_BUSINESS_ANALYST,
		label: 'Business Analyst',
	},

	{
		group: CAREER_ROLE_GROUP.CAREER_ROLE_GROUP_ANSWER_MARKETING,
		value: CAREER_ROLE_ANSWER_CLEVEL_MARKETING,
		label: 'CMO / VP Marketing',
	},
	{
		group: CAREER_ROLE_GROUP.CAREER_ROLE_GROUP_ANSWER_MARKETING,
		value: CAREER_ROLE_ANSWER_BRAND_CREATIVE_MARKETING,
		label: 'Brand & Creative Marketing',
	},
	{
		group: CAREER_ROLE_GROUP.CAREER_ROLE_GROUP_ANSWER_MARKETING,
		value: CAREER_ROLE_ANSWER_CONTENT_MARKETING,
		label: 'Content Marketing',
	},
	{
		group: CAREER_ROLE_GROUP.CAREER_ROLE_GROUP_ANSWER_MARKETING,
		value: CAREER_ROLE_ANSWER_COPYWRITING,
		label: 'Copywriting',
	},
	{ group: CAREER_ROLE_GROUP.CAREER_ROLE_GROUP_ANSWER_MARKETING, value: CAREER_ROLE_ANSWER_CRM, label: 'CRM' },
	{
		group: CAREER_ROLE_GROUP.CAREER_ROLE_GROUP_ANSWER_MARKETING,
		value: CAREER_ROLE_ANSWER_GENERALIST_MARKETING,
		label: 'Generalist Marketing',
	},
	{
		group: CAREER_ROLE_GROUP.CAREER_ROLE_GROUP_ANSWER_MARKETING,
		value: CAREER_ROLE_ANSWER_LEAD_GENERATION,
		label: 'Lead Generation',
	},
	{
		group: CAREER_ROLE_GROUP.CAREER_ROLE_GROUP_ANSWER_MARKETING,
		value: CAREER_ROLE_ANSWER_GROWTH_ACQ,
		label: 'Growth Acquisition',
	},
	{
		group: CAREER_ROLE_GROUP.CAREER_ROLE_GROUP_ANSWER_MARKETING,
		value: CAREER_ROLE_ANSWER_PERFORMANCE_MARKETING,
		label: 'Performance Marketing',
	},
	{
		group: CAREER_ROLE_GROUP.CAREER_ROLE_GROUP_ANSWER_MARKETING,
		value: CAREER_ROLE_ANSWER_MARKET_ANALYSIS,
		label: 'Market Analysis',
	},
	{
		group: CAREER_ROLE_GROUP.CAREER_ROLE_GROUP_ANSWER_MARKETING,
		value: CAREER_ROLE_ANSWER_LOYALTY_ANTICHURN,
		label: 'Loyalty & Anti-churn',
	},
	{
		group: CAREER_ROLE_GROUP.CAREER_ROLE_GROUP_ANSWER_MARKETING,
		value: CAREER_ROLE_ANSWER_PR_COMMUNICATIONS,
		label: 'PR & Communications',
	},
	{
		group: CAREER_ROLE_GROUP.CAREER_ROLE_GROUP_ANSWER_MARKETING,
		value: CAREER_ROLE_ANSWER_PRODUCT_MARKETING,
		label: 'Product Marketing',
	},
	{
		group: CAREER_ROLE_GROUP.CAREER_ROLE_GROUP_ANSWER_MARKETING,
		value: CAREER_ROLE_ANSWER_SOCIAL_MEDIA_COMMUNITY,
		label: 'Social Media & Community',
	},

	{
		group: CAREER_ROLE_GROUP.CAREER_ROLE_GROUP_ANSWER_PEOPLE_HR,
		value: CAREER_ROLE_ANSWER_CLEVEL_HR,
		label: 'CHRO / VP People',
	},
	{
		group: CAREER_ROLE_GROUP.CAREER_ROLE_GROUP_ANSWER_PEOPLE_HR,
		value: CAREER_ROLE_ANSWER_CHIEF_OF_STAFF,
		label: 'Chief of Staff',
	},
	{
		group: CAREER_ROLE_GROUP.CAREER_ROLE_GROUP_ANSWER_PEOPLE_HR,
		value: CAREER_ROLE_ANSWER_HR_BUSINESS_PARTNER,
		label: 'HR Business Partner',
	},
	{
		group: CAREER_ROLE_GROUP.CAREER_ROLE_GROUP_ANSWER_PEOPLE_HR,
		value: CAREER_ROLE_ANSWER_PEOPLE_OPERATIONS,
		label: 'People Operations',
	},
	{
		group: CAREER_ROLE_GROUP.CAREER_ROLE_GROUP_ANSWER_PEOPLE_HR,
		value: CAREER_ROLE_ANSWER_LEARNING_DEVELOPMENT,
		label: 'Learning & Development',
	},
	{
		group: CAREER_ROLE_GROUP.CAREER_ROLE_GROUP_ANSWER_PEOPLE_HR,
		value: CAREER_ROLE_ANSWER_RECRUITMENT,
		label: 'Recruitment',
	},

	{
		group: CAREER_ROLE_GROUP.CAREER_ROLE_GROUP_ANSWER_FINANCE_LEGAL,
		value: CAREER_ROLE_ANSWER_CLEVEL_FINANCE,
		label: 'CFO / VP Finance',
	},
	{
		group: CAREER_ROLE_GROUP.CAREER_ROLE_GROUP_ANSWER_FINANCE_LEGAL,
		value: CAREER_ROLE_ANSWER_ACCOUNTS_PAYROLL,
		label: 'Accounts & Payroll',
	},
	{
		group: CAREER_ROLE_GROUP.CAREER_ROLE_GROUP_ANSWER_FINANCE_LEGAL,
		value: CAREER_ROLE_ANSWER_ADMINISTRATION,
		label: 'Administration',
	},
	{
		group: CAREER_ROLE_GROUP.CAREER_ROLE_GROUP_ANSWER_FINANCE_LEGAL,
		value: CAREER_ROLE_ANSWER_ACCOUNTING_FINANCIAL_PLANNING,
		label: 'Accounting & Financial Planning',
	},
	{
		group: CAREER_ROLE_GROUP.CAREER_ROLE_GROUP_ANSWER_FINANCE_LEGAL,
		value: CAREER_ROLE_ANSWER_COMPLIANCE,
		label: 'Compliance',
	},
	{
		group: CAREER_ROLE_GROUP.CAREER_ROLE_GROUP_ANSWER_FINANCE_LEGAL,
		value: CAREER_ROLE_ANSWER_CORPORATE_FINANCE,
		label: 'Corporate Finance',
	},
	{
		group: CAREER_ROLE_GROUP.CAREER_ROLE_GROUP_ANSWER_FINANCE_LEGAL,
		value: CAREER_ROLE_ANSWER_TAX_LEGAL,
		label: 'Tax & Legal',
	},

	{ group: CAREER_ROLE_GROUP.CAREER_ROLE_GROUP_ANSWER_DATA, value: CAREER_ROLE_ANSWER_DATABASE_ADMINISTRATION, label: 'Database Administration (DBA)' },
	{ group: CAREER_ROLE_GROUP.CAREER_ROLE_GROUP_ANSWER_ENGINEERING, value: CAREER_ROLE_ANSWER_EMBEDDED_SYSTEMS_ENGINEERING, label: 'Embedded Systems Engineering' },
	{ group: CAREER_ROLE_GROUP.CAREER_ROLE_GROUP_ANSWER_DATA, value: CAREER_ROLE_ANSWER_DATA_VISUALIZATION, label: 'Data Visualization' },
	{ group: CAREER_ROLE_GROUP.CAREER_ROLE_GROUP_ANSWER_PRODUCT_DESIGN, value: CAREER_ROLE_ANSWER_GRAPHIC_MOTION_DESIGN, label: 'Graphic & Motion Design' },
	{ group: CAREER_ROLE_GROUP.CAREER_ROLE_GROUP_ANSWER_OPERATIONS, value: CAREER_ROLE_ANSWER_FACILITIES_MANAGEMENT, label: 'Facilities Management' },
	{ group: CAREER_ROLE_GROUP.CAREER_ROLE_GROUP_ANSWER_SALES, value: CAREER_ROLE_ANSWER_INSIDE_SALES, label: 'Inside Sales' },
	{ group: CAREER_ROLE_GROUP.CAREER_ROLE_GROUP_ANSWER_PRODUCT_DESIGN, value: CAREER_ROLE_ANSWER_VIDEO_PRODUCTION, label: 'Video Production' },
	{ group: CAREER_ROLE_GROUP.CAREER_ROLE_GROUP_ANSWER_PEOPLE_HR, value: CAREER_ROLE_ANSWER_COMPENSATION_BENEFITS, label: 'Compensation and Benefits' },
	{ group: CAREER_ROLE_GROUP.CAREER_ROLE_GROUP_ANSWER_PEOPLE_HR, value: CAREER_ROLE_ANSWER_DIVERSITY_EQUITY_INCLUSION, label: 'DE&I - Diversity, Equity & Inclusion' },
	{ group: CAREER_ROLE_GROUP.CAREER_ROLE_GROUP_ANSWER_ENGINEERING, value: CAREER_ROLE_ANSWER_RESEARCH_DEVELOPMENT, label: 'Research & Development (R&D)' },
	{ group: CAREER_ROLE_GROUP.CAREER_ROLE_GROUP_ANSWER_PRODUCT_DESIGN, value: CAREER_ROLE_ANSWER_PRODUCT_DESIGN, label: 'Product Design' },
	{ group: CAREER_ROLE_GROUP.CAREER_ROLE_GROUP_ANSWER_ENGINEERING, value: CAREER_ROLE_ANSWER_IT_ARCHITECTURE, label: 'IT Architecture' },
	{ group: CAREER_ROLE_GROUP.CAREER_ROLE_GROUP_ANSWER_ENGINEERING, value: CAREER_ROLE_ANSWER_CLOUD_COMPUTING, label: 'Cloud Computing' },
	{ group: CAREER_ROLE_GROUP.CAREER_ROLE_GROUP_ANSWER_ENGINEERING, value: CAREER_ROLE_ANSWER_ARTIFICIAL_INTELLIGENCE, label: 'Artificial Intelligence (AI)' },
	{ group: CAREER_ROLE_GROUP.CAREER_ROLE_GROUP_ANSWER_DATA, value: CAREER_ROLE_ANSWER_BIG_DATA, label: 'Big Data' },
	{ group: CAREER_ROLE_GROUP.CAREER_ROLE_GROUP_ANSWER_ENGINEERING, value: CAREER_ROLE_ANSWER_COMPUTER_VISION, label: 'Computer Vision' },

];

const customValidator = (value) => CAREER_ROLE_OPTIONS.map((o) => o.value).includes(value);

const CAREER_ROLE_VALIDATORS = [
	isStringTypeValidator,
	customValidator,
];

export default {
	CAREER_ROLE_ANSWER_CLEVEL_ENGINEERING,
	CAREER_ROLE_ANSWER_BACKEND_DEV,
	CAREER_ROLE_ANSWER_DEVOPS_INFRA,
	CAREER_ROLE_ANSWER_ENGINEERING_MGT,
	CAREER_ROLE_ANSWER_FRONTEND_DEV,
	CAREER_ROLE_ANSWER_FULLSTACK_DEV,
	CAREER_ROLE_ANSWER_SOFTWARE_DEV,
	CAREER_ROLE_ANSWER_GAME_DEV,
	CAREER_ROLE_ANSWER_HARDWARE_DEV,
	CAREER_ROLE_ANSWER_MOBILE_DEV,
	CAREER_ROLE_ANSWER_QA_TESTING,
	CAREER_ROLE_ANSWER_SECURITY,
	CAREER_ROLE_ANSWER_NETWORK,
	CAREER_ROLE_ANSWER_IT_SUPPORT,

	CAREER_ROLE_ANSWER_CLEVEL_DATA,
	CAREER_ROLE_ANSWER_DATA_ENGINEERING,
	CAREER_ROLE_ANSWER_DATA_SCIENCE,
	CAREER_ROLE_ANSWER_DATA_ANALYSIS_BI,
	CAREER_ROLE_ANSWER_MACHINE_LEARNING,

	CAREER_ROLE_ANSWER_CLEVEL_PRODUCT,
	CAREER_ROLE_ANSWER_CLEVEL_DESIGN,
	CAREER_ROLE_ANSWER_UI_DESIGN,
	CAREER_ROLE_ANSWER_UX_DESIGN,
	CAREER_ROLE_ANSWER_USER_RESEARCH,
	CAREER_ROLE_ANSWER_PRODUCT_ANALYSIS,
	CAREER_ROLE_ANSWER_PRODUCT_MANAGEMENT,
	CAREER_ROLE_ANSWER_PRODUCT_OWNERSHIP,

	CAREER_ROLE_ANSWER_CLEVEL_OPERATIONS,
	CAREER_ROLE_ANSWER_OPERATIONS_MGT,
	CAREER_ROLE_ANSWER_STRATEGY,
	CAREER_ROLE_ANSWER_PROGRAMME_MGT,
	CAREER_ROLE_ANSWER_PROJECT_MGT,
	CAREER_ROLE_ANSWER_SCRUM_MASTER,

	CAREER_ROLE_ANSWER_CLEVEL_SALES,
	CAREER_ROLE_ANSWER_SALES_B2B,
	CAREER_ROLE_ANSWER_SALES_B2C,
	CAREER_ROLE_ANSWER_SALES_BUSINESS_DEV,
	CAREER_ROLE_ANSWER_PARTNERSHIPS_CHANNELS,
	CAREER_ROLE_ANSWER_SOLUTIONS_PRESALES,
	CAREER_ROLE_ANSWER_CUSTOMER_SUCCESS,
	CAREER_ROLE_ANSWER_BUSINESS_ANALYST,
	// CAREER_ROLE_ANSWER_ACCOUNT_MGT,
	// CAREER_ROLE_ANSWER_ACCOUNT_DEV,
	// CAREER_ROLE_ANSWER_ACCOUNT_EXEC,

	CAREER_ROLE_ANSWER_CLEVEL_MARKETING,
	CAREER_ROLE_ANSWER_BRAND_CREATIVE_MARKETING,
	CAREER_ROLE_ANSWER_CONTENT_MARKETING,
	CAREER_ROLE_ANSWER_COPYWRITING,
	CAREER_ROLE_ANSWER_CRM,
	CAREER_ROLE_ANSWER_GENERALIST_MARKETING,
	CAREER_ROLE_ANSWER_LEAD_GENERATION,
	CAREER_ROLE_ANSWER_GROWTH_ACQ,
	CAREER_ROLE_ANSWER_PERFORMANCE_MARKETING,
	CAREER_ROLE_ANSWER_MARKET_ANALYSIS,
	CAREER_ROLE_ANSWER_LOYALTY_ANTICHURN,
	CAREER_ROLE_ANSWER_PR_COMMUNICATIONS,
	CAREER_ROLE_ANSWER_PRODUCT_MARKETING,
	CAREER_ROLE_ANSWER_SOCIAL_MEDIA_COMMUNITY,

	CAREER_ROLE_ANSWER_CLEVEL_HR,
	CAREER_ROLE_ANSWER_CHIEF_OF_STAFF,
	CAREER_ROLE_ANSWER_HR_BUSINESS_PARTNER,
	CAREER_ROLE_ANSWER_PEOPLE_OPERATIONS,
	CAREER_ROLE_ANSWER_LEARNING_DEVELOPMENT,
	CAREER_ROLE_ANSWER_RECRUITMENT,

	CAREER_ROLE_ANSWER_CLEVEL_FINANCE,
	CAREER_ROLE_ANSWER_ACCOUNTS_PAYROLL,
	CAREER_ROLE_ANSWER_ADMINISTRATION,
	CAREER_ROLE_ANSWER_ACCOUNTING_FINANCIAL_PLANNING,
	CAREER_ROLE_ANSWER_COMPLIANCE,
	CAREER_ROLE_ANSWER_CORPORATE_FINANCE,
	CAREER_ROLE_ANSWER_TAX_LEGAL,

	CAREER_ROLE_ANSWER_DATABASE_ADMINISTRATION,
	CAREER_ROLE_ANSWER_EMBEDDED_SYSTEMS_ENGINEERING,
	CAREER_ROLE_ANSWER_DATA_VISUALIZATION,
	CAREER_ROLE_ANSWER_GRAPHIC_MOTION_DESIGN,
	CAREER_ROLE_ANSWER_FACILITIES_MANAGEMENT,
	CAREER_ROLE_ANSWER_INSIDE_SALES,
	CAREER_ROLE_ANSWER_VIDEO_PRODUCTION,
	CAREER_ROLE_ANSWER_COMPENSATION_BENEFITS,
	CAREER_ROLE_ANSWER_DIVERSITY_EQUITY_INCLUSION,
	CAREER_ROLE_ANSWER_RESEARCH_DEVELOPMENT,
	CAREER_ROLE_ANSWER_PRODUCT_DESIGN,
	CAREER_ROLE_ANSWER_IT_ARCHITECTURE,
	CAREER_ROLE_ANSWER_CLOUD_COMPUTING,
	CAREER_ROLE_ANSWER_ARTIFICIAL_INTELLIGENCE,
	CAREER_ROLE_ANSWER_BIG_DATA,
	CAREER_ROLE_ANSWER_COMPUTER_VISION,

	key: CAREER_ROLE_KEY,
	CAREER_ROLE_KEY,
	validators: CAREER_ROLE_VALIDATORS,
	CAREER_ROLE_VALIDATORS,
	groups: CAREER_ROLE_GROUP.CAREER_ROLE_GROUP_OPTIONS,
	CAREER_ROLE_GROUPS: CAREER_ROLE_GROUP.CAREER_ROLE_GROUP_OPTIONS,
	options: CAREER_ROLE_OPTIONS,
	CAREER_ROLE_OPTIONS,
};
