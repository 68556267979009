import PageLayout from '../../../../components/app/layout/PageLayout';

const TagsWarningsPage = () => {
	return (
		<PageLayout
			withBackground={false}
			title='Tag warnings'
		/>
	);
};

export default TagsWarningsPage;
