import { mixColors } from '../../utils/common';
import { GENDERSCORE_LABEL } from '../constant';

const GENDERSCORE_STATUS_KEY = 'genderscore-status';

const GENDERSCORE_STATUS_MULTI = false;
const GENDERSCORE_STATUS_FREE = false;

export const GENDERSCORE_SURVEY_STATUS_DRAFT_VALUE = 'DRAFT';
export const GENDERSCORE_SURVEY_STATUS_CURRENT_VALUE = 'CURRENT';
export const GENDERSCORE_SURVEY_STATUS_PREVIOUS_VALUE = 'PREVIOUS';
export const GENDERSCORE_SURVEY_STATUS_LEAD_VALUE = 'LEAD';
export const GENDERSCORE_SURVEY_STATUS_REVIEW_VALUE = 'REVIEW';

export const GENDERSCORE_SURVEY_STATUS_DRAFT_OPTION = {
	value: GENDERSCORE_SURVEY_STATUS_DRAFT_VALUE,
	label: GENDERSCORE_SURVEY_STATUS_DRAFT_VALUE,
	color: '#FF635B',
	tagStyle: { border: '1px dashed #FF635B', color: '#FF635B' },
};

export const GENDERSCORE_SURVEY_STATUS_CURRENT_OPTION = {
	value: GENDERSCORE_SURVEY_STATUS_CURRENT_VALUE,
	label: GENDERSCORE_SURVEY_STATUS_CURRENT_VALUE,
	color: '#00ced1',
	tagStyle: { background: '#00ced1', color: 'white' },
};

export const GENDERSCORE_SURVEY_STATUS_PREVIOUS_OPTION = {
	value: GENDERSCORE_SURVEY_STATUS_PREVIOUS_VALUE,
	label: GENDERSCORE_SURVEY_STATUS_PREVIOUS_VALUE,
	color: mixColors('#00ced1', '#ffffff', 50),
	tagStyle: { border: '1px solid #00ced1', color: '#00ced1' },
};

export const GENDERSCORE_SURVEY_STATUS_LEAD_OPTION = {
	value: GENDERSCORE_SURVEY_STATUS_LEAD_VALUE,
	label: GENDERSCORE_SURVEY_STATUS_LEAD_VALUE,
	color: '#881cff',
	tagStyle: { background: mixColors('#881cff', '#ffffff', 60), color: 'white' },
};

export const GENDERSCORE_SURVEY_STATUS_REVIEW_OPTION = {
	value: GENDERSCORE_SURVEY_STATUS_REVIEW_VALUE,
	label: GENDERSCORE_SURVEY_STATUS_REVIEW_VALUE,
	color: '#9D9D9D',
	tagStyle: { border: '1px dashed #9D9D9D', color: '#9D9D9D' },
};

export const GENDERSCORE_STATUS_OPTIONS = [
	GENDERSCORE_SURVEY_STATUS_DRAFT_OPTION,
	GENDERSCORE_SURVEY_STATUS_CURRENT_OPTION,
	GENDERSCORE_SURVEY_STATUS_PREVIOUS_OPTION,
	GENDERSCORE_SURVEY_STATUS_LEAD_OPTION,
	GENDERSCORE_SURVEY_STATUS_REVIEW_OPTION,
];

export const getGenderScoreStatusOption = (value) => (
	GENDERSCORE_STATUS_OPTIONS?.find((o) => o?.value === value) || {}
);

export const getGenderScoreStatusLabel = (value) => (
	getGenderScoreStatusOption(value)?.label
);

export const getGenderScoreStatusColor = (value) => (
	getGenderScoreStatusOption(value)?.color
);

export const getGenderScoreStatusTag = (value, style) => (
	<span
		style={{
			fontSize: 12,
			width: 'auto',
			fontWeight: 'bold',
			padding: '2px 4px',
			borderRadius: 3,
			lineHeight: 1.2,
			...getGenderScoreStatusOption(value)?.tagStyle,
			...style || {},
		}}
	>
		{getGenderScoreStatusLabel(value)}
	</span>
);

export const GENDERSCORE_STATUS_VALUES = GENDERSCORE_STATUS_OPTIONS.map((o) => o.value);

const exporter = {
	key: GENDERSCORE_STATUS_KEY,
	GENDERSCORE_STATUS_KEY,
	GENDERSCORE_SURVEY_STATUS_DRAFT_OPTION,
	GENDERSCORE_SURVEY_STATUS_CURRENT_OPTION,
	GENDERSCORE_SURVEY_STATUS_PREVIOUS_OPTION,
	GENDERSCORE_SURVEY_STATUS_LEAD_OPTION,
	GENDERSCORE_SURVEY_STATUS_REVIEW_OPTION,
	multi: GENDERSCORE_STATUS_MULTI,
	GENDERSCORE_STATUS_MULTI,
	options: GENDERSCORE_STATUS_OPTIONS,
	GENDERSCORE_STATUS_OPTIONS,
	free: GENDERSCORE_STATUS_FREE,
	GENDERSCORE_STATUS_FREE,
	values: GENDERSCORE_STATUS_VALUES,
	GENDERSCORE_STATUS_VALUES,
	getGenderScoreStatusOption,
	getGenderScoreStatusLabel,
	getGenderScoreStatusColor,
	getGenderScoreStatusTag,
};

export default exporter;
