import { useTheme } from '@emotion/react';
import CompanyService from '../../services/company';
import TableColumns from '../app/table/TableColumns';
import Table from '../app/table/Table';
import { useMemo, useState } from 'react';
import CompanyStub from './CompanyStub';
import useBodyScrollBlocker from '../../hooks/useBodyScrollBlocker';
import {
	documentId, filterAutoCompleteItems, openExternalUrl,
} from '../../utils/common';
import { GENDERSCORE_SURVEY_50PLUS_V3_VALUE, getGenderScoreSurveyTag } from '../../constants/property/genderscore-survey';
import { Dropdown, Tooltip } from 'antd';
import SURVEYS from '../genderscore/data/surveys';
import { GENDERSCORE_PILLAR_VALUES } from '../../constants/genderscore-pillars';
import { BiChevronDown } from 'react-icons/bi';
import { FaBuilding, FaEye } from 'react-icons/fa';
import { buildGSAnswersCol, buildScoresCol } from './CompanyGenderScoreTableColumns';
import { getGenderScoreStatusTag } from '../../constants/property/genderscore-status';
import { Link, useNavigate } from 'react-router-dom';
import GENDERSCORE_SURVEY_50PLUS_V3_DATA_OPTION from '../genderscore/data/surveys/genderscore-survey-50-plus-v3';
import StaffGSSurveyListMenu from '../../menu/staff/genderscore/StaffGSSurveyListMenu';
import StaffGSSurveyDetailsMenu from '../../menu/staff/genderscore/StaffGSSurveyDetailsMenu';
import config from '../../config/config';
import SCORING from '../genderscore/data/scoring';

const CompanyGenderScoreSurveyTable = (
	{
		path = '/genderscore/survey',
		onDataLoaded,
		filterOptions,
		isRefresh = 0,
		defaultColumnsOptions = ['name', 'path'],
	},
) => {
	const className = 'CompanyGenderScoreSurveyTable';

	const theme = useTheme();
	const navigate = useNavigate();

	const handleSearchAutoComplete = async (options) => {
		const { data: { companies } } = await CompanyService.findAll(options);
		return filterAutoCompleteItems(companies);
	};

	const [company, setCompany] = useState();
	const [openForm, setOpenForm] = useState(false);

	useBodyScrollBlocker(openForm, className);

	const sanitizeGenderScoreObj = (genderScore) => {
		const result = Object.fromEntries(
			Object
				.entries(genderScore || {})
				.filter(([key, value]) => (
					['globalScore', ...GENDERSCORE_PILLAR_VALUES].includes(key)
					&& (value || value === 0))),
		);

		return Object.keys(result)?.length > 0
			? result
			: null;
	};
	const handleRedirectGenderScoreSurveysPage = (survey) => {
		openExternalUrl(`${config.adminRoot}${StaffGSSurveyListMenu.props.path}?companySelected=${survey?.company?.path}`);
		// navigate(`${StaffGSSurveyListMenu.props.path}?companySelected=${survey?.company?.path}`);
	};
	const handleRedirectGenderScoreSurveyDetailsPage = (survey) => {
		openExternalUrl(`${config.adminRoot}${StaffGSSurveyDetailsMenu.props.path}?surveyId=${documentId(survey)}`);
		// navigate(`${StaffGSSurveyDetailsMenu.props.path}?surveyId=${documentId(survey)}`);
	};
	const handleRedirectGenderScoreSurveyDetailsV3Page = (survey) => {
		openExternalUrl(`${config.adminRoot}${StaffGSSurveyDetailsMenu.props.path}?surveyId=${documentId(survey)}&surveyConf=${GENDERSCORE_SURVEY_50PLUS_V3_VALUE}`);
		// navigate(`${StaffGSSurveyDetailsMenu.props.path}?surveyId=${documentId(survey)}&surveyConf=${GENDERSCORE_SURVEY_50PLUS_V3_VALUE}`);
	};

	const actionsCol = {
		title: 'Staff actions',
		fixed: 'right',
		removeInColumnFilter: true,
		render: (value, row) => {
			const ActionMenuItem = ({
				title,
				onClick,
				Icon,
				children,
			}) => (
				<div onClick={onClick}>
					<Icon style={{
						marginBottom: -5,
						fontSize: 10,
						border: '1px solid #ddd',
						padding: 4,
						borderRadius: 4,
					}} />
					&nbsp;
					{children}
				</div>
			);
			const items = [
				{
					key: 'genderScore',
					type: 'group',
					label: 'GenderScore',
					children: [
						{
							key: 'genderScore/details',
							label: (
								<ActionMenuItem
									onClick={() => { handleRedirectGenderScoreSurveyDetailsPage(row); }}
									Icon={FaEye}
								>
										See details of this survey
								</ActionMenuItem>
							),
						},
						{
							key: 'genderScore/details/v3',
							label: (
								<ActionMenuItem
									onClick={() => { handleRedirectGenderScoreSurveyDetailsV3Page(row); }}
									Icon={FaEye}
								>
										See details of this survey (v3)
								</ActionMenuItem>
							),
						},
						{
							key: 'genderScore/see-all',
							label: (
								<ActionMenuItem
									onClick={() => { handleRedirectGenderScoreSurveysPage(row); }}
									Icon={FaBuilding}
								>
										See all surveys for this company
								</ActionMenuItem>
							),
						},
					],
				},

			];

			return (
				<Dropdown menu={{ items }}>
					<Link>Actions <BiChevronDown style={{ marginBottom: -2 }}/></Link>
				</Dropdown>
			);
		},
		...TableColumns.columnFixedWidth(120),
	};

	const columns = [
		TableColumns.companyStubColumn(theme, {
			fixed: 'left',
			render: (value, row) => (
				row.company
					? <CompanyStub company={row.company} />
					: row?.companyInfo?.name
			),
			customOptions: { searchFields: [{ label: 'Company name', value: 'companyInfo.name' }] },
			colWidth: 160,
		}),
		TableColumns.userAvatarColumn(theme, {
			title: <>By</>,
			...TableColumns.columnMinWidth(44),
		}),
		TableColumns.jsonColumn(theme),
		{
			title: 'Completion',
			dataIndex: 'submittedAt',
			key: 'submittedAt',
			sorter: true,
			removeInColumnFilter: true,
			render: (v, row) => {
				return (
					<>
						{row?.completionTime && (
							<div>
								Completed in <b>{(() => {
									let completionTime = row?.completionTime;
									if (completionTime < 60) return <>{completionTime}s</>;
									completionTime /= 60;
									if (completionTime < 60) return <>{Math.ceil(completionTime)}min</>;
									completionTime /= 60;
									if (completionTime < 24) return <>{Math.ceil(completionTime)}h</>;
									completionTime /= 24;
									return <>{Math.ceil(completionTime)}d</>;
								})()}</b>
							</div>
						)}
						{row?.startedAt && (
							<div>
								Started: <b>{TableColumns.dateColumn(theme).render(row?.startedAt)}</b>
							</div>
						)}
						{row?.submittedAt && (
							<div>
								Submitted: <b>{TableColumns.dateColumn(theme).render(row?.submittedAt)}</b>
							</div>
						)}
					</>
				);
			},
			...TableColumns.columnFixedWidth(140),
		},
		{
			title: 'Survey',
			dataIndex: 'survey',
			align: 'center',
			removeInColumnFilter: true,
			render: (value, row) => getGenderScoreSurveyTag(value),
			...TableColumns.columnFixedWidth(70),
		},
		{
			title: 'Status',
			dataIndex: 'status',
			align: 'center',
			removeInColumnFilter: true,
			render: (value, row) => getGenderScoreStatusTag(value),
			...TableColumns.columnFixedWidth(90),
		},
		buildScoresCol({
			title: (
				<Tooltip
					title={(
						<>
							GenderScore <b>currently published</b> to candidates (if available)
						</>
					)}
					style={{ userSelect: 'none' }}
				>
					GS<br />(Published)
				</Tooltip>
			),
			score: (row) => row?.company?.genderScore,
			origin: 'GS published',
		}),
		// buildScoresCol({
		// 	title: (
		// 		<Tooltip
		// 			title={(
		// 				<>
		// 					GenderScore as computed and extracted from <b>Outgrow</b> (if available)
		// 				</>
		// 			)}
		// 			style={{ userSelect: 'none' }}
		// 		>
		// 			GS<br />(Outgrow)
		// 		</Tooltip>
		// 	),
		// 	score: (row) => (row?.isOutgrow ? row?.genderScoreOutgrow : null),
		// 	diff: (row) => (row?.isOutgrow ? sanitizeGenderScoreObj(row?.company?.genderScore) : null),
		// }),
		// buildScoresCol({
		// 	title: (
		// 		<Tooltip
		// 			title={(
		// 				<>
		// 					GenderScore as computed upon <b>submission</b> of the answers,
		// 					and stored in the <b>company-genderscore-survey</b> collection
		// 				</>
		// 			)}
		// 			style={{ userSelect: 'none' }}
		// 		>
		// 			GS<br />(Survey)
		// 		</Tooltip>
		// 	),
		// 	score: (row) => row?.genderScore,
		// 	diff: (row) => (row?.isOutgrow ? sanitizeGenderScoreObj(row?.company?.genderScore) : null),
		// 	// diff: (row) => sanitizeGenderScoreObj(
		// 	// 	row?.isOutgrow
		// 	// 		? row?.genderScoreOutgrow
		// 	// 		: row?.company?.genderScore,
		// 	// ),
		// }),
		// buildScoresCol({
		// 	title: (
		// 		<Tooltip
		// 			title={(
		// 				<>
		// 					GenderScore as computed from the <b>server's scoring formula</b>,
		// 					upon fetching this document from the database
		// 				</>
		// 			)}
		// 			style={{ userSelect: 'none' }}
		// 		>
		// 			GS<br />(Server)
		// 		</Tooltip>
		// 	),
		// 	score: (row) => row?.genderScore?.server,
		// 	diff: (row) => sanitizeGenderScoreObj(row?.genderScore),
		// }),
		buildGSAnswersCol({
			theme,
		}),
		buildScoresCol({
			title: (
				<Tooltip
					title={(
						<>
							GenderScore as computed from the <b>client's scoring formula</b>,
							upon receiving this document from the database
						</>
					)}
					style={{ userSelect: 'none' }}
				>
					GS<br />(Client)
				</Tooltip>
			),
			scoreConf: (row) => ({
				surveyConf: SURVEYS.getSurveyDataOption(row?.survey),
				answers: row?.answers,
				origin: 'buildScoresCol - GS client',
			}),
			origin: 'GS client',
			// diff: (row) => sanitizeGenderScoreObj(row?.genderScore?.server),
		}),
		// buildScoresCol({
		// 	title: (
		// 		<Tooltip
		// 			title={(
		// 				<>
		// 					GenderScore as computed from the <b>server's scoring formula</b>,
		// 					upon fetching this document from the database
		// 				</>
		// 			)}
		// 			style={{ userSelect: 'none' }}
		// 		>
		// 			GS<br />(Server)
		// 		</Tooltip>
		// 	),
		// 	score: (row) => row?.genderScore?.server,
		// 	diff: (row) => sanitizeGenderScoreObj(row?.genderScore),
		// }),
		buildGSAnswersCol({
			theme, title: <>Answers<br/>v3</>, dataIndex: 'answersV3',
		}),
		buildScoresCol({
			title: (
				<Tooltip
					title={(
						<>
							GenderScore as computed from the <b>client's scoring formula</b>,
							upon receiving this document from the database
						</>
					)}
					style={{ userSelect: 'none' }}
				>
					GS<br />v3
				</Tooltip>
			),
			scoreConf: (row) => ({
				surveyConf: GENDERSCORE_SURVEY_50PLUS_V3_DATA_OPTION,
				answers: row?.answersV3,
			}),
			diffConf: (row) => ({
				surveyConf: SURVEYS.getSurveyDataOption(row?.survey),
				answers: row?.answers,
			}),
			origin: 'GS v3',
			// prepareScore: (row) => { ...row}
			// score: (row) => SCORING.computeSurveyScores({
			// 	surveyConf: GENDERSCORE_SURVEY_50PLUS_V3_DATA_OPTION,
			// 	answers: row?.answersV3,
			// }),
			// diff: (row) => SCORING.computeSurveyScores({
			// 	surveyConf: SURVEYS.getSurveyDataOption(row?.survey),
			// 	answers: row?.answers,
			// }),
		}),
		// ...Object.entries(QUESTIONS)
		// 	.map(([questionId, question]) => ({
		// 		title: question.shortLabel,
		// 		render: (value, row) => {
		// 			return JSON.stringify(row?.answers?.[questionId] || {});
		// 		},
		// 	})),
		buildGSAnswersCol({
			theme, title: <>Answers<br/>v3 (MAX)</>, dataIndex: 'answersV3Max',
		}),
		buildScoresCol({
			title: (
				<Tooltip
					title={(
						<>
							GenderScore as computed from the <b>client's scoring formula</b>,
							upon receiving this document from the database
						</>
					)}
					style={{ userSelect: 'none' }}
				>
					GS<br />v3 (MAX)
				</Tooltip>
			),
			scoreConf: (row) => ({
				surveyConf: GENDERSCORE_SURVEY_50PLUS_V3_DATA_OPTION,
				answers: row?.answersV3Max,
			}),
			diffConf: (row) => ({
				surveyConf: SURVEYS.getSurveyDataOption(row?.survey),
				answers: row?.answers,
			}),
			origin: 'GS v3 (max)',
		}),
		actionsCol,
	];

	return (
		<>
			<Table
				path={path}
				onDataLoaded={onDataLoaded}
				columns={columns}
				filterOptions={filterOptions}
				isRefresh={isRefresh}
				onSearchDataColumn={handleSearchAutoComplete}
				withDynamicSearch
				defaultColumnsOptions={defaultColumnsOptions}
			/>
		</>
	);
};

export default CompanyGenderScoreSurveyTable;
