import { useTheme } from '@emotion/react';
import { Tag } from 'antd';

const UserProfileTag = (
	{
		tagStyle,
		user,
		size = 'medium',
		children,
		...props
	},
) => {
	const theme = useTheme();

	return <Tag
		color='blue'
		style={{
			fontSize: 14,
			margin: 0,
			marginRight: 0,
			...tagStyle,
		}}
		{...props}
	>
		{children}
	</Tag>;
};

export default UserProfileTag;
