import { useAuthContext } from '../../../hooks/useAuthContext';
import { hexToRgb } from '../../../utils/common';
import { useTheme } from '@emotion/react';

const BlockBase = ({
	value = false,
	color = 'red',
	tags,
	withStaffView = true,
	withTags = true,
	withTagAsIcon = false,
	withBorder = true,
	tagStyle,
	children,
	...props
}) => {
	const theme = useTheme();
	const { isStaffView } = useAuthContext();
	if (!value || (!isStaffView && withStaffView)) return null;

	return (
		<div
			{...props}
			style={{
				position: 'relative',
				...withBorder && {
					border: `1px dotted rgba(${[...hexToRgb(theme?.color?.[color] || color) || [], 0, 0, 0].slice(0, 3).join(',')}, .2)`,
					borderRadius: 5,
				},
				...props.style,
			}}
		>
			{children}
			{withTags
				&& <div
					style={{
						position: 'absolute',
						top: -5,
						left: 1,
						opacity: 0.7,
						...tagStyle,
					}}
				>{tags}</div>}
		</div>
	);
};

export default BlockBase;
