import { useTheme } from '@emotion/react';
import { Space } from 'antd';
import GenderScoreTitleBlock from './GenderScoreTitleBlock';
import GenderScoreComparisonLineBlock from './GenderScoreComparisonLineBlock';
import GenderScoreNotProvidedBlock from './GenderScoreNotProvidedBlock';
import RateGraph from '../graphs/RateGraph';

/**
 * @param {string} title - Graph title
 * @param {number} current - Current rate value.
 * @param {number} previous - Optional. Previous rate value.
 * @param {boolean} [withPrevious] - Optional. Whether to show the previous value comparison.
 * Default: true
 * @param {*} [graphColors] - Optional. Format: [from, to, bg]. If not set, use theme.chart.rate
 * @param {number} [width] - Optional. Width of component. Default: 170
 * @param {number} [lineWidth] - Optional. Width of the circumference. Default: 10
 * @param {number} [fontSize] - Optional. Font size of the label. Default: 36
 * @returns
 */
const GenderScoreRateBlock = ({
	title,
	current,
	previous,
	existsPreviousSurvey,
	withPrevious = true,
	width = 160,
	lineWidth = 3,
	fontSize = 36,
	isPct = true,
	graphColors,
}) => {
	const className = 'GenderScoreRateBlock';

	const theme = useTheme();
	let content;
	if (isNaN(current)) {
		content = <>
			<GenderScoreNotProvidedBlock width={width}/>
		</>;
	} else {
		content = <>
			<RateGraph
				value={current}
				width={width}
				fontSize={fontSize}
				lineWidth={lineWidth}
				graphColors={graphColors}
				style={{ margin: '5px 0px' }}
			/>

			{withPrevious && <GenderScoreComparisonLineBlock
				previous={previous}
				current={current}
				isPct={isPct}
				existsPreviousSurvey={existsPreviousSurvey}
			/>
			}
		</>;
	}
	return <Space
		direction='vertical'
		style={{
			width: '100%',
		}}>
		<GenderScoreTitleBlock
			title={title}
			isMainTitle={false}
		/>
		<div style={{
			display: 'grid',
			justifyContent: 'center',
		}}>
			{content}
		</div>
	</Space>;
};
export default GenderScoreRateBlock;
