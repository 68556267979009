import { NOT_PROVIDED_YET_LABEL } from '../../../../constants/constant';
import UserTagsList from '../UserTagsList';

const UserProfileDEIFitCardBody = (
	{
		user,
		notProvided = NOT_PROVIDED_YET_LABEL,
		size = 'medium',
		...props
	},
) => {
	return (
		<UserTagsList
			values={user?.getProfileDEICriteria()}
			user={user}
			size={size}
			notProvided={notProvided}
		/>
	);
};

export default UserProfileDEIFitCardBody;
