/** @jsxImportSource @emotion/react */

/**
 * Displays information about the Meta tags fields
 * If the tags are translatable, it displays a disclaimer explaining the active language
 * It warns the user about the behaviour of the form when empty fields are left
 */
const MetaDivider = ({ isEnVersion, isTranslatable }) => {
	return (
		<>
			{
				isTranslatable
              && <p css={{
              	display: 'flex', alignItems: 'center', gap: 10, background: '#f5f5f5', padding: 5, borderRadius: 5,
              }}>
              	{
              		isEnVersion ? (
              			<>
              				<span>🇬🇧</span>
              				<span>
                        The content below is <strong>translatable</strong>. Now you are
                        translating the <strong>English version</strong>.
              				</span>
              			</>
              		) : (
              			<>
              				<span>🇫🇷</span>
              				<span>
                          Le contenu ci-dessous est <strong>traduisible</strong>, vous éditez
                          actuellement sa <strong>version française</strong>.
              				</span>
              			</>
              		)
              	}
              </p>
			}
			<p css={{
				display: 'flex', alignItems: 'center', gap: 10, background: '#f5f5f5', padding: 5, borderRadius: 5,
			}}>
				{
					isEnVersion ? (
						<>
							<span>❗</span>
							<span>
                If you leave any field <strong>empty</strong> , the <strong>default</strong> value will be used.
							</span>
						</>
					) : (
						<>
							<span>❗</span>
							<span>
                  Si vous laissez un champ <strong>vide</strong>, la valeur par <strong>défaut</strong> sera utilisée
							</span>
						</>
					)
				}
			</p>
		</>
	);
};
export default MetaDivider;
