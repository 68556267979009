/** @jsxImportSource @emotion/react */

import ReactPlayer from 'react-player';
import { useTheme } from '@emotion/react';
import { videoUrlParse } from '../../../../../../utils/common';

const classes = (theme) => ({
	'position': 'relative',
	':after': {
		content: '""',
		position: 'absolute',
		top: -10,
		left: -20,
		width: 'calc(100% + 40px)',
		height: 'calc(100% + 20px)',
		border: '3px dashed rgba(255, 99, 91, 0.5)',
		borderRadius: 20,
	},
	'iframe': {
		height: '360px',
		paddingTop: 0,
		width: '107% !important',
	},
	'div:first-of-type': {},
	'.html-content': {
		fontSize: '18px',
		textAlign: 'left',
	},
	'readButton': {
		'width': '100%',
		'backgroundColor': theme.color.accent,
		'color': theme.color.white,
		'borderRadius': '8px',
		'fontSize': '14px',
		'&:hover': {
			backgroundColor: theme.color.accentLight,
			color: theme.color.white,
		},
		'&:active': {
			backgroundColor: theme.color.accentLight,
			color: theme.color.white,
		},
		'&:focus': {
			backgroundColor: theme.color.accentLight,
			color: theme.color.white,
		},
		'margin': '65px 5px 50px 5px',
		'padding': '0 24px',
		'minHeight': '52px',
	},
});

function LearnDetailContentLogged({ article }) {
	const theme = useTheme();
	const styles = classes(theme);
	return (
		<div css={{}}>
			{article?.contentLogged && (
				<div css={classes}>
					{videoUrlParse(article.contentLogged) && (
						<ReactPlayer
							controls
							url={videoUrlParse(article.contentLogged || '')}
						/>
					)}

					<div
						className="html-content"
						dangerouslySetInnerHTML={{ __html: article.contentLogged || '' }}
					 />
				</div>
			)}
		</div>
	);
}

export default LearnDetailContentLogged;
