/** @jsxImportSource @emotion/react */

import {
	Button, Segmented, Space,
} from 'antd';
import { buildCloudinaryUrl } from '../../../../../utils/common';
import { useEffect, useState } from 'react';
import GenderScoreResourceImage from '../../../../company/genderscore/resources/GenderScoreResourceImage';
import { LocalDebug } from '../../../../../utils/LocalDebug';
import CloudinaryImageUploader from '../../../../app/CloudinaryImageUploader';
import { UploadOutlined } from '@ant-design/icons';
import { useTheme } from '@emotion/react';

function FieldItemUpload({
	resource,
	fieldName,
	lang,
	onChange,
	refresh,
}) {
	const className = 'FieldItemUpload';

	const theme = useTheme();

	const [imageUrl, setImageUrl] = useState(resource?.getField(fieldName, lang));

	// LocalDebug.logInfo({ className }, { imageUrl, lang }, resource);

	const label = resource?.getLabel(fieldName, lang);
	const name = resource?.getFieldName(fieldName, lang);

	const onUpload = () => {
		// LocalDebug.logUseEffect(
		// 	{ className, method: 'onUpload' },
		// 	{ 'uploader.cloudinaryPublicId': uploader.cloudinaryPublicId },
		// );
		if (uploader.cloudinaryPublicId) {
			const url = buildCloudinaryUrl(uploader.cloudinaryPublicId);
			setImageUrl(url);
			onChange({ [name]: url });
		}
	};

	const uploader = new CloudinaryImageUploader({
		folder: 'resources',
		uploadPreset: 'resources',
		onSuccess: onUpload,
	});

	useEffect(() => {
		setImageUrl(resource?.getField(fieldName, lang));
	}, [lang]);

	return (
		<>
			{/* <ResourceDivider isEnVersion={lang === ENGLISH_LANG}/> */}

			<Space
				direction="vertical"
				css={{ width: '100%' }}
			>
				<label
					className='form-label'
					style={{
						color: theme.color.blue,
						fontWeight: 600,
					}}
				>
					{label}
				</label>

				<GenderScoreResourceImage
					resource={{
						...resource,
						image: imageUrl,
					}}
					width={480}
					height={270}
					style={{
						width: 480,
						height: 270,
					}}
				/>

				<Space size={30}>
					<Button
						type='primary'
						icon={<UploadOutlined/>}
						color={theme.color.accent}
						onClick={() => { uploader.showWidget(); }}
					>
						Upload image
					</Button>

					<Space>
						<>Content display:</>
						<Segmented
							defaultValue={JSON.stringify({ options: resource?.options || {} })}
							options={[
								{
									value: JSON.stringify({ options: { image: { crop: 'fit' } } }),
									label: 'Fit content',
								},
								{
									value: JSON.stringify({ options: {} }),
									label: 'Fill content',
								},
							]}
							onChange={(value) => onChange(JSON.parse(value))}
						/>
					</Space>
				</Space>

			</Space>

		</>
	);
}

export default FieldItemUpload;
