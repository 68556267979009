import { ENGLISH_LANG } from '../../../../constants/constant';
import { displayHtml } from '../../../../utils/common';
import CompanyFieldNotProvided from '../shared/CompanyFieldNotProvided';

const CompanyBenefitsDisplay = (
	{
		company,
		lang = ENGLISH_LANG,
	},
) => {
	const className = 'CompanyBenefitsDisplay';

	const field = 'benefits';
	const value = company?.getTranslationFieldFallback(field, lang);

	const content = value
		? displayHtml(value)
		: <CompanyFieldNotProvided/>;

	return (
		<>
			<h2>Benefits & Perks</h2>
			{content}
		</>
	);
};

export default CompanyBenefitsDisplay;
