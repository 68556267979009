import moment from 'moment';
import { Alert } from 'antd';
import UserAvatar from '../UserAvatar';
import UserModel from '../../../../models/UserModel';

const UserResumeLatestInfo = (
	{
		user,
		withDelay = true,
		withDate = true,
		withUploader = true,
	},
) => {
	return (new UserModel(user)?.hasResume()
        && <Alert type="info"
        	style={{ fontSize: 12, padding: '3px 12px' }}
        	message={<>
        		{/* <Space direction='horizontal' wrap={false}> */}
        		<span>Last upload</span>
        		{withDelay && <span>&nbsp;{moment(user?.resume?.updatedAt).fromNow()}</span>}
        		{withDate && <span>&nbsp;on {moment(user?.resume?.updatedAt).format('MMMM Do YYYY')}</span>}
        		{
        			withUploader && user?.resume?.uploader && <>
        				<span>&nbsp;by&nbsp;</span>
        				<div style={{ display: 'inline-block' }}>
        					<UserAvatar
        						user={user?.resume?.uploader}
        						size={24}
        						withTooltip={true}
        						withIsNew={false}
        						withIsUnviewed={false}
        					/>
        				</div>
        			</>
        		}
        		{/* </Space> */}
        	</>
        	}
        />
	);
};

export default UserResumeLatestInfo;
