import { FaRegCopy } from 'react-icons/fa';
import Link from '../../app/Link';
import { message, Tooltip } from 'antd';

const UserLinkSlugCopy = (
	{
		user,
		label,
		withLabel = true,
		iconPlacement = 'left',
		...props
	},
) => {
	if (!user) {
		return null;
	}

	return (
		<Tooltip title={`Copy this user's slug (${user.slug}) in your clipboard`}>
			<Link
				onClick={() => {
					navigator.clipboard.writeText(user.slug);
					message.success(<>User slug <b>{user.slug}</b> copied to clipboard</>);
				}}
				style={{ fontSize: 11, ...props?.style }}
			>
				{iconPlacement === 'left' && (
					<>
						<FaRegCopy style={{ marginBottom: -2 }} />
						{label && <>&nbsp;</>}
					</>
				)}
				{label}
				{iconPlacement === 'right' && (
					<>
						{label && <>&nbsp;</>}
						<FaRegCopy style={{ marginBottom: -2 }} />
					</>
				)}
			</Link>
		</Tooltip>
	);
};

export default UserLinkSlugCopy;
