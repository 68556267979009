import { useTheme } from '@emotion/react';
import { useAuthContext } from '../../../../hooks/useAuthContext';
import { Button } from 'antd';
import { useNavigate } from 'react-router';

const GenderScoreDashboardEmpty = ({
}) => {
	const className = 'GenderScoreDashboardEmpty';

	const theme = useTheme();
	const { currentUser } = useAuthContext();
	const navigate = useNavigate();
	return (<>
		<div
			style={{
				padding: 24,
				margin: '0 auto',
			}}
		>

			<div
				style={{
					fontSize: 32,
					color: 'black',
					fontWeight: 'bold',
					marginBottom: 30,
				}}
			>
			🌟 Welcome back {currentUser?.firstName}!
				<br/>Are you ready to pass the GenderScore Survey?
			</div>
			<div
				style={{
					display: 'grid',
					justifyContent: 'center',
					gap: 16,
					padding: 16,
					marginTop: '15%',
				}}>
				<div
					style={{
						display: 'grid',
						justifyContent: 'center',
						textAlign: 'center',
						gap: 8,
					}}>
					<span style={{
						fontSize: 16,
						fontWeight: 500,
					}}>
					You haven't passed your GenderScore yet
					</span>
					<span style={{
						fontSize: 14,
						fontWeight: 400,
					}}>
	To access all your data and our expertise let's get started today!
					</span>
				</div>
				<div
					style={{
						display: 'flex',
						justifyContent: 'center',
					}}>
					<Button
						type='primary'
						onClick={() => {
							navigate('/genderscore/survey');
						}}
						style={{
							width: 260,
							height: 30,
							padding: '0px 16px',
						}}
					>
						<strong>
						Start your GenderScore Survey
						</strong>
					</Button>
				</div>
			</div>

		</div>
	</>
	);
};

export default GenderScoreDashboardEmpty;
