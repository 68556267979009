import { LocalDebug } from '../utils/LocalDebug';
import { documentId } from '../utils/common';
import { request } from './api';

const className = 'ums-service';
// const classLogger = ({ method }, ...args) => LocalDebug.logInfo({ className, method }, ...args);

const getAdminStatus = async ({ params }) => {
	// classLogger({ method: 'getAdminStatus' }, { params });
	return request.get('/ums/status/admin', { params });
};

const getManagerStatus = async ({ params }) => {
	// classLogger({ method: 'getAdminStatus' }, { params });
	return request.get('/ums/status/manager', { params });
};

const inviteRole = async ({ data }) => {
	// classLogger({ method: 'inviteRole' }, { data });
	return request.post('/ums/team/invite', data);
};

const getRoles = async ({ params } = {}) => {
	// classLogger({ method: 'getRoles' }, { params });
	return request.get('/ums/team', { params });
};

// const createRole = async ({ data }) => {
// 	return request.post('/ums/team', { data });
// };

const updateRole = ({ role, data }) => {
	// classLogger({ method: 'updateRole' }, { role, data });
	return request.put(`/ums/team/${documentId(role) || role}`, data);
};

const removeRole = ({ role }) => {
	// classLogger({ method: 'removeRole' }, { role });
	return request.delete(`/ums/team/${documentId(role) || role}`);
};

const notifyRole = ({ role }) => {
	// classLogger({ method: 'notifyRole' }, { role });
	return request.post(`/ums/team/${documentId(role) || role}/notify`, role);
};

const renotifyRole = ({ role }) => {
	// classLogger({ method: 'renotifyRole' }, { role });
	return request.put(`/ums/team/${documentId(role) || role}/renotify`, role);
};

const getResults = async ({ params } = {}) => {
	// classLogger({ method: 'getResults' }, { params });
	return request.get('/ums/results', { params });
};

const UMSService = {
	getAdminStatus,
	getManagerStatus,
	getResults,
	getRoles,
	inviteRole,
	// createRole,
	updateRole,
	removeRole,
	notifyRole,
	renotifyRole,
};

export default UMSService;
