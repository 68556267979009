import { useTheme } from '@emotion/react';
import { FireOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Flex } from 'antd';
import CompanyActionModel from '../../../../models/CompanyActionModel';
import Box from '../../../app/box/Box';
import CompanyActionComplexityTag from '../CompanyActionComplexityTag';
import CompanyActionBudgetTag from '../CompanyActionBudgetTag';
import CompanyActionPeopleTag from '../CompanyActionPeopleTag';
import CompanyActionPillarTag from '../CompanyActionPillarTag';
import CompanyActionCompletionStatusMgr from './CompanyActionCompletionStatusMgr';
import CompanyActionTimeframeMgr from './CompanyActionTimeframeMgr';

const CompanyActionContentLeftSide = ({
	action,
	withActionManager,
	withAddButton,
	onAddAction,
	onEditAction,
	onDeleteAction,
	isSendActionPending = false,
	setIsSendActionPending,
	...props
}) => {
	const className = 'CompanyActionContentLeftSide';

	const theme = useTheme();

	action = action instanceof CompanyActionModel
		? action
		: new CompanyActionModel(action);

	return (
		<div
			className={[
				'left-side',
				'color-container',
				// 'action-card',
				...action?.pillar || [],
				action.completionStatus,
				action.timeframe,
			].join(' ')}
			style={{
				width: '100%',
				...props?.style,
			}}
		>
			<div className='subbox divider'>
				<div className='action-label'>
					<FireOutlined /> ACTION
				</div>
				<div className='action-title'>
					{action.title}
				</div>
			</div>

			<div className='subbox divider'>
				<div
					style={{
						fontSize: 11,
						color: 'rgba(0,0,0,.5)',
						marginBottom: 4,
					}}
				>
					<span>This action is part of the following categories and pillars:</span>
				</div>
				<CompanyActionPillarTag action={action} />
			</div>

			<div className='subbox'>
				<Flex
					gap={10}
				>
					{[
						{
							title: 'Complexity',
							TagComponent: CompanyActionComplexityTag,
						},
						{
							title: 'Budget',
							TagComponent: CompanyActionBudgetTag,
						},
						{
							title: 'People',
							TagComponent: CompanyActionPeopleTag,
						},
					].map(({ title, TagComponent }, tagIndex) => (
						<Flex
							key={tagIndex}
							vertical={true}
							width={'100%'}
							justify='space-between'
							align='flex-start'
							style={{
								background: '#f5f5f5',
								background: 'rgba(255,255,255,.5)',
								color: 'rgba(0,0,0,.7)',
								borderRadius: 6,
								padding: '3px 8px',
							}}
						>
							<span
								style={{
									fontSize: 12,
									color: 'rgba(0,0,0,.7)',
								}}
							>
								{title}
							</span>
							<div
								style={{
								}}
							>
								<TagComponent action={action} />
							</div>
						</Flex>
					))}
				</Flex>
			</div>

			{withAddButton && (
				<div
					className='subbox'
					style={{
						marginTop: 20,
					}}
				>
					<Button
						disabled={isSendActionPending}
						type='primary'
						size='large'
						onClick={() => onAddAction?.({ action })}
						style={{
							width: '100%',
							borderRadius: 6,
							marginTop: 12,
						}}
					>
						<b><PlusOutlined /> Add to plan</b>
					</Button>
				</div>
			)}

			{withActionManager && (
				<div
					className='subbox'
					style={{
						marginTop: 20,
					}}
				>
					<CompanyActionCompletionStatusMgr
						action={action}
						onEditAction={onEditAction}
						isSendActionPending={isSendActionPending}
						setIsSendActionPending={setIsSendActionPending}
					/>

					<CompanyActionTimeframeMgr
						action={action}
						onEditAction={onEditAction}
						isSendActionPending={isSendActionPending}
						setIsSendActionPending={setIsSendActionPending}
					/>

					<Button
						disabled={isSendActionPending}
						// type='danger'
						danger
						style={{
							float: 'right',
							// width: '100%',
							borderRadius: 6,
							marginTop: 12,
						}}
						onClick={() => onDeleteAction({ action })}
					>
						<b>Remove</b>
					</Button>
				</div>
			)}

		</div >
	);
};

export default CompanyActionContentLeftSide;
