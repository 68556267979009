import {
	Col, Divider, Row, Space, Spin, Tag,
} from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { useTheme } from '@emotion/react';
import { FaSync } from 'react-icons/fa';
import Link from '../../app/Link';
import { getCompanies, getCompanySelected } from '../../../reducers/app';
import ReactJsonDebug from '../../app/debug/ReactJsonDebug';
import { useAuthContext } from '../../../hooks/useAuthContext';
import AdminService from '../../../services/admin';
import CompanyLogoList from '../../company/CompanyLogoList';
import ListLimiter from '../../company/ListLimiter';
import { documentId } from '../../../utils/common';

const ProductJobLocations = (props) => {
	const companies = useSelector(getCompanies);
	const companySelected = useSelector(getCompanySelected);

	const theme = useTheme();
	const dispatch = useDispatch();
	const { isStaff } = useAuthContext();
	const [isLoading, setIsLoading] = useState(true);
	const [data, setData] = useState({});
	const [refreshData, setRefreshData] = useState(0);

	useEffect(async () => {
		setIsLoading(true);
		const { data: { results } } = await AdminService.getJobFields({ companyId: documentId(companySelected) });
		setData(results);
		setIsLoading(false);
	}, [refreshData]);

	if (!isStaff) return null;

	return (
		<>
			<h2>Job locations</h2>
			<p>
				<Link
					style={{ marginBottom: 20 }}
					onClick={() => setRefreshData((p) => p + 1)}
				>
					<FaSync style={{ marginBottom: -2 }}/> <b>Refresh</b>
				</Link>
			</p>

			{isLoading
				? <>
					<Spin/>
				</>
				: (
					<>
						<ReactJsonDebug src={{ data }} sortKeys={false} collapsed={0} style={{ marginBottom: 10 }}/>

						<div style={{ marginTop: 20 }}>

							{data?.locations?.slice(0, 500)?.map((loc, index) => {
								return (
									<>
										<Row gutter={[20, 10]}>
											<Col span={1}>
												<span
													style={{
														fontSize: 16,
														fontWeight: 'bold',
														color: theme.color.grey,
													}}
												>
													{loc.count}
												</span>
											</Col>
											<Col span={5}>
												<span
													style={{
														fontSize: 16,
														fontWeight: 'bold',
													}}
												>
													{loc.key}
												</span>
											</Col>
											<Col span={5}>
												<ListLimiter data={loc.cities}
															 size={22}
															 overflow={2}
															 builder={({ data }) => <Tag
																 style={{ marginRight: 0 }}>{data}</Tag>}/>
											</Col>
											<Col span={5}>
												<ListLimiter data={loc.countries}
															 size={22}
															 overflow={2}
															 builder={({ data }) => <Tag
																 style={{ marginRight: 0 }}>{data}</Tag>}/>
											</Col>
											<Col span={8}>
												<Space wrap={true}>
													<CompanyLogoList
														limit={10}
														overflow={1}
														size={28}
														companies={loc?.companyIds?.map((lc) => companies?.find((c) => documentId(c) === lc))}
													/>
												</Space>
											</Col>
										</Row>
										<Divider style={{ margin: 5 }}/>
									</>
								);
							})}
						</div>
						{/* {data?.tagLocations?.map((loc, index) => { */}
						{/*	return ( */}
						{/*		<Row> */}
						{/*			<Col span={2}> */}
						{/*				{loc.count} */}
						{/*			</Col> */}
						{/*			<Col span={22}> */}
						{/*				{loc.key} */}
						{/*			</Col> */}
						{/*		</Row> */}
						{/*	) */}
						{/* })} */}
					</>

			// 	data?.map((user, index) => {
			// 	const userModel = new UserModel(user);
			// 	return (
			// 		<>
			// 			<Row style={{ width: '100%' }} key={index} wrap={false}>
			// 				<Col span={8} style={{ padding: 10 }}>
			// 					<UserStub user={user} withDropdown={true} withIsNew={false} withIsUnviewed={false}/>
			// 				</Col>
			// 				<Col span={8}
			// 					 style={{ padding: 10, fontSize: 20, fontWeight: 'bold', lineHeight: 1.2 }}>
			// 					<div>
			// 						{userModel?.getSalaryAskRangeLine() ||
			// 							<span style={{ color: 'red' }}>???</span>}
			// 					</div>
			// 					<div style={{ opacity: .5 }}>
			// 						{user?.coaching?.coachingSalaryExpectations}
			// 					</div>
			// 				</Col>
			// 				<Col span={8} style={{ padding: 10 }}>
			// 					<ReactJsonDebug src={user?.jobSearch?.salaryAsk} collapsed={0} title={''}/>
			// 				</Col>
			// 			</Row>
			// 			<Divider style={{ margin: 5 }}/>
			// 		</>
			// 	);
			// })}
				)
			}
		</>
	);
};

export default ProductJobLocations;
