import { useDispatch } from 'react-redux';
import { buildConfirmLink } from '../../../utils/common';

const StaffCommandLink = (
	{
		title,
		path,
		params,
		dispatcher,
		tooltip,
		disabled,
		callback,
		style,
	},
) => {
	const dispatch = useDispatch();

	return (
		buildConfirmLink({
			title,
			path,
			params,
			dispatcher,
			dispatch,
			tooltip,
			disabled,
			callback,
			style,
		})
	);
};

export default StaffCommandLink;
