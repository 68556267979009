import { Divider, Space } from 'antd';
import { useContext, useEffect, useState } from 'react';
import { arrayWrap } from '../../utils/common';
import { MESSAGES_ICON, SOURCING_OPTION_ARCHIVED } from '../../constants/constant';
import { CandidateContext } from '../../contexts/CandidateProvider';
import CandidateSelectorPipelineLargeButton from './CandidateSelectorPipelineLargeButton';
import { LocalDebug } from '../../utils/LocalDebug';

const CandidatePipelineSelector = (
	{
		initialStage,
		atsPipelines,
		atsArchiveReasons,
		onStageSelected,
		withStageSelector = false,
		withArchivedSelector = false,
		withContactedSelector = false,
		...props
	},
) => {
	const className = 'CandidatePipelineSelector';

	const {
		candidateCounts,
		candidatePipeline, candidatePipelines, candidateStages, isArchived, archiveReasons,
		updatePipeline, updateStages, updateIsArchived, updateArchiveReasons,
		updateSelectedOptions, shouldRefresh, contactedSelected, setContactedSelected, contactedCount,
	} = useContext(CandidateContext);
	const [pipelineOptions, setPipelineOptions] = useState([]);
	const [archiveOption, setArchiveOption] = useState(atsArchiveReasons);

	const CONTACTED_TAB = {
		icon: MESSAGES_ICON,
		count: contactedCount,
		label: 'Contacted',
		value: 'contacted',
	};

	useEffect(() => {
		const logVars = { className, effects: 'candidateCounts,shouldRefresh' };
		// LocalDebug.logUseEffect(logVars, { candidateCounts, shouldRefresh, candidatePipeline, candidateStages, isArchived, archiveReasons });
		setPipelineOptions(
			(atsPipelines || [])
				.map((option) => ({
					...option,
					count: candidateCounts?.candidatePipeline?.[option.value] || 0,
				}))
				.filter((option) => !option.hideIfEmpty || option.count > 0),
		);

		setArchiveOption({
			...SOURCING_OPTION_ARCHIVED,
			count: candidateCounts?.isArchived,
		});
	}, [
		atsPipelines,
		candidateCounts,
		shouldRefresh,
	]);

	const isPipelineSelected = (p) => {
		return !contactedSelected && (
			p.value === candidatePipeline?.value || arrayWrap(candidatePipelines)?.includes(p.value)
		);
	};
	const selectPipeline = (pipeline) => {
		LocalDebug.logInfo({ className, method: 'onClick' }, pipeline);
		if (!isPipelineSelected(pipeline)) {
			// Select the pipeline
			updateSelectedOptions({
				pipeline,
				pipelines: [pipeline],
				stages: [],
				isArchived: false,
			});
			// Unselect contacted
			setContactedSelected(false);
		}
	};
	const selectContacted = () => {
		LocalDebug.logInfo({ className, method: 'onClick' }, CONTACTED_TAB);
		// Select contacted
		setContactedSelected(true);
		// Unselect all pipelines
		updateSelectedOptions({
			pipelines: [],
			stages: [],
			isArchived: false,
		});
	};

	const selectArchived = () => {
		setContactedSelected(false);
		updateSelectedOptions({
			pipeline: null,
			pipelines: [],
			stages: [],
			isArchived: true,
		});
	};
	return <>
		{withArchivedSelector
			&& <Space direction='horizontal' style={{ width: '100%', justifyContent: 'space-between' }}>
				{pipelineOptions?.length > 0
					&& <Space direction='horizontal' style={{ margin: '10px 0' }}>
						{withContactedSelector && <CandidateSelectorPipelineLargeButton
							option={CONTACTED_TAB}
							selected={contactedSelected}
							onClick={selectContacted}
						/>
						}
						<Divider type='vertical' style={{
							height: 108,
						}}/>
						{pipelineOptions.map((p, index) => <CandidateSelectorPipelineLargeButton
							key={index}
							option={p}
							selected={isPipelineSelected(p)}
							onClick={() => selectPipeline(p)}
						/>)}
					</Space>}
				{withArchivedSelector && atsArchiveReasons?.length > 0
					&& <CandidateSelectorPipelineLargeButton
						option={archiveOption}
						selected={isArchived}
						onClick={selectArchived}
					/>
				}
			</Space>
		}
	</>;
};

export default CandidatePipelineSelector;
