import { useMemo, useState } from 'react';
import { useTheme } from '@emotion/react';
import { Button, Progress, Space } from 'antd';
import { ArrowRightOutlined, DownloadOutlined, SendOutlined } from '@ant-design/icons';
import { LocalDebug } from '../../../utils/LocalDebug';
import { UMS_LABEL } from '../../../constants/constant';
import { useAuthContext } from '../../../hooks/useAuthContext';
import { openExternalUrl } from '../../../utils/common';
import config from '../../../config/config';
import { useNavigate } from 'react-router';
import UMSSurveyMenu from '../../../menu/ums/UMSSurveyMenu';

const UMSManagerResultsStatusBanner = ({
	isLoading,
	statusData,
}) => {
	const className = 'UMSManagerResultsStatusBanner';

	const theme = useTheme();
	const navigate = useNavigate();

	const { currentUser, isStaffView } = useAuthContext();

	const [bannerStyle, setBannerStyle] = useState({});
	const [bannerContent, setBannerContent] = useState();

	useMemo(() => {
		LocalDebug.logUseEffect({ className, effects: 'memo' }, { isLoading, statusData });

		if (isLoading) {
			setBannerStyle({ background: 'gray' });
			setBannerContent(
				<div><h2>Loading...</h2></div>,
			);
			return;
		}

		if (statusData?.isResultReady) {
			setBannerStyle({ background: theme.background.gradientPurpleGreen() });
			setBannerContent(
				<>
					<div>
						<h2>Results are ready!</h2>
						<p>
							A sufficient number of your employees have filled the survey.
							<br />Your results of the <b>{UMS_LABEL}</b> are now <b>available.</b>
						</p>
						<Button
							type='primary'
							size='large'
							style={{ fontWeight: 'bold ' }}
							onClick={() => {
								let filePath = `Unbiased%20Mgt%20Score%20-%20Manager%20Demo%20(EN).pdf?magic=${new Date().getTime()}`;
								if (isStaffView) {
									filePath = `50inTech%20-%20Unbiased%20Mgt%20Score%20-%20Manager%20Results%20-%20${currentUser.slug}.pdf?magic=${new Date().getTime()}`;
								}
								openExternalUrl(`${config.storagePublicRoot}/ums/demo/${filePath}`);
							}}
						>
							Download your results (PDF) <DownloadOutlined />
						</Button>
					</div>
					<Progress
						type='circle'
						size={80}
						percent={statusData?.resultReadyPercent}
						strokeColor={theme.color.white}
						format={(value) => (
							<span
								style={{
									fontWeight: 'bold',
									color: theme.color.white,
								}}
							>
								{value}<span style={{ fontSize: 16 }}>%</span>
							</span>
						)}
					/>
				</>,
			);
		} else if (!statusData.isManagerCompleteSurvey) {
			setBannerStyle({ background: theme.background.gradientPdf() });
			setBannerContent(
				<>
					<div>
						<h2>Results are not ready yet...</h2>
						<p>
							We received a sufficient number of answers from your employees to calculate your results, but you haven't yet filled your own survey.
						</p>
						<Button
							type='primary'
							size='large'
							style={{ fontWeight: 'bold ' }}
							onClick={() => navigate(UMSSurveyMenu.props.path)}
						>
							Go to my survey&nbsp;<ArrowRightOutlined />
						</Button>
					</div>
					<Progress
						type='circle'
						size={80}
						strokeColor={theme.color.white}
						percent={statusData?.resultReadyPercent}
						format={(value) => (
							<span
								style={{
									fontWeight: 'bold',
									color: theme.color.white,
								}}
							>
								{value}<span style={{ fontSize: 16 }}>%</span>
							</span>
						)}
					/>
				</>,
			);
		} else {
			setBannerStyle({ background: theme.background.gradientPdf() });
			setBannerContent(
				<>
					<div>
						<h2>Results are not ready yet...</h2>
						<p>
							We need a sufficient number of your employees to fill their surveys before being able to calculate your results.
							<br />You can send a reminder to people who have yet to complete their surveys:
						</p>
						<Button
							type='primary'
							size='large'
							style={{ fontWeight: 'bold ' }}
						>
							Send a reminder <SendOutlined />
						</Button>
					</div>
					<Progress
						type='circle'
						size={80}
						strokeColor={theme.color.white}
						percent={statusData?.resultReadyPercent}
						format={(value) => (
							<span
								style={{
									fontWeight: 'bold',
									color: theme.color.white,
								}}
							>
								{value}<span style={{ fontSize: 16 }}>%</span>
							</span>
						)}
					/>
				</>,
			);
		}
	}, [isLoading, statusData]);

	return (
		<Space
			style={{
				justifyContent: 'space-between',
				alignItems: 'center',
				padding: '30px 40px',
				background: theme.color.fitBlueElectric,
				color: theme.color.white,
				width: '100%',
				borderRadius: 20,
				margin: '20px 0',
				...bannerStyle,
			}}
		>
			{bannerContent}
		</Space>
	);
};

export default UMSManagerResultsStatusBanner;
