import { UMS_LABEL_SHORT } from '../../../../constants/constant';
import FormItem from '../../form/FormItem';
import CompanyPlanUMSIsEnabledSwitch from './CompanyPlanUMSIsEnabledSwitch';

export const companyPlanUMSIsEnabledPath = ['companyPlan', 'ums', 'isEnabled'];

const CompanyPlanUMSIsEnabledFormItem = ({
	disabled = false,
}) => {
	const className = 'CompanyPlanUMSIsEnabledFormItem';

	return (
		<FormItem
			label={
				<span
					style={{
						...disabled ? { color: '#ccc' } : {},
					}}
				>
					{UMS_LABEL_SHORT} enabled
				</span>
			}
			name={companyPlanUMSIsEnabledPath}
			valuePropName='checked'
		>
			<CompanyPlanUMSIsEnabledSwitch disabled={disabled} />
		</FormItem>
	);
};

export default CompanyPlanUMSIsEnabledFormItem;
