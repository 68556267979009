import { useEffect, useState } from 'react';
import { useAuthContext } from '../../../hooks/useAuthContext';
import SourcingsTab from '../../sourcings/SourcingsTab';
import { documentId } from '../../../utils/common';
import { SOURCE_50INTECH_VALUE, SOURCE_RECRUITER_VALUE } from '../../../constants/sourcing';

const Sourcings = ({ job }) => {
	const { isTalent } = useAuthContext();

	const [sourcingData, setSourcingData] = useState([]);
	useEffect(() => {
		const data = (job?.sourcings || []).map((sourcing) => ({
			...sourcing,
			company: job?.company,
		}));
		setSourcingData(data);
	}, [job]);

	return (
		<>
			<SourcingsTab
				sourcings={sourcingData}
				initialValues={{
					source: isTalent ? SOURCE_50INTECH_VALUE : SOURCE_RECRUITER_VALUE,
					jobId: documentId(job),
					companyId: job.companyId,
					notUserIds: sourcingData.map((sourcing) => sourcing.userId),
					viaJob: true,
					company: [{ ...job.company, id: documentId(job.company) }],
					jobs: [job],
					user: [],
				}}
				viaJob
			/>
		</>
	);
};

export default Sourcings;
