import { useTheme } from '@emotion/react';
import { PlusOutlined } from '@ant-design/icons';
import { Badge, Flex } from 'antd';
import { Droppable } from '@hello-pangea/dnd';
import { hexToRgb } from '../../../utils/common';
import Link from '../../app/Link';
import { LocalDebug } from '../../../utils/LocalDebug';
import CompanyActionCard from './CompanyActionCard';
import {
	findActionCompletionStatus,
	findActionTimeframe,
} from './action-plan';
import Box from '../../app/box/Box';

const DroppableColumnWrapper = ({
	field,
	value,
	droppable = true,
	children,
}) => {
	const theme = useTheme();

	if (!droppable) return children;

	return (
		<Droppable droppableId={`droppable_${field}_${value}`}>
			{(provided, snapshot) => (

				<div
					ref={provided.innerRef}
					style={{
						backgroundColor: snapshot.isDraggingOver
							? `rgba(${hexToRgb(theme.color.darkturquoise).join(',')},.1)`
							: 'transparent',
						borderRadius: 8,
						height: 'calc(100% - 50px)',
					}}
					{...provided.droppableProps}
				>
					{children}
					{provided.placeholder}
				</div>
			)}
		</Droppable>
	);
};

const CompanyActionPlanGridColumn = ({
	field,
	value,
	actions,
	droppable = true,
	onOpenAction,
	onOpenLibrary,
	onAddAction,
}) => {
	const className = 'CompanyActionPlanGridColumn';

	const option = field === 'timeframe'
		? findActionTimeframe(value)
		: findActionCompletionStatus(value);

	// LocalDebug.logInfo({ className }, { actions: actions?.length });

	return (
		<div
			style={{
				height: '100%',
				minHeight: 340,
				boxShadow: 'inset 0 0 0 1px rgba(0,61,61,.04)',
				borderRadius: 28,
			}}
		>
			<Box
				style={{
					padding: 20,
					borderColor: '#ddd',
					borderRadius: 8,
					background: '#f7f7f9',
					height: 'calc(100% - 20px)',
					boxShadow: '0 0 20px -4px rgba(0,0,0,.2)',
					border: 'none',
					borderRadius: 28,
				}}
			>
				<Flex
					justify='space-between'
					align='center'
					style={{
						marginBottom: 20,
					}}
				>
					<div style={{
						fontSize: 18,
					}}>
						{option.tagIcon} {option.label}
						&nbsp;
						<Badge
							count={actions?.length || 0}
							showZero={true}
							color={'#aaa'}
							size='small'
							style={{
								fontSize: 10,
								fontWeight: 'bold',
								padding: '3px 0',
								width: 20,
								height: 20,
								borderRadius: 20,
								marginTop: -1,
							}}
						/>
					</div>

					<Link onClick={onOpenLibrary}>
						<PlusOutlined /> Add action
					</Link>
				</Flex>

				<DroppableColumnWrapper
					field={field}
					value={value}
					droppable={droppable}
				>
					{actions
						?.map((action, index) => (
							<div key={index}>
								<CompanyActionCard
									action={action}
									view={field}
									index={index}
									onOpenAction={onOpenAction}
									onAddAction={onAddAction}
								/>
							</div>
						))}
				</DroppableColumnWrapper>

			</Box>
		</div>
	);
};

export default CompanyActionPlanGridColumn;
