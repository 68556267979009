import CompanyModel from '../../models/CompanyModel';
import { LocalDebug } from '../../utils/LocalDebug';
import { saveCompany } from '../../actions/company';
import { GENDERSCORE_LABEL, PATHS } from '../../constants/constant';
import { documentId } from '../../utils/common';
// import ConfigureCompanyPageMenu from '../../menu/configure/ConfigureCompanyPageMenu';

const className = 'OnboardingSteps';

export const ONBOARDING_STEP_JOBS_SETUP_ID = 'jobs-setup';
export const ONBOARDING_STEP_COMPANY_PAGE_ID = 'company-page';
export const ONBOARDING_STEP_TEAM_MEMBERS_ID = 'team-members';
export const ONBOARDING_STEP_JOBS_PUBLICATION_ID = 'jobs-publication';
export const ONBOARDING_STEP_GENDERSCORE_ID = 'gender-score';
export const ONBOARDING_STEP_DEMO_ID = 'demo';

export const ONBOARDING_STEP_JOBS_SETUP = {
	id: ONBOARDING_STEP_JOBS_SETUP_ID,
	title: 'Jobs Setup',
	description: 'Add details about how we can fetch your jobs',
	disabledDescription: '',
	inProgressDescription: 'Setup in progress: We are fetching and configuring your jobs',
	doneDescription: 'Completed: You\'ve successfully configured your jobs',
	popin: '',
	redirectPath: '',
	callback: async (company, currentUser, dispatch) => {
		await dispatch(
			await saveCompany({
				'id': documentId(company),
				'onboarding.jobsSetup': {
					startedAt: new Date(),
				},
			}),
		);
	},
	isEnabled: (company) => true,
	isInProgress: (company) => {
		const companyModel = new CompanyModel(company);
		return companyModel?.hasStartedOnboardingJobsSetupProcess()
		&& !companyModel?.hasFinishedOnboardingJobsSetupProcess();
	},
	isCompleted: (company) => {
		const companyModel = new CompanyModel(company);
		return companyModel?.hasFinishedOnboardingJobsSetupProcess();
	},
};

export const ONBOARDING_STEP_COMPANY_PAGE = {
	id: ONBOARDING_STEP_COMPANY_PAGE_ID,
	title: 'Company Page',
	description: 'Edit and publish your company page',
	disabledDescription: '',
	inProgressDescription: 'Editing in progress: Your company page is underway',
	doneDescription: 'Completed: Your company page is live',
	popin: 'Proceed to your company page?',
	redirectPath: PATHS.COMPANY_PAGE,
	// redirectPath: new ConfigureCompanyPageMenu().path,
	isEnabled: (company) => true,
	isInProgress: (company) => {
		const companyModel = new CompanyModel(company);
		return companyModel?.hasStartedOnboardingCompanyPageProcess()
		&& !companyModel?.hasFinishedOnboardingCompanyPageProcess();
	},
	isCompleted: (company) => {
		const companyModel = new CompanyModel(company);
		return companyModel?.hasFinishedOnboardingCompanyPageProcess();
	},
};

export const ONBOARDING_STEP_TEAM_MEMBERS = {
	id: ONBOARDING_STEP_TEAM_MEMBERS_ID,
	title: 'Add team',
	description: 'Invite your team members',
	disabledDescription: '',
	doneDescription: 'Completed: Team members have been added',
	popin: 'Invite your team members now?',
	redirectPath: '/access',
	isEnabled: (company) => true,
	isInProgress: (company) => false,
	isCompleted: (company) => {
		const companyModel = new CompanyModel(company);
		return companyModel?.hasFinishedOnboardingTeamProcess();
	},
};

export const ONBOARDING_STEP_JOBS_PUBLICATION = {
	id: ONBOARDING_STEP_JOBS_PUBLICATION_ID,
	title: 'Publish your jobs',
	description: 'Make your jobs live on 50inTech',
	disabledDescription: 'You must complete \'Jobs Setup\' before you can publish your jobs',
	doneDescription: 'Completed: Your jobs are live on 50inTech',
	popin: 'Proceed to your job publishing page?',
	redirectPath: '/jobs',
	isEnabled: (company) => {
		LocalDebug.logNull(
			{ className, method: 'ONBOARDING_STEP_JOBS_PUBLICATION' },
			{ isEnabled: ONBOARDING_STEP_JOBS_SETUP.isCompleted(company) },
		);
		return ONBOARDING_STEP_JOBS_SETUP.isCompleted(company);
	},
	isInProgress: (company) => false,
	isCompleted: (company) => {
		const companyModel = new CompanyModel(company);
		LocalDebug.logNull(
			{ className, method: 'ONBOARDING_STEP_JOBS_PUBLICATION' },
			{ isCompleted: companyModel?.hasFinishedOnboardingJobsPublicationProcess() },
		);
		return companyModel?.hasFinishedOnboardingJobsPublicationProcess();
	},
};

export const ONBOARDING_STEP_GENDERSCORE = {
	id: ONBOARDING_STEP_GENDERSCORE_ID,
	title: GENDERSCORE_LABEL,
	description: 'Assess your company\'s DEI actions',
	disabledDescription: '',
	inProgressDescription: `Assessment in progress: Your ${GENDERSCORE_LABEL} is underway`,
	doneDescription: `Completed: Your ${GENDERSCORE_LABEL} is being calculated`,
	popin: `Ready to take the ${GENDERSCORE_LABEL}?`,
	redirectPath: '',
	callback: async (company, currentUser, dispatch) => {
		await dispatch(
			await saveCompany({
				'id': documentId(company),
				'onboarding.genderScore': {
					startedAt: new Date(),
				},
			}),
		);
	},
	isEnabled: (company) => true,
	isInProgress: (company) => {
		const companyModel = new CompanyModel(company);
		return companyModel?.hasStartedOnboardingGenderScoreProcess()
		&& !companyModel?.hasFinishedOnboardingGenderScoreProcess();
	},
	isCompleted: (company) => {
		const companyModel = new CompanyModel(company);
		return companyModel?.hasFinishedOnboardingGenderScoreProcess();
	},
};

export const ONBOARDING_STEP_DEMO = {
	id: ONBOARDING_STEP_DEMO_ID,
	title: 'Book a demo',
	description: 'Get acquainted with 50inTech\'s features',
	disabledDescription: 'Please complete the previous steps to unlock the option to book a demo',
	doneDescription: 'Completed: You\'ve booked a demo',
	popin: 'Book a demo with the 50inTech team?',
	redirectPath: '',
	callback: async (company, currentUser, dispatch) => {
		LocalDebug.logNull({ className, method: 'ONBOARDING_STEP_DEMO.callback' }, { currentUser });

		await dispatch(
			await saveCompany({
				'id': documentId(company),
				'onboarding.demo.requestedAt': new Date(),
				// the demo is only enabled when the rest of steps are completed
				// if we complete the demo, then we set the onboarding as finished
				'onboarding.finishedAt': new Date(),
				'onboarding.finishedBy': documentId(currentUser),
			}),
		);
	},
	isEnabled: (company) => {
		return onlyDemoStepIsMissing(company);
	},
	isInProgress: (company) => false,
	isCompleted: (company) => {
		const companyModel = new CompanyModel(company);
		return companyModel?.hasFinishedOnboardingDemoProcess();
	},
};

/*
	Ordered list of the basic steps.
	These are the steps needed to setup the company
	This order is to have consistency when displaying lists and next steps
	but clients can fill the steps in whichever order they want.
*/
export const ONBOARDING_BASIC_STEPS = [
	ONBOARDING_STEP_JOBS_SETUP,
	ONBOARDING_STEP_COMPANY_PAGE,
	ONBOARDING_STEP_TEAM_MEMBERS,
	ONBOARDING_STEP_JOBS_PUBLICATION,
];
export const ONBOARDING_BASIC_STEPS_TOTAL = ONBOARDING_BASIC_STEPS.length;

/**
 * The GenderScore is mandatory, but it's treated differently
 */
export const ONBOARDING_MANDATORY_STEPS = [
	...ONBOARDING_BASIC_STEPS,
	ONBOARDING_STEP_GENDERSCORE,
];

export const ONBOARDING_MANDATORY_STEPS_TOTAL = ONBOARDING_MANDATORY_STEPS.length;

/**
 * The Demo is an extra and optional step
 */
export const ONBOARDING_ALL_STEPS = [
	...ONBOARDING_MANDATORY_STEPS,
	ONBOARDING_STEP_DEMO,
];

export const ONBOARDING_ALL_STEPS_TOTAL = ONBOARDING_ALL_STEPS.length;

export const areAllBasicStepsCompleted = (company) => ONBOARDING_BASIC_STEPS
	.map((step) => step.isCompleted(company))
	.reduce((prev, cur) => (prev == undefined ? true : prev) && cur);

export const areAllBasicStepsIncomplete = (company) => ONBOARDING_BASIC_STEPS
	.map((step) => !step.isCompleted(company))
	.reduce((prev, cur) => (prev == undefined ? true : prev) && cur);
export const areAllMandatoryStepsCompleted = (company) => ONBOARDING_MANDATORY_STEPS
	.map((step) => step.isCompleted(company))
	.reduce((prev, cur) => (prev == undefined ? true : prev) && cur);

export const areAllMandatoryStepsIncomplete = (company) => ONBOARDING_MANDATORY_STEPS
	.map((step) => !step.isCompleted(company))
	.reduce((prev, cur) => (prev == undefined ? true : prev) && cur);

export const findCurrentBasicStepIndex = (company) => {
	let stepIndex = 0;
	ONBOARDING_BASIC_STEPS
		.forEach((step, index) => {
			if (step.isCompleted(company)) stepIndex = index + 1;
		});
	return stepIndex;
};

export const findCurrentMandatoryStepIndex = (company) => {
	let stepIndex = 0;
	ONBOARDING_MANDATORY_STEPS
		.forEach((step, index) => {
			if (step.isCompleted(company)) stepIndex = index + 1;
		});
	return stepIndex;
};
export const getNextBasicStep = (company) => {
	const currentStepIndex = findCurrentBasicStepIndex(company);
	const [nextStep] = ONBOARDING_BASIC_STEPS
		?.slice?.(currentStepIndex, ONBOARDING_BASIC_STEPS?.length)
			|| [];
	return nextStep;
};

export const getNextMandatoryStep = (company) => {
	const currentStepIndex = findCurrentMandatoryStepIndex(company);
	const [nextStep] = ONBOARDING_MANDATORY_STEPS
		?.slice?.(currentStepIndex, ONBOARDING_MANDATORY_STEPS?.length)
			|| [];
	return nextStep;
};
export const getNextIncompleteBasicStep = (company) => {
	const [nextStep] = ONBOARDING_BASIC_STEPS
		?.filter?.((step) => !isBasicStepCompleted(documentId(step), company))
			|| [];
	LocalDebug.logNull({ className, method: 'getNextIncompleteBasicyStep' }, { nextStep: documentId(nextStep) });
	return nextStep;
};
export const getNextIncompleteMandatoryStep = (company) => {
	const [nextStep] = ONBOARDING_MANDATORY_STEPS
		?.filter?.((step) => !isMandatoryStepCompleted(documentId(step), company))
			|| [];
	LocalDebug.logNull({ className, method: 'getNextIncompleteMandatoryStep' }, { nextStep: documentId(nextStep) });
	return nextStep;
};

export const getCompleteBasicStepList = (company) => {
	return ONBOARDING_BASIC_STEPS
		?.filter?.((step) => !!isBasicStepCompleted(documentId(step), company))
			|| [];
};
export const getCompleteMandatoryStepList = (company) => {
	return ONBOARDING_MANDATORY_STEPS
		?.filter?.((step) => !!isMandatoryStepCompleted(documentId(step), company))
			|| [];
};

export const getIncompleteBasicStepList = (company) => {
	return ONBOARDING_BASIC_STEPS
		?.filter?.((step) => !isBasicStepCompleted(documentId(step), company))
			|| [];
};
export const getIncompleteMandatoryStepList = (company) => {
	return ONBOARDING_MANDATORY_STEPS
		?.filter?.((step) => !isMandatoryStepCompleted(documentId(step), company))
			|| [];
};
export const getInProgressMandatoryStepList = (company) => {
	return ONBOARDING_MANDATORY_STEPS
		?.filter?.((step) => !isMandatoryStepInProgress(documentId(step), company))
			|| [];
};

export const getIncompleteBasicStepIdList = (company) => {
	return ONBOARDING_BASIC_STEPS
		?.filter?.((step) => !isBasicStepCompleted(documentId(step), company))
		?.map((step) => documentId(step))
			|| [];
};
export const getIncompleteMandatoryStepIdList = (company) => {
	return ONBOARDING_MANDATORY_STEPS
		?.filter?.((step) => !isMandatoryStepCompleted(documentId(step), company))
		?.map((step) => documentId(step))
			|| [];
};
export const hasNextIncompleteBasicStep = (company) => {
	return !!getNextIncompleteBasicStep(company);
};
export const hasNextIncompleteMandatoryStep = (company) => {
	return !!getNextIncompleteMandatoryStep(company);
};
export const getIncompleteBasicStepsTotal = (company) => {
	return getIncompleteBasicStepList(company).length;
};
export const getIncompleteMandatoryStepsTotal = (company) => {
	return getIncompleteMandatoryStepList(company).length;
};
export const getInProgressMandatoryStepsTotal = (company) => {
	return getInProgressMandatoryStepList(company).length;
};
export const hasAnyCompletedBasicStep = (company) => {
	return getIncompleteBasicStepsTotal(company) < ONBOARDING_BASIC_STEPS_TOTAL;
};
export const hasAnyCompletedMandatoryStep = (company) => {
	return getIncompleteMandatoryStepsTotal(company) < ONBOARDING_MANDATORY_STEPS_TOTAL;
};
export const hasAnyInProgressMandatoryStep = (company) => {
	return getInProgressMandatoryStepsTotal(company) < ONBOARDING_MANDATORY_STEPS_TOTAL;
};
export const isBasicStepCompleted = (stepId, company) => {
	const step = ONBOARDING_BASIC_STEPS
		?.find((item) => documentId(item) === stepId);
	return step?.isCompleted?.(company);
};
export const isMandatoryStepCompleted = (stepId, company) => {
	const step = ONBOARDING_MANDATORY_STEPS
		?.find((item) => documentId(item) === stepId);
	return step?.isCompleted?.(company);
};

export const isMandatoryStepInProgress = (stepId, company) => {
	const step = ONBOARDING_MANDATORY_STEPS
		?.find((item) => documentId(item) === stepId);
	return step?.isInProgress?.(company);
};

/**
 * - All Mandatory Steps are complete
 * - All Basic Steps are complete and the GenderScore is in progress
 * @param {*} company
 * @returns boolean
 */
export const onlyDemoStepIsMissing = (company) => {
	return !hasNextIncompleteMandatoryStep(company) || (
		!hasNextIncompleteBasicStep(company)
			&& isMandatoryStepInProgress(ONBOARDING_STEP_GENDERSCORE_ID, company)
	);
};
