import {
	useState, useEffect, useContext, useMemo,
} from 'react';
import { Layout as AntLayout, Button } from 'antd';
import { getPermittedMenuItems } from '../../../utils/client/permission';
import AppMenu from './AppMenu';
import { useSelector } from 'react-redux';
import { getCompanySelected, getHistoryActions } from '../../../reducers/app';
import { useAuthContext } from '../../../hooks/useAuthContext';
import AppVersionTag from './AppVersionTag';
import { useTheme } from '@emotion/react';
import { useLocation } from 'react-router-dom';
import { AppDataContext } from '../../../contexts/AppDataProvider';
import { CompanyContext } from '../../../contexts/CompanyProvider';

const { Sider } = AntLayout;

const AppLayoutSider = () => {
	const theme = useTheme();

	const historyActions = useSelector(getHistoryActions);
	const location = useLocation();

	const companySelected = useSelector(getCompanySelected);

	const {
		currentUser,
		isStaff,
		isDocumentEditorOnly,
		isCompanySelected,
		appDataReady,
		isSavedSearchNewTagEnabled,
		isGenderScoreEnabled,
		isGenderScoreFilled,
		isGenderScoreSurveyEnabled,
		isUMSEnabled,
		isUMSManagerSurveyFilled,
		isUMSEmployeeSurveyFilled,
		isUMSSurveyEnabled,
	} = useAuthContext();

	const {
		appSiderWidthExpanded,
		appSiderWidthCollapsed,
		isAppSiderCollapsed,
		appTopOffset,
	} = useContext(AppDataContext);

	const { planValue } = useContext(CompanyContext);

	const authContext = useAuthContext();

	const appDataContext = useContext(AppDataContext);

	const context = {
		...appDataContext || {},
		...authContext || {},
		user: authContext?.currentUser,
		currentLocation: location,
		planValue,
	};

	const [menuItems, setMenuItems] = useState([]);

	// if (!companySelected && !isStaff) return null;

	// if (!appDataReady) return null;
	// const allowedItems = getPermittedMenuItems(context);
	// const menuItems = allowedItems;
	// const menuItems = [];

	useMemo(() => {
		if (!appDataReady) return;
		const allowedItems = getPermittedMenuItems(context);
		setMenuItems(allowedItems);
	}, [
		currentUser,
		location,
		planValue,
		isDocumentEditorOnly,
		isCompanySelected,
		appDataReady,
		isSavedSearchNewTagEnabled,
		isGenderScoreEnabled,
		isGenderScoreFilled,
		isGenderScoreSurveyEnabled,
		isUMSEnabled,
		isUMSManagerSurveyFilled,
		isUMSEmployeeSurveyFilled,
		isUMSSurveyEnabled,
	]);

	if (!companySelected && !isStaff) return null;

	return (
		<Sider
			width={appSiderWidthExpanded}
			style={{
				overflow: 'auto',
				height: `calc(100vh - ${appTopOffset}px)`,
				position: 'fixed',
				left: 0,
				top: appTopOffset,
				bottom: 0,
				borderRight: '1px solid #eee',
				background: theme.color.bgGrey,
				zIndex: 1,
			}}
			trigger={null}
			collapsedWidth={appSiderWidthCollapsed}
			collapsed={isAppSiderCollapsed}
		>
			{currentUser && (
				<AppMenu
					items={menuItems}
					historyActions={historyActions}
					style={{ marginTop: 15 }}
				/>
			)}

			<span style={{
				position: 'fixed',
				left: 5,
				bottom: 5,
			}}>
				<AppVersionTag />
			</span>

		</Sider>
	);
};

export default AppLayoutSider;
