import Box from '../box/Box';
import { Divider } from 'antd';
import BlockAdmin from '../blocker/BlockAdmin';
import ReactJsonDebug from './ReactJsonDebug';

const ReactJsonAdmin = ({ src, withDivider, ...props }) => {
	return (
		<BlockAdmin>
			{withDivider && <Divider/>}
			<Box style={{ maxWidth: 800, padding: 20 }}>
				<ReactJsonDebug
					src={src}
					{...props}
				/>
			</Box>
		</BlockAdmin>
	);
};

export default ReactJsonAdmin;
