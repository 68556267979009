import { css, useTheme } from '@emotion/react';
import { Space, Tag } from 'antd';
import {
	FaCog,
	FaDownload, FaHourglassHalf,
	FaMinus,
	FaPlus,
	FaRoute,
	FaTags,
	FaTimesCircle,
	FaWifi,
} from 'react-icons/fa';
import { RiDeleteBinFill } from 'react-icons/ri';
import { atsProviderOptions } from '../../../constants/constant';
import ActionButton from '../../app/table/ActionButton';
import Table from '../../app/table/Table';
import TableColumns from '../../app/table/TableColumns';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { getCompanySelected } from '../../../reducers/app';
import { documentId } from '../../../utils/common';

const atsByValue = new Map();
atsProviderOptions.forEach((ats) => atsByValue.set(ats.value, ats.label));

const CompanyBatchTable = ({
	filterOptions, isEditor, onDelete, isRefresh,
}) => {
	const theme = useTheme();

	const companySelected = useSelector(getCompanySelected);

	const columns = [
		TableColumns.companyStubColumn(theme, { fixed: 'left' }),
		TableColumns.docsCountColumn(theme, {
			title: 'Published',
			dataIndex: 'published',
			sorter: true,
			style: { minWidth: 40 },
			icon: <FaWifi size={12} style={{ marginBottom: -1 }}/>,
			// fixed: 'left',
			removeInColumnFilter: true,
			...TableColumns.columnMinWidth(100),
		}),
		TableColumns.docsCountColumn(theme, {
			title: 'Processed',
			dataIndex: 'total',
			removeInColumnFilter: true,
			sorter: true,
			icon: <FaCog size={12} style={{ marginBottom: -1 }}/>,
			style: { minWidth: 40, background: 'transparent', color: theme.color.grey },
			...TableColumns.columnMinWidth(100),
		}),
		// TableColumns.docsCountColumn(theme, {
		//     title: 'Success',
		//     dataIndex: 'success',
		//     sorter: true,
		//     icon: <FaCheckCircle size={12} style={{marginBottom:-1}}/>,
		//     style: {minWidth: 40, background: theme.color.lightgreen, color: theme.color.darkgreen},
		// }),
		TableColumns.docsCountColumn(theme, {
			title: 'Created',
			dataIndex: 'created',
			sorter: true,
			iconPosition: 'left',
			removeInColumnFilter: true,
			icon: <FaPlus size={7} style={{ marginBottom: 1 }}/>,
			style: { minWidth: 40, background: theme.color.lightgreen, color: theme.color.darkgreen },
		}),
		TableColumns.docsCountColumn(theme, {
			title: 'Deleted',
			dataIndex: 'deleted',
			removeInColumnFilter: true,
			sorter: true,
			iconPosition: 'left',
			icon: <FaMinus size={7} style={{ marginBottom: 1 }}/>,
			// icon: <FaMinusCircle size={12} style={{marginBottom:-1}}/>,
			style: { minWidth: 40, background: '#ffcc66', color: '#996633' },
		}),
		TableColumns.docsCountColumn(theme, {
			title: 'Errors',
			dataIndex: 'error',
			removeInColumnFilter: true,
			sorter: true,
			icon: <FaTimesCircle size={12} style={{ marginBottom: -1 }}/>,
			style: { minWidth: 40, background: '#ffdddd', color: '#cc3333' },
		}),
		{
			title: 'Durations',
			dataIndex: ['startDate'],
			removeInColumnFilter: true,
			render: (value, row) => {
				if (!row?.startDate) return;
				let durationDisplay = <><FaCog style={{ color: theme.color.orange, marginBottom: -2 }}/><FaHourglassHalf
					style={{ color: theme.color.orange, marginBottom: -2 }}/></>;
				let tagDurationDisplay = null;
				if (row?.endDate) {
					const duration = moment.duration(moment(row.endDate).diff(moment(row.startDate)));
					durationDisplay = `${Math.round(duration.asHours())} h`;
					if (duration.asHours() < 1) durationDisplay = `${Math.round(duration.asMinutes())} min`;
					if (duration.asMinutes() < 1) durationDisplay = `${Math.round(duration.asSeconds())} s`;
					durationDisplay = <><FaCog
						style={{ color: theme.color.gray, marginBottom: -2 }}/>{durationDisplay}</>;
					tagDurationDisplay = <><FaTags
						style={{ color: theme.color.orange, marginBottom: -2 }}/><FaHourglassHalf
						style={{ color: theme.color.orange, marginBottom: -2 }}/></>;
					if (row?.taggedDate && row?.endDate) {
						const tagDuration = moment.duration(moment(row.taggedDate).diff(moment(row.endDate)));
						tagDurationDisplay = `${Math.round(tagDuration.asHours())} h`;
						if (tagDuration.asHours() < 1) tagDurationDisplay = `${Math.round(tagDuration.asMinutes())} min`;
						if (tagDuration.asMinutes() < 1) tagDurationDisplay = `${Math.round(tagDuration.asSeconds())} s`;
						tagDurationDisplay = <><FaTags
							style={{ color: theme.color.gray, marginBottom: -2 }}/>{tagDurationDisplay}</>;
					}
				}
				// durationDisplay = String(durationDisplay) + ' (' + duration.asMinutes() + ' asM)';
				// durationDisplay = String(durationDisplay) + ' (' + duration.asSeconds() + ' asS)';
				return <Space direction='horizontal' size={10} style={{ color: '#444' }}>
					<Space direction='horizontal' size={2} style={{ color: '#444' }}>{durationDisplay}</Space>
					<Space direction='horizontal' size={2} style={{ color: '#444' }}>{tagDurationDisplay}</Space>
				</Space>;
			},
			...TableColumns.columnMinWidth(140),
		},
		{
			title: 'ATS',
			dataIndex: 'access',
			key: 'access',
			render: (value, row) => <Space direction="horizontal">
				{(value || []).map((access, key) => <Tag key={key} icon={<FaDownload style={{ marginBottom: -2 }}/>}
														 color="green"> <b>{atsByValue.get(access.ats)}</b></Tag>)}
			</Space>,
			...TableColumns.columnMinWidth(80),
		},
		{
			title: 'Access Type',
			dataIndex: 'access',
			key: 'access',
			render: (value, row) => <Space direction="horizontal">
				{(value || []).map((access, key) => <Tag key={key} icon={<FaRoute style={{ marginBottom: -2 }}/>}
														 color="cyan"> <b>{access.accessType}</b></Tag>)}
			</Space>,
			...TableColumns.columnMinWidth(80),
		},
		{
			title: 'Value',
			dataIndex: 'access',
			render: (value, row) => <Space direction="horizontal">
				{(value || []).map((access, key) => <code key={key}>{access.value}</code>)}
			</Space>,
			...TableColumns.columnMinWidth(80),
		},
		TableColumns.createdAtColumn(theme),
		{
			title: '',
			dataIndex: 'isActive',
			key: 'isActive',
			removeInColumnFilter: true,
			render: (_, row) => {
				return (
					isEditor
					&& <Space direction="horizontal" size={4}>

						<ActionButton
							tooltipTitle="Delete batch"
							icon={<RiDeleteBinFill/>}
							styles={{ color: theme.color.orange }}
							title="Are you sure you want to delete this row?"
							okText="Yes" cancelText="No"
							onConfirm={() => onDelete(documentId(row))}
							withConfirm
						/>
					</Space>
				);
			},
			className: css({ width: 150 }),
		},
	];

	return (
		<Table columns={columns}
			   path='/company/batch'
			   filterOptions={{
				   ...filterOptions,
				   ...companySelected ? { companyId: documentId(companySelected) } : {},
			   }}
			   isRefresh={isRefresh}
			   withDynamicSearch
		/>
	);
};

export default CompanyBatchTable;
