import AccountPage from '../../containers/root/AccountPage';
import { onEnterBuilder } from '../../actions/app';

const menu = {
	name: 'account',
	path: '/account',
	label: 'Account',
	icon: <span>📇</span>,
	component: AccountPage,
	onEnter: onEnterBuilder({
		origin: AccountPage.name,
		shouldGoToPreviousHistory: true,
	}),
};

export default menu;
