import { useTheme } from '@emotion/react';
import {
	Divider, Space,
} from 'antd';
import { useAuthContext } from '../../hooks/useAuthContext';
import AccountCard from './AccountCard';
import { FaCheckCircle } from 'react-icons/fa';
import AccountNotificationForm from './AccountNotificationForm';
import UserModel from '../../models/UserModel';
import HelpInfoNotifications from '../app/help/HelpInfoNotifications';

const AccountNotificationCard = (
	{
		user,
		style = {},
	},
) => {
	const className = 'AccountNotificationCard';

	const theme = useTheme();

	const { isDocumentEditorOnly } = useAuthContext();

	if (!user) return null;

	if (isDocumentEditorOnly) return null;

	const userModel = new UserModel(user);
	const shouldNotifyAllOnApplysIfNoRecipients = userModel?.companyShouldNotifyAllOnApplysIfNoRecipients();
	const shouldBeNotifiedForApplysIfNoRecipient = userModel?.companyMemberShouldBeNotifiedForApplysIfNoRecipient();
	const isDefaultRecipient = userModel?.isDefaultRecipient();

	return (
		<AccountCard
			user={user}
			title={<><span>📥</span> Your notifications settings</>}
			details={<>Control the email notifications you receive from 50inTech</>}
			AccountForm={AccountNotificationForm}
		>
			When a candidate applies on a job offer, you will receive an email notification...
			<Space direction='vertical' style={{ width: '100%', marginTop: 20 }}>
				<Space align='top'>
					<FaCheckCircle style={{ marginBottom: -2, color: theme.color.darkturquoise }}/>
					<span>...if you have <b>subscribed</b> to notifications for this job offer</span>
				</Space>
				{(shouldBeNotifiedForApplysIfNoRecipient || (!shouldNotifyAllOnApplysIfNoRecipients && isDefaultRecipient))
					? <Space align='top'>
						<FaCheckCircle style={{ marginBottom: -2, color: theme.color.darkturquoise }}/>
						<span>
							<span>...if <b>nobody in your company</b> has yet subscribed to notifications for this job offer</span>
							{!shouldNotifyAllOnApplysIfNoRecipients && isDefaultRecipient
								&& <i style={{ color: theme.color.grey, fontSize: 12 }}> (because your company admin set
									you as the <b>default recipient</b> for this particular case)</i>
							}
						</span>
					</Space>
					: <></>
				}
				<Divider/>
				<HelpInfoNotifications/>
			</Space>
		</AccountCard>
	);
};

export default AccountNotificationCard;
