import {
	Form, Input, Divider, Space,
} from 'antd';
import { useTheme } from '@emotion/react';
import Link from '../../components/app/Link';
import Button from '../../components/app/button/Button';
import FormItem from '../../components/app/form/FormItem';
import { FaChevronRight } from 'react-icons/fa';
import { requiredTrimRule } from '../../utils/common';

const LoginForm = (
	{
		initialValues = {},
		onSubmit,
		onResetPassword,
		isSubmitting,
	},
) => {
	const theme = useTheme();
	const [form] = Form.useForm();

	const labelStyles = {
		rootContainer: {
			display: 'flex', alignItems: 'start', justifyContent: 'space-between', marginBottom: 20,
		},
		label: { marginTop: 10, paddingRight: 10, width: 85 },
		itemContainer: { marginBottom: 0 },
		container: { flex: 1 },
		input: { height: 40 },
	};

	return (

		<div>
			<Form
				form={form}
				name="alert_form"
				initialValues={initialValues}
				onFinish={onSubmit}
			>
				<FormItem
					label="Email"
					name="email"
					defaultContainer="div"
					rules={[
						{ ...requiredTrimRule, message: 'Please input your email' },
						{ type: 'email', message: 'Please enter a valid email' },
					]}
					styles={labelStyles}
				/>
				<FormItem
					label="Password"
					name="password"
					defaultContainer="div"
					rules={[
						{ ...requiredTrimRule, message: 'Please input your password' },
					]}
					styles={labelStyles}
				>
					<Input.Password/>
				</FormItem>
				<div>
					<Button
						type="secondary"
						disabled={isSubmitting}
						style={{
							// margin: '5px 5px 5px 130px',
							margin: 0,
							background: theme.color.orange,
							borderColor: theme.color.orange,
							color: theme.color.white,
							fontWeight: 'bold',
							borderRadius: 5,
							padding: '8px 0',
							// width: 'calc(100% - 130px)',
							width: '100%',
							height: 'auto',
							...isSubmitting ? { opacity: 0.7 } : {},
						}}
						htmlType="submit"
					>
						Sign in <FaChevronRight style={{ marginLeft: 5, marginBottom: -2 }}/>
					</Button>
				</div>
			</Form>
			<Divider/>
			<Space size={0} direction='vertical' align='center' style={{ width: '100%' }}>
				<Link onClick={onResetPassword} color={theme.color.blue}>
					<u>Forgot your password?</u>
				</Link>
			</Space>
		</div>
	);
};

export default LoginForm;
