import {
	Button, Space,
} from 'antd';
import {
	ENGLISH_LANG, GENDERSCORE_EMPTY_ICON_IMG, GENDERSCORE_LABEL, PATHS,
} from '../../../../constants/constant';
import { documentId } from '../../../../utils/common';
import CompanyModel from '../../../../models/CompanyModel';
import { useAuthContext } from '../../../../hooks/useAuthContext';
import {
	GENDERSCORE_LOG_FROM_ELEMENT_OPEN_SURVEY_BTN,
	writeGenderScoreLog,
} from '../../../../constants/genderscore-logs';
import { useDispatch, useSelector } from 'react-redux';
import { getCompanySelected } from '../../../../reducers/app';
import { saveCompany } from '../../../../actions/company';
import { useNavigate } from 'react-router';

const SurveyButton = ({
	label = '1-49',
	survey = 'survey-1-to-49',
	onClick,
}) => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const companySelected = useSelector(getCompanySelected);

	const handleClick = async () => {
		await dispatch(
			saveCompany({
				'id': documentId(companySelected),
				'genderScore.survey': survey,
			}, true),
		);
		navigate(PATHS.GENDERSCORE_SURVEY());
		writeGenderScoreLog({
			type: GENDERSCORE_LOG_FROM_ELEMENT_OPEN_SURVEY_BTN,
			context: label,
		});
		// openExternalUrl(link);
		onClick?.({ label, value });
	};
	return (
		<Button
			type='primary'
			style={{
				borderRadius: 10,
				width: 200,
				height: 100,
			}}
			onClick={handleClick}
		>
			<b>Company with</b>
			<div style={{
				fontSize: 32, fontWeight: 'bold', marginTop: -6, marginBottom: -10,
			}}>
				{/* <FaChevronLeft style={{ marginBottom: -4 }} />50 */}
				{label}
			</div>
			<b>employees</b>
		</Button>

	);
};

const CompanyGenderScoreNotFilledContent = (
	{
		company,
		lang = ENGLISH_LANG,
		withCloseMessage = true,
		setEditorOpen,
		...props
	},
) => {
	const className = 'CompanyGenderScoreNotFilledContent';

	const {
		isStaff, isStaffView, isDocumentAdmin, isDocumentEditorOnly,
	} = useAuthContext();

	if (!new CompanyModel(company)
		.isGenderScorePassingAllowed({
			isStaff, isStaffView, isDocumentAdmin, isDocumentEditorOnly,
		})
	) {
		return (
			<div>
				<h3>Sorry, you cannot pass the GenderScore.</h3>
				<p>
					Only the <b>admins</b> of this account are allowed to pass the <b>{GENDERSCORE_LABEL}</b>.
					<br/>We recommend reaching out to them if you want to start it now.
				</p>
			</div>
		);
	}

	return (
		<div
			style={{ ...props?.style }}
		>
			<Space
				direction='horizontal'
				wrap={false}
				justifyContent='center'
				size={32}
				style={{
					margin: '0 auto',
				}}
			>
				<div>
					<h3>Pass the {GENDERSCORE_LABEL}!</h3>
					<ul>
						<li>
							If you have <strong>started</strong> passing the GenderScore and <strong>saved your progress</strong>, check your emails to continue
						</li>
						<li>
							If you haven't started yet, <strong>select the survey</strong> corresponding to the size of your company below
						</li>
					</ul>

					<Space
						direction='horizontal'
						size={20}
						wrap={false}
						style={{
							justifyContent: 'center',
							alignItems: 'center',
							width: '100%',
						}}
					>
						<SurveyButton
							label='1-49'
							value={'survey-1-to-49'}
						/>
						<SurveyButton
							label='+50'
							value={'survey-50-plus'}
						/>
					</Space>
				</div>
				{GENDERSCORE_EMPTY_ICON_IMG({ width: 210 })}
			</Space>
		</div>
	);
};

export default CompanyGenderScoreNotFilledContent;
