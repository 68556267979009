import PageLayout from '../../../components/app/layout/PageLayout';
import { useDispatch, useSelector } from 'react-redux';
import { useTheme } from '@emotion/react';
import { useAuthContext } from '../../../hooks/useAuthContext';
import { LocalDebug } from '../../../utils/LocalDebug';
import AppService from '../../../services/app';
import { useEffect, useState } from 'react';
import ReactJson from 'react-json-view';
import {
	Button, Divider, Select, Space, Statistic, Table,
} from 'antd';
import { FaDatabase, FaRecycle, FaSync } from 'react-icons/fa';
import { documentId, mixColors } from '../../../utils/common';
import SectionTitle from '../../../components/app/SectionTitle';
import AddButton from '../../../components/app/button/AddButton';
import CalloutAtsLaunch from '../../../components/app/callout/CalloutAtsLaunch';
import FeedbackBanner from '../../../components/staff/product/feedback/FeedbackBanner';
import TableColumns from '../../../components/app/table/TableColumns';
import { getCompanies, getCompanySelected } from '../../../reducers/app';
import COUNTRIES from '../../../constants/countries.json';

const MESSAGE_ORDER_OPTIONS = [
	{ value: 'total|desc|number', label: 'By total ↓' },
	{ value: 'total|asc|number', label: 'By total ↑' },
	{ value: 'company.name|desc|string', label: 'By company ↓' },
	{ value: 'company.name|asc|string', label: 'By company ↑' },
	{ value: 'status.sent.count|desc|number', label: 'By sent ↓' },
	{ value: 'status.sent.count|asc|number', label: 'By sent ↑' },
	{ value: 'status.sent.rate|desc|number', label: 'By sent % rate ↓' },
	{ value: 'status.sent.rate|asc|number', label: 'By sent % rate ↑' },
	{ value: 'status.opened.count|desc|number', label: 'By opened ↓' },
	{ value: 'status.opened.count|asc|number', label: 'By opened ↑' },
	{ value: 'status.opened.rate|desc|number', label: 'By opened % rate ↓' },
	{ value: 'status.opened.rate|asc|number', label: 'By opened % rate ↑' },
	{ value: 'status.clicked.count|desc|number', label: 'By clicked ↓' },
	{ value: 'status.clicked.count|asc|number', label: 'By clicked ↑' },
	{ value: 'status.clicked.rate|desc|number', label: 'By clicked % rate ↓' },
	{ value: 'status.clicked.rate|asc|number', label: 'By clicked % rate ↑' },
];

const APPLICATION_ORDER_OPTIONS = [
	{ value: 'total|desc|number', label: 'By total ↓' },
	{ value: 'total|asc|number', label: 'By total ↑' },
	{ value: 'company.name|desc|string', label: 'By company ↓' },
	{ value: 'company.name|asc|string', label: 'By company ↑' },
];

const MiscDataPage = () => {
	const className = 'StaffData';
	const theme = useTheme();
	const dispatch = useDispatch();
	const companySelected = useSelector(getCompanySelected);
	const companies = useSelector(getCompanies);

	const {
		currentUser, isAdmin, isStaffView, isAtsSyncable,
	} = useAuthContext();

	const [isLoading, setIsLoading] = useState(false);
	const [dataSource, setDataSource] = useState({});
	const [messageData, setMessageData] = useState({});
	const [messageOrderSelectedOption, setMessageOrderSelectedOption] = useState(MESSAGE_ORDER_OPTIONS[0]?.value);
	const [applicationOrderSelectedOption, setApplicationOrderSelectedOption] = useState(MESSAGE_ORDER_OPTIONS[0]?.value);
	const [countrySelectedOptions, setCountrySelectedOptions] = useState([]);
	const [talentsCountryData, setTalentsCountryData] = useState({});
	const [fetchParams, setFetchParams] = useState({});

	const fetchData = async (params = {}) => {
		const method = 'fetchData';
		LocalDebug.logInfo({ className, method }, JSON.stringify(params), { params });

		LocalDebug.logAPICall({ className, method }, { params });

		setIsLoading(true);
		try {
			const { data: { success, ...data } } = await AppService.getData('/data', {
				...params,
				companyId: documentId(companySelected),
			});
			LocalDebug.logAPIResult({ className, method }, { data });
			setDataSource(data);
			setIsLoading(false);
		} catch (error) {
			if (!error?.message?.includes('canceled due to new request')) {
				setIsLoading(false);
			}
			LocalDebug.logError({
				className, method, __filename, error,
			});
		}
	};

	useEffect(() => {
		setFetchParams((p) => ({ ...p, companyId: documentId(companySelected) }));
	}, [companySelected]);

	useEffect(() => {
		fetchData(fetchParams);
	}, [fetchParams]);

	const handleRefresh = () => {
		fetchData(fetchParams);
	};

	const handleCountriesChanged = (values) => {
		const method = 'handleCountriesChanged';
		setCountrySelectedOptions(values);
		LocalDebug.logInfo({ className, method }, { values });
		setFetchParams((p) => ({ ...p, country: values }));
	};

	const handleMessageOrderChanged = (value) => {
		const method = 'handleMessageOrderChanged';
		setMessageOrderSelectedOption(value);
		LocalDebug.logInfo({ className, method }, { value });
		const [sortField, sortOrder, sortAsString] = value.split('|');
		setFetchParams((p) => ({ ...p, recruiterMessageDataOptions: { sortOrder, sortField, sortAsString } }));
	};

	const handleApplicationOrderChanged = (value) => {
		const method = 'handleApplicationOrderChanged';
		setApplicationOrderSelectedOption(value);
		LocalDebug.logInfo({ className, method }, { value });
		const [sortField, sortOrder, sortAsString] = value.split('|');
		setFetchParams((p) => ({ ...p, applicationDataOptions: { sortOrder, sortField, sortAsString } }));
	};

	const buildStatCard = ({
		title, mainvalue, caption, key,
	}) => (
		<Space
			direction='vertical'
			key={key}
			style={{ width: 180 }}
		>
			<Space
				direction='vertical'
				style={{
					width: '100%',
					border: '1px solid #eee',
					borderRadius: 4,
					padding: '10px 20px',
					height: 140,
				}}
			>
				<div style={{ color: theme.color.grey }}>
					{title}
				</div>
				<div style={{ fontSize: 26 }}>
					{mainvalue}
				</div>
				{caption && <div style={{ color: theme.color.grey, fontSize: 10 }}>{caption}</div>}
			</Space>
		</Space>
	);

	return (
		<PageLayout
			withBackground={false}
			icon={<FaDatabase />}
			title='Data'
			rightActions={(
				<AddButton
					icon={<FaSync style={{ marginBottom: -2 }}/>}
					style={{ marginTop: 1 }}
					onClick={handleRefresh}
					title="Refresh"
				/>
			)}
		>

			<Space direction='vertical'
				   style={{ opacity: isLoading ? 0.5 : 1, pointerEvents: isLoading ? 'none' : 'unset' }}>
				{dataSource?.companyData?.companies
					&& <>
						<div style={{ fontSize: 24, fontWeight: 'bold' }}>
							Companies
						</div>
						<Table
							columns={[
								TableColumns.companyStubColumn(theme),
								{ title: 'Messages', dataIndex: 'recruiterMessages' },
							]}
							dataSource={dataSource?.companyData?.companies}
						/>
						<Space
							direction='horizontal'
							align='top'
							wrap={true}
						>
							{Object.entries(dataSource?.companyData?.companies).map(([key, value]) => {
								const {
									title, count, rate, caption,
								} = value;
								let mainvalue = count;
								mainvalue = <>{count} <span style={{ fontSize: 16 }}>{rate}%</span></>;
								return buildStatCard({
									key, title, mainvalue, caption,
								});
							})}
						</Space>
						<Divider/>
					</>
				}

				{dataSource?.talentsCountryData
					&& <>
						<div style={{ fontSize: 24, fontWeight: 'bold' }}>
							Talents qualification funnel
						</div>
						<Space direction='horizontal' style={{ width: '100%' }} wrap={false}>
							<b>Countries</b>
							<Select
								allowClear={true}
								mode='multiple'
								onChange={handleCountriesChanged}
								value={countrySelectedOptions}
								style={{ width: 500 }}
							>
								{COUNTRIES.map((o) => <Select.Option key={o.value}
																   value={o.value}>{o.emoji} {o.label}</Select.Option>)}
							</Select>
						</Space>
						<Space>{
							['france', 'united-states', 'united-kingdom', 'ireland', 'germany', 'spain'].map((value) => {
								const o = COUNTRIES.find((c) => c.value === value);
								return <Button
									key={o.value}
									onClick={() => {
										handleCountriesChanged([
											...new Set([...countrySelectedOptions || [], o.value]),
										]);
									}}
								>
									{o.emoji} {o.label}
								</Button>;
							})
						}
						</Space>
						<Space
							direction='horizontal'
							align='top'
						>
							{Object.entries(dataSource?.talentsCountryData).map(([key, value]) => {
								const {
									title, count, rate, caption,
								} = value;
								let mainvalue = count;
								const subvalue = rate;
								mainvalue = <>{count}</>;
								return (
									<Space
										direction='vertical'
										key={key}
										style={{
											width: 130,
										}}
									>
										<Space
											direction='vertical'
											style={{
												width: '100%',
												border: '1px solid #eee',
												borderRadius: 4,
												padding: '10px 10px 10px 10px',
												lineHeight: 1.1,
												height: 140,
											}}
										>
											<div style={{ fontSize: 12, color: theme.color.grey }}>
												<b>{title}</b>
											</div>
											<div style={{ fontSize: 26 }}>
												{mainvalue}
											</div>
											<div style={{ fontSize: 16 }}>
												{rate}%
											</div>
											{caption
												&& <div style={{ color: theme.color.grey, fontSize: 10 }}>{caption}</div>}
										</Space>
									</Space>
								);
							})}
						</Space>
						<Divider/>
					</>
				}
				{dataSource?.recruiterMessageData?.status
					&& <>
						<div style={{ fontSize: 24, fontWeight: 'bold' }}>
							Recruiter Messages
						</div>
						<Space
							direction='horizontal'
							align='top'
						>
							{Object.entries(dataSource?.recruiterMessageData?.status).map(([key, value]) => {
								const {
									title, count, rate, caption,
								} = value;
								let mainvalue = count;
								const subvalue = rate;
								// if (key === 'sent') {
								mainvalue = <>{count} {rate ? <span style={{ fontSize: 16 }}>{rate}%</span> : ''}</>;
								//     mainvalue = <div>{count}</div>;
								return (
									<Space
										direction='vertical'
										key={key}
										style={{
											width: 180,
										}}
									>
										<Space
											direction='vertical'
											style={{
												width: '100%',
												border: '1px solid #eee',
												borderRadius: 4,
												padding: '10px 20px 0px 10px',
												height: 140,
											}}
										>
											<div style={{ color: theme.color.grey }}>
												{title}
											</div>
											<div style={{ fontSize: 26 }}>
												{mainvalue}
											</div>
											{/* <Statistic */}
											{/*    title={title} */}
											{/*    value={<>{mainvalue}</>} */}
											{/* /> */}
											{/* <div style={{}}>{subvalue}</div> */}
											{caption
												&& <div style={{ color: theme.color.grey, fontSize: 10 }}>{caption}</div>}
										</Space>
									</Space>
								);
							})}
						</Space>
						{dataSource?.recruiterMessageData?.companies?.length > 0
							&& <>
								<Select onChange={handleMessageOrderChanged} value={messageOrderSelectedOption}
									style={{ width: 200 }}>
									{MESSAGE_ORDER_OPTIONS.map((o) => <Select.Option key={o.value}
																				   value={o.value}>{o.label}</Select.Option>)}
								</Select>
								<Table
									style={{ width: '100%' }}
									columns={[
										TableColumns.companyStubColumn(theme, { sorter: false }),
										...(isAdmin && isStaffView && [TableColumns.jsonColumn(theme)]) || [],
										...[
											{ title: 'Messages', data: 'total' },
											{ title: 'Sent', data: 'sent' },
											{ title: 'Opened', data: 'opened' },
											{ title: 'Clicked', data: 'clicked' },
										].map((o) => ({
											...o,
											align: 'center',
											dataIndex: ['status', o.data],
											render: (value, row) => <Space direction='vertical' size={0}>
												<span style={{
													fontWeight: 'bold', fontSize: 16,
												}}>{value.count}</span>
												{/* <span style={{fontWeight: 'bold', color: mixColors(theme.color.red, theme.color.darkturquoise, value.rate), fontSize: 12}}>{value.rate}%</span> */}
												<span style={{
													fontWeight: 'bold',
													padding: '2px 8px',
													borderRadius: 5,
													color: 'white',
													background: mixColors(theme.color.red, theme.color.darkturquoise, value.rate),
													fontSize: 12,
												}}>{value.rate}%</span>
											</Space>,
											...TableColumns.columnFixedWidth(120),
										})),
									]}
									dataSource={dataSource?.recruiterMessageData?.companies}
								/>
							</>
						}
						<Divider/>
					</>
				}
				{dataSource?.applicationData
					&& <>
						<div style={{ fontSize: 24, fontWeight: 'bold' }}>
							Applications
						</div>
						<Space
							direction='horizontal'
							align='top'
						>
							{buildStatCard({
								title: 'Applications',
								mainvalue: dataSource?.applicationData?.total,
							})}
						</Space>
						{dataSource?.applicationData?.companies?.length > 0
							&& <>
								<Select onChange={handleApplicationOrderChanged} value={applicationOrderSelectedOption}
									style={{ width: 200 }}>
									{APPLICATION_ORDER_OPTIONS.map((o) => <Select.Option key={o.value}
																					   value={o.value}>{o.label}</Select.Option>)}
								</Select>
								<Table
									style={{ width: '100%' }}
									columns={[
										TableColumns.companyStubColumn(theme, { sorter: false }),
										...(isAdmin && isStaffView && [TableColumns.jsonColumn(theme)]) || [],
										{ title: 'Applications', dataIndex: 'total' },
									]}
									dataSource={dataSource?.applicationData?.companies}
								/>
							</>
						}
						<Divider/>
					</>
				}
			</Space>
			{isAdmin && isStaffView && <ReactJson
				src={dataSource}
				style={{ fontSize: 11 }}
				// sortKeys={true}
				collapsed={2}
				displayDataTypes={false}
				name={false}
				quotesOnKeys={false}
			/>}

		</PageLayout>
	);
};

export default MiscDataPage;
