import { Space } from 'antd';
import { useState } from 'react';
import { useTheme } from '@emotion/react';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';
import Link from '../../../../app/Link';
import UserModel from '../../../../../models/UserModel';
import UserGridCardLabelValue from './UserGridCardLabelValue';
import UserGridCardValueList from './UserGridCardValueList';
import { NOT_PROVIDED_YET_LABEL } from '../../../../../constants/constant';
import UserGridCardResumeExperienceEntryLine from './UserGridCardResumeExperienceEntryLine';
import UserGridCardResumeExperienceList from './UserGridCardResumeExperienceList';
import UserGridCardResumeEducationList from './UserGridCardResumeEducationList';
import { getDocumentScrollTop } from '../../../../../utils/common';

const UserGridCardResumeLatestExperienceExpandable = (
	{
		user,
		label = <>Latest experience</>,
		placement = 'right',
		defaultContainer = 'div',
		withSeparator = false,
		itemStyle,
		limit = 1,
		hideIfEmpty = true,
		notProvided = NOT_PROVIDED_YET_LABEL,
		children,
		...props
	},
) => {
	const className = 'UserGridCardResumeLatestExperienceExpandable';

	const theme = useTheme();

	const userModel = new UserModel(user);

	const [scroll, setScroll] = useState();
	const [open, setOpen] = useState(false);
	const experiences = userModel?.getResumeExperienceFlattened() || [];
	const educations = userModel?.getResumeEducation()?.degrees || [];
	const [latestEntry, ...otherEntries] = experiences;

	if (!latestEntry) return null;

	const expandLink = (
		<Link
			onClick={() => {
				if (open) {
					window.scrollTo({ top: Math.min(getDocumentScrollTop(), scroll), behavior: 'smooth' });
				} else {
					setScroll(getDocumentScrollTop());
				}
				setOpen(!open);
			}}
			style={{ fontSize: 12, userSelect: 'none' }}
		>
			{open
				? (
					<>
						Hide {experiences.length} experience{experiences.length > 1 ? 's' : ''}
						{educations?.length > 0 && <> and {educations?.length} education{educations?.length > 1 ? 's' : ''}</>}
						&nbsp;<FaChevronUp style={{ marginBottom: -2 }}/>
					</>
				)
				: (
					<>
						Expand {experiences.length} experience{experiences.length > 1 ? 's' : ''}
						{educations?.length > 0 && <> and {educations?.length} education{educations?.length > 1 ? 's' : ''}</>}
						&nbsp;<FaChevronDown style={{ marginBottom: -2 }}/>
					</>
				)
			}
		</Link>
	);

	const items = [
		<Space key={0} direction='vertical' style={{ width: '100%' }} size={0}>
			<UserGridCardResumeExperienceEntryLine entry={latestEntry} />
			{expandLink}
			{/* {open && ( */}
			<div
				style={{
					maxHeight: open ? 20000 : 0,
					opacity: open ? 1 : 0,
					transition: 'all 1s ease-in-out',
					overflow: 'hidden',
				}}
			>
				<Space
					direction='vertical'
					size={40}
					style={{
						marginTop: 10,
						marginBottom: 10,
						borderRadius: 4,
						background: theme.color.bgGrey,
						padding: 20,
						// padding: `${open ? 20 : 0}px 20px`,
					}}
				>
					<Space
						direction='vertical'
						size={20}
					>
						<h4>Experiences</h4>
						<UserGridCardResumeExperienceList
							items={experiences}
							withExpander={false}
						/>
					</Space>

					{educations?.length && (
						<Space
							direction='vertical'
							size={20}
						>
							<h4>Education</h4>
							<UserGridCardResumeEducationList
								withExpander={false}
								items={educations}
							/>
						</Space>
					)}

				</Space>
				{open && expandLink}
			</div>
			{/* )} */}
		</Space>,
	];

	return (
		<UserGridCardLabelValue
			label={label}
			{...props}
		>
			<UserGridCardValueList
				items={items}
				placement={placement}
				defaultContainer={defaultContainer}
				withSeparator={withSeparator}
				itemStyle={itemStyle}
			/>
		</UserGridCardLabelValue>
	);
};

export default UserGridCardResumeLatestExperienceExpandable;
