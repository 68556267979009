import StaffToolsPage from '../../containers/staff/StaffToolsPage';
import { onEnterBuilder } from '../../actions/app';
import { PERMS_GRANT_STAFF } from '../menu.utils';
import MenuBase from '../MenuBase';
import { ToolOutlined } from '@ant-design/icons';

export const StaffToolsIcon = ToolOutlined;

class StaffToolsMenu extends MenuBase {
	static props = {
		name: 'staff-tools',
		path: '/staff/tools',
		label: 'Tools',
		icon: <StaffToolsIcon />,
		iconLegacy: '🧰',
		Component: StaffToolsPage,
		onEnterProps: {
			shouldLoadAtsData: true,
			shouldLoadCategoryTags: true,
		},
		perms: PERMS_GRANT_STAFF,
	};

	constructor() {
		super(StaffToolsMenu.props);
	}
}

export default StaffToolsMenu;
