import { useEffect, useState } from 'react';
import { useAuthContext } from '../../../hooks/useAuthContext';
import TableColumns from '../../app/table/TableColumns';
import { FaEye, FaPaperPlane } from 'react-icons/fa';
import { useTheme } from '@emotion/react';
import userService from '../../../services/user';
import UserStub from '../stub/UserStub';
import JobTable from './JobTable';
import { localDebug } from '../../../utils/LocalDebug';
import { documentId } from '../../../utils/common';

const dateSorter = (ad, bd, order) => {
	if (!ad) return order === 'descend' ? -1 : 1;
	if (!bd) return order === 'descend' ? 1 : -1;
	return bd - ad;
};

const UserJobActions = (
	{
		user,
		withUserStub = false,
	},
) => {
	// context
	const { isStaff, isStaffView, companyRecruiterDocIds } = useAuthContext();

	// theme
	const theme = useTheme();

	// state
	const [dataSource, setDataSource] = useState([]);
	const [isLoading, setIsLoading] = useState(false);

	const fetchData = async (userId, options = {}) => {
		const { data: { actions } } = await userService.getJobActions(userId, options);
		localDebug('UserJobActions fetchData', actions);
		setDataSource(processData(actions));
		setIsLoading(false);
	};

	const processData = (data) => {
		let jobData = [];
		Object.entries(data || {})
			.forEach(([action, actions]) => {
				actions
					.filter((a) => isStaffView || companyRecruiterDocIds.includes(a.job?.companyId))
					.forEach((a, i) => {
						try {
							const [row] = [
								...jobData.filter((r) => r.jobId?.toString() === a.jobId?.toString()),
								{
									'jobId': a.jobId,
									'job': a.job,
									'read': { count: 0 },
									'apply-submit': { count: 0 },
									'coaching-submit': { count: 0 },
								},
							];
							row[action].count += 1;
							// localDebug(a.createdAt, new Date(a.createdAt), Math.min(new Date(), a.createdAt), new Date() - a.createdAt);
							if (a.createdAt) {
								row[action].firstAt = row[action]?.firstAt
									// ? moment.min(moment(row[action].firstAt), moment(a.createdAt)).toDate()
									? Math.min(row[action].firstAt, new Date(a.createdAt))
									: new Date(a.createdAt);
								row[action].lastAt = row[action]?.lastAt
									// ? moment.max(moment(row[action].lastAt), moment(a.createdAt)).toDate()
									? Math.max(row[action].lastAt, new Date(a.createdAt))
									: new Date(a.createdAt);
							}
							jobData = [
								...jobData.filter((r) => !(r.jobId?.toString() === a.jobId?.toString())),
								row,
							];
						} catch (e) {
							localDebug(e);
						}
					});
			});
		return jobData;
	};

	useEffect(() => {
		setIsLoading(true);
		localDebug('useEffect', { user });
		if (user) {
			fetchData(documentId(user));
		} else {
			setIsLoading(false);
			setDataSource([]);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [user]);

	const columns = [
		TableColumns.jobStubColumn(theme, { fixed: 'left' }),
		TableColumns.docsCountColumn(theme, {
			title: 'Reads',
			dataIndex: ['read', 'count'],
			icon: <FaEye style={{ marginBottom: -2 }}/>,
			sorter: (a, b) => (a.read?.count > b.read?.count ? 1 : -1),
		}),
		TableColumns.dateColumn(theme, {
			title: 'First read',
			dataIndex: ['read', 'firstAt'],
			sorter: (a, b, order) => dateSorter(a.read?.firstAt, b.read?.firstAt, order),
		}),
		TableColumns.dateColumn(theme, {
			title: 'Last read',
			dataIndex: ['read', 'lastAt'],
			sorter: (a, b, order) => dateSorter(a.read?.lastAt, b.read?.lastAt, order),
		}),
		TableColumns.docsCountColumn(theme, {
			title: 'Applications',
			dataIndex: ['apply-submit', 'count'],
			icon: <FaPaperPlane style={{ marginBottom: -2 }}/>,
			sorter: (a, b) => (a['apply-submit']?.count > b['apply-submit']?.count ? 1 : -1),
		}),
		TableColumns.dateColumn(theme, {
			title: 'First apply',
			dataIndex: ['apply-submit', 'firstAt'],
			sorter: (a, b, order) => dateSorter(a['apply-submit']?.firstAt, b['apply-submit']?.firstAt, order),
		}),
		TableColumns.dateColumn(theme, {
			title: 'Last apply',
			dataIndex: ['apply-submit', 'lastAt'],
			sorter: (a, b, order) => dateSorter(a['apply-submit']?.lastAt, b['apply-submit']?.lastAt, order),
		}),
		// TableColumns.docsCountColumn(theme, { title: 'Coachings', dataIndex: ['coaching-submit', 'count'] }),
	];

	const handleFilter = (values) => {
		localDebug('values', values);
		fetchData(documentId(user), values);
	};

	const handleClearFilter = () => {
		fetchData(documentId(user));
	};

	return (user
		&& <>
			{
				withUserStub
				&& <div style={{
					border: '1px solid #eee', borderRadius: 4, padding: '5px 12px', marginBottom: 20,
				}}>
					<UserStub user={user}/>
				</div>
			}
			<JobTable
				user={user}
				columns={columns}
				dataSource={dataSource}
				onFilter={handleFilter}
				isLoading={isLoading}
				onClearFilter={handleClearFilter}
			/>
		</>
	);
};

export default UserJobActions;
