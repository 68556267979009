import { useTheme } from '@emotion/react';
import { useEffect, useState } from 'react';
import { FaEye, FaPaperPlane } from 'react-icons/fa';
import { useAuthContext } from '../../../hooks/useAuthContext';
import JobService from '../../../services/jobs';
import TableColumns from '../../app/table/TableColumns';
import UserTable from '../UserTable';
import { documentId } from '../../../utils/common';

const dateSorter = (ad, bd, order) => {
	if (!ad) return order === 'descend' ? -1 : 1;
	if (!bd) return order === 'descend' ? 1 : -1;
	return bd - ad;
};

const JobActionTable = ({ job }) => {
	const { isStaffView, companyRecruiterDocIds } = useAuthContext();

	const [isRefreshDataLayout, setIsRefreshDataLayout] = useState(0);
	const [dataSource, setDataSource] = useState([]);

	const theme = useTheme();

	const handleRefresh = () => {
		setIsRefreshDataLayout((prev) => prev + 1);
	};

	const userStubCol = TableColumns.userStubColumn(theme, {
		fixed: 'left',
		onRefresh: handleRefresh,
	});
	const readsCountCol = TableColumns.docsCountColumn(theme, {
		title: 'Reads',
		dataIndex: ['read', 'count'],
		icon: <FaEye style={{ marginBottom: -2 }}/>,
		sorter: (a, b) => (a.read?.count > b.read?.count ? 1 : -1),
	});
	const firstReadAtCol = TableColumns.dateColumn(theme, {
		title: 'First read',
		dataIndex: ['read', 'firstAt'],
		sorter: (a, b, order) => dateSorter(a.read?.firstAt, b.read?.firstAt, order),
	});
	const lastReadAtCol = TableColumns.dateColumn(theme, {
		title: 'Last read',
		dataIndex: ['read', 'lastAt'],
		sorter: (a, b, order) => dateSorter(a.read?.lastAt, b.read?.lastAt, order),
	});
	const applysCountCol = TableColumns.docsCountColumn(theme, {
		title: 'Applications',
		dataIndex: ['apply-submit', 'count'],
		icon: <FaPaperPlane style={{ marginBottom: -2 }}/>,
		sorter: (a, b) => (a['apply-submit']?.count > b['apply-submit']?.count ? 1 : -1),
	});
	const firstApplyAtCol = TableColumns.dateColumn(theme, {
		title: 'First apply',
		dataIndex: ['apply-submit', 'firstAt'],
		sorter: (a, b, order) => dateSorter(a['apply-submit']?.firstAt, b['apply-submit']?.firstAt, order),
	});
	const lastApplyAtCol = TableColumns.dateColumn(theme, {
		title: 'Last apply',
		dataIndex: ['apply-submit', 'lastAt'],
		sorter: (a, b, order) => dateSorter(a['apply-submit']?.lastAt, b['apply-submit']?.lastAt, order),
	});

	const columns = [
		userStubCol,
		readsCountCol,
		firstReadAtCol,
		lastReadAtCol,
		applysCountCol,
		firstApplyAtCol,
		lastApplyAtCol,
	];

	const createDataSource = (data) => {
		let userData = [];
		Object.entries(data?.actions || {}).forEach(([action, actions]) => {
			actions
				.filter((a) => isStaffView || companyRecruiterDocIds.includes(a.job?.companyId))
				.forEach((a, i) => {
					const [row] = [
						...userData.filter((r) => documentId(r.user) === documentId(a.user)),
						{
							'userId': a.userId,
							'user': a.user,
							'read': { count: 0 },
							'apply-submit': { count: 0 },
							'coaching-submit': { count: 0 },
						},
					];

					row[action].count += 1;
					if (a.createdAt) {
						row[action].firstAt = row[action]?.firstAt
							// ? moment.min(moment(row[action].firstAt), moment(a.createdAt)).toDate()
							? Math.min(row[action].firstAt, new Date(a.createdAt))
							: new Date(a.createdAt);
						row[action].lastAt = row[action]?.lastAt
							// ? moment.max(moment(row[action].lastAt), moment(a.createdAt)).toDate()
							? Math.max(row[action].lastAt, new Date(a.createdAt))
							: new Date(a.createdAt);
					}

					userData = [
						...userData.filter((r) => !(r.userId?.toString() === a.userId?.toString())),
						row,
					];
				});
		});

		setDataSource(userData);
	};

	useEffect(() => {
		createDataSource(job);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [job]);

	const fetchUsers = async (jobId, options = {}) => {
		const { data: { userJobActions } } = await JobService.getUserJobActions(jobId, options);
		createDataSource(userJobActions);
	};

	const handleFilter = (values) => {
		fetchUsers(documentId(job), values);
	};

	const handleClearFilter = () => {
		fetchUsers(documentId(job));
	};

	return (
		<UserTable
			columns={columns}
			dataSource={dataSource}
			isRefresh={isRefreshDataLayout}
			onFilter={handleFilter}
			onClearFilter={handleClearFilter}
		/>
	);
};

export default JobActionTable;
