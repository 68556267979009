import { Divider } from 'antd';

const GenderScoreLayoutDivider = ({
	company,
	forceValue,
	title,
	subtitle,
	id,
	orientation = 'left',
	...props
}) => {
	const className = 'GenderScoreLayoutDivider';

	return (
		<div
			id={id}
			style={{
				marginTop: 60,
				marginBottom: 24,
				...props?.style,
			}}
		>
			{/* <Divider
				id={id}
				orientation={orientation}
				style={{
					marginTop: 60,
					...props?.style?.divider,
				}}
			> */}
			<h2>{title}</h2>
			{/* </Divider> */}

			{subtitle && (
				<p
					style={{
						fontSize: 16,
						opacity: 0.7,
						marginTop: 8,
						...props?.style?.subtitle,
					}}>
					{subtitle}
				</p>
			)}
		</div>
	);
};

export default GenderScoreLayoutDivider;
