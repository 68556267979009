import { request } from './api';

const invalidateTags = async () => {
	return request.get('/admin/tag/invalidate');
};

const resetTags = async () => {
	return request.get('/admin/tag/reset');
};

const updateTags = async () => {
	return request.get('/admin/tag/update');
};

const updateUserTags = async (params = {}) => {
	return request.get('/admin/tag/updateUsers', { params });
};

const updateUserProperty = async ({ userId, key, value }) => {
	return request.post('/admin/user/update-property', { params: { userId, key, value } });
};

const updateUserPassword = async ({ email, password }) => {
	return request.post('/admin/user/update-password', { email, password });
};

const updateUserSalaryExpectations = async () => {
	return request.get('/admin/user/salary/update');
};

const updateCompanyTags = async () => {
	return request.get('/admin/tag/updateCompanies');
};

const updateJobTags = async () => {
	return request.get('/admin/tag/updateJobs');
};

const updateArticleTags = async () => {
	return request.get('/admin/tag/updateArticles');
};

const applyInducedTags = async () => {
	return request.get('/admin/tag/applyInduced');
};

const getCampaignEmailFilters = async () => {
	return request.get('/admin/campaign-emails/filters');
};

const remapHardSkills = async () => {
	return request.get('/admin/remap-hardskills');
};

const remapLegacyCompanyPlans = async () => {
	return request.get('/admin/remap-legacy-company-plans');
};

const addUserResumeFiles = async () => {
	return request.get('/admin/add-user-resume-files');
};

const remapCandidateStages = async () => {
	return request.get('/admin/remap-candidate-stages');
};

const remapScrumMasters = async () => {
	return request.get('/admin/remap-scrum-masters');
};

const applyUserHiddenCompanies = async () => {
	return request.get('/admin/user/apply-hidden-companies');
};

const updateAutoHiddenCompanyMappings = async () => {
	return request.get('/admin/user/update-auto-hidden-company-mappings');
};

const getRecruiterInfo = async () => {
	return request.get('/acl/users?notCompanyIds=5ee967fd6d5f600038bda91c');
};

const syncAllSourcings = async () => {
	return request.get('/admin/syncAllSourcings');
};

const initializeAtsPipeline = async () => {
	return request.get('/admin/ats-pipelines-initialize');
};

const initializeAtsArchiveReasons = async () => {
	return request.get('/admin/ats-archive-reasons-initialize');
};

const checkAtsPipelineStages = async () => {
	return request.get('/admin/checkAtsPipelineStages');
};

const encryptAtsApiKeys = async () => {
	return request.get('/admin/encryptAtsApiKeys');
};

const addTestPlanCompany = async (planOption) => {
	return request.get(`/admin/test-plans/addTestPlanCompany?planValue=${planOption.value}`);
};
const addTestPlanRoles = async (planOption) => {
	return request.get(`/admin/test-plans/addTestPlanRoles?planValue=${planOption.value}`);
};
const addCompanyTestRole = async ({
	companyId,
	roleId,
	password,
	withEmail,
	withLoginPopup,
}) => {
	return request.get(`/admin/test-plans/addCompanyTestRole?companyId=${companyId}&roleId=${roleId}&password=${password}}&withEmail=${withEmail}&withLoginPopup=${withLoginPopup}`);
};

const addDemoCompany = async (ats) => {
	return request.get(`/admin/demo-ats/addDemoCompany?ats=${ats}`);
};
const addDemoAccess = async (ats, companyId) => {
	return request.get(`/admin/demo-ats/addDemoAccess?ats=${ats}&companyId=${companyId}`);
};
const removeDemoAccess = async (ats, companyId) => {
	return request.get(`/admin/demo-ats/removeDemoAccess?ats=${ats}&companyId=${companyId}`);
};
const fakeDemoSourcings = async (ats) => {
	return request.get(`/admin/demo-ats/fakeDemoSourcings?ats=${ats}`);
};
const cleanDemoSourcings = async (ats) => {
	return request.get(`/admin/demo-ats/cleanDemoSourcings?ats=${ats}`);
};

const cleanGeolocations = async () => {
	return request.get('/admin/cleanGeolocations');
};
const createGeolocations = async () => {
	return request.get('/admin/createGeolocations');
};

const getPeriodStats = async () => {
	return request.get('/stats/');
};
const getPeriodStatsLists = async () => {
	return request.get('/stats/lists');
};
const computePeriodStats = async () => {
	return request.get('/stats/compute?bustCache=true');
};
const computePeriodStatsBypassStorage = async () => {
	return request.get('/stats/compute?bustCache=true&bypassStorage=true');
};

const getUserSalaryExpectations = async () => {
	return request.get('/admin/user/salary');
};

const getJobFields = async (params) => {
	return request.get('/admin/job/fields', { params });
};

const add50inTechSavedSearches = async (params) => {
	return request.get('/admin/saved-searches/add-50intech-saved-searches', { params });
};

const fakeSavedSearchesAlerts = async (params) => {
	return request.get('/admin/saved-searches/alerts/fake', { params });
};

const clearSavedSearchesAlerts = async (params) => {
	return request.get('/admin/saved-searches/alerts/clear', { params });
};

const deleteSavedSearchesAlerts = async (params) => {
	return request.get('/admin/saved-searches/alerts/delete', { params });
};

const computeSavedSearchesAlerts = async (params) => {
	return request.get('/admin/saved-searches/alerts/compute', { params });
};

const computeSavedSearchesDigests = async (params) => {
	return request.get('/admin/saved-searches/digests/compute', { params });
};

const sendFakeSavedSearchDigests = async (params) => {
	return request.get('/admin/saved-searches/digests/fake', { params });
};

const addCoachingCoachedAt = async (params) => {
	return request.get('/admin/coaching/add-coaching-coached-at', { params });
};

const computeFunnels = async (params) => {
	return request.get('/admin/stats/compute-funnels', { params });
};

const retryFailedUserMailings = async (params) => {
	return request.get('/admin/user-mailing/retry-failed', { params });
};

const refreshAllUserMailingsStatus = async (params) => {
	return request.get('/admin/user-mailing/refresh-user-mailings-status', { params });
};

const refreshMailjetContactsData = async (params) => {
	return request.get('/admin/mailjet/refresh-mailjet-contacts-data', { params });
};

const checkMailjetHealth = async (params) => {
	return request.get('/admin/mailjet/check-health', { params });
};

const getOpenAIJob = async (params) => {
	return request.get('/admin/openai/job', { params });
};

const getRandomUserSlug = async (params) => {
	return request.get('/admin/user/random-slug', { params });
};

const AdminService = {
	invalidateTags,
	resetTags,
	updateTags,
	updateUserTags,
	updateUserSalaryExpectations,
	updateCompanyTags,
	updateJobTags,
	updateArticleTags,
	applyInducedTags,
	getCampaignEmailFilters,
	remapHardSkills,
	remapLegacyCompanyPlans,
	applyUserHiddenCompanies,
	updateAutoHiddenCompanyMappings,
	getRecruiterInfo,
	syncAllSourcings,
	initializeAtsPipeline,
	initializeAtsArchiveReasons,
	checkAtsPipelineStages,
	addUserResumeFiles,
	remapCandidateStages,
	remapScrumMasters,
	cleanGeolocations,
	createGeolocations,
	getPeriodStatsLists,
	getPeriodStats,
	computePeriodStats,
	computePeriodStatsBypassStorage,
	getUserSalaryExpectations,
	getJobFields,

	add50inTechSavedSearches,
	fakeSavedSearchesAlerts,
	clearSavedSearchesAlerts,
	deleteSavedSearchesAlerts,
	computeSavedSearchesAlerts,
	computeSavedSearchesDigests,
	sendFakeSavedSearchDigests,
	addCoachingCoachedAt,
	computeFunnels,
	retryFailedUserMailings,
	refreshAllUserMailingsStatus,
	refreshMailjetContactsData,
	checkMailjetHealth,

	encryptAtsApiKeys,

	addTestPlanCompany,
	addTestPlanRoles,
	addCompanyTestRole,

	addDemoCompany,
	addDemoAccess,
	removeDemoAccess,
	fakeDemoSourcings,
	cleanDemoSourcings,

	updateUserProperty,
	updateUserPassword,

	getOpenAIJob,

	getRandomUserSlug,
};

export default AdminService;
