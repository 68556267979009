import QUESTIONS from '../../questions';
import { GENDERSCORE_PILLAR_EQUALPAY_OPTION } from '../../../../../constants/genderscore-pillars';

const section = {
	...GENDERSCORE_PILLAR_EQUALPAY_OPTION,
	details: `
### On average, women in Tech earn ~€400,000 less than men over their working life. 

For 50inTech's women community, compensation transparency and concrete steps that tackle the gender pay gap are among the most important criteria when choosing their future employer.`,
	questions: [
		QUESTIONS.V3_EP_REVIEW_EQUALPAY_REVIEW,
		{
			...QUESTIONS.V3_EP_REVIEW_EQUALPAY_REVIEW_METHOD,
			clause: (answers) => answers?.[QUESTIONS.V3_EP_REVIEW_EQUALPAY_REVIEW.questionId] !== 'no',
		},
		{
			...QUESTIONS.V3_EP_REVIEW_ADJUSTED_PAYGAP,
			clause: (answers) => answers?.[QUESTIONS.V3_EP_REVIEW_EQUALPAY_REVIEW.questionId] === 'yes',
		},
		{
			...QUESTIONS.V3_EP_REVIEW_UNADJUSTED_PAYGAP,
			clause: (answers) => answers?.[QUESTIONS.V3_EP_REVIEW_EQUALPAY_REVIEW.questionId] === 'yes',
		},
		QUESTIONS.V3_EP_REVIEW_PAY_DISPARITY_METHODS,
		QUESTIONS.V3_EP_TRANSPARENCY_PAYGAP_SHARING,
		QUESTIONS.V3_EP_TRANSPARENCY_SALARY_GRID,
		QUESTIONS.V3_EP_TRANSPARENCY_SALARY_POLICY,
		QUESTIONS.V3_EP_COMPENSATION_EQUITY,
		QUESTIONS.V3_EP_COMPENSATION_MATERNITY_RAISE,
	],
};

export default section;
