import { Empty, Space } from 'antd';
import { useTheme } from '@emotion/react';
import { useContext, useEffect, useState } from 'react';
import Link from '../app/Link';
import { documentId, mixColors } from '../../utils/common';
import Grid from '../app/grid/Grid';
import UserGridCardSkeleton from '../user/grid/card/UserGridCardSkeleton';
import UserGridCard from '../user/grid/card/UserGridCard';
import GridEmptyData from '../app/grid/GridEmptyData';
import { UserSavedSearchContext } from './UserSavedSearchProvider';
import { DataFetcherContext } from '../app/data/DataFetcherProvider';

const SearchTalentsUserGrid = (
	{
		onCreateSavedSearch,
		onEditSavedSearch,
	},
) => {
	const className = 'SearchTalentsUserGrid';

	const theme = useTheme();

	const {
		selectedSavedSearch, savedSearches, excludedViewed, setExcludedViewed,
		selectedPage, MY_SEARCHES_PAGE, onUserViewed, mySavedSearches,
		isLoading: isSavedSearchesLoading,
	} = useContext(UserSavedSearchContext);

	const {
		refresh,
	} = useContext(DataFetcherContext);

	const [empty, setEmpty] = useState(<GridEmptyData/>);

	useEffect(() => {
		const editLink = selectedSavedSearch && !selectedSavedSearch?.is50
			? <Link onClick={() => onEditSavedSearch(selectedSavedSearch)}>Edit current search</Link>
			: null;
		let links = editLink;
		if ((!selectedSavedSearch || !selectedSavedSearch?.is50)
			&& (!mySavedSearches || mySavedSearches?.length === 0)
			&& (selectedPage === MY_SEARCHES_PAGE || !selectedPage)) {
			links = <Link onClick={onCreateSavedSearch}>Create your first search</Link>;
		}
		if (selectedSavedSearch && excludedViewed) {
			links = <Space size={30}>
				<Link onClick={() => setExcludedViewed(false)}>Include viewed profiles</Link>
				{editLink}
			</Space>;
		}
		setEmpty(
			<GridEmptyData>
				<Empty
					// image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
					imageStyle={{ height: 120 }}
					description={<span>No profiles found</span>}
				>
					{links}
				</Empty>
			</GridEmptyData>,
		);
	}, [excludedViewed, mySavedSearches, selectedSavedSearch, selectedPage]);

	return (
		<>
			<Grid
				columns={1}
				skeletonCard={<UserGridCardSkeleton/>}
				empty={empty}
				forceLoading={isSavedSearchesLoading}
				cardRender={(row, isDataLoading) => (
					<UserGridCard
						user={{
							...row,
							isNewId: selectedSavedSearch?.alerts?.includes(documentId(row)),
						}}
						loading={isDataLoading}
						onUserViewed={onUserViewed}
						onRefresh={refresh}
						// if savedSearchId is set, it will be sent when viewing a user
						// to add the userId to the saved search log in viewedUserIds
						savedSearchId={documentId(selectedSavedSearch)}
						style={{
							hardSkills: {
								background: mixColors(theme.color.white, theme.color.fitBlueElectric, 5),
								border: 'none',
								fontSize: 12,
								borderRadius: 30,
								color: theme.color.fitBlueElectric,
							},
						}}
					/>
				)
				}
			/>
		</>
	);
};

export default SearchTalentsUserGrid;
