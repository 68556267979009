import { useTheme } from '@emotion/react';
import { BiLinkExternal } from 'react-icons/bi';
import { getCloudinaryPublicId, documentId } from '../../../../utils/common';
import { Space } from 'antd';
import Box from '../../../app/box/Box';
import { GENDERSCORE_PILLAR_DEIACTIONS_VALUE, getPillarLabel } from '../../../../constants/genderscore-pillars';
import GenderScorePillarLogoLabel from '../pillars/GenderScorePillarLogoLabel';
import { Image, Transformation } from 'cloudinary-react';
import { useMemo } from 'react';
import {
	GENDERSCORE_LOG_FROM_ELEMENT_RESOURCE_CARD_LINK,
	writeGenderScoreLog,
} from '../../../../constants/genderscore-logs';
import { FaStar } from 'react-icons/fa';
import GenderScoreResourceImage from './GenderScoreResourceImage';
import GenderScoreResource50Banner from './GenderScoreResource50Banner';

const TagListSeparator = (
	{
		defaultContainer = 'span',
		placement = 'right',
		withSeparator = true,
		children,
		...props
	},
) => {
	const theme = useTheme();

	// const separator = <span style={{ opacity: .2 }}>&nbsp;\ </span>;
	const separator = placement === 'right'
		? <span style={{ opacity: 0.7, color: props?.separatorColor || theme.color.fitBlueElectric }}>&nbsp;• </span>
		: <span style={{ opacity: 0.7, color: props?.separatorColor || theme.color.fitBlueElectric }}> •&nbsp;</span>;

	return (
		<ListSeparator
			defaultContainer={defaultContainer}
			placement={placement}
			separator={separator}
			withSeparator={withSeparator}
			{...props}
		>
			{children}
		</ListSeparator>
	);
};

const GenderScoreResourceRow = ({
	company,
	pillar,
	resource = {},
	withPillarHeader = false,
	withPillarTags = false,
	withTags = false,
	...props
}) => {
	const className = 'GenderScoreResourceRow';

	const theme = useTheme();

	const openLink = (selectedResource, target = '_blank') => {
		writeGenderScoreLog({
			type: GENDERSCORE_LOG_FROM_ELEMENT_RESOURCE_CARD_LINK,
			context: documentId(selectedResource) || selectedResource?.title,
			company,
		});
	};

	const buildPillarContent = ({
		pillar, withLogo, withLabel, withTooltip,
	}) => {
		return (
			<GenderScorePillarLogoLabel
				pillar={pillar}
				withLogo={withLogo}
				withLabel={withLabel}
				withTooltip={withTooltip}
				tooltipTitle={withLabel
					? null
					: <>This resource is useful to improve in the <strong>{getPillarLabel(pillar)}</strong> pillar</>
				}
			/>
		);
	};

	const buildPillarListContent = ({ withLogo, withLabel, withTooltip }) => {
		return (
			<Space
				size={8}
			>
				{(
					resource.pillars?.length > 0
						? resource.pillars
						: [GENDERSCORE_PILLAR_DEIACTIONS_VALUE]
				)
					?.filter((pillar) => getPillarLabel(pillar))
					?.map((pillar) => (
						<div key={pillar}>
							{buildPillarContent({
								pillar, withLogo, withLabel, withTooltip,
							})}
						</div>
					))}

			</Space>
		);
	};

	const memoizedContent = useMemo(() => {
		return (
			<div
				style={{
					position: 'relative',
				}}
			>
				<Box
					style={{
						padding: 10,
						// paddingBottom: 60,
						overflow: 'hidden',
						background: 'white',
					}}
				>
					<Space
						size={0}
						style={{
							justifyContent: 'flex-start',
							alignItems: 'flex-start',
						}}
					>

						<GenderScoreResourceImage
							resource={resource}
							width={160}
							height={90}
							style={{
								borderRadius: 5,
								width: 82,
								height: 46,
							}}
						/>

						<Space
							direction='vertical'
							size={0}
							style={{ padding: '0 12px' }}
						>
							<h5
								style={{
								// marginTop: 24,
								// marginBottom: 6,
									lineHeight: 1.3,
								}}
							>
								{resource.title}
							</h5>

							<div
								style={{
									fontSize: 10,
									opacity: 0.5,
									// marginBottom: 8,
								}}
							>
								<strong>{resource.type}</strong>&nbsp;
								<span>
									from <strong>{resource.source}</strong>
								</span>
							</div>

							<div
								className='ellipsis'
								style={{
									fontSize: 12,
									opacity: 0.7,
								}}
							>
								{resource.summary}
							</div>

							{withPillarTags && buildPillarListContent({ withLabel: false, withLogo: true, withTooltip: true })}

							{/* {withTags && (
								<TagListSeparator
									style={{
										marginTop: 18,
										separatorColor: 'purple',
									}}
								>
									{resource.tags?.map?.((tag) => (
										<span
											key={tag}
											style={{
												fontSize: 10,
												color: 'purple',
											}}
										>
											{tag}
										</span>
									))}
								</TagListSeparator>
							)} */}
						</Space>

						<a
							href={resource.url}
							target='_blank'
							rel="noreferrer"
							onClick={() => openLink(resource)}
							style={{
								// bottom: 24,
								// right: 24,
								// marginTop: 12,
							}}
						>
							<span>Open&nbsp;</span>

							<BiLinkExternal
								style={{
									marginBottom: -2,
								}}
							/>
						</a>

					</Space>

				</Box>

				{resource.source?.indexOf('50inTech') >= 0 && (
					<GenderScoreResource50Banner
						style={{
							position: 'absolute',
							top: 59,
							left: 15,
							fontSize: 9,
						}}
					/>
				)}

			</div>
		);
	}, [resource, withPillarHeader, withPillarTags, withTags]);

	return memoizedContent;
};

export default GenderScoreResourceRow;
