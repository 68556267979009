import { Space } from 'antd';
import { useTheme } from '@emotion/react';
import { useEffect, useRef, useState } from 'react';
import moment from 'moment';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';
import Link from '../../../../app/Link';
import UserGridCardLabelValue from './UserGridCardLabelValue';
import UserModel from '../../../../../models/UserModel';
import { displayHtml, getDocumentScrollTop } from '../../../../../utils/common';
import BoxRainbow from '../../../../app/box/BoxRainbow';
import UserAvatar from '../../../widgets/UserAvatar';
import { LocalDebug } from '../../../../../utils/LocalDebug';

const UserGridCardCoachingOpinion = (
	{
		user,
		opened,
		...props
	},
) => {
	const className = 'UserGridCardCoachingOpinion';

	const theme = useTheme();
	const refContainer = useRef();

	const [scroll, setScroll] = useState();
	const [open, setOpen] = useState(false);
	const [containerHeight, setContainerHeight] = useState(0);
	const [transitionDuration, setTransitionDuration] = useState(1);

	useEffect(() => {
		if (refContainer.current) {
			LocalDebug.logUseEffect({ className, effects: '' }, refContainer?.current?.offsetHeight);
			setContainerHeight(refContainer.current.offsetHeight + 12);
			setTransitionDuration(Math.round(Math.min(1, containerHeight / 800) * 10) / 10);
		}
	}, []);

	const userModel = new UserModel(user);

	const openLink = (
		<Link
			onClick={() => {
				setScroll(getDocumentScrollTop());
				setOpen(true);
			}}
			style={{ fontSize: 12 }}
		>
			<FaChevronDown style={{ marginBottom: -2 }}/> Read the summary of the coaching
		</Link>
	);

	const closeLink = (
		<Link
			onClick={() => {
				window.scrollTo({ top: Math.min(getDocumentScrollTop(), scroll), behavior: 'smooth' });
				setOpen(false);
			}}
			style={{ fontSize: 12 }}
		>
			<FaChevronUp style={{ marginBottom: -2 }}/> Close summary
		</Link>
	);
	return (
		<UserGridCardLabelValue
			label={null}
			{...props }
			labelStyle={{}}
			valueStyle={{
				fontSize: 12,
				width: '100%',
			}}
			style={{
				width: '100%',
			}}
		>
			<BoxRainbow
				mode={'left'}
				borderSize={6}
				bgColor={theme.color.bgGrey}
				style={{
					width: 'calc(100% - 88px)',
					padding: '20px 20px 20px 20px',
					fontWeight: 'normal',
					// borderRadius: 0,
					// ...!open ? { background: theme.color.bgGrey } : {},
				}}
			>
				<Space
					direction='vertical'
					size={0}
					style={{
						width: '100%',
						transition: 'all 1s ease-in-out',
						overflow: 'hidden',
					}}
				>
					<Space
						style={{
							color: theme.color.darkgrey,
							fontSize: 12,
							width: '100%',
						}}
					>
						<UserAvatar user={user?.coach}/>
						<Space direction='vertical' size={0} style={{ width: '100%' }}>
							<span>Pre-screened by <b>{user?.coach?.firstName}</b>, {moment(user?.coaching?.coachedAt || user.coaching?.lastStatusAt).fromNow()}</span>
							{open ? closeLink : openLink}
						</Space>
					</Space>

					<div
						style={{
							fontSize: 12,
							lineHeight: 1.5,
							maxHeight: open ? containerHeight : 0,
							opacity: open ? 1 : 0,
							transition: `all ${transitionDuration}s ease-in-out`,
							overflow: 'hidden',
							...props?.style?.body,
						}}
					>
						<div
							ref={refContainer}
						>
							{displayHtml(userModel.getCoachingOpinion())}
						</div>
					</div>
					{/* )} */}
				</Space>
			</BoxRainbow>
		</UserGridCardLabelValue>
	);
};

export default UserGridCardCoachingOpinion;
