import { documentId } from '../utils/common';
import { request } from './api';

const getAll = (params, subPath = '') => {
	const dataParams = {
		params: {
			sortField: 'atsCreatedAt',
			sortOrder: 'descend',
			...params || {},
		},
	};
	return request.get(`/jobs${subPath}`, dataParams);
};

const getJobs = (params) => {
	return request.get('/jobs', { params });
};

const getJob = (jobId) => {
	return request.get(`/jobs/${jobId}`);
};

const published = (data) => {
	return request.post('/jobs/published', data);
};

const fetchJob = (data) => {
	return request.post('/jobs/fetch', { companyId: data?.companyId, id: documentId(data) });
};

const updateJobTags = (data) => {
	return request.post('/jobs/tag', { id: documentId(data) });
};

const deleteJobs = (jobIds = []) => {
	return request.put('/jobs', { jobIds });
};

const updateOrCreateJob = (data) => {
	return request.post('/jobs', data);
};

const getGroupTags = () => {
	return request.get('/jobs/tags');
};

const getUserJobActions = (jobId, params = {}) => {
	return request.get(`/jobs/${jobId}/jobActions`, { params });
};

const getAllItems = ({ companyId }) => {
	return request.get('/jobs/allItems', { params: { companyId } });
};

const JobService = {
	getAll,
	published,
	deleteJobs,
	fetchJob,
	updateJobTags,
	updateOrCreateJob,
	getAllItems,
	getJobs,
	getJob,
	getGroupTags,
	getUserJobActions,
};
export default JobService;
