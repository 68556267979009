import { Checkbox, Form } from 'antd';
import { useTheme } from '@emotion/react';
import { useSelector } from 'react-redux';
import Button from '../../../../app/button/Button';
import FormItem from '../../../../app/form/FormItem';
import FormSelect from '../../../../app/form/FormSelect';
import Conditions from './Conditions';
import { useEffect, useState } from 'react';
import { schemaOptions } from '../../../../../constants/constant';
import TagInput from './TagInput';
import { getAllTags } from '../../../../../actions/tags';
import { useDebouncedEffect } from '../../../../../hooks/useDebounceEffect';
import { getTagsCategory } from '../../../../../reducers/app';
import { documentId, requiredRule, requiredTrimRule } from '../../../../../utils/common';

const documentRefOptions = [
	{
		value: 'Job',
		label: 'Job',
	},
	{
		value: 'User',
		label: 'User',
	},
	{
		value: 'Company',
		label: 'Company',
	},
	{
		value: 'Article',
		label: 'Article',
	},
];

const associationOptions = [{ value: 'OR', label: 'OR' }, { value: 'AND', label: 'AND' }];

const TagRuleForm = (
	{
		initialValues = { conditions: [{ documentField: '' }], tagSlugs: [{ slugCategory: '' }] },
		isEditor,
		onSubmit,
		onReset,
	},
) => {
	const [form] = Form.useForm();
	const theme = useTheme();

	const [schemaSelected, setSchemaSelected] = useState([]);
	const [searchTag, setSearchTag] = useState('');
	const [tagSlugOptions, setTagSlugOptions] = useState([]);
	const [isPublished, setIsPublished] = useState(false);

	const tagsCategory = useSelector(getTagsCategory);
	const tagCategoryOptions = tagsCategory.map((tag) => ({ value: documentId(tag), label: documentId(tag) }));

	const handleSearchTag = (value) => {
		setSearchTag(value);
	};

	// ------------------------------------------------------ //
	// ----------------- handle search ---------------------- //
	// ------------------------------------------------------ //
	useDebouncedEffect(async () => {
		if (searchTag !== '') {
			const tags = await getAllTags({ search: searchTag });
			setTagSlugOptions(tags.map((tag) => ({ label: tag.slug, value: tag.slug })));
		}
	}, [searchTag], 250);

	const handleSubmit = (values) => {
		const { tagSlugs: tagSlugsToSave, ...othersValues } = values;
		const tagSlugs = tagSlugsToSave.map((tag) => tag.slugValue);

		if (onSubmit) {
			onSubmit({
				...othersValues, tagSlugs, isPublished, id: documentId(initialValues),
			});
		}
	};

	const handleChangeCollection = (value) => {
		if (value && value !== '') {
			const schema = schemaOptions.get(value);
			setSchemaSelected(schema.map((item) => ({ label: item, value: item })));
		}
	};

	const handleIsPublished = (event) => {
		setIsPublished(event.target.checked);
	};

	useEffect(() => {
		form.resetFields();
		form.setFieldsValue(initialValues);
		setIsPublished(initialValues?.isPublished);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [initialValues]);

	return (
		<Form
			form={form}
			name="tag-form"
			initialValues={{ ...initialValues }}
			onFinish={handleSubmit}
		>
			<div style={{ marginBottom: 20 }}>
				<FormItem
					label="Name"
					name="name"
					defaultContainer="div"
					rules={[requiredTrimRule]}
					styles={{
						rootContainer: {
							display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: 20,
						},
						label: { marginBottom: 0, paddingRight: 10, width: 130 },
						itemContainer: { marginBottom: 0 },
						container: { flex: 1 },
						input: { height: 46 },
					}}
				/>
				<FormItem
					label="Collection"
					name="documentRef"
					defaultContainer="div"
					rules={[requiredRule]}
					styles={{
						rootContainer: {
							display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: 20,
						},
						label: { marginBottom: 0, paddingRight: 10, width: 130 },
						itemContainer: { marginBottom: 0 },
						container: { flex: 1 },
						input: { height: 46 },
					}}
				>
					<FormSelect options={documentRefOptions} placeholder="Collection"
						onChange={handleChangeCollection}/>
				</FormItem>
				<FormItem
					label="Association"
					name="association"
					defaultContainer="div"
					rules={[requiredRule]}
					styles={{
						rootContainer: {
							display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: 20,
						},
						label: { marginBottom: 0, paddingRight: 10, width: 130 },
						itemContainer: { marginBottom: 0 },
						container: { flex: 1 },
						input: { height: 46 },
					}}
				>
					<FormSelect options={associationOptions} placeholder="Association"/>
				</FormItem>

				<TagInput
					onSearchTag={handleSearchTag}
					tagSlugOptions={tagSlugOptions}
					tagCategoryOptions={tagCategoryOptions}
				/>
				<Conditions defaultSchemaOptions={schemaSelected}/>

				{isEditor && documentId(initialValues) && <FormItem
					label="Published"
					name="isPublished"
					defaultContainer="div"
					rules={[requiredRule]}
					styles={{
						rootContainer: {
							display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: 20,
						},
						label: { marginBottom: 0, paddingRight: 10, width: 130 },
						itemContainer: { marginBottom: 0 },
						container: { flex: 1 },
						input: { height: 46 },
					}}
				>
					<Checkbox value={true} checked={isPublished} onChange={handleIsPublished}/>
				</FormItem>
				}

			</div>
			<div style={{
				display: 'flex',
				flexDirection: 'row-reverse',
			}}>
				<Button
					type='default'
					style={{
						margin: 5,
						background: theme.color.blue,
						borderColor: theme.color.blue,
						color: theme.color.white,
						fontWeight: 'bold',
						borderRadius: 5,
					}}
					htmlType='submit'
				>
					Save
				</Button>
				<Button
					type='default'
					style={{
						margin: 5,
						borderColor: theme.color.orange,
						fontWeight: 'bold',
						borderRadius: 5,
						color: theme.color.orange,
					}}
					htmlType='reset'
					onClick={onReset}
				>
					Cancel
				</Button>
			</div>
		</Form>
	);
};

export default TagRuleForm;
