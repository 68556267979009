import { Space } from 'antd';
import { useDispatch } from 'react-redux';
import { useTheme } from '@emotion/react';
import { FaMap, FaMapPin, FaRegTrashAlt } from 'react-icons/fa';
import { useAuthContext } from '../../../hooks/useAuthContext';
import config from '../../../config/config';
import * as adminActions from '../../../actions/admin';
import AdminToolsSectionContainer from './AdminToolsSectionContainer';
import { buildConfirmBtn, buildConfirmBtnStyleLarge } from '../../../utils/common';

const AdminToolsGeolocationSection = () => {
	const theme = useTheme();
	const dispatch = useDispatch();

	const { isAdmin, currentUser } = useAuthContext();

	if (!currentUser) return null;
	if (!isAdmin) return null;
	if (!config.isNotProdEnv) return null;

	return (
		<AdminToolsSectionContainer title='Geolocation' Icon={FaMapPin}>
			<Space direction='horizontal' size={4} wrap={true}>
				{buildConfirmBtn({
					theme, 
					title: 'Clear all geolocations',
					Icon: FaRegTrashAlt,
					dispatch,
					dispatcher: adminActions.cleanGeolocations,
					style: { ...buildConfirmBtnStyleLarge, color: theme.color.orange },
				})}
				{buildConfirmBtn({
					theme, 
					title: 'Create geolocations',
					Icon: FaMap,
					dispatcher: adminActions.createGeolocations,
					dispatch,
				})}
			</Space>
		</AdminToolsSectionContainer>
	);
};

export default AdminToolsGeolocationSection;
