import { useTheme } from '@emotion/react';
import { Space } from 'antd';
import CompanyGenderScoreRingScore from '../../CompanyGenderScoreRingScore';
import { getPillarColorMain, getPillarPath } from '../../../../../constants/genderscore-pillars';
import GenderScorePillarTitleBlock from '../blocks/GenderScorePillarTitleBlock';
import GenderScoreComparisonLineBlock from '../blocks/GenderScoreComparisonLineBlock';
import CompanyModel from '../../../../../models/CompanyModel';
import GenderScoreMedalBlock from '../blocks/GenderScoreMedalBlock';
import { Link } from 'react-router-dom';
import { ArrowRightIcon } from '../../../../../utils/common';

const CompanyGenderScorePillarPerformanceBox = ({
	company,
	pillar,
	index,
	width = '100%',
	fontSize = 14,
	withTitleLeftAlign = true,
	...props
}) => {
	const className = 'CompanyGenderScorePillarPerformanceBox';

	const theme = useTheme();
	const CompareRing = ({ forceScore }) => <CompanyGenderScoreRingScore
		company={company}
		pillar={pillar}
		forceScore={forceScore}
		size={27}
		style={{
			score: {
				color: theme.color.fitBlack,
				opacity: 0.88,
			},
		}}
		ringColor={getPillarColorMain(pillar)}
	/>;

	const companyModel = new CompanyModel(company);
	const [previousSurvey] = companyModel.getGenderScorePreviousSurveys() || [];
	const currentScore = companyModel.genderScore?.[pillar];
	const previousScore = previousSurvey?.genderScore?.[pillar];
	const avgScore = companyModel?.genderScore?.currentStatistics?.averages?.[pillar] || currentScore || 0;
	const rank = companyModel?.getGenderScoreRanking(pillar);
	const lastYearRing = <CompareRing
		forceScore={previousScore}
	/>;
	const avgRing = <CompareRing
		forceScore={avgScore}
	/>;

	return <Space
		direction='vertical'
		key={props?.key}
		style={{
			width,
			...props?.style,
		}}
	>
		<div style={{
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
		}}>
			<CompanyGenderScoreRingScore
				company={company}
				pillar={pillar}
				size={70}
				padding={3}
				style={{
					score: {
						color: theme.color.fitBlack,
						opacity: 0.88,
					},
				}}
				ringColor={getPillarColorMain(pillar)}
				girthRatio={0.07}
			/>
			<GenderScorePillarTitleBlock
				pillar={pillar} index={index}
				fontSize={fontSize} withLeftAlign={withTitleLeftAlign}
				style={{
					marginLeft: 10,
				}}/>
		</div>
		{previousScore && <GenderScoreComparisonLineBlock
			previous={previousScore}
			current={currentScore}
			withPrevious={true}
			forcePrevious={lastYearRing}
			diffLabel='pts'
			withTooltip={false}
		/>}
		<GenderScoreComparisonLineBlock
			previous={avgScore}
			current={currentScore}
			withPrevious={true}
			forcePrevious={avgRing}
			diffLabel='pts'
			secondaryLabel='vs average'
			tooltipLabel='Average'
			withTooltip={false}
		/>
		<Space style={{
			width: '100%',
			justifyContent: 'space-between',
			marginTop: 10,
		}}>
			<GenderScoreMedalBlock
				value={rank}
				fontSize={fontSize * 1.1}
				height={fontSize}
				gap={4}
				color={theme.dashboardColors.pastelBlue}
			/>
			<Link to={getPillarPath(pillar)} style={{
				fontSize,
			}}>
				{<>
				Details&nbsp;
					<ArrowRightIcon />
				</>}
			</Link>
		</Space>
	</Space>;
};
export default CompanyGenderScorePillarPerformanceBox;
