import {
	COACHING_USERS_LOADED,
	SET_USER_SELECTED,
	TAGS_USERS_LOADED,
	USERS_LOADED,
} from './types';
import { action, actionWithLoader } from './utils';

import { COMPANY_RECRUITER_LOG } from '../constants/company-recruiter-log';
import CoachingService from '../services/coaching';
import UserService from '../services/user';
import { getCompanySelected } from '../reducers/app';
import { LocalDebug, localDebug } from '../utils/LocalDebug';
import { message } from 'antd';
import { documentId, isFunction, openExternalUrl } from '../utils/common';
import { refreshCurrentUser } from './app';
import { writeRecruiterLog } from './logging';

const className = 'actions.users';

export const getAllUsers = (options = {}) => {
	const method = 'getAllUsers';
	LocalDebug.logNull({ className, method }, 'actionWithLoader dispatcher', { options });
	return actionWithLoader(async (dispatch) => {
		try {
			LocalDebug.logNull({ className, method }, 'actionWithLoader dispatcher');
			const { data } = await UserService.findAll(options);
			LocalDebug.logNull({ className, method }, 'actionWithLoader dispatcher', { data });
			dispatch({
				type: USERS_LOADED,
				users: data.users,
			});
		} catch (error) {
			LocalDebug.logError({ className, method, error });
		}
	});
};

export const getAllTagUsers = () => {
	return async (dispatch) => {
		const { data } = await UserService.getGroupTags();
		if (data.items) {
			dispatch({
				type: TAGS_USERS_LOADED,
				tagsUsers: data.items,
			});
		}
	};
};

export const loadUser = (userId) => {
	LocalDebug.logNull({ className, method: 'loadUser' }, { userId });
	return actionWithLoader(async (dispatch, getState) => {
		try {
			if (!userId) {
				LocalDebug.logNull({ className, method: 'loadUser: actionWithLoader' }, 'userId undefined, dispatching null user');
				dispatch({
					type: SET_USER_SELECTED,
					user: null,
				});
				return;
			}

			const companySelected = getCompanySelected(getState());
			// dispatch tag rule selected
			const { data } = await UserService.getUser(userId, {
				companyId: documentId(companySelected),
			});
			LocalDebug.logNull({ className, method: 'loadUser: actionWithLoader' }, { data });
			dispatch({
				type: SET_USER_SELECTED,
				user: data.user,
			});
		} catch (error) {
			localDebug(__filename.split('/').shift(), error);
		}
	});
};

export const computeJobSlider = (user) => {
	const key = user.slug;
	message.loading({ content: 'Computing job slider', key, duration: 10 });
	return action(async () => {
		try {
			await UserService.computeJobSlider(documentId(user));
			message.success({ content: 'Job slider updated', key, duration: 1 });
		} catch (error) {
			message.error({ content: error.message, key });
		}
	});
};

export const computeTags = (user) => {
	const key = user.slug;
	message.loading({ content: 'Computing user tags', key, duration: 10 });
	return action(async () => {
		try {
			await UserService.computeTags(documentId(user));
			message.success({ content: 'User tags updated', key, duration: 1 });
		} catch (error) {
			message.error({ content: error.message, key });
		}
	});
};

/**
 * registerUser
 * @param {*} data
 * @param {*} callback
 * @returns
 */
export const registerUser = (data, callback) => {
	return actionWithLoader(async (dispatch) => {
		await UserService.registerUser(data);

		// force to reload user in localStorage
		if (data?.isInvitePending) {
			refreshCurrentUser()(dispatch);
		}

		if (isFunction(callback)) await callback();
	});
};

/**
 * write company log - action: talent-link
 * @param {*} user
 * @param {*} url
 * @param {*} target
 */
export const openExternalUserLink = (user, url, target = '_blank') => {
	// write company log
	writeRecruiterLog(COMPANY_RECRUITER_LOG.TALENT_LINK, {
		userTargetId: documentId(user),
	});
	openExternalUrl(url, target);
};

/**
 * Opens the resume file in a new tab
 * write company log - action: talent-resume
 * @param {} filename
 */

export const downloadResume = async (user, filename) => {
	writeRecruiterLog(COMPANY_RECRUITER_LOG.TALENT_RESUME, { userTargetId: documentId(user), filename });
	const result = await UserService.getUserResume(filename);
	if (result && result.data?.signedUrl) {
		window.open(result.data?.signedUrl, '_blank');
	}
};
