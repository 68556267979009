import { useTheme } from '@emotion/react';
import Link from '../../../app/Link';
import { ArrowRightIcon, addSignToNum } from '../../../../utils/common';
import CompanyGenderScoreRingScore from '../CompanyGenderScoreRingScore';
import GenderScoreLayoutBox from '../layout/GenderScoreLayoutBox';
import GenderScoreLayoutGrid from '../layout/GenderScoreLayoutGrid';
import { GENDERSCORE_LABEL } from '../../../../constants/constant';
import GenderScoreResultsNavLink from './GenderScoreResultsNavLink';
import CompanyModel from '../../../../models/CompanyModel';
import DiffDisplay from '../DiffDisplay';
import dayjs from 'dayjs';

const GenderScoreResultsLastScoreBox = ({
	company,
	withDetailsLink = false,
	...props
}) => {
	const className = 'GenderScoreResultsLastScoreBox';

	const theme = useTheme();

	if (!company) return null;

	const companyModel = new CompanyModel(company);

	const [previousSurvey] = companyModel.getGenderScorePreviousSurveys() || [];

	if (!previousSurvey) return <i>No previous survey found</i>;

	const currentScore = company?.genderScore?.globalScore || 0;
	const previousScore = previousSurvey?.genderScore?.globalScore || currentScore || 0;
	const diff = currentScore - previousScore;

	return (
		<>
			<GenderScoreLayoutBox
				title={<>Your last score</>}
				style={{
					...props?.style,
				}}
			>
				<GenderScoreLayoutGrid
					columns={'48px 1fr'}
					columnGap={12}
					marginBottom={4}
					style={{
						alignSelf: 'center',
					}}
				>
					<CompanyGenderScoreRingScore
						company={company}
						forceScore={previousSurvey?.genderScore?.globalScore}
						version='white'
						size={48}
						withPopover={false}
					/>

					<div>
						<div
							style={{
								fontSize: 12,
								marginBottom: 0,
							}}
						>
							<div><DiffDisplay
								previous={previousScore}
								current={company?.genderScore?.globalScore || 0}
								style={{
									fontSize: 16,
									display: 'inline',
								}}
							/>
							<span
								style={{
									opacity: 0.5,
								}}
							>
								{/* <b> point{Math.abs(Math.trunc(diff)) > 1 ? 's' : ''}</b><br /> */}
							</span>
							</div>
							<span
								style={{
									opacity: 0.5,
								}}
							>
								since your last survey<br />
								on <b>{dayjs(previousSurvey?.submittedAt).format('DD/MM/YYYY')}</b>
							</span>
						</div>
					</div>

				</GenderScoreLayoutGrid>

				{withDetailsLink && (
					<GenderScoreResultsNavLink
						style={{
							display: 'block',
							marginTop: 6,
							marginBottom: -2,
						}}
					>
						<span style={{ fontSize: 12 }}>
							See your evolution analysis <ArrowRightIcon />
						</span>
					</GenderScoreResultsNavLink>
				)}
			</GenderScoreLayoutBox>
		</>
	);
};

export default GenderScoreResultsLastScoreBox;
