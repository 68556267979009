import { useTheme } from '@emotion/react';
import {
	Divider, Popover, Space, Tag, Tooltip, Typography,
} from 'antd';
import moment from 'moment';
import { Fragment } from 'react';
import { DATE_FORMAT } from '../../../constants/constant';
import { FaRegClock } from 'react-icons/fa';

const ActivityPreview = (
	{
		createdAt,
		updatedAt,
	},
) => {
	const theme = useTheme();

	let iconColor = theme.color.grey;
	const createDateLabel = (title, date, days = 7) => {
		let colors = { fromNow: theme.color.darkgrey, date: theme.color.grey };

		const isLate = moment(date).isBefore(moment().add(-days, 'days'));
		if (isLate) colors = { fromNow: '#dd5555', date: '#ff9999', iconColor: 'orange' };

		iconColor = colors.iconColor || iconColor;
		return <Space direction='horizontal' size={0} align='center'>
			<Typography style={{ fontSize: 11, width: 90 }}>
				<b>{title}</b>
			</Typography>
			<Space direction='vertical' size={0} align='top'>
				<Typography style={{ fontSize: 11, color: colors.fromNow }}>
					{moment(date).fromNow()}
				</Typography>
				<Typography style={{ fontSize: 10, color: colors.date }}>
					{moment(date).format(DATE_FORMAT)}
				</Typography>
			</Space>
		</Space>;
	};

	const content = <Space direction='vertical'>
		{createDateLabel('Last updated', updatedAt, 7)}
		{createDateLabel('First created', createdAt, 30000)}
	</Space>;

	const Icon = FaRegClock;
	const iconStyle = { marginBottom: -2, color: iconColor };

	return <Popover title={<Space align='center'><Icon style={iconStyle}/> Activity</Space>} content={content}>
		<Icon style={{ ...iconStyle, fontSize: 18 }} />
	</Popover>;
};

export default ActivityPreview;
