import GridCardBase from '../../../app/grid/GridCardBase';
import { Col, Row } from 'antd';
import { useTheme } from '@emotion/react';
import { LocalDebug } from '../../../../utils/LocalDebug';

const UserGridCardLayout = (
	{
		leftColumn,
		rightColumn,
		footerRow,
		children,
		...props
	},
) => {
	const className = 'UserGridCardLayout';

	const theme = useTheme();
	const padding = 40;

	return (
		<GridCardBase
			{...props}
			height='unset'
		>
			<Row gutter={[0, 0]}>

				<Col
					span={7}
					style={{
						padding: `${padding}px`,
						// paddingTop: padding,
						display: 'flex',
						flexDirection: 'column',
						// justifyContent: 'center',
						alignItems: 'center',
						textAlign: 'center',
						background: theme.color.bgGrey,
						...props?.style?.leftColumn,
					}}
				>

					{leftColumn}

				</Col>

				<Col
					span={17}
					style={{
						// padding: `${padding}px`,
						// display: 'flex',
						// flexDirection: 'column',
						width: '100%',
						...props?.style?.rightColumn,
						// paddingTop: padding + 32,
						// paddingLeft: 42,
					}}
				>

					{rightColumn}

				</Col>

			</Row>

			{footerRow && (
				<div
					style={{
						minHeight: 90,
						padding: 0,
						display: 'flex',
						justifyContent: 'space-between',
						borderTop: '1px solid #eee',
					}}
				>
					{footerRow}

				</div>
			)}

			{children}

		</GridCardBase>
	);
};

export default UserGridCardLayout;
