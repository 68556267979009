import { Space } from 'antd';
import FunnelUserColumn from './FunnelUserColumn';
import { LocalDebug } from '../../../../utils/LocalDebug';
import PopinContact from '../../../../containers/staff/PopinContact';
import {
	useContext, useEffect, useMemo, useState,
} from 'react';
import { DndContext } from '@dnd-kit/core';
import { NotificationContext } from '../../../app/notification/NotificationProvider';

const Funnels = (
	{
		funnel,
		columns,
		ColumnComponent = FunnelUserColumn,
		refresh = 0,
		filters,
	},
) => {
	const className = 'Funnels';

	const { notifySuccess } = useContext(NotificationContext);
	// const refreshColumn = {};
	const [refreshColumn, setRefreshColumn] = useState({});
	const [refreshFunnel, setRefreshFunnel] = useState(0);
	const onUserFunnelEdited = (id) => {
		LocalDebug.logInfo({ className, method: 'onUserFunnelEdited' }, { id });
		handleRefreshColumn(id);
	};

	useEffect(() => {
		LocalDebug.logUseEffect({ className, effects: 'refresh' });
		setRefreshFunnel((p) => p + 1);
	}, [refresh]);

	const handleRefreshColumn = (id) => {
		LocalDebug.logInfo({ className, method: 'handleRefreshColumn' }, { id });
		if (id) {
			// refreshColumn[id] = (refreshColumn[id] || 0) + 1;
			setRefreshColumn((p) => ({ ...p || {}, [id]: (p?.[id] || 0) + 1 }));
		} else {
			setRefreshFunnel((p) => p + 1);
		}
	};

	const onDragStart = (event) => {
		const method = 'onDragStart';
		LocalDebug.logInfo({ className, method }, event);
	};

	const onDragEnd = (event) => {
		const method = 'onDragEnd';
		const draggedId = event?.active?.id;
		const draggedData = event?.active?.data;
		const sourceId = event?.active?.data?.current?.source;
		const droppedId = event?.over?.id;
		LocalDebug.logInfo({ className, method }, {
			event, draggedId, sourceId, droppedId,
		});
		if (sourceId === droppedId) return;
		const user = event?.active?.data?.current?.user;
		const sourceCol = columns.find((c) => c.value === sourceId);
		const droppedCol = columns.find((c) => c.value === droppedId);
		notifySuccess({
			key: 'drag',
			// message: 'Dropped ' + draggedId + ' from ' + sourceId + ' to ' + droppedId,
			description: <div>
				<div>
					Dropped <b>{user.firstName} {user.lastName}</b> from <b>{sourceCol.title}</b> to <b>{droppedCol.title}</b>
				</div>
				<div>
					<i>Feature not really working yet, it's just a test!</i>
				</div>

			</div>,
			duration: 2,
		});
		handleRefreshColumn(sourceId);
		handleRefreshColumn(droppedId);
	};

	const funnelColumns = columns
		.map(({
			id, title, label, details, value, cardOptions, menu,
		}, i) => {
			title = title || label;
			const key = id || value || i;
			return (
				<div
					key={key}
					style={{
						width: 300,
						maxWidth: 300,
					}}
				>
					{/* {JSON.stringify(refreshColumn)} */}
					{/* {refreshFunnel} + {refreshColumn?.[key] || 0} = {refreshFunnel + (refreshColumn?.[key] || 0)} */}
					<ColumnComponent
						id={key}
						funnel={funnel}
						columns={columns}
						title={title}
						details={details}
						cardOptions={cardOptions}
						menu={menu}
						onUserFunnelEdited={onUserFunnelEdited}
						refreshColumn={refreshFunnel + (refreshColumn?.[key] || 0)}
						filterOptions={{
							...filters,
							[funnel]: value,
						}}
					/>
				</div>
			);
		});

	return (
		<>
			<DndContext
				onDragStart={onDragStart}
				onDragEnd={onDragEnd}
				// onDragMove={onDragMove}
			>
				<Space
					align='top'
					size={0}
					wrap={false}
					style={{
						width: '100%',
						overflowX: 'scroll',
					}}
				>
					{funnelColumns}
				</Space>
			</DndContext>
		</>
	);
};

export default Funnels;
