import SavedSearchesDigestsPage	from '../../../containers/staff/tech/SavedSearchesDigestsPage';
import { PERMS_GRANT_STAFF } from '../../menu.utils';
import MenuBase from '../../MenuBase';
import { SendOutlined } from '@ant-design/icons';

export const StaffSavedSearchesDigestsIcon = SendOutlined;

class StaffSavedSearchesDigestsMenu extends MenuBase {
	static props = {
		name: 'tech-saved-searches-digests-staff',
		path: '/staff/tech/saved-searches-digests',
		label: 'Saved Searches Digests',
		icon: <StaffSavedSearchesDigestsIcon />,
		iconLegacy: '💌',
		Component: SavedSearchesDigestsPage,
		onEnterProps: { shouldLoadUserTags: true },
		perms: PERMS_GRANT_STAFF,
	};

	constructor() {
		super(StaffSavedSearchesDigestsMenu.props);
	}
}

export default StaffSavedSearchesDigestsMenu;
