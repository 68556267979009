/** @jsxImportSource @emotion/react */

import HeaderPage from './HeaderPage';
import React from 'react';
import { cx } from '@emotion/css';
import { useTheme } from '@emotion/react';

const classes = (theme) => ({
	container: {
		// minHeight: '70vh',
		maxHeight: 'calc(100vh - 200px)',
	},
	loading: {
		width: 100,
		height: 100,
		position: 'fixed',
		top: '50vh',
		left: '50vw',
		marginLeft: -50,
		marginTop: -50,
		zIndex: 1000,
		borderRadius: 10,
		boxShadow: '0 2px 10px -2px rgba(0, 0,0,.3)',
		background: 'rgba(255,255,255,.95)',
	},
});

const PageLayout = ({
	title,
	className,
	subtitle,
	renderHeader,
	withHeader = true,
	header,
	children,
	style = {},
}) => {
	const theme = useTheme();
	const styles = classes(theme);

	return (
		<>
			<div css={[styles.container, style]} className={cx([className])}>
				{header || withHeader && (
					<HeaderPage
						title={title}
						subtitle={subtitle}
						renderHeader={renderHeader}
						className={className}
					/>
				)}
				{children}
			</div>
		</>
	);
};

export default PageLayout;
