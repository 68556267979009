import { useAuthContext } from '../../../hooks/useAuthContext';
import PageLayout from '../../../components/app/layout/PageLayout';
import Chatbot from '../../../components/chatbot/Chatbot';
import StaffChatbotMenu from '../../../menu/staff/tech/StaffChatbotMenu';

const StaffChatbotPage = () => {
	const className = 'StaffChatbotPage';

	const { isStaff } = useAuthContext();

	if (!isStaff) return null;

	return (
		<PageLayout
			MenuClass={StaffChatbotMenu}
		>
			<Chatbot />
		</PageLayout>
	);
};

export default StaffChatbotPage;
