import { Select } from 'antd';
import { GENDERSCORE_SURVEY_OPTIONS } from '../../../../constants/property/genderscore-survey';

const CompanyPlanGenderScoreSurveySelect = ({
	...props
}) => {
	const className = 'CompanyPlanGenderScoreSurveySelect';

	return (
		<Select
			{...props}
			options={GENDERSCORE_SURVEY_OPTIONS}
			style={{
				minWidth: 150,
				...props?.style,
			}}
		/>
	);
};

export default CompanyPlanGenderScoreSurveySelect;
