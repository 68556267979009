import { useTheme } from '@emotion/react';
import { Space } from 'antd';
import GenderScoreTitleBlock from './GenderScoreTitleBlock';
import GenderScoreComparisonLineBlock from './GenderScoreComparisonLineBlock';
import GenderScoreNotProvidedBlock from './GenderScoreNotProvidedBlock';
import RatioGraph from '../graphs/RatioGraph';

/**
 * @param {string} title - Graph title
 * @param {number} current - Current ratio value.
 * @param {number} previous - Optional. Previous ratio value.
 * @param {boolean} [withPrevious] - Optional. Whether to show the previous value comparison.
 * Default: true
 * @param {*} [graphColors] - Optional. Format: [from, to, bg, goal:{reached,notReached}].
 * If not set, use theme.chart.ratio
 * @param {number} [width] - Optional. Width of component. Default: 170
 * @param {number} [lineWidth] - Optional. Width of the circumference. Default: 10
 * @param {number} [fontSize] - Optional. Font size of the label. Default: 36
 * @param {number} [chartGraphRatio] - Optional. Number between 0 and 1.
 * Ratio between the outer component (chart) and the graph.
 * It defines the size of the graph and the position of the previous comparison. Default: 0.6
 * @returns
 */
const GenderScoreRatioBlock = ({
	title,
	current,
	previous,
	existsPreviousSurvey,
	withPrevious = true,
	withBgPie = true,
	width = 170,
	fontSize = 36,
	lineWidth = 10,
	chartGraphRatio = 0.75,
	isPct = true,
	graphColors,
}) => {
	const className = 'GenderScoreRatioBlock';

	const theme = useTheme();
	const graphWidth = width * chartGraphRatio;
	let content;
	if (isNaN(current)) {
		content = <>
			<GenderScoreNotProvidedBlock width={width}/>
		</>;
	} else {
		content = <>
			<RatioGraph
				value={current}
				width={graphWidth}
				fontSize={fontSize}
				lineWidth={lineWidth}
				graphColors={graphColors}
				style={{
					marginTop: 5,
					marginBottom: 5,
				}}
				withBgPie = {withBgPie}
			/>
			{withPrevious && <div
				style={{ marginTop: (-graphWidth / 2) + 30 }}
			>
				<GenderScoreComparisonLineBlock
					previous={previous}
					current={current}
					isPct={isPct}
					existsPreviousSurvey={existsPreviousSurvey}
				/>
			</div>
			}
		</>;
	}
	return <Space
		direction='vertical'
		style={{
			width: '100%',
		}}>
		<GenderScoreTitleBlock
			title={title}
			isMainTitle={false}
		/>
		<div style={{
			display: 'grid',
			justifyContent: 'center',
			justifyItems: 'center',
		}}>
			{content}
		</div>
	</Space>;
};
export default GenderScoreRatioBlock;
