import { useAuthContext } from '../../../hooks/useAuthContext';
import { TAG_ADMIN } from '../../../constants/constant';
import BlockBase from './BlockBase';

const BlockAdmin = ({
	withStaffView = true,
	withTags = true,
	withBorder = true,
	children,
	...props
}) => {
	const { isAdmin } = useAuthContext();

	return (
		<BlockBase
			value={isAdmin}
			tags={TAG_ADMIN}
			color='red'
			withStaffView={withStaffView}
			withTags={withTags}
			withBorder={withBorder}
			{...props}
		>
			{children}
		</BlockBase>
	);
};

export default BlockAdmin;
