import {
	Form, Input, Select, Space,
} from 'antd';
import LOCATION_COUNTRY from '../../../../constants/property/location-country';
import EXPERIENCE_LEVEL from '../../../../constants/property/experience-level';
import CAREER_ROLE_GROUPS from '../../../../constants/property/career-role-group';
import CAREER_ROLES from '../../../../constants/property/career-role';
import TECH_STACK from '../../../../constants/property/tech-stack';
import FormItem from '../../../company/form/FormItem';
import { useDebouncedEffect } from '../../../../hooks/useDebounceEffect';
import { useState } from 'react';
import FunnelSelectFormItem from './FunnelSelectFormItem';
import { useTheme } from '@emotion/react';

const FunnelUserFilters = (
	{
		setFilters,
	},
) => {
	const className = 'FunnelUserFilters';

	const theme = useTheme();

	const [search, setSearch] = useState('');

	useDebouncedEffect(async () => {
		const dynamicSearch = {
			text: search,
			fields: ['firstName', 'lastName', 'email'],
			// fields: defaultColumnsFilterSelected
		};
		setFilters((p) => ({ ...p, dynamicSearch }));
	}, [search], 300);

	const orderingOptions = [
		{
			label: 'Logged (newest first)',
			value: JSON.stringify({ sortField: 'loggedAt', sortOrder: 'desc' }),
		},
		{
			label: 'Logged (oldest first)',
			value: JSON.stringify({ sortField: 'loggedAt', sortOrder: 'asc' }),
		},
		{
			label: 'Registration (newest first)',
			value: JSON.stringify({ sortField: 'createdAt', sortOrder: 'desc' }),
		},
		{
			label: 'Registration (oldest first)',
			value: JSON.stringify({ sortField: 'createdAt', sortOrder: 'asc' }),
		},
		{
			label: 'First name (Z-A)',
			value: JSON.stringify({ sortField: 'firstName', sortOrder: 'desc' }),
		},
		{
			label: 'First name (A-Z)',
			value: JSON.stringify({ sortField: 'firstName', sortOrder: 'asc' }),
		},
		{
			label: 'Last name (Z-A)',
			value: JSON.stringify({ sortField: 'lastName', sortOrder: 'desc' }),
		},
		{
			label: 'Last name (A-Z)',
			value: JSON.stringify({ sortField: 'lastName', sortOrder: 'asc' }),
		},
	];

	return (
		<div
			style={{
				background: theme.color.bgGrey,
				padding: 10,
				marginBottom: 10,
			}}
		>
			<h3>User filters</h3>
			<Form
				layout='vertical'
				style={{ width: '100%' }}
				className='funnel-filters'
			>
				<Space wrap={true} size={[8, 8]}>

					<FunnelSelectFormItem
						label='Job search status'
						setFilters={setFilters}
						field='status'
						placeholder='Job search status'
						style={{ width: 140 }}
						options={[
							{ value: 'active', label: 'Active' },
							{ value: 'open', label: 'Open' },
							{ value: 'no', label: 'Not looking' },
						]}
					/>

					<FunnelSelectFormItem
						label='Country'
						setFilters={setFilters}
						field='information.location.country'
						options={LOCATION_COUNTRY.options.map((o) => ({ ...o, label: `${o.emoji} ${o.label}` }))}
						placeholder='Country'
						style={{ width: 140 }}
					/>

					<FunnelSelectFormItem
						label='Level'
						setFilters={setFilters}
						field='level'
						options={EXPERIENCE_LEVEL.options}
						placeholder='Level'
						style={{ width: 140 }}
					/>

					<FunnelSelectFormItem
						label='Reachability'
						setFilters={setFilters}
						field='isReachableByRecruiters'
						mode='single'
						options={[
							{ label: 'Reachable', value: true },
							{ label: 'Unreachable', value: false },
						]}
						placeholder='Reachability'
						style={{ width: 140 }}
					/>

					<FunnelSelectFormItem
						label='Job role groups'
						setFilters={setFilters}
						field='jobRoleGroup'
						options={CAREER_ROLE_GROUPS.options}
						placeholder='Job role group'
						style={{ width: 180 }}
					/>

					<FunnelSelectFormItem
						label='Job roles'
						setFilters={setFilters}
						field='jobRole'
						placeholder='Job role'
						options={CAREER_ROLES.options}
						style={{ width: 180 }}
					/>

					<FunnelSelectFormItem
						label='Hard skills'
						setFilters={setFilters}
						field='hardSkill'
						options={TECH_STACK.singleOptions}
						placeholder='Hard skills'
						style={{ width: 180 }}
					/>

					<FunnelSelectFormItem
						label='Coach'
						setFilters={setFilters}
						field='coach'
						mode='single'
						placeholder='Coach'
						options={[
							{ label: 'Maisa Mattedi', value: 'maisa' },
							{ label: 'Hélène Descamps', value: 'helene.descamps' },
							{ label: 'Sonia Hernandez Quintanar', value: 'sonia' },
							{ label: 'Jean Lerat', value: 'jean' },
							{ label: 'Other coaches', value: 'others' },
							{ label: 'No coach', value: 'none' },
						]}
						style={{ width: 180 }}
					/>

					{/* <FormItem */}
					{/*	label='Coach' */}
					{/*	layout='vertical' */}
					{/*	style={{ margin: 0 }} */}
					{/* > */}
					{/*	<Select */}
					{/*		showSearch */}
					{/*		allowClear */}
					{/*		onChange={(value) => setFilters((p) => ({ ...p, coach: value }))} */}
					{/*		options={coachOptions} */}
					{/*		placeholder='Coach' */}
					{/*		style={{ */}
					{/*			width: 180, */}
					{/*			fontSize: 12, */}
					{/*		}} */}
					{/*	/> */}
					{/* </FormItem> */}

					<FormItem
						label='Ordering'
						layout='vertical'
						style={{ margin: 0 }}
					>
						<Select
							onChange={(value) => setFilters((p) => ({ ...p, ...JSON.parse(value) }))}
							style={{ width: 180, fontSize: 12 }}
						>
							{orderingOptions
								.map((option) => (
									<Select.Option
										key={option.value}
										label={<b>{option.label}</b>}
										value={option.value}
									>
										<span style={{ fontSize: 12 }}>
											{option.label}
										</span>
									</Select.Option>
								))
							}

						</Select>
					</FormItem>

					<FormItem
						label='Search'
						layout='vertical'
						style={{ margin: 0 }}
					>
						<Input
							placeholder='Search'
							onChange={(e) => setSearch(e?.target?.value)}
							style={{
								width: 180,
								fontSize: 14,
							}}
						/>
					</FormItem>
				</Space>
			</Form>
		</div>
	);
};

export default FunnelUserFilters;
