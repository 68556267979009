import UMSInviteProvider from '../../../components/ums/UMSInviteProvider';
import UMSStaffResults from '../../../components/ums/staff/UMSStaffResults';
import UnbiasedScoreProvider from '../../../contexts/UnbiasedScoreProvider';
import { PERMS_GRANT_STAFF } from '../../menu.utils';

const menu = {
	name: 'staff-unbiased-managing-score',
	path: '/staff/unbiased-managing-score',
	label: 'Unbiased Managing Score',
	icon: <span>📨</span>,
	component: () => (
		<UMSInviteProvider>
			<UnbiasedScoreProvider>
				<UMSStaffResults />
			</UnbiasedScoreProvider>
		</UMSInviteProvider>
	),
	perms: PERMS_GRANT_STAFF,
};

export default menu;
