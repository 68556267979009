import { useTheme } from '@emotion/react';
import { isArray } from 'lodash';
import ReactMarkdown from 'react-markdown';
import { Divider } from 'antd';
import { GenderScoreResourceContext } from '../../../../contexts/GenderScoreResourceProvider';
import CompanyActionModel from '../../../../models/CompanyActionModel';
import { useContext, useEffect, useState } from 'react';
import GenderScoreResourceCard from '../../genderscore/resources/GenderScoreResourceCard';
import { useSelector } from 'react-redux';
import { getCompanySelected } from '../../../../reducers/app';
import GenderScoreResourceRow from '../../genderscore/resources/GenderScoreResourceRow';

const CompanyActionResourceGrid = ({
	action,
	...props
}) => {
	const className = 'CompanyActionResourceGrid';

	const theme = useTheme();
	const { getResources, loading } = useContext(GenderScoreResourceContext);
	const companySelected = useSelector(getCompanySelected);

	action = action instanceof CompanyActionModel
		? action
		: new CompanyActionModel(action);

	const [resources, setResources] = useState([]);

	useEffect(() => {
		const launch = async () => {
			const results = await getResources();
			setResources(action?.resources
				?.map((slug) => results.find((item) => slug === item.key) || { slug, title: slug }));
		};
		if (getResources) launch();
	}, [getResources]);

	if (!(action?.resources?.length > 0)) return null;

	return (
		<>
			<h2>Resources</h2>
			{/* <Divider orientation='left'>
				Resources
			</Divider> */}

			<div
				style={{
					display: 'grid',
					gridTemplateColumns: '1fr',
					columnGap: 10,
					rowGap: 10,
				}}
			>
				{resources?.map((resource, resourceIndex) => (
					<div
						key={resourceIndex}
						style={{
						}}
					>
						<GenderScoreResourceRow
							company={companySelected}
							resource={resource}
							withPillarTags={false}
						/>
					</div>
				))}
			</div>
		</>
	);
};

const CompanyActionContentRightSide = ({
	action,
	...props
}) => {
	const className = 'CompanyActionContentRightSide';

	const theme = useTheme();

	action = action instanceof CompanyActionModel
		? action
		: new CompanyActionModel(action);

	return (
		<div
			className='right-side'
			style={{
				...props?.style,
			}}
		>
			<h2>
				Guidelines to implement this action
			</h2>

			<div
				style={{
					fontSize: 14,
				}}
			>
				<ReactMarkdown
					linkTarget='_blank'
				>
					{action?.descriptionForMarkdown}
				</ReactMarkdown>
			</div>

			{isArray(action?.data) && action?.data?.length > 0 && (
				<>
					<h2>Data / Statistics</h2>
					{/* <Divider orientation='left'>
								Data / Statistics
					</Divider> */}

					{action?.data?.map((data, dataIndex) => (
						<div
							key={dataIndex}
							style={{
								padding: '1px 16px',
								// width: '100%',
								background: '#f5f5f5',
								fontSize: 13,
								fontStyle: 'italic',
								color: '#333',
								borderRadius: 6,
							}}
						>
							<ReactMarkdown
								linkTarget='_blank'
							>
								{data}
							</ReactMarkdown>
						</div>
					))}
				</>
			)}

			<CompanyActionResourceGrid
				action={action}
			/>

			{isArray(action?.questions) && action?.questions?.length > 0 && (
				<>
					<h2>Questions</h2>
					{/* <Divider orientation='left'>
						Questions
					</Divider> */}

					{action?.questions?.map((question, questionIndex) => (
						<div
							key={questionIndex}
							style={{
								padding: '1px 16px',
								// width: '100%',
								background: '#f5f5f5',
								fontSize: 13,
								fontStyle: 'italic',
								color: '#333',
								borderRadius: 6,
							}}
						>
							{JSON.stringify(question)}

						</div>
					))}
				</>
			)}
		</div>
	);
};

export default CompanyActionContentRightSide;
