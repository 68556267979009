import { ENGLISH_LANG } from '../../../../constants/constant';
import CompanyRichTextEditor from '../shared/CompanyRichTextEditor';

const CompanyQuoteEditor = (
	{
		company,
		lang = ENGLISH_LANG,
		setEditorOpen,
	},
) => {
	const className = 'CompanyQuoteEditor';

	return (
		<CompanyRichTextEditor
			company={company}
			lang={lang}
			title={<b>Quote</b>}
			details={<span>
				Please share a quote from the CPO, CTO,
				D&I Manager, Head of engineering or CEO
				in the company on women in tech, diversity
				and inclusion or any intersecting themes.
			</span>}
			field={'quote'}
			setEditorOpen={setEditorOpen}
		/>
	);
};

export default CompanyQuoteEditor;
