import slugify from '@sindresorhus/slugify';
import { Tooltip, Typography } from 'antd';
import { EnvironmentOutlined } from '@ant-design/icons';
import { useTheme } from '@emotion/react';
import COUNTRIES_BY_SLUGS from '../../../constants/countries-by-slugs.json';
import CONTINENTS from '../../../constants/continents.json';
import CITIES from '../../../constants/country-cities.json';
import { TAG_CATEGORY_JOB_SEARCH_JOB_LOCATION_CITY_SLUG } from '../../../constants/tag/constants/job-search/job-search-job-location-city';
import { TAG_CATEGORY_JOB_SEARCH_JOB_LOCATION_COUNTRY_SLUG } from '../../../constants/tag/constants/job-search/job-search-job-location-country';
import { TAG_CATEGORY_JOB_SEARCH_JOB_LOCATION_CONTINENT_SLUG } from '../../../constants/tag/constants/job-search/job-search-job-location-continent';
import {
	CITY, CONTINENT, COUNTRY, NOT_PROVIDED_YET_LABEL, getJobLocationLabel,
} from '../../../constants/constant';
import UserModel from '../../../models/UserModel';

const UserStubLocation = ({
	user,
	level = null,
	withDesiredLocation = false,
	...props
}) => {
	const theme = useTheme();

	if (!withDesiredLocation && !(user?.information?.location?.city || user?.information?.location?.country)) {
		return null;
	}
	if (withDesiredLocation && !(user?.jobSearch?.jobLocation)) {
		return null;
	}

	let content;

	if (withDesiredLocation) {
		// Desired job location
		content = (
			new UserModel(user)
				?.getJobSearchJobLocations?.({
					sorted: true,
					withCountryCode: false,
					withCountryFlag: true,
					withCityCountry: true,
					withCityCountryCode: true,
					withCityCountryFlag: true,
					withFlagNbsp: true,
				}) || []
		)
			?.map((label, index) => (
				<div
					key={index}
					style={{ ...props?.style }}
				>
					<EnvironmentOutlined
						style={{ color: theme.color.grey }}
					/>&nbsp;{label}
				</div>
			));

		if (!content || !content.length) {
			content = NOT_PROVIDED_YET_LABEL;
		}
	} else {
		// Current user location
		const { city, country } = user?.information?.location || {};
		content = (
			<div
				style={{ ...props?.style }}
			>
				<EnvironmentOutlined
					style={{ color: theme.color.grey }}
				/>&nbsp;{getJobLocationLabel({
					value: `city:${slugify(city)}|country:${slugify(country)}`,
					withCountryCode: false,
					withCountryFlag: true,
					withCityCountry: true,
					withCityCountryCode: true,
					withCityCountryFlag: true,
					withFlagNbsp: true,
				})}
			</div>
		);
	}

	return level
		? <Typography.Title level={level} style={{ marginBottom: 0 }}>
			{content}
		</Typography.Title>
		: <Typography style={{ color: theme.color.darkgrey, fontSize: 12, ...props?.style }}>
			{content}
		</Typography>;
};

export default UserStubLocation;
