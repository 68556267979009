import { Select } from 'antd';
import { UMS_SURVEY_MANAGER_OPTIONS } from '../../../../constants/property/ums-survey';

const CompanyPlanUMSSurveyManagerSelect = ({
	...props
}) => {
	const className = 'CompanyPlanUMSSurveyManagerSelect';

	return (
		<Select
			{...props}
			options={UMS_SURVEY_MANAGER_OPTIONS}
			style={{
				minWidth: 150,
				...props?.style,
			}}
		/>
	);
};

export default CompanyPlanUMSSurveyManagerSelect;
