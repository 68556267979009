/* eslint-disable react-hooks/exhaustive-deps */
import { Space, Spin } from 'antd';
import Logo50inTech from './Logo50inTech';
import { LoadingOutlined } from '@ant-design/icons';
import { useTheme } from '@emotion/react';

const LoadSpinner = ({
	open = true,
	withLogo = true,
	...props
}) => {
	const theme = useTheme();

	if (!open) return null;

	return (
		<div
			style={{
				position: 'fixed',
				width: '100vw',
				height: '100vh',
				top: 0,
				left: 0,
				background: 'rgba(255,255,255,.5)',
				zIndex: 1000000,
				...props?.style,
			}}
		>
			<Space style={{ height: '100vh' }} direction='horizontal' align='center'>
				<Space style={{ width: '100vw' }} direction='vertical' align='center'>
					<Spin
						indicator={<LoadingOutlined style={{ fontSize: 80 }} spin/>}
						spinning={open}
						style={{
							marginBottom: -40,
							color: theme.color.fitBlueElectric,
							background: 'white',
							borderRadius: 200,
						}}
					/>
					{withLogo && (
						<Logo50inTech
							size={30}
							style={{
								position: 'relative',
								marginTop: -23,
								marginBottom: 23,
							}}
						/>
					)}
				</Space>
			</Space>
		</div>
	);
};

export default LoadSpinner;
