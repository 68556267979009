import MenuBase from '../../MenuBase';
import { PERMS_GRANT_STAFF } from '../../menu.utils';
import { ExperimentOutlined } from '@ant-design/icons';
import StaffGenderScoreSurveyDetailsPage from '../../../containers/staff/genderscore/StaffGenderScoreSurveyDetailsPage';

export const StaffGSSurveyDetailsIcon = ExperimentOutlined;

class StaffGSSurveyDetailsMenu extends MenuBase {
	static props = {
		name: 'staff-genderscore-survey-details',
		path: '/staff/genderscore/survey/details',
		label: 'Survey Details',
		icon: <StaffGSSurveyDetailsIcon />,
		iconLegacy: <span>📊</span>,
		Component: StaffGenderScoreSurveyDetailsPage,
		perms: PERMS_GRANT_STAFF,
	};

	constructor() {
		super(StaffGSSurveyDetailsMenu.props);
	}
}

export default StaffGSSurveyDetailsMenu;
