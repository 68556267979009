import { ENGLISH_LANG } from '../../../../constants/constant';
import CompanyRichTextEditor from '../shared/CompanyRichTextEditor';

const CompanyMissionEditor = (
	{
		company,
		lang = ENGLISH_LANG,
		setEditorOpen,
	},
) => {
	const className = 'CompanyMissionEditor';

	const title = 'Mission';
	const field = 'mission';

	return (
		<CompanyRichTextEditor
			company={company}
			lang={lang}
			title={<b>{title}</b>}
			details={<span>
				When writing your company's mission,
				you want to clearly convey your organisation's purpose
				and the impact it strives to make
			</span>}
			field={field}
			setEditorOpen={setEditorOpen}
		/>
	);
};

export default CompanyMissionEditor;
