import { useTheme } from '@emotion/react';
import DiffDisplay from '../../DiffDisplay';
import { Space, Tooltip } from 'antd';
import GenderScoreMedalBlock from './GenderScoreMedalBlock';
import {
	ArrowDownIcon, ArrowRightIcon, ArrowUpIcon, capitalize,
} from '../../../../../utils/common';

const getStyleOptions = ({
	theme, isRanking, withOneColor, current, previous,
}) => {
	const rankColor = theme.dashboardColors.pastelBlue;
	let label = 'pts';
	let icon;
	let colors = {
		main: rankColor,
		secondary: theme.color.dustyGray,
	};
	const diff = current - previous;

	if (isRanking) {
		label = 'rank';
		if (diff === 0) {
			icon = () => ArrowRightIcon({ isBoldArrow: true });
		}
		if (diff < 0) {
			icon = () => ArrowUpIcon({ isBoldArrow: true });
		}
		if (diff > 0) {
			icon = () => ArrowDownIcon({ isBoldArrow: true });
		}
	} else {
		if (diff === 0) {
			colors = {
				...colors,
				main: theme.dashboardColors.darkGray,
			};
		}
		if (diff < 0) {
			colors = {
				...colors,
				main: theme.dashboardColors.red,
			};
		}
		if (diff > 0) {
			colors = {
				...colors,
				main: theme.dashboardColors.green,
			};
		}
	}
	if (withOneColor) {
		colors = {
			...colors,
			secondary: colors.main,
		};
	}
	return { colors, label, icon };
};

/**
 *
 * @param {number} current - Main number to compare
 * @param {number} previous - Number to which compare the current one
 * @param {string} diffLabel - Optional. Label to show next to the difference
 * @param {*} diffIcon - Optional. Icon to show next to the difference
 * @param {string} secondaryLabel - Optional. Label to indicate the context of the difference
 * @param {string} secondaryPosition - Optional. Where to show the secondaryLabel:
 * 'before' or 'after'. Default: 'after'
 * @param {number} width - Optional. Width of the block. Default: 180
 * @param {number} fontSize - Optional. Font size of the main value.
 * The rest of fonts are proportional to this number. Default: 12
 * @param {number} fontRatio - Optional. Ratio between the diffLabel size and the
 * secondaryLabel one.
 * @param {boolean} isRanking - Optional. Whether the current value is a ranking. Default: false
 * @param {boolean} isPct - Optional. Whether the current value is a percent. Default: false
 * @param {boolean} withPrevious - Optional. Whether to render the previous value. Default: false
 * @param {boolean} withWrappedContent - Optional. Whether to render the content in one
 *  or multiple lines. Default: false
 * @param {boolean} withOneColor - Optional. Whether to render all the content with a single color.
 *  Default: false
 * @returns GenderScoreComparisonLineBlock
 */
const GenderScoreComparisonLineBlock = ({
	current,
	previous,
	diffLabel,
	diffIcon,
	secondaryLabel,
	secondaryPosition = 'after',
	forcePrevious,
	justifyContent = 'center',
	tooltipLabel,
	width = '100%',
	fontSize = 12,
	fontRatio = 1,
	isRanking = false,
	isPct = false,
	existsPreviousSurvey = false,
	withPrevious = false,
	withWrappedContent = false,
	withOneColor = false,
	withTooltip = true,
	...props
}) => {
	const className = 'GenderScoreComparisonLineBlock';
	const theme = useTheme();
	const secondaryFontSize = fontSize * fontRatio;

	if (!previous && !forcePrevious) {
		return null;
	}

	const { colors, label, icon } = getStyleOptions({
		theme, current, previous, isRanking, withOneColor,
	});

	let compareSuffix = diffLabel !== undefined ? diffLabel : label;
	let tooltipSuffix = compareSuffix;
	if (isPct) {
		compareSuffix = 'pts';
		tooltipSuffix = '%';
	}
	let previousContent = forcePrevious || previous;
	if (!forcePrevious && withPrevious && isRanking) {
		previousContent = <GenderScoreMedalBlock
			value={previous}
			fontSize={fontSize * 1.4}
			height={fontSize * 1.2}
			gap={4}
			color={colors.main}
		/>;
	}
	const secondary = <span style={{
		fontSize: secondaryFontSize,
		fontWeight: 400,
		color: colors.secondary,
		marginLeft: withWrappedContent ? 0 : -5,
	}}>
		{secondaryLabel || 'vs last year'}
	</span>;
	const content = <>
		{secondaryPosition === 'before' && <>{secondary}</>}
		<DiffDisplay
			previous={previous}
			current={current}
			withSignPrefix={!isRanking}
			isPct={isPct}
			forceColor={colors.main}
			label={compareSuffix}
			icon={diffIcon || icon}
			style={{ fontSize, marginTop: 3, icon: icon ? { height: fontSize * 0.87 } : {} }}
		/>

		{secondaryPosition === 'after' && <>{secondary}</>}

		{withPrevious && previousContent}
	</>;
	let CompareBlock;
	if (withWrappedContent) {
		CompareBlock = <Space.Compact style={{ width, justifyContent, ...props.style }}
			direction='vertical'>
			{content}
		</Space.Compact>;
	} else {
		CompareBlock = <Space style={{ width, justifyContent, ...props.style }}>
			{content}
		</Space>;
	}

	if (withTooltip) {
		return <Tooltip title={<>
			<span><b>{tooltipLabel || 'Last year'}:</b>&nbsp;</span>
			<span>{previous}</span>
			{tooltipSuffix && <span>&nbsp;{tooltipSuffix}</span>}
		</>}>
			{CompareBlock}
		</Tooltip>;
	}
	return CompareBlock;
};
export default GenderScoreComparisonLineBlock;
