import aclService from '../services/acl';
import * as ACTION_TYPES from './types';
import { actionWithLoader } from './utils';
import { localDebug } from '../utils/LocalDebug';
import {
	getCompanies,
	getAclsCompanySelected,
	getAclsRoleSelected,
	getAclsUserSelected,
} from '../reducers/app';
import { documentId, isFunction } from '../utils/common';

export const getAllAcls = () => {
	return actionWithLoader(async (dispatch, getState) => {
		try {
			const companyId = documentId(getAclsCompanySelected(getState()));
			const userId = getAclsUserSelected(getState());
			const role = getAclsRoleSelected(getState());
			localDebug('getAllAcls', { companyId, userId, role });
			const { data } = await aclService.findAll({ companyId, userId, role });
			localDebug('getAllAcls', data);
			dispatch({
				type: ACTION_TYPES.ACLS_LOADED,
				acls: data.acls,
			});
		} catch (error) {
			localDebug(__filename.split('/').shift(), error);
		}
	});
};

export const add = (data) => {
	return actionWithLoader(async () => {
		try {
			await aclService.create(data);
		} catch (error) {
			localDebug(__filename.split('/').shift(), error);
		}
	});
};

export const update = (aclId, data) => {
	return actionWithLoader(async () => {
		try {
			await aclService.update(aclId, data);
		} catch (error) {
			localDebug(__filename.split('/').shift(), error);
		}
	});
};

export const remove = (acl) => {
	return actionWithLoader(async (dispatch) => {
		try {
			await aclService.remove(acl);
		} catch (error) {
			localDebug(__filename.split('/').shift(), error);
		}
	});
};

export const resendInvitation = (acl, callback) => {
	return actionWithLoader(async () => {
		try {
			await aclService.resendInvitation(acl);
			if (isFunction(callback)) callback();
		} catch (error) {
			localDebug(__filename.split('/').shift(), error);
		}
	});
};

export const sendInvitedUser = (data, callback) => {
	return actionWithLoader(async () => {
		try {
			const result = await aclService.sendInvitedUser(data);
			if (isFunction(callback)) await callback(result);
		} catch (error) {
			localDebug(__filename.split('/').shift(), error);
		}
	});
};

export const setAclsCompanySelected = (companyId) => {
	return async (dispatch, getState) => {
		if (!companyId) {
			dispatch({
				type: ACTION_TYPES.SET_ACLS_COMPANY_SELECTED,
				aclCompanySelected: null,
			});
			return;
		}
		const allCompanies = getCompanies(getState());
		const company = allCompanies.find((c) => documentId(c)?.toString?.() === companyId?.toString?.());
		dispatch({
			type: ACTION_TYPES.SET_ACLS_COMPANY_SELECTED,
			aclsCompanySelected: company,
		});

		const companySelected = getAclsCompanySelected(getState());
		localDebug({ getState: getState() });
		localDebug({ companySelected });

		dispatch(getAllAcls());
	};
};
