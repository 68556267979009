import {
	Button, Divider, Popconfirm, Space,
} from 'antd';
import { useContext, useEffect, useState } from 'react';
import { FaBan, FaCheck } from 'react-icons/fa';
import CompanyModel from '../../../../models/CompanyModel';
import { CompanyPageContext } from './CompanyPageProvider';
import { ENGLISH_LANG } from '../../../../constants/constant';

const CompanyFieldEditorFooter = (
	{
		company,
		field,
		setEditorOpen,
		handleSave,
		disabled = false,
		cancelText = <><FaBan style={{ marginBottom: -2, marginRight: 12 }} /><b>Cancel</b></>,
		saveText = <><FaCheck style={{ marginBottom: -2, marginRight: 12 }} /><b>Save</b></>,
	},
) => {
	const className = 'CompanyFieldEditorFooter';

	const { lang, setCurrentlyEditingStep } = useContext(CompanyPageContext);

	if (!company) {
		return null;
	}

	let confirmBtn = <Button
		disabled={disabled}
		type='primary'
		size='large'
		onClick={handleSave}
	>
		{saveText}
	</Button>;

	if (
		lang === ENGLISH_LANG
			? new CompanyModel(company).isPublishedNow()
			: company.isTranslationPublished
	) {
		confirmBtn = <Popconfirm
			title={(
				<div style={{ maxWidth: 300 }}>
					<b>Are you sure?</b>
					<br />
					Since your company page is already live,
					this change will be published right away
					and visible to candidates.
				</div>
			)}
			onConfirm={handleSave}
		>
			<Button
				disabled={disabled}
				type='primary'
				size='large'
			>
				<FaCheck
					style={{ marginBottom: -2, marginRight: 12 }}
				/>
				<b>Publish</b>
			</Button>
		</Popconfirm>;
	}

	return (
		<>
			<Divider />

			<Space style={{ width: '100%', justifyContent: 'space-between' }}>

				<Button
					size='large'
					onClick={() => {
						setCurrentlyEditingStep(null);
						setEditorOpen(false);
					}}
				>
					{cancelText}
				</Button>

				{confirmBtn}
			</Space>
		</>
	);
};

export default CompanyFieldEditorFooter;
