import { useMemo, useRef, useState } from 'react';
import { message, Select, Spin } from 'antd';
import debounce from 'lodash/debounce';
import jobService from '../../services/jobs';
import JobStub from './JobStub';
import { LocalDebug } from '../../utils/LocalDebug';
import { documentId } from '../../utils/common';

const JobSelect = (
	{
		isPublished = true,
		companyId,
		companyIds,
		defaultValue,
		defaultOptions = [],
		notJobIds,
		debounceTimeout = 500,
		...props
	},
) => {
	const className = 'JobSelect';

	const [fetching, setFetching] = useState(false);
	const [options, setOptions] = useState(defaultOptions);
	const fetchRef = useRef(0);

	const fetchOptions = async (search) => {
		const method = 'fetchOptions';
		// if (search && search !== '') {
		// message.loading();
		const filters = {
			isPublished,
			companyId,
			companyIds,
			notJobIds,
			...(search && search !== ''
				? { search }
				: {}
			),
			limit: 100,
		};
		LocalDebug.logInfo({ className, method }, { search, filters });
		const result = await jobService.getAll(filters);
		const { data: { items, count } } = result;
		if (!count || count === 0) {
			message.warning('Sorry, no jobs are available');
		}
		return items;
	};

	const debounceFetcher = useMemo(() => {
		const loadOptions = (value) => {
			fetchRef.current += 1;
			const fetchId = fetchRef.current;
			setOptions([]);
			setFetching(true);

			fetchOptions(value).then((newOptions) => {
				if (fetchId !== fetchRef.current) {
					// for fetch callback order
					return;
				}

				setOptions(newOptions);
				setFetching(false);
			});
		};

		return debounce(loadOptions, debounceTimeout);
	}, [fetchOptions, debounceTimeout, defaultValue]);

	return (
		<>
			<Select
				filterOption={false}
				showSearch
				onFocus={() => { debounceFetcher(); }}
				onSearch={debounceFetcher}
				allowClear={true}
				notFoundContent={fetching ? <Spin size="small"/> : null}
				{...props}
				className='job-select'
				optionLabelProp="label">
				{options.map((job) => (<Select.Option
					key={job.slug}
					companyId={job.companyId}
					value={documentId(job)}
					label={<JobStub job={job} withDropdown={false} style={{ lineHeight: 1.2 }}/>}
					// label={job.title + ' - ' + job?.location}
				>
					<JobStub job={job} withDropdown={false}/>
				</Select.Option>))}
			</Select>
		</>
	);
};

export default JobSelect;
