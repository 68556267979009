import {
	Button, Space,
} from 'antd';
import { ArrowRightOutlined, PlusOutlined } from '@ant-design/icons';
import CompanyActionModel from '../../../models/CompanyActionModel';
import Box from '../../app/box/Box';
import Link from '../../app/Link';
import CompanyActionComplexityTag from './CompanyActionComplexityTag';
import CompanyActionBudgetTag from './CompanyActionBudgetTag';
import CompanyActionPeopleTag from './CompanyActionPeopleTag';
import CompanyActionTimeframeTag from './CompanyActionTimeframeTag';
import CompanyActionCompletionStatusTag from './CompanyActionCompletionStatusTag';
import CompanyActionPillarTag from './CompanyActionPillarTag';
import {
	GS_ACTION_GRID_VIEW_COMPLETION_STATUS,
	GS_ACTION_GRID_VIEW_TIMEFRAME,
} from './action-plan';
import { Draggable } from '@hello-pangea/dnd';
import { documentId } from '../../../utils/common';
import { useTheme } from '@emotion/react';

const DraggableCardWrapper = ({
	action,
	index,
	draggable = true,
	children,
}) => {
	if (!draggable) {
		return (
			<div
				key={index}
				style={{
					width: '100%',
					// border: '1px solid red',
				}}
			>
				{children}
			</div>
		);
	}

	return (
		<Draggable
			draggableId={`draggable_action-card_${documentId(action)}`}
			index={index}
		>
			{(provided, snapshot) => (
				<div
					ref={provided.innerRef}
					{...provided.draggableProps}
					{...provided.dragHandleProps}
				>
					{/* {index} / {`draggable_action-card_${documentId(action)}`} */}
					{children}
				</div>
			)}
		</Draggable>
	);
};

const CompanyActionCard = ({
	action,
	view,
	index,
	onOpenAction,
	onAddAction,
	draggable = true,
	viaModal = false,
	debug = false,
	withAddButton = false,
	...props
}) => {
	const className = 'CompanyActionCard';

	const theme = useTheme();

	action = action instanceof CompanyActionModel
		? action
		: new CompanyActionModel(action);

	return (
 		<DraggableCardWrapper
			action={action}
			index={index}
			draggable={draggable}
		>
			<Box
				className={[
					'action-card',
					...action?.pillar || [],
					...draggable ? ['draggable'] : [],
					action.completionStatus,
					action.timeframe,
				].join(' ')}
			>
				<div>
					<div
						className='title ellipsis clamp-3'
						style={{
							fontSize: 18,
							// fontSize: Math.max(18, Math.min(24, round(500 / (1.1 ** action.title.length)))),
							lineHeight: 1.2,
						}}
					>
						<b>
							{/* #{documentId(action)} */}
							{action.title}
						</b>
					</div>
					{debug && (
						<div
							style={{ fontSize: 10, color: '#999' }}
						>
							<div>id: {action.id}, index: {index}</div>
							<div>timeframeIndex: {action.timeframeIndex}, completionStatusIndex: {action.completionStatusIndex}</div>
						</div>
					)}
				</div>

				<div
					style={{
						width: '100%',
						width: `calc(100% - ${viaModal ? 40 : 0}px)`,
						padding: '12px 20px',
						margin: -0,
						marginTop: 10,
						boxShadow: '0 0 10px -2px rgba(0,0,0,.2)',
						backgroundImage: 'radial-gradient(circle at 100% 0,#ffffff,#f5f5ff 60%)',
						borderRadius: 18,
					}}
				>
					<CompanyActionPillarTag action={action} />

					<div
						style={{
						}}
					>
						<Space
							style={{
								width: '100%',
								fontSize: 12,
								color: 'rgba(0,0,0,.5)',
								marginBottom: 12,
							}}
						>
							{[
								<CompanyActionComplexityTag action={action} />,
								<CompanyActionBudgetTag action={action} />,
								<CompanyActionPeopleTag action={action} />,
							]
								.filter((item) => item)
								.map((item, index, arr) => (
									<>
										<div key={index}>{item}</div>
										{index < arr.length - 1
											? <div key={`span-${index}`}> | </div>
											: null
										}
									</>
								))
							}
						</Space>

						{/* <Divider style={{ marginTop: 12, marginBottom: 16 }} /> */}

						<Space
							style={{
								justifyContent: 'space-between',
								alignItems: 'center',
								width: '100%',
							}}
						>
							{withAddButton
								? (
									<>
										<Link
											onClick={() => onOpenAction?.(action)}
											style={{ fontFamily: 'DM Sans' }}
										>
										Details&nbsp;<ArrowRightOutlined />
										</Link>
										<Button
											type='primary'
											onClick={() => onAddAction?.({ action })}
										>
											<b><PlusOutlined /> Add to plan</b>
										</Button>
									</>
								)
								: (
									<>
										{view !== GS_ACTION_GRID_VIEW_COMPLETION_STATUS && (
											<CompanyActionCompletionStatusTag action={action} />
										)}
										{view !== GS_ACTION_GRID_VIEW_TIMEFRAME && (
											<CompanyActionTimeframeTag action={action} />
										)}
										<Link
											onClick={() => onOpenAction?.(action)}
											style={{ fontFamily: 'DM Sans' }}
										>
										Details&nbsp;<ArrowRightOutlined />
										</Link>
									</>
								)}
						</Space>
					</div>
				</div>
			</Box>
		</DraggableCardWrapper>
	);
};

export default CompanyActionCard;
