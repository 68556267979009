import UMSPresentationMenu from './UMSPresentationMenu';
import UMSTeamMenu from './UMSTeamMenu';
import UMSAdminResultsMenu from './UMSAdminResultsMenu';
import UMSManagerResultsMenu from './UMSManagerResultsMenu';
import UMSSurveyMenu from './UMSSurveyMenu';
import UMSStaffMenu from './UMSStaffMenu';
import MenuBase from '../MenuBase';
import { PERMS_UMS_ENABLED } from './ums-utils';
import { UMS_LABEL } from '../../constants/constant';
import { menuGroupBuilder } from '../menu.utils';

class UMSMenu extends MenuBase {
	static props = {
		children: (args) => [
			...args.withDivider
				? [{ perms: PERMS_UMS_ENABLED }]
				: [],
			menuGroupBuilder({ label: UMS_LABEL, perms: PERMS_UMS_ENABLED }),
			(new UMSPresentationMenu()).build({ isRoot: args?.withRoot }),
			(new UMSTeamMenu()).build(),
			(new UMSAdminResultsMenu()).build(),
			(new UMSSurveyMenu(args)).build(),
			(new UMSManagerResultsMenu()).build(),
			(new UMSStaffMenu()).build(),
		],
	};

	constructor() {
		super(UMSMenu.props);
	}
}

export default UMSMenu;
