import ConfigureMenu from './configure/ConfigureMenu';
import StaffMenu from './staff/StaffMenu';
import { menuBuilder as UMSPlansMenuBuilder } from './menu-ums-plans';
import { PERMS_UMS_ENABLED } from './ums/ums-utils';
import ConfigureCompanyPageMenu from './configure/ConfigureCompanyPageMenu';
import GenderhireMessagesMenu from './genderhire/GenderhireMessagesMenu';
import GenderhireJobsMenu from './genderhire/GenderhireJobsMenu';
import GenderhireReportsMenu from './genderhire/GenderhireReportsMenu';
import GenderhireCandidatesAtsMenu from './genderhire/GenderhireCandidatesAtsMenu';
import GenderhireCandidatesMenu from './genderhire/GenderhireCandidatesMenu';
import GenderhireSearchTalentsMenu from './genderhire/GenderhireSearchTalentsMenu';
import GenderScoreDashboardMenu from './genderscore/GenderscoreDashboardMenu';
import GenderscoreSurveyMenu from './genderscore/GenderscoreSurveyMenu';
import GenderscoreActionPlanMenu from './genderscore/GenderscoreActionPlanMenu';
import GenderscoreResourcesMenu from './genderscore/GenderscoreResourcesMenu';
import GenderscoreFilledMenu from './genderscore/GenderscoreFilledMenu';
import { GENDERHIRE_LABEL, GENDERSCORE_LABEL } from '../constants/constant';
import { menuGroupBuilder } from './menu.utils';
import GenderhireMenu from './genderhire/GenderhireMenu';
import GenderscoreMenu from './genderscore/GenderscoreMenu';
import UMSMenu from './ums/UMSMenu';

// Divider, activated only if "Configure" is allowed
const configureDivider = {
	perms: (new ConfigureMenu())?.perms || [],
};

const staffDivider = {
	perms: StaffMenu?.props?.perms || [],
};

export const menuBuilder = (args) => {
	let withRoot = true;
	let configureMenu = (new ConfigureMenu()).build({ withDivider: true });
	if (args.isDocumentEditorOnly) {
		configureMenu = [(new ConfigureCompanyPageMenu()).build({ isRoot: true })];
		withRoot = false;
	}

	return [
		...GenderhireMenu.props.children({ ...args, withRoot }),
		...GenderscoreMenu.props.children({ ...args, withRoot, withDivider: true }),
		...UMSMenu.props.children({ ...args, withRoot, withDivider: true }),
		...configureMenu,
		...(new StaffMenu()).build({ withDivider: true }),
	];
	// return [
	// 	...GenderHireMenu.props.children(args),
	// 	// ...GenderHireMenu.props.children({
	// 	// 	isGenderScoreEnabled,
	// 	// 	isDocumentAdmin,
	// 	// }),
	// 	...GenderscoreMenu.props.children({
	// 		withDivider: true,
	// 		isDocumentAdmin,
	// 		isGenderScoreSurveyEnabled,
	// 	}),
	// 	...UMSMenu.props.children({
	// 		withDivider: true,
	// 		isUMSManager,
	// 		isUMSEmployee,
	// 		isUMSManagerSurveyFilled,
	// 		isUMSEmployeeSurveyFilled,
	// 	}),
	// 	...isDocumentEditorOnly
	// 		? [(new ConfigureCompanyPageMenu()).build({ isRoot: true })]
	// 		: [(new ConfigureMenu()).build({
	// 			withDivider: true,
	// 		})],
	// 	staffDivider,
	// 	(new StaffMenu()).build(),
	// ];
};

export default { menuBuilder };
