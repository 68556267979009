const CompanyGenderScoreLabel = (
	{
		company,
		...props
	},
) => {
	const score = company?.genderScore?.globalScore;

	if (!score) return null;

	let scoreLabel = 'Observer';
	if (score >= 80) scoreLabel = 'Pioneer';
	else if (score >= 60) scoreLabel = 'Pacemaker';
	else if (score >= 40) scoreLabel = 'Achiever';
	else if (score >= 25) scoreLabel = 'Discoverer';

	return (
		<span
			style={{
				userSelect: 'none',
				...props?.style,
			}}
		>
			{scoreLabel}
		</span>
	);
};

export default CompanyGenderScoreLabel;
