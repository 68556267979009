import { useSelector } from 'react-redux';
import { useTheme } from '@emotion/react';
import { getCompanySelected } from '../../reducers/app';
import {
	Divider, Space,
} from 'antd';
import {
	GENDERSCORE_EMPTY_ICON_IMG, GENDERSCORE_LABEL,
} from '../../constants/constant';
import GenderScoreLevelDetails from '../company/genderscore/levels/GenderScoreLevelDetails';
import GenderScoreSurveyStartForm from './GenderScoreSurveyStartForm';
import CompanyModel from '../../models/CompanyModel';
import { countSurveyQuestions } from './data/shared';
import GenderScoreResultsExpirationBanners from '../company/genderscore/results/GenderScoreResultsExpirationBanners';
import { scrollToTop } from '../../utils/common';
import { useEffect } from 'react';
import { useLocation } from 'react-router';

const GenderScoreSurveyStart = ({
	...props
}) => {
	const className = 'GenderScoreSurveyStart';

	const theme = useTheme();
	const location = useLocation();
	const companySelected = useSelector(getCompanySelected);

	useEffect(() => {
		scrollToTop();
	}, [location.pathname]);

	return (
		<>
			<GenderScoreResultsExpirationBanners
				company={companySelected}
				withNavigateToSurvey={false}
			/>
			<div
				style={{
					maxWidth: 860,
					margin: '40px auto 0',
					fontSize: 16,
				}}
			>
				<Space
					wrap={false}
					align={'top'}
					size={30}
				>
					{GENDERSCORE_EMPTY_ICON_IMG({ width: 210 })}

					<div style={{ fontSize: 16 }}>
						<h1>
						Welcome to the<br />{GENDERSCORE_LABEL} Survey! 🌟
						</h1>
						<p>
						This assessment consists of&nbsp;
							{countSurveyQuestions({
								surveyConf: new CompanyModel(companySelected)
									.getCompanyPlanGenderScoreSurveyDataOption(),
							})}
						&nbsp;questions.
						</p>
						<p>
						Your progress will be saved along
						the way so you can come back to resume where you left off.
						</p>
						<p>
							<b>
							Your efforts toward creating a more equitable workplace are truly commendable.
							Let's get started on this journey together!
							</b>
						</p>
					</div>
				</Space>

				<Divider />

				<GenderScoreSurveyStartForm />

				<div
					style={{
						background: theme.color.bgGrey,
						padding: '20px 30px',
						borderRadius: 10,
						marginTop: 60,
					}}
				>
					<Divider style={{ }}>
						<h2>The {GENDERSCORE_LABEL} Levels</h2>
					</Divider>

					<p style={{ fontSize: 16 }}>
				Once you've taken the test, it's essential to understand
				the implications of your score.
						<br />Here's what each bracket signifies:
					</p>

					<GenderScoreLevelDetails />

				</div>
			</div >
		</>
	);
};

export default GenderScoreSurveyStart;
