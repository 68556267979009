import { useTheme } from '@emotion/react';
import { Space } from 'antd';
import BlockTester from '../app/blocker/BlockTester';
import AccountCard from './AccountCard';
import {
	FaBan, FaCheck, FaExclamation, FaExclamationCircle,
} from 'react-icons/fa';
import UserModel from '../../models/UserModel';
import AccountBrowsingForm from './AccountBrowsingForm';
import AccountBrowsingSettingOverrideDisabled from './AccountBrowsingSettingOverrideDisabled';
import SettingsBrowsingUserStubPreview from './SettingsBrowsingUserStubPreview';

const AccountBrowsingCard = (
	{
		user,
		style = {},
	},
) => {
	const className = 'AccountBrowsingCard';

	const theme = useTheme();
	if (!user) return null;
	const userModel = new UserModel(user);

	return (
		<div style={{ position: 'relative' }}>
			<BlockTester withBorder={false} tagStyle={{ top: 5, left: 24 }}>
				<AccountCard
					user={user}
					AccountForm={AccountBrowsingForm}
					title={<>👀 Your unbiased browsing settings</>}
					details={<>
						Reduce inconscious bias by navigating 50inTech's talent pool without showing the
						photos or names of candidates.
					</>}
				>
					<Space direction='vertical' size={12}>
						<span>
							<Space direction='horizontal' align='top'>
								{userModel.canDisplayTalentAvatars?.()
									? <>
										<FaBan style={{ marginBottom: -2, color: theme.color.red }} />
										<span>Talent <b>avatars</b> are <b>visible</b></span>
									</>
									: <>
										<FaCheck style={{ marginBottom: -2, color: theme.color.darkturquoise }} />
										<span>Talent <b>avatars</b> are <b>hidden</b></span>
									</>
								}
							</Space>
							{!userModel?.companyCanRecruiterOverrideAnonTalentAvatars?.()
							&& <AccountBrowsingSettingOverrideDisabled />
							}
						</span>
						<span>
							<Space direction='horizontal' align='top'>
								{userModel.canDisplayTalentNames?.()
									? <>
										<FaBan style={{ marginBottom: -2, color: theme.color.red }} />
										<span>Talent <b>names</b> are <b>visible</b></span>
									</>
									: <>
										<FaCheck style={{ marginBottom: -2, color: theme.color.darkturquoise }} />
										<span>Talent <b>names</b> are <b>hidden</b></span>
									</>
								}
							</Space>
							{!userModel?.companyCanRecruiterOverrideAnonTalentNames?.()
								&& <AccountBrowsingSettingOverrideDisabled />
							}
						</span>

						<SettingsBrowsingUserStubPreview
							style={{ marginBottom: 0 }}
							showAvatar={userModel?.canDisplayTalentAvatars()}
							showName={userModel?.canDisplayTalentNames()}
						/>

					</Space>
				</AccountCard>
			</BlockTester>
		</div>
	);
};

export default AccountBrowsingCard;
