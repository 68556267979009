import { DMSansBold } from './DMSansBold';
import { DMSansBoldItalic } from './DMSansBoldItalic';
import { DMSansItalic } from './DMSansItalic';
import { DMSansRegular } from './DMSansRegular';
import { SpaceGroteskBold } from './SpaceGroteskBold';
import { SpaceGroteskRegular } from './SpaceGroteskRegular';
import { SpaceGroteskSemiBold } from './SpaceGroteskSemiBold';

const fonts = {
	DMSans: {
		regular: DMSansRegular,
		bold: DMSansBold,
		italic: DMSansItalic,
		bolditalic: DMSansBoldItalic,
	},
	// DMSansBold,
	// DMSansBoldItalic,
	// DMSansItalic,
	// DMSansRegular,
	SpaceGrotesk: {
		regular: SpaceGroteskRegular,
		bold: SpaceGroteskBold,
	},
	// SpaceGroteskBold,
	// SpaceGroteskRegular,
	// SpaceGroteskSemiBold,
};

export default fonts;
