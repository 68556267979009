import AclAddForm from '../AclAddForm';
import { localDebug } from '../../../utils/LocalDebug';

const InviteUserConfirmForm = (
	{
		form,
		initialValues = {},
		onCompanyChange,
		onSubmit,
		children,
	},
) => {
	localDebug('InviteUserConfirmForm', { initialValues });
	return (
		<AclAddForm
			form={form}
			initialValues={initialValues}
			onCompanyChange={onCompanyChange}
			onSubmit={onSubmit}
		>
			{children}
		</AclAddForm>
	);
};

export default InviteUserConfirmForm;
