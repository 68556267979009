import { PERMS_GRANT_STAFF } from '../../menu.utils';
import MenuBase from '../../MenuBase';
import { UnorderedListOutlined } from '@ant-design/icons';
import StaffGenderScoreAnswersToActionsPage from '../../../containers/staff/genderscore/StaffGenderScoreAnswersToActionsPage';

export const StaffGSAnswersToActionsIcon = UnorderedListOutlined;

class StaffGSAnswersToActionsMenu extends MenuBase {
	static props = {
		name: 'staff-genderscore-answers-to-actions',
		path: '/staff/genderscore/answers-to-actions',
		label: 'Answers to Actions',
		icon: <StaffGSAnswersToActionsIcon />,
		iconLegacy: '',
		Component: StaffGenderScoreAnswersToActionsPage,
		perms: PERMS_GRANT_STAFF,
	};

	constructor() {
		super(StaffGSAnswersToActionsMenu.props);
	}
}

export default StaffGSAnswersToActionsMenu;
