import { useTheme } from '@emotion/react';
import HelpVideoLink from './button/HelpVideoLink';
import { mixColors } from '../../utils/common';
import { Divider, Space } from 'antd';

const SectionTitle = (
	{
		icon,
		title,
		subtitle,
		helpVideoUrl,
		children,
		renderAlone = true,
	},
) => {
	const theme = useTheme();

	const titleComponent = (
		<span
			style={{
				color: 'rgba(0,0,0,.88)',
				fontWeight: 400,
				// marginBottom: 12,
				fontFamily: 'DM Sans',
				fontSize: 28,
			}}
		>
			{icon && (
				<span
					style={{
						fontSize: 28,
						marginRight: 10,
					}}
				>
					{icon}
				</span>
			)}
			<span>
				{title}
			</span>
		</span>
	);

	const subtitleComponent = subtitle && (
		<div
			style={{
				fontSize: 14,
				fontWeight: 300,
				lineHeight: 1.3,
				color: 'rgba(0,0,0,0.88)',
				// marginTop: 8,
			}}
		>
			{subtitle}
		</div>
	);

	return renderAlone
		? (
			<Space
				direction='vertical'
				size={0}
				style={{ width: '100%' }}
			>
				<h1
					style={{
						// marginBottom: 20,
						// color: 'rgba(0,0,0,.88)',
						// fontWeight: '400 !important',
						width: '100%',
					}}
				>
					{titleComponent}
					<HelpVideoLink url={helpVideoUrl} style={{ marginLeft: 10 }}/>
				</h1>
				<div style={{ width: '100%' }}>
					{children || subtitleComponent}
				</div>
				<Divider style={{ width: '100%' }} />
			</Space>
		)
		: (
			<>
				{titleComponent}
				{subtitleComponent}
			</>
		);
};

export default SectionTitle;
