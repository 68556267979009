import { useEffect, useState } from 'react';
import { useTheme } from '@emotion/react';
import {
	List, Tag,
} from 'antd';
import { request } from '../../services/api';
import { documentId } from '../../utils/common';
import { LocalDebug } from '../../utils/LocalDebug';
import UserAvatar from '../user/widgets/UserAvatar';
import UserName from '../user/widgets/UserName';
import DatePreview from '../app/table/DatePreview';
import ChatbotMessages from './ChatbotMessages';

const StaffChatbotViewer = ({
	height,
}) => {
	const className = 'StaffChatbotViewer';
	const theme = useTheme();

	const [chatbotId, setChatbotId] = useState();
	const [chatbots, setChatbots] = useState([]);
	const [chatbot, setChatbot] = useState();
	const [messages, setMessages] = useState([]);

	const fetchChatbotList = async () => {
		const result = await request.get('/chatbot/');
		LocalDebug.logInfo({ className, method: 'fetchChatbotList' }, { result: result?.data });
		setChatbots(result?.data?.results || []);
		// setChatbot(result?.data?.result);
		// setMessages(result?.data?.result?.messages);
	};

	const fetchChatbot = async () => {
		const result = await request.get(`/chatbot/${chatbotId}`);
		LocalDebug.logInfo({ className, method: 'fetchChatbot' }, { chatbotId, result: result?.data?.result });
		setChatbot(result?.data?.result);
		setMessages(result?.data?.result?.messages);
	};

	useEffect(() => {
		fetchChatbotList();
	}, []);

	useEffect(() => {
		LocalDebug.logUseEffect({ className, effects: 'chatbotId' }, { chatbotId });
		if (chatbotId) {
			fetchChatbot();
		} else {
			setChatbot(null);
			setMessages([]);
		}
	}, [chatbotId]);

	return (
		<div
			style={{
				position: 'relative',
				display: 'grid',
				gridTemplateColumns: '260px calc(100% - 260px - 20px)',
				columnGap: 20,
				// overflow: 'hidden',
				height,
			}}
		>
			<div
				style={{
					// position: 'absolute',
					// top: 10,
					// left: 10,
					border: '1px solid #eee',
					borderRadius: 10,
					height: '100%',
					height,
					maxHeight: '100%',
					overflowY: 'scroll',
					boxShadow: '0 0 20px -4px rgba(0,0,0,.3)',
				}}
			>
				<List
					itemLayout="horizontal"
					dataSource={chatbots}
					renderItem={(item, index) => (
						<List.Item
							onClick={() => setChatbotId(documentId(item))}
							style={{
								cursor: 'pointer',
								userSelect: 'none',
								padding: '12px 10px 8px',
								background: item.isActive ? 'white' : 'rgba(0,0,0,.05)',
								borderRight: `5px solid ${documentId(item) === chatbotId ? theme.color.fitBlueElectric : 'transparent'}`,
							}}>
							{/* <div onClick={() => setChatbotId(documentId(item))}> */}
							<List.Item.Meta
								avatar={<UserAvatar size={32} user={item.user} />}
								title={<>
									<Tag
										size='small'
										color={item.isActive ? 'green' : 'red'}
										style={{ fontWeight: 'normal' }}
									>
										#{documentId(item)?.slice(-4)}
									</Tag>
									&nbsp;
									<UserName user={item.user} style={{ fontSize: 13 }} />
								</>}
								description={<div style={{ fontSize: 11 }}>
									{item.messages} messages
									&nbsp;
									<DatePreview withDate={false} date={item.createdAt} />
								</div>}
								style={{
									opacity: item.isActive ? 1 : 0.5,
								}}
							/>
							{/* </div> */}
						</List.Item>
					)}
				/>
				{/* <div
					style={{
						overflowY: 'scroll',
					}}
				>
					{chatbots?.map?.((c) => (
						<StaffChatbotViewerButton
							key={documentId(c)}
							chatbot={c}
							onSelect={setChatbotId}
						/>
					))}
				</div> */}
			</div>

			<div
				style={{
					padding: '0 20px',
					border: '1px solid #eee',
					borderRadius: 10,
					// height: '100%',
					// maxHeight: '100%',
					width: '100%',
					maxWidth: '100%',
					height,
					boxShadow: '0 0 20px -4px rgba(0,0,0,.3)',
				}}
			>
				<ChatbotMessages
					messages={messages}
					// height={height}
					height={'100%'}
				/>
			</div>
		</div>
	);
};

export default StaffChatbotViewer;
