/** @jsxImportSource @emotion/react */

import { useTheme } from '@emotion/react';
import AvatarCustom from './AvatarCustom';
import { Typography } from 'antd';
import { useAuthContext } from '../../../../../../hooks/useAuthContext';

const classes = (theme) => ({
	container: {
		'marginTop': '42px',
		'background': 'radial-gradient(#3157af 30%, #0d2b79 64%)',
		'backgroundPosition': '50% 0',
		'backgroundSize': '200% 200%',
		'backgroundRepeat': 'no-repeat',

		'marginBottom': '72px',
		'paddingTop': '19px',
		'paddingBottom': '6px',
		':before ': {
			content: '""',
			display: 'block',
			position: 'absolute',
			bottom: '-50px',
			width: '50%',
			height: '50px',
			left: 0,
			background:
				'linear-gradient(to bottom left, #3157af 49.6%, transparent 50.4%), linear-gradient(to bottom right, #a8e4d6 49.6%, transparent 50.4%)',
		},
		':after': {
			content: '""',
			display: 'block',
			position: 'absolute',
			bottom: '-50px',
			width: '50%',
			height: '50px',
			right: 0,
			background:
				'linear-gradient(to bottom right, #3157af 49.6%, transparent 50.4%), linear-gradient(to bottom left, #eae040 49.6%, transparent 50.4%)',
		},
	},
	sizer: {
		'width': '680px',
		'margin': 'auto',
		'fontSize': '18px',
		'.separator': {
			fontSize: 28,
			color: theme.color.blue,
			width: '100%',
			margin: '80px auto 12px',
			paddingTop: 30,
			borderTop: '3px dashed #e7d2ff',
		},
	},
	layoutFlexvc: {
		paddingTop: '24px',
		paddingBottom: '0px',
		fontSize: '14px',
	},

	topic: {
		fontSize: '11px',
		textTransform: 'uppercase',
		fontWeight: 600,
		lineHeight: '1.2!important',
		paddingBottom: '4px',
		color: 'rgba(255, 255, 255, 0.6)',
		padding: '4px 0 6px',
		marginTop: '-26px',
	},
	title: {
		fontSize: '42px!important',
		marginBottom: '5px!important',
		marginTop: '0!important',
		lineHeight: '1.15!important',
		fontWeight: 700,
		wordBreak: 'break-word',
		color: `${theme.color.white} !important`,
	},
});

const avatarClassNames = (theme) => ({
	container: {
		'alignItems': 'center',
		'marginTop': '9px',
		'lignHeight': 1.3,
		'transition': 'all 0.2s ease-out',
		'cursor': 'pointer',
		'.separator': { opacity: 0.65 },
		':hover': {
			opacity: 0.8,
		},
		'.text-danger': {
			color: `${theme.color.white}!important`,
		},
	},
	userAvatar: {
		width: '32px',
		minWidth: '32px',
		maxWidth: '32px',
		height: '32px',
		minHeight: '32px',
		maxHeight: '32px',
		position: 'relative',
		marginRight: '8px',
	},
	avatar: {
		position: 'relative',
		top: '0px',
		left: '0px',
		marginRight: '10px',
		backgroundColor: 'rgba(0, 0, 0, 0.1)',
		backgroundSize: 'cover',
		backgroundPosition: 'center',
		backgroundRepeat: 'no-repeat',
		width: '100%',
		height: '100%',
		zIndex: 2,
		transition: 'all 0.2s ease-out',
		borderRadius: '100%',
	},
	userName: {
		color: theme.color.white,
		fontSize: '14px',
		fontWeight: 500,
		lineHeight: 1.2,
	},
	userProfession: {
		fontWeight: 400,
		fontSize: 12,
		color: 'rgba(255, 255, 255, 0.75) !important',
	},
	userCreatedDelay: {
		color: 'rgba(255, 255, 255, 0.45) !important',
		fontSize: '12px',
		lineHeight: 1.4,
	},
	userLocation: {
		fontSize: '10px',
		lineHeight: 1.5,
		color: theme.color.dustyGray,
	},
});

const LearnHeader = ({ article, lang }) => {
	const theme = useTheme();
	const styles = classes(theme);
	const { currentUser } = useAuthContext();
	const user = article.user ? article.user : currentUser;
	return (
		<div css={styles.sizer}>
			<Typography variant="text" css={styles.topic}>
				{article?.topic || ''}
			</Typography>
			<Typography level={1} variant="title" css={styles.title}>
				{ article.getField('title', lang, article.getField('title'))}
			</Typography>
			<AvatarCustom
				{...user}
				avatar={
					user?.information?.avatar || 'assets/avatars/default-avatar.jpg'
				}
				position={
					<span
						dangerouslySetInnerHTML={{
							__html: article?.user?.information?.position
								? `${article?.user?.information?.position} chez ${article?.user?.information?.company}`
								: article?.user?.information?.company,
						}}
					 />
				}
				createdAt={article?.createdAt}
				classes={avatarClassNames}
			/>
		</div>
	);
};

export default LearnHeader;
