import { useTheme } from '@emotion/react';
import { Space, Tag, Tooltip } from 'antd';
import {
	NOT_PROVIDED_YET_LABEL,
	getDEICriteriaLabel,
	userTag50Style,
	userTagStyle,
} from '../../../constants/constant';
import BoxRainbow from '../../app/box/BoxRainbow';

const UserDEICriteria = (
	{
		user,
		mode = 'tags',
		withGroups = false,
		size = 'medium',
		full = false,
		bgColor,
		notProvided = NOT_PROVIDED_YET_LABEL,
		...props
	},
) => {
	const theme = useTheme();

	const value = user?.jobSearch?.deiCriteria;
	if (!(value?.length > 0)) return notProvided;

	if (mode === 'tags') {
		const tagStyle = {
			...userTagStyle(size),
			...userTag50Style,
			...props?.style,
		};
		const tags = value.map((tag, index) => <Tag color='blue' key={index}
			style={tagStyle}>{getDEICriteriaLabel(tag)}</Tag>);
		return tags?.length
			? <Space direction='horizontal' wrap={true} size={[2, 1]}>{tags}</Space>
			: notProvided;
	}

	return (
		<Tooltip
			title={<div style={{ textAlign: 'center' }}>
				Most important DE&I criteria selected by this user
			</div>}
		>
			<Space
				direction='vertical'
				size={6}
				style={{
					marginTop: 20,
					userSelect: 'none',
					...props?.style?.list,
				}}
			>
				<span
					style={{
						position: 'relative',
						background: theme.color.darkgrey,
						color: theme.color.white,
						border: '2px solid transparent',
						borderRadius: 30,
						padding: '3px 8px',
						zIndex: 2,
					}}
				>
					<b>DE&I criteria</b>
				</span>

				<BoxRainbow
					bgColor={bgColor || theme.color.bgGrey}
					style={{
						marginTop: -18,
						borderRadius: 10,
					}}
				>
					<Space
						direction='vertical'
						size={6}
						style={{
							padding: '14px 10px',
							fontWeight: 'bold',
							lineHeight: 1.2,
						}}
					>
						{value.map((tag, index) => (
							<span
								key={tag}
								style={{
									// padding: '3px 8px',
									// background,
									// border: '1px solid transparent',
									// borderRadius: 30,
									...props?.style?.listItem,
								}}
							>
								{getDEICriteriaLabel(tag)}
							</span>
						))}
					</Space>
				</BoxRainbow>
			</Space>
		</Tooltip>
	);
};

export default UserDEICriteria;
