import { isStringTypeValidator } from '../../../utils/isStringTypeValidator';
import { innerObjPropExtractor } from '../../property/property-extractor';
import { innerObjPropMapper } from '../../property/property-mapper';

const USER_PROPERTY_PROFILE_GOAL_KEY = 'profile-goal';

const USER_PROPERTY_PROFILE_GOAL_FREE = false;
const USER_PROPERTY_PROFILE_GOAL_ANSWER_CAREER = 'career';
const USER_PROPERTY_PROFILE_GOAL_ANSWER_MENTOR = 'mentor';

const USER_PROPERTY_PROFILE_GOAL_OPTIONS = [
	{ value: USER_PROPERTY_PROFILE_GOAL_ANSWER_CAREER, label: 'Career' },
	{ value: USER_PROPERTY_PROFILE_GOAL_ANSWER_MENTOR, label: 'Mentor' },
];

const USER_PROPERTY_PROFILE_GOAL_VALUES = USER_PROPERTY_PROFILE_GOAL_OPTIONS.map((o) => o.value);

const customValidator = (value) => USER_PROPERTY_PROFILE_GOAL_VALUES.includes(value);

const USER_PROPERTY_PROFILE_GOAL_VALIDATORS = [
	isStringTypeValidator,
	customValidator,
];

const USER_PROPERTY_PROFILE_GOAL_FIELD = 'confidential.goal';
const USER_PROPERTY_PROFILE_GOAL_MAPPER = innerObjPropMapper(USER_PROPERTY_PROFILE_GOAL_FIELD);
const USER_PROPERTY_PROFILE_GOAL_EXTRACTOR = innerObjPropExtractor(USER_PROPERTY_PROFILE_GOAL_FIELD);

export default {
	key: USER_PROPERTY_PROFILE_GOAL_KEY,
	USER_PROPERTY_PROFILE_GOAL_KEY,
	validators: USER_PROPERTY_PROFILE_GOAL_VALIDATORS,
	USER_PROPERTY_PROFILE_GOAL_VALIDATORS,
	options: USER_PROPERTY_PROFILE_GOAL_OPTIONS,
	USER_PROPERTY_PROFILE_GOAL_OPTIONS,
	free: USER_PROPERTY_PROFILE_GOAL_FREE,
	USER_PROPERTY_PROFILE_GOAL_FREE,
	mapper: USER_PROPERTY_PROFILE_GOAL_MAPPER,
	USER_PROPERTY_PROFILE_GOAL_MAPPER,
	extractor: USER_PROPERTY_PROFILE_GOAL_EXTRACTOR,
	USER_PROPERTY_PROFILE_GOAL_EXTRACTOR,
	field: USER_PROPERTY_PROFILE_GOAL_FIELD,
	USER_PROPERTY_PROFILE_GOAL_FIELD,
};
