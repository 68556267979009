import {
	Col, DatePicker, Form, Input, Row,
} from 'antd';
import { useEffect, useState } from 'react';
import { DATE_FORMAT } from '../../../../constants/constant';
import { COMPANY_RECRUITER_LOG_ACTIONS } from '../../../../constants/company-recruiter-log';
import FormItem from '../../../company/form/FormItem';
import { useSelector } from 'react-redux';
import { getCompanies } from '../../../../reducers/app';
import FormCompanySelect from '../../../app/form/FormCompanySelect';
import { localDebug } from '../../../../utils/LocalDebug';
import { useAuthContext } from '../../../../hooks/useAuthContext';
import FilterModal from '../../../app/filters/FilterModal';
import UserSelect from '../../../user/UserSelect';
import UserService from '../../../../services/user';
import FormSelect from '../../../app/form/FormSelect';
import { documentId, getQueryParams } from '../../../../utils/common';

const RecruiterActivityFilterModal = (
	{
		title,
		open,
		initialValues = {},
		onClose,
		onFilter,
	},
) => {
	const {
		isStaff, isStaffView, isCompanyRecruiter, currentUser, companyRecruiterDocIds,
	} = useAuthContext();

	const [form] = Form.useForm();

	const company = useSelector(getCompanies);
	const queryParams = getQueryParams();
	const [optionsCompany, setOptionsCompany] = useState([]);

	const getDefaultSelected = (fieldName) => {
		return queryParams[fieldName] || [];
	};

	useEffect(() => {
		setOptionsCompany([
			// {id:null, name: ' Any company'},
			...company,
		]);
	}, [isCompanyRecruiter, isStaff, isStaffView, companyRecruiterDocIds, company, currentUser]);

	const handleFetchRecruiter = async (options) => {
		const { data: { items } } = await UserService.findAll({ ...options, isRecruiter: true });
		return items;
	};

	const handleFilter = () => {
		form
			.validateFields()
			.then((values) => {
				const filtersValues = {
					...values,
				};
				if (onFilter) onFilter(filtersValues);
			})
			.catch((errors) => {
				localDebug('Validate Failed:', errors);
			});
	};

	useEffect(() => {
		form.resetFields();
		form.setFieldsValue(initialValues);
	}, [form, initialValues]);

	return (
		<FilterModal
			title={title}
			width={500}
			open={open}
			onClose={onClose}
			onFilter={handleFilter}
		>
			{/* <Modal */}
			{/*        title={<Space> Message filters <Button type="text" onClick={handleResetFilter}><FcClearFilters /></Button></Space>} */}
			{/*        open={open} */}
			{/*        okText="OK" */}
			{/*        cancelText="Cancel" */}
			{/*        onCancel={onClose} */}
			{/*        width={750} */}
			{/*        onOk={handleFilter} */}
			{/*    > */}
			<Form
				form={form}
				name="filters"
				labelCol={{ span: 9 }}
				labelAlign='right'
				className="user-filter-form"
			>
				<Row gutter={0}>
					<Col span={24}>
						<FormItem
							label="Recruiter"
							defaultContainer="div"
							labelCol={{ span: 9 }}
							// styles={{rootContainer: {padding: '0 10px', display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }, label: {marginBottom: 0, paddingRight: 20}, itemContainer: {marginBottom: 0}}}
							name="userId"
						>
							<UserSelect defaultOptions={[]} onFindUsers={handleFetchRecruiter}/>
						</FormItem>
					</Col>
					<Col span={24}>
						<FormItem
							label="Companies"
							defaultContainer="div"
							labelCol={{ span: 9 }}
							name="companyIds"
						>
							<FormCompanySelect
								options={optionsCompany.map((c) => ({ value: documentId(c), label: c.name, item: c }))}
								defaultValue={getDefaultSelected('companyIds')}
								mode="multiple"
							/>
						</FormItem>
					</Col>

					<Col span={24}>
						<FormItem
							label="Excluded companies"
							defaultContainer="div"
							labelCol={{ span: 9 }}
							name="notCompanyIds"
						>
							<FormCompanySelect with50inTechSorting={1}
											   options={optionsCompany.map((c) => ({
												   value: documentId(c), label: c.name, item: c,
											   }))}
											   defaultValue={getDefaultSelected('notCompanyIds')}
											   mode="multiple"
							/>
						</FormItem>
					</Col>

					<Col span={24}>
						<FormItem
							label='Actions'
							name="action"
						>
							<FormSelect mode="multiple" options={COMPANY_RECRUITER_LOG_ACTIONS}/>
						</FormItem>
					</Col>
					<Col span={24}>
						<FormItem
							label='Versions'
							name="version"
						>
							<Input/>
						</FormItem>
					</Col>
					<Col span={24}>
						<FormItem label='Host' name="host">
							<Input/>
						</FormItem>
					</Col>
					<Col span={24}>
						<FormItem label='Path name' name="pathname">
							<Input/>
						</FormItem>
					</Col>
					<Col span={24}>
						<FormItem
							label='Created before'
							name="createdBefore"
						>
							<DatePicker showTime format={DATE_FORMAT}/>
						</FormItem>
					</Col>
					<Col span={24}>
						<FormItem
							label='Created after'
							name="createdAfter"
						>
							<DatePicker showTime format={DATE_FORMAT}/>
						</FormItem>
					</Col>
				</Row>
			</Form>
		</FilterModal>
	);
};

export default RecruiterActivityFilterModal;
