import { useEffect, useState } from 'react';
import { LocalDebug } from '../../utils/LocalDebug';
import { useTheme } from '@emotion/react';
import authFirebaseService from '../../services/authFirebase';
import ChatbotPrompt from './ChatbotPrompt';
import ChatbotMessages from './ChatbotMessages';
import ChatbotTools from './ChatbotTools';
import { request } from '../../services/api';
import { API_URL, documentId } from '../../utils/common';
import LoadSpinner from '../app/LoadSpinner';
import { Divider } from 'antd';

const JSON_SEPARATOR_CHAR = '‡';

const Chatbot = ({
	width = 800,
	height = '80vh',
}) => {
	const className = 'Chatbot';
	const theme = useTheme();

	const [chatbot, setChatbot] = useState();
	const [answerPending, setAnswerPending] = useState(false);
	const [messages, setMessages] = useState([]);
	const [question, setQuestion] = useState();

	const callApiCompletion = async (body) => fetch(
		`${API_URL}/chatbot/completion`,
		{
			method: 'post',
			headers: {
				'Accept': 'application/json, text/plain, */*',
				'Content-Type': 'application/json',
				'Authorization': `Bearer ${await authFirebaseService.getToken()}`,
			},
			body,
		},
	);

	const fetchUserChatbot = async () => {
		const result = await request.get('/chatbot/user');
		LocalDebug.logUseEffect({ className, effects: '' }, { result: result?.data?.result });
		setChatbot(result?.data?.result);
		setMessages(result?.data?.result?.messages
			?.filter((item) => item.role !== 'system')
			?.map((item) => {
				const jsonSeparatorIndex = item?.content?.indexOf?.(JSON_SEPARATOR_CHAR);
				if (jsonSeparatorIndex === -1) return item;
				return {
					...item,
					content: item?.content?.slice?.(0, jsonSeparatorIndex),
				};
			}));
		const lastAssistantContent = (
			result?.data?.result?.messages
				?.slice?.()
				?.reverse?.()
				?.find((item) => item.role === 'assistant')
		)?.content;
		if (!lastAssistantContent) return;
		// const jsonSeparatorIndex = lastAssistantContent?.indexOf?.(JSON_SEPARATION_CHAR);
		// if (jsonSeparatorIndex === -1) return;
		let [, jsonContent] = [
			...lastAssistantContent?.split?.(JSON_SEPARATOR_CHAR) || [],
			null,
		].map((item) => item?.trim?.());
		if (!jsonContent) return;
		jsonContent = jsonContent?.trim?.();
		jsonContent = jsonContent?.slice?.(-4) === '\n```' ? jsonContent?.slice?.(0, -4) : jsonContent;
		if (!jsonContent) return;
		try {
			setQuestion(JSON.parse(jsonContent));
		} catch (e) {
			console.log(e);
		}
	};

	useEffect(() => {
		fetchUserChatbot();
		// fetchParamsToken();
	}, []);

	const handleClear = () => {
		setQuestion();
		setMessages([]);
		setAnswerPending(false);
	};

	const handleResetChatbot = async () => {
		handleClear();
		if (documentId(chatbot)) {
			await request.delete(`/chatbot/${documentId(chatbot)}`);
			setChatbot();
		}
		await fetchUserChatbot();
	};

	const handleSubmit = async (values) => {
		const { userPrompt, preventStorage } = values;
		LocalDebug.logInfo({ className, method: 'handleSubmit' }, { userPrompt, preventStorage });
		setQuestion(null);
		setAnswerPending(true);
		const currentMessages = messages || [];
		const newUserMessage = { role: 'user', content: userPrompt };
		const newAssistantMessage = { role: 'assistant', content: '...' };

		const debug = false;

		setMessages((p) => [
			...currentMessages,
			newUserMessage,
			newAssistantMessage,
		]);

		let jsonFound = false;
		let jsonContent = '';

		try {
			const streamResponse = await callApiCompletion(JSON.stringify({ userPrompt, preventStorage }));

			newAssistantMessage.content = '';
			setMessages((p) => [
				...currentMessages,
				newUserMessage,
				newAssistantMessage,
			]);

			// const response = await request.post('/chatbot/completion', { userPrompt });
			LocalDebug.logInfo({ className, method: 'handleSubmit' }, { userPrompt, preventStorage, response: streamResponse });

			if (!streamResponse.ok || !streamResponse.body) {
				setAnswerPending(false);
				throw streamResponse.statusText;
			}
			// setUserPrompt('');

			// Here we start prepping for the streaming response
			const reader = streamResponse.body.getReader();
			const decoder = new TextDecoder();
			const loopRunner = true;

			while (loopRunner) {
				// Here we start reading the stream, until its done.
				const { value, done } = await reader.read();
				if (done) {
					break;
				}
				const decodedChunk = decoder.decode(value, { stream: true });
				// LocalDebug.logInfo({ className, method: 'handleSubmit' }, { decodedChunk });
				if (jsonFound) {
					jsonContent += decodedChunk;
					if (debug) newAssistantMessage.content += decodedChunk;
				} else {
					const parts = decodedChunk.split(JSON_SEPARATOR_CHAR);
					newAssistantMessage.content += parts[0];
					setMessages((p) => [
						...currentMessages,
						newUserMessage,
						newAssistantMessage,
					]);
					if (parts?.length > 1) {
						jsonFound = true;
						jsonContent += parts[1];
						if (debug) newAssistantMessage.content += parts[1];
					}
				}
			}
			setAnswerPending(false);

			if (!chatbot) {
				await fetchUserChatbot();
			}
		} catch (e) {
			setAnswerPending(false);
			console.log(e);
			throw e;
		}

		try {
			const json = JSON.parse(jsonContent.trim());
			setQuestion(json);
		} catch (e) {
			console.log(e);
		}
	};

	const handleQuestionInput = (value) => {
		handleSubmit({ userPrompt: JSON.stringify(value) });
		setQuestion(null);
	};

	return (
		<div
			style={{
				display: 'grid',
				gridTemplateRows: '1fr 10px 100px 30px',
				// border: '1px solid blue',
				height: 'calc(100vh - 125px)',
			}}
		>
			<ChatbotMessages
				messages={messages}
				height={height}
				question={question}
				onQuestionInput={handleQuestionInput}
			/>

			<div style={{ borderTop: '1px solid #eee' }}/>
			<ChatbotPrompt
				messages={messages}
				answerPending={answerPending}
				setAnswerPending={setAnswerPending}
				onSubmit={handleSubmit}
				onClear={handleClear}
			/>
			<ChatbotTools
				chatbot={chatbot}
				messages={messages}
				answerPending={answerPending}
				onResetChatbot={handleResetChatbot}
			/>
			{/* {JSON.stringify(question)}<br/> */}
			{/* {question?.options?.length}<br/> */}
		</div>
	);
};

export default Chatbot;
