import { Badge } from 'antd';

const UserButtonBadge = (
	{
		count,
		withNumber = true,
		children,
		...props
	},
) => {
	if (count === 0) return children;

	return (
		<Badge
			size="small"
			showZero={false}
			status='default'
			count={withNumber ? count : ' '}
			overflowCount={10000}
			offset={[-4, 0]}
			color='#ffb703'
			{...props}
			style={{
				fontWeight: 'bold',
				fontSize: 10,
				background: 'white',
				borderRadius: 30,
				color: '#6a040f',
				minWidth: 16,
				minHeight: 16,
				height: 16,
				padding: '1px 4px 2px',
				...props?.style,
			}}
		>
			{children}
		</Badge>
	);
};

export default UserButtonBadge;
