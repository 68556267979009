import { NOT_PROVIDED_YET_LABEL } from '../../../../constants/constant';
import EntriesCard from '../EntriesCard';
import UserResumeExperienceEntryLine from './UserResumeExperienceEntryLine';
import UserResumeExperienceEntryRow from './UserResumeExperienceEntryRow';

const UserResumeExperienceCard = (
	{
		user,
		limit = 1,
		hideIfEmpty = true,
		notProvided = NOT_PROVIDED_YET_LABEL,
		children,
		...props
	},
) => {
	return <EntriesCard
		entries={user?.getResumeExperienceFlattened()}
		RowRenderer={UserResumeExperienceEntryRow}
		LineRenderer={UserResumeExperienceEntryLine}
		// BodyRenderer={UserResumeEducationCardBody}
		title='Experience'
		hideIfEmpty={hideIfEmpty}
		{...props}
	>
		{children}
	</EntriesCard>;
};

export default UserResumeExperienceCard;

// import GridValueCard from "../../../app/card/GridValueCard";
// import UserResumeExperienceCardBody from "./UserResumeExperienceCardBody";
// import {NOT_PROVIDED_YET_LABEL} from "../../../../constants/constant";
// import UserResumeExperienceEntryRow from "./UserResumeExperienceEntryRow";
//
// const UserResumeExperienceCard = (
//     {
//         user,
//         hideIfEmpty = true,
//         notProvided = NOT_PROVIDED_YET_LABEL,
//         children,
//         ...props
//     }) => {
//     const title = 'Experience';
//     const details = null;
//
//     return (user?.hasResumeExperience() || !hideIfEmpty
//             ? <GridValueCard
//                 title={<>{title} <span style={{verticalAlign: 'middle', opacity: .7, fontSize: 14}}>({user?.countResumeExperience()})</span></>}
//                 details={details}
//                 {...props}
//             >
//                 { children || <UserResumeExperienceCardBody user={user} notProvided={notProvided} />}
//             </GridValueCard>
//             : null
//     )
// }
//
// export default UserResumeExperienceCard;
