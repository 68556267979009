import { useState } from 'react';
import {
	Button, Form, Input, Space, Switch,
} from 'antd';
import { useTheme } from '@emotion/react';

import { accessTypeOptions, atsProviderOptions, TAG_ADMIN } from '../../../constants/constant';
import FormItem, { FormItemContainer } from '../../app/form/FormItem';
import FormSelect from '../../app/form/FormSelect';
import { useSelector } from 'react-redux';
import { getCompanies } from '../../../reducers/app';
import FormCompanySelect from '../../app/form/FormCompanySelect';
import Callout from '../../app/callout/Callout';
import { useAuthContext } from '../../../hooks/useAuthContext';
import { LocalDebug } from '../../../utils/LocalDebug';
import FormAtsSelect from '../../app/form/FormAtsSelect';
import CompanyAccessModel from '../../../models/CompanyAccessModel';
import { documentId, requiredRule, requiredTrimRule } from '../../../utils/common';

const CompanyAccessForm = (
	{
		initialValues = {},
		onSubmit,
		onReset,
	},
) => {
	const className = 'CompanyAccessForm';

	const theme = useTheme();

	const { isAdmin, isStaff, isStaffView } = useAuthContext();

	const companies = useSelector(getCompanies);

	const initialModel = new CompanyAccessModel(initialValues || {});
	const [model, setModel] = useState(new CompanyAccessModel(initialValues || {}));

	const [isEditionDisabled, setIsEditionDisabled] = useState(
		documentId(initialModel),
	);
	const [isActive, setIsActive] = useState(model.isActive);
	const [companyId, setCompanyId] = useState(model.companyId);

	const [permissions, setPermissions] = useState(initialValues.permissions || []);

	const [form] = Form.useForm();

	const handleFormValuesChange = (lastChangedValue, values) => {
		LocalDebug.logInfo({ className, method: 'handleFormValuesChange' }, values, initialValues);
		setModel(new CompanyAccessModel({ ...initialValues, ...values }));
	};

	const handleSubmit = (values) => {
		const { value, ...rest } = values;
		onSubmit({
			...rest,
			...(model.isAccessTypeApi && documentId(initialValues)) ? {} : { value },
			permissions,
			isActive,
			// companyId,
			accessId: documentId(initialValues) || null,
		});
	};

	const onPermissionChange = (perm, value) => {
		setPermissions((prev) => {
			const list = (prev || []).filter((item) => item !== perm);
			if (value) list.push(perm);
			return list;
		});
	};

	const labelWidth = 180;
	const labelMarginRight = 14;
	const formRowStyles = {
		label: {
			display: 'inline-block',
			width: labelWidth,
			textAlign: 'right',
			marginRight: labelMarginRight,
			lineHeight: 2.4,
			marginBottom: 0,
		},
		container: {
			marginBottom: '10px!important',
			display: 'inline-block',
			width: `calc(100% - ${labelWidth + labelMarginRight}px)`,
		},
	};

	return (
		<div style={{ opacity: isActive ? 1 : 0.5 }}>
			{/* <Title level={3}>Access Form: </Title>
			 <Divider /> */}
			<Form
				form={form}
				name='alert_form'
				initialValues={{
					...initialValues,
				}}
				onFinish={handleSubmit}
				onValuesChange={handleFormValuesChange}
			>
				<FormItemContainer>

					<FormItem
						label='Company'
						name='companyId'
						defaultContainer='div'
						rules={[requiredRule]}
						styles={formRowStyles}
					>
						<FormCompanySelect
							disabled={isEditionDisabled}
							options={companies.map((c) => ({ value: documentId(c), label: c.name, item: c }))}
							// onChange={(value) => setCompanyId(value)}
							mode='single'
						/>
					</FormItem>

					<FormItem
						label='ATS Provider'
						name='ats'
						defaultContainer='div'
						rules={[requiredRule]}
						styles={formRowStyles}
					>
						<FormAtsSelect
							options={atsProviderOptions}
							disabled={isEditionDisabled}
							// onChange={(value) => setCompanyId(value)}
						/>
					</FormItem>

					<FormItem
						label='Access type'
						defaultContainer='div'
						name='accessType'
						rules={[requiredRule]}
						styles={formRowStyles}
					>
						<FormSelect
							options={accessTypeOptions}
							disabled={isEditionDisabled}
							placeholder='Access type'
						/>
					</FormItem>

					{model.isAccessTypeEnpoint && model.isAtsSuccessFactors
						&& <>
							<FormItem
								label='Apply URL Prefix'
								defaultContainer='div'
								name={['extra', 'applyUrlPrefix']}
								styles={formRowStyles}
							/>
							<FormItem
								label='Default location'
								defaultContainer='div'
								name={['extra', 'defaultLocation']}
								styles={formRowStyles}
							/>
							<FormItem
								label='rcmSiteLocale'
								defaultContainer='div'
								name={['extra', 'rcmSiteLocale']}
								styles={formRowStyles}
								extra='Default value: fr_FR'
							/>
							<FormItem
								label='rcmSiteLocale'
								defaultContainer='div'
								name={['extra', 'selectedLang']}
								styles={formRowStyles}
								extra='Default value: fr_FR'
							/>
						</>
					}

					{model.isAccessTypeApi && model.isAtsLever
						&& <>
							<FormItem
								label='Custom subdomain'
								defaultContainer='div'
								name={['extra', 'subdomain']}
								extra={'The URL that you see in your browser when connected on your Lever instance. If you\'re in doubt leave blank and it will use the classic \'lever.co\' URL'}
								styles={{ ...formRowStyles }}
							>
								<FormSelect
									options={[
										{ value: 'lever.co', label: 'https://hire.lever.co' },
										{ value: 'lever.eu.co', label: 'https://hire.eu.lever.eu.co' },
										...isStaff && isStaffView
											? [{ value: 'sandbox.lever.co', label: 'https://hire.sandbox.lever.co' }]
											: [],
									]}
									placeholder=''
								/>
							</FormItem>
						</>
					}

					{model.isAccessTypeApi
						&& <>
							<div
								style={{ opacity: form?.getFieldValue(['extra', 'isCandidateTagDisabled']) !== true ? 1 : 0.5 }}>

								<FormItem
									label='Source / Tag'
									defaultContainer='div'
									name={['extra', 'candidateTag']}
									extra={'The source or tag added to candidates in your ATS. Default is \'50inTech\''}
									styles={{
										...formRowStyles,
									}}
								/>
							</div>
							<FormItem
								label='Disable source / tag'
								defaultContainer='div'
								name={['extra', 'isCandidateTagDisabled']}
								extra={'If you don\'t want the candidates to be marked as coming from \'50inTech\''}
								styles={formRowStyles}
							>
								<Switch />
							</FormItem>
						</>
					}

					{model.isAtsSmartRecruiters && model.isAccessTypeApi
						&& <>
							<FormItem
								label='Company Identifier'
								defaultContainer='div'
								name={['extra', 'companyIdentifier']}
								extra='Used for Job Board API'
								styles={formRowStyles}
							/>
						</>
					}

					{model.isAtsSmartRecruiters && (model.isAccessTypeApi || model.isAccessTypeEnpoint)
						&& <>
							<FormItem
								label='Brands'
								defaultContainer='div'
								name={['extra', 'brands']}
								extra="To limit results to certain sub brands. Separate labels with ','"
								styles={formRowStyles}
							/>
						</>
					}

					{model.isFormBoardNameVisible
						&& <FormItem
							label='Board name'
							defaultContainer='div'
							name={['extra', 'board']}
							rules={[requiredTrimRule]}
							styles={formRowStyles}
						/>
					}
					{model.isFormOnBehalfOfVisible
						&& <FormItem
							label="'On-Behalf-Of'"
							defaultContainer='div'
							name={['extra', 'onBehalfOfUserId']}
							extra='GreenHouse User ID'
							rules={[requiredTrimRule]}
							styles={formRowStyles}
						/>
					}
					{model.isFormCustomApiSubdomainVisible
						&& <FormItem
							label='Custom API subdomain'
							defaultContainer='div'
							name={['extra', 'customApiSubdomain']}
							{...model.isAtsWorkable && {
								label: 'Custom subdomain',
								rules: [requiredTrimRule],
								extra: 'Used for API and ATS',
							}}
							{...model.isAtsSmartRecruiters && { extra: '?.smartrecruiters.com - Default \'api\'' }}
							styles={formRowStyles}
						/>
					}
					{model.isFormCustomAtsSubdomainVisible
						&& <FormItem
							label='Custom ATS subdomain'
							defaultContainer='div'
							name={['extra', 'customAtsSubdomain']}
							{...model.isAtsSmartRecruiters && { extra: '?.smartrecruiters.com - Default \'api\'' }}
							{...model.isAtsGreenhouse && { extra: '?.greenhouse.io - Default \'app5\'' }}
							styles={formRowStyles}
						/>
					}

					{/* isEditionDisabled: {isEditionDisabled ? 'true': 'false'}<br/> */}
					{/* initialModel.isAccessTypeApi: {initialModel.isAccessTypeApi ? 'true': 'false'}<br/> */}
					<FormItem
						label={model.isAccessTypeApi ? 'API Key' : 'Value'}
						defaultContainer='div'
						name='value'
						rules={[requiredTrimRule]}
						styles={formRowStyles}
						extra={isEditionDisabled && initialModel.isAccessTypeApi && <Callout style={{
							fontSize: 12,
							fontWeight: 'bold',
							marginTop: 4,
							padding: '4px 8px 3px',
							borderRadius: 5,
							marginBottom: 0,
						}} withFeedback={false}><i>API Key not editable for security reasons</i></Callout>}
					>
						<Input disabled={isEditionDisabled && initialModel.isAccessTypeApi} />
					</FormItem>

					<FormItem
						label='Permissions'
						defaultContainer='div'
						name='permissions'
						styles={formRowStyles}
					>
						<Space direction='vertical'>
							<Space direction='horizontal'>
								<Switch
									checked={permissions.includes('atsSyncable')}
									onChange={(value) => { onPermissionChange('atsSyncable', value); }}
								/>
								<span style={{ fontSize: 12, lineHeight: 1.3 }}>
									ATS Synchronization
								</span>
							</Space>

							<Space direction='horizontal'>
								<Switch
									checked={permissions.includes('fetchJobs')}
									onChange={(value) => { onPermissionChange('fetchJobs', value); }}
								/>
								<span style={{ fontSize: 12, lineHeight: 1.3 }}>
									Jobs fetching
								</span>
							</Space>
						</Space>

					</FormItem>

					<FormItem
						label='Active'
						defaultContainer='div'
						name='isActive'
						value={isActive}
						styles={formRowStyles}
					>
						<Switch
							checked={isActive}
							onChange={setIsActive}
						/>
					</FormItem>

				</FormItemContainer>
				<div style={{
					display: 'flex',
					flexDirection: 'row-reverse',
					margin: -5,
				}}>
					<Button type='primary'
						style={{
							margin: 5,
							background: theme.color.blue,
							borderColor: theme.color.blue,
							fontWeight: 'bold',
							borderRadius: 5,
						}}
						htmlType='submit'
					>
						{documentId(initialValues) ? 'Edit' : 'Add'}
					</Button>
					<Button
						type='default'
						style={{
							margin: 5,
							borderColor: theme.color.orange,
							fontWeight: 'bold',
							borderRadius: 5,
							color: theme.color.orange,
						}}
						htmlType='reset'
						onClick={onReset}
					>
						Cancel
					</Button>
				</div>
			</Form>
		</div>
	);
};

export default CompanyAccessForm;
