/** @jsxImportSource @emotion/react */
import { useEffect, useState } from 'react';

import ArticleForm from './ArticleForm';
import FooterAction from './components/FooterAction';
import LearnDetailContainer from './components/preview/LearnDetail';
import Modal from '../../../app/modal/Modal';
import { ENGLISH_LANG } from '../../../../constants/constant';
import ArticleModel from '../../../../models/ArticleModel';
import { LocalDebug } from '../../../../utils/LocalDebug';

const ArticleFormModal = (
	{
		open,
		initialValues,
		onPublish,
		onPreview,
		isPreview,
		setIsPreview,
		onClose,
		title = 'Add Meta',
		setValues,
	},
) => {
	const className = 'ArticleFormModal';

	const [lang, setLang] = useState(ENGLISH_LANG);

	const onChange = (name, value) => {
		LocalDebug.logInfo({ className, method: 'onChange' }, { name, value });
		setValues(new ArticleModel({ ...initialValues, [name]: value }));
	};

	return (
		<Modal
			centered
			open={open}
			onCancel={onClose}
			width={1000}
			title={title}
			footer={null}
			css={{
				'.ant-modal-body': { padding: 0 },
			}}
		>
			<div css={{ overflow: 'hidden', margin: 'auto' }}>
				{isPreview ? (
					<div>
						<LearnDetailContainer
							article={initialValues}
							lang={lang}
							onChange={onChange}

						/>
						<FooterAction
							isPreview={isPreview}
							setIsPreview={setIsPreview}
							onPublish={onPublish}
							lang={lang}
							setLang={setLang}
							isNotPublished={initialValues?.isNotPublished?.()}
						/>
					</div>
				) : (
					<div css={{ maxWidth: 680, overflow: 'hidden', margin: 'auto' }}>
						<ArticleForm
							onSubmit={onPreview}
							article={initialValues}
							lang={lang}
							setLang={setLang}
							isPreview={isPreview}
							onChange={onChange}
						/>
					</div>
				)}
			</div>
		</Modal>
	);
};

export default ArticleFormModal;
