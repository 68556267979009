import {
	Divider, Form, message, Tag,
} from 'antd';
import { useEffect, useReducer, useState } from 'react';
import { useDispatch } from 'react-redux';
import { LocalDebug } from '../../../utils/LocalDebug';
import { documentId, writeLog } from '../../../utils/common';
import FormItem from '../form/FormItem';
import { FaCheck, FaStop } from 'react-icons/fa';
import FormBoxTitle from '../../app/form/FormBoxTitle';
import FormSubmitButton from '../../app/form/FormSubmitButton';
import { saveCompany } from '../../../actions/company';
import { refreshCurrentUser } from '../../../actions/app';
import BlockTester from '../../app/blocker/BlockTester';
import SettingsBrowsingUserStubPreview from '../../account/SettingsBrowsingUserStubPreview';
import Switch from '../../app/form/Switch';
import { useAuthContext } from '../../../hooks/useAuthContext';
import GenderhireCandidatesAtsMenu from '../../../menu/genderhire/GenderhireCandidatesAtsMenu';
import GenderhireCandidatesAppliedMenu from '../../../menu/genderhire/GenderhireCandidatesAppliedMenu';

const SettingOverridableTag = (
	{
		value,
		overridable,
		settingLabel = 'setting',
	},
) => {
	const tagColor = value ? 'volcano' : 'green';
	const TagIcon = value ? FaStop : FaCheck;
	const valueText = <>Your recruiters <b>can{value ? 'not' : ''} see </b> </>;
	const overrideText = overridable ? ', unless they override the setting' : '';

	return <Tag color={tagColor}>
		<TagIcon style={{ marginBottom: -2 }}/>
		&nbsp;{valueText}<b>{settingLabel}</b>{overrideText}
	</Tag>;
};

const AnonTalentAvatarsTag = ({ company }) => {
	return (
		<SettingOverridableTag
			value={company.shouldAnonTalentAvatars}
			overridable={company.canRecruiterOverrideAnonTalentAvatars}
			settingLabel='talent avatars'
		/>
	);
};

const AnonTalentNamesTag = ({ company }) => {
	return (
		<SettingOverridableTag
			value={company.shouldAnonTalentNames}
			overridable={company.canRecruiterOverrideAnonTalentNames}
			settingLabel='talent names'
		/>
	);
};

const CompanySettingsBrowsingForm = (
	{
		company,
		onSuccess,
		labelCol = { span: 0 },
		wrapperCol = { span: 24 },
	},
) => {
	const className = 'CompanySettingsBrowsingForm';
	const dispatch = useDispatch();
	const { isAtsSyncable } = useAuthContext();

	const prepareCompany = (c) => ({
		shouldAnonTalentAvatars: c?.shouldAnonTalentAvatars !== false,
		canRecruiterOverrideAnonTalentAvatars: c?.canRecruiterOverrideAnonTalentAvatars !== false,
		shouldAnonTalentNames: c?.shouldAnonTalentNames === true,
		canRecruiterOverrideAnonTalentNames: c?.canRecruiterOverrideAnonTalentNames !== false,
	});

	const [initialValues, setInitialValues] = useState(prepareCompany(company));
	const [companyEdited, setCompanyEdited] = useState(prepareCompany(company));
	const [isFormChanged, setIsFormChanged] = useState(false);
	const [form] = Form.useForm();

	const hasFormErrors = () => form.getFieldsError().some(({ errors }) => errors.length);

	const [state, stateDispatch] = useReducer((state, action) => {
		switch (action) {
			case 'forceDisabled':
				return { disabled: true };
			case 'updateDisabled':
				return { disabled: hasFormErrors() };
		}
	}, { disabled: true });

	const onFormValuesChange = (values) => {
		setCompanyEdited({ ...companyEdited, ...values });
		setIsFormChanged(true);
		stateDispatch('updateDisabled');
	};

	useEffect(() => {
		LocalDebug.logUseEffect({ className, effects: 'form, company' }, { company });
		form.resetFields();
		setIsFormChanged(false);
		const c = prepareCompany(company);
		form.setFieldsValue({ ...c });
		setCompanyEdited({ ...c });
	}, [company]);

	const onFormFinish = async (values) => {
		LocalDebug.logInfo({ className, method: 'onFormFinish' }, { values });

		stateDispatch('forceDisabled');

		const data = {
			id: documentId(company),
			forceRecompute: false,
			...values,
		};

		message.loading({ key: className, content: 'Saving company browsing settings...' });

		await dispatch(saveCompany(data));

		refreshCurrentUser()(dispatch);

		message.success({ key: className, content: 'Company browsing settings saved!' });

		writeLog('Company browsing settings saved', { data: values });

		onSuccess?.();
	};

	const onFormFinishFailed = ({ values, errorFields, outOfDate }) => {
		message.error(<div>
			Error: {(errorFields?.map((e) => e.errors) || []).slice(0, 1).join(',')}
		</div>);
	};

	return (
		<BlockTester
			withBorder={false}
			tagStyle={{
				top: 5,
				left: 0,
				opacity: 1,
			}}
		>
			<FormBoxTitle
				title={<>👀 Unbiased talent browsing</>}
				details={<>
					<p>
							Reduce inconscious bias by navigating 50inTech's talent pool without showing the photos or
							names of candidates.
					</p>
					<p>
						Note that these settings don't apply to
						the {isAtsSyncable
							? <>
							"Applications" of the {(new GenderhireCandidatesAtsMenu()).getInlineNavLink()}
							</>
							: (new GenderhireCandidatesAppliedMenu()).getInlineNavLink()
						} section.
					</p>
				</>}
			/>

			<Form
				form={form}
				name="company-browsing-form"
				initialValues={initialValues}
				labelCol={labelCol}
				wrapperCol={wrapperCol}
				labelAlign='right'
				layout='vertical'
				onFinish={onFormFinish}
				onFinishFailed={onFormFinishFailed}
				onValuesChange={onFormValuesChange}
			>

				<FormItem
					name="shouldAnonTalentAvatars"
					valuePropName="checked"
				>
					<Switch
						style={{ width: 90 }}
						className='ant-switch-large'
						before={<>I want to</>}
						after={<><b>talent avatars</b> to my recruiters</>}
						checkedChildren={<b>hide</b>}
						unCheckedChildren={<b>show</b>}
						// checkedChildren={<b>&nbsp;Talent avatars hidden</b>}
						// unCheckedChildren={<b>Talent avatars visible&nbsp;</b>}
					/>
				</FormItem>

				<FormItem
					name="canRecruiterOverrideAnonTalentAvatars"
					valuePropName="checked"
					// help={<>Whether recruiters can select their own setting for talent avatars
					// 	anonymization</>}
				>
					<Switch
						style={{ width: 100 }}
						className='ant-switch-large'
						before={<>Recruiters</>}
						after={<>modify the <b>talent avatars</b> setting from their account</>}
						checkedChildren={<b>can</b>}
						unCheckedChildren={<b>cannot</b>}
						// checkedChildren={<b>&nbsp;Recruiters can decide to see talent avatars or not</b>}
						// unCheckedChildren={
						// 	<b>No override (talent
						// 		avatars {companyEdited?.shouldAnonTalentAvatars ? 'hidden' : 'visible'})&nbsp;</b>}
					/>
				</FormItem>

				<Divider/>

				<FormItem
					name="shouldAnonTalentNames"
					valuePropName="checked"
				>
					<Switch
						style={{ width: 90 }}
						className='ant-switch-large'
						before={<>I want to</>}
						after={<><b>talent names</b> to my recruiters</>}
						checkedChildren={<b>hide</b>}
						unCheckedChildren={<b>show</b>}
					/>
				</FormItem>

				<FormItem
					name="canRecruiterOverrideAnonTalentNames"
					valuePropName="checked"
				>
					<Switch
						style={{ width: 100 }}
						className='ant-switch-large'
						before={<>Recruiters</>}
						after={<>modify the <b>talent names</b> setting from their account</>}
						checkedChildren={<b>can</b>}
						unCheckedChildren={<b>cannot</b>}
					/>
				</FormItem>

				<SettingsBrowsingUserStubPreview
					showAvatar={!form.getFieldValue('shouldAnonTalentAvatars')}
					showName={!form.getFieldValue('shouldAnonTalentNames')}
				/>

				<FormItem>
					<FormSubmitButton disabled={state?.disabled}>
							Save browsing settings
					</FormSubmitButton>
				</FormItem>

			</Form>
		</BlockTester>
	);
};

export default CompanySettingsBrowsingForm;
