import { Modal, Popover, Space } from 'antd';
import { deleteResource, updateOrCreateResource } from '../../../../actions/resource';
import { useEffect, useState } from 'react';
import ActionButton from '../../../app/table/ActionButton';
import Table from '../../../app/table/Table';
import TableColumns from '../../../app/table/TableColumns';
import { arrayWrap, documentId } from '../../../../utils/common';
import { useDispatch } from 'react-redux';
import { useTheme } from '@emotion/react';
import GenderScoreResourceCard from '../../../company/genderscore/resources/GenderScoreResourceCard';
import GenderScoreResourceImage from '../../../company/genderscore/resources/GenderScoreResourceImage';
import Link from '../../../app/Link';
import GenderScorePillarLogoLabel from '../../../company/genderscore/pillars/GenderScorePillarLogoLabel';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';

const ResourceTable = (
	{
		onDataLoaded,
		path = '/genderscore/resource',
		filterOptions = { sortField: 'publishedAt' },
		isRefresh = 0,
		onSearchDataColumn,
		onToggleIsActive,
		onEditResource,
		isEditor,
		...props
	},
) => {
	const theme = useTheme();
	const dispatch = useDispatch();

	const [isRefreshDataLayout, setIsRefreshDataLayout] = useState(0);

	const onToggleIs50Content = async (data) => {
		await dispatch(updateOrCreateResource(data));
		setIsRefreshDataLayout((prev) => prev + 1);
	};

	const toggleIs50Content = (row) => {
		const textDisable = row.is50Content ? 'hide' : 'show';
		Modal.confirm({
			title: `Are you sure you want to ${textDisable} this resource in 'Learn' section?`,
			okText: 'Yes',
			cancelText: 'No',
			onOk() {
				return onToggleIs50Content({
					id: documentId(row),
					is50Content: !row.is50Content,
				});
			},
		});
	};

	const toggleHasReplay = async (row) => {
		await dispatch(
			updateOrCreateResource({ id: documentId(row), hasReplay: !row.hasReplay }),
		);
		setIsRefreshDataLayout((prev) => prev + 1);
	};

	const onDeleteResource = async (values) => {
		await dispatch(deleteResource(values));
		setIsRefreshDataLayout((prev) => prev + 1);
	};

	const columns = [
		TableColumns.jsonColumn(theme),
		{
			title: 'Image',
			dataIndex: 'image',
			render: (value, row) => (
				<GenderScoreResourceImage
					resource={row}
					width={480}
					height={270}
					style={{
						width: 240 * 0.6,
						height: 140 * 0.6,
						borderRadius: 4,
					}}
				/>
			),
			...TableColumns.columnFixedWidth(240 * 0.6 + 16),
		},
		{
			title: 'Title',
			dataIndex: 'title',
			render: (value, row) => (
				<Space direction='vertical' size={0}>
					<Popover
						placement='left'
						content={(
							<div style={{ width: 360 }}>
								<GenderScoreResourceCard
									resource={row}
									withPillarTags={true}
									width={360}
								/>
							</div>
						)}
					>
						<Link>
							{value}
						</Link>
					</Popover>
					<code className='tiny'>
						{row?.key}
					</code>
				</Space>
			),
			...TableColumns.columnMinWidth(300),
		},
		{
			title: 'Source',
			dataIndex: 'source',
			removeInColumnFilter: true,
			render: (value, row) => (
				<div
					style={{
						fontSize: 11,
						lineHeight: 1.2,
						color: theme.color.darkgrey,
					}}
				>
					{value}
				</div>
			),
			...TableColumns.columnMinWidth(120),
		},
		{
			title: 'Type',
			dataIndex: 'type',
			align: 'center',
			removeInColumnFilter: true,
			render: (value, row) => (
				<div
					style={{
						fontSize: 11,
						lineHeight: 1.2,
						color: theme.color.darkgrey,
					}}
				>
					{value}
				</div>
			),
			...TableColumns.columnMinWidth(120),
		},
		{
			title: 'Pillars',
			dataIndex: 'pillars',
			align: 'center',
			removeInColumnFilter: true,
			render: (value, row) => (
				<Space size={2}>
					{arrayWrap(value)
						.map((pillar, index) => (
							<div key={index}>
								<GenderScorePillarLogoLabel
									pillar={pillar}
									withLabel={false}
									size={24}
								/>
							</div>
						))}
				</Space>
			),
			...TableColumns.columnMinWidth(120),
		},
		TableColumns.iconLinkColumn(theme, {
			dataIndex: 'url',
		}),
		TableColumns.userAvatarColumn(theme, { title: 'Author' }),
		TableColumns.dateColumn(theme, {
			title: 'Published at',
			dataIndex: 'publishedAt',
		}),
		TableColumns.toggleColumn(theme, toggleIs50Content, {
			title: '50inTech',
			dataIndex: 'is50Content',
			...TableColumns.columnMinWidth(100),
		}),
		{
			title: 'actions',
			dataIndex: 'id',
			key: 'id',
			render: (value, row) => {
				return (
					<Space direction='horizontal' size={4}>
						<ActionButton
							tooltipTitle='Edit resource'
							onClick={() => onEditResource(row)}
							icon={<EditOutlined />}
						/>
						<ActionButton
							tooltipTitle='Delete resource'
							icon={<DeleteOutlined />}
							styles={{ color: theme.color.accent }}
							title='Are you sure you want to delete this resource?'
							okText='Yes'
							cancelText='No'
							onConfirm={() => onDeleteResource(value)}
							withConfirm
						/>
					</Space>
				);
			},
		},
	];

	useEffect(() => {
		setIsRefreshDataLayout((prev) => prev + 1);
	}, [isRefresh]);

	return (
		<>
			<Table
				path={path}
				// onDataLoaded={onDataLoaded}
				columns={columns}
				filterOptions={filterOptions}
				isRefresh={isRefreshDataLayout}
				onSearchDataColumn={onSearchDataColumn}
				showTotal={(total) => `Total ${total} items`}
				limit={10}
				rowClassName={(row, index) => (row.is50Content === false ? 'rowDisabled' : '')
				}
				withDynamicSearch
				{...props}
			/>
		</>
	);
};

export default ResourceTable;
