import {
	useContext, useMemo, useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useAuthContext } from '../../hooks/useAuthContext';
import { getCompanies } from '../../reducers/app';
import Callout from '../app/callout/Callout';
import {
	Button, Col, Input, Row, Space,
} from 'antd';
import CompanyCard from './CompanyCard';
import { sortOn } from '../../utils/common';
import { useTheme } from '@emotion/react';
import CompanyAddButton from './addCompany/CompanyAddButton';
import { CompanyContext } from '../../contexts/CompanyProvider';
import { GENDERSCORE_LABEL } from '../../constants/constant';
import CompanyStub from './CompanyStub';
import CompanyPlanTag from './CompanyPlanTag';
import Box from '../app/box/Box';
import PageLayout from '../app/layout/PageLayout';

const CompanyGridPicker = ({
	withPageLayout = true,
}) => {
	const theme = useTheme();
	const dispatch = useDispatch();
	const companies = useSelector(getCompanies);

	const { isStaff, isStaffView } = useAuthContext();
	const { setCompanySelectedAsQueryParam } = useContext(CompanyContext);

	const [pageContent, setPageContent] = useState(null);
	const [search, setSearch] = useState('');
	const [sortField, setSortField] = useState('createdAt');
	const [sortOrder, setSortOrder] = useState(true);

	const handleSearch = (event) => {
		setSearch(event?.target?.value);
	};

	useMemo(() => {
		if (!isStaff || !isStaffView) {
			setPageContent(
				<Callout
					style={{ width: '100%' }}
					withFeedback={false}>
					No company selected
				</Callout>,
			);
		} else {
			setPageContent(
				<>
					<Space direction='vertical' style={{ width: '100%' }}>
						<Space wrap={false} align='top' style={{ width: '100%', justifyContent: 'space-between' }}>
							<Callout
								style={{ width: '100%' }}
								withFeedback={false}>
								Please select a company, or create a new one
							</Callout>
							<CompanyAddButton style={{ margin: 0 }} />
						</Space>
						<Space direction='horizontal' size={20}>
							<Space direction='horizontal'>
								<span><b>Search:</b></span>
								<Input onChange={handleSearch} style={{ maxWidth: 200 }} />
							</Space>
							<Space direction='horizontal'>
								<span><b>Sort:</b></span>
								<Button
									disabled={sortField === 'name' && sortOrder === false}
									onClick={() => {
										setSortField('name');
										setSortOrder(false);
									}}
								>
									by name
								</Button>
								<Button
									disabled={sortField === 'createdAt' && sortOrder === true}
									onClick={() => {
										setSortField('createdAt');
										setSortOrder(true);
									}}
								>
									by creation date
								</Button>
								<Button
									disabled={sortField === 'genderScoreGlobalScore' && sortOrder === true}
									onClick={() => {
										setSortField('genderScoreGlobalScore');
										setSortOrder(false);
									}}
								>
									by {GENDERSCORE_LABEL}
								</Button>
							</Space>
						</Space>
						<Row style={{ background: theme.color.bgGrey }}>
							{companies
								.filter((c) => !search || c.name.toLowerCase().indexOf(search.toLowerCase()) >= 0)
								.map((c) => ({ ...c, genderScoreGlobalScore: c?.genderScore?.globalScore }))
								.sort(sortOn({ key: sortField, string: 'true' }, sortOrder))
								.map((company, index) => {
									return (
										<Col key={index} span={6} style={{ padding: 5 }}>
											<div
												onClick={() => setCompanySelectedAsQueryParam(company.path)}
											>
												<Box
													style={{
														background: 'white',
														padding: '15px 20px',
													}}
												>
													<CompanyStub
														size={36}
														company={company}
														withPublicationDot={true}
														withJobsPublicationDot={true}
														withAtsAccess={true}
														withTooltip={true}
														withPublicationOpacity={true}
														withGenderScoreDot={true}
													>
														<CompanyPlanTag
															company={company}
															withShortFormat={true}
															withExtraTags={true}
															style={{
																margin: 0,
															}}
														/>
													</CompanyStub>
												</Box>
											</div>
										</Col>
									);
								})}
						</Row>
					</Space>
				</>,
			);
		}
	}, [companies, isStaff, isStaffView, sortField, sortOrder, search]);

	if (withPageLayout) {
		return (
			<PageLayout
				withHeader={false}
			>
				{pageContent}
			</PageLayout>
		);
	}

	return pageContent;
};

export default CompanyGridPicker;
