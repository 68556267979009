import { options } from '../../../constants/currency';
import { Select } from 'antd';

const CurrencySelect = (
	{
		value,
		onChange,
		...props
	},
) => {
	const className = 'CurrencySelect';
	return (
		<Select
			showSearch
			options={options}
			value={value}
			onChange={onChange}
			placeholder={props?.placeholder}
		/>
	);
};

export default CurrencySelect;
