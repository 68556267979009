import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { useTheme } from '@emotion/react';
import { useSelector } from 'react-redux';
import { getCompanySelected } from '../../../../reducers/app';
import { Button, Space, Statistic } from 'antd';
import { GENDERSCORE_LABEL, PATHS } from '../../../../constants/constant';
import CompanyModel from '../../../../models/CompanyModel';
import { LocalDebug } from '../../../../utils/LocalDebug';
import { useNavigate } from 'react-router';

const GenderScoreCountdownValueBlock = ({
	label,
	value,
	format,
	...props
}) => {
	const theme = useTheme();

	return (
		<div
			style={{
				width: 60,
				borderRadius: 10,
				background: 'rgba(255, 255, 255, .8)',
				display: 'flex',
				flexDirection: 'column',
				justifyContent: 'center',
				alignItems: 'center',
				padding: '0 6px 2px',
				userSelect: 'none',
				...props?.style,
			}}
		>
			<Statistic
				value={value}
				valueStyle={{
					padding: 0,
					...props?.style?.value,
				}}/>
			<span
				style={{
					fontSize: 11,
					marginTop: -8,
					...props?.style?.label,
				}}
			>
				{value > 1 ? label[1] : label[0]}
			</span>
		</div>
	);
};

const GenderScoreCountdownBanner = ({
	company,
	withNavigateToSurvey = true,
	isExpirationOfCurrentBadge = true,
}) => {
	const className = 'GenderScoreCountdownBanner';

	const theme = useTheme();
	const navigate = useNavigate();

	const companySelected = useSelector(getCompanySelected);

	const [companyModel, setCompanyModel] = useState(null);
	const [cta, setCta] = useState(null);
	const [refresh, setRefresh] = useState(0);
	const [days, setDays] = useState(0);
	const [hours, setHours] = useState(0);
	const [minutes, setMinutes] = useState(0);
	const [seconds, setSeconds] = useState(0);

	useEffect(() => {
		setCompanyModel(companySelected ? new CompanyModel(companySelected) : null);
	}, [companySelected]);

	useEffect(() => {
		if (!companyModel) return;

		const deadline = dayjs(
			companyModel.getGenderScoreExpirationDate({ isExpirationOfCurrentBadge }) || new Date(),
		);

		const d = Date.now();
		const dj = dayjs(d);
		if (d > deadline) {
			setDays(0);
			setHours(0);
			setMinutes(0);
			setSeconds(0);
		} else {
			const tDays = Math.floor(
				(
					deadline.unix()
					- dj.unix()
				) / (60 * 60 * 24),
			);
			setDays(tDays);
			const tHours = Math.floor(
				(
					deadline.unix()
					- dj.unix()
					- tDays * (60 * 60 * 24)
				) / (60 * 60),
			);
			setHours(tHours);
			const tMinutes = Math.floor(
				(
					deadline.unix()
					- dj.unix()
					- tDays * (60 * 60 * 24)
					- tHours * (60 * 60)
				) / 60,
			);
			setMinutes(tMinutes);
			const tSeconds = (
				deadline.unix()
				- dj.unix()
				- tDays * (60 * 60 * 24)
				- tHours * (60 * 60)
				- tMinutes * 60
			);
			setSeconds(tSeconds);
		}
	}, [refresh, companyModel]);

	useEffect(() => {
		const id = setInterval(() => {
			LocalDebug.logNull({ className, effects: 'setInterval' });
			setRefresh((p) => p + 1);
		}, 1000);
		return () => {
			LocalDebug.logNull({ className, effects: 'setInterval cleanup' }, { id });
			clearInterval(id);
		};
	}, []);

	useEffect(() => {
		if (withNavigateToSurvey) {
			setCta(
				<Space style={{ marginLeft: 10 }}>
					<span>Are you ready for the next level?</span>
					<Button
						ghost
						onClick={() => navigate(PATHS.GENDERSCORE_SURVEY())}
					>Open Survey</Button>
				</Space>,
			);
		}
	}, [companyModel]);

	const mainColor = '#c1a5e4';

	const countdownBlockStyle = {
		value: { color: mainColor },
		label: { color: mainColor },
	};

	return (
		<div
			id='genderscore-countdown-banner'
			style={{
				width: '100%',
				height: 100,
				background: mainColor,
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
			}}
		>
			<Space
				style={{
					fontWeight: 'bold',
					fontSize: 16,
					color: 'white',
				}}
			>
				<span>Your {isExpirationOfCurrentBadge ? GENDERSCORE_LABEL : 'plan'} will expire in</span>
				<GenderScoreCountdownValueBlock value={days} label={['day', 'days']} style={countdownBlockStyle} />
				<GenderScoreCountdownValueBlock value={hours} label={['hour', 'hours']} style={countdownBlockStyle} />
				<GenderScoreCountdownValueBlock value={minutes} label={['minute', 'minutes']} style={countdownBlockStyle} />
				<GenderScoreCountdownValueBlock value={seconds} label={['second', 'seconds']} style={countdownBlockStyle} />
				{cta}
			</Space>
		</div>
	);
};

export default GenderScoreCountdownBanner;
