import { useContext } from 'react';
import { useTheme } from '@emotion/react';
import { Button, Space } from 'antd';
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa';
import { ClockCircleOutlined } from '@ant-design/icons';
import { BiChevronsRight, BiExit } from 'react-icons/bi';
import { AppDataContext } from '../../../../contexts/AppDataProvider';
import Link from '../../../app/Link';
import { SurveyContext } from '../providers/SurveyProvider';
import {
	EVENT, buildEventLabel, buildEventSection, pushClick,
} from '../../../../contexts/EventPushProvider';

const SurveyQuestionFooter = ({
	disablePrev = false,
	disableSave = false,
	nextText,
	withPrev = true,
	withAnswerLater = false,
	withSkip = false,
	onPrev,
	onSave,
	onSkip,
	onAnswerLater,
	htmlType,
}) => {
	const className = 'SurveyQuestionFooter';
	const theme = useTheme();

	const { appSiderWidth, isAppSiderVisible } = useContext(AppDataContext);

	const {
		eventContext,
		setOpenLeaveModal,
		t,
	} = useContext(SurveyContext);

	const eventProps = {
		...eventContext,
		...buildEventSection(EVENT.SECTION.SURVEY_FOOTER),
	};

	return (
		<>
			<div
				style={{
					padding: '20px 24px 16px',
					// background: 'rgba(250,250, 255,.8)',
					position: 'fixed',
					left: isAppSiderVisible ? appSiderWidth : 0,
					bottom: 0,
					display: 'flex',
					justifyContent: 'space-between',
					width: `calc(100% - ${isAppSiderVisible ? appSiderWidth : 0}px)`,
					// opacity: 0.5,
				}}>

				<Space
					direction='vertical'
					style={{
						justifyContent: withPrev ? 'flex-start' : 'center',
						alignItems: withPrev ? 'flex-start' : 'center',
					}}
				>
					{withPrev && <Button
						onClick={onPrev}
						disabled={disablePrev}
						size="large"
						style={{
							paddingLeft: 24,
							paddingRight: 24,
							// padding: 24,
							height: 56,
							fontSize: 18,
							fontWeight: 600,
						}}
					>
						<FaArrowLeft style={{
							marginRight: 6,
							marginBottom: -1,
							fontSize: 14,
						}}/> {t('survey-footer.previous-btn')}
					</Button>
					}
					<Link
						className='leave-survey'
						onClick={() => {
							pushClick({
								...eventProps,
								...buildEventLabel(EVENT.LABEL.SURVEY_LEAVE_MODAL),
							});
							setOpenLeaveModal(true);
						}}
					>
						<BiExit
							style={{
								transform: 'rotate(-180deg)',
								fontSize: 16,
								marginBottom: -3,
								marginRight: 4,
							}}
						/>
						{t('leave-survey-btn')}
					</Link>

				</Space>

				<Space align='top'>

					<Space
						direction='vertical'
						style={{
							justifyContent: 'flex-end',
							alignItems: 'flex-end',
						}}
					>
						<Button
							className='submit'
							type="primary"
							size="large"
							onClick={onSave}
							disabled={disableSave}
							{...htmlType ? { htmlType } : {}}
							style={{
								paddingLeft: 24,
								paddingRight: 24,
								// padding: 24,
								height: 56,
								fontSize: 18,
								fontWeight: 600,
							}}
						>
							{nextText || t('survey-footer.save-btn')}
							<FaArrowRight
								style={{
									marginLeft: 6,
									marginBottom: -1,
									fontSize: 14,
								}}
							/>
						</Button>
						{withAnswerLater && (
							<Link
								className={'answer-later'}
								onClick={onAnswerLater}
							>
								<ClockCircleOutlined
									style={{
										marginBottom: -2,
									}}
								/>
								&nbsp;{t('survey-footer.answer-later-btn')}
								<BiChevronsRight
									style={{
										fontSize: 22,
										marginBottom: -6,
									}}
								/>
							</Link>
						)}
					</Space>
				</Space>
			</div>
		</>
	);
};

export default SurveyQuestionFooter;
