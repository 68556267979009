import { useTheme } from '@emotion/react';
import { Space } from 'antd';
import { useContext, useState } from 'react';
import { useDispatch } from 'react-redux';
import JobService from '../../services/jobs';
import { fetchJob, updateJobTags } from '../../actions/jobs';
import TableColumns from '../app/table/TableColumns';
import Table from '../app/table/Table';
import ActionButton from '../app/table/ActionButton';
import { RiEdit2Fill, RiRobotLine } from 'react-icons/ri';
import { AuthContext } from '../../contexts/AuthProvider';
import JobDrawerDetails from './JobDrawerDetails';
import { SOURCINGS_TAB_LABEL } from '../../constants/constant';
import { documentId, filterAutoCompleteItems, writeLog } from '../../utils/common';
import { localDebug } from '../../utils/LocalDebug';
import JobEditManagersButton from './managers/JobEditManagersButton';
import JobModel from '../../models/JobModel';
import JobNotificationsToggle from './managers/JobNotificationsToggle';
import JobNotificationsRecipients from './managers/JobNotificationsRecipients';
import JobNotificationsStatus from './managers/JobNotificationsStatus';
import { GenderhireCandidatesSourcedIcon } from '../../menu/genderhire/GenderhireCandidatesSourcedMenu';
import { CloudDownloadOutlined, EyeOutlined, TagsOutlined } from '@ant-design/icons';
import { GenderhireCandidatesAppliedIcon } from '../../menu/genderhire/GenderhireCandidatesAppliedMenu';

const JobsTable = (
	{
		onDataLoaded,
		path = '/jobs',
		filterOptions = {},
		isRefresh = 0,
		onSelectJob,
		onUpdateJob,
		onEditJob,
		onEditAIJob,
		isEditor,
		columns,
		...props
	},
) => {
	const theme = useTheme();
	const dispatch = useDispatch();
	// selector
	const { isStaffView, isAtsSyncable } = useContext(AuthContext);
	const [isRefreshDataLayout, setIsRefreshDataLayout] = useState(0);

	const [jobSelected, setJobSelected] = useState(null);
	const [openJobDetails, setOpenJobDetails] = useState(false);
	const [JobActiveTab, setJobActiveTab] = useState(null);

	const handleRefreshTable = () => {
		setIsRefreshDataLayout((prev) => prev + 1);
	};

	const handleUpdateTags = async (job) => {
		await dispatch(updateJobTags(job));
		handleRefreshTable();
	};

	const handleFetchJob = async (data) => {
		await dispatch(fetchJob(data));
		handleRefreshTable();
	};

	// ------------------------------------------- //
	// -------- search autocomplete data --------- //
	// ------------------------------------------- //
	const handleSearchAutoComplete = async (options) => {
		const { data: { jobs } } = await JobService.getAll(options, '/groupBy');
		return filterAutoCompleteItems(jobs);
	};

	const togglePublishedJob = async (row) => {
		await onUpdateJob({ id: documentId(row), isPublished: !row.isPublished, companyId: row.companyId }, row);
		// const textDisable = row.isPublished ? 'unpublish' : 'publish'
		// Modal.confirm ({
		//     title: `Are you sure you want to ${textDisable} this job?`,
		//     okText: 'Yes',
		//     cancelText: 'No',
		//     onOk() {
		//         onUpdateJob({ id: documentId(row), isPublished: !row.isPublished});
		//     }
		// });
	};

	const handleCloseDrawerJobDetails = () => {
		setOpenJobDetails(false);
		setJobSelected(null);
	};

	const handleOpenJobDetails = (job, tab) => {
		setJobSelected(job);
		setOpenJobDetails(true);
		setJobActiveTab(tab);
		// add log
		writeLog(`Open drawer job - tab: ${tab}`, { jobId: documentId(job) });
	};

	const jobStubCol = TableColumns.jobStubColumn(theme, { fixed: 'left', onSelectJob, onEditJob });
	const jobJsonCol = TableColumns.jsonColumn(theme);
	const jobPageLinkCol = TableColumns.jobPageLinkColumn(theme);
	const jobExternalUriCol = TableColumns.jobExternalUriColumn(theme);
	const jobExternalApplyUriCol = TableColumns.jobExternalApplyUriColumn(theme);
	const jobExternalLinkCol = TableColumns.jobExternalLinkColumn(theme);
	const companyLogoCol = TableColumns.companyLogoColumn(theme);
	const jobLocationCol = TableColumns.jobLocationColumn(theme);
	const jobDepartmentCol = TableColumns.jobDepartmentColumn(theme);
	const jobTeamCol = TableColumns.jobTeamColumn(theme);
	const jobHasContentCol = TableColumns.jobHasContentColumn(theme);
	const jobTogglePublishedCol = TableColumns.jobTogglePublishedColumn(theme, togglePublishedJob);
	const companyAccessCol = TableColumns.companyAccessColumn(theme);
	const createdAtCol = TableColumns.createdAtColumn(theme);
	const updatedAtCol = TableColumns.updatedAtColumn(theme);
	const atsCreatedAtCol = TableColumns.dateColumn(theme, {
		title: 'ATS Created',
		dataIndex: 'atsCreatedAt',
		key: 'atsCreatedAt',
	});
	const jobTagsCols = TableColumns.jobTagsColumns(theme);
	const jobTagsStaffCols = TableColumns.jobTagsStaffColumns(theme);
	const countReadsCol = TableColumns.docsCountColumn(theme, {
		title: 'Reads',
		dataIndex: 'actions',
		value: (v) => (v?.read || []).length,
		icon: <EyeOutlined />,
		onClick: (value, row) => { handleOpenJobDetails(row, 'actions'); },
		removeInColumnFilter: true,
	});
	const countApplysCol = TableColumns.docsCountColumn(theme, {
		title: 'Apply',
		dataIndex: 'actions',
		value: (v) => (v['apply-submit'] || []).length,
		icon: <GenderhireCandidatesAppliedIcon />,
		onClick: (value, row) => { handleOpenJobDetails(row, 'actions'); },
		removeInColumnFilter: true,
	});
	const countSourcingsCol = TableColumns.docsCountColumn(theme, {
		title: SOURCINGS_TAB_LABEL,
		dataIndex: 'sourcings',
		value: (v) => (v || []).length,
		icon: <GenderhireCandidatesSourcedIcon />,
		onClick: (value, row) => { handleOpenJobDetails(row, 'sourcings'); },
		style: { color: '#08979c', background: '#e6fffb', borderColor: '#87e8de' },
		removeInColumnFilter: true,
	});
	const staffActionsCol = {
		title: 'Actions',
		dataIndex: 'actions',
		fixed: 'right',
		render: (value, row) => <Space direction='vertical' size={4}>
			<Space direction='horizontal' size={4}>
				{TableColumns.refreshBtn(theme, {
					icon: <TagsOutlined style={{ marginBottom: -2 }} color={theme.color.black} />,
					onClick: () => handleUpdateTags(row),
					tooltip: 'Compute tags',
				})}
			</Space>
			<Space direction='horizontal' size={4}>
				{TableColumns.refreshBtn(theme, {
					icon: <CloudDownloadOutlined style={{ marginBottom: -2 }} color={theme.color.black} />,
					onClick: () => handleFetchJob(row),
					tooltip: 'Fetch job content',
				})}
				{row?.isCustomize && <ActionButton
					tooltipTitle='Edit job'
					onClick={() => onEditJob(row)}
					icon={<RiEdit2Fill />}
				/>
				}
			</Space>
			<Space direction='horizontal' size={4}>
				<ActionButton
					tooltipTitle='AI'
					onClick={() => onEditAIJob(row)}
					icon={<RiRobotLine />}
				/>
			</Space>
		</Space>,
		...TableColumns.columnMinWidth(60),
	};

	const managersCol = {
		title: 'Managers',
		dataIndex: 'managerIds',
		removeInColumnFilter: true,
		align: 'center',
		render: (_, row) => <JobEditManagersButton job={row} onRefresh={handleRefreshTable} />,
		...TableColumns.columnMinWidth(200),
	};

	const notificationsToggleCol = {
		title: <>Notify me on<br />applications</>,
		dataIndex: 'managerIds',
		removeInColumnFilter: true,
		align: 'center',
		render: (_, row) => <JobNotificationsToggle job={row} onRefresh={handleRefreshTable} />,
		...TableColumns.columnMinWidth(90),
	};

	const notificationsStatusCol = {
		title: <>Notification<br />status</>,
		dataIndex: 'managerIds',
		removeInColumnFilter: true,
		align: 'center',
		render: (_, row) => <JobNotificationsStatus job={row} />,
		...TableColumns.columnMinWidth(90),
	};

	const notificationsRecipientsCol = {
		title: <>Notifications<br />recipients</>,
		dataIndex: 'managerIds',
		removeInColumnFilter: true,
		align: 'center',
		render: (_, row) => (
			<Space>
				<JobEditManagersButton job={row} onRefresh={handleRefreshTable} />
				<JobNotificationsRecipients job={row} onRefresh={handleRefreshTable} />
			</Space>
		),
		...TableColumns.columnMinWidth(160),
	};

	const actionsCol = {
		title: 'Actions',
		dataIndex: 'actions',
		fixed: 'right',
		render: (value, row) => row?.isCustomize && !isAtsSyncable
			&& <ActionButton
				tooltipTitle='Edit job'
				onClick={() => onEditJob(row)}
				icon={<RiEdit2Fill />}
			/>,
		...TableColumns.columnMinWidth(60),
	};

	const defaultColumns = isStaffView
		? [
			jobStubCol,
			jobJsonCol,
			companyLogoCol,
			jobTogglePublishedCol,
			notificationsStatusCol,
			notificationsToggleCol,
			notificationsRecipientsCol,
			// managersCol,
			companyAccessCol,
			countSourcingsCol,
			countReadsCol,
			countApplysCol,
			jobPageLinkCol,
			jobExternalUriCol,
			jobExternalApplyUriCol,
			jobExternalLinkCol,
			createdAtCol,
			updatedAtCol,
			atsCreatedAtCol,
			jobLocationCol,
			jobDepartmentCol,
			jobTeamCol,
			jobHasContentCol,
			...jobTagsStaffCols,
			staffActionsCol,
		]
		: [
			jobStubCol,
			jobTogglePublishedCol,
			notificationsStatusCol,
			notificationsToggleCol,
			notificationsRecipientsCol,
			// managersCol,
			countSourcingsCol,
			jobPageLinkCol,
			jobExternalLinkCol,
			createdAtCol,
			// updatedAtCol,
			// actionsCol,
			...!isAtsSyncable ? [actionsCol] : [],
		];

	const handleEditJob = (job) => {
		localDebug('JobsTable handleEditJob', { job });
		onEditJob(job);
	};

	return (
		<>
			<Table
				path={path}
				onDataLoaded={onDataLoaded}
				columns={columns || defaultColumns}
				filterOptions={filterOptions}
				isRefresh={isRefresh + isRefreshDataLayout}
				onSearchDataColumn={handleSearchAutoComplete}
				withDynamicSearch
				defaultColumnsOptions={['title', 'location', 'department', 'team']}
				rowClassName={(row, index) => (!(new JobModel(row)).isJobPublished ? 'rowDisabled' : '')}
				{...props}
			/>
			<JobDrawerDetails
				open={openJobDetails}
				onClose={handleCloseDrawerJobDetails}
				job={jobSelected}
				jobActiveTab={JobActiveTab}
				// onEditJob={onEditJob}
				onEditJob={handleEditJob}
			/>
		</>
	);
};

export default JobsTable;
