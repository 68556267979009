export const options = [
	{ value: 'yearly', label: '/ year' },
	{ value: 'monthly', label: '/ month' },
	{ value: 'semi-monthly', label: '/ semi-monthly' },
	{ value: 'bimonthly', label: '/ bimonthly' },
	{ value: 'biweekly', label: '/ biweekly' },
	{ value: 'weekly', label: '/ week' },
	{ value: 'daily', label: '/ day' },
	{ value: 'hourly', label: '/ hour' },
	{ value: 'one-time', label: 'One-time' },
];
