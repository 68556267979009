import {
	Button, Col, Row, Space,
} from 'antd';
import PageLayout from '../../../components/app/layout/PageLayout';
import SectionTitle from '../../../components/app/SectionTitle';
import TableColumns from '../../../components/app/table/TableColumns';
import Table from '../../../components/app/table/Table';
import { useEffect, useState } from 'react';
import { request } from '../../../services/api';
import { LocalDebug } from '../../../utils/LocalDebug';
import { useTheme } from '@emotion/react';
import { numToArray } from '../../../utils/common';
import moment from 'moment';

const BoardKPisPage = () => {
	const className = 'BoardKPisPage';
	const theme = useTheme();

	const [data, setData] = useState(null);
	const [refresh, setRefresh] = useState(0);

	useEffect(async () => {
		try {
			const { data: { results } } = await request.get('/stats/boardKpis');
			LocalDebug.logUseEffect({ className, effects: '' }, { results });
			setData(results);
		} catch (e) {
			LocalDebug.logError({ className, error: e }, { e });
		}
	}, [refresh]);

	const columns = [
		{
			title: 'Title',
			dataIndex: ['title'],
			render: (value, row) => <div style={{ lineHeight: 1.2, fontWeight: 'bold' }}>{value}</div>,
			...TableColumns.columnFixedWidth(300),
		},
		...numToArray(10).map((i) => {
			const date = moment().add(-i, 'quarter').startOf('quarter').format('[Q]Q YYYY');
			return {
				title: date,
				dataIndex: date,
				render: (value, row) => (
					<div
						style={{
							lineHeight: 1.2,
							fontSize: 16,
							fontWeight: 'bold',
						}}
					>
						{value}
					</div>
				),
				...TableColumns.columnFixedWidth(100),

			};
		}),
	];

	return (
		<PageLayout
			withBackground={false}
			title='Board KPIs'
			icon={<span>💯</span>}
			rightActions={<Button onClick={() => setRefresh((p) => p + 1)}>Refresh</Button>}
		>
			{data && (
				<Table
					withTableHeader={false}
					style={{ width: '100%' }}
					columns={columns}
					showSizeChanger={false}
					dataSource={data}
					limit={200}
					withPagination={false}
				/>
			)}
		</PageLayout>
	);
};

export default BoardKPisPage;
