import { useTheme } from '@emotion/react';
import { BiChevronRight } from 'react-icons/bi';
import ReactMarkdown from 'react-markdown';
import Link from '../../../app/Link';
import { ANSWER_VALUE_SKIPPED } from '../../data/shared';
import SurveyQuestionInputNumber from '../../../survey/inputs/SurveyQuestionInputNumber';
import SurveyQuestionInputText from '../../../survey/inputs/SurveyQuestionInputText';
import SurveyQuestionInputPct from '../../../survey/inputs/SurveyQuestionInputPct';
import SurveyInputSingleChoice from '../inputs/SurveyInputSingleChoice';
import SurveyInputMultipleChoice from '../inputs/SurveyInputMultipleChoice';
import SurveyInputAgree from '../inputs/SurveyInputAgree';
import { useContext } from 'react';
import { SurveyNavigationContext } from '../providers/SurveyNavigationProvider';
import { Space } from 'antd';
import SurveyQuestionInputTextarea from '../../../survey/inputs/SurveyQuestionInputTextarea';

const SurveyQuestionContent = ({
	question,
	pillar,
	answers,
	value,
	onChange = () => {},
	onSkip = () => {},
}) => {
	const className = 'SurveyQuestionContent';

	const theme = useTheme();

	const { appLang, t } = useContext(SurveyNavigationContext);

	const QuestionInputComponent = {
		text: SurveyQuestionInputText,
		textarea: SurveyQuestionInputTextarea,
		number: SurveyQuestionInputNumber,
		pct: SurveyQuestionInputPct,
		single: SurveyInputSingleChoice,
		multiple: SurveyInputMultipleChoice,
		agree: SurveyInputAgree,
	}[question?.input];

	return (
		<>
			{/* <div
				style={{
					fontSize: 24,
					fontWeight: 300,
					color: '#999',
				}}
			>
			</div> */}

			<span
				style={{
					fontWeight: 'unset',
					color: '#222',
					lineHeight: 1.2,
				}}
			>
				<ReactMarkdown>
					{`## ${question?.label?.[appLang] || question?.label}`}
				</ReactMarkdown>
			</span>
			{question?.sublabel && (
				<div
					className='sublabel'
					style={{
						fontSize: 16,
						fontWeight: 300,
						color: '#444',
						marginBottom: 0,
					}}
				>
					<ReactMarkdown>
						{question?.sublabel?.[appLang] || question?.sublabel}
					</ReactMarkdown>
				</div>
			)}
			{
				QuestionInputComponent
					? <div style={{ marginTop: 12, width: '100%' }}>
						<QuestionInputComponent
							question={question}
							onChange={onChange}
							value={value}
							align={'left'}
						/>
					</div>
					: null
			}

			{question?.optional && (
				<p
					className='caption'
					style={{
						marginTop: 30,
						fontSize: 14,
						fontWeight: 300,
						color: '#444',
					}}
				>
					{answers?.[question?.questionId] === ANSWER_VALUE_SKIPPED
						? (
							<Space size={4}>
								<ReactMarkdown>{t('question-skipped.intro')}</ReactMarkdown>
								<Link onClick={onSkip}>
									<b>{t('question-skipped.skip-btn')}</b>
									<BiChevronRight
										style={{
											fontSize: 16,
											marginBottom: -4,
										}}
									/>
								</Link>
							</Space>
						)
						: (
							<>{question?.skipLabel?.[appLang] || question?.skipLabel || t('question-skip.intro')}
								, {t('question-skip.you-can')} <Link onClick={onSkip}>
									<b>{t('question-skip.skip-btn')}</b>
									<BiChevronRight
										style={{
											fontSize: 16,
											marginBottom: -4,
										}}
									/>
								</Link>
							</>
						)
					}
				</p>
			)}
		</>
	);
};

export default SurveyQuestionContent;
