import ArchivedPage from '../../containers/sourcing/ArchivedPage';
import {
	PERMS_GRANT_RECRUITER_DENY_ATS_SYNCABLE,
	PERMS_OVERRIDE_STAFF,
} from '../menu.utils';
import MenuBase from '../MenuBase';
import { FolderOpenOutlined } from '@ant-design/icons';

export const GenderhireCandidatesArchivedIcon = FolderOpenOutlined;

class GenderhireCandidatesArchivedMenu extends MenuBase {
	static props = {
		name: 'candidates-archived',
		path: '/candidates/archived',
		aliases: ['^/archived$'],
		label: 'Archived',
		icon: <GenderhireCandidatesArchivedIcon />,
		iconLegacy: '📂',
		Component: ArchivedPage,
		perms: [
			...PERMS_GRANT_RECRUITER_DENY_ATS_SYNCABLE,
			PERMS_OVERRIDE_STAFF,
		],
	};

	constructor() {
		super(GenderhireCandidatesArchivedMenu.props);
	}
}

export default GenderhireCandidatesArchivedMenu;
