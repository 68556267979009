import { mapSeries } from 'async';
import {
	BIAS_OPTIONS,
	getBiasLevel,
	PERSONA_MANAGER,
	PERSONA_EMPLOYEES_ALL,
} from '../../../data/biases';
import {
	bold,
	italic,
	PDF_PAGE_TEMPLATE_CONTENT,
	PDF_TEXT_STYLES,
	PDF_TYPE_BLOCK,
	PDF_TYPE_DIVIDER,
	PDF_TYPE_GRID,
	PDF_TYPE_IMAGE,
	PDF_TYPE_RECT,
	PDF_TYPE_TEXT,
} from '../base/PDFBuilder';

const PageResultsPersona = ({
	persona,
	title,
	subtitle,
	version,
	pageCount,
	footer,
	skip,
}) => async ({
	doc,
	context,
}) => {
	let center = { x: doc.pdfWidth / 2, y: doc.pdfHeight / 2 };
	const radius = doc.pdfWidth / 4.5;
	const offset = 6;

	// console.log('PageResultsPersona', persona?.id, context.getRadarId(persona));

	if (version === 'legacy') {
		center.y += -20;
		return [
			{
				template: PDF_PAGE_TEMPLATE_CONTENT,
				title: title || 'Score results',
				subtitle: subtitle || persona.label,
				pageCount,
				footer,
				items: [
					{
						type: PDF_TYPE_IMAGE,
						uri: await doc.makeImageUri({ id: context.getRadarId(persona), context, skip }),
						width: doc.pdfWidth - doc.n(doc.pagePadding.left) - doc.n(doc.pagePadding.right),
						height: (doc.pdfWidth - doc.n(doc.pagePadding.left) - doc.n(doc.pagePadding.right)) * context.radarSize[1] / context.radarSize[0],
						x: center.x - radius,
						y: center.y - radius,
						width: 2 * radius,
						height: 2 * radius,
						position: 'absolute',
					},
					...BIAS_OPTIONS
						.map((bias, index, arr) => {
							const angle = -(Math.PI / 2) + 2 * Math.PI * index / arr.length;
							const x = center.x + radius * Math.cos(angle);
							const y = center.y + radius * Math.sin(angle);
							const w = 36;
							const h = 26;
							// const dx = 0;
							// const dy = 0;

							const { dx, dy, align } = [
								{ align: 'center' },
								{ align: 'left' },
								{ align: 'left' },
								{ align: 'left' },
								{ align: 'right' },
								{ align: 'right' },
								{ align: 'right' },
							][index];
							// const { dx, dy } = [
							// 	{ dx: -w / 2, dy: -h - 4 - offset },
							// 	{ dx: offset + 3, dy: -h / 2 - 6 },
							// 	{ dx: offset, dy: -h / 2 + 2 },
							// 	{ dx: -w / 4, dy: offset },
							// 	{ dx: -3 * w / 4, dy: offset },
							// 	{ dx: -w - offset, dy: -h / 2 + 2 },
							// 	{ dx: -w - offset - 3, dy: -h / 2 - 6 },
							// ][index];
							return [
								{
									type: PDF_TYPE_TEXT,
									...bold(PDF_TEXT_STYLES.normal),
									text: bias.label2 || bias.label,
									x: x + doc.n(dx),
									y: y + doc.n(dy),
									options: {
										align,
									},
									position: 'absolute',
								},
							];
						}).flat(),
					{
						type: PDF_TYPE_DIVIDER,
						x: doc.pagePadding.left,
						y: center.y + radius,
						marginTop: 5,
						marginBottom: 5,
					},
					{
						type: PDF_TYPE_GRID,
						marginTop: 5,
						gridTemplateColumns: [1, 1],
						columnGap: 30,
						rowGap: 4,
						columns: BIAS_OPTIONS.map((bias, index) => {
							const value = context?.umsResult?.scores?.bias?.[bias?.id]?.[persona.id] || 0;
							const level = getBiasLevel(value);
							return doc.getBiasLevelTagBlock({ bias, value, level });
						}),
					},
				],
			},
		];
	}

	center = { x: doc.pdfWidth / 2, y: doc.pdfHeight / 2 + 0 * 16 };
	return [
		{
			template: PDF_PAGE_TEMPLATE_CONTENT,
			title: title || 'Score results',
			subtitle: subtitle || persona.label,
			pageCount,
			footer,
			items: [
				{
					type: PDF_TYPE_IMAGE,
					uri: await doc.makeImageUri({ id: context.getRadarId(persona), context, skip }),
					width: (radius + offset) * 2,
					height: (radius + offset) * 2,
					x: center.x - radius - offset,
					y: center.y - radius - offset,
				},
				...(await mapSeries(
					BIAS_OPTIONS.map((bias, index, arr) => [bias, index, arr]),
					async ([bias, index, arr]) => {
						const value = context?.umsResult?.scores?.bias?.[bias?.id]?.[persona.id] || 0;
						const level = getBiasLevel(value);
						const angle = -(Math.PI / 2) + 2 * Math.PI * index / arr.length;
						const x = center.x + radius * Math.cos(angle);
						const y = center.y + radius * Math.sin(angle);
						// const w = 36;
						// const h = 26;
						// const { dx, dy } = [
						// 	{ dx: -w / 2, dy: -h - 4 - offset },
						// 	{ dx: offset + 3, dy: -h / 2 - 6 },
						// 	{ dx: offset, dy: -h / 2 + 2 },
						// 	{ dx: -w / 4, dy: offset },
						// 	{ dx: -3 * w / 4, dy: offset },
						// 	{ dx: -w - offset, dy: -h / 2 + 2 },
						// 	{ dx: -w - offset - 3, dy: -h / 2 - 6 },
						// ][index];
						const w = 140 / 2.2;
						const h = 80 / 2.2;
						const { dx, dy } = [
							{ dx: -w / 2, dy: -h - 4 },
							{ dx: offset + 9.2, dy: -h / 2 - 10 },
							{ dx: offset, dy: -h / 2 + 2 },
							{ dx: 0, dy: offset / 3 },
							{ dx: -w, dy: offset / 3 },
							{ dx: -w - offset, dy: -h / 2 + 2 },
							{ dx: -w - offset - 9.2, dy: -h / 2 - 10 },
						][index];
						return [
							await doc.getScoreBiasPersonaTagBlock({
								context,
								bias,
								persona: [PERSONA_MANAGER, PERSONA_EMPLOYEES_ALL],
								width: w,
								height: h,
								x: x + dx,
								y: y + dy,
								skip,
							}),
						];
						// return [
						// 	doc.getRadialBiasLevelTagBlock({
						// 		bias,
						// 		value,
						// 		level,
						// 		x: x + dx,
						// 		y: y + dy,
						// 	}),
						// ];
					},
				)).flat(),
				{
					type: PDF_TYPE_RECT,
					// width: doc.pdfWidth - doc.pagePadding.left - doc.pagePadding.right,
					width: doc.pdfWidth,
					x: 0,
					y: doc.pdfHeight - 40 - doc.pagePadding.bottom,
					height: 40 + doc.pagePadding.bottom,
					fill: '#f5f5f5',
					position: 'absolute',
				},
				{
					type: PDF_TYPE_BLOCK,
					// dx: -20,
					// x: 0,
					y: doc.pdfHeight - 40 - doc.pagePadding.bottom,
					options: { maxWidth: doc.pdfWidth },
					padding: {
						// left: 6,
						// right: 6,
						// top: 4,
						// bottom: 4,
						left: doc.pagePadding.left * 0,
						right: doc.pagePadding.right * 0,
						top: 8,
						bottom: 8,
					},
					items: [
						...BIAS_OPTIONS.map((bias, index, arr) => [
							{
								type: PDF_TYPE_TEXT,
								text: `• ${bias.label2 || bias.label}: ${bias.shortDesc?.[context?.appLang || 'en']}`,
								...italic(PDF_TEXT_STYLES.small),
								fontSize: 9,
								color: '#aaaaaa',
								// ...index === 0 ? { marginTop: 10 } : {},
								marginBottom: 1,
								addSpacesBeforeDot: false,
							},
						]).flat(),
					],
				},
			],
		},
	];
};

export default PageResultsPersona;
