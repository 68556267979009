import MenuBase from '../../MenuBase';
import { StarOutlined } from '@ant-design/icons';
import StaffGenderScoreRankingsPage from '../../../containers/genderscore/StaffGenderScoreRankingsPage';
import { PERMS_GRANT_STAFF } from '../../menu.utils';

export const StaffGSRankingsIcon = StarOutlined;

class StaffGSRankingsMenu extends MenuBase {
	static props = {
		name: 'staff-genderscore-rankings',
		path: '/staff/genderscore/rankings',
		label: 'Rankings',
		icon: <StaffGSRankingsIcon />,
		iconLegacy: '💯',
		Component: StaffGenderScoreRankingsPage,
		perms: PERMS_GRANT_STAFF,
	};

	constructor() {
		super(StaffGSRankingsMenu.props);
	}
}

export default StaffGSRankingsMenu;
