import { Tooltip } from 'antd';
import { FaExpandAlt } from 'react-icons/fa';
import Link from '../../app/Link';
import UserModel from '../../../models/UserModel';
import { useAuthContext } from '../../../hooks/useAuthContext';
import { useLocation } from 'react-router-dom';

const UserLinkFullPage = (
	{
		user,
		label = 'Open full page',
		iconPlacement = 'left',
		withLabel = true,
		withCircle = false,
		...props
	},
) => {
	const className = 'UserLinkFullPage';

	const { currentUser } = useAuthContext();
	const location = useLocation();

	if (!user) {
		return null;
	}

	const path = `/user/${new UserModel(user).getPublicSlug(currentUser)}`;
	if (path === location?.pathname) {
		return null;
	}
	const fullpath = new UserModel(user).getProfileUrl(currentUser);

	const icon = (
		<FaExpandAlt
			style={{
				marginBottom: -2,
				...props?.style?.icon,
			}}
		/>
	);

	return (
		<Tooltip title={fullpath}>
			<Link
				to={path}
				style={{
					fontSize: 11,
					...props?.style,
				}}
			>
				{iconPlacement === 'left' && (
					<>
						{icon}
						{withLabel && <>&nbsp;</>}
					</>
				)}
				{label}
				{iconPlacement === 'right' && (
					<>
						{withLabel && <>&nbsp;</>}
						{icon}
					</>
				)}
			</Link>
		</Tooltip>
	);
};

export default UserLinkFullPage;
