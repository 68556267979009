const { isStringTypeValidator } = require('../../utils/isStringTypeValidator');

const CAREER_REMOTE_KEY = 'career-remote';

const CAREER_REMOTE_ANSWER_FULLY_REMOTE = 'fully-remote';
const CAREER_REMOTE_ANSWER_HYBRID = 'hybrid';
const CAREER_REMOTE_ANSWER_FULLY_ONSITE = 'fully-onsite';

const CAREER_REMOTE_OPTIONS = [
	{ value: CAREER_REMOTE_ANSWER_FULLY_REMOTE, label: 'Fully remote' },
	{ value: CAREER_REMOTE_ANSWER_HYBRID, label: 'Hybrid' },
	{ value: CAREER_REMOTE_ANSWER_FULLY_ONSITE, label: 'Fully on-site' },
];

const customValidator = (value) => CAREER_REMOTE_OPTIONS.map((o) => o.value).includes(value);

const CAREER_REMOTE_VALIDATORS = [
	isStringTypeValidator,
	customValidator,
];

export default {
	CAREER_REMOTE_ANSWER_HYBRID,
	CAREER_REMOTE_ANSWER_FULLY_REMOTE,
	CAREER_REMOTE_ANSWER_FULLY_ONSITE,

	key: CAREER_REMOTE_KEY,
	CAREER_REMOTE_KEY,
	validators: CAREER_REMOTE_VALIDATORS,
	CAREER_REMOTE_VALIDATORS,
	options: CAREER_REMOTE_OPTIONS,
	CAREER_REMOTE_OPTIONS,
};
