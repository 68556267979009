import { Tag, Tooltip, Typography } from 'antd';
import { CheckCircleOutlined } from '@ant-design/icons';
import { useTheme } from '@emotion/react';
import { getDEICriteriaLabel } from '../../../constants/constant';

const UserStubDEICriteria = ({ user, level = null, ...props }) => {
	const theme = useTheme();

	if (!user?.jobSearch?.deiCriteria?.length) {
		return null;
	}
	const tagStyle = {
		fontSize: 11,
		margin: 1,
		borderRadius: 30,
	};
	// const tagStyle = { margin: 2, fontSize: 12, padding: '1px 5px', fontWeight: 'bold', border: 'none' };

	const content = (
		<div
			style={{ ...props?.style }}
		>
			<CheckCircleOutlined
				key='check'
				style={{ color: theme.color.grey }}
			/>
			&nbsp;{user?.jobSearch?.deiCriteria
				.map((value, index, arr) => {
					if (index === 0) {
						return (
							<Tag
								key={index}
								color='blue'
								style={{ ...tagStyle }}
							>
								{getDEICriteriaLabel(value)}
							</Tag>
						);
					}
					if (index === 1) {
						return <Tooltip key={index} title={arr.map((v, i) => (
							<Tag
								key={i}
								color='blue'
								size={'medium'}
								style={{
									...tagStyle,
									fontWeight: 'bold',
									fontSize: 12,
									margin: 3,
								}}
							>
								{getDEICriteriaLabel(v)}
							</Tag>
						))}>
							<span
								key={index}
								color='blue'
								style={{
									...tagStyle,
									color: theme.color.white,
									background: theme.color.grey,
									borderRadius: 30,
									fontWeight: 'bold',
									userSelect: 'none',
									padding: '2px 6px',
								}}
							>
							+{arr.length - 1}
							</span>
						</Tooltip>;
					}
					return <span key={index} />;
				})}
		</div>
	);

	return level
		? <Typography.Title level={level} style={{ marginBottom: 0 }}>
			{content}
		</Typography.Title>
		: <Typography style={{ color: theme.color.darkgrey, fontSize: 12, ...props?.style }}>
			{content}
		</Typography>;
};

export default UserStubDEICriteria;
