import { useSelector } from 'react-redux';
import PageLayout from '../../components/app/layout/PageLayout';
import { useTheme } from '@emotion/react';
import { getCompanySelected } from '../../reducers/app';
import CompanyGridPicker from '../../components/company/CompanyGridPicker';
import GenderScoreActionPlan from '../../components/company/genderscore/actions/GenderScoreActionPlan';

const GenderScoreActionPlanPage = () => {
	const className = 'GenderScoreActionPlanPage';

	const theme = useTheme();

	const companySelected = useSelector(getCompanySelected);

	if (!companySelected) return <CompanyGridPicker />;

	return (
		<PageLayout
			withHeader={false}
			withPadding={false}
		>
			<GenderScoreActionPlan
				company={companySelected}
			/>
		</PageLayout>
	);
};

export default GenderScoreActionPlanPage;
