import { isStringTypeValidator } from '../../../utils/isStringTypeValidator';
import { innerObjPropExtractor } from '../../property/property-extractor';
import { innerObjPropMapper } from '../../property/property-mapper';

const USER_PROPERTY_CAREER_SPECIFIC_KEY = 'career-specific';

const USER_PROPERTY_CAREER_SPECIFIC_MULTI = true;
const USER_PROPERTY_CAREER_SPECIFIC_FREE = false;

const USER_PROPERTY_CAREER_SPECIFIC_ANSWER_MANAGEMENT = 'management';
const USER_PROPERTY_CAREER_SPECIFIC_ANSWER_PROFESSIONAL_TRANSITION = 'professional-transition';
const USER_PROPERTY_CAREER_SPECIFIC_ANSWER_FREELANCE = 'freelance';
const USER_PROPERTY_CAREER_SPECIFIC_ANSWER_STARTUP_EXPERIENCE = 'startup-experience';

const USER_PROPERTY_CAREER_SPECIFIC_OPTIONS = [
	{ value: USER_PROPERTY_CAREER_SPECIFIC_ANSWER_STARTUP_EXPERIENCE, label: 'Startup experience' },
	{ value: USER_PROPERTY_CAREER_SPECIFIC_ANSWER_MANAGEMENT, label: 'Management experience' },
	{ value: USER_PROPERTY_CAREER_SPECIFIC_ANSWER_PROFESSIONAL_TRANSITION, label: 'In a professional transition' },
	{ value: USER_PROPERTY_CAREER_SPECIFIC_ANSWER_FREELANCE, label: 'Freelance' },
];

const customValidator = (value) => USER_PROPERTY_CAREER_SPECIFIC_OPTIONS.map((o) => o.value).includes(value);

const USER_PROPERTY_CAREER_SPECIFIC_VALIDATORS = [
	isStringTypeValidator,
	customValidator,
];

const USER_PROPERTY_CAREER_SPECIFIC_FIELD = 'career.careerSpecific';
const USER_PROPERTY_CAREER_SPECIFIC_MAPPER = innerObjPropMapper(USER_PROPERTY_CAREER_SPECIFIC_FIELD);
const USER_PROPERTY_CAREER_SPECIFIC_EXTRACTOR = innerObjPropExtractor(USER_PROPERTY_CAREER_SPECIFIC_FIELD);

export default {
	key: USER_PROPERTY_CAREER_SPECIFIC_KEY,
	USER_PROPERTY_CAREER_SPECIFIC_KEY,
	validators: USER_PROPERTY_CAREER_SPECIFIC_VALIDATORS,
	USER_PROPERTY_CAREER_SPECIFIC_VALIDATORS,
	options: USER_PROPERTY_CAREER_SPECIFIC_OPTIONS,
	USER_PROPERTY_CAREER_SPECIFIC_OPTIONS,
	multi: USER_PROPERTY_CAREER_SPECIFIC_MULTI,
	USER_PROPERTY_CAREER_SPECIFIC_MULTI,
	free: USER_PROPERTY_CAREER_SPECIFIC_FREE,
	USER_PROPERTY_CAREER_SPECIFIC_FREE,
	mapper: USER_PROPERTY_CAREER_SPECIFIC_MAPPER,
	USER_PROPERTY_CAREER_SPECIFIC_MAPPER,
	extractor: USER_PROPERTY_CAREER_SPECIFIC_EXTRACTOR,
	USER_PROPERTY_CAREER_SPECIFIC_EXTRACTOR,
	field: USER_PROPERTY_CAREER_SPECIFIC_FIELD,
	USER_PROPERTY_CAREER_SPECIFIC_FIELD,
};
