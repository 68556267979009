import { request } from './api';

const lists = async () => {
	return request.get('/stats/lists');
};
const refresh = async (params) => {
	return request.get('/stats/compute', { params });
};

const compute = async (params) => {
	return request.get('/stats/compute', { params: { ...params, bypassStorage: true } });
};

const StatService = {
	lists,
	refresh,
	compute,
};

export default StatService;
