import { message, Space, Tooltip } from 'antd';
import theme from '../../theme';
import { localDebug } from '../../utils/LocalDebug';
import SourcingATSOpenSmallButton from './buttons/SourcingATSOpenSmallButton';
import SourcingATSSyncSmallButton from './buttons/SourcingATSSyncSmallButton';
import AtsLogo from '../ats/AtsLogo';
import { useDispatch } from 'react-redux';
import SourcingAtsSender from './buttons/SourcingAtsSender';
import { shouldRefreshData } from '../../actions/app';
import { pushSourcingToATS, syncSourcingFromATS } from '../../actions/sourcings';
import { FaExclamationTriangle } from 'react-icons/fa';
import SourcingModel from '../../models/SourcingModel';

const SourcingATSManagerCell = (
	{
		sourcing,
		sendAction = pushSourcingToATS,
		syncAction = syncSourcingFromATS,
	},
) => {
	const dispatch = useDispatch();

	return (<></>);
	// const handleAtsSync = async (sourcing) => {
	//     localDebug('handleAtsSync', { sourcing: documentId(sourcing) });
	//     try {
	//         const { sourcing: updatedSourcing } = await dispatch(syncAction(sourcing));
	//         dispatch(shouldRefreshData());
	//     } catch (e) {
	//         message.error(e.message);
	//     }
	// }
	//
	// const handleAtsSend = async (sourcing) => {
	//     localDebug('handleAtsSend', { sourcing: documentId(sourcing) });
	//     try {
	//         const { sourcing: updatedSourcing } = await dispatch(sendAction(sourcing));
	//         dispatch(shouldRefreshData());
	//     } catch (e) {
	//         message.error(e.message);
	//     }
	// }
	//
	// let tooltip
	// if (!sourcing?.job) {
	//     tooltip = 'No job selected. Please add a job';
	// } else if (!sourcing?.job?.atsSync?.atsProvider) {
	//     tooltip = 'Job not found in your ATS. Please select an ATS-linked job';
	// }
	// if (tooltip) {
	//     return <Tooltip title={tooltip}>
	//         <Space direction='horizontal' size={8} style={{border: '1px dashed #ddd', borderRadius: 4, padding: '6px 10px', fontSize: 10, color: theme.color.grey, lineHeight: 1}}>
	//             <FaExclamationTriangle style={{fontSize: 20, marginBottom: -2}} />
	//             <span>
	//                 <i>ATS linkage<br/>unavailable</i>
	//             </span>
	//         </Space>
	//         {/*<span style={{fontSize: 10, color: theme.color.grey, lineHeight: 1}}>*/}
	//         {/*    <i>ATS linkage <br/>unavailable <FaInfoCircle style={{marginBottom: -2}} /></i>*/}
	//         {/*</span>*/}
	//     </Tooltip>;
	// }
	//
	// return <Space direction='horizontal'>
	//         {sourcing?.atsSync?.push?.atsId
	//             ? <>
	//                 <AtsLogo ats={new SourcingModel(sourcing).atsProvider} size={32} style={{marginBottom: -1}} />
	//                 <SourcingATSOpenSmallButton sourcing={sourcing} withLogo={false} title='' />
	//                 <SourcingATSSyncSmallButton sourcing={sourcing} onSync={handleAtsSync} />
	//             </>
	//             : <>
	//                 <SourcingAtsSender sourcing={sourcing} handleSend={handleAtsSend}/>
	//                 {/*<SourcingATSPushSmallButton sourcing={sourcing} onPush={handleAtsSend} />*/}
	//             </>
	//         }
	//     </Space>
};

export default SourcingATSManagerCell;
