import { useTheme } from '@emotion/react';
import { useEffect, useState } from 'react';
import Table from '../app/table/Table';
import { useAuthContext } from '../../hooks/useAuthContext';
import JSONPretty from 'react-json-pretty';
import TableColumns from '../app/table/TableColumns';
import {
	Button, message, Popconfirm, Popover, Space, Tag,
} from 'antd';
import { useDispatch } from 'react-redux';
import companyService from '../../services/company';
import { BOOLEAN_NO_ICON, BOOLEAN_YES_ICON } from '../../constants/constant';
import { localDebug } from '../../utils/LocalDebug';
import { documentId } from '../../utils/common';
import ReactJsonDebug from '../app/debug/ReactJsonDebug';

const AtsPermissionTable = (
	{
		companyAccess,
		isRefresh = 0,
		...props
	},
) => {
	const theme = useTheme();
	const dispatch = useDispatch();

	const [isRefreshDataLayout, setIsRefreshDataLayout] = useState(0);
	const { isStaffView } = useAuthContext();

	const refreshData = async () => {
		setIsRefreshDataLayout((prev) => prev + 1);
	};

	useEffect(() => {
		refreshData();
	}, [companyAccess]);

	const handleTestPermission = async (row) => {
		const key = `handleTestPermission-${row?.label}`;
		message.loading({ content: <>Testing permission <b>"{row.label}"</b></>, duration: 0, key });
		const { data: { result, error } } = await companyService.testAccessPermission(documentId(companyAccess), row?.label);
		if (error) {
			const { status, data, ...rest } = error || {};
			const { code, message: errorMessage } = data || {};
			message.error({
				content: <>Permission <b>"{row.label}"</b> failed<br/><b>Status /
					Code:</b> {status} / {code}<br/><b>Message:</b> {errorMessage}</>,
				key,
			});
		} else {
			message.success({ content: <>Permission <b>"{row.label}"</b> successfully tested!</>, key });
		}
		localDebug('handleTestPermission', { row, result, error });
		isRefresh += 1;
		refreshData();
	};

	const renderMethodTag = (row) => {
		const method = (row?.method || 'GET').toUpperCase();
		const methodColors = { GET: 'geekblue', POST: 'gold', PUT: 'green' };
		return <Tag color={methodColors[method]} style={{ borderRadius: 30 }}><b>{method}</b></Tag>;
	};

	const renderTestResult = (row) => {
		// return <JSONPretty data={row} />
		const popOverContentStyle = {
			maxWidth: 600,
			fontSize: 11,
			maxHeight: '50vh',
			overflow: 'scroll',
		};
		let popOverTitle = 'Success';
		let popOverContent;
		let popOverDisplay;

		const date = <span style={{ fontSize: 8 }}>{TableColumns.dateColumn(theme).render(row.createdAt)}</span>;

		if (row.result) {
			const { message: successMessage, data } = row.result || {};

			if (successMessage && data) {
				popOverContent = (
					<>
						{successMessage}
						<ReactJsonDebug src={data} />
					</>
				);
			} else {
				popOverContent = (
					typeof row?.result === 'string'
						? <div dangerouslySetInnerHTML={{ __html: row?.result }} />
						: <JSONPretty data={row?.result || {}} />
				);
			}
			popOverDisplay = (<><span>{BOOLEAN_YES_ICON} <b>OK</b></span></>);
		} else if (row.error) {
			const { status, data, ...rest } = row.error || {};
			const { code, message: errorMessage } = data || {};
			popOverTitle = <b>Error</b>;
			popOverContent = <JSONPretty data={rest} />;
			popOverDisplay = (
				<div style={{ verticalAlign: 'middle' }}>
					{BOOLEAN_NO_ICON} <b>{status} / {code}</b> {errorMessage}
				</div>
			);
		}

		return (
			<Popover
				placement={'right'}
				title={<b>{popOverTitle}</b>}
				content={(
					<div style={popOverContentStyle}>
						{popOverContent}
					</div>
				)}
			>
				{popOverDisplay}&nbsp; {date}
			</Popover>
		);
	};

	const labelCol = {
		title: 'Label',
		dataIndex: 'label',
		render: (value, row) => <Space direction='vertical' size={6}>
			<span style={{ fontSize: 16 }}><b>{value}</b></span>
			<div>{row.details}</div>
		</Space>,
		...TableColumns.columnMinWidth(200),
	};

	const labelEnpointCol = {
		title: 'Label',
		dataIndex: 'label',
		render: (value, row) => <Space direction='vertical' size={0}>
			<span style={{ fontSize: 16 }}>
				<b>{value}</b>
			</span>
			<div style={{ fontSize: 10, color: theme.color.grey, lineHeight: '1.1!important' }}>{row.details}</div>
			{/* <div>{row.details}</div> */}
			<Space direction='horizontal' size={4}
				   style={{ fontSize: 10, marginTop: 10 }}>{renderMethodTag(row)}<code>{row.endpoint}</code></Space>
		</Space>,
		...TableColumns.columnMinWidth(160),
	};

	const methodCol = {
		title: 'Method',
		dataIndex: 'method',
		align: 'center',
		render: (value, row) => renderMethodTag(row),
		...TableColumns.columnMinWidth(70),
	};

	const endpointCol = {
		title: 'Endpoint',
		dataIndex: 'endpoint',
		render: (value, row) => {
			return <Space direction='vertical' size={0}>
				<Space direction='horizontal' size={0} wrap={false}>{renderMethodTag(row)}&nbsp;<code
					style={{ fontSize: 10 }}>{value}</code></Space>
			</Space>;
		},
		...TableColumns.columnMinWidth(190),
	};

	const detailsCol = {
		title: 'Details',
		dataIndex: 'details',
		...TableColumns.columnMinWidth(220),
	};

	const levelCol = {
		title: 'Level',
		dataIndex: 'level',
		...TableColumns.columnMinWidth(120),
	};

	const clientRationaleCol = {
		title: isStaffView ? 'Client rationale' : 'Usage explanation',
		dataIndex: 'clientRationale',
		render: (value, row) => <span dangerouslySetInnerHTML={{ __html: value }} />,
		...TableColumns.columnMinWidth(320),
	};

	const staffRationaleCol = {
		title: 'Staff rationale',
		dataIndex: 'staffRationale',
		...TableColumns.columnMinWidth(320),
	};

	const statusCol = {
		title: 'Latest status',
		dataIndex: 'error',
		render: (value, row) => {
			if (!row?.testable) return <i>Not testable</i>;
			return renderTestResult(row);
		},
		...TableColumns.columnMinWidth(220),
	};

	const testButtonCol = {
		title: 'Test',
		dataIndex: 'testable',
		align: 'center',
		render: (value, row) => {
			if (!row?.testable) return <i>Not testable</i>;
			const button = row.confirm
				? <Popconfirm title={<div style={{ maxWidth: 300 }}>{row.confirm}</div>}
					okText="Yes"
					cancelText="No"
					onConfirm={() => handleTestPermission(row)}
				>
					<Button type='primary'>Test permission</Button>
				</Popconfirm>
				: <Button type='primary' onClick={() => { handleTestPermission(row); }}>Test permission</Button>;

			return <Space direction='vertical' size={4}>
				{button}
				{renderTestResult(row)}
			</Space>;
		},
		...TableColumns.columnMinWidth(220),
	};

	const resultCol = {
		title: 'Result',
		dataIndex: 'result',
		render: (value, row) => {
			if (!value && value !== false) return BOOLEAN_NO_ICON;
			return <Popover
				title={<b>Result</b>}
				content={
					<div style={{ maxWidth: 500, maxHeight: '50vh', overflow: 'scroll' }}>
						{typeof value === 'string'
							? value
							: <JSONPretty data={value || {}}/>
						}
					</div>}>
				<div>{BOOLEAN_YES_ICON} <b>Result</b></div>
			</Popover>;
		},
		...TableColumns.columnMinWidth(220),
	};

	const columns = isStaffView
		? [
			labelEnpointCol,
			// methodCol,
			// endpointCol,
			// detailsCol,
			// levelCol,
			testButtonCol,
			// testResultCol,
			clientRationaleCol,
			staffRationaleCol,
			// resultCol,
		]
		: [
			labelEnpointCol,
			// labelCol,
			// levelCol,
			// detailsCol,
			// endpointCol,
			// methodCol,
			testButtonCol,
			clientRationaleCol,
			// statusCol,
			// resultCol,
		];

	return (companyAccess
		&& <Table
			path={`access/${documentId(companyAccess)}/ats-permissions`}
			columns={columns}
			isRefresh={isRefresh + isRefreshDataLayout}
			withPagination={false}
			{...props}
		/>
	);
};

export default AtsPermissionTable;
