import FormBoxTitle from '../../../app/form/FormBoxTitle';
import { useNavigate } from 'react-router';
import { GENDERSCORE_LABEL, PATHS } from '../../../../constants/constant';
import { useContext } from 'react';
import { CompanyPageContext } from '../shared/CompanyPageProvider';

const CompanyGenderScoreEditorOnlyEditorLocked = (
	{
		company,
	},
) => {
	const className = 'CompanyGenderScoreEditorOnlyEditorLocked';

	const navigate = useNavigate();

	const { setCurrentlyEditingStep } = useContext(CompanyPageContext);

	return (
		<>
			<FormBoxTitle
				title={<>{GENDERSCORE_LABEL} Survey</>}
			/>

			Sorry, only <b>admin roles</b> of your company can take the {GENDERSCORE_LABEL} Survey.
		</>
	);
};

export default CompanyGenderScoreEditorOnlyEditorLocked;
