import { DatePicker, Form } from 'antd';
import { useReducer, useState } from 'react';
import FormBoxTitle from '../../app/form/FormBoxTitle';
import FormItem from '../form/FormItem';
import FormSubmitButton from '../../app/form/FormSubmitButton';
import { DATE_FORMAT } from '../../../constants/constant';
import {
	formStateReducer, onFormFinish, onFormFinishFailed, onFormValuesChange,
} from './CompanySettingsFormHelper';
import { useDispatch } from 'react-redux';
import BlockStaff from '../../app/blocker/BlockStaff';
import dayjs from 'dayjs';
import { LocalDebug } from '../../../utils/LocalDebug';
import { documentId } from '../../../utils/common';

const CompanySettingsChurnedAtForm = (
	{
		company,
		onSuccess,
		labelCol = { span: 6 },
		wrapperCol = { span: 24 },
		...props
	},
) => {
	const className = 'CompanySettingsChurnedAtForm';
	const formName = 'company-settings-churned-at-form';
	const dispatch = useDispatch();

	LocalDebug.logInfo({ className }, company?.churnedAt);
	const initialValues = {
		...company,
		churnedAt: company?.churnedAt && dayjs(company?.churnedAt),
	};

	const [form] = Form.useForm();
	const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
	const [companyEdited, setCompanyEdited] = useState({ ...company });

	const [state, stateDispatch] = useReducer(formStateReducer({ form, setIsSubmitDisabled }));

	return (
		<BlockStaff
			withBorder={false}
			tagStyle={{
				top: 5,
				left: 0,
				opacity: 1,
			}}
		>
			<FormBoxTitle
				title={<>👋 Churn date</>}
				details={<>
						Date when the contract ended and the company churned
				</>}
			/>
			<Form
				form={form}
				name={formName}
				initialValues={initialValues}
				labelCol={labelCol}
				wrapperCol={wrapperCol}
				labelAlign='right'
				layout='horizontal'
				onFinish={async (values) => {
					const result = await onFormFinish({
						id: documentId(company),
						values: { ...values, forceRecompute: false },
						formName,
						dispatch,
						stateDispatch,
					});
					onSuccess?.();
					return result;
				}}
				onFinishFailed={onFormFinishFailed}
				onValuesChange={async (values) => {
					setIsSubmitDisabled(isSubmitDisabled);
					return onFormValuesChange(
						{ values, stateDispatch, setCompanyEdited },
					);
				}}
			>
				<FormItem
					label='Churn date'
					name='churnedAt'
					rules={[{
						type: 'object',
						required: false,
						message: 'Please input date',
						whitespace: true,
					}]}
				>
					<DatePicker showTime={true} format={DATE_FORMAT} />
				</FormItem>

				<FormItem>
					<FormSubmitButton disabled={isSubmitDisabled}>
							Save churn date
					</FormSubmitButton>
				</FormItem>
			</Form>
		</BlockStaff>
	);
};

export default CompanySettingsChurnedAtForm;
