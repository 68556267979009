import { Tooltip } from 'antd';
import { DollarOutlined } from '@ant-design/icons';
import { findActionBudget } from './action-plan';

const CompanyActionBudgetTag = ({
	action,
	budget,
	...props
}) => {
	const className = 'CompanyActionBudgetTag';

	const budgetOption = findActionBudget(action?.budget || budget);

	if (!budgetOption) return null;

	return (
		<Tooltip title={budgetOption?.label}>
			<DollarOutlined /> {budgetOption?.tagLabel}
		</Tooltip>
	);
};

export default CompanyActionBudgetTag;
