const DrawerContainer = ({ children, header }) => {
	return (
		<>
			<div style={{
				border: '1px solid #eee', borderRadius: 4, padding: '5px 12px', marginBottom: 20,
			}}>
				{header}
			</div>
			{children}
		</>
	);
};

export default DrawerContainer;
