import { onEnterBuilder } from '../../../actions/app';
import UserMailingsPage from '../../../containers/staff/tech/UserMailingsPage';
import { PERMS_GRANT_STAFF } from '../../menu.utils';

const menu = {
	name: 'staff-tech-user-mailings',
	path: '/staff/tech/user-mailings',
	label: 'UserMailings',
	icon: <span>📮</span>,
	component: UserMailingsPage,
	onEnter: onEnterBuilder({
		origin: UserMailingsPage.name,
		shouldLoadUserTags: true,
	}),
	perms: PERMS_GRANT_STAFF,
};

export default menu;
