import { Button, Popover } from 'antd';
import { useContext } from 'react';
import { useSelector } from 'react-redux';
import { documentId } from '../../../utils/common';
import { atsProviderOptions } from '../../../constants/constant';
import UserAvatarList from '../widgets/UserAvatarList';
import UserButtonBadge from './UserButtonBadge';
import AtsLogo from '../../ats/AtsLogo';
import { getCompanySelectedApiAccess } from '../../../reducers/app';
import { UserAtsSendModalContext } from '../../../contexts/modals/UserAtsSendModalProvider';
import GenderhireCandidatesAtsMenu from '../../../menu/genderhire/GenderhireCandidatesAtsMenu';
import GenderhireCandidatesAppliedMenu from '../../../menu/genderhire/GenderhireCandidatesAppliedMenu';
import GenderhireCandidatesArchivedMenu from '../../../menu/genderhire/GenderhireCandidatesArchivedMenu';

const UserAtsSender = (
	{
		user,
		Component,
		withPrimaryStyle = false,
		onStart,
		onComplete,
		popoverPlacement,
		children,
		...props
	},
) => {
	const className = 'UserAtsSender';

	const { setOpen, setUser, setOnComplete } = useContext(UserAtsSendModalContext);

	const companySelectedApiAccess = useSelector(getCompanySelectedApiAccess);

	const atsName = atsProviderOptions?.find((o) => o?.value === companySelectedApiAccess?.ats)?.label || 'your ATS';

	const sourcers = [];
	user?.sourced?.forEach((item) => {
		const { manager } = item;
		sourcers.push(...manager && !sourcers.some((s) => (documentId(s)?.toString?.()) === (documentId(manager)?.toString?.())) ? [manager] : []);
	});
	const archivers = [];
	user?.archived?.forEach((item) => {
		const { manager } = item;
		archivers.push(...manager && !archivers.some((s) => (documentId(s)?.toString?.()) === (documentId(manager)?.toString?.())) ? [manager] : []);
	});

	const handleClick = async (e) => {
		e?.stopPropagation();
		setOpen(true);
		setUser(user);
		setOnComplete(() => async () => onComplete?.());
		await props?.onClick?.();
	};

	const button = Component
		? (
			<Component
				{...props}
				onClick={handleClick}
				style={{ ...props?.style?.button }}
			>
				{children}
			</Component>
		)
		: (
			<Button
				onClick={handleClick}
				icon={<span><AtsLogo size={16}/></span>}
				size={props?.size || 10}
				{...withPrimaryStyle && { type: 'primary' }}
				style={{ ...props?.style?.button }}
			>
				&nbsp;{props?.title || `Send talent to ${atsName}`}
			</Button>
		);

	const sourcingsCount = (
		(user?.sourcedCount || 0)
		+ (user?.applicationsCount || 0)
		+ (user?.archivedCount || 0)
	) || 0;

	const candidatesAtsMenu = new GenderhireCandidatesAtsMenu();
	const candidatesAppliedMenu = new GenderhireCandidatesAppliedMenu();
	const candidatesArchivedMenu = new GenderhireCandidatesArchivedMenu();

	return (
		<div
			style={{
				textAlign: 'left',
			}}
		>
			{sourcingsCount
				? <Popover
					title={<>{candidatesAtsMenu.icon} {candidatesAtsMenu.label}</>}
					placement={popoverPlacement}
					content={(<>
						{user?.sourcedCount > 0 && (
							<div>
								<strong>
									<span><AtsLogo size={16} /></span> Sent to {atsName}
								</strong>
								&nbsp;{user?.sourcedCount} time{user?.sourcedCount > 1 && 's'}
								{sourcers?.length > 0 && (
									<>
										&nbsp;by&nbsp;
										<UserAvatarList
											users={sourcers}
											size={28}
											overflow={-9}
											withCurrentUser={true}
										/>
									</>
								)}
							</div>
						)}
						{user?.applicationsCount > 0 && (
							<div style={{
								minHeight: 28,
							}}>
								<strong>{candidatesAppliedMenu.icon} Applied</strong>
								&nbsp;{user?.applicationsCount} time{user?.applicationsCount > 1 && 's'}
							</div>)
						}
						{user?.archivedCount > 0 && (
							<div style={{
								minHeight: 28,
								opacity: 0.7,
							}}>
								<strong>{candidatesArchivedMenu.icon} Archived</strong>
								&nbsp;{user?.archivedCount} time{user?.archivedCount > 1 && 's'}
								{archivers?.length > 0 && (
									<>
										&nbsp;by&nbsp;
										<UserAvatarList
											users={archivers}
											size={28}
											overflow={-9}
											withCurrentUser={true}
										/>
									</>
								)}
							</div>
						)}
					</>
					)}
				>
					<UserButtonBadge
						count={sourcingsCount}
						style={{ ...props?.style?.badge }}
					>
						{button}
					</UserButtonBadge>
				</Popover>
				: button
			}
		</div>
	);
};

export default UserAtsSender;
