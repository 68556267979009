import { useTheme } from '@emotion/react';
import { message, Typography } from 'antd';
import { useAuthContext } from '../../hooks/useAuthContext';
import UpdatePasswordForm from './UpdatePasswordForm';
import AdminService from '../../services/admin';

const UpdatePassword = ({
	withTitle = true,
	withEmail = false,
}) => {
	const theme = useTheme();
	const { updatePassword } = useAuthContext();

	const handleUpdatePassword = async ({ email, password }) => {
		if (email) {
			await AdminService.updateUserPassword({ email, password });
		} else {
			updatePassword(password);
		}
		message.success('Success');
	};

	return (
		<div className="flex1 flexCenter">
			{withTitle && (
				<div className="flexColumn" style={{ marginBottom: 24 }}>
					<Typography.Title level={1} style={{ color: theme.color.blue }}>
						Update password
					</Typography.Title>
				</div>
			)}
			<UpdatePasswordForm
				withEmail={withEmail}
				onSubmit={handleUpdatePassword}
			/>
		</div>
	);
};

export default UpdatePassword;
