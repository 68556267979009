import React from 'react';
import { Space } from 'antd';
import { useTheme } from '@emotion/react';
import UserResumeExperienceEntryRow from '../../../card/resume/UserResumeExperienceEntryRow';
import UserGridCardExpander from './UserGridCardExpander';

const UserGridCardResumeExperienceList = (
	{
		items = [],
		withExpander = true,
		...props
	},
) => {
	const className = 'UserGridCardResumeExperienceList';

	const theme = useTheme();

	if (!(items?.length > 0)) return null;

	const WrapperComponent = withExpander ? UserGridCardExpander : React.Fragment;

	return (
		<WrapperComponent>
			<Space
				direction='vertical'
				size={0}
				style={{
					width: '100%',
				}}
			>
				{items?.map((entry, i) => (
					<UserResumeExperienceEntryRow
						key={i}
						entry={entry}
						style={{
							title: {
								fontSize: 14,
							},
							organization: {
								fontSize: 14,
							},
							date: {
								fontSize: 12,
							},
							details: {
								fontSize: 12,
							},
							...props?.style,
						}}
					/>
				))}
			</Space>
		</WrapperComponent>
	);
};

export default UserGridCardResumeExperienceList;
