import { isStringTypeValidator } from '../../../utils/isStringTypeValidator';
import { innerObjPropExtractor } from '../../property/property-extractor';
import { innerObjPropMapper } from '../../property/property-mapper';

const USER_PROPERTY_PROFILE_COMPANY_KEY = 'profile-company';

const USER_PROPERTY_PROFILE_COMPANY_FREE = true;

const USER_PROPERTY_PROFILE_COMPANY_VALIDATORS = [
	isStringTypeValidator,
];

const USER_PROPERTY_PROFILE_COMPANY_FIELD = 'information.company';
const USER_PROPERTY_PROFILE_COMPANY_MAPPER = innerObjPropMapper(USER_PROPERTY_PROFILE_COMPANY_FIELD);
const USER_PROPERTY_PROFILE_COMPANY_EXTRACTOR = innerObjPropExtractor(USER_PROPERTY_PROFILE_COMPANY_FIELD);

export default {
	key: USER_PROPERTY_PROFILE_COMPANY_KEY,
	USER_PROPERTY_PROFILE_COMPANY_KEY,
	validators: USER_PROPERTY_PROFILE_COMPANY_VALIDATORS,
	USER_PROPERTY_PROFILE_COMPANY_VALIDATORS,
	free: USER_PROPERTY_PROFILE_COMPANY_FREE,
	USER_PROPERTY_PROFILE_COMPANY_FREE,
	mapper: USER_PROPERTY_PROFILE_COMPANY_MAPPER,
	USER_PROPERTY_PROFILE_COMPANY_MAPPER,
	extractor: USER_PROPERTY_PROFILE_COMPANY_EXTRACTOR,
	USER_PROPERTY_PROFILE_COMPANY_EXTRACTOR,
	field: USER_PROPERTY_PROFILE_COMPANY_FIELD,
	USER_PROPERTY_PROFILE_COMPANY_FIELD,
};
