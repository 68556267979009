import UserStub from '../../../user/stub/UserStub';
import GridCardBase from '../../../app/grid/GridCardBase';
import { Space, Tooltip } from 'antd';
import CAREER_SEEKING from '../../../../constants/user/property/career-seeking';
import LOCATION_COUNTRY from '../../../../constants/property/location-country';
import EXPERIENCE_LEVEL from '../../../../constants/property/experience-level';
import CAREER_EXPERIENCE from '../../../../constants/property/career-experience';
import CAREER_ROLE from '../../../../constants/property/career-role';
import CAREER_ROLE_GROUPS from '../../../../constants/property/career-role-group';
import TECH_STACK from '../../../../constants/property/tech-stack';
import { useTheme } from '@emotion/react';
import JsonPopoverLink from '../../../app/debug/JsonPopoverLink';
import { FaChevronRight } from 'react-icons/fa';
import { useAuthContext } from '../../../../hooks/useAuthContext';
import UserAvatar from '../../../user/widgets/UserAvatar';
import FunnelUserCardMenu from './FunnelUserCardMenu';
import moment from 'moment';
import { mixColors } from '../../../../utils/common';
import { useState } from 'react';
import { LocalDebug } from '../../../../utils/LocalDebug';
import UserModel from '../../../../models/UserModel';

const UserColorDots = (
	{
		user,
		dotSize = 10,
		...props
	},
) => {
	const theme = useTheme();
	const getDelayColor = (date, limit) => {
		if (!date) return null;
		const delay = (new Date()).getTime() - (new Date(date)).getTime();
		return mixColors(
			theme.color.accent,
			theme.color.darkturquoise,
			Math.max(
				0,
				Math.min(
					100,
					100 * (limit - delay) / limit,
				),
			),
		);
	};

	const day = 1000 * 60 * 60 * 24;
	const limit = day * 365;
	const registeredColor = getDelayColor(user?.createdAt, day * 365);
	const loggedColor = getDelayColor(user?.loggedAt, day * 30);
	const activeColor = getDelayColor(user?.careerSeekingUpdatedAt, day * 60);

	const textDelay = (date) => {
		if (!date) return <i style={{ opacity: 0.7 }}>Unknown date</i>;
		const m = moment(date);
		return (
			<>
				<b>{m.format('MMM Do YYYY, HH:mm')}</b> ({m.fromNow()})
			</>
		);
	};

	const colorDot = (color) => (
		<div
			style={{
				width: dotSize,
				height: dotSize,
				borderRadius: dotSize,
				// border: '1px solid white',
				...color
					? {
						background: color,
					}
					: {
						width: dotSize - 3,
						height: dotSize - 3,
						// border: '3px solid white',
						background: '#ddd',
					},
			}}
		/>
	);

	return (
		<Tooltip
			title={(
				<Space
					direction='vertical'
					style={{
						fontSize: 12,
					}}
				>
					<Space>
						{colorDot(registeredColor)}
						<span>
							Registered:<br />
							{textDelay(user?.createdAt)}
						</span>
					</Space>
					<Space>
						{colorDot(activeColor)}
						<span>
							Last job search status edition:<br />
							{textDelay(user?.careerSeekingUpdatedAt)}
						</span>
					</Space>
					<Space>
						{colorDot(loggedColor)}
						<span>
							Last logged:<br />
							{textDelay(user?.loggedAt)}
						</span>
					</Space>
				</Space>
			)}
		>
			<Space
				direction='horizontal'
				size={1}
				wrap={false}
				{...props}
			>
				{colorDot(registeredColor)}
				{colorDot(activeColor)}
				{colorDot(loggedColor)}
			</Space>
		</Tooltip>
	);
};

const UserCardTag = ({ children, style }) => {
	const theme = useTheme();

	if (!children) return null;

	return (
		<div
			style={{
				padding: '2px 6px',
				borderRadius: 4,
				fontSize: 10,
				background: '#eee',
				userSelect: 'none',
				...style,
			}}
		>
			{children}
		</div>
	);
};

const FunnelUserCard = (
	{
		columnId,
		funnel,
		title,
		user,
		menu,
		isLoading,
		withCard = true,
		withExperienceTag = false,
		withLevelTag = true,
		withLocationTag = true,
		withRolesTag = true,
		withSeekingTag = true,
		withProfession = true,
		withDelay = true,
		onContactUser,
		onEditCoach,
		onUserFunnel,
		onSetUserIsReachable,
	},
) => {
	const className = 'FunnelUserCard';

	const theme = useTheme();

	const { isAdmin, isStaffView } = useAuthContext();
	const [isReachable, setIsReachable] = useState(new UserModel(user).getCareerIsReachableByRecruiters());

	const notProvided = (field) => <Tooltip title={`${field} not provided`}><i>?</i></Tooltip>;

	const handleSetUserIsReachable = async (...args) => {
		await onSetUserIsReachable(...args);
		setIsReachable(new UserModel(user).getCareerIsReachableByRecruiters());
	};

	const cardSeekingStatus = (user) => {
		const field = 'Job search status';
		const option = CAREER_SEEKING.options.find((o) => o.value === user?.career?.seeking);
		return option
			? (
				<Tooltip title={<>{field}: <b>{option.label}</b></>}>
					{option.emoji}
				</Tooltip>
			)
			: notProvided(field);
	};

	const cardExperience = (user) => {
		const field = 'Experience';
		const option = CAREER_EXPERIENCE.options.find((o) => o.value === user?.career?.experience);
		return option
			? (
				<Tooltip title={<>{field}: <b>{option?.label}</b></>}>
					{option?.label?.replaceAll(' years', 'y')?.replaceAll(' year', 'y')}
				</Tooltip>
			)
			: notProvided(field);
	};

	const cardLevel = (user) => {
		const field = 'Level';
		const option = EXPERIENCE_LEVEL.options.find((o) => o.value === user?.jobSearch?.jobLevel);
		return option
			? (
				<Tooltip title={<>{field}: <b>{option?.label}</b></>}>
					{option?.label}
				</Tooltip>
			)
			: notProvided(field);
	};

	const cardCountry = (user) => {
		const field = 'Location';
		const option = LOCATION_COUNTRY.options.find((o) => o.value === user?.information?.location?.country);
		return option
			? (
				<Tooltip title={<>{field}: <b>{user?.information?.location?.city}, {option.label}</b></>}>
					{option.emoji}
				</Tooltip>
			)
			: notProvided(field);
	};

	const cardDelay = (user) => {
		const delay = (new Date()).getTime() - (new Date(user?.createdAt)).getTime();
		// return delay;
		const day = 1000 * 60 * 60 * 24;
		const field = 'Registered';
		const limit = day * 7;
		const m = moment(user?.createdAt);
		const d = m.fromNow()?.replace(' ago', '');
		return <Tooltip title={<>
			<div>{field}</div>
			<b>{m.format('MMM Do YYYY, HH:mm')}</b>
			<br />({m.fromNow()})
		</>}>
			<span>{d}</span>
		</Tooltip>;
	};

	const textDelay = (date) => {
		if (!date) return <i style={{ opacity: 0.7 }}>Unknown date</i>;
		const m = moment(date);
		return (
			<>
				<b>{m.format('MMM Do YYYY, HH:mm')}</b> ({m.fromNow()})
			</>
		);
	};

	const cardRoleGroups = (user) => {
		const field = 'Roles';
		const skillOptions = TECH_STACK.options.filter((o) => user?.career?.hardSkill?.includes(o.value));
		const groupOptions = CAREER_ROLE_GROUPS.options.filter((o) => user?.jobSearch?.jobRoleGroup?.includes?.(o.value));
		const roleOptions = CAREER_ROLE.options.filter((o) => user?.jobSearch?.jobRole?.includes?.(o.value));

		const tooltipTitle = <>
			<div><b>Desired roles ({roleOptions.length})</b></div>
			{groupOptions
				.map((groupOption, i) => {
					return (
						<div key={i}>
							{groupOption.label}
							{(
								roleOptions
									.filter((o) => o.group === groupOption.value)
									.map((roleOption, j) => (
										<div key={j} style={{ marginLeft: 6 }}>
											<FaChevronRight style={{ marginBottom: -2 }} /> {roleOption.label}
										</div>
									))
							)}
						</div>
					);
				})
			}
			<div style={{ marginTop: 12 }}><b>Hard Skills ({skillOptions.length})</b></div>
			{skillOptions.map((skillOption) => skillOption.label).join(', ')}
		</>;
		return groupOptions?.length > 0
			? (
				<Tooltip title={tooltipTitle}>
					{groupOptions[0]?.label}
					{(
						groupOptions?.length > 1
							? <>&nbsp;<span
								style={{
									fontSize: 9,
									fontWeight: 'bold',
									background: '#fff',
									color: '#666',
									padding: '0 2px',
									borderRadius: 4,
								}}>+{groupOptions.length - 1}</span></>
							: ''
					)}
				</Tooltip>
			)
			: notProvided(field);
	};

	const content = (
		user
			? (
				<>
					<Space
						direction='vertical'
						style={{ width: '100%' }}
					>
						<UserStub
							avatarSize={28}
							user={user}
							withDropdown={true}
							withLocation={false}
							withProfession={withProfession}
							withIsNew={false}
							withIsUnviewed={false}
							style={{
								margin: '6px 0',
								width: 'calc(100% - 15px)',
								// marginLeft: 10,
							}}
						/>

						<Space
							style={{
								width: 'calc(100% + 20px)',
								margin: '0 -10px -10px',
								padding: '4px 12px 8px',
								borderTop: '1px solid #eee',
								background: 'rgba(0, 0, 0, .03)',
								// theme.color.bgGrey,
								justifyContent: 'space-between',
							}}
							align='end'
						>
							<Space wrap={true} size={[4, 2]}>
								<UserColorDots
									user={user}
									size={2}
									style={{
										background: '#ddd',
										borderRadius: 12,
										padding: 2,
										height: 14,
									}}
								/>
								{withSeekingTag && <UserCardTag>{cardSeekingStatus(user)}</UserCardTag>}
								{withExperienceTag && <UserCardTag>{cardExperience(user)}</UserCardTag>}
								{withLevelTag && <UserCardTag>{cardLevel(user)}</UserCardTag>}
								{withLocationTag && <UserCardTag>{cardCountry(user)}</UserCardTag>}
								{withRolesTag && <UserCardTag>{cardRoleGroups(user)}</UserCardTag>}
								{/* {withDelay && <UserCardTag>{cardDelay(user)}</UserCardTag>} */}
								<UserAvatar
									size={18}
									user={user.coach}
									withDropdown={false}
									withLocation={false}
									withProfession={withProfession}
									withIsNew={false}
									withIsUnviewed={false}
									style={{ marginBottom: 2 }}
								/>
								{isAdmin && <JsonPopoverLink data={user} style={{ fontSize: 12 }} />}
							</Space>

							<Space>
								<FunnelUserCardMenu
									funnel={funnel}
									user={user}
									menu={menu}
									onContactUser={onContactUser}
									onEditCoach={onEditCoach}
									onUserFunnel={onUserFunnel}
									onSetUserIsReachable={handleSetUserIsReachable}
								/>
							</Space>
						</Space>
					</Space>
					{/* <UserColorDots */}
					{/*	user={user} */}
					{/*	direction='vertical' */}
					{/*	// size={0} */}
					{/*	style={{ */}
					{/*		position: 'absolute', */}
					{/*		top: 3, */}
					{/*		left: 3, */}
					{/*	}} */}
					{/* /> */}
				</>
			)
			: null
	);

	if (!user || !withCard) return content;

	return (
		<GridCardBase
			style={{
				padding: '8px 10px 8px 10px',
				height: 'auto',
				minHeight: 60,
				borderRadius: 5,
				...isReachable
					? {}
					: {
						borderColor: 'rgba(238, 130, 238, .7)',
						background: mixColors('#ee82ee', '#ffffff', 95),
					},
			}}
		>
			{content}
		</GridCardBase>
	);
};

export default FunnelUserCard;
