import {
	Button, Space, Tooltip,
} from 'antd';
import { useTheme } from '@emotion/react';

const ChatbotPromptHelpers = ({
	answerPending,
	onPromptSelected,
}) => {
	const className = 'ChatbotPromptHelpers';
	const theme = useTheme();

	const promptOptions = [
		{
			label: 'Start',
			userPrompt: 'Start the questionnaire',
		},
		{
			label: 'Continue',
			userPrompt: 'Continue',
		},
		{
			label: 'Yes',
			userPrompt: 'Yes',
		},
		{
			label: 'No',
			userPrompt: 'No',
		},
		{
			label: 'Ready',
			userPrompt: 'Ready',
		},
		{
			label: 'Prev',
			userPrompt: 'Go to the previous question',
		},
		{
			label: 'Next',
			userPrompt: 'Go to the next question',
		},
		{
			label: 'Random',
			userPrompt: 'Ask me a random question',
		},
		{
			label: 'Skip',
			userPrompt: 'Skip this question',
		},
		{
			label: 'FR',
			userPrompt: 'Parle-moi en français',
		},
		{
			label: 'EN',
			userPrompt: 'Talk to me in english',
		},
		{
			label: 'Ask unanswered',
			userPrompt: 'Ask me the first unanswered or skipped question',
		},
		{
			label: 'Ask options',
			userPrompt: 'Skip the current question but wait for me to confirm. On confirmation, just ask me a random question from the list, among those which have an "options" field',
			// preventStorage: true,
		},
		{
			label: 'List Q&A',
			userPrompt: 'List ALL the questions by pillar for ALL pillars (do not hide questions, show EVERYTHING) as an ordered list (i.e. using markdown), and give answers in bold if provided by the user',
			preventStorage: true,
		},
		{
			label: 'Answers',
			userPrompt: `Show me all my answers up to this point. 
			Display it as an unordered bulleted list.
			On each line I want to have the title of the question (strip markdown) and my answer in bold, separated by a colon. 
			If the question was skipped, or not answered yet, show "Not provided yet" in italic in place of the answer.
			After the list, add statistics.`,
			// After the list, add a divider, then:
			// "- [A]/[B] questiond answered ([C]% completion)
			// - Time spent answering: [D]"
			// where "[A]" = the number of questions answered (do not count skipped or unanswered), "[B]" = the total number of questions in all the GenderScore questionnaire, "[C]" = the ratio of "[A]" compared to "[B]", "[D]" = the time spent answering up to this point.`,
			// After the list, add:
			// - the number of answered questions compared to the number of total questions
			// - the % of completion for the whole questionnaire (which the ratio be )
			// - the time spent answering up to this point.
			preventStorage: true,
		},
	];

	return (
		<>
			<Space
				size={4}
				wrap={true}
				style={{
					marginTop: 8,
					width: '100%',
				}}
			>
				{/* <Button
					type='primary'
					size='small'
					ghost
					danger
					onClick={onClearAnswers}
				>
					Clear answers <CloseCircleOutlined />
				</Button> */}
				<b>Helper prompts:</b>

				{answerPending ? 'answerPending' : ''}
				{promptOptions
					.map(({ label, userPrompt, preventStorage }, index) => (
						<Tooltip
							key={index}
							title={<><b>Prompt:</b> "{userPrompt}"</>}
						>
							<Button
								disabled={answerPending}
								type='primary'
								size='small'
								ghost
								onClick={() => onPromptSelected?.({ userPrompt, preventStorage })}
							>
								{label}
							</Button>
						</Tooltip>
					))
				}
			</Space>
		</>
	);
};

export default ChatbotPromptHelpers;
