import { useEffect, useState } from 'react';
import { ENGLISH_LANG } from '../../../../constants/constant';
import { displayHtml, hexToRgb, mixColors } from '../../../../utils/common';
import BoxRainbow from '../../../app/box/BoxRainbow';
import { useTheme } from '@emotion/react';
import CompanyFieldDisplayOverlayLabel from './CompanyFieldDisplayOverlayLabel';
import CompanyFieldDisplayOverlayIcon from './CompanyFieldDisplayOverlayIcon';
import { FaLock } from 'react-icons/fa';
import { useAuthContext } from '../../../../hooks/useAuthContext';

const CompanyFieldDisplay = (
	{
		onClick,
		withOverlayLabel = false,
		overlayLabel = 'Edit',
		isEditable = true,
		isStaffLocked = false,
		hasEditorLockedComponent = false,
		children,
	},
) => {
	const className = 'CompanyFieldDisplay';

	const theme = useTheme();

	const { isStaff, isStaffView } = useAuthContext();
	const [overlay, setOverlay] = useState(false);
	const [overlayProps, setOverlayProps] = useState({ label: overlayLabel });

	useEffect(() => {
		setOverlayProps(
			!hasEditorLockedComponent && isStaffLocked && !(isStaff && isStaffView)
				? { Icon: FaLock, label: 'Locked' }
				: { label: overlayLabel },
		);
	}, [isStaffLocked, isStaff, isStaffView, hasEditorLockedComponent, overlayLabel]);

	if (!isEditable) {
		return (
			<>
				{children}
			</>
		);
	}

	return (
		<div
			style={{
				position: 'relative',
				cursor: 'pointer',
			}}
		>
			<div
				onClick={onClick}
				onMouseEnter={() => setOverlay(true)}
				onMouseLeave={() => setOverlay(false)}
			>
				{children}
			</div>
			{overlay && (
				<div
					style={{
						position: 'absolute',
						top: -10,
						left: -10,
						width: 'calc(100% + 20px)',
						height: 'calc(100% + 20px)',
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
						background: `rgba(${hexToRgb(mixColors(theme.color.fitBlueElectric, theme.color.white, 80)).join(',')}, .4)`,
						boxShadow: '0 0 20px -4px rgba(0,0,0,.3)',
						borderRadius: 10,
						pointerEvents: 'none',
					}}
				>
					{(
						withOverlayLabel
							? <CompanyFieldDisplayOverlayLabel {...overlayProps} />
							: <CompanyFieldDisplayOverlayIcon {...overlayProps} />
					)}
				</div>
			)}
		</div>
	);
};

export default CompanyFieldDisplay;
