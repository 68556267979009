import CompanyModel from '../../models/CompanyModel';
import ArticleModel from '../../models/ArticleModel';
import {
	getDBMetaTitle, getDBArticleMetaPublisher, getDBMetaAltImage, getDBMetaDescription,
} from './DBMetas';
import {
	getDefaultArticleMetaPublisher, getDefaultMetaAltImage, getDefaultMetaDescription, getDefaultMetaTitle,
} from './DefaultMetas';

/**
 * Returns the meta tag for the title of the metaHolder
 * If it does not exist in the database collection,
 * it will create a default one
 * @param {*} metaHolder ArticleModel or CompanyModel object
 * @param {*} locale en or fr
 * @returns
 */
export const getMetaTitle = (metaHolder, locale) => {
	checkType(metaHolder);
	return (
		getDBMetaTitle(metaHolder, locale)
        || getDefaultMetaTitle(metaHolder, locale)
	);
};

/**
 * Returns the meta tag for the title of the metaHolder
 * If it does not exist in the database collection,
 * it will create a default one
 * @param {*} metaHolder ArticleModel or CompanyModel  object
 * @param {*} locale en or fr
 * @returns
 */
export const getMetaDescription = (metaHolder, locale) => {
	checkType(metaHolder);
	return (
		getDBMetaDescription(metaHolder, locale)
        || getDefaultMetaDescription(metaHolder, locale)
	);
};

/**
 * Returns the meta tag for the publisher of the article
 * If it does not exist in the database collection,
 * it will create a default one
 * @param {*} articleModel Article object
 * @returns
 */
export const getMetaPublisher = (articleModel) => {
	checkType(articleModel);
	return (
		getDBArticleMetaPublisher(articleModel)
        || getDefaultArticleMetaPublisher()
	);
};

/**
 * Returns the meta tag for the alternative text of the image of the metaHolder
 * If it does not exist in the database collection,
 * it will create a default one
 * @param {*} metaHolder ArticleModel or CompanyModel  object
 * @param {*} locale en or fr
 * @returns
 */
export const getMetaAltImage = (metaHolder, locale) => {
	checkType(metaHolder);
	return (
		getDBMetaAltImage(metaHolder, locale)
        || getDefaultMetaAltImage(metaHolder, locale)
	);
};

/**
 * Throw an error if the metaHolder type is not expected
 * @param {*} metaHolder
 */
const checkType = (metaHolder) => {
	if (!(metaHolder instanceof CompanyModel) && !(metaHolder instanceof ArticleModel)) throw new Error('Type is incorrect: `metaHolder` expected to be instance of CompanyModel or ArticleModel');
};
