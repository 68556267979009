import { Form } from 'antd';
import { useEffect, useReducer, useState } from 'react';
import { LocalDebug } from '../../../utils/LocalDebug';
import UserService from '../../../services/user';
import FormBoxTitle from '../../app/form/FormBoxTitle';
import FormItem from '../form/FormItem';
import UserSelect from '../../user/UserSelect';
import UserStub from '../../user/stub/UserStub';
import FormSubmitButton from '../../app/form/FormSubmitButton';
import {
	formStateReducer, onFormFinish, onFormFinishFailed, onFormValuesChange,
} from './CompanySettingsFormHelper';
import { useDispatch } from 'react-redux';
import Switch from '../../app/form/Switch';
import { documentId } from '../../../utils/common';

const CompanySettingsNotificationsForm = (
	{
		company,
		onSuccess,
		labelCol = { span: 0 },
		wrapperCol = { span: 24 },
		...props
	},
) => {
	const className = 'CompanySettingsNotificationsForm';
	const formName = 'company-settings-notifications-form';

	const dispatch = useDispatch();

	const initialValues = {
		...company,
		shouldNotifyAllOnApplysIfNoRecipients: company?.shouldNotifyAllOnApplysIfNoRecipients !== false,
	};

	const [form] = Form.useForm();
	const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
	const [companyEdited, setCompanyEdited] = useState({ ...company });
	const [state, stateDispatch] = useReducer(formStateReducer({ form, setIsSubmitDisabled }));

	useEffect(() => {
		setCompanyEdited?.((prev) => ({ ...prev, ...form.getFieldsValue() }));
	}, [form]);

	const handleFetchManagers = async (options) => {
		const method = 'handleFetchManagers';
		LocalDebug.logInfo({ className, method }, { options });
		const { data: { items } } = await UserService.findAll({
			...options,
			disableHiddenCompanyIds: true,
			companyId: documentId(company),
			isManager: true,
		});
		return items;
	};

	return (
		<Form
			form={form}
			name={formName}
			initialValues={initialValues}
			labelCol={labelCol}
			wrapperCol={wrapperCol}
			labelAlign='right'
			layout='horizontal'
			onFinish={async (values) => {
				const result = await onFormFinish({
					id: documentId(company),
					values: { ...values, forceRecompute: false },
					formName,
					dispatch,
					stateDispatch,
				});
				onSuccess?.();
				return result;
			}}
			onFinishFailed={onFormFinishFailed}
			onValuesChange={(values) => {
				return onFormValuesChange({
					values, stateDispatch, setCompanyEdited,
				});
			}}
		>
			<FormBoxTitle
				title={<>📥 Notifications</>}
			/>

			{/* <FormItem style={{ marginBottom: 0 }}> */}
			{/*	<h3>Jobs with no notification recipients</h3> */}
			{/* </FormItem> */}

			<p>
					When a talent applies for a job offer for which <b>nobody subscribed</b> to receive
					notifications...
			</p>

			<FormItem
				name='shouldNotifyAllOnApplysIfNoRecipients'
				valuePropName='checked'
			>
				<Switch
					className='ant-switch-large'
					// before={
					// 	<>
					// 		When a talent applies for a job offer for which <b>nobody subscribed</b> to receive
					// 		notifications,
					// 	</>
					// }
					after={
						<>
								should be notified
						</>
					}
					checkedChildren={<b>&nbsp;all "Recruiter" and "Admin" users</b>}
					unCheckedChildren={<b>only a specific user&nbsp;</b>}
				/>
			</FormItem>

			{form?.getFieldValue('shouldNotifyAllOnApplysIfNoRecipients') === false
					&& <FormItem
						label='Default recipient'
						name='defaultRecipientForApplysIfNoRecipients'
						rules={[
							{
								required: !form.getFieldValue('shouldNotifyAllOnApplysIfNoRecipients'),
								message: 'You must select a user in \'Default applications recipient\'',
							},
						]}
						extra={
							<>
								The user above will be the <b>only one</b> notified
								when a candidate apply on a job for which <b>nobody subscribed
								to receive notifications</b>
							</>
						}
						// help={<>When candidates <b>apply on jobs for which no users have been set as notification
						// 	recipients</b>, the
						// 	user above will be the <b>only one</b> receiving a notification</>}
					>
						<UserSelect
							onFindUsers={handleFetchManagers}
							placeholder='Select a user'
							style={{ maxWidth: 400 }}
							defaultOptions={[]}
							labelizer={(user) => (
								<UserStub
									user={user}
									avatarSize={20}
									withLocation={false}
									withProfession={false}
									withCompanyLogo={false}
									withIsUnviewed={false}
									withCoachedTag={false}
									withResumeTag={false}
								/>
							)}
							// notUserIds={notUserIds}
						/>
					</FormItem>
			}

			<FormItem>
				<FormSubmitButton disabled={isSubmitDisabled}>
						Save notification settings
				</FormSubmitButton>
			</FormItem>

		</Form>
	);
};

export default CompanySettingsNotificationsForm;
