import GridValueCard from '../../../app/card/GridValueCard';
import UserResumeSummaryCardBody from './UserResumeSummaryCardBody';
import { NOT_PROVIDED_YET_LABEL } from '../../../../constants/constant';

const UserResumeSummaryCard = (
	{
		user,
		hideIfEmpty = true,
		notProvided = NOT_PROVIDED_YET_LABEL,
		children,
		...props
	},
) => {
	const title = 'Personal summary';
	const details = null;

	return (user?.hasResumeSummary() || !hideIfEmpty
		? <GridValueCard
			title={title}
			details={details}
			{...props}
		>
			{children || <UserResumeSummaryCardBody user={user} notProvided={notProvided}/>}
		</GridValueCard>
		: null
	);
};

export default UserResumeSummaryCard;
