export const SURVEY_COPY = {
	fr: {
		'survey-resume.title': 'Votre questionnaire vous attend! 🌟',
		'survey-resume.body': 'Votre progression a été enregistrée, vous pouvez reprendre là où vous en étiez.',
		'survey-resume.resume-btn': 'Reprendre mon questionnaire',
		'survey-resume.still': 'Il vous reste',
		'survey-resume.only': 'Plus que',
		'survey-resume.answers-left': ' {count} question{plural} à répondre',
		'survey-resume.all-answered': 'Vous avez répondu à tout, il ne reste qu\'a vérifier et soumettre!',

		'survey-filled.title': 'Votre questionnaire a bien été rempli! 💯',

		'leave-survey-btn': 'Quitter le questionnaire',
		'leave-modal.title': 'Vous êtes sur le point de quitter le questionnaire',
		'leave-modal.body': 'Votre progression sera enregistrée.\n\nVous pouvez revenir à tout moment via le menu de votre application.',
		'leave-modal.cancel': 'Rester sur le questionnaire',
		'leave-modal.confirm': 'Quitter',

		'question-answer': 'Réponse',
		'question-skip.intro': 'Si vous ne pouvez pas répondre ou si ce n\'est pas pertinent',
		'question-skip.you-can': 'vous pouvez',
		'question-skip.skip-btn': 'passer cette question',
		'question-skipped.intro': 'Vous avez **passé cette question**. Vous pouvez toujours y répondre si vous le souhaitez, ou bien',
		'question-skipped.skip-btn': 'la passer à nouveau',
		'survey-question.not-answered': 'Non répondue',

		'question-edit-btn.tooltip.answered': 'Vous avez **déjà répondu** à cette question, cliquez pour modifier votre réponse.',
		'question-edit-btn.tooltip.unanswered.mandatory': 'Cette question est **obligatoire**, cliquez pour répondre',
		'question-edit-btn.tooltip.unanswered.optional': 'Cette question est **optionelle**, cliquez si vous souhaitez répondre',

		'survey-header.next-required-btn': 'Question suivante',
		'survey-header.next-required.tooltip.title': 'Votre questionnaire est incomplet.',
		'survey-header.next-required.tooltip.body': 'Cliquez pour aller à la première question non encore répondue.',
		'survey-header.review-btn': 'Prêt à soumettre!',
		'survey-header.review-btn.tooltip.title': 'Votre questionnaire est complet!',
		'survey-header.review-btn.tooltip.body': 'Vérifiez vos réponses et soumettez-le.',

		'survey-progress.you-answered': 'Votre réponse',
		'survey-progress.you-skipped': 'Vous avez passé cette question.',
		'survey-progress.answer-mandatory': 'Cette question est obligatoire.',
		'survey-footer.answer-later-btn': 'Répondre plus tard',
		'survey-footer.previous-btn': 'Retour',
		'survey-footer.start-btn': 'Commencer',
		'survey-footer.save-btn': 'Enregistrer',

		'survey-review-answers.header': 'Votre questionnaire est prêt à être soumis.',
		'survey-review-answers.subtitle': `Merci pour votre temps !

Veuillez **vérifier toutes vos réponses** attentivement.
Une fois soumises, pas de retour en arrière !`,
		'survey-review-answers.submit-btn.complete': 'Soumettre mes réponses',
		'survey-review-answers.submit-btn.incomplete': 'Votre questionnaire est incomplet',
	},
	en: {
		'survey-resume.title': 'Your survey is waiting for you! 🌟',
		'survey-resume.body': 'Your progress was saved, you can pick up where you left off.',
		'survey-resume.resume-btn': 'Resume my survey',
		'survey-resume.still': 'You still have',
		'survey-resume.only': 'Only',
		'survey-resume.answers-left': ' {count} question{plural} to answer',
		'survey-resume.all-answered': 'You answered everything, just need to review and submit!',

		'survey-filled.title': 'Your survey has been filled! 💯',

		'leave-survey-btn': 'Leave survey',
		'leave-modal.title': 'You are about to leave the survey',
		'leave-modal.body': 'Your progress will be saved.\n\nYou can come back anytime through your app menu.',

		'question-answer': 'Answer',
		'question-skip.intro': 'If you cannot answer or if it\'s not relevant',
		'question-skip.you-can': 'you can',
		'question-skip.skip-btn': 'skip this question',
		'question-skipped.intro': 'You **skipped this question**. You can still answer it if you want, or',
		'question-skipped.skip-btn': 'skip it again',

		'question-edit-btn.tooltip.answered': 'This question has **already** been answered, click to modify.',
		'question-edit-btn.tooltip.unanswered.mandatory': 'This question is **mandatory**, click to answer',
		'question-edit-btn.tooltip.unanswered.optional': 'This question is **optional**, click if you want to answer',

		'survey-header.next-required-btn': 'Next required question',
		'survey-header.next-required.tooltip.title': 'Your survey is still incomplete.',
		'survey-header.next-required.tooltip.body': 'Click to go to the first unanswered question.',
		'survey-header.review-btn': 'Review & Submit',
		'survey-header.review-btn.tooltip.title': 'Your survey is complete!',
		'survey-header.review-btn.tooltip.body': 'Review your answers and submit them.',

		'survey-progress.you-answered': 'You answered',
		'survey-progress.you-skipped': 'You skipped this question.',
		'survey-progress.answer-mandatory': 'Your answer to this question is required.',
		'survey-footer.answer-later-btn': 'Answer later',
		'survey-footer.previous-btn': 'Previous',
		'survey-footer.start-btn': 'Start',
		'survey-footer.save-btn': 'Save answer',

		'survey-review-answers.header': 'Your survey is ready for submission.',
		'survey-review-answers.subtitle': `Thank you for your time!

Please **review all your answers** carefully.
Once you submit them, no backsies!`,
		'survey-review-answers.submit-btn.complete': 'Submit your answers',
		'survey-review-answers.submit-btn.incomplete': 'Your survey is incomplete',
	},
};

const exporter = { SURVEY_COPY };
export default exporter;
