import { render } from 'react-dom';
import { applyMiddleware, combineReducers, createStore } from 'redux';
import {
	createRouterMiddleware, createRouterReducer, ReduxRouter,
} from '@lagunovsky/redux-react-router';
import thunk from 'redux-thunk';
import { ThemeProvider } from '@emotion/react';
import { Provider } from 'react-redux';

import './scss/index.scss';
import reportWebVitals from './reportWebVitals';
import reduxDevToolsComposeEnhancer from './reduxDevTools';

import { showError } from './actions/app';
import appReducers from './reducers';
import theme from './theme';
import Routes from './Routes';
import AuthProvider from './contexts/AuthProvider';
import SocketProvider from './contexts/SocketProvider';
import CandidateProvider from './contexts/CandidateProvider';

// import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import UserSearchFiltersProvider from './contexts/UserSearchFiltersProvider';
import NotificationProvider from './components/app/notification/NotificationProvider';
import AlertProvider from './contexts/AlertProvider';
import browserHistory, { history } from './browserHistory';
import AppDataProvider from './contexts/AppDataProvider';
import CompanyProvider from './contexts/CompanyProvider';
import OnboardingProvider from './contexts/OnboardingProvider';
import PermissionProvider from './contexts/PermissionProvider';
import { ConfigProvider } from 'antd';
import { writeRecruiterLog } from './actions/logging';
import { COMPANY_RECRUITER_LOG } from './constants/company-recruiter-log';
import { LocalDebug } from './utils/LocalDebug';
import UserStoreContext from './contexts/UserStoreProvider';
import StaffToolsProvider from './contexts/StaffToolsProvider';
import CompanyPageProvider from './components/company/companyPage/shared/CompanyPageProvider';
import SurveyProvider from './components/genderscore/survey/SurveyProvider';
import EventPushProvider from './contexts/EventPushProvider';
import UMSSurveyProvider from './components/ums/survey/providers/SurveyProvider';
import GenderScoreResourceProvider from './contexts/GenderScoreResourceProvider';

const className = 'index';

const initApp = async () => {
	const routerMiddleware = createRouterMiddleware(history);

	// ---- reducer ----//
	const reducer = combineReducers({
		...appReducers,
		navigator: createRouterReducer(history),
	});

	// get navigator state
	const routerSelector = (state) => state.navigator;

	// -------------------------------------------------------------------------------//
	// -------------------------- store and middleware -------------------------------//
	// -------------------------------------------------------------------------------//
	// taken from https://redux.js.org/advanced/middleware
	const crashReporter = (store) => (next) => (action) => {
		// utils/LocalDebug('crashReporter');
		try {
			return next(action);
		} catch (error) {
			console.error(error);
			showError(error.message)(store.dispatch);
		}
	};
	const nullMiddleware = (store) => (next) => (action) => {
		if (action == null) {
			return;
		}
		return next(action);
	};

	const store = createStore(reducer, reduxDevToolsComposeEnhancer(applyMiddleware(routerMiddleware, crashReporter, nullMiddleware, thunk)));

	// ------------------------------------------- //
	// ----------- history listener -------------- //
	// ------------------------------------------- //
	history.listen((route) => {
		LocalDebug.logNull({ className, method: 'history.listen' }, route);

		if (['PUSH', 'POP'].includes(route?.action)) {
			const { action, location } = route;
			// in history V6, action and query are missing in history.location object. So,  If we need to store these in location, we must to customize it
			writeRecruiterLog(
				COMPANY_RECRUITER_LOG.DISPLAY,
				{ location: { ...location, action, query: browserHistory.parseQueryParamsToObject() } },
			);
		}
	});

	render(
		<>
			<Provider store={store}>
				<ConfigProvider
					theme={{
						token: {
							// Seed Token
							fontFamily: 'DM Sans',
						},
					}}>
					<ThemeProvider theme={theme}>
						<AppDataProvider>
							<UserStoreContext>
								<CompanyProvider>
									<GenderScoreResourceProvider>
										<NotificationProvider>
											<AuthProvider>
												<EventPushProvider>
													<SurveyProvider>
														<UMSSurveyProvider>
															<PermissionProvider>
																<StaffToolsProvider>
																	<AlertProvider>
																		<SocketProvider>
																			<CandidateProvider>
																				<UserSearchFiltersProvider>
																					<OnboardingProvider>
																						<CompanyPageProvider>

																							<ReduxRouter
																								history={history}
																								routerSelector={routerSelector}
																							>
																								<Routes
																									history={history}
																								/>
																							</ReduxRouter>

																						</CompanyPageProvider>
																					</OnboardingProvider>
																				</UserSearchFiltersProvider>
																			</CandidateProvider>
																		</SocketProvider>
																	</AlertProvider>
																</StaffToolsProvider>
															</PermissionProvider>
														</UMSSurveyProvider>
													</SurveyProvider>
												</EventPushProvider>
											</AuthProvider>
										</NotificationProvider>
									</GenderScoreResourceProvider>
								</CompanyProvider>
							</UserStoreContext>
						</AppDataProvider>
					</ThemeProvider>
				</ConfigProvider>
			</Provider>
		</>,
		document.getElementById('root'),
	);
};

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals

initApp();
reportWebVitals();

// serviceWorkerRegistration.unregister();
// serviceWorkerRegistration.register({
// 	onUpdate: registration => {
// 		const waitingServiceWorker = registration.waiting;
// 		if (waitingServiceWorker) {
// 			waitingServiceWorker.addEventListener("statechange", event => {
//
// 				if (event.target.state === "activated") {
// 					Modal.info({
// 						title: 'New version available',
// 						content: 'Please click OK to refresh the page',
// 						okText: 'OK',
// 						cancelText: 'Cancel',
// 						onOk: () => {
// 							window.location.reload();
// 						}
// 					});
// 				}
// 			});
// 			registration.waiting.postMessage({ type: "SKIP_WAITING" });
// 		}
// 	}
// });
