import { useTheme } from '@emotion/react';
import Table from '../app/table/Table';
import { useAuthContext } from '../../hooks/useAuthContext';
import Link from '../app/Link';
import TableColumns from '../app/table/TableColumns';
import {
	Button, Input, Select, Space,
} from 'antd';
import FormItem from '../company/form/FormItem';
import { useEffect, useState } from 'react';
import { LocalDebug } from '../../utils/LocalDebug';
import AccessService from '../../services/access';
import ReactJsonDebug from '../app/debug/ReactJsonDebug';
import { documentId } from '../../utils/common';

const InputWithHelp = ({ onChange, setFilterOptions }) => {
	const [tagValue, setTagValue] = useState();
	return (
		<Space direction='vertical'>
			<Input value={tagValue} onChange={onChange}/>
			<Space>
				<Link onClick={() => {
					setTagValue('50intech');
					setFilterOptions('50intech');
				}}>50intech</Link>
				<Link onClick={() => {
					setTagValue('50inTech');
					setFilterOptions('50inTech');
				}}>50inTech</Link>
			</Space>
		</Space>
	);
};

const AtsOpportunitiesTable = (
	{
		companyAccess,
		isRefresh = 0,
		...props
	},
) => {
	const className = 'AtsOpportunitiesTable';
	const theme = useTheme();

	const { isStaffView } = useAuthContext();
	const [formOptions, setFormOptions] = useState({});
	const [filterOptions, setFilterOptions] = useState({});
	const [stageOptions, setStageOptions] = useState([]);
	// const [tagValue, setTagValue] = useState(null);

	const onChangeFilters = (values) => {
		LocalDebug.logInfo({ className, method: 'onChangeFilters' }, { values });
		setFormOptions((p) => ({ ...p, ...values }));
	};

	const handleFilter = () => {
		setFilterOptions(formOptions);
	};

	useEffect(async () => {
		const { data: { items: stages } } = await AccessService.getStages(documentId(companyAccess));
		setStageOptions(stages.map((stage) => {
			const { id, text } = stage;
			// return stage;
			return { value: id, label: text };
		}));
	}, []);
	const allCol = TableColumns.jsonColumn(theme, { dataIndex: '_id' });
	// {
	// 	title: 'all',
	// 	dataIndex: '_id',
	// 	render: (value, row) => <Popover title='JSON' content={
	// 		<div style={{ width: 500, height: '50vh', overflow: 'scroll' }}>
	// 			<JSONPretty data={row}/>
	// 		</div>
	// 	}>JSON</Popover>,
	// 	...TableColumns.columnFixedWidth(50),
	// }

	const oppUserCol = {
		title: 'ATS Contact',
		dataIndex: '_id',
		render: (value, row) => {
			return (
				<Space direction='vertical' size={0}>
					<div style={{ fontSize: 14 }}><b>{row.name}</b></div>
					<div style={{ fontSize: 10 }}>{row.headline} ({row.location})</div>
					<div>{row.emails.map((e, i) => <span key={i} style={{
						fontSize: 10, color: theme.color.fitBlueElectric, textDecoration: 'underline',
					}}>{e}</span>)}</div>
					{/* <span style={{color: theme.color.darkgrey}}>Created on {TableColumns.dateColumn(theme).render(new Date(row.createdAt))}</span> */}
				</Space>
			);
		},
		...TableColumns.columnFixedWidth(200),
	};

	const userStubCol = {
		title: 'Candidate',
		render: (value, row) => {
			// localDebug('row.user', row.user);
			if (row.user) return TableColumns.userStubColumn(theme).render(row.user);
			return <Space direction='vertical' size={0}>
				<div style={{ fontSize: 14 }}><b>{row.name}</b></div>
				<div style={{ fontSize: 10 }}>{row.headline} ({row.location})</div>
				<div>{row?.emails?.map((e, i) => <span key={i} style={{
					fontSize: 10, color: theme.color.fitBlueElectric, textDecoration: 'underline',
				}}>{e}</span>)}</div>
				{/* <span style={{color: theme.color.darkgrey}}>Created on {TableColumns.dateColumn(theme).render(new Date(row.createdAt))}</span> */}
			</Space>;
		},
		...TableColumns.columnFixedWidth(260),
	};

	const oppJobCol = {
		title: 'Job',
		dataIndex: 'jobs',
		render: (value, row) => (value?.length
			? <Space direction='vertical' size={4}>
				{value.map((job, index) => <div key={index}>{TableColumns.jobStubColumn(theme, { withCompany: false }).render(job)}</div>)}
			</Space>
			: null),
		...TableColumns.columnFixedWidth(200),
	};

	const oppOwnerCol = {
		title: 'Manager',
		dataIndex: 'owner',
		render: (value, row) => {
			if (!value) return null;
			const { user } = value;
			if (user) {
				return TableColumns.userStubColumn(theme).render(user);
			}
			return <Space direction='vertical' size={0}>
				<div style={{ fontSize: 14 }}><b>{value.name}</b></div>
				<div style={{ fontSize: 10 }}>{value.accessRole}</div>
				{/* <div style={{fontSize: 10}}>{row.headline} ({row.location})</div> */}
				{/* <div>{row.emails.map((e, i) => <span key={i} style={{fontSize: 10, color: theme.color.fitBlueElectric, textDecoration: 'underline'}}>{e}</span>)}</div> */}
				{/* <span style={{color: theme.color.darkgrey}}>Created on {TableColumns.dateColumn(theme).render(new Date(row.createdAt))}</span> */}
			</Space>;
			// value?.user<JSONPretty data={value} />,
		},
		...TableColumns.columnFixedWidth(200),
	};

	const oppStageCol = {
		title: 'Stage',
		dataIndex: 'stage',
		// render: (value, row) => <JSONPretty data={value} />,
		render: (value, row) => <Space direction='vertical' size={0}>
			<div><b>{value?.text}</b></div>
			<code style={{ fontSize: 8, color: theme.color.grey }}>{documentId(value)}</code>
		</Space>,
		...TableColumns.columnFixedWidth(150),
	};

	const oppArchivedCol = {
		title: 'Archived',
		dataIndex: 'archived',
		render: (value, row) => {
			if (!value) return null;
			return <Space direction='vertical' size={0}>
				<div><b>{value.reasonText}</b></div>
				{/* <div>{value.reason}</div> */}
				<span
					style={{ color: theme.color.darkgrey }}>Archived on {TableColumns.dateColumn(theme).render(new Date(value.archivedAt))}</span>
			</Space>;
		},
		...TableColumns.columnFixedWidth(140),
	};

	const oppCreatedAtCol = {
		title: 'Created',
		dataIndex: 'createdAt',
		render: (value, row) => TableColumns.dateColumn(theme).render(new Date(row.createdAt)),
		...TableColumns.columnFixedWidth(80),
	};

	const columns = isStaffView
		? [
			allCol,
			// oppUserCol,
			userStubCol,
			oppJobCol,
			oppOwnerCol,
			oppStageCol,
			oppArchivedCol,
			oppCreatedAtCol,
		]
		: [
			oppUserCol,
			oppJobCol,
			oppOwnerCol,
			oppStageCol,
			oppArchivedCol,
			oppCreatedAtCol,
		];

	return (companyAccess
		&& <>
			<Space size={[20, 0]} align='top' wrap={true} style={{ width: '100%' }}>
				<FormItem label='Stages' layout='vertical' style={{ width: 200 }}>
					<Select
						placeholder='Stage options'
						mode='multiple'
						options={stageOptions}
						onChange={(v) => onChangeFilters({ stage: v })}
					/>
				</FormItem>
				<FormItem label='Expand' layout='vertical' style={{ width: 200 }}>
					<Select
						placeholder='Expand options'
						mode='multiple'
						options={[
							{ value: 'applications', label: 'Applications' },
							{ value: 'stage', label: 'Stage' },
							{ value: 'owner', label: 'Owner' },
							{ value: 'followers', label: 'Followers' },
							{ value: 'sourcedBy', label: 'Sourced by' },
							{ value: 'contact', label: 'Contact' }]}
						onChange={(v) => onChangeFilters({ expand: v })}
					/>
				</FormItem>
				<FormItem label='Source' layout='vertical' style={{ width: 200 }}>
					<Input onChange={(e) => {
						onChangeFilters({ source: e?.target?.value });
					}}/>
				</FormItem>
				<FormItem label='Tag' layout='vertical' style={{ width: 200 }}>
					<InputWithHelp
						setFilterOptions={(v) => setFormOptions((p) => ({ ...p, tag: v }))}
						onChange={(e) => onChangeFilters({ tag: e?.target?.value })}/>
				</FormItem>
			</Space>
			<Button type='primary' onClick={handleFilter}><b>Filter</b></Button>
			<ReactJsonDebug src={{ formOptions, filterOptions }} collapsed={0}/>

			{/* <Space> */}
			{/*    Expand */}
			{/*    </Space> */}
			{/* <Space> */}
			{/*    <Tag: <Input /> */}
			{/* </Space> */}

			{/* tag */}
			{/* San Francisco */}
			{/* Optional */}
			{/* Filter Opportunities by tag (case sensitive). Results will include Opportunities that contain the */}
			{/* specified tag. Multiple tags can be specified and results will include a union of result sets (i.e. */}
			{/* Opportunities that have either tag). */}
			{/* email */}
			{/* shane@exampleq3.com */}
			{/* Optional */}
			{/* Filter Opportunities by an email address. Results will include Opportunities for Contacts that contain */}
			{/* the canonicalized email address. */}
			{/* origin */}
			{/* sourced */}
			{/* Optional */}
			{/* Filter Opportunities by origin. Results will include Opportunities that contain the specified origin. */}
			{/* Multiple origins can be specified and results will include a union of result sets (i.e. Opportunities */}
			{/* from either origin). */}
			{/* source */}
			{/* Optional */}
			{/* Filter Opportunities by source. Results will include Opportunities that contain the specified source */}
			{/* tag. Multiple sources can be specified and results will include a union of result sets (i.e. */}
			{/* Opportunities from either source). */}
			{/* confidentiality */}
			{/* confidential, non-confidential, all */}
			{/* Optional */}
			{/* Filter opportunities by confidentiality. If unspecified, defaults to non-confidential. To get both */}
			{/* confidential and non-confidential opportunities you must specify all. Learn more about confidential data */}
			{/* in the API. */}
			{/* stage_id */}
			{/* fff60592-31dd-4ebe-ba8e-e7a397c30f8e */}
			{/* Optional */}
			{/* Filter Opportunities by current stage. Results will include Opportunities that are currently in the */}
			{/* specified stage. Multiple stages can be specified and results will include a union of result sets (i.e. */}
			{/* Opportunities that are in either stage). */}
			{/* posting_id */}
			{/* f2f01e16-27f8-4711-a728-7d49499795a0 */}
			{/* Optional */}
			{/* Filter Opportunities by posting. Results will include Opportunities that are applied to the specified */}
			{/* posting. Multiple postings can be specified and results will include a union of result sets (i.e. */}
			{/* Opportunities that are applied to either posting). */}
			{/* archived_posting_id */}
			{/* f2f01e16-27f8-4711-a728-7d49499795a0 */}
			{/* Optional */}
			{/* Filter Opportunities by postings for which they have been archived. Results will include opportunities */}
			{/* for candidates that applied to the specified posting and then the application was archived. Multiple */}
			{/* postings can be specified and results will include a union of result sets (i.e. Opportunities that were */}
			{/* applied to either posting). */}
			{/* created_at_start, created_at_end */}
			{/* 1407460069499 */}
			{/* Optional */}
			{/* Filter Opportunities by the timestamp they were created. If only created_at_start is specified, all */}
			{/* Opportunities created from that timestamp (inclusive) to the present will be included. If only */}
			{/* created_at_end is specified, all Opportunities created before that timestamp (inclusive) are included. */}
			{/* updated_at_start, updated_at_end */}
			{/* 1407460069499 */}
			{/* Optional */}
			{/* Filter Opportunities by the timestamp they were last updated. If only updated_at_start is specified, all */}
			{/* Opportunities updated from that timestamp (inclusive) to the present will be included. If only */}
			{/* updated_at_end is specified, all Opportunities updated before that timestamp (inclusive) are included. */}
			{/* advanced_at_start, advanced_at_end */}
			{/* 1407460069499 */}
			{/* Optional */}
			{/* Filter Opportunities by the timestamp they were advanced to their current stage. If only */}
			{/* advanced_at_start is specified, all Opportunities advanced from that timestamp (inclusive) to the */}
			{/* present will be included. If only advanced_at_end is specified, all Opportunities advanced before that */}
			{/* timestamp (inclusive) are included. */}
			{/* archived_at_start, archived_at_end */}
			{/* 1407460069499 */}
			{/* Optional */}
			{/* Filter Opportunities by the timestamp they were archived. If only archived_at_start is specified, all */}
			{/* Opportunities archived from that timestamp (inclusive) to the present will be included. If only */}
			{/* archived_at_end is specified, all Opportunities archived before that timestamp (inclusive) are included. */}
			{/* archived */}
			{/* true */}
			{/* Optional */}
			{/* Filter Opportunities by archive status. If unspecified, results include both archived and unarchived */}
			{/* Opportunities. If true, results only include archived Opportunities. If false, results only include */}
			{/* active Opportunities. */}
			{/* archive_reason_id */}
			{/* 63dd55b2-a99f-4e7b-985f-22c7bf80ab42 */}
			{/* Optional */}
			{/* Filter Opportunities by archive reason. Results will include Opportunities that have been archived with */}
			{/* the specified reason. Multiple archive reasons can be specified and results will include a union of */}
			{/* result sets (i.e. Opportunities that have been archived for either reason). */}
			{/* snoozed */}
			{/* true */}
			{/* Optional */}
			{/* Filter Opportunities by snoozed status. If unspecified, results include both snoozed and unsnoozed */}
			{/* Opportunities. If true, results only include snoozed Opportunities. If false, results only include */}
			{/* unsnoozed Opportunities. */}
			{/* contact_id */}
			{/* 7f23e772-f2cb-4ebb-b33f-54b872999992 */}
			{/* Optional */}
			{/* Filter Opportunities by contact. Results will include the Opportunities that match the s */}
			{/* </Space> */}
			<Table
				path={`access/${documentId(companyAccess)}/ats-opportunities`}
				filterOptions={filterOptions}
				columns={columns}
				isRefresh={isRefresh}
				withPagination={false}
				rowClassName={(row, index) => (row.archived !== null ? 'rowDisabled' : '')}
				{...props}
			/>
		</>
	);
};

export default AtsOpportunitiesTable;
