import { ENGLISH_LANG } from '../../../../constants/constant';
import CompanyFieldNotProvided from '../shared/CompanyFieldNotProvided';
import { useTheme } from '@emotion/react';
import { FaCog, FaMapPin, FaUsers } from 'react-icons/fa';

const CompanyHeadquartersDisplay = (
	{
		company,
		lang = ENGLISH_LANG,
	},
) => {
	const className = 'CompanyHeadquartersDisplay';

	const theme = useTheme();

	const field = 'headquarters';
	const value = company?.getTranslationFieldFallback(field, lang);

	if (!value) return <CompanyFieldNotProvided>Headquarters</CompanyFieldNotProvided>;

	return (
		<div
			style={{
				fontSize: 12,
				color: theme.color.grey,
			}}
		>
			<FaMapPin style={{ marginBottom: -2 }}/> {value}
		</div>
	);
};

export default CompanyHeadquartersDisplay;
