import { message, Tooltip } from 'antd';
import { FaRegCopy } from 'react-icons/fa';
import Link from '../../app/Link';
import UserModel from '../../../models/UserModel';
import { useAuthContext } from '../../../hooks/useAuthContext';

const UserLinkPageCopy = (
	{
		user,
		label,
		withLabel = true,
		iconPlacement = 'left',
		...props
	},
) => {
	const { currentUser } = useAuthContext();

	if (!user) {
		return null;
	}

	const path = new UserModel(user).getProfileUrl(currentUser);

	return (
		<Tooltip title={path}>
			<Link
				onClick={() => {
					navigator.clipboard.writeText(path);
					message.success(<>The direct link to this user's profile (<b>{path}</b>) has been copied to your
						clipboard</>);
				}}
				style={{
					fontSize: 11,
					...props?.style,
				}}
			>
				{iconPlacement === 'left' && (
					<>
						<FaRegCopy style={{ marginBottom: -2 }} />
						{label && <>&nbsp;</>}
					</>
				)}
				{label}
				{iconPlacement === 'right' && (
					<>
						{label && <>&nbsp;</>}
						<FaRegCopy style={{ marginBottom: -2 }} />
					</>
				)}
			</Link>
		</Tooltip>
	);
};

export default UserLinkPageCopy;
