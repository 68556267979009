import { Space } from 'antd';
import { useTheme } from '@emotion/react';

const CompanyRelevantDataLine = (
	{
		data,
		style = {},
	},
) => {
	const className = 'CompanyRelevantDataLine';

	const theme = useTheme();
	return (
		<Space
			size={20}
			style={{
				width: '100%',
				alignItems: 'center',
				justifyContent: 'space-between',
				minHeight: 62,
				// maxWidth: 240,
				lineHeight: 1.2,
				...style,
			}}
		>
			<Space
				direction='vertical'
				size={4}
			>
				<div
					style={{
						color: theme.color.darkgrey,
						fontSize: 14,
						fontWeight: 'bold',
					}}
				>
					{data.label}
				</div>
				{data.sublabel && (
					<div
						style={{
							color: theme.color.accent,
							fontSize: 10,
						}}
					>
						{data.sublabel}
					</div>
				)}
			</Space>
			<div
				style={{
					color: theme.color.accent,
					fontSize: 22,
					lineHeight: 1.1,
					fontWeight: 'bold',
					textAlign: 'right',
					whiteSpace: 'nowrap',
					// minWidth: 100,
					// border: '1px solid red',
				}}
			>
				{data.value}
			</div>
		</Space>
	);
};

export default CompanyRelevantDataLine;
