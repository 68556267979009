import { useTheme } from '@emotion/react';
import {
	Button, Col, Divider, Form, Row,
} from 'antd';
import { useEffect } from 'react';
import {
	EXTRA_META_DESCRIPTION,
	EXTRA_META_TITLE,
	META_DESCRIPTION_MAX_LENGTH,
	META_TITLE_MAX_LENGTH,
} from '../../../../constants/constant';
import FormItem from '../../../app/form/FormItem';
import InputMeta from './InputMeta';
import { documentId, requiredTrimRule } from '../../../../utils/common';

const ArticleMetaForm = ({ initialValues, onSubmit, onReset }) => {
	const [form] = Form.useForm();
	const theme = useTheme();

	const handleSubmit = (values) => {
		const { meta } = values;
		const toDb = {
			meta: {
				...meta,
				isCustom: true,
			},
			id: documentId(initialValues),
		};
		onSubmit(toDb);
	};

	useEffect(() => {
		form.resetFields();
		form.setFieldsValue(initialValues);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [initialValues]);
	return (
		<Form
			form={form}
			name="article-meta-form"
			initialValues={{ ...initialValues }}
			onFinish={handleSubmit}
		>

			<div style={{ overflow: 'auto', maxHeight: '50vh' }}>

				<div style={{
					background: '#f5f5f5',
					border: '1px solid #eee',
					borderRadius: 4,
					padding: '8px 12px',
					marginBottom: 20,
				}}>
					<span style={{ fontSize: 18, fontWeight: 700 }}>Global</span>
					<Divider style={{ marginTop: 8 }}/>
					<FormItem
						label="Publisher"
						name={['meta', 'publisher']}
						defaultContainer="div"
						styles={{
							rootContainer: { display: 'flex', flexDirection: 'row', width: '100%' },
							label: { marginTop: 6, width: 140 },
							container: { width: '100%' },
						}}
					/>
				</div>

				<div style={{
					background: '#f5f5f5',
					border: '1px solid #eee',
					borderRadius: 4,
					padding: '8px 12px',
					marginBottom: 20,
				}}>
					<span style={{ fontSize: 18, fontWeight: 700 }}>🇬🇧 English</span>
					<Divider style={{ marginTop: 8 }}/>
					<InputMeta
						label="Title"
						name={['meta', 'title']}
						extra={EXTRA_META_TITLE}
						maxLength={META_TITLE_MAX_LENGTH}
						rules={[requiredTrimRule]}

					/>
					<InputMeta
						label="Description"
						name={['meta', 'description']}
						extra={EXTRA_META_DESCRIPTION}
						maxLength={META_DESCRIPTION_MAX_LENGTH}
						style={{ minHeight: 100 }}
						isTextarea
					/>
				</div>

				<div style={{
					background: '#f5f5f5',
					border: '1px solid #eee',
					borderRadius: 4,
					padding: '8px 12px',
					marginBottom: 20,
				}}>
					<span style={{ fontSize: 18, fontWeight: 700 }}>🇫🇷 Français</span>
					<Divider style={{ marginTop: 8 }}/>
					<InputMeta
						label="Title"
						name={['meta', 'fr', 'title']}
						extra={EXTRA_META_TITLE}
						maxLength={60}

					/>
					<InputMeta
						label="Description"
						name={['meta', 'fr', 'description']}
						extra={EXTRA_META_DESCRIPTION}
						maxLength={220}
						style={{ minHeight: 100 }}
						isTextarea
					/>
				</div>

			</div>
			<Divider/>
			<Row>
				<Col span={24}>
					<div style={{
						display: 'flex',
						justifyContent: 'space-between',
						margin: -5,
						width: 'calc(100% + 10px)',
					}}>
						<Button type="default" style={{
							margin: 5,
							borderColor: theme.color.orange,
							fontWeight: 'bold',
							borderRadius: 5,
							color: theme.color.orange,
						}}
						htmlType="reset"
						onClick={onReset}
						>
							Cancel
						</Button>
						<Button type="primary"
							style={{
								margin: 5,
								background: theme.color.blue,
								borderColor: theme.color.blue,
								fontWeight: 'bold',
								borderRadius: 5,
							}}
							htmlType="submit"
						>
							Save
						</Button>
					</div>
				</Col>
			</Row>

		</Form>
	);
};

export default ArticleMetaForm;
