import { useContext, useEffect, useState } from 'react';
import { Button } from 'antd';
import { useTheme } from '@emotion/react';
import { PermissionContext } from '../../../contexts/PermissionProvider';
import { CompanyContext } from '../../../contexts/CompanyProvider';

const UpgradePlanButton = ({
	...props
}) => {
	const className = 'UpgradePlanButton';

	const theme = useTheme();

	const { setOpenUpgradePlanPopin } = useContext(PermissionContext);

	const {
		upgradeRequested,
	} = useContext(CompanyContext);

	const [buttonLabel, setButtonLabel] = useState('Upgrade plan');
	const [buttonStyle, setButtonStyle] = useState({ danger: true, ghost: true });

	useEffect(() => {
		if (upgradeRequested) {
			setButtonLabel('Upgrade requested');
			setButtonStyle({ type: 'dashed', disabled: true });
		} else {
			setButtonLabel('Upgrade plan');
			setButtonStyle({ danger: true, ghost: true });
		}
	}, [upgradeRequested]);

	return (
		<>
			<Button
				type='primary'
				// danger
				{...buttonStyle}
				// ghost
				style={{
					width: '100%',
					boxShadow: 'none',
					...props?.style,
				}}
				onClick={() => setOpenUpgradePlanPopin(true)}
			>
				<b>{buttonLabel}</b>
			</Button>
		</>
	);
};

export default UpgradePlanButton;
