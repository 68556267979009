import { Form } from 'antd';
import { useEffect, useState } from 'react';
import moment from 'moment';
import FormItem from '../app/form/FormItem';
import FormSelect from '../app/form/FormSelect';
import Modal from '../app/modal/Modal';
import {
	getNextSourcingValueByAction, getSourcingActionOptions, capitalize, documentId,
	requiredRule,
} from '../../utils/common';
import { localDebug } from '../../utils/LocalDebug';
import { treatSourcingActionToDb } from '../../actions/sourcings';
import {
	sourcingArchiveReasons,
	SOURCING_ACTION_STAGE,
	STOP_STATUS,
	SOURCING_ACTION_ARCHIVE,
} from '../../constants/constant';
import { useAuthContext } from '../../hooks/useAuthContext';
import ModalContainerBase from '../../contexts/modals/ModalContainerBase';
import FormBoxTitle from '../app/form/FormBoxTitle';

const ModalConfirmAction = ({
	initialValues,
	open,
	onClose,
	action,
	onConfirm,
}) => {
	const [form] = Form.useForm();
	const { currentUser } = useAuthContext();

	const [options, setOptions] = useState([]);
	const [nextValueSelected, setNextValueSelected] = useState({});
	const [isStop, setIsStop] = useState(false);

	useEffect(() => {
		// localDebug('ModalConfirmAction', { action, test: action && ![SOURCING_ACTION_STAGE, SOURCING_ACTION_ARCHIVE].includes(action.type) });
		if (action && ![SOURCING_ACTION_STAGE, SOURCING_ACTION_ARCHIVE].includes(action.type)) {
			// localDebug('ModalConfirmAction, passed test!')
			const nextValue = getNextSourcingValueByAction(action.value, action.type);
			const options = getSourcingActionOptions(action.value, action.type);
			setNextValueSelected(nextValue);
			setIsStop(nextValue.value === STOP_STATUS);
			setOptions(options);
			form.setFieldsValue({ 'payload.value': nextValue.value });
		}
	}, [action, form]);

	const handleChangeAction = (value, option) => {
		setNextValueSelected(option);
		setIsStop(value === STOP_STATUS);
	};

	const saveAction = (values) => {
		const history = {
			action: action?.type,
			creator: documentId(currentUser),
			postedAt: moment(),
			author: documentId(currentUser),
			...values,
		};

		const toDb = treatSourcingActionToDb({
			id: documentId(initialValues),
			status: initialValues?.status,
			...history,
		}, currentUser);

		onConfirm(toDb, action?.type);
	};

	return (
		<ModalContainerBase
			open={open}
			onCancel={onClose}
			onOk={() => {
				form
					.validateFields()
					.then((values) => {
						saveAction(values);
						form.resetFields();
					})
					.catch((info) => {
						localDebug('Validate Failed:', info);
					});
			}}
		>
			<FormBoxTitle
				title={'Edit a candidacy'}
			/>

			<Form
				form={form}
				layout="vertical"
				name="form_in_modal"
				initialValues={{ ...initialValues }}
			>
				<FormItem
					label={capitalize(action?.type)}
					defaultContainer="div"
					name="payload.value"
					rules={[requiredRule]}
					styles={{
						rootContainer: {
							padding: '0 10px',
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'flex-start',
						},
						label: { marginBottom: 0, paddingRight: 20, width: 100 },
						itemContainer: { marginBottom: 10 },
						container: { flex: 1 },
					}}
				>
					<FormSelect
						options={options}
						placeholder={capitalize(action?.type)}
						onChange={handleChangeAction}
					/>
				</FormItem>
				{
					isStop && <FormItem
						label="Archive Reasons"
						defaultContainer="div"
						name="payload.archiveReasons"
						rules={[requiredRule]}
						styles={{
							rootContainer: {
								padding: '0 10px',
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'flex-start',
							},
							label: { marginBottom: 0, paddingRight: 20, width: 100 },
							itemContainer: { marginBottom: 10 },
							container: { flex: 1 },
						}}
					>
						<FormSelect
							options={sourcingArchiveReasons.map((r) => ({ label: r, value: r }))}
							placeholder="Archive reason"
							defaultValue={initialValues?.payload?.archiveReasons}
							mode="tags"
						/>
					</FormItem>
				}
			</Form>
		</ModalContainerBase>
	);
};

export default ModalConfirmAction;
