import { useTheme } from '@emotion/react';
import {
	Button, Col, Form, Input, Row, Space,
} from 'antd';
import { useState, useEffect } from 'react';
import { FiSend } from 'react-icons/fi';
import { useSelector } from 'react-redux';

import { aclRoles } from '../../constants/constant';
import { useAuthContext } from '../../hooks/useAuthContext';
import { getCompanies, getCompanySelected } from '../../reducers/app';
import {
	documentId, getPermissionOptionsByUser, inArray, requiredRule,
	requiredTrimRule,
} from '../../utils/common';
import FormItem, { FormItemContainer } from '../app/form/FormItem';
import FormAclRoleSelect from '../app/form/FormAclRoleSelect';
import UserStub from '../user/stub/UserStub';
import FormCompanySelect from '../app/form/FormCompanySelect';
import Box from '../app/box/Box';
import ReactJsonDebug from '../app/debug/ReactJsonDebug';

const AclAddForm = (
	{
		// form,
		initialValues = {},
		onInviteUser,
		onCompanyChange,
		onSubmit,
		onReset,
		withInviteUser = false,
		withSubmitButton = false,
		children,
	},
) => {
	const theme = useTheme();
	const companySelected = useSelector(getCompanySelected);
	const companies = useSelector(getCompanies);

	const {
		currentUser, isStaff, isStaffView, companyIdsUser,
	} = useAuthContext();

	const [form] = Form.useForm();
	// local state
	const [roleOptions, setRoleOptions] = useState([]);
	const [roleSelected, setRoleSelected] = useState(null);
	const isExist = initialValues?.isExist;

	useEffect(() => {
		const options = getPermissionOptionsByUser(aclRoles, currentUser);
		setRoleOptions(options);
	}, [currentUser]);

	useEffect(() => {
		if (!isStaff) {
			onCompanyChange(companyIdsUser?.[0]);
		}
	}, [companyIdsUser, isStaff, onCompanyChange]);

	useEffect(() => {
		onCompanyChange(documentId(companySelected));
	}, [companySelected]);

	return (<>
		<Form
			form={form}
			name="acl_form"
			initialValues={{ ...initialValues }}
			onFinish={onSubmit}
		>
			<FormItemContainer>

				{!isExist && <FormItem
					name="email"
					label="Email"
					defaultContainer="div"
				>
					<Input disabled={true}/>
				</FormItem>
				}

				{isExist
					? (
						<FormItem
							label="User"
							name="userId"
							defaultContainer="div"
						>
							<Box style={{ width: '100%' }}>
								<UserStub user={initialValues?.user} withDropdown={false}/>
							</Box>
						</FormItem>
					)
					: (
						<Row gutter={[20, 0]}>
							<Col span={12}>
								<FormItem
									name="firstName"
									label="First Name"
									defaultContainer="div"
									rules={[requiredTrimRule]}
								/>
							</Col>
							<Col span={12}>
								<FormItem
									name="lastName"
									label="Last Name"
									defaultContainer="div"
									rules={[requiredTrimRule]}
								/>
							</Col>
							<Col span={24}>
								<FormItem
									name="information.position"
									label={<>Position <span style={{ fontWeight: 'normal', fontSize: 12, color: '#666' }}>(optional)</span></>}
									defaultContainer="div"
								/>
							</Col>
						</Row>
					)
				}
				{isStaffView
					&& !companySelected
					&& <FormItem
						label="Company"
						name="companyId"
						defaultContainer="div"
						rules={[
							{
								validator: async (_, value) => {
									if (inArray(roleSelected, roleOptions.filter((role) => role?.isCompanyRequired).map((r) => r.value)) && !value) throw new Error('This field is required');
									return true;
								},
							},
						]}

					>
						<FormCompanySelect
							options={companies.map((c) => ({ value: documentId(c), label: c.name, item: c }))}
							mode="single"
							placeholder="Select a company"
							onChange={(value) => onCompanyChange(value)}
						/>
					</FormItem>
				}
				<FormItem
					name="roleId"
					label="Role"
					defaultContainer="div"
					rules={[requiredRule]}
				>
					<FormAclRoleSelect
						options={roleOptions.map((role) => ({ value: role.value, label: role.label }))}
						onChange={(value) => setRoleSelected(value)}
						mode="single"
					/>
				</FormItem>

			</FormItemContainer>
			{withInviteUser && <Space>
				<Button type="link" style={{ color: theme.color.orange }} onClick={onInviteUser}>Invite by
						email &nbsp; <FiSend/></Button>
			</Space>
			}

			{withSubmitButton && <div style={{
				display: 'flex',
				flexDirection: 'row-reverse',
				margin: -5,
			}}>
				<Button type="primary"
					style={{
						margin: 5,
						background: theme.color.blue,
						borderColor: theme.color.blue,
						fontWeight: 'bold',
						borderRadius: 5,
					}}
					htmlType="submit"
				>
						Add
				</Button>
				<Button type="default"
					style={{
						margin: 5,
						borderColor: theme.color.orange,
						fontWeight: 'bold',
						borderRadius: 5,
						color: theme.color.orange,
					}}
					htmlType="reset"
					onClick={onReset}
				>
						Cancel
				</Button>
			</div>
			}
			{children}
		</Form>
	</>
	);
};

export default AclAddForm;
