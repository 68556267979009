import { useTheme } from '@emotion/react';
import { Form } from 'antd';
import Button from '../../components/app/button/Button';
import FormItem from '../../components/app/form/FormItem';
import { requiredTrimRule } from '../../utils/common';

const ResetPasswordForm = ({ onSubmit }) => {
	const theme = useTheme();
	const [form] = Form.useForm();
	return (

		<div>
			<Form
				form={form}
				name="alert_form"
				onFinish={onSubmit}
			>

				<FormItem
					label="Email"
					name="email"
					defaultContainer="div"
					rules={[
						{ ...requiredTrimRule, message: 'Please input your email' },
						{ type: 'email', message: 'Please enter a valid email' },
					]}
					styles={{
						rootContainer: {
							display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: 20,
						},
						label: {
							marginBottom: 0, paddingRight: 10, width: 80,
						},
						itemContainer: {
							marginBottom: 0,
						},
						container: {
							flex: 1,
						},
						input: {
							height: 40,
						},
					}}
				/>
				<div>
					<Button
						type='default'
						style={{
							width: '100%',
							height: 40,
							background: theme.color.orange,
							borderColor: theme.color.orange,
							color: theme.color.white,
							fontWeight: 'bold',
							borderRadius: 5,
							margin: 0,
						}}
						htmlType='submit'
					>
						Send me a link to reset my password
					</Button>
				</div>
			</Form>
		</div>
	);
};

export default ResetPasswordForm;
