import { isFunction } from 'lodash';
import { onEnterBuilder } from '../actions/app';
import { CaretRightOutlined, RightOutlined } from '@ant-design/icons';
import InlineNavLink from '../components/app/button/InlineNavLink';
import { arrayWrap } from '../utils/common';

class MenuBase {
	props;

	name;

	path;

	aliases = [];

	label = () => <>No label defined</>;

	icon = <RightOutlined />;

	Component = () => <>No Component defined</>;

	onEnterProps;

	perms;

	children;

	constructor(props) {
		if (props || this.props) {
			Object
				.entries(props || this.props)
				.forEach(([key, value]) => {
					this[key] = value;
				});
		}
	}

	build({
		isRoot = false,
		lang = 'en',
		perms,
		...args
	} = {
		isRoot: false,
		lang: 'en',
	}) {
		const pathProps = isRoot
			? {
				isIndexRoute: (params) => true,
				path: '/',
				aliases: ['^$', ...this.aliases || []],
			}
			: {
				path: this.path,
				aliases: this.aliases,
			};

		const menuItem = {
			name: this.name,
			...pathProps,
			label: this.getLabel?.(lang),
			icon: this.getIcon?.(args),
			// icon: <span className='menu-icon'>{this.getIcon?.(args)}</span>,
			component: this.Component,
			onEnter: onEnterBuilder({
				origin: this.Component?.name,
				...this.onEnterProps || {},
			}),
			perms: perms || this.perms,
			children: this.getChildren?.({ ...args, withDivider: false }),
		};

		if (args.withDivider) {
			return [
				{},
				menuItem,
			];
		}
		return menuItem;
	}

	getLabel(...args) {
		return isFunction(this.label)
			? this.label(...args)
			: this.label;
	}

	getIcon(...args) {
		if (this.iconLegacy) {
			isFunction(this.iconLegacy)
				? this.iconLegacy(...args)
				: <span>{this.iconLegacy}</span>;
		}
		const { icon } = this;
		return isFunction(icon)
			? icon(...args)
			: <span>{icon}</span>;
	}

	getChildren(...args) {
		return isFunction(this.children)
			? this.children(...args)
			: this.children;
	}

	getInlineNavLinkLabel({ withIcon = true }) {
		let icon = this.getIcon();
		if (isFunction(icon)) icon = icon?.();
		let label = this.getLabel();
		if (isFunction(label)) label = label?.();
		return <>{withIcon ? <span>{icon} </span> : null}{label}</>;
	}

	getInlineNavLink({
		parentMenus,
		withIcon = true,
		withUnderline = true,
		withChevronRight = false,
		...props
	} = {}) {
		let icon = this.getIcon();
		if (isFunction(icon)) icon = icon?.();
		let label = this.getLabel();
		if (isFunction(label)) label = label?.();
		return (
			<InlineNavLink
				path={this.path}
				style={{
					...!withUnderline
						? { span: { textDecoration: 'none' } }
						: {},
					...props?.style,
				}}
			>
				{[...arrayWrap(parentMenus || []), this]
					.map((menu, index, arr) => (
						<span key={index}>
							{menu.getInlineNavLinkLabel({ withIcon })}
							{index === arr.length - 1 && withChevronRight ? <>&nbsp;<RightOutlined style={{ fontSize: 10 }}/></> : null}
							{index < arr.length - 1 ? <>&nbsp;<RightOutlined style={{ fontSize: 10 }}/>&nbsp;</> : null}
						</span>
					))}
			</InlineNavLink>
		);
	}
}

export default MenuBase;
