/** @jsxImportSource @emotion/react */

import AvatarCustom from './AvatarCustom';
import GridItem from './GridItem';
import { PlayCircleFilled } from '@ant-design/icons';
import { formatImage } from '../../../../../../utils/common';
import { useTheme } from '@emotion/react';

const classes = (theme) => ({
	container: {
		'.text-danger': {
			color: `${theme.color.black}!important`,
		},
	},
});

const LearnCard = ({ learn, lang }) => {
	const theme = useTheme();

	const defaultValue = learn.getField('illustration');

	return (
		<GridItem style={{ padding: 10, height: 380, maxWidth: 380 }}>
			<div className="with-text-danger height100">
				<div css={{ height: '200px' }}>
					<div className="backgroundCoverCenter">
						<img
							css={{ width: '100%' }}
							src={formatImage(
								learn.getField('illustration', lang, defaultValue),
								{
									crop: 'fill',
									width: 1200,
									quality: 'auto:best',
								},
							)}
						/>
					</div>
				</div>

				<div
					className="flexColumn spaceBetween width100"
					css={{ height: 160, padding: '10px 13px', alignItems: 'flex-start' }}
				>
					<div>
						<div
							className="uppercase"
							css={{
								fontSize: '11px',
								color: theme.color.secondary,
								fontWeight: '600',
							}}
						>
							{learn.topic}
						</div>
						<div
							className={'ellipsis text-danger'}
							css={{
								color: theme.color.black,
								fontWeight: '600',
								lineHeight: 1.2,
								fontSize: '18px',
								marginTop: 5,
								transition: 'all 0.2s ease-out',
							}}
						>
							{learn.title}
						</div>
					</div>
					<AvatarCustom
						avatar={learn?.user?.information?.avatar}
						firstName={learn.user?.firstName}
						lastName={learn?.user?.lastName}
						position={
							learn?.user?.information?.position ? (
								<span
									dangerouslySetInnerHTML={{
										__html: learn?.user?.information?.position
											? `${learn?.user?.information?.position} at ${learn?.user?.information?.company}`
											: learn?.user?.information?.company,
									}}
								 />
							) : (
								learn?.user?.information?.company
							)
						}
						classes={classes}
					/>
				</div>
				{learn.topic === 'masterclass' && (
					<div className="absolute" css={{ top: '177px', right: '20px' }}>
						<PlayCircleFilled
							css={{
								fontSize: '50px',
								color: theme.color.accent,
								backgroundColor: theme.color.white,
								borderRadius: '50px',
								boxShadow: '0 5px 14px -3px rgb(0 0 0 / 25%)',
							}}
						/>
					</div>
				)}
			</div>
		</GridItem>
	);
};

export default LearnCard;
