import { useMemo, useState } from 'react';
import {
	Button, Dropdown, Space, Tag,
} from 'antd';
import { request } from '../../../services/api';
import { documentId, sortOn } from '../../../utils/common';
import { LocalDebug } from '../../../utils/LocalDebug';
import { BulbOutlined, LoadingOutlined } from '@ant-design/icons';

const UMSStaffSurveyComputeAnalysisButton = ({
	survey,
	onSuccess,
}) => {
	const className = 'UMSStaffSurveyComputeAnalysisButton';
	const [isLoading, setIsLoading] = useState(false);
	const [menu, setMenu] = useState([]);
	const [model, setModel] = useState();

	useMemo(() => {
		const launch = async () => {
			let modelsList = await request.get('/admin/openai/models?isChat=true&duplicates=false');
			// console.log({ modelsList });
			modelsList = modelsList?.data?.models;
			// console.log({ modelsList });
			modelsList = [
				...modelsList.filter((item) => item.isMainModel).sort(sortOn({ key: 'label' })),
				...modelsList.filter((item) => !item.isMainModel).sort(sortOn({ key: 'label' }, false)),
			];
			console.log({ modelsList });
			setMenu(modelsList.map((item) => (
				{
					key: item.id,
					label: (
						<Space>
							{item.isMainModel ? <b>{item.label}</b> : item.label}
							{item.isChat ? <Tag color='lightgray' style={{
								padding: '1px 4px', lineHeight: 1.2, fontSize: 10, fontWeight: 'bold',
							}}>chat</Tag> : null}
						</Space>
					),
				}
			)));
		};
		launch();
	}, [className]);

	const handleComputeAnalysis = async () => {
		setIsLoading(true);
		try {
			const result = await request.get(`/ums/survey/${documentId(survey)}/analysis${model ? `?model=${model}` : ''}`);
			LocalDebug.logInfo({ className, method: 'handleComputeAnalysis' }, { result });
			setIsLoading(false);
			onSuccess?.();
		} catch (e) {
			setIsLoading(false);
		}
	};

	const disabled = isLoading || !survey?.isComplete;

	// <Dropdown
	// 	menu={{ menu }}
	// 	onClick={handleComputeAnalysis}
	// >
	// 	<Button
	// 		disabled={disabled}
	// 	>
	// 	Compute AI analysis&nbsp;{isLoading ? <LoadingOutlined /> : <BulbOutlined />}
	// 	</Button>
	// </Dropdown>

	return (
		<Dropdown.Button
			menu={{
				items: menu,
				onClick: (e) => setModel(e.key),
			}}
			placement="bottom"
			disabled={disabled}
			onClick={handleComputeAnalysis}
		>
			<span>{isLoading ? <LoadingOutlined /> : <BulbOutlined />}&nbsp;New AI completion with {model || 'default model'}</span>
		</Dropdown.Button>
	);
};

export default UMSStaffSurveyComputeAnalysisButton;
