import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { getAllTagUsers } from '../../../actions/users';
import Button from '../button/Button';
import { FaFilter, FaTrash } from 'react-icons/fa';
import { Space, Tooltip } from 'antd';
import { useTheme } from '@emotion/react';
import { mixColors, writeLog } from '../../../utils/common';

const FilterButton = (
	{
		title = 'Filter',
		modal,
		modalOptions = {},
		onFilter,
		allowClear = true,
		onClearFilter,
		initialValues = {},
		forcedValues = {},
		openFilterModal = false,
		...props
	},
) => {
	const theme = useTheme();
	const dispatch = useDispatch();

	const getIsFilterEmpty = () => {
		const filterKeys = Object.keys(initialValues || {});
		if (filterKeys.length === 0) {
			return true;
		}
		if (filterKeys.length === 1) {
			if (filterKeys.includes('companyId')) {
				return true;
			}
		}
		return filterKeys.reduce((acc, key) => {
			if (key !== 'companyId'
				&& initialValues[key]?.length > 0) {
				return false;
			}
			return acc;
		}, true);
	};
	const [openModal, setOpenModal] = useState(openFilterModal);
	const [isFilterEmpty, setIsFilterEmpty] = useState(getIsFilterEmpty());

	const toggleFilterModal = () => {
		setOpenModal((prev) => !prev);
	};

	const handleFilter = (values) => {
		writeLog(`handle ${title}`, {
			keys: Object.keys(values).length,
			values,
		});
		setIsFilterEmpty(Object.keys(values).length === 0);
		if (onFilter) {
			onFilter({
				...forcedValues || {},
				...values,
			});
		}
		toggleFilterModal();
	};

	const handleClearFilter = () => {
		initialValues = {};
		setIsFilterEmpty(true);
		writeLog(`clear ${title} `);
		if (onClearFilter) onClearFilter();
	};

	useEffect(() => {
		dispatch(getAllTagUsers());
	}, [dispatch]);

	useEffect(() => {
		setOpenModal(openFilterModal);
	}, [openFilterModal]);

	useEffect(() => {
		setIsFilterEmpty(getIsFilterEmpty());
	}, [initialValues]);

	return (
		<div style={props?.style}>
			<Space direction='vertical' align="center" size={4}
				   style={{
					width: '100%', justifyContent: 'center', alignItems: 'center', margin: '10px auto 20px',
				}}>
				<Space direction='horizontal' size={4}
					   style={{ width: '100%', justifyContent: 'center', margin: '10px auto 20px' }}>
					<Button
						className={`filter-btn ${allowClear && !isFilterEmpty && 'filter-btn-active'}`}
						style={{
							// borderRadius: 4,
							// borderRadius: `100px ${isFilterEmpty ? '100px 100px': '0 0'} 100px`,
							borderRadius: 100,
							borderWidth: 2,
							borderColor: theme.color.darkturquoise,
							boxShadow: '0 2px 14px -6px darkturquoise',
							...isFilterEmpty || !allowClear
								? {
									background: mixColors(theme.color.darkerturquoise, theme.color.white, 90),
									color: theme.color.darkerturquoise,
								}
								: {
									background: theme.color.darkturquoise,
									// borderColor: theme.color.white,
									color: theme.color.white,
								},
							// background: theme.color.darkturquoise,
							// background: theme.color.lightturquoise,
							// background: theme.color.lilacLightest2,
							// color: theme.color.lilac,
							// color: theme.color.darkturquoise,
							// color: theme.color.white,
							// border: 'none',
							padding: `6px ${(isFilterEmpty || !allowClear) ? '28px' : '70px'} 6px 28px`,
							// padding: `6px 28px`,
							fontSize: 16,
							height: 40,
							// transition: 'padding 0.3s ease',
						}}
						onClick={toggleFilterModal}
						icon={<FaFilter style={{ marginBottom: -2, marginRight: 4 }}/>}
					>
						<strong>{title}</strong>
					</Button>
					{allowClear && !isFilterEmpty && <Tooltip
						title='Clear filters'
						// title={<>Clear filters<br />{filters}</>}
					>
						<Button
							className='filter-clear-btn'
							style={{
								// borderRadius: 4,
								// borderRadius: '0 100px 100px 0',
								borderRadius: 100,
								// background: theme.color.lilacLight,
								// background: theme.color.black,
								background: 'rgba(0,0,0, .5)',
								// background: theme.color.darkturquoise,
								color: 'rgba(255,255,255, .9)',
								border: 'none',
								// padding: '6px 18px 6px 12px',
								padding: '2px 12px',
								fontSize: 16,
								width: 50,
								height: 32,
								marginTop: 0,
								marginLeft: -58,
							}}
							ghost
							onClick={handleClearFilter}
							// icon={<FaTrash style={{marginBottom: -2}} />}
						>
							<FaTrash style={{ marginBottom: -2 }}/>
						</Button>
					</Tooltip>}
				</Space>
				{/* {!isFilterEmpty && <>Des filtres en cours</>} */}
			</Space>
			{React.createElement(modal, {
				title: <><FaFilter style={{ marginBottom: -2, marginRight: 4 }}/><strong>{title}</strong></>,
				open: openModal,
				onClose: toggleFilterModal,
				onFilter: handleFilter,
				onClearFilter: handleClearFilter,
				modalOptions,
				initialValues: isFilterEmpty ? {} : initialValues,
				getContainer: false,
			})}
		</div>
	);
};

export default FilterButton;
