import slugify from '@sindresorhus/slugify';
import { isStringTypeValidator } from '../../../utils/isStringTypeValidator';
import { innerObjPropMapper } from '../../property/property-mapper';
import { innerObjPropExtractor } from '../../property/property-extractor';

const POSITIONS = require('../../profile-positions.json');

const USER_PROPERTY_PROFILE_POSITION_KEY = 'profile-position';

const USER_PROPERTY_PROFILE_POSITION_OPTIONS = POSITIONS.map((item) => slugify(item));

const USER_PROPERTY_PROFILE_POSITION_FREE = true;

const USER_PROPERTY_PROFILE_POSITION_VALIDATORS = [
	isStringTypeValidator,
];

const USER_PROPERTY_PROFILE_POSITION_FIELD = 'information.position';
const USER_PROPERTY_PROFILE_POSITION_MAPPER = innerObjPropMapper(USER_PROPERTY_PROFILE_POSITION_FIELD);
const USER_PROPERTY_PROFILE_POSITION_EXTRACTOR = innerObjPropExtractor(USER_PROPERTY_PROFILE_POSITION_FIELD);

const exporter = {
	key: USER_PROPERTY_PROFILE_POSITION_KEY,
	USER_PROPERTY_PROFILE_POSITION_KEY,
	validators: USER_PROPERTY_PROFILE_POSITION_VALIDATORS,
	USER_PROPERTY_PROFILE_POSITION_VALIDATORS,
	options: USER_PROPERTY_PROFILE_POSITION_OPTIONS,
	USER_PROPERTY_PROFILE_POSITION_OPTIONS,
	free: USER_PROPERTY_PROFILE_POSITION_FREE,
	USER_PROPERTY_PROFILE_POSITION_FREE,
	mapper: USER_PROPERTY_PROFILE_POSITION_MAPPER,
	USER_PROPERTY_PROFILE_POSITION_MAPPER,
	extractor: USER_PROPERTY_PROFILE_POSITION_EXTRACTOR,
	USER_PROPERTY_PROFILE_POSITION_EXTRACTOR,
	field: USER_PROPERTY_PROFILE_POSITION_FIELD,
	USER_PROPERTY_PROFILE_POSITION_FIELD,
};

export default exporter;
