import { useTheme } from '@emotion/react';
import { Space } from 'antd';
import {
	getPillarLabel,
	getPillarIndex,
	getPillarColorLight,
	getPillarColorMain,
	getPillarIcon,
	getPrevPillar,
	getNextPillar,
	getPillarOption,
} from '../../../../constants/genderscore-pillars';
import GenderScorePillarRankingBox from './GenderScorePillarRankingBox';
import GenderScoreActionPlanSection from '../actions/GenderScoreActionPlanSection';
import GenderScoreResourcesGrid from '../resources/GenderScoreResourcesGrid';
import GenderScoreLayoutGrid from '../layout/GenderScoreLayoutGrid';
import { useEffect } from 'react';
import { mixColors, scrollToTop } from '../../../../utils/common';
import GenderScorePillarNavigationButton from './GenderScorePillarNavigationButton';
import GenderScorePillarScoreBadge from './GenderScorePillarScoreBadge';
import GenderScorePillarCommunityStat from './GenderScorePillarCommunityStat';
import GenderScorePillarPerformanceBox from './GenderScorePillarPerformanceBox';
import GenderScoreLayoutBox from '../layout/GenderScoreLayoutBox';
import { useLocation } from 'react-router';
import GenderScoreLayoutDivider from '../layout/GenderScoreLayoutDivider';
import GenderScoreLayoutPageHeader from '../layout/GenderScoreLayoutPageHeader';
import GenderScoreResultsExpirationBanners from '../results/GenderScoreResultsExpirationBanners';
import GenderscoreResourcesMenu from '../../../../menu/genderscore/GenderscoreResourcesMenu';
import CompanyActionLibrary from '../../actionPlan/CompanyActionLibrary';
import { useAuthContext } from '../../../../hooks/useAuthContext';

const GenderScorePillar = ({
	company,
	forceValue,
	pillar,
	...props
}) => {
	const className = 'GenderScorePillar';

	const theme = useTheme();
	const location = useLocation();
	const { isStaffView } = useAuthContext();

	const genderScore = forceValue || company?.genderScore;

	useEffect(() => {
		scrollToTop();
	}, [location.pathname]);

	return (
		<>
			<GenderScoreResultsExpirationBanners
				company={company}
			/>
			<GenderScoreLayoutPageHeader
				color={[
					getPillarColorLight(pillar),
					mixColors(
						getPillarColorLight(pillar),
						getPillarColorMain(pillar),
						20,
					),
				]}
				height={250}
			>
				<GenderScoreLayoutGrid
					columns={'1fr 1fr'}
				>
					<div>
						<div
							style={{
								display: 'flex',
								justifyContent: 'start',
								alignItems: 'center',
							}}
						>
							<div style={{ width: 64, height: 64, marginRight: 12 }}>
								{getPillarIcon(pillar, { width: 64, height: 64 })}
							</div>
							<div>
								<div
									style={{
										display: 'inline-block',
										color: getPillarColorMain(pillar),
										borderRadius: 50,
										marginBottom: -4,
									}}
								>
										GenderScore Pillar #{getPillarIndex(pillar) + 1}
								</div>
								<h1>{getPillarLabel(pillar)}</h1>
							</div>
						</div>

						<p style={{ fontSize: 18, color: '#333' }}>
							{getPillarOption(pillar).sectionIntroShort}
						</p>

					</div>

					<GenderScorePillarScoreBadge
						company={company}
						pillar={pillar}
					/>

				</GenderScoreLayoutGrid>
			</GenderScoreLayoutPageHeader>

			<div
				id={'body'}
				style={{
					padding: 32,
					paddingTop: 0,
				}}
			>

				<Space style={{
					width: '100%',
					justifyContent: 'center',
				}}>
					<div
						style={{
							marginBottom: 32,
						}}
					>
						<GenderScorePillarCommunityStat
							pillar={pillar}
						/>
					</div>
				</Space>

				<GenderScoreLayoutGrid
					columns={'1fr 1fr 1fr'}
				>
					<GenderScorePillarRankingBox
						company={company}
						pillar={pillar}
					/>

					<GenderScorePillarPerformanceBox
						company={company}
						pillar={pillar}
					/>

					{/* {pillar === GENDERSCORE_PILLAR_GENDERDATA_VALUE && (
						<GenderScoreSolutionCardHiringPractices
							link={`${location.pathname}#${SOLUTION_HIRING_PRACTICES_ID}`}
							element={`#${SOLUTION_HIRING_PRACTICES_ID}`}
						/>
					)} */}

					{getPillarOption(pillar)?.globalStat && (
						<GenderScoreLayoutBox
							withShadow={false}
							style={{
								border: '1px dashed #ddd',
								background: theme.color.bgGrey,
								color: '#333',
								opacity: 0.7,
							}}
						>
							<div
								style={{
									fontSize: 36,
									marginBottom: 8,
									color: 'black',
								}}
							>
								{getPillarOption(pillar)?.globalStat?.value}
							</div>
							<p>
								{getPillarOption(pillar)?.globalStat?.text}
							</p>
						</GenderScoreLayoutBox>
					)}

				</GenderScoreLayoutGrid>

				<GenderScoreLayoutDivider
					title={<>Want to unlock your next level?</>}
					subtitle={<>Plan your next actions for the <strong>{getPillarLabel(pillar)}</strong> level:</>}
				/>

				{isStaffView
					? (
						<CompanyActionLibrary
							pillar={pillar}
							viaModal={false}
							limit={3}
						/>
					)
					: (
						<GenderScoreActionPlanSection
							company={company}
							pillar={pillar}
							withActionSelectButton={true}
							collapsed={4}
							style={{
								grid: {
									gridTemplateColumns: '1fr 1fr 1fr 1fr',
									marginBottom: 0,
								},
							}}
						/>
					)
				}

				{/* {pillar === GENDERSCORE_PILLAR_GENDERDATA_VALUE && (
					<GenderScoreSolutionsSection
						withAnnualReview={false}
						withHiringPractices={true}
					/>
				)} */}

				<div
					style={{
						width: 'calc(100% + 64px)',
						margin: '0 -32px',
						padding: 32,
						background: theme.color.bgGrey,
						marginTop: 32,
						marginBottom: 60,
					}}
				>
					<h2
						style={{
							marginBottom: 12,
						}}
					>
						{GenderscoreResourcesMenu?.props?.icon} {GenderscoreResourcesMenu?.props?.label}
					</h2>

					<p>
					Here are some useful resources which are guaranteed to help your company
					improve in the <strong>{getPillarLabel(pillar)}</strong> pillar:
					</p>
					<GenderScoreResourcesGrid
						company={company}
						pillar={pillar}
					/>
				</div>

				<Space
					style={{
						width: '100%',
						justifyContent: 'space-between',
					}}
				>
					<GenderScorePillarNavigationButton
						pillar={getPrevPillar(pillar)}
						isPrev={true}
					/>
					<GenderScorePillarNavigationButton
						pillar={getNextPillar(pillar)}
						isPrev={false}
					/>
				</Space>

			</div>
		</>
	);
};

export default GenderScorePillar;
