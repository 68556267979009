import { useContext, useState } from 'react';
import SurveyQuestionFooter from './SurveyQuestionFooter';
import { useNavigate } from 'react-router-dom';
import { SurveyNavigationContext } from '../providers/SurveyNavigationProvider';
import LoadSpinner from '../../../app/LoadSpinner';
import SurveyPageLayout from '../layout/SurveyPageLayout';
import { GENDERSCORE_PILLAR_OPTIONS_LIST } from '../../../../constants/genderscore-pillars';
import { useTheme } from '@emotion/react';
import { useSelector } from 'react-redux';
import { getCompanyUMSSurveyDraftSelected } from '../../../../reducers/app';
import { ANSWER_VALUE_LATER, ANSWER_VALUE_SKIPPED, isQuestionAnswered } from '../../data/shared';
import { useDebouncedEffect } from '../../../../hooks/useDebounceEffect';
import SurveyQuestionContent from './SurveyQuestionContent';
import {
	EVENT,
	buildEventSection,
	buildEventLabel,
	pushClick,
} from '../../../../contexts/EventPushProvider';
import { LocalDebug } from '../../../../utils/LocalDebug';

const SurveyQuestion = () => {
	const className = 'SurveyQuestion';

	const theme = useTheme();
	const navigate = useNavigate();
	const draftSelected = useSelector(getCompanyUMSSurveyDraftSelected);

	// LocalDebug.logInfo({ className });
	const {
		eventContext,
		currentQuestion: question,
		currentSection: section,
		isLoading,
		gotoPrevQuestion,
		gotoNextQuestion,
		answers,
		saveAnswers,
	} = useContext(SurveyNavigationContext);

	const eventProps = {
		...eventContext,
		...buildEventSection(EVENT.SECTION.SURVEY_QUESTION),
	};

	const [value, setValue] = useState(null);
	const [wait, setWait] = useState(false);
	const [pillar, setPillar] = useState(null);

	useDebouncedEffect(() => {
		const answer = answers?.[question?.questionId];
		setValue(![ANSWER_VALUE_SKIPPED, ANSWER_VALUE_LATER].includes(answer) ? answer : null);
		setPillar(GENDERSCORE_PILLAR_OPTIONS_LIST
			.find((o) => (
				o?.label?.toLocaleLowerCase?.()
				=== section?.label?.toLocaleLowerCase?.()))
			?.value);
		setWait(false);
	}, [answers, question], 80);

	const handleChange = (newValue) => {
		setValue(newValue);
		if (question.autoSave) {
			handleSaveAnswer({ answer: newValue });
		}
	};

	const onPrev = () => {
		pushClick({ ...eventProps, ...buildEventLabel(EVENT.LABEL.ANSWER_PREVIOUS) });
		setWait(true);
		setValue(null);
		gotoPrevQuestion({ section, question, navigate });
	};

	const handleSaveAnswer = async ({ answer }) => {
		const { answers: newAnswers } = await saveAnswers({
			draft: draftSelected,
			answers: { [question.questionId]: answer },
		});
		setWait(true);
		setValue(null);
		gotoNextQuestion({
			section, question, answers: newAnswers, navigate,
		});
	};

	const onSave = async () => {
		pushClick({ ...eventProps, ...buildEventLabel(EVENT.LABEL.ANSWER_SAVED) });
		await handleSaveAnswer({ answer: value });
	};

	const onSkip = async () => {
		pushClick({ ...eventProps, ...buildEventLabel(EVENT.LABEL.ANSWER_SKIPPED) });
		await handleSaveAnswer({ answer: ANSWER_VALUE_SKIPPED });
	};

	const onAnswerLater = async () => {
		pushClick({ ...eventProps, ...buildEventLabel(EVENT.LABEL.ANSWER_LATER) });
		await handleSaveAnswer({ answer: ANSWER_VALUE_LATER });
	};

	if (isLoading || !question) {
		return (
			<>
				<LoadSpinner />
			</>
		);
	}

	return (
		<SurveyPageLayout
			withHeader={true}
			withFooter={true}
			cssClassNames={[
				...pillar ? [pillar] : [],
			]}
		>
			{!wait && (
				<div
					style={{
						display: 'flex',
						flexDirection: 'column',
						justifyContent: 'center',
						alignItems: 'flex-start',
						textAlign: 'left',
						minWidth: 820,
						maxWidth: 820,
						width: 820,
						margin: '0 auto',
					}}
				>
					<SurveyQuestionContent
						question={question}
						pillar={section?.value}
						value={value}
						answers={answers}
						onChange={handleChange}
						onSkip={onSkip}
					/>

					<div style={{ height: 30 }} />
				</div>
			)}

			<SurveyQuestionFooter
				disablePrev={false}
				disableSave={!isQuestionAnswered({ question, answers: { [question.questionId]: value } })}
				withAnswerLater={(
					(
						!isQuestionAnswered({ question, answers })
						|| answers?.[question?.questionId] === ANSWER_VALUE_LATER
					)
					&& answers?.[question?.questionId] !== ANSWER_VALUE_SKIPPED
				)}
				withSkip={question?.optional}
				onPrev={onPrev}
				onSave={onSave}
				onSkip={onSkip}
				onAnswerLater={onAnswerLater}
			/>
			{/* </div> */}
		</SurveyPageLayout>
	);
};

export default SurveyQuestion;
