import PageLayout from '../../../components/app/layout/PageLayout';
import UserHiddenCompaniesTable from '../../../components/user/UserHiddenCompanyMappingsTable';
import StaffHiddenCompaniesMenu from '../../../menu/staff/users/StaffHiddenCompaniesMenu';

const StaffHiddenCompaniesPage = () => {
	return (
		<PageLayout
			MenuClass={StaffHiddenCompaniesMenu}
		>
			<UserHiddenCompaniesTable
			/>
		</PageLayout>
	);
};

export default StaffHiddenCompaniesPage;
