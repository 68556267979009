import { createContext, useState } from 'react';

export const DrawerContext = createContext();

const DrawerProvider = ({
	children,
}) => {
	const className = 'DrawerProvider';

	const [open, setOpen] = useState(false);
	const [content, setContent] = useState();
	const [title, setTitle] = useState('');
	const [width, setWidth] = useState(500);
	const [style, setStyle] = useState({});
	const [onClose, setOnClose] = useState({});

	return (
		<DrawerContext.Provider
			value={{
				open,
				setOpen,
				title,
				setTitle,
				onClose,
				setOnClose,
				content,
				setContent,
				width,
				setWidth,
				style,
				setStyle,
			}}
		>
			{children}
		</DrawerContext.Provider>
	);
};

export default DrawerProvider;
