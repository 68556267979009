import { useTheme } from '@emotion/react';
import {
	ADD_TO_TALENT_POOL_LABEL, SOURCE_APPLICATIONS_LIST, SOURCE_SOURCED_LIST,
	STAGE_APPLICATIONS_LIST, STAGE_SOURCED_LIST,
} from '../../../constants/constant';
import { useAuthContext } from '../../../hooks/useAuthContext';
import TableColumns from '../../app/table/TableColumns';
import { useEffect, useState } from 'react';
import userService from '../../../services/user';
import JobStub from '../../jobs/JobStub';
import UserStub from '../stub/UserStub';
import EmptyData from '../../app/EmptyData';
import SourcingsTab from '../../sourcings/SourcingsTab';
import UserSourcingAddBtn from './UserSourcingAddBtn';
import { Space } from 'antd';
import { useSelector } from 'react-redux';
import { getCompanySelected } from '../../../reducers/app';
import { documentId } from '../../../utils/common';
import { SOURCE_50INTECH_VALUE, SOURCE_RECRUITER_VALUE } from '../../../constants/sourcing';

const UserArchived = (
	{
		user = null,
		withUserStub = false,
	},
) => {
	const theme = useTheme();

	const companySelected = useSelector(getCompanySelected);
	const {
		isStaff, isStaffView, isTalent, companyIdsUser,
	} = useAuthContext();

	// selector
	const [dataSource, setDataSource] = useState([]);
	const [isLoading, setIsLoading] = useState(false);

	const fetchData = async (userId) => {
		const { data: { sourcings } } = await userService.getSourcingArchived(userId, { companyId: documentId(companySelected) });
		setDataSource(sourcings);
		setIsLoading(false);
	};

	useEffect(() => {
		setIsLoading(true);
		if (user) {
			fetchData(documentId(user));
		} else {
			setIsLoading(false);
			setDataSource([]);
		}
	}, [user]);

	const addSourcingBtn = <UserSourcingAddBtn
		title={ADD_TO_TALENT_POOL_LABEL}
		user={user}
		initialValues={{
			userId: documentId(user),
			source: isTalent ? SOURCE_50INTECH_VALUE : SOURCE_RECRUITER_VALUE,
			user,
			viaProfile: true,
			companyIds: !isStaffView ? companyIdsUser : null,
		}}
		sourcings={user.sourcings}
		style={{ marginBottom: 0 }}
	/>;

	return (user
		&& <>
			{
				withUserStub
				&& <div style={{
					border: '1px solid #eee', borderRadius: 4, padding: '5px 12px', marginBottom: 20,
				}}>
					<UserStub user={user}/>
				</div>
			}
			{!isLoading && dataSource?.length > 0
				? <SourcingsTab
					sourcings={dataSource}
					initialValues={{
						userId: documentId(user),
						source: [...SOURCE_APPLICATIONS_LIST, ...SOURCE_SOURCED_LIST],
						stage: [...STAGE_APPLICATIONS_LIST, ...STAGE_SOURCED_LIST],
						user,
						viaProfile: true,
						companyIds: !isStaffView ? companyIdsUser : null,
						notJobIds: dataSource.map((sourcing) => sourcing.jobId),
					}}
					viaProfile
				/>
				: <EmptyData description={
					<Space direction='vertical' size={20} style={{ width: '100%' }}>
						<span>
							<strong>{user?.firstName}</strong> has not been archived for one of your jobs.
						</span>
						{dataSource?.length > 0 && <span style={{ textAlign: 'left' }}>{addSourcingBtn}</span>}
					</Space>
				}/>
			}
		</>
	);
};

export default UserArchived;
