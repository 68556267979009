import { Typography } from 'antd';
import CompanyPageNoticeTranslate from '../companyPage/shared/CompanyPageNoticeTranslate';
import CKEditor from '../../app/form/CkEditor';

const TextareaEditor = (
	{
		lang,
		title,
		value,
		isTranslatable,
		onChange,
		config = {},
		...props
	},
) => {
	return (
		<div style={{ marginBottom: 30, ...props?.style }}>
			{title && <Typography.Title level={3}>{title}</Typography.Title>}
			{isTranslatable && <CompanyPageNoticeTranslate lang={lang}/>}
			<CKEditor
				value={value}
				onChange={onChange}
				config={config}
			/>
		</div>
	);
};

export default TextareaEditor;
