const LOCATION_COUNTRY = require('../../../property/location-country');
const { asCategoryTag, asCategoryTagOption } = require('../tags.constants.utils');

export const TAG_CATEGORY_JOB_SEARCH_JOB_LOCATION_COUNTRY_SLUG = 'job-search-job-location-country';

const asJobSearchJobLocationCountryTag = (slug) => asCategoryTag(TAG_CATEGORY_JOB_SEARCH_JOB_LOCATION_COUNTRY_SLUG, slug);

const asJobSearchJobLocationCountryTagOption = (value, label, group) => asCategoryTagOption(TAG_CATEGORY_JOB_SEARCH_JOB_LOCATION_COUNTRY_SLUG, value, label, group);

const options = LOCATION_COUNTRY
	?.options
	?.map((option) => asJobSearchJobLocationCountryTagOption(option.value, option.label, option.group));

export default {
	category: TAG_CATEGORY_JOB_SEARCH_JOB_LOCATION_COUNTRY_SLUG,
	TAG_CATEGORY_JOB_SEARCH_JOB_LOCATION_COUNTRY_SLUG,
	...LOCATION_COUNTRY,
	options,
	asTag: asJobSearchJobLocationCountryTag,
	asTagOption: asJobSearchJobLocationCountryTagOption,
	asJobSearchJobLocationCountryTag,
	asJobSearchJobLocationCountryTagOption,
};
