import { FaBan, FaCheck } from 'react-icons/fa';
import { useTheme } from '@emotion/react';
import { Space } from 'antd';
import { CompanySettingsCardLineOk } from './CompanySettingsCard';

const CompanySettingsTranslationEditionStatus = (
	{
		company,
		...props
	},
) => {
	const className = 'CompanySettingsTranslationEditionStatus';
	const theme = useTheme();

	return (
		<CompanySettingsCardLineOk
			ok={company?.isTranslatable}
			okText={<><b>{company.name}</b>'s company page is <b>translatable in 🇫🇷 french</b></>}
			koText={<><b>{company.name}</b>'s company page is <b>not translatable in 🇫🇷 french</b></>}
		/>
	);
};

export default CompanySettingsTranslationEditionStatus;
