import { documentId, hexToRgb, mixColors } from '../../../../utils/common';
import { Space, Tour } from 'antd';
import Box from '../../../app/box/Box';
import CompanyPublicationWithTranslationStatus from '../../CompanyPublicationWithTranslationStatus';
import {
	useContext, useEffect, useRef, useState,
} from 'react';
import { useTheme } from '@emotion/react';
import { CompanyPageContext, LANG_TEXTS } from './CompanyPageProvider';
import CompanyPagePublishButton from './CompanyPagePublishButton';
import CompanyPagePreviewButton from './CompanyPagePreviewButton';
import {
	COMPANY_PAGE_EDITION_STATE_START_ID,
	COMPANY_PAGE_EDITION_STATE_IN_PROGRESS_ID,
	COMPANY_PAGE_EDITION_STATE_PENDING_PUBLICATION_ID,
	COMPANY_PAGE_EDITION_STATE_PUBLISHED_PENDING_GENDERSCORE_ID,
	COMPANY_PAGE_EDITION_STATE_PUBLISHED_WITH_OPTIONAL_FIELDS_ID,
	COMPANY_PAGE_EDITION_STATE_PUBLISHED_ID,
} from './CompanyPageEditionStates';
import CompanyPageNextStepLink from './CompanyPageNextStepLink';
import CompanyPageViewLiveButton from './CompanyPageViewLiveButton';
import CompanyPageLangEditionSwitch from './CompanyPageLangEditionSwitch';
import { ENGLISH_LANG, FRENCH_LANG } from '../../../../constants/constant';
import CompanyPageUnpublishButton from './CompanyPageUnpublishButton';
import { useAuthContext } from '../../../../hooks/useAuthContext';

export const CompanyPublicationStatusBox = (
	{
		company,
		color,
	},
) => (<Space size={30}>
	<Box style={{
		border: `1px dashed rgba(${hexToRgb(color).join(',')},.5)`,
		borderRadius: 40,
	}}>
		<CompanyPublicationWithTranslationStatus company={company} />
	</Box>
</Space>
);

const CompanyPageHeader = (
	{
		company,
		...props
	},
) => {
	const className = 'CompanyPageHeader';

	const theme = useTheme();

	const { isDocumentEditorOnly } = useAuthContext();
	const {
		lang,
		setLang,
		langTexts,
		editionState,
		openLangEditionTour,
		setOpenLangEditionTour,
	} = useContext(CompanyPageContext);

	const [shouldDisplayPublishButton, setShouldDisplayPublishButton] = useState(false);
	const [shouldDisablePublishButton, setShouldDisablePublishButton] = useState(false);
	const [shouldDisplayUnpublishButton, setShouldDisplayUnpublishButton] = useState(false);
	const [shouldDisableUnpublishButton, setShouldDisableUnpublishButton] = useState(false);
	const [shouldDisplayPreviewButton, setShouldDisplayPreviewButton] = useState(false);
	const [shouldDisplayViewLiveButton, setShouldDisplayViewLiveButton] = useState(false);

	const companyPageLangEditionSwitchRef = useRef(null);
	const companyPagePublishFrenchButtonRef = useRef(null);

	const companyPageFrenchPublicationTourSteps = [
		{
			title: (
				<h3 style={{ marginTop: 0, marginBottom: 0 }}>
					Your company page can now be<br />translated {LANG_TEXTS[FRENCH_LANG].labelInFlag}!
				</h3>
			),
			description: (
				<p>
					Click on this toggle to switch between languages,
					and start translating your content.
				</p>
			),
			target: () => companyPageLangEditionSwitchRef.current,
			nextButtonProps: {
				onClick: () => setLang(FRENCH_LANG),
			},
		},
		{
			title: (
				<h3 style={{ marginTop: 0, marginBottom: 0 }}>
					Your company page can now be<br/>translated {LANG_TEXTS[FRENCH_LANG].labelInFlag}!
				</h3>
			),
			description: (
				<p>
					When everything is ready, you'll be able to publish
					your french version!
				</p>
			),
			target: () => companyPagePublishFrenchButtonRef.current,
		},
	];

	let publicationStatusColor = theme.color.accent;
	let nextStepOrPublicationStatus;

	if (lang === ENGLISH_LANG) {
		if (company.isPublishedNow?.()) {
			publicationStatusColor = theme.color.darkturquoise;
			nextStepOrPublicationStatus = <CompanyPublicationStatusBox
				company={company}
				color={publicationStatusColor}
			/>;
		} else if (company.isPublishedFuture?.()) {
			publicationStatusColor = theme.color.fitBlueElectric;
			nextStepOrPublicationStatus = <CompanyPublicationStatusBox
				company={company}
				color={publicationStatusColor}
			/>;
		} else {
			publicationStatusColor = theme.color.fitBlueElectric;
			nextStepOrPublicationStatus = <CompanyPageNextStepLink
				company={company}
				style={{ color: theme.color.accent }}
			/>;
		}
	} else if (company.isTranslationPublished) {
		publicationStatusColor = theme.color.darkturquoise;
		nextStepOrPublicationStatus = <CompanyPublicationStatusBox
			company={company}
			color={publicationStatusColor}
		/>;
	} else {
		publicationStatusColor = theme.color.fitBlueElectric;
		nextStepOrPublicationStatus = <CompanyPageNextStepLink
			company={company}
			style={{ color: theme.color.accent }}
		/>;
	}

	// If FR version has been published,
	// overriding the value to always display publish
	const newShouldDisplayPublishButton = (
		company.isTranslatable
		&& lang === FRENCH_LANG
		&& !company.isTranslationPublished
	);

	useEffect(() => {
		// Default values
		setShouldDisplayPublishButton(false);
		setShouldDisablePublishButton(true);
		setShouldDisplayUnpublishButton(false);
		setShouldDisableUnpublishButton(true);
		setShouldDisplayPreviewButton(false);
		setShouldDisplayViewLiveButton(false);

		if (!(editionState && company && lang)) return;

		switch (documentId(editionState)) {
			case COMPANY_PAGE_EDITION_STATE_START_ID:
				setShouldDisplayPublishButton(true);
				setShouldDisplayPreviewButton(true);
				break;
			case COMPANY_PAGE_EDITION_STATE_IN_PROGRESS_ID:
				setShouldDisplayPublishButton(true);
				setShouldDisplayPreviewButton(true);
				break;
			case COMPANY_PAGE_EDITION_STATE_PENDING_PUBLICATION_ID:
				setShouldDisplayPublishButton(true);
				setShouldDisablePublishButton(false);
				setShouldDisplayPreviewButton(true);
				break;
			case COMPANY_PAGE_EDITION_STATE_PUBLISHED_PENDING_GENDERSCORE_ID:
				setShouldDisplayPublishButton(newShouldDisplayPublishButton);
				setShouldDisplayUnpublishButton(!newShouldDisplayPublishButton);
				setShouldDisableUnpublishButton(false);
				setShouldDisplayViewLiveButton(true);
				break;
			case COMPANY_PAGE_EDITION_STATE_PUBLISHED_WITH_OPTIONAL_FIELDS_ID:
				setShouldDisplayPublishButton(newShouldDisplayPublishButton);
				setShouldDisplayUnpublishButton(!newShouldDisplayPublishButton);
				setShouldDisableUnpublishButton(false);
				setShouldDisplayViewLiveButton(true);
				break;
			case COMPANY_PAGE_EDITION_STATE_PUBLISHED_ID:
				setShouldDisplayPublishButton(false);
				setShouldDisplayUnpublishButton(true);
				setShouldDisableUnpublishButton(false);
				setShouldDisplayViewLiveButton(true);
				break;
			default:
				break;
		}
		if (isDocumentEditorOnly && lang === ENGLISH_LANG) {
			// setShouldDisplayUnpublishButton(true);
			setShouldDisableUnpublishButton(true);
		}
	}, [editionState, company, lang, isDocumentEditorOnly]);

	return (
		<>
			<Space
				style={{
					width: '100%',
					padding: '12px 32px',
					justifyContent: 'space-between',
					background: mixColors(theme.color.white, publicationStatusColor, 15),
					transition: 'all 1s ease-out',
					height: 58,
				}}
			>
				<Space>
					{nextStepOrPublicationStatus || <span />}
				</Space>

				<Space>
					<CompanyPageLangEditionSwitch
						innerRef={companyPageLangEditionSwitchRef}
						company={company}
					/>

					{shouldDisplayPreviewButton && (
						<>
							<CompanyPagePreviewButton
								company={company}
							/>
						</>
					)}

					{shouldDisplayViewLiveButton && (
						<>
							<CompanyPageViewLiveButton
								company={company}
							/>
						</>
					)}

					<div ref={companyPagePublishFrenchButtonRef} >
						{shouldDisplayPublishButton && (
							<CompanyPagePublishButton
								company={company}
								disabled={shouldDisablePublishButton}
							/>
						)}
						{shouldDisplayUnpublishButton && (
							<CompanyPageUnpublishButton
								company={company}
								disabled={shouldDisableUnpublishButton}
							/>
						)}
					</div>
				</Space>
			</Space>

			<Tour
				open={openLangEditionTour}
				placement='bottomLeft'
				onClose={() => setOpenLangEditionTour(false)}
				steps={companyPageFrenchPublicationTourSteps}
			/>
		</>
	);
};

export default CompanyPageHeader;
