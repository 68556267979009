import {
	Button, Col, Row, Space, Steps,
} from 'antd';
import Popin from '../../app/modal/Popin';
import BoxRainbow from '../../app/box/BoxRainbow';
import { getMandatorySteps } from '../../company/companyPage/shared/CompanyPageSteps';
import { FaChevronRight } from 'react-icons/fa';
import { useTheme } from '@emotion/react';
import { ENGLISH_LANG } from '../../../constants/constant';
import { useSelector } from 'react-redux';
import { getCompanySelected } from '../../../reducers/app';
import { useEffect, useState } from 'react';
import ModalContainerBase from '../../../contexts/modals/ModalContainerBase';

const UserInvitedWelcomePopin = (
	{
		open,
		onSuccess,
	},
) => {
	const className = 'UserInvitedWelcomePopin';

	const companySelected = useSelector(getCompanySelected);
	const theme = useTheme();
	const [steps, setSteps] = useState([]);

	useEffect(() => {
		if (companySelected) {
			setSteps(
				getMandatorySteps({
					company: companySelected,
					lang: ENGLISH_LANG,
				})
					.map((step) => ({
						...step || {},
						title: <Space
							direction='horizontal'
							wrap={false}
							style={{
								justifyContent: 'space-between',
								alignItems: 'top',
								width: '542px',
							}}
						>
							{step?.title}
						</Space>,
					})),
			);
		}
	}, [companySelected]);

	if (!steps?.length) return null;

	return (
		<ModalContainerBase
			cssClassName='modal-large no-close'
			open={open}
			width={800}
			onCancel={onSuccess}
		>
			<Row gutter={[40, 40]}>
				<Col span={10}>
					<h2>Welcome!</h2>
					<h3>Let's get started on showcasing your company!</h3>
					<p>
					Throughout this process, we'll guide you step-by-step,
					ensuring that you have everything you need to create an impactful company page.
					</p>
					<Button
						type='primary'
						size='large'
						style={{ marginTop: 40 }}
						onClick={onSuccess}
					>
						<b>Start filling my company page</b>&nbsp;<FaChevronRight style={{ marginBottom: -2 }}/>
					</Button>
				</Col>
				<Col span={14}>
					<BoxRainbow
						borderSize={4}
						style={{
							position: 'relative',
							borderRadius: 10,
							padding: '20px 20px 0',
						}}
					>
						<Steps
							direction="vertical"
							current={steps.length}
							items={steps}
							style={{
								position: 'relative',
								maxHeight: '400px',
								overflow: 'hidden',
							}}
						/>
						<div
							style={{
								position: 'absolute',
								bottom: 0,
								left: 0,
								borderRadius: 6,
								background: 'linear-gradient(to bottom, rgba(255,255,255,0), rgba(255,255,255,100) 85%)',
								width: '100%',
								height: 300,
							}}
						>
							&nbsp;
						</div>
					</BoxRainbow>
				</Col>
			</Row>
		</ModalContainerBase>
	);
};

export default UserInvitedWelcomePopin;
