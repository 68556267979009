import { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router';
import { useTheme } from '@emotion/react';
import { Modal } from 'antd';
import { BiExit } from 'react-icons/bi';
import { GENDERSCORE_LABEL, PATHS } from '../../../constants/constant';
import { SurveyContext } from './SurveyProvider';
import {
	EVENT, buildEventLabel, buildEventSection, pushClick,
} from '../../../contexts/EventPushProvider';

const SurveyLeaveModal = () => {
	const className = 'SurveyLeaveModal';

	const theme = useTheme();
	const navigate = useNavigate();

	const {
		eventContext,
		openLeaveModal,
		setOpenLeaveModal,
		dispatchSelectSurveyDraft,
	} = useContext(SurveyContext);

	const eventProps = {
		...eventContext,
		...buildEventSection(EVENT.SECTION.SURVEY_LEAVE_MODAL),
	};

	return (
		<Modal
			centered
			open={openLeaveModal}
			onCancel={() => setOpenLeaveModal(false)}
			onOk={async () => {
				setOpenLeaveModal(false);
				await dispatchSelectSurveyDraft(null);
				navigate(PATHS.GENDERSCORE_SURVEY());
			}}
			closable={false}
			cancelButtonProps={{ size: 'large' }}
			okButtonProps={{ size: 'large' }}
			cancelText={<b>Resume survey</b>}
			okText={<b>
				Leave
				<BiExit
					style={{
						fontSize: 16,
						marginBottom: -3,
						marginLeft: 4,
					}}
				/>
			</b>}
			style={{
				maxWidth: 480,
			}}
		>
			<div
				style={{
					lineHeight: 1.3,
					padding: '10px 0 6px',
					fontSize: 16,
				}}
			>
				<h2>You are about to leave the {GENDERSCORE_LABEL} survey</h2>
				<p>Your progress will be saved.</p>
				<p>You can come back anytime through your app menu.</p>
			</div>
		</Modal>
	);
};

export default SurveyLeaveModal;
