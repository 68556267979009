import StaffMenu from './staff/StaffMenu';
import roles from '../config/roles';
import UMSPresentationMenu from './ums/UMSPresentationMenu';
import UMSTeamMenu from './ums/UMSTeamMenu';
import UMSAdminResultsMenu from './ums/UMSAdminResultsMenu';
import UMSManagerResultsMenu from './ums/UMSManagerResultsMenu';
import UMSSurveyMenu from './ums/UMSSurveyMenu';
import UMSStaffMenu from './ums/UMSStaffMenu';
import { LocalDebug } from '../utils/LocalDebug';
import { PERM_GRANT_TYPE } from './menu.utils';
import ConfigureMenu from './configure/ConfigureMenu';
import ConfigureUsersMenu from './configure/ConfigureUsersMenu';
import ConfigureCompanySettingsMenu from './configure/ConfigureCompanySettingsMenu';

const className = 'menu-ums-plans';

const staffOnlyPermissions = {
	perms: [
		{
			type: PERM_GRANT_TYPE,
			// plans: [...BUSINESS_PLAN_UMS_GROUP_VALUES],
			roles: [roles.STAFF],
		},
	],
};

export const menuBuilder = ({
	isUMSAdmin = false,
	isUMSManager = false,
	isUMSEmployee = false,
	isUMSEnabled = false,
	isUMSSurveyEnabled = false,
	isUMSManagerSurveyFilled = false,
	isUMSEmployeeSurveyFilled = false,
	withRoot = true,
	withFullMenu = true,
} = {
	isUMSAdmin: false,
	isUMSManager: false,
	isUMSEmployee: false,
	isUMSEnabled: false,
	isUMSSurveyEnabled: false,
	isUMSManagerSurveyFilled: false,
	isUMSEmployeeSurveyFilled: false,
	withRoot: true,
	withFullMenu: true,
}) => {
	LocalDebug.logInfo({ className, method: 'menuBuilder' }, {
		isUMSEnabled,
		isUMSSurveyEnabled,
		isUMSManagerSurveyFilled,
		isUMSEmployeeSurveyFilled,
		isUMSAdmin,
		isUMSManager,
		isUMSEmployee,
		withFullMenu,
	});

	const configureItems = [
		{ ...staffOnlyPermissions },
		{
			...(new ConfigureMenu()).build(),
			children: [
				(new ConfigureCompanySettingsMenu()).build(staffOnlyPermissions),
				(new ConfigureUsersMenu()).build(staffOnlyPermissions),
			],
			...staffOnlyPermissions,
		},
	];

	const staffItems = [
		{ perms: StaffMenu?.props?.perms || [] },
		(new StaffMenu()).build(),
	];

	return [
		(new UMSPresentationMenu()).build({ isRoot: withRoot }),
		(new UMSTeamMenu()).build(),
		(new UMSAdminResultsMenu()).build(),
		(new UMSSurveyMenu({
			isUMSManager,
			isUMSEmployee,
			isUMSManagerSurveyFilled,
			isUMSEmployeeSurveyFilled,
		})).build(),
		(new UMSManagerResultsMenu()).build(),
		(new UMSStaffMenu()).build(),
		...withFullMenu
			? [
				...configureItems,
				...staffItems,
			]
			: [],
	];
};

export default { menuBuilder };
