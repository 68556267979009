import {
	Button, Col, Divider, Popconfirm, Popover, Row, Space, Steps, Tooltip, Typography,
} from 'antd';
import { TEMPLATES_MAP } from '../constants/mailjet/mailjetTemplates';
import CompanyModel from './CompanyModel';
import UserMailingModel from './UserMailingModel';
import UserModel from './UserModel';
import { displayHtml, isValueDefined, momentShortenFrom } from '../utils/common';
import moment from 'moment';
import { BOOLEAN_NO_ICON, BOOLEAN_YES_ICON } from '../constants/constant';
import DatePreview from '../components/app/table/DatePreview';
import {
	MAILJET_STATUS_BLOCKED_ID,
	MAILJET_STATUS_BOUNCE_ID,
	MAILJET_STATUS_CLICKED_ID, MAILJET_STATUS_DEFERRED_ID, MAILJET_STATUS_HARDBOUNCE_ID, MAILJET_STATUS_OPENED_ID, MAILJET_STATUS_PROCESSED_ID, MAILJET_STATUS_QUEUED_LABEL, MAILJET_STATUS_SENT, MAILJET_STATUS_SENT_ID,
	MAILJET_STATUS_SOFTBOUNCE_ID,
	MAILJET_STATUS_SPAM_ID,
	MAILJET_STATUS_UNSUB_ID,
} from '../constants/mailjet/mailjetStatus';
import { MAILING_STATUS_TALENT_ANSWER_ACCEPTED_ID, MAILING_STATUS_TALENT_ANSWER_DECLINED_ID } from '../constants/mailing';
import { FaCommentDots } from 'react-icons/fa';
import ReactJsonDebug from '../components/app/debug/ReactJsonDebug';
import { useTheme } from '@emotion/react';
import { isNull } from 'lodash';
import Box from '../components/app/box/Box';
import { Link } from 'react-router-dom';
import { ReloadOutlined } from '@ant-design/icons';

export const ANSWER_STEP_SENT = 'ANSWER_STEP_SENT';
export const ANSWER_STEP_READ = 'ANSWER_STEP_READ';
export const ANSWER_STEP_STATUS_SET = 'ANSWER_STEP_STATUS_SET';
export const ANSWER_STEP_ANSWERED = 'ANSWER_STEP_ANSWERED';

export const MessageStatusTag = ({ status, ...props }) => {
	const theme = useTheme();
	return new UserMailingModel({ status })
		?.renderStatusTag?.(theme, props);
};

export const MessageStatusSentTag = (props = {}) => (
	<MessageStatusTag status={MAILJET_STATUS_SENT_ID} {...props} />
);
export const MessageStatusClickedTag = (props = {}) => (
	<MessageStatusTag status={MAILJET_STATUS_CLICKED_ID} {...props} />
);
export const MessageStatusOpenedTag = (props = {}) => (
	<MessageStatusTag status={MAILJET_STATUS_OPENED_ID} {...props} />
);
export const MessageStatusTalentAnswerAcceptedTag = (props = {}) => (
	<MessageStatusTag status={MAILING_STATUS_TALENT_ANSWER_ACCEPTED_ID} {...props} />
);
export const MessageStatusTalentAnswerDeclinedTag = (props = {}) => (
	<MessageStatusTag status={MAILING_STATUS_TALENT_ANSWER_DECLINED_ID} {...props} />
);
export const MessageStatusTalentAnswerTag = ({ message, ...props }) => {
	if (message?.answerStatus === 'yes') {
		return <MessageStatusTalentAnswerAcceptedTag {...props} />;
	}
	if (message?.answerStatus === 'no') {
		return <MessageStatusTalentAnswerDeclinedTag {...props} />;
	}
	return 'No answerStatus set';
};
export const MessageStatusTalentAnswerLine = ({ message, ...props }) => {
	const json = (
		<div style={{ fontSize: 8, fontWeight: 'normal' }}>
			{JSON.stringify({
				asModel: message.asModel ? 'true' : 'false',
				answeredAt: JSON.stringify(message.answeredAt || {}),
				messageReadAt: JSON.stringify(message?.answer?.messageReadAt || {}),
				isAnswered: message?.isAnswered,
				isRead: message?.isRead,
			})}
		</div>
	);

	if (message?.isAnswered) {
		return (
			<>
				<b>
					<span>Talent has </span>
					<MessageStatusTalentAnswerTag
						message={message}
						{...props}
					/>
					<span> the first approach message</span>
				</b>
			</>
		);
	}
	if (message?.isRead) {
		return (
			<>
				<b>
					<span>Talent has </span>
					<MessageStatusOpenedTag
						message={message}
						{...props}
						label='READ'
					/>
					<span> the first approach message</span>
				</b>
			</>
		);
	}
	return (
		<>
			Talent has not yet read the message.
		</>
	);
};
export const MessageStatusBlockedTag = (props = {}) => (
	<MessageStatusTag status={MAILJET_STATUS_BLOCKED_ID} {...props} />
);
export const MessageStatusBounceTag = (props = {}) => (
	<MessageStatusTag status={MAILJET_STATUS_BOUNCE_ID} {...props} />
);
export const MessageStatusDeferredTag = (props = {}) => (
	<MessageStatusTag status={MAILJET_STATUS_DEFERRED_ID} {...props} />
);
export const MessageStatusHardBounceTag = (props = {}) => (
	<MessageStatusTag status={MAILJET_STATUS_HARDBOUNCE_ID} {...props} />
);
export const MessageStatusQueuedTag = (props = {}) => (
	<MessageStatusTag status={MAILJET_STATUS_QUEUED_ID} {...props} />
);
export const MessageStatusSpamTag = (props = {}) => (
	<MessageStatusTag status={MAILJET_STATUS_SPAM_ID} {...props} />
);
export const MessageStatusSoftBounceTag = (props = {}) => (
	<MessageStatusTag status={MAILJET_STATUS_SOFTBOUNCE_ID} {...props} />
);
export const MessageStatusProcessedTag = (props = {}) => (
	<MessageStatusTag status={MAILJET_STATUS_PROCESSED_ID} {...props} />
);
export const MessageStatusSoftUnsubTag = (props = {}) => (
	<MessageStatusTag status={MAILJET_STATUS_UNSUB_ID} {...props} />
);

export default class UserMessageModel {
	userId;

	user;

	userToId;

	userTo;

	companyId;

	company;

	emailTemplateId;

	category;

	content; // { title, subject, body, company, jobs[], data }

	// TODO: to remove when remapped to lastRecipientMailing
	mailing;

	lastRecipientMailing;

	mailingHistory;

	answer;

	/*
	 {
		messageReadAt,
		status,
		createdAt,
		statusAt,
		content,
		sentAt,
		mailing,
		recipientMailing,
		mailingConfirmation,
		senderMailing
	}
	*/

	constructor(data) {
		Object.assign(this, data);
		this.asModel = true;
		this.user = data?.user ? new UserModel(data?.user) : null;
		this.userTo = data?.userTo ? new UserModel(data?.userTo) : null;
		this.company = data?.company ? new CompanyModel(data?.company) : null;
		this.mailing = data?.mailing ? new UserMailingModel(data?.mailing) : null;
		this.lastRecipientMailing = data?.lastRecipientMailing
			? new UserMailingModel(data?.lastRecipientMailing)
			: null;

		this.answer = data?.answer
			? {
				...data.answer || {},
				mailing: data?.answer?.mailing
					? new UserMailingModel(data?.answer?.mailing)
					: null,
				recipientMailing: data?.answer?.recipientMailing
					? new UserMailingModel(data.answer?.recipientMailing)
					: null,
				mailingConfirmation: data?.answer?.mailingConfirmation
					? new UserMailingModel(data?.answer.mailingConfirmation)
					: null,
				senderMailing: data?.answer?.senderMailing
					? new UserMailingModel(data?.answer?.senderMailing)
					: null,
			}
			: null;
	}

	get answerStatus() {
		return this?.answer?.status;
	}

	get sentAt() {
		return this?.recipientMailing?.sentAt;
	}

	get answeredAt() {
		return this?.answer?.sentAt;
	}

	get isAnswered() {
		return isValueDefined(this.answeredAt);
	}

	get isStatus() {
		return isValueDefined(this?.answer?.statusAt);
	}

	get isRead() {
		return isValueDefined(this?.answer?.messageReadAt);
	}

	get answerContentBody() {
		return this?.answer?.content?.body || this?.answer?.content;
	}

	get recipientMailing() {
		return this?.lastRecipientMailing || this?.mailing;
	}

	renderAnswerDates(theme = {}, { userMessage } = {}) {
		const message = this || userMessage;
		if (!message) return null;

		const lineStyles = {
			color: theme?.color?.grey || '#aaa',
			fontSize: 10,
			lineHeight: 1.1,
		};

		const dateStyles = {
			fontSize: 12,
			color: theme?.color?.white || '#fff',
		};

		return (
			<>
				{[
					{ label: 'Answer sent', date: message?.answer?.sentAt },
					{ label: 'Status set', date: message?.answer?.statusAt },
					{ label: 'Message read', date: message?.answer?.messageReadAt },
					{ label: 'Email sent', date: message?.recipientMailing?.sentAt },
				].map(({ label, date }, index) => (
					<Row key={index} style={{ minWidth: 380 }}>
						<Col span={6}>
							<Typography.Text
								style={{
									...dateStyles,
									fontWeight: 'bold',
									...date ? {} : { opacity: 0.5 },
								}}
							>
								{label}
							</Typography.Text>
						</Col>
						<Col span={5}>
							{date && (
								<Typography.Text style={{
									...dateStyles,
									...date ? {} : { opacity: 0.5 },
								}}>
									after {momentShortenFrom(moment(date)
										.from(moment(message?.createdAt)))}
								</Typography.Text>
							)}
						</Col>
						<Col span={13}>
							{date && (
								<>
									<Space size={0}>
										<DatePreview
											date={date}
											dateFormat='DD/MM/YY HH:mm'
											withDate={true}
											withFromNow={false}
											withToolip={false}
											styles={{ fromNow: dateStyles, date: dateStyles }}
										/>
										&nbsp;
										(<DatePreview
											date={date}
											dateFormat='DD/MM/YY HH:mm'
											withDate={false}
											withFromNow={true}
											withToolip={false}
											styles={{ fromNow: dateStyles, date: dateStyles }}
										/>)
									</Space>
								</>
							)}
						</Col>
					</Row>
				))}
			</>
		);
	}

	renderAnswerContentBodyIcon(theme, { contentBody } = {}) {
		const content = contentBody || this.answerContentBody;
		return content
			? (
				<Popover title={<b>Talent's answer</b>}
					content={
						<div style={{
							maxWidth: '600px',
							maxHeight: '60vh',
							overflow: 'auto',
							fontSize: 14,
						}}>
							{displayHtml(`"${content}"`)}
							<div
								style={{
									fontSize: 9,
									color: '#666',
									borderRadius: 4,
									fontStyle: 'italic',
									marginTop: 4,
								}}
							>
								This message was sent by email to the original sender on {moment(this.answeredAt).format('DD/MM/YYYY')}
								<br />Follow up with the talent from your email inbox
							</div>
						</div>}>
					<Button type='link' icon={<FaCommentDots style={{ marginBottom: -2 }} />} />
				</Popover>
			)
			: null;
	}

	get hasAnswerDetails() {
		const templateId = parseInt(this?.recipientMailing?.templateId, 10);
		if (templateId !== TEMPLATES_MAP.RECRUITER_USER_MESSAGE_FIRST_APPROACH_ANSWER.value) {
			return false;
		}
		return true;
	}

	get answerStep() {
		if (!this?.hasAnswerDetails) return null;

		if (this.isAnswered) return ANSWER_STEP_ANSWERED;
		if (this.isStatus) return ANSWER_STEP_STATUS_SET;
		if (this.isRead) return ANSWER_STEP_READ;
		return ANSWER_STEP_SENT;
	}

	renderStatusDisplay(
		theme = {},
		{
			userMessage,
			withToolTip = true,
			withStatusStep = true,
			withDate = true,
		} = {
			withDate: true,
			withStatusStep: true,
			withToolTip: false,
		},
	) {
		const message = this || userMessage;
		if (!message) return null;

		const lineStyles = {
			color: theme?.color?.grey || '#aaa',
			fontSize: 10,
			lineHeight: 1.1,
		};

		if (!this?.hasAnswerDetails) return <MessageStatusSentTag />;

		const tooltipWrapper = (children) => (
			withToolTip
				? (
					<Tooltip
						title={this.renderAnswerDates(theme)}
						overlayStyle={{ minWidth: 380 }}
					>
						{children}
					</Tooltip>
				)
				: children
		);

		if (message?.isAnswered) {
			return (
				<Space>
					{tooltipWrapper(<MessageStatusTalentAnswerTag message={message} />)}
				</Space>
			);
		}

		if (withStatusStep && message?.isStatus) {
			return tooltipWrapper(
				<Typography.Text style={lineStyles}>
					<Typography.Text
						style={{ ...lineStyles, fontWeight: 'bold' }}>
						Clicked '{message?.answer?.status}'
					</Typography.Text>&nbsp;
					after {
						momentShortenFrom(moment(message?.answer?.statusAt)
							.from(moment(message?.createdAt)))
					}
				</Typography.Text>,
			);
		}

		if (message?.isRead) {
			return tooltipWrapper(
				<Typography.Text style={lineStyles}>
					{new UserMailingModel({ status: MAILJET_STATUS_OPENED_ID })?.renderStatusTag?.(theme, { label: 'READ' })}
				</Typography.Text>,
			);
		}

		return tooltipWrapper(
			<Typography.Text style={lineStyles}>
				<MessageStatusSentTag />
			</Typography.Text>,
		);
	}

	renderAnswerDisplay(theme = {}, { userMessage, withDate = true } = { withDate: true }) {
		const message = this || userMessage;
		if (!message) return null;

		const lineStyles = {
			color: theme?.color?.grey || '#aaa',
			fontSize: 10,
			lineHeight: 1.1,
		};

		if (!this?.hasAnswerDetails) {
			return (
				<Tooltip title='Template ID not compatible with answer feature'>
					<div style={lineStyles}><i>N/A</i></div>
				</Tooltip>
			);
		}

		const tooltipWrapper = (children) => (
			<Tooltip
				title={this.renderAnswerDates(theme)}
				overlayStyle={{ minWidth: 380 }}
			>
				{children}
			</Tooltip>
		);

		if (!message?.answer) {
			return tooltipWrapper(<Typography.Text style={lineStyles}>Not answered yet!</Typography.Text>);
		}

		if (!message?.answerStatus && message?.answer?.messageReadAt) {
			return tooltipWrapper(
				<Typography.Text style={lineStyles}>
					<Typography.Text bold>Read</Typography.Text>&nbsp;
				after {
						momentShortenFrom(moment(message?.answer?.messageReadAt)
							.from(moment(message?.createdAt)))
					}
				</Typography.Text>,
			);
		}

		if (message?.answerStatus && !message?.answer?.sentAt) {
			return tooltipWrapper(
				<Typography.Text style={lineStyles}>
					<Typography.Text
						style={{ ...lineStyles, fontWeight: 'bold' }}>
						Clicked '{message?.answer?.status}'
					</Typography.Text>&nbsp;
					after {
						momentShortenFrom(moment(message?.answer?.statusAt)
							.from(moment(message?.createdAt)))
					}
				</Typography.Text>,
			);
		}

		const answerStatusIcon = (
			<span style={{ fontSize: 24 }}>
				{message?.answerStatus === 'yes'
					? BOOLEAN_YES_ICON
					: BOOLEAN_NO_ICON
				}
			</span>
		);

		return (
			<Space>
				{tooltipWrapper(<MessageStatusTalentAnswerTag message={message} />)}
				{this.renderAnswerContentBodyIcon(theme)}
			</Space>
		);
	}

	renderAnswerDisplayForRecruiter(
		theme = {},
		{ userMessage, withDate = true } = { withDate: true },
	) {
		const message = this || userMessage;
		if (!message) return null;

		const lineStyles = {
			color: theme?.color?.grey || '#aaa',
			fontSize: 10,
			lineHeight: 1.1,
		};

		const openedTag = new UserMailingModel({ status: MAILJET_STATUS_OPENED_ID })
			?.renderStatusTag?.();

		if (!this?.hasAnswerDetails) {
			const { status } = this?.recipientMailing || {};
			if ([MAILJET_STATUS_OPENED_ID, MAILJET_STATUS_CLICKED_ID].includes(status)) {
				return <MessageStatusOpenedTag />;
			}
			return <MessageStatusSentTag/>;
		}

		if (!message?.answer?.messageReadAt) {
			return <MessageStatusSentTag/>;
		}

		if (!message?.answer?.sentAt) {
			return <MessageStatusOpenedTag/>;
		}

		return (
			<Space>
				<MessageStatusTalentAnswerTag message={message} />
				{this.renderAnswerContentBodyIcon(theme)}
			</Space>
		);
	}

	renderTimeline({ userMessage, isStaffView }) {
		const message = userMessage || this;

		const dateStyles = {
			fontSize: 12,
			color: '#666',
		};

		const buildDesc = (date) => (
			<Space direction='vertical' size={0} style={dateStyles}>
				{date
					? (
						<>
							<Space size={5}>
								<DatePreview
									date={date}
									dateFormat='DD/MM/YY HH:mm'
									withDate={true}
									withFromNow={false}
									withToolip={false}
									styles={{ fromNow: dateStyles, date: dateStyles }}
								/>
								<div>
								(<DatePreview
										date={date}
										dateFormat='DD/MM/YY HH:mm'
										withDate={false}
										withFromNow={true}
										withToolip={false}
										styles={{ fromNow: dateStyles, date: dateStyles }}
									/>)
								</div>
							</Space>
							<div>
								<b>After {momentShortenFrom(moment(date).from(moment(message?.createdAt)))}</b>
							</div>
						</>
					)
					: 'Waiting'
				}
			</Space>
		);

		let currentAnswerStep = message?.answerStep;
		if (!isStaffView && currentAnswerStep === ANSWER_STEP_STATUS_SET) {
			currentAnswerStep = ANSWER_STEP_READ;
		}
		let answeredStepTitle = 'Email answered';
		if (currentAnswerStep === ANSWER_STEP_ANSWERED) {
			answeredStepTitle = message?.answerStatus === 'yes'
				? <>Email answered <MessageStatusTalentAnswerAcceptedTag /></>
				: <>Email answered <MessageStatusTalentAnswerDeclinedTag /></>;
		}

		const items = [
			{
				title: 'Email sent',
				description: buildDesc(message?.recipientMailing?.sentAt),
				step: ANSWER_STEP_SENT,
			},
			{
				title: 'Email read',
				description: buildDesc(message?.answer?.messageReadAt),
				step: ANSWER_STEP_READ,
			},
			...isStaffView
				? [
					{
						title: 'Status set',
						description: buildDesc(message?.answer?.statusAt),
						step: ANSWER_STEP_STATUS_SET,
					},
				]
				: [],

			{
				title: answeredStepTitle,
				description: buildDesc(message?.answer?.sentAt),
				step: ANSWER_STEP_ANSWERED,
			},
		];

		const current = items.map(({ step }) => step).indexOf(currentAnswerStep);

		return (
			<>
				<Steps
					progressDot
					current={current}
					direction='vertical'
					items={items}
				/>
			</>
		);
	}

	get isExpandable() {
		return isValueDefined(this.answerStep);
	}

	renderExpand({ userMessage, isStaffView, handleRetrySend }) {
		const message = userMessage || this;

		return (
			<div style={{ position: 'relative' }}>
				<Row gutter={[10, 10]}>

					<Col span={6}>
						<h4 style={{ marginBottom: 8 }}>Timeline</h4>
						<Box style={{ background: 'white', padding: 20 }}>
							{message.renderTimeline({ isStaffView })}
						</Box>
					</Col>

					<Col span={9}>
						<h4 style={{ marginBottom: 8 }}>Message sent</h4>
						<Box style={{ background: 'white', padding: 20 }}>
							<div
								style={{
									fontSize: 9,
									color: '#666',
									fontStyle: 'italic',
								}}
							>
								<span>The following message was sent by email to the talent
									on {moment(message?.answeredAt).format('DD/MM/YYYY')}
								<br />The talent answer will be received in your email inbox
								</span>
							</div>
							<Divider />
							<div style={{
								fontSize: 11,
							}}>
								<b>Subject:</b>
								<br/>
								{displayHtml(message?.content?.subject)}
								<br/>
								<b>Body:</b>
								{displayHtml(message?.content?.body)}
							</div>
						</Box>
					</Col>

					<Col span={9}>
						<h4 style={{ marginBottom: 8 }}>Talent answer</h4>
						<Box style={{ background: 'white', padding: 20 }}>
							{message?.answeredAt
								? (
									<>
										<b><MessageStatusTalentAnswerLine message={message} /></b>
										<div
											style={{
												fontSize: 9,
												color: '#666',
												fontStyle: 'italic',
											}}
										>
											<span>The following message was sent by email to the original
											sender on {moment(message?.answeredAt).format('DD/MM/YYYY')}
											<br />Follow up with the talent from your email inbox
											</span>
										</div>
										<Divider />
										<div style={{
											fontSize: 11,
										}}>
											{displayHtml(message?.answerContentBody)}
										</div>
									</>
								)
								: (
									<>
										<b><MessageStatusTalentAnswerLine message={message} /></b>
										{handleRetrySend && (
											<p>
												<Popconfirm
													title={(
														<div
															style={{ maxWidth: 300 }}
														>
															<b>Are you sure?</b>
															<br />
														Beware, it will send again the email from the recruiter to the talent.
														</div>
													)}
													onConfirm={(e) => {
														handleRetrySend(message);
														e.stopPropagation?.();
														e.preventDefault?.();
													}}
												>
													<Link>
														<ReloadOutlined /> Resend message
													</Link>
												</Popconfirm>
											</p>
										)}
									</>
								)
							}
						</Box>
					</Col>
				</Row>
			</div>
		);
	}
}
