import { Space, Typography } from 'antd';
import { useDispatch } from 'react-redux';
import { useTheme } from '@emotion/react';

import { doLogin, showResetPassword } from '../../actions/app';
import LoginForm from './LoginForm';
import { useEffect, useState } from 'react';
import { useAuthContext } from '../../hooks/useAuthContext';

const LoginPage = () => {
	const dispatch = useDispatch();
	const theme = useTheme();

	const { currentUser } = useAuthContext();
	const [isSubmitting, setIsSubmitting] = useState(false);

	useEffect(() => {
		setIsSubmitting(!!currentUser);
	}, [currentUser]);

	const handleLogin = async (values) => {
		setIsSubmitting(true);
		await dispatch(doLogin(values));
		setIsSubmitting(false);
	};

	return (
		<div
			className='flexCenter flexRow login'
			style={{
				height: 'calc(100vh - 100px)',
				opacity: currentUser ? 0 : 1,
			}}
		>
			<Space direction='vertical' size={10} align='center'>
				<img
					width='120'
					alt='Logo 50inTech'
					className='logo'
					loading='lazy'
					src='https://res.cloudinary.com/dkatmtbbc/image/upload/v1643941794/app/50-logo-full-blue_hzz6di.svg'
				/>
				<Typography.Title level={1} style={{ color: theme.color.blue, marginBottom: '10px' }}>
					For Companies
				</Typography.Title>
				<div
					style={{
						width: 370,
						padding: 20,
						borderRadius: 10,
						boxShadow: '0 2px 20px -2px rgba(0,0,0,.2)',
					}}>
					<LoginForm
						onSubmit={handleLogin}
						onResetPassword={showResetPassword}
						isSubmitting={isSubmitting}
					/>
				</div>
			</Space>
		</div>
	);
};

export default LoginPage;
