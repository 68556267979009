import { Space } from 'antd';
import { useState } from 'react';
import Link from '../app/Link';
import { documentId, writeLog } from '../../utils/common';
import JobDrawerDetails from './JobDrawerDetails';

const JobLinkDropdown = (
	{
		job,
		jobActiveTab = null,
		onEditJob,
		onSelectJob,
		withEditLink = false,
		withOpenLink = false,
		children,
		...props
	},
) => {
	const [openDrawer, setOpenDrawer] = useState(false);

	const handleOpenDrawer = (e) => {
		writeLog('open job drawer', { job: documentId(job) });
		e?.stopPropagation();
		setOpenDrawer(true);
	};

	const handleCloseDrawer = () => {
		setOpenDrawer(false);
	};

	return <>
		<Space direction="vertical">
			<Link onClick={(e) => {
				if (onSelectJob instanceof Function) {
					onSelectJob(job);
				} else {
					handleOpenDrawer(e);
				}
			}}>
				{children}
			</Link>
			{withEditLink && <Link onClick={openDrawer}>Edit job</Link>}
			{withOpenLink && <Link onClick={openDrawer}>Job details</Link>}
		</Space>

		<JobDrawerDetails
			job={job}
			jobActiveTab={jobActiveTab}
			onClose={handleCloseDrawer}
			open={openDrawer}
			onEditJob={() => (onEditJob instanceof Function ? onEditJob(job) : null)}
		/>
	</>;
};

export default JobLinkDropdown;
