import {
	Col, DatePicker, Form, Input, Row, Select, Tag, Typography,
} from 'antd';
import { useEffect, useState } from 'react';
import { DATE_FORMAT } from '../../constants/constant';
import FormItem from '../company/form/FormItem';
import { localDebug } from '../../utils/LocalDebug';
import FilterModal from '../app/filters/FilterModal';
import { useAuthContext } from '../../hooks/useAuthContext';
import { useSelector } from 'react-redux';
import { getCompanies } from '../../reducers/app';
import { documentId, getQueryParams } from '../../utils/common';
import FormSelect from '../app/form/FormSelect';
import { MAILING_STATUS_LIST } from '../../constants/mailing';
import { TEMPLATES_LIST } from '../../constants/mailjet/mailjetTemplates';
import LangFlagColorTag from '../app/tag/LangFlagColorTag';
import { useTheme } from '@emotion/react';

const UserMailingFilterModal = ({
	title,
	open,
	initialValues = {},
	onClose,
	onFilter,
}) => {
	const className = 'UserMailingFilterModal';
	const theme = useTheme();
	const { isStaff, isStaffView, isCompanyRecruiter } = useAuthContext();

	const [form] = Form.useForm();

	const companies = useSelector(getCompanies);
	const queryParams = getQueryParams();
	const [optionsCompany, setOptionsCompany] = useState([]);

	const getDefaultSelected = (fieldName) => {
		return queryParams[fieldName] || [];
	};

	useEffect(() => {
		setOptionsCompany([...companies]);
	}, [companies, isStaff, isStaffView]);

	const handleFilter = () => {
		form
			.validateFields()
			.then((values) => {
				const filtersValues = { ...values };
				if (onFilter) onFilter(filtersValues);
			})
			.catch((errors) => {
				localDebug('Validate Failed:', errors);
			});
	};

	useEffect(() => {
		form.resetFields();
		form.setFieldsValue(initialValues);
	}, [form, initialValues]);

	return (
		<FilterModal
			title={title}
			width={750}
			open={open}
			onClose={onClose}
			onFilter={handleFilter}
		>
			<Form
				form={form}
				name="filters"
				labelCol={{ span: 12 }}
				labelAlign='right'
				className="user-filter-form"
			>
				<Row gutter={0}>
					<Col span={24}>
						<FormItem
							label='Template'
							name='templateId'
							labelCol={{ span: 6 }}
						>
							<FormSelect options={TEMPLATES_LIST}>
								{TEMPLATES_LIST.map((option, index) => {
									return (
										<Select.Option key={index} value={option.value}>
											{option.label}
											&nbsp;
											{option.lang && <LangFlagColorTag
												lang={option.lang} />}
											{option.platforms?.length > 0
												&& <>
													&nbsp;
													<Tag
														color={theme.color.darkgrey}
														size='small'
														style={{
															borderRadius: 5,
															padding: '1px 3px',
															height: 'unset',
															lineHeight: 1.2,
															fontSize: 10,
															fontWeight: 'bold',
														}}
													>
														{option.platforms?.join(', ')}
													</Tag>
												</>
											}
											&nbsp;
											<Typography.Text
												type='secondary'
												style={{ fontSize: 10 }}
											>
												{option.value}
											</Typography.Text>
										</Select.Option>
									);
								})}
							</FormSelect>
						</FormItem>
					</Col>
					<Col span={12}>
						<FormItem label='UserMessage ID' name='userMessageId'>
							<Input />
						</FormItem>
					</Col>
					<Col span={12}>
						<FormItem label='UserMailing ID' name='ids'>
							<Input />
						</FormItem>
					</Col>
					<Col span={12}>
						<FormItem label='Recipient type' name='recipientType'>
							<FormSelect
								mode={'multiple'}
								options={[
									{ value: 'to', label: 'To' },
									{ value: 'cc', label: 'Cc' },
									{ value: 'bcc', label: 'Bcc' },
								]} />
						</FormItem>
					</Col>
					{/* <Col span={12}>
						<FormItem label='Retried' name='retried'>
							<FormSelect options={BOOLEAN_OR_NULL_OPTIONS} />
						</FormItem>
					</Col> */}
					<Col span={12}>
						<FormItem label='Status' name='status'>
							<FormSelect options={MAILING_STATUS_LIST.map((status) => ({ ...status, value: documentId(status) }))} />
						</FormItem>
					</Col>
					<Col span={12}>
						<FormItem
							label='Created before'
							name="createdBefore"
						>
							<DatePicker showTime format={DATE_FORMAT}/>
						</FormItem>
					</Col>
					<Col span={12}>
						<FormItem
							label='Created after'
							name="createdAfter"
						>
							<DatePicker showTime format={DATE_FORMAT}/>
						</FormItem>
					</Col>
				</Row>
			</Form>
		</FilterModal>
	);
};

export default UserMailingFilterModal;
