import roles from '../../config/roles';
import {
	PERMS_OVERRIDE_STAFF_BACKUP,
	PERM_DENY_TYPE,
	PERM_GRANT_TYPE,
} from '../menu.utils';

export const PERMS_GENDERSCORE_SURVEY_ENABLED = [
	{
		type: PERM_GRANT_TYPE,
		roles: [
			roles.DOCUMENT_ADMIN,
		],
		genderScoreSurveyEnabled: true,
	},
	{
		...PERMS_OVERRIDE_STAFF_BACKUP || {},
		genderScoreSurveyEnabled: true,
	},
];
export const PERMS_GENDERSCORE_FILLED = [
	{
		type: PERM_GRANT_TYPE,
		genderScoreFilled: true,
	},
	{
		type: PERM_DENY_TYPE,
		roles: [
			roles.DOCUMENT_EDITOR_ONLY,
		],
	},
	{
		...PERMS_OVERRIDE_STAFF_BACKUP || {},
		genderScoreFilled: true,
	},
];
export const PERMS_GENDERSCORE_NOT_FILLED = [
	{
		type: PERM_GRANT_TYPE,
		genderScoreFilled: false,
	},
	{
		type: PERM_DENY_TYPE,
		roles: [
			roles.DOCUMENT_EDITOR_ONLY,
		],
	},
	{
		...PERMS_OVERRIDE_STAFF_BACKUP || {},
		genderScoreFilled: false,
	},
];
export const PERMS_GENDERCORE_ENABLED = [
	{
		type: PERM_GRANT_TYPE,
		roles: [
			roles.STAFF,
			roles.DOCUMENT_ADMIN,
		],
		genderScoreEnabled: true,
	},
];
export const getScoreColorMain = (theme) => (
	theme?.color?.blue
	|| '#0d2b79'
);

export default {
	PERMS_GENDERSCORE_FILLED,
};
