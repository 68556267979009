import { round } from '../../../utils/common';

export const INPUT_TEXT = 'text';
export const INPUT_TEXTAREA = 'textarea';
export const INPUT_PCT = 'pct';
export const INPUT_NUMBER = 'number';
export const INPUT_SINGLE = 'single';
export const INPUT_MULTIPLE = 'multiple';
export const INPUT_AGREE = 'agree';

const textInput = { input: INPUT_TEXT };
const textareaInput = { input: INPUT_TEXTAREA };
const pctInput = { input: INPUT_PCT };
const numberInput = { input: INPUT_NUMBER };
const singleInput = { input: INPUT_SINGLE };
const multipleInput = { input: INPUT_MULTIPLE };
const agreeInput = { input: INPUT_AGREE };

const buildNumberImporter = (decimals = 0) => (value) => round(Number(value), decimals);

const ratingOptions = {
	...agreeInput,
	options: [
		{ label: '1', value: 1, score: 0 },
		{ label: '2', value: 2, score: 1 },
		{ label: '3', value: 3, score: 2 },
		{ label: '4', value: 4, score: 3 },
		{ label: '5', value: 5, score: 4 },
		{ label: '6', value: 6, score: 5 },
	],
	leftText: {
		fr: 'Pas du tout d\'accord',
		en: 'Totally disagree',
	},
	rightText: {
		fr: 'Totalement d\'accord',
		en: 'Totally agree',
	},
	columns: 6,
	align: 'horizontal',
	import: buildNumberImporter(0),
	autoSave: true,
};

const sublabels = {
	assertive: {
		fr: '"Assertif" désigne une manière confiante et directe d\'exprimer ses pensées et sentiments tout en respectant autrui. Cela implique de défendre ses propres intérêts et d\'établir des limites sans être agressif.',
		en: '"Assertive" describes a confident and direct way of expressing thoughts and feelings while respecting others. It involves standing up for oneself and setting boundaries without aggression.',
	},
	personalCharacteristics: {
		fr: 'Les caractéristiques personnelles renvoient aux éléments qui définissent une personne. Cela inclut les traits de personnalité, la manière d\'agir, ou encore les émotions.',
		en: 'Personal characteristics refer to the elements that define a person. This includes personality traits, ways of acting, and emotions.',
	},
};
const QUESTIONS = {
	CONTACT_PERSONAL: {
		questionId: '',
		label: {
			fr: ['Veuillez indiquer vos **informations personnelles** de contact'],
			en: [''],
		},
		sublabel: {
			fr: ['Ces informations sont strictement confidentielles, et ne serviront qu\'à vous notifier si nécessaire. Vos réponses à ce questionnaire seront anonymisées.'],
			en: [''],
		},
	},
	CONTACT_MANAGER: {
		questionId: '',
		label: {
			fr: ['Veuillez indiquer le prénom et le nom de **votre manager**'],
			en: [''],
		},
		sublabel: {
			fr: ['Cette information nous sert uniquement à vous rattacher à votre manager, aucun donnée identifiable sur vos réponses à ce questionnaire ne lui sera communiqué.'],
			en: [''],
		},
	},
	MGR_SFFP_PREDICTIONS_CONFIRMED: {
		bias: 'Self-Fullfilling Prophecy',
		questionId: 'MGR_SFFP_PREDICTIONS_CONFIRMED',
		label: {
			fr: ['Mes **prédictions** à propos des performances de mes employé·e·s sont **toujours confirmées.**'],
			en: ['**My** **predictions** about the performance of my employees are often **confirmed.**'],
		},
		...ratingOptions,
	},
	MGR_SFFP_REMEMBER_IF_CONFIRM: {
		bias: 'Self-Fullfilling Prophecy',
		questionId: 'MGR_SFFP_REMEMBER_IF_CONFIRM',
		label: {
			fr: ['Je me souviens des résultats qui viennent **confirmer les attentes** sur mes employé·e·s.'],
			en: ['I tend to remember the results that **confirm my expectations** of my employees.'],
		},
		...ratingOptions,
	},
	MGR_SFFP_BEHAVE_DIFFERENTLY: {
		bias: 'Self-Fullfilling Prophecy',
		questionId: 'MGR_SFFP_BEHAVE_DIFFERENTLY',
		label: {
			fr: ['Je me comporte **différemment** avec les employé·e·s que je considère **les plus performant·e·s.**'],
			en: ['I tend to behave **differently** with employees whom I consider to be the **most performing.**'],
		},
		...ratingOptions,
	},
	MGR_SFFP_ALLOCATE_MORE_TIME: {
		bias: 'Self-Fullfilling Prophecy',
		questionId: 'MGR_SFFP_ALLOCATE_MORE_TIME',
		label: {
			fr: ['Je consacre plus de temps aux employé·e·s que **je considère comme plus performant·e·s.**'],
			en: ['I allocate **more time** to employees whom I consider to be **more** **performant.**'],
		},
		...ratingOptions,
	},
	MGR_SFFP_EXPECTATIONS_RESPONSIBILITIES: {
		bias: 'Self-Fullfilling Prophecy',
		questionId: 'MGR_SFFP_EXPECTATIONS_RESPONSIBILITIES',
		label: {
			fr: ['Je me base sur **mes attentes** pour donner plus de responsabilités ou non à un·e employé·e.'],
			en: ['I rely on my **expectations** to assign **more responsibilities** to an employee.'],
		},
		...ratingOptions,
	},
	MGR_SFFP_PERSONAL_INFORM_PERFORMANCE: {
		bias: 'Self-Fullfilling Prophecy',
		questionId: 'MGR_SFFP_PERSONAL_INFORM_PERFORMANCE',
		label: {
			fr: ['Je prends en compte certaines **caractéristiques personnelles** de mes employé·e·s pour **évaluer leurs performances.**'],
			en: ['I take into account certain **personal characteristics** of my employees to evaluate their performance.'],
		},
		sublabel: sublabels.personalCharacteristics,
		...ratingOptions,
	},
	MGR_SFFP_EXPECTATIONS_BEHAVIOR: {
		bias: 'Self-Fullfilling Prophecy',
		questionId: 'MGR_SFFP_EXPECTATIONS_BEHAVIOR',
		label: {
			fr: ["Mon comportement avec mes employé·e·s est influencé par **mes attentes initiales** que j'ai sur eux·elles."],
			en: ['My **initial expectations** tend to **influence** my behaviour with my employees.'],
		},
		...ratingOptions,
	},
	MGR_BLE_SAME_XP_NOT_SAME_SALARY: {
		bias: 'Backlash Effect',
		questionId: 'MGR_BLE_SAME_XP_NOT_SAME_SALARY',
		label: {
			fr: ["Lorsque je décide du **salaire d'un·e employé·e,** je prends en compte ses **caractéristiques personnelles** qui vont au-delà de son niveau d'expérience."],
			en: ["When deciding on an **employee's salary**, I take into account their **personal characteristics** that go beyond their level of experience."],
		},
		sublabel: sublabels.personalCharacteristics,
		...ratingOptions,
	},
	MGR_BLE_SAME_TEAM_NOT_DISCUSSIONS: {
		bias: 'Backlash Effect',
		questionId: 'MGR_BLE_SAME_TEAM_NOT_DISCUSSIONS',
		label: {
			fr: ['Je ne choisis que **certain·e·s employé·e·s** pour les **discussions importantes**, laissant de côté les autres appartenant à la **même équipe** et ayant le **même niveau professionnel.**'],
			en: ['Some employees in the same team **don’t have to participate** in important discussions.'],
		},
		...ratingOptions,
	},
	MGR_BLE_IDEAS_CERTAIN_EMPS: {
		bias: 'Backlash Effect',
		questionId: 'MGR_BLE_IDEAS_CERTAIN_EMPS',
		label: {
			fr: ['Je ne prends en compte que **les idées de certain·e·s de mes employé·e·s.**'],
			en: ['I tend to consider ideas **only** from certain employees.'],
		},
		...ratingOptions,
	},
	MGR_BLE_UNASSERTIVE_NO_RESP: {
		bias: 'Backlash Effect',
		questionId: 'MGR_BLE_UNASSERTIVE_NO_RESP',
		label: {
			fr: ["Indépendamment des performances, je ne donne pas de **responsabilités significatives** aux individus que je considère comme **incapables d'être assertif·ve·s.**"],
			en: ["Independently of performance, I don't give significant responsibilities to individuals I consider **unable** to be assertive."],
		},
		sublabel: sublabels.assertive,
		...ratingOptions,
	},
	MGR_BLE_TRAITS_LIMIT_PROMOTION: {
		bias: 'Backlash Effect',
		questionId: 'MGR_BLE_TRAITS_LIMIT_PROMOTION',
		label: {
			fr: ["Certaines **caractéristiques personnelles** peuvent limiter les chances d'un·e employé·e d'avoir une **promotion ou une augmentation de salaire.**"],
			en: ["I believe that **certain personality traits** can limit an employee's potential for a promotion or a salary increase."],
		},
		sublabel: sublabels.personalCharacteristics,
		...ratingOptions,
	},
	MGR_BLE_NOT_CONSIDER_IF_OBLIGATIONS: {
		bias: 'Backlash Effect',
		questionId: 'MGR_BLE_NOT_CONSIDER_IF_OBLIGATIONS',
		label: {
			fr: ['Certain·e·s des employé·e·s ne sont pas pris·es en considération pour certains projets dû à **leurs obligations personnelles.**'],
			en: ["It's normal for some employees not to be considered for certain projects due to **their** **personal obligations.**"],
		},
		...ratingOptions,
	},
	MGR_BLE_SOCIAL_SKILLS_EVAL: {
		bias: 'Backlash Effect',
		questionId: 'MGR_BLE_SOCIAL_SKILLS_EVAL',
		label: {
			fr: ["Je prends particulièrement en compte les **compétences sociales** lorsque j'évalue une **employée femme.**"],
			en: ['I particularly take into account **social skills** when evaluating a **female employee.**'],
		},
		...ratingOptions,
	},
	MGR_HHE_TRUST_INITIAL_IMP_EVAL: {
		bias: 'Halo effect / horns effect',
		questionId: 'MGR_HHE_TRUST_INITIAL_IMP_EVAL',
		label: {
			fr: ["Je me fie toujours à ma **première impression** quand j'évalue une personne."],
			en: ['I always trust **my initial impression** when evaluating people.'],
		},
		...ratingOptions,
	},
	MGR_HHE_FIRST_MEETING_GOOD_WORK: {
		bias: 'Halo effect / horns effect',
		questionId: 'MGR_HHE_FIRST_MEETING_GOOD_WORK',
		label: {
			fr: ["Je sais **dès la première rencontre** avec un·e employé·e s'il·elle va faire du bon travail ou non."],
			en: ['I know from the **first meeting** with an employee whether they will do good work or not.'],
		},
		...ratingOptions,
	},
	MGR_HHE_POSITIVE_ATTRIBUTE_MORE: {
		bias: 'Halo effect / horns effect',
		questionId: 'MGR_HHE_POSITIVE_ATTRIBUTE_MORE',
		label: {
			fr: ["Quand je perçois quelqu'un de **manière positive** dès la première fois, je lui attribue **d'autres caractéristiques positives** sans en avoir fait l'expérience."],
			en: ['When I perceive someone **positively** for the first time, I believe I can attribute **other positive characteristics** to them without experiencing them.'],
		},
		sublabel: sublabels.personalCharacteristics,
		...ratingOptions,
	},
	MGR_HHE_NEGATIVE_ATTRIBUTE_MORE: {
		bias: 'Halo effect / horns effect',
		questionId: 'MGR_HHE_NEGATIVE_ATTRIBUTE_MORE',
		label: {
			fr: ["Si je perçois quelqu'un de **manière négative** dès la première fois, je lui attribue **d'autres caractéristiques négatives** sans en avoir fait l'expérience."],
			en: ['When I perceive someone **negatively** for the first time, I believe I can attribute **other negative characteristics** to them without experiencing them.'],
		},
		sublabel: sublabels.personalCharacteristics,
		...ratingOptions,
	},
	MGR_HHE_FIRST_IMP_EVALUATION: {
		bias: 'Halo effect / horns effect',
		questionId: 'MGR_HHE_FIRST_IMP_EVALUATION',
		label: {
			fr: ["Ma perception d'un·e employé·e est déterminée par la **première impression** que j'ai de lui·elle."],
			en: ['My evaluation of an employee is often determined by **the first impression** I have of them.'],
		},
		...ratingOptions,
	},
	MGR_HHE_POSITIVE_FEEDBACK_GOOD_IMP: {
		bias: 'Halo effect / horns effect',
		questionId: 'MGR_HHE_POSITIVE_FEEDBACK_GOOD_IMP',
		label: {
			fr: ["Je donne des **retours positifs** aux employé·e·s qui m'ont fait une **première impression positive**, au-delà de leurs performances."],
			en: ['I am more inclined to give **positive feedback** to employees for whom I have formed a **good first impression**, beyond their performance.'],
		},
		...ratingOptions,
	},
	MGR_HHE_NEGATIVE_IMP_NEGATIVE_EVAL: {
		bias: 'Halo effect / horns effect',
		questionId: 'MGR_HHE_NEGATIVE_IMP_NEGATIVE_EVAL',
		label: {
			fr: ["Les employé·e·s pour qui j'ai une **première mauvaise impression** reçoivent **plus d'évaluations négatives.**"],
			en: ['Employees for whom I initially had a **negative impression** receive more **negative evaluations.**'],
		},
		...ratingOptions,
	},
	MGR_REC_REMEMBER_MOST_RECENT: {
		bias: 'Recency bias',
		questionId: 'MGR_REC_REMEMBER_MOST_RECENT',
		label: {
			fr: ['Je me souviens plus facilement des **accomplissements les plus récents** de mes employé·e·s.'],
			en: ["I tend to **remember** my employees' **most recent accomplishments** more easily."],
		},
		...ratingOptions,
	},
	MGR_REC_LATEST_PERF_REFLECT_OVERALL: {
		bias: 'Recency bias',
		questionId: 'MGR_REC_LATEST_PERF_REFLECT_OVERALL',
		label: {
			fr: ["**Les dernières performances** de mes employé·e·s reflètent l'ensemble de leur travail."],
			en: ['**The** **latest performances** of my employees tend to reflect their overall work.'],
		},
		...ratingOptions,
	},
	MGR_REC_MOST_RECENT_EVALUATION: {
		bias: 'Recency bias',
		questionId: 'MGR_REC_MOST_RECENT_EVALUATION',
		label: {
			fr: ['Je préfère prendre en compte **les performances les plus récentes** de mes employé·e·s pour les évaluer.'],
			en: ['I prefer to consider **the most recent performances** of my employees for evaluation.'],
		},
		...ratingOptions,
	},
	MGR_REC_UNNECESSARY_CONSIDER_BEGINNING: {
		bias: 'Recency bias',
		questionId: 'MGR_REC_UNNECESSARY_CONSIDER_BEGINNING',
		label: {
			fr: ["Ce n'est **pas nécessaire** de prendre en compte la performance **dès le début.**"],
			en: ["I believe it's **not necessary** to consider employees' performances **from the beginning.**"],
		},
		...ratingOptions,
	},
	MGR_REC_LATEST_ACCOMPS_INFORM_FUTURE: {
		bias: 'Recency bias',
		questionId: 'MGR_REC_LATEST_ACCOMPS_INFORM_FUTURE',
		label: {
			fr: ["**Le dernier accomplissement professionnel** d'un·e employé·e reflète ce qu'il·elle peut faire dans les prochains mois."],
			en: ["An employee's **latest accomplishment** reflects what they will be able to do in the coming months."],
		},
		...ratingOptions,
	},
	MGR_REC_LATEST_ACCOMPS_EVALUATION: {
		bias: 'Recency bias',
		questionId: 'MGR_REC_LATEST_ACCOMPS_EVALUATION',
		label: {
			fr: ["Je prends en compte uniquement **les derniers accomplissements professionnels** pour évaluer l'ensemble du travail d'un·e employé·e."],
			en: ["**The latest accomplishments** are the **only ones** to consider for evaluating an employee's overall work."],
		},
		...ratingOptions,
	},
	MGR_REC_MOST_RECENT_INFORM_CAPABILITIES: {
		bias: 'Recency bias',
		questionId: 'MGR_REC_MOST_RECENT_INFORM_CAPABILITIES',
		label: {
			fr: ["Les **performances les plus récentes** d'un·e employé·e sont plus indicatives de ses compétences que ses **performances passées.**"],
			en: ["I believe that an employee's **most recent performance** is more indicative of their capabilities than their **past achievements.**"],
		},
		...ratingOptions,
	},
	MGR_IGF_COMMON_CHARS_IMPORTANT_WORK: {
		bias: 'In-group Effect',
		questionId: 'MGR_IGF_COMMON_CHARS_IMPORTANT_WORK',
		label: {
			fr: ['Les **caractéristiques communes** que je partage avec mes employé·e·s sont particulièrement importantes pour pouvoir **travailler ensemble.**'],
			en: ['The **common characteristics** I share with my employees are particularly important to **work together.**'],
		},
		sublabel: sublabels.personalCharacteristics,
		...ratingOptions,
	},
	MGR_IGF_TEAM_LOOK_LIKE_MGR: {
		bias: 'In-group Effect',
		questionId: 'MGR_IGF_TEAM_LOOK_LIKE_MGR',
		label: {
			fr: ["Il est important pour un·e responsable d'avoir une équipe qui lui **ressemble.**"],
			en: ["I think it's important for a manager to have a team that **looks like** him/her."],
		},
		...ratingOptions,
	},
	MGR_IGF_SIMILAR_PEOPLE_WORK_BETTER: {
		bias: 'In-group Effect',
		questionId: 'MGR_IGF_SIMILAR_PEOPLE_WORK_BETTER',
		label: {
			fr: ['Les personnes **similaires** travaillent **mieux** ensemble.'],
			en: ['**Similar people** tend to work **better** together.'],
		},
		...ratingOptions,
	},
	MGR_IGF_TRAITS_SHARED_EVALUATION: {
		bias: 'In-group Effect',
		questionId: 'MGR_IGF_TRAITS_SHARED_EVALUATION',
		label: {
			fr: ['Bien que les compétences professionnelles sont importantes, je donne une grande importance aux **caractéristiques personnelles** que je **partage** avec mes employé·e·s.'],
			en: ["Even though professional skills are important, I think it's crucial to take into account **the traits I share** with my employees for their evaluation."],
		},
		sublabel: sublabels.personalCharacteristics,
		...ratingOptions,
	},
	MGR_IGF_CLOSER_RESEMBLE: {
		bias: 'In-group Effect',
		questionId: 'MGR_IGF_CLOSER_RESEMBLE',
		label: {
			fr: ['Je me sens **proche** des employé·e·s qui me **ressemblent.**'],
			en: ['I feel **closer** to my employees when they **resemble me.**'],
		},
		...ratingOptions,
	},
	MGR_IGF_SIMILARITIES_ENJOY: {
		bias: 'In-group Effect',
		questionId: 'MGR_IGF_SIMILARITIES_ENJOY',
		label: {
			fr: ["Plus j'ai des **points communs** avec un·e employé·e, plus **j'aime travailler** avec il·elle."],
			en: ['The more **similarities** I have with employees, the more I **enjoy working** with them.'],
		},
		...ratingOptions,
	},
	MGR_IGF_RELY_IF_COMMON_CHARS: {
		bias: 'In-group Effect',
		questionId: 'MGR_IGF_RELY_IF_COMMON_CHARS',
		label: {
			fr: ["Je **m'appuie** sur les employé·e·s qui partagent des **caractéristiques communes** avec moi."],
			en: ['I am more likely to **rely** on employees who share **common characteristics** with me.'],
		},
		sublabel: sublabels.personalCharacteristics,
		...ratingOptions,
	},
	MGR_OVC_SKILLS_BEYOND_REQ: {
		bias: 'Overly critical',
		questionId: 'MGR_OVC_SKILLS_BEYOND_REQ',
		label: {
			fr: ['Il est normal que mes employé·e·s aient des compétences qui vont **au-delà de ce que leur poste requiert.**'],
			en: ["It's normal for my employees to have skills that go **beyond what their position requires.**"],
		},
		...ratingOptions,
	},
	MGR_OVC_GENERAL_UNMET_EXPECTATIONS: {
		bias: 'Overly critical',
		questionId: 'MGR_OVC_GENERAL_UNMET_EXPECTATIONS',
		label: {
			fr: ['Mes employé·e·s **ne répondent généralement pas** à toutes mes attentes.'],
			en: ['In general, my employees **tend not to meet** all of my expectations.'],
		},
		...ratingOptions,
	},
	MGR_OVC_CRITICAL: {
		bias: 'Overly critical',
		questionId: 'MGR_OVC_CRITICAL',
		label: {
			fr: ["J'ai tendance à **critiquer** ce que mes employé·e·s font."],
			en: ['I tend to be **critical** of what my employees do.'],
		},
		...ratingOptions,
	},
	MGR_OVC_NEGATIVE_MOTIVATIONAL: {
		bias: 'Overly critical',
		questionId: 'MGR_OVC_NEGATIVE_MOTIVATIONAL',
		label: {
			fr: ['Mettre en avant les **aspects négatifs** de mes employé·e·s est le meilleur moyen de **les motiver.**'],
			en: ["Highlighting employees' **negative aspects** is the best way to **motivate them.**"],
		},
		...ratingOptions,
	},
	MGR_OVC_HIGHLIGHT_NEGATIVE: {
		bias: 'Overly critical',
		questionId: 'MGR_OVC_HIGHLIGHT_NEGATIVE',
		label: {
			fr: ["C'est plus important de **souligner les aspects négatifs que positifs** du travail de mes employé·e·s."],
			en: ["It's more important to highlight the **negatives than the positives** of my employees' work."],
		},
		...ratingOptions,
	},
	MGR_OVC_EXPECTATIONS_UNMET_NEGATIVE: {
		bias: 'Overly critical',
		questionId: 'MGR_OVC_EXPECTATIONS_UNMET_NEGATIVE',
		label: {
			fr: ["Si mes employé·e·s ne répondent pas **à toutes mes attentes**, j'aurais une **évaluation négative** de leur travail."],
			en: ["If my employees **don't meet all my expectations**, I have a negative evaluation of their work."],
		},
		...ratingOptions,
	},
	MGR_OVC_WEIGH_MORE_FAILURES: {
		bias: 'Overly critical',
		questionId: 'MGR_OVC_WEIGH_MORE_FAILURES',
		label: {
			fr: ["Je donne plus d'importance **aux échecs qu'aux réussites** de mes employé·e·s. dans leurs évaluations."],
			en: ["I tend to weigh my employees' **failures more than their successes** in my evaluations."],
		},
		...ratingOptions,
	},
	MGR_CTE_COMPARE_PERF_TENDENCY: {
		bias: 'Contrast effect',
		questionId: 'MGR_CTE_COMPARE_PERF_TENDENCY',
		label: {
			fr: ['Je **compare** les compétences de mes employé·e·s entre elles.'],
			en: ['I **compare** the performances of my employees among themselves.'],
		},
		...ratingOptions,
	},
	MGR_CTE_EMP_SHOULD_COMPARE: {
		bias: 'Contrast effect',
		questionId: 'MGR_CTE_EMP_SHOULD_COMPARE',
		label: {
			fr: ["C'est une bonne chose que mes employé·e·s **se comparent entre eux·elles.**"],
			en: ["I think it's a good thing for employees to **compare themselves to others.**"],
		},
		...ratingOptions,
	},
	MGR_CTE_COMPARING_IMPORTANT_ASPECT: {
		bias: 'Contrast effect',
		questionId: 'MGR_CTE_COMPARING_IMPORTANT_ASPECT',
		label: {
			fr: ['**Comparer** mes employé·e·s est un aspect important lorsque je les évalue.'],
			en: ['**Comparing** employees is an important aspect of evaluating each of them.'],
		},
		...ratingOptions,
	},
	MGR_CTE_UNABLE_ASSESS_WITHOUT_COMPARE: {
		bias: 'Contrast effect',
		questionId: 'MGR_CTE_UNABLE_ASSESS_WITHOUT_COMPARE',
		label: {
			fr: ['Je ne **saurais pas comment évaluer** les performances de mes employé·e·s je ne **pouvais pas les comparer** entre elles.'],
			en: ["I **wouldn't know** how to assess performance if I couldn't compare them to each other."],
		},
		...ratingOptions,
	},
	MGR_CTE_ADJUST_COLLEAGUES: {
		bias: 'Contrast effect',
		questionId: 'MGR_CTE_ADJUST_COLLEAGUES',
		label: {
			fr: ["**J'ajuste** l'évaluation d'un·e employé·e en me basant sur **la performance de ses collègues.**"],
			en: ["I tend to **adjust** my evaluation of an employee **based on their colleagues' performance.**"],
		},
		...ratingOptions,
	},
	MGR_CTE_ASSESS_PERF_COMPARISON_MOST: {
		bias: 'Contrast effect',
		questionId: 'MGR_CTE_ASSESS_PERF_COMPARISON_MOST',
		label: {
			fr: ["J'évalue les performances de chaque employé·e en les **comparant** à celles de l'employé·e **le·la plus performant·e** plutôt que de l'évaluer **individuellement.**"],
			en: ["I tend to assess each employee's performance based on a **comparison** with the most performant employee rather than **individually.**"],
		},
		...ratingOptions,
	},
	MGR_CTE_EVALUATATION_RELATIVE_MOST: {
		bias: 'Contrast effect',
		questionId: 'MGR_CTE_EVALUATATION_RELATIVE_MOST',
		label: {
			fr: ["Au lieu de d'évaluer chaque employé·e **individuellement,** j'évalue leur performance par rapport à celles des employé·e·s **les plus performant·e·s.**"],
			en: ['Instead of evaluating each employee in their **own context**, I tend to consider their performance in relation to the **most performant members.**'],
		},
		...ratingOptions,
	},

	/** ******** EMPLOYEES ******** * */

	EMP_SFFP_PREDICTIONS_CONFIRMED: {
		bias: 'Self-Fullfilling Prophecy',
		questionId: 'EMP_SFFP_PREDICTIONS_CONFIRMED',
		label: {
			fr: ["Je suis **anxieux·se à l'idée de confirmer** les attentes que mon·ma responsable a à propos de **mes performances.**"],
			en: ["I'm **nervous about confirming** my manager's predictions about **my** performance."],
		},
		...ratingOptions,
	},
	EMP_SFFP_REMEMBER_IF_CONFIRM: {
		bias: 'Self-Fullfilling Prophecy',
		questionId: 'EMP_SFFP_REMEMBER_IF_CONFIRM',
		label: {
			fr: ['Mon·ma responsable ne se souvient uniquement des résultats qui **confirment ses attentes** à propos de moi.'],
			en: ['My manager tends to remember only the results that **confirm his/her expectations** about me.'],
		},
		...ratingOptions,
	},
	EMP_SFFP_BEHAVE_DIFFERENTLY: {
		bias: 'Self-Fullfilling Prophecy',
		questionId: 'EMP_SFFP_BEHAVE_DIFFERENTLY',
		label: {
			fr: ['Mon·ma responsable se **comporte différemment** avec moi par rapport aux autres employé·e·s.'],
			en: ['My manager **behaves differently** with me compared to the *the most performant* employees.'],
		},
		...ratingOptions,
	},
	EMP_SFFP_ALLOCATE_MORE_TIME: {
		bias: 'Self-Fullfilling Prophecy',
		questionId: 'EMP_SFFP_ALLOCATE_MORE_TIME',
		label: {
			fr: ["Mon·ma responsable consacre **plus de temps** aux employé·e·s qu'il·elle considère comme **plus performant·e·s.**"],
			en: ['My manager allocates **more time** to employees he/she perceives as **more performant.**'],
		},
		...ratingOptions,
	},
	EMP_SFFP_EXPECTATIONS_RESPONSIBILITIES: {
		bias: 'Self-Fullfilling Prophecy',
		questionId: 'EMP_SFFP_EXPECTATIONS_RESPONSIBILITIES',
		label: {
			fr: ['Mon·ma responsable se base sur **ses attentes** pour me donner **plus de responsabilités** ou non.'],
			en: ['My manager relies on **his/her expectations** to assign **more responsibilities** to an employee.'],
		},
		...ratingOptions,
	},
	EMP_SFFP_PERSONAL_INFORM_PERFORMANCE: {
		bias: 'Self-Fullfilling Prophecy',
		questionId: 'EMP_SFFP_PERSONAL_INFORM_PERFORMANCE',
		label: {
			fr: ['Mon·ma responsable prend en compte **certaines de mes caractéristiques personnelles** pour évaluer mes performances.'],
			en: ['My manager tends to take into account **certain personal characteristics **to evaluate my performance.'],
		},
		sublabel: sublabels.personalCharacteristics,
		...ratingOptions,
	},
	EMP_SFFP_EXPECTATIONS_BEHAVIOR: {
		bias: 'Self-Fullfilling Prophecy',
		questionId: 'EMP_SFFP_EXPECTATIONS_BEHAVIOR',
		label: {
			fr: ["Le comportement de mon·ma responsable est influencé par **les attentes initiales qu'il·elle a envers moi.**"],
			en: ['The behaviour of my manager tends to be **influenced** by **his/her initial expectations about me.**'],
		},
		...ratingOptions,
	},
	EMP_BLE_SAME_XP_NOT_SAME_SALARY: {
		bias: 'Backlash Effect',
		questionId: 'EMP_BLE_SAME_XP_NOT_SAME_SALARY',
		label: {
			fr: ["Lorsque mon·ma responsable décide de **mon salaire**, il·elle prend en compte **mes caractéristiques personnelles** qui vont au-delà de mon niveau d'expérience."],
			en: ['When my manager decides on **my salary**, he or she considers my **personal characteristics** that go beyond my **level of experience.**'],
		},
		sublabel: sublabels.personalCharacteristics,
		...ratingOptions,
	},
	EMP_BLE_SAME_TEAM_NOT_DISCUSSIONS: {
		bias: 'Backlash Effect',
		questionId: 'EMP_BLE_SAME_TEAM_NOT_DISCUSSIONS',
		label: {
			fr: ["J'ai remarqué que **je suis souvent exclu·e de discussions importantes**, alors que d'autres collègues de mon équipe et ayant le même niveau professionnel y participent."],
			en: ["I've noticed that **I'm often excluded from important discussions**, while other colleagues from my team and professional level take part in them."],
		},
		...ratingOptions,
	},
	EMP_BLE_IDEAS_CERTAIN_EMPS: {
		bias: 'Backlash Effect',
		questionId: 'EMP_BLE_IDEAS_CERTAIN_EMPS',
		label: {
			fr: ['Mon·ma responsable **ne prend pas en compte mes idées.**'],
			en: ['My manager tends to **not consider my ideas.**'],
		},
		...ratingOptions,
	},
	EMP_BLE_UNASSERTIVE_NO_RESP: {
		bias: 'Backlash Effect',
		questionId: 'EMP_BLE_UNASSERTIVE_NO_RESP',
		label: {
			fr: ["Indépendamment de ma performance, mon·ma responsable ne me donne pas de **responsabilités importantes** car il·elle considère que je ne suis pas capable d'être **assertif·ve.**"],
			en: ["Independently of my performance, my manager doesn't give me **significant responsibilities** because he/she considers me unable to be **assertive.**"],
		},
		sublabel: sublabels.assertive,
		...ratingOptions,
	},
	EMP_BLE_TRAITS_LIMIT_PROMOTION: {
		bias: 'Backlash Effect',
		questionId: 'EMP_BLE_TRAITS_LIMIT_PROMOTION',
		label: {
			fr: ["J'ai l'impression que certaines de mes **caractéristiques personnelles** peuvent limiter mes chances d'avoir une **promotion** ou une **augmentation de salaire.**"],
			en: ['I have the impression that some of my **personal characteristics** can limit my potential for a **promotion** or a **salary increase.**'],
		},
		sublabel: sublabels.personalCharacteristics,
		...ratingOptions,
	},
	EMP_BLE_NOT_CONSIDER_IF_OBLIGATIONS: {
		bias: 'Backlash Effect',
		questionId: 'EMP_BLE_NOT_CONSIDER_IF_OBLIGATIONS',
		label: {
			fr: ['Je ne suis pas pris·e en compte pour certains projets à cause de mes **obligations personnelles.**'],
			en: ['I am not considered for certain projects because of my **personal obligations.**'],
		},
		...ratingOptions,
	},
	EMP_BLE_SOCIAL_SKILLS_EVAL: {
		bias: 'Backlash Effect',
		questionId: 'EMP_BLE_SOCIAL_SKILLS_EVAL',
		label: {
			fr: ["Mon·ma responsable prend particulièrement en compte mes **compétences sociales** lorsqu'il·elle m'évalue."],
			en: ['My manager takes into account my **social skills** to evaluate my performance.'],
		},
		...ratingOptions,
	},
	EMP_HHE_TRUST_INITIAL_IMP_EVAL: {
		bias: 'Halo effect / horns effect',
		questionId: 'EMP_HHE_TRUST_INITIAL_IMP_EVAL',
		label: {
			fr: ["Mon·ma responsable se base sur **son·sa première impression** lorsqu'il·elle évalue mon travail."],
			en: ['My manager relies on **his/her first impression** when evaluating my work.'],
		},
		...ratingOptions,
	},
	EMP_HHE_FIRST_MEETING_GOOD_WORK: {
		bias: 'Halo effect / horns effect',
		questionId: 'EMP_HHE_FIRST_MEETING_GOOD_WORK',
		label: {
			fr: ["Je pense que mon·ma responsable savait depuis **notre première rencontre** si j'allais faire du bon travail ou non."],
			en: ['My manager knew from our **first meeting** whether I would do good work or not.'],
		},
		...ratingOptions,
	},
	EMP_HHE_POSITIVE_ATTRIBUTE_MORE: {
		bias: 'Halo effect / horns effect',
		questionId: 'EMP_HHE_POSITIVE_ATTRIBUTE_MORE',
		label: {
			fr: ["Si mon·ma responsable m'avait perçu de **manière positive dès la première fois**, je sais qu'il·elle m'aurait attribué·e **d'autres caractéristiques positives** sans en avoir fait l'expérience."],
			en: ['If my manager had perceived me **positively** for the first time, I know he/she would have attributed **other positive characteristics** to me without experiencing them.'],
		},
		sublabel: sublabels.personalCharacteristics,
		...ratingOptions,
	},
	EMP_HHE_NEGATIVE_ATTRIBUTE_MORE: {
		bias: 'Halo effect / horns effect',
		questionId: 'EMP_HHE_NEGATIVE_ATTRIBUTE_MORE',
		label: {
			fr: ["Si mon·ma responsable m'avait perçu de **manière** **négative** dès la première fois, je sais qu'il·elle m'aurait attribué·e d'autres **caractéristiques négatives** sans en avoir fait l'expérience."],
			en: ['If my manager had perceived me **negatively** for the first time, I know he/she would have attributed **other negative characteristics** to me without experiencing them.'],
		},
		sublabel: sublabels.personalCharacteristics,
		...ratingOptions,
	},
	EMP_HHE_FIRST_IMP_EVALUATION: {
		bias: 'Halo effect / horns effect',
		questionId: 'EMP_HHE_FIRST_IMP_EVALUATION',
		label: {
			fr: ['La perception que mon·ma responsable a de moi est souvent déterminée par sa **première impression** de moi.'],
			en: ['My evaluation by my manager is often determined by his/her **first impression** of me.'],
		},
		...ratingOptions,
	},
	EMP_HHE_POSITIVE_FEEDBACK_GOOD_IMP: {
		bias: 'Halo effect / horns effect',
		questionId: 'EMP_HHE_POSITIVE_FEEDBACK_GOOD_IMP',
		label: {
			fr: ['Mon·ma responsable donne des **feedbacks positifs** aux employé·e·s qui lui ont fait une **bonne impression**, au-delà de leurs performances.'],
			en: ['My manager is more inclined to give **positive feedback** to employees of whom he/she has a **good impression**, beyond their performance.'],
		},
		...ratingOptions,
	},
	EMP_HHE_NEGATIVE_IMP_NEGATIVE_EVAL: {
		bias: 'Halo effect / horns effect',
		questionId: 'EMP_HHE_NEGATIVE_IMP_NEGATIVE_EVAL',
		label: {
			fr: ["Les employé·e·s pour lesquel·le·s mon·ma responsable avait **une première impression négative** reçoivent plus d'évaluations négatives."],
			en: ['Employees for whom my manager initially had a **negative impression **tended to receive **more negative evaluations.**'],
		},
		...ratingOptions,
	},
	EMP_REC_REMEMBER_MOST_RECENT: {
		bias: 'Recency bias',
		questionId: 'EMP_REC_REMEMBER_MOST_RECENT',
		label: {
			fr: ['Mon·ma responsable se souvient plus facilement de **mes accomplissements les plus récents.**'],
			en: ['My manager tends to more easily remember **my most recent accomplishments.**'],
		},
		...ratingOptions,
	},
	EMP_REC_LATEST_PERF_REFLECT_OVERALL: {
		bias: 'Recency bias',
		questionId: 'EMP_REC_LATEST_PERF_REFLECT_OVERALL',
		label: {
			fr: ["J'ai le sentiment que **mes dernières performances** reflètent l'entièreté de mon travail."],
			en: ['I feel that for my manager, **my latest performances** reflect the entirety of my work.'],
		},
		...ratingOptions,
	},
	EMP_REC_MOST_RECENT_EVALUATION: {
		bias: 'Recency bias',
		questionId: 'EMP_REC_MOST_RECENT_EVALUATION',
		label: {
			fr: ["Mon·ma responsable préfère considérer **mes performances les plus récentes** pour m'évaluer."],
			en: ['My manager prefers to consider **my most recent performances** to evaluate me.'],
		},
		...ratingOptions,
	},
	EMP_REC_UNNECESSARY_CONSIDER_BEGINNING: {
		bias: 'Recency bias',
		questionId: 'EMP_REC_UNNECESSARY_CONSIDER_BEGINNING',
		label: {
			fr: ["J'ai l'impression que pour mon·ma responsable, ce n'est **pas nécessaire** de prendre en compte ma performance **dès le début.**"],
			en: ["I feel that for my manager, it's **not necessary** to consider my performance **from the beginning.**"],
		},
		...ratingOptions,
	},
	EMP_REC_LATEST_ACCOMPS_INFORM_FUTURE: {
		bias: 'Recency bias',
		questionId: 'EMP_REC_LATEST_ACCOMPS_INFORM_FUTURE',
		label: {
			fr: ['Pour mon·ma responsable, **mon dernier accomplissement professionnel** reflète ce que je peux faire dans les prochains mois.'],
			en: ['For my manager, **my latest accomplishment** reflects what I could do in the coming months.'],
		},
		...ratingOptions,
	},
	EMP_REC_LATEST_ACCOMPS_EVALUATION: {
		bias: 'Recency bias',
		questionId: 'EMP_REC_LATEST_ACCOMPS_EVALUATION',
		label: {
			fr: ["Mon·ma responsable prend en compte **uniquement mes derniers accomplissements professionnels** pour évaluer l'ensemble de mon travail."],
			en: ['My manager only takes into account **my latest accomplishments** to evaluate the entirety of my work.'],
		},
		...ratingOptions,
	},
	EMP_REC_MOST_RECENT_INFORM_CAPABILITIES: {
		bias: 'Recency bias',
		questionId: 'EMP_REC_MOST_RECENT_INFORM_CAPABILITIES',
		label: {
			fr: ['Mes performances **les plus récentes** semblent être plus indicatives de mes capacités que mes accomplissements passés pour mon·ma responsable.'],
			en: ['**My most recent performance** seems to be more indicative of my capabilities than my past achievements for my manager.'],
		},
		...ratingOptions,
	},
	EMP_IGF_COMMON_CHARS_IMPORTANT_WORK: {
		bias: 'In-group Effect',
		questionId: 'EMP_IGF_COMMON_CHARS_IMPORTANT_WORK',
		label: {
			fr: ['Pour mon·ma responsable, **les caractéristiques communes que nous partageons** sont particulièrement importantes pour pouvoir travailler ensemble.'],
			en: ['For my manager, **the common characteristics** between us are particularly important to work together.'],
		},
		sublabel: sublabels.personalCharacteristics,
		...ratingOptions,
	},
	EMP_IGF_TEAM_LOOK_LIKE_MGR: {
		bias: 'In-group Effect',
		questionId: 'EMP_IGF_TEAM_LOOK_LIKE_MGR',
		label: {
			fr: ["Il est important pour mon·ma responsable d'avoir une équipe **qui lui ressemble.**"],
			en: ["It's important for my manager to have a team that **looks like him/her.**"],
		},
		...ratingOptions,
	},
	EMP_IGF_SIMILAR_PEOPLE_WORK_BETTER: {
		bias: 'In-group Effect',
		questionId: 'EMP_IGF_SIMILAR_PEOPLE_WORK_BETTER',
		label: {
			fr: ['Mon·ma responsable pense que les **personnes similaires** travaillent mieux ensemble.'],
			en: ['My manager thinks that **similar people** tend to work better together.'],
		},
		...ratingOptions,
	},
	EMP_IGF_TRAITS_SHARED_EVALUATION: {
		bias: 'In-group Effect',
		questionId: 'EMP_IGF_TRAITS_SHARED_EVALUATION',
		label: {
			fr: ["Bien que les compétences professionnelles sont importantes, mon·ma responsable donne une grande importance aux **caractéristiques personnelles** qu'il·elle **partage** avec ses employé·e·s."],
			en: ['Even though professional skills are important, my manager seems to give a lot of importance to the **characteristics** he/she **shares** with his/her employees.'],
		},
		sublabel: sublabels.personalCharacteristics,
		...ratingOptions,
	},
	EMP_IGF_CLOSER_RESEMBLE: {
		bias: 'In-group Effect',
		questionId: 'EMP_IGF_CLOSER_RESEMBLE',
		label: {
			fr: ['Mon·ma responsable se sent **plus proche** des employé·e·s qui **lui ressemblent.**'],
			en: ['My manager feels **closer** to employees when they **look like him/her.**'],
		},
		...ratingOptions,
	},
	EMP_IGF_SIMILARITIES_ENJOY: {
		bias: 'In-group Effect',
		questionId: 'EMP_IGF_SIMILARITIES_ENJOY',
		label: {
			fr: ['Plus mon·ma responsable a des **points communs** avec un·e employé·e, plus il·elle **aime travailler** avec il·elle.'],
			en: ['**The more similarities** my manager has with an employee, the more he/she enjoys working with him/her.'],
		},
		...ratingOptions,
	},
	EMP_IGF_RELY_IF_COMMON_CHARS: {
		bias: 'In-group Effect',
		questionId: 'EMP_IGF_RELY_IF_COMMON_CHARS',
		label: {
			fr: ["Mon·ma responsable s'appuie sur les employé·e·s qui partagent des **caractéristiques communes** avec lui·elle."],
			en: ['My manager is more likely to rely on employees who share **common characteristics** with him/her.'],
		},
		sublabel: sublabels.personalCharacteristics,
		...ratingOptions,
	},
	EMP_OVC_SKILLS_BEYOND_REQ: {
		bias: 'Overly critical',
		questionId: 'EMP_OVC_SKILLS_BEYOND_REQ',
		label: {
			fr: ['Pour mon·ma responsable, je devrais avoir des compétences qui vont **au-delà de ce que mon poste requiert.**'],
			en: ["For my manager, it's normal for employees to have skills that go **beyond what the position requires.**"],
		},
		...ratingOptions,
	},
	EMP_OVC_GENERAL_UNMET_EXPECTATIONS: {
		bias: 'Overly critical',
		questionId: 'EMP_OVC_GENERAL_UNMET_EXPECTATIONS',
		label: {
			fr: ["J'ai l'impression que je ne **réponds pas** à toutes les **attentes** de mon·ma responsable."],
			en: ["I feel like I **don't meet** all of my manager's **expectations.**"],
		},
		...ratingOptions,
	},
	EMP_OVC_CRITICAL: {
		bias: 'Overly critical',
		questionId: 'EMP_OVC_CRITICAL',
		label: {
			fr: ['Mon·ma responsable a tendance à **critiquer** ce que je fais.'],
			en: ['My manager tends to be **critical** of what I do.'],
		},
		...ratingOptions,
	},
	EMP_OVC_NEGATIVE_MOTIVATIONAL: {
		bias: 'Overly critical',
		questionId: 'EMP_OVC_NEGATIVE_MOTIVATIONAL',
		label: {
			fr: ['Mon·ma responsable a tendance à mettre en avant les **aspects négatifs** pour **me motiver.**'],
			en: ['My manager tends to highlight **negative aspects** to **motivate me.**'],
		},
		...ratingOptions,
	},
	EMP_OVC_HIGHLIGHT_NEGATIVE: {
		bias: 'Overly critical',
		questionId: 'EMP_OVC_HIGHLIGHT_NEGATIVE',
		label: {
			fr: ["J'ai l'impression que c'est plus important pour mon·ma responsable de **souligner le négatif** plus que le positif dans mon travail."],
			en: ["I feel that it's more important for my manager to **highlight the negatives** than the positives of my work."],
		},
		...ratingOptions,
	},
	EMP_OVC_EXPECTATIONS_UNMET_NEGATIVE: {
		bias: 'Overly critical',
		questionId: 'EMP_OVC_EXPECTATIONS_UNMET_NEGATIVE',
		label: {
			fr: ['Si je ne réponds pas à toutes les **attentes** de mon·ma responsable, il·elle aura une **évaluation négative** de mon travail.'],
			en: ["If I don't meet all my manager's **expectations**, he/she will have a **negative evaluation** of my work."],
		},
		...ratingOptions,
	},
	EMP_OVC_WEIGH_MORE_FAILURES: {
		bias: 'Overly critical',
		questionId: 'EMP_OVC_WEIGH_MORE_FAILURES',
		label: {
			fr: ["Mon·ma responsable donne plus d'importance à **mes échecs** qu'à mes réussites dans ses évaluations."],
			en: ['My manager tends to weigh **my failures more than my successes** in his/her evaluations.'],
		},
		...ratingOptions,
	},
	EMP_CTE_COMPARE_PERF_TENDENCY: {
		bias: 'Contrast effect',
		questionId: 'EMP_CTE_COMPARE_PERF_TENDENCY',
		label: {
			fr: ['Mon·ma responsable **compare mes performances** à celles des autres employé·e·s.'],
			en: ['My manager **compares** my performance to the other employees’ performance.'],
		},
		...ratingOptions,
	},
	EMP_CTE_EMP_SHOULD_COMPARE: {
		bias: 'Contrast effect',
		questionId: 'EMP_CTE_EMP_SHOULD_COMPARE',
		label: {
			fr: ["C'est une bonne chose pour mon·ma responsable si **je me compare aux autres employé·e·s.**"],
			en: ["It's considered a good thing by my manager if **I compare myself with other employees.**"],
		},
		...ratingOptions,
	},
	EMP_CTE_COMPARING_IMPORTANT_ASPECT: {
		bias: 'Contrast effect',
		questionId: 'EMP_CTE_COMPARING_IMPORTANT_ASPECT',
		label: {
			fr: ['**Comparer** les employé·e·s semble être un aspect important pour mon·ma manager dans mon évaluation.'],
			en: ['**Comparing** employees seems to be an important aspect of my evaluation'],
		},
		...ratingOptions,
	},
	EMP_CTE_UNABLE_ASSESS_WITHOUT_COMPARE: {
		bias: 'Contrast effect',
		questionId: 'EMP_CTE_UNABLE_ASSESS_WITHOUT_COMPARE',
		label: {
			fr: ["Je pense que mon·ma responsable **ne saurait pas** comment évaluer mes performances s'il·elle ne pouvait pas les **comparer** à celles des autres."],
			en: ["I think that my manager **wouldn't know** how to evaluate my performance if he/she couldn't compare it to others."],
		},
		...ratingOptions,
	},
	EMP_CTE_ADJUST_COLLEAGUES: {
		bias: 'Contrast effect',
		questionId: 'EMP_CTE_ADJUST_COLLEAGUES',
		label: {
			fr: ['Mon·ma responsable **ajuste son évaluation** de mon travail en se basant sur la **performance de mes collègues.**'],
			en: ["My manager tends to **adjust his/her evaluation** of my work based on my **colleagues' performance.**"],
		},
		...ratingOptions,
	},
	EMP_CTE_ASSESS_PERF_COMPARISON_MOST: {
		bias: 'Contrast effect',
		questionId: 'EMP_CTE_ASSESS_PERF_COMPARISON_MOST',
		label: {
			fr: ["J'ai le sentiment que mon·ma responsable m'évalue **en me comparant** à l'employé·e **le·la plus performant·e** plutôt que de m'évaluer individuellement."],
			en: ['I feel like my manager tends to evaluate me by **comparing me** to the top performer rather than assessing me individually.'],
		},
		...ratingOptions,
	},
	EMP_CTE_EVALUATATION_RELATIVE_MOST: {
		bias: 'Contrast effect',
		questionId: 'EMP_CTE_EVALUATATION_RELATIVE_MOST',
		label: {
			fr: ["Au lieu de m'évaluer individuellement, mon manager évalue ma performance **par rapport aux membres les plus performants.**"],
			en: ['Instead of evaluating me in their own context, my manager tends to consider my performance **in relation to the most performant members.**'],
		},
		...ratingOptions,
	},

	/** ******** INFOS ******** * */

	INFO_GENDER: {
		questionId: 'INFO_GENDER',
		label: {
			fr: ['A quel genre vous identifiez-vous?'],
			en: ['Which gender do you identify with?'],
		},
		...singleInput,
		autoSave: true,
		columns: 4,
		options: [
			{
				value: 'W',
				label: {
					fr: 'Femme',
					en: 'Woman',
				},
			},
			{
				value: 'M',
				label: {
					fr: 'Homme',
					en: 'Man',
				},
			},
			{
				value: 'NB',
				label: {
					fr: 'Non-binaire',
					en: 'Non-binary',
				},
			},
		],
		allowOther: true,
	},
	INFO_AGE: {
		questionId: 'INFO_AGE',
		label: {
			fr: ['Quel âge avez-vous ?'],
			en: ['How old are you?'],
		},
		...singleInput,
		autoSave: true,
		columns: 3,
		options: [
			{
				value: '1-24',
				label: {
					fr: 'Moins de 25 ans',
					en: 'Less than 25 years',
				},
			},
			{
				value: '25-34',
				label: {
					fr: '25-34 ans',
					en: '25-34 years',
				},
			},
			{
				value: '35-44',
				label: {
					fr: '35-44 ans',
					en: '35-44 years',
				},
			},
			{
				value: '45-54',
				label: {
					fr: '45-54 ans',
					en: '45-54 years',
				},
			},
			{
				value: '55-plus',
				label: {
					fr: '55 ans ou plus',
					en: '55 years or above',
				},
			},
		],
	},
	INFO_COMPANY_NAME: {
		questionId: 'INFO_COMPANY_NAME',
		label: {
			fr: ['Quel est le nom de votre entreprise ?'],
			en: ['What is the name of your company?'],
		},
		...textInput,
	},
	INFO_COMPANY_SIZE: {
		questionId: 'INFO_COMPANY_SIZE',
		label: {
			fr: ['Quelle est la taille de votre entreprise ?'],
			en: ['What is the size of your company'],
		},
		...singleInput,
		autoSave: true,
		columns: 2,
		options: [
			{
				value: '1-50',
				label: {
					fr: 'Moins de 50 employés',
					en: 'Less than 50 employees',
				},
			},
			{
				value: '50-250',
				label: {
					fr: '50 à 250 employés',
					en: '50 to 250 employees',
				},
			},
			{
				value: '250-1000',
				label: {
					fr: '250 à 1000 employés',
					en: '250 to 1000 employees',
				},
			},
			{
				value: '1000-plus',
				label: {
					fr: 'Plus de 1000 employés',
					en: 'More than 1000 employees',
				},
			},
		],
	},
	INFO_COUNTRY_WORK: {
		questionId: 'INFO_COUNTRY_WORK',
		label: {
			fr: ['Dans quel pays travaillez-vous?'],
			en: ['In which country do you work?'],
		},
		...singleInput,
		autoSave: true,
		columns: 4,
		options: [
			{
				value: 'france',
				label: {
					fr: 'France',
					en: 'France',
				},
			},
			{
				value: 'germany',
				label: {
					fr: 'Allemagne',
					en: 'germany',
				},
			},
			{
				value: 'uk',
				label: {
					fr: 'UK',
					en: 'UK',
				},
			},
			{
				value: 'spain',
				label: {
					fr: 'Espagne',
					en: 'Spain',
				},
			},
			{
				value: 'italy',
				label: {
					fr: 'Italie',
					en: 'Italy',
				},
			},
			{
				value: 'belgium',
				label: {
					fr: 'Belgique',
					en: 'Belgium',
				},
			},
			{
				value: 'us',
				label: {
					fr: 'Etats-Unis',
					en: 'United States',
				},
			},
		],
		allowOther: true,
	},
	INFO_MANAGER_GENDER: {
		questionId: 'INFO_MANAGER_GENDER',
		label: {
			fr: ['Quel est le genre de votre manager ?'],
			en: ['What is the gender of your manager?'],
		},
		...singleInput,
		autoSave: true,
		columns: 3,
		options: [
			{
				value: 'W',
				label: {
					fr: 'Femme',
					en: 'Woman',
				},
			},
			{
				value: 'M',
				label: {
					fr: 'Homme',
					en: 'Man',
				},
			},
			{
				value: 'NB',
				label: {
					fr: 'Non-binaire',
					en: 'Non-binary',
				},
			},
			{
				value: 'UNKNOWN',
				label: {
					fr: 'Je ne sais pas',
					en: 'I don\'t know',
				},
			},
		],
		allowOther: true,
	},
	INFO_CURRENT_TENURE: {
		questionId: 'INFO_CURRENT_TENURE',
		label: {
			fr: ['Depuis combien de temps travaillez-vous pour votre employeur actuel ?'],
			en: ['How long have you been working for your current company?'],
		},
		...singleInput,
		autoSave: true,
		columns: 3,
		options: [
			{
				value: 'less-than-1',
				label: {
					fr: 'Moins d\'un an',
					en: 'Less than a year',
				},
			},
			{
				value: '1-2',
				label: {
					fr: '1-2 ans',
					en: '1-2 years',
				},
			},
			{
				value: '3-5',
				label: {
					fr: '3-5 ans',
					en: '3-5 years',
				},
			},
			{
				value: '6-10',
				label: {
					fr: '6-10 ans',
					en: '6-10 years',
				},
			},
			{
				value: '11-20',
				label: {
					fr: '11-20 ans',
					en: '11-20 years',
				},
			},
			{
				value: '20-plus',
				label: {
					fr: 'Plus de 20 ans',
					en: 'More than 20 years',
				},
			},
		],
	},
	INFO_COMPANY_EVALUATION_FREQUENCY: {
		questionId: 'INFO_COMPANY_EVALUATION_FREQUENCY',
		label: {
			fr: ['À quelle fréquence votre entreprise effectue-t-elle des évaluations de performances ?'],
			en: ['How frequently does your company evaluate performances?'],
		},
		...singleInput,
		autoSave: true,
		columns: 2,
		options: [
			{
				value: 'annually',
				label: {
					fr: 'Annuellement',
					en: 'Annually',
				},
			},
			{
				value: 'semi-annually',
				label: {
					fr: 'Semi-annuellement',
					en: 'Semi-annually',
				},
			},
			{
				value: 'quarterly',
				label: {
					fr: 'Trimestriellement',
					en: 'Quarterly',
				},
			},
			{
				value: 'never',
				label: {
					fr: 'Jamais',
					en: 'Never',
				},
			},
		],
		allowOther: true,
	},
	INFO_WORK_ARRANGEMENT: {
		questionId: 'INFO_WORK_ARRANGEMENT',
		label: {
			fr: ['Quelle est votre modalité de travail ?'],
			en: ['What is your work arrangement?'],
		},
		...singleInput,
		autoSave: true,
		columns: 4,
		options: [
			{
				value: 'on-site',
				label: {
					fr: '100% présentiel',
					en: '100% on-site',
				},
			},
			{
				value: 'hybrid',
				label: {
					fr: 'Hybride',
					en: 'Hybrid',
				},
			},
			{
				value: 'remote',
				label: {
					fr: '100% télétravail',
					en: '100% remote',
				},
			},
		],
		allowOther: true,
	},
	INFO_WEEKLY_WORKED_HOURS: {
		questionId: 'INFO_WEEKLY_WORKED_HOURS',
		label: {
			fr: ['En moyenne, combien d\'heures travaillez-vous par semaine dans votre emploi actuel ?'],
			en: ['On average, how many weekly hours do you work in your current job?'],
		},
		...singleInput,
		autoSave: true,
		columns: 2,
		options: [
			{
				value: 'less-than-20',
				label: {
					fr: 'Moins de 20 heures par semaine',
					en: 'Less than 20 hours per week',
				},
			},
			{
				value: '20-30',
				label: {
					fr: 'Entre 20 et 30 heures par semaine',
					en: 'Between 20 and 30 hours per week',
				},
			},
			{
				value: '30-40',
				label: {
					fr: 'Entre 30 et 40 heures par semaine',
					en: 'Between 30 and 40 hours per week',
				},
			},
			{
				value: '40-plus',
				label: {
					fr: 'Plus de 40 heures par semaine',
					en: 'More than 40 hours per week',
				},
			},
		],
	},
	INFO_COUNT_SUBORDINATES: {
		questionId: 'INFO_COUNT_SUBORDINATES',
		label: {
			fr: ['Combien de personnes supervisez-vous directement ?'],
			en: ['How many people are you directly supervising?'],
		},
		...singleInput,
		autoSave: true,
		columns: 3,
		options: [
			{
				value: '1-2',
				label: {
					fr: '1-2',
					en: '1-2',
				},
			},
			{
				value: '3-5',
				label: {
					fr: '3-5',
					en: '3-5',
				},
			},
			{
				value: '6-10',
				label: {
					fr: '6-10',
					en: '6-10',
				},
			},
			{
				value: '11-20',
				label: {
					fr: '11-20',
					en: '11-20',
				},
			},
			{
				value: '20-plus',
				label: {
					fr: 'Plus de 20',
					en: 'More than 20',
				},
			},
		],
	},
	INFO_MANAGEMENT_EXP_LEVEL: {
		questionId: 'INFO_MANAGEMENT_EXP_LEVEL',
		label: {
			fr: ['Quel est votre niveau d\'expérience dans le management ?'],
			en: ['What is your management\'s experience level?'],
		},
		...singleInput,
		autoSave: true,
		columns: 3,
		options: [
			{
				value: 'less-than-1',
				label: {
					fr: 'Moins d\'un an',
					en: 'Less than a year',
				},
			},
			{
				value: '1-2',
				label: {
					fr: '1-2 ans',
					en: '1-2 years',
				},
			},
			{
				value: '3-5',
				label: {
					fr: '3-5 ans',
					en: '3-5 years',
				},
			},
			{
				value: '6-10',
				label: {
					fr: '6-10 ans',
					en: '6-10 years',
				},
			},
			{
				value: '11-20',
				label: {
					fr: '11-20 ans',
					en: '11-20 years',
				},
			},
			{
				value: '20-plus',
				label: {
					fr: 'Plus de 20 ans',
					en: 'More than 20 years',
				},
			},
		],
	},
	INFO_EVALUATION_IMPLICATIONS: {
		questionId: 'INFO_EVALUATION_IMPLICATIONS',
		label: {
			fr: ['Qui est impliqué dans le processus d\'évaluation des performances ?'],
			en: ['In your company, what are the parties involved in performance evaluation?'],
		},
		...multipleInput,
		columns: 2,
		options: [
			{
				value: 'hr-personel',
				label: {
					fr: 'Personnel RH',
					en: 'HR Personel',
				},
			},
			{
				value: 'colleagues',
				label: {
					fr: 'Collègues',
					en: 'Colleagues',
				},
			},
			{
				value: 'managers',
				label: {
					fr: 'Managers',
					en: 'Managers',
				},
			},
			{
				value: 'recruitment-agencies',
				label: {
					fr: 'Agences de recrutement',
					en: 'Recruitment agencies',
				},
			},
			{
				value: 'executive-leadership',
				label: {
					fr: 'Leadership exécutif (N+2 et au-delà)',
					en: 'Executive leadership (N+2 and above)',
				},
			},
		],
		allowOther: true,
	},
	INFO_EVALUATION_OBJECTIVES: {
		questionId: 'INFO_EVALUATION_OBJECTIVES',
		label: {
			fr: ['Quels sont vos principaux objectifs quand vous évaluez un·e employé·e ?'],
			en: ['What are the main objectives when you evaluate an employee?'],
		},
		...multipleInput,
		columns: 2,
		options: [
			{
				value: 'performance-evaluation',
				label: {
					fr: 'Évaluation de performance',
					en: 'Performance evaluation',
				},
			},
			{
				value: 'feedback',
				label: {
					fr: 'Feedback',
					en: 'Feedback',
				},
			},
			{
				value: 'objective-alignment',
				label: {
					fr: 'Alignement des objectifs',
					en: 'Objective alignment',
				},
			},
			{
				value: 'salary-adjustment',
				label: {
					fr: 'Ajustement du salaire',
					en: 'Salary adjustment',
				},
			},
			{
				value: 'promotion-attribution',
				label: {
					fr: 'Attribution d\'une promotion',
					en: 'Promotion attribution',
				},
			},
			{
				value: 'leadership-potential-assessment',
				label: {
					fr: 'Potentiel de leadership',
					en: 'Leadership potential assessment',
				},
			},
			{
				value: 'career-development',
				label: {
					fr: 'Développement de carrière',
					en: 'Career development',
				},
			},
			{
				value: 'improvement-planning',
				label: {
					fr: 'Plans d\'amélioration',
					en: 'Improvement planning',
				},
			},
		],
		allowOther: true,
	},
	INFO_SUGGESTIONS: {
		questionId: 'INFO_SUGGESTIONS',
		label: {
			fr: ['Avez-vous des questions ou suggestions à propos de ce questionnaire ?'],
			en: ['Do you have any questions or suggestions about this survey?'],
		},
		...textareaInput,
	},
};

export default QUESTIONS;
