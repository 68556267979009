const LOCATION_CITY = require('../../../property/location-city');
const { asCategoryTag, asCategoryTagOption } = require('../tags.constants.utils');

export const TAG_CATEGORY_JOB_SEARCH_JOB_LOCATION_CITY_SLUG = 'job-search-job-location-city';

const asJobSearchJobLocationCityTag = (slug) => asCategoryTag(
	TAG_CATEGORY_JOB_SEARCH_JOB_LOCATION_CITY_SLUG,
	slug,
);

const asJobSearchJobLocationCityTagOption = (value, label, group) => asCategoryTagOption(
	TAG_CATEGORY_JOB_SEARCH_JOB_LOCATION_CITY_SLUG,
	value,
	label,
	group,
);

const options = LOCATION_CITY
	.options
	?.map((option) => asJobSearchJobLocationCityTagOption(
		option.value,
		option.label,
		option.group,
	));

export default {
	category: TAG_CATEGORY_JOB_SEARCH_JOB_LOCATION_CITY_SLUG,
	TAG_CATEGORY_JOB_SEARCH_JOB_LOCATION_CITY_SLUG,
	...LOCATION_CITY,
	options,
	asTag: asJobSearchJobLocationCityTag,
	asTagOption: asJobSearchJobLocationCityTagOption,
	asJobSearchJobLocationCityTag,
	asJobSearchJobLocationCityTagOption,
};
