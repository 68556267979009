import { Space } from 'antd';
import UserAvatar from './UserAvatar';
import { useAuthContext } from '../../../hooks/useAuthContext';
import { documentId } from '../../../utils/common';
import { useTheme } from '@emotion/react';

const UserAvatarList = (
	{
		users,
		withCurrentUser = false,
		size = 36,
		overflow = -12,
		limit = 3,
		...props
	},
) => {
	let limitedUsers = users;
	const { currentUser } = useAuthContext();
	const theme = useTheme();

	if (limit > 0 && users?.length > limit) {
		const extraCount = (Math.min(9, users?.length) - limit);
		limitedUsers = [
			...limitedUsers?.slice(0, limit),
			{ firstName: '+', lastName: `${extraCount} people`/* + (extraCount > 1 ? 's' : '') */ },
		];
	}
	if (withCurrentUser
		&& limitedUsers?.find((u) => documentId(u) === documentId(currentUser))) {
		const filteredUsers = limitedUsers?.filter((u) => documentId(u) !== documentId(currentUser));
		const otherPeopleCount = filteredUsers?.length;
		return (
			<Space
				direction='horizontal'
				size={0}
				style={{
					fontSize: 12,
					rowGap: 3,
					columnGap: 3,
				}}
			>
				<UserAvatar user={currentUser}
					size={size}
					withYou={true}
				/>
				<Space direction='horizontal' size={0}>
					{otherPeopleCount > 0 && <>
						{filteredUsers
							.map((user, index) => (
								<UserAvatar
									user={user}
									size={size - 8}
									key={index}
									style={{
										...index && { marginLeft: overflow },
									}}
								/>
							))
						}
					</>
					}
				</Space>
			</Space>
		);
	}

	return (
		<Space direction='horizontal' size={0}>
			{limitedUsers
				?.map((user, index) => (
					<UserAvatar
						user={user}
						size={size}
						key={index}
						style={{
							...index && { marginLeft: overflow },
						}}
					/>
				))
			}
		</Space>
	);
};

export default UserAvatarList;
