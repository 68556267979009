import { PERMS_GRANT_RECRUITER_DENY_ATS_SYNCABLE } from '../menu.utils';
import MenuBase from '../MenuBase';
import GenderhireCandidatesAppliedMenu from './GenderhireCandidatesAppliedMenu';
import GenderhireCandidatesArchivedMenu from './GenderhireCandidatesArchivedMenu';
import GenderhireCandidatesSourcedMenu from './GenderhireCandidatesSourcedMenu';
import { SolutionOutlined } from '@ant-design/icons';

export const GenderhireCandidatesIcon = SolutionOutlined;

class GenderhireCandidatesMenu extends MenuBase {
	static props = {
		name: 'candidates',
		label: 'Candidates',
		icon: <GenderhireCandidatesIcon />,
		iconLegacy: '👩‍🚀️',
		children: (args) => [
			(new GenderhireCandidatesSourcedMenu()).build(args),
			(new GenderhireCandidatesAppliedMenu()).build(args),
			(new GenderhireCandidatesArchivedMenu()).build(args),
		],
		perms: PERMS_GRANT_RECRUITER_DENY_ATS_SYNCABLE,
	};

	constructor() {
		super(GenderhireCandidatesMenu.props);
	}
}

export default GenderhireCandidatesMenu;
