import { useTheme } from '@emotion/react';
import { Space } from 'antd';
import { FaMedal } from 'react-icons/fa';

/**
 *
 * @param {number} value - Number to render
 * @param {number} [height] - Optional. Height of the medal. Default: 36
 * @param {number} fontSize - Optional. Size of the number. Default: 40
 * @param {string} [color] - Optional. Number and medal color.
 * @param {number} [gap] - Optional. Margin between number and medal.
 * @returns
 */
const GenderScoreMedalBlock = ({
	value,
	gap = 0,
	color,
	fontSize = 40,
	height = 36,
	...props
}) => {
	const theme = useTheme();
	return <Space style={{
		color,
		...props.style,
	}}>
		<span
			style={{
				fontSize,
				fontWeight: 700,
			}}>{value}
		</span>
		<FaMedal
			style={{
				fontSize: height,
				marginBottom: -(fontSize * 0.125),
				marginLeft: -gap,
			}}
		/>

	</Space>;
};
export default GenderScoreMedalBlock;
