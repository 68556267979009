import { Tag, Tooltip } from 'antd';
import { AlertOutlined } from '@ant-design/icons';
import { findActionCompletionStatus } from './action-plan';

const CompanyActionCompletionStatusTag = ({
	action,
	completionStatus,
	...props
}) => {
	const className = 'CompanyActionCompletionStatusTag';

	const completionStatusOption = findActionCompletionStatus(
		action?.completionStatus || completionStatus,
	);

	if (!completionStatusOption) return null;

	return (
		<Tooltip title={completionStatusOption?.label}>
			<Tag
				color={completionStatusOption?.tagColor}
				{...props}
				style={{
					border: 'none',
					borderRadius: 6,
					fontWeight: 'bold',
					padding: '4px 8px',
					...props?.style,
				}}
			>
				{completionStatusOption.tagIcon || <AlertOutlined />} {completionStatusOption?.tagLabel}
			</Tag>
		</Tooltip>
	);
};

export default CompanyActionCompletionStatusTag;
