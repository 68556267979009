import moment from 'moment';
import { useAuthContext } from '../../../hooks/useAuthContext';
import { Button, Popover } from 'antd';
import { FaEnvelope } from 'react-icons/fa';
import {
	MESSAGES_ICON,
	MESSAGES_MENU,
	SOURCING_ACTION_CONTACT,
	SOURCING_DOCUMENT_REF,
} from '../../../constants/constant';
import { COMPANY_RECRUITER_LOG } from '../../../constants/company-recruiter-log';
import UserAvatarList from '../../user/widgets/UserAvatarList';
import UserButtonBadge from '../../user/buttons/UserButtonBadge';
import MessagePopover from '../../messages/MessagePopover';
import { addMessage } from '../../../actions/messages';
import { useContext, useState } from 'react';
import { addSourcing, treatSourcingActionToDb } from '../../../actions/sourcings';
import { writeRecruiterLog } from '../../../actions/logging';
import { SocketContext } from '../../../contexts/SocketProvider';
import { documentId } from '../../../utils/common';
import GenderhireMessagesMenu from '../../../menu/genderhire/GenderhireMessagesMenu';

const SourcingContacter = (
	{
		sourcing,
		Component,
		onStart,
		onComplete,
		onEditSourcing,
		mode,
		children,
		...props
	},
) => {
	const className = 'SourcingContacter';

	const { createAction } = useContext(SocketContext);

	const { isStaffView, companyIdsUser, currentUser } = useAuthContext();

	const [openPopover, setOpenPopover] = useState(false);

	const handleSubmit = async (values) => {
		if (onStart) await onStart();
		const valuesToDb = { ...values };
		const message = await addMessage(valuesToDb);

		const action = SOURCING_ACTION_CONTACT;
		const history = {
			action,
			'creator': documentId(currentUser),
			'postedAt': moment(),
			'author': documentId(currentUser),
			'payload.userMessageId': documentId(message),
		};

		const toDb = treatSourcingActionToDb({
			id: documentId(sourcing),
			managerId: documentId(currentUser),
			...history,
		}, currentUser);

		const actionTitle = `add ${action}`;

		// update history sourcing
		const result = await addSourcing({
			userId: values?.userToId,
			...toDb,
		});
		// create action
		if (result) {
			const playload = {
				documentRef: SOURCING_DOCUMENT_REF,
				documentId: documentId(result),
				companyId: result.companyId,
				action: {
					actionTitle,
					...(toDb?.history && { history: toDb.history }),
				},
			};
			createAction(playload);
		}

		// company recruiter log contact
		writeRecruiterLog(COMPANY_RECRUITER_LOG.CONTACT, {
			userTargetId: values?.userToId, sourcingTargetId: documentId(sourcing),
		});

		setOpenPopover(false);
		if (onComplete) await onComplete();
	};

	const senders = [];
	sourcing?.user?.messagesTo?.forEach((item) => {
		const { user } = item;
		senders.push(...user && !senders.some((s) => (documentId(s)?.toString?.()) === (documentId(user)?.toString?.())) ? [user] : []);
	});
	// LocalDebug.logInfo({ className, method: 'init'}, { user: sourcing?.user?.slug, sourcing, messagesTo: sourcing?.user?.messagesTo, senders })

	const button = Component
		? <Component {...props} onClick={() => {
			setOpenPopover(true);
			if (props?.onClick) props.onClick();
		}}>
			{children}
		</Component>
		: <Button
			{...!(sourcing?.user?.messagesToCount > 0) && { type: 'primary' }}
			onClick={() => setOpenPopover(true)}
			icon={<FaEnvelope style={{ marginBottom: -2 }}/>}
			size={10}>
            &nbsp;Contact
		</Button>;

	const messagesMenu = new GenderhireMessagesMenu();

	return <div style={{ textAlign: 'left' }}>
		{sourcing?.user?.messagesToCount > 0
			? <Popover
				title={<>{messagesMenu.icon} {messagesMenu.label}</>}
				content={<>
					<strong>Contacted </strong> {sourcing?.user?.messagesToCount} times {senders?.length > 0 && <>by <UserAvatarList
						size={28} users={senders}/></>}</>}
			>
				<UserButtonBadge count={sourcing?.user?.messagesToCount}>
					{button}
				</UserButtonBadge>
			</Popover>
			: button
		}
		<MessagePopover
			open={openPopover}
			initialValues={{
				userToId: documentId(sourcing?.user),
				viaProfile: true,
				userTo: sourcing?.user,
				companyIds: !isStaffView ? companyIdsUser : null,
			}}
			onClose={() => setOpenPopover(false)}
			onSubmit={handleSubmit}
			onReset={() => setOpenPopover(false)}
		/>
	</div>;
};

export default SourcingContacter;
