import { useTheme } from '@emotion/react';
import CompanyModel from '../../../../../models/CompanyModel';
import GenderScoreCompareAverageBlock from '../blocks/GenderScoreCompareAverageBlock';
import { GENDERSCORE_LABEL } from '../../../../../constants/constant';
import { getLevelFromScore, getLevelLabel } from '../../../../../constants/genderscore-levels';
import { sumReducer } from '../../../../../utils/common';
import { LocalDebug } from '../../../../../utils/LocalDebug';

const CompanyGenderScorePerformanceBox = ({
	company,
	width = 180,
	isGlobal = true,
	...props
}) => {
	const className = 'CompanyGenderScorePerformanceBox';
	const theme = useTheme();
	const companyModel = new CompanyModel(company);
	if (!companyModel) {
		return null;
	}
	const genderScore = companyModel?.genderScore;

	LocalDebug.logInfo({ className }, { 'genderScore?.currentStatistics': genderScore?.currentStatistics });
	const score = genderScore?.globalScore;
	let avgScore = genderScore?.currentStatistics?.averages?.globalScore || score || 0;
	let avgContextLabel = GENDERSCORE_LABEL;
	if (!isGlobal) {
		const currentLevel = getLevelFromScore(score);
		const scores = genderScore?.currentStatistics?.levels?.[currentLevel]?.companies
			?.map((c) => c?.genderScore?.globalScore || 0)
			?.filter((item) => item > 0) || [];

		avgScore = Math.round(scores.reduce(sumReducer, 0) / (scores?.length || 1));
		avgContextLabel = getLevelLabel(currentLevel)?.toUpperCase();
	}

	return <div style={{ ...props?.style }}>
		<GenderScoreCompareAverageBlock
			score={score}
			avgScore={avgScore}
			avgContextLabel={avgContextLabel}
			width={width}
		/>
	</div>;
};
export default CompanyGenderScorePerformanceBox;
