import { Button, Tag } from 'antd';
import CountTag from './CountTag';

const CandidateSelectorPipelineButton = (
	{
		pipeline,
		selected,
		onClick,
		...props
	},
) => {
	return <Button
		onClick={onClick}
		icon={pipeline.icon}
		key={pipeline?.value}
		type={selected ? 'primary' : 'default'}
		size='large'
	>
		{pipeline.icon && <>&nbsp;</>}
		<span style={{ fontWeight: 500 }}>{pipeline.label}</span>
        &nbsp;
		<CountTag count={pipeline.count} selected={selected} />
	</Button>;
};
export default CandidateSelectorPipelineButton;
