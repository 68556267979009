import DataFetcherProvider from '../app/data/DataFetcherProvider';
import SearchTalents from './SearchTalents';
import UserSavedSearchProvider from './UserSavedSearchProvider';

const SearchTalentsDataFetcherContainer = (
	{
		withLoadMore = true,
		loadOnNoFilter = true,
	},
) => {
	const className = 'SearchTalentsDataFetcherContainer';

	return (
		<div
			style={{
				position: 'relative',
			}}
		>
			<UserSavedSearchProvider>
				<DataFetcherProvider
					limit={10}
					loadOnInit={false}
					loadOnNoFilter={loadOnNoFilter}
					path='/user/search'
					withLoadMore={withLoadMore}
				>
					<SearchTalents/>
				</DataFetcherProvider>
			</UserSavedSearchProvider>

		</div>
	);
};

export default SearchTalentsDataFetcherContainer;
