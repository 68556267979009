import { CKEditor as ReactCKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { CloudinaryImageUploadAdapter } from 'ckeditor-cloudinary-uploader-adapter';
import config from '../../../config/config';
import { ckEditorReadyMinHeight } from '../../../utils/common';
import { useEffect } from 'react';

function imagePluginFactory(editor) {
	editor.plugins.get('FileRepository').createUploadAdapter = (loader) => {
		return new CloudinaryImageUploadAdapter(loader, config.cloudinary.cloud_name, config.cloudinary.upload_preset);
	};
}

const ckEditorConfig = {
	extraPlugins: [imagePluginFactory],
	// toolbar: [ 'heading', '|', 'bold', 'italic', 'link', 'bulletedList', 'numberedList', 'blockQuote' ],
	// toolbar: [ 'bold', 'italic' ],
	// height: 300
};

const CKEditor = (
	{
		value = '',
		onChange,
		onReady,
		onKeyDown,
		config = {},
		height = 300,
	},
) => {
	useEffect(() => {
		ClassicEditor
			.create(document.querySelector('#editor'))
			.then((editor) => {
				editor.keystrokes.set('A', (data, stop) => {
					stop(); // Works like data.preventDefault() + evt.stop()
				});
				editor.keystrokes.set('Ctrl+S', (data, stop) => {
				});
			})
			.catch((error) => {
				console.error(error);
			});
	}, []);

	return (
		<ReactCKEditor
			config={{ ...ckEditorConfig, ...config }}
			editor={ClassicEditor}
			data={value}
			onReady={onReady || ckEditorReadyMinHeight(height)}
			// editor => {
			// 	// You can store the "editor" and use when it is needed.
			// 	editor?.editing?.view?.change((writer) => {
			// 		writer.setStyle(
			// 			"min-height",
			// 			"300px",
			// 			editor?.editing?.view?.document?.getRoot()
			// 		);
			// 	});
			// 	onReady?.();
			// }}
			onChange={(event, editor) => {
				const data = editor.getData();
				// utils/LocalDebug( { event, editor, data } );
				if (onChange) onChange(data);
			}}
			onKeyDown={onKeyDown}
			style={{ width: '100% !important', height }}
		/>
	);
};

export default CKEditor;
