import { useTheme } from '@emotion/react';
import { NOT_PROVIDED_YET_LABEL } from '../../../../constants/constant';
import { FaAward } from 'react-icons/fa';

const UserResumeCertificationsCardBody = (
	{
		user,
		notProvided = NOT_PROVIDED_YET_LABEL,
		...props
	},
) => {
	const theme = useTheme();

	return user?.getResumeCertifications()?.map(
		(text, index) => {
			return <div key={index}>
				<FaAward style={{ color: theme.color.fitBlueElectric, marginBottom: -2 }}/>&nbsp;{text}
			</div>;
		},
	) || notProvided;
};

export default UserResumeCertificationsCardBody;
