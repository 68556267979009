import StageTag from './StageTag';
import { useAuthContext } from '../../hooks/useAuthContext';

const SourcingATSStageTag = (
	{
		sourcing,
		onClick,
	},
) => {
	const className = 'SourcingATSStageTag';

	const { isAtsSyncable } = useAuthContext();

	const label = isAtsSyncable
		? sourcing?.atsStage
		: sourcing.stage;

	return <StageTag
		candidatePipeline={sourcing?.candidatePipeline}
		candidateStage={sourcing?.candidateStage}
		label={label}
		withPipeline={false}
		error={sourcing?.atsSync?.stage?.error}
		onClick={onClick}
	/>;
	// const { findStage } = useContext(CandidateContext);
	//
	// let tagIcon = <span>{findStage(sourcing?.candidateStage)?.icon} </span>
	//     || <FaCheck color={theme.color.darkturquoise} style={{marginBottom: -2, marginRight: 4}}/>
	// const label = sourcing?.atsStage
	//     || findStage(sourcing?.candidateStage)?.label;
	//
	// let tagColor = 'green';
	// if (sourcing?.atsSync?.stage?.error) {
	//     tagColor = 'volcano';
	//     tagIcon = <FaTimes color={theme.color.red} style={{marginBottom: -2, marginRight: 4}}/>;
	// }
	//
	// const content = label ?
	//     <Tag
	//         icon={tagIcon}
	//         color={tagColor}
	//         style={{borderRadius: 20, fontWeight: 'bold', margin:0, paddingTop: 2, paddingBottom: 2}}
	//     >
	//         {label}
	//     </Tag>
	//     : 'No tag found'
	//
	// const buttonWrapper = (children) => isFunction(onClick)
	//     ? <Button
	//         style={{borderRadius: 20, padding: 0,boxShadow: 'none', lineHeight: 'unset', height: 'unset', margin:0, border:'none',background:'none', outline: 'none'}}
	//         onClick={onClick}>
	//         {children}
	//     </Button>
	//     : {children}
	//
	// return buttonWrapper(content)
};

export default SourcingATSStageTag;
