import {
	Button, Checkbox,
} from 'antd';
import { useTheme } from '@emotion/react';
import { arrayWrap, numToArray, round } from '../../../utils/common';
import { useEffect, useState } from 'react';

const SurveyQuestionMultipleChoice = ({
	question,
	value,
	onChange,
}) => {
	const className = 'SurveyQuestionMultipleChoice';

	const theme = useTheme();

	const [columns, setColumns] = useState(1);
	const [regularOptions, setRegularOptions] = useState([]);
	const [noneOption, setNoneOption] = useState(null);

	useEffect(() => {
		setRegularOptions(question?.options?.filter?.((option) => option.value !== 'none'));
		setNoneOption(question?.options?.find?.((option) => option.value === 'none'));
		setColumns(question?.columns || 1);
	}, [question]);

	return (
		<div
			style={{
				width: '100%',
				display: 'grid',
				gridTemplateColumns: `repeat(${columns}, ${round(100 / columns, 2)}%)`,
				rowGap: 10,
				columnGap: 10,
			}}
		>
			{regularOptions
				?.map?.((option, index) => {
					return (
						<Button
							key={index}
							onClick={(e) => {
								e.stopPropagation();
								e.preventDefault();
								onChange(
									(value || [])?.includes(option.value)
										? (value || [])?.filter?.((v) => v !== option.value && v !== 'none')
										: [...(value || []).filter((v) => v !== 'none'), option.value],
								);
							}}
							size='large'
							style={{
								display: 'block',
								minHeight: 60,
								height: 'unset',
								width: '100%',
								textAlign: 'left',
								paddingRight: 20,
							}}
							{...arrayWrap(value || []).includes(option.value) ? {
								type: 'primary',
							} : {}}
						>
							<Checkbox
								checked={arrayWrap(value || []).includes(option.value)}
								style={{
									fontSize: 12,
									marginBottom: 1,
									verticalAlign: 'middle',
									...value === option.value
										? { color: theme.color.fitBlueElectric }
										: {},
								}}
							/>
							<span
								style={{
									verticalAlign: 'middle',
									whiteSpace: 'normal',
									marginLeft: 10,
									lineHeight: 1.2,
									width: 'calc(100% - 22px)',
								}}
							>
								{option.label?.fr || option.label?.en || option.label}
							</span>
						</Button>
					);
				})
			}
			{noneOption && (
				<>
					{numToArray((columns - (regularOptions?.length || 0) % columns) % columns)
						.map((item, index) => <div key={index} />)}
					<div
						style={{
							gridColumn: `span ${columns}`,
							height: 10,
						}}
					/>
					<div>
						<Checkbox
							checked={arrayWrap(value || []).includes(noneOption.value)}
							style={{
								marginLeft: 16,
								fontSize: 12,
								...value === noneOption.value ? { color: theme.color.fitBlueElectric } : {},

							}}
							onChange={() => onChange(
								(value || [])?.includes(noneOption.value)
									? null
									: [noneOption.value],
							)}
						>
							<span
								style={{
									whiteSpace: 'normal',
									fontSize: 16,
									marginLeft: 10,
									lineHeight: 1.2,
								}}
							>
								{noneOption.label?.fr || noneOption.label?.en || noneOption.label}
							</span>
						</Checkbox>
					</div>
				</>
			)}
		</div>
	);
};

export default SurveyQuestionMultipleChoice;
