import { Space } from 'antd';
import { FaBan, FaCheck, FaTimes } from 'react-icons/fa';
import BlockStaff from '../../app/blocker/BlockStaff';
import CompanySettingsCard from './CompanySettingsCard';
import { useTheme } from '@emotion/react';
import CompanySettingsTranslationForm from './CompanySettingsTranslationForm';
import CompanySettingsTranslationEditionStatus from './CompanySettingsTranslationEditionStatus';
import CompanySettingsTranslationPublicationStatus from './CompanySettingsTranslationPublicationStatus';

const CompanySettingsTranslationCard = (
	{
		company,
		...props
	},
) => {
	const className = 'CompanySettingsTranslationCard';
	const theme = useTheme();

	return (
		<div style={{ position: 'relative' }}>
			<BlockStaff withBorder={false} tagStyle={{ top: 2, left: 24, opacity: 1 }}>
				<CompanySettingsCard
					title={<>👅 Translations</>}
					details={<>
						Defines whether french translations can be set for all contents
					</>}
					company={company}
					CompanySettingsForm={CompanySettingsTranslationForm}
				>
					<CompanySettingsTranslationEditionStatus company={company} />
					<CompanySettingsTranslationPublicationStatus company={company} />
				</CompanySettingsCard>

			</BlockStaff>
		</div>
	);
};

export default CompanySettingsTranslationCard;
