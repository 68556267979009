import { Affix } from 'antd';
import { useSelector } from 'react-redux';
import PageLayout from '../../components/app/layout/PageLayout';
import { getCompanySelected } from '../../reducers/app';
import { useContext, useEffect, useState } from 'react';
import CompanyGridPicker from '../../components/company/CompanyGridPicker';
import { documentId, scrollToTop } from '../../utils/common';
import CompanyModel from '../../models/CompanyModel';
import { ENGLISH_LANG } from '../../constants/constant';
import { useTheme } from '@emotion/react';
import { useScrollToTopEffect } from '../../hooks/useScrollToTopEffect';
import { useAuthContext } from '../../hooks/useAuthContext';
import CompanyPageHeader from '../../components/company/companyPage/shared/CompanyPageHeader';
import CompanyPageEditionLayout from '../../components/company/companyPage/CompanyPageEditionLayout';
import CompanyPageHeroMessage from '../../components/company/companyPage/shared/CompanyPageHeroMessage';
import CompanyPagePublicationPopin from '../../components/company/companyPage/shared/CompanyPagePublicationPopin';
import CompanyPageHeaderLegacy from '../../components/company/companyPage/shared/CompanyPageHeaderLegacy';
import CompanyPageUnpublicationPopin from '../../components/company/companyPage/shared/CompanyPageUnpublicationPopin';
import { StaffToolsContext } from '../../contexts/StaffToolsProvider';
import { CompanyPageContext } from '../../components/company/companyPage/shared/CompanyPageProvider';
import useBodyScrollBlocker from '../../hooks/useBodyScrollBlocker';
import StaffManagerCompanyPageTab from '../../components/staff/manager/tabs/StaffManagerCompanyPageTab';

const CompanyEditionPage = (
	{
		...props
	},
) => {
	const className = 'CompanyEditionPage';

	const theme = useTheme();

	const companySelected = useSelector(getCompanySelected);
	const { currentlyEditingStep } = useContext(CompanyPageContext);
	const { setPanelContent } = useContext(StaffToolsContext);

	const { isStaff, isStaffView } = useAuthContext();
	const [companyModel, setCompanyModel] = useState(null);
	const [lang, setLang] = useState(ENGLISH_LANG);

	useBodyScrollBlocker(!!currentlyEditingStep, className);
	useScrollToTopEffect();

	// Wrapping the selected company inside a CompanyModel
	useEffect(() => {
		if (documentId(companySelected) !== documentId(companyModel)) {
			scrollToTop();
		}
		setCompanyModel(new CompanyModel(companySelected || {}));
	}, [companySelected]);

	useEffect(() => {
		setPanelContent?.(<StaffManagerCompanyPageTab company={companyModel} />);
		return () => setPanelContent?.(null);
	}, [companyModel, setPanelContent]);

	// If the company is not set as translatable, forcing lang to English
	useEffect(() => {
		if (!companyModel?.isTranslatable) setLang(ENGLISH_LANG);
	}, [companyModel]);

	if (!companySelected || !companyModel) return <CompanyGridPicker />;

	return (
		<PageLayout
			withHeader={false}
			style={{ paddingTop: 0 }}
		>
			{/* Wrapping inside the provider to share context */}
			<div style={{ position: 'relative', margin: '0 -24px' }}>
				{/* Managing the switch between legacy and non-legacy */}
				{companyModel.isCompanyPageEditionLegacyVersion()
					? (
						<>
							<Affix style={{ zIndex: 20 }}>
								<CompanyPageHeaderLegacy company={companyModel} />
							</Affix>
							<CompanyPageEditionLayout company={companyModel}/>
						</>
					)
					: (
						<>
							<Affix style={{ zIndex: 20 }}>
								<CompanyPageHeader company={companyModel} />
							</Affix>
							<CompanyPageHeroMessage company={companyModel} />
							<CompanyPageEditionLayout company={companyModel}/>
						</>
					)
				}
			</div>
			<CompanyPagePublicationPopin company={companyModel} />
			<CompanyPageUnpublicationPopin company={companyModel} />
		</PageLayout>
	);
};

export default CompanyEditionPage;
