import { useSelector } from 'react-redux';
import { useContext, useEffect, useState } from 'react';
import { getCompanySelected } from '../../../../reducers/app';
import { CompanyPageContext } from './CompanyPageProvider';
import { ENGLISH_LANG } from '../../../../constants/constant';
import CompanyModel from '../../../../models/CompanyModel';
import CompanyPagePreviewButton from './CompanyPagePreviewButton';

const CompanyPageNoticeTranslate = () => {
	const company = useSelector(getCompanySelected);

	const { lang, langTexts } = useContext(CompanyPageContext);
	const [firstLine, setFirstLine] = useState(null);
	const [secondLine, setSecondLine] = useState(null);

	useEffect(() => {
		setFirstLine(
			<>
				You're currently editing
				the <strong>{langTexts.flag} {langTexts.label}</strong> version
				of this content
			</>,
		);
		if (lang === ENGLISH_LANG) {
			if (!new CompanyModel(company).isPublishedNow()) {
				setSecondLine(
					<>
						When your company page is published in English,
						you will be able to <strong>translate</strong> this
						content in 🇫🇷 French
					</>,
				);
			}
		} else {
			setSecondLine(
				<div
					style={{
						position: 'absolute',
						top: -6,
						right: 0,
					}}
				>
					<CompanyPagePreviewButton
						company={company}
						forceLang={ENGLISH_LANG}
						title={<b>See my English version</b>}
					/>
				</div>,
			);
		}
	}, [lang, langTexts]);

	return (
		<div
			style={{
				position: 'relative',
				// display: 'block',
				marginBottom: 20,
			}}
		>
			<div>{firstLine}</div>
			{secondLine && <div>{secondLine}</div>}
		</div>
	);
};
export default CompanyPageNoticeTranslate;
