import Box from './Box';

const BoxRainbow = ({
	mode = 'box',
	angle = '135deg',
	borderSize = 2,
	bgColor = '#ffffff',
	style = {},
	children,
	...props
}) => {
	const background = ({
		angle = '135deg',
		bgColor = 'transparent',
	}) => {
		return {
			background: `linear-gradient(${bgColor}, ${bgColor}) padding-box padding-box, linear-gradient(${angle}, rgb(255, 107, 107) 14.71%, rgb(255, 170, 68) 25.87%, rgb(255, 247, 65) 35.64%, rgb(99, 255, 96) 44.59%, rgb(0, 245, 212) 55.48%, rgb(0, 187, 249) 63.81%, rgb(83, 80, 255) 71.04%, rgb(155, 93, 229) 78.81%, rgb(241, 91, 181) 85.43%) border-box border-box`,
		};
	};

	let borders = { border: `${borderSize}px solid transparent` };

	if (mode === 'left') {
		angle = '180deg';
		borders = {
			borderTop: '0px solid transparent',
			borderRight: '0px solid transparent',
			borderBottom: '0px solid transparent',
			borderLeft: `${borderSize}px solid transparent`,
		};
	}

	if (mode === 'right') {
		angle = '180deg';
		borders = {
			borderTop: '0px solid transparent',
			borderRight: `${borderSize}px solid transparent`,
			borderBottom: '0px solid transparent',
			borderLeft: '0px solid transparent',
		};
	}

	if (mode === 'top') {
		angle = '90deg';
		borders = {
			borderTop: `${borderSize}px solid transparent`,
			borderRight: '0px solid transparent',
			borderBottom: '0px solid transparent',
			borderLeft: '0px solid transparent',
		};
	}

	if (mode === 'bottom') {
		angle = '270deg';
		borders = {
			borderRight: '0px solid transparent',
			borderTop: '0px solid transparent',
			borderBottom: `${borderSize}px solid transparent`,
			borderLeft: '0px solid transparent',
		};
	}

	return (
		<Box
			{...props}
			style={{
				...borders || {},
				...background({ angle, bgColor }),
				...style,
			}}
		>
			{children}
		</Box>
	);
};

export default BoxRainbow;
