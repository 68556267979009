import { Space, Tooltip } from 'antd';
import { useEffect, useState } from 'react';
import { FaCheck, FaTimes } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import { updateOrCreateJob } from '../../../actions/jobs';
import { useAuthContext } from '../../../hooks/useAuthContext';
import { useTheme } from '@emotion/react';
import { LocalDebug } from '../../../utils/LocalDebug';
import JobModel from '../../../models/JobModel';
import InlineNavLink from '../../app/button/InlineNavLink';
import { getCompanySelected } from '../../../reducers/app';
import { documentId } from '../../../utils/common';

const JobNotificationsStatus = (
	{
		job,
		onRefresh,
	},
) => {
	const className = 'JobNotificationsToggle';
	const dispatch = useDispatch();
	const theme = useTheme();
	const { currentUser } = useAuthContext();
	const companySelected = useSelector(getCompanySelected);
	const [isManager, setIsManager] = useState((job?.managerIds || [])?.map((i) => i?.toString()).includes(documentId(currentUser)));
	const [countJobManagers, setCountJobManagers] = useState((job?.managerIds || []).length);
	const shouldBeNotifiedForApplysIfNoRecipient = currentUser?.companyMember?.shouldBeNotifiedForApplysIfNoRecipient !== false;

	useEffect(() => {
		// LocalDebug.logUseEffect({ className, effects: 'job' }, { managerIds: job?.managerIds });
		setIsManager((job?.managerIds || [])?.map((i) => i?.toString()).includes(documentId(currentUser)));
		setCountJobManagers((job?.managerIds || []).length);
	}, [job]);

	const handleChange = async (value) => {
		const method = 'handleChange';
		// LocalDebug.logInfo({ className, method }, { 'job?.managerIds': job?.managerIds });
		const managerIds = (job?.managerIds || []).filter((id) => id !== documentId(currentUser));
		if (value) managerIds.push(documentId(currentUser));
		setIsManager(value);
		setCountJobManagers(managerIds?.length);
		// LocalDebug.logInfo({ className, method }, { managerIds });
		await dispatch(updateOrCreateJob(
			{ id: documentId(job), managerIds },
			{ successMessage: <>Notifications <b>{value ? 'activated' : 'removed'}</b> for job <b>{job.title}</b></> },
		));
		if (onRefresh) onRefresh();
	};

	let tooltipTitle = '';
	let shouldReceiveNotifications = false;
	if (isManager) {
		if (countJobManagers === 0) {
			shouldReceiveNotifications = true;
			tooltipTitle = <>
				You <b>subscribed to notifications</b> upon applications for this job offer.
			</>;
		} else {
			shouldReceiveNotifications = true;
			tooltipTitle = <>
				You <b>subscribed to notifications</b> upon applications for this job offer
				{(countJobManagers > 1)
					&& <>, along with {countJobManagers - 1} other colleague{countJobManagers > 2 ? 's' : ''}.</>
				}</>;
		}
	} else if (countJobManagers === 0) {
		if (shouldBeNotifiedForApplysIfNoRecipient) {
			shouldReceiveNotifications = true;
			tooltipTitle = <>
					You <b>will receive notifications</b> upon applications for this job offer,
					because <b>nobody has yet subscribed</b> for notifications.<br />
					You can change this default behaviour in <InlineNavLink path='/account'>your account</InlineNavLink>
			</>;
		} else {
			shouldReceiveNotifications = false;
			tooltipTitle = <>
					You <b>won't receive notifications</b> upon applications for this job offer.
			</>;
		}
	} else {
		shouldReceiveNotifications = false;
		tooltipTitle = <>
				You <b>won't receive notifications</b> upon applications for this job offer.<br />
			{countJobManagers} colleague{countJobManagers > 1 ? 's' : ''} will be notified
		</>;
	}

	const isCurrentUserDefaultRecipient = companySelected?.shouldNotifyAllOnApplysIfNoRecipients === false
		&& companySelected?.defaultRecipientForApplysIfNoRecipients?.toString() === documentId(currentUser);

	const disabledByPublished = !new JobModel(job).isJobPublished;
	const disabledByDefaultRecipient = isCurrentUserDefaultRecipient && !countJobManagers;

	if (disabledByDefaultRecipient) {
		shouldReceiveNotifications = true;
		tooltipTitle = <>
			You <b>will receive notifications</b> upon applications for this job offer,
			because you have been set as the <b>default recipient</b> when
			no other notification recipients are defined for a job offer.<br />
			Contact your <b>company admin</b> to change this behaviour.
		</>;
	}

	shouldReceiveNotifications = shouldReceiveNotifications && !disabledByPublished;
	// const shouldReceiveNotifications = isManager || !(disabledByPublished || disabledByDefaultRecipient);

	const status = (
		<div
			style={{
				width: 32,
				height: 32,
				borderRadius: 32,
				marginRight: 6,
				display: 'flex-inline',
				justifyContent: 'center',
				alignItems: 'center',
				background: shouldReceiveNotifications ? theme.color.darkturquoise : theme.color.grey,
				color: theme.color.white,
				fontSize: 18,
			}}>
			{shouldReceiveNotifications
				?				<>
					<FaCheck style={{ marginBottom: -5 }} />
				</>
				: <>
					<FaTimes style={{ marginBottom: -5 }} />
				</>}
		</div>);

	return (
		<>
			<Space size={6}>
				{disabledByPublished
					? <>{status}</>
					: <>
						<Tooltip title={tooltipTitle} placement='top'>
							{status}
						</Tooltip>
					</>

				}
			</Space>
			{/* {<ReactJsonAdmin src={{ */}
			{/*	isManager, isCurrentUserDefaultRecipient, */}
			{/*	shouldNotifyAllOnApplysIfNoRecipients: companySelected?.shouldNotifyAllOnApplysIfNoRecipients, */}
			{/*	// defaultRecipientForApplysIfNoRecipients: companySelected?.defaultRecipientForApplysIfNoRecipients, */}
			{/*	shouldReceiveNotifications, */}
			{/*	countJobManagers, */}
			{/*	disabledByPublished, disabledByDefaultRecipient, */}
			{/*	// disabled, isManager, countJobManagers, countJobManagersBool: countJobManagers > 0, shouldBeNotifiedForApplysIfNoRecipient, */}
			{/*	// test: (disabled || isManager || countJobManagers > 0 || !shouldBeNotifiedForApplysIfNoRecipient), */}
			{/*	// managerIds: job?.managerIds, */}
			{/* }}/>} */}
		</>
	);
};

export default JobNotificationsStatus;
