import ReportsPage from '../../containers/root/ReportsPage';
import { PERMS_GRANT_DOCUMENT_ADMIN } from '../menu.utils';
import MenuBase from '../MenuBase';
import { LineChartOutlined } from '@ant-design/icons';

export const GenderhireReportsIcon = LineChartOutlined;

class GenderhireReportsMenu extends MenuBase {
	static props = {
		name: 'reports',
		path: '/reports',
		label: 'Reports',
		icon: <GenderhireReportsIcon />,
		iconLegacy: '🔬',
		Component: ReportsPage,
		perms: PERMS_GRANT_DOCUMENT_ADMIN,
	};

	constructor() {
		super(GenderhireReportsMenu.props);
	}
}

export default GenderhireReportsMenu;
