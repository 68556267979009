import { useTheme } from '@emotion/react';
import { Segmented } from 'antd';
import CompanyActionModel from '../../../../models/CompanyActionModel';
import { GS_ACTION_TIMEFRAME_OPTIONS } from '../action-plan';
import CompanyActionTimeframeTag from '../CompanyActionTimeframeTag';

const CompanyActionTimeframeMgr = ({
	action,
	onEditAction,
	isSendActionPending = false,
	setIsSendActionPending,
	...props
}) => {
	const className = 'CompanyActionTimeframeMgr';

	const theme = useTheme();

	action = action instanceof CompanyActionModel
		? action
		: new CompanyActionModel(action);

	const timeframeOptions = GS_ACTION_TIMEFRAME_OPTIONS
		.map(({ value, label }) => ({
			value,
			label: (
				<div style={{ margin: '4px 0', pointerEvents: 'none' }}>
					<CompanyActionTimeframeTag
						action={{ timeframe: value }}
						style={{
							background: 'none',
							color: value === action.timeframe ? 'white' : theme.color.fitBlueElectric,
						}}
					/>
				</div>
			),
		}));

	return (
		<Segmented
			className='action-plan'
			disabled={isSendActionPending}
			defaultValue={action.timeframe}
			onChange={(value) => {
				setIsSendActionPending?.(true);
				onEditAction?.({ action: { ...action, timeframe: value } });
			}}
			options={timeframeOptions}
			block
			style={{ marginTop: 12 }}
		/>
	);
};

export default CompanyActionTimeframeMgr;
