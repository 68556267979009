import { LocalDebug } from '../../utils/LocalDebug';
import { message } from 'antd';
import { useAuthContext } from '../../hooks/useAuthContext';
import { useState } from 'react';
import Link from '../app/Link';
import { ArrowRightOutlined } from '@ant-design/icons';
import { useTheme } from '@emotion/react';

const AccountCredentialsPasswordForm = ({
	user,
	onSuccess,
	...props
}) => {
	const className = 'AccountCredentialsPasswordForm';

	const theme = useTheme();
	const { resetPassword } = useAuthContext();

	const [resetPasswordLinkSent, setResetPasswordLinkSent] = useState(false);

	const handleResetPassword = () => {
		setResetPasswordLinkSent(true);
		const result = resetPassword(user.email);
		LocalDebug.logInfo({ className, method: 'handleResetPassword' }, { result });
		message.success(<>A link to reset your password was sent to <b>{user.email}</b></>);
		onSuccess?.(user);
	};

	return (
		<div style={{ ...props?.style }}>
			{!resetPasswordLinkSent
				? (
					<Link onClick={handleResetPassword}>
						<span>Receive a link to reset your password <ArrowRightOutlined /></span>
					</Link>
				)
				: <>✅ Link sent!</>
			}
		</div>
	);
};

export default AccountCredentialsPasswordForm;
