import Card from '../app/card/Card';
import { useTheme } from '@emotion/react';
import Link from '../app/Link';
import { useState } from 'react';
import { Space } from 'antd';
import Popin from '../app/modal/Popin';
import useBodyScrollBlocker from '../../hooks/useBodyScrollBlocker';
import { mixColors } from '../../utils/common';
import ModalContainerBase from '../../contexts/modals/ModalContainerBase';

const AccountCard = ({
	title,
	details,
	user,
	AccountForm,
	children,
}) => {
	const className = 'AccountCard';
	const theme = useTheme();

	const [openForm, setOpenForm] = useState(false);

	useBodyScrollBlocker(openForm, className);

	return (
		<>
			<Card
				style={{
					position: 'relative',
					borderRadius: 5,
					border: '1px solid #ddd',
				}}
			>
				<Space
					direction='horizontal'
					align='top'
					style={{
						width: '100%',
						justifyContent: 'space-between',
					}}
					wrap={true}
				>
					<h3 style={{ marginTop: 0 }}>{title}</h3>
					{AccountForm && (
						<Link
							onClick={() => { setOpenForm(true); }}
							style={{
								background: mixColors(theme.color.white, theme.color.fitBlueElectric, 10),
								padding: '2px 12px',
								borderRadius: 30,
							}}
						>
							Edit
						</Link>
					)}
				</Space>
				{details && (
					<div
						style={{
							fontSize: 12,
							color: theme.color.grey,
							marginBottom: 20,
						}}
					>
						{details}
					</div>
				)}
				{children}
			</Card>

			{AccountForm && (
				<ModalContainerBase
					open={openForm}
					width={800}
					setOpen={setOpenForm}
				>
					<AccountForm
						user={user}
						onSuccess={() => { setOpenForm(false); }}
					/>
				</ModalContainerBase>
			)}
		</>
	);
};

export default AccountCard;
