import shared, { optionalizeQuestion } from '../../shared';
import QUESTIONS from '../../questions';
import { numToArray } from '../../../../../utils/common';

const scorify = (questions) => questions.map((question) => ({
	question,
	weight: 1,
	computer: shared.COMPUTE_VALUE,
	ranges: 100 / 5,
}));

const list = [
	// SFFP
	QUESTIONS.MGR_SFFP_ALLOCATE_MORE_TIME,
	QUESTIONS.MGR_SFFP_BEHAVE_DIFFERENTLY,
	QUESTIONS.MGR_SFFP_EXPECTATIONS_BEHAVIOR,
	QUESTIONS.MGR_SFFP_EXPECTATIONS_RESPONSIBILITIES,
	QUESTIONS.MGR_SFFP_PERSONAL_INFORM_PERFORMANCE,
	QUESTIONS.MGR_SFFP_PREDICTIONS_CONFIRMED,
	QUESTIONS.MGR_SFFP_REMEMBER_IF_CONFIRM,
	// BLE
	QUESTIONS.MGR_BLE_IDEAS_CERTAIN_EMPS,
	QUESTIONS.MGR_BLE_NOT_CONSIDER_IF_OBLIGATIONS,
	QUESTIONS.MGR_BLE_SAME_TEAM_NOT_DISCUSSIONS,
	QUESTIONS.MGR_BLE_SAME_XP_NOT_SAME_SALARY,
	QUESTIONS.MGR_BLE_SOCIAL_SKILLS_EVAL,
	QUESTIONS.MGR_BLE_TRAITS_LIMIT_PROMOTION,
	QUESTIONS.MGR_BLE_UNASSERTIVE_NO_RESP,
	// HHE
	QUESTIONS.MGR_HHE_FIRST_IMP_EVALUATION,
	QUESTIONS.MGR_HHE_FIRST_MEETING_GOOD_WORK,
	QUESTIONS.MGR_HHE_NEGATIVE_ATTRIBUTE_MORE,
	QUESTIONS.MGR_HHE_NEGATIVE_IMP_NEGATIVE_EVAL,
	QUESTIONS.MGR_HHE_POSITIVE_ATTRIBUTE_MORE,
	QUESTIONS.MGR_HHE_POSITIVE_FEEDBACK_GOOD_IMP,
	QUESTIONS.MGR_HHE_TRUST_INITIAL_IMP_EVAL,
	// OVC
	QUESTIONS.MGR_OVC_CRITICAL,
	QUESTIONS.MGR_OVC_EXPECTATIONS_UNMET_NEGATIVE,
	QUESTIONS.MGR_OVC_GENERAL_UNMET_EXPECTATIONS,
	QUESTIONS.MGR_OVC_HIGHLIGHT_NEGATIVE,
	QUESTIONS.MGR_OVC_NEGATIVE_MOTIVATIONAL,
	QUESTIONS.MGR_OVC_SKILLS_BEYOND_REQ,
	QUESTIONS.MGR_OVC_WEIGH_MORE_FAILURES,
	// REC
	QUESTIONS.MGR_REC_LATEST_ACCOMPS_EVALUATION,
	QUESTIONS.MGR_REC_LATEST_ACCOMPS_INFORM_FUTURE,
	QUESTIONS.MGR_REC_LATEST_PERF_REFLECT_OVERALL,
	QUESTIONS.MGR_REC_MOST_RECENT_EVALUATION,
	QUESTIONS.MGR_REC_MOST_RECENT_INFORM_CAPABILITIES,
	QUESTIONS.MGR_REC_REMEMBER_MOST_RECENT,
	QUESTIONS.MGR_REC_UNNECESSARY_CONSIDER_BEGINNING,
	// IGF
	QUESTIONS.MGR_IGF_CLOSER_RESEMBLE,
	QUESTIONS.MGR_IGF_COMMON_CHARS_IMPORTANT_WORK,
	QUESTIONS.MGR_IGF_RELY_IF_COMMON_CHARS,
	QUESTIONS.MGR_IGF_SIMILARITIES_ENJOY,
	QUESTIONS.MGR_IGF_SIMILAR_PEOPLE_WORK_BETTER,
	QUESTIONS.MGR_IGF_TEAM_LOOK_LIKE_MGR,
	QUESTIONS.MGR_IGF_TRAITS_SHARED_EVALUATION,
	// CTE
	QUESTIONS.MGR_CTE_ADJUST_COLLEAGUES,
	QUESTIONS.MGR_CTE_ASSESS_PERF_COMPARISON_MOST,
	QUESTIONS.MGR_CTE_COMPARE_PERF_TENDENCY,
	QUESTIONS.MGR_CTE_COMPARING_IMPORTANT_ASPECT,
	QUESTIONS.MGR_CTE_EMP_SHOULD_COMPARE,
	QUESTIONS.MGR_CTE_EVALUATATION_RELATIVE_MOST,
	QUESTIONS.MGR_CTE_UNABLE_ASSESS_WITHOUT_COMPARE,
];

const shuffledList = list.map((_, index) => {
	const newIndex = (index * 7 + Math.floor((index * 7) / list.length)) % list.length;
	return list[newIndex];
});

const section = {
	value: 'manager',
	label: 'Unbiased Management Score - Manager',
	slug: 'manager',
	initials: 'MGR',
	colors: {
		main: '#531DAB',
		light: '#F9F0FF',
		tag: 'purple',
	},
	details: {
		fr: `## Bienvenue !

50inTech a conçu un questionnaire pour sonder **votre perception des méthodes d'évaluation** de vos performances par votre responsable. Notre objectif est de promouvoir **une évaluation équitable**, garantissant de meilleures conditions de travail et un avenir professionnel plus **juste**.

Vous évaluerez des affirmations sur une **échelle de 1 à 6**, selon votre point de vue. Il n'y a pas de bonnes ou de mauvaises réponses, mais elles doivent refléter **vos convictions personnelles**.

Ce questionnaire prendra environ **10 minutes** et vos réponses resteront **anonymes**.`,
		en: `## Welcome!

50inTech has designed a questionnaire to probe **your perception of the evaluation methods** of your performance by your manager. Our goal is to promote **fair evaluation**, ensuring better working conditions and a more **just** professional future.

You will rate statements on a **scale of 1 to 6**, according to your viewpoint. There are no right or wrong answers, but they should reflect **your personal beliefs**.

This questionnaire will take about **10 minutes** and your responses will remain **anonymous**.`,
	},
	questions: [
		...shuffledList,

		// Infos

		QUESTIONS.INFO_GENDER,
		QUESTIONS.INFO_AGE,
		QUESTIONS.INFO_COMPANY_NAME,
		QUESTIONS.INFO_COMPANY_SIZE,
		QUESTIONS.INFO_COUNTRY_WORK,
		QUESTIONS.INFO_COUNT_SUBORDINATES,
		QUESTIONS.INFO_CURRENT_TENURE,

		QUESTIONS.INFO_COMPANY_EVALUATION_FREQUENCY,
		QUESTIONS.INFO_EVALUATION_IMPLICATIONS,
		QUESTIONS.INFO_EVALUATION_OBJECTIVES,
		QUESTIONS.INFO_MANAGEMENT_EXP_LEVEL,
		QUESTIONS.INFO_MANAGER_GENDER,
		QUESTIONS.INFO_WEEKLY_WORKED_HOURS,
		QUESTIONS.INFO_WORK_ARRANGEMENT,
		optionalizeQuestion(QUESTIONS.INFO_SUGGESTIONS),
	],
};

export default section;
