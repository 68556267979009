import { ENGLISH_LANG } from '../../../../constants/constant';
import CompanyRichTextEditor from '../shared/CompanyRichTextEditor';

const CompanyWeLikeEditor = (
	{
		company,
		lang = ENGLISH_LANG,
		setEditorOpen,
	},
) => {
	const className = 'CompanyWeLikeEditor';

	return (
		<CompanyRichTextEditor
			company={company}
			lang={lang}
			title={<b>What we like about</b>}
			field={'weLike'}
			setEditorOpen={setEditorOpen}
		/>
	);
};

export default CompanyWeLikeEditor;
