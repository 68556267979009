import { useTheme } from '@emotion/react';
import ModalContainerBase from '../../../contexts/modals/ModalContainerBase';
import CompanyActionLibrary from './CompanyActionLibrary';
import { LocalDebug } from '../../../utils/LocalDebug';

const CompanyActionLibraryModal = ({
	actions,
	pillar,
	open,
	setOpen,
	onAddAction,
	onEditAction,
	onDeleteAction,
	isSendActionPending,
	setIsSendActionPending,
}) => {
	const className = 'CompanyActionLibraryModal';

	const theme = useTheme();

	// LocalDebug.logInfo({ className }, { actions: actions?.length });

	return (
		<ModalContainerBase
			open={open}
			setOpen={setOpen}
			cssClassName='modal-large no-padding'
			width={1180}
			style={{
				position: 'relative',
				marginTop: 30,
				marginBottom: 30,
				maxHeight: 'calc(100vh - 60px)',
			}}
		>
			<div
				style={{
					boxSizing: 'border-box',
					// overflow: 'scroll',
					height: 'calc(100vh - 60px - 40px)',
					padding: '20px 24px',
				}}
			>
				<CompanyActionLibrary
					actions={actions}
					pillar={pillar}
					viaModal={true}
					onAddAction={onAddAction}
					onEditAction={onEditAction}
					onDeleteAction={onDeleteAction}
					isSendActionPending={isSendActionPending}
					setIsSendActionPending={setIsSendActionPending}
				/>
			</div>

		</ModalContainerBase>
	);
};

export default CompanyActionLibraryModal;
