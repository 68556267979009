import { useTheme } from '@emotion/react';
import { useMemo } from 'react';
import { ArrowRightIcon } from '../../../../utils/common';

const GenderScoreActionStepItem = ({
	title,
	...props
}) => {
	const theme = useTheme();

	const memoizedContent = useMemo(() => {
		return (
			<div
				{...props}
				style={{
					color: '#333',
					...props?.style,
				}}
			>
				<ArrowRightIcon /> {title}
			</div>
		);
	}, [title, props]);

	return memoizedContent;
};

export default GenderScoreActionStepItem;
