import { UMS_SURVEY_MANAGER_V1_DATA_OPTION } from './ums-survey-manager';
import { UMS_SURVEY_EMPLOYEE_V1_DATA_OPTION } from './ums-survey-employee';

export const options = [
	UMS_SURVEY_MANAGER_V1_DATA_OPTION,
	UMS_SURVEY_EMPLOYEE_V1_DATA_OPTION,
];

export const getSurveyDataOption = (value) => options
	?.find((o) => o?.value === value);

const exporter = {
	options,
	getSurveyDataOption,
};

export default exporter;
