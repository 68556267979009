import { DownloadOutlined } from '@ant-design/icons';
import { useTheme } from '@emotion/react';
import { Button } from 'antd';
import { useState } from 'react';
import ModalContainerBase from '../../../contexts/modals/ModalContainerBase';
import StaffGenderScoreBadgeMaker from '../../../containers/staff/product/StaffGenderScoreBadgeMaker';
import useBodyScrollBlocker from '../../../hooks/useBodyScrollBlocker';

const CompanyGenderScoreBadgeDownloader = ({
	size,
}) => {
	const className = 'CompanyGenderScoreBadgeDownloader';

	const theme = useTheme();

	const [openModal, setOpenModal] = useState(false);

	useBodyScrollBlocker(openModal, className);

	const onClose = () => {
		setOpenModal(false);
	};

	return (
		<>
			<Button
				type='primary'
				shape="circle"
				size={size}
				onClick={(e) => {
					setOpenModal(true);
					e?.preventDefault?.();
					e?.stopPropagation?.();
				}}
			>
				<DownloadOutlined />
			</Button>

			<ModalContainerBase
				open={openModal}
				onCancel={onClose}
				width={1100}
			>
				<div
					style={{
						maxHeight: '85vh',
					}}
				>
					<StaffGenderScoreBadgeMaker
						withEditableScore={false}
						withEditablePreviousScore={false}
						withEditableExpirationDate={false}
						withCustomFilename={false}
						withModalContainer={true}
					/>
				</div>

			</ModalContainerBase>
		</>
	);
};

export default CompanyGenderScoreBadgeDownloader;
