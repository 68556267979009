import { Typography } from 'antd';
import { MailOutlined } from '@ant-design/icons';
import { useTheme } from '@emotion/react';

const UserStubEmail = ({ user, level = null, ...props }) => {
	const theme = useTheme();

	if (!user?.email) {
		return null;
	}

	const content = <div style={{ ...props?.style }}>
		<MailOutlined style={{ color: theme.color.grey }}/>&nbsp;
		{user?.email}
	</div>;

	return level
		? <Typography.Title level={level} style={{ marginBottom: 0 }}>
			{content}
		</Typography.Title>
		: <Typography style={{ color: theme.color.darkgrey, fontSize: 12, ...props?.style }}>
			{content}
		</Typography>;
};

export default UserStubEmail;
