import { useDispatch } from 'react-redux';
import { useTheme } from '@emotion/react';
import { FaCog } from 'react-icons/fa';
import { useAuthContext } from '../../../hooks/useAuthContext';
import { buildConfirmBtn } from '../../../utils/common';

const StaffCommandButton = (
	{
		title,
		path,
		withPath = true,
		params,
		Icon = FaCog,
		dispatcher,
		tooltip,
		disabled,
		callback,
		style,
	},
) => {
	const theme = useTheme();
	const dispatch = useDispatch();
	const { isAdmin } = useAuthContext();

	return (
		buildConfirmBtn({
			theme,
			title,
			path,
			withPath,
			params,
			Icon,
			dispatcher,
			dispatch,
			tooltip,
			disabled,
			callback,
			style,
		})
	);
};

export default StaffCommandButton;
