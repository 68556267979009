import { DATE_FORMAT } from '../constants/constant';
import moment from 'moment';
import JobModel from './JobModel';

export default class SourcingModel {
	atsSync;

	job;

	constructor(sourcing) {
		Object.assign(this, sourcing);
		// this.job = new JobModel(sourcing?.job);
	}

	get atsProvider() { return this.atsSync?.atsProvider || this.job?.atsSync?.atsProvider; }

	get candidateId() { return this.atsSync?.push?.atsId?.id; }

	get opportunityId() { return this.atsSync?.push?.atsId?.opportunityId; }

	get openAtsUrl() {
		const url = this?.atsSync?.push?.openAtsUrl;
		return url;
	}

	get atsSentAt() { return this?.atsSync?.push?.pushedAt; }

	get hasAtsProvider() { return !!this.atsProvider; }

	get hasJob() { return !!this.job; }

	get isAtsLinked() {
		if (['lever', 'lever_eu', 'lever_sandbox'].includes(this.atsProvider)) {
			return !!this.opportunityId;
		}
		if (['greenhouse'].includes(this.atsProvider)) {
			return !!this.candidateId;
		}
		if (['smartrecruiters'].includes(this.atsProvider)) {
			return !!this.candidateId;
		}
		if (['teamtailor'].includes(this.atsProvider)) {
			return !!this.atsSync?.push?.atsId?.data?.id;
		}
		if (['workable'].includes(this.atsProvider)) {
			return !!this.atsSync?.push?.atsId;
		}
		if (['ashby'].includes(this.atsProvider)) {
			return !!this.atsSync?.push?.atsId;
		}
		return false;
	}

	get isPushPending() {
		return this?.atsSync?.push?.pending === true;
	}

	get isJobAtsLinked() { return !!this?.job?.atsSync?.atsProvider; }

	get lastSyncedDate() { return this?.atsSync?.stage?.lastSyncedAt; }

	get lastSyncedDateFormatted() { return moment(this.lastSyncedDate).format(DATE_FORMAT); }

	get isSyncDisabled() {
		return false;
		// if (!this.lastSyncedDate) return false;
		// return moment(this.lastSyncedDate).isAfter(moment().add(-3, 'second'));
	}
}
