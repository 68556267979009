/** @jsxImportSource @emotion/react */

import {
	Button, Col, Form, Row, Space, Spin, Typography,
} from 'antd';
import { useEffect, useState } from 'react';
import { useTheme } from '@emotion/react';
import Link from '../../app/Link';
import MessageTemplateCategoryTag from './MessageTemplateCategoryTag';
import { LocalDebug } from '../../../utils/LocalDebug';
import TemplateService from '../../../services/template';
import { useSelector } from 'react-redux';
import { getCompanySelected } from '../../../reducers/app';
import Box from '../../app/box/Box';
import { FaCopy, FaPlusCircle } from 'react-icons/fa';
import { useAuthContext } from '../../../hooks/useAuthContext';
import MessageTemplateUserAvatar from './MessageTemplateUserAvatar';
import MessageTemplateUserStub from './MessageTemplateUserStub';
import { findCategoryOption } from '../../../constants/email-templates';
import { documentId } from '../../../utils/common';

function MessageTemplatePicker(
	{
		setStep,
		masterTemplate,
		onReset,
		onTemplateSelected,
		setIsWithStartStep,
	},
) {
	const className = 'MessageTemplatePicker';

	const theme = useTheme();
	const companySelected = useSelector(getCompanySelected);
	const { currentUserModel } = useAuthContext();

	const blankTemplate = {
		_id: 'custom',
		name: <span style={{ fontSize: 14, fontWeight: 'bold' }}>
			<FaPlusCircle style={{ marginBottom: -2 }}/> New empty template
		</span>,
	};
	const blankTemplateSection = { data: [blankTemplate] };

	const [templates, setTemplates] = useState([]);
	const [selectedTemplate, setSelectedTemplate] = useState(masterTemplate);

	const fetchTemplates = async () => {
		const method = 'fetchTemplates';
		LocalDebug.logInfo({ className, method });
		const { data: { templates: masterTemplates } } = await TemplateService.findAll({ isMasterTemplate: true });
		let { data: { templates: companyTemplates } } = await TemplateService.findAll({
			companyId: documentId(companySelected), isMasterTemplate: false,
		});
		LocalDebug.logInfo({ className, method }, {
			masterTemplates, companyTemplates,
		});
		LocalDebug.logInfo({ className, method }, {
			masterTemplates: masterTemplates?.length, companyTemplates: companyTemplates?.length,
		});
		const userTemplates = companyTemplates.filter((t) => currentUserModel.isMe(t.user));
		companyTemplates = companyTemplates.filter((t) => !currentUserModel.isMe(t.user));

		setTemplates([
			blankTemplateSection,
			{ section: '50inTech templates', data: masterTemplates },
			{ section: 'Your personal templates', data: userTemplates },
			{ section: 'Your company templates', data: companyTemplates },
		]);
	};

	useEffect(() => {
		if (templates?.length === 1) {
			setStep(2);
		}
	}, [templates]);

	const handleSelectTemplate = (value) => {
		LocalDebug.logInfo({ className, method: 'handleSelectTemplate' }, value);
		setSelectedTemplate(value);
		onTemplateSelected({});
		setIsWithStartStep(false);
		if (documentId(value) === 'custom') {
			onTemplateSelected({});
			setIsWithStartStep(true);
			setStep(2);
		} else if (documentId(value)) {
			const {
				id: masterTemplateId, name, category, subject, content,
			} = value;
			onTemplateSelected({
				masterTemplateId, name, category, subject, content,
			});
			setIsWithStartStep(true);
		}
		// }
	};

	useEffect(async () => {
		if (selectedTemplate) handleSelectTemplate(selectedTemplate);
		await fetchTemplates();
	}, []);

	const previewInputStyle = {
		border: `1px solid ${theme.color.lightgrey}`,
		padding: 10,
		width: '100%',
		borderRadius: 5,
		background: theme.color.bgGrey,
	};

	if (templates.length === 0) {
		return <div style={{
			display: 'flex',
			flexDirection: 'column',
			height: '100%',
			minHeight: '60vh',
			justifyContent: 'center',
			alignItems: 'center',
			position: 'relative',
			overflow: 'hidden',
		}}>
			<Spin size='large'/>
		</div>;
	}

	return (
		<div style={{
			display: 'flex',
			flexDirection: 'column',
			height: '100%',
			position: 'relative',
			overflow: 'hidden',
			wordBreak: 'break-word',
		}}>
			<>
				<p>
					You can <b>duplicate</b> an existing template to customize it as your own,
					or start with an <b>empty</b> template.
				</p>
			</>

			<div
				// direction="vertical"
				style={{
					position: 'relative',
					width: '100%',
					height: 500,
					overflow: 'hidden',
					borderRadius: 5,
				}}
			>
				<div
					style={{
						position: 'relative',
						display: 'flex',
						alignItems: 'flex-start',
						gap: 0,
						width: '100%',
						height: 'calc(100% - 70px)',
						overflow: 'auto',
						border: '1px solid #ddd',
						borderRadius: 5,
						marginBottom: 20,
					}}
				>
					<Space
						direction="vertical"
						size={0}
						style={{
							width: 'calc(100% - 600pxx)',
							height: 'calc(100% + 2px)',
							overflowX: 'hidden',
							overflowY: 'auto',
							paddingRight: 0,
							borderRight: '1px solid #ddd',
							margin: '-2px 0 -2px',
						}}
					>
						{templates
							.filter((temp) => temp.data.length > 0)
							.map((template, i) => (
								<Space
									key={i}
									direction="vertical"
									style={{ width: '100%' }}
									size={0}
								>
									{template.section
										&& <div
											style={{
												width: '100%',
												padding: '10px 20px',
												background: theme.color.bgGrey,
												fontSize: 14,
												fontWeight: 'bold',
												color: theme.color.darkgrey,
												borderTop: '1px solid #ddd',
												borderBottom: '1px solid #ddd',
												marginBottom: -1,
											}}
										>
											{template.section}
										</div>
									}
									{template.data.map((value, j) => (
										documentId(value) === 'custom'
											? (
												<Button
													key={j}
													type='primary'
													ghost
													disabled={documentId(selectedTemplate)?.toString?.() === documentId(value)?.toString?.()}
													onClick={() => handleSelectTemplate(value)}
													style={{
														display: 'block',
														width: 'calc(100% - 16px)',
														padding: '10px 20px',
														height: 'auto',
														textAlign: 'left',
														whiteSpace: 'normal',
														borderRadius: 5,
														margin: 8,
													}}
												>
													{value.name}
												</Button>
											)
											: <Button
												key={j}
												disabled={documentId(selectedTemplate)?.toString?.() === documentId(value)?.toString?.()}
												onClick={() => handleSelectTemplate(value)}
												style={{
													display: 'block',
													borderRadius: 0,
													border: '1px solid transparent',
													// borderLeft: 'none',
													// borderRight: 'none',
													borderTop: '1px solid #ddd !important',
													borderBottom: '1px solid #ddd !important',
													width: '100%',
													padding: '10px 20px',
													margin: '0 0 0px 0',
													height: 'auto',
													textAlign: 'left',
													whiteSpace: 'normal',
												}}
											>

												<Space
													direction='horizontal'
													size={8}
													style={{
														lineHeight: 1.3,
													}}
												>
													<MessageTemplateUserAvatar template={value}/>
													<Space direction='vertical' size={2} style={{}}>
														<MessageTemplateCategoryTag
															category={value.category}
															withIcon={false}/>
														<span
															style={{ fontSize: 12, fontWeight: 'bold' }}>{value.name}</span>
													</Space>
												</Space>
											</Button>
									))}
								</Space>
							))}
					</Space>
					<div
						direction="vertical"
						style={{
							position: 'relative',
							overflow: 'auto',
							width: 600,
							height: '100%',
						}}
						size={0}
						className="preview"
					>
						{selectedTemplate
							? (
								<div style={{ padding: '30px 20px' }}>
									<Typography.Title level={3}>Template Preview</Typography.Title>
									<Space direction="vertical" style={{ width: '100%' }}>

										{documentId(selectedTemplate) === 'custom' && <p>No preview available</p>}
										{selectedTemplate && documentId(selectedTemplate) !== 'custom' && (
											<>
												<div style={{ width: '100%' }}>
													<Form labelCol={{ span: 24 }}>
														<Form.Item
															label={<b>Template name</b>}
															style={{ marginBottom: 5 }}
														>
															<p style={previewInputStyle}>
																{selectedTemplate.name}
															</p>
														</Form.Item>
														{findCategoryOption(selectedTemplate.category)
															&& <Form.Item
																label={<b>Category</b>}
																style={{ marginBottom: 5 }}
															>
																<p style={previewInputStyle}>
																	{findCategoryOption(selectedTemplate.category)?.label}
																</p>
															</Form.Item>
														}
														<Form.Item
															label={<b>Email Subject</b>}
															style={{ marginBottom: 5 }}
														>
															<p style={previewInputStyle}>
																{selectedTemplate.subject}
															</p>
														</Form.Item>
														<Form.Item
															label={<b>Email body</b>}
															style={{ marginBottom: 5 }}
														>
															<p
																style={previewInputStyle}
																dangerouslySetInnerHTML={{ __html: selectedTemplate.content }}
															/>
														</Form.Item>
													</Form>
												</div>
												<Box style={{ border: 'none' }}>
													<Space direction='vertical'>
														<b>Created by</b>
														<Box>
															<MessageTemplateUserStub template={selectedTemplate}/>
														</Box>
													</Space>
												</Box>
											</>
										)}
									</Space>
								</div>
							)
							: (
								<div
									style={{
										padding: '20px 20px',
										height: '100%',
									}}
								>
									<div style={{
										width: 'calc(100% - 80px)',
										height: 'calc(100% - 390px)',
										border: '5px dashed #eee',
										borderRadius: 20,
										display: 'flex',
										flexDirection: 'column',
										justifyContent: 'center',
										alignItems: 'center',
										textAlign: 'center',
										padding: '60px 30px',
										// margin: 20,
									}}>
										<h3>Template Preview</h3>
										<p>
											Select a template on the left,<br/>
											or start from an&nbsp;
											<Link onClick={() => handleSelectTemplate(blankTemplate)}>
												empty template
											</Link>
										</p>
									</div>
								</div>
							)
						}
					</div>
				</div>

				<Row style={{
					position: 'absolute',
					bottom: '0',
					margin: '48px -24px -24px',
					width: 'calc(100% + 48px)',
					borderTop: '1px solid #eee',
					padding: 24,
					background: theme.color.bgGrey,
				}}>
					<Col span={24}>
						<div
							style={{
								display: 'flex',
								justifyContent: 'flex-end',
								margin: -5,
								width: 'calc(100% + 10px)',
							}}
						>
							<Button
								type="primary"
								disabled={!selectedTemplate}
								onClick={() => setStep(1)}
								style={{
									margin: 5,
									fontWeight: 'bold',
									borderRadius: 5,
									height: 42,
								}}
							>
								<FaCopy style={{ marginBottom: -2 }}/>
								&nbsp;Duplicate and customize
							</Button>
						</div>
					</Col>
				</Row>
			</div>
		</div>
	);
}

export default MessageTemplatePicker;
