import { message, Space, Tooltip } from 'antd';
import theme from '../../theme';
import { localDebug } from '../../utils/LocalDebug';
import SourcingATSOpenSmallButton from './buttons/SourcingATSOpenSmallButton';
import { useDispatch } from 'react-redux';
import SourcingAtsSender from './buttons/SourcingAtsSender';
import { shouldRefreshData } from '../../actions/app';
import { pushSourcingToATS, syncSourcingFromATS } from '../../actions/sourcings';
import { FaExclamationTriangle } from 'react-icons/fa';
import SourcingATSSyncSmallButton from './buttons/SourcingATSSyncSmallButton';
import { useAuthContext } from '../../hooks/useAuthContext';
import SourcingModel from '../../models/SourcingModel';
import { documentId } from '../../utils/common';

const SourcingATSHandlerCell = (
	{
		sourcing,
		sendAction = pushSourcingToATS,
		syncAction = syncSourcingFromATS,
	},
) => {
	const dispatch = useDispatch();
	const { isAdmin, isStaffView } = useAuthContext();

	const sourcingModel = new SourcingModel(sourcing);

	const handleAtsSync = async (s) => {
		localDebug('handleAtsSync', { sourcing: documentId(s) });
		try {
			const { sourcing: updatedSourcing } = await dispatch(syncAction(s));
			dispatch(shouldRefreshData());
		} catch (e) {
			message.error(e.message);
		}
	};

	const handleAtsSend = async (s) => {
		localDebug('handleAtsSend', { sourcing: documentId(s) });
		try {
			const { sourcing: updatedSourcing } = await dispatch(sendAction(s));
			dispatch(shouldRefreshData());
		} catch (e) {
			message.error(e.message);
		}
	};

	let tooltip;
	if (!sourcingModel.hasJob) {
		tooltip = 'No job selected. Please add a job';
	} else if (!sourcingModel.isJobAtsLinked) {
		tooltip = 'Job not found in your ATS. Please select an ATS-linked job';
	}
	if (tooltip) {
		return <Tooltip title={tooltip}>
			<Space direction='horizontal' size={8} style={{
				border: '1px dashed #ddd',
				borderRadius: 4,
				padding: '6px 10px',
				fontSize: 10,
				color: theme.color.grey,
				lineHeight: 1,
			}}>
				<FaExclamationTriangle style={{ fontSize: 20, marginBottom: -2 }}/>
				<span>
					<i>ATS linkage<br/>unavailable</i>
				</span>
			</Space>
		</Tooltip>;
	}

	return <Space direction='horizontal'>
		{sourcingModel.isAtsLinked
			? <>
				<SourcingATSOpenSmallButton sourcing={sourcingModel}/>
				<SourcingATSSyncSmallButton sourcing={sourcingModel} onSync={handleAtsSync}/>
			</>
			: <>
				<SourcingAtsSender sourcing={sourcingModel} handleSend={handleAtsSend}/>
				{/* <SourcingATSPushSmallButton sourcing={sourcingModel} onPush={handleAtsSend} /> */}
			</>
		}
	</Space>;
};

export default SourcingATSHandlerCell;
