import { onEnterBuilder } from '../../../actions/app';
import FunnelsPage from '../../../containers/staff/data/FunnelsPage';
import { PERMS_GRANT_STAFF } from '../../menu.utils';

const menu = {
	name: 'staff-data-funnels',
	path: '/staff/data/funnels',
	label: 'Funnels',
	icon: <span>🔀</span>,
	component: FunnelsPage,
	onEnter: onEnterBuilder({
		origin: FunnelsPage.name,
		shouldLoadUserTags: true,
	}),
	perms: PERMS_GRANT_STAFF,
};

export default menu;
