/** @jsxImportSource @emotion/react */
import { useTheme } from '@emotion/react';
import { formatDelay, formatImage } from '../../../../../../utils/common';

import { FaMapMarkerAlt } from 'react-icons/fa';
import { Avatar } from 'antd';

const defaultClasses = (theme) => ({
	container: {},
	userAvatar: {
		width: '24px',
		minWidth: '24px',
		maxWidth: '24px',
		height: '24px',
		minHeight: '24px',
		maxHeight: '24px',
		marginRight: '6px',
	},
	avatar: {
		position: 'relative',
		top: ' 4px',
		left: '4px',
		marginRight: '10px',
		backgroundColor: 'rgba(0, 0, 0, 0.1)',
		backgroundSize: 'cover',
		backgroundPosition: 'center',
		backgroundRepeat: 'no-repeat',
		width: '100%',
		height: '100%',
		zIndex: 2,
		transition: 'all 0.2s ease-out',
		borderRadius: '100%',
	},
	userInfo: { width: '90%', marginLeft: '5px' },
	userName: {
		fontSize: '12px',
		fontWeight: 500,
		lineHeight: '150%',
		transition: 'color 0.2s ease-out',
	},
	userProfession: {
		fontWeight: 400,
		fontSize: 12,
		color: theme.color.doveGray,
	},
	userCreatedDelay: {},
	userLocation: {
		'fontSize': '10px',
		'lineHeight': 1.5,
		'textTransform': 'uppercase',
		'display': 'flex',
		'alignItems': 'center',
		'gap': 2,
		'color': theme.color.dustyGray,
		'.icon': {
			marginRight: ' 3px !important',
			fontSize: 10,
		},
	},
});

function AvatarCustom(props) {
	const theme = useTheme();

	const {
		avatar = 'assets/avatars/default-avatar.jpg',
		firstName,
		lastName,
		position,
		createdAt,
		location,
		classes,
		styles = {},
	} = props;
	const defaultClasse = defaultClasses(theme);
	const addingClasse = classes ? classes(theme) : {};
	const classNames = { ...defaultClasse, ...addingClasse };

	return (
		<div
			className="flexRow alignStart nowrap width100 avatar-container"
			css={{
				...classNames?.container,
				...(styles?.container && styles?.container),
				marginTop: 6,
			}}
		>
			<div className="relative user-avatar" css={classNames?.userAvatar}>
				<Avatar
					src={formatImage(avatar, {
						format: 'auto',
						quality: 'auto',
						flags: 'lossy',
						width: 100,
						crop: 'thumb',
						gravity: 'face',
					})}
				/>
			</div>
			<div
				className="flexColomn textLeft overflowHidden with-text-danger user-infos"
				css={classNames?.userInfo}
			>
				<div
					className="ellipsis user-name text-danger"
					css={{ ...classNames?.userName, ...styles?.userName }}
				>
					{firstName} {lastName}
				</div>
				{position && (
					<div className="ellipsis pointer" css={classNames?.userProfession}>
						{position}
					</div>
				)}
				{createdAt && (
					<div css={classNames?.userCreatedDelay}>{formatDelay(createdAt)}</div>
				)}
				{location && (
					<div css={classNames?.userLocation}>
						<FaMapMarkerAlt/>
						{location}
					</div>
				)}
			</div>
		</div>
	);
}

export default AvatarCustom;
