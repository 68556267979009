import { Divider, Typography } from 'antd';
import { FaChevronRight } from 'react-icons/fa';

const AdminToolsSectionContainer = (
	{
		title,
		Icon = FaChevronRight,
		children,
	},
) => {
	return (<>
		<Typography.Title level={3}>
			<Icon style={{ verticalAlign: 'middle', marginBottom: 2 }}/> {title}
		</Typography.Title>

		{children}

		{/* <Divider/> */}
	</>);
};

export default AdminToolsSectionContainer;
