import { isStringTypeValidator } from '../../../utils/isStringTypeValidator';
import { innerObjPropExtractor } from '../../property/property-extractor';
import { innerObjPropMapper } from '../../property/property-mapper';

const MENTOR_AVAILABILITY_KEY = 'mentor-availability';

const MENTOR_AVAILABILITY_ANSWER_1HOUR_MONTHLY = 'one-hour-per-month';
const MENTOR_AVAILABILITY_ANSWER_4HOUR_MONTHLY = 'four-hours-per-month';
const MENTOR_AVAILABILITY_ANSWER_8HOUR_MONTHLY = 'eight-hours-per-month';

const MENTOR_AVAILABILITY_OPTIONS = [
	{ value: MENTOR_AVAILABILITY_ANSWER_1HOUR_MONTHLY, label: '1h/mo' },
	{ value: MENTOR_AVAILABILITY_ANSWER_4HOUR_MONTHLY, label: '4h/mo' },
	{ value: MENTOR_AVAILABILITY_ANSWER_8HOUR_MONTHLY, label: '8h/mo' },
];

const customValidator = (value) => MENTOR_AVAILABILITY_OPTIONS.map((o) => o.value).includes(value);

const MENTOR_AVAILABILITY_VALIDATORS = [
	isStringTypeValidator,
	customValidator,
];

const USER_PROPERTY_MENTOR_AVAILABILITY_FIELD = 'mentor.mentorAvailability';
const USER_PROPERTY_MENTOR_AVAILABILITY_MAPPER = innerObjPropMapper(USER_PROPERTY_MENTOR_AVAILABILITY_FIELD);
const USER_PROPERTY_MENTOR_AVAILABILITY_EXTRACTOR = innerObjPropExtractor(USER_PROPERTY_MENTOR_AVAILABILITY_FIELD);

export default {
	MENTOR_AVAILABILITY_ANSWER_1HOUR_MONTHLY,
	MENTOR_AVAILABILITY_ANSWER_4HOUR_MONTHLY,
	MENTOR_AVAILABILITY_ANSWER_8HOUR_MONTHLY,
	key: MENTOR_AVAILABILITY_KEY,
	MENTOR_AVAILABILITY_KEY,
	validators: MENTOR_AVAILABILITY_VALIDATORS,
	MENTOR_AVAILABILITY_VALIDATORS,
	options: MENTOR_AVAILABILITY_OPTIONS,
	MENTOR_AVAILABILITY_OPTIONS,
	mapper: USER_PROPERTY_MENTOR_AVAILABILITY_MAPPER,
	USER_PROPERTY_MENTOR_AVAILABILITY_MAPPER,
	extractor: USER_PROPERTY_MENTOR_AVAILABILITY_EXTRACTOR,
	USER_PROPERTY_MENTOR_AVAILABILITY_EXTRACTOR,
	field: USER_PROPERTY_MENTOR_AVAILABILITY_FIELD,
	USER_PROPERTY_MENTOR_AVAILABILITY_FIELD,
};
