export const WELCOME_FLOW_VERSION_V1 = 'v1';
export const WELCOME_FLOW_VERSIONS = [
	WELCOME_FLOW_VERSION_V1,
];
export const DEFAULT_VERSION = WELCOME_FLOW_VERSION_V1;

export const WELCOME_FLOW_STEP_D0 = 'WELCOME_FLOW_STEP_D0';
export const WELCOME_FLOW_STEP_D1 = 'WELCOME_FLOW_STEP_D1';
export const WELCOME_FLOW_STEP_D2 = 'WELCOME_FLOW_STEP_D2';
export const WELCOME_FLOW_STEP_D3 = 'WELCOME_FLOW_STEP_D3';
export const WELCOME_FLOW_STEP_D4 = 'WELCOME_FLOW_STEP_D4';
export const WELCOME_FLOW_STEP_D5 = 'WELCOME_FLOW_STEP_D5';

export const WELCOME_FLOW_STEPS = {
	[DEFAULT_VERSION]: [
		WELCOME_FLOW_STEP_D0,
		WELCOME_FLOW_STEP_D1,
		WELCOME_FLOW_STEP_D2,
		WELCOME_FLOW_STEP_D3,
		WELCOME_FLOW_STEP_D4,
		WELCOME_FLOW_STEP_D5,
	],
};
