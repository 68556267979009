import { Button, Space, Tooltip } from 'antd';
import { useContext } from 'react';
import { useTheme } from '@emotion/react';
import { FaExclamationTriangle } from 'react-icons/fa';
import { openExternalUrl } from '../../../../utils/common';
import CompanyModel from '../../../../models/CompanyModel';
import { CompanyPageContext } from './CompanyPageProvider';
import { ENGLISH_LANG } from '../../../../constants/constant';
import { EyeOutlined } from '@ant-design/icons';

const CompanyPageViewLiveButton = (
	{
		company,
		forceLang = ENGLISH_LANG,
		...props
	},
) => {
	const className = 'CompanyPageViewLiveButton';

	const theme = useTheme();

	const companyModel = new CompanyModel(company);

	const { langTexts, editionState } = useContext(CompanyPageContext);

	return (
		<Tooltip
			placement='bottom'
			title={(
				<Space
					direction='vertical'
					style={{ lineHeight: 1.3 }}
				>
					Opens the public version of the company page
					{!companyModel?.isPublishedNow() && (
						<span style={{ fontSize: 12 }}>
							<FaExclamationTriangle />&nbsp;
							<i><b>Company is not yet published,
								you will need to be logged to see the page.</b></i>
						</span>
					)}
				</Space>
			)}
		>
			<Space size={12}>
				<Button
					onClick={() => openExternalUrl(`${companyModel?.getAppUri()}?forceLang=${forceLang}`)}
					style={{
						borderRadius: 5,
						minWidth: 120,
					}}
				>
					<b>View live</b>
					&nbsp;
					<EyeOutlined />
				</Button>
			</Space>
		</Tooltip>
	);
};

export default CompanyPageViewLiveButton;
