import React, { useState } from 'react';
import {
	Button, Row, Col, Divider, Form, Card,
} from 'antd';
import { useTheme } from '@emotion/react';
import FormItem from '../app/form/FormItem';
import JobSelect from '../jobs/JobSelect';
import UserStub from '../user/stub/UserStub';
import { useSelector } from 'react-redux';
import { getCompanies, getCompanySelected } from '../../reducers/app';
import FormCompanySelect from '../app/form/FormCompanySelect';
import { useAuthContext } from '../../hooks/useAuthContext';
import { TAG_STAFF } from '../../constants/constant';
import { documentId, requiredRule } from '../../utils/common';

const SourcingJobForm = (
	{
		initialValues = {},
		withFormHistoryItems = true,
		onSubmit,
		onReset,
	},
) => {
	const theme = useTheme();

	const { isStaffView } = useAuthContext();
	const companies = useSelector(getCompanies);
	const companySelected = useSelector(getCompanySelected);

	const defaultCandidate = initialValues?.user;

	const [companyId, setCompanyId] = useState(documentId(companySelected) || initialValues?.companyId);
	const [jobId, setJobId] = useState();

	const [form] = Form.useForm();

	const handleSubmit = (values) => {
		const data = {
			id: documentId(initialValues) || null,
			...values,
		};
		onSubmit(data);
		form.resetFields();
	};

	const handleChangeJob = (value, option) => {
		setJobId(value?.toString());
	};

	// const handleChangeCompany = (value, option) => {
	// 	if ()
	// 	setJobId();
	// }

	return (
		<Form
			form={form}
			name="user-filter-form"
			initialValues={{ ...initialValues }}
			onFinish={handleSubmit}
			labelCol={{ span: 11 }}
			labelAlign='right'
		>
			<Row gutter={24}>
				<Col span={24}>
					{/* <FormItemContainer> */}

					<FormItem
						label="Talent"
						name="userId"
						defaultContainer="div"
						rules={[requiredRule]}
					>
						<Card size="small" style={{ borderRadius: 4, width: '100%' }}>
							<UserStub user={defaultCandidate} withDropdown={false}/>
						</Card>
					</FormItem>

					<FormItem
						label="Job"
						defaultContainer="div"
						name="jobId"
						rules={[requiredRule]}
					>
						<JobSelect
							defaultOptions={initialValues?.job ? [initialValues?.job] : []}
							value={jobId}
							// defaultValue={jobId}
							placeholder="Select a job"
							onChange={handleChangeJob}
							// style={{ width: '100%' }}
							disabled={!companyId}
							companyIds={[companyId]}
							notJobIds={initialValues?.notJobIds}
						/>
					</FormItem>

					{isStaffView && !companySelected
						&& <FormItem label={<>Company {TAG_STAFF}</>} name="companyId">
							<FormCompanySelect
								options={companies.map((c) => ({ value: documentId(c), label: c.name, item: c }))}
								defaultValue={companyId}
								mode="single"
								onChange={(value) => setCompanyId(value)}
							/>
						</FormItem>}

					{/* </FormItemContainer> */}
				</Col>
			</Row>

			<Divider/>

			<Row>
				<Col span={24}>
					<div style={{
						display: 'flex',
						justifyContent: 'space-between',
						margin: -5,
						width: 'calc(100% + 10px)',
					}}>
						<Button type="default" style={{
							margin: 5,
							borderColor: theme.color.orange,
							fontWeight: 'bold',
							borderRadius: 5,
							color: theme.color.orange,
						}}
						htmlType="reset"
						onClick={onReset}
						>
							Cancel
						</Button>
						<Button type="primary"
							style={{
								margin: 5,
								background: theme.color.blue,
								borderColor: theme.color.blue,
								fontWeight: 'bold',
								borderRadius: 5,
							}}
							htmlType="submit"
						>
							Add
						</Button>
					</div>
				</Col>
			</Row>
		</Form>
	);
};

export default SourcingJobForm;
