import {
	message, Space, Switch, Tooltip,
} from 'antd';
import { useAuthContext } from '../../hooks/useAuthContext';
import { TAG_STAFF } from '../../constants/constant';
import { useEffect, useState } from 'react';

const StaffClientSwitch = (
	{
		label,
		tooltip,
		initialValue,
		setter,
		...props
	},
) => {
	const { isTalent } = useAuthContext();

	const defaultStaffLabel = <div style={{ fontSize: 12, fontWeight: 'bold', width: 42 }}>STAFF</div>;
	const defaultClientLabel = <div style={{ fontSize: 12, fontWeight: 'bold', width: 42 }}>CLIENT</div>;
	const [staffLabel, setStaffLabel] = useState(defaultStaffLabel);
	const [clientLabel, setClientLabel] = useState(defaultClientLabel);
	const handleSwitch = (value) => {
		setter(value);
		message.info({
			key: 'StaffClientSwitch',
			duration: 1,
			content: value
				? <><b>{label || 'Display'}:</b> Switched to <b>STAFF</b> mode {TAG_STAFF}</>
				: <><b>{label || 'Display'}:</b> Switched to <b>CLIENT</b> mode</>,
		});
	};

	useEffect(() => {
		setStaffLabel(<div style={{ fontSize: 11, fontWeight: 'bold', width: 12 }}>S</div>);
		setClientLabel(<div style={{ fontSize: 11, fontWeight: 'bold', width: 12 }}>C</div>);
	}, []);

	if (!isTalent) return null;

	return (
		<Tooltip title={tooltip} placement='bottomLeft'>
			<Switch
				checked={initialValue}
				onChange={handleSwitch}
				checkedChildren={staffLabel}
				unCheckedChildren={clientLabel}
				size={props?.size}
			/>
		</Tooltip>
	);
};

export default StaffClientSwitch;
