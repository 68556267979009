import { Tabs } from 'antd';
import { useAuthContext } from '../../../hooks/useAuthContext';
import ProductJobLocations from '../../../components/staff/product/ProductJobLocations';
import Box from '../../../components/app/box/Box';

const ProductJobsPage = () => {
	const { isStaff } = useAuthContext();

	if (!isStaff) return null;

	return (
		<>
			<h1>💼 Jobs</h1>
			<Tabs>
				<Tabs.TabPane tab='Locations' key='locations'>
					<Box style={{ marginTop: -17, paddingTop: 10 }}>
						<ProductJobLocations/>
					</Box>
				</Tabs.TabPane>
			</Tabs>
		</>
	);
};

export default ProductJobsPage;
