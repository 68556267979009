import LOCATION_CITY from '../../property/location-city';
import { innerObjPropExtractor } from '../../property/property-extractor';
import { innerObjPropMapper } from '../../property/property-mapper';

const USER_PROPERTY_PROFILE_LOCATION_CITY_KEY = 'profile-location-city';

const USER_PROPERTY_PROFILE_LOCATION_CITY_FREE = true;

const USER_PROPERTY_PROFILE_LOCATION_CITY_FIELD = 'information.location.city';
const USER_PROPERTY_PROFILE_LOCATION_CITY_MAPPER = innerObjPropMapper(USER_PROPERTY_PROFILE_LOCATION_CITY_FIELD);
const USER_PROPERTY_PROFILE_LOCATION_CITY_EXTRACTOR = innerObjPropExtractor(USER_PROPERTY_PROFILE_LOCATION_CITY_FIELD);

export default {
	...LOCATION_CITY,
	key: USER_PROPERTY_PROFILE_LOCATION_CITY_KEY,
	USER_PROPERTY_PROFILE_LOCATION_CITY_KEY,
	free: USER_PROPERTY_PROFILE_LOCATION_CITY_FREE,
	USER_PROPERTY_PROFILE_LOCATION_CITY_FREE,
	mapper: USER_PROPERTY_PROFILE_LOCATION_CITY_MAPPER,
	USER_PROPERTY_PROFILE_LOCATION_CITY_MAPPER,
	extractor: USER_PROPERTY_PROFILE_LOCATION_CITY_EXTRACTOR,
	USER_PROPERTY_PROFILE_LOCATION_CITY_EXTRACTOR,
	field: USER_PROPERTY_PROFILE_LOCATION_CITY_FIELD,
	USER_PROPERTY_PROFILE_LOCATION_CITY_FIELD,
};
