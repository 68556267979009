import PageLayout from '../../components/app/layout/PageLayout';
import {
	Divider, Space, Tabs, Tooltip,
} from 'antd';
import * as adminActions from '../../actions/admin';
import { useDispatch, useSelector } from 'react-redux';
import { useTheme } from '@emotion/react';
import {
	FaBuilding, FaBullhorn, FaCog, FaCopy, FaDatabase,
	FaEnvelope, FaHeartbeat, FaLock, FaMailBulk,
	FaPeopleArrows, FaRedo, FaSync, FaUser, FaUserCheck,
} from 'react-icons/fa';
import config from '../../config/config';
import { useAuthContext } from '../../hooks/useAuthContext';
import AdminToolsSectionContainer from '../../components/staff/tools/AdminToolsSectionContainer';
import AdminToolsAtsSection from '../../components/staff/tools/AdminToolsAtsSection';
import AdminToolsWelcomeFlowSection from '../../components/staff/tools/AdminToolsWelcomeFlowSection';
import { buildConfirmBtn, buildConfirmBtnStyleLarge } from '../../utils/common';
import ReactJsonDebug from '../../components/app/debug/ReactJsonDebug';
import StaffCommandButton from '../../components/staff/tools/StaffCommandButton';
import {
	adminProdRequest, adminProdStaffRequest, apiCandidateRequest,
} from '../../services/api';
import { getCompanySelected } from '../../reducers/app';
import AdminCommandGetButton from '../../components/staff/tools/AdminCommandGetButton';
import AdminToolsTagsSection from '../../components/staff/tools/AdminToolsTagsSection';
import AdminToolsGeolocationSection from '../../components/staff/tools/AdminToolsGeolocationSection';
import { TAG_ADMIN_ICON, TAG_STAFF, TAG_STAFF_ICON } from '../../constants/constant';
import AdminToolsSavedSearchesSection from '../../components/staff/tools/AdminToolsSavedSearchesSection';
import AdminToolsPlansSection from '../../components/staff/tools/AdminToolsPlansSection';
import Apps1448Tools from '../../components/staff/tools/releases/Apps1448Release';
import UpdatePassword from '../tools/UpdatePassword';
import Box from '../../components/app/box/Box';
import AdminToolsUMSSection from '../../components/staff/tools/AdminToolsUMSSection';
import { ThunderboltOutlined } from '@ant-design/icons';

export const CopyRecruiterListButton = (
	{
		title = 'Copy recruiter list',
		Icon = FaCopy,
	},
) => (
	<StaffCommandButton
		title={title}
		Icon={Icon}
		dispatcher={adminActions.getRecruiterInfo}
	/>
);

export const ComputeFunnelsButton = (
	{
		title = 'Compute funnels',
		Icon = FaSync,
		style,
	},
) => (
	<StaffCommandButton
		title={title}
		Icon={Icon}
		dispatcher={adminActions.computeFunnels}
		style={style}
	/>
);

const StaffToolsPage = () => {
	const theme = useTheme();
	const dispatch = useDispatch();

	const companySelected = useSelector(getCompanySelected);

	const {
		isAdmin, isStaff, currentUser,
	} = useAuthContext();

	if (!currentUser) return null;

	return (
		<PageLayout
			withBackground={false}
			icon={<span>🧰</span>}
			title='Tools'
		>
			<Tabs>

				<Tabs.TabPane tab='Recruiters' key='recruiters'>
					<AdminToolsSectionContainer title='Recruiters' Icon={FaUser}>
						<Space direction='vertical'>
							<CopyRecruiterListButton />
							<div style={{ color: '#666', fontSize: 12 }}>
								This button will <b>copy</b> in your clipboard the list of all active recruiters (i.e.
								all people with an access to the SaaS - either Company Admin, Company Editor, or
								Recruiter).
								<br />You should then <b>paste</b> the content in this
								spreadsheet: <a target='_blank' rel='noreferrer' href='https://docs.google.com/spreadsheets/d/11P1VUM5MpR_ZragiE7efE6BPeCQeN0KVFS8DFku1VFA/edit#gid=1738279817'>Client
								Recruiter List</a> (and if necessary <b>delete</b> old content present in the
								spreadsheet!)
							</div>
						</Space>
					</AdminToolsSectionContainer>

					<AdminCommandGetButton
						title={'Add session & version info on UserRoles'}
						path='/admin/user/roles-session'
					/>

				</Tabs.TabPane>

				{(config.isNotProdEnv && isStaff) && (
					<Tabs.TabPane
						tab={<>Plans {TAG_STAFF_ICON}</>}
						key='plans'
					>
						<AdminToolsPlansSection />
					</Tabs.TabPane>
				)}

				{isAdmin && (
					<Tabs.TabPane
						tab={<>Releases {TAG_ADMIN_ICON}</>}
						key='releases'
					>
						<Apps1448Tools />
					</Tabs.TabPane>
				)}

				{isAdmin && (
					<Tabs.TabPane
						tab={<>UMS {TAG_ADMIN_ICON}</>}
						key='ums'
					>
						<AdminToolsUMSSection />
					</Tabs.TabPane>
				)}

				{isAdmin && (
 					<Tabs.TabPane
						tab={<>Saved searches {TAG_ADMIN_ICON}</>}
						key='saved-searches'
					>
						<AdminToolsSavedSearchesSection />
					</Tabs.TabPane>
				)}

				{isAdmin && (
					<Tabs.TabPane
						tab={
							<Tooltip
								title={'Job Sliders / Onboarding / Welcome Flow / Tags / Geolocation / Stats'}>
								Manage {TAG_ADMIN_ICON}
							</Tooltip>
						}
						key='manage'
					>

						<Space direction='vertical'>

							<AdminCommandGetButton
								title={'Remap GenderScore surveys answer to V3'}
								path='/admin/company/remap-genderscore-survey-answers-v3'
							/>

							<AdminToolsSectionContainer title='Candidate Job Sliders'>
								<AdminCommandGetButton
									api={apiCandidateRequest}
									path={'/dashboard/cron/fetchJobSlider'}
									title={'api.50intech.com/dashboard/cron/fetchJobSlider'}
								/>
							</AdminToolsSectionContainer>

							<Divider />

							<AdminToolsSectionContainer title='Cache'>
								<AdminCommandGetButton
									api={apiCandidateRequest}
									path={'/admin/manage/reset-cache'}
									title={'Clear Candidate API cache'}
								/>
							</AdminToolsSectionContainer>

							<Divider />

							<AdminToolsSectionContainer title='Onboarding'>
								<AdminCommandGetButton
									title='Initialize onboarding flows'
									api={apiCandidateRequest}
									path={'/admin/manage/initialize-onboarding'}
								/>
							</AdminToolsSectionContainer>

							<Divider />

							<AdminToolsWelcomeFlowSection />

							<Divider />

							<AdminToolsTagsSection />

							<Divider />

							<AdminToolsGeolocationSection />

							<Divider />

							<AdminToolsSectionContainer title='Cron' Icon={FaSync}>

								<Space direction='horizontal' size={4} wrap={true}>
									<AdminCommandGetButton
										title={'Cron: Compute GenderScore Monthly Stats'}
										path='/cron/computeGenderScoreMonthlyStats'
									/>
									<AdminCommandGetButton
										title={'Cron: Fetch ATS accesses'}
										path='/cron/fetchAccesses'
									/>
									<AdminCommandGetButton
										title={'Cron: Fetch pending jobs'}
										path='/cron/fetchPendingJobs'
									/>
									<AdminCommandGetButton
										title={'Cron: Sync all sourcings'}
										path='/cron/syncAllSourcings'
									/>
									<AdminCommandGetButton
										title={'Cron: Send Welcome Flow emails'}
										path='/cron/sendWelcomeFlowEmails'
										disabled={true}
									/>
									<AdminCommandGetButton
										title={'Cron: Update period stats'}
										path='/cron/updatePeriodStats'
									/>
									<AdminCommandGetButton
										title={'Cron: Apply user hidden companies'}
										path='/cron/applyUserHiddenCompanies'
									/>
									<AdminCommandGetButton
										title={'Cron: Compute saved searches alerts'}
										path='/cron/computeSavedSearchesAlerts'
									/>
									<AdminCommandGetButton
										title={'Cron: Compute (and send) saved searches digests'}
										path='/cron/computeSavedSearchesDigests'
									/>
									<AdminCommandGetButton
										title={'Cron: Compute (and NOT send) saved searches digests'}
										path='/cron/computeSavedSearchesDigests?dryRun=true'
									/>
									<AdminCommandGetButton
										title={'Cron: Compute (and send) saved searches digests - SKIP NO_SAVED_SEARCHES'}
										path='/cron/computeSavedSearchesDigests?skip=NO_SAVED_SEARCHES'
									/>
									<AdminCommandGetButton
										title={'Cron: Compute  (and NOT send) saved searches digests - SKIP NO_SAVED_SEARCHES'}
										path='/cron/computeSavedSearchesDigests?skip=NO_SAVED_SEARCHES&dryRun=true'
									/>
									<AdminCommandGetButton
										title={'Cron: Randomize jobs'}
										path='/cron/randomizeJobs'
									/>
									<AdminCommandGetButton
										title={'Cron: compute publication'}
										path='/cron/computePublication'
									/>
									<AdminCommandGetButton
										title={'Cron: Update users resume'}
										path='/cron/updateUsersResume'
										disabled={true}
									/>
									<AdminCommandGetButton
										title={'Cron: Compute funnels'}
										path='/cron/computeFunnels'
									/>
									<AdminCommandGetButton
										title={'Cron: Retry failed mailings'}
										path='/cron/retryFailedMailings'
										disabled={true}
									/>
									<AdminCommandGetButton
										title={'Cron: Refresh all UserMailings status'}
										path='/cron/refreshAllUserMailingsStatus'
										disabled={true}
									/>
									<AdminCommandGetButton
										title={'Cron: Refresh Mailjet contacts data'}
										path='/cron/refreshMailjetContactsData'
										disabled={true}
									/>
								</Space>
							</AdminToolsSectionContainer>

							<Divider />

							<AdminToolsSectionContainer title='Stats' Icon={FaDatabase}>

								<Space direction='horizontal' size={4} wrap={true}>
									{buildConfirmBtn({
										theme,
										title: 'Get lists',
										Icon: ThunderboltOutlined,
										dispatcher: adminActions.getPeriodStatsLists,
										dispatch,
									})}
									{buildConfirmBtn({
										theme,
										title: 'Get period stats',
										Icon: ThunderboltOutlined,
										dispatcher: adminActions.getPeriodStats,
										dispatch,
									})}
									{buildConfirmBtn({
										theme,
										title: 'Compute period stats',
										Icon: ThunderboltOutlined,
										dispatcher: adminActions.computePeriodStats,
										dispatch,
									})}
									{buildConfirmBtn({
										theme,
										title: 'Compute period stats (with storage bypass)',
										Icon: ThunderboltOutlined,
										dispatcher: adminActions.computePeriodStatsBypassStorage,
										dispatch,
										style: { ...buildConfirmBtnStyleLarge, color: theme.color.orange },
									})}
								</Space>
							</AdminToolsSectionContainer>
						</Space>

					</Tabs.TabPane>
				)}

				{isAdmin && (
					<Tabs.TabPane
						tab={<>Users {TAG_ADMIN_ICON}</>}
						key='users'
					>

						<Space direction='vertical'>
							<Space>

								<Box
									withShadow={true}
									style={{
										margin: '10px 0',
										width: 400,
										borderRadius: 6,
										padding: 20,
									}}
								>
									<h3 style={{ marginBottom: 12 }}>Modify a user's password</h3>
									<UpdatePassword
										withTitle={false}
										withEmail={true}
									/>
								</Box>

							</Space>

							<Divider />

							<AdminToolsSectionContainer title='Users' Icon={FaPeopleArrows}>
								<Space direction='vertical' size={4} wrap={true}>
									<Space direction='horizontal' size={4} wrap={true}>
										{buildConfirmBtn({
											theme,
											title: 'Update salary expectations',
											Icon: FaSync,
											dispatch,
											dispatcher: adminActions.updateUserSalaryExpectations,
										})}
									</Space>
								</Space>
							</AdminToolsSectionContainer>

							<Divider />

							<AdminCommandGetButton
								api={apiCandidateRequest}
								title={'Compute job sliders (limited to 10min)'}
								path={'/dashboard/cron/fetchJobSlider'}
							/>

							<Divider />

							<AdminToolsSectionContainer title='Hidden companies' Icon={FaBuilding}>
								<Space direction='vertical' size={4} wrap={true}>
									<Space direction='horizontal' size={4} wrap={true}>
										{buildConfirmBtn({
											theme,
											title: 'Hide company users',
											Icon: FaLock,
											dispatcher: adminActions.applyUserHiddenCompanies,
											dispatch,
										})}
									</Space>
								</Space>
							</AdminToolsSectionContainer>

						</Space>
					</Tabs.TabPane>
				)}

				{isAdmin && (
					<Tabs.TabPane
						tab={<>Companies & Jobs {TAG_ADMIN_ICON}</>}
						key='companies'
					>
						<Space style={{ marginBottom: 20 }}>
							<AdminCommandGetButton
								title={'Randomize jobs'}
								path='/admin/job/randomize-jobs'
							/>
							<AdminCommandGetButton
								title={'Cron compute publication (companies, jobs, articles)'}
								path={'/cron/computePublication'}
							/>
						</Space>
						<AdminToolsSectionContainer title='Job Publication' Icon={FaBullhorn}>
							<Space>
								<AdminCommandGetButton
									title='invalidate job publication'
									path={'/admin/company/invalidateShouldComputeJobPublication'}
								/>
								<AdminCommandGetButton
									title='Compute job publication'
									path={'/admin/company/computeJobPublication'}
								/>
							</Space>
						</AdminToolsSectionContainer>
					</Tabs.TabPane>
				)}

				{isAdmin && (
					<Tabs.TabPane
						tab={<>ATS {TAG_ADMIN_ICON}</>}
						key='ats'
					>
						<AdminToolsAtsSection />
					</Tabs.TabPane>
				)}

				{isAdmin && (
					<Tabs.TabPane
						tab={<>Emails {TAG_ADMIN_ICON}</>}
						key='emails'
					>
						<AdminToolsSectionContainer title='UserMailing' Icon={FaEnvelope}>
							<Space direction='horizontal'>
								{buildConfirmBtn({
									theme,
									title: 'Retry Failed UserMailings',
									Icon: FaRedo,
									dispatcher: adminActions.retryFailedUserMailings,
									dispatch,
								})}
								{buildConfirmBtn({
									theme,
									title: 'Refresh All UserMailings Status',
									Icon: FaMailBulk,
									dispatcher: adminActions.refreshAllUserMailingsStatus,
									dispatch,
								})}
								{buildConfirmBtn({
									theme,
									title: 'Refresh All Mailjet Contact Data',
									Icon: FaUserCheck,
									dispatcher: adminActions.refreshMailjetContactsData,
									dispatch,
								})}
							</Space>
						</AdminToolsSectionContainer>

						<Divider />

						<AdminToolsSectionContainer title='Mailjet' Icon={FaEnvelope}>
							<Space direction='horizontal'>
								{buildConfirmBtn({
									theme,
									title: 'Check Mailjet health (own)',
									Icon: FaHeartbeat,
									dispatcher: adminActions.checkMailjetHealth,
									dispatch,
								})}
								{config.isNotProdVersion && (
									<AdminCommandGetButton
										title='Check Mailjet health (admin.50intech.com API)'
										api={adminProdRequest}
										path={'/admin/mailjet/check-health'}
									/>
								)}
								{config.isNotProdStaffVersion && (
									<AdminCommandGetButton
										title='Check Mailjet health (admin-staff.50intech.com API)'
										api={adminProdStaffRequest}
										path={'/admin/mailjet/check-health'}
									/>
								)}
								<AdminCommandGetButton
									title='Check Mailjet health (api.50intech.com)'
									api={apiCandidateRequest}
									path={'/admin/mailjet/check-health'}
								/>
								<AdminCommandGetButton
									title='Get request info (and IP?)'
									api={apiCandidateRequest}
									path={'/admin/manage/ip'}
								/>
							</Space>
						</AdminToolsSectionContainer>
					</Tabs.TabPane>
				)}

				{isAdmin && (
					<Tabs.TabPane
						tab={<>Config {TAG_ADMIN_ICON}</>}
						key='config'
					>
						<AdminToolsSectionContainer title='process.env' Icon={FaCog}>
							<ReactJsonDebug src={process.env} />
						</AdminToolsSectionContainer>
						<AdminToolsSectionContainer title='Config' Icon={FaCog}>
							<ReactJsonDebug src={config} />
						</AdminToolsSectionContainer>
						<AdminToolsSectionContainer title='currentUser' Icon={FaCog}>
							<ReactJsonDebug src={currentUser} />
						</AdminToolsSectionContainer>
					</Tabs.TabPane>
				)}

			</Tabs>

		</PageLayout>
	);
};

export default StaffToolsPage;
