import { Space } from 'antd';
import { FaBan, FaCheck, FaTimes } from 'react-icons/fa';
import CompanySettingsCard, { CompanySettingsCardLineOk } from './CompanySettingsCard';
import { useTheme } from '@emotion/react';
import CompanySettingsBrowsingForm from './CompanySettingsBrowsingForm';
import BlockTester from '../../app/blocker/BlockTester';
import SettingsBrowsingUserStubPreview from '../../account/SettingsBrowsingUserStubPreview';
import CompanyModel from '../../../models/CompanyModel';
import { LocalDebug } from '../../../utils/LocalDebug';

const CompanySettingsBrowsingCard = (
	{
		company,
		...props
	},
) => {
	const className = 'CompanySettingsBrowsingCard';
	const theme = useTheme();

	if (!company) return null;
	LocalDebug.logInfo({ className, method: 'constructor' }, { company });
	const companyModel = new CompanyModel(company);

	return (
		<div style={{ position: 'relative' }}>
			<BlockTester withBorder={false} tagStyle={{ top: 2, left: 24, opacity: 1 }}>
				<CompanySettingsCard
					title={<>👀 Unbiased talent browsing</>}
					details={<>
						Reduce inconscious bias by navigating 50inTech's talent pool without showing the photos or
						names of candidates.
					</>}
					company={company}
					CompanySettingsForm={CompanySettingsBrowsingForm}
				>
					<Space direction='vertical'>

						<CompanySettingsCardLineOk
							ok={companyModel?.shouldAnonTalentAvatars}
							okText={<>
								Talent <b>avatars</b> are <b>hidden</b> to recruiters
								{companyModel?.canRecruiterOverrideAnonTalentAvatars
									? <>, but they <b>can modify</b> the setting</>
									: <>, and they <b>can't modify</b> the setting</>
								}
							</>}
							koText={<>
								Talent <b>avatars</b> are <b>visible</b> to recruiters
								{companyModel?.canRecruiterOverrideAnonTalentAvatars
									? <>, but they <b>can modify</b> the setting</>
									: <>, and they <b>can't modify</b> the setting</>
								}
							</>}
						/>

						<CompanySettingsCardLineOk
							ok={companyModel?.shouldAnonTalentNames}
							okText={<>
								Talent <b>names</b> are <b>hidden</b> to recruiters
								{companyModel?.canRecruiterOverrideAnonTalentNames
									? <>, but they <b>can modify</b> the setting</>
									: <>, and they <b>can't modify</b> the setting</>
								}
							</>}
							koText={<>
								Talent <b>names</b> are <b>visible</b> to recruiters
								{companyModel?.canRecruiterOverrideAnonTalentNames
									? <>, but they <b>can modify</b> the setting</>
									: <>, and they <b>can't modify</b> the setting</>
								}
							</>}
						/>

						<SettingsBrowsingUserStubPreview
							style={{ marginBottom: 0 }}
							showAvatar={!companyModel?.shouldAnonTalentAvatars}
							showName={!companyModel?.shouldAnonTalentNames}
						/>

					</Space>

				</CompanySettingsCard>

			</BlockTester>
		</div>
	);
};

export default CompanySettingsBrowsingCard;
