const { default: DEI_CRITERIA_GROUP } = require('./dei-criteria-group');
const { isStringTypeValidator } = require('../../utils/isStringTypeValidator');

const DEI_CRITERIA_KEY = 'dei-criteria';

// ## Career Growth
// ## Salary Policies
// ## Work-Life Balance
// ## DE&I actions

const DEI_CRITERIA_ANSWER_MENTORING_COACHING_PROGRAMS = 'mentoring-coaching-programs';
const DEI_CRITERIA_ANSWER_MENTORING_COACHING_PROGRAMS_LABEL = 'Mentoring/coaching programs';
const DEI_CRITERIA_ANSWER_MENTORING_PERSONAL_UPSKILLING_PROGRAMS = 'personal-upskilling-programs';
const DEI_CRITERIA_ANSWER_MENTORING_PERSONAL_UPSKILLING_PROGRAMS_LABEL = 'Personal upskilling programs';
const DEI_CRITERIA_ANSWER_MENTORING_RETURN_TO_WORK_PROGRAMS = 'return-to-work-programs';
const DEI_CRITERIA_ANSWER_MENTORING_RETURN_TO_WORK_PROGRAMS_LABEL = 'Return to work programs';
const DEI_CRITERIA_ANSWER_MENTORING_JUNIOR_ENTRY_LEVEL_OPENNESS = 'junior-entry-level-openness';
const DEI_CRITERIA_ANSWER_MENTORING_JUNIOR_ENTRY_LEVEL_OPENNESS_LABEL = 'Open to Junior/entry-level candidates';
const DEI_CRITERIA_ANSWER_MENTORING_CAREER_CHANGE_OPENNESS = 'career-change-openness';
const DEI_CRITERIA_ANSWER_MENTORING_CAREER_CHANGE_OPENNESS_LABEL = 'Open to career change candidates';

const DEI_CRITERIA_ANSWER_EQUAL_PAY = 'equal-pay';
const DEI_CRITERIA_ANSWER_EQUAL_PAY_LABEL = 'Equal pay';
const DEI_CRITERIA_ANSWER_TRANSPARENT_PAYGRID = 'transparent-paygrid';
const DEI_CRITERIA_ANSWER_TRANSPARENT_PAYGRID_LABEL = 'Transparent paygrid';
const DEI_CRITERIA_ANSWER_EMPLOYEE_EQUITY = 'employee-equity';
const DEI_CRITERIA_ANSWER_EMPLOYEE_EQUITY_LABEL = 'Employee equity';
const DEI_CRITERIA_ANSWER_MATERNITY_SALARY_MAINTENANCE = 'maternity-salary-maintenance';
const DEI_CRITERIA_ANSWER_MATERNITY_SALARY_MAINTENANCE_LABEL = 'Maintenance of salary during maternity leave';

const DEI_CRITERIA_ANSWER_REMOTE_HYBRID = 'remote-hybrid';
const DEI_CRITERIA_ANSWER_REMOTE_HYBRID_LABEL = 'Remote/Hybrid';
const DEI_CRITERIA_ANSWER_FLEXIBLE_WORK_SCHEDULE = 'flexible-work-schedule';
const DEI_CRITERIA_ANSWER_FLEXIBLE_WORK_SCHEDULE_LABEL = 'Flexible work schedule';
const DEI_CRITERIA_ANSWER_FLEXIBLE_HOLIDAYS = 'flexible-holidays';
const DEI_CRITERIA_ANSWER_FLEXIBLE_HOLIDAYS_LABEL = 'Flexible holidays';
const DEI_CRITERIA_ANSWER_INSURANCE_POLICIES = 'insurance-policies';
const DEI_CRITERIA_ANSWER_INSURANCE_POLICIES_LABEL = 'Insurance policies';
const DEI_CRITERIA_ANSWER_EXTRA_PARENTAL_LEAVE = 'extra-parental-leave';
const DEI_CRITERIA_ANSWER_EXTRA_PARENTAL_LEAVE_LABEL = 'Extra Parental Leave';

const DEI_CRITERIA_ANSWER_WORKPLACE_CONFLICTS_POLICY = 'workplace-conflicts-policy';
const DEI_CRITERIA_ANSWER_WORKPLACE_CONFLICTS_POLICY_LABEL = 'Workplace conflicts policy';
const DEI_CRITERIA_ANSWER_ANTI_DISCRIMINATION_POLICY = 'anti-discrimination-policy';
const DEI_CRITERIA_ANSWER_ANTI_DISCRIMINATION_POLICY_LABEL = 'Anti-discrimination policy';
const DEI_CRITERIA_ANSWER_DEI_POLICY = 'dei-policy';
const DEI_CRITERIA_ANSWER_DEI_POLICY_LABEL = 'Diversity, Equity and Inclusion Policy';
const DEI_CRITERIA_ANSWER_EMPLOYEE_RESOURCE_GROUPS = 'employee-resource-groups';
const DEI_CRITERIA_ANSWER_EMPLOYEE_RESOURCE_GROUPS_LABEL = 'Employee Resource Groups';
const DEI_CRITERIA_ANSWER_MENTAL_HEALTH_INITIATIVES = 'mental-health-initiatives';
const DEI_CRITERIA_ANSWER_MENTAL_HEALTH_INITIATIVES_LABEL = 'Mental health initiatives';

const DEI_CRITERIA_ANSWER_REMOTE_WORKING = 'remote-working';
const DEI_CRITERIA_ANSWER_FLEXIBLE_HOURS = 'flexible-hours';
const DEI_CRITERIA_ANSWER_FLEXIBLE_HOURS_REMOTE = 'flexible-hours-remote';
const DEI_CRITERIA_ANSWER_OPEN_HOLIDAYS = 'open-holidays';
const DEI_CRITERIA_ANSWER_EXTRA_MATERNITY_LEAVE = 'extra-maternity-leave';
// const DEI_CRITERIA_ANSWER_EXTRA_PARENTAL_LEAVE = 'extra-parental-leave';
const DEI_CRITERIA_ANSWER_PARENTAL_LEAVE = 'parental-leave';
const DEI_CRITERIA_ANSWER_GENDER_INDEX = 'gender-index';
// const DEI_CRITERIA_ANSWER_TRANSPARENT_PAYGRID = 'transparent-paygrid';
const DEI_CRITERIA_ANSWER_MATERNITY_RAISE = 'maternity-raise';
const DEI_CRITERIA_ANSWER_STOCK_OPTIONS = 'stock-options';
// const DEI_CRITERIA_ANSWER_EQUAL_PAY = 'equal-pay';
const DEI_CRITERIA_ANSWER_CLEAR_CAREER_PATH = 'clear-career-path';
const DEI_CRITERIA_ANSWER_FAIR_CAREER_PATH = 'fair-career-path';
const DEI_CRITERIA_ANSWER_PARITY_COMPENSATION = 'parity-compensation';
const DEI_CRITERIA_ANSWER_NOREFERRAL_NOCULTUREFIT = 'noreferral-noculturefit';
const DEI_CRITERIA_ANSWER_DEVELOPMENT_PROGRAM = 'development-program';
const DEI_CRITERIA_ANSWER_MENTORING_COACHING = 'mentoring-coaching';
const DEI_CRITERIA_ANSWER_ANTI_HARASSMENT_TRAINING = 'anti-harassment-training';
const DEI_CRITERIA_ANSWER_UNBIASED_RECRUITMENT = 'unbiased-recruitment';
const DEI_CRITERIA_ANSWER_UNBIASED_RECRUITMENT_LABEL = 'Unbiased recruitment';
const DEI_CRITERIA_ANSWER_UNCONSCIOUS_BIAS_TRAINING = 'unconsicous-bias-training';
const DEI_CRITERIA_ANSWER_DEI_COMMITTEE = 'dei-committee';
const DEI_CRITERIA_ANSWER_BCORP_CERTIFICATION = 'bcorp-certification';

const DEI_CRITERIA_OPTIONS = [
	{ group: DEI_CRITERIA_GROUP.DEI_CRITERIA_GROUP_WORKLIFE_BALANCE, value: DEI_CRITERIA_ANSWER_REMOTE_WORKING },
	{ group: DEI_CRITERIA_GROUP.DEI_CRITERIA_GROUP_WORKLIFE_BALANCE, value: DEI_CRITERIA_ANSWER_FLEXIBLE_HOURS },
	// { group: DEI_CRITERIA_GROUP.DEI_CRITERIA_GROUP_WORKLIFE_BALANCE, value: DEI_CRITERIA_ANSWER_OPEN_HOLIDAYS },
	{ group: DEI_CRITERIA_GROUP.DEI_CRITERIA_GROUP_WORKLIFE_BALANCE, value: DEI_CRITERIA_ANSWER_EXTRA_MATERNITY_LEAVE },
	{ group: DEI_CRITERIA_GROUP.DEI_CRITERIA_GROUP_WORKLIFE_BALANCE, value: DEI_CRITERIA_ANSWER_PARENTAL_LEAVE },

	{ group: DEI_CRITERIA_GROUP.DEI_CRITERIA_GROUP_EQUALPAY, value: DEI_CRITERIA_ANSWER_GENDER_INDEX },
	{ group: DEI_CRITERIA_GROUP.DEI_CRITERIA_GROUP_EQUALPAY, value: DEI_CRITERIA_ANSWER_TRANSPARENT_PAYGRID },
	{ group: DEI_CRITERIA_GROUP.DEI_CRITERIA_GROUP_EQUALPAY, value: DEI_CRITERIA_ANSWER_MATERNITY_RAISE },
	// { group: DEI_CRITERIA_GROUP.DEI_CRITERIA_GROUP_EQUALPAY, value: DEI_CRITERIA_ANSWER_STOCK_OPTIONS },
	{ group: DEI_CRITERIA_GROUP.DEI_CRITERIA_GROUP_EQUALPAY, value: DEI_CRITERIA_ANSWER_EQUAL_PAY },

	{ group: DEI_CRITERIA_GROUP.DEI_CRITERIA_GROUP_FAIR_CAREER_PATH, value: DEI_CRITERIA_ANSWER_CLEAR_CAREER_PATH },
	{ group: DEI_CRITERIA_GROUP.DEI_CRITERIA_GROUP_FAIR_CAREER_PATH, value: DEI_CRITERIA_ANSWER_PARITY_COMPENSATION },
	{
		group: DEI_CRITERIA_GROUP.DEI_CRITERIA_GROUP_FAIR_CAREER_PATH,
		value: DEI_CRITERIA_ANSWER_NOREFERRAL_NOCULTUREFIT,
	},
	{ group: DEI_CRITERIA_GROUP.DEI_CRITERIA_GROUP_FAIR_CAREER_PATH, value: DEI_CRITERIA_ANSWER_DEVELOPMENT_PROGRAM },
	{ group: DEI_CRITERIA_GROUP.DEI_CRITERIA_GROUP_FAIR_CAREER_PATH, value: DEI_CRITERIA_ANSWER_MENTORING_COACHING },

	{ group: DEI_CRITERIA_GROUP.DEI_CRITERIA_GROUP_DEI_POLICIES, value: DEI_CRITERIA_ANSWER_ANTI_HARASSMENT_TRAINING },
	{ group: DEI_CRITERIA_GROUP.DEI_CRITERIA_GROUP_DEI_POLICIES, value: DEI_CRITERIA_ANSWER_UNBIASED_RECRUITMENT },
	{ group: DEI_CRITERIA_GROUP.DEI_CRITERIA_GROUP_DEI_POLICIES, value: DEI_CRITERIA_ANSWER_UNCONSCIOUS_BIAS_TRAINING },
	{ group: DEI_CRITERIA_GROUP.DEI_CRITERIA_GROUP_DEI_POLICIES, value: DEI_CRITERIA_ANSWER_DEI_COMMITTEE },
	{ group: DEI_CRITERIA_GROUP.DEI_CRITERIA_GROUP_DEI_POLICIES, value: DEI_CRITERIA_ANSWER_BCORP_CERTIFICATION },
];

const DEI_CRITERIA_OPTIONS_SIMPLIFIED = [
	{
		group: DEI_CRITERIA_GROUP.DEI_CRITERIA_GROUP_WORKLIFE_BALANCE,
		value: DEI_CRITERIA_ANSWER_FLEXIBLE_HOURS_REMOTE,
		label: 'Flexible hours & remote',
	},
	// { group: DEI_CRITERIA_GROUP.DEI_CRITERIA_GROUP_WORKLIFE_BALANCE, value: DEI_CRITERIA_ANSWER_OPEN_HOLIDAYS },
	{
		group: DEI_CRITERIA_GROUP.DEI_CRITERIA_GROUP_WORKLIFE_BALANCE,
		value: DEI_CRITERIA_ANSWER_EXTRA_PARENTAL_LEAVE,
		label: 'Extra parental leave',
	},

	{
		group: DEI_CRITERIA_GROUP.DEI_CRITERIA_GROUP_EQUALPAY,
		value: DEI_CRITERIA_ANSWER_TRANSPARENT_PAYGRID,
		label: 'Transparent paygrid',
	},
	// { group: DEI_CRITERIA_GROUP.DEI_CRITERIA_GROUP_EQUALPAY, value: DEI_CRITERIA_ANSWER_STOCK_OPTIONS },
	{ group: DEI_CRITERIA_GROUP.DEI_CRITERIA_GROUP_EQUALPAY, value: DEI_CRITERIA_ANSWER_EQUAL_PAY, label: 'Equal pay' },

	{
		group: DEI_CRITERIA_GROUP.DEI_CRITERIA_GROUP_FAIR_CAREER_PATH,
		value: DEI_CRITERIA_ANSWER_FAIR_CAREER_PATH,
		label: 'Fair career path',
	},
	// { group: DEI_CRITERIA_GROUP.DEI_CRITERIA_GROUP_FAIR_CAREER_PATH, value: DEI_CRITERIA_ANSWER_CLEAR_CAREER_PATH },
	{
		group: DEI_CRITERIA_GROUP.DEI_CRITERIA_GROUP_FAIR_CAREER_PATH,
		value: DEI_CRITERIA_ANSWER_DEVELOPMENT_PROGRAM,
		label: 'Development programs',
	},
	{
		group: DEI_CRITERIA_GROUP.DEI_CRITERIA_GROUP_FAIR_CAREER_PATH,
		value: DEI_CRITERIA_ANSWER_MENTORING_COACHING,
		label: 'Mentoring & coaching',
	},

	{
		group: DEI_CRITERIA_GROUP.DEI_CRITERIA_GROUP_DEI_POLICIES,
		value: DEI_CRITERIA_ANSWER_ANTI_HARASSMENT_TRAINING,
		label: 'Anti-harassment training',
	},
	{
		group: DEI_CRITERIA_GROUP.DEI_CRITERIA_GROUP_DEI_POLICIES,
		value: DEI_CRITERIA_ANSWER_UNBIASED_RECRUITMENT,
		label: 'Unbiased recruitment',
	},
	// { group: DEI_CRITERIA_GROUP.DEI_CRITERIA_GROUP_DEI_POLICIES, value: DEI_CRITERIA_ANSWER_DEI_COMMITTEE },
];

const LEGACY_MAPPINGS = {
	[DEI_CRITERIA_ANSWER_FAIR_CAREER_PATH]: [DEI_CRITERIA_ANSWER_MENTORING_COACHING_PROGRAMS],
	[DEI_CRITERIA_ANSWER_MENTORING_COACHING]: [DEI_CRITERIA_ANSWER_MENTORING_COACHING_PROGRAMS],
	[DEI_CRITERIA_ANSWER_DEVELOPMENT_PROGRAM]: [DEI_CRITERIA_ANSWER_MENTORING_PERSONAL_UPSKILLING_PROGRAMS],
	[DEI_CRITERIA_ANSWER_FLEXIBLE_HOURS_REMOTE]: [DEI_CRITERIA_ANSWER_FLEXIBLE_WORK_SCHEDULE, DEI_CRITERIA_ANSWER_REMOTE_HYBRID_LABEL],
	[DEI_CRITERIA_ANSWER_ANTI_HARASSMENT_TRAINING]: [DEI_CRITERIA_ANSWER_WORKPLACE_CONFLICTS_POLICY],
};

const DEI_CRITERIA_OPTIONS_V2 = [
	{
		group: DEI_CRITERIA_GROUP.DEI_CRITERIA_GROUP_CAREER_CHANCES,
		value: DEI_CRITERIA_ANSWER_MENTORING_COACHING_PROGRAMS,
		label: DEI_CRITERIA_ANSWER_MENTORING_COACHING_PROGRAMS_LABEL,
	},
	{
		group: DEI_CRITERIA_GROUP.DEI_CRITERIA_GROUP_CAREER_CHANCES,
		value: DEI_CRITERIA_ANSWER_MENTORING_PERSONAL_UPSKILLING_PROGRAMS,
		label: DEI_CRITERIA_ANSWER_MENTORING_PERSONAL_UPSKILLING_PROGRAMS_LABEL,
	},
	{
		group: DEI_CRITERIA_GROUP.DEI_CRITERIA_GROUP_CAREER_CHANCES,
		value: DEI_CRITERIA_ANSWER_MENTORING_RETURN_TO_WORK_PROGRAMS,
		label: DEI_CRITERIA_ANSWER_MENTORING_RETURN_TO_WORK_PROGRAMS_LABEL,
	},
	// {
	// 	group: DEI_CRITERIA_GROUP.DEI_CRITERIA_GROUP_CAREER_CHANCES,
	// 	value: DEI_CRITERIA_ANSWER_MENTORING_JUNIOR_ENTRY_LEVEL_OPENNESS,
	// 	label: DEI_CRITERIA_ANSWER_MENTORING_JUNIOR_ENTRY_LEVEL_OPENNESS_LABEL,
	// },
	{
		group: DEI_CRITERIA_GROUP.DEI_CRITERIA_GROUP_CAREER_CHANCES,
		value: DEI_CRITERIA_ANSWER_MENTORING_CAREER_CHANGE_OPENNESS,
		label: DEI_CRITERIA_ANSWER_MENTORING_CAREER_CHANGE_OPENNESS_LABEL,
	},

	{
		group: DEI_CRITERIA_GROUP.DEI_CRITERIA_GROUP_SALARY_POLICIES,
		value: DEI_CRITERIA_ANSWER_EQUAL_PAY,
		label: DEI_CRITERIA_ANSWER_EQUAL_PAY_LABEL,
	},
	{
		group: DEI_CRITERIA_GROUP.DEI_CRITERIA_GROUP_SALARY_POLICIES,
		value: DEI_CRITERIA_ANSWER_TRANSPARENT_PAYGRID,
		label: DEI_CRITERIA_ANSWER_TRANSPARENT_PAYGRID_LABEL,
	},
	{
		group: DEI_CRITERIA_GROUP.DEI_CRITERIA_GROUP_SALARY_POLICIES,
		value: DEI_CRITERIA_ANSWER_EMPLOYEE_EQUITY,
		label: DEI_CRITERIA_ANSWER_EMPLOYEE_EQUITY_LABEL,
	},
	{
		group: DEI_CRITERIA_GROUP.DEI_CRITERIA_GROUP_SALARY_POLICIES,
		value: DEI_CRITERIA_ANSWER_MATERNITY_SALARY_MAINTENANCE,
		label: DEI_CRITERIA_ANSWER_MATERNITY_SALARY_MAINTENANCE_LABEL,
	},

	{
		group: DEI_CRITERIA_GROUP.DEI_CRITERIA_GROUP_WORKLIFE_BALANCE,
		value: DEI_CRITERIA_ANSWER_REMOTE_HYBRID,
		label: DEI_CRITERIA_ANSWER_REMOTE_HYBRID_LABEL,
	},
	{
		group: DEI_CRITERIA_GROUP.DEI_CRITERIA_GROUP_WORKLIFE_BALANCE,
		value: DEI_CRITERIA_ANSWER_FLEXIBLE_WORK_SCHEDULE,
		label: DEI_CRITERIA_ANSWER_FLEXIBLE_WORK_SCHEDULE_LABEL,
	},
	{
		group: DEI_CRITERIA_GROUP.DEI_CRITERIA_GROUP_WORKLIFE_BALANCE,
		value: DEI_CRITERIA_ANSWER_FLEXIBLE_HOLIDAYS,
		label: DEI_CRITERIA_ANSWER_FLEXIBLE_HOLIDAYS_LABEL,
	},
	// {
	// 	group: DEI_CRITERIA_GROUP.DEI_CRITERIA_GROUP_WORKLIFE_BALANCE,
	// 	value: DEI_CRITERIA_ANSWER_INSURANCE_POLICIES,
	// 	label: DEI_CRITERIA_ANSWER_INSURANCE_POLICIES_LABEL,
	// },
	{
		group: DEI_CRITERIA_GROUP.DEI_CRITERIA_GROUP_WORKLIFE_BALANCE,
		value: DEI_CRITERIA_ANSWER_EXTRA_PARENTAL_LEAVE,
		label: DEI_CRITERIA_ANSWER_EXTRA_PARENTAL_LEAVE_LABEL,
	},

	{
		group: DEI_CRITERIA_GROUP.DEI_CRITERIA_GROUP_DEI_ACTIONS,
		value: DEI_CRITERIA_ANSWER_WORKPLACE_CONFLICTS_POLICY,
		label: DEI_CRITERIA_ANSWER_WORKPLACE_CONFLICTS_POLICY_LABEL,
	},
	{
		group: DEI_CRITERIA_GROUP.DEI_CRITERIA_GROUP_DEI_ACTIONS,
		value: DEI_CRITERIA_ANSWER_UNBIASED_RECRUITMENT,
		label: DEI_CRITERIA_ANSWER_UNBIASED_RECRUITMENT_LABEL,
	},
	{
		group: DEI_CRITERIA_GROUP.DEI_CRITERIA_GROUP_DEI_ACTIONS,
		value: DEI_CRITERIA_ANSWER_EMPLOYEE_RESOURCE_GROUPS,
		label: DEI_CRITERIA_ANSWER_EMPLOYEE_RESOURCE_GROUPS_LABEL,
	},
	{
		group: DEI_CRITERIA_GROUP.DEI_CRITERIA_GROUP_DEI_ACTIONS,
		value: DEI_CRITERIA_ANSWER_MENTAL_HEALTH_INITIATIVES,
		label: DEI_CRITERIA_ANSWER_MENTAL_HEALTH_INITIATIVES_LABEL,
	},
];

const DEI_CRITERIA_OPTIONS_SELECT = [
	DEI_CRITERIA_ANSWER_FLEXIBLE_HOURS_REMOTE,
	DEI_CRITERIA_ANSWER_EXTRA_PARENTAL_LEAVE,
	DEI_CRITERIA_ANSWER_TRANSPARENT_PAYGRID,
	DEI_CRITERIA_ANSWER_EQUAL_PAY,
	// DEI_CRITERIA_ANSWER_CLEAR_CAREER_PATH,
	DEI_CRITERIA_ANSWER_FAIR_CAREER_PATH,
	DEI_CRITERIA_ANSWER_DEVELOPMENT_PROGRAM,
	DEI_CRITERIA_ANSWER_MENTORING_COACHING,
	DEI_CRITERIA_ANSWER_ANTI_HARASSMENT_TRAINING,
	DEI_CRITERIA_ANSWER_UNBIASED_RECRUITMENT,
];

const customValidator = (value) => DEI_CRITERIA_OPTIONS_V2.map((o) => o.value).includes(value);

const DEI_CRITERIA_VALIDATORS = [
	isStringTypeValidator,
	customValidator,
];

export default {
	key: DEI_CRITERIA_KEY,
	DEI_CRITERIA_KEY,
	validators: DEI_CRITERIA_VALIDATORS,
	DEI_CRITERIA_VALIDATORS,
	groups: DEI_CRITERIA_GROUP.groups,
	DEI_CRITERIA_GROUP: DEI_CRITERIA_GROUP.options,
	options: DEI_CRITERIA_OPTIONS_V2,
	// DEI_CRITERIA_OPTIONS,
	// DEI_CRITERIA_OPTIONS_SIMPLIFIED,
	// DEI_CRITERIA_OPTIONS_SELECT,
	LEGACY_MAPPINGS,
};
