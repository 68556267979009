import {
	Form, Input, Select, Space,
} from 'antd';
import LOCATION_COUNTRY from '../../../../constants/property/location-country';
import EXPERIENCE_LEVEL from '../../../../constants/property/experience-level';
import CAREER_ROLE_GROUPS from '../../../../constants/property/career-role-group';
import CAREER_ROLES from '../../../../constants/property/career-role';
import { useState } from 'react';
import { useTheme } from '@emotion/react';
import FunnelSelectFormItem from '../funnels/FunnelSelectFormItem';

const TalentChartUserFilters = (
	{
		setFilters,
	},
) => {
	const className = 'TalentChartUserFilters';

	const theme = useTheme();

	const [search, setSearch] = useState('');

	const onboardedOptions = [
		{ value: true, label: 'Onboarded' },
		{ value: false, label: 'Not onboarded' },
		{ value: undefined, label: 'Not provided' },
	];
	return (
		<div
			style={{
				background: theme.color.bgGrey,
				padding: 10,
				marginBottom: 10,
			}}
		>
			<h3>Filters</h3>
			<Form
				layout='vertical'
				style={{ width: '100%' }}
				className='funnel-filters'
			>
				<Space wrap={true} size={[8, 8]}>

					{/* <FunnelSelectFormItem */}
					{/*	label='Onboarded' */}
					{/*	setFilters={setFilters} */}
					{/*	field='onboarded' */}
					{/*	options={onboardedOptions} */}
					{/*	placeholder='Onboarded' */}
					{/*	style={{ width: 140 }} */}
					{/*	mode='single' */}
					{/* /> */}

					<FunnelSelectFormItem
						label='Job search status'
						setFilters={setFilters}
						field='status'
						placeholder='Job search status'
						style={{ width: 140 }}
						options={[
							{ value: 'active', label: 'Active' },
							{ value: 'open', label: 'Open' },
							{ value: 'no', label: 'Not looking' },
						]}
					/>

					<FunnelSelectFormItem
						label='Country'
						setFilters={setFilters}
						field='country'
						options={LOCATION_COUNTRY.options.map((o) => ({ ...o, label: `${o.emoji} ${o.label}` }))}
						placeholder='Country'
						style={{ width: 140 }}
					/>

					<FunnelSelectFormItem
						label='Level'
						setFilters={setFilters}
						field='level'
						options={EXPERIENCE_LEVEL.options}
						placeholder='Level'
						style={{ width: 140 }}
					/>

					<FunnelSelectFormItem
						label='Job role groups'
						setFilters={setFilters}
						field='jobRoleGroup'
						options={CAREER_ROLE_GROUPS.options}
						placeholder='Job role group'
						style={{ width: 180 }}
					/>

					<FunnelSelectFormItem
						label='Job roles'
						setFilters={setFilters}
						field='jobRole'
						placeholder='Job role'
						options={CAREER_ROLES.options}
						style={{ width: 180 }}
					/>

				</Space>
			</Form>
		</div>
	);
};

export default TalentChartUserFilters;
