import { Divider } from 'antd';
import { useState } from 'react';
import { useTheme } from '@emotion/react';
import { FaMinusCircle, FaPlusCircle } from 'react-icons/fa';
import Link from '../../app/Link';
import { NOT_PROVIDED_YET_LABEL } from '../../../constants/constant';

const EntriesCardBody = (
	{
		entries,
		RowRenderer,
		LineRenderer,
		limit = 1,
		notProvided = NOT_PROVIDED_YET_LABEL,
		...props
	},
) => {
	const theme = useTheme();

	const [isFullDisplay, setIsFullDisplay] = useState(false);

	if (!entries || !entries?.length) return notProvided;

	const fullContent = entries?.map((entry, index) => <div key={index}>
		<RowRenderer entry={entry}/>
		<Divider/>
	</div>);

	if (limit > 0 && entries?.length > limit) {
		const rest = entries?.length - limit;
		const limitedContent = <>
			<RowRenderer entry={entries?.[0]}/>
			<Divider/>
			<Link onClick={() => { setIsFullDisplay(true); }}>
				<FaPlusCircle style={{ marginBottom: -2 }}/> See <b>{rest}</b> additional entr{rest > 1 ? 'ies' : 'y'}
			</Link>
			{entries
				.slice(limit, limit + 4)
				.map((r, i) => <div
					key={i}
					style={{
						fontSize: 11,
						color: theme.color.grey,
						opacity: 1 - 0.25 * i,
						overflow: 'hidden',
						whiteSpace: 'nowrap',
						textOverflow: 'ellipsis',
					}}>
					<LineRenderer entry={r}/>
				</div>)
			}
		</>;
		const fullContentWithLink = <>
			{fullContent}
			<Link onClick={() => { setIsFullDisplay(false); }}>
				<FaMinusCircle style={{ marginBottom: -2 }}/> See less
			</Link>
		</>;
		return (isFullDisplay ? fullContentWithLink : limitedContent);
	}
	return fullContent;
};

export default EntriesCardBody;
