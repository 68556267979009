import { Form, Input } from 'antd';
import FormItem from '../../company/form/FormItem';
import { requiredEmailRules } from '../../../utils/common';

const InviteUserForm = ({
	form,
	initialValues = {},
	onSubmit,
	children,
}) => {
	return (
		<Form
			form={form}
			name="inviteUserForm"
			initialValues={{ ...initialValues }}
			onFinish={onSubmit}
		>
			<FormItem
				name="email"
				label="Email"
				rules={requiredEmailRules}
			>
				<Input />
			</FormItem>
			{children}
		</Form>
	);
};

export default InviteUserForm;
