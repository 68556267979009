import moment from 'moment';
import { useEffect, useState } from 'react';
import { useTheme } from '@emotion/react';
import { useDispatch, useSelector } from 'react-redux';
import {
	Button, Row, Col, Divider, Form, message, Card, Space,
} from 'antd';
import { STAGE_NEW } from '../../constants/constant';
// import FormItem, { FormItemContainer } from '../app/form/FormItem';
import FormItem from '../company/form/FormItem';
import { getCompanies, getCompanySelected } from '../../reducers/app';
import JobSelect from '../jobs/JobSelect';
import { useAuthContext } from '../../hooks/useAuthContext';
import UserStub from '../user/stub/UserStub';
import FormCompanySelect from '../app/form/FormCompanySelect';
import InlineNavLink from '../app/button/InlineNavLink';
import { shouldRefreshData } from '../../actions/app';
import CompanyStub from '../company/CompanyStub';
import FormCancelSubmitButtons from './FormCancelSubmitButtons';
import { documentId, requiredRule } from '../../utils/common';

const SourcingSaveForm = (
	{
		initialValues = {},
		successMessage,
		onSubmit,
		onReset,
	},
) => {
	const theme = useTheme();
	const dispatch = useDispatch();
	const companies = useSelector(getCompanies);
	const companySelected = useSelector(getCompanySelected);
	const { isTalent, isStaffView, currentUser } = useAuthContext();

	const defaultCandidate = initialValues?.user;
	const viaProfile = initialValues?.viaProfile || false;
	const viaJob = initialValues?.viaJob || false;
	const defaultSource = initialValues?.source;

	// -------------------------------------------- //
	// -------------- state ----------------------- //
	// -------------------------------------------- //
	const [initialized, setInitialized] = useState(false);
	const [isActive] = useState(initialValues?.isActive !== false);

	const [companyId, setCompanyId] = useState();
	const [jobId, setJobId] = useState(documentId(initialValues?.job));

	const [companyIdJob, setCompanyIdJob] = useState(initialValues?.companyId);
	const [companyIds, setCompanyIds] = useState(initialValues?.companyIds);

	const [form] = Form.useForm();

	const handleSubmit = async (values) => {
		const data = {
			...initialValues,
			creatorId: documentId(currentUser),
			companyId: companyIdJob,
			...values,
			id: documentId(initialValues) || null,
			updatedStageAt: moment(),
			isActive,
		};
		form.resetFields();
		await onSubmit(data);
		dispatch(shouldRefreshData());
	};

	const handleChangeJob = (value, option) => {
		setJobId(value);
		// set companyId vale
		setCompanyIdJob(option?.companyId);
	};

	const handleChangeCompany = (value) => {
		if (value) {
			setCompanyId(value);
			setCompanyIds([value]);
		} else {
			setCompanyId(null);
			setCompanyIds([]);
		}
		form.setFieldsValue({ jobId: null });
	};

	useEffect(() => {
		setInitialized(true);
	}, []);

	useEffect(() => {
		initialValues.companyId = documentId(companySelected);
	}, [companySelected]);

	return (initialized
		? <div>
			<Form
				form={form}
				labelCol={{ span: 4 }}
				labelAlign='right'
				className="user-filter-form"
				initialValues={{ ...initialValues }}
				onFinish={handleSubmit}
			>
				<Row gutter={24}>
					<Col span={24}>
						{/* <FormItemContainer> */}
						<FormItem
							label="Talent"
							name="userId"
							rules={[requiredRule]}
						>
							<Card
								size="small"
								style={{
									borderRadius: 4,
									width: '100%',
								}}>
								<UserStub user={defaultCandidate} withDropdown={false}/>
							</Card>
						</FormItem>
					</Col>
				</Row>
				{(
					(isTalent && isStaffView)
							|| (!viaProfile && !viaJob)
							|| companySelected
				)
						&& <FormItem
							label="Client"
							defaultContainer="div"
							name="companyId"
							rules={[requiredRule]}
						>
							<FormCompanySelect
								options={companies.map((c) => ({ value: documentId(c), label: c?.name, item: c }))}
								onChange={handleChangeCompany}
								disabled={documentId(companySelected) || viaJob}
								placeholder="Client"
								mode="single"
							/>
						</FormItem>}

				<FormItem
					label="Job"
					defaultContainer="div"
					name="jobId"
				>
					<JobSelect
						defaultOptions={initialValues?.job ? [initialValues?.job] : []}
						value={jobId}
						placeholder="Select a job"
						onChange={handleChangeJob}
						style={{ width: '100%' }}
						disabled={!companyId && !viaProfile}
						companyIds={documentId(companySelected) ? [documentId(companySelected)] : companyIds}
						notJobIds={initialValues?.notJobIds}
					/>
				</FormItem>
				{/* </FormItemContainer> */}

				<Divider/>

				<FormCancelSubmitButtons
					onReset={onReset}
					onSubmit={onSubmit}
					submitText='Save'
				/>
			</Form>

		</div>
		: null
	);
};

export default SourcingSaveForm;
