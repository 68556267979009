import { Select } from 'antd';
import { getExtraTags } from '../../../reducers/app';
import { useDispatch, useSelector } from 'react-redux';
import { getAllCompanyPlansExtraTags } from '../../../actions/company';
import { useEffect } from 'react';

const CompanyPlanExtraTagsSelect = ({
	...props
}) => {
	const className = 'CompanyPlanExtraTagsSelect';

	const dispatch = useDispatch();

	const extraTags = useSelector(getExtraTags);
	useEffect(() => {
		dispatch(getAllCompanyPlansExtraTags());
	}, []);

	return (
		<Select
			{...props}
			options={extraTags || []}
			mode='tags'
			style={{
				minWidth: 150,
				...props?.style,
			}}
		/>
	);
};

export default CompanyPlanExtraTagsSelect;
