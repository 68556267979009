import {
	cssLinearGradient, documentId,
} from '../../../../utils/common';
import { useTheme } from '@emotion/react';
import UserGridCardFooter from './UserGridCardFooter';
import UserService from '../../../../services/user';
import UserGridCardLayout from './UserGridCardLayout';
import UserGridCardLeftColumn from './UserGridCardLeftColumn';
import UserGridCardRightColumn from './UserGridCardRightColumn';
import UserGridCardSaverButton from './UserGridCardSaverButton';
import UserGridCardAlert from './UserGridCardAlert';
import { StaffToolsContext } from '../../../../contexts/StaffToolsProvider';
import { useContext, useState } from 'react';
import { UserStoreContext } from '../../../../contexts/UserStoreProvider';
import UserModel from '../../../../models/UserModel';

const UserGridCard = (
	{
		user,
		onUserViewed,
		loading,
		onRefresh,
		savedSearchId,
		children,
		...props
	},
) => {
	const className = 'UserGridCard';

	const theme = useTheme();
	const { isSavedSearchExcludeViewedEnabled } = useContext(StaffToolsContext);
	const { fetchUser } = useContext(UserStoreContext);
	const [userModel, setUserModel] = useState(new UserModel(user));

	const onUserChanged = async () => {
		setUserModel(await fetchUser(documentId(user)));
	};

	const setUserViewed = () => {
		// if savedSearchId is set, sending it when viewing a user
		// to add the userId to the saved search log in viewedUserIds
		UserService.viewUser(documentId(user), { savedSearchId });
		user.isViewed = true;
		user.isNewId = false;
		setUserModel(user);
		onUserViewed?.({ user });
	};

	const background = cssLinearGradient({
		angle: '150deg',
		rgbPcts: [
			[user.isNewId ? theme.color.accent : theme.color.darkturquoise, '44px'],
			[theme.color.bgGrey, '44px'],
		],
	});

	return (
		<>
			<UserGridCardLayout
				leftColumn={<UserGridCardLeftColumn user={userModel}/>}
				rightColumn={<UserGridCardRightColumn user={userModel} withSave={false} />}
				footerRow={(
					<UserGridCardFooter
						user={userModel}
						setUserViewed={setUserViewed}
						onUserChanged={onUserChanged}
						onRefresh={onRefresh}
					/>
				)}
				{...props}
			>
				{isSavedSearchExcludeViewedEnabled && <UserGridCardAlert user={userModel}/>}
				<UserGridCardSaverButton user={userModel}/>
			</UserGridCardLayout>
		</>
	);
};

export default UserGridCard;
