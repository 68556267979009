import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { useTheme } from '@emotion/react';
import { useSelector } from 'react-redux';
import { getCompanySelected } from '../../../../reducers/app';
import { Button, Space, Statistic } from 'antd';
import { GENDERSCORE_LABEL, PATHS } from '../../../../constants/constant';
import CompanyModel from '../../../../models/CompanyModel';
import { useNavigate } from 'react-router';
import CSM_DETAILS from '../../../../constants/csmDetails';
import { openExternalUrl } from '../../../../utils/common';

const GenderScoreUnpublishedBanner = ({
	company,
	withNavigateToSurvey = true,
}) => {
	const className = 'GenderScoreUnpublishedBanner';

	const theme = useTheme();
	const navigate = useNavigate();

	const companySelected = useSelector(getCompanySelected);

	// const [companyModel, setCompanyModel] = useState(null);
	const [content, setContent] = useState(null);

	useEffect(() => {
		const companyModel = new CompanyModel(companySelected);
		setContent(
			<>
				<span>Your {GENDERSCORE_LABEL} has been <b>unpublished</b> by 50inTech: it is not visible anymore to talents</span>
				<Button
					ghost
					onClick={() => openExternalUrl(`mailto:${csmDetails?.user?.email}`)}
				>
					Talk to Customer Success Team
				</Button>
			</>,
		);
	}, [companySelected]);
	const mainColor = '#e26f6c';

	return (
		<div
			id='genderscore-expired-banner'
			style={{
				width: '100%',
				height: 100,
				background: mainColor,
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
			}}
		>
			<Space
				direction='vertical'
				style={{
					fontWeight: 'bold',
					fontSize: 16,
					color: 'white',
					justifyContent: 'center',
					alignItems: 'center',
				}}
			>
				{content}
			</Space>
		</div>
	);
};

export default GenderScoreUnpublishedBanner;
