export const CATEGORY_FIRST_APPROACH_VALUE = 'first-approach';
export const CATEGORY_FIRST_APPROACH_OPTION = {
	value: CATEGORY_FIRST_APPROACH_VALUE,
	label: 'First approach',
	color: '#0000ff',
};
export const CATEGORY_APPLICATION_ACCEPTED_VALUE = 'application-accepted';
export const CATEGORY_APPLICATION_ACCEPTED_OPTION = {
	value: CATEGORY_APPLICATION_ACCEPTED_VALUE,
	label: 'Application accepted',
	color: '#00ced1',
};
export const CATEGORY_APPLICATION_REJECTED_VALUE = 'application-rejected';
export const CATEGORY_APPLICATION_REJECTED_OPTION = {
	value: CATEGORY_APPLICATION_REJECTED_VALUE,
	label: 'Application rejected',
	color: '#ff0000',
};
export const CATEGORY_FOLLOWUP_VALUE = 'followup';
export const CATEGORY_FOLLOWUP_OPTION = {
	value: CATEGORY_FOLLOWUP_VALUE,
	label: 'Followup',
	color: '#00ffff',
};
export const CATEGORY_OTHER_VALUE = 'other';
export const CATEGORY_OTHER_OPTION = {
	value: CATEGORY_OTHER_VALUE,
	label: 'Other',
	color: '#ffff00',
};

export const CATEGORY_LIST = [
	CATEGORY_FIRST_APPROACH_OPTION,
	CATEGORY_APPLICATION_ACCEPTED_OPTION,
	CATEGORY_APPLICATION_REJECTED_OPTION,
	CATEGORY_FOLLOWUP_OPTION,
	CATEGORY_OTHER_OPTION,
];

export const findCategoryOption = (value) => CATEGORY_LIST.find((o) => o.value === value);
