import GridValueCard from '../../../app/card/GridValueCard';
import { NOT_PROVIDED_YET_LABEL } from '../../../../constants/constant';
import UserProfileJobSearchJobLocationsCardBody from './UserProfileJobSearchJobLocationsCardBody';

const UserProfileJobSearchJobLocationsCard = (
	{
		user,
		notProvided = NOT_PROVIDED_YET_LABEL,
		children,
		...props
	},
) => {
	const title = 'Desired work locations';

	return (
		<GridValueCard
			title={title}
			{...props}
		>
			{
				children
				|| (
					<UserProfileJobSearchJobLocationsCardBody
						user={user}
						notProvided={notProvided}
					/>
				)}
		</GridValueCard>
	);
};

export default UserProfileJobSearchJobLocationsCard;
