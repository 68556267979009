export const GENDERSCORE_PILLAR_GENDERDATA_VALUE = 'genderData';
export const GENDERSCORE_PILLAR_GENDERDATA_LABEL = 'Gender Data';
export const GENDERSCORE_PILLAR_EQUALPAY_VALUE = 'equalPay';
export const GENDERSCORE_PILLAR_EQUALPAY_LABEL = 'Equal Pay';
export const GENDERSCORE_PILLAR_FAIRCAREERPATH_VALUE = 'fairCareerPath';
export const GENDERSCORE_PILLAR_FAIRCAREERPATH_LABEL = 'Career Path';
export const GENDERSCORE_PILLAR_WORKLIFEBALANCE_VALUE = 'worklifeBalance';
export const GENDERSCORE_PILLAR_WORKLIFEBALANCE_LABEL = 'Work-life Balance';
export const GENDERSCORE_PILLAR_DEIACTIONS_VALUE = 'deiActions';
export const GENDERSCORE_PILLAR_DEIACTIONS_LABEL = 'DEI Actions';

export const GENDERSCORE_PILLAR_VALUES = [
	GENDERSCORE_PILLAR_GENDERDATA_VALUE,
	GENDERSCORE_PILLAR_EQUALPAY_VALUE,
	GENDERSCORE_PILLAR_FAIRCAREERPATH_VALUE,
	GENDERSCORE_PILLAR_WORKLIFEBALANCE_VALUE,
	GENDERSCORE_PILLAR_DEIACTIONS_VALUE,
];

export const GENDERSCORE_PILLAR_GENDERDATA_OPTION = {
	value: GENDERSCORE_PILLAR_GENDERDATA_VALUE,
	label: GENDERSCORE_PILLAR_GENDERDATA_LABEL,
	path: '/genderscore/gender-data',
	slug: 'gender-data',
	initials: 'GD',
	colors: {
		main: '#0959D9',
		light: '#E7F4FF',
		tag: 'blue',
	},
	style: {
		actionCardLight: {
			// blue light
			backgroundImage: 'radial-gradient(circle at 100% 0,#9fd,#a4f9ff 60%)',
		},
		actionCardStrong: {
			// blue strong
			backgroundImage: 'radial-gradient(circle at 100% 0,#1295ad,#046 80%)',
			color: '#cdface',
		},
	},
	icon: ({
		width, height, background, withBackground, color, ...props
	} = {}) => (
		<div style={{ ...props?.style }}>
			<svg width={width || 20} height={height || 20} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 500">
				<g>
					{withBackground !== false && <rect x="0" y="0" strokeWidth="0" rx="100" height="500" width="500" fill={background || '#0959D9'} />}
					<path transform="rotate(-45 250 250)" stroke="null" d="m250.00004,70.17169l0,0c30.5669,0 55.34628,24.77938 55.34628,55.34628c0,30.56692 -24.77937,55.34628 -55.34628,55.34628c-30.56694,0 -55.34628,-24.77933 -55.34628,-55.34628c0,-30.56691 24.77934,-55.34628 55.34628,-55.34628zm0,359.65673c-30.56694,0 -55.34628,-24.77936 -55.34628,-55.34629c0,-30.5669 24.77934,-55.34628 55.34628,-55.34628c30.5669,0 55.34628,24.77937 55.34628,55.34628c0,30.56693 -24.77937,55.34629 -55.34628,55.34629z" strokeWidth="0" fill={color || '#ffffff'}/>
					<rect transform="rotate(-45 250 250)" stroke="null" height="50" width="320" y="225" x="90" strokeWidth="0" fill={color || '#ffffff'}/>
				</g>
			</svg>
		</div>
	),
	sectionIntro: (
		<>
			In this section, we focused on your company’s <strong>Gender Data</strong> in
			order to have a clear understanding of your commitment towards <strong>achieving
			gender diversity and inclusion.</strong>
		</>
	),
	sectionIntroShort: (
		<>
			This pillar assesses your company's commitment towards <strong>achieving
			gender diversity and inclusion.</strong>
		</>
	),
};

export const GENDERSCORE_PILLAR_FAIRCAREERPATH_OPTION = {
	value: GENDERSCORE_PILLAR_FAIRCAREERPATH_VALUE,
	label: GENDERSCORE_PILLAR_FAIRCAREERPATH_LABEL,
	path: '/genderscore/career-path',
	slug: 'career-path',
	initials: 'CP',
	colors: {
		main: '#09979C',
		light: '#E6FFFB',
		tag: 'cyan',
	},
	style: {
		actionCardLight: {
			// green light
			backgroundImage: 'radial-gradient(circle at 100% 0,#e9f59e,#b3ffd7 60%)',
			color: '#003d3d',
		},
		actionCardStrong: {
			// green strong
			backgroundImage: 'radial-gradient(circle at 100% 0,#008c63,#00664e 30%,#003d3d 70%)',
			color: '#cdface',
		},
	},
	icon: ({
		width, height, background, withBackground, color, ...props
	} = {}) => (
		<div style={{ ...props?.style }}>
			<svg width={width || 20} height={height || 20} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 500">
				<g>
					{withBackground !== false && <rect x="0" y="0" strokeWidth="0" rx="100" height="500" width="500" fill={background || '#09979C'} />}
					<rect height="50" width="170" y="330" x="95" strokeWidth="0" stroke="null" fill={color || '#ffffff'}/>
					<rect height="50" width="170" y="120" x="245" strokeWidth="0" stroke="null" fill={color || '#ffffff'}/>
					<rect height="50" width="170" y="225" x="165" strokeWidth="0" stroke="null" fill={color || '#ffffff'} />
				</g>
			</svg>
		</div>
	),
	sectionIntro: (
		<>
			In this section, we focused on your company’s <strong>Career Path</strong> in
			order to have a clear understanding of your commitment towards <strong>identifying
			and supporting employee career goals</strong>
		</>
	),
	sectionIntroShort: (
		<>
			This pillar assesses your company's commitment towards <strong>identifying
			and supporting employee career goals.</strong>
		</>
	),
	communityStat: {
		ranking: (
			<>
				This pillar ranks as the <strong>3rd most important DEI criteria</strong> among
				our 30.000+ community members
			</>
		),
		data: (
			<>
				22% of those surveyed stated that they prefer to work
				for companies that offer <strong>upskilling, and mentoring and coaching programmes.</strong>
			</>
		),
	},
	globalStat: {
		value: '8%',
		text: <>
			of <strong>C-Level positions in Europe</strong> are occupied by women
		</>,
	},
};

export const GENDERSCORE_PILLAR_EQUALPAY_OPTION = {
	value: GENDERSCORE_PILLAR_EQUALPAY_VALUE,
	label: GENDERSCORE_PILLAR_EQUALPAY_LABEL,
	path: '/genderscore/equal-pay',
	slug: 'equal-pay',
	initials: 'EP',
	colors: {
		main: '#D48806',
		light: '#FFFBE6',
		tag: 'gold',
	},
	style: {
		actionCardLight: {
			// orange
			backgroundImage: 'radial-gradient(circle at 100% 0,#ffe0a3,#ffadb2 60%)',
		},
	},
	icon: ({
		width, height, background, withBackground, color, ...props
	} = {}) => (
		<div style={{ ...props?.style }}>
			<svg width={width || 20} height={height || 20} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 500">
				<g>
					{withBackground !== false && <rect id="svg_1" x="0" y="0" strokeWidth="0" rx="100" height="500" width="500" fill={background || '#D48806'} />}
					<rect fill={color || '#ffffff'} stroke="null" strokeWidth="0" y="250.20464" x="71" height="50" width="360" id="svg_2"/>
					<path stroke="null" fill={color || '#ffffff'} d="m202.49999,400.79536l47.50001,-83.12504l47.50001,83.12504l-95.00003,0l0.00001,0z" strokeWidth="0" id="svg_3"/>
					<ellipse fill={color || '#ffffff'} stroke="null" strokeWidth="0" cx="130" cy="177.20464" rx="50" ry="50" id="svg_4"/>
					<ellipse fill={color || '#ffffff'} stroke="null" strokeWidth="0" cx="370" cy="177.20464" rx="50" ry="50" id="svg_5" />
				</g>
			</svg>
		</div>
	),
	sectionIntro: (
		<>
			In this section, we focused on your company’s <strong>Equal Pay</strong> in
			order to have a clear understanding of your commitment towards <strong>equal
			work leads to equal pay.</strong>
		</>
	),
	sectionIntroShort: (
		<>
			This pillar assesses your company's commitment towards the idea that <strong>equal
			work leads to equal pay.</strong>
		</>
	),
	globalStat: {
		value: '€400k',
		text: <>
			This is the <strong>average deficit</strong> in compensation for women compared to men <strong>over their working life</strong>
		</>,
	},
};

export const GENDERSCORE_PILLAR_WORKLIFEBALANCE_OPTION = {
	value: GENDERSCORE_PILLAR_WORKLIFEBALANCE_VALUE,
	label: GENDERSCORE_PILLAR_WORKLIFEBALANCE_LABEL,
	slug: 'work-life-balance',
	initials: 'WL',
	path: '/genderscore/work-life-balance',
	colors: {
		main: '#531DAB',
		light: '#F9F0FF',
		tag: 'purple',
	},
	style: {
		actionCardLight: {
			// purple lighter
			backgroundImage: 'radial-gradient(circle at 100% 0,#fab8ff,#dde2ff 60%)',
		},
		actionCardStrong: {
			// purple light
			backgroundImage: 'radial-gradient(circle at 100% 0,#bfbfff,#dcbeff 60%)',
		},
	},
	icon: ({
		width, height, background, withBackground, color, ...props
	} = {}) => (
		<div style={{ ...props?.style }}>
			<svg width={width || 20} height={height || 20} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 500">
				<g>
					{withBackground !== false && <rect x="0" y="0" strokeWidth="0" rx="100" height="500" width="500" fill={background || '#531DAB'} />}
					<path stroke="null" id="svg_28" d="m414.99429,249.98109l-66.57531,23.68411l0,-47.34299l66.57531,23.65889zm-48.3393,-116.66757l-30.33029,63.83864l-33.47052,-33.49574l63.80081,-30.3429zm-116.65498,-48.31407l23.6715,66.58791l-47.35561,0l23.68411,-66.58791zm-116.66759,48.31407l63.82604,30.3429l-33.48313,33.49574l-30.34291,-63.83864zm-48.32669,116.66757l66.58792,-23.65889l0,47.34299l-66.58792,-23.68411zm48.32669,116.66757l30.34291,-63.81341l33.48313,33.48313l-63.82604,30.33029l0,0zm116.66759,48.3519l-23.68411,-66.60052l47.35561,0l-23.6715,66.60052zm116.65498,-48.3519l-63.81343,-30.31768l33.47052,-33.48313l30.34291,63.8008zm-199.14581,-116.66757l0,0c0,-45.55218 36.93864,-82.49082 82.50344,-82.49082c45.55219,0 82.50344,36.93863 82.50344,82.49082c0,45.5648 -36.95125,82.50343 -82.50344,82.50343c-45.57741,0 -82.50344,-36.93863 -82.50344,-82.50343l0,0z" strokeWidth="0" fill={color || '#ffffff'}/>
				</g>
			</svg>
		</div>
	),
	sectionIntro: (
		<>
			In this section, we focused on your company’s <strong>Work-Life Balance</strong> in
			order to have a clear understanding of the <strong>expectations</strong> set by your
			company <strong>for employees</strong> both in their personal and professional lives.
		</>
	),
	sectionIntroShort: (
		<>
			This pillar assesses the expectations set by your company for employees
			both in their <strong>personal and professional lives.</strong>
		</>
	),
	communityStat: {
		ranking: (
			<>
				This pillar ranks as the <strong>most important DEI criteria</strong> among
				our 30.000+ community members
			</>
		),
	},
};

export const GENDERSCORE_PILLAR_DEIACTIONS_OPTION = {
	value: GENDERSCORE_PILLAR_DEIACTIONS_VALUE,
	label: GENDERSCORE_PILLAR_DEIACTIONS_LABEL,
	path: '/genderscore/hr-dei-initiatives',
	slug: 'dei-actions',
	initials: 'DEI',
	colors: {
		main: '#C41C7F',
		light: '#FFF0F6',
		tag: 'pink',
	},
	style: {
		actionCardLight: {
			// red light
			backgroundImage: 'radial-gradient(circle at 100% 0,#FFF0F6,#C41C7F 60%)',
		},
		actionCardStrong: {
			// red light
			backgroundImage: 'radial-gradient(circle at 100% 0,#FFF0F6,#C41C7F 60%)',
		},
	},
	icon: ({
		width, height, background, withBackground, color, ...props
	} = {}) => (
		<div style={{ ...props?.style }}>
			<svg width={width || 20} height={height || 20} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 500">
				<g>
					{withBackground !== false && <rect x="0" y="0" strokeWidth="0" rx="100" height="500" width="500" fill={background || '#C41C7F'} />}
					<rect transform="rotate(45 333 203.5)" stroke="null" fill={color || '#ffffff'} strokeWidth="0" x="242.99993" y="178.49998" width="180" height="50" id="svg_3"/>
					<rect transform="rotate(-45 332 296.5)" stroke="null" fill={color || '#ffffff'} strokeWidth="0" x="241.99993" y="271.49998" width="180" height="50" id="svg_4"/>
					<rect transform="rotate(45 193 203.5)" fill={color || '#ffffff'} stroke="null" strokeWidth="0" x="102.99993" y="178.49998" width="180" height="50" id="svg_2"/>
					<rect transform="rotate(-45 192 296.5)" stroke="null" fill={color || '#ffffff'} strokeWidth="0" x="101.99993" y="271.49998" width="180" height="50" id="svg_6"/>			</g>
			</svg>
		</div>
	),
	sectionIntro: (
		<>
			In this section, we focused on your company’s <strong>HR and DEI Initiatives</strong> in
			order to have a clear understanding of your organisation’s dedication to <strong>fostering
				a work environment where each employee</strong>, regardless of their, his or her
			background, <strong>feels safe, seen and respected.</strong>
		</>
	),
	sectionIntroShort: (
		<>
			This pillar assesses your company's commitment that <strong>each employee
			feels safe, seen and respected.</strong>
		</>
	),
	communityStat: {
		ranking: (
			<>
				This pillar ranks as the <strong>2nd most important DEI criteria</strong> among
				our 30.000+ community members
			</>
		),
	},
	globalStat: {
		value: '50%',
		text: <>
			of women say <strong>discrimination</strong> is their main reason for <strong>leaving tech</strong>
		</>,
	},
};

export const GENDERSCORE_PILLAR_OPTIONS_LIST = [
	GENDERSCORE_PILLAR_GENDERDATA_OPTION,
	GENDERSCORE_PILLAR_EQUALPAY_OPTION,
	GENDERSCORE_PILLAR_FAIRCAREERPATH_OPTION,
	GENDERSCORE_PILLAR_WORKLIFEBALANCE_OPTION,
	GENDERSCORE_PILLAR_DEIACTIONS_OPTION,
];

export const GENDERSCORE_PILLAR_LABELS = GENDERSCORE_PILLAR_OPTIONS_LIST.map((o) => o.label);

export const GENDERSCORE_PILLAR_OPTIONS_MAP = GENDERSCORE_PILLAR_OPTIONS_LIST
	.map((option) => ({ [option.value]: option })).reduce((prev, cur) => ({ ...(prev || {}), ...(cur || {}) }), {});

export const getPillarOption = (pillar) => GENDERSCORE_PILLAR_OPTIONS_MAP[pillar];
export const getPillarLabel = (pillar) => getPillarOption(pillar)?.label;
export const getPillarIndex = (pillar) => GENDERSCORE_PILLAR_VALUES.indexOf(pillar);
export const getPillarColorMain = (pillar) => getPillarOption(pillar)?.colors?.main;
export const getPillarColorLight = (pillar) => getPillarOption(pillar)?.colors?.light;
export const getPillarColorTag = (pillar) => getPillarOption(pillar)?.colors?.tag;
export const getPillarIcon = (pillar, options) => getPillarOption(pillar)?.icon(options || {});
export const getPillarPath = (pillar, options) => getPillarOption(pillar)?.path;

export const hasNextPillar = (pillar) => (
	GENDERSCORE_PILLAR_VALUES.length >= GENDERSCORE_PILLAR_VALUES.indexOf(pillar) - 1
);
export const getNextPillar = (pillar) => (
	hasNextPillar(pillar)
		? GENDERSCORE_PILLAR_VALUES[GENDERSCORE_PILLAR_VALUES.indexOf(pillar) + 1]
		: null
);

export const hasPrevPillar = (pillar) => (
	GENDERSCORE_PILLAR_VALUES.indexOf(pillar) > 0
);
export const getPrevPillar = (pillar) => (
	hasPrevPillar(pillar)
		? GENDERSCORE_PILLAR_VALUES[GENDERSCORE_PILLAR_VALUES.indexOf(pillar) - 1]
		: null
);
