import { Space, Tag } from 'antd';
import { arrayWrap } from '../../../utils/common';
import { convertQuestionAnswerValueToLabel, isQuestionAnswered } from '../data/shared';
import { useTheme } from '@emotion/react';
import { useContext } from 'react';
import { SurveyNavigationContext } from './providers/SurveyNavigationProvider';

const SurveyAnswer = ({
	question,
	answer,
	notAnswered,
	...props
}) => {
	const className = 'SurveyAnswer';

	const theme = useTheme();

	const { appLang, t } = useContext(SurveyNavigationContext);

	if (!question) return null;

	if (!isQuestionAnswered({
		question,
		answers: { [question?.questionId]: answer },
	})) {
		if (notAnswered) return notAnswered;

		return <i>{t('survey-question.not-answered')}</i>;
	}

	return (
		<Space
			direction='vertical'
			size={3}
			style={{ ...props?.style }}
		>
			{arrayWrap(convertQuestionAnswerValueToLabel(
				{ value: answer, question },
			))
				?.map((label, index) => (
					<Tag
						key={index}
						color='blue'
						style={{
							padding: '2px 8px',
							fontSize: 14,
							whiteSpace: 'normal',
							...props?.style?.tag,
						}}
					>
						<div>
							<b>{label?.[appLang] || label}</b>{question?.suffix?.[appLang] || question?.suffix}
						</div>
					</Tag>
				))
			}
		</Space>
	);
};

export default SurveyAnswer;
