import { useTheme } from '@emotion/react';
import { getCompanies, getExtraTags, getJobIds } from '../../../reducers/app';
import {
	addToFilterOptions, documentId, filterAutoCompleteItems, getQueryParams, openExternalUrl,
} from '../../../utils/common';
import {
	COMPANY_LIST_ICON,
	COMPANY_LIST_TITLE,
	GENDERSCORE_LABEL,
} from '../../../constants/constant';
import PageLayout from '../../../components/app/layout/PageLayout';
import { Helmet } from 'react-helmet';
import {
	Col, Collapse, Divider, Row, Space, Switch,
} from 'antd';
import FormItem from '../../../components/app/form/FormItem';
import FormCompanySelect from '../../../components/app/form/FormCompanySelect';
import FormSelect from '../../../components/app/form/FormSelect';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import JobService from '../../../services/jobs';
import CompanyTable from '../../../components/company/CompanyTable';
import {
	getAllCompanyPlansExtraTags, saveCompany, showCompanyEditor, updateOrCreateCompany,
} from '../../../actions/company';
import { useAuthContext } from '../../../hooks/useAuthContext';
import SectionTitle from '../../../components/app/SectionTitle';
import CompanyAddButton from '../../../components/company/addCompany/CompanyAddButton';
import CompanyModel from '../../../models/CompanyModel';
import { BUSINESS_PLAN_OPTIONS } from '../../../constants/property/business-plan';
import { filter } from 'lodash';
import StaffCompaniesCompanyListMenu from '../../../menu/staff/companies/StaffCompaniesCompanyListMenu';

const isPublishedOptions = [
	{ value: null, label: '' },
	{ value: true, label: 'Published' },
	{ value: false, label: 'Unpublished' },
];
// TODO: date selector
const isChurnedOptions = [
	{ value: true, label: 'Churned' },
	{ value: false, label: 'Not churned' },
];
const filters = {
	isPublished: 'isPublished',
	planValues: 'planValues',
	extraTags: 'extraTags',
	companyIds: 'companyIds',
	isChurned: 'isChurned',
};
const CompaniesPage = () => {
	const theme = useTheme();
	const dispatch = useDispatch();

	// selector
	const companies = useSelector(getCompanies);
	const jobIds = useSelector(getJobIds);
	const extraTags = useSelector(getExtraTags);

	// state

	const [openAddModal, setOpenAddModal] = useState();

	const { isEditor } = useAuthContext();

	// query params

	const queryParams = getQueryParams();

	// --------------------------------- //
	// ----------- state --------------- //
	// --------------------------------- //
	const [companyOptions, setCompanyOptions] = useState([]);
	const [showGSCols, setShowGSCols] = useState(true);
	const [isPublishedQueryParam, setIsPublishedQueryParam] = useState();
	const [isChurnedQueryParam, setIsChurnedQueryParam] = useState();
	const [businessPlanQueryParam, setBusinessPlanQueryParam] = useState();
	const [extraTagsQueryParam, setExtraTagsQueryParam] = useState();
	const [optionsFilterTable, setOptionsFilterTable] = useState({
		withAccess: true,
		withBatch: true,
		// sortField: 'publishedAt',
		// sortOrder: 'descend',
		...jobIds ? { jobIds } : {},
	});
	const [isRefreshDataLayout, setIsRefreshDataLayout] = useState(0);

	const getDefaultSelected = (fieldName) => {
		return queryParams[fieldName] || [];
	};

	useEffect(() => {
		setCompanyOptions([...companies]);
	}, [companies]);

	useEffect(() => {
		// Load filters from query params
		const queryIsPublished = queryParams?.[filters.isPublished];
		setIsPublishedQueryParam(queryIsPublished && queryIsPublished !== 'undefined'
			? JSON.parse(queryIsPublished) : undefined);
		const queryBusinessPlan = queryParams?.[filters.planValues];
		setBusinessPlanQueryParam(queryBusinessPlan && queryBusinessPlan.length > 0
			? queryBusinessPlan.split(',') : undefined);
		const queryExtraTags = queryParams?.[filters.extraTags];
		setExtraTagsQueryParam(queryExtraTags && queryExtraTags.length > 0
			? queryExtraTags.split(',') : undefined);
		setOptionsFilterTable((prev) => ({
			...prev,
			...queryParams,
		}));

		// Load companyPlansExtraTags
		dispatch(getAllCompanyPlansExtraTags());
	}, []);

	// ---------------------------------- //
	// -------- options filters --------- //
	// ---------------------------------- //
	const handleChangeCompany = (companyIds) => {
		addToFilterOptions(filters.companyIds, companyIds, setOptionsFilterTable);
	};
	// TODO: handle filter state in a single object and function
	const handleChangeIsPublishedFilter = (value) => {
		addToFilterOptions(filters.isPublished, value, setOptionsFilterTable);
	};
	const handleChangeIsChurnedFilter = (value) => {
		addToFilterOptions(filters.isChurned, value, setOptionsFilterTable);
	};

	const handleChangeBusinessPlanFilter = (value) => {
		addToFilterOptions(filters.planValues, value, setOptionsFilterTable);
	};

	const handleChangeBusinessPlanExtraTagsFilter = (value) => {
		addToFilterOptions(filters.extraTags, value, setOptionsFilterTable);
	};

	// ------------------------------------------- //
	// -------- search autocomplete data --------- //
	// ------------------------------------------- //
	const handleSearchAutoComplete = async (options) => {
		const { data: { jobs } } = await JobService.getAll(options);
		return filterAutoCompleteItems(jobs);
	};

	const onUpdateCompany = (data) => {
		data && dispatch(updateOrCreateCompany(data));
		setIsRefreshDataLayout((prev) => prev + 1);
	};

	const handleEditCompany = (row) => {
		dispatch(showCompanyEditor(documentId(row)));
	};

	const handlePreviewCompany = (row) => {
		if (row) openExternalUrl(new CompanyModel(row)?.getAppUri());
	};

	const onCompanyAdded = (values) => {
		// dispatch(saveCompany(values, true));
		// setOpenAddModal(false);
		setIsRefreshDataLayout((prev) => prev + 1);
	};

	return (
		<PageLayout
			MenuClass={StaffCompaniesCompanyListMenu}
			rightActions={(
				<CompanyAddButton onSuccess={onCompanyAdded} />
			)}
		>
			<Helmet>
				<meta charSet='utf-8' />
				<title>50inTech Admin - Companies</title>
				<link rel='canonical' href='http://admin.50intech.com/companies' />
			</Helmet>

			<Row style={{ marginBottom: 20 }}>
				<Col span={4}>
					<FormItem
						label='Company'
						defaultContainer='div'
						styles={{
							rootContainer: {
								display: 'flex', alignItems: 'center', justifyContent: 'space-between',
							},
							label: { marginBottom: 0, paddingRight: 10, minWidth: 100 },
							itemContainer: { marginBottom: 0 },
						}}
					>
						<FormCompanySelect
							options={companyOptions.map((c) => ({ value: documentId(c), label: c.name, item: c }))}
							onChange={handleChangeCompany}
							defaultValue={getDefaultSelected('companyIds')}
						/>
					</FormItem>
				</Col>
			</Row>
			<Row>
				<Col span={6}>
					<Switch
						checked={showGSCols}
						onChange={() => setShowGSCols((p) => !p)}
						id='gs-columns-toggle'
					/>
					<span style={{ marginLeft: 10 }}>
						Show
						<span style={{
							background: theme.color.lilacLightest3,
							padding: 5,
							fontWeight: 600,
						}}>
							{GENDERSCORE_LABEL}
						</span> columns
					</span>
				</Col>
			</Row>

			<Divider />
			<Collapse ghost style={{ maxWidth: '100%', marginBottom: 20 }}>
				<Collapse.Panel header={<b>Filters</b>} key='1'>
					<Space >

						<Row style={{ marginRight: 10 }}>
							<FormItem
								label='Churned'
								defaultContainer='div'
								styles={{
									rootContainer: {
										display: 'flex', alignItems: 'center', justifyContent: 'space-between',
									},
									label: {
										paddingRight: 10, fontSize: 13,
									},
									itemContainer: { marginBottom: 0 },
								}}
							>
								<div style={{ display: 'flex', justifyContent: 'space-between', margin: -2 }}>
									<FormSelect
										defaultValue={isChurnedQueryParam}
										options={isChurnedOptions}
										onChange={(value) => handleChangeIsChurnedFilter(value)}
										placeholder=''
										styles={{ minWidth: 150 }}
									/>
								</div>
							</FormItem>
						</Row>
						<Row style={{ marginRight: 10 }}>
							<FormItem
								label='Published'
								defaultContainer='div'
								styles={{
									rootContainer: {
										display: 'flex', alignItems: 'center', justifyContent: 'space-between',
									},
									label: {
										paddingRight: 10, fontSize: 13,
									},
									itemContainer: { marginBottom: 0 },
								}}
							>
								<div style={{ display: 'flex', justifyContent: 'space-between', margin: -2 }}>
									<FormSelect
										defaultValue={isPublishedQueryParam}
										options={isPublishedOptions}
										onChange={(value) => handleChangeIsPublishedFilter(value)}
										placeholder=''
										styles={{ minWidth: 150 }}
									/>
								</div>
							</FormItem>
						</Row>
						<Row style={{ marginRight: 10 }}>
							<FormItem
								label='Business Plan'
								defaultContainer='div'
								styles={{
									rootContainer: {
										display: 'flex', alignItems: 'center', justifyContent: 'space-between',
									},
									label: {
										paddingRight: 10, fontSize: 13,
									},
									itemContainer: { marginBottom: 0 },
								}}
							>
								<div style={{ display: 'flex', justifyContent: 'space-between', margin: -2 }}>
									<FormSelect
										defaultValue={businessPlanQueryParam}
										options={BUSINESS_PLAN_OPTIONS}
										onChange={(value) => handleChangeBusinessPlanFilter(value)}
										placeholder=''
										styles={{ minWidth: 150, maxWidth: 250 }}
										mode='multiple'
									/>
								</div>
							</FormItem>
						</Row>
						<Row style={{ marginRight: 10 }}>
							<FormItem
								label='Business Plan Extra Tags'
								defaultContainer='div'
								styles={{
									rootContainer: {
										display: 'flex', alignItems: 'center', justifyContent: 'space-between',
									},
									label: {
										paddingRight: 10, fontSize: 13,
									},
									itemContainer: { marginBottom: 0 },
								}}
							>
								<div style={{ display: 'flex', justifyContent: 'space-between', margin: -2 }}>
									<FormSelect
										defaultValue={extraTagsQueryParam}
										options={extraTags}
										onChange={(value) => handleChangeBusinessPlanExtraTagsFilter(value)}
										placeholder=''
										styles={{ minWidth: 150, maxWidth: 250, fontSize: 13 }}
										mode='tags'
									/>
								</div>
							</FormItem>
						</Row>
					</Space>
					{/* <Row style={{ maxWidth: '100%', marginBottom: '20px' }}>
						<Col style={{ marginRight: '20px' }}>
							<FormItem
								label='Published'
								defaultContainer='div'
								styles={{
									rootContainer: {
										display: 'flex', alignItems: 'center', justifyContent: 'space-between',
									},
									label: { marginBottom: 0, paddingRight: 10, minWidth: 100 },
									itemContainer: { marginBottom: 0 },
								}}
							>
								<div style={{ display: 'flex', justifyContent: 'space-between', margin: -2 }}>
									<FormSelect
										defaultValue={isPublishedQueryParam}
										options={isPublishedOptions}
										onChange={(value) => handleChangeIsPublishedFilter(value)}
										placeholder=''
										styles={{ minWidth: 150 }}
									/>
								</div>
							</FormItem>
						</Col>
						<Col>
							<FormItem
								label='Churned'
								defaultContainer='div'
								styles={{
									rootContainer: {
										display: 'flex', alignItems: 'center', justifyContent: 'space-between',
									},
									label: { marginBottom: 0, paddingRight: 10, minWidth: 100 },
									itemContainer: { marginBottom: 0 },
								}}
							>
								<div style={{ display: 'flex', justifyContent: 'space-between', margin: -2 }}>
									<FormSelect
										defaultValue={isChurnedQueryParam}
										options={isChurnedOptions}
										onChange={(value) => handleChangeIsChurnedFilter(value)}
										placeholder=''
										styles={{ minWidth: 150 }}
									/>
								</div>
							</FormItem>
						</Col>
					</Row>
					<Row style={{ maxWidth: '100%', marginBottom: '20px' }}>
						<Col style={{ marginRight: '20px' }}>
							<FormItem
								label='Business Plan'
								defaultContainer='div'
								styles={{
									rootContainer: {
										display: 'flex', alignItems: 'center', justifyContent: 'space-between',
									},
									label: { marginBottom: 0, paddingRight: 10, minWidth: 100 },
									itemContainer: { marginBottom: 0 },
								}}
							>
								<div style={{ display: 'flex', justifyContent: 'space-between', margin: -2 }}>
									<FormSelect
										defaultValue={businessPlanQueryParam}
										options={BUSINESS_PLAN_OPTIONS}
										onChange={(value) => handleChangeBusinessPlanFilter(value)}
										placeholder=''
										styles={{ minWidth: 250, maxWidth: 350 }}
										mode='multiple'
									/>
								</div>
							</FormItem>
						</Col>
						<Col>
							<FormItem
								label='Business Plan Extra Tags'
								defaultContainer='div'
								styles={{
									rootContainer: {
										display: 'flex', alignItems: 'center', justifyContent: 'space-between',
									},
									label: { marginBottom: 0, paddingRight: 10, minWidth: 150 },
									itemContainer: { marginBottom: 0 },
								}}
							>
								<div style={{ display: 'flex', justifyContent: 'space-between', margin: -2 }}>
									<FormSelect
										defaultValue={extraTagsQueryParam}
										options={extraTags}
										onChange={(value) => handleChangeBusinessPlanExtraTagsFilter(value)}
										placeholder=''
										styles={{ minWidth: 250, maxWidth: 350 }}
										mode='tags'
									/>
								</div>
							</FormItem>
						</Col>
					</Row> */}
				</Collapse.Panel>
			</Collapse>
			<Divider style={{ marginTop: 0 }} />
			<CompanyTable
				filterOptions={optionsFilterTable}
				onSearchDataColumn={handleSearchAutoComplete}
				isRefresh={isRefreshDataLayout}
				withDynamicSearch
				onUpdateCompany={onUpdateCompany}
				onEdit={handleEditCompany}
				onPreview={handlePreviewCompany}
				showGSCols={showGSCols}
			/>

		</PageLayout>
	);
};

export default CompaniesPage;
