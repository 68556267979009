import React, { useEffect, useState } from 'react';
import { LocalDebug } from '../../utils/LocalDebug';

export const UserMessageModalContext = React.createContext();

const UserMessageModalProvider = ({
	children,
}) => {
	const className = 'UserMessageModalProvider';

	const [open, setOpen] = useState(false);
	const [user, setUser] = useState();
	const [job, setJob] = useState();
	const [onOpen, setOnOpen] = useState();
	const [onClose, setOnClose] = useState();
	const [onStart, setOnStart] = useState();
	const [onComplete, setOnComplete] = useState();

	useEffect(() => {
		LocalDebug.logNull({ className, effects: 'open' }, { open });
		const apply = async () => {
			if (open) {
				await onOpen?.();
			} else {
				await onClose?.();
			}
		};
		apply();
	}, [open]);

	return (
		<UserMessageModalContext.Provider
			value={{
				open,
				setOpen,
				user,
				setUser,
				job,
				setJob,
				onOpen,
				setOnOpen,
				onClose,
				setOnClose,
				onStart,
				setOnStart,
				onComplete,
				setOnComplete,
			}}
		>
			{children}

		</UserMessageModalContext.Provider>
	);
};

export default UserMessageModalProvider;
