import { ENGLISH_LANG } from '../../../../constants/constant';
import CompanySelectEditor from '../shared/CompanySelectEditor';
import { useEffect, useState } from 'react';
import CompanyService from '../../../../services/company';
import { LocalDebug } from '../../../../utils/LocalDebug';
import { getTranslatedOptions } from '../../../../utils/common';

const CompanyIndustryEditor = (
	{
		company,
		lang = ENGLISH_LANG,
		setEditorOpen,
	},
) => {
	const className = 'CompanyIndustryEditor';

	const title = 'Industry';
	const field = 'industry';

	const [options, setOptions] = useState([]);

	useEffect(() => {
		const getOptions = async () => {
			const result = await CompanyService.getIndustryOptions();
			setOptions(getTranslatedOptions(result?.data?.options) || []);
		};
		getOptions();
	}, [lang]);

	return (
		<CompanySelectEditor
			isTranslatable={false}
			setEditorOpen={setEditorOpen}
			options={options}
			title={<b>{title}</b>}
			details={<span>
				Select the industry sectors in which your company operates
			</span>}
			company={company}
			field={field}
			mode="multiple"
		/>
	);
};

export default CompanyIndustryEditor;
