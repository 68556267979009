import { Typography } from 'antd';
import { ContactsOutlined } from '@ant-design/icons';
import { useTheme } from '@emotion/react';

const UserStubProfessional = ({ user, level = undefined, ...props }) => {
	const theme = useTheme();

	if (!(user?.information?.position || user?.information?.company)) {
		return null;
	}
	const content = <div style={{ ...props?.style }}>
		<ContactsOutlined style={{ color: theme.color.grey }} />
		&nbsp;{user.information.position} <span style={{ color: theme.color.grey }}>@</span>
		&nbsp;{user.information.company}
	</div>;

	return level
		? <Typography.Title level={level} style={{ marginBottom: 0 }}>
			{content}
		</Typography.Title>
		: <Typography style={{ lineHeight: 1.2, fontSize: 12, ...props?.style }}>
			{content}
		</Typography>;
};

export default UserStubProfessional;
