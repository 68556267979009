import Link from '../../../app/Link';
import GenderScoreLevelDetails from '../levels/GenderScoreLevelDetails';
import { useDrawer } from '../../../../hooks/useDrawer';
import { GENDERSCORE_LABEL } from '../../../../constants/constant';
import { ArrowRightIcon } from '../../../../utils/common';
import {
	GENDERSCORE_LOG_FROM_ELEMENT_LEARN_LEVELS_LINK,
	 writeGenderScoreFilledLog,
	 writeGenderScoreLog,
} from '../../../../constants/genderscore-logs';

const GenderScoreLevelDetailsLink = ({
	company,
	forceValue,
	label,
	context,
}) => {
	const className = 'GenderScoreLevelDetailsLink';

	const {
		setOpen: setOpenDrawer,
		setTitle: setDrawerTitle,
		setContent: setDrawerContent,
		setWidth: setDrawerWidth,
	} = useDrawer();

	const onOpenLeLevelDetails = (e) => {
		e?.stopPropagation?.();

		writeGenderScoreFilledLog({
			type: GENDERSCORE_LOG_FROM_ELEMENT_LEARN_LEVELS_LINK,
			context: context || label,
		});

		setDrawerTitle(
			<h2>The {GENDERSCORE_LABEL} Levels</h2>,
		);
		// 'Level Details');
		setDrawerContent(
			<GenderScoreLevelDetails
				company={company}
				forceValue={forceValue}
			/>,
		);
		setDrawerWidth(800);
		setOpenDrawer(true);
	};

	return (
		<Link onClick={onOpenLeLevelDetails}>
			{label || (<>
				Learn more about levels&nbsp;
				<ArrowRightIcon />
			</>)}
		</Link>
	);
};

export default GenderScoreLevelDetailsLink;
