import { useSelector } from 'react-redux';
import { getJobSelected } from '../../reducers/app';
import { useRef } from 'react';
import JobDetails from '../../components/jobs/JobDetails';

const JobPage = () => {
	const job = useSelector(getJobSelected);
	const container = useRef();

	return (<div className="withAffixedHead" ref={container}>
		{job && <JobDetails job={job} affixTop={48} />}
	</div>);
};

export default JobPage;
