import { useState } from 'react';
import { useTheme } from '@emotion/react';
import Link from '../../../app/Link';
import { NOT_PROVIDED_YET_LABEL } from '../../../../constants/constant';

const UserResumeSummaryCardBody = (
	{
		user,
		wordLimit = 60,
		notProvided = NOT_PROVIDED_YET_LABEL,
		...props
	},
) => {
	const theme = useTheme();
	const [isFullDisplay, setIsFullDisplay] = useState(false);

	const contentLength = user?.getResumeSummary()?.join(' ')?.split(' ').length;
	const fullContent = user?.hasResumeSummary()
		? user?.getResumeSummary()?.map(
			(text, index) => {
				return <p key={index}>
					{text}
				</p>;
			},
		)
		: notProvided;
	if (contentLength > wordLimit) {
		const limitedContent = <>
			<p>{user?.getResumeSummary()?.join(' ').split(' ').slice(0, wordLimit)
				.join(' ')} [...]</p>
			<Link onClick={() => { setIsFullDisplay(true); }}>See more</Link>
		</>;
		const fullContentWithLink = <>
			{fullContent}
			<Link onClick={() => { setIsFullDisplay(false); }}>See less</Link>
		</>;
		return (isFullDisplay ? fullContentWithLink : limitedContent);
	}
	return fullContent;
};

export default UserResumeSummaryCardBody;
