import { useState } from 'react';
import {
	Button,
	Row,
	Col,
	Divider,
	Form,
	Card,
} from 'antd';
import { useTheme } from '@emotion/react';
import { useSelector } from 'react-redux';
import { LocalDebug } from '../../utils/LocalDebug';
import { sourcingSources, STAGE_STARTED } from '../../constants/constant';
import FormItem, { FormItemContainer } from '../app/form/FormItem';
import FormSelect from '../app/form/FormSelect';
import { getCompanies, getCompanySelected } from '../../reducers/app';
import UserSelect from '../user/UserSelect';
import JobSelect from '../jobs/JobSelect';
import SourcingActionPopover from './SourcingActionPopover';
import moment from 'moment';
import { useAuthContext } from '../../hooks/useAuthContext';
import UserStub from '../user/stub/UserStub';
import FormCompanySelect from '../app/form/FormCompanySelect';
import { documentId, requiredRule } from '../../utils/common';

const UserAtsSenderForm = (
	{
		initialValues = {},
		withFormHistoryItems = true,
		viaShortlist = false,
		withJob = true,
		onSubmit,
		onReset,
	},
) => {
	const className = 'UserAtsSenderForm';
	const theme = useTheme();
	const companies = useSelector(getCompanies);
	const companySelected = useSelector(getCompanySelected);

	LocalDebug.logInfo({ className, method: 'constructor' }, { companies: companies?.map((c) => c?.name) });
	const { isTalent, isStaffView, currentUser } = useAuthContext();

	const viaProfile = initialValues?.viaProfile || false;
	const viaJob = initialValues?.viaJob || false;
	const defaultSource = initialValues?.source;

	// -------------------------------------------- //
	// -------------- state ----------------------- //
	// -------------------------------------------- //
	const [isActive] = useState(initialValues?.isActive !== false);

	// const [companyId, setCompanyId] = useState(initialValues?.companyId?.toString() || documentId(props?.company) || props?.job?.companyId);
	const [companyId, setCompanyId] = useState(initialValues?.companyId);
	const [jobId, setJobId] = useState();

	const [companyIdJob, setCompanyIdJob] = useState(initialValues?.companyId);
	const [companyIds, setCompanyIds] = useState(initialValues?.companyIds);

	// --------------------------------------- //
	// ------- state for steps --------------- //
	// --------------------------------------- //
	const [openStepPopover, setOpenStepPopover] = useState(false);
	const [initValuesStepForm, setInitValuesStepForm] = useState({});
	const [titleStepPopover, setTitleStepPopover] = useState('Change status');

	// default action sourcing infos
	const [actionSourcingInfos, setActionSourcingInfos] = useState({
		// status: NEW_STATUS,
		// updatedStatusAt: moment(),
		// step: DEFAULT_STEP_VALUE,
		// updatedStepAt: moment(),
		stage: STAGE_STARTED,
		updatedStageAt: moment(),
	});

	const [form] = Form.useForm();

	const handleSubmit = (values) => {
		const data = {
			creatorId: documentId(currentUser),
			source: defaultSource,
			companyId: companyIdJob,
			...values,
			isActive,
			id: documentId(initialValues) || null,
			...actionSourcingInfos,
		};
		onSubmit(data);
		form.resetFields();
	};

	const handleChangeJob = (value, option) => {
		setJobId(value);
		// set companyId vale
		setCompanyIdJob(option?.companyId);
	};

	const handleChangeCompany = (value) => {
		if (value) {
			setCompanyId(value);
			setCompanyIds([value]);
		} else {
			setCompanyId(null);
			setCompanyIds([]);
		}
		form.setFieldsValue({ jobId: null });
	};

	// ------------------------------------------------- //
	// ----- open pop add step (status or comment) ----- //
	// ------------------------------------------------- //
	const handleAddStep = (add, action, title) => {
		setOpenStepPopover(true);
		setInitValuesStepForm({
			add,
			action,
			author: documentId(currentUser),
			defaultUserOptions: currentUser,
		});
		// set title step popover
		setTitleStepPopover(title);
	};

	// -------------------------------------- //
	// -------- on submit step form --------- //
	// -------------------------------------- //
	const handleStepSubmit = (values) => {
		const {
			add,
			// status,
			// updatedStatusAt,
			// step,
			// updatedStepAt,
			...rest
		} = values;
		// add history in list
		rest.history.forEach((action) => add(action));
		// set status and step infos
		setActionSourcingInfos((prev) => ({
			...prev,
			// ...(status && {status, updatedStatusAt}),
			// ...(step && {step, updatedStepAt})
		}));
		setOpenStepPopover(false);
	};

	const UserFormItem = (
		{
			selectMode = true,
			disabled = false,
			...props
		},
	) => <FormItem
		label="Talent"
		name="userId"
		defaultContainer="div"
		rules={[requiredRule]}
	>
		{!selectMode
			? <Card
				size="small"
				style={{ borderRadius: 4, width: '100%' }}
			>
				<UserStub user={props?.user}/>
			</Card>
			: <UserSelect
				defaultOptions={props?.user ? [props?.user] : []}
				placeholder="Select a user"
				style={{ width: '100%' }}
				disabled={disabled}
				notUserIds={props?.notUserIds}
			/>
		}
	</FormItem>;

	const SourceFormItem = (
		{
			disabled = false,
			...props
		},
	) => <FormItem
		label="Sourcing method"
		defaultContainer="div"
		name="source"
		rules={[requiredRule]}
	>
		<FormSelect
			options={sourcingSources}
			placeholder="Sourcing method"
			defaultValue={props?.source}
			disabled={disabled}
		/>
	</FormItem>;

	const CompanyFormItem = (
		{
			disabled = false,
			...props
		},
	) => <FormItem
		label="Client"
		defaultContainer="div"
		name="companyId"
		rules={[requiredRule]}
	>
		<FormCompanySelect
			options={companies.map((c) => ({ value: documentId(c), label: c.name, item: c }))}
			onChange={handleChangeCompany}
			disabled={disabled}
			placeholder="Client"
			mode="single"
		/>
	</FormItem>;

	const JobFormItem = (
		{
			required = true,
			disabled = false,
			...props
		},
	) => <FormItem
		label="Job"
		defaultContainer="div"
		name="jobId"
		{...required && { rules: [requiredRule] }}
	>
		<JobSelect
			// defaultOptions={props?.job ? [props?.job]: []}
			defaultOptions={props?.jobs || (props?.job ? [props?.job] : [])}
			// defaultOptions={props?.jobs || []}
			value={jobId}
			placeholder="Select a job"
			onChange={handleChangeJob}
			style={{ width: '100%' }}
			disabled={!(props?.companyId?.toString() || documentId(props?.company) || props?.job?.companyId) || disabled}
			companyIds={documentId(companySelected) ? [documentId(companySelected)] : companyIds}
			notJobIds={props?.notJobIds}
		/>
	</FormItem>;

	return (
		<div>
			<Form
				form={form}
				name="alert_form"
				initialValues={{ ...initialValues }}
				onFinish={handleSubmit}
			>
				<Row>
					<Col span={24}>
						<FormItemContainer>
							<UserFormItem
								selectMode={!viaProfile}
								disabled={viaProfile}
								{...initialValues}
							/>
							{((isTalent && isStaffView) || (!viaProfile && !viaJob))
								&& <SourceFormItem
									disabled={!(isTalent && isStaffView) && (viaProfile || viaJob)}
									{...initialValues}
								/>
							}
							{((isTalent && isStaffView) || (!viaProfile && !viaJob))
								&& <CompanyFormItem
									disabled={viaJob}
									{...initialValues}
									companyId={companyId}
								/>
							}
							{withJob && <FormItem
								label="Job"
								defaultContainer="div"
								name="jobId"
								{...(viaProfile || viaJob) && {
									rules: [requiredRule],
								}}
							>
								<JobSelect
									defaultOptions={initialValues?.job ? [initialValues?.job] : []}
									value={jobId}
									placeholder="Select a job"
									onChange={handleChangeJob}
									style={{ width: '100%' }}
									disabled={!companyId && !viaProfile}
									companyIds={documentId(companySelected) ? [documentId(companySelected)] : companyIds}
									notJobIds={initialValues?.notJobIds}
								/>
							</FormItem>
							}
						</FormItemContainer>
					</Col>
				</Row>

				<Divider/>

				<Row>
					<Col span={24}>
						<div style={{
							display: 'flex',
							justifyContent: 'space-between',
							margin: -5,
							width: 'calc(100% + 10px)',
						}}>
							<Button type="default" style={{
								margin: 5,
								borderColor: theme.color.orange,
								fontWeight: 'bold',
								borderRadius: 5,
								color: theme.color.orange,
							}}
							htmlType="reset"
							onClick={onReset}
							>
								Cancel
							</Button>
							<Button type="primary"
								style={{
									margin: 5,
									background: theme.color.blue,
									borderColor: theme.color.blue,
									fontWeight: 'bold',
									borderRadius: 5,
								}}
								htmlType="submit"
							>
								Add
							</Button>
						</div>
					</Col>
				</Row>

			</Form>

			<SourcingActionPopover
				open={openStepPopover}
				initialValues={initValuesStepForm}
				onSubmit={handleStepSubmit}
				onReset={() => setOpenStepPopover(false)}
				title={titleStepPopover}
			/>

		</div>
	);
};

export default UserAtsSenderForm;
