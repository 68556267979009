import { Divider, Space, Tag } from 'antd';
import ReactMarkdown from 'react-markdown';
import {
	arrayWrap,
	mergeReducer, mixColors, numToArray, shuffleArr, sortOn,
} from '../../../utils/common';
import { round } from 'lodash';
import { CaretDownFilled } from '@ant-design/icons';
import { LocalDebug } from '../../../utils/LocalDebug';
import ReactJsonDebug from '../../app/debug/ReactJsonDebug';

const mainClassName = 'biases';

export const BIAS_SELF_FULLFILLING_PROPHECY_ID = 'BIAS_SELF_FULLFILLING_PROPHECY';
export const BIAS_SELF_FULLFILLING_PROPHECY_SHORT_ID = 'sffp';
export const BIAS_BACKLASH_EFFECT_ID = 'BIAS_BACKLASH_EFFECT';
export const BIAS_BACKLASH_EFFECT_SHORT_ID = 'ble';
export const BIAS_RECENCY_BIAS_ID = 'BIAS_RECENCY_BIAS';
export const BIAS_RECENCY_BIAS_SHORT_ID = 'rec';
export const BIAS_HALO_HORNS_EFFECT_ID = 'BIAS_HALO_HORNS_EFFECT';
export const BIAS_HALO_HORNS_EFFECT_SHORT_ID = 'hhe';
export const BIAS_IN_GROUP_EFFECT_ID = 'BIAS_IN_GROUP_EFFECT';
export const BIAS_IN_GROUP_EFFECT_SHORT_ID = 'igf';
export const BIAS_OVERLY_CRITICAL_ID = 'BIAS_OVERLY_CRITICAL';
export const BIAS_OVERLY_CRITICAL_SHORT_ID = 'ovc';
export const BIAS_CONTRAST_EFFECT_ID = 'BIAS_CONTRAST_EFFECT';
export const BIAS_CONTRAST_EFFECT_SHORT_ID = 'cte';

export const BIAS_SELF_FULLFILLING_PROPHECY_DESC_EN = 'The manager will behave according to his/her expectations, which may lead the employee to modify his/her behaviour and answers and confirm the evaluator\'s initial expectations. This can then affect the employee\'s evaluation, and subsequently impact the allocation of responsibilities, projects, etc.';
export const BIAS_SELF_FULLFILLING_PROPHECY_SHORT_DESC_EN = 'Employees modifying their behaviour to match their manager\'s expectations';
export const BIAS_SELF_FULLFILLING_PROPHECY_EXAMPLES_EN = [
	'Unconsciously, a manager might adhere to the stereotype that women are less competent in computer science than men. Consequently, the manager may handle a woman employee with lower expectations, assigning easier tasks, granting less autonomy, and believing she is less capable. This can diminish the employee\'s confidence and lead to lower performance, confirming the manager\'s initial expectations.',
	'When a manager or colleague communicates condescendingly or negatively towards a woman employee, it can influence the employee\'s confidence and self-esteem, thereby affecting their performance and confirming the initial negative expectations.',
	'If a woman employee announces her pregnancy and the manager presumes that she will not be as dedicated to her work or committed to her career upon returning from maternity leave, it might inadvertently influence promotion decisions and the distribution of responsibilities. Consequently, the woman may have fewer responsibilities and professional opportunities, reinforcing the idea that motherhood diminishes professional commitment.',
];

export const BIAS_BACKLASH_EFFECT_DESC_EN = 'It refers to social and economic sanctions following a counter-stereotypical behaviour (such as in salary, promotion, exclusion, etc.).';
export const BIAS_BACKLASH_EFFECT_SHORT_DESC_EN = 'Social and economic sanctions following a counter-stereotypical behaviour';
export const BIAS_BACKLASH_EFFECT_EXAMPLES_EN = [
	'A woman negotiating her salary may be perceived as "too ambitious" or "too demanding," while a man doing the same is often seen as "assertive" or "confident," reducing her chances of a salary increase.',
	'A woman leader displaying strong authority might be labelled as "domineering" or "tough," whereas a man exhibiting a similar behaviour is often considered "decisive" or "strong," contributing to a negative perception of woman leaders.',
	'A woman expressing firm or critical opinions might be seen as "aggressive" or "negative," whereas a man in a similar situation is often viewed as "assertive" or "confident," potentially resulting in less consideration for her ideas and remarks.',
];

const BIAS_HALO_HORNS_EFFECT_DESC_EN = 'This phenomenon encompasses the tendency to overemphasise or diminish an employee\'s overall performance based on a singular success or failure, often influenced by initial impressions.';
const BIAS_HALO_HORNS_EFFECT_SHORT_DESC_EN = 'Overemphasise or diminish overall performance based on a singular success or failure.';
const BIAS_HALO_HORNS_EFFECT_EXAMPLES_EN = [
	'Men are frequently associated with stereotypes of competence and leadership. Consequently, if a man aligns with these stereotypes during a first impression, there\'s a tendency for an exaggeratedly positive perception of his professional skills.',
	'Women are often perceived as more communicative and empathetic.Should a woman not immediately display these traits in initial interactions, there\'s a risk of undervaluing her communication and relational skills.',
];

const BIAS_IN_GROUP_EFFECT_DESC_EN = 'It refers to the preference for individuals who share similarities with oneself over those belonging to an out-group. This bias can significantly influence hiring decisions and workplace dynamics.';
const BIAS_IN_GROUP_EFFECT_SHORT_DESC_EN = 'Preference for individuals who share similarities with oneself';
const BIAS_IN_GROUP_EFFECT_EXAMPLES_EN = [
	'A manager, unconsciously, might be inclined to promote more individuals of their gender, subconsciously considering them to be more competent.',
	'A manager may tend to delegate important tasks to members of their gender, assuming they are more capable or reliable to accomplish them.',
	'There might be a bias in giving positive feedback or recognition within the team, where members of the same gender as the manager could be favored, receiving more compliments or encouragement.',
];
const BIAS_OVERLY_CRITICAL_DESC_EN = 'It involves having excessively high expectations and harshly judging even minor mistakes. This tendency may lead to more criticism of certain individuals, particularly women, for things for which men might receive less criticism. It can result in harsher consequences for women compared to men for similar mistakes.';
const BIAS_OVERLY_CRITICAL_SHORT_DESC_EN = 'High expectations and harshly judging even minor mistakes.';
const BIAS_OVERLY_CRITICAL_EXAMPLES_EN = [
	'A woman leader might receive exaggerated critiques about her leadership style (too authoritarian, too passive) whereas her male counterparts might face less severe criticism for similar behaviours.',
	'A woman making a mistake might be stripped of important responsibilities more quickly than a man in a similar situation due to a tendency to be more severely criticised.',
];

const BIAS_RECENCY_BIAS_DESC_EN = 'It refers to our tendency to give more weight to recent events compared to those that happened further in the past. It\'s important to consider all aspects throughout the entire year rather than just focusing on the last few months.';
const BIAS_RECENCY_BIAS_SHORT_DESC_EN = 'Tendency to give more weight to recent events';
const BIAS_RECENCY_BIAS_EXAMPLES_EN = [
	'Recent errors made by a woman might be emphasised more, negatively impacting the perception of her overall competence.At the same time, a man could have his errors downplayed due to past successes.',
	'A woman returning after maternity leave might have her recent performance disproportionately weighed when evaluating her current abilities.',
	'Following a period of mixed results, a woman leader might have her leadership potential questioned without considering her overall skill set, influenced by the expectation that a woman is not suitable for a leadership role, which might not be the case for a man.',
];

const BIAS_CONTRAST_EFFECT_DESC_EN = 'This bias involves assessing employees by comparing them with each other. It\'s important to evaluate employee performance based on job requirements rather than comparing them against each other.';
const BIAS_CONTRAST_EFFECT_SHORT_DESC_EN = 'Assessing employees by comparing them with each other';
const BIAS_CONTRAST_EFFECT_EXAMPLES_EN = [
	'If a woman leader is initially perceived as less directive or less communicative, a subsequent male leader might seem much more directive or communicative, even with similar behaviour, reinforced by gender stereotypes.',
	'After assigning a task to a woman and then the same task to a man, the task assigned to the man might be perceived as more important or complex, leading to a perception of the man\'s skills being superior to those of the woman.',
];

export const BIAS_SELF_FULLFILLING_PROPHECY_DESC_FR = 'Le manager se comportera selon ses attentes, ce qui peut amener l\'employé à modifier son comportement et ses réponses et confirmer les attentes initiales de l\'évaluateur. Cela peut ensuite affecter l\'évaluation de l\'employé, et par la suite impacter l\'allocation des responsabilités, des projets, etc.';
export const BIAS_SELF_FULLFILLING_PROPHECY_SHORT_DESC_FR = 'Employés modifiant leur comportement pour correspondre aux attentes de leur manager';
export const BIAS_SELF_FULLFILLING_PROPHECY_EXAMPLES_FR = [
	'Sans en avoir conscience, un manager peut adhérer au stéréotype que les femmes sont moins compétentes en informatique que les hommes. Par conséquent, le manager peut avoir des attentes plus basses pour une employée, lui assignant des tâches plus faciles, lui accordant moins d\'autonomie, et croyant qu\'elle est moins capable. Cela peut diminuer la confiance de l\'employée et conduire à une performance inférieure, confirmant les attentes initiales du manager.',
	'Lorsqu\'un manager ou un collègue communique de manière condescendante ou négative envers une employée, cela peut influencer la confiance et l\'estime de soi de l\'employée, affectant ainsi sa performance et confirmant les attentes négatives initiales.',
	'Si une employée annonce sa grossesse et que le manager présume qu\'elle ne sera pas aussi dédiée à son travail ou engagée dans sa carrière après son retour de congé maternité, cela pourrait influencer par inadvertance les décisions de promotion et la répartition des responsabilités. Par conséquent, la femme peut avoir moins de responsabilités et d\'opportunités professionnelles, renforçant l\'idée que la maternité diminue l\'engagement professionnel.',
];

export const BIAS_BACKLASH_EFFECT_DESC_FR = 'Il fait référence aux sanctions sociales et économiques suivant un comportement contre-stéréotypique (telles que dans le salaire, la promotion, l\'exclusion, etc.).';
export const BIAS_BACKLASH_EFFECT_SHORT_DESC_FR = 'Sanctions sociales et économiques suivant un comportement contre-stéréotypique';
export const BIAS_BACKLASH_EFFECT_EXAMPLES_FR = [
	'Une femme négociant son salaire peut être perçue comme "trop ambitieuse" ou "trop exigeante", tandis qu\'un homme faisant de même est souvent vu comme "assertif" ou "confiant", réduisant ses chances d\'une augmentation de salaire.',
	'Une femme leader affichant une forte autorité pourrait être étiquetée comme "dominatrice" ou "dure", tandis qu\'un homme exhibant un comportement similaire est souvent considéré comme "décisif" ou "fort", contribuant à une perception négative des leaders féminins.',
	'Une femme exprimant des opinions fermes ou critiques pourrait être vue comme "agressive" ou "négative", tandis qu\'un homme dans une situation similaire est souvent perçu comme "assertif" ou "confiant", pouvant entraîner une moindre considération pour ses idées et remarques.',
];

export const BIAS_HALO_HORNS_EFFECT_DESC_FR = 'Ce phénomène englobe la tendance à surestimer ou minimiser la performance globale d\'un employé basée sur un succès ou un échec unique, souvent influencé par les premières impressions.';
export const BIAS_HALO_HORNS_EFFECT_SHORT_DESC_FR = 'Surestimer ou minimiser la performance globale basée sur un succès ou un échec unique.';
export const BIAS_HALO_HORNS_EFFECT_EXAMPLES_FR = [
	'Les hommes sont fréquemment associés aux stéréotypes de compétence et de leadership. Par conséquent, si un homme correspond à ces stéréotypes lors d\'une première impression, il y a une tendance à une perception exagérément positive de ses compétences professionnelles.',
	'Les femmes sont souvent perçues comme plus communicatives et empathiques. Si une femme n\'affiche pas immédiatement ces traits lors des interactions initiales, il y a un risque de sous-évaluation de ses compétences en communication et relationnelles.',
];

export const BIAS_IN_GROUP_EFFECT_DESC_FR = 'Il fait référence à la préférence pour les individus qui partagent des similitudes avec soi-même par rapport à ceux appartenant à un groupe externe. Ce biais peut influencer significativement les décisions d\'embauche et la dynamique de travail.';
export const BIAS_IN_GROUP_EFFECT_SHORT_DESC_FR = 'Préférence pour les individus qui partagent des similitudes avec soi-même';
export const BIAS_IN_GROUP_EFFECT_EXAMPLES_FR = [
	'Un manager, inconsciemment, pourrait être enclin à promouvoir davantage d\'individus de son genre, considérant subconsciemment qu\'ils sont plus compétents.',
	'Un manager peut tendre à déléguer des tâches importantes aux membres de son genre, supposant qu\'ils sont plus capables ou fiables pour les accomplir.',
	'Il pourrait y avoir un biais dans l\'attribution de retours positifs ou de reconnaissance au sein de l\'équipe, où les membres du même genre que le manager pourraient être favorisés, recevant plus de compliments ou d\'encouragements.',
];
export const BIAS_OVERLY_CRITICAL_DESC_FR = 'Il implique d\'avoir des attentes excessivement élevées et de juger sévèrement même les petites erreurs. Cette tendance peut conduire à plus de critiques envers certains individus, particulièrement les femmes, pour des choses pour lesquelles les hommes pourraient recevoir moins de critiques. Cela peut entraîner des conséquences plus sévères pour les femmes par rapport aux hommes pour des erreurs similaires.';
export const BIAS_OVERLY_CRITICAL_SHORT_DESC_FR = 'Attentes élevées et jugement sévère même pour des erreurs mineures.';
export const BIAS_OVERLY_CRITICAL_EXAMPLES_FR = [
	'Une femme leader pourrait recevoir des critiques exagérées sur son style de leadership (trop autoritaire, trop passive) tandis que ses homologues masculins pourraient faire face à moins de critiques pour des comportements similaires.',
	'Une femme commettant une erreur pourrait être privée de responsabilités importantes plus rapidement qu\'un homme dans une situation similaire en raison d\'une tendance à être plus sévèrement critiquée.',
];

export const BIAS_RECENCY_BIAS_DESC_FR = 'Il fait référence à notre tendance à donner plus de poids aux événements récents par rapport à ceux qui se sont produits plus tôt dans le passé. Il est important de considérer tous les aspects tout au long de l\'année plutôt que de se concentrer uniquement sur les derniers mois.';
export const BIAS_RECENCY_BIAS_SHORT_DESC_FR = 'Tendance à donner plus de poids aux événements récents';
export const BIAS_RECENCY_BIAS_EXAMPLES_FR = [
	'Les erreurs récentes commises par une femme pourraient être plus soulignées, impactant négativement la perception de sa compétence globale. En même temps, les erreurs d\'un homme pourraient être minimisées en raison de succès passés.',
	'Une femme revenant de congé de maternité pourrait voir ses performances récentes peser disproportionnellement lors de l\'évaluation de ses capacités actuelles.',
	'Suite à une période de résultats mitigés, le potentiel de leadership d\'une femme leader pourrait être remis en question sans considérer son ensemble de compétences, influencé par l\'attente qu\'une femme n\'est pas adaptée pour un rôle de leadership, ce qui pourrait ne pas être le cas pour un homme.',
];

export const BIAS_CONTRAST_EFFECT_DESC_FR = 'Ce biais implique d\'évaluer les employés en les comparant les uns aux autres. Il est important d\'évaluer la performance des employés en fonction des exigences du poste plutôt que de les comparer entre eux.';
export const BIAS_CONTRAST_EFFECT_SHORT_DESC_FR = 'Évaluer les employés en les comparant les uns aux autres';
export const BIAS_CONTRAST_EFFECT_EXAMPLES_FR = [
	'Si une femme leader est initialement perçue comme moins directive ou moins communicative, un leader masculin subséquent pourrait sembler beaucoup plus directive ou communicative, même avec un comportement similaire, renforcé par les stéréotypes de genre.',
	'Après avoir attribué une tâche à une femme puis la même tâche à un homme, la tâche assignée à l\'homme pourrait être perçue comme plus importante ou complexe, menant à une perception que les compétences de l\'homme sont supérieures à celles de la femme.',
];

export const BIAS_SELF_FULLFILLING_PROPHECY_OPTION = {
	id: BIAS_SELF_FULLFILLING_PROPHECY_ID,
	shortId: BIAS_SELF_FULLFILLING_PROPHECY_SHORT_ID,
	label: 'Self-Fullfilling Prophecy',
	radarLabel: <>Self-Fullfilling<br/>Prophecy</>,
	desc: {
		en: BIAS_SELF_FULLFILLING_PROPHECY_DESC_EN,
		fr: BIAS_SELF_FULLFILLING_PROPHECY_DESC_FR,
	},
	shortDesc: {
		en: BIAS_SELF_FULLFILLING_PROPHECY_SHORT_DESC_EN,
		fr: BIAS_SELF_FULLFILLING_PROPHECY_SHORT_DESC_FR,
	},
	examples: {
		en: BIAS_SELF_FULLFILLING_PROPHECY_EXAMPLES_EN,
		fr: BIAS_SELF_FULLFILLING_PROPHECY_EXAMPLES_FR,
	},
	styles: {
		radar: {
			textAnchor: 'middle', verticalAnchor: 'end', dy: -10, width: 140,
		},
		radarTag: {
			width: 76, tx: 0, ty: 10, textAlign: 'center', popoverPlacement: 'right',
		},
	},
};
export const BIAS_BACKLASH_EFFECT_OPTION = {
	id: BIAS_BACKLASH_EFFECT_ID,
	shortId: BIAS_BACKLASH_EFFECT_SHORT_ID,
	label: 'Backlash Effect',
	radarLabel: <>Backlash<br/>Effect</>,
	desc: {
		en: BIAS_BACKLASH_EFFECT_DESC_EN,
		fr: BIAS_BACKLASH_EFFECT_DESC_FR,
	},
	shortDesc: {
		en: BIAS_BACKLASH_EFFECT_SHORT_DESC_EN,
		fr: BIAS_BACKLASH_EFFECT_SHORT_DESC_FR,
	},
	examples: {
		en: BIAS_BACKLASH_EFFECT_EXAMPLES_EN,
		fr: BIAS_BACKLASH_EFFECT_EXAMPLES_FR,
	},
	styles: {
		radar: {
			textAnchor: 'start', verticalAnchor: 'middle', width: 100, dx: 10,
		},
		radarTag: {
			width: 50, tx: 10, ty: 6, textAlign: 'left', popoverPlacement: 'topLeft',
		},
	},
};
export const BIAS_RECENCY_BIAS_OPTION = {
	id: BIAS_RECENCY_BIAS_ID,
	shortId: BIAS_RECENCY_BIAS_SHORT_ID,
	label: 'Recency Bias',
	radarLabel: <>Recency<br/>Bias</>,
	desc: {
		en: BIAS_RECENCY_BIAS_DESC_EN,
		fr: BIAS_RECENCY_BIAS_DESC_FR,
	},
	shortDesc: {
		en: BIAS_RECENCY_BIAS_SHORT_DESC_EN,
		fr: BIAS_RECENCY_BIAS_SHORT_DESC_FR,
	},
	examples: {
		en: BIAS_RECENCY_BIAS_EXAMPLES_EN,
		fr: BIAS_RECENCY_BIAS_EXAMPLES_FR,
	},
	styles: {
		radar: {
			textAnchor: 'start', verticalAnchor: 'middle', width: 90, w: 63, dx: 10,
		},
		radarTag: {
			width: 50, tx: 6, ty: 0, textAlign: 'left', popoverPlacement: 'topLeft',
		},
	},
};
export const BIAS_HALO_HORNS_EFFECT_OPTION = {
	id: BIAS_HALO_HORNS_EFFECT_ID,
	shortId: BIAS_HALO_HORNS_EFFECT_SHORT_ID,
	label: 'Halo Effect / Horns Effect',
	label2: 'Halo/Horns Effect',
	radarLabel: <>Halo / Horns<br/>Effect</>,
	desc: {
		en: BIAS_HALO_HORNS_EFFECT_DESC_EN,
		fr: BIAS_HALO_HORNS_EFFECT_DESC_FR,
	},
	shortDesc: {
		en: BIAS_HALO_HORNS_EFFECT_SHORT_DESC_EN,
		fr: BIAS_HALO_HORNS_EFFECT_SHORT_DESC_FR,
	},
	examples: {
		en: BIAS_HALO_HORNS_EFFECT_EXAMPLES_EN,
		fr: BIAS_HALO_HORNS_EFFECT_EXAMPLES_FR,
	},
	styles: {
		radar: {
			textAnchor: 'middle', verticalAnchor: 'start', width: 100, dy: 10,
		},
		radarTag: {
			width: 66, tx: 20, ty: -8, textAlign: 'center', popoverPlacement: 'right',
		},
	},
};
export const BIAS_IN_GROUP_EFFECT_OPTION = {
	id: BIAS_IN_GROUP_EFFECT_ID,
	shortId: BIAS_IN_GROUP_EFFECT_SHORT_ID,
	label: 'In-group Favoritism',
	radarLabel: <>In-group<br/>Favoritism</>,
	desc: {
		en: BIAS_IN_GROUP_EFFECT_DESC_EN,
		fr: BIAS_IN_GROUP_EFFECT_DESC_FR,
	},
	shortDesc: {
		en: BIAS_IN_GROUP_EFFECT_SHORT_DESC_EN,
		fr: BIAS_IN_GROUP_EFFECT_SHORT_DESC_FR,
	},
	examples: {
		en: BIAS_IN_GROUP_EFFECT_EXAMPLES_EN,
		fr: BIAS_IN_GROUP_EFFECT_EXAMPLES_FR,
	},
	styles: {
		radar: {
			textAnchor: 'middle', verticalAnchor: 'start', width: 100, dy: 10,
		},
		radarTag: {
			width: 50, tx: -20, ty: -8, textAlign: 'center', popoverPlacement: 'left',
		},
	},
};
export const BIAS_OVERLY_CRITICAL_OPTION = {
	id: BIAS_OVERLY_CRITICAL_ID,
	shortId: BIAS_OVERLY_CRITICAL_SHORT_ID,
	label: 'Overly Critical',
	radarLabel: <>Overly<br/>Critical</>,
	desc: {
		en: BIAS_OVERLY_CRITICAL_DESC_EN,
		fr: BIAS_OVERLY_CRITICAL_DESC_FR,
	},
	shortDesc: {
		en: BIAS_OVERLY_CRITICAL_SHORT_DESC_EN,
		fr: BIAS_OVERLY_CRITICAL_SHORT_DESC_FR,
	},
	examples: {
		en: BIAS_OVERLY_CRITICAL_EXAMPLES_EN,
		fr: BIAS_OVERLY_CRITICAL_EXAMPLES_FR,
	},
	styles: {
		radar: {
			textAnchor: 'end', verticalAnchor: 'middle', width: 80, dx: -10,
		},
		radarTag: {
			width: 40, tx: 0, ty: 0, textAlign: 'right', popoverPlacement: 'left',
		},
	},
};
export const BIAS_CONTRAST_EFFECT_OPTION = {
	id: BIAS_CONTRAST_EFFECT_ID,
	shortId: BIAS_CONTRAST_EFFECT_SHORT_ID,
	label: 'Contrast Effect',
	radarLabel: <>Contrast<br/>Effect</>,
	desc: {
		en: BIAS_CONTRAST_EFFECT_DESC_EN,
		fr: BIAS_CONTRAST_EFFECT_DESC_FR,
	},
	shortDesc: {
		en: BIAS_CONTRAST_EFFECT_SHORT_DESC_EN,
		fr: BIAS_CONTRAST_EFFECT_SHORT_DESC_FR,
	},
	examples: {
		en: BIAS_CONTRAST_EFFECT_EXAMPLES_EN,
		fr: BIAS_CONTRAST_EFFECT_EXAMPLES_FR,
	},
	styles: {
		radar: {
			textAnchor: 'end', verticalAnchor: 'middle', width: 100, dx: -10,
		},
		radarTag: {
			width: 48, tx: -10, ty: 6, textAlign: 'right', popoverPlacement: 'topRight',
		},
	},
};

export const BIAS_OPTIONS = [
	BIAS_SELF_FULLFILLING_PROPHECY_OPTION,
	BIAS_BACKLASH_EFFECT_OPTION,
	BIAS_RECENCY_BIAS_OPTION,
	BIAS_HALO_HORNS_EFFECT_OPTION,
	BIAS_IN_GROUP_EFFECT_OPTION,
	BIAS_OVERLY_CRITICAL_OPTION,
	BIAS_CONTRAST_EFFECT_OPTION,
];

export const BIAS_DEFAULT = BIAS_SELF_FULLFILLING_PROPHECY_OPTION;

export const optionFinder = ({ options, defaultOption }) => (value) => {
	const id = (value?.id || value?.shortId || value || '')?.toString?.()?.toLowerCase?.();
	// if (value === 'employees') {
	// 	console.log('getBiasRadarData', value, id, options, options?.map((o) => o?.shortId));
	// }

	if (!id) return defaultOption;
	return options
		.find((o) => {
			const ids = [o?.id, o?.shortId, o?.label, o?.alias].map((s) => s?.toString?.()?.toLowerCase?.());
			return ids.includes(id);
		}) || defaultOption;
};

export const findBias = optionFinder({ options: BIAS_OPTIONS, defaultOption: BIAS_DEFAULT });

export const getBiasContent = ({
	bias,
	withDesc = true,
	withExamples = false,
}) => {
	bias = findBias(bias);
	return (
		<ReactMarkdown>
			{[
				...withDesc ? [bias.desc] : [],
				...withExamples
					? [['**Some examples**', ...bias.examples].join('\n- ')]
					: [],
			].join('\n\n')}
		</ReactMarkdown>
	);
};

export const BIAS_LEVEL_LOW_ID = 'BIAS_LEVEL_LOW';
export const BIAS_LEVEL_LOW_SHORT_ID = 'low';
export const BIAS_LEVEL_MODERATE_ID = 'BIAS_LEVEL_MODERATE';
export const BIAS_LEVEL_MODERATE_SHORT_ID = 'moderate';
export const BIAS_LEVEL_HIGH_ID = 'BIAS_LEVEL_HIGH';
export const BIAS_LEVEL_HIGH_SHORT_ID = 'high';

export const BIAS_LEVEL_LOW = {
	id: BIAS_LEVEL_LOW_ID,
	shortId: BIAS_LEVEL_LOW_SHORT_ID,
	min: 0,
	max: 29,
	label: {
		en: 'Low bias',
		fr: 'Biais faible',
	},
	adjective: {
		en: 'Low',
		fr: 'Faible',
	},
	details: {
		en: `You appear to exhibit a low level of bias, meaning you seem to demonstrate an ability to assess and interact with your employees objectively and without bias. 
Keep up the vigilance and engagement for an unbiased management!`,
		fr: `Vous semblez présenter un faible niveau de biais, ce qui signifie que vous démontrez une capacité à évaluer et à interagir avec vos employés de manière objective et sans parti pris.
Continuez à faire preuve de vigilance et d'engagement pour une gestion sans biais !`,
	},
	tag: {
		// color: 'green',
		background: mixColors('#e6346b', '#ffffff', 80),
		color: mixColors('#e6346b', '#ffffff', 20),
	},
	styles: {
		dot: {
			background: '#6c6',
			size: 2,
		},
		text: {
			color: '#6c6',
			color: '#aaa',
		},
	},
};
export const BIAS_LEVEL_MODERATE = {
	id: BIAS_LEVEL_MODERATE_ID,
	shortId: BIAS_LEVEL_MODERATE_SHORT_ID,
	min: 30,
	max: 69,
	label: {
		en: 'Moderate bias',
		fr: 'Biais modéré',
	},
	adjective: {
		en: 'Moderate',
		fr: 'Moderate',
	},
	details: {
		en: `You appear to exhibit a moderate level of bias. 
While you demonstrate some ability to assess and interact with your employees objectively, there is still room for improvement in reducing biases. 
You are on the right track! Stay even more vigilant to progress towards unbiased management!`,
		fr: `Vous semblez présenter un niveau de biais modéré.
Bien que vous démontriez une certaine capacité à évaluer et à interagir objectivement avec vos employés, il reste encore des améliorations à apporter pour réduire les biais.
Vous êtes sur la bonne voie ! Restez encore plus vigilant pour progresser vers une gestion sans biais !`,
	},
	tag: {
		// color: 'gold',
		background: mixColors('#e6346b', '#ffffff', 40),
		color: 'white',
	},
	styles: {
		dot: {
			background: 'orange',
			size: 4,
		},
		text: {
			color: '#cc6600',
			color: '#855',
		},
	},
};
export const BIAS_LEVEL_HIGH = {
	id: BIAS_LEVEL_HIGH_ID,
	shortId: BIAS_LEVEL_HIGH_SHORT_ID,
	min: 70,
	max: 100,
	label: {
		en: 'High bias',
		fr: 'Biais fort',
	},
	adjective: {
		en: 'High',
		fr: 'Fort',
	},
	details: {
		en: `You appear to exhibit a high level of bias. 
It's important to note that everyone has biases, and the goal is to recognize and work to mitigate them, which is achievable for everyone!
You still have a lot of work to do in reducing your biases to promote inclusive management and a supportive environment for your employees.`,
		fr: `Vous semblez présenter un niveau élevé de biais.
Il est important de noter que tout le monde a des biais, et l'objectif est de les reconnaître et de travailler à les atténuer, ce qui est réalisable pour chacun !
Vous avez encore beaucoup de travail à faire pour réduire vos biais afin de promouvoir une gestion inclusive et un environnement de soutien pour vos employés.`,
	},
	tag: {
		// color: 'volcano',
		background: mixColors('#e6346b', '#ffffff', 0),
		color: 'white',
	},
	styles: {
		dot: {
			background: 'red',
			size: 8,
		},
		text: {
			color: 'red',
		},
	},
};

export const BIAS_LEVEL_OPTIONS = [
	BIAS_LEVEL_LOW,
	BIAS_LEVEL_MODERATE,
	BIAS_LEVEL_HIGH,
];

export const BIAS_LEVEL_DEFAULT = BIAS_LEVEL_HIGH;

export const findBiasLevel = optionFinder({
	options: BIAS_LEVEL_OPTIONS,
	defaultOption: BIAS_DEFAULT,
});

export const getBiasLevel = (score) => [
	...BIAS_LEVEL_OPTIONS.filter((o) => score >= o.min && score <= o.max),
]?.[0] || BIAS_LEVEL_DEFAULT;

// export const getBiasLevel = (score) => BIAS_LEVEL_OPTIONS.find((level) => score >= level.min && score <= level.max);

export const BIAS_ALIGNMENT_STRONG_ID = 'BIAS_ALIGNMENT_STRONG';
export const BIAS_ALIGNMENT_STRONG_SHORT_ID = 'strong';
export const BIAS_ALIGNMENT_MEDIUM_ID = 'BIAS_ALIGNMENT_MEDIUM';
export const BIAS_ALIGNMENT_MEDIUM_SHORT_ID = 'medium';
export const BIAS_ALIGNMENT_WEAK_ID = 'BIAS_ALIGNMENT_WEAK';
export const BIAS_ALIGNMENT_WEAK_SHORT_ID = 'weak';

export const BIAS_ALIGNMENT_STRONG = {
	id: BIAS_ALIGNMENT_STRONG_ID,
	shortId: BIAS_ALIGNMENT_STRONG_SHORT_ID,
	min: 0,
	max: 29,
	label: {
		en: 'Strong alignment',
		fr: 'Alignment fort',
	},
	adjective: {
		en: 'Strong',
		fr: 'Fort',
	},
	details: {
		en: `There appears to be a good alignment regarding the perception of biases between you and your employees. 
This narrow gap suggests that you all share a fairly similar view of the extent and impact of biases within the work environment, demonstrating a common recognition of biases.`,
		fr: `Il semble y avoir une bonne concordance concernant la perception des biais entre vous et vos employés.
Cet écart étroit suggère que vous partagez tous une vision assez similaire de l'ampleur et de l'impact des biais dans l'environnement de travail, démontrant une reconnaissance commune des biais.`,
	},
	styles: {
		dot: {
			background: '#6c6',
			size: 2,
			// color: 'white',
		},
		text: {
			color: '#6c6',
			color: '#aaa',
		},
	},
	tag: {
		// color: 'green',
		background: mixColors('#101582', '#ffffff', 40),
		color: 'white',
	},
};
export const BIAS_ALIGNMENT_MEDIUM = {
	id: BIAS_ALIGNMENT_MEDIUM_ID,
	shortId: BIAS_ALIGNMENT_MEDIUM_SHORT_ID,
	min: 30,
	max: 69,
	label: {
		en: 'Medium alignment',
		fr: 'Alignement moyen',
	},
	adjective: {
		en: 'Medium',
		fr: 'Moyen',
	},
	details: {
		en: `There appears to be a moderate misalignment regarding the perception of biases between you and your employees. 
There is some recognition of biases by both parties, but their assessments on the extent and impact of these biases differ.
While not as severe as a larger gap, this difference still highlights that the manager and employees do not fully agree on the impact of biases in their interactions and decision-making processes.`,
		fr: `Il semble y avoir un décalage modéré concernant la perception des biais entre vous et vos employés.
Les deux parties reconnaissent certains biais, mais leurs évaluations de l'ampleur et de l'impact de ces biais diffèrent.
Bien que moins sévère qu'un écart plus important, cette différence met en évidence que le gestionnaire et les employés ne sont pas totalement d'accord sur l'impact des biais dans leurs interactions et leurs processus de prise de décision.`,
	},
	styles: {
		dot: {
			background: 'orange',
			color: 'white',
			size: 4,
		},
		text: {
			color: '#cc6600',
			color: '#855',
		},
	},
	tag: {
		// color: 'gold',
		background: mixColors('#101582', '#ffffff', 20),
		color: 'white',
	},
};
export const BIAS_ALIGNMENT_WEAK = {
	id: BIAS_ALIGNMENT_WEAK_ID,
	shortId: BIAS_ALIGNMENT_WEAK_SHORT_ID,
	min: 70,
	max: 100,
	label: {
		en: 'Weak alignment',
		fr: 'Alignement faible',
	},
	adjective: {
		en: 'Weak',
		fr: 'Faible',
	},
	details: {
		en: `There appears to be a significant misalignment, where you seem to underestimate or be unaware of the biases that your employees perceive. 
Everyone has unconscious biases; the goal is to become aware of them and find solutions to limit their impact.`,
		fr: `Il semble y avoir un décalage significatif, où vous semblez sous-estimer ou ne pas être conscient des biais que perçoivent vos employés. 
Tout le monde a des biais inconscients; l'objectif est d'en prendre conscience et de trouver des solutions pour limiter leur impact.`,
	},
	details2: {
		en: `There appears to be a significant misalignment, where the you seem to overestimate your biases compared to what your employees perceive. 
This suggests that despite an environment perceived as unbiased by the employees, these biases may still be expressed in your internal decision-making processes, affecting your employee's careers.
Therefore, it is crucial to limit the consequences of biases, even internally.`,
		fr: `Il semble y avoir un décalage significatif, où vous semblez surestimer vos biais par rapport à ce que perçoivent vos employés. 
Cela suggère que malgré un environnement perçu comme impartial par les employés, ces biais peuvent encore être présents dans vos processus de prise de décision internes, affectant les carrières de vos employés.
Il est donc crucial de limiter les conséquences des biais, même en interne.`,
	},
	styles: {
		dot: {
			background: 'red',
			size: 8,
			// color: 'white',
		},
		text: {
			color: 'red',
		},
	},
	tag: {
		// color: 'magenta',
		background: mixColors('#101582', '#ffffff', 0),
		color: 'white',
	},
};

export const BIAS_ALIGNMENT_OPTIONS = [
	BIAS_ALIGNMENT_STRONG,
	BIAS_ALIGNMENT_MEDIUM,
	BIAS_ALIGNMENT_WEAK,
];

export const BIAS_ALIGNMENT_DEFAULT = BIAS_ALIGNMENT_WEAK;

export const findBiasAlignment = optionFinder({
	options: BIAS_ALIGNMENT_OPTIONS,
	defaultOption: BIAS_ALIGNMENT_DEFAULT,
});

export const getBiasAlignment = (score) => [
	...BIAS_ALIGNMENT_OPTIONS.filter((o) => score < o.max),
]?.[0] || BIAS_ALIGNMENT_DEFAULT;

export const PERSONA_MANAGER_ID = 'PERSONA_MANAGER';
export const PERSONA_MANAGER_SHORT_ID = 'manager';
export const PERSONA_EMPLOYEES_MANAGER_ID = 'PERSONA_EMPLOYEE_MANAGER';
export const PERSONA_EMPLOYEES_MANAGER_SHORT_ID = 'employees-manager';
export const PERSONA_EMPLOYEES_ALL_ID = 'PERSONA_EMPLOYEES';
export const PERSONA_EMPLOYEES_ALL_SHORT_ID = 'employees';
export const PERSONA_EMPLOYEES_MALE_ID = 'PERSONA_EMPLOYEES_MALE';
export const PERSONA_EMPLOYEES_MALE_SHORT_ID = 'employeeMales';
export const PERSONA_EMPLOYEES_FEMALE_ID = 'PERSONA_EMPLOYEES_FEMALE';
export const PERSONA_EMPLOYEES_FEMALE_SHORT_ID = 'employeeFemales';
export const PERSONA_EMPLOYEES_GENDER_ID = 'PERSONA_EMPLOYEES_GENDER';
export const PERSONA_EMPLOYEES_GENDER_SHORT_ID = 'employee-gender';

// export const PERSONA_MANAGER_COLOR = '#925FFF';
export const PERSONA_MANAGER_COLOR = '#c25Fff';
export const PERSONA_EMPLOYEES_ALL_COLOR = '#0F6DFB';
export const PERSONA_EMPLOYEES_MALE_COLOR = '#0F3DFB';
export const PERSONA_EMPLOYEES_FEMALE_COLOR = '#f7506e';

export const PERSONA_MANAGER = {
	id: PERSONA_MANAGER_ID,
	shortId: PERSONA_MANAGER_SHORT_ID,
	label: 'Manager',
	alias: 'Manager',
	short: 'You',
	color: PERSONA_MANAGER_COLOR,
	style: {
		radar: {
			stroke: PERSONA_MANAGER_COLOR,
			strokeWidth: 6,
			fill: PERSONA_MANAGER_COLOR,
			fillOpacity: 0.1,
		},
	},
};
export const PERSONA_EMPLOYEES_MANAGER = {
	id: PERSONA_EMPLOYEES_MANAGER_ID,
	shortId: PERSONA_EMPLOYEES_MANAGER_SHORT_ID,
	label: 'Employee+Manager',
	alias: 'Employee+Manager',
	color: PERSONA_EMPLOYEES_ALL_COLOR,
	style: {
		radar: {
			stroke: PERSONA_EMPLOYEES_ALL_COLOR,
			stroke: '#0F3DFB',
			strokeWidth: 6,
			fill: PERSONA_EMPLOYEES_ALL_COLOR,
			fill: '#0F3DFB',
			fillOpacity: 0.1,
		},
	},
};

export const PERSONA_EMPLOYEES_ALL = {
	id: PERSONA_EMPLOYEES_ALL_ID,
	shortId: PERSONA_EMPLOYEES_ALL_SHORT_ID,
	label: 'Employees (all genders)',
	alias: 'Employees',
	color: PERSONA_EMPLOYEES_ALL_COLOR,
	style: {
		radar: {
			stroke: PERSONA_EMPLOYEES_ALL_COLOR,
			stroke: '#0F3DFB',
			strokeWidth: 6,
			fill: PERSONA_EMPLOYEES_ALL_COLOR,
			fill: '#0F3DFB',
			fillOpacity: 0.1,
		},
	},
};

export const PERSONA_EMPLOYEES_MALE = {
	id: PERSONA_EMPLOYEES_MALE_ID,
	shortId: PERSONA_EMPLOYEES_MALE_SHORT_ID,
	label: 'Employees (male)',
	alias: 'Male',
	color: PERSONA_EMPLOYEES_MALE_COLOR,
	style: {
		radar: {
			stroke: PERSONA_EMPLOYEES_MALE_COLOR,
			strokeWidth: 6,
			fill: PERSONA_EMPLOYEES_MALE_COLOR,
			fillOpacity: 0.1,
		},
	},
};
export const PERSONA_EMPLOYEES_FEMALE = {
	id: PERSONA_EMPLOYEES_FEMALE_ID,
	shortId: PERSONA_EMPLOYEES_FEMALE_SHORT_ID,
	label: 'Employees (female)',
	alias: 'Female',
	color: PERSONA_EMPLOYEES_FEMALE_COLOR,
	style: {
		radar: {
			stroke: PERSONA_EMPLOYEES_FEMALE_COLOR,
			strokeWidth: 6,
			fill: PERSONA_EMPLOYEES_FEMALE_COLOR,
			fillOpacity: 0.1,
		},
	},
};

export const PERSONA_EMPLOYEES_GENDER = {
	id: PERSONA_EMPLOYEES_GENDER_ID,
	shortId: PERSONA_EMPLOYEES_GENDER_SHORT_ID,
	label: 'Employees (F/M)',
	alias: 'Employees (F/M)',
	style: {
		radar: { stroke: '#28D78A', strokeWidth: 2 },
	},
};
export const PERSONA_OPTIONS = [
	PERSONA_MANAGER,
	PERSONA_EMPLOYEES_ALL,
	PERSONA_EMPLOYEES_MALE,
	PERSONA_EMPLOYEES_FEMALE,
	PERSONA_EMPLOYEES_GENDER,
];

export const PERSONA_DEFAULT = PERSONA_MANAGER;

export const findPersona = optionFinder({
	options: PERSONA_OPTIONS,
	defaultOption: PERSONA_DEFAULT,
});

export const getManagerBiasResults = (data) => {
	// const {
	// 	Manager: manager,
	// 	Employees: employees,
	// 	'Male employees': male,
	// 	'Female employees': female,
	// } = data
	// 	?.map(({ answerType, scores }) => ({ [answerType]: scores }))
	// 	?.reduce(mergeReducer);

	let results = {};
	const managerScores = {};
	const employeeScores = {};
	const employeeMaleScores = {};
	const employeeFemaleScores = {};
	const biasScores = BIAS_OPTIONS
		.map(({ id }) => ({ [id]: {} }))
		.reduce(mergeReducer);

	Object.entries(data)
		?.map?.(([answerType, values]) => {
			const persona = findPersona(answerType);
			if (persona) {
				const { count, scores } = values;
				// console.log({ answerType, count, scores });
				results = {
					...results || {},
					persona: {
						...results?.persona || {},
						[persona.id]: {
							count: parseInt(count, 10) || 1,
							scores: BIAS_OPTIONS
								.map?.(({ id }) => ({
									[id]: scores?.find?.((s) => s?.bias === id)?.value,
								}))
								.reduce?.(mergeReducer, {}),
							orderedBias: scores?.slice?.()?.sort?.(sortOn({ key: 'value' }, true)),
						},
					},
					bias: {
						...results?.bias || {},
						...scores
							?.map?.(({ bias, value }) => ({
								[bias]: {
									...results?.bias?.[bias] || {},
									[persona.id]: value,
								},
							}))
							?.reduce?.(mergeReducer, {}),
					},
				};
			}
		});

	BIAS_OPTIONS
		.forEach?.(({ id }) => {
			const managerBiasScore = results?.bias?.[id]?.[PERSONA_MANAGER_ID];
			const employeesAllBiasScore = results?.bias?.[id]?.[PERSONA_EMPLOYEES_ALL_ID];
			const avgScore = Math.round((managerBiasScore + 2 * employeesAllBiasScore) / 3);
			const rawDelta = Math.abs(managerBiasScore - employeesAllBiasScore);
			const deltaFactor = round(2 - (Math.abs(50 - managerBiasScore) / 50), 2);
			const delta = Math.round(rawDelta * deltaFactor);
			const level = getBiasLevel(avgScore)?.id;
			const avgLevel = getBiasLevel(avgScore)?.id;
			const alignment = getBiasAlignment(delta)?.id;
			results.bias = {
				...results?.bias || {},
				[id]: {
					...results?.bias?.[id] || {},
					avgScore,
					rawDelta,
					deltaFactor,
					delta,
					level,
					avgLevel,
					alignment,
				},
			};
		});

	LocalDebug.logInfo({ className, method: 'getManagerBiasResults' }, { results });

	return results;
};

export const RISK_PERFORMANCE_EVALUATION_ID = 'RISK_PERFORMANCE_EVALUATION';
export const RISK_PERFORMANCE_EVALUATION = {
	id: RISK_PERFORMANCE_EVALUATION_ID,
	label: 'Performance evaluation',
	details: {
		en: 'Gender stereotypes and expectations can influence how the work of men and women employees is perceived during performance evaluations. Biases can lead to over- or under-evaluation based on gender, even though performances are the same.',
		fr: 'Les stéréotypes et attentes liés au genre peuvent influencer la manière dont le travail des employés hommes et femmes est perçu lors des évaluations de performance. Les biais peuvent conduire à une surévaluation ou une sous-évaluation basée sur le genre, même si les performances sont identiques',
	},
	biases: [
		// Main biases
		{ bias: BIAS_RECENCY_BIAS_OPTION, weight: 10 },
		{ bias: BIAS_IN_GROUP_EFFECT_OPTION, weight: 5 },
		{ bias: BIAS_CONTRAST_EFFECT_OPTION, weight: 5 },
		{ bias: BIAS_HALO_HORNS_EFFECT_OPTION, weight: 3 },
		{ bias: BIAS_OVERLY_CRITICAL_OPTION, weight: 3 },
		// Other biases
		{ bias: BIAS_BACKLASH_EFFECT_OPTION, weight: 1 },
		{ bias: BIAS_SELF_FULLFILLING_PROPHECY_OPTION, weight: 1 },
	],
	impact: {
		en: `Implicit biases, such as Recency bias, In-group Favoritism, Contrast Effect, Halo/Horn Effect, and Overly critical bias can significantly impact a manager's evaluation of an employee's performance, particularly regarding gender.

Gender stereotypes are generalised beliefs about what men and women are supposed to be or do. These stereotypes can strongly influence the perception of skills and performance of employees in the workplace. During performance evaluations, these biases can lead to an overestimation or underestimation of performance based on the employee's gender, even if actual performance is the same.

Thus, managers may unconsciously attribute skills, traits, and behaviors more to a man than to a woman, or vice versa, based on their gender expectations. For example, men might be more often perceived as risk-takers and decisive, whereas these same traits in a woman might be interpreted negatively as aggression. Conversely, women may be more valued for their cooperation and empathy, traits less valued in men.`,
		fr: `Les biais implicites, tels que le Recency bias, l'In-group Favoritism, le Contrast Effect, le Halo/Horn Effect, et le Overly critical bias, peuvent avoir un impact significatif sur l'évaluation qu'un manager fait de la performance d'un employé, notamment en ce qui concerne le genre.

Les stéréotypes de genre sont des croyances généralisées sur ce que les hommes et les femmes sont supposés être ou faire. Ces stéréotypes peuvent fortement influencer la perception des compétences et de la performance des employés sur le lieu de travail. Lors des évaluations de performance, ces biais peuvent conduire à une surestimation ou une sous-estimation de la performance basée sur le genre de l'employé, même si la performance réelle est identique.

Ainsi, les managers peuvent inconsciemment attribuer des compétences, des traits et des comportements davantage à un homme qu'à une femme, ou vice versa, en fonction de leurs attentes liées au genre. Par exemple, les hommes pourraient être plus souvent perçus comme des preneurs de risques et décisifs, tandis que ces mêmes traits chez une femme pourraient être interprétés négativement comme de l'agressivité. Inversement, les femmes peuvent être plus appréciées pour leur coopération et leur empathie, des traits moins valorisés chez les hommes.`,
	},
	solutions: {
		en: `50inTech offers some easy-to-implement tips to limit the impact of biases on performance evaluations:
• Maintain regular tracking of each employee's accomplishments, both failures and successes, to have a complete view of their performance and avoid considering only part of their work.
• Use objective evaluation criteria by defining relevant and directly measurable criteria linked to the job for each employee, to have a clear basis for evaluation.
• Implement a structured evaluation process by involving multiple evaluators who contribute to the employee's assessment to gather various opinions and counterbalance certain biases.
	
We remind that we all have biases, but the goal is to limit their consequences. By implementing fair practices, organisations can ensure that all employees have the opportunity to fully develop and effectively contribute to their company.`,
		fr: `50inTech offre quelques conseils faciles à mettre en œuvre pour limiter l'impact des biais sur les évaluations de performance :
• Maintenir un suivi régulier des accomplissements de chaque employé, tant les échecs que les succès, pour avoir une vue complète de leur performance et éviter de ne considérer qu'une partie de leur travail.
• Utiliser des critères d'évaluation objectifs en définissant des critères pertinents et directement mesurables liés au poste pour chaque employé, afin d'avoir une base claire pour l'évaluation.
• Mettre en place un processus d'évaluation structuré en impliquant plusieurs évaluateurs qui contribuent à l'évaluation de l'employé pour recueillir diverses opinions et contrebalancer certains biais.

Nous rappelons que nous avons tous des biais, mais l'objectif est de limiter leurs conséquences. En mettant en œuvre des pratiques équitables, les organisations peuvent s'assurer que tous les employés ont la possibilité de se développer pleinement et de contribuer efficacement à leur entreprise.`,
	},
};

export const RISK_ASSIGNEMENTS_ID = 'RISK_ASSIGNEMENTS';
export const RISK_ASSIGNEMENTS = {
	id: RISK_ASSIGNEMENTS_ID,
	label: 'Assigned missions and projects',
	details: {
		en: 'Participation in projects, discussions, and taking on responsibilities can increase an employee\'s visibility and promotion prospects. The decision on who is assigned to specific projects or roles might be influenced by unconscious biases and stereotypes, and can lead to inequalities in the repartition of missions and projects.',
		fr: 'La participation à des projets, des discussions et la prise de responsabilités peuvent augmenter la visibilité d\'un employé et ses perspectives de promotion. La décision concernant l\'attribution de projets ou de rôles spécifiques peut être influencée par des biais inconscients et des stéréotypes, et peut entraîner des inégalités dans la répartition des missions et des projets.',
	},
	biases: [
		// Main biases
		{ bias: BIAS_BACKLASH_EFFECT_OPTION, weight: 10 },
		{ bias: BIAS_IN_GROUP_EFFECT_OPTION, weight: 5 },
		{ bias: BIAS_CONTRAST_EFFECT_OPTION, weight: 5 },
		// Other biases
		{ bias: BIAS_RECENCY_BIAS_OPTION, weight: 1 },
		{ bias: BIAS_HALO_HORNS_EFFECT_OPTION, weight: 1 },
		{ bias: BIAS_SELF_FULLFILLING_PROPHECY_OPTION, weight: 1 },
		{ bias: BIAS_OVERLY_CRITICAL_OPTION, weight: 1 },
	],
};
export const RISK_BENEFITS_DISTRIBUTION_ID = 'RISK_BENEFITS_DISTRIBUTION';
export const RISK_BENEFITS_DISTRIBUTION = {
	id: RISK_BENEFITS_DISTRIBUTION_ID,
	label: 'Merit-based benefits distribution',
	details: {
		en: 'Managers may be confident in their ability to make impartial judgments but might be unaware of their gender biases. As a result, they might reward employees they believe deserve it, inadvertently disadvantaged women due to unrecognised biases.',
		fr: 'Les managers peuvent être confiants dans leur capacité à porter des jugements impartiaux, mais pourraient ne pas être conscients de leurs biais de genre. En conséquence, ils pourraient récompenser les employés qu\'ils estiment mériter, désavantageant involontairement les femmes en raison de biais non reconnus.		',
	},
	impact: {
		en: `Implicit biases, such as the Recency Effect, Backlash Effect, and In-group Favoritism, can significantly influence the distribution of merit-based benefits within companies. These biases can distort the perception of employee performance and affect decisions on who deserves rewards or promotions.

Gender stereotypes are generalised beliefs about what men and women are supposed to be or do. For example, in a professional context, men may be unconsciously favoured for rewards or promotions due to a perception that they are more decisive or authoritative. Conversely, skills such as empathy or collaboration, often attributed to women, may be undervalued when distributing merit-based benefits.`,
		fr: `Les biais implicites, tels que le Recency Effect, le Backlash Effect, et l'In-group Favoritism, peuvent influencer de manière significative la distribution des avantages basés sur le mérite au sein des entreprises. Ces biais peuvent déformer la perception de la performance des employés et affecter les décisions sur qui mérite des récompenses ou des promotions.

Les stéréotypes de genre sont des croyances généralisées sur ce que les hommes et les femmes sont censés être ou faire. Par exemple, dans un contexte professionnel, les hommes peuvent être inconsciemment favorisés pour des récompenses ou des promotions en raison de la perception qu'ils sont plus décisifs ou autoritaires. Inversement, des compétences telles que l'empathie ou la collaboration, souvent attribuées aux femmes, peuvent être sous-évaluées lors de la distribution des avantages basés sur le mérite.`,
	},
	solutions: {
		en: `To limit the impact of these biases in the distribution of merit-based benefits, here are some practical tips:
• Establish clear, measurable criteria for performance evaluation and reward allocation to avoid subjective judgments. These criteria should be communicated to all employees to promote transparency in promotion and reward criteria.
• Regularly review reward and promotion decisions by consistently tracking the performance of each employee, for example.
• Provide regular feedback by implementing a system where employees can regularly receive constructive feedback on their performance.
	
By recognising and addressing biases, companies can strive to create a more equitable environment where rewards and promotions are truly based on merit and not on stereotypes or unconscious biases.`,
		fr: `Pour limiter l'impact de ces biais dans la distribution des avantages basés sur le mérite, voici quelques conseils pratiques :
• Établir des critères clairs et mesurables pour l'évaluation de la performance et l'attribution des récompenses afin d'éviter les jugements subjectifs. Ces critères doivent être communiqués à tous les employés pour promouvoir la transparence des critères de promotion et de récompense.
• Revoir régulièrement les décisions de récompense et de promotion en suivant constamment la performance de chaque employé, par exemple.
• Fournir un retour régulier en mettant en place un système où les employés peuvent régulièrement recevoir des retours constructifs sur leur performance.

En reconnaissant et en abordant les biais, les entreprises peuvent s'efforcer de créer un environnement plus équitable où les récompenses et les promotions sont véritablement basées sur le mérite et non sur des stéréotypes ou des biais inconscients.`,
	},
	biases: [
		// Main biases
		{ bias: BIAS_RECENCY_BIAS_OPTION, weight: 10 },
		{ bias: BIAS_BACKLASH_EFFECT_OPTION, weight: 5 },
		{ bias: BIAS_IN_GROUP_EFFECT_OPTION, weight: 5 },
		// Other biases
		{ bias: BIAS_CONTRAST_EFFECT_OPTION, weight: 1 },
		{ bias: BIAS_HALO_HORNS_EFFECT_OPTION, weight: 1 },
		{ bias: BIAS_OVERLY_CRITICAL_OPTION, weight: 1 },
		{ bias: BIAS_SELF_FULLFILLING_PROPHECY_OPTION, weight: 1 },
	],
};
export const RISK_LEADERSHIP_DEV_PROGRAMS_ID = 'RISK_LEADERSHIP_DEV_PROGRAMS';
export const RISK_LEADERSHIP_DEV_PROGRAMS = {
	id: RISK_LEADERSHIP_DEV_PROGRAMS_ID,
	label: 'Leadership development programs',
	details: {
		en: 'Women often have limited access to leadership development programs and opportunities to interact with established leaders. This can affect women\'s chances of advancing in the professional hierarchy.',
		fr: 'Les femmes ont souvent un accès limité aux programmes de développement du leadership et aux opportunités d\'interaction avec des leaders établis. Cela peut affecter les chances des femmes de progresser dans la hiérarchie professionnelle.',
	},
	impact: {
		en: `Leadership development programs are often less accessible to women, as well as opportunities to interact with expert leaders because of some bias such as the Backlash effect, In-group Favoritism, Self-fulfilling Prophecy, Halo/horn Effect. This can limit the possibilities for women to progress in the professional hierarchy.

Gender stereotypes are generalised beliefs about what men and women are supposed to be or do. For example, in a professional context, men, compared to women, may be unconsciously perceived as more legitimate to participate in leadership development programs because they are supposed to have leadership skills and to become better leaders.`,
		fr: `Les programmes de développement du leadership sont souvent moins accessibles aux femmes, ainsi que les opportunités d'interaction avec des leaders experts en raison de certains biais tels que le Backlash Effect, In-group Favoritism, Self-fulfilling Prophecy, et Halo/Horn Effect. Cela peut limiter les possibilités pour les femmes de progresser dans la hiérarchie professionnelle.

Les stéréotypes de genre sont des croyances généralisées sur ce que les hommes et les femmes sont censés être ou faire. Par exemple, dans un contexte professionnel, les hommes, par rapport aux femmes, peuvent être inconsciemment perçus comme plus légitimes pour participer à des programmes de développement du leadership car ils sont supposés posséder des compétences de leadership et devenir de meilleurs leaders.`,
	},
	solutions: {
		en: `To counter this imbalance and promote equitable access to leadership development programs, 50inTech give you some practical tools:
• Establish quotas or specific goals to ensure that women are fairly represented in leadership development programs.
• Implement a mentoring program where women can interact with leaders who can advise, support, and enable them to network.
• Monitor and evaluate progress in leadership development programs by setting up systems to regularly track and evaluate employee progress and provide relevant feedback.
• Promote and highlight the successes of women in these programs to enhance their visibility in leadership, and limit future manager bias by showing that women can participate in such programs.

By implementing these practices, companies can work to create a more inclusive environment where women have equal opportunities to develop their leadership skills and progress professionally.`,
		fr: `Pour contrer ce déséquilibre et promouvoir un accès équitable aux programmes de développement du leadership, 50inTech vous propose quelques outils pratiques :
• Établir des quotas ou des objectifs spécifiques pour garantir une représentation équitable des femmes dans les programmes de développement du leadership.
• Mettre en place un programme de mentorat où les femmes peuvent interagir avec des leaders qui peuvent les conseiller, les soutenir et leur permettre de réseauter.
• Surveiller et évaluer les progrès dans les programmes de développement du leadership en mettant en place des systèmes pour suivre et évaluer régulièrement les progrès des employés et fournir des retours pertinents.
• Promouvoir et mettre en avant les succès des femmes dans ces programmes pour renforcer leur visibilité dans le leadership, et limiter les biais futurs des managers en montrant que les femmes peuvent participer à de tels programmes.

En mettant en œuvre ces pratiques, les entreprises peuvent travailler à créer un environnement plus inclusif où les femmes ont des chances égales de développer leurs compétences en leadership et de progresser professionnellement.`,
	},
	biases: [
		// Main biases
		{ bias: BIAS_BACKLASH_EFFECT_OPTION, weight: 10 },
		{ bias: BIAS_IN_GROUP_EFFECT_OPTION, weight: 5 },
		{ bias: BIAS_SELF_FULLFILLING_PROPHECY_OPTION, weight: 5 },
		{ bias: BIAS_HALO_HORNS_EFFECT_OPTION, weight: 3 },
		// Other biases
		{ bias: BIAS_RECENCY_BIAS_OPTION, weight: 1 },
		{ bias: BIAS_CONTRAST_EFFECT_OPTION, weight: 1 },
		{ bias: BIAS_OVERLY_CRITICAL_OPTION, weight: 1 },
	],
};
export const RISK_ORGANISATIONAL_STANDARDS_ID = 'RISK_ORGANISATIONAL_STANDARDS';
export const RISK_ORGANISATIONAL_STANDARDS = {
	id: RISK_ORGANISATIONAL_STANDARDS_ID,
	label: 'Organisational standards',
	details: {
		en: 'Behaviour exhibited by managers can perpetuate gender biases within the company, creating a biased organizational culture and an impression that women are less competent than men. This impacts women\'s self-perception on their skills, effectiveness, feelings of workplace security, and career opportunities.',
		fr: 'Le comportement affiché par les managers peut perpétuer les biais de genre au sein de l\'entreprise, créant une culture organisationnelle biaisée et une impression que les femmes sont moins compétentes que les hommes. Cela impacte la perception qu\'ont les femmes de leurs compétences, de leur efficacité, de leur sentiment de sécurité au travail et de leurs opportunités de carrière.',
	},
	biases: [
		// Main biases
		{ bias: BIAS_IN_GROUP_EFFECT_OPTION, weight: 10 },
		{ bias: BIAS_SELF_FULLFILLING_PROPHECY_OPTION, weight: 5 },
		{ bias: BIAS_CONTRAST_EFFECT_OPTION, weight: 5 },
		// Other biases
		{ bias: BIAS_RECENCY_BIAS_OPTION, weight: 1 },
		{ bias: BIAS_HALO_HORNS_EFFECT_OPTION, weight: 1 },
		{ bias: BIAS_OVERLY_CRITICAL_OPTION, weight: 1 },
		{ bias: BIAS_BACKLASH_EFFECT_OPTION, weight: 1 },
	],
};

export const RISK_OPTIONS = [
	RISK_PERFORMANCE_EVALUATION,
	RISK_ASSIGNEMENTS,
	RISK_BENEFITS_DISTRIBUTION,
	RISK_LEADERSHIP_DEV_PROGRAMS,
	RISK_ORGANISATIONAL_STANDARDS,
];

export const RISK_DEFAULT = RISK_PERFORMANCE_EVALUATION;

export const findRisk = (value) => RISK_OPTIONS
	.find((o) => {
		if (value?.id) {
			return (
				o?.id && o?.id?.toLowerCase?.() === value?.id?.toLowerCase?.()
			);
		}
		return (value && (
			(o?.id && (o?.id?.toLowerCase?.() === value?.toLowerCase?.())
				|| (o?.label && o?.label?.toLowerCase?.() === value?.toLowerCase?.())
				|| (o?.alias && o?.alias?.toLowerCase?.() === value?.toLowerCase?.())
			)
		));
	}) || RISK_DEFAULT;

export const getScoreBiasPersonaId = ({
	bias,
	persona,
	context,
}) => `score-${bias?.shortId || bias?.id || bias}-${persona?.map((p) => p?.shortId || p?.id || p).join('-')}-${context?.appLang || 'en'}`;

export const getBiasLevelId = ({
	level,
	context,
}) => `level-${level?.shortId || level?.id || level}-${context?.appLang || 'en'}`;

export const getBiasAlignmentId = ({
	alignment,
	context,
}) => `alignment-${alignment?.shortId || alignment?.id || alignment}-${context?.appLang || 'en'}`;

export const BiasDotLabelTag = ({
	ratio = 1,
	label,
	tagStyle,
	labelStyle,
	dotStyle,
}) => {
	const className = 'BiasDotLabelTag';

	const h = 80;

	const dotSize = 8;
	return (
		<>
			<Space
				size={[2 * ratio, 0 * ratio]}
				style={{
					height: ratio * h / 7,
					justifyContent: 'flex-start',
					alignItems: 'center',
					whiteSpace: 'nowrap',
					fontWeight: 'bold',
					fontSize: ratio * 8,
					textAlign: 'center',
					// marginBottom: -4,
					...tagStyle,
				}}
			>
				<div
					style={{
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
						width: ratio * dotSize + 2,
						height: ratio * dotSize + 2,
						maxWidth: ratio * dotSize + 2,
						maxHeight: ratio * dotSize + 2,
						minWidth: ratio * dotSize + 2,
						minHeight: ratio * dotSize + 2,
						borderRadius: ratio * 100,
						...dotStyle?.size < 6
							? { border: `1px solid ${dotStyle.background}` }
							: {},
					}}
				>
					<div
						style={{
							display: 'block',
							width: ratio * (dotStyle?.size || 7),
							height: ratio * (dotStyle?.size || 7),
							maxWidth: ratio * (dotStyle?.size || 7),
							maxHeight: ratio * (dotStyle?.size || 7),
							borderRadius: ratio * 100,
							...dotStyle,
						}}
					/>

				</div>
				<span style={{ ...labelStyle }}>
					{label}
				</span>
			</Space>
		</>
	);
};

export const BiasLevelTag = ({
	level,
	tagStyle,
	ratio,
	context,
}) => {
	return (
		<BiasDotLabelTag
			ratio={ratio}
			label={level.label?.[context?.appLang || 'en'] || level.label}
			dotStyle={level?.styles?.dot}
			labelStyle={level?.styles?.text}
			tagStyle={tagStyle}
		/>
	);
};

export const BiasAlignmentTag = ({
	alignment,
	tagStyle,
	ratio,
	context,
}) => {
	return (
		<BiasDotLabelTag
			ratio={ratio}
			label={alignment.label?.[context?.appLang || 'en'] || alignment.label}
			dotStyle={alignment?.styles?.dot}
			labelStyle={alignment?.styles?.text}
			tagStyle={tagStyle}
		/>
	);
};

export const RadarBiasTag = ({
	ratio,
	bias,
	data,
	personas,
	withBiasLevel = true,
	withAlignmentLevel = true,
	withCaret = true,
	withSpaceStyles = true,
	context,
}) => {
	const className = 'RadarBiasTag';
	// LocalDebug.logInfo({ className }, {
	// 	ratio, bias, data, persona,
	// });
	const spaceStyles = {
		[BIAS_SELF_FULLFILLING_PROPHECY_SHORT_ID]: { justifyContent: 'flex-start', alignItems: 'center' },
		[BIAS_BACKLASH_EFFECT_SHORT_ID]: { justifyContent: 'flex-start', alignItems: 'flex-start' },
		[BIAS_RECENCY_BIAS_SHORT_ID]: { justifyContent: 'flex-start', alignItems: 'flex-start' },
		[BIAS_HALO_HORNS_EFFECT_SHORT_ID]: { justifyContent: 'flex-start', alignItems: 'flex-start' },
		[BIAS_IN_GROUP_EFFECT_SHORT_ID]: { justifyContent: 'flex-start', alignItems: 'flex-end' },
		[BIAS_OVERLY_CRITICAL_SHORT_ID]: { justifyContent: 'flex-start', alignItems: 'flex-end' },
		[BIAS_CONTRAST_EFFECT_SHORT_ID]: { justifyContent: 'flex-start', alignItems: 'flex-end' },
	};
	const levelStyles = {
		[BIAS_IN_GROUP_EFFECT_SHORT_ID]: { flexDirection: 'row-reverse' },
		[BIAS_OVERLY_CRITICAL_SHORT_ID]: { flexDirection: 'row-reverse' },
		[BIAS_CONTRAST_EFFECT_SHORT_ID]: { flexDirection: 'row-reverse' },
	};

	// const zoom = ratio;
	// ratio = 1;
	const biasId = bias?.shortId || bias?.id || bias;
	const values = data?.scores?.bias?.[biasId] || {};
	const scores = personas
		?.map((persona, index) => {
			// return <div key={index}><ReactJsonDebug src={{ persona, p: findPersona(persona) }} /></div>;
			const p = findPersona(persona);
			return (
				<div
					key={p?.id}
					style={{
						display: 'flex',
						flexDirection: 'column',
						justifyContent: 'flex-start',
						alignItems: 'flex-start',
						...withSpaceStyles ? spaceStyles?.[biasId] || {} : {},
						gap: 1 * ratio,
						fontSize: 16 * ratio,
						fontWeight: 'bold',
						lineHeight: 1.1,
						color: p?.color,
					}}
				>
					<div
						style={{
							fontSize: 6 * ratio,
							fontWeight: 'normal',
						}}
					>
						{p?.short || p?.alias || p?.label}
					</div>
					<span>
						{Math.round(data?.scores?.persona?.[p?.shortId]?.scores?.[biasId])}
						<span style={{
							fontSize: 12 * ratio,
						}}>%</span>
					</span>
				</div>
			);
		});

	const w = 140;
	const h = 48 + (withBiasLevel ? 11 : 0) + (withAlignmentLevel ? 11 : 0);

	return (
		<>
			<div
				id={getScoreBiasPersonaId({ bias, persona: personas, context })}
				style={{
					// zoom,
					minWidth: ratio * w,
					width: ratio * w,
					maxWidth: ratio * w,
					minHeight: ratio * h,
					height: ratio * h,
					maxHeight: ratio * h,
				}}
			>
				<div
					// direction='vertical'
					// size={0.1 * ratio}
					style={{
						display: 'flex',
						flexDirection: 'column',
						justifyContent: 'center',
						alignItems: 'flex-start',
						...withSpaceStyles ? spaceStyles?.[biasId] || {} : {},
						minWidth: ratio * w,
						width: ratio * w,
						maxWidth: ratio * w,
						minHeight: ratio * h,
						height: ratio * h,
						maxHeight: ratio * h,
					}}
				>
					<div
						style={{
							fontSize: 10 * ratio,
							fontWeight: 'bold',
							lineHeight: 1.3,
							color: '#333',
						}}
					>
						{bias.label2 || bias.label}
					</div>
					{withCaret && (
						<CaretDownFilled
							style={{
								fontSize: 12 * ratio,
								opacity: 0.3,
								marginTop: -2 * ratio,
								marginBottom: -2 * ratio,
								// marginBottom: -4 * ratio,
							}}
						/>
					)}
					<div
						// size={8 * ratio}
						style={{
							display: 'flex',
							flexDirection: 'row',
							gap: 8 * ratio,
							// height: 26 * ratio,
							marginTop: 3 * ratio,
							marginBottom: 3 * ratio,
						}}
						// style={{ marginBottom: 3 * ratio }}
					>
						{scores}
					</div>
					{withBiasLevel && (
						<BiasLevelTag
							ratio={ratio}
							level={findBiasLevel(values?.level)}
							tagStyle={levelStyles?.[biasId] || {}}
							context={context}
						/>
					)}
					{withAlignmentLevel && (
						<BiasAlignmentTag
							ratio={ratio}
							alignment={findBiasAlignment(values?.alignment)}
							tagStyle={levelStyles?.[biasId] || {}}
							context={context}
						/>
					)}
				</div>
			</div>
		</>
	);
};

export const RadarBiasTagPdf = ({
	ratio = 1,
	...props
}) => {
	const className = 'RadarBiasTagPdf';
	return (
		<>
			<div
				style={{
					border: '1px solid #eee',
					zoom: 1 / ratio,
				}}
			>
				<RadarBiasTag ratio={ratio} {...props} />
			</div>
		</>
	);
};

export const getBiasAnalysisText = ({ version = 'employee', data }) => {
	const countStrong = data?.strong?.length;
	const countModerate = data?.moderate?.length;
	const countLow = data?.low?.length;

	const replacer = (str) => {
		let replaced = str;
		['strong', 'moderate', 'low'].forEach((level) => {
			numToArray(7).forEach((index) => {
				replaced = replaced.replaceAll(
					`{${level}[${index}]}`,
					findBias(data?.[level]?.[index])?.label,
				);
			});
		});
		replaced = replaced.replaceAll('{countStrong}', countStrong);
		replaced = replaced.replaceAll('{countModerate}', countModerate);
		replaced = replaced.replaceAll('{countLow}', countLow);
		return replaced;
	};

	const inotherwords = [
		'In other words, it seems that you particularly express these specific types of biases in your management.',
		'Therefore, it\'s important for you to focus on the consequences of these particular biases and how to address them.',
		'Keep up the vigilance and engagement for an unbiased management!',
	];

	const additionalInfo = [];
	// return JSON.stringify({
	// 	data, countStrong, countModerate, countLow,
	// });

	let strongContent = null;
	let moderateContent = null;
	let lowContent = null;

	switch (true) {
		case countStrong === 7:
			strongContent = (
				<>
					{/* Your employees seem to perceive */}
					a <b>high level</b> of bias on <b>all 7 biases</b> we measure,
					especially but not limited to
					the <b>{findBias(data.strong?.[0]).label}</b> and
					the <b>{findBias(data.strong?.[1]).label}</b>.
				</>
			);
			break;
		case countStrong > 1:
			strongContent = (
				<>
					{/* Your employees seem to perceive */}
					a <b>high level</b> of bias on <b>{data.strong?.length} biases</b> out of the 7 we
					measure, {data.strong?.length > 2 ? 'especially but not limited to ' : ' specifically '} the <b>{findBias(data.strong?.[0]).label}</b> and
					the <b>{findBias(data.strong?.[1]).label}</b>.
				</>
			);
			break;
		case countStrong === 1:
			strongContent = (
				<>
					{/* Your employees seem to perceive */}
					a <b>high level</b> of bias on <b>only 1 bias</b> out of the 7 we
					measure, specifically the <b>{findBias(data.strong?.[0]).label}</b>.
				</>
			);
			break;
		default:
			strongContent = (
				<>
					{/* Your employees don't seem to perceive */}
					no <b>high level</b> of bias on <b>any of the 7 biases we measure</b>.
				</>
			);
			break;
	}

	switch (true) {
		case countModerate === 7:
			moderateContent = (
				<>
					{/* Your employees seem to perceive */}
					a <b>moderate level</b> of bias on <b>all 7 biases</b> we measure,
					especially but not limited to
					the <b>{findBias(data.moderate?.[0]).label}</b> and
					the <b>{findBias(data.moderate?.[1]).label}</b>.
				</>
			);
			break;
		case countModerate > 1:
			moderateContent = (
				<>
					{/* Your employees seem to perceive */}
					a <b>moderate level</b> of bias on <b>{data.moderate?.length} biases</b> out of the 7 we
					measure, {data.moderate?.length > 2 ? 'especially but not limited to ' : ' specifically '} the <b>{findBias(data.moderate?.[0]).label}</b> and
					the <b>{findBias(data.moderate?.[1]).label}</b>.
				</>
			);
			break;
		case countModerate === 1:
			moderateContent = (
				<>
					{/* Your employees seem to perceive */}
					a <b>moderate level</b> of bias on <b>only 1 bias</b> out of the 7 we
					measure, specifically the <b>{findBias(data.moderate?.[0]).label}</b>.
				</>
			);
			break;
		default:
			moderateContent = (
				<>
					{/* Your employees don't seem to perceive */}
					no <b>moderate level</b> of bias on <b>any of the 7 biases we measure</b>.
				</>
			);
			break;
	}

	switch (true) {
		case countLow === 7:
			lowContent = (
				<>
					{/* Your employees seem to perceive */}
					a <b>low level</b> of bias on <b>all 7 biases</b> we measure,
					especially but not limited to
					the <b>{findBias(data.low?.[0]).label}</b> and
					the <b>{findBias(data.low?.[1]).label}</b>.
				</>
			);
			break;
		case countLow > 1:
			lowContent = (
				<>
					{/* Your employees seem to perceive */}
					a <b>low level</b> of bias on <b>{data.strong?.length} biases</b> out of the 7 we
					measure, {data.low?.length > 2 ? 'especially but not limited to ' : ' specifically '} the <b>{findBias(data.low?.[0]).label}</b> and
					the <b>{findBias(data.low?.[1]).label}</b>.
				</>
			);
			break;
		case countLow === 1:
			lowContent = (
				<>
					{/* Your employees seem to perceive */}
					a <b>low level</b> of bias on <b>only 1 bias</b> out of the 7 we
					measure, specifically the <b>{findBias(data.low?.[0]).label}</b>.
				</>
			);
			break;
		default:
			lowContent = (
				<>
					{/* Your employees don't seem to perceive */}
					no <b>low level</b> of bias on <b>any of the 7 biases we measure</b>.
				</>
			);
			break;
	}

	return (
		<>
			{version === 'employees'
				? <b>Based on their answers, your employees seem to perceive...</b>
				: <b>Based on your own answers, you seem to express...</b>
			}
			<ul>
				<li>{strongContent}</li>
				<li>{moderateContent}</li>
				<li>{lowContent}</li>
			</ul>
			{/* {strongContent}
			{moderateContent}
			{lowContent} */}
		</>
	);
};

export const getAlignmentAnalysisText = ({ data }) => {
	const countStrong = data?.strong?.length;
	const countModerate = data?.moderate?.length;
	const countLow = data?.low?.length;

	let strongContent = null;
	let moderateContent = null;
	let lowContent = null;

	switch (true) {
		case countStrong === 7:
			strongContent = (
				<>
					a <b>high level</b> of alignment on <b>all 7 biases</b> we measure,
					especially but not limited to
					the <b>{findBias(data.strong?.[0]).label}</b> and
					the <b>{findBias(data.strong?.[1]).label}</b>.
				</>
			);
			break;
		case countStrong > 1:
			strongContent = (
				<>
					a <b>high level</b> of alignment on <b>{data.strong?.length} biases</b> out of the 7 we
					measure, {data.strong?.length > 2 ? 'especially but not limited to ' : ' specifically '} the <b>{findBias(data.strong?.[0]).label}</b> and
					the <b>{findBias(data.strong?.[1]).label}</b>.
				</>
			);
			break;
		case countStrong === 1:
			strongContent = (
				<>
					a <b>high level</b> of alignment on <b>only 1 bias</b> out of the 7 we
					measure, specifically the <b>{findBias(data.strong?.[0]).label}</b>.
				</>
			);
			break;
		default:
			strongContent = (
				<>
					{/* Your employees don't seem to perceive */}
					no <b>high level</b> of alignment on <b>any of the 7 biases we measure</b>.
				</>
			);
			break;
	}

	switch (true) {
		case countModerate === 7:
			moderateContent = (
				<>
					{/* Your employees seem to perceive */}
					a <b>moderate level</b> of alignment on <b>all 7 biases</b> we measure,
					especially but not limited to
					the <b>{findBias(data.moderate?.[0]).label}</b> and
					the <b>{findBias(data.moderate?.[1]).label}</b>.
				</>
			);
			break;
		case countModerate > 1:
			moderateContent = (
				<>
					{/* Your employees seem to perceive */}
					a <b>moderate level</b> of alignment on <b>{data.strong?.length} biases</b> out of the 7 we
					measure, {data.moderate?.length > 2 ? 'especially but not limited to ' : ' specifically '} the <b>{findBias(data.moderate?.[0]).label}</b> and
					the <b>{findBias(data.moderate?.[1]).label}</b>.
				</>
			);
			break;
		case countModerate === 1:
			moderateContent = (
				<>
					{/* Your employees seem to perceive */}
					a <b>moderate level</b> of alignment on <b>only 1 bias</b> out of the 7 we
					measure, specifically the <b>{findBias(data.moderate?.[0]).label}</b>.
				</>
			);
			break;
		default:
			moderateContent = (
				<>
					{/* Your employees don't seem to perceive */}
					no <b>moderate level</b> of alignment on <b>any of the 7 biases we measure</b>.
				</>
			);
			break;
	}

	switch (true) {
		case countLow === 7:
			lowContent = (
				<>
					{/* Your employees seem to perceive */}
					a <b>low level</b> of alignment on <b>all 7 biases</b> we measure,
					especially but not limited to
					the <b>{findBias(data.low?.[0]).label}</b> and
					the <b>{findBias(data.low?.[1]).label}</b>.
				</>
			);
			break;
		case countLow > 1:
			lowContent = (
				<>
					{/* Your employees seem to perceive */}
					a <b>low level</b> of alignment on <b>{data.strong?.length} biases</b> out of the 7 we
					measure, {data.low?.length > 2 ? 'especially but not limited to ' : ' specifically '} the <b>{findBias(data.low?.[0]).label}</b> and
					the <b>{findBias(data.low?.[1]).label}</b>.
				</>
			);
			break;
		case countLow === 1:
			lowContent = (
				<>
					{/* Your employees seem to perceive */}
					a <b>low level</b> of alignment on <b>only 1 bias</b> out of the 7 we
					measure, specifically the <b>{findBias(data.low?.[0]).label}</b>.
				</>
			);
			break;
		default:
			lowContent = (
				<>
					{/* Your employees don't seem to perceive */}
					no <b>low level</b> of alignment on <b>any of the 7 biases we measure</b>.
				</>
			);
			break;
	}

	return (
		<>
			<b>Based on the comparison of your employees and your own answers...</b>
			<ul>
				<li>{strongContent}</li>
				<li>{moderateContent}</li>
				<li>{lowContent}</li>
			</ul>
		</>
	);
};

export const getAnalysisTextAsMarkdown = ({ data }) => {
	const countStrong = data?.strong?.length;
	const countModerate = data?.moderate?.length;
	const countLow = data?.low?.length;

	console?.log('getAnalysisTextAsMarkdown', {
		countStrong,
		countModerate,
		countLow,
	});
	const replacer = (str) => {
		let replaced = str;
		['strong', 'moderate', 'low'].forEach((level) => {
			numToArray(7).forEach((index) => {
				replaced = replaced.replaceAll(
					`{${level}[${index}]}`,
					findBias(data?.[level]?.[index])?.label,
				);
			});
		});
		replaced = replaced.replaceAll('{countStrong}', countStrong);
		replaced = replaced.replaceAll('{countModerate}', countModerate);
		replaced = replaced.replaceAll('{countLow}', countLow);
		return replaced;
	};

	const inotherwords = [
		'In other words, it seems that you particularly express these specific types of biases in your management.',
		'Therefore, it\'s important for you to focus on the consequences of these particular biases and how to address them.',
		'Keep up the vigilance and engagement for an unbiased management!',
	];

	const additionalInfo = [];
	// return JSON.stringify({
	// 	data, countStrong, countModerate, countLow,
	// });

	if (countStrong === 7) {
		return replacer([
			'Your employees perceive a **high level** for **all biases,**',
			'especially the **{strong[0]}** and the **{strong[1]}.**',
			...additionalInfo,
		].join(' '));
	}

	if (countStrong > 2) {
		return replacer([
			'Your employees perceive a **high level** of bias for **{countStrong} biases** out of 7, ',
			'especially the **{strong[0]}** and the **{strong[1]}.**',
			...additionalInfo,
		].join(' '));
	}

	if (countStrong > 1) {
		return replacer([
			'Your employees perceive a **high level** of bias for **{countStrong} biases** out of 7, ',
			'specifically the **{strong[0]}** and the **{strong[1]}.**',
			...additionalInfo,
		].join(' '));
	}

	if (countStrong === 1) {
		return replacer([
			'Your employees perceive a **high level** of bias for **only {countStrong} bias** out of 7, ',
			'specifically the **{strong[0]}.**',
			...additionalInfo,
		].join(' '));
	}

	if (countModerate === 7) {
		return replacer([
			'Your employees seem to perceive **moderate bias** from you for **all 7 biases.**',
			...additionalInfo,
		].join(' '));
	}

	if (countModerate > 3) {
		return replacer([
			'Your employees don\'t seem to perceive **a lot of bias** from you.',
			'Your most bias is **{moderate[0]}** and **{moderate[1]}.**',
			...additionalInfo,
		].join(' '));
	}

	return 'Your employees don\'t seem to perceive **any bias from you.**';
	// Your employees don't seem to perceive any bias from you.	However, your highest bias seems to be on (b1).	A FAIRE (low)
	// Your employees don't seem to perceive a lot of bias from you.	Your most bias is (b1) and (b2)	A FAIRE (low)
	// Your employees seem to perceive moderate bias from you for all 7 biases. (exprimer que ça concerne bcp de biais)	Especially the (b1) and the (b2)	A FAIRE (moderate)
	// Your employees perceive a high level for {countStrong} bias out of 7.	Specifically the (b1) (and b2)	In other words, it seems that you particularly express these specific types of biases in your management. Therefore, it's important for you to focus on the consequences of these particular biases and how to address them. Keep up the vigilance and engagement for an unbiased management!
	// Your employees perceive a high level for {countStrong} bias(es) out of 7.	Especially the (b1) and the (b2)	In other words, it seems that you particularly express these specific types of biases in your management. Therefore, it's important for you to focus on the consequences of these particular biases and how to address them. Keep up the vigilance and engagement for an unbiased management!
	// Your employees perceive a high level for all biases.	Especially the (b1) and the (b2)	In other words, it seems that you particularly express these specific types of biases in your management. Therefore, it's important for you to focus on the consequences of these particular biases and how to address them. Keep up the vigilance and engagement for an unbiased management!`);
};

export const TestAnalysisTextsAsMarkdown = ({ }) => {
	const cases = [
		700,
		610, 601,
		520, 511, 502,
		430, 421, 412, 403,
		340, 331, 322, 331, 304,
		250, 241, 232, 223, 214, 205,
		160, 151, 142, 133, 124, 115, 106,
		70, 61, 52, 43, 34, 25, 16,
		7,
	];
	return (
		<Space direction='vertical'>
			{cases
				.map((c, index) => {
					const low = c % 10;
					const moderate = ((c - low) / 10) % 10;
					const strong = ((c - moderate * 10 - low) / 100) % 10;
					// console.log('analysis', index, c, { low, moderate, strong });

					const biases = shuffleArr(BIAS_OPTIONS.slice());
					let data = { strong: [], moderate: [], low: [] };
					if (strong > 0) {
						numToArray(strong).forEach(() => {
							const bias = biases.pop();
							data = { ...data || {}, strong: [...data.strong || [], bias.shortId] };
						});
					}
					if (moderate > 0) {
						numToArray(moderate).forEach(() => {
							const bias = biases.pop();
							data = { ...data || {}, moderate: [...data.moderate || [], bias.shortId] };
						});
					}
					if (low > 0) {
						numToArray(low).forEach(() => {
							const bias = biases.pop();
							data = { ...data || {}, low: [...data.low || [], bias.shortId] };
						});
					}
					return (
						<div key={index}>
							<Space direction='horizontal'>
								<Space direction='vertical' size={0} style={{ width: 160 }}>
									{data.strong.map((b, dIndex) => <Space key={`strong${dIndex}`}><div style={{ width: 50 }}><b>{b}:</b></div><BiasLevelTag key={b} level={BIAS_LEVEL_HIGH} /></Space>)}
									{data.moderate.map((b, dIndex) => <Space key={`moderate${dIndex}`}><div style={{ width: 40 }}><b>{b}:</b></div><BiasLevelTag key={b} level={BIAS_LEVEL_MODERATE} /></Space>)}
									{data.low.map((b, dIndex) => <Space key={`low${dIndex}`}><div style={{ width: 40 }}><b>{b}:</b></div><BiasLevelTag key={b} level={BIAS_LEVEL_LOW} /></Space>)}
								</Space>
								{/* <span>{index}/{c}</span> */}
								{/* <span>{JSON.stringify({
									strong,
									moderate,
									low,
									data,
								})}</span> */}
								{/* <span>{JSON.stringify({ results: data })}</span> */}
								<div style={{ maxWidth: 400 }}>
									<ReactMarkdown>
										{getAnalysisTextAsMarkdown({ data })}
									</ReactMarkdown>
								</div>
							</Space>
							<Divider />
						</div>
					);
				})
			}
		</Space>
	);
};

export const TestAnalysisTexts = ({ }) => {
	const cases = [
		700,
		610, 601,
		520, 511, 502,
		430, 421, 412, 403,
		340, 331, 322, 331, 304,
		250, 241, 232, 223, 214, 205,
		160, 151, 142, 133, 124, 115, 106,
		70, 61, 52, 43, 34, 25, 16,
		7,
	];
	return (
		<Space direction='vertical'>
			{cases
				.map((c, index) => {
					const low = c % 10;
					const moderate = ((c - low) / 10) % 10;
					const strong = ((c - moderate * 10 - low) / 100) % 10;
					// console.log('analysis', index, c, { low, moderate, strong });

					const biases = shuffleArr(BIAS_OPTIONS.slice());
					let data = { strong: [], moderate: [], low: [] };
					if (strong > 0) {
						numToArray(strong).forEach(() => {
							const bias = biases.pop();
							data = { ...data || {}, strong: [...data.strong || [], bias.shortId] };
						});
					}
					if (moderate > 0) {
						numToArray(moderate).forEach(() => {
							const bias = biases.pop();
							data = { ...data || {}, moderate: [...data.moderate || [], bias.shortId] };
						});
					}
					if (low > 0) {
						numToArray(low).forEach(() => {
							const bias = biases.pop();
							data = { ...data || {}, low: [...data.low || [], bias.shortId] };
						});
					}
					return (
						<div key={index}>
							<Space direction='horizontal'>
								<Space direction='vertical' size={0} style={{ width: 160 }}>
									{data.strong.map((b, dIndex) => <Space key={`strong${dIndex}`}><div style={{ width: 50 }}><b>{b}:</b></div><BiasLevelTag key={b} level={BIAS_LEVEL_HIGH} /></Space>)}
									{data.moderate.map((b, dIndex) => <Space key={`moderate${dIndex}`}><div style={{ width: 40 }}><b>{b}:</b></div><BiasLevelTag key={b} level={BIAS_LEVEL_MODERATE} /></Space>)}
									{data.low.map((b, dIndex) => <Space key={`low${dIndex}`}><div style={{ width: 40 }}><b>{b}:</b></div><BiasLevelTag key={b} level={BIAS_LEVEL_LOW} /></Space>)}
								</Space>
								<div style={{ maxWidth: 400 }}>
									{getBiasAnalysisText({ data })}
								</div>
							</Space>
							<Divider />
						</div>
					);
				})
			}
		</Space>
	);
};

export const getRadarBiasData = ({
	result,
	personas = [],
}) => {
	const data = arrayWrap(personas)
		.map((p) => {
			const persona = findPersona(p);
			// LocalDebug.logInfo({ className, method: 'getBiasRadarData' }, { p, found: persona?.shortId });
			return {
				...result?.scores?.persona?.[persona?.shortId],
				radarStyle: persona?.style?.radar || {},
			};
		});
	// LocalDebug.logInfo({ className, method: 'getBiasRadarData' }, personas, data);
	return data;
};
