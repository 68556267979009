import { useTheme } from '@emotion/react';
import Box from '../../../app/box/Box';

const GenderScoreLayoutBox = ({
	title,
	children,
	withShadow = true,
	...props
}) => {
	const theme = useTheme();
	return (
		<Box
			withShadow={withShadow}
			{...props}
			style={{
				position: 'relative',
				background: theme.background.gradientWhiteBlueish(),
				borderRadius: 8,
				border: 'none',
				padding: '16px 24px',
				...props?.style,
			}}
		>
			{title && (
				<h3
					style={{
						color: '#333',
						fontSize: 18,
						marginBottom: 10,
					}}
				>
					{title}
				</h3>
			)}
			{children}
		</Box>
	);
};

export default GenderScoreLayoutBox;
