import { isStringTypeValidator } from '../../../utils/isStringTypeValidator';
import { innerObjPropExtractor } from '../../property/property-extractor';
import { innerObjPropMapper } from '../../property/property-mapper';

const USER_PROPERTY_PROFILE_FIRSTNAME_KEY = 'profile-firstname';

const USER_PROPERTY_PROFILE_FIRSTNAME_FREE = true;

const USER_PROPERTY_PROFILE_FIRSTNAME_VALIDATORS = [
	isStringTypeValidator,
];

const USER_PROPERTY_PROFILE_FIRSTNAME_FIELD = 'firstName';
const USER_PROPERTY_PROFILE_FIRSTNAME_MAPPER = innerObjPropMapper(USER_PROPERTY_PROFILE_FIRSTNAME_FIELD);
const USER_PROPERTY_PROFILE_FIRSTNAME_EXTRACTOR = innerObjPropExtractor(USER_PROPERTY_PROFILE_FIRSTNAME_FIELD);

export default {
	key: USER_PROPERTY_PROFILE_FIRSTNAME_KEY,
	USER_PROPERTY_PROFILE_FIRSTNAME_KEY,
	validators: USER_PROPERTY_PROFILE_FIRSTNAME_VALIDATORS,
	USER_PROPERTY_PROFILE_FIRSTNAME_VALIDATORS,
	free: USER_PROPERTY_PROFILE_FIRSTNAME_FREE,
	USER_PROPERTY_PROFILE_FIRSTNAME_FREE,
	mapper: USER_PROPERTY_PROFILE_FIRSTNAME_MAPPER,
	USER_PROPERTY_PROFILE_FIRSTNAME_MAPPER,
	extractor: USER_PROPERTY_PROFILE_FIRSTNAME_EXTRACTOR,
	USER_PROPERTY_PROFILE_FIRSTNAME_EXTRACTOR,
	field: USER_PROPERTY_PROFILE_FIRSTNAME_FIELD,
	USER_PROPERTY_PROFILE_FIRSTNAME_FIELD,
};
