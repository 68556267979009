import { innerObjPropExtractor } from '../../property/property-extractor';
import { innerObjPropMapper } from '../../property/property-mapper';

const USER_PROPERTY_AUTH_EMAIL_VERIFIED_KEY = 'auth-email-verified';

const USER_PROPERTY_AUTH_EMAIL_VERIFIED_FREE = false;

const USER_PROPERTY_AUTH_EMAIL_VERIFIED_FIELD = 'auth.emailVerified';
const USER_PROPERTY_AUTH_EMAIL_VERIFIED_MAPPER = innerObjPropMapper(USER_PROPERTY_AUTH_EMAIL_VERIFIED_FIELD);
const USER_PROPERTY_AUTH_EMAIL_VERIFIED_EXTRACTOR = innerObjPropExtractor(USER_PROPERTY_AUTH_EMAIL_VERIFIED_FIELD);

export default {
	key: USER_PROPERTY_AUTH_EMAIL_VERIFIED_KEY,
	USER_PROPERTY_AUTH_EMAIL_VERIFIED_KEY,
	free: USER_PROPERTY_AUTH_EMAIL_VERIFIED_FREE,
	USER_PROPERTY_AUTH_EMAIL_VERIFIED_FREE,
	mapper: USER_PROPERTY_AUTH_EMAIL_VERIFIED_MAPPER,
	USER_PROPERTY_AUTH_EMAIL_VERIFIED_MAPPER,
	extractor: USER_PROPERTY_AUTH_EMAIL_VERIFIED_EXTRACTOR,
	USER_PROPERTY_AUTH_EMAIL_VERIFIED_EXTRACTOR,
	field: USER_PROPERTY_AUTH_EMAIL_VERIFIED_FIELD,
	USER_PROPERTY_AUTH_EMAIL_VERIFIED_FIELD,
};
