import { useContext } from 'react';
import { useAuthContext } from '../../hooks/useAuthContext';
import { addSourcing } from '../../actions/sourcings';
import {
	ADD_TO_TALENT_POOL_LABEL,
	STAGE_STARTED,
} from '../../constants/constant';
import { COMPANY_RECRUITER_LOG } from '../../constants/company-recruiter-log';
import { documentId, writeLog } from '../../utils/common';
import { LocalDebug } from '../../utils/LocalDebug';
import { writeRecruiterLog } from '../../actions/logging';
import UserModel from '../../models/UserModel';
import { UserSourceModalContext } from './UserSourceModalProvider';
import SourcingForm from '../../components/sourcings/SourcingForm';
import FormBoxTitle from '../../components/app/form/FormBoxTitle';
import { NotificationContext } from '../../components/app/notification/NotificationProvider';
import ModalContainerBase from './ModalContainerBase';
import { shouldRefreshData } from '../../actions/app';
import { useDispatch } from 'react-redux';
import SourcingModel from '../../models/SourcingModel';
import GenderhireCandidatesSourcedMenu, { GenderhireCandidatesSourcedIcon } from '../../menu/genderhire/GenderhireCandidatesSourcedMenu';
import { SOURCE_50INTECH_VALUE, SOURCE_RECRUITER_VALUE } from '../../constants/sourcing';

const UserSourceModalContainer = () => {
	const className = 'UserSourceModalContainer';

	const dispatch = useDispatch();

	const {
		isAtsSyncable, isStaffView, isTalent, companyIdsUser,
	} = useAuthContext();

	const {
		open,
		setOpen,
		user,
		onStart,
		onComplete,
	} = useContext(UserSourceModalContext);

	const { notifyLoading, notifySuccess } = useContext(NotificationContext);

	const handleSubmit = async (values) => {
		const method = 'handleSubmit';
		await onStart?.();
		setOpen(false);
		const valuesToDb = { ...values };
		writeLog(`${className} handleSubmit`, valuesToDb);

		notifyLoading({ key: className });

		const sourcing = new SourcingModel(await addSourcing(valuesToDb));
		LocalDebug.logInfo({ className, method }, { sourcing });

		writeRecruiterLog(
			COMPANY_RECRUITER_LOG.SOURCE,
			{
				userTargetId: documentId(user),
				sourcingTargetId: documentId(sourcing),
			},
		);

		const candidatesAtsMenu = new GenderhireCandidatesAtsMenu();
		const candidatesSourcedMenu = new GenderhireCandidatesSourcedMenu();
		const menu = isAtsSyncable ? candidatesAtsMenu : candidatesSourcedMenu;

		const link = <a href={menu.path}>{menu.icon}&nbsp;{menu.label}</a>;

		notifySuccess({
			key: className,
			description: <>
				<span><b>{new UserModel(user).firstName}</b> was added to your {link} for
					your <b>{sourcing?.job?.title}</b> position.</span>
			</>,
		});

		dispatch(shouldRefreshData());

		await onComplete?.(sourcing);
		return sourcing;
	};

	return (
		<ModalContainerBase
			open={open}
			setOpen={setOpen}
			width={600}
			footer={false}
		>
			<FormBoxTitle
				title={<><span><GenderhireCandidatesSourcedIcon /> {ADD_TO_TALENT_POOL_LABEL}</span></>}
			/>
			<SourcingForm
				initialValues={{
					userId: documentId(user),
					source: isTalent ? SOURCE_50INTECH_VALUE : SOURCE_RECRUITER_VALUE,
					stage: STAGE_STARTED,
					user,
					viaProfile: true,
					companyIds: !isStaffView ? companyIdsUser : null,
				}}
				withSource={false}
				withFormHistoryItems={false}

				onClose={() => setOpen(false)}
				onSubmit={handleSubmit}
				onReset={() => setOpen(false)}
			/>
		</ModalContainerBase>
	);
};

export default UserSourceModalContainer;
