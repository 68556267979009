import DEI_CRITERIA_GROUP from '../../property/dei-criteria-group';
import { innerObjPropExtractor } from '../../property/property-extractor';
import { innerObjPropMapper } from '../../property/property-mapper';

const USER_PROPERTY_JOB_SEARCH_DEI_CRITERIA_GROUP_KEY = 'job-search-dei-criteria-group';

const USER_PROPERTY_JOB_SEARCH_DEI_CRITERIA_GROUP_MULTI = true;
const USER_PROPERTY_JOB_SEARCH_DEI_CRITERIA_GROUP_FREE = false;
const USER_PROPERTY_JOB_SEARCH_DEI_CRITERIA_GROUP_MAX = 3;

const USER_PROPERTY_JOB_SEARCH_DEI_CRITERIA_GROUP_FIELD = 'jobSearch.deiCriteriaGroup';
const USER_PROPERTY_JOB_SEARCH_DEI_CRITERIA_GROUP_MAPPER = innerObjPropMapper(USER_PROPERTY_JOB_SEARCH_DEI_CRITERIA_GROUP_FIELD);
const USER_PROPERTY_JOB_SEARCH_DEI_CRITERIA_GROUP_EXTRACTOR = innerObjPropExtractor(USER_PROPERTY_JOB_SEARCH_DEI_CRITERIA_GROUP_FIELD);

export default {
	...DEI_CRITERIA_GROUP,
	options: DEI_CRITERIA_GROUP.options,
	key: USER_PROPERTY_JOB_SEARCH_DEI_CRITERIA_GROUP_KEY,
	USER_PROPERTY_JOB_SEARCH_DEI_CRITERIA_GROUP_KEY,
	free: USER_PROPERTY_JOB_SEARCH_DEI_CRITERIA_GROUP_FREE,
	USER_PROPERTY_JOB_SEARCH_DEI_CRITERIA_GROUP_FREE,
	multi: USER_PROPERTY_JOB_SEARCH_DEI_CRITERIA_GROUP_MULTI,
	USER_PROPERTY_JOB_SEARCH_DEI_CRITERIA_GROUP_MULTI,
	max: USER_PROPERTY_JOB_SEARCH_DEI_CRITERIA_GROUP_MAX,
	USER_PROPERTY_JOB_SEARCH_DEI_CRITERIA_GROUP_MAX,
	mapper: USER_PROPERTY_JOB_SEARCH_DEI_CRITERIA_GROUP_MAPPER,
	USER_PROPERTY_JOB_SEARCH_DEI_CRITERIA_GROUP_MAPPER,
	extractor: USER_PROPERTY_JOB_SEARCH_DEI_CRITERIA_GROUP_EXTRACTOR,
	USER_PROPERTY_JOB_SEARCH_DEI_CRITERIA_GROUP_EXTRACTOR,
	field: USER_PROPERTY_JOB_SEARCH_DEI_CRITERIA_GROUP_FIELD,
	USER_PROPERTY_JOB_SEARCH_DEI_CRITERIA_GROUP_FIELD,
};
