import { useTheme } from '@emotion/react';
import { Space } from 'antd';
import UserAvatar from '../../user/widgets/UserAvatar';
import Box from '../../app/box/Box';
import CSM_DETAILS from '../../../constants/csmDetails';

const CsmSignature = (
	{
		data = CSM_DETAILS.defaultCsm,
		...props
	},
) => {
	const className = 'CsmSignature';

	const theme = useTheme();

	return (
		<Box
			style={{
				border: 'none',
				width: 'auto',
				display: 'inline-block',
				marginLeft: -8,
				...props?.style,
			}}
		>

			<Space>
				<UserAvatar
					user={data?.user}
					withCompanyLogo={false}
					withTooltip={false}
					withIsNew={false}
					withIsUnviewed={false}
					withQuickLook={false}
					forceShow={true}
				/>

				<Space
					direction='vertical'
					size={0}
					style={{
						fontSize: 12,
						color: theme.color.grey,
						fontWeight: 'bold',
					}}
				>
					{data?.buildBody({ ...data, theme })}
				</Space>

			</Space>
		</Box>

	);
};

export default CsmSignature;
