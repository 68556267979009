import { FireOutlined } from '@ant-design/icons';
import StaffCompanyActionPlanListPage from '../../../containers/genderscore/StaffCompanyActionPlanListPage';
import { PERM_GRANT_TYPE } from '../../menu.utils';
import roles from '../../../config/roles';
import MenuBase from '../../MenuBase';

export const StaffGSCompanyActionPlanListIcon = FireOutlined;

class StaffGSCompanyActionPlanListMenu extends MenuBase {
	static props = {
		name: 'staff-company-action-plan-list',
		path: '/staff/company/action-plan',
		label: 'Action plan v2 List',
		icon: <StaffGSCompanyActionPlanListIcon />,
		iconLegacy: '🎯',
		Component: StaffCompanyActionPlanListPage,
		perms: [
			{
				type: PERM_GRANT_TYPE,
				roles: [
					roles.STAFF,
				],
			},
		],
	};

	constructor() {
		super(StaffGSCompanyActionPlanListMenu.props);
	}
}

export default StaffGSCompanyActionPlanListMenu;
