import { Space, Switch } from 'antd';
import { useContext, useEffect } from 'react';
import { UserSavedSearchContext } from './UserSavedSearchProvider';
import { LocalDebug } from '../../utils/LocalDebug';
import { addMultipleQueryParams } from '../../utils/common';

const SearchTalentsCustomFilters = (
	{
		user,
		...props
	},
) => {
	const className = 'SearchTalentsCustomFilters';

	const {
		excludedViewed, setExcludedViewed,
	} = useContext(UserSavedSearchContext);

	const handleChange = (setter) => {
		LocalDebug.logInfo({ className, method: 'handleChange' }, { excludedViewed });
		setter?.();
	};

	return (
		<>
			<Space>
				<Switch
					checked={excludedViewed}
					onChange={() => handleChange(() => setExcludedViewed(!excludedViewed))}
				/>
				<span>Hide viewed profiles</span>
				{/* Former copy: Exclude viewed profiles */}
			</Space>
		</>
	);
};

export default SearchTalentsCustomFilters;
