/* eslint-disable import/no-named-as-default-member */
import { useTheme } from '@emotion/react';
import { useState } from 'react';
import { Tag, Tooltip, Typography } from 'antd';
import Table from '../app/table/Table';
import TableColumns from '../app/table/TableColumns';
import ObjectIdDisplayer from '../app/ObjectIdDisplayer';
import UserDrawerProfile from '../user/UserDrawerProfile';

const DIGEST_STATUS_COLOR = {
	'digest-ready': 'gold',
	'digest-sending': 'orange',
	'digest-sent': 'green',
	'digest-deprecated': '#CCC',
	'digest-failed': 'red',
};
const SavedSearchesDigestsTable = (
	{
		onDataLoaded,
		path = '/admin/saved-searches/digests',
		filterOptions = {},
		isRefresh = 0,
		onSearchDataColumn,
		onToggleIsActive,
		onEditArticle,
		isEditor,
		...props
	},
) => {
	const className = 'SavedSearchesDigestsTable';

	const theme = useTheme();

	const [isRefreshDataLayout, setIsRefreshDataLayout] = useState(0);
	const [openProfile, setOpenProfile] = useState(false);
	const [activeTab, setActiveTab] = useState(null);
	const [userSelected, setUserSelected] = useState(null);

	const handleCloseProfile = () => {
		setUserSelected(null);
		setActiveTab(null);
		setOpenProfile(false);
	};

	const recipientStubCol = TableColumns.userStubColumn(theme, {
		title: <Tooltip title='User who received the sending (recipient field)'>To</Tooltip>,
		dataIndex: 'user',
		withDropdown: true,
		sorter: false,
	});

	const mailingIdCol = {
		title: 'mailingId',
		dataIndex: ['mailing', 'mailingId'],
		removeInColumnFilter: true,
		sorter: true,
		align: 'center',
		render: (value, row) => (
			<ObjectIdDisplayer
				id={value}
				withTooltip={true}
			/>),
		...TableColumns.columnFixedWidth(120),
	};

	const createdAtCol = TableColumns.createdAtColumn(theme);
	const updatedAtCol = TableColumns.updatedAtColumn(theme);

	const statusCol = {
		title: 'Status',
		dataIndex: 'status',
		align: 'center',
		sorter: true,
		removeInColumnFilter: true,
		render: (value, row) => {
			return value
				? <Tag
					color={DIGEST_STATUS_COLOR[value] || 'purple'}
					style={{
						margin: 0,
						fontSize: 11,
						padding: '2px 4px',
						lineHeight: 1,
						textTransform: 'uppercase',
						fontWeight: 'bold',
					}}>
					{value}
				</Tag>
				: <Typography.Text disabled italic>N/A</Typography.Text>;
		},
		...TableColumns.columnFixedWidth(140),
	};
	const mailingStatusCol = {
		title: 'Mailing Status',
		dataIndex: ['mailing', 'status'],
		align: 'center',
		sorter: true,
		removeInColumnFilter: true,
		render: (value, row) => {
			return value
				? <Tag
					color={value === 'success' ? 'green' : 'volcano'}
					style={{
						margin: 0,
						fontSize: 11,
						padding: '2px 4px',
						lineHeight: 1,
						textTransform: 'uppercase',
						fontWeight: 'bold',
					}}>
					{value}
				</Tag>
				: <Typography.Text disabled italic>N/A</Typography.Text>;
		},
		...TableColumns.columnFixedWidth(140),
	};

	const mailingSentAtCol = TableColumns.dateColumn(theme, {
		title: 'Sent',
		dataIndex: ['mailing', 'sentAt'],
	});
	const jsonCol = TableColumns.jsonColumn(theme, TableColumns.columnFixedWidth(50));

	const columns = [
		jsonCol,

		recipientStubCol,
		statusCol,
		mailingIdCol,
		mailingStatusCol,
		createdAtCol,
		updatedAtCol,
		mailingSentAtCol,
	];

	return (
		<>
			<Table
				path={path}
				// ItemClass={UserMailingModel}
				onDataLoaded={onDataLoaded}
				columns={columns}
				filterOptions={filterOptions}
				isRefresh={isRefresh + isRefreshDataLayout}
				onSearchDataColumn={onSearchDataColumn}
				showTotal={(total) => `Total ${total} items`}
				limit={10}
				rowClassName={(row, index) => (row.is50Content === false ? 'rowDisabled' : '')}
				withDynamicSearch
				{...props}
			/>

			<UserDrawerProfile

				user={userSelected}
				activeTab={activeTab}
				open={openProfile}
				onClose={handleCloseProfile}
			/>
		</>
	);
};

export default SavedSearchesDigestsTable;
