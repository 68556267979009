import { useContext } from 'react';
import { useTheme } from '@emotion/react';
import {
	areAllOptionalStepsCompleted,
	getOptionalSteps,
} from './CompanyPageSteps';
import { CompanyPageContext } from './CompanyPageProvider';
import Popin from '../../../app/modal/Popin';
import { Button, Divider, Space } from 'antd';
import { FaBan, FaCheck } from 'react-icons/fa';
import FormBoxTitle from '../../../app/form/FormBoxTitle';
import CompanyPagePreviewButton from './CompanyPagePreviewButton';
import { buildStepList } from './CompanyPageHeroMessage';
import CompanyPageStepTagList from './CompanyPageStepTagList';
import { ENGLISH_LANG } from '../../../../constants/constant';
import CsmSignature from '../../../onboarding/shared/CsmSignature';
import { CompanyContext } from '../../../../contexts/CompanyProvider';
import ModalContainerBase from '../../../../contexts/modals/ModalContainerBase';

const CompanyPagePublicationPopin = (
	{
		company,
		...props
	},
) => {
	const className = 'CompanyPagePublicationPopin';

	const theme = useTheme();

	const { csmDetails } = useContext(CompanyContext);

	const {
		lang,
		langTexts,
		openPublicationPopin,
		setOpenPublicationPopin,
		publishCompanyPage,
	} = useContext(CompanyPageContext);

	const handleConfirm = async () => {
		await publishCompanyPage(() => setOpenPublicationPopin(false));
	};

	return (
		<ModalContainerBase
			open={openPublicationPopin}
			width={600}
			onCancel={() => setOpenPublicationPopin(false)}
		>
			<FormBoxTitle
				title={<>Publish your company page{langTexts.labelIn}</>}
			/>
			<p>You're ready to publish your company page{langTexts.labelIn}!</p>
			<p>
				Want to see how it will be displayed before publication?&nbsp;
				<CompanyPagePreviewButton company={company} />
			</p>
			{!areAllOptionalStepsCompleted({ company, lang }) && (
				<>
					<p>
					As a reminder, here are the optional content not yet {lang === ENGLISH_LANG ? 'filled' : 'translated'}:
					</p>
					<CompanyPageStepTagList
						steps={getOptionalSteps({ company, lang })}
						company={company}
						withFilled={false}
						onClick={() => setOpenPublicationPopin(false)}
					/>
				</>
			)}

			<CsmSignature data={csmDetails} style={{ marginTop: 20 }} />

			<Divider style={{ marginTop: 0 }} />
			<Space
				wrap={false}
				style={{
					width: '100%',
					justifyContent: 'space-between',
				}}
			>
				<Button
					size='large'
					onClick={() => setOpenPublicationPopin(false)}
				>
					<FaBan style={{ marginBottom: -2, marginRight: 12 }} />
					<b>Not yet</b>
				</Button>

				<Button
					type='primary'
					size='large'
					onClick={handleConfirm}
				>
					<FaCheck style={{ marginBottom: -2, marginRight: 12 }} />
					<b>Yes, publish</b>
				</Button>
			</Space>
		</ModalContainerBase>
	);
};

export default CompanyPagePublicationPopin;
