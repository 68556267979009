import { Drawer as AntDrawer, Space, Spin } from 'antd';
import React, { useState } from 'react';
import { FaTimes } from 'react-icons/fa';

const Drawer = ({
	renderTitle,
	title,
	open,
	onClose,
	placement = 'right',
	width = 640,
	content,
	contentAfterOpen,
	children,
	...props
}) => {
	const [isLoadContent, setIsLoadContent] = useState(false);

	const handleAfterOpenChange = () => {
		setIsLoadContent(open);
	};

	return (
		<AntDrawer
			title={renderTitle ? renderTitle() : title}
			width={width}
			placement={placement}
			onClose={onClose}
			open={open}
			afterOpenChange={handleAfterOpenChange}
			{...props}
			closeIcon={<FaTimes size={24} />}
		>
			{contentAfterOpen && (isLoadContent
				? contentAfterOpen
				: <Space style={{
					display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%', height: 200,
				}} >
					<Spin size="large" />
				</Space>
			)}
			{content}
			{children}
		</AntDrawer>
	);
};

export default Drawer;
