import { useTheme } from '@emotion/react';
import {
	Button,
	Space,
	// Divider, Space,
} from 'antd';
// import UMSSurveyDraftStartForm from './UMSSurveyDraftStartForm';
import Box from '../../../app/box/Box';
import ReactJsonDebug from '../../../app/debug/ReactJsonDebug';
import { useContext, useEffect, useState } from 'react';
import { SurveyContext } from '../providers/SurveyProvider';
import { UMS_SURVEY_MANAGER_GROUP_VALUE } from '../../../../constants/property/ums-survey';
import { LocalDebug } from '../../../../utils/LocalDebug';
import { BiChevronRight } from 'react-icons/bi';
import ReactMarkdown from 'react-markdown';
import { getSurveyAnsweredAndUnansweredQuestions } from '../../data/shared';
import SURVEYS from '../../data/surveys';

const UMSSurveyDraftStart = ({
	survey,
	...props
}) => {
	const className = 'UMSSurveyDraftStart';

	const theme = useTheme();

	const {
		setSurveyDraft, dispatchSelectedSurveyAndReload, appLang, t,
	} = useContext(SurveyContext);
	// const {
	// 	createOrUpdateSurveyDraft,
	// 	dispatchSelectedSurveyAndReload,
	// 	appLang, t,
	// } = useContext(SurveyContext);
	// const [label, setLabel] = useState('');
	const [buttonSublabel, setButtonSublabel] = useState(null);

	// useEffect(() => {
	// 	if (!survey) return setLabel('');
	// 	LocalDebug.logUseEffect({ className, effects: 'survey' }, { surveyGroup: survey?.surveyGroup });
	// 	setLabel(survey?.surveyGroup === UMS_SURVEY_MANAGER_GROUP_VALUE ? 'manager' : 'employee');
	// }, [survey]);

	useEffect(() => {
		LocalDebug.logUseEffect({ className, effects: 'currentUserDraft, appLang' }, { survey, appLang });
		const surveyConf = SURVEYS?.getSurveyDataOption?.(survey?.survey);
		const { unanswered } = getSurveyAnsweredAndUnansweredQuestions({
			surveyConf,
			answers: survey?.answers,
		});

		if (unanswered?.length > 0) {
			setButtonSublabel(
				<div>
					{t(`survey-resume.${unanswered?.length < 10 ? 'only' : 'still'}`)}
					{t('survey-resume.answers-left', { count: unanswered?.length, plural: unanswered?.length > 1 ? 's' : '' })}
				</div>,
			);
		} else {
			setButtonSublabel(t('survey-resume.all-answered'));
		}
	}, [survey, appLang]);

	const handleClick = () => {
		setSurveyDraft(survey);
	};

	return (
		<>
			<div
				style={{
					maxWidth: 860,
					width: '100%',
					margin: '0 auto',
					fontSize: 16,
					display: 'grid',
					// height: '100vh',
					gridTemplateColumns: '1fr',
					justifyContent: 'center',
					alignItems: 'center',
				}}
			>
				<div
					style={{
						boxShadow: '0px 15px 60px -12px rgba(0,0,0,.3)',
						padding: 30,
						borderRadius: 12,
					}}
				>

					<Space
						wrap={false}
						align={'top'}
						size={30}
						style={{
							width: '100%',
							marginBottom: 30,
						}}
					>
						{/* {UMS_EMPTY_ICON_IMG({ width: 120 })} */}

						<div style={{ fontSize: 16 }}>
							<h1>{t('survey-resume.title')}</h1>
							<ReactMarkdown>{t('survey-resume.body')}</ReactMarkdown>
						</div>
					</Space>

					<div>
						<Button
							type='primary'
							size={'large'}
							style={{
								width: '100%',
								borderRadius: 10,
								height: 110,
							}}
							// iconPlacement={'right'}
							onClick={handleClick}
						>
							<Space
								direction='horizontal'
								style={{
									width: '100%',
									justifyContent: 'space-between',
									alignItems: 'center',
								}}
							>
								<div />
								<div>
									<div
										style={{
											fontSize: 24,
											lineHeight: 1.3,
											fontWeight: 'bold',
											marginBottom: 8,
										}}
									>
										{t('survey-resume.resume-btn')}
									</div>
									{buttonSublabel}
								</div>
								<BiChevronRight
									style={{
										fontSize: 42,
										marginBottom: -6,
									}}
								/>
							</Space>
						</Button>
					</div>

				</div>
			</div>
			{/*
			<Box>
				<Button
					size={'large'}
					type='primary'
					style={{
						paddingTop: 30,
						paddingBottom: 55,
						fontSize: 24,
						fontWeight: 'bold',
						lineHeight: 1.3,
						width: '100%',
						borderRadius: 10,
					}}
					onClick={handleClick}
				>
					Start your {label} survey
				</Button>

			</Box> */}
			{/* <ReactJsonDebug
				src={survey || {}}
				collapsed={0}
			/> */}

		</>
	);

	// return (
	// 	<>
	// 		<div
	// 			style={{
	// 				// maxWidth: 860,
	// 				// margin: '40px auto 0',
	// 				fontSize: 16,
	// 			}}
	// 		>
	// 			<Space
	// 				wrap={false}
	// 				align={'top'}
	// 				size={30}
	// 			>
	// 				{/* {UMS_EMPTY_ICON_IMG({ width: 210 })} */}

	// 				<div style={{ fontSize: 16 }}>
	// 					<h1>
	// 					Welcome! 🌟
	// 					</h1>
	// 					<p>
	// 					This assessment consists of a series of questions.
	// 					</p>
	// 					<p>
	// 					Your progress will be saved along
	// 					the way so you can come back to resume where you left off.
	// 					</p>
	// 					<p>
	// 						<b>
	// 						Your efforts toward creating a more equitable workplace are truly commendable.
	// 						Let's get started on this journey together!
	// 						</b>
	// 					</p>
	// 				</div>
	// 			</Space>

	// 			<Divider />

	// 			<UMSSurveyDraftStartForm />

	// 		</div >
	// 	</>
	// );
};

export default UMSSurveyDraftStart;
