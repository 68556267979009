import {
	DatePicker,
	Divider, Form, InputNumber, Space,
} from 'antd';
import { useEffect, useState } from 'react';
import { useTheme } from '@emotion/react';
import { FaCheck, FaExclamationCircle, FaExclamationTriangle } from 'react-icons/fa';
import FormItem from '../../form/FormItem';
import { GENDERSCORE_PILLAR_OPTIONS_LIST } from '../../../../constants/genderscore-pillars';

const ScoreInput = (
	{
		value,
		onChange,
		key,
	},
) => (
	<InputNumber
		key={key}
		size='middle'
		style={{ width: 100 }}
		min={0} max={100}
		addonAfter='%'
		defaultValue={value}
		// initialValue={value}
		onChange={onChange}
	/>
);

const CompanyGenderScoreForm = (
	{
		initialValues = {},
		onValuesChange,
	},
) => {
	const className = 'CompanyGenderScoreForm';
	const theme = useTheme();

	const [form] = Form.useForm();

	const [computeAvgBlock, setComputeAvgBlock] = useState(null);

	const recomputeAvgBlock = (values) => {
		const method = 'recomputeAvgBlock';
		const {
			globalScore,
			genderData,
			fairCareerPath,
			equalPay,
			worklifeBalance,
			deiActions,
		} = { ...form.getFieldsValue(), ...values };
		// LocalDebug.logInfo({ className, method }, {
		// 	globalScore, genderData, fairCareerPath, equalPay, worklifeBalance, deiActions,
		// });
		const average = Math.round(((genderData || 0) + (fairCareerPath || 0) + (equalPay || 0) + (worklifeBalance || 0) + (deiActions || 0)) / 5 * 100) / 100;
		const roundedAverage = Math.round(average);
		const pillarAvgText = (
			<span style={{
				fontSize: 12, fontStyle: 'italic', color: theme.color.darkgrey,
			}}>
				Average of the 5 Pillars: <b>{average}</b>
			</span>
		);
		const diffText = <>{(average - globalScore) > 0 ? '+' : ''}{roundedAverage - globalScore}</>;

		const calloutStyle = {
			// border: '1px dotted ' + theme.color.darkturquoise,
			// color: mixColors(theme.color.darkturquoise, theme.color.black, 50),
			display: 'inline-block',
			borderRadius: 6,
			padding: '4px 10px',
			fontSize: 12,
			lineHeight: 1.3,
			background: theme.color.darkturquoise,
			color: theme.color.white,
		};

		// LocalDebug.logInfo({ className, method }, { values, roundedAverage, globalScore });

		if (roundedAverage === globalScore) {
			setComputeAvgBlock(<>
				{pillarAvgText}
				<span style={calloutStyle}>
					<FaCheck style={{ marginBottom: -2, marginRight: 6 }} />
					Same as Global Score, all seems good!
				</span>
			</>);
		} else if (Math.abs(average - globalScore) <= 1) {
			setComputeAvgBlock(<>
				{pillarAvgText}
				<span style={{ ...calloutStyle, background: theme.color.fitBlueElectric }}>
					<FaExclamationCircle style={{ marginBottom: -2, marginRight: 6 }} />
					<b>{diffText}</b> vs. Global Score. <br />
					Acceptable error margin, may be due to roundings.
				</span>
			</>);
		} else {
			setComputeAvgBlock(<>
				{pillarAvgText}
				<span style={{ ...calloutStyle, background: theme.color.red }}>
					<FaExclamationTriangle style={{ marginBottom: -2, marginRight: 6 }} />
					<b>{diffText}</b> vs. Global Score. <br />
					Something may be wrong!
				</span>
			</>);
		}
	};

	useEffect(() => {
		recomputeAvgBlock();
	}, []);

	return (
		<>
			<Form
				form={form}
				name='company-form'
				initialValues={{ ...initialValues }}
				labelCol={{ span: 12 }}
				labelAlign='right'
				wrapperCol={{ span: 12 }}
				onValuesChange={(values) => {
					recomputeAvgBlock(values);
					onValuesChange(values);
				}}
			>
				<FormItem
					label={<span style={{ fontSize: 18 }}>Global score</span>}
					name={'globalScore'}
				>
					<ScoreInput />
				</FormItem>

				<FormItem
					label={<span>Completed on</span>}
					name={'createdAt'}
				>
					<DatePicker />
				</FormItem>

				<Divider>Pillars</Divider>

				{GENDERSCORE_PILLAR_OPTIONS_LIST
					.map((option) => (
						<FormItem
							key={option.value}
							label={option.label}
							name={option.value}
						>
							<ScoreInput />
						</FormItem>
					))}

				<Space
					direction='vertical'
					size={3}
					style={{
						width: '100%',
						justifyContent: 'center',
						alignItems: 'center',
						textAlign: 'center',
					}}
				>
					{computeAvgBlock}
				</Space>
			</Form>
		</>
	);
};

export default CompanyGenderScoreForm;
