import TextArea from 'antd/es/input/TextArea';
import { useEffect, useRef } from 'react';

const SurveyQuestionInputTextarea = ({
	question,
	value,
	onChange,
}) => {
	const className = 'SurveyQuestionInputTextarea';

	const inputRef = useRef();

	useEffect(() => {
		inputRef?.current?.focus?.();
	}, [question]);

	return (
		<TextArea
			ref={inputRef}
			onChange={(e) => onChange(e.target.value)}
			value={value}
		/>
	);
};

export default SurveyQuestionInputTextarea;
