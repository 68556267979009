import { useState } from 'react';
import {
	Button,
	Row,
	Col,
	Divider,
	Form,
	Space,
	Steps, Card,
} from 'antd';
import { useTheme } from '@emotion/react';
import { useSelector } from 'react-redux';
import { LocalDebug, localDebug } from '../../utils/LocalDebug';

import {
	sourcingSources,
	SOURCING_ACTION_STATUS,
	SOURCING_ACTION_COMMENT,
	STAGE_STARTED,
} from '../../constants/constant';
import FormItem, { FormItemContainer } from '../app/form/FormItem';
import FormSelect from '../app/form/FormSelect';
import { getCompanies } from '../../reducers/app';
import UserSelect from '../user/UserSelect';
import JobSelect from '../jobs/JobSelect';
import {
	DownCircleOutlined,
	MinusCircleOutlined,
	PlusOutlined,
	UpCircleOutlined,
} from '@ant-design/icons';
import { documentId, getCurrentSteps, requiredRule } from '../../utils/common';
import SourcingActionPopover from './SourcingActionPopover';
import moment from 'moment';
import { useAuthContext } from '../../hooks/useAuthContext';
import UserStub from '../user/stub/UserStub';
import FormCompanySelect from '../app/form/FormCompanySelect';
import FormCancelSubmitButtons from './FormCancelSubmitButtons';

const SourcingForm = (
	{
		initialValues = {},
		withFormHistoryItems = true,
		viaShortlist = false,
		withJob = true,
		onSubmit,
		onReset,
	},
) => {
	const className = 'SourcingForm';
	const theme = useTheme();
	const companies = useSelector(getCompanies);

	LocalDebug.logInfo({ className }, { companies: companies?.map((c) => c?.name) });
	const { isTalent, isStaffView, currentUser } = useAuthContext();

	const viaProfile = initialValues?.viaProfile || false;
	const viaJob = initialValues?.viaJob || false;
	const defaultSource = initialValues?.source;

	// -------------------------------------------- //
	// -------------- state ----------------------- //
	// -------------------------------------------- //
	const [isActive] = useState(initialValues?.isActive !== false);

	const [companyId, setCompanyId] = useState(initialValues?.companyId);
	const [jobId, setJobId] = useState();

	const [companyIdJob, setCompanyIdJob] = useState(initialValues?.companyId);
	const [companyIds, setCompanyIds] = useState(initialValues?.companyIds);

	// --------------------------------------- //
	// ------- state for steps --------------- //
	// --------------------------------------- //
	const [openStepPopover, setOpenStepPopover] = useState(false);
	const [initValuesStepForm, setInitValuesStepForm] = useState({});
	const [titleStepPopover, setTitleStepPopover] = useState('Change status');

	// default action sourcing infos
	const [actionSourcingInfos, setActionSourcingInfos] = useState({
		// status: NEW_STATUS,
		// updatedStatusAt: moment(),
		// step: DEFAULT_STEP_VALUE,
		// updatedStepAt: moment(),
		stage: STAGE_STARTED,
		updatedStageAt: moment(),
	});

	const [form] = Form.useForm();

	const handleSubmit = (values) => {
		const data = {
			creatorId: documentId(currentUser),
			source: defaultSource,
			companyId: companyIdJob,
			...values,
			isActive,
			id: documentId(initialValues) || null,
			...actionSourcingInfos,
		};
		onSubmit(data);
		form.resetFields();
	};

	const handleChangeJob = (value, option) => {
		setJobId(value);
		// set companyId vale
		setCompanyIdJob(option?.companyId);
	};

	const handleChangeCompany = (value) => {
		if (value) {
			setCompanyId(value);
			setCompanyIds([value]);
		} else {
			setCompanyId(null);
			setCompanyIds([]);
		}
		form.setFieldsValue({ jobId: null });
	};

	// ------------------------------------------------- //
	// ----- open pop add step (status or comment) ----- //
	// ------------------------------------------------- //
	const handleAddStep = (add, action, title) => {
		setOpenStepPopover(true);
		setInitValuesStepForm({
			add,
			action,
			author: documentId(currentUser),
			defaultUserOptions: currentUser,
		});
		// set title step popover
		setTitleStepPopover(title);
	};

	// -------------------------------------- //
	// -------- on submit step form --------- //
	// -------------------------------------- //
	const handleStepSubmit = (values) => {
		const {
			add,
			// status,
			// updatedStatusAt,
			// step,
			// updatedStepAt,
			...rest
		} = values;
		// add history in list
		rest.history.forEach((action) => add(action));
		// set status and step infos
		setActionSourcingInfos((prev) => ({
			...prev,
			// ...(status && {status, updatedStatusAt}),
			// ...(step && {step, updatedStepAt})
		}));
		setOpenStepPopover(false);
	};

	const UserFormItem = (
		{
			selectMode = true,
			disabled = false,
			...props
		},
	) => <FormItem
		label="Talent"
		name="userId"
		defaultContainer="div"
		rules={[requiredRule]}
	>
		{!selectMode
			? <Card
				size="small"
				style={{ borderRadius: 4, width: '100%' }}
			>
				<UserStub user={props?.user}/>
			</Card>
			: <UserSelect
				defaultOptions={props?.user ? [props?.user] : []}
				placeholder="Select a user"
				style={{ width: '100%' }}
				disabled={disabled}
				notUserIds={props?.notUserIds}
			/>
		}
	</FormItem>;

	const SourceFormItem = (
		{
			disabled = false,
			...props
		},
	) => <FormItem
		label="Sourcing method"
		defaultContainer="div"
		name="source"
		rules={[requiredRule]}
	>
		<FormSelect
			options={sourcingSources}
			placeholder="Sourcing method"
			defaultValue={props?.source}
			disabled={disabled}
		/>
	</FormItem>;

	const CompanyFormItem = (
		{
			disabled = false,
			...props
		},
	) => <FormItem
		label="Client"
		defaultContainer="div"
		name="companyId"
		rules={[requiredRule]}
	>
		<FormCompanySelect
			options={companies.map((c) => ({ value: documentId(c), label: c.name, item: c }))}
			onChange={handleChangeCompany}
			disabled={disabled}
			placeholder="Client"
			mode="single"
		/>
	</FormItem>;

	const JobFormItem = (
		{
			required = true,
			disabled = false,
			...props
		},
	) => <FormItem
		label="Job"
		defaultContainer="div"
		name="jobId"
		{...required && { rules: [requiredRule] }}
	>
		<JobSelect
			// defaultOptions={props?.job ? [props?.job]: []}
			defaultOptions={props?.jobs || (props?.job ? [props?.job] : [])}
			// defaultOptions={props?.jobs || []}
			value={jobId}
			placeholder="Select a job"
			onChange={handleChangeJob}
			style={{ width: '100%' }}
			disabled={!(props?.companyId?.toString() || documentId(props?.company) || props?.job?.companyId) || disabled}
			companyIds={companyIds}
			notJobIds={props?.notJobIds}
		/>
	</FormItem>;

	const mainFormItems = <FormItemContainer>
		<UserFormItem
			selectMode={!viaProfile}
			disabled={viaProfile}
			{...initialValues}
		/>
		{((isTalent && isStaffView) || (!viaProfile && !viaJob))
			&& <SourceFormItem
				disabled={!(isTalent && isStaffView) && (viaProfile || viaJob)}
				{...initialValues}
			/>
		}
		{((isTalent && isStaffView) || (!viaProfile && !viaJob))
			&& <CompanyFormItem
				disabled={viaJob}
				{...initialValues}
				companyId={companyId}
			/>
		}
		{withJob && <FormItem
			label="Job"
			defaultContainer="div"
			name="jobId"
			{...(viaProfile || viaJob) && { rules: [requiredRule] }}
		>
			<JobSelect
				defaultOptions={initialValues?.job ? [initialValues?.job] : []}
				value={jobId}
				placeholder="Select a job"
				onChange={handleChangeJob}
				style={{ width: '100%' }}
				disabled={!companyId && !viaProfile}
				companyIds={companyIds}
				notJobIds={initialValues?.notJobIds}
			/>
		</FormItem>
		}
	</FormItemContainer>;

	const historyFormItems = <Space direction="vertical" size={10}>
		<span>Steps</span>
		<Form.List name="history">
			{(fields, { add, remove }) => {
				// localDebug('fields:', fields);
				return (
					<Space size={10} direction="vertical">
						<Steps direction="vertical" current={getCurrentSteps(form.getFieldValue('history'))}>
							{fields.map((field, index) => {
								const value = form.getFieldValue('history')[index];
								localDebug(index, value);
								const moveUp = () => {
									remove(field.name);
									add(value, index - 1);
								};
								const moveDown = () => {
									remove(field.name);
									add(value, index + 1);
								};
								const buttons = <Space size={5}>
									<MinusCircleOutlined {...(fields.length > 1
										? { onClick: () => remove(field.name) }
										: { style: { opacity: 0.3 } }
									)} />
									<DownCircleOutlined {...(index < (fields.length - 1)
										? { onClick: () => moveDown() }
										: { style: { opacity: 0.3 } }
									)} />
									<UpCircleOutlined {...(index > 1
										? { onClick: () => moveUp() }
										: { style: { opacity: 0.3 } }
									)} />
								</Space>;
								return (
									<Steps.Step
										key={index}
										description={<Space direction="vertical" size={4}>
											<span>{value.postedAt?.format('YY-MM-DD HH:mm')}</span>
											<span>{value['payload.value'] || value['payload.text'] || value.payload?.text}</span>
											<span>{value.authorId}</span>
										</Space>}
									>
										{buttons}
									</Steps.Step>
								);
							})}
						</Steps>
						<Space direction="horizontal" size={10}>
							<Button
								type="dashed"
								onClick={() => handleAddStep(add, SOURCING_ACTION_STATUS, 'Change status')}
								icon={<PlusOutlined/>}
							>
								Add a status
							</Button>
							<Button
								type="dashed"
								onClick={() => handleAddStep(add, SOURCING_ACTION_COMMENT, 'Add a comment')}
								icon={<PlusOutlined/>}
							>
								Add a comment
							</Button>
						</Space>
					</Space>
				);
			}}
		</Form.List>
	</Space>;

	return (
		<div>
			<Form
				form={form}
				name="alert_form"
				initialValues={{ ...initialValues }}
				onFinish={handleSubmit}
			>
				{withFormHistoryItems
					? <Row>
						<Col span={6}>
							{mainFormItems}
						</Col>
						<Col span={1}>
							<Divider type="vertical"/>
						</Col>
						<Col span={15}>
							{historyFormItems}
						</Col>
					</Row>
					: <Row>
						<Col span={24}>
							{mainFormItems}
						</Col>
					</Row>
				}

				<Divider/>

				<FormCancelSubmitButtons
					onReset={onReset}
					onSubmit={onSubmit}
					submitText='Add'
				/>

			</Form>

			<SourcingActionPopover
				open={openStepPopover}
				initialValues={initValuesStepForm}
				onSubmit={handleStepSubmit}
				onReset={() => setOpenStepPopover(false)}
				title={titleStepPopover}
			/>

		</div>
	);
};

export default SourcingForm;
