import QUESTIONS from '../../questions';
import { GENDERSCORE_PILLAR_WORKLIFEBALANCE_OPTION } from '../../../../../constants/genderscore-pillars';

const section = {
	...GENDERSCORE_PILLAR_WORKLIFEBALANCE_OPTION,
	details: `
### For 50inTech's women community, flexibility at work is their main requirement when choosing a company.

The quality of your work-life balance policy is key to attracting and retaining women in Tech.`,
	questions: [
		// QUESTIONS.V3_WLB_FLEXIBILITY_FLEXIBLE_SCHEDULE,
		{
			...QUESTIONS.V3_WLB_FLEXIBILITY_FLEXIBLE_SCHEDULE_OFFERING,
			// clause: (answers) => answers?.[QUESTIONS.V3_WLB_FLEXIBILITY_FLEXIBLE_SCHEDULE.questionId] === 'yes',
		},
		QUESTIONS.V3_WLB_FLEXIBILITY_TIMEOFF_SCHEDULING_METHODS,
		QUESTIONS.V3_WLB_FLEXIBILITY_TIMEOFF_ENCOURAGEMENT,
		QUESTIONS.V3_WLB_PARENTALITY_BENEFITS_DURING,
		QUESTIONS.V3_WLB_PARENTALITY_BENEFITS_RETURN,
		QUESTIONS.V3_WLB_BENEFITS_LIST,
	],
};

export default section;
