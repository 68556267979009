import {
	Button, Dropdown, message,
} from 'antd';
import {
	FaEnvelope, FaHandshake, FaIdBadge, FaInfoCircle,
} from 'react-icons/fa';
import Logo50inTech from '../../app/Logo50inTech';
import { useContext } from 'react';
import { DrawerContext } from '../../../contexts/DrawerProvider';
import UserProfile from '../profile/UserProfile';
import UserHeader from './UserHeader';

const UserStaffMenuButton = (
	{
		user,
		size = 48,
		...props
	},
) => {
	const className = 'UserStaffMenuButton';

	const {
		setOpen,
		setTitle,
		setContent,
		setWidth,
		setStyle,
	} = useContext(DrawerContext);

	const items = [
		{
			label: (
				<div
					onClick={() => {
						setTitle(
							<UserHeader
								user={user}
							/>,
						);
						setContent(
							<UserProfile
								user={user}
								forceLegacyView={true}
							/>,
						);
						setWidth(1200);
						setStyle({ zIndex: 1001 });
						setOpen(true);
					}}
					style={{ whiteSpace: 'nowrap' }}
				>
					<FaInfoCircle style={{ marginBottom: -2 }} />&nbsp;
					Open legacy view
				</div>
			),
			key: 'legacy-view',
		},
		{
			label: (
				<div
					onClick={() => {
						navigator.clipboard.writeText(user?.email);
						message.success(<>
						The user's email:
							<br/><code style={{ fontSize: 12, color: 'black' }}>{user?.email}</code>
							<br/>has been copied to your clipboard
						</>);
					}}
					style={{ whiteSpace: 'nowrap' }}
				>
					<FaEnvelope style={{ marginBottom: -2 }} />&nbsp;
					Copy email <code style={{ fontSize: 10, color: 'black' }}>{user?.email}</code>
				</div>
			),
			key: 'copy-email',
		},
		{
			label: (
				<div
					onClick={() => {
						navigator.clipboard.writeText(user?.slug);
						message.success(<>
						The user's slug:
							<br/><code style={{ fontSize: 12, color: 'black' }}>{user?.slug}</code>
							<br/>has been copied to your clipboard
						</>);
					}}
					style={{ whiteSpace: 'nowrap' }}
				>
					<FaIdBadge style={{ marginBottom: -2 }} />&nbsp;
					Copy slug <code style={{ fontSize: 10, color: 'black' }}>{user?.slug}</code>
				</div>
			),
			key: 'copy-slug',
		},
		{
			label: (
				<a
					target='_blank'
					rel='noopener noreferrer'
					href={`https://20221027t120118-dot-fit-prod-website.ew.r.appspot.com/coaching/user/${user.slug}`}
				>
					<FaHandshake style={{ marginBottom: -2 }} />&nbsp;
					Open coaching form
				</a>
			),
			key: 'open-coaching-form',
		},
	];

	if (!user) {
		return null;
	}

	return (
		<Dropdown
			menu={{
				items,
			}}
			placement='bottom'
		>
			<Button
				// onClick={() => openExternalUrl(path)}
				className='icon-circle-button'
				type='default'
				style={{
					fontSize: 16,
					width: size,
					height: size,
					borderRadius: size,
					padding: 0,
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
					...props?.style,
				}}
			>
				<Logo50inTech
					size={18}
					style={{
						...props?.style?.icon,
					}}
				/>
			</Button>
		</Dropdown>

	);
};

export default UserStaffMenuButton;
