import { useTheme } from '@emotion/react';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import SurveyPageLayout from './SurveyPageLayout';
import { GENDERSCORE_LABEL } from '../../../constants/constant';

const SurveySubmissionPending = ({
	...props
}) => {
	const className = 'SurveySubmissionPending';

	const theme = useTheme();

	return (
		<SurveyPageLayout
			withHeader={false}
			withFooter={false}
			withProgressBar={false}
		>
			<div
				style={{
					display: 'flex',
					flexDirection: 'column',
					justifyContent: 'center',
					alignItems: 'flex-start',
					textAlign: 'left',
					minWidth: 820,
					maxWidth: 820,
					width: 820,
					margin: '0 auto',
				}}
			>

				<h1
					style={{
						fontWeight: 'unset',
						color: '#222',
						lineHeight: 1.2,
					}}
				>
					<Spin
						indicator={<LoadingOutlined style={{ fontSize: 32 }} spin/>}
						spinning={true}
						style={{
							marginBottom: 5,
							marginRight: 12,
							color: theme.color.fitBlueElectric,
							background: 'white',
							borderRadius: 200,
						}}
					/>
					Submitting your survey
				</h1>
				<p
					style={{
						fontSize: 16,
						color: '#222',
					}}
				>
					Hang on! We're crunching your answers to calculate your score.
					<br />We'll take you to your <b>{GENDERSCORE_LABEL} results page</b> in a few seconds!
				</p>
			</div>

		</SurveyPageLayout>

	);
};

export default SurveySubmissionPending;
