import {
	CaretRightOutlined, CheckOutlined, FastForwardOutlined, InboxOutlined, RiseOutlined, StepForwardOutlined,
} from '@ant-design/icons';
import { findOption } from '../../../constants/constant';
import {
	GENDERSCORE_PILLAR_DEIACTIONS_VALUE,
	GENDERSCORE_PILLAR_EQUALPAY_VALUE,
	GENDERSCORE_PILLAR_FAIRCAREERPATH_VALUE,
	GENDERSCORE_PILLAR_GENDERDATA_VALUE,
	GENDERSCORE_PILLAR_WORKLIFEBALANCE_VALUE,
	getPillarOption,
} from '../../../constants/genderscore-pillars';

/* *** ACTION GRID VIEW *** */

export const GS_ACTION_GRID_VIEW_TIMEFRAME = 'timeframe';
export const GS_ACTION_GRID_VIEW_TIMEFRAME_OPTION = {
	value: GS_ACTION_GRID_VIEW_TIMEFRAME,
	label: 'Timeframe',
};

export const GS_ACTION_GRID_VIEW_COMPLETION_STATUS = 'completionStatus';
export const GS_ACTION_GRID_VIEW_COMPLETION_STATUS_OPTION = {
	value: GS_ACTION_GRID_VIEW_COMPLETION_STATUS,
	label: 'Completion status',
};

export const GS_ACTION_GRID_VIEW_OPTIONS = [
	GS_ACTION_GRID_VIEW_TIMEFRAME_OPTION,
	GS_ACTION_GRID_VIEW_COMPLETION_STATUS_OPTION,
];

export const findActionGridView = (value) => findOption(GS_ACTION_GRID_VIEW_OPTIONS, value);

/* *** ACTION COMPLETION STATUS *** */

export const GS_ACTION_COMPLETION_STATUS_TODO = 'completion-status-todo';
export const GS_ACTION_COMPLETION_STATUS_TODO_OPTION = {
	value: GS_ACTION_COMPLETION_STATUS_TODO,
	label: 'To do',
	tagLabel: 'To do',
	tagColor: 'red',
	tagIcon: <InboxOutlined />,
};

export const GS_ACTION_COMPLETION_STATUS_INPROGRESS = 'completion-status-inprogress';
export const GS_ACTION_COMPLETION_STATUS_INPROGRESS_OPTION = {
	value: GS_ACTION_COMPLETION_STATUS_INPROGRESS,
	label: 'In progress',
	tagLabel: 'In progress',
	tagColor: 'yellow',
	tagIcon: <RiseOutlined />,
};

export const GS_ACTION_COMPLETION_STATUS_DONE = 'completion-status-done';
export const GS_ACTION_COMPLETION_STATUS_DONE_OPTION = {
	value: GS_ACTION_COMPLETION_STATUS_DONE,
	label: 'Done',
	tagLabel: 'Done',
	tagColor: 'green',
	tagIcon: <CheckOutlined />,
};

export const GS_ACTION_COMPLETION_STATUS_OPTIONS = [
	GS_ACTION_COMPLETION_STATUS_TODO_OPTION,
	GS_ACTION_COMPLETION_STATUS_INPROGRESS_OPTION,
	GS_ACTION_COMPLETION_STATUS_DONE_OPTION,
];

export const findActionCompletionStatus = (value) => findOption(GS_ACTION_COMPLETION_STATUS_OPTIONS, value);

/* *** ACTION TIMEFRAME *** */

export const GS_ACTION_TIMEFRAME_SHORT_TERM = 'timeframe-short-term';
export const GS_ACTION_TIMEFRAME_SHORT_TERM_OPTION = {
	value: GS_ACTION_TIMEFRAME_SHORT_TERM,
	label: 'Short-term',
	tagLabel: 'Short-term',
	tagColor: 'blue',
	tagIcon: <CaretRightOutlined />,
};

export const GS_ACTION_TIMEFRAME_MID_TERM = 'timeframe-mid-term';
export const GS_ACTION_TIMEFRAME_MID_TERM_OPTION = {
	value: GS_ACTION_TIMEFRAME_MID_TERM,
	label: 'Mid-term',
	tagLabel: 'Mid-term',
	tagColor: 'orange',
	tagIcon: <StepForwardOutlined />,
};

export const GS_ACTION_TIMEFRAME_LONG_TERM = 'timeframe-long-term';
export const GS_ACTION_TIMEFRAME_LONG_TERM_OPTION = {
	value: GS_ACTION_TIMEFRAME_LONG_TERM,
	label: 'Long-term',
	tagLabel: 'Long-term',
	tagColor: 'purple',
	tagIcon: <FastForwardOutlined />,
};

export const GS_ACTION_TIMEFRAME_OPTIONS = [
	GS_ACTION_TIMEFRAME_SHORT_TERM_OPTION,
	GS_ACTION_TIMEFRAME_MID_TERM_OPTION,
	GS_ACTION_TIMEFRAME_LONG_TERM_OPTION,
];

export const findActionTimeframe = (value) => findOption(GS_ACTION_TIMEFRAME_OPTIONS, value);

/* *** ACTION COMPLEXITY *** */

export const GS_ACTION_COMPLEXITY_HIGH = 'complexity-high';
export const GS_ACTION_COMPLEXITY_HIGH_OPTION = {
	value: GS_ACTION_COMPLEXITY_HIGH,
	label: 'High complexity',
	tagLabel: 'High',
};

export const GS_ACTION_COMPLEXITY_MEDIUM = 'complexity-medium';
export const GS_ACTION_COMPLEXITY_MEDIUM_OPTION = {
	value: GS_ACTION_COMPLEXITY_MEDIUM,
	label: 'Medium complexity',
	tagLabel: 'Medium',
};

export const GS_ACTION_COMPLEXITY_LOW = 'complexity-low';
export const GS_ACTION_COMPLEXITY_LOW_OPTION = {
	value: GS_ACTION_COMPLEXITY_LOW,
	label: 'Low complexity',
	tagLabel: 'Low',
};

export const GS_ACTION_COMPLEXITY_OPTIONS = [
	GS_ACTION_COMPLEXITY_MEDIUM_OPTION,
	GS_ACTION_COMPLEXITY_HIGH_OPTION,
	GS_ACTION_COMPLEXITY_LOW_OPTION,
];

export const findActionComplexity = (value) => findOption(GS_ACTION_COMPLEXITY_OPTIONS, value);

/* *** ACTION BUDGET *** */

export const GS_ACTION_BUDGET_HIGH = 'budget-high';
export const GS_ACTION_BUDGET_HIGH_OPTION = {
	value: GS_ACTION_BUDGET_HIGH,
	label: 'High budget',
	tagLabel: '$$$',
};

export const GS_ACTION_BUDGET_MEDIUM = 'budget-medium';
export const GS_ACTION_BUDGET_MEDIUM_OPTION = {
	value: GS_ACTION_BUDGET_MEDIUM,
	label: 'Medium budget',
	tagLabel: '$$',
};

export const GS_ACTION_BUDGET_LOW = 'budget-low';
export const GS_ACTION_BUDGET_LOW_OPTION = {
	value: GS_ACTION_BUDGET_LOW,
	label: 'Low budget',
	tagLabel: '$',
};

export const GS_ACTION_BUDGET_OPTIONS = [
	GS_ACTION_BUDGET_MEDIUM_OPTION,
	GS_ACTION_BUDGET_HIGH_OPTION,
	GS_ACTION_BUDGET_LOW_OPTION,
];

export const findActionBudget = (value) => findOption(GS_ACTION_BUDGET_OPTIONS, value);

/* *** ACTION PEOPLE *** */

export const GS_ACTION_PEOPLE_HIGH = 'people-high';
export const GS_ACTION_PEOPLE_HIGH_OPTION = {
	value: GS_ACTION_PEOPLE_HIGH,
	label: 'High people',
	tagLabel: '5+',
};

export const GS_ACTION_PEOPLE_MEDIUM = 'people-medium';
export const GS_ACTION_PEOPLE_MEDIUM_OPTION = {
	value: GS_ACTION_PEOPLE_MEDIUM,
	label: 'Medium people',
	tagLabel: '3-5',
};

export const GS_ACTION_PEOPLE_LOW = 'people-low';
export const GS_ACTION_PEOPLE_LOW_OPTION = {
	value: GS_ACTION_PEOPLE_LOW,
	label: 'Low people',
	tagLabel: '1-2',
};
export const GS_ACTION_PEOPLE_OPTIONS = [
	GS_ACTION_PEOPLE_MEDIUM_OPTION,
	GS_ACTION_PEOPLE_HIGH_OPTION,
	GS_ACTION_PEOPLE_LOW_OPTION,
];

export const findActionPeople = (value) => findOption(GS_ACTION_PEOPLE_OPTIONS, value);

/* *** ACTION PILLAR CATEGORY *** */

export const GS_PILLAR_CATEGORY_GD_WORKFORCE = 'gdWorkforce';
export const GS_PILLAR_CATEGORY_GD_WORKFORCE_OPTION = {
	value: GS_PILLAR_CATEGORY_GD_WORKFORCE,
	pillar: GENDERSCORE_PILLAR_GENDERDATA_VALUE,
	label: 'Workforce data',
	tagLabel: 'Workforce data',
};

export const GS_PILLAR_CATEGORY_GD_TECH = 'gdTech';
export const GS_PILLAR_CATEGORY_GD_TECH_OPTION = {
	value: GS_PILLAR_CATEGORY_GD_TECH,
	pillar: GENDERSCORE_PILLAR_GENDERDATA_VALUE,
	label: 'IT/Tech Department data',
	tagLabel: 'IT/Tech data',
};

export const GS_PILLAR_CATEGORY_GD_LABELS = 'gdLabels';
export const GS_PILLAR_CATEGORY_GD_LABELS_OPTION = {
	value: GS_PILLAR_CATEGORY_GD_LABELS,
	pillar: GENDERSCORE_PILLAR_GENDERDATA_VALUE,
	label: 'Indexes and Labels',
	tagLabel: 'Indexes & Labels',
};

export const GS_PILLAR_CATEGORY_GD_RETENTION = 'gdRetention';
export const GS_PILLAR_CATEGORY_GD_RETENTION_OPTION = {
	value: GS_PILLAR_CATEGORY_GD_RETENTION,
	pillar: GENDERSCORE_PILLAR_GENDERDATA_VALUE,
	label: 'Turnover & Retention',
	tagLabel: 'Turnover & Retention',
};

export const GS_PILLAR_CATEGORY_FCP_PRACTICES = 'fcpPractices';
export const GS_PILLAR_CATEGORY_FCP_PRACTICES_OPTION = {
	value: GS_PILLAR_CATEGORY_FCP_PRACTICES,
	pillar: GENDERSCORE_PILLAR_FAIRCAREERPATH_VALUE,
	label: 'Practices',
	tagLabel: 'Practices',
};

export const GS_PILLAR_CATEGORY_FCP_DEVELOPMENT = 'fcpDevelopment';
export const GS_PILLAR_CATEGORY_FCP_DEVELOPMENT_OPTION = {
	value: GS_PILLAR_CATEGORY_FCP_DEVELOPMENT,
	pillar: GENDERSCORE_PILLAR_FAIRCAREERPATH_VALUE,
	label: 'Development',
	tagLabel: 'Development',
};

export const GS_PILLAR_CATEGORY_EP_REVIEW = 'epReview';
export const GS_PILLAR_CATEGORY_EP_REVIEW_OPTION = {
	value: GS_PILLAR_CATEGORY_EP_REVIEW,
	pillar: GENDERSCORE_PILLAR_EQUALPAY_VALUE,
	label: 'Pay Review',
	tagLabel: 'Pay Review',
};

export const GS_PILLAR_CATEGORY_EP_TRANSPARENCY = 'epTransparency';
export const GS_PILLAR_CATEGORY_EP_TRANSPARENCY_OPTION = {
	value: GS_PILLAR_CATEGORY_EP_TRANSPARENCY,
	pillar: GENDERSCORE_PILLAR_EQUALPAY_VALUE,
	label: 'Pay Transparency',
	tagLabel: 'Pay Transparency',
};

export const GS_PILLAR_CATEGORY_EP_COMPENSATION = 'epCompensation';
export const GS_PILLAR_CATEGORY_EP_COMPENSATION_OPTION = {
	value: GS_PILLAR_CATEGORY_EP_COMPENSATION,
	pillar: GENDERSCORE_PILLAR_EQUALPAY_VALUE,
	label: 'Compensation & Benefits',
	tagLabel: 'Compensation & Benefits',
};

export const GS_PILLAR_CATEGORY_WLB_FLEXIBILITY = 'wlbFlexiblity';
export const GS_PILLAR_CATEGORY_WLB_FLEXIBILITY_OPTION = {
	value: GS_PILLAR_CATEGORY_WLB_FLEXIBILITY,
	pillar: GENDERSCORE_PILLAR_WORKLIFEBALANCE_VALUE,
	label: 'Work Flexibility',
	tagLabel: 'Work Flexibility',
};

export const GS_PILLAR_CATEGORY_WLB_PARENTALITY = 'wlbParentality';
export const GS_PILLAR_CATEGORY_WLB_PARENTALITY_OPTION = {
	value: GS_PILLAR_CATEGORY_WLB_PARENTALITY,
	pillar: GENDERSCORE_PILLAR_WORKLIFEBALANCE_VALUE,
	label: 'Parentality',
	tagLabel: 'Parentality',
};

export const GS_PILLAR_CATEGORY_WLB_BENEFITS = 'wlbBenefits';
export const GS_PILLAR_CATEGORY_WLB_BENEFITS_OPTION = {
	value: GS_PILLAR_CATEGORY_WLB_BENEFITS,
	pillar: GENDERSCORE_PILLAR_WORKLIFEBALANCE_VALUE,
	label: 'Benefits',
	tagLabel: 'Benefits',
};

export const GS_PILLAR_CATEGORY_DEI_SAFETY = 'deiSafety';
export const GS_PILLAR_CATEGORY_DEI_SAFETY_OPTION = {
	value: GS_PILLAR_CATEGORY_DEI_SAFETY,
	pillar: GENDERSCORE_PILLAR_DEIACTIONS_VALUE,
	label: 'Workplace Safety',
	tagLabel: 'Workplace Safety',
};

export const GS_PILLAR_CATEGORY_DEI_HEALTH = 'deiHealth';
export const GS_PILLAR_CATEGORY_DEI_HEALTH_OPTION = {
	value: GS_PILLAR_CATEGORY_DEI_HEALTH,
	pillar: GENDERSCORE_PILLAR_DEIACTIONS_VALUE,
	label: 'Health',
	tagLabel: 'Health',
};

export const GS_PILLAR_CATEGORY_DEI_POLICIES = 'deiPolicies';
export const GS_PILLAR_CATEGORY_DEI_POLICIES_OPTION = {
	value: GS_PILLAR_CATEGORY_DEI_POLICIES,
	pillar: GENDERSCORE_PILLAR_DEIACTIONS_VALUE,
	label: 'Policies',
	tagLabel: 'Policies',
};

export const GS_PILLAR_CATEGORY_DEI_RECRUITMENT = 'deiRecruitment';
export const GS_PILLAR_CATEGORY_DEI_RECRUITMENT_OPTION = {
	value: GS_PILLAR_CATEGORY_DEI_RECRUITMENT,
	pillar: GENDERSCORE_PILLAR_DEIACTIONS_VALUE,
	label: 'Recruitment',
	tagLabel: 'Recruitment',
};

export const GS_PILLAR_CATEGORY_OPTIONS = [
	GS_PILLAR_CATEGORY_GD_WORKFORCE_OPTION,
	GS_PILLAR_CATEGORY_GD_TECH_OPTION,
	GS_PILLAR_CATEGORY_GD_LABELS_OPTION,
	GS_PILLAR_CATEGORY_GD_RETENTION_OPTION,
	GS_PILLAR_CATEGORY_FCP_PRACTICES_OPTION,
	GS_PILLAR_CATEGORY_FCP_DEVELOPMENT_OPTION,
	GS_PILLAR_CATEGORY_EP_REVIEW_OPTION,
	GS_PILLAR_CATEGORY_EP_TRANSPARENCY_OPTION,
	GS_PILLAR_CATEGORY_EP_COMPENSATION_OPTION,
	GS_PILLAR_CATEGORY_WLB_FLEXIBILITY_OPTION,
	GS_PILLAR_CATEGORY_WLB_PARENTALITY_OPTION,
	GS_PILLAR_CATEGORY_WLB_BENEFITS_OPTION,
	GS_PILLAR_CATEGORY_DEI_SAFETY_OPTION,
	GS_PILLAR_CATEGORY_DEI_HEALTH_OPTION,
	GS_PILLAR_CATEGORY_DEI_POLICIES_OPTION,
	GS_PILLAR_CATEGORY_DEI_RECRUITMENT_OPTION,
];

// console.log('GS_PILLAR_CATEGORY_OPTIONS', GS_PILLAR_CATEGORY_OPTIONS.map((o) => o.value));
export const findActionPillarCategory = (value) => findOption(GS_PILLAR_CATEGORY_OPTIONS, value);

export const findActionPillar = (value) => {
	const pillar = getPillarOption(value);
	if (pillar) return pillar;
	return getPillarOption(findActionPillarCategory(value)?.pillar);
};
