import { Button } from 'antd';
import {
	getPillarColorMain, getPillarIcon, getPillarLabel, getPillarPath,
} from '../../../../constants/genderscore-pillars';
import { useNavigate } from 'react-router';
import { BiChevronLeft } from 'react-icons/bi';
import { useTheme } from '@emotion/react';
import { useAuthContext } from '../../../../hooks/useAuthContext';
import {
	GENDERSCORE_LOG_FROM_ELEMENT_NAVIGATION_BACK_BTN,
	GENDERSCORE_LOG_FROM_ELEMENT_NAVIGATION_NEXT_BTN,
	writeGenderScoreFilledLog,
} from '../../../../constants/genderscore-logs';
import { getScoreColorMain } from '../../../../menu/genderscore/genderscore-utils';
import CompanyGenderScoreRingScore from '../CompanyGenderScoreRingScore';

const GenderScorePillarNavigationButton = ({
	pillar,
	from,
	isPrev = true,
	...props
}) => {
	const className = 'GenderScorePillarNavigationButton';

	const { genderScore } = useAuthContext();

	const theme = useTheme();

	const navigate = useNavigate();

	const onNavigate = ({ to }) => {
		writeGenderScoreFilledLog({
			type: isPrev
				? GENDERSCORE_LOG_FROM_ELEMENT_NAVIGATION_BACK_BTN
				: GENDERSCORE_LOG_FROM_ELEMENT_NAVIGATION_NEXT_BTN,
			context: from,
		});
		navigate(to);
	};

	const path = getPillarPath(pillar) || '/genderscore';
	const caption = pillar ? `${isPrev ? 'Previous' : 'Next'} pillar` : 'Back to';
	const label = getPillarLabel(pillar) || 'Your results';

	return (
		<Button
			onClick={() => onNavigate({ to: path })}
			style={{
				display: 'block',
				alignContent: isPrev ? 'left' : 'right',
				textAlign: isPrev ? 'left' : 'right',
				minWidth: 200,
				height: 68,
				padding: '6px 18px',
				...props?.style,
			}}
		>
			<div
				style={{
					display: 'flex',
					flexDirection: isPrev ? 'row' : 'row-reverse',
					alignItems: 'center',
				}}
			>
				<BiChevronLeft
					style={{
						fontSize: 28,
						opacity: 0.5,
						...!isPrev ? { transform: 'rotate(180deg)' } : {},
					}}
				/>

				{getPillarIcon(pillar, { width: 32, height: 32 })
					|| (
						<CompanyGenderScoreRingScore
							size={32}
							company={{ genderScore }}
						/>
					)
				}

				<div
					style={{
						margin: '0 8px',
					}}
				>
					<div
						style={{
							fontSize: 12,
							opacity: 0.5,
							marginBottom: -2,
						}}
					>
						{caption}
					</div>
					<div
						style={{
							fontSize: 16,
							fontWeight: 'bold',
							color: getPillarColorMain(pillar) || getScoreColorMain(theme),
						}}
					>
						{label}
					</div>
				</div>
			</div>
		</Button>
	);
};

export default GenderScorePillarNavigationButton;
