import { useContext, useEffect, useState } from 'react';
import { AppDataContext } from '../../../contexts/AppDataProvider';
import SurveyProgressBar from './SurveyProgressBar';
import SurveyPageHeader from './SurveyPageHeader';

const SurveyPageLayout = ({
	withHeader = true,
	withFooter = true,
	withProgressBar = true,
	cssClassNames = [],
	children,
	...props
}) => {
	const {
		appSiderWidth,
		appTopOffset,
		isAppSiderVisible,
	} = useContext(AppDataContext);

	const [substractHeight, setSubstractHeight] = useState(0);

	useEffect(() => {
		setSubstractHeight(
			0
			+ (withFooter ? 120 : 0)
			+ (withHeader ? 62 : 0)
			+ (appTopOffset),
		);
	}, [withHeader, withFooter, appTopOffset]);

	return (
		<>
			<div
				className={['survey', ...cssClassNames || []].join(' ')}
				{...props}
				style={{
					position: 'relative',
					height: `calc(100vh - ${substractHeight}px)`,
					overflowY: 'scroll',
					top: withHeader ? 62 : 0,
				}}
			>
				<div
					style={{
						width: '100%',
						height: '100%',
						display: 'grid',
						gridTemplateRows: '1fr',
						gridTemplateColumns: '1fr',
						padding: '100px 0',
					}}
				>
					{children}
				</div>

			</div>
			{withProgressBar && <SurveyPageHeader>
				<SurveyProgressBar />
			</SurveyPageHeader>}
		</>
	);
};

export default SurveyPageLayout;
