import { documentId } from '../utils/common';
import { request } from './api';

const findAll = async (category) => {
	const dataParams = {
		params: {
			category,
		},
	};
	return request.get('/tag', dataParams);
};

const create = (data) => {
	return request.post('/tag', data);
};

const update = (tagId, data) => {
	return request.put(`/tag/${tagId}`, data);
};

const remove = (tag) => {
	return request.delete(`/tag/${documentId(tag)}`);
};

const TagService = {
	findAll,
	create,
	update,
	remove,
};

export default TagService;
