import {
	Button, message, Select, Space, Tooltip,
} from 'antd';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTheme } from '@emotion/react';
import { LocalDebug } from '../../../../utils/LocalDebug';
import StatService from '../../../../services/stat';
import { getCompanies, getCompanySelected, getRecruiters } from '../../../../reducers/app';
import FormCompanySelect from '../../../app/form/FormCompanySelect';
import { FaCalculator, FaSync } from 'react-icons/fa';
import { capitalize, documentId, hexToRgb } from '../../../../utils/common';
import ReactJson from 'react-json-view';
import { useAuthContext } from '../../../../hooks/useAuthContext';

const periodOptions = [
	{ value: 'day', label: 'Day' },
	{ value: 'isoWeek', label: 'Week' },
	{ value: 'month', label: 'Month' },
	{ value: 'quarter', label: 'Quarter' },
	{ value: 'year', label: 'Year' },
];

const AppStatsFilters = (
	{
		onFilterChanged,
		statsOptions,
		selectedStatOption,
		filterOptions = {},
		onRefresh,
	},
) => {
	const className = 'AppStatsFilters';
	const theme = useTheme();
	const dispatch = useDispatch();
	const companySelected = useSelector(getCompanySelected);
	const companies = useSelector(getCompanies);
	const recruiters = useSelector(getRecruiters);

	const { isAdmin } = useAuthContext();
	const [formFilterOptions, setFormFilterOptions] = useState({
		period: 'isoWeek',
	});
	const [docId, setDocId] = useState(null);

	// useEffect(async () => {
	// 	const results = await StatService.lists();
	// 	LocalDebug.logUseEffect({ className, effects: '' }, { results });
	// 	setStatsOptions(results?.data?.stats || []);
	// }, []);
	//
	useEffect(() => {
		onFilterChanged?.(formFilterOptions);
	}, [formFilterOptions]);

	useEffect(() => {
		setDocId(filterOptions.documentId);
	}, [filterOptions]);

	useEffect(() => {
		// setFormFilterOptions((p) => ({ ...p, stat: statsOptions?.[0]?.value }))
	}, [statsOptions]);

	const handleServiceCall = async (method, call) => {
		const { stat, period } = formFilterOptions || {};
		LocalDebug.logInfo({ className, method }, { stat, period });
		const msgKey = method;
		message.loading({
			key: msgKey,
			content: (
				<>Computing <b>{stat}</b> for period: <b>{periodOptions?.find((o) => o?.value === period)?.label}</b></>
			),
			duration: 0,
		});
		const { data: { success, ...result } } = await call({ ...formFilterOptions });
		message.success({
			key: msgKey,
			content: (
				<>
					<ReactJson
						collapsed={0}
						src={result}
						style={{
							fontSize: 10, lineHeight: 1.5, textAlign: 'left', minWidth: 500,
						}}
					/>
				</>
			),
		});
		onRefresh?.();
	};
	const handleRefresh = async () => {
		await handleServiceCall('handleRefresh', StatService.refresh);
	};

	const handleCompute = async () => {
		await handleServiceCall('handleCompute', StatService.compute);
	};

	return (
		<>
			<Space
				size={[10, 10]}
				style={{
					width: '100%',
					marginBottom: 6,
					justifyContent: 'space-between',
					alignItems: 'center',
				}}
			>
				<Space size={[10, 10]} wrap={true}>
					<Select
						allowClear={true}
						style={{ minWidth: 144, fontSize: 14, fontWeight: 'bold' }}
						options={periodOptions}
						// defaultValue={periodOptions?.[0]?.value}
						placeholder={<>Select a period</>}
						onChange={(value) => {
							setFormFilterOptions((prev) => ({ ...prev, period: value }));
						}}
					/>

					<Select
						showSearch
						allowClear={true}
						style={{ minWidth: 360, fontSize: 14, fontWeight: 'bold' }}
						// filterOption={(o) => {
						// }
						// }
						// options={statsOptions}
						// render={(option, key) => (
						// 	<Select.Option value={option?.value} label={option?.label} key={key}>
						// 		<Space direction='vertical'>
						// 			<b>{option?.label}</b>
						// 			<span>
						// 				<code>{option?.value}</code>
						// 				<span>{option?.comment}</span>
						// 			</span>
						// 		</Space>
						// 	</Select.Option>
						// )}
						// defaultValue={statsOptions?.[0]?.value}
						// optionFilterProp='children'
						placeholder={<>Select a stat</>}
						onChange={(value) => {
							setFormFilterOptions((prev) => ({ ...prev, stat: value }));
						}}
						optionLabelProp='label'
						dropdownMatchSelectWidth={500}
						listHeight={500}
					>
						{statsOptions?.map((option, key) => (
							<Select.Option
								value={option?.value}
								label={<b>{option?.label || capitalize(option?.value?.split('-').join(' '))}</b>}
								key={key}>
								<Space
									direction='vertical'
									size={4}
									style={{
										borderLeft: `3px solid ${theme.color.lightgrey}`,
										paddingLeft: 8,
										margin: '4px 0',
										lineHeight: 1.3,
									}}
								>
									<div
										style={{
											fontSize: 14,
											// marginBottom: -4,
											fontWeight: 'bold',
										}}
									>
										{option?.label || capitalize(option?.value?.split('-').join(' '))}
									</div>
									{option?.comment && <div
										style={{
											whiteSpace: 'normal',
											// maxWidth: '100%',
											// display: 'inline-block',
											fontSize: 12,
											color: theme.color.darkgrey,
											// marginBottom: -6,
										}}
									>
										{option?.comment}
									</div>}
									<code
										style={{
											padding: '1px 4px',
											border: `1px solid rgb(${hexToRgb(theme.color.fitBlueElectric).join(',')}, 0.2)`,
											borderRadius: 4,
											color: theme.color.fitBlueElectric,
											fontSize: 9,
											background: 'none',
											margin: 0,
										}}
									>
										{option?.value}
									</code>
								</Space>
							</Select.Option>
						))}
					</Select>

					<FormCompanySelect
						options={companies.map((c) => ({ value: documentId(c), label: c.name, item: c }))}
						mode='single'
						defaultValue={docId}
						value={docId}
						placeholder='Select a company'
						with50inTechSorting={-1}
						styles={{ minWidth: 220, fontSize: 14, fontWeight: 'bold' }}
						onChange={(value) => {
							LocalDebug.logInfo({ className, method: 'FormCompanySelect.onChange' }, { value });
							setFormFilterOptions((prev) => ({ ...prev, documentId: value }));
						}}
					/>

					{/* <UserSelect */}
					{/*	options={recruiters */}
					{/*		.filter(r => !documentId(companySelected) || r?.companyId === documentId(companySelected)) */}
					{/*		.map(item => ({ */}
					{/*			value: documentId(item), */}
					{/*			label: ( */}
					{/*				<UserStub */}
					{/*					avatarSize={24} */}
					{/*					user={item} */}
					{/*					withProfession={false} */}
					{/*					withLocation={false} */}
					{/*					withIsNew={false} */}
					{/*					withIsUnviewed={false} */}
					{/*				/> */}
					{/*			), */}
					{/*			item */}
					{/*		})) */}
					{/*	} */}
					{/*	mode="single" */}
					{/*	placeholder="Select a recruiter" */}
					{/*	styles={{ minWidth: 280 }} */}
					{/*	onChange={(value) => { */}
					{/*		LocalDebug.logInfo({ className, method: 'UserSelect.onChange' }, { value }) */}
					{/*		setFormFilterOptions((prev) => ({ ...prev, documentId: value })); */}
					{/*	}} */}
					{/* /> */}
				</Space>
				<Space size={[10, 10]}>
					<Tooltip title={<>Refresh the data with latest values for this stat & period</>}>
						<Button
							type='primary'
							icon={<FaSync style={{ marginBottom: -2 }} />}
							onClick={handleRefresh}
							disabled={!(formFilterOptions?.stat && formFilterOptions?.period)}
						/>
					</Tooltip>
					<Tooltip title={<>Recompute all data for this stat & period</>}>
						<Button
							type='primary'
							danger
							icon={<FaCalculator style={{ marginBottom: -2 }} />}
							onClick={handleCompute}
							disabled={(!isAdmin && selectedStatOption?.disableRecompute) || !(formFilterOptions?.stat && formFilterOptions?.period)}
						/>
					</Tooltip>

				</Space>
			</Space>
		</>
	);
};

export default AppStatsFilters;
