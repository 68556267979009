import { isStringTypeValidator } from '../../../utils/isStringTypeValidator';
import { innerObjPropExtractor } from '../../property/property-extractor';
import { innerObjPropMapper } from '../../property/property-mapper';

const USER_PROPERTY_PROFILE_AVATAR_KEY = 'profile-avatar';

const USER_PROPERTY_PROFILE_AVATAR_FREE = true;

const USER_PROPERTY_PROFILE_AVATAR_VALIDATORS = [
	isStringTypeValidator,
];

const USER_PROPERTY_PROFILE_AVATAR_FIELD = 'information.avatar';
const USER_PROPERTY_PROFILE_AVATAR_MAPPER = innerObjPropMapper(USER_PROPERTY_PROFILE_AVATAR_FIELD);
const USER_PROPERTY_PROFILE_AVATAR_EXTRACTOR = innerObjPropExtractor(USER_PROPERTY_PROFILE_AVATAR_FIELD);

export default {
	key: USER_PROPERTY_PROFILE_AVATAR_KEY,
	USER_PROPERTY_PROFILE_AVATAR_KEY,
	validators: USER_PROPERTY_PROFILE_AVATAR_VALIDATORS,
	USER_PROPERTY_PROFILE_AVATAR_VALIDATORS,
	free: USER_PROPERTY_PROFILE_AVATAR_FREE,
	USER_PROPERTY_PROFILE_AVATAR_FREE,
	mapper: USER_PROPERTY_PROFILE_AVATAR_MAPPER,
	USER_PROPERTY_PROFILE_AVATAR_MAPPER,
	extractor: USER_PROPERTY_PROFILE_AVATAR_EXTRACTOR,
	USER_PROPERTY_PROFILE_AVATAR_EXTRACTOR,
	field: USER_PROPERTY_PROFILE_AVATAR_FIELD,
	USER_PROPERTY_PROFILE_AVATAR_FIELD,
};
