import { onEnterBuilder } from '../../../actions/app';
import ProductUsersPage from '../../../containers/staff/product/ProductUsersPage';
import { PERMS_GRANT_STAFF } from '../../menu.utils';

const menu = {
	name: 'staff-product-users',
	path: '/staff/product/users',
	label: 'Salary expectations',
	icon: <span>🤑</span>,
	component: ProductUsersPage,
	onEnter: onEnterBuilder({
		origin: ProductUsersPage.name,
	}),
	perms: PERMS_GRANT_STAFF,
};

export default menu;
