import { useContext, useEffect, useState } from 'react';
import { useTheme } from '@emotion/react';
import { Space } from 'antd';
import useBodyScrollBlocker from '../../../../hooks/useBodyScrollBlocker';
import CompanyModel from '../../../../models/CompanyModel';
import { getCompanyUMSSurveyDraftSelected, getCompanySelected } from '../../../../reducers/app';
import { useDispatch, useSelector } from 'react-redux';
import { StaffManagerPanelDivider, StaffManagerPanelLink, StaffManagerPanelTabContentWrapper } from '../StaffManagerPanel';
import { UMS_LABEL, NOT_PROVIDED_YET_LABEL, PATHS } from '../../../../constants/constant';
import {
	countSurveyCompletedQuestions,
	countSurveyMandatoryQuestions,
	countSurveyQuestions,
	randomlyFakeQuestionAnswer,
	walkSectionQuestionForAnswers,
} from '../../../ums/data/shared';
import { SurveyContext } from '../../../ums/survey/providers/SurveyProvider';
import {
	documentId, sumReducer,
} from '../../../../utils/common';
import { AppDataContext } from '../../../../contexts/AppDataProvider';
import { CompanySettingsCardLineOk } from '../../../company/settings/CompanySettingsCard';
import Link from '../../../app/Link';
import ModalContainerBase from '../../../../contexts/modals/ModalContainerBase';
import CompanySettingsUMSForm from '../../../company/settings/CompanySettingsGenderScoreForm';
import Box from '../../../app/box/Box';
import CompanyStub from '../../../company/CompanyStub';
import CompanyService from '../../../../services/company';
import { loadCompany } from '../../../../actions/company';
import { LocalDebug } from '../../../../utils/LocalDebug';
import SurveyLangSwitcher from '../../../ums/survey/SurveyLangSwitcher';

const StaffManagerUMSTab = () => {
	const className = 'StaffManagerUMSTab';

	const theme = useTheme();
	const dispatch = useDispatch();

	const companySelected = useSelector(getCompanySelected);
	const draftSelected = useSelector(getCompanyUMSSurveyDraftSelected);
	const {
		surveyConf, answers, saveAnswers, createOrUpdateSurveyDraft,
	} = useContext(SurveyContext);
	const {
		isAppSiderVisibleInSurvey, setIsAppSiderVisibleInSurvey,
		isProgressBarVisibleInSurvey, setIsProgressBarVisibleInSurvey,
		isProgressBarClickableInSurvey, setIsProgressBarClickableInSurvey,
	} = useContext(AppDataContext);

	const [openUMSSettingsModal, setOpenUMSSettingsModal] = useState(false);
	const [companyModel, setCompanyModel] = useState();

	useBodyScrollBlocker(openUMSSettingsModal, className);

	useEffect(() => {
		setCompanyModel(
			companySelected?._id
				? new CompanyModel(companySelected)
				: null,
		);
	}, [companySelected]);

	if (!companyModel) {
		return (
			<StaffManagerPanelTabContentWrapper>
				<i>Select a company</i>
			</StaffManagerPanelTabContentWrapper>
		);
	}

	if (!companyModel?.isUMSEnabled?.()) {
		return (
			<StaffManagerPanelTabContentWrapper>
				<CompanySettingsCardLineOk
					ok={companyModel?.isUMSEnabled()}
					okText={<>{UMS_LABEL} is enabled</>}
					koText={<>{UMS_LABEL} is disabled</>}
				/>
				<Link to={PATHS.CONFIGURE_SETTINGS}>Open Settings</Link>
			</StaffManagerPanelTabContentWrapper>
		);
	}

	const updateExpirationDate = async (data) => {
		const { data: survey } = await CompanyService
			.updateExpirationDate({
				companyId: documentId(companySelected),
				...data || {},
			});
		await dispatch(
			await loadCompany(documentId(companySelected)),
		);
	};

	const deleteSurveys = async (data) => {
		await CompanyService
			.deleteSurveys({
				companyId: documentId(companySelected),
				...data || {},
			});
		await dispatch(
			await loadCompany(
				documentId(companySelected),
				{
					withGenderScoreAction: true,
					withGenderScoreSurveys: true,
					withUMSResults: true,
					withUMSSurveys: true,
				},
			),
		);
	};

	const createSurvey = async (data) => {
		await CompanyService
			.createFakeSurvey({
				companyId: documentId(companySelected),
				...data || {},
			});
		await dispatch(
			await loadCompany(
				documentId(companySelected),
				{
					withGenderScoreAction: true,
					withGenderScoreSurveys: true,
					withUMSResults: true,
					withUMSSurveys: true,
				},
			),
		);
	};

	return (
		<>
			<StaffManagerPanelTabContentWrapper>

				<Link
					onClick={() => setOpenUMSSettingsModal(true)}>
					Open {UMS_LABEL} settings for <b>{companySelected?.name}</b>
				</Link>

				<StaffManagerPanelDivider>
					Surveys
				</StaffManagerPanelDivider>

				{/* <Space>
					<StaffManagerPanelLink
						label={'Delete all surveys'}
						confirm='Are you sure you want to delete all the GS surveys WITH ANY STATUS of this company?'
						onClick={async () => {
							await deleteSurveys();
						}}
					/>
				</Space> */}

				<Space
					direction='horizontal'
					size={10}
					wrap={false}
					style={{
						width: '100%',
						padding: '0 15px 15px 15px',
					}}
				>
					<SurveyLangSwitcher />

				</Space>

				<StaffManagerPanelDivider>
					Survey
				</StaffManagerPanelDivider>

				<div>
					<b>Selected draft:</b> {draftSelected?.survey || NOT_PROVIDED_YET_LABEL}
				</div>

				{draftSelected && (
					<>
						<div>
							<b>Completion:</b>
						&nbsp;
							{countSurveyCompletedQuestions({ surveyConf, answers, withOptional: false })}
						&nbsp;/&nbsp;
							{countSurveyMandatoryQuestions({ surveyConf, answers })}
						&nbsp;(mandatory),&nbsp;
							{countSurveyCompletedQuestions({ surveyConf, answers, withOptional: true })}
						&nbsp;/&nbsp;
							{countSurveyQuestions({ surveyConf })}
						&nbsp;(all - optional included)
						</div>

						{/* <StaffManagerPanelLink
							label={'Delete selected draft'}
							confirm={`Are you sure you want to delete the selected draft? You will be taken to the "Start the ${UMS_LABEL}" page.`}
							onClick={async () => {
								await createOrUpdateSurveyDraft({
									data: { isActive: false, withSlack: false },
									withDispatch: true,
								});
							}}
						/>
 */}
						<StaffManagerPanelLink
							label={'Reset all questions'}
							confirm='Are you sure you want to erase all your current answers to the survey questions?'
							onClick={async () => {
								const newAnswers = walkSectionQuestionForAnswers({
									sections: surveyConf?.sections,
									questionAnswerMapper: ({ question }) => ({ [question?.questionId]: null }),
								});

								await saveAnswers({
									answers: newAnswers,
									withSlack: false,
								});
							}}
						/>

						<StaffManagerPanelLink
							label={'Fill random questions'}
							confirm='Are you sure you want to randomly fill the survey questions?'
							onClick={async () => {
								const newAnswers = walkSectionQuestionForAnswers({
									sections: surveyConf?.sections,
									questionAnswerMapper: ({ question }) => {
										const value = Math.random() > 0.5
											? randomlyFakeQuestionAnswer({ question })
											: null;
										return { [question?.questionId]: value };
									},
								});

								LocalDebug.logInfo({ className }, { newAnswers });
								await saveAnswers({
									answers: newAnswers,
									withSlack: false,
								});
							}}
						/>

						<StaffManagerPanelLink
							label={'Fill all questions but the last'}
							confirm='Are you sure you want to fill all the survey questions but the last one?'
							onClick={async () => {
								const newAnswers = walkSectionQuestionForAnswers({
									sections: surveyConf?.sections,
									questionAnswerMapper: ({
										section, sIndex, sArr, question, qIndex, qArr,
									}) => {
										LocalDebug.logInfo({ className }, {
											section, sIndex, sArr, question, qIndex, qArr,
										});
										if (!question) return {};
										if ((
											sIndex === ((sArr?.length || 0) - 1)
												&& qIndex === ((qArr?.length || 0) - 1))
										) {
											return { [question?.questionId]: null };
										}
										return { [question?.questionId]: randomlyFakeQuestionAnswer({ question }) };
									},
								});

								LocalDebug.logInfo({ className }, { newAnswers });
								await saveAnswers({ answers: newAnswers, withSlack: false });
							}}
						/>

						<StaffManagerPanelLink
							label={'Fill all questions but one'}
							confirm='Are you sure you want to fill all the survey questions but one?'
							onClick={async () => {
								const randUnansweredQuestionNotLast = Math.floor(
									Math.random() * (
										(surveyConf?.sections
											?.map((section) => section?.questions?.length)
											?.reduce(sumReducer, 0) || 0)
										- 1),
								);
								LocalDebug.logInfo({ className }, { randUnansweredQuestionNotLast });

								let qTotalIndex = 0;
								const newAnswers = walkSectionQuestionForAnswers({
									sections: surveyConf?.sections,
									questionAnswerMapper: ({
										section, sIndex, sArr, question, qIndex, qArr,
									}) => {
										LocalDebug.logInfo({ className }, {
											sIndex, qIndex, qTotalIndex, randUnansweredQuestionNotLast,
										});
										if (!question) return {};
										if (qTotalIndex === randUnansweredQuestionNotLast) {
											qTotalIndex += 1;
											return { [question?.questionId]: null };
										}
										qTotalIndex += 1;
										return { [question?.questionId]: randomlyFakeQuestionAnswer({ question }) };
									},
								});

								LocalDebug.logInfo({ className }, { newAnswers });
								await saveAnswers({ answers: newAnswers, withSlack: false });
							}}
						/>

						<StaffManagerPanelLink
							label={'Fill all questions'}
							confirm='Are you sure you want to fill all the survey questions?'
							onClick={async () => {
								const newAnswers = walkSectionQuestionForAnswers({
									sections: surveyConf?.sections,
									questionAnswerMapper: ({ question }) => {
										if (!question) return {};
										return { [question?.questionId]: randomlyFakeQuestionAnswer({ question }) };
									},
								});

								LocalDebug.logInfo({ className }, { newAnswers });
								await saveAnswers({ answers: newAnswers, withSlack: false });
							}}
						/>
					</>
				)
				}
				<StaffManagerPanelDivider>
					Surveys
				</StaffManagerPanelDivider>
				<div>
					<b>Surveys:</b> {new CompanyModel(companySelected).getUMSSurveys()?.length || 'None'}
				</div>
				<div>
					<b>Drafts:</b> {new CompanyModel(companySelected).getUMSSurveyDrafts()?.length || 'None'}
				</div>

			</StaffManagerPanelTabContentWrapper>

			{/* Modal window for the UMS settings form */}
			<ModalContainerBase
				open={openUMSSettingsModal}
				setOpen={setOpenUMSSettingsModal}
				width={600}
			>
				<CompanySettingsUMSForm
					company={companyModel}
					onSuccess={() => setOpenUMSSettingsModal(false)}
				>
					<Box style={{ background: theme.color.bgGrey, marginBottom: 12 }}>
						<CompanyStub company={companyModel} />
					</Box>
				</CompanySettingsUMSForm>
			</ModalContainerBase>
		</>
	);
};

export default StaffManagerUMSTab;
