import {
	Divider, Space, Steps, Tooltip,
} from 'antd';
import moment from 'moment';
import UserAvatar from '../user/widgets/UserAvatar';
import {
	SOURCING_ACTION_STATUS,
	SOURCING_ACTION_STEP,
	SOURCING_ACTION_COMMENT,
	DATE_FORMAT,
	SOURCING_ACTION_STAGE,
	SOURCING_ACTION_CREATION,
	SOURCING_ACTION_CONTACT,
	renderStepTag,
	renderStatusTag,
	renderStageTag,
	SOURCING_ACTION_ARCHIVE,
	SOURCING_ACTION_MANAGER,
} from '../../constants/constant';

const SourcingHistory = (
	{
		sourcing,
	},
) => {
	return (
		sourcing?.history?.length > 0
        && <>
        	<div style={{ fontSize: 16 }}><strong>History</strong></div>
        	<Divider style={{ marginTop: 5 }}/>
        	<Steps direction="vertical">
        		{(sourcing.history || [])
        			.filter((i) => i.isActive !== false)
        			.map((step, index) => (
        				<Steps.Step
        					key={index}
        					icon={step?.author && <div>
        						<UserAvatar
        							user={step?.author}
        							title={`${step?.author?.firstName} ${step?.author?.lastName}`}
        						/></div>}
        					title={
        						<div>
        							<span style={{ color: '#333', fontSize: '14px', lineHeight: '18px' }}>
        								{step.action === SOURCING_ACTION_CREATION && <>Created
                                            by <strong>{step.author.firstName}</strong></>}
        								{step.action === SOURCING_ACTION_COMMENT && 'Comment'}
        								{step.action === SOURCING_ACTION_STEP
                                        && <span>Step changed to: {renderStepTag(step?.body || step?.payload?.value)}</span>}
        								{step.action === SOURCING_ACTION_STATUS
                                        && <span>Status changed to: {renderStatusTag(step?.body || step?.payload?.value)}</span>}
        								{step.action === SOURCING_ACTION_STAGE
                                        && <span>Stage changed to: {renderStageTag(step?.body || step?.payload?.value)}</span>}
        								{step.action === SOURCING_ACTION_CONTACT && <span>Message sent</span>}
        								{step.action === SOURCING_ACTION_ARCHIVE && <span>Archived</span>}
        								{step.action === SOURCING_ACTION_MANAGER && <span>Manager set</span>}
        							</span>
        							{/* { isEditor && */}
        							{/* <Popconfirm */}
        							{/*    title="Are you sure to delete?" */}
        							{/*    onConfirm={() => handleDeleteStep(documentId(sourcing), step, sourcing.history)} */}
        							{/*    onCancel={() => {}} */}
        							{/*    okText="Yes" */}
        							{/*    cancelText="No" */}
        							{/* > */}
        							{/*    <Button type="text" icon ={<RiCloseCircleFill />} /> */}
        							{/* </Popconfirm> */}
        							{/* } */}
        						</div>
        					}
        					description={<Space direction="vertical" size={4}>
        						<div>
        							{[SOURCING_ACTION_COMMENT, SOURCING_ACTION_CONTACT].includes(step.action)
                                    && (step?.body || step?.payload?.text)
                                    && <div style={{
                                    	color: '#666',
                                    	marginTop: '8px',
                                    	background: 'white',
                                    	border: '1px solid #ccc',
                                    	borderRadius: '4px',
                                    	padding: '4px 12px',
                                    }}>
                                    	{step?.body || step?.payload?.text}
                                    </div>}
        							<Tooltip title={moment(step.createdAt).format(DATE_FORMAT)}>
        								<span style={{ fontSize: '10px' }}>{moment(step.createdAt).fromNow()}</span>
        							</Tooltip>
        						</div>
        					</Space>}
        				 />
        			))
        		}
        	</Steps>
        </>
	);
};

export default SourcingHistory;
