import {
	GS_ACTION_BUDGET_HIGH, GS_ACTION_COMPLETION_STATUS_DONE, GS_ACTION_COMPLETION_STATUS_TODO, GS_ACTION_COMPLEXITY_HIGH, GS_ACTION_PEOPLE_HIGH, GS_ACTION_TIMEFRAME_SHORT_TERM, GS_PILLAR_CATEGORY_GD_WORKFORCE,
	findActionPillar,
	findActionPillarCategory,
} from '../components/company/actionPlan/action-plan';
import { GENDERSCORE_PILLAR_GENDERDATA_VALUE } from '../constants/genderscore-pillars';
import { arrayWrap } from '../utils/common';

export default class CompanyActionModel {
	_id;

	id;

	actionId;

	title = 'Default title';

	pillar = GENDERSCORE_PILLAR_GENDERDATA_VALUE;

	pillarCategory = [GS_PILLAR_CATEGORY_GD_WORKFORCE];

	complexity = GS_ACTION_COMPLEXITY_HIGH;

	budget = GS_ACTION_BUDGET_HIGH;

	people = GS_ACTION_PEOPLE_HIGH;

	completionStatus = GS_ACTION_COMPLETION_STATUS_TODO;

	timeframe = GS_ACTION_TIMEFRAME_SHORT_TERM;

	details = 'Details';

	timeframeIndex = 0;

	completionStatusIndex = 0;

	constructor(props) {
		Object.assign(this, props);
		if (!this.actionId) this.actionId = this._id;
		if (!this._id) this._id = this.actionId;
		if (!this.id) this.id = this._id;
		this.pillar = arrayWrap(this?.pillarCategory)
			.map((category) => findActionPillarCategory(category)?.pillar);
	}

	get isDone() {
		return this.completionStatus === GS_ACTION_COMPLETION_STATUS_DONE;
	}

	get descriptionForMarkdown() {
		let desc = this.description;
		if (desc?.startsWith('> ')) desc = desc?.replace?.('> ', '- ');
		desc = desc?.replaceAll('\n> ', '\n- ');
		return desc;
	}

	isSame(action) {
		return this.id === action.id;
	}

	isFromPillar(pillar) {
		return this?.pillarCategory
			?.map((c) => findActionPillarCategory(c)?.pillar)
			?.includes?.(pillar);
	}

	isFromPillarCategories(pillarCategories = []) {
		return arrayWrap(pillarCategories).some((c) => this.pillarCategory.includes(c));
	}
}
