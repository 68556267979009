import SourcingsPage from '../../containers/sourcing/SourcingsPage';
import {
	PERMS_GRANT_RECRUITER_DENY_ATS_SYNCABLE,
	PERMS_OVERRIDE_STAFF_BACKUP,
} from '../menu.utils';
import MenuBase from '../MenuBase';
import { CrownOutlined } from '@ant-design/icons';

export const GenderhireCandidatesSourcedIcon = CrownOutlined;

class GenderhireCandidatesSourcedMenu extends MenuBase {
	static props = {
		name: 'candidates-talent-pool',
		path: '/candidates/talent-pool',
		aliases: ['^/sourced$'],
		label: 'Talent Pool',
		icon: <GenderhireCandidatesSourcedIcon />,
		iconLegacy: '💎',
		Component: SourcingsPage,
		perms: [
			...PERMS_GRANT_RECRUITER_DENY_ATS_SYNCABLE,
			PERMS_OVERRIDE_STAFF_BACKUP,
		],
	};

	constructor() {
		super(GenderhireCandidatesSourcedMenu.props);
	}
}

export default GenderhireCandidatesSourcedMenu;
