import { useAuthContext } from '../../../hooks/useAuthContext';
import PageLayout from '../../../components/app/layout/PageLayout';
import StaffChatbotViewer from '../../../components/chatbot/StaffChatbotViewer';
import StaffChatbotViewerMenu from '../../../menu/staff/tech/StaffChatbotViewerMenu';

const StaffChatbotViewerPage = () => {
	const className = 'StaffChatbotViewerPage';

	const { isStaff } = useAuthContext();

	if (!isStaff) return null;

	return (
		<PageLayout
			MenuClass={StaffChatbotViewerMenu}
		>
			<StaffChatbotViewer
				height='calc(100vh - 125px)'
			/>
		</PageLayout>
	);
};

export default StaffChatbotViewerPage;
