import { LocalDebug } from '../../utils/LocalDebug';
import {
	ONBOARDING_BASIC_STEPS_TOTAL,
	getIncompleteBasicStepIdList,
	hasNextIncompleteBasicStep,
	hasAnyCompletedMandatoryStep,
	isMandatoryStepCompleted,
	ONBOARDING_STEP_GENDERSCORE_ID,
	hasAnyInProgressMandatoryStep,
	hasNextIncompleteMandatoryStep,
	isMandatoryStepInProgress,
	onlyDemoStepIsMissing,
} from './OnboardingSteps';

const className = 'OnboardingStates';

export const ONBOARDING_STATE_WELCOME_ID = 'welcome';
export const ONBOARDING_STATE_IN_PROGRESS_ID = 'in-progress';
export const ONBOARDING_STATE_IN_PROGRESS_ONLY_GENDERSCORE_ID = 'in-progress-gender-score';
export const ONBOARDING_STATE_DONE_ID = 'done';// gender-score-done';

export const ONBOARDING_STATE_WELCOME = {
	id: ONBOARDING_STATE_WELCOME_ID,
	title: 'Welcome',
	isActive: (company) => true,
};

/**
 * State is In Progress if:
 * - At least one step in the Basic Steps is complete
 * - At least one step in the Basic Steps is missing
 */
export const ONBOARDING_STATE_IN_PROGRESS = {
	id: ONBOARDING_STATE_IN_PROGRESS_ID,
	title: 'In Progress',
	isActive: (company) => {
		LocalDebug.logNull(
			{ className, method: ONBOARDING_STATE_IN_PROGRESS_ID },
			{
				incomplete: getIncompleteBasicStepIdList(),
				ONBOARDING_BASIC_STEPS_TOTAL,
			},
		);

		return (hasAnyCompletedMandatoryStep(company) || hasAnyInProgressMandatoryStep(company))
		&& hasNextIncompleteBasicStep(company);
	},
};

/**
 * State is In Progress (only GenderScore is missing) if:
 * - All the Basic Steps are complete
 * - The GenderScore is missing
 */
export const ONBOARDING_STATE_IN_PROGRESS_ONLY_GENDERSCORE = {
	id: ONBOARDING_STATE_IN_PROGRESS_ONLY_GENDERSCORE_ID,
	title: 'In Progress',
	isActive: (company) => {
		LocalDebug.logNull(
			{ className, method: ONBOARDING_STATE_IN_PROGRESS_ONLY_GENDERSCORE_ID },
			{
				incomplete: getIncompleteBasicStepIdList(),
				hasNextIncomplete: hasNextIncompleteBasicStep(company),
				genderScore: isMandatoryStepCompleted(ONBOARDING_STEP_GENDERSCORE_ID, company),
				ONBOARDING_BASIC_STEPS_TOTAL,
			},
		);

		return !hasNextIncompleteBasicStep(company)
				&& !isMandatoryStepCompleted(ONBOARDING_STEP_GENDERSCORE_ID, company);
	},
};

/**
 * State is Done if:
 * - All Mandatory Steps are complete
 * - All Basic Steps are complete and the GenderScore is in progress
 */
export const ONBOARDING_STATE_DONE = {
	id: ONBOARDING_STATE_DONE_ID,
	title: 'Done',
	isActive: (company) => {
		LocalDebug.logNull(
			{ className, method: ONBOARDING_STATE_DONE_ID },
			{
				incomplete: getIncompleteBasicStepIdList(),
				ONBOARDING_BASIC_STEPS_TOTAL,
			},
		);

		return onlyDemoStepIsMissing(company);
	},
};
export const ONBOARDING_STATES = [
	ONBOARDING_STATE_WELCOME,
	ONBOARDING_STATE_IN_PROGRESS,
	ONBOARDING_STATE_IN_PROGRESS_ONLY_GENDERSCORE,
	ONBOARDING_STATE_DONE,
];

export const getCurrentOnboardingState = (company) => {
	const [state] = ONBOARDING_STATES
		.filter((item) => item.isActive(company))
		.reverse();
	return state;
};
