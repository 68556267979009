import { useSelector } from 'react-redux';
import PageLayout from '../../components/app/layout/PageLayout';
import { useTheme } from '@emotion/react';
import { getCompanySelected } from '../../reducers/app';
import CompanyGridPicker from '../../components/company/CompanyGridPicker';
import GenderScoreResourcesGrid from '../../components/company/genderscore/resources/GenderScoreResourcesGrid';
import GenderscoreResourcesMenu from '../../menu/genderscore/GenderscoreResourcesMenu';

const GenderScoreResourcesPage = () => {
	const className = 'GenderScoreResourcesPage';

	const theme = useTheme();

	const companySelected = useSelector(getCompanySelected);

	if (!companySelected) return <CompanyGridPicker />;

	const menu = (new GenderscoreResourcesMenu()).build();

	return (
		<PageLayout
			icon={menu.icon}
			title={menu.label}
		>

			<GenderScoreResourcesGrid
				company={companySelected}
			/>

		</PageLayout>
	);
};

export default GenderScoreResourcesPage;
