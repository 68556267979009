import UserMailingService from '../services/user-mailing';
import { localDebug } from '../utils/LocalDebug';
import { isFunction } from '../utils/common';

export const refreshUserMailingMailjetData = async (data, callback) => {
	try {
		await UserMailingService.refreshMailjetData(data);
		if (isFunction(callback)) callback();
	} catch (error) {
		localDebug(__filename.split('/').shift(), error);
		throw error;
	}
};

export const retrySendUserMailing = async (data) => {
	try {
		const { data: { data: userMailing } } = await UserMailingService.retrySend(data);
		return userMailing;
	} catch (error) {
		localDebug(__filename.split('/').shift(), error);
		throw error;
	}
};
