import { useState } from 'react';
import { Divider, Space } from 'antd';
import { ENGLISH_LANG } from '../../../../constants/constant';
import CompanyModel from '../../../../models/CompanyModel';
import CompanyNumberEditor from '../shared/CompanyNumberEditor';
import CompanyGlassdoorDisplay from './CompanyGlassdoorDisplay';
import { useTheme } from '@emotion/react';

const CompanyGlassdoorEditor = (
	{
		company,
		lang = ENGLISH_LANG,
		setEditorOpen,
	},
) => {
	const className = 'CompanyGlassdoorEditor';

	const theme = useTheme();

	const title = 'Glassdoor rating';
	const field = 'glassdoor';

	const [editedValue, setEditedValue] = useState(company.getTranslationField(field, lang));

	const onChange = (value) => {
		setEditedValue(value);
	};

	return (
		<>
			<CompanyNumberEditor
				company={company}
				lang={lang}
				title={<b>{title}</b>}
				field={field}
				setEditorOpen={setEditorOpen}
				isTranslatable={false}
				min={0}
				max={5}
				step={0.1}
				onChange={onChange}
				style={{
					input: {
						width: 120,
						minWith: 120,
						maxWith: 120,
						textAlign: 'center',
						fontSize: 24,
						color: theme.color.darkgrey,
					},
				}}
			>
				<Divider style={{ marginTop: 40 }}>Preview</Divider>

				<CompanyGlassdoorDisplay
					company={new CompanyModel({ [field]: editedValue })}
					lang={lang}
					width={200}
				/>

			</CompanyNumberEditor>
		</>
	);
};

export default CompanyGlassdoorEditor;
