import {
	Row, Col, Form, Space, Input, Select, Modal,
} from 'antd';
import { useTheme } from '@emotion/react';
import UserSelect from '../user/UserSelect';
import { useAuthContext } from '../../hooks/useAuthContext';
import UserStub from '../user/stub/UserStub';
import CKEditor from '../app/form/CkEditor';
import { FaChevronDown } from 'react-icons/fa';
import Callout from '../app/callout/Callout';
import templateService from '../../services/template';
import {
	documentId, readTemplates, requiredRule, requiredTrimRule, writeLog,
} from '../../utils/common';
import { useContext, useState } from 'react';
import { useDispatch } from 'react-redux';
import { shouldRefreshData } from '../../actions/app';
import FormSelect from '../app/form/FormSelect';
import { EMAIL_TEMPLATES } from '../../constants/constant';
import { LocalDebug } from '../../utils/LocalDebug';
import FormItem from '../company/form/FormItem';
import { NotificationContext } from '../app/notification/NotificationProvider';
import FormBoxTitle from '../app/form/FormBoxTitle';
import FormSubmitButton from '../app/form/FormSubmitButton';
import Box from '../app/box/Box';
import MessageTemplateList from './templates/MessageTemplateList';
import { GenderhireMessagesIcon } from '../../menu/genderhire/GenderhireMessagesMenu';

const draftStorageKey = (u) => `draft_message_${documentId(u)}`;

const UserMessageForm = (
	{
		initialValues = {},
		onSubmit,
		onReset,
		...props
	},
) => {
	const className = 'UserMessageForm';

	const theme = useTheme();
	const dispatch = useDispatch();

	const { notifySuccess } = useContext(NotificationContext);
	const {
		currentUser, currentUserModel, companyRecruiterDocIds, isMasterTemplatesEnabled,
	} = useAuthContext();
	const [emailTemplateId, setEmailTemplateId] = useState(null);

	// const handleCancel = () => {
	// 	setIsModalOpen(false);
	// }

	// const [category, setCategory] = useState(null);

	const defaultUser = initialValues?.userTo || currentUser;
	const viaProfile = initialValues?.viaProfile || false;

	const getStoredContent = () => {
		try {
			return JSON.parse(localStorage.getItem(draftStorageKey(defaultUser)));
		} catch (e) {}
		return {};
	};
	const clearStoredContent = () => {
		try {
			localStorage.setItem(draftStorageKey(defaultUser), '{}');
			localStorage.removeItem(draftStorageKey(defaultUser));
		} catch (e) {}
	};
	const setStoredContent = ({ category, subject, body }) => {
		LocalDebug.logInfo({ className, method: 'setStoredContent' }, { category, subject, body });
		try {
			localStorage.setItem(
				draftStorageKey(defaultUser),
				JSON.stringify({
					...getStoredContent(),
					...category ? { category } : {},
					content: {
						...getStoredContent()?.content || {},
						...subject || subject === '' ? { subject } : {},
						...body || body === '' ? { body } : {},
					},
				}),
			);
		} catch (e) {}
		LocalDebug.logInfo({ className, method: 'setStoredContent - getStoredContent' }, getStoredContent());
	};

	initialValues = {
		...getStoredContent() || {},
		...initialValues || {},
		content: {
			...getStoredContent()?.content || {},
			...initialValues?.content || {},
		},
	};

	const [form] = Form.useForm();

	const handleSubmit = (values) => {
		const [companyId] = companyRecruiterDocIds;
		// localDebug(companyRecruiterDocIds);
		const data = {
			companyId: companyId || currentUser?.information?.companyId,
			userId: documentId(currentUser),
			...values,
			id: documentId(initialValues, null),
			sourcingId: initialValues?.sourcingId || null,
			emailTemplateId,
			// category,
		};
		onSubmit(data);

		clearStoredContent();

		notifySuccess({
			description: <span>
				<b>{defaultUser?.firstName}</b> has been contacted.
			</span>,
		});

		// message.success(`The talent has been contacted`);
		form.resetFields();
		dispatch(shouldRefreshData());
	};

	const anonymizeContent = (content) => content
		.replaceAll('{{talentFirstName}}', '')
		.replaceAll('{{talentLastName}}', '')
		.replaceAll('{{talentFullName}}', '');

	const handleSelectTemplate = async (templateId) => {
		LocalDebug.logInfo({ className, method: 'handleSelectTemplate' }, { templateId });
		if (templateId) {
			// setIsModalOpen(true);
			Modal.confirm({
				title: 'Heads up!',
				content: <> Do you want to use this template? It will replace your current message.</>,
				okText: <b>Yes, replace</b>,
				onOk: async () => {
					writeLog('select template', { templateId });
					let {
						data: {
							template: {
								category, subject, content: body,
							},
						},
					} = await templateService.getById(templateId);
					LocalDebug.logInfo({ className, method: 'handleSelectTemplate' }, category);
					if (currentUserModel?.canDisplayTalentNames?.() === false) {
						body = anonymizeContent(body);
						subject = anonymizeContent(subject);
					}
					body = readTemplates(body, defaultUser, currentUser);
					subject = readTemplates(subject, defaultUser, currentUser);
					form.setFieldsValue({ category, content: { subject, body } });
					setStoredContent({ category, subject, body });
					setEmailTemplateId(templateId);
				},
				// onCancel: () => setIsModalOpen(false),
				cancelText: 'Cancel',
			});
		}
	};

	return (
		<>
			<div style={{ ...props?.style }}>
				<FormBoxTitle
					title={<><GenderhireMessagesIcon /> Contact a talent</>}
				/>
				<Form
					form={form}
					name="alert_form"
					initialValues={{ ...initialValues }}
					layout='vertical'
					onFinish={handleSubmit}
				>
					<Row gutter={[30, 30]}>
						<Col span={9}>
							<FormItem
								label=''
								name='userToId'
								defaultContainer='div'
								rules={[{ required: !viaProfile, message: 'This field is required' }]}
							>
								{viaProfile
									? (
										<Box style={{ width: '100%' }}>
											<UserStub
												user={defaultUser}
												withDropdown={false}
												withIsUnviewed={false}
												withIsNew={false}
												withDEICriteria={true}
											/>
										</Box>
									)
									: (
										<UserSelect
											defaultOptions={[defaultUser]}
											placeholder="Select a user"
											style={{ width: '100%' }}
											disabled={viaProfile}
											notUserIds={initialValues?.notUserIds}
										/>
									)
								}
							</FormItem>

							<p>
								<b>Start from a template <FaChevronDown style={{ marginBottom: -2 }} /></b>
							</p>
							<MessageTemplateList
								withBlankTemplateBtn={false}
								onTemplateSelected={(t) => handleSelectTemplate(t?.masterTemplateId)}
								style={{
									height: 350,
									border: '1px solid #ddd',
								}}
							/>
						</Col>

						<Col span={15}>

							{isMasterTemplatesEnabled && <FormItem
								label="Category"
								name="category"
								defaultContainer="div"
								rules={[requiredRule]}
							>
								<FormSelect
									options={EMAIL_TEMPLATES.CATEGORY_LIST}
									styles={{ maxWidth: 300 }}
									onChange={(category) => setStoredContent({ category })}
									render={(option) => (
										<Select.Option
											key={option.value}
											label={option.label}
											value={option.value}
										>
											{option.label}
										</Select.Option>
									)}
								/>
							</FormItem>}

							<FormItem
								label={
									<Space style={{ display: 'flex', justifyContent: 'space-between' }}>
										<span>Subject</span>
										{/* <TemplateDropDown */}
										{/*	onSelect={handleSelectTemplate} */}
										{/* /> */}
									</Space>
								}
								defaultContainer="div"
								name={['content', 'subject']}
								rules={[requiredTrimRule]}
							>
								<Input onChange={(e) => setStoredContent({ subject: e?.target?.value })} />
							</FormItem>

							<FormItem
								label={
									<span>Your message to {defaultUser?.firstName}</span>
								}
								defaultContainer="div"
								name={['content', 'body']}
								rules={[requiredTrimRule]}
							>
								<CKEditor
									height={200}
									config={{
										toolbar: ['bold', 'italic', 'link', 'bulletedList', 'numberedList', 'blockQuote'],
									}}
									style={{ width: '100%', fontSize: 12 }}
									onChange={(body) => setStoredContent({ body })}
								/>
							</FormItem>

							{/* </FormItemContainer> */}

							{/* <Divider/> */}
							<FormItem
								// style={{ justifyContent: 'flex-end' }}
							>
								<FormSubmitButton size={'large'}>
									Send message
								</FormSubmitButton>
							</FormItem>

						</Col>
					</Row>
					<Row>
						<Callout
							withFeedback={false}
							style={{ marginBottom: 0, background: theme.background.gradientBluePink(315) }}
						>
							<span style={{
								fontStyle: 'italic', fontSize: 12, fontWeight: 'bold', color: 'rgba(0,0,0,.7)',
							}}>
					This will send an email from 50inTech to the talent on your behalf.
							</span>
						</Callout>

					</Row>

				</Form>

			</div>
		</>
	);
};

export default UserMessageForm;
