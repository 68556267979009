import { request } from './api';

const updateOrCreateArticle = (data) => {
	return request.post('/article', data);
};
const deleteArticle = (articleId) => {
	return request.delete(`/article/${articleId}`);
};

const uploadFile = (data) => {
	return request.post('/upload/image-info?folder=articles', data);
};

const findAll = async (options) => {
	const dataParams = {
		params: {
			...(options || {}),
		},
	};
	return request.get('/article', dataParams);
};

const ArticleService = {
	updateOrCreateArticle,
	findAll,
	uploadFile,
	deleteArticle,
};

export default ArticleService;
