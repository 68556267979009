import { Space, Tooltip } from 'antd';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useAuthContext } from '../../../hooks/useAuthContext';
import { documentId, inArray } from '../../../utils/common';
import UserAvatar from '../../user/widgets/UserAvatar';
import UserAvatarList from '../../user/widgets/UserAvatarList';

const JobNotificationsRecipients = (
	{
		job,
		onRefresh,
	},
) => {
	const className = 'JobNotificationsRecipients';
	const { currentUser } = useAuthContext();

	// dipatch
	const dispatch = useDispatch();

	const [managerIds, setManagerIds] = useState([]);
	const [othersManager, setOthersManager] = useState([]);

	useEffect(() => {
		if (job) {
			setOthersManager(
				(job?.managers || [])
					.filter((m) => documentId(m)?.toString?.() !== documentId(currentUser)?.toString?.()),
			);
		}
	}, [job]);

	return (
		<>
			<Space align="center">
				{job?.managers?.length
					? <Space size={3}>
						{inArray(documentId(currentUser), job?.managerIds)
							&& <Tooltip title='You!'>
								<UserAvatar
									size={32}
									user={currentUser}
									withTooltip={false}
									withCompanyLogo={false}
								/>
							</Tooltip>
						}
						<UserAvatarList
							users={othersManager}
							size={24}
							overflow={-6}
							limit={2}
						/>
					</Space>
					: <></>
				}
			</Space>
		</>
	);
};

export default JobNotificationsRecipients;
