import umsService from '../services/ums';
import { actionWithLoader } from './utils';
import { LocalDebug } from '../utils/LocalDebug';

const className = 'ums-actions';
// const classLogger = ({ method }, ...args) => {
// 	console.log('classLogger');
// 	console.log('classLogger', { className });
// 	console.log('classLogger', { method });
// 	console.log('classLogger', { args });
// 	LocalDebug.logInfo({ className, method }, ...args);
// };
const actionBuilder = ({
	method,
	service,
	callback,
	...args
}) => actionWithLoader(async () => {
	try {
		// classLogger({ method }, ...args);
		const result = await service?.(args);
		await callback?.(result);
	} catch (error) {
		LocalDebug.logError({ className }, error);
	}
});

const getRoles = ({ params, callback }) => actionBuilder({
	method: 'getRoles',
	service: umsService.getRoles,
	callback,
	params,
});

const inviteRole = ({ data, callback }) => actionBuilder({
	method: 'inviteRole',
	service: umsService.inviteRole,
	callback,
	data,
});

// const createRole = ({ data, callback }) => actionBuilder({
// 	service: umsService.createRole,
// 	callback,
// 	data,
// });

const updateRole = ({ role, data, callback }) => actionBuilder({
	method: 'updateRole',
	service: umsService.updateRole,
	callback,
	role,
	data,
});

const removeRole = ({ role, callback }) => actionBuilder({
	method: 'removeRole',
	service: umsService.removeRole,
	callback,
	role,
});

const notifyRole = ({ role, callback }) => actionBuilder({
	method: 'notifyRole',
	service: umsService.notifyRole,
	callback,
	role,
});

const renotifyRole = ({ role, callback }) => actionBuilder({
	method: 'renotifyRole',
	service: umsService.renotifyRole,
	callback,
	role,
});

const getResults = ({ params, callback }) => actionBuilder({
	method: 'getResults',
	service: umsService.getResults,
	callback,
	params,
});

const UMSActions = {
	getResults,
	getRoles,
	inviteRole,
	updateRole,
	removeRole,
	notifyRole,
	renotifyRole,
};

export default UMSActions;
