import { useTheme } from '@emotion/react';
import { Col, Row, Space } from 'antd';
import AppLogo from './AppLogo';
import HeaderUser from './HeaderUser';
import HeaderCompanySelector from './HeaderCompanySelector';

const AppLayoutHeader = () => {
	const theme = useTheme();

	return (
		<Row style={{
			position: 'fixed',
			width: '100vw',
			minHeight: 48,
			maxHeight: 48,
			zIndex: 20,
			background: theme.color.white,
			borderBottom: '1px solid #eee',
			padding: '8px 10px',
		}}>
			<Col span={8}>
				<div style={{ display: 'flex', justifyContent: 'start', width: '100%' }}>
					<AppLogo/>
				</div>
			</Col>
			<Col span={8} style={{ zIndex: 11 }}>
				<div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
					<HeaderCompanySelector/>
				</div>
			</Col>
			<Col span={8}>
				<div style={{ display: 'flex', justifyContent: 'end', width: '100%' }}>
					<HeaderUser/>
				</div>
			</Col>
		</Row>
		// <div style={{
		// 	position: 'fixed',
		// 	width: '100vw',
		// 	minHeight: 48, maxHeight: 48,
		// 	zIndex: 10,
		// 	background: theme.color.white,
		// 	borderBottom: '1px solid #eee',
		// 	padding: '6px 10px'
		// }}>
		// 	<Space size={0}
		// 		   style={{width: '100%', justifyContent: 'space-between'}}
		// 	>
		// 		<HeaderAppLogo />
		// 		<HeaderCompanySelector />
		// 		<HeaderUser />
		// 	</Space>
		// 	{/*<div style={{*/}
		// 	{/*	display: 'flex',*/}
		// 	{/*	justifyContent: 'center',*/}
		// 	{/*	pointerEvents: 'none',*/}
		// 	{/*	position: 'absolute',*/}
		// 	{/*	top: 6, left: 0,*/}
		// 	{/*	width: 'calc(100%-200px)',*/}
		// 	{/*}}>*/}
		// 	{/*	<HeaderCompanySelector />*/}
		// 	{/*</div>*/}
		// </div>
	);
};

export default AppLayoutHeader;
