import { useTheme } from '@emotion/react';
import { Radio, Button, Space } from 'antd';
import {
	useContext, useEffect, useMemo, useState,
} from 'react';
import { round } from '../../../../utils/common';
import { SurveyNavigationContext } from '../providers/SurveyNavigationProvider';
import { getQuestionOptions } from '../../data/shared';

const SurveyInputSingleChoice = ({
	question,
	value,
	onChange,
}) => {
	const className = 'SurveyInputSingleChoice';

	const theme = useTheme();
	const { appLang } = useContext(SurveyNavigationContext);

	const [columns, setColumns] = useState(1);
	const [align, setAlign] = useState('vertical');
	const [width, setWidth] = useState('100%');

	useEffect(() => {
		setColumns(question?.columns || 1);
		setAlign(question?.align || 'vertical');
		setWidth(question?.width || '100%');
	}, [question]);

	const memoizedContent = useMemo(() => {
		const options = getQuestionOptions(question);

		return options
			?.map((option, index) => (
				<Button
					key={index}
					onClick={() => onChange(option.value)}
					size='large'
					{...value === option.value ? { type: 'primary' } : {}}
					style={{
						width,
						minWidth: 130,
						minHeight: 60,
						height: 'unset',
						textAlign: 'left',
					}}
				>
					<Radio
						checked={value === option.value}
						style={{
							verticalAlign: 'middle',
							fontSize: 16,
							border: '1px solid rgba(255,255,255,.2)',
							borderRadius: 100,
							padding: '0 5px',
							...value === option.value ? { color: theme.color.fitBlueElectric } : {},
						}}
					/>
					<span
						style={{
							verticalAlign: 'middle',
							whiteSpace: 'normal',
							lineHeight: 1.2,
							textAlign: 'left',
							width: 'calc(100% - 36px)',
						}}
					>
						{option.label?.[appLang] || option.label}
					</span>
				</Button>
			));
	}, [question, value, width, onChange, theme]);

	if (align === 'vertical') {
		return (
			<div
				style={{
					display: 'grid',
					width: '100%',
					gridTemplateColumns: `repeat(${columns}, ${round(100 / columns, 2)}%)`,
					rowGap: 10,
					columnGap: 10,
				}}
			>
				{memoizedContent}
			</div>
		);
	}

	return (
		<Space
			direction='horizontal'
			wrap={true}
		>
			{memoizedContent}
		</Space>
	);
};

export default SurveyInputSingleChoice;
