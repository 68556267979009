const { isStringTypeValidator } = require('../../utils/isStringTypeValidator');

const CONTENT_INTEREST_KEY = 'content-interest';

const CONTENT_INTEREST_ANSWER_TECH_LEADER = 'tech-leader';
const CONTENT_INTEREST_ANSWER_TECH_SKILLS = 'tech-skills';
const CONTENT_INTEREST_ANSWER_SALARY_NEGOTIATION = 'salary-negotiation';
const CONTENT_INTEREST_ANSWER_PERSONAL_BRANDING = 'personal-branding';
const CONTENT_INTEREST_ANSWER_INTERVIEW_PREP = 'interview-prep';
const CONTENT_INTEREST_ANSWER_WORKLIFE_BALANCE = 'worklife-balance';
const CONTENT_INTEREST_ANSWER_BREAK_BARRIERS = 'break-barriers';
const CONTENT_INTEREST_ANSWER_BECOME_MANAGER = 'become-manager';
const CONTENT_INTEREST_ANSWER_CAREER_PLANNING = 'career-planning';

const CONTENT_INTEREST_OPTIONS = [
	{ value: CONTENT_INTEREST_ANSWER_CAREER_PLANNING },
	{ value: CONTENT_INTEREST_ANSWER_TECH_SKILLS },
	{ value: CONTENT_INTEREST_ANSWER_SALARY_NEGOTIATION },
	{ value: CONTENT_INTEREST_ANSWER_PERSONAL_BRANDING },
	{ value: CONTENT_INTEREST_ANSWER_INTERVIEW_PREP },
	{ value: CONTENT_INTEREST_ANSWER_WORKLIFE_BALANCE },
	{ value: CONTENT_INTEREST_ANSWER_BECOME_MANAGER },
];

const customValidator = (value) => CONTENT_INTEREST_OPTIONS.map((o) => o.value).includes(value);

const CONTENT_INTEREST_VALIDATORS = [
	isStringTypeValidator,
	customValidator,
];

export default {
	key: CONTENT_INTEREST_KEY,
	CONTENT_INTEREST_KEY,
	validators: CONTENT_INTEREST_VALIDATORS,
	CONTENT_INTEREST_VALIDATORS,
	options: CONTENT_INTEREST_OPTIONS,
	CONTENT_INTEREST_OPTIONS,
};
