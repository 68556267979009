import { ENGLISH_LANG, teamSizeOptions } from '../../../../constants/constant';
import CompanySelectEditor from '../shared/CompanySelectEditor';
import { useEffect, useState } from 'react';
import { getTranslatedOptions } from '../../../../utils/common';

const CompanyTeamSizeEditor = (
	{
		company,
		lang = ENGLISH_LANG,
		setEditorOpen,
	},
) => {
	const className = 'CompanyTeamSizeEditor';

	const title = 'Team size';
	const field = 'teamsize';

	const [options, setOptions] = useState([]);

	useEffect(() => {
		setOptions(getTranslatedOptions(teamSizeOptions) || []);
	}, [lang]);

	return (
		<CompanySelectEditor
			company={company}
			title={<b>{title}</b>}
			details={<span>
				How many employees work for your company
			</span>}
			field={field}
			options={options}
			setEditorOpen={setEditorOpen}
			isTranslatable={false}
		/>
	);
};

export default CompanyTeamSizeEditor;
