import { BOOLEAN_NO_ICON, BOOLEAN_YES_ICON } from '../../../constants/constant';

const CheckboxTable = ({ isChecked = true, styles = {} }) => {
	return isChecked
		? <span style={{ fontSize: 18 }}>{BOOLEAN_YES_ICON}</span>
		: <span style={{ fontSize: 18 }}>{BOOLEAN_NO_ICON}</span>;
	// <RiCheckboxCircleFill style={{ fontSize: 24, color: 'darkturquoise', marginTop: '8px', ...styles}} />
	// : <FaTimesCircle style={{ fontSize: 18, color: 'red', marginTop: '8px', ...styles }} />
	// );
};

export default CheckboxTable;
