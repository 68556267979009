import { useDispatch } from 'react-redux';
import { useTheme } from '@emotion/react';
import { Form, message, Space } from 'antd';
import { useEffect, useReducer, useState } from 'react';
import { useAuthContext } from '../../hooks/useAuthContext';
import { LocalDebug } from '../../utils/LocalDebug';
import { refreshCurrentUser } from '../../actions/app';
import FormItem from '../company/form/FormItem';
import FormBoxTitle from '../app/form/FormBoxTitle';
import FormSubmitButton from '../app/form/FormSubmitButton';
import CompanyService from '../../services/company';
import BlockTester from '../app/blocker/BlockTester';
import InlineNavLink from '../app/button/InlineNavLink';
import Switch from '../app/form/Switch';
import SettingsBrowsingUserStubPreview from './SettingsBrowsingUserStubPreview';
import AccountBrowsingSettingOverrideDisabled from './AccountBrowsingSettingOverrideDisabled';
import UserModel from '../../models/UserModel';
import ReactJson from 'react-json-view';
import { documentId } from '../../utils/common';
import GenderhireCandidatesAtsMenu from '../../menu/genderhire/GenderhireCandidatesAtsMenu';
import GenderhireCandidatesAppliedMenu from '../../menu/genderhire/GenderhireCandidatesAppliedMenu';

const AccountBrowsingForm = (
	{
		user,
		onSuccess,
		style = {},
	},
) => {
	const className = 'AccountBrowsingForm';

	const dispatch = useDispatch();
	const theme = useTheme();
	const { currentUser, isAtsSyncable } = useAuthContext();

	const [form] = Form.useForm();

	const [state, stateDispatch] = useReducer((state, action) => {
		const hasErrors = form.getFieldsError().some(({ errors }) => errors.length);
		const isTouched = form.isFieldsTouched();
		LocalDebug.logInfo({ className, method: 'useReducer' }, {
			state,
			action,
			hasErrors,
			isTouched,
		});
		switch (action) {
			case 'forceDisabled':
				return { disabled: true };
			case 'updateDisabled':
				// const hasErrors = form.getFieldsError().some(({ errors }) => errors.length);
				return { disabled: hasErrors };
		}
	}, { disabled: true });

	useEffect(() => {
		stateDispatch('forceDisabled');
	}, [user]);

	if (!user) return null;
	const userModel = new UserModel(user);

	const initialValues = {
		shouldAnonTalentAvatars:
			userModel.companyCanRecruiterOverrideAnonTalentAvatars?.()
				? userModel?.companyMemberShouldAnonTalentAvatars?.()
				: userModel?.companyShouldAnonTalentAvatars?.(),
		shouldAnonTalentNames:
			userModel?.companyCanRecruiterOverrideAnonTalentNames?.()
				? userModel?.companyMemberShouldAnonTalentNames?.()
				: userModel?.companyShouldAnonTalentNames?.(),
	};

	const onFormFieldsChange = () => {
		stateDispatch('updateDisabled');
	};

	const onFormFinish = async (values) => {
		message.loading({ content: 'Updating settings', key: className });

		stateDispatch('forceDisabled');

		const data = {
			userId: documentId(user),
			companyId: user?.information?.companyId,
			...values,
		};

		LocalDebug.logInfo({ className, method: 'onFormFinish' }, { user, data });
		const result = await CompanyService.createOrUpdateCompanyMember(data);

		LocalDebug.logInfo({ className, method: 'onFormFinish' }, { result });
		// await UserService.updateProperty(documentId(currentUser), { key: 'profile-avatar', value: imageUrl });
		message.success({ content: 'Your settings have been updated', key: className });

		if (documentId(user) === documentId(currentUser)) refreshCurrentUser()(dispatch);

		onSuccess?.();
	};

	const candidatesAtsMenu = (new GenderhireCandidatesAtsMenu()).build();
	const candidatesAppliedMenu = (new GenderhireCandidatesAppliedMenu()).build();

	return (
		<BlockTester withBorder={false} tagStyle={{ top: 5, left: 12, opacity: 1 }}>
			<Form
				form={form}
				name='user-browsing-settings'
				initialValues={initialValues}
				onFinish={onFormFinish}
				onFieldsChange={onFormFieldsChange}
				style={{ ...style }}
				labelCol={{ span: 6 }}
				labelAlign='right'
				wrapperCol={{ span: 24 }}
				layout='vertical'
			>
				<FormBoxTitle
					title={<>👀 Your unbiased browsing settings</>}
					details={<>
						<div>
							Reduce inconscious bias by navigating 50inTech's talent pool without showing the photos or
							names of candidates.
						</div>
						<div>
							Note that these settings don't apply to
							the {
								isAtsSyncable
									? (<>
										"Applications" of the <InlineNavLink
											path={candidatesAtsMenu.path}>
											{candidatesAtsMenu.icon} {candidatesAtsMenu.label}
										</InlineNavLink>
									</>
									)
									: (
										<InlineNavLink
											path={candidatesAppliedMenu.path}
										>
											{candidatesAppliedMenu.icon} {candidatesAppliedMenu.label}
										</InlineNavLink>
									)
							} section.
						</div>
					</>}
				>
					<FormItem
						name='shouldAnonTalentAvatars'
						valuePropName='checked'
						{...!userModel?.companyCanRecruiterOverrideAnonTalentAvatars() && {
							extra: <AccountBrowsingSettingOverrideDisabled />,
						}}
					>
						<Switch
							style={{ width: 90 }}
							className='ant-switch-large'
							disabled={!userModel?.companyCanRecruiterOverrideAnonTalentAvatars()}
							before={<>I want to</>}
							after={<><b>talent avatars</b></>}
							checkedChildren={
								<b>
									<Space>
										<span>hide</span>
									</Space>
								</b>}
							unCheckedChildren={
								<b>
									<Space>
										<span>show</span>
									</Space>
								</b>}
						/>
					</FormItem>

					<FormItem
						// label="&nbsp;"
						name='shouldAnonTalentNames'
						valuePropName='checked'
						{...(!userModel?.companyCanRecruiterOverrideAnonTalentNames()) && {
							extra: <AccountBrowsingSettingOverrideDisabled />,
						}}
					>
						<Switch
							style={{ width: 90 }}
							className='ant-switch-large'
							disabled={!userModel?.companyCanRecruiterOverrideAnonTalentNames()}
							before={<>I want to</>}
							after={<><b>talent names</b></>}
							checkedChildren={<b>hide</b>}
							unCheckedChildren={<b>show</b>}
						/>
					</FormItem>

					<SettingsBrowsingUserStubPreview
						showAvatar={!form.getFieldValue('shouldAnonTalentAvatars')}
						showName={!form.getFieldValue('shouldAnonTalentNames')}
					/>

					<FormItem>
						<FormSubmitButton disabled={state?.disabled}>
							Save browsing settings
						</FormSubmitButton>
					</FormItem>

				</FormBoxTitle>
			</Form>

		</BlockTester>
	);
};

export default AccountBrowsingForm;
