import { useDispatch, useSelector } from 'react-redux';
import { getUserSelected } from '../../reducers/app';
import {
	useContext,
	useEffect, useMemo, useRef, useState,
} from 'react';
import UserProfile from '../../components/user/profile/UserProfile';
import { LocalDebug } from '../../utils/LocalDebug';
import { loadUser } from '../../actions/users';
import UserPageStaffManagerPanel from '../../components/user/UserPageStaffManagerPanel';
import StaffManagerUserPageTab from '../../components/staff/manager/tabs/StaffManagerUserPageTab';
import { StaffToolsContext } from '../../contexts/StaffToolsProvider';

const UserPage = () => {
	const className = 'UserPage';
	const dispatch = useDispatch();
	const user = useSelector(getUserSelected);
	const container = useRef();
	const { setPanelContent } = useContext(StaffToolsContext);

	LocalDebug.logInfo({ className });
	// Setting the selected user to null when unmounting
	useEffect(
		() => () => {
			dispatch(loadUser());
		},
		[],
	);

	useEffect(() => {
		setPanelContent?.(<StaffManagerUserPageTab />);
		return () => setPanelContent?.(null);
	}, [setPanelContent]);

	const [memoizedContent, setMemoizedContent] = useState(null);

	useMemo(() => {
		LocalDebug.logNull({ className, effects: 'useMemo(user)' }, { user: user?.slug });
		if (user) {
			setMemoizedContent(
				<div
					className="withAffixedHead"
					ref={container}
				>
					<UserProfile
						user={user}
						withHeader={true}
						affixTop={0}
					/>
				</div>,
			);
		} else {
			setMemoizedContent(null);
		}
	}, [user?.slug]);

	return memoizedContent;
};

export default UserPage;
