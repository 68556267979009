import { useContext, useState } from 'react';
import { useTheme } from '@emotion/react';
import Popin from '../modal/Popin';
import FormBoxTitle from '../form/FormBoxTitle';
import { Button, Space } from 'antd';
import { request } from '../../../services/api';
import { useSelector } from 'react-redux';
import { getCompanySelected } from '../../../reducers/app';
import { PermissionContext } from '../../../contexts/PermissionProvider';
import useBodyScrollBlocker from '../../../hooks/useBodyScrollBlocker';
import CompanyService from '../../../services/company';
import { CompanyContext } from '../../../contexts/CompanyProvider';
import { documentId } from '../../../utils/common';
import ModalContainerBase from '../../../contexts/modals/ModalContainerBase';

const UpgradePlanPopin = ({
	onClose,
}) => {
	const className = 'UpgradePlanPopin';

	const theme = useTheme();

	const companySelected = useSelector(getCompanySelected);

	const {
		openUpgradePlanPopin,
		setOpenUpgradePlanPopin,
	} = useContext(PermissionContext);

	const {
		upgradeRequested,
		setUpgradeRequested,
	} = useContext(CompanyContext);

	const handleClick = async () => {
		CompanyService.postUpgradeRequest(documentId(companySelected));
		setUpgradeRequested(true);
	};

	const handleClose = () => {
		setOpenUpgradePlanPopin(false);
		onClose?.();
	};

	useBodyScrollBlocker(openUpgradePlanPopin, className);

	return (
		<ModalContainerBase
			open={openUpgradePlanPopin}
			width={600}
			onCancel={handleClose}
		>
			<FormBoxTitle
				title='Upgrade your plan'
			/>

			{!upgradeRequested
				? (
					<div style={{ width: '100%' }}>
						<h3>Interested to go further with 50inTech?</h3>
						<p>We also offer hiring solutions to help you bring diversity to your team.
							<br />Browse our pool of +30.000 women in Tech and find your next hire!</p>
						<Space
							style={{
								width: '100%',
								justifyContent: 'center',
								margin: '20px auto 10px',
							}}>
							<Button
								type='primary'
								size='large'
								style={{ fontWeight: 'bold' }}
								onClick={handleClick}
							>
						Notify Sales team
							</Button>
						</Space>
					</div>
				)
				: (
					<>
						<h3>Thanks for your interest!</h3>
						<p>
							Upgrade request received!
							<br />We're on the case 🕵️ and will get back to you via email ASAP!
						</p>
					</>
				)
			}

		</ModalContainerBase>
	);
};

export default UpgradePlanPopin;
