import { useEffect, useState } from 'react';
import { ENGLISH_LANG, teamSizeOptions } from '../../../../constants/constant';
import CompanyFieldNotProvided from '../shared/CompanyFieldNotProvided';
import { useTheme } from '@emotion/react';
import { FaUsers } from 'react-icons/fa';
import { getTranslatedOptions } from '../../../../utils/common';

const CompanyTeamSizeDisplay = (
	{
		company,
		lang = ENGLISH_LANG,
	},
) => {
	const className = 'CompanyTeamSizeDisplay';

	const theme = useTheme();

	const field = 'teamsize';

	const [value, setValue] = useState(null);

	useEffect(() => {
		const findValue = async () => {
			const options = getTranslatedOptions(teamSizeOptions) || [];
			const teamSize = company?.getTranslationFieldFallback(field, lang);
			setValue(options.find((o) => o.value === teamSize)?.label || teamSize);
		};
		findValue();
	}, [company, lang]);

	if (!value) return <CompanyFieldNotProvided>Team size</CompanyFieldNotProvided>;

	return (
		<div
			style={{
				fontSize: 12,
				color: theme.color.grey,
			}}
		>
			<FaUsers style={{ marginBottom: -2 }}/> {value}
		</div>
	);
};

export default CompanyTeamSizeDisplay;
