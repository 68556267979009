import { Form } from 'antd';
import { useContext, useEffect, useState } from 'react';
import moment from 'moment';
import FormItem from '../app/form/FormItem';
import FormSelect from '../app/form/FormSelect';
import Modal from '../app/modal/Modal';
import { documentId, getNextSourcingValueByAction, getSourcingActionOptions, requiredRule } from '../../utils/common';
import { LocalDebug, localDebug } from '../../utils/LocalDebug';
import { treatSourcingActionToDb } from '../../actions/sourcings';
import { SOURCING_ACTION_STAGE, sourcingArchiveReasons, STOP_STATUS } from '../../constants/constant';
import { useAuthContext } from '../../hooks/useAuthContext';
import { useSelector } from 'react-redux';
import { getAtsPipelines } from '../../reducers/app';
import { CandidateContext } from '../../contexts/CandidateProvider';
import FormAtsStageSelect from '../app/form/FormAtsStageSelect';

const SourcingEditModal = (
	{
		initialValues,
		open,
		onClose,
		action,
		onConfirm,
	},
) => {
	const className = 'SourcingEditModal';

	const [form] = Form.useForm();
	const { currentUser } = useAuthContext();
	const { findPipeline, findStage } = useContext(CandidateContext);

	const atsPipelines = useSelector(getAtsPipelines);
	const pipelineOptions = useSelector(getAtsPipelines);
	// const [, setPipelineOptions] = useState([]);
	const [stageOptions, setStageOptions] = useState(pipelineOptions.map((p) => p?.stages).flat());
	const [options, setOptions] = useState([]);
	const [nextValueSelected, setNextValueSelected] = useState({});
	const [defaultStage, setDefaultStage] = useState(null);
	const [isStop, setIsStop] = useState(false);

	useEffect(() => {
		LocalDebug.logUseEffect({ className, effects: 'atsPipelines' }, { atsPipelines });
		setStageOptions(atsPipelines.map((p) => {
			const { label, value, stages } = p;
			return stages.map((s) => ({ ...s, pipeline: { label, value } }));
		}).flat());
	}, [atsPipelines]);

	useEffect(() => {
		LocalDebug.logUseEffect({ className, effects: 'initialValues' }, { initialValues });
		initialValues.newStage = initialValues?.candidateStage;
	}, [initialValues]);

	// useEffect(() => {
	//     LocalDebug.logUseEffect({ className})
	//     setPipelineOptions(atsPipelines);
	// }, [atsPipelines]);

	useEffect(() => {
		// localDebug('SourcingEditModal useEffect')
		if (action?.type === SOURCING_ACTION_STAGE) {
			const nextValue = getNextSourcingValueByAction(action.value, action.type);
			const options = getSourcingActionOptions(action.value, action.type);
			setNextValueSelected(nextValue);
			// setIsStop(nextValue.value === STAGE_);
			setOptions(options);
			form.setFieldsValue({ 'payload.value': nextValue.value });
		}
	}, [action, form]);

	// useEffect(() => {
	//     LocalDebug.logUseEffect({ className, effects:'stageOptions' });
	//     // form.setFieldsValue({'payload.value': nextValue.value});
	// }, [stageOptions]);

	// const handleChangePipeline = (value, option) => {
	//     LocalDebug.logInfo({ className, method: 'handleChangePipeline' }, { value, options, form, formCandidateStage: form.getFieldValue('candidateStage') });
	//     setStageOptions(findPipeline(value)?.stages || []);
	// }

	const handleChangeStage = (value, option) => {
		LocalDebug.logInfo({ className, method: 'handleChangeStage' }, { value, options });
	};

	const saveAction = (values = {}) => {
		const method = 'saveAction';
		const { newStage } = values;
		const stage = findStage(newStage);
		const candidatePipeline = stage?.pipelineId;
		const candidateStage = stage?.value;
		LocalDebug.logInfo({ className, method }, {
			values, stage, candidatePipeline, candidateStage,
		});
		// const stage = findStage(values?.stage'));

		// const history = {
		//     action: action?.type,
		//     creator: documentId(currentUser),
		//     postedAt: moment(),
		//     author: documentId(currentUser),
		//     ...values
		// }
		//
		// const toDb = treatSourcingActionToDb({
		//     id: documentId(initialValues),
		//     status: initialValues?.status,
		//     ...history
		// }, currentUser);
		//
		// onConfirm(toDb, action?.type);
	};

	return (
		open && <Modal
			title={'Edit pipeline & stage'}
			open={open}
			okText="Yes, confirm"
			cancelText="No, cancel"
			onCancel={onClose}
			onOk={() => {
				form
					.validateFields()
					.then((values) => {
						saveAction(values);
						// form.resetFields();
					})
					.catch((info) => {
						localDebug('Validation failed:', info);
					});
			}}
			content={
				<Form
					form={form}
					layout="vertical"
					name="form_in_modal"
					initialValues={{ ...initialValues }}
				>
					<FormItem
						name="newStage"
						label="Stage"
						defaultContainer="div"
					>
						<FormAtsStageSelect
							onChange={handleChangeStage}
							options={stageOptions}
							style={{ width: '100%' }}
						/>
					</FormItem>
					{
						isStop && <FormItem
							label="Raisons"
							defaultContainer="div"
							name="payload.raison"
							rules={[requiredRule]}
							styles={{
								rootContainer: {
									padding: '0 10px',
									display: 'flex',
									alignItems: 'center',
									justifyContent: 'flex-start',
								},
								label: {
									marginBottom: 0,
									paddingRight: 20,
									width: 100,
								},
								itemContainer: {
									marginBottom: 10,
								},
								container: {
									flex: 1,
								},
							}}
						>
							<FormSelect
								options={sourcingArchiveReasons}
								placeholder="Raison"
								defaultValue={initialValues?.payload?.raison}
								mode="tags"
							/>
						</FormItem>
					}
				</Form>
			}
		/>
	);
};

export default SourcingEditModal;
