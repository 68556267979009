import { useContext, useEffect, useState } from 'react';
import { useTheme } from '@emotion/react';
import {
	Divider, Space, Switch, message,
} from 'antd';
import useBodyScrollBlocker from '../../../../hooks/useBodyScrollBlocker';
import CompanyModel from '../../../../models/CompanyModel';
import {
	StaffManagerPanelCompanyGenderScoreFillRandomButton,
	StaffManagerPanelCompanyGenderScoreResetButton,
} from './StaffManagerCompanyPageTab';
import { GENDERSCORE_LEVEL_VALUES } from '../../../../constants/genderscore-levels';
import { getCompanyGenderScoreSurveyDraftSelected, getCompanySelected } from '../../../../reducers/app';
import { useDispatch, useSelector } from 'react-redux';
import { StaffManagerPanelDivider, StaffManagerPanelLink, StaffManagerPanelTabContentWrapper } from '../StaffManagerPanel';
import { GENDERSCORE_LABEL, NOT_PROVIDED_YET_LABEL, PATHS } from '../../../../constants/constant';
import {
	countSurveyCompletedQuestions,
	countSurveyMandatoryQuestions,
	countSurveyQuestions,
	isQuestionAnswered,
} from '../../../genderscore/data/shared';
import { SurveyContext } from '../../../genderscore/survey/SurveyProvider';
import { INPUT_MULTIPLE } from '../../../genderscore/data/questions';
import {
	documentId, mergeReducer, shuffleArr, sumReducer,
} from '../../../../utils/common';
import { AppDataContext } from '../../../../contexts/AppDataProvider';
import CompanyGenderScoreBadge from '../../../company/genderscore/CompanyGenderScoreBadge';
import { CompanySettingsCardLineOk } from '../../../company/settings/CompanySettingsCard';
import Link from '../../../app/Link';
import ModalContainerBase from '../../../../contexts/modals/ModalContainerBase';
import CompanySettingsUMSForm from '../../../company/settings/CompanySettingsGenderScoreForm';
import Box from '../../../app/box/Box';
import CompanyStub from '../../../company/CompanyStub';
import dayjs from 'dayjs';
import CompanyService from '../../../../services/company';
import { loadCompany, saveCompany } from '../../../../actions/company';
import ReactJson from 'react-json-view';
import ReactJsonDebug from '../../../app/debug/ReactJsonDebug';
import { LocalDebug } from '../../../../utils/LocalDebug';

const randomyFakeQuestionAnswer = ({ question }) => {
	let value;
	LocalDebug.logInfo({ className: '', method: 'randomyFakeQuestionAnswer' }, { question, hasOptions: !!question?.options, input: question?.input });
	if (question?.options) {
		if (question?.input === INPUT_MULTIPLE) {
			value = (
				shuffleArr(
					question?.options.slice(),
				)
					.slice(0, 1 + Math.floor(Math.random() * (question?.options?.length || 0)))
					.map((o) => o?.value)
			);
		} else {
			value = (
				shuffleArr(
					question?.options?.slice?.(),
				)
					?.map?.((o) => o?.value)
					?.[0]
			);
		}
	} else {
		value = (question?.min || 0)
			+ 1
			+ Math.round(Math.random() * ((question?.max || 0) - (question?.min || 0)));
	}
	return value;
};

const walkSectionQuestionForAnswers = ({ sections, questionAnswerMapper }) => {
	return sections
		?.map((section, sIndex, sArr) => section?.questions
			?.map((question, qIndex, qArr) => (
				questionAnswerMapper?.({
					section, sIndex, sArr, question, qIndex, qArr,
				})
			)))
		.flat(3)
		.reduce(mergeReducer, {});
};

const StaffManagerGenderScoreTab = () => {
	const className = 'StaffManagerGenderScoreTab';

	const theme = useTheme();
	const dispatch = useDispatch();

	const companySelected = useSelector(getCompanySelected);
	const draftSelected = useSelector(getCompanyGenderScoreSurveyDraftSelected);
	const {
		surveyConf, answers, saveAnswers, createOrUpdateSurveyDraft,
	} = useContext(SurveyContext);
	const {
		isAppSiderVisibleInSurvey, setIsAppSiderVisibleInSurvey,
		isProgressBarVisibleInSurvey, setIsProgressBarVisibleInSurvey,
		isProgressBarClickableInSurvey, setIsProgressBarClickableInSurvey,
	} = useContext(AppDataContext);

	const [openGenderScoreSettingsModal, setOpenGenderScoreSettingsModal] = useState(false);
	const [companyModel, setCompanyModel] = useState();

	useBodyScrollBlocker(openGenderScoreSettingsModal, className);

	useEffect(() => {
		setCompanyModel(
			companySelected?._id
				? new CompanyModel(companySelected)
				: null,
		);
	}, [companySelected]);

	if (!companyModel) {
		return (
			<StaffManagerPanelTabContentWrapper>
				<i>Select a company</i>
			</StaffManagerPanelTabContentWrapper>
		);
	}

	if (!companyModel?.isGenderScoreEnabled?.()) {
		return (
			<StaffManagerPanelTabContentWrapper>
				<CompanySettingsCardLineOk
					ok={companyModel?.isGenderScoreEnabled()}
					okText={<>{GENDERSCORE_LABEL} is enabled</>}
					koText={<>{GENDERSCORE_LABEL} is disabled</>}
				/>
				<Link to={PATHS.CONFIGURE_SETTINGS}>Open Settings</Link>
			</StaffManagerPanelTabContentWrapper>
		);
	}

	const updateExpirationDate = async (data) => {
		const { data: survey } = await CompanyService
			.updateExpirationDate({
				companyId: documentId(companySelected),
				...data || {},
			});
		await dispatch(
			await loadCompany(documentId(companySelected)),
		);
	};

	const deleteSurveys = async (data) => {
		await CompanyService
			.deleteSurveys({
				companyId: documentId(companySelected),
				...data || {},
			});
		await dispatch(
			await loadCompany(
				documentId(companySelected),
				{
					withGenderScoreAction: true,
					withGenderScoreSurveys: true,
					withUMSResults: true,
					withUMSSurveys: true,
				},
			),
		);
	};

	const createSurvey = async (data) => {
		await CompanyService
			.createFakeSurvey({
				companyId: documentId(companySelected),
				...data || {},
			});
		await dispatch(
			await loadCompany(
				documentId(companySelected),
				{
					withGenderScoreAction: true,
					withGenderScoreSurveys: true,
					withUMSResults: true,
					withUMSSurveys: true,
				},
			),
		);
	};

	return (
		<>
			<StaffManagerPanelTabContentWrapper>

				<Link
					onClick={() => setOpenGenderScoreSettingsModal(true)}>
					Open {GENDERSCORE_LABEL} settings for <b>{companySelected?.name}</b>
				</Link>

				<StaffManagerPanelDivider>
					{GENDERSCORE_LABEL} Plan
				</StaffManagerPanelDivider>

				<div>
					<b>Expiration date:</b>&nbsp;
					{dayjs(companyModel.getGenderScoreExpirationDate({ isExpirationOfCurrentBadge: false }))
						.format('DD/MM/YYYY HH:mm:ss.ms')}
					&nbsp;(in {companyModel.getGenderScoreDaysToExpire({ isExpirationOfCurrentBadge: false })} days)
				</div>

				<CompanySettingsCardLineOk
					ok={companyModel?.isGenderScoreCloseToExpire()}
					okText={<>{GENDERSCORE_LABEL} is CLOSE to expire (under 45 days)</>}
					koText={<>{GENDERSCORE_LABEL} is NOT CLOSE to expire yet</>}
					style={{ label: { fontSize: 12 } }}
				/>

				<CompanySettingsCardLineOk
					ok={companyModel?.isGenderScoreExpired()}
					okText={<>{GENDERSCORE_LABEL} is expired</>}
					koText={<>{GENDERSCORE_LABEL} is NOT expired yet</>}
					style={{ label: { fontSize: 12 } }}
				/>
				<Space>
					<StaffManagerPanelLink
						label={'Set to 1 YEAR'}
						confirm='Are you sure you want to change the current GS plan expiration date?'
						onClick={async () => {
							await updateExpirationDate({
								isExpirationOfCurrentBadge: false,
								days: 365,
							});
						}}
					/>
					<StaffManagerPanelLink
						label={'Set to CLOSE TO EXPIRE'}
						confirm='Are you sure you want to change the current GS plan expiration date?'
						onClick={async () => {
							await updateExpirationDate({
								isExpirationOfCurrentBadge: false,
								days: 10,
							});
						}}
					/>
					<StaffManagerPanelLink
						label={'Set to EXPIRED'}
						confirm='Are you sure you want to change the current GS plan expiration date?'
						onClick={async () => {
							await updateExpirationDate({
								isExpirationOfCurrentBadge: false,
								days: 0,
							});
						}}
					/>
				</Space>
				<StaffManagerPanelDivider>
					Current badge
				</StaffManagerPanelDivider>

				<div>
					<b>Expiration date:</b>&nbsp;
					{dayjs(companyModel.getGenderScoreExpirationDate({ isExpirationOfCurrentBadge: true }))
						.format('DD/MM/YYYY HH:mm:ss.ms')}
					&nbsp;(in {companyModel.getGenderScoreDaysToExpire({ isExpirationOfCurrentBadge: true })} days)
				</div>

				<CompanySettingsCardLineOk
					ok={companyModel?.isCurrentGenderScoreBadgeCloseToExpire()}
					okText={<>Current badge is CLOSE to expire (under 45 days)</>}
					koText={<>Current badge is NOT CLOSE to expire yet</>}
					style={{ label: { fontSize: 12 } }}
				/>

				<CompanySettingsCardLineOk
					ok={companyModel?.isCurrentGenderScoreBadgeExpired()}
					okText={<>Current badge is expired</>}
					koText={<>Current badge is NOT expired yet</>}
					style={{ label: { fontSize: 12 } }}
				/>
				<Space>
					<StaffManagerPanelLink
						label={'Set to 1 YEAR'}
						confirm='Are you sure you want to change the current badge expiration date?'
						onClick={async () => {
							await updateExpirationDate({
								isExpirationOfCurrentBadge: true,
								days: 365,
							});
						}}
					/>
					<StaffManagerPanelLink
						label={'Set to CLOSE TO EXPIRE'}
						confirm='Are you sure you want to change the current badge expiration date?'
						onClick={async () => {
							await updateExpirationDate({
								isExpirationOfCurrentBadge: true,
								days: 10,
							});
						}}
					/>
					<StaffManagerPanelLink
						label={'Set to EXPIRED'}
						confirm='Are you sure you want to change the current badge expiration date?'
						onClick={async () => {
							await updateExpirationDate({
								isExpirationOfCurrentBadge: true,
								days: 0,
							});
						}}
					/>
				</Space>
				<StaffManagerPanelDivider>
					Surveys
				</StaffManagerPanelDivider>
				<Space>
					<StaffManagerPanelLink
						label={'Delete all surveys'}
						confirm='Are you sure you want to delete all the GS surveys WITH ANY STATUS of this company?'
						onClick={async () => {
							await deleteSurveys();
						}}
					/>
					<StaffManagerPanelLink
						label={'Delete all PREVIOUS'}
						confirm='Are you sure you want to delete all the GS surveys with status PREVIOUS of this company?'
						onClick={async () => {
							await deleteSurveys({
								status: 'PREVIOUS',
							});
						}}
					/>
				</Space>
				<Space>
					<StaffManagerPanelLink
						label={'Create 2023 survey'}
						confirm={`This will create a ${GENDERSCORE_LABEL} Survey (PREVIOUS) for 2023`}
						onClick={async () => {
							await createSurvey({
								status: 'PREVIOUS',
								year: 2023,
							});
						}}
					/>
					<StaffManagerPanelLink
						label={'Create 2022 survey'}
						confirm={`This will create a ${GENDERSCORE_LABEL} Survey (PREVIOUS) for 2022`}
						onClick={async () => {
							await createSurvey({
								status: 'PREVIOUS',
								year: 2022,
							});
						}}
					/>
					<StaffManagerPanelLink
						label={'Create 2021 survey'}
						confirm={`This will create a ${GENDERSCORE_LABEL} Survey (PREVIOUS) for 2021`}
						onClick={async () => {
							await createSurvey({
								status: 'PREVIOUS',
								year: 2021,
							});
						}}
					/>
				</Space>
				<Space
					direction='horizontal'
					size={10}
					wrap={false}
					style={{
						width: '100%',
						padding: '0 15px 15px 15px',
					}}
				>
					<CompanyGenderScoreBadge
						company={companyModel}
						version='white'
						size={80}
					/>

					<StaffManagerPanelTabContentWrapper>
						<StaffManagerPanelDivider>
						Set score
						</StaffManagerPanelDivider>

						<Space wrap={true} style={{ maxWidth: 200 }} size={[6, 0]}>
							<StaffManagerPanelCompanyGenderScoreFillRandomButton
								company={companyModel}
							/>
							{GENDERSCORE_LEVEL_VALUES
								.map((level) => (
									<StaffManagerPanelCompanyGenderScoreFillRandomButton
										key={level}
										company={companyModel}
										level={level}
									/>
								))
							}
							{[1, 100]
								.map((score) => (
									<StaffManagerPanelCompanyGenderScoreFillRandomButton
										key={score}
										company={companyModel}
										score={score}
									/>
								))
							}

							<StaffManagerPanelDivider>
						Reset score
							</StaffManagerPanelDivider>

							<StaffManagerPanelCompanyGenderScoreResetButton company={companyModel} />
						</Space>

					</StaffManagerPanelTabContentWrapper>
				</Space>

				<StaffManagerPanelDivider>
				Survey
				</StaffManagerPanelDivider>

				<div>
					<Switch
						size='small'
						checked={isAppSiderVisibleInSurvey}
						onChange={() => setIsAppSiderVisibleInSurvey((p) => !p)}
					/>
					&nbsp;&nbsp;Show left menu
				</div>

				<div>
					<Switch
						size='small'
						checked={isProgressBarVisibleInSurvey}
						onChange={() => setIsProgressBarVisibleInSurvey((p) => !p)}
					/>
					&nbsp;&nbsp;Show progress bar
				</div>

				<div>
					<Switch
						size='small'
						checked={isProgressBarClickableInSurvey}
						onChange={() => setIsProgressBarClickableInSurvey((p) => !p)}
					/>
					&nbsp;&nbsp;Allow navigation from progress bar
				</div>

				<br />

				<div>
					<b>Selected draft:</b> {draftSelected?.survey || NOT_PROVIDED_YET_LABEL}
				</div>
				{draftSelected && (
					<>
						<div>
							<b>Completion:</b>
						&nbsp;
							{countSurveyCompletedQuestions({ surveyConf, answers, withOptional: false })}
						&nbsp;/&nbsp;
							{countSurveyMandatoryQuestions({ surveyConf, answers })}
						&nbsp;(mandatory),&nbsp;
							{countSurveyCompletedQuestions({ surveyConf, answers, withOptional: true })}
						&nbsp;/&nbsp;
							{countSurveyQuestions({ surveyConf })}
						&nbsp;(all - optional included)
						</div>

						<StaffManagerPanelLink
							label={'Delete selected draft'}
							confirm='Are you sure you want to delete the selected draft? You will be taken to the "Start the GenderScore" page.'
							onClick={async () => {
								await createOrUpdateSurveyDraft({
									data: { isActive: false, withSlack: false },
									withDispatch: true,
								});
							}}
						/>

						<StaffManagerPanelLink
							label={'Reset all questions'}
							confirm='Are you sure you want to erase all your current answers to the survey questions?'
							onClick={async () => {
								const newAnswers = walkSectionQuestionForAnswers({
									sections: surveyConf?.sections,
									questionAnswerMapper: ({ question }) => ({ [question?.questionId]: null }),
								});

								await saveAnswers({
									answers: newAnswers,
									withSlack: false,
								});
							}}
						/>

						<StaffManagerPanelLink
							label={'Fill random questions'}
							confirm='Are you sure you want to randomly fill the survey questions?'
							onClick={async () => {
								const newAnswers = walkSectionQuestionForAnswers({
									sections: surveyConf?.sections,
									questionAnswerMapper: ({ question }) => {
										const value = Math.random() > 0.5
											? randomyFakeQuestionAnswer({ question })
											: null;
										return { [question?.questionId]: value };
									},
								});

								LocalDebug.logInfo({ className }, { newAnswers });
								await saveAnswers({
									answers: newAnswers,
									withSlack: false,
								});
							}}
						/>

						<StaffManagerPanelLink
							label={'Fill all questions but the last'}
							confirm='Are you sure you want to fill all the survey questions but the last one?'
							onClick={async () => {
								const newAnswers = walkSectionQuestionForAnswers({
									sections: surveyConf?.sections,
									questionAnswerMapper: ({
										section, sIndex, sArr, question, qIndex, qArr,
									}) => {
										LocalDebug.logInfo({ className }, {
											section, sIndex, sArr, question, qIndex, qArr,
										});
										if (!question) return {};
										if ((
											sIndex === ((sArr?.length || 0) - 1)
												&& qIndex === ((qArr?.length || 0) - 1))
										) {
											return { [question?.questionId]: null };
										}
										return { [question?.questionId]: randomyFakeQuestionAnswer({ question }) };
									},
								});

								LocalDebug.logInfo({ className }, { newAnswers });
								await saveAnswers({ answers: newAnswers, withSlack: false });
							}}
						/>

						<StaffManagerPanelLink
							label={'Fill all questions but one'}
							confirm='Are you sure you want to fill all the survey questions but one?'
							onClick={async () => {
								const randUnansweredQuestionNotLast = Math.floor(
									Math.random() * (
										(surveyConf?.sections
											?.map((section) => section?.questions?.length)
											?.reduce(sumReducer, 0) || 0)
										- 1),
								);
								LocalDebug.logInfo({ className }, { randUnansweredQuestionNotLast });

								let qTotalIndex = 0;
								const newAnswers = walkSectionQuestionForAnswers({
									sections: surveyConf?.sections,
									questionAnswerMapper: ({
										section, sIndex, sArr, question, qIndex, qArr,
									}) => {
										LocalDebug.logInfo({ className }, {
											sIndex, qIndex, qTotalIndex, randUnansweredQuestionNotLast,
										});
										if (!question) return {};
										if (qTotalIndex === randUnansweredQuestionNotLast) {
											qTotalIndex += 1;
											return { [question?.questionId]: null };
										}
										qTotalIndex += 1;
										return { [question?.questionId]: randomyFakeQuestionAnswer({ question }) };
									},
								});

								LocalDebug.logInfo({ className }, { newAnswers });
								await saveAnswers({ answers: newAnswers, withSlack: false });
							}}
						/>

						<StaffManagerPanelLink
							label={'Fill all questions'}
							confirm='Are you sure you want to fill all the survey questions?'
							onClick={async () => {
								const newAnswers = walkSectionQuestionForAnswers({
									sections: surveyConf?.sections,
									questionAnswerMapper: ({ question }) => {
										if (!question) return {};
										return { [question?.questionId]: randomyFakeQuestionAnswer({ question }) };
									},
								});

								LocalDebug.logInfo({ className }, { newAnswers });
								await saveAnswers({ answers: newAnswers, withSlack: false });
							}}
						/>
					</>
				)
				}
				<StaffManagerPanelDivider>
				Surveys
				</StaffManagerPanelDivider>
				<div>
					<b>Surveys:</b> {new CompanyModel(companySelected).getGenderScoreSurveys()?.length || 'None'}
				</div>
				<div>
					<b>Drafts:</b> {new CompanyModel(companySelected).getGenderScoreSurveyDrafts()?.length || 'None'}
				</div>

			</StaffManagerPanelTabContentWrapper>

			{/* Modal window for the GenderScore settings form */}
			<ModalContainerBase
				open={openGenderScoreSettingsModal}
				setOpen={setOpenGenderScoreSettingsModal}
				width={600}
			>
				<CompanySettingsUMSForm
					company={companyModel}
					onSuccess={() => setOpenGenderScoreSettingsModal(false)}
				>
					<Box style={{ background: theme.color.bgGrey, marginBottom: 12 }}>
						<CompanyStub company={companyModel} />
					</Box>
				</CompanySettingsUMSForm>
			</ModalContainerBase>
		</>
	);
};

export default StaffManagerGenderScoreTab;
