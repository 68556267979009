import { useState } from 'react';
import { FaArrowCircleRight, FaEnvelope } from 'react-icons/fa';
import Link from '../../../app/Link';
import { writeLog } from '../../../../utils/common';
import { addFeedback } from '../../../../actions/feedback';
import FeedbackPopover from './FeedbackPopover';

const FeedbackBtnBuilder = (
	{
		button,
		initialValues = {},
		...props
	},
) => {
	const [openPopover, setOpenPopover] = useState(false);

	const handleSubmit = async (values) => {
		writeLog('Send a feedback', { category: values?.payload?.category });
		const valuesToDb = {
			...values,
		};
		await addFeedback(valuesToDb);
		setOpenPopover(false);
	};

	const addBtn = <Link
		icon={props?.icon || <FaEnvelope style={{ marginBottom: -2 }}/>}
		onClick={() => setOpenPopover(true)}
		{...props}
		style={{ ...props?.style }}
	>
		{<>{props?.title || 'Send a feedback'} <FaArrowCircleRight style={{ marginBottom: -2, marginLeft: 2 }}/></>}
	</Link>;

	return (
		<>
			{button || addBtn}
			<FeedbackPopover
				open={openPopover}
				initialValues={initialValues}
				onClose={() => setOpenPopover(false)}
				onSubmit={handleSubmit}
				onReset={() => setOpenPopover(false)}
				{...props}
			/>
		</>
	);
};

export default FeedbackBtnBuilder;
