import { useMemo } from 'react';
import { useTheme } from '@emotion/react';
import {
	BiCheck, BiPlus,
} from 'react-icons/bi';

export const GenderScoreActionCategoryIcon = ({
	size = 24,
	isCompleted = false,
	children,
}) => {
	const theme = useTheme();

	const memoizedContent = useMemo(() => {
		let color = '#ccc';

		if (isCompleted) {
			color = theme.color.fitBlueElectric;
		}

		color = theme.color.fitBlueElectric;

		let icon = isCompleted
			? <BiCheck style={{ }}/>
			: <BiPlus style={{ transform: 'rotate(45deg)' }}/>;

		icon = <BiCheck style={{ }} />;

		return (
			<div
				style={{
					position: 'relative',
					fontSize: size / 2,
					width: size,
					height: size,
					borderRadius: size,
					border: `1px solid ${color}`,
					color,
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
				}}
			>
				{children || icon}
			</div>
		);
	}, [isCompleted]);

	return memoizedContent;
};

export default GenderScoreActionCategoryIcon;
