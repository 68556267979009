/** @jsxImportSource @emotion/react */

import { useTheme } from '@emotion/react';

const classes = (width = 680) => ({
	container: {
		position: 'relative',
	},
	layoutSizer: {
		margin: '0 48px',
		width: `${width}px`,
		maxWidth: 'calc(100vw - 2 * 48px)',
	},
	layoutFlexvc: {
		flexDirection: 'column',
		alignItems: 'flex-start',
		display: 'flex',
		justifyContent: 'space-between',
	},
});
const Wrapper = ({ style, children, width = 680 }) => {
	const theme = useTheme();
	const styles = classes(theme, width);
	return (
		<div
			className="layout-flexhc flexRow justifyCenter"
			css={{ ...styles.container, ...style }}
		>
			<div
				className="layout-sizer"
				css={{ ...styles.layoutSizer, ...style?.layoutSizer }}
			>
				<div className="layout-flexvc flexColumn wrapper-content">
					{children}
				</div>
			</div>
		</div>
	);
};

export default Wrapper;
