import { getCareerSeekingOption, NOT_PROVIDED_YET_LABEL, userTagStyle } from '../../../constants/constant';
import { Tag } from 'antd';

const UserCareerSeeking = (
	{
		user,
		size = 'medium',
		full = false,
		notProvided = NOT_PROVIDED_YET_LABEL,
		...props
	},
) => {
	const value = user?.career?.seeking;
	if (!value) return notProvided;

	const option = getCareerSeekingOption(value);
	if (!option) return notProvided;

	const tagStyle = {
		...userTagStyle(size),
		...option?.tagStyle,
		...props?.style,
	};
	return <Tag
		{...option?.tagStyle?.tagColor ? { color: option?.tagStyle?.tagColor } : {}}
		style={{ ...tagStyle }}
	>
		{option.icon} <b>{option.label}</b>
	</Tag>;
};

export default UserCareerSeeking;
