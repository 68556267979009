import { useTheme } from '@emotion/react';
import PageLayout from '../../components/app/layout/PageLayout';
import StaffGSCompanyActionPlanListMenu from '../../menu/staff/genderscore/StaffGSCompanyActionPlanListMenu';
import StaffCompanyActionPlanList from '../../components/company/actionPlan/StaffCompanyActionPlanList';

const StaffCompanyActionPlanListPage = () => {
	const className = 'StaffCompanyActionPlanListPage';

	const theme = useTheme();

	return (
		<PageLayout
			MenuClass={StaffGSCompanyActionPlanListMenu}
		>
			<StaffCompanyActionPlanList />
		</PageLayout>
	);
};

export default StaffCompanyActionPlanListPage;
