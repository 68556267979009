import { PERMS_GRANT_STAFF_CLIENT_NO_EDITOR } from '../menu.utils';
import MenuBase from '../MenuBase';
import { TeamOutlined } from '@ant-design/icons';
import ConfigureUsersPage from '../../containers/configure/ConfigureUsersPage';

export const ConfigureUsersIcon = TeamOutlined;

class ConfigureUsersMenu extends MenuBase {
	static props = {
		name: 'configure-users',
		path: '/configure/users',
		aliases: ['^/access$'],
		label: 'Users',
		icon: <ConfigureUsersIcon />,
		iconLegacy: '🔑',
		Component: ConfigureUsersPage,
		perms: PERMS_GRANT_STAFF_CLIENT_NO_EDITOR,
	};

	constructor() {
		super(ConfigureUsersMenu.props);
	}
}

export default ConfigureUsersMenu;
