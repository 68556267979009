const SESSION = 'session';
const DISPLAY = 'display';
const CLICK = 'click';
const POPIN = 'popin';
const TALENT_VIEW = 'talent-view';
const TALENT_LINK = 'talent-link';
const TALENT_RESUME = 'talent-resume';
const HIDE = 'hide';
const SHORTLIST = 'shortlist';
const CONTACT = 'contact';
const SOURCE = 'source';
const ARCHIVE = 'archive';
const TEMPLATE = 'template';
const API_RESULT = 'api-result';
const INTERNAL_LINK = 'internal-link';
const EXTERNAL_LINK = 'external-link';
const OPEN_DRAWER = 'open-drawer';
const HOVER = 'hover';
export const COMPANY_RECRUITER_LOG = {
	SESSION,
	DISPLAY,
	CLICK,
	POPIN,
	TALENT_VIEW,
	TALENT_LINK,
	TALENT_RESUME,
	HIDE,
	SHORTLIST,
	CONTACT,
	SOURCE,
	ARCHIVE,
	TEMPLATE,
	API_RESULT,
	INTERNAL_LINK,
	EXTERNAL_LINK,
	OPEN_DRAWER,
	HOVER,
};

export const COMPANY_RECRUITER_LOG_ACTIONS = [
	{
		label: 'Session', title: 'Sessions', value: SESSION, color: 'gold', icon: '🆕',
	},
	{
		label: 'Page View', title: 'Page views', value: DISPLAY, color: '#cccccc', icon: '📃',
	},
	{
		label: 'Click', title: 'Click button or link', value: CLICK, color: 'lilac', icon: '💥',
	},
	{
		label: 'Talent view', title: 'Talent Views', value: TALENT_VIEW, color: 'cyan', icon: '👀',
	},
	{
		label: 'Talent link', title: 'Talent Links clicked', value: TALENT_LINK, color: '#108ee9', icon: '🔗',
	},
	{
		label: 'Talent resume', title: 'Talent Resume Link clicked', value: TALENT_RESUME, color: '#108ee9', icon: '📄',
	},
	{
		label: 'Hide', title: 'Talent hide actions', value: HIDE, color: 'purple', icon: '🚫',
	},
	{
		label: 'ShortList', title: 'Saved actions', value: SHORTLIST, color: 'green', icon: '📌',
	},
	{
		label: 'Contact', title: 'Contact actions', value: CONTACT, color: 'geekblue', icon: '💌',
	},
	{
		label: 'Source', title: 'Source actions', value: SOURCE, color: 'volcano', icon: '💎',
	},
	{
		label: 'Archive', title: 'Archive actions', value: ARCHIVE, color: 'magenta', icon: '📂',
	},
	{
		label: 'Template', title: 'Template creations', value: TEMPLATE, color: 'lime', icon: '💡',
	},
	{
		label: 'API result', title: 'API result', value: API_RESULT, color: 'geekblue', icon: '🔍',
	},
	{
		label: 'Internal link', title: 'Internal Link clicked', value: INTERNAL_LINK, color: '#108ee9', icon: '🔗',
	},
	{
		label: 'External link', title: 'External Link clicked', value: EXTERNAL_LINK, color: '#108ee9', icon: '🔗',
	},
	{
		label: 'Open drawer', title: 'Open drawer', value: OPEN_DRAWER, color: 'blue', icon: '⬅️',
	},
	{
		label: 'Hover', title: 'Hover', value: HOVER, color: 'orange', icon: '💬',
	},
];
