import { ENGLISH_LANG } from '../../../../constants/constant';
import { useEffect, useState } from 'react';
import CompanyService from '../../../../services/company';
import CompanyAutoCompleteEditor from '../shared/CompanyAutoCompleteEditor';

const CompanyHeadquartersEditor = (
	{
		company,
		lang = ENGLISH_LANG,
		setEditorOpen,
	},
) => {
	const className = 'CompanyHeadquartersEditor';

	const title = 'Headquarters';
	const field = 'headquarters';

	const [fullOptions, setFullOptions] = useState([]);
	const [options, setOptions] = useState([]);

	useEffect(() => {
		const findOptions = async () => {
			const headquarters = await CompanyService.getValuesByField(field);
			setFullOptions(headquarters.data.items
				?.filter((item) => item?.trim?.())
				?.map((item) => ({ label: item, value: item })));
			setOptions([...fullOptions]);
		};
		findOptions();
	}, []);

	const onSearch = (search) => {
		setOptions(fullOptions
			?.filter((item) => item?.label?.toLowerCase()?.indexOf(search?.toLowerCase()) >= 0));
	};

	return (
		<CompanyAutoCompleteEditor
			company={company}
			lang={lang}
			title={<b>{title}</b>}
			details={<span>
				Where your company is based
			</span>}
			field={field}
			options={options}
			onSearch={onSearch}
			setEditorOpen={setEditorOpen}
			isTranslatable={false}
		/>
	);
};

export default CompanyHeadquartersEditor;
