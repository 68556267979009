const { default: JOB_SEARCH_JOB_REMOTE } = require('../../../user/property/job-search-job-remote');
const { asCategoryTag, asCategoryTagOption } = require('../tags.constants.utils');

const TAG_CATEGORY_JOB_SEARCH_JOB_REMOTE_SLUG = 'job-search-job-remote';

const asJobSearchJobRemoteTag = (slug) => asCategoryTag(TAG_CATEGORY_JOB_SEARCH_JOB_REMOTE_SLUG, slug);

const asJobSearchJobRemoteTagOption = (value, label, group) => asCategoryTagOption(TAG_CATEGORY_JOB_SEARCH_JOB_REMOTE_SLUG, value, label, group);

const options = JOB_SEARCH_JOB_REMOTE
	.options
	.map((option) => asJobSearchJobRemoteTagOption(option.value, option.label, option.group));

export default {
	category: TAG_CATEGORY_JOB_SEARCH_JOB_REMOTE_SLUG,
	TAG_CATEGORY_JOB_SEARCH_JOB_REMOTE_SLUG,
	...JOB_SEARCH_JOB_REMOTE,
	options,
	asTag: asJobSearchJobRemoteTag,
	asTagOption: asJobSearchJobRemoteTagOption,
	asJobSearchJobRemoteTag,
	asJobSearchJobRemoteTagOption,
};
