import { useTheme } from '@emotion/react';
import { Space, Tag } from 'antd';
import {
	NOT_PROVIDED_YET_LABEL,
	USER_TAG_CAREER_LANGUAGE, userTagStyle,
} from '../../../constants/constant';
import { arrayWrap } from '../../../utils/common';
import { useSelector } from 'react-redux';
import { getTagsUsers } from '../../../reducers/app';
import { getPrettifiedLanguageLabel } from '../../../constants/languages';

const UserLanguage = (
	{
		user,
		mode = 'tags',
		withGroups = false,
		forceValue = null,
		size = 'medium',
		full = false,
		notProvided = NOT_PROVIDED_YET_LABEL,
		...props
	},
) => {
	const allTags = useSelector(getTagsUsers);
	const languageOptions = allTags.find(
		(tag) => tag.category === USER_TAG_CAREER_LANGUAGE?.value,
	)?.items || [];
	const getLanguageLabel = (tag) => {
		const language = languageOptions?.find((v) => v.value === tag)?.value;
		return getPrettifiedLanguageLabel(language || tag);
	};

	const value = arrayWrap(forceValue || user?.career?.language);
	if (!(value?.length > 0)) return notProvided;

	const languages = [...new Set(value)];

	const tagStyle = {
		...userTagStyle(size),
		...props?.style,
	};
	const uniqueLanguages = [];
	const tags = languages.reduce((acc, tag, index) => {
		const label = getLanguageLabel(tag);
		if (!label) {
			return acc;
		}
		if (uniqueLanguages.includes(label)) {
			return acc;
		}
		uniqueLanguages.push(label);

		return [...acc, <Tag
			color='blue'
			key={index}
			style={tagStyle}
		>
			{label}

		</Tag>];
	}, []);

	return tags?.length
		? <Space
			direction='horizontal'
			wrap={true}
			size={[2, 1]}
		>
			{tags}
		</Space>
		: notProvided;
};

export default UserLanguage;
