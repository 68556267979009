import { Typography } from 'antd';
import { useTheme } from '@emotion/react';
import { useAuthContext } from '../../../hooks/useAuthContext';
import UserCoachedTag from './UserCoachedTag';
import UserResumeTag from './UserResumeTag';
import UserName from '../widgets/UserName';
import JsonPopoverLink from '../../app/debug/JsonPopoverLink';

const UserStubName = (
	{
		user,
		forceShow = false,
		forceHide = false,
		withCoachedTag = true,
		withResumeTag = true,
		withJSONPopover = false,
		...props
	},
) => {
	const theme = useTheme();
	const { isStaff, isStaffView, currentUser } = useAuthContext();

	return (
		user
		&& <Typography style={{
			color: theme.color.black,
			lineHeight: 1.3,
			fontWeight: 'bold',
			fontSize: 14,
			...props?.style,
		}}>
			<UserName user={user} forceShow={forceShow} forceHide={forceHide}/>
			{withCoachedTag && <UserCoachedTag user={user} style={{ marginLeft: 2 }}/>}
			{withResumeTag && <UserResumeTag user={user} style={{ marginLeft: 2 }}/>}
			{isStaff && isStaffView && withJSONPopover && (
				<JsonPopoverLink
					data={user}
					style={{
						marginLeft: 2,
						fontSize: 12,
						...props?.style?.fontSize
							? { fontSize: props?.style?.fontSize * 3 / 4 }
							: {},
					}}
				/>
			)}
		</Typography>
	);
};

export default UserStubName;
