import UserModel from '../../../../../models/UserModel';
import UserGridCardValueList from './UserGridCardValueList';
import UserGridCardLabelValue from './UserGridCardLabelValue';
import { NOT_PROVIDED_YET_LABEL } from '../../../../../constants/constant';

const UserGridCardJobSearchEmploymentVisa = (
	{
		user,
		label = <>Needs visa...</>,
		placement = 'right',
		defaultContainer = 'div',
		withSeparator = false,
		itemStyle,
		notProvided = NOT_PROVIDED_YET_LABEL,
		...props
	},
) => {
	const className = 'UserGridCardJobSearchEmploymentVisa';

	const userModel = new UserModel(user);

	const items = userModel?.hasJobSearchEmploymentVisas()
		? userModel?.getJobSearchEmploymentVisas()
		: [notProvided];

	return (
		<UserGridCardLabelValue
			label={label}
			style={{ ...props?.style }}
		>
			<UserGridCardValueList
				items={items}
				placement={placement}
				defaultContainer={defaultContainer}
				withSeparator={withSeparator}
				itemStyle={itemStyle}
			/>
		</UserGridCardLabelValue>
	);
};

export default UserGridCardJobSearchEmploymentVisa;
