import { useTheme } from '@emotion/react';
import { Space } from 'antd';
import { EMAIL_TEMPLATES } from '../../../constants/constant';
import { FaFolder } from 'react-icons/fa';
import { mixColors } from '../../../utils/common';

const MessageTemplateCategoryTag = (
	{
		category,
		withTagStyle = true,
		withIcon = true,
		...props
	},
) => {
	const theme = useTheme();

	const categoryOption = EMAIL_TEMPLATES.findCategoryOption(category);
	return category && categoryOption
		? (
			<Space
				wrap={false}
				size={5}
				style={{
					...withTagStyle
						? {
							borderRadius: 5,
							background: mixColors(theme.color.white, categoryOption.color || theme.color.grey, 10),
							// background: theme.color.fitBgGrey,
							// border: `1px solid ${categoryOption.color || theme.color.lightgrey}`,
							// border: `1px solid ${theme.color.lightgrey}`,
							padding: '2px 8px',
						}
						: {},
					color: theme.color.darkgrey,
					fontSize: 10,
					whiteSpace: 'nowrap',
				}}>
				{withIcon
					&& <FaFolder style={{ marginBottom: -1, color: (categoryOption.color || theme.color.darkgrey) }}/>}
				{categoryOption?.label}
			</Space>
		)
		: null;
};

export default MessageTemplateCategoryTag;
