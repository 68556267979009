import CompanySettingsFormBase from './CompanySettingsFormBase';
import { useState } from 'react';
import FormBoxTitle from '../../app/form/FormBoxTitle';
import FormItem from '../form/FormItem';
import { Form } from 'antd';
import FormSubmitButton from '../../app/form/FormSubmitButton';
import BlockStaff from '../../app/blocker/BlockStaff';
import CompanyModel from '../../../models/CompanyModel';
import CompanyPlanFormItem from '../companyPlan/CompanyPlanFormItem';
import CompanyPlanExtraTagsFormItem from '../companyPlan/CompanyPlanExtraTagsFormItem';

const CompanySettingsCompanyPlanForm = (
	{
		company,
		onSuccess,
		labelCol = { span: 0 },
		wrapperCol = { span: 24 },
		...props
	},
) => {
	const className = 'CompanySettingsCompanyPlanForm';

	const [form] = Form.useForm();
	const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
	const [companyInitialValues, setCompanyInitialValues] = useState({
		...company,
		companyPlan: {
			...company?.companyPlan || {},
			planValue: new CompanyModel(company).getCompanyPlanValue(),
		},
	});

	return (
		<BlockStaff
			withBorder={false}
			tagStyle={{
				top: 5,
				left: 0,
				opacity: 1,
			}}
		>
			<FormBoxTitle
				title={<>✍️ Plan</>}
				details={<>
					Select the plan this client should be on
				</>}
			/>

			<CompanySettingsFormBase
				form={form}
				formName='company-settings-plan-form'
				company={companyInitialValues}
				labelCol={labelCol}
				wrapperCol={wrapperCol}
				layout='horizontal'
				setIsSubmitDisabled={setIsSubmitDisabled}
				onSuccess={onSuccess}
				hiddenValues={{ forceRecompute: false }}
			>

				<CompanyPlanFormItem />

				<CompanyPlanExtraTagsFormItem />

				<FormItem>
					<FormSubmitButton disabled={isSubmitDisabled}>
						Save plan
					</FormSubmitButton>
				</FormItem>

			</CompanySettingsFormBase>

		</BlockStaff>
	);
};

export default CompanySettingsCompanyPlanForm;
