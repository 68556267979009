import { Button, Tooltip } from 'antd';
import { FaExpandAlt } from 'react-icons/fa';
import UserModel from '../../../models/UserModel';
import { useAuthContext } from '../../../hooks/useAuthContext';
import { useLocation, useNavigate } from 'react-router-dom';
import Link from '../../app/Link';

const UserFullPageIconButton = (
	{
		user,
		size = 48,
		tooltip = 'Open profile in full page',
		tooltipPlacement = 'top',
		onClick,
		...props
	},
) => {
	const className = 'UserFullPageButton';

	const { currentUser } = useAuthContext();
	const location = useLocation();

	if (!user) {
		return null;
	}

	const path = `/user/${new UserModel(user).getPublicSlug(currentUser)}`;

	// Not showing if currently on the full page
	if (path === location?.pathname) {
		return null;
	}

	return (
		<Tooltip
			title={tooltip}
			placement={tooltipPlacement}
		>
			<Link
				to={path}
				onClick={onClick}
			>
				<Button
					className='icon-circle-button'
					// onClick={() => navigate(path)}
					type='default'
					style={{
						fontSize: 16,
						width: size,
						height: size,
						borderRadius: size,
						padding: 0,
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
						...props?.style,
					}}
				>
					<FaExpandAlt
						style={{
							...props?.style?.icon,
						}}
					/>
				</Button>
			</Link>
		</Tooltip>
	);
};

export default UserFullPageIconButton;
