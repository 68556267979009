import { NOT_PROVIDED_YET_LABEL } from '../../../constants/constant';
import UserProfileTag from './UserProfileTag';
import { Space } from 'antd';

const UserTagsList = (
	{
		values,
		notProvided = NOT_PROVIDED_YET_LABEL,
		size = 'medium',
		user,
	},
) => {
	const tags = values?.map((text, index) => <UserProfileTag
		key={index}
		size={size}
		user={user}
	>
		{text}
	</UserProfileTag>);

	return tags?.length
		? <Space size={4} wrap={true}>{tags}</Space>
		: notProvided;
};

export default UserTagsList;
