const GenderScoreLayoutGrid = ({
	rows,
	columns,
	rowGap = 20,
	columnGap = 20,
	marginBottom = 20,
	children,
	...props
}) => {
	return (
		<div
			style={{
				width: '100%',
				display: 'grid',
				columnGap,
				rowGap,
				marginBottom,
				gridTemplateRows: rows,
				gridTemplateColumns: columns,
				...props?.style,
			}}
		>
			{children}
		</div>
	);
};

export default GenderScoreLayoutGrid;
