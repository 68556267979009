import { useTheme } from '@emotion/react';
import { getCompanies, getCompanySelected } from '../../../reducers/app';
import { useAuthContext } from '../../../hooks/useAuthContext';
import {
	documentId, getPermissionOptionsByUser, inArray, requiredRule, requiredTrimRule,
} from '../../../utils/common';
import {
	Button,
	Checkbox,
	Col,
	Divider,
	Form, Input, Popover, Radio, Row, Select, Space,
} from 'antd';
import FormItem, { FormItemContainer } from '../../app/form/FormItem';
import Box from '../../app/box/Box';
import FormCompanySelect from '../../app/form/FormCompanySelect';
import { useContext, useEffect, useState } from 'react';
import { aclRoles } from '../../../constants/constant';
import { useSelector } from 'react-redux';
import { ACL_ROLE_UMS_ADMIN, ACL_ROLE_UMS_EMPLOYEE, ACL_ROLE_UMS_MANAGER } from '../../../constants/acls';
import { UMSInviteContext } from '../UMSInviteProvider';
import { BiChevronLeft, BiEnvelope } from 'react-icons/bi';
import { InfoCircleFilled } from '@ant-design/icons';
import { LocalDebug } from '../../../utils/LocalDebug';
import UMSUserStub from './UMSUserStub';

const UMSInviteConfirmForm = ({
	// form,
	initialValues = {},
	onCompanyChange,
	onSubmit,
	children,
}) => {
	const className = 'UMSInviteConfirmForm';

	const theme = useTheme();
	const companySelected = useSelector(getCompanySelected);
	const companies = useSelector(getCompanies);

	const {
		currentUser, isStaff, isUMSAdmin, isUMSManager, isStaffView, companyIdsUser,
	} = useAuthContext();

	const {
		shouldNotifyInvitee,
		setShouldNotifyInvitee,
		setInviteFormStep,
		managers,
	} = useContext(UMSInviteContext);

	const [form] = Form.useForm();
	// local state
	const [roleOptions, setRoleOptions] = useState([]);
	const [roleSelected, setRoleSelected] = useState(null);
	const isExist = initialValues?.isExist;

	useEffect(() => {
		const options = getPermissionOptionsByUser(aclRoles, currentUser);
		setRoleOptions(options);
	}, [currentUser]);

	useEffect(() => {
		if (!isStaff) {
			onCompanyChange(companyIdsUser?.[0]);
		}
	}, [companyIdsUser, isStaff, onCompanyChange]);

	const getRoleRadioLabel = ({ role, label }) => {
		const { popoverTitle, popoverContent } = {
			[ACL_ROLE_UMS_ADMIN]: {
				popoverTitle: <b>Admin roles</b>,
				popoverContent: <>
					<div>• <b>Admin</b> roles are able to <b>manage all roles</b> for the company's teammates.</div>
					<div>• They are not supposed to fill a survey.</div>
					<div>• When all teammates have filled their survey, <b>Admins</b> can access the <b>company-wide results.</b></div>
					<div>• Admins <b>cannot access</b> Managers and Employees surveys or results.</div>
				</>,
			},
			[ACL_ROLE_UMS_MANAGER]: {
				popoverTitle: <b>Manager roles</b>,
				popoverContent: <>
					<div>• <b>Manager</b> roles are able to <b>invite their employees.</b></div>
					<div>• They are <b>required to fill a survey.</b></div>
					<div>• When every manager's <b>Employees</b> have filled their own survey, their <b>Manager</b> can access their <b>personal results,</b> which anonymously aggregate their assessment and those of their employees.</div>
					<div>• <b>Managers</b> cannot access the <b>company-wide results,</b> nor other teammates <b>surveys and results.</b></div>
				</>,
			},
			[ACL_ROLE_UMS_EMPLOYEE]: {
				popoverTitle: <b>Employee roles</b>,
				popoverContent: <>
					<div>• <b>Employee</b> roles are <b>required to fill a survey,</b> but will not receive <b>any results.</b></div>
					<div>• They <b>cannot access</b> other Managers and Employees surveys or results.</div>
				</>,
			},
		}[role] || {};

		if (isExist && (
			initialValues
				?.userRoles
				?.filter((ur) => (
					ur.isActive !== false
					&& ur.documentId === documentId(companySelected)
					&& ur.userId === documentId(initialValues?.user)
					&& ur?.role?.label === role
				))
				?.length || 0) > 0
		) {
			return {
				label: (
					<Space direction='vertical' size={0}>
						<div
							style={{
								color: theme.color.grey,
							}}
						>
							<b>{label}</b> <InfoCircleFilled />
						</div>
						<div
							style={{
								fontSize: 9,
								color: theme.color.accent,
								marginBottom: -4,
							}}>
							This user already has this role
						</div>
					</Space>
				),
				popoverTitle,
				popoverContent,
				disabled: true,
			};
		}

		if (!(managers?.length > 0) && role === ACL_ROLE_UMS_EMPLOYEE) {
			return {
				label: (
					<Space direction='vertical' size={0}>
						<div
							style={{
								color: theme.color.grey,
							}}
						>
							<b>{label}</b> <InfoCircleFilled />
						</div>
						<div
							style={{
								fontSize: 9,
								color: theme.color.accent,
								marginBottom: -4,
							}}>
							Role not available: invite managers first!
						</div>
					</Space>
				),
				popoverTitle,
				popoverContent,
				disabled: true,
			};
		}
		return {
			label: <><b>{label}</b> <InfoCircleFilled /></>,
			popoverTitle,
			popoverContent,
		};
	};

	return (<>
		<Form
			form={form}
			name="ums-invite-user-confirm"
			initialValues={{ ...initialValues }}
			onValuesChange={(values) => {
				LocalDebug.logInfo({ className, method: 'onValuesChange' }, values);
				if (values.roleId) setRoleSelected(values.roleId);
			}}
			onFinish={async (values) => onSubmit({
				...isUMSManager
					? {
						roleId: ACL_ROLE_UMS_EMPLOYEE,
						managerId: documentId(currentUser),
					}
					: {},
				...values,
				withEmail: shouldNotifyInvitee,
			})}
		>
			<FormItemContainer>

				{!isExist && (
					<>
						<FormItem
							name="email"
							label="Teammate's email"
							defaultContainer="div"
							help={(
								<>This user is not yet registered on 50inTech: an account will be created.</>
							)}
						>
							<Input disabled={true} />
						</FormItem>

						<Row gutter={[12, 0]}>
							<Col span={12}>
								<FormItem
									name="firstName"
									label="First Name"
									defaultContainer="div"
									rules={[requiredTrimRule]}
								/>
							</Col>
							<Col span={12}>
								<FormItem
									name="lastName"
									label="Last Name"
									defaultContainer="div"
									rules={[requiredTrimRule]}
								/>
							</Col>
						</Row>
					</>
				)}

				{isExist && (
					<FormItem
						label="User"
						name="userId"
						defaultContainer="div"
					>
						<Box style={{ width: '100%' }}>
							<UMSUserStub user={initialValues?.user} withDropdown={false} />
						</Box>
					</FormItem>
				)}

				{(isStaff && !companySelected)
					&& (
						<FormItem
							label="Company"
							name="companyId"
							defaultContainer="div"
							rules={[
								{
									validator: async (_, value) => {
										if (inArray(roleSelected, roleOptions.filter((role) => role?.isCompanyRequired).map((r) => r.value)) && !value) throw new Error('This field is required');
										return true;
									},
								},
							]}
						>
							<FormCompanySelect
								options={companies.map((c) => ({ value: documentId(c), label: c.name, item: c }))}
								mode="single"
								placeholder="Select a company"
								onChange={(value) => onCompanyChange(value)}
							/>
						</FormItem>
					)
				}

				{(isStaffView || isUMSAdmin) && (
					<>
						<div style={{ padding: '16px 0' }}>
							<Divider style={{ margin: 0, padding: 0 }}/>
						</div>
						<FormItem
							name="roleId"
							label='Select a role'
							defaultContainer="div"
							rules={[requiredRule]}
						>
							<Radio.Group style={{ marginTop: 6 }}>
								<Space direction='vertical'>
									{[
										...isUMSAdmin
											? [
												{
													value: ACL_ROLE_UMS_ADMIN,
													...getRoleRadioLabel({ role: ACL_ROLE_UMS_ADMIN, label: 'Admin' }),
												},
												{
													value: ACL_ROLE_UMS_MANAGER,
													...getRoleRadioLabel({ role: ACL_ROLE_UMS_MANAGER, label: 'Manager' }),
												},
												{
													value: ACL_ROLE_UMS_EMPLOYEE,
													...getRoleRadioLabel({ role: ACL_ROLE_UMS_EMPLOYEE, label: 'Employee' }),
												},
											]
											: [],
										...isUMSManager
											? [{
												value: ACL_ROLE_UMS_EMPLOYEE,
												...getRoleRadioLabel({ role: ACL_ROLE_UMS_EMPLOYEE, label: 'Employee' }),
											}]
											: [],
									]
										.map((role, index) => (
											<Radio
												key={index}
												value={role?.value}
												disabled={role?.disabled}
											>
												<Popover
													placement='right'
													title={role?.popoverTitle || 'Role'}
													content={(
														<Space
															direction='vertical'
															size={4}
															style={{
																maxWidth: 440,
																fontSize: 12,
																lineHeight: 1.3,
															}}
														>
															{role?.popoverContent || 'Details'}
														</Space>
													)}
												>
													{role?.label}
												</Popover>
											</Radio>
										))
									}
								</Space>
							</Radio.Group>

						</FormItem>

						{roleSelected === ACL_ROLE_UMS_EMPLOYEE && (
							<FormItem
								name="managerId"
								label='Select a manager'
								defaultContainer="div"
								rules={[requiredRule]}
							>
								<Select>
									{managers.map((u) => (
										<Select.Option
											key={documentId(u)}
											value={documentId(u)}
											label={`${u.firstName} ${u.lastName}`}
										>
											<Space direction="horizontal">
												<UMSUserStub
													user={u}
													// avatarSize={24}
												/>
											</Space>
										</Select.Option>
									))}
								</Select>
							</FormItem>
						)}

					</>
				)}

				{isStaffView && (
					<>
						<div style={{ padding: '16px 0' }}>
							<Divider style={{ margin: 0, padding: 0 }}/>
						</div>
						<FormItem
							name='withEmail'
							label='Notification preference'
							defaultContainer="div"
							help={(
								<>The email will present the subject of the survey, and explain how to connect to the platform.</>
							)}
						>
							<Checkbox
								checked={shouldNotifyInvitee}
								onChange={(e) => {
									setShouldNotifyInvitee(e?.target?.checked);
								}}
							>
							Send information email right away
							</Checkbox>
						</FormItem>
					</>
				)}

			</FormItemContainer>

			{children}

			<div style={{ padding: '16px 0 0' }}>
				<Divider style={{ margin: 0, padding: 0 }}/>
			</div>

			<div
				style={{
					display: 'flex',
					justifyContent: 'space-between',
					alignItems: 'center',
					marginTop: 20,
				}}
			>
				<Button
					type='default'
					size='large'
					// ghost
					style={{
						fontWeight: 'bold',
						// borderRadius: 5,
					}}
					onClick={() => setInviteFormStep((p) => p - 1)}
				>
					<BiChevronLeft style={{ marginBottom: -2 }} /> Back
				</Button>

				<Button
					type='primary'
					size='large'
					style={{
						// margin: 5,
						// background: theme.color.blue,
						// borderColor: theme.color.blue,
						fontWeight: 'bold',
						// borderRadius: 5,
					}}
					htmlType='submit'
				>
					{shouldNotifyInvitee
						? <>Invite and notify teammate <BiEnvelope style={{ marginBottom: -2 }} /></>
						: <>Invite teammate (no notification)</>
					}
				</Button>
			</div>

		</Form>
	</>
	);
};

export default UMSInviteConfirmForm;
