import slugify from '@sindresorhus/slugify';
import moment from 'moment';
import { fill, isBoolean } from 'lodash';
import { saveCompany } from '../actions/company';
import {
	DATE_FORMAT_NO_TIME,
	DATE_FORMAT_ONLY_YEAR,
	ENGLISH_LANG, FRENCH_LANG,
} from '../constants/constant';
import CompanyService from '../services/company';
import {
	arrayWrap, documentId, mergeReducer, orderList,
} from '../utils/common';
import config from '../config/config';
import { LocalDebug } from '../utils/LocalDebug';
import {
	BUSINESS_PLAN_GH_ENTERPRISE_VALUE,
	BUSINESS_PLAN_GH_PREMIUM_VALUE,
	BUSINESS_PLAN_LEGACY_VALUE,
	BUSINESS_PLAN_NO_PLAN_VALUE,
	BUSINESS_PLAN_ROLES,
	getBusinessPlanLabel,
	getBusinessPlanShortLabel,
	isGenderHirePlan,
	isGenderScorePlan,
	isUMSPlan,
} from '../constants/property/business-plan';
import { areSomeMandatoryStepsComplete } from '../components/company/companyPage/shared/CompanyPageSteps';
import SURVEYS from '../components/genderscore/data/surveys';
import {
	GENDERSCORE_SURVEY_STATUS_CURRENT_VALUE,
	GENDERSCORE_SURVEY_STATUS_DRAFT_VALUE,
	GENDERSCORE_SURVEY_STATUS_PREVIOUS_VALUE,
	GENDERSCORE_SURVEY_STATUS_REVIEW_VALUE,
} from '../constants/property/genderscore-status';
import {
	UMS_SURVEY_STATUS_CURRENT_VALUE,
	UMS_SURVEY_STATUS_DRAFT_VALUE,
	UMS_SURVEY_STATUS_PREVIOUS_VALUE,
	UMS_SURVEY_STATUS_REVIEW_VALUE,
} from '../constants/property/ums-status';

export const GENDERSCORE_PASSING_DISALLOWED_GS_DISABLED_VALUE = 'GENDERSCORE_PASSING_DISALLOWED_GS_DISABLED_VALUE';
export const GENDERSCORE_PASSING_DISALLOWED_SURVEY_DISABLED_VALUE = 'GENDERSCORE_PASSING_DISALLOWED_SURVEY_DISABLED_VALUE';
export const GENDERSCORE_PASSING_DISALLOWED_SURVEY_NOT_SET_VALUE = 'GENDERSCORE_PASSING_DISALLOWED_SURVEY_NOT_SET_VALUE';
export const GENDERSCORE_PASSING_DISALLOWED_GS_FILLED_VALUE = 'GENDERSCORE_PASSING_DISALLOWED_GS_FILLED_VALUE';
export const GENDERSCORE_PASSING_DISALLOWED_USER_ROLE_VALUE = 'GENDERSCORE_PASSING_DISALLOWED_USER_ROLE_VALUE';

export const GENDERSCORE_PASSING_DISALLOWED_REASONS = {
	GS_DISABLED: 'GenderScore is disabled',
	SURVEY_DISABLED: 'Survey is disabled',
	SURVEY_NOT_SET: 'Survey is not set',
	GS_FILLED: 'GenderScore is already filled',
	USER_ROLE: 'User role not matching',
	OWNER_NOT_SET: 'Survey owner is not set',
	USER_NOT_OWNER: 'User is not the owner',
};

export const UMS_PASSING_DISALLOWED_UMS_DISABLED_VALUE = 'UMS_PASSING_DISALLOWED_UMS_DISABLED_VALUE';
export const UMS_PASSING_DISALLOWED_SURVEY_DISABLED_VALUE = 'UMS_PASSING_DISALLOWED_SURVEY_DISABLED_VALUE';
export const UMS_PASSING_DISALLOWED_SURVEY_MANAGER_NOT_SET_VALUE = 'UMS_PASSING_DISALLOWED_SURVEY_MANAGER_NOT_SET_VALUE';
export const UMS_PASSING_DISALLOWED_SURVEY_EMPLOYEE_NOT_SET_VALUE = 'UMS_PASSING_DISALLOWED_SURVEY_EMPLOYEE_NOT_SET_VALUE';
export const UMS_PASSING_DISALLOWED_UMS_FILLED_VALUE = 'UMS_PASSING_DISALLOWED_UMS_FILLED_VALUE';
export const UMS_PASSING_DISALLOWED_USER_ROLE_VALUE = 'UMS_PASSING_DISALLOWED_USER_ROLE_VALUE';

export const UMS_PASSING_DISALLOWED_REASONS = {
	UMS_DISABLED: 'UMS is disabled',
	SURVEY_DISABLED: 'Survey is disabled',
	SURVEY_MANAGER_NOT_SET: 'Survey for managers is not set',
	SURVEY_EMPLOYEE_NOT_SET: 'Survey for employees is not set',
	UMS_FILLED: 'UMS is already filled',
	USER_ROLE: 'User role not matching',
};

const className = 'CompanyModel';

export default class CompanyModel {
	id;

	_id;

	name;

	isClient;

	isSuggestion;

	access;

	path;

	logo;

	oneliner;

	website;

	industry;

	teamsize;

	revenue;

	headquarters;

	locations;

	team;

	data;

	desc;

	about;

	mission;

	values;

	benefits;

	recruitment;

	weLike;

	quote;

	diversityActions;

	communities;

	techStack;

	certifications;

	articles;

	podcasts;

	atsProvider;

	atsId;

	isAtsAutoPublish;

	demo;

	isPublished;

	publishedAt;

	areJobsPublished;

	jobsPublishedAt;

	publishedJobsCount;

	teamMembersCount;

	shouldNotifyAllOnApplysIfNoRecipients;

	defaultRecipientForApplysIfNoRecipients;

	shouldAnonTalentAvatars;

	canRecruiterOverrideAnonTalentAvatars;

	shouldAnonTalentNames;

	canRecruiterOverrideAnonTalentNames;

	genderScore;

	ums;

	deiCriteria;

	matches;

	glassdoor;

	inhersight;

	egapro;

	equapro;

	isTranslatable;

	translations;

	tagSlugs;

	tagTree;

	translatableFields = [
		'oneliner',
		'website',
		'data',
		'desc',
		'about',
		'mission',
		'values',
		'benefits',
		'recruitment',
		'weLike',
		'quote',
		'diversityActions',
		'communities',
	];

	isTranslationPublished;

	companyPlan;

	onboarding;

	churnedAt;

	constructor(company) {
		// LocalDebug.logInfo({ className, method: 'constructor'}, company);
		Object.assign(this, company || {});
		this.shouldAnonTalentAvatars = company?.shouldAnonTalentAvatars !== false;
		this.canRecruiterOverrideAnonTalentAvatars = company?.canRecruiterOverrideAnonTalentAvatars !== false;
		this.shouldAnonTalentNames = company?.shouldAnonTalentNames === true;
		this.canRecruiterOverrideAnonTalentNames = company?.canRecruiterOverrideAnonTalentNames !== false;
	}

	getTranslationFieldFallback(field, lang) {
		return this.getTranslationField(field, lang) || this.getTranslationField(field, ENGLISH_LANG);
	}

	getTranslationField(field, lang, defaultValue) {
		if (lang === ENGLISH_LANG) {
			return this?.[field];
		}
		return (
			(
				this?.translations?.hasOwnProperty(lang)
				&& this?.translations[lang]?.hasOwnProperty(field)
			)
				? this?.translations[lang][field]
				: defaultValue
		);
	}

	getTranslatedFieldValues(lang = ENGLISH_LANG) {
		if (lang === ENGLISH_LANG) {
			return this.translatableFields.map((f) => ({ [f]: this[f] })).reduce(mergeReducer, {});
		}
		return this.translations?.[lang] || {};
	}

	setTranslationField(field, value, lang) {
		if (lang === ENGLISH_LANG) {
			const newValues = {
				...this.getTranslatedFieldValues(),
				[field]: value,
			};
			Object.assign(this, newValues);
		} else {
			const newValues = {
				...this.getTranslatedFieldValues(),
				translations: {
					...(this.translations && { translations: this.translations }),
					[lang]: {
						...this.translations?.[lang] || {},
						[field]: value,
					},
				},
			};
			Object.assign(this, newValues);
		}
	}

	getTranslateData(lang) {
		const defaultValue = this.data;
		return this.getTranslationField('data', lang, defaultValue);
	}

	setTranslateData(relevantData, index, lang) {
		const defaultValue = this.data || [];
		const prevTranslateData = this.getTranslationField('data', lang, defaultValue);
		const newTranslateData = fill(prevTranslateData, relevantData, index, index + 1);
		this.setTranslationField('data', newTranslateData, lang);
	}

	addTranslateData(relevantData, lang, isTranslatable) {
		const prevTranslateData = this.getTranslationField('data', lang);
		const newTranslateData = [relevantData, ...prevTranslateData];
		this.setTranslationField('data', newTranslateData, lang);
		// also add the other languages
		if (isTranslatable) {
			if (lang === FRENCH_LANG) {
				this.addTranslateData(relevantData, ENGLISH_LANG);
			} else {
				const prevFrenchData = this.getTranslationField('data', FRENCH_LANG, []);
				if (prevFrenchData.length > 0) {
					this.addTranslateData(relevantData, FRENCH_LANG);
				}
			}
		}
	}

	removeTranslateData(index, lang, isTranslatable) {
		const defaultValue = [];
		const prevTranslateData = this.getTranslationField('data', lang, defaultValue);
		const newTranslateData = [...prevTranslateData];
		newTranslateData.splice(index, 1);
		this.setTranslationField('data', newTranslateData, lang);

		if (isTranslatable) {
			if (lang === FRENCH_LANG) {
				this.removeTranslateData(index, ENGLISH_LANG);
			} else {
				this.removeTranslateData(index, FRENCH_LANG);
			}
		}
	}

	orderData(startIndex, indexIndex, isTranslatable) {
		this.orderDataByLang(startIndex, indexIndex, ENGLISH_LANG);
		if (isTranslatable) {
			this.orderDataByLang(startIndex, indexIndex, FRENCH_LANG);
		}
	}

	orderDataByLang(startIndex, indexIndex, lang) {
		const defaultValue = [];
		const prevTranslateData = this.getTranslationField('data', lang, defaultValue);
		const data = orderList([...prevTranslateData], startIndex, indexIndex);
		this.setTranslationField('data', data, lang);
	}

	async save(dispatch, fields = []) {
		if (dispatch) {
			dispatch(saveCompany(this));
		} else {
			await CompanyService.saveCompany(this);
		}
	}

	getTranslatedValues(lang, fields = ['oneliner', 'name']) {
		return ({
			...this.getTranslatedFieldValues(lang),
			...this.translateFields(lang, fields),
		});
	}

	translateFields(lang, fields) {
		return fields.reduce((prev, cur) => {
			const defaultValue = this[cur];
			return {
				...prev,
				[`${cur}_${lang}`]: this.getTranslationField(cur, lang, defaultValue),
			};
		}, {});
	}

	getAppUri() {
		return `${config.appRoot}/company/${this.path}`;
	}

	getGAReportLinkForGlobalViews(startAt = null, endAt = null) {
		const companyPath = this.path;
		const params = {
			'explorer-table.advFilter': `%5B%5B0,%22analytics.pagePath%22,%22BW%22,%22~2Fcompany~2F${companyPath}%22,0%5D,%5B0,%22analytics.pagePath%22,%22BW%22,%22~2Fcompany~2F${companyPath}~2Fadmin%22,1%5D%5D`,
		};
		return this.buildGAReportLink(params, startAt, endAt);
	}

	getGAReportLinkForDiversitySubpageViews(startAt = null, endAt = null) {
		const companyPath = this.path;
		const params = {
			'explorer-table.advFilter': `%5B%5B0,%22analytics.pagePath%22,%22BW%22,%22~2Fcompany~2F${companyPath}%22,0%5D,%5B0,%22analytics.pagePath%22,%22BW%22,%22~2Fcompany~2F${companyPath}~2Fadmin%22,1%5D,%5B0,%22analytics.pagePath%22,%22PT%22,%22~2Fcompany~2F${companyPath}%3Fpage=stories%22,1%5D,%5B0,%22analytics.pagePath%22,%22PT%22,%22~2Fcompany~2F${companyPath}%3Fpage=team%22,1%5D,%5B0,%22analytics.pagePath%22,%22PT%22,%22~2Fcompany~2F${companyPath}%3Fpage=about%22,1%5D,%5B0,%22analytics.pagePath%22,%22PT%22,%22~2Fcompany~2F${companyPath}%3Fpage=jobs%22,1%5D%5D`,
		};
		return this.buildGAReportLink(params, startAt, endAt);
	}

	getGAReportLinkForSubpageViews(startAt = null, endAt = null) {
		const companyPath = this.path;
		const params = {
			'explorer-table.advFilter': `%5B%5B0,%22analytics.pagePath%22,%22BW%22,%22~2Fcompany~2F${companyPath}%3Fpage=%22,0%5D,%5B0,%22analytics.pagePath%22,%22PT%22,%22~2Fcompany~2F${companyPath}%3Fpage=diversity%22,1%5D%5D`,
		};
		return this.buildGAReportLink(params, startAt, endAt);
	}

	getGAReportLinkForJobViews(startAt = null, endAt = null) {
		// const companyPath = this.path;
		const companySlug = slugify(this.name);
		const params = {
			'explorer-table.advFilter': `%5B%5B0,%22analytics.pagePath%22,%22BW%22,%22~2Fjob~2F${companySlug}-%22,0%5D%5D`,
		};
		return this.buildGAReportLink(params, startAt, endAt);
	}

	buildGAReportLink(params, startAt = null, endAt = null) {
		const startDate = moment(startAt || this.publishedAt).format('YYYYMMDD');
		const endDate = moment(endAt || new Date()).format('YYYYMMDD');
		const basePath = 'https://analytics.google.com/analytics/web/#/report/content-pages/a127728257w186680945p183617578/';
		const inlineParams = Object.entries({
			'_u.date00': startDate,
			'_u.date01': endDate,
			'explorer-table.plotKeys': '%5B%5D',
			'explorer-table.rowCount': '500',
			...params,
		})
			.map((entry) => {
				const [field, value] = entry;
				return `${field}=${value}`;
			});
		return `${basePath}${inlineParams.join('&')}`;
	}

	// shouldAnonTalentAvatars() {
	// 	return this.shouldAnonTalentAvatars !== false;
	// }
	//
	// canRecruiterOverrideAnonTalentAvatars() {
	// 	return this.canRecruiterOverrideAnonTalentAvatars !== false;
	// }
	//
	// shouldAnonTalentNames() {
	// 	return this.shouldAnonTalentNames === true;
	// }
	//
	// canRecruiterOverrideAnonTalentNames() {
	// 	return this.canRecruiterOverrideAnonTalentNames !== false;
	// }
	//
	// shouldNotifyAllOnApplysIfNoRecipients() {
	// 	return this.shouldNotifyAllOnApplysIfNoRecipients;
	// }
	//
	// defaultRecipientForApplysIfNoRecipients() {
	// 	return this.defaultRecipientForApplysIfNoRecipients;
	// }

	/**
	 * Checks whether the company page is currently published in English or not ("is it live NOW?")
	 * @returns boolean
	 */
	isPublishedNow() {
		return this.isPublished && (!this.publishedAt || moment(this.publishedAt).isBefore(moment()));
	}

	/**
	 * Checks whether the company page is not yet published in English, but is set to be published in English in the future
	 * @returns boolean
	 */
	isPublishedFuture() {
		return this.isPublished && this.publishedAt && moment(this.publishedAt).isAfter(moment());
	}

	/**
	 * Checks whether the company page is set to be published in English (now or in the future) or not
	 * @returns boolean
	 */
	isPublishedNowOrFuture() {
		return this?.isPublishedNow() || this?.isPublishedFuture();
	}

	/**
	 * Checks whether the company is currently published or if it has been published at some point (and now is unpublished)
	 * Note: this function relies on the fact that the `publishedAt` is never reset when we unpublish
	 * @returns boolean
	 */
	hasEverBeenPublished() {
		return this.isPublished || this.publishedAt;
	}

	isCompanyPageInEdition() {
		return !this?.isPublishedNowOrFuture()
			&& areSomeMandatoryStepsComplete({ company: this });
	}

	areJobsPublishedNow() {
		return this.areJobsPublished && (!this.jobsPublishedAt || moment(this.jobsPublishedAt).isBefore(moment()));
	}

	areJobsPublishedFuture() {
		return this.areJobsPublished && this.jobsPublishedAt && moment(this.jobsPublishedAt).isAfter(moment());
	}

	areJobsUnpublished() {
		return !this.areJobsPublishedNow() && !this.areJobsPublishedFuture();
	}

	getSyncableAccesses() {
		return this.access?.filter((a) => a.permissions?.includes('atsSyncable'));
	}

	/**
	 * Checks whether the company is connected to an ATS via API
	 * @returns boolean
	 */
	isAtsSyncable() {
		// LocalDebug.logInfo({ className, method: 'isAtsSyncable' }, {
		// 	path: this.path, access: this.access?.length, permissions: this.access?.map(a => a?.permissions)?.flat(),
		// 	isAtsSyncable: !!this.access?.map(a => a?.permissions)?.flat()?.includes('atsSyncable'),
		// });
		return this.getSyncableAccesses()?.length > 0;
	}

	/**
	 * Check if there is any meta tag created for the company in the language indicated by the locale
	 * @param {*} locale
	 * @returns boolean
	 */
	areMetasCustomized(locale) {
		return locale === ENGLISH_LANG
			? this?.meta?.title || this?.meta?.description || this?.meta?.altImage
			: this?.meta?.fr && (this?.meta?.fr?.title || this?.meta?.fr?.description || this?.meta?.fr?.altImage);
	}

	/**
	 * Check if ALL the meta tags for the company in the language indicated by the locale are set
	 * @param {*} locale
	 * @returns boolean
	 */
	areAllMetasCustomized(locale) {
		return locale === ENGLISH_LANG
			? this?.meta?.title && this?.meta?.description && this?.meta?.altImage
			: this?.meta?.fr && (this?.meta?.fr?.title && this?.meta?.fr?.description && this?.meta?.fr?.altImage);
	}

	/**
	 * Retrieves the value (identifier) of the current plan
	 * Returns 'no-plan' by default (if not explicitly indicated)
	 * @returns string
	 */
	getCompanyPlanValue({ withLegacyDefault = true } = { withLegacyDefault: true }) {
		return this?.companyPlan?.planValue
			|| (withLegacyDefault !== false
				? BUSINESS_PLAN_NO_PLAN_VALUE
				: undefined
			);
	}

	/**
	 * Retrieves the label of the current plan
	 * Returns 'Legacy' by default (if not explicitly indicated)
	 * @returns string
	 */
	getCompanyPlanLabel({ withLegacyDefault = true } = { withLegacyDefault: true }) {
		return getBusinessPlanLabel(this?.getCompanyPlanValue({ withLegacyDefault }));
	}

	/**
	 * Retrieves the short label of the current plan
	 * Returns 'Legacy' by default (if not explicitly indicated)
	 * @returns string
	 */
	getCompanyPlanShortLabel({ withLegacyDefault = true } = { withLegacyDefault: true }) {
		return getBusinessPlanShortLabel(this?.getCompanyPlanValue({ withLegacyDefault }));
	}

	/**
	 * Checks whether the company is on the "legacy" plan
	 * @returns boolean
	 */
	isCompanyPlanLegacy() {
		return this?.getCompanyPlanValue() === BUSINESS_PLAN_LEGACY_VALUE;
	}

	/**
	 * Checks whether the company is under a GenderScore plan
	 * @returns boolean
	 */
	isGenderScoreCompanyPlan() {
		return isGenderScorePlan(this?.getCompanyPlanValue());
	}

	/**
	 * Checks whether the company is under a GenderHire plan
	 * @returns boolean
	 */
	isGenderHireCompanyPlan() {
		return isGenderHirePlan(this?.getCompanyPlanValue());
	}

	/**
	 * Checks whether the company is under a UMS plan
	 * @returns boolean
	 */
	isUMSCompanyPlan() {
		return isUMSPlan(this?.getCompanyPlanValue());
	}

	/**
	 * Returns the roles allowed for this company's business plan
	 * @returns boolean
	 */
	getCompanyRoles() {
		return BUSINESS_PLAN_ROLES[this?.getCompanyPlanValue()]
			|| BUSINESS_PLAN_ROLES.DEFAULT;
	}

	/**
	 * Checks whether the GenderScore has been filled for this company
	 * @returns boolean
	 */
	isGenderScoreFilled() {
		return this?.genderScore?.globalScore > 0;
	}

	getCompanyPlanGenderScore() {
		return this?.companyPlan?.genderScore || {};
	}

	getCompanyPlanGenderScoreSurvey() {
		return this?.getCompanyPlanGenderScore()?.survey;
	}

	getCompanyPlanGenderScoreIsEnabled() {
		return this?.getCompanyPlanGenderScore()?.isEnabled;
	}

	getCompanyPlanGenderScoreSurveyDataOption() {
		return SURVEYS.getSurveyDataOption(this?.getCompanyPlanGenderScoreSurvey());
	}

	/**
	 * Returns all GenderScore surveys
	 * @returns array
	 */
	getGenderScoreSurveys() {
		return this?.genderScoreSurveys || [];
	}

	/**
	 * Returns the GenderScore surveys in the status 'DRAFT', 'CURRENT', 'PREVIOUS'
	 * sorted by submission date (if exists, use update or start dates instead)
	 * @returns array
	 */
	getGenderScoreSortedSurveys() {
		return this?.getGenderScoreSurveys()
			?.filter((s) => [
				GENDERSCORE_SURVEY_STATUS_DRAFT_VALUE,
				GENDERSCORE_SURVEY_STATUS_CURRENT_VALUE,
				GENDERSCORE_SURVEY_STATUS_PREVIOUS_VALUE,
			].includes(s.status))
			?.sort(
				(a, b) => new Date(b.submittedAt || b.updatedAt || b.startedAt)
						- new Date(a.submittedAt || a.updatedAt || a.startedAt),
			) || [];
	}

	getGenderScorePreviousSurveys() {
		return this.getGenderScoreSortedSurveys()
			?.filter((s) => s.status === GENDERSCORE_SURVEY_STATUS_PREVIOUS_VALUE);
	}

	getGenderScoreProgress(dateFormat = DATE_FORMAT_NO_TIME) {
		const previousGenderScoreSurveys = this.getGenderScorePreviousSurveys();
		return previousGenderScoreSurveys?.map((s) => {
			return {
				score: s?.genderScore?.globalScore,
				year: moment(s?.submittedAt).format(DATE_FORMAT_ONLY_YEAR),
				fullDate: moment(s?.submittedAt).format(dateFormat),
			};
		});
	}

	getGenderScorePreviousSurvey() {
		return this.getGenderScorePreviousSurveys()?.[0];
	}

	getGenderScoreCurrentSurvey() {
		return this.getGenderScoreSortedSurveys()
			?.filter((s) => s.status === GENDERSCORE_SURVEY_STATUS_CURRENT_VALUE)?.[0];
	}

	getGenderScoreDraftSurvey() {
		return this.getGenderScoreSortedSurveys()
			?.filter((s) => s.status === GENDERSCORE_SURVEY_STATUS_DRAFT_VALUE)?.[0];
	}

	/**
	 * Returns all GenderScore surveys for a list of status
	 * @returns array
	 */
	getGenderScoreSurveysWithStatus({ status }) {
		return this?.getGenderScoreSurveys()
			?.filter?.((gss) => (
				arrayWrap(status || [])
					.filter((item) => item)
					.includes(gss?.status)
			))
			|| [];
	}

	/**
	 * Returns all GenderScore surveys with status 'DRAFT'
	 * @returns boolean
	 */
	getGenderScoreSurveyDrafts() {
		return this?.getGenderScoreSurveysWithStatus({ status: GENDERSCORE_SURVEY_STATUS_DRAFT_VALUE }) || [];
	}

	/**
	 * Checks whether GenderScore surveys drafts exist
	 * @returns boolean
	 */
	hasGenderScoreSurveyDrafts() {
		return this?.getGenderScoreSurveyDrafts()?.length > 0;
	}

	/**
	 * Checks whether consent has been given for the GenderScore
	 * @returns boolean
	 */
	getGenderScoreSurveyDraftByUser({ userId }) {
		return this?.getGenderScoreSurveyDrafts()
			?.find?.((gss) => (
				gss?.userId && userId
				&& gss?.userId?.toString?.() === userId?.toString?.()
			));
	}

	/**
	 * Checks whether consent has been given for the GenderScore
	 * @returns boolean
	 */
	hasGenderScoreSurveyDraftByUser({ userId }) {
		return !!this?.getGenderScoreSurveyDraftByUser({ userId });
	}

	/**
	 * Retrieves the status of the last GenderScore survey
	 * that has been created
	 * Status:
	 * 'DRAFT' // If there's a DRAFT, it's the last survey
	 * 'CURRENT' // Ignore if there's a DRAFT
	 * 'LEAD'  //  Ignore: not a client
	 * 'PREVIOUS // Ignore: when there's a PREVIOUS, we have a CURRENT
	 * 'REVIEW' // To be reviewed
	 * @returns string
	 */
	getLastGenderScoreSurveyStatus() {
		const statusList = [
			GENDERSCORE_SURVEY_STATUS_DRAFT_VALUE,
			GENDERSCORE_SURVEY_STATUS_CURRENT_VALUE,
			GENDERSCORE_SURVEY_STATUS_REVIEW_VALUE,
		]; // Order is important
		const lastStatus = statusList.reduce((acc, status) => {
			if (acc === 'N/A' && this?.getGenderScoreSurveysWithStatus({ status })?.length) {
				return status;
			}
			return acc;
		}, 'N/A');
		return lastStatus;
	}

	/**
	 * Retrieves the previous survey
	 * If last survey status is:
	 * 'DRAFT' // the previous survey is in status CURRENT
	 * 'CURRENT' // the previous survey is in status PREVIOUS
	 * 'LEAD'  //  Ignore: not a client
	 * 'PREVIOUS // Ignore: when there's a PREVIOUS, we have a CURRENT
	 * 'REVIEW' // Ignore: to be reviewed
	 * @returns number
	 */
	getPreviousGenderScoreSurvey() {
		const surveyStatusNow = this.getLastGenderScoreSurveyStatus();
		if (![
			GENDERSCORE_SURVEY_STATUS_DRAFT_VALUE,
			GENDERSCORE_SURVEY_STATUS_CURRENT_VALUE,
		].includes(surveyStatusNow)) {
			return null;
		}
		const prevStatus = surveyStatusNow === GENDERSCORE_SURVEY_STATUS_DRAFT_VALUE
			? GENDERSCORE_SURVEY_STATUS_CURRENT_VALUE
			: GENDERSCORE_SURVEY_STATUS_PREVIOUS_VALUE;
		const prevSurveys = this?.getGenderScoreSurveysWithStatus({ status: prevStatus });
		if (prevStatus === GENDERSCORE_SURVEY_STATUS_CURRENT_VALUE) {
			// It can only exist 1 CURRENT survey
			return prevSurveys?.[0];
		}
		// Return the most recent PREVIOUS survey
		return prevSurveys?.sort(
			(a, b) => new Date(b.submittedAt || b.updatedAt || b.startedAt)
					- new Date(a.submittedAt || a.updatedAt || a.startedAt),
		)?.[0];
	}

	/**
	 * Retrieves the previous survey score
	 * @returns number
	 */
	getPreviousGenderScoreSurveyGenderScore() {
		return this.getPreviousGenderScoreSurvey()?.genderScore || 0;
	}

	/**
	 * Checks whether the GenderScore can been passed by a specific user
	 * @returns boolean
	 */
	isGenderScorePassingAllowed({
		isStaff = false,
		isStaffView = false,
		isDocumentAdmin = false,
		isDocumentEditorOnly = false,
		currentUser,
		omitCurrentUser = false,
	} = {
		isStaff: false,
		isStaffView: false,
		isDocumentAdmin: false,
		isDocumentEditorOnly: false,
		omitCurrentUser: false,
	}) {
		const disallowedReasons = this.getGenderScorePassingDisallowedReasons({
			isDocumentAdmin, currentUser, omitCurrentUser,
		});

		if (
			[
				GENDERSCORE_PASSING_DISALLOWED_REASONS.GS_DISABLED,
				GENDERSCORE_PASSING_DISALLOWED_REASONS.SURVEY_DISABLED,
				GENDERSCORE_PASSING_DISALLOWED_REASONS.SURVEY_NOT_SET,
				GENDERSCORE_PASSING_DISALLOWED_REASONS.OWNER_NOT_SET,
			].some((p) => disallowedReasons.includes(p))
		) {
			return false;
		}

		// If staff and staffView activated, passing it is allowed
		if (isStaff && isStaffView) return true;

		// If it's not staff and the logged user is not the owner of the GS
		if ([
			GENDERSCORE_PASSING_DISALLOWED_REASONS.USER_NOT_OWNER,
		].some((p) => disallowedReasons.includes(p))) {
			return false;
		}

		return disallowedReasons?.length === 0;
	}

	/**
	 * Checks whether the GenderScore can been passed by a specific user
	 * @returns boolean
	 */
	getGenderScorePassingDisallowedReasons({
		isDocumentAdmin,
		currentUser,
		omitCurrentUser,
	} = {}) {
		const surveyOwnerId = this.getCompanyPlanGenderScore()?.ownerId;
		return [
			// If GS not enabled
			...!this.isGenderScoreEnabled()
				? [GENDERSCORE_PASSING_DISALLOWED_REASONS.GS_DISABLED]
				: [],
			// If survey not enabled
			...!this.isGenderScoreSurveyEnabled()
				? [GENDERSCORE_PASSING_DISALLOWED_REASONS.SURVEY_DISABLED]
				: [],
			// If survey not yet provided
			...!this.getCompanyPlanGenderScoreSurvey()
				? [GENDERSCORE_PASSING_DISALLOWED_REASONS.SURVEY_NOT_SET]
				: [],
			...!omitCurrentUser && !isDocumentAdmin
				? [GENDERSCORE_PASSING_DISALLOWED_REASONS.USER_ROLE]
				: [],
			// If no GenderScore survey owner or mismatch
			...!surveyOwnerId
				? [GENDERSCORE_PASSING_DISALLOWED_REASONS.OWNER_NOT_SET]
				: (!omitCurrentUser && surveyOwnerId?.toString?.() !== documentId(currentUser)?.toString?.())
					? [GENDERSCORE_PASSING_DISALLOWED_REASONS.USER_NOT_OWNER]
					: [],
		];
	}

	/**
	 * Checks whether the GenderScore should in theory be enabled
	 * for this company relative to its current plan
	 * @returns boolean
	 */
	shouldGenderScoreBeEnabledFromPlan() {
		if (this.isCompanyPlanLegacy()) return true;
		if (this.isGenderScoreCompanyPlan()) return true;
		if (this.isGenderHireCompanyPlan()) {
			const plan = this.getCompanyPlanValue({ withLegacyDefault: false });
			if (
				[
					BUSINESS_PLAN_GH_PREMIUM_VALUE,
					BUSINESS_PLAN_GH_ENTERPRISE_VALUE,
				].includes(plan)
			) return true;
		}
		return false;
	}

	/**
	 * Checks whether the GenderScore is enabled for this company
	 * @returns boolean
	 */
	isGenderScoreEnabled() {
		return this?.getCompanyPlanGenderScoreIsEnabled() === true;
	}

	/**
	 * Checks whether the GenderScore survey is accesible by the company users
	 * If the flag is not defined, assume it's false
	 * @returns boolean
	 */
	isGenderScoreSurveyEnabled() {
		return this?.companyPlan?.genderScore?.isSurveyEnabled || false;
	}

	/**
	 * Checks whether the GenderScore badge is public or not to the talents
	 * If the flag is not defined, assume it's true
	 * @returns boolean
	 */
	isGenderScoreBadgeStaffPublished() {
		return isBoolean(this?.companyPlan?.genderScore?.isStaffPublished)
			? this?.companyPlan?.genderScore?.isStaffPublished
			: true;
	}

	/**
	 * Retrieves the GenderScore purchase date
	 * @returns string date
	 */
	getGenderScorePurchasedDate() {
		return this?.companyPlan?.genderScore?.purchasedAt;
	}

	/**
	 * Retrieves the GenderScore expiration date
	 * When isExpirationOfCurrentBadge is false (default),
	 * retrieve the expiration date of the purchase of the GS
	 * When isExpirationOfCurrentBadge is true,
	 * retrieve the expiration date of the CURRENT GS Results
	 * > Use the GS expiration date as the default when the badge one does not exist
	 * @returns string date
	 */
	getGenderScoreExpirationDate(
		{ isExpirationOfCurrentBadge = false } = { isExpirationOfCurrentBadge: false },
	) {
		return (isExpirationOfCurrentBadge
			? this?.genderScore?.expiredAt : null)
			|| this?.companyPlan?.genderScore?.expiredAt;
	}

	/**
	 * Retrieves the time remaining to the GenderScore expiration date
	 * When isExpirationOfCurrentBadge is false (default),
	 * the expiration date of the purchase of the GS is used
	 * When isExpirationOfCurrentBadge is true,
	 * the expiration date of the CURRENT GS Results is used
	 * Note: this value can be negative if the expiration date is on the past
	 * @returns date
	 */
	getGenderScoreTimeToExpire(
		{ isExpirationOfCurrentBadge = false } = { isExpirationOfCurrentBadge: false },
	) {
		const expDate = new Date(this.getGenderScoreExpirationDate(
			{ isExpirationOfCurrentBadge },
		)).setHours(0, 0, 0, 0);
		const today = new Date().setHours(0, 0, 0, 0);
		return expDate - today;
	}

	/**
	 * Retrieves the days remaining to the GenderScore expiration date
	 * When isExpirationOfCurrentBadge is false (default),
	 * the expiration date of the purchase of the GS is used
	 * When isExpirationOfCurrentBadge is true,
	 * the expiration date of the CURRENT GS Results is used
	 * Note: this value can be negative if the expiration date is on the past
	 * @returns date
	 */
	getGenderScoreDaysToExpire(
		{ isExpirationOfCurrentBadge = false } = { isExpirationOfCurrentBadge: false },
	) {
		const timeToExpire = this.getGenderScoreTimeToExpire({ isExpirationOfCurrentBadge });
		return (Math.trunc(timeToExpire / 1000 / 60 / 60 / 24));
	}

	/**
	 * Checks whether the GenderScore is expired
	 * @returns string date
	 */
	isGenderScoreExpired() {
		return this?.getGenderScoreDaysToExpire({ isExpirationOfCurrentBadge: false }) <= 0;
	}

	/**
	 * Checks whether the CURRENT GenderScore Badge is expired
	 * @returns string date
	 */
	isCurrentGenderScoreBadgeExpired() {
		return this?.getGenderScoreDaysToExpire({ isExpirationOfCurrentBadge: true }) <= 0;
	}

	/**
	 * Checks whether the GenderScore is about to be expired
	 * @returns string date
	 */
	isGenderScoreCloseToExpire() {
		return this?.getGenderScoreDaysToExpire({ isExpirationOfCurrentBadge: false }) <= 45;
	}

	/**
	 * Checks whether the CURRENT GenderScore Badge is about to be expired
	 * @returns string date
	 */
	isCurrentGenderScoreBadgeCloseToExpire() {
		return this?.getGenderScoreDaysToExpire({ isExpirationOfCurrentBadge: true }) <= 45;
	}

	/**
	 * Checks whether a new expiration date is set for the GenderScore
	 * @returns string date
	 */
	isNewGenderScoreExpirationDateSet() {
		return (this.getGenderScoreDaysToExpire({
			isExpirationOfCurrentBadge: true,
		}) || 0) < this.getGenderScoreDaysToExpire({
			isExpirationOfCurrentBadge: false,
		});
	}

	getGenderScoreRanking(pillar = 'globalScore') {
		return this.genderScore?.currentStatistics?.rankings?.[pillar]
			?.find?.((item) => documentId(item)?.toString?.() === documentId(this)?.toString?.())
			?.rank;
	}

	/**
	 * Checks whether the company is on the legacy plan
	 * SPECIFICALLY for the edition of the company page
	 * @returns boolean
	 */
	isCompanyPageEditionLegacyVersion() { return this?.isCompanyPlanLegacy(); }

	/**
	 * Checks whether the company has at least 1 job offer published
	 * @returns boolean
	 */
	hasAnyJobPublished() {
		return this?.publishedJobsCount > 0;
	}

	/**
	 * Checks whether we have scraped the jobs yet
	 * This flag is set in the Company Settings by 50i Staff
	 * TODO:  [ONBOARDING] areJobsFetched
	 * @returns boolean
	 */
	areJobsScraped() {
		return this?.onboarding?.jobsSetup?.fetchingMethod === 'SCRAP';
	}

	/* TODO: [ONBOARDING] In the following methods, we only need to check
		the startedAt and finishedAt once they're set in the actions
		(i.e: publishing a company page should set the finishedAt of the onboarding.companyPage)
		Hence, review this functions and remove unnecesary checks
		Update: no, we still need those checks for companies that might have some steps Done
		before going through the onboarding (i.e. companies upgraded)
	*/

	/**
	 * Checks whether the Jobs Setup step of the onboarding has been started
	 * but the step has not been marked as Done yet
	 * @returns boolean
	 */
	hasStartedOnboardingJobsSetupProcess() {
		return !this?.onboarding?.jobsSetup?.finishedAt && !!this?.onboarding?.jobsSetup?.startedAt;
	}

	/**
	 * Checks whether the Jobs Setup step of the onboarding is Done
	 * @returns boolean
	 */
	hasFinishedOnboardingJobsSetupProcess() {
		return (!!(this?.onboarding?.jobsSetup?.finishedAt)
				|| (this?.isAtsSyncable() || this?.areJobsScraped()));
	}

	/**
	 * Checks whether the Company Page step of the onboarding has been started
	 * but the step has not been marked as Done yet
	 * TODO: [ONBOARDING] use the startedAt (write it on the company page first edition and read it here)
	 * @returns boolean
	 */
	hasStartedOnboardingCompanyPageProcess() {
		return !this?.onboarding?.companyPage?.finishedAt && this?.isCompanyPageInEdition();
	}

	/**
	 * Checks whether the Company Page step of the onboarding is Done
	 * TODO: [ONBOARDING] use the finishedAt (write it when publishing the company page the first time)
	 * @returns boolean
	 */
	hasFinishedOnboardingCompanyPageProcess() {
		return !!this?.onboarding?.companyPage?.finishedAt || this?.isPublishedNowOrFuture();
	}

	/**
	 * Checks whether the Team step of the onboarding is Done
	 * TODO: [ONBOARDING] use the finishedAt (write it when adding a team member the first time)
	 * @returns boolean
	 */
	hasFinishedOnboardingTeamProcess() {
		// TODO: [ONBOARDING] Only active and not current user (admin)
		return !!this?.onboarding?.team?.finishedAt || this?.teamMembersCount > 1;
	}

	/**
	 * Checks whether the Jobs Publication step of the onboarding is Done
	 * TODO: [ONBOARDING] use the finishedAt (write it when adding a team member the first time)
	 * @returns boolean
	 */
	hasFinishedOnboardingJobsPublicationProcess() {
		return !!this?.onboarding?.publishJobs?.finishedAt || this?.publishedJobsCount > 0;
	}

	/**
	 * Checks whether the GenderScore step of the onboarding has been started
	 * but the step has not been marked as Done yet
	 * TODO: [ONBOARDING] use the finishedAt (write it when adding the GenderScore the first time)
	 * @returns boolean
	 */
	hasStartedOnboardingGenderScoreProcess() {
		return !this?.onboarding?.genderScore?.finishedAt && !!this?.onboarding?.genderScore?.startedAt
		&& !this?.genderScore?.globalScore;
	}

	/**
	 * Checks whether the Jobs Setup step of the onboarding is Done
	 * @returns boolean
	 */
	hasFinishedOnboardingGenderScoreProcess() {
		return (!!(this?.onboarding?.genderScore?.finishedAt)
				|| this?.genderScore?.globalScore > 0);
	}

	/**
	 * Checks whether the Demo step of the onboarding is Done (the demo is requested)
	 * @returns boolean
	 */
	hasFinishedOnboardingDemoProcess() {
		return (!!(this?.onboarding?.demo?.finishedAt)
				&& !!this?.onboarding?.demo?.requestedAt);
	}

	/**
	 * Checks whether we can fetch the jobs of the company
	 * @returns boolean
	 */
	canBeFetched() {
		return this?.access.length > 0;
	}

	/**
	 * Checks whether the onboarding is already finished
	 * @returns boolean
	 */
	hasFinishedOnboarding() {
		return !!this.onboarding?.finishedAt;
	}

	isChurned() {
		return !!this.churnedAt;
	}

	isDemo() {
		return this?.name?.toLowerCase?.()?.startsWith('test');
	}

	/* *********************************************************************** */
	/* *****              UMS : Unbiased Management Score                 **** */
	/* *********************************************************************** */

	/**
	 * Checks whether the UMS has been filled for this company
	 * @returns boolean
	 */
	isUMSFilled() {
		return this?.ums?.globalScore > 0;
	}

	getCompanyPlanUMS() {
		return this?.companyPlan?.ums || {};
	}

	getCompanyPlanUMSIsEnabled() {
		return this?.getCompanyPlanUMS()?.isEnabled;
	}

	getCompanyPlanUMSSurveyGroup() {
		return this?.getCompanyPlanUMS()?.surveyGroup;
	}

	getCompanyPlanUMSSurveyManager() {
		return this?.getCompanyPlanUMS()?.surveyManager;
	}

	getCompanyPlanUMSSurveyEmployee() {
		return this?.getCompanyPlanUMS()?.surveyEmployee;
	}

	/**
	 * Returns all UMS surveys
	 * @returns array
	 */
	getUMSSurveys() {
		return this?.umsSurveys || [];
	}

	/**
	 * Returns the UMS surveys in the status 'DRAFT', 'CURRENT', 'PREVIOUS'
	 * sorted by submission date (if exists, use update or start dates instead)
	 * @returns array
	 */
	getUMSSortedSurveys() {
		return this?.getUMSSurveys()
			?.filter((s) => [
				UMS_SURVEY_STATUS_DRAFT_VALUE,
				UMS_SURVEY_STATUS_CURRENT_VALUE,
				UMS_SURVEY_STATUS_PREVIOUS_VALUE,
			].includes(s.status))
			?.sort(
				(a, b) => new Date(b.submittedAt || b.updatedAt || b.startedAt)
						- new Date(a.submittedAt || a.updatedAt || a.startedAt),
			) || [];
	}

	getUMSPreviousSurveys() {
		return this.getUMSSortedSurveys()
			?.filter((s) => s.status === UMS_SURVEY_STATUS_PREVIOUS_VALUE);
	}

	getUMSProgress(dateFormat = DATE_FORMAT_NO_TIME) {
		const previousUMSSurveys = this.getUMSPreviousSurveys();
		return previousUMSSurveys?.map((s) => {
			return {
				score: s?.ums?.globalScore,
				year: moment(s?.submittedAt).format(DATE_FORMAT_ONLY_YEAR),
				fullDate: moment(s?.submittedAt).format(dateFormat),
			};
		});
	}

	getUMSPreviousSurvey() {
		return this.getUMSPreviousSurveys()?.[0];
	}

	getUMSCurrentSurvey() {
		return this.getUMSSortedSurveys()
			?.filter((s) => s.status === UMS_SURVEY_STATUS_CURRENT_VALUE)?.[0];
	}

	getUMSDraftSurvey() {
		return this.getUMSSortedSurveys()
			?.filter((s) => s.status === UMS_SURVEY_STATUS_DRAFT_VALUE)?.[0];
	}

	/**
	 * Returns all UMS surveys for a list of status
	 * @returns array
	 */
	getUMSSurveysWithStatus({ status }) {
		return this?.getUMSSurveys()
			?.filter?.((s) => (
				arrayWrap(status || [])
					.filter((item) => item)
					.includes(s?.status)
			))
			|| [];
	}

	/**
	 * Returns all UMS surveys with status 'DRAFT'
	 * @returns boolean
	 */
	getUMSSurveyDrafts() {
		return this?.getUMSSurveysWithStatus({ status: UMS_SURVEY_STATUS_DRAFT_VALUE }) || [];
	}

	/**
	 * Checks whether UMS surveys drafts exist
	 * @returns boolean
	 */
	hasUMSSurveyDrafts() {
		return this?.getUMSSurveyDrafts()?.length > 0;
	}

	/**
	 * Checks whether consent has been given for the UMS
	 * @returns boolean
	 */
	getUMSSurveyDraftByUser({ user }) {
		return this?.getUMSSurveyDrafts()
			?.find?.((s) => (
				s?.userId && documentId(user)
				&& s?.userId?.toString?.() === documentId(user)?.toString?.()
			));
	}

	getUMSUserSurvey({ user }) {
		LocalDebug.logNull({ className, method: 'getUMSUserSurvey' }, { user, isUMSEmployee: user?.acls?.isUMSEmployee, isUMSManager: user?.acls?.isUMSManager });
		if (user?.acls?.isUMSEmployee) return this?.getUMSEmployeeSurvey({ user });
		if (user?.acls?.isUMSManager) return this?.getUMSManagerSurvey({ user });
		return null;
	}

	// getUMSEmployeeSurvey({ user }) {
	// 	const survey = this?.umsEmployeeSurvey;
	// 	return survey?.userId?.toString() === documentId(user)?.toString()
	// 		? survey
	// 		: null;
	// }

	getUMSEmployeeSurvey({ user }) {
		const survey = this?.getUMSEmployeeSurveyList({ user })?.[0];
		return survey;
	}

	getUMSEmployeeSurveyList({ user }) {
		const surveys = this?.umsSurveys
			?.filter((survey) => survey?.managerId)
			?.filter((survey) => survey?.userId?.toString() === documentId(user)?.toString());
		// const surveys = this?.umsManagerSurveys
		// 	?.find((survey) => survey?.userId?.toString() === documentId(user)?.toString());
		return surveys;
	}

	getUMSManagerSurvey({ user }) {
		const survey = this?.getUMSManagerSurveyList({ user })?.[0];
		// LocalDebug.logInfo({ className, method: 'getUMSManagerSurvey' }, { survey });
		return survey;
	}

	getUMSManagerSurveyList({ user }) {
		const surveys = this?.getUMSSurveys()
			?.filter((survey) => !survey?.managerId)
			?.filter((survey) => survey?.userId?.toString() === documentId(user)?.toString());
		// LocalDebug.logInfo({ className, method: 'getUMSManagerSurveyList' }, { surveys: surveys?.length });
		// const surveys = this?.umsManagerSurveys
		// 	?.find((survey) => survey?.userId?.toString() === documentId(user)?.toString());
		return surveys;
	}

	/**
	 * Checks whether consent has been given for the UMS
	 * @returns boolean
	 */
	hasUMSSurveyDraftByUser({ user }) {
		return !!this?.getUMSSurveyDraftByUser({ user });
	}

	/**
	 * Retrieves the status of the last UMS survey
	 * that has been created
	 * Status:
	 * 'DRAFT' // If there's a DRAFT, it's the last survey
	 * 'CURRENT' // Ignore if there's a DRAFT
	 * 'LEAD'  //  Ignore: not a client
	 * 'PREVIOUS // Ignore: when there's a PREVIOUS, we have a CURRENT
	 * 'REVIEW' // To be reviewed
	 * @returns string
	 */
	getLastUMSSurveyStatus() {
		const statusList = [
			UMS_SURVEY_STATUS_DRAFT_VALUE,
			UMS_SURVEY_STATUS_CURRENT_VALUE,
			UMS_SURVEY_STATUS_REVIEW_VALUE,
		]; // Order is important
		const lastStatus = statusList.reduce((acc, status) => {
			if (acc === 'N/A' && this?.getUMSSurveysWithStatus({ status })?.length) {
				return status;
			}
			return acc;
		}, 'N/A');
		return lastStatus;
	}

	/**
	 * Retrieves the previous survey
	 * If last survey status is:
	 * 'DRAFT' // the previous survey is in status CURRENT
	 * 'CURRENT' // the previous survey is in status PREVIOUS
	 * 'LEAD'  //  Ignore: not a client
	 * 'PREVIOUS // Ignore: when there's a PREVIOUS, we have a CURRENT
	 * 'REVIEW' // Ignore: to be reviewed
	 * @returns number
	 */
	getPreviousUMSSurvey() {
		const surveyStatusNow = this.getLastUMSSurveyStatus();
		if (![
			UMS_SURVEY_STATUS_DRAFT_VALUE,
			UMS_SURVEY_STATUS_CURRENT_VALUE,
		].includes(surveyStatusNow)) {
			return null;
		}
		const prevStatus = surveyStatusNow === UMS_SURVEY_STATUS_DRAFT_VALUE
			? UMS_SURVEY_STATUS_CURRENT_VALUE
			: UMS_SURVEY_STATUS_PREVIOUS_VALUE;
		const prevSurveys = this?.getUMSSurveysWithStatus({ status: prevStatus });
		if (prevStatus === UMS_SURVEY_STATUS_CURRENT_VALUE) {
			// It can only exist 1 CURRENT survey
			return prevSurveys?.[0];
		}
		// Return the most recent PREVIOUS survey
		return prevSurveys?.sort(
			(a, b) => new Date(b.submittedAt || b.updatedAt || b.startedAt)
					- new Date(a.submittedAt || a.updatedAt || a.startedAt),
		)?.[0];
	}

	/**
	 * Retrieves the previous survey score
	 * @returns number
	 */
	getPreviousUMSSurveyUMS() {
		return this.getPreviousUMSSurvey()?.ums || 0;
	}

	/**
	 * Checks whether the UMS can been passed by a specific user
	 * @returns boolean
	 */
	isUMSPassingAllowed({
		isStaff = false,
		isStaffView = false,
		isDocumentAdmin = false,
		isDocumentEditorOnly = false,
		currentUser,
		omitCurrentUser = false,
	} = {
		isStaff: false,
		isStaffView: false,
		isDocumentAdmin: false,
		isDocumentEditorOnly: false,
		omitCurrentUser: false,
	}) {
		const disallowedReasons = this.getUMSPassingDisallowedReasons({
			isDocumentAdmin, currentUser, omitCurrentUser,
		});

		if (
			[
				UMS_PASSING_DISALLOWED_REASONS.UMS_DISABLED,
				UMS_PASSING_DISALLOWED_REASONS.SURVEY_DISABLED,
				UMS_PASSING_DISALLOWED_REASONS.SURVEY_MANAGER_NOT_SET,
				UMS_PASSING_DISALLOWED_REASONS.SURVEY_EMPLOYEE_NOT_SET,
			].some((p) => disallowedReasons.includes(p))
		) {
			return false;
		}

		// If staff and staffView activated, passing it is allowed
		if (isStaff && isStaffView) return true;

		// If it's not staff and the logged user is not the owner of the UMS

		return disallowedReasons?.length === 0;
	}

	/**
	 * Checks whether the UMS can been passed by a specific user
	 * @returns boolean
	 */
	getUMSPassingDisallowedReasons({
		isDocumentAdmin,
		currentUser,
		omitCurrentUser,
	} = {}) {
		return [
			// If UMS not enabled
			...!this.isUMSEnabled()
				? [UMS_PASSING_DISALLOWED_REASONS.UMS_DISABLED]
				: [],
			// If survey not enabled
			...!this.isUMSSurveyEnabled()
				? [UMS_PASSING_DISALLOWED_REASONS.SURVEY_DISABLED]
				: [],
			// If survey not yet provided
			...!this.getCompanyPlanUMSSurveyManager()
				? [UMS_PASSING_DISALLOWED_REASONS.SURVEY_MANAGER_NOT_SET]
				: [],
			...!this.getCompanyPlanUMSSurveyEmployee()
				? [UMS_PASSING_DISALLOWED_REASONS.SURVEY_EMPLOYEE_NOT_SET]
				: [],
			...!omitCurrentUser && !isDocumentAdmin
				? [UMS_PASSING_DISALLOWED_REASONS.USER_ROLE]
				: [],
		];
	}

	/**
	 * Checks whether the UMS should in theory be enabled
	 * for this company relative to its current plan
	 * @returns boolean
	 */
	shouldUMSBeEnabledFromPlan() {
		if (this.isUMSCompanyPlan()) return true;
		return false;
	}

	/**
	 * Checks whether the UMS is enabled for this company
	 * @returns boolean
	 */
	isUMSEnabled() {
		return this?.getCompanyPlanUMSIsEnabled() === true;
	}

	/**
	 * Checks whether the UMS survey is accesible by the company users
	 * If the flag is not defined, assume it's false
	 * @returns boolean
	 */
	isUMSSurveyEnabled() {
		return this?.companyPlan?.ums?.isSurveyEnabled || false;
	}

	/**
	 * Retrieves the UMS purchase date
	 * @returns string date
	 */
	getUMSPurchasedDate() {
		return this?.companyPlan?.ums?.purchasedAt;
	}

	/**
	 * Retrieves the UMS expiration date
	 * When isExpirationOfCurrentBadge is false (default),
	 * retrieve the expiration date of the purchase of the UMS
	 * When isExpirationOfCurrentBadge is true,
	 * retrieve the expiration date of the CURRENT UMS Results
	 * > Use the UMS expiration date as the default when the badge one does not exist
	 * @returns string date
	 */
	getUMSExpirationDate(
		{ isExpirationOfCurrentBadge = false } = { isExpirationOfCurrentBadge: false },
	) {
		return (isExpirationOfCurrentBadge
			? this?.ums?.expiredAt : null)
			|| this?.companyPlan?.ums?.expiredAt;
	}

	/**
	 * Retrieves the time remaining to the UMS expiration date
	 * When isExpirationOfCurrentBadge is false (default),
	 * the expiration date of the purchase of the UMS is used
	 * When isExpirationOfCurrentBadge is true,
	 * the expiration date of the CURRENT UMS Results is used
	 * Note: this value can be negative if the expiration date is on the past
	 * @returns date
	 */
	getUMSTimeToExpire(
		{ isExpirationOfCurrentBadge = false } = { isExpirationOfCurrentBadge: false },
	) {
		const expDate = new Date(this.getUMSExpirationDate(
			{ isExpirationOfCurrentBadge },
		)).setHours(0, 0, 0, 0);
		const today = new Date().setHours(0, 0, 0, 0);
		return expDate - today;
	}

	/**
	 * Retrieves the days remaining to the UMS expiration date
	 * When isExpirationOfCurrentBadge is false (default),
	 * the expiration date of the purchase of the UMS is used
	 * When isExpirationOfCurrentBadge is true,
	 * the expiration date of the CURRENT UMS Results is used
	 * Note: this value can be negative if the expiration date is on the past
	 * @returns date
	 */
	getUMSDaysToExpire(
		{ isExpirationOfCurrentBadge = false } = { isExpirationOfCurrentBadge: false },
	) {
		const timeToExpire = this.getUMSTimeToExpire({ isExpirationOfCurrentBadge });
		return (Math.trunc(timeToExpire / 1000 / 60 / 60 / 24));
	}

	/**
	 * Checks whether the UMS is expired
	 * @returns string date
	 */
	isUMSExpired() {
		return this?.getUMSDaysToExpire({ isExpirationOfCurrentBadge: false }) <= 0;
	}

	/**
	 * Checks whether the CURRENT UMS Badge is expired
	 * @returns string date
	 */
	isCurrentUMSBadgeExpired() {
		return this?.getUMSDaysToExpire({ isExpirationOfCurrentBadge: true }) <= 0;
	}

	/**
	 * Checks whether the UMS is about to be expired
	 * @returns string date
	 */
	isUMSCloseToExpire() {
		return this?.getUMSDaysToExpire({ isExpirationOfCurrentBadge: false }) <= 45;
	}

	/**
	 * Checks whether the CURRENT UMS Badge is about to be expired
	 * @returns string date
	 */
	isCurrentUMSBadgeCloseToExpire() {
		return this?.getUMSDaysToExpire({ isExpirationOfCurrentBadge: true }) <= 45;
	}

	/**
	 * Checks whether a new expiration date is set for the UMS
	 * @returns string date
	 */
	isNewUMSExpirationDateSet() {
		return (this.getUMSDaysToExpire({
			isExpirationOfCurrentBadge: true,
		}) || 0) < this.getUMSDaysToExpire({
			isExpirationOfCurrentBadge: false,
		});
	}
}
