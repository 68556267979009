import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { LocalDebug } from '../utils/LocalDebug';
import {
	getAtsArchiveReasons,
	getAtsCandidatePipelines,
	getAtsPipelines,
	getAtsShortlistPipelines,
} from '../reducers/app';
import { arrayWrap, documentId, objectStripUndefinedValues } from '../utils/common';

export const CandidateContext = React.createContext();

const CandidateProvider = ({ children }) => {
	const className = 'CandidateProvider';

	const atsPipelines = useSelector(getAtsPipelines);
	const atsCandidatePipelines = useSelector(getAtsCandidatePipelines);
	const atsShortlistPipelines = useSelector(getAtsShortlistPipelines);
	const atsArchiveReasons = useSelector(getAtsArchiveReasons);

	const getItemFilterForIdOrValue = (value) => (item) => documentId(item) === value || documentId(item) === documentId(value) || item.value === value || item.value === value?.value;
	const findPipeline = (value) => atsPipelines.find(getItemFilterForIdOrValue(value));
	const findPipelines = (values) => (values ? arrayWrap(values).map(findPipeline) : []);
	const findStage = (value) => atsPipelines.map((item) => item?.stages).flat().find(getItemFilterForIdOrValue(value));
	const findStages = (values) => (values ? arrayWrap(values).map(findStage) : []);
	const findArchiveReason = (value) => atsArchiveReasons.find(getItemFilterForIdOrValue(value));
	const findArchiveReasons = (values) => (values ? arrayWrap(values).map(findArchiveReason) : []);

	const [sortField, setSortField] = useState(null);
	const [sortOrder, setSortOrder] = useState(null);
	const [sources, setSources] = useState([]);
	const [atsStages, setAtsStages] = useState([]);
	const [candidatePipelines, setCandidatePipelines] = useState([]);
	const [candidatePipeline, setCandidatePipeline] = useState();
	const [shortlistPipeline, setShortlistPipeline] = useState(atsShortlistPipelines[0]);
	const [candidateStages, setCandidateStages] = useState([]);
	const [isArchived, setIsArchived] = useState(false);
	const [showArchived, setShowArchived] = useState(null);
	const [archiveReasons, setArchiveReasons] = useState([]);
	const [candidateCounts, setCandidateCounts] = useState({});
	const [invalidated, setInvalidated] = useState(false);
	const [shouldRefresh, setShouldRefresh] = useState(0);
	const [selectedOptions, setSelectedOptions] = useState({});
	const [atsStageOptions, setAtsStageOptions] = useState([]);
	const [sourceOptions, setSourceOptions] = useState([]);
	const [contactedSelected, setContactedSelected] = useState(true);
	const [contactedCount, setContactedCount] = useState(0);

	const processShouldRefresh = () => {
		LocalDebug.logNull({ className, method: 'processShouldRefresh' }, {
			invalidated, shouldRefresh, selectedOptions, atsPipelines, atsCandidatePipelines,
		});

		// setCandidatePipeline(findPipeline(selectedOptions.pipeline));
		setCandidatePipelines(findPipelines(selectedOptions.pipelines)?.map((o) => o?.value));
		setCandidateStages(findStages(selectedOptions.stages));
		setIsArchived([undefined, null].includes(isArchived) ? null : [true, 'true'].includes(selectedOptions.isArchived));
		setShowArchived([true, 'true'].includes(selectedOptions.showArchived));
		setArchiveReasons(findArchiveReasons(selectedOptions.archiveReasons));
		setSources(selectedOptions.sources);
		setAtsStages(selectedOptions.atsStages);
		setSortField(selectedOptions.sortField);
		setSortOrder(selectedOptions.sortOrder);

		setShouldRefresh((p) => p + 1);
	};

	useEffect(() => {
		LocalDebug.logNull({ className, effects: 'invalidated, selectedOptions' }, {
			invalidated, selectedOptions, shouldRefresh,
		});
		if (invalidated) {
			LocalDebug.logNull({ className, effects: 'invalidated' }, { invalidated, shouldRefresh });
			setTimeout(processShouldRefresh, 1);
			setInvalidated(false);
		}
	}, [invalidated, selectedOptions]);

	const updateSelectedOptions = ({
									   pipeline, pipelines, stages, isArchived, showArchived, archiveReasons, sources,
									   atsStages, sortField, sortOrder,
								   }) => {
		const options = {
			...objectStripUndefinedValues({
				pipeline,
				pipelines,
				stages,
				archiveReasons,
				sources,
				atsStages,
				showArchived,
				sortField,
				sortOrder,
				isArchived,
			}),
			// isArchived,
		};
		LocalDebug.logNull({ className, method: 'updateSelectedOptions' }, {
			pipeline,
			pipelines,
			stages,
			isArchived,
			showArchived,
			archiveReasons,
			sources,
			atsStages,
			sortField,
			sortOrder,
			options,
			selectedOptions,
		});
		setSelectedOptions((prev) => ({ ...prev, ...options }));
		setInvalidated(true);
	};

	const updateSources = (values) => {
		LocalDebug.logInfo({ className, method: 'updateSources' }, { values });
		setSelectedOptions({ sources: values });
	};

	const updateAtsStages = (values) => {
		LocalDebug.logInfo({ className, method: 'updateAtsStages' }, { values });
		setSelectedOptions({ atsStages: values });
	};

	const updatePipeline = (value) => {
		LocalDebug.logInfo({ className, method: 'updatePipeline' }, { value });
		setSelectedOptions({ pipeline: value });
	};

	const updatePipelines = (values) => {
		LocalDebug.logInfo({ className, method: 'updatePipelines' }, { values });
		setSelectedOptions({ pipelines: values });
	};

	const updateStages = (values) => {
		LocalDebug.logInfo({ className, method: 'updateStages' }, { values });
		setSelectedOptions({ stages: values });
	};

	const updateIsArchived = (value) => {
		LocalDebug.logInfo({ className, method: 'updateIsArchived' }, { value });
		setSelectedOptions({ isArchived: value });
	};

	const updateArchiveReasons = (values) => {
		LocalDebug.logInfo({ className, method: 'updateArchiveReasons' }, { values });
		setSelectedOptions({ archiveReasons: values });
	};

	const isPipelineAllowed = (pipeline, allowedPipelines) => {
		if (!allowedPipelines?.length) return true;
		const allowedPipelineValues = allowedPipelines?.map((p) => p?.value);
		if (allowedPipelineValues.includes(pipeline)) return true;
		return false;
	};

	const isStageAllowed = (stage, allowedPipelines) => {
		if (!allowedPipelines?.length) return true;
		const allowedPipelineStageValues = allowedPipelines?.map((p) => p?.stages?.map((s) => s.value)).flat();
		if (allowedPipelineStageValues.includes(stage)) return true;
		return false;
	};

	const applyQueryParams = (queryParams, allowedPipelines) => {
		const method = 'applyQueryParams';
		LocalDebug.logNull({ className, method }, {
			queryParams,
			allowedPipelines,
			candidatePipeline,
			candidateStages,
			isArchived,
			showArchived,
			archiveReasons,
			sources,
			atsStages,
		});
		const {
			candidatePipeline: qcp, candidateStage: qcs,
			isArchived: qia, archiveReason: qar, showArchived: qsa,
			source: qs, atsStage: qas,
			sortField: qsf, sortOrder: qso,
		} = queryParams;

		if (qcp || qcs || qia || qar || qs || qas || qsa || qsf || qso) {
			delete queryParams.candidatePipeline;
			delete queryParams.candidateStage;
			delete queryParams.isArchived;
			delete queryParams.archiveReason;
			delete queryParams.showArchived;
			delete queryParams.source;
			delete queryParams.atsStage;
			delete queryParams.sortField;
			delete queryParams.sortOrder;

			const options = {
				// ...qia === 'false' ? { isArchived: false } : {},
				isArchived: qia === 'true',
				archiveReasons: qar,
			};

			// LocalDebug.logInfo({ className, method }, { allowedPipelines, '!allowedPipelines?.length': !allowedPipelines?.length, 'allowedPipelines?.map(p => p?.value).includes(qcp)': allowedPipelines?.map(p => p?.value).includes(qcp)})

			// if (isPipelineAllowed(qcp, allowedPipelines)) {
			//     options.pipeline = qcp;
			//     options.stages = qcs ? arrayWrap(qcs).filter(value => isStageAllowed(value, allowedPipelines)) : [];
			// }

			if (showArchived) options.showArchived = true;
			options.pipelines = arrayWrap(qcp)?.filter((item) => item && item !== 'undefined');
			options.sources = arrayWrap(qs)?.filter((item) => item && item !== 'undefined');
			options.atsStages = arrayWrap(qas)?.filter((item) => item && item !== 'undefined');
			options.sortField = qsf && qsf !== 'undefined' ? qsf : null;
			options.sortOrder = qso && qso !== 'undefined' ? qso : null;
			LocalDebug.logNull({ className, method }, { options });
			updateSelectedOptions(options);
			// setSelectedOptions(options);
			return true;
		}

		// if (!isArchived) {
		//     if (!isPipelineAllowed(candidatePipeline?.value, allowedPipelines)) {
		//         updateSelectedOptions({
		//             pipeline: allowedPipelines?.[0],
		//             stages: [],
		//         });
		//         // setSelectedOptions({
		//         //     pipeline: allowedPipelines?.[0],
		//         //     stages: [],
		//         // });
		//         return true;
		//     }
		// }
	};

	return (
		<CandidateContext.Provider
			value={{
				findPipeline,
				findStage,
				findStages,
				findArchiveReason,
				findArchiveReasons,
				candidatePipeline,
				setCandidatePipeline,
				candidatePipelines,
				setCandidatePipelines,
				shortlistPipeline,
				setShortlistPipeline,
				candidateStages,
				setCandidateStages,
				isArchived,
				setIsArchived,
				archiveReasons,
				setArchiveReasons,
				showArchived,
				setShowArchived,
				candidateCounts,
				setCandidateCounts,
				updatePipeline,
				updateStages,
				updateIsArchived,
				updateArchiveReasons,
				updateSelectedOptions,
				shouldRefresh,
				applyQueryParams,
				atsStageOptions,
				setAtsStageOptions,
				sourceOptions,
				setSourceOptions,
				sources,
				setSources,
				updateSources,
				atsStages,
				setAtsStages,
				updateAtsStages,
				sortField,
				setSortField,
				sortOrder,
				setSortOrder,
				contactedSelected,
				setContactedSelected,
				contactedCount,
				setContactedCount,
			}}
		>
			{children}
		</CandidateContext.Provider>
	);
};

export default CandidateProvider;
