import { Col, Divider, Row } from 'antd';
import FormBoxTitle from '../app/form/FormBoxTitle';
import Box from '../app/box/Box';
import { useTheme } from '@emotion/react';
import AccountCredentialsPasswordForm from './AccountCredentialsPasswordForm';
import AccountCredentialsEmailForm from './AccountCredentialsEmailForm';

const AccountCredentialsForm = ({
	user,
	...props
}) => {
	const className = 'AccountCredentialsForm';

	const theme = useTheme();

	return (
		<>
			<FormBoxTitle
				title={<>🔑 Edit your credentials</>}
			/>

			<Row gutter={[20, 20]}>
				<Col span={12}>
					<Box style={{ padding: '12px 20px 20px' }}>
						<Divider
							orientation='left'
							style={{ marginTop: 0 }}
						>
							<span>Email</span>
						</Divider>

						<AccountCredentialsEmailForm user={user} />

					</Box>
				</Col>
				<Col span={12}>
					<Box style={{ padding: '12px 20px 20px' }}>
						<Divider
							orientation='left'
							style={{ marginTop: 0 }}
						>
							<span>Password</span>
						</Divider>

						<AccountCredentialsPasswordForm user={user} />
					</Box>

				</Col>
			</Row>
		</>
	);
};

export default AccountCredentialsForm;
