import { Tag } from 'antd';
import { useTheme } from '@emotion/react';
import { accessTypeOptions } from '../../../constants/constant';
import { FaRoute } from 'react-icons/fa';

const CompanyAccessAccessTypeTag = (
	{
		companyAccess,
		...props
	},
) => {
	const theme = useTheme();

	if (!companyAccess?.accessType) return null;

	const accessTypeOption = accessTypeOptions.find((a) => a.value === companyAccess?.accessType);
	return (
		<span>{accessTypeOption?.icon?.(12)} <b>{accessTypeOption?.label || 'Unrecognized type'}</b></span>
	);
	// return (
	//     <Tag
	//         icon={<FaRoute style={{marginBottom: -2}}/>}
	//         color="cyan"
	//         style={{...props?.style}}
	//     >
	//         &nbsp;<b>{accessTypeOption?.label}</b>
	//     </Tag>
	// );
};

export default CompanyAccessAccessTypeTag;
