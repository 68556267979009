import CAREER_ROLE_GROUP from '../../property/career-role-group';
import { innerObjPropExtractor } from '../../property/property-extractor';
import { innerObjPropMapper } from '../../property/property-mapper';

const USER_PROPERTY_CAREER_ROLE_GROUP_KEY = 'career-role-group';

const USER_PROPERTY_CAREER_ROLE_GROUP_MULTI = true;
const USER_PROPERTY_CAREER_ROLE_GROUP_FREE = false;

const USER_PROPERTY_CAREER_ROLE_GROUP_FIELD = 'career.roleGroup';
const USER_PROPERTY_CAREER_ROLE_GROUP_MAPPER = innerObjPropMapper(USER_PROPERTY_CAREER_ROLE_GROUP_FIELD);
const USER_PROPERTY_CAREER_ROLE_GROUP_EXTRACTOR = innerObjPropExtractor(USER_PROPERTY_CAREER_ROLE_GROUP_FIELD);

export default {
	...CAREER_ROLE_GROUP,
	key: USER_PROPERTY_CAREER_ROLE_GROUP_KEY,
	USER_PROPERTY_CAREER_ROLE_GROUP_KEY,
	free: USER_PROPERTY_CAREER_ROLE_GROUP_FREE,
	USER_PROPERTY_CAREER_ROLE_GROUP_FREE,
	multi: USER_PROPERTY_CAREER_ROLE_GROUP_MULTI,
	USER_PROPERTY_CAREER_ROLE_GROUP_MULTI,
	extractor: USER_PROPERTY_CAREER_ROLE_GROUP_EXTRACTOR,
	USER_PROPERTY_CAREER_ROLE_GROUP_EXTRACTOR,
	field: USER_PROPERTY_CAREER_ROLE_GROUP_FIELD,
	USER_PROPERTY_CAREER_ROLE_GROUP_FIELD,
};
