const ListSeparator = (
	{
		defaultContainer = 'span',
		// separator = <span style={{ opacity: 0.2 }}>&nbsp;/ </span>,
		placement = 'right',
		withSeparator = true,
		children,
		...props
	},
) => {
	const Container = defaultContainer;

	if (!children) return null;

	return (
		children?.map((child, index, arr) => (
			<Container
				key={index}
				style={{ ...props?.style?.container }}
			>
				{(
					!!withSeparator
					&& (
						(placement === 'right' && index > 0)
						|| (placement === 'left' && index < arr.length)
					)
					&& (
						withSeparator === true
							? <span style={{ opacity: 0.2 }}>&nbsp;/ </span>
							: withSeparator
					)
				)}
				{child}
			</Container>
		))
	);
};

export default ListSeparator;
