/** @jsxImportSource @emotion/react */

import React from 'react';
import Wrapper from './Wrapper';
import { useTheme } from '@emotion/react';

const classes = (theme) => ({
	container: {
		'background': 'radial-gradient(#3157af 30%, #0d2b79 64%)',
		'backgroundPosition': '50% 0',
		'backgroundSize': '200% 200%',
		'backgroundRepeat': 'no-repeat',
		'position': 'relative',
		'marginBottom': '72px',
		'paddingTop': '24px',
		'paddingBottom': '0px',
		'.wrapper-content': {
			alignItems: 'center',
		},
		':before ': {
			content: "''",
			display: 'block',
			position: 'absolute',
			bottom: -50,
			width: '50%',
			height: 50,
			left: 0,
			background:
        'linear-gradient(to bottom left, #3157af 49.6%, transparent 50.4%), linear-gradient(to bottom right, #a8e4d6 49.6%, transparent 50.4%)',
		},
		':after': {
			right: '0',
			background:
        'linear-gradient(to bottom right, #3157af 49.6%, transparent 50.4%), linear-gradient(to bottom left, #eae040 49.6%, transparent 50.4%)',
			content: "''",
			display: 'block',
			position: 'absolute',
			bottom: '-50px',
			width: '50%',
			height: '50px',
		},
		':layout-flexvc': {
			paddingTop: '24px',
			paddingBottom: '0px',
		},
	},
	content: {
		textAlign: 'center',
	},
});

const HeaderPage = ({
	title,
	subtitle,
	renderHeader,
	style = {},
	children,
	width = 680,
}) => {
	const theme = useTheme();
	const styles = classes(theme);

	return (
		<Wrapper style={{ ...styles.container, ...style }} width={width}>
			<div>
				<div
					className="flexColumn justifyCenter alignCenter fullWidth header-content layout-header"
					css={styles.content}
				>
					{renderHeader ? (
						renderHeader()
					) : (
						<>
							{title && <h2>{title}</h2>}
							{subtitle && <h3>{subtitle}</h3>}
							{children}
						</>
					)}
				</div>
			</div>
		</Wrapper>
	);
};

export default HeaderPage;
