import { Space, Switch } from 'antd';
import { useDispatch } from 'react-redux';
import { FaCalendar, FaSearch, FaSync } from 'react-icons/fa';
import { useAuthContext } from '../../../hooks/useAuthContext';
import * as adminActions from '../../../actions/admin';
import { buildConfirmBtn } from '../../../utils/common';
import StaffCommandButton from './StaffCommandButton';
import AdminToolsSectionContainer from './AdminToolsSectionContainer';
import { useContext } from 'react';
import { StaffToolsContext } from '../../../contexts/StaffToolsProvider';

export const Add50inTechSavedSearchesButton = (
	{
		title = 'Add 50inTech saved searches',
		Icon = FaSearch,
	},
) => (
	<StaffCommandButton
		title={title}
		Icon={Icon}
		dispatcher={adminActions.add50inTechSavedSearches}
	/>
);

export const SavedSearchesTabsSwitch = (
	{
		title = 'Display Suggested for you and Hidden tabs',
		checkedChildren = <b>Enabled</b>,
		unCheckedChildren = <b>Disabled</b>,
	},
) => {
	const {
		isSavedSearchTabsEnabled, setIsSavedSearchTabsEnabled,
	} = useContext(StaffToolsContext);

	return (
		<Space>
			{title}
			<Switch
				className='ant-switch-large'
				checked={!!isSavedSearchTabsEnabled}
				checkedChildren={checkedChildren}
				unCheckedChildren={unCheckedChildren}
				onChange={() => setIsSavedSearchTabsEnabled(!isSavedSearchTabsEnabled)}
			/>
		</Space>
	);
};

export const SavedSearchesViewedSwitch = (
	{
		title = 'Toggle Exclude viewed profiles',
		checkedChildren = <b>Enabled</b>,
		unCheckedChildren = <b>Disabled</b>,
	},
) => {
	const {
		isSavedSearchExcludeViewedEnabled, setIsSavedSearchExcludeViewedEnabled,
	} = useContext(StaffToolsContext);

	return (
		<Space>
			{title}
			<Switch
				className='ant-switch-large'
				checked={!!isSavedSearchExcludeViewedEnabled}
				checkedChildren={checkedChildren}
				unCheckedChildren={unCheckedChildren}
				onChange={() => setIsSavedSearchExcludeViewedEnabled(!isSavedSearchExcludeViewedEnabled)}
			/>
		</Space>
	);
};

export const SavedSearchesWithJobSwitch = (
	{
		title = 'Saved search from job',
		checkedChildren = <b>Enabled</b>,
		unCheckedChildren = <b>Disabled</b>,
	},
) => {
	const {
		savedSearchWithJobEnabled, setSavedSearchWithJobEnabled,
	} = useContext(StaffToolsContext);

	return (
		<Space>
			{title}
			<Switch
				className='ant-switch-large'
				checked={!!savedSearchWithJobEnabled}
				checkedChildren={checkedChildren}
				unCheckedChildren={unCheckedChildren}
				onChange={() => setSavedSearchWithJobEnabled(!savedSearchWithJobEnabled)}
			/>
		</Space>
	);
};

export const ComputeSavedSearchesAlertsButton = (
	{
		title = 'Update all alerts',
		Icon = FaSync,
		style,
	},
) => (
	<StaffCommandButton
		title={title}
		Icon={Icon}
		dispatcher={adminActions.computeSavedSearchesAlerts}
		style={style}
	/>
);

const AdminToolsSavedSearchesSection = () => {
	const dispatch = useDispatch();

	const {
		isAdmin, currentUser,
		savedSearchWithJobEnabled, setSavedSearchWithJobEnabled,
	} = useAuthContext();

	if (!currentUser) return null;
	if (!isAdmin) return null;

	return (
		<Space direction='vertical' size={20}>
			<SavedSearchesWithJobSwitch />
			<AdminToolsSectionContainer title='50inTech searches'>
				<Add50inTechSavedSearchesButton />
			</AdminToolsSectionContainer>
			<AdminToolsSectionContainer title='Recruiter saved searches'>
				<ComputeSavedSearchesAlertsButton />
			</AdminToolsSectionContainer>
			<AdminToolsSectionContainer title='Coaching'>
				{buildConfirmBtn({
					title: 'Add coaching dates',
					Icon: FaCalendar,
					dispatcher: adminActions.addCoachingCoachedAt,
					dispatch,
				})}
			</AdminToolsSectionContainer>
		</Space>
	);
};

export default AdminToolsSavedSearchesSection;
