import StaffUserMissingResumesPage from '../../../containers/staff/users/StaffMissingResumesPage';
import { PERMS_GRANT_STAFF } from '../../menu.utils';
import MenuBase from '../../MenuBase';
import { FileExclamationOutlined } from '@ant-design/icons';

export const StaffMissingResumesIcon = FileExclamationOutlined;

class StaffMissingResumesMenu extends MenuBase {
	static props = {
		name: 'staff-users-missing-resumes',
		path: '/staff/users/missing-resumes',
		label: 'Missing Resumes',
		icon: <StaffMissingResumesIcon />,
		iconLegacy: '',
		Component: StaffUserMissingResumesPage,
		perms: PERMS_GRANT_STAFF,
	};

	constructor() {
		super(StaffMissingResumesMenu.props);
	}
}

export default StaffMissingResumesMenu;
