import { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import ReactMarkdown from 'react-markdown';
import { SurveyNavigationContext } from '../providers/SurveyNavigationProvider';
import SurveyQuestionFooter from '../question/SurveyQuestionFooter';
import SurveyPageLayout from './SurveyPageLayout';
import { GENDERSCORE_PILLAR_OPTIONS_LIST } from '../../../../constants/genderscore-pillars';
import { useTheme } from '@emotion/react';
import LoadSpinner from '../../../app/LoadSpinner';
import {
	EVENT, buildEventLabel, buildEventSection, pushClick,
} from '../../../../contexts/EventPushProvider';
import { LocalDebug } from '../../../../utils/LocalDebug';

const SurveySection = () => {
	const className = 'SurveySection';

	const theme = useTheme();
	const navigate = useNavigate();
	const params = useParams();

	LocalDebug.logInfo({ className }, { params });

	const {
		eventContext,
		surveyConf,
		gotoPrevQuestion,
		gotoNextQuestion,
		appLang, t,
	} = useContext(SurveyNavigationContext);

	const eventProps = {
		...eventContext,
		...buildEventSection(EVENT.SECTION.SURVEY_SECTION),
	};

	const [section, setSection] = useState(null);
	const [pillar, setPillar] = useState(null);

	useEffect(() => {
		const newSection = surveyConf?.sections?.find?.((s) => s?.slug === params?.section);
		LocalDebug.logUseEffect({ className, effects: 'params, surveyConf?.sections' }, { newSection });
		setSection(newSection);
	}, [params, surveyConf?.sections]);

	useEffect(() => {
		setPillar(GENDERSCORE_PILLAR_OPTIONS_LIST
			.find((o) => (
				o?.label?.toLocaleLowerCase?.()
				=== section?.label?.toLocaleLowerCase?.()))
			?.value);
	}, [section]);

	const onPrev = () => {
		pushClick({ ...eventProps, ...buildEventLabel(EVENT.LABEL.ANSWER_PREVIOUS) });
		gotoPrevQuestion({ section, navigate });
	};

	const onSave = () => {
		pushClick({ ...eventProps, ...buildEventLabel(EVENT.LABEL.SECTION_START) });
		gotoNextQuestion({ section, navigate });
	};

	if (!section) {
		return (
			<SurveyPageLayout
				withHeader={true}
				withFooter={true}
			>
				<LoadSpinner />
			</SurveyPageLayout>
		);
	}

	return (
		<SurveyPageLayout
			withHeader={true}
			withFooter={true}
			cssClassNames={[
				...pillar ? [pillar] : [],
			]}
		>
			<div
				style={{
					display: 'flex',
					flexDirection: 'column',
					justifyContent: 'center',
					alignItems: 'flex-start',
					textAlign: 'left',
					maxWidth: 600,
					lineHeight: 1.4,
					margin: '0 auto',
				}}
			>
				{/* <h2
					style={{
						fontSize: 16,
						fontWeight: 800,
						color: '#555',
					}}
				>
					Part {surveyConf?.sections?.indexOf((s) => s.value === section.value) + 1}/{surveyConf?.sections?.length}
				</h2> */}

				{/* <Divider /> */}
				<p
					style={{
						fontSize: 16,
						fontWeight: 300,
						color: '#445',
					}}
				>
					<ReactMarkdown>
						{section?.details?.[appLang] || section?.details}
					</ReactMarkdown>
				</p>

			</div>

			<SurveyQuestionFooter
				disablePrev={section.index === 1}
				onPrev={onPrev}
				onSave={onSave}
				nextText={t('survey-footer.start-btn')}
			/>

		</SurveyPageLayout>
	);
};

export default SurveySection;
