/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useCallback } from 'react';

export const useDebouncedEffect = (func, deps, delay = 500) => {
	const callback = useCallback(func, deps);
	useEffect(() => {
		const handler = setTimeout(() => {
			callback();
		}, delay);

		return () => {
			clearTimeout(handler);
		};
	}, [callback, delay]);
};
