import { ENGLISH_LANG } from '../../../../constants/constant';
import CompanyFieldNotProvided from '../shared/CompanyFieldNotProvided';

const CompanyOneLinerDisplay = (
	{
		company,
		lang = ENGLISH_LANG,
	},
) => {
	const className = 'CompanyOneLinerDisplay';

	const field = 'oneliner';
	const value = company?.getTranslationFieldFallback(field, lang);

	if (!value) return <CompanyFieldNotProvided>One-liner</CompanyFieldNotProvided>;

	return (
		<div
			style={{
				fontSize: 14,
			}}
		>
			{value}
		</div>
	);
};

export default CompanyOneLinerDisplay;
