import { useTheme } from '@emotion/react';
import Table from '../../app/table/Table';
import TableColumns from '../../app/table/TableColumns';
import { GS_ACTION_COMPLETION_STATUS_OPTIONS, GS_ACTION_TIMEFRAME_OPTIONS, findActionPillar } from './action-plan';
import { GENDERSCORE_PILLAR_OPTIONS_LIST } from '../../../constants/genderscore-pillars';
import { Tag, Tooltip } from 'antd';
import GenderScorePillarLogoLabel from '../genderscore/pillars/GenderScorePillarLogoLabel';

const StaffCompanyActionPlanList = ({
	...props
}) => {
	const className = 'StaffCompanyActionPlanList';

	const theme = useTheme();

	const columns = [
		TableColumns.companyStubColumn(theme),
		TableColumns.jsonColumn(theme),
		TableColumns.docsCountColumn(theme, {
			title: 'Actions',
			dataIndex: 'actions',
			sorter: false,
			removeInColumnFilter: true,
			align: 'center',
			value: (value, row) => value?.length,
		}),
		TableColumns.docsCountColumn(theme, {
			title: 'Recos',
			dataIndex: 'actions',
			sorter: false,
			removeInColumnFilter: true,
			align: 'center',
			value: (value, row) => value?.map(({ recommendedStatus } = {}) => recommendedStatus === 'RECOMMENED_STATUS')?.length,
		}),
		{
			title: 'GenderScore Pillars',
			children: GENDERSCORE_PILLAR_OPTIONS_LIST
				.map((pillarOption) => TableColumns
					.docsCountColumn(theme, {
						title: (
							<Tooltip
								title={(
									<>{pillarOption.label}</>
								)}
							>
								<GenderScorePillarLogoLabel withLabel={false} pillar={pillarOption.value} />
							</Tooltip>
						),
						dataIndex: 'actions',
						sorter: false,
						removeInColumnFilter: true,
						align: 'center',
						value: (value, row) => value?.filter(({ pillarCategory } = {}) => (
							pillarCategory.some((c) => findActionPillar(c)?.value === pillarOption.value)
						))?.length,
					})),
		},
		{
			title: 'Completion Status',
			children: GS_ACTION_COMPLETION_STATUS_OPTIONS
				.map((completionStatusOption) => TableColumns
					.docsCountColumn(theme, {
						title: (
							<Tooltip
								title={(
									completionStatusOption.label
								)}
							>
								<Tag
									color={completionStatusOption.tagColor}
								>
									{completionStatusOption.tagIcon}
								</Tag>
							</Tooltip>
						),
						dataIndex: 'actions',
						sorter: false,
						removeInColumnFilter: true,
						align: 'center',
						value: (value, row) => value
							?.filter(({ completionStatus } = {}) => (
								completionStatus === completionStatusOption.value
							))?.length,
					})),
		},
		{
			title: 'Timeframe',
			children: GS_ACTION_TIMEFRAME_OPTIONS
				.map((timeframeOption) => TableColumns
					.docsCountColumn(theme, {
						title: (
							<Tooltip
								title={(
									timeframeOption.label
								)}
							>
								<Tag
									color={timeframeOption.tagColor}
								>
									{timeframeOption.tagIcon}
								</Tag>
							</Tooltip>
						),
						dataIndex: 'actions',
						sorter: false,
						removeInColumnFilter: true,
						align: 'center',
						value: (value, row) => value
							?.filter(({ timeframe } = {}) => (
								timeframe === timeframeOption.value
							))?.length,
					})),
		},
		// {
		// 	title: 'Action plan',
		// 	dataIndex: 'actions',
		// 	render: (value, row) => JSON.stringify(value || []),
		// },
	];

	return (
		<Table
			path={'/admin/company/action-plan'}
			columns={columns}
		/>
	);
};

export default StaffCompanyActionPlanList;
