import {
	Affix, Button, Skeleton, Space, Spin, Switch,
} from 'antd';
import { useContext, useEffect, useState } from 'react';
import RecruiterService from '../../services/recruiter';
import UserSavedSearchButton from './UserSavedSearchButton';
import { LocalDebug } from '../../utils/LocalDebug';
import { useAuthContext } from '../../hooks/useAuthContext';
import { FaPlus } from 'react-icons/fa';
import { UserSavedSearchContext } from './UserSavedSearchProvider';
import { NotificationContext } from '../app/notification/NotificationProvider';
import CompanyLogo from '../company/CompanyLogo';
import UserAvatar from '../user/widgets/UserAvatar';
import UserSavedSearchesButtonList from './UserSavedSearchesButtonList';
import SearchTalentsTabs from './SearchTalentsTabs';
import Callout from '../app/callout/Callout';
import { LOGO_50INTECH, SPOTLIGHT_DETAILS, SUGGESTED_BY_50_DETAILS } from '../../constants/constant';
import { useTheme } from '@emotion/react';
import BlockAdmin from '../app/blocker/BlockAdmin';
import ReactJsonDebug from '../app/debug/ReactJsonDebug';
import ReactJsonAdmin from '../app/debug/ReactJsonAdmin';
import { StaffToolsContext } from '../../contexts/StaffToolsProvider';
import SearchTalentsCustomFilters from './SearchTalentsCustomFilters';
import TextSearch from '../app/table/TextSearch';
import DataFetcherTotal from '../app/data/DataFetcherTotal';

const SearchTalentsAffixedHeader = (
	{
		user,
		refresh = 0,
		onEdit,
		onClone,
		onCreate,
		onSelect,
		textSearch,
		setTextSearch,
		dynamicSearch,
		dynamicSearchFields,
		dynamicSearchDefaultFields,
		setDynamicSearchDefaultFields,
		...props
	},
) => {
	const className = 'SearchTalentsAffixedHeader';
	const theme = useTheme();

	const { isSavedSearchTabsEnabled, isSavedSearchExcludeViewedEnabled } = useContext(StaffToolsContext);
	const {
		isLoading,
		savedSearches,
		selectedSavedSearch, setSelectedSavedSearch,
		updateSavedSearches,
		selectedPage, setSelectedPage,
		SUGGESTED_TALENTS_PAGE, MY_SEARCHES_PAGE, PAGES_LIST,
	} = useContext(UserSavedSearchContext);

	const { notifySuccess, notifyLoading, notifyError } = useContext(NotificationContext);
	const [savedSearches50, setSavedSearches50] = useState([]);
	const [savedSearchesUser, setSavedSearchesUser] = useState([]);
	const [layoutMode, setLayoutMode] = useState('tabs');

	const [isAffixed, setIsAffixed] = useState(false);

	useEffect(() => {
		setSavedSearches50(savedSearches?.filter((s) => s?.is50 === true));
		setSavedSearchesUser(savedSearches?.filter((s) => s?.is50 !== true));
	}, [savedSearches]);

	const onAffixChanged = (value) => {
		setIsAffixed(value);
	};

	return (
		<>
			<Affix
				offsetTop={0}
				style={{
					background: theme.color.bgPage,
					margin: '0 -24px',
					width: 'calc(100% + 48px)',
				}}
				onChange={onAffixChanged}
			>

				<Space
					direction='vertical'
					style={{
						width: '100%',
						// padding: '12px 24px',
						padding: `12px 24px ${layoutMode !== 'tabs' ? '' : (selectedPage === MY_SEARCHES_PAGE ? '8px' : '0px')}`,
						background: theme.color.bgPage,
						marginBottom: 10,
						boxShadow: isAffixed ? '0 6px 18px -8px rgba(0,0,0,.2)' : 'none',
					}}
				>
					{isSavedSearchTabsEnabled
					&& <SearchTalentsTabs/>
					}

					{selectedPage === MY_SEARCHES_PAGE && (
						<UserSavedSearchesButtonList
							// isAffixed={isAffixed}
							onCreate={onCreate}
							onEdit={onEdit}
							onClone={onClone}
							style={{ marginBottom: 10 }}
							// onSelect={onSelectSavedSearch}
							// refresh={isRefreshSavedSearches}
						/>
					)}
					<Space
						wrap={true}
						size={20}
						align='center'
						style={{
							position: 'relative',
							width: '100%',
							justifyContent: isSavedSearchExcludeViewedEnabled ? 'space-between' : 'end',
							marginTop: 12,
						}}
					>
						{
						/* Only show the custom filters (Exclude Viewed) if:
							- The option is enabled
							- There is a search selected
						*/
							isSavedSearchExcludeViewedEnabled
							&& selectedSavedSearch
							&& <SearchTalentsCustomFilters/>
						}

						<Space>
							{isLoading
								? <Skeleton.Input active style={{
									width: 80, height: 20, borderRadius: 5, marginBottom: 0,
								}}/>
								: selectedSavedSearch
								&& <>
									<TextSearch
										onChange={(input) => setTextSearch(input.target.value)}
										columnsFilterOptions={dynamicSearchFields}
										onChangeColumnsFilterOptions={setDynamicSearchDefaultFields}
										defaultColumnsFilterSelected={dynamicSearchDefaultFields}
										initialValue={textSearch}
									/>
									<DataFetcherTotal/>
								</>
							}
						</Space>
					</Space>
				</Space>
			</Affix>
		</>
	);
};

export default SearchTalentsAffixedHeader;
