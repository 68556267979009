import GridValueCard from '../../../app/card/GridValueCard';
import { NOT_PROVIDED_YET_LABEL } from '../../../../constants/constant';
import UserProfileDEIFitCardBody from './UserProfileDEIFitCardBody';
import { Space } from 'antd';

const UserProfileDEIFitCard = (
	{
		user,
		hideIfEmpty = true,
		notProvided = NOT_PROVIDED_YET_LABEL,
		children,
		...props
	},
) => {
	const title = 'DE&I Fit';
	const details = <Space direction='vertical'>
		<span>DE&I criteria that matter the most for this talent.</span>
		<span><strong>Tip:</strong> Use it as an icebreaker for your first message!</span>
	</Space>;

	return (user?.hasProfileDEICriteria() || !hideIfEmpty
		? <GridValueCard
			title={title}
			details={details}
			{...props}
		>
			{children || <UserProfileDEIFitCardBody user={user} notProvided={notProvided}/>}
		</GridValueCard>
		: null
	);
};

export default UserProfileDEIFitCard;
