import ResourceService from '../services/resource';
import UserService from '../services/user';
import { action } from './utils';
import { message } from 'antd';

export const updateOrCreateResource = (data) => {
	const key = JSON.stringify(data);
	message.loading({ content: 'Updating resource', key, duration: 10 });
	return action(async () => {
		try {
			// await ResourceService.updateOrCreateResource(JSON.parse(JSON.stringify(data)));
			await ResourceService.updateOrCreateResource(data);
			message.success({ content: 'Resource updated', key, duration: 1 });
		} catch (error) {
			message.error({ content: error.message, key });
		}
	});
};
export const uploadFileResource = (data) => {
	const key = JSON.stringify(data);
	message.loading({ content: 'Updating resource', key, duration: 10 });
	return action(async () => {
		try {
			const result = await ResourceService.uploadFile(data);
			message.success({ content: 'File uploaded', key, duration: 1 });
			return result.data;
		} catch (error) {
			message.error({ content: error.message, key });
		}
	});
};

export const deleteResource = (resourceId) => {
	return action(async () => {
		try {
			await ResourceService.deleteResource(resourceId);
			message.success({ content: 'Resource deleted', resourceId, duration: 1 });
		} catch (error) {
			message.error({ content: error.message, resourceId });
		}
	});
};
export const searchUser = (params) => {
	return action(async () => {
		try {
			const result = await UserService.findAll(params, '/search');
			return result.data;
		} catch (error) {
			message.error({ content: error.message });
		}
	});
};
