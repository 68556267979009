/* eslint-disable import/no-named-as-default-member */
import { useTheme } from '@emotion/react';
import { useState } from 'react';
import {
	Button, message, Popconfirm, Popover, Space, Tooltip, Typography,
} from 'antd';
import Table from '../app/table/Table';
import TableColumns from '../app/table/TableColumns';
import { displayHtml, displayObjectID, documentId } from '../../utils/common';
import UserDrawerProfile from '../user/UserDrawerProfile';
import { useAuthContext } from '../../hooks/useAuthContext';
import { retrySendMessage } from '../../actions/messages';
import { findTemplateDataByTemplateId } from '../../constants/mailjet/mailjetTemplates';
import UserMailingService from '../../services/user-mailing';
import UserMailingModel from '../../models/UserMailingModel';
import Link from '../app/Link';
import { FaCommentDots } from 'react-icons/fa';
import { MAILING_STATUS_FAILED_ID, MAILING_STATUS_RETRIED_ID } from '../../constants/mailing';
import CheckboxTable from '../app/table/CheckboxTable';
import UserMessageModel from '../../models/UserMessageModel';
import { refreshUserMailingMailjetData, retrySendUserMailing } from '../../actions/user-mailing';
import ObjectIdDisplayer from '../app/ObjectIdDisplayer';
import LangFlagColorTag from '../app/tag/LangFlagColorTag';
import { LocalDebug } from '../../utils/LocalDebug';

const UserMailingTable = (
	{
		onDataLoaded,
		path = '/user-mailing',
		filterOptions = {},
		isRefresh = 0,
		onSearchDataColumn,
		onToggleIsActive,
		onEditArticle,
		isEditor,
		...props
	},
) => {
	const className = 'UserMailingTable';

	const theme = useTheme();

	const { isAdmin, isStaff, isStaffView } = useAuthContext();

	const [isRefreshDataLayout, setIsRefreshDataLayout] = useState(0);
	const [openProfile, setOpenProfile] = useState(false);
	const [activeTab, setActiveTab] = useState(null);
	const [userSelected, setUserSelected] = useState(null);

	const lineStyles = {
		color: theme.color.grey,
		fontSize: 10,
		lineHeight: 1.1,
		// height: 10
	};
	const replaceFrom = (s) => {
		return s
			.replace(/^in /g, '')
			.replace(/a year/g, '1y')
			.replace(/ years/g, 'y')
			.replace(/a month/g, '1mo')
			.replace(/ months/g, 'mo')
			.replace(/a day/g, '1d')
			.replace(/ days/g, 'd')
			.replace(/an hour/g, '1h')
			.replace(/ hours/g, 'h')
			.replace(/a minute/g, '1m')
			.replace(/ minutes/g, 'm')
			.replace(/a few seconds/g, ' <1min')
			.replace(/ seconds/g, 's');
	};

	const handleOpenUserProfile = (user, tab) => {
		setUserSelected(user);
		setActiveTab(tab);
		setOpenProfile(true);
	};

	const handleCloseProfile = () => {
		setUserSelected(null);
		setActiveTab(null);
		setOpenProfile(false);
	};

	const handleMailjetDataRefresh = async (mailingId) => {
		const method = 'handleSync';
		message.loading({ key: method, content: 'Refreshing data from Mailjet...', duration: 0 });

		try {
			await refreshUserMailingMailjetData({
				mailingId,
				force: true,
			});
			message.success({ key: method, content: 'Data refreshed!' });
			setIsRefreshDataLayout((p) => p + 1);
		} catch (error) {
			const defaultError = 'Could not refresh the data';
			const errorMsg = error?.response?.data?.error?.message;
			message.error({ key: method, content: errorMsg || defaultError });
		}
	};

	const handleRetrySendUserMessage = async (userMailing) => {
		const method = 'handleSendRetry';
		message.loading({ key: method, content: 'Retry sending user message...', duration: 0 });
		const result = await retrySendMessage({
			id: userMailing?.linkedDocument?.documentId,
			userMailingId: documentId(userMailing),
		});
		message.success({ key: method, content: 'User message sending retried!' });
		setIsRefreshDataLayout((p) => p + 1);
	};

	const handleRetrySendUserMailing = async (userMailing) => {
		const method = 'handleRetrySendUserMailing';
		message.loading({ key: method, content: 'Retry sending user mailing...', duration: 3 });
		try {
			const result = await retrySendUserMailing({ mailingId: documentId(userMailing) });
			message.success({ key: method, content: 'User mailing sending retried!' });
		} catch (error) {
			// LocalDebug.logError({ className, method, error });
		}
		setIsRefreshDataLayout((p) => p + 1);
	};

	const senderAvatarCol = TableColumns.userAvatarColumn(theme, {
		title: <Tooltip title='User who was identified as the sender (sender field)'>From</Tooltip>,
		removeInColumnFilter: true,
		dataIndex: 'sender',
	});

	const senderCompanyLogoCol = TableColumns.companyLogoColumn(theme, {
		title: 'Company',
		dataIndex: ['sender', 'company'],
		removeInColumnFilter: true,
		render: (value, row) => {
			if (value) return TableColumns.companyLogoColumn(theme).render(value, row);
			return null;
		},
	});

	const recipientAvatarCol = TableColumns.userAvatarColumn(theme, {
		title: <Tooltip title='User who received the sending (recipient field)'>To</Tooltip>,
		dataIndex: 'recipient',
		withDropdown: true,
		sorter: false,
	});

	const recipientStubCol = TableColumns.userStubColumn(theme, {
		title: <Tooltip title='User who received the sending (recipient field)'>To</Tooltip>,
		dataIndex: 'recipient',
		withDropdown: true,
		sorter: false,
	});

	const userTriggerAvatarCol = TableColumns.userAvatarColumn(theme, {
		title: <Tooltip title='User who triggered the sending (userTrigger field)'>By</Tooltip>,
		dataIndex: 'userTrigger',
		withDropdown: true,
		sorter: false,
	});

	const messageCol = {
		title: 'Message',
		dataIndex: ['sendPayload', 'Variables', 'messageBody'],
		removeInColumnFilter: true,
		sorter: false,
		align: 'center',
		render: (value, row) => (
			row?.messageBody
				? <Popover title={<b>Message</b>}
					content={
						<div style={{
							maxWidth: '600px', maxHeight: '60vh', overflow: 'auto', fontSize: 14,
						}}>
							{displayHtml(row?.messageBody)}
						</div>}>
					<Button type='link' icon={<FaCommentDots style={{ marginBottom: -2 }} />} />
				</Popover>
				: null
		),
		...TableColumns.columnFixedWidth(70),
	};

	const messageIdCol = {
		title: 'UserMessage',
		dataIndex: ['linkedDocument', 'documentId'],
		removeInColumnFilter: true,
		sorter: true,
		align: 'center',
		render: (value, row) => (
			// eslint-disable-next-line no-nested-ternary
			row?.linkedDocument?.modelName === 'UserMessage'
				? (
					row?.messageBody
						? (
							<Popover title={<b>Message (ID: {value})</b>}
								content={
									<div style={{
										maxWidth: '600px',
										maxHeight: '60vh',
										overflow: 'auto',
										fontSize: 14,
									}}>
										{displayHtml(row?.messageBody)}
									</div>}>
								<ObjectIdDisplayer id={value} />
							</Popover>
						)
						: (
							<ObjectIdDisplayer
								id={value}
								withTooltip={true}
							/>
						)
				)
				: null

		),
		...TableColumns.columnFixedWidth(120),
	};

	const mailingIdCol = {
		title: 'UserMailing Id',
		dataIndex: ['_id'],
		removeInColumnFilter: true,
		sorter: true,
		align: 'center',
		render: (value, row) => (
			<ObjectIdDisplayer
				id={value}
				withTooltip={true}
			/>),
		...TableColumns.columnFixedWidth(120),
	};

	const templateCol = {
		title: 'Template',
		dataIndex: 'templateId',
		align: 'left',
		removeInColumnFilter: true,
		sorter: false,
		render: (templateId, row) => {
			const templateData = findTemplateDataByTemplateId(templateId);
			if (!templateData) return `No template found for ID ${templateId}`;
			const label = templateData?.label || 'No label defined';
			return (
				<>
					<Tooltip
						title={`Template key: ${templateData.templateKey}`}
						style={{ display: 'inline-block' }}
					>
						<Space direction='vertical' style={{ width: '100%', lineHeight: 1.2 }} size={2}>
							<Typography.Text strong>{label}</Typography.Text>
							{templateData.lang && <LangFlagColorTag lang={templateData.lang} />}
							<Typography.Text type='secondary'>{templateId}</Typography.Text>
						</Space>
					</Tooltip>
				</>
			);
		},
		...TableColumns.columnFixedWidth(180),
	};

	const hasPayloadCol = {
		title: 'Payload?',
		dataIndex: 'payload',
		align: 'center',
		removeInColumnFilter: true,
		sorter: false,
		render: (value, row) => TableColumns.booleanColumn(theme).render(!!row?.payload, {}),
		...TableColumns.columnFixedWidth(90),
	};

	const recipientTypeCol = {
		title: 'Type',
		dataIndex: 'recipientType',
		align: 'center',
		removeInColumnFilter: true,
		sorter: false,
		...TableColumns.columnFixedWidth(60),
	};

	const createdAtCol = TableColumns.createdAtColumn(theme);
	const updatedAtCol = TableColumns.updatedAtColumn(theme);

	const statusCol = {
		title: 'Status',
		dataIndex: 'status',
		align: 'center',
		sorter: true,
		removeInColumnFilter: true,
		render: (value, row) => row?.renderStatusTagAndHistoryHover(theme),
		...TableColumns.columnFixedWidth(140),
	};
	const retriedCol = {
		title: 'Retried',
		dataIndex: 'retried',
		align: 'center',
		sorter: true,
		removeInColumnFilter: true,
		render: (value, row) => {
			if (![MAILING_STATUS_FAILED_ID, MAILING_STATUS_RETRIED_ID].includes(row.status)) {
				return <Typography.Text type='secondary'>
					<i>N/A, not failed</i>
				</Typography.Text>;
			}
			if (!row?.sendPayload && !row?.apiPost?.payloadFull) {
				return <Typography.Text type='secondary'>
					Cannot be retried: <br /> no payload
				</Typography.Text>;
			}
			if (row?.recipientType !== 'to') {
				return <Typography.Text type='secondary'>
					Cannot be retried: <br /> Not a &apos;to&apos; recipient
				</Typography.Text>;
			}
			const payload = row?.apiPost?.payloadFull || row?.sendPayload;
			if (payload?.To?.[0]?.Email !== row?.recipient?.email) {
				return <Typography.Text type='secondary'>
					Cannot be retried: <br /> Not the main &apos;to&apos; recipient
				</Typography.Text>;
			}
			return value
				? <Space direction='horizontal'>
					<CheckboxTable
						isChecked={value.status !== MAILING_STATUS_FAILED_ID}
						style={{ marginBottom: -2 }}
					/>
					<Typography.Text type='secondary'>
						Retried & {value.status !== MAILING_STATUS_FAILED_ID ? 'sent' : 'failed'} on
						<br/>
						<ObjectIdDisplayer
							id={documentId(value)}
							withTooltip={true}
						/>
					</Typography.Text>
				</Space>
				: <Typography.Text type='secondary'>
					Not retried yet <br/>
					{retrySendLink(row, <>Retry&nbsp;&gt;</>)}
				</Typography.Text>;
		},
		...TableColumns.columnFixedWidth(200),
	};

	const jsonCol = TableColumns.jsonColumn(theme, TableColumns.columnFixedWidth(50));

	const mailjetDataRefreshCol = TableColumns.mailjetDataRefreshColumn(theme, {
		dataIndex: ['refreshedAt'],
		value: (v, row) => row,
		onClick: (row) => { handleMailjetDataRefresh(documentId(row)); },
	});

	const retrySendLink = (row, label = 'Retry send >') => (
		<Popconfirm
			title={(
				<div style={{ maxWidth: 300 }}>
					<b>Are you sure?</b>
					<br />
				Beware, it will send again this email.
				</div>
			)}
			onConfirm={() => { handleRetrySendUserMailing(row); }}
		>
			<Link>{label}</Link>
		</Popconfirm>
	);

	const resendCol = {
		title: <>Send<br />again</>,
		align: 'center',
		sorter: false,
		render: (value, row) => (
			<>
				{retrySendLink(row, <>Send again&nbsp;&gt;</>)}
			</>
		),
		...TableColumns.columnFixedWidth(100),
	};

	const columns = [
		jsonCol,
		senderAvatarCol,
		recipientTypeCol,
		recipientStubCol,
		userTriggerAvatarCol,
		templateCol,
		statusCol,
		retriedCol,
		messageIdCol,
		mailingIdCol,
		// messageCol,
		hasPayloadCol,
		createdAtCol,
		updatedAtCol,
		mailjetDataRefreshCol,
	];

	return (
		<>
			<Table
				path={path}
				ItemClass={UserMailingModel}
				onDataLoaded={onDataLoaded}
				columns={columns}
				filterOptions={filterOptions}
				isRefresh={isRefresh + isRefreshDataLayout}
				onSearchDataColumn={onSearchDataColumn}
				showTotal={(total) => `Total ${total} items`}
				limit={10}
				rowClassName={(row, index) => (row.is50Content === false ? 'rowDisabled' : '')}
				withDynamicSearch
				{...props}
			/>

			<UserDrawerProfile
				user={userSelected}
				activeTab={activeTab}
				open={openProfile}
				onClose={handleCloseProfile}
			/>
		</>
	);
};

export default UserMailingTable;
