import { Space } from 'antd';
import { useTheme } from '@emotion/react';
import { useContext } from 'react';
import { FaTicketAlt } from 'react-icons/fa';
import { AppDataContext } from '../../../../contexts/AppDataProvider';
import { useAuthContext } from '../../../../hooks/useAuthContext';
import Callout from '../../../app/callout/Callout';
import Link from '../../../app/Link';
import AdminToolsSectionContainer from '../AdminToolsSectionContainer';
import CopyClipboardLink from '../../../app/button/CopyClipboardLink';
import AdminCommandGetButton from '../AdminCommandGetButton';

const AppSettingVarToggler = ({
	title = <><b>AppSettingVarToggler</b></>,
	appSettingKey = 'appSettingKey',
	value = false,
	content,
	extraFields,
	disable = { content: false },
}) => {
	const theme = useTheme();

	return (
		<Callout
			style={{ borderLeft: `10px solid ${theme.color[value ? 'darkturquoise' : 'redLighter']}` }}
			icon='☑️'
			withFeedback={false}
		>
			<div>
				{title} (<code>{appSettingKey}</code>) is currently <b>{value ? '✅ enabled' : '🚫 disabled'}</b>
				<br />
				{value
					? (
						<>
							To disable it, set <code>{JSON.stringify({ ...disable })}</code> in the related document
							inside the <b>app-setting</b> collection.
							<br/>You can find the document by running this query:
							<br/>
							<CopyClipboardLink
								content={{
									key: appSettingKey,
								}}
								copyAsJSON={true}
							/>
						</>
					)
					: (
						<>
							To enable it, insert the following document inside the <b>app-setting</b> collection
							(or modify the existing one to <code>content: false</code>):
							<br/>
							<CopyClipboardLink
								content={{
									key: appSettingKey,
									content,
									...extraFields,
								}}
								copyAsJSON={true}
							/>
						</>
					)
				}
			</div>
		</Callout>
	);
};

const Apps1448Tools = () => {
	const theme = useTheme();

	const {
		isAdmin, currentUser,
	} = useAuthContext();

	const {
		apps1448ToolsVisible,
		setApps1448ToolsVisible,
		appSettings,
	} = useContext(AppDataContext);

	if (!currentUser) return null;

	if (!isAdmin) return null;

	const appSettingKey = 'apps1448ToolsVisible';
	const appSettingValue = apps1448ToolsVisible;
	const appSettingSetter = setApps1448ToolsVisible;

	return (
		<AdminToolsSectionContainer
			title={
				<>
					<a
						target='_blank'
						href='https://50intech.atlassian.net/browse/APPS-1448'
						rel="noreferrer">
						APPS-1448
					</a>
					&nbsp;
					GenderScore Survey in-app
				</>
			}
			Icon={() => <FaTicketAlt style={{ marginBottom: -4 }} />}
		>
			{appSettingValue
				? (
					<Space direction='vertical'>

						<div>
							<Callout
								style={{ background: theme.color.lilacLightest2 }}
								icon='⚠️'
								withFeedback={false}
							>
								<div>
									Once the content of this panel has been used,
									the buttons should be hidden to avoid any errors.
									<br />
									They are currently visible via the presence of
									an <b>app-setting</b> document
									containing <code style={{ fontSize: 12, color: 'black', background: 'transparent' }}>{`{ key: '${appSettingKey}', content: true }`}</code>.
									<br/>To hide the buttons below, you can thus either:
									<ul>
										<li>delete this document</li>
										<li>update by setting <code style={{ fontSize: 12, color: 'black', background: 'transparent' }}>{'content: false'}</code></li>
										<li>update it by adding <code style={{ fontSize: 12, color: 'black', background: 'transparent' }}>{'isActive: false'}</code></li>
									</ul>
									<Link onClick={() => appSettingSetter(false)}>
										Hide the buttons for this session (refresh to show them again)
									</Link>
								</div>
							</Callout>
						</div>

						<AdminCommandGetButton
							title={'Set GenderScore surveys "companyInfo" content from Outgrow'}
							path='/admin/company/remap-genderscore-survey-company-info'
						/>

						<AdminCommandGetButton
							title={'Create collection "company-genderScore-stat"'}
							path='/admin/company/remap-genderscore-survey-monthly-stat'
						/>

					</Space>
				)
				: (
					<div>
						<Callout
							style={{ background: theme.color.lilacLightest2 }}
							icon='⚠️'
							withFeedback={false}
						>
							<div>
								Buttons are hidden by default.<br/>
								To display them, insert the following document
								inside <b>app-setting</b> collection:
								<br/>
								<CopyClipboardLink
									content={{
										key: 'apps1448ToolsVisible',
										content: true,
									}}
									copyAsJSON={true}
								/>
							</div>
						</Callout>
					</div>

				)
			}

		</AdminToolsSectionContainer>

	);
};

export default Apps1448Tools;
