import { Modal, Space, Typography } from 'antd';
import { useDispatch } from 'react-redux';
import { useTheme } from '@emotion/react';
import {
	FaCheck, FaEdit, FaLock, FaMinusCircle, FaPlusCircle, FaRegTrashAlt, FaSync,
} from 'react-icons/fa';
import * as adminActions from '../../../actions/admin';
import AddButton from '../../app/button/AddButton';
import { request } from '../../../services/api';
import { useAuthContext } from '../../../hooks/useAuthContext';
import AtsLogo from '../../ats/AtsLogo';
import { mixColors } from '../../../utils/common';
import AdminToolsSection from './AdminToolsSectionContainer';
import AdminConfirmRequestBtn from './AdminConfirmRequestBtn';

const AdminToolsAtsSection = () => {
	const theme = useTheme();
	const dispatch = useDispatch();
	const { currentUser } = useAuthContext();

	const confirmDispatch = (label, callback) => {
		Modal.confirm({
			title: <>
				<div><b>{label}</b></div>
				Are you sure you want to proceed?</>,
			okText: 'Yes',
			cancelText: 'No',
			onOk() {
				callback();
			},
		});
	};

	const btnProps = { icon: null, color: theme.color.darkturquoise };

	const buildConfirmBtn = ({
		title, Icon, confirmText, dispatcher, styles,
	}) => <AddButton
		{...btnProps}
		{...styles}
		title={title}
		icon={<Icon style={{ marginBottom: -2 }}/>}
		onClick={
			() => confirmDispatch(
				confirmText || title,
				() => { dispatch(dispatcher()); },
			)
		}/>;

	return (
		<AdminToolsSection title='ATS' Icon={FaSync}>
			<Space direction='vertical' size={10}>

				<Space direction='horizontal'>
					<AdminConfirmRequestBtn
						title='Remap candidate stages' Icon={FaSync}
						request={() => request.get('/admin/remap-candidate-stages')}
					/>
					<AdminConfirmRequestBtn
						title='Check pipeline/stages' Icon={FaCheck}
						request={() => request.get('/admin/checkAtsPipelineStages')}
					/>
				</Space>

				<Space direction='horizontal'>
					{buildConfirmBtn({
						theme, title: 'Initialize pipelines', Icon: FaSync, dispatcher: adminActions.initializeAtsPipelines,
					})}
					{buildConfirmBtn({
						theme,
						title: 'Initialize archive reasons',
						Icon: FaSync,
						dispatcher: adminActions.initializeAtsArchiveReasons,
					})}
				</Space>
				<Space direction='horizontal'>
					{buildConfirmBtn({
						theme, title: 'Encrypt ATS API Keys', Icon: FaSync, dispatcher: adminActions.encryptAtsApiKeys,
					})}
				</Space>
				{
					[
						{
							logo: <AtsLogo ats='50intech'/>,
							title: 'Demo ATS Classic (No ATS or ATS not managed yet)',
							companyName: 'Demo ATS Classic',
							createCompany: () => { return adminActions.addDemoCompany('classic'); },
							createAccess: () => { return adminActions.addDemoAccess('classic'); },
							removeAccess: () => { return adminActions.removeDemoAccess('classic'); },
							fakeSourcings: () => { return adminActions.fakeDemoSourcings('classic'); },
							cleanSourcings: () => { return adminActions.cleanDemoSourcings('classic'); },
						},
						{
							logo: <AtsLogo ats='lever'/>,
							title: 'Demo ATS Lever',
							companyName: 'Demo ATS Lever',
							createCompany: () => { return adminActions.addDemoCompany('lever'); },
							createAccess: () => { return adminActions.addDemoAccess('lever'); },
							removeAccess: () => { return adminActions.removeDemoAccess('lever'); },
							fakeSourcings: () => { return adminActions.fakeDemoSourcings('lever'); },
							cleanSourcings: () => { return adminActions.cleanDemoSourcings('lever'); },
						},
						{
							logo: <AtsLogo ats='greenhouse'/>,
							title: 'Demo ATS GreenHouse',
							companyName: 'Demo ATS GreenHouse',
							createCompany: () => { return adminActions.addDemoCompany('greenhouse'); },
							createAccess: () => { return adminActions.addDemoAccess('greenhouse'); },
							removeAccess: () => { return adminActions.removeDemoAccess('greenhouse'); },
							fakeSourcings: () => { return adminActions.fakeDemoSourcings('greenhouse'); },
							cleanSourcings: () => { return adminActions.cleanDemoSourcings('greenhouse'); },
						},
						{
							logo: <AtsLogo ats='smartrecruiters'/>,
							title: 'Demo ATS SmartRecruiters',
							companyName: 'Demo ATS SmartRecruiters',
							createCompany: () => { return adminActions.addDemoCompany('smartrecruiters'); },
							createAccess: () => { return adminActions.addDemoAccess('smartrecruiters'); },
							removeAccess: () => { return adminActions.removeDemoAccess('smartrecruiters'); },
							fakeSourcings: () => { return adminActions.fakeDemoSourcings('smartrecruiters'); },
							cleanSourcings: () => { return adminActions.cleanDemoSourcings('smartrecruiters'); },
						},
						{
							logo: <AtsLogo ats='teamtailor'/>,
							title: 'Demo ATS TeamTailor',
							companyName: 'Demo ATS TeamTailor',
							createCompany: () => { return adminActions.addDemoCompany('teamtailor'); },
							createAccess: () => { return adminActions.addDemoAccess('teamtailor'); },
							removeAccess: () => { return adminActions.removeDemoAccess('teamtailor'); },
							fakeSourcings: () => { return adminActions.fakeDemoSourcings('teamtailor'); },
							cleanSourcings: () => { return adminActions.cleanDemoSourcings('teamtailor'); },
						},
						{
							logo: <AtsLogo ats='workable'/>,
							title: 'Demo ATS Workable',
							companyName: 'Demo ATS Workable',
							createCompany: () => { return adminActions.addDemoCompany('workable'); },
							createAccess: () => { return adminActions.addDemoAccess('workable'); },
							removeAccess: () => { return adminActions.removeDemoAccess('workable'); },
							fakeSourcings: () => { return adminActions.fakeDemoSourcings('workable'); },
							cleanSourcings: () => { return adminActions.cleanDemoSourcings('workable'); },
						},
						{
							logo: <AtsLogo ats='ashby'/>,
							title: 'Demo ATS Ashby',
							companyName: 'Demo ATS Ashby',
							createCompany: () => { return adminActions.addDemoCompany('ashby'); },
							createAccess: () => { return adminActions.addDemoAccess('ashby'); },
							removeAccess: () => { return adminActions.removeDemoAccess('ashby'); },
							fakeSourcings: () => { return adminActions.fakeDemoSourcings('ashby'); },
							cleanSourcings: () => { return adminActions.cleanDemoSourcings('ashby'); },
						},
					].map((demo, index) => (
						<Space key={index} direction='vertical' size={0} style={{
							padding: 10,
							borderRadius: 10,
							marginTop: 10,
							background: mixColors(theme.color.darkturquoise, theme.color.white, 90),
						}}>
							<Typography.Title level={4} style={{ width: '100%' }}>
								{demo.logo} {demo.title}
							</Typography.Title>
							<Space direction='horizontal'>
								{demo.createCompany && buildConfirmBtn({
									theme,
									title: 'Add company',
									confirmText: `Create '${demo.companyName}' company`,
									Icon: FaPlusCircle,
									dispatcher: demo.createCompany,
								})}
								{demo.createAccess && buildConfirmBtn({
									theme,
									title: 'Add access',
									confirmText: `Create access for '${demo.companyName}' company`,
									Icon: FaPlusCircle,
									dispatcher: demo.createAccess,
								})}
								{demo.removeAccess && buildConfirmBtn({
									theme,
									title: 'Remove access',
									confirmText: `Remove access for '${demo.companyName}' company`,
									Icon: FaMinusCircle,
									dispatcher: demo.removeAccess,
								})}
								{demo.fakeSourcings && buildConfirmBtn({
									theme,
									title: 'Fake sourcings',
									confirmText: `Fake sourcings for '${demo.companyName}' company`,
									Icon: FaEdit,
									dispatcher: demo.fakeSourcings,
								})}
								{demo.cleanSourcings && buildConfirmBtn({
									theme,
									title: 'Clean sourcings',
									confirmText: `Clean sourcings for '${demo.companyName}' company`,
									Icon: FaRegTrashAlt,
									dispatcher: demo.cleanSourcings,
								})}
							</Space>
						</Space>
					))
				}
				<Space direction='horizontal' style={{ marginTop: 10 }}>All ATS</Space>
				<Space direction='horizontal'>
					{buildConfirmBtn({
						theme, title: 'Sync all sourcings', Icon: FaSync, dispatcher: adminActions.syncAllSourcings,
					})}
				</Space>
			</Space>
		</AdminToolsSection>
	);
};

export default AdminToolsAtsSection;
