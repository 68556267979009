/* eslint-disable import/prefer-default-export */
import { COMPANY_RECRUITER_LOG } from '../constants/company-recruiter-log';
import { checkCurrentCompanyRecruiter, dateDiffFromNow, documentId } from '../utils/common';
import version from '../autoBuildVersion';
import { LocalDebug } from '../utils/LocalDebug';
import LoggingService from '../services/logging';
import { history } from '../browserHistory';
import { pushEvent } from '../contexts/EventPushProvider';

const className = 'logging';

export const writeRecruiterLog = async (action = '', options = {}, value = {}) => {
	const method = 'writeRecruiterLog';
	const {
		userInfo,
		companyContext,
	} = checkCurrentCompanyRecruiter();

	const location = history?.location;
	LocalDebug.logNull({ className, method }, `WRITE ${action} (${location?.pathname?.toString?.()})`, options);

	try {
		const logInfo = {
			version,
			action,
			userId: documentId(userInfo),
			companyId: userInfo?.companyId,
			companyContext,
			host: window.location.origin,
			location,
			value,
			...options,
		};

		// TODO: gradually replace the events managed by these calls
		pushEvent({
			event: action,
			companyId: userInfo?.companyId,
			userId: userInfo?.slug,
			companyContext,
			value,
			...options,
		});

		// save log
		const { data: { lastSessionLog } } = await LoggingService.writeRecruiter(logInfo);

		// check last session log
		if (action !== COMPANY_RECRUITER_LOG.SESSION) {
			const lastLog = dateDiffFromNow(lastSessionLog?.createdAt);
			LocalDebug.logNull(
				{ className, method },
				{ lastSessionLog: lastSessionLog?.createdAt, lastLog },
			);
			// create new session log when last session log >= 1 hour
			if (lastLog > 0 || !lastSessionLog) {
				writeRecruiterLog(COMPANY_RECRUITER_LOG.SESSION);
			}
		} else {
			writeRecruiterLog(COMPANY_RECRUITER_LOG.DISPLAY);
		}
	} catch (e) {
		console.log(e);
	}
	// }
};
