import StaffMenu from './staff/StaffMenu';
import roles from '../config/roles';
import { BUSINESS_PLAN_GENDERSCORE_GROUP_VALUES } from '../constants/property/business-plan';
import { menuBuilder as UMSPlansMenuBuilder } from './menu-ums-plans';
import { LocalDebug } from '../utils/LocalDebug';
import { PERM_GRANT_TYPE } from './menu.utils';
import { PERMS_UMS_ENABLED } from './ums/ums-utils';
import ConfigureMenu from './configure/ConfigureMenu';
import ConfigureUsersMenu from './configure/ConfigureUsersMenu';
import ConfigureCompanyPageMenu from './configure/ConfigureCompanyPageMenu';
import ConfigureCompanySettingsMenu from './configure/ConfigureCompanySettingsMenu';
import GenderscoreMenu from './genderscore/GenderscoreMenu';

const className = 'menu-gs-plans';

const staffOnlyPermissions = {
	perms: [
		{
			type: PERM_GRANT_TYPE,
			plans: [...BUSINESS_PLAN_GENDERSCORE_GROUP_VALUES],
			roles: [roles.STAFF],
		},
	],
};

export const menuBuilder = ({
	isDocumentAdmin = false,
	isDocumentEditorOnly = true,
	isGenderScoreEnabled = false,
	isGenderScoreFilled = false,
	isGenderScoreSurveyEnabled = false,
	isUMSEnabled = false,
	isUMSSurveyEnabled = false,
	isUMSAdmin = false,
	isUMSManager = false,
	isUMSEmployee = false,
	isUMSManagerSurveyFilled = false,
	isUMSEmployeeSurveyFilled = false,
} = {
	isDocumentAdmin: false,
	isDocumentEditorOnly: true,
	isGenderScoreEnabled: false,
	isGenderScoreFilled: false,
	isGenderScoreSurveyEnabled: false,
	isUMSEnabled: false,
	isUMSSurveyEnabled: false,
	isUMSAdmin: false,
	isUMSManager: false,
	isUMSEmployee: false,
	isUMSManagerSurveyFilled: false,
	isUMSEmployeeSurveyFilled: false,
}) => {
	let menuUMS;
	let companyPageItem;
	let configureCompanyPageItem;
	let configureDivider;

	LocalDebug.logNull({ className, method: 'menuBuilder' }, { isGenderScoreEnabled, isDocumentEditorOnly });

	if (isDocumentEditorOnly) {
		companyPageItem = (new ConfigureCompanyPageMenu()).build({ isRoot: true });
	} else {
		// GS Dashboard is always the root, regardless of the flag isGenderScoreFilled
		configureCompanyPageItem = (new ConfigureCompanyPageMenu()).build();
		configureDivider = { perms: [] };
		companyPageItem = (new ConfigureCompanyPageMenu()).build();

		menuUMS = [
			{ perms: PERMS_UMS_ENABLED },
			...UMSPlansMenuBuilder({
				isUMSAdmin,
				isUMSManager,
				isUMSEmployee,
				isUMSEnabled,
				isUMSSurveyEnabled,
				isUMSManagerSurveyFilled,
				isUMSEmployeeSurveyFilled,
				withRoot: false,
				withFullMenu: false,
			}),
		];
	}

	const items = [
		...GenderscoreMenu.props.children({ isDocumentAdmin, isGenderScoreSurveyEnabled }),
		configureDivider,
		companyPageItem,
		...menuUMS,
		{
			...(new ConfigureMenu()).build(),
			children: [
				(new ConfigureUsersMenu()).build(staffOnlyPermissions),
				configureCompanyPageItem,
				(new ConfigureCompanySettingsMenu()).build(staffOnlyPermissions),
			],
			...staffOnlyPermissions,
		},
		{ perms: StaffMenu?.props?.perms || [] },
		(new StaffMenu()).build(),
	];
	return items;
};

export default { menuBuilder };
