import { useTheme } from '@emotion/react';
import PageLayout from '../../../components/app/layout/PageLayout';
import StaffGSActionsMenu from '../../../menu/staff/genderscore/StaffGSActionsMenu';
import { useEffect, useState } from 'react';
import { request } from '../../../services/api';
import { LocalDebug } from '../../../utils/LocalDebug';
import CompanyActionContent from '../../../components/company/actionPlan/action/CompanyActionContent';

const StaffGenderScoreActionsPage = () => {
	const className = 'StaffGenderScoreActionsPage';

	const theme = useTheme();
	const [actions, setActions] = useState([]);

	useEffect(() => {
		const launch = async () => {
			try {
				const result = await request.get('/admin/genderscore/actions');
				LocalDebug.logInfo({ className, method: 'launch' }, { result });
				setActions(result?.data?.results);
			} catch (e) {

			}
		};
		launch();
	}, []);

	return (
		<PageLayout
			MenuClass={StaffGSActionsMenu}
		>
			{actions?.slice(0, 10)?.map?.((action, index) => (
				<div
					// className='action-modal'
					key={index}
					style={{
						position: 'relative',
						border: '1px solid #ccc',
						borderRadius: 20,
						width: 1180,
						maxHeight: 'calc(100vh - 80px)',
						overflow: 'hidden',
						marginBottom: 30,
						marginLeft: -24,
					}}
				>
					<div
						// className='modal-container-body'
						style={{
							// padding: '20px 24px',
						}}
					>
						<CompanyActionContent
							action={action}
							style={{
								leftSide: {
									// width: 'calc(100% + 40px)',
									// opacity: 0.5,
								},
								rightSide: {
									// width: 'calc(100% + 40px)',
									// opacity: 0.5,
								},
							}}
						/>
					</div>
				</div>
			))}
		</PageLayout>
	);
};

export default StaffGenderScoreActionsPage;
