/*
Mailjet statuses as described here:
https://dev.mailjet.com/email/reference/message-events/#v3_get_clickstatistics (MessageStatus)

0 (Processed)
1 (Queued)
2 (Sent)
3 (Opened)
4 (Clicked)
5 (Bounce)
6 (Spam)
7 (Unsub)
8 (Blocked)
9 (SoftBounce)
10 (HardBounce)
11 (Deferred)
*/

export const MAILJET_STATUS_PROCESSED_ID = 'processed';
export const MAILJET_STATUS_PROCESSED_LABEL = 'Processed';
export const MAILJET_STATUS_PROCESSED_COLOR = 'lime';
export const MAILJET_STATUS_PROCESSED = {
	id: MAILJET_STATUS_PROCESSED_ID,
	label: MAILJET_STATUS_PROCESSED_LABEL,
	color: MAILJET_STATUS_PROCESSED_COLOR,
};

export const MAILJET_STATUS_QUEUED_ID = 'queued';
export const MAILJET_STATUS_QUEUED_LABEL = 'Queued';
export const MAILJET_STATUS_QUEUED_COLOR = 'lime';
export const MAILJET_STATUS_QUEUED = {
	id: MAILJET_STATUS_QUEUED_ID,
	label: MAILJET_STATUS_QUEUED_LABEL,
	color: MAILJET_STATUS_QUEUED_COLOR,
};

export const MAILJET_STATUS_SENT_ID = 'sent';
export const MAILJET_STATUS_SENT_LABEL = 'Sent';
export const MAILJET_STATUS_SENT_COLOR = 'gold';
export const MAILJET_STATUS_SENT = {
	id: MAILJET_STATUS_SENT_ID,
	label: MAILJET_STATUS_SENT_LABEL,
	color: MAILJET_STATUS_SENT_COLOR,
};

export const MAILJET_STATUS_OPENED_ID = 'opened';
export const MAILJET_STATUS_OPENED_LABEL = 'Opened';
export const MAILJET_STATUS_OPENED_COLOR = 'blue';
export const MAILJET_STATUS_OPENED = {
	id: MAILJET_STATUS_OPENED_ID,
	label: MAILJET_STATUS_OPENED_LABEL,
	color: MAILJET_STATUS_OPENED_COLOR,
};

export const MAILJET_STATUS_CLICKED_ID = 'clicked';
export const MAILJET_STATUS_CLICKED_LABEL = 'Clicked';
export const MAILJET_STATUS_CLICKED_COLOR = 'green';
export const MAILJET_STATUS_CLICKED = {
	id: MAILJET_STATUS_CLICKED_ID,
	label: MAILJET_STATUS_CLICKED_LABEL,
	color: MAILJET_STATUS_CLICKED_COLOR,
};

export const MAILJET_STATUS_BOUNCE_ID = 'bounce';
export const MAILJET_STATUS_BOUNCE_LABEL = 'Bounce';
export const MAILJET_STATUS_BOUNCE_COLOR = 'orange';
export const MAILJET_STATUS_BOUNCE = {
	id: MAILJET_STATUS_BOUNCE_ID,
	label: MAILJET_STATUS_BOUNCE_LABEL,
	color: MAILJET_STATUS_BOUNCE_COLOR,
};

export const MAILJET_STATUS_SPAM_ID = 'spam';
export const MAILJET_STATUS_SPAM_LABEL = 'Spam';
export const MAILJET_STATUS_SPAM_COLOR = 'orange';
export const MAILJET_STATUS_SPAM = {
	id: MAILJET_STATUS_SPAM_ID,
	label: MAILJET_STATUS_SPAM_LABEL,
	color: MAILJET_STATUS_SPAM_COLOR,
};

export const MAILJET_STATUS_UNSUB_ID = 'unsub';
export const MAILJET_STATUS_UNSUB_LABEL = 'Unsub';
export const MAILJET_STATUS_UNSUB_COLOR = 'orange';
export const MAILJET_STATUS_UNSUB = {
	id: MAILJET_STATUS_UNSUB_ID,
	label: MAILJET_STATUS_UNSUB_LABEL,
	color: MAILJET_STATUS_UNSUB_COLOR,
};

export const MAILJET_STATUS_BLOCKED_ID = 'blocked';
export const MAILJET_STATUS_BLOCKED_LABEL = 'Blocked';
export const MAILJET_STATUS_BLOCKED_COLOR = 'orange';
export const MAILJET_STATUS_BLOCKED = {
	id: MAILJET_STATUS_BLOCKED_ID,
	label: MAILJET_STATUS_BLOCKED_LABEL,
	color: MAILJET_STATUS_BLOCKED_COLOR,
};

export const MAILJET_STATUS_SOFTBOUNCE_ID = 'softbounced';
export const MAILJET_STATUS_SOFTBOUNCE_LABEL = 'Softbounced';
export const MAILJET_STATUS_SOFTBOUNCE_COLOR = 'orange';
export const MAILJET_STATUS_SOFTBOUNCE = {
	id: MAILJET_STATUS_SOFTBOUNCE_ID,
	label: MAILJET_STATUS_SOFTBOUNCE_LABEL,
	color: MAILJET_STATUS_SOFTBOUNCE_COLOR,
};

export const MAILJET_STATUS_DEFERRED_ID = 'deferred';
export const MAILJET_STATUS_DEFERRED_LABEL = 'Deferred';
export const MAILJET_STATUS_DEFERRED_COLOR = 'orange';
export const MAILJET_STATUS_DEFERRED = {
	id: MAILJET_STATUS_DEFERRED_ID,
	label: MAILJET_STATUS_DEFERRED_LABEL,
	color: MAILJET_STATUS_DEFERRED_COLOR,
};

export const MAILJET_STATUS_HARDBOUNCE_ID = 'hardbounce';
export const MAILJET_STATUS_HARDBOUNCE_LABEL = 'Hardbounce';
export const MAILJET_STATUS_HARDBOUNCE_COLOR = 'orange';
export const MAILJET_STATUS_HARDBOUNCE = {
	id: MAILJET_STATUS_HARDBOUNCE_ID,
	label: MAILJET_STATUS_HARDBOUNCE_LABEL,
	color: MAILJET_STATUS_HARDBOUNCE_COLOR,
};

export const MAILJET_STATUS_DATA = {
	MAILJET_STATUS_BLOCKED,
	MAILJET_STATUS_BOUNCE,
	MAILJET_STATUS_CLICKED,
	MAILJET_STATUS_DEFERRED,
	MAILJET_STATUS_HARDBOUNCE,
	MAILJET_STATUS_OPENED,
	MAILJET_STATUS_PROCESSED,
	MAILJET_STATUS_QUEUED,
	MAILJET_STATUS_SENT,
	MAILJET_STATUS_SOFTBOUNCE,
	MAILJET_STATUS_SPAM,
	MAILJET_STATUS_UNSUB,
};

export const findMailjetStatus = (value) => Object
	.values(MAILJET_STATUS_DATA)
	.find((option) => value && (option?.id === value || option?.label === value));

export const MAILJET_STATUS_LIST = Object.values(MAILJET_STATUS_DATA);
