/* eslint-disable react-hooks/exhaustive-deps */
import PageLayout from '../../../components/app/layout/PageLayout';
import AdminAtsPipelineTable from '../../../components/staff/ats/AtsPipelineTable';
import { ATS_PIPELINES_DETAILS } from '../../../constants/constant';
import StaffAtsPipelinesMenu from '../../../menu/staff/ats/StaffAtsPipelinesMenu';

const AtsPipelinesPage = () => {
	return (
		<PageLayout
			MenuClass={StaffAtsPipelinesMenu}
			subtitle={ATS_PIPELINES_DETAILS}
		>

			<AdminAtsPipelineTable/>

		</PageLayout>
	);
};

export default AtsPipelinesPage;
