import DataMessagesPage from '../../../containers/staff/data/DataMessagesPage';
import { onEnterBuilder } from '../../../actions/app';
import { PERMS_GRANT_STAFF } from '../../menu.utils';

const menu = {
	name: 'staff-data-messages',
	path: '/staff/data/messages',
	label: 'Data Messages',
	icon: <span>💌</span>,
	component: DataMessagesPage,
	onEnter: onEnterBuilder({
		origin: DataMessagesPage.name,
		shouldGoToPreviousHistory: true,
	}),
	perms: PERMS_GRANT_STAFF,
};

export default menu;
