import SourcingForm from './SourcingForm';
import FormBoxTitle from '../app/form/FormBoxTitle';
import useBodyScrollBlocker from '../../hooks/useBodyScrollBlocker';
import ModalContainerBase from '../../contexts/modals/ModalContainerBase';
import { GenderhireCandidatesSourcedIcon } from '../../menu/genderhire/GenderhireCandidatesSourcedMenu';
import { ADD_TO_TALENT_POOL_LABEL } from '../../constants/constant';

const SourcingPopover = (
	{
		open,
		initialValues = {},
		onSubmit,
		onReset,
		withFormHistoryItems = true,
		withJob = true,
	},
) => {
	const className = 'SourcingPopover';

	useBodyScrollBlocker(open, className);

	const { user } = initialValues;
	const title = <><span><GenderhireCandidatesSourcedIcon /> {ADD_TO_TALENT_POOL_LABEL}</span></>;

	return (
		<ModalContainerBase
			open={open}
			onCancel={onReset}
			width={600}
		>
			<FormBoxTitle
				title={title}
			/>
			<SourcingForm
				initialValues={initialValues}
				withSource={false}
				withJob={withJob}
				withFormHistoryItems={withFormHistoryItems}
				onSubmit={onSubmit}
				onReset={onReset}/>
		</ModalContainerBase>
	);
};

export default SourcingPopover;
