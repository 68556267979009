import { useTheme } from '@emotion/react';
import { Tag } from 'antd';
import {
	EXPERIENCE_LEVEL_OPTIONS,
	NOT_PROVIDED_YET_LABEL,
	getExperienceLevelOption,
	userTagStyle,
} from '../../../constants/constant';
import UserModel from '../../../models/UserModel';
import UserCoachingExperience from './UserCoachingExperience';
import { arrayWrap } from '../../../utils/common';

const UserJobLevel = (
	{
		user,
		mode = 'tags',
		size = 'medium',
		full = false,
		forceValue = null,
		notProvided = NOT_PROVIDED_YET_LABEL,
		...props
	},
) => {
	const theme = useTheme();
	const userModel = new UserModel(user);

	const value = forceValue || user?.jobSearch?.jobLevel;
	if (!user?.isNewlyOnboarded) {
		return notProvided;
	}

	if (mode === 'tags') {
		const tagStyle = {
			...userTagStyle(size),
			...props?.style,
			margin: '0 2px 1px 0',
		};
		const tags = EXPERIENCE_LEVEL_OPTIONS
			.map((o, index) => {
				return arrayWrap(value).includes(o.value)
					? <Tag color='blue' key={index} style={tagStyle}>{o?.label}</Tag>
					: full && <Tag key={index} style={{ opacity: 0.5, ...tagStyle }}>{o?.label}</Tag>;
			}).filter((item) => item);

		if (tags?.length) {
			return tags;
		}
		if (forceValue) {
			return <Tag color='blue' style={tagStyle}>{forceValue}</Tag>;
		}
		return notProvided;
	}

	const options = arrayWrap(value).map((v) => getExperienceLevelOption(v));

	const span = <span
		style={{
			...props?.style,
		}}
	>
		<UserCoachingExperience user={user} defaultValue={options.map((o) => o?.label).join(', ')}/>
	</span>;
	return span;
};

export default UserJobLevel;
