import { useEffect, useState } from 'react';
import { useTheme } from '@emotion/react';
import {
	PDF_PAGE_RATIO,
	PDF_PAGE_WIDTH,
} from '../base/PDFPage';
import PDFPageLayout from '../base/PDFPageLayout';
import {
	BIAS_OPTIONS,
	PERSONA_MANAGER,
	getBiasLevel,
	getManagerBiasResults,
} from '../../../data/biases';
import PDFPageGrid from '../base/PDFPageGrid';
import PDFPageText, { PDF_TEXT_LEVELS, PDF_TEXT_STYLES } from '../base/PDFPageText';
import { PDFBiasLevelTag } from './PDFBiasTag';
import BiasRadar from '../../display/BiasRadar';

const csv = `Name	Answer	Count	Self-Fullfilling Prophecy	Backlash Effect	Recency bias	Halo effect / horns effect	In-group Effect	Overly critical	Contrast effect
Julien	Manager	1	33	23	22	24	17	26	23
Julien	Employees	2	25	15	23	22	28	21	7
Julien	Female employees	1	25	12	30	25	32	25	7
Julien	Male employees	1	25	18	17	19	25	18	7`;

const PDFResults = ({
	width = PDF_PAGE_WIDTH,
	count,
	page,
}) => {
	const theme = useTheme();

	const r = (v) => PDF_PAGE_RATIO(v, width);

	const [data, setData] = useState();
	const [results, setResults] = useState([]);

	const radarStyles = {
		'Manager': {
			stroke: theme.color.fitPurple,
			strokeWidth: 3,
		},
		'Employees': {
			stroke: theme.color.fitGreen,
			strokeWidth: 2,
		},
		'Male employees': {
			stroke: theme.color.fitBlueElectric,
			strokeWidth: 1,
		},
		'Female employees': {
			stroke: theme.color.red,
			strokeWidth: 1,
		},
	};

	useEffect(() => {
		const [headers, ...answers] = csv.split('\n');
		let [, , , ...biases] = headers.split('\t');
		biases = biases
			.map((bias) => BIAS_OPTIONS.find((o) => o.label.toLocaleLowerCase() === bias.toLowerCase()))
			.map((bias) => bias.id);

		const managersByName = {};
		answers.map((answer, index) => {
			const [managerName, answerType, answerCount, ...scores] = answer.split('\t');
			// console.log({
			// 	managerName, answerType, answerCount, scores,
			// });
			managersByName[managerName] = {
				...managersByName[managerName] || {},
				name: managerName,
				[answerType]: {
					answerType,
					count: answerCount,
					scores: biases.map((bias, i) => ({
						bias,
						value: Math.round((randomData
							? 42 * Math.random()
							: parseInt(scores[i], 10)
							// : 32 * 42 / 100
						) * 100 / 42),
					})),
					radarStyle: radarStyles[answerType] || {},
				},
			};
		});
		setData(managersByName);

		setResults(getManagerBiasResults(managersByName.Julien));
	}, [randomData]);

	const blockStyle = {
		boxShadow: '0px 0px 20px -8px rgba(0,0,0,.3)',
		background: 'linear-gradient(to bottom, #fafaff, #f6f6fc)',
		borderRadius: 15,
	};

	if (!data) return null;

	return (
		<PDFPageLayout
			key={'manager-results-bias-level'}
			title={<>Result</>}
			subtitle={<>Level bias result breakdown</>}
			count={count++}
		>

			<BiasRadar
				width={1132}
				width={r(1900)}
				height={r(1000)}
				margin={{
					top: 0, left: 0, right: 0, bottom: 140,
				}}
				levels={5}
				data={[
					// managersByName.Julien['Male employees'],
					// managersByName.Julien['Female employees'],
					data?.Julien?.Employees,
					data?.Julien?.Manager,
				]}
				style={blockStyle}
			/>

			<PDFPageGrid
				style={{
					columnGap: r(300),
					marginTop: r(80),
				}}
			>
				{BIAS_OPTIONS.map((bias, index) => {
					return (
						<div
							key={bias.id}
							style={{ marginBottom: r(20) }}
						>
							<PDFPageText level={PDF_TEXT_LEVELS.large}>
								{bias.label}
							</PDFPageText>
							<PDFPageGrid>
								<PDFPageText
									level={PDF_TEXT_LEVELS.huge}
								>
									{results?.scores?.bias?.[bias.id]?.[PERSONA_MANAGER.id]}
									<span style={{ ...PDF_TEXT_STYLES(r).big }}>
										/100
									</span>
								</PDFPageText>
								<PDFBiasLevelTag
									value={getBiasLevel(results?.scores?.bias?.[bias.id]?.[PERSONA_MANAGER.id])}
								/>
							</PDFPageGrid>
						</div>
					);
				})}
			</PDFPageGrid>
		</PDFPageLayout>
	);
};

export default PDFResults;
