import GridValueCard from '../../../app/card/GridValueCard';
import UserCoachingOpinionCardBody from './UserCoachingOpinionCardBody';
import { NOT_PROVIDED_YET_LABEL } from '../../../../constants/constant';
import { useAuthContext } from '../../../../hooks/useAuthContext';

const UserCoachingOpinionCard = (
	{
		user,
		hideIfEmpty = true,
		notProvided = NOT_PROVIDED_YET_LABEL,
		children,
		...props
	},
) => {
	const { currentUser } = useAuthContext();

	const title = <>What we like about&nbsp;
		{currentUser?.canDisplayTalentNames?.(user) !== false ? user?.firstName : 'this talent'}</>;
	const details = null;

	return (user?.hasCoachingOpinion() || !hideIfEmpty
		? <GridValueCard
			title={title}
			details={details}
			{...props}
		>
			{children || <UserCoachingOpinionCardBody user={user} notProvided={notProvided}/>}
		</GridValueCard>
		: null
	);
};

export default UserCoachingOpinionCard;
