import { mapSeries } from 'async';
import { mixColors } from '../../../../../utils/common';
import { BIAS_ALIGNMENT_OPTIONS, BIAS_LEVEL_OPTIONS } from '../../../data/biases';
import {
	bold,
	regular,
	PDF_TEXT_STYLES,
	PDF_TYPE_TEXT,
	PDF_TYPE_TITLE,
	PDF_TYPE_GRID,
	PDF_PAGE_TEMPLATE_CONTENT,
	PDF_TYPE_RECT,
	PDF_TYPE_MOVE,
} from '../base/PDFBuilder';

const PageResultsIntro = async ({
	doc,
	context,
	skip,
}) => {
	const tagRatio = 1 / 1.4;
	return [
		{
			template: PDF_PAGE_TEMPLATE_CONTENT,
			title: {
				en: 'How to read your results',
				fr: 'Comment lire vos résultats',
			},
			footer: true,
			pageCount: true,
			items: [
				{
					type: PDF_TYPE_GRID,
					gridTemplateColumns: [1, 1],
					columnGap: 10,
					marginTop: 8,
					columns: [
						[
							{
								type: PDF_TYPE_TITLE,
								text: {
									en: 'Bias Level',
									fr: 'Niveau de biais',
								},
								...bold(PDF_TEXT_STYLES.title2),
								marginBottom: 4,
								withArrow: true,
							},
							{
								type: PDF_TYPE_TEXT,
								text: {
									en: 'How strong or weak your bias level is, according to your own assessment.\n',
									fr: 'Quel est le niveau de vos biais, fort ou faible, selon votre propre évaluation.\n',
								},
								...regular(PDF_TEXT_STYLES.normal),
								marginBottom: 5,
							},
							{
								type: PDF_TYPE_MOVE,
								y: 120,
							},
							...(await mapSeries(
								BIAS_LEVEL_OPTIONS,
								async (option) => [
									await doc.getBiasLevelTagImage({
										context,
										level: option,
										width: context.biasLevelTagSizes[0] * tagRatio,
										height: context.biasLevelTagSizes[1] * tagRatio,
										skip,
									}),
									{
										type: PDF_TYPE_TEXT,
										text: option.details,
										...regular(PDF_TEXT_STYLES.small),
										marginBottom: 5,
									},
								],
							)).flat(),
						],
						[
							{
								type: PDF_TYPE_TITLE,
								text: {
									en: 'Alignment',
									fr: 'Alignement',
								},
								...bold(PDF_TEXT_STYLES.title2),
								marginBottom: 5,
								withArrow: true,
							},
							{
								type: PDF_TYPE_TEXT,
								text: {
									en: 'Comparison between your personal assessment and your employees’ perception',
									fr: 'Comparaison entre votre évaluation personnelle et la perception de vos employés',
								},
								...regular(PDF_TEXT_STYLES.normal),
								marginBottom: 5,
							},
							...(await mapSeries(
								BIAS_ALIGNMENT_OPTIONS,
								async (option) => [
									await doc.getBiasAlignmentTagImage({
										context,
										alignment: option,
										width: context.biasLevelTagSizes[0] * tagRatio,
										height: context.biasLevelTagSizes[1] * tagRatio,
										skip,
									}),
									{
										type: PDF_TYPE_TEXT,
										text: option.details,
										...regular(PDF_TEXT_STYLES.small),
										marginBottom: 5,
									},
								],
							)).flat(),
						],
					],
				},
			],
		},
	];
};

export default PageResultsIntro;
