/* eslint-disable react-hooks/exhaustive-deps */
import PageLayout from '../../../components/app/layout/PageLayout';
import { ATS_ARCHIVE_REASONS_DETAILS } from '../../../constants/constant';
import AdminAtsArchiveReasonTable from '../../../components/staff/ats/AtsArchiveReasonTable';
import StaffAtsArchiveReasonsMenu from '../../../menu/staff/ats/StaffAtsArchiveReasonsMenu';

const AtsArchiveReasonsPage = () => {
	return (
		<PageLayout
			MenuClass={StaffAtsArchiveReasonsMenu}
			subtitle={ATS_ARCHIVE_REASONS_DETAILS}
		>

			<AdminAtsArchiveReasonTable/>

		</PageLayout>
	);
};

export default AtsArchiveReasonsPage;
