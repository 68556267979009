import {
	Button, Col, Dropdown, Menu, Row, Space, Tooltip,
} from 'antd';
import { DownOutlined } from '@ant-design/icons';
import { mailVariableOptions } from '../../../constants/constant';
import { useTheme } from '@emotion/react';
import { FaPlusSquare } from 'react-icons/fa';
import { mixColors } from '../../../utils/common';

const AddVariablesDropdown = (
	{
		onSelected,
		...props
	},
) => {
	const className = 'AddVariablesDropdown';

	const theme = useTheme();

	const handleSelectMenu = (menu) => {
		if (onSelected) onSelected(menu.key);
	};
	const menu = (
		<Menu
			onClick={handleSelectMenu}
			mode="horizontal"
			style={{ maxHeight: 300, overflow: 'auto' }}
		>
			{
				mailVariableOptions.map((item) => (
					<Menu.Item key={item.value}>
						{item.label}
					</Menu.Item>
				))
			}

		</Menu>
	);
	return (
		<Dropdown overlay={menu} trigger={['click']} placement='bottomRight'>
			<Tooltip title='Insert a dynamic variable into this field'>
				<Button
					type="link"
					style={{
						borderRadius: 5,
						height: 32,
						padding: '2px 10px',
						// fontSize: 10,
						// background: mixColors(theme.color.fitGreenPastel, theme.color.white, 50),
						background: theme.color.fitGreen,
						color: theme.color.white,
						...props?.style,
					}}
				>
					<FaPlusSquare style={{ marginBottom: -2 }}/>&nbsp;Insert
					{/* <Row> */}
					{/*    <Col */}
					{/*        span={24} */}
					{/*        style={{ */}
					{/*            display: 'flex', */}
					{/*            justifyContent: 'space-between', */}
					{/*            width: 'calc(100% + 10px)' */}
					{/*        }} */}
					{/*    > */}
					{/*        <Space align="center"> */}
					{/*            <FaPlusSquare /> Add variable */}
					{/*            /!*<DownOutlined />*!/ */}
					{/*        </Space> */}
					{/*    </Col> */}
					{/* </Row> */}
				</Button>
			</Tooltip>
		</Dropdown>
	);
};

export default AddVariablesDropdown;
