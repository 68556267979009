/* eslint-disable no-nested-ternary */
import { ENGLISH_LANG } from '../../../../constants/constant';
import { COMPANY_RECRUITER_LOG } from '../../../../constants/company-recruiter-log';
import { useContext, useEffect, useState } from 'react';
import Popin from '../../../app/modal/Popin';
import CompanyFieldDisplay from './CompanyFieldDisplay';
import { useTheme } from '@emotion/react';
import { CompanyPageContext } from './CompanyPageProvider';
import { useAuthContext } from '../../../../hooks/useAuthContext';
import CompanyFieldEditorLocked from './CompanyFieldEditorLocked';
import { writeRecruiterLog } from '../../../../actions/logging';
import { Modal } from 'antd';
import ModalContainerBase from '../../../../contexts/modals/ModalContainerBase';

const CompanyEditableContent = (
	{
		company,
		contentStep,
		DisplayComponent,
		EditorComponent,
		withOverlayLabel = true,
		popinWidth = 1060,
		popinWidthLocked = 540,
		isTranslatable = true,
		isStaffLocked = false,
		EditorLockedComponent,
		overlayLabel = 'Edit',
		isEditable = true,
		lang = ENGLISH_LANG,
	},
) => {
	const className = 'CompanyEditableContent';

	const theme = useTheme();

	const { isStaff, isStaffView } = useAuthContext();
	const { currentlyEditingStep, setCurrentlyEditingStep } = useContext(CompanyPageContext);
	const [editorOpen, setEditorOpen] = useState(currentlyEditingStep && (currentlyEditingStep === contentStep));
	const [editorLocked, setEditorLocked] = useState(false);

	useEffect(() => {
		setEditorOpen(currentlyEditingStep && currentlyEditingStep === contentStep);
	}, [currentlyEditingStep, contentStep]);

	useEffect(() => {
		setEditorLocked(isStaffLocked && !(isStaff && isStaffView));
	}, [isStaffLocked, isStaff, isStaffView]);

	useEffect(() => {
		if (!editorOpen || !contentStep) return;
		writeRecruiterLog(
			COMPANY_RECRUITER_LOG.POPIN,
			{ extra: { name: `${contentStep}${editorLocked ? '-locked' : ''}` } },
		);
	}, [editorOpen, editorLocked, contentStep]);

	const updatedLang = isTranslatable ? lang : ENGLISH_LANG;

	return (
		<>
			<CompanyFieldDisplay
				withOverlayLabel={withOverlayLabel}
				overlayLabel={overlayLabel}
				isEditable={isEditable}
				isStaffLocked={isStaffLocked}
				hasEditorLockedComponent={!!EditorLockedComponent}
				onClick={() => {
					if (currentlyEditingStep !== contentStep) setCurrentlyEditingStep(contentStep);
					// setEditorOpen(true);
				}}
			>
				<DisplayComponent
					company={company}
					lang={updatedLang}
					isStaffLocked={isStaffLocked}
				/>
			</CompanyFieldDisplay>

			<ModalContainerBase
				open={editorOpen}
				width={editorLocked ? popinWidthLocked : popinWidth}
				onCancel={() => { setCurrentlyEditingStep(null); }}
			>
				{editorLocked
					? (
						EditorLockedComponent
							? (
								<EditorLockedComponent
									company={company}
									setEditorOpen={setEditorOpen}
								/>
							)
							: (
								<CompanyFieldEditorLocked
									company={company}
									setEditorOpen={setEditorOpen}
								/>
							)
					)
					: (
						<EditorComponent
							company={company}
							lang={updatedLang}
							isTranslatable={isTranslatable}
							setEditorOpen={setEditorOpen}
						/>
					)
				}
			</ModalContainerBase>
		</>
	);
};

export default CompanyEditableContent;
