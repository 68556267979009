import { useTheme } from '@emotion/react';
import { useEffect, useState } from 'react';
import Table from '../../app/table/Table';
import { useAuthContext } from '../../../hooks/useAuthContext';
import TableColumns from '../../app/table/TableColumns';
import { useDispatch, useSelector } from 'react-redux';
import { Divider, Space, Tag } from 'antd';
import { getAtsArchiveReasons } from '../../../reducers/app';
import { localDebug } from '../../../utils/LocalDebug';

const AtsArchiveReasonTable = (
	{
		isRefresh = 0,
		...props
	},
) => {
	const theme = useTheme();
	const dispatch = useDispatch();
	const atsArchiveReasons = useSelector(getAtsArchiveReasons);

	const { isStaffView } = useAuthContext();

	const [data, setData] = useState([]);
	const [isRefreshDataLayout, setIsRefreshDataLayout] = useState(0);

	const refreshTable = () => {
		setIsRefreshDataLayout((prev) => prev + 1);
	};

	useEffect(() => {
		localDebug('useEffect[atsArchiveReasons]', { atsArchiveReasons });
		setData(atsArchiveReasons);
	}, [atsArchiveReasons]);

	const jsonCol = TableColumns.jsonColumn(theme);

	const labelCol = {
		title: 'Label',
		dataIndex: 'label',
		render: (value, row) => <Tag icon={row.icon} color='volcano'>&nbsp;<b>{row.label}</b></Tag>,
		...TableColumns.columnFixedWidth(160),
	};

	const valueCol = {
		title: 'Value',
		dataIndex: 'value',
		render: (value, row) => <code>{value}</code>,
		...TableColumns.columnFixedWidth(160),
	};

	const mappingsCol = {
		title: 'Mappings',
		dataIndex: 'atsMappings',
		render: (value, row) => <Space direction='horizontal' size={0} wrap={true}>
			{value?.map((remoteReason, i) => <code key={i}>{remoteReason}</code>)}
		</Space>,
		...TableColumns.columnMinWidth(400),
	};

	const columns = isStaffView
		? [
			jsonCol,
			labelCol,
			valueCol,
			mappingsCol,
		]
		: [
			jsonCol,
			// pipelineCol,
			// stagesCol,
		];

	return (true
		&& <>
			{/* <JSONPretty data={atsPipelines || []}/> */}
			{/* <JSONPretty data={stages || []}/> */}
			{/* <JSONPretty data={data || []}/> */}
			<Table
				data={data}
				// path={`company/ats-pipeline-stages?group=true`}
				columns={columns}
				isRefresh={isRefresh + isRefreshDataLayout}
				withPagination={false}
				{...props}
			/>
		</>
	);
};

export default AtsArchiveReasonTable;
