import { actionWithLoader, push } from './utils';
import { localDebug } from '../utils/LocalDebug';
import tagRuleService from '../services/tagRules';
import { SET_TAG_RULE_SELECTED, TAGS_CATEGORY_LOADED, TAG_RULES_LOADED } from './types';

export const getAllTagRules = () => {
	return actionWithLoader(async (dispatch) => {
		try {
			const { data } = await tagRuleService.findAll();
			dispatch({
				type: TAG_RULES_LOADED,
				tagRules: data.tagRules,
			});
		} catch (error) {
			localDebug(__filename.split('/').shift(), error);
		}
	});
};

export const getAllCategoryTags = () => {
	return actionWithLoader(async (dispatch) => {
		try {
			const { data } = await tagRuleService.getTags({ groupBy: 'category' });
			dispatch({
				type: TAGS_CATEGORY_LOADED,
				tagsCategory: data.tags,
			});
		} catch (error) {
			localDebug(__filename.split('/').shift(), error);
		}
	});
};

export const getTagsByCategory = async (category) => {
	try {
		const { data } = await tagRuleService.getTags(category);
		return data.tags;
	} catch (error) {
		localDebug(__filename.split('/').shift(), error);
	}
};

export const saveTagRule = (data, goToList = false) => {
	return actionWithLoader(async (dispatch) => {
		try {
			const { data: { tagRule } } = await tagRuleService.save(data);
			return tagRule;
		} catch (error) {
			localDebug(__filename.split('/').shift(), error);
		}
	});
};

export const deleteTagRule = async (id) => {
	try {
		await tagRuleService.remove(id);
	} catch (error) {
		localDebug(__filename.split('/').shift(), error);
	}
};

export const loadTagRule = (tagRuleId) => {
	return actionWithLoader(async (dispatch) => {
		try {
			// tagRuleId is null on created
			// so set tag rule selected to null
			if (!tagRuleId) {
				dispatch({
					type: SET_TAG_RULE_SELECTED,
					tagRule: null,
				});
				return;
			}

			// dispatch tag rule selected
			const { data } = await tagRuleService.getById(tagRuleId);
			dispatch({
				type: SET_TAG_RULE_SELECTED,
				tagRule: data.tagRule,
			});
		} catch (error) {
			localDebug(__filename.split('/').shift(), error);
		}
	});
};

export function showTagRules() {
	return push('/tag-rules');
}

export function showEditTagRule(id) {
	return push(`/tag-rule-${id}`);
}
