import PageLayout from '../components/app/layout/PageLayout';
import { useTheme } from '@emotion/react';
import EmptyData from '../components/app/EmptyData';

const EmptyPage = () => {
	const theme = useTheme();
	
	return (
		<PageLayout
			withBackground={false}
		>
			<EmptyData
				description='No access'
				style={{
					marginTop: 10,
				}}
			/>
		</PageLayout>
	);
};

export default EmptyPage;
