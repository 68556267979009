import {
	ACL_ROLE_COMPANY_RECRUITER_LABEL,
	ACL_ROLE_DOCUMENT_ADMIN_LABEL,
	ACL_ROLE_DOCUMENT_EDITOR_LABEL,
} from '../acls';

const BUSINESS_PLAN_KEY = 'business-plan';

const BUSINESS_PLAN_MULTI = false;
const BUSINESS_PLAN_FREE = false;

// This can be used to generalize settings for all the plans
export const BUSINESS_PLAN_ALL = 'ALL';

export const BUSINESS_PLAN_GROUP_NO_PLAN_VALUE = 'no-plan-plans';
export const BUSINESS_PLAN_GROUP_LEGACY_VALUE = 'legacy-plans';
export const BUSINESS_PLAN_GROUP_GENDERSCORE_VALUE = 'gs-plans';
export const BUSINESS_PLAN_GROUP_GENDERHIRE_VALUE = 'gh-plans';
export const BUSINESS_PLAN_GROUP_UMS_VALUE = 'ums-plans';

// Default value for companies we cannot retrieve their business plan
// Note that no company should be in this plan
export const BUSINESS_PLAN_NO_PLAN_VALUE = 'no-plan';

export const BUSINESS_PLAN_LEGACY_VALUE = 'legacy';
// this plan will be removed and replaced by another gs plan, with extraTag Discount
export const BUSINESS_PLAN_GS_DISCOUNT_VALUE = 'gs-discount';
export const BUSINESS_PLAN_GS_STARTUP_VALUE = 'gs-startup';
export const BUSINESS_PLAN_GS_SCALEUP_VALUE = 'gs-scaleup';
export const BUSINESS_PLAN_GS_ENTERPRISE_VALUE = 'gs-enterprise';
export const BUSINESS_PLAN_GH_STARTER_VALUE = 'gh-starter';
export const BUSINESS_PLAN_GH_PREMIUM_VALUE = 'gh-premium';
export const BUSINESS_PLAN_GH_ENTERPRISE_VALUE = 'gh-enterprise';
export const BUSINESS_PLAN_UMS_VALUE = 'ums';

export const BUSINESS_PLAN_GROUP_OPTIONS = [
	{
		value: BUSINESS_PLAN_GROUP_GENDERSCORE_VALUE,
		label: 'GenderScore Plans',
		shortLabel: 'GS Plans',
		color: 'green',
	},
	{
		value: BUSINESS_PLAN_GROUP_GENDERHIRE_VALUE,
		label: 'GenderHire Plans',
		shortLabel: 'GH Plans',
		color: 'magenta',
	},
	{
		value: BUSINESS_PLAN_GROUP_UMS_VALUE,
		label: 'UMS Plans',
		shortLabel: 'UMS Plans',
		color: 'volcano',
	},
	{
		value: BUSINESS_PLAN_GROUP_LEGACY_VALUE,
		label: 'Legacy Plans',
		shortLabel: 'Legacy Plans',
		color: '',
	},
];

export const BUSINESS_PLAN_OPTIONS = [
	{
		group: BUSINESS_PLAN_GROUP_NO_PLAN_VALUE,
		value: BUSINESS_PLAN_NO_PLAN_VALUE,
		label: 'No Plan',
		shortLabel: 'No Plan',
		color: '#000',
	},
	{
		group: BUSINESS_PLAN_GROUP_LEGACY_VALUE,
		value: BUSINESS_PLAN_LEGACY_VALUE,
		label: 'Legacy',
		shortLabel: 'Legacy',
		color: '',
	},

	{
		group: BUSINESS_PLAN_GROUP_GENDERSCORE_VALUE,
		value: BUSINESS_PLAN_GS_DISCOUNT_VALUE,
		label: 'GenderScore Discount',
		shortLabel: 'GS Discount',
		color: 'green',
	},
	{
		group: BUSINESS_PLAN_GROUP_GENDERSCORE_VALUE,
		value: BUSINESS_PLAN_GS_STARTUP_VALUE,
		label: 'GenderScore Startup',
		shortLabel: 'GS Startup',
		color: 'cyan',
	},
	{
		group: BUSINESS_PLAN_GROUP_GENDERSCORE_VALUE,
		value: BUSINESS_PLAN_GS_SCALEUP_VALUE,
		label: 'GenderScore Scaleup',
		shortLabel: 'GS Scaleup',
		color: 'geekblue',
	},
	{
		group: BUSINESS_PLAN_GROUP_GENDERSCORE_VALUE,
		value: BUSINESS_PLAN_GS_ENTERPRISE_VALUE,
		label: 'GenderScore Enterprise',
		shortLabel: 'GS Enterprise',
		color: 'purple',
	},

	{
		group: BUSINESS_PLAN_GROUP_GENDERHIRE_VALUE,
		value: BUSINESS_PLAN_GH_STARTER_VALUE,
		label: 'GenderHire Starter',
		shortLabel: 'GH Starter',
		color: 'gold',
	},
	{
		group: BUSINESS_PLAN_GROUP_GENDERHIRE_VALUE,
		value: BUSINESS_PLAN_GH_PREMIUM_VALUE,
		label: 'GenderHire Premium',
		shortLabel: 'GH Premium',
		color: 'volcano',
	},
	{
		group: BUSINESS_PLAN_GROUP_GENDERHIRE_VALUE,
		value: BUSINESS_PLAN_GH_ENTERPRISE_VALUE,
		label: 'GenderHire Enterprise',
		shortLabel: 'GH Enterprise',
		color: 'magenta',
	},
	{
		group: BUSINESS_PLAN_GROUP_UMS_VALUE,
		value: BUSINESS_PLAN_UMS_VALUE,
		label: 'UMS',
		shortLabel: 'UMS',
		color: 'volcano',
	},
];

export const getBusinessPlan = (planValue) => (
	BUSINESS_PLAN_OPTIONS?.find((o) => o?.value === planValue) || {}
);

export const isGenderScorePlan = (planValue) => (
	getBusinessPlan(planValue).group === BUSINESS_PLAN_GROUP_GENDERSCORE_VALUE
);
export const isGenderHirePlan = (planValue) => (
	getBusinessPlan(planValue).group === BUSINESS_PLAN_GROUP_GENDERHIRE_VALUE
);
export const isUMSPlan = (planValue) => (
	getBusinessPlan(planValue).group === BUSINESS_PLAN_GROUP_UMS_VALUE
);
export const isHiringPlan = (planValue) => (
	getBusinessPlan(planValue).group === BUSINESS_PLAN_GROUP_GENDERHIRE_VALUE
	|| planValue === BUSINESS_PLAN_LEGACY_VALUE
);

export const getBusinessPlanGroup = (planGroup) => (
	BUSINESS_PLAN_GROUP_OPTIONS?.find((o) => o?.value === planGroup) || {}
);

export const getBusinessPlanGroupValue = (planValue) => (
	getBusinessPlan(planValue)?.group
);

export const getBusinessPlanGroupOption = (planValue) => (
	getBusinessPlanGroup(getBusinessPlan(planValue)?.group)
);

export const getBusinessPlanLabel = (planValue) => (
	getBusinessPlan(planValue)?.label
);

export const getBusinessPlanShortLabel = (planValue) => (
	getBusinessPlan(planValue)?.shortLabel
);

export const getBusinessPlanColor = (planValue) => (
	getBusinessPlan(planValue)?.color
);

export const BUSINESS_PLAN_GROUP_VALUES = BUSINESS_PLAN_GROUP_OPTIONS.map((o) => o.value);
export const BUSINESS_PLAN_VALUES = BUSINESS_PLAN_OPTIONS.map((o) => o.value);
export const BUSINESS_PLAN_GENDERSCORE_GROUP_VALUES = BUSINESS_PLAN_OPTIONS
	.filter((o) => o.group === BUSINESS_PLAN_GROUP_GENDERSCORE_VALUE)
	.map((o) => o.value);
export const BUSINESS_PLAN_GENDERHIRE_GROUP_VALUES = BUSINESS_PLAN_OPTIONS
	.filter((o) => o.group === BUSINESS_PLAN_GROUP_GENDERHIRE_VALUE)
	.map((o) => o.value);
export const BUSINESS_PLAN_UMS_GROUP_VALUES = BUSINESS_PLAN_OPTIONS
	.filter((o) => o.group === BUSINESS_PLAN_GROUP_UMS_VALUE)
	.map((o) => o.value);

export const BUSINESS_PLAN_ROLES = {};
BUSINESS_PLAN_ROLES.DEFAULT = [
	ACL_ROLE_DOCUMENT_ADMIN_LABEL,
	ACL_ROLE_DOCUMENT_EDITOR_LABEL,
	ACL_ROLE_COMPANY_RECRUITER_LABEL,
];
BUSINESS_PLAN_ROLES[BUSINESS_PLAN_GS_STARTUP_VALUE] = [
	ACL_ROLE_DOCUMENT_ADMIN_LABEL,
	ACL_ROLE_DOCUMENT_EDITOR_LABEL,
];
BUSINESS_PLAN_ROLES[BUSINESS_PLAN_GS_SCALEUP_VALUE] = [
	ACL_ROLE_DOCUMENT_ADMIN_LABEL,
	ACL_ROLE_DOCUMENT_EDITOR_LABEL,
];
BUSINESS_PLAN_ROLES[BUSINESS_PLAN_GS_DISCOUNT_VALUE] = [
	ACL_ROLE_DOCUMENT_ADMIN_LABEL,
	ACL_ROLE_DOCUMENT_EDITOR_LABEL,
];

export const getBusinessPlanRoles = (planValue) => (
	BUSINESS_PLAN_ROLES[planValue] || BUSINESS_PLAN_ROLES.DEFAULT
);

const exporter = {
	key: BUSINESS_PLAN_KEY,
	BUSINESS_PLAN_ALL,
	BUSINESS_PLAN_KEY,
	BUSINESS_PLAN_NO_PLAN_VALUE,
	BUSINESS_PLAN_LEGACY_VALUE,
	BUSINESS_PLAN_GS_DISCOUNT_VALUE,
	BUSINESS_PLAN_GS_STARTUP_VALUE,
	BUSINESS_PLAN_GS_SCALEUP_VALUE,
	BUSINESS_PLAN_GS_ENTERPRISE_VALUE,
	BUSINESS_PLAN_GH_STARTER_VALUE,
	BUSINESS_PLAN_GH_PREMIUM_VALUE,
	BUSINESS_PLAN_GH_ENTERPRISE_VALUE,
	BUSINESS_PLAN_UMS_VALUE,
	multi: BUSINESS_PLAN_MULTI,
	BUSINESS_PLAN_MULTI,
	options: BUSINESS_PLAN_OPTIONS,
	BUSINESS_PLAN_OPTIONS,
	free: BUSINESS_PLAN_FREE,
	BUSINESS_PLAN_FREE,
	values: BUSINESS_PLAN_VALUES,
	BUSINESS_PLAN_VALUES,
	BUSINESS_PLAN_GROUP_VALUES,
	BUSINESS_PLAN_GENDERSCORE_GROUP_VALUES,
	BUSINESS_PLAN_GENDERHIRE_GROUP_VALUES,
	BUSINESS_PLAN_UMS_GROUP_VALUES,
	isGenderScorePlan,
	isGenderHirePlan,
	isUMSPlan,
	getBusinessPlan,
	getBusinessPlanLabel,
	getBusinessPlanShortLabel,
	getBusinessPlanColor,
	getBusinessPlanGroupValue,
	getBusinessPlanGroupOption,
};

export default exporter;
