import { useTheme } from '@emotion/react';
import SectionTitle from '../../app/SectionTitle';
import PageLayout from '../../app/layout/PageLayout';
import {
	Button,
	Checkbox,
	List,
	Radio,
	Space,
	message,
} from 'antd';
import UMSStaffDownloadButton from '../UMSStaffDownloadButton';
import { useContext, useEffect, useState } from 'react';
import UnbiasedScoreProvider, { UnbiasedScoreContext } from '../../../contexts/UnbiasedScoreProvider';
import ReactJson from 'react-json-view';
import UMSStaffUploadPDFButton from '../UMSStaffUploadButton';
import { useDispatch, useSelector } from 'react-redux';
import { getCompanySelected } from '../../../reducers/app';
import { documentId, numToArray, openExternalUrl } from '../../../utils/common';
import { loadCompany } from '../../../actions/company';
import {
	ArrowRightOutlined, CheckOutlined, CloseOutlined, ReloadOutlined,
} from '@ant-design/icons';
import { getPDFManagerPageList } from '../results/PDFManager';
import Link from '../../app/Link';
import AdminToolsUMSSection from '../../staff/tools/AdminToolsUMSSection';
import { useAuthContext } from '../../../hooks/useAuthContext';
import ModalContainerBase from '../../../contexts/modals/ModalContainerBase';
import { UMSInviteContext } from '../UMSInviteProvider';
import UMSUserStub from '../users/UMSUserStub';
import ReactJsonDebug from '../../app/debug/ReactJsonDebug';
import { PERSONA_EMPLOYEES_ALL_SHORT_ID, PERSONA_MANAGER_SHORT_ID, getRadarBiasData } from '../data/biases';
import BiasRadar from '../results/display/BiasRadar';
import config from '../../../config/config';
import UMSStaffPDFPageSelector from './UMSStaffPDFPageSelector';
import Box from '../../app/box/Box';
import UMSStaffManagerSelector from './UMSStaffManagerSelector';
import SurveyLangSwitcher from '../survey/SurveyLangSwitcher';
import { AppDataContext } from '../../../contexts/AppDataProvider';

const UMSStaffResults = () => {
	const className = 'UMSStaffResults';
	const theme = useTheme();

	const dispatch = useDispatch();
	const {
		refresh,
		umsResult,
		setImageUris,
		makeManagerPDFResultFilename,
	} = useContext(UnbiasedScoreContext) || {};

	const { isAdmin } = useAuthContext();
	const { companyResults, managerResults } = useContext(UMSInviteContext);

	const companySelected = useSelector(getCompanySelected);
	const [openDemoTools, setOpenDemoTools] = useState(false);

	// message.success({ content: 'Restarted', duration: 1, key: 'restarted' });

	// useEffect(() => {}, [refresh]);

	const blockStyle = {
		boxShadow: '0px 0px 20px -8px rgba(0,0,0,.3)',
		background: 'linear-gradient(to bottom, #fafaff, #f6f6fc)',
		borderRadius: 15,
	};

	return (
		<div id='mypage'>
			<PageLayout
				withPadding={false}
				title={(
					<SectionTitle
						title="UMS Staff"
						renderAlone={false}
					/>
				)}
			>

				<Space
					direction='horizontal'
					style={{
						position: 'fixed',
						top: 20,
						right: 20,
						// width: 'calc(100vw - 300px)',
						justifyContent: 'flex-end',
						alignItems: 'flex-end',
					}}
				>

					{/* <SurveyLangSwitcher /> */}

					{/* <UMSStaffUploadPDFButton /> */}

					{/* <UMSStaffDownloadButton /> */}

					{/* <UploadCSVButton /> */}

					<Button
						onClick={() => {
							setImageUris(new Map());
							dispatch(loadCompany(documentId(companySelected)));
						}}
					>
						<ReloadOutlined /> Company
					</Button>

					<Button onClick={refresh}>
						<ReloadOutlined /> Page
					</Button>

					{isAdmin && <Button onClick={() => setOpenDemoTools(true)}>Demo tools</Button>}
					<ModalContainerBase
						open={openDemoTools}
						setOpen={setOpenDemoTools}
						width={700}
					>
						<AdminToolsUMSSection />
					</ModalContainerBase>

					{/* <AdminCommandGetButton
						title={'Fake surveys'}
						path='/ums/demo'
						callback={async () => {
							setImageUris(new Map());
							await dispatch(loadCompany(documentId(companySelected)));
							message.success('reload');
						}}
						Icon={null}
						style={{
							...buildConfirmBtnStyleLarge,
							width: 120,
							height: 80,
						}}
					/> */}
				</Space>

				<div>
					<Space
						align='top'
						style={{

						}}
					>
						<Space
							direction='vertical'
						>

							<Box style={{ padding: '14px 20px', width: 360, height: 240 }}>
								<h3 style={{ marginBottom: 12 }}>Selected manager</h3>

								<BiasRadar
									width={80}
									height={80}
									margin={{
										top: 0, left: 0, right: 0, bottom: 0,
									}}
									levels={5}
									data={getRadarBiasData([
										PERSONA_EMPLOYEES_ALL_SHORT_ID,
										PERSONA_MANAGER_SHORT_ID,
									])}
								/>

								<div style={{ fontSize: 9 }}>
									<b>Filename:</b> {makeManagerPDFResultFilename({ withExtension: true })}
								</div>

								<div style={{ fontSize: 9 }}>
									<b>Filepath:</b> <Link onClick={() => (
										openExternalUrl(
											makeManagerPDFResultFilename({
												withFullPath: true,
												withExtension: true,
												withEncodeURI: true,
												withMagic: true,
											}),
										))}
									>
										{makeManagerPDFResultFilename({
											withFullPath: true,
											withExtension: true,
											withEncodeURI: false,
										})}
									</Link>
								</div>
							</Box>

							<Box style={{ padding: '14px 20px', width: 360 }}>
								<h3 style={{ marginBottom: 12 }}>Select a manager</h3>

								<UMSStaffManagerSelector
									style={{
										// border: '1px solid #eee',
										// borderRadius: 5,
										height: 340,
										overflow: 'scroll',
										marginBottom: 10,
									}}
								/>
							</Box>

						</Space>

						<Box style={{ padding: '14px 20px' }}>

							<Space style={{ marginBottom: 12 }}>
								<SurveyLangSwitcher />
								<UMSStaffPDFPageSelector />
								<UMSStaffUploadPDFButton />
								<UMSStaffDownloadButton />
							</Space>

							<div>
								{/* <BiasRadar
									width={80}
									height={80}
									margin={{
										top: 0, left: 0, right: 0, bottom: 0,
									}}
									levels={5}
									data={getRadarBiasData([
										PERSONA_EMPLOYEES_ALL_SHORT_ID,
										PERSONA_MANAGER_SHORT_ID,
									])}
								/>

								<div>
									<b>Filename:</b> {makeManagerPDFResultFilename({ withExtension: true })}
								</div>

								<div>
									<b>Filepath:</b> <Link onClick={() => (
										openExternalUrl(
											makeManagerPDFResultFilename({
												withFullPath: true,
												withExtension: true,
												withEncodeURI: true,
												withMagic: true,
											}),
										))}
									>
										{makeManagerPDFResultFilename({
											withFullPath: true,
											withExtension: true,
											withEncodeURI: false,
										})}
									</Link>
								</div> */}

								<iframe
									title={makeManagerPDFResultFilename({ withExtension: true })}
									width={700} height={640}
									src={makeManagerPDFResultFilename({
										withFullPath: true, withExtension: true, withEncodeURI: true, withMagic: true,
									})}
								/>
							</div>
						</Box>
					</Space>
				</div>

				<Space
					direction='vertical'
					style={{
						fontSize: 10, textAlign: 'left',
					}}
				>
					<ReactJson name='umsResult' src={umsResult} sortKeys={true} collapsed={0}/>
					<ReactJson name='companyResults' src={companyResults || {}} sortKeys={true} collapsed={0}/>
					<ReactJson name='managerResults' src={managerResults || []} sortKeys={true} collapsed={0} />

				</Space>

			</PageLayout>

		</div>
	);
};

export default UMSStaffResults;
