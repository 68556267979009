import { Space, Switch, Tag } from 'antd';
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import PageLayout from '../../../components/app/layout/PageLayout';
import { getCompanySelected } from '../../../reducers/app';
import RecruiterSearchesTable from '../../../components/staff/talent/recruiterSearches/RecruiterSearchesTable';
import { useAuthContext } from '../../../hooks/useAuthContext';
import { ComputeSavedSearchesAlertsButton } from '../../../components/staff/tools/AdminToolsSavedSearchesSection';
import { documentId } from '../../../utils/common';
import UserSavedSearchProvider from '../../../components/search/UserSavedSearchProvider';
import StaffCompaniesRecruiterSearchesMenu from '../../../menu/staff/companies/StaffCompaniesRecruiterSearchesMenu';

const RecruiterSearchesPage = () => {
	const className = 'RecruiterSearchesPage';

	const { isAdmin } = useAuthContext();

	const companySelected = useSelector(getCompanySelected);

	const [filterOptions, setFilterOptions] = useState({});
	const [refreshStatsTable, setRefreshStatsTable] = useState(0);
	const [exclude50Disabled, setExclude50Disabled] = useState(true);
	const [exclude50, setExclude50] = useState(true);
	const [excludeInactive, setExcludeInactive] = useState(true);
	const [withParentColumns, setWithParentColumns] = useState(false);
	const [searchId, setSearchId] = useState('');

	useEffect(() => {
		setExclude50Disabled(companySelected?.name === '50inTech');
	}, [companySelected]);

	const handleSearchById = (id) => {
		setSearchId(id);
		setWithParentColumns(true);
		setExcludeInactive(false);
	};

	return (
		<PageLayout
			MenuClass={StaffCompaniesRecruiterSearchesMenu}
			rightActions={() => (
				<Space direction='vertical' >
					{isAdmin && <ComputeSavedSearchesAlertsButton style={{}} />}
				</Space>
			)}
		>
			<Space size={30} style={{ marginBottom: 8 }}>
				<Space>
					<Switch
						disabled={exclude50Disabled}
						checked={exclude50Disabled ? true : !exclude50}
						onChange={() => setExclude50((p) => !p)}
					/>
					Show 50inTech
				</Space>
				<Space>
					<Switch checked={!excludeInactive} onChange={() => setExcludeInactive((p) => !p)} />
					Show inactive searches
				</Space>
				<Space>
					<Switch checked={withParentColumns} onChange={() => setWithParentColumns((p) => !p)} />
					Show 'parent' columns
				</Space>
			</Space>

			{searchId && (
				<div>
					<Space style={{ marginBottom: 20 }}>
						{searchId && <Tag
							closable
							onClose={() => setSearchId()}
							style={{
								fontWeight: 'bold',
								fontSize: 14,
							}}
							color='blue'
						>{searchId}</Tag>}
					</Space>
				</div>
			)}
			<UserSavedSearchProvider>

				<RecruiterSearchesTable
					isRefresh={refreshStatsTable}
					withParentColumns={withParentColumns}
					filterOptions={{
						...companySelected ? { companyId: documentId(companySelected) } : {},
						...filterOptions,
						searchId,
						exclude50: exclude50Disabled ? false : exclude50,
						excludeInactive,
						sortByLastLog: true,
					}}
					onFilterId={handleSearchById}
				/>

			</UserSavedSearchProvider>

		</PageLayout>
	);
};

export default RecruiterSearchesPage;
