import { useContext, useState } from 'react';
import { addMessage } from '../../actions/messages';
import AddButton from '../app/button/AddButton';
import MessagePopover from './MessagePopover';
import { FaEnvelope } from 'react-icons/fa';
import { Space } from 'antd';
import UserAvatarList from '../user/widgets/UserAvatarList';
import { useTheme } from '@emotion/react';
import { useAuthContext } from '../../hooks/useAuthContext';
import { SocketContext } from '../../contexts/SocketProvider';
import { documentId } from '../../utils/common';

const MessageBtn = (
	{
		title,
		user,
		messages = [],
		initialValues = {},
		...props
	},
) => {
	// context
	const theme = useTheme();

	const { isEditor, isStaff, isStaffView } = useAuthContext();
	const { createAction } = useContext(SocketContext);

	// state
	const [openPopover, setOpenPopover] = useState(false);

	const senders = [];
	messages.forEach((item) => {
		const { user } = item;
		senders.push(...user && !senders.some((s) => documentId(s) === documentId(user)) ? [user] : []);
	});
	// localDebug({ senders: senders.length });

	const save = async (values, title) => {
		const valuesToDb = { ...values };
		await addMessage(valuesToDb);
	};

	const handleSubmit = async (values, title = 'Send a message') => {
		await save(values, title);
		setOpenPopover(false);
	};

	const addBtn = <AddButton
		icon={<FaEnvelope style={{ marginBottom: -2 }}/>}
		onClick={() => setOpenPopover(true)}
		title={title || 'Contact this talent'}
		{...props}
		style={{ ...props?.style, ...messages?.length > 0 && { marginRight: 0, borderRadius: '4px 0 0 4px' } }}
	/>;

	const messagesSent = messages?.length > 0
		? <div style={{
			position: 'relative',
			fontSize: 12,
			fontWeight: 'bold',
			padding: '5px 8px 5px 12px',
			background: theme.color.paleblueLight,
			height: 32,
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
			borderRadius: 4,
			marginLeft: -4,
			border: `2px solid ${theme.color.blue}`,
		}}>{messages.length} message{messages.length > 1 ? 's' : ''} by &nbsp;{<UserAvatarList size={22} overflow={-5}
																							   users={senders}/>}</div>
		: null;

	return (
		<>
			<Space direction='horizontal' style={{ marginBottom: 0 }} size={0}>
				{addBtn}
				{messagesSent}
			</Space>
			<MessagePopover
				open={openPopover}
				initialValues={initialValues}
				onClose={() => setOpenPopover(false)}
				onSubmit={handleSubmit}
				onReset={() => setOpenPopover(false)}
			/>
		</>
	);
};

export default MessageBtn;
