import ReactJson from 'react-json-view';
import { useAuthContext } from '../../../hooks/useAuthContext';
import JsonPopoverLink from './JsonPopoverLink';

const JsonAdminPopoverLink = (
	{
		data,
		label,
		popover = { placement: 'left' },
		content = { style: {} },
		RenderComponent = ReactJson,
		rendererProps = { onAdd: null, onEdit: null },
		...props
	},
) => {
	const { isAdmin, isStaffView } = useAuthContext();

	if (!isAdmin || !isStaffView) return null;

	return (
		<JsonPopoverLink
			data={data}
			label={label}
			popover={popover}
			content={content}
			RenderComponent={RenderComponent}
			rendererProps={rendererProps}
			{...props}
		/>
	);
};

export default JsonAdminPopoverLink;
