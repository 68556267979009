import {
	Button, Modal, Popconfirm, Space, Tag, Tooltip,
} from 'antd';
import { canUserManageRoles, dateDiffFromNow, documentId } from '../../utils/common';

import ActionButton from '../app/table/ActionButton';
import CheckboxTable from '../app/table/CheckboxTable';
import { RiEdit2Fill } from 'react-icons/ri';
import Table from '../app/table/Table';
import TableColumns from '../app/table/TableColumns';
import { useAuthContext } from '../../hooks/useAuthContext';
import { useState } from 'react';
import { useTheme } from '@emotion/react';
import Link from '../app/Link';
import UserMailingModel from '../../models/UserMailingModel';
import { MAILING_STATUS_FAILED_ID } from '../../constants/mailing';
import BlockStaff from '../app/blocker/BlockStaff';

const AclTable = (
	{
		onDataLoaded,
		path = '/acl',
		filterOptions = {},
		isRefresh = 0,
		onSearchDataColumn,
		onToggleIsActive,
		onResendInvitation,
		onEditAcl,
		isEditor,
		...props
	},
) => {
	const theme = useTheme();

	const {
		isStaff, isAdmin, isDocumentAdmin, isStaffView, isAclEditor, currentUser,
	} = useAuthContext();
	const [isRefreshDataLayout, setIsRefreshDataLayout] = useState(0);

	const toggleIsActiveAcl = (row) => {
		const textDisable = row.isActive ? 'disable' : 'active';
		if (isAdmin) {
			onToggleIsActive({ ...row, isActive: !row.isActive });
		} else {
			Modal.confirm({
				title: `Are you sure you want to ${textDisable} this role?`,
				okText: 'Yes',
				cancelText: 'No',
				onOk() {
					onToggleIsActive({ ...row, isActive: !row.isActive });
				},
			});
		}
	};

	const handleEditAcl = (row) => {
		const initialValue = {
			...row,
			userId: documentId(row.user),
			roleId: row.role?.label,
			companyId: documentId(row?.company),
		};
		onEditAcl(initialValue);
	};

	const handleRefresh = () => {
		setIsRefreshDataLayout((prev) => prev + 1);
	};

	const userStubCol = TableColumns.userStubColumn(theme, {
		dataIndex: 'user',
		fixed: 'left',
		avatarSize: 32,
		withEmail: true,
		withProfession: true,
		withLocation: false,
		withDEICriteria: false,
		withBox: false,
		withDropdown: isStaffView,
		withPreview: false,
		withIsNew: false,
		withIsUnviewed: false,
		withTooltip: false,
		withIsNewlyOnboarded: false,
		withCoachedTag: false,
		withResumeTag: false,
		onRefresh: handleRefresh,
	});

	const jsonCol = TableColumns.jsonColumn(theme);

	const companyLogoCol = TableColumns.companyLogoColumn(theme);
	const roleLabelCol = TableColumns.userRoleLabelColumn(theme);
	const invitedUserStubCol = TableColumns.userStubColumn(theme, {
		title: 'Invited by',
		dataIndex: 'invitedUser',
		removeInColumnFilter: true,
		onRefresh: handleRefresh,
		...TableColumns.columnMinWidth(200),
	});
	const invitationStatusCol = {
		title: 'Invitation status',
		dataIndex: 'isInvitePending',
		key: 'isInvitePending',
		removeInColumnFilter: true,
		render: (value, row) => {
			if (row?.invitedUser) {
				const diff = dateDiffFromNow(row?.invitedAt, isStaffView ? 'minute' : 'hour');

				const userMailing = row?.lastMailing
					? new UserMailingModel(row?.lastMailing)
					: null;

				const resendLink = isAclEditor
					? (
						<Popconfirm
							title={(
								<div style={{ maxWidth: 300 }}>
									<b>Are you sure?</b>
									<br />
									It will send again the invitation to the customer.
								</div>
							)}
							onConfirm={() => { onResendInvitation(row); }}
						>
							<Link disabled={diff < 1}>Resend</Link>
						</Popconfirm>
					)
					: null;

				if (isStaff && isStaffView && userMailing?.status === MAILING_STATUS_FAILED_ID) {
					return (
						<Space>
							<BlockStaff withBorder={true} tagStyle={{ top: -18, left: 0, opacity: 1 }}>
								{userMailing.renderStatusTag(theme)}
							</BlockStaff>
							{resendLink}
						</Space>
					);
				}

				if (value === true) {
					return (
						<Space>
							<Tag color='blue'><b>PENDING</b></Tag>
							{resendLink}
						</Space>
					);
				}

				return <Tag color={theme.color.green}><b>ACCEPTED</b></Tag>;
			}
			return null;
		},
		...TableColumns.columnMinWidth(100),
	};

	// Invitation status
	const createdAtCol = TableColumns.createdAtColumn(theme);
	const updatedAtCol = TableColumns.updatedAtColumn(theme);
	const actionsCol = {
		title: 'actions',
		dataIndex: 'id',
		key: 'id',
		render: (value, row) => {
			return (canUserManageRoles(currentUser, [row?.role?.label])
				&& <Space direction="horizontal" size={4}>
					<Tooltip title="Toggle active">
						{TableColumns.toggleColumn(theme, toggleIsActiveAcl, { dataIndex: 'isActive' }).render(row?.isActive !== false, row)}
					</Tooltip>
					{/* <Switch onClick={() => toggleIsActiveAcl(row)} checked={row.isActive} /> */}
					<ActionButton
						tooltipTitle="Edit access"
						placement="top"
						onClick={() => handleEditAcl(row)}
						icon={<RiEdit2Fill/>}
					/>
				</Space>
			);
		},
		...TableColumns.columnFixedWidth(160),
	};

	const columns = isStaffView
		? [
			userStubCol,
			TableColumns.staffColumnWrapper(jsonCol),
			TableColumns.staffColumnWrapper(companyLogoCol),
			...isAdmin ? [
				TableColumns.adminColumnWrapper({ dataIndex: ['user', 'information', 'company'] }),
				TableColumns.adminColumnWrapper({ dataIndex: ['company', 'name'] }),
				TableColumns.adminColumnWrapper({
					render: (value, row) => <CheckboxTable
						isChecked={row?.user?.information?.company?.toLowerCase()?.trim() === row?.company?.name?.toLowerCase()?.trim()}/>,
				}),
			] : [],
			roleLabelCol,
			invitedUserStubCol,
			invitationStatusCol,
			createdAtCol,
			TableColumns.staffColumnWrapper(updatedAtCol),
			actionsCol,
		]
		: [
			userStubCol,
			roleLabelCol,
			invitedUserStubCol,
			invitationStatusCol,
			createdAtCol,
			...isDocumentAdmin ? [actionsCol] : [],
		];

	return (
		<>
			<Table
				path={path}
				onDataLoaded={onDataLoaded}
				columns={columns}
				filterOptions={filterOptions}
				isRefresh={isRefresh + isRefreshDataLayout}
				onSearchDataColumn={onSearchDataColumn}
				showTotal={(total) => `Total ${total} items`}
				limit={10}
				rowClassName={(row, index) => (row.isActive !== false ? '' : 'rowDisabled')}
				withDynamicSearch
				{...props}
			/>
		</>
	);
};

export default AclTable;
