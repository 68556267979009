import { useContext, useState } from 'react';
import DataFetcherProvider, { DataFetcherContext } from '../../../app/data/DataFetcherProvider';
import Grid from '../../../app/grid/Grid';
import GridCardSkeleton from '../../../app/grid/GridCardSkeleton';
import Box from '../../../app/box/Box';
import { Divider, Space, Tooltip } from 'antd';
import { useTheme } from '@emotion/react';
import { LocalDebug } from '../../../../utils/LocalDebug';
import { FaInfoCircle, FaSync } from 'react-icons/fa';
import { SyncOutlined } from '@ant-design/icons';

const BoardColumnCount = () => {
	const theme = useTheme();
	const { refresh, dataTotal } = useContext(DataFetcherContext);
	return (
		<Space>
			<SyncOutlined
				style={{ marginBottom: -2, color: theme.color.fitBlueElectric }}
				onClick={() => refresh({ force: true })}
			/>
			<div
				style={{
					fontSize: 10,
					color: theme.color.darkgrey,
					textAlign: 'center',
				}}
			>
				{dataTotal}
			</div>
		</Space>
	);
};
const FunnelColumn = (
	{
		id,
		title = 'Column title',
		details = 'Details',
		filterOptions,
		cardRender = (row, isLoading) => (isLoading ? 'loading...' : JSON.stringify(row || {})),
		skeletonCard = <GridCardSkeleton />,
		empty,
		refreshColumn = 0,
		style = {},
		...props
	},
) => {
	const className = 'FunnelColumn';
	const theme = useTheme();

	const [isRefresh, setIsRefresh] = useState(refreshColumn);
	const [optionsFilterTable, setOptionsFilterTable] = useState({});

	LocalDebug.logInfo({ className }, { filterOptions });

	return (
		<DataFetcherProvider
			limit={12}
			loadOnInit={true}
			path='/stats/funnel'
			withLoadMore={true}
			filterOptions={filterOptions}
		>
			<div style={{ padding: 4 }}>

				<Box
					style={{
						background: theme.color.bgGrey,
						border: 'none',
						// padding: 2,
						marginBottom: 8,
						...style,
						width: '100%',
					}}
				>
					<Space direction='vertical' style={{ width: '100%' }}>
						<Space style={{ justifyContent: 'space-between', width: '100%' }}>
							<BoardColumnCount />
							{details && (
								<Tooltip title={details}>
									<FaInfoCircle style={{ marginBottom: -2, color: theme.color.darkgrey }} />
								</Tooltip>
							)}
						</Space>
						<b style={{ fontSize: 18 }}>{title}</b>
					</Space>
				</Box>
				<Grid
					columns={1}
					gutter={[8, 8]}
					showSizeChanger={false}
					cardRender={cardRender}
					skeletonCard={skeletonCard}
					empty={empty}
					isRefresh={isRefresh}
					showTotal={(total) => `Total ${total} items`}
				/>
			</div>
		</DataFetcherProvider>
	);
};

export default FunnelColumn;
