import { Select } from 'antd';
import FormSelect from './FormSelect';
import CompanyStub from '../../company/CompanyStub';
import CompanyPlanTag from '../../company/CompanyPlanTag';

const FormCompanySelect = (
	{
		showSearch = true,
		onChange,
		defaultValue,
		placeholder = 'Select a company',
		styles = {},
		isSorted = true,
		with50inTechSorting = -1,
		withPublicationDot = false,
		withJobsPublicationDot = false,
		withPublicationOpacity = false,
		withAtsDot = false,
		withGenderScoreDot = false,
		withAtsAccess = false,
		mode = 'multiple',
		options = [],
		withCompanyPlan = false,
		withCompanyPlanExtraTags = false,
		hideChurnedCompanies = false,
		className,
		...props
	},
) => {
	const handleChange = (value, option) => {
		if (onChange) onChange(value, option);
	};

	const optionLabelProp = 'display';

	const selectionRender = (option) => {
		if (className === 'staff-company-select') {
			return (
				<div style={{ marginTop: 2 }}>
					<CompanyStub
						size={36}
						company={option?.item}
						withPublicationDot={withPublicationDot}
						withJobsPublicationDot={withJobsPublicationDot}
						withAtsDot={withAtsDot}
						withGenderScoreDot={withGenderScoreDot}
						withAtsAccess={withAtsAccess}
						withTooltip={false}
					>
						<CompanyPlanTag
							company={option?.item}
							withShortFormat={true}
							withExtraTags={withCompanyPlanExtraTags}
							style={{
								margin: 0,
							}}
						/>
					</CompanyStub>
				</div>
			);
		}
		return (
			<div
				style={{
					position: 'relative',
				}}
			>
				<CompanyStub
					size={20}
					company={option?.item}
					withPublicationDot={withPublicationDot}
					withJobsPublicationDot={withJobsPublicationDot}
					withAtsDot={withAtsDot}
					withGenderScoreDot={withGenderScoreDot}
					withAtsAccess={withAtsAccess}
					withTooltip={false}
				/>
				{withCompanyPlan && <CompanyPlanTag
					company={option?.item}
					withShortFormat={true}
					withExtraTags={withCompanyPlanExtraTags}
					style={{
						position: 'absolute',
						top: 3,
						right: 0,
						margin: 0,
					}}
				/>}
			</div>
		);
	};

	const render = (option, key) => {
		if (className === 'staff-company-select') {
			return (
				<Select.Option
					value={option?.value}
					label={option?.label}
					{...{ [optionLabelProp]: selectionRender(option) }}
					key={key}
				>
					<CompanyStub
						size={36}
						company={option?.item}
						withPublicationDot={withPublicationDot}
						withJobsPublicationDot={withJobsPublicationDot}
						withPublicationOpacity={withPublicationOpacity}
						withAtsDot={withAtsDot}
						withGenderScoreDot={withGenderScoreDot}
						withAtsAccess={withAtsAccess}
						withTooltip={false}
						style={{ margin: '2px 0' }}
					>
						{withCompanyPlan && <CompanyPlanTag
							company={option?.item}
							withShortFormat={true}
							withCompanyPlanExtraTags={withCompanyPlanExtraTags}
							style={{
								margin: 0,
								marginTop: -4,
							}}
						/>}
					</CompanyStub>
				</Select.Option>
			);
		}

		return (
			<Select.Option
				value={option?.value}
				label={option?.label}
				{...{ [optionLabelProp]: selectionRender(option) }}
				key={key}
			>
				<div
					style={{
						position: 'relative',
					}}
				>
					<CompanyStub
						size={20}
						company={option?.item}
						withPublicationDot={withPublicationDot}
						withJobsPublicationDot={withJobsPublicationDot}
						withAtsAccess={withAtsAccess}
						withTooltip={false}
					 />
					{withCompanyPlan && <CompanyPlanTag
						company={option?.item}
						withShortFormat={true}
						withCompanyPlanExtraTags={withCompanyPlanExtraTags}
						style={{
							position: 'absolute',
							left: 60,
							top: 20,
							margin: 0,
						}}
					/>}
				</div>
			</Select.Option>
		);
	};

	return (
		<FormSelect
			showSearch={showSearch}
			className={className}
			optionFilterProp='children'
			placeholder={placeholder || (mode === 'multiple'
				? 'Select one or more companies'
				: 'Select a company')
			}
			onChange={handleChange}
			defaultValue={defaultValue}
			styles={{
				minWidth: 200,
				width: '100%',
				...styles,
			}}
			mode={mode}
			{...(
				isSorted
					? {
						filterSort: (a, b) => {
							if (a?.label?.startsWith('Demo ATS')) return -1;
							if (b?.label?.startsWith('Demo ATS')) return 1;
							if (with50inTechSorting && a?.label === '50inTech') return -with50inTechSorting;
							if (with50inTechSorting && b?.label === '50inTech') return with50inTechSorting;
							return (a?.label || '').toLowerCase().localeCompare((b?.label || '').toLowerCase());
						},
					}
					: {}
			)}
			filterOption={
				(input, option) => (
					option?.label?.toLowerCase().indexOf(input?.toLowerCase()) >= 0
				)
			}
			render={render}
			options={options.filter((o) => !hideChurnedCompanies || !o?.item?.churnedAt)}
			optionLabelProp={optionLabelProp}
			{...props}
		/>
	);
};

export default FormCompanySelect;
