import { PERMS_GENDERCORE_ENABLED } from './genderscore-utils';
import MenuBase from '../MenuBase';
import { StarOutlined } from '@ant-design/icons';
import GenderScoreRankingsPage from '../../containers/genderscore/GenderScoreRankingsPage';

export const GenderscoreRankingsIcon = StarOutlined;

class GenderscoreRankingsMenu extends MenuBase {
	static props = {
		name: 'genderscore-rankings',
		path: '/genderscore/rankings',
		aliases: ['^/genderscore/rankings'],
		label: 'Rankings',
		icon: <GenderscoreRankingsIcon />,
		iconLegacy: '💯',
		Component: GenderScoreRankingsPage,
		perms: PERMS_GENDERCORE_ENABLED,
	};

	constructor() {
		super(GenderscoreRankingsMenu.props);
	}
}

export default GenderscoreRankingsMenu;
