import { useTheme } from '@emotion/react';
import { Button, Space } from 'antd';
import {
	useContext, useEffect, useMemo, useState,
} from 'react';
import { round } from '../../../../utils/common';
import { SurveyNavigationContext } from '../providers/SurveyNavigationProvider';

const SurveyInputAgree = ({
	question,
	value,
	onChange,
}) => {
	const className = 'SurveyInputAgree';

	const theme = useTheme();

	const { appLang } = useContext(SurveyNavigationContext);

	const [columns, setColumns] = useState(1);
	const [align, setAlign] = useState('vertical');
	const [width, setWidth] = useState('100%');

	useEffect(() => {
		setColumns(question?.columns || 1);
		setAlign(question?.align || 'vertical');
		setWidth(question?.width || '100%');
	}, [question]);

	const memoizedContent = useMemo(() => {
		return (
			<>
				<Space
					direction='horizontal'
					wrap={false}
				>
					{question
						?.options
						?.map((option, index) => (
							<Button
								key={index}
								onClick={() => onChange(option.value)}
								size='large'
								{...value === option.value ? { type: 'primary' } : {}}
								style={{
									width,
									minWidth: 130,
									minHeight: 60,
									height: 'unset',
									textAlign: 'left',
								}}
							>
								<span
									style={{
										verticalAlign: 'middle',
										whiteSpace: 'normal',
										lineHeight: 1.2,
										textAlign: 'center',
										width: '100%',
									}}
								>
									{option.label?.[appLang] || option.label}
								</span>
							</Button>
						))
					}
				</Space>
				<Space
					direction='horizontal'
					wrap={false}
					style={{
						marginTop: 20,
						justifyContent: 'space-between',
						width: '100%',
					}}
				>
					<div className='caption' style={{ textAlign: 'left' }}>{question.leftText?.[appLang]}</div>
					<div className='caption' style={{ textAlign: 'right' }}>{question.rightText?.[appLang]}</div>
				</Space>
			</>
		);
	}, [question, value, width, onChange, appLang, theme]);

	return memoizedContent;
};

export default SurveyInputAgree;
