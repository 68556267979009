import { DatePicker, Space } from 'antd';
import { DATE_FORMAT, GENDERSCORE_LABEL } from '../../../../constants/constant';
import FormItem from '../../form/FormItem';
import dayjs from 'dayjs';

export const companyPlanGenderScorePurchaseDatePath = ['companyPlan', 'genderScore', 'purchasedAt'];
export const companyPlanGenderScoreExpirationDatePath = ['companyPlan', 'genderScore', 'expiredAt'];

const CompanyPlanGenderScoreDatesFormItem = ({
	form,
	required = false,
	withExpirationDate = true,
	withPurchaseDateWarning = true,
	placement = 'bottomLeft',
	onChange,
}) => {
	const className = 'CompanyPlanGenderScoreDatesFormItem';
	const onPurchaseDateChange = (newPurchaseDate) => {
		const newExpirationDate = new Date(newPurchaseDate);
		newExpirationDate.setFullYear(newExpirationDate.getFullYear() + 1);
		form.setFieldValue(
			companyPlanGenderScoreExpirationDatePath,
			dayjs(newExpirationDate),
		);
		onChange?.();
	};
	return (
		<Space direction='vertical' style={{
			width: '100%',
		}}>
			<FormItem
				label={`${GENDERSCORE_LABEL} Purchase Date`}
				name={companyPlanGenderScorePurchaseDatePath}
				style={{ marginBottom: 15 }}
				rules={[
					{
						required,
						message: 'This field is required',
					},
					{
						type: 'object',
						required: false,
						message: 'Please input date',
						whitespace: true,
					},
				]}
				extra={withPurchaseDateWarning && <>
					<span style={{ color: 'black' }}>⚠️ </span>If you modify this value, <b>the {GENDERSCORE_LABEL} Expiration date will be affected </b> as well as the <b>renewal process</b>
				</>
				}
			>
				<DatePicker
					showTime={false}
					format={'DD/MM/YYYY'}
					onChange={onPurchaseDateChange}
					placement={placement}

				/>
			</FormItem>
			<FormItem
				label={`${GENDERSCORE_LABEL} Expiration Date`}
				name={companyPlanGenderScoreExpirationDatePath}
				style={{ marginBottom: 15 }}
				rules={[
					{
						required,
						message: 'This field is required',
					},
					{
						type: 'object',
						required: false,
						message: 'Please input date',
						whitespace: true,
					},
				]}
				extra={
					<>
						This value is auto-calculated as <b>1 year</b> after the {GENDERSCORE_LABEL} Purchase date,
						but you can customize it
					</>
				}
				hidden={!withExpirationDate}
			>
				<DatePicker
					showTime={false}
					format={'DD/MM/YYYY'}
					placement={placement}
					onChange={onChange}
				/>
			</FormItem>

		</Space>

	);
};

export default CompanyPlanGenderScoreDatesFormItem;
