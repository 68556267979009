import { Route } from 'react-router-dom';
import RouteWrapper from '../../../../RouteWrapper';
import { PATHS } from '../../../../constants/constant';
import {
	buildSectionQuestionPath,
} from '../../data/shared';
import UMSSurveyContainer from '../layout/UMSSurveyContainer';
import SurveySection from '../layout/SurveySection';
import SurveyQuestion from '../question/SurveyQuestion';
import SurveyScoringDetails from '../SurveyScoringDetails';
import SurveyReviewAnswers from '../SurveyReviewAnswers';
import { LocalDebug } from '../../../../utils/LocalDebug';

const SurveyRouter = ({
	baseRoutePath = PATHS.UMS_SURVEY(),
}) => {
	const className = 'SurveyRouter';

	LocalDebug.logNull({ className }, {
		baseRoutePath,
		sectionPath: buildSectionQuestionPath({ section: ':section' }),
	});
	return (
		<Route
			path={baseRoutePath}
			element={<UMSSurveyContainer />}
		>
			<Route
				path={buildSectionQuestionPath({ section: ':section' })}
				element={<RouteWrapper component={SurveySection} />}
			/>
			<Route
				path={buildSectionQuestionPath({ section: ':section', question: ':question' })}
				element={<RouteWrapper
					component={SurveyQuestion}
					info={buildSectionQuestionPath({ section: ':section', question: ':question' })}
				/>}
			/>
			<Route
				path={'scoring'}
				element={<RouteWrapper component={SurveyScoringDetails} />}
			/>
			<Route
				path={'review'}
				element={<RouteWrapper component={SurveyReviewAnswers} />}
			/>
			<Route
				path='*'
				key={'*'}
				element={<RouteWrapper component={SurveySection} />}
			/>
		</Route>
	);
};

export default SurveyRouter;
