import React, { useMemo } from 'react';
import browserHistory, { history } from '../browserHistory';
import { LocalDebug } from '../utils/LocalDebug';
import { useSearchParams } from 'react-router-dom';

export const QueryParamsContext = React.createContext();

const QueryParamsProvider = ({
	children,
}) => {
	const className = 'QueryParamsProvider';
	const [searchParams] = useSearchParams();

	console.log({ searchParams: Object.fromEntries((searchParams || {}).entries()) });

	const queryParams = useMemo(() => {
		return Object.fromEntries((searchParams || {}).entries());
	}, [searchParams]);

	const storeLocationQueryParams = ({ params = {}, pathname } = {}) => {
		localStorage.setItem(
			pathname || history?.location?.pathname,
			JSON.stringify(params || {}),
		);
	};

	const getLocationQueryParams = ({ pathname } = {}) => {
		try {
			return JSON.parse(localStorage.getItem(pathname || history?.location?.pathname) || '{}');
		} catch (e) {
			return {};
		}
	};

	const clearLocationQueryParams = ({ pathname } = {}) => {
		localStorage.removeItem(pathname || history?.location?.pathname);
	};

	const applyQueryParams = ({ query } = {}) => {
		history.push({
			...history?.location || {},
			search: browserHistory.toSearchParams(query || {}),
		});
		storeLocationQueryParams(query);
	};

	const addQueryParams = ({ params } = {}) => {
		const currentQuery = browserHistory.parseQueryParamsToObject();

		LocalDebug.logNull({ className, method: 'addQueryParams' }, { params, currentQuery });

		applyQueryParams({
			query: {
				...currentQuery,
				...params || {},
			},
		});
	};

	const removeQueryParams = ({ keys } = {}) => {
		const currentQuery = browserHistory.parseQueryParamsToObject();
		const newQuery = !keys || keys.length === 0
			? {}
			: Object.fromEntries(
				Object.entries(currentQuery)
					.filter(([key]) => !keys.includes(key)),
			);

		LocalDebug.logNull({ className, method: 'removeQueryParams' }, { keys, currentQuery, newQuery });

		applyQueryParams({
			query: newQuery,
		});
	};

	const getQueryParam = ({ name, asArray = false, separator = ',' } = {}) => {
		if (!name) return null;
		const value = queryParams?.[name];
		if (!value) return null;
		if (asArray) return value?.split?.(separator);
		return value;
	};

	const setQueryParam = ({ name, value, allowNull = false } = {}) => {
		if (!name) return;
		if (!allowNull && (!value || value?.length === 0) && value !== 0) {
			removeQueryParams({ keys: [name] });
		} else {
			addQueryParams({ params: { [name]: value } });
		}
	};

	const applyLocationQueryParams = ({ pathname } = {}) => {
		const storedParams = getLocationQueryParams({ pathname });
		const currentQuery = browserHistory.parseQueryParamsToObject();
		LocalDebug.logNull({ className, method: 'applyLocationQueryParams' }, { storedParams, currentQuery });

		// addQueryParams({ params: getLocationQueryParams({ pathname }) });
	};

	const value = {
		queryParams,
		getLocationQueryParams,
		clearLocationQueryParams,
		applyLocationQueryParams,
		addQueryParams,
		removeQueryParams,
		getQueryParam,
		setQueryParam,
	};

	return (
		<QueryParamsContext.Provider
			value={value}
		>
			{children}
		</QueryParamsContext.Provider>
	);
};

export default QueryParamsProvider;
