import StaffCommandButton from './StaffCommandButton';
import { basicActionBuilder } from '../../../actions/utils';
import { request } from '../../../services/api';

const AdminCommandGetButton = (
	{
		title,
		path,
		withPath = true,
		tooltip,
		api = request,
		params,
		disabled,
		callback,
		...props
	},
) => (
	path
		? (<StaffCommandButton
			title={title || path}
			tooltip={tooltip}
			path={path}
			withPath={withPath}
			params={params}
			callback={callback}
			dispatcher={() => basicActionBuilder({
				command: async () => (api || request).get(path, { params }),
				messageTitle: `[Admin] ${path}`,
			})}
			disabled={disabled}
			{...props}
		/>)
		: null
);

export default AdminCommandGetButton;
