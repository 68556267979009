import { optionalizeQuestion } from '../../shared';
import QUESTIONS from '../../questions';
import { GENDERSCORE_PILLAR_GENDERDATA_OPTION } from '../../../../../constants/genderscore-pillars';

const section = {
	...GENDERSCORE_PILLAR_GENDERDATA_OPTION,
	details: `
### Let's start by taking stock of the gender situation

Inclusive Tech companies are tracking the percentage of women in their workforce and specifically in Engineering, Data and Product departments.`,
	questions: [
		QUESTIONS.V3_GD_WORKFORCE_WOMEN_RATIO,
		QUESTIONS.V3_GD_WORKFORCE_WOMEN_BOARD_RATIO,
		QUESTIONS.V3_GD_WORKFORCE_WOMEN_TOPMGT_RATIO,
		QUESTIONS.V3_GD_WORKFORCE_WOMEN_OTHERMGT_RATIO,
		QUESTIONS.V3_GD_TECH_EMPLOYEES,
		QUESTIONS.V3_GD_TECH_WOMEN_RATIO,
		QUESTIONS.V3_GD_TECH_WOMEN_TOPMGT_RATIO,
		QUESTIONS.V3_GD_TECH_WOMEN_OTHERMGT_RATIO,
		optionalizeQuestion(QUESTIONS.V3_GD_TECH_WOMEN_ENGINEERING_RATIO),
		optionalizeQuestion(QUESTIONS.V3_GD_TECH_WOMEN_PRODUCT_RATIO),
		optionalizeQuestion(QUESTIONS.V3_GD_TECH_WOMEN_DATA_RATIO),
		QUESTIONS.V3_GD_LABELS_GLASSDOOR_PRESENCE,
		{
			...QUESTIONS.V3_GD_LABELS_GLASSDOOR_SCORE,
			clause: (answers) => answers?.[QUESTIONS.V3_GD_LABELS_GLASSDOOR_PRESENCE.questionId] === 'yes',
		},
		QUESTIONS.V3_GD_LABELS_EGAPRO_MEASUREMENT,
		{
			...QUESTIONS.V3_GD_LABELS_EGAPRO_SCORE,
			clause: (answers) => answers?.[QUESTIONS.V3_GD_LABELS_EGAPRO_MEASUREMENT.questionId] === 'yes',
		},
		QUESTIONS.V3_GD_LABELS_OTHER_CERTS,
		QUESTIONS.V3_GD_RETENTION_MEDIAN_TENURE,
		QUESTIONS.V3_GD_RETENTION_PROMOTION_RATE,
		QUESTIONS.V3_GD_RETENTION_WOMEN_PROMOTION_RATE,
		QUESTIONS.V3_GD_RETENTION_TURNOVER_TRACKING,
		{
			...QUESTIONS.V3_GD_RETENTION_TURNOVER_RATE,
			clause: (answers) => answers?.[QUESTIONS.V3_GD_RETENTION_TURNOVER_TRACKING.questionId]?.startsWith?.('yes'),
		},
		{
			...QUESTIONS.V3_GD_RETENTION_WOMEN_TURNOVER_RATE,
			clause: (answers) => answers?.[QUESTIONS.V3_GD_RETENTION_TURNOVER_TRACKING.questionId] === 'yes-gender-breakdown',
		},
	],
};

export default section;
