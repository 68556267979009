import ApplicationsPage from '../../containers/sourcing/ApplicationsPage';
import { PERMS_GRANT_STAFF_CLIENT_NO_EDITOR } from '../menu.utils';
import MenuBase from '../MenuBase';
import { InboxOutlined } from '@ant-design/icons';

export const GenderhireCandidatesAppliedIcon = InboxOutlined;

class GenderhireCandidatesAppliedMenu extends MenuBase {
	static props = {
		name: 'candidates-applications',
		path: '/candidates/applications',
		aliases: ['^/applications$'],
		label: 'Applications',
		icon: <GenderhireCandidatesAppliedIcon />,
		iconLegacy: '📥',
		Component: ApplicationsPage,
		perms: PERMS_GRANT_STAFF_CLIENT_NO_EDITOR,
	};

	constructor() {
		super(GenderhireCandidatesAppliedMenu.props);
	}
}

export default GenderhireCandidatesAppliedMenu;
