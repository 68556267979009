import PageLayout from '../../../components/app/layout/PageLayout';
import { useEffect, useState } from 'react';
import {
	addMultipleQueryParams, documentId, getQueryParams, removeQueryParams,
} from '../../../utils/common';
import { localDebug } from '../../../utils/LocalDebug';
import MessageTable2 from '../../../components/messages/MessageTable2';
import SectionTitle from '../../../components/app/SectionTitle';
import FeedbackBanner from '../../../components/staff/product/feedback/FeedbackBanner';
import { Space } from 'antd';
import MessageFilterModal from '../../../components/messages/MessageFilterModal';
import FilterButton from '../../../components/app/filters/FilterButton';
import InlineNavLink from '../../../components/app/button/InlineNavLink';
import { useSelector } from 'react-redux';
import { getCompanySelected } from '../../../reducers/app';
import ConfigureEmailTemplatesMenu from '../../../menu/configure/ConfigureEmailTemplatesMenu';
import ConfigureMenu from '../../../menu/configure/ConfigureMenu';
import GenderhireMessagesMenu from '../../../menu/genderhire/GenderhireMessagesMenu';

const DataMessagesPage = () => {
	const companySelected = useSelector(getCompanySelected);

	const queryParams = getQueryParams();

	const [optionsFilterTable, setOptionsFilterTable] = useState({
		companyId: documentId(companySelected),
	});
	const [isRefreshDataLayout, setIsRefreshDataLayout] = useState(0);

	useEffect(() => {
		localDebug('Messages.useEffect[companySelected]', documentId(companySelected));
		setOptionsFilterTable((prev) => ({
			...prev,
			...queryParams,
			companyId: documentId(companySelected),
		}));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [companySelected]);

	// --------------------------------------- //
	// ------------ handle filter ------------ //
	// --------------------------------------- //
	const handleFilter = (values) => {
		localDebug('Messages.handleFilter()', { values });
		const {
			companyId, createdAfter, createdBefore, ...rest
		} = values;
		addMultipleQueryParams(rest);
		setOptionsFilterTable((prev) => ({
			...prev,
			...rest,
			createdAfter,
			createdBefore,
			companyId: documentId(companySelected),
		}));
	};

	const handleClearFilter = () => {
		localDebug('Messages.handleClearFilter()', {});
		setOptionsFilterTable({ companyId: documentId(companySelected) });
		removeQueryParams();
	};

	return (
		<PageLayout
			withBackground={false}
			MenuClass={GenderhireMessagesMenu}
			filter={<FilterButton
				title="Filter list"
				modal={MessageFilterModal}
				onFilter={handleFilter}
				onClearFilter={handleClearFilter}
				initialValues={optionsFilterTable}
			/>}
		>
			<MessageTable2
				filterOptions={optionsFilterTable}
				isRefresh={isRefreshDataLayout}
			/>
		</PageLayout>
	);
};

export default DataMessagesPage;
