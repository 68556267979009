/** @jsxImportSource @emotion/react */

import { Space } from 'antd';
import { FaCheck, FaBan } from 'react-icons/fa';
import { useTheme } from '@emotion/react';

/**
 * Renders the status of the meta tags of a company
 */
const CompanyMetaStatus = ({
	company, isFullyCustomized, isEnVersion, isTranslatable,
}) => {
	const theme = useTheme();

	return (
		<Space direction='horizontal'>
			{
				isFullyCustomized
					?					<FaCheck style={{ marginBottom: -2, color: theme.color.darkturquoise }}/>
					: <FaBan style={{ marginBottom: -2, color: theme.color.red }}/>
			}
			<span>
				{company?.name}'s meta tags are <b> {!isFullyCustomized && <span>not</span>} fully customized </b>
				{
					isTranslatable
                    && (
                    	isEnVersion
                    		? <span>in 🇬🇧 <b>English</b></span>
                    		: <span>in 🇫🇷 <b>French</b></span>
                    )
				}
			</span>
		</Space>
	);
};
export default CompanyMetaStatus;
