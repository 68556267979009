const { mixColors } = require('./utils/common');

const white = '#ffffff';
const black = '#000000';
const bgGrey = '#f9f9fb';
const bgGrey2 = '#f5f5f7';
const bgPage = '#fafafb';
const blue = '#0d2b79';
const eblue = '#0011ff';
const antblue = '#1677ff';
const grey = '#9D9D9D';
const darkgrey = '#555555';
const green = '#72f5a2';
const darkgreen = 'darkgreen';
const lightgrey = 'lightgrey';
const lightgreen = 'lightgreen';
const lightblue = 'lightblue';
const orange = '#ee6d62';
const orangeLightest = mixColors(orange, white, 80);
const purple = '#b386f9';
const red = '#e03e39';
const lilac = '#881cff';
const lilacLight = mixColors(lilac, white, 40);
const lilacLighter = mixColors(lilac, white, 60);
const lilacLightest = mixColors(lilac, white, 80);
const lilacLightest2 = mixColors(lilac, white, 90);
const lilacLightest3 = mixColors(lilac, white, 95);
const paleblue = '#b6dbf8';
const paleblueLight = mixColors(paleblue, white, 50);
const yellow = '#feff8a';
const mustard = '#e3d600';
const mustardLight = mixColors(mustard, white, 75);
const mustardDark = mixColors(mustard, black, 75);
const mustard2 = '#e2d547';
const green50 = '#d8f6ef';
const green50Dark = '#a8e4d6';
const green50Darker = mixColors('#a8e4d6', black, 50);
const redLighter = mixColors(red, white, 50);
const lightturquoise = '#E6F6F6';
const darkturquoise = '#00ced1';
const darkerturquoise = mixColors(darkturquoise, black, 20);
const darkturquoiselight = mixColors(darkturquoise, white, 50);
const doveGray = '#666';
const dustyGray = '#999';
const accent = '#FF635B';
const accentLight = '#ff827c';
const pastelBlue = '#e0effd';

const fitBlue = '#101582';
const fitBlueElectric = '#0F3DFB';
const lightestFitBlueElectric = mixColors(fitBlueElectric, white, 80);
const fitPink = '#F7506E';
const fitPinkPastel = '#FF93A7';
const fitYellow = '#FFDD00';
const fitYellowPastel = '#FFED66';
const fitGreen = '#28D78A';
const fitGreenPastel = '#7DEFAA';
const fitPurple = '#925FFF';
const fitPurplePastel = '#B38FFF';
const fitBgGrey = '#F9F9FB';
const fitBgGreyDark = '#EFEFF2';
const fitBlack = '#222222';

const theme = {
	color: {
		black,
		bgGrey,
		bgGrey2,
		bgPage,
		blue,
		eblue,
		antblue,
		green,
		grey,
		red,
		white,
		purple,
		orange,
		orangeLightest,
		lightgrey,
		lightgreen,
		lightblue,
		lilac,
		lilacLight,
		lilacLighter,
		lilacLightest,
		lilacLightest2,
		lilacLightest3,
		paleblue,
		paleblueLight,
		yellow,
		mustard,
		mustardDark,
		mustardLight,
		mustard2,
		green50,
		green50Dark,
		green50Darker,
		darkgrey,
		darkgreen,
		redLighter,
		lightturquoise,
		darkturquoise,
		darkerturquoise,
		darkturquoiselight,
		doveGray,
		dustyGray,
		accent,
		accentLight,
		pastelBlue,

		fitBlue,
		fitBlueElectric,
		lightestFitBlueElectric,
		fitPink,
		fitPinkPastel,
		fitYellow,
		fitYellowPastel,
		fitGreen,
		fitGreenPastel,
		fitPurple,
		fitPurplePastel,
		fitBgGrey,
		fitBgGreyDark,
		fitBlack,
	},
};

theme.avatarColors = [
	'#ea698b', '#d55d92', '#c05299', '#ac46a1',
	'#973aa8', '#822faf', '#6d23b6', '#6411ad',
	'#571089', '#47126b', '#ff7b00', '#ff8800',
	'#ff9500', '#ffa200', '#ffaa00', '#ffb700',
	'#ffc300', '#ffd000', '#ffdd00', '#7400b8',
	'#6930c3', '#5e60ce', '#5390d9', '#4ea8de',
	'#48bfe3', '#56cfe1', '#64dfdf', '#b5e48c',
	'#99d98c', '#76c893', '#52b69a', '#34a0a4',
	'#168aad', '#1a759f', '#1e6091', '#184e77',
	'#99e2b4', '#88d4ab', '#78c6a3', '#67b99a',
	'#56ab91', '#469d89', '#358f80', '#248277',
	'#14746f', '#036666', '#90caf9', '#64b5f6',
	'#42a5f5', '#2196f3', '#1e88e5', '#1976d2',
	'#1565c0', '#0d47a1', '#007f5f', '#2b9348',
	'#55a630', '#80b918', '#aacc00', '#bfd200',
	'#d4d700', '#dddf00', '#006466', '#065a60',
	'#0b525b', '#144552', '#1b3a4b', '#212f45',
	'#272640', '#312244', '#3e1f47', '#4d194d',
	'#582f0e', '#7f4f24', '#936639', '#a68a64',
	'#b6ad90', '#c2c5aa', '#a4ac86', '#656d4a',
	'#414833', '#333d29', '#590d22', '#800f2f',
	'#a4133c', '#c9184a', '#ff4d6d', '#ff758f',
	'#ff8fa3', '#ffb3c1', '#ff6d00', '#ff7900',
	'#ff8500', '#ff9100', '#ff9e00', '#240046',
	'#3c096c', '#5a189a', '#7b2cbf', '#9d4edd',
	'#f3722c', '#f8961e', '#f9c74f', '#90be6d',
	'#43aa8b', '#577590',
];

theme.background = {
	blue: '#0d2b79',
	red: '#ec533f',
	white: '#fff',
	gradientBluePink: (deg = 35) => `linear-gradient(${deg}deg, #edf2fb, #dfe7fd, #f1c0e8)`,
	gradientBluePink2: (deg = 35) => `linear-gradient(${deg}deg, #cfd5f4, #fae1e6)`,
	gradientPurpleGreen: (deg = 35) => `linear-gradient(${deg}deg, #8458ED, #00E3DF)`,
	gradientRainbow: (deg = 135) => `linear-gradient(${deg}deg, rgb(255, 107, 107) 14.71%, rgb(255, 170, 68) 25.87%, rgb(255, 247, 65) 35.64%, rgb(99, 255, 96) 44.59%, rgb(0, 245, 212) 55.48%, rgb(0, 187, 249) 63.81%, rgb(83, 80, 255) 71.04%, rgb(155, 93, 229) 78.81%, rgb(241, 91, 181) 85.43%)`,
	gradientWhiteBlueish: (deg = 135) => `linear-gradient(${deg}deg, ${theme.color.white}, ${mixColors(theme.color.white, theme.color.fitBlueElectric, 5)})`,
	gradientPdf: (deg = 135) => `linear-gradient(${deg}deg, #101582, #e6346b)`,
};

theme.dashboardColors = {
	darkestBlue: '#1A6FC5',
	darkBlue: '#4A9AEB',
	pastelBlue: '#2997E7',
	lightBlue: '#0EAEE9',
	lightestBlue: '#2DC9FF',
	darkestPurple: '#4536E2',
	lightestPurple: '#887DFE',
	gray: '#EFEFF2',
	darkGray: '#CCCCCC',
	green: '#20AC6E',
	red: '#D4380D',
};
theme.gradient = {
	darkBlue: {
		from: theme.dashboardColors.darkBlue,
		to: theme.dashboardColors.darkestBlue,
	},
	lightBlue: {
		from: theme.dashboardColors.lightestBlue,
		to: theme.dashboardColors.darkBlue,
	},
	brightBlue: {
		from: theme.dashboardColors.lightBlue,
		to: theme.dashboardColors.lightestBlue,
	},
	purple:
	{
		from: theme.dashboardColors.darkestPurple,
		to: theme.dashboardColors.lightestPurple,
	},
};
theme.reverseGradient = ({ from, to }) => { return { to: from, from: to }; };
theme.chart = {
	bar: {
		...theme.gradient.lightBlue,
	},
	ratio: {
		...theme.gradient.lightBlue,
		bg: theme.dashboardColors.gray,
		goal: {
			reached: theme.dashboardColors.green,
			notReached: theme.dashboardColors.lightestBlue,
		},
	},
	rate: {
		...theme.reverseGradient(theme.gradient.lightBlue),
		bg: theme.dashboardColors.gray,
	},
	radial: [
		{
			...theme.gradient.purple,
			bg: theme.dashboardColors.gray,
		},
		{
			...theme.reverseGradient(theme.gradient.darkBlue),
			bg: theme.dashboardColors.gray,
		},
		{
			...theme.gradient.brightBlue,
			bg: theme.dashboardColors.gray,
		},
	],
	ranking: {
		...theme.gradient.lightBlue,
		rank: theme.dashboardColors.pastelBlue,
	},
	pctBar: {
		...theme.reverseGradient(theme.gradient.lightBlue),
		bg: theme.dashboardColors.gray,
	},
};
theme.button = {
	primary: {
		background: blue,
		color: white,
		border: `1px solid ${blue}`,
	},
	secondary: {
		background: orange,
		color: white,
		border: `1px solid ${orange}`,
	},
	disabled: {
		background: orange,
		border: `1px solid ${orange}`,
		opacity: 0.5,
		color: red,
	},
	default: {
		background: 'gray',
		color: 'black',
	},
};

export default theme;
