import roles from '../../config/roles';
import {
	PERMS_OVERRIDE_STAFF_BACKUP,
	PERM_GRANT_TYPE,
} from '../menu.utils';

export const PERMS_UMS_MANAGER_SURVEY_FILLED = [
	{
		type: PERM_GRANT_TYPE,
		roles: [
			roles.STAFF,
			roles.UMS_MANAGER,
		],
		umsEnabled: true,
		umsManagerSurveyFilled: true,
	},
	// {
	// 	...PERMS_OVERRIDE_STAFF_BACKUP || {},
	// 	umsManagerSurveyFilled: true,
	// },
];

export const PERMS_UMS_EMPLOYEE_SURVEY_FILLED = [
	{
		type: PERM_GRANT_TYPE,
		roles: [
			roles.STAFF,
			roles.UMS_EMPLOYEE,
		],
		umsEnabled: true,
		umsEmployeeSurveyFilled: true,
	},
	// {
	// 	...PERMS_OVERRIDE_STAFF_BACKUP || {},
	// 	umsEmployeeSurveyFilled: true,
	// },
];

export const PERMS_UMS_ENABLED = [
	{
		type: PERM_GRANT_TYPE,
		roles: [
			roles.STAFF,
			roles.UMS_ADMIN,
			roles.UMS_MANAGER,
			roles.UMS_EMPLOYEE,
		],
		umsEnabled: true,
	},
	// {
	// 	...PERMS_OVERRIDE_STAFF_BACKUP || {},
	// 	umsManagerSurveyFilled: true,
	// },
];

export const PERMS_UMS_ENABLED_ADMIN = [
	{
		type: PERM_GRANT_TYPE,
		roles: [
			roles.STAFF,
			roles.UMS_ADMIN,
		],
		umsEnabled: true,
	},
	// {
	// 	...PERMS_OVERRIDE_STAFF_BACKUP || {},
	// 	umsManagerSurveyFilled: true,
	// },
];

export const PERMS_UMS_ENABLED_MANAGER = [
	{
		type: PERM_GRANT_TYPE,
		roles: [
			roles.STAFF,
			roles.UMS_MANAGER,
		],
		umsEnabled: true,
	},
	// {
	// 	...PERMS_OVERRIDE_STAFF_BACKUP || {},
	// 	umsManagerSurveyFilled: true,
	// },
];

export const PERMS_UMS_ENABLED_EMPLOYEE = [
	{
		type: PERM_GRANT_TYPE,
		roles: [
			roles.STAFF,
			roles.UMS_EMPLOYEE,
		],
		umsEnabled: true,
	},
	// {
	// 	...PERMS_OVERRIDE_STAFF_BACKUP || {},
	// 	umsManagerSurveyFilled: true,
	// },
];

export const PERMS_UMS_ENABLED_MANAGER_OR_EMPLOYEE = [
	{
		type: PERM_GRANT_TYPE,
		roles: [
			roles.STAFF,
			roles.UMS_MANAGER,
			roles.UMS_EMPLOYEE,
		],
		umsEnabled: true,
	},
	// {
	// 	...PERMS_OVERRIDE_STAFF_BACKUP || {},
	// 	// umsManagerSurveyFilled: true,
	// },
];

export const getScoreColorMain = (theme) => (
	theme?.color?.blue
	|| '#0d2b79'
);

export default {
	PERMS_UMS_MANAGER_SURVEY_FILLED,
	PERMS_UMS_EMPLOYEE_SURVEY_FILLED,
	PERMS_UMS_ENABLED_MANAGER_OR_EMPLOYEE,
};
