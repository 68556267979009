import jsPDF from 'jspdf';
import domtoimage from 'dom-to-image';
import fonts from '../../../../fonts';
import {
	hexToRgb, padLeft, round, shuffleArr, sumReducer,
} from '../../../../../utils/common';
import { BIAS_ALIGNMENT_OPTIONS, BIAS_LEVEL_OPTIONS, findBias } from '../../../data/biases';
import moment from 'moment';

const r = 210 / 595;

const printCharacters = ({
	doc,
	text,
	startX,
	startY,
	width,
	fontRegular,
	fontBold,
}) => {
	const startXCached = startX;
	const fontSize = doc.getFontSize();
	const lineSpacing = doc.getLineHeightFactor() + fontSize * r;

	const textObject = getTextRows({
		doc,
		inputValue: text,
		width,
		fontRegular,
		fontBold,
	});

	textObject.map((row, rowPosition) => {
		Object.entries(row.chars).map(([key, value]) => {
			doc.setFont(value.bold ? fontBold : fontRegular);
			doc.text(value.char, startX, startY);

			if (value.char == ' ' && rowPosition < textObject.length - 1) {
				startX += row.blankSpacing * r;
			} else {
				startX += doc.getStringUnitWidth(value.char) * fontSize * r;
			}
		});
		startX = startXCached;
		startY += lineSpacing;
	});
};

const getTextRows = ({
	doc,
	inputValue,
	width,
	fontRegular,
	fontBold,
}) => {
	const regex = /(\*{2})+/g; // all "**" words
	const textWithoutBoldMarks = inputValue.replace(regex, '');
	const fontSize = doc.getFontSize() * r;

	const splitTextWithoutBoldMarks = doc.splitTextToSize(
		textWithoutBoldMarks,
		360 * r,
	);

	let charsMapLength = 0;
	let position = 0;
	let isBold = false;

	// <><>><><>><>><><><><><>>><><<><><><><>
	// power algorithm to determine which char is bold
	const textRows = splitTextWithoutBoldMarks.map((row, i) => {
		const charsMap = row.split('');

		const chars = charsMap.map((char, j) => {
			position = charsMapLength + j + i;

			let currentChar = inputValue.charAt(position);

			if (currentChar === '*') {
				const spyNextChar = inputValue.charAt(position + 1);
				if (spyNextChar === '*') {
					// double asterix marker exist on these position's so we toggle the bold state
					isBold = !isBold;
					currentChar = inputValue.charAt(position + 2);

					// now we remove the markers, so loop jumps to the next real printable char
					const removeMarks = inputValue.split('');
					removeMarks.splice(position, 2);
					inputValue = removeMarks.join('');
				}
			}

			return { char: currentChar, bold: isBold };
		});
		charsMapLength += charsMap.length;

		// Calculate the size of the white space to justify the text
		const charsWihoutsSpacing = Object.entries(chars)
			.filter(([key, value]) => value.char != ' ');
		let widthRow = 0;

		charsWihoutsSpacing.forEach(([key, value]) => {
			// Keep in mind that the calculations are affected if the letter is in bold or normal
			doc.setFont(value.bold ? fontBold : fontRegular);
			widthRow += doc.getStringUnitWidth(value.char) * fontSize;
		});

		const totalBlankSpaces = charsMap.length - charsWihoutsSpacing.length;
		const blankSpacing = (width - widthRow) / totalBlankSpaces;

		return { blankSpacing, chars: { ...chars } };
	});

	return textRows;
};

export const PDF_PAGE_TEMPLATE_COVER = 'cover';
export const PDF_PAGE_TEMPLATE_CONTENT = 'content';

export const PDF_TEXT_STYLES = {
	small: {
		font: fonts.DMSans,
		fontSize: 11,
		lineHeight: 1.3,
		marginBottom: 2,
		options: {
			baseline: 'top',
		},
	},
	normal: {
		font: fonts.DMSans,
		fontSize: 13.5,
		lineHeight: 1.3,
		marginBottom: 3,
		options: {
			baseline: 'top',
		},
	},
	title: {
		font: fonts.SpaceGrotesk,
		fontSize: 45,
		lineHeight: 1.1,
		marginBottom: 8,
		options: {
			maxWidth: 210 - 10 * 2 * 0,
			charSpace: -1,
			baseline: 'top',
		},
	},
	title2: {
		font: fonts.SpaceGrotesk,
		fontSize: 32,
		lineHeight: 1.1,
		options: {
			maxWidth: 210 - 10 * 2 * 0,
			charSpace: -0.5,
		},
	},
	subtitle: {
		font: fonts.SpaceGrotesk,
		fontSize: 18,
		lineHeight: 1.1,
		marginBottom: 8,
		options: {
			maxWidth: 210 - 10 * 2 * 0,
			// charSpace: -1,
			baseline: 'top',
		},
	},
};

export const regular = (o) => ({ ...o, font: o?.font?.regular });
export const bold = (o) => ({ ...o, font: o?.font?.bold });
export const italic = (o) => ({ ...o, font: o?.font?.italic });
export const bolditalic = (o) => ({ ...o, font: o?.font?.bolditalic });

export const PDF_TYPE_TITLE = 'title';
export const PDF_TYPE_TEXT = 'text';
export const PDF_TYPE_IMAGE = 'image';
export const PDF_TYPE_GRID = 'grid';
export const PDF_TYPE_BLOCK = 'block';
export const PDF_TYPE_ARROW = 'arrow';
export const PDF_TYPE_DIVIDER = 'divider';
export const PDF_TYPE_MOVE = 'move';
export const PDF_TYPE_RECT = 'rect';

export default class PDFBuilder {
	context;

	pdf;

	filename;

	pdfWidth;

	pdfHeight;

	pagePadding = {
		left: 10, top: 10, right: 10, bottom: 10,
	};

	x = 0;

	y = 0;

	withBorder = false;

	constructor({
		filename = 'test.pdf',
		context,
	} = {
		filename: 'test.pdf',
	}) {
		this.context = context;
		this.filename = filename || 'test.pdf';
		this.pdf = new jsPDF('p', 'mm', 'a4');
		this.pdfWidth = Math.round(this.pdf.internal.pageSize.getWidth());
		this.pdfHeight = Math.round(this.pdf.internal.pageSize.getHeight());

		console.log({ pdfWidth: this.pdfWidth, pdfHeight: this.pdfHeight });

		Object.values(fonts).forEach((fontFamily) => {
			Object.values(fontFamily).forEach((font) => {
				this.pdf.addFileToVFS(font.fontFileName, font.font);
				this.pdf.addFont(font.fontFileName, font.fontName, font.fontStyle, font.fontWeight);
			});
		});
	}

	savePages(pages, context) {
		this.buildPages(pages, context);
		console.log('saving', this.filename);
		this.pdf.save(this.filename);
	}

	buildPages(pages, context) {
		console.log('buildPages', { pages: pages?.length });
		let pageBuiltCount = 0;
		pages.forEach((page, pIndex) => {
			console.log('buildPages', { page: pIndex });
			const {
				skip, template, title, titleStyle, subtitle, items, pageCount, footer,
			} = page || {};

			if (skip) return;

			if (pageBuiltCount !== 0) this.addPage({ pageCount: pIndex + 1 });

			pageBuiltCount += 1;

			console.log('buildPages', { items: items?.length });
			if (template === PDF_PAGE_TEMPLATE_CONTENT) this.setPageTemplateContent();
			// if (template === PDF_PAGE_TEMPLATE_COVER) this.setPageTemplateCover();
			if (title) {
				this.y += ((this.addTitle({
					item: {
						text: title,
						...titleStyle || {},
						options: {
							// maxWidth: this.pdfWidth - this.n(this.pagePadding.left) - this.n(this.pagePadding.right),
							maxWidth: this.pdfWidth + 16,
						},
					},
					x: this.x,
					y: this.y,
				}))?.h || 0);
			}
			if (subtitle) {
				this.y += ((this.addSubtitle({
					item: {
						text: subtitle,
						options: {
							maxWidth: this.pdfWidth - this.n(this.pagePadding.left) - this.n(this.pagePadding.right),
						},
					},
					x: this.x,
					y: this.y - 5,
				}))?.h || 0);
			}

			(items || []).forEach((item, iIndex) => {
				const { w, h } = this.buildItem({ item, x: this.x, y: this.y });

				this.y += h;
			});

			if (footer) {
				this.addText({
					item: {
						text: '50inTech Unbiased Management Score',
						color: footer?.color || '#666',
						...regular(PDF_TEXT_STYLES.normal),
						fontSize: 9,
					},
					x: this.pagePadding.left,
					y: this.pdfHeight - this.pagePadding.bottom + 3,
				});

				this.addText({
					item: {
						text: {
							fr: `Preparé pour ${[context?.umsResult?.user?.firstName, context?.umsResult?.user?.lastName].join(' ')}, ${context?.umsResult?.company?.name} le ${moment().format('DD/MM/YYYY')}`,
							en: `Prepared for ${[context?.umsResult?.user?.firstName, context?.umsResult?.user?.lastName].join(' ')}, ${context?.umsResult?.company?.name} on ${moment().format('DD/MM/YYYY')}`,
						},
						color: footer?.color || '#666',
						...regular(PDF_TEXT_STYLES.normal),
						fontSize: 9,
						options: { align: 'right' },
					},
					x: this.pdfWidth - this.pagePadding.right,
					y: this.pdfHeight - this.pagePadding.bottom + 3,
				});
			}

			// if (pageCount) {
			// 	this.addText({
			// 		item: {
			// 			text: padLeft((pIndex + 1)?.toString() || '1', 2, '0'),
			// 			color: pageCount?.color || '#f7506e',
			// 			...regular(PDF_TEXT_STYLES.normal),
			// 			fontSize: 16,
			// 			options: { align: 'right' },
			// 		},
			// 		x: this.pdfWidth - this.pagePadding.right + 7,
			// 		y: this.pdfHeight - this.pagePadding.bottom + 2,
			// 	});
			// }
			if (pageCount) {
				this.addRect({
					item: {
						width: 13,
						height: 9,
						fill: '#ffffff',
						position: 'absolute',
					},
					x: this.pdfWidth - this.pagePadding.right - 13,
					y: 6,
				});
				this.addText({
					item: {
						text: padLeft((pIndex + 1)?.toString() || '1', 2, '0'),
						color: '#f7506e',
						...bold(PDF_TEXT_STYLES.normal),
						fontSize: 18,
						options: { align: 'right' },
					},
					x: this.pdfWidth - this.pagePadding.right,
					y: 7.5,
				});
			}
		});
	}

	n(...args) {
		let value = null;
		args.forEach((arg) => {
			if (value === null && arg !== null && arg !== undefined) {
				value = arg;
			}
		});
		return value || 0;
	}

	getText(text) { return text?.[this.context?.appLang || 'en'] || text; }

	buildItem({ item, x, y }) {
		console.log('buildItem', {
			item: item.type, text: this.getText(item?.text)?.slice(0, 10), x, y,
		});
		switch (item.type) {
			case PDF_TYPE_MOVE:
				this.x = this.n(item.x, this.x);
				this.y = this.n(item.y, this.y);
				return { h: 0 };
			case PDF_TYPE_TITLE:
				return this.addTitle({ item, x, y });
			case PDF_TYPE_BLOCK:
				return this.addBlock({ item, x, y });
			case PDF_TYPE_ARROW:
				return this.addArrow({ item, x, y });
			case PDF_TYPE_GRID:
				return this.addGrid({ item, x, y });
			case PDF_TYPE_TEXT:
				return this.addText({ item, x, y });
			case PDF_TYPE_IMAGE:
				return this.addImage({ item, x, y });
			case PDF_TYPE_DIVIDER:
				return this.addDivider({ item, y });
			case PDF_TYPE_RECT:
				return this.addRect({ item, x, y });
			default:
				return { w: 0, h: 0 };
		}
	}

	addPage({ pageCount }) {
		this.pdf.addPage();
		this.x = this.pagePadding.left;
		this.y = this.pagePadding.top;
		// const { h } = this.addDivider({ y: this.y });
		// this.y += h + 12;
	}

	setPageTemplateContent() {
		console.log('setPageTemplateContent');
		this.x = this.pagePadding.left;
		this.y = this.pagePadding.top;
		const { h } = this.addDivider({ y: this.y });
		this.y += h + 7;
	}

	setPageTemplateCover() {
		this.x = this.pagePadding.left;
		this.y = this.pagePadding.top;
		this.addRect({
			x: 0, y: 0, w: this.pdfWidth, h: this.pdfHeight,
		});
	}

	addDivider({ item, x, y }) {
		const {
			marginTop, marginBottom, color, x: forceX, y: forceY, width,
		} = item || {};
		this.pdf.setDrawColor(...hexToRgb(color || '#000000'));
		this.pdf.setLineWidth(0.2);
		this.pdf.line(
			this.n(forceX, x, this.pagePadding.left),
			this.n(forceY, y) + this.n(marginTop),
			this.n(forceX, x, this.pagePadding.left) + this.n(width, (this.pdfWidth - this.n(this.pagePadding.left) - this.n(this.pagePadding.right))),
			this.n(forceY, y) + this.n(marginTop),
		).close();
		this.y = this.n(forceY, this.y);
		return { h: this.n(marginTop) + this.n(marginBottom) };
	}

	addImage({ item, x, y }) {
		const coords = [
			this.n(item?.x, x),
			this.n(item?.y, y),
			this.n(item?.width, this.pdfWidth),
			this.n(item?.height, this.pdfHeight),
		];
		this.pdf.addImage(
			item?.uri,
			'PNG',
			...coords,
		);
		return {
			w: item.position === 'absolute' ? 0 : this.n(item?.width, this.pdfWidth),
			h: item.position === 'absolute' ? 0 : this.n(item?.height, this.pdfHeight),
		};
	}

	addBlock({ item, x, y }) {
		// console.log('addBlock', { item, x, y });

		const {
			marginTop,
			marginBottom,
			x: forceX, y: forceY,
			padding,
			options,
		} = item;

		x = this.n(forceX, x);
		y = this.n(forceY, y);

		let y2 = this.n(marginTop);
		const subOptions = {
			...item?.options || {},
			maxWidth: this.n(options?.maxWidth, options?.width, this.pdfWidth) - this.n(padding?.left) - this.n(padding?.right),
		};
		console.log('addBlock', subOptions, { x, y });
		item?.items?.forEach((subItem) => {
			const { h } = this.buildItem({
				item: {
					...subItem,
					options: {
						...subItem?.options || {},
						...subOptions || {},
					},
				},
				x: x + this.n(padding?.left),
				y: y + this.n(padding?.top) + y2,
			});
			// console.log({
			// 	subItem: subItem?.text?.slice(0, 10), h, y, y2,
			// });
			y2 += subItem.position === 'absolute'
				? 0
				: h;
		});
		return {
			h: item.position === 'absolute'
				? 0
				: y2 + this.n(marginBottom),
		};
	}

	addGrid({ item, x, y }) {
		const {
			gridTemplateColumns,
			columnGap,
			rowGap,
			columns,
			gridWidth,
			marginTop,
			marginBottom,
			options,
		} = item;

		// const { maxWidth: gridMaxWidth } = options || {};
		// console.log('addGrid', {
		// 	item, x, y, gridTemplateColumns, columnGap, options,
		// });
		let maxHeight = 0;
		const gridAvailWidth = this.n(gridWidth, this.pdfWidth - this.n(this.pagePadding?.left) - this.n(this.pagePadding?.right));
		const rows = Math.ceil(columns.length / gridTemplateColumns.length);
		let y2 = this.n(marginTop);
		columns.forEach((column, index, arr) => {
			const col = index % gridTemplateColumns.length;
			const row = Math.floor(index / gridTemplateColumns.length);

			const prevColsRatio = gridTemplateColumns.slice(0, col).reduce(sumReducer, 0);
			const fullRatio = gridTemplateColumns.reduce(sumReducer, 0);
			const availW = gridAvailWidth - (gridTemplateColumns.length - 1) * this.n(columnGap);
			const maxWidth = availW * this.n(gridTemplateColumns[index], 1) / fullRatio;

			const x2 = x
+ col * this.n(columnGap)
+ availW * prevColsRatio / fullRatio;
			// let y2 = this.n(marginTop);
			// let y2 = y + this.n(marginTop);
			// if (row === 0 && col === 0) y2 = this.n(marginTop);
			if (row > 0 && col === 0) y2 += maxHeight + this.n(rowGap);
			// y2 = row === 0 && col >= 1 ? maxHeight + this.n(rowGap) : y2;

			console.log('addGrid', index, `${row}x${col}`, {
				y: round(y, 1), x2: round(x2, 1), y2: round(y2, 1), maxHeight: round(maxHeight, 1), arr: arr?.length, prevColsRatio, fullRatio, availW, maxWidth,
			});

			let y3 = 0;
			let maxHeightItems = 0;
			column.forEach((colItem) => {
				const { h } = this.buildItem({
					item: {
						...colItem,
						gridWidth: maxWidth,
						options: {
							...colItem.options,
							...item.options,
							maxWidth,
						},
					},
					x: x2,
					y: y + y2 + y3,
				});
				y3 += colItem.position === 'absolute' ? 0 : h;
				maxHeightItems = Math.max(maxHeightItems, y3);
			});
			maxHeight = Math.max(maxHeight, maxHeightItems);
		});

		return {
			h: item.position === 'absolute'
				? 0
				: (maxHeight + this.n(marginBottom)),
		};
	}

	addTitle({ item, x, y }) {
		// console.log('addTitle', { item, x, y });

		const {
			font,
			fontSize,
			color,
			lineHeight,
			marginBottom,
			options,
			withArrow,
		} = {
			...bold(PDF_TEXT_STYLES.title),
			...item,
			options: {
				...bold(PDF_TEXT_STYLES.title)?.options || {},
				...item?.options || {},
			},
		};

		const maxWidth = options?.maxWidth || this.pdfWidth;

		this.pdf.setFont(font.fontName, font.fontStyle, font.fontWeight);
		this.pdf.setFontSize(fontSize);
		this.pdf.setTextColor(color || '#000000');
		this.pdf.setLineHeightFactor(lineHeight);

		const text = this.getText(item?.text);

		const { w, h } = this.pdf
			.getTextDimensions(text, { maxWidth });

		if (this.withBorder || item.withBorder) {
			this.pdf.setLineWidth(0.1);
			this.pdf.setDrawColor(200, 200, 200);
			this.pdf.rect(x, y, w, h).close();
		}

		if (withArrow) {
			this.addArrow({ item: { size: fontSize }, x: x + maxWidth, y });
		}

		// this.pdf.text(item.text, x || this.x, y || this.y, options || {});
		this.pdf.text(text, x, y, options || {});

		return { w, h: item.position === 'absolute' ? 0 : (h + this.n(marginBottom)) };
	}

	addArrow({ item, x, y }) {
		const size = item?.size || 45;
		const orientation = item?.orientation || 'left';

		const lineWidth = 0.9 * size / 45;
		const aLength = 9 * size / 45;
		const arrowOffset = 1 * size / 45;
		const px = x;
		const py = y + (3.5 * size / 45);
		this.pdf.setDrawColor(...hexToRgb('#f7506e'));
		this.pdf.setLineWidth(lineWidth);
		this.pdf.setLineCap('square');
		this.pdf.setLineJoin('square');

		const c = [
			{ x: px + aLength, y: py },
			{ x: px + aLength, y: py + aLength },
			{ x: px, y: py + aLength },
			{ x: px, y: py },
		];

		if (orientation === 'right') {
			// horizontal line
			this.pdf.line(c[2].x + arrowOffset, c[2].y, c[1].x, c[1].y);
			// vertical line
			this.pdf.line(c[0].x, c[0].y + arrowOffset, c[1].x, c[1].y);
			// diagonal line
			this.pdf.line(c[3].x, c[3].y, c[1].x - lineWidth / 2, c[1].y - lineWidth / 2);
			// this.pdf.line(px, py, px + aLength + lineWidth / 2, py + aLength - lineWidth / 2); // TL>BR diag
			// this.pdf.line(px + aLength, py + aLength, px - arrowOffset, py + aLength); // BL>BR bar
			// this.pdf.line(px, py + aLength, px, py + arrowOffset).close(); // BL>TL bar
		} else {
			// horizontal line
			this.pdf.line(c[2].x - aLength, c[2].y, c[1].x - aLength - arrowOffset, c[1].y);
			// vertical line
			this.pdf.line(c[3].x - aLength, c[3].y + arrowOffset, c[2].x - aLength, c[2].y);
			// diagonal line
			this.pdf.line(c[0].x - aLength, c[0].y, c[2].x - aLength + lineWidth / 2, c[2].y - lineWidth / 2);
			// this.pdf.line(px, py, px - aLength + lineWidth / 2, py + aLength - lineWidth / 2); // TR>BL diag
			// this.pdf.line(px - aLength, py + aLength, px - arrowOffset, py + aLength); // BL>BR bar
			// this.pdf.line(px - aLength, py + aLength, px - aLength, py + arrowOffset).close(); // BL>TL bar
		}

		return { w: 0, h: 0 };
	}

	addSubtitle({ item, x, y }) {
		const {
			font,
			fontSize,
			color,
			lineHeight,
			marginBottom,
			options,
			withArrow,
		} = {
			...regular(PDF_TEXT_STYLES.subtitle),
			...item,
			options: {
				...bold(PDF_TEXT_STYLES.subtitle)?.options || {},
				...item?.options || {},
			},
		};

		const maxWidth = options?.maxWidth || this.pdfWidth;

		this.pdf.setFont(font.fontName, font.fontStyle, font.fontWeight);
		this.pdf.setFontSize(fontSize);
		this.pdf.setTextColor(color || '#000000');
		this.pdf.setLineHeightFactor(lineHeight);

		const { w, h } = this.pdf
			.getTextDimensions(item.text, { maxWidth });

		if (this.withBorder || item.withBorder) {
			this.pdf.setLineWidth(0.1);
			this.pdf.setDrawColor(200, 200, 200);
			this.pdf.rect(x, y, w, h).close();
		}

		this.pdf.text(item.text, x, y, options || {});

		return { w, h: item.position === 'absolute' ? 0 : (h + this.n(marginBottom)) };
	}

	addText({ item, x, y }) {
		// console.log('addText', { item, x, y });

		const {
			font,
			fontSize,
			color,
			lineHeight,
			marginTop,
			marginBottom,
			fill,
			stroke,
			padding,
			options,
			width,
			x: forceX, y: forceY,
			dx, dy,
		} = {
			...PDF_TEXT_STYLES.normal,
			...item,
			options: {
				...PDF_TEXT_STYLES.normal?.options || {},
				...item?.options || {},
			},
		};

		const maxWidth = this.n(
			width,
			options?.maxWidth,
			this.pdfWidth - this.n(this.pagePadding?.left) - this.n(this.pagePadding?.right),
		) - this.n(padding?.left) - this.n(padding?.right);

		let text = this.getText(item?.text);

		if (text?.indexOf('•') === 0 && item?.addSpacesBeforeDot !== false) {
			text = `    ${text}`;
		}

		this.pdf.setFont(font.fontName, font.fontStyle, font.fontWeight);
		this.pdf.setFontSize(fontSize);
		this.pdf.setTextColor(color || '#000000');
		this.pdf.setLineHeightFactor(lineHeight);

		const { w, h } = this.pdf
			.getTextDimensions(text, { maxWidth });

		const px = this.n(forceX, x) + this.n(dx);
		const py = this.n(forceY, y) + this.n(dy);

		// console.log('addText', {
		// 	'text': item.text, x, y, w, h, dx, dy, px, py, font, width, 'options?.maxWidth': options?.maxWidth, 'options': { ...options, maxWidth },
		// });

		if (this.withBorder || item.withBorder) {
			this.pdf.setLineWidth(0.1);
			this.pdf.setDrawColor(100, 100, 100);
			this.pdf.rect(px, py, w, h).close();
		}

		if (fill || stroke) {
			this.addRect({
				item: {
					fill,
					stroke,
					width: w + this.n(padding?.left) + this.n(padding?.right),
					height: h + this.n(padding?.top) + this.n(padding?.bottom),
				},
				x: px,
				y: py + this.n(marginTop),
			});
		}

		this.pdf.text(
			text,
			px + this.n(padding?.left) + (options?.align === 'center' ? w / 2 : 0),
			py + this.n(padding?.top) + this.n(marginTop),
			{ ...options, maxWidth },
		);

		// console.log('Text added', { text: this.getText(item?.text)?.slice(0, 10), y, h });
		return { w, h: item.position === 'absolute' ? 0 : (h + this.n(marginTop) + this.n(marginBottom)) };
	}

	addRect({
		item, x, y,
	}) {
		const w = this.n(item?.width, this.pdfWidth);
		const h = this.n(item?.height, this.pdfHeight);
		// this.pdf.setLineWidth(0);
		if (item?.fill) this.pdf.setFillColor(...hexToRgb(item?.fill || '#ff0000'));
		if (item?.stroke) this.pdf.setDrawColor(...hexToRgb(item?.stroke || '#ff0000'));
		// this.pdf.setDrawColor(0, 255, 0);
		// this.pdf.setFillColor(255, 0, 0);
		let style = 'S';
		switch (true) {
			case !!(item?.stroke && item?.fill):
				style = 'FD';
				break;
			case !!item?.fill:
				style = 'F';
				break;
			default:
				style = 'S';
				break;
		}
		const args = [
			this.n(item?.x, x),
			this.n(item?.y, y) + this.n(item?.marginTop),
			w,
			h,
			style,
		];
		// console.log('addRect', args);
		this.pdf.rect(...args);
		return { w, h: (item?.position === 'absolute' ? 0 : h) + this.n(item?.marginTop) };
		/*
this.pdf.advancedAPI((pdf) => {
const rw = this.n(w, this.pdfWidth) + this.n(padding?.left) + this.n(padding?.right);
const rh = this.n(h, this.pdfHeight) + this.n(padding?.top, 0) + this.n(padding?.bottom);
const patternKey = `pattern-${new Date().getTime()}-${Math.round(Math.random() * 100000)}`;
pdf.addShadingPattern(
patternKey,
new ShadingPattern(
'axial',
[this.n(x), this.n(y), this.n(x) + rw, this.n(y) + rh],
[
	{ offset: 0, color: hexToRgb('#101582') },
	{ offset: 1, color: hexToRgb('#e6346b') },
],
),
);
pdf
.rect(this.n(x), this.n(y), rw, rh)
.fill({
key: patternKey,
matrix: [0, 0, 0, 0, 0, 0, 0, 0],
});
// pdf.clip();
});
*/
	}

	walkBiasBlock({
		doc, bias, index, arr, context,
	}) {
		return {
			type: PDF_TYPE_BLOCK,
			options: {
				maxWidth: doc.pdfWidth - this.n(doc.pagePadding?.left) - this.n(doc.pagePadding?.right),
			},
			items: [
				{
					type: PDF_TYPE_GRID,
					gridTemplateColumns: [1, 1],
					columnGap: 8,
					columns: [
						[
							{
								type: PDF_TYPE_TITLE,
								text: bias.label,
								...bold(PDF_TEXT_STYLES.title2),
								withArrow: true,
							},
						],
						[
							{
								type: PDF_TYPE_TEXT,
								text: bias.desc,
								...regular(PDF_TEXT_STYLES.normal),
								fontSize: 12,
							},
						],
					],
				},
				{
					type: PDF_TYPE_TEXT,
					text: {
						en: 'Some examples:',
						fr: 'Quelques exemples :',
					},
					...bolditalic(PDF_TEXT_STYLES.normal),
					marginTop: 1,
					marginBottom: 2,
					color: '#666666',
				},
				...(bias?.examples?.[context?.appLang || 'en'] || [])
					?.map((example) => ({
						type: PDF_TYPE_TEXT,
						text: `• ${example}`,
						...italic(PDF_TEXT_STYLES.small),
						fontSize: 10,
						color: '#666666',
					})),
				...(index < (arr.length - 1))
					? [{
						type: PDF_TYPE_DIVIDER,
						marginTop: 6,
						marginBottom: 7,
					}]
					: [],
			],
		};
	}

	walkRiskBlock(risk, index, arr) {
		return {
			type: PDF_TYPE_BLOCK,
			items: [
				{
					type: PDF_TYPE_GRID,
					gridTemplateColumns: [1, 1.4],
					columnGap: 6,
					marginBottom: -2,
					columns: [
						[
							{
								type: PDF_TYPE_TITLE,
								text: risk.label,
								...bold(PDF_TEXT_STYLES.title2),
								fontSize: 28,
								withArrow: true,
							},
						],
						[
							{
								type: PDF_TYPE_TEXT,
								text: risk.details,
								...regular(PDF_TEXT_STYLES.small),
								fontSize: 11,
							},
							{
								type: PDF_TYPE_TEXT,
								text: {
									en: `Related biases: ${risk.biases
										.filter(({ weight }) => weight > 1)
										.map(({ bias }) => findBias(bias)?.label).join(', ')}`,
									fr: `Biais liés : ${risk.biases
										.filter(({ weight }) => weight > 1)
										.map(({ bias }) => findBias(bias)?.label).join(', ')}`,
								},
								...italic(PDF_TEXT_STYLES.small),
								color: '#666',
								fontSize: 10,
								marginTop: 1,
								marginBottom: 4,
							},
						],
					],
				},
				// {
				// 	type: PDF_TYPE_TEXT,
				// 	text: `Related biases: ${risk.biases
				// 		.filter(({ weight }) => weight > 1)
				// 		.map(({ bias }) => findBias(bias)?.label).join(', ')}`,
				// 	...italic(PDF_TEXT_STYLES.small),
				// 	color: '#666',
				// 	fontSize: 10,
				// },
				...(index < (arr.length - 1))
					? [{
						type: PDF_TYPE_DIVIDER,
						marginTop: 3,
						marginBottom: 5,
					}]
					: [],
			],
		};
	}

	async makeImageUri({ id, context, skip }) {
		console.log('makeImageUri', { id, skip });

		if (skip) return;

		let imageUri = context.imageUris.get(id);
		imageUri = null;
		if (!imageUri) {
			console.log('makeImageUri', 'creating png', id);
			const node = document.getElementById(id);
			if (!node) console.log('makeImageUri', 'node not found');
			// console.log('makeImageUri', 'node', node);
			imageUri = await domtoimage.toPng(node);
			// console.log('makeImageUri', 'imageUri', imageUri);
			context.imageUris.set(id, imageUri);
		}
		// console.log('makeImageUri', { id, imageUri });
		return imageUri;
	}

	async getPageTemplateCover({ context, skip }) {
		return [
			{
				type: PDF_TYPE_MOVE,
				x: 0,
				y: 0,
			},
			{
				type: PDF_TYPE_IMAGE,
				uri: await this.makeImageUri({ id: context.gradientId, context, skip }),
				x: 0,
				y: 0,
				width: this.pdfWidth,
				height: this.pdfHeight,
				position: 'absolute',
			},
			{
				type: PDF_TYPE_MOVE,
				x: this.pagePadding.left,
				y: this.pagePadding.top,
			},
		];
	}

	getTagBlock({
		text,
		fill = '#e6346b',
		stroke,
		width = 36,
		height = 8,
		color = '#ffffff',
		maxWidth,
		...props
	}) {
		return {
			type: PDF_TYPE_BLOCK,
			...props,
			items: [
				{
					type: PDF_TYPE_RECT,
					width,
					height,
					fill,
					stroke,
					position: 'absolute',
					...props?.style?.rect || {},
				},
				{
					type: PDF_TYPE_TEXT,
					text,
					...bold(PDF_TEXT_STYLES.normal),
					marginBottom: 7,
					color: color || '#ffffff',
					padding: { left: width / 2, top: 2 },
					width: width * 2,
					...props?.style?.text || {},
					options: {
						align: 'center',
						...props?.style?.text?.options || {},
					},
				},
			],
		};
	}

	getBiasLevelTagBlock({
		bias,
		value,
		level,
	}) {
		return [
			{
				type: PDF_TYPE_TEXT,
				...bold(PDF_TEXT_STYLES.normal),
				text: bias.label,
				// marginTop: 2,
				marginBottom: 0,
			},
			{
				type: PDF_TYPE_GRID,
				gridTemplateColumns: [1, 1],
				columnGap: 0,
				columns: [
					[
						{
							type: PDF_TYPE_TEXT,
							...bold(PDF_TEXT_STYLES.title2),
							text: value.toString(),
							position: 'absolute',
						},
						{
							type: PDF_TYPE_TEXT,
							...regular(PDF_TEXT_STYLES.normal),
							fontSize: 18,
							text: '/100',
							dx: 14,
							dy: 5.5,
						},
					],
					[
						this.getTagBlock({
							text: level.adjective,
							fill: level.tag.background,
							color: level.tag.color,
							marginTop: 1.6,
							height: 9,
							style: {
								text: { marginTop: 1 },
							},
						}),
					],
				],
			},
			// {
			// 	type: PDF_TYPE_DIVIDER,
			// 	color: '#cccccc',
			// },
			// {
			// 	type: PDF_TYPE_TEXT,
			// 	...regular(PDF_TEXT_STYLES.normal),
			// 	text: bias.label,
			// 	marginTop: 2,
			// },
		];
	}

	getRadialBiasLevelTagBlock({
		bias,
		value,
		level,
		...props
	}) {
		return {
			type: PDF_TYPE_BLOCK,
			...props,
			items: [
				{
					type: PDF_TYPE_TEXT,
					...bold(PDF_TEXT_STYLES.normal),
					fontSize: 14,
					lineHeight: 1,
					text: bias.label2 || bias.label,
					marginBottom: 0.5,
					width: 44,
				},
				// {
				// 	type: PDF_TYPE_TEXT,
				// 	...bold(PDF_TEXT_STYLES.title2),
				// 	text: value.toString(),
				// 	position: 'absolute',
				// },
				// {
				// 	type: PDF_TYPE_TEXT,
				// 	...regular(PDF_TEXT_STYLES.normal),
				// 	fontSize: 18,
				// 	text: '/100',
				// 	dx: 14,
				// 	dy: 5.5,
				// },
				{
					type: PDF_TYPE_TEXT,
					...bold(PDF_TEXT_STYLES.title2),
					fontSize: 18,
					text: `${value.toString()}%`,
					width: 30,
					marginTop: 3,
					padding: {
						left: 1.5, right: 1.5, top: 1, bottom: 1.5,
					},
					options: {
						align: 'center',
					},
					fill: '#EE82EE',
					color: '#ffffff',
					position: 'absolute',
				},
				{
					type: PDF_TYPE_TEXT,
					...bold(PDF_TEXT_STYLES.title2),
					fontSize: 18,
					text: `${value.toString()}%`,
					width: 30,
					marginTop: 3,
					padding: {
						left: 1.5, right: 1.5, top: 1, bottom: 1.5,
					},
					options: {
						align: 'center',
					},
					dx: 15,
					fill: '#00CED1',
					color: '#ffffff',
					position: 'absolute',
				},
				this.getTagBlock({
					text: level.adjective,
					fill: level.tag.background,
					color: level.tag.color,
					marginTop: 3.5,
					marginTop: 13.5,
					height: 9,
					dx: 1,
					style: {
						text: { marginTop: 1 },
					},
				}),
			],
		};
	}

	async getBiasAlignmentTagImage({
		context,
		alignment,
		skip,
		...props
	}) {
		return {
			type: PDF_TYPE_IMAGE,
			uri: await this.makeImageUri({
				id: context.getBiasAlignmentId({ alignment, context }),
				context,
				skip,
			}),
			...props,
		};
	}

	async getBiasLevelTagImage({
		context,
		level,
		skip,
		...props
	}) {
		return {
			type: PDF_TYPE_IMAGE,
			uri: await this.makeImageUri({
				id: context.getBiasLevelId({ level, context }),
				context,
				skip,
			}),
			...props,
		};
	}

	async getScoreBiasPersonaTagBlock({
		context,
		bias,
		persona,
		skip,
		...props
	}) {
		return {
			type: PDF_TYPE_IMAGE,
			uri: await this.makeImageUri({
				id: context.getScoreBiasPersonaId({ bias, persona, context }),
				context,
				skip,
			}),
			...props,
		};
	}

	getProfileBiasLevelTagBlock({
		...props
	}) {
		const option = shuffleArr(BIAS_LEVEL_OPTIONS.slice())[0];
		const value = 2;
		return {
			type: PDF_TYPE_BLOCK,
			...props,
			items: [
				this.getTagBlock({
					text: option.adjective,
					fill: option.tag.background,
					color: option.tag.color,
					height: 9,
					dx: 1,
					marginBottom: -2,
					style: {
						text: { marginTop: 1 },
					},
				}),
				{
					type: PDF_TYPE_TEXT,
					...bold(PDF_TEXT_STYLES.title2),
					text: value.toString(),
					position: 'absolute',
				},
				{
					type: PDF_TYPE_TEXT,
					...regular(PDF_TEXT_STYLES.normal),
					fontSize: 18,
					text: '/7',
					dx: 7,
					dy: 5.5,
				},
				{
					type: PDF_TYPE_TEXT,
					...regular(PDF_TEXT_STYLES.small),
					lineHeight: 1.1,
					text: 'biases are above average',
					color: '#999999',
					marginTop: 2,
				},
			],
		};
	}

	getProfileAlignmentLevelTagBlock({
		...props
	}) {
		const option = shuffleArr(BIAS_ALIGNMENT_OPTIONS.slice())[0];
		const value = 2;
		return {
			type: PDF_TYPE_BLOCK,
			...props,
			items: [
				this.getTagBlock({
					text: option.adjective,
					fill: option.tag.background,
					color: option.tag.color,
					height: 9,
					dx: 1,
					marginBottom: -2,
					style: {
						text: { marginTop: 1 },
					},
				}),
				{
					type: PDF_TYPE_TEXT,
					...bold(PDF_TEXT_STYLES.title2),
					text: value.toString(),
					position: 'absolute',
				},
				{
					type: PDF_TYPE_TEXT,
					...regular(PDF_TEXT_STYLES.normal),
					fontSize: 18,
					text: '/7',
					dx: 7,
					dy: 5.5,
				},
				{
					type: PDF_TYPE_TEXT,
					...regular(PDF_TEXT_STYLES.small),
					lineHeight: 1.1,
					text: 'biases are above average',
					color: '#999999',
					marginTop: 2,
				},
			],
		};
	}
}
