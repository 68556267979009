import { Space } from 'antd';
import { useTheme } from '@emotion/react';
import AddButton from '../../app/button/AddButton';
import { useEffect, useState } from 'react';
import { buildCloudinaryUrl } from '../../../utils/common';
import { FiUpload } from 'react-icons/fi';
import CompanyLogo from '../CompanyLogo';
import { LocalDebug } from '../../../utils/LocalDebug';
import CloudinaryImageUploader from '../../app/CloudinaryImageUploader';
import config from '../../../config/config';

const CompanyLogoUploader = (
	{
		defaultValue,
		onSuccess,
		buttonText = 'Upload',
	},
) => {
	const className = 'AccountAvatarUploader';
	const [imageUrl, setImageUrl] = useState(defaultValue);

	const onUpload = () => {
		LocalDebug.logUseEffect(
			{ className, method: 'onUpload' },
			{ 'uploader.cloudinaryPublicId': uploader.cloudinaryPublicId },
		);
		if (uploader.cloudinaryPublicId) {
			setImageUrl(buildCloudinaryUrl(uploader.cloudinaryPublicId));
		}
	};

	const uploader = new CloudinaryImageUploader({
		// uploadPreset: config.cloudinary.upload_preset_company, // Create a new preset
		onSuccess: onUpload,
	});

	useEffect(() => {
		setImageUrl(defaultValue);
	}, [defaultValue]);

	useEffect(() => {
		LocalDebug.logUseEffect({ className, effects: 'imageUrl' }, { imageUrl });
		if (imageUrl) {
			onSuccess(imageUrl);
		}
	}, [imageUrl]);

	const theme = useTheme();
	return (
		<Space direction="vertical" style={{ justifyContent: 'center', alignItems: 'center' }}>
			{imageUrl
				&& <CompanyLogo
					size={200}
					company={{ logo: imageUrl }}
					withTooltip={false}
				/>
			}
			<AddButton
				icon={<FiUpload/>}
				title={buttonText}
				color={theme.color.accent}
				onClick={() => { uploader.showWidget(); }}
				style={{ padding: '10px 32px 8px', borderRadius: 6, height: 'unset' }}
			/>
		</Space>
	);
};

export default CompanyLogoUploader;
