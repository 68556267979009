import { useContext, useEffect } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router';
import SurveyNavigationProvider from './SurveyNavigationProvider';
import { useSelector } from 'react-redux';
import { getCompanyGenderScoreSurveyDraftSelected, getCompanySelected } from '../../../reducers/app';
import CompanyGridPicker from '../../company/CompanyGridPicker';
import CompanyModel from '../../../models/CompanyModel';
import { documentId } from '../../../utils/common';
import { useAuthContext } from '../../../hooks/useAuthContext';
import { SurveyContext } from './SurveyProvider';
import { useTheme } from '@emotion/react';
import SurveyLeaveModal from './SurveyLeaveModal';
import GenderScoreSurveyStart from '../GenderScoreSurveyStart';
import GenderScoreSurveyResume from '../GenderScoreSurveyResume';
import GenderScoreSurveyNotAllowed from '../GenderScoreSurveyNotAllowed';
import SurveySubmissionPending from './SurveySubmissionPending';
import { LocalDebug } from '../../../utils/LocalDebug';
import { PATHS } from '../../../constants/constant';

const SurveyContainer = () => {
	const className = 'SurveyContainer';
	const theme = useTheme();
	const location = useLocation();
	const navigate = useNavigate();

	const {
		surveyDraft,
		setSurveyDraft,
		createOrUpdateSurveyDraft,
		isSurveySubmissionPending,
	} = useContext(SurveyContext);
	const {
		isStaff, isStaffView, isDocumentAdmin, isDocumentEditorOnly, currentUser,
	} = useAuthContext();
	const companySelected = useSelector(getCompanySelected);
	const draftSelected = useSelector(getCompanyGenderScoreSurveyDraftSelected);
	const companyModel = new CompanyModel(companySelected);

	useEffect(() => {
		LocalDebug.logInfo({ className, effects: 'draftSelected, setSurveyDraft' }, { draftSelected });
		setSurveyDraft(draftSelected);
	}, [draftSelected, setSurveyDraft]);

	useEffect(() => {
		LocalDebug.logNull({ className, effects: 'surveyDraft, location' }, { surveyDraft, location });
		const lastVisitedPath = location.pathname;

		if (lastVisitedPath.indexOf(PATHS.GENDERSCORE_SURVEY()) !== 0) return;

		if (surveyDraft && surveyDraft?.lastVisitedPath !== lastVisitedPath) {
			createOrUpdateSurveyDraft({
				data: { lastVisitedPath },
				withDispatch: true,
			});
		}
	}, [surveyDraft, location]);

	if (!currentUser) return null;

	if (!companySelected) return <CompanyGridPicker />;

	if (isSurveySubmissionPending) return <SurveySubmissionPending />;

	if (!companyModel.isGenderScoreEnabled() && location.pathname !== '/') {
		navigate('/');
	}

	if (!draftSelected) {
		if (!companyModel.isGenderScorePassingAllowed({
			isStaff, isStaffView, isDocumentAdmin, isDocumentEditorOnly, currentUser,
		})) {
			return <GenderScoreSurveyNotAllowed companyModel={companyModel}/>;
		}
		const currentUserDraft = companyModel
			.getGenderScoreSurveyDraftByUser({ userId: documentId(currentUser) });

		return currentUserDraft
			? <GenderScoreSurveyResume />
			: <GenderScoreSurveyStart />;
	}

	return (
		<div
			className='survey'
			style={{
				position: 'relative',
			}}
		>
			<SurveyNavigationProvider>

				<Outlet />

				<SurveyLeaveModal />

			</SurveyNavigationProvider>
		</div>
	);
};

export default SurveyContainer;
