import { openExternalUrl } from '../../../utils/common';
import { FaRegPlayCircle } from 'react-icons/fa';
import Link from '../Link';

const HelpVideoLink = (
	{
		url,
		...props
	},
) => {
	if (!url) return null;

	return (
		<Link
			style={{
				fontSize: 12,
				fontWeight: 'bold',
				textTransform: 'uppercase',
				...props?.style,
			}}
			onClick={() => {
				openExternalUrl(url);
			}}
		>
			Help <FaRegPlayCircle style={{ marginBottom: -2 }}/>
		</Link>
	);
};

export default HelpVideoLink;
