import { useTheme } from '@emotion/react';
import { Space } from 'antd';
import GenderScoreComparisonLineBlock from './GenderScoreComparisonLineBlock';

/**
 *
 * @param {number} score - Main number to compare
 * @param {number} avgScore - Number to which compare the score
 * @param {string} avgContextLabel - Optional. Identifies the average context
 * @param {number} width - Optional. Width of the block. Default: 180
 * @param {number} fontSize - Optional. Font size of the main score.
 * The rest of fonts are proportional to this number. Default: 40
 * @returns GenderScoreCompareAverageBlock
 */
const GenderScoreCompareAverageBlock = ({
	score,
	avgScore,
	avgContextLabel,
	width = 180,
	fontSize = 40,
	...props
}) => {
	const className = 'GenderScoreCompareAverageBlock';

	const theme = useTheme();

	const avgDiff = score - avgScore;

	const avgDiffSentence = <>
		<span style={{ fontWeight: 500 }}>
			{avgDiff < 0 ? 'Underperform' : 'Overperform'}
		</span> of
	</>;

	return <div
		style={{
			display: 'grid',
			justifyContent: 'center',
			justifyItems: 'center',
			...props?.style,
		}}
	>
		<p style={{
			fontSize,
			fontWeight: 700,
			textAlign: 'center',
			margin: 0,
		}}>
			{avgScore}
		</p>
		{avgContextLabel && <p style={{
			fontSize: fontSize * 0.35,
			fontWeight: 500,
			textAlign: 'center',
			margin: 0,
		}}>
			Average<br/>{avgContextLabel}
		</p>}
		<GenderScoreComparisonLineBlock
			current={score}
			previous={avgScore}
			diffLabel={'pts'}
			secondaryLabel={avgDiffSentence}
			secondaryPosition='before'
			withTooltip={false}
		/>
	</div>;
};
export default GenderScoreCompareAverageBlock;
