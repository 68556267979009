import Link from '../components/app/Link';
import { openExternalUrl } from '../utils/common';

const CSM_DETAILS = {
	defaultCsm: {
		user: {
			email: 'support@50intech.com',
			information: {
				avatar: 'https://res.cloudinary.com/dkatmtbbc/image/upload/v1695933383/app/Logos%2050inTech/50intech-50i-avatar.jpg',
				position: 'Customer Success Manager',
				company: '50inTech',
			},
		},
		buildBody: ({ user, theme }) => (
			<>
				<span>
					We’re here to help!
				</span>
				<span>
					For assistance or support, please contact us:&nbsp;
					<Link
						onClick={() => openExternalUrl(`mailto:${user?.email}`)}
						style={{ margin: 0 }}
					>
						{user?.email}
					</Link>
				</span>
			</>
		),
	},
	nadineCsm: {
		user: {
			email: 'nadine@50intech.com',
			firstName: 'Nadine',
			lastName: 'Utuje',
			company: {
				name: '50inTech',
				logo: 'https://res.cloudinary.com/dkatmtbbc/image/upload/v1/company/pomivtsuixgj0efg9uyn.png',
			},
			information: {
				avatar: 'https://res.cloudinary.com/dkatmtbbc/image/upload/v1/profile/dyhj8kssf41xmtabcwgi.jpg',
				position: 'Customer Success Manager',
				company: '50inTech',
			},
		},
		buildBody: ({ user, theme }) => (
			<>
				<span>
					Your dedicated Customer Success Manager is&nbsp;
					<span style={{ color: theme?.color?.darkgrey }}>{user?.firstName} {user?.lastName}</span>.
				</span>
				<span>
					If you need any support, please contact her:&nbsp;
					<Link
						onClick={() => openExternalUrl(`mailto:${user?.email}`)}
						style={{ margin: 0 }}
					>
						{user?.email}
					</Link>
				</span>
			</>
		),
	},
	carolineCsm: {
		user: {
			email: 'caroline@50intech.com',
			firstName: 'Caroline',
			lastName: 'Ramade',
			company: {
				name: '50inTech',
				logo: 'https://res.cloudinary.com/dkatmtbbc/image/upload/v1/company/pomivtsuixgj0efg9uyn.png',
			},
			information: {
				avatar: 'https://res.cloudinary.com/dkatmtbbc/image/upload/v1/profile/image7_d6z7h4_rdg1ze.jpg',
				position: 'CEO',
				company: '50inTech',
			},
		},
		buildBody: ({ user, theme }) => (
			<>
				<span>
					Your dedicated Customer Success Manager is&nbsp;
					<span style={{ color: theme?.color?.darkgrey }}>{user?.firstName} {user?.lastName}</span>.
				</span>
				<span>
					If you need any support, please contact her:&nbsp;
					<Link
						onClick={() => openExternalUrl(`mailto:${user?.email}`)}
						style={{ margin: 0 }}
					>
						{user?.email}
					</Link>
				</span>
			</>
		),
	},
};

export default CSM_DETAILS;
