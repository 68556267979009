import { isEqual } from 'lodash';
import { doLogout } from '../../actions/app';
import { actionWithLoader, push } from '../../actions/utils';
import { menuBuilder as genderHirePlansMenuBuilder } from '../../menu/menu-genderhire-plans';
import { menuBuilder as genderScorePlansMenuBuilder } from '../../menu/menu-genderscore-plans';
import { menuBuilder as umsPlansMenuBuilder } from '../../menu/menu-ums-plans';
import AuthService from '../../services/auth';
import { PATHS } from '../../constants/constant';
import { isGenderScorePlan, isUMSPlan } from '../../constants/property/business-plan';
import { LocalDebug } from '../LocalDebug';

const className = 'permission';

export function createOnEnterPermitted(route, store, withLoader = true) {
	// LocalDebug.logInfo({ className, method: 'createOnEnterPermitted' }, 'loadCompanies', route?.name, { route });
	return async (nextState, replace, callback) => {
		const { onEnter } = route;
		// chech user when refresh page or menu click
		const { data: { user } } = await AuthService.getCurrentUser();
		// TODO: delete forceAccess usage, it's not used anymore so always false
		const forceAccess = nextState?.location?.query?.access || false;
		const isUserAllowed = isRoutePermitted(route, user, forceAccess);
		if (!isUserAllowed && !user) {
			push(PATHS.LOGIN);
			return;
		}

		// go to logout
		if (!isUserAllowed) {
			doLogout()(store.dispatch, store.getState);
			return;
		}

		let result;
		if (onEnter) {
			// LocalDebug.logInfo({ className, method: 'createOnEnterPermitted' }, 'loadCompanies', route?.name, { onEnter });
			const dispatchingFunction = onEnter();
			if (withLoader) {
				result = actionWithLoader(dispatchingFunction)(
					store.dispatch,
					store.getState,
				);
			} else {
				result = dispatchingFunction(store.dispatch, store.getState);
			}
		}
		if (result && result.then) {
			await result;
		}
	};
}

export function isRoutePermitted(route, user, forceAccess = false) {
	if (!user || !route) {
		// localDebug('no user => route NOT permitted');
		return false;
	}

	if (forceAccess) {
		// localDebug('forceAccess => route IS permitted');
		return true;
	}
	const { permissions: routePermission } = route;
	const { permissions } = user.acls;

	if (!permissions) {
		// localDebug('no permissions => route NOT permitted');
		return false;
	}

	if (permissions?.length && routePermission?.length) {
		// localDebug({ route })
		// localDebug({ acls: user.acls })
		// localDebug({ permissions })
		// localDebug({ routePermission })

		// localDebug(permissions?.length, routePermission?.length)
		const match = permissions.some((p) => routePermission.some((r) => isEqual(r, p)));
		if (match) {
			// localDebug('match found => route IS permitted');
		} else {
			// localDebug('no match => route NOT permitted');
		}
		return match;
	}
	return true;
}

const isPermittedItem = (item, user, currentLocation) => {
	const { pathname, query } = currentLocation;
	const forceAccess = (item?.path === pathname && query?.access) || false;
	return isRoutePermitted(item, user, forceAccess);
};

export function getPermittedMenuItems({
	user,
	planValue,
	currentLocation,
	...context
}) {
	const menuItems = [];
	let sourceMenuItems = genderHirePlansMenuBuilder(context);
	LocalDebug.logNull({ className, method: 'getPermittedMenuItems' }, {
		user,
		planValue,
		isDocumentEditorOnly: context.isDocumentEditorOnly,
		isCompanySelected: context.isCompanySelected,
	});
	if (isUMSPlan(planValue)) {
		sourceMenuItems = umsPlansMenuBuilder(context);
	}
	if (isGenderScorePlan(planValue)) {
		sourceMenuItems = genderScorePlansMenuBuilder(context);
	}
	sourceMenuItems.forEach((item) => {
		if (!item) {
			menuItems.push(null);
			return;
		}
		if (item.children) {
			const childrenMenu = item.children.filter((child) => isPermittedItem(child, user, currentLocation));
			if (childrenMenu.length) {
				menuItems.push({ ...item, children: childrenMenu });
			}
		} else if (isPermittedItem(item, user, currentLocation)) {
			menuItems.push(item);
		}
	});
	return menuItems;
}
