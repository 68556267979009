import { useTheme } from '@emotion/react';
import {
	Space, Tag, Tooltip, Typography,
} from 'antd';

const { Paragraph } = Typography;

/**
 * Displays the current value for a field in a form
 * It displays a tag to indicate whether the value is default or custom
 * @param {*}
 * @returns
 */
const FormCurrentValue = (
	{
		text,
		title,
		tooltip,
		isDefault,
	},
) => {
	const theme = useTheme();

	return <div style={{
		background: theme.color.fitBgGrey,
		borderLeft: `3px solid ${theme.color.fitYellow}`,
		// width: '100%',
		padding: '10px 20px',
		marginBottom: 20,
	}}>
		<Space style={{
			width: '100%',
			justifyContent: 'space-between',
			marginBottom: 15,
			fontWeight: 'bold',
		}}>
			{title}
			{
				<Tooltip title={tooltip}>
					{
						isDefault
							? <Tag color="orange">DEFAULT VALUE</Tag>
							: <Tag color="cyan">CUSTOM VALUE</Tag>
					}
				</Tooltip>
			}
		</Space>
		<Paragraph style={{ paddingLeft: '20px', fontSize: '90%' }}>
			{text}
		</Paragraph>
	</div>;
};

export default FormCurrentValue;
