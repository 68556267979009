import {
	Button, Popconfirm, Popover, Space, Spin, Tooltip,
} from 'antd';
import { FaBan, FaCheck, FaQuestion } from 'react-icons/fa';
import AtsLogo from '../../ats/AtsLogo';
import SourcingModel from '../../../models/SourcingModel';
import { useAuthContext } from '../../../hooks/useAuthContext';
import { useSelector } from 'react-redux';
import { getCompanySelected } from '../../../reducers/app';
import { useEffect, useState } from 'react';
import { LocalDebug } from '../../../utils/LocalDebug';
import {
	CANDIDATE_POOL_ICON,
	TAG_ADMIN,
	TAG_STAFF,
} from '../../../constants/constant';
import CompanyStub from '../../company/CompanyStub';
import UserAvatarList from '../../user/widgets/UserAvatarList';
import UserButtonBadge from '../../user/buttons/UserButtonBadge';
import { documentId } from '../../../utils/common';
import GenderhireCandidatesAtsMenu from '../../../menu/genderhire/GenderhireCandidatesAtsMenu';

const SourcingAtsSender = (
	{
		sourcing,
		handleSend,
		...props
	},
) => {
	const className = 'SourcingAtsSender';

	const {
		isAdmin, isStaffView, companyRecruiterDocIds, getUserCompanies,
	} = useAuthContext();
	const companySelected = useSelector(getCompanySelected);
	// const companies = useSelector(getCompanies);
	const sourcingModel = new SourcingModel(sourcing);
	const [buttonTitle, setButtonTitle] = useState('Send to ATS');
	const [disabled, setDisabled] = useState(true);
	const [tooltipTitle, setTooltipTitle] = useState(null);

	const sourcers = [];
	const atsSourcingsCount = 0;
	sourcing?.user?.sourcedAtsSent?.forEach((item) => {
		const { manager } = item;
		// if (item.atsSync?.push) {
		//     atsSourcingsCount += 1;
		sourcers.push(
			...manager
				&& !sourcers.some((s) => (
					documentId(s)?.toString?.() === documentId(manager)?.toString?.()))
				? [manager]
				: [],
		);
		// }
	});
	// if (sourcingModel?.user?.slug === 'lisa-de-spain') {
	//     LocalDebug.logInfo({ className, method: 'init' }, { sourced: sourcing?.user?.sourced?.length, atsSourcingsCount });
	// }

	useEffect(() => {
		const effects = 'sourcing, isStaffView, companyRecruiterDocIds, companySelected';
		if (sourcingModel.isPushPending) {
		    setDisabled(true);
		    setButtonTitle(<>Sending&nbsp;&nbsp;<Spin size='small'/></>);
		    setTooltipTitle(null);
		    return;
		}
		// if (!companies?.length) return;
		const sourcingCompanyId = sourcing?.companyId;
		// LocalDebug.logUseEffect({ className, effects }, { sourcingCompanyId, isStaffView, companyRecruiterDocIds, companySelected });
		if (companyRecruiterDocIds.includes(sourcingCompanyId)) {
			setDisabled(false);
			setTooltipTitle(null);
			return;
		}
		if (isStaffView) {
			setDisabled(false);
			setTooltipTitle(<><b>{TAG_STAFF} Action enabled</b></>);
			return;
		}
		if (isAdmin) {
			setDisabled(false);
			setTooltipTitle(<><b>{TAG_ADMIN}Action enabled</b><br/></>);
			return;
		}
		setTooltipTitle(<><b>Action Disabled</b><br/>You're not registered as a recruiter for this company</>);
		setDisabled(false);
	}, [sourcing, isStaffView, companyRecruiterDocIds, companySelected]);

	if (sourcingModel.opportunityId) {
		return null;
	}

	let content = <Button
		type='primary'
		icon={<AtsLogo ats={sourcingModel.atsProvider} size={16} inlineDisplay={true} withTooltip={false}/>}
		disabled={disabled}
		size={10}>
		&nbsp;{buttonTitle}
	</Button>;

	if (disabled || tooltipTitle) {
		content = <Tooltip title={tooltipTitle}>
			{content}
		</Tooltip>;
	}

	const candiatesAtsMenu = new GenderhireCandidatesAtsMenu();

	if (sourcing?.user?.sourcedAtsToSendCount + sourcing?.user?.sourcedAtsSentCount) {
		content =			<Popover
			title={<>{candiatesAtsMenu.icon} {candiatesAtsMenu.label}</>}
			content={<Space direction='vertical'>
				{sourcing?.user?.sourcedAtsSentCount > 0 && <div>
					<AtsLogo ats={sourcingModel.atsProvider} size={16}/> <strong>Sent to
						ATS</strong> {sourcing?.user?.sourcedAtsSentCount} times
					{sourcers?.length > 0 && <> by <UserAvatarList users={sourcers} size={28}/></>}
				</div>}
				{sourcing?.user?.sourcedAtsToSendCount > 0 && <div style={{ opacity: 0.7 }}>
					<AtsLogo ats={sourcingModel.atsProvider} size={16}/> <strong>Pending sending to
						ATS</strong> {sourcing?.user?.sourcedAtsToSendCount} times
				</div>}
			</Space>}>
			<UserButtonBadge count={sourcing?.user?.sourcedAtsToSendCount + sourcing?.user?.sourcedAtsSentCount}>
				{content}
			</UserButtonBadge>
		</Popover>;
	}

	return disabled
		? content
		: <>
			<Popconfirm
				title={<div style={{ maxWidth: 300, whiteSpace: 'normal' }}
				>
					{<>
						<strong>Send to your ATS?</strong>
						<ul>
							<li>You will then be able to manage the status from your own ATS dashboard</li>
							<li>This candidate will be moved to the "{candiatesAtsMenu.label}" section in 50inTech.</li>
						</ul>
						{isStaffView
							&& <div>
								<CompanyStub company={sourcing?.company}/>
								{isAdmin
									&& <span style={{ fontSize: 10, lineHeight: 1 }}>
										<br/>companyRecruiterDocIds: {companyRecruiterDocIds?.join(', ')}
										<br/>companies: {getUserCompanies().map((c) => c.name).join(', ')}
										<br/>companySelected: {companySelected?.name || 'No company selected'}
									</span>
								}
							</div>
						}
					</>}
				</div>}
				okText={<span style={{ padding: '0 4px' }}><FaCheck style={{ marginBottom: -2 }}/> Confirm</span>}
				cancelText={<span style={{ padding: '0 4px' }}><FaBan style={{ marginBottom: -2 }}/> Cancel</span>}
				onConfirm={() => { handleSend(sourcing); }}
			>
				{content}
			</Popconfirm>
		</>;
};

export default SourcingAtsSender;
