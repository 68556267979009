import { useDispatch } from 'react-redux';
import { registerUser } from '../../../actions/users';
import { useAuthContext } from '../../../hooks/useAuthContext';
import Popin from '../../app/modal/Popin';
import UserInvitedActivationForm from './UserInvitedActivationForm';
import { LocalDebug } from '../../../utils/LocalDebug';
import FormBoxTitle from '../../app/form/FormBoxTitle';
import { documentId } from '../../../utils/common';
import ModalContainerBase from '../../../contexts/modals/ModalContainerBase';

const UserInvitedActivationPopup = (
	{
		open,
		onSuccess,
	},
) => {
	const className = 'UserInvitedActivationPopup';

	const { currentUser } = useAuthContext();

	const dispatch = useDispatch();

	const onUserRegistered = () => {
		LocalDebug.logInfo({ className, method: 'onUserRegistered' });
		onSuccess?.();
	};

	const saveUser = (values) => {
		const userToDb = {
			...values,
			isInvitePending: true,
			userId: documentId(currentUser),
		};

		LocalDebug.logInfo({ className, method: 'saveUser' }, userToDb);

		dispatch(registerUser(userToDb, onUserRegistered));
	};

	return (
		<ModalContainerBase
			cssClassName='modal-large no-close'
			open={open}
			width={600}
			onCancel={null}
		>
			<FormBoxTitle
				title={<>Hello there!<br/>Please complete your 50inTech account.</>}
				details='We just need some info about you to finish setting up your account!'
				style={{
					details: {
						fontSize: 13,
						fontWeight: 'bold',
					},
				}}
			/>

			<UserInvitedActivationForm
				initialValues={{
					...currentUser,
					'information.position': currentUser?.information?.position,
					'information.company': currentUser?.information?.company,
				}}
				onClick={(e) => { e?.stopPropagation(); }}
				onSubmit={saveUser}
			/>
		</ModalContainerBase>
	);
};

export default UserInvitedActivationPopup;
