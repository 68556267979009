import {
	Button, Form, Input, Spin,
} from 'antd';
import FormItem from '../../company/form/FormItem';
import { useAuthContext } from '../../../hooks/useAuthContext';
import { useTheme } from '@emotion/react';
import { useContext } from 'react';
import { UMSInviteContext } from '../UMSInviteProvider';
import { CheckOutlined, LoadingOutlined } from '@ant-design/icons';
import { requiredEmailRules } from '../../../utils/common';

const UMSInviteEmailForm = ({
	form,
	initialValues = {},
	onSubmit,
	children,
}) => {
	const theme = useTheme();

	const { isStaff } = useAuthContext();
	const { checkEmailLoading } = useContext(UMSInviteContext);

	return (
		<Form
			form={form}
			name="inviteUserForm"
			initialValues={{
				...isStaff
					? { email: 'jlondeix@gmail.com' }
					: {},
				...initialValues,
			}}
			onFinish={onSubmit}
		>
			<FormItem
				name="email"
				label="Teammate's email"
				rules={requiredEmailRules}
			>
				<Input />
			</FormItem>

			<div
				style={{
					display: 'flex',
					justifyContent: 'flex-end',
					alignItems: 'center',
					marginTop: 10,
				}}
			>
				<Button
					type='primary'
					size='large'
					style={{
						fontWeight: 'bold',
						borderRadius: 5,
					}}
					htmlType='submit'
				>
					Next step {
						checkEmailLoading
							? <Spin
								indicator={<LoadingOutlined
									spin
									style={{
										width: 24,
										color: 'white',
										fontSize: 14,
									}}
								/>}
							/>
							: <CheckOutlined />
					}
				</Button>
			</div>

		</Form>
	);
};

export default UMSInviteEmailForm;
