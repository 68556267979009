import { HeartOutlined } from '@ant-design/icons';
import MenuBase from '../MenuBase';
import { PERMS_GRANT_STAFF } from '../menu.utils';
import StaffHealthPage from '../../containers/staff/StaffHealthPage';

export const StaffHealthIcon = HeartOutlined;

class StaffHealthMenu extends MenuBase {
	static props = {
		name: 'staff-health',
		path: '/staff/health',
		label: 'Health',
		icon: <StaffHealthIcon />,
		iconLegacy: '🧰',
		Component: StaffHealthPage,
		onEnterProps: {
			shouldLoadAtsData: true,
			shouldLoadCategoryTags: true,
		},
		perms: PERMS_GRANT_STAFF,
	};

	constructor() {
		super(StaffHealthMenu.props);
	}
}

export default StaffHealthMenu;
