import { useContext, useState } from 'react';
import { useTheme } from '@emotion/react';
import { AppstoreAddOutlined } from '@ant-design/icons';
import {
	Flex, Select, Space,
} from 'antd';
import {
	arrayToObjectListByProp,
	documentId,
} from '../../../utils/common';
import { LocalDebug } from '../../../utils/LocalDebug';
import GenderScorePillarLogoLabel from '../genderscore/pillars/GenderScorePillarLogoLabel';
import CompanyActionCard from './CompanyActionCard';
import CompanyActionModel from '../../../models/CompanyActionModel';
import {
	GS_ACTION_BUDGET_OPTIONS,
	GS_ACTION_COMPLEXITY_OPTIONS,
	GS_ACTION_PEOPLE_OPTIONS,
	GS_PILLAR_CATEGORY_OPTIONS,
	findActionPillarCategory,
} from './action-plan';
import CompanyActionModal from './action/CompanyActionModal';
import { AppDataContext } from '../../../contexts/AppDataProvider';

const CompanyActionPeopleSelect = ({
	values,
	onChange,
}) => <Select
	value={values}
	onChange={onChange}
	options={GS_ACTION_PEOPLE_OPTIONS}
	mode={'multiple'}
	allowClear={true}
	maxTagCount={0}
	placeholder={'Filter by people'}
	maxTagPlaceholder={(v) => `${v.length} people${v?.length > 1 ? '' : ''} selected`}
	style={{
		minWidth: 240,
	}}
/>;

const CompanyActionComplexitySelect = ({
	values,
	onChange,
}) => <Select
	value={values}
	onChange={onChange}
	options={GS_ACTION_COMPLEXITY_OPTIONS}
	mode={'multiple'}
	allowClear={true}
	maxTagCount={0}
	placeholder={'Filter by complexity'}
	maxTagPlaceholder={(v) => `${v.length} complexit${v?.length > 1 ? 'ies' : 'y'} selected`}
	style={{
		minWidth: 240,
	}}
/>;

const CompanyActionBudgetSelect = ({
	values,
	onChange,
}) => <Select
	value={values}
	onChange={onChange}
	options={GS_ACTION_BUDGET_OPTIONS}
	mode={'multiple'}
	allowClear={true}
	maxTagCount={0}
	placeholder={'Filter by budget'}
	maxTagPlaceholder={(v) => `${v.length} budget${v?.length > 1 ? 's' : ''} selected`}
	style={{
		minWidth: 240,
	}}
/>;

const CompanyActionPillarCategorySelect = ({
	pillar,
	values,
	onChange,
}) => {
	const options = Object
		.entries(
			arrayToObjectListByProp(
				GS_PILLAR_CATEGORY_OPTIONS.filter((c) => !pillar || c.pillar === pillar),
				'pillar',
			),
		).map(([categoryPillar, categories]) => ({
			label: <GenderScorePillarLogoLabel pillar={categoryPillar} />,
			options: categories,
		}));
	return (
		<Select
			value={values}
			onChange={onChange}
			options={options}
			mode={'multiple'}
			allowClear={true}
			maxTagCount={0}
			placeholder={'Filter by pillar category'}
			maxTagPlaceholder={(v) => `${v.length} categor${v?.length > 1 ? 'ies' : 'y'} selected`}
			style={{
				minWidth: 240,
			}}
		/>
	);
};

const CompanyActionLibrary = ({
	actions,
	pillar,
	viaModal = false,
	onAddAction,
	onEditAction,
	onDeleteAction,
	isSendActionPending,
	setIsSendActionPending,
	limit,
	...props
}) => {
	const className = 'CompanyActionLibrary';

	const theme = useTheme();

	const { getGenderScoreActions } = useContext(AppDataContext);

	const [openActionModal, setOpenActionModal] = useState(false);
	const [selectedAction, setSelectedAction] = useState();

	const [pillarCategoryFilters, setPillarCategoryFilters] = useState([]);
	const [budgetFilters, setBudgetFilters] = useState([]);
	const [complexityFilters, setComplexityFilters] = useState([]);
	const [peopleFilters, setPeopleFilters] = useState([]);

	// const [actions, setActions] = useState((() => {
	const actionsLibrary = (
		getGenderScoreActions({
			actions, pillar, pillarCategories: pillarCategoryFilters,
		})
			?.map((content, i) => new CompanyActionModel({ id: `id-${i + 1}`, ...content }))
			?.filter((action) => (budgetFilters.length === 0 || budgetFilters.includes(action.budget)))
			?.filter((action) => (complexityFilters.length === 0 || complexityFilters.includes(action.complexity)))
			?.filter((action) => (peopleFilters.length === 0 || peopleFilters.includes(action.people)))
			|| []
	);

	LocalDebug.logInfo({ className }, {
		actions,
		genderScoreActions: getGenderScoreActions(),
		actionsLibrary,
	});
	// LocalDebug.logInfo({ className }, {
	// 	budgetFilters,
	// 	complexityFilters,
	// 	peopleFilters,
	// 	pillarCategoryFilters,
	// 	actions: actions?.length,
	// 	actionsLibrary: actionsLibrary?.length,
	// });

	const handlePillarCategoryFiltersChange = (values) => {
		LocalDebug.logInfo({ className, method: 'handlePillarCategoryFiltersChange' }, { values });
		setPillarCategoryFilters(values);
	};

	const handleBudgetFiltersChange = (values) => {
		LocalDebug.logInfo({ className, method: 'handleBudgetFiltersChange' }, { values });
		setBudgetFilters(values);
	};

	const handleComplexityFiltersChange = (values) => {
		LocalDebug.logInfo({ className, method: 'handleComplexityFiltersChange' }, { values });
		setComplexityFilters(values);
	};

	const handlePeopleFiltersChange = (values) => {
		LocalDebug.logInfo({ className, method: 'handlePeopleFiltersChange' }, { values });
		setPeopleFilters(values);
	};

	const handleOpenAction = (newSelectedAction) => {
		LocalDebug.logInfo({ className, method: 'handleOpenAction' }, { newSelectedAction });
		setSelectedAction(newSelectedAction);
		if (newSelectedAction) setOpenActionModal(true);
	};

	return (
		<div
			style={{
				height: '100%',
			}}
		>

			<Flex
				justify='space-between'
				align='center'
				style={{
					marginBottom: 12,
				}}
			>
				<div>
					{pillar && (
						<GenderScorePillarLogoLabel
							iconSize={28}
							labelSize={16}
							pillar={pillar} />
					)}
					<h2>
						<AppstoreAddOutlined /> Actions Library
						&nbsp;
						<span
							style={{
								fontSize: 12,
								fontWeight: 'normal',
								color: '#999',
							}}
						>
							{actionsLibrary?.length} action{actionsLibrary?.length > 1 ? 's' : ''}
						</span>
					</h2>
				</div>

			</Flex>

			<Space
				style={{
					marginBottom: 12,
				}}
			>
				<CompanyActionPillarCategorySelect
					pillar={pillar}
					values={pillarCategoryFilters}
					onChange={handlePillarCategoryFiltersChange}
				/>
				<CompanyActionComplexitySelect
					values={complexityFilters}
					onChange={handleComplexityFiltersChange}
				/>
				<CompanyActionBudgetSelect
					values={budgetFilters}
					onChange={handleBudgetFiltersChange}
				/>
				<CompanyActionPeopleSelect
					values={peopleFilters}
					onChange={handlePeopleFiltersChange}
				/>
			</Space>
			<div
				style={{
					display: 'grid',
					gridTemplateColumns: 'repeat(3, 1fr)',
					columnGap: 20,
					rowGap: 20,
					width: '100%',
					overflow: 'scroll',
					height: 'calc(100% - 100px)',
				}}
			>
				{actionsLibrary
					?.slice?.(0, limit)
					?.map?.((action) => (
						<div
							key={documentId(action)}
							style={{
							// border: '1px solid blue'
							}}
						>
							<CompanyActionCard
								draggable={false}
								viaModal={viaModal}
								action={action}
								onOpenAction={handleOpenAction}
								withAddButton={true}
								onAddAction={onAddAction}
								onEditAction={onEditAction}
								onDeleteAction={onDeleteAction}
								isSendActionPending={isSendActionPending}
								setIsSendActionPending={setIsSendActionPending}
							/>
						</div>
					))}

			</div>

			<CompanyActionModal
				open={openActionModal}
				setOpen={setOpenActionModal}
				action={selectedAction}
				withAddButton={true}
				onAddAction={({ action }) => {
					onAddAction?.({ action });
					setOpenActionModal(false);
				}}
				onDeleteAction={({ action }) => {
					onDeleteAction?.({ action });
					setOpenActionModal(false);
				}}
				onEditAction={onEditAction}
				isSendActionPending={isSendActionPending}
				setIsSendActionPending={setIsSendActionPending}
			/>

		</div>
	);
};

export default CompanyActionLibrary;
