import { useEffect, useState } from 'react';
import { ENGLISH_LANG } from '../../../../constants/constant';
import CompanyService from '../../../../services/company';
import { arrayWrap, getTranslatedOptions } from '../../../../utils/common';
import CompanyFieldNotProvided from '../shared/CompanyFieldNotProvided';
import { useTheme } from '@emotion/react';
import { FaCog } from 'react-icons/fa';
import { LocalDebug } from '../../../../utils/LocalDebug';

const CompanyIndustryDisplay = (
	{
		company,
		lang = ENGLISH_LANG,
	},
) => {
	const className = 'CompanyIndustryDisplay';

	const theme = useTheme();

	const [value, setValue] = useState([]);

	const field = 'industry';

	useEffect(() => {
		const findValues = async () => {
			const result = await CompanyService.getIndustryOptions();
			const options = getTranslatedOptions(result?.data?.options) || [];
			const industries = arrayWrap(company?.getTranslationFieldFallback(field, lang)) || [];
			setValue(industries?.map((item) => options.find((o) => o.value === item)?.label || item));
		};
		findValues();
	}, [company, lang]);

	if (!value || value?.length === 0) {
		return <CompanyFieldNotProvided>Industry</CompanyFieldNotProvided>;
	}

	return (
		<div
			style={{
				fontSize: 12,
				color: theme.color.grey,
			}}
		>
			<FaCog style={{ marginBottom: -2 }}/> {arrayWrap(value).join(', ')}
		</div>
	);
};

export default CompanyIndustryDisplay;
