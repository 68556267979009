import GridValueCard from '../../../app/card/GridValueCard';
import UserProfileLanguagesCardBody from './UserProfileLanguagesCardBody';
import { NOT_PROVIDED_YET_LABEL } from '../../../../constants/constant';
import UserLanguage from '../../widgets/UserLanguage';

const UserProfileLanguagesCard = (
	{
		user,
		title = 'Languages',
		hideIfEmpty = true,
		notProvided = NOT_PROVIDED_YET_LABEL,
		children,
		...props
	},
) => {
	return (user?.hasCareerLanguage() || !hideIfEmpty
		? <GridValueCard
			title={title}
			{...props}
		>
			{children || <UserLanguage user={user} notProvided={notProvided}/>}
		</GridValueCard>
		: null
	);
};

export default UserProfileLanguagesCard;
