import Popin from '../app/modal/Popin';
import UserMessageForm from './UserMessageForm';
import { FaEnvelope } from 'react-icons/fa';
import { Space } from 'antd';
import useBodyScrollBlocker from '../../hooks/useBodyScrollBlocker';
import ModalContainerBase from '../../contexts/modals/ModalContainerBase';

const MessagePopover = (
	{
		open,
		initialValues = {},
		onSubmit,
		onReset,
	},
) => {
	const className = 'MessagePopover';
	// localDebug({ initialValues });
	useBodyScrollBlocker(open, className);

	const title = <Space direction='horizontal' align='left'>
		<FaEnvelope style={{ marginBottom: -2 }}/>
		Contact a talent
	</Space>;

	const handleSubmit = async (values) => {

	};

	return (
		<ModalContainerBase
			open={open}
			onCancel={onReset}
			width={1060}
		>
			<UserMessageForm
				initialValues={initialValues}
				onSubmit={onSubmit}
				onReset={onReset}/>
		</ModalContainerBase>
	);
};

export default MessagePopover;
