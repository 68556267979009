import { LocalDebug } from '../utils/LocalDebug';
import { request } from './api';

const className = 'ResourceService';

const updateOrCreateResource = (data) => {
	LocalDebug.logInfo({ className, method: 'updateOrCreateResource' }, { data });
	return request.post('/genderscore/resource', data);
};
const deleteResource = (resourceId) => {
	return request.delete(`/genderscore/resource/${resourceId}`);
};

const uploadFile = (data) => {
	return request.post('/upload/image-info?folder=resources', data);
};

const findAll = async (options) => {
	const dataParams = {
		params: {
			...(options || {}),
		},
	};
	return request.get('/genderscore/resource', dataParams);
};

const ResourceService = {
	updateOrCreateResource,
	findAll,
	uploadFile,
	deleteResource,
};

export default ResourceService;
