import { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router';
import { useTheme } from '@emotion/react';
import { Modal } from 'antd';
import { BiExit } from 'react-icons/bi';
import { UMS_LABEL, PATHS } from '../../../../constants/constant';
import { SurveyContext } from '../providers/SurveyProvider';
import {
	EVENT, buildEventLabel, buildEventSection, pushClick,
} from '../../../../contexts/EventPushProvider';
import ReactMarkdown from 'react-markdown';

const SurveyLeaveModal = () => {
	const className = 'SurveyLeaveModal';

	const theme = useTheme();
	const navigate = useNavigate();

	const {
		eventContext,
		openLeaveModal,
		setOpenLeaveModal,
		dispatchSelectedSurveyAndReload,
		t,
	} = useContext(SurveyContext);

	const eventProps = {
		...eventContext,
		...buildEventSection(EVENT.SECTION.SURVEY_LEAVE_MODAL),
	};

	return (
		<Modal
			centered
			open={openLeaveModal}
			onCancel={() => setOpenLeaveModal(false)}
			onOk={async () => {
				setOpenLeaveModal(false);
				await dispatchSelectedSurveyAndReload(null);
				navigate(PATHS.UMS_SURVEY());
			}}
			closable={false}
			cancelButtonProps={{ size: 'large' }}
			okButtonProps={{ size: 'large' }}
			cancelText={<b>{t('leave-modal.cancel')}</b>}
			okText={<b>
				{t('leave-modal.confirm')}
				<BiExit
					style={{
						fontSize: 16,
						marginBottom: -3,
						marginLeft: 4,
					}}
				/>
			</b>}
			style={{
				maxWidth: 480,
			}}
		>
			<div
				style={{
					lineHeight: 1.3,
					padding: '10px 0 6px',
					fontSize: 16,
				}}
			>
				<h2>{t('leave-modal.title')}</h2>
				<ReactMarkdown>{t('leave-modal.body')}</ReactMarkdown>
			</div>
		</Modal>
	);
};

export default SurveyLeaveModal;
