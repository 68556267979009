import { useMemo, useState } from 'react';
import { useTheme } from '@emotion/react';
import { Button, Progress, Space } from 'antd';
import { DownloadOutlined, SendOutlined } from '@ant-design/icons';
import { LocalDebug } from '../../../utils/LocalDebug';
import { UMS_LABEL } from '../../../constants/constant';
import { openExternalUrl } from '../../../utils/common';
import config from '../../../config/config';

const UMSAdminResultsStatusBanner = ({
	isLoading,
	statusData,
}) => {
	const className = 'UMSAdminResultsStatusBanner';

	const theme = useTheme();

	const [bannerStyle, setBannerStyle] = useState({});
	const [bannerContent, setBannerContent] = useState();

	useMemo(() => {
		LocalDebug.logUseEffect({ className, effects: 'memo' }, { isLoading, statusData });

		if (isLoading) {
			setBannerStyle({ background: 'gray' });
			setBannerContent(
				<div><h2>Loading...</h2></div>,
			);
			return;
		}

		if (statusData?.isResultReady) {
			setBannerStyle({ background: theme.background.gradientPurpleGreen() });
			setBannerContent(
				<>
					<div>
						<h2>Results are ready!</h2>
						<p>
							A sufficient number of your managers and employees have filled the survey.
							<br />The results of the <b>{UMS_LABEL}</b> for your company
							are now <b>available.</b>
						</p>
						<Button
							type='primary'
							size='large'
							style={{ fontWeight: 'bold ' }}
							onClick={() => openExternalUrl(
								`${config.storagePublicRoot}/ums/demo/Unbiased%20Mgt%20Score%20-%20Admin%20Demo%20(EN).pdf?magic=${new Date().getTime()}`,
							)}
						>
							Download your results (PDF) <DownloadOutlined />
						</Button>
					</div>
					<Progress
						type='circle'
						size={80}
						percent={statusData?.resultReadyPercent}
						strokeColor={theme.color.white}
						format={(value) => (
							<span
								style={{
									fontWeight: 'bold',
									color: theme.color.white,
								}}
							>
								{value}<span style={{ fontSize: 16 }}>%</span>
							</span>
						)}
					/>
				</>,
			);
		} else {
			setBannerStyle({ background: theme.background.gradientPdf() });
			setBannerContent(
				<>
					<div>
						<h2>Results are not ready yet...</h2>
						<p>
							We need a sufficient number of managers and employees to fill their surveys
							before being able to calculate your results.
							<br />You can send a reminder to people who have yet to complete their surveys:
						</p>
						<Button
							type='primary'
							size='large'
							style={{ fontWeight: 'bold ' }}
						>
							Send a reminder <SendOutlined />
						</Button>
					</div>
					<Progress
						type='circle'
						size={80}
						strokeColor={theme.color.white}
						percent={statusData?.resultReadyPercent}
						format={(value) => (
							<span
								style={{
									fontWeight: 'bold',
									color: theme.color.white,
								}}
							>
								{value}<span style={{ fontSize: 16 }}>%</span>
							</span>
						)}
					/>
				</>,
			);
		}
	}, [isLoading, statusData]);

	return (
		<Space
			style={{
				justifyContent: 'space-between',
				alignItems: 'center',
				padding: '30px 40px',
				background: theme.color.fitBlueElectric,
				color: theme.color.white,
				width: '100%',
				borderRadius: 20,
				margin: '20px 0',
				...bannerStyle,
			}}
		>
			{bannerContent}
		</Space>
	);
};

export default UMSAdminResultsStatusBanner;
