const menu = ['exclude'];

const EXCLUSION_RECRUITER_VALUE = 'recruiter';
const EXCLUSION_RECRUITER_LABEL = 'Recruiters';
const EXCLUSION_CLIENT_TEAM_VALUE = 'client-team';
const EXCLUSION_CLIENT_TEAM_LABEL = 'Client employees';
const EXCLUSION_STAFF_VALUE = 'staff';
const EXCLUSION_STAFF_LABEL = 'Staff / Tests';
const EXCLUSION_TALENT_DECLINED_VALUE = 'talent-declined';
const EXCLUSION_TALENT_DECLINED_LABEL = 'Talents declined';
const EXCLUSION_LOCATION_VALUE = 'location';
const EXCLUSION_LOCATION_LABEL = 'Location';
const EXCLUSION_NO_EXPERIENCE_VALUE = 'no-experience';
const EXCLUSION_NO_EXPERIENCE_LABEL = 'No experience';
const EXCLUSION_ROLE_ALIGNMENT_VALUE = 'role-alignment';
const EXCLUSION_ROLE_ALIGNMENT_LABEL = 'Role not aligned';
const EXCLUSION_OTHER_VALUE = 'other';
const EXCLUSION_OTHER_LABEL = 'Other';
const EXCLUSION_ACCOUNT_DELETED_VALUE = 'account-deleted';
const EXCLUSION_ACCOUNT_DELETED_LABEL = 'Account deleted';

export const options = [
	{
		label: EXCLUSION_RECRUITER_LABEL,
		value: EXCLUSION_RECRUITER_VALUE,
		details: 'funnel.exclusion: recruiter',
		menu,
	},
	{
		label: EXCLUSION_CLIENT_TEAM_LABEL,
		value: EXCLUSION_CLIENT_TEAM_VALUE,
		details: 'funnel.exclusion: client-team',
		menu,
	},
	{
		label: EXCLUSION_STAFF_LABEL,
		value: EXCLUSION_STAFF_VALUE,
		details: 'funnel.exclusion: staff',
		menu,
	},
	{
		label: EXCLUSION_TALENT_DECLINED_LABEL,
		value: EXCLUSION_TALENT_DECLINED_VALUE,
		details: 'funnel.exclusion: talent-declined',
		menu,
	},
	{
		label: EXCLUSION_LOCATION_LABEL,
		value: EXCLUSION_LOCATION_VALUE,
		details: 'funnel.exclusion: location',
		menu,
	},
	{
		label: EXCLUSION_NO_EXPERIENCE_LABEL,
		value: EXCLUSION_NO_EXPERIENCE_VALUE,
		details: 'funnel.exclusion: no-experience',
		menu,
	},
	{
		label: EXCLUSION_ROLE_ALIGNMENT_LABEL,
		value: EXCLUSION_ROLE_ALIGNMENT_VALUE,
		details: 'funnel.exclusion: role-alignment',
		menu,
	},
	{
		label: EXCLUSION_OTHER_LABEL,
		value: EXCLUSION_OTHER_VALUE,
		details: 'funnel.exclusion: other',
		menu,
	},
	{
		label: EXCLUSION_ACCOUNT_DELETED_LABEL,
		value: EXCLUSION_ACCOUNT_DELETED_VALUE,
		details: 'funnel.exclusion: account-deleted',
	},
];

export const MAILING_TEMPLATES = [
	{
		exclusion: EXCLUSION_LOCATION_VALUE, label: EXCLUSION_LOCATION_LABEL, lang: 'en', value: 4830027,
	},
	{
		exclusion: EXCLUSION_LOCATION_VALUE, label: EXCLUSION_LOCATION_LABEL, lang: 'fr', value: 4830028,
	},
	{
		exclusion: EXCLUSION_NO_EXPERIENCE_VALUE, label: EXCLUSION_NO_EXPERIENCE_LABEL, lang: 'en', value: 4830029,
	},
	{
		exclusion: EXCLUSION_NO_EXPERIENCE_VALUE, label: EXCLUSION_NO_EXPERIENCE_LABEL, lang: 'fr', value: 4830030,
	},
	{
		exclusion: EXCLUSION_ROLE_ALIGNMENT_VALUE, label: EXCLUSION_ROLE_ALIGNMENT_LABEL, lang: 'en', value: 4830031,
	},
	{
		exclusion: EXCLUSION_ROLE_ALIGNMENT_VALUE, label: EXCLUSION_ROLE_ALIGNMENT_LABEL, lang: 'fr', value: 4830032,
	},
];

export default {
	options,
	MAILING_TEMPLATES,
};
