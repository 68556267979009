/** @jsxImportSource @emotion/react */

import { Button, Space } from 'antd';
import SelectLocalContent from './SelectLocalContent';
import { useTheme } from '@emotion/react';

function FooterAction(
	{
		lang,
		setLang,
		onSubmit,
		disabled,
	},
) {
	const theme = useTheme();

	return (
		<Space
			style={{
				width: '100%',
				justifyContent: 'end',
				alignItems: 'center',
			}}
		>
			{/* <SelectLocalContent
				lang={lang}
				setLang={setLang}
			/> */}
			<Button
				type='primary'
				size='large'
				disabled={disabled}
				// htmlType='submit'
				onClick={() => onSubmit?.()}
			>
				Submit
			</Button>
		</Space>
	);
}

export default FooterAction;
