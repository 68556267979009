export const RELEVANT_DATA_SHARED_FIELDS = [
	{ key: 'ratioWorkforce', en: 'Women in the workforce', fr: 'Femmes dans les effectifs' },
	{ key: 'ratioTechnical', en: 'Women in technical roles', fr: 'Femmes dans les postes techniques' },
	{ key: 'ratioManagerial', en: 'Women in managerial roles', fr: 'Femmes dans les postes managériaux' },
	{ key: 'ratioEngineering', en: 'Women in Engineering roles', fr: 'Femmes dans les postes Ingénierie' },
	{ key: 'ratioData', en: 'Women in Data roles', fr: 'Femmes dans les postes Data' },
	{ key: 'ratioProduct', en: 'Women in Product roles', fr: 'Femmes dans les postes Produit' },
	{ key: 'ratioSales', en: 'Women in Sales roles', fr: 'Femmes dans les postes Ventes' },
	{ key: 'ratioOperations', en: 'Women in Operations roles', fr: 'Femmes dans les postes Opérations' },
	{ key: 'ratioClevel', en: 'Women in C-level roles', fr: 'Femmes dans les postes C-Level' },
	{ key: 'ratioLeadership', en: 'Women in leadership roles', fr: 'Femmes dans les postes de direction' },
	{ key: 'ratioBoard', en: 'Women on the Board of Directors', fr: 'Femmes dans le Comité de Direction' },
];
