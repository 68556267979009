import { LocalDebug } from '../utils/LocalDebug';
import { request } from './api';

const className = 'userService';

const findAll = async (params = {}, subPath = '') => {
	return request.get(`/user${subPath}`, { params });
};

const searchTalents = async (params = {}) => {
	return request.get('/user/search', { params });
};

const getUser = (id, params = {}) => {
	return request.get(`/user/${id}`, { params });
};

const viewUser = (id, params) => {
	return request.get(`/user/${id}/view`, { params });
};

const getUserDigest = (id, params = {}) => {
	return request.get(`/user/${id}/digest`, { params });
};

const getGroupTags = () => {
	// localDebug('UserService - getGroupTags')
	return request.get('/user/tags');
};

const getJobActions = (userId, params = {}) => {
	return request.get(`/user/${userId}/job-actions`, { params });
};

const getSourcingShortlists = (userId, params = {}) => {
	return request.get(`/user/${userId}/shortlists`, { params });
};

const getSourcingArchived = (userId, params = {}) => {
	return request.get(`/user/${userId}/archived`, { params });
};

const getSourcingSourced = (userId, params = {}) => {
	return request.get(`/user/${userId}/sourced`, { params });
};

const getSourcingApplications = (userId, params = {}) => {
	return request.get(`/user/${userId}/applications`, { params });
};

const getMessagesTo = (userId, params = {}) => {
	return request.get(`/user/${userId}/messagesTo`, { params });
};

const computeJobSlider = (userId, params = {}) => {
	return request.get(`/user/${userId}/compute-job-slider`, { params });
};

const computeTags = (userId, params = { verbose: true }) => {
	return request.get(`/user/${userId}/compute-tags`, { params });
};

const getUserByMail = (email, params = {}) => {
	return request.get(`/user/mail/${email}`, { params });
};

const registerUser = (data) => {
	return request.post('/user/register', data);
};

const updateUser = (data) => {
	return request.post('/user/update', data);
};

const hideUser = (userId) => {
	return request.get(`/user/${userId}/hide`);
};

const shortlistUser = (userId) => {
	return request.get(`/user/${userId}/shortlist`);
};

const getMailjetData = (userId) => {
	return request.get(`/user/${userId}/mailjet`);
};

const updateCoachingStatus = (userId, status) => {
	return request.post(`/user/${userId}/coaching-status`, { status });
};

const updateProperty = (userId, { key, value }) => {
	return request.post(`/user/${userId}/property`, { key, value });
};

const updateFunnel = (userId, params) => {
	return request.post(`/user/${userId}/funnel`, params);
};

const uploadResume = async (file, slug) => {
	const formData = new FormData();
	formData.append('file', file);
	formData.append('slug', slug);
	/* isLinkedinCV is forced to true since currently we only upload Linkedin CVs from this app */
	formData.append('isLinkedinCV', true);

	return request.post(
		`/user/${slug}/upload-resume`,
		formData,
		{ headers: { 'content-type': 'multipart/form-data' } },
	);
};

const getUserResume = async (filename) => {
	return request.get(`/user/resume/file?filename=${filename}`);
};

const updateEmail = async ({ uid, oldEmail, newEmail }) => {
	LocalDebug.logInfo({ className, method: 'updateEmail' }, { uid, oldEmail, newEmail });
	return request.post('/user/email', { uid, oldEmail, newEmail });
};

const UserService = {
	findAll,
	searchTalents,
	getUser,
	getUserDigest,
	getJobActions,
	getMailjetData,
	getSourcingApplications,
	getSourcingSourced,
	getSourcingShortlists,
	getSourcingArchived,
	getMessagesTo,
	getGroupTags,
	computeTags,
	computeJobSlider,
	getUserByMail,
	registerUser,
	updateUser,
	viewUser,
	hideUser,
	shortlistUser,
	updateCoachingStatus,
	uploadResume,
	getUserResume,
	updateProperty,
	updateFunnel,
	updateEmail,
};

export default UserService;
