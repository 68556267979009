/** @jsxImportSource @emotion/react */

import MessageTemplateForm from './MessageTemplateForm';
import MessageTemplatePicker from './MessageTemplatePicker';
import { useTheme } from '@emotion/react';
import useBodyScrollBlocker from '../../../hooks/useBodyScrollBlocker';
import ModalContainerBase from '../../../contexts/modals/ModalContainerBase';
import FormBoxTitle from '../../app/form/FormBoxTitle';
import { ConfigureEmailTemplatesIcon } from '../../../menu/configure/ConfigureEmailTemplatesMenu';

const MessageTemplatePopover = (
	{
		open,
		initialValues = {},
		onSubmit,
		templates,
		onReset,
		title = 'Create an email template',
		step = 0,
		setStep,
		onTemplateSelected,
		setIsWithStartStep,
		isWithStartStep,
	},
) => {
	const className = 'MessageTemplatePopover';

	const theme = useTheme();

	useBodyScrollBlocker(open, className);

	return (
		<ModalContainerBase
			open={open}
			onCancel={onReset}
			width={920}
		>
			<FormBoxTitle
				title={<><ConfigureEmailTemplatesIcon /> {title}</>}
			/>

			<div
				style={{
					height: 'calc(100% - 140px)',
					position: 'relative',
				}}
			>
				{step === 0 ? (
					<MessageTemplatePicker
						masterTemplate={initialValues.masterTemplate}
						setStep={setStep}
						onReset={onReset}
						onTemplateSelected={onTemplateSelected}
						setIsWithStartStep={setIsWithStartStep}
					/>
				) : (
					<MessageTemplateForm
						initialValues={initialValues}
						onSubmit={onSubmit}
						onReset={onReset}
						setStep={setStep}
						isWithStartStep={isWithStartStep}
					/>
				)}
			</div>
		</ModalContainerBase>
	);
};

export default MessageTemplatePopover;
