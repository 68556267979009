import { useAuthContext } from '../../../hooks/useAuthContext';
import UserMailingTable from '../../../components/mailing/UserMailingTable';
import SectionTitle from '../../../components/app/SectionTitle';
import PageLayout from '../../../components/app/layout/PageLayout';
import FilterButton from '../../../components/app/filters/FilterButton';
import { useEffect, useState } from 'react';
import { addMultipleQueryParams, getQueryParams, removeQueryParams } from '../../../utils/common';
import { localDebug } from '../../../utils/LocalDebug';
import UserMailingFilterModal from '../../../components/mailing/UserMailingFilterModal';
import { Space } from 'antd';
import { CONFIGURE_USERS_ICON, MESSAGES_ICON } from '../../../constants/constant';
import { FaChevronRight } from 'react-icons/fa';
import InlineNavLink from '../../../components/app/button/InlineNavLink';
import GenderhireMessagesMenu from '../../../menu/genderhire/GenderhireMessagesMenu';
import ConfigureUsersMenu from '../../../menu/configure/ConfigureUsersMenu';
import ConfigureMenu from '../../../menu/configure/ConfigureMenu';
import GenderhireSearchTalentsMenu from '../../../menu/genderhire/GenderhireSearchTalentsMenu';

const UserMailingsPage = () => {
	const { isStaff } = useAuthContext();
	const [isRefreshDataLayout, setIsRefreshDataLayout] = useState(0);
	const [optionsFilterTable, setOptionsFilterTable] = useState({});
	if (!isStaff) return null;

	const handleFilter = (values) => {
		localDebug('UserMailing.handleFilter()', { values });
		const {
			createdAfter, createdBefore, ...rest
		} = values;
		addMultipleQueryParams(rest);
		setOptionsFilterTable((prev) => ({
			...prev,
			...rest,
			createdAfter,
			createdBefore,
		}));
	};

	const handleClearFilter = () => {
		localDebug('UserMailing.handleClearFilter()', {});
		setOptionsFilterTable({
		});
		removeQueryParams();
	};

	return (
		<PageLayout
			icon={'📮'}
			title={'UserMailings'}
			rightActions={(
				<Space
					direction='vertical'
					style={{
						justifyContent: 'flex-end',
					}}
				>
					{new GenderhireMessagesMenu().getInlineNavLink({
						withChevronRight: true,
						style: { span: { textDecoration: 'none' } },
					})}
					<InlineNavLink
						path={'/staff/tech/saved-searches-digests'}
						style={{ span: { textDecoration: 'none' } }}
					>
						{new GenderhireSearchTalentsMenu().icon} Saved Searches Digests
						<FaChevronRight style={{ fontSize: 10, marginBottom: -1 }} />
					</InlineNavLink>
					{}
					{new ConfigureUsersMenu().getInlineNavLink({
						parentMenus: new ConfigureMenu(),
						withChevronRight: true,
						style: { span: { textDecoration: 'none' } },
					})}
				</Space>
			)}
			filter={<FilterButton
				title="Filter UserMailings"
				modal={UserMailingFilterModal}
				onFilter={handleFilter}
				onClearFilter={handleClearFilter}
				initialValues={optionsFilterTable}
			/>}
		>
			<UserMailingTable
				filterOptions={optionsFilterTable}
				isRefresh={isRefreshDataLayout}
			/>
		</PageLayout>
	);
};

export default UserMailingsPage;
