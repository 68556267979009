import { PERMS_GRANT_STAFF } from '../menu.utils';
import MenuBase from '../MenuBase';
import { BankOutlined } from '@ant-design/icons';
import StaffCompaniesCompanyListMenu from './companies/StaffCompaniesCompanyListMenu';
import StaffCompaniesRecruiterSearchesMenu from './companies/StaffCompaniesRecruiterSearchesMenu';

export const StaffCompaniesIcon = BankOutlined;

class StaffCompaniesMenu extends MenuBase {
	static props = {
		name: 'staff-companies-menu',
		label: 'Companies',
		icon: <StaffCompaniesIcon />,
		iconLegacy: '🏢',
		children: () => [
			(new StaffCompaniesCompanyListMenu()).build(),
			(new StaffCompaniesRecruiterSearchesMenu()).build(),
		],
		perms: PERMS_GRANT_STAFF,
	};

	constructor() {
		super(StaffCompaniesMenu.props);
	}
}

export default StaffCompaniesMenu;
