import { Button, Space, Tooltip } from 'antd';
import { useTheme } from '@emotion/react';
import moment from 'moment';
import UserAvatar from '../../user/widgets/UserAvatar';
import {
	DATE_FORMAT,
	EMAIL_TEMPLATES,

} from '../../../constants/constant';
import {
	LOGO_50INTECH,
	LOGO_50INTECH_FULL_BLUE_SQUARE,
	LOGO_50INTECH_SVG,
} from '../../../constants/logos';
import Box from '../../app/box/Box';
import MessageTemplateCategoryTag from './MessageTemplateCategoryTag';
import { mixColors } from '../../../utils/common';
import {
	FaCopy, FaPen, FaStar, FaTrash,
} from 'react-icons/fa';
import { useAuthContext } from '../../../hooks/useAuthContext';
import UserModel from '../../../models/UserModel';
import ActionButton from '../../app/table/ActionButton';
import { RiDeleteBinFill, RiEdit2Fill } from 'react-icons/ri';

const MessageTemplateUserAvatar = (
	{
		template,
		...props
	},
) => {
	const className = 'MessageTemplateUserAvatar';

	return (
		<UserAvatar
			withIsNew={false}
			withIsUnviewed={false}
			withTooltip={!template.isMasterTemplate}
			withCompanyLogo={!template.isMasterTemplate}
			forceShow={template.isMasterTemplate}
			{...props}
			user={{
				...template.isMasterTemplate
					? {
						id: 1,
						firstName: '50inTech',
						lastName: '',
						information: { avatar: LOGO_50INTECH_FULL_BLUE_SQUARE },
					}
					: template.user,
			}}/>
	);
};

export default MessageTemplateUserAvatar;
