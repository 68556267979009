import { PieChartOutlined } from '@ant-design/icons';
import GenderScorePage from '../../containers/genderscore/GenderScorePage';
import { PERMS_GENDERSCORE_FILLED } from './genderscore-utils';
import MenuBase from '../MenuBase';

export const GenderscoreResultsIcon = PieChartOutlined;

class GenderscoreResultsMenu extends MenuBase {
	static props = {
		name: 'genderscore',
		path: '/genderscore',
		aliases: ['^/genderscore$'],
		label: 'Results',
		icon: <GenderscoreResultsIcon />,
		iconLegacy: '📈',
		Component: GenderScorePage,
		perms: PERMS_GENDERSCORE_FILLED,
	};

	constructor() {
		super(GenderscoreResultsMenu.props);
	}
}

export default GenderscoreResultsMenu;
