import GenderscoreActionPlanMenu from './GenderscoreActionPlanMenu';
import GenderscoreSurveyMenu from './GenderscoreSurveyMenu';
import GenderscoreFilledMenu from './GenderscoreFilledMenu';
import GenderscoreDashboardMenu from './GenderscoreDashboardMenu';
import GenderscoreResourcesMenu from './GenderscoreResourcesMenu';
import GenderscoreRankingsMenu from './GenderscoreRankingsMenu';
import CompanyActionPlanMenu from './CompanyActionPlanMenu';
import MenuBase from '../MenuBase';
import { GENDERSCORE_LABEL } from '../../constants/constant';
import { menuGroupBuilder } from '../menu.utils';
import { PERMS_GENDERCORE_ENABLED } from './genderscore-utils';
import config from '../../config/config';

class GenderscoreMenu extends MenuBase {
	static props = {
		children: (args) => [
			...args.withDivider
				? [{ perms: PERMS_GENDERCORE_ENABLED }]
				: [],
			menuGroupBuilder({ label: GENDERSCORE_LABEL, perms: PERMS_GENDERCORE_ENABLED }),
			(new GenderscoreDashboardMenu()).build({ isRoot: args.isDocumentAdmin }),
			(new GenderscoreFilledMenu()).build(),
			(new GenderscoreSurveyMenu()).build(),
			(new GenderscoreActionPlanMenu()).build(),
			(new CompanyActionPlanMenu()).build(),
			(new GenderscoreResourcesMenu()).build(),
			...config.isDevEnv ? [(new GenderscoreRankingsMenu()).build()] : [],
		],
	};

	constructor() {
		super(GenderscoreMenu.props);
	}
}

export default GenderscoreMenu;
