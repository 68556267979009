import { useTheme } from '@emotion/react';

/**
 *
 * @param {string} title - Title to render
 * @param {number} maxWidth - Optional. Maximum width of the title. Default: 180
 * @param {boolean} isMainTitle - Optional. Whether it is a main title or not. Default: true
 * @returns
 */
const GenderScoreTitleBlock = ({
	title,
	maxWidth,
	isMainTitle = true,
	...props
}) => {
	const className = 'GenderScoreTitleBlock';

	const theme = useTheme();

	return <p style={{
		fontSize: isMainTitle ? 16 : 14,
		fontWeight: isMainTitle ? 500 : 400,
		color: theme.color.fitBlack,
		opacity: 0.88,
		maxWidth,
		margin: 0,
		...props.style,
	}}>
		{title}
	</p>;
};
export default GenderScoreTitleBlock;
