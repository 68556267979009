import { useEffect, useState } from 'react';
import { useTheme } from '@emotion/react';
import { Checkbox, Space, message } from 'antd';
import CompanyModel from '../../../../models/CompanyModel';
import { getCompanySelected } from '../../../../reducers/app';
import { useDispatch, useSelector } from 'react-redux';
import { ACL_EXTERNAL_LABELS } from '../../../../constants/acls';
import { StaffManagerPanelDivider, StaffManagerPanelTabContentWrapper } from '../StaffManagerPanel';
import { useAuthContext } from '../../../../hooks/useAuthContext';
import AclRoleStub from '../../../acls/AcRoleStub';
import { add, update } from '../../../../actions/acls';
import AclService from '../../../../services/acl';
import { LocalDebug } from '../../../../utils/LocalDebug';
import { refreshCurrentUser } from '../../../../actions/app';
import BlockStaff from '../../../app/blocker/BlockStaff';

const StaffManagerUserTab = () => {
	const className = 'StaffManagerUserTab';

	const theme = useTheme();
	const dispatch = useDispatch();

	const companySelected = useSelector(getCompanySelected);
	const { currentUser, isStaff } = useAuthContext();

	const [companyModel, setCompanyModel] = useState();
	const [rolesWithActivity, setRolesWithActivity] = useState([]);

	useEffect(() => {
		setCompanyModel(
			companySelected?._id
				? new CompanyModel(companySelected)
				: null,
		);
	}, [companySelected]);

	useEffect(() => {
		setRolesWithActivity(
			ACL_EXTERNAL_LABELS
				.map((label) => ({
					label,
					isActive:
						currentUser.acls.userAcls
							.some((item) => (
								`${item.documentId}-${item.acl.label}`
								=== `${companyModel?._id}-${label}`
							)),
				})),
		);
	}, [currentUser, companyModel]);

	const getActiveRole = (label) => {
		return currentUser.acls.userAcls
			.find((item) => (
				`${item.documentId}-${item.acl.label}`
				=== `${companyModel?._id}-${label}`
			));
	};

	const handleToggleRole = async (roleId) => {
		const method = 'handleToggleRole';

		const activeRole = getActiveRole(roleId);

		LocalDebug.logInfo({ className, method }, { roleId, activeRole });

		const { data: { isAclExist, id } } = await AclService
			.aclExists(
				roleId,
				{
					userId: currentUser?._id,
					companyId: companyModel?._id,
				},
			);

		LocalDebug.logInfo({ className, method }, { isAclExist });

		if (isAclExist) {
			await dispatch(update(
				id,
				{
					...activeRole || {},
					roleId,
					userId: currentUser?._id,
					documentId: companyModel?._id,
					isActive: !activeRole,
				},
			));
		} else {
			LocalDebug.logInfo({ className, method }, {
				roleId,
				userId: currentUser?._id,
				documentId: companyModel?._id,
			});
			await dispatch(add({
				roleId,
				userId: currentUser?._id,
				documentId: companyModel?._id,
			}));
		}

		LocalDebug.logInfo({ className, method }, 'done');

		refreshCurrentUser()(dispatch);
	};

	if (!companyModel) {
		return (
			<StaffManagerPanelTabContentWrapper>
				<i>Select a company</i>
			</StaffManagerPanelTabContentWrapper>
		);
	}

	return (
		<StaffManagerPanelTabContentWrapper>

			<BlockStaff
				withStaffView={false}
				withTags={false}
				withBorder={false}
				blockedContent={
					<>
						<StaffManagerPanelDivider>Roles for the current company</StaffManagerPanelDivider>

						{rolesWithActivity
							.map(
								({ label, isActive }) => (
									<Space
										key={label}
										style={{
											width: '100%',
											marginBottom: 6,
										}}
									>
										<Checkbox
											checked={isActive}
											onClick={() => handleToggleRole(label)}
											/* If last active role, disabling to blocking current user */
											disabled={
												isActive
												&& rolesWithActivity?.filter((item) => item.isActive)?.length <= 1
											}
										/>
										<AclRoleStub
											role={{
												acl: { label },
												company: companyModel,
											}}
											companyLogoSize={24}
											withDate={false}
											withDetails={false}
										/>
									</Space>
								),
							)}
					</>

				}
			>
				<StaffManagerPanelTabContentWrapper style={{ padding: 0 }}>
					<i>Roles management not available for staff accounts</i>
				</StaffManagerPanelTabContentWrapper>

			</BlockStaff>

		</StaffManagerPanelTabContentWrapper>
	);
};

export default StaffManagerUserTab;
