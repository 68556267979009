import moment from 'moment';
import sourcingService from '../services/sourcing';
import * as ACTION_TYPES from './types';
import { action, actionWithLoader } from './utils';
import {
	getCompanies,
	getSourcingsCompanySelected,
} from '../reducers/app';
import {
	SOURCING_ACTION_COMMENT,
	SOURCING_ACTION_STAGE,
	SOURCING_ACTION_STATUS,
	SOURCING_ACTION_STEP,
	SOURCING_ACTION_CONTACT,
} from '../constants/constant';
import { documentId, inArray, writeLog } from '../utils/common';
import { localDebug } from '../utils/LocalDebug';
import { message } from 'antd';
import { addMessage } from './messages';
import GenderhireCandidatesAtsMenu from '../menu/genderhire/GenderhireCandidatesAtsMenu';

// companyId, userId, status, source
export const getAllSourcings = (options = {}) => {
	localDebug('getAllSourcings', options);
	return actionWithLoader(async (dispatch, getState) => {
		try {
			localDebug('getAllSourcings', options);
			const { data } = await sourcingService.findAll(options);
			localDebug('getAllSourcings', data);
			dispatch({
				type: ACTION_TYPES.SOURCINGS_LOADED,
				sourcings: data.sourcings,
			});
		} catch (error) {
			localDebug(__filename.split('/').shift(), error);
		}
	});
};

export const addSourcing = async (data) => {
	// Don't send the user document to the server
	let { user, userId, ...trimmedData } = data;
	trimmedData = {
		...trimmedData,
		userId: userId || documentId(user) || undefined,
	};
	try {
		const { data: { sourcing } } = await sourcingService.create(trimmedData);
		return sourcing;
	} catch (error) {
		localDebug(__filename.split('/').shift(), error);
	}
};

export const updateSourcing = (sourcingId, data) => {
	// Don't send the user document to the server
	let { user, userId, ...trimmedData } = data;
	trimmedData = {
		...trimmedData,
		userId: userId || documentId(user) || undefined,
	};
	return async (dispatch) => {
		try {
			await sourcingService.update(sourcingId, trimmedData);
			dispatch(getAllSourcings());
		} catch (error) {
			localDebug(__filename.split('/').shift(), error);
		}
	};
};

export const removeSourcing = (sourcing) => {
	return actionWithLoader(async () => {
		try {
			await sourcingService.remove(sourcing);
		} catch (error) {
			localDebug(__filename.split('/').shift(), error);
		}
	});
};

export const deleteStep = (id, step, sourcing) => {
	return actionWithLoader(async () => {
		try {
			const { data } = await sourcingService.removeStep(id, step, sourcing);
		} catch (error) {
			localDebug(__filename.split('/').shift(), error);
		}
	});
};

export const setSourcingsCompanySelected = (companyId) => {
	return async (dispatch, getState) => {
		if (!companyId) {
			dispatch({
				type: ACTION_TYPES.SET_SOURCINGS_COMPANY_SELECTED,
				sourcingCompanySelected: null,
			});
			return;
		}
		const allCompanies = getCompanies(getState());
		const company = allCompanies.find((c) => documentId(c) === companyId);
		dispatch({
			type: ACTION_TYPES.SET_SOURCINGS_COMPANY_SELECTED,
			sourcingsCompanySelected: company,
		});

		const companySelected = getSourcingsCompanySelected(getState());
		localDebug({ getState: getState() });
		localDebug({ companySelected });

		dispatch(getAllSourcings());
	};
};

export const getSourcingWithActions = (sourcing, isStaff) => {
	const actionFilterByAcl = (history, isStaff) => {
		if (!isStaff) {
			return ((history.action === 'comment' && !history.isStaffPrivate) || history.action !== 'comment') && history.isActive;
		}
		return history.isActive;
	};

	const history = (sourcing?.history || []).filter((action) => actionFilterByAcl(action, isStaff));
	const comment = history.find((i) => i.action === 'comment');
	const reasonsStop = history.find((a) => a.action === 'status' && a?.payload?.value === 'stop');
	return {
		...sourcing,
		id: documentId(sourcing),
		history,
		comment,
		reasonsStop,
	};
};

export const saveSourcing = async (values, title) => {
	const result = await addSourcing({ ...values });
	return result;
};

export const prepareHistoryForMessageAction = async (sourcingId, currentUser, message) => {
	localDebug('actions/sourcings: prepareHistoryForMessageAction', { sourcingId, message });
	if (!sourcingId) return;

	const action = SOURCING_ACTION_CONTACT;
	const history = {
		action,
		'creator': documentId(currentUser),
		'postedAt': moment(),
		'author': documentId(currentUser),
		'payload.userMessageId': documentId(message),
	};

	return treatSourcingActionToDb({
		id: sourcingId,
		managerId: documentId(currentUser),
		...history,
	}, currentUser);
};

export const submitMessage = async (values, currentUser) => {
	const { sourcingId, ...valuesToDb } = values || {};
	const message = await addMessage(valuesToDb);
	localDebug('actions/sourcings: submitMessage', { sourcingId, message });
	if (!sourcingId) return;
	const sourcingValues = prepareHistoryForMessageAction(sourcingId, currentUser, message);
	const editedSourcing = await saveSourcing(sourcingValues, 'edit a sourcing');
	return editedSourcing;
};

export const submitAfterMessage = async (values, currentUser, message) => {
	const { sourcingId, ...valuesToDb } = values || {};
	localDebug('actions/sourcings: submitAfterMessage', { sourcingId, message });
	if (!sourcingId) return;
	const sourcingValues = prepareHistoryForMessageAction(sourcingId, currentUser, message);
	const editedSourcing = await saveSourcing(sourcingValues, 'edit a sourcing');
	return editedSourcing;
};

export const treatSourcingActionToDb = (values, currentUser, isStaffPrivate) => {
	const {
		id, status, step, stage, managerId, ...history
	} = values;
	const historyToArray = [history];

	localDebug('treatSourcingActionToDb', {
		id, status, step, managerId, history,
	});

	// set status and step before add a action
	const sourcingInfo = {
		...stage && { stage, updatedStageAt: moment() },
		...(history.action === SOURCING_ACTION_STATUS && {
			status: history['payload.value'],
			updatedStatusAt: history.postedAt,
		}),
		...(history.action === SOURCING_ACTION_STEP && {
			step: history['payload.value'],
			updatedStepAt: history.postedAt,
		}),
		...(history.action === SOURCING_ACTION_STAGE && {
			stage: history['payload.value'],
			updatedStageAt: history.postedAt,
		}),
	};

	// add a comment when adding status or step comment field is not null
	if (history['payload.text'] && inArray(history.action)) {
		const comment = {
			action: SOURCING_ACTION_COMMENT,
			postedAt: history.postedAt,
			author: history.author,
			isStaffPrivate,
			payload: {
				text: history['payload.text'],
				isStaffPrivate,
			},
			creator: documentId(currentUser),
		};
		historyToArray.push(comment);
	}

	// set status new  (or stand by) to in process when adding a step
	// if (inArray(status, [NEW_STATUS, STANDBY_STATUS]) && history.action === SOURCING_ACTION_STEP) {
	// 	// adding history and action field
	// 	const statusHistory = {
	// 		action: SOURCING_ACTION_STATUS,
	// 		payload: {
	// 			value: PROCESS_STATUS
	// 		},
	// 		postedAt: history.postedAt,
	// 		author: history.author,
	// 		creator: documentId(currentUser)
	// 	};
	// 	historyToArray.push(statusHistory);
	// 	sourcingInfo = {
	// 		status: PROCESS_STATUS,
	// 		updatedStatusAt: history.postedAt
	// 	}
	// }

	const data = {
		id,
		...sourcingInfo,
		managerId,
		history: historyToArray,
	};
	localDebug('treatSourcingActionToDb, data', data);
	return data;
};

export const createOrEditSourcing = async (values) => {
	writeLog('createOrEditSourcing', { data: values });
	localDebug('sourcings.createOrEditSourcing', { values });
	// return;
	const valuesToDb = { ...values };
	const result = await addSourcing(valuesToDb);
	// if (result) {
	// 	const payload = {
	// 		documentRef: SOURCING_DOCUMENT_REF,
	// 		documentId: documentId(result),
	// 		companyId: result.companyId,
	// 		action: {
	// 			title,
	// 			...(values?.history && {history: values.history})
	// 		}
	// 	}
	// 	createAction(payload);
	// }
};

export const syncSourcingFromATS = (sourcing) => {
	const { id } = sourcing;
	localDebug('actions.sourcings.syncSourcingFromATS', id);
	const key = `syncSourcingFromATS${id}`;
	message.loading({ content: 'Syncing from ATS', key, duration: 10 });
	return action(async () => {
		try {
			const result = await sourcingService.syncFromATS(id);
			message.success({ content: 'Sync done!', key, duration: 1 });
			return result?.data;
		} catch (error) {
			message.error({ content: error.message, key });
			localDebug(__filename.split('/').shift(), error);
		}
	});
};

export const pushSourcingToATS = (sourcing) => {
	const { id } = sourcing;
	localDebug('actions.sourcings.pushSourcingToATS', id);
	const key = `pushSourcingToATS${id}`;
	message.loading({ content: 'Sending to ATS', key, duration: 10 });
	return action(async () => {
		try {
			const result = await sourcingService.pushToATS(id);
			const candidatesMenu = (new GenderhireCandidatesAtsMenu()).build();
			message.success({
				content: <>
					Sent to your ATS!
					Find it in <a
						href={candidatesMenu.path}>{candidatesMenu.icon} {candidatesMenu.label}</a>
				</>,
				key,
				duration: 2,
			});
			return result?.data;
		} catch (error) {
			message.error({ content: error.message, key });
			localDebug(__filename.split('/').shift(), error);
		}
	});
};
