export const options = [
	{ value: 'AED', label: 'AED - د.إ', currency: 'د.إ' },
	{ value: 'AMD', label: 'AMD - AMD', currency: 'AMD' },
	{ value: 'ARS', label: 'ARS - $', currency: '$' },
	{ value: 'AUD', label: 'AUD - $', currency: '$' },
	{ value: 'BAM', label: 'BAM - KM', currency: 'KM' },
	{ value: 'BBD', label: 'BBD - $', currency: '$' },
	{ value: 'BDT', label: 'BDT - Tk', currency: 'Tk' },
	{ value: 'BGN', label: 'BGN - лв', currency: 'лв' },
	{ value: 'BHD', label: 'BHD - .د.', currency: '.د.' },
	{ value: 'BRL', label: 'BRL - R$', currency: 'R$' },
	{ value: 'BYN', label: 'BYN - Br', currency: 'Br' },
	{ value: 'CAD', label: 'CAD - $', currency: '$' },
	{ value: 'CHF', label: 'CHF - CHF', currency: 'CHF' },
	{ value: 'CLP', label: 'CLP - $', currency: '$' },
	{ value: 'CNY', label: 'CNY - ¥', currency: '¥' },
	{ value: 'COP', label: 'COP - $', currency: '$' },
	{ value: 'CRC', label: 'CRC - ₡', currency: '₡' },
	{ value: 'CZK', label: 'CZK - Kč', currency: 'Kč' },
	{ value: 'DKK', label: 'DKK - kr', currency: 'kr' },
	{ value: 'DOP', label: 'DOP - RD$', currency: 'RD$' },
	{ value: 'DZD', label: 'DZD - DA', currency: 'DA' },
	{ value: 'EGP', label: 'EGP - E£', currency: 'E£' },
	{ value: 'ETB', label: 'ETB - Br', currency: 'Br' },
	{ value: 'EUR', label: 'EUR - €', currency: '€' },
	{ value: 'GBP', label: 'GBP - £', currency: '£' },
	{ value: 'GEL', label: 'GEL - GEL', currency: 'GEL' },
	{ value: 'GHS', label: 'GHS - GH₵', currency: 'GH₵' },
	{ value: 'GTQ', label: 'GTQ - Q', currency: 'Q' },
	{ value: 'HKD', label: 'HKD - HK$', currency: 'HK$' },
	{ value: 'HNL', label: 'HNL - L', currency: 'L' },
	{ value: 'HRK', label: 'HRK - kn', currency: 'kn' },
	{ value: 'HUF', label: 'HUF - Ft', currency: 'Ft' },
	{ value: 'IDR', label: 'IDR - Rp', currency: 'Rp' },
	{ value: 'ILS', label: 'ILS - ₪', currency: '₪' },
	{ value: 'INR', label: 'INR - ₹', currency: '₹' },
	{ value: 'ISK', label: 'ISK - Íkr', currency: 'Íkr' },
	{ value: 'JOD', label: 'JOD - د.أ', currency: 'د.أ' },
	{ value: 'JPY', label: 'JPY - ¥', currency: '¥' },
	{ value: 'KES', label: 'KES - KSh', currency: 'KSh' },
	{ value: 'KGS', label: 'KGS - лв', currency: 'лв' },
	{ value: 'KRW', label: 'KRW - ₩', currency: '₩' },
	{ value: 'KWD', label: 'KWD - د.ك', currency: 'د.ك' },
	{ value: 'KZT', label: 'KZT - ₸', currency: '₸' },
	{ value: 'LKR', label: 'LKR - ₨', currency: '₨' },
	{ value: 'MAD', label: 'MAD - DH', currency: 'DH' },
	{ value: 'MKD', label: 'MKD - ден', currency: 'ден' },
	{ value: 'MMK', label: 'MMK - K', currency: 'K' },
	{ value: 'MUR', label: 'MUR - ₨', currency: '₨' },
	{ value: 'MXN', label: 'MXN - $', currency: '$' },
	{ value: 'MYR', label: 'MYR - RM', currency: 'RM' },
	{ value: 'NGN', label: 'NGN - ₦', currency: '₦' },
	{ value: 'NOK', label: 'NOK - kr', currency: 'kr' },
	{ value: 'NZD', label: 'NZD - $', currency: '$' },
	{ value: 'OMR', label: 'OMR - ر.ع.', currency: 'ر.ع.' },
	{ value: 'PAB', label: 'PAB - B/.', currency: 'B/.' },
	{ value: 'PEN', label: 'PEN - S/', currency: 'S/' },
	{ value: 'PHP', label: 'PHP - ₱', currency: '₱' },
	{ value: 'PKR', label: 'PKR - ₨ ', currency: '₨ ' },
	{ value: 'PLN', label: 'PLN - zł', currency: 'zł' },
	{ value: 'QAR', label: 'QAR - ر.ق', currency: 'ر.ق' },
	{ value: 'RON', label: 'RON - lei', currency: 'lei' },
	{ value: 'RSD', label: 'RSD - РСД', currency: 'РСД' },
	{ value: 'RUB', label: 'RUB - ₽', currency: '₽' },
	{ value: 'RWF', label: 'RWF - FRw', currency: 'FRw' },
	{ value: 'SAR', label: 'SAR - ر.س', currency: 'ر.س' },
	{ value: 'SEK', label: 'SEK - kr', currency: 'kr' },
	{ value: 'SGD', label: 'SGD - S$', currency: 'S$' },
	{ value: 'THB', label: 'THB - ฿', currency: '฿' },
	{ value: 'TJS', label: 'TJS - TJS', currency: 'TJS' },
	{ value: 'TND', label: 'TND - DT', currency: 'DT' },
	{ value: 'TRY', label: 'TRY - ₺', currency: '₺' },
	{ value: 'TWD', label: 'TWD - NT$', currency: 'NT$' },
	{ value: 'TZS', label: 'TZS - TSh', currency: 'TSh' },
	{ value: 'UAH', label: 'UAH - ₴', currency: '₴' },
	{ value: 'UGX', label: 'UGX - USh', currency: 'USh' },
	{ value: 'USD', label: 'USD - $', currency: '$' },
	{ value: 'UYU', label: 'UYU - $U', currency: '$U' },
	{ value: 'UZS', label: 'UZS - лв', currency: 'лв' },
	{ value: 'VND', label: 'VND - ₫', currency: '₫' },
	{ value: 'ZAR', label: 'ZAR - R', currency: 'R' },
	{ value: 'ZMW', label: 'ZMW - K', currency: 'K' },
];
