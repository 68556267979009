import { LocalDebug } from '../../utils/LocalDebug';
import { Form, Modal } from 'antd';
import FormItem from '../company/form/FormItem';
import FormSubmitButton from '../app/form/FormSubmitButton';
import { useAuthContext } from '../../hooks/useAuthContext';
import { requiredEmailRules } from '../../utils/common';
import { useState } from 'react';
import { refreshCurrentUser } from '../../actions/app';
import { useTheme } from '@emotion/react';
import UserService from '../../services/user';

const AccountCredentialsEmailForm = ({
	user,
	...props
}) => {
	const className = 'AccountCredentialsEmailForm';

	const theme = useTheme();
	const { currentUser } = useAuthContext();

	const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
	const [form] = Form.useForm();

	const onFormChanged = (values) => {
		LocalDebug.logInfo({ className, method: 'onFormChanged' }, { values });
		setIsSubmitDisabled(false);
	};

	const onFormFinishFailed = (values) => {
		LocalDebug.logInfo({ className, method: 'onFormFinishFailed' }, { values });
	};

	const onFormFinish = async (values) => {
		LocalDebug.logInfo({ className, method: 'onFormFinish' }, { values });
		Modal.confirm({
			title: <>
				Are you sure you want to edit your email?
				<br />New email:
				<br /><span style={{
					fontSize: 14,
					color: theme.color.fitBlueElectric,
				}}>
					{values.email}
				</span>
			</>,
			okText: 'Yes',
			cancelText: 'No',
			onOk: async () => {
				try {
					LocalDebug.logInfo({ className, method: 'onFormFinish' }, { user, newEmail: values.email });
					const result = await UserService.updateEmail({
						uid: user.uid,
						oldEmail: user.email,
						newEmail: values.email,
					});
					LocalDebug.logInfo({ className, method: 'onFormFinish' }, { result });
					refreshCurrentUser();
				} catch (e) {
					LocalDebug.logInfo({ className, method: 'onFormFinish - error' }, { e });
				}
			},
		});
	};

	return (
		<div style={{ ...props?.style }}>
			<Form
				form={form}
				name='account-credentials-email'
				initialValues={{ ...user }}
				labelCol={{ span: 0 }}
				labelAlign='left'
				wrapperCol={{ span: 24 }}
				onFieldsChange={onFormChanged}
				onFinishFailed={onFormFinishFailed}
				onFinish={onFormFinish}
				layout='horizontal'
			>
				<FormItem
					name={'email'}
					label=''
					defaultContainer='div'
					rules={requiredEmailRules}
				/>
				<FormSubmitButton
					disabled={isSubmitDisabled}
				>
					<span>Edit email</span>
				</FormSubmitButton>
			</Form>
		</div>
	);
};

export default AccountCredentialsEmailForm;
