const UserGridCardResumeExperienceEntryLine = (
	{
		entry,
		...props
	},
) => {
	return (
		<span style={{ ...props?.style }}>
			{[entry.title, entry.organization].map((i) => i?.trim()).filter((i) => i && i !== '').join(' @ ')}
			{entry.duration && <>&nbsp;({entry.duration})</>}
		</span>
	);
};

export default UserGridCardResumeExperienceEntryLine;
