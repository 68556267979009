import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { LocalDebug } from '../utils/LocalDebug';
import {
	addMultipleQueryParams, documentId, removeQueryParams,
} from '../utils/common';
import { getCompanies, getCompanySelected } from '../reducers/app';
import { loadCompanies, setCompanySelected } from '../actions/company';
import CompanyModel from '../models/CompanyModel';
import CSM_DETAILS from '../constants/csmDetails';

export const CompanyContext = React.createContext();

export const COMPANY_SELECTED_QUERY_PARAM = 'companySelected';

const CompanyProvider = ({
	children,
}) => {
	const className = 'CompanyProvider';

	const dispatch = useDispatch();

	const companySelected = useSelector(getCompanySelected);
	const companies = useSelector(getCompanies);

	const [csmDetails, setCsmDetails] = useState(CSM_DETAILS.defaultCsm);
	// Keeping planValue null unless a company is selected
	const [planValue, setPlanValue] = useState(null);

	const [upgradeRequested, setUpgradeRequested] = useState(false);

	const findInCompanies = useCallback(
		(value) => companies
			.find((c) => [c.path, c.name, (documentId(c))?.toString?.()]
				.map((s) => s?.toLowerCase?.())
				.includes(value?.toLowerCase?.())),
		[companies],
	);

	const setCompanySelectedAsQueryParam = (value) => {
		localStorage.removeItem(COMPANY_SELECTED_QUERY_PARAM);
		const method = 'setCompanySelectedAsQueryParam';
		LocalDebug.logInfo({ className, method }, { value, companies: companies?.length });
		const company = !!value && findInCompanies(value);
		LocalDebug.logInfo({ className, method }, { value, company: company?.path });
		if (company) {
			return addMultipleQueryParams({ [COMPANY_SELECTED_QUERY_PARAM]: company.path });
		}
		removeQueryParams([COMPANY_SELECTED_QUERY_PARAM]);
		dispatch(setCompanySelected(null));
	};

	const dispatchCompanySelectedFromQueryParam = (queryCompanySelected) => {
		const method = 'dispatchCompanySelectedFromQueryParam';
		LocalDebug.logNull({ className, method }, { companies: companies?.length, queryCompanySelected });
		if (!(companies?.length > 0 && queryCompanySelected)) return;
		const company = findInCompanies(queryCompanySelected);
		LocalDebug.logNull({ className, method }, { company: company?.path });

		if (!company) return;
		const companyId = documentId(company);
		const companySelectedId = documentId(companySelected);
		if (companyId === companySelectedId) return;
		dispatch(setCompanySelected(companyId));
		dispatch(loadCompanies({
			defaultCompanyIdSelected: companyId,
			origin: 'dispatchCompanySelectedFromQueryParam',
		}));
	};

	useEffect(() => {
		LocalDebug.logUseEffect({ className, effects: 'companySelected' }, { companySelected });
		if (companySelected) {
			const companyModel = new CompanyModel(companySelected);

			setPlanValue(companyModel.getCompanyPlanValue());

			if (companyModel?.isGenderScoreCompanyPlan()) {
				setCsmDetails(CSM_DETAILS.defaultCsm);
			} else {
				setCsmDetails(CSM_DETAILS.defaultCsm);
			}
		} else {
			setPlanValue(null);
		}
	}, [companySelected]);

	return (
		<CompanyContext.Provider
			value={{
				setCompanySelectedAsQueryParam,
				dispatchCompanySelectedFromQueryParam,
				findInCompanies,
				csmDetails,
				planValue,
				upgradeRequested,
				setUpgradeRequested,
			}}
		>
			{children}
		</CompanyContext.Provider>
	);
};

export default CompanyProvider;
