import UserModel from '../../../../../models/UserModel';
import UserGridCardLabelValue from './UserGridCardLabelValue';
import UserGridCardValueList from './UserGridCardValueList';

const UserGridCardJobSearchJobRoles = (
	{
		user,
		label = <>Looking for a position in</>,
		placement = 'left',
		defaultContainer = 'div',
		withSeparator = false,
		...props
	},
) => {
	const className = 'UserGridCardJobSearchJobRoles';

	const userModel = new UserModel(user);

	if (!userModel?.hasJobSearchJobRoles()) return null;

	const items = userModel?.getJobSearchJobRoles();

	return (
		<UserGridCardLabelValue
			label={label}
		>
			<UserGridCardValueList
				items={items}
				placement={placement}
				defaultContainer={defaultContainer}
				withSeparator={withSeparator}
			/>
		</UserGridCardLabelValue>
	);
};

export default UserGridCardJobSearchJobRoles;
