import moment from 'moment';
import { Space } from 'antd';
import CompanyLogo from './CompanyLogo';
import { useTheme } from '@emotion/react';
import CompanyModel from '../../models/CompanyModel';
import AtsLogo from '../ats/AtsLogo';
import CompanyPlanTag from './CompanyPlanTag';
import { GenderScoreSurveyStatusDot } from './settings/CompanySettingsGenderScoreCard';
import CompanyGenderScoreRingScore from './genderscore/CompanyGenderScoreRingScore';
import { CloseOutlined } from '@ant-design/icons';

const CompanyStub = (
	{
		company,
		size = 32,
		withLink = true,
		withTooltip = true,
		withPublicationDot = false,
		withJobsPublicationDot = false,
		withAtsDot = false,
		withGenderScoreDot = false,
		withPublicationOpacity = false,
		withAtsAccess = false,
		withCompanyPlan = false,
		withCompanyPlanExtraTags = false,
		children,
		...props
	},
) => {
	const theme = useTheme();

	const companyModel = new CompanyModel(company);

	const dot = (color, offset = 0) => (
		<div
			style={{
				position: 'absolute',
				// top: size - 5,
				// left: size - 4 + offset - size + 4,
				left: size + 4,
				top: offset + 2,
				width: size / 2.3,
				height: size / 2.3,
				borderRadius: size / 2.3,
				border: '1px solid white',
				background: color,
			}}
		/>
	);

	const publicationDot = (color, letter) => (
		<div
			style={{
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
				width: size / 2.3,
				height: size / 2.3,
				borderRadius: 4,
				fontSize: 8,
				background: color,
				color: 'white',
				lineHeight: 0,
				fontWeight: 'bold',
				marginBottom: 1,
			}}
		>
			{letter}
		</div>
	);
	const jobsPublicationDot = (color) => publicationDot(color, 'J');
	const companyPublicationDot = (color) => publicationDot(color, 'C');

	if (!company) {
		return (
			<span
				style={{
					color: theme.color.lightgrey,
				}}
			>
					N/A
			</span>
		);
	}

	const isConsideredPublished = (
		companyModel?.isPublishedNow()
		|| companyModel.isPublishedFuture()
		|| companyModel.areJobsPublishedNow()
		|| companyModel.areJobsPublishedFuture()
	);

	return (
		<Space
			direction='horizontal'
			size={4}
			align='center'
			{...props}
			style={{
				position: 'relative',
				opacity: withPublicationOpacity && !isConsideredPublished ? 0.5 : 1,
				...props?.style,
			}}
		>
			<CompanyLogo
				size={size}
				company={company}
				withTooltip={withTooltip}
				style={{
					position: 'relative',
					marginBottom: 2,
				}}
			/>
			{(withJobsPublicationDot || withPublicationDot) && (
				<div>
					{withPublicationDot && (
						companyPublicationDot(
							companyModel.isPublishedNow()
								? theme.color.darkturquoise
								: companyModel.isPublishedFuture()
									? theme.color.mustard
									: theme.color.accent,
						)
					)}
					{withJobsPublicationDot && (
						jobsPublicationDot(
							companyModel.areJobsPublishedNow()
								? theme.color.darkturquoise
								: companyModel.areJobsPublishedFuture()
									? theme.color.mustard
									: theme.color.accent,
						)
					)}

				</div>
			)}
			{(withAtsDot || withGenderScoreDot) && (
				<div>
					{withGenderScoreDot && (
						<div
							style={{
								position: 'relative',
							}}
						>
							<CompanyGenderScoreRingScore
								size={28}
								{...companyModel?.genderScore?.globalScore > 0
									? {
										forceScore: companyModel?.genderScore?.globalScore,
										value: companyModel?.genderScore?.globalScore,
									}
									: {
										ringColor: '#eee',
										forceScore: <span style={{ color: '#aaa' }}>
											{companyModel.isGenderScoreEnabled() ? '?' : <CloseOutlined />}</span>,
										value: 100,
									}
								}
							/>
							{companyModel.isGenderScoreEnabled() && (
								<div
									style={{
										position: 'absolute',
										bottom: 0,
										right: 0,
									}}
								>
									<GenderScoreSurveyStatusDot
										company={company}
										size={10}
										style={{
											icon: { fontSize: 10 },
										}}
									/>
								</div>
							)}
						</div>
					)}
				</div>
			)}
			<div style={{ lineHeight: 1 }}>
				<div>
					<div
						style={{
							fontWeight: 700,
						}}
					>
						{company?.name}

						{withAtsAccess && companyModel.isAtsSyncable() && (
							<AtsLogo
								ats={companyModel?.getSyncableAccesses()?.[0]?.ats}
								size={12}
								style={{ marginLeft: 5 }}
							/>
						)}
					</div>

					{withCompanyPlan && (
						<CompanyPlanTag
							company={companyModel}
							withShortFormat={true}
							withExtraTags={withCompanyPlanExtraTags}
							// style={{ marginLeft: 15, marginBottom: 5, padding: 0 }}
						/>
					)}
				</div>

				{children}
			</div>
		</Space>
	);
};

export default CompanyStub;
