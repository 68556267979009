import { Button as AntButton } from 'antd';

const Button = ({
	children, type = 'primary', otherStyles = {}, ...otherProps
}) => {
	return (
		<AntButton
			style={{
				// ...defaultStyleButton,
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'center',
				fontSize: 16,
				// fontWeight: 400,
				// borderRadius: 8,
				borderRadius: 4,
				padding: '2px 12px',
				// height: 45,
				// marginBottom: 12,
				...otherStyles,
			}}
			{...otherProps}
		>
			{children}
		</AntButton>
	);
};

export default Button;
