import { Button, Space } from 'antd';
import { useTheme } from '@emotion/react';
import { useAuthContext } from '../../../../hooks/useAuthContext';
import UserModel from '../../../../models/UserModel';
import UserGridCardLabelValue from './blocks/UserGridCardLabelValue';
import UserGridCardResumeLatestExperienceExpandable from './blocks/UserGridCardResumeLatestExperienceExpandable';
import UserGridCardCareerHardSkill from './blocks/UserGridCardCareerHardSkill';
import UserGridCardCareerSpecific from './blocks/UserGridCardCareerSpecific';
import UserGridCardJobSearchJobContract from './blocks/UserGridCardJobSearchJobContract';
import UserGridCardJobSearchJobLevel from './blocks/UserGridCardJobSearchJobLevel';
import UserGridCardJobSearchJobRoles from './blocks/UserGridCardJobSearchJobRoles';
import UserGridCardCoachingOpinion from './blocks/UserGridCardCoachingOpinion';
import UserResumeContactCardBody from '../../card/resume/UserResumeContactCardBody';
import UserGridCardResumeCertification from './blocks/UserGridCardResumeCertification';
import UserGridCardJobSearchJobLocation from './blocks/UserGridCardJobSearchJobLocation';
import UserGridCardSuggested from './UserGridCardSuggested';
import UserGridCardJobSearchJobRemote from './blocks/UserGridCardJobSearchJobRemote';
import UserGridCardCareerLanguage from './blocks/UserGridCardCareerLanguage';
import UserGridCardJobSearchEmploymentVisa from './blocks/UserGridCardJobSearchEmploymentVisa';
import UserGridCardJobSearchEmploymentEligibility from './blocks/UserGridCardJobSearchEmploymentEligibility';
import { SHORTLISTS_ICON } from '../../../../constants/constant';
import UserSaver from '../../buttons/UserSaver';
import { rowSpanner } from './UserGridCardUtils';
import UserGridCardName from './UserGridCardName';
import UserGridCardTopButtons from './UserGridCardTopButtons';
import { useContext } from 'react';
import { UserModalContext } from '../../../../contexts/modals/UserModalProvider';

const UserModalCardRightColumn = (
	{
		user,
		withSuggested = true,
		...props
	},
) => {
	const className = 'UserModalCardRightColumn';

	const theme = useTheme();

	const { isStaffView } = useAuthContext();
	const { setOpen } = useContext(UserModalContext);

	const userModel = new UserModel(user);

	const linksBlock = (
		<UserGridCardLabelValue
			label={<>Links</>}
			style={{ display: 'block', width: '100%' }}
		>
			<UserResumeContactCardBody
				user={userModel}
				vertical={false}
			/>
		</UserGridCardLabelValue>
	);

	return (
		<>
			<div style={{
				position: 'relative',
				padding: '30px 40px 20px 40px',
				width: '100%',
			}}>

				<Space
					align='top'
					style={{
						width: '100%',
						justifyContent: 'space-between',
					}}
				>

					<UserGridCardName
						user={user}
					/>

					<UserGridCardTopButtons
						user={user}
						style={{ ...props?.style?.topButtons }}
						onFullPageButtonClick={() => setOpen(false)}
						tooltipPlacement={'bottomRight'}
					/>
				</Space>

				<Space
					direction='vertical'
					size={20}
					style={{
						width: '100%',
					}}
				>
					{rowSpanner([
						[
							[
								{
									content: <UserGridCardCoachingOpinion
										user={user}
										opened={true}
										style={{ width: '100%' }}
									/>,
									span: 24,
									check: userModel.isCoachingVisible({ isStaffView }),
								},
							],
							[
								{
									content: <>
										<UserGridCardJobSearchJobRoles user={user} />
										<UserGridCardJobSearchJobLevel
											user={user}
											style={{ marginTop: 12 }}
										/>
									</>,
									span: 9,
									check: userModel.hasJobSearchJobRoles(),
								},
								{
									content: <UserGridCardJobSearchJobLocation user={user} />,
									span: 9,
									check: userModel.hasJobSearchJobLocations(),
								},
								{
									content: <>
										<UserGridCardJobSearchEmploymentVisa
											user={user}
										/>
										<UserGridCardJobSearchJobRemote
											user={user}
											style={{ marginTop: 12 }}
										/>
									</>,
									span: 6,
								},
							],
						],
						[[
							{
								content:
										rowSpanner(
											[[[
												{
													content: (
														<UserGridCardCareerHardSkill
															user={user}
														/>
													),
													span: 24,
												},
												{
													content: <UserGridCardResumeCertification
														user={user}
													/>,
													span: 24,
													check: userModel.hasResumeCertifications(),
												},
											]]],
											[0, 24],
											0,
										),
								span: userModel.hasCareerLanguages() ? 18 : 24,
							},
							{
								content: <UserGridCardCareerLanguage user={user} />,
								span: 6,
								check: userModel.hasCareerLanguages(),
							},
						]],
						[
							[
								{
									content: <UserGridCardResumeLatestExperienceExpandable
										user={user}
										style={{ display: 'block', width: '100%' }}
									/>,
									span: 24,
									check: userModel.hasResumeExperience(),
								},
							],
							[
								{
									content: <UserGridCardCareerSpecific user={user} />,
									span: 14,
									check: userModel.hasCareerSpecifics(),
								},
								{
									content: <UserGridCardJobSearchJobContract user={user} />,
									span: 10,
									check: userModel.hasJobSearchJobContracts(),
								},
							],
						],
						[
							[
								{
									content: linksBlock,
									span: 24,
									check: user?.information?.website || userModel.hasResumeContact(),
								},
							],
							[],
						],
					])}
				</Space>

			</div>

			{withSuggested && <UserGridCardSuggested user={user}/>}
		</>
	);
};

export default UserModalCardRightColumn;
