import { LaptopOutlined } from '@ant-design/icons';
import JobsPage from '../../containers/root/JobsPage';
import { PERMS_GRANT_STAFF_CLIENT_NO_EDITOR } from '../menu.utils';
import MenuBase from '../MenuBase';

export const GenderhireJobsIcon = LaptopOutlined;

class GenderhireJobsMenu extends MenuBase {
	static props = {
		path: '/jobs',
		name: 'jobs',
		label: 'Jobs',
		icon: <GenderhireJobsIcon />,
		iconLegacy: '💼',
		Component: JobsPage,
		onEnterProps: { shouldGoToPreviousHistory: true },
		perms: PERMS_GRANT_STAFF_CLIENT_NO_EDITOR,
	};

	constructor() {
		super(GenderhireJobsMenu.props);
	}
}

export default GenderhireJobsMenu;
