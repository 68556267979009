import { Col, Row, Space } from 'antd';
import { useContext } from 'react';
import { useTheme } from '@emotion/react';
import { documentId, numToArray } from '../../../utils/common';
import { DataFetcherContext } from '../data/DataFetcherProvider';
import GridLoadMoreButton from './GridLoadMoreButton';
import GridEmptyData from './GridEmptyData';
import GridCardSkeleton from './GridCardSkeleton';
import GridDataComplete from './GridDataComplete';

const Grid = (
	{
		cardRender,
		forceLoading,
		skeletonCard = <GridCardSkeleton />,
		columns = 3,
		gutter = [30, 30],
		empty = <GridEmptyData />,
		complete = <GridDataComplete />,
		...props
	},
) => {
	const className = 'Grid';

	const theme = useTheme();

	const {
		dataSource,
		setDataSource,
		isLoading,
		dataTotal,
		dataSourceComplete,
		isLoadMore, setIsLoadMore, fetchMore,
		params, paramsDispatch,
	} = useContext(DataFetcherContext);

	let content;

	const nextCount = Math.min(params?.limit, dataTotal - (params?.skip + params?.limit));

	const colify = (
		{
			items,
			span,
			renderer = (i) => i,
		},
	) => items?.map((item, index) => (
		<Col
			key={documentId(item) || index}
			span={span || Math.floor(24 / columns)}
		>
			{renderer(item)}
		</Col>
	));

	const dataSourceCols = colify({
		items: dataSource,
		renderer: (item) => cardRender(item, isLoading),
	});

	const skeletonCols = colify({
		items: numToArray(columns),
		renderer: () => skeletonCard,
	});

	if (forceLoading) {
		content = (
			<>
				{skeletonCols}
			</>
		);
	} else if (dataSource?.length === 0 && !isLoading) {
		content = colify({
			items: [empty],
			span: 24,
		});
	} else {
		content = dataSourceCols;

		if (isLoading) {
			content = (
				<>
					{isLoadMore && content}
					{skeletonCols}
				</>
			);
		} else {
			content = <>
				{/* {skeletonCols} */}
				{dataSourceCols}
				{isLoadMore
					&& colify({
						items: [
							dataSourceComplete
								? complete
								: (
									<Space style={{ width: '100%', justifyContent: 'center' }}>
										<GridLoadMoreButton
											title={isLoading
												? (
													<>
														Loading...
													</>
												)
												: (
													<>
														{/* Load the next {nextCount} result{nextCount > 1 ? 's' : ''} */}
														Load next results
													</>
												)
											}
											onClick={fetchMore}
											disabled={isLoading || dataSource?.length === 0}
										/>
									</Space>
								),
						],
						span: 24,
					})
				}
			</>;
		}
	}

	return (
		<div style={{
			width: '100%',
			// border: '1px solid red',
			...props?.style,
		}}>
			<Row
				gutter={gutter}
				style={{ padding: 0 }}
			>
				{content}
			</Row>
		</div>
	);
};

export default Grid;
