const { default: USER_PROPERTY_AUTH_EMAIL_VERIFIED } = require('./auth-email-verified');
const { default: USER_PROPERTY_AUTH_IS_ONBOARDED } = require('./auth-is-onboarded');
const { default: USER_PROPERTY_AUTH_STATE } = require('./auth-state');
const { default: USER_PROPERTY_CAREER_EXPERIENCE } = require('./career-experience');
const { default: USER_PROPERTY_CAREER_HARD_SKILL } = require('./career-hard-skill');
const { default: USER_PROPERTY_CAREER_IS_REACHABLE_BY_RECRUITERS } = require('./career-is-reachable-by-recruiters');
const { default: USER_PROPERTY_CAREER_ROLE } = require('./career-role');
const { default: USER_PROPERTY_CAREER_ROLE_GROUP } = require('./career-role-group');
const { default: USER_PROPERTY_CAREER_SEEKING } = require('./career-seeking');
const { default: USER_PROPERTY_CAREER_SEEKING_UPDATED_AT } = require('./career-seeking-updated-at');
const { default: USER_PROPERTY_CAREER_SPECIFIC } = require('./career-specific');
const { default: USER_PROPERTY_JOB_SEARCH_DEI_CRITERIA } = require('./job-search-dei-criteria');
const { default: USER_PROPERTY_JOB_SEARCH_DEI_CRITERIA_GROUP } = require('./job-search-dei-criteria-group');
const { default: USER_PROPERTY_JOB_SEARCH_EMAIL_CADENCE } = require('./job-search-email-cadence');
const { default: USER_PROPERTY_JOB_SEARCH_EMPLOYMENT_VISA } = require('./job-search-employment-visa');
const { default: USER_PROPERTY_JOB_SEARCH_HUNTING_CONSENT } = require('./job-search-hunting-consent');
const { default: USER_PROPERTY_JOB_SEARCH_JOB_LEVEL } = require('./job-search-job-level');
const { default: USER_PROPERTY_JOB_SEARCH_JOB_LOCATION } = require('./job-search-job-location');
const { default: USER_PROPERTY_JOB_SEARCH_JOB_PLACE } = require('./job-search-job-place');
const { default: USER_PROPERTY_JOB_SEARCH_JOB_REMOTE } = require('./job-search-job-remote');
const { default: USER_PROPERTY_JOB_SEARCH_JOB_ROLE } = require('./job-search-job-role');
const { default: USER_PROPERTY_JOB_SEARCH_JOB_ROLE_GROUP } = require('./job-search-job-role-group');
const { default: USER_PROPERTY_MENTOR_AVAILABILITY } = require('./mentor-availability');
const { default: USER_PROPERTY_MENTOR_CONSENT } = require('./mentor-consent');
const { default: USER_PROPERTY_MENTOR_LEVEL } = require('./mentor-level');
const { default: USER_PROPERTY_MENTOR_SUBJECT } = require('./mentor-subject');
const { default: USER_PROPERTY_MENTOR_SUBJECT_CUSTOM } = require('./mentor-subject-custom');
const { default: USER_PROPERTY_PROFILE_AVATAR } = require('./profile-avatar');
const { default: USER_PROPERTY_PROFILE_BIOGRAPHY } = require('./profile-biography');
const { default: USER_PROPERTY_PROFILE_COMMUNITY_CONSENT } = require('./profile-community-consent');
const { default: USER_PROPERTY_PROFILE_COMPANY } = require('./profile-company');
const { default: USER_PROPERTY_PROFILE_CONTENT_INTEREST } = require('./profile-content-interest');
const { default: USER_PROPERTY_PROFILE_DECLARED_WOMAN } = require('./profile-declared-woman');
const { default: USER_PROPERTY_PROFILE_FIRSTNAME } = require('./profile-firstname');
const { default: USER_PROPERTY_PROFILE_GOAL } = require('./profile-goal');
const { default: USER_PROPERTY_PROFILE_LASTNAME } = require('./profile-lastname');
const { default: USER_PROPERTY_PROFILE_LINKEDIN } = require('./profile-linkedin');
const { default: USER_PROPERTY_PROFILE_LOCATION_COUNTRY } = require('./profile-location-country');
const { default: USER_PROPERTY_PROFILE_LOCATION_CITY } = require('./profile-location-city');
const { default: USER_PROPERTY_PROFILE_POSITION } = require('./profile-position');

const registerUserProperty = (property, target = {}) => {
	const { key } = property;
	return {
		...(target || {}),
		[key]: property,
		list: [
			...(target.list || []),
			property,
		],
	};
};

let exporter = {};
exporter = registerUserProperty(USER_PROPERTY_AUTH_EMAIL_VERIFIED, exporter);
exporter = registerUserProperty(USER_PROPERTY_AUTH_IS_ONBOARDED, exporter);
exporter = registerUserProperty(USER_PROPERTY_AUTH_STATE, exporter);

exporter = registerUserProperty(USER_PROPERTY_CAREER_EXPERIENCE, exporter);
exporter = registerUserProperty(USER_PROPERTY_CAREER_HARD_SKILL, exporter);
exporter = registerUserProperty(USER_PROPERTY_CAREER_IS_REACHABLE_BY_RECRUITERS, exporter);
exporter = registerUserProperty(USER_PROPERTY_CAREER_ROLE, exporter);
exporter = registerUserProperty(USER_PROPERTY_CAREER_ROLE_GROUP, exporter);
exporter = registerUserProperty(USER_PROPERTY_CAREER_SEEKING_UPDATED_AT, exporter);
exporter = registerUserProperty(USER_PROPERTY_CAREER_SEEKING, exporter);
exporter = registerUserProperty(USER_PROPERTY_CAREER_SPECIFIC, exporter);

exporter = registerUserProperty(USER_PROPERTY_JOB_SEARCH_DEI_CRITERIA, exporter);
exporter = registerUserProperty(USER_PROPERTY_JOB_SEARCH_DEI_CRITERIA_GROUP, exporter);
exporter = registerUserProperty(USER_PROPERTY_JOB_SEARCH_EMAIL_CADENCE, exporter);
exporter = registerUserProperty(USER_PROPERTY_JOB_SEARCH_EMPLOYMENT_VISA, exporter);
exporter = registerUserProperty(USER_PROPERTY_JOB_SEARCH_HUNTING_CONSENT, exporter);
exporter = registerUserProperty(USER_PROPERTY_JOB_SEARCH_JOB_ROLE, exporter);
exporter = registerUserProperty(USER_PROPERTY_JOB_SEARCH_JOB_ROLE_GROUP, exporter);
exporter = registerUserProperty(USER_PROPERTY_JOB_SEARCH_JOB_LEVEL, exporter);
exporter = registerUserProperty(USER_PROPERTY_JOB_SEARCH_JOB_LOCATION, exporter);
exporter = registerUserProperty(USER_PROPERTY_JOB_SEARCH_JOB_PLACE, exporter);
exporter = registerUserProperty(USER_PROPERTY_JOB_SEARCH_JOB_REMOTE, exporter);

exporter = registerUserProperty(USER_PROPERTY_MENTOR_AVAILABILITY, exporter);
exporter = registerUserProperty(USER_PROPERTY_MENTOR_CONSENT, exporter);
exporter = registerUserProperty(USER_PROPERTY_MENTOR_LEVEL, exporter);
exporter = registerUserProperty(USER_PROPERTY_MENTOR_SUBJECT, exporter);
exporter = registerUserProperty(USER_PROPERTY_MENTOR_SUBJECT_CUSTOM, exporter);

exporter = registerUserProperty(USER_PROPERTY_PROFILE_AVATAR, exporter);
exporter = registerUserProperty(USER_PROPERTY_PROFILE_BIOGRAPHY, exporter);
exporter = registerUserProperty(USER_PROPERTY_PROFILE_COMMUNITY_CONSENT, exporter);
exporter = registerUserProperty(USER_PROPERTY_PROFILE_COMPANY, exporter);
exporter = registerUserProperty(USER_PROPERTY_PROFILE_CONTENT_INTEREST, exporter);
exporter = registerUserProperty(USER_PROPERTY_PROFILE_DECLARED_WOMAN, exporter);
exporter = registerUserProperty(USER_PROPERTY_PROFILE_FIRSTNAME, exporter);
exporter = registerUserProperty(USER_PROPERTY_PROFILE_GOAL, exporter);
exporter = registerUserProperty(USER_PROPERTY_PROFILE_LASTNAME, exporter);
exporter = registerUserProperty(USER_PROPERTY_PROFILE_LINKEDIN, exporter);
exporter = registerUserProperty(USER_PROPERTY_PROFILE_LOCATION_COUNTRY, exporter);
exporter = registerUserProperty(USER_PROPERTY_PROFILE_LOCATION_CITY, exporter);
exporter = registerUserProperty(USER_PROPERTY_PROFILE_POSITION, exporter);

export default {
	...exporter,
	keys: exporter.list.map((item) => item.key),
};
