import { useTheme } from '@emotion/react';
import React, { useContext, useEffect, useState } from 'react';
import { ENGLISH_LANG, FRENCH_LANG } from '../../../../constants/constant';
import { useDispatch, useSelector } from 'react-redux';
import { getCompanySelected } from '../../../../reducers/app';
import { getCurrentEditionState } from './CompanyPageEditionStates';
import { saveCompany } from '../../../../actions/company';
import { refreshCurrentUser } from '../../../../actions/app';
import { NotificationContext } from '../../../app/notification/NotificationProvider';
import { documentId, isFunction } from '../../../../utils/common';
import CompanyModel from '../../../../models/CompanyModel';

export const CompanyPageContext = React.createContext();

export const LANG_TEXTS = {
	[ENGLISH_LANG]: {
		flag: '🇬🇧',
		label: 'English',
		labelIn: ' in English',
		labelInFlag: <> in English&nbsp;🇬🇧</>,
		labelInBoth: ' in English and in French',
		labelInFlagBoth: <> in English&nbsp;🇬🇧 and in French&nbsp;🇫🇷</>,
	},
	[FRENCH_LANG]: {
		flag: '🇫🇷',
		label: 'French',
		labelIn: ' in French',
		labelInFlag: <> in French&nbsp;🇫🇷</>,
	},
	none: {
		flag: '',
		label: '',
		labelIn: '',
		labelInFlag: '',
		labelInBoth: '',
		labelInFlagBoth: '',
	},
};

const CompanyPageProvider = (
	{
		children,
	},
) => {
	const className = 'CompanyPageProvider';

	const theme = useTheme();

	const dispatch = useDispatch();

	const { notifyLoading, notifySuccess } = useContext(NotificationContext);

	const companySelected = useSelector(getCompanySelected);

	const [companyModel, setCompanyModel] = useState(companySelected
		? new CompanyModel(companySelected)
		: null);
	const [lang, setLang] = useState(ENGLISH_LANG);
	const [langTexts, setLangTexts] = useState(LANG_TEXTS.none);
	const [currentlyEditingStep, setCurrentlyEditingStep] = useState(null);
	const [openStepsDrawer, setOpenStepsDrawer] = useState(false);
	const [openPublicationPopin, setOpenPublicationPopin] = useState(false);
	const [openUnpublicationPopin, setOpenUnpublicationPopin] = useState(false);
	const [editionState, setEditionState] = useState();
	const [openLangEditionTour, setOpenLangEditionTour] = useState(false);

	useEffect(() => {
		if (!companySelected) {
			setCompanyModel(null);
			return;
		}
		setCompanyModel(new CompanyModel(companySelected));
		setEditionState(getCurrentEditionState({ company: companySelected, lang }));
	}, [
		companySelected,
		lang,
	]);

	useEffect(() => {
		if (companyModel?.isTranslatable) {
			setLangTexts(LANG_TEXTS[lang]);
			return;
		}
		setLangTexts(LANG_TEXTS.none);
	}, [
		companyModel?.isTranslatable,
		lang,
	]);

	const publishCompanyPage = async (callback) => {
		const data = {
			id: documentId(companySelected),
			...lang === ENGLISH_LANG
				? { isPublished: true }
				: { isTranslationPublished: true },
		};

		notifyLoading({
			key: className,
			description: <div>Publishing your company page<b>{langTexts.labelInFlag}</b>...</div>,
		});

		await dispatch(saveCompany(data));

		refreshCurrentUser()(dispatch);

		notifySuccess({
			key: className,
			description: <div>
				Company page published<b>{langTexts.labelInFlag}</b>
			</div>,
			duration: 3,
			onClose: () => {
				if (
					companyModel.isTranslatable
					&& !companyModel.isTranslationPublished
					&& lang === ENGLISH_LANG
				) {
					setCurrentlyEditingStep(null);
					setOpenLangEditionTour(true);
				}
			},
		});

		if (isFunction(callback)) callback();
	};

	const unpublishCompanyPage = async (callback) => {
		const langVersion = lang === ENGLISH_LANG
			? langTexts.labelInFlagBoth
			: langTexts.labelInFlag;

		const data = {
			id: documentId(companySelected),
			...lang === ENGLISH_LANG
				? {
					isPublished: false,
					publishedAt: null,
					...companySelected.isTranslatable
						? {
							isTranslationPublished: false,
							translationPublishedAt: null,
						}
						: {},
				}
				: {
					isTranslationPublished: false,
				},
		};

		notifyLoading({
			key: className,
			description: <div>Unpublishing your company page<b>{langVersion}</b>...</div>,
		});

		await dispatch(saveCompany(data));

		refreshCurrentUser()(dispatch);

		notifySuccess({
			key: className,
			description: <div>Company page unpublished<b>{langVersion}</b></div>,
			duration: 3,
		});

		if (isFunction(callback)) callback();
	};

	return (
		<CompanyPageContext.Provider
			value={{
				lang,
				setLang,
				langTexts,
				editionState,
				openStepsDrawer,
				setOpenStepsDrawer,
				openPublicationPopin,
				setOpenPublicationPopin,
				openUnpublicationPopin,
				setOpenUnpublicationPopin,
				currentlyEditingStep,
				setCurrentlyEditingStep,
				publishCompanyPage,
				unpublishCompanyPage,
				openLangEditionTour,
				setOpenLangEditionTour,
			}}
		>
			{children}
		</CompanyPageContext.Provider>
	);
};

export default CompanyPageProvider;
