import { useEffect, useState } from 'react';
import {
	Col, Form, Row,
} from 'antd';
import Modal from '../../../app/modal/Modal';
import { localDebug } from '../../../../utils/LocalDebug';
import CompanyRelevantDataLine from './CompanyRelevantDataLine';
import FormItem from '../../form/FormItem';
import { requiredTrimRule } from '../../../../utils/common';

const CompanyRelevantDataModal = (
	{
		title = 'Add a custom data',
		initialValues = {},
		open,
		onSave,
		handleClose,
	},
) => {
	const [form] = Form.useForm();
	const [formValues, setFormValues] = useState(initialValues);

	const _handleClose = () => {
		form.resetFields();
		handleClose();
	};

	const handleChange = (_, values) => {
		localDebug('values', values);
		setFormValues(values);
	};

	useEffect(() => {
		setFormValues(initialValues);
		form.resetFields();
		form.setFieldsValue(initialValues);
	}, [form, initialValues]);

	return (
		<Modal
			title={title}
			open={open}
			okText='Add this data'
			cancelText='Cancel'
			onCancel={_handleClose}
			onOk={() => {
				form
					.validateFields()
					.then((values) => {
						onSave({
							...initialValues,
							...values,
						});
						form.resetFields();
					})
					.catch((info) => {
						localDebug('Validate Failed:', info);
					});
			}}
		>
			<Row gutter={[20, 20]}>
				<Col span={12}>
					<Form
						form={form}
						name='data_form'
						initialValues={{ ...initialValues }}
						onValuesChange={handleChange}

					>
						<FormItem
							label='Label'
							name='label'
							defaultContainer='div'
							rules={[requiredTrimRule]}
						/>

						<FormItem
							label='Value'
							name='value'
							defaultContainer='div'
							rules={[requiredTrimRule]}
						/>
					</Form>

				</Col>
				<Col span={12}>
					<h3>Preview</h3>
					<CompanyRelevantDataLine data={formValues} />
				</Col>
			</Row>
		</Modal>
	);
};

export default CompanyRelevantDataModal;
