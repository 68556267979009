import { Col, Row, Space } from 'antd';
import { useContext, useEffect, useState } from 'react';
import {
	SOURCING_OPTION_ARCHIVED,
	SOURCING_PIPELINE_OPTIONS,
} from '../../constants/constant';
import { CandidateContext } from '../../contexts/CandidateProvider';
import { LocalDebug } from '../../utils/LocalDebug';
import { useAuthContext } from '../../hooks/useAuthContext';
import CandidateSelectorPipelineButton from './CandidateSelectorPipelineButton';
import CandidateSelectorStageButton from './CandidateSelectorStageButton';

const CandidateStageSelector = (
	{
		initialStage,
		atsPipelines,
		atsArchiveReasons,
		onStageSelected,
		withPipelineSelector = false,
		withStageSelector = false,
		withArchivedSelector = false,
		...props
	},
) => {
	const className = 'CandidateStageSelector';

	const {
		candidateCounts,
		candidatePipeline, candidateStages, isArchived, archiveReasons,
		updatePipeline, updateStages, updateIsArchived, updateArchiveReasons,
		updateSelectedOptions, shouldRefresh,
	} = useContext(CandidateContext);
	const [pipelineOptions, setPipelineOptions] = useState([]);
	const [archiveOption, setArchiveOption] = useState(atsArchiveReasons);
	const [stageOptions, setStageOptions] = useState([]);
	const [archiveReasonOptions, setArchiveReasonOptions] = useState([]);

	const { isAdmin } = useAuthContext();

	useEffect(() => {
		const logVars = { className, effects: 'candidateCounts,shouldRefresh' };
		// LocalDebug.logUseEffect(logVars, { candidateCounts, shouldRefresh, candidatePipeline, candidateStages, isArchived, archiveReasons });
		setPipelineOptions(
			(atsPipelines || [])
				.map((option) => ({
					...option,
					count: candidateCounts?.candidatePipeline?.[option.value] || 0,
				}))
				.filter((option) => !option.hideIfEmpty || option.count > 0),
		);

		setArchiveOption({
			...SOURCING_OPTION_ARCHIVED,
			count: candidateCounts?.isArchived,
		});

		if (isArchived) {
			setArchiveReasonOptions(
				(atsArchiveReasons || [])
					.map((option) => ({
						...option,
						count: candidateCounts?.atsArchiveReason?.[option.value],
					}))
					.filter((option) => !option.hideIfEmpty || option.count > 0),
			);
		} else {
			// LocalDebug.logUseEffect(logVars, { candidateCounts, candidatePipeline, candidateStages, isArchived, archiveReasons });
			setStageOptions(
				(candidatePipeline?.stages || [])
					.map((option) => ({
						...option,
						count: candidateCounts?.candidateStage?.[option.value],
					}))
					.filter((option) => !option.hideIfEmpty || option.count > 0),
			);
		}
	}, [
		candidateCounts,
		shouldRefresh,
		// candidatePipeline,
	]);

	return <>
		{
			// (stageOptions?.length > 0 || archiveReasonOptions?.length > 0 &&
			<>
				{withPipelineSelector
					&& withArchivedSelector
					&& <Space direction='horizontal' style={{ width: '100%', justifyContent: 'space-between' }}>
						{withPipelineSelector
							&& pipelineOptions?.length > 0
							&& (
								<Space direction='horizontal' style={{ margin: '10px 0' }}>
									{pipelineOptions.map((p, index) => <CandidateSelectorPipelineButton
										key={index}
										pipeline={p}
										selected={p.value === candidatePipeline?.value}
										onClick={() => {
											updateSelectedOptions({
												pipeline: p,
												stages: [],
												isArchived: false,
											});
										// updateIsArchived(false);
										// updatePipeline(p);
										// updateStages([]);
										}}
									/>)}
								</Space>
							)
						}
						{withArchivedSelector
							&& atsArchiveReasons?.length > 0
							&& <CandidateSelectorPipelineButton
								pipeline={archiveOption}
								selected={isArchived}
								onClick={() => {
									updateSelectedOptions({
										pipeline: null,
										stages: [],
										isArchived: true,
									});
									// updatePipeline(null);
									// updateStages([]);
									// updateIsArchived(true);
								}}
							/>
						}
					</Space>
				}
				{withStageSelector
					&& <Row span={24} gutter={[10, 10]} style={{ ...props?.style, width: '100%', overflow: 'visible' }}>
						{isArchived
							? (archiveReasonOptions || []).map((o) => {
								const selected = archiveReasons.map((r) => r.value).includes(o.value);
								// LocalDebug.logInfo({ className, method: 'recreating archive reason buttons' }, { selected });
								return <Col key={o?.value} span={3}>
									<CandidateSelectorStageButton
										stage={o}
										selected={selected}
										onClick={() => {
											LocalDebug.logInfo({
												className,
												method: 'CandidateSelectorStageButton.onClick',
											}, { o, archiveReasons });
											const ar = selected
												? archiveReasons.filter((item) => item.value !== o.value)
												: [...archiveReasons, o];
											updateSelectedOptions({ archiveReasons: ar });
											// if (selected) {
											//     updateArchiveReasons(archiveReasons.filter(r => r.value !== o.value));
											// } else {
											//     updateArchiveReasons([...archiveReasons, o]);
											// }
										}}
									/>
								</Col>;
							})
							: stageOptions?.length > 1
							&& (stageOptions || []).map((o) => {
								const selected = (candidateStages || []).map((s) => s.value).includes(o.value);
								// LocalDebug.logInfo({ className, method: 'recreating stage buttons' }, { selected });
								// return <JSONPretty data={o} />;
								return <Col key={o?.value} span={3}>
									<CandidateSelectorStageButton
										stage={o}
										selected={selected}
										onClick={() => {
											LocalDebug.logInfo({
												className,
												method: 'CandidateSelectorStageButton.onClick',
											}, { o, candidateStages });
											const st = selected
												? candidateStages.filter((item) => item.value !== o.value)
												: [...candidateStages, o];
											updateSelectedOptions({ stages: st });
											// if (selected) {
											//     updateStages(candidateStages.filter(s => s.value !== o.value));
											// } else {
											//     updateStages([...candidateStages, o]);
											// }
										}}
									/>
								</Col>;
							})
						}
					</Row>
				}
			</>
			// )
		}</>;
};

export default CandidateStageSelector;
