import { useTheme } from '@emotion/react';
import Box from '../../app/box/Box';

const UserProfileBoxValue = ({
	label,
	children,
	...props
}) => {
	const theme = useTheme();
	return (
		<Box
			withShadow={true}
			style={{
				padding: 32,
			}}
		>
			{label && (
				<h2
					style={{
						marginTop: 0,
						marginBottom: 36,
						color: theme.color.darkGrey,
					}}
				>
					{label}
				</h2>
			)}
			{children}
		</Box>
	);
};

export default UserProfileBoxValue;
