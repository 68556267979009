import { Badge, Tag, Tooltip } from 'antd';
import { useTheme } from '@emotion/react';
import { arrayWrap } from '../../utils/common';
import {
	BUSINESS_PLAN_NO_PLAN_VALUE,
	getBusinessPlanColor,
	getBusinessPlanLabel,
	getBusinessPlanShortLabel,
} from '../../constants/property/business-plan';
import { companyPlanExtraTagsPath } from './companyPlan/CompanyPlanExtraTagsFormItem';
import { innerObjPropExtractor } from '../../constants/property/property-extractor';
import { companyPlanValuePath } from './companyPlan/CompanyPlanFormItem';

const CompanyPlanTag = (
	{
		company,
		withShortFormat = false,
		withExtraTags = true,
		...props
	},
) => {
	const theme = useTheme();

	const planValue = innerObjPropExtractor(companyPlanValuePath)(company)
		|| props?.planValue
		|| BUSINESS_PLAN_NO_PLAN_VALUE;
	const color = getBusinessPlanColor(planValue);
	let label = getBusinessPlanLabel(planValue);
	const isNoPlan = planValue === BUSINESS_PLAN_NO_PLAN_VALUE;

	// Style to complete and apply to the tag
	let tagStyle = {
		userSelect: 'none',
	};

	// Offset to apply to the badge
	let badgeOffset = [-9, 2];
	let badgeStyle = {};

	if (withShortFormat) {
		label = getBusinessPlanShortLabel(planValue);

		badgeOffset = [-1, 4];
		const badgeSize = 3;
		badgeStyle = {
			width: badgeSize,
			minWidth: badgeSize,
			maxWidth: badgeSize,
			height: badgeSize,
			minHeight: badgeSize,
			maxHeight: badgeSize,
		};

		tagStyle = {
			...tagStyle,
			fontSize: 8,
			padding: '0px 3px',
			lineHeight: 1.3,
			borderRadius: 3,
		};
	}

	label = label || planValue || 'Plan N/A';
	let tagLabel = label;
	if (!isNoPlan) {
		// Format the label to highlight the last word
		const [prefix, suffix] = [
			...label?.split?.(' ') || [],
			'', '', // to avoid the spreading into prefix/suffix to break
		];
		tagLabel = <span>{prefix} <b>{suffix}</b></span>;
	}

	// Format the extra tags
	const companyPlanExtraTags = arrayWrap(
		innerObjPropExtractor(companyPlanExtraTagsPath)(company) || [],
	) || [];
	const showExtraTags = withExtraTags && companyPlanExtraTags?.length > 0;

	const extraTagsComponents = companyPlanExtraTags
		?.map((tag, index) => (
			<Tag
				key={index}
				color={theme.color.fitBlueElectric}
			>
				{tag}
			</Tag>
		));

	const extraTagsTooltipTitle = (
		<div>
			<b>Extra tags: </b>
			<div style={{ padding: 5, userSelect: 'none' }}>
				{extraTagsComponents}
			</div>
		</div>
	);

	const tagComponent = (<>
		<Tag
			color={color}
			style={{
				...tagStyle,
				...props?.style,
			}}
		>
			{tagLabel}
		</Tag>
		{isNoPlan && !withShortFormat && <Tooltip
			title={'This company has no company plan associated. Please take actions to solve it. All companies should have a company plan.'}
		>
			<span
				style={{
					fontSize: 16,
				}}>
						❗
			</span>
		</Tooltip>
		}
	</>);

	if (showExtraTags) {
		return (
			<Tooltip
				title={extraTagsTooltipTitle}
			>
				<Badge
					dot={showExtraTags}
					color={theme.color.fitBlueElectric}
					offset={badgeOffset}
					style={badgeStyle}
				>
					{tagComponent}
				</Badge>
			</Tooltip>
		);
	}

	return tagComponent;
};

export default CompanyPlanTag;
