import { useEffect, useState } from 'react';
import { Col, Form, Row } from 'antd';
import FormCompanySelect from '../../../app/form/FormCompanySelect';
import { documentId, getQueryParams } from '../../../../utils/common';
import { useSelector } from 'react-redux';
import { getCompanies } from '../../../../reducers/app';
import FormItem from '../../../company/form/FormItem';
import FilterModal from '../../../app/filters/FilterModal';
import { localDebug } from '../../../../utils/LocalDebug';

const UserActionFilterModal = ({
	title,
	open,
	initialValues = {},
	onClose,
	onFilter,
}) => {
	const [form] = Form.useForm();
	const queryParams = getQueryParams();
	const company = useSelector(getCompanies);
	const [optionsCompany, setOptionsCompany] = useState([]);

	const getDefaultSelected = (fieldName) => {
		return queryParams[fieldName] || [];
	};

	useEffect(() => {
		setOptionsCompany([
			// {id:null, name: ' Any company'},
			...company,
		]);
	}, [company]);

	const handleFilter = () => {
		form
			.validateFields()
			.then((values) => {
				const filtersValues = values;
				// const filtersValues = objectFilterUndefinedValues(values);
				if (onFilter) onFilter(filtersValues);
			})
			.catch((errors) => {
				localDebug('Validate Failed:', errors);
			});
	};

	useEffect(() => {
		form.resetFields();
		form.setFieldsValue(initialValues);
	}, [form, initialValues]);

	return (
		<FilterModal
			title={title}
			width={500}
			open={open}
			onClose={onClose}
			onFilter={handleFilter}
		>

			<Form
				form={form}
				name="add-team"
				labelCol={{ span: 8 }}
				labelAlign='right'
				className="user-filter-form"
			>
				<Row gutter={24}>
					<Col span={24}>
						<FormItem label="Company" name="companyId">
							<FormCompanySelect
								options={optionsCompany.map((c) => ({ value: documentId(c), label: c.name, item: c }))}
								defaultValue={getDefaultSelected('companyId')}
								mode="multiple"
							/>
						</FormItem>
					</Col>
					<Col span={24}>
						<FormItem label="Excluded Company" name="notCompanyIds">
							<FormCompanySelect
								options={optionsCompany.map((c) => ({ value: documentId(c), label: c.name, item: c }))}
								defaultValue={getDefaultSelected('notCompanyIds')}
								mode="multiple"
							/>
						</FormItem>
					</Col>
					{/* <Col span={24}> */}
					{/*    <FormItem label="Role" name="role"> */}
					{/*        <FormAclRoleSelect */}
					{/*            options={optionsRole} */}
					{/*            defaultValue={getDefaultSelected('role')} */}
					{/*            placeholder="Select one or more roles" */}
					{/*            mode="multiple" */}
					{/*        /> */}
					{/*    </FormItem> */}
					{/* </Col> */}
					{/* <Col span={24}> */}
					{/*    <FormItem label="Active" name="isActive"> */}
					{/*        <FormSelect options={BOOLEAN_OPTIONS}/> */}
					{/*    </FormItem> */}
					{/* </Col> */}
				</Row>
			</Form>

		</FilterModal>
	);
};

export default UserActionFilterModal;
