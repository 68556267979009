import { useContext } from 'react';
import SearchTalentsPage from '../../containers/root/SearchTalentsPage';
import { PERMS_GRANT_STAFF_CLIENT_NO_EDITOR } from '../menu.utils';
import MenuBase from '../MenuBase';
import { SearchOutlined } from '@ant-design/icons';
import MenuBadge from '../MenuBadge';
import { AlertContext } from '../../contexts/AlertProvider';

export const GenderhireSearchTalentsLabel = ({
	withBadge = false,
}) => {
	const alertContext = useContext(AlertContext);

	return (
		<>
			<span>Search Talents</span>
			{withBadge && (
				<MenuBadge
					overflowCount={100000}
					value={alertContext.alerts?.length}
				/>
			)}
		</>
	);
};

export const GenderhireSearchTalentsIcon = SearchOutlined;

class GenderhireSearchTalentsMenu extends MenuBase {
	static props = {
		name: 'search-talents',
		path: '/search',
		aliases: ['^/search$', '^/search-talents$'],
		label: () => (args) => <GenderhireSearchTalentsLabel {...args } />,
		icon: <GenderhireSearchTalentsIcon />,
		iconLegacy: '🔦',
		Component: SearchTalentsPage,
		onEnterProps: { shouldLoadUserTags: true },
		perms: PERMS_GRANT_STAFF_CLIENT_NO_EDITOR,
	};

	constructor() {
		super(GenderhireSearchTalentsMenu.props);
	}
}

export default GenderhireSearchTalentsMenu;
