import { documentId } from '../utils/common';

export const ATS_LEVER = 'lever';
export const ATS_LEVER_EU = 'lever_eu';
export const ATS_LEVER_SANDBOX = 'lever_sandbox';
export const ATS_GREENHOUSE = 'greenhouse';
export const ATS_SMARTRECRUITERS = 'smartrecruiters';
export const ATS_TEAMTAILOR = 'teamtailor';
export const ATS_ASHBY = 'ashby';
export const ATS_WORKABLE = 'workable';
export const ATS_WORKDAY = 'workday';
export const ATS_SUCCESSFACTORS = 'successfactors';
export const ACCESS_TYPE_ENPOINT = 'endpoint';
export const ACCESS_TYPE_API = 'api';

export default class CompanyAccessModel {
	constructor(access) {
		this.access = access;
	}

	get id() { return documentId(this.access); }

	get isActive() { return this.access?.isActive || false; }

	get companyId() { return this.access?.companyId; }

	get isAtsLever() { return this.access?.ats === ATS_LEVER; }

	get isAtsLeverEu() { return this.access?.ats === ATS_LEVER_EU; }

	get isAtsLeverSandbox() { return this.access?.ats === ATS_LEVER_SANDBOX; }

	get isAtsLeverAny() { return this.isAtsLever || this.isAtsLeverEu || this.isAtsLeverSandbox; }

	get isAtsGreenhouse() { return this.access?.ats === ATS_GREENHOUSE; }

	get isAtsSmartRecruiters() { return this.access?.ats === ATS_SMARTRECRUITERS; }

	get isAtsTeamTailor() { return this.access?.ats === ATS_TEAMTAILOR; }

	get isAtsWorkable() { return this.access?.ats === ATS_WORKABLE; }

	get isAtsSuccessFactors() { return this.access?.ats === ATS_SUCCESSFACTORS; }

	get isAccessTypeEnpoint() { return this.access?.accessType === ACCESS_TYPE_ENPOINT; }

	get isAccessTypeApi() { return this.access?.accessType === ACCESS_TYPE_API; }

	get isFormBoardNameVisible() { return this.isAccessTypeApi && this.isAtsGreenhouse; }

	get isFormOnBehalfOfVisible() { return this.isAccessTypeApi && this.isAtsGreenhouse; }

	get isFormCustomAtsSubdomainVisible() { return this.isAccessTypeApi && (this.isAtsGreenhouse || this.isAtsSmartRecruiters); }

	get isFormCustomApiSubdomainVisible() { return this.isAccessTypeApi && (this.isAtsWorkable || this.isAtsSmartRecruiters); }
}
