import { Space } from 'antd';

const GridEmptyData = (
	{
		children = <>No results found</>,
		...props
	},
) => {
	const className = 'GridEmptyData';

	return (
		<Space
			style={{
				border: '3px dashed #eee',
				borderRadius: 12,
				width: '100%',
				height: 300,
				justifyContent: 'center',
				alignItems: 'center',
			}}>
			{children}
		</Space>
	);
};

export default GridEmptyData;
