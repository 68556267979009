import { Popover, Space } from 'antd';
import UserAvatar from '../widgets/UserAvatar';
import UserLinkDropdown from '../widgets/UserLinkDropdown';
import UserStubLocation from './UserStubLocation';
import UserStubProfessional from './UserStubProfessional';
import UserStubName from './UserStubName';
import UserPreviewCard from '../card/UserPreviewCard';
import UserStubDEICriteria from './UserStubDEICriteria';
import UserStubEmail from './UserStubEmail';

const UserStub = (
	{
		user,
		withBox = false,
		withCompanyLogo = true,
		withProfession = true,
		withLocation = true,
		withDEICriteria = false,
		withEmail = false,
		withDropdown = false,
		withPreview = false,
		withPreviewButtons = false,
		direction = 'vertical',
		avatarSize = 36,
		avatarForceHide = false,
		avatarForceShow = false,
		nameForceHide = false,
		nameForceShow = false,
		activeTab = null,
		withIsNew = true,
		withIsNewlyOnboarded = false,
		withIsUnviewed = true,
		withTooltip = false,
		withCoachedTag = true,
		withResumeTag = true,
		onRefresh,
		children,
		...props
	},
) => {
	const className = 'UserStub';

	if (!user) return null;

	let component = (
		<Space
			direction="horizontal"
			size={props?.style?.avatarSpacing || 12}
			className='user-stub'
			{...props}
		>
			{avatarSize > 0
			&& <UserAvatar
				size={avatarSize}
				user={user}
				withIsNew={withIsNew}
				withIsNewlyOnboarded={withIsNewlyOnboarded}
				withIsUnviewed={withIsUnviewed}
				withTooltip={withTooltip}
				withCompanyLogo={withCompanyLogo}
				forceShow={avatarForceShow}
				forceHide={avatarForceHide}
			/>}
			<Space direction={direction} size={3}>
				<UserStubName
					user={user}
					withCoachedTag={withCoachedTag}
					withResumeTag={withResumeTag}
					forceShow={nameForceShow}
					forceHide={nameForceHide}
					{...props}
				/>
				{withProfession && <UserStubProfessional user={user}/>}
				{withLocation && <UserStubLocation user={user}/>}
				{withDEICriteria && <UserStubDEICriteria user={user}/>}
				{withEmail && <UserStubEmail user={user}/>}
				{children}
			</Space>
		</Space>
	);

	if (withPreview) {
		component = <Popover
			title={<UserStub user={user}/>}
			content={<UserPreviewCard withButtons={withPreviewButtons} user={user} onRefresh={onRefresh}/>}
			placement='left'
			style={{ maxWidth: 400 }}
		>
			{component}
		</Popover>;
	}

	return (withDropdown
		? <UserLinkDropdown user={user} activeTab={activeTab} onRefresh={onRefresh}>
			{component}
		</UserLinkDropdown>
		: component
	);
};

export default UserStub;
