import { useContext } from 'react';
import { UserSavedSearchContext } from './UserSavedSearchProvider';
import Callout from '../app/callout/Callout';
import { useTheme } from '@emotion/react';
import { FaEyeSlash } from 'react-icons/fa';
import Logo50inTech from '../app/Logo50inTech';

const SearchTalentsUserGridCaption = ({}) => {
	const className = 'SearchTalentsUserGridCaption';
	const theme = useTheme();

	const {
		selectedPage,
		SUGGESTED_TALENTS_PAGE, HIDDEN_TALENTS_PAGE,
	} = useContext(UserSavedSearchContext);

	return (
		<>
			{selectedPage === SUGGESTED_TALENTS_PAGE && (
				<Callout
					withFeedback={false}
					style={{ marginBottom: 12 }}
					icon={<Logo50inTech
						size={18}
						style={{
							marginTop: 1,
							marginBottom: -1,
						}}
					/>}
				>
					<b>
						List of profiles that 50inTech's Talent Team
						selected for you, based on your recruitment needs
					</b>
				</Callout>
			)}
			{selectedPage === HIDDEN_TALENTS_PAGE && (
				<Callout
					withFeedback={false}
					style={{ marginBottom: 12 }}
					icon={<FaEyeSlash style={{ marginBottom: -2 }}/>}
				>
					<b>
						Talents that you hid during your navigation
					</b>
				</Callout>
			)}
		</>
	);
};

export default SearchTalentsUserGridCaption;
