import moment from 'moment';
import { Button, Popover, Tooltip } from 'antd';
import UserModel from '../../../../models/UserModel';
import JSONPretty from 'react-json-pretty';
import { FaFilePdf } from 'react-icons/fa';
import UserAvatar from '../UserAvatar';

const UserResumePreviewer = (
	{
		user,
	},
) => {
	const latestInfo = <>
        Last upload <br />
		<b>{moment(user?.resume?.updatedAt).fromNow()}</b> on <b>{moment(user?.resume?.updatedAt).format('MMM Do YYYY')}</b>
		{user?.resume?.uploader && <>
			<br/>
            by
			<div style={{ display: 'inline-block' }}>
				<UserAvatar
					user={user?.resume?.uploader}
					size={24}
					withTooltip={true}
					withIsNew={false}
					withIsUnviewed={false}
					style={{ display: 'inline-block' }}/>
			</div>
		</>
		}</>;

	return (new UserModel(user)?.hasResume()
        && <Popover
        	content={<div style={{ overflow: 'scroll', maxWidth: 500, maxHeight: 300 }}>
        		<JSONPretty data={user.resume}/>
        	</div>}
        	trigger="click"
        >
        	<Tooltip title={latestInfo}>
        		<Button icon={<FaFilePdf style={{ marginBottom: -2 }}/>} type='primary'/>
        	</Tooltip>
        </Popover>
	);
};

export default UserResumePreviewer;
