/** @jsxImportSource @emotion/react */

import { Button, Space, Spin } from 'antd';
import { useEffect, useState } from 'react';
import { useTheme } from '@emotion/react';
import MessageTemplateCategoryTag from './MessageTemplateCategoryTag';
import { LocalDebug } from '../../../utils/LocalDebug';
import TemplateService from '../../../services/template';
import { useSelector } from 'react-redux';
import { getCompanySelected } from '../../../reducers/app';
import { FaPlusCircle } from 'react-icons/fa';
import { useAuthContext } from '../../../hooks/useAuthContext';
import MessageTemplateUserAvatar from './MessageTemplateUserAvatar';
import InlineNavLink from '../../app/button/InlineNavLink';
import {
	CONFIGURE_ICON, CONFIGURE_MENU, PATHS, TEMPLATE_MAIL_ICON, TEMPLATE_MAIL_MENU,
} from '../../../constants/constant';
import { documentId } from '../../../utils/common';
import ConfigureEmailTemplatesMenu from '../../../menu/configure/ConfigureEmailTemplatesMenu';
import ConfigureMenu from '../../../menu/configure/ConfigureMenu';

function MessageTemplateList(
	{
		masterTemplate,
		withBlankTemplateBtn = true,
		onTemplateSelected,
		...props
	},
) {
	const className = 'MessageTemplateList';

	const theme = useTheme();
	const companySelected = useSelector(getCompanySelected);
	const { currentUserModel } = useAuthContext();

	const blankTemplate = {
		_id: 'custom',
		name: <span style={{ fontSize: 14, fontWeight: 'bold' }}>
			<FaPlusCircle style={{ marginBottom: -2 }}/> New empty template
		</span>,
	};
	const blankTemplateSection = { data: [blankTemplate] };

	const [isLoading, setIsLoading] = useState(true);
	const [templates, setTemplates] = useState([]);
	const [selectedTemplate, setSelectedTemplate] = useState(masterTemplate);

	const fetchTemplates = async () => {
		const method = 'fetchTemplates';
		LocalDebug.logInfo({ className, method });
		const { data: { templates: masterTemplates } } = await TemplateService.findAll({ isMasterTemplate: true });
		let { data: { templates: companyTemplates } } = await TemplateService.findAll({
			companyId: documentId(companySelected), isMasterTemplate: false,
		});
		LocalDebug.logInfo({ className, method }, {
			masterTemplates, companyTemplates,
		});
		LocalDebug.logInfo({ className, method }, {
			masterTemplates: masterTemplates?.length, companyTemplates: companyTemplates?.length,
		});
		const userTemplates = companyTemplates.filter((t) => currentUserModel.isMe(t.user));
		companyTemplates = companyTemplates.filter((t) => !currentUserModel.isMe(t.user));

		setTemplates([
			...withBlankTemplateBtn ? [blankTemplateSection] : [],
			{ section: '50inTech templates', data: masterTemplates },
			{ section: 'Your personal templates', data: userTemplates },
			{ section: 'Your company templates', data: companyTemplates },
		]);
		setIsLoading(false);
	};

	const handleSelectTemplate = (value) => {
		LocalDebug.logInfo({ className, method: 'handleSelectTemplate' }, value);
		setSelectedTemplate(value);
		onTemplateSelected({});
		if (documentId(value) === 'custom') {
			onTemplateSelected({});
		} else if (documentId(value)) {
			const {
				_id: masterTemplateId, name, category, subject, content,
			} = value;
			onTemplateSelected({
				masterTemplateId, name, category, subject, content,
			});
		}
	};

	useEffect(async () => {
		if (selectedTemplate) handleSelectTemplate(selectedTemplate);
		await fetchTemplates();
	}, []);

	if (isLoading) {
		return (
			<div style={{
				display: 'flex',
				flexDirection: 'column',
				height: '100%',
				// minHeight: '60vh',
				justifyContent: 'center',
				alignItems: 'center',
				position: 'relative',
				overflow: 'hidden',
			}}>
				<Spin size='large'/>
			</div>
		);
	}

	if (templates.length === 0) {
		return (
			<div style={{
				fontSize: 14,
				fontWeight: 600,
				lineHeight: 1.3,
				color: '#666',
				marginTop: 8,
				marginBottom: 12,
			}}>
				Create your first email template in the
				{(new ConfigureEmailTemplatesMenu()).getInlineNavLink({ parentMenus: new ConfigureMenu() })}
				section.
			</div>
		);
	}

	return (
		<div
			className='message-templates-list'
			style={{
				position: 'relative',
				width: '100%',
				height: 500,
				overflow: 'hidden',
				borderRadius: 5,
				...props?.style,
			}}
		>
			<div
				style={{
					position: 'relative',
					width: '100%',
					height: '100%',
					overflow: 'auto',
					marginBottom: 20,
				}}
			>
				{templates
					.filter((temp) => temp.data.length > 0)
					.map((template) => (
						<div
							key={template.section}
						>
							{template.section
									&& <div
										style={{
											width: '100%',
											padding: '10px 20px',
											background: theme.color.bgGrey,
											fontSize: 14,
											fontWeight: 'bold',
											color: theme.color.darkgrey,
											// borderTop: '1px solid #ddd',
											borderBottom: '1px solid #ddd',
											// marginBottom: -1,
										}}
									>
										{template.section}
									</div>
							}
							{template.data.map((value) => (
								documentId(value) === 'custom'
									? (
										<Button
											key={documentId(value)}
											type='primary'
											ghost
											disabled={(
												documentId(selectedTemplate)?.toString?.()
												=== documentId(value)?.toString?.()
											)}
											onClick={() => handleSelectTemplate(value)}
											style={{
												width: 'calc(100% - 16px)',
												padding: '10px 20px',
												height: 'auto',
												textAlign: 'left',
												whiteSpace: 'normal',
												margin: 8,
											}}
										>
											{value.name}
										</Button>
									)
									: <Button
										key={documentId(value)}
										type='default'
										disabled={
											documentId(selectedTemplate)?.toString?.()
											=== documentId(value)?.toString?.()}
										onClick={() => handleSelectTemplate(value)}
										style={{
											borderRadius: 0,
											width: '100%',
											padding: '10px 20px',
											height: 'auto',
											textAlign: 'left',
											whiteSpace: 'normal',
										}}
									>
										<Space
											direction='horizontal'
											size={8}
											style={{
												lineHeight: 1.3,
											}}
										>
											<MessageTemplateUserAvatar template={value}/>
											<Space direction='vertical' size={2} style={{}}>
												<MessageTemplateCategoryTag
													category={value.category}
													// withTagStyle={false}
													withIcon={false}/>
												<span
													style={{ fontSize: 12, fontWeight: 'bold' }}>{value.name}</span>
											</Space>
										</Space>
									</Button>
							))}
						</div>
					))}
			</div>
		</div>
	);
}

export default MessageTemplateList;
