import { useTheme } from '@emotion/react';
import { Button, Divider, Form } from 'antd';
import { useEffect, useState } from 'react';
import { FaCheck } from 'react-icons/fa';
import FormItem from '../../company/form/FormItem';
import { trim } from 'lodash';
import { requiredTrimRule } from '../../../utils/common';
import FormSubmitButton from '../../app/form/FormSubmitButton';

const UserInvitedActivationForm = (
	{
		initialValues = {},
		onSubmit,
	},
) => {
	const className = 'UserInvitedActivationForm';

	const [form] = Form.useForm();
	const theme = useTheme();

	const [refresh, setRefresh] = useState(0);
	const [disabled, setDisabled] = useState(true);

	const onValuesChange = (values) => {
		setTimeout(() => {
			setRefresh((p) => p + 1);
		}, 0);
	};

	useEffect(() => {
		form.resetFields();
		form.setFieldsValue(initialValues);
	}, [form, initialValues]);

	useEffect(() => {
		const hasFormError = form.getFieldsError().some(({ errors }) => errors.length);
		setDisabled(hasFormError);
	}, [form, refresh]);

	return (
		<Form
			form={form}
			name="user_invite_pending"
			initialValues={{ ...initialValues }}
			onFinish={onSubmit}
			layout='horizontal'
			labelCol={{ span: 6 }}
			wrapperCol={{ span: 18 }}
			labelAlign='right'
			onValuesChange={onValuesChange}
		>
			<FormItem
				name="firstName"
				label="First Name"
				defaultContainer="div"
				rules={[requiredTrimRule]}
			/>
			<FormItem
				name="lastName"
				label="Last Name"
				defaultContainer="div"
				rules={[requiredTrimRule]}
			/>
			<FormItem
				name="information.position"
				label="Position"
				defaultContainer="div"
				rules={[requiredTrimRule]}
			/>
			<FormItem
				name="information.company"
				label="Company"
				defaultContainer="div"
				rules={[requiredTrimRule]}
			/>

			<FormItem label={<>&nbsp;</>} style={{ alignText: 'end', marginBottom: 6 }}>
				<FormSubmitButton disabled={disabled} size={'large'} style={{ alignText: 'end' }}>
					<b>Save my info</b>
				</FormSubmitButton>
			</FormItem>
		</Form>
	);
};

export default UserInvitedActivationForm;
