import React, { useState } from 'react';

import config from '../config/config';
import { FaSleigh } from 'react-icons/fa';

export const StaffToolsContext = React.createContext();

const StaffToolsProvider = ({
	children,
}) => {
	const className = 'StaffToolsProvider';

	const [panelContent, setPanelContent] = useState();

	const [staffManagerPanelPlacementLeft, setStaffManagerPanelPlacementLeft] = useState(true);
	const [staffManagerPanelOpen, setStaffManagerPanelOpen] = useState(false);

	const [isSavedSearchTabsEnabled, setIsSavedSearchTabsEnabled] = useState(false);
	const [isSavedSearchExcludeViewedEnabled, setIsSavedSearchExcludeViewedEnabled] = useState(true);
	const [savedSearchWithJobEnabled, setSavedSearchWithJobEnabled] = useState(false);
	const [isUserGridCardSuggestedJobsInline, setIsUserGridCardSuggestedJobsInline] = useState(true);

	const [userPageToggleCardView, setUserPageToggleCardView] = useState(true);
	const [userPageToggleForceNotContactedYet, setUserPageToggleForceNotContactedYet] = useState(FaSleigh);

	return (
		<StaffToolsContext.Provider
			value={{
				staffManagerPanelPlacementLeft,
				setStaffManagerPanelPlacementLeft,
				staffManagerPanelOpen,
				setStaffManagerPanelOpen,

				isSavedSearchTabsEnabled,
				setIsSavedSearchTabsEnabled,
				isSavedSearchExcludeViewedEnabled,
				setIsSavedSearchExcludeViewedEnabled,
				savedSearchWithJobEnabled,
				setSavedSearchWithJobEnabled,

				isUserGridCardSuggestedJobsInline,
				setIsUserGridCardSuggestedJobsInline,

				userPageToggleCardView,
				setUserPageToggleCardView,
				userPageToggleForceNotContactedYet,
				setUserPageToggleForceNotContactedYet,

				panelContent,
				setPanelContent,
			}}
		>
			{children}
		</StaffToolsContext.Provider>
	);
};

export default StaffToolsProvider;
