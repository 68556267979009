import { useTheme } from '@emotion/react';
import { useState } from 'react';
import UserStub from '../user/stub/UserStub';
import FormItemState from '../app/form/FormItemState';
import Box from '../app/box/Box';

const SettingsBrowsingUserStubPreview = (
	{
		showAvatar = true,
		showName = true,
		style = {},
	},
) => {
	const className = 'SettingsBrowsingUserStubPreview';

	const theme = useTheme();
	const [fakeAvatar, setFakeAvatar] = useState(
		`https://res.cloudinary.com/dkatmtbbc/image/upload/v1675065655/app/avatars/fake/fake${Math.ceil(8 * Math.random())}.jpg`,
	);

	return (
		<FormItemState
			initialStateText='Preview'
			changedStateText='Preview'
			style={{ ...style }}
		>
			<Box style={{ background: theme.color.white }}>
				<UserStub
					avatarForceHide={!showAvatar}
					avatarForceShow={showAvatar}
					nameForceHide={!showName}
					nameForceShow={showName}
					withIsUnviewed={false}
					withTooltip={false}
					withDropdown={false}
					user={{
						firstName: 'Léa',
						lastName: 'Smith',
						information: {
							avatar: fakeAvatar,
							position: 'Job title',
							company: 'Company',
						},
					}}
				/>
			</Box>
		</FormItemState>
	);
};

export default SettingsBrowsingUserStubPreview;
