import BlockStaff from '../../app/blocker/BlockStaff';
import CompanySettingsCard from './CompanySettingsCard';
import CompanySettingsSEOForm from './CompanySettingsSEOForm';
import { ENGLISH_LANG, FRENCH_LANG } from '../../../constants/constant';
import CompanyModel from '../../../models/CompanyModel';
import CompanyMetaStatus from '../../seo/CompanyMetaStatus';
import { SEO_DETAILS, SEO_TITLE } from './CompanySettingsTitles';

const CompanySettingsSEOCard = (
	{
		company,
		...props
	},
) => {
	const className = 'CompanySettingsSEOCard';
	const companyModel = new CompanyModel(company);
	const isFullyCustomizedEN = companyModel.areAllMetasCustomized(ENGLISH_LANG);
	const isFullyCustomizedFR = companyModel.areAllMetasCustomized(FRENCH_LANG);

	return (
		<div style={{ position: 'relative' }}>
			<BlockStaff withBorder={false} tagStyle={{ top: 2, left: 24, opacity: 1 }}>
				{/* Title and subtitle of the card */}
				<CompanySettingsCard
					title={SEO_TITLE}
					details={SEO_DETAILS}
					company={company}
					CompanySettingsForm={CompanySettingsSEOForm}
				>
					{/* Summary of the SEO status */}
					<>
						{/* Status for meta tags in English */}
						<CompanyMetaStatus company={company}
							isFullyCustomized={isFullyCustomizedEN}
							isEnVersion={true}
							isTranslatable={company.isTranslatable}
						/>
						{/* Status for meta tags in French, only if it's translatable */}
						{ company?.isTranslatable
						&& <CompanyMetaStatus company={company}
							isFullyCustomized={isFullyCustomizedFR}
							isEnVersion={false}
							isTranslatable={company.isTranslatable}
						/>
						}
					</>
				</CompanySettingsCard>
			</BlockStaff>
		</div>
	);
};

export default CompanySettingsSEOCard;
