import { useTheme } from '@emotion/react';
import { Button, Space, Tooltip } from 'antd';
import { BiRefresh } from 'react-icons/bi';
import SourcingModel from '../../../models/SourcingModel';
import { useState } from 'react';

const SourcingATSSyncSmallButton = (
	{
		sourcing,
		onSync,
		...props
	},
) => {
	const className = 'SourcingATSSyncSmallButton';
	const theme = useTheme();

	const sourcingModel = new SourcingModel(sourcing);

	const [isDisabled, setIsDisabled] = useState(false);

	// useEffect(() => {
	//     // LocalDebug.logUseEffect({ className, effects: 'sourcing' }, sourcingModel.lastSyncedDateFormatted, isDisabled, sourcingModel.isSyncDisabled);
	//     // setIsDisabled(sourcingModel.isSyncDisabled)
	// }, [sourcingModel]);

	if (!sourcingModel.isAtsLinked) {
		return null;
	}

	const handleSyncSourcing = async () => {
		setIsDisabled(true);
		await onSync?.(sourcingModel);
		setIsDisabled(false);
	};

	const tooltip = <>
		<div><b>Synchronize from ATS</b></div>
		<div style={{ fontSize: 10 }}>
            Last synced: {sourcingModel.lastSyncedDateFormatted}
		</div>
	</>;

	return (
		<Tooltip title={tooltip}>
			<Button
				icon={<BiRefresh style={{ marginBottom: -2 }} />}
				disabled={isDisabled || sourcingModel.isSyncDisabled}
				onClick={handleSyncSourcing}
			 />
		</Tooltip>
	// <Link onClick={() => { onSync(sourcing); }}>
	//     <FaSyncAlt style={{marginBottom: -2}}/> Sync now
	// </Link>
	);
};

export default SourcingATSSyncSmallButton;
