import { Space } from 'antd';
import PageLayout from '../../../components/app/layout/PageLayout';
import SectionTitle from '../../../components/app/SectionTitle';
import TableColumns from '../../../components/app/table/TableColumns';
import Table from '../../../components/app/table/Table';
import { useEffect, useState } from 'react';
import { request } from '../../../services/api';
import { LocalDebug } from '../../../utils/LocalDebug';
import { useTheme } from '@emotion/react';
import ReactMarkdown from 'react-markdown';
import { SecurityScanOutlined } from '@ant-design/icons';

const ImpactKPisPage = () => {
	const className = 'ImpactKPisPage';
	const theme = useTheme();

	const [data, setData] = useState(null);

	useEffect(() => {
		const applyEffect = async () => {
			try {
				const { data: { results } } = await request.get('/stats/impactKpis');
				LocalDebug.logUseEffect({ className, effects: '' }, { results });
				setData(results);
			} catch (e) {
				LocalDebug.logError({ className, error: e }, { e });
			}
		};
		applyEffect();
	}, []);

	const columns = [
		{
			title: 'ID',
			dataIndex: ['id'],
			align: 'center',
			...TableColumns.columnFixedWidth(50),
		},
		{
			title: 'Indicateur',
			dataIndex: ['title'],
			render: (value, row) => (
				<div style={{ lineHeight: 1.2, fontWeight: 'bold' }}>
					{value}
				</div>
			),
			...TableColumns.columnFixedWidth(200),
		},
		{
			title: <>Méthode de collecte<br />(par qui ? Comment ?<br />Quelle fréquence ?)</>,
			dataIndex: ['method'],
			render: (value, row) => (
				<div style={{ color: theme.color.grey, lineHeight: 1.2 }}>
					{value}
				</div>
			),
			...TableColumns.columnFixedWidth(150),
		},
		{
			title: <>Détail indicateur<br />(définition des termes,<br />formule de calcul, etc.)</>,
			dataIndex: ['details'],
			render: (value, row) => <div style={{ color: theme.color.grey, lineHeight: 1.2 }}>
				<ReactMarkdown>{value}</ReactMarkdown></div>,
			...TableColumns.columnFixedWidth(270),
		},
		...[2020, 2021, 2022, 2023, 2024].map((year) => ({
			title: <>{year}</>,
			dataIndex: ['data', `year${year}`],
			align: 'center',
			render: (value, row) => (
				<div
					style={{
						fontSize: 18,
						fontWeight: 'bold',
						lineHeight: 1.2,
					}}
				>
					{value}
				</div>
			),
			...TableColumns.columnFixedWidth(62),
		})),
	];

	return (
		<PageLayout
			withBackground={false}
			title='Impact KPIs'
			icon={<SecurityScanOutlined />}
		>
			{data && (
				<Table
					style={{ width: '100%' }}
					columns={columns}
					showSizeChanger={false}
					dataSource={data}
					limit={1000}
					withPagination={false}
				/>
			)}
		</PageLayout>
	);
};

export default ImpactKPisPage;
