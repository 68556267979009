import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { useTheme } from '@emotion/react';
import CompanyGenderScoreBadge from '../CompanyGenderScoreBadge';
import { Space } from 'antd';
import {
	getLevelFromScore,
	getLevelLabel,
	hasNextLevel,
} from '../../../../constants/genderscore-levels';
import GenderScoreLayoutGrid from '../layout/GenderScoreLayoutGrid';
import {
	scrollToTop, mixColors,
} from '../../../../utils/common';
import GenderScorePillarRankingBox from '../pillars/GenderScorePillarRankingBox';
import GenderScorePillarPerformanceBox from '../pillars/GenderScorePillarPerformanceBox';
import GenderScoreActionPlanSection from '../actions/GenderScoreActionPlanSection';
import GenderScoreSolutionsSection from './GenderScoreSolutionsSection';
import GenderScoreLayoutDivider from '../layout/GenderScoreLayoutDivider';
import GenderScorePillarBreakdownSection from './GenderScorePillarBreakdownSection';
import GenderScoreLayoutPageHeader from '../layout/GenderScoreLayoutPageHeader';
import { SOLUTION_ANNUAL_REVIEW_ID } from './GenderScoreSolutionBannerAnnualReview';
import GenderScoreSolutionCardAnnualReview from './GenderScoreSolutionCardAnnualReview';
import GenderScoreResultsLevelBox from './GenderScoreResultsLevelBox';
import GenderScoreResultsLevelNextBox from './GenderScoreResultsLevelNextBox';
import GenderScoreResultsLevelStatsBox from './GenderScoreResultsLevelStatsBox';
import GenderScoreResultsLastScoreBox from './GenderScoreResultsLastScoreBox';
import GenderScoreResultsPillarScoresBox from './GenderScoreResultsPillarScoresBox';
import CompanyModel from '../../../../models/CompanyModel';
import GenderScoreResultsExpirationBanners from './GenderScoreResultsExpirationBanners';
import CompanyActionLibrary from '../../actionPlan/CompanyActionLibrary';
import { useAuthContext } from '../../../../hooks/useAuthContext';

export const PILLAR_BREAKDOWN_ANCHOR_ID = 'pillar-breakdowns';
export const ACTIONS_ANCHOR_ID = 'actions';

const GenderScoreResults = ({
	company,
	forceValue,
}) => {
	const className = 'GenderScoreResults';

	const theme = useTheme();

	const location = useLocation();
	const { isStaffView } = useAuthContext();

	const genderScore = forceValue || company?.genderScore;

	const levelValue = getLevelFromScore(genderScore?.globalScore);
	const [previousSurvey] = new CompanyModel(company).getGenderScorePreviousSurveys() || [];

	useEffect(() => {
		scrollToTop();
	}, [location.pathname]);

	const onAddAction = (action) => {

	}
	return (
		<>
			<GenderScoreResultsExpirationBanners
				company={company}
			/>

			<GenderScoreLayoutPageHeader
				color={[
					mixColors(theme.color.white, theme.color.fitBlueElectric, 35),
					mixColors(theme.color.white, theme.color.fitBlueElectric, 15),
				]}
				height={250}
				style={{ marginBottom: -50 }}
			>

				<GenderScoreLayoutGrid
					columns={'210px repeat(3, 250px)'}
					marginBottom={0}
					style={{
						paddingBottom: 0,
						position: 'relative',
						zIndex: 2,
						border: 'none',
					}}
				>
					<div
						style={{
							display: 'flex',
							flexDirection: 'column',
							justifyContent: 'center',
							alignItems: 'center',
							marginBottom: 20,
							alignSelf: 'start',
							gap: 20,
						}}
					>
						<CompanyGenderScoreBadge
							company={{ ...company || {}, genderScore }}
							version='white'
							size={200}
							withPopover={false}
							withPreviousScore={true}
							withDownloader={true}
						/>

					</div>

					{previousSurvey
						? (
							<>
								<GenderScoreLayoutGrid
									columns={'1fr'}
									style={{
										alignSelf: 'start',
									}}
								>
									<GenderScorePillarRankingBox
										company={company}
									/>

									<GenderScorePillarPerformanceBox
										company={company}
										forceValue={forceValue}
									/>

								</GenderScoreLayoutGrid>

								<GenderScoreLayoutGrid
									columns={'1fr'}
									style={{
										alignSelf: 'start',
									}}
								>
									<GenderScoreResultsPillarScoresBox
										company={company}
										forceValue={forceValue}
									/>

									{/* <GenderScoreResultsLastScoreBox company={company} /> */}

								</GenderScoreLayoutGrid>

								<GenderScoreLayoutGrid
									columns={'1fr'}
									style={{
										alignSelf: 'start',
									}}
								>
									{/* <GenderScoreResultsPillarScoresBox
										company={company}
										forceValue={forceValue}
									/> */}

									<GenderScoreResultsLastScoreBox company={company} />

								</GenderScoreLayoutGrid>
							</>
						)
						: (
							<>
								<GenderScoreLayoutGrid
									columns={'1fr'}
									style={{
										alignSelf: 'start',
									}}
								>
									<GenderScorePillarRankingBox
										company={company}
									/>

									{/* <GenderScorePillarPerformanceBox
										company={company}
										forceValue={forceValue}
									/> */}

								</GenderScoreLayoutGrid>

								<GenderScoreLayoutGrid
									columns={'1fr'}
									style={{
										alignSelf: 'start',
									}}
								>
									{/* <GenderScorePillarRankingBox
										company={company}
									/> */}

									<GenderScorePillarPerformanceBox
										company={company}
										forceValue={forceValue}
									/>

								</GenderScoreLayoutGrid>

								<GenderScoreLayoutGrid
									style={{
										alignSelf: 'start',
									}}
								>

									<GenderScoreResultsPillarScoresBox
										company={company}
										forceValue={forceValue}
									/>

								</GenderScoreLayoutGrid>
							</>
						)
					}

					{/* <Space
						direction='vertical'
						style={{
							alignSelf: 'start',
						}}
					>
						<GenderScoreSolutionCardAnnualReview
							link={`${location.pathname}#${SOLUTION_ANNUAL_REVIEW_ID}`}
							element={`#${SOLUTION_ANNUAL_REVIEW_ID}`}
						/>

					</Space> */}

				</GenderScoreLayoutGrid>

			</GenderScoreLayoutPageHeader>
			<div
				id='body'
				style={{
					padding: 32,
				}}
			>
				<GenderScoreLayoutDivider
					title={<>Want to unlock your next level?</>}
					subtitle={<>Plan your next actions</>}
					id={ACTIONS_ANCHOR_ID}
					style={{ marginTop: 0 }}
				/>

				{isStaffView
					? (
						<CompanyActionLibrary
							actions={company?.actionPlan?.actions}
							onAddAction={onAddAction}
							viaModal={false}
							limit={3}
						/>
					)
					: (
						<GenderScoreActionPlanSection
							company={company}
							forceValue={forceValue}
							withActionSelectButton={true}
							style={{
								grid: {
									gridTemplateColumns: '1fr 1fr 1fr',
								},
								alignSelf: 'start',
							}}
						/>
					)
				}

				<GenderScoreLayoutDivider
					title={<>Your GenderScore Level</>}
					id={'your-level'}
				/>

				<GenderScoreLayoutGrid
					columns={
						hasNextLevel(levelValue)
							? '3fr 2fr 2fr'
							: '5fr 2fr'
					}
				>

					<GenderScoreResultsLevelBox company={company} forceValue={forceValue} />
					<GenderScoreResultsLevelNextBox company={company} forceValue={forceValue} />
					<GenderScoreResultsLevelStatsBox company={company} forceValue={forceValue} />

				</GenderScoreLayoutGrid>

				<GenderScoreLayoutDivider
					id={PILLAR_BREAKDOWN_ANCHOR_ID}
					title={<>Breakdown by Key Pillars</>}
					subtitle={<>
						Your GenderScore is the average of the scores
						you obtained for the 5 following pillars:
					</>}
					style={{ marginBottom: 32 }}
				/>

				<GenderScorePillarBreakdownSection
					company={company}
					forceValue={forceValue}
				/>

				{/* <GenderScoreSolutionsSection
					id={'solutions'}
					company={company}
					forceValue={forceValue}
				/> */}

			</div>
		</>
	);
};

export default GenderScoreResults;
