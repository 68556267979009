import { useSelector, useDispatch } from 'react-redux';

import { getDocumentReadersIds, getTagRules } from '../../../../reducers/app';
import TagRuleTable from '../../../../components/staff/tags/tagRules/TagRuleTable';
import { useEffect, useState } from 'react';
import { deleteTagRule, saveTagRule, showEditTagRule } from '../../../../actions/tagRules';
import PageLayout from '../../../../components/app/layout/PageLayout';
import { documentId, getQueryParams } from '../../../../utils/common';
import { useAuthContext } from '../../../../hooks/useAuthContext';

const TagRulesPage = () => {
	const dispatch = useDispatch();
	const { isStaff, isStaffView } = useAuthContext();
	const tagRules = useSelector(getTagRules);

	const documentReadersIds = useSelector(getDocumentReadersIds);

	// --------------------------------------------------------------------- //
	// ------------------- filter table ------------------------------------ //
	// --------------------------------------------------------------------- //
	const queryParams = getQueryParams();
	const [optionsFilterTable, setOptionsFilterTable] = useState(queryParams || {});
	const [isRefreshDataLayout, setIsRefreshDataLayout] = useState(0);

	// --------------------------------------- //
	// ------------ pubished a tag rule ------ //
	// --------------------------------------- //
	const handleChangePublished = (tagRule, statut) => {
		dispatch(saveTagRule({ id: documentId(tagRule), isPublished: statut }));
	};

	// ------------------------------------------- //
	// ------------ handle edit tag rule --------- //
	// ------------------------------------------- //
	const handleEditTagRule = (tagRule) => {
		showEditTagRule(documentId(tagRule));
	};

	// ------------------------------------------- //
	// ------------- delete tag rule ------------- //
	// ------------------------------------------- //
	const handleDeleteTagRule = async (id) => {
		await deleteTagRule(id);
		setIsRefreshDataLayout((prev) => prev + 1);
	};

	useEffect(() => {
		setOptionsFilterTable((prev) => ({ ...prev, documentReadersIds }));
	}, [documentReadersIds]);

	return (
		<PageLayout
			withBackground={false}
			title='Tag rules'
		>
			<TagRuleTable
				dataSource={tagRules}
				isEditor={isStaffView}
				onPublished={handleChangePublished}
				onEditTagRule={handleEditTagRule}
				onDelete={handleDeleteTagRule}
				filterOptions={optionsFilterTable}
				isRefresh={isRefreshDataLayout}
			/>
		</PageLayout>
	);
};

export default TagRulesPage;
