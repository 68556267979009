import StaffChatbotViewerPage from '../../../containers/staff/tech/StaffChatbotViewerPage';
import { PERMS_GRANT_STAFF } from '../../menu.utils';
import MenuBase from '../../MenuBase';
import { EyeOutlined } from '@ant-design/icons';

export const StaffChatbotViewerIcon = EyeOutlined;

class StaffChatbotViewerMenu extends MenuBase {
	static props = {
		name: 'staff-chatbot-viewer',
		path: '/staff/tech/chatbot-viewer',
		label: 'Chatbot Viewer',
		icon: <StaffChatbotViewerIcon />,
		iconLegacy: '',
		Component: StaffChatbotViewerPage,
		perms: PERMS_GRANT_STAFF,
	};

	constructor() {
		super(StaffChatbotViewerMenu.props);
	}
}

export default StaffChatbotViewerMenu;
