import { Button, Space } from 'antd';
import FormBoxTitle from '../../../app/form/FormBoxTitle';
import { useNavigate } from 'react-router';
import { GENDERSCORE_LABEL, PATHS } from '../../../../constants/constant';
import { useContext } from 'react';
import { CompanyPageContext } from '../shared/CompanyPageProvider';
import { scrollToTop } from '../../../../utils/common';
import CompanyModel from '../../../../models/CompanyModel';

const CompanyGenderScoreEditorLocked = (
	{
		company,
	},
) => {
	const className = 'CompanyGenderScoreEditorLocked';

	const navigate = useNavigate();
	const isGenderScoreFilled = new CompanyModel(company)?.isGenderScoreFilled() || false;
	const { setCurrentlyEditingStep } = useContext(CompanyPageContext);

	const onCancel = () => {
		setCurrentlyEditingStep(null);
	};

	const onOk = () => {
		setCurrentlyEditingStep(null);
		setTimeout(() => {
			navigate(isGenderScoreFilled
				? PATHS.GENDERSCORE_RESULTS
				: PATHS.GENDERSCORE_SURVEY());
			scrollToTop();
		}, 100);
	};

	return (
		<>
			<FormBoxTitle
				title={<>Go to your {GENDERSCORE_LABEL} {isGenderScoreFilled ? 'results' : 'survey'}?</>}
				details={<b>You'll navigate away from your company page.</b>}
			/>

			<Space style={{ width: '100%', justifyContent: 'space-between' }}>
				<Button
					size='large'
					onClick={onCancel}
				>
					No, stay on my company page
				</Button>
				<Button
					type='primary'
					size='large'
					onClick={onOk}
				>
					<b>Yes, take me to the {isGenderScoreFilled ? 'results' : 'survey'}</b>
				</Button>
			</Space>
		</>
	);
};

export default CompanyGenderScoreEditorLocked;
