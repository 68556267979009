import { useTheme } from '@emotion/react';
import { Radio, Button, Space } from 'antd';
import { useEffect, useMemo, useState } from 'react';
import { round } from '../../../utils/common';

const SurveyQuestionSingleChoice = ({
	question,
	value,
	onChange,
}) => {
	const className = 'SurveyQuestionSingleChoice';

	const theme = useTheme();

	const [columns, setColumns] = useState(question?.columns || 1);
	const [align, setAlign] = useState(question?.align || 'vertical');
	const [width, setWidth] = useState(question?.width || '100%');

	const memoizedContent = useMemo(() => {
		return question
			?.options
			?.map((option, index) => (
				<Button
					key={index}
					onClick={() => onChange(option.value)}
					size='large'
					{...value === option.value ? { type: 'primary' } : {}}
					style={{
						width,
						minWidth: 130,
						minHeight: 60,
						height: 'unset',
						textAlign: 'left',
					}}
				>
					<Radio
						checked={value === option.value}
						style={{
							verticalAlign: 'middle',
							fontSize: 16,
							border: '1px solid rgba(255,255,255,.2)',
							borderRadius: 100,
							padding: '0 5px',
							...value === option.value ? { color: theme.color.fitBlueElectric } : {},
						}}
					/>
					<span
						style={{
							verticalAlign: 'middle',
							whiteSpace: 'normal',
							lineHeight: 1.2,
							textAlign: 'left',
							width: 'calc(100% - 36px)',
						}}
					>
						{option.label?.fr || option.label?.en || option.label}
					</span>
				</Button>
			));
	}, [question, value, width, onChange, theme]);

	if (align === 'vertical') {
		return (
			<div
				style={{
					display: 'grid',
					width: '100%',
					gridTemplateColumns: `repeat(${columns}, ${round(100 / columns, 2)}%)`,
					rowGap: 10,
					columnGap: 10,
				}}
			>
				{memoizedContent}
			</div>
		);
	}

	return (
		<Space
			direction='horizontal'
			wrap={true}
		>
			{memoizedContent}
		</Space>
	);
};

export default SurveyQuestionSingleChoice;
