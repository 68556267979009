import {
	FaCalendarCheck,
	FaCheck, FaEnvelope, FaEye,
	FaEyeSlash,
	FaHourglass,
	FaPaperPlane,
	FaRandom,
	FaThumbsUp,
	FaTimes,
} from 'react-icons/fa';
import { TEMPLATES_MAP } from './mailjet/mailjetTemplates';

export const COACHING_STATUS_NOT_CONTACTED_VALUE = 'not-contacted';
export const COACHING_STATUS_USER_EXCLUDED_VALUE = 'user-excluded';
export const COACHING_STATUS_USER_DISPATCHED_VALUE = 'user-dispatched';
export const COACHING_STATUS_USER_CONTACTED_VALUE = 'user-contacted';
export const COACHING_STATUS_USER_CONTACTED_X2_VALUE = 'user-contacted-x2';
export const COACHING_STATUS_USER_CONTACTED_X3_VALUE = 'user-contacted-x3';
export const COACHING_STATUS_USER_DECLINED_VALUE = 'user-declined';
export const COACHING_STATUS_USER_ACCEPTED_VALUE = 'user-accepted';
export const COACHING_STATUS_USER_SCHEDULED_VALUE = 'user-scheduled';
export const COACHING_STATUS_USER_COACHED_VALUE = 'user-coached';
export const COACHING_STATUS_USER_READY_VALUE = 'user-ready';
export const COACHING_STATUS_USER_DISPLAYED_VALUE = 'user-displayed';
export const COACHING_STATUS_USER_SENT_VALUE = 'user-sent';

export const COACHING_STATUS_NOT_CONTACTED_ICON = FaHourglass;
export const COACHING_STATUS_USER_EXCLUDED_ICON = FaEyeSlash;
export const COACHING_STATUS_USER_DISPATCHED_ICON = FaRandom;
export const COACHING_STATUS_USER_CONTACTED_ICON = FaPaperPlane;
export const COACHING_STATUS_USER_CONTACTED_X2_ICON = FaPaperPlane;
export const COACHING_STATUS_USER_CONTACTED_X3_ICON = FaPaperPlane;
export const COACHING_STATUS_USER_DECLINED_ICON = FaTimes;
export const COACHING_STATUS_USER_ACCEPTED_ICON = FaThumbsUp;
export const COACHING_STATUS_USER_SCHEDULED_ICON = FaCalendarCheck;
export const COACHING_STATUS_USER_COACHED_ICON = FaCheck;
export const COACHING_STATUS_USER_READY_ICON = FaThumbsUp;
export const COACHING_STATUS_USER_DISPLAYED_ICON = FaEye;
export const COACHING_STATUS_USER_SENT_ICON = FaEnvelope;

export const COACHING_STATUS_NOT_CONTACTED_COLOR = '#bbbbbb';
export const COACHING_STATUS_USER_EXCLUDED_COLOR = '#000000';
export const COACHING_STATUS_USER_DISPATCHED_COLOR = '#ff9900';
export const COACHING_STATUS_USER_CONTACTED_COLOR = '#cccc00';
export const COACHING_STATUS_USER_CONTACTED_X2_COLOR = '#bbbb00';
export const COACHING_STATUS_USER_CONTACTED_X3_COLOR = '#aaaa00';
export const COACHING_STATUS_USER_DECLINED_COLOR = '#cc0000';
export const COACHING_STATUS_USER_ACCEPTED_COLOR = '#9999ff';
export const COACHING_STATUS_USER_SCHEDULED_COLOR = '#9999ff';
export const COACHING_STATUS_USER_COACHED_COLOR = '#99ff99';
export const COACHING_STATUS_USER_READY_COLOR = '#00cc00';
export const COACHING_STATUS_USER_DISPLAYED_COLOR = '#00cc00';
export const COACHING_STATUS_USER_SENT_COLOR = '#00cc00';

export const COACHING_COACH_OPTIONS = [
	{
		value: '63185b9a2bd73daa9555e5b4',
		label: 'Maisa Mattedi',
	},
	{
		value: '64f8223ab69ff6000b62d73b',
		label: 'Hélène Descamps',
	},
	{
		value: '63c48ee5cd81194ecbbb8f1b',
		label: 'Sonia Hernandez Quintanar',
	},
	{
		value: '62bafa16e018422fb6bcccb4',
		label: 'Jean Lerat',
	},
];

export const COACHING_STATUS_OPTIONS = [
	{
		label: 'Not contacted',
		icon: COACHING_STATUS_NOT_CONTACTED_ICON,
		color: COACHING_STATUS_NOT_CONTACTED_COLOR,
		value: COACHING_STATUS_NOT_CONTACTED_VALUE,
	},
	{
		label: 'Excluded',
		icon: COACHING_STATUS_USER_EXCLUDED_ICON,
		color: COACHING_STATUS_USER_EXCLUDED_COLOR,
		value: COACHING_STATUS_USER_EXCLUDED_VALUE,
	},
	{
		label: 'Dispatched',
		icon: COACHING_STATUS_USER_DISPATCHED_ICON,
		color: COACHING_STATUS_USER_DISPATCHED_COLOR,
		value: COACHING_STATUS_USER_DISPATCHED_VALUE,
	},
	{
		label: 'Contacted',
		icon: COACHING_STATUS_USER_CONTACTED_ICON,
		color: COACHING_STATUS_USER_CONTACTED_COLOR,
		value: COACHING_STATUS_USER_CONTACTED_VALUE,
	},
	{
		label: 'Contacted x2',
		icon: COACHING_STATUS_USER_CONTACTED_X2_ICON,
		color: COACHING_STATUS_USER_CONTACTED_X2_COLOR,
		value: COACHING_STATUS_USER_CONTACTED_X2_VALUE,
	},
	{
		label: 'Contacted x3',
		icon: COACHING_STATUS_USER_CONTACTED_X3_ICON,
		color: COACHING_STATUS_USER_CONTACTED_X3_COLOR,
		value: COACHING_STATUS_USER_CONTACTED_X3_VALUE,
	},
	{
		label: 'Refused',
		icon: COACHING_STATUS_USER_DECLINED_ICON,
		color: COACHING_STATUS_USER_DECLINED_COLOR,
		value: COACHING_STATUS_USER_DECLINED_VALUE,
	},
	{
		label: 'Accepted',
		icon: COACHING_STATUS_USER_ACCEPTED_ICON,
		color: COACHING_STATUS_USER_ACCEPTED_COLOR,
		value: COACHING_STATUS_USER_ACCEPTED_VALUE,
	},
	{
		label: 'Scheduled',
		icon: COACHING_STATUS_USER_SCHEDULED_ICON,
		color: COACHING_STATUS_USER_SCHEDULED_COLOR,
		value: COACHING_STATUS_USER_SCHEDULED_VALUE,
	},
	{
		label: 'Coaching done',
		icon: COACHING_STATUS_USER_COACHED_ICON,
		color: COACHING_STATUS_USER_COACHED_COLOR,
		value: COACHING_STATUS_USER_COACHED_VALUE,
	},
	{
		label: 'Ready for NL',
		icon: COACHING_STATUS_USER_READY_ICON,
		color: COACHING_STATUS_USER_READY_COLOR,
		value: COACHING_STATUS_USER_READY_VALUE,
	},
	{
		label: 'Visible',
		icon: COACHING_STATUS_USER_DISPLAYED_ICON,
		color: COACHING_STATUS_USER_DISPLAYED_COLOR,
		value: COACHING_STATUS_USER_DISPLAYED_VALUE,
	},
	{
		label: 'Sent in NL',
		icon: COACHING_STATUS_USER_SENT_ICON,
		color: COACHING_STATUS_USER_SENT_COLOR,
		value: COACHING_STATUS_USER_SENT_VALUE,
	},
];

export const COACHING_DONE_STATUS_LIST = [
	COACHING_STATUS_USER_COACHED_VALUE,
	COACHING_STATUS_USER_DISPLAYED_VALUE,
	COACHING_STATUS_USER_SENT_VALUE,
];

export const COACHING_MAILING_TEMPLATES = [
	{
		...TEMPLATES_MAP.COACHING_PROPOSAL_V2_FR_DORMANT,
		status: COACHING_STATUS_USER_CONTACTED_VALUE,
	},
	{
		...TEMPLATES_MAP.COACHING_PROPOSAL_V2_FR,
		status: COACHING_STATUS_USER_CONTACTED_VALUE,
	},
	{
		...TEMPLATES_MAP.COACHING_PROPOSAL_V2_EN,
		status: COACHING_STATUS_USER_CONTACTED_VALUE,
	},
	{
		...TEMPLATES_MAP.COACHING_PROPOSAL_V2_FOLLOWUP_FR,
		status: COACHING_STATUS_USER_CONTACTED_X2_VALUE,
	},
	{
		...TEMPLATES_MAP.COACHING_PROPOSAL_V2_FOLLOWUP_EN,
		status: COACHING_STATUS_USER_CONTACTED_X2_VALUE,
	},
	{
		...TEMPLATES_MAP.COACHING_PROPOSAL_V1_FR,
		status: COACHING_STATUS_USER_CONTACTED_VALUE,
	},
	{
		...TEMPLATES_MAP.COACHING_PROPOSAL_V1_FOLLOWUP_1_FR,
		status: COACHING_STATUS_USER_CONTACTED_X2_VALUE,
	},
	{
		...TEMPLATES_MAP.COACHING_PROPOSAL_V1_FOLLOWUP_2_FR,
		status: COACHING_STATUS_USER_CONTACTED_X3_VALUE,
	},
	{
		...TEMPLATES_MAP.COACHING_PROPOSAL_V1_EN,
		status: COACHING_STATUS_USER_CONTACTED_VALUE,
	},
	{
		...TEMPLATES_MAP.COACHING_PROPOSAL_V1_FOLLOWUP_1_EN,
		status: COACHING_STATUS_USER_CONTACTED_X2_VALUE,
	},
	{
		...TEMPLATES_MAP.COACHING_PROPOSAL_V1_FOLLOWUP_2_EN,
		status: COACHING_STATUS_USER_CONTACTED_X3_VALUE,
	},
	{
		...TEMPLATES_MAP.COACHING_CAREER_NEEDS_SENIOR_FR,
		status: COACHING_STATUS_USER_CONTACTED_VALUE,
	},
	{
		...TEMPLATES_MAP.COACHING_CAREER_NEEDS_SENIOR_FOLLOWUP_1_FR,
		status: COACHING_STATUS_USER_CONTACTED_X2_VALUE,
	},
	{
		...TEMPLATES_MAP.COACHING_CAREER_NEEDS_SENIOR_FOLLOWUP_2_FR,
		status: COACHING_STATUS_USER_CONTACTED_X3_VALUE,
	},
	{
		...TEMPLATES_MAP.COACHING_CAREER_NEEDS_SENIOR_EN,
		status: COACHING_STATUS_USER_CONTACTED_VALUE,
	},
	{
		...TEMPLATES_MAP.COACHING_CAREER_NEEDS_SENIOR_FOLLOWUP_1_EN,
		status: COACHING_STATUS_USER_CONTACTED_X2_VALUE,
	},
	{
		...TEMPLATES_MAP.COACHING_CAREER_NEEDS_SENIOR_FOLLOWUP_2_EN,
		status: COACHING_STATUS_USER_CONTACTED_X3_VALUE,
	},
	{
		...TEMPLATES_MAP.COACHING_NPS_FR,
		isNps: true,
	},
	{
		...TEMPLATES_MAP.COACHING_NPS_EN,
		isNps: true,
	},
	{
		...TEMPLATES_MAP.COACHING_NPS_GENDERLENS_FR,
		isNps: true,
	},
	{
		...TEMPLATES_MAP.COACHING_NPS_GENDERLENS_EN,
		isNps: true,
	},
];

const CoachingStatus = {
	COACHING_COACH_OPTIONS,
	COACHING_STATUS_OPTIONS,
	COACHING_MAILING_TEMPLATES,
};

export default CoachingStatus;
