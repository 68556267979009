import { RadarChartOutlined } from '@ant-design/icons';
import UMSAdminResultsPage from '../../containers/ums/UMSAdminResultsPage';
import MenuBase from '../MenuBase';
import { PERMS_UMS_ENABLED_ADMIN } from './ums-utils';

class UMSAdminResultsMenu extends MenuBase {
	name = 'ums-admin-results';

	label = 'Results';

	icon = <RadarChartOutlined />;

	iconLegacy = '📈';

	path = '/ums/results';

	aliases = ['^/ums/results$'];

	Component = UMSAdminResultsPage;

	perms = PERMS_UMS_ENABLED_ADMIN;
}

export default UMSAdminResultsMenu;
