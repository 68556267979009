import CompanySettingsFormBase from './CompanySettingsFormBase';
import { useState } from 'react';
import FormBoxTitle from '../../app/form/FormBoxTitle';
import FormItem from '../form/FormItem';
import { Alert, Form } from 'antd';
import FormSubmitButton from '../../app/form/FormSubmitButton';
import BlockStaff from '../../app/blocker/BlockStaff';
import Switch from '../../app/form/Switch';
import CompanyModel from '../../../models/CompanyModel';

/*
 * Form controlling the translation settings for a company
 * Should only be accessible by the staff
 * 2 switches:
 * - one to manage whether you can edit the translations (linked to isTranslatable field)
 * - one to manage whether the translation is publicly published (linked to isTranslationPublished field)
 * If the "edition" switch is set to false (= English only), the "publication" switch is automatically set to false as well, and disabled
 * */

const CompanySettingsTranslationForm = (
	{
		company,
		onSuccess,
		labelCol = { span: 0 },
		wrapperCol = { span: 24 },
		...props
	},
) => {
	const className = 'CompanySettingsTranslationForm';

	const [form] = Form.useForm();
	const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
	const [isTranslatable, setIsTranslatable] = useState(company?.isTranslatable);

	// TODO: Read the isGenderScorePlan value from the Context
	const companyModel = new CompanyModel(company);
	const isGenderScorePlan = companyModel?.isGenderScoreCompanyPlan();

	return (
		<BlockStaff
			withBorder={false}
			tagStyle={{
				top: 5,
				left: 0,
				opacity: 1,
			}}
		>
			<FormBoxTitle
				title={<>👅 Translations</>}
				details={<>
						Defines management of translations
				</>}
			/>

			<CompanySettingsFormBase
				form={form}
				formName='company-settings-translation-form'
				company={company}
				labelCol={labelCol}
				wrapperCol={wrapperCol}
				layout='horizontal'
				setIsSubmitDisabled={setIsSubmitDisabled}
				onSuccess={onSuccess}
				hiddenValues={{ forceRecompute: false }}
			>

				{isGenderScorePlan
					&& <Alert
						type='warning'
						showIcon
						message={'Warning'}
						description={<>
							<p style={{ marginBottom: '0' }}>This company is under the plan <b>{companyModel.getCompanyPlanLabel()}</b>.</p>
							<p style={{ marginTop: '0' }}>Enabling the translation for this type of companies is <br/><b>not recommended.</b></p>
						</> }
						style={{ paddingBottom: '5px', marginBottom: '20px' }}
					/>
				}
				<FormItem
					name='isTranslatable'
					valuePropName='checked'
				>
					<Switch
						className='ant-switch-large'
						before={<><b>{company.name}</b>'s company page is editable in</>}
						checkedChildren={<b>🇬🇧 English and 🇫🇷 French</b>}
						unCheckedChildren={<b>🇬🇧 English only</b>}
						onChange={(value) => {
							setIsTranslatable(value);
							/* If the "edition" switch is set to false (= English only),
								 * the "publication" switch is automatically set to false as well, and disabled
								 */
							if (!value) {
								form.setFieldsValue({ isTranslationPublished: false });
							}
						}}
					/>
				</FormItem>

				<FormItem
					name='isTranslationPublished'
					valuePropName='checked'
				>
					<Switch
						disabled={!isTranslatable}
						className='ant-switch-large'
						before={<><b>{company.name}</b>'s company page is published in</>}
						checkedChildren={<b>🇬🇧 English and 🇫🇷 French</b>}
						unCheckedChildren={<b>🇬🇧 English only</b>}
					/>
				</FormItem>

				<FormItem>
					<FormSubmitButton disabled={isSubmitDisabled}>
							Save translation settings
					</FormSubmitButton>
				</FormItem>

			</CompanySettingsFormBase>
		</BlockStaff>
	);
};

export default CompanySettingsTranslationForm;
