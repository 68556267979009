/** @jsxImportSource @emotion/react */
import React, { useState } from 'react';
import { withTheme } from '@emotion/react';
import { FaRegTimesCircle, FaTimes } from 'react-icons/fa';
import { LocalDebug, localDebug } from '../../../utils/LocalDebug';
import { cssLinearGradient, hexToRgb, mixColors } from '../../../utils/common';

const Popin = withTheme((
	{
		open,
		theme,
		children,
		title,
		withoutClose = false,
		withoutBackdropClose = false,
		onClose,
		styles = {},
		contentClassName = '',
		classNames = '',
		variant = 'default',
	},
) => {
	const className = 'Popin';

	const [mouseDownFromBackdrop, setMouseDownFromBackdrop] = useState(false);

	const popoverStyle = {
		backdrop: {
			alignItems: 'center',
			justifyContent: 'center',
		},
		container: {
			alignSelf: 'center',
			overflow: 'scroll',
			flex: '0 1 600px',
		},
		title: {
			fontSize: '28px !important',
			color: theme.color.primary,
		},
	};

	const handleBackdropClick = (e) => {
		// LocalDebug.logInfo({ className, method: 'handleBackdropClick' });
		// if (!withoutClose && onClose) {
		//     onClose(e);
		// }
	};

	const handleBackdropClickCapture = (e) => {
		// LocalDebug.logInfo({ className, method: 'handleBackdropClickCapture' });
		// if (!withoutClose && onClose) {
		//     onClose(e);
		// }
	};

	const handleBackdropMouseDown = (e) => {
		setMouseDownFromBackdrop(true);
		// LocalDebug.logInfo({ className, method: 'handleBackdropMouseDown' });
	};

	const handleBackdropMouseUp = (e) => {
		// LocalDebug.logInfo({ className, method: 'handleBackdropMouseUp' }, {
		// 	mouseDownFromBackdrop, withoutBackdropClose
		// });
		if (mouseDownFromBackdrop && !withoutClose && onClose && !withoutBackdropClose) {
			onClose(e);
		}
	};

	const handleContentMouseDown = (e) => {
		setMouseDownFromBackdrop(false);
		e?.stopPropagation?.();
		// localDebug('handleContentMouseDown');
	};

	const handleContentMouseUp = (e) => {
		e?.stopPropagation?.();
		// localDebug('handleContentMouseUp', { mouseDownFromBackdrop });
	};

	const rgbPcts = [
		[`rgba(${hexToRgb(mixColors(theme.color.fitBlueElectric, theme.color.black, 90)).join(', ')}, 0.5)`, '0%'],
		[`rgba(${hexToRgb(mixColors(theme.color.fitBlueElectric, theme.color.black, 90)).join(', ')}, 0.3)`, '100%'],
	];
	const bgColor = cssLinearGradient({ angle: '180deg', rgbPcts });

	return (
		<>
			{open && (
				<div
					style={{
						width: '100%',
						height: '100%',
						position: 'fixed',
						zIndex: 100,
						left: 0,
						top: 0,
						// backgroundColor: 'rgba(0, 0, 0, 0.2)',
						background: bgColor,
						overflow: 'scroll',
						...variant === 'popover' ? popoverStyle.backdrop : {},
						...styles.backdrop,
						// transition: 'all 3s ease-out'
					}}
					className={`flexRow justifyEnd ${classNames}`}
					// onClick={handleBackdropClick}
					// onClickCapture={handleBackdropClickCapture}
					onMouseDown={handleBackdropMouseDown}
					onMouseUp={handleBackdropMouseUp}
					role="button"
					tabIndex={0}
				>
					<div
						style={{
							background: theme.background.white,
							borderRadius: 10,
							overflow: 'scroll',
							flex: '0 1 239px',
							...variant === 'popover' ? popoverStyle.container : {},
							...styles.container,
						}}
						className="stretchSelf flexStretch"
						onClick={(e) => e?.stopPropagation()}
						onMouseDown={handleContentMouseDown}
						onMouseUp={handleContentMouseUp}
						role="button"
						tabIndex={0}
					>
						{title && (
							<div
								style={{
									border: `1px solid ${theme.background.primary}`,
									padding: 24,
									fontWeight: 'bold',
									...variant === 'popover' ? popoverStyle.title : {},
									...styles.title,
									color: theme.color.blue,
								}}
								className="title24 flexRow alignCenter spaceBetween"
							>
								<>{title}</>

								{!withoutClose && <FaRegTimesCircle color={theme.color.grey} onClick={onClose}
									style={{ fontSize: 24, cursor: 'pointer' }}/>}
								{/* <CloseCircleOutlined onClick={onClose} style={{fontSize: 24, cursor: 'pointer'}} /> */}

							</div>

						)}

						{!title && !withoutClose
							&& (
								<div
									onClick={onClose}
									style={{
										display: 'flex',
										justifyContent: 'center',
										alignItems: 'center',
										width: 36,
										height: 36,
										// borderRadius: 36,
										background: 'transparent',
										zIndex: 10,
										cursor: 'pointer',
										position: 'absolute',
										top: 32,
										right: 20,
									}}
								>
									<div
										style={{
											display: 'flex',
											justifyContent: 'center',
											alignItems: 'center',
											width: 32,
											height: 32,
											borderRadius: 32,
											background: theme.color.accent,
										}}
									>
										<FaTimes
											color={theme.color.white}
											size={18}
										/>
									</div>
								</div>
							)
						}

						<div
							style={{ padding: 24, ...styles.content, flex: 1 }}
							className={`flex1 ${contentClassName}`}
						>
							{children}
						</div>
					</div>
				</div>
			)}
		</>
	);
});

export default Popin;
