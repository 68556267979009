import { useEffect, useState } from 'react';
import { ENGLISH_LANG } from '../../../../constants/constant';
import { useAuthContext } from '../../../../hooks/useAuthContext';
import DataFetcherProvider from '../../../app/data/DataFetcherProvider';
import Grid from '../../../app/grid/Grid';
import UserStub from '../../../user/stub/UserStub';
import CompanyFieldNotProvided from '../shared/CompanyFieldNotProvided';
import CompanyFieldNotProvidedLocked from '../shared/CompanyFieldNotProvidedLocked';
import { documentId } from '../../../../utils/common';

const CompanyTeamDisplay = (
	{
		company,
		lang = ENGLISH_LANG,
		isStaffLocked = false,
	},
) => {
	const className = 'CompanyTeamDisplay';

	const { isStaff, isStaffView } = useAuthContext();
	const [content, setContent] = useState(<CompanyFieldNotProvided />);

	const field = 'team';

	const [isRefresh, setIsRefresh] = useState(0);

	useEffect(() => {
		setIsRefresh((p) => p + 1);
	}, [company]);

	useEffect(() => {
		const value = company?.getTranslationFieldFallback?.(field, lang);

		if (value?.length > 0) {
			setContent(
				<DataFetcherProvider
					path={`company/${documentId(company)}/team`}
					isRefresh={isRefresh}
					limit={1000000}
				>
					<Grid
						columns={3}
						cardRender={(user) => <UserStub user={user} />}
					/>
				</DataFetcherProvider>,
			);
			return;
		}
		if (isStaffLocked && !(isStaff && isStaffView)) {
			setContent(
				<CompanyFieldNotProvidedLocked>
					This section will be automatically populated
					with the list of your recruiters registered to 50inTech
				</CompanyFieldNotProvidedLocked>,
			);
			return;
		}
		setContent(<CompanyFieldNotProvided />);
	}, [company, isStaffLocked, isStaff, isStaffView]);

	return (
		<>
			<h2>Recruitment team</h2>
			{content}
		</>
	);
};

export default CompanyTeamDisplay;
