import { onEnterBuilder } from '../../../actions/app';
import RecruiterActivityPage from '../../../containers/staff/data/RecruiterActivityPage';
import { PERMS_GRANT_STAFF } from '../../menu.utils';

const menu = {
	name: 'staff-data-recruiter-activity',
	path: '/staff/data/recruiter',
	label: 'Recruiter Activity',
	icon: <span>🥵</span>,
	component: RecruiterActivityPage,
	onEnter: onEnterBuilder({
		origin: RecruiterActivityPage.name,
		shouldGoToPreviousHistory: true,
	}),
	perms: PERMS_GRANT_STAFF,
};

export default menu;
