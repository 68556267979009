import { request } from './api';

const getCurrentUser = async () => {
	return request.get('/user/current');
};

const AuthService = {
	getCurrentUser,
};

export default AuthService;
