import { Divider } from 'antd';
import ArticleMetaForm from './ArticleMetaForm';
import ArticleStub from './ArticleStub';
import ModalContainerBase from '../../../../contexts/modals/ModalContainerBase';
import FormBoxTitle from '../../../app/form/FormBoxTitle';

const ArticleMetaPopover = ({
	title = 'Add Meta',
	open,
	initialValues,
	onSubmit,
	onReset,
}) => {
	return (
		<ModalContainerBase
			open={open}
			onCancel={onReset}
			width={600}
		>
			<FormBoxTitle
				title={title}
			/>

			<ArticleStub article={initialValues} />

			<Divider />

			<ArticleMetaForm
				onSubmit={onSubmit}
				onReset={onReset}
				initialValues={initialValues}
			/>

		</ModalContainerBase>
	);
};

export default ArticleMetaPopover;
