import {
	BOOLEAN_YES_ICON,
	NOT_PROVIDED_YET_LABEL,
	notProvidedStyle,
} from '../../../../constants/constant';
import {
	Button, Col, Row, Space, Tooltip, message,
} from 'antd';
import { BiEnvelope } from 'react-icons/bi';
import { useTheme } from '@emotion/react';
import { copyClipboard } from '../../../../utils/common';
import { useAuthContext } from '../../../../hooks/useAuthContext';
import { useContext } from 'react';
import { StaffToolsContext } from '../../../../contexts/StaffToolsProvider';
import Logo50inTech from '../../../app/Logo50inTech';
import { LOGO_50INTECH_FULL_BLUE_SVG, LOGO_50INTECH_FULL_WHITE_SVG } from '../../../../constants/logos';
import { FaEnvelope } from 'react-icons/fa';

const UserProfileEmailCardBody = (
	{
		user,
		notProvided = NOT_PROVIDED_YET_LABEL,
		size = 'medium',
		...props
	},
) => {
	const theme = useTheme();

	const { isStaff, isStaffView } = useAuthContext();

	const { userPageToggleForceNotContactedYet } = useContext(StaffToolsContext);

	if (!user?.email || userPageToggleForceNotContactedYet) {
		return (
			<Space style={{ rowGap: 2, columnGap: 2 }}>

				<span
					style={notProvidedStyle}
				>
					Email not visible. <br/>
					<FaEnvelope style={{ marginBottom: -2, marginLeft: 2 }}/> <b>Contact</b> the talent through <b>50inTech</b>	first!
				</span>

			</Space>
		);
	}

	return (
		<>
			<div>
				<Tooltip title={<>Click to copy</>}>
					<Button
						type='link'
						onClick={() => {
							copyClipboard(user.email);
							message.success('Email copied to clipboard');
						}}
						style={{
							maxWidth: '100%',
							padding: 0,
							wordBreak: 'break-all',
							whiteSpace: 'initial',
							textAlign: 'left',
							height: 'unset',
							lineHeight: 1.3,
							marginBottom: 6,
						}}
						icon={<BiEnvelope style={{ marginRight: 3, marginBottom: -3 }} />}
					>
						<span>
							{user.email}
						</span>
					</Button>
				</Tooltip>
			</div>
			{user?.auth?.emailVerified && (
				<div>{BOOLEAN_YES_ICON} <i>Email verified</i></div>
			)}
		</>
	);
};

export default UserProfileEmailCardBody;
