import { Space } from 'antd';
import UserGridCardListWithSeparator from './UserGridCardListWithSeparator';
import { useMemo } from 'react';

const UserGridCardValueList = (
	{
		items,
		placement = 'right',
		defaultContainer = 'span',
		withSeparator = true,
		itemStyle,
		...props
	},
) => {
	const className = 'UserGridCardValueList';

	const memoizedContent = useMemo(() => (
		<Space direction='vertical'>
			<UserGridCardListWithSeparator
				placement={placement}
				defaultContainer={defaultContainer}
				withSeparator={withSeparator}
				{...props}
			>
				{items?.map((label, index) => (
					<span key={index} style={itemStyle || {}}>
						{label}
					</span>
				))}
			</UserGridCardListWithSeparator>
		</Space>
	), [items, placement, defaultContainer, withSeparator, itemStyle]);

	return memoizedContent;
};

export default UserGridCardValueList;
