import { PERMS_UMS_MANAGER_SURVEY_FILLED } from './ums-utils';
import UMSManagerResultsPage from '../../containers/ums/UMSManagerResultsPage';
import MenuBase from '../MenuBase';
import { RadarChartOutlined } from '@ant-design/icons';

class UMSManagerResultsMenu extends MenuBase {
	static props = {
		name: 'ums-manager-results',
		path: '/ums/results/manager',
		aliases: ['^/ums/results/manager$'],
		label: 'Manager Results',
		icon: <RadarChartOutlined />,
		iconLegacy: '📈',
		Component: UMSManagerResultsPage,
		perms: PERMS_UMS_MANAGER_SURVEY_FILLED,
	};

	constructor() {
		super(UMSManagerResultsMenu.props);
	}
}

export default UMSManagerResultsMenu;
