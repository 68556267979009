import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import roles from '../config/roles';
import { doLogin, doLogout, loginSuccess } from '../actions/app';
import { getCompanies, getCurrentUser, getCompanySelected } from '../reducers/app';
import authFirebaseService from '../services/authFirebase';
import config from '../config/config';
import { LocalDebug } from '../utils/LocalDebug';
import UserModel from '../models/UserModel';
import { useTheme } from '@emotion/react';
import { isEmpty, isEqual } from 'lodash';
import {
	arrayWrap, booleanAndReducer, booleanOrReducer, documentId,
} from '../utils/common';
import CompanyModel from '../models/CompanyModel';
import { CompanyContext } from './CompanyProvider';
import { loadCompanies } from '../actions/company';
import LoadSpinner from '../components/app/LoadSpinner';
import { BUSINESS_PLAN_NO_PLAN_VALUE } from '../constants/property/business-plan';
import {
	PERM_DENY_TYPE,
	PERM_GRANT_TYPE,
	PERM_OVERRIDE_TYPE,
} from '../menu/menu.utils';

export const AuthContext = React.createContext();

const AuthProvider = ({
	children,
}) => {
	const className = 'AuthProvider';

	const theme = useTheme();
	const dispatch = useDispatch();
	const companies = useSelector(getCompanies);
	const companySelected = useSelector(getCompanySelected);
	const { planValue } = useContext(CompanyContext);

	const [loading, setLoading] = useState(true);
	const [hasPlanOrStaff, setHasPlanOrStaff] = useState(false);
	const [isAdmin, setIsAdmin] = useState();
	const [isStaff, setIsStaff] = useState();
	const [isStaffView, setIsStaffView] = useState();
	const [isStaffBackup, setIsStaffBackup] = useState();
	const [isTalent, setIsTalent] = useState();
	const [isTester, setIsTester] = useState();
	const [isDemo, setIsDemo] = useState();
	const [isCompanyRecruiter, setIsCompanyRecruiter] = useState();
	const [isDocumentAdmin, setIsDocumentAdmin] = useState();
	const [isDocumentEditor, setIsDocumentEditor] = useState();
	const [isDocumentReader, setIsDocumentReader] = useState();
	const [companyAdminDocIds, setCompanyAdminDocIds] = useState();
	const [companyRecruiterDocIds, setCompanyRecruiterDocIds] = useState();
	const [isAclEditor, setIsAclEditor] = useState();
	const [aclLabels, setAclLabels] = useState([]);
	const [isResumeEnabled, setIsResumeEnabled] = useState(false);
	const [isCompanySelected, setIsCompanySelected] = useState(false);
	const [companySelectedPlan, setCompanySelectedPlan] = useState(null);
	const [isAtsSyncable, setIsAtsSyncable] = useState(false);
	const [isGenderScoreEnabled, setIsGenderScoreEnabled] = useState(false);
	const [isGenderScoreSurveyEnabled, setIsGenderScoreSurveyEnabled] = useState(false);
	const [isGenderScoreFilled, setIsGenderScoreFilled] = useState(false);
	const [genderScore, setGenderScore] = useState({});
	const [isUMSEnabled, setIsUMSEnabled] = useState(false);
	const [isUMSSurveyEnabled, setIsUMSSurveyEnabled] = useState(false);
	const [isUMSManagerSurveyFilled, setIsUMSManagerSurveyFilled] = useState(false);
	const [isUMSEmployeeSurveyFilled, setIsUMSEmployeeSurveyFilled] = useState(false);
	const [isUMSAdmin, setIsUMSAdmin] = useState(false);
	const [isUMSManager, setIsUMSManager] = useState(false);
	const [isUMSEmployee, setIsUMSEmployee] = useState(false);
	const [ums, setUMS] = useState({});
	const [isMasterTemplatesEnabled, setIsMasterTemplatesEnabled] = useState(true);
	const [currentUserModel, setCurrentUserModel] = useState(null);
	const [permissions, setPermissions] = useState({});
	const [routePermissions, setRoutePermissions] = useState([]);
	const [isDocumentEditorOnly, setIsDocumentEditorOnly] = useState();
	const [profileSubtitle, setProfileSubtitle] = useState(<>Some information about you.</>);
	const [isCalendlyFieldVisible, setIsCalendlyFieldVisible] = useState(false);
	const [appDataReady, setAppDataReady] = useState(false);

	const [companyIdsUser, setCompanyIdsUser] = useState([]);

	const [token, setToken] = useState(null);

	const [isEditor, setIsEditor] = useState(false);

	const currentUser = useSelector(getCurrentUser);

	const login = (email, password) => {
		dispatch(doLogin({ email, password }));
	};

	const logout = () => {
		dispatch(doLogout());
	};
	const resetPassword = (email) => {
		return authFirebaseService.sendPasswordResetEmail(email);
	};
	const updatePassword = (pwd) => {
		return authFirebaseService.updatePassword(pwd);
	};

	const getToken = async () => {
		// force to refresh token when was expired
		const token = await authFirebaseService.getToken(true);
		setToken(token);
	};
	const hasRole = (key) => {
		return currentUser?.acls?.[key] || false;
	};

	const hasEditor = () => {
		const isEditor = hasRole('isStaff');
		const isDocumentEditorRole = hasRole('isDocumentEditor');
		const isCompanyRecruiter = hasRole('isCompanyRecruiter');
		return isEditor || isDocumentEditorRole || isCompanyRecruiter;
	};

	const hasAclEditor = () => {
		const isRoleEditor = hasRole('isStaff');
		const isRoleDocumentAdmin = hasRole('isDocumentAdmin');

		return isRoleEditor || isRoleDocumentAdmin;
	};

	const getUserCompanies = () => {
		return companies?.filter((c) => companyRecruiterDocIds.includes(documentId(c)));
	};

	const isMenuItemAllowed = (menuItem) => {
		if (!appDataReady || !(routePermissions?.length > 0)) return false;

		const {
			name, perms: menuPermissions,
		} = menuItem || {};

		if (isEmpty(menuPermissions)) return true;

		/* Is authorized if:
			  - There's a explicit indication: business plan + user role
			  - There's no list of roles for the business plan --> all roles are authorized in that plan
			- There's an indication of roles for ALL plans
		*/

		/* Is denied if:
			  - There's a explicit indication: business plan + user role
			- There's an indication of roles for ALL plans
		*/

		/* Permissions can be overriden if:
			  - There's a explicit indication: business plan + user role
			- There's an indication of roles for ALL plans
		*/

		let isGranted = true;
		let isDenied = false;
		let isOverride = false;

		const isMatchingItem = ({ permItem, name }) => {
			// let roleCheck = true;
			// const checks = [];
			const {
				plans,
				roles,
				atsSyncable,
				genderScoreFilled,
				genderScoreSurveyEnabled,
				genderScoreEnabled,
				umsManagerSurveyFilled,
				umsEmployeeSurveyFilled,
				umsSurveyEnabled,
				umsEnabled,
				// Extra boolean value (can be undefined)
				value,
			} = permItem;

			const planCheck = !planValue
				|| !(plans?.length > 0)
				|| plans.includes(planValue);

			const roleCheck = !(roles?.length > 0)
				|| roles.some((p) => {
					// LocalDebug.logInfo(
					// 	{ className, method: 'isMatchingItem' },
					// 	{ name, roles, routePermissions },
					// );
					return routePermissions.some((r) => isEqual(r, p));
				});

			const atsSyncableCheck = !([true, false].includes(atsSyncable))
				|| (atsSyncable === isAtsSyncable);

			const genderScoreEnabledCheck = !([true, false].includes(genderScoreEnabled))
				|| (genderScoreEnabled === isGenderScoreEnabled);

			const genderScoreSurveyEnabledCheck = !([true, false].includes(genderScoreSurveyEnabled))
				|| (genderScoreSurveyEnabled === isGenderScoreSurveyEnabled);

			const genderScoreFilledCheck = !([true, false].includes(genderScoreFilled))
				|| (genderScoreFilled === isGenderScoreFilled);

			const umsEnabledCheck = !([true, false].includes(umsEnabled))
				|| (umsEnabled === isUMSEnabled);

			const umsSurveyEnabledCheck = !([true, false].includes(umsSurveyEnabled))
				|| (umsSurveyEnabled === isUMSSurveyEnabled);

			const umsManagerSurveyFilledCheck = !([true, false].includes(umsManagerSurveyFilled))
				|| (umsManagerSurveyFilled === isUMSManagerSurveyFilled);

			const umsEmployeeSurveyFilledCheck = !([true, false].includes(umsEmployeeSurveyFilled))
				|| (umsEmployeeSurveyFilled === isUMSEmployeeSurveyFilled);

			const companySelectedCheck = !([true, false].includes(companySelected))
				|| (companySelected === isCompanySelected);

			const valueCheck = !([true, false].includes(value))
				|| value;

			const result = [
				planCheck,
				roleCheck,
				atsSyncableCheck,
				genderScoreEnabledCheck,
				genderScoreSurveyEnabledCheck,
				genderScoreFilledCheck,
				umsEnabledCheck,
				umsSurveyEnabledCheck,
				umsEmployeeSurveyFilledCheck,
				companySelectedCheck,
				valueCheck,
			].reduce(booleanAndReducer, true);

			LocalDebug.logNull(
				{ className, method: 'isMatchingItem' },
				{ name, result, valueCheck },
				{
					planCheck,
					roleCheck,
					atsSyncableCheck,
					genderScoreEnabledCheck,
					genderScoreSurveyEnabledCheck,
					genderScoreFilledCheck,
					umsEnabledCheck,
					umsSurveyEnabledCheck,
					umsManagerSurveyFilledCheck,
					umsEmployeeSurveyFilledCheck,
					companySelectedCheck,
					valueCheck,
				},
			);

			return result;
		};

		isGranted = arrayWrap(menuPermissions)
			.filter((item) => item?.type === PERM_GRANT_TYPE);

		isGranted = isGranted.length === 0
			? true
			: isGranted
				.map((permItem) => {
					const matchingItemResult = isMatchingItem({ permItem, name });
					LocalDebug.logNull({ className, method: 'isMenuItemAllowed' }, 'isGranted', {
						name, permItem, matchingItemResult,
					});
					if ([true, false].includes(matchingItemResult)) return matchingItemResult;
					return true;
				})
				.reduce(booleanOrReducer, false);

		isDenied = arrayWrap(menuPermissions)
			.filter((item) => item?.type === PERM_DENY_TYPE);

		isDenied = isDenied.length === 0
			? false
			: isDenied
				.map((permItem) => {
					const matchingItemResult = isMatchingItem({ permItem, name });
					LocalDebug.logNull({ className, method: 'isMenuItemAllowed' }, 'isDenied', {
						name, permItem, matchingItemResult,
					});
					if ([true, false].includes(matchingItemResult)) return matchingItemResult;
					return true;
				})
				.reduce(booleanOrReducer, false);

		isOverride = arrayWrap(menuPermissions)
			.filter((item) => item?.type === PERM_OVERRIDE_TYPE);

		isOverride = isOverride.length === 0
			? false
			: isOverride
				.map((permItem) => {
					const matchingItemResult = isMatchingItem({ permItem, name });
					LocalDebug.logNull({ className, method: 'isMenuItemAllowed' }, 'isOverride', {
						name, permItem, matchingItemResult,
					});
					if ([true, false].includes(matchingItemResult)) return matchingItemResult;
					return false;
				})
				.reduce(booleanOrReducer, false);

		LocalDebug.logNull(
			{ className, method: 'isMenuItemAllowed' },
			menuItem.name,
			menuPermissions,
			{ isGranted, isDenied, isOverride },
			{ result: !((!isGranted || isDenied) && !isOverride) },
		);

		if ((!isGranted || isDenied) && !isOverride) return false;

		return true;
	};

	// ------------------------------------------------------- //
	// ------------- set state on current user change -------- //
	// ------------------------------------------------------- //
	useEffect(() => {
		if (!currentUser) return;
		LocalDebug.logNull({ className, effects: 'currentUser' }, { currentUser, acls: currentUser?.acls });
		setCurrentUserModel(new UserModel(currentUser));
		// localDebug({ 'currentUser?.acls': currentUser?.acls });
		setIsAdmin(currentUser?.acls?.isAdmin || false);
		setIsStaff(currentUser?.acls?.isStaff || false);
		setIsStaffView(currentUser?.acls?.isStaff || false);
		setIsStaffBackup(currentUser?.acls?.isStaff || false);
		setIsTalent(currentUser?.acls?.isTalent || false);
		setIsTester(currentUser?.acls?.isTester || false);
		setIsDemo(currentUser?.acls?.isDemo || false);
		setIsDocumentAdmin(hasRole('isDocumentAdmin'));
		setIsDocumentEditor(hasRole('isDocumentEditor'));
		setIsDocumentReader(hasRole('isDocumentReader'));
		setIsCompanyRecruiter(hasRole('isCompanyRecruiter'));
		setCompanyAdminDocIds(currentUser?.acls?.documentAdminDocIds || []);
		setCompanyRecruiterDocIds(currentUser?.acls?.companyRecruiterDocIds || []);
		setCompanyIdsUser(currentUser?.acls?.docIdsReaderUser || []);
		setIsEditor(hasEditor());
		setIsAclEditor(hasAclEditor());
		setAclLabels(currentUser?.acls?.aclLabels);
		// setIsAtsSyncable(currentUser?.acls?.isAtsSyncable === true);
		// setIsGenderScoreEnabled(currentUser?.acls?.isGenderScoreEnabled === true);
		// setIsGenderScoreSurveyEnabled(currentUser?.acls?.isGenderScoreSurveyEnabled === true);
		// setIsGenderScoreFilled(currentUser?.acls?.isGenderScoreFilled === true);
		// setGenderScore(currentUser?.acls?.genderScore);
		// UMS
		setIsUMSAdmin(currentUser?.acls?.isUMSAdmin === true);
		setIsUMSManager(currentUser?.acls?.isUMSManager === true);
		setIsUMSEmployee(currentUser?.acls?.isUMSEmployee === true);

		// setIsUMSManagerSurveyFilled(currentUser?.acls?.isUMSManagerSurveyFilled === true);
		// setIsUMSEmployeeSurveyFilled(currentUser?.acls?.isUMSEmployeeSurveyFilled === true);
		// setIsUMSSurveyEnabled(currentUser?.acls?.isUMSManager === true || currentUser?.acls?.isUMSEmployee === true);
		// LocalDebug.logUseEffect({ className, effects: 'currentUser' }, { isUMSEmployee, setIsUMSEmployee: currentUser?.acls?.isUMSEmployee });
		// setUMS(currentUser?.acls?.ums);
		setIsResumeEnabled(currentUser?.acls?.isStaff || !config.isProdEnv || false);

		LocalDebug.logUseEffect({ className, effects: 'currentUser' }, currentUser);

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentUser]);

	useEffect(() => {
		authFirebaseService
			.getAuth()
			.onAuthStateChanged((user) => {
				dispatch(loginSuccess());
				dispatch(loadCompanies({
					defaultCompanyIdSelected: documentId(companySelected),
					origin: 'AuthProvider',
				}));
				setLoading(false);
				getToken();
			});
	}, [dispatch]);

	useEffect(() => {
		LocalDebug.logNull({ className, effects: 'isStaff, planValue' }, { isStaff, planValue });
		setHasPlanOrStaff(isStaff || planValue !== null);
	}, [isStaff, planValue]);

	useEffect(() => {
		if (appDataReady) return;
		const currentUserCheck = !!currentUser;
		const companyLengthCheck = companies?.length > 0;
		const isDocumentEditorOnlyBooleanDefinedCheck = [true, false].includes(isDocumentEditorOnly);

		const newValue = currentUserCheck
			&& companyLengthCheck
			&& isDocumentEditorOnlyBooleanDefinedCheck;

		if (newValue) {
			setAppDataReady(newValue);

			LocalDebug.logNull(
				{ className, effects: 'companies isDocumentEditorOnly' },
				{
					isDocumentEditorOnly,
					currentUserCheck,
					companyLengthCheck,
					isDocumentEditorOnlyBooleanDefinedCheck,
					newValue,
				},
			);
		}
	}, [companies, isDocumentEditorOnly, appDataReady, currentUser]);

	useEffect(() => {
		LocalDebug.logNull({ className, effects: 'is...' }, {
			isAdmin,
			isStaff,
			isStaffBackup,
			isTalent,
			isTester,
			isDemo,
			isEditor,
			isCompanyRecruiter,
			isDocumentAdmin,
			isDocumentEditor,
			isDocumentReader,
			isDocumentEditorOnly,
			isAtsSyncable,
			isGenderScoreEnabled,
			isGenderScoreSurveyEnabled,
			isGenderScoreFilled,
			isUMSEnabled,
			isUMSSurveyEnabled,
			isUMSManagerSurveyFilled,
			isUMSEmployeeSurveyFilled,
			isUMSAdmin,
			isUMSManager,
			isUMSEmployee,
			planValue,
		});
		LocalDebug.logNull({ className, effects: 'is... (ums)' }, {
			isUMSEnabled,
			isUMSSurveyEnabled,
			isUMSManagerSurveyFilled,
			isUMSEmployeeSurveyFilled,
			isUMSAdmin,
			isUMSManager,
			isUMSEmployee,
		});
		setPermissions([
			{ isAdmin },
			{ isStaff },
			{ isStaffBackup },
			{ isTalent },
			{ isTester },
			{ isDemo },
			{ isEditor },
			{ isCompanyRecruiter },
			{ isDocumentAdmin },
			{ isDocumentEditor },
			{ isDocumentReader },
			{ isDocumentEditorOnly },
			{ isAtsSyncable },
			{ isGenderScoreEnabled },
			{ isGenderScoreSurveyEnabled },
			{ isGenderScoreFilled },
			{ isUMSEnabled },
			{ isUMSSurveyEnabled },
			{ isUMSManagerSurveyFilled },
			{ isUMSEmployeeSurveyFilled },
			{ isUMSAdmin },
			{ isUMSManager },
			{ isUMSEmployee },
			{ planValue },
		]);
	}, [
		isAdmin,
		isStaff,
		isStaffBackup,
		isTalent,
		isTester,
		isDemo,
		isEditor,
		isCompanyRecruiter,
		isDocumentAdmin,
		isDocumentEditor,
		isDocumentReader,
		isDocumentEditorOnly,
		isAtsSyncable,
		isGenderScoreEnabled,
		isGenderScoreSurveyEnabled,
		isGenderScoreFilled,
		isUMSEnabled,
		isUMSSurveyEnabled,
		isUMSManagerSurveyFilled,
		isUMSEmployeeSurveyFilled,
		isUMSAdmin,
		isUMSManager,
		isUMSEmployee,
		planValue,
	]);

	useEffect(() => {
		setIsDocumentEditorOnly((() => {
			const hasNoRoleAboveDocumentEditor = ![
				isAdmin,
				isStaff,
				isTalent,
				isCompanyRecruiter,
				isDocumentAdmin,
			].reduce(booleanOrReducer, false);
			const result = isDocumentEditor && hasNoRoleAboveDocumentEditor;
			LocalDebug.logNull({ className, method: 'setIsDocumentEditorOnly' }, {
				isAdmin,
				isStaff,
				isTalent,
				isCompanyRecruiter,
				isDocumentAdmin,
				isDocumentEditor,
				hasNoRoleAboveDocumentEditor,
			}, { isDocumentEditorOnly: result });
			return result;
		})());
	}, [
		isAdmin,
		isStaff,
		isTalent,
		isEditor,
		isCompanyRecruiter,
		isDocumentAdmin,
		isDocumentEditor,
		isDocumentReader,
	]);

	useEffect(() => {
		if (!appDataReady) return;
		if (!(permissions?.length > 0)) return;
		if (isStaffView) {
			setRoutePermissions(permissions);
		} else {
			// Special case for staff's "client mode": we strip the staff permissions
			const staffRoles = [
				roles.ADMIN,
				roles.STAFF,
				roles.TAGGER,
				roles.TALENT,
				roles.DEMO,
				roles.EDITOR,
			];
			setRoutePermissions(permissions?.filter((p) => !staffRoles.some((r) => isEqual(p, r))));
		}
	}, [permissions, isStaffView, appDataReady]);

	useEffect(() => {
		if (!companySelected) {
			setIsCompanySelected(false);
			setCompanySelectedPlan(BUSINESS_PLAN_NO_PLAN_VALUE);
			setIsGenderScoreEnabled(true);
			setIsGenderScoreSurveyEnabled(false);
			return null;
		}
		const companyModel = new CompanyModel(companySelected);
		// LocalDebug.logUseEffect({ className, effects: 'companySelected' }, { companySelected });
		setIsAtsSyncable(!!companySelected?.access?.find((a) => a?.permissions?.includes('atsSyncable')));
		setIsCompanySelected(true);
		setCompanySelectedPlan(companyModel.getCompanyPlanValue());
		setIsGenderScoreEnabled(companyModel.isGenderScoreEnabled());
		setIsGenderScoreSurveyEnabled(companyModel.isGenderScoreSurveyEnabled());
		setIsGenderScoreFilled(companyModel.isGenderScoreFilled());
		setGenderScore(companySelected?.genderScore);
		setIsUMSEnabled(companyModel.isUMSEnabled());
		setIsUMSSurveyEnabled(companyModel.isUMSSurveyEnabled());
		setIsUMSManagerSurveyFilled(companyModel?.getUMSManagerSurvey?.({ user: currentUser })?.isComplete === true);
		setIsUMSEmployeeSurveyFilled(companyModel?.getUMSEmployeeSurvey?.({ user: currentUser })?.isComplete === true);
	}, [companySelected, currentUser]);

	useEffect(() => {
		const companyModel = new CompanyModel(companySelected);
		if (companyModel?.isGenderHireCompanyPlan()) {
			setProfileSubtitle(<>Information about you that a talent can see.</>);
			setIsCalendlyFieldVisible(true);
		} else {
			setProfileSubtitle(<>Some information about you.</>);
			setIsCalendlyFieldVisible(false);
		}
	}, [companySelected]);

	if (loading) return <LoadSpinner />;

	const value = {
		currentUser,
		currentUserModel,
		isAdmin,
		isStaff,
		isStaffView,
		isStaffBackup,
		setIsStaffView,
		setIsStaffBackup,
		isTalent,
		isTester,
		isEditor,
		isAclEditor,
		isCompanyRecruiter,
		companyAdminDocIds,
		companyRecruiterDocIds,
		isDocumentAdmin,
		isDocumentEditorOnly,
		companyIdsUser,
		isAtsSyncable,
		isCompanySelected,
		companySelectedPlan,
		isGenderScoreEnabled,
		isGenderScoreSurveyEnabled,
		isGenderScoreFilled,
		genderScore,
		isUMSEnabled,
		isUMSSurveyEnabled,
		isUMSManagerSurveyFilled,
		isUMSEmployeeSurveyFilled,
		isUMSAdmin,
		isUMSManager,
		isUMSEmployee,
		ums,
		login,
		logout,
		resetPassword,
		updatePassword,
		hasRole,
		hasEditor,
		aclLabels,
		token,
		getUserCompanies,
		isMasterTemplatesEnabled,
		permissions,
		routePermissions,
		isMenuItemAllowed,
		setIsDocumentEditorOnly,
		profileSubtitle,
		isCalendlyFieldVisible,
		hasPlanOrStaff,
		appDataReady,
	};

	return (
		<AuthContext.Provider value={value}>
			{children}
		</AuthContext.Provider>
	);
};

export default AuthProvider;
