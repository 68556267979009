import { Space } from 'antd';
import { useTheme } from '@emotion/react';
import UserResumeEducationEntryRow from '../../../card/resume/UserResumeEducationEntryRow';
import UserGridCardExpander from './UserGridCardExpander';
import React from 'react';

const UserGridCardResumeEducationList = (
	{
		items = [],
		withExpander = true,
		colSpans,
		...props
	},
) => {
	const className = 'UserGridCardResumeEducationList';

	const theme = useTheme();

	if (!(items?.length > 0)) return null;

	const WrapperComponent = withExpander ? UserGridCardExpander : React.Fragment;
	const wrapperComponentProps = withExpander
		? {
			containerPaddingSide: 0,
		} : {};

	return (
		<WrapperComponent
			{...wrapperComponentProps}
		>
			<Space
				direction='vertical'
				size={20}
				style={{
					width: '100%',
				}}
			>
				{items?.map((entry, i) => (
					<UserResumeEducationEntryRow
						key={i}
						entry={entry}
						{...colSpans
							? { colSpans }
							: {}
						}
						style={{
							degree: {
								fontSize: 14,
							},
							academy: {
								fontSize: 14,
							},
							dates: {
								fontSize: 12,
							},
							...props?.style,
						}}
					/>
				))}
			</Space>
		</WrapperComponent>);
};

export default UserGridCardResumeEducationList;
