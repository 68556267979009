import {
	Space,
} from 'antd';
import { useTheme } from '@emotion/react';
import UserContacter from '../../buttons/UserContacter';
import UserSaver from '../../buttons/UserSaver';
import UserAtsSender from '../../buttons/UserAtsSender';
import { useAuthContext } from '../../../../hooks/useAuthContext';
import UserSourcer from '../../buttons/UserSourcer';

const UserGridCardEngageButtons = (
	{
		user,
		onUserChanged,
		popoverPlacement = 'top',
		onShortlistClick,
		onMessageClick,
		onSourceClick,
		...props
	},
) => {
	const className = 'UserGridCardEngageButtons';
	const { isAtsSyncable, isStaff, isStaffView } = useAuthContext();
	const theme = useTheme();

	const contactButton = <UserContacter
		user={user}
		size='large'
		type='default'
		popoverPlacement={popoverPlacement === 'top' ? 'topRight' : popoverPlacement}
		title='Contact'
		onClick={onMessageClick}
		onComplete={onUserChanged}
	/>;

	const sendToButton = isAtsSyncable
		? <UserAtsSender
			user={user}
			size='large'
			type='default'
			popoverPlacement={popoverPlacement}
			withPrimaryStyle={false}
			title='Send to your ATS'
			onClick={onSourceClick}
			onComplete={onUserChanged}
		/>
		: <UserSourcer
			user={user}
			size='large'
			type='default'
			popoverPlacement={popoverPlacement}
			withPrimaryStyle={false}
			title='Add to your Talent Pool'
			onClick={onSourceClick}
			onComplete={onUserChanged}
		/>;

	const shortlistButton = isStaff && isStaffView && <UserSaver
		user={user}
		size='large'
		type='default'
		popoverPlacement={popoverPlacement}
		withPrimaryStyle={false}
		title='Shortlist'
		className='staff-shortlist'
		mode='suggested'
		onClick={onShortlistClick}
		onComplete={onUserChanged}
	/>;

	return (
		<Space direction='horizontal' gap={8} className='engage-buttons'>
			{shortlistButton}
			{sendToButton}
			{contactButton}
		</Space>
	);
};

export default UserGridCardEngageButtons;
