import moment from 'moment';
import {
	Button, Col, DatePicker, Form, Input, InputNumber, Row, Select, Space, Switch,
} from 'antd';
import { useTheme } from '@emotion/react';
import React, { useEffect, useState } from 'react';
import Link from '../../app/Link';
import { FormItemContainer } from '../../app/form/FormItem';
import FormItem from '../../company/form/FormItem';
import { useDispatch, useSelector } from 'react-redux';
import { getCompanies, getCompanySelected, getItems } from '../../../reducers/app';
import GroupItems from './GroupItems';
import FormCompanySelect from '../../app/form/FormCompanySelect';
import { useAuthContext } from '../../../hooks/useAuthContext';
import TextareaEditor from '../../company/form/TextareaEditor';
import { LocalDebug, localDebug } from '../../../utils/LocalDebug';
import ReactJsonAdmin from '../../app/debug/ReactJsonAdmin';
import { getAllJobItems } from '../../../actions/jobs';
import BlockStaff from '../../app/blocker/BlockStaff';
import CompanyStub from '../../company/CompanyStub';
import { FaCheck, FaExternalLinkAlt } from 'react-icons/fa';
import { openExternalUrl } from '../../../utils/common';
import CurrencySelect from './CurrencySelect';
import IntervalSelect from './IntervalSelect';

const SalaryRangeInput = (
	{
		value,
		onChange,
		...props
	},
) => {
	const className = 'JobSalaryInput';

	const theme = useTheme();

	const labelStyle = { opacity: 0.5, fontSize: '12px' };

	return (
		<Row style={{ width: '100%' }}>
			<Col span={6} style={{ paddingRight: 5 }}>

				<InputNumber
					value={value?.min}
					onChange={(v) => onChange({ ...value, min: v })}
					min={0}
					step={1}
					style={{ width: '100%' }}
					placeholder='Minimum'
				/>
			</Col>
			<Col span={6} style={{ paddingRight: 5 }}>

				<InputNumber
					value={value?.max}
					onChange={(v) => onChange({ ...value, max: v })}
					min={0}
					step={1}
					style={{ width: '100%' }}
					placeholder='Maximum'
				/>
			</Col>
			<Col span={6} style={{ paddingRight: 5 }}>

				<CurrencySelect
					value={value?.currency}
					onChange={(v) => onChange({ ...value, currency: v })}
					style={{ width: '100%' }}
					placeholder='Currency'
				/>
			</Col>
			<Col span={6}>

				<IntervalSelect
					value={value?.interval}
					onChange={(v) => onChange({ ...value, interval: v })}
					style={{ width: '100%' }}
					placeholder='Interval'
				/>
			</Col>
		</Row>
	);
};

export default SalaryRangeInput;
