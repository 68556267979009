import { useTheme } from '@emotion/react';
import { Space } from 'antd';
import GenderScoreTitleBlock from './GenderScoreTitleBlock';
import { ArrowRightIcon } from '../../../../../utils/common';
import {
	getLevelFromScore, getLevelRanges, getNextLevel, getNextLevelRanges, hasNextLevel,
} from '../../../../../constants/genderscore-levels';
import GenderScoreLevelLabel from '../../levels/GenderScoreLevelLabel';
import Link from '../../../../app/Link';
import PctBarGraph from '../graphs/PctBarGraph';
import { LocalDebug } from '../../../../../utils/LocalDebug';

/**
 * @param {number} score - Current GS score
 * @param {*} [graphColors] - Optional. Format: [from, to, rank].
 * If not set, use theme.chart.pctBar
* @param {number} [width] - Optional. Width of the chart. Default: 300
 * @param {number} [graphHeight] - Optional. Height of the graph. Default: 40
 * @param {number} [barHeight] - Optional. Height of the bar. Default: 10
 * @param {number} [fontSize] - Optional. Font size of the biggest label.
 * Default: 40. The rest of labels are resized proportionally to this value
 * @param {number} [innerMargin] - Optional. Distance between two main components. Default: 40
 * @returns
 */
const GenderScoreLevelNextBlock = ({
	score,
	width = 300,
	graphHeight = 40,
	barHeight = 10,
	innerMargin = 40,
	fontSize = 40,
	graphColors,
}) => {
	const className = 'GenderScoreLevelBlock';

	const theme = useTheme();
	const colors = graphColors || theme.chart.pctBar;
	const textFontSize = Math.floor(fontSize * 0.35);
	const labelFontSize = Math.floor(fontSize * 0.4);

	// Current level
	const currentLevel = getLevelFromScore(score);
	const [currentLevelMinScore, currentLevelMaxScore] = getLevelRanges(currentLevel);
	const pctInCurrentLevel = 100
		* (score - currentLevelMinScore) / (1 + currentLevelMaxScore - currentLevelMinScore);
	// Next level
	const nextLevelMinScore = getNextLevelRanges(currentLevel)?.[0] || (currentLevelMaxScore);
	const pointsToNext = Math.ceil(nextLevelMinScore - (score || 0)) || 0;
	const nextLevel = getNextLevel(currentLevel);

	LocalDebug.logInfo({ className }, {
		currentLevel,
		currentLevelMinScore,
		currentLevelMaxScore,
		pctInCurrentLevel,
		nextLevelMinScore,
		pointsToNext,
		nextLevel,
		width,
	});
	return <Space
		direction='vertical'
		style={{
			width: '100%',
		}}>
		<Space.Compact direction='vertical'>
			<div><span style={{
				fontSize,
				fontWeight: 700,
			}}>{pointsToNext}</span>
			<span style={{
				fontSize: fontSize * 0.8,
				fontWeight: 600,
			}}>pts</span></div>
			<span style={{
				fontSize: textFontSize,
			}}>to {hasNextLevel(currentLevel) ? 'next level' : 'best score'}</span>
			<PctBarGraph
				pct={pctInCurrentLevel || 0}
				width={width}
				height={graphHeight}
				colors={colors}
				fontSize={labelFontSize}
				barHeight={barHeight}
			/>
		</Space.Compact>
		{ hasNextLevel(currentLevel) && <>
			<GenderScoreTitleBlock
				title={'Next Level'}
				style={{
					marginTop: innerMargin,
					fontSize: labelFontSize,
				}}
			/>
			<GenderScoreLevelLabel
				level={nextLevel}
				style={{
					fontSize: textFontSize,
				}}
				withPill={true} withPopover={true} withUnderline={false}/>
			<Space.Compact direction='vertical'>
				<span style={{
					fontSize: textFontSize,
				}}>How to reach this level?</span>
				<Link to='/genderscore/action-plan' style={{
					fontSize: textFontSize,
				}}>
					{<>
					Details&nbsp;
						<ArrowRightIcon />
					</>}
				</Link>
			</Space.Compact>
		</>}
	</Space>;
};
export default GenderScoreLevelNextBlock;
