import { useContext, useEffect, useState } from 'react';
import { useTheme } from '@emotion/react';
import { UserModalContext } from './UserModalProvider';
import UserModalCard from '../../components/user/grid/card/UserModalCard';
import UserModalCardFooter from '../../components/user/grid/card/UserModalCardFooter';
import { LocalDebug } from '../../utils/LocalDebug';
import ModalContainerBase from './ModalContainerBase';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

const UserModalContainer = () => {
	const className = 'UserModalContainer';

	const theme = useTheme();

	const {
		open, setOpen, user, userModel, setUserModel,
	} = useContext(UserModalContext);

	useEffect(() => {
		if (!open) {
			setUserModel(null);
		}
	}, [open]);

	return (
		<ModalContainerBase
			open={open}
			setOpen={setOpen}
			width={1130}
			centered
			footer={[
				<div
					key={0}
					style={{
						width: 'calc(100% + 48px)',
						minWidth: 'calc(100% + 48px)',
						maxWidth: 'calc(100% + 48px)',
						marginTop: -24,
						marginBottom: -24,
						marginLeft: -24,
						boxShadow: '0 -10px 10px -5px rgba(0,0,0,.2)',
						background: 'white',
					}}
				>
					<div style={{
						width: 'calc(100% - 48px)',
					}}
					>
						<UserModalCardFooter
							user={userModel}
							onShortlistClick={() => setOpen(false)}
							onMessageClick={() => setOpen(false)}
							onSourceClick={() => setOpen(false)}
							style={{
								padding: 0,
							}}
						/>
					</div>
				</div>,
			]}
			style={{
				body: {
					margin: '-20px -24px 20px -24px',
					wdith: 'calc(100 % + 48px)',
					paddingRight: 24,
					maxHeight: 'calc(100vh - 2 * 30px - 78px)',
					overflow: 'scroll',
					position: 'static',
				},
			}}
		>
			{open && (
				<UserModalCard
					user={userModel || user}
					style={{
						borderRadius: 0,
						border: 'none',
						boxShadow: 'none',
					}}
					withSuggested={false}
				/>
			)}

		</ModalContainerBase>
	);
};

export default UserModalContainer;
