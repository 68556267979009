import {
	regular,
	PDF_PAGE_TEMPLATE_CONTENT,
	PDF_TEXT_STYLES,
	PDF_TYPE_DIVIDER,
	PDF_TYPE_TEXT,
	PDF_TYPE_TITLE,
} from '../base/PDFBuilder';

const lines = {
	en: [
		'The goal of this questionnaire was to assess the level of various biases among managers in the evaluation of their employees, and comparing it with the employees\' perception.',
		`This questionnaire is a first step to establish a starting point regarding the presence of certain biases in managers, their consequences, and to give you some tips to favour an inclusive and fair professional environment.
By providing you with a mapping of your biases and a comparison with your employees' perceptions, you gain a clearer and more precise vision of your management and the areas that need improvement.`,
		'Of course, this questionnaire is not a magic solution! Indeed:',
		'• It can be challenging to measure biases through self-reported items and responses. If the manager wants to delve deeper, additional in-depth tests would be necessary.',
		'• It\'s not possible to measure all possible biases, so it should not be ruled out that even if the manager has a low score, they may express other types of biases specific to certain situations (e.g., maternity).',
		'• Social desirability bias may be present, meaning participants may respond with socially acceptable answers rather than their true thoughts. However, the employee\'s perspective helps mitigate this through an external assessment.',
	],
	fr: [
		"L'objectif de ce questionnaire était d'évaluer le niveau de divers biais parmi les gestionnaires dans l'évaluation de leurs employés, et de le comparer à la perception des employés.",
		'Ce questionnaire est une première étape pour établir un point de départ concernant la présence de certains biais chez les gestionnaires, leurs conséquences, et pour vous donner quelques conseils pour favoriser un environnement professionnel inclusif et équitable. En vous fournissant une cartographie de vos biais et une comparaison avec les perceptions de vos employés, vous obtenez une vision plus claire et plus précise de votre gestion et des domaines nécessitant une amélioration.',
		"Bien sûr, ce questionnaire n'est pas une solution magique ! En effet :",
		'• Il peut être difficile de mesurer les biais à travers des éléments et des réponses auto-déclarés. Si le gestionnaire souhaite approfondir, des tests plus approfondis seraient nécessaires.',
		"• Il n'est pas possible de mesurer tous les biais possibles, donc il ne faut pas exclure que même si le gestionnaire obtient un faible score, il peut exprimer d'autres types de biais spécifiques à certaines situations (par exemple, la maternité).",
		"• Le biais de désirabilité sociale peut être présent, ce qui signifie que les participants peuvent répondre avec des réponses socialement acceptables plutôt qu'avec leurs véritables pensées. Cependant, la perspective de l'employé aide à atténuer cela grâce à une évaluation externe.",
	],
};

const PageKeyConsiderations = async ({
	doc,
	context,
}) => [
	{
		template: PDF_PAGE_TEMPLATE_CONTENT,
		title: {
			en: 'Key Considerations',
			fr: 'Considérations clés',
		},
		footer: true,
		pageCount: true,
		items: [
			...(lines?.[context?.appLang || 'en'] || [])
				?.map((line) => ({
					type: PDF_TYPE_TEXT,
					...regular(PDF_TEXT_STYLES.normal),
					text: line,
					marginBottom: 4,
				})) || [],
		],
	},
];

export default PageKeyConsiderations;
