import { useEffect, useState } from 'react';
import { useTheme } from '@emotion/react';
import { Space } from 'antd';
import { useAuthContext } from '../../../../hooks/useAuthContext';
import CompanyFieldNotProvided from '../shared/CompanyFieldNotProvided';
import CompanyGenderScoreBadge from '../../genderscore/CompanyGenderScoreBadge';
import { GENDERSCORE_LABEL } from '../../../../constants/constant';
import GenderScoreColorRing from '../../genderscore/GenderScoreColorRing';
import CompanyGenderScoreLogo from '../../genderscore/CompanyGenderScoreLogo';

const CompanyGenderScoreEditorOnlyDisplay = (
	{
		company,
		isStaffLocked = false,
	},
) => {
	const className = 'CompanyGenderScoreEditorOnlyDisplay';

	const theme = useTheme();

	const { isStaff, isStaffView } = useAuthContext();

	const field = 'genderScore.globalScore';

	const [content, setContent] = useState(<CompanyFieldNotProvided />);

	useEffect(() => {
		const value = company?.genderScore?.globalScore || 0;

		if (value) {
			setContent(
				<Space>
					<CompanyGenderScoreBadge
						company={company}
						// year={2023}
						version={'white'}
						size={150}
					/>
				</Space>,
			);
			return;
		}

		if (isStaffLocked && !(isStaff && isStaffView)) {
			setContent(
				<CompanyFieldNotProvided
					style={{
						width: 150,
						height: 200,
						borderRadius: '20px 20px 150px 150px',
					}}
				>
					<Space
						direction='vertical'
						size={12}
						style={{
							fontStyle: 'normal',
							position: 'relative',
							fontSize: 18,
						}}
					>
						<CompanyGenderScoreLogo size={110} />
						<div
							style={{
								position: 'relative',
							}}
						>
							<GenderScoreColorRing
								size={110}
								value={67}
								withBackgroundRing={true}
								ringColor={'#999'}
							/>
							<span
								style={{
									fontStyle: 'italic',
									borderRadius: 110,
									fontSize: 12,
									lineHeight: 1.3,
									width: 110,
									height: 110,
									display: 'flex',
									alignItems: 'center',
									padding: 20,
									position: 'absolute',
									top: 0,
									left: 0,
								}}
							>
							Waiting for your GenderScore
							</span>
						</div>
					</Space>
				</CompanyFieldNotProvided>,
			);
			return;
		}

		setContent(
			<CompanyFieldNotProvided
				style={{
					width: 150,
					height: 175,
					borderRadius: '20px 20px 150px 150px',
				}}
			>
				{GENDERSCORE_LABEL}
			</CompanyFieldNotProvided>,
		);
	}, [company, isStaffLocked, isStaff, isStaffView]);

	return content;
};

export default CompanyGenderScoreEditorOnlyDisplay;
