import { AutoComplete } from 'antd';
import FormItem from '../../company/form/FormItem';
import Tags from './Tags';

const GroupItems = (
	{
		label = '',
		name = '',
		options = [],
		defaultOptions = [],
		onClickTag,
		...otherProps
	},
) => {
	return (
		<>
			<FormItem
				label={label}
				name={name}
				defaultContainer="div"
				styles={{
					rootContainer: {
						display: 'flex',
						alignItems: 'flex-start',
						justifyContent: 'space-between',
						marginBottom: (defaultOptions?.length > 0 ? 5 : 20),
					},
					itemContainer: {
						marginBottom: 0,
					},
					container: {
						flex: 1,
					},
				}}
				{...otherProps}
			>
				<AutoComplete
					options={options}
					filterOption={(inputValue, option) => option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1}
					{...otherProps}
				/>

			</FormItem>
			{defaultOptions?.length > 0
				&& <Tags
					name={name}
					options={defaultOptions}
					onClick={onClickTag}
				/>
			}
		</>
	);
};

export default GroupItems;
