import { useTheme } from '@emotion/react';
import { Select, Space, Typography } from 'antd';
import { useState } from 'react';
import ArticleService from '../../../services/article';
import UserStub from '../../user/stub/UserStub';
import AutoComplete from './AutoComplete';
import { documentId } from '../../../utils/common';

const FormArticleSelect = (
	{
		notArticleIds = [],
		value,
		defaultOptions = [],
		...props
	},
) => {
	const theme = useTheme();

	const [options, setOptions] = useState(defaultOptions);

	const handleSearch = async (search) => {
		const { data: { articles } } = await ArticleService.findAll({
			search,
			notArticleIds,
			is50Content: true,
			sortField: 'createdAt',
		});

		if (articles) {
			setOptions(articles);
		}
	};

	return (
		<AutoComplete
			onSearch={handleSearch}
			onStartSearch={() => setOptions([])}
			debounceTimeout={500}
			{...props}
			value={value}
		>
			{options.map((a) => (<Select.Option key={documentId(a)} value={documentId(a)} label={a.title} style={{ borderBottom: '1px solid #eee' }}>
				<Space direction="horizontal">
					<Space direction="vertical" size={0}>
						<Typography style={{
							fontSize: 10, fontWeight: 700, color: theme.color.darkgrey, marginTop: 4,
						}}>
							{a?.topic?.toUpperCase()}
						</Typography>
						<Typography style={{
							whiteSpace: 'normal', width: '100%', marginBottom: 6, padding: 0, color: theme.color.blue, fontSize: 16, fontWeight: 700,
						}}>{a.title}</Typography>
						<UserStub user={a.user} avatarSize={28} withLocation={false} style={{ marginBottom: 6 }}/>
					</Space>
				</Space>
			</Select.Option>))}
		</AutoComplete>
	);
};

export default FormArticleSelect;
