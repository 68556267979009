import DEI_CRITERIA from '../../property/dei-criteria';
import { innerObjPropExtractor } from '../../property/property-extractor';
import { innerObjPropMapper } from '../../property/property-mapper';

const USER_PROPERTY_JOB_SEARCH_DEI_CRITERIA_KEY = 'job-search-dei-criteria';

const USER_PROPERTY_JOB_SEARCH_DEI_CRITERIA_MULTI = true;
const USER_PROPERTY_JOB_SEARCH_DEI_CRITERIA_FREE = false;
const USER_PROPERTY_JOB_SEARCH_DEI_CRITERIA_MAX = 4;

const USER_PROPERTY_JOB_SEARCH_DEI_CRITERIA_FIELD = 'jobSearch.deiCriteria';
const USER_PROPERTY_JOB_SEARCH_DEI_CRITERIA_MAPPER = innerObjPropMapper(USER_PROPERTY_JOB_SEARCH_DEI_CRITERIA_FIELD);
const USER_PROPERTY_JOB_SEARCH_DEI_CRITERIA_EXTRACTOR = innerObjPropExtractor(USER_PROPERTY_JOB_SEARCH_DEI_CRITERIA_FIELD);

export default {
	...DEI_CRITERIA,
	options: DEI_CRITERIA.options,
	key: USER_PROPERTY_JOB_SEARCH_DEI_CRITERIA_KEY,
	USER_PROPERTY_JOB_SEARCH_DEI_CRITERIA_KEY,
	free: USER_PROPERTY_JOB_SEARCH_DEI_CRITERIA_FREE,
	USER_PROPERTY_JOB_SEARCH_DEI_CRITERIA_FREE,
	multi: USER_PROPERTY_JOB_SEARCH_DEI_CRITERIA_MULTI,
	USER_PROPERTY_JOB_SEARCH_DEI_CRITERIA_MULTI,
	max: USER_PROPERTY_JOB_SEARCH_DEI_CRITERIA_MAX,
	USER_PROPERTY_JOB_SEARCH_DEI_CRITERIA_MAX,
	mapper: USER_PROPERTY_JOB_SEARCH_DEI_CRITERIA_MAPPER,
	USER_PROPERTY_JOB_SEARCH_DEI_CRITERIA_MAPPER,
	extractor: USER_PROPERTY_JOB_SEARCH_DEI_CRITERIA_EXTRACTOR,
	USER_PROPERTY_JOB_SEARCH_DEI_CRITERIA_EXTRACTOR,
	field: USER_PROPERTY_JOB_SEARCH_DEI_CRITERIA_FIELD,
	USER_PROPERTY_JOB_SEARCH_DEI_CRITERIA_FIELD,
};
