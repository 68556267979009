import PageLayout from '../../components/app/layout/PageLayout';
import SearchTalentsDataFetcherContainer from '../../components/search/SearchTalentsDataFetcherContainer';
import StaffManagerSearchTalentsTab from '../../components/staff/manager/tabs/StaffManagerSearchTalentsTab';
import { useContext, useEffect } from 'react';
import { StaffToolsContext } from '../../contexts/StaffToolsProvider';
import GenderhireSearchTalentsMenu from '../../menu/genderhire/GenderhireSearchTalentsMenu';

const SearchTalentsPage = () => {
	const className = 'SearchTalentsPage';

	const { setPanelContent } = useContext(StaffToolsContext);

	useEffect(() => {
		setPanelContent?.(<StaffManagerSearchTalentsTab />);
		return () => setPanelContent?.(null);
	}, [setPanelContent]);

	return (
		<PageLayout
			MenuClass={GenderhireSearchTalentsMenu}
			withHeaderDivider={false}
		>
			<SearchTalentsDataFetcherContainer
				withLoadMore={true}
				loadOnNoFilter={false}
			/>

		</PageLayout>
	);
};

export default SearchTalentsPage;
