import {
	Button, Popover,
} from 'antd';
import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTheme } from '@emotion/react';
import { SurveyContext } from './SurveyProvider';
import { buildSectionQuestionPath } from '../data/shared';
import Link from '../../app/Link';
import { ArrowRightIcon } from '../../../utils/common';
import { GENDERSCORE_LABEL } from '../../../constants/constant';
import {
	EVENT, buildEventSection, buildEventLabel, pushClick,
} from '../../../contexts/EventPushProvider';

const SurveyReviewButton = () => {
	const theme = useTheme();
	const navigate = useNavigate();

	const {
		eventContext,
		baseRoutePath,
		unansweredQuestions,
	} = useContext(SurveyContext);

	const eventProps = {
		...eventContext,
		...buildEventSection(EVENT.SECTION.SURVEY_PROGRESS_NAV),
	};

	if (unansweredQuestions?.length > 0) {
		return (
			<div
				style={{
					marginTop: 2,
				}}
			>
				<Popover
					placement='bottomLeft'
					content={(
						<>
							<b>Your survey is still incomplete.</b>
							<br />Click to go to the first unanswered question.
						</>
					)}
				>
					<Link
						className={'answer-later'}
						onClick={() => {
							pushClick({
								...eventProps,
								...buildEventLabel(EVENT.LABEL.SURVEY_NEXT_REQUIRED),
							});
						}}
						to={buildSectionQuestionPath({ baseRoutePath, ...unansweredQuestions?.[0] })}
						style={{
							whiteSpace: 'nowrap',
						}}
					>
						<b>Next required question&nbsp;<ArrowRightIcon /></b>
					</Link>
				</Popover>
			</div>
		);
	}

	return (
		<Popover
			placement='bottomLeft'
			content={(
				<>
					<b>Your survey is complete!</b>
					<br />Review your answers and submit them<br/>to obtain your <b>{GENDERSCORE_LABEL} results.</b>
				</>
			)}
		>
			<Button
				type='primary'
				onClick={() => {
					pushClick({
						...eventProps,
						...buildEventLabel(EVENT.LABEL.SURVEY_REVIEW),
					});
					navigate(`${baseRoutePath}/review`);
				}}
			>
				<b>Review & Submit</b>
			</Button>
		</Popover>

	);
};

export default SurveyReviewButton;
