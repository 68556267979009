import slugify from '@sindresorhus/slugify';
import {
	arrayWrap, mergeReducer, pct, round, shuffleArr, sumReducer,
} from '../../../utils/common';
import QUESTIONS, { INPUT_MULTIPLE, INPUT_NUMBER, INPUT_PCT } from './questions';
import { QUESTIONS_SUBPATH } from '../../../constants/constant';
import { isArray } from 'lodash';
import { LocalDebug } from '../../../utils/LocalDebug';

const className = 'ums/data/shared';

export const ANSWER_VALUE_SKIPPED = 'ANSWER_VALUE_SKIPPED';
export const ANSWER_VALUE_LATER = 'ANSWER_VALUE_LATER';
export const ANSWER_STATE_NOT_ANSWERED = 'ANSWER_STATE_NOT_ANSWERED';
export const ANSWER_STATE_LATER = 'ANSWER_STATE_LATER';
export const ANSWER_STATE_SKIPPED = 'ANSWER_STATE_SKIPPED';
export const ANSWER_STATE_ANSWERED = 'ANSWER_STATE_ANSWERED';

// export const PROGRESS_BAR_UNANSWERED_COLOR = '#9D9D9D';
export const PROGRESS_BAR_UNANSWERED_COLOR = 'rgba(0,0,0,.4)';
export const PROGRESS_BAR_ANSWER_SKIPPED_COLOR = '#a9ace6';
// export const PROGRESS_BAR_ANSWERED_COLOR = '#0F3DFB';
export const PROGRESS_BAR_ANSWERED_COLOR = '#FF635B';

export const SLUGS = new Map();

export const optionalizeQuestion = (question, ...args) => ({
	...question,
	optional: true,
	...args,
});

export const findQuestionSection = ({
	question,
	sections,
}) => sections?.find((s) => s.questions
	?.find((q) => q.questionId === question?.questionId));

export const getSectionQuestionsFromAnswers = ({
	section,
	answers,
}) => section?.questions
	?.filter((question) => !question?.clause || question?.clause?.(answers));

export const getSurveySectionsQuestionsFromAnswers = ({
	surveyConf,
	answers,
}) => surveyConf?.sections
	?.map((section) => ({
		...section,
		questions: getSectionQuestionsFromAnswers({ section, answers }),
	}));

export const walkSectionQuestionForAnswers = ({ sections, questionAnswerMapper }) => {
	return sections
		?.map((section, sIndex, sArr) => section?.questions
			?.map((question, qIndex, qArr) => (
				questionAnswerMapper?.({
					section, sIndex, sArr, question, qIndex, qArr,
				})
			)))
		.flat(3)
		.reduce(mergeReducer, {});
};

export const randomlyFakeQuestionAnswer = ({ question }) => {
	let value;
	LocalDebug.logInfo({ className: '', method: 'randomlyFakeQuestionAnswer' }, { question, hasOptions: !!question?.options, input: question?.input });
	if (question?.options) {
		if (question?.input === INPUT_MULTIPLE) {
			value = (
				shuffleArr(
					question?.options.slice(),
				)
					.slice(0, 1 + Math.floor(Math.random() * (question?.options?.length || 0)))
					.map((o) => o?.value)
			);
		} else {
			value = (
				shuffleArr(
					question?.options?.slice?.(),
				)
					?.map?.((o) => o?.value)
					?.[0]
			);
		}
	} else {
		value = (question?.min || 0)
			+ 1
			+ Math.round(Math.random() * ((question?.max || 0) - (question?.min || 0)));
	}
	return value;
};

export const getSurveyAnsweredAndUnansweredQuestions = ({ surveyConf, answers }) => {
	const answered = [];
	const unanswered = [];
	getSurveySectionsQuestionsFromAnswers({ surveyConf, answers })
		?.forEach((section) => {
			section.questions.forEach((question, index) => {
				const value = answers?.[question?.questionId];
				const state = getQuestionAnsweredState({ question, answers });
				const result = {
					section, question, value, state,
				};
				switch (state) {
					case ANSWER_STATE_ANSWERED:
						answered.push(result);
						break;
					case ANSWER_STATE_SKIPPED:
					case ANSWER_STATE_LATER:
						if (question.optional) {
							answered.push(result);
						} else {
							unanswered.push(result);
						}
						break;
					case ANSWER_STATE_NOT_ANSWERED:
					default:
						unanswered.push(result);
						break;
				}

				// if (![ANSWER_STATE_ANSWERED, ANSWER_STATE_SKIPPED].includes(state)) {
				// 	unanswered.push({ section, question });
				// } else {
				// 	answered.push({ section, question });
				// }
			});
		});
	return { answered, unanswered };
};

export const getOrCreateSubpathSlug = ({
	value,
	mapper = (slug) => slug,
}) => {
	if (!value) return '';
	if (!SLUGS.get(value)) {
		SLUGS.set(value, mapper(slugify(value)));
	}
	return SLUGS.get(value);
};

export const buildSectionSubpath = ({
	section,
}) => {
	if (!section) return null;

	if (typeof section === 'string') return section;

	return getOrCreateSubpathSlug({ value: section?.value || section?.label });
};

export const buildQuestionSubpath = ({
	question,
}) => {
	if (!question) return null;

	if (typeof question === 'string') return question;

	return getOrCreateSubpathSlug({
		value: question?.shortLabel || question?.questionId || question?.label || question?.value,
		mapper: (slug) => {
			let mappedSlug = slug;
			const prefixesToRemove = ['mgr', 'emp', 'sffp', 'cte', 'rec', 'hhe', 'igf', 'ble', 'ovc'];
			prefixesToRemove.forEach((p) => {
				if (mappedSlug.startsWith(`${p}-`)) mappedSlug = mappedSlug.slice(p.length + 1, mappedSlug.length);
			});
			return mappedSlug;
		},
	});
};

export const buildSectionQuestionPath = ({
	baseRoutePath,
	section,
	question,
}) => {
	const sectionSubpath = buildSectionSubpath({ section });
	const questionSubpath = buildQuestionSubpath({ question });

	const questionPath = [
		baseRoutePath,
		QUESTIONS_SUBPATH,
		...sectionSubpath ? [sectionSubpath] : [],
		...questionSubpath ? [questionSubpath] : [],
	]
		.filter((item) => item)
		.join('/');

	LocalDebug.logNull({ className, method: 'buildSectionQuestionPath' }, questionPath, {
		sectionSubpath, questionSubpath, section, question,
	});
	return questionPath;
};

export const isQuestionAnswered = ({
	question,
	answers,
}) => {
	const { questionId, input } = question;

	if (!answers || Object.keys(answers || {})?.length === 0
	) {
		return false;
	}

	const answer = answers?.[questionId];

	if (answer === ANSWER_VALUE_SKIPPED) return false;

	const label = convertQuestionAnswerValueToLabel({
		value: answer, question,
	});

	if ([null, undefined].includes(label)) {
		return false;
	}

	if (input === INPUT_MULTIPLE) {
		return arrayWrap(label, [])?.length > 0;
	}

	if ([INPUT_PCT, INPUT_NUMBER].includes(input)) {
		if (label === 0) return true;
	}

	return !!label;
};

export const getQuestionAnsweredState = ({
	question,
	answers,
}) => {
	const { questionId, input } = question;

	if (!answers || Object.keys(answers || {})?.length === 0
	) {
		return ANSWER_STATE_NOT_ANSWERED;
	}

	const answer = answers?.[questionId];

	if (answer === ANSWER_VALUE_SKIPPED) return ANSWER_STATE_SKIPPED;
	if (answer === ANSWER_VALUE_LATER) return ANSWER_STATE_LATER;

	const label = convertQuestionAnswerValueToLabel({
		value: answer, question,
	});

	if ([null, undefined].includes(label)) {
		return ANSWER_STATE_NOT_ANSWERED;
	}

	if (input === INPUT_MULTIPLE) {
		return arrayWrap(label, [])?.length > 0
			? ANSWER_STATE_ANSWERED
			: ANSWER_STATE_NOT_ANSWERED;
	}

	if ([INPUT_PCT, INPUT_NUMBER].includes(input)) {
		if (label === 0) return ANSWER_STATE_ANSWERED;
	}

	return label
		? ANSWER_STATE_ANSWERED
		: ANSWER_STATE_NOT_ANSWERED;
};

export const getSurveyQuestions = ({ surveyConf, answers }) => surveyConf
	?.sections
	?.map((section) => getSectionQuestionsFromAnswers({ section, answers }))
	?.flat(2) || [];

export const countSurveyQuestions = ({ surveyConf, answers }) => (
	getSurveyQuestions({ surveyConf, answers })?.length
);

export const getSurveyMandatoryQuestions = ({ surveyConf, answers }) => (
	getSurveyQuestions({ surveyConf, answers })?.filter((question) => !question?.optional)
);

export const getSurveyCompletedQuestions = ({ surveyConf, answers, withOptional }) => {
	const questions = withOptional
		? getSurveyQuestions({ surveyConf, answers })
		: getSurveyMandatoryQuestions({ surveyConf, answers });
	return questions
		?.filter((question) => isQuestionAnswered({ question, answers }));
};

export const countSurveyCompletedQuestions = ({ surveyConf, answers, withOptional }) => (
	getSurveyCompletedQuestions({ surveyConf, answers, withOptional })?.length
);

export const getSurveyQuestion = ({ surveyConf }) => surveyConf
	?.sections
	?.map((section) => section?.questions
		?.map((question) => {
			if (!question?.optional) return question;
		}))
	?.flat(2) || [];

export const countSurveyMandatoryQuestions = ({ surveyConf, answers }) => (
	getSurveyMandatoryQuestions({ surveyConf, answers })?.length || 0
);

export const isSurveyCompleted = ({
	surveyConf,
	answers,
}) => {
	const { unanswered } = getSurveyAnsweredAndUnansweredQuestions({ surveyConf, answers });
	return unanswered?.length === 0;
};

export const getQuestionOptions = (question) => [
	...question?.options || [],
	...question.allowOther
		? [
			{
				value: 'ANSWER_VALUE_OTHER',
				label: {
					fr: 'Autre',
					en: 'Other',
				},
			},
		]
		: [],
];

export const convertQuestionAnswerValueToLabel = ({
	value,
	question,
}) => {
	const { input } = question;

	const options = getQuestionOptions(question);

	if (!options || options.length === 0) return value;

	const labels = options
		.filter((o) => (
			arrayWrap(value || [])
				.map((s) => s?.toString?.()?.toLowerCase?.())
				.includes(o?.value?.toString?.()?.toLowerCase?.())
		))
		.map((o) => o?.label);

	if (input !== INPUT_MULTIPLE) {
		return labels?.[0];
	}

	return labels;
};

const computeValueFunc = ({
	value,
	ranges = 100,
	defaultValue,
}) => {
	const score = (value || defaultValue || 0) * (ranges || 100);
	return score;
};

const computePctFunc = ({
	value,
	ranges,
	defaultValue,
}) => {
	const convertedValue = value >= 0 ? Number(value) : 0;
	const [[vMin]] = ranges;
	const [[vMax]] = ranges.slice().reverse();
	const scores = ranges.map((r) => r[1]);
	const sMin = scores.reduce((p, c) => Math.min(p, c), 0);
	const sMax = scores.reduce((p, c) => Math.max(p, c), 0);
	const valueRanger = convertedValue < vMin ? vMin : convertedValue > vMax ? vMax : convertedValue;
	const safeValue = valueRanger >= vMin && valueRanger <= vMax ? valueRanger : defaultValue || vMin;
	let score = null;
	ranges.forEach(([v, m], i, a) => {
		if (score !== null) return;
		if ((i + 1) < a.length) {
			const [vNext, mNext] = a[i + 1];
			if ((i + 2) < a.length) {
				if (safeValue <= vNext) {
					score = m + (mNext - m) * (safeValue - v) / (vNext - v);
				}
			} else {
				score = m + (mNext - m) * (safeValue - v) / (vNext - v);
			}
		}
	});
	if (sMax !== undefined) score = Math.min(sMax, score);
	if (sMin !== undefined) score = Math.max(sMin, score);
	return score;
};

const COMPUTE_PCT = {
	id: 'computePct',
	computer: computePctFunc,
};
const COMPUTE_VALUE = {
	id: 'computeValue',
	computer: computeValueFunc,
};

export const questionAnswersBuilder = (answers) => answers
	.map((a) => ({
		[a.questionId]: [null, undefined].includes(a.value)
			? a.value
			: (arrayWrap(a.value) || [])
				.map((value) => {
					const option = QUESTIONS[a.questionId]?.options?.find((o) => o.value === value);
					const result = option?.score >= 0
						? option?.score
						: option?.value || value;
					return result;
				})
				.reduce(sumReducer),
	}))
	.reduce(mergeReducer, {});

const computeScore = ({ scoring, answers }) => {
	const logger = (...args) => LocalDebug.logNull({ className, method: 'computeScore' }, ...args);
	logger({ scoring, answers });

	const answersObj = isArray(answers)
		? questionAnswersBuilder(answers)
		: answers;

	const result = scoring
		.map(({
			question,
			weight,
			computer,
			ranges,
			clause,
		}) => {
			const { questionId: qId } = question;
			const value = answersObj[qId];

			logger({ qId, value });
			const base = {
				value,
				computer,
				weight,
				qId,
				clause,
			};

			if (
				[ANSWER_VALUE_LATER, ANSWER_VALUE_SKIPPED].includes(value)
				|| clause?.(value, answersObj) === false
			) {
				logger('return 0');
				return {
					...base,
					score: 0,
				};
			}
			const score = computer.computer({
				value,
				ranges,
			});

			logger('score', score);
			return {
				...base,
				score,
			};
		})
		.map((s, i, a) => {
			const total = a
				.map((item) => item.weight
				* (item?.clause?.(answersObj[item.qId], answersObj) === false ? 0 : 1))
				.reduce(sumReducer, 0);

			logger('map, #', i, 'total:', total);
			return {
				...s,
				total,
			};
		})
		.reduce((prev, cur) => {
			const score = prev.score + cur.score * cur.weight;
			const total = prev.total + (100
				* cur.weight
				* (cur?.clause?.(answersObj[cur.qId], answersObj) === false ? 0 : 1)
			);
			const details = {
				id: cur.id,
				qId: cur.qId,
				score: cur.score,
				impact: pct(
					cur.weight * (cur?.clause?.(answersObj[cur.qId], answersObj) === false ? 0 : 1),
					cur.total,
					4,
				),
				answer: cur.value,
				verbose: (() => {
					const qPrefix = `For ${cur.id} (${cur.qId})`;
					const qAnswer = `Client answer: ${cur.value}`;
					const qScore = `Question score: ${round(cur.score, 0)}%`;
					const qWeight = (cur.weight * (cur?.clause?.(answersObj[cur.qId], answersObj) === false ? 0 : 1)) > 0 ? `Weight in section score: ${pct(cur.weight, cur.total, 0)}%` : '';
					return [qPrefix, qAnswer, qScore, qWeight].join(' ');
				})(),
			};

			logger('reduce, score:', score, ', total: ', total, { details });

			return {
				score,
				total,
				details: [
					...prev.details,
					details,
				],
			};
		}, {
			score: 0,
			details: [],
			total: 0,
		});

	logger({ result });

	return 100 * result?.score / result?.total;
	// return result;
};

export const computeSurveyScores = ({ surveyConf, answers }) => {
	if (!surveyConf) return {};

	const logger = (...args) => LocalDebug.logNull({ className, method: 'computeSurveyScores' }, ...args);
	logger({ surveyConf, answers });

	const answersRemapped = surveyConf?.sections?.map?.((s) => s.questions
		.map((q) => ({
			questionId: q?.questionId,
			value: ((q?.defaultSkippedValue
			&& answers?.[q?.questionId] === ANSWER_VALUE_SKIPPED)
				? q?.defaultSkippedValue
				: answers?.[q?.questionId]
			),
		})))
		.flat();

	const sectionScores = Object
		.entries(surveyConf?.scoring)
		.map(([key, values]) => {
			logger({ key, values, answers: answersRemapped });
			return {
				[key]: computeScore({
					scoring: values,
					answers: answersRemapped,
				}),
			};
		})
		.reduce(mergeReducer, {});

	logger({ sectionScores });

	const globalScore = (
		Object.values(sectionScores).reduce(sumReducer, 0)
	)
		/ Object.values(sectionScores).length;

	const computedScores = {
		globalScore: round(globalScore, 2),
		...Object.entries(sectionScores)
			.map(([key, value]) => ({ [key]: round(value, 2) }))
			.reduce(mergeReducer, {}),
	};

	logger({ computedScores });

	return computedScores;
};

const shared = {
	computeScore,
	COMPUTE_PCT,
	COMPUTE_VALUE,
	convertQuestionAnswerValueToLabel,
	optionalizeQuestion,
};

export default shared;
