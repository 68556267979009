import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { useSelector } from 'react-redux';
import { useTheme } from '@emotion/react';
import {
	Col, Divider, Row, Space,
} from 'antd';
import ReactMarkdown from 'react-markdown';
import { getCompanyGenderScoreSurveyDraftSelected } from '../../../reducers/app';
import { SurveyNavigationContext } from './SurveyNavigationProvider';
import { GENDERSCORE_LABEL, NOT_ANSWERED_LABEL } from '../../../constants/constant';
import GenderScorePillarLogoLabel from '../../company/genderscore/pillars/GenderScorePillarLogoLabel';
import {
	getSurveySectionsQuestionsFromAnswers,
	isQuestionAnswered,
	isSurveyCompleted,
} from '../data/shared';
import { getPillarColorMain } from '../../../constants/genderscore-pillars';
import { AppDataContext } from '../../../contexts/AppDataProvider';
import { SurveyContext } from './SurveyProvider';
import SurveyQuestionAnswer from './SurveyQuestionAnswer';
import SurveyQuestionEditButton from './SurveyQuestionEditButton';
import AppLogo from '../../app/layout/AppLogo';
import SurveyPageHeader from './SurveyPageHeader';
import SurveyQuestionFooter from './SurveyQuestionFooter';
import {
	EVENT, buildEventLabel, buildEventSection, pushClick,
} from '../../../contexts/EventPushProvider';
import { LocalDebug } from '../../../utils/LocalDebug';
import SurveyInfoModal from './SurveyInfoModal';
import useBodyScrollBlocker from '../../../hooks/useBodyScrollBlocker';

const SurveyReviewAnswers = ({
	genderScoreSurvey,
	...props
}) => {
	const className = 'SurveyReviewAnswers';

	const theme = useTheme();
	const navigate = useNavigate();
	const { isAppSiderVisibleInSurvey } = useContext(AppDataContext);
	const draftSelected = useSelector(getCompanyGenderScoreSurveyDraftSelected);
	const { eventContext, submitSurvey } = useContext(SurveyContext);
	const { surveyConf, answers } = useContext(SurveyNavigationContext);
	const [surveyCompleted, setSurveyCompleted] = useState(false);
	const [infoModalOpen, setInfoModalOpen] = useState(false);

	useBodyScrollBlocker(infoModalOpen, className);

	const eventProps = {
		...eventContext,
		...buildEventSection(EVENT.SECTION.SURVEY_REVIEW),
	};

	const [survey, setSurvey] = useState();

	useEffect(() => {
		setSurvey(genderScoreSurvey || draftSelected);
	}, [genderScoreSurvey, draftSelected]);

	useEffect(() => {
		setSurveyCompleted(isSurveyCompleted({ surveyConf, answers }));
	}, [surveyConf, answers]);

	if (!survey) return null;

	const handleOpenInfoModal = async () => {
		setInfoModalOpen(true);
	};

	const handleSubmit = async (values) => {
		setInfoModalOpen(false);
		LocalDebug.logInfo({ className, method: 'handleSubmit' }, { values });
		pushClick({
			...eventProps,
			...buildEventLabel(EVENT.LABEL.SURVEY_SUBMIT),
		});
		await submitSurvey({
			draft: draftSelected,
			data: { withSlack: true, ...values },
			navigate,
		});
	};

	let questionIndex = 0;
	return (
		<>
			<div
				style={{
					maxWidth: 860,
					margin: '60px auto 130px',
					fontSize: 16,
				}}
			>
				<h1 style={{ marginBottom: 0 }}>
				💯<br/>Your {GENDERSCORE_LABEL} survey<br/>is ready for submission.
				</h1>
				<p>
				Thank you for your time!
				</p>
				<p>
				Before obtaining your results, please <b>review all your answers</b> carefully.
					<br/>Once you submit them, no backsies!
				</p>

				<Divider />
				<Space
					direction='vertical'
					wrap={false}
					align={'top'}
					size={30}
				>

					{getSurveySectionsQuestionsFromAnswers({ surveyConf, answers })
						?.map((section) => {
							return (
								<div
									key={section.value}
									style={{
										paddingLeft: 24,
										borderLeft: `6px solid ${getPillarColorMain(section.value)}`,
									}}
								>
									<GenderScorePillarLogoLabel
										pillar={section.value}
										labelSize={24}
										iconSize={32}
										style={{
											label: {
												fontWeight: 'bold',
												marginBottom: -3,
											},
										}}
									/>

									{section?.questions?.map((question) => {
										questionIndex += 1;
										return (
											<div
												key={question.questionId}
												style={{
													width: '100%',
													borderBottom: '1px dotted #ccc',
												}}
											>
												<Space
													direction='horizontal'
													style={{
														justifyContent: 'space-between',
														alignItems: 'flex-start',
														width: '100%',
														padding: '10px 5px',
													}}
												>
													<div>
														<ReactMarkdown>{`**${questionIndex}** • ${question.label}`}</ReactMarkdown>
													</div>

													{!isQuestionAnswered({ question, answers })
														? (
															<p
																style={{
																	textAlign: 'right',
																	minWidth: 160,
																}}
															>
																<span style={{
																	opacity: 0.5,
																	verticalAlign: 'middle',
																}}>
																	{NOT_ANSWERED_LABEL}
																</span>
														&nbsp;
														&nbsp;
														&nbsp;
																<SurveyQuestionEditButton
																	section={section}
																	question={question}
																	answer={answers?.[question?.questionId]}
																/>
															</p>
														) : (
															<Space style={{ paddingTop: 8 }}>
																<Space
																	direction='vertical'
																	size={3}
																	style={{
																		justifyContent: 'flex-end',
																		alignItems: 'flex-end',
																	}}
																>
																	<SurveyQuestionAnswer
																		question={question}
																		answer={answers?.[question?.questionId]}
																	/>

																</Space>

																<SurveyQuestionEditButton
																	section={section}
																	question={question}
																	answer={answers?.[question?.questionId]}
																/>
															</Space>
														)
													}
												</Space>
											</div>
										);
									})}
								</div>
							);
						})}
				</Space>
				<SurveyQuestionFooter
					nextText={surveyCompleted ? 'Submit your answers' : 'Your survey is incomplete'}
					disableSave={!surveyCompleted}
					withPrev={false}
					withAnswerLater={false}
					withSkip={false}
					onSave={handleOpenInfoModal}
				/>
			</div>
			<SurveyPageHeader>
				<Row
					style={{
						width: '100%',
						paddingTop: 4,
					}}>
					<Col>
						{!isAppSiderVisibleInSurvey && (
							<AppLogo label={GENDERSCORE_LABEL} style={{ pointerEvents: 'none' }}/>
						)}
					</Col>
				</Row>
			</SurveyPageHeader>

			<SurveyInfoModal
				open={infoModalOpen}
				onClose={() => setInfoModalOpen(false)}
				onSubmit={handleSubmit}
			/>
		</>

	);
};

export default SurveyReviewAnswers;
