import { useTheme } from '@emotion/react';
import { useState } from 'react';
import { Button } from 'antd';
import Table from '../../../app/table/Table';
import TableColumns from '../../../app/table/TableColumns';
import { displayHtml } from '../../../../utils/common';
import { FaHtml5 } from 'react-icons/fa';
import ModalContainerBase from '../../../../contexts/modals/ModalContainerBase';
import FormBoxTitle from '../../../app/form/FormBoxTitle';

const CampaignEmailTable = (
	{
		onDataLoaded,
		path = '/admin/campaign-emails',
		filterOptions = {},
		isRefresh = 0,
		onSearchDataColumn,
		...props
	},
) => {
	const theme = useTheme();

	const [isRefreshDataLayout] = useState(0);
	const [isEmailOpen, setIsEmailOpen] = useState(false);
	const [emailSelected, setEmailSelected] = useState(null);

	const handleOpenEmail = (row) => {
		setEmailSelected(row);
		setIsEmailOpen(true);
	};

	const userStubCol = TableColumns.userStubColumn(theme, { withDropdown: true, withPreview: true });
	const userEmailCol = TableColumns.userEmailColumn(theme, {
		value: (value, row) => row.userEmail, removeInColumnFilter: true,
	});
	const createdAtCol = TableColumns.createdAtColumn(theme);
	const updatedAtCol = TableColumns.updatedAtColumn(theme);
	const statusCol = TableColumns.campaignEmailStatusColumn(theme);
	// const statusCol = { title: 'Status', dataIndex: 'status', removeInColumnFilter: true };
	const campaignCol = { title: 'Campaign', dataIndex: 'campaign', removeInColumnFilter: true };
	const subjectCol = { title: 'Subject', dataIndex: ['message', 'Subject'], removeInColumnFilter: true };
	const htmlCol = {
		title: 'HTML',
		dataIndex: ['message', 'HTMLPart'],
		removeInColumnFilter: true,
		// render: (value, row) => {
		//     return (value && value !== '' && <Popover
		//         content={<Space style={{maxWidth: '620px', maxHeight: '400px', overflow: 'scroll'}}><span>{displayHtml(value)}</span></Space>}
		//         title={<b>Email content</b>}
		//         style={{maxHeight: '400px', overflow: 'hidden'}}
		//     >
		//         <Button
		//             type='link'
		//             icon={<BiEnvelope />}
		//         />
		//     </Popover>)},
		render: (value, row) => <Button
			type='link'
			onClick={() => handleOpenEmail(row)}
			icon={<FaHtml5/>}
		/>,
	};

	const columns = [
		userStubCol,
		userEmailCol,
		campaignCol,
		subjectCol,
		statusCol,
		createdAtCol,
		updatedAtCol,
		htmlCol,
	];

	return (
		<>
			<Table
				path={path}
				onDataLoaded={onDataLoaded}
				columns={columns}
				filterOptions={filterOptions}
				isRefresh={isRefresh + isRefreshDataLayout}
				onSearchDataColumn={onSearchDataColumn}
				showTotal={(total) => `Total ${total} items`}
				limit={10}
				withDynamicSearch
				{...props}
			/>
			<ModalContainerBase
				open={isEmailOpen}
				setOpen={setIsEmailOpen}
				width={620}
			>
				<FormBoxTitle
					title='Email HTML'
				/>
				{displayHtml(emailSelected?.message?.HTMLPart)}
			</ModalContainerBase>
		</>
	);
};

export default CampaignEmailTable;
