import { NOT_PROVIDED_YET_LABEL } from '../../../../constants/constant';
import EntriesCard from '../EntriesCard';
import UserResumeEducationEntryLine from './UserResumeEducationEntryLine';
import UserResumeEducationEntryRow from './UserResumeEducationEntryRow';

const UserResumeEducationCard = (
	{
		user,
		limit = 1,
		hideIfEmpty = true,
		notProvided = NOT_PROVIDED_YET_LABEL,
		children,
		...props
	},
) => {
	return <EntriesCard
		entries={user?.getResumeEducation()?.degrees}
		RowRenderer={UserResumeEducationEntryRow}
		LineRenderer={UserResumeEducationEntryLine}
		// BodyRenderer={UserResumeEducationCardBody}
		title='Education'
		hideIfEmpty={hideIfEmpty}
		{...props}
	>
		{children}
	</EntriesCard>;
};

export default UserResumeEducationCard;
