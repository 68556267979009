import CompanyModel from '../../../../../models/CompanyModel';
import { getQuestionChart } from '../../../../genderscore/data/dashboard';

const CompanyGenderScoreChartFromSurveyBox = ({
	company,
	question,
	width,
	...props
}) => {
	const companyModel = new CompanyModel(company);
	if (!companyModel.genderScore) {
		return null;
	}

	const current = companyModel?.getGenderScoreCurrentSurvey()?.answers?.[question];
	const previous = companyModel?.getGenderScorePreviousSurvey()?.answers?.[question];

	const {
		Chart, valueMapper, labelMapper, options,
	} = getQuestionChart(question);
	if (!Chart) {
		return null;
	}
	return <Chart
		current={valueMapper(current)}
		previous={valueMapper(previous)}
		currentLabel={labelMapper(current)}
		width={width}
		existsPreviousSurvey={companyModel?.getGenderScorePreviousSurvey() !== undefined}
		{...options}
		{...props}
	/>;
};
export default CompanyGenderScoreChartFromSurveyBox;
