import { createContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getCompanySelected } from '../reducers/app';
import { useAuthContext } from '../hooks/useAuthContext';
import CompanyModel from '../models/CompanyModel';
import { getCurrentOnboardingState } from '../components/onboarding/OnboardingStates';

export const OnboardingContext = createContext();

const OnboardingProvider = ({ children }) => {
	const companySelected = useSelector(getCompanySelected);
	const { currentUser } = useAuthContext();
	const [withOnboardingBanner, setWithOnboardingBanner] = useState(false);
	const [onboardingState, setOnboardingState] = useState();

	const canShowOnboardingBanner = (companyModel) => {
		/* Only show Onboarding Banner to ADMIN users
			if the company is not in a legacy plan
			AND it has not gone through the onboarding yet */

		if (!companyModel || !currentUser) {
			return false;
		}
		return !companyModel?.isCompanyPlanLegacy()
				&& !companyModel?.hasFinishedOnboarding()
				&& currentUser?.acls?.isDocumentAdmin;
	};

	useEffect(() => {
		if (!companySelected) return;
		const companyModel = new CompanyModel(companySelected);
		setOnboardingState(getCurrentOnboardingState(companySelected));
		setWithOnboardingBanner(canShowOnboardingBanner(companyModel));
	}, [companySelected]);

	return (
		<OnboardingContext.Provider
			value={{
				withOnboardingBanner,
				onboardingState,
			}}
		>
			{children}
		</OnboardingContext.Provider>
	);
};

export default OnboardingProvider;
