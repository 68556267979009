import { useContext, useEffect, useState } from 'react';
import { AppDataContext } from '../../../../contexts/AppDataProvider';

const SurveyPageHeader = ({
	children,
}) => {
	const {
		appSiderWidth,
		appTopOffset,
		isAppSiderVisible,
	} = useContext(AppDataContext);

	return (
		<div
			style={{
				position: 'fixed',
				top: appTopOffset,
				left: isAppSiderVisible ? appSiderWidth : 0,
				justifyContent: 'space-evenly',
				// background: 'white',
				padding: 10,
				width: `calc(100% - ${isAppSiderVisible ? appSiderWidth : 0}px)`,
				// opacity: 0.5,
			}}
		>
			{children}
		</div>
	);
};

export default SurveyPageHeader;
