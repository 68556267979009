import { useTheme } from '@emotion/react';
import { Divider, Menu, Space } from 'antd';
import Link from '../Link';
import { UserOutlined } from '@ant-design/icons';
import { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { readAction } from '../../../actions/app';
import { useAuthContext } from '../../../hooks/useAuthContext';
import {
	hexToRgb, isReactFragment, mixColors, writeLog,
} from '../../../utils/common';
import AppLogo from './AppLogo';
import AppMenuUser from './AppMenuUser';
import CompanyLogo from '../../company/CompanyLogo';
import { getCompanyGenderScoreSurveyDraftSelected, getCompanySelected } from '../../../reducers/app';
import UpgradePlanButton from './UpgradePlanButton';
import { PermissionContext } from '../../../contexts/PermissionProvider';
import { isFunction } from 'lodash';
import { LocalDebug } from '../../../utils/LocalDebug';
import { AppDataContext } from '../../../contexts/AppDataProvider';
import { PATHS } from '../../../constants/constant';
import SurveyMenu from '../../genderscore/survey/SurveyMenu';
import CompanyModel from '../../../models/CompanyModel';

const AppMenu = (
	{
		items,
		withIcon = true,
		historyActions = [],
		...props
	},
) => {
	const className = 'AppMenu';

	const theme = useTheme();
	const companySelected = useSelector(getCompanySelected);
	const draftSelected = useSelector(getCompanyGenderScoreSurveyDraftSelected);

	const dispatch = useDispatch();
	const location = useLocation();
	const navigate = useNavigate();

	const [openedSubMenus, setOpenedSubMenus] = useState([]);

	const {
		isAtsSyncable,
		isGenderScoreEnabled,
		isGenderScoreFilled,
		genderScore,
		isUMSEnabled,
		isUMSManagerSurveyFilled,
		isUMSEmployeeSurveyFilled,
		isUMSSurveyEnabled,
		umsScore,
		routePermissions,
		isMenuItemAllowed,
	} = useAuthContext();

	const { appSiderWidth } = useContext(AppDataContext);

	const { shouldDisplayUpgradePlanButton } = useContext(PermissionContext);

	const context = {
		...useAuthContext() || {},
		...useContext(AppDataContext) || {},
		...useContext(PermissionContext) || {},
	};

	const [menu, setMenu] = useState(null);
	const [menuItems, setMenuItems] = useState([]);
	const [defaultOpenKeys, setDefaultOpenKeys] = useState(null);
	const [selectedKeys, setSelectedKeys] = useState([]);

	const findMenuItemByKey = ({ key, itemList = menuItems }) => {
		LocalDebug.logNull({ className, method: 'findMenuItemByKey' }, { key, itemList });
		let menuItem;
		(itemList || menuItems || [])
			.forEach((item) => {
				if (item?.children) {
					const menuItemFound = findMenuItemByKey({ key, itemList: item?.children });
					if (menuItemFound) menuItem = menuItemFound;
				}
				LocalDebug.logNull({ className, method: 'findMenuItemByKey' }, key, 'vs.', item?.key);
				if (item && (item?.key === key)) {
					menuItem = item;
				}
			});
		LocalDebug.logNull({ className, method: 'findMenuItemByKey' }, 'exiting => ', { menuItem });
		return menuItem;
	};

	const getSelectedKeys = (itemList) => {
		const values = [];
		LocalDebug.logNull({ className, method: 'getSelectedKeys' }, { itemList: itemList?.length });
		if (itemList?.length > 0) {
			itemList.forEach((item) => {
				if (item?.children) {
					values.push(...getSelectedKeys(item?.children));
				}
				if (item
					&& (
						item?.path === location?.pathname
						|| [...item?.aliases || []].some((alias) => (
							(alias?.length > 0 && location?.pathname?.length > 0)
							&& (new RegExp(alias, 'i')).test(location?.pathname)
						))
					)) {
					values.push(item.key);
				}
			});
		}
		return values;
	};

	const getDefaultOpenKeys = (itemList, breadCrumbs) => {
		const values = [];
		LocalDebug.logNull({ className, method: 'getDefaultOpenKeys' }, { itemList, breadCrumbs });
		if (itemList?.length > 0) {
			itemList.forEach((item) => {
				if (item?.children) {
					values.push(
						...getDefaultOpenKeys(item?.children, [...breadCrumbs || [], item.key]),
					);
				}
				if (item
					&& (
						item?.path === location?.pathname
						|| [...item?.aliases || []].some((alias) => (new RegExp(alias, 'i')).test(location?.pathname))
					)
				) {
					values.push(...breadCrumbs || []);
				}
			});
		}
		return values;
	};

	const color = theme.color.blue;

	const buildMenuItem = (item, index) => {
		const {
			path, aliases, icon, children, name, label, hidden,
		} = item || {};

		if (!item) return null;

		if (!isMenuItemAllowed(item)) return null;

		if (item.type === 'group') {
			return item;
		}

		if (!item.name) {
			return {
				type: 'divider',
				key: index,
			};
		}

		let iconValue = withIcon && (icon || <UserOutlined />);
		let labelValue = label;

		// LocalDebug.logInfo({ className, method: 'buildMenuItem' }, {
		// 	path, name, iconValue, labelValue, 'isFunction(labelValue)': isFunction(labelValue),
		// });
		if (isFunction(iconValue)) {
			iconValue = iconValue({
				genderScore,
				width: 16,
				height: 16,
				size: 18,
				color: 'rgba(0,0,0,.88)',
				ringColor: 'rgba(0,0,0,.88)',
			});
		}
		if (isFunction(labelValue)) {
			// LocalDebug.logInfo({ className, method: 'buildMenuItem' }, {
			// 	path, name, iconValue, labelValue, 'isFunction(labelValue)': isFunction(labelValue),
			// });
			labelValue = labelValue({ ...context || {}, withBadge: true });
			// theme,
			// actionCount: companySelected?.genderScoreActions?.actions
			// 	?.filter((a) => a?.isActive !== false)?.length,
			// });
		}

		if (children && children.length) {
			return {
				key: name,
				path,
				aliases,
				label: labelValue,
				icon: iconValue,
				children: item.children.map((subItem) => buildMenuItem(subItem)),
			};
		}
		return {
			key: name,
			path,
			aliases,
			icon: iconValue,
			label: labelValue,
		};
	};

	const handleMenuItemClicked = (e) => {
		LocalDebug.logInfo({ className, method: 'handleMenuItemClicked' }, e?.key, e?.keyPath);
		const { key, keyPath } = e || {};
		if (!key) return;
		const menuItem = findMenuItemByKey({ key, itemList: menuItems });
		LocalDebug.logInfo({ className, method: 'handleMenuItemClicked' }, key, menuItem?.path);
		// remove documentReadersIds filter
		if (!menuItem) return;
		writeLog('Clic Menu', {
			path: menuItem.path,
			keysPath: keyPath,
		});
		dispatch(readAction({ label: null, documentIds: null }));
		navigate(menuItem.path);
	};

	const handleOnOpenChange = (keys) => {
		const openedMenuItems = keys
			.filter((key) => !openedSubMenus.includes(key))
			.map((key) => findMenuItemByKey({ key, itemList: menuItems }))
			.filter((item) => item?.path);

		const [menuItemToOpen] = openedMenuItems || [];
		if (menuItemToOpen) handleMenuItemClicked(menuItemToOpen);
		setOpenedSubMenus(keys);
	};

	useEffect(() => {
		if (menuItems?.length > 0) {
			setSelectedKeys(getSelectedKeys(menuItems));
			setDefaultOpenKeys(getDefaultOpenKeys(menuItems));

			LocalDebug.logNull(
				{ className, effects: 'location.pathname, menuItems' },
				{
					selectedKeys: getSelectedKeys(menuItems),
					defaultOpenKeys: getDefaultOpenKeys(menuItems),
				},
			);
		}
	}, [menuItems]);

	useEffect(() => {
		if (!routePermissions || !items) return;
		setMenuItems(items?.map?.(buildMenuItem)?.filter?.((item) => item) || []);
	}, [
		items,
		routePermissions,
		isAtsSyncable,
		isGenderScoreEnabled,
		isGenderScoreFilled,
		isUMSEnabled,
		isUMSManagerSurveyFilled,
		isUMSEmployeeSurveyFilled,
		isUMSSurveyEnabled,
		companySelected?.genderScoreActions?.actions,
		companySelected?.actionPlan?.actions,
	]);

	useEffect(() => {
		if (
			location.pathname.indexOf(PATHS.GENDERSCORE_SURVEY()) === 0
			&& draftSelected
		) {
			setMenu(<SurveyMenu />);
		} else {
			setMenu(
				(menuItems?.length > 0 && defaultOpenKeys)
					? (
						<Menu
							className='ant-menu-new'
							items={menuItems}
							mode="inline"
							selectedKeys={selectedKeys}
							onClick={handleMenuItemClicked}
							onOpenChange={handleOnOpenChange}
							defaultOpenKeys={defaultOpenKeys}
							style={{
								background: 'transparent',
								marginTop: 0,
								paddingBottom: 60,
								position: 'relative',
								...props?.style,
							}}
						/>
					)
					: null,
			);
		}
	}, [
		location.pathname,
		draftSelected,
		menuItems,
		defaultOpenKeys,
		selectedKeys,
		companySelected,
	]);

	return (
		<>
			<Space
				style={{
					width: '100%',
					justifyContent: 'space-between',
					alignItems: 'center',
					padding: 20,
				}}
			>
				<AppLogo
					{...new CompanyModel(companySelected).isUMSCompanyPlan()
						? { label: 'Solutions' }
						: {}
					}
				/>

				<CompanyLogo size={36} company={companySelected} />

			</Space>

			<Divider style={{ margin: 0 }} />

			<AppMenuUser
				style={{
					// background: `rgba(${hexToRgb(theme.color.black).join(',')},.05)`,
					margin: '14px 20px',
					// padding: 10,
					borderRadius: 4,
					// width: 'calc(100% - 20px)',
				}}
			/>

			{shouldDisplayUpgradePlanButton && (
				<UpgradePlanButton
					style={{ margin: 20 }}
				/>
			)}

			<Divider style={{ margin: 0 }} />

			{menu}
		</>
	);
};

export default AppMenu;
