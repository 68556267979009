import { Col, Divider, Row } from 'antd';

export const checkRow = (row) => row
	.map(({ check }) => check)
	.reduce((p, c) => p || c !== false, false);

export const rowSpanner = (
	blocks,
	gutter = [30, 30],
	marginBottom = 24,
) => (
	blocks
		.filter((rows) => rows
			.map((row) => checkRow(row))
			.reduce((p, c) => p || c !== false, false))
		.map((rows, b, arr) => (
			<div
				key={b}
				style={{
					borders: '1px solid green',
				}}
			>
				{rows
					.filter((row) => row
						.map(({ check }) => check)
						.reduce((p, c) => p || c !== false, false))
					.map((cols, i) => (
						<Row
							key={i}
							gutter={gutter}
							style={{
								marginBottom,
								borders: '1px solid red',
							}}
						>
							{cols
								.filter(({ check }) => check !== false)
								.map(({ content, span }, j) => (
									<Col
										key={j}
										span={span}
										style={{
											borders: '1px solid blue',
										}}
									>
										{content}
									</Col>
								))}
						</Row>
					))
				}
				{b < (arr.length - 1) && (
					<Row
						gutter={gutter}
					>
						<Divider style={{ margin: 0 }}/>
					</Row>
				)}
			</div>
		))
);
