import { PERMS_GRANT_STAFF } from '../menu.utils';
import MenuBase from '../MenuBase';
import { TeamOutlined } from '@ant-design/icons';
import StaffTalentDatabaseMenu from './users/StaffTalentDatabaseMenu';
import StaffMissingResumesMenu from './users/StaffMissingResumesMenu';
import StaffHiddenCompaniesMenu from './users/StaffHiddenCompaniesMenu';

class StaffUsersMenu extends MenuBase {
	static props = {
		name: 'staff-users-menu',
		label: 'Users',
		icon: <TeamOutlined />,
		iconLegacy: <span>👩‍💻</span>,
		children: () => [
			(new StaffTalentDatabaseMenu()).build(),
			(new StaffMissingResumesMenu()).build(),
			(new StaffHiddenCompaniesMenu()).build(),
		],
		perms: PERMS_GRANT_STAFF,
	};

	constructor() {
		super(StaffUsersMenu.props);
	}
}

export default StaffUsersMenu;
