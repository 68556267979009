import { Button, Space, Tooltip } from 'antd';
import { useTheme } from '@emotion/react';
import moment from 'moment';
import { EMAIL_TEMPLATES } from '../../../constants/constant';
import MessageTemplateCategoryTag from './MessageTemplateCategoryTag';
import { mixColors } from '../../../utils/common';
import {
	FaCopy, FaCrown, FaHandPointUp, FaHands, FaPen, FaStar, FaTrash, FaUser,
} from 'react-icons/fa';
import { useAuthContext } from '../../../hooks/useAuthContext';
import ActionButton from '../../app/table/ActionButton';
import MessageTemplateUserAvatar from './MessageTemplateUserAvatar';
import GridCardBase from '../../app/grid/GridCardBase';

const CornerIcon = (
	{
		color,
		tooltip,
		Icon,
	},
) => {
	const className = 'CornerIcon';

	const theme = useTheme();
	return (
		<>
			<div
				style={{
					position: 'absolute',
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
					top: 0,
					right: 0,
					width: 48,
					height: 48,
					borderLeft: '48px solid transparent',
					borderRight: '0px solid transparent',
					borderTop: `48px solid ${color}`,
				}}
			 />
			<Tooltip title={tooltip}>
				<div
					style={{
						position: 'absolute',
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
						color: theme.color.white,
						fontSize: 16,
						top: 0,
						right: 0,
						width: 28,
						height: 28,
					}}
				>
					<Icon />
				</div>
			</Tooltip>
		</>
	);
};

const MessageTemplateGridCard = (
	{
		template,
		height = 350,
		onEditTemplate,
		onCopyTemplate,
		onDeleteTemplate,
		...props
	},
) => {
	const className = 'MessageTemplateGridCard';

	const theme = useTheme();
	const { currentUserModel, isAdmin, isStaffView } = useAuthContext();

	const categoryOption = EMAIL_TEMPLATES.findCategoryOption(template.category) || EMAIL_TEMPLATES.CATEGORY_OTHER_OPTION;

	return (
		<GridCardBase
			{...props}
		>

			<div
				style={{
					width: '100%',
					height: height - 40,
					whiteSpace: 'normal',
					wordBreak: 'break-word',
					fontSize: 12,
					pointerEvents: 'none',
				}}
			>
				<div
					style={{
						width: 'calc(100% + 0px)',
						background: `linear-gradient(45deg, ${mixColors(theme.color.white, categoryOption?.color || theme.color.grey, 5)}, ${mixColors(theme.color.white, categoryOption?.color || theme.color.grey, 0)})`,
					}}
				>
					<Space
						direction='vertical'
						style={{
							width: '100%',
							padding: 20,
							justifyContent: 'center',
						}}
					>
						<MessageTemplateCategoryTag category={template.category} />
						<span style={{ fontSize: 14 }}><b>{template.name}</b></span>
					</Space>
				</div>

				<Space
					direction='vertical'
					size={0}
					style={{
						width: '100%',
						// overflow: 'hidden',
						padding: '10px 20px',
						// background: theme.color.bgGrey,
					}}
				>

					<div
						style={{
							paddingLeft: 10,
							marginBottom: 4,
							fontWeight: 'bold',
							color: theme.color.darkgrey,
						}}
					>
						Email subject
					</div>

					<div
						style={{
							display: 'inline-block',
							width: 'auto',
							// width: '100%',
							background: theme.color.bgGrey,
							padding: '4px 10px',
							// border: '1px solid #eee',
							borderRadius: 4,
							color: theme.color.darkgrey,
							// background: theme.color.white,
							border: 'none',
							// lineHeight: 1.5,
						}}
					>
						{template.subject}
					</div>

					<div
						style={{
							marginTop: 10,
						}}
					>
						<div
							style={{
								paddingLeft: 10,
								marginBottom: 4,
								fontWeight: 'bold',
								color: theme.color.darkgrey,
							}}
						>
							Email body
						</div>
					</div>
					<div
						style={{
							// width: '100%',
							background: theme.color.bgGrey,
							padding: '4px 10px',
							// border: '1px solid #eee',
							borderRadius: 4,
							color: theme.color.darkgrey,
							// background: theme.color.white,
							border: 'none',
						}}
						dangerouslySetInnerHTML={{ __html: template.content }}
					/>
				</Space>
			</div>

			<Space
				style={{
					position: 'absolute',
					left: 0,
					bottom: 0,
					background: 'white',
					borderTop: '1px solid #eee',
					width: '100%',
					height: 74,
					// boxShadow: '0 -4px 10px -4px rgba(0,0,0,.2)',
				}}
			>
				<Space
					style={{
						width: 'calc(100% - 40px)',
						justifyContent: 'space-between',
						alignItems: 'center',
						position: 'absolute',
						left: 20,
						bottom: 20,
					}}
				>
					<Space
						style={{
							alignItems: 'center',
							position: 'relative',
						}}
					>
						<MessageTemplateUserAvatar template={template} />
						<div
							style={{
								fontSize: 10,
								color: theme.color.grey,
							}}
						>
							{moment(template.createdAt).format('MMM Do YYYY')}<br />
							{moment(template.createdAt).format('HH:mm')}
						</div>
					</Space>
					<Space>
						{(currentUserModel.isMe(template.user) || (isAdmin && isStaffView))
							&& <Tooltip title={<>Edit this template</>}>
								<Button
									type='primary'
									ghost
									onClick={() => onEditTemplate(template)}
									style={{
										width: 32, height: 32, borderRadius: 32,
									}}
									icon={<FaPen style={{ marginBottom: -1, fontSize: 14 }} />}
								 />
							</Tooltip>
						}
						<Tooltip title={<>Duplicate this template and customize it</>}>
							<Button
								type='primary'
								ghost
								onClick={() => onCopyTemplate(template)}
								style={{
									width: 32, height: 32, borderRadius: 32,
								}}
								icon={<FaCopy style={{ marginBottom: -1, fontSize: 14 }} />}
							/>
						</Tooltip>
						{(currentUserModel.isMe(template.user) || (isAdmin && isStaffView))
							// <Tooltip title={<>Delete this template</>}>
							&& <ActionButton
								danger
								ghost
								type='primary'
								tooltipTitle="Delete template"
								icon={<FaTrash style={{ marginBottom: -1, fontSize: 14, marginLeft: -7 }} />}
								title="Are you sure you want to delete this template?"
								okText="Yes"
								cancelText="No"
								onConfirm={() => onDeleteTemplate(template)}
								styles={{
									width: 32, height: 32, borderRadius: 32,
								}}
								withConfirm
							/>
						}

					</Space>
				</Space>

			</Space>

			{currentUserModel.isMe(template.user)
				&& <CornerIcon
					color={theme.color.darkturquoise}
					Icon={FaCrown}
					tooltip={<>Personal template</>}
				/>
			}
			{template.isMasterTemplate
				&& <CornerIcon
					color={theme.color.fitYellow}
					Icon={FaStar}
					tooltip={<>Master template by 50inTech</>}
				/>
			}

		</GridCardBase>
	);
};

export default MessageTemplateGridCard;
