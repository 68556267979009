import { useEffect, useState } from 'react';
import { ENGLISH_LANG } from '../../../../constants/constant';
import { useAuthContext } from '../../../../hooks/useAuthContext';
import DataFetcherProvider from '../../../app/data/DataFetcherProvider';
import Grid from '../../../app/grid/Grid';
import ArticleImage from '../../../staff/content/articles/ArticleImage';
import CompanyFieldNotProvided from '../shared/CompanyFieldNotProvided';
import CompanyFieldNotProvidedLocked from '../shared/CompanyFieldNotProvidedLocked';
import { documentId } from '../../../../utils/common';

const CompanyStoriesDisplay = (
	{
		company,
		lang = ENGLISH_LANG,
		isStaffLocked = false,
	},
) => {
	const className = 'CompanyStoriesDisplay';

	const { isStaff, isStaffView } = useAuthContext();
	const [content, setContent] = useState(<CompanyFieldNotProvided />);

	const field = 'articles';

	const [isRefresh, setIsRefresh] = useState(0);

	useEffect(() => {
		setIsRefresh((p) => p + 1);
	}, [company]);

	useEffect(() => {
		const value = company?.getTranslationFieldFallback?.(field, lang);

		if (value?.length > 0) {
			setContent(
				<DataFetcherProvider
					path={`company/${documentId(company)}/stories`}
					isRefresh={isRefresh}
					limit={1000000}
				>
					<Grid
						columns={1}
						cardRender={
							(article) => (
								<ArticleImage
									article={article}
									size={340}
									className='article-image-block'
									style={{
										borderRadius: 6,
										overflow: 'hidden',
									}}
								/>
							)}
					/>
				</DataFetcherProvider>,
			);
			return;
		}
		if (isStaffLocked && !(isStaff && isStaffView)) {
			setContent(
				<CompanyFieldNotProvidedLocked>
					Our team will add your Diversity Stories
					as soon as they are available
				</CompanyFieldNotProvidedLocked>,
			);
			return;
		}
		setContent(<CompanyFieldNotProvided />);
	}, [company, isStaffLocked, isStaff, isStaffView]);

	return (
		<>
			<h2>Diversity Stories</h2>
			{content}
		</>
	);
};

export default CompanyStoriesDisplay;
