import CompanySettingsFormBase from './CompanySettingsFormBase';
import { useEffect, useState } from 'react';
import FormBoxTitle from '../../app/form/FormBoxTitle';
import FormItem from '../form/FormItem';
import {
	Button, Col, Divider, Form, Row, Space,
} from 'antd';
import FormSubmitButton from '../../app/form/FormSubmitButton';
import BlockStaff from '../../app/blocker/BlockStaff';
import CompanyModel from '../../../models/CompanyModel';
import { GENDERSCORE_EMPTY_ICON_IMG, GENDERSCORE_LABEL } from '../../../constants/constant';
import CompanyPlanGenderScoreSurveyFormItem from '../companyPlan/genderscore/CompanyPlanGenderScoreSurveyFormItem';
import CompanyPlanGenderScoreIsEnabledFormItem, { companyPlanGenderScoreIsEnabledPath } from '../companyPlan/genderscore/CompanyPlanGenderScoreIsEnabledFormItem';
import { innerObjPropExtractor } from '../../../constants/property/property-extractor';
import { innerObjPropMapper } from '../../../constants/property/property-mapper';
import CompanyPlanGenderScoreOwnerIdFormItem, { companyPlanGenderScoreOwnerIdPath } from '../companyPlan/genderscore/CompanyPlanGenderScoreOwnerIdFormItem';
import CompanyPlanGenderScoreDatesFormItem, { companyPlanGenderScoreExpirationDatePath, companyPlanGenderScorePurchaseDatePath } from '../companyPlan/genderscore/CompanyPlanGenderScoreDatesFormItem';
import dayjs from 'dayjs';
import CompanyPlanGenderScoreSurveyIsEnabledFormItem, { companyPlanGenderScoreSurveyIsEnabledPath } from '../companyPlan/genderscore/CompanyPlanGenderScoreSurveyIsEnabledFormItem';
import { useTheme } from '@emotion/react';
import CompanyPlanGenderScoreBadgeIsPublishedFormItem, { companyPlanGenderScoreBadgeIsPublishedPath } from '../companyPlan/genderscore/CompanyPlanGenderScoreBadgeIsPublishedFormItem';
import {
	FaArrowLeft, FaChevronCircleRight, FaChevronRight, FaCogs, FaEye, FaSync,
} from 'react-icons/fa';

const CompanySettingsGenderScoreMenuButton = ({
	color,
	title,
	icon,
	onClick,
}) => <Space
	className='menu-button'
	style={{
		background: color,
	}}
	onClick={onClick}
	align='center'
>
	<div style={{
		padding: 10,
		fontSize: 24,
		marginTop: 5,
	}}>{icon}</div>
	<div style={{
		fontWeight: 600,
		fontSize: 14,
		marginLeft: 10,
	}}>{title}</div>
	<div style={{
		position: 'absolute',
		right: 120,
		marginTop: -10,
	}}><FaChevronRight style={{
			fontSize: 18,
		}}/></div>
</Space>;

const CompanySettingsGenderScoreFormDivider = ({
	theme,
	title,
}) => <Divider style={{
	marginBottom: 15,
	marginTop: 5,
}}>
	<span style={{
		fontSize: 13,
		color: theme.color.darkgrey,
		fontWeight: 600,
	}}>
		{title}
	</span></Divider>;
const CompanySettingsGenderScoreForm = (
	{
		company,
		onSuccess,
		labelCol = { span: 10 },
		wrapperCol = { span: 14 },
		children,
		...props
	},
) => {
	const className = 'CompanySettingsGenderScoreForm';
	const theme = useTheme();

	const companyModel = new CompanyModel(company);

	const [form] = Form.useForm();
	const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
	const [isRenewal, setIsRenewal] = useState(false);
	const [showAll, setShowAll] = useState(true);
	const [isMenu, setIsMenu] = useState(true);
	const [daysToExpDate, setDaysToExpDate] = useState(companyModel.getGenderScoreDaysToExpire());
	const [menuOptions, setMenuOptions] = useState({
		isMenu: companyModel.isGenderScoreEnabled(),
		isRenewal: false,
		isInitial: false,
		showAll: !companyModel.isGenderScoreEnabled(),
	});
	const [
		isGenderScoreSurveyFieldActivated,
		setIsGenderScoreSurveyFieldActivated,
	] = useState(companyModel.isGenderScoreEnabled());

	let companyFormValues = innerObjPropMapper(companyPlanGenderScoreIsEnabledPath)(
		company,
		companyModel.isGenderScoreEnabled(),
	);
	companyFormValues = innerObjPropMapper(companyPlanGenderScoreBadgeIsPublishedPath)(
		company,
		companyModel.isGenderScoreBadgeStaffPublished(),
	);
	// Format dates before passing them to the form items
	if (innerObjPropExtractor(companyPlanGenderScorePurchaseDatePath)(company)) {
		companyFormValues = innerObjPropMapper(companyPlanGenderScorePurchaseDatePath)(
			companyFormValues,
			dayjs(innerObjPropExtractor(companyPlanGenderScorePurchaseDatePath)(company)),
		);
	}
	if (innerObjPropExtractor(companyPlanGenderScoreExpirationDatePath)(company)) {
		companyFormValues = innerObjPropMapper(companyPlanGenderScoreExpirationDatePath)(
			companyFormValues,
			dayjs(innerObjPropExtractor(companyPlanGenderScoreExpirationDatePath)(company)),
		);
	}
	const onDatesChange = () => {
		const expirationDate = form.getFieldValue(companyPlanGenderScoreExpirationDatePath);
		setDaysToExpDate(Math.trunc((
			new Date(expirationDate).setHours(0, 0, 0, 0) - new Date().setHours(0, 0, 0, 0)
		) / 1000 / 60 / 60 / 24));
		// We changed a field, force validation
		// This prevents the side effects for changing the expiration date with the purchase date
		form.validateFields();
	};
	useEffect(() => {
		// Force disabled fields to default values
		if (daysToExpDate < 45) {
			form.setFieldValue(
				companyPlanGenderScoreSurveyIsEnabledPath,
				false,
			);
		}
	}, [daysToExpDate]);

	const [initialValues, setInitialValues] = useState(companyFormValues);
	let subtitle = <>What do you want to do?</>;
	if (!menuOptions.isMenu) {
		if (menuOptions.isRenewal) {
			subtitle = <>Update the configuration for the {GENDERSCORE_LABEL} renewal</>;
		} else if (menuOptions.isInitial) {
			subtitle = <>Set the initial configuration for the {GENDERSCORE_LABEL} Survey</>;
		} else {
			subtitle = <>Select the {GENDERSCORE_LABEL} settings for this company</>;
		}
	}
	return (
		<BlockStaff
			withBorder={false}
			tagStyle={{
				top: 5,
				left: 0,
				opacity: 1,
			}}
			id='genderscore-settings'
		>
			<FormBoxTitle
				title={(
					<div
						style={{
							display: 'flex',
							alignItems: 'center',
						}}
					>
						{GENDERSCORE_EMPTY_ICON_IMG()}
						&nbsp;
						{GENDERSCORE_LABEL}
					</div>
				)}
				details={subtitle}
			/>

			{children}

			{menuOptions?.isMenu
				? <Space direction='vertical'>
					<CompanySettingsGenderScoreMenuButton
						onClick={() => setMenuOptions({
							isMenu: false, isRenewal: false, isInitial: true, showAll: false,
						})}
						title={`${GENDERSCORE_LABEL} Survey initial setup`}
						icon={<FaCogs/>}
						color={theme.color.lilacLightest2}
					/>
					<CompanySettingsGenderScoreMenuButton
						onClick={() => setMenuOptions({
							isMenu: false, isRenewal: true, isInitial: false, showAll: false,
						})}
						title={`Renew ${GENDERSCORE_LABEL}`}
						icon={<FaSync/>}
						color={theme.color.paleblueLight}
					/>
					<CompanySettingsGenderScoreMenuButton
						onClick={() => setMenuOptions({
							isMenu: false, isRenewal: false, isInitial: false, showAll: true,
						})}
						title={`Show all ${GENDERSCORE_LABEL} settings`}
						icon={<FaEye/>}
						color={theme.color.orangeLightest}
					/>
				</Space>
				: <CompanySettingsFormBase
					form={form}
					formName='company-settings-genderscore-form'
					company={initialValues}
					labelCol={labelCol}
					wrapperCol={wrapperCol}
					labelAlign='left'
					layout='horizontal'
					setIsSubmitDisabled={setIsSubmitDisabled}
					onSuccess={onSuccess}
					hiddenValues={{
						forceRecompute: false,
						shouldUpdateTags: false,
						shouldUpdateInducedTags: false,
						...(menuOptions?.showAll || menuOptions?.isInitial)
							? innerObjPropMapper(companyPlanGenderScoreOwnerIdPath)({}, null)
							: {},
					}}
					onFormValuesChanged={(values) => {
						const gsEnabled = innerObjPropExtractor(companyPlanGenderScoreIsEnabledPath)(values);
						if ([true, false].includes(gsEnabled)) setIsGenderScoreSurveyFieldActivated(gsEnabled);
					}}
					style={{
						overflow: 'auto',
					}}
				>
					{companyModel?.isGenderScoreEnabled() && <div style={{ marginBottom: 20 }}>
						<a onClick={() => setMenuOptions({ ...menuOptions, isMenu: true })}>
							<FaArrowLeft style={{ marginBottom: -2, marginRight: 5 }}/>
							<span>Back to Menu</span>
						</a>
					</div>}

					{menuOptions?.showAll && <CompanyPlanGenderScoreIsEnabledFormItem
						disabled={
						// Disable modification for GS plans
							companyModel.isGenderScoreCompanyPlan()
						}
					/>}

					{isGenderScoreSurveyFieldActivated && (
						<>
							{(menuOptions?.showAll || menuOptions?.isRenewal || menuOptions?.isInitial) && (
								<CompanyPlanGenderScoreDatesFormItem
									required={true}
									form={form}
									onChange={onDatesChange}
								/>
							)}
							{menuOptions?.showAll && (
								<CompanySettingsGenderScoreFormDivider
									title={`${GENDERSCORE_LABEL} Survey`}
									theme={theme}/>
							)}
							{(menuOptions?.showAll || menuOptions?.isInitial) && (
								<CompanyPlanGenderScoreSurveyFormItem required={true} />
							)}
							{(menuOptions?.showAll || menuOptions?.isInitial) && (
								<CompanyPlanGenderScoreOwnerIdFormItem
									company={initialValues}
								/>
							)}

							{(menuOptions?.showAll || menuOptions?.isRenewal || menuOptions?.isInitial) && (
								<CompanyPlanGenderScoreSurveyIsEnabledFormItem
									initialValue={innerObjPropExtractor(companyPlanGenderScoreSurveyIsEnabledPath)(initialValues)
									}
									daysToExpDate={daysToExpDate}
								/>
							)}
							{menuOptions?.showAll && (
								<CompanySettingsGenderScoreFormDivider
									title={`${GENDERSCORE_LABEL} Results`}
									theme={theme}/>
							)}
							{menuOptions?.showAll && (
								<CompanyPlanGenderScoreBadgeIsPublishedFormItem
									initialValue={
										innerObjPropExtractor(companyPlanGenderScoreBadgeIsPublishedPath)(initialValues)
									}
									noResults={!companyModel?.isGenderScoreFilled()}/>
							)}
						</>
					)}

					<FormItem
						label={<>&nbsp;</>}
						labelCol={{ span: 5 }}
						style={{ marginBottom: 0, marginTop: 20 }}
					>
						<FormSubmitButton
							disabled={isSubmitDisabled}
							style={{ width: '100%' }}
						>
						Save {GENDERSCORE_LABEL} settings
						</FormSubmitButton>
					</FormItem>

				</CompanySettingsFormBase>
			}
		</BlockStaff>
	);
};

export default CompanySettingsGenderScoreForm;
