/** @jsxImportSource @emotion/react */

import { Button, Space } from 'antd';
import { ENGLISH_LANG, FRENCH_LANG } from '../../../../../constants/constant';

function SelectLocalContent({ lang, setLang, styles }) {
	return (
		<Space
			direction="horizontal"
			css={{ justifyContent: 'center', width: '100%' }}
		>
			<Button
				css={[styles.button, lang === ENGLISH_LANG ? styles.activeButton : {}]}
				onClick={() => setLang(ENGLISH_LANG)}
			>
        🇬🇧 English version
			</Button>
			<Button
				css={[styles.button, lang === FRENCH_LANG ? styles.activeButton : {}]}
				onClick={() => setLang(FRENCH_LANG)}

			>
        🇫🇷 French version
			</Button>
		</Space>
	);
}

export default SelectLocalContent;
