import CompanyLogo from './CompanyLogo';
import ListLimiter from './ListLimiter';

const CompanyLogoList = (
	{
		companies = [],
		size = 36,
		overflow = -12,
		wrap = true,
		limit = 3,
		withItemTooltip = false,
		withMoreTooltip = false,
		...props
	},
) => {
	return (
		<ListLimiter
			data={companies}
			size={size}
			overflow={overflow}
			wrap={wrap}
			limit={limit}
			withItemTooltip={withItemTooltip}
			withMoreTooltip={withMoreTooltip}
			{...props}
			builder={({ data }) => <CompanyLogo
				company={data}
				size={size}
				withTooltip={withItemTooltip}
				style={{ marginBottom: 0 }}
			/>}
		/>
	);
};

export default CompanyLogoList;
