import { Tooltip } from 'antd';
import { ThunderboltOutlined } from '@ant-design/icons';
import { findActionComplexity } from './action-plan';

const CompanyActionComplexityTag = ({
	action,
	complexity,
	...props
}) => {
	const className = 'CompanyActionComplexityTag';

	const complexityOption = findActionComplexity(action?.complexity || complexity);

	if (!complexityOption) return null;

	return (
		<Tooltip title={complexityOption?.label}>
			<ThunderboltOutlined /> {complexityOption?.tagLabel}
		</Tooltip>
	);
};

export default CompanyActionComplexityTag;
