import moment from 'moment';
import {
	Modal, Select, Space, Tooltip,
} from 'antd';
import { useTheme } from '@emotion/react';
import Table from '../../../app/table/Table';
import TableColumns from '../../../app/table/TableColumns';
import { updateOrCreateArticle } from '../../../../actions/article';
import { replaceFromNow } from '../../../../utils/common';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import UserSelect from '../../../user/UserSelect';
import { LocalDebug } from '../../../../utils/LocalDebug';
import UserStub from '../../../user/stub/UserStub';
import UserService from '../../../../services/user';
import { getCompanySelected } from '../../../../reducers/app';

const ACTIONS = {
	VISITOR: 'visitor',
	SESSION: 'session',
	CLICK: 'click',
	DISPLAY: 'display',
	SUBMIT: 'submit',
	SUCCESS: 'success',
	ERROR: 'error',
	NAVIGATE: 'navigate',
	BACK: 'back',
};

const ACTION_PROPS = {
	[ACTIONS.VISITOR]: { label: 'New visitor', emoji: '🆕', slackEmoji: ':new:' },
	[ACTIONS.SESSION]: { label: 'New session', emoji: '🆕', slackEmoji: ':new:' },
	[ACTIONS.CLICK]: { label: 'Click', emoji: '💥', slackEmoji: ':boom:' },
	[ACTIONS.DISPLAY]: { label: 'Page view', emoji: '👓', slackEmoji: ':eyeglasses:' },
	[ACTIONS.SUBMIT]: { label: 'API submit', emoji: '📩', slackEmoji: ':envelope_with_arrow:' },
	[ACTIONS.SUCCESS]: { label: 'API success', emoji: '✅', slackEmoji: ':white_check_mark:' },
	[ACTIONS.ERROR]: { label: 'Error', emoji: '🚨', slackEmoji: ':rotating_light:' },
	[ACTIONS.NAVIGATE]: { label: 'Navigate', emoji: '🧭', slackEmoji: ':navigate:' },
	[ACTIONS.BACK]: { label: 'Back', emoji: '🔙', slackEmoji: ':back:' },
};

const AnalyticsLogTable = (
	{
		onDataLoaded,
		path = '/analytics/log',
		filterOptions = {},
		isRefresh = 0,
		onSearchDataColumn,
		onToggleIsActive,
		isEditor,
		...props
	},
) => {
	const className = 'AnalyticsLogTable';
	const theme = useTheme();
	const dispatch = useDispatch();
	const company = useSelector(getCompanySelected);

	const [isRefreshDataLayout, setIsRefreshDataLayout] = useState(0);
	const [selectedActions, setSelectedActions] = useState([]);
	const [filterTableOptions, setFilterTableOptions] = useState(filterOptions);
	const [userId, setUserId] = useState(null);
	// const user = null;

	const columns = [
		TableColumns.jsonColumn(theme),
		TableColumns.userStubColumn(theme, {
			dataIndex: 'user',
			avatarSize: 0,
			withProfession: false,
			withLocation: false,
			sorter: false,
			...TableColumns.columnFixedWidth(160),
		}),
		{
			title: 'Action',
			dataIndex: 'action',
			key: 'action',
			align: 'center',
			sorter: false,
			render: (v, r) => <Tooltip title={ACTION_PROPS[v].label}>
				<span style={{ fontSize: 20 }}>
					{ACTION_PROPS[v].emoji}
				</span>
			</Tooltip>,
			...TableColumns.columnFixedWidth(50),
		},
		{
			title: 'Category',
			dataIndex: 'category',
			key: 'category',
			sorter: false,
			...TableColumns.columnMinWidth(180),
		},
		{
			title: 'Label',
			dataIndex: 'label',
			key: 'label',
			sorter: false,
			...TableColumns.columnMinWidth(180),
		},
		{
			title: 'Value',
			dataIndex: 'value',
			sorter: false,
			render: (value, row) => {
				return (value && value !== '{}' && (
					<Space wrap={true}>
						{TableColumns.jsonColumn(theme, { collapsed: 3 }).render(JSON.parse(value || '{}'))}
						{value?.length > 20 ? `${value?.slice(0, 20)}...` : value}
					</Space>
				));
			},
			...TableColumns.columnMinWidth(180),
		},
		// TableColumns.jsonColumn(theme, {
		//         title: 'Value',
		//         value: (r) => JSON.parse(r.value || '{}'), collapsed: 1
		//     }
		// ),
		// { title: 'Created at', dataIndex: 'createdAt' },
		{
			title: 'Created',
			dataIndex: 'createdAt',
			align: 'center',
			removeInColumnFilter: true,
			render: (v, r) => (
				<Space
					direction='vertical'
					size={-3}
				>
					<div style={{ fontSize: 11, lineHeight: 1.5 }}>{replaceFromNow(moment(v).fromNow())}</div>
					<div style={{
						fontSize: 9, fontStyle: 'italic', color: theme.color.darkgrey, lineHeight: 1.3,
					}}>
						{moment(v).format('D MMM [\']YY')}
					</div>
					<div style={{
						fontSize: 9, fontStyle: 'italic', color: theme.color.darkgrey, lineHeight: 1.3,
					}}>
						{moment(v).format('HH:mm:ss')}
					</div>
				</Space>
			),
			...TableColumns.columnFixedWidth(100),
		},
		{
			title: <Tooltip title={'User lang'}>User Lang</Tooltip>,
			dataIndex: ['user', 'settings', 'lang'],
			align: 'center',
			sorter: false,
			removeInColumnFilter: true,
			...TableColumns.columnFixedWidth(40),
		},
		{
			title: <Tooltip title={'App lang'}>App Lang</Tooltip>,
			dataIndex: 'lang',
			align: 'center',
			sorter: false,
			removeInColumnFilter: true,
			...TableColumns.columnFixedWidth(40),
		},
		{
			title: 'Visitor ID',
			dataIndex: 'visitorId',
			align: 'center',
			removeInColumnFilter: true,
			sorter: false,
			render: (v) => <code style={{
				fontSize: 10, fontWeight: 400, color: theme.color.darkgrey,
			}}>…{v?.substr(v.length - 3, v.length)}</code>,
			...TableColumns.columnFixedWidth(70),
		},
		{
			title: 'Session ID',
			dataIndex: 'sessionId',
			align: 'center',
			sorter: false,
			removeInColumnFilter: true,
			render: (v) => <code style={{
				fontSize: 10, fontWeight: 400, color: theme.color.darkgrey,
			}}>…{v?.substr(v.length - 3, v.length)}</code>,
			...TableColumns.columnFixedWidth(70),
		},
		// TableColumns.createdAtColumn(theme),
		// TableColumns.updatedAtColumn(theme),
	];

	const onSelectActionChanged = (values) => {
		setSelectedActions(values);
		setFilterTableOptions((p) => ({ ...p, action: values }));
	};

	const onUserChange = (value) => {
		LocalDebug.logInfo({ className, method: 'onUserChange' }, { value });
		setUserId(value);
	};
	// LocalDebug.logInfo({ className });

	return (
		<Space direction='vertical'>
			<Space direction='horizontal'>
				<UserSelect
					// onFindUsers={handleFetchManagers}
					placeholder='Select a user'
					style={{ display: 'block', width: 400 }}
					defaultOptions={[]}
					labelizer={(user) => (
						<UserStub
							user={user}
							avatarSize={20}
							withLocation={false}
							withProfession={false}
							withCompanyLogo={false}
							withIsUnviewed={false}
							withCoachedTag={false}
							withResumeTag={false}
						/>
					)}
					onChange={onUserChange}
					// notUserIds={notUserIds}
				/>
				{/* <UserSelect */}
				{/*    // searchOptions={{ searchField: ['slug', 'firstName', 'lastName', 'email'] }} */}
				{/*    placeholder='User' */}
				{/*    style={{ width: '100%' }} */}
				{/*    // onChange={onUserChange} */}
				{/* /> */}
				<b>Filter by actions: </b>
				<Select mode='multiple'
					onChange={onSelectActionChanged} value={selectedActions}
					style={{ minWidth: 200 }}
				>
					{Object.entries(ACTION_PROPS).map(([key, value]) => {
						return <Select.Option key={key}>{value.emoji} {value.label}</Select.Option>;
					})}
				</Select>
			</Space>
			{userId && (
				<Table
					path={path}
					onDataLoaded={onDataLoaded}
					columns={columns}
					filterOptions={{
						...filterTableOptions,
						userId,
						createdAfter: moment().add(-1, 'month'),
					}}
					isRefresh={isRefreshDataLayout}
					onSearchDataColumn={onSearchDataColumn}
					showTotal={(total) => `Total ${total} items`}
					limit={10}
					rowClassName={(row, index) => (row.is50Content === false ? 'rowDisabled' : '')}
					withDynamicSearch
					{...props}
				/>
			)}
		</Space>
	);
};

export default AnalyticsLogTable;
