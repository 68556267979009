import { Tooltip } from 'antd';
import { FaExternalLinkAlt } from 'react-icons/fa';
import Link from '../../app/Link';

const UserLinkCoaching = (
	{
		user,
		children,
		...props
	},
) => {
	if (!user) {
		return null;
	}

	const path = `/user/${user.slug}/coaching`;

	return (
		<Tooltip title="Open this user's coaching">
			<Link
				to={path}
				style={{
					fontSize: 11,
					...props?.style,
				}}
			>
				{children || <><FaExternalLinkAlt style={{ marginBottom: -2 }}/> Coaching</>}
			</Link>
		</Tooltip>
	);
};

export default UserLinkCoaching;
