import EMPLOYMENT_VISA from '../../property/employment-visa';
import { innerObjPropExtractor } from '../../property/property-extractor';
import { innerObjPropMapper } from '../../property/property-mapper';

const USER_PROPERTY_JOB_SEARCH_EMPLOYMENT_VISA_KEY = 'job-search-employment-visa';

const USER_PROPERTY_JOB_SEARCH_EMPLOYMENT_VISA_MULTI = true;
const USER_PROPERTY_JOB_SEARCH_EMPLOYMENT_VISA_FREE = false;
const USER_PROPERTY_JOB_SEARCH_EMPLOYMENT_VISA_MAX = 4;

const USER_PROPERTY_JOB_SEARCH_EMPLOYMENT_VISA_FIELD = 'jobSearch.employmentVisa';
const USER_PROPERTY_JOB_SEARCH_EMPLOYMENT_VISA_MAPPER = innerObjPropMapper(USER_PROPERTY_JOB_SEARCH_EMPLOYMENT_VISA_FIELD);
const USER_PROPERTY_JOB_SEARCH_EMPLOYMENT_VISA_EXTRACTOR = innerObjPropExtractor(USER_PROPERTY_JOB_SEARCH_EMPLOYMENT_VISA_FIELD);

export default {
	...EMPLOYMENT_VISA,
	key: USER_PROPERTY_JOB_SEARCH_EMPLOYMENT_VISA_KEY,
	USER_PROPERTY_JOB_SEARCH_EMPLOYMENT_VISA_KEY,
	free: USER_PROPERTY_JOB_SEARCH_EMPLOYMENT_VISA_FREE,
	USER_PROPERTY_JOB_SEARCH_EMPLOYMENT_VISA_FREE,
	multi: USER_PROPERTY_JOB_SEARCH_EMPLOYMENT_VISA_MULTI,
	USER_PROPERTY_JOB_SEARCH_EMPLOYMENT_VISA_MULTI,
	max: USER_PROPERTY_JOB_SEARCH_EMPLOYMENT_VISA_MAX,
	USER_PROPERTY_JOB_SEARCH_EMPLOYMENT_VISA_MAX,
	mapper: USER_PROPERTY_JOB_SEARCH_EMPLOYMENT_VISA_MAPPER,
	USER_PROPERTY_JOB_SEARCH_EMPLOYMENT_VISA_MAPPER,
	extractor: USER_PROPERTY_JOB_SEARCH_EMPLOYMENT_VISA_EXTRACTOR,
	USER_PROPERTY_JOB_SEARCH_EMPLOYMENT_VISA_EXTRACTOR,
	field: USER_PROPERTY_JOB_SEARCH_EMPLOYMENT_VISA_FIELD,
	USER_PROPERTY_JOB_SEARCH_EMPLOYMENT_VISA_FIELD,
};
