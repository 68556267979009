import adminService from '../services/admin';
import * as ACTION_TYPES from './types';
import moment from 'moment';
import { actionWithLoader, basicActionBuilder } from './utils';
import { LocalDebug, localDebug } from '../utils/LocalDebug';
import { Divider, message } from 'antd';
import { CAMPAIGN_EMAIL_FILTERS_LOADED } from './types';
import { BOOLEAN_YES_ICON } from '../constants/constant';
import copy from 'clipboard-copy';
import {
	arrayToObjectListByProp, documentId, relabelAtsProvider, sortOn,
} from '../utils/common';
import ReactJsonDebug from '../components/app/debug/ReactJsonDebug';
import ReactJson from 'react-json-view';

const className = 'actions.admin';

export const getCampaignEmailFilters = () => {
	return async (dispatch) => {
		const { data } = await adminService.getCampaignEmailFilters();
		if (data.items) {
			dispatch({
				type: CAMPAIGN_EMAIL_FILTERS_LOADED,
				campaignEmailFilters: data.items,
			});
		}
	};
};

export const cleanGeolocations = () => basicActionBuilder({
	command: adminService.cleanGeolocations, messageTitle: '[Admin] Clean geolocations',
});
export const createGeolocations = () => basicActionBuilder({
	command: adminService.createGeolocations, messageTitle: '[Admin] Create geolocations',
});

export const getPeriodStatsLists = () => basicActionBuilder({
	command: adminService.getPeriodStatsLists, messageTitle: '[Admin] Get period stats lists',
});
export const getPeriodStats = () => basicActionBuilder({
	command: adminService.getPeriodStats, messageTitle: '[Admin] Get period stats',
});
export const computePeriodStatsBypassStorage = () => basicActionBuilder({
	command: adminService.computePeriodStatsBypassStorage,
	messageTitle: '[Admin] Compute period stats (with storage bypass)',
});
export const computePeriodStats = () => basicActionBuilder({
	command: adminService.computePeriodStats, messageTitle: '[Admin] Compute period stats',
});

export const invalidateTags = () => basicActionBuilder({
	command: adminService.invalidateTags,
	messageTitle: '[Admin] invalidateTags',
});

export const resetTags = () => basicActionBuilder({
	command: adminService.resetTags,
	messageTitle: '[Admin] resetTags',
});

export const updateTags = () => basicActionBuilder({
	command: adminService.updateTags,
	messageTitle: '[Admin] updateTags',
});

export const updateUserTags = (params) => basicActionBuilder({
	command: async () => adminService.updateUserTags(params),
	messageTitle: '[Admin] updateUserTags',
});

export const updateUserSalaryExpectations = () => basicActionBuilder({
	command: adminService.updateUserSalaryExpectations,
	messageTitle: '[Admin] updateUserSalaryExpectations',
});

export const updateCompanyTags = () => basicActionBuilder({
	command: adminService.updateCompanyTags,
	messageTitle: '[Admin] updateCompanyTags',
});

export const updateJobTags = () => basicActionBuilder({
	command: adminService.updateJobTags,
	messageTitle: '[Admin] updateJobTags',
});

export const updateArticleTags = () => basicActionBuilder({
	command: adminService.updateArticleTags,
	messageTitle: '[Admin] updateArticleTags',
});

export const applyInducedTags = () => basicActionBuilder({
	command: adminService.applyInducedTags,
	messageTitle: '[Admin] applyInducedTags',
});

export const remapHardSkills = () => basicActionBuilder({
	command: adminService.remapHardSkills,
	messageTitle: '[Admin] remapHardSkills',
});

export const remapLegacyCompanyPlans = () => basicActionBuilder({
	command: adminService.remapLegacyCompanyPlans,
	messageTitle: '[Admin] remapLegacyCompanyPlans',
});

export const addUserResumeFiles = () => basicActionBuilder({
	command: adminService.addUserResumeFiles,
	messageTitle: '[Admin] addUserResumeFiles',
});

export const remapCandidateStages = () => basicActionBuilder({
	command: adminService.remapCandidateStages,
	messageTitle: '[Admin] remapCandidateStages',
});

export const remapScrumMasters = () => basicActionBuilder({
	command: adminService.remapScrumMasters,
	messageTitle: '[Admin] remapScrumMasters',
});

export const updateAutoHiddenCompanyMappings = () => basicActionBuilder({
	command: adminService.updateAutoHiddenCompanyMappings,
	messageTitle: '[Admin] updateAutoHiddenCompanyMappings',
});

export const applyUserHiddenCompanies = () => basicActionBuilder({
	command: adminService.applyUserHiddenCompanies,
	messageTitle: '[Admin] applyUserHiddenCompanies',
	resultBuilder: ({ results: data }) => {
		LocalDebug.logInfo({ className, method: 'applyUserHiddenCompanies - resultBuilder' }, data);
		const newlyHidden = data?.map(
			({ company, users }) => {
				const uH = users.filter((u) => u.newlyHidden);
				return uH?.length > 0 ? { company, users: uH } : null;
			},
		).filter((i) => i);

		const msg = <>
			<b>{data?.reduce((p, c) => p + c.users.length, 0)} users hidden for {data?.length} companies
				({data?.reduce((p, c) => p + c.users.filter((u) => u.newlyHidden).length, 0)} new)</b>
			<Divider />
			{newlyHidden?.length > 0 && <>
				<b>{BOOLEAN_YES_ICON} Newly hidden</b>
				<ul>
					{newlyHidden.map(
						({ company, users }, cIndex) => {
							return <li key={cIndex}>
								<b>{company.name}</b>
								<ul>
									{users.map((u, uIndex) => <li key={uIndex}>
										<b>{BOOLEAN_YES_ICON}&nbsp;{u.firstName} {u.lastName}</b>
										, {u.information?.position} @ {u.information?.company} ({u.information?.location?.country})
									</li>)}
								</ul>
							</li>;
						},
					)}
				</ul>
				<Divider />
			</>}

			<b>Hidden users:</b>
			<ul>
				{data?.map(
					({ company, users }, cIndex) => {
						return <li key={cIndex}>
							<b>({users?.length}) {company.name}</b>
							<ul>
								{Object
									.entries(
										arrayToObjectListByProp(users, (u) => u?.information?.company?.trim()?.toLowerCase()),
									)
									.map(([nameOption, values], i) => (
										<li key={i}>
											<b>({values?.length}) <code>"{nameOption}"</code></b>
											<ul>
												{values.map((u, j) => (
													<li key={j}>
														<b>
															{u.newlyHidden ? <>{BOOLEAN_YES_ICON}&nbsp;</> : null}
															{u.firstName} {u.lastName}
														</b>
														, {u.information?.position} @ {u.information?.company} ({u.slug})
													</li>
												))}
											</ul>
										</li>
									))
								}
								{/* {users.map((u, uIndex) => <li key={uIndex}> */}
								{/*	<b> */}
								{/*		{u.newlyHidden ? <>{BOOLEAN_YES_ICON}&nbsp;</> : null} */}
								{/*		{u.firstName} {u.lastName} */}
								{/*	</b> */}
								{/*	, {u.information?.position} @ {u.information?.company} ({u.information?.location?.country}) */}
								{/* </li>)} */}
							</ul>
						</li>;
					},
				)}
			</ul>
		</>;

		return msg;
	},
});

export const getRecruiterInfo = () => basicActionBuilder({
	command: adminService.getRecruiterInfo,
	messageTitle: 'Copy recruiter list',
	resultBuilder: async (data) => {
		LocalDebug.logInfo({ className, method: 'getRecruiterInfo' }, { data });
		const file = [
			'TO GUARANTEE THAT THE LIST IS UP TO DATE, NEVER USE THIS LIST WITHOUT EXPORTING IT FROM THE PRODUCT FIRST : https://admin-staff.50intech.com/staff/tools > Recruiters',
			`Created on ${moment().format('YYYY-MM-DD HH:mm:ss')}`,
			[
				'Email', 'First name', 'Last name', 'Company',
				'Global status',
				'Company admin role?', 'Company editor role?', 'Recruiter role?',
				'Invited by',
				'Company published?',
				'Company publication date',
			].join('\t'),
			...data?.items
				.map((d) => ({ ...d, companyName: d?.company?.name?.toLowerCase() }))
				.filter((d) => d.companyName && d.companyName !== '')
				.sort(sortOn({ key: 'companyName' }))
				.map((d) => {
					const {
						user, company, roles, ...rest
					} = d;
					const [companyRecruiterRole] = roles.filter((r) => r?.role?.label === 'company-recruiter');
					const [companyEditorRole] = roles.filter((r) => r?.role?.label === 'document-editor');
					const [companyAdminRole] = roles.filter((r) => r?.role?.label === 'document-admin');
					const isCompanyRecruiter = companyRecruiterRole ? (companyRecruiterRole.isActive !== false ? 'GRANTED' : 'REVOKED') : '';
					const isCompanyEditor = companyEditorRole ? (companyEditorRole.isActive !== false ? 'GRANTED' : 'REVOKED') : '';
					const isCompanyAdmin = companyAdminRole ? (companyAdminRole.isActive !== false ? 'GRANTED' : 'REVOKED') : '';
					const isActive = [isCompanyRecruiter, isCompanyEditor, isCompanyAdmin].includes('GRANTED') ? 'ACTIVE' : 'DEACTIVATED';
					return [
						user.email, user.firstName, user.lastName, company?.name,
						isActive,
						isCompanyAdmin, isCompanyEditor, isCompanyRecruiter,
						[...new Set(d?.roles?.map((r) => r?.invitedUser).map((u) => [u?.firstName, u?.lastName].filter((i) => i && i?.trim() !== '').join(' ')))].join(', '),
						company.isPublished ? 'PUBLISHED' : 'NOT PUBLISHED',
						...company.publishedAt ? [moment(company.publishedAt).format('YYYY-MM-DD HH:mm:ss')] : [],
						// roles.map(r => r?.role?.label).join(', '),
					].join('\t');
				}),
		].join('\n');

		await copy(file);
		// LocalDebug.logInfo({ className: 'actions/admin', method: 'getRecruiterInfo2' }, file.slice(0, 100));
		// LocalDebug.logInfo({ className: 'actions/admin', method: 'getRecruiterInfo2' }, file.slice(0, 100));
		const msg = <>
			Recruiter list was <b>copied in your clipboard</b>.
			<br />Paste it in this <a target='_blank' rel='noreferrer'
									  href='https://docs.google.com/spreadsheets/d/11P1VUM5MpR_ZragiE7efE6BPeCQeN0KVFS8DFku1VFA/edit#gid=1738279817'>Google
			spreadsheet</a>
			<br />(Be sure to <b>check</b> whether some rows should be deleted if there are less lines than the current
			content!).
			<ReactJson src={data?.items?.[0]} style={{ fontSize: 10, lineHeight: 1.5 }} sortKeys={true} />
		</>;
		return msg;
	},
});

export const encryptAtsApiKeys = () => basicActionBuilder({
	command: adminService.encryptAtsApiKeys, messageTitle: '[Admin] encryptAtsApiKeys',
});

export const addDemoCompany = (ats) => basicActionBuilder({
	command: async () => { return adminService.addDemoCompany(ats); },
	messageTitle: `[Admin] addDemoCompany for '${ats}'`,
});
export const addDemoAccess = (ats, company) => basicActionBuilder({
	command: async () => { return adminService.addDemoAccess(ats, documentId(company)); },
	messageTitle: `[Admin] Add '${relabelAtsProvider(ats)}' ATS access${company ? `for company '${company.name}'` : ''}?`,
});
export const removeDemoAccess = (ats, company) => basicActionBuilder({
	command: async () => { return adminService.removeDemoAccess(ats, documentId(company)); },
	messageTitle: `[Admin] Remove '${relabelAtsProvider(ats)}' ATS access${company ? `for company '${company.name}'` : ''}?`,
});
export const fakeDemoSourcings = (ats) => basicActionBuilder({
	command: async () => { return adminService.fakeDemoSourcings(ats); },
	messageTitle: `[Admin] fakeDemoSourcings for '${ats}'`,
});
export const cleanDemoSourcings = (ats) => basicActionBuilder({
	command: async () => { return adminService.cleanDemoSourcings(ats); },
	messageTitle: `[Admin] cleanDemoSourcings for '${ats}'`,
});

export const addTestPlanCompany = (planOption) => basicActionBuilder({
	command: async () => { return adminService.addTestPlanCompany(planOption); },
	messageTitle: `[Admin] addTestPlanCompany for '${planOption?.label}'`,
});
export const addTestPlanRoles = (planOption) => basicActionBuilder({
	command: async () => { return adminService.addTestPlanRoles(planOption); },
	messageTitle: `[Admin] addTestPlanRoles for '${planOption?.label}'`,
});
export const addCompanyTestRole = ({
	company,
	roleId,
	password,
	withEmail,
	withLoginPopup,
}) => basicActionBuilder({
	command: async () => {
		return adminService.addCompanyTestRole({
			companyId: documentId(company),
			roleId,
			password,
			withEmail,
			withLoginPopup,
		});
	},
	messageTitle: `[Admin] addCompanyTestRole '${roleId}' for '${company?.name} (${withEmail ? 'with' : 'without'} email) (${withLoginPopup ? 'with' : 'without'} popup on first login)'`,
});

export const syncAllSourcings = () => basicActionBuilder({
	command: adminService.syncAllSourcings, messageTitle: '[Admin] syncAllSourcings',
});
export const initializeAtsPipelines = () => basicActionBuilder({
	command: adminService.initializeAtsPipeline, messageTitle: '[Admin] initializeAtsPipeline',
});
export const initializeAtsArchiveReasons = () => basicActionBuilder({
	command: adminService.initializeAtsArchiveReasons, messageTitle: '[Admin] initializeAtsArchiveReasons',
});
export const checkAtsPipelineStages = () => basicActionBuilder({
	command: adminService.checkAtsPipelineStages, messageTitle: '[Admin] checkAtsPipelineStages',
});

export const add50inTechSavedSearches = () => basicActionBuilder({
	command: adminService.add50inTechSavedSearches, messageTitle: '[Admin] add50inTechSavedSearches',
});

export const computeSavedSearchesAlerts = () => basicActionBuilder({
	command: adminService.computeSavedSearchesAlerts, messageTitle: '[Admin] computeSavedSearchesAlerts',
});

export const addCoachingCoachedAt = () => basicActionBuilder({
	command: adminService.addCoachingCoachedAt, messageTitle: '[Admin] addCoachingCoachedAt',
});

export const computeFunnels = () => basicActionBuilder({
	command: adminService.computeFunnels, messageTitle: '[Admin] computeFunnels',
});

export const retryFailedUserMailings = () => basicActionBuilder({
	command: adminService.retryFailedUserMailings, messageTitle: '[Admin] retryFailedUserMailings',
});

export const refreshAllUserMailingsStatus = () => basicActionBuilder({
	command: adminService.refreshAllUserMailingsStatus, messageTitle: '[Admin] refreshAllUserMailingsStatus',
});

export const refreshMailjetContactsData = () => basicActionBuilder({
	command: adminService.refreshMailjetContactsData, messageTitle: '[Admin] refreshMailjetContactsData',
});

export const checkMailjetHealth = () => basicActionBuilder({
	command: adminService.checkMailjetHealth, messageTitle: '[Admin] checkMailjetHealth',
});
