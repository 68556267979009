import { message } from 'antd';
import JobService from '../services/jobs';
import {
	ITEMS_LOADED,
	JOBS_LOADED,
	SET_HISTORY,
	SET_JOBS_ID,
	SET_JOB_SELECTED,
	TAGS_JOBS_LOADED,
} from './types';
import { action, actionWithLoader, push } from './utils';
import { localDebug } from '../utils/LocalDebug';
import { documentId } from '../utils/common';

/**
 * load company into redux
 * @param {*} atsProvider
 * @returns
 */

export const loadJobs = (
	{
		companyId,
		search = '',
		sortField = 'createdAt',
		sortOrder = 'descend',
		isSaveCurrentPublish = false,
	},
) => {
	return actionWithLoader(async (dispatch) => {
		try {
			const { data } = await JobService.getAll({
				companyId, search, sortField, sortOrder,
			});
			dispatch({
				type: JOBS_LOADED,
				jobs: data.jobs,
			});

			// // history for publish jobs
			// if (isSaveCurrentPublish) {
			//     const { jobs } = data;
			//     const jobsCompanyFromDb = jobs.length ? jobs[0].company.jobs : [];
			//     const tempPublisedJobs = [];
			//     const tempCatalogJobs = [];
			//
			//     jobs.forEach((job)=> {
			//         if (jobsCompanyFromDb.findIndex((id) => id === documentId(job)) !== -1)
			//         {
			//             tempPublisedJobs.push(documentId(job));
			//         } else {
			//             tempCatalogJobs.push(documentId(job));
			//         }
			//     });
			//
			//     dispatch(setHistory('currentPublished', {
			//         catalog: tempCatalogJobs,
			//         published: tempPublisedJobs
			//     }));
			// }
		} catch (error) {
			localDebug(__filename.split('/').shift(), error);
		}
	});
};

export const getAllTagJobs = () => {
	return async (dispatch) => {
		const { data } = await JobService.getGroupTags();
		if (data.items) {
			dispatch({
				type: TAGS_JOBS_LOADED,
				tagsJobs: data.items,
			});
		}
	};
};

export const getAllJobItems = ({ companyId }) => {
	return async (dispatch) => {
		const { data } = await JobService.getAllItems({ companyId });
		dispatch({
			type: ITEMS_LOADED,
			items: data.items,
		});
	};
};

export const savePublishedJobs = async (companyId, jobIds = []) => {
	try {
		await JobService.published({ companyId, jobIds });
	} catch (error) {
		// localDebug(error);
		// message.error('published with error')
	}
};

export const deleteJobs = async (jobIds = []) => {
	try {
		await JobService.deleteJobs(jobIds);
	} catch (error) {
		localDebug(__filename.split('/').shift(), error);
	}
};

export const fetchJobsByCompany = async (companyId) => {
	try {
		await JobService.fetchJobs({ companyId });
	} catch (error) {
		// utils/LocalDebug(error);
		message.error(error.message);
	}
};

export const updateJobTags = (job) => {
	const key = job.slug;
	message.loading({ content: 'Updating job tags', key, duration: 10 });
	return action(async () => {
		try {
			await JobService.updateJobTags(job);
			message.success({ content: 'Tags updated', key, duration: 1 });
		} catch (error) {
			message.error({ content: error.message, key });
		}
	});
};

export const fetchJob = (job) => {
	const key = job.slug;
	message.loading({ content: 'Fetching job', key, duration: 10 });
	return action(async () => {
		try {
			await JobService.fetchJob(job);
			message.success({ content: 'Job fetched', key, duration: 1 });
		} catch (error) {
			message.error({ content: error.message, key });
		}
	});
};

export const updateOrCreateJob = (data, { successMessage } = { successMessage: '' }) => {
	const key = JSON.stringify(data);
	message.loading({ content: 'Updating job', key, duration: 10 });
	return async () => {
		try {
			const { data: { job } } = await JobService.updateOrCreateJob(data);
			message.success({ content: successMessage || 'Job updated', key, duration: 1 });
			return job;
		} catch (error) {
			message.error({ content: error.message, key });
			return null;
		}
	};
};

export const setHistory = (key, value) => {
	return async (dispatch) => {
		dispatch({
			type: SET_HISTORY,
			history: { key, value },
		});
	};
};

export const goToJob = (jobs) => {
	return async (dispatch) => {
		const jobIds = jobs.map((job) => documentId(job));
		dispatch({
			type: SET_JOBS_ID,
			jobIds,
		});

		push('/jobs');
	};
};

export const loadJob = (jobId) => {
	localDebug('loadJob', { jobId });
	return actionWithLoader(async (dispatch) => {
		try {
			if (!jobId) {
				dispatch({
					type: SET_JOB_SELECTED,
					job: null,
				});
				return;
			}

			// dispatch tag rule selected
			const { data } = await JobService.getJob(jobId);
			dispatch({
				type: SET_JOB_SELECTED,
				job: data.job,
			});
		} catch (error) {
			localDebug(__filename.split('/').shift(), error);
		}
	});
};

export const computeUserMatches = (job) => {
	const key = job.slug;
	message.loading({ content: 'Computing user matches', key, duration: 10 });
	return action(async () => {
		try {
			await JobService.computeUserMatches(documentId(job));
			message.success({ content: 'Matches updated', key, duration: 1 });
		} catch (error) {
			message.error({ content: error.message, key });
		}
	});
};
