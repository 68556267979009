import { useTheme } from '@emotion/react';
import { useContext, useState } from 'react';
import { useSelector } from 'react-redux';
import {
	Badge, Col, Dropdown, Popconfirm, Popover, Row, Tag, Tooltip, message,
} from 'antd';
import {
	FaClone, FaExternalLinkAlt, FaEyeSlash, FaFlag, FaSync, FaTrash,
} from 'react-icons/fa';
import Link from '../../../app/Link';
import Box from '../../../app/box/Box';
import Table from '../../../app/table/Table';
import TableColumns from '../../../app/table/TableColumns';
import { getCompanySelected, getTagsUsers } from '../../../../reducers/app';
import RecruiterService from '../../../../services/recruiter';
import AdminService from '../../../../services/admin';
import {
	documentId, formatDelay, openExternalUrl, sumReducer,
} from '../../../../utils/common';
import UserSavedSearchSummary from '../../../search/UserSavedSearchSummary';
import { UserSavedSearchContext } from '../../../search/UserSavedSearchProvider';
import UserStub from '../../../user/stub/UserStub';
import { messageResultBuilder } from '../../../../actions/utils';
import { BiChevronDown } from 'react-icons/bi';
import { DATE_FORMAT, PATHS } from '../../../../constants/constant';
import config from '../../../../config/config';
import { useAuthContext } from '../../../../hooks/useAuthContext';
import UserSavedSearchBadge from '../../../search/UserSavedSearchBadge';
import DatePreview from '../../../app/table/DatePreview';
import ObjectIdDisplayer from '../../../app/ObjectIdDisplayer';
import moment from 'moment';

const RecruiterSearchesTable = (
	{
		onDataLoaded,
		path = '/recruiter/saved-search',
		filterOptions = {},
		isRefresh = 0,
		withParentColumns,
		onDocumentIdSelected,
		onSearchDataColumn,
		onFilterId,
		...props
	},
) => {
	const className = 'RecruiterSearchesTable';

	const theme = useTheme();
	const { currentUser } = useAuthContext();

	const userTags = useSelector(getTagsUsers);

	const companySelected = useSelector(getCompanySelected);
	const { getSavedSearchFilters } = useContext(UserSavedSearchContext);

	const [isRefreshDataLayout, setIsRefreshDataLayout] = useState(0);

	const handleCloneSearch = async (search) => {
		const { data } = await RecruiterService
			.cloneSavedSearch(documentId(search));
		if (data?.success) {
			message.success(
				<>
					Search was cloned
					{messageResultBuilder(data)}
				</>,
			);
		} else {
			message.error('An error occurred');
		}
		setIsRefreshDataLayout((p) => p + 1);
	};

	const handleOpenSearch = async (search) => {
		openExternalUrl(config.adminRoot + PATHS.DISCOVER({ searchId: documentId(search) }));
	};

	const handleDeleteSearch = async (search) => {
		const { data } = await RecruiterService
			.deleteSavedSearch(documentId(search));
		if (data?.success) {
			message.success(
				<>
					Search was deleted
					{messageResultBuilder(data)}
				</>,
			);
		} else {
			message.error('An error occurred');
		}
		setIsRefreshDataLayout((p) => p + 1);
	};

	const handleComputeAlerts = async (search) => {
		const { data } = await AdminService
			.computeSavedSearchesAlerts({ ids: [documentId(search)] });
		if (data?.success) {
			message.success(
				<>
					Alerts were recomputed
					{messageResultBuilder(data)}
				</>,
			);
		} else {
			message.error('An error occurred');
		}
		setIsRefreshDataLayout((p) => p + 1);
	};

	const handleFakeAlerts = async (search) => {
		const { data } = await AdminService
			.fakeSavedSearchesAlerts({ ids: [documentId(search)] });
		if (data?.success) {
			message.success(
				<>
					Alerts were faked
					{messageResultBuilder(data)}
				</>,
			);
		} else {
			message.error('An error occurred');
		}
		setIsRefreshDataLayout((p) => p + 1);
	};

	const handleClearAlerts = async (search) => {
		const { data } = await AdminService
			.clearSavedSearchesAlerts({ ids: [documentId(search)] });
		if (data?.success) {
			message.success(
				<>
					Alerts were cleared
					{messageResultBuilder(data)}
				</>,
			);
		} else {
			message.error('An error occurred');
		}
		setIsRefreshDataLayout((p) => p + 1);
	};

	const handleDeleteAlerts = async (search) => {
		const { data } = await AdminService
			.deleteSavedSearchesAlerts({ ids: [documentId(search)] });
		if (data?.success) {
			message.success(
				<>
					Alerts were deleted
					{messageResultBuilder(data)}
				</>,
			);
		} else {
			message.error('An error occurred');
		}
		setIsRefreshDataLayout((p) => p + 1);
	};

	const jsonCol = TableColumns.jsonColumn(theme);

	const companyCol = TableColumns.companyLogoColumn(theme, { title: 'Client' });

	const recruiterCol = TableColumns.userAvatarColumn(theme);

	const titleFiltersCol = {
		title: 'Saved search',
		dataIndex: 'title',
		render: (value, row) => {
			const popoverContent = <UserSavedSearchSummary savedSearch={row} />;
			const filters = getSavedSearchFilters(row);
			const categories = filters?.filter?.((item) => item?.category)?.length;
			const values = filters?.map?.((item) => item?.values?.length || 0).reduce(sumReducer, 0);
			const isFilteredId = documentId(row) === filterOptions?.searchId;
			return (
				<Popover placement={'right'} content={popoverContent}>
					<div style={{ fontSize: 14, fontWeight: 'bold' }}>
						{row?.title}
					</div>
					<div>
						<ObjectIdDisplayer
							id={documentId(row)}
							withTooltip={true}
							withSlice={true}
							withFilter={true}
							style={{
								fontSize: 12,
								color: theme.color.grey,
								fontWeight: isFilteredId ? 'bold' : 'default',
								backgroundColor: isFilteredId ? '#91caff' : 'default',
							}}
							onClick={onFilterId}
						/>
					</div>
					<Link>
						<b>{values} filter{values > 1 ? 's' : ''}</b> ({categories} categor{categories > 1 ? 'ies' : 'y'})
					</Link>
				</Popover>
			);
		},
		...TableColumns.columnFixedWidth(230),
	};

	const parentSearchCol = TableColumns.editedFromColumn(theme, {
		title: 'Parent search',
		fieldId: 'parentSavedSearchId',
		fieldDate: 'parentSavedSearchCreatedAt',
		filterOptions,
		onFilterId,
	});
	const originalSearchCol = TableColumns.editedFromColumn(theme, {
		title: 'Original search',
		fieldId: 'originalSavedSearchId',
		fieldDate: 'originalSavedSearchCreatedAt',
		filterOptions,
		onFilterId,
	});

	const recruiterUsageCol = {
		title: 'Recruiter usage',
		dataIndex: ['log', 'searches'],
		render: (value, row) => {
			const sortedLogs = row?.log?.searches
				?.sort((a, b) => (a.createdAt < b.createdAt ? 1 : -1));
			const lastSearchLog = sortedLogs?.[0] || {};
			const lastSearchedAt = formatDelay(lastSearchLog?.createdAt);
			const runsDates = sortedLogs
				?.map((s) => <ul key={s?.createdAt}>{moment(s?.createdAt).format(DATE_FORMAT)}</ul>);
			return (
				<div>
					<div>
						<b>{lastSearchLog?.count || 'n/a'}</b> <span style={{ color: theme.color.grey }}>talents</span>
					</div>
					<div>
						<b>{row?.log?.viewedUsersCount || 'n/a'}</b> <span style={{ color: theme.color.grey }}>viewed</span>
					</div>
					<Tooltip title={<>
						<span>Search was run at:</span>
						<li style={{ fontSize: 10 }}>{runsDates}</li>
					</>} overlayStyle={{ maxHeight: 150, overflow: 'auto' }}>
						<b>{row?.log?.searches?.length || 'n/a'}</b> <span style={{ color: theme.color.grey }}>runs</span>
					</Tooltip>
					<Tooltip title={`Last run at: ${moment(lastSearchLog?.createdAt).format(DATE_FORMAT)}`}>
					&nbsp;<span style={{ color: theme.color.grey }}>
							(Last
						</span>
						&nbsp;
						<b>{lastSearchedAt}</b>)
					</Tooltip>
				</div>
			);
		},
		removeInColumnFilter: true,
		sorter: false,
		...TableColumns.columnFixedWidth(160),
	};

	const sinceLastRunCol = {
		title: <Tooltip
			title={
				<>Current open alerts for new users matching the search and that logged in since the last time the recruiter launched it</>
			}
		>
			Since last run<br/>by recruiter
		</Tooltip>,
		dataIndex: ['log', 'alert'],
		// align: 'center',
		removeInColumnFilter: true,
		sorter: false,
		render: (value) => (
			<>
				<div>
					<UserSavedSearchBadge
						count={value?.newUserIds?.length}
						size={'small'}
						style={{
							fontSize: 12,
							fontWeight: 'bold',
							...value?.newUserIds?.length > 0 ? {} : { background: theme.color.lightgrey },
						}}
						showZero={true}
					/>
					&nbsp;
					<span style={{ color: theme.color.grey }}>
						new talents
					</span>
				</div>
				<div>
					<span style={{ color: theme.color.grey }}>
						Last
					</span>
					&nbsp;
					<b>{formatDelay(value?.updatedAt)}</b>
					{/* &nbsp;
					<span style={{ color: theme.color.grey }}>
						({value?.countUpdates} times)
					</span> */}
				</div>
			</>
		),
		...TableColumns.columnFixedWidth(160),
	};

	const alertsCol = {
		title: <Tooltip
			title={
				<>Current open alerts for new users matching the search</>
			}
		>
			Open alerts
		</Tooltip>,
		dataIndex: ['alerts'],
		removeInColumnFilter: true,
		sorter: false,
		render: (value, row) => (
			<>
				<div>
					<UserSavedSearchBadge
						count={value?.length}
						size={'small'}
						style={{
							fontSize: 12,
							fontWeight: 'bold',
							...value?.length > 0 ? {} : { background: theme.color.lightgrey },
						}}
						showZero={true}
					/>
					&nbsp;
					<span style={{ color: theme.color.grey }}>
						alerts
					</span>
				</div>
				<div>
					<span style={{ color: theme.color.grey }}>
						Last
					</span>
					&nbsp;
					<b>{formatDelay(row?.log?.alert?.updatedAt)}</b>
				</div>
			</>
		),
		...TableColumns.columnFixedWidth(160),
	};

	const alertCol = {
		title: <Tooltip
			title={
				<>Current open alerts for new users matching the search since the last time the recruiter launched it</>
			}
		>
			Alert
		</Tooltip>,
		dataIndex: ['alerts'],
		align: 'center',
		removeInColumnFilter: true,
		sorter: false,
		render: (value) => (
			// <ReactJsonAdmin src={value}/>
			<UserSavedSearchBadge count={value?.length} showZero={false}/>
		),
		...TableColumns.columnFixedWidth(60),
	};

	const pastAlertsCol = {
		title: <Tooltip
			title={<>Past alerts</>}>Past alerts</Tooltip>,
		dataIndex: ['log', 'pastAlerts'],
		align: 'center',
		removeInColumnFilter: true,
		sorter: false,
		render: (value) => (
			<Badge
				count={value?.length}
				showZero={false}
				style={{
					background: theme.color.grey,
					userSelect: 'none',
					fontWeight: 'bold',
				}}
			/>
		),
		...TableColumns.columnFixedWidth(60),
	};

	const actionsCol = {
		title: 'Staff actions',
		render: (value, row) => {
			const ActionMenuItem = ({
				title,
				onConfirm,
				Icon,
				children,
			}) => (
				<Popconfirm
					title={(
						<div style={{ maxWidth: 300 }}>
							<b>Are you sure?</b><br />{title}
						</div>
					)}
					onConfirm={onConfirm}
				>
					<Icon style={{
						marginBottom: -5,
						fontSize: 10,
						border: '1px solid #ddd',
						padding: 4,
						borderRadius: 4,
					}} />
					&nbsp;
					{children}
				</Popconfirm>
			);
			const items = [
				{
					key: 'search',
					type: 'group',
					label: 'Saved Search',
					children: [
						...row?.userId?.toString() === documentId(currentUser)
							? [{
								key: 'search/open',
								label: (
									<ActionMenuItem
										title={'It will open this search in a new tab.'}
										onConfirm={() => { handleOpenSearch(row); }}
										Icon={FaExternalLinkAlt}
									>
									Open search
									</ActionMenuItem>
								),
							},
							{
								key: 'search/delete',
								danger: true,
								label: (
									<ActionMenuItem
										title={'It will delete this saved search.'}
										onConfirm={() => { handleDeleteSearch(row); }}
										Icon={FaTrash}
									>
										Delete search
									</ActionMenuItem>
								),
							},
							]
							: [],
						{
							key: 'search/clone',
							label: (
								<ActionMenuItem
									title={'It will clone this search into your own user\'s saved searches.'}
									onConfirm={() => { handleCloneSearch(row); }}
									Icon={FaClone}
								>
									Clone search
								</ActionMenuItem>
							),
						},
					],
				},
				{
					key: 'alerts',
					type: 'group',
					label: 'Alerts',
					children: [
						{
							key: 'alerts/compute',
							label: (
								<ActionMenuItem
									title={'It will compute alerts for this saved search.'}
									onConfirm={() => { handleComputeAlerts(row); }}
									Icon={FaSync}
								>
									Compute alerts
								</ActionMenuItem>
							),
						},
						{
							key: 'alerts/fake',
							label: (
								<ActionMenuItem
									title={'It will fake alerts for this saved search.'}
									onConfirm={() => { handleFakeAlerts(row); }}
									Icon={FaFlag}
								>
									Fake alerts
								</ActionMenuItem>
							),
						},
						{
							key: 'alerts/clear',
							label: (
								<ActionMenuItem
									title={'It will clear alerts for this saved search.'}
									onConfirm={() => { handleClearAlerts(row); }}
									Icon={FaEyeSlash}
								>
									Clear alerts
								</ActionMenuItem>
							),
						},
						{
							key: 'alerts/delete',
							danger: true,
							label: (
								<ActionMenuItem
									title={'It will delete alerts for this saved search.'}
									onConfirm={() => { handleDeleteAlerts(row); }}
									Icon={FaTrash}
								>
									Delete alerts
								</ActionMenuItem>
							),
						},
					],
				},
			];

			return (
				<Dropdown menu={{ items }}>
					<Link>Actions <BiChevronDown style={{ marginBottom: -2 }}/></Link>
				</Dropdown>
			);
		},
		...TableColumns.columnFixedWidth(120),
	};

	const createdAtCol = TableColumns.createdAtColumn(theme);
	const updatedAtCol = TableColumns.updatedAtColumn(theme);

	const expandable = {
		expandedRowRender: (row) => {
			const newUsers = row?.log?.alert?.newUserIds?.slice(0, 12);
			return (
				<>
					{/* <UserAvatarList users={newUsers} overflow={-4} size={32} limit={7}/> */}
					<Row gutter={[10, 10]}>
						{newUsers?.map((user, i) => (
							<Col key={i} span={8}>
								<Box style={{ background: theme.color.white }}>
									<UserStub user={user} withDropdown={true} />
								</Box>
							</Col>
						))}
					</Row>
				</>
			);
		},
	};

	const rowExpandable = (row) => row?.log?.alert?.newUserIds?.length > 0;

	return (
		<>
			<Table
				path={path}
				onDataLoaded={onDataLoaded}
				expandable={expandable}
				rowExpandable={rowExpandable}
				columns={[
					jsonCol,
					recruiterCol,
					companyCol,
					titleFiltersCol,
					...withParentColumns
						? [parentSearchCol, originalSearchCol]
						: [],
					recruiterUsageCol,
					sinceLastRunCol,
					alertsCol,
					// alertCol,
					// pastAlertsCol,
					actionsCol,
					// updateAlertsButtonCol,
					createdAtCol,
					updatedAtCol,
				]}
				filterOptions={{
					...filterOptions,
					companyIds: documentId(companySelected),
					withAlerts: true,
					withLogs: true,
				}}
				isRefresh={isRefresh + isRefreshDataLayout}
				onSearchDataColumn={onSearchDataColumn}
				showTotal={(total) => `Total ${total} items`}
				limit={10}
				withDynamicSearch={false}
				rowClassName={(row, index) => (!row.isActive ? 'rowDisabled' : '')}
				{...props}
			/>
		</>
	);
};

export default RecruiterSearchesTable;
