const ANSWER_VALUE_SKIPPED = 'ANSWER_VALUE_SKIPPED';
const ANSWER_VALUE_LATER = 'ANSWER_VALUE_LATER';
const ANSWER_STATE_NOT_ANSWERED = 'ANSWER_STATE_NOT_ANSWERED';
const ANSWER_STATE_LATER = 'ANSWER_STATE_LATER';
const ANSWER_STATE_SKIPPED = 'ANSWER_STATE_SKIPPED';
const ANSWER_STATE_ANSWERED = 'ANSWER_STATE_ANSWERED';

const exporter = {
	ANSWER_VALUE_SKIPPED,
	ANSWER_VALUE_LATER,
	ANSWER_STATE_NOT_ANSWERED,
	ANSWER_STATE_LATER,
	ANSWER_STATE_SKIPPED,
	ANSWER_STATE_ANSWERED,
};

export default exporter;
