/** @jsxImportSource @emotion/react */

import { Select, Space } from 'antd';
import {
	documentId,
	formatImage,
	replaceLoggedContent,
	videoUrlParse,
} from '../../../../../../utils/common';
import { useEffect, useState } from 'react';

import LearnCard from './LearnCard';
import LearnDetailContentLogged from './LearnDetailContentLogged';
import LearnHeader from './LearnHeader';
import PageLayout from './PageLayout';
import ReactPlayer from 'react-player';
import { searchUser } from '../../../../../../actions/article';
import { useDebouncedEffect } from '../../../../../../hooks/useDebounceEffect';
import { useDispatch } from 'react-redux';
import { useTheme } from '@emotion/react';
import UserSelect from '../../../../../user/UserSelect';
import UserStub from '../../../../../user/stub/UserStub';
import { Link } from 'react-router-dom';

const classes = (theme) => ({
	container: {
		'padding': '20px 15px',
		'alignItems': 'center',
		'iframe': {
			width: '100%',
			height: '100%',
			paddingTop: 0,
		},
		'.text-danger': {
			color: `${theme.color.black}!important`,
		},
	},
	userAvatar: {
		'width': '100px',
		'minWidth': '100px',
		'maxWidth': '100px',
		'height': '100px',
		'minHeight': '100px',
		'maxHeight': '100px',
		'position': 'relative',
		'marginRight': '15px',
		'background': 'linear-gradient(to left, #0d2b79 20%, #ff635b 80%)',
		'borderRadius': '100px',
		':before': {
			content: '""',
			position: 'absolute',
			top: 0,
			left: 0,
			borderRadius: '100px',
			width: '100px',
			minWidth: '100px',
			maxWidth: '100px',
			height: '100px',
			minHeight: '100px',
			maxHeight: '100px',
			background: 'linear-gradient(to left, #0d2b79 20%, #FF635B 80%)',
			zIndex: 0,
			opacity: 1,
			transition: 'all 0.2s ease-out',
		},
		':after': {
			content: '""',
			position: 'absolute',
			top: '3px',
			left: '3px',
			borderRadius: '100px',
			width: '94px',
			minWidth: '94px',
			maxWidth: '94px',
			height: '94px',
			minHeight: '94px',
			maxHeight: '94px',
			background: theme.color.white,
			zIndex: 1,
			opacity: 1,
			transition: 'all 0.2s ease-out',
		},
	},

	avatar: {
		position: 'relative',
		top: ' 4px',
		left: '4px',
		backgroundColor: 'rgba(0, 0, 0, 0.1)',
		backgroundSize: 'cover',
		backgroundPosition: 'center',
		backgroundRepeat: 'no-repeat',
		width: 'calc(100% - 2px * 2 - 2px * 2)',
		height: 'calc(100% - 2px * 2 - 2px * 2)',
		zIndex: 2,
		transition: 'all 0.2s ease-out',
		borderRadius: '100px',
	},
	userName: {
		fontSize: '16px',
		fontWeight: 600,
		lineHeight: 1.3,
		marginBottom: '4px',
		transition: 'all 0.2s ease-out',
	},
	userProfession: {
		fontSize: '14px',
		overflow: 'visible',
		whiteSpace: 'normal',
		textOverflow: 'unset',
		marginBottom: '4px',
	},
	avatarCompanyGrid: {
		maxWidth: '100%',
		margin: '20px 0',
		padding: 0,
	},
	sizer: {
		'width': '680px',
		'margin': 'auto',
		'fontSize': '18px',
		'.separator': {
			fontSize: 28,
			color: theme.color.blue,
			width: '100%',
			margin: '80px auto 12px',
			paddingTop: 30,
			borderTop: '3px dashed #e7d2ff',
		},
	},
	gridItem: {
		'maxWidth': '100%',
		'margin': '20px 0',
		'padding': 0,
		'backgroundImage': 'linear-gradient(white, rgba(255, 255, 255, 0))',
		'transition': 'background-color 1s',
		'transitionProperty':
			'opacity, transform, box-shadow, background-color !important',
		'transitionDelay': '0.2s, 0.2s, 0s, 0s !important',
		'transitionDuration': '0.2s, 0.2s, 0.2s, 0.2s !important',
		'transitionTimingFunction':
			'ease-out, ease-out, ease-out, ease-out !important',
		'boxSizing': 'content-box',
		'overflow': 'hidden',
		'boxShadow': '0 5px 14px -3px rgb(0 0 0 / 25%)',
		'zIndex': 2,
		':hover': {
			boxShadow: '0 1px 8px -4px rgb(0 0 0 / 40%)',
			backgroundColor: theme.color.white,
		},
	},
});

const LearnDetailContainer = ({ article, lang, onChange }) => {
	const theme = useTheme();
	const styles = classes(theme);
	const topics = ['story', 'event', 'masterclass', 'podcast'];
	const [search, setSearch] = useState('');
	const [users, setUsers] = useState([]);
	const dispatch = useDispatch();
	const Player = () => {
		return <ReactPlayer controls url={videoUrlParse(article.content || '')} />;
	};

	// ------------------------------------------ //
	// ------ handle search Dynamic ------------- //
	// ------------------------------------------ //
	useDebouncedEffect(
		async () => {
			const dynamicSearch = {
				text: search,
				fields: ['firstName', 'lastName'],
			};
			getUsers(dynamicSearch);
		},
		[search],
		500,
	);
	const getUsers = async (params) => {
		const results = await dispatch(searchUser({ params }));
		setUsers(results.items);
	};
	const onSearchUser = (text) => {
		setSearch(text);
	};
	useEffect(() => {
		getUsers();
	}, []);

	if (!article) return null;

	const defaultContent = article.getField('content');

	return (
		<PageLayout
			renderHeader={() => (
				<LearnHeader
					article={article}
					lang={lang}
					styles={styles}
				/>
			)}
			style={{
				'overflow': 'auto',
				'paddingBottom': 40,
				'& .header-content': {
					textAlign: 'left !important',
					alignItems: 'flex-start',
				},
				'.form-label': {
					fontSize: 16,
					lineHeight: 1.3,
					color: `${theme.color.blue}!important`,
					fontWeight: 500,
					padding: '0',
				},
			}}
		>
			{article && (
				<>
					<div css={styles.sizer}>
						<div>
							<img
								css={{ width: '100%' }}
								src={formatImage(
									article.getField('illustration', lang, article.getField('illustration')),
									{
										crop: 'fill',
										width: 1200,
										quality: 'auto:best',
									},
								)}
							/>
						</div>
						<div
							css={{
								'marginTop': '40px',
								'img.media': {
									width: '100%',
								},
							}}
							dangerouslySetInnerHTML={{
								__html: replaceLoggedContent(
									article.getField('content', lang, defaultContent),
								)[0],
							}}
						 />
						{!!videoUrlParse(
							article.getField('content', lang, defaultContent),
						) && (
							<div
								css={{
									iframe: {
										height: '360px',
										paddingTop: 0,
										width: '107% !important',
									},
								}}
							>
								<Player />
							</div>
						)}
						{replaceLoggedContent(
							article.getField('content', lang, defaultContent),
						)[1] && (
							<div
								css={{
									'marginTop': '40px',
									'img.media': {
										width: '100%',
									},
								}}
								dangerouslySetInnerHTML={{
									__html: replaceLoggedContent(
										article.getField('content', lang, defaultContent),
									)[1],
								}}
							 />
						)}
						<LearnDetailContentLogged article={article} />
						<h2 className='separator'>Affichage en carte :</h2>
						<div className='user-row-match'>
							<LearnCard learn={article} lang={lang} />
						</div>
						<h2 className='separator' css={{ textTransform: 'upperCase' }}>
							Administration :
						</h2>
						<Space direction='vertical' css={{ width: '100%!important' }}>
							<Space direction='horizontal'>
								<label className='form-label'>Edit article's topic?</label>
								<Select
									css={{ width: '200px' }}
									defaultValue='story'
									value={article.topic}
									onChange={(e) => onChange('topic', e)}
								>
									{topics.map((value) => (
										<Select.Option
											value={value}
											css={{ textTransform: 'capitalize' }}
										>
											{value}
										</Select.Option>
									))}
								</Select>
							</Space>

							<Space
								direction='horizontal'
								css={{
									'justifyContent': 'space-between',
									'width': '100%!important',
									'.ant-space-item:last-of-type': {
										width: '85%',
									},
									'.ant-select-selector, .ant-select-focus': {
										height: '50px!important',
										paddingTop: 10,
									},
									'.user-infos': {
										lineHeight: '120%!important',
									},
								}}
							>
								<label className='form-label'>Edit author</label>
								<Space direction='vertical' size={0} style={{ minWidth: 400 }}>
									<UserSelect
										onChange={(value) => {
											onChange('userId', value);
										}}
										value={article?.userId}
										defaultOptions={article.user ? [article.user] : []}
										placeholder='User'
										style={{ width: '100%' }}
										labelizer={(user) => (
											<UserStub
												user={user}
												avatarSize={20}
												withLocation={false}
												withProfession={false}
												withCompanyLogo={false}
												withIsUnviewed={false}
												withCoachedTag={false}
												withResumeTag={false}
											/>
										)}
									/>
									<Link
										onClick={() => onChange('userId', '5dd2a711d8ec89003819cc2f')}
										style={{ fontSize: 12 }}
									>
										Set author as 50inTech
									</Link>
								</Space>
								{/* <Select */}
								{/*	css={{ */}
								{/*		width: '100%', */}
								{/*		'.ant-select-selection-placeholder': { */}
								{/*			marginTop: 8, */}
								{/*		}, */}
								{/*	}} */}
								{/*	defaultValue={article.user} */}
								{/*	value={article.userId} */}
								{/*	onKeyUp={(e) => onSearchUser(e.target.value)} */}
								{/*	popupClassName="auto-complete-dropdown" */}
								{/*	tokenSeparators={[',']} */}
								{/*	showSearch */}
								{/*	clearIcon={<FaTimesCircle/>} */}
								{/*	onChange={(value) => onChange('userdId', value)} */}
								{/*	allowClear */}
								{/*	showArrow={false} */}
								{/*	placeholder="change author..." */}
								{/* > */}
								{/*	{users.map((user) => ( */}
								{/*		<Select.Option */}
								{/*			value={documentId(user)} */}
								{/*			css={{ */}
								{/*				textTransform: 'capitalize', */}
								{/*				padding: 10, */}
								{/*			}} */}
								{/*		> */}
								{/*			<AvatarCustom */}
								{/*				firstName={user?.firstName} */}
								{/*				styles={{ container: { marginTop: `5px!important` } }} */}
								{/*				lastName={user?.lastName} */}
								{/*				position={`${user?.information?.position} at ${user?.information?.company}`} */}
								{/*			/> */}
								{/*		</Select.Option> */}
								{/*	))} */}
								{/* </Select> */}
							</Space>
						</Space>
					</div>
				</>
			)}
		</PageLayout>
	);
};

export default LearnDetailContainer;
