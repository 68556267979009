import { useTheme } from '@emotion/react';
import PageLayout from '../../components/app/layout/PageLayout';
import { ACCOUNT_DETAILS } from '../../constants/constant';
import { useAuthContext } from '../../hooks/useAuthContext';
import {
	Button, Col, Popconfirm, Row, Space,
} from 'antd';
import Box from '../../components/app/box/Box';
import ReactJsonAdmin from '../../components/app/debug/ReactJsonAdmin';
import BlockAdmin from '../../components/app/blocker/BlockAdmin';
import AccountBrowsingCard from '../../components/account/AccountBrowsingCard';
import AccountProfileCard from '../../components/account/AccountProfileCard';
import AccountNotificationCard from '../../components/account/AccountNotificationCard';
import AccountRolesCard from '../../components/account/AccountRolesCard';
import { useContext, useEffect, useState } from 'react';
import { isGenderHirePlan, isGenderScorePlan } from '../../constants/property/business-plan';
import { CompanyContext } from '../../contexts/CompanyProvider';
import AccountCredentialsCard from '../../components/account/AccountCredentialsCard';
import AccountMenu from '../../menu/AccountMenu';

const AccountPage = () => {
	const className = 'AccountPage';

	const theme = useTheme();

	const {
		currentUser,
		isStaff,
		isCompanyRecruiter,
		logout,
	} = useAuthContext();
	const { planValue } = useContext(CompanyContext);

	const [layout, setLayout] = useState(null);

	useEffect(() => {
		const items = [
			<AccountProfileCard key='profile' user={currentUser} />,
			<AccountCredentialsCard key='credentials' user={currentUser} />,
			...isStaff || true
				? [<AccountRolesCard key='roles' user={currentUser} />]
				: [],
			...(isStaff && !planValue) || isGenderHirePlan(planValue)
				? [
					<AccountNotificationCard key='notifs' user={currentUser} />,
					<AccountBrowsingCard key='browsing' user={currentUser}/>,
				]
				: [],
		];
		const rows = items.length < 3
			? [[items[0]], [items[1]]]
			: [items.slice(0, 2), items.slice(2, items.length)];

		setLayout(
			rows.map((row, rowIndex) => (
				<Col key={rowIndex} span={12}>
					<Space direction='vertical' size={gap} style={{ width: '100%' }}>
						{row.map((col, colIndex) => (
							<div key={colIndex}>
								{col}
							</div>
						))}
					</Space>
				</Col>
			)),
		);
	}, [planValue, currentUser]);

	const gap = 20;

	const menu = (new AccountMenu()).build();

	return (
		<PageLayout
			icon={menu.icon}
			title={menu.label}
			subtitle={ACCOUNT_DETAILS}
			rightActions={(
				<Popconfirm
					title="Are you sure you want to log out？"
					okText="Yes"
					cancelText="No"
					onConfirm={() => logout()}
				>
					<Button
						type='primary'
						danger
					>
						<b>👋 Log out</b>
					</Button>
				</Popconfirm>
			)}
			styles={{
				container: { flex: '0 1 600px', overflow: 'auto' },
			}}
		>
			{currentUser && <>

				<Row gutter={[gap, gap]} style={{ marginBottom: 40 }}>
					{layout}
				</Row>

				<BlockAdmin>

					<Box>
						<ul>
							<li>Manage subscription to Talent NL</li>
							<li>Modify my password</li>
							<li>Modify my email</li>
							<li>Contact my customer success manager (Jean)</li>
							<li>Feedback</li>
						</ul>
						<ReactJsonAdmin src={currentUser}/>
					</Box>

				</BlockAdmin>
			</>}
		</PageLayout>
	);
};

export default AccountPage;
