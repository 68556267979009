export const STAGE_NEW = 'new';
export const STAGE_CONTACTED = 'contacted';
export const STAGE_RESPONDED = 'responded';
export const STAGE_STARTED = 'started';
export const STAGE_INTERVIEWED = 'interviewed';
export const STAGE_TESTED = 'tested';
export const STAGE_OFFER = 'offer';
export const STAGE_HIRED = 'hired';

export const STAGE_SHORTLISTS_LIST = [
	STAGE_NEW,
	STAGE_CONTACTED,
	STAGE_RESPONDED,
];

export const STAGE_SOURCED_LIST = [
	STAGE_STARTED,
	STAGE_INTERVIEWED,
	STAGE_TESTED,
	STAGE_OFFER,
	STAGE_HIRED,
];

export const STAGE_APPLICATIONS_LIST = [
	STAGE_NEW,
	STAGE_CONTACTED,
	STAGE_RESPONDED,
	STAGE_STARTED,
	STAGE_INTERVIEWED,
	STAGE_TESTED,
	STAGE_OFFER,
	STAGE_HIRED,
];

export const SOURCE_50INTECH_VALUE = 'sourcing-50intech';
export const SOURCE_50INTECH_LABEL = '50inTech suggestion/sourcing';
export const SOURCE_RECRUITER_VALUE = 'sourcing-client';
export const SOURCE_RECRUITER_LABEL = 'Recruiter sourcing';
export const SOURCE_DIRECT_APPLY_VALUE = 'direct-apply';
export const SOURCE_DIRECT_APPLY_LABEL = 'Direct application';
export const SOURCE_CONNECT_REQUEST_VALUE = 'connect-request';
export const SOURCE_CONNECT_REQUEST_LABEL = 'Connection Request';

export const SOURCE_OPTIONS = [
	{ value: SOURCE_50INTECH_VALUE, label: SOURCE_50INTECH_LABEL },
	{ value: SOURCE_RECRUITER_VALUE, label: SOURCE_RECRUITER_LABEL },
	{ value: SOURCE_DIRECT_APPLY_VALUE, label: SOURCE_DIRECT_APPLY_LABEL },
	{ value: SOURCE_CONNECT_REQUEST_VALUE, label: SOURCE_CONNECT_REQUEST_LABEL },
];

export const SOURCE_SHORTLISTS_LIST = [
	SOURCE_50INTECH_VALUE,
	SOURCE_RECRUITER_VALUE,
	SOURCE_CONNECT_REQUEST_VALUE,
];

export const SOURCE_APPLICATIONS_LIST = [
	SOURCE_DIRECT_APPLY_VALUE,
];

export const SOURCE_SOURCED_LIST = [
	SOURCE_50INTECH_VALUE,
	SOURCE_RECRUITER_VALUE,
	SOURCE_CONNECT_REQUEST_VALUE,
];

export const CANDIDATE_PIPELINE_SHORTLIST_VALUE = 'shortlist';
export const CANDIDATE_PIPELINE_SHORTLIST_LABEL = 'Saved for later';
export const CANDIDATE_PIPELINE_SHORTLIST_ICON = '📌';

export const CANDIDATE_STAGE_SHORTLIST_RECRUITER_VALUE = 'shortlist-recruiter';
export const CANDIDATE_STAGE_SHORTLIST_RECRUITER_LABEL = 'Saved by you';
export const CANDIDATE_STAGE_SHORTLIST_RECRUITER_ICON = '🌱';

export const CANDIDATE_STAGE_SHORTLIST_50INTECH_VALUE = 'shortlist-50intech';
export const CANDIDATE_STAGE_SHORTLIST_50INTECH_LABEL = 'Suggested';
export const CANDIDATE_STAGE_SHORTLIST_50INTECH_ICON = '👀';

export const CANDIDATE_STAGE_SHORTLIST_REACHED_OUT_VALUE = 'shortlist-reached-out';
export const CANDIDATE_STAGE_SHORTLIST_REACHED_OUT_LABEL = 'Reached out';
export const CANDIDATE_STAGE_SHORTLIST_REACHED_OUT_ICON = '👋';

export const CANDIDATE_PIPELINE_LEAD_VALUE = 'lead';
export const CANDIDATE_PIPELINE_LEAD_LABEL = 'Leads';
export const CANDIDATE_PIPELINE_LEAD_ICON = '📤';
export const CANDIDATE_STAGE_LEAD_NEW_VALUE = 'lead-new';
export const CANDIDATE_STAGE_LEAD_NEW_LABEL = 'New lead';
export const CANDIDATE_STAGE_LEAD_NEW_ICON = '🌱';
export const CANDIDATE_STAGE_LEAD_REACHED_OUT_VALUE = 'lead-reached-out';
export const CANDIDATE_STAGE_LEAD_REACHED_OUT_LABEL = 'Reached out';
export const CANDIDATE_STAGE_LEAD_REACHED_OUT_ICON = '👋';
export const CANDIDATE_STAGE_LEAD_RESPONDED_VALUE = 'lead-responded';
export const CANDIDATE_STAGE_LEAD_RESPONDED_LABEL = 'Responded';
export const CANDIDATE_STAGE_LEAD_RESPONDED_ICON = '🙋‍♀️';
export const CANDIDATE_STAGE_LEAD_OTHER_VALUE = 'lead-other';
export const CANDIDATE_STAGE_LEAD_OTHER_LABEL = 'Other stages';
export const CANDIDATE_STAGE_LEAD_OTHER_ICON = '️🧐';

export const CANDIDATE_PIPELINE_APPLICANT_VALUE = 'applicant';
export const CANDIDATE_PIPELINE_APPLICANT_LABEL = 'Applicants';
export const CANDIDATE_PIPELINE_APPLICANT_ICON = '📥';
export const CANDIDATE_STAGE_APPLICANT_NEW_VALUE = 'applicant-new';
export const CANDIDATE_STAGE_APPLICANT_NEW_LABEL = 'New applicant';
export const CANDIDATE_STAGE_APPLICANT_NEW_ICON = '✍️';
export const CANDIDATE_STAGE_APPLICANT_ANSWERED_VALUE = 'applicant-answered';
export const CANDIDATE_STAGE_APPLICANT_ANSWERED_LABEL = 'Applicant answered';
export const CANDIDATE_STAGE_APPLICANT_ANSWERED_ICON = '🙋‍♀️';
export const CANDIDATE_STAGE_APPLICANT_OTHER_VALUE = 'applicant-other';
export const CANDIDATE_STAGE_APPLICANT_OTHER_LABEL = 'Other stages';
export const CANDIDATE_STAGE_APPLICANT_OTHER_ICON = '️🧐';

export const CANDIDATE_PIPELINE_INTERVIEW_VALUE = 'interview';
export const CANDIDATE_PIPELINE_INTERVIEW_LABEL = 'Interviews';
export const CANDIDATE_PIPELINE_INTERVIEW_ICON = '☕';
export const CANDIDATE_STAGE_SCREENING_VALUE = 'screening';
export const CANDIDATE_STAGE_SCREENING_LABEL = 'Screening';
export const CANDIDATE_STAGE_SCREENING_ICON = '☎️';
export const CANDIDATE_STAGE_INTERVIEW_VALUE = 'interview';
export const CANDIDATE_STAGE_INTERVIEW_LABEL = 'Interview';
export const CANDIDATE_STAGE_INTERVIEW_ICON = '☕';
export const CANDIDATE_STAGE_ASSESSMENT_VALUE = 'assessment';
export const CANDIDATE_STAGE_ASSESSMENT_LABEL = 'Assessment';
export const CANDIDATE_STAGE_ASSESSMENT_ICON = '⚗️';
export const CANDIDATE_STAGE_REFERENCE_CHECK_VALUE = 'reference-check';
export const CANDIDATE_STAGE_REFERENCE_CHECK_LABEL = 'Reference Check';
export const CANDIDATE_STAGE_REFERENCE_CHECK_ICON = '☑️️';
export const CANDIDATE_STAGE_OFFER_VALUE = 'offer';
export const CANDIDATE_STAGE_OFFER_LABEL = 'Offer';
export const CANDIDATE_STAGE_OFFER_ICON = '💐️';
export const CANDIDATE_STAGE_INTERVIEW_OTHER_VALUE = 'interview-other';
export const CANDIDATE_STAGE_INTERVIEW_OTHER_LABEL = 'Other stages';
export const CANDIDATE_STAGE_INTERVIEW_OTHER_ICON = '️🧐';

export const CANDIDATE_PIPELINE_HIRED_VALUE = 'hired';
export const CANDIDATE_PIPELINE_HIRED_LABEL = 'Hired';
export const CANDIDATE_PIPELINE_HIRED_ICON = '🎉';
export const CANDIDATE_STAGE_HIRED_VALUE = 'hired';
export const CANDIDATE_STAGE_HIRED_LABEL = 'Hired';
export const CANDIDATE_STAGE_HIRED_ICON = '🎉';

export const CANDIDATE_PIPELINE_STAGES = [
	{
		value: CANDIDATE_PIPELINE_SHORTLIST_VALUE,
		label: CANDIDATE_PIPELINE_SHORTLIST_LABEL,
		icon: CANDIDATE_PIPELINE_SHORTLIST_ICON,
		filters: { candidatePipeline: CANDIDATE_PIPELINE_SHORTLIST_VALUE },
		stages: [
			{
				value: CANDIDATE_STAGE_SHORTLIST_RECRUITER_VALUE,
				label: CANDIDATE_STAGE_SHORTLIST_RECRUITER_LABEL,
				icon: CANDIDATE_STAGE_SHORTLIST_RECRUITER_ICON,
				filters: {
					candidateStage: CANDIDATE_STAGE_SHORTLIST_RECRUITER_VALUE,
					// candidateOrigin: 'recruiter',
				},
			},
			{
				value: CANDIDATE_STAGE_SHORTLIST_50INTECH_VALUE,
				label: CANDIDATE_STAGE_SHORTLIST_50INTECH_LABEL,
				icon: CANDIDATE_STAGE_SHORTLIST_50INTECH_ICON,
				hideIfEmpty: true,
				filters: {
					candidateStage: CANDIDATE_STAGE_SHORTLIST_50INTECH_VALUE,
					// candidateOrigin: '50intech',
				},
			},
			{
				value: CANDIDATE_STAGE_SHORTLIST_REACHED_OUT_VALUE,
				label: CANDIDATE_STAGE_SHORTLIST_REACHED_OUT_LABEL,
				icon: CANDIDATE_STAGE_SHORTLIST_REACHED_OUT_ICON,
				legacyStage: 'contacted',
				aliases: ['contacted'],
				filters: { candidateStage: CANDIDATE_STAGE_SHORTLIST_REACHED_OUT_VALUE },
			},
		],
	},
	{
		value: CANDIDATE_PIPELINE_LEAD_VALUE,
		label: CANDIDATE_PIPELINE_LEAD_LABEL,
		icon: CANDIDATE_PIPELINE_LEAD_ICON,
		filters: { candidatePipeline: CANDIDATE_PIPELINE_LEAD_VALUE },
		stages: [
			{
				value: CANDIDATE_STAGE_LEAD_NEW_VALUE,
				label: CANDIDATE_STAGE_LEAD_NEW_LABEL,
				icon: CANDIDATE_STAGE_LEAD_NEW_ICON,
				legacyStage: 'started',
				aliases: ['new'],
				filters: { candidateStage: CANDIDATE_STAGE_LEAD_NEW_VALUE },
			},
			{
				value: CANDIDATE_STAGE_LEAD_REACHED_OUT_VALUE,
				label: CANDIDATE_STAGE_LEAD_REACHED_OUT_LABEL,
				icon: CANDIDATE_STAGE_LEAD_REACHED_OUT_ICON,
				legacyStage: 'contacted',
				aliases: ['contacted'],
				filters: { candidateStage: CANDIDATE_STAGE_LEAD_REACHED_OUT_VALUE },
			},
			{
				value: CANDIDATE_STAGE_LEAD_RESPONDED_VALUE,
				label: CANDIDATE_STAGE_LEAD_RESPONDED_LABEL,
				icon: CANDIDATE_STAGE_LEAD_RESPONDED_ICON,
				legacyStage: 'responded',
				aliases: ['responded'],
				filters: { candidateStage: CANDIDATE_STAGE_LEAD_RESPONDED_VALUE },
			},
			{
				value: CANDIDATE_STAGE_LEAD_OTHER_VALUE,
				label: CANDIDATE_STAGE_LEAD_OTHER_LABEL,
				icon: CANDIDATE_STAGE_LEAD_OTHER_ICON,
				hideIfEmpty: true,
				filters: { candidateStage: CANDIDATE_STAGE_LEAD_OTHER_VALUE },
			},
		],
	},
	{
		value: CANDIDATE_PIPELINE_APPLICANT_VALUE,
		label: CANDIDATE_PIPELINE_APPLICANT_LABEL,
		icon: CANDIDATE_PIPELINE_APPLICANT_ICON,
		filters: { candidatePipeline: CANDIDATE_PIPELINE_APPLICANT_VALUE },
		stages: [
			{
				value: CANDIDATE_STAGE_APPLICANT_NEW_VALUE,
				label: CANDIDATE_STAGE_APPLICANT_NEW_LABEL,
				icon: CANDIDATE_STAGE_APPLICANT_NEW_ICON,
				legacyStage: 'new',
				filters: { candidateStage: CANDIDATE_STAGE_APPLICANT_NEW_VALUE },
			},
			// {
			//     value: CANDIDATE_STAGE_APPLICANT_ANSWERED_VALUE, label: 'Answered', icon: '👋️',
			//     // legacyStage: 'new', aliases: ['new', CANDIDATE_STAGE_LEAD_NEW_VALUE],
			//     filters: { candidateStage: CANDIDATE_STAGE_APPLICANT_ANSWERED_VALUE },
			// },
			{
				value: CANDIDATE_STAGE_APPLICANT_OTHER_VALUE,
				label: CANDIDATE_STAGE_APPLICANT_OTHER_LABEL,
				icon: CANDIDATE_STAGE_APPLICANT_OTHER_ICON,
				hideIfEmpty: true,
				filters: { candidateStage: CANDIDATE_STAGE_APPLICANT_OTHER_VALUE },
			},
		],
	},
	{
		value: CANDIDATE_PIPELINE_INTERVIEW_VALUE,
		label: CANDIDATE_PIPELINE_INTERVIEW_LABEL,
		icon: CANDIDATE_PIPELINE_INTERVIEW_ICON,
		filters: { candidatePipeline: CANDIDATE_PIPELINE_INTERVIEW_VALUE },
		stages: [
			{
				value: CANDIDATE_STAGE_SCREENING_VALUE,
				label: CANDIDATE_STAGE_SCREENING_LABEL,
				icon: CANDIDATE_STAGE_SCREENING_ICON,
				legacyStage: 'interviewed',
				aliases: ['started', 'interviewed'],
				filters: { candidateStage: CANDIDATE_STAGE_SCREENING_VALUE },
			},
			// {
			//     value: 'interviews', label: 'Interviews', icon: '☕',
			//     legacyStage: 'interviewed', aliases: ['interviewed'],
			//     filters: { candidateStage: 'interviews' },
			// },
			{
				value: CANDIDATE_STAGE_ASSESSMENT_VALUE,
				label: CANDIDATE_STAGE_ASSESSMENT_LABEL,
				icon: CANDIDATE_STAGE_ASSESSMENT_ICON,
				legacyStage: 'tested',
				aliases: ['tested'],
				filters: { candidateStage: CANDIDATE_STAGE_ASSESSMENT_VALUE },
			},
			{
				value: CANDIDATE_STAGE_INTERVIEW_VALUE,
				label: CANDIDATE_STAGE_INTERVIEW_LABEL,
				icon: CANDIDATE_STAGE_INTERVIEW_ICON,
				legacyStage: 'interviewed',
				aliases: ['interviewed'],
				filters: { candidateStage: CANDIDATE_STAGE_INTERVIEW_VALUE },
			},
			{
				value: CANDIDATE_STAGE_INTERVIEW_OTHER_VALUE,
				label: CANDIDATE_STAGE_INTERVIEW_OTHER_LABEL,
				icon: CANDIDATE_STAGE_INTERVIEW_OTHER_ICON,
				hideIfEmpty: true,
				filters: { candidateStage: CANDIDATE_STAGE_INTERVIEW_OTHER_VALUE },
			},
			{
				value: CANDIDATE_STAGE_REFERENCE_CHECK_VALUE,
				label: CANDIDATE_STAGE_REFERENCE_CHECK_LABEL,
				icon: CANDIDATE_STAGE_REFERENCE_CHECK_ICON,
				legacyStage: CANDIDATE_STAGE_REFERENCE_CHECK_VALUE,
				aliases: [CANDIDATE_STAGE_REFERENCE_CHECK_VALUE],
				filters: { candidateStage: CANDIDATE_STAGE_REFERENCE_CHECK_VALUE },
			},
			{
				value: CANDIDATE_STAGE_OFFER_VALUE,
				label: CANDIDATE_STAGE_OFFER_LABEL,
				icon: CANDIDATE_STAGE_OFFER_ICON,
				legacyStage: CANDIDATE_STAGE_OFFER_VALUE,
				aliases: [CANDIDATE_STAGE_OFFER_VALUE],
				filters: { candidateStage: CANDIDATE_STAGE_OFFER_VALUE },
			},
			{
				value: CANDIDATE_STAGE_HIRED_VALUE,
				label: CANDIDATE_STAGE_HIRED_LABEL,
				icon: CANDIDATE_STAGE_HIRED_ICON,
				legacyStage: 'hired',
				filters: { candidateStage: CANDIDATE_STAGE_HIRED_VALUE },
			},
		],
	},
	// {
	//     value: CANDIDATE_PIPELINE_HIRED_VALUE, label: 'Hired',
	//     filters: { candidatePipeline: CANDIDATE_PIPELINE_HIRED_VALUE },
	//     stages: [
	//         {
	//             value: CANDIDATE_STAGE_HIRED_VALUE, label: 'Hired', icon: '🎉',
	//             legacyStage: 'hired',
	//             filters: { candidateStage: CANDIDATE_STAGE_HIRED_VALUE },
	//         },
	//     ],
	// },
];

export const CANDIDATE_ARCHIVE_REASON_UNDERQUALIFIED_VALUE = 'underqualified';
export const CANDIDATE_ARCHIVE_REASON_OVERQUALIFIED_VALUE = 'overqualified';
export const CANDIDATE_ARCHIVE_REASON_UNRESPONSIVE_VALUE = 'unresponsive';
export const CANDIDATE_ARCHIVE_REASON_TIMING_VALUE = 'timing';
export const CANDIDATE_ARCHIVE_REASON_WITHDREW_VALUE = 'withdrew';
export const CANDIDATE_ARCHIVE_REASON_OFFER_DECLINED_VALUE = 'offer-declined';
export const CANDIDATE_ARCHIVE_REASON_POSITION_CLOSED_VALUE = 'position-closed';
export const CANDIDATE_ARCHIVE_REASON_OTHER_VALUE = 'archive-other';
export const CANDIDATE_ARCHIVE_REASON_HIRED_VALUE = 'hired';

export const CANDIDATE_ARCHIVE_REASONS = [
	{
		value: CANDIDATE_ARCHIVE_REASON_UNDERQUALIFIED_VALUE,
		label: 'Underqualified',
		icon: '💯',
		filters: { isArchived: true, atsArchiveReason: CANDIDATE_ARCHIVE_REASON_UNDERQUALIFIED_VALUE },
	},
	{
		value: CANDIDATE_ARCHIVE_REASON_OVERQUALIFIED_VALUE,
		label: 'Overqualified',
		icon: '💯',
		filters: { isArchived: true, atsArchiveReason: CANDIDATE_ARCHIVE_REASON_OVERQUALIFIED_VALUE },
	},
	{
		value: CANDIDATE_ARCHIVE_REASON_UNRESPONSIVE_VALUE,
		label: 'Unresponsive',
		icon: '👻',
		filters: { isArchived: true, atsArchiveReason: CANDIDATE_ARCHIVE_REASON_UNRESPONSIVE_VALUE },
	},
	{
		value: CANDIDATE_ARCHIVE_REASON_TIMING_VALUE,
		label: 'Timing',
		icon: '⌛',
		filters: { isArchived: true, atsArchiveReason: CANDIDATE_ARCHIVE_REASON_TIMING_VALUE },
	},
	{
		value: CANDIDATE_ARCHIVE_REASON_WITHDREW_VALUE,
		label: 'Withdrew',
		icon: '🚪',
		filters: { isArchived: true, atsArchiveReason: CANDIDATE_ARCHIVE_REASON_WITHDREW_VALUE },
	},
	{
		value: CANDIDATE_ARCHIVE_REASON_OFFER_DECLINED_VALUE,
		label: 'Offer declined',
		icon: '🙏',
		filters: { isArchived: true, atsArchiveReason: CANDIDATE_ARCHIVE_REASON_OFFER_DECLINED_VALUE },
	},
	{
		value: CANDIDATE_ARCHIVE_REASON_POSITION_CLOSED_VALUE,
		label: 'Position closed',
		icon: '🔇',
		filters: { isArchived: true, atsArchiveReason: CANDIDATE_ARCHIVE_REASON_POSITION_CLOSED_VALUE },
	},
	{
		value: CANDIDATE_ARCHIVE_REASON_OTHER_VALUE,
		label: 'Other',
		icon: '🧐',
		filters: { isArchived: true, atsArchiveReason: CANDIDATE_ARCHIVE_REASON_OTHER_VALUE },
	},
	// {
	//     value: CANDIDATE_ARCHIVE_REASON_HIRED_VALUE, label: 'Hired', icon: '🎉',
	//     filters: { isArchived: true, atsArchiveReason: CANDIDATE_ARCHIVE_REASON_HIRED_VALUE },
	// },
];

export default {
	STAGE_NEW,
	STAGE_CONTACTED,
	STAGE_RESPONDED,
	STAGE_STARTED,
	STAGE_INTERVIEWED,
	STAGE_TESTED,
	STAGE_OFFER,
	STAGE_HIRED,
	STAGE_SHORTLISTS_LIST,
	STAGE_SOURCED_LIST,
	STAGE_APPLICATIONS_LIST,
	SOURCE_50INTECH_VALUE,
	SOURCE_50INTECH_LABEL,
	SOURCE_RECRUITER_VALUE,
	SOURCE_RECRUITER_LABEL,
	SOURCE_DIRECT_APPLY_VALUE,
	SOURCE_DIRECT_APPLY_LABEL,
	SOURCE_CONNECT_REQUEST_VALUE,
	SOURCE_CONNECT_REQUEST_LABEL,
	SOURCE_OPTIONS,
	SOURCE_SHORTLISTS_LIST,
	SOURCE_APPLICATIONS_LIST,
	SOURCE_SOURCED_LIST,
	CANDIDATE_PIPELINE_SHORTLIST_VALUE,
	CANDIDATE_PIPELINE_SHORTLIST_LABEL,
	CANDIDATE_PIPELINE_SHORTLIST_ICON,
	CANDIDATE_STAGE_SHORTLIST_RECRUITER_VALUE,
	CANDIDATE_STAGE_SHORTLIST_RECRUITER_LABEL,
	CANDIDATE_STAGE_SHORTLIST_RECRUITER_ICON,
	CANDIDATE_STAGE_SHORTLIST_50INTECH_VALUE,
	CANDIDATE_STAGE_SHORTLIST_50INTECH_LABEL,
	CANDIDATE_STAGE_SHORTLIST_50INTECH_ICON,
	CANDIDATE_STAGE_SHORTLIST_REACHED_OUT_VALUE,
	CANDIDATE_STAGE_SHORTLIST_REACHED_OUT_LABEL,
	CANDIDATE_STAGE_SHORTLIST_REACHED_OUT_ICON,
	CANDIDATE_PIPELINE_LEAD_VALUE,
	CANDIDATE_PIPELINE_LEAD_LABEL,
	CANDIDATE_PIPELINE_LEAD_ICON,
	CANDIDATE_STAGE_LEAD_NEW_VALUE,
	CANDIDATE_STAGE_LEAD_NEW_LABEL,
	CANDIDATE_STAGE_LEAD_NEW_ICON,
	CANDIDATE_STAGE_LEAD_REACHED_OUT_VALUE,
	CANDIDATE_STAGE_LEAD_REACHED_OUT_LABEL,
	CANDIDATE_STAGE_LEAD_REACHED_OUT_ICON,
	CANDIDATE_STAGE_LEAD_RESPONDED_VALUE,
	CANDIDATE_STAGE_LEAD_RESPONDED_LABEL,
	CANDIDATE_STAGE_LEAD_RESPONDED_ICON,
	CANDIDATE_STAGE_LEAD_OTHER_VALUE,
	CANDIDATE_STAGE_LEAD_OTHER_LABEL,
	CANDIDATE_STAGE_LEAD_OTHER_ICON,
	CANDIDATE_PIPELINE_APPLICANT_VALUE,
	CANDIDATE_PIPELINE_APPLICANT_LABEL,
	CANDIDATE_PIPELINE_APPLICANT_ICON,
	CANDIDATE_STAGE_APPLICANT_NEW_VALUE,
	CANDIDATE_STAGE_APPLICANT_NEW_LABEL,
	CANDIDATE_STAGE_APPLICANT_NEW_ICON,
	CANDIDATE_STAGE_APPLICANT_ANSWERED_VALUE,
	CANDIDATE_STAGE_APPLICANT_ANSWERED_LABEL,
	CANDIDATE_STAGE_APPLICANT_ANSWERED_ICON,
	CANDIDATE_STAGE_APPLICANT_OTHER_VALUE,
	CANDIDATE_STAGE_APPLICANT_OTHER_LABEL,
	CANDIDATE_STAGE_APPLICANT_OTHER_ICON,
	CANDIDATE_PIPELINE_INTERVIEW_VALUE,
	CANDIDATE_PIPELINE_INTERVIEW_LABEL,
	CANDIDATE_PIPELINE_INTERVIEW_ICON,
	CANDIDATE_STAGE_SCREENING_VALUE,
	CANDIDATE_STAGE_SCREENING_LABEL,
	CANDIDATE_STAGE_SCREENING_ICON,
	CANDIDATE_STAGE_INTERVIEW_VALUE,
	CANDIDATE_STAGE_INTERVIEW_LABEL,
	CANDIDATE_STAGE_INTERVIEW_ICON,
	CANDIDATE_STAGE_ASSESSMENT_VALUE,
	CANDIDATE_STAGE_ASSESSMENT_LABEL,
	CANDIDATE_STAGE_ASSESSMENT_ICON,
	CANDIDATE_STAGE_REFERENCE_CHECK_VALUE,
	CANDIDATE_STAGE_REFERENCE_CHECK_LABEL,
	CANDIDATE_STAGE_REFERENCE_CHECK_ICON,
	CANDIDATE_STAGE_OFFER_VALUE,
	CANDIDATE_STAGE_OFFER_LABEL,
	CANDIDATE_STAGE_OFFER_ICON,
	CANDIDATE_STAGE_INTERVIEW_OTHER_VALUE,
	CANDIDATE_STAGE_INTERVIEW_OTHER_LABEL,
	CANDIDATE_STAGE_INTERVIEW_OTHER_ICON,
	CANDIDATE_PIPELINE_HIRED_VALUE,
	CANDIDATE_PIPELINE_HIRED_LABEL,
	CANDIDATE_PIPELINE_HIRED_ICON,
	CANDIDATE_STAGE_HIRED_VALUE,
	CANDIDATE_STAGE_HIRED_LABEL,
	CANDIDATE_STAGE_HIRED_ICON,
	CANDIDATE_ARCHIVE_REASON_UNDERQUALIFIED_VALUE,
	// CANDIDATE_ARCHIVE_REASON_UNDERQUALIFIED_LABEL: CANDIDATE_ARCHIVE_REASON_UNDERQUALIFIED_LABEL,
	// CANDIDATE_ARCHIVE_REASON_UNDERQUALIFIED_ICON: CANDIDATE_ARCHIVE_REASON_UNDERQUALIFIED_ICON,
	CANDIDATE_ARCHIVE_REASON_UNRESPONSIVE_VALUE,
	// CANDIDATE_ARCHIVE_REASON_UNRESPONSIVE_LABEL: CANDIDATE_ARCHIVE_REASON_UNRESPONSIVE_LABEL,
	// CANDIDATE_ARCHIVE_REASON_UNRESPONSIVE_ICON: CANDIDATE_ARCHIVE_REASON_UNRESPONSIVE_ICON,
	CANDIDATE_ARCHIVE_REASON_TIMING_VALUE,
	// CANDIDATE_ARCHIVE_REASON_TIMING_LABEL: CANDIDATE_ARCHIVE_REASON_TIMING_LABEL,
	// CANDIDATE_ARCHIVE_REASON_TIMING_ICON: CANDIDATE_ARCHIVE_REASON_TIMING_ICON,
	CANDIDATE_ARCHIVE_REASON_WITHDREW_VALUE,
	// CANDIDATE_ARCHIVE_REASON_WITHDREW_LABEL: CANDIDATE_ARCHIVE_REASON_WITHDREW_LABEL,
	// CANDIDATE_ARCHIVE_REASON_WITHDREW_ICON: CANDIDATE_ARCHIVE_REASON_WITHDREW_ICON,
	CANDIDATE_ARCHIVE_REASON_OFFER_DECLINED_VALUE,
	// CANDIDATE_ARCHIVE_REASON_OFFER_DECLINED_LABEL: CANDIDATE_ARCHIVE_REASON_OFFER_DECLINED_LABEL,
	// CANDIDATE_ARCHIVE_REASON_OFFER_DECLINED_ICON: CANDIDATE_ARCHIVE_REASON_OFFER_DECLINED_ICON,
	CANDIDATE_ARCHIVE_REASON_POSITION_CLOSED_VALUE,
	// CANDIDATE_ARCHIVE_REASON_POSITION_CLOSED_LABEL: CANDIDATE_ARCHIVE_REASON_POSITION_CLOSED_LABEL,
	// CANDIDATE_ARCHIVE_REASON_POSITION_CLOSED_ICON: CANDIDATE_ARCHIVE_REASON_POSITION_CLOSED_ICON,
	CANDIDATE_ARCHIVE_REASON_OTHER_VALUE,
	// CANDIDATE_ARCHIVE_REASON_OTHER_LABEL: CANDIDATE_ARCHIVE_REASON_OTHER_LABEL,
	// CANDIDATE_ARCHIVE_REASON_OTHER_ICON: CANDIDATE_ARCHIVE_REASON_OTHER_ICON,
	CANDIDATE_ARCHIVE_REASON_HIRED_VALUE,
	// CANDIDATE_ARCHIVE_REASON_HIRED_LABEL: CANDIDATE_ARCHIVE_REASON_HIRED_LABEL,
	// CANDIDATE_ARCHIVE_REASON_HIRED_ICON: CANDIDATE_ARCHIVE_REASON_HIRED_ICON,
	CANDIDATE_PIPELINE_STAGES,
	CANDIDATE_ARCHIVE_REASONS,
};
