import { DatePicker, Form } from 'antd';
import { useReducer, useState } from 'react';
import FormBoxTitle from '../../app/form/FormBoxTitle';
import FormItem from '../form/FormItem';
import FormSubmitButton from '../../app/form/FormSubmitButton';
import { DATE_FORMAT, ENGLISH_LANG, FRENCH_LANG } from '../../../constants/constant';
import { useAuthContext } from '../../../hooks/useAuthContext';
import { documentId, opacify } from '../../../utils/common';
import {
	formStateReducer, onFormFinish, onFormFinishFailed, onFormValuesChange,
} from './CompanySettingsFormHelper';
import { useDispatch } from 'react-redux';
import BlockStaff from '../../app/blocker/BlockStaff';
import Switch from '../../app/form/Switch';
import CompanyModel from '../../../models/CompanyModel';
import { useTheme } from '@emotion/react';
import CompanyMetaDisclaimer from '../../seo/CompanyMetaDisclamer';
import dayjs from 'dayjs';

const CompanySettingsCompanyPagePublicationForm = (
	{
		company,
		onSuccess,
		labelCol = { span: 6 },
		wrapperCol = { span: 24 },
		...props
	},
) => {
	const className = 'CompanySettingsCompanyPagePublicationForm';
	const formName = 'company-settings-company-page-publication-form';

	const dispatch = useDispatch();
	const { isStaffView } = useAuthContext();

	const initialValues = {
		...company,
		isPublished: company?.isPublished !== false,
		publishedAt: company?.publishedAt && dayjs(company?.publishedAt),
	};

	const [form] = Form.useForm();
	const [isPublished, setIsPublished] = useState(initialValues.isPublished);
	const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
	const [companyEdited, setCompanyEdited] = useState({ ...company });
	const [state, stateDispatch] = useReducer(formStateReducer({ form, setIsSubmitDisabled }));

	const companyModel = new CompanyModel(company);
	const metasFullyCustomizedEN = companyModel.areAllMetasCustomized(ENGLISH_LANG);
	const metasFullyCustomizedFR = companyModel.areAllMetasCustomized(FRENCH_LANG);
	const theme = useTheme();

	return (
		<BlockStaff
			withBorder={false}
			tagStyle={{
				top: 5,
				left: 0,
				opacity: 1,
			}}
		>
			<FormBoxTitle
				title='🏢 Company page publication'
				details={<div>
						Publication of the company page can be set in advance by checking <b>Publish company
						page</b> and setting
						the <b>Company page published after</b> to a date in the future.</div>}
			/>
			<Form
				form={form}
				name={formName}
				initialValues={initialValues}
				labelCol={labelCol}
				wrapperCol={wrapperCol}
				labelAlign='right'
				layout='horizontal'
				onFinish={async (values) => {
					const result = await onFormFinish({
						id: documentId(company),
						values: { ...values, forceRecompute: false },
						formName,
						dispatch,
						stateDispatch,
					});
					onSuccess?.();
					return result;
				}}
				onFinishFailed={onFormFinishFailed}
				onValuesChange={async (values) => {
					setIsSubmitDisabled(isSubmitDisabled);
					return onFormValuesChange({
						values, stateDispatch, setCompanyEdited,
					});
				}}
			>

				{
					// If SEO meta tags are not customized, display a disclaimer
					(!metasFullyCustomizedEN || (company.isTranslatable && !metasFullyCustomizedFR))
						&&						<CompanyMetaDisclaimer
							company={company}
							isFullyCustomizedEN={metasFullyCustomizedEN}
							isFullyCustomizedFR={metasFullyCustomizedFR}
						/>
				}

				<FormItem
					name="isPublished"
					valuePropName="checked"
				>
					<Switch
						style={{ width: 126 }}
						className='ant-switch-large'
						checkedChildren={<b>publish</b>}
						unCheckedChildren={<b>unpublish</b>}
						before={<>I want to</>}
						after={<>the company page</>}
						onChange={(value) => setIsPublished(value)}
					/>
				</FormItem>

				<FormItem
					shouldUpdate
					label="Publication date"
					name="publishedAt"
					rules={[{
						type: 'object',
						required: false,
						message: 'Please input date',
						whitespace: true,
					}]}
					extra={<>
							If defined, publication will start <b>after this date</b>.<br/>
							If left blank, publication will start <b>immediately</b>.
					</>}
					// style={opacify(!form.getFieldValue('isPublished'))}
					style={opacify(!isPublished)}
				>
					<DatePicker showTime={true} format={DATE_FORMAT}/>
				</FormItem>

				<FormItem>
					<FormSubmitButton disabled={isSubmitDisabled}>
							Save company publication settings
					</FormSubmitButton>
				</FormItem>

			</Form>
		</BlockStaff>
	);
};

export default CompanySettingsCompanyPagePublicationForm;
