import { Image, Transformation } from 'cloudinary-react';

const ArticleImage = ({
	article,
	lang,
	size = 120,
	imgSize,
	...props
}) => {
	let illustration = article[`illustration${lang ? `_${lang}` : ''}`];
	if (illustration) {
		const parts = illustration.split('/upload/').reverse()[0].split('/');
		parts.shift();
		illustration = parts.join('/');
	}

	return (
		<div
			width={size}
			className={props?.className}
			style={{ ...props?.style }}
		>
			{
				illustration
					? <Image width={imgSize || size} publicId={illustration} secure="true">
						<Transformation width={imgSize || size * 2} crop="fill"/>
					</Image>
					: <Image width={imgSize || size} publicId='https://placehold.co/600x400/eee/ccc?text=No+image&font=montserrat' secure="true" />
			}
		</div>
	);
};

export default ArticleImage;
