import { innerObjPropExtractor } from '../../property/property-extractor';

const chalk = require('chalk');
const { default: CAREER_ROLE } = require('../../property/career-role');
const { innerObjPropMapper } = require('../../property/property-mapper');

const USER_PROPERTY_JOB_SEARCH_JOB_ROLE_KEY = 'job-search-job-role';

const USER_PROPERTY_JOB_SEARCH_JOB_ROLE_MULTI = true;
const USER_PROPERTY_JOB_SEARCH_JOB_ROLE_FREE = false;
const USER_PROPERTY_JOB_SEARCH_JOB_ROLE_MAX = 5;

const USER_PROPERTY_JOB_SEARCH_JOB_ROLE_FIELD = 'jobSearch.jobRole';
const USER_PROPERTY_JOB_SEARCH_JOB_ROLE_MAPPER = [
	innerObjPropMapper(USER_PROPERTY_JOB_SEARCH_JOB_ROLE_FIELD),
	(obj, value) => {
		const options = CAREER_ROLE.CAREER_ROLE_OPTIONS.filter((item) => value.includes(item.value));
		if (options && options.length > 0) {
			options.map((option) => {
				const { group } = option;
				obj.jobSearch = {
					...(obj.jobSearch || {}),
					jobRoleGroup: [...new Set([
						...(obj.jobSearch.jobRoleGroup || []),
						group,
					])],
				};
			});
		}
		return obj;
	},
];
const USER_PROPERTY_JOB_SEARCH_JOB_ROLE_EXTRACTOR = innerObjPropExtractor(
	USER_PROPERTY_JOB_SEARCH_JOB_ROLE_FIELD,
);

export default {
	...CAREER_ROLE,
	key: USER_PROPERTY_JOB_SEARCH_JOB_ROLE_KEY,
	USER_PROPERTY_JOB_SEARCH_JOB_ROLE_KEY,
	free: USER_PROPERTY_JOB_SEARCH_JOB_ROLE_FREE,
	USER_PROPERTY_JOB_SEARCH_JOB_ROLE_FREE,
	multi: USER_PROPERTY_JOB_SEARCH_JOB_ROLE_MULTI,
	USER_PROPERTY_JOB_SEARCH_JOB_ROLE_MULTI,
	max: USER_PROPERTY_JOB_SEARCH_JOB_ROLE_MAX,
	USER_PROPERTY_JOB_SEARCH_JOB_ROLE_MAX,
	mapper: USER_PROPERTY_JOB_SEARCH_JOB_ROLE_MAPPER,
	USER_PROPERTY_JOB_SEARCH_JOB_ROLE_MAPPER,
	extractor: USER_PROPERTY_JOB_SEARCH_JOB_ROLE_EXTRACTOR,
	USER_PROPERTY_JOB_SEARCH_JOB_ROLE_EXTRACTOR,
	field: USER_PROPERTY_JOB_SEARCH_JOB_ROLE_FIELD,
	USER_PROPERTY_JOB_SEARCH_JOB_ROLE_FIELD,
};
