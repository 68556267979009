import AtsArchiveReasonsPage from '../../../containers/staff/ats/AtsArchiveReasonsPage';
import { PERMS_GRANT_ADMIN } from '../../menu.utils';
import MenuBase from '../../MenuBase';
import { DislikeOutlined } from '@ant-design/icons';

export const StaffAtsArchiveReasonsIcon = DislikeOutlined;

class StaffAtsArchiveReasonsMenu extends MenuBase {
	static props = {
		name: 'staff-ats-archive-reasons',
		path: '/staff/ats/archive-reasons',
		label: 'ATS Archive Reasons',
		icon: <StaffAtsArchiveReasonsIcon />,
		iconLegacy: '🥓️',
		Component: AtsArchiveReasonsPage,
		onEnterProps: { shouldLoadAtsData: true },
		perms: PERMS_GRANT_ADMIN,
	};

	constructor() {
		super(StaffAtsArchiveReasonsMenu.props);
	}
}

export default StaffAtsArchiveReasonsMenu;
