import { onEnterBuilder } from '../../../actions/app';
import BoardKPIsPage from '../../../containers/staff/data/BoardKPIsPage';
import { PERMS_GRANT_STAFF } from '../../menu.utils';

const menu = {
	name: 'staff-data-board-kpis',
	path: '/staff/data/board-kpis',
	label: 'Board KPIs',
	icon: <span>💯</span>,
	component: BoardKPIsPage,
	onEnter: onEnterBuilder({
		origin: BoardKPIsPage.name,
		shouldGoToPreviousHistory: true,
	}),
	perms: PERMS_GRANT_STAFF,
};

export default menu;
