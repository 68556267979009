import { ENGLISH_LANG } from '../../../../constants/constant';
import CompanyRichTextEditor from '../shared/CompanyRichTextEditor';

const CompanyBenefitsEditor = (
	{
		company,
		lang = ENGLISH_LANG,
		setEditorOpen,
	},
) => {
	const className = 'CompanyBenefitsEditor';

	return (
		<CompanyRichTextEditor
			company={company}
			lang={lang}
			title={<b>Benefits & Perks</b>}
			details={<span>
				Describe the benefits and perks for employees.
				Highlight the value and advantages of working
				for your organisation
			</span>}
			field={'benefits'}
			setEditorOpen={setEditorOpen}
		/>
	);
};

export default CompanyBenefitsEditor;
