import {
	Form, message,
} from 'antd';
import { useEffect, useReducer, useState } from 'react';
import { useDispatch } from 'react-redux';
import { LocalDebug } from '../../../utils/LocalDebug';
import { documentId, writeLog } from '../../../utils/common';
import { useTheme } from '@emotion/react';
import { saveCompany } from '../../../actions/company';
import { refreshCurrentUser } from '../../../actions/app';
import { merge } from 'lodash';

const CompanySettingsFormBase = (
	{
		form,
		formName = 'CompanySettingsFormBase',
		company,
		hiddenValues = {},
		onFormValuesChanged,
		labelCol = { span: 6 },
		wrapperCol = { span: 16 },
		layout = 'vertical',
		labelAlign = 'left',
		setIsSubmitDisabled,
		onSuccess,
		children,
		...props
	},
) => {
	const className = 'CompanySettingsFormBase';
	const theme = useTheme();
	const dispatch = useDispatch();

	const [initialValues, setInitialValues] = useState({ ...company, ...form.getFieldsValue() });
	const [companyEdited, setCompanyEdited] = useState({ ...company, ...form.getFieldsValue() }); // TODO: remove this state if it's not used
	const [isFormChanged, setIsFormChanged] = useState(false);
	const [editedValues, setEditedValues] = useState({ ...form.getFieldsValue() });

	const hasFormErrors = () => form.getFieldsError().some(({ errors }) => errors.length);

	const [state, stateDispatch] = useReducer((state, action) => {
		switch (action) {
			case 'forceDisabled':
				return setIsSubmitDisabled?.(true);
			case 'updateDisabled':
				const isTouched = form.isFieldsTouched();
				return setIsSubmitDisabled?.(hasFormErrors() || !isTouched);
			default:
				break;
		}
	}, null);

	const onFormValuesChange = (values) => {
		setCompanyEdited({ ...companyEdited, ...values });
		setEditedValues(merge(editedValues, values)); // use lodash.merge instead of spread operator to do a deep merge (incl. nested objects)

		setIsFormChanged(true);
		stateDispatch('updateDisabled');
		onFormValuesChanged?.(values);
	};

	useEffect(() => {
		form.resetFields();
		setIsFormChanged(false);
		form.setFieldsValue({ ...company });
		setCompanyEdited({ ...company });
	}, [company]);

	const onFormFinish = async (values) => {
		const valuesToSend = merge(values, editedValues); // use lodash.merge instead of spread operator to do a deep merge (incl. nested objects)

		stateDispatch('forceDisabled');

		const data = {
			id: documentId(initialValues),
			...merge(hiddenValues, valuesToSend),
		};

		LocalDebug.logInfo({ className, method: 'onFormFinish' }, { valuesToSend, hiddenValues, data });

		message.loading({ key: className, content: 'Saving company settings...' });

		await dispatch(saveCompany(data));

		refreshCurrentUser()(dispatch);

		message.success({ key: className, content: 'Saved company settings successfully!' });

		writeLog(`Company settings saved (${formName})`, { data: valuesToSend });

		onSuccess?.();
	};

	const onFormFinishFailed = ({ values, errorFields, outOfDate }) => {
		message.error(<div>
			Error: {(errorFields?.map((e) => e.errors) || []).slice(0, 1).join(',')}
		</div>);
	};

	return (
		<>
			<Form
				form={form}
				name={formName}
				initialValues={initialValues}
				labelCol={labelCol}
				wrapperCol={wrapperCol}
				layout={layout}
				labelAlign={labelAlign}
				onFinish={onFormFinish}
				onFinishFailed={onFormFinishFailed}
				onValuesChange={onFormValuesChange}
				{...props}
			>

				{children}

			</Form>
		</>
	);
};

export default CompanySettingsFormBase;
