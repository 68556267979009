import { Space } from 'antd';
import GenderScoreTitleBlock from './GenderScoreTitleBlock';
import BarGraph from '../graphs/BarGraph';
import Link from '../../../../app/Link';
import { ArrowRightIcon } from '../../../../../utils/common';

/**
 * @param {string} title - Graph title
 * @param {*} progress - Values to render. Format: [{score, year, fulDate}]
 * @param {*} [graphColors] - Optional. Format: [from, to]. If not set, use theme.chart.bar
 * @param {number} [width] - Optional. Width of component. Default: 150
 * @param {number} [height] - Optional. Height of component. Default: 100
 * @param {number} [fontSize] - Optional. Font size of the labels on the graph. Default: 16
 * @param {boolean} [maxBars] - Optional. Maximum number of bars to show.
 * It impacts the internal size of the graph. Default: 3
 * @returns
 */
const GenderScoreBarBlock = ({
	progress,
	title,
	width = 180,
	height = 100,
	fontSize = 16,
	maxBars = 3,
	graphColors,
}) => {
	const className = 'GenderScoreBarBlock';
	if (!progress?.length) {
		return null;
	}
	const progressToRender = progress.slice(0, maxBars);
	const years = progressToRender?.reduce(
		(acc, v) => (
			acc.includes(v.year) ? acc : [...acc, v.year]),
		[],
	);
	const areSurveysOfSameYear = (years?.length < progress?.length);
	const data = {
		values: progressToRender,
		helpers: {
			xMapper: (d) => (areSurveysOfSameYear
				? d.fullDate
				: d?.year),
			yMapper: (d) => +d.score,
		},
	};
	const graphWidth = progressToRender.length * width / maxBars;
	return <Space direction='vertical'>
		<GenderScoreTitleBlock title={title}/>
		<BarGraph
			data={data}
			width={graphWidth}
			height={height}
			fontSize={fontSize}
			graphColors={graphColors}
			style={{ marginLeft: -12 }}
		/>
	</Space>;
};
export default GenderScoreBarBlock;
