import { Button, Form, Space } from 'antd';
import { useEffect, useState } from 'react';
import moment from 'moment';

import FormItem from '../app/form/FormItem';
import FormSelect from '../app/form/FormSelect';
import Modal from '../app/modal/Modal';
import { localDebug } from '../../utils/LocalDebug';
import { treatSourcingActionToDb } from '../../actions/sourcings';
import { SOURCING_ACTION_ARCHIVE, sourcingArchiveReasons, STOP_STATUS } from '../../constants/constant';
import { useAuthContext } from '../../hooks/useAuthContext';
import { documentId, requiredRule } from '../../utils/common';
import ModalContainerBase from '../../contexts/modals/ModalContainerBase';
import FormBoxTitle from '../app/form/FormBoxTitle';

const SourcingArchiveModal = (
	{
		open,
		action,
		initialValues,
		onClose,
		onConfirm,
	},
) => {
	const [form] = Form.useForm();
	const { currentUser } = useAuthContext();

	const [options, setOptions] = useState([]);
	// const [nextValueSelected, setNextValueSelected] = useState({});
	const [isStop, setIsStop] = useState(false);

	useEffect(() => {
		if (action) {
			setOptions(options);
			// form.setFieldsValue({'payload.value': nextValue.value});
		}
	}, [action, form]);

	const handleChangeAction = (value, option) => {
		setIsStop(value === SOURCING_ACTION_ARCHIVE);
	};

	const saveAction = (values) => {
		const history = {
			action: action?.type,
			creator: documentId(currentUser),
			author: documentId(currentUser),
			postedAt: moment(),
			...values,
		};
		const toDb = treatSourcingActionToDb({
			id: documentId(initialValues),
			...history,
		}, currentUser);
		onConfirm(toDb, action?.type);
	};

	return (
		<ModalContainerBase
			open={open}
			onCancel={onClose}
			footer={(
				<Space
					style={{ width: '100%', justifyContent: 'space-between' }}
				>
					<Button
						type='primary'
						ghost
						// danger
						onClick={onClose}
					>
						<b>No, cancel</b>
					</Button>
					<Button
						type='primary'
						onClick={() => {
							form
								.validateFields()
								.then((values) => {
									saveAction(values);
									form.resetFields();
								})
								.catch((info) => {
									localDebug('Validation failed:', info);
								});
						}}
					>
						<b>Yes, confirm</b>
					</Button>
				</Space>
			)}
		>
			<FormBoxTitle
				title={'Archive'}
			/>
			<Form
				form={form}
				layout="vertical"
				name="form_in_modal"
				initialValues={{ ...initialValues }}
			>
				<FormItem
					label="Archive reasons"
					defaultContainer="div"
					name="payload.archiveReasons"
					rules={[requiredRule]}
				>
					<FormSelect
						options={sourcingArchiveReasons.map((r) => ({ label: r, value: r }))}
						placeholder="Archive reason"
						defaultValue={initialValues?.payload?.archiveReasons}
						mode="tags"
					/>
				</FormItem>
			</Form>
		</ModalContainerBase>
	);
};

export default SourcingArchiveModal;
