import React, { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getCompanySelected } from '../reducers/app';
import { isGenderScorePlan } from '../constants/property/business-plan';
import { useAuthContext } from '../hooks/useAuthContext';
import { CompanyContext } from './CompanyProvider';

export const PermissionContext = React.createContext();

const PermissionProvider = ({
	children,
}) => {
	const className = 'PermissionProvider';

	const companySelected = useSelector(getCompanySelected);

	const { isStaff, isDocumentAdmin } = useAuthContext();
	const { planValue } = useContext(CompanyContext);

	const [shouldDisplayUpgradePlanButton, setShouldDisplayUpgradePlanButton] = useState(false);
	const [openUpgradePlanPopin, setOpenUpgradePlanPopin] = useState(false);

	// useEffect(() => {
	// 	const getShouldDisplayUpgradePlanButton = () => {
	// 		if (!isGenderScorePlan(planValue)) return false;
	// 		if (!companySelected) return false;
	// 		if (!isDocumentAdmin && !isStaff) return false;
	// 		return true;
	// 	};
	// 	setShouldDisplayUpgradePlanButton(getShouldDisplayUpgradePlanButton());
	// }, [
	// 	planValue,
	// 	companySelected,
	// 	isStaff,
	// 	isDocumentAdmin,
	// ]);

	return (
		<PermissionContext.Provider
			value={{
				shouldDisplayUpgradePlanButton,
				openUpgradePlanPopin,
				setOpenUpgradePlanPopin,
			}}
		>
			{children}
		</PermissionContext.Provider>
	);
};

export default PermissionProvider;
