import {
	Button, Col, Dropdown, Menu, Modal, Popover, Row, Space,
} from 'antd';
import { useContext } from 'react';
import { useTheme } from '@emotion/react';
import {
	FaClone, FaEdit, FaEyeSlash, FaSlidersH, FaTrashAlt,
} from 'react-icons/fa';
import { UserSavedSearchContext } from './UserSavedSearchProvider';
import { useAuthContext } from '../../hooks/useAuthContext';
import { arrayWrap, documentId } from '../../utils/common';
import UserSavedSearchSummary from './UserSavedSearchSummary';

const MENU_SELECT_KEY = 'select';
const MENU_EDIT_KEY = 'edit';
const MENU_CLONE_KEY = 'clone';
const MENU_DISCARD_ALERTS_KEY = 'discard-alerts';
const MENU_DELETE_KEY = 'delete';

const menu = ({
	onClick,
	savedSearch,
}) => (
	<Menu style={{ padding: 0, borderRadius: 0 }}>
		<Menu.Item
			key={MENU_EDIT_KEY}
			icon={<FaEdit style={{ marginBottom: 0 }}/>}
			onClick={() => onClick?.(MENU_EDIT_KEY)}
			style={{ fontSize: 13 }}
		>
			Edit search
		</Menu.Item>
		<Menu.Item
			key={MENU_CLONE_KEY}
			icon={<FaClone style={{ marginBottom: 0 }}/>}
			onClick={() => onClick?.(MENU_CLONE_KEY)}
			style={{ fontSize: 13 }}
		>
			Clone search
		</Menu.Item>
		{savedSearch?.alerts?.length > 0 && (
			<Menu.Item
				key={MENU_DISCARD_ALERTS_KEY}
				icon={<FaEyeSlash style={{ marginBottom: 0 }} />}
				onClick={() => onClick?.(MENU_DISCARD_ALERTS_KEY)}
				style={{ fontSize: 13 }}
			>
				Discard alerts
			</Menu.Item>)
		}
		<Menu.Item
			key={MENU_DELETE_KEY}
			danger
			icon={<FaTrashAlt style={{ marginBottom: 0 }}/>}
			onClick={() => onClick?.(MENU_DELETE_KEY)}
			style={{ fontSize: 13 }}
		>
			Delete search
		</Menu.Item>
	</Menu>
);
// };
const UserSavedSearchButton = (
	{
		savedSearch,
		onSelectSavedSearch,
		onEditSavedSearch,
		onCloneSavedSearch,
		onDiscardSavedSearchAlerts,
		onDeleteSavedSearch,
		active = false,
		...props
	},
) => {
	const className = 'UserSavedSearchStub';

	const theme = useTheme();
	const { currentUser } = useAuthContext();

	const handleClick = (key) => {
		// LocalDebug.logInfo({ className, method: 'handleClick' }, { key });
		switch (key) {
			case MENU_SELECT_KEY:
				onSelectSavedSearch?.(savedSearch);
				break;
			case MENU_EDIT_KEY:
				onEditSavedSearch?.(savedSearch);
				break;
			case MENU_CLONE_KEY:
				onCloneSavedSearch?.(savedSearch);
				break;
			case MENU_DISCARD_ALERTS_KEY:
				onDiscardSavedSearchAlerts?.(savedSearch);
				break;
			case MENU_DELETE_KEY:
				Modal.confirm({
					title: <>
						<div><b>Delete this search?</b></div>
						There's no going back...</>,
					okText: 'Yes',
					cancelText: 'No',
					onOk() {
						onDeleteSavedSearch?.(savedSearch);
					},
				});
				break;
			default:
				break;
		}
	};

	const popoverContent = <UserSavedSearchSummary savedSearch={savedSearch} />;

	if (savedSearch.is50 && savedSearch.userId !== documentId(currentUser)) {
		return (
			<Popover placement={'bottom'} content={popoverContent}>
				<Button
					// danger
					type={active ? 'primary' : 'default'}
					size={'large'}
					onClick={() => handleClick('select')}
					style={{ fontWeight: 'bold', borderRadius: 6 }}
				>
					<Space wrap={false}>
						{savedSearch?.title}
					</Space>
				</Button>
			</Popover>
		);
	}

	return (
		<Dropdown.Button
			type={active ? 'primary' : 'default'}
			// ghost
			size={'large'}
			overlay={
				menu({ onClick: handleClick, savedSearch })
			}
			icon={<FaSlidersH style={{ marginBottom: -2 }}/>}
			style={{ fontSize: 10 }}
			// danger
			onClick={() => handleClick('select')}
		>
			<Popover placement={'bottom'} content={popoverContent} >
				<span
					style={{
						fontWeight: 'bold',
					}}
				>
					{savedSearch?.title || 'My saved search'}
				</span>
			</Popover>
		</Dropdown.Button>
	);
};

export default UserSavedSearchButton;
