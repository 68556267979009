import { forwardRef } from 'react';

const Box = forwardRef(
	(
		{
			children,
			withShadow = false,
			...props
		},
		ref,
	) => {
		return (
			<div
				ref={ref}
				{...props}
				className={['box',
					withShadow ? 'shadow' : '',
					props?.className,
				].join(' ')}
				style={{
				// padding: '6px 12px',
				// borderRadius: 5,
				// border: '1px solid #eee',
					...withShadow ? { boxShadow: '0px 5px 20px -8px rgba(0,0,0,.3)' } : {},
					...props?.style,
				}}
			>
				{children}
			</div>
		);
	},
);

export default Box;
