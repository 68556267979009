import { useTheme } from '@emotion/react';
import {
	Button, Popconfirm, Tooltip, message,
} from 'antd';
import { BiPlus } from 'react-icons/bi';
import { ArrowRightIcon, documentId } from '../../../../utils/common';
import { LocalDebug } from '../../../../utils/LocalDebug';
import { loadCompanies, loadCompany, postGenderScoreAction } from '../../../../actions/company';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getCompanySelected } from '../../../../reducers/app';
import { COMPANY_RECRUITER_LOG } from '../../../../constants/company-recruiter-log';
import { writeGenderScoreFilledLog } from '../../../../constants/genderscore-logs';
import { useAuthContext } from '../../../../hooks/useAuthContext';

const GenderScoreActionSelectButton = ({
	company,
	forceValue,
	action,
	selectedLabel = 'Remove',
	unselectedLabel = 'Add',
	withSelectIcon = false,
	onAddSuccess,
	onRemoveSuccess,
	withoutGhost,
	size,
	index,
	...props
}) => {
	const className = 'GenderScoreActionSelectButton';

	const theme = useTheme();
	const dispatch = useDispatch();
	const { isDocumentAdmin } = useAuthContext();

	const companySelected = useSelector(getCompanySelected);

	const genderScore = forceValue || company?.genderScore;

	const [disabled, setDisabled] = useState(!isDocumentAdmin);
	const [addButton, setAddButton] = useState(null);
	const [removeButton, setRemoveButton] = useState(null);
	const [isSelected, setIsSelected] = useState(action.isSelected);

	useEffect(() => {
		setDisabled(!isDocumentAdmin);
	}, [isDocumentAdmin]);

	useEffect(() => {
		setIsSelected(action.isSelected);
	}, [action]);

	useEffect(() => {
		setAddButton(
			<Button
				type='primary'
				disabled={disabled}
				size={size}
				onClick={onClick}
				style={{ ...props?.style }}
			>
				<strong>{unselectedLabel}</strong>&nbsp;
				{withSelectIcon && <ArrowRightIcon />}
			</Button>,
		);

		setRemoveButton(
			<Button
				type='primary'
				className='action-item-remove'
				size={size}
				disabled={disabled}
				style={{
					...props?.style,
				}}
			>
				<strong>{selectedLabel}</strong>&nbsp;
				{withSelectIcon && <BiPlus style={{ marginBottom: -2, transform: 'rotate(45deg)' }} />}
			</Button>,
		);
	}, [disabled, isSelected]);

	const onClick = async () => {
		const companyId = documentId(company) || documentId(companySelected);
		LocalDebug.logInfo({ className, method: 'onClick' }, { action, companyId });

		if (!companyId) {
			return;
		}
		setDisabled(true);
		await postGenderScoreAction({
			companyId: documentId(company),
			actionId: action?.value,
			action,
			isActive: !isSelected,
		});
		setDisabled(false);
		await dispatch(loadCompany(companyId));
		message.success(<>
			This action was {isSelected ? 'removed from' : 'added to'} your 🎯 <strong>Action Plan</strong>
		</>);
		if (action.isSelected) {
			onRemoveSuccess?.({ action });
		} else {
			onAddSuccess?.({ action });
		}
		writeGenderScoreFilledLog({
			type: {
				element: action?.value,
				action: COMPANY_RECRUITER_LOG.CLICK,
			},
			context: `action-${isSelected ? 'remove' : 'add'}`,
		});
	};

	if (!isDocumentAdmin) {
		return (
			<Tooltip
				title='Only ADMIN roles of your company can manage the action plan'
			>
				{isSelected ? removeButton : addButton}
			</Tooltip>
		);
	}

	return isSelected
		? (
			<Popconfirm
				title={(
					<div style={{ maxWidth: 300 }}>
						Are you sure you want to <strong>remove</strong> this action from your 🎯 <strong>Action Plan</strong>?
					</div>
				)}
				onConfirm={onClick}
			>
				{removeButton}
			</Popconfirm>
		)
		: addButton;
};

export default GenderScoreActionSelectButton;
