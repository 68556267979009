import { useTheme } from '@emotion/react';
import { Space, Tag } from 'antd';
import { NOT_PROVIDED_YET_LABEL, getJobRoleLabel, userTagStyle } from '../../../constants/constant';

const UserJobRoles = (
	{
		user,
		mode = 'tags',
		size = 'medium',
		withGroups = false,
		forceValue = null,
		full = false,
		notProvided = NOT_PROVIDED_YET_LABEL,
		...props
	},
) => {
	const theme = useTheme();

	// const value = user?.tagTree?.['job-search-job-role'];
	const value = forceValue || user?.jobSearch?.jobRole;
	if (!(value?.length > 0)) return notProvided;

	// if (mode === 'tags') {
	const tagStyle = {
		...userTagStyle(size),
		...props?.style,
	};
	const tags = value.map((tag, index) => <Tag
		color='blue'
		key={index}
		style={tagStyle}
	>
		{/* {getJobRoleLabel(tag.split(':')[1])} */}
		{getJobRoleLabel(tag) || tag}
	</Tag>);
	return tags?.length
		? <Space
			direction='horizontal'
			wrap={true}
			size={[2, 1]}
		>
			{tags}
		</Space>
		: notProvided;
};

export default UserJobRoles;
