/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { request } from '../services/api';
import { useAuthContext } from '../hooks/useAuthContext';
import { arrayToObjectListByProp, documentId, mergeReducer } from '../utils/common';
import { LocalDebug } from '../utils/LocalDebug';

export const AlertContext = React.createContext();

const AlertProvider = ({
	children,
}) => {
	const className = 'AlertProvider';

	const { currentUser } = useAuthContext();
	const [alerts, setAlerts] = useState([]);

	const disableAlert = ({ documentId: docId, alertDocumentId }) => {
		setAlerts(alerts.filter((alert) => !(
			alert.documentId == docId
			&& (!alertDocumentId || alert.alertDocumentId == alertDocumentId)
		)));
	};

	useEffect(() => {
		const fetchAlerts = async () => {
			if (currentUser && documentId(currentUser)) {
				try {
					const { data: { results } } = await request.get(`/alert?userId=${documentId(currentUser)}`);

					const alertsByDocumentIds = Object
						.entries(
							arrayToObjectListByProp(
								results?.[0]?.alerts,
								'documentId',
							),
						)
						.map(([docId, docAlerts]) => {
							const uniqueDocAlertDocumentIds = [
								...new Set(docAlerts.map((a) => a.alertDocumentId)),
							];
							return {
								[docId]: uniqueDocAlertDocumentIds
									.map((id) => ({ documentId: docId, alertDocumentId: id })),
							};
						})
						.reduce(mergeReducer, {});

					const newAlerts = Object.values(alertsByDocumentIds).flat() || [];

					LocalDebug.logUseEffect({ className, effects: 'currentUser' }, {
						alerts: results?.[0]?.alerts?.length,
						uniqueDocumentIds: [
							...new Set(results?.[0]?.alerts?.map((a) => a.documentId)),
						].length,
						uniqueAlertDocumentIds: [
							...new Set(results?.[0]?.alerts?.map((a) => a.alertDocumentId)),
						].length,
						// alertsByDocumentIds,
						// newAlerts,
					});

					setAlerts(newAlerts);
				} catch (e) {}
			}
		};
		fetchAlerts();
	}, [currentUser]);

	const value = {
		alerts, disableAlert,
	};

	return (
		<AlertContext.Provider
			value={value}
		>
			{children}
		</AlertContext.Provider>
	);
};

export default AlertProvider;
