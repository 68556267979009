import { ENGLISH_LANG } from '../../../../constants/constant';
import CompanyTextEditor from '../shared/CompanyTextEditor';

const CompanyPathEditor = (
	{
		company,
		lang = ENGLISH_LANG,
		setEditorOpen,
	},
) => {
	const className = 'CompanyPathEditor';

	const title = 'Path';
	const field = 'path';

	return (
		<CompanyTextEditor
			company={company}
			lang={lang}
			title={<b>{title}</b>}
			field={field}
			setEditorOpen={setEditorOpen}
			isTranslatable={false}
		/>
	);
};

export default CompanyPathEditor;
