import GridValueCard from '../../app/card/GridValueCard';
import { NOT_PROVIDED_YET_LABEL } from '../../../constants/constant';
import EntriesCardBody from './EntriesCardBody';

const EntriesCard = (
	{
		entries,
		RowRenderer,
		LineRenderer,
		title,
		details,
		hideIfEmpty = true,
		limit = 1,
		notProvided = NOT_PROVIDED_YET_LABEL,
		children,
		...props
	},
) => {
	return ((entries && entries?.length > 0) || !hideIfEmpty
		? <GridValueCard
			title={<>
				{title}
					&nbsp;<span style={{ verticalAlign: 'middle', opacity: 0.7, fontSize: 14 }}>
                        ({entries?.length})
				</span>
			</>}
			details={details}
			{...props}
		>
			{children
					|| <EntriesCardBody
						entries={entries}
						RowRenderer={RowRenderer}
						LineRenderer={LineRenderer}
						limit={limit}
						notProvided={notProvided}
						{...props}
					/>}
		</GridValueCard>
		: null
	);
};

export default EntriesCard;
