import { useSelector } from 'react-redux';
import PageLayout from '../../components/app/layout/PageLayout';
import { useTheme } from '@emotion/react';
import { getCompanySelected } from '../../reducers/app';
import CompanyGridPicker from '../../components/company/CompanyGridPicker';
import UMSPresentation from '../../components/ums/pages/UMSPresentation';
import UMSPresentationMenu from '../../menu/ums/UMSPresentationMenu';

const UMSPresentationPage = () => {
	const className = 'UMSPresentationPage';

	const theme = useTheme();

	const companySelected = useSelector(getCompanySelected);

	if (!companySelected) return <CompanyGridPicker />;

	const menu = (new UMSPresentationMenu()).build();

	return (
		<PageLayout
			icon={menu.icon}
			title={menu.label}
		>
			<UMSPresentation />
		</PageLayout>
	);
};

export default UMSPresentationPage;
