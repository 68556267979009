import FeedbacksPage from '../../../containers/staff/product/FeedbacksPage';
import { PERMS_GRANT_STAFF } from '../../menu.utils';

const menu = {
	name: 'staff-product-feedbacks',
	path: '/staff/product/feedbacks',
	label: 'Feedbacks',
	icon: <span>💌</span>,
	component: FeedbacksPage,
	perms: PERMS_GRANT_STAFF,
};

export default menu;
