import AnalyticsPage from '../../../containers/staff/data/AnalyticsPage';
import { FaMouse } from 'react-icons/fa';
import { PERMS_GRANT_STAFF } from '../../menu.utils';

const menu = {
	name: 'staff-data-analytics',
	path: '/staff/data/analytics',
	label: 'Analytics',
	icon: <FaMouse />,
	component: AnalyticsPage,
	perms: PERMS_GRANT_STAFF,
};

export default menu;
