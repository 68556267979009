import { MAILJET_STATUS_DATA } from './mailjet/mailjetStatus';

export const MAILING_STATUS_FAILED_ID = 'failed';
export const MAILING_STATUS_FAILED_LABEL = 'Failed';
export const MAILING_STATUS_FAILED_COLOR = '#f00';
export const MAILING_STATUS_FAILED = {
	id: MAILING_STATUS_FAILED_ID,
	label: MAILING_STATUS_FAILED_LABEL,
	color: MAILING_STATUS_FAILED_COLOR,
};

export const MAILING_STATUS_RETRIED_ID = 'retried';
export const MAILING_STATUS_RETRIED_LABEL = 'Retried';
export const MAILING_STATUS_RETRIED_COLOR = '#f90';
export const MAILING_STATUS_RETRIED = {
	id: MAILING_STATUS_RETRIED_ID,
	label: MAILING_STATUS_RETRIED_LABEL,
	color: MAILING_STATUS_RETRIED_COLOR,
};

export const MAILING_STATUS_TALENT_ANSWER_ACCEPTED_ID = 'talent-answer-accepted';
export const MAILING_STATUS_TALENT_ANSWER_ACCEPTED_LABEL = 'Accepted';
export const MAILING_STATUS_TALENT_ANSWER_ACCEPTED_COLOR = 'green';
export const MAILING_STATUS_TALENT_ANSWER_ACCEPTED = {
	id: MAILING_STATUS_TALENT_ANSWER_ACCEPTED_ID,
	label: MAILING_STATUS_TALENT_ANSWER_ACCEPTED_LABEL,
	color: MAILING_STATUS_TALENT_ANSWER_ACCEPTED_COLOR,
};

export const MAILING_STATUS_TALENT_ANSWER_DECLINED_ID = 'talent-answer-declined';
export const MAILING_STATUS_TALENT_ANSWER_DECLINED_LABEL = 'Declined';
export const MAILING_STATUS_TALENT_ANSWER_DECLINED_COLOR = 'magenta';
export const MAILING_STATUS_TALENT_ANSWER_DECLINED = {
	id: MAILING_STATUS_TALENT_ANSWER_DECLINED_ID,
	label: MAILING_STATUS_TALENT_ANSWER_DECLINED_LABEL,
	color: MAILING_STATUS_TALENT_ANSWER_DECLINED_COLOR,
};

export const MAILING_STATUS_DATA = {
	MAILING_STATUS_FAILED,
	MAILING_STATUS_RETRIED,
	MAILING_STATUS_TALENT_ANSWER_ACCEPTED,
	MAILING_STATUS_TALENT_ANSWER_DECLINED,
	...MAILJET_STATUS_DATA,
};
export const MAILING_STATUS_LIST = Object.values(MAILING_STATUS_DATA);
