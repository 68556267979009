import { useTheme } from '@emotion/react';
import moment from 'moment';
import { Col, Row } from 'antd';
import { CalendarOutlined, EnvironmentOutlined, ShopOutlined } from '@ant-design/icons';

const UserResumeExperienceEntryRow = (
	{
		entry,
		...props
	},
) => {
	const theme = useTheme();

	const {
		organization, title, startDate, endDate, isPresent, duration, location, details,
	} = entry;
	return <Row gutter={[20, 20]} {...props}>
		<Col span={details?.length > 0 ? 8 : 24}>

			<div
				style={{
					fontSize: 18,
					fontWeight: 'bold',
					lineHeight: 1.2,
					marginBottom: 8,
					...props?.style?.title,
				}}
			>
				{title}
			</div>

			<div
				style={{
					fontSize: 14,
					fontWeight: 'bold',
					lineHeight: 1.2,
					color: theme.color.darkgrey,
					marginBottom: 4,
					...props?.style?.organization,
				}}
			>
				<ShopOutlined/>&nbsp;{organization}
			</div>

			<p
				style={{
					fontSize: 13,
					color: theme.color.darkgrey,
					...props?.style?.date,
				}}
			>
				<CalendarOutlined style={{ color: theme.color.grey }}/>&nbsp;{[
					moment(startDate).format('MMMM YYYY'),
					isPresent ? 'Present' : moment(endDate).format('MMMM YYYY'),
				].join(' - ')} ({duration})
				{location
                    && <>
                    	<br/>
                    	<EnvironmentOutlined style={{ color: theme.color.grey }}/>&nbsp;{location}
                    </>
				}
			</p>
		</Col>

		{details?.length > 0 && (
			<Col
				span={16}
				style={{
					...props?.style?.details,
				}}
			>
				<p>
					{details?.map((detail, dIndex, arr) => <span
						key={dIndex}
					>
						{detail} {(dIndex < (arr.length - 1)) && <br/>}
					</span>)}
				</p>
			</Col>
		)}
	</Row>;
};

export default UserResumeExperienceEntryRow;
