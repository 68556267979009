import { useTheme } from '@emotion/react';
import GenderScoreLayoutGrid from '../layout/GenderScoreLayoutGrid';
import Link from '../../../app/Link';
import GenderScoreActionBox from './GenderScoreActionBox';
import { useMemo, useState } from 'react';
import { BiChevronDown, BiChevronUp } from 'react-icons/bi';
import { COMPANY_RECRUITER_LOG } from '../../../../constants/company-recruiter-log';
import { writeGenderScoreFilledLog } from '../../../../constants/genderscore-logs';
import { ACTION_GRID_COLLAPSE, getPillarActions } from '../genderscore.utils';
import { LocalDebug } from '../../../../utils/LocalDebug';

const GenderScoreActionGrid = ({
	company,
	forceValue,
	dataSource,
	collapsed = ACTION_GRID_COLLAPSE,
	pillar,
	withSelectButton,
	withExpandLimit = true,
	index,
	onAddSuccess,
	onRemoveSuccess,
	...props
}) => {
	const className = 'GenderScoreActionGrid';

	const theme = useTheme();

	const [expandResults, setExpandResults] = useState(false);
	const [refresh, setRefresh] = useState(0);

	const data = useMemo(() => {
		// LocalDebug.logUseEffect({ className, effects: 'dataSource, company, forceValue, pillar, refresh' }, { dataSource });
		if (dataSource) {
			return dataSource;
		}
		const { pillarActions } = getPillarActions({ company, forceValue, pillar });
		// LocalDebug.logUseEffect(
		// 	{ className, effects: 'dataSource, company, forceValue, pillar, refresh' },
		// 	{ pillarActions: pillarActions.map((item) => `${item.value} > ${item.isSelected}`) },
		// );
		return pillarActions;
	}, [dataSource, company, forceValue, pillar, refresh]);

	if (!data?.length > 0) return null;

	const handleAddSuccess = async ({ action }) => {
		setRefresh((p) => p + 1);
		return onAddSuccess?.({ action });
	};

	const handleRemoveSuccess = async ({ action }) => {
		setRefresh((p) => p + 1);
		return onRemoveSuccess?.({ action });
	};

	return (
		<div
			{...props}
		>
			<GenderScoreLayoutGrid
				columns={'1fr 1fr 1fr 1fr'}
				style={{
					...props?.style?.grid,
				}}
			>
				{(data
					.filter((item) => item)
					.slice(0, !withExpandLimit || expandResults
						? data.length
						: collapsed)
				)
					.map((item, itemIndex) => (
						item && (
							<GenderScoreActionBox
								company={company}
								forceValue={forceValue}
								action={item}
								pillar={item.pillar || pillar}
								index={itemIndex}
								pillarIndex={item.pillarIndex}
								levelIndex={item.levelIndex}
								withSelectButton={withSelectButton}
								isSelected={item.isSelected}
								key={itemIndex}
								onAddSuccess={handleAddSuccess}
								onRemoveSuccess={handleRemoveSuccess}
							/>
						)
					))}

			</GenderScoreLayoutGrid>

			{withExpandLimit && data.length > collapsed && (
				<div style={{ marginTop: 12 }}>
					<Link
						onClick={() => {
							writeGenderScoreFilledLog({
								type: {
									element: expandResults ? 'collapse' : 'expand',
									action: COMPANY_RECRUITER_LOG.CLICK,
								},
								context: 'actions-expander',
							});

							setExpandResults((p) => !p);
						}}
					>
						{expandResults
							? (
								<>
									Collapse actions&nbsp;
									<BiChevronUp style={{ fontSize: 18, marginBottom: -3 }} />
								</>
							)
							: (
								<>
									Expand all {data.length} actions&nbsp;
									<BiChevronDown style={{ fontSize: 18, marginBottom: -3 }} />
								</>
							)}
					</Link>
				</div>
			)}
		</div>
	);
};

export default GenderScoreActionGrid;
