import { useTheme } from '@emotion/react';
import CompanyActionModel from '../../../../models/CompanyActionModel';
import ModalContainerBase from '../../../../contexts/modals/ModalContainerBase';
import CompanyActionContentLeftSide from './CompanyActionContentLeftSide';
import CompanyActionContentRightSide from './CompanyActionContentRightSide';
import CompanyActionContent from './CompanyActionContent';

const CompanyActionModal = ({
	open,
	setOpen,
	action,
	view,
	index,
	withActionManager,
	withAddButton,
	onAddAction,
	onEditAction,
	onDeleteAction,
	isSendActionPending,
	setIsSendActionPending,
	...props
}) => {
	const className = 'CompanyActionModal';

	const theme = useTheme();

	action = action instanceof CompanyActionModel
		? action
		: new CompanyActionModel(action);

	return (
		<ModalContainerBase
			className='action-modal'
			open={open}
			setOpen={setOpen}
			width={1180}
			style={{
				position: 'relative',
				padding: '0 !important',
				marginTop: 30,
				marginBottom: 30,
				maxHeight: 'calc(100vh - 60px)',
			}}
		>
			<CompanyActionContent
				action={action}
				view={view}
				index={index}
				withActionManager={withActionManager}
				withAddButton={withAddButton}
				onAddAction={onAddAction}
				onEditAction={onEditAction}
				onDeleteAction={onDeleteAction}
				isSendActionPending={isSendActionPending}
				setIsSendActionPending={setIsSendActionPending}
				{...props}
			/>

		</ModalContainerBase>
	);
};

export default CompanyActionModal;
