import { useSelector } from 'react-redux';
import PageLayout from '../../components/app/layout/PageLayout';
import { useTheme } from '@emotion/react';
import { getCompanySelected } from '../../reducers/app';
import CompanyGridPicker from '../../components/company/CompanyGridPicker';
import GenderScoreResults from '../../components/company/genderscore/results/GenderScoreResults';
import CompanyModel from '../../models/CompanyModel';
import CompanyGenderScorePresentation from '../../components/company/genderscore/CompanyGenderScorePresentation';
import { SurveyContext } from '../../components/genderscore/survey/SurveyProvider';
import { useContext } from 'react';
import SurveySubmissionPending from '../../components/genderscore/survey/SurveySubmissionPending';

const GenderScorePage = () => {
	const className = 'GenderScorePage';

	const theme = useTheme();

	const companySelected = useSelector(getCompanySelected);
	const { isSurveySubmissionPending } = useContext(SurveyContext);

	if (!companySelected) return <CompanyGridPicker />;

	if (isSurveySubmissionPending) return <SurveySubmissionPending />;

	return (
		<PageLayout
			withHeader={false}
			withPadding={false}
		>
			{new CompanyModel(companySelected).isGenderScoreFilled()
				? (
					<GenderScoreResults
						company={companySelected}
					/>
				)
				: (
					<CompanyGenderScorePresentation />
				)
			}
		</PageLayout>
	);
};

export default GenderScorePage;
