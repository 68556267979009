import { Col, Row } from 'antd';
import AccountCard from './AccountCard';
import UserStub from '../user/stub/UserStub';
import Box from '../app/box/Box';
import AccountProfileForm from './AccountProfileForm';
import UserCalendlyLink from '../user/widgets/UserCalendlyLink';
import { useAuthContext } from '../../hooks/useAuthContext';

const AccountProfileCard = (
	{
		user,
	},
) => {
	const className = 'AccountProfileCard';

	const {
		profileSubtitle,
		isCalendlyFieldVisible,
	} = useAuthContext();

	if (!user) return null;

	return (
		<AccountCard
			title={<>👋 Your profile</>}
			details={profileSubtitle}
			user={user}
			AccountForm={AccountProfileForm}
		>
			<Row gutter={[10, 10]}>
				<Col span={24}>
					<Box style={{ padding: '12px 20px' }}>
						<UserStub user={user} avatarSize={48} />
					</Box>
				</Col>
				{isCalendlyFieldVisible && (
					<Col span={24}>
						<Box>
							<b>Your Calendly:</b> <UserCalendlyLink user={user} />
						</Box>
					</Col>
				)}
			</Row>

		</AccountCard>
	);
};

export default AccountProfileCard;
