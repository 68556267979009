import { ENGLISH_LANG, GENDERSCORE_LABEL } from '../../../../constants/constant';
import CompanyModel from '../../../../models/CompanyModel';
import { LocalDebug } from '../../../../utils/LocalDebug';
import {
	countMandatorySteps,
	COMPANY_PAGE_STEP_GENDERSCORE,
	areAllMandatoryStepsCompleted,
	areAllOptionalStepsCompleted,
	getIncompleteMandatoryStepList,
	getMandatorySteps,
} from './CompanyPageSteps';

const className = 'CompanyPageEditionStates';

export const COMPANY_PAGE_EDITION_STATE_START_ID = 'start';
export const COMPANY_PAGE_EDITION_STATE_IN_PROGRESS_ID = 'in-progress';
export const COMPANY_PAGE_EDITION_STATE_PENDING_PUBLICATION_ID = 'pending-publication';
export const COMPANY_PAGE_EDITION_STATE_PUBLISHED_PENDING_GENDERSCORE_ID = 'published-pending-genderscore';
export const COMPANY_PAGE_EDITION_STATE_PUBLISHED_WITH_OPTIONAL_FIELDS_ID = 'published-optional-fields';
export const COMPANY_PAGE_EDITION_STATE_PUBLISHED_ID = 'published';

export const COMPANY_PAGE_EDITION_STATE_START = {
	id: COMPANY_PAGE_EDITION_STATE_START_ID,
	title: 'Welcome',
	isActive: ({ company, lang }) => true,
};

export const COMPANY_PAGE_EDITION_STATE_IN_PROGRESS = {
	id: COMPANY_PAGE_EDITION_STATE_IN_PROGRESS_ID,
	title: 'In Progress',
	isActive: ({ company, lang }) => (
		getIncompleteMandatoryStepList({ company, lang })
			?.length < countMandatorySteps({ company, lang })
	),
};

export const COMPANY_PAGE_EDITION_STATE_PENDING_PUBLICATION = {
	id: COMPANY_PAGE_EDITION_STATE_PENDING_PUBLICATION_ID,
	title: 'Pending publication',
	description: 'Pending publication',
	isActive: ({ company, lang }) => areAllMandatoryStepsCompleted({ company, lang }),
};

export const COMPANY_PAGE_EDITION_STATE_PUBLISHED_PENDING_GENDERSCORE = {
	id: COMPANY_PAGE_EDITION_STATE_PUBLISHED_PENDING_GENDERSCORE_ID,
	title: `Pending ${GENDERSCORE_LABEL}`,
	description: `Pending ${GENDERSCORE_LABEL}`,
	isActive: ({ company, lang }) => (
		(lang === ENGLISH_LANG
			? new CompanyModel(company).hasEverBeenPublished()
			: new CompanyModel(company).isTranslationPublished
		)
		&& (
			new CompanyModel(company).isGenderScoreEnabled()
			&& !COMPANY_PAGE_STEP_GENDERSCORE.isComplete({ company, lang })
		)
	),
};

export const COMPANY_PAGE_EDITION_STATE_PUBLISHED_WITH_OPTIONAL_FIELDS = {
	id: COMPANY_PAGE_EDITION_STATE_PUBLISHED_WITH_OPTIONAL_FIELDS_ID,
	title: 'Published, optional fields to fill',
	description: 'Published',
	isActive: ({ company, lang }) => (
		(lang === ENGLISH_LANG
			? new CompanyModel(company).hasEverBeenPublished()
			: new CompanyModel(company).isTranslationPublished
		)
		&& (
			!new CompanyModel(company).isGenderScoreEnabled()
			|| COMPANY_PAGE_STEP_GENDERSCORE.isComplete({ company, lang })
		)
		&& !areAllOptionalStepsCompleted({ company, lang })
	),
};

export const COMPANY_PAGE_EDITION_STATE_PUBLISHED = {
	id: COMPANY_PAGE_EDITION_STATE_PUBLISHED_ID,
	title: 'Published',
	description: 'Published',
	isActive: ({ company, lang }) => (
		(lang === ENGLISH_LANG
			? new CompanyModel(company).hasEverBeenPublished()
			: new CompanyModel(company).isTranslationPublished
		)
		&& (
			!new CompanyModel(company).isGenderScoreEnabled()
			|| COMPANY_PAGE_STEP_GENDERSCORE.isComplete({ company, lang })
		)
	&& areAllOptionalStepsCompleted({ company, lang })
	),
};

export const COMPANY_PAGE_EDITION_STATES = [
	COMPANY_PAGE_EDITION_STATE_START,
	COMPANY_PAGE_EDITION_STATE_IN_PROGRESS,
	COMPANY_PAGE_EDITION_STATE_PENDING_PUBLICATION,
	COMPANY_PAGE_EDITION_STATE_PUBLISHED_PENDING_GENDERSCORE,
	COMPANY_PAGE_EDITION_STATE_PUBLISHED_WITH_OPTIONAL_FIELDS,
	COMPANY_PAGE_EDITION_STATE_PUBLISHED,
];

export const getCurrentEditionState = ({ company, lang }) => {
	const [state] = COMPANY_PAGE_EDITION_STATES
		.filter((item) => item.isActive({ company, lang }))
		.reverse();
	return state;
};
