import { Space, Tag, Tooltip } from 'antd';
import { FaTags } from 'react-icons/fa';
import { useAuthContext } from '../../../hooks/useAuthContext';
import Callout from '../../app/callout/Callout';
import AdminCommandGetButton from './AdminCommandGetButton';
import { useEffect, useState } from 'react';

const AdminComputeModelTagsButton = (
	{
		Icon = FaTags,
		modelName = 'User',
		tagCategories = null,
		shouldUpdateInducedTags = true,
		withSideDetails = false,
	},
) => {
	const className = 'AdminComputeModelTagsButton';

	const {
		isAdmin, currentUser,
	} = useAuthContext();

	const [tagCategoriesDisplay, setTagCategoriesDisplay] = useState();

	useEffect(() => {
		if (!tagCategories || tagCategories?.length < 1) {
			setTagCategoriesDisplay(<b>all</b>);
		} else {
			setTagCategoriesDisplay(
				<Tooltip
					title={tagCategories.map((c) => <Tag key={c}>{c}</Tag>)}
				>
					<b>{tagCategories.length}</b>
				</Tooltip>,
			);
		}
	}, [tagCategories]);

	if (!currentUser) return null;

	if (!isAdmin) return null;

	return (
		<Space align='top' size={20}>
			<AdminCommandGetButton
				Icon={Icon}
				title={<>Recompute {tagCategoriesDisplay} tag categories for <b>{modelName}</b> collection</>}
				path={`/admin/tag/${modelName}/update`}
				params={{
					tagCategories,
					shouldUpdateInducedTags,
				}}
				disabled={!modelName}
			/>
			{withSideDetails && (
				<div>
					This script will launch the following operations:
					<ol>
						<li>
							For all <b>{modelName}</b> documents,
							reset and recompute the tags for {tagCategoriesDisplay} tag categories
						</li>
						{shouldUpdateInducedTags && (
							<li>
								Apply the <b>induced tagging rules</b> to complete the list of tags
							</li>
						)}
					</ol>
					<Callout withFeedback={false}>
						This script can take a few minutes
					</Callout>
				</div>
			)}
		</Space>
	);
};

export default AdminComputeModelTagsButton;
