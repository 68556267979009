import { META_ALT_MAX_LENGTH, META_DESCRIPTION_MAX_LENGTH, META_TITLE_MAX_LENGTH } from '../../constants/constant';
import ArticleModel from '../../models/ArticleModel';
import { META_DEFAULT_ARTICLE_ALT_PREFIX, META_DEFAULT_COMPANY_ALT_PREFIX } from './MetaConstants';
import striptags from 'striptags';

/**
 * Creates a default meta title tag
 * @param {*} metaHolder ArticleModel or CompanyModel  object
 * @param {*} locale
 * @returns meta tag
 */
export const getDefaultMetaTitle = (metaHolder, locale) => {
	return metaHolder instanceof ArticleModel
		? getDefaultArticleMetaTitle(metaHolder, locale)
		: getDefaultCompanyMetaTitle(metaHolder);
};

/**
 * Creates a default meta description tag
 * @param {*} metaHolder ArticleModel or CompanyModel  object
 * @param {*} locale
 * @returns meta tag
 */
export const getDefaultMetaDescription = (metaHolder, locale) => {
	return metaHolder instanceof ArticleModel
		? getDefaultArticleMetaDescription(metaHolder, locale)
		: getDefaultCompanyMetaDescription(metaHolder, locale);
};

/**
 * Creates a default meta altImage tag
 * @param {*} metaHolder ArticleModel or CompanyModel  object
 * @param {*} locale
 * @returns meta tag
 */
export const getDefaultMetaAltImage = (metaHolder, locale) => {
	return metaHolder instanceof ArticleModel
		? getDefaultArticleMetaAltImage(metaHolder, locale)
		: getDefaultCompanyMetaAltImage(metaHolder, locale);
};

/**
 * Creates the default meta tag for the article title
 * composed by the first characters of the "title" field
 * @param {*} articleModel
 * @param {*} locale
 * @returns Default meta tag for the articleModel title
 */
const getDefaultArticleMetaTitle = (articleModel, locale) => {
	return striptags((
		articleModel?.translations?.[locale]?.title
			? articleModel?.translations?.[locale]
			: articleModel
	)?.title)?.substring(0, META_TITLE_MAX_LENGTH);
};

/**
 * Creates the default meta tag for the article description,
 * composed by the first characters of the "content" field
 * If the there's no translation for that locale, the EN version is used
 * @param {*} articleModel
 * @param {*} locale
 * @returns Default meta tag for the article description.
 */
const getDefaultArticleMetaDescription = (articleModel, locale) => {
	return striptags((
		articleModel?.translations?.[locale]?.content
			? articleModel?.translations?.[locale]
			: articleModel
	)?.content)?.substring(0, META_DESCRIPTION_MAX_LENGTH);
};

/**
 * Creates the default meta tag for the article publisher,
 * @returns Default meta tag for the article publisher.
 */
export const getDefaultArticleMetaPublisher = () => {
	return '50inTech';
};

/**
 * Creates the default meta tag for the company title
 * @param {*} companyModel
 * @returns Default meta tag for the company title
 */
const getDefaultCompanyMetaTitle = (companyModel) => {
	return (`${companyModel?.name} - 50inTech`)?.substring(0, META_TITLE_MAX_LENGTH);
};

/**
 * Creates the default meta tag for the company description,
 * composed by the 220 first characters of the "What we like about" section
 * If the there's no translation for that locale, the EN version is used
 * @param {*} companyModel
 * @param {*} locale
 * @returns Default meta tag for the company description.
 */
const getDefaultCompanyMetaDescription = (companyModel, locale) => {
	return striptags((
		companyModel?.translations?.[locale]?.weLike
			? companyModel?.translations?.[locale]
			: companyModel
	)?.weLike)?.substring(0, META_DESCRIPTION_MAX_LENGTH);
};

/**
 * Creates the default meta tag for the company's logo alternative text
 * @param {*} companyModel
 * @param {*} locale
 * @returns Default meta tag for the company logo.
 */
export const getDefaultCompanyMetaAltImage = (companyModel, locale) => {
	return striptags(META_DEFAULT_COMPANY_ALT_PREFIX[locale] + companyModel?.name)?.substring(0, META_ALT_MAX_LENGTH);
};

/**
 * Creates the default meta tag for the article's illustration alternative text
 * @param {*} articleModel
 * @param {*} locale
 * @returns Default meta tag for the article illustration.
 */
export const getDefaultArticleMetaAltImage = (articleModel, locale) => {
	return striptags(META_DEFAULT_ARTICLE_ALT_PREFIX[locale] + articleModel?.title)?.substring(0, META_ALT_MAX_LENGTH);
};
