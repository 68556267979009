import PageLayout from '../../components/app/layout/PageLayout';
import {
	Divider, Space, Tabs, Tooltip,
} from 'antd';
import * as adminActions from '../../actions/admin';
import { useDispatch, useSelector } from 'react-redux';
import { useTheme } from '@emotion/react';
import { useAuthContext } from '../../hooks/useAuthContext';
import AdminToolsSectionContainer from '../../components/staff/tools/AdminToolsSectionContainer';
import { buildConfirmBtn, buildConfirmBtnStyleLarge } from '../../utils/common';
import { getCompanySelected } from '../../reducers/app';
import {
	DatabaseOutlined, FireOutlined, InboxOutlined, ThunderboltOutlined,
} from '@ant-design/icons';
import StaffHealthMenu from '../../menu/staff/StaffHealthMenu';

const StaffHealthPage = () => {
	const theme = useTheme();
	const dispatch = useDispatch();

	const companySelected = useSelector(getCompanySelected);

	const {
		isAdmin, isStaff, currentUser,
	} = useAuthContext();

	if (!currentUser) return null;

	return (
		<PageLayout
			MenuClass={StaffHealthMenu}
		>
			<Tabs>

				<Tabs.TabPane
					tab={<>Jobs</>}
					key='jobs'
				>

					<Space direction='vertical'>

						{/* <AdminCommandGetButton
							title={'Remap GenderScore surveys answer to V3'}
							path='/admin/company/remap-genderscore-survey-answers-v3'
						/>

						<AdminToolsSectionContainer title='Candidate Job Sliders'>
							<AdminCommandGetButton
								api={apiCandidateRequest}
								path={'/dashboard/cron/fetchJobSlider'}
								title={'api.50intech.com/dashboard/cron/fetchJobSlider'}
							/>
						</AdminToolsSectionContainer> */}

						<AdminToolsSectionContainer title='Jobs' Icon={DatabaseOutlined}>

							Total jobs:
							<Space direction='horizontal' size={4} wrap={true}>
								{/* {buildConfirmBtn({
									theme,
									title: 'Compute period stats (with storage bypass)',
									Icon: ThunderboltOutlined,
									dispatcher: adminActions.computePeriodStatsBypassStorage,
									dispatch,
									style: { ...buildConfirmBtnStyleLarge, color: theme.color.orange },
								})} */}
							</Space>
						</AdminToolsSectionContainer>

						<AdminToolsSectionContainer title='Candidates' Icon={InboxOutlined}>
							Candidates
						</AdminToolsSectionContainer>

						<AdminToolsSectionContainer title='Action plan' Icon={FireOutlined}>
							Action plans
						</AdminToolsSectionContainer>
					</Space>

				</Tabs.TabPane>

			</Tabs>

		</PageLayout>
	);
};

export default StaffHealthPage;
