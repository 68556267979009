import CandidatesPage from '../../containers/sourcing/CandidatesPage';
import { PERMS_GRANT_ATS_SYNCABLE_DENY_EDITOR } from '../menu.utils';
import MenuBase from '../MenuBase';
import { SolutionOutlined } from '@ant-design/icons';

export const GenderhireCandidatesAtsIcon = SolutionOutlined;

class GenderhireCandidatesAtsMenu extends MenuBase {
	static props = {
		name: 'candidates-ats',
		path: '/candidates',
		aliases: ['^/sourced$', '^/applications$'],
		label: 'Candidates',
		icon: <GenderhireCandidatesAtsIcon />,
		iconLegacy: '👩‍🚀️',
		Component: CandidatesPage,
		onEnterProps: { shouldLoadAtsData: true },
		perms: PERMS_GRANT_ATS_SYNCABLE_DENY_EDITOR,
	};

	constructor() {
		super(GenderhireCandidatesAtsMenu.props);
	}
}

export default GenderhireCandidatesAtsMenu;
