import { getCompanies, getCompanySelected } from '../../reducers/app';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { add, remove, resendInvitation } from '../../actions/acls';
import {
	Alert, message, Modal, Space,
	Switch,
} from 'antd';
import { CONFIGURE_USERS_DETAILS } from '../../constants/constant';
import {
	addMultipleQueryParams, documentId, getQueryParams, clearQueryParams,
} from '../../utils/common';
import { localDebug } from '../../utils/LocalDebug';
import PageLayout from '../../components/app/layout/PageLayout';
import AclTable from '../../components/acls/AclTable';
import AclAddPopover from '../../components/acls/AclAddPopover';
import AclEditPopover from '../../components/acls/AclEditPopover';
import AclService from '../../services/acl';
import CompanyStub from '../../components/company/CompanyStub';
import UserStub from '../../components/user/stub/UserStub';
import AddButton from '../../components/app/button/AddButton';
import { useAuthContext } from '../../hooks/useAuthContext';
import FilterButton from '../../components/app/filters/FilterButton';
import AclFilterModal from '../../components/acls/AclFilterModal';
import AclUserTable from '../../components/acls/AclUserTable';
import InlineNavLink from '../../components/app/button/InlineNavLink';
import { FaChevronRight } from 'react-icons/fa';
import CompanyModel from '../../models/CompanyModel';
import ConfigureUsersMenu from '../../menu/configure/ConfigureUsersMenu';

const ConfigureUsersPage = () => {
	const className = 'ConfigureUsersPage';

	const dispatch = useDispatch();

	const companySelected = useSelector(getCompanySelected);

	const queryParams = getQueryParams();

	const {
		isAclEditor, isAdmin, isStaffView,
	} = useAuthContext();
	const [isGroupedUsersVersion, setIsGroupedUsersVersion] = useState(isAdmin);
	// const [isGroupedUsersVersion, setIsGroupedUsersVersion] = useState(false);

	const [optionsFilterTable, setOptionsFilterTable] = useState({
		...queryParams || {},
		companyId: documentId(companySelected),
		documentId: documentId(companySelected),
	});
	const [acls, setAcls] = useState([]);
	const [isRefreshDataLayout, setIsRefreshDataLayout] = useState(0);

	const [openAddAcl, setOpenAddAcl] = useState(false);
	const [openEditAcl, setOpenEditAcl] = useState(false);
	const [initAclValuesForm, setInitAclValuesForm] = useState({});

	// TODO: Read the isGenderScorePlan value from the Context
	const [companyModel, setCompanyModel] = useState(null);
	const [isGenderScorePlan, setIsGenderScorePlan] = useState(false);

	useEffect(() => {
		setInitAclValuesForm((p) => ({
			...p,
			companyId: documentId(companySelected),
		}));
		setOptionsFilterTable((prev) => ({
			...prev, documentId: documentId(companySelected), companyId: documentId(companySelected),
		}));
		if (companySelected) {
			const newCompanyModel = new CompanyModel(companySelected);
			setCompanyModel(newCompanyModel);
			setIsGenderScorePlan(newCompanyModel?.isGenderScoreCompanyPlan());
		} else {
			setCompanyModel({});
			setIsGenderScorePlan(false);
		}
	}, [companySelected]);

	const refreshTable = () => {
		setIsRefreshDataLayout((prev) => prev + 1);
	};

	// ----------------------------------------------------- //
	// --------- after loading data sourcings -------------- //
	// ----------------------------------------------------- //
	const handleLoadedData = ({ items } = {}) => {
		setAcls(items || []);
	};

	// --------------------------------------------- //
	// ---------- save acl ------------------------- //
	// --------------------------------------------- //
	const handleSubmit = async (values) => {
		const { roleId, userId, documentId: companyId } = values;
		const { data: { isAclExist } } = await AclService.aclExists(roleId, { userId, companyId });

		if (isAclExist) {
			message.error('This access already exists for this user');
			return;
		}

		dispatch(add(values));
		setOpenAddAcl(false);
		setOpenEditAcl(false);
		refreshTable();
	};

	const handleCloseWithChange = () => {
		setOpenAddAcl(false);
		setOpenEditAcl(false);
		refreshTable();
	};

	// --------------------------------------------- //
	// ---------- open add acl form ---------------- //
	// --------------------------------------------- //
	const handleAddAcl = () => {
		localDebug('handleAddAcl');
		setInitAclValuesForm({
			companyId: documentId(companySelected),
		});
		setOpenAddAcl(true);
	};

	// --------------------------------------------- //
	// ---------- open edit acl form --------------- //
	// --------------------------------------------- //
	const handleEditAcl = (row = {}) => {
		localDebug('handleEditAcl', { row });
		setInitAclValuesForm(row);
		setOpenEditAcl(true);
	};

	// ------------------------------------------- //
	// ----------- update acl -------------------- //
	// ------------------------------------------- //
	const onToggleIsActiveAcl = (data) => {
		dispatch(remove(data));
		refreshTable();
	};

	const handleResetForm = () => {
		// setInitAclValuesForm({});
		setOpenAddAcl(false);
		setOpenEditAcl(false);
	};

	// --------------------------------------- //
	// ------------ handle filter ------------ //
	// --------------------------------------- //
	const handleFilter = (values) => {
		const { tags, ...rest } = values;
		localDebug('Acls handleFilter', { values });
		addMultipleQueryParams(rest);
		setOptionsFilterTable((prev) => ({
			...prev,
			...rest,
			tags,
			documentId: documentId(companySelected),
			companyId: documentId(companySelected),
		}));
	};

	const handleClearFilter = () => {
		setOptionsFilterTable({
			documentId: documentId(companySelected),
			companyId: documentId(companySelected),
		});
		refreshTable();
		clearQueryParams();
	};

	// -------------------------------------------------- //
	// --------- init user options autoComple ----------- //
	// -------------------------------------------------- //
	const handleSearchAutoComplete = async (options) => {
		const { data: { items } } = await AclService.findAll(options, '/groupBy');

		return (items || []).map((item) => {
			const { id: option } = item;
			if (options.searchField === 'company') {
				return {
					value: option.name,
					label: <CompanyStub company={option} withLink={false} />,
				};
			}
			return {
				value: `${option.firstName} ${option.lastName}`,
				label: <UserStub user={option} withLink={false} />,
			};
		});
	};

	const handleResendInvitation = (acl) => {
		Modal.confirm({
			title: 'Are you sure you want to resend an invitation to this user?',
			okText: 'Yes',
			cancelText: 'No',
			onOk() {
				dispatch(resendInvitation(acl, () => {
					message.success('The invitation was sent again');
					refreshTable();
				}));
			},
		});
	};

	// useEffect(() => {
	//     const aclsOptions = getPermissionOptionsByUser(aclRoles, currentUser);
	//     setOptionsRole([
	//         ...aclsOptions
	//     ]);
	// }, [currentUser]);

	return (
		<PageLayout
			MenuClass={ConfigureUsersMenu}
			subtitle={CONFIGURE_USERS_DETAILS}
			rightActions={isAclEditor && (
				<Space direction='vertical'>
					<AddButton
						style={{ marginTop: 1 }}
						onClick={handleAddAcl}
						title="Add an access"
					/>

					{isAdmin && isStaffView && (
						<>
							<Switch
								checked={isGroupedUsersVersion}
								onChange={() => setIsGroupedUsersVersion(!isGroupedUsersVersion)}
							>
								<span>Group users</span>
							</Switch>
							<InlineNavLink
								path={'/staff/tech/user-mailings'}
								style={{ span: { textDecoration: 'none' } }}
							>
								<span>📮 UserMailings</span>
								<FaChevronRight style={{ fontSize: 10, marginBottom: -1 }} />
							</InlineNavLink>
						</>
					)}
				</Space>
			)}
			filter={<FilterButton
				title='Filter access list'
				modal={AclFilterModal}
				onFilter={handleFilter}
				onClearFilter={handleClearFilter}
				initialValues={optionsFilterTable}
			/>}
		>

			{isGenderScorePlan
			&& <>
				<Alert
					type='warning'
					showIcon
					description={<>
						This company is under the plan <b>{companyModel.getCompanyPlanLabel()}</b>.
						You should <b>ONLY</b> invite <b>ADMIN</b> users to this company.
					</>}
					style={{ marginBottom: '20px', width: '100%' }}
				/>
			</>
			}

			{!isGroupedUsersVersion && (
				<AclTable
					onDataLoaded={handleLoadedData}
					dataSource={acls}
					filterOptions={optionsFilterTable}
					isEditor={isAclEditor}
					onEditAcl={handleEditAcl}
					onToggleIsActive={onToggleIsActiveAcl}
					onResendInvitation={handleResendInvitation}
					isRefresh={isRefreshDataLayout}
					onSearchDataColumn={handleSearchAutoComplete}
				/>
			)}
			{isGroupedUsersVersion && (
				<AclUserTable
					onDataLoaded={handleLoadedData}
					dataSource={acls}
					filterOptions={optionsFilterTable}
					isEditor={isAclEditor}
					onEditAcl={handleEditAcl}
					onToggleIsActive={onToggleIsActiveAcl}
					onResendInvitation={handleResendInvitation}
					isRefresh={isRefreshDataLayout}
					onSearchDataColumn={handleSearchAutoComplete}
					// sticky={true}
				/>
			)}
			<AclAddPopover
				open={openAddAcl}
				initialValues={initAclValuesForm}
				onSubmit={handleSubmit}
				onReset={handleResetForm}
				onCloseWithRefresh={handleCloseWithChange}
			/>
			<AclEditPopover
				open={openEditAcl}
				initialValues={initAclValuesForm}
				onSubmit={handleSubmit}
				onReset={handleResetForm}
				onCloseWithRefresh={handleCloseWithChange}
			/>
		</PageLayout>
	);
};

export default ConfigureUsersPage;
