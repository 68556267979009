import { useTheme } from '@emotion/react';
import { useContext, useEffect, useState } from 'react';
import { CompanyPageContext, LANG_TEXTS } from './CompanyPageProvider';
import { Divider, Space } from 'antd';
import {
	documentId, hexToRgb, mixColors, openExternalUrl,
} from '../../../../utils/common';
import CompanyModel from '../../../../models/CompanyModel';
import {
	getMandatorySteps,
	COMPANY_PAGE_STEP_GENDERSCORE,
	areAllOptionalStepsCompleted,
	getOptionalSteps,
} from './CompanyPageSteps';
import { FaBan, FaCheck, FaChevronRight } from 'react-icons/fa';
import CompanyPageStepCompletionTag from './CompanyPageStepCompletionTag';
import CompanyPagePublishButton from './CompanyPagePublishButton';
import {
	COMPANY_PAGE_EDITION_STATE_START_ID,
	COMPANY_PAGE_EDITION_STATE_IN_PROGRESS_ID,
	COMPANY_PAGE_EDITION_STATE_PENDING_PUBLICATION_ID,
	COMPANY_PAGE_EDITION_STATE_PUBLISHED_PENDING_GENDERSCORE_ID,
	COMPANY_PAGE_EDITION_STATE_PUBLISHED_WITH_OPTIONAL_FIELDS_ID,
	COMPANY_PAGE_EDITION_STATE_PUBLISHED_ID,
} from './CompanyPageEditionStates';
import CompanyPageStepTagList from './CompanyPageStepTagList';
import CompanyPageNextStepLink from './CompanyPageNextStepLink';
import { FRENCH_LANG, GENDERSCORE_LABEL } from '../../../../constants/constant';
import CsmSignature from '../../../onboarding/shared/CsmSignature';
import { CompanyContext } from '../../../../contexts/CompanyProvider';

const getCompanyPageCompletionStateContents = ({
	company,
	state,
	lang,
	langTexts,
}) => {
	if (!(state && company && lang)) return {};

	if (lang === FRENCH_LANG) {
		switch (documentId(state)) {
			case COMPANY_PAGE_EDITION_STATE_START_ID:
				return {
					title: <span>Let's prepare the French version of your company page!</span>,
					details: (
						<div>
							<p>You company page is already published in English, congrats! 🎉</p>
							<p>
								It's time to take care of your page for French 🇫🇷 candidates.
								<br />Here is a list of the information you need to translate
								before you can publish the French version:
							</p>
						</div>
					),
					actions: (
						<>
							<CompanyPageStepTagList
								steps={getMandatorySteps({ company, lang })}
								company={company}
								withFilled={false}
								withEmpty={true}
							/>
							<CompanyPageStepTagList
								steps={getMandatorySteps({ company, lang })}
								company={company}
								withFilled={true}
								withEmpty={false}
							/>
						</>
					),
				};
			case COMPANY_PAGE_EDITION_STATE_IN_PROGRESS_ID:
				return {
					title: <span>Keep up the momentum!</span>,
					details: (
						<div>
							<p>Let your company shine! ✨</p>
							<p>
								Your page is currently a work in progress.
								<br />You have the power to shape it into something truly special.
								<br />Here is where you stand in terms of filling information
								before you can publish your page:
							</p>
						</div>
					),
					actions: (
						<>
							<CompanyPageStepTagList
								steps={getMandatorySteps({ company, lang })}
								company={company}
								withFilled={false}
								withEmpty={true}
							/>
							<CompanyPageStepTagList
								steps={getMandatorySteps({ company, lang })}
								company={company}
								withFilled={true}
								withEmpty={false}
							/>
						</>
					),
				};
			case COMPANY_PAGE_EDITION_STATE_PENDING_PUBLICATION_ID:
				return {
					title: <span>Ready to publish in {LANG_TEXTS[FRENCH_LANG].labelIn}!</span>,
					details: (
						<p>
							Take the next step and make your page live for candidates on 50inTech. 🚀
						</p>
					),
					actions: (
						<>
							<CompanyPagePublishButton
								title={<>Publish my company page{LANG_TEXTS[FRENCH_LANG].labelIn}</>}
								company={company}
								size='large'
								style={{ marginTop: -20 }}
							/>
							{!areAllOptionalStepsCompleted({ company, lang }) && (
								<div>
									<p>To go even further, you should also translate these optional contents for your company:</p>
									<p>
										<CompanyPageStepTagList
											steps={getOptionalSteps({ company, lang })}
											company={company}
											withFilled={false}
											withEmpty={true}
										/>
									</p>
								</div>
							)}
						</>
					),
				};
			case COMPANY_PAGE_EDITION_STATE_PUBLISHED_PENDING_GENDERSCORE_ID:
				return {
					title: <span>Your company page is live{LANG_TEXTS[FRENCH_LANG].labelIn}!</span>,
					details: (
						<div>
							<p>Don't stop now!</p>
							<p>
							To stand out to candidates you should finish
							your <b>{GENDERSCORE_LABEL}</b> so we can add it to your company page.
							</p>
						</div>
					),
					actions: (
						<CompanyPageNextStepLink step={COMPANY_PAGE_STEP_GENDERSCORE}/>
					),
				};
			case COMPANY_PAGE_EDITION_STATE_PUBLISHED_WITH_OPTIONAL_FIELDS_ID:
				return {
					title: <span>Your company page is live{LANG_TEXTS[FRENCH_LANG].labelIn}!</span>,
					details: <span>
						Candidates can now discover your mission and initiatives on 50inTech.
					</span>,
					actions: (
						<div>
							<p>
								To go even further, you can also
								translate the optional content for your company:
							</p>
							<p>
								<CompanyPageStepTagList
									steps={getOptionalSteps({ company, lang })}
									company={company}
									withFilled={false}
									withEmpty={true}
								/>
							</p>
						</div>
					),
				};
			case COMPANY_PAGE_EDITION_STATE_PUBLISHED_ID:
				return null;
			default:
				return {
					title: <span>{documentId(state)}</span>,
					details: (
						<p>
							Case not handled yet
						</p>
					),
				};
		}
	}

	switch (documentId(state)) {
		case COMPANY_PAGE_EDITION_STATE_START_ID:
			return {
				title: <span>Welcome to your company page on 50inTech!</span>,
				details: (
					<div>
						<p>Let's get started on showcasing your company 🔥</p>
						<p>
							Throughout this process, we'll guide you step-by-step,
							ensuring that you have everything you need to create an impactful company page.
							<br />
							Here is a list of the information required
							before you can publish your page:
						</p>
					</div>
				),
				actions: (
					<>
						<CompanyPageStepTagList
							steps={getMandatorySteps({ company, lang })}
							company={company}
							withFilled={false}
							withEmpty={true}
						/>
						<CompanyPageStepTagList
							steps={getMandatorySteps({ company, lang })}
							company={company}
							withFilled={true}
							withEmpty={false}
						/>
					</>
				),
			};
		case COMPANY_PAGE_EDITION_STATE_IN_PROGRESS_ID:
			return {
				title: <span>Keep up the momentum!</span>,
				details: (
					<div>
						<p>Let your company shine! ✨</p>
						<p>
							Your page is currently a work in progress.
							You have the power to shape it into something truly special.
							<br />Here is where you stand in terms of filling information
							before you can publish your page:
						</p>
					</div>
				),
				actions: (
					<>
						<CompanyPageStepTagList
							steps={getMandatorySteps({ company, lang })}
							company={company}
							withFilled={false}
							withEmpty={true}
						/>
						<CompanyPageStepTagList
							steps={getMandatorySteps({ company, lang })}
							company={company}
							withFilled={true}
							withEmpty={false}
						/>
					</>
				),
			};
		case COMPANY_PAGE_EDITION_STATE_PENDING_PUBLICATION_ID:
			return {
				title: <span>Ready to publish!</span>,
				details: (
					<p>
						Take the next step and make your page live for candidates on 50inTech. 🚀
					</p>
				),
				actions: (
					<>
						<CompanyPagePublishButton
							title={<>Publish my company page{langTexts.labelIn}</>}
							company={company}
							size='large'
							style={{ marginTop: -20 }}
						/>
						{!areAllOptionalStepsCompleted({ company, lang }) && (
							<div>
								<p>
									To go even further, you can also
									complete the optional content for your company:</p>
								<p>
									<CompanyPageStepTagList
										steps={getOptionalSteps({ company, lang })}
										company={company}
										withFilled={false}
										withEmpty={true}
									/>
								</p>
							</div>
						)}
					</>
				),
			};
		case COMPANY_PAGE_EDITION_STATE_PUBLISHED_PENDING_GENDERSCORE_ID:
			return {
				title: <span>Your company page is live{langTexts.labelIn}!</span>,
				details: (
					<div>
						<p>Don't stop now!</p>
						<p>
							To stand out to candidates you should finish
							your <b>{GENDERSCORE_LABEL}</b> so we can add it to your company page.
						</p>
					</div>
				),
				actions: (
					<CompanyPageNextStepLink step={COMPANY_PAGE_STEP_GENDERSCORE} />
				),
			};
		case COMPANY_PAGE_EDITION_STATE_PUBLISHED_WITH_OPTIONAL_FIELDS_ID:
			return {
				title: <span>Your company page is live{langTexts.labelIn}!</span>,
				details: <span>
					Candidates can now discover your mission and initiatives on 50inTech.
				</span>,
				actions: (
					<div>
						<p>
							To go even further, you can also
							complete the optional content for your company:</p>
						<p>
							<CompanyPageStepTagList
								steps={getOptionalSteps({ company, lang })}
								company={company}
								withFilled={false}
								withEmpty={true}
							/>
						</p>
					</div>
				),
			};
		case COMPANY_PAGE_EDITION_STATE_PUBLISHED_ID:
			return null;
		default:
			return {
				title: <span>{documentId(state)}</span>,
				details: <p>Case not handled yet</p>,
			};
	}
};

const CompanyPageHeroMessage = (
	{
		company,
		...props
	},
) => {
	const className = 'CompanyPageHeroMessage';

	const theme = useTheme();

	const { lang, langTexts, editionState } = useContext(CompanyPageContext);

	const { csmDetails } = useContext(CompanyContext);

	const companyModel = new CompanyModel(company);

	const [bannerTitle, setBannerTitle] = useState();
	const [bannerDetails, setBannerDetails] = useState();
	const [bannerActions, setBannerActions] = useState();

	let publicationStatusColor = theme.color.accent;

	if (companyModel.isPublishedNow?.()) {
		publicationStatusColor = theme.color.darkturquoise;
	} else if (companyModel.isPublishedFuture?.()) {
		publicationStatusColor = theme.color.fitBlueElectric;
	} else {
		publicationStatusColor = theme.color.accent;
	}

	useEffect(() => {
		if (!(editionState && company && lang)) return;
		const contents = getCompanyPageCompletionStateContents({
			company,
			state: editionState,
			lang,
			langTexts,
		});
		setBannerTitle(contents?.title);
		setBannerDetails(contents?.details);
		setBannerActions(contents?.actions);
	}, [editionState, company, lang]);

	if (!bannerTitle) return null;

	return (
		<div
			style={{
				position: 'relative',
			}}
		>
			<div
				style={{
					justifyContent: 'space-between',
					background: `linear-gradient(275deg, ${theme.color.pastelBlue},${mixColors(theme.color.white, theme.color.pastelBlue, 20)})`,
					marginBottom: 50,
				}}
			>

				<Space
					direction='vertical'
					style={{
						width: '100%',
						padding: '24px 32px 18px',
					}}
				>
					<h2
						style={{ marginTop: 0, marginBottom: 0 }}
					>
						{bannerTitle}
					</h2>

					{bannerDetails}

					{bannerActions}

					<Divider style={{
						width: '100%',
						margin: '12px 0 0',
					}} />

					<CsmSignature data={csmDetails} />

				</Space>

			</div>
		</div>
	);
};

export default CompanyPageHeroMessage;
