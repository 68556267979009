import {
	Col,
	Divider, Row, Select, Space, Switch,
} from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useTheme } from '@emotion/react';
import {
	FaBacon, FaFlag, FaSync, FaTag,
} from 'react-icons/fa';
import { useAuthContext } from '../../../hooks/useAuthContext';
import * as adminActions from '../../../actions/admin';
import { buildConfirmBtn, buildConfirmBtnStyleLarge } from '../../../utils/common';
import AdminToolsSectionContainer from './AdminToolsSectionContainer';
import AdminComputeModelTagsButton from './AdminComputeModelTagsButton';
import { useState } from 'react';
import { getTagsCategory } from '../../../reducers/app';

const AdminToolsTagsSection = () => {
	const theme = useTheme();
	const dispatch = useDispatch();

	const tags = useSelector(getTagsCategory);

	const { isAdmin, currentUser } = useAuthContext();

	const [modelName, setModelName] = useState(null);
	const [tagCategories, setTagCategories] = useState([]);
	const [shouldUpdateInducedTags, setShouldUpdateInducedTags] = useState(true);

	if (!currentUser) return null;
	if (!isAdmin) return null;

	return (
		<AdminToolsSectionContainer title='Tags' Icon={FaTag}>

			<Space direction='vertical' size={4} wrap={true}>

				<Space direction='horizontal' size={4} wrap={true}>
					{buildConfirmBtn({
						theme, 
						title: 'Update tags',
						Icon: FaSync,
						dispatcher: adminActions.updateTags,
						dispatch,
					})}
					{buildConfirmBtn({
						theme, 
						title: 'Invalidate tags',
						Icon: FaFlag,
						dispatcher: adminActions.invalidateTags,
						dispatch,
					})}
					{buildConfirmBtn({
						theme, 
						title: 'Apply induced tags',
						Icon: FaBacon,
						dispatcher: adminActions.applyInducedTags,
						dispatch,
					})}
					{buildConfirmBtn({
						theme, 
						title: 'Reset & recompute tags',
						Icon: FaSync,
						dispatcher: adminActions.resetTags,
						dispatch,
						style: { ...buildConfirmBtnStyleLarge, color: theme.color.orange },
					})}
				</Space>

				<Space direction='horizontal' size={4} wrap={true}>
					<AdminComputeModelTagsButton
						modelName='User'
					/>
					<AdminComputeModelTagsButton
						modelName='Job'
					/>
					<AdminComputeModelTagsButton
						modelName='Company'
					/>
					<AdminComputeModelTagsButton
						modelName='Article'
					/>
				</Space>

				<Divider />

				<Space direction='vertical'>

					<Row
						style={{ width: '100%' }}
						gutter={[10, 10]}
					>
						<Col
							span={6}
							style={{ textAlign: 'right' }}
						>
						Collection
						</Col>
						<Col span={18}>
							<Select
								options={[
									{ value: 'User' },
									{ value: 'Job' },
									{ value: 'Company' },
									{ value: 'Article' },
								]}
								value={modelName}
								allowClear={true}
								onChange={(value) => setModelName(value)}
								style={{ minWidth: 200 }}
							/>
						</Col>
						<Col
							span={6}
							style={{ textAlign: 'right' }}
						>
						Tag categories
						</Col>
						<Col span={18}>
							<Select
								options={[
									...new Set(tags.map((tag) => tag?.category)),
								]
									.sort()
									.map((value) => ({ value }))}
								value={tagCategories}
								mode='multiple'
								allowClear={true}
								onChange={(values) => setTagCategories(values)}
								style={{ minWidth: 300 }}
							/>
						</Col>
						<Col
							span={6}
							style={{ textAlign: 'right' }}
						>
						shouldUpdateInducedTags
						</Col>
						<Col span={18}>
							<Switch
								checked={shouldUpdateInducedTags}
								onChange={() => setShouldUpdateInducedTags((p) => !p)}
							/>
						</Col>
					</Row>

					<div style={{ color: theme.color.grey }}>Parameters: {JSON.stringify({ modelName, tagCategories, shouldUpdateInducedTags })}</div>

					<Divider />

					<AdminComputeModelTagsButton
						modelName={modelName}
						tagCategories={tagCategories}
						shouldUpdateInducedTags={shouldUpdateInducedTags}
						withSideDetails={true}
					/>
				</Space>

			</Space>

		</AdminToolsSectionContainer>
	);
};

export default AdminToolsTagsSection;
