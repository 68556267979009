import { getCareerExperienceOption } from '../../../constants/constant';

const UserCoachingExperience = (
	{
		user,
		defaultValue,
	},
) => {
	try {
		let xp = parseInt(user?.coaching?.coachingExperience, 10);
		let xpt = parseInt(user?.coaching?.coachingExperienceTech, 10);
		xp = xp >= 0 ? xp : null;
		xpt = xpt >= 0 ? xpt : null;
		if (xp === null && xpt === null) {
			if (defaultValue) return defaultValue;
			if (user?.career?.experience) {
				const option = getCareerExperienceOption(user?.career?.experience);
				if (option?.label) return option?.label;
				return 'Not provided';
			}
		}
		// localDebug({ xp, xpt });
		if ((xp && !xpt) || (xp === xpt)) {
			return `${xp} year${xp > 1 ? 's' : ''} in tech`;
		}
		return `${xpt} year${xpt > 1 ? 's' : ''} in tech (${xp} year${xp > 1 ? 's' : ''} overall)`;
	} catch (e) {
		return null;
	}
};

export default UserCoachingExperience;
