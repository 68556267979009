import { documentId } from '../utils/common';
import { request } from './api';

const findAll = async (params, subPath = '') => {
	const dataParams = {
		params,
	};
	return request.get(`/sourcing${subPath}`, dataParams);
};

const getById = (id) => {
	return request.get(`/sourcing/${id}`);
};

const create = (data) => {
	return request.post('/sourcing', data);
};

const update = (sourcingId, data) => {
	return request.put(`/sourcing/${sourcingId}`, data);
};

const remove = (sourcing) => {
	return request.delete(`/sourcing/${documentId(sourcing)}`);
};

const removeStep = (id, step, sourcing) => {
	const data = {
		data: {
			step,
			id,
			sourcing,
		},
	};
	return request.delete(`/sourcing/${id}/step`, data);
};

const syncFromATS = (id) => {
	return request.get(`/sourcing/${id}/sync`);
};

const pushToATS = (id) => {
	return request.get(`/sourcing/${id}/push`);
};

const SourcingService = {
	findAll,
	getById,
	create,
	update,
	remove,
	removeStep,
	syncFromATS,
	pushToATS,
};

export default SourcingService;
