import { Select } from 'antd';
import { LocalDebug } from '../../../utils/LocalDebug';

const TableSorter = (
	{
		sortOptions = [],
		sortStyle,
		sortValue,
		onChange,
		...props
	},
) => {
	const className = 'TableSorter';

	const handleChange = (value) => {
		LocalDebug.logInfo({ className, method: 'handleChange' }, { value, sortStyle });
		onChange(value);
	};

	return (
		<>
			<span style={{ fontSize: 12, fontWeight: 700, padding: '0 8px 0 8px' }}>
                Sort by
			</span>
			<Select
				value={sortValue || sortOptions?.[0]?.value}
				onChange={handleChange}
				style={{ fontSize: 12, ...sortStyle?.select }}
			>
				{sortOptions?.map((so) => {
					return <Select.Option
						key={so.value}
						value={so.value}
						style={{ fontSize: 12 }}
					>
						{so.label}
					</Select.Option>;
				})}
			</Select>
		</>
	);
};

export default TableSorter;
