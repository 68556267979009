import TECH_STACK from '../../property/tech-stack';
import { innerObjPropExtractor } from '../../property/property-extractor';
import { innerObjPropMapper } from '../../property/property-mapper';

const USER_PROPERTY_CAREER_HARD_SKILL_KEY = 'career-hard-skill';

const USER_PROPERTY_CAREER_HARD_SKILL_MULTI = true;
const USER_PROPERTY_CAREER_HARD_SKILL_FREE = true;
const USER_PROPERTY_CAREER_HARD_SKILL_MAX = 10;

const USER_PROPERTY_CAREER_HARD_SKILL_FIELD = 'career.hardSkill';
const USER_PROPERTY_CAREER_HARD_SKILL_MAPPER = innerObjPropMapper(USER_PROPERTY_CAREER_HARD_SKILL_FIELD);
const USER_PROPERTY_CAREER_HARD_SKILL_EXTRACTOR = innerObjPropExtractor(USER_PROPERTY_CAREER_HARD_SKILL_FIELD);

export default {
	...TECH_STACK,
	key: USER_PROPERTY_CAREER_HARD_SKILL_KEY,
	USER_PROPERTY_CAREER_HARD_SKILL_KEY,
	free: USER_PROPERTY_CAREER_HARD_SKILL_FREE,
	USER_PROPERTY_CAREER_HARD_SKILL_FREE,
	multi: USER_PROPERTY_CAREER_HARD_SKILL_MULTI,
	USER_PROPERTY_CAREER_HARD_SKILL_MULTI,
	max: USER_PROPERTY_CAREER_HARD_SKILL_MAX,
	USER_PROPERTY_CAREER_HARD_SKILL_MAX,
	mapper: USER_PROPERTY_CAREER_HARD_SKILL_MAPPER,
	USER_PROPERTY_CAREER_HARD_SKILL_MAPPER,
	extractor: USER_PROPERTY_CAREER_HARD_SKILL_EXTRACTOR,
	USER_PROPERTY_CAREER_HARD_SKILL_EXTRACTOR,
	field: USER_PROPERTY_CAREER_HARD_SKILL_FIELD,
	USER_PROPERTY_CAREER_HARD_SKILL_FIELD,
};
