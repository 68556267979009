import SCORING from './scoring';

class SurveyQuestionModel {
	questionId;

	label;

	sublabel;

	shortLabel;

	skipLabel;

	input;

	options;

	min;

	max;

	step;

	columns;

	suffix;

	import;

	align;

	constructor(props) {
		Object.assign(this, props);
	}

	getScore({ surveyConf, answers, defaultScore }) {
		return SCORING.computeQuestionScore({
			question: this,
			...surveyConf.scoring[this.questionId],
			...defaultScore >= 0 ? { defaultScore } : {},
			answers,
		});
	}
}

export default SurveyQuestionModel;
