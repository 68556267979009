import { documentId } from '../utils/common';
import { request } from './api';

const findAll = async (params, subPath = '') => {
	const dataParams = {
		params,
	};
	return request.get(`/message${subPath}`, dataParams);
};

const create = (data) => {
	return request.post('/message', data);
};

const update = (messageId, data) => {
	return request.put(`/message/${messageId}`, data);
};

const remove = (message) => {
	return request.delete(`/message/${documentId(message)}`);
};

const retrySend = (message) => {
	return request.post('/message/retry-send', {
		id: documentId(message),
		userMailingId: message?.userMailingId,
	});
};

const refreshUserMailing = (message) => {
	return request.post('/message/refresh-user-mailing?force=true', { id: documentId(message) });
};

const MessageService = {
	findAll,
	create,
	update,
	remove,
	retrySend,
	refreshUserMailing,
};

export default MessageService;
