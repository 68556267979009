import { Tooltip } from 'antd';
import { FaExternalLinkAlt } from 'react-icons/fa';
import Link from '../../app/Link';
import config from '../../../config/config';
import { openExternalUrl } from '../../../utils/common';

const UserLinkAppLeads = (
	{
		user,
		...props
	},
) => {
	if (!user) {
		return null;
	}

	const path = `${config.appRoot}/coaching/leads?name=${user.slug}`;

	return (
		<Tooltip title='Find this user in the "Leads" section of app.50intech.com'>
			<Link
				onClick={() => { openExternalUrl(path); }}
				style={{
					fontSize: 11,
					...props?.style,
				}}
			>
				<FaExternalLinkAlt style={{ marginBottom: -2 }}/> Find in Leads
			</Link>
		</Tooltip>
	);
};

export default UserLinkAppLeads;
