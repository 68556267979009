import { documentId } from '../utils/common';
import { request } from './api';

const findAll = async (params, subPath = '') => {
	const dataParams = {
		params,
	};
	return request.get(`/feedback${subPath}`, dataParams);
};

const create = (data) => {
	return request.post('/feedback', data);
};

const update = (feedbackId, data) => {
	return request.put(`/feedback/${feedbackId}`, data);
};

const remove = (feedback) => {
	return request.delete(`/feedback/${documentId(feedback)}`);
};

const FeedbackService = {
	findAll,
	create,
	update,
	remove,
};

export default FeedbackService;
