import { DATE_FORMAT_NO_TIME_WITH_LINEBREAK } from '../../../../../constants/constant';
import CompanyModel from '../../../../../models/CompanyModel';
import GenderScoreBarBlock from '../blocks/GenderScoreBarBlock';
import { useEffect, useRef, useState } from 'react';

const CompanyGenderScoreProgressBox = ({
	company,
	maxPreviousSurveys = 5,
	width = 150,
	height = 100,
}) => {
	const companyModel = new CompanyModel(company);
	const ref = useRef();
	const [thisWidth, setThisWidth] = useState(width);

	useEffect(() => {
		if (ref?.current) {
			setThisWidth(ref?.current?.offsetWidth);
		}
	}, [ref]);
	if (!companyModel) {
		return null;
	}
	const genderScoreProgress = companyModel.getGenderScoreProgress(
		DATE_FORMAT_NO_TIME_WITH_LINEBREAK,
	)?.slice(0, maxPreviousSurveys);
	if (!genderScoreProgress) {
		return null;
	}
	return <div ref={ref}>
		<GenderScoreBarBlock
			progress={genderScoreProgress}
			title="Previous Scores"
			width={thisWidth}
			height={height}
			maxBars={maxPreviousSurveys}
		/>
	</div>;
};
export default CompanyGenderScoreProgressBox;
