import { ENGLISH_LANG } from '../../../../constants/constant';
import { displayHtml } from '../../../../utils/common';
import CompanyFieldNotProvided from '../shared/CompanyFieldNotProvided';

const CompanyQuoteDisplay = (
	{
		company,
		lang = ENGLISH_LANG,
	},
) => {
	const className = 'CompanyQuoteDisplay';

	const field = 'quote';
	const value = company?.getTranslationFieldFallback(field, lang);

	const content = value
		? displayHtml(value)
		: <CompanyFieldNotProvided/>;

	return (
		<>
			<h2>Quote</h2>
			{content}
		</>
	);
};

export default CompanyQuoteDisplay;
