import { Modal } from 'antd';
import { useTheme } from '@emotion/react';
import { useDispatch } from 'react-redux';
import AddButton from '../../app/button/AddButton';
import { basicActionBuilder } from '../../../actions/utils';

const AdminConfirmRequestBtn = (
	{
		title,
		Icon,
		messageTitle,
		confirmText,
		request,
		styles,
		...props
	},
) => {
	// context
	const dispatch = useDispatch();
	const theme = useTheme();

	const confirmDispatch = (label, callback) => {
		Modal.confirm({
			title: <>
				<div><b>{label}</b></div>
				Are you sure you want to proceed?</>,
			okText: 'Yes',
			cancelText: 'No',
			onOk() {
				callback();
			},
		});
	};

	const btnProps = {
		icon: null,
		// color: theme.color.darkturquoise
		color: theme.color.white,
		textColor: theme.color.black,
		// color: theme.color.white
		style: {
			border: '1px solid #ccc',
			// height: 38,
		},
	};

	const addBtn = <AddButton
		{...btnProps}
		{...styles}
		title={title}
		icon={<Icon style={{ marginBottom: -2 }}/>}
		onClick={
			() => confirmDispatch(
				confirmText || title,
				() => {
					dispatch(
						basicActionBuilder({
							command: request,
							messageTitle: messageTitle || title,
						}),
					);
				},
			)
		}
	/>;

	return addBtn;
};

export default AdminConfirmRequestBtn;
