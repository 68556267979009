import { GENDERSCORE_SURVEY_1TO49_V2_DATA_OPTION } from './genderscore-survey-1-to-49-v2';
import { GENDERSCORE_SURVEY_50PLUS_V2_DATA_OPTION } from './genderscore-survey-50-plus-v2';
import { GENDERSCORE_SURVEY_50PLUS_V3_DATA_OPTION } from './genderscore-survey-50-plus-v3';

export const options = [
	GENDERSCORE_SURVEY_1TO49_V2_DATA_OPTION,
	GENDERSCORE_SURVEY_50PLUS_V2_DATA_OPTION,
	GENDERSCORE_SURVEY_50PLUS_V3_DATA_OPTION,
];

export const getSurveyDataOption = (value) => options
	?.find((o) => o?.value === value);

const exporter = {
	options,
	getSurveyDataOption,
};

export default exporter;
