import { useAuthContext } from '../../../hooks/useAuthContext';
import PageLayout from '../../../components/app/layout/PageLayout';
import { useState } from 'react';
import { addMultipleQueryParams, removeQueryParams } from '../../../utils/common';
import { localDebug } from '../../../utils/LocalDebug';
import { FaChevronRight } from 'react-icons/fa';
import InlineNavLink from '../../../components/app/button/InlineNavLink';
import SavedSearchesDigestsTable from '../../../components/search/SavedSearchesDigestsTable';
import StaffSavedSearchesDigestsMenu from '../../../menu/staff/tech/StaffSavedSearchesDigestsMenu';

const SavedSearchesDigestsPage = () => {
	const { isStaff } = useAuthContext();
	const [isRefreshDataLayout, setIsRefreshDataLayout] = useState(0);
	const [optionsFilterTable, setOptionsFilterTable] = useState({});
	if (!isStaff) return null;

	const handleFilter = (values) => {
		localDebug('SavedSearchesDigestsPage.handleFilter()', { values });
		const {
			createdAfter, createdBefore, ...rest
		} = values;
		addMultipleQueryParams(rest);
		setOptionsFilterTable((prev) => ({
			...prev,
			...rest,
			createdAfter,
			createdBefore,
		}));
	};

	return (
		<PageLayout
			MenuClass={StaffSavedSearchesDigestsMenu}
			rightActions={(
				<InlineNavLink
					path={'/staff/tech/user-mailings'}
					style={{ span: { textDecoration: 'none' } }}
				>
					<span>📮</span> User Mailings
					<FaChevronRight style={{ fontSize: 10, marginBottom: -1 }} />
				</InlineNavLink>
			)}

		>
			<SavedSearchesDigestsTable
				filterOptions={optionsFilterTable}
				isRefresh={isRefreshDataLayout}
			/>
		</PageLayout>
	);
};

export default SavedSearchesDigestsPage;
