import { Popover, Space } from 'antd';
import { useTheme } from '@emotion/react';
import { FaChevronRight } from 'react-icons/fa';
import Link from '../Link';
import { useAuthContext } from '../../../hooks/useAuthContext';
import { getAclRoleTag } from '../../../constants/constant';
import UserAvatar from '../../user/widgets/UserAvatar';
import StaffViewSwitch from '../StaffViewSwitch';
import { useContext } from 'react';
import { AppDataContext } from '../../../contexts/AppDataProvider';

const AppMenuUser = ({
	...props
}) => {
	const className = 'AppMenuUser';

	const theme = useTheme();
	const {
		isStaff, isStaffView, currentUser, aclLabels,
	} = useAuthContext();

	const {
		appSiderWidth,
	} = useContext(AppDataContext);

	if (!currentUser) {
		return null;
	}

	const aclRoleTags = (aclLabels || [])
		.map((roleValue, index) => getAclRoleTag(
			roleValue,
			{
				margin: '0 !important',
				marginTop: -10,
				marginLeft: 0,
				marginRight: 0,
				fontSize: 9,
				padding: '1px 3px',
				lineHeight: 1,
			},
			{ key: index },
		));

	const aclRoleTagsFull = aclRoleTags?.length > 0
		&& <Space size={3} style={{ marginTop: 0 }}>
			{aclRoleTags}
		</Space>;

	const aclRoleTagsExtract = aclRoleTags?.length > 0
		&& <div style={{ marginTop: 0 }}>
			<Space size={3} style={{ pointerEvents: 'none' }}>{aclRoleTags.slice(0, 2)}</Space>
		</div>;

	const aclRolesContainer = (
		aclRoleTags?.length > 2
			? (
				<Popover title='Your roles' content={aclRoleTagsFull}>
					<Space size={3}>
						{aclRoleTagsExtract}
						<span style={{
							background: theme.color.lightgrey,
							color: theme.color.white,
							borderRadius: 3,
							fontSize: 9,
							padding: '1px 3px',
							lineHeight: 1,
						}}>
					+{(aclRoleTags?.length || 0) - 2}
						</span>
					</Space>
				</Popover>
			)
			: aclRoleTagsFull
	);

	return (
		<Space
			direction='horizontal'
			size={0}
			align='center'
			style={{
				width: '100%',
				maxWidth: '100%',
				fontSize: 14,
				fontWeight: 600,
				justifyContent: 'space-between',
				...props.style,
			}}
		>
			<Link to='/account'>
				<Space size={8} wrap={false}>
					<UserAvatar
						user={currentUser}
						withTooltipEmail={true}
						tooltipPlacement='right'
						style={{
							rootContainer: {},
							a: { width: 20 },
						}}
					/>
					<Space
						direction='vertical'
						size={0}
						style={{
							maxWidth: appSiderWidth - 90,
						}}
					>
						<div style={{
							whiteSpace: 'nowrap',
							marginBottom: -3,
							color: '#444',
							textOverflow: 'ellipsis',
							overflow: 'hidden',
						}}>
							{currentUser.firstName}
						</div>
						<div
							style={{
								fontSize: 10,
								fontWeight: 400,
								userSelect: 'none',
								cursor: 'pointer',
								marginTop: 4,
							}}
						>
							Account <FaChevronRight style={{ fontSize: 8, marginBottom: -1 }}/>
						</div>
					</Space>
				</Space>
			</Link>

			{/* <StaffViewSwitch style={{ display: 'inline-block', marginTop: -4 }}/> */}
		</Space>
	);
};

export default AppMenuUser;
