import {
	Col, DatePicker, Form, Row, Select,
} from 'antd';
import { useEffect, useState } from 'react';
import { DATE_FORMAT } from '../../constants/constant';
import FormItem from '../company/form/FormItem';
import { localDebug } from '../../utils/LocalDebug';
import FilterModal from '../app/filters/FilterModal';
import FormCompanySelect from '../app/form/FormCompanySelect';
import { useAuthContext } from '../../hooks/useAuthContext';
import { useSelector } from 'react-redux';
import { getCompanies } from '../../reducers/app';
import { documentId, getQueryParams } from '../../utils/common';
import { CATEGORY_LIST } from '../../constants/email-templates';

const MessageFilterModal = (
	{
		title,
		open,
		initialValues = {},
		onClose,
		onFilter,
	},
) => {
	const { isStaff, isStaffView, isCompanyRecruiter } = useAuthContext();

	const [form] = Form.useForm();

	const companies = useSelector(getCompanies);
	const queryParams = getQueryParams();
	const [optionsCompany, setOptionsCompany] = useState([]);

	const getDefaultSelected = (fieldName) => {
		return queryParams[fieldName] || [];
	};

	useEffect(() => {
		setOptionsCompany([...companies]);
	}, [companies, isStaff, isStaffView]);

	const handleFilter = () => {
		form
			.validateFields()
			.then((values) => {
				const filtersValues = { ...values };
				if (onFilter) onFilter(filtersValues);
			})
			.catch((errors) => {
				localDebug('Validate Failed:', errors);
			});
	};

	useEffect(() => {
		form.resetFields();
		form.setFieldsValue(initialValues);
	}, [form, initialValues]);

	return (
		<FilterModal
			title={title}
			width={500}
			open={open}
			onClose={onClose}
			onFilter={handleFilter}
		>
			<Form
				form={form}
				name="filters"
				labelCol={{ span: 6 }}
				labelAlign='right'
				className="user-filter-form"
			>
				<Row gutter={0}>
					{isStaff && isStaffView
						&& <Col span={24}>
							<FormItem
								label="Excluded companies"
								defaultContainer="div"
								labelCol={{ span: 9 }}
								name="notCompanyIds"
							>
								<FormCompanySelect
									with50inTechSorting={1}
									options={optionsCompany.map((c) => ({
										value: documentId(c),
										label: c.name,
										item: c,
									}))}
									defaultValue={getDefaultSelected('notCompanyIds')}
									mode="multiple"
								/>
							</FormItem>
						</Col>
					}
					{isStaff && isStaffView && <Col span={24}>
						<FormItem
							label='Category'
							name="category"
							labelCol={{ span: 9 }}
						>
							<Select
								placeholder={<>Category</>}
								allowClear={true}
								style={{ borderRadius: 20, fontSize: 14, width: 200 }}
								options={[
									...CATEGORY_LIST,
									{ label: <i>No category</i>, value: 'none' },
								]}
							/>
						</FormItem>
					</Col>}
					<Col span={24}>
						<FormItem
							label='Created before'
							name="createdBefore"
							labelCol={{ span: 9 }}
						>
							<DatePicker showTime format={DATE_FORMAT}/>
						</FormItem>
					</Col>
					<Col span={24}>
						<FormItem
							label='Created after'
							name="createdAfter"
							labelCol={{ span: 9 }}
						>
							<DatePicker showTime format={DATE_FORMAT}/>
						</FormItem>
					</Col>
				</Row>
			</Form>
		</FilterModal>
	);
};

export default MessageFilterModal;
