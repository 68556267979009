import SourcingATSManagerCell from './SourcingATSManagerCell';
import TableColumns from '../app/table/TableColumns';
import { pushSourcingToATS, syncSourcingFromATS } from '../../actions/sourcings';

const SourcingATSManagerColumn = (
	{
		CellRendererComponent = SourcingATSManagerCell,
		sendAction = pushSourcingToATS,
		syncAction = syncSourcingFromATS,
		...props
	},
) => {
	return {
		title: 'ATS',
		align: 'center',
		removeInColumnFilter: true,
		dataIndex: ['atsSync'],
		render: (value, row) => <CellRendererComponent
			sourcing={row}
			sendAction={sendAction}
			syncAction={syncAction}
		/>,
		...TableColumns.columnMinWidth(150),
		...props,
	};
};

export default SourcingATSManagerColumn;
