import React, { useContext, useEffect, useState } from 'react';
import { useTheme } from '@emotion/react';
import { documentId } from '../../utils/common';
import { useDispatch, useSelector } from 'react-redux';
import { getCompanySelected } from '../../reducers/app';
import UMSActions from '../../actions/ums';
import { useAuthContext } from '../../hooks/useAuthContext';
import { ACL_ROLE_UMS_ADMIN, ACL_ROLE_UMS_EMPLOYEE, ACL_ROLE_UMS_MANAGER } from '../../constants/acls';
import { LocalDebug } from '../../utils/LocalDebug';
import { deburr } from 'lodash';
import config from '../../config/config';
import { AppDataContext } from '../../contexts/AppDataProvider';

export const UMSInviteContext = React.createContext();

const UMSInviteProvider = ({
	children,
}) => {
	const className = 'UMSInviteProvider';
	const theme = useTheme();
	const dispatch = useDispatch();

	const companySelected = useSelector(getCompanySelected);

	const { isStaffView, isUMSAdmin, isUMSManager } = useAuthContext();
	const { appLang, setAppLang } = useContext(AppDataContext);

	const [isLoading, setIsLoading] = useState(true);
	const [isResultsLoading, setIsResultsLoading] = useState(true);
	const [companyResults, setCompanyResults] = useState();
	const [managerResults, setManagerResults] = useState([]);
	const [admins, setAdmins] = useState([]);
	const [employees, setEmployees] = useState([]);
	const [managers, setManagers] = useState([]);
	const [team, setTeam] = useState([]);
	const [isRefreshDataLayout, setIsRefreshDataLayout] = useState(0);
	const [inviteModalFilters, setInviteModalFilters] = useState({});
	const [inviteModalOpen, setInviteModalOpen] = useState(false);
	const [checkEmailLoading, setCheckEmailLoading] = useState(false);
	const [inviteFormStep, setInviteFormStep] = useState(0);
	const [shouldNotifyInvitee, setShouldNotifyInvitee] = useState(true);
	const [managerSelected, setManagerSelected] = useState();
	const [managerSelectedEmployees, setManagerSelectedEmployees] = useState([]);

	useEffect(() => {
		setManagerSelectedEmployees(
			managerSelected
				? employees?.filter?.((item) => documentId(item?.role?.manager) === documentId(managerSelected))
				: employees || [],
		);
	}, [managers, managerSelected, employees]);

	const setUsers = (data, func) => {
		func?.(data?.map((item) => {
			const { user, ...rest } = item || {};
			return {
				...user,
				role: rest,
			};
		}));
	};

	const refreshLayout = () => {
		setIsRefreshDataLayout((prev) => prev + 1);
	};

	const closeInviteModal = () => {
		setInviteModalOpen(false);
		setInviteFormStep(0);
	};

	const reload = () => {
		setIsLoading(true);
		dispatch(
			UMSActions.getRoles({
				params: {
					companyId: documentId(companySelected),
					roles: [
						...isStaffView || isUMSAdmin ? [ACL_ROLE_UMS_ADMIN, ACL_ROLE_UMS_MANAGER] : [],
						ACL_ROLE_UMS_EMPLOYEE,
					],
				},
				callback: async (result) => {
					LocalDebug.logUseEffect({ className, effects: '' }, { result });
					setIsLoading(false);
					const { data } = result || {};
					setUsers(data?.admins || [], setAdmins);
					setUsers(data?.managers || [], setManagers);
					setUsers(data?.employees || [], setEmployees);
					setTeam(data?.team);
				},
			}),
		);
	};

	const loadResults = () => {
		setIsResultsLoading(true);
		dispatch(
			UMSActions.getResults({
				params: {
					companyId: documentId(companySelected),
				},
				callback: async (result) => {
					setIsResultsLoading(false);
					const { data } = result || {};
					LocalDebug.logInfo({ className, method: 'loadResults' }, { data });
					setCompanyResults(data.company);
					setManagerResults(data.managers);
				},
			}),
		);
	};

	const PDF_FILENAME_PATH = `${config.storagePublicRoot}/ums`;
	const PDF_FILENAME_BASE = '50inTech Unbiased Management Score';

	const makePDFResultFilename = ({
		name,
		company,
		lang,
		withFullPath = false,
		withExtension = false,
		withEncodeURI = false,
		withMagic = false,
	} = {
		withFullPath: false,
		withExtension: false,
		withEncodeURI: false,
		withMagic: false,
	}) => {
		let filename = [
			PDF_FILENAME_BASE,
			' - Results for ',
			deburr(name),
			` (${lang || appLang || 'en'})`,
			...(withExtension || withFullPath) ? ['.pdf'] : [],
		].join('');

		if (withEncodeURI) filename = encodeURIComponent(filename);

		if (withFullPath) {
			return [
				PDF_FILENAME_PATH,
				...withEncodeURI ? [encodeURIComponent(company.path)] : [company.path],
				filename + (withMagic ? `?magic=${new Date().getTime()}` : ''),
			].join('/');
		}

		return filename;
	};

	const makeManagerPDFResultFilename = ({
		user,
		company,
		lang,
		withFullPath = false,
		withExtension = false,
		withEncodeURI = false,
		withMagic = false,
	} = {
		withFullPath: false,
		withExtension: false,
		withEncodeURI: false,
		withMagic: false,
	}) => {
		return makePDFResultFilename({
			name: [user?.firstName || '', user?.lastName || ''].join(' '),
			company,
			lang,
			withFullPath,
			withExtension,
			withEncodeURI,
			withMagic,
		});
	};

	const makeCompanyPDFResultFilename = ({
		company,
		lang,
		withFullPath = false,
		withExtension = false,
		withEncodeURI = false,
		withMagic = false,
	} = {
		withFullPath: false,
		withExtension: false,
		withEncodeURI: false,
		withMagic: false,
	}) => {
		return makePDFResultFilename({
			name: company?.name,
			company,
			lang,
			withFullPath,
			withExtension,
			withEncodeURI,
			withMagic,
		});
	};

	return (
		<UMSInviteContext.Provider
			value={{
				isLoading,
				setIsLoading,
				reload,
				admins,
				setAdmins,
				employees,
				setEmployees,
				managers,
				setManagers,
				team,
				setTeam,
				setUsers,
				managerSelected,
				setManagerSelected,
				isRefreshDataLayout,
				setIsRefreshDataLayout,
				refreshLayout,
				inviteModalOpen,
				setInviteModalOpen,
				closeInviteModal,
				inviteModalFilters,
				setInviteModalFilters,
				inviteFormStep,
				setInviteFormStep,
				shouldNotifyInvitee,
				setShouldNotifyInvitee,
				checkEmailLoading,
				setCheckEmailLoading,
				managerSelectedEmployees,
				loadResults,
				isResultsLoading,
				setIsResultsLoading,
				companyResults,
				managerResults,
				makeManagerPDFResultFilename,
				makeCompanyPDFResultFilename,
				appLang,
				setAppLang,
			}}
		>
			{children}
		</UMSInviteContext.Provider>
	);
};

export default UMSInviteProvider;
