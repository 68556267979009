import AccountAvatarUploader from './AccountAvatarUploader';
import { LocalDebug } from '../../utils/LocalDebug';
import { Form, Input, message } from 'antd';
import {
	useReducer, useState,
} from 'react';
import UserService from '../../services/user';
import FormItem from '../company/form/FormItem';
import { useDispatch } from 'react-redux';
import { refreshCurrentUser } from '../../actions/app';
import FormBoxTitle from '../app/form/FormBoxTitle';
import FormSubmitButton from '../app/form/FormSubmitButton';
import { useAuthContext } from '../../hooks/useAuthContext';
import { documentId, requiredTrimRule } from '../../utils/common';

const AccountProfileForm = (
	{
		user,
		onSuccess,
		style = {},
	},
) => {
	const className = 'AccountProfileForm';

	const dispatch = useDispatch();
	const {
		currentUser,
		profileSubtitle,
		isCalendlyFieldVisible,
	} = useAuthContext();

	const [form] = Form.useForm();
	const [avatar, setAvatar] = useState(user?.information?.avatar);
	const [avatarEdited, setAvatarEdited] = useState(false);

	// const [disabledSave, setDisabledSave] = useState(true);
	const [state, stateDispatch] = useReducer((state, action) => {
		switch (action) {
			case 'forceDisabled':
				LocalDebug.logInfo({ className, method: 'useReducer' }, { action });
				return { disabled: true };
			case 'updateDisabled':
				const hasErrors = form.getFieldsError().some(({ errors }) => errors.length);
				const isTouched = form.isFieldsTouched();
				LocalDebug.logInfo({ className, method: 'useReducer' }, {
					action,
					state,
					hasErrors,
					isTouched,
					avatarEdited,
				});
				if (hasErrors) return { disabled: true };
				if (!isTouched && !avatarEdited) return { disabled: true };
				return { disabled: false };
			default:
				break;
		}
	}, { disabled: true });

	const onFormChanged = () => {
		stateDispatch('updateDisabled');
	};

	const handleAvatarUpload = async (imageUrl) => {
		LocalDebug.logInfo({ className, method: 'handleAvatarUpload' }, { imageUrl });
		setAvatar(imageUrl);
		setAvatarEdited(true);
		stateDispatch('updateDisabled');
	};

	const onFormFinish = async () => {
		message.loading({ content: 'Updating profile', key: className });

		stateDispatch('forceDisabled');

		const data = {
			id: documentId(user),
			...form.getFieldsValue(),
			information: {
				...form.getFieldsValue()?.information || {},
				avatar,
			},
		};

		LocalDebug.logInfo({ className, method: 'onFormFinish' }, data);
		const result = await UserService.updateUser(data);

		LocalDebug.logInfo({ className, method: 'onFormFinish' }, result);
		// await UserService.updateProperty(documentId(currentUser), { key: 'profile-avatar', value: imageUrl });
		message.success({ content: 'Your profile was updated', key: className });

		if (documentId(user) === documentId(currentUser)) refreshCurrentUser()(dispatch);

		onSuccess?.();
	};

	const onCalendlyChanged = (event) => {
		const value = event?.target?.value?.trim?.();
		LocalDebug.logInfo({ className, method: 'onCalendlyChanged' }, { value });
		const computed = value?.replace?.('https://calendly.com/', '');
		LocalDebug.logInfo({ className, method: 'onCalendlyChanged' }, { computed });
		if (computed !== value) form.setFieldsValue({ information: { calendly: computed } });
		// stateDispatch('updateDisabled');
	};

	const onFormFinishFailed = ({ values, errorFields, outOfDate }) => {
		message.error(<div>
			Error: {(errorFields?.map((e) => e.errors) || []).slice(0, 1).join(',')}
		</div>);
	};

	return (
		<Form
			form={form}
			name="account-profile"
			initialValues={{ ...user }}
			labelCol={{ span: 6 }}
			labelAlign='right'
			// wrapperCol={{ span: 8 }}
			wrapperCol={{ span: 18 }}
			onFinish={onFormFinish}
			onFinishFailed={onFormFinishFailed}
			style={{ ...style }}
			layout='horizontal'
			// onValuesChange={onFormChanged}
			onFieldsChange={onFormChanged}
		>
			<FormBoxTitle
				title={<>👋 Your profile</>}
			>

				<FormItem
					name={['information', 'avatar']}
					label="Avatar"
					defaultContainer="div"
				>
					<AccountAvatarUploader
						user={user}
						onSuccess={handleAvatarUpload}
					/>
				</FormItem>

				<FormItem
					name="firstName"
					label="First Name"
					defaultContainer="div"
					rules={[requiredTrimRule]}
				/>
				<FormItem
					name="lastName"
					label="Last Name"
					defaultContainer="div"
					rules={[requiredTrimRule]}
				/>
				<FormItem
					name={['information', 'position']}
					label="Position"
					defaultContainer="div"
					rules={[requiredTrimRule]}
				/>
				<FormItem
					name={['information', 'company']}
					label="Company"
					defaultContainer="div"
					rules={[requiredTrimRule]}
				/>

				{isCalendlyFieldVisible && (
					<FormItem
						name={['information', 'calendly']}
						label="Calendly link"
						defaultContainer="div"
					>
						<Input
							addonBefore="https://calendly.com/"
							onChange={onCalendlyChanged}
						/>
					</FormItem>
				)}

				<FormItem label='&nbsp;'>
					<FormSubmitButton disabled={state?.disabled}>
						Save profile
					</FormSubmitButton>
				</FormItem>

			</FormBoxTitle>
		</Form>
	);
};

export default AccountProfileForm;
