import { Tabs } from 'antd';
import { useAuthContext } from '../../../hooks/useAuthContext';
import Box from '../../../components/app/box/Box';
import ProductUserSalaryExpectations from '../../../components/staff/product/ProductUserSalaryExpectations';

const ProductUsersPage = () => {
	const { isStaff } = useAuthContext();

	if (!isStaff) return null;

	return (
		<>
			<h1>Product/Users</h1>
			<Tabs>
				<Tabs.TabPane tab='Salary expectations' key='salary-expectations'>
					<Box style={{ marginTop: -17, paddingTop: 10 }}>
						<ProductUserSalaryExpectations/>
					</Box>
				</Tabs.TabPane>
			</Tabs>
		</>
	);
};

export default ProductUsersPage;
