import { mergeReducer } from '../utils/common';

export const GENDERSCORE_LEVEL_OBSERVER_VALUE = 'observer';
export const GENDERSCORE_LEVEL_DISCOVERER_VALUE = 'discoverer';
export const GENDERSCORE_LEVEL_ACHIEVER_VALUE = 'achiever';
export const GENDERSCORE_LEVEL_PACEMAKER_VALUE = 'pacemaker';
export const GENDERSCORE_LEVEL_PIONEER_VALUE = 'pioneer';

export const GENDERSCORE_LEVEL_VALUES = [
	GENDERSCORE_LEVEL_OBSERVER_VALUE,
	GENDERSCORE_LEVEL_DISCOVERER_VALUE,
	GENDERSCORE_LEVEL_ACHIEVER_VALUE,
	GENDERSCORE_LEVEL_PACEMAKER_VALUE,
	GENDERSCORE_LEVEL_PIONEER_VALUE,
];

export const GENDERSCORE_LEVEL_OBSERVER_OPTION = {
	value: GENDERSCORE_LEVEL_OBSERVER_VALUE,
	label: 'Observer',
	ranges: [0, 24],
	details: {
		analysis: <>
			<p>
				Although your organisation is aware of the importance of
				<strong>Diversity, Equity & Inclusion (DEI)</strong>, a lot
				more work needs to be done in order to achieve <strong>true
					parity in the workplace.</strong>
			</p>
		</>,
		range: <>
			OBSERVER companies have achieved a score between 0 and 24 points.
		</>,
		meaning: <>
			These companies are at the beginning of their diversity, equity and
			inclusion journey, and are keen to better support women in the workplace.
		</>,
	},
};

export const GENDERSCORE_LEVEL_DISCOVERER_OPTION = {
	value: GENDERSCORE_LEVEL_DISCOVERER_VALUE,
	label: 'Discoverer',
	ranges: [25, 39],
	details: {
		analysis: <>
			<p>
				Although your organisation is mindful of the importance of
				<strong>Diversity, Equity and Inclusion (DEI)</strong> efforts
				in the workplace, there still are a lot of steps that you and
				your team need to take in order to foster a truly <strong>inclusive
				and supportive environment, </strong>
				where everyone feels <strong>heard, seen and valued.</strong>
			</p>
		</>,
		range: <>
			DISCOVERER companies have achieved a score between 25 and 39 points.
		</>,
		meaning: <>
			These companies have already started to put in place diversity, equity
			and inclusion initiatives that will help them better support women in the workplace.
		</>,
	},
};

export const GENDERSCORE_LEVEL_ACHIEVER_OPTION = {
	value: GENDERSCORE_LEVEL_ACHIEVER_VALUE,
	label: 'Achiever',
	ranges: [40, 59],
	details: {
		analysis: <>
			<p>
				Your organisation understands the value of <strong>Diversity, Equity and
					Inclusion (DEI)</strong> efforts in the workplace.</p>
			<p>
				However, there still are some more steps to be taken in order to ensure
				that your organisation is fostering a truly <strong>inclusive and supportive
				environment</strong>, where everyone feels <strong>valued and respected.</strong>
			</p>
		</>,
		range: <>
			ACHIEVER companies have achieved a score between 40 and 59 points.
		</>,
		meaning: <>
			These companies have already started reaping the benefits of their
			diversity, equity and inclusion strategy, and are steadily working
			towards improving the everyday experiences of women in the workplace.
		</>,
	},
};

export const GENDERSCORE_LEVEL_PACEMAKER_OPTION = {
	value: GENDERSCORE_LEVEL_PACEMAKER_VALUE,
	label: 'Pacemaker',
	ranges: [60, 79],
	details: {
		analysis: <>
			<p>
				Your organisation has already taken great strides towards achieving a number
				of your meaningful <strong>Diversity, Equity and Inclusion (DEI)</strong> goals.</p>
			<p>
				Yet, there still is some more important work to be done in order to
				foster a work environment that is truly <strong>equitable, inclusive, and celebrates
				all forms of diversity.</strong>
			</p>
		</>,
		range: <>
			PACEMAKER companies have achieved a score between 60 and 79 points.
		</>,
		meaning: <>
			These companies have put in place an effective diversity, equity and
			inclusion strategy which has helped them to foster a work culture that
			supports women working at all levels of the company.
		</>,
	},
};

export const GENDERSCORE_LEVEL_PIONEER_OPTION = {
	value: GENDERSCORE_LEVEL_PIONEER_VALUE,
	label: 'Pioneer',
	ranges: [80, 100],
	details: {
		analysis: <>
			<p>
				Not only is your organisation successfully supporting Diversity, Equity
				and Inclusion (DEI) initiatives, but is also implementing policies and
				strategies that continue to make everyone, regardless of gender, feel
				heard, seen and respected in the workplace.
			</p>
			<p>
				However, this doesn’t mean that it’s time to slow down your efforts
				anytime soon. DEI is a journey, and not a destination!
			</p>
			<p>
				Therefore, it’s important that your employees see that they are part of
				an organisation that is continually working towards making them feel valued.
			</p>
		</>,
		range: <>
			PIONEER companies have earned a score over 80 points.
		</>,
		meaning: <>
			These companies are leading the pack when it comes to championing
			diversity, equity and inclusion efforts for all employees, regardless
			of their background. Moreover, they are always looking for
			new and innovative ways to better support their team.
		</>,
	},
};

export const GENDERSCORE_LEVEL_OPTIONS_LIST = [
	GENDERSCORE_LEVEL_OBSERVER_OPTION,
	GENDERSCORE_LEVEL_DISCOVERER_OPTION,
	GENDERSCORE_LEVEL_ACHIEVER_OPTION,
	GENDERSCORE_LEVEL_PACEMAKER_OPTION,
	GENDERSCORE_LEVEL_PIONEER_OPTION,
];

export const GENDERSCORE_LEVEL_OPTIONS_MAP = GENDERSCORE_LEVEL_OPTIONS_LIST
	.map((option) => ({ [option.value]: option })).reduce(mergeReducer, {});

export const getLevelOption = (level) => GENDERSCORE_LEVEL_OPTIONS_MAP[level];
export const getLevelIndex = (level) => GENDERSCORE_LEVEL_VALUES.indexOf(level);
export const getLevelLabel = (level) => getLevelOption(level)?.label;
export const getLevelRanges = (level) => getLevelOption(level)?.ranges;
export const getLevelDetails = (level) => getLevelOption(level)?.details;

export const hasNextLevel = (level) => (
	GENDERSCORE_LEVEL_VALUES.length > GENDERSCORE_LEVEL_VALUES.indexOf(level) + 1
);
export const getNextLevel = (level) => (
	hasNextLevel(level)
		? GENDERSCORE_LEVEL_VALUES[GENDERSCORE_LEVEL_VALUES.indexOf(level) + 1]
		: null
);

export const getNextLevelOption = (level) => getLevelOption(getNextLevel(level));
export const getNextLevelLabel = (level) => getLevelLabel(getNextLevel(level));
export const getNextLevelRanges = (level) => getLevelRanges(getNextLevel(level));
export const getNextLevelDetails = (level) => getLevelDetails(getNextLevel(level));

export const getLevelFromScore = (score) => (
	Object
		.entries(GENDERSCORE_LEVEL_OPTIONS_MAP)
		.find(([level, { ranges }]) => score >= ranges?.[0] && score <= ranges?.[1])
)?.[0];
