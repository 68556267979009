import { UMS_LABEL_SHORT } from '../../../../constants/constant';
import FormItem from '../../form/FormItem';
import CompanyPlanUMSSurveyManagerSelect from './CompanyPlanUMSSurveyManagerSelect';

export const companyPlanUMSSurveyManagerPath = ['companyPlan', 'ums', 'surveyManager'];

const CompanyPlanUMSSurveyManagerFormItem = ({
	required = false,
}) => {
	const className = 'CompanyPlanUMSSurveyManagerFormItem';

	return (
		<FormItem
			label={`${UMS_LABEL_SHORT} Survey for Managers`}
			name={companyPlanUMSSurveyManagerPath}
			rules={[
				{
					required,
					message: 'This field is required',
				},
			]}>
			<CompanyPlanUMSSurveyManagerSelect />
		</FormItem>
	);
};

export default CompanyPlanUMSSurveyManagerFormItem;
