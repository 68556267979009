import { useTheme } from '@emotion/react';
import { useContext, useEffect, useState } from 'react';
import { LocalDebug } from '../../utils/LocalDebug';
import { DataFetcherContext } from '../app/data/DataFetcherProvider';
import { UserSavedSearchContext } from './UserSavedSearchProvider';
import useBodyScrollBlocker from '../../hooks/useBodyScrollBlocker';
import UserSavedSearchForm, { cloneSavedSearchData } from './UserSavedSearchForm';
import { useSelector } from 'react-redux';
import { getCompanySelected, getTagsUsers } from '../../reducers/app';
import { message } from 'antd';
import SearchTalentsAffixedHeader from './SearchTalentsAffixedHeader';
import SearchTalentsUserGrid from './SearchTalentsUserGrid';
import SearchTalentsUserGridCaption from './SearchTalentsUserGridCaption';
import { useDebouncedEffect } from '../../hooks/useDebounceEffect';
import { documentId } from '../../utils/common';
import { StaffToolsContext } from '../../contexts/StaffToolsProvider';
import ModalContainerBase from '../../contexts/modals/ModalContainerBase';

const SearchTalents = (
	{
		withLoadMore = true,
	},
) => {
	const className = 'SearchTalents';

	const theme = useTheme();
	const tags = useSelector(getTagsUsers);
	const companySelected = useSelector(getCompanySelected);
	const { isSavedSearchExcludeViewedEnabled } = useContext(StaffToolsContext);

	const { selectedSavedSearch, excludedViewed, isFirstLoad } = useContext(UserSavedSearchContext);
	const { paramsDispatch, DATA_FETCHER_UPDATE_FILTERS, isLoading } = useContext(DataFetcherContext);
	const defaultTitle = <>🔍 Create new search</>;

	const [savedSearchFormTitle, setSavedSearchFormTitle] = useState(defaultTitle);
	const [savedSearchFormInitialValues, setSavedSearchFormInitialValues] = useState({});
	const [createModalOpen, setCreateModalOpen] = useState(false);
	const [textSearch, setTextSearch] = useState(null);
	const [dynamicSearch, setDynamicSearch] = useState({});
	const [dynamicSearchFields, setDynamicSearchFields] = useState([]);
	const [dynamicSearchDefaultFields, setDynamicSearchDefaultFields] = useState([]);
	const delay = 1000;

	useBodyScrollBlocker(createModalOpen, className);

	const onCreateSavedSearch = async () => {
		setSavedSearchFormTitle(defaultTitle);
		setSavedSearchFormInitialValues({});
		setCreateModalOpen(true);
	};

	const onEditSavedSearch = async (savedSearch) => {
		setSavedSearchFormTitle(<>🔍 Edit search</>);
		setSavedSearchFormInitialValues(savedSearch);
		setCreateModalOpen(true);
	};

	const onCloneSavedSearch = async (savedSearch) => {
		LocalDebug.logInfo({ className, method: 'onCloneSavedSearch' }, savedSearch);
		setSavedSearchFormTitle(<>🔍 Clone search</>);
		setSavedSearchFormInitialValues(cloneSavedSearchData(savedSearch));
		setCreateModalOpen(true);
	};

	const onFormSuccess = async (value) => {
		LocalDebug.logInfo({ className, method: 'onFormSuccess' }, value);
		setCreateModalOpen(false);
	};

	useEffect(() => {
		LocalDebug.logNull({ className, effects: 'selectedSavedSearch' }, { selectedSavedSearch });
		const fetchProfiles = async () => {
			// Fetch profiles that match the selected filter and the dynamic search
			await paramsDispatch({
				action: DATA_FETCHER_UPDATE_FILTERS,
				filters: {
					fromSavedSearch: true,
					companyId: documentId(companySelected),
					...selectedSavedSearch?.filters,
					excludedViewed,
					dynamicSearch,
					searchId: documentId(selectedSavedSearch),
				},
			});
		};
		if (!isFirstLoad || selectedSavedSearch) {
			fetchProfiles();
		}
	}, [selectedSavedSearch, excludedViewed, dynamicSearch, companySelected]);

	useEffect(() => {
		// Create the fields that will be used to filter with the value on the TextSearch
		const customFields = [
			{ label: 'First name', value: 'firstName' },
			{ label: 'Last name', value: 'lastName' },
			{ label: 'Position', value: 'information.position' },
			{ label: 'Company', value: 'information.company' },
		];

		setDynamicSearchFields(customFields);

		// Select all the above fields to be used in the dynamic search by default
		setDynamicSearchDefaultFields(customFields.map((c) => c.value));
	}, []);

	// ------------------------------------------ //
	// ------ handle search Dynamic ------------- //
	// ------------------------------------------ //
	useDebouncedEffect(async () => {
		LocalDebug.logUseEffect({ className, effects: `search(debounced by ${delay}ms)` }, { search: textSearch });

		let dynamicSearchObj = {};
		if (textSearch && textSearch !== '') {
			if (!dynamicSearchDefaultFields.length) {
				message.error('Please select a field');
				return;
			}
			dynamicSearchObj = {
				text: textSearch,
				fields: dynamicSearchDefaultFields,
			};
		}

		setDynamicSearch(dynamicSearchObj);
	}, [textSearch], delay);

	return (
		<div
			id='search-talents-page'
			className='search-talents-page'
			style={{
				position: 'relative',
			}}
		>
			<SearchTalentsAffixedHeader
				onCreate={onCreateSavedSearch}
				onEdit={onEditSavedSearch}
				onClone={onCloneSavedSearch}
				textSearch={textSearch}
				setTextSearch={setTextSearch}
				dynamicSearch={dynamicSearch}
				dynamicSearchFields={dynamicSearchFields}
				dynamicSearchDefaultFields={dynamicSearchDefaultFields}
				setDynamicSearchDefaultFields={setDynamicSearchDefaultFields}
			/>

			<SearchTalentsUserGridCaption/>

			<SearchTalentsUserGrid
				onCreateSavedSearch={onCreateSavedSearch}
				onEditSavedSearch={onEditSavedSearch}
			/>

			<ModalContainerBase
				open={createModalOpen}
				setOpen={setCreateModalOpen}
				width={1200}
			>

				<UserSavedSearchForm
					defaultTitle={defaultTitle}
					title={savedSearchFormTitle}
					initialValues={savedSearchFormInitialValues}
					onSuccess={onFormSuccess}
				/>

			</ModalContainerBase>

		</div>
	);
};

export default SearchTalents;
