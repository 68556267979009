import { Tooltip } from 'antd';
import { TeamOutlined } from '@ant-design/icons';
import { findActionPeople } from './action-plan';

const CompanyActionPeopleTag = ({
	action,
	people,
	...props
}) => {
	const className = 'CompanyActionPeopleTag';

	const peopleOption = findActionPeople(action?.people || people);

	if (!peopleOption) return null;

	return (
		<Tooltip title={peopleOption?.label}>
			<TeamOutlined /> {peopleOption?.tagLabel}
		</Tooltip>
	);
};

export default CompanyActionPeopleTag;
