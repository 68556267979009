import { Space, Spin } from 'antd';
import { useRef, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import { useDebouncedEffect } from '../../hooks/useDebounceEffect';
import config from '../../config/config';
import { useTheme } from '@emotion/react';
import SurveyQuestionInput from '../genderscore/survey/SurveyQuestionInput';
import LoadSpinner from '../app/LoadSpinner';

const ChatbotMessages = ({
	messages,
	answerPending,
	question,
	height,
	onQuestionInput,
}) => {
	const className = 'ChatbotMessages';
	const theme = useTheme();

	const messagesRef = useRef(null);

	useDebouncedEffect(() => {
		try {
			messagesRef.current.scrollTop = messagesRef.current?.scrollHeight;
		} catch (e) {}
	}, [messages, question], 0);

	return (
		<div
			// direction='vertical'
			ref={messagesRef}
			// size={0}
			style={{
				width: 'calc(100% - 0px)',
				maxWidth: 'calc(100% - 0px)',
				maxHeight: height,
				overflowY: 'scroll',
				wordWrap: 'normal',
			}}
		>
			{messages
				?.map?.((message, index) => {
					let { role, content } = message;
					let markdown = true;
					let justifyContent = 'flex-start';
					let textAlign = 'left';
					let background = '#eee';
					let color = 'black';

					switch (role) {
						case 'assistant':
							if (content === '...') {
								content = <div style={{ margin: 10 }}>
									<Spin />
								</div>;
								markdown = false;
							}
							break;
						case 'system':
							justifyContent = 'center';
							// textAlign = 'center';
							background = '#ccc';
							color = '#666';
							break;
						case 'user':
							justifyContent = 'flex-end';
							textAlign = 'right';
							background = theme.color.fitBlueElectric;
							color = 'white';
							break;
						default:
							break;
					}

					return (
						<div
							key={index}
							style={{
								display: 'flex',
								width: '100%',
								justifyContent,
								marginBottom: 20,
							}}
						>
							<div
								key={index}
								style={{
									// display: 'inline-block',
									padding: '2px 18px',
									maxWidth: '80%',
									borderRadius: 20,
									fontSize: 13,
									textAlign,
									background,
									color,
								}}
							>

								{markdown
									? (<ReactMarkdown >
										{content}
									</ReactMarkdown>
									)
									: content
								}

							</div>
						</div>
					);
				})}

			{question?.options?.length >= 1 && (
				<div style={{ marginBottom: 10 }}>
					<SurveyQuestionInput
						question={question}
						onChange={onQuestionInput}
					/>
				</div>
			)}
		</div>
	);
};

export default ChatbotMessages;
