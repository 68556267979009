import Box from '../box/Box';
import FormHorizontalRow from './FormHorizontalRow';
import FormItem from '../../company/form/FormItem';
import { Divider } from 'antd';
import { useTheme } from '@emotion/react';
import { mixColors } from '../../../utils/common';

const FormBoxTitle = (
	{
		title,
		details,
		open = true,
		children,
		withShadow = false,
		style,
		...props
	},
) => {
	const theme = useTheme();

	return open
		&& <Box
			withShadow={withShadow}
			style={{
				padding: 0,
				borderRadius: 0,
				border: 'none',
				margin: '0',
				...style || {},
			}}
			{...props}
		>
			<div style={{
				background: theme.background.gradientBluePink2(),
				width: 'calc(100% - 36px)',
				padding: '20px 60px 20px 24px',
				marginLeft: -24,
				marginTop: -20,
				marginBottom: 20,
				...style?.title,
			}}>
				<FormItem
					style={{ marginBottom: (details ? 0 : 0) }}
				>
					<h2 style={{ margin: 0 }}>{title}</h2>
				</FormItem>

				{details
					&& (
						<FormItem
							style={{ marginTop: 0, marginBottom: 0 }}
						>
							<div
								style={{
									fontSize: 12,
									color: theme.color.darkgrey,
									...style?.details,
								}}
							>
								{details}
							</div>
						</FormItem>
					)
				}
			</div>

			{children}

		</Box>;
};

export default FormBoxTitle;
