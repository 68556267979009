import { useTheme } from '@emotion/react';
import { useEffect, useState } from 'react';
import Table from '../../app/table/Table';
import { useAuthContext } from '../../../hooks/useAuthContext';
import JSONPretty from 'react-json-pretty';
import TableColumns from '../../app/table/TableColumns';
import { useDispatch, useSelector } from 'react-redux';
import { Divider, Space, Tag } from 'antd';
import { getAtsPipelines } from '../../../reducers/app';
import { localDebug } from '../../../utils/LocalDebug';

const AtsPipelineTable = (
	{
		isRefresh = 0,
		...props
	},
) => {
	const theme = useTheme();
	const dispatch = useDispatch();
	const atsPipelines = useSelector(getAtsPipelines);

	const { isStaffView } = useAuthContext();

	const [data, setData] = useState([]);
	const [isRefreshDataLayout, setIsRefreshDataLayout] = useState(0);

	const refreshTable = () => {
		setIsRefreshDataLayout((prev) => prev + 1);
	};

	useEffect(() => {
		localDebug('useEffect[atsPipelines]', { atsPipelines });
		setData(atsPipelines);
	}, [atsPipelines]);
	const jsonCol = {
		title: 'JSON',
		dataIndex: '_id',
		render: (value, row) => <JSONPretty data={row}/>,
		...TableColumns.columnMinWidth(300),
	};

	const pipelineCol = {
		title: 'Pipeline',
		dataIndex: '_id',
		render: (value, row) => <Tag icon={row.icon} color='green'>&nbsp;<b>{row.label} [{row.value}]</b></Tag>,
		...TableColumns.columnFixedWidth(160),
	};

	const stagesCol = {
		title: 'Stages',
		dataIndex: 'stages',
		render: (value, row) => <Space direction='vertical' size={2}>
			{value?.map((stage, index) => {
				return <>
					<Space direction='horizontal' size={0}>
						<Tag key={index} icon={stage.icon} color='cyan'>&nbsp;<b>{stage.label}</b></Tag>
						<Space direction='horizontal' size={4} wrap={true}>
							{stage?.atsMappings?.map((m, i) => <code key={i}>{m}</code>)}
						</Space>
						{/* <JSONPretty data={stage}/> */}
					</Space>
					<Divider style={{ margin: 4 }}/>
				</>;
			})}
		</Space>,
		...TableColumns.columnMinWidth(400),
	};

	// const mappingsCol = {
	//     title: 'Stage mappings',
	//     dataIndex: 'atsMappings',
	//     render: () => <Space direction='horizontal' size={4}>
	//         {row?.stage?.atsMappings?.map((m, i) => <code key={i}>{m}</code>)}
	//     </Space>,
	//     ...TableColumns.columnMinWidth(240),
	// };

	const columns = isStaffView
		? [
			// jsonCol,
			pipelineCol,
			stagesCol,
		]
		: [
			pipelineCol,
			stagesCol,
		];

	return (true
		&& <>
			{/* <JSONPretty data={atsPipelines || []}/> */}
			{/* <JSONPretty data={stages || []}/> */}
			{/* <JSONPretty data={data || []}/> */}
			<Table
				data={data}
				// path={`company/ats-pipeline-stages?group=true`}
				columns={columns}
				isRefresh={isRefresh + isRefreshDataLayout}
				withPagination={false}
				{...props}
			/>
		</>
	);
};

export default AtsPipelineTable;
