import { ENGLISH_LANG } from '../../../../constants/constant';
import CompanyRichTextEditor from '../shared/CompanyRichTextEditor';

const CompanyValuesEditor = (
	{
		company,
		lang = ENGLISH_LANG,
		setEditorOpen,
	},
) => {
	const className = 'CompanyValuesEditor';

	return (
		<CompanyRichTextEditor
			company={company}
			lang={lang}
			title={<b>Values</b>}
			details={<span>
				Describing your company's values is essential.
				It communicates the principles that guide your
				business decisions and actions
			</span>}
			field={'values'}
			setEditorOpen={setEditorOpen}
		/>
	);
};

export default CompanyValuesEditor;
