import { useContext, useEffect, useState } from 'react';
import { useTheme } from '@emotion/react';
import {
	Col, Popover, Row, Space,
} from 'antd';
import { useAuthContext } from '../../../hooks/useAuthContext';
import { CompanyPageContext } from './shared/CompanyPageProvider';
import CompanyEditableContent from './shared/CompanyEditableContent';
import CompanyWeLikeDisplay from './fields/CompanyWeLikeDisplay';
import CompanyWeLikeEditor from './fields/CompanyWeLikeEditor';
import CompanyMissionDisplay from './fields/CompanyMissionDisplay';
import CompanyRelevantDataDisplay from './fields/CompanyRelevantDataDisplay';
import CompanyRelevantDataEditor from './fields/CompanyRelevantDataEditor';
import CompanyValuesDisplay from './fields/CompanyValuesDisplay';
import CompanyValuesEditor from './fields/CompanyValuesEditor';
import CompanyQuoteDisplay from './fields/CompanyQuoteDisplay';
import CompanyQuoteEditor from './fields/CompanyQuoteEditor';
import CompanyCommunitiesDisplay from './fields/CompanyCommunitiesDisplay';
import CompanyCommunitiesEditor from './fields/CompanyCommunitiesEditor';
import CompanyDiversityActionsDisplay from './fields/CompanyDiversityActionsDisplay';
import CompanyDiversityActionsEditor from './fields/CompanyDiversityActionsEditor';
import CompanyBenefitsDisplay from './fields/CompanyBenefitsDisplay';
import CompanyBenefitsEditor from './fields/CompanyBenefitsEditor';
import CompanyRecruitmentDisplay from './fields/CompanyRecruitmentDisplay';
import CompanyRecruitmentEditor from './fields/CompanyRecruitmentEditor';
import CompanyTeamDisplay from './fields/CompanyTeamDisplay';
import CompanyTeamEditor from './fields/CompanyTeamEditor';
import CompanyStoriesDisplay from './fields/CompanyStoriesDisplay';
import CompanyStoriesEditor from './fields/CompanyStoriesEditor';
import CompanyLogoDisplay from './fields/CompanyLogoDisplay';
import CompanyLogoEditor from './fields/CompanyLogoEditor';
import CompanyNameDisplay from './fields/CompanyNameDisplay';
import CompanyNameEditor from './fields/CompanyNameEditor';
import CompanyOneLinerDisplay from './fields/CompanyOneLinerDisplay';
import CompanyOneLinerEditor from './fields/CompanyOneLinerEditor';
import CompanyMissionEditor from './fields/CompanyMissionEditor';
import CompanyWebsiteDisplay from './fields/CompanyWebsiteDisplay';
import CompanyWebsiteEditor from './fields/CompanyWebsiteEditor';
import CompanyTeamSizeDisplay from './fields/CompanyTeamSizeDisplay';
import CompanyTeamSizeEditor from './fields/CompanyTeamSizeEditor';
import CompanyIndustryDisplay from './fields/CompanyIndustryDisplay';
import CompanyIndustryEditor from './fields/CompanyIndustryEditor';
import CompanyHeadquartersDisplay from './fields/CompanyHeadquartersDisplay';
import CompanyHeadquartersEditor from './fields/CompanyHeadquartersEditor';
import CompanyPathDisplay from './fields/CompanyPathDisplay';
import CompanyPathEditor from './fields/CompanyPathEditor';
import CompanyGenderScoreDisplay from './fields/CompanyGenderScoreDisplay';
import CompanyGenderScoreEditor from './fields/CompanyGenderScoreEditor';
import CompanyGenderScoreEditorLocked from './fields/CompanyGenderScoreEditorLocked';
import CompanyGenderScoreEditorOnlyDisplay from './fields/CompanyGenderScoreEditorOnlyDisplay';
import CompanyGenderScoreEditorOnlyEditorLocked from './fields/CompanyGenderScoreEditorOnlyEditorLocked';
import CompanyGlassdoorDisplay from './fields/CompanyGlassdoorDisplay';
import CompanyGlassdoorEditor from './fields/CompanyGlassdoorEditor';
import {
	COMPANY_PAGE_STEP_BENEFITS_ID,
	COMPANY_PAGE_STEP_COMMUNITIES_ID,
	COMPANY_PAGE_STEP_DEI_ACTIONS_ID,
	COMPANY_PAGE_STEP_GENDERSCORE_ID,
	COMPANY_PAGE_STEP_GLASSDOOR_ID,
	COMPANY_PAGE_STEP_HEADQUARTERS_ID,
	COMPANY_PAGE_STEP_INDUSTRY_ID,
	COMPANY_PAGE_STEP_LOGO_ID,
	COMPANY_PAGE_STEP_MISSION_ID,
	COMPANY_PAGE_STEP_NAME_ID,
	COMPANY_PAGE_STEP_ONELINER_ID,
	COMPANY_PAGE_STEP_PATH_ID,
	COMPANY_PAGE_STEP_QUOTE_ID,
	COMPANY_PAGE_STEP_RECRUITMENT_ID,
	COMPANY_PAGE_STEP_RELEVANT_DATA_ID,
	COMPANY_PAGE_STEP_STORIES_ID,
	COMPANY_PAGE_STEP_TEAMSIZE_ID,
	COMPANY_PAGE_STEP_TEAM_ID,
	COMPANY_PAGE_STEP_VALUES_ID,
	COMPANY_PAGE_STEP_WEBSITE_ID,
	COMPANY_PAGE_STEP_WELIKE_ID,
} from './shared/CompanyPageSteps';
import { isGenderScorePlan } from '../../../constants/property/business-plan';
import { CompanyContext } from '../../../contexts/CompanyProvider';
import CompanyModel, { GENDERSCORE_PASSING_DISALLOWED_REASONS } from '../../../models/CompanyModel';
import { GENDERSCORE_LABEL } from '../../../constants/constant';

const POPIN_WIDTHS = {
	regular: 500,
	large: 700,
};
const CompanyLogoBlock = ({ company, lang, popinWidth = POPIN_WIDTHS.large }) => {
	return (
		<CompanyEditableContent
			contentStep={COMPANY_PAGE_STEP_LOGO_ID}
			company={company} lang={lang}
			DisplayComponent={CompanyLogoDisplay}
			EditorComponent={CompanyLogoEditor}
			popinWidth={popinWidth}
		/>
	);
};

const CompanyNameBlock = ({ company, lang, popinWidth = POPIN_WIDTHS.regular }) => {
	return (
		<CompanyEditableContent
			contentStep={COMPANY_PAGE_STEP_NAME_ID}
			company={company} lang={lang}
			DisplayComponent={CompanyNameDisplay}
			EditorComponent={CompanyNameEditor}
			withOverlayLabel={false}
			popinWidth={popinWidth}
		/>
	);
};

const CompanyOneLinerBlock = ({ company, lang, popinWidth = POPIN_WIDTHS.large }) => {
	return (
		<CompanyEditableContent
			contentStep={COMPANY_PAGE_STEP_ONELINER_ID}
			company={company} lang={lang}
			DisplayComponent={CompanyOneLinerDisplay}
			EditorComponent={CompanyOneLinerEditor}
			withOverlayLabel={false}
			popinWidth={popinWidth}
		/>
	);
};

const CompanyWebsiteBlock = ({ company, lang, popinWidth = POPIN_WIDTHS.large }) => {
	return (
		<CompanyEditableContent
			contentStep={COMPANY_PAGE_STEP_WEBSITE_ID}
			company={company} lang={lang}
			DisplayComponent={CompanyWebsiteDisplay}
			EditorComponent={CompanyWebsiteEditor}
			withOverlayLabel={false}
			popinWidth={popinWidth}
		/>
	);
};

const CompanyHeadquartersBlock = ({ company, lang, popinWidth = POPIN_WIDTHS.regular }) => {
	return (
		<CompanyEditableContent
			contentStep={COMPANY_PAGE_STEP_HEADQUARTERS_ID}
			company={company} lang={lang}
			DisplayComponent={CompanyHeadquartersDisplay}
			EditorComponent={CompanyHeadquartersEditor}
			withOverlayLabel={false}
			popinWidth={popinWidth}
		/>
	);
};

const CompanyTeamSizeBlock = ({ company, lang, popinWidth = POPIN_WIDTHS.regular }) => {
	return (
		<CompanyEditableContent
			contentStep={COMPANY_PAGE_STEP_TEAMSIZE_ID}
			company={company} lang={lang}
			isTranslatable={false}
			DisplayComponent={CompanyTeamSizeDisplay}
			EditorComponent={CompanyTeamSizeEditor}
			withOverlayLabel={false}
			popinWidth={popinWidth}
		/>
	);
};

const CompanyIndustryBlock = ({ company, lang, popinWidth = POPIN_WIDTHS.regular }) => {
	return (
		<CompanyEditableContent
			contentStep={COMPANY_PAGE_STEP_INDUSTRY_ID}
			company={company} lang={lang}
			isTranslatable={false}
			DisplayComponent={CompanyIndustryDisplay}
			EditorComponent={CompanyIndustryEditor}
			withOverlayLabel={false}
			popinWidth={popinWidth}
		/>
	);
};

const CompanyPathBlock = ({ company, lang, popinWidth = POPIN_WIDTHS.regular }) => {
	return (
		<CompanyEditableContent
			contentStep={COMPANY_PAGE_STEP_PATH_ID}
			company={company} lang={lang}
			DisplayComponent={CompanyPathDisplay}
			EditorComponent={CompanyPathEditor}
			withOverlayLabel={false}
			isTranslatable={false}
			isStaffLocked={true}
			popinWidth={popinWidth}
		/>
	);
};

const CompanyGenderscoreBlock = ({
	company,
	lang,
	isStaff,
	isStaffView,
	isDocumentAdmin,
	isDocumentEditorOnly,
	isGenderScoreEnabled,
	currentUser,
}) => {
	if (isGenderScoreEnabled === false) {
		return null;
	}

	const companyModel = new CompanyModel(company);
	const reasons = companyModel.getGenderScorePassingDisallowedReasons({
		isDocumentAdmin,
		currentUser,
	});

	const isPublished = companyModel.isGenderScoreBadgeStaffPublished();

	if (!isPublished) {
		return (
			<Popover
				title=''
				content={(
					<div style={{ maxWidth: 300 }}>
						{GENDERSCORE_LABEL} currently <b>unpublished</b> by 50inTech: it is not visible anymore to talents.
						<br />Talk to your CSM to know more.
					</div>
				)}
				placement='left'
			>
				<div>
					<div
						style={{
							userSelect: 'none',
							pointerEvents: 'none',
						}}
					>
						<CompanyGenderScoreDisplay
							company={company}
							lang={lang}
							isUnpublished={true}
						/>
					</div>
				</div>
			</Popover>
		);
	}

	const isEditable = companyModel.isGenderScoreEnabled();

	if ((reasons || []).includes(GENDERSCORE_PASSING_DISALLOWED_REASONS.USER_ROLE)) {
		return (
			<CompanyEditableContent
				contentStep={COMPANY_PAGE_STEP_GENDERSCORE_ID}
				company={company} lang={lang}
				isEditable={true}
				overlayLabel={'Locked'}
				DisplayComponent={CompanyGenderScoreEditorOnlyDisplay}
				EditorComponent={CompanyGenderScoreEditorOnlyEditorLocked}
				EditorLockedComponent={CompanyGenderScoreEditorOnlyEditorLocked}
				popinWidth={1200}
				popinWidthLocked={600}
				isStaffLocked={true}
			/>
		);
	}

	return (
		<CompanyEditableContent
			contentStep={COMPANY_PAGE_STEP_GENDERSCORE_ID}
			company={company} lang={lang}
			isEditable={isEditable}
			overlayLabel={'Fill'}
			DisplayComponent={CompanyGenderScoreDisplay}
			EditorComponent={CompanyGenderScoreEditor}
			EditorLockedComponent={CompanyGenderScoreEditorLocked}
			popinWidth={1200}
			popinWidthLocked={600}
			isStaffLocked={true}
		/>
	);
};

const CompanyMissionBlock = ({ company, lang }) => {
	return (
		<CompanyEditableContent
			contentStep={COMPANY_PAGE_STEP_MISSION_ID}
			company={company} lang={lang}
			DisplayComponent={CompanyMissionDisplay}
			EditorComponent={CompanyMissionEditor}
		/>
	);
};

const CompanyWeLikeBlock = ({ company, lang }) => {
	return (
		<CompanyEditableContent
			contentStep={COMPANY_PAGE_STEP_WELIKE_ID}
			company={company} lang={lang}
			DisplayComponent={CompanyWeLikeDisplay}
			EditorComponent={CompanyWeLikeEditor}
			isStaffLocked={true}
		/>
	);
};

const CompanyValuesBlock = ({ company, lang }) => {
	return (
		<CompanyEditableContent
			contentStep={COMPANY_PAGE_STEP_VALUES_ID}
			company={company} lang={lang}
			DisplayComponent={CompanyValuesDisplay}
			EditorComponent={CompanyValuesEditor}
		/>
	);
};

const CompanyDeiActionsBlock = ({ company, lang }) => {
	return (
		<CompanyEditableContent
			contentStep={COMPANY_PAGE_STEP_DEI_ACTIONS_ID}
			company={company} lang={lang}
			DisplayComponent={CompanyDiversityActionsDisplay}
			EditorComponent={CompanyDiversityActionsEditor}
			isStaffLocked={true}
		/>
	);
};

const CompanyQuoteBlock = ({ company, lang }) => {
	return (
		<CompanyEditableContent
			contentStep={COMPANY_PAGE_STEP_QUOTE_ID}
			company={company} lang={lang}
			DisplayComponent={CompanyQuoteDisplay}
			EditorComponent={CompanyQuoteEditor}
		/>
	);
};

const CompanyCommunitiesBlock = ({ company, lang }) => {
	return (
		<CompanyEditableContent
			contentStep={COMPANY_PAGE_STEP_COMMUNITIES_ID}
			company={company} lang={lang}
			DisplayComponent={CompanyCommunitiesDisplay}
			EditorComponent={CompanyCommunitiesEditor}
		/>
	);
};

const CompanBenefitsBlock = ({ company, lang }) => {
	return (
		<CompanyEditableContent
			contentStep={COMPANY_PAGE_STEP_BENEFITS_ID}
			company={company} lang={lang}
			DisplayComponent={CompanyBenefitsDisplay}
			EditorComponent={CompanyBenefitsEditor}
		/>
	);
};

const CompanyRecruitmentBlock = ({ company, lang }) => {
	return (
		<CompanyEditableContent
			contentStep={COMPANY_PAGE_STEP_RECRUITMENT_ID}
			company={company} lang={lang}
			DisplayComponent={CompanyRecruitmentDisplay}
			EditorComponent={CompanyRecruitmentEditor}
		/>
	);
};

const CompanyTeamBlock = ({ company, lang }) => {
	return (
		<CompanyEditableContent
			contentStep={COMPANY_PAGE_STEP_TEAM_ID}
			company={company} lang={lang}
			DisplayComponent={CompanyTeamDisplay}
			EditorComponent={CompanyTeamEditor}
			isTranslatable={false}
			isStaffLocked={true}
		/>
	);
};

const CompanyRelevantDataBlock = ({ company, lang }) => {
	return (
		<CompanyEditableContent
			contentStep={COMPANY_PAGE_STEP_RELEVANT_DATA_ID}
			company={company} lang={lang}
			DisplayComponent={CompanyRelevantDataDisplay}
			EditorComponent={CompanyRelevantDataEditor}
		/>
	);
};

const CompanyGlassdoorBlock = ({ company, lang }) => {
	return (
		<CompanyEditableContent
			contentStep={COMPANY_PAGE_STEP_GLASSDOOR_ID}
			company={company} lang={lang}
			isTranslatable={false}
			DisplayComponent={CompanyGlassdoorDisplay}
			EditorComponent={CompanyGlassdoorEditor}
			popinWidth={400}
		/>
	);
};

const CompanyStoriesBlock = ({ company, lang }) => {
	return (
		<CompanyEditableContent
			contentStep={COMPANY_PAGE_STEP_STORIES_ID}
			company={company} lang={lang}
			DisplayComponent={CompanyStoriesDisplay}
			EditorComponent={CompanyStoriesEditor}
			isStaffLocked={true}
		/>
	);
};

const CompanyPageEditionLayout = (
	{
		company,
		...props
	},
) => {
	const className = 'CompanyPageEditionLayout';

	const theme = useTheme();

	const authProps = useAuthContext();
	const { planValue } = useContext(CompanyContext);
	const { lang } = useContext(CompanyPageContext);
	const [layout, setLayout] = useState({});
	const [blockProps, setBlockProps] = useState({
		company,
		lang,
		...authProps,
	});

	useEffect(() => {
		if (!company) {
			setLayout({});
			setBlockProps({});
		} else {
			if (isGenderScorePlan(planValue)) {
				setLayout({
					body: [
						CompanyMissionBlock,
						CompanyValuesBlock,
						CompanyQuoteBlock,
						CompanyCommunitiesBlock,
						CompanBenefitsBlock,
						CompanyRecruitmentBlock,
					],
					rightColumn: [
						CompanyRelevantDataBlock,
						CompanyGlassdoorBlock,
					],
				});
			} else {
				setLayout({
					body: [
						CompanyMissionBlock,
						CompanyWeLikeBlock,
						CompanyValuesBlock,
						CompanyDeiActionsBlock,
						CompanyQuoteBlock,
						CompanyCommunitiesBlock,
						CompanBenefitsBlock,
						CompanyRecruitmentBlock,
						CompanyTeamBlock,
					],
					rightColumn: [
						CompanyRelevantDataBlock,
						CompanyGlassdoorBlock,
						CompanyStoriesBlock,
					],
				});
			}
			setBlockProps({
				company,
				lang,
				...authProps,
			});
		}
	}, [
		planValue,
		company,
		lang,
		authProps,
	]);

	return (
		<div style={{ margin: 32 }}>
			<Row gutter={[48, 48]}>
				<Col span={24}>
					<Space
						size={40}
						align='top'
						style={{
							width: '100%',
							justifyContent: 'space-between',
						}}
					>
						<Space
							size={30}
							align='top'
							style={{ width: '100%' }}
						>

							<CompanyLogoBlock {...blockProps} />

							<Space
								direction='vertical'
								align='top'
								style={{ width: '100%' }}
							>
								<CompanyNameBlock {...blockProps} />

								<CompanyOneLinerBlock {...blockProps} />

								<CompanyWebsiteBlock {...blockProps} />

								<Space size={30}>

									<CompanyHeadquartersBlock {...blockProps} />
									<CompanyTeamSizeBlock {...blockProps} />
									<CompanyIndustryBlock {...blockProps} />

								</Space>

								<CompanyPathBlock {...blockProps} />

							</Space>
						</Space>

						<CompanyGenderscoreBlock {...blockProps} />

					</Space>
				</Col>
				<Col span={16}>
					<Row gutter={[48, 48]}>
						{layout?.body?.map?.((BlockComponent, index) => (
							<Col span={24} key={index}>
								<BlockComponent {...blockProps} />
							</Col>
						))}
					</Row>
				</Col>
				<Col span={8}>
					<Row gutter={[48, 48]}>
						{layout?.rightColumn?.map?.((BlockComponent, index) => (
							<Col span={24} key={index}>
								<BlockComponent {...blockProps} />
							</Col>
						))}
					</Row>
				</Col>
			</Row>
		</div>
	);
};

export default CompanyPageEditionLayout;
