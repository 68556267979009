import { PERMS_GENDERSCORE_FILLED } from './genderscore-utils';
import { getPillarIcon } from '../../constants/genderscore-pillars';
import MenuBase from '../MenuBase';
import { mixColors } from '../../utils/common';

class GenderscoreResultsPillarMenu extends MenuBase {
	constructor({ pillar, Component }) {
		super();
		this.name = `genderscore-${pillar.value}`;
		this.path = `/genderscore/${pillar.slug}`;
		this.label = pillar.label;
		this.icon = getPillarIcon(
			pillar.value,
			{
				width: 14,
				background: '#ffffff',
				withBackground: false,
				color: mixColors('#ffffff', '#000000', 70),
				style: {
					border: '1px solid rgba(0,0,0,.7)',
					borderRadius: 2,
					width: 14,
					height: 14,
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
				},
			},
		);
		this.Component = Component;
		this.perms = PERMS_GENDERSCORE_FILLED;
	}
}

export default GenderscoreResultsPillarMenu;
