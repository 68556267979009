import { useTheme } from '@emotion/react';
import { Button, DatePicker, Form } from 'antd';
import { treatSourcingActionToDb } from '../../actions/sourcings';
import { useAuthContext } from '../../hooks/useAuthContext';
import { DATE_FORMAT, SOURCING_ACTION_COMMENT } from '../../constants/constant';
import CheckboxTable from '../app/table/CheckboxTable';
import FormItem from '../company/form/FormItem';
import TextArea from 'antd/es/input/TextArea';
import { useState } from 'react';
import dayjs from 'dayjs';
import { documentId, requiredTrimRule } from '../../utils/common';

const SourcingCommentForm = ({ initialValues = {}, onSubmit, onReset }) => {
	const theme = useTheme();
	const [form] = Form.useForm();
	const { currentUser, isStaff, isStaffView } = useAuthContext();

	const [isReadOnly] = useState(initialValues?.isReadOnly || false);

	initialValues = {
		'creator': documentId(currentUser),
		...initialValues,
		'postedAt': initialValues?.postedAt ? dayjs(initialValues.postedAt) : dayjs(),
		'payload.text': initialValues?.payload?.text || '',
	};

	// ------------------------------------- //
	// ---------------- state -------------- //
	// ------------------------------------- //
	const [isStaffPrivate, setIsStaffPrivate] = useState(initialValues?.isStaffPrivate || false);
	const sourcingId = documentId(initialValues) || null;

	// ---------------------------------- //
	// -------- handle submit ----------- //
	// ---------------------------------- //
	const handleSubmit = (values) => {
		const id = documentId(initialValues) || null;
		const history = {
			...values,
			'managerId': documentId(currentUser),
			'creator': initialValues?.creator,
			'author': documentId(currentUser),
			'action': SOURCING_ACTION_COMMENT,
			isStaffPrivate,
			'payload.isStaffPrivate': isStaffPrivate,
			id,
		};

		const toSubmit = treatSourcingActionToDb(history, currentUser, isStaffPrivate);

		onSubmit({
			...toSubmit,
			add: initialValues?.add || (() => {}),
		}, values.action);
	};

	// --------------------------------------- //
	// ----------- handle private ------------ //
	// --------------------------------------- //
	const handleChangePrivate = (event) => {
		setIsStaffPrivate(event.target.checked);
	};

	return (
		<>
			<p style={{ color: theme.color.grey }}>It will be displayed in the history of this candidate.</p>
			<Form
				form={form}
				name="action_form"
				labelCol={{ span: 6 }}
				initialValues={{ ...initialValues }}
				onFinish={handleSubmit}
			>
				<FormItem
					label="Comment"
					name="payload.text"
					defaultContainer="div"
					rules={[requiredTrimRule]}
				>
					<TextArea rows={6} />
				</FormItem>
				{isStaffView
					&& <FormItem
						label="Private"
						defaultContainer="div"
						name="isStaffPrivate"
					>
						<CheckboxTable value={true} isChecked={isStaffPrivate} onChange={handleChangePrivate}/>
					</FormItem>
				}

				<FormItem
					label="Posting date"
					defaultContainer="div"
					name="postedAt"
					rules={[{
						type: 'object',
						required: false,
						message: 'Please input date',
						whitespace: true,
					}]}
				>
					<DatePicker format={DATE_FORMAT}/>
				</FormItem>
				<div style={{
					display: 'flex',
					flexDirection: 'row-reverse',
					margin: -5,
				}}>
					{!isReadOnly && <Button type="primary"
						style={{
							margin: 5,
							background: theme.color.blue,
							borderColor: theme.color.blue,
							fontWeight: 'bold',
							borderRadius: 5,
						}}
						htmlType="submit"
					>
						Save
					</Button>
					}
				</div>
			</Form>
		</>
	);
};

export default SourcingCommentForm;
