import { Button } from 'antd';
import { useTheme } from '@emotion/react';
import { useEffect, useRef, useState } from 'react';
import { LocalDebug } from '../../../../../utils/LocalDebug';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';
import { getDocumentScrollTop, hexToRgb } from '../../../../../utils/common';

const UserGridCardExpander = (
	{
		children,
		containerBackground = '#ffffff',
		containerPaddingSide = 0,
		maxCollapsedHeight = 200,
		expandedExtraPaddingBottom = 32,
		expandLabel = 'Expand',
		collapseLabel = 'Collapse',
		...props
	},
) => {
	const className = 'UserGridCardExpander';

	const theme = useTheme();

	const refContainer = useRef();
	const refChildren = useRef();

	const [scroll, setScroll] = useState();
	const [isExpandable, setIsExpandable] = useState(true);
	const [open, setOpen] = useState(false);
	const [transitionDuration, setTransitionDuration] = useState(1);
	const [childrenHeight, setChildrenHeight] = useState(maxCollapsedHeight);

	useEffect(() => {
		LocalDebug.logUseEffect({ className, effects: '' }, {
			refContainer: refContainer?.current?.offsetHeight,
			refChildren: refChildren?.current?.offsetHeight,
		});
		if (refChildren.current && refContainer.current) {
			const refChildrenHeight = refChildren.current.offsetHeight;
			setChildrenHeight(refChildrenHeight);
			const refContainerHeight = refContainer.current.offsetHeight;
			if (refContainerHeight < maxCollapsedHeight) {
				setIsExpandable(false);
			} else {
				setTransitionDuration(Math.round(Math.min(1, refContainerHeight / 800) * 10) / 10);
			}
		}
	}, []);

	const handleExpand = () => {
		if (open) {
			window.scrollTo({ top: Math.min(getDocumentScrollTop(), scroll), behavior: 'smooth' });
		} else {
			setScroll(getDocumentScrollTop());
		}
		setOpen((p) => !p);
	};

	if (!children) return null;

	return (
		<>
			<div
				ref={refContainer}
				style={{
					position: 'relative',
					...isExpandable && {
						maxHeight: childrenHeight,
						...!open
							? { maxHeight: maxCollapsedHeight }
							: { maxHeight: childrenHeight + expandedExtraPaddingBottom },
						transition: `all ${transitionDuration}s ease-in-out`,
						paddingBottom: 0,
						overflow: 'hidden',
					},
				}}
			>
				<div
					ref={refChildren}
					style={{
						position: 'relative',
						width: '100%',
						paddingBottom: 0,
						...open && {
							paddingBottom: expandedExtraPaddingBottom,
						},
						transition: `all ${transitionDuration}s ease-in-out`,
					}}
				>
					{children}
				</div>
			</div>
			{isExpandable && (
				<div
					style={{
						position: 'absolute',
						display: 'flex',
						width: `calc(100% - ${containerPaddingSide}px)`,
						height: 140,
						justifyContent: 'center',
						alignItems: 'flex-end',
						bottom: 0,
						background: `linear-gradient(to bottom, rgba(${hexToRgb(containerBackground).join(',')},0) ${open ? '70%' : '0%'}, rgba(${hexToRgb(containerBackground).join(',')},1) 100%)`,
						transition: `all ${transitionDuration}s ease-in-out`,
						zIndex: 1,
					}}
				>
					<Button
						type={'primary'}
						ghost
						onClick={handleExpand}
						style={{
							background: 'white',
							borderRadius: 32,
							// marginBottom: 24,
						}}
					>
						{open
							? <>{collapseLabel} <FaChevronUp style={{ fontSize: 12, marginBottom: -2, opacity: 1 }} /></>
							: <>{expandLabel} <FaChevronDown style={{ fontSize: 12, marginBottom: -2, opacity: 1 }} /></>
						}
					</Button>
				</div>
			)}
		</>
	);
};

export default UserGridCardExpander;
