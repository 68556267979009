import { useTheme } from '@emotion/react';
import { Space } from 'antd';
import GenderScoreTitleBlock from './GenderScoreTitleBlock';
import GenderScoreNotProvidedBlock from './GenderScoreNotProvidedBlock';
import RadialGraph from '../graphs/RadialGraph';

/**
 * @param {string} title - Graph title
 * @param {*} values - Values to render. Format: [{value, label}]
 * @param {*} [graphColors] - Optional. Format: [from, to, bg]. If not set, use theme.chart.radial
 * @param {number} [width] - Optional. Width of component. Default: 300
 * @param {number} [lineWidth] - Optional. Width of each circumference. Default: 10
 * @param {number} [gap] - Optional. Distance between circumferences. Default: 22
 * @param {number} [fontSize] - Optional. Font size of the legend. Default: 16
 * @param {boolean} [withLegend] - Optional. Whether to show the legend or not. Default: true
 * @param {number} [chartGraphRatio] - Optional. Number between 0 and 1.
 * Ratio between the outer component (chart) and the graph.
 * It defines the size of the graph and the position of the legend. Default: 0.6
 * @param {number} [graphLegendRatio] - Optional. Number between 0 and 1.
 * Ratio between the graph and legend sizes.
 * It defines the size of the legend with regards the graph. Default: 0.7
 * @returns
 */
const GenderScoreRadialBlock = ({
	title,
	values,
	width = 300,
	lineWidth = 10,
	gap = 25,
	fontSize = 16,
	chartGraphRatio = 0.66,
	graphLegendRatio = 0.7,
	withLegend = true,
	withBgPie = true,
	legendMargin,
	graphColors,
}) => {
	const className = 'GenderScoreRadialBlock';

	const theme = useTheme();
	let content;
	let justifyContent = 'center';
	if (!values?.length) {
		content = <>
			<GenderScoreNotProvidedBlock width={width} forceHeight={270}/>
		</>;
		justifyContent = 'left';
	} else {
		content = <>
			<RadialGraph
				values={values}
				width={width * chartGraphRatio}
				fontSize={fontSize}
				withLegend={withLegend}
				withBgPie={withBgPie}
				lineWidth={lineWidth}
				gap={gap}
				legendMargin={legendMargin || `${chartGraphRatio * 100}%`}
				graphLegendRatio={graphLegendRatio}
				graphColors={graphColors}
				style={{ margin: '5px  15px' }}
			/>
		</>;
	}
	return <Space
		direction='vertical'
		style={{
			width: '100%',
		}}>
		<GenderScoreTitleBlock
			title={title}
			isMainTitle={false}
		/>
		<div
			style={{
				display: 'grid',
				justifyContent,
			}}
		>
			{content}
		</div>
	</Space>;
};
export default GenderScoreRadialBlock;
