import { useTheme } from '@emotion/react';
import { capitalize, openExternalUrl } from '../../../utils/common';
import { FaExternalLinkAlt } from 'react-icons/fa';
import { Button, Space } from 'antd';
import AtsLogo from '../../ats/AtsLogo';

const SourcingATSOpenLargeButton = (
	{
		sourcing,
		...props
	},
) => {
	const theme = useTheme();

	const opportunityId = sourcing?.atsSync?.push?.atsId?.opportunityId;
	const atsProvider = sourcing?.atsSync?.atsProvider;

	if (!opportunityId) {
		return null;
	}

	const url = sourcing?.atsSync?.push?.openAtsUrl;
	return (
		<Button
			onClick={() => { openExternalUrl(url); }}
			style={{
				height: 'auto',
				width: '100%',
				textAlign: 'left',
				justifyContent: 'start',
				borderRadius: 4,
				margin: '10px 0',
				padding: '20px 24px',
				backgroundColor: theme.color.black,
				...props?.style,
			}}
		>
			<Space direction='horizontal' style={{ width: '100%', justifyContent: 'space-between' }}>
				<Space direction='horizontal' style={{ width: '100%' }}>
					<AtsLogo ats={atsProvider} size={48} />
					<Space direction='vertical' size={0} style={{ display: 'inline-block' }}>
						<div style={{ fontSize: 11, color: '#ccc', marginTop: -2 }}>This opportunity is synchronized with your ATS</div>
						<div style={{ fontSize: 16, fontWeight: 'bold', color: 'white' }}>Manage in {capitalize(sourcing?.atsSync?.atsProvider?.split('_')?.[0])}</div>
					</Space>
				</Space>
				<FaExternalLinkAlt style={{
					marginBottom: -2, fontSize: 24, color: 'white', float: 'right',
				}} />
			</Space>
		</Button>
	);
};

export default SourcingATSOpenLargeButton;
