import { Space } from 'antd';
import { useTheme } from '@emotion/react';
import { FaEdit } from 'react-icons/fa';
import Link from '../app/Link';
import SourcingLinkDropdown from './SourcingLinkDropdown';
import CompanyLogo from '../company/CompanyLogo';
import JobStub from '../jobs/JobStub';
import UserStub from '../user/stub/UserStub';

const SourcingStubLite = (
	{
		sourcing,
		// withExperience = true,
		// withHardSkill = true,
		withUserPreview = true,
		withUserPreviewButtons = true,
		withProfession = true,
		withLocation = true,
		withDropdown = true,
		withJobEdit = true,
		direction = 'vertical',
		avatarSize = 0,
		activeTab = null,
		onSelectJob,
		...props
	},
) => {
	const theme = useTheme();
	const { user, job } = sourcing || {};

	const component = <Space direction='vertical' size={2}>
		{user
			&& <Space direction='horizontal' size={12} {...props}>
				<UserStub
					user={user}
					avatarSize={0}
					withPreview={withUserPreview}
					withPreviewButtons={withUserPreviewButtons}
					withProfession={false}
					withLocation={false}
					withDropdown={true}
				/>
				{/* <UserAvatar size={avatarSize} user={user} withTooltip={false}/> */}
				{/* <Space direction={direction} size={3}> */}
				{/*    <UserStubName user={user}/> */}
				{/* </Space> */}
			</Space>
		}
		<Space direction='horizontal' size={6} wrap={false} {...props}>
			<Space direction='horizontal' size={6} {...props}>
				<>↳</>
				{job && (
					<Space
						direction='horizontal'
						size={6}
						{...props}
					>
						<CompanyLogo
							size={16}
							company={sourcing?.company || job?.company}
							withTooltip={true}
						/>
						<Space
							direction={direction}
							size={3}
						>
							<JobStub
								job={job}
								withCompany={false}
								withLocation={false}
								withDropdown={true}
								style={{
									title: {
										fontWeight: 400,
										fontSize: 12,
									},
								}}
							/>
						</Space>
					</Space>
				)}
			</Space>
			<>
				{!job && (
					<span
						style={{
							fontSize: 12,
							color: theme.color.grey,
						}}
					>
						<CompanyLogo
							size={16}
							company={sourcing?.company}
							withTooltip={true}
						/>
						&nbsp;No job
					</span>
				)}
				{withJobEdit && (
					<Link
						style={{ lineHeight: 1.2, whiteSpace: 'nowrap' }}
						onClick={(e) => {
							e?.stopPropagation?.();
							onSelectJob(sourcing);
						}}
					>
						<FaEdit style={{ marginBottom: -2 }} />
					</Link>
				)}
			</>
			{/* } */}
		</Space>
	</Space>;

	return component;
};

export default SourcingStubLite;
