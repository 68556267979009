import { useContext } from 'react';
import { useTheme } from '@emotion/react';
import { CompanyPageContext } from './CompanyPageProvider';
import Popin from '../../../app/modal/Popin';
import { Button, Divider, Space } from 'antd';
import { FaBan, FaCheck } from 'react-icons/fa';
import FormBoxTitle from '../../../app/form/FormBoxTitle';
import { ENGLISH_LANG, GENDERSCORE_LABEL } from '../../../../constants/constant';
import CsmSignature from '../../../onboarding/shared/CsmSignature';
import { CompanyContext } from '../../../../contexts/CompanyProvider';
import ModalContainerBase from '../../../../contexts/modals/ModalContainerBase';

const CompanyPageUnpublicationPopin = (
	{
		company,
		...props
	},
) => {
	const className = 'CompanyPageUnpublicationPopin';

	const theme = useTheme();

	const { csmDetails } = useContext(CompanyContext);

	const {
		lang,
		openUnpublicationPopin,
		setOpenUnpublicationPopin,
		unpublishCompanyPage,
	} = useContext(CompanyPageContext);

	const handleConfirm = async () => {
		await unpublishCompanyPage(() => setOpenUnpublicationPopin(false));
	};

	const langVersion = company?.isTranslatable
		? lang === ENGLISH_LANG
			? ' in English and French'
			: ' in French'
		: '';

	return (
		<ModalContainerBase
			open={openUnpublicationPopin}
			width={560}
			onCancel={() => setOpenUnpublicationPopin(false)}
		>
			<FormBoxTitle
				title={<>Unpublish your company page{langVersion}</>}
			/>

			<p>Your company's presence, mission, and {GENDERSCORE_LABEL} will be hidden from potential talent.</p>

			<p>Please consider the consequences carefully before proceeding.</p>

			<CsmSignature data={csmDetails} />

			<Divider style={{ marginTop: 0 }} />

			<Space
				wrap={false}
				style={{
					width: '100%',
					justifyContent: 'space-between',
				}}
			>
				<Button
					size='large'
					onClick={() => setOpenUnpublicationPopin(false)}
				>
					<FaBan style={{ marginBottom: -2, marginRight: 12 }} />
					<b>No, keep published</b>
				</Button>

				<Button
					type='primary'
					size='large'
					onClick={handleConfirm}
				>
					<FaCheck style={{ marginBottom: -2, marginRight: 12 }} />
					<b>Yes, unpublish</b>
				</Button>
			</Space>
		</ModalContainerBase>
	);
};

export default CompanyPageUnpublicationPopin;
