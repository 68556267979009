import { Space } from 'antd';
import { useTheme } from '@emotion/react';

const GridDataComplete = (
	{
		...props
	},
) => {
	const className = 'GridDataComplete';

	const theme = useTheme();

	return (<Space
		direction='vertical'
		align={'center'}
		style={{
			width: '100%',
			height: 60,
			justifyContent: 'center',
			textAlign: 'center',
			fontSize: 12,
			color: theme.color.grey,
			fontStyle: 'italic',
			...props?.style,
		}}
	>
			You reached the end of this list!
	</Space>
	);
};

export default GridDataComplete;
