import { useTheme } from '@emotion/react';
import { useContext } from 'react';
import { Button, Popover, Tooltip } from 'antd';
import { FaBan, FaUpload } from 'react-icons/fa';
import { CompanyPageContext } from './CompanyPageProvider';
import CompanyPageUnpublishBtnPopoverContent from './CompanyPageUnpublishBtnPopoverContent';
import { ENGLISH_LANG, FRENCH_LANG } from '../../../../constants/constant';
import { StopOutlined } from '@ant-design/icons';

const CompanyPageUnpublishButton = (
	{
		company,
		title,
		disabled,
		innerRef,
		...props
	},
) => {
	const className = 'CompanyPageUnpublishButton';

	const theme = useTheme();

	const {
		lang,
		langTexts,
		setOpenUnpublicationPopin,
	} = useContext(CompanyPageContext);

	let btn = (
		<Button
			type='primary'
			onClick={() => setOpenUnpublicationPopin(true)}
			disabled={disabled}
			ref={innerRef}
			{...props}
			style={{
				borderRadius: 5,
				minWidth: 200,
				...props.style,
			}}
		>
			<b>{title || <>Unpublish{langTexts.labelIn}</>}</b>&nbsp;<StopOutlined style={{ fontSize: 12 }} />
		</Button>
	);

	if (disabled) {
		btn = (
			<Popover
				content={<CompanyPageUnpublishBtnPopoverContent company={company} />}
				placement="bottomRight"
			>
				{btn}
			</Popover>
		);
	}

	return btn;
};

export default CompanyPageUnpublishButton;
