import { Space } from 'antd';
import { useTheme } from '@emotion/react';
import CompanyLogo from './CompanyLogo';

const CompanyCaption = (
	{
		company,
		size = 12,
		...props
	},
) => {
	const theme = useTheme();

	if (!company) {
		return null;
	}

	return (
		<Space
			direction='horizontal'
			size={3}
			align="center"
			style={{
				lineHeight: 1.2,
				...props?.style,
			}}
		>
			<CompanyLogo
				size={size}
				company={company}
				withTooltip={false}
			/>
			<span style={{
				lineHeight: 1.2,
				fontSize: 10,
				fontWeight: 700,
				color: theme.color.blue,
				opacity: 0.7,
			}}>
				{company?.name}
			</span>
		</Space>
	);
};

export default CompanyCaption;
