import {
	Divider, Popover, Space,
} from 'antd';
import BlockStaff from '../../app/blocker/BlockStaff';
import CompanySettingsCard, { CompanySettingsCardDotOk, CompanySettingsCardLineOk } from './CompanySettingsCard';
import { useTheme } from '@emotion/react';
import CompanyModel, { UMS_PASSING_DISALLOWED_REASONS } from '../../../models/CompanyModel';
import {
	UMS_EMPTY_ICON_IMG, UMS_LABEL, UMS_LABEL_SHORT, NOT_PROVIDED_YET_LABEL,
} from '../../../constants/constant';
import CompanySettingsUMSForm from './CompanySettingsUMSForm';
import { getUMSSurveyShortLabel } from '../../../constants/property/ums-survey';
import Link from '../../app/Link';
import { useAuthContext } from '../../../hooks/useAuthContext';
import { mixColors } from '../../../utils/common';
import moment from 'moment';

export const UMSStatusDot = ({
	company,
	size = 32,
	...props
}) => {
	const theme = useTheme();
	const authProps = useAuthContext();
	const companyModel = new CompanyModel(company);
	const isPassingAllowed = companyModel?.isUMSPassingAllowed(authProps);

	const popoverTitle = `Is ${UMS_LABEL_SHORT} passing allowed?`;
	let popoverContent = <>Allowed</>;

	if (!isPassingAllowed) {
		popoverContent = (
			<>
				Passing is not allowed.
				<br/>Blocking reasons:
				<ul style={{ paddingLeft: 16 }}>
					{companyModel
						?.getUMSPassingDisallowedReasons()
						?.filter((reason) => (
							![
								UMS_PASSING_DISALLOWED_REASONS.USER_ROLE,
								UMS_PASSING_DISALLOWED_REASONS.USER_NOT_OWNER,
							].includes(reason)
						))
						?.map((reason, index) => (
							<li
								key={index}
							>
								{reason}
							</li>
						))
					}
				</ul>

			</>
		);
	}
	return (
		<Popover
			title={popoverTitle}
			content={popoverContent}
		>
			<CompanySettingsCardDotOk
				ok={isPassingAllowed}
				size={size}
				style={{
					background: isPassingAllowed
						? mixColors(theme.color.darkturquoise, theme.color.white, 80)
						: mixColors(theme.color.red, theme.color.white, 80),
					borderRadius: size,
					width: size,
					height: size,
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
					...props?.style,
				}}
			/>
		</Popover>
	);
};

export const UMSSurveyStatusLine = ({
	company,
	withTooltip = false,
	...props
}) => {
	const theme = useTheme();
	const authProps = useAuthContext();
	const companyModel = new CompanyModel(company);
	const isPassingAllowed = companyModel?.isUMSPassingAllowed(authProps);

	return (
		<CompanySettingsCardLineOk
			ok={isPassingAllowed}
			style={{
				background: isPassingAllowed
					? mixColors(theme.color.darkturquoise, theme.color.white, 80)
					: mixColors(theme.color.red, theme.color.white, 80),
				borderRadius: 8,
				fontWeight: 'bold',
				padding: '4px 12px',
				...props?.style,
			}}
			okText={<>{UMS_LABEL_SHORT} passing is allowed</>}
			koText={(
				<>
					{UMS_LABEL_SHORT} passing is not allowed,&nbsp;
					<Popover
						title='Blocking reasons'
						content={
							<ul>
								{companyModel
									?.getUMSPassingDisallowedReasons()
									?.filter((reason) => (
										![
											UMS_PASSING_DISALLOWED_REASONS.USER_ROLE,
										].includes(reason)
									))
									?.map((reason, index) => (
										<li
											key={index}
										>
											{reason}
										</li>
									))
								}
							</ul>
						}>
						<Link>see why</Link>
					</Popover>
				</>
			)}
		/>
	);
};

const CompanySettingsUMSCardDivider = ({
	title,
}) => {
	return (
		<Divider
			style={{
				marginBottom: 10,
				marginTop: 10,
			}}
			orientation='left'
		>
			<span style={{
				fontSize: 14,
				fontWeight: 600,
			}}>
				{title}
			</span>
		</Divider>
	);
};

const CompanySettingsUMSCard = (
	{
		company,
		...props
	},
) => {
	const className = 'CompanySettingsUMSCard';
	const theme = useTheme();

	const authProps = useAuthContext();

	const companyModel = new CompanyModel(company);

	return (
		<div style={{ position: 'relative' }}>
			<BlockStaff withBorder={false} tagStyle={{ top: 2, left: 24, opacity: 1 }}>
				<CompanySettingsCard
					title={(
						<div style={{ display: 'flex', alignItems: 'center' }}>
							{UMS_EMPTY_ICON_IMG()}&nbsp;{UMS_LABEL}
						</div>
					)}
					details={<>
						{UMS_LABEL} settings for this company
					</>}
					company={company}
					CompanySettingsForm={CompanySettingsUMSForm}
					modalWidth={600}
				>
					<Space direction='vertical'>

						<CompanySettingsCardLineOk
							ok={companyModel?.isUMSEnabled()}
							okText={<>{UMS_LABEL_SHORT} is enabled for <b>{company?.name}</b></>}
							koText={<>{UMS_LABEL_SHORT} is disabled for <b>{company?.name}</b></>}
						/>

						{companyModel?.isUMSEnabled() && (
							<>
								<CompanySettingsCardLineOk
									ok={companyModel?.getUMSPurchasedDate()}
									label={UMS_LABEL_SHORT}
									okText={<> purchased on <b>{moment(companyModel?.getUMSPurchasedDate()).format('DD/MM/YYYY')}</b></>}
									koText={<span style={{
										fontWeight: 'bold',
										color: theme.color.red,
									}}> purchase date not set</span>}
								/>
								<CompanySettingsUMSCardDivider title='Survey'/>
								<CompanySettingsCardLineOk
									ok={companyModel?.isUMSSurveyEnabled()}
									okText={<><b>{company?.name} has access </b> to the {UMS_LABEL_SHORT} surveys</>}
									koText={<><b>{company?.name} doesn't have access </b> to the {UMS_LABEL_SHORT} Surveys</>}
								/>
								<CompanySettingsCardLineOk
									ok={companyModel?.getCompanyPlanUMSSurveyManager()}
									label={<b>{UMS_LABEL_SHORT} Survey for managers:&nbsp;</b>}
									okText={getUMSSurveyShortLabel(
										companyModel?.getCompanyPlanUMSSurveyManager(),
									)}
									koText={NOT_PROVIDED_YET_LABEL}
								/>
								<CompanySettingsCardLineOk
									ok={companyModel?.getCompanyPlanUMSSurveyEmployee()}
									label={<b>{UMS_LABEL_SHORT} Survey for employees:&nbsp;</b>}
									okText={getUMSSurveyShortLabel(
										companyModel?.getCompanyPlanUMSSurveyEmployee(),
									)}
									koText={NOT_PROVIDED_YET_LABEL}
								/>

								<UMSSurveyStatusLine
									company={company}
									style={{ marginTop: 15, marginBottom: 10 }}
								/>
								{/* <CompanySettingsUMSCardDivider title='Results'/> */}
							</>
						)}
					</Space>

				</CompanySettingsCard>

			</BlockStaff>
		</div>
	);
};

export default CompanySettingsUMSCard;
