import { Menu } from 'antd';
import { useContext } from 'react';
import { FaCheck } from 'react-icons/fa';
import { useLocation, useNavigate } from 'react-router-dom';
import { PATHS, TAG_STAFF_ICON } from '../../../constants/constant';
import { GENDERSCORE_PILLAR_OPTIONS_LIST, getPillarIcon } from '../../../constants/genderscore-pillars';
import GenderScorePillarLogoLabel from '../../company/genderscore/pillars/GenderScorePillarLogoLabel';
import { useTheme } from '@emotion/react';
import CompanyGenderScoreRingScore from '../../company/genderscore/CompanyGenderScoreRingScore';
import { mixColors } from '../../../utils/common';
import { SurveyContext } from './SurveyProvider';
import {
	buildSectionQuestionPath, getSurveySectionsQuestionsFromAnswers, isQuestionAnswered,
} from '../data/shared';
import { useAuthContext } from '../../../hooks/useAuthContext';
import config from '../../../config/config';
import { PlusCircleOutlined } from '@ant-design/icons';

const SurveyMenu = ({
	baseRoutePath = PATHS.GENDERSCORE_SURVEY(),
	withProgress = false,
}) => {
	const theme = useTheme();
	const navigate = useNavigate();
	const location = useLocation();

	const { isStaff } = useAuthContext();
	const { answers, surveyConf } = useContext(SurveyContext);

	const handleClick = (e) => {
		navigate(e.key);
	};

	const statusIconProps = {
		fontSize: 12,
		borderRadius: 24,
		width: 24,
		height: 24,
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'center',
		alignItems: 'center',
	};

	const pctIcon = (value) => (
		<span
			style={{
				...statusIconProps,
				borderRadius: 4,
				background: '#222',
				fontSize: 10,
				width: 48,
				color: 'white',
			}}
		>
			<b>{value}</b>%
		</span>
	);

	const answeredIcon = (
		<span
			style={{
				...statusIconProps,
				border: '1px solid darkturquoise',
				background: 'darkturquoise',
			}}
		>
			<FaCheck color='white' />
		</span>
	);

	const unansweredIcon = (
		<span
			style={{
				...statusIconProps,
				fontSize: 22,
				color: '#aaa',
			}}
		>
			<PlusCircleOutlined />
		</span>
	);

	const menuItems = [
		...getSurveySectionsQuestionsFromAnswers({ surveyConf, answers })?.map?.((section) => {
			const pillar = GENDERSCORE_PILLAR_OPTIONS_LIST
				.find((o) => (
					o?.label?.toLocaleLowerCase?.()
						=== section?.label?.toLocaleLowerCase?.()))
				?.value;

			const sSlug = section.slug;

			const percentageCompleted = Math.round(
				100 * (section?.questions
					?.filter?.((q) => answers?.[q.questionId] !== undefined)?.length || 0)
				/ (section?.questions?.length || 1),
			);

			return {
				key: sSlug,
				icon: withProgress
					? (
						<CompanyGenderScoreRingScore
							value={Math.max(0.001, percentageCompleted)}
							ringColor={percentageCompleted === 0
								? 'transparent'
								: mixColors('#ff0000', theme.color.darkturquoise, percentageCompleted)}
							size={38}
							girthRatio={percentageCompleted === 100 ? 1 : 0.1}
							forceScore={<>{percentageCompleted}<span style={{ fontSize: 12 }}>%</span></>}
							style={{
								score: {
									fontSize: 12,
									...percentageCompleted === 100 ? { color: 'white' } : { color: theme.color.darkgrey },
								},
								marginLeft: -12,
								marginRight: 8,
							}}
						/>
					)
					: (
						getPillarIcon(pillar, {
							width: 18,
							height: 18,
							style: {
								// marginBottom: -6,
							},
						})
					),
				label: (
					<div
						style={{
							// marginBottom: -6,
						}}
					>
						<GenderScorePillarLogoLabel
							pillar={pillar}
							withLogo={false}
							iconSize={18}
							labelSize={14}
							style={{
								icon: {
								// marginBottom: -2,
								},
								label: {
									fontWeight: 500,
									color: 'rgba(0, 0, 0, 0.88)',
								},
							}}
						/>
					</div>
				),
				children: [
					{
						key: buildSectionQuestionPath({
							baseRoutePath,
							section,
						}),
						label: 'Pillar Presentation',
					},
					...section.questions
						.map((q) => {
							const key = buildSectionQuestionPath({
								baseRoutePath,
								section,
								question: q,
							});
							const label = q.shortLabel || q.label;
							return {
								key,
								icon: isQuestionAnswered({ question: q, answers })
									? answeredIcon
									: unansweredIcon,
								label,
							};
						}),
				],
			};
		}) || [],
		...(isStaff || config.isDevEnv)
			? [
				{
					key: `${baseRoutePath}/review`,
					label: <>Review answers {TAG_STAFF_ICON}</>,
				},
				{
					key: `${baseRoutePath}/scoring`,
					label: <>Formula Details {TAG_STAFF_ICON}</>,
				},
			]
			: [],
	];

	return (
		<>
			<Menu
				onClick={handleClick}
				mode="inline"
				className="sidebar"
				selectedKeys={location.pathname}
				items={menuItems}
				style={{
					background: 'transparent',
					marginTop: 0,
					paddingBottom: 60,
					paddingTop: 10,
				}}
			/>
		</>
	);
};

export default SurveyMenu;
