import UserModel from '../../../../../models/UserModel';
import UserGridCardLabelValue from './UserGridCardLabelValue';
import UserGridCardValueList from './UserGridCardValueList';

const UserGridCardJobSearchJobLocation = (
	{
		user,
		label = <>Desired work locations</>,
		placement = 'right',
		defaultContainer = 'div',
		withSeparator = false,
		itemStyle,
		...props
	},
) => {
	const className = 'UserGridCardJobSearchJobLocation';

	const userModel = new UserModel(user);

	if (!userModel?.hasJobSearchJobLocations()) return null;

	const items = userModel
		?.getJobSearchJobLocations({
			sorted: true,
			withCountryCode: false,
			withCountryFlag: true,
			withCityCountry: true,
			withCityCountryCode: true,
			withCityCountryFlag: true,
		});

	return (
		<UserGridCardLabelValue
			label={label}
			style={{ ...props?.style }}
		>
			<UserGridCardValueList
				items={items}
				placement={placement}
				defaultContainer={defaultContainer}
				withSeparator={withSeparator}
				itemStyle={itemStyle}
			/>
		</UserGridCardLabelValue>
	);
};

export default UserGridCardJobSearchJobLocation;
