import { COACHING_USERS_LOADED } from './types';
import CoachingService from '../services/coaching';

export const listCoaches = () => {
	return async (dispatch) => {
		const { data } = await CoachingService.getCoaches();
		if (data.items) {
			dispatch({
				type: COACHING_USERS_LOADED,
				coaches: data.items,
			});
		}
	};
};
export const sendEmailCoaching = async (userId, data) => {
	const result = await CoachingService.sendUserContactEmail(userId, data);
	return result;
};
