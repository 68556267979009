import FormSelect from './FormSelect';
import { Select } from 'antd';
import AtsLogo from '../../ats/AtsLogo';

const FormAtsSelect = (
	{
		showSearch = true,
		onChange,
		defaultValue,
		placeholder = 'Select an ATS',
		styles = {},
		isSorted = false,
		mode = 'single',
		...props
	},
) => {
	const handleChange = (value, option) => {
		if (onChange) onChange(value, option);
	};
	return (
		<FormSelect
			showSearch={showSearch}
			optionFilterProp="children"
			placeholder={placeholder || (mode === 'multiple'
				? 'Select one or more ATS'
				: 'Select an ATS')
			}
			onChange={handleChange}
			defaultValue={defaultValue}
			styles={{ minWidth: 180, ...styles }}
			mode={mode}
			{...(isSorted
				? {
					filterSort: (a, b) => {
						return (a.label || '').toLowerCase().localeCompare((b.label || '').toLowerCase());
					},
				}
				: {}
			)}
			render={(option, key) => (
				<Select.Option value={option.value} label={option.label} key={key}>
					{/* <div className="demo-option-label-item"> */}
					<AtsLogo ats={option.value} size={20} /> <b>{option.label}</b>
					{/* </div> */}
				</Select.Option>
			)}
			{...props}
		/>
	);
};

export default FormAtsSelect;
