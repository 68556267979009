import { Card, Popover, Space } from 'antd';
import { FaQuestionCircle } from 'react-icons/fa';
import { useTheme } from '@emotion/react';

const ValueCard = (
	{
		title,
		details,
		children,
		...props
	},
) => {
	const theme = useTheme();

	return !!children && <Card
		title={<Space wrap={false} style={{ width: '100%', justifyContent: 'start' }}>
			<span style={{ whiteSpace: 'normal', fontSize: 24, color: theme.color.darkgrey }}>{title}</span>
			{details && <Popover placement="leftTop" content={details}>
				<FaQuestionCircle style={{ color: '#ccc', fontSize: 14 }}/>
			</Popover>}
		</Space>}
		// bordered={true}
		// style={{borderRadius: 10, margin: '0 10px', boxShadow: '0 6px 12px -3px rgba(0,0,0,.2)', ...(props?.cardStyle || {})}}
		bordered={false}
		style={{
			borderRadius: 0, margin: '0', boxShadow: 'none', ...(props?.cardStyle || {}),
		}}
		{...props}
	>
		{children}
	</Card>;
};

export default ValueCard;
