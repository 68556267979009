import {
	arrayToMap,
	arrayWrap, objectEntriesMapper, pct, round, sumReducer,
} from '../../../utils/common';
import QUESTIONS from './questions';
import CONSTANTS from './constants';
import { isArray, isFunction } from 'lodash';
import { LocalDebug } from '../../../utils/LocalDebug';

const className = 'genderscore/data/scoring';

const computeValueFunc = ({
	value,
	ranges = 100,
	defaultValue,
}) => {
	const score = (value || defaultValue || 0) * (ranges || 100);
	return score;
};

const computePctFunc = ({
	value,
	ranges,
	defaultValue,
}) => {
	const convertedValue = value >= 0 ? Number(value) : 0;
	const [[vMin]] = ranges;
	const [[vMax]] = ranges.slice().reverse();
	const scores = ranges.map((r) => r[1]);
	const sMin = scores.reduce((p, c) => Math.min(p, c), 0);
	const sMax = scores.reduce((p, c) => Math.max(p, c), 0);
	const valueRanger = convertedValue < vMin ? vMin : convertedValue > vMax ? vMax : convertedValue;
	const safeValue = valueRanger >= vMin && valueRanger <= vMax ? valueRanger : defaultValue || vMin;
	let score = null;
	ranges.forEach(([v, m], i, a) => {
		if (score !== null) return;
		if ((i + 1) < a.length) {
			const [vNext, mNext] = a[i + 1];
			if ((i + 2) < a.length) {
				if (safeValue <= vNext) {
					score = m + (mNext - m) * (safeValue - v) / (vNext - v);
				}
			} else {
				score = m + (mNext - m) * (safeValue - v) / (vNext - v);
			}
		}
	});
	if (sMax !== undefined) score = Math.min(sMax, score);
	if (sMin !== undefined) score = Math.max(sMin, score);
	return score;
};

const COMPUTE_PCT = {
	id: 'computePct',
	computer: computePctFunc,
};
const COMPUTE_VALUE = {
	id: 'computeValue',
	computer: computeValueFunc,
};

const buildScoringPct = ({ ranges, defaultScore = 0 } = {}) => ({
	computer: COMPUTE_PCT,
	ranges,
	defaultScore,
});

const buildScoringValue = ({ ranges, defaultScore = 0 } = {}) => ({
	computer: COMPUTE_VALUE,
	ranges,
	defaultScore,
});

const questionAnswersBuilder = (answers) => (
	Object.fromEntries(
		arrayToMap(
			answers.map((answer) => ([null, undefined].includes(answer?.value)
				? answer.value
				: (arrayWrap(answer.value) || [])
					.map((value) => {
						const options = QUESTIONS?.[answer?.questionId]?.options;
						if (options) {
							const option = options?.find((o) => o.value === value);
							const score = option?.score || option?.value || 0;
							return Number.isNaN(score) ? 0 : score;
						}
						return value;
					}))),
			(item) => item?.questionId,
		),
	)
);

const computeQuestionScore = ({
	question,
	computer,
	ranges,
	answers,
	defaultScore,
}) => {
	const { questionId } = question;
	let value = answers?.[questionId];

	if (!answers) return 0;

	if (CONSTANTS.ANSWER_VALUE_SKIPPED === value) {
		return defaultScore || 0;
	}

	if ([null, undefined, CONSTANTS.ANSWER_VALUE_LATER].includes(value)) {
		return 0;
	}

	value = (arrayWrap(value) || [])
		.map((item) => {
			const options = question?.options;
			if (options) {
				const option = options?.find((o) => o.value === item);
				const score = option?.score || option?.value || 0;
				return Number.isNaN(score) ? 0 : score;
			}
			return item;
		})
		.reduce(sumReducer, 0);

	const score = computer.computer({
		value,
		ranges,
	});

	if (Number.isNaN(score)) {
		LocalDebug.logInfo({ className, method: 'computeQuestionScore' }, questionId, 'computed => score:', score, value, computer);
		return 0;
		throw new Error('question score is NaN');
	}
	return Math.min(100, Math.max(0, score));
};

const computeScore = ({ scoring, answers }) => {
	const answersObj = isArray(answers)
		? questionAnswersBuilder(answers)
		: answers;

	const result = scoring
		.map(({
			question,
			weight,
			computer,
			ranges,
			clause,
		}) => {
			const { questionId: qId } = question;
			const value = answersObj[qId];

			const base = {
				value,
				computer,
				weight,
				qId,
				clause,
			};

			if (
				[null, undefined, CONSTANTS.ANSWER_VALUE_LATER, CONSTANTS.ANSWER_VALUE_SKIPPED].includes(value)
				|| clause?.(value, answersObj) === false
			) {
				// LocalDebug.logInfo({ className, method: 'computeScore' }, qId, 'null/skipped/clause => score:', 0);
				return {
					...base,
					score: 0,
				};
			}
			return {
				...base,
				score: computeQuestionScore({
					question,
					answers: answersObj,
					computer,
					ranges,
				}),
			};
		})
		.map((s, i, a) => ({
			...s,
			total: a
				.map((item) => item.weight
						* (item?.clause?.(answersObj[item.qId], answersObj) === false ? 0 : 1))
				.reduce(sumReducer, 0),
		}))
		.reduce((prev, cur) => ({
			score: prev.score + cur.score * cur.weight,
			details: [...prev.details, ({
				id: cur.id,
				qId: cur.qId,
				score: cur.score,
				impact: pct(
					cur.weight
					* (cur?.clause?.(answersObj[cur.qId], answersObj) === false ? 0 : 1),
					cur.total,
					0,
				),
				answer: cur.value,
				verbose: (() => {
					const qPrefix = `For ${cur.id} (${cur.qId})`;
					const qAnswer = `Client answer: ${cur.value}`;
					const qScore = `Question score: ${round(cur.score, 0)}%`;
					const qWeight = (cur.weight * (cur?.clause?.(answersObj[cur.qId], answersObj) === false ? 0 : 1)) > 0 ? `Weight in section score: ${pct(cur.weight, cur.total, 0)}%` : '';
					return [qPrefix, qAnswer, qScore, qWeight].join(' ');
				})(),
			})],
			total: (
				prev.total
				+ (100
					* cur.weight
					* (cur?.clause?.(answersObj[cur.qId], answersObj) === false ? 0 : 1)
				)
			),
		}), {
			score: 0,
			details: [],
			total: 0,
		});

	return result;
};

const computeSectionWeightedScore = ({ questionScores, weighings, answers }) => {
	const weighingsWithClauses = objectEntriesMapper({
		target: weighings,
		valueMapper: ({ value: weighing }) => (isFunction(weighing)
			? weighing(answers)
			: weighing),
	});
	const total = Object.values(weighingsWithClauses).reduce(sumReducer, 0);
	const score = Object
		.entries(weighingsWithClauses)
		.map(([questionId, weighing]) => {
			const questionScore = questionScores[questionId];
			const weightedScore = weighing * questionScore;
			if (!questionScore && questionScore !== 0) {
				console.log('computeSectionWeightedScore', 'Undefined score for', questionId);
			}
			if (!weightedScore && weightedScore !== 0) {
				console.log('computeSectionWeightedScore', 'Undefined weightedScore for', questionId);
			}
			return [questionId, weighing];
		})
		.map(([questionId, weighing]) => weighing * questionScores[questionId])
		.reduce(sumReducer, 0);
	// console.log('computeSectionWeightedScore', { weighingsWithClauses, total, score });
	return score / total;
};

const computeSurveyQuestionScores = ({ surveyConf, answers, origin }) => {
	const scores = objectEntriesMapper({
		target: surveyConf?.scoring,
		valueMapper: ({
			key: questionId,
			value: { computer, ranges, defaultScore },
		}) => computeQuestionScore({
			question: QUESTIONS[questionId],
			computer,
			ranges,
			answers,
			defaultScore,
		}),
	});
	LocalDebug.logInfo({ className, method: 'computeSurveyQuestionScores' }, {
		origin, surveyConf, answers, scores,
	});
	return scores;
};

const computeSurveyScores = ({ surveyConf, answers, origin }) => {
	if (!surveyConf) return {};
	if (!answers) return {};

	const logger = (...args) => LocalDebug.logNull({ className, method: 'computeSurveyScores' }, ...args);
	logger({ surveyConf, answers });

	const questionScores = computeSurveyQuestionScores({ surveyConf, answers, origin: origin || 'computeSurveyScores' });

	const sectionScores = surveyConf?.sections
		?.map((section) => {
			logger({ section, answers });
			return computeSectionWeightedScore({
				weighings: section?.weighings,
				answers,
				questionScores,
			});
		});

	logger({ sectionScores });

	const globalScore = sectionScores
		.reduce(sumReducer, 0) / sectionScores.length;

	logger({ globalScore });

	const computedScores = {
		globalScore: Math.round(globalScore),
		...Object.fromEntries(
			sectionScores
				.map((score, i) => [
					surveyConf?.sections?.[i]?.value,
					Math.round(score),
				]),
		),
	};

	logger({ computedScores });

	return computedScores;
};

const exporter = {
	COMPUTE_PCT,
	COMPUTE_VALUE,
	buildScoringPct,
	buildScoringValue,
	questionAnswersBuilder,
	computeScore,
	computeQuestionScore,
	computeSectionWeightedScore,
	computeSurveyScores,
	computeSurveyQuestionScores,
};

export default exporter;
