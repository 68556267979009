import { GENDERSCORE_PILLAR_VALUES } from '../../../../constants/genderscore-pillars';
import GenderScoreLayoutGrid from '../layout/GenderScoreLayoutGrid';
import GenderScorePillarScoreBox from '../pillars/GenderScorePillarScoreBox';

const GenderScorePillarBreakdownSection = ({
	company,
	forceValue,
}) => {
	const className = 'GenderScorePillarBreakdownSection';

	const genderScore = forceValue || company?.genderScore;

	return (
		<GenderScoreLayoutGrid
			columns={'1fr 1fr 1fr 1fr 1fr'}
		>
			{GENDERSCORE_PILLAR_VALUES
				.map((pillar, index) => (
					<GenderScorePillarScoreBox
						company={company}
						pillar={pillar}
						index={index}
						key={index}
						withLink={true}
					/>
				))}
		</GenderScoreLayoutGrid>
	);
};

export default GenderScorePillarBreakdownSection;
