import { useTheme } from '@emotion/react';
import { useState } from 'react';
import useBodyScrollBlocker from '../../../hooks/useBodyScrollBlocker';
import Link from '../../app/Link';
import Card from '../../app/card/Card';
import { Space } from 'antd';
import { mixColors } from '../../../utils/common';
import ModalContainerBase from '../../../contexts/modals/ModalContainerBase';
import { FaBan, FaCheck } from 'react-icons/fa';

export const CompanySettingsCardDotOk = ({
	ok = true,
	size = 32,
	...props
}) => {
	const theme = useTheme();
	const icon = ok
		? <FaCheck style={{ marginBottom: -2, color: theme.color.darkturquoise, ...props?.style?.icon }}/>
		: <FaBan style={{ marginBottom: -2, color: theme.color.red, ...props?.style?.icon }} />;

	return (
		<Space
			direction='horizontal'
			{...props}
			style={{
				justifyContent: 'center',
				alignItems: 'center',
				...props?.style,
			}}
		>
			{icon}
		</Space>
	);
};

export const CompanySettingsCardLineOk = ({
	ok = true,
	label,
	okText = 'ok',
	koText = 'ko',
	...props
}) => {
	const theme = useTheme();
	const icon = ok
		? <FaCheck style={{ marginBottom: -2, color: theme.color.darkturquoise }}/>
		: <FaBan style={{ marginBottom: -2, color: theme.color.red }} />;

	const text = ok ? okText : koText;
	return (
		<Space
			direction='horizontal'
			align='top'
			{...props}
		>
			{icon}
			{text && (
				<div
					style={{
						verticalAlign: 'top',
						...props?.style?.label,
					}}
				>
					{label && (
						<span
							style={{
								verticalAlign: 'top',
							}}
						>
							{label}
						</span>
					)}
					{text}
				</div>
			)}
		</Space>
	);
};

const CompanySettingsCard = (
	{
		title,
		details,
		company,
		CompanySettingsForm,
		children,
		...props
	},
) => {
	const className = 'CompanySettingsCard';
	const theme = useTheme();

	const [openForm, setOpenForm] = useState(false);
	useBodyScrollBlocker(openForm, className);

	return (
		<>
			<Card
				style={{
					display: 'inline-block',
					position: 'relative',
					borderRadius: 5,
					border: '1px solid #ddd',
					width: '100%',
				}}
			>
				<Space
					direction='horizontal'
					align='top'
					style={{
						width: '100%',
						justifyContent: 'space-between',
					}}
					wrap={true}
				>
					<h3 style={{ marginTop: 0 }}>
						{title}
					</h3>
					{CompanySettingsForm && (
						<Link
							onClick={() => { setOpenForm(true); }}
							style={{
								background: mixColors(theme.color.white, theme.color.fitBlueElectric, 10),
								padding: '2px 12px',
								borderRadius: 30,
							}}
						>
							Edit
							{/* <FaPen style={{ marginBottom: -2 }}/> */}
						</Link>
					)}
				</Space>
				{details && (
					<div
						style={{
							fontSize: 12,
							color: theme.color.grey,
							marginBottom: 20,
						}}
					>
						{details}
					</div>
				)}
				<div style={{ position: 'relative' }}>
					{children}
				</div>
			</Card>

			<ModalContainerBase
				open={openForm}
				setOpen={setOpenForm}
				width={props?.modalWidth || 600}
			>
				<CompanySettingsForm
					company={company}
					title={title}
					details={details}
					onSuccess={() => { setOpenForm(false); }}
				/>
			</ModalContainerBase>
		</>
	);
};
export default CompanySettingsCard;
