import {
	BIAS_BACKLASH_EFFECT_OPTION,
	BIAS_CONTRAST_EFFECT_OPTION,
	BIAS_HALO_HORNS_EFFECT_OPTION,
	BIAS_IN_GROUP_EFFECT_OPTION,
	BIAS_OVERLY_CRITICAL_OPTION,
	BIAS_RECENCY_BIAS_OPTION,
	BIAS_SELF_FULLFILLING_PROPHECY_OPTION,
} from '../../../data/biases';
import {
	PDF_PAGE_TEMPLATE_CONTENT,
} from '../base/PDFBuilder';

const PageBiasDetails = ({
	doc,
	context,
}) => [
	{
		template: PDF_PAGE_TEMPLATE_CONTENT,
		title: {
			fr: 'Différents biais possibles',
			en: 'Different possible biases',
		},
		footer: true,
		pageCount: true,
		items: [
			BIAS_SELF_FULLFILLING_PROPHECY_OPTION,
			BIAS_BACKLASH_EFFECT_OPTION,
		].map((bias, index, arr) => doc.walkBiasBlock({
			doc, bias, index, arr, context,
		})),
	},
	{
		template: PDF_PAGE_TEMPLATE_CONTENT,
		footer: true,
		pageCount: true,
		items: [
			BIAS_RECENCY_BIAS_OPTION,
			BIAS_HALO_HORNS_EFFECT_OPTION,
			BIAS_IN_GROUP_EFFECT_OPTION,
		].map((bias, index, arr) => doc.walkBiasBlock({
			doc, bias, index, arr, context,
		})),
	},
	{
		template: PDF_PAGE_TEMPLATE_CONTENT,
		footer: true,
		pageCount: true,
		items: [
			BIAS_OVERLY_CRITICAL_OPTION,
			BIAS_CONTRAST_EFFECT_OPTION,
		].map((bias, index, arr) => doc.walkBiasBlock({
			doc, bias, index, arr, context,
		})),
	},
];

export default PageBiasDetails;
