import { useTheme } from '@emotion/react';
import {
	ArrowDownIcon, ArrowRightIcon, ArrowUpIcon, addSignToNum, pct, round,
} from '../../../utils/common';

export const DIFF_DIRECTION_POSITIVE_OPTION = {
	Icon: ArrowUpIcon,
	color: '#5fb583',
};

export const DIFF_DIRECTION_NEGATIVE_OPTION = {
	Icon: ArrowDownIcon,
	color: '#d4380d',
};

export const DIFF_DIRECTION_NONE_OPTION = {
	Icon: ArrowRightIcon,
	color: '#ccc',
};

export const DIFF_DIRECTION_OPTIONS = [
	DIFF_DIRECTION_POSITIVE_OPTION,
	DIFF_DIRECTION_NEGATIVE_OPTION,
	DIFF_DIRECTION_NONE_OPTION,
];

export const getDiffDirectionOption = (diff) => {
	if (!diff) return DIFF_DIRECTION_NONE_OPTION;
	if (diff < 0) return DIFF_DIRECTION_NEGATIVE_OPTION;
	if (diff > 0) return DIFF_DIRECTION_POSITIVE_OPTION;
};

const DiffDisplay = ({
	previous,
	current,
	decimals = 0,
	forceColor,
	label,
	icon,
	isPct = false,
	withSignPrefix = true,
	withArrow = true,
	withBackground = false,
	withCompareLabel = false,
	...props
}) => {
	const className = 'DiffDisplay';

	const theme = useTheme();
	const diff = round((current || 0) - (previous || current || 0), decimals);
	const { Icon, color } = getDiffDirectionOption(diff);
	const DiffIcon = icon || Icon;

	return (
		<div
			style={{
				...withBackground
					? { background: color, color: 'white' }
					: { color: forceColor || color },
				fontWeight: 'bold',
				userSelect: 'none',
				...props?.style,
			}}
		>
			{withSignPrefix ? addSignToNum(diff) : diff}
			{label && <span>{label}</span>}
			<DiffIcon
				style={{
					marginBottom: Math.trunc(props?.style?.fontSize)
						? Math.trunc(props?.style?.fontSize) * -0.12
						: -2,
					marginLeft: '.1em',
					...props?.style?.icon,
				}}
			/>
		</div>
	);
};

export default DiffDisplay;
