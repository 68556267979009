import GridValueCard from '../../../app/card/GridValueCard';
import UserResumeTopSkillsCardBody from './UserResumeTopSkillsCardBody';
import { NOT_PROVIDED_YET_LABEL } from '../../../../constants/constant';

const UserResumeTopSkillsCard = (
	{
		user,
		hideIfEmpty = true,
		notProvided = NOT_PROVIDED_YET_LABEL,
		children,
		...props
	},
) => {
	const title = 'Top Skills';
	const details = null;

	return (user?.hasResumeTopSkills() || !hideIfEmpty
		? <GridValueCard
			title={title}
			details={details}
			{...props}
		>
			{children || <UserResumeTopSkillsCardBody user={user} notProvided={notProvided}/>}
		</GridValueCard>
		: null
	);
};

export default UserResumeTopSkillsCard;
