import QUESTIONS from '../../questions';
import RANGES from '../../ranges';
import SCORING from '../../scoring';
import { GENDERSCORE_SURVEY_50PLUS_V3_OPTION } from '../../../../../constants/property/genderscore-survey';
import sectionGenderData from './section-genderdata';
import sectionCareerPath from './section-careerpath';
import sectionEqualPay from './section-equalpay';
import sectionWorklifeBalance from './section-worklifebalance';
import sectionDeiActions from './section-deiactions';

export const GENDERSCORE_SURVEY_50PLUS_V3_DATA_OPTION = {
	...GENDERSCORE_SURVEY_50PLUS_V3_OPTION || {},
	scoring: {
		[QUESTIONS.V3_GD_WORKFORCE_WOMEN_RATIO.questionId]: SCORING.buildScoringPct({
			ranges: RANGES.GENDERDATA_OVERALLPCT_RANGES,
		}),
		[QUESTIONS.V3_GD_WORKFORCE_WOMEN_BOARD_RATIO.questionId]: SCORING.buildScoringPct({
			ranges: RANGES.GENDERDATA_BOARDPCT_RANGES,
		}),
		[QUESTIONS.V3_GD_WORKFORCE_WOMEN_TOPMGT_RATIO.questionId]: SCORING.buildScoringPct({
			ranges: RANGES.GENDERDATA_MGTPCT_RANGES,
		}),
		[QUESTIONS.V3_GD_WORKFORCE_WOMEN_OTHERMGT_RATIO.questionId]: SCORING.buildScoringPct({
			ranges: RANGES.GENDERDATA_MGTPCT_RANGES,
		}),
		[QUESTIONS.V3_GD_TECH_WOMEN_RATIO.questionId]: SCORING.buildScoringPct({
			ranges: RANGES.GENDERDATA_OVERALLPCT_RANGES,
		}),
		[QUESTIONS.V3_GD_TECH_WOMEN_TOPMGT_RATIO.questionId]: SCORING.buildScoringPct({
			ranges: RANGES.GENDERDATA_MGTPCT_RANGES,
		}),
		[QUESTIONS.V3_GD_TECH_WOMEN_OTHERMGT_RATIO.questionId]: SCORING.buildScoringPct({
			ranges: RANGES.GENDERDATA_MGTPCT_RANGES,
		}),
		// [QUESTIONS.V3_GD_LABELS_GLASSDOOR_PRESENCE.questionId]: SCORING.buildScoringValue(),
		[QUESTIONS.V3_GD_LABELS_GLASSDOOR_SCORE.questionId]: SCORING.buildScoringPct({
			ranges: RANGES.GENDERDATA_RATING_RANGES,
		}),
		[QUESTIONS.V3_GD_LABELS_EGAPRO_MEASUREMENT.questionId]: SCORING.buildScoringValue(),
		[QUESTIONS.V3_GD_LABELS_EGAPRO_SCORE.questionId]: SCORING.buildScoringPct({
			ranges: RANGES.GENDERDATA_EGAPRO_SCORE_RANGES,
		}),
		// [QUESTIONS.V3_GD_RETENTION_MEDIAN_TENURE.questionId]: SCORING.buildScoringValue(),
		// [QUESTIONS.V3_GD_RETENTION_PROMOTION_RATE.questionId]: SCORING.buildScoringValue(),
		[QUESTIONS.V3_GD_RETENTION_WOMEN_PROMOTION_RATE.questionId]: SCORING.buildScoringPct({
			ranges: RANGES.GENDERDATA_WOMEN_PROMOTION_RATE_RANGES,
		}),
		[QUESTIONS.V3_GD_RETENTION_TURNOVER_TRACKING.questionId]: SCORING.buildScoringValue(),
		// [QUESTIONS.V3_GD_RETENTION_TURNOVER_RATE.questionId]: SCORING.buildScoringPct(),
		// [QUESTIONS.V3_GD_RETENTION_WOMEN_TURNOVER_RATE.questionId]: SCORING.buildScoringPct(),
		//
		[QUESTIONS.V3_FCP_PRACTICES_COMPETENCY_MATRIX.questionId]: SCORING.buildScoringValue(),
		[QUESTIONS.V3_FCP_PRACTICES_CAREER_DEVELOPMENT_GRID.questionId]: SCORING.buildScoringValue(),
		[QUESTIONS.V3_FCP_PRACTICES_UNBIASED_LEADERSHIP_ROLES.questionId]: SCORING.buildScoringValue(),
		[QUESTIONS.V3_FCP_PRACTICES_SUCCESSION_PLAN.questionId]: SCORING.buildScoringValue(),
		[QUESTIONS.V3_FCP_PRACTICES_INTERNAL_MOBILITY_PROGRAM.questionId]: SCORING.buildScoringValue(),
		[QUESTIONS.V3_FCP_PRACTICES_MANAGERS_CAREER_PATHING.questionId]: SCORING.buildScoringValue(),
		[QUESTIONS.V3_FCP_PRACTICES_FORMALIZED_FEEDBACK_PROCESS.questionId]: SCORING.buildScoringValue(),
		[QUESTIONS.V3_FCP_PRACTICES_NONBIASED_ANNUAL_REVIEW.questionId]: SCORING.buildScoringValue(),
		[QUESTIONS.V3_FCP_DEVELOPMENT_MENTORSHIP_PROGRAM.questionId]: SCORING.buildScoringValue(),
		[QUESTIONS.V3_FCP_DEVELOPMENT_MENTORSHIP_PROGRAM_SATISFACTION.questionId]: SCORING.buildScoringValue(),
		[QUESTIONS.V3_FCP_DEVELOPMENT_SPONSORSHIP_PROGRAM.questionId]: SCORING.buildScoringValue(),
		[QUESTIONS.V3_FCP_DEVELOPMENT_SPONSORSHIP_PROGRAM_SATISFACTION.questionId]: SCORING.buildScoringValue(),
		[QUESTIONS.V3_FCP_DEVELOPMENT_CAREER_COACHING_PROGRAM.questionId]: SCORING.buildScoringValue(),
		[QUESTIONS.V3_FCP_DEVELOPMENT_CAREER_COACHING_PROGRAM_SATISFACTION.questionId]: SCORING.buildScoringValue(),
		//
		[QUESTIONS.V3_EP_REVIEW_EQUALPAY_REVIEW.questionId]: SCORING.buildScoringValue(),
		[QUESTIONS.V3_EP_REVIEW_ADJUSTED_PAYGAP.questionId]: SCORING.buildScoringPct({
			ranges: RANGES.ADJUSTED_PAY_GAP_RANGES,
		}),
		[QUESTIONS.V3_EP_REVIEW_UNADJUSTED_PAYGAP.questionId]: SCORING.buildScoringPct({
			ranges: RANGES.UNADJUSTED_PAY_GAP_RANGES,
		}),
		[QUESTIONS.V3_EP_REVIEW_PAY_DISPARITY_METHODS.questionId]: SCORING.buildScoringValue({
			ranges: 100 / 7, // Max possible points total: 12
		}),
		[QUESTIONS.V3_EP_TRANSPARENCY_PAYGAP_SHARING.questionId]: SCORING.buildScoringValue(),
		[QUESTIONS.V3_EP_TRANSPARENCY_SALARY_GRID.questionId]: SCORING.buildScoringValue(),
		[QUESTIONS.V3_EP_TRANSPARENCY_SALARY_POLICY.questionId]: SCORING.buildScoringValue({
			ranges: 100 / 10, // Max possible points total: 12
		}),
		[QUESTIONS.V3_EP_COMPENSATION_EQUITY.questionId]: SCORING.buildScoringValue(),
		[QUESTIONS.V3_EP_COMPENSATION_MATERNITY_RAISE.questionId]: SCORING.buildScoringValue(),
		//
		// [QUESTIONS.V3_WLB_FLEXIBILITY_FLEXIBLE_SCHEDULE.questionId]: SCORING.buildScoringValue(),
		[QUESTIONS.V3_WLB_FLEXIBILITY_FLEXIBLE_SCHEDULE_OFFERING.questionId]: SCORING.buildScoringValue({
			ranges: 100 / 18,
		}),
		[QUESTIONS.V3_WLB_FLEXIBILITY_TIMEOFF_SCHEDULING_METHODS.questionId]: SCORING.buildScoringValue({
			ranges: 100 / 10,
		}),
		[QUESTIONS.V3_WLB_FLEXIBILITY_TIMEOFF_ENCOURAGEMENT.questionId]: SCORING.buildScoringValue({
			ranges: 100 / 20,
		}),
		[QUESTIONS.V3_WLB_PARENTALITY_BENEFITS_DURING.questionId]: SCORING.buildScoringValue({
			ranges: 100 / 14, // Max possible points total: 15
		}),
		[QUESTIONS.V3_WLB_PARENTALITY_BENEFITS_RETURN.questionId]: SCORING.buildScoringValue({
			ranges: 100 / 7, // Max possible points total: 10
		}),
		[QUESTIONS.V3_WLB_BENEFITS_LIST.questionId]: SCORING.buildScoringValue({
			ranges: 100 / 14, // Max possible points total: 16
		}),
		//
		[QUESTIONS.V3_DEI_SAFETY_PSR_MEASUREMENTS.questionId]: SCORING.buildScoringValue({
			ranges: 100 / 13, // Max possible points total: 14
		}),
		[QUESTIONS.V3_DEI_SAFETY_ANTI_HARASSMENT_POLICY.questionId]: SCORING.buildScoringValue(),
		[QUESTIONS.V3_DEI_SAFETY_ANTI_DISCRIMINATION_POLICY.questionId]: SCORING.buildScoringValue(),
		[QUESTIONS.V3_DEI_SAFETY_REPORTING_PROCEDURES.questionId]: SCORING.buildScoringValue({ ranges: 100 / 6 }),
		[QUESTIONS.V3_DEI_HEALTH_MENTAL_HEALTH_SERVICES.questionId]: SCORING.buildScoringValue({
			ranges: 100 / 10, // 12
		}),
		[QUESTIONS.V3_DEI_HEALTH_WOMEN_MENTAL_HEALTH_SERVICES.questionId]: SCORING.buildScoringValue({ ranges: 100 / 7 }),
		[QUESTIONS.V3_DEI_POLICIES_DESIGNATED_DEI.questionId]: SCORING.buildScoringValue(),
		[QUESTIONS.V3_DEI_POLICIES_PUBLIC_DEI_ENGAGEMENTS.questionId]: SCORING.buildScoringValue(),
		[QUESTIONS.V3_DEI_POLICIES_ALLOCATED_BUDGET.questionId]: SCORING.buildScoringValue(),
		[QUESTIONS.V3_DEI_POLICIES_BIAS_EDUCATION.questionId]: SCORING.buildScoringValue({ ranges: 100 / 4 }),
		[QUESTIONS.V3_DEI_POLICIES_BIAS_EDUCATION_EFFICIENCY_MEASURE.questionId]: SCORING.buildScoringValue(),
		[QUESTIONS.V3_DEI_POLICIES_DEI_LEARNING_COMMITMENT.questionId]: SCORING.buildScoringValue({
			ranges: 100 / 17, // Max possible points total: 18
		}),
		[QUESTIONS.V3_DEI_RECRUITMENT_UNBIASED_RECRUITMENT_STRATEGIES.questionId]: SCORING.buildScoringValue({
			ranges: 100 / 10, // Max possible points total: 11
		}),
		[QUESTIONS.V3_DEI_RECRUITMENT_PRIORITIES.questionId]: SCORING.buildScoringValue({
			ranges: 100 / 3, // Max possible points: 4
		}),
		[QUESTIONS.V3_DEI_RECRUITMENT_ATS_PRESENCE.questionId]: SCORING.buildScoringValue(),
		[QUESTIONS.V3_DEI_RECRUITMENT_ATS_DEI_FEATURES.questionId]: SCORING.buildScoringValue(),
		[QUESTIONS.V3_DEI_RECRUITMENT_JUNIOR_HIRING.questionId]: SCORING.buildScoringValue(),
		[QUESTIONS.V3_DEI_RECRUITMENT_SENIOR_HIRING.questionId]: SCORING.buildScoringValue(),
		[QUESTIONS.V3_DEI_RECRUITMENT_CAREER_CHANGE_HIRING.questionId]: SCORING.buildScoringValue(),
	},
	sections: [
		{
			index: 1,
			...sectionGenderData,
			weighings: {
				[QUESTIONS.V3_GD_WORKFORCE_WOMEN_RATIO.questionId]: 24,
				[QUESTIONS.V3_GD_WORKFORCE_WOMEN_BOARD_RATIO.questionId]: 10,
				[QUESTIONS.V3_GD_WORKFORCE_WOMEN_TOPMGT_RATIO.questionId]: 10,
				[QUESTIONS.V3_GD_WORKFORCE_WOMEN_OTHERMGT_RATIO.questionId]: 5,
				[QUESTIONS.V3_GD_TECH_WOMEN_RATIO.questionId]: 24,
				[QUESTIONS.V3_GD_TECH_WOMEN_TOPMGT_RATIO.questionId]: 10,
				[QUESTIONS.V3_GD_TECH_WOMEN_OTHERMGT_RATIO.questionId]: 5,
				[QUESTIONS.V3_GD_LABELS_GLASSDOOR_SCORE.questionId]: 2,
				[QUESTIONS.V3_GD_LABELS_EGAPRO_SCORE.questionId]: (answers) => (
					// this answer should be used in the score ONLY IF
					// V3_GD_LABELS_EGAPRO_MEASUREMENT answer IS NOT 'not-applicable' (or value -1)
					[-1, null, 'not-applicable'].includes(answers?.[QUESTIONS.V3_GD_LABELS_EGAPRO_MEASUREMENT.questionId])
						? 0
						: 15
				),
				[QUESTIONS.V3_GD_RETENTION_TURNOVER_TRACKING.questionId]: 5,
			},
		},
		{
			index: 2,
			...sectionCareerPath,
			weighings: {
				[QUESTIONS.V3_FCP_PRACTICES_COMPETENCY_MATRIX.questionId]: 26,
				[QUESTIONS.V3_FCP_PRACTICES_CAREER_DEVELOPMENT_GRID.questionId]: 26,
				[QUESTIONS.V3_FCP_PRACTICES_SUCCESSION_PLAN.questionId]: 5,
				[QUESTIONS.V3_FCP_PRACTICES_INTERNAL_MOBILITY_PROGRAM.questionId]: 5,
				[QUESTIONS.V3_FCP_PRACTICES_NONBIASED_ANNUAL_REVIEW.questionId]: 5,
				[QUESTIONS.V3_FCP_DEVELOPMENT_MENTORSHIP_PROGRAM.questionId]: 3,
				[QUESTIONS.V3_FCP_DEVELOPMENT_MENTORSHIP_PROGRAM_SATISFACTION.questionId]: 1,
				[QUESTIONS.V3_FCP_DEVELOPMENT_SPONSORSHIP_PROGRAM.questionId]: 3,
				[QUESTIONS.V3_FCP_DEVELOPMENT_SPONSORSHIP_PROGRAM_SATISFACTION.questionId]: 1,
				[QUESTIONS.V3_FCP_DEVELOPMENT_CAREER_COACHING_PROGRAM.questionId]: 3,
				[QUESTIONS.V3_FCP_DEVELOPMENT_CAREER_COACHING_PROGRAM_SATISFACTION.questionId]: 1,
				//
				[QUESTIONS.V3_GD_LABELS_GLASSDOOR_SCORE.questionId]: 8,
				[QUESTIONS.V3_GD_LABELS_EGAPRO_SCORE.questionId]: (answers) => (
					// this answer should be used in the score ONLY IF
					// GENDERDATA_ISEGAPRO answer IS NOT 'not-applicable' (or value -1)
					[-1, null, 'not-applicable'].includes(answers?.[QUESTIONS.V3_GD_LABELS_EGAPRO_MEASUREMENT.questionId])
						? 0
						: 16
				),
			},
		},
		{
			index: 3,
			...sectionEqualPay,
			weighings: {
				[QUESTIONS.V3_EP_REVIEW_EQUALPAY_REVIEW.questionId]: 50,
				[QUESTIONS.V3_EP_REVIEW_ADJUSTED_PAYGAP.questionId]: 25,
				[QUESTIONS.V3_EP_REVIEW_UNADJUSTED_PAYGAP.questionId]: 18,
				[QUESTIONS.V3_EP_REVIEW_PAY_DISPARITY_METHODS.questionId]: 10,
				[QUESTIONS.V3_EP_TRANSPARENCY_PAYGAP_SHARING.questionId]: 10,
				[QUESTIONS.V3_EP_TRANSPARENCY_SALARY_GRID.questionId]: 10,
				[QUESTIONS.V3_EP_TRANSPARENCY_SALARY_POLICY.questionId]: 10,
				[QUESTIONS.V3_EP_COMPENSATION_EQUITY.questionId]: 5,
				[QUESTIONS.V3_EP_COMPENSATION_MATERNITY_RAISE.questionId]: 10,
				//
				[QUESTIONS.V3_GD_LABELS_EGAPRO_SCORE.questionId]: (answers) => (
					// this answer should be used in the score ONLY IF
					// GENDERDATA_ISEGAPRO answer IS NOT 'not-applicable' (or value -1)
					[-1, null, 'not-applicable'].includes(answers?.[QUESTIONS.V3_GD_LABELS_EGAPRO_MEASUREMENT.questionId])
						? 0
						: 5
				),
			},
		},
		{
			index: 4,
			...sectionWorklifeBalance,
			weighings: {
				// [QUESTIONS.V3_WLB_FLEXIBILITY_FLEXIBLE_SCHEDULE.questionId]: 10,
				[QUESTIONS.V3_WLB_FLEXIBILITY_FLEXIBLE_SCHEDULE_OFFERING.questionId]: 25,
				[QUESTIONS.V3_WLB_FLEXIBILITY_TIMEOFF_SCHEDULING_METHODS.questionId]: 10,
				[QUESTIONS.V3_WLB_FLEXIBILITY_TIMEOFF_ENCOURAGEMENT.questionId]: 5,
				[QUESTIONS.V3_WLB_PARENTALITY_BENEFITS_DURING.questionId]: 10,
				[QUESTIONS.V3_WLB_PARENTALITY_BENEFITS_RETURN.questionId]: 10,
				[QUESTIONS.V3_WLB_BENEFITS_LIST.questionId]: 5,
			},

		},
		{
			index: 5,
			...sectionDeiActions,
			weighings: {
				[QUESTIONS.V3_DEI_SAFETY_PSR_MEASUREMENTS.questionId]: 50,
				[QUESTIONS.V3_DEI_SAFETY_ANTI_HARASSMENT_POLICY.questionId]: 30,
				[QUESTIONS.V3_DEI_SAFETY_ANTI_DISCRIMINATION_POLICY.questionId]: 30,
				[QUESTIONS.V3_DEI_SAFETY_REPORTING_PROCEDURES.questionId]: 5,
				[QUESTIONS.V3_DEI_HEALTH_MENTAL_HEALTH_SERVICES.questionId]: 5,
				[QUESTIONS.V3_DEI_HEALTH_WOMEN_MENTAL_HEALTH_SERVICES.questionId]: 10,
				[QUESTIONS.V3_DEI_POLICIES_DESIGNATED_DEI.questionId]: 10,
				[QUESTIONS.V3_DEI_POLICIES_PUBLIC_DEI_ENGAGEMENTS.questionId]: 5,
				[QUESTIONS.V3_DEI_POLICIES_ALLOCATED_BUDGET.questionId]: 10,
				[QUESTIONS.V3_DEI_POLICIES_BIAS_EDUCATION.questionId]: 5,
				[QUESTIONS.V3_DEI_POLICIES_BIAS_EDUCATION_EFFICIENCY_MEASURE.questionId]: 3,
				[QUESTIONS.V3_DEI_POLICIES_DEI_LEARNING_COMMITMENT.questionId]: 10,
				[QUESTIONS.V3_DEI_RECRUITMENT_UNBIASED_RECRUITMENT_STRATEGIES.questionId]: 10,
				[QUESTIONS.V3_DEI_RECRUITMENT_PRIORITIES.questionId]: 10,
				[QUESTIONS.V3_DEI_RECRUITMENT_ATS_PRESENCE.questionId]: 2,
				[QUESTIONS.V3_DEI_RECRUITMENT_ATS_DEI_FEATURES.questionId]: 3,
				[QUESTIONS.V3_DEI_RECRUITMENT_JUNIOR_HIRING.questionId]: 10,
				[QUESTIONS.V3_DEI_RECRUITMENT_JUNIOR_HIRING.questionId]: 10,
				[QUESTIONS.V3_DEI_RECRUITMENT_SENIOR_HIRING.questionId]: 10,
				[QUESTIONS.V3_DEI_RECRUITMENT_CAREER_CHANGE_HIRING.questionId]: 10,
			},
		},
	],
};

export default GENDERSCORE_SURVEY_50PLUS_V3_DATA_OPTION;
