import { useTheme } from '@emotion/react';
import { FaStar } from 'react-icons/fa';

const CoachingRating = ({ user }) => {
	const theme = useTheme();

	if (!user?.coaching?.coachingRating) return null;

	const rating = Math.min(5, Math.max(0, user?.coaching?.coachingRating));
	return (
		<div>
			{[...Array(rating).keys()].map((i) => <FaStar
				key={i} style={{ marginBottom: -2 }} color={theme.color.orange}/>)}
			{[...Array(5 - rating).keys()].map((i) => <FaStar
				key={i} style={{ marginBottom: -2 }} color={theme.color.lightgrey}/>)}
		</div>
	);
};

export default CoachingRating;
