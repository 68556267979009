import { useEffect, useRef, useState } from 'react';
import { LocalDebug } from '../utils/LocalDebug';
import ChartsEmbedSDK from '@mongodb-js/charts-embed-dom';

const baseUrl = 'https://charts.mongodb.com/charts-50intech-main-project-vsqjh';

const MongoChart = (
	{
		filters,
		chartId,
		width,
		height,
		withBox = true,
	},
) => {
	const className = 'Chart';
	LocalDebug.logInfo({ className });

	const sdk = new ChartsEmbedSDK({ baseUrl });
	const chartDiv = useRef(null);
	const [rendered, setRendered] = useState(false);
	const [chart] = useState(sdk.createChart({
		chartId, width: `${width}px`, height: `${height}px`, /* , theme: 'dark' */
	}));

	useEffect(() => {
		LocalDebug.logUseEffect({ className, effects: 'chart' }, { chart });
		chart
			.render(chartDiv.current)
			.then(() => setRendered(true))
			.catch((err) => console.log('Error during Charts rendering.', err));
	}, [chart]);

	useEffect(() => {
		LocalDebug.logUseEffect({ className, effects: 'chart filters rendered' }, { chart, rendered, filters });
		if (rendered) {
			chart.setFilter(filters).catch((err) => console.log('Error while filtering.', err));
		}
	}, [chart, filters, rendered]);

	return (
		<div
			className='chart'
			style={{ border: '1px solid #eee', borderRadius: 4 }}
			ref={chartDiv}
		/>
	);
};

export default MongoChart;
