import { useEffect, useState } from 'react';
import InviteUserModal from './inviteUser/InviteUserModal';
import ModalContainerBase from '../../contexts/modals/ModalContainerBase';
import AclInviteForm from './AclInviteForm';
import { documentId } from '../../utils/common';
import FormBoxTitle from '../app/form/FormBoxTitle';
import ReactJsonDebug from '../app/debug/ReactJsonDebug';
import { useSelector } from 'react-redux';
import { getCompanySelected } from '../../reducers/app';
import { LocalDebug } from '../../utils/LocalDebug';

const AclAddPopover = (
	{
		title = 'Add an access',
		initialValues = {},
		onCloseWithRefresh,
		open,
		onSubmit,
		onReset,
	},
) => {
	const className = 'AclAddPopover';

	const companySelected = useSelector(getCompanySelected);

	const [openInviteModal, setOpenInviteModal] = useState(false);
	const [companyId, setCompanyId] = useState(initialValues?.companyId);

	const toggleInviteModal = () => {
		setOpenInviteModal((prev) => !prev);
	};

	// ------------------------------------------------ //
	// --------------- handle submit ------------------ //
	// ------------------------------------------------ //

	const handleSubmit = (values) => {
		const submitValues = {
			...values,
			documentId: companyId,
			id: documentId(initialValues),
		};
		LocalDebug.logInfo({ className, method: 'handleSubmit' }, { submitValues });
		onSubmit?.(submitValues);
	};

	useEffect(() => {
		if (companySelected) setCompanyId(documentId(companySelected));
	}, [companySelected]);

	return (
		<ModalContainerBase
			open={open}
			onCancel={onReset}
		>
			<FormBoxTitle
				title={documentId(initialValues) ? 'Edit an access' : title}
			/>
			<AclInviteForm
				initialValues={{ ...initialValues, isExist: true }}
				onReset={onReset}
				onSubmit={handleSubmit}
				onCloseWithRefresh={onCloseWithRefresh}
				onCompanyChange={setCompanyId}
				autoValidateEmail={initialValues?.autoValidateEmail}
				withInviteUser
				withSubmitButton
			/>

			<InviteUserModal
				open={openInviteModal}
				onClose={toggleInviteModal}
				onCloseWithRefresh={onCloseWithRefresh}
			/>
		</ModalContainerBase>
	);
};

export default AclAddPopover;
