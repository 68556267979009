import { Button } from 'antd';
import { FaPlus } from 'react-icons/fa';

const UserSavedSearchCreateNewButton = (
	{
		onCreate,
		...props
	},
) => {
	const className = 'UserSavedSearchCreateNewButton';

	return (
		<Button
			type='primary'
			ghost
			size={'large'}
			icon={<FaPlus style={{ marginBottom: -2, marginRight: 6 }}/>}
			style={{
				borderRadius: 8,
				fontWeight: 'bold',
			}}
			onClick={() => onCreate?.()}
		>
			Create new search
		</Button>
	);
};

export default UserSavedSearchCreateNewButton;
