import React, { useState } from 'react';
import { request } from '../services/api';
import { LocalDebug } from '../utils/LocalDebug';

export const GenderScoreResourceContext = React.createContext();

const GenderScoreResourceProvider = ({
	children,
}) => {
	const className = 'GenderScoreResourcesProvider';

	const [loading, setLoading] = useState(true);
	const [resources, setResources] = useState([]);

	const fetch = async () => {
		LocalDebug.logInfo({ className, method: 'fetch' });
		setLoading(true);
		try {
			const result = await request.get('/genderscore/resource');
			LocalDebug.logInfo({ className, method: 'fetch' }, { result, data: result?.data, count: result?.data?.items?.length });
			if (result?.data?.items?.length > 0) {
				setLoading(false);
				setResources(result?.data?.items);
				return result?.data?.items;
			}
		} catch (e) {
			setLoading(false);
			return [];
		}
		setLoading(false);
	};

	const getResources = async () => {
		LocalDebug.logInfo({ className, method: 'getResources' }, { resources: resources?.length });
		if (resources?.length === 0) return fetch();
		return resources;
	};

	return (
		<GenderScoreResourceContext.Provider
			value={{
				getResources,
				loading,
			}}
		>
			{children}
		</GenderScoreResourceContext.Provider>
	);
};

export default GenderScoreResourceProvider;
