import GenderScoreActionPlanPage from '../../containers/genderscore/GenderScoreActionPlanPage';
import { Badge, Space } from 'antd';
import { PERM_GRANT_TYPE } from '../menu.utils';
import roles from '../../config/roles';
import MenuBase from '../MenuBase';
import { FireOutlined } from '@ant-design/icons';
import { useTheme } from '@emotion/react';
import { useSelector } from 'react-redux';
import { getCompanySelected } from '../../reducers/app';
import { useEffect, useState } from 'react';
import MenuBadge from '../MenuBadge';

export const GenderscoreActionPlanIcon = FireOutlined;

export const GenderscoreActionPlanLabel = ({
	withCount = true,
}) => {
	const theme = useTheme();
	const companySelected = useSelector(getCompanySelected);

	const [actionPlanCount, setActionPlanCount] = useState(0);

	useEffect(() => {
		setActionPlanCount(companySelected?.genderScoreActions?.actions
			?.filter((a) => a?.isActive !== false)?.length || 0);
	}, [companySelected]);

	const label = 'Action plan';

	if (!withCount || !(actionPlanCount > 0)) {
		return label;
	}

	return (
		<>
			<span>{label}</span>
			{theme && actionPlanCount > 0 && (
				<MenuBadge
					value={actionPlanCount}
					overflowCount={10}
				/>
			)}
		</>
	);
};

class GenderscoreActionPlanMenu extends MenuBase {
	static props = {
		name: 'genderscore-action-plan',
		path: '/genderscore/action-plan',
		label: () => (args) => <GenderscoreActionPlanLabel {...args} />,
		icon: <GenderscoreActionPlanIcon />,
		iconLegacy: '🎯',
		Component: GenderScoreActionPlanPage,
		perms: [
			{
				type: PERM_GRANT_TYPE,
				roles: [
					roles.STAFF,
					roles.DOCUMENT_ADMIN,
					roles.COMPANY_RECRUITER,
				],
				genderScoreEnabled: true,
			},
		],
	};

	constructor() {
		super(GenderscoreActionPlanMenu.props);
	}
}

export default GenderscoreActionPlanMenu;
