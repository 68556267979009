import moment from 'moment';
import {
	Button, Col, DatePicker, Divider, Form, Input, Row, Select, Space, Spin, Switch,
} from 'antd';
import { useTheme } from '@emotion/react';
import { useEffect, useState } from 'react';
import Link from '../../app/Link';

import { NodeHtmlMarkdown } from 'node-html-markdown';
import { FormItemContainer } from '../../app/form/FormItem';
import FormItem from '../../company/form/FormItem';
import { useDispatch, useSelector } from 'react-redux';
import { getCompanies, getCompanySelected, getItems } from '../../../reducers/app';
import GroupItems from './GroupItems';
import FormCompanySelect from '../../app/form/FormCompanySelect';
import { useAuthContext } from '../../../hooks/useAuthContext';
import TextareaEditor from '../../company/form/TextareaEditor';
import { LocalDebug, localDebug } from '../../../utils/LocalDebug';
import ReactJsonAdmin from '../../app/debug/ReactJsonAdmin';
import { getAllJobItems } from '../../../actions/jobs';
import BlockStaff from '../../app/blocker/BlockStaff';
import CompanyStub from '../../company/CompanyStub';
import { FaCheck, FaExternalLinkAlt } from 'react-icons/fa';
import { documentId, openExternalUrl } from '../../../utils/common';
import SalaryRangeInput from './SalaryRangeInput';
import AdminService from '../../../services/admin';
import ReactJsonDebug from '../../app/debug/ReactJsonDebug';
import JSONPretty from 'react-json-pretty';
import CompanyLogo from '../../company/CompanyLogo';
import ReactMarkdown from 'react-markdown';
// import Diff from 'react-diff';
import ReactDiffViewer from 'react-diff-viewer-continued';

const JobOpenAIFormItem = (
	{
		children,
		...props
	},
) => <FormItem
	{...props}
	defaultContainer='div'
	styles={{
		rootContainer: {
			width: '100%',
			display: 'flex',
			alignItems: 'flex-start',
			justifyContent: 'space-between',
			marginBottom: 20,
			...props?.styles?.rootContainer,
			fontSize: 12,
		},
		label: {
			textAlign: 'right',
			...props?.styles?.label,
		},
		itemContainer: {
			width: '100%',
			marginBottom: 0,
			...props?.styles?.itemContainer,
		},
		container: {
			flex: 1,
			...props?.styles?.container,
			// justifyContent: 'flex-start',
		},
		input: {
			height: 32,
			...props?.styles?.input,
		},
	}}
>
	{children}
</FormItem>;

const JobOpenAIForm = (
	{
		initialValues,
		onSubmit,
		onReset,
		...props
	},
) => {
	const className = 'JobOpenAIForm';

	const dispatch = useDispatch();

	const [form] = Form.useForm();
	const theme = useTheme();

	// localDebug({ initialValues });
	const { isStaffView, companyIdsUser } = useAuthContext();

	const promptOptions = [
		{
			value: 'properties',
			label: 'Extract skills',
			returnType: 'json',
			prompt: 'From the following job offer information, create a JSON with the hard skills (organized by category) and the soft skills required for the position',
		},
		{
			value: 'inclusive',
			label: 'Make more inclusive',
			prompt: 'From the following job offer information, rewrite the title and job description to make it more inclusive',
		},
		{
			value: 'friend',
			label: 'As a friend',
			prompt: 'Rewrite the job offer as if a friend working there was trying to convince you to join',
		},
		{
			value: 'woman',
			label: 'As another woman',
			prompt: 'Rewrite the job offer as if a woman working there was trying to convince another woman to join',
		},
		{
			value: 'nineteenth',
			label: '19th century',
			prompt: '19th century style',
		},
		{
			value: 'shakespeare',
			label: 'Shakespeare',
			prompt: 'Shakespeare style',
		},
		{
			value: 'lafontaine',
			label: 'La Fontaine',
			prompt: 'La Fontaine style',
		},
		{
			value: 'slang',
			label: 'Slang',
			prompt: 'Slang style',
		},
		{
			value: 'beyonce',
			label: 'Beyonce',
			prompt: 'Beyonce',
		},
		{
			value: 'tonimorrison',
			label: 'Toni Morrison',
			prompt: 'Toni Morrison',
		},
		// {
		// 	value: 'borisvian', label: 'Boris Vian',
		// 	prompt: 'Boris Vian style',
		// },
		// {
		// 	value: 'gainsbourg', label: 'Serge Gainsbourg',
		// 	prompt: 'Serge Gainsbourg style',
		// },
		// {
		// 	value: 'lapointe', label: 'Boby Lapointe',
		// 	prompt: 'Boby Lapointe style',
		// },
		{
			value: 'comedy',
			label: 'Comedy',
			prompt: 'Comedy style',
		},
		{
			value: 'romcom',
			label: 'Romantic comedy',
			prompt: 'Romantic comedy style',
		},
		{
			value: 'play',
			label: 'Theater Play',
			prompt: 'Theater play style',
		},
		{
			value: 'grandma',
			label: 'Granma',
			prompt: 'Granma style',
		},
		{
			value: 'baby',
			label: '5-year old',
			prompt: 'Explain it to a 5-year old',
		},
		{
			value: 'starwars',
			label: 'Star Wars',
			prompt: 'Add Star Wars references',
		},
		{ value: 'test 1', label: 'Test 1', prompt: 'Test 1' },
		{ value: 'test 2', label: 'Test 2', prompt: 'Test 2' },
	];
	const modelOptions = [
		{ value: 'text-davinci-003', label: 'text-davinci-003' },
		{ value: 'text-ada-001', label: 'text-ada-001' },
		{ value: 'ada', label: 'ada' },
		{ value: 'gpt-3.5-turbo', label: 'gpt-3.5-turbo' },
	];
	const [openAIResult, setOpenAIResult] = useState({});
	const [openAIPromptId, setOpenAIPromptId] = useState(promptOptions[0]?.value);
	const [openAIModel, setOpenAIModel] = useState(modelOptions[0]?.value);
	const [openAIPromptOption, setOpenAIPromptOption] = useState(null);
	const [isLoading, setIsLoading] = useState(false);

	// const handleSubmit = (values) => {
	// 	if (!onSubmit) {
	// 		return;
	// 	}
	// 	const data = {
	// 		...values,
	// 		id: documentId(initialValues),
	// 		companyId,
	// 		// publishedAt,
	// 		isActive: true,
	// 		isCustomize: true,
	// 	};
	// 	setData(data);
	// 	LocalDebug.logInfo({ className, method: 'handleSubmit' }, data);
	// 	onSubmit(data);
	// };

	const submitPrompt = async () => {
		const method = 'submitPrompt';
		try {
			setIsLoading(true);
			setOpenAIResult(null);
			const promptOption = promptOptions.find((p) => p.value === openAIPromptId);
			setOpenAIPromptOption(promptOption);
			const { data: { result } } = await AdminService.getOpenAIJob({
				model: openAIModel,
				promptId: openAIPromptId,
				jobId: documentId(initialValues),
			});
			LocalDebug.logInfo({ className, method }, { result });
			setOpenAIResult(result);
		} catch (e) {
			LocalDebug.logError({ className, method }, e);
		}
		setIsLoading(false);
	};

	// const handlePromptChange = async (value) => {
	// 	setOpenAIResult(null);
	// 	const promptOption = promptOptions.find(p => p.value === value);
	// 	LocalDebug.logInfo({ className, method: 'handleSelectChange' }, { value, promptOption });
	// 	setOpenAIPromptOption(promptOption);
	// 	const { data: { result } } = await AdminService.getOpenAIJob({
	// 		promptId: value, jobId: documentId(initialValues),
	// 	});
	// 	LocalDebug.logInfo({ className, method: 'handleSelectChange' }, { result });
	// 	setOpenAIResult(result);
	// };

	const handleModelChange = async (value) => {
		setOpenAIModel(value);
	};

	return (
		<Form
			form={form}
			name='alert_form'
			initialValues={{
				...initialValues,
			}}
			labelCol={{ span: 5 }}
			wrapperCol={{ span: 19 }}
			// onFinish={handleSubmit}
			style={{ paddingTop: 20 }}
			// onValuesChange={onFormChanged}
		>
			<div style={{
				// height: '100vh',
				height: '100%',
				fontSize: 12,
				// overflowY: 'hidden',
				// paddingRight: '15px',
				// height: 'calc(100vh - 72px)',
				// maxHeight: 'calc(100vh - 72px)',
				// overflowY: 'hidden',
				// overflowX: 'hidden',
			}}>
				<FormItemContainer>
					<Row gutter={[20, 20]}>
						<Col span={12}>
							{initialValues.title && (
								<h2>
									<CompanyLogo company={initialValues.company} /> {initialValues.title}
								</h2>
							)}

							{initialValues.uri && (
								<p style={{ fontSize: 12 }}>
									<b>URI:</b>
									<Link onClick={() => openExternalUrl(initialValues.uri)}>
										&nbsp;{initialValues.uri} <FaExternalLinkAlt style={{ marginBottom: -2 }} />
									</Link>
								</p>
							)}
							{initialValues.applyUri && initialValues.applyUri !== initialValues.uri && (
								<p style={{ fontSize: 12 }}>
									<b>Apply URI:</b>
									<Link onClick={() => openExternalUrl(initialValues.applyUri)}>
										&nbsp;{initialValues.applyUri} <FaExternalLinkAlt
											style={{ marginBottom: -2 }} />
									</Link>
								</p>
							)}

							{initialValues.location && (
								<p style={{ fontSize: 12 }}>
									<b>Location:</b> {initialValues.location}
								</p>
							)}
							{initialValues.contract && (
								<p style={{ fontSize: 12 }}>
									<b>Contract:</b> {initialValues.contract}
								</p>
							)}
							{initialValues.department && (
								<p style={{ fontSize: 12 }}>
									<b>Department:</b> {initialValues.department}
								</p>
							)}
							{initialValues.content && (
								<p>
									<b style={{ fontSize: 12 }}>Job desc</b>

									<div style={{ border: '1px solid #eee', padding: 10 }}>
										<ReactMarkdown>
											{NodeHtmlMarkdown.translate(`<h1>${initialValues.title}</h1>${initialValues.content}`)}
										</ReactMarkdown>
									</div>
									{/* <p style={{ border: '1px solid #eee', padding: 10, fontSize: 11 }} */}
									{/*   className='rawHtml' */}
									{/*   dangerouslySetInnerHTML={{ __html: initialValues.content }} */}
									{/* /> */}
								</p>
							)}

							{/* <JobOpenAIFormItem */}
							{/*	label='&nbsp;' */}
							{/* > */}
							{/*	<Button type='primary' */}
							{/*			style={{ */}
							{/*				margin: 0, */}
							{/*				fontWeight: 'bold', */}
							{/*				borderRadius: 4, */}
							{/*			}} */}
							{/*			icon={<FaCheck style={{ marginBottom: -2 }} />} */}
							{/*			htmlType='submit' */}
							{/*	> */}
							{/*		&nbsp;Save */}
							{/*	</Button> */}
							{/* </JobOpenAIFormItem> */}
						</Col>

						<Col span={12}>

							<Space>
								<div>
									<Select
										style={{ width: 200 }}
										options={promptOptions}
										onChange={setOpenAIPromptId}
										defaultValue={promptOptions[0]?.value}
									/>
								</div>

								<div>
									<Select
										style={{ width: 200 }}
										options={modelOptions}
										onChange={setOpenAIModel}
										defaultValue={modelOptions[0]?.value}
									/>
								</div>

								<Button
									disabled={isLoading || !openAIModel || !openAIPromptId}
									type='primary'
									onClick={submitPrompt}
								>
									Submit
								</Button>
							</Space>

							<Divider />

							{isLoading && <Spin />}
							{openAIResult && openAIPromptOption && (
								<>
									<b>Prompt</b>
									<h3>{openAIPromptOption?.prompt}</h3>

									<Divider />
									{openAIPromptOption?.returnType === 'json'
										? (
											<>
												<JSONPretty
													style={{
														border: '1px solid #eee',
														padding: 10,
														fontSize: '12px !important',
													}}
													data={(() => {
														try {
															return JSON.parse(openAIResult.text || '{}');
														} catch (e) {
															return { error: 'JSON malformed' };
														}
													})()}
												/>
												<Divider />

												<ReactJsonDebug
													src={(() => {
														try {
															return JSON.parse(openAIResult.text || '{}');
														} catch (e) {
															return { error: 'JSON malformed' };
														}
													})()}
													collapsed={0} />
												<ReactJsonDebug src={openAIResult || {}} collapsed={0} />
											</>
										)
										: (
											<>
												{/* <Diff */}
												{/*	inputA={NodeHtmlMarkdown.translate(initialValues.content)} */}
												{/*	inputB={openAIResult?.text} */}
												{/*	type='chars' */}
												{/* /> */}
												<ReactMarkdown>
													{openAIResult?.text}
												</ReactMarkdown>
											</>
										)
									}

								</>
							)}
						</Col>
					</Row>

					<ReactDiffViewer
						oldValue={`# ${initialValues.title}\n\n${NodeHtmlMarkdown.translate(initialValues.content)}`}
						newValue={openAIResult?.text}
						splitView={true}
					/>

				</FormItemContainer>
			</div>

		</Form>
	);
};

export default JobOpenAIForm;
