import { LOADING_START, LOADING_END } from './types';
import { LocalDebug, localDebug } from '../utils/LocalDebug';
import { Button, message } from 'antd';
import ReactJson from 'react-json-view';
import { FaTimes } from 'react-icons/fa';
import { history } from '../browserHistory';
import { isFunction } from '../utils/common';

const className = 'actions.utils';

const { push: routerPush, back: goBackInRouter } = history;

export function displayMessage({
	method, key, body, error, dismissable, duration = 2,
}) {
	method?.(
		{
			key,
			content:
            <div style={{ position: 'relative' }}>
            	<div style={{
            		minWidth: 300, maxWidth: 600, maxHeight: '80vh', overflow: 'auto', textAlign: 'left',
            	}}>
            		{body || <div dangerouslySetInnerHTML={{
            			__html:
                      [error.message, error.response?.data?.error, error.response?.data?.error?.message].filter((i) => i).join('<br/>'),
            		}} />}
            	</div>
            	{dismissable && <div style={{ position: 'absolute', top: -24, right: -10 }}>
            		<Button
            			icon={<FaTimes style={{ marginBottom: -2 }}/>}
            			style={{ border: 'none', background: 'none' }}
            			onClick={() => message.destroy(key)} />
            	</div>}
            </div>,
			duration: dismissable ? 0 : duration,
		},
	);
}

/**
 * returns a thunk
 * @param thunkOrPromise (signature if thunk: (dispatch : func, getState : func) : Promise)
 * @returns {function(*=, *=): Promise<void>}
 */
export function actionWithLoader(thunkOrPromise) {
	return async (dispatch, getState) => {
		dispatch({
			type: LOADING_START,
		});
		try {
			if (typeof thunkOrPromise === 'function') {
				const response = await thunkOrPromise(dispatch, getState);
				return response;
			}
			await thunkOrPromise;
		} catch (error) {
			// localDebug('error', error);
		} finally {
			dispatch({
				type: LOADING_END,
			});
		}
	};
}

export function action(thunkOrPromise) {
	return async (dispatch, getState) => {
		try {
			if (typeof thunkOrPromise === 'function') {
				const response = await thunkOrPromise(dispatch, getState);
				return response;
			}
			await thunkOrPromise;
		} catch (error) {
			localDebug('error', error);
		}
	};
}

export function messageTitleBuilder({ title, step }) {
	return <div><b>{`${title ? `${title}: ` : ''}${step}`}</b></div>;
}

export function messageResultBuilder(data) {
	return (
		<div
			style={{
				textAlign: 'left',
				minWidth: 400,
				maxWidth: 800,
				whiteSpace: 'normal',
				wordBreak: 'break-word',
				maxHeight: '90vh',
				overflowY: 'scroll',
			}}
		>
			<ReactJson
				src={data}
				style={{ fontSize: 10 }}
				// sortKeys={true}
				collapsed={3}
				displayDataTypes={false}
				name={false}
				quotesOnKeys={false}
			/>
		</div>
	);
	// return <JSONPretty data={data} />;
}

export function basicActionBuilder(
	{
		command,
		params = {},
		withLoader = false,
		messageTitle,
		titleBuilder = messageTitleBuilder,
		resultBuilder = messageResultBuilder,
		messageDismissable = true,
		messageDuration = 2,
	},
) {
	return (withLoader ? actionWithLoader : action)(async (dispatch) => {
		const messageArgs = {
			key: Math.round(1000000 * Math.random()).toString(10),
			dismissable: messageDismissable,
			duration: messageDuration,
		};

		try {
			displayMessage({
				...messageArgs,
				method: message.loading,
				body: titleBuilder({ title: messageTitle, step: 'Started' }),
			});
			const result = await command(params);
			// dispatch({ type: ACTION_TYPES.LOADING_END });
			const body = <>
				{messageTitleBuilder({ title: messageTitle, step: 'Done' })}
				{await resultBuilder(result?.data)}
			</>;
			displayMessage({ ...messageArgs, method: message.success, body });
		} catch (error) {
			// displayMessage({ ...messageArgs, dismissable: false, method: message.error, error, duration: 0.1 })
			message.destroy(messageArgs.key);
			// displayMessage({ ...messageArgs, dismissable: false, method: message.error, error, duration: 0.0001 })
			// displayMessage({ ...messageArgs, dismissable: false, method: message.open, duration: 1 })
			LocalDebug.logError({
				className, method: 'basicActionLoader', __filename, error,
			});
		}
	});
}

export function onEnter({
	store,
	actionThunk,
	getReplacingPath,
	withLoader = true,
}) {
	return async (nextState, replace, callback) => {
		try {
			if (getReplacingPath) {
				const replacingPath = await getReplacingPath(store.getState);
				if (replacingPath) {
					replace(replacingPath);
					if (isFunction(callback)) callback();
					return;
				}
			}

			// ---- actual call ----//
			const dispatchingFunction = actionThunk(nextState.params);
			let result;
			if (withLoader) {
				result = actionWithLoader(dispatchingFunction)(
					store.dispatch,
					store.getState,
				);
			} else {
				result = dispatchingFunction(store.dispatch, store.getState);
			}
			if (result && result.then) {
				await result;
			}

			if (isFunction(callback)) callback();
		} catch (error) {
			console.error(error);
			if (isFunction(callback)) callback(error);
		}
	};
}

export function goBack() {
	goBackInRouter();
}

export function routerLocation(pathname, returnPath) {
	if (typeof returnPath !== 'string') {
		// can be an Event (eg: onClick={showxxx})
		returnPath = undefined;
	}
	return {
		pathname,
		state: {
			returnPath,
		},
	};
}

export function push(pathname, returnPath) {
	// pathname might already be a location object
	const location = returnPath ? routerLocation(pathname, returnPath) : pathname;
	routerPush(location);
}
