import MessageService from '../services/message';
import * as ACTION_TYPES from './types';
import { actionWithLoader } from './utils';
import { localDebug } from '../utils/LocalDebug';

export const getAllMessages = (options = {}) => {
	localDebug('getAllMessages', options);
	return actionWithLoader(async (dispatch, getState) => {
		try {
			localDebug('getAllMessages', options);
			const { data } = await MessageService.findAll(options);
			dispatch({
				type: ACTION_TYPES.MESSAGES_LOADED,
				messages: data.messages,
			});
		} catch (error) {
			localDebug(__filename.split('/').shift(), error);
		}
	});
};

export const addMessage = async (data) => {
	try {
		const { data: { data: userMessage } } = await MessageService.create(data);
		return userMessage;
	} catch (error) {
		localDebug(__filename.split('/').shift(), error);
	}
};

export const retrySendMessage = async (data) => {
	try {
		const { data: { data: userMessage } } = await MessageService.retrySend(data);
		return userMessage;
	} catch (error) {
		localDebug(__filename.split('/').shift(), error);
	}
};

export const refreshUserMailingOfMessage = async (data) => {
	try {
		const { data: { data: userMessage } } = await MessageService.refreshUserMailing(data);
		return userMessage;
	} catch (error) {
		localDebug(__filename.split('/').shift(), error);
		throw error;
	}
};

export const updateMessage = (messageId, data) => {
	return async (dispatch) => {
		try {
			await MessageService.update(messageId, data);
			dispatch(getAllMessages());
		} catch (error) {
			localDebug(__filename.split('/').shift(), error);
		}
	};
};

export const removeMessage = (message) => {
	return actionWithLoader(async () => {
		try {
			await MessageService.remove(message);
		} catch (error) {
			localDebug(__filename.split('/').shift(), error);
		}
	});
};
