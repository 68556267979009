import { GENDERSCORE_EMPTY_ICON_IMG, GENDERSCORE_LABEL } from '../../constants/constant';
import SurveyContainer from '../../components/genderscore/survey/SurveyContainer';
import { PERMS_GENDERSCORE_SURVEY_ENABLED } from './genderscore-utils';
import MenuBase from '../MenuBase';
import { SafetyOutlined } from '@ant-design/icons';
import { useTheme } from '@emotion/react';
import MenuBadge from '../MenuBadge';

export const GenderscoreSurveyLabel = (args) => {
	const theme = useTheme();
	return (
		<>
			<span>Survey</span>
			{args.isGenderScoreSurveyEnabled && (
				<MenuBadge
					value={' '}
				/>
			)}
		</>
	);
};

export const GenderscoreSurveyIcon = SafetyOutlined;

class GenderscoreSurveyMenu extends MenuBase {
	static props = {
		name: 'genderscore-survey',
		path: '/genderscore/survey',
		aliases: ['^/genderscore/survey'],
		label: () => (args) => <GenderscoreSurveyLabel {...args} />,
		icon: <GenderscoreSurveyIcon />,
		iconLegacy: GENDERSCORE_EMPTY_ICON_IMG(),
		Component: SurveyContainer,
		perms: PERMS_GENDERSCORE_SURVEY_ENABLED,
	};

	constructor() {
		super(GenderscoreSurveyMenu.props);
	}
}

export default GenderscoreSurveyMenu;
