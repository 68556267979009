import { useEffect, useState } from 'react';
import {
	Collapse,
	Divider, Skeleton, Space,
} from 'antd';
import {
	CheckCircleOutlined,
	EyeOutlined, MessageOutlined, ReloadOutlined, SearchOutlined, UserOutlined,
} from '@ant-design/icons';
import { useAuthContext } from '../../../hooks/useAuthContext';
import { useDebouncedEffect } from '../../../hooks/useDebounceEffect';
import { request } from '../../../services/api';
import { LocalDebug } from '../../../utils/LocalDebug';
import JsonPopoverLink from '../../../components/app/debug/JsonPopoverLink';
import PageLayout from '../../../components/app/layout/PageLayout';
import StaffMissingResumesMenu from '../../../menu/staff/users/StaffMissingResumesMenu';
import UserMissingResumesTable from '../../../components/user/UserMissingResumesTable';
import Box from '../../../components/app/box/Box';
import { pct } from '../../../utils/common';

const StatBox = ({
	title,
	isLoading = false,
	caption,
	value,
}) => {
	return (
		<Box style={{ padding: '18px 16px' }}>
			<Space
				direction='vertical'
				size={10}
			>
				<div
					style={{
						fontSize: 12,
						color: '#999',
					}}
				>
					{title}
				</div>
				{isLoading
					? (
						<Skeleton.Input
							active
							style={{
								width: 40 + 100 * Math.random(),
								borderRadius: 5,
								height: 48.2,
							}}
						/>
					)
					: (
						<>
							<div
								style={{
									fontSize: 24,
									color: '#333',
								}}
							>
								{value}
							</div>
							<div
								style={{
									fontSize: 12,
									color: '#999',
									marginTop: -12,
								}}
							>
								{caption || <>&nbsp;</>}
							</div>
						</>
					)
				}
			</Space>
		</Box>
	);
};

const StaffMissingResumesStatistics = () => {
	const className = 'StaffMissingResumesStatistics';

	const { isStaffView } = useAuthContext();

	const [statistics, setStatistics] = useState({});
	const [isLoading, setIsLoading] = useState(true);

	LocalDebug.logInfo(
		{ className },
	);

	const getStatistics = async () => {
		LocalDebug.logInfo({ className, method: 'getStatistics' });
		setIsLoading(true);
		try {
			const { data } = await request.get('/admin/user/resume-stats') || {};
			setStatistics(data);
		} catch (e) {}
		setIsLoading(false);
	};

	useEffect(() => {
		getStatistics();
	}, []);

	const statBoxes = [
		{ label: 'Registered', value: (item) => item?.registeredUsers },
		{ label: 'Onboarded', value: (item) => item?.onboardedUsers },
		// { label: 'Onboarded (v2)', value: (item) => item?.onboardedV2Users },
		{ label: '...& active or open', value: (item) => item?.seekingUsers },
		{ label: '...& with resume', value: (item) => item?.resumesUsers },
		{ label: '...& uploaded by user', value: (item) => item?.selfUploadUsers },
	];
	return [
		{
			title: 'All-time',
			source: statistics,
			boxes: statBoxes,
		},
		{
			title: 'Last 365 days',
			source: statistics.last365Days,
			boxes: statBoxes,
		},
		{
			title: 'Last 90 days',
			source: statistics.last90Days,
			boxes: statBoxes,
		},
		{
			title: 'Last 30 days',
			source: statistics.last30Days,
			boxes: statBoxes,
		},
		{
			title: 'Last 7 days',
			source: statistics.last7Days,
			boxes: statBoxes,
		},
	].map(({ title, source, boxes }, index) => (
		<div key={index}>
			<Divider orientation='left'>
				{title}
				{index === 0 && <>
					&nbsp;<JsonPopoverLink sortKeys={false} data={source} />
					&nbsp;<ReloadOutlined onClick={getStatistics} />
				</>}
			</Divider>
			<div
				style={{
					display: 'grid',
					gridTemplateColumns: `repeat(${boxes?.length}, 1fr)`,
					columnGap: 10,
					rowGap: 10,
				}}
			>
				{boxes.map(({ label, value }, boxIndex) => (
					<div key={boxIndex}>
						<StatBox
							title={<>{label}</>}
							isLoading={isLoading}
							value={value(source)}
							caption={boxIndex > 0
								? <>
									<b>{`${pct(value(source), boxes[boxIndex - 1].value(source))}%`}</b> of {boxes[boxIndex - 1].label}
								</>
								: null
							}
						/>
					</div>
				))}
			</div>
		</div>
	));
};

const StaffUserMissingResumesPage = () => {
	const className = 'StaffUserMissingResumesPage';

	LocalDebug.logInfo({ className });

	return (
		<PageLayout
			MenuClass={StaffMissingResumesMenu}
		>
			<Collapse
				items={
					[{
						label: 'Statistics',
						key: 1,
						children: <StaffMissingResumesStatistics />,
					}]
				}
			/>

			<Divider />

			<UserMissingResumesTable
				filterOptions={{
					isOnboarded: true,
					hasResume: false,
				}}
			/>
		</PageLayout>
	);
};

export default StaffUserMissingResumesPage;
