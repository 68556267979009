import { useTheme } from '@emotion/react';
import { Form, Input } from 'antd';
import Button from '../../components/app/button/Button';
import FormItem from '../../components/app/form/FormItem';
import { requiredRule, requiredTrimRule } from '../../utils/common';

const UpdatePasswordForm = ({
	withEmail = false,
	onSubmit,
}) => {
	const theme = useTheme();
	const [form] = Form.useForm();

	return (

		<div>
			<Form
				form={form}
				name='alert_form'
				onFinish={onSubmit}
			>

				{withEmail && (
					<FormItem
						label='Email'
						name='email'
						defaultContainer='div'
						rules={[
							{ ...requiredTrimRule, message: 'Please input a user\'s email' },
							{ type: 'email', message: 'Please input a valid email' },
						]}
						styles={{
							rootContainer: {
								display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: 20,
							},
							label: { marginBottom: 0, paddingRight: 10, width: 130 },
							itemContainer: { marginBottom: 0 },
							container: { flex: 1 },
							input: { height: 40 },
						}}
					/>
				)}
				<FormItem
					label='Password'
					name='password'
					defaultContainer='div'
					rules={[
						{ ...requiredRule, message: 'Please input the new password' },
					]}
					styles={{
						rootContainer: {
							display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: 20,
						},
						label: { marginBottom: 0, paddingRight: 10, width: 130 },
						itemContainer: { marginBottom: 0 },
						container: { flex: 1 },
						input: { height: 40 },
					}}
				>
					<Input.Password />
				</FormItem>
				<div>
					<Button
						type='secondary'
						style={{
							margin: 5,
							background: theme.color.orange,
							borderColor: theme.color.orange,
							color: theme.color.white,
							fontWeight: 'bold',
							borderRadius: 5,
						}}
						htmlType='submit'
					>
						Send
					</Button>
				</div>
			</Form>
		</div>
	);
};

export default UpdatePasswordForm;
