import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
	Col, Divider, Form, InputNumber, Row, Select, Space,
} from 'antd';
import {
	BOOLEAN_OPTIONS,
	BOOLEAN_OR_NULL_OPTIONS,
	COACHING_STATUS,
	LOGO_50INTECH,
	SOURCINGS_TAB_LABEL,
	USER_TAG_CATEGORIES,
	USER_TAG_CATEGORIES_FILTERS_RECRUITER,
	USER_TAG_CATEGORIES_FILTERS_STAFF,
	USER_TAG_JOB_SEARCH_JOB_ROLE_GROUP,
} from '../../constants/constant';
import { getTagsUsers } from '../../reducers/app';
import { objectFilterUndefinedValues, sortOn, capitalize } from '../../utils/common';
import { LocalDebug, localDebug } from '../../utils/LocalDebug';
import FormItem from '../company/form/FormItem';
import FormSelect from '../app/form/FormSelect';
import FilterModal from '../app/filters/FilterModal';
import { useAuthContext } from '../../hooks/useAuthContext';
import Callout from '../app/callout/Callout';
import { useTheme } from '@emotion/react';
import Logo50inTech from '../app/Logo50inTech';
import LocationFilter from '../app/filters/LocationFilter';
import Popin from '../app/modal/Popin';
import FormBoxTitle from '../app/form/FormBoxTitle';
import FormSubmitButton from '../app/form/FormSubmitButton';
import ModalContainerBase from '../../contexts/modals/ModalContainerBase';

const UserFilterModal = (
	{
		title,
		open,
		initialValues = {},
		onClose,
		onFilter,
	},
) => {
	const className = 'UserFilterModal';
	const labelColSpan = 7;

	const theme = useTheme();

	const { isStaffView } = useAuthContext();

	const [form] = Form.useForm();

	const tags = useSelector(getTagsUsers);

	const handleFilter = () => {
		form
			.validateFields()
			.then((values) => {
				const { goal, ...rest } = values;
				const tags = USER_TAG_CATEGORIES.reduce((prev, c) => {
					if (rest[c.value]) {
						return { ...prev, [c.value]: rest[c.value] };
					}
					return prev;
				}, {});

				if (goal) {
					tags['profile-goal'] = [goal];
				}

				const filtersValues = {
					...objectFilterUndefinedValues(values),
					...Object.keys(tags).length ? { tags } : {},
				};
				if (onFilter) onFilter(filtersValues);
			})
			.catch((errors) => {
				localDebug('Validate Failed:', errors);
			});
	};

	useEffect(() => {
		form.resetFields();
		form.setFieldsValue(initialValues);
	}, [form, initialValues]);

	const getTagsOptions = (category, noneValue = '-1', sortOnKey = null) => {
		const method = 'getTagsOptions';
		let options = [];
		const categoryTags = tags.find((tag) => tag.category === category);
		// LocalDebug.logInfo({ className, method}, category, categoryTags)
		if (categoryTags) {
			options = (categoryTags?.items || []);
			if (sortOnKey) options.sort(sortOn({ key: sortOnKey }));
			options = [
				...noneValue ? [{
					value: noneValue, label: <span style={{ fontStyle: 'italic' }}>Not provided</span>,
				}] : [],
				...options,
			];
		}
		return options;
	};

	const tagSelectOption = (option, index) => (
		<Select.Option
			key={option?.value || index}
			value={option?.value}
			label={option?.label}
		>
			{option?.label}
		</Select.Option>
	);

	const tagSelect = (category) => {
		const [noneOption, ...options] = getTagsOptions(category.value) || [];
		const groupedTags = {};
		options.forEach(({ group, ...rest }, index, arr) => {
			if (!group) return;
			groupedTags[group] = [
				...groupedTags[group] || [],
				rest,
			];
		});

		const content = <>
			{tagSelectOption(noneOption)}
			{Object.keys(groupedTags).length > 0
				? Object.entries(groupedTags).map(([group, tags], index) => <Select.OptGroup key={index}
									 label={getTagsOptions(USER_TAG_JOB_SEARCH_JOB_ROLE_GROUP.value).find((o) => o.value === group)?.label}
				>
					{tags.map(tagSelectOption)}
				</Select.OptGroup>)
				: options.map(tagSelectOption)
			}
		</>;

		return (
			<FormSelect options={options} mode="multiple">
				{content}
			</FormSelect>
		);
	};

	const tagFormItem = (category, key) => <Col span={24} key={key}>
		<FormItem
			key={category.value}
			labelCol={{ span: labelColSpan }}
			label={category.label}
			labelAlign='right'
			name={category.value}
		>
			{tagSelect(category)}
		</FormItem>
	</Col>;

	if (!(tags?.length > 0)) return null;

	return (
		<ModalContainerBase
			open={open}
			width={780}
			onCancel={onClose}
		>
			<Form
				form={form}
				name="user-filter-form"
				labelCol={{ span: labelColSpan }}
				labelAlign='right'
				className="user-filter-form"
				onFinish={handleFilter}
			>
				<FormBoxTitle
					title="Filter talents"
				>
					<Row>
						{USER_TAG_CATEGORIES_FILTERS_RECRUITER.map(tagFormItem)}
					</Row>

					{isStaffView
					&& <>
						<Divider/>
						<div style={{
							background: '#eee', borderRadius: 14, margin: '5', padding: 10, width: 'calc(100% + 0px)',
						}}>
							<Callout
								icon={<Logo50inTech size={22} style={{ marginTop: 0, marginBottom: -4 }} />}
								style={{ background: theme.background.gradientBluePink(315) }}
								withFeedback={false}
							>
								<b>50inTech - Staff filters</b>
							</Callout>
							<Row gutter={24}>
								{USER_TAG_CATEGORIES_FILTERS_STAFF.map(tagFormItem)}
								<Col span={12}>
									<FormItem
										label='Goal'
										name="goal"
									>
										<FormSelect
											options={getTagsOptions('profile-goal', '')}
											render={(option) => <Select.Option
												key={option.value} label={option.label}
												value={option.value}
											>
												{capitalize(option.label)}
											</Select.Option>}
										/>
									</FormItem>
								</Col>
								<Col span={12}>
									<FormItem
										label='Job seeking'
										tooltip='Whether the talent is actively looking, just open to new opportunities, or not looking at the moment.'
										name="status"
									>
										<FormSelect
											mode="multiple"
											options={getTagsOptions('career-seeking', null, null)}
											render={(option) => <Select.Option
												key={option.value}
												label={option.label}
												value={option.value}
											>
												{capitalize(option.label)}
											</Select.Option>}
										/>
									</FormItem>
								</Col>
								<Col span={12}>
									<FormItem
										label="Onboarded"
										name="isOnboarded"
									>
										<FormSelect options={BOOLEAN_OPTIONS}/>
									</FormItem>
								</Col>
								<Col span={12}>
									<FormItem
										label="Email Verified"
										name="emailVerified"
									>
										<FormSelect options={BOOLEAN_OR_NULL_OPTIONS}/>
									</FormItem>
								</Col>
								<Col span={12}>
									<FormItem
										label="Hunting consent"
										name="huntingConsent"
									>
										<FormSelect options={BOOLEAN_OR_NULL_OPTIONS}/>
									</FormItem>
								</Col>
								<Col span={12}>
									<FormItem
										label="Community consent"
										name="communityConsent"
									>
										<FormSelect options={BOOLEAN_OR_NULL_OPTIONS}/>
									</FormItem>
								</Col>
								<Col span={12}>
									<FormItem
										label="Resume uploaded"
										name="hasResume"
									>
										<FormSelect options={BOOLEAN_OPTIONS}/>
									</FormItem>
								</Col>
								<Col span={12}>
									<FormItem
										label="Mailjet data"
										name="mailjet"
									>
										<FormSelect options={BOOLEAN_OPTIONS}/>
									</FormItem>
								</Col>
								<Col span={12}>
									<FormItem
										label="Coaching status"
										name="coachingStatus"
									>
										<FormSelect mode="multiple" options={COACHING_STATUS.COACHING_STATUS_OPTIONS}
											placeholder="Coaching status"/>
									</FormItem>
								</Col>
								<Col span={12}>
									<FormItem
										label="Coach"
										name="coachId"
									>
										<FormSelect mode="multiple" options={COACHING_STATUS.COACHING_COACH_OPTIONS}
											placeholder="Coach"/>
									</FormItem>
								</Col>
								<Col span={12}>
									<FormItem
										label={SOURCINGS_TAB_LABEL}
										name="sourcings"
									>
										<InputNumber size='middle' style={{ width: 100 }} min={0} defaultValue={0}/>
									</FormItem>
								</Col>
								<Col span={12}>
									<FormItem
										label="Reads"
										name="reads"
									>
										<InputNumber size='middle' style={{ width: 100 }} min={0} defaultValue={0}/>
									</FormItem>
								</Col>
								<Col span={12}>
									<FormItem
										label="Applys"
										name="applys"
									>
										<InputNumber size='middle' style={{ width: 100 }} min={0} defaultValue={0}/>
									</FormItem>
								</Col>
							</Row>
						</div>
					</>
					}

					<Row>
						<Divider/>
						<Space style={{ width: '100%', justifyContent: 'flex-end' }}>
							<FormItem>
								<FormSubmitButton disabled={false} size='large'>
										Apply filter
								</FormSubmitButton>
							</FormItem>
						</Space>
					</Row>
				</FormBoxTitle>
			</Form>

		</ModalContainerBase>
	);
};

export default UserFilterModal;
