import { useEffect, useState } from 'react';
import {
	Select, Space,
} from 'antd';
import { useTheme } from '@emotion/react';
import AutoComplete from '../app/form/AutoComplete';
import { LocalDebug } from '../../utils/LocalDebug';
import { documentId } from '../../utils/common';
import CompanyService from '../../services/company';
import CompanyStub from '../company/CompanyStub';
import dayjs from 'dayjs';
import CompanyGenderScoreRingScore from '../company/genderscore/CompanyGenderScoreRingScore';
import { getGenderScoreSurveyTag } from '../../constants/property/genderscore-survey';
import { getGenderScoreStatusTag } from '../../constants/property/genderscore-status';

const SurveySelect = (
	{
		debounceTimeout = 800,
		searchOptions = {},
		value,
		defaultOptions = [],
		withoutSearch,
		disabled = false,
		onSearch,
		labelizer,
		onChange,
		sorter = (item) => item,
		...props
	},
) => {
	const className = 'SurveySelect';
	const theme = useTheme();
	const [options, setOptions] = useState(defaultOptions);
	const [surveys, setSurveys] = useState([]);

	const defaultOptionLabelizer = (option) => {
		return (
			<Space
				direction='vertical'
				size={0}
				style={{
					lineHeight: 'unset',
					justifyContent: 'center',
					width: '100%',
				}}
			>
				<Space
					style={{
						width: '100%',
						lineHeight: 'unset',
						justifyContent: 'space-between',
						alignItems: 'center',
					}}
				>
					<Space>
						<CompanyGenderScoreRingScore
							company={option}
							size={24}
							{...option?.genderScore?.globalScore > 0
								? {
									forceScore: option?.genderScore?.globalScore,
									value: option?.genderScore?.globalScore,
									...!option?.submittedAt ? {
										ringColor: '#999',
										style: {
											label: { color: '#999' },
											score: { color: '#999' },
										},
									} : {},
								}
								: {
									ringColor: '#eee',
									forceScore: <span style={{ color: '#aaa' }}>?</span>,
									value: 100,
								}
							}
						/>
						{getGenderScoreSurveyTag(option?.survey)}
						<CompanyStub
							company={option.company || option?.companyInfo}
							withTooltip={false}
							size={24}
						/>
					</Space>

					<Space>
						{getGenderScoreStatusTag(option?.status)}
						<div
							style={{
								width: '100%',
								fontSize: 12,
								color: theme.color.grey,
								textAlign: 'right',
							}}
						>
							{dayjs(option?.startedAt).format('DD/MM/YY HH:mm')}
						</div>
					</Space>
				</Space>
			</Space>
		);
	};

	const onSearchDefault = async (newOptions) => {
		let { data: { surveys: items } } = await CompanyService.listGenderScoreSurveys(newOptions);
		LocalDebug.logInfo({ className, method: 'onSearchDefault' }, { options: newOptions, items });
		items = sorter(items);
		setSurveys(items);
		return items;
	};

	const handleSearch = async (search) => {
		const method = 'handleSearch';
		LocalDebug.logInfo({ className, method }, { search });
		if (withoutSearch) return;
		const optionsSearch = {
			search,
			...searchOptions,
		};
		const items = onSearch
			? await onSearch(optionsSearch)
			: await onSearchDefault(optionsSearch);
		if (items) {
			setOptions(items);
		}
	};

	const handleChange = (v) => {
		onChange(documentId(v) ? v : surveys.find((o) => documentId(o) === v));
	};

	useEffect(() => {
		const initDefaultOptions = async (id) => {
			const { data } = await CompanyService.getGenderScoreSurvey(id);
			if (data?.survey) {
				setOptions([data?.survey]);
				handleChange(data?.survey);
			}
		};
		if (value && !options.find((o) => documentId(o) === value)) {
			initDefaultOptions(value);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [value]);

	return (
		<AutoComplete
			className={'survey-select'}
			onSearch={handleSearch}
			debounceTimeout={500}
			disabled={disabled}
			{...props}
			value={value}
			style={{ ...props?.style }}
			onChange={handleChange}
		>
			{options.map((option) => (
				<Select.Option
					key={documentId(option)}
					value={documentId(option)}
					label={(labelizer || defaultOptionLabelizer)(option)}
				>
					{(labelizer || defaultOptionLabelizer)(option)}
				</Select.Option>
			))}
		</AutoComplete>
	);
};

export default SurveySelect;
