/** @jsxImportSource @emotion/react */
import { useState } from 'react';

import { ENGLISH_LANG } from '../../../../constants/constant';
import ResourceModel from '../../../../models/ResourceModel';
import { LocalDebug } from '../../../../utils/LocalDebug';
import ModalContainerBase from '../../../../contexts/modals/ModalContainerBase';
import FormBoxTitle from '../../../app/form/FormBoxTitle';
import ResourceForm from './ResourceForm';

const ResourceFormModal = (
	{
		open,
		initialValues,
		onSubmit,
		onClose,
		title = 'Add Meta',
		setValues,
	},
) => {
	const className = 'ResourceFormModal';

	const [lang, setLang] = useState(ENGLISH_LANG);

	const onChange = (name, value) => {
		LocalDebug.logInfo({ className, method: 'onChange' }, { name, value });
		setValues(new ResourceModel({ ...initialValues, [name]: value }));
	};

	return (
		<ModalContainerBase
			open={open}
			onCancel={onClose}
			width={960}
		>
			<FormBoxTitle
				title={title}
			/>
			<div>
				<ResourceForm
					initialValues={initialValues}
					lang={lang}
					setLang={setLang}
					onChange={onChange}
					onSubmit={onSubmit}
				/>
			</div>
		</ModalContainerBase>
	);
};

export default ResourceFormModal;
