import { ENGLISH_LANG } from '../../../../constants/constant';
import CompanyRichTextEditor from '../shared/CompanyRichTextEditor';

const CompanyRecruitmentEditor = (
	{
		company,
		lang = ENGLISH_LANG,
		setEditorOpen,
	},
) => {
	const className = 'CompanyRecruitmentEditor';

	return (
		<CompanyRichTextEditor
			company={company}
			lang={lang}
			title={<b>Recruitment Process</b>}
			details={<span>
				Describe the recruitment process you offer to employees,
				it's best to provide a clear and positive overview
				of the steps involved
			</span>}
			field={'recruitment'}
			setEditorOpen={setEditorOpen}
		/>
	);
};

export default CompanyRecruitmentEditor;
