import { useTheme } from '@emotion/react';
import { AppDataContext } from '../../../contexts/AppDataProvider';
import { useContext, useEffect, useState } from 'react';
import { FaTimes } from 'react-icons/fa';
import { Button } from 'antd';
import { LocalDebug } from '../../../utils/LocalDebug';
import FeedbackBtnBuilder from '../../staff/product/feedback/FeedbackBtnBuilder';
import { useAuthContext } from '../../../hooks/useAuthContext';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router';
import InlineNavLink from '../button/InlineNavLink';

const BANNER_CATEGORIES = {
	announcement: 'announcement',
	warning: 'warning',
};
const AppLayoutBanner = (
	{ onClose },
) => {
	const className = 'AppLayoutBanner';

	const theme = useTheme();
	const navigate = useNavigate();

	const {
		appSettings,
		appBannerHeight,
		setAppBannerHeight,
		isAppBannerVisible,
	} = useContext(AppDataContext);

	const {
		currentUser,
	} = useAuthContext();

	const [icon, setIcon] = useState(null);
	const [text, setText] = useState(null);
	const [title, setTitle] = useState(null);
	const [category, setCategory] = useState(BANNER_CATEGORIES.announcement);
	const [withNew, setWithNew] = useState(false);
	const [ctaLabel, setCtaLabel] = useState(null);
	const [ctaPath, setCtaPath] = useState(null);
	const [withFeedback, setWithFeedback] = useState(false);

	useEffect(() => {
		try {
			const appBanner = JSON.parse(appSettings?.appBanner || '{}');
			LocalDebug.logUseEffect({ className, effects: 'appSettings' }, { appBanner });
			setCategory(appBanner?.category);
			setIcon(appBanner?.icon);
			setText(appBanner?.text);
			setTitle(appBanner?.title);
			setCtaLabel(appBanner?.ctaLabel);
			setCtaPath(appBanner?.ctaPath);
			setWithFeedback(appBanner?.withFeedback);
			setWithNew(appBanner?.withNew);
			if (appBanner?.title && appBanner?.text) {
				setAppBannerHeight(60);
			}
		} catch (error) {
			LocalDebug.logError({ className, method: 'useEffect', error });
		}
	}, [appSettings, currentUser]);

	return (
		<div
			className='app-banner'
			style={{
				position: 'fixed',
				zIndex: 20,
				padding: '10px 15px',
				display: 'flex',
				flexDirection: 'horizontal',
				justifyContent: 'space-between',
				alignItems: 'center',
				background: category === BANNER_CATEGORIES.announcement
					? theme.background.gradientPurpleGreen()
					: theme.background.gradientBluePink(),
				width: '100%',
				fontWeight: 'bold',
				height: appBannerHeight,
				textAlign: 'center',
				lineHeight: 1.2,
			}}>
			{icon && <span
				style={{ fontSize: 24 }}
			>
				{icon}
			</span>
			}
			<div style={{
				width: '80%',
				color: category === BANNER_CATEGORIES.announcement ? 'white' : 'default',
				fontSize: category === BANNER_CATEGORIES.announcement ? 16 : 13,
				opacity: category === BANNER_CATEGORIES.announcement ? 1 : 0.7,
			}}
			>
				{withNew && <>
					<span className='logoNew'>NEW</span>
						&nbsp;
				</>
				}
				{title && <span
					style={{ }}
					dangerouslySetInnerHTML={{ __html: title }}
				/>
				}
				{text && <span
					style={{ }}
					dangerouslySetInnerHTML={{ __html: text }}
				/>
				}
				{ctaLabel && <>&nbsp;<InlineNavLink
					path={ctaPath || '/'}
				>{ctaLabel}
				</InlineNavLink>
				</>
				}
			</div>

			{withFeedback && <FeedbackBtnBuilder
				title='Share your thoughts with us'
				initialValues={
					{
						payload: {
							category: 'general',
						},
					}
				}/>
			}
			<Button
				type='danger'
				onClick={onClose}
				style={{
					display: 'flex',
					flexDirection: 'horizontal',
					justifyContent: 'center',
					alignItems: 'center',
					padding: 4,
					borderRadius: 5,
					height: 'unset',
					color: category === BANNER_CATEGORIES.announcement ? 'white' : 'default',
				}}
			>
				<FaTimes size={18} />
			</Button>
		</div>
	);
};

export default AppLayoutBanner;
