import GenderScoreResultsNavLink from './GenderScoreResultsNavLink';
import { PILLAR_BREAKDOWN_ANCHOR_ID } from './GenderScoreResults';

const GenderScoreResultsPillarsLink = ({
	context,
	children,
	...props
}) => {
	return (
		<GenderScoreResultsNavLink
			context={context}
			path={`#${PILLAR_BREAKDOWN_ANCHOR_ID}`}
			style={{
				...props?.style,
			}}
		>
			{children || 'See pillars breakdown'}
		</GenderScoreResultsNavLink>
	);
};

export default GenderScoreResultsPillarsLink;
