import { request } from './api';

const getStages = async (id, params) => {
	return request.get(`/access/${id}/ats-stages`, { params });
};

const AccessService = {
	getStages,
};

export default AccessService;
