import { useTheme } from '@emotion/react';
import { Space } from 'antd';
import GenderScoreTitleBlock from './GenderScoreTitleBlock';
import GenderScoreComparisonLineBlock from './GenderScoreComparisonLineBlock';
import GenderScoreMedalBlock from './GenderScoreMedalBlock';
import BarGraph from '../graphs/BarGraph';

/**
 * @param {*} values - Values to render and mappers to get the data.
 * Format: {values, helpers:{xMapper, yMapper}}
 * @param {*} [graphColors] - Optional. Format: [from, to, rank].
 * If not set, use theme.chart.ranking
 * @param {number} [width] - Optional. Width of the chart. Default: 54
 * @param {number} [height] - Optional. Height of the chart. Default: 32
 * @param {number} [gap] - Optional. Distance between bars. Default: 0.3
 * @param {number} [barRadius] - Optional. Radius of the bars. Default: 4
 * @returns GenderScoreRankingProgressGraph component
 */
const GenderScoreRankingProgressGraph = ({
	values,
	width = 54,
	height = 32,
	graphColors,
	gap = 0.3,
	barRadius = 4,
	compareSecondaryLabel,
}) => {
	const theme = useTheme();
	const colors = graphColors || theme.chart.ranking;
	return <BarGraph
		data={values}
		width={width}
		height={height}
		graphColors={colors}
		withTopLabel={false}
		withBottomLabel={false}
		barRadius={barRadius}
		gap={gap}
		withTooltip={true}
		margin = {{
			top: 0, bottom: 0, left: 0, right: 0,
		}}
	/>;
};
/**
 * @param {string} title - Graph title
 * @param {*} progress - Values to render. Format: [{rank, month}]
 * @param {*} [graphColors] - Optional. Format: [from, to, rank].
 * If not set, use theme.chart.ranking
 * @param {number} [width] - Optional. Width of component. Default: 180
 * @param {number} [gap] - Optional. Distance between bars. Default: 0.3
 * @param {number} [fontSize] - Optional. Font size of the current value. Default: 40
 * @param {boolean} [maxBars] - Optional. Maximum number of bars to render. Default: 4
* @param {number} [chartGraphRatio] - Optional. Number between 0 and 1.
 * Ratio between the graph and the chart width. Default: 0.3
 * @param {number} [widthHeightGraphRatio] - Optional. Number between 0 and 1.
 * Ratio between the graph height and width. Default: 0.8
 * @returns
 */
const GenderScoreRankingBlock = ({
	title,
	value,
	progress,
	compareValue,
	compareSecondaryLabel,
	width = 180,
	gap = 0.3,
	fontSize = 40,
	maxBars = 4,
	chartGraphRatio = 0.3,
	widthHeightGraphRatio = 0.8,
	graphColors,
}) => {
	const className = 'GenderScoreRankingBlock';

	const theme = useTheme();
	const colors = graphColors || theme.chart.ranking;
	if (!value) {
		return null;
	}
	const progressToRender = progress.slice(0, maxBars);
	const barsData = {
		values: progressToRender,
		helpers: {
			xMapper: (d) => d.month,
			yMapper: (d) => +d.rank,
		},
	};
	const graphWidth = width * chartGraphRatio * (progress?.length || 0) / 4;
	const graphHeight = fontSize * widthHeightGraphRatio;

	return <div
		style={{
			display: 'grid',
			justifyContent: 'center',
		}}
	>
		<Space style={{
			justifyContent: 'center',
		}}>
			<GenderScoreMedalBlock
				value={value}
				fontSize={fontSize}
				height={graphHeight}
				color={colors.rank}
			/>
			<GenderScoreRankingProgressGraph
				values={barsData}
				width={graphWidth}
				height={graphHeight}
				graphColors={colors}
				gap={gap}
			/>
		</Space>
		<GenderScoreTitleBlock
			title={title}
			isMainTitle={true}
			style={{
				textAlign: 'center',
				fontSize: 14,
				marginBottom: title?.length > 20 ? 0 : '1.3em',
			}}
		/>

		{compareValue && (
			<GenderScoreComparisonLineBlock
				previous={compareValue}
				diffLabel={<></>}
				current={value}
				isRanking={true}
				withPrevious={true}
				secondaryLabel={compareSecondaryLabel}
				tooltipLabel={'Last submission'}
			/>
		)}
	</div>;
};
export default GenderScoreRankingBlock;
