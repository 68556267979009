import PageLayout from '../../components/app/layout/PageLayout';
import { useEffect, useState } from 'react';
import {
	addMultipleQueryParams, documentId, getQueryParams, removeQueryParams,
} from '../../utils/common';
import { localDebug } from '../../utils/LocalDebug';
import MessageFilterModal from '../../components/messages/MessageFilterModal';
import FilterButton from '../../components/app/filters/FilterButton';
import InlineNavLink from '../../components/app/button/InlineNavLink';
import { useSelector } from 'react-redux';
import { getCompanySelected } from '../../reducers/app';
import { useAuthContext } from '../../hooks/useAuthContext';
import { FaChevronRight } from 'react-icons/fa';
import MessageNewTable from '../../components/messages/MessageNewTable';
import GenderhireMessagesMenu from '../../menu/genderhire/GenderhireMessagesMenu';
import ConfigureEmailTemplatesMenu from '../../menu/configure/ConfigureEmailTemplatesMenu';
import ConfigureMenu from '../../menu/configure/ConfigureMenu';

const MessagesPage = () => {
	const companySelected = useSelector(getCompanySelected);

	const queryParams = getQueryParams();

	const { isAdmin, isStaffView } = useAuthContext();

	const [optionsFilterTable, setOptionsFilterTable] = useState({
		companyId: documentId(companySelected),
	});
	const [isRefreshDataLayout, setIsRefreshDataLayout] = useState(0);

	useEffect(() => {
		localDebug('Messages.useEffect[companySelected]', documentId(companySelected));
		setOptionsFilterTable((prev) => ({
			...prev,
			...queryParams,
			companyId: documentId(companySelected),
		}));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [companySelected]);

	// --------------------------------------- //
	// ------------ handle filter ------------ //
	// --------------------------------------- //
	const handleFilter = (values) => {
		localDebug('Messages.handleFilter()', { values });
		const {
			companyId, createdAfter, createdBefore, ...rest
		} = values;
		addMultipleQueryParams(rest);
		setOptionsFilterTable((prev) => ({
			...prev,
			...rest,
			createdAfter,
			createdBefore,
			companyId: documentId(companySelected),
		}));
	};

	const handleClearFilter = () => {
		localDebug('Messages.handleClearFilter()', {});
		setOptionsFilterTable({ companyId: documentId(companySelected) });
		removeQueryParams();
	};

	return (
		<PageLayout
			MenuClass={GenderhireMessagesMenu}
			subtitle={(
				<div>
					You can customize your message templates in
					the {new ConfigureEmailTemplatesMenu()
						.getInlineNavLink({ parentMenus: new ConfigureMenu() })
					} section.
				</div>
			)}
			rightActions={(
				isAdmin && isStaffView && (
					<InlineNavLink
						path={'/staff/tech/user-mailings'}
						style={{ span: { textDecoration: 'none' } }}
					>
						📮 UserMailings
						<FaChevronRight style={{ fontSize: 10, marginBottom: -1 }} />
					</InlineNavLink>
				))}
			filter={isStaffView && (
				<FilterButton
					title="Filter list"
					modal={MessageFilterModal}
					onFilter={handleFilter}
					onClearFilter={handleClearFilter}
					initialValues={optionsFilterTable}
				/>
			)}
		>
			{/* <MessageTable
				filterOptions={optionsFilterTable}
				isRefresh={isRefreshDataLayout}
			/> */}
			<MessageNewTable
				filterOptions={optionsFilterTable}
				isRefresh={isRefreshDataLayout}
			/>
		</PageLayout>
	);
};

export default MessagesPage;
