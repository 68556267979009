import { isStringTypeValidator } from '../../utils/isStringTypeValidator';

const EMPLOYMENT_VISA_KEY = 'employment-visa';

const EMPLOYMENT_VISA_ANSWER_EU_VISA_REQUIRED = 'eu-visa-required';
const EMPLOYMENT_VISA_ANSWER_UK_VISA_REQUIRED = 'uk-visa-required';
const EMPLOYMENT_VISA_ANSWER_US_VISA_REQUIRED = 'us-visa-required';
const EMPLOYMENT_VISA_ANSWER_NO_VISA_REQUIRED = 'no-visa-required';

export const EMPLOYMENT_VISA_OPTION_EU = {
	value: EMPLOYMENT_VISA_ANSWER_EU_VISA_REQUIRED,
	label: 'For the EU\xa0🇪🇺',
	eligibilityLabel: 'In the EU 🇪🇺',
};
export const EMPLOYMENT_VISA_OPTION_UK = {
	value: EMPLOYMENT_VISA_ANSWER_UK_VISA_REQUIRED,
	label: 'For the UK\xa0🇬🇧',
	eligibilityLabel: 'In the UK 🇬🇧',
};
export const EMPLOYMENT_VISA_OPTION_US = {
	value: EMPLOYMENT_VISA_ANSWER_US_VISA_REQUIRED,
	label: 'For the US\xa0🇺🇸',
	eligibilityLabel: 'In the US 🇺🇸',
};
export const EMPLOYMENT_VISA_OPTION_NO = {
	value: EMPLOYMENT_VISA_ANSWER_NO_VISA_REQUIRED,
	label: 'Doesn\'t need a visa',
	eligibilityLabel: 'Anywhere 🌎',
};

export const EMPLOYMENT_VISA_OPTIONS = [
	EMPLOYMENT_VISA_OPTION_EU,
	EMPLOYMENT_VISA_OPTION_UK,
	EMPLOYMENT_VISA_OPTION_US,
	EMPLOYMENT_VISA_OPTION_NO,
];
export const EMPLOYMENT_VISA_FILTER_OPTIONS = [
	EMPLOYMENT_VISA_OPTION_EU,
	EMPLOYMENT_VISA_OPTION_UK,
	EMPLOYMENT_VISA_OPTION_US,
];
const customValidator = (value) => EMPLOYMENT_VISA_OPTIONS.map((o) => o.value).includes(value);

const EMPLOYMENT_VISA_VALIDATORS = [
	isStringTypeValidator,
	customValidator,
];

export default {
	EMPLOYMENT_VISA_ANSWER_EU_VISA_REQUIRED,
	EMPLOYMENT_VISA_ANSWER_UK_VISA_REQUIRED,
	EMPLOYMENT_VISA_ANSWER_US_VISA_REQUIRED,
	EMPLOYMENT_VISA_ANSWER_NO_VISA_REQUIRED,

	key: EMPLOYMENT_VISA_KEY,
	EMPLOYMENT_VISA_KEY,
	validators: EMPLOYMENT_VISA_VALIDATORS,
	EMPLOYMENT_VISA_VALIDATORS,
	options: EMPLOYMENT_VISA_OPTIONS,
	EMPLOYMENT_VISA_OPTIONS,
	EMPLOYMENT_VISA_FILTER_OPTIONS,
};
