import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { LocalDebug } from '../utils/LocalDebug';
import { arrayWrap, objectStripUndefinedValues } from '../utils/common';

export const UserSearchFiltersContext = React.createContext();

const UserSearchFiltersProvider = ({ children }) => {
	const className = 'UserSearchFiltersProvider';

	// Params that can be used in the URL query
	const allowedQueryParams = [
		'job-search-job-role',
		'career-role',
		'job-search-job-level',
		'career-experience',
		'career-hard-skill',
		'career-language',
		'language',
		'job-search-job-remote',
		'location',
		'job-search-job-location',
		'job-search-employment-visa',
		'hideVisa',
		'sortField',
		'sortOrder',
	];
	// Filters that can be rendered in the filter tags
	const allowedRenderTags = [
		'job-search-job-role',
		'career-role',
		'job-search-job-level',
		'career-experience',
		'career-hard-skill',
		'career-language',
		'language',
		'job-search-job-remote',
		'location',
		'job-search-job-location',
		'job-search-employment-visa',
		'hideVisa',
		'sortField',
		'sortOrder',
	];
	const [sortField, setSortField] = useState(null);
	const [sortOrder, setSortOrder] = useState(null);
	const [filters, setFilters] = useState([]);
	const [invalidated, setInvalidated] = useState(false);
	const [shouldRefresh, setShouldRefresh] = useState(0);
	const [selectedFilters, setSelectedFilters] = useState({});
	const [activeSavedSearch, setActiveSavedSearch] = useState(null);
	const [time, setTime] = useState(1000);

	const processShouldRefresh = () => {
		LocalDebug.logInfo({ className, method: 'processShouldRefresh' }, { filters });
		const { sortField, sortOrder, ...rest } = selectedFilters;
		setFilters(rest);
		setSortField(selectedFilters.sortField);
		setSortOrder(selectedFilters.sortOrder);

		setShouldRefresh((p) => p + 1);
	};

	useEffect(() => {
		LocalDebug.logNull({ className, effects: 'invalidated, selectedFilters' }, {
			invalidated, selectedFilters, shouldRefresh,
		});
		if (invalidated) {
			LocalDebug.logNull({ className, effects: 'invalidated' }, { invalidated, shouldRefresh });
			setTimeout(processShouldRefresh, 1);
			// setTime(p => p + 1000);
			setInvalidated(false);
		}
	}, [invalidated, selectedFilters]);

	const updateFilters = (newFilters) => {
		// const sanitizedFilters = objectStripUndefinedValues(newFilters);
		LocalDebug.logNull({ className, method: 'updateFilters' }, { newFilters, selectedFilters });
		setSelectedFilters((prev) => cleanQueryParams({
			...prev || {},
			...newFilters || {},
		}, true) || {});
		setInvalidated(true);
	};

	const cleanQueryParams = (params, removeNull, renderTags = false) => {
		const method = 'cleanQueryParams';
		const allowedParams = renderTags ? allowedRenderTags : allowedQueryParams;
		const cleanedParams = allowedParams
			.filter((param) => !removeNull || arrayWrap(params?.[param] || [])?.length > 0)
			.map((param) => ({ [param]: arrayWrap(arrayWrap(params?.[param])?.map((item) => item?.split?.(',')).flat())?.map((item) => item?.split?.(',')).flat() }))
			.reduce((p, c) => ({ ...p || {}, ...c || {} }), {});

		LocalDebug.logNull({ className, method }, {
			allowedParams, params, removeNull, cleanedParams,
		});
		return cleanedParams;
	};

	const applyQueryParams = (queryParams) => {
		const method = 'applyQueryParams';
		LocalDebug.logNull({ className, method }, { queryParams, str: JSON.parse(JSON.stringify(queryParams)) });
		const cleanedQueryParams = cleanQueryParams(queryParams);
		const hasQueryValues = allowedQueryParams.map((param) => queryParams?.[param]).filter((v) => v).length;

		LocalDebug.logNull({ className, method }, { queryParams, cleanedQueryParams, hasQueryValues });

		if (hasQueryValues) {
			allowedQueryParams.forEach((param) => delete queryParams?.[param]);
			// delete queryParams['job-search-job-role'];
			delete queryParams.access;
			const { sortField, sortOrder, ...rest } = cleanedQueryParams;
			const queryFilters = {
				...Object.entries(rest).map(([key, value]) => ({ [key]: arrayWrap(value) })).reduce((p, c) => ({ ...p, ...c }), {}),
				...sortField,
				...sortOrder,
			};
			LocalDebug.logInfo({ className, method }, {
				queryFilters, queryParams, str: JSON.parse(JSON.stringify(queryParams)),
			});
			updateFilters(queryFilters);
			return true;
		}
	};

	return (
		<UserSearchFiltersContext.Provider
			value={{
				allowedQueryParams,
				allowedRenderTags,
				cleanQueryParams,
				updateFilters,
				shouldRefresh,
				applyQueryParams,
				filters,
				sortField,
				setSortField,
				sortOrder,
				setSortOrder,
				activeSavedSearch,
				setActiveSavedSearch,
			}}
		>
			{children}
		</UserSearchFiltersContext.Provider>
	);
};

export default UserSearchFiltersProvider;
