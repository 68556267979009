import { useTheme } from '@emotion/react';
import CompanyModel from '../../../../../models/CompanyModel';
import GenderScoreComparisonLineBlock from '../blocks/GenderScoreComparisonLineBlock';
import { FaMedal } from 'react-icons/fa';
import { documentId } from '../../../../../utils/common';

const CompanyGenderScoreGlobalComparisonLineBox = ({
	company,
	width = 150,
	isRanking = false,
}) => {
	const theme = useTheme();
	const companyModel = new CompanyModel(company);
	if (!companyModel) {
		return null;
	}
	const [previousSurvey] = companyModel.getGenderScorePreviousSurveys() || [];

	if (!previousSurvey) return null;

	if (isRanking) {
		const getRank = ({ statistics, defaultValue }) => {
			return (
				statistics?.rankings?.globalScore
					?.find((r) => documentId(r)?.toString?.() === documentId(company)?.toString?.())
					?.rank
			) || defaultValue;
		};
		const currentRank = getRank({
			statistics: companyModel.genderScore?.currentStatistics,
			defaultValue: 0,
		});
		const previousRank = getRank({
			statistics: previousSurvey?.statistics,
			defaultValue: currentRank,
		});

		return <GenderScoreComparisonLineBlock
			current={currentRank}
			previous={previousRank}
			mainColor={theme.chart.ranking.rank}
			isRanking={true}
			withWrappedContent={true}
			withOneColor={true}
			width={width}
			fontSize={16}
			fontRatio={0.7}
			diffIcon={FaMedal}
		/>;
	}

	const currentScore = companyModel.genderScore?.globalScore;
	const previousScore = previousSurvey?.genderScore?.globalScore || currentScore || 0;
	return <GenderScoreComparisonLineBlock
		current={currentScore}
		previous={previousScore}
		withWrappedContent={true}
		withOneColor={true}
		width={width}
		fontSize={16}
		fontRatio={0.7}
		diffLabel={'pts'}
	/>;
};
export default CompanyGenderScoreGlobalComparisonLineBox;
