import { Tooltip } from 'antd';
import { useAuthContext } from '../../../hooks/useAuthContext';
import UserModel from '../../../models/UserModel';

const UserCoachedTag = (
	{
		user,
		...props
	},
) => {
	const { isStaffView } = useAuthContext();

	if (!new UserModel(user)?.isCoachingVisible({ isStaffView })) return null;

	return (
		<Tooltip
			title='Profile pre-screened by 50inTech'
		>
			<span style={{
				userSelect: 'none',
				...props?.style,
			}}>
				🚨
			</span>
		</Tooltip>
	);
};

export default UserCoachedTag;
