import UserAvatar from '../../widgets/UserAvatar';
import { Space } from 'antd';
import { useTheme } from '@emotion/react';
import UserGridCardLastSeen from './blocks/UserGridCardLastSeen';
import UserGridCardCareerSeeking from './blocks/UserGridCardCareerSeeking';
import UserDEICriteria from '../../widgets/UserDEICriteria';
import UserGridCardNewPill from './UserGridCardNewPill';

const UserGridCardLeftColumn = (
	{
		user,
		withIsNew = true,
		deiBgColor,
		...props
	},
) => {
	const className = 'UserGridCardLeftColumn';

	const theme = useTheme();

	return (
		<div>

			<Space
				direction='vertical'
				size={32}
				align='top'
				style={{
					width: '100%',
					justifyContent: 'center',
					alignItems: 'center',
					textAlign: 'center',
				}}
			>

				<UserAvatar
					user={user}
					size={120}
					withTooltip={false}
					withIsNew={false}
					withIsUnviewed={false}
				/>

				<Space
					direction='vertical'
					size={12}
					style={{
						width: '100%',
						justifyContent: 'center',
						alignItems: 'center',
						textAlign: 'center',
					}}
				>

					<UserGridCardLastSeen
						orientation='vertical'
						style={{ width: '100%' }}
						user={user}
					/>

					<UserGridCardCareerSeeking
						style={{ display: 'block', width: '100%' }}
						orientation='vertical'
						user={user}
					/>

					<UserDEICriteria
						mode='list'
						user={user}
						bgColor={deiBgColor}
						style={{
							list: {},
							listItem: {
								fontSize: 12,
							},
						}}
					/>

				</Space>

			</Space>
		</div>
	);
};

export default UserGridCardLeftColumn;
