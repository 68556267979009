export const ACL_ROLE_50_ADMIN_LABEL = 'admin';
export const ACL_ROLE_50_STAFF_LABEL = 'staff';
export const ACL_ROLE_50_EDITOR_LABEL = 'editor';
export const ACL_ROLE_50_COACH_LABEL = 'talent';
export const ACL_ROLE_50_TESTER_LABEL = 'tester';
export const ACL_ROLE_50_DEMO_LABEL = 'demo';
export const ACL_ROLE_COMPANY_RECRUITER_LABEL = 'company-recruiter';
export const ACL_ROLE_DOCUMENT_ADMIN_LABEL = 'document-admin';
export const ACL_ROLE_DOCUMENT_EDITOR_LABEL = 'document-editor';
export const ACL_ROLE_UMS_ADMIN = 'ums-admin';
export const ACL_ROLE_UMS_MANAGER = 'ums-manager';
export const ACL_ROLE_UMS_EMPLOYEE = 'ums-employee';

export const DEFAULT_ACL_PASSWORD = '50inTech$';

export const ACL_STAFF_LIST = [
	ACL_ROLE_50_ADMIN_LABEL,
	ACL_ROLE_50_STAFF_LABEL,
	ACL_ROLE_50_COACH_LABEL,
];

export const ACL_INTERNAL_LABELS = [
	...new Set([
		...ACL_STAFF_LIST,
		ACL_ROLE_50_EDITOR_LABEL,
		ACL_ROLE_50_TESTER_LABEL,
		ACL_ROLE_50_DEMO_LABEL,
	]),
];

export const ACL_EXTERNAL_LABELS = [
	ACL_ROLE_DOCUMENT_ADMIN_LABEL,
	ACL_ROLE_DOCUMENT_EDITOR_LABEL,
	ACL_ROLE_COMPANY_RECRUITER_LABEL,
	ACL_ROLE_UMS_ADMIN,
	ACL_ROLE_UMS_MANAGER,
	ACL_ROLE_UMS_EMPLOYEE,
];

export const ACL_ALL_LABELS = [
	...ACL_INTERNAL_LABELS,
	...ACL_EXTERNAL_LABELS,
];

export const ACL_ROLE_DISPLAYS = {
	[ACL_ROLE_DOCUMENT_ADMIN_LABEL]: 'ADMIN',
	[ACL_ROLE_DOCUMENT_EDITOR_LABEL]: 'EDITOR',
	[ACL_ROLE_COMPANY_RECRUITER_LABEL]: 'RECRUITER',
	[ACL_ROLE_UMS_ADMIN]: 'UMS ADMIN',
	[ACL_ROLE_UMS_MANAGER]: 'UMS MANAGER',
	[ACL_ROLE_UMS_EMPLOYEE]: 'UMS EMPLOYEE',
};
