import { useTheme } from '@emotion/react';
import { openExternalUrl, relabelAtsProvider } from '../../../utils/common';
import { Button, Tooltip } from 'antd';
import AtsLogo from '../../ats/AtsLogo';
import { BiLinkExternal } from 'react-icons/bi';
import moment from 'moment';
import { DATE_FORMAT } from '../../../constants/constant';
import SourcingModel from '../../../models/SourcingModel';

const SourcingATSOpenSmallButton = (
	{
		sourcing,
		title = 'Open in ATS',
		withLogo = true,
		withTooltip = true,
		...props
	},
) => {
	const className = 'SourcingATSOpenSmallButton';

	const theme = useTheme();

	const sourcingModel = new SourcingModel(sourcing);

	if (!sourcingModel.isAtsLinked) {
		return null;
	}

	const tooltip = <>
		<div><b>Open in {relabelAtsProvider(sourcingModel.atsProvider)}</b></div>
		<div style={{ fontSize: 10 }}>
            First pushed: {moment(sourcingModel.atsSentAt).format(DATE_FORMAT)}
		</div>
	</>;

	const atsLogo = <span><AtsLogo ats={sourcingModel.atsProvider} size={16} withTooltip={false}/></span>;

	return (
		<Tooltip title={tooltip}>
			<Button
				icon={withLogo ? atsLogo : <BiLinkExternal style={{ marginBottom: -2 }} />}
				onClick={() => { openExternalUrl(sourcingModel.openAtsUrl); }}
			>
				{title && <>&nbsp;{title}</>}
			</Button>
		</Tooltip>
	);
};

export default SourcingATSOpenSmallButton;
