import { useEffect, useState } from 'react';
import { useTheme } from '@emotion/react';
import { ENGLISH_LANG } from '../../../../constants/constant';
import AddButton from '../../../app/button/AddButton';
import DraggableTable from '../../../app/table/DraggableTable';
import TableColumns from '../../../app/table/TableColumns';
import { LocalDebug } from '../../../../utils/LocalDebug';
import { request } from '../../../../services/api';
import CompanyFieldEditor from '../shared/CompanyFieldEditor';
import ArticleService from '../../../../services/article';
import ArticleModel from '../../../../models/ArticleModel';
import ArticleImage from '../../../staff/content/articles/ArticleImage';
import config from '../../../../config/config';
import CompanyStoriesAddModal from './CompanyStoriesAddModal';
import { documentId } from '../../../../utils/common';

const CompanyStoriesEditor = (
	{
		company,
		lang = ENGLISH_LANG,
		setEditorOpen,
	},
) => {
	const className = 'CompanyStoriesEditor';

	const theme = useTheme();

	const field = 'articles';

	const [editedValue, setEditedValue] = useState(company?.[field]);
	const [dataSource, setDataSource] = useState([]);
	const [modalOpen, setModalOpen] = useState(false);

	useEffect(async () => {
		const { data: { items } } = await request.get(`/company/${documentId(company)}/stories`);
		setDataSource(translateData(items || []));
	}, [company]);

	useEffect(() => {
		setEditedValue(dataSource?.map((u) => documentId(u)) || []);
	}, [dataSource]);

	const handleUp = (index) => {
		const startIndex = index;
		const endIndex = index === 0 ? editedValue.length - 1 : index - 1;
		handleOrderRow(startIndex, endIndex);
	};

	const handleDown = (index) => {
		const startIndex = index;
		const endIndex = index + 1 === editedValue.length ? 0 : index + 1;
		handleOrderRow(startIndex, endIndex);
	};

	const handleOrderRow = (startIndex, endIndex) => {
		const newDataSource = [...dataSource];
		const tempValue = newDataSource[startIndex];
		newDataSource[startIndex] = newDataSource[endIndex];
		newDataSource[endIndex] = tempValue;
		setDataSource(newDataSource);
	};

	const translateData = (data) => data.map((item) => {
		const article = (new ArticleModel(item)).translate(lang);
		return { ...article, id: documentId(item) };
	});

	const handleAdd = async (values) => {
		const { articleId } = values;
		const { data: { articles } } = await ArticleService.findAll({ ids: [articleId] });
		setDataSource((prev) => [...prev || [], ...translateData(articles)]);
		setModalOpen(false);
	};

	const handleRemove = (row) => {
		setDataSource(dataSource.filter((item) => documentId(item) !== documentId(row)));
	};

	// --------------------------------------------- //
	// --------------- columns --------------------- //
	// --------------------------------------------- //

	const columns = [
		{
			title: 'Image',
			dataIndex: `illustration_${lang}`,
			render: (value, row) => (
				<ArticleImage lang={lang} article={row} size={140} />
			),
			...TableColumns.columnFixedWidth(156),
		},
		{
			title: 'Title',
			dataIndex: `title_${lang}`,
			render: (value, row) => (
				<a
					href={`${config.appRoot}/article/${row?.slug}`}
					target='_blank'
					rel='noreferrer'
					className='link'
					style={{ fontSize: 14, fontWeight: 700, lineHeight: 1.3 }}
				>
					{value}
				</a>
			),
			...TableColumns.columnMinWidth(300),
		},
		{
			title: 'Topic',
			dataIndex: 'topic',
			align: 'center',
			render: (value, row) => <b
				style={{ textTransform: 'uppercase', fontWeight: 700, color: theme.color.darkgrey }}>{value}</b>,
			...TableColumns.columnMinWidth(70),
		},
		TableColumns.userAvatarColumn(theme, { title: 'Author' }),
		TableColumns.dateColumn(theme, { title: 'Published at', dataIndex: 'publishedAt', sorter: false }),
	];

	return (
		<CompanyFieldEditor
			company={company}
			setEditorOpen={setEditorOpen}
			title='Diversity Stories'
			field={field}
			editedValue={editedValue}
		>
			<AddButton
				style={{ margin: '10px 0' }}
				onClick={() => setModalOpen(true)}
				title='Add a diversity story'
			/>

			<DraggableTable
				dataSource={dataSource}
				columns={columns}
				onDragEnd={handleOrderRow}
				onMoveRowUp={handleUp}
				onMoveRowDown={handleDown}
				onDeleteRow={handleRemove}
			/>

			<CompanyStoriesAddModal
				open={modalOpen}
				onSave={handleAdd}
				handleClose={() => setModalOpen(false)}
				initialValues={{ notArticleIds: dataSource.map((item) => documentId(item)) }}
			/>

		</CompanyFieldEditor>
	);
};

export default CompanyStoriesEditor;
