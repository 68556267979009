import { Avatar, Space, Tooltip } from 'antd';
import CompanyLogo from './CompanyLogo';
import { useTheme } from '@emotion/react';

const ListLimiter = (
	{
		data = [],
		builder,
		builderTooltip,
		size = 36,
		overflow = 0,
		wrap = true,
		limit = 3,
		withItemTooltip = true,
		withMoreTooltip = true,
		...props
	},
) => {
	const theme = useTheme();

	const displayedItems = data?.slice(0, limit)
		.map((item, index) => (
			<div key={index} style={{ marginRight: overflow }}>
				{builder({ data: item })}
			</div>
		));

	const moreItem = <Avatar
		shape={'square'}
		style={{
			margin: 0,
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
			width: size,
			height: size,
			background: theme.color.darkgrey,
			color: theme.color.white,
			borderRadius: 5,
			fontWeight: 'bold',
			fontSize: size / 3,
			lineHeight: 1,
			userSelect: 'none',
		}}
	>
		<div>
			<span>+{data?.length - limit}</span>
		</div>
	</Avatar>;

	return (
		<Space
			direction='horizontal'
			size={[0, overflow]}
			wrap={wrap}
			style={{ ...props?.style }}
		>
			{displayedItems}
			{data?.slice(limit).length > 0 && (
				withMoreTooltip
					? (
						<Tooltip title={(
							<Space
								align={'center'}
								direction='horizontal'
								size={overflow}
								wrap={wrap}
								style={{ ...props?.style }}
							>
								{data?.slice(limit)
									.map((item, index) => (
										(builderTooltip || builder)({ data: item, style: { marginRight: overflow } })
									))
								}
							</Space>
						)}>
							{moreItem}
						</Tooltip>
					)
					: moreItem
			)}
		</Space>
	);
};

export default ListLimiter;
