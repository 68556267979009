import { ENGLISH_LANG } from '../../constants/constant';

/**
 * Returns the meta title tag from the metaHolder collection
 * @param {*} metaHolder ArticleModel or CompanyModel  object
 * @param {*} locale
 * @returns meta tag if it exists. It can return undefined
 */
export const getDBMetaTitle = (metaHolder, locale) => {
	return locale === ENGLISH_LANG
		? metaHolder?.meta?.title
		: metaHolder?.meta?.[locale]?.title;
};

/**
 * Returns the meta description tag from the metaHolder collection
 * @param {*} metaHolder ArticleModel or CompanyModel  object
 * @param {*} locale
 * @returns meta tag if it exists. It can return undefined
 */
export const getDBMetaDescription = (metaHolder, locale) => {
	return locale === ENGLISH_LANG
		? metaHolder?.meta?.description
		: metaHolder?.meta?.[locale]?.description;
};

/**
 * Returns the meta tag for the article publisher,
 * @param {*} articleModel
 * @returns meta tag if it exists. It can return undefined
 */
export const getDBArticleMetaPublisher = (articleModel) => {
	return articleModel?.meta?.isCustom && articleModel?.meta?.publisher;
};

/**
 * Returns the meta tag for the metaHolder's image alternative text
 * @param {*} metaHolder
 * @param {*} locale
 * @returns meta tag if it exists. It can return undefined
 */
export const getDBMetaAltImage = (metaHolder, locale) => {
	return locale === ENGLISH_LANG
		? metaHolder?.meta?.altImage
		: metaHolder?.meta?.[locale]?.altImage;
};
