import { Space, Tag as AntTag } from 'antd';
import { useAuthContext } from '../../hooks/useAuthContext';
import { capitalize } from '../../utils/common';

const SourcingArchiveReasonTags = (
	{
		sourcing,
		onClick,
	},
) => {
	const className = 'SourcingArchiveReasonTags';

	const { isAtsSyncable } = useAuthContext();

	const archiveReasonsContent = null;

	const reasons = [...new Set([
		...sourcing?.archiveReasons?.payload?.raison || [], // Levea storage
		...sourcing?.archiveReasons?.payload?.tags || [], // Legacy storage (hashtag format)
		...sourcing?.archiveReasons?.payload?.archiveReasons || [], // New storage
		sourcing?.atsArchiveReason,
	]
		.filter((item) => item)
		.map((item) => capitalize(item)))];

	return reasons?.length > 0
        && <Space
        	wrap={true}
        	align='center'
        	style={{ justifyContent: 'center' }}
        	size={5}
        >
        	{reasons
        		.map((reason, key) => <AntTag
        				color="orange"
        				key={key}
        				style={{ fontWeight: 'bold', margin: 0 }}
        			>
        				{reason}
        			</AntTag>)
        	}
        </Space>;
};

export default SourcingArchiveReasonTags;
