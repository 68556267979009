import {
	ArrowRightOutlined,
	MessageOutlined, SearchOutlined, StopOutlined, UserOutlined,
} from '@ant-design/icons';
import { useTheme } from '@emotion/react';
import {
	Modal, Space,
	message,
} from 'antd';
import moment from 'moment';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { resendInvitation } from '../../actions/acls';
import { useAuthContext } from '../../hooks/useAuthContext';
import UserMessageModel from '../../models/UserMessageModel';
import Link from '../app/Link';
import Table from '../app/table/Table';
import TableColumns from '../app/table/TableColumns';
import { arrayWrap } from '../../utils/common';

const ReportsTable = (
	{
		onDataLoaded,
		path = '/recruiter/report',
		filterOptions = {},
		isRefresh = 0,
		...props
	},
) => {
	const className = 'ReportsTable';

	const theme = useTheme();
	const dispatch = useDispatch();

	const { isAdmin, isStaff, isStaffView } = useAuthContext();

	const [isRefreshDataLayout, setIsRefreshDataLayout] = useState(0);

	const handleResendInvitation = (row) => {
		Modal.confirm({
			title: 'Are you sure you want to resend an invitation to this user?',
			okText: 'Yes',
			cancelText: 'No',
			onOk() {
				row.isResent = true;
				dispatch(resendInvitation(row, () => {
					message.success('The invitation was sent again');
				}));
			},
		});
	};

	const isLogged = (row) => !!row?.lastSessionAt;

	const jsonCol = TableColumns.jsonColumn(theme, { title: '', ...TableColumns.columnFixedWidth(50) });

	const userColProps = {
		sorter: false,
		avatarSize: 0,
		withProfession: false,
		withLocation: false,
		withDEICriteria: false,
		withEmail: false,
		withBox: false,
		withDropdown: false,
		withPreview: false,
		withIsNew: false,
		withIsUnviewed: false,
		withTooltip: false,
		withIsNewlyOnboarded: false,
		withCoachedTag: false,
		withResumeTag: false,
		colWidth: 260,
		style: {
			fontSize: 12,
		},
	};

	const userStubCol = TableColumns.userStubColumn(theme, {
		...userColProps,
		title: 'Recruiter',
		dataIndex: 'user',
		// removeInColumnFilter: true,
		withDropdown: isStaffView,
	});

	const companyLogoCol = TableColumns.companyLogoColumn(theme, {
		title: '',
		dataIndex: 'company',
		removeInColumnFilter: true,
		colWidth: 32,
		size: 24,
	});

	const invitedAtCol = TableColumns.dateColumn(
		theme,
		{
			title: <>Invited<br/>on</>,
			dataIndex: 'createdAt',
			sorter: true,
		},
	);

	const lastSessionCol = {
		title: <>Last<br/>session</>,
		dataIndex: 'lastSessionAt',
		align: 'center',
		removeInColumnFilter: true,
		sorter: true,
		render: (value, row) => {
			if (isLogged(row)) {
				return TableColumns.dateColumn(theme).render(row?.lastSessionAt);
			}
			return (
				<Space direction='vertical' size={0}>
					<span style={{ color: '#f99', fontSize: 10, fontStyle: 'italic' }}>
						<StopOutlined /> Never logged
					</span>
					{(row?.isActive !== false
					&& row?.isResent !== true
					&& (
						<Link
							onClick={() => handleResendInvitation(row)}
							style={{ display: 'block', fontSize: 10, marginTop: -2 }}
						>
							<span>Resend&nbsp;invite&nbsp;<ArrowRightOutlined /></span>
						</Link>
					))}
				</Space>
			);
		},
		...TableColumns.columnFixedWidth(90),
	};

	const savedSearchesCol = TableColumns.docsCountColumn(theme, {
		title: <>Saved<br />searches</>,
		dataIndex: 'savedSearches',
		icon: <SearchOutlined />,
		value: (value) => value?.length,
		sorter: false,
		colWidth: 72,
	});

	const talentsViewedCol = TableColumns.docsCountColumn(theme, {
		title: <>Talents<br />viewed</>,
		dataIndex: 'talentViews',
		icon: <UserOutlined />,
		value: (value) => Object.keys(value || {})?.length,
		// value: (value) => `${value?.length || 0} / ${uniqueStrings(value, (item) => item)?.length || 0}`,
		sorter: false,
		colWidth: 72,
	});

	const buildTimeCols = ({
		dataIndex = 'messages',
		icon = <MessageOutlined />,
		dateFilter = ({ item, limit }) => item,
		prepareValue = (item) => item,
		// dateProp: dateMapper = (model) => model?.sentAt,
		// result = (model) => model?.hasAnswerDetails,
	}) => {
		const buildColProps = ({ title, limit }) => ({
			title: <span style={{ fontSize: 10, color: 'grey', fontWeight: 'normal' }}>{title}</span>,
			dataIndex,
			icon,
			sorter: false,
			colWidth: 72,
			value: (value) => {
				// console.log('buildColProps', value, prepareValue(value));
				return prepareValue(value)
					?.filter?.((item) => {
						return dateFilter({ item, limit });
					})
					?.length;
			},
		});
		return [
			TableColumns.docsCountColumn(theme, buildColProps({ title: 'All time' })),
			TableColumns.docsCountColumn(theme, buildColProps({ title: 'Last month', limit: moment().add(-1, 'month') })),
			TableColumns.docsCountColumn(theme, buildColProps({ title: 'This month', limit: moment() })),
		];
	};

	const talentsViewedCols = {
		title: 'Talents viewed',
		removeInColumnFilter: true,
		children: buildTimeCols({
			dataIndex: 'talentViews',
			icon: <UserOutlined />,
			prepareValue: (value) => Object.entries(value || {}),
			dateFilter: ({ item, limit }) => {
				// console.log('dateFilter', item, limit);
				if (!limit) return true;
				return arrayWrap(item[1]).some((date) => moment(date).format('YYYY-MM') === limit.format('YYYY-MM'));
			},
		}),
	};

	const talentsContactedCols = {
		title: 'Talents contacted',
		removeInColumnFilter: true,
		children: buildTimeCols({
			dataIndex: 'messages',
			icon: <MessageOutlined />,
			dateFilter: ({ item, limit }) => {
				// console.log('dateFilter (messages)', item, limit);
				const model = new UserMessageModel(item);
				if (limit && moment(model.sentAt).format('YYYY-MM') !== limit.format('YYYY-MM')) {
					return false;
				}
				return model?.hasAnswerDetails;
			},
		}),
	};

	const versionCol = {
		dataIndex: 'version',
		align: 'center',
		sorter: false,
		removeInColumnFilter: true,
		render: (value) => <code style={{ fontSize: 8, fontWeight: 'normal' }}>{value}</code>,
		...TableColumns.columnFixedWidth(40),
	};

	const columns = isStaffView
		? [
			TableColumns.staffColumnWrapper(jsonCol),
			TableColumns.staffColumnWrapper(companyLogoCol),
			userStubCol,
			TableColumns.staffColumnWrapper(versionCol),
			invitedAtCol,
			lastSessionCol,
			savedSearchesCol,
			// talentsViewedCol,
			talentsViewedCols,
			talentsContactedCols,
		]
		: [
			userStubCol,
			invitedAtCol,
			lastSessionCol,
			savedSearchesCol,
			// talentsViewedCol,
			talentsViewedCols,
			talentsContactedCols,
		];

	return (
		<>
			<Table
				path={path}
				// ItemClass={UserMessageModel}
				onDataLoaded={onDataLoaded}
				columns={columns}
				filterOptions={filterOptions}
				isRefresh={isRefresh + isRefreshDataLayout}
				showTotal={(total) => `Total ${total} items`}
				limit={10}
				rowClassName={(row, index) => {
					if (row.isActive === false) return 'rowDisabled';
					if (!isLogged(row)) return 'rowNeverLogged';
				}}
				withDynamicSearch
				{...props}
			/>
		</>
	);
};

export default ReportsTable;
