import { DatePicker } from 'antd';
import { UMS_LABEL_SHORT } from '../../../../constants/constant';
import FormItem from '../../form/FormItem';

export const companyPlanUMSPurchasedAtPath = ['companyPlan', 'ums', 'purchasedAt'];

const CompanyPlanUMSPurchasedAtFormItem = ({
	required = false,
	placement = 'bottomLeft',
}) => {
	const className = 'CompanyPlanUMSPurchasedAtFormItem';

	return (
		<FormItem
			label={`${UMS_LABEL_SHORT} purchase date`}
			name={companyPlanUMSPurchasedAtPath}
			style={{ marginBottom: 15 }}
			rules={[
				{
					required,
					message: 'This field is required',
				},
				{
					type: 'object',
					required: false,
					message: 'Please input date',
					whitespace: true,
				},
			]}
		>
			<DatePicker
				showTime={false}
				format={'DD/MM/YYYY'}
				placement={placement}
			/>
		</FormItem>
	);
};

export default CompanyPlanUMSPurchasedAtFormItem;
