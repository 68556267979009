import { useTheme } from '@emotion/react';
import { openExternalUrl } from '../../../utils/common';
import Link from '../../app/Link';

const UserCalendlyLink = (
	{
		user,
	},
) => {
	const className = 'UserCalendlyLink';
	const theme = useTheme();

	const calendlyUri = user?.information?.calendly;

	if (!calendlyUri) {
		return (
			<span style={{ color: theme.color.grey }}><i>Not provided</i></span>
		);
	}

	const calendlyUrl = `https://calendly.com/${calendlyUri}`;

	return (
		<Link
			onClick={() => openExternalUrl(calendlyUrl)}
		>
			{calendlyUrl}
		</Link>
	);
};

export default UserCalendlyLink;
