import { useAuthContext } from '../../../hooks/useAuthContext';
import { TAG_STAFF, TAG_STAFF_ICON } from '../../../constants/constant';
import BlockBase from './BlockBase';
import { useTheme } from '@emotion/react';
import { Collapse } from 'antd';
import CollapsePanel from 'antd/es/collapse/CollapsePanel';
import { useState } from 'react';

const COLLAPSE_KEYS = {
	OPEN: '1',
	CLOSED: '0',
};
const BlockStaff = ({
	withStaffView = true,
	withTags = true,
	withTagAsIcon = false,
	withBorder = true,
	withCollapse = false,
	tagStyle,
	children,
	blockedContent,
	...props
}) => {
	const theme = useTheme();
	const { isStaff, isStaffView } = useAuthContext();
	const [activeKey, setActiveKey] = useState(COLLAPSE_KEYS.CLOSED);

	const content = <BlockBase
		value={isStaff}
		color={theme.color.lilac}
		withStaffView={withStaffView}
		withTags={!withCollapse && withTags}
		withTagAsIcon={!withCollapse && withTagAsIcon}
		withBorder={!withCollapse && withBorder}
		tagStyle={tagStyle}
		tags={withTagAsIcon ? TAG_STAFF_ICON : TAG_STAFF}
		{...props}
	>
		{children}
	</BlockBase>;

	if (!isStaff || (!isStaffView && withStaffView)) return blockedContent || null;

	if (!withCollapse) {
		return content;
	}

	const collapseIsClosed = () => activeKey === COLLAPSE_KEYS.CLOSED;
	return (<div
		style={{
			position: 'absolute',
			top: 20,
			right: 20,
			background: collapseIsClosed() ? 'transparent' : 'rgba(255,255,255,.8)',
			zIndex: 99,
			...props?.style,
		}}
	>
		<Collapse defaultActiveKey={[COLLAPSE_KEYS.CLOSED]} activeKey={activeKey}
			onChange={() => setActiveKey(collapseIsClosed() ? COLLAPSE_KEYS.OPEN : COLLAPSE_KEYS.CLOSED)}
			style={{
				background: 'white',
			}}>
			<CollapsePanel
				key={COLLAPSE_KEYS.OPEN}
				header={TAG_STAFF}
			>
				{content}
			</CollapsePanel>
		</Collapse>
	</div>
	);
};

export default BlockStaff;
