import HARD_SKILLS from './hard-skills.json';
/**
 * HARD_SKILLS format:
 * [
	{
		"group": "backend",
		"groupName": "Backend",
		"label": "Akka",
		"value": "akka"
	},
	]
	- Formerly: stack.json
 */

/**
 * TECH_STACK_GROUP_OPTIONS format:
 * [{
	value: group,
	label: groupName,
	items: [
		{
			value,
			label,
		},
	],
},]
	- Formerly: techs2.json
*/
export const TECH_STACK_GROUP_OPTIONS = HARD_SKILLS.reduce((acc, hardSkill) => {
	const {
		group, groupName, label, value,
	} = hardSkill;
	const groupObjectIndex = acc.findIndex((e) => e.value === group);
	if (groupObjectIndex < 0) {
		return [
			...acc,
			{
				value: group,
				label: groupName,
				items: [
					{
						value,
						label,
					},
				],
			},
		];
	}
	acc[groupObjectIndex]?.items.push({
		value,
		label,
	});
	return acc;
}, []);
