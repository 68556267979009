import { useTheme } from '@emotion/react';
import CompanyService from '../../services/company';
import TableColumns from '../app/table/TableColumns';
import {
	Dropdown, Tooltip,
} from 'antd';
import {
	FaChartBar,
	FaCog,
	FaEye,
	FaMagnet,
	FaPencilAlt,
	FaUsers, FaWifi,
} from 'react-icons/fa';
import Table from '../app/table/Table';
import { useState } from 'react';
import CompanyStub from './CompanyStub';
import CompanyTeamDrawerContent from './CompanyTeamDrawerContent';
import { useDrawer } from '../../hooks/useDrawer';
import CompanyStoriesDrawerContent from './CompanyStoriesDrawerContent';
import { useAuthContext } from '../../hooks/useAuthContext';
import CompanySettingsSEOForm from './settings/CompanySettingsSEOForm';
import useBodyScrollBlocker from '../../hooks/useBodyScrollBlocker';
import { SEO_TITLE, SEO_DETAILS } from './settings/CompanySettingsTitles';
import {
	documentId, filterAutoCompleteItems,
} from '../../utils/common';
import ModalContainerBase from '../../contexts/modals/ModalContainerBase';
import CompanyModel from '../../models/CompanyModel';
import CompanySettingsUMSForm from './settings/CompanySettingsGenderScoreForm';
import Box from '../app/box/Box';
import { useNavigate } from 'react-router';
import { companyGenderScoreColumnsGroup } from './CompanyGenderScoreTableColumns';
import { BiChevronDown } from 'react-icons/bi';
import Link from '../app/Link';
import StaffGSSurveyListMenu from '../../menu/staff/genderscore/StaffGSSurveyListMenu';
import StaffGSSurveyDetailsMenu from '../../menu/staff/genderscore/StaffGSSurveyDetailsMenu';

const CompanyTable = (
	{
		onDataLoaded,
		path = '/company',
		filterOptions,
		isRefresh = 0,
		onUpdateCompany,
		onEdit,
		onPreview,
		onFetchCompany,
		onSearchDataColumn,
		isEditor,
		documentReadersIds,
		defaultColumnsOptions = ['name', 'path'],
		showGSCols = true,
	},
) => {
	const className = 'CompanyTable';

	const theme = useTheme();
	const navigate = useNavigate();
	const { isStaffView } = useAuthContext();
	// selector
	const [isRefreshDataLayout, setIsRefreshDataLayout] = useState(0);

	// drawer
	const {
		setOpen: setOpenDrawer,
		setTitle: setDrawerTitle,
		setContent: setDrawerContent,
	} = useDrawer();

	// ------------------------------------------- //
	// -------- search autocomplete data --------- //
	// ------------------------------------------- //
	const handleSearchAutoComplete = async (options) => {
		const { data: { companies } } = await CompanyService.findAll(options);
		return filterAutoCompleteItems(companies);
	};

	const togglePublishedCompany = (row) => {
		onUpdateCompany({ id: documentId(row), isPublished: !row.isPublished });
	};

	const toggleJobsPublishedCompany = (row) => {
		onUpdateCompany({ id: documentId(row), areJobsPublished: !row.areJobsPublished });
	};

	// ------------------------------------------ //
	// ------------ handle open team ------------ //
	// ------------------------------------------ //
	const handleOpenTeam = (row) => {
		setOpenDrawer(true);
		setDrawerTitle('Teams');
		setDrawerContent(<CompanyTeamDrawerContent company={row}/>);
	};

	// ------------------------------------------ //
	// ------------ handle open team ------------ //
	// ------------------------------------------ //
	const handleOpenStories = (row) => {
		setOpenDrawer(true);
		setDrawerTitle('Articles');
		setDrawerContent(<CompanyStoriesDrawerContent company={row}/>);
	};

	// ---------------------------------------------- //
	// ------------ handle settings form ------------ //
	// ---------------------------------------------- //
	const [company, setCompany] = useState();
	const [openSEOModal, setOpenSEOModal] = useState(false);
	const [openGenderScoreSettingsModal, setOpenGenderScoreSettingsModal] = useState(false);

	useBodyScrollBlocker(openSEOModal || openGenderScoreSettingsModal, className);

	const handleOpenSEOModal = (selectedCompany) => {
		setCompany(selectedCompany);
		setOpenSEOModal(true);
	};
	const handleCloseSEOModal = () => {
		setOpenSEOModal(false);
		onUpdateCompany();
	};

	const handleOpenGenderScoreSettingsModal = (selectedCompany) => {
		setCompany(selectedCompany);
		setOpenGenderScoreSettingsModal(true);
	};
	const handleRedirectGenderScoreSurveysPage = (selectedCompany) => {
		setCompany(selectedCompany);
		navigate(`${StaffGSSurveyListMenu.props.path}?companySelected=${selectedCompany?.path}`);
	};
	const handleRedirectGenderScoreSurveyDetailsPage = (surveyId) => {
		navigate(`${StaffGSSurveyDetailsMenu.props.path}?surveyId=${surveyId}`);
	};
	const handleCloseGenderScoreSettingsModal = () => {
		setOpenGenderScoreSettingsModal(false);
		onUpdateCompany();
	};
	const actionsCol = {
		title: 'Staff actions',
		removeInColumnFilter: true,
		fixed: 'right',
		render: (value, row) => {
			const ActionMenuItem = ({
				title,
				onClick,
				Icon,
				children,
			}) => (
				<div onClick={onClick}>
					<Icon style={{
						marginBottom: -5,
						fontSize: 10,
						border: '1px solid #ddd',
						padding: 4,
						borderRadius: 4,
					}} />
					&nbsp;
					{children}
				</div>
			);
			const items = [
				{
					key: 'companyPage',
					type: 'group',
					label: 'Company Page',
					children: [
						{
							key: 'companyPage/open',
							label: (
								<ActionMenuItem
									onClick={() => { onPreview(row); }}
									Icon={FaEye}
								>
									Open Company Page
								</ActionMenuItem>
							),
						},
						{
							key: 'companyPage/edit',
							label: (
								<ActionMenuItem
									onClick={() => { onEdit(row); }}
									Icon={FaPencilAlt}
								>
									Edit Company Page
								</ActionMenuItem>
							),
						},
					],
				},
				{
					...new CompanyModel(row)?.isGenderScoreEnabled()
						? {
							key: 'genderScore',
							type: 'group',
							label: 'GenderScore',
							children: [
								{
									key: 'genderScore/open',
									label: (
										<ActionMenuItem
											onClick={() => { handleRedirectGenderScoreSurveysPage(row); }}
											Icon={FaChartBar}
										>
									Open GS Surveys
										</ActionMenuItem>
									),
								},
								{
									key: 'genderScore/edit',
									label: (
										<ActionMenuItem
											onClick={() => { handleOpenGenderScoreSettingsModal(row); }}
											Icon={FaCog}
										>
									Edit GS Settings
										</ActionMenuItem>
									),
								},
							],
						}
						: {},
				},

			];

			return (
				<Dropdown menu={{ items }}>
					<Link>Actions <BiChevronDown style={{ marginBottom: -2 }}/></Link>
				</Dropdown>
			);
		},
		...TableColumns.columnFixedWidth(120),
	};
	const columns = [
		TableColumns.jsonColumn(theme),
		TableColumns.companyStubColumn(theme, {
			fixed: 'left',
			render: (v, r) => <CompanyStub
				size={36}
				company={r}
				withPublicationDot={true}
				withJobsPublicationDot={true}
				withAtsDot={true}
				withGenderScoreDot={true}
				withCompanyPlan={true}
				withPublicationOpacity={false}
				withCompanyPlanExtraTags={true}
				withAtsAccess={true}
				withTooltip={false}
				style={{ margin: '2px 0' }}
			/>,
			customOptions: { searchFields: [{ label: 'Company name', value: 'name' }] },
			colWidth: 240,
		}),
		TableColumns.churnedAtColumn(theme),
		TableColumns.companyPlanColumn(theme),
		showGSCols
			? companyGenderScoreColumnsGroup(theme, {
				onOpen: handleOpenGenderScoreSettingsModal,
				onNavigate: handleRedirectGenderScoreSurveysPage,
				onSurveyClick: handleRedirectGenderScoreSurveyDetailsPage,
			})
			: {},
		TableColumns.jsonTagTreeColumn(theme, { withRefreshLink: true }),
		TableColumns.togglePublishedColumn(theme, togglePublishedCompany),
		TableColumns.dateColumn(theme, { title: 'Published at', dataIndex: 'publishedAt' }),
		TableColumns.togglePublishedColumn(theme, toggleJobsPublishedCompany, {
			title: 'Jobs published', dataIndex: 'areJobsPublished',
		}),
		TableColumns.dateColumn(theme, { title: 'Jobs published at', dataIndex: 'jobsPublishedAt' }),
		TableColumns.companyHasCustomMetas(theme, (company) => { handleOpenSEOModal(company); }),

		TableColumns.docsCountColumn(theme, {
			title: <Tooltip title="Total jobs currently published">Jobs</Tooltip>,
			dataIndex: 'publishedJobsCount',
			// value: (v)=> (v || []).length,
			// sorter: (a, b) => a.jobs.length > b.jobs.length ? 1 : -1,
			icon: <FaWifi style={{ marginBottom: -2 }}/>,
			style: { color: '#129c8f', background: '#b4f7f1' },
			// onClick: (v) => { handleOpenJobs(v) }
		}),

		TableColumns.createdAtColumn(theme),
		TableColumns.updatedAtColumn(theme),
		TableColumns.docsCountColumn(theme, {
			title: 'Team',
			dataIndex: 'team',
			value: (v) => (v || []).length,
			sorter: (a, b) => (a.team.length > b.team.length ? 1 : -1),
			icon: <FaUsers style={{ marginBottom: -2 }}/>,
			onClick: (v) => { handleOpenTeam(v); },
			style: { color: '#d46b08', background: '#fff7e6', borderColor: '#ffd591' },
			removeInColumnFilter: true,
		}),
		TableColumns.docsCountColumn(theme, {
			title: 'Stories',
			dataIndex: 'articles',
			value: (v) => (v || []).length,
			// icon: <FaPen style={{marginBottom: -2}}/>,
			onClick: (v) => { handleOpenStories(v); },
			style: { color: '#d48806', background: '#fffbe6', borderColor: '#ffe58f' },
			removeInColumnFilter: true,
		}),
		TableColumns.docsCountColumn(theme, {
			title: <>ATS<br/>Access</>,
			dataIndex: 'access',
			value: (v) => v?.length,
			icon: <FaMagnet style={{ marginBottom: -2 }}/>,
			style: { color: '#08979c', background: '#e6fffb', borderColor: '#87e8de' },
		}),
		TableColumns.docsCountColumn(theme, {
			title: 'Batches',
			dataIndex: ['batch', 'total'],
			value: (_, r) => r?.batch?.total,
			icon: <FaCog style={{ marginBottom: -2 }}/>,
			style: { color: '#c41d7f', background: '#fff0f6', borderColor: '#ffadd2' },
			removeInColumnFilter: true,
		}),
		TableColumns.companyLastBatchColumn(theme),
		actionsCol,
	];

	return (
		<>
			<Table
				id='companies-table'
				path={path}
				onDataLoaded={onDataLoaded}
				columns={columns}
				filterOptions={filterOptions}
				isRefresh={isRefresh + isRefreshDataLayout}
				onSearchDataColumn={handleSearchAutoComplete}
				withDynamicSearch
				defaultColumnsOptions={defaultColumnsOptions}
				rowClassName={(row, index) => ((new CompanyModel(row)).isChurned() ? 'rowDisabledNotForceBg' : '')}
			/>

			{/* Modal window for the settings form */}
			<ModalContainerBase
				open={openSEOModal}
				setOpen={setOpenSEOModal}
			>
				{/* The modal window contains a SEO form to set the meta-tags of the company */}
				{/* If in the future we want to open another form when clicking a column,
				  * we'll need extra conditions here or make the component more dynamic */}
				{
					company
						&& <CompanySettingsSEOForm
							company={company}
							title={SEO_TITLE}
							details={SEO_DETAILS}
							onSuccess={handleCloseSEOModal}
						/>
				}
			</ModalContainerBase>

			{/* Modal window for the GenderScore settings form */}
			<ModalContainerBase
				open={openGenderScoreSettingsModal}
				setOpen={setOpenGenderScoreSettingsModal}
				width={600}
			>
				<CompanySettingsUMSForm
					company={company}
					onSuccess={handleCloseGenderScoreSettingsModal}
				>
					<Box style={{ background: theme.color.bgGrey, marginBottom: 12 }}>
						<CompanyStub company={company} />
					</Box>
				</CompanySettingsUMSForm>
			</ModalContainerBase>
		</>
	);
};

export default CompanyTable;
