import { FaBan, FaCheck } from 'react-icons/fa';
import { useTheme } from '@emotion/react';
import { Space } from 'antd';
import { CompanySettingsCardLineOk } from './CompanySettingsCard';

/*
 Displays a text explaining the current status for the publication of translation of a company page
 */
const CompanySettingsTranslationPublicationStatus = (
	{
		company,
		...props
	},
) => {
	const className = 'CompanySettingsTranslationPublicationStatus';
	const theme = useTheme();

	// Whenever the company is not set as translatable, we don't show the publication status
	if (!company?.isTranslatable) return null;

	// In other cases, we detail the publication status
	return (
		<CompanySettingsCardLineOk
			ok={company?.isTranslationPublished}
			okText={<><b>{company.name}</b>'s company page is <b>published in 🇫🇷 french</b></>}
			koText={<><b>{company.name}</b>'s company page is <b>not published in 🇫🇷 french</b></>}
		/>
	);
};

export default CompanySettingsTranslationPublicationStatus;
