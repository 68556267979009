import { Space } from 'antd';
import theme from '../../theme';
import Link from '../app/Link';
import { useTheme } from '@emotion/react';

const CandidateSelectorPipelineLargeButton = (
	{
		option,
		selected,
		onClick,
		...props
	},
) => {
	const theme = useTheme();

	return option
		? <div style={{ minWidth: 160 }}>
			<Link onClick={onClick}>
				<Space
					direction='vertical'
					size={6}
					style={{
						background: '#fcfcfc',
						width: '100%',
						height: 108,
						padding: '16px 10px 0 20px',
						border: '1px solid #eee',
						borderRadius: 4,
						boxShadow: '0 2px 12px -4px rgba(0,0,0,.2)',
						...selected
							? {
								background: 'white',
								border: `1px solid ${theme.color.fitBlueElectric}`,
								borderBottom: `6px solid ${theme.color.fitBlueElectric}`,
								boxShadow: 'none',
							} : {},
					}}>
					<span style={{
						fontSize: 24, fontWeight: 500, color: 'black', marginTop: 12,
					}}>
						{option?.icon}
					&nbsp;
						{option.count > 0
							? <span>{option.count}</span>
							: <span style={{ color: theme.color.lightgrey }}>0</span>
						}
					</span>
					<div style={{ fontSize: 16, lineHeight: 1.1, color: '#444' }}>
						<span style={{ color: (selected ? theme.color.fitBlueElectric : 'black') }}>{option.label}</span>
					</div>
				</Space>
			</Link>
		</div>
		: null;
};
export default CandidateSelectorPipelineLargeButton;
