import { Form, Input, Tooltip } from 'antd';

const FormItem = (
	{
		label,
		tooltip,
		children,
		...props
	},
) => {
	const labelComponent = label
		? <label style={{ fontSize: 14, fontWeight: 600 }}>{label}</label>
		: null;
	const decoratedLabelComponent = label && tooltip
		? <Tooltip title={tooltip}>{labelComponent}</Tooltip>
		: labelComponent;

	return (
		<Form.Item
			label={decoratedLabelComponent}
			{...props}
		>
			{children || <Input />}
		</Form.Item>
	);
};

export default FormItem;
