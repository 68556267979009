import { Switch as AntSwitch } from 'antd';

const Switch = (
	{
		value,
		onChange,
		before,
		after,
		children,
		...props
	},
) => {
	return (
		<div style={{ lineHeight: 2, verticalAlign: 'bottom' }}>
			{before ? <>{before}&nbsp;</> : null}
			<AntSwitch
				checked={value}
				onChange={onChange}
				{...props}
				style={{
					// margin: '-5px 3px 0',
					margin: '0 3px 3px',
					...props.style,
				}}
			/>
			{after ? <>&nbsp;{after}</> : null}
			{children}
		</div>
	);
};
export default Switch;
