import { useTheme } from '@emotion/react';
import { FaUpload } from 'react-icons/fa';
import {
	Button, Spin, Tooltip, message,
} from 'antd';
import { useContext, useState } from 'react';
import { LoadingOutlined } from '@ant-design/icons';
import PDFBuilder from './results/pdf/base/PDFBuilder';
import { getPDFManagerPages } from './results/PDFManager';
import { UnbiasedScoreContext } from '../../contexts/UnbiasedScoreProvider';
import { request } from '../../services/api';
import { LocalDebug } from '../../utils/LocalDebug';
import { getCompanySelected } from '../../reducers/app';
import { useSelector } from 'react-redux';
import { documentId } from '../../utils/common';

const UMSStaffUploadPDFButton = ({
	...props
}) => {
	const className = 'UMSStaffUploadPDFButton';
	const theme = useTheme();
	const [isLoading, setIsLoading] = useState(false);

	const companySelected = useSelector(getCompanySelected);
	const context = useContext(UnbiasedScoreContext);

	const handleClick = async ({ id, bgcolor = '#FFFFFF' }) => {
		setIsLoading(true);
		const filename = context.makeManagerPDFResultFilename();

		try {
			const pdf = new PDFBuilder({ filename, context });
			LocalDebug.logInfo({ className, method: 'handleClick' }, { pdf, umsResult: context.umsResult });
			const pages = await getPDFManagerPages({
				doc: pdf,
				company: { name: 'Company' },
				manager: { firstName: 'Julien', lastName: `Londeix ${Math.round(Math.random() * 100)}` },
				date: new Date(),
				context,
			});
			LocalDebug.logInfo({ className, method: 'handleClick' }, { pages });

			pdf.buildPages(pages, context);
			const output = pdf.pdf.output('blob');
			LocalDebug.logInfo({ className, method: 'handleClick' }, { output });

			const formData = new FormData();
			formData.append('file', output, filename);
			formData.append('companyPath', companySelected?.path);
			formData.append('companyId', documentId(companySelected));
			formData.append('userId', documentId(context.umsResult.user));
			formData.append('lang', context.appLang);

			// formData.append('umsResultData', context.umsResult);
			formData.append('umsResultData', JSON.stringify({ hello: true }));

			const result = await request.post(
				'/ums/pdf',
				formData,
				{ headers: { 'content-type': 'multipart/form-data' } },
			);
			message.success('PDF uploaded to bucket');
			context.setRefresh((p) => p + 1);
			LocalDebug.logInfo({ className, method: 'handleClick' }, { result });
		} catch (e) {
			console.error(e);
		}

		setIsLoading(false);
	};

	return (
		<Tooltip
			title={context.makeManagerPDFResultFilename()}
		>
			<Button
				style={{
					background: theme.color.lightturquoise,
					color: theme.color.darkerturquoise,
					border: `1px solid ${theme.color.darkturquoise}`,
					borderRadius: 8,
					...props?.style,
				// position: 'fixed',
				// zIndex: 100,
				}}
				onClick={handleClick}
			>
				{
					isLoading
						? <Spin indicator={<LoadingOutlined spin style={{ fontSize: 14 }} />} />
						: <FaUpload/>
				}
			&nbsp;Upload to GCP
			</Button>
		</Tooltip>
	);
};
export default UMSStaffUploadPDFButton;
