import { Group } from '@visx/group';
import { Bar } from '@visx/shape';
import { scaleLinear, scaleBand } from '@visx/scale';
import { useTheme } from '@emotion/react';
import { LinearGradient } from '@visx/gradient';
import { Space, Tooltip } from 'antd';
import { arrayWrap } from '../../../../../utils/common';
import GenderScoreMedalBlock from '../blocks/GenderScoreMedalBlock';

/**
 * @param {*} data - Values to render and mappers to get the data.
 * Format: {value, helpers:{xMapper, yMapper}}
 * @param {*} [graphColors] - Optional. Format: [from, to]. If not set, use theme.chart.bar
 * @param {number} [width] - Optional. Width of the chart. Default: 200
 * @param {number} [height] - Optional. Height of the chart. Default: 200
 * @param {number} [fontSize] - Optional. Font size of the top labels.
 * The bottom labels are proportional to this size. Default: 16
 * @param {number} [barRadius] - Optional. Radius of the bar.
 * @param {number} [gap] - Optional. Separation between bars. Default: 0.2
 * @param {Boolean} [withTopLabel] - Optional. Whether to show the top label (y value).
 * Default: true
 * @param {Boolean} [withBottomLabel] - Optional. Whether to show the bottom label (x value).
 * Default: true
 * @param {Boolean} [withTooltip] - Optional. Whether to show a tooltip on hover of a bar.
 * Default: false
 * @param {*} [margin] - Optional. Internal margins of the chart.
 * Format: {top, bottom, left, right}.
 * Default: {
		top: 20, bottom: 20, left: 0, right: 0,
	}
 * @returns BarGraph component
 */
const BarGraph = ({
	data,
	graphColors,
	width = 200,
	height = 200,
	margin = {
		top: 20, bottom: 20, left: 0, right: 0,
	},
	fontSize = 16,
	withTopLabel = true,
	withBottomLabel = true,
	withTooltip = false,
	gap = 0.2,
	barRadius,
	...props
}) => {
	const theme = useTheme();
	const gradient = graphColors || theme.chart.bar;
	const gradientId = `bar_fill${Math.random() * 10}`;

	const { values, helpers: { xMapper, yMapper } } = data;
	// Create bounds
	const xMax = width - margin.left - margin.right;
	const yMax = height - margin.top - margin.bottom;

	// Scale the graph by our data
	const xScale = scaleBand({
		range: [0, xMax],
		round: true,
		domain: values?.map(xMapper),
		padding: gap,
	});
	const yScale = scaleLinear({
		range: [yMax, 0],
		round: true,
		domain: [0, Math.max(...values.map(yMapper))],
	});

	// Compose together the scale and mapper functions to get point functions
	const compose = (scale, mapper) => (inData) => scale(mapper(inData));
	const xPoint = compose(xScale, xMapper);
	const yPoint = compose(yScale, yMapper);

	const isXLabelWrapped = values?.some((v) => xMapper(v)?.indexOf('\n') >= 0);
	const pxToEm = (px, pxSize) => px / pxSize;
	const bottomFontSize = Math.floor(isXLabelWrapped ? fontSize * 0.6 : fontSize * 0.75);
	const xOffsetTop = `${pxToEm(xScale.bandwidth() / 2, fontSize) - 0.6}em`;
	const xOffsetBottom = [`${pxToEm(xScale.bandwidth() / 2, bottomFontSize) - 1.2}em`];
	if (isXLabelWrapped) {
		xOffsetBottom.push(`${pxToEm(xScale.bandwidth() / 2, bottomFontSize) - 1}em`);
	}
	const barTooltip = (value) => withTooltip && <Space.Compact
		direction='vertical'
		align='center'>
		<span style={{ textAlign: 'center', margin: -5 }}>
			{xMapper(value)}
		</span>
		<GenderScoreMedalBlock
			value={yMapper(value)}
			fontSize={12}
			height={11}
			color='#FFFFFF'
		/>
	</Space.Compact>;
	return <Space style={{ ...props?.style }}>
		<svg width={width} height={height}>
			<defs>
				<LinearGradient from={gradient?.from} to={gradient?.to} id={gradientId}/>
			</defs>
			{values?.map((value, i) => {
				const barHeight = yMax - yPoint(value);
				return (
					<Group key={`bar-${i}`} left={margin.left} top={margin.top}>
						<Tooltip title={barTooltip(value)}><Bar
							x={xPoint(value)}
							y={yMax - barHeight}
							height={barHeight}
							width={xScale.bandwidth()}
							fill={`url(#${gradientId})`}
							rx={barRadius}
						/></Tooltip>
						{withTopLabel && <text
							x={xScale(xMapper(value))}
							y={yMax - barHeight}
							fill={theme.color.fitBlack}
							fontSize={fontSize}
							fontWeight={700}
							dx={xOffsetTop}
							dy={'-.33em'}
						>
							{yMapper(value)}
						</text>
						}
						{withBottomLabel && arrayWrap(isXLabelWrapped
							? xMapper(value).split('\n')
							: xMapper(value)).map((v, j) => <text
							key={`xTag${j}`}
							x={xScale(xMapper(value))}
							y={yMax + j * 10}
							fill={theme.color.fitBlack}
							fontSize={bottomFontSize}
							fontWeight={500}
							opacity={0.65}
							dx={xOffsetBottom[j]}
							dy={'1em'}
						>
							{v}
						</text>)}

					</Group>
				);
			})}
		</svg>
	</Space>;
};

export default BarGraph;
