/** @jsxImportSource @emotion/react */
import FormInput from '../../../../form/FormInput';
import { useTheme } from '@emotion/react';

const FormItemTitle = ({ label, name, onChange }) => {
	const theme = useTheme();

	return (
		<div
			css={{
				textarea: {
					fontSize: 28,
					fontWeight: 600,
					lineHeight: 1.2,
					color: theme.color.blue,
					transition: ' all 0.2s ease-out',
				},
			}}
		>
			<FormInput
				label={label}
				name={name}
				style={{ minHeight: 100 }}
				extra = '~60 characters max'
				maxLength={60}
				onChange={(e) => onChange(name, e.target.value)}
				isTextarea
			/>
		</div>
	);
};

export default FormItemTitle;
