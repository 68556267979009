import { useTheme } from '@emotion/react';
import {
	Button,
	Checkbox,
	Space,
} from 'antd';
import { useContext, useState } from 'react';
import { UnbiasedScoreContext } from '../../../contexts/UnbiasedScoreProvider';
import { useDispatch } from 'react-redux';
import { numToArray } from '../../../utils/common';
import {
	CheckOutlined, CloseOutlined,
	FolderOpenOutlined,
} from '@ant-design/icons';
import { getPDFManagerPageList } from '../results/PDFManager';
import Link from '../../app/Link';
import ModalContainerBase from '../../../contexts/modals/ModalContainerBase';

const UMSStaffPDFPageSelector = ({
	...props
}) => {
	const className = 'UMSStaffPDFPageSelector';

	const theme = useTheme();

	const {
		pageList,
		setPageListStorage,
	} = useContext(UnbiasedScoreContext) || {};

	const [open, setOpen] = useState(false);

	return (
		<>
			<Button
				onClick={() => setOpen(true)}
				style={{
					...props?.style,
				}}
			>
				Select pages (<b>{pageList?.filter((item) => item)?.length}</b> / {pageList?.length}) <FolderOpenOutlined />
			</Button>

			<ModalContainerBase
				open={open}
				setOpen={(setOpen)}
			>
				<Space direction='vertical'>
					<Space>
						<Link
							onClick={() => setPageListStorage(
								numToArray(getPDFManagerPageList().length).map(() => true),
							)}
						>
							<CheckOutlined /> Select all
						</Link>
						<Link
							onClick={() => setPageListStorage(
								numToArray(getPDFManagerPageList().length).map(() => false),
							)}
						>
							<CloseOutlined /> Unselect all
						</Link>
					</Space>
					{getPDFManagerPageList().map((page, index) => {
						return (
							<Space key={index}>
								<Checkbox
									checked={pageList?.[index] !== false}
									onChange={(value) => {
										const newPageList = [
											...pageList,
											...numToArray(getPDFManagerPageList().length).map(() => true),
										].slice(0, getPDFManagerPageList().length);

										newPageList[index] = !newPageList[index];
										setPageListStorage(newPageList);
									}}
								/>
								{`${index + 1}. ${page.name.slice(4)}`}
								<CheckOutlined onClick={() => {
									const newPageList = numToArray(getPDFManagerPageList().length)
										.map(() => false);
									newPageList[index] = true;
									setPageListStorage(newPageList);
								}}
								/>
							</Space>
						);
					})}
				</Space>
			</ModalContainerBase>
		</>
	);
};

export default UMSStaffPDFPageSelector;
