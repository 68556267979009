import {
	Button, Dropdown, Menu, Spin,
} from 'antd';
import { useEffect, useState } from 'react';
import { useAuthContext } from '../../../hooks/useAuthContext';
import CompanyService from '../../../services/company';
import AddButton from '../../app/button/AddButton';
import UserStub from '../../user/stub/UserStub';
import { documentId, writeLog } from '../../../utils/common';
import { FaRegPlusSquare } from 'react-icons/fa';
import useBodyScrollBlocker from '../../../hooks/useBodyScrollBlocker';

const JobEditManagersDropDown = ({
	job,
	onAddManager,
	managerIds = [],
}) => {
	const className = 'JobEditManagersDropDown';

	const [options, setOptions] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	const [jobSelected, setJobSelected] = useState(job);
	const { currentUser } = useAuthContext();

	const handleSubmitManager = (e) => {
		const userId = e.key;
		writeLog('select a manager for job', { job: documentId(job), managerId: userId });
		const newManager = options.find((o) => documentId(o) === userId);
		if (onAddManager) onAddManager(newManager);
		setOptions((prev) => prev.filter((o) => documentId(o) !== userId));
	};

	const openManagerDropdown = () => {
		setJobSelected(job);
	};

	useBodyScrollBlocker(true, className);

	const menu = (
		<Menu
			onClick={handleSubmitManager}
			mode="horizontal"
			style={{ maxHeight: 300, overflow: 'auto' }}
		>{isLoading
				? <Spin size="large"/>
				: options.map((u) => <Menu.Item key={documentId(u)}>
					<UserStub user={u} withLocation={false}/>
				</Menu.Item>)
			}
		</Menu>
	);

	useEffect(() => {
		const fetchCompanyManagers = async (companyId) => {
			setIsLoading(true);
			const notUserIds = [
				// documentId(currentUser),
				...managerIds,
			];
			const { data: { users } } = await CompanyService.getManagers(companyId, { notUserIds });
			setOptions(users);
			setIsLoading(false);
		};

		if (jobSelected) {
			fetchCompanyManagers(jobSelected?.companyId);
		}
	}, [currentUser?.id, jobSelected, managerIds]);

	const disabled = !options?.length;

	const button = <Button
		type='primary'
		ghost
		disabled={disabled}
		style={{ marginTop: 0, width: '100%', height: 42 }}
		title="Add a recipient"
		onClick={openManagerDropdown}
		icon={<FaRegPlusSquare style={{ marginBottom: -2 }}/>}
	>
		&nbsp;Add a recipient
	</Button>;

	return (
		disabled
			? button
			: <Dropdown overlay={menu} trigger={['hover']}>
				{button}
				{/* <AddButton */}
				{/*	disabled={!options?.length} */}
				{/*	style={{ marginTop: 1 }} */}
				{/*	title="Add a recipient" */}
				{/*	// onClick={openManagerDropdown} */}
				{/* /> */}
			</Dropdown>
	);
};

export default JobEditManagersDropDown;
