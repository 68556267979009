import { useTheme } from '@emotion/react';
import CompanyActionModel from '../../../../models/CompanyActionModel';
import ModalContainerBase from '../../../../contexts/modals/ModalContainerBase';
import CompanyActionContentLeftSide from './CompanyActionContentLeftSide';
import CompanyActionContentRightSide from './CompanyActionContentRightSide';

const CompanyActionContent = ({
	action,
	view,
	index,
	withActionManager,
	withAddButton,
	onAddAction,
	onEditAction,
	onDeleteAction,
	isSendActionPending,
	setIsSendActionPending,
	...props
}) => {
	const className = 'CompanyActionContent';

	const theme = useTheme();

	action = action instanceof CompanyActionModel
		? action
		: new CompanyActionModel(action);

	return (
		<div
			className={[
				'action-content',
				...(action?.pillar || []).map((pillar) => `action-${pillar}`),
				action.completionStatus,
				action.timeframe,
			].join(' ')}

			style={{
				// margin: '-20px -24px',
				...props?.style,
			}}
		>
			{/* <div
				style={{
					display: 'grid',
					gridTemplateColumns: '400px 1fr',
					overflow: 'scroll',
					width: '100%',
					maxHeight: 'calc(100vh - 60px - 0 * 72px)',
					height: 'calc(100vh - 60px - 0 * 72px)',
				}}
			> */}
			<CompanyActionContentLeftSide
				action={action}
				withActionManager={withActionManager}
				withAddButton={withAddButton}
				onAddAction={onAddAction}
				onEditAction={onEditAction}
				onDeleteAction={onDeleteAction}
				isSendActionPending={isSendActionPending}
				setIsSendActionPending={setIsSendActionPending}
				style={{
					...props?.style?.leftSide,
				}}
			/>

			<CompanyActionContentRightSide
				action={action}
				withAddButton={withAddButton}
				onAddAction={onAddAction}
				onEditAction={onEditAction}
				onDeleteAction={onDeleteAction}
				isSendActionPending={isSendActionPending}
				setIsSendActionPending={setIsSendActionPending}
				style={{
					...props?.style?.rightSide,
				}}
			/>

			{/* </div> */}

		</div>
	);
};

export default CompanyActionContent;
