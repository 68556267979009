import config from '../../../../config/config';
import { ENGLISH_LANG } from '../../../../constants/constant';
import CompanyFieldNotProvided from '../shared/CompanyFieldNotProvided';
import { useTheme } from '@emotion/react';
import { Space } from 'antd';

const CompanyGlassdoorDisplay = (
	{
		company,
		lang = ENGLISH_LANG,
		width = 120,
	},
) => {
	const className = 'CompanyGlassdoorDisplay';

	const theme = useTheme();

	const field = 'glassdoor';
	const value = company?.getTranslationFieldFallback(field, lang);

	const resizer = (v) => v * width / 170;

	if (!value) {
		return (
			<CompanyFieldNotProvided>
				<Space direction='vertical'>
					<img
						src={`${config.adminRoot}/assets/images/glassdoor-logo.png`}
						width={resizer(170)}
						style={{ opacity: 0.5 }}
						alt='glassdoor-logo'
					/>
					<span>Glassdoor rating not provided</span>
				</Space>
			</CompanyFieldNotProvided>
		);
	}

	return (
		<Space
			direction='vertical'
			align='center'
			size={resizer(0)}
			style={{
				justifyContent: 'center',
				fontSize: resizer(20),
				color: theme.color.darkgrey,
				width: '100%',
			}}
		>
			<img
				src={`${config.adminRoot}/assets/images/glassdoor-logo.png`}
				width={resizer(170)}
				alt='glassdoor-logo'
			/>
			<Space size={resizer(8)}>
				<div style={{
					fontSize: resizer(24),
					width: resizer(38),
					textAlign: 'right',
				}}>
					{value}
				</div>
				<div style={{ width: 1, height: resizer(24), background: theme.color.grey }} />
				<div style={{ position: 'relative', width: resizer(367 / 3), height: resizer(22) }}>
					<div
						style={{
							position: 'absolute',
							top: 0,
							left: 0,
							background: `url(${config.adminRoot}/assets/images/glassdoor-stars-off.png)`,
							backgroundSize: '100% 100%',
							width: resizer(367 / 3),
							height: resizer(22),
						}}
					/>
					<div
						// width='100%'
						// height={resizer(22)}
						style={{
							position: 'absolute',
							top: 0,
							left: 0,
							background: `url(${config.adminRoot}/assets/images/glassdoor-stars-on.png)`,
							backgroundSize: `auto ${resizer(22)}px`,
							width: resizer(Math.min(367, (1 + 75 * Math.floor(value) + 64 * (value % 1))) / 3),
							height: resizer(22),
						}}
					/>
				</div>
				{value % 1 === 0 && <div style={{ width: resizer(12), height: resizer(24) }} />}
			</Space>
		</Space>
	);
};

export default CompanyGlassdoorDisplay;
