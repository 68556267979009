import { Button, Space } from 'antd';
import ModalContainerBase from '../../../contexts/modals/ModalContainerBase';
import FormBoxTitle from '../form/FormBoxTitle';

const FilterModal = (
	{
		title,
		width = 500,
		open,
		onClose,
		onFilter,
		children,
	},
) => {
	return (
		<ModalContainerBase
			open={open}
			onCancel={onClose}
			width={width}
			footer={(
				<Space
					style={{ width: '100%', justifyContent: 'space-between' }}
				>
					<Button
						type='primary'
						ghost
						onClick={onClose}
					>
						<b>No, cancel</b>
					</Button>
					<Button
						type='primary'
						onClick={onFilter}
					>
						<b>Yes, confirm</b>
					</Button>
				</Space>
			)}
		>
			<FormBoxTitle
				title={title}
			/>
			{children}
		</ModalContainerBase>
	);
};

export default FilterModal;
