import { hexToRgb, mixColors } from '../../../../utils/common';
import { useTheme } from '@emotion/react';
import { FaPen } from 'react-icons/fa';

const CompanyFieldDisplayOverlayLabel = ({
	Icon = FaPen,
	label = 'Edit',
}) => {
	const className = 'CompanyFieldDisplayOverlayLabel';

	const theme = useTheme();

	return (
		<div style={{
			fontSize: 16,
			lineHeight: 1.2,
			fontWeight: 'bold',
			color: theme.color.fitBlueElectric,
			background: theme.color.white,
			padding: '10px 20px',
			borderRadius: 32,
			boxShadow: `0 0 30px -4px rgba(${hexToRgb(mixColors(theme.color.black, theme.color.fitBlueElectric, 20))?.join(',')},.3)`,
			zIndex: 9,
		}}
		>
			<Icon style={{ marginBottom: -2 }} />&nbsp;<b>{label}</b>
		</div>
	);
};

export default CompanyFieldDisplayOverlayLabel;
