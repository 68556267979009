import { documentId } from '../utils/common';
import { request } from './api';

const findAll = (params = {}) => {
	const dataParams = {
		params,
	};
	return request.get('/template/mail', dataParams);
};

const create = (data) => {
	return request.post('/template/mail', data);
};

const remove = (template) => {
	return request.delete(`/template/mail/${documentId(template)}`);
};

const getById = (templateId) => {
	return request.get(`/template/mail/${templateId}`);
};

const TemplateService = {
	create,
	remove,
	findAll,
	getById,
};

export default TemplateService;
