import { useEffect, useRef, useState } from 'react';
import CompanyModel from '../../../../../models/CompanyModel';
import GenderScoreLevelNextBlock from '../blocks/GenderScoreLevelBlock';

const CompanyGenderScoreLevelNextBox = ({
	company,
	width = 100,
}) => {
	const companyModel = new CompanyModel(company);
	const ref = useRef();
	const [levelWidth, setLevelWidth] = useState(width);

	useEffect(() => {
		if (ref?.current) {
			setLevelWidth(ref?.current?.offsetWidth);
		}
	}, [ref]);

	if (!companyModel.genderScore?.globalScore) {
		return null;
	}

	return <div ref={ref}>
		<GenderScoreLevelNextBlock
			score={companyModel.genderScore?.globalScore}
			width={levelWidth}
		/>
	</div>;
};
export default CompanyGenderScoreLevelNextBox;
