import { TAG_NOPRODENV, TAG_NOPRODENV_ICON } from '../../../constants/constant';
import BlockBase from './BlockBase';
import config from '../../../config/config';

const BlockNotProdEnv = ({
	withStaffView = true,
	withTags = true,
	withTagAsIcon = false,
	withBorder = true,
	children,
	...props
}) => {
	return (
		<BlockBase
			value={config.isNotProdEnv}
			tags={withTagAsIcon ? TAG_NOPRODENV_ICON : TAG_NOPRODENV}
			color='red'
			withStaffView={withStaffView}
			withTags={withTags}
			withBorder={withBorder}
			{...props}
		>
			{children}
		</BlockBase>
	);
};

export default BlockNotProdEnv;
