import { Tooltip } from 'antd';
import { FaMedal } from 'react-icons/fa';
import { getPillarLabel } from '../../../../constants/genderscore-pillars';
import { COMPANY_RECRUITER_LOG } from '../../../../constants/company-recruiter-log';
import { writeGenderScoreFilledLog } from '../../../../constants/genderscore-logs';
import CompanyModel from '../../../../models/CompanyModel';

const GenderScorePillarRankingPill = ({
	company,
	forceValue,
	pillar,
	index,
	...props
}) => {
	const className = 'GenderScorePillarRankingPill';

	const genderScore = forceValue || company?.genderScore;

	const pillarLabel = getPillarLabel(pillar);

	const rank = new CompanyModel(company).getGenderScoreRanking(pillar);
	const count = genderScore?.genderScores;

	return (
		<div
			key={index}
		>
			<Tooltip
				title={<>
					You rank <strong>#{rank}</strong> on all companies
					for the <strong>{pillarLabel}</strong> pillar
				</>}
				onOpenChange={() => {
					writeGenderScoreFilledLog({
						type: {
							element: `${pillar}-rank`,
							action: COMPANY_RECRUITER_LOG.HOVER,
						},
						context: 'pillars-breakdown',
					});
				}}
			>
				<div
					style={{
						color: '#FE14A0',
						userSelect: 'none',
					}}
				>
					<FaMedal
						style={{ marginBottom: -2 }}
					/>
					&nbsp;
					<i
						style={{
							fontSize: 12,
							letterSpacing: 2,
						}}>#</i>{rank}
				</div>
			</Tooltip>
		</div>
	);
};

export default GenderScorePillarRankingPill;
