import { Space } from 'antd';
import UserAvatar from '../user/widgets/UserAvatar';
import UserStubLocation from '../user/stub/UserStubLocation';
import UserStubProfessional from '../user/stub/UserStubProfessional';
import UserStubName from '../user/stub/UserStubName';
import SourcingLinkDropdown from './SourcingLinkDropdown';
import { SOURCE_DIRECT_APPLY_VALUE } from '../../constants/sourcing';

const SourcingStub = (
	{
		sourcing,
		// withExperience = true,
		// withHardSkill = true,
		withProfession = true,
		withLocation = true,
		withDropdown = true,
		direction = 'vertical',
		avatarSize = 36,
		activeTab = null,
		...props
	},
) => {
	const { user } = sourcing || {};

	const forceShow = sourcing?.source === SOURCE_DIRECT_APPLY_VALUE;

	const component = user && <Space direction="horizontal" size={12} {...props}>
		<UserAvatar size={avatarSize} user={user} forceShow={forceShow} withTooltip={false}/>
		<Space direction={direction} size={3}>
			<UserStubName
				user={user}
				forceShow={forceShow}
				withDropdown={true}
			/>
			{/* {withExperience && user?.career?.experience} */}
			{/* {withHardSkill && JSON.stringify(user?.career?.hardSkill)} */}
			{withProfession && <UserStubProfessional user={user}/>}
			{withLocation && <UserStubLocation user={user}/>}
		</Space>
	</Space>;

	return component;

	return (withDropdown
		? <SourcingLinkDropdown sourcing={sourcing} activeTab={activeTab}>
			{component}
		</SourcingLinkDropdown>
		: component
	);
};

export default SourcingStub;
