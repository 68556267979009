import PageLayout from '../../components/app/layout/PageLayout';
import GenderscoreRankingsMenu from '../../menu/genderscore/GenderscoreRankingsMenu';
import GenderScoreRankings from './GenderScoreRankings';

const GenderScoreRankingsPage = () => {
	const className = 'GenderScoreRankingsPage';

	return (
		<PageLayout
			MenuClass={GenderscoreRankingsMenu}
		>

			<GenderScoreRankings />

		</PageLayout>
	);
};

export default GenderScoreRankingsPage;
