import GridCardBase from '../../../app/grid/GridCardBase';
import FunnelUserCard from './FunnelUserCard';
import JsonPopoverLink from '../../../app/debug/JsonPopoverLink';
import JobStub from '../../../jobs/JobStub';
import { Divider, Space, Tag } from 'antd';
import moment from 'moment';
import SourcingStubLite from '../../../sourcings/SourcingStubLite';
import { isBoolean } from '../../../../utils/common';
import {
	SOURCING_PIPELINE_OPTION_INTERVIEW,
	SOURCING_STAGE_OPTIONS,
	sourcingSources,
} from '../../../../constants/constant';
import ActivityPreview from '../../../app/table/ActivityPreview';
import { useTheme } from '@emotion/react';

const FunnelSourcingCard = (
	{
		sourcing,
		isLoading,
		withSource = true,
		withCard = true,
	},
) => {
	const className = 'FunnelSourcingCard';
	const theme = useTheme();

	const renderSourceTag2 = (value, row) => {
		const { atsLabel, atsIcon } = (((sourcingSources.filter((s) => s.value === value) || [])[0]) || {});
		return <Space direction='horizontal'
					  size={4}
					  style={{
						  // padding: '2px 6px',
						  // background: '#eeeeff',
						  // borderRadius: 4
					  }}
					  align='center'
		>
			<div style={{ fontSize: 16, color: '#0000ff', marginBottom: -0 }}>{atsIcon}</div>
			<div style={{ whiteSpace: 'nowrap', fontWeight: 'bold' }}>{atsLabel}</div>
			{/* <Tag color={color} icon={icon} style={{fontWeight: 'bold'}}>{label}</Tag> */}
		</Space>;
	};
	const renderSourceTag = (value, row) => {
		const { label, icon } = (((sourcingSources.filter((s) => s.value === value) || [])[0]) || {});
		return <Space direction='horizontal' size={4}
					  style={{ padding: '3px 6px', background: '#eeeeff', borderRadius: 4 }} align='center'>
			<div style={{ fontSize: 12, color: '#0000ff', marginBottom: -4 }}>{icon}</div>
			<div style={{ fontSize: 12, whiteSpace: 'nowrap', fontWeight: 'bold' }}>{label}</div>
			{/* <Tag color={color} icon={icon} style={{fontWeight: 'bold'}}>{label}</Tag> */}
		</Space>;
	};

	const content = (
		sourcing
			? (
				<Space
					direction='vertical'
					style={{
						...sourcing?.isArchived
							? { opacity: 0.35 }
							: { opacity: 1 },
					}}
				>
					<SourcingStubLite
						sourcing={sourcing}
						withDropdown={false}
						withUserPreview={false}
						withJobEdit={false}
					/>
					{/* <FunnelUserCard */}
					{/*	user={sourcing?.user} */}
					{/*	isLoading={isLoading} */}
					{/*	withCard={false} */}
					{/*	withExperienceTag={false} */}
					{/*	withLocationTag={false} */}
					{/*	withSeekingTag={false} */}
					{/*	withProfession={false} */}
					{/* /> */}
					{/* <Divider style={{ margin: '4px 0' }} /> */}
					{/* <JobStub job={sourcing?.job} /> */}
					<Space direction='vertical' size={2}>
						<Space size={2} style={{ fontSize: 12 }}>
							<ActivityPreview
								createdAt={sourcing.createdAt}
								updatedAt={sourcing.updatedAt}
							/>
							{withSource && renderSourceTag2(sourcing?.source)}
							{SOURCING_STAGE_OPTIONS.find((o) => o.label === (sourcing?.atsStage || sourcing?.stage))?.label || (sourcing?.atsStage || sourcing?.stage)}
						</Space>
						{/* <Space size={2} wrap={true}> */}
						{/*	<Tag color={'blue'}>{sourcing?.source}</Tag> */}
						{/*	/!*<Tag>{sourcing?.candidateStage}</Tag>*!/ */}
						{/* </Space> */}
						{/* <Space size={2} wrap={true}> */}
						{/*	<Tag>{sourcing?.atsStage || sourcing?.stage}</Tag> */}
						{/*	/!*<Tag>{sourcing?.candidateStage}</Tag>*!/ */}
						{/* </Space> */}
						{/* <Space size={2} wrap={true}> */}
						{/*	<ActivityPreview */}
						{/*		createdAt={sourcing.createdAt} */}
						{/*		updatedAt={sourcing.updatedAt} */}
						{/*	/> */}
						{/*	<Tag>{moment(sourcing.createdAt).fromNow()}</Tag> */}
						{/*	<Tag>{moment(sourcing.updatedAt).fromNow()}</Tag> */}
						{/* </Space> */}
					</Space>
				</Space>
			)
			: null
	);

	if (!sourcing || !withCard) return content;

	return (
		<GridCardBase
			style={{
				padding: '8px 10px',
				height: 'auto',
				minHeight: 60,
				borderRadius: 5,
				...sourcing?.isArchived
					? { border: 'none', background: theme.color.bgGrey }
					: {},
			}}
		>
			{content}
		</GridCardBase>
	);
};

export default FunnelSourcingCard;
