import {
	Divider, Popover, Space, Tooltip,
} from 'antd';
import BlockStaff from '../../app/blocker/BlockStaff';
import CompanySettingsCard, { CompanySettingsCardDotOk, CompanySettingsCardLineOk } from './CompanySettingsCard';
import { useTheme } from '@emotion/react';
import CompanyModel, { GENDERSCORE_PASSING_DISALLOWED_REASONS, GENDERSCORE_PASSING_DISALLOWED_USER_ROLE_VALUE } from '../../../models/CompanyModel';
import { GENDERSCORE_EMPTY_ICON_IMG, GENDERSCORE_LABEL, NOT_PROVIDED_YET_LABEL } from '../../../constants/constant';
import CompanySettingsUMSForm from './CompanySettingsGenderScoreForm';
import { getGenderScoreSurveyLabel } from '../../../constants/property/genderscore-survey';
import UserStub from '../../user/stub/UserStub';
import Link from '../../app/Link';
import { useAuthContext } from '../../../hooks/useAuthContext';
import { mixColors } from '../../../utils/common';
import UserName from '../../user/widgets/UserName';
import Box from '../../app/box/Box';
import { FaBan } from 'react-icons/fa';

export const GenderScoreSurveyStatusDot = ({
	company,
	size = 32,
	...props
}) => {
	const theme = useTheme();
	const authProps = useAuthContext();
	const companyModel = new CompanyModel(company);
	const isPassingAllowed = companyModel?.isGenderScorePassingAllowed(authProps);

	const popoverTitle = `Is ${GENDERSCORE_LABEL} passing allowed?`;
	let popoverContent = <>Allowed for <b><UserName user={company?.genderScoreSurveyOwner} /></b></>;

	if (!isPassingAllowed) {
		popoverContent = (
			<>
				Passing is not allowed.
				<br/>Blocking reasons:
				<ul style={{ paddingLeft: 16 }}>
					{companyModel
						?.getGenderScorePassingDisallowedReasons()
						?.filter((reason) => (
							![
								GENDERSCORE_PASSING_DISALLOWED_REASONS.USER_ROLE,
								GENDERSCORE_PASSING_DISALLOWED_REASONS.USER_NOT_OWNER,
							].includes(reason)
						))
						?.map((reason, index) => (
							<li
								key={index}
							>
								{reason}
							</li>
						))
					}
				</ul>

			</>
		);
	}
	return (
		<Popover
			title={popoverTitle}
			content={popoverContent}
		>
			<CompanySettingsCardDotOk
				ok={isPassingAllowed}
				size={size}
				style={{
					background: isPassingAllowed
						? mixColors(theme.color.darkturquoise, theme.color.white, 80)
						: mixColors(theme.color.red, theme.color.white, 80),
					borderRadius: size,
					width: size,
					height: size,
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
					...props?.style,
				}}
			/>
		</Popover>
	);
};

export const GenderScoreSurveyStatusLine = ({
	company,
	withTooltip = false,
	...props
}) => {
	const theme = useTheme();
	const authProps = useAuthContext();
	const companyModel = new CompanyModel(company);
	const isPassingAllowed = companyModel?.isGenderScorePassingAllowed(authProps);

	return (
		<CompanySettingsCardLineOk
			ok={isPassingAllowed}
			style={{
				background: isPassingAllowed
					? mixColors(theme.color.darkturquoise, theme.color.white, 80)
					: mixColors(theme.color.red, theme.color.white, 80),
				borderRadius: 8,
				fontWeight: 'bold',
				padding: '4px 12px',
				...props?.style,
			}}
			okText={(
				<>
					{GENDERSCORE_LABEL} passing is allowed
				for <UserName user={company?.genderScoreSurveyOwner} />
				</>
			)}
			koText={(
				<>
					{GENDERSCORE_LABEL} passing is not allowed,&nbsp;
					<Popover
						title='Blocking reasons'
						content={
							<ul>
								{companyModel
									?.getGenderScorePassingDisallowedReasons()
									?.filter((reason) => (
										![
											GENDERSCORE_PASSING_DISALLOWED_REASONS.USER_ROLE,
											GENDERSCORE_PASSING_DISALLOWED_REASONS.USER_NOT_OWNER,
										].includes(reason)
									))
									?.map((reason, index) => (
										<li
											key={index}
										>
											{reason}
										</li>
									))
								}
							</ul>
						}>
						<Link>see why</Link>
					</Popover>
				</>
			)}
		/>
	);
};

const CompanySettingsGenderScoreDaysToExpire = (
	{ theme, daysToExpire, isExpirationOfCurrentBadge },
) => {
	let style = {
		fontWeight: 'bold',
	};
	if (daysToExpire < 30) {
		style = {
			...style,
			color: theme.color.red,
		};
	}
	return <Tooltip
		title={isExpirationOfCurrentBadge
			? <>
				<p>Used to show validity of the {GENDERSCORE_LABEL} badge to the Talents, and to encourage the Clients to renew their {GENDERSCORE_LABEL}</p>
				<p>This date is automatically calculated with regards the current published {GENDERSCORE_LABEL} purchase date. A renewal of the GS will not affect this date</p>
			</>
			: <>
				<p>Used to define the end of the {GENDERSCORE_LABEL} contract</p>
				<p>This date is automatically calculated as 1 year after the purchase date, but it can be customized. Any renewal will affect directly this date</p>
			</>
		}
	>
		<span style={style}>
		📆 {Math.abs(daysToExpire)} days
		</span>
	</Tooltip>;
};
const CompanySettingsGenderScoreCardDivider = ({
	title,
}) => <Divider
	style={{
		marginBottom: 10,
		marginTop: 10,
	}}
	orientation='left'
>
	<span style={{
		fontSize: 14,
		fontWeight: 600,
	}}>
		{title}
	</span>
</Divider>;
const CompanySettingsGenderScoreCard = (
	{
		company,
		...props
	},
) => {
	const className = 'CompanySettingsGenderScoreCard';
	const theme = useTheme();

	const authProps = useAuthContext();

	const companyModel = new CompanyModel(company);

	const isPassingAllowed = companyModel?.isGenderScorePassingAllowed(authProps);
	const daysToExpireGS = companyModel?.getGenderScoreDaysToExpire(
		{ isExpirationOfCurrentBadge: false },
	);
	const daysToExpireGSCurrentBadge = companyModel?.getGenderScoreDaysToExpire(
		{ isExpirationOfCurrentBadge: true },
	);

	return (
		<div style={{ position: 'relative' }}>
			<BlockStaff withBorder={false} tagStyle={{ top: 2, left: 24, opacity: 1 }}>
				<CompanySettingsCard
					title={(
						<div style={{ display: 'flex', alignItems: 'center' }}>
							{GENDERSCORE_EMPTY_ICON_IMG()}
							&nbsp;
							{GENDERSCORE_LABEL}
						</div>
					)}
					details={<>
						{GENDERSCORE_LABEL} settings for this company
					</>}
					company={company}
					CompanySettingsForm={CompanySettingsUMSForm}
					modalWidth={600}
				>
					<Space direction='vertical'>

						<CompanySettingsCardLineOk
							ok={companyModel?.isGenderScoreEnabled()}
							okText={<>{GENDERSCORE_LABEL} is enabled for <b>{company?.name}</b></>}
							koText={<>{GENDERSCORE_LABEL} is disabled for <b>{company?.name}</b></>}
						/>

						{companyModel?.isGenderScoreEnabled() && (
							<>
								{isNaN(daysToExpireGS)
									? <Space
										direction='horizontal'
										align='top'
									>
										<FaBan style={{ marginBottom: -2, color: theme.color.red }} />
										<div
											style={{
												verticalAlign: 'top',
											}}
										>
										There is no <b>Purchase date</b> configured for the {GENDERSCORE_LABEL} of  <b>{company?.name}</b>
										</div>
									</Space>
									: <CompanySettingsCardLineOk
										ok={!companyModel?.isGenderScoreExpired()}
										label={GENDERSCORE_LABEL}
										okText={<> will expire in <CompanySettingsGenderScoreDaysToExpire
											theme={theme}
											daysToExpire = {daysToExpireGS}
											isExpirationOfCurrentBadge={false}
										/></>}
										koText={<span style={{
											fontWeight: 'bold',
											color: theme.color.red,
										}}> expired <CompanySettingsGenderScoreDaysToExpire
												theme={theme}
												daysToExpire = {daysToExpireGS}
												isExpirationOfCurrentBadge={false}
											/> ago</span>}
									/>
								}
								<CompanySettingsGenderScoreCardDivider title='Survey'/>
								<CompanySettingsCardLineOk
									ok={companyModel?.isGenderScoreSurveyEnabled()}
									okText={<><b>{company?.name} has access </b> to the {GENDERSCORE_LABEL} <b>Survey</b></>}
									koText={<><b>{company?.name} doesn't have access </b> to the {GENDERSCORE_LABEL} <b>Survey</b></>}
								/>
								<CompanySettingsCardLineOk
									ok={companyModel?.getCompanyPlanGenderScoreSurvey()}
									label={<b>{GENDERSCORE_LABEL} Survey:&nbsp;</b>}
									okText={getGenderScoreSurveyLabel(
										companyModel?.getCompanyPlanGenderScoreSurvey(),
									)}
									koText={NOT_PROVIDED_YET_LABEL}
								/>

								<CompanySettingsCardLineOk
									ok={company?.genderScoreSurveyOwner}
									label={<b>Survey owner:&nbsp;</b>}
									okText={(
										<Box style={{ display: 'inline-block' }}>
											<UserStub
												user={company?.genderScoreSurveyOwner}
												avatarSize={32}
												withLocation={false}
												withProfession={true}
												withCompanyLogo={false}
												withIsUnviewed={false}
												withCoachedTag={false}
												withResumeTag={false}
												withBox={true}
											/>
										</Box>
									)}
									koText={NOT_PROVIDED_YET_LABEL}
								/>

								<GenderScoreSurveyStatusLine
									company={company}
									style={{ marginTop: 15, marginBottom: 10 }}
								/>
								<CompanySettingsGenderScoreCardDivider title='Results'/>
								<CompanySettingsCardLineOk
									ok={companyModel?.isGenderScoreBadgeStaffPublished()}
									label={<>{GENDERSCORE_LABEL} <b>Badge</b>: </>}
									okText={<>{companyModel?.isGenderScoreFilled()
										? <b>👀 visible to the Talents</b>
										: <><b>👀 visible to the Talents</b> (once the Survey is submitted)</>} </>}
									koText={<>{
										companyModel?.isGenderScoreFilled()
											? <b>🙈 hidden from the Talents</b>
											: <><b>🙈 hidden from the Talents</b> (once the Survey is submitted)</>}</>}
								/>
								{companyModel?.isGenderScoreBadgeStaffPublished()
									&& !isNaN(daysToExpireGSCurrentBadge)
									&& (
										<>
											<CompanySettingsCardLineOk
												ok={!companyModel?.isCurrentGenderScoreBadgeExpired()}
												label={<>The <b>current</b> {GENDERSCORE_LABEL} <b>Badge</b>: </>}
												okText={<> will expire in <CompanySettingsGenderScoreDaysToExpire
													theme={theme}
													daysToExpire = {daysToExpireGSCurrentBadge}
													isExpirationOfCurrentBadge={true}
												/></>}
												koText={<span style={{
													fontWeight: 'bold',
													color: theme.color.red,
												}}> expired <CompanySettingsGenderScoreDaysToExpire
														theme={theme}
														daysToExpire = {daysToExpireGSCurrentBadge}
														isExpirationOfCurrentBadge={true}
													/> ago</span>}
											/>
											{companyModel?.isNewGenderScoreExpirationDateSet()
											&& (
												<CompanySettingsCardLineOk
													ok={!companyModel?.isGenderScoreExpired()}
													label={<>The <b>next</b> {GENDERSCORE_LABEL} <b>Badge</b>: </>}
													okText={<> will expire in <CompanySettingsGenderScoreDaysToExpire
														theme={theme}
														daysToExpire = {daysToExpireGS}
														isExpirationOfCurrentBadge={false}
													/></>}
													koText={<span style={{
														fontWeight: 'bold',
														color: theme.color.red,
													}}> expired <CompanySettingsGenderScoreDaysToExpire
															theme={theme}
															daysToExpire = {daysToExpireGS}
															isExpirationOfCurrentBadge={false}
														/> ago</span>}
												/>
											)
											}
										</>
									)}
							</>
						)}
					</Space>

				</CompanySettingsCard>

			</BlockStaff>
		</div>
	);
};

export default CompanySettingsGenderScoreCard;
