export const asCategoryTag = (category, slug) => [category, slug].join(':');

export const asCategoryTagOption = (category, value, label, group) => ({
	category,
	slug: asCategoryTag(category, value),
	label: (label && label !== '' ? label : value),
	value,
	group,
});

export const asCategoryTagOptionGroup = (category, value, label, group) => ({
	category,
	slug: asCategoryTag(category, value),
	label: (label && label !== '' ? label : value),
	value,
	group,
});

export const unwrapCategoryFromSlug = (slug) => slug.split(':')[1];

export const asyncIdentityFilter = async (item) => item;

export const buildQueryScalarFieldExactMatch = (field, value) => ({
	[field]: value,
});

export const buildQueryTagSlugScalarFieldExactMatch = (field) => async (tag) => {
	const { slug } = tag;
	return buildQueryScalarFieldExactMatch(field, unwrapCategoryFromSlug(slug));
};

export const buildQueryTagLabelScalarFieldExactMatch = (field) => async (tag) => {
	const { label } = tag;
	return buildQueryScalarFieldExactMatch(field, label);
};

export const buildQueryArrayFieldExactMatch = (field, value) => ({
	[field]: { $elemMatch: { $eq: value } },
});

export const buildQueryTagLabelArrayFieldExactMatch = (field) => async (tag) => {
	const { label } = tag;
	return buildQueryArrayFieldExactMatch(field, label);
};

export const buildQueryTagSlugArrayFieldExactMatch = (field) => async (tag) => {
	const { slug } = tag;
	return buildQueryArrayFieldExactMatch(field, unwrapCategoryFromSlug(slug));
};

export default {
	asCategoryTag,
	asCategoryTagOption,
	asCategoryTagOptionGroup,
	unwrapCategoryFromSlug,
	asyncIdentityFilter,
	buildQueryTagLabelScalarFieldExactMatch,
	buildQueryTagSlugScalarFieldExactMatch,
	buildQueryTagLabelArrayFieldExactMatch,
	buildQueryTagSlugArrayFieldExactMatch,
};
