import { useState } from 'react';
import { ENGLISH_LANG } from '../../../../constants/constant';
import CompanyFieldEditor from './CompanyFieldEditor';
import { AutoComplete } from 'antd';

const CompanyAutoCompleteEditor = (
	{
		company,
		lang = ENGLISH_LANG,
		title,
		details,
		field,
		options = [],
		isTranslatable = true,
		onSearch,
		setEditorOpen,
	},
) => {
	const className = 'CompanyAutoCompleteEditor';

	const [editedValue, setEditedValue] = useState(company.getTranslationField(field, lang));

	return (
		<CompanyFieldEditor
			company={company}
			lang={lang}
			setEditorOpen={setEditorOpen}
			title={title}
			details={details}
			field={field}
			isTranslatable={isTranslatable}
			editedValue={editedValue}
		>
			<AutoComplete
				showSearch={true}
				onSearch={onSearch}
				options={options}
				value={editedValue}
				onChange={setEditedValue}
				style={{ width: '100%' }}
			/>

		</CompanyFieldEditor>
	);
};

export default CompanyAutoCompleteEditor;
