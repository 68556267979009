import { Table } from 'antd';
import TableHeader from '../../app/table/TableHeader';
import EmptyData from '../../app/EmptyData';
import JobFilterModal from '../../jobs/JobFilterModal';
import FilterButton from '../../app/filters/FilterButton';
import { localDebug } from '../../../utils/LocalDebug';

const JobTable = ({
	dataSource = [],
	user,
	columns,
	onFilter,
	onClearFilter,
	isLoading,
	initialValues = {},
	...otherProps
}) => {
	try {
		return (
			<>
				<FilterButton
					title='Filter jobs'
					modal={JobFilterModal}
					onFilter={onFilter}
					onClearFilter={onClearFilter}
					initialValues={initialValues}
				/>
				{!isLoading && dataSource?.length > 0
					? (
						<Table
							title={() => <TableHeader total={dataSource.length}/>}
							dataSource={dataSource}
							loading={isLoading}
							columns={columns}
							size="middle"
						/>
					)
					: (
						<EmptyData
							description={(
								<span>
									<strong>{user?.firstName}</strong> doesn't match any of your jobs.
								</span>
							)}
						/>
					)
				}
			</>
		);
	} catch (e) {
		localDebug(e);
		return null;
	}
};

export default JobTable;
