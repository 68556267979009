import { NOT_PROVIDED_YET_LABEL } from '../../../../constants/constant';
import UserTagsList from '../UserTagsList';

const UserProfileJobSearchJobLocationsCardBody = (
	{
		user,
		notProvided = NOT_PROVIDED_YET_LABEL,
	},
) => {
	return (
		<UserTagsList
			values={
				user
					?.getJobSearchJobLocations?.({
						sorted: true,
						withCountryCode: false,
						withCountryFlag: true,
						withCityCountry: true,
						withCityCountryCode: true,
						withCityCountryFlag: true,
					})
			}
			notProvided={notProvided}
		/>
	);
};

export default UserProfileJobSearchJobLocationsCardBody;
