import { useEffect, useRef, useState } from 'react';
import SourcingService from '../../services/sourcing';
import SourcingATSOpenLargeButton from './buttons/SourcingATSOpenLargeButton';
import SourcingHistory from './SourcingHistory';
import SourcingATSHistory from './SourcingATSHistory';
import SourcingJson from './SourcingJson';
import { useAuthContext } from '../../hooks/useAuthContext';
import Drawer from '../app/drawer/Drawer';
import { Tabs } from 'antd';
import { TAG_STAFF } from '../../constants/constant';
import UserHeader from '../user/widgets/UserHeader';
import UserProfile from '../user/profile/UserProfile';
import JobHeader from '../jobs/JobHeader';
import JobDetails from '../jobs/JobDetails';
import { documentId } from '../../utils/common';

const SourcingDrawer = (
	{
		open,
		sourcing,
		title,
		width = 900,
		activeTab,
		onClose,
		...props
	},
) => {
	const container = useRef();

	const { isStaffView } = useAuthContext();

	const [isLoading, setIsLoading] = useState(false);
	const [sourcingModel, setSourcingModel] = useState(null);

	const fetchSourcing = async (sourcingId) => {
		const { data: { sourcing: data } } = await SourcingService.getById(sourcingId);
		setSourcingModel({
			...data,
			job: {
				...data.job,
				company: sourcing.company,
			},
		});
		setIsLoading(false);
	};

	useEffect(() => {
		if (!open) {
			return;
		}
		if (!sourcing) {
			setSourcingModel(null);
			setIsLoading(false);
			return;
		}
		if (!isLoading) {
			setIsLoading(true);
			fetchSourcing(documentId(sourcing, sourcing?.slug));
		}
	}, [sourcing]);

	const handleClose = () => {
		setSourcingModel(null);
		if (onClose instanceof Function) onClose();
	};

	return (sourcingModel && !isLoading && <Drawer
		// title={title || <SourcingHeader sourcing={sourcingModel} user={sourcingModel.user} />}
		width={width}
		className="withAffix"
		onClose={handleClose}
		headerStyle={{ align: 'start', justifyContent: 'start', alignItems: 'start' }}
		{...props}
		open={open && sourcingModel && !isLoading}
	>
		<div ref={container}>
			<SourcingATSOpenLargeButton sourcing={sourcingModel}/>
			<Tabs>
				<Tabs.TabPane tab='Talent' key='user'>
					<UserHeader user={sourcingModel.user}/>
					<UserProfile user={sourcingModel.user}/>
				</Tabs.TabPane>
				<Tabs.TabPane tab='Job' key='job'>
					{documentId(sourcingModel?.job)
						? <>
							<JobHeader job={sourcingModel.job}/>
							<JobDetails job={sourcingModel.job}/>
						</>
						: 'No job selected yet'
					}
				</Tabs.TabPane>
				<Tabs.TabPane tab='History' key='history'>
					<SourcingHistory sourcing={sourcingModel}/>
				</Tabs.TabPane>
				{isStaffView && <Tabs.TabPane tab={<>JSON {TAG_STAFF}</>} key='json'>
					<SourcingJson sourcing={sourcingModel}/>
				</Tabs.TabPane>}
				{isStaffView && sourcingModel?.atsSync
					&& <Tabs.TabPane tab={<>ATS Sync data {TAG_STAFF}</>} key='ats-sync'>
						<SourcingATSHistory sourcing={sourcingModel}/>
					</Tabs.TabPane>}
			</Tabs>
		</div>
	</Drawer>);
};

export default SourcingDrawer;
