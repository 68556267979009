import {
	Divider, Space, Tooltip, Typography,
} from 'antd';
import UserAvatar from './UserAvatar';
import UserStubProfessional from '../stub/UserStubProfessional';
import UserStubLocation from '../stub/UserStubLocation';
import { useTheme } from '@emotion/react';
import { useAuthContext } from '../../../hooks/useAuthContext';
import UserSourcingAddBtn from '../profile/UserSourcingAddBtn';
import { ADD_TO_TALENT_POOL_LABEL } from '../../../constants/constant';
import MessageBtn from '../../messages/MessageBtn';
import UserHider from '../buttons/UserHider';
import UserShortlister from '../buttons/UserShortlister';
import UserContacter from '../buttons/UserContacter';
import UserSourcer from '../buttons/UserSourcer';
import UserLinkFullPage from './UserLinkFullPage';
import UserLinkPageCopy from './UserLinkPageCopy';
import UserLinkAppLeads from './UserLinkAppLeads';
import UserLinkSlugCopy from './UserLinkSlugCopy';
import UserLinkAppCoachingProfile from './UserLinkAppCoachingProfile';
import UserLinkAppPublicProfile from './UserLinkAppPublicProfile';
import UserAtsSender from '../buttons/UserAtsSender';
import UserSaver from '../buttons/UserSaver';
import UserName from './UserName';
import UserLinkCoaching from './UserLinkCoaching';
import UserStubName from '../stub/UserStubName';
import { documentId } from '../../../utils/common';
import { SOURCE_50INTECH_VALUE, SOURCE_RECRUITER_VALUE } from '../../../constants/sourcing';

const UserHeader = (
	{
		user,
		isAffixedHead = false,
		onRefresh,
	},
) => {
	const {
		isAtsSyncable, isAdmin, isStaff, isStaffView, isTalent, isCompanyRecruiter, isDocumentAdmin, companyIdsUser,
	} = useAuthContext();
	const theme = useTheme();

	const addSourcingBtn = <UserSourcingAddBtn
		title={ADD_TO_TALENT_POOL_LABEL}
		user={user}
		initialValues={{
			userId: documentId(user),
			source: isTalent ? SOURCE_50INTECH_VALUE : SOURCE_RECRUITER_VALUE,
			user,
			viaProfile: true,
			companyIds: !isStaffView ? companyIdsUser : null,
		}}
		sourcings={user.sourcings}
		style={{ marginBottom: 0 }}
	/>;

	const messageBtnDisabled = (disabled) => <MessageBtn
		// title={`Contact ${`user.firstName`}`}
		user={user}
		// disabled={disabled}
		initialValues={{
			userToId: documentId(user),
			userTo: user,
			viaProfile: true,
			companyIds: !isStaffView ? companyIdsUser : null,
		}}
		messages={user.messagesTo}
		style={{ marginBottom: 0 }}
	/>;

	const messageBtn = user?.sourcings?.length
		? messageBtnDisabled(false)
		: <Tooltip title='Add to your Talent Pool to be able to contact this talent!'>
			{messageBtnDisabled(true)}
		</Tooltip>;

	return (user
		&& <>
			<Space
				direction='vertical'
				size={10}
				{...isAffixedHead ? { className: 'affixedHead' } : {}}
			>
				<Space
					direction='horizontal'
					size={20}
					align='start'
					style={{ width: '100%' }}
				>
					<UserAvatar user={user} size={80} withTooltip={false} />
					<Space direction='vertical' size={0}>
						<Space direction='horizontal' size={8} wrap={true}>
							<UserStubName
								user={user}
								withJSONPopover={true}
								style={{ fontSize: 24, color: theme.color.blue }}
							/>
						</Space>
						<UserStubProfessional user={user} level={5} />
						<UserStubLocation user={user} level={5} />
						<Space size={10}>
							<UserLinkFullPage user={user} />
							<UserLinkPageCopy user={user} label='Copy link' />
							{isStaffView && <UserLinkAppPublicProfile user={user} />}
							{isStaffView && <UserLinkAppLeads user={user} />}
							{isStaffView && <UserLinkAppCoachingProfile user={user} />}
							{isStaffView && <UserLinkCoaching user={user} />}
							{isStaffView && <UserLinkSlugCopy user={user} label={user.slug} />}
						</Space>
					</Space>
				</Space>
				{/* <Divider style={{margin: '0 -24px -24px 0'}}/> */}
				<Space direction='horizontal' size={10} style={{ width: '100%' }}>
					{(isCompanyRecruiter || isDocumentAdmin)
						&& <Space direction='horizontal' size={10} align='start'>
							<UserSaver user={user} onComplete={onRefresh}
									   mode={isAtsSyncable ? 'saved' : 'shortlists'} />
							<UserContacter user={user} onComplete={onRefresh} />
							{isAtsSyncable
								? <UserAtsSender user={user} onComplete={onRefresh} />
								: <UserSourcer user={user} onComplete={onRefresh} />
							}
							<Divider type='vertical' />
							<UserHider user={user} onComplete={onRefresh} />
							{/* {addSourcingBtn} */}
							{/* {messageBtn} */}
						</Space>}
					{isStaffView && <>
						<Space>
							<div style={{ width: '100%' }}>&nbsp;</div>
						</Space>
						{/* <Space direction='horizontal' size={10}> */}
						{/*    <UserResumeManager user={user} onUpload={onRefresh}/> */}
						{/* </Space> */}
					</>}
				</Space>

				{/* <Space direction='horizontal' size={10} align='center' style={{width: '100%'}}> */}
				{/*    <UserResumeManager user={user} onUpload={onRefresh}/> */}
				{/*    {user?.resume?.updatedAt && */}
				{/*    <Alert type="info" */}
				{/*           message={<> */}
				{/*        Last PDF uploaded&nbsp; */}
				{/*                   {moment(user?.resume?.updatedAt).fromNow()}&nbsp; */}
				{/*                   ({moment(user?.resume?.updatedAt).format('YYYY-MM-DD')})&nbsp; */}
				{/*                   - by <UserStub user={user?.resume?.uploader} avatarSize={20} withIsNew={false} withIsUnviewed={false} withLocation={false} withProfession={false} /> */}
				{/*           </>} /> */}
				{/*    } */}
				{/* </Space> */}
			</Space>
		</>
	);
};

export default UserHeader;
