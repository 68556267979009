import { useTheme } from '@emotion/react';
import { Divider, Space } from 'antd';
import { RELEVANT_DATA_SHARED_FIELDS } from '../../../../constants/company-relevant-data';
import { ENGLISH_LANG } from '../../../../constants/constant';
import CompanyFieldNotProvided from '../shared/CompanyFieldNotProvided';
import CompanyRelevantDataLine from './CompanyRelevantDataLine';
import { LocalDebug } from '../../../../utils/LocalDebug';

const CompanyRelevantDataDisplay = (
	{
		company,
		lang = ENGLISH_LANG,
	},
) => {
	const className = 'CompanyRelevantDataDisplay';

	const theme = useTheme();

	const field = 'relevantData';
	const value = [
		...(RELEVANT_DATA_SHARED_FIELDS || [])
			.map((data) => ({
				key: data?.key,
				label: data?.[lang],
				value: company?.[data?.key],
			}))
			.filter((data) => data && data?.value !== null && data?.value >= 0)
			.map((data) => ({ ...data, value: `${data.value}%` })),
		...company?.getTranslationFieldFallback(field, lang) || [],
	];

	const content = value?.length > 0
		? (
			<Space
				direction='vertical'
				size={0}
				style={{
					width: '100%',
					// justifyContent: 'center',
					// border: '1px solid red',
					margin: '0px 0 20px',
				}}
			>
				{value.map((item, i) => (
					<div key={i}>
						{i >= 0 && <Divider style={{ margin: 0 }} />}
						<CompanyRelevantDataLine data={item} style={{ opacity: i < 6 ? 1 : 0.5 }} />
					</div>
				))}
			</Space>
		)
		: <CompanyFieldNotProvided />;

	return (
		<>
			<h2>Relevant data</h2>
			{content}
		</>
	);
};

export default CompanyRelevantDataDisplay;
