import { FaExclamationTriangle, FaExternalLinkAlt } from 'react-icons/fa';
import { hexToRgb, mixColors, openExternalUrl } from '../../../../utils/common';
import { ENGLISH_LANG, FRENCH_LANG } from '../../../../constants/constant';
import {
	Button, Space, Switch, Tooltip,
} from 'antd';
import Box from '../../../app/box/Box';
import CompanyPublicationWithTranslationStatus from '../../CompanyPublicationWithTranslationStatus';
import { useContext } from 'react';
import { useTheme } from '@emotion/react';
import { CompanyPageContext } from './CompanyPageProvider';
import CompanyPageLangEditionSwitch from './CompanyPageLangEditionSwitch';

const CompanyPageHeaderLegacy = (
	{
		company,
		...props
	},
) => {
	const className = 'CompanyPageHeaderLegacy';

	const theme = useTheme();

	const { lang, setLang } = useContext(CompanyPageContext);

	let publicationStatusColor = theme.color.accent;

	if (company.isPublishedNow?.()) {
		publicationStatusColor = theme.color.darkturquoise;
	} else if (company.isPublishedFuture?.()) {
		publicationStatusColor = theme.color.fitBlueElectric;
	}

	return (
		<Space
			// onClick={handleClickBanner}
			style={{
				width: '100%',
				padding: '12px 32px',
				justifyContent: 'space-between',
				background: mixColors(theme.color.white, publicationStatusColor, 5),
			}}
		>
			<Space size={30}>
				<Box style={{
					border: `1px dashed rgba(${hexToRgb(publicationStatusColor).join(',')},.5)`,
					borderRadius: 40,
				}}>
					<CompanyPublicationWithTranslationStatus company={company}/>
				</Box>
			</Space>
			<Space>
				<CompanyPageLangEditionSwitch
					company={company}
				/>
				<Tooltip title={<Space direction='vertical' style={{ lineHeight: 1.3 }}>
								Opens the public version of the company page
					{!company?.isPublishedNow() && (<span style={{ fontSize: 12 }}>
						<FaExclamationTriangle />&nbsp;
						<i><b>Company is not yet published, you will need to be logged to see the page.</b></i>
					</span>)}
				</Space>}>
					<Button
						type='primary'
						style={{
							borderRadius: 5,
							minWidth: 120,
						}}
						onClick={() => openExternalUrl(company?.getAppUri())}
					>
						<b>Preview</b>&nbsp;<FaExternalLinkAlt style={{ fontSize: 12, marginBottom: -1 }} />
					</Button>
				</Tooltip>
			</Space>
		</Space>
	);
};

export default CompanyPageHeaderLegacy;
