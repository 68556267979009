import chalk from 'chalk';
import config from '../config/config';

const arrayWrap = (value) => { return value ? (Array.isArray(value) ? value : [value]) : value; };

export const applyStyles = (str, styles) => {
	let styled = str;
	arrayWrap(styles).forEach((s) => { styled = s(styled); });
	return styled;
};

export const logSourceMethod = ({ styles, source, method = 'constructor' }, ...args) => {
	// return;
	if (config.isProdVersion) return;
	console.log(applyStyles(`${chalk.bold(`[${source}]`)} ${method}`, styles), ...args);
};

export const logUseEffect = ({ styles, className, effects = 'NOT PROVIDED' }, ...args) => {
	if (config.isProdVersion) return;
	logSourceMethod({
		styles: styles || [chalk.grey],
		source: className,
		method: `✨ useEffect([${effects}])`,
	}, ...args);
};

export const logInfo = ({ styles, className, method = 'constructor' }, ...args) => {
	if (config.isProdVersion) return;
	logSourceMethod({
		styles: styles || [chalk.dim, chalk.grey],
		source: className,
		method,
	}, ...args);
};

export const logAPICall = ({ styles, className, method = 'constructor' }, ...args) => {
	if (config.isProdVersion) return;
	logSourceMethod({
		styles: styles || [chalk.dim, chalk.yellow],
		source: className,
		method: `📡️ ${method}`,
	}, ...args);
};

export const logAPIResult = ({ styles, className, method = 'constructor' }, ...args) => {
	if (config.isProdVersion) return;
	logSourceMethod({
		styles: styles || [chalk.dim, chalk.green],
		source: className,
		method: `✅️ ${method}`,
	}, ...args);
};

export const logError = ({
	styles, className, method, __filename, error,
}, ...args) => {
	if (config.isProdVersion) return;
	logSourceMethod({
		styles: styles || [chalk.dim, chalk.grey],
		source: className,
		method,
	}, __filename?.split?.('/')?.shift(), error, ...args);
};

export const logNull = () => { };

export const LocalDebug = {
	decorators: {
		USE_EFFECT: 'USE_EFFECT',
	},
	logUseEffect,
	logInfo,
	logError,
	logAPICall,
	logAPIResult,
	logNull,
	// logUseEffect2,
};

export const localDebug = (...args) => {
	// return;
	if (!config.isProdVersion) {
		console.log(...args);
	}
};
