import { Tooltip } from 'antd';
import {
	ArrowDownIcon, ArrowRightIcon, ArrowUpIcon, addSignToNum, mixColors,
} from '../../../../utils/common';
import {
	getPillarColorLight,
	getPillarColorMain,
	getPillarIcon,
	getPillarLabel,
	getPillarPath,
} from '../../../../constants/genderscore-pillars';
import CompanyGenderScoreRingScore from '../CompanyGenderScoreRingScore';
import GenderScoreLayoutBox from '../layout/GenderScoreLayoutBox';
import GenderScorePillarRankingPill from './GenderScorePillarRankingPill';
import { useNavigate } from 'react-router';
import Link from '../../../app/Link';
import { useTheme } from '@emotion/react';
import {
	GENDERSCORE_LOG_FROM_ELEMENT_SEE_PILLAR_LINK,
	writeGenderScoreFilledLog,
} from '../../../../constants/genderscore-logs';
import { COMPANY_RECRUITER_LOG } from '../../../../constants/company-recruiter-log';

const GenderScorePillarScoreBox = ({
	company,
	forceValue,
	pillar,
	withStats = true,
	withLink = false,
	index,
	...props
}) => {
	const className = 'GenderScorePillarScoreBox';

	const theme = useTheme();

	const navigate = useNavigate();

	const genderScore = forceValue || company?.genderScore;

	const boxStyle = {
		backgroundImage: `radial-gradient(${mixColors(getPillarColorLight(pillar), '#ffffff', 0)} 20%, white 70%)`,
		backgroundPosition: '50% -400px',
		backgroundRepeat: 'no-repeat',
		backgroundSize: '500% 200%',
	};

	const pillarScore = genderScore?.[pillar];
	const pillarAvgScore = genderScore?.currentStatistics?.averages?.[pillar] || 0;
	const avgDiff = pillarScore - pillarAvgScore;
	const avgDiffAbs = Math.abs(avgDiff);
	const avgDiffNorm = avgDiff / (Math.abs(avgDiff) || 1);
	const avgDiffSigned = avgDiff === 0 ? '=' : addSignToNum(avgDiff);
	const avgDiffColor = ['#d4380d', '#ccc', '#389e0d'][avgDiffNorm + 1];
	const avgDiffIcon = [
		<ArrowDownIcon key={0} style={{ marginBottom: -2 }} />,
		<ArrowRightIcon key={1} style={{ marginBottom: -2 }} />,
		<ArrowUpIcon key={2} style={{ marginBottom: -2 }} />,
	][avgDiffNorm + 1];

	const avgDiffSentence = [
		`underperform by ${avgDiffAbs} points`,
		'are on par with',
		`overperform by ${avgDiffAbs} points`,
	][avgDiffNorm + 1];

	const avgPill = (
		<Tooltip
			title={<>
				With your score of <strong>{pillarScore}</strong>,
				you <strong>{avgDiffSentence}</strong> the average
				score of <strong>{pillarAvgScore}</strong> for
				the <strong>{getPillarLabel(pillar)}</strong> pillar
			</>}
			onOpenChange={() => {
				writeGenderScoreFilledLog({
					type: {
						element: `${pillar}-perf`,
						action: COMPANY_RECRUITER_LOG.HOVER,
					},
					context: 'pillars-breakdown',
				});
			}}

		>
			<span
				style={{
					color: avgDiffColor,
					// fontWeight: 'bold',
					borderRadius: 30,
					userSelect: 'none',
				}}
			>
				{avgDiffSigned}

				{avgDiffIcon}
			</span>
		</Tooltip>
	);

	const onNavigate = ({ to, from }) => {
		writeGenderScoreFilledLog({
			type: GENDERSCORE_LOG_FROM_ELEMENT_SEE_PILLAR_LINK,
			context: from,
		});
		navigate(to);
	};

	return (
		<div
			key={index}
		>
			<GenderScoreLayoutBox
				style={{
					backgroundColor: 'white',
					...boxStyle,
				}}
			>
				<div
					style={{
						position: 'relative',
						width: '100%',
						whiteSpace: 'normal',
						align: 'center',
						display: 'flex',
						flexDirection: 'column',
						justifyContent: 'center',
						alignItems: 'center',
						textAlign: 'center',
						gap: 12,
					}}
				>
					<div
						style={{
							position: 'absolute',
							top: -36,
							left: 'calc(50% - 15px)',
						}}
					>
						{getPillarIcon(pillar, { width: 30, height: 30 })}
					</div>
					<div>
						<div
							style={{
								fontSize: 10,
								opacity: 0.5,
							}}
						>
									PILLAR #{index + 1}
						</div>
						<div
							style={{
								fontSize: 16,
								fontWeight: 'bold',
							}}
						>
							{getPillarLabel(pillar)}
						</div>
					</div>

					<CompanyGenderScoreRingScore
						company={company}
						pillar={pillar}
						size={100}
						style={{
							score: { color: '#555' },
						}}
						ringColor={getPillarColorMain(pillar)}
					/>

					{withStats && (
						<div
							style={{
								width: '100%',
								display: 'flex',
								justifyContent: 'space-between',
								alignItems: 'center',
							}}
						>
							<GenderScorePillarRankingPill
								company={company}
								pillar={pillar}
							/>
							{avgPill}
						</div>
					)}

					{withLink && (

						<div
							style={{
								width: 'calc(100% + 48px)',
								margin: '0 -24px -12px',
								padding: '12px 24px',
								// background: theme.color.bgGrey,
								borderTop: `1px solid ${theme.color.bgGrey}`,
							}}
						>
							<Link
								onClick={() => onNavigate({ to: getPillarPath(pillar), from: pillar })}
							>
								See pillar details
								<ArrowRightIcon />
							</Link>
						</div>
					)}
				</div>

			</GenderScoreLayoutBox>
		</div>
	);
};

export default GenderScorePillarScoreBox;
