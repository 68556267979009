import { useDispatch } from 'react-redux';
import { useContext, useEffect, useState } from 'react';
import { ENGLISH_LANG } from '../../../../constants/constant';
import { LocalDebug } from '../../../../utils/LocalDebug';
import CompanyPageNoticeTranslate from './CompanyPageNoticeTranslate';
import FormBoxTitle from '../../../app/form/FormBoxTitle';
import CompanyFieldEditorFooter from './CompanyFieldEditorFooter';
import { NotificationContext } from '../../../app/notification/NotificationProvider';
import { saveCompany } from '../../../../actions/company';
import { CompanyPageContext } from './CompanyPageProvider';
import CompanyPageNextStepNoContextLink from './CompanyPageNextStepNoContextLink';
import {
	countMandatorySteps,
	getIncompleteMandatoryStepList,
} from './CompanyPageSteps';
import { useTheme } from '@emotion/react';
import CompanyModel from '../../../../models/CompanyModel';
import { isEmpty } from 'lodash';
import CompanyPageNoticeTranslateLegacy from './CompanyPageNoticeTranslateLegacy';
import { documentId } from '../../../../utils/common';

const CompanyFieldEditor = (
	{
		company,
		lang = ENGLISH_LANG,
		title,
		details,
		field,
		editedValue,
		extraValues,
		setEditorOpen,
		isTranslatable = true,
		forceRecompute = false,
		disabled = false,
		children,
	},
) => {
	const className = 'CompanyFieldEditor';

	const theme = useTheme();
	const dispatch = useDispatch();

	const { lang: contextLang, setCurrentlyEditingStep } = useContext(CompanyPageContext);
	const { notifyLoading, notifySuccess } = useContext(NotificationContext);

	/* submitDisabledExternal: used to disable the submit button from outside the component */
	const [submitDisabledExternal, setSubmitDisabledExternal] = useState(disabled);

	/* submitDisabledByValue: used to disable the submit button when edited value is null */
	const [submitDisabledByValue, setSubmitDisabledByValue] = useState(true);

	/* isTranslationEnabled: used to know what format of value to send when submitting */
	const [isTranslationEnabled, setIsTranslationEnabled] = useState(false);

	/* notice: helper label to display above the field */
	const [notice, setNotice] = useState(null);

	useEffect(() => {
		setSubmitDisabledExternal(disabled);
	}, [disabled, submitDisabledExternal]);

	useEffect(() => {
		setSubmitDisabledByValue(isEmpty(editedValue) && isEmpty(extraValues));
	}, [extraValues, editedValue, setSubmitDisabledByValue]);

	useEffect(() => {
		if (isTranslatable && company?.isTranslatable) {
			setIsTranslationEnabled(true);
			setNotice(new CompanyModel(company).isCompanyPlanLegacy()
				? <CompanyPageNoticeTranslateLegacy lang={lang} />
				: <CompanyPageNoticeTranslate />);
		} else {
			setIsTranslationEnabled(false);
			setNotice(null);
		}
	}, [company, isTranslatable]);

	const handleSave = async () => {
		const method = 'handleSave';

		setCurrentlyEditingStep(null);
		setSubmitDisabledExternal(true);

		notifyLoading({
			key: className,
			description: 'Editing your company page...',
		});

		await dispatch(
			await saveCompany({
				id: documentId(company),
				...(
					(!isTranslationEnabled || lang === ENGLISH_LANG)
						? { [field]: editedValue }
						: { [`translations.fr.${field}`]: editedValue }
				),
				...extraValues || {},
				forceRecompute,
			}),
		);

		const incompleteSteps = getIncompleteMandatoryStepList({ company, lang })
			?.filter?.((step) => documentId(step) !== field) || [];

		const [nextIncompleteStep] = incompleteSteps;

		let description = 'Company page edited';

		if (nextIncompleteStep) {
			const countCompletedSteps = (
				countMandatorySteps({ company, lang }) - (incompleteSteps?.length || 0)
			);

			description = (
				<div>
					<p>{description}.</p>
					{new CompanyModel(company)?.isCompanyPageEditionLegacyVersion?.() === false
						&& (
							<>
								{countCompletedSteps > 0 && (
									<p>
									You filled <b>{countCompletedSteps} step{countCompletedSteps > 1 ? 's' : ''}</b>,
									only <b>{incompleteSteps?.length} to go</b> before being able to publish!
									</p>
								)}
								<p>
								Let&apos;s fill next section: <b>{nextIncompleteStep.title}!</b>
									<CompanyPageNextStepNoContextLink
										company={company}
										step={nextIncompleteStep}
										setCurrentlyEditingStep={setCurrentlyEditingStep}
										lang={contextLang}
										theme={theme}
									/>
								</p>
							</>
						)
					}
				</div>
			);
		}

		notifySuccess({
			key: className,
			description,
			duration: 5,
		});

		// setEditorOpen(false);
		setSubmitDisabledExternal(false);
	};

	if (!company) return null;

	return (
		<>
			<FormBoxTitle
				title={title}
				details={details}
				style={{
					details: {
						fontSize: 13,
						fontWeight: 'bold',
					},
				}}
			/>

			{notice}

			{children}

			{field && (
				<>
					<CompanyFieldEditorFooter
						company={company}
						field={field}
						setEditorOpen={setEditorOpen}
						handleSave={handleSave}
						disabled={submitDisabledExternal || submitDisabledByValue}
					/>
				</>
			)}
		</>
	);
};

export default CompanyFieldEditor;
