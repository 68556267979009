import ConfigureEmailTemplatesPage from '../../containers/configure/ConfigureEmailTemplatesPage';
import { PERMS_GRANT_NO_EDITOR } from '../menu.utils';
import MenuBase from '../MenuBase';
import { FormatPainterOutlined } from '@ant-design/icons';

export const ConfigureEmailTemplatesIcon = FormatPainterOutlined;

class ConfigureEmailTemplatesMenu extends MenuBase {
	static props = {
		name: 'configure-email-templates',
		path: '/configure/email-templates',
		aliases: ['^/email-templates$'],
		label: 'Email templates',
		icon: <ConfigureEmailTemplatesIcon />,
		iconLegacy: '📧',
		Component: ConfigureEmailTemplatesPage,
		perms: PERMS_GRANT_NO_EDITOR,
	};

	constructor() {
		super(ConfigureEmailTemplatesMenu.props);
	}
}

export default ConfigureEmailTemplatesMenu;
