import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { useSelector } from 'react-redux';
import { useTheme } from '@emotion/react';
import {
	Divider, Space,
} from 'antd';
import ReactMarkdown from 'react-markdown';
import { getCompanyUMSSurveyDraftSelected } from '../../../reducers/app';
import { SurveyNavigationContext } from './providers/SurveyNavigationProvider';
import GenderScorePillarLogoLabel from '../../company/genderscore/pillars/GenderScorePillarLogoLabel';
import {
	getSurveySectionsQuestionsFromAnswers,
	isQuestionAnswered,
	isSurveyCompleted,
} from '../data/shared';
import { getPillarColorMain } from '../../../constants/genderscore-pillars';
import { SurveyContext } from './providers/SurveyProvider';
import SurveyAnswer from './SurveyAnswer';
import SurveyQuestionEditButton from './SurveyQuestionEditButton';
import SurveyQuestionFooter from './question/SurveyQuestionFooter';
import {
	EVENT, buildEventLabel, buildEventSection, pushClick,
} from '../../../contexts/EventPushProvider';
import { LocalDebug } from '../../../utils/LocalDebug';
import SurveyInfoModal from './SurveyInfoModal';
import useBodyScrollBlocker from '../../../hooks/useBodyScrollBlocker';

const SurveyReviewAnswers = ({
	umsSurvey,
	...props
}) => {
	const className = 'SurveyReviewAnswers';

	const theme = useTheme();
	const navigate = useNavigate();
	const draftSelected = useSelector(getCompanyUMSSurveyDraftSelected);
	const { eventContext, submitSurvey } = useContext(SurveyContext);
	const {
		surveyConf, answers, appLang, t,
	} = useContext(SurveyNavigationContext);
	const [surveyCompleted, setSurveyCompleted] = useState(false);
	const [infoModalOpen, setInfoModalOpen] = useState(false);

	useBodyScrollBlocker(infoModalOpen, className);

	const eventProps = {
		...eventContext,
		...buildEventSection(EVENT.SECTION.SURVEY_REVIEW),
	};

	const [survey, setSurvey] = useState();

	LocalDebug.logInfo({ className }, { draftSelected });
	useEffect(() => {
		setSurvey(umsSurvey || draftSelected);
	}, [umsSurvey, draftSelected]);

	useEffect(() => {
		setSurveyCompleted(isSurveyCompleted({ surveyConf, answers }));
	}, [surveyConf, answers]);

	if (!survey) return null;

	const handleOpenInfoModal = async () => {
		setInfoModalOpen(true);
	};

	const handleSubmit = async (values = {}) => {
		setInfoModalOpen(false);
		LocalDebug.logInfo({ className, method: 'handleSubmit' }, { values });
		pushClick({
			...eventProps,
			...buildEventLabel(EVENT.LABEL.SURVEY_SUBMIT),
		});
		await submitSurvey({
			draft: draftSelected,
			data: { withSlack: true },
			navigate,
		});
	};

	let questionIndex = 0;
	return (
		<div
			style={{
				margin: 0,
			}}
		>
			<div
				style={{
					maxWidth: 860,
					margin: '60px auto 130px',
					fontSize: 16,
				}}
			>
				<h1 style={{ marginTop: 20, marginBottom: 0 }}>
					💯<br />{t('survey-review-answers.header')}
				</h1>
				<ReactMarkdown>
					{t('survey-review-answers.subtitle')}
				</ReactMarkdown>

				<Divider />

				<Space
					direction='vertical'
					wrap={false}
					align={'top'}
					size={30}
				>

					{getSurveySectionsQuestionsFromAnswers({ surveyConf, answers })
						?.map((section) => {
							return (
								<div
									key={section.value}
									style={{
										paddingLeft: 24,
										borderLeft: `6px solid ${getPillarColorMain(section.value)}`,
									}}
								>
									<GenderScorePillarLogoLabel
										pillar={section.value}
										labelSize={24}
										iconSize={32}
										style={{
											label: {
												fontWeight: 'bold',
												marginBottom: -3,
											},
										}}
									/>

									{section?.questions?.map((question) => {
										questionIndex += 1;
										return (
											<div
												key={question.questionId}
												style={{
													width: '100%',
													borderBottom: '1px dotted #ccc',
												}}
											>
												<Space
													direction='horizontal'
													style={{
														justifyContent: 'space-between',
														alignItems: 'flex-start',
														width: '100%',
														padding: '10px 5px',
													}}
												>
													<div>
														<ReactMarkdown>{`**${questionIndex}** • ${question.label?.[appLang] || question.label}`}</ReactMarkdown>
													</div>

													{!isQuestionAnswered({ question, answers })
														? (
															<p
																style={{
																	textAlign: 'right',
																	minWidth: 160,
																}}
															>
																<span style={{
																	opacity: 0.5,
																	verticalAlign: 'middle',
																}}>
																	<i>{t('survey-question.not-answered')}</i>
																</span>
																&nbsp;
																&nbsp;
																&nbsp;
																<SurveyQuestionEditButton
																	section={section}
																	question={question}
																	answer={answers?.[question?.questionId]}
																/>
															</p>
														) : (
															<Space style={{ paddingTop: 8 }}>
																<Space
																	direction='vertical'
																	size={3}
																	style={{
																		justifyContent: 'flex-end',
																		alignItems: 'flex-end',
																	}}
																>
																	<SurveyAnswer
																		question={question}
																		answer={answers?.[question?.questionId]}
																	/>

																</Space>

																<SurveyQuestionEditButton
																	section={section}
																	question={question}
																	answer={answers?.[question?.questionId]}
																/>
															</Space>
														)
													}
												</Space>
											</div>
										);
									})}
								</div>
							);
						})}
				</Space>
				<SurveyQuestionFooter
					nextText={t(`survey-review-answers.submit-btn.${surveyCompleted ? 'complete' : 'incomplete'}`)}
					disableSave={!surveyCompleted}
					withPrev={false}
					withAnswerLater={false}
					withSkip={false}
					onSave={handleSubmit}
				/>
			</div>

			<SurveyInfoModal
				open={infoModalOpen}
				onClose={() => setInfoModalOpen(false)}
				onSubmit={handleSubmit}
			/>
		</div>
	);
};

export default SurveyReviewAnswers;
