import { useMemo, useState } from 'react';
import {
	Divider, Skeleton, Space,
} from 'antd';
import Box from '../../components/app/box/Box';
import { request } from '../../services/api';
import { useDebouncedEffect } from '../../hooks/useDebounceEffect';
import { LocalDebug } from '../../utils/LocalDebug';
import {
	CheckCircleOutlined,
	EyeOutlined, MessageOutlined, SearchOutlined, UserOutlined,
} from '@ant-design/icons';
import JsonPopoverLink from '../../components/app/debug/JsonPopoverLink';
import { useAuthContext } from '../../hooks/useAuthContext';

const StatBox = ({
	title,
	isLoading = false,
	children,
}) => {
	return (
		<Box style={{ padding: '18px 16px' }}>
			<Space
				direction='vertical'
				size={10}
			>
				<div
					style={{
						fontSize: 12,
						color: '#999',
					}}
				>
					{title}
				</div>
				{isLoading
					? (
						<Skeleton.Input
							active
							style={{
								maxWidth: 40 + 100 * Math.random(),
								borderRadius: 5,
								height: 33.5,
							}}
						/>
					)
					: (
						<div
							style={{
								fontSize: 24,
								color: '#333',
							}}
						>
							{children}
						</div>
					)
				}
			</Space>
		</Box>
	);
};

const ReportsStatistics = ({
	filterOptions = {},
}) => {
	const className = 'ReportsStatistics';

	const { isStaffView } = useAuthContext();

	const [statistics, setStatistics] = useState({});
	const [isLoading, setIsLoading] = useState(true);

	LocalDebug.logInfo(
		{ className },
	);

	const getStatistics = async () => {
		LocalDebug.logInfo({ className, method: 'getStatistics' }, { filterOptions });
		setIsLoading(true);
		const { data } = await request.get('/recruiter/report/stats', { params: { ...filterOptions } }) || {};
		setStatistics(data);
		setIsLoading(false);
	};

	useDebouncedEffect(getStatistics, [filterOptions], 500);

	return (
		<>
			<Divider orientation='left'>
				Global statistics
				{isStaffView && <>&nbsp;<JsonPopoverLink sortKeys={false} data={statistics} /></>}
			</Divider>

			<div
				style={{
					display: 'grid',
					gridTemplateColumns: 'repeat(5, 1fr)',
					columnGap: 10,
					rowGap: 10,
				}}
			>
				<StatBox title={<><UserOutlined /> Total recruiters</>} isLoading={isLoading}>
					{statistics?.recruitersCount}
				</StatBox>
				<StatBox title={<><SearchOutlined /> Saved searches created</>} isLoading={isLoading}>
					{statistics?.savedSearchesCount}
				</StatBox>
				<StatBox title={<><MessageOutlined /> Messages sent</>} isLoading={isLoading}>
					{statistics?.messagesCount}
				</StatBox>
				<StatBox title={<><EyeOutlined /> Talents viewed</>} isLoading={isLoading}>
					{statistics?.talentsViewedCount}
				</StatBox>
				<StatBox title={<><MessageOutlined /> Talents contacted</>} isLoading={isLoading}>
					{statistics?.talentsContactedCount}
				</StatBox>
			</div>

			<Divider orientation='left'>Recruiter statistics</Divider>

			<div
				style={{
					display: 'grid',
					gridTemplateColumns: 'repeat(5, 1fr)',
					columnGap: 10,
					rowGap: 10,
				}}
			>
				<StatBox title={<><UserOutlined /> Enabled recruiters</>} isLoading={isLoading}>
					{statistics?.activeRecruitersCount}
				</StatBox>
				<StatBox title={<><CheckCircleOutlined /> Logged at least once</>} isLoading={isLoading}>
					{statistics?.activeLoggedCount}
				</StatBox>
				<StatBox title={<><SearchOutlined /> Created a saved search</>} isLoading={isLoading}>
					{statistics?.activeSearchCreatedCount}
				</StatBox>
				<StatBox title={<><EyeOutlined /> Viewed a talent</>} isLoading={isLoading}>
					{statistics?.activeTalentViewedCount}
				</StatBox>
				<StatBox title={<><MessageOutlined /> Contacted a talent</>} isLoading={isLoading}>
					{statistics?.activeTalentContactedCount}
				</StatBox>
			</div>

		</>
	);
};

export default ReportsStatistics;
