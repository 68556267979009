import { Space } from 'antd';
import { useContext, useState } from 'react';
import Table from '../app/table/Table';
import theme from '../../theme';
import {
	atsProviderOptions,
} from '../../constants/constant';

import { LocalDebug } from '../../utils/LocalDebug';
import TableColumns from '../app/table/TableColumns';
import { useAuthContext } from '../../hooks/useAuthContext';
import UserDrawerProfile from '../user/UserDrawerProfile';
import UserContacter from '../user/buttons/UserContacter';
import { CandidateContext } from '../../contexts/CandidateProvider';
import { useSelector } from 'react-redux';
import { getCompanySelectedApiAccess, getShouldRefreshData } from '../../reducers/app';
import UserMessageModel from '../../models/UserMessageModel';
import UserAtsSender from '../user/buttons/UserAtsSender';

const ContactedCandidateTable = (
	{
		path = '/message/first-approach-contacts',
		filterOptions = {},
		isRefresh = 0,
		onSearchDataColumn,
		withClientSidePagination,
		withDynamicSearch = true,
		withTableHeader = true,
		withSort = true,
	},
) => {
	const className = 'CandidateTable';

	const {
		isStaffView,
	} = useAuthContext();
	const {
		setContactedCount,
	} = useContext(CandidateContext);

	const dataRefresh = useSelector(getShouldRefreshData);

	const [openProfile, setOpenProfile] = useState(false);
	const [activeTab, setActiveTab] = useState(null);
	const [userSelected, setUserSelected] = useState(null);
	const [isRefreshDataLayout, setIsRefreshDataLayout] = useState(0);

	const handleCloseProfile = () => {
		setUserSelected(null);
		setActiveTab(null);
		setOpenProfile(false);
	};

	const handleDataLoaded = ({ items } = {}) => {
		const method = 'handleDataLoaded';
		LocalDebug.logInfo({ className, method }, { items, filterOptions });
		setContactedCount(items?.length || 0);
	};

	// ----------------------------------- //
	// ----------- Table Columns --------- //
	// ----------------------------------- //

	const actionsCol = {
		title: 'Actions',
		dataIndex: ['actions'],
		align: 'center',
		// fixed: 'right',
		render: (_, row) => {
			return <Space direction='horizontal' size={8}>
				<UserAtsSender user={row?.userTo} title={'Send to your ATS'} withCurrentUser={true}/>
				<UserContacter user={row?.userTo} withPrimaryStyle={true} withCurrentUser={true}/>
			</Space>;
		},
		...TableColumns.columnMinWidth(120),
	};

	const candidateCol = TableColumns.userStubColumn(theme, {
		title: 'Candidate',
		dataIndex: 'userTo',
		withDropdown: true,
		sorter: false,
	});
	const contactedByCol = TableColumns.userAvatarListColumn(theme, {
		title: 'Contacted by',
		removeInColumnFilter: true,
		dataIndex: 'contactedBy',
		withCurrentUser: true,
	});

	const sentAtCol = TableColumns.dateColumn(theme, {
		title: 'Sent at',
		dataIndex: ['recipientMailing', 'sentAt'],
		key: 'sentAt',
	});
	const statusCol = {
		title: 'Status',
		dataIndex: ['answer'],
		align: 'center',
		sorter: false,
		removeInColumnFilter: true,
		render: (value, row) => row?.renderStatusDisplay(theme, { withStatusStep: false, withToolTip: false }),
		...TableColumns.columnFixedWidth(110),
	};

	const columns = isStaffView
		? [
			TableColumns.staffColumnWrapper(TableColumns.jsonColumn(theme, { title: '', colWidth: 32 })),
			candidateCol,
			contactedByCol,
			sentAtCol,
			statusCol,
			actionsCol,
		]
		: [
			candidateCol,
			contactedByCol,
			sentAtCol,
			statusCol,
			actionsCol,
		];

	return (
		<>
			<Table
				path={path}
				ItemClass={UserMessageModel}
				onDataLoaded={handleDataLoaded}
				loadOnInit={false}
				columns={columns}
				bordered={false}
				filterOptions={filterOptions}
				onSearchDataColumn={onSearchDataColumn}
				isRefresh={isRefresh + isRefreshDataLayout + dataRefresh}
				withClientSidePagination={withClientSidePagination}
				withTableHeader={withTableHeader}
				limit={10}
				withDynamicSearch={withDynamicSearch}
				withSort={withSort}
				sortStyle={{ select: { width: 180 } }}
				expandable={{
					expandedRowRender: (row) => new UserMessageModel(row).renderExpand({ isStaffView }),
					rowExpandable: (row) => new UserMessageModel(row).isExpandable,
					// expandIcon: () => <Link>Expand</Link>,
					// expandRowByClick: true,
					// showExpandColumn: false,
					columnWidth: 32,
					// onExpand: (expanded, row) => {
					// 	console.log('onExpand', expanded, row);
					// 	if (expanded) {
					// 		setExpandedRowKeys((p) => {
					// 			return [...new Set([...p || [], documentId(row)])];
					// 		});
					// 	} else {
					// 		setExpandedRowKeys((p) => {
					// 			return [...new Set((p || []).filter((key) => key !== documentId(row)))];
					// 		});
					// 	}
					// },
					// onExpandedRowsChange: (rows) => {
					// 	console.log('onExpandedRowsChange', rows);
					// },
					// expandedRowKeys,
				  }}
			/>

			<UserDrawerProfile
				open={openProfile}
				user={userSelected}
				activeTab={activeTab}
				onClose={handleCloseProfile}
			/>
		</>
	);
};

export default ContactedCandidateTable;
