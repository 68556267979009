import { useSelector } from 'react-redux';
import PageLayout from '../../components/app/layout/PageLayout';
import { useTheme } from '@emotion/react';
import { getCompanySelected } from '../../reducers/app';
import UMSStaffResults from '../../components/ums/staff/UMSStaffResults';
import UnbiasedScoreProvider from '../../contexts/UnbiasedScoreProvider';
import UMSInviteProvider from '../../components/ums/UMSInviteProvider';
import { useAuthContext } from '../../hooks/useAuthContext';
import UMSStaffSurveyManager from '../../components/ums/staff/UMSStaffSurveys';
import CompanyGridPicker from '../../components/company/CompanyGridPicker';
import { Table } from 'antd';
import FeatureChartTables from './FeatureChartTables';
import UMSStaffMenu from '../../menu/ums/UMSStaffMenu';

const UMSStaffPage = () => {
	const className = 'UMSStaffPage';

	const theme = useTheme();

	const { isStaff } = useAuthContext();
	const companySelected = useSelector(getCompanySelected);

	if (!companySelected) return <CompanyGridPicker />;

	const menu = (new UMSStaffMenu()).build();

	return (
		<PageLayout
			icon={menu.icon}
			title={menu.label}
		>
			<UMSStaffSurveyManager />
			{/* {isStaff && ( */}
			<UMSInviteProvider>
				<UnbiasedScoreProvider>
					<UMSStaffResults />
				</UnbiasedScoreProvider>
			</UMSInviteProvider>
			{/* )} */}
			{/* <FeatureChartTables /> */}
		</PageLayout>
	);
};

export default UMSStaffPage;
