import { arrayToObjectListByProp } from '../../utils/common';
import { TECH_STACK_GROUP_OPTIONS } from '../hard-skills';

const { innerObjPropMapper } = require('./property-mapper');
const STACK_OPTIONS = require('../hard-skills.json');

const STACK_GROUPS = TECH_STACK_GROUP_OPTIONS.map((o) => o.value);

const STACK_KEY = 'tech-stack';

const STACK_MULTI = true;
const STACK_FREE = true;
const STACK_MAX = 10;

const STACK_VALIDATORS = [
	// isStringTypeValidator,
];

const STACK_MAPPER = innerObjPropMapper('career.hardSkill');

const STACK_OPTIONS_SINGLE = Object
	.entries(arrayToObjectListByProp(STACK_OPTIONS, 'value'))
	.map(([key, entries]) => {
		const { label, value, group } = entries[0];
		return { label, value, group };
	});

export default {
	key: STACK_KEY,
	STACK_KEY,
	validators: STACK_VALIDATORS,
	STACK_VALIDATORS,
	multi: STACK_MULTI,
	STACK_MULTI,
	groups: STACK_GROUPS,
	STACK_GROUPS,
	options: STACK_OPTIONS,
	singleOptions: STACK_OPTIONS_SINGLE,
	STACK_OPTIONS,
	free: STACK_FREE,
	STACK_FREE,
	max: STACK_MAX,
	STACK_MAX,
	mapper: STACK_MAPPER,
	STACK_MAPPER,
};
