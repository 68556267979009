import { Form } from 'antd';
import FormArticleSelect from '../../../app/form/FormArticleSelect';
import Modal from '../../../app/modal/Modal';
import FormItem from '../../form/FormItem';
import { LocalDebug } from '../../../../utils/LocalDebug';
import { requiredRule } from '../../../../utils/common';

const CompanyStoriesAddModal = ({
	open,
	onClose,
	onSave,
	initialValues = {},
}) => {
	const className = 'CompanyStoriesAddModal';

	const [form] = Form.useForm();

	const handleClose = () => {
		form.resetFields();
		onClose?.();
	};

	return (
		<Modal
			title='Add article'
			open={open}
			okText="Save"
			cancelText="Cancel"
			onCancel={handleClose}
			onOk={() => {
				form
					.validateFields()
					.then((values) => {
						onSave(values);
						form.resetFields();
					})
					.catch((info) => {
						LocalDebug.logInfo({ className, method: 'onOk (catch)' }, 'Validate Failed:', info);
					});
			}}
		>
			<Form
				form={form}
				name="add-team"
				labelCol={{ span: 4 }}
				labelAlign="left"
			>
				<FormItem
					label=''
					name='articleId'
					rules={[requiredRule]}
				>
					<FormArticleSelect
						notArticleIds={initialValues?.notArticleIds}
					/>
				</FormItem>
			</Form>
		</Modal>
	);
};

export default CompanyStoriesAddModal;
