import { IdcardOutlined } from '@ant-design/icons';
import AccountPage from '../containers/root/AccountPage';
import MenuBase from './MenuBase';

const AccountIcon = IdcardOutlined;

class AccountMenu extends MenuBase {
	static props = {
		name: 'account',
		path: '/account',
		label: 'Account',
		icon: <AccountIcon />,
		iconLegacy: '📇',
		Component: AccountPage,
	};

	constructor() {
		super(AccountMenu.props);
	}
}

export default AccountMenu;
