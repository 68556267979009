import Drawer from '../app/drawer/Drawer';
import React, { useRef } from 'react';
import JobDetails from './JobDetails';
import JobHeader from './JobHeader';
import { FaRegTimesCircle } from 'react-icons/fa';

const JobDrawerDetails = (
	{
		title,
		width = 900,
		job,
		jobActiveTab,
		onClose,
		onEditJob,
		...props
	},
) => {
	const container = useRef();

	const handleEditJob = (job) => {
		onEditJob(job);
	};

	return <Drawer
		title={title || <JobHeader job={job} onEditJob={handleEditJob}/>}
		width={width}
		className="withAffix"
		onClose={onClose}
		closeIcon={<FaRegTimesCircle size={24} style={{ marginTop: 10 }}/>}
		destroyOnClose={true}
		contentAfterOpen={
			<div ref={container}>
				<JobDetails job={job} activeTab={jobActiveTab}/>
			</div>
		}
		{...props}
	 />;
};

export default JobDrawerDetails;
