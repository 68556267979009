import {
	Col, Form, Input, InputNumber, Row, Select,
} from 'antd';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
	accessTypeOptions,
	atsProviderOptions,
	BOOLEAN_OR_NULL_OPTIONS,
	JOB_TAG_CATEGORIES,
	JOB_TAG_CATEGORIES_STAFF,
	JOB_TAG_JOB_ROLE_GROUP, LOGO_50INTECH,
} from '../../constants/constant';
import { getCompanies, getTagsJobs } from '../../reducers/app';
import { documentId, getQueryParams, sortOn } from '../../utils/common';
import { LocalDebug, localDebug } from '../../utils/LocalDebug';
import FormItem from '../company/form/FormItem';
import FormSelect from '../app/form/FormSelect';
import { useAuthContext } from '../../hooks/useAuthContext';
import FormCompanySelect from '../app/form/FormCompanySelect';
import FilterModal from '../app/filters/FilterModal';
import { getAllTagJobs } from '../../actions/jobs';
import Callout from '../app/callout/Callout';
import { useTheme } from '@emotion/react';
import UserSelect from '../user/UserSelect';
import UserService from '../../services/user';
import FormAtsSelect from '../app/form/FormAtsSelect';
import Logo50inTech from '../app/Logo50inTech';

const JobFilterModal = ({
	title,
	open,
	initialValues = {},
	onClose,
	onFilter,
}) => {
	const className = 'JobFilterModal';
	const dispatch = useDispatch();
	const theme = useTheme();

	const { isStaff, isStaffView, currentUser } = useAuthContext();

	const [form] = Form.useForm();
	const queryParams = getQueryParams();
	const companies = useSelector(getCompanies);
	const tags = useSelector(getTagsJobs);
	const [minSourcingsDisabled, setMinSourcingsDisabled] = useState(![true, 'true'].includes(initialValues?.hasSourcings));

	const getDefaultSelected = (fieldName) => {
		return queryParams[fieldName] || [];
	};

	const getTagsOptions = (category, noneValue = '-1', sortOnKey = null) => {
		let options = [];
		const categoryTags = (tags.find((tag) => tag.category === category));
		if (categoryTags) {
			options = (categoryTags?.items || []);
			if (sortOnKey) options.sort(sortOn({ key: sortOnKey }));
			options = [
				...noneValue ? [{
					value: noneValue, label: <span style={{ fontStyle: 'italic' }}>Not provided</span>,
				}] : [],
				...options,
			];
		}
		return options;
	};

	const tagSelectOption = ({ label, value }, index) => <Select.Option key={value} value={value} label={label}>
		{label}
	</Select.Option>;

	const tagSelect = (category) => {
		const [noneOption, ...options] = getTagsOptions(category.value) || [];
		// LocalDebug.logInfo({
		// 	className, method: 'tagSelect',
		// }, category.value + ' (' + options?.length + ' options)', { category, noneOption, options });
		const groupedTags = {};
		options.forEach(({ group, ...rest }, index, arr) => {
			if (!group) return;
			groupedTags[group] = [
				...groupedTags[group] || [],
				rest,
			];
		});

		const content = <>
			{tagSelectOption(noneOption)}
			{Object.keys(groupedTags).length > 0
				? Object.entries(groupedTags).map(([group, tags], index) => <Select.OptGroup key={index}
									 label={getTagsOptions(JOB_TAG_JOB_ROLE_GROUP.value)
										 .find((o) => o.value === group)?.label}
				>
					{tags.map(tagSelectOption)}
				</Select.OptGroup>)
				: options.map(tagSelectOption)
			}
		</>;

		return (
			<FormSelect options={options} mode='multiple'>
				{content}
			</FormSelect>
		);
	};

	const tagFormItem = (category, key) => <Col span={12} key={key}>
		<FormItem
			key={category.value}
			labelCol={{ span: 9 }}
			label={category.label}
			labelAlign='right'
			name={category.value}
		>
			{tagSelect(category)}
		</FormItem>
	</Col>;

	const handleFilter = () => {
		form
			.validateFields()
			.then((values) => {
				const { goal, ...rest } = values;
				const tags = JOB_TAG_CATEGORIES_STAFF.reduce((prev, c) => {
					if (rest[c.value]) {
						return { ...prev, [c.value]: rest[c.value] };
					}
					return prev;
				}, {});

				if (goal) {
					tags['profile-goal'] = [goal];
				}

				const filtersValues = {
					...values,
					...Object.keys(tags).length ? { tags } : {},
				};
				if (onFilter) onFilter(filtersValues);
			})
			.catch((errors) => {
				localDebug('Validate Failed:', errors);
			});
	};

	const handleHasSourcingsChanged = (value) => {
		// localDebug('handleHasSourcingsChanged', { value });
		setMinSourcingsDisabled(value !== 'true');
	};

	useEffect(() => {
		dispatch(getAllTagJobs());
	}, [dispatch]);

	useEffect(() => {
		form.resetFields();
		form.setFieldsValue(initialValues);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [initialValues]);

	const handleFetchManagers = async (options) => {
		const { data: { items } } = await UserService.findAll({ ...options, isManager: true });
		return items;
	};

	const companySelectFormItem = <FormItem
		label='Company'
		name='companyIds'
		labelCol={{ span: 6 }}
		defaultContainer='div'
	>
		<FormCompanySelect
			options={companies.map((c) => ({ value: documentId(c), label: c.name, item: c }))}
		/>
	</FormItem>;

	const isPublishedFormItem = <FormItem label='Published' name='isPublished'>
		<FormSelect options={BOOLEAN_OR_NULL_OPTIONS} />
	</FormItem>;

	const hasContentFormItem = <FormItem label='Has content' name='isContent'>
		<FormSelect options={BOOLEAN_OR_NULL_OPTIONS} />
	</FormItem>;

	const hasSourcingsFormItem = <FormItem label='Has sourcings' name='hasSourcings'>
		<FormSelect options={BOOLEAN_OR_NULL_OPTIONS} onChange={handleHasSourcingsChanged} />
	</FormItem>;

	const minSourcingsFormItem = <FormItem label='Min. sourcings' name='sourcings'>
		<InputNumber size='middle' style={{ width: '100%' }} min={0} defaultValue={null}
			// disabled={minSourcingsDisabled}
		/>
	</FormItem>;

	const minReadsFormItem = <FormItem label='Min. reads' name='reads'>
		<InputNumber size='middle' style={{ width: '100%' }} min={0} defaultValue={null} />
	</FormItem>;

	const minApplysFormItem = <FormItem label='Min. applys' name='applys'>
		<InputNumber size='middle' style={{ width: '100%' }} min={0} defaultValue={null} />
	</FormItem>;

	const minManagersFormItem = <FormItem label='Min. managers' name='managers'>
		<InputNumber size='middle' style={{ width: '100%' }} min={0} defaultValue={null}
			// disabled={minSourcingsDisabled}
		/>
	</FormItem>;

	const hasManagersFormItem = <FormItem labelCol={{ span: 9 }} label='Has managers' name='hasManagers'>
		<FormSelect options={BOOLEAN_OR_NULL_OPTIONS} />
	</FormItem>;

	if (!(tags?.length > 0)) return null;

	return (

		<FilterModal
			title={title}
			width={750}
			open={open}
			onClose={onClose}
			onFilter={handleFilter}
		>
			<Form
				form={form}
				name='add-team'
				labelCol={{ span: 12 }}
				labelAlign='right'
				className='user-filter-form'
			>
				<Row gutter={0}>

					{(isStaff && isStaffView
						? JOB_TAG_CATEGORIES_STAFF
						: JOB_TAG_CATEGORIES
					).map(tagFormItem)}

					<Col span={12}>{hasManagersFormItem}</Col>

					<Col span={12}>
						<FormItem
							labelCol={{ span: 9 }}
							label='Managers'
							labelAlign='right'
							name='managerId'
						>
							<UserSelect defaultOptions={[currentUser]} onFindUsers={handleFetchManagers} />
						</FormItem>
					</Col>
				</Row>

				{isStaff
					&& isStaffView
					&& (
						<div
							style={{
								background: '#eee',
								borderRadius: 14,
								margin: '0 10px',
								padding: 10,
								width: 'calc(100% - 30px)',
							}}
						>
							<Callout
								icon={<Logo50inTech size={22} style={{ marginTop: 0, marginBottom: -4 }} />}
								style={{ background: theme.background.gradientBluePink(315) }}
								withFeedback={false}
							>
								<b>50inTech - Staff filters</b>
							</Callout>
							<Row gutter={0}>
								{/* <Col span={24}>{companySelectFormItem}</Col> */}
								<Col span={12}>{isPublishedFormItem}</Col>
								<Col span={12}>{hasContentFormItem}</Col>
								<Col span={12}>{minSourcingsFormItem}</Col>
								<Col span={12}>{minReadsFormItem}</Col>
								<Col span={12}>{minApplysFormItem}</Col>
								<Col span={12}>
									<FormItem label='ATS Provider' name='atsProvider'>
										<FormAtsSelect
											options={atsProviderOptions}
											mode='multiple'
											defaultValue={getDefaultSelected('ats')}
											styles={{ minWidth: 'unset' }}
										/>
									</FormItem>
								</Col>
								<Col span={12}>
									<FormItem label='Access type' name='accessType'>
										<FormSelect
											options={accessTypeOptions}
											placeholder='Select an access type'
											defaultValue={getDefaultSelected('accessType')}
											styles={{ minWidth: 'unset' }}
											mode='multiple'
											render={(option) => <Select.Option value={option.value}>
												{option.icon(16)} <b>{option.label}</b>
											</Select.Option>}
										/>
									</FormItem>
								</Col>
								<Col span={12}>
									<FormItem label='Value/API Key' name='accessValue'>
										<Input value={getDefaultSelected('accessValue')} />
									</FormItem>
								</Col>
							</Row>
						</div>
					)
				}
			</Form>

		</FilterModal>
	);
};

export default JobFilterModal;
