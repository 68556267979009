import {
	Button, Divider, Space, Tree, message,
} from 'antd';
import UMSInvitationTable from './UMSInvitationTable';
import { useContext, useEffect, useState } from 'react';
import { LocalDebug } from '../../utils/LocalDebug';
import UMSInviteModal from './users/UMSInviteModal';
import useBodyScrollBlocker from '../../hooks/useBodyScrollBlocker';
import AclService from '../../services/acl';
import { ACL_ROLE_UMS_ADMIN, ACL_ROLE_UMS_EMPLOYEE, ACL_ROLE_UMS_MANAGER } from '../../constants/acls';
import { UMSInviteContext } from './UMSInviteProvider';
import {
	ArrowRightOutlined, CloseOutlined, DownOutlined, PlusOutlined,
	ReloadOutlined,
} from '@ant-design/icons';
import ReactJsonDebug from '../app/debug/ReactJsonDebug';
import Box from '../app/box/Box';
import UserName from '../user/widgets/UserName';
import { documentId, mixColors } from '../../utils/common';
import { useTheme } from '@emotion/react';
import Link from '../app/Link';
import { useDispatch, useSelector } from 'react-redux';
import { getCompanySelected } from '../../reducers/app';
import { useAuthContext } from '../../hooks/useAuthContext';
import config from '../../config/config';
import UMSUserStub from './users/UMSUserStub';
import { getAclRoleTag } from '../../constants/constant';

const UserCardBase = ({
	user,
	children,
	...props
}) => {
	const { isStaffView } = useAuthContext();

	return (
		<Box
			style={{
				width: '100%',
				display: 'block',
				padding: 20,
				background: '#ffffff',
				border: '1px solid #dddddd',
				...props?.style,
			}}
		>
			<div>
				<UMSUserStub user={user} />
			</div>
			{children}
		</Box>
	);
};

const UserCardManager = ({
	user,
}) => {
	const theme = useTheme();
	const {
		employees,
		managerSelected,
		setManagerSelected,
	} = useContext(UMSInviteContext);

	const isManagerSelected = managerSelected && documentId(managerSelected) === documentId(user);
	return (
		<UserCardBase
			user={user}
			style={{
				...isManagerSelected
					? {
						border: `1px solid ${theme.color.fitBlueElectric}`,
						background: mixColors(theme.color.fitBlueElectric, theme.color.white, 98),
					}
					: {},
			}}
		>
			{/* <div style={{ height: 40 }}>&nbsp;</div> */}
			<Divider style={{ margin: '10px 0 16px' }}/>
			<Space
				style={{
					justifyContent: 'space-between',
					alignItems: 'center',
					fontSize: 12,
					width: '100%',
				}}
			>
				<div
					style={{
						fontSize: 12,
						color: theme.color.grey,
					}}
				>
					<b>Employees:</b> {(
						employees
							.filter((u) => documentId(u?.role?.manager) === documentId(user))
							.length
					)}
				</div>
				{/* <div>&nbsp;</div> */}
				{isManagerSelected
					? <Link onClick={() => setManagerSelected?.(null)}>Unselect <CloseOutlined /></Link>
					: <Link onClick={() => setManagerSelected?.(user)}>Select <ArrowRightOutlined /></Link>
				}
			</Space>
			{/* <UMSUserSurveyProgress survey={user?.role?.umsSurvey} /> */}
		</UserCardBase>
	);
};

const UserCardEmployee = ({
	user,
}) => {
	const theme = useTheme();

	const { isStaffView, isUMSAdmin } = useAuthContext();

	return (
		<UserCardBase
			user={user}
		>
			{/* <Divider /> */}
			<div
				style={{
					fontSize: 12,
					color: theme.color.grey,
				}}
			>
				{(isStaffView || isUMSAdmin) && (
					<>
						<b>Manager:</b> <UserName
							user={user?.role?.manager}
						/>
					</>
				)}
			</div>
			{/* <UMSUserSurveyProgress survey={user?.role?.umsSurvey} /> */}
		</UserCardBase>
	);
};

const RoleGridColumn = ({
	title,
	users,
	subheader,
	UserCard = UserCardBase,
}) => {
	const { isStaffView, isUMSAdmin, isUMSManager } = useAuthContext();

	const [cols, setCols] = useState(1);

	useEffect(() => {
		if (isUMSAdmin || isStaffView) {
			setCols(1);
			return;
		}
		setCols(3);
	}, [isStaffView, isUMSAdmin, isUMSManager]);

	return (
		<Box
			style={{
				display: 'block',
				padding: 20,
				background: '#fdfdfd',
				border: '1px solid #dddddd',
			}}
		>
			<Space
				style={{
					justifyContent: 'space-between',
					alignItems: 'center',
					width: '100%',
				}}
			>
				<div
					style={{
						fontSize: 18,
						fontWeight: 500,
					}}
				>
					{title}
				</div>
				<div
					style={{
						fontSize: 14,
					}}
				>
					{users?.length}
				</div>
			</Space>

			{subheader}

			<Divider style={{ marginTop: 10 }}/>

			<div
				style={{
					display: 'grid',
					gridTemplateColumns: `repeat(${cols}, 1fr)`,
					columnGap: 20,
					rowGap: 20,
				}}
			>

				{users?.map((user, index) => {
					return (
						<div key={index}>
							<UserCard user={user} />
						</div>
					);
				})}
			</div>
		</Box>
	);
};
const UMSInvite = () => {
	const className = 'UMSInvite';

	const theme = useTheme();
	const dispatch = useDispatch();

	const companySelected = useSelector(getCompanySelected);

	const {
		isLoading, reload,
		inviteModalOpen, setInviteModalOpen,
		inviteModalFilters, setInviteModalFilters,
		isRefreshDataLayout,
		refreshLayout,
		closeInviteModal,
		admins, managers, employees, team,
		setAdmins, setManagers, setEmployees,
		setUsers,
		managerSelected,
		setManagerSelected,
		managerSelectedEmployees,
	} = useContext(UMSInviteContext);

	const { isStaffView, isUMSAdmin, isUMSManager } = useAuthContext();
	const [columns, setColumns] = useState([]);
	const [teamTreeData, setTeamTreeData] = useState([]);
	const [expandedKeys, setExpandedKeys] = useState([]);

	useBodyScrollBlocker(inviteModalOpen, className);

	const handleSubmit = async (values) => {
		LocalDebug.logInfo({ className, method: 'handleSubmit' }, { values });
		setInviteModalOpen(false);

		const { roleId, userId, documentId: companyId } = values;
		const { data: { isAclExist } } = await AclService.aclExists(roleId, { userId, companyId });

		if (isAclExist) {
			message.error('This access already exists for this user');
			return;
		}

		refreshLayout();
	};

	const handleCloseWithChange = async (values) => {
		LocalDebug.logInfo({ className, method: 'handleCloseWithChange' }, { values });
		closeInviteModal();
		refreshLayout();
	};

	const handleResetForm = async (values) => {
		LocalDebug.logInfo({ className, method: 'handleResetForm' }, { values });
		closeInviteModal();
	};

	useEffect(() => {
		LocalDebug.logUseEffect({ className, effects: '' });
		reload();
	}, [isRefreshDataLayout]);

	useEffect(() => {
		const adminsCol = (
			<RoleGridColumn
				title='Admins'
				users={admins}
			/>
		);
		const managersCol = (
			<RoleGridColumn
				title='Managers'
				users={managers}
				UserCard={UserCardManager}
			/>
		);
		const employeesCol = (
			<RoleGridColumn
				title='Employees'
				users={managerSelectedEmployees}
				UserCard={UserCardEmployee}
				subheader={managerSelected && (
					<Box
						style={{
							background: mixColors(theme.color.fitBlueElectric, theme.color.white, 98),
							borderColor: mixColors(theme.color.fitBlueElectric, theme.color.white, 50),
							marginTop: 10,
						}}
					>
						<Space
							style={{
								justifyContent: 'space-between',
								alignItems: 'flex-start',
								width: '100%',
							}}
						>
							<div>Manager selected: <UserName style={{ fontWeight: 'bold' }} user={managerSelected} /></div>
							<Link onClick={() => setManagerSelected(null)}>
								<CloseOutlined />
							</Link>
						</Space>
					</Box>
				)}
			/>
		);

		if (isUMSAdmin || isStaffView) {
			setColumns([adminsCol, managersCol, employeesCol]);
			return;
		}
		if (isUMSManager) {
			setColumns([employeesCol]);
		}
	}, [isUMSAdmin, isUMSManager, isStaffView, admins, managers, managerSelected, managerSelectedEmployees]);

	useEffect(() => {
		LocalDebug.logUseEffect({ className, effects: 'team' }, { team });
		const walkLeaf = ({
			user,
			isManager,
			isEmployee,
			employees: employeeList,
		}) => {
			const roleTags = <Space size={2}>{
				[
					...isManager ? [getAclRoleTag(ACL_ROLE_UMS_MANAGER)] : [],
					...isEmployee ? [getAclRoleTag(ACL_ROLE_UMS_EMPLOYEE)] : [],
				].map((tag, index) => <div key={index}>{tag}</div>)
			}</Space>;

			return {
				title: (
					<Space
						direction='horizontal'
						size={6}
					>
						<UMSUserStub user={user} />
						{/* {roleTags} */}
						{!isManager && isEmployee
							? <span
								style={{
									fontSize: 10,
									color: theme.color.fitBlueElectric,
									color: '#389e0d',
								}}
							>
								<b>EMPLOYEE</b>
							</span>
							: null
						}
						{isManager
							? <span
								style={{
									fontSize: 10,
									color: 'gray',
								}}
							>
								<span
									style={{
										color: '#c41d7f',
									}}
								>
									<b>MANAGER</b>
								</span>&nbsp;
								 ({employeeList?.length} employees)
							</span>
							: null
						}
					</Space>
				),
				// title: JSON.stringify(user),
				key: user?._id,
				selectable: isManager,
				...employeeList?.length > 0
					? {
						children: employeeList.map(walkLeaf),
					}
					: {},
			};
		};
		const newTeamTreeData = (team || []).map(walkLeaf);
		LocalDebug.logUseEffect({ className, effects: 'team' }, { newTeamTreeData });
		setTeamTreeData(newTeamTreeData);
	}, [team]);

	const onSelect = (keys, info) => {
		// console.log('onSelect', { expandedKeys, keys, info });
		setExpandedKeys((prev) => {
			const newKey = info?.node?.key;
			const newKeys = prev.includes(newKey)
				? prev.filter((key) => key !== newKey)
				: [...new Set([newKey, ...prev])];
			// console.log('onSelect', { prev, newKey, newKeys });
			return newKeys;
		});
	};

	const layoutify = (children) => (
		<div>
			<Space
				style={{
					justifyContent: 'space-between',
					width: '100%',
					marginBottom: 12,
				}}
			>
				<Button
					type='primary'
					onClick={() => {
						setInviteModalFilters({ allowedRoles: [ACL_ROLE_UMS_ADMIN] });
						setInviteModalOpen(true);
					}}
				>
					<PlusOutlined /> <b>Invite {isUMSAdmin ? 'a teammate' : 'an employee'}</b>
				</Button>

				{isStaffView && <ReloadOutlined onClick={reload} />}

			</Space>

			{children}

		</div>
		// <PageLayout
		// 	title={(
		// 		<SectionTitle
		// 			title={<>
		// 				Manage your team
		// 				{(config?.isDevEnv || isStaffView) && <>&nbsp;<ReloadOutlined onClick={reload} /></>}
		// 			</>}
		// 			renderAlone={false}
		// 		/>
		// 	)}
		// >

		// 	</Space>

		// 	<Divider />

		// 	{children}
		// </PageLayout>
	);

	if (isLoading) return layoutify(<>Loading...</>);

	return layoutify(
		<>
			<Box
				style={{
					background: 'white',
				}}
			>
				<Tree
					showLine
					switcherIcon={<DownOutlined />}
					expandedKeys={expandedKeys}
					// defaultExpandedKeys={['0-0-0']}
					onSelect={onSelect}
					treeData={teamTreeData}
					style={{
						background: 'none',
					}}
				/>
			</Box>

			{false && (
				<div
					style={{
						display: 'grid',
						gridTemplateColumns: `repeat(${columns?.length}, 1fr)`,
						columnGap: 10,
					}}
				>
					{columns.map((col, index) => (
						<div key={index}>{col}</div>
					))}
				</div>
			)}

			<UMSInviteModal
				title={`Invite ${isUMSAdmin ? 'a teammate' : 'an employee'}`}
				open={inviteModalOpen}
				initialValues={inviteModalFilters}
				onSubmit={handleSubmit}
				onReset={handleResetForm}
				onCloseWithRefresh={handleCloseWithChange}
			/>

			{false && (
				<>

					<Divider>Admins ({admins.length || 0})</Divider>

					<UMSInvitationTable
						filterOptions={{ role: ['ums-admin'] }}
						isRefresh={isRefreshDataLayout}
						withManagerColumns={false}
						withSurveyColumns={false}
						onDataLoaded={({ items } = {}) => setUsers(items, setAdmins)}
					/>
					<Divider>Managers ({managers.length || 0})</Divider>

					<UMSInvitationTable
						filterOptions={{ role: ['ums-manager'] }}
						isRefresh={isRefreshDataLayout}
						withManagerColumns={false}
						onDataLoaded={({ items } = {}) => setUsers(items, setManagers)}
					/>

					<Divider>Employees ({employees.length || 0})</Divider>

					<UMSInvitationTable
						filterOptions={{ role: ['ums-employee'] }}
						isRefresh={isRefreshDataLayout}
						onDataLoaded={({ items } = {}) => setUsers(items, setEmployees)}
					/>

					<ReactJsonDebug
						name='admins, managers, employees'
						src={{ admins, managers, employees }}
						collapsed={0}
					/>

				</>
			)}
		</>,
	);
};

export default UMSInvite;
