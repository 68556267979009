import Popin from '../../app/modal/Popin';
import JobEditManagersForm from './JobEditManagersForm';
import useBodyScrollBlocker from '../../../hooks/useBodyScrollBlocker';
import JobStub from '../JobStub';
import Box from '../../app/box/Box';
import { Divider } from 'antd';
import HelpInfoNotifications from '../../app/help/HelpInfoNotifications';
import FormBoxTitle from '../../app/form/FormBoxTitle';
import ModalContainerBase from '../../../contexts/modals/ModalContainerBase';

const JobEditManagersPopover = (
	{
		open,
		job,
		initialValues = {},
		title = '📥 Edit notifications recipients',
		onSubmit,
		onReset,
	},
) => {
	const className = 'JobEditManagersPopover';

	useBodyScrollBlocker(open, className);

	return (
		<ModalContainerBase
			open={open}
			width={520}
			onCancel={onReset}
		>
			<FormBoxTitle
				title={title}
			/>

			<h3>Job offer</h3>
			<Box style={{ padding: 10, width: '100%' }}>
				<JobStub job={job}/>
			</Box>
			<Divider/>
			<h3>Recipients</h3>
			<JobEditManagersForm
				initialValues={initialValues}
				onSubmit={onSubmit}
			/>
			<Divider/>
			<HelpInfoNotifications style={{ marginTop: 0, textAlign: 'left' }}/>

		</ModalContainerBase>
	);
};

export default JobEditManagersPopover;
