/** @jsxImportSource @emotion/react */

import { Checkbox, Divider, Form } from 'antd';
import { useEffect, useState } from 'react';
import FieldItemUpload from './components/FieldItemUpload';
import FooterAction from './components/FooterAction';
import FormInput from '../../../form/FormInput';
import { useTheme } from '@emotion/react';
import { LocalDebug } from '../../../../utils/LocalDebug';
import ResourceModel from '../../../../models/ResourceModel';
import FormItemSummary from './components/FormItemSummary';
import { requiredTrimRule } from '../../../../utils/common';
import { updateOrCreateResource } from '../../../../actions/resource';
import { useDispatch } from 'react-redux';
import GenderScoreResourceCard from '../../../company/genderscore/resources/GenderScoreResourceCard';
import { GENDERSCORE_PILLAR_OPTIONS_LIST } from '../../../../constants/genderscore-pillars';

const ResourceForm = ({
	initialValues,
	onSubmit,
	lang,
	setLang,
}) => {
	const className = 'ResourceForm';

	const theme = useTheme();
	const dispatch = useDispatch();

	const [form] = Form.useForm();
	const [refresh, setRefresh] = useState(0);
	const [resource, setResource] = useState(new ResourceModel(initialValues));
	// const resource = new ResourceModel(initialValues);

	LocalDebug.logInfo({ className }, Object.keys(resource?.options || {}));

	const handleChange = (values) => {
		Object.entries(values).forEach(([key, value]) => resource.setField(key, value, lang));
		LocalDebug.logInfo({ className, method: 'handleChange' }, values, lang, resource, resource.title);
		setRefresh((p) => p + 1);
	};

	const handleSubmit = async (values) => {
		LocalDebug.logInfo({ className, method: 'handleSubmit' }, values, form.getFieldsValue(), resource);
		await dispatch(updateOrCreateResource(resource));
		onSubmit();
	};

	useEffect(() => {
		LocalDebug.logUseEffect({ className, effects: 'lang, refresh' });
		setResource(new ResourceModel(resource));
	}, [lang, refresh]);

	return (
		<Form
			form={form}
			name='resource-form'
			initialValues={initialValues}
		>
			<div
				style={{
					display: 'grid',
					gridTemplateColumns: '480px 400px',
					columnGap: 40,
				}}
			>
				<div>
					<FormInput
						label={resource?.getLabel('title', lang)}
						name={resource?.getFieldName('title', lang)}
						onChange={(e) => handleChange({ [resource?.getFieldName('title', lang)]: e.target.value })}
						style={{ minHeight: 60 }}
						rules={[{ ...requiredTrimRule, message: '' }]}
						extra={'~60 characters max'}
						maxLength={60}
						isTextarea
					/>

					<FormItemSummary
						label={resource?.getLabel('summary', lang)}
						name={resource.getFieldName('summary', lang)}
						rules={[{ ...requiredTrimRule, message: '' }]}
						onChange={handleChange}
					/>

					<FormInput
						label='Link'
						name='url'
						extra={<>URL to navigate to when clicking 'Open'</>}
						placeholder={'https://'}
						rules={[{ ...requiredTrimRule, message: '' }]}
						onChange={(e) => handleChange({ url: e.target.value })}
					/>

					<FieldItemUpload
						resource={new ResourceModel(resource)}
						fieldName='image'
						lang={lang}
						// refresh={refresh}
						onChange={handleChange}
					/>

					{GENDERSCORE_PILLAR_OPTIONS_LIST
						.map((pillar) => (
							<Checkbox
								key={pillar.value}
								onChange={(value) => handleChange({
									pillar: resource.pillar,
								})}
							>
								{pillar.label}
							</Checkbox>
						))}
				</div>

				<div
					style={{
						position: 'relative',
						background: theme.color.bgGrey,
						border: '2px dashed rgba(0,0,0,.05)',
						borderRadius: 10,
						padding: 20,
					}}
				>
					<Divider>Preview</Divider>

					<GenderScoreResourceCard
						resource={new ResourceModel(resource)}
						withPillarTags={true}
					/>
				</div>

			</div>

			<Divider />

			<FooterAction
				onSubmit={handleSubmit}
				lang={lang}
				setLang={setLang}
			/>
		</Form>
	);
};

export default ResourceForm;
