import { Space } from 'antd';
import { useTheme } from '@emotion/react';
import moment from 'moment';
import { LocalDebug } from '../../../../../utils/LocalDebug';

const UserGridCardLabelValue = (
	{
		label,
		value,
		labelStyle = {},
		valueStyle = {},
		orientation,
		size = 4,
		children,
		...props
	},
) => {
	const className = 'UserGridCardLabelValueInline';

	const theme = useTheme();

	// LocalDebug.logInfo({ className }, { value });
	if (!children) return null;

	if (orientation === 'inline') {
		return (
			<div
				style={{
					...props?.style,
				}}
			>
				<Space
					size={6}
					align='top'
					style={{ width: '100%' }}
				>
					{label && (
						<span
							style={{ ...labelStyle }}
						>
							{label}
						</span>
					)}
					<span
						style={{
							// fontWeight: 'bold',
							...valueStyle,
						}}
					>
						{/* {value} */}
						{children}
					</span>
				</Space>
			</div>
		);
	}

	const alignItems = orientation === 'vertical' ? 'center' : 'stretch';

	return (
		<div
			style={{
				...props?.style,
			}}
		>
			<Space
				direction='vertical'
				size={size || 4}
				align='top'
				style={{
					justifyContent: 'center',
					alignItems,
					width: '100%',
				}}
			>
				{label && (
					<div
						style={{
							width: '100%',
							fontSize: 12,
							color: theme.color.grey,
							...labelStyle,
						}}
					>
						{label}
					</div>
				)}
				<div
					style={{
						width: '100%',
						lineHeight: 1.4,
						...valueStyle,
					}}
				>
					{children}
				</div>
			</Space>
		</div>
	);
};

export default UserGridCardLabelValue;
