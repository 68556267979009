import FormSelect from './FormSelect';
import { Card, Select, Space } from 'antd';
import { getAclRoleDetails, getAclRoleLabel, getAclRoleTag } from '../../../constants/constant';
import { useTheme } from '@emotion/react';

const FormAclRoleSelect = (
	{
		showSearch = true,
		onChange,
		defaultValue,
		placeholder = 'Select a role',
		styles = {},
		mode = 'multiple',
		...props
	},
) => {
	const theme = useTheme();
	const _handleChange = (value, option) => {
		if (onChange) onChange(value, option);
	};

	return (
		<FormSelect
			showSearch={showSearch}
			optionFilterProp="children"
			placeholder={placeholder || 'Select a role'}
			onChange={_handleChange}
			defaultValue={defaultValue}
			styles={{ minWidth: 180, ...styles }}
			mode={mode}
			// filterOption={(input, option) =>
			//     option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
			// }
			optionLabelProp="label"
			render={(option) => <Select.Option
				key={option.value}
				label={getAclRoleTag(option.value)}
				value={option.value}
			>
				<Card size="small" title={getAclRoleTag(option.value)} bordered={true} style={{ padding: 0 }}>
					<Space direction='vertical' size={4}>
						{/* {getAclRoleTag(option.value)} */}
						<span style={{
							width: '100%', whiteSpace: 'normal', fontSize: 12, color: theme.color.darkgrey, lineHeight: 1,
						}}>{getAclRoleDetails(option.value)}</span>
					</Space>
				</Card>
			</Select.Option>}
			{...props}
		/>
	);
};

export default FormAclRoleSelect;
