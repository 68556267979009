import { Button, Space, Tooltip } from 'antd';
import { useTheme } from '@emotion/react';
import { FaExclamationTriangle, FaExternalLinkAlt } from 'react-icons/fa';
import { openExternalUrl } from '../../../../utils/common';
import CompanyModel from '../../../../models/CompanyModel';
import { ENGLISH_LANG } from '../../../../constants/constant';
import { useContext, useEffect, useState } from 'react';
import { CompanyPageContext, LANG_TEXTS } from './CompanyPageProvider';

const CompanyPagePreviewButton = (
	{
		company,
		title,
		forceLang,
		withFlag = false,
		...props
	},
) => {
	const className = 'CompanyPagePreviewButton';

	const companyModel = new CompanyModel(company);

	const [forceLangTexts, setForceLangTexts] = useState(null);

	const {
		lang,
		langTexts,
	} = useContext(CompanyPageContext);

	useEffect(() => {
		setForceLangTexts(forceLang ? LANG_TEXTS[forceLang] : langTexts);
	}, [lang, langTexts, forceLang]);
	return (
		<Tooltip
			title={(
				<Space
					direction='vertical'
					style={{ lineHeight: 1.3 }}
				>
					<div>
						Opens the public version of the company page{forceLangTexts?.labelInFlag}
					</div>
					{!companyModel?.isPublishedNow() && (
						<span style={{ fontSize: 12 }}>
							<FaExclamationTriangle />&nbsp;
							<i><b>
								Company is not yet published,
								you will need to be logged with the same account used on
								this platform to see the page on the Candidates' website.
							</b></i>
						</span>
					)}
				</Space>
			)}
		>
			<Space size={12}>
				<Button
					onClick={() => openExternalUrl(
						`${companyModel?.getAppUri()}${forceLang ? `?forceLang=${forceLang}` : ''}`,
					)}
					style={{
						borderRadius: 5,
						minWidth: 120,
					}}
					{...props}
				>
					{title || <b>Preview {withFlag && forceLangTexts?.flag}</b>}
					&nbsp;
					<FaExternalLinkAlt
						style={{ fontSize: 12, marginBottom: -1 }}
					/>
				</Button>
			</Space>
		</Tooltip>
	);
};

export default CompanyPagePreviewButton;
