import { ENGLISH_LANG, FRENCH_LANG } from '../../constants/constant';

export const META_CURRENT_VALUE_TITLE = {
	[ENGLISH_LANG]: '👀 Current content of the meta tag:',
	[FRENCH_LANG]: '👀 Contenu actuel de la meta tag:',
};

export const META_CURRENT_VALUE_TOOLTIP_DEFAULT = {
	[ENGLISH_LANG]: 'The value of this meta tag is the default one',
	[FRENCH_LANG]: 'Cette meta tag a la valeur par défaut',
};

export const META_CURRENT_VALUE_TOOLTIP_CUSTOM = {
	[ENGLISH_LANG]: 'The value of this meta tag is customized',
	[FRENCH_LANG]: 'Cette meta tag est personnalisée',
};

export const META_DEFAULT_COMPANY_ALT_PREFIX = {
	[ENGLISH_LANG]: 'This is the logo of ',
	[FRENCH_LANG]: 'Ceci est le logo de ',
};

export const META_DEFAULT_ARTICLE_ALT_PREFIX = {
	[ENGLISH_LANG]: 'This is the illustration of the article ',
	[FRENCH_LANG]: "Ceci est l'illustration de l'article ",
};
