import { PERMS_UMS_ENABLED } from './ums-utils';
import UMSPresentationPage from '../../containers/ums/UMSPresentationPage';
import { BulbOutlined } from '@ant-design/icons';
import MenuBase from '../MenuBase';

class UMSPresentationMenu extends MenuBase {
	name = 'ums-presentation';

	label = 'Presentation';

	icon = <BulbOutlined />;

	iconLegacy = '💡';

	path = '/ums/presentation';

	aliases = ['^/ums/presentation$'];

	Component = UMSPresentationPage;

	perms = PERMS_UMS_ENABLED;
}

export default UMSPresentationMenu;
