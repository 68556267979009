import ReactMarkdown from 'react-markdown';
import { UMSBox } from './UMSManagerResults';
import UMSStaffSurveyComputeAnalysisButton from '../staff/UMSStaffSurveyComputeAnalysisButton';
import { useAuthContext } from '../../../hooks/useAuthContext';
import config from '../../../config/config';
import {
	Divider, Popover, Radio, Space,
	Tag,
} from 'antd';
import { useState } from 'react';
import dayjs from 'dayjs';
import JsonPopoverLink from '../../app/debug/JsonPopoverLink';

const StaffActionsBanner = ({
	umsResult,
	analysis,
	setAnalysis,
	onRefresh,
}) => {
	const { isStaffView } = useAuthContext();

	const [prompt, setPrompt] = useState(umsResult?.analysis?.prompt);
	const [model, setModel] = useState(umsResult?.analysis?.model);
	const [createdAt, setCreatedAt] = useState(umsResult?.analysis?.createdAt);
	const [createdBy, setCreatedBy] = useState(umsResult?.analysis?.createdBy);

	if (!(config.isDevEnv || isStaffView)) return null;
	return (
		<>
			<Space
				style={{
					justifyContent: 'space-between',
					alignItems: 'center',
					width: '100%',
					marginBottom: 10,
				}}
			>
				<Radio.Group onChange={(e) => setAnalysis?.(umsResult?.analysisHistory[e.target.value])} defaultValue={analysis}>
					{umsResult?.analysisHistory
						?.map(({
							completion, prompt, createdAt, model,
						}, index) => (
							<Radio.Button
								key={index}
								value={index}
								style={{ height: 40 }}
							>
								<Space
									style={{
										alignItems: 'center',
										lineHeight: 1.3,
										verticalAlign: 'middle',
									}}
								>
									<div
										style={{
											fontSize: 16,
											fontWeight: 'bold',
											marginTop: 4,
											// lineHeight: 1,
										}}
									>
										{index + 1}
									</div>
									<Space
										direction='vertical'
										size={0}
										style={{
											fontSize: 10,
										}}
									>
										<b>{model}</b>
										{dayjs(createdAt).format('MM-DD HH:mm')}
									</Space>
								</Space>
							</Radio.Button>
						))}
				</Radio.Group>

			</Space>

			<UMSStaffSurveyComputeAnalysisButton
				survey={umsResult?.managerSurvey}
				onSuccess={onRefresh}
			/>

			<Divider />

			<Space>
				<JsonPopoverLink data={analysis} />
				<Popover
					title='Prompt'
					content={
						<div
							style={{
								maxWidth: 600,
								maxHeight: '70vh',
								overflow: 'scroll',
							}}
						>
							<div style={{ zoom: 0.7 }}>
								<ReactMarkdown>
									{prompt}
								</ReactMarkdown>
							</div>
						</div>
					}
				>
					<Tag>Prompt</Tag>
				</Popover>

				<span>Completion generated with <b>{model}</b> by <b>{createdBy}</b> on <b>{createdAt}</b></span>
			</Space>

			<Divider />
		</>
	);
};
const UMSManagerResultsAnalysisTab = ({
	umsResult,
	onRefresh,
}) => {
	const className = 'UMSManagerResultsAnalysisTab';

	const [analysis, setAnalysis] = useState(umsResult?.analysis);

	return (
		<UMSBox>
			<StaffActionsBanner
				umsResult={umsResult}
				analysis={analysis}
				setAnalysis={setAnalysis}
				onRefresh={onRefresh}
			/>
			<ReactMarkdown>
				{analysis.completion}
			</ReactMarkdown>
		</UMSBox>
	);
};

export default UMSManagerResultsAnalysisTab;
