import { Tag, Tooltip } from 'antd';
import { ClockCircleOutlined } from '@ant-design/icons';
import { findActionTimeframe } from './action-plan';

const CompanyActionTimeframeTag = ({
	action,
	timeframe,
	...props
}) => {
	const className = 'CompanyActionTimeframeTag';

	const timeframeOption = findActionTimeframe(action?.timeframe || timeframe);

	if (!timeframeOption) return null;

	return (
		<Tooltip title={timeframeOption?.label}>
			<Tag
				color={timeframeOption?.tagColor}
				{...props}
				style={{
					border: 'none',
					borderRadius: 6,
					fontWeight: 'bold',
					padding: '4px 8px',
					...props?.style,
				}}
			>
				{timeframeOption?.tagIcon || <ClockCircleOutlined />} {timeframeOption?.tagLabel}
			</Tag>
		</Tooltip>
	);
};

export default CompanyActionTimeframeTag;
