import { optionalizeQuestion } from '../../shared';
import QUESTIONS from '../../questions';
import { GENDERSCORE_PILLAR_FAIRCAREERPATH_OPTION } from '../../../../../constants/genderscore-pillars';

const section = {
	...GENDERSCORE_PILLAR_FAIRCAREERPATH_OPTION,
	details: `
### Did you know that in Europe, women account for less than 8% of C-level positions?

Breaking the glass ceiling means ensuring that women have access to the same opportunities for internal career progression as men.`,
	questions: [
		QUESTIONS.CAREERPATH_GRIDS,
		QUESTIONS.CAREERPATH_SKILLSMATRIX,
		QUESTIONS.CAREERPATH_CAREERPATHING,
		QUESTIONS.CAREERPATH_BUDDY,
		QUESTIONS.CAREERPATH_MENTORING,
		QUESTIONS.CAREERPATH_SPONSORSHIP,
		optionalizeQuestion({
			...QUESTIONS.CAREERPATH_SPONSOREDPCT,
			clause: (answers) => answers?.[QUESTIONS.CAREERPATH_SPONSORSHIP.questionId] === 'yes',
		}),
	],
};

export default section;
