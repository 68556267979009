import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
	Col, Form, Row, Select,
} from 'antd';
import { getCampaignEmailFilters } from '../../../../reducers/app';
import { objectFilterUndefinedValues } from '../../../../utils/common';
import FormItem from '../../../company/form/FormItem';
import FormSelect from '../../../app/form/FormSelect';
import FilterModal from '../../../app/filters/FilterModal';
import { localDebug } from '../../../../utils/LocalDebug';

const CampaignEmailFilterModal = (
	{
		title,
		open,
		initialValues = {},
		onClose,
		onFilter,
	},
) => {
	const [form] = Form.useForm();

	const filters = useSelector(getCampaignEmailFilters);

	const handleFilter = () => {
		form
			.validateFields()
			.then((values) => {
				const filtersValues = {
					...objectFilterUndefinedValues(values),
				};
				if (onFilter) onFilter(filtersValues);
			})
			.catch((errors) => {
				localDebug('Validate Failed:', errors);
			});
	};

	useEffect(() => {
		form.resetFields();
		form.setFieldsValue(initialValues);
	}, [form, initialValues]);

	return (
		<FilterModal
			title={title}
			width={750}
			open={open}
			onClose={onClose}
			onFilter={handleFilter}
		>
			<Form
				form={form}
				name="add-team"
				labelCol={{ span: 11 }}
				labelAlign='right'
				className="user-filter-form"
			>
				<Row gutter={24}>
					<Col span={12}>
						<FormItem
							label='Campaign'
							name="campaign"
						>
							<FormSelect
								mode="multiple"
								options={filters?.campaign?.map((item) => ({ label: item, value: item })) || []}
								render={(option) => <Select.Option key={option.value} label={option.label}
																   value={option.value}>
									{option.label}
								</Select.Option>}
							/>
						</FormItem>
					</Col>
					<Col span={12}>
						<FormItem
							label='Status'
							name="status"
						>
							<FormSelect
								mode="multiple"
								options={filters?.status?.map((item) => ({ label: item, value: item })) || []}
								render={(option) => <Select.Option key={option.value} label={option.label}
																   value={option.value}>
									{option.label}
								</Select.Option>}
							/>
						</FormItem>
					</Col>
				</Row>

			</Form>

			{/* <JSONPretty data={filters}/> */}
		</FilterModal>
	);
};

export default CampaignEmailFilterModal;
