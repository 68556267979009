import { useContext } from 'react';
import { Button, Popover, Tooltip } from 'antd';
import { FaEnvelope } from 'react-icons/fa';
import UserAvatarList from '../widgets/UserAvatarList';
import { MESSAGES_ICON, MESSAGES_MENU } from '../../../constants/constant';
import UserButtonBadge from './UserButtonBadge';
import { documentId, writeLog } from '../../../utils/common';
import { UserMessageModalContext } from '../../../contexts/modals/UserMessageModalProvider';
import GenderhireMessagesMenu from '../../../menu/genderhire/GenderhireMessagesMenu';

const UserContacter = (
	{
		user,
		Component,
		onStart,
		onComplete,
		withTooltip = false,
		popoverPlacement = 'left',
		withPrimaryStyle = true,
		setExternalOpenPopover,
		setExternalHandleSubmit,
		children,
		...props
	},
) => {
	const className = 'UserContacter';

	const { setOpen, setUser, setOnComplete } = useContext(UserMessageModalContext);

	const senders = [];
	user?.messagesTo?.forEach((item) => {
		const { user } = item;
		senders.push(...user && !senders.some((s) => (documentId(s)?.toString?.()) === (documentId(user)?.toString?.())) ? [user] : []);
	});

	const handleClick = async (e) => {
		e?.stopPropagation();
		setOpen(true);
		setUser(user);
		setOnComplete(() => async () => onComplete?.());
		writeLog('Open modal user contact  ', { userContacted: documentId(user) });
		await props?.onClick?.();
	};

	let button = Component
		? <Component
			{...props}
			onClick={handleClick}
		>
			{children}
		</Component>
		: <Button
			{...!(user?.messagesToCount > 0) && withPrimaryStyle && { type: 'primary' }}
			onClick={handleClick}
			icon={<FaEnvelope style={{ marginBottom: -2 }}/>}
			size={props?.size || 10}
			style={props?.style?.button}
		>
			<>&nbsp;{props?.title || props?.title === '' ? props?.title : 'Contact'}</>
		</Button>;

	if (withTooltip && user?.messagesToCount === 0) {
		button = <Tooltip title={<>Contact {user.firstName}</>}>{button}</Tooltip>;
	}

	const messagesMenu = new GenderhireMessagesMenu();

	return (
		<div
			style={{
				textAlign: 'left',
			}}
		>
			{user?.messagesToCount > 0
				? (
					<Popover
						placement={popoverPlacement}
						style={{ marginRight: 20 }}
						title={<>{messagesMenu.icon} {messagesMenu.label}</>}
						content={(
							<>
								<strong>Contacted</strong>
								&nbsp;{user?.messagesToCount} time{user?.messagesToCount > 1 && 's'}
								{senders?.length > 0 && (
									<>
										&nbsp;by&nbsp;
										<UserAvatarList
											size={28}
											overflow={-9}
											users={senders}
											withCurrentUser={true}
										/>
									</>
								)}
							</>
						)}
					>
						<UserButtonBadge
							count={user?.messagesToCount}
							style={{ ...props?.style?.badge }}
						>
							{button}
						</UserButtonBadge>
					</Popover>
				)
				: button
			}
		</div>
	);
};

export default UserContacter;
