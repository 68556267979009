import { PERMS_UMS_ENABLED_ADMIN, PERMS_UMS_ENABLED_MANAGER } from './ums-utils';
import UMSTeamPage from '../../containers/ums/UMSTeamPage';
import MenuBase from '../MenuBase';
import { TeamOutlined } from '@ant-design/icons';

class UMSTeamMenu extends MenuBase {
	name = 'ums-team';

	path = '/ums/team';

	aliases = ['^/ums/team'];

	label = 'Team';

	icon = <TeamOutlined />;

	iconLegacy = '📨';

	Component = UMSTeamPage;

	perms = [
		...PERMS_UMS_ENABLED_ADMIN,
		...PERMS_UMS_ENABLED_MANAGER,
	];
}
export default UMSTeamMenu;
