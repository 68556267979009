import { Col, InputNumber, Row } from 'antd';
import { LocalDebug } from '../../../utils/LocalDebug';
import { useTheme } from '@emotion/react';
import { useEffect, useRef, useState } from 'react';
import { countDecimals, isValueDefined, round } from '../../../utils/common';

const SurveyQuestionInputNumber = ({
	question,
	value,
	onChange,
}) => {
	const className = 'SurveyQuestionInputNumber';

	const theme = useTheme();
	const inputRef = useRef();

	const [decimals, setDecimals] = useState(countDecimals(question?.step));

	useEffect(() => {
		inputRef?.current?.focus?.();
		setDecimals(countDecimals(question?.step));
	}, [question, question?.step]);

	const handleChange = (val) => {
		if (!isValueDefined(val)) {
			onChange(null);
		} else {
			const newValue = (
				Math.min(
					question?.max || 0,
					Math.max(
						question?.min || 0,
						round(val, decimals),
					),
				) || 0
			);
			onChange(newValue);
		}
	};

	return (
		<div>
			<InputNumber
				ref={inputRef}
				size='large'
				onChange={handleChange}
				value={value}
				min={question?.min}
				max={question?.max}
				step={question?.step}
				placeholder={round(
					(question?.min || 0) + ((question?.max || 0) - (question?.min || 0)) / 2,
					decimals,
				)}
				style={{
					textAlign: 'right',
					width: 130,
					color: theme.color.fitBlueElectric,
				}}
			/>
		</div>
	);
};

export default SurveyQuestionInputNumber;
