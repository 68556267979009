import FeedbackBtnBuilder from './FeedbackBtnBuilder';

const FeedbackBanner = (
	{
		text = '👋 Something wrong, missing, or could be improved?',
		linkText = 'Send a feedback',
		inHeader = false,
		initialValues = {},
		...props
	},
) => {
	return null;
	return <div style={{
		textAlign: 'center',
		margin: 20,
		fontSize: 12,
		...inHeader && { textAlign: 'left', margin: '0 0 20px 0' },
		...props?.style,
	}}>
		{text} <FeedbackBtnBuilder initialValues={initialValues} title={linkText}/>
	</div>;
};

export default FeedbackBanner;
