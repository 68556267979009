import { Select } from 'antd';
import { UMS_SURVEY_EMPLOYEE_OPTIONS } from '../../../../constants/property/ums-survey';

const CompanyPlanUMSSurveyEmployeeSelect = ({
	...props
}) => {
	const className = 'CompanyPlanUMSSurveyEmployeeSelect';

	return (
		<Select
			{...props}
			options={UMS_SURVEY_EMPLOYEE_OPTIONS}
			style={{
				minWidth: 150,
				...props?.style,
			}}
		/>
	);
};

export default CompanyPlanUMSSurveyEmployeeSelect;
