import { Space, Tag } from 'antd';
import { groupHardSkills } from '../../../../../constants/constant';
import UserModel from '../../../../../models/UserModel';
import UserGridCardLabelValue from './UserGridCardLabelValue';
import UserGridCardValueList from './UserGridCardValueList';

const UserGridCardCareerHardSkill = (
	{
		user,
		label = <>Hard Skills</>,
		placement = 'right',
		defaultContainer = 'span',
		withSeparator = true,
		itemStyle,
		grouped = false,
		...props
	},
) => {
	const className = 'UserGridCardCareerHardSkill';

	const userModel = new UserModel(user);

	let items = userModel?.getCareerHardSkills();

	if (grouped) {
		const groups = groupHardSkills(items);
		items = Object
			.entries(groups)
			.map(([group, labels], index) => (
				<div key={index}>
					<Space direction='horizontal'
						style={{ width: '100%', ...index > 0 && { marginTop: 10 } }}
						align='start'
						key={index}
					>
						<div style={{
							width: 160,
							fontWeight: 'bold',
							...itemStyle,
						}}>
							{group}
						</div>
						<UserGridCardValueList
							items={labels}
							placement={placement}
							defaultContainer={defaultContainer}
							withSeparator={withSeparator}
							itemStyle={itemStyle}
						/>
					</Space>
				</div>
			));
	}

	return (
		<UserGridCardLabelValue
			label={label}
			style={{ ...props?.style }}
		>
			<UserGridCardValueList
				items={items}
				placement={placement}
				defaultContainer={defaultContainer}
				withSeparator={!grouped && withSeparator}
				itemStyle={itemStyle}
			/>
		</UserGridCardLabelValue>
	);
};

export default UserGridCardCareerHardSkill;
