import { Button, Popover } from 'antd';
import { FaMapPin } from 'react-icons/fa';
import UserButtonBadge from './UserButtonBadge';
import UserAvatarList from '../widgets/UserAvatarList';
import { useContext, useEffect, useState } from 'react';
import { documentId, writeLog } from '../../../utils/common';
import { useSelector } from 'react-redux';
import { getCompanySelected } from '../../../reducers/app';
import Logo50inTech from '../../app/Logo50inTech';
import CompanyModel from '../../../models/CompanyModel';
import { UserSuggestModalContext } from '../../../contexts/modals/UserSuggestModalProvider';

const UserSaver = (
	{
		user,
		job,
		mode = 'saved',
		Component,
		popoverPlacement = 'left',
		onStart,
		onComplete,
		setExternalOpenPopover,
		setExternalHandleSubmit,
		children,
		...props
	},
) => {
	const className = 'UserSaver';

	const {
		setOpen, setUser, setOnComplete,
	} = useContext(UserSuggestModalContext);

	const companySelected = useSelector(getCompanySelected);
	const [company, setCompany] = useState();

	const buttonLabel = props?.title || 'Save for later';
	let popupTitle = <span>📌 Save a talent for later</span>;
	let popoverTitle = <> 📌 Saved for later</>;
	let popoverContentLabel = '📌 Saved';
	let counter = user?.shortlistsCount;
	let shortlists = user?.shortlists;
	const managers = [];

	if (mode === 'suggested') {
		popupTitle = <>
			<Logo50inTech size={24} style={{ marginBottom: -4, marginRight: 8 }}/>
			<span>{company ? `Suggest a talent for ${company.name}` : 'Suggest a talent'}</span>
		</>;
		popoverTitle = <>
			<Logo50inTech size={14} style={{ marginBottom: -2, marginRight: 4 }}/>
			<span>{company ? `Suggestions for ${company.name}` : 'Suggestions for clients'}</span>
		</>;
		popoverContentLabel = '📌 Shortlisted';
		counter = user?.suggestedSourcings?.length;
		shortlists = user?.suggestedSourcings;
	}

	shortlists?.forEach((item) => {
		const { manager } = item;
		managers.push(...manager && !managers.some(
			(s) => (documentId(s)?.toString?.()) === (documentId(manager)?.toString?.()),
		)
			? [manager] : []);
	});

	useEffect(() => {
		if (companySelected) {
			setCompany(new CompanyModel(companySelected));
		} else {
			setCompany(null);
		}
	}, [companySelected]);

	const handleClick = async (e) => {
		e?.stopPropagation();
		setOpen(true);
		setUser(user);
		setOnComplete(() => async () => onComplete?.());
		await props?.onClick?.();
	};

	const button = (
		Component
			? (
				<Component
					{...props}
					onClick={handleClick}
				>
					{children}
				</Component>
			)
			: (
				<Button
					{...!(counter > 0 || user?.sourcingsCount > 0) && { type: 'primary' }}
					onClick={handleClick}
					icon={<FaMapPin style={{ marginBottom: -2 }}/>}
					size={props?.size || 10}
					{...props?.style && { style: { ...props?.style } }}
				>
					&nbsp;{buttonLabel}
				</Button>
			)
	);
	return (
		<span
			className={props?.className}
			style={{
				textAlign: 'left',
			}}
		>
			{counter > 0
				? (
					<Popover
						title={popoverTitle}
						placement={popoverPlacement}
						content={(
							<>
								<strong>{popoverContentLabel}</strong>
						&nbsp;{counter} time{counter > 1 ? 's' : ''}
								{managers?.length > 0 && (
									<>
								&nbsp;by&nbsp;
										<UserAvatarList
											size={28}
											overflow={-9}
											users={managers}
											withCurrentUser={true}
										/>
									</>
								)}
							</>
						)}
						style={{
							marginRight: 100,
							zIndex: 1500,
						}}
					>
						<UserButtonBadge
							count={counter}
							style={{ ...props?.style?.badge }}
						>
							{button}
						</UserButtonBadge>
					</Popover>
				)
				: button
			}
		</span>
	);
};

export default UserSaver;
