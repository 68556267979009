import { opacify } from '../../../utils/common';
import FormItem from '../../company/form/FormItem';
import Callout from '../callout/Callout';
import { useTheme } from '@emotion/react';

const FormItemState = (
	{
		isFormChanged,
		initialStateText = 'Current state',
		changedStateText = 'New state after save',
		children,
		...props
	},
) => {
	const theme = useTheme();
	return (
		// <Callout
		// 	withFeedback={false}
		// 	style={{
		// 		background: 'transparent',
		// 		// marginBottom: 20,
		// 	}}
		// >
		<FormItem
			label={<span style={opacify(true)}
			>
				{isFormChanged ? changedStateText : initialStateText}
			</span>}
			style={{
				padding: '10px 20px',
				background: isFormChanged ? theme.color.white : theme.color.bgGrey,
				borderRadius: 5,
				border: isFormChanged
					? `1px dashed ${theme.color.grey}`
					: '1px solid transparent',
				...props?.style,
			}}
		>
			{children}
		</FormItem>
		// </Callout>
	);
};
export default FormItemState;
