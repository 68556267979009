import GenderhireMessagesMenu from './GenderhireMessagesMenu';
import GenderhireJobsMenu from './GenderhireJobsMenu';
import GenderhireReportsMenu from './GenderhireReportsMenu';
import GenderhireCandidatesAtsMenu from './GenderhireCandidatesAtsMenu';
import GenderhireCandidatesMenu from './GenderhireCandidatesMenu';
import GenderhireSearchTalentsMenu from './GenderhireSearchTalentsMenu';
import { GENDERHIRE_LABEL } from '../../constants/constant';
import { menuGroupBuilder } from '../menu.utils';
import MenuBase from '../MenuBase';

class GenderhireMenu extends MenuBase {
	static props = {
		children: (args) => [
			menuGroupBuilder({ label: GENDERHIRE_LABEL }),
			(new GenderhireSearchTalentsMenu()).build({
				isRoot: !args.isGenderScoreEnabled || !args.isDocumentAdmin,
			}),
			(new GenderhireCandidatesMenu()).build(),
			(new GenderhireCandidatesAtsMenu()).build(),
			(new GenderhireMessagesMenu()).build(),
			(new GenderhireJobsMenu()).build(),
			(new GenderhireReportsMenu()).build(),
		],
	};

	constructor() {
		super(GenderhireMenu.props);
	}
}

export default GenderhireMenu;
