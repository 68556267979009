import { Input } from 'antd';
import { useEffect, useRef } from 'react';

const SurveyQuestionInputText = ({
	question,
	value,
	onChange,
}) => {
	const className = 'SurveyQuestionInputText';

	const inputRef = useRef();

	useEffect(() => {
		inputRef?.current?.focus?.();
	}, [question]);

	return (
		<Input
			ref={inputRef}
			onChange={(e) => onChange(e.target.value)}
			value={value}
		/>
	);
};

export default SurveyQuestionInputText;
