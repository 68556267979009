import { useTheme } from '@emotion/react';
import { Table } from 'antd';
import { useEffect, useState } from 'react';
import DrawerContainer from '../app/drawer/DrawerContainer';
import TableColumns from '../app/table/TableColumns';
import CompanyStub from './CompanyStub';
import ArticleService from '../../services/article';
import ArticleImage from '../staff/content/articles/ArticleImage';
import config from '../../config/config';

const CompanyStoriesDrawerContent = (
	{
		company = null,
		data = null,
	},
) => {
	const theme = useTheme();

	const [dataSource, setDataSource] = useState([]);
	const [isLoading, setIsLoading] = useState(false);

	const columns = [
		{
			title: 'Image',
			dataIndex: 'illustration',
			render: (value, row) => <ArticleImage article={row} size={140}/>,
		},
		{
			title: 'Title',
			dataIndex: 'title',
			render: (value, row) => <a href={`${config.appRoot}/article/${row?.slug}`}
				   target='_blank'
				   rel='noreferrer'
				   className='link'
				   style={{ fontSize: 14, fontWeight: 700, lineHeight: 1.3 }}>
				{value}
			</a>,
			...TableColumns.columnMinWidth(300),
		},
		{
			title: 'Topic',
			dataIndex: 'topic',
			align: 'center',
			render: (value, row) => <b
				style={{ textTransform: 'uppercase', fontWeight: 700, color: theme.color.darkgrey }}>{value}</b>,
			...TableColumns.columnMinWidth(70),
		},
		TableColumns.userAvatarColumn(theme, { title: 'Author' }),
		// TableColumns.userStubColumn(theme, {
		//     sorter: false,
		//     value: (value, row) => row
		// }),
	];

	const fetchData = async (ids) => {
		const { data: { articles } } = await ArticleService.findAll({ ids });
		setDataSource(articles);
		setIsLoading(false);
	};

	useEffect(() => {
		if (data) {
			setDataSource(data);
			return;
		}
		if (company) {
			setIsLoading(true);
			fetchData(company.articles);
		}
	}, [company, data]);

	return (
		<DrawerContainer
			header={<CompanyStub company={company}/>}
		>
			<Table
				dataSource={dataSource}
				loading={isLoading}
				columns={columns}
				size="middle"
				bordered
			/>
		</DrawerContainer>
	);
};

export default CompanyStoriesDrawerContent;
