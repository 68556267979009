const { isStringTypeValidator } = require('../../utils/isStringTypeValidator');

export const CAREER_ROLE_GROUP_KEY = 'career-role-group';

export const CAREER_ROLE_GROUP_ANSWER_ENGINEERING = 'engineering';
export const CAREER_ROLE_GROUP_ANSWER_DATA = 'data';
export const CAREER_ROLE_GROUP_ANSWER_PRODUCT_DESIGN = 'product-design';
export const CAREER_ROLE_GROUP_ANSWER_OPERATIONS = 'operations';
export const CAREER_ROLE_GROUP_ANSWER_SALES = 'sales';
export const CAREER_ROLE_GROUP_ANSWER_MARKETING = 'marketing';
export const CAREER_ROLE_GROUP_ANSWER_PEOPLE_HR = 'people-hr';
export const CAREER_ROLE_GROUP_ANSWER_FINANCE_LEGAL = 'finance-legal';

export const CAREER_ROLE_GROUP_OPTIONS = [
	{ value: CAREER_ROLE_GROUP_ANSWER_ENGINEERING, label: 'Engineering' },
	{ value: CAREER_ROLE_GROUP_ANSWER_DATA, label: 'Data' },
	{ value: CAREER_ROLE_GROUP_ANSWER_PRODUCT_DESIGN, label: 'Product/Design' },
	{ value: CAREER_ROLE_GROUP_ANSWER_OPERATIONS, label: 'Operations' },
	{ value: CAREER_ROLE_GROUP_ANSWER_SALES, label: 'Sales' },
	{ value: CAREER_ROLE_GROUP_ANSWER_MARKETING, label: 'Marketing' },
	{ value: CAREER_ROLE_GROUP_ANSWER_PEOPLE_HR, label: 'People/HR' },
	{ value: CAREER_ROLE_GROUP_ANSWER_FINANCE_LEGAL, label: 'Finance/Legal' },
];

export const customValidator = (value) => CAREER_ROLE_GROUP_OPTIONS.map((o) => o.value).includes(value);

export const CAREER_ROLE_GROUP_VALIDATORS = [
	isStringTypeValidator,
	customValidator,
];

export default {
	CAREER_ROLE_GROUP_ANSWER_ENGINEERING,
	CAREER_ROLE_GROUP_ANSWER_DATA,
	CAREER_ROLE_GROUP_ANSWER_PRODUCT_DESIGN,
	CAREER_ROLE_GROUP_ANSWER_OPERATIONS,
	CAREER_ROLE_GROUP_ANSWER_SALES,
	CAREER_ROLE_GROUP_ANSWER_MARKETING,
	CAREER_ROLE_GROUP_ANSWER_PEOPLE_HR,
	CAREER_ROLE_GROUP_ANSWER_FINANCE_LEGAL,

	key: CAREER_ROLE_GROUP_KEY,
	CAREER_ROLE_GROUP_KEY,
	validators: CAREER_ROLE_GROUP_VALIDATORS,
	CAREER_ROLE_GROUP_VALIDATORS,
	options: CAREER_ROLE_GROUP_OPTIONS,
	CAREER_ROLE_GROUP_OPTIONS,
};
