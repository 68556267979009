import { useTheme } from '@emotion/react';
import { useState } from 'react';
import { Tag } from 'antd';
import Table from '../../../app/table/Table';
import TableColumns from '../../../app/table/TableColumns';
import { FaEye, FaEyeSlash } from 'react-icons/fa';

const UserActionTable = (
	{
		onDataLoaded,
		path = '/admin/user-actions',
		filterOptions = {},
		isRefresh = 0,
		onSearchDataColumn,
		onToggleIsActive,
		onEditArticle,
		isEditor,
		...props
	},
) => {
	const theme = useTheme();

	const [isRefreshDataLayout] = useState(0);

	const columns = [
		// { title: 'ID', dataIndex: 'id' },
		TableColumns.userStubColumn(theme, { withDropdown: true, sorter: false }),
		TableColumns.companyLogoColumn(theme, { sorter: false, value: (value, row) => row?.user?.company }),
		{
			title: 'Action',
			dataIndex: 'action',
			align: 'center',
			sorter: false,
			render: (value, row) => {
				if (value === 'hide') {
					return <Tag color='volcano' style={{ padding: '3px 10px', fontSize: 12, fontWeight: 'bold' }}>
						<FaEyeSlash style={{ marginBottom: -2 }}/> Hide
					</Tag>;
				} if (value === 'view') {
					return <Tag color='green' style={{ padding: '3px 10px', fontSize: 12, fontWeight: 'bold' }}>
						<FaEye style={{ marginBottom: -2 }}/> View
					</Tag>;
				}
				return <Tag>{value}</Tag>;
			},
			...TableColumns.columnMinWidth(80),
		},
		TableColumns.userStubColumn(theme, {
			title: 'User target',
			dataIndex: 'userDocument',
			sorter: false,
			withDropdown: true,
			render: (value, row) => (value ? TableColumns.userStubColumn(theme, { withDropdown: true }).render(value, row) : <>{row.documentId}<br/>{row.documentSlug}</>),
		}),
		TableColumns.createdAtColumn(theme),
		TableColumns.updatedAtColumn(theme),
	];

	return (
		<>
			<Table
				path={path}
				onDataLoaded={onDataLoaded}
				columns={columns}
				filterOptions={filterOptions}
				isRefresh={isRefresh + isRefreshDataLayout}
				onSearchDataColumn={onSearchDataColumn}
				showTotal={(total) => `Total ${total} items`}
				limit={10}
				rowClassName={(row, index) => (row.is50Content === false ? 'rowDisabled' : '')}
				withDynamicSearch
				{...props}
			/>
		</>
	);
};

export default UserActionTable;
