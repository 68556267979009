import {
	useContext, useMemo,
} from 'react';
import { Popover, Space } from 'antd';
import { SurveyNavigationContext } from './providers/SurveyNavigationProvider';
import { numToArray, round, sumReducer } from '../../../utils/common';
import { useTheme } from '@emotion/react';
import GenderScorePillarLogoLabel from '../../company/genderscore/pillars/GenderScorePillarLogoLabel';
import { BiCaretUp } from 'react-icons/bi';
import { useNavigate } from 'react-router';
import {
	ANSWER_STATE_ANSWERED,
	ANSWER_STATE_LATER,
	ANSWER_STATE_SKIPPED,
	PROGRESS_BAR_ANSWERED_COLOR,
	PROGRESS_BAR_ANSWER_SKIPPED_COLOR,
	PROGRESS_BAR_UNANSWERED_COLOR,
	buildSectionQuestionPath,
	getQuestionAnsweredState,
	getSurveySectionsQuestionsFromAnswers,
	isQuestionAnswered,
	isSurveyCompleted,
} from '../data/shared';
import ReactMarkdown from 'react-markdown';
import SurveyAnswer from './SurveyAnswer';
import SurveyReviewButton from './SurveyReviewButton';
import { AppDataContext } from '../../../contexts/AppDataProvider';
import {
	EVENT, buildEventLabel, buildEventSection, buildEventValue, pushClick,
} from '../../../contexts/EventPushProvider';

const SurveyProgressBar = () => {
	const className = 'SurveyProgressBar';
	const theme = useTheme();
	const navigate = useNavigate();

	const {
		baseRoutePath,
		eventContext,
		surveyConf,
		answers,
		currentQuestion,
		currentSection,
		unansweredQuestions,
		appLang,
		t,
	} = useContext(SurveyNavigationContext);

	const eventProps = {
		...eventContext,
		...buildEventSection(EVENT.SECTION.SURVEY_PROGRESS_NAV),
	};

	const {
		isAppSiderVisibleInSurvey,
		isProgressBarVisibleInSurvey,
		isProgressBarClickableInSurvey,
	} = useContext(AppDataContext);

	const memoizedContent = useMemo(() => {
		if (!surveyConf) return null;

		if (!answers) return null;

		const surveySections = getSurveySectionsQuestionsFromAnswers({ surveyConf, answers });
		const columns = surveySections?.length || 1;
		const isSurveyCompletedResult = isSurveyCompleted({ surveyConf, answers });
		const questionCount = surveySections
			?.map((section) => section.questions.length)
			.reduce(sumReducer, 0);
		let lastFilledQuestionIndex = 0;
		let questionIndex = 0;
		let currentQuestionIndex = 0;

		surveySections
			?.forEach((section) => section?.questions
				?.forEach((question) => {
					if (isQuestionAnswered({ question, answers })) {
						lastFilledQuestionIndex = questionIndex;
					}
					if (question?.questionId === currentQuestion?.questionId) {
						currentQuestionIndex = questionIndex;
					}
					questionIndex += 1;
				}));

		lastFilledQuestionIndex = Math.max(lastFilledQuestionIndex, currentQuestionIndex);
		questionIndex = 0;
		return (
			<div
				style={{
					width: '100%',
				}}
			>
				<div
					style={{
						width: '100%',
						display: 'grid',
						gridTemplateColumns: `${isAppSiderVisibleInSurvey ? '' : '160px '}calc(100% - 210px - ${isAppSiderVisibleInSurvey ? '0px' : '160px'}) 210px`,
					}}
				>
					<div />

					<div
						style={{
							width: '100%',
							display: 'grid',
							gridTemplateColumns: `repeat(${columns}, ${round(100 / columns, 2)}%)`,
						}}
					>
						{surveySections
							?.map((section) => (
								<Space
									direction='vertical'
									key={section.value}
									// style={{ marginTop: 10 }}
								>
									{isProgressBarVisibleInSurvey && (
										<>
											<div
												style={{
													width: '100%',
													display: 'grid',
													gridTemplateColumns: numToArray(section?.questions?.length).map((item) => '1fr').join(' '),
													border: '8px solid transparent',
													borderBottom: 'none',
													columnGap: 1,
												}}
											>
												{section.questions
													?.map((question) => {
														questionIndex += 1;
														const value = answers?.[question?.questionId];
														const isAnswered = isQuestionAnswered({ question, answers });
														const answerState = getQuestionAnsweredState({ question, answers });
														let { color, text } = {
															[ANSWER_STATE_ANSWERED]: {
																color: PROGRESS_BAR_ANSWERED_COLOR,
																text: <>
																	<b>{t('survey-progress.you-answered')}:</b>&nbsp;
																	<SurveyAnswer
																		question={question}
																		answer={value}
																	/>
																</>,
															},
															[ANSWER_STATE_SKIPPED]: {
																color: PROGRESS_BAR_ANSWERED_COLOR,
																text: (
																	<div
																		style={{
																			color: PROGRESS_BAR_ANSWERED_COLOR,
																			fontStyle: 'italic',
																		}}
																	>
																		{t('survey-progress.you-skipped')}
																	</div>
																),
															},
															[ANSWER_STATE_LATER]: {
																color: PROGRESS_BAR_ANSWER_SKIPPED_COLOR,
																text: (
																	<div
																		style={{
																			color: PROGRESS_BAR_ANSWER_SKIPPED_COLOR,
																			fontStyle: 'italic',
																		}}
																	>
																		{t('survey-progress.answer-mandatory')}
																	</div>
																),
															},
														}[answerState]
														|| {};

														if (!color) {
															if (!isAnswered && questionIndex <= lastFilledQuestionIndex) {
																color = PROGRESS_BAR_ANSWER_SKIPPED_COLOR;
																text = (
																	<div
																		style={{
																			color: PROGRESS_BAR_ANSWER_SKIPPED_COLOR,
																			fontStyle: 'italic',
																		}}
																	>
																		{t('survey-progress.answer-mandatory')}
																	</div>
																);
															} else {
																color = PROGRESS_BAR_UNANSWERED_COLOR;
															}
														}

														return (
															<div
																key={question?.questionId}
																style={{
																	display: 'grid',
																	gridTemplateColumns: '1fr',
																	gridTemplateRows: '4px 10px',
																	verticalAlign: 'top',
																	alignItems: 'flex-start',
																}}
															>
																<Popover
																	placement='bottom'
																	color='#fff'
																	title={`Question ${questionIndex}/${questionCount}`}
																	content={(
																		<div style={{ maxWidth: 400 }}>
																			<div
																				style={{
																					marginTop: -8,
																					paddingLeft: 10,
																					borderLeft: `4px solid ${color}`,
																				}}>
																				<ReactMarkdown>{question.label?.[appLang]?.[0] || question.label?.[appLang] || question.label}</ReactMarkdown>
																			</div>
																			{text || (
																				<>
																					{isAnswered && <b>{t('survey-progress.you-answered')}:</b>}
																					<SurveyAnswer
																						question={question}
																						answer={value}
																					/>
																				</>
																			)}
																		</div>
																	)}
																>
																	<div
																		onClick={() => {
																			if (!isProgressBarClickableInSurvey) return;
																			const path = buildSectionQuestionPath({
																				baseRoutePath, section, question,
																			});
																			pushClick({
																				...eventProps,
																				...buildEventLabel(EVENT.LABEL.SURVEY_QUESTION),
																				...buildEventValue({ path, answerState }),
																			});
																			navigate(path);
																		}}
																		style={{
																			...isProgressBarClickableInSurvey ? { cursor: 'pointer' } : {},
																			userSelect: 'none',
																		}}
																	>
																		<div
																			style={{
																				width: '100%',
																				height: 5,
																				maxHeight: 5,
																				margin: 0,
																				borderRadius: 3,
																				background: color,
																			}}
																		/>
																		<div
																			style={{
																				width: '100%',
																				height: 5,
																				maxHeight: 5,
																			}}
																		>
																			{question?.questionId === currentQuestion?.questionId
																				? <div
																					style={{
																						width: '100%',
																						textAlign: 'center',
																						marginTop: -4,
																					}}
																				>
																					<BiCaretUp
																						style={{
																							width: 12,
																							color: PROGRESS_BAR_ANSWERED_COLOR,
																						}}
																					/>
																				</div>
																				: <>&nbsp;</>}
																		</div>
																	</div>
																</Popover>
															</div>
														);
													})
												}
											</div>
											<div
												style={{
													margin: '-8px 0 0 8px',
												}}
											>
												<GenderScorePillarLogoLabel
													pillar={section.value}
													withLogo={false}
													labelSize={14}
													style={{
														label: {
															fontWeight: 'bold',
															// color: 'black',
															color: section?.value === currentSection?.value
																? PROGRESS_BAR_ANSWERED_COLOR
																: PROGRESS_BAR_UNANSWERED_COLOR,
														},
													}}
												/>
											</div>
										</>
									)}
								</Space>
							))
						}
					</div>

					<Space
						style={{
							justifyContent: 'flex-end',
							alignItems: 'flex-start',
						}}
					>
						<SurveyReviewButton />
					</Space>
				</div>
			</div>
		);
	}, [
		baseRoutePath,
		surveyConf,
		answers,
		currentSection,
		currentQuestion,
		unansweredQuestions,
		isAppSiderVisibleInSurvey,
		isProgressBarVisibleInSurvey,
		isProgressBarClickableInSurvey,
	]);

	return memoizedContent;
};

export default SurveyProgressBar;
