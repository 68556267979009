import { Form } from 'antd';
import { Fragment } from 'react';
import Input from './Input';
import FormCurrentValue from './FormCurrentValue';

const FormItem = (
	{
		label,
		placeholder,
		children,
		defaultContainer = Fragment,
		styles = {},
		input = {},
		containerProps = {},
		currentValue, // Current value of the field in DB. Optional. Used in metas
		...props
	},
) => {
	const Container = defaultContainer;
	const DefaultInput = children || <Input placeholder={placeholder} {...input} style={styles.input} />;
	return (
		<Container
			className="root-container"
			{...containerProps}
			style={{ ...styles.rootContainer }}
		>
			{label && (
				<label
					// className='form-label'
					style={{
						marginBottom: 10,
						fontSize: 14,
						fontWeight: 600,
						...styles.itemContainer,
						...styles.label,
					}}
				>
					{label}
				</label>
			)}
			{
				currentValue && (
					<FormCurrentValue
						text={currentValue.text}
						title={currentValue.title}
						isDefault={currentValue.isDefault}
						tooltip={currentValue.tooltip}
					/>
				)
			}
			<Form.Item
				shouldUpdate
				style={{
					marginBottom: 10,
					fontSize: 16,
					...styles.container,
					...styles.itemContainer,
				}}
				{...props}
			>
				{DefaultInput}
			</Form.Item>
		</Container>
	);
};

export default FormItem;

export const FormItemContainer = ({ children, style = {} }) => {
	return (
		<div
			className="displayTable width100"
			style={{
				fontSize: 16,
				borderCollapse: 'separate',
				...style,
				...style.container,
			}}
		>{children}
		</div>
	);
};

export const FormItemMultipleContainer = ({ children, title = '', style = {} }) => {
	return (
		<div>
			<div style={{ fontWeight: 600, ...style }}>
				{title}
			</div>
			<div>
				<div className="flexRow" style={{ '& .ant-form-item': { marginBottom: 0 } }}>
					{children}
				</div>
			</div>
		</div>
	);
};
