import { useTheme } from '@emotion/react';
import { useSelector } from 'react-redux';
import { getCompanySelected } from '../../../reducers/app';
import {
	addToFilterOptions, documentId, getQueryParams, removeFromFilterOptions,
} from '../../../utils/common';
import { useContext, useEffect, useState } from 'react';
import PageLayout from '../../../components/app/layout/PageLayout';
import CompanyGenderScoreSurveyTable from '../../../components/company/CompanyGenderScoreSurveyTable';
import CompanyPlanGenderScoreSurveySelect from '../../../components/company/companyPlan/genderscore/CompanyPlanGenderScoreSurveySelect';
import { LocalDebug } from '../../../utils/LocalDebug';
import { Space } from 'antd';
import SurveyStatusSelect from '../../../components/genderscore/survey/SurveyStatusSelect';
import StaffCompaniesCompanyListMenu from '../../../menu/staff/companies/StaffCompaniesCompanyListMenu';
import StaffGSSurveyListMenu from '../../../menu/staff/genderscore/StaffGSSurveyListMenu';
import SurveyConfSelect from '../../../components/survey/SurveyConfSelect';
import SURVEYS from '../../../components/genderscore/data/surveys';
import { QueryParamsContext } from '../../../contexts/QueryParamsProvider';

const StaffGenderScoreSurveyListPage = () => {
	const className = 'CompanyGenderScoreSurveysPage';

	const theme = useTheme();
	const companySelected = useSelector(getCompanySelected);

	// const queryParams = getQueryParams();

	const {
		getQueryParam, setQueryParam, applyLocationQueryParams,
	} = useContext(QueryParamsContext);

	applyLocationQueryParams();
	const status = getQueryParam({ name: 'status', asArray: true });
	const surveyConf = SURVEYS?.getSurveyDataOption?.(getQueryParam({ name: 'surveyConf' }));

	return (
		<PageLayout
			MenuClass={StaffGSSurveyListMenu}
			rightActions={(
				<Space direction='vertical'>
					{(new StaffCompaniesCompanyListMenu()).getInlineNavLink()}
				</Space>
			)}
		>
			<Space
				style={{
					marginBottom: 12,
				}}
			>
				<Space direction='vertical' size={4}>
					<b>Status</b>
					<SurveyStatusSelect
						value={status}
						onChange={(value) => {
							setQueryParam({ name: 'status', value });
						}}
					/>
				</Space>

				<Space direction='vertical' size={4}>
					<b>Survey Conf</b>
					<SurveyConfSelect
						value={surveyConf?.value}
						onChange={async (value) => {
							LocalDebug.logInfo({ className, method: 'onChange' }, { value });
							setQueryParam({ name: 'surveyConf', value });
						}}
					/>
				</Space>

				{/* <CompanyPlanGenderScoreSurveySelect
					value={optionsFilterTable?.survey}
					allowClear
					onChange={(value) => handleChangeFilter('survey', value)}
				/> */}
			</Space>

			<CompanyGenderScoreSurveyTable
				// filterOptions={optionsFilterTable}
				filterOptions={{
					survey: surveyConf?.value,
					status,
					companyId: documentId(companySelected),
					withAccess: false,
					withBatch: false,
				}}
				withDynamicSearch={false}
			/>

		</PageLayout>
	);
};

export default StaffGenderScoreSurveyListPage;
