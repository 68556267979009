import {
	PERMS_OVERRIDE_STAFF,
	PERM_DENY_TYPE,
} from '../menu.utils';
import { BUSINESS_PLAN_GENDERSCORE_GROUP_VALUES } from '../../constants/property/business-plan';
import ConfigureUsersMenu from './ConfigureUsersMenu';
import ConfigureCompanyPageMenu from './ConfigureCompanyPageMenu';
import ConfigureEmailTemplatesMenu from './ConfigureEmailTemplatesMenu';
import ConfigureAtsSetupMenu from './ConfigureAtsSetupMenu';
import ConfigureCompanySettingsMenu from './ConfigureCompanySettingsMenu';
import MenuBase from '../MenuBase';
import { SettingOutlined } from '@ant-design/icons';

export const ConfigureIcon = SettingOutlined;

class ConfigureMenu extends MenuBase {
	static props = {
		name: 'configure',
		label: 'Configure',
		icon: <ConfigureIcon />,
		iconLegacy: '⚙️',
		children: (args) => [
			(new ConfigureUsersMenu()).build(args),
			(new ConfigureCompanyPageMenu()).build(args),
			(new ConfigureEmailTemplatesMenu()).build(args),
			(new ConfigureAtsSetupMenu()).build(args),
			(new ConfigureCompanySettingsMenu()).build(args),
		],
		perms: [
			{
				type: PERM_DENY_TYPE,
				plans: BUSINESS_PLAN_GENDERSCORE_GROUP_VALUES,
			},
			PERMS_OVERRIDE_STAFF,
		],
	};

	constructor() {
		super(ConfigureMenu.props);
	}
}

export default ConfigureMenu;
