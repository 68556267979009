import { useTheme } from '@emotion/react';
import { round } from '../../../utils/common';
import { useMemo } from 'react';

const GenderScoreColorRing = (
	{
		value = 0,
		size = 100,
		girthRatio = 0.05,
		withBackgroundRing = true,
		backgroundRingColor = 'rgba(0,0,0,.1)',
		ringColor,
	},
) => {
	const theme = useTheme();

	const valueToAngle = (val) => (
		Math.max(
			0,
			Math.min(
				359.9999, // To avoid issue with drawing a full circle
				round(3.6 * val),
			),
		)
	);

	const polarToCartesian = (centerX, centerY, radius, angleInDegrees) => {
		const angleInRadians = (angleInDegrees - 90) * Math.PI / 180.0;

		return {
			x: centerX + (radius * Math.cos(angleInRadians)),
			y: centerY + (radius * Math.sin(angleInRadians)),
		};
	};

	const describeArc = (x, y, radius, startAngle, endAngle) => {
		const start = polarToCartesian(x, y, radius, endAngle);
		const end = polarToCartesian(x, y, radius, startAngle);

		const largeArcFlag = endAngle - startAngle <= 180 ? '0' : '1';

		const d = [
			'M', start.x, start.y,
			'A', radius, radius, 0, largeArcFlag, 0, end.x, end.y,
		].join(' ');

		return d;
	};

	const memoizedArc = useMemo(() => {
		const maskId = `arcMask${round(value)}-${size}-${new Date()}-${Math.round(10000 * Math.random())}}`;
		return (
			<div
				style={{
					position: 'relative',
					top: 0,
					left: 0,
					width: size,
					height: size,
				}}
			>
				<div
					style={{
						width: size,
						height: size,
					}}
				>
					<svg
						width={0}
						height={0}
						viewBox={`0 0 ${0} ${0}`}
						xmlns='http://www.w3.org/2000/svg'
					>
						<defs>
							<mask id={maskId} >
								<rect width='100%' height='50%' fill='black' />
								<path
									d={describeArc(
										size / 2,
										size / 2,
										(1 - girthRatio) * size / 2,
										0,
										valueToAngle(value),
									)}
									fill='transparent'
									stroke='white'
									strokeLinecap='round'
									strokeWidth={size * girthRatio}
								/>
							</mask>
						</defs>
					</svg>
				</div>
				{withBackgroundRing && (
					<div
						style={{
							position: 'absolute',
							top: 0,
							left: 0,
							width: size,
							height: size,
						}}
					>
						<svg
							width={size}
							height={size}
							viewBox={`0 0 ${size} ${size}`}
							xmlns='http://www.w3.org/2000/svg'
						>
							<path
								d={describeArc(
									size / 2,
									size / 2,
									(1 - girthRatio) * size / 2,
									valueToAngle(value),
									359.99999,
								)}
								fill='transparent'
								stroke={backgroundRingColor}
								strokeLinecap='round'
								strokeWidth={size * girthRatio}
							/>
						</svg>
					</div>
				)}
				{value > 0 && (
					<>
						<div
							style={{
								position: 'absolute',
								top: 0,
								left: 0,
								width: size,
								height: size,
							}}
						>
							<svg
								width={size}
								height={size}
								viewBox={`0 0 ${size} ${size}`}
								xmlns='http://www.w3.org/2000/svg'
							>
								<path
									d={describeArc(
										size / 2,
										size / 2,
										(1 - girthRatio) * size / 2,
										0,
										valueToAngle(value),
									)}
									fill='transparent'
									stroke={'#999'}
									strokeLinecap='round'
									strokeWidth={size * girthRatio}
								/>
							</svg>
						</div>

						<div
							style={{
								position: 'absolute',
								top: 0,
								left: 0,
								width: size,
								height: size,
								WebkitMaskImage: `url("#${maskId}")`,
								maskImage: `url("#${maskId}")`,
								background: (
									ringColor
									|| 'url(https://res.cloudinary.com/dkatmtbbc/image/upload/q_100/v1/genderscore/genderscore-color.png)'
								),
								backgroundRepeat: 'no-repeat',
								backgroundSize: 'cover',
							}}
						/>
					</>
				)}
			</div>
		);
	}, [value, size, girthRatio, ringColor]);

	return memoizedArc;
};

export default GenderScoreColorRing;
