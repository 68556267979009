import {
	AutoComplete,
	Select,
	Input as AntdInput,
	Switch,
} from 'antd';

const Input = ({
	autocomplet, select, inputSwitch, componentType, style, ...antProps
}) => {
	// eslint-disable-next-line no-nested-ternary
	const Comp = inputSwitch ? Switch : select ? Select : autocomplet ? FormAutocomplet : componentType ? AntdInput[componentType] : AntdInput;
	return (
		<Comp
			style={{ height: inputSwitch || 32, fontSize: 14, ...style }}
			size={inputSwitch || 'large'}
			{...antProps}
		/>
	);
};

const FormAutocomplet = ({ filterOption = (inputValue, option) => option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1, ...otherProps }) => {
	return (
		<AutoComplete
			css={{
				'width': '100%',
				'& .ant-select-selection-search': {
					display: 'flex',
					alignItems: 'center',
				},
			}}
			filterOption={filterOption}
			{... otherProps}
		/>
	);
};

export default Input;
