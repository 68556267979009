import {
	useContext, useEffect, useState,
} from 'react';
import { useTheme } from '@emotion/react';
import GenderScoreLayoutGrid from '../layout/GenderScoreLayoutGrid';
import {
	arrayWrap, numToArray, scrollToTop, sortOn,
} from '../../../../utils/common';
import GenderScoreResourceCard from './GenderScoreResourceCard';
import { AppDataContext } from '../../../../contexts/AppDataProvider';
import {
	Select, Space,
} from 'antd';
import { GENDERSCORE_PILLAR_OPTIONS_LIST } from '../../../../constants/genderscore-pillars';
import GenderScorePillarLogoLabel from '../pillars/GenderScorePillarLogoLabel';
import { useLocation } from 'react-router';
import {
	GENDERSCORE_LOG_FROM_ELEMENT_FILTER_PILLAR_LINK,
	GENDERSCORE_LOG_FROM_ELEMENT_FILTER_TYPE_LINK,
	writeGenderScoreLog,
} from '../../../../constants/genderscore-logs';
import useScreenSize from '../../../../hooks/useScreenSize';
import { GenderScoreResourceContext } from '../../../../contexts/GenderScoreResourceProvider';

const GenderScoreResourcesGrid = ({
	company,
	forceValue,
	pillar,
	index,
	...props
}) => {
	const className = 'GenderScoreResourcesGrid';

	const theme = useTheme();
	const location = useLocation();

	const { appSiderWidth } = useContext(AppDataContext);
	const { getResources, loading } = useContext(GenderScoreResourceContext);

	const screenSize = useScreenSize();

	const [selectedPillar, setSelectedPillar] = useState();
	const [selectedType, setSelectedType] = useState();
	const [screenResizedColumns, setScreenResizedColumns] = useState();
	const [resources, setResources] = useState([]);

	useEffect(() => {
		const launch = async () => setResources(await getResources());
		if (getResources) launch();
	}, [getResources]);

	useEffect(() => {
		scrollToTop();
	}, [location.pathname]);

	useEffect(() => {
		const cardWidth = 364;
		const countCols = Math.floor(
			Math.max(
				1,
				(
					// screen width
					(screenSize.width || 0)
					// menu width
					- (appSiderWidth || 0)
					// padding around grid
					- 24 * 2
					// adding gap
					+ 20
				)
				// card + gap
				/ (cardWidth + 20),
			),
		);
		setScreenResizedColumns(numToArray(countCols).map(() => `${cardWidth}px`).join(' '));
	}, [screenSize, appSiderWidth]);

	const onSelectPillar = (filteredPillar) => {
		setSelectedPillar(filteredPillar);
		writeGenderScoreLog({
			type: GENDERSCORE_LOG_FROM_ELEMENT_FILTER_PILLAR_LINK,
			context: filteredPillar,
			company,
		});
	};

	const onFilterResource = (resourceType) => {
		setSelectedType(resourceType);
		writeGenderScoreLog({
			type: GENDERSCORE_LOG_FROM_ELEMENT_FILTER_TYPE_LINK,
			context: resourceType,
			company,
		});
	};

	if (loading) return 'Loading...';

	return (
		<div
			style={{
			}}
		>
			<Space style={{ marginBottom: 12 }}>

				{!pillar && (
					<Select
						options={(
							GENDERSCORE_PILLAR_OPTIONS_LIST
								.map((o) => ({
									...o,
									label: (
										<GenderScorePillarLogoLabel
											pillar={o.value}
											iconSize={18}
											labelSize={14}
											style={{
												label: { color: '#333' },
											}}
										/>
									),
								}))
						)}
						onChange={onSelectPillar}
						placeholder='Select a pillar'
						allowClear
						style={{ minWidth: 200, borderRadius: 6 }}
					/>
				)}

				<Select
					options={
						[...new Set(
							resources
								?.map((resource) => resource?.type)
								?.filter((item) => item),
						)]
							?.map((value) => ({ value, label: value, slug: value.toLowerCase() }))
							?.sort(sortOn({ key: 'slug' }, false))
					}
					onChange={onFilterResource}
					placeholder='Select a resource type'
					allowClear
					style={{ minWidth: 200, borderRadius: 6 }}
				/>

			</Space>

			<GenderScoreLayoutGrid
				columns={screenResizedColumns}
			>
				{resources
					?.filter((item) => !pillar
						|| item.pillars?.includes?.(pillar))
					?.filter((item) => !selectedPillar
						|| item.pillars?.includes?.(selectedPillar))
					?.filter((item) => !selectedType
						|| arrayWrap(item.type)?.includes?.(selectedType))
					?.map((resource, i) => (
						<div key={i}>
							<GenderScoreResourceCard
								company={company}
								resource={resource}
								withPillarTags={true}
								pillar={pillar}
							/>
						</div>
					))}
			</GenderScoreLayoutGrid>
		</div>
	);
};

export default GenderScoreResourcesGrid;
