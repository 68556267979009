import { useEffect, useState } from 'react';
import { useAuthContext } from '../../../hooks/useAuthContext';
import * as dayjs from 'dayjs';
import CompanyGenderScoreBadge from '../../../components/company/genderscore/CompanyGenderScoreBadge';
import { useTheme } from '@emotion/react';
import {
	Button,
	DatePicker,
	Divider,
	Form,
	Input, Radio, Spin, Switch,
} from 'antd';
import { useSelector } from 'react-redux';
import { getCompanySelected } from '../../../reducers/app';
import FormItem from '../../../components/company/form/FormItem';
import Box from '../../../components/app/box/Box';
import { saveAsPNG } from '../../../components/company/genderscore/dashboard/download/GenderScoreDashboardDownloadButton';
import domtoimage from 'dom-to-image';
import { GENDERSCORE_LABEL } from '../../../constants/constant';
import { DownloadOutlined, LoadingOutlined } from '@ant-design/icons';
import CompanyModel from '../../../models/CompanyModel';

const DownloadButton = ({
	id,
	filename,
	bgcolor,
	fileWidth,
	...props
}) => {
	const className = 'DownloadButton';
	const theme = useTheme();
	const [isLoading, setIsLoading] = useState(false);

	const handleClick = async () => {
		setIsLoading(true);
		const node = document.getElementById(id || 'gs-badge-download');
		domtoimage.toPng(node, { bgcolor })
			.then((dataUrl) => saveAsPNG({ uri: dataUrl, filename, setIsLoading }))
			.catch((error) => {
				console.error('Oops, something went wrong!', error);
				setIsLoading(false);
			});
	};

	return (
		<Button
			type='primary'
			size='large'
			disabled={isLoading}
			onClick={handleClick}
			style={{
				display: 'block',
				width: '100%',
			}}
		>
			<b>Download badge</b>&nbsp;(PNG, {fileWidth}px wide)
			&nbsp;{isLoading
				? <Spin indicator={<LoadingOutlined spin />} />
				: <DownloadOutlined />
			}
		</Button>
	);
};

const StaffGenderScoreBadgeMaker = ({
	company,
	withEditableScore = true,
	withEditablePreviousScore = true,
	withEditableExpirationDate = true,
	withEditableCompanyLogo = true,
	withCustomFilename = true,
	withModalContainer = false,
}) => {
	const className = 'StaffGenderScoreBadgeMaker';

	const theme = useTheme();
	const { isStaff } = useAuthContext();
	const companySelected = useSelector(getCompanySelected);

	const [size, setSize] = useState(600);
	const [version, setVersion] = useState('white');
	const [score, setScore] = useState(100);
	const [expiredAt, setExpiredAt] = useState(new Date());
	const [withExpiration, setWithExpiration] = useState(true);
	const [withCompanyLogo, setWithCompanyLogo] = useState(false);
	const [withPreviousScore, setWithPreviousScore] = useState(false);
	const [previousScore, setPreviousScore] = useState(null);
	const [withShadow, setWithShadow] = useState(true);

	useEffect(() => {
		if (companySelected) {
			const companyModel = new CompanyModel(companySelected);
			const gsPrevScore = companyModel.getPreviousGenderScoreSurveyGenderScore()?.globalScore;
			setScore(companySelected?.genderScore?.globalScore);
			if (gsPrevScore) {
				setWithPreviousScore(true);
				setPreviousScore(gsPrevScore);
			}
			const gsExpiredAt = companySelected?.companyPlan?.genderScore?.expiredAt;
			if (gsExpiredAt) {
				setWithExpiration(true);
				setExpiredAt(gsExpiredAt);
			} else {
				setWithExpiration(false);
			}
			setExpiredAt(companySelected?.companyPlan?.genderScore?.expiredAt);
		}
	}, [companySelected]);

	return (
		<div
			style={{
				display: 'grid',
				gridTemplateColumns: '400px 1fr',
				verticalAlign: 'top',
				alignItems: 'top',
				columnGap: 20,
				width: '100%',
				// lineHeight: 1.4,
			}}
		>
			<Form
				labelCol={{ span: 12 }}
				wrapperCol={{ span: 12 }}
				style={{
					display: 'grid',
					gridTemplateColumns: '1fr',
					columnGap: 20,
					rowGap: 20,
					width: '100%',
					alignSelf: 'flex-start',
				}}
			>
				<Box
					style={{
						width: 'calc(100% - 24px)',
						padding: '8px 12px',
						borderRadius: 6,
						background: 'white',
					}}
				>
					<Divider style={{ marginTop: 4 }}>Aspect</Divider>

					<FormItem
						label={'Badge width'}
					>
						<Input
							value={size}
							type='number'
							min={150}
							max={1000}
							step={10}
							width={120}
							onChange={(e) => setSize(e?.target?.value)}
							style={{
								maxWidth: 200,
							}}
						/>
					</FormItem>

					<FormItem
						label={'Version'}
					>
						<Radio.Group
							defaultValue={version}
							onChange={(e) => setVersion(e?.target?.value)}
						>
							<Radio.Button value={'white'}>White</Radio.Button>
							<Radio.Button value={'blue'}>Blue</Radio.Button>
						</Radio.Group>
					</FormItem>

					<FormItem
						label={'Show shadow?'}
						helper={'Will add padding to the downloaded PNG'}
					>
						<Switch
							checked={withShadow}
							onChange={(val) => setWithShadow(val)}
						/>
					</FormItem>

				</Box>

				<Box
					style={{
						width: 'calc(100% - 24px)',
						padding: '8px 12px',
						borderRadius: 6,
						background: 'white',
					}}
				>
					<Divider style={{ marginTop: 8 }}>Content</Divider>

					{withEditableScore && (
						<FormItem
							label={'Score'}
						>
							<Input
								value={score}
								type='number'
								min={0}
								max={100}
								step={1}
								onChange={(e) => setScore(e?.target?.value)}
								style={{
									maxWidth: 200,
								}}
							/>
						</FormItem>
					)}

					<FormItem
						label={'Show company logo?'}
					>
						<Switch
							checked={withCompanyLogo}
							onChange={(val) => setWithCompanyLogo(val)}
						/>
					</FormItem>

					<FormItem
						label={'Show expiration?'}
					>
						<Switch
							checked={withExpiration}
							onChange={(val) => setWithExpiration(val)}
						/>
					</FormItem>

					{withEditableExpirationDate && (
						<FormItem
							label={'Expiration date'}
						>
							<DatePicker
								disabled={!withExpiration}
								showTime={false}
								format={'DD/MM/YYYY'}
								defaultValue={dayjs(expiredAt)}
								onChange={(val) => {
									setExpiredAt(new Date(val));
								}}
							/>
						</FormItem>
					)}

					<FormItem
						label={'Show previous score?'}
					>
						<Switch
							checked={withPreviousScore}
							onChange={(val) => setWithPreviousScore(val)}
						/>
					</FormItem>

					{withEditablePreviousScore && (
						<FormItem
							label={'Previous score'}
						>
							<Input
								value={previousScore}
								type='number'
								min={0}
								max={100}
								step={1}
								onChange={(e) => setPreviousScore(e?.target?.value)}
								style={{
									maxWidth: 200,
								}}
							/>
						</FormItem>
					)}
				</Box>

				<DownloadButton
					id='gs-badge-custom'
					filename={withCustomFilename
						? [
							[
								'50inTech GenderScore',
								score,
								version,
								`${size}px`,
								...withShadow ? ['shadow'] : [],
								...withExpiration ? ['expiration'] : [],
								...withPreviousScore ? ['withPrevScore'] : [],
							].join(' '),
							'png',
						].join('.')
						: `50inTech-GenderScore-${companySelected?.name || 'Badge'}.png`
					}
					fileWidth={parseInt(size, 10) + (withShadow ? 2 * parseInt(size, 10) / 10 : 0)}
					style={{ width: '100%' }}
				/>

			</Form>

			<div
				style={{
					display: 'block',
					width: '100%',
					zoom: 0.5,
					display: 'flex',
					flexDirection: 'column',
					justifyContent: 'center',
					alignItems: 'center',
				}}
			>
				<div
					style={{
						border: '1px dotted rgba(0,0,255,.4)',
						padding: 2,
					}}
				>
					<div
						id='gs-badge-custom'
						style={{
							padding: withShadow ? parseInt(size, 10) / 10 : 0,
							width: parseInt(size, 10) + (withShadow ? 2 * parseInt(size, 10) / 10 : 0),
							...withModalContainer && { maxWidth: parseInt(size, 10) },
						}}
					>
						<div
							style={{
								width: parseInt(size, 10),
								maxWidth: parseInt(size, 10),
							// border: '1px solid red',
							}}
						>
							<CompanyGenderScoreBadge
								company={{
									...companySelected,
									companyPlan: {
										genderScore: {
											expiredAt,
										},
									},
									genderScore: {
										...companySelected?.genderScore || {},
										globalScore: score,
									},
									genderScoreSurveys: [
										{
											status: 'CURRENT',
											genderScore: {
												globalScore: score,
											},
										},
										...withPreviousScore
											? [{
												status: 'PREVIOUS',
												genderScore: {
													globalScore: previousScore,
												},
											}]
											: [],
									],
								}}
								size={parseInt(size, 10)}
								version={version}
								// value={value}
								withShadow={withShadow}
								withExpiration={withExpiration}
								withPreviousScore={withPreviousScore}
								withCompanyLogo={withCompanyLogo}
								withPopover={false}
								withModalContainer={withModalContainer}
							/>
						</div>
					</div>
				</div>
			</div>

		</div>
	);
};

export default StaffGenderScoreBadgeMaker;
