import { SearchOutlined } from '@ant-design/icons';
import { Button, Input, Space } from 'antd';
import { useState } from 'react';
import AutoComplete from '../form/AutoComplete';

const FilterDropDownColumn = ({
	column, value = '', placeholder = '', defaultOptions = [], onChange, onFilter, onSelect, onSearchDataColumn, onReset,
}) => {
	const [options, setOptions] = useState(defaultOptions);
	const { customOptions, dataIndex, isAutoComplete } = column;

	// ---------------------------------------------- //
	// -------- search data for autoComplete -------- //
	// ---------------------------------------------- //
	const handleSearchData = async (search) => {
		setOptions([]);
		const searchOptions = {
			searchField: customOptions ? customOptions.field : dataIndex,
			search,
			groupByField: customOptions ? customOptions.field : dataIndex,
		};

		if (onSearchDataColumn) {
			const data = await onSearchDataColumn(searchOptions);
			setOptions(data);
		}
	};

	// ----------------------------------------------------- //
	// --------- handle change autocomplete filter --------- //
	// ----------------------------------------------------- //
	const handleChange = (value) => {
		// set select value filter
		if (onChange) onChange(value);
		// filter table with current value
		if (onSelect) onSelect(value);
	};

	return (
		<div style={{ padding: 8 }} className="flexColumn alignStart">
			{ isAutoComplete
				? <AutoComplete
					options={options}
					onSearch={handleSearchData}
					onChange={handleChange}
					onStartSearch={() => setOptions([])}
					style={{ maxWidth: '100%', minWidth: 200 }}
					debounceTimeout={500}
				/>
				: <Input
					placeholder={placeholder}
					value={value}
					onChange={(e) => onChange(e.target.value || '')}
					onPressEnter={onFilter}
					style={{ marginBottom: 8, display: 'block' }}
				/>
			}
			<Space direction="horizontal" size={100}>
				{ !isAutoComplete && <Button
					type="primary"
					onClick={onFilter}
					icon={<SearchOutlined />}
					size="small"
					style={{ width: 90 }}
				>
                        Search
				</Button>
				}
				{/* <a onClick={onReset}>Reset</a> */}
				<Button type="link" onClick={onReset}>Reset</Button>
			</Space>
		</div>
	);
};

export default FilterDropDownColumn;
