import { Space } from 'antd';
import { FaBan, FaCheck, FaTimes } from 'react-icons/fa';
import BlockStaff from '../../app/blocker/BlockStaff';
import CompanySettingsCard from './CompanySettingsCard';
import { useTheme } from '@emotion/react';
import CompanySettingsNotificationsForm from './CompanySettingsNotificationsForm';

const CompanySettingsNotificationsCard = (
	{
		company,
		...props
	},
) => {
	const className = 'CompanySettingsNotificationsCard';
	const theme = useTheme();

	return (
		<CompanySettingsCard
			title={<>📥 Notifications</>}
			details={<>
				Defines how notifications are sent to recruiters when candidates apply on a job offer
			</>}
			company={company}
			CompanySettingsForm={CompanySettingsNotificationsForm}
		>
			<Space direction='vertical'>
				<span>When a talent applies for a job offer for which <b>nobody subscribed</b> to receive notifications...</span>
				<Space direction='horizontal'>
					{company?.shouldNotifyAllOnApplysIfNoRecipients !== false
						? <>
							{/* <FaCheck style={{ marginBottom: -2, color: theme.color.darkturquoise }}/> */}
							<span>💯 <b>all "Recruiter" and "Admin" users</b> should be notified</span>
						</>
						: <>
							{/* <FaBan style={{ marginBottom: -2, color: theme.color.red }}/> */}
							<span>🙋‍♀️ only <b>a specific user</b> should be notified</span>
						</>
					}
				</Space>
			</Space>
		</CompanySettingsCard>
	);
};

export default CompanySettingsNotificationsCard;
