import { PERMS_GENDERCORE_ENABLED } from './genderscore-utils';
import GenderScoreDashboardPage from '../../containers/genderscore/GenderScoreDashboardPage';
import MenuBase from '../MenuBase';
import { DashboardOutlined, StarOutlined } from '@ant-design/icons';

export const GenderscoreDashboardIcon = DashboardOutlined;

class GenderscoreDashboardMenu extends MenuBase {
	static props = {
		name: 'genderscore-dashboard',
		path: '/genderscore/dashboard',
		aliases: ['^/genderscore/dashboard'],
		label: 'Dashboard',
		icon: <GenderscoreDashboardIcon />,
		iconLegacy: '🌟',
		Component: GenderScoreDashboardPage,
		perms: PERMS_GENDERCORE_ENABLED,
	};

	constructor() {
		super(GenderscoreDashboardMenu.props);
	}
}

export default GenderscoreDashboardMenu;
