import { ENGLISH_LANG } from '../../../../constants/constant';
import CompanyTextEditor from '../shared/CompanyTextEditor';

const CompanyNameEditor = (
	{
		company,
		lang = ENGLISH_LANG,
		setEditorOpen,
	},
) => {
	const className = 'CompanyNameEditor';

	return (
		<CompanyTextEditor
			company={company}
			lang={lang}
			title={<b>Company name</b>}
			details={<>As displayed to the candidates on 50inTech</>}
			field={'name'}
			isTranslatable={false}
			setEditorOpen={setEditorOpen}
		/>
	);
};

export default CompanyNameEditor;
