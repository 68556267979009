import { Button, Popconfirm } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import { useTheme } from '@emotion/react';
import { FaEdit } from 'react-icons/fa';
import Tag from './Tag';
import Table from '../../app/table/Table';
import TableColumns from '../../app/table/TableColumns';

const className = 'TagTable';

const TagTable = ({
	dataSource,
	onEditAccess,
	onChangeActive,
	onDelete,
	isEditor,
}) => {
	const theme = useTheme();

	const categories = [...new Set(dataSource.map((item) => item.category))];

	const stringSorter = (field) => (a, b) => {
		return (a[field] || '').toLowerCase() >= (b[field] || '').toLowerCase() ? 1 : -1;
	};

	const columns = [
		{
			title: 'Category',
			dataIndex: 'category',
			sorter: stringSorter('category'),
			filters: categories.map((item) => ({ text: item, value: item })),
			filterSearch: true,
			onFilter: (value, row) => row.category === value,
			render: (value, row) => <Tag slug={row.category}/>,
			...TableColumns.columnMinWidth(160),
		},
		{
			title: 'Value',
			dataIndex: 'value',
			sorter: stringSorter('value'),
			filterSearch: true,
			onFilter: (value, row) => row.value.includes(value),
			render: (value, row) => <Tag slug={value}/>,
			...TableColumns.columnMinWidth(160),
		},
		{
			title: 'Label',
			dataIndex: 'label',
			sorter: stringSorter('label'),
			...TableColumns.columnMinWidth(160),
		},
		TableColumns.booleanColumn(theme, {
			title: 'Active',
			dataIndex: 'isActive',
			sorter: (a, b) => (a.isActive > b.isActive ? 1 : -1),
		}),
		TableColumns.docsCountColumn(theme, { title: 'Users', dataIndex: 'users', removeInColumnFilter: true }),
		TableColumns.docsCountColumn(theme, { title: 'Jobs', dataIndex: 'jobs', removeInColumnFilter: true }),
		{
			title: 'Actions',
			dataIndex: 'actions',
			// render: (value, row) => isEditor ? 'true' : 'false',
			render: (value, row) => {
				return (
					isEditor ? <div className="flexRow alignCenter">
						<Button type="link" icon={<FaEdit/>} onClick={() => onEditAccess(row)}/>
						<Popconfirm title="Are you sure to delete this row？" okText="Yes" cancelText="No"
							onConfirm={() => onDelete(row)}>
							<Button type="link" style={{ color: theme.color.orange }}><DeleteOutlined/></Button>
						</Popconfirm>

					</div> : ''
				);
			},
			...TableColumns.columnMinWidth(160),
		},
	];

	return (
		<Table
			path="/tag"
			columns={columns}
			filterOptions={{}}
			withDynamicSearch
		/>
	);
};

export default TagTable;
