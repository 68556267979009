import { useNavigate } from 'react-router';
import Link from '../../../app/Link';
import { ArrowRightIcon } from '../../../../utils/common';
import { writeGenderScoreFilledLog } from '../../../../constants/genderscore-logs';
import { COMPANY_RECRUITER_LOG } from '../../../../constants/company-recruiter-log';

const GenderScoreResultsNavLink = ({
	context,
	path,
	children,
	...props
}) => {
	const navigate = useNavigate();

	return (
		<Link
			onClick={() => {
				navigate(path);
				writeGenderScoreFilledLog({
					type: {
						element: path,
						action: COMPANY_RECRUITER_LOG.INTERNAL_LINK,
					},
					context,
				});
			}}
			style={{
				...props?.style,
			}}
		>
			{children || <>Navigate <ArrowRightIcon /></>}
		</Link>
	);
};

export default GenderScoreResultsNavLink;
