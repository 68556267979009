import { useTheme } from '@emotion/react';
import { Space } from 'antd';
import { Bar } from '@visx/shape';
import { scaleLinear } from '@visx/scale';
import { Group } from '@visx/group';
import { LinearGradient } from '@visx/gradient';
import { normalizePct } from '../../../../../utils/common';
import { LocalDebug } from '../../../../../utils/LocalDebug';

/**
 * @param {number} pct - Percent to render
 * @param {*} [graphColors] - Optional. Format: [from, to, rank].
 * If not set, use theme.chart.ranking
 * @param {number} [width] - Optional. Width of the chart. Default: 300
 * @param {number} [height] - Optional. Height of the chart. Default: 40
 * @param {number} [fontSize] - Optional. Font size of the label. Default: 16
 * @param {number} [barRadius] - Optional. Radius of the bar. Default: 4
 * @param {number} [barHeight] - Optional. Height of the bar. Default: 10
 * @param {*} [margin] - Optional. Internal margins of the chart.
 * Format: {top, bottom, left, right}.
 * Default: {
		top: 20, bottom: 0, left: 0, right: 20,
	}
 * @returns PctBarGraph component
 */
const PctBarGraph = ({
	pct,
	graphColors,
	width = 300,
	height = 40,
	fontSize = 16,
	barRadius = 4,
	barHeight = 10,
	margin = {
		top: 20, bottom: 0, left: 0, right: 20,
	},
	...props
}) => {
	const className = 'PctBarGraph';

	const theme = useTheme();
	const colors = graphColors || theme.chart.pctBar;
	const gradientId = `pct_fill${Math.random() * 10}`;

	// Create bounds
	const xMax = width - margin.left - margin.right;

	// Scale the graph by our data
	const xScale = scaleLinear({
		range: [xMax, 0],
		round: true,
		domain: [0, 100], // is a percent --> max = 100
	});
	// Ensure that the main percentage is an integer between 0 an 100
	const normalizedValue = normalizePct({ value: pct, roundDown: false });
	const levelBarWidth = xMax - xScale(normalizedValue);
	let labelOffset = {
		value: '-1em',
		percentage: '.4em',
	};
	if (normalizedValue === 100) {
		labelOffset = {
			value: '-1.3em',
			percentage: '.6em',
		};
	}
	if (normalizedValue < 10) {
		labelOffset = {
			value: '-0.5em',
			percentage: '.4em',
		};
	}
	if (normalizedValue < 3) {
		labelOffset = {
			value: '0',
			percentage: '1em',
		};
	}
	const labelFontSize = {
		value: fontSize,
		percentage: Math.floor(fontSize * 0.8),
	};

	LocalDebug.logInfo({ className }, {
		pct,
		graphColors,
		width,
		height,
		fontSize,
		barRadius,
		barHeight,
		margin,
		levelBarWidth,
		xMax,
		normalizedValue,
	});

	return <Space style={{ ...props?.style }}>
		<svg width={width} height={height}>
			<defs>
				<LinearGradient vertical={false} from={colors?.from} to={colors?.to} id={gradientId} />
			</defs>
			<Group left={margin.left} top={margin.top}>
				<text
					x={levelBarWidth}
					y={0}
					fill={theme.color.fitBlack}
					fontSize={labelFontSize.value}
					fontWeight={700}
					dx={labelOffset.value}
					dy={0}
				>
					{`${normalizedValue}`}
				</text>
				<text
					x={levelBarWidth}
					y={0}
					fill={theme.color.fitBlack}
					fontSize={labelFontSize.percentage}
					fontWeight={700}
					dx={labelOffset.percentage}
				>
					{'%'}
				</text>
				<Bar
					x={0}
					y={5}
					height={barHeight}
					width={xMax}
					fill={colors.bg}
					rx={barRadius}
				/>
				<Bar
					x={0}
					y={5}
					height={barHeight}
					width={levelBarWidth}
					fill={`url(#${gradientId})`}
					rx={barRadius}
				/>
			</Group>
		</svg>
	</Space>;
};

export default PctBarGraph;
