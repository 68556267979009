import { auth } from '../config/firebase';

const login = (email, password) => {
	return auth.signInWithEmailAndPassword(email, password);
};

const logout = () => {
	return auth.signOut();
};

const currentUser = () => {
	return auth.currentUser;
};

const sendPasswordResetEmail = (email) => {
	return auth.sendPasswordResetEmail(email);
};

const updatePassword = (pwd) => {
	return auth.updatePassword(pwd);
};

const updateEmail = (uid, email) => {
	return auth.updateUser(uid, { email });
};

const getAuth = () => {
	return auth;
};

const getToken = () => {
	return auth?.currentUser ? auth.currentUser.getIdToken() : null;
};

const AuthFirebaseService = {
	getAuth,
	login,
	currentUser,
	sendPasswordResetEmail,
	updatePassword,
	updateEmail,
	getToken,
	logout,
};

export default AuthFirebaseService;
