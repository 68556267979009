import { useTheme } from '@emotion/react';
import { getPillarIcon, getPillarLabel } from '../../../../../constants/genderscore-pillars';

const GenderScorePillarTitleBlock = ({
	pillar,
	index,
	fontSize = 14,
	gap = 5,
	withLeftAlign = true,
	...props
}) => {
	const theme = useTheme();
	return <div style={{ ...props?.style }}>
		<div
			style={{
				fontSize: fontSize * 0.85,
				color: theme.color.fitBlack,
				opacity: 0.65,
				display: 'inline-flex',
				alignItems: 'center',
			}}
		>
			{getPillarIcon(pillar, {
				width: fontSize,
				height: fontSize,
				style: {
					marginRight: gap,
					marginTop: 3,
				},
			})
			}PILLAR #{index + 1}
		</div>
		<div
			style={{
				fontSize,
				fontWeight: 500,
				opacity: 0.88,
				color: theme.color.fitBlack,
				marginLeft: withLeftAlign ? 0 : fontSize + gap,
			}}
		>
			{getPillarLabel(pillar)}
		</div>
	</div>;
};

export default GenderScorePillarTitleBlock;
