import {
	Button, Row, Col, Input,
} from 'antd';
import { PlusOutlined, MinusOutlined } from '@ant-design/icons';
import { useTheme } from '@emotion/react';
import { useEffect, useRef, useState } from 'react';
import { isValueDefined, round } from '../../../utils/common';

const SurveyQuestionInputPct = ({
	question,
	name,
	value,
	onChange,
	error,
}) => {
	const className = 'SurveyQuestionInputPct';

	const theme = useTheme();

	const inputRef = useRef();

	const {
		min,
		max,
		step,
		suffix,
	} = question;

	const rounding = 1;

	const handleChange = (val) => {
		if (!isValueDefined(val)) {
			onChange(null);
		} else {
			onChange(Math.min(max, Math.max(min, val)) || 0);
		}
	};

	const increase = () => {
		const newValue = round((Number(value) || 0) + (step || 1), rounding);
		handleChange(newValue);
	};

	const decrease = () => {
		const newValue = round((Number(value) || 0) - (step || 1), rounding);
		handleChange(newValue);
	};

	const arrowButtonStyle = {
		width: 60,
		height: 60,
		borderRadius: 60,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
	};

	useEffect(() => {
		inputRef?.current?.focus?.();
	}, [question]);

	return (
		<div>
			<Row gutter={[40, 40]}>
				<Col>
					<Button
						onClick={decrease}
						icon={<MinusOutlined/>}
						style={arrowButtonStyle}
					/>
				</Col>
				<Col>
					<div
						style={{
							fontSize: 32,
							color: theme.color.fitBlueElectric,
							border: '1px solid #ddd',
							borderRadius: 10,
							paddingRight: 10,
						}}
					>
						<Input
							autoFocus
							ref={inputRef}
							name={name}
							id={name}
							value={isValueDefined(value)
								? round(value && Number(value), rounding) || 0
								: null}
							min={min}
							max={max}
							step={1}
							placeholder={50}
							onChange={(e) => {
								if (e.target.value === '') handleChange(null);
								const v = parseInt(e.target.value, 10);
								if (!isNaN(v)) handleChange(v);
							}}
							style={{
								background: 'transparent',
								border: 'none',
								textAlign: 'right',
								fontSize: 42,
								width: 120,
								height: 60,
								color: theme.color.fitBlueElectric,
								borderRadius: 0,
								outline: 'none',
								boxShadow: 'none',
							}}
						/>
						{suffix && (
							<span
								style={{
									userSelect: 'none',
									color: '#222',
								}}
							>
								{suffix}
							</span>
						)}
					</div>
				</Col>
				<Col>
					<Button
						onClick={increase}
						icon={<PlusOutlined/>}
						style={arrowButtonStyle}
					/>
				</Col>
			</Row>
			{error && <p className="error">{error}</p>}
		</div>
	);
};

export default SurveyQuestionInputPct;
