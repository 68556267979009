import CAREER_ROLE from '../../property/career-role';
import { innerObjPropExtractor } from '../../property/property-extractor';
import { innerObjPropMapper } from '../../property/property-mapper';

const USER_PROPERTY_CAREER_ROLE_KEY = 'career-role';

const USER_PROPERTY_CAREER_ROLE_MULTI = true;
const USER_PROPERTY_CAREER_ROLE_FREE = false;

const USER_PROPERTY_CAREER_ROLE_FIELD = 'career.role';
const USER_PROPERTY_CAREER_ROLE_MAPPER = innerObjPropMapper(USER_PROPERTY_CAREER_ROLE_FIELD);
const USER_PROPERTY_CAREER_ROLE_EXTRACTOR = innerObjPropExtractor(USER_PROPERTY_CAREER_ROLE_FIELD);

export default {
	...CAREER_ROLE,
	key: USER_PROPERTY_CAREER_ROLE_KEY,
	USER_PROPERTY_CAREER_ROLE_KEY,
	free: USER_PROPERTY_CAREER_ROLE_FREE,
	USER_PROPERTY_CAREER_ROLE_FREE,
	multi: USER_PROPERTY_CAREER_ROLE_MULTI,
	USER_PROPERTY_CAREER_ROLE_MULTI,
	mapper: USER_PROPERTY_CAREER_ROLE_MAPPER,
	USER_PROPERTY_CAREER_ROLE_MAPPER,
	extractor: USER_PROPERTY_CAREER_ROLE_EXTRACTOR,
	USER_PROPERTY_CAREER_ROLE_EXTRACTOR,
	field: USER_PROPERTY_CAREER_ROLE_FIELD,
	USER_PROPERTY_CAREER_ROLE_FIELD,
};
