/* eslint-disable react-hooks/exhaustive-deps */
import { useTheme } from '@emotion/react';
import { Collapse, Space, Tabs } from 'antd';
import PageLayout from '../../components/app/layout/PageLayout';
import {
	ATS_ICON, ATS_DETAILS, ATS_TITLE, TAG_STAFF, TAG_ADMIN_ICON, TAG_ADMIN, TAG_STAFF_ICON,
} from '../../constants/constant';
import SectionTitle from '../../components/app/SectionTitle';
import { useAuthContext } from '../../hooks/useAuthContext';
import AtsMemberTable from '../../components/ats/AtsMemberTable';
import AtsStageTable from '../../components/ats/AtsStageTable';
import AtsArchiveReasonTable from '../../components/ats/AtsArchiveReasonTable';
import AtsOpportunitiesTable from '../../components/ats/AtsOpportunitiesTable';
import AtsPermissionTable from '../../components/ats/AtsPermissionTable';
import { useDispatch, useSelector } from 'react-redux';
import {
	getCompanies, getCompanyAccesses, getCompanyAccessSelected, getCompanySelected,
} from '../../reducers/app';
import FormItem from '../../components/app/form/FormItem';
import FormCompanyAccessSelect from '../../components/app/form/FormCompanyAccessSelect';
import { localDebug } from '../../utils/LocalDebug';
import { useContext, useEffect, useState } from 'react';
import CompanyAccessStub from '../../components/company/access/CompanyAccessStub';
import { setCompanyAccessSelected, setCompanySelected } from '../../actions/company';
import CompanyAccessModel from '../../models/CompanyAccessModel';
import { CompanyContext } from '../../contexts/CompanyProvider';
import { documentId } from '../../utils/common';
import ConfigureAtsSetupMenu from '../../menu/configure/ConfigureAtsSetupMenu';

const ConfigureAtsSetupPage = () => {
	const className = 'ConfigureAtsSetupPage';

	const theme = useTheme();
	const dispatch = useDispatch();

	const companies = useSelector(getCompanies);
	const company = useSelector(getCompanySelected);
	const companyAccesses = useSelector(getCompanyAccesses);
	const companyAccess = useSelector(getCompanyAccessSelected);

	const [companySelectOptions, setCompanySelectOptions] = useState([]);
	const [companyAccessSelectOptions, setCompanyAccessSelectOptions] = useState([]);

	const { isAdmin, isStaffView, isAclEditor } = useAuthContext();
	const { setCompanySelectedAsQueryParam } = useContext(CompanyContext);

	const handleChangeCompanyAccess = (companyAccessId) => {
		localDebug('handleChangeCompanyAccess', companyAccessId);
		dispatch(setCompanyAccessSelected(companyAccessId));
	};

	useEffect(() => {
		dispatch(setCompanyAccessSelected(null));
		setCompanyAccessSelectOptions([]);
	}, [company]);

	useEffect(() => {
		const companiesWithApiAccess = companies.filter((c) => c.access?.some((a) => a.accessType === 'api'));
		localDebug('Ats.useEffect[companies]', {
			companies: companies.length, companiesWithApiAccess: companiesWithApiAccess.length,
		});
		if (companiesWithApiAccess?.length === 1) {
			setCompanySelectedAsQueryParam(companiesWithApiAccess?.[0]?.path);
			setCompanySelectOptions([]);
			// dispatch(setCompanyAccessSelected(documentId(companies?.[0]?.access?.[0])));
		} else {
			setCompanySelectOptions(companiesWithApiAccess
				.map((c) => ({ item: c, value: documentId(c), label: c.name })));
		}
	}, [companies]);

	useEffect(() => {
		const companyApiAccesses = companyAccesses
			.filter((c) => c?.accessType === 'api');
		if (companyApiAccesses?.length === 1) {
			dispatch(setCompanyAccessSelected(documentId(companyApiAccesses?.[0])));
			setCompanyAccessSelectOptions([]);
		} else {
			setCompanyAccessSelectOptions(companyApiAccesses
				.map((c) => ({ item: c, value: documentId(c), label: `${c.company.name} (${c.ats})` })));
		}
	}, [companyAccesses]);

	return (
		<PageLayout
			MenuClass={ConfigureAtsSetupMenu}
			subtitle={ATS_DETAILS}
		>
			{companyAccessSelectOptions?.length > 0
				&& <FormItem
					label="Company accesses"
					defaultContainer="div"
					styles={{
						rootContainer: {
							padding: '0 10px 20px',
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'flex-start',
						},
						label: { marginBottom: 0, paddingRight: 20 },
						itemContainer: { marginBottom: 0 },
					}}
				>
					<FormCompanyAccessSelect
						options={companyAccessSelectOptions}
						onChange={handleChangeCompanyAccess}
						mode="single"
						defaultValue={documentId(companyAccess)}
					/>
				</FormItem>}

			{companyAccess
				&& <>
					<Space direction='horizontal' size={4} style={{ marginBottom: 10 }}>
						<CompanyAccessStub companyAccess={companyAccess} withCompany={true}/>
					</Space>

					<Tabs>

						{(() => {
							const permissionsTab = ({ tab = 'Test API Key', title = 'Test API Key' } = { tab: 'Test API Key', title: 'Test API Key' }) => (
								<Tabs.TabPane tab={tab} key='ats-test-apikey'>
									<h2>{title}</h2>
									<AtsPermissionTable companyAccess={companyAccess} />
								</Tabs.TabPane>
							);

							const m = new CompanyAccessModel(companyAccess);

							// Permissions tests are currently only available for Lever and Greenhouse
							if (
								m.isAtsLever || m.isAtsGreenhouse
							) {
								return permissionsTab();
							}

							// For other ATS, we only display this tab for 50admin roles
							if (isAdmin && isStaffView) {
								return permissionsTab({ tab: <>Test API Key {TAG_ADMIN_ICON}</>, title: <>Test API Key {TAG_ADMIN}</> });
							}
						})()}

						<Tabs.TabPane tab='ATS Members' key='ats-members'>
							<h2>ATS Members</h2>
							<AtsMemberTable companyAccess={companyAccess}/>
						</Tabs.TabPane>

						<Tabs.TabPane tab='ATS Stages' key='ats-stages'>
							<h2>ATS Stages</h2>
							<AtsStageTable companyAccess={companyAccess}/>
						</Tabs.TabPane>

						<Tabs.TabPane tab='ATS Archive Reasons' key='ats-archive-reasons'>
							<h2>ATS Archive Reasons</h2>
							<AtsArchiveReasonTable companyAccess={companyAccess}/>
						</Tabs.TabPane>

						{isStaffView && <Tabs.TabPane tab={<>ATS Opportunities {TAG_STAFF_ICON}</>} key='ats-opportunities'>
							<h2>ATS Opportunities { TAG_STAFF}</h2>
							<AtsOpportunitiesTable companyAccess={companyAccess}/>
						</Tabs.TabPane>}

					</Tabs>
				</>
			}

			{isStaffView
				&& <Collapse ghost style={{ maxWidth: '100%', marginBottom: 20 }}>
					<Collapse.Panel header={<b>Debug {TAG_STAFF}</b>} key="1">
						All companies: {companies?.length}<br/>
						Companies with
						API: {companies?.filter((c) => c?.access?.some((a) => a?.accessType === 'api'))?.length}<br/>
						Selected company: {company?.name}<br/>
						Selected company's accesses: {companyAccesses?.length}<br/>
						Selected company access: {documentId(companyAccess)}<br/>
						{/* Company access: {JSON.stringify(companyAccess || {})}<br/> */}
						{/* {companies && <JSONPretty data={companies} />} */}
					</Collapse.Panel>
				</Collapse>}

		</PageLayout>
	);
};

export default ConfigureAtsSetupPage;
