import { Button, Space } from 'antd';
import { useTheme } from '@emotion/react';
import { SHORTLISTS_ICON } from '../../../../constants/constant';
import UserSaver from '../../buttons/UserSaver';

const UserGridCardSaverButton = (
	{
		user,
		...props
	},
) => {
	const className = 'UserGridCardSaverButton';

	const theme = useTheme();

	return (
		<div
			style={{
				position: 'absolute',
				top: 24,
				right: 24,
			}}
		>
			<Space>
				{/* <UserSaver */}
				{/*	user={user} */}
				{/*	Component={Button} */}
				{/*	size='large' */}
				{/*	icon={<>{SHORTLISTS_ICON}&nbsp;</>} */}
				{/*	style={{ border: 'none', boxShadow: 'none' }} */}
				{/* > */}
				{/*	<b>Save</b> */}
				{/* </UserSaver> */}
				{/* <UserSaver */}
				{/*	Component={Button} */}
				{/*	size='large' */}
				{/*	danger */}
				{/*	icon={SHORTLISTS_ICON} */}
				{/*	style={{ borderRadius: 40 }} */}
				{/*	user={user} */}
				{/* > */}
				{/* </UserSaver> */}
			</Space>
		</div>
	);
};

export default UserGridCardSaverButton;
