import { useState } from 'react';
import { ENGLISH_LANG } from '../../../../constants/constant';
import { Input } from 'antd';
import CompanyFieldEditor from './CompanyFieldEditor';

const CompanyTextEditor = (
	{
		company,
		lang = ENGLISH_LANG,
		title,
		details,
		field,
		isTranslatable = true,
		setEditorOpen,
	},
) => {
	const className = 'CompanyTextEditor';

	const [editedValue, setEditedValue] = useState(company.getTranslationField(field, lang));

	return (
		<CompanyFieldEditor
			company={company}
			lang={lang}
			setEditorOpen={setEditorOpen}
			title={title}
			details={details}
			field={field}
			isTranslatable={isTranslatable}
			editedValue={editedValue}
		>
			<Input
				value={editedValue}
				onChange={(e) => setEditedValue(e.target.value)}
			/>

		</CompanyFieldEditor>
	);
};

export default CompanyTextEditor;
