import { Col, Row } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useContext, useMemo } from 'react';

import TagRuleForm from '../../../../components/staff/tags/tagRules/tagRuleCreation/TagRuleForm';
import { saveTagRule, showTagRules } from '../../../../actions/tagRules';
import { getTagRuleSelected } from '../../../../reducers/app';
import PageLayout from '../../../../components/app/layout/PageLayout';
import { TAG_RULE_DOCUMENT_REF } from '../../../../constants/constant';
import { SocketContext } from '../../../../contexts/SocketProvider';
import { useAuthContext } from '../../../../hooks/useAuthContext';
import { documentId } from '../../../../utils/common';

const TagRuleCreationPage = () => {
	const dispatch = useDispatch();

	const tagRule = useSelector(getTagRuleSelected);

	const { isEditor, companyRecruiterDocIds } = useAuthContext();
	const { createAction } = useContext(SocketContext);

	const saveTagRules = async (values) => {
		const result = await dispatch(saveTagRule(values, true));

		if (result && result?.isActive) {
			const playload = {
				documentRef: TAG_RULE_DOCUMENT_REF,
				documentId: documentId(result),
				action: {
					title: documentId(values) ? 'Edit a tagRule' : 'Add new tagRule',
				},
				...(companyRecruiterDocIds.length > 0 && { companyId: companyRecruiterDocIds[0] }),
			};
			createAction(playload);
		}

		showTagRules();
	};

	const initialValues = useMemo(() => {
		if (!tagRule) return;
		const tagSlugs = tagRule.tagSlugs.map((tagSlug) => {
			const [slugCategory, slugValue] = tagSlug.split(':');
			return {
				slugCategory,
				slugValue,
			};
		});
		return { ...tagRule, tagSlugs };
	}, [tagRule]);

	return (
		<PageLayout
			withBackground={false}
			title='Add a tag rule'
		>
			<Row className='flexCenter'>
				<Col span={16}>
					<TagRuleForm onSubmit={saveTagRules} initialValues={initialValues} isEditor={isEditor} />
				</Col>
			</Row>
		</PageLayout>
		// <>
		//     {/*<Typography.Title level={1} style={{marginBottom: 20, color: theme.color.blue}}>Add a tag rule</Typography.Title>*/}
		// </>
	);
};

export default TagRuleCreationPage;
