import { useTheme } from '@emotion/react';
import { getCloudinaryPublicId } from '../../../../utils/common';
import { Image, Transformation } from 'cloudinary-react';
import { useMemo } from 'react';

const GenderScoreResourceImage = ({
	resource = {},
	width,
	height,
	...props
}) => {
	const className = 'GenderScoreResourceImage';

	const theme = useTheme();

	const memoizedContent = useMemo(() => {
		return (
			<div
				style={{
					position: 'relative',
					overflow: 'hidden',
					...props?.style,
				}}
			>
				{resource?.image?.indexOf?.('cloudinary') > 0
					? resource?.options?.image?.crop === 'fit'
						? (
							<>
								<div
									style={{
										position: 'absolute',
										filter: 'blur(5px) brightness(50%)',
										top: 0,
										left: 0,
										margin: '-25%',
									}}
								>
									<Image
										width={'150%'}
										height={'150%'}
										publicId={getCloudinaryPublicId(resource.image)}
										secure='true'
									>
										<Transformation
											width={width}
											height={height}
											crop='thumb'
										/>
									</Image>
								</div>
								<div
									style={{
										position: 'absolute',
										top: 0,
										left: 0,
										padding: 10,
									}}
								>
									<Image
										width={'100%'}
										height={'100%'}
										publicId={getCloudinaryPublicId(resource.image)}
										secure='true'
									>
										<Transformation
											width={width}
											height={height - 20}
											crop='pad'
											background='rgb:55ff0000'
											gravity={resource?.extra?.image?.gravity}
										/>
									</Image>
								</div>
							</>
						)
						: (
							<>
								<Image
									width={'100%'}
									height={'100%'}
									publicId={getCloudinaryPublicId(resource.image)}
									secure='true'
								>
									<Transformation
										width={width}
										height={height}
										crop='thumb'
										gravity={resource?.extra?.image?.gravity}
									/>
								</Image>
							</>
						)

					: (
						<Image
							width={width}
							height={height}
							publicId={`https://placehold.co/${width}x${height}/eee/ccc?text=No+image&font=montserrat`}
							secure='true'
						/>
					)
				}
			</div>
		);
	}, [resource?.image, resource?.options, width, height, props?.style]);

	return memoizedContent;
};

export default GenderScoreResourceImage;
