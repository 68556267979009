import { useContext, useMemo } from 'react';
import { useTheme } from '@emotion/react';
import { Radio } from 'antd';
import { AppDataContext } from '../../../contexts/AppDataProvider';

const SurveyLangSwitcher = () => {
	const className = 'SurveyLangSwitcher';

	const theme = useTheme();

	const { appLang, setAppLang } = useContext(AppDataContext);

	const memoizedContent = useMemo(() => {
		return (
			<Radio.Group onChange={(e) => setAppLang?.(e.target.value)} defaultValue={appLang}>
				<Radio.Button value="fr">FR</Radio.Button>
				<Radio.Button value="en">EN</Radio.Button>
			</Radio.Group>
		);
	}, [appLang, setAppLang]);

	return memoizedContent;
};

export default SurveyLangSwitcher;
