import { requiredRule } from '../../../utils/common';
import FormItem from '../form/FormItem';
import CompanyPlanSelect from './CompanyPlanSelect';

export const companyPlanValuePath = ['companyPlan', 'planValue'];

const CompanyPlanFormItem = ({
	...props
}) => {
	const className = 'CompanyPlanFormItem';

	return (
		<FormItem
			label='Business Plan'
			name={companyPlanValuePath}
			rules={[requiredRule]}
		>
			<CompanyPlanSelect />
		</FormItem>
	);
};

export default CompanyPlanFormItem;
