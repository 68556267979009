/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect } from 'react';
import { UserModalContext } from '../contexts/modals/UserModalProvider';
import { UserSuggestModalContext } from '../contexts/modals/UserSuggestModalProvider';
import { UserMessageModalContext } from '../contexts/modals/UserMessageModalProvider';
import { UserSourceModalContext } from '../contexts/modals/UserSourceModalProvider';
import { UserAtsSendModalContext } from '../contexts/modals/UserAtsSendModalProvider';

const useBodyScrollBlocker = (open, source) => {
	const className = 'useBodyScrollBlocker';

	const { open: userModalOpen } = useContext(UserModalContext);
	const { open: userSourceModalOpen } = useContext(UserSourceModalContext);
	const { open: userAtsSendModalOpen } = useContext(UserAtsSendModalContext);
	const { open: userSuggestModalOpen } = useContext(UserSuggestModalContext);
	const { open: userMessageModalOpen } = useContext(UserMessageModalContext);

	useEffect(() => {
		if (typeof window != 'undefined' && window.document) {
			if (open
				|| userModalOpen
				|| userSourceModalOpen
				|| userAtsSendModalOpen
				|| userSuggestModalOpen
				|| userMessageModalOpen
				|| userAtsSendModalOpen
			) {
				document.body.style.overflow = 'hidden';
			} else {
				document.body.style.overflow = 'unset';
			}
		}
	}, [
		open, userModalOpen, userSourceModalOpen, userAtsSendModalOpen, userSuggestModalOpen, userMessageModalOpen]);
};

export default useBodyScrollBlocker;
