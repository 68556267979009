import * as ActionTypes from '../actions/types';
import { getData } from './index';
import { findIndex, pullAt } from 'lodash';
import { LocalDebug, localDebug } from '../utils/LocalDebug';
import { documentId } from '../utils/common';

const className = 'app';

export default function app(state = {
	user: null,
	users: [],
	userSelected: null,
	shortlists: [],
	shortlistUsers: [],
	sourcingsCount: 0,
	sourcings: [],
	tags: [],
	tagRules: [],
	tagRuleSelected: null,
	tagsCategory: [],
	atsArchiveReasons: [],
	atsPipelines: [],
	atsShortlistPipelines: [],
	atsApplicationPipelines: [],
	atsCandidatePipelines: [],
	atsCandidateWithoutApplicationPipelines: [],
	atsArchivedPipelines: [],
	companies: [],
	companySelected: null,
	companyGenderScoreSurveyDraftSelected: null,
	companyUMSSurveyDraftSelected: null,
	companyEditor: null,
	companyAccesses: [],
	companyAccessSelected: null,
	recruiters: [],
	jobs: [],
	jobIds: null,
	jobSelected: null,
	tagsJobs: [],
	tagsUsers: [],
	coaches: [],
	campaignEmailFilters: [],
	items: [],
	loading: false,
	error: null,
	message: null,
	displayScreen: 'desktop',
	historyActions: [],
	documentReadersIds: null,
	shouldRefreshData: 0,
	history: {
		currentPublished: {},
		nextPublished: {},
		jobsCompany: [],
		deletedJobsCompany: [],
		publishedJobsCompany: [],
		catalogJobsCompany: [],

	},
}, action) {
	switch (action.type) {
		case ActionTypes.LOGIN_SUCCESS:
			return {
				...state,
				user: action.user,
			};
		case ActionTypes.SHOULD_REFRESH_DATA:
			return {
				...state,
				shouldRefreshData: (state.shouldRefreshData || 0) + 1,
			};
		case ActionTypes.COMPANY_ACCESSES_LOADED:
			return {
				...state,
				companyAccesses: action.companyAccesses,
			};
		case ActionTypes.COMPANIES_LOADED:
			return {
				...state,
				companies: action.companies,
			};
		case ActionTypes.RECRUITERS_LOADED:
			return {
				...state,
				recruiters: action.recruiters,
			};
		case ActionTypes.COMPANY_LOADED:
			const companyId = documentId(action?.company)?.toString?.();
			const companies = [
				...state?.companies?.filter((c) => documentId(c).toString?.() !== companyId),
				...action?.company ? [action?.company] : [],
			];
			const companySelected = documentId(state?.companySelected).toString() === companyId
				? action?.company
				: state?.companySelected;

			return {
				...state,
				companies,
				companySelected,
			};
		case ActionTypes.COMPANY_GENDERSCORE_SURVEY_DRAFT_SELECTED:
			return {
				...state,
				companyGenderScoreSurveyDraftSelected: action.companyGenderScoreSurveyDraftSelected,
			};
		case ActionTypes.COMPANY_UMS_SURVEY_DRAFT_SELECTED:
			return {
				...state,
				companyUMSSurveyDraftSelected: action.companyUMSSurveyDraftSelected,
			};
		case ActionTypes.ALL_ACCESS_LOADED:
			return {
				...state,
				companySelected: { ...state.companySelected, access: action.access },
			};
		case ActionTypes.LAST_BATCH_LOADED:
			return {
				...state,
				companySelected: { ...state.companySelected, lastBatch: action.batch },
			};
		case ActionTypes.SET_COMPANY_SELECTED:
			return {
				...state,
				companySelected: action.company,
			};
		case ActionTypes.SET_COMPANY_ACCESS_SELECTED:
			localDebug(action.type, { state, action });
			return {
				...state,
				companyAccessSelected: action.companyAccess,
			};
		case ActionTypes.SET_COMPANY_EDITOR:
			return {
				...state,
				companyEditor: action.company,
			};
		case ActionTypes.JOBS_LOADED:
			return {
				...state,
				jobs: action.jobs,
			};
		case ActionTypes.USERS_LOADED:
			return {
				...state,
				users: action.users,
			};
		case ActionTypes.COACHING_USERS_LOADED:
			return {
				...state,
				coaches: action.coaches,
			};
		case ActionTypes.TAGS_LOADED:
			return {
				...state,
				tags: action.tags,
			};
		case ActionTypes.TAG_RULES_LOADED:
			return {
				...state,
				tagRules: action.tagRules,
			};
		case ActionTypes.SET_TAG_RULE_SELECTED:
			return {
				...state,
				tagRuleSelected: action.tagRule,
			};
		case ActionTypes.SET_USER_SELECTED:
			return {
				...state,
				userSelected: action.user,
			};
		case ActionTypes.TAGS_CATEGORY_LOADED:
			return {
				...state,
				tagsCategory: action.tagsCategory,
			};

		case ActionTypes.SHORTLISTS_LOADED:
			return {
				...state,
				shortlists: action.shortlists,
			};
		case ActionTypes.SHORTLIST_USERS_LOADED:
			return {
				...state,
				shortlistUsers: action.shortlistUsers,
			};
		case ActionTypes.SET_SOURCING_SELECTED:
			return {
				...state,
				sourcingSelected: action.sourcingSelected,
			};
		case ActionTypes.SET_SOURCINGS_USER_SELECTED:
			return {
				...state,
				sourcingsUserSelected: action.sourcingsUserSelected,
			};
		case ActionTypes.SOURCINGS_LOADED:
			return {
				...state,
				sourcings: action.sourcings,
			};
		case ActionTypes.SET_SOURCINGS_COMPANY_SELECTED:
			return {
				...state,
				sourcingsCompanySelected: action.sourcingsCompanySelected,
			};
		case ActionTypes.SET_SOURCINGS_STATUS_SELECTED:
			return {
				...state,
				sourcingsStatusSelected: action.sourcingsStatusSelected,
			};
		case ActionTypes.SET_SOURCINGS_SOURCE_SELECTED:
			return {
				...state,
				sourcingsSourceSelected: action.sourcingsSourceSelected,
			};
		case ActionTypes.TAGS_JOBS_LOADED:
			return {
				...state,
				tagsJobs: action.tagsJobs,
			};
		case ActionTypes.SET_JOB_SELECTED:
			return {
				...state,
				jobSelected: action.job,
			};
		case ActionTypes.TAGS_USERS_LOADED:
			return {
				...state,
				tagsUsers: action.tagsUsers,
			};

		case ActionTypes.CAMPAIGN_EMAIL_FILTERS_LOADED:
			return {
				...state,
				campaignEmailFilters: action.campaignEmailFilters,
			};

		case ActionTypes.ITEMS_LOADED:
			return {
				...state,
				items: action.items,
			};

		case ActionTypes.COMPANY_PLAN_EXTRA_TAGS_LOADED:
			const companyPlansExtraTags = action.companyPlansExtraTags
				.filter((e) => e !== null)
				.reduce((acc, tag) => {
					return [...acc,
						{
							value: tag,
							key: tag,
						}];
				}, []);
			return {
				...state,
				companyPlansExtraTags,
			};
		case ActionTypes.SET_HISTORY:
			return {
				...state,
				history: { ...state.history, [action.history.key]: action.history.value },
			};
		case ActionTypes.LOADING_START:
			return {
				...state,
				loading: true,
			};
		case ActionTypes.LOADING_END:
			return {
				...state,
				loading: false,
			};
		case ActionTypes.MESSAGE:
			return {
				...state,
				message: action.message,
				variant: action.variant || 'info',
			};
		case ActionTypes.CLOSE_MESSAGE:
			return {
				...state,
				message: null,
			};
		case ActionTypes.DISPLAY_SCREEN_UPDATED:
			return {
				...state,
				displayScreen: action.displayScreen,
			};
		case ActionTypes.SET_ACTION_HISTORY:
			return {
				...state,
				historyActions: action.data,
			};

		case ActionTypes.READ_ACTION_HISTORY:
			const prevActions = [...state.historyActions];

			const index = findIndex(prevActions, (a) => a.label === action.playload.label);

			pullAt(prevActions, index);
			return {
				...state,
				documentReadersIds: action.playload?.documentIds,
				historyActions: prevActions,
			};
		case ActionTypes.SET_JOBS_ID:
			return {
				...state,
				jobIds: action.jobIds,
			};
		case ActionTypes.ATS_PIPELINES_LOADED:
			const { atsPipelines } = action;
			if (!atsPipelines) return state;
			const atsShortlistPipelines = atsPipelines.filter((p) => ['shortlist'].includes(p.value));
			const atsCandidatePipelines = atsPipelines.filter((p) => !['shortlist'].includes(p.value));
			const atsApplicationPipelines = atsPipelines.filter((p) => ['applicant'].includes(p.value));
			const atsCandidateWithoutApplicationPipelines = atsPipelines.filter((p) => !['shortlist', 'applicant'].includes(p.value));

			return {
				...state,
				...atsPipelines ? { atsPipelines } : {},
				...atsShortlistPipelines ? { atsShortlistPipelines } : {},
				...atsCandidatePipelines ? { atsCandidatePipelines } : {},
				...atsApplicationPipelines ? { atsApplicationPipelines } : {},
				...atsCandidateWithoutApplicationPipelines ? { atsCandidateWithoutApplicationPipelines } : {},
			};
		case ActionTypes.ATS_ARCHIVE_REASONS_LOADED:
			const { atsArchiveReasons } = action;
			if (!atsArchiveReasons) return state;
			return {
				...state,
				atsArchiveReasons: action.atsArchiveReasons,
			};
		default:
			return state;
	}
}

export function getAtsArchiveReasons(state, errorIfNotFound = false) {
	return getData(state, 'app.atsArchiveReasons', errorIfNotFound && 'Unknown archive reasons');
}

export function getAtsPipelines(state, errorIfNotFound = false) {
	return getData(state, 'app.atsPipelines', errorIfNotFound && 'Unknown pipelines');
}

export function getAtsCandidatePipelines(state, errorIfNotFound = false) {
	return getData(state, 'app.atsCandidatePipelines', errorIfNotFound && 'Unknown pipelines');
}

export function getAtsCandidateWithoutApplicationPipelines(state, errorIfNotFound = false) {
	return getData(state, 'app.atsCandidateWithoutApplicationPipelines', errorIfNotFound && 'Unknown pipelines');
}

export function getAtsApplicationPipelines(state, errorIfNotFound = false) {
	return getData(state, 'app.atsApplicationPipelines', errorIfNotFound && 'Unknown pipelines');
}

export function getAtsShortlistPipelines(state, errorIfNotFound = false) {
	return getData(state, 'app.atsShortlistPipelines', errorIfNotFound && 'Unknown pipelines');
}

export function getAtsArchivedPipelines(state, errorIfNotFound = false) {
	return getData(state, 'app.atsArchivedPipelines', errorIfNotFound && 'Unknown pipelines');
}

export function getCompanies(state, errorIfNotFound = false) {
	return getData(state, 'app.companies', errorIfNotFound && 'Unknown companies');
}

export function getRecruiters(state, errorIfNotFound = false) {
	return getData(state, 'app.recruiters', errorIfNotFound && 'Unknown recruiters');
}

export function getCompanySelected(state, errorIfNotFound = false) {
	return getData(state, 'app.companySelected', errorIfNotFound && 'Unknown company');
}
export function getCompanyGenderScoreSurveyDraftSelected(state, errorIfNotFound = false) {
	return getData(state, 'app.companyGenderScoreSurveyDraftSelected', errorIfNotFound && 'Unknown');
}
export function getCompanyUMSSurveyDraftSelected(state, errorIfNotFound = false) {
	return getData(state, 'app.companyUMSSurveyDraftSelected', errorIfNotFound && 'Unknown');
}

export function getCompanySelectedApiAccess(state, errorIfNotFound = false) {
	// const companySelected = getCompanySelected(state, errorIfNotFound);
	const companyAccesses = getCompanyAccesses(state, errorIfNotFound);
	const atsApiAccess = companyAccesses.find((c) => c.accessType === 'api');
	// LocalDebug.logInfo({className, method: 'getCompanySelectedApiAccess'}, { atsApiAccess })
	return atsApiAccess;
}

export function getCompanyEditor(state, errorIfNotFound = false) {
	return getData(state, 'app.companyEditor', errorIfNotFound && 'Unknown company');
}

export function getCompanyAccesses(state, errorIfNotFound = false) {
	return getData(state, 'app.companyAccesses', errorIfNotFound && 'Unknown company accesses');
}

export function getCompanyAccessSelected(state, errorIfNotFound = false) {
	return getData(state, 'app.companyAccessSelected', errorIfNotFound && 'Unknown company access');
}

export function getJobs(state, errorIfNotFound = false) {
	return getData(state, 'app.jobs', errorIfNotFound && 'Unknown company');
}

export function getUsers(state, errorIfNotFound = false) {
	return getData(state, 'app.users', errorIfNotFound && 'Unknown user');
}

export function getUserSelected(state, errorIfNotFound = false) {
	return getData(state, 'app.userSelected', errorIfNotFound && 'Unknown user');
}

export function getJobSelected(state, errorIfNotFound = false) {
	return getData(state, 'app.jobSelected', errorIfNotFound && 'Unknown job');
}

export function getTags(state, errorIfNotFound = false) {
	return getData(state, 'app.tags', errorIfNotFound && 'Unknown tags');
}

export function getTagRules(state, errorIfNotFound = false) {
	return getData(state, 'app.tagRules', errorIfNotFound && 'Unknown tagRules');
}

export function getTagRuleSelected(state, errorIfNotFound = false) {
	return getData(state, 'app.tagRuleSelected', errorIfNotFound && 'Unknown tagRules');
}

export function getTagsCategory(state, errorIfNotFound = false) {
	return getData(state, 'app.tagsCategory', errorIfNotFound && 'Unknown tagRules');
}

export function getDisplayScreen(state, errorIfNotFound = false) {
	return getData(state, 'app.displayScreen', errorIfNotFound && 'Unknown screen device');
}

export function getLoading(state, errorIfNotFound = false) {
	return getData(state, 'app.loading', errorIfNotFound && 'Unknown screen device');
}

export function getShouldRefreshData(state, errorIfNotFound = false) {
	return getData(state, 'app.shouldRefreshData', errorIfNotFound && 'Unknown');
}

export function getHistory(state, errorIfNotFound = false) {
	return getData(state, 'app.history', errorIfNotFound && 'history');
}

export function getExtraTags(state, errorIfNotFound = false) {
	return getData(state, 'app.companyPlansExtraTags', errorIfNotFound && 'Extra Tags');
}
export function getItems(state, errorIfNotFound = false) {
	return getData(state, 'app.items', errorIfNotFound && 'items');
}

export function getCurrentUser(state, errorIfNotFound = false) {
	return getData(state, 'app.user', errorIfNotFound && 'No current user in app');
}

export function getShortlists(state, errorIfNotFound = false) {
	return getData(state, 'app.shortlists', errorIfNotFound && 'Unknown shortlists');
}

export function getShortlistUsers(state, errorIfNotFound = false) {
	return getData(state, 'app.shortlistUsers', errorIfNotFound && 'Unknown shortlistUsers');
}

export function getShortlistSelected(state, errorIfNotFound = false) {
	return getData(state, 'app.shortlistSelected', errorIfNotFound && 'Unknown shortlist selected');
}

export function getShortlistsUserSelected(state, errorIfNotFound = false) {
	return getData(state, 'app.shortlistsUserSelected', errorIfNotFound && 'Unknown shortlist user selected');
}

export function getShortlistsCompanySelected(state, errorIfNotFound = false) {
	return getData(state, 'app.shortlistsCompanySelected', errorIfNotFound && 'Unknown shortlist company selected');
}

export function getSourcings(state, errorIfNotFound = false) {
	return getData(state, 'app.sourcings', errorIfNotFound && 'Unknown sourcings');
}

export function getSourcingSelected(state, errorIfNotFound = false) {
	return getData(state, 'app.sourcingSelected', errorIfNotFound && 'Unknown sourcing selected');
}

export function getSourcingsUserSelected(state, errorIfNotFound = false) {
	return getData(state, 'app.sourcingsUserSelected', errorIfNotFound && 'Unknown sourcing user selected');
}

export function getSourcingsCompanySelected(state, errorIfNotFound = false) {
	return getData(state, 'app.sourcingsCompanySelected', errorIfNotFound && 'Unknown sourcing company selected');
}

export function getSourcingsStatusSelected(state, errorIfNotFound = false) {
	return getData(state, 'app.sourcingsStatusSelected', errorIfNotFound && 'Unknown sourcing status selected');
}

export function getSourcingsSourceSelected(state, errorIfNotFound = false) {
	return getData(state, 'app.sourcingsSourceSelected', errorIfNotFound && 'Unknown sourcing source selected');
}

export function getAcls(state, errorIfNotFound = false) {
	return getData(state, 'app.acls', errorIfNotFound && 'Unknown acls');
}

export function getAclSelected(state, errorIfNotFound = false) {
	return getData(state, 'app.aclSelected', errorIfNotFound && 'Unknown acl selected');
}

export function getAclsUserSelected(state, errorIfNotFound = false) {
	return getData(state, 'app.aclsUserSelected', errorIfNotFound && 'Unknown acl user selected');
}

export function getAclsCompanySelected(state, errorIfNotFound = false) {
	return getData(state, 'app.aclsCompanySelected', errorIfNotFound && 'Unknown acl company selected');
}

export function getAclsRoleSelected(state, errorIfNotFound = false) {
	return getData(state, 'app.aclsRoleSelected', errorIfNotFound && 'Unknown acl role selected');
}

export function getTagsJobs(state, errorIfNotFound = false) {
	return getData(state, 'app.tagsJobs', errorIfNotFound && 'Unknown job tags selected');
}

export function getTagsUsers(state, errorIfNotFound = false) {
	return getData(state, 'app.tagsUsers', errorIfNotFound && 'Unknown user tags selected');
}

export function getCampaignEmailFilters(state, errorIfNotFound = false) {
	return getData(state, 'app.campaignEmailFilters', errorIfNotFound && 'Unknown');
}

export function getHistoryActions(state, errorIfNotFound = false) {
	return getData(state, 'app.historyActions', errorIfNotFound && 'Unknown user tags selected');
}

export function getDocumentReadersIds(state, errorIfNotFound = false) {
	return getData(state, 'app.documentReadersIds', errorIfNotFound && 'Unknown user tags selected');
}

export function getJobIds(state, errorIfNotFound = false) {
	return getData(state, 'app.jobIds', errorIfNotFound && 'Unknown user tags selected');
}
