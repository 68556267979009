import GenderhireCandidatesSourcedMenu from '../../menu/genderhire/GenderhireCandidatesSourcedMenu';
import SourcingsBasePage from './SourcingsBasePage';

const SourcingsPage = () => (
	<SourcingsBasePage
		mode='sourcings'
		MenuClass={GenderhireCandidatesSourcedMenu}
	/>
);

export default SourcingsPage;
