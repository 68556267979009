/** @jsxImportSource @emotion/react */

import React from 'react';
import { useTheme } from '@emotion/react';

const classes = (theme) => ({
	container: {
		padding: '10px',
		width: '100%',
		margin: '0 auto',
	},
	contentGridItem: {
		'display': 'block',
		'cursor': 'pointer',
		'borderRadius': '14px',
		'overflow': 'hidden',
		'boxShadow': '0 5px 14px -3px rgb(0 0 0 / 25%)',
		'zIndex': 2,
		'width': '100%',
		'height': '100%',
		'backgroundColor': '#fafafa',
		'backgroundImage': 'linear-gradient(white, rgba(255, 255, 255, 0))',
		'transition': 'background-color 1s',
		'transitionProperty':
      'opacity, transform, box-shadow, background-color !important',
		'transitionDelay': ' 0.2s, 0.2s, 0s, 0s !important',
		'transitionDuration': ' 0.2s, 0.2s, 0.2s, 0.2s !important',
		'transitionTimingFunction':
      'ease-out, ease-out, ease-out, ease-out !important',
		':hover': {
			boxShadow: '0 1px 8px -4px rgb(0 0 0 / 40%)',
			backgroundColor: theme.color.white,
		},
	},
});

function GridItem({ children, style = {} }) {
	const theme = useTheme();
	const styles = classes(theme);
	return (
		<div>
			<div css={[styles.container, style]}>
				<div
					className="relative grid-item-container"
					css={{ ...styles.contentGridItem, ...style?.contentGridItem }}
				>
					{children}
				</div>
			</div>
		</div>
	);
}

export default GridItem;
