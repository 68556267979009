import { actionWithLoader } from './utils';
import { localDebug } from '../utils/LocalDebug';
import BatchService from '../services/batch';

export function remove(id) {
	return actionWithLoader(async () => {
		try {
			await BatchService.remove(id);
		} catch (error) {
			localDebug('error', error);
		}
	});
}
