import { Button, Space } from 'antd';
import { useTheme } from '@emotion/react';
import config from '../../config/config';
import AddButton from '../app/button/AddButton';
import { useEffect, useState } from 'react';
import { FiUpload, FiUser } from 'react-icons/fi';
import CloudinaryImageUploader from '../app/CloudinaryImageUploader';
import { LocalDebug } from '../../utils/LocalDebug';
import UserAvatar from '../user/widgets/UserAvatar';
import { buildCloudinaryUrl } from '../../utils/common';
import { FaEdit, FaTimes, FaUpload } from 'react-icons/fa';
import Callout from '../app/callout/Callout';

const AccountAvatarUploader = (
	{
		user,
		onSuccess,
	},
) => {
	const className = 'AccountAvatarUploader';
	const [imageUrl, setImageUrl] = useState(user?.information?.avatar);

	const onUpload = () => {
		LocalDebug.logUseEffect({
			className, method: 'onUpload',
		}, { 'uploader.cloudinaryPublicId': uploader.cloudinaryPublicId });
		if (uploader.cloudinaryPublicId) {
			setImageUrl(buildCloudinaryUrl(uploader.cloudinaryPublicId));
		}
	};

	const uploader = new CloudinaryImageUploader({
		uploadPreset: config.cloudinary.upload_preset_profile,
		onSuccess: onUpload,
	});
	LocalDebug.logInfo({ className, method: 'constructor' }, { uploader });

	useEffect(() => {
		if (imageUrl !== user?.information?.avatar) {
			onSuccess(imageUrl);
		}
	}, [imageUrl]);

	const theme = useTheme();
	return (
		<Space direction="horizontal">
			<div>
				{(imageUrl || true)
					? <UserAvatar
						withTooltip={false}
						withIsNew={false}
						withIsUnviewed={false}
						withCompanyLogo={false}
						size={100}
						user={{
							...user,
							information: {
								...user?.information,
								avatar: imageUrl,
							},
						}}
					/>
					: <div
						style={{
							background: theme.color.grey,
							color: 'white',
							borderRadius: 100,
							width: 100,
							height: 100,
							display: 'flex',
							flexDirection: 'row',
							justifyContent: 'center',
							alignItems: 'center',
							fontSize: 64,
							padding: 0,
						}}
					>
						<FiUser/>
					</div>
				}
				<Button
					onClick={() => { setImageUrl(null); }}
					style={{
						background: theme.color.accent,
						color: 'white',
						borderRadius: 100,
						width: 32,
						height: 32,
						display: 'flex',
						flexDirection: 'row',
						justifyContent: 'center',
						alignItems: 'center',
						fontSize: 16,
						padding: 0,
						position: 'absolute',
						top: 68,
						left: 0,
					}}
				>
					<FaTimes/>
				</Button>
				<Button
					onClick={() => { uploader.showWidget(); }}
					style={{
						background: theme.color.blue,
						color: 'white',
						borderRadius: 100,
						width: 32,
						height: 32,
						display: 'flex',
						flexDirection: 'row',
						justifyContent: 'center',
						alignItems: 'center',
						fontSize: 16,
						padding: 0,
						position: 'absolute',
						top: 68,
						left: 72,
					}}
				>
					<FiUpload/>
				</Button>
			</div>

			{imageUrl
				&& <Callout
					style={{
						borderRadius: 5, margin: 0, lineHeight: 1.2, fontSize: 12,
					}}
					withFeedback={false}
				>
					Upload a picture for candidates to see your face!
				</Callout>
			}
			{/* <AddButton */}
			{/*    icon={<FiUpload />} */}
			{/*    title={buttonText} */}
			{/*    color={theme.color.blue} */}
			{/* /> */}
		</Space>
	);
};

export default AccountAvatarUploader;
