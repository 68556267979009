import { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getCompanySelected } from '../../reducers/app';
import { NotificationContext } from '../../components/app/notification/NotificationProvider';
import { useAuthContext } from '../../hooks/useAuthContext';
import Logo50inTech from '../../components/app/Logo50inTech';
import { addSourcing } from '../../actions/sourcings';
import { writeRecruiterLog } from '../../actions/logging';
import CompanyModel from '../../models/CompanyModel';
import { documentId, writeLog } from '../../utils/common';
import {
	SOURCING_PIPELINE_SHORTLIST,
	SOURCING_STAGE_OPTION_SHORTLIST_50INTECH,
	STAGE_NEW,
} from '../../constants/constant';
import { COMPANY_RECRUITER_LOG } from '../../constants/company-recruiter-log';
import { UserSuggestModalContext } from './UserSuggestModalProvider';
import ModalContainerBase from './ModalContainerBase';
import FormBoxTitle from '../../components/app/form/FormBoxTitle';
import SourcingSaveForm from '../../components/sourcings/SourcingSaveForm';
import { SOURCE_50INTECH_VALUE } from '../../constants/sourcing';

const UserSuggestModalContainer = () => {
	const className = 'UserSuggestModalContainer';

	const {
		isStaffView, companyIdsUser,
	} = useAuthContext();

	const {
		open, setOpen, user, job, onStart, onComplete,
	} = useContext(UserSuggestModalContext);

	const { notifySuccess } = useContext(NotificationContext);

	const companySelected = useSelector(getCompanySelected);

	const [company, setCompany] = useState();

	useEffect(() => {
		if (companySelected) {
			setCompany(new CompanyModel(companySelected));
		} else {
			setCompany(null);
		}
	}, [companySelected]);

	const managers = [];

	const popupTitle = <>
		<Logo50inTech size={24} style={{ marginBottom: -4, marginRight: 8 }}/>
		<span>{company ? `Suggest a talent for ${company.name}` : 'Suggest a talent'}</span>
	</>;
	const counter = user?.suggestedSourcings?.length;
	const shortlists = user?.suggestedSourcings;

	shortlists?.forEach((item) => {
		const { manager } = item;
		managers.push(...manager && !managers.some(
			(s) => (documentId(s)?.toString?.()) === (documentId(manager)?.toString?.()),
		)
			? [manager] : []);
	});

	const handleSubmit = async (values) => {
		await onStart?.();
		setOpen(false);
		writeLog(`${className} handleSubmit`, { user: user?.slug });
		const valuesToDb = { ...values };
		const sourcing = await addSourcing(valuesToDb);

		// company recruiter log
		writeRecruiterLog(COMPANY_RECRUITER_LOG.SHORTLIST, {
			userTargetId: documentId(user),
			sourcingTargetId: documentId(sourcing),
		});

		notifySuccess({
			description: (
				<div>
					<b>{user?.firstName}</b> has been added to
					{companySelected ? companySelected?.name : 'the client'}'s <i>Suggested for you</i> section.
				</div>
			),
		});

		await onComplete?.();
	};

	return (
		<ModalContainerBase
			open={open}
			setOpen={setOpen}
			width={640}
			footer={false}
		>
			<FormBoxTitle
				title={popupTitle}
			>
				<SourcingSaveForm
					initialValues={{
						userId: documentId(user),
						disableATSPush: true,
						stage: STAGE_NEW,
						candidatePipeline: SOURCING_PIPELINE_SHORTLIST,
						source: SOURCE_50INTECH_VALUE,
						candidateStage: SOURCING_STAGE_OPTION_SHORTLIST_50INTECH.value,
						user,
						job,
						companyId: documentId(job?.company) || companyIdsUser?.[0],
						jobId: documentId(job),
						viaProfile: true,
						companyIds: documentId(job?.company)
					|| (!isStaffView ? companyIdsUser : null),
					}}
					onClose={() => setOpen(false)}
					onReset={() => setOpen(false)}
					onSubmit={handleSubmit}
					withSource={false}
				/>
			</FormBoxTitle>
		</ModalContainerBase>
	);
};

export default UserSuggestModalContainer;
