import { useEffect, useState } from 'react';
import { useAuthContext } from '../../../hooks/useAuthContext';
import TableColumns from '../../app/table/TableColumns';
import { useTheme } from '@emotion/react';
import userService from '../../../services/user';
import UserStub from '../stub/UserStub';
import {
	Button, Popover, Space, Table, Tooltip,
} from 'antd';
import TableHeader from '../../app/table/TableHeader';
import EmptyData from '../../app/EmptyData';
import { displayHtml, documentId } from '../../../utils/common';
import MessageBtn from '../../messages/MessageBtn';
import UserSourcingAddBtn from './UserSourcingAddBtn';
import { ADD_TO_TALENT_POOL_LABEL } from '../../../constants/constant';
import { useSelector } from 'react-redux';
import { getCompanySelected } from '../../../reducers/app';
import { FaCommentDots } from 'react-icons/fa';
import { SOURCE_50INTECH_VALUE, SOURCE_RECRUITER_VALUE } from '../../../constants/sourcing';

const MessageTable = (
	{
		dataSource = [],
		user,
		columns,
		onFilter,
		onClearFilter,
		isLoading,
		initialValues = {},
		...otherProps
	},
) => {
	const { isStaffView, isTalent, companyIdsUser } = useAuthContext();

	const addSourcingBtn = <UserSourcingAddBtn
		title={ADD_TO_TALENT_POOL_LABEL}
		user={user}
		initialValues={{
			userId: documentId(user),
			source: isTalent ? SOURCE_50INTECH_VALUE : SOURCE_RECRUITER_VALUE,
			user,
			viaProfile: true,
			companyIds: !isStaffView ? companyIdsUser : null,
		}}
		sourcings={user.sourcings}
		style={{ marginBottom: 0 }}
	/>;

	const messageBtn = <MessageBtn
		user={user}
		initialValues={{
			userToId: documentId(user),
			userTo: user,
			viaProfile: true,
			companyIds: !isStaffView ? companyIdsUser : null,
		}}
		messages={user.messagesTo}
		style={{ marginBottom: 0 }}
	/>;

	return (
		<>
			{!isLoading && dataSource?.length > 0
				? <Table
					// title={() => <TableHeader total={dataSource.length}/>}
					title={false}
					dataSource={dataSource}
					loading={isLoading}
					columns={columns}
					size="middle"
					bordered
				/>
				: <EmptyData
					description={<Space
						direction='vertical'
						size={20}
						style={{ width: '100%' }}>
						<span>
							<strong>{user?.firstName}</strong>
								&nbsp;hasn't yet been contacted by a recruiter of your company.
						</span>
						{/* {user?.sourcings?.length */}
						{/*    ? messageBtn */}
						{/*    : <> */}
						{/*        <div>Start by sourcing her!</div><span style={{textAlign: 'left'}}>{addSourcingBtn}</span> */}
						{/*    </> */}
						{/* } */}
					</Space>
					}
				/>
			}
		</>
	);
};

const dateSorter = (ad, bd, order) => {
	if (!ad) return order === 'descend' ? -1 : 1;
	if (!bd) return order === 'descend' ? 1 : -1;
	return bd - ad;
};

const UserMessagesTo = (
	{
		user,
		withUserStub = false,
	},
) => {
	// context
	const companySelected = useSelector(getCompanySelected);
	const { isStaff, isStaffView, companyRecruiterDocIds } = useAuthContext();

	// theme
	const theme = useTheme();

	// state
	const [dataSource, setDataSource] = useState([]);
	const [isLoading, setIsLoading] = useState(false);

	const fetchData = async (userId, options = {}) => {
		const { data: { messagesTo } } = await userService.getMessagesTo(userId, { companyId: documentId(companySelected) });
		setDataSource(messagesTo);
		setIsLoading(false);
	};

	useEffect(() => {
		setIsLoading(true);
		if (user) {
			fetchData(documentId(user));
		} else {
			setIsLoading(false);
			setDataSource([]);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [user]);

	const columns = [
		TableColumns.userStubColumn(theme, { fixed: 'left' }),
		{
			title: 'Message',
			// dataIndex: 'message',
			dataIndex: ['content', 'body'],
			removeInColumnFilter: true,
			sorter: true,
			render: (value, row) => <Popover title={<b>Message</b>}
						 content={
							 <Space style={{ maxWidth: '400px', fontSize: 14 }}>
								 {displayHtml(value)}
							 </Space>}>
				<Button type='link' icon={<FaCommentDots style={{ marginBottom: -2 }}/>}/>
			</Popover>,
			...TableColumns.columnFixedWidth(80),
		},
		TableColumns.createdAtColumn(theme),
		TableColumns.updatedAtColumn(theme),
	];

	const handleFilter = (values) => {
		fetchData(documentId(user), values);
	};

	const handleClearFilter = () => {
		fetchData(documentId(user));
	};

	return (user
		&& <>
			{
				withUserStub
				&& <div style={{
					border: '1px solid #eee', borderRadius: 4, padding: '5px 12px', marginBottom: 20,
				}}>
					<UserStub user={user}/>
				</div>
			}
			<MessageTable
				user={user}
				columns={columns}
				dataSource={dataSource}
				onFilter={handleFilter}
				isLoading={isLoading}
				onClearFilter={handleClearFilter}
			/>
		</>
	);
};

export default UserMessagesTo;
