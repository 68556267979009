const LOCATION_CONTINENT = require('../../../property/location-continent');
const { asCategoryTag, asCategoryTagOption } = require('../tags.constants.utils');

export const TAG_CATEGORY_JOB_SEARCH_JOB_LOCATION_CONTINENT_SLUG = 'job-search-job-location-continent';

const asJobSearchJobLocationContinentTag = (slug) => asCategoryTag(TAG_CATEGORY_JOB_SEARCH_JOB_LOCATION_CONTINENT_SLUG, slug);

const asJobSearchJobLocationContinentTagOption = (value, label, group) => asCategoryTagOption(TAG_CATEGORY_JOB_SEARCH_JOB_LOCATION_CONTINENT_SLUG, value, label, group);

export const options = LOCATION_CONTINENT
	?.options
	?.map((option) => asJobSearchJobLocationContinentTagOption(option.value, option.label, option.group));

export default {
	category: TAG_CATEGORY_JOB_SEARCH_JOB_LOCATION_CONTINENT_SLUG,
	TAG_CATEGORY_JOB_SEARCH_JOB_LOCATION_CONTINENT_SLUG,
	...LOCATION_CONTINENT,
	options,
	asTag: asJobSearchJobLocationContinentTag,
	asTagOption: asJobSearchJobLocationContinentTagOption,
	asJobSearchJobLocationContinentTag,
	asJobSearchJobLocationContinentTagOption,
};
