import { ENGLISH_LANG } from '../../../../constants/constant';
import CompanyRichTextEditor from '../shared/CompanyRichTextEditor';

const CompanyDiversityActionsEditor = (
	{
		company,
		lang = ENGLISH_LANG,
		setEditorOpen,
	},
) => {
	const className = 'CompanyDiversityActionsEditor';

	return (
		<CompanyRichTextEditor
			company={company}
			lang={lang}
			title={<b>Diversity Actions</b>}
			field={'diversityActions'}
			setEditorOpen={setEditorOpen}
		/>
	);
};

export default CompanyDiversityActionsEditor;
