module.exports = {
	env: process.env.REACT_APP_ENV || 'dev', // "recette", "production", "developpement", "test"
	serverLocal: {
		server: 'http://localhost:',
		port: process.env.REACT_APP_PORT || '8080',
	},
	isDevEnv: !process.env.REACT_APP_ENV || process.env.REACT_APP_ENV.startsWith('dev'),
	isNotDevEnv: !(!process.env.REACT_APP_ENV || process.env.REACT_APP_ENV.startsWith('dev')),
	isDevVersion: !process.env.REACT_APP_ENV || process.env.REACT_APP_ENV === 'dev',
	isDevStagingVersion: !process.env.REACT_APP_ENV || process.env.REACT_APP_ENV === 'dev-staging',

	isStagingEnv: process.env.REACT_APP_ENV.startsWith('staging'),
	isStagingVersion: process.env.REACT_APP_ENV === 'staging',
	isStagingDemoVersion: process.env.REACT_APP_ENV === 'staging-demo',

	isProdEnv: process.env.REACT_APP_ENV.startsWith('prod'),
	isNotProdEnv: !process.env.REACT_APP_ENV.startsWith('prod'),
	isNotProdVersion: process.env.REACT_APP_ENV !== 'prod',
	isProdVersion: process.env.REACT_APP_ENV === 'prod',
	isProdStaffVersion: process.env.REACT_APP_ENV === 'prod-staff',
	isNotProdStaffVersion: process.env.REACT_APP_ENV !== 'prod-staff',

	webRoot: process.env.REACT_APP_WEB_ROOT || 'https://www.50intech.com',
	adminRoot: process.env.REACT_APP_ADMIN_ROOT || 'http://localhost:3000',
	adminRootProd: process.env.REACT_APP_ADMIN_ROOT_PROD || 'https://admin.50intech.com',
	appRoot: process.env.REACT_APP_APP_ROOT || 'http://localhost:3001',
	appRootProd: 'https://app.50intech.com',
	apiRoot: process.env.REACT_APP_API_ROOT || (process.env.REACT_APP_APP_ROOT === 'https://app.50intech.com' ? 'https://api2.50intech.com' : null) || 'http://localhost:8080',
	apiRootProd: 'https://api.50intech.com',
	storagePublicRoot: process.env.REACT_APP_STORAGE_PUBLIC_ROOT || 'https://storage.googleapis.com/fit-staging-public',
	storagePublicRootProd: process.env.REACT_APP_STORAGE_PUBLIC_ROOT_PROD || 'https://storage.googleapis.com/fit-prod-public',
	secretKey: process.env.REACT_APP_SECRET_KEY || 'secret_key_goes_here',
	secretKeyVerif: process.env.REACT_APP_SECRET_KEY_VERIF || 'secret_key_verif',
	momentHoursOffset: !process.env.REACT_APP_ENV || process.env.REACT_APP_ENV === 'developpement' ? 0 : 2,
	jobClickSecretKey: 'jobClickSecretKeyILove50',
	mongoDb: {
		server: {
			host: process.env.REACT_APP_DB_SERVER || 'mongodb://127.0.0.1:27017',
			database: '50intech',
		},
		poolsize: 10,
	},
	segment: {
		sourceId: process.env.REACT_APP_SEGMENT_SOURCE_ID || 'p9V2gfPvwbeapkz4iMpwkC',
		apiKey: process.env.REACT_APP_SEGMENT_API_KEY || 'As5T3mHefyuP7KDKFbaJnxvCe3GGYHpP',
		writeKey: process.env.REACT_APP_SEGMENT_WRITE_KEY || 'R23B74HUNuRkINaUXVvX53RLlsCALqWI',
	},
	customerio: {
		apiKey: process.env.REACT_APP_CUSTOMERIO_API_KEY || '87aa9ed895e571bc40378b7ade4f33e0',
	},
	pusher: {
		appId: process.env.REACT_APP_PUSHER_APP_ID || '1126492',
		key: process.env.REACT_APP_PUSHER_KEY || '21eab402bbd66658a6c2',
		secret: process.env.REACT_APP_PUSHER_SECRET || '2f49d4941f756142ea7f',
		cluster: process.env.REACT_APP_PUSHER_CLUSTER || 'eu',
		useTLS: true,
	},
	db: {
		host: process.env.REACT_APP_DB_HOST || 'localhost:27017',
		user: process.env.REACT_APP_DB_USER || '',
		password: process.env.REACT_APP_DB_PASSWORD || '',
		name: process.env.REACT_APP_DB_DATABASE || '50intech',
	},
	cloudinary: {
		cloud_name: process.env.REACT_APP_CLOUDINARY_NAME || 'dkatmtbbc',
		api_key: process.env.REACT_APP_CLOUDINARY_API_KEY || '327449463663916',
		api_secret: process.env.REACT_APP_CLOUDINARY_API_SECRET || 'CkGV43QRTB5JCP8mlfpk1B5dnNY',
		upload_preset: process.env.REACT_APP_CLOUDINARY_UPLOAD_PRESET || 'qus2f9mq',
		upload_preset_profile: process.env.REACT_APP_CLOUDINARY_UPLOAD_PRESET_PROFILE || 'profile',
		upload_preset_company: process.env.REACT_APP_CLOUDINARY_UPLOAD_PRESET_COMPANY || 'company',
	},
	mailjet: {
		api_key: process.env.REACT_APP_MAILJET_API_KEY || '1fde0ed0c60e082aae33980c49239236',
		secret_key: process.env.REACT_APP_MAILJET_SECRET_KEY || 'd1d6162948532dad49787c5a4b7e7a09',
		admin_email: process.env.REACT_APP_MAILJET_ADMIN_EMAIL || (process.env.REACT_APP_ENV === 'test' ? 'tech+test@50intech.com' : (process.env.REACT_APP_ENV === 'demo' ? 'tech+demo@50intech.com' : 'tech+dev@50intech.com')),
		mailing_list_id: process.env.REACT_APP_MAILJET_MAILING_LIST_ID || 11210,
		localhost_email: process.env.REACT_APP_MAILJET_LOCALHOST_EMAIL || 'localhost@50intech.com',
		test_email: process.env.REACT_APP_MAILJET_TEST_EMAIL || 'test@50intech.com',
	},
	firebaseAdmin: {
		type: 'service_account',
		project_id: process.env.REACT_APP_FIREBASE_PROJECT_ID || 'intech-dev',
		api_key: process.env.REACT_APP_FIREBASE_API_KEY || 'AIzaSyCVfGnK8cibpIYvA3uBXwYypJSCVwWVdfo',
		private_key_id: process.env.REACT_APP_FIREBASE_PRIVATE_KEY_ID || '487a2493dd659664854605c9977da8cc0c4bff5a',
		private_key: process.env.REACT_APP_FIREBASE_PRIVATE_KEY || '-----BEGIN PRIVATE KEY-----\nMIIEvQIBADANBgkqhkiG9w0BAQEFAASCBKcwggSjAgEAAoIBAQCK8maMUVzBsUCf\nKo+zBfSiO9msqn9IzRmqEZiafZyN97t+S2yapDf7mS/SP/kquPYXKjJWpZnBR8CE\njiT+RhZ4Diq6E2Ukx6WafyWcUmF5mmNxhWGSZYWD8W23+IZWcZPnXCRZ9ZZEKT14\nRqK87Ze+yQRMFIGDFFSVfGj0z5nBR16CVWYR/SpR0+jkwk+TC5HF6m6jLK7HDR7P\nTeHppNgxzOuQrr4KjYgGMlYR20zJYXPREsmHkuYCX6J1EiteZK+hcsNZKs/0ZdJK\nVUAeJgSOJv4MW6BFw5HKTxohE3nD5bzG26Lx7fOSdTiIY3F3XEixE9AM5zst1atx\n+4H1aBSlAgMBAAECggEACtmJy2wxB4jtSGB87xhIdYCh6zs3DDrDxcVw+qNTXbyZ\n/e8q0FqBapqYIt1O+hiOUtm/K4fr3S1aqZ8l4Vsctww4JuaLz9btJgbGq5G9Pc8h\n88PcDh/yUI7CKAr2hnqA96B40DN+o9zqiWFOpN0Sq9mrhbtWL6OfRTr9QRyS34Yz\nNFPZaxS345ctFFGCkGktgW1tu9BSCaFPThmRZdOixy2GI24xve1xexsLodlWTnUK\nziYFdEu2+LL25OeqXTWQp1pCHfPeham5DDWvvcvio/1vzZmDpiUQiiGVx8ne/jZP\ntmTcIbiGKhYHPt59Jb+X68BUBTf5lC/g0YOPsAMqMwKBgQC/fGCH/KWgNFFNp8hR\no9oMr/Irpd5PwYiAwDo/4H4f8gwfAVe74Z3/tf+INsGjJWhJrsrmFTmeqqy987yD\nZpuDyq0kET60irh79Zxxbwc5SGykA0sAN2SHy4u4TNdkEmO7wl1gPynSqxc+m2DS\n49fzjhQF1kvxEdKiN2kv34x6lwKBgQC5wovA/2Y/idW2pt+vWZVSoYQlnO5uiBbk\nX2wZ7K+IAoIT3zy8q317OryVojlYaUCPsu9j3lfQifOYMxGCZ+TkQhQpfOYa/GlX\n72K+Jt9XWNVZqI1MdRXxstHkxjhk0+82CH3VhBXmMdGdvGZXoTYpMrHDYfP3yT+j\n70pzUqt+IwKBgHfZZiXXGOczt6uOF1AZ6PFF8qIlGAb9T+2d4xd18BsYiUOiuIHs\nzdJt3DkqZHozgJmVoILvx/eqRaJCerwXrcCIS2QmswOXS66yZK+O2Yud6/mf4gKJ\nqQ9hc9rvpWSKKggrt/v0rL2hvlO+NpTobJKRW7DnlvKUwCwt2FVRawCTAoGBAIr4\n1yFOcap7bh21/obrGGG3YGvbWi3rNmUoVnGWt0OjzLkdPA1Spw/4Q3oW4ZwZMinP\njnVCtKPZcRqeqizLmVyDax4WyPfLeuxEtbTY1CI+3W60QPHAbuhiBdGH0kzsmXvt\nImcI6HFNOCsv9cj9XM25lXA86g6oyynULfjVEmPNAoGAP2Ktj/4Orm2PDS4b4A4L\nGQdkhoilV4qXdMB0PTzrpWvvT8yY/hWTglzw9luvw/hZzCmFfxwQDbyhGHJR1Nh/\nsV6jcdBuJuxWNshuavhl6Ru2Wp5aCT0acTtiyW9D8X0Cf67EfQ/v4/u1kiDx/k7D\n1i4g19ndmOulMNMfM1/OD9s=\n-----END PRIVATE KEY-----\n',
		// private_key: (process.env.REACT_APP_FIREBASE_PRIVATE_KEY || '-----BEGIN PRIVATE KEY-----\nMIIEvQIBADANBgkqhkiG9w0BAQEFAASCBKcwggSjAgEAAoIBAQCK8maMUVzBsUCf\nKo+zBfSiO9msqn9IzRmqEZiafZyN97t+S2yapDf7mS/SP/kquPYXKjJWpZnBR8CE\njiT+RhZ4Diq6E2Ukx6WafyWcUmF5mmNxhWGSZYWD8W23+IZWcZPnXCRZ9ZZEKT14\nRqK87Ze+yQRMFIGDFFSVfGj0z5nBR16CVWYR/SpR0+jkwk+TC5HF6m6jLK7HDR7P\nTeHppNgxzOuQrr4KjYgGMlYR20zJYXPREsmHkuYCX6J1EiteZK+hcsNZKs/0ZdJK\nVUAeJgSOJv4MW6BFw5HKTxohE3nD5bzG26Lx7fOSdTiIY3F3XEixE9AM5zst1atx\n+4H1aBSlAgMBAAECggEACtmJy2wxB4jtSGB87xhIdYCh6zs3DDrDxcVw+qNTXbyZ\n/e8q0FqBapqYIt1O+hiOUtm/K4fr3S1aqZ8l4Vsctww4JuaLz9btJgbGq5G9Pc8h\n88PcDh/yUI7CKAr2hnqA96B40DN+o9zqiWFOpN0Sq9mrhbtWL6OfRTr9QRyS34Yz\nNFPZaxS345ctFFGCkGktgW1tu9BSCaFPThmRZdOixy2GI24xve1xexsLodlWTnUK\nziYFdEu2+LL25OeqXTWQp1pCHfPeham5DDWvvcvio/1vzZmDpiUQiiGVx8ne/jZP\ntmTcIbiGKhYHPt59Jb+X68BUBTf5lC/g0YOPsAMqMwKBgQC/fGCH/KWgNFFNp8hR\no9oMr/Irpd5PwYiAwDo/4H4f8gwfAVe74Z3/tf+INsGjJWhJrsrmFTmeqqy987yD\nZpuDyq0kET60irh79Zxxbwc5SGykA0sAN2SHy4u4TNdkEmO7wl1gPynSqxc+m2DS\n49fzjhQF1kvxEdKiN2kv34x6lwKBgQC5wovA/2Y/idW2pt+vWZVSoYQlnO5uiBbk\nX2wZ7K+IAoIT3zy8q317OryVojlYaUCPsu9j3lfQifOYMxGCZ+TkQhQpfOYa/GlX\n72K+Jt9XWNVZqI1MdRXxstHkxjhk0+82CH3VhBXmMdGdvGZXoTYpMrHDYfP3yT+j\n70pzUqt+IwKBgHfZZiXXGOczt6uOF1AZ6PFF8qIlGAb9T+2d4xd18BsYiUOiuIHs\nzdJt3DkqZHozgJmVoILvx/eqRaJCerwXrcCIS2QmswOXS66yZK+O2Yud6/mf4gKJ\nqQ9hc9rvpWSKKggrt/v0rL2hvlO+NpTobJKRW7DnlvKUwCwt2FVRawCTAoGBAIr4\n1yFOcap7bh21/obrGGG3YGvbWi3rNmUoVnGWt0OjzLkdPA1Spw/4Q3oW4ZwZMinP\njnVCtKPZcRqeqizLmVyDax4WyPfLeuxEtbTY1CI+3W60QPHAbuhiBdGH0kzsmXvt\nImcI6HFNOCsv9cj9XM25lXA86g6oyynULfjVEmPNAoGAP2Ktj/4Orm2PDS4b4A4L\nGQdkhoilV4qXdMB0PTzrpWvvT8yY/hWTglzw9luvw/hZzCmFfxwQDbyhGHJR1Nh/\nsV6jcdBuJuxWNshuavhl6Ru2Wp5aCT0acTtiyW9D8X0Cf67EfQ/v4/u1kiDx/k7D\n1i4g19ndmOulMNMfM1/OD9s=\n-----END PRIVATE KEY-----\n')
		//   .replace(/\\n/g, '\n'),
		// private_key: '-----BEGIN PRIVATE KEY-----\nMIIEvAIBADANBgkqhkiG9w0BAQEFAASCBKYwggSiAgEAAoIBAQCnUJp+pq9WJnJf\n8C3yIR84zIjVy+rhVNPDGyEBfgPtdmEAg50K7vc/AD8M3VeyL7aTtJxuWsbd7nna\nY9I70ftRBRWs0nWimTtQcnPYEXiVv7GiUUKh/ch7rsrlf7dWZD95L/eJ/ToMYn5k\nKQsxiY+OfJiUEEuycvToPMFnz9npmEUIKJMDB8kjFqtBPbb2tsRXnYDIWL/Y1tmu\nhLknA71PUNVCWqoERjo+rqoYezGQOAk4yAOrVfkc+bgTKRH0ueB+3EAEvIL+1Xy2\n5R+iwXvHJgc36QD7fSsRPqgJLx9JoHATPqbNXVm/l1npV54Zjx0mhciNQffYfGPy\nxOoatciRAgMBAAECggEAE4Ul4SzVO/nUUjkVQzB2Egxa0oat6ogZBKQ/n4ryLlwn\nSQYR5lqm4eTHdlrEhndml6j0rzNMDExsTvxiZLRE29j6BGWxb7zs3J/dwBGZve7V\nbJiKa5fOMg/cgBX8b8nWVe8wtBqlERjUoh7fvXHNG9SRgIS+MB2VURr1fYUUaVo3\n/CKp7tNxYfKuvJOsrU0ZYNZ+aP/xAB8XtB0V4BQJelscnAOBI6QulYKv5hxcCPQa\nRn/Xhsed/c5gSO1a2I6pVMmEmkMSyj5w11L6TwepzL2Epfddfvc0OTC2tic99U0+\nPnw4YQYOhUCR9AGneSr/L76s2S0poaBdwIe68P9hGQKBgQDkhrTr8sWP83GwUYGf\nntTfAYpIbOAlqEwXyvuJBqfdrNyjX0NSEIsMDy3kgtSSW3gUeFLTzIBWNEQvRfPc\nqHPVS43rPQgVXmrFeOaOscMYGkzrbO3g70+iyw1QaE9Jv4J5PP/FTDVPQhgxoSc1\ncOGo1w2E8PaQESnSden0hpReKwKBgQC7bgLAH/LunA45K4TfIOnGVZxRno5uqVci\neeicXeai0aSk0zeUyH7n85ht+FiJu+B1gG/lYvs+aqwW67Ao+svF2Fd1ZgQ2ifFo\nxO5flty+/9aVnbaj0eWB1SCr+RD7OLd1eSa+xqZIHpLN3MscbWZCu56uWs3js28/\n1GS4WZgSMwKBgHO7nMiZjGAYpAztWj4iTCAcQZHoQaQQlWi/TZ/ZlQ34y0yZpBhd\ndG3JCi7d6xWr6SZEwX2t5ZjCo31ladjoHrF4sAjgQcXAqUYUsn61m94LA8V+xwHR\ncH07ZCTCjtUEfek0cTvUGnNnP6Vyvc6cukutjZU0C0puxsVlHfTOazk9AoGAGb05\n03iPvATiTyeSz7GNaRdIqEzF8px+wtVLBG5AxgtUOO5oq539D418WV3JiuCqKnjA\n5Rd6uU+c4mjrgDUw1rKnEBA6c+LtrB2fPrFRD0nDBjjqZATnJ7VFD1jc5CMdzpwc\nngZZv32zW2H+4qCDcpahbAA8VhDGLlXUnxxurdECgYA1zCK53Cj0pq3wtT6OgWAO\nGbUS9h/ZhW2nfDpYltiPgmZj7aFAr1lio4lF7qcoi6uGFm7vcGAifOZ/UnWUUZ/H\nvXiCki0E1HdPmQ3jwHwQfCWB9SAJU+y6iugrFs6mh4gcQiQphr1Q1gVtvuaSQM2Q\nmuHFl5tRlmmdQ6JZzbmsNw==\n-----END PRIVATE KEY-----\n',
		client_email: process.env.REACT_APP_FIREBASE_CLIENT_EMAIL || 'firebase-adminsdk-98sjh@intech-dev.iam.gserviceaccount.com',
		client_id: process.env.REACT_APP_FIREBASE_CLIENT_ID || '101419208549750465978',
		auth_uri: 'https://accounts.google.com/o/oauth2/auth',
		token_uri: 'https://oauth2.googleapis.com/token',
		auth_provider_x509_cert_url: 'https://www.googleapis.com/oauth2/v1/certs',
		client_x509_cert_url: process.env.REACT_APP_FIREBASE_X509_CERT_URL || 'https://www.googleapis.com/robot/v1/metadata/x509/firebase-adminsdk-98sjh%40intech-dev.iam.gserviceaccount.com',
	},
	google: {
		spreadsheets: {
			connectRequests: process.env.REACT_APP_GOOGLE_SPREADSHEET_CONNECT_REQUESTS || '1Fa6b-SYx1SDKLcgEf-izqNB6S7jcQtUwBOW50KfCQ94',
			users: process.env.REACT_APP_GOOGLE_SPREADSHEET_USERS || '1YQ176hEx0oWxxpphOf65Y-9aI_ENetiq9X5QLRRMmF8',
		},
		maps: {
			apiKey: 'AIzaSyAcq30r0kQYvBGIdJnS0nv703QWFx-XIqQ',
		},
	},
	firebaseDbUrl: process.env.REACT_APP_FIREBASE_DB_URL || 'https://intech-dev.firebaseio.com',
	slack: {
		webHook: process.env.REACT_APP_SLACK_WEBHOOK || 'https://hooks.slack.com/services/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',
		channels: {
			default: process.env.REACT_APP_SLACK_CHANNEL_DEFAULT || '@jlondeix',
			error: process.env.REACT_APP_SLACK_CHANNEL_ERROR || '#bots-dev',
			admin: process.env.REACT_APP_SLACK_CHANNEL_ADMIN || '#bots-dev',
			session: process.env.REACT_APP_SLACK_CHANNEL_SESSION || '#bots-dev',
			mailing: process.env.REACT_APP_SLACK_CHANNEL_MAILING || '#bots-dev',
			staff: process.env.REACT_APP_SLACK_CHANNEL_STAFF || '#bots-dev',
			stats: {
				'prod': '#bots-stats',
				'staging': '#bots-staging',
				'staging-demo': '#bots-staging',
			}[process.env.REACT_APP_ENV] || '#bots-dev',
			analytics: process.env.REACT_APP_SLACK_CHANNEL_ANALYTICS || '#bots-dev',
			subscribe: process.env.REACT_APP_SLACK_CHANNEL_SUBSCRIBE || '#bots-dev',
			login: process.env.REACT_APP_SLACK_CHANNEL_LOGIN || '#bots-dev',
			log: process.env.REACT_APP_SLACK_CHANNEL_EVENT || '#bots-dev',
			profile: process.env.REACT_APP_SLACK_CHANNEL_PROFILE || '#bots-dev',
			connect: process.env.REACT_APP_SLACK_CHANNEL_CONNECT || '#bots-dev',
			request: process.env.REACT_APP_SLACK_CHANNEL_REQUEST || '#bots-dev',
			discuss: process.env.REACT_APP_SLACK_CHANNEL_DISCUSS || '#bots-dev',
			content: process.env.REACT_APP_SLACK_CHANNEL_CONTENT || '#bots-dev',
			job: process.env.REACT_APP_SLACK_CHANNEL_JOB || '#bots-dev',
			genderscore: process.env.REACT_APP_SLACK_CHANNEL_GENDERSCORE || '#bots-dev',
			ums: process.env.REACT_APP_SLACK_CHANNEL_UMS || '#bots-dev',
		},
	},
};
