import { ApiOutlined } from '@ant-design/icons';
import ConfigureAtsSetupPage from '../../containers/configure/ConfigureAtsSetupPage';
import { PERMS_GRANT_STAFF } from '../menu.utils';
import MenuBase from '../MenuBase';

export const ConfigureAtsSetupIcon = ApiOutlined;

class ConfigureAtsSetupMenu extends MenuBase {
	static props = {
		name: 'configure-ats-setup',
		path: '/configure/ats-setup',
		aliases: ['^/ats-setup$'],
		label: 'ATS Setup',
		icon: <ConfigureAtsSetupIcon />,
		iconLegacy: '⚙️',
		Component: ConfigureAtsSetupPage,
		onEnterProps: { shouldLoadAtsData: true },
		perms: PERMS_GRANT_STAFF,
	};

	constructor() {
		super(ConfigureAtsSetupMenu.props);
	}
}

export default ConfigureAtsSetupMenu;
