import AtsPipelinesPage from '../../../containers/staff/ats/AtsPipelinesPage';
import { PERMS_GRANT_ADMIN } from '../../menu.utils';
import MenuBase from '../../MenuBase';
import { ApartmentOutlined } from '@ant-design/icons';

export const StaffAtsPipelinesIcon = ApartmentOutlined;

class StaffAtsPipelinesMenu extends MenuBase {
	static props = {
		name: 'staff-ats-pipelines',
		path: '/staff/ats/pipelines',
		label: 'ATS Pipelines',
		icon: <StaffAtsPipelinesIcon />,
		iconLegacy: '🥓️',
		Component: AtsPipelinesPage,
		onEnterProps: { shouldLoadAtsData: true },
		perms: PERMS_GRANT_ADMIN,
	};

	constructor() {
		super(StaffAtsPipelinesMenu.props);
	}
}

export default StaffAtsPipelinesMenu;
