import feedbackService from '../services/feedback';
import * as ACTION_TYPES from './types';
import { actionWithLoader } from './utils';
import { localDebug } from '../utils/LocalDebug';

export const getAllFeedbacks = (options = {}) => {
	localDebug('getAllFeedbacks', options);
	return actionWithLoader(async (dispatch, getState) => {
		try {
			localDebug('getAllFeedbacks', options);
			const { data } = await feedbackService.findAll(options);
			dispatch({
				type: ACTION_TYPES.FEEDBACKS_LOADED,
				feedbacks: data.feedbacks,
			});
		} catch (error) {
			localDebug(__filename.split('/').shift(), error);
		}
	});
};

export const addFeedback = async (data) => {
	try {
		const { data: { feedback } } = await feedbackService.create(data);
		return feedback;
	} catch (error) {
		localDebug(__filename.split('/').shift(), error);
	}
};

export const updateFeedback = (feedbackId, data) => {
	return async (dispatch) => {
		try {
			await feedbackService.update(feedbackId, data);
			dispatch(getAllFeedbacks());
		} catch (error) {
			localDebug(__filename.split('/').shift(), error);
		}
	};
};

export const removeFeedback = (feedback) => {
	return actionWithLoader(async () => {
		try {
			await feedbackService.remove(feedback);
		} catch (error) {
			localDebug(__filename.split('/').shift(), error);
		}
	});
};
