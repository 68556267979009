import { isStringTypeValidator } from '../../../utils/isStringTypeValidator';
import { innerObjPropExtractor } from '../../property/property-extractor';
import { innerObjPropMapper } from '../../property/property-mapper';

const USER_PROPERTY_PROFILE_LASTNAME_KEY = 'profile-lastname';

const USER_PROPERTY_PROFILE_LASTNAME_FREE = true;

const USER_PROPERTY_PROFILE_LASTNAME_VALIDATORS = [
	isStringTypeValidator,
];

const USER_PROPERTY_PROFILE_LASTNAME_FIELD = 'lastName';
const USER_PROPERTY_PROFILE_LASTNAME_MAPPER = innerObjPropMapper(USER_PROPERTY_PROFILE_LASTNAME_FIELD);
const USER_PROPERTY_PROFILE_LASTNAME_EXTRACTOR = innerObjPropExtractor(USER_PROPERTY_PROFILE_LASTNAME_FIELD);

export default {
	key: USER_PROPERTY_PROFILE_LASTNAME_KEY,
	USER_PROPERTY_PROFILE_LASTNAME_KEY,
	validators: USER_PROPERTY_PROFILE_LASTNAME_VALIDATORS,
	USER_PROPERTY_PROFILE_LASTNAME_VALIDATORS,
	free: USER_PROPERTY_PROFILE_LASTNAME_FREE,
	USER_PROPERTY_PROFILE_LASTNAME_FREE,
	mapper: USER_PROPERTY_PROFILE_LASTNAME_MAPPER,
	USER_PROPERTY_PROFILE_LASTNAME_MAPPER,
	extractor: USER_PROPERTY_PROFILE_LASTNAME_EXTRACTOR,
	USER_PROPERTY_PROFILE_LASTNAME_EXTRACTOR,
	field: USER_PROPERTY_PROFILE_LASTNAME_FIELD,
	USER_PROPERTY_PROFILE_LASTNAME_FIELD,
};
