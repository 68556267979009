import { LOGO_50INTECH_SVG } from '../../constants/logos';

const Logo50inTech = ({
	size = 24,
	height,
	src,
	color,
	...props
}) => {
	const imgSrc = src || LOGO_50INTECH_SVG;
	if (color) {
		return <div
			className='logo50'
			{...props}
			style={{
				width: size,
				height: height || size,
				backgroundColor: color,
				WebkitMask: `url(${imgSrc}) no-repeat center`,
				mask: `url(${imgSrc}) no-repeat center`,
				transition: 'all 0.2s ease-out',
				...props?.style,
			}}
		/>;
	}
	return <img
		width={size}
		height={height || size}
		loading='lazy'
		src={imgSrc}
		alt='Logo 50inTech'
		{...props}
	/>;
};

export default Logo50inTech;
