import UMSStaffPage from '../../containers/ums/UMSStaffPage';
import { PERMS_GRANT_NOT_PROD_VERSION, PERMS_GRANT_STAFF } from '../menu.utils';
import MenuBase from '../MenuBase';
import { SettingOutlined } from '@ant-design/icons';

class UMSStaffMenu extends MenuBase {
	name = 'ums-staff';

	label = 'UMS Staff';

	icon = <SettingOutlined />;

	iconLegacy = '⚙️';

	path = '/ums/staff';

	aliases = ['^/ums/staff$'];

	Component = UMSStaffPage;

	perms = {
		...PERMS_GRANT_NOT_PROD_VERSION,
		umsEnabled: true,
	};
}

export default UMSStaffMenu;
