import {
	createContext, useContext, useEffect, useState,
} from 'react';
import { UserStoreContext } from '../UserStoreProvider';
import { documentId } from '../../utils/common';

export const UserModalContext = createContext();

const UserModalProvider = ({
	children,
}) => {
	const className = 'UserModalProvider';

	const [open, setOpen] = useState(false);
	const [users, setUsers] = useState([]);
	const [user, setUser] = useState();
	const [userModel, setUserModel] = useState();
	const [onOpen, setOnOpen] = useState();
	const [onClose, setOnClose] = useState();
	const { fetchUser } = useContext(UserStoreContext);

	const fetchUserData = async (newUser) => {
		const userData = await fetchUser(documentId(newUser));
		setUserModel(userData);
	};
	useEffect(() => {
		const newUser = users?.[0];
		setUser(newUser);
		fetchUserData(newUser);
	}, [users]);

	return (
		<UserModalContext.Provider
			value={{
				open,
				setOpen,
				users,
				setUsers,
				user,
				setUser,
				userModel,
				setUserModel,
				onOpen,
				setOnOpen,
				onClose,
				setOnClose,
			}}
		>
			{children}

		</UserModalContext.Provider>
	);
};

export default UserModalProvider;
