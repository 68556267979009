import { useTheme } from '@emotion/react';

function LangFlagColorTag(
	{
		lang = 'en',
	},
) {
	const theme = useTheme();
	const langParams = {
		fr: { label: '🇫🇷 FR', background: theme.color.paleblue, color: theme.color.blue },
		en: { label: '🇬🇧 EN', background: theme.color.orangeLightest, color: theme.color.orange },
	};
	const currentLangParam = langParams[lang] || {
		label: lang?.toUpperCase(), background: theme.color.lightgrey, color: theme.color.darkgrey,
	};
	return (
		<span
			style={{
				fontSize: 10,
				borderRadius: 20,
				padding: '1px 6px',
				background: currentLangParam?.background,
				color: currentLangParam?.color,
			}}
		>
			<b>{currentLangParam?.label || lang?.toUpperCase()}</b>
		</span>
	);
}

export default LangFlagColorTag;
