import ReactJson from 'react-json-view';

const SourcingATSHistory = (
	{
		sourcing,
	},
) => {
	return (
		sourcing?.atsSync
			? <ReactJson
				src={sourcing?.atsSync}
				style={{ fontSize: 10 }}
				sortKeys={true}
				collapsed={2}
				displayDataTypes={false}
				name={false}
				quotesOnKeys={false}
			/>
			: null
	);
};

export default SourcingATSHistory;
