import { useTheme } from '@emotion/react';
import { Dropdown, Menu, Spin } from 'antd';
import { useEffect, useState } from 'react';
import { FaEdit } from 'react-icons/fa';
import Link from '../app/Link';
import companyService from '../../services/company';
import { documentId, writeLog } from '../../utils/common';
import { localDebug } from '../../utils/LocalDebug';
import UserStub from '../user/stub/UserStub';

const SourcingManagerDropdown = (
	{
		sourcing = null,
		onSelect,
		children,
	},
) => {
	const theme = useTheme();

	const previousManagerId = sourcing?.managerId;
	const [sourcingSelected, setSourcingSelected] = useState(null);
	const [options, setOptions] = useState([]);
	const [isLoading, setIsLoading] = useState(false);

	const handleSubmitManager = (e) => {
		localDebug('handleSubmitManager');
		const userId = e.key;
		writeLog('confirm user manager sourcing', { sourcing: documentId(sourcing), managerId: userId });
		if (onSelect) onSelect(sourcing, userId, previousManagerId);
	};

	const openManagerDropdown = () => {
		writeLog('open edit user manager sourcing', { sourcing: documentId(sourcing) });
		setSourcingSelected(sourcing);
	};

	useEffect(() => {
		const fetchCompanyManagers = async (companyId) => {
			localDebug('fetchCompanyManagers', companyId);
			setIsLoading(true);
			const { data: { users } } = await companyService.getManagers(companyId);
			setOptions(users);
			setIsLoading(false);
		};

		if (sourcingSelected) {
			localDebug(sourcingSelected, sourcingSelected?.companyId);
			fetchCompanyManagers(sourcingSelected?.companyId);
		}
	}, [sourcingSelected]);

	const menu = (
		<Menu
			onClick={handleSubmitManager}
			mode="horizontal"
			style={{ maxHeight: 300, overflow: 'auto' }}
		>{isLoading
				? <Spin size="large"/>
				: options.map((u) => <Menu.Item key={documentId(u)}>
					<UserStub user={u} withLocation={false} withIsUnviewed={false}/>
				</Menu.Item>)
			}
		</Menu>
	);

	return (
		<Dropdown overlay={menu} trigger={['click']}>
			<Link onClick={openManagerDropdown} type="link">
				{children || <span style={{ fontSize: 10 }}><FaEdit style={{ marginBottom: -2 }} /> Edit manager</span>}
				{/* Edit managers */}
			</Link>
		</Dropdown>
	);
};

export default SourcingManagerDropdown;
