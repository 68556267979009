import { useTheme } from '@emotion/react';
import { NOT_PROVIDED_YET_LABEL } from '../../../../constants/constant';
import { openExternalUrl } from '../../../../utils/common';
import { writeRecruiterLog } from '../../../../actions/logging';
import UrlIcon from '../../../app/UrlIcon';
import UserService from '../../../../services/user';
import UserLinks from '../../profile/UserLinks';
import { downloadResume, openExternalUserLink } from '../../../../actions/users';

const UserResumeContactCardBody = (
	{
		user,
		notProvided = NOT_PROVIDED_YET_LABEL,
		...props
	},
) => {
	const theme = useTheme();

	return <UserLinks userModel={user} {...props}/> || notProvided;
};

export default UserResumeContactCardBody;
