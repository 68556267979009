import FunnelColumn from './FunnelColumn';
import GridCardBase from '../../../app/grid/GridCardBase';
import { Skeleton } from 'antd';
import FunnelUserCard from './FunnelUserCard';
import {
	memo, useContext, useMemo, useState,
} from 'react';
import { LocalDebug } from '../../../../utils/LocalDebug';
import PopinContact from '../../../../containers/staff/PopinContact';
import CoachEditModal from '../../../../containers/staff/CoachEditModal';
import UserService from '../../../../services/user';
import { NotificationContext } from '../../../app/notification/NotificationProvider';
import Droppable from './Droppable';
import FunnelUserExclusionModal from './FunnelUserExclusionModal';
import UserModel from '../../../../models/UserModel';
import { documentId } from '../../../../utils/common';

const FunnelUserColumn = (
	{
		id,
		funnel,
		columns,
		title,
		details,
		cardOptions,
		menu,
		filterOptions,
		onUserFunnelEdited,
		refreshColumn = 0,
		style = {},
		...props
	},
) => {
	const className = 'FunnelUserColumn';

	LocalDebug.logInfo({ className }, { id, refreshColumn });
	const { notifyLoading, notifySuccess } = useContext(NotificationContext);
	const [openExclusionPopin, setOpenExclusionPopin] = useState(false);
	const [openPopinContact, setOpenPopinContact] = useState(false);
	const [openPopinEditCoach, setOpenPopinEditCoach] = useState(false);
	const [selectedUser, setSelectedUser] = useState(null);

	const skeletonCard = (
		<GridCardBase style={{ padding: 30, height: 80, borderRadius: 5 }}>
			<Skeleton active style={{ width: '100%', height: 80, borderRadius: 5 }} />
		</GridCardBase>
	);

	const handleContactUser = (user) => {
		LocalDebug.logInfo({ className, method: 'handleContactUser' });
		setSelectedUser(user);
		setOpenPopinContact(true);
	};

	const handleEditCoach = (user) => {
		LocalDebug.logInfo({ className, method: 'handleEditCoach' });
		setSelectedUser(user);
		setOpenPopinEditCoach(true);
	};

	const handleUserIsReachable = async (user, value) => {
		const method = 'handleUserIsReachable';
		LocalDebug.logInfo({ className, method }, 'before', { user, value });
		const key = `${method}:${documentId(user)}`;
		notifyLoading({
			key,
			description: <span>Setting <b>{user.firstName}</b> as <b>{value ? 'reachable' : 'unreachable'}</b> by recruiters</span>,
		});
		await UserService.updateProperty(documentId(user), { key: 'career-is-reachable-by-recruiters', value });
		notifySuccess({
			key,
			description: <span>Successfully set as <b>{user.firstName}</b> as <b>{value ? 'reachable' : 'unreachable'}</b> by recruiters</span>,
			duration: 2,
		});
		user.career = {
			...user.career || {},
			isReachableByRecruiters: !(new UserModel(user).getCareerIsReachableByRecruiters()),
		};
		// onUserFunnelEdited?.(id);
		LocalDebug.logInfo({ className, method }, 'after', { user, value });
	};

	const handleUserFunnel = async (user, funnel, value) => {
		LocalDebug.logInfo({ className, method: 'handleUserFunnel' }, { user, funnel, value });
		if (funnel === 'funnel.exclusion') {
			setSelectedUser(user);
			setOpenExclusionPopin(true);
			return;
		}
		notifyLoading({
			key: `handleUserFunnel:${documentId(user)}`,
			description: <span>Moving <b>{user.firstName}</b> to <b>{value}</b></span>,
		});
		await UserService.updateFunnel(documentId(user), { funnel, value });
		notifySuccess({
			key: `handleUserFunnel:${documentId(user)}`,
			description: <span>Successfully moved <b>{user.firstName}</b> to <b>{value}</b></span>,
			duration: 3,
		});
		onUserFunnelEdited?.(id);
	};

	const memoizedContent = useMemo(
		() => (
			<>
				<FunnelColumn
					id={id}
					title={title}
					details={details}
					cardRender={(row, isLoading) => (
						<FunnelUserCard
							columnId={id}
							funnel={funnel}
							columns={columns}
							title={title}
							menu={menu}
							user={row}
							isLoading={row}
							onContactUser={handleContactUser}
							onUserFunnel={handleUserFunnel}
							onEditCoach={handleEditCoach}
							onSetUserIsReachable={handleUserIsReachable}
							withCard={true}
							{...cardOptions || {}}
						/>
					)}
					skeletonCard={skeletonCard}
					filterOptions={filterOptions}
					refreshColumn={refreshColumn}
					style={style}
				/>
				<PopinContact
					open={openPopinContact}
					selectedUser={selectedUser}
					setOpen={setOpenPopinContact}
					onRefresh={() => onUserFunnelEdited?.(id)}
				/>
				<CoachEditModal
					open={openPopinEditCoach}
					selectedUser={selectedUser}
					setOpen={setOpenPopinEditCoach}
					onRefresh={() => onUserFunnelEdited?.(id)}
				/>
				<FunnelUserExclusionModal
					open={openExclusionPopin}
					user={selectedUser}
					setOpen={setOpenExclusionPopin}
					onSuccess={() => { onUserFunnelEdited?.(id); }}
				/>
			</>
		),
		[refreshColumn, filterOptions, openPopinContact, openPopinEditCoach, openExclusionPopin],
	);

	return (
		memoizedContent
	);
};

export default FunnelUserColumn;
