import { useContext, useEffect, useState } from 'react';
import { useTheme } from '@emotion/react';
import {
	FaChevronDown, FaChevronRight, FaChevronUp, FaCog, FaLock,
} from 'react-icons/fa';
import { useAuthContext } from '../../../hooks/useAuthContext';
import BlockNotProdEnv from '../../app/blocker/BlockNotProdEnv';
import {
	Button, Divider, Popconfirm, Space, Switch, Tabs,
} from 'antd';
import { Link } from 'react-router-dom';
import StaffViewSwitch from '../../app/StaffViewSwitch';
import { StaffToolsContext } from '../../../contexts/StaffToolsProvider';
import StaffManagerCompanyTab from './tabs/StaffManagerCompanyTab';
import StaffManagerGenderScoreTab from './tabs/StaffManagerGenderScoreTab';
import CompanyGenderScoreBadge from '../../company/genderscore/CompanyGenderScoreBadge';
import { useDispatch, useSelector } from 'react-redux';
import { getCompanySelected } from '../../../reducers/app';
import CompanyModel from '../../../models/CompanyModel';
import StaffManagerUserTab from './tabs/StaffManagerUserTab';
import config from '../../../config/config';
import HeaderCompanySelector from '../../app/layout/HeaderCompanySelector';
import BlockStaff from '../../app/blocker/BlockStaff';
import CompanyStub from '../../company/CompanyStub';
import StaffManagerUMSTab from './tabs/StaffManagerUMSTab';
import JsonPopoverLink from '../../app/debug/JsonPopoverLink';
import { ReloadOutlined } from '@ant-design/icons';
import { loadCompany } from '../../../actions/company';
import { documentId } from '../../../utils/common';

export const StaffManagerPanelDivider = ({
	children,
}) => {
	return <Divider
		orientation='left'
		position='left'
		style={{
			fontWeight: 'bold',
			margin: '10px 0 2px 0',
			fontSize: 14,
		}}>{children}
	</Divider>;
};

export const StaffManagerPanelTabContentWrapper = ({
	children,
	...props
}) => {
	return (
		<Space
			direction='vertical'
			size={0}
			style={{
				padding: '0 15px 15px',
				width: 'calc(100% - 30px)',
				fontSize: 12,
				...props?.style,
			}}
		>
			{children}
		</Space>
	);
};

export const StaffManagerPanelLink = ({
	label,
	onClick,
	confirm,
	confirmPlacement = 'top',
}) => {
	const link = (click) => (
		<Link
			onClick={click}
			style={{ fontSize: 11 }}
		>
			{label}
			&nbsp;
			<FaChevronRight style={{ marginBottom: -2 }} />
		</Link>
	);

	if (!confirm) return link(onClick);
	return (
		<Popconfirm
			placement={confirmPlacement}
			title={(
				<div
					style={{
						maxWidth: 500,
					}}
				>
					<b>{label}</b>
					<br />
					{confirm}
				</div>
			)}
			onConfirm={onClick}
		>
			{link()}
		</Popconfirm>
	);
};

const StaffManagerPanel = ({
	children,
	...props
}) => {
	const className = 'StaffManagerPanel';

	const theme = useTheme();

	const dispatch = useDispatch();
	const companySelected = useSelector(getCompanySelected);

	const { isStaff, isStaffView } = useAuthContext();

	const {
		staffManagerPanelPlacementLeft,
		setStaffManagerPanelPlacementLeft,
		staffManagerPanelOpen,
		setStaffManagerPanelOpen,
		panelContent,
	} = useContext(StaffToolsContext);

	const [companyModel, setCompanyModel] = useState();

	useEffect(() => {
		setCompanyModel(
			companySelected?._id
				? new CompanyModel(companySelected)
				: null,
		);
	}, [companySelected]);

	if (config.isProdEnv && !isStaff) return null;

	return (
		<>
			<div
				onClick={() => setStaffManagerPanelOpen(false)}
				style={{
					position: 'fixed',
					zIndex: 999,
					top: 0,
					left: 0,
					width: '100vw',
					height: '100vh',
					background: `rgba(0,0,0,${staffManagerPanelOpen ? 0.1 : 0})`,
					transition: 'all .2s ease-out .1s',
					...!staffManagerPanelOpen && { pointerEvents: 'none' },
				}}
			/>
			<div
				style={{
					position: 'fixed',
					zIndex: 1049,
					top: 0,
					left: 0,
					...props?.style,
				}}
			>
				<Space
					direction='vertical'
					size={0}
					style={{
						position: 'fixed',
						opacity: (staffManagerPanelOpen || isStaffView) ? 1 : 0,
						...staffManagerPanelPlacementLeft ? { left: 0 } : { right: 0 },
						...staffManagerPanelOpen
							? {
								background: 'white',
								boxShadow: '0 4px 10px 0px rgba(0,0,0,.2)',
							}
							: {
								background: 'transparent',
							},
						borderRadius: staffManagerPanelPlacementLeft
							? '0px 0px 6px 0px'
							: '0px 0px 0px 6px',
						overflow: 'hidden',
						transition: 'all .2s ease-out',
						paddingTop: 24,
					}}
				>

					{staffManagerPanelOpen && (
						<>
							<Space
								direction='vertical'
								size={4}
								style={{
									width: '100%',
									fontSize: 12,
									background: theme.color.lilacLightest,
									color: theme.color.lilac,
									padding: 10,
									paddingTop: 30,
									marginTop: -26,
								}}
							>
								<div>
									<Switch
										checked={staffManagerPanelPlacementLeft}
										onChange={() => setStaffManagerPanelPlacementLeft((p) => !p)}
										size='small'
										style={{ width: 36 }}
									/>
								&nbsp;<b>Panel on left side</b>
								</div>

								{isStaff && (
									<div>
										<StaffViewSwitch
											size='small'
										/>
										<span>&nbsp;<b>Staff view</b></span>
									</div>
								)}

							</Space>

							<div
								style={{
									padding: 0,
									maxHeight: 'calc(100vh - 92px)',
									overflow: 'scroll',
									// maxWidth: 230,
								}}>

								<BlockStaff
									withBorder={false}
									withTags={false}
									blockedContent={(
										<StaffManagerPanelTabContentWrapper>
											<StaffManagerPanelDivider>Current company</StaffManagerPanelDivider>
											<Space
												direction='horizontal'
												style={{ width: '100%' }}
											>
												<CompanyStub
													company={companyModel}
													withPublicationDot={true}
													withJobsPublicationDot={true}
													withAtsDot={true}
													withGenderScoreDot={true}
													withCompanyPlan={true}
													withPublicationOpacity={true}
													withCompanyPlanExtraTags={true}
													withAtsAccess={true}
												/>
												<Space direction='vertical'>
													<JsonPopoverLink
														data={companySelected}
														popover={{ placement: 'right' }}
														style={{ marginLeft: -4 }}
													/>
													<ReloadOutlined
														onClick={() => {
															dispatch(loadCompany(documentId(companySelected)));
														}}
													/>
												</Space>
											</Space>
										</StaffManagerPanelTabContentWrapper>
									)}
								>
									<StaffManagerPanelTabContentWrapper>
										<StaffManagerPanelDivider>Company selector</StaffManagerPanelDivider>
										<HeaderCompanySelector
											withStaffView={false}
										/>
									</StaffManagerPanelTabContentWrapper>
								</BlockStaff>

								{(config.isNotProdEnv || companyModel?.isDemo())
									&& (
										<>
											<StaffManagerPanelTabContentWrapper>
												<StaffManagerPanelDivider>Managers</StaffManagerPanelDivider>
											</StaffManagerPanelTabContentWrapper>

											<Tabs>
												{panelContent && (
													<Tabs.TabPane
														key={'custom'}
														tab='Custom'
													>
														{panelContent}
													</Tabs.TabPane>
												)}

												<Tabs.TabPane
													key={'company'}
													tab='Company'
												>
													<StaffManagerCompanyTab />
												</Tabs.TabPane>

												<Tabs.TabPane
													key={'genderscore'}
													tab='GenderScore'
												>
													<StaffManagerGenderScoreTab />
												</Tabs.TabPane>

												<Tabs.TabPane
													key={'ums'}
													tab='UMS'
												>
													<StaffManagerUMSTab />
												</Tabs.TabPane>

												<Tabs.TabPane
													key={'user'}
													tab='User'
												>
													<StaffManagerUserTab />
												</Tabs.TabPane>
											</Tabs>
										</>
									)}
							</div>
						</>
					)}
				</Space>

				<Button
					type={'primary'}
					className='staff-manager-panel-btn'
					onClick={() => setStaffManagerPanelOpen((p) => !p)}
					style={{
						position: 'fixed',
						opacity: (staffManagerPanelOpen || isStaffView) ? 1 : 0,
						...staffManagerPanelPlacementLeft ? { left: 75 } : { right: 10 },
						padding: '2px 8px',
						fontSize: 12,
						fontWeight: 'bold',
						borderRadius: '0px 0px 4px 4px',
						background: theme.color.purple,
						borderColor: theme.color.purple,
						boxShadow: 'none',
						height: 'unset',
					}}
				>
					{staffManagerPanelOpen
						? <FaChevronUp style={{ marginBottom: -2 }} />
						: <FaChevronDown style={{ marginBottom: -2 }} />
					}&nbsp;&nbsp;Staff Panel&nbsp;<FaCog style={{ fontSize: 9, marginBottom: 0 }} />
				</Button>

			</div>
		</>
	);
};

export default StaffManagerPanel;
