import { useSelector } from 'react-redux';
import PageLayout from '../../components/app/layout/PageLayout';
import { useTheme } from '@emotion/react';
import { getCompanySelected } from '../../reducers/app';
import CompanyGridPicker from '../../components/company/CompanyGridPicker';
import CompanyActionPlan from '../../components/company/actionPlan/CompanyActionPlan';
import CompanyActionPlanMenu from '../../menu/genderscore/CompanyActionPlanMenu';

const CompanyActionPlanPage = () => {
	const className = 'CompanyActionPlanPage';

	const theme = useTheme();

	const companySelected = useSelector(getCompanySelected);

	if (!companySelected) return <CompanyGridPicker />;

	return (
		<PageLayout
			// withHeader={false}
			// withPadding={false}
			MenuClass={CompanyActionPlanMenu}
		>
			<CompanyActionPlan
				company={companySelected}
			/>
		</PageLayout>
	);
};

export default CompanyActionPlanPage;
