import { request } from './api';

const remove = (id) => {
	return request.delete(`/company/batch/${id}`);
};

const BatchService = {
	remove,
};

export default BatchService;
