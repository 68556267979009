import { FaMedal } from 'react-icons/fa';
import { GENDERSCORE_PILLAR_VALUES, getPillarLabel } from '../../../../constants/genderscore-pillars';
import GenderScoreLayoutBox from '../layout/GenderScoreLayoutBox';
import { GENDERSCORE_LABEL } from '../../../../constants/constant';
import CompanyModel from '../../../../models/CompanyModel';
import { documentId } from '../../../../utils/common';

const GenderScorePillarRankingBox = ({
	company,
	forceValue,
	pillar,
	...props
}) => {
	const className = 'GenderScorePillarRankingBox';

	const genderScore = forceValue || company?.genderScore;

	const isPillar = GENDERSCORE_PILLAR_VALUES.includes(pillar);

	let pillarLabel = <strong>{GENDERSCORE_LABEL}</strong>;
	const rank = new CompanyModel(company).getGenderScoreRanking(pillar);

	if (pillar && isPillar) {
		pillarLabel = <><strong>{getPillarLabel(pillar)}</strong>&nbsp;pillar</>;
	}

	return (
		<GenderScoreLayoutBox
			title={<>Your Ranking</>}
			style={{
				...props?.style,
			}}
		>

			<div
				style={{
					color: '#FE14A0',
					fontSize: 38,
					fontWeight: 'bold',
					userSelect: 'none',
					marginTop: -10,
					marginBottom: -10,
				}}
			>
				<span style={{ fontSize: 24 }}><i>#&nbsp;</i></span>
				{rank} <FaMedal style={{ fontSize: 24 }} />
			</div>

			<p
				style={{
					opacity: 0.5,
					fontSize: 12,
					marginBottom: 0,
				}}
			>
				on all companies for {pillarLabel}
			</p>

		</GenderScoreLayoutBox>
	);
};

export default GenderScorePillarRankingBox;
