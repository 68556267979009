import { useTheme } from '@emotion/react';

import Link from '../Link';
import { push } from '../../../actions/utils';

const InlineNavLink = (
	{
		path = '',
		children,
		...props
	},
) => {
	const theme = useTheme();

	const handleClick = () => {
		push(path);
	};

	return <Link
		to={path}
		onClick={handleClick}
		style={{ ...props?.style }}
	>
		<span
			style={{
				fontWeight: 'bold',
				textDecoration: 'underline',
				...props?.style?.span,
			}}
		>
			{children}
		</span>
	</Link>;
};

export default InlineNavLink;
