import { Space } from 'antd';
import { FaBan, FaCheck, FaTimes } from 'react-icons/fa';
import BlockStaff from '../../app/blocker/BlockStaff';
import CompanySettingsCard, { CompanySettingsCardLineOk } from './CompanySettingsCard';
import CompanySettingsContractForm from './CompanySettingsContractForm';
import { useTheme } from '@emotion/react';

const CompanySettingsContractCard = (
	{
		company,
		...props
	},
) => {
	const className = 'CompanySettingsContractCard';
	const theme = useTheme();

	return (
		<div style={{ position: 'relative' }}>
			<BlockStaff withBorder={false} tagStyle={{ top: 2, left: 24, opacity: 1 }}>
				<CompanySettingsCard
					title={<>✍️ Contract</>}
					details={<>
						Defines whether the client has access to special paying features such as Diversity Stories or
						profile suggestions from 50inTech's Talent team.
					</>}
					company={company}
					CompanySettingsForm={CompanySettingsContractForm}
				>
					<Space direction='vertical'>

						<CompanySettingsCardLineOk
							ok={company?.isClient}
							okText={<><b>{company.name}</b> is a <b>paid client</b></>}
							koText={<><b>{company.name}</b> is a <b>not a paid client</b></>}
						/>

						<CompanySettingsCardLineOk
							ok={company?.isSuggestion}
							okText={<><b>{company.name}</b> will <b>receive suggestions</b> from 50inTech's Talent team</>}
							koText={<><b>{company.name}</b> will <b>not receive suggestions</b> from 50inTech's Talent team</>}
						/>

					</Space>
				</CompanySettingsCard>

			</BlockStaff>
		</div>
	);
};

export default CompanySettingsContractCard;
