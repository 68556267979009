import { useEffect, useState } from 'react';
import { Select, Space, Typography } from 'antd';
import UserAvatar from './widgets/UserAvatar';
import { useTheme } from '@emotion/react';
import userService from '../../services/user';
import AutoComplete from '../app/form/AutoComplete';
import { LocalDebug } from '../../utils/LocalDebug';
import { documentId } from '../../utils/common';

const UserSelect = (
	{
		debounceTimeout = 800,
		notUserIds,
		searchOptions = {},
		value,
		defaultOptions = [],
		withoutSearch,
		disabled = false,
		onFindUsers,
		labelizer,
		...props
	},
) => {
	const className = 'UserSelect';
	const theme = useTheme();
	const [options, setOptions] = useState(defaultOptions);

	const onSearchUserDefault = async (options) => {
		const { data: { items } } = await userService.findAll(options);
		return items;
	};

	// localDebug('UserSelect', { value });
	const handleUserSearch = async (search) => {
		const method = 'handleUserSearch';
		LocalDebug.logInfo({ className, method }, { search });
		if (withoutSearch) return;
		const optionsSearch = {
			search, notUserIds, limit: 50, ...searchOptions,
		};
		const items = onFindUsers ? await onFindUsers(optionsSearch) : await onSearchUserDefault(optionsSearch);
		if (items) {
			setOptions(items);
		}
	};

	// localDebug('defaultOptions', defaultOptions);

	useEffect(() => {
		setOptions(defaultOptions);
	}, [defaultOptions]);

	// ------------------------------------------------- //
	// ------------ options with default value --------- //
	// ------------------------------------------------- //
	useEffect(() => {
		const initDefaultOptions = async (id) => {
			const { data } = await userService.getUser(id);
			if (data?.user) {
				setOptions([data?.user]);
			}
		};
		if (value && !options.find((o) => documentId(o) === value)) {
			initDefaultOptions(value);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [value]);

	return (
		<AutoComplete
			onSearch={handleUserSearch}
			onStartSearch={() => {
				LocalDebug.logInfo({ className, method: 'onStartSearch' });
				// !withoutSearch && setOptions([]);
			}}
			debounceTimeout={500}
			disabled={disabled}
			{...props}
			value={value}
			style={{ ...props?.style }}
		>
			{options.map((u) => (
				<Select.Option
					key={documentId(u)}
					value={documentId(u)}
					label={labelizer ? labelizer(u) : `${u.firstName} ${u.lastName}`}
				>
					<Space direction="horizontal">
						<UserAvatar user={u} />
						<Space direction="vertical" size={0}>
							<Typography style={{ fontWeight: 'bold' }}>{u.firstName} {u.lastName}</Typography>
							{u.information
								&& (u.information.position || u.information.company)
								&& <Typography
									style={{ fontSize: '12px', color: theme.color.darkgrey }}>{u.information.position}
									<span style={{ color: theme.color.grey }}>@</span> {u.information.company}
								</Typography>
							}
							<Typography style={{ fontSize: '12px', color: theme.color.lightgrey }}>{u.slug}</Typography>
						</Space>
					</Space>
				</Select.Option>
			))}
		</AutoComplete>
	);
};

export default UserSelect;
