/* eslint-disable import/no-named-as-default-member */
import { useTheme } from '@emotion/react';
import { useState } from 'react';
import {
	Button, Col, Divider, Dropdown, message, Popconfirm, Popover,
	Row,
	Space,
	Steps,
	Typography,
} from 'antd';
import Table from '../app/table/Table';
import TableColumns from '../app/table/TableColumns';
import { displayHtml, documentId, momentShortenFrom } from '../../utils/common';
import UserDrawerProfile from '../user/UserDrawerProfile';
import { useAuthContext } from '../../hooks/useAuthContext';
import { refreshUserMailingOfMessage, retrySendMessage } from '../../actions/messages';
import MessageTemplateCategoryTag from './templates/MessageTemplateCategoryTag';
import { Link } from 'react-router-dom';
import UserMessageModel, {
	ANSWER_STEP_ANSWERED, ANSWER_STEP_READ, ANSWER_STEP_SENT, ANSWER_STEP_STATUS_SET,
	MessageStatusTalentAnswerLine,
	MessageStatusTalentAnswerTag,
} from '../../models/UserMessageModel';
import UserMailingService from '../../services/user-mailing';
import UserMailingModel from '../../models/UserMailingModel';
import { FaCommentDots, FaEye } from 'react-icons/fa';
import ReactJsonDebug from '../app/debug/ReactJsonDebug';
import moment from 'moment';
import DatePreview from '../app/table/DatePreview';
import Box from '../app/box/Box';
import { backgroundColors } from 'chalk';
import UserGridCardExpander from '../user/grid/card/blocks/UserGridCardExpander';
import { BiChevronDown } from 'react-icons/bi';
import { EyeInvisibleOutlined, EyeOutlined, ReloadOutlined } from '@ant-design/icons';

const MessageNewTable = (
	{
		onDataLoaded,
		path = '/message',
		filterOptions = {},
		isRefresh = 0,
		onSearchDataColumn,
		onToggleIsActive,
		onEditArticle,
		isEditor,
		...props
	},
) => {
	const className = 'MessageNewTable';

	const theme = useTheme();

	const { isAdmin, isStaff, isStaffView } = useAuthContext();

	const [expandedRowKeys, setExpandedRowKeys] = useState([]);
	const [isRefreshDataLayout, setIsRefreshDataLayout] = useState(0);
	const [openProfile, setOpenProfile] = useState(false);
	const [activeTab, setActiveTab] = useState(null);
	const [userSelected, setUserSelected] = useState(null);

	const lineStyles = {
		color: theme.color.grey,
		fontSize: 10,
		lineHeight: 1.1,
		// height: 10
	};

	const handleOpenUserProfile = (user, tab) => {
		setUserSelected(user);
		setActiveTab(tab);
		setOpenProfile(true);
	};

	const handleCloseProfile = () => {
		setUserSelected(null);
		setActiveTab(null);
		setOpenProfile(false);
	};

	const handleMailjetDataRefresh = async (msg) => {
		const method = 'handleMailjetDataRefresh';
		message.loading({ key: method, content: 'Refresh data from Mailjet...', duration: 0 });

		try {
			await refreshUserMailingOfMessage(msg);

			message.success({ key: method, content: 'Data refreshed!' });
			setIsRefreshDataLayout((p) => p + 1);
		} catch (error) {
			const defaultError = 'Could not refresh the data';
			const errorMsg = error?.response?.data?.error?.message;
			message.error({ key: method, content: errorMsg || defaultError });
		}
	};

	const handleRetrySend = async (msg) => {
		const method = 'handleSendRetry';
		message.loading({ key: method, content: 'Retry sending message...', duration: 0 });
		// LocalDebug.logInfo({ className, method }, { msg });
		const result = await retrySendMessage(msg);
		// LocalDebug.logInfo({ className, method }, { result });
		message.success({ key: method, content: 'Message sending retried!' });
		setIsRefreshDataLayout((p) => p + 1);
	};

	const jsonCol = TableColumns.jsonColumn(theme, { title: '', ...TableColumns.columnFixedWidth(50) });

	const userColProps = {
		sorter: false,
		avatarSize: 0,
		withProfession: false,
		withLocation: false,
		withDEICriteria: false,
		withEmail: false,
		withBox: false,
		withDropdown: false,
		withPreview: false,
		withIsNew: false,
		withIsUnviewed: false,
		withTooltip: false,
		withIsNewlyOnboarded: false,
		withCoachedTag: false,
		withResumeTag: false,
		colWidth: 200,
		style: {
			fontSize: 12,
		},
	};

	const userFromStubCol = TableColumns.userStubColumn(theme, {
		...userColProps,
		title: 'From',
		dataIndex: 'user',
		withDropdown: isStaffView,
	});

	const userToStubCol = TableColumns.userStubColumn(theme, {
		...userColProps,
		title: 'To',
		dataIndex: 'userTo',
	});

	const companyLogoCol = TableColumns.companyLogoColumn(theme, {
		title: '',
		dataIndex: 'company',
		removeInColumnFilter: true,
		colWidth: 32,
		size: 24,
	});

	const categoryCol = {
		title: 'Type',
		dataIndex: 'category',
		align: 'center',
		removeInColumnFilter: true,
		sorter: false,
		render: (value, row) => (
			<MessageTemplateCategoryTag category={value} />
		),
		...TableColumns.columnFixedWidth(130),
	};

	const sentAtCol = TableColumns.dateColumn(theme, {
		title: 'Sent at',
		dataIndex: ['recipientMailing', 'sentAt'],
		key: 'sentAt',
	});

	const updatedAtCol = TableColumns.updatedAtColumn(theme);

	const statusCol = {
		title: 'Status',
		dataIndex: ['answer'],
		align: 'center',
		sorter: false,
		removeInColumnFilter: true,
		render: (value, row) => row?.renderStatusDisplay(theme, { withStatusStep: false, withToolTip: false }),
		...TableColumns.columnFixedWidth(110),
	};

	const retrySendLink = (row, label = 'Retry send >') => (
		<Popconfirm
			title={<div style={{ maxWidth: 300 }}><b>Are you sure?</b><br />Beware, it will send again the email from the recruiter to the talent.</div>}
			onConfirm={() => { handleRetrySend(row); }}
		>
			<Link>{label}</Link>
		</Popconfirm>
	);

	const openDetailsCol = {
		title: 'Actions',
		render: (value, row) => {
			if (row?.hasAnswerDetails) {
				if (!(expandedRowKeys || []).includes(documentId(row))) {
					return (
						<Link
							onClick={() => {
								setExpandedRowKeys((p) => {
									return [...new Set([...p || [], documentId(row)])];
								});
							}}
						>
							Open details
						</Link>
					);
				}
				return (
					<Link
						onClick={() => {
							setExpandedRowKeys((p) => {
								return [...new Set((p || []).filter((key) => key !== documentId(row)))];
							});
						}}
					>
						Close details
					</Link>
				);
			}
		},
		...TableColumns.columnFixedWidth(120),
	};

	const actionsCol = {
		title: 'Actions',
		render: (value, row) => {
			if (!row?.hasAnswerDetails) {
				return null;
			}

			const ActionMenuItem = ({
				title,
				onClick,
				Icon,
				children,
			}) => (
				<div onClick={onClick}>
					<Icon style={{
						marginBottom: -5,
						fontSize: 10,
						border: '1px solid #ddd',
						padding: 4,
						borderRadius: 4,
					}} />
					&nbsp;
					{children}
				</div>
			);
			const items = [
				...(
					!(expandedRowKeys || []).includes(documentId(row))
						? [{
							key: 'details',
							label: (
								<ActionMenuItem
									onClick={() => {
										setExpandedRowKeys((p) => {
											return [...new Set([...p || [], documentId(row)])];
										});
									}}
									Icon={EyeOutlined}
								>
								Open details
								</ActionMenuItem>
							),
						}]
						: []
				),
				...(
					(expandedRowKeys || []).includes(documentId(row))
						? [{
							key: 'details',
							label: (
								<ActionMenuItem
									onClick={() => {
										setExpandedRowKeys((p) => {
											return [...new Set((p || []).filter((key) => key !== documentId(row)))];
										});
									}}
									Icon={EyeInvisibleOutlined}
								>
								Close details
								</ActionMenuItem>
							),
						}]
						: []
				),
				...!row?.isAnswered
					? [{
						key: 'resend',
						// label: retrySendLink(row),
						label: (
							<Popconfirm
								title={(
									<div
										style={{ maxWidth: 300 }}
									>
										<b>Are you sure?</b>
										<br />
										Beware, it will send again the email from the recruiter to the talent.
									</div>
								)}
								onConfirm={(e) => {
									handleRetrySend(row);
									e.stopPropagation?.();
									e.preventDefault?.();
								}}
							>
								<ActionMenuItem
									Icon={ReloadOutlined}
								>
									Resend message
								</ActionMenuItem>
							</Popconfirm>
						),
					}]
					: [],
			];

			return (
				<Dropdown menu={{ items }}>
					<Link>Actions <BiChevronDown style={{ marginBottom: -2 }}/></Link>
				</Dropdown>
			);
		},
		...TableColumns.columnFixedWidth(120),
	};

	const columns = isStaffView
		? [
			TableColumns.staffColumnWrapper(jsonCol),
			TableColumns.staffColumnWrapper(companyLogoCol),
			userFromStubCol,
			userToStubCol,
			categoryCol,
			sentAtCol,
			statusCol,
			updatedAtCol,
			TableColumns.staffColumnWrapper(actionsCol),
		]
		: [
			userFromStubCol,
			userToStubCol,
			categoryCol,
			sentAtCol,
			statusCol,
			updatedAtCol,
			openDetailsCol,
		];

	return (
		<>
			<Table
				path={path}
				ItemClass={UserMessageModel}
				onDataLoaded={onDataLoaded}
				columns={columns}
				filterOptions={filterOptions}
				isRefresh={isRefresh + isRefreshDataLayout}
				onSearchDataColumn={onSearchDataColumn}
				showTotal={(total) => `Total ${total} items`}
				limit={10}
				rowClassName={(row, index) => (row.is50Content === false ? 'rowDisabled' : '')}
				withDynamicSearch
				expandable={{
					expandedRowRender: (row) => new UserMessageModel(row).renderExpand({ isStaffView, handleRetrySend }),
					rowExpandable: (row) => new UserMessageModel(row).isExpandable,
					// expandIcon: () => <Link>Expand</Link>,
					// expandRowByClick: true,
					// showExpandColumn: false,
					columnWidth: 32,
					onExpand: (expanded, row) => {
						if (expanded) {
							setExpandedRowKeys((p) => {
								return [...new Set([...p || [], documentId(row)])];
							});
						} else {
							setExpandedRowKeys((p) => {
								return [...new Set((p || []).filter((key) => key !== documentId(row)))];
							});
						}
					},
					onExpandedRowsChange: (rows) => {
					},
					expandedRowKeys,
				  }}
				{...props}
			/>

			<UserDrawerProfile
				user={userSelected}
				activeTab={activeTab}
				open={openProfile}
				onClose={handleCloseProfile}
			/>
		</>
	);
};

export default MessageNewTable;
