import { Space, Tooltip } from 'antd';
import {
	GENDERSCORE_PILLAR_VALUES,
	getPillarColorMain,
	getPillarIcon,
	getPillarLabel,
} from '../../../../constants/genderscore-pillars';
import { icons } from 'react-icons/lib';
import { memo, useMemo } from 'react';

const GenderScorePillarLogoLabel = ({
	pillar,
	iconSize = 14,
	labelSize = 10,
	withLogo = true,
	withLabel = true,
	withTooltip = false,
	tooltipTitle,
	...props
}) => {
	const className = 'GenderScorePillarLogoLabel';

	const memoizedContent = useMemo(() => {
		if (!GENDERSCORE_PILLAR_VALUES.includes(pillar)) return null;

		const content = (
			<Space size={iconSize / 3.5} style={{ alignItems: 'center' }}>
				{withLogo && (
					getPillarIcon(pillar, {
						width: iconSize,
						height: iconSize,
						style: {
							marginBottom: -6,
							...props?.style?.icon,
						},
					})
				)}
				{withLabel && (
					<span
						style={{
							fontSize: labelSize,
							color: getPillarColorMain(pillar),
							lineHeight: 1,
							...props?.style?.label,
						}}
					>
						{getPillarLabel(pillar)}
					</span>
				)}
			</Space>
		);

		if (withTooltip) {
			return <Tooltip title={(
				tooltipTitle || getPillarLabel(pillar)
			)}>
				{content}
			</Tooltip>;
		}

		return content;
	}, [pillar, labelSize, iconSize, props, tooltipTitle, withLabel, withLogo, withTooltip]);

	return memoizedContent;
};

export default GenderScorePillarLogoLabel;
