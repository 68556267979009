import { useTheme } from '@emotion/react';
import { Group } from '@visx/group';
import { scaleLinear } from '@visx/scale';
import { Point } from '@visx/point';
import { Circle, Line } from '@visx/shape';
import BiasRadarTag from './BiasRadarTag';
import { BIAS_OPTIONS } from '../../data/biases';

const y = (d) => d;

const genAngles = (length) => [...new Array(length + 1)]
	.map((_, i) => ({
		angle: i * (360 / length) + (length % 2 === 0 ? 360 / length / 2 : 0),
	}));

const genPoints = (length, radius) => {
	const step = (Math.PI * 2) / length;
	return [...new Array(length)].map((_, i) => ({
		x: radius * Math.sin(-(i + 3.5) * step),
		y: radius * Math.cos(-(i + 3.5) * step),
	}));
};

function genPolygonPoints(
	dataArray,
	scale,
	getValue,
) {
	const step = (Math.PI * 2) / dataArray.length;
	const points = new Array(dataArray.length).fill({ x: 0, y: 0 });
	const pointString = new Array(dataArray.length + 1).fill('').reduce((res, _, i) => {
		if (i > dataArray.length) return res;
		const v = dataArray[i - 1];
		const xVal = scale(getValue(dataArray[i - 1])) * Math.sin(-(i + 2.5) * step);
		const yVal = scale(getValue(dataArray[i - 1])) * Math.cos(-(i + 2.5) * step);
		points[i - 1] = { x: xVal, y: yVal };
		res += `${xVal},${yVal} `;
		return res;
	});

	return { points, pointString };
}

const defaultMargin = {
	top: 0, left: 80, right: 80, bottom: 80,
};

const RadarResults = ({
	polygonPoints,
	fill = 'transparent',
	fillOpacity = 0.3,
	stroke = 'orange',
	strokeWidth = 3,
	strokeLinecap = 'round',
	// pointSize = 6,
}) => {
	return (
		<>
			<polygon
				points={polygonPoints.pointString}
				fill={fill}
				fillOpacity={fillOpacity}
				stroke={stroke}
				strokeWidth={strokeWidth}
				strokeLinecap={strokeLinecap}
			/>
			{polygonPoints.points.map((point, i) => (
				<circle
					key={`radar-point-${i}`}
					cx={point.x}
					cy={point.y}
					r={strokeWidth * 2}
					fill={stroke}
					fillOpacity={1}
				/>
			))}
		</>
	);
};

export default function BiasRadar({
	data,
	width = 500,
	height = 500,
	levels = 5,
	margin = defaultMargin,
	...props
}) {
	const theme = useTheme();

	const resize = (v) => v * width / 1200;

	const biases = BIAS_OPTIONS;

	const xMax = width - margin.left - margin.right;
	const yMax = height - margin.top - margin.bottom;
	const radius = Math.min(xMax, yMax) / 2;

	const radialScale = scaleLinear({
		range: [0, Math.PI * 2],
		domain: [360, 0],
	});

	const yScale = scaleLinear({
		range: [0, radius],
		domain: [0, 100],
	});

	const webs = genAngles(biases?.length);
	const points = genPoints(biases?.length, radius);
	const zeroPoint = new Point({ x: 0, y: 0 });

	return (
		<div
			style={{
				position: 'relative',
				...props?.style,
				width: `${width}px`,
				height: `${height}px`,
			}}
		>
			<svg width={width} height={height}>
				<radialGradient id="MyGradient" opacity={0.2}>
					<stop offset="0%" stopColor="rgba(0,255,255,.2)" />
					<stop offset="25%" stopColor="rgba(0,255,255,.2)" />
					<stop offset="25%" stopColor="rgba(0,255,0,.2)" />
					<stop offset="50%" stopColor="rgba(0,255,0,.2)" />
					<stop offset="50%" stopColor="rgba(255,255,0,.2)" />
					<stop offset="75%" stopColor="rgba(255,255,0,.2)" />
					<stop offset="75%" stopColor="rgba(255,0,0,.2)" />
					<stop offset="100%" stopColor="rgba(255,0,0,.2)" />
				</radialGradient>
				<Group top={height / 2 - margin.top} left={width / 2}>
					{/* <Circle
					key={'circle-bg'}
					cx={zeroPoint.x}
					cy={zeroPoint.y}
					r={radius}
					fill='url(#MyGradient)'
					stroke={'none'}
				/> */}
					{[...new Array(levels)].map((_, i) => {
						return (
							<Circle
								key={`circle-${i}`}
								cx={zeroPoint.x}
								cy={zeroPoint.y}
								r={((i + 1) * radius) / levels}
								fill='none'
								stroke={'black'}
								strokeWidth={resize(0.4)}
								strokeOpacity={1}
								strokeLinecap="round"
							/>
						);
						{ /* <LineRadial
						key={`web-${i}`}
						data={webs}
						angle={(d) => radialScale(d.angle) ?? 0}
						radius={((i + 1) * radius) / levels}
						fill="none"
						stroke={'black'}
						strokeWidth={0.1}
						strokeOpacity={1}
						strokeLinecap="round"
					/> */ }
					})}
					{/* {[...new Array(levels)].map((_, i) => {
						return (
							<Text
								key={`legend-${i}`}
								x={zeroPoint.x + 1}
								y={zeroPoint.y + (i + 1) * radius}
								width={140}
								height={30}
								style={{
									// textAlign: 'center',
									background: 'black',
									border: '1px solid blue',
									fontSize: 20,
									textAlign: 'center',
								}}
							>
								Legend: {i * 20}
							</Text>
						);
					})} */}
					{biases.map((bias, i) => {
						return (
							<Line
								key={`radar-line-${i}`}
								from={zeroPoint}
								to={points[i]}
								to={{ x: 1 * points[i].x, y: 1 * points[i].y }}
								stroke={'black'}
								strokeWidth={resize(0.5)}
								strokeOpacity={1}
							/>
						);
					})}
					{/* <RadarResults
					polygonPoints={genPolygonPoints(managersByName['Average All'].scores, (d) => yScale(d) ?? 0, y)}
					fill={'transparent'}
					fillOpacity={0.3}
					stroke={orange}
					strokeWidth={1}
					strokeLinecap="round"
				/> */}
					{data?.map((d, i) => {
						const { scores, radarStyle } = d || {};
						const strokeWidth = resize(radarStyle?.strokeWidth || 5);
						if (!scores) return null;
						const dValues = BIAS_OPTIONS.map((bias) => scores?.[bias?.shortId]);
						// console.log('BiasRadar', scores, dValues);
						return (
							<RadarResults
								key={`results-${i}`}
								polygonPoints={genPolygonPoints(dValues, (d) => yScale(d) ?? 0, y)}
								fill={'transparent'}
								fillOpacity={0.3}
								stroke={theme.color.fitBlueElectric}
								strokeOpacity={0.3}
								strokeLinecap="round"
								// pointSize={width / 120}
								{...radarStyle}
								strokeWidth={strokeWidth}
							/>
						);
					})}

					{/* <polygon
					points={polygonPoints.pointString}
					fill={'transparent'}
					fillOpacity={0.3}
					stroke={orange}
					strokeWidth={3}
					strokeLinecap="round"
				/> */}
					{/* {polygonPoints.points.map((point, i) => (
					<circle key={`radar-point-${i}`} cx={point.x} cy={point.y} r={4} fill={pumpkin} />
				))} */}
				</Group>
			</svg>

			{false && biases.map((bias, i) => (
				<div
					key={bias.id}
				>
					<BiasRadarTag
						bias={bias}
						x={points[i].x}
						y={points[i].y}
						width={width}
						height={height}
						data={data}
					/>
				</div>
			))}

			{/* <div style={{ position: 'absolute', left: 10, top: 10 }}>
				<Popover
					title='Questions'
					content={(
						<div style={{ maxWidth: 500 }}>
							<ReactMarkdown
							>
								{`- _**What score should be used to determine the manager\'s level of bias (weakly, moderately, strongly)?**_
  - If we only use the manager's answers, we could say (for instance) he/she is **weakly biased**, even if all employees declared him/her **strongly biased**.`}
							</ReactMarkdown>
						</div>
					)}
				>
					<FaQuestionCircle />
				</Popover>
			</div> */}
		</div>
	);
}
