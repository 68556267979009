import AccountCard from './AccountCard';
import Box from '../app/box/Box';
import AclRoleStub from '../acls/AcRoleStub';
import { Space } from 'antd';

const AccountRolesCard = (
	{
		user,
		style = {},
	},
) => {
	const className = 'AccountRolesCard';

	return (
		<AccountCard
			user={user}
			title={<>🔐 Your roles</>}
			details={<>What type of permissions you have been granted on 50inTech.</>}
		>
			<Space direction='horizontal' align='top' wrap={true}>

				{user?.acls?.userAcls?.map(
					(userAcl, index) => <Box
						key={index}
						style={{ padding: 10, width: '100%' }}
					>
						<AclRoleStub
							role={userAcl}
							withDetails={false}
						/>
					</Box>,
				)}
			</Space>

		</AccountCard>
	);
};

export default AccountRolesCard;
