import { Button, Space, Tabs } from 'antd';
import PageLayout from '../../../components/app/layout/PageLayout';
import SectionTitle from '../../../components/app/SectionTitle';
import { useEffect, useState } from 'react';
import { LocalDebug } from '../../../utils/LocalDebug';
import { useTheme } from '@emotion/react';
import TalentChartUserFilters from '../../../components/staff/data/talentChart/TalentChartUserFilters';
import MongoChart from '../../../components/MongoChart';
import Box from '../../../components/app/box/Box';
import { PieChartOutlined } from '@ant-design/icons';

const USER_TOTAL_CHART_ID = 'ffe4c41a-b093-4c21-9c10-97fd82f9ae13';
// const USER_ROLE_GROUP_CHART_ID = '6add831c-05c9-4a08-b4b2-c66041a009ee';
const USER_ROLE_GROUP_CHART_ID = '6212bcb3-ee96-48e4-8c2c-787bdbe1954e';
// const USER_ROLE_CHART_ID = 'ea271737-9078-421d-abe9-13ae600140e6';
const USER_ROLE_CHART_ID = '6212bcb3-ee96-4bca-8e69-787bdbe1954b';
const ACTIVE_COMPANIES_TOTAL_CHART_ID = 'b072341b-c8c3-4437-98cc-40021f874f8a';
const ACTIVE_COMPANIES_LIST_CHART_ID = '6447fbae-b3e2-45ff-8533-4464f2ef97f5';

const DBChartsPage = () => {
	const className = 'DBChartsPage';
	const theme = useTheme();

	const [chartFilters, setChartFilters] = useState({});
	const [filters, setFilters] = useState({});
	const [refresh, setRefresh] = useState(0);

	useEffect(async () => {
		try {
			LocalDebug.logUseEffect({ className, effects: 'refresh' });
		} catch (e) {
			LocalDebug.logError({ className, error: e }, { e });
		}
	}, [refresh]);

	useEffect(() => {
		const {
			onboarded, country, level, status, jobRole, jobRoleGroup, ...rest
		} = filters;
		LocalDebug.logUseEffect({ className, effects: 'filters' }, {
			country, status, level, rest,
		});
		setChartFilters({
			...[true, false].includes(onboarded) ? { 'auth.isOnboarded': { $eq: onboarded } } : {},
			...country?.length >= 1 ? { 'information.location.country': { $in: country } } : {},
			...level?.length >= 1 ? { 'jobSearch.jobLevel': { $in: level } } : {},
			...status?.length >= 1 ? { 'career.seeking': { $in: status } } : {},
			...jobRole?.length >= 1 ? { 'jobSearch.jobRole': { $in: jobRole } } : {},
			...jobRoleGroup?.length >= 1 ? { 'jobSearch.jobRoleGroup': { $in: jobRoleGroup } } : {},
		});
	}, [filters]);

	return (
		<PageLayout
			withBackground={false}
			title='DB Charts'
			icon={<PieChartOutlined />}
			rightActions={<Button onClick={() => setRefresh((p) => p + 1)}>Refresh</Button>}
		>
			<Tabs>
				<Tabs.TabPane tab='Talents by roles' key={'talentroles'}>
					<TalentChartUserFilters setFilters={setFilters} />

					<Space wrap={true} align={'top'}>

						<MongoChart
							width={1100}
							height={200}
							filters={chartFilters}
							chartId={USER_TOTAL_CHART_ID}
						/>

						<Tabs>
							<Tabs.TabPane tab='Job Role groups' key={'jobRoleGroup'}>
								<MongoChart
									width={1100}
									height={600}
									filters={chartFilters}
									chartId={USER_ROLE_GROUP_CHART_ID}
								/>
							</Tabs.TabPane>
							<Tabs.TabPane tab='Job Roles' key={'jobRole'}>
								<MongoChart
									width={1100}
									height={600}
									filters={chartFilters}
									chartId={USER_ROLE_CHART_ID}
								/>
							</Tabs.TabPane>
						</Tabs>

					</Space>
				</Tabs.TabPane>

				<Tabs.TabPane tab='Active companies' key={'activeCompanies'}>

					<Space wrap={true} align='top'>
						<MongoChart
							width={1100}
							height={240}
							chartId={ACTIVE_COMPANIES_TOTAL_CHART_ID}
						/>
						<MongoChart
							width={1100}
							height={600}
							chartId={ACTIVE_COMPANIES_LIST_CHART_ID}
						/>
					</Space>

				</Tabs.TabPane>
			</Tabs>

		</PageLayout>
	);
};

export default DBChartsPage;
