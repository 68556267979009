import {
	Divider, Dropdown, Menu, Popover, Space, Tooltip,
} from 'antd';
import moment from 'moment';
import { useContext, useState } from 'react';
import Table from '../app/table/Table';
import theme from '../../theme';
import SourcingActionPopover from './SourcingActionPopover';
import {
	SOURCING_ACTION_STATUS, SOURCING_ACTION_COMMENT,
	SOURCING_ACTION_STAGE, SOURCING_ACTION_CONTACT, SOURCING_ACTION_ARCHIVE, SOURCING_ACTION_MANAGER,
	sourcingSources,
} from '../../constants/constant';
import { COMPANY_RECRUITER_LOG } from '../../constants/company-recruiter-log';

import { documentId, writeLog } from '../../utils/common';
import { LocalDebug } from '../../utils/LocalDebug';
import ActionButton from '../app/table/ActionButton';
import TableColumns from '../app/table/TableColumns';
import ModalConfirmAction from './ModalConfirmAction';
import { useAuthContext } from '../../hooks/useAuthContext';
import {
	FaCommentDots, FaEdit, FaEllipsisH, FaFolder, FaPiedPiper, FaTrash,
} from 'react-icons/fa';
import MessagePopover from '../messages/MessagePopover';
import { addMessage } from '../../actions/messages';
import SourcingStageEditModal from './SourcingStageEditModal';
import SourcingEditModal from './SourcingEditModal';
import UserDrawerProfile from '../user/UserDrawerProfile';
import { treatSourcingActionToDb } from '../../actions/sourcings';
import UserContacter from '../user/buttons/UserContacter';
import SourcingJobPopover from './SourcingJobPopover';
import SourcingArchiveModal from './SourcingArchiveModal';
import SourcingCommentPopover from './SourcingCommentPopover';
import SourcingManagerDropdown from './SourcingManagerDropdown';
import { writeRecruiterLog } from '../../actions/logging';
import { CandidateContext } from '../../contexts/CandidateProvider';
import { useDispatch, useSelector } from 'react-redux';
import { getAtsCandidatePipelines, getShouldRefreshData } from '../../reducers/app';
import SourcingATSStageTag from './SourcingATSStageTag';
import SourcingATSManagerColumn from './SourcingATSManagerColumn';
import SourcingContacter from './buttons/SourcingContacter';
import SourcingDeleter from './buttons/SourcingDeleter';
import SourcingATSHandlerCell from './SourcingATSHandlerCell';

const CandidateTable = (
	{
		dataSource,
		path = '/sourcing',
		filterOptions = {},
		isRefresh = 0,
		onEditSourcing,
		onDeleteSourcing,
		onSearchDataColumn,
		withClientSidePagination,
		withDynamicSearch = true,
		withTableHeader = true,
		withSort = true,
		mode = 'sourcings',
	},
) => {
	const className = 'CandidateTable';

	const {
		isStaffView, isAdmin, isAtsSyncable, currentUser,
	} = useAuthContext();
	const {
		isArchived, showArchived, setCandidateCounts, setSourceOptions, setAtsStageOptions,
	} = useContext(CandidateContext);

	const dispatch = useDispatch();
	const dataRefresh = useSelector(getShouldRefreshData);
	const atsCandidatePipelines = useSelector(getAtsCandidatePipelines);

	const [openCommentPopover, setOpenCommentPopover] = useState(false);
	const [initialValuesCommentForm, setInitialValuesCommentForm] = useState({ action: SOURCING_ACTION_COMMENT });
	const [openActionPopover, setOpenActionPopover] = useState(false);
	const [openMessagePopover, setOpenMessagePopover] = useState(false);
	const [initialValuesActionForm, setInitialValuesActionForm] = useState({ action: SOURCING_ACTION_STATUS });
	const [initialValuesMessageForm, setInitialValuesMessageForm] = useState({});
	const [openConfirmAction, setOpenConfirmAction] = useState(false);
	const [openArchiveModal, setOpenArchiveModal] = useState(false);
	const [archiveInitialValues, setArchiveInitialValues] = useState(false);
	const [openEditModal, setOpenEditModal] = useState(false);
	const [editInitialValues, setEditInitialValues] = useState({});
	const [openStageEditModal, setOpenStageEditModal] = useState(false);
	const [stageEditInitialValues, setStageEditInitialValues] = useState({});
	const [openSourcingEditJob, setOpenSourcingEditJob] = useState(false);
	const [sourcingEditJobInitialValues, setSourcingEditJobInitialValues] = useState({});
	const [currentAction, setCurrentAction] = useState(null);
	const [actionConfirmInitialValues, setActionConfirmInitialValue] = useState({});
	const [openProfile, setOpenProfile] = useState(false);
	const [activeTab, setActiveTab] = useState(null);
	const [userSelected, setUserSelected] = useState(null);
	const [isRefreshDataLayout, setIsRefreshDataLayout] = useState(0);

	const handleRefresh = () => {
		LocalDebug.logInfo({ className, method: 'handleRefresh' }, { dataRefresh });
		// dispatch(shouldRefreshData());
		// setIsRefreshDataLayout((prev) => prev + 1);
	};

	const handleAddAction = (row, isReadOnly = false) => {
		writeLog('Sourcing: open modal add action (handleAddAction)', { sourcingId: documentId(row), isReadOnly });
		setInitialValuesActionForm((prev) => ({
			...prev,
			...row,
			isReadOnly,
			author: documentId(currentUser), // add current user
		}));
		setOpenActionPopover(true);
	};

	const handleAddComment = (row, isReadOnly = false) => {
		writeLog('Sourcing: open modal add comment (handleAddComment)', { sourcingId: documentId(row), isReadOnly });
		setInitialValuesCommentForm((prev) => ({
			...prev,
			...row,
			isReadOnly,
			author: documentId(currentUser), // add current user
			creator: documentId(currentUser), // add current user
		}));
		setOpenCommentPopover(true);
	};

	const handleMessageSubmitted = async (row, values) => {
		LocalDebug.logInfo({ className, method: 'handleMessageSubmitted' }, { row, values });
		handleRefresh();
	};

	const handleMessageSubmit = async (values, title = 'Send a message') => {
		const { sourcingId, ...valuesToDb } = values || {};
		const message = await addMessage(valuesToDb);
		LocalDebug.logInfo({ className, method: 'handleMessageSubmit' }, { mode, sourcingId, message });
		if (sourcingId) {
			const action = SOURCING_ACTION_CONTACT;
			const history = {
				action,
				'creator': documentId(currentUser),
				'postedAt': moment(),
				'author': documentId(currentUser),
				'payload.userMessageId': documentId(message),
			};

			const toDb = treatSourcingActionToDb({
				id: sourcingId,
				managerId: documentId(currentUser),
				...history,
			}, currentUser);

			const actionTitle = `add ${action}`;
			onEditSourcing(toDb, actionTitle);
		}
		setOpenMessagePopover(false);
	};

	// ----------------------------------- //
	// --------- handle select job ------- //
	// ----------------------------------- //
	const handleSubmitSelectJob = (values) => {
		onEditSourcing(values);
		setOpenSourcingEditJob(false);
	};

	// ----------------------------------- //
	// --------- handle add action ------- //
	// ----------------------------------- //

	const handleSubmitAction = (values, action) => {
		const actionTitle = `add ${action}`;
		onEditSourcing(values, actionTitle);
		setOpenActionPopover(false);
		setOpenConfirmAction(false);
	};

	const handleSubmitComment = (values, action) => {
		const actionTitle = `add ${action}`;
		onEditSourcing(values, actionTitle);
		setOpenCommentPopover(false);
	};

	const handleSubmitEdit = (values, action) => {
		LocalDebug.logInfo({ className, method: 'handleSubmitEdit' }, { values, action });
		setOpenEditModal(false);
	};

	const handleSubmitStage = (values, action) => {
		LocalDebug.logInfo({ className, method: 'handleSubmitStage' }, { values, action });
		const actionTitle = `add ${action}`;
		onEditSourcing(values, actionTitle);
		setOpenStageEditModal(false);
	};

	const handleSubmitArchive = (values, action) => {
		const actionTitle = `add ${action}`;
		onEditSourcing(values, actionTitle);
		// company recruiter log
		writeRecruiterLog(COMPANY_RECRUITER_LOG.ARCHIVE, {
			userTargetId: archiveInitialValues?.userId,
			sourcingTargetId: documentId(archiveInitialValues),
		});
		setOpenArchiveModal(false);
	};

	const handleDeleteSourcing = (sourcing) => {
		onDeleteSourcing(sourcing);
	};

	const handleOpenStageEditModal = (row, action) => {
		LocalDebug.logInfo({ className, method: 'handleOpenStageEditModal' }, { sourcingId: documentId(row), action });
		writeLog('Sourcing: handleOpenStageEditModal ', { sourcingId: documentId(row), action });
		setOpenEditModal(true);
		setEditInitialValues(row);
	};

	const handleOpenArchiveModal = (row, action) => {
		writeLog('Sourcing: handleOpenArchiveModal ', { sourcingId: documentId(row), action });
		const currentValue = !!row.isArchived;
		setOpenArchiveModal(true);
		setCurrentAction({ value: currentValue, type: action });
		setArchiveInitialValues(row);
	};

	const handleCloseProfile = () => {
		setUserSelected(null);
		setActiveTab(null);
		setOpenProfile(false);
	};

	const handleJobSelection = (row) => {
		writeLog('Sourcing: handleJobSelection ', { sourcingId: documentId(row), jobId: documentId(row?.job) });
		setSourcingEditJobInitialValues(row);
		setOpenSourcingEditJob(true);
	};

	const handleSubmitManager = (sourcing, managerId, previousManagerId) => {
		if (documentId(sourcing) && managerId) {
			const action = SOURCING_ACTION_MANAGER;
			const history = {
				action,
				'creator': documentId(currentUser),
				'author': documentId(currentUser),
				'payload.managerId': managerId,
				'payload.previousManagerId': sourcing.managerId,
			};

			const toDb = treatSourcingActionToDb({
				id: documentId(sourcing),
				managerId,
				...history,
			}, currentUser);

			const actionTitle = `add ${action}`;
			onEditSourcing(toDb, actionTitle);
		}
	};

	const handleDataLoaded = ({ items, ...rest } = {}) => {
		const method = 'handleDataLoaded';
		LocalDebug.logInfo({ className, method }, { items, rest });
		try {
			const [{
				groupFields: {
					candidatePipeline, candidateStage, isArchived, atsArchiveReason, source, atsStage,
				},
			}] = rest;
			// LocalDebug.logInfo({ className, method }, { stage, source });
			// setStageCounts(stage);
			setCandidateCounts({
				candidatePipeline, candidateStage, isArchived, atsArchiveReason,
			});
			const sourceKeys = Object.keys(source);
			setSourceOptions(
				sourcingSources
					.filter((s) => sourceKeys.includes(s.value))
					.map((s) => ({
						value: s.value,
						// label: <>{s.atsIcon} {s.atsLabel}</>,
						label: s.atsLabel,
					})),
			);
			setAtsStageOptions(
				Object.entries(atsStage)
					.map(([key, value]) => ({
						value: key,
						label: key === 'null' ? 'Not set' : key,
						count: value,
					}))
					.filter(({ value }) => (isAdmin && isStaffView) || (value && value !== 'null')),
			);
		} catch (error) {
			LocalDebug.logError({
				className, method, __filename, error,
			});
		}
	};

	// ----------------------------------- //
	// ----------- Table Columns --------- //
	// ----------------------------------- //

	const atsManagerCol = SourcingATSManagerColumn({ CellRendererComponent: SourcingATSHandlerCell });

	const creatorCol = TableColumns.userAvatarColumn(theme, {
		title: 'Creator', dataIndex: 'creator', removeInColumnFilter: true,
	});
	const managerCol = TableColumns.userAvatarColumn(theme, {
		title: 'Manager',
		dataIndex: 'manager',
		removeInColumnFilter: true,
		render: (value, row) => <SourcingManagerDropdown
			sourcing={row}
			onSelect={handleSubmitManager}
		>
			{row.manager && TableColumns.userAvatarColumn(theme).render(value, row)}
		</SourcingManagerDropdown>,
	});
	const companyLogoCol = TableColumns.companyLogoColumn(theme, { title: 'Client' });

	const createdAtCol = TableColumns.createdAtColumn(theme, TableColumns.dateColumnLiteProps());
	const updatedAtCol = TableColumns.updatedAtColumn(theme, TableColumns.dateColumnLiteProps());

	const removeBtnCol = {
		title: '',
		dataIndex: 'remove',
		align: 'center',
		render: (_, row) => {
			const hasAtsSync = !!row?.atsSync?.atsProvider;
			const button = <SourcingDeleter
				title='' sourcing={row}
				withTooltip={true} tooltipTitle='Delete'
				onConfirmComplete={handleRefresh}
			/>;
			return button;
			// return !hasAtsSync && button;
		},
		...TableColumns.columnMinWidth(30),
	};

	const actionsCol = {
		title: 'Actions',
		dataIndex: ['actions'],
		align: 'center',
		// fixed: 'right',
		render: (_, row) => {
			return <Space direction='horizontal' size={8}>
				{/* <UserShortlister user={row?.user} job={row?.job}/> */}
				<SourcingContacter sourcing={row} withPrimaryStyle={true} />
				{/* <UserContacter user={row?.user} withPrimaryStyle={true} /> */}
				{/* <UserAtsSender user={row?.user} /> */}
				{/* <Divider type='vertical' /> */}
				{/* <SourcingDeleter */}
				{/*    title="" sourcing={row} */}
				{/*    withTooltip={true} tooltipTitle='Remove' */}
				{/*    onConfirmComplete={handleRefresh} */}
				{/* /> */}
				{/* <SourcingArchiver sourcing={row} onComplete={handleRefresh} title="" /> */}
			</Space>;
		},
		// ...TableColumns.columnMinWidth(isStaffView ? 180: 120),
		...TableColumns.columnMinWidth(120),
		// ...TableColumns.columnMinWidth(60),
	};

	const actionsCol2 = {
		title: 'Actions',
		dataIndex: ['actions'],
		// fixed: 'right',
		render: (_, row) => {
			const menuComment =				<Menu.Item key='comment' icon={<FaCommentDots />} onClick={() => handleAddComment(row)}>
					Add a comment
			</Menu.Item>;
			const menuStage =				<Menu.Item key='stage' icon={<FaEdit />}
						   onClick={() => handleOpenStageEditModal(row, SOURCING_ACTION_STAGE)}>
					Edit stage
			</Menu.Item>;
			const menuArchive = !row.isArchived
				&& <Menu.Item key='archive' icon={<FaFolder />}
						   onClick={() => handleOpenArchiveModal(row, SOURCING_ACTION_ARCHIVE)}>
					Archive
				</Menu.Item>;
			const menuDelete =				<Menu.Item key='delete' icon={null}>
				<ActionButton
					icon={<><FaTrash style={{ marginBottom: -2 }} /> <strong>Delete</strong></>}
					styles={{ color: theme.color.orange, width: '100%' }}
					title='Are you sure you want to delete this row？'
					okText='Yes' cancelText='No'
					onConfirm={() => handleDeleteSourcing(row)}
					withConfirm
				/>
			</Menu.Item>;

			const hasAtsSync = !!row?.atsSync?.atsProvider;
			const dropdownMenu =				<Menu style={{ fontSize: 12, padding: 0, borderRadius: 0 }}>
				{/* {menuComment} */}
				{/* {!hasAtsSync && menuStage} */}
				{/* {!hasAtsSync && menuArchive} */}
				{!hasAtsSync && menuDelete}
			</Menu>;

			return <SourcingContacter
				sourcing={row}
				onComplete={(values) => handleMessageSubmitted(row, values)}
			/>;

			// <Divider type='vertical' />
			// {isStaffView && <SourcingDeleter
			//     title="" sourcing={row}
			//     withTooltip={true} tooltipTitle='Remove this suggestion'
			//     onConfirmComplete={handleRefresh}
			// />}

			// return <UserContacter
			//     // title=""
			//     user={row?.user}
			//     onComplete={(values) => handleMessageSubmitted(row, values)}
			// />;
			//
			// return <Space direction='horizontal' size={4}>
			//     <UserContacter
			//         title="" user={row?.user}
			//         onComplete={(values) => handleMessageSubmitted(row, values)}
			//     />
			//     {!hasAtsSync && <Dropdown
			//         overlay={dropdownMenu}
			//         arrow={true}
			//         placement="topLeft"
			//         overlayStyle={{minWidth: 'unset'}}
			//         trigger={['click']}
			//     >
			//         <div>
			//             <ActionButton icon={<FaEllipsisH/>} tooltipTitle=""/>
			//         </div>
			//     </Dropdown>
			//     }
			// </Space>;
		},
		...TableColumns.columnMinWidth(60),
	};

	const archiveReasonsCol = TableColumns.sourcingArchiveReasonsColumn(theme);

	const atsStageCol = {
		title: 'Stage',
		dataIndex: 'atsStage',
		align: 'center',
		removeInColumnFilter: true,
		render: (value, row) => <SourcingATSStageTag
			sourcing={row}
			// onClick={() => { handleOpenStageEditModal(row) }}
		/>,
		...TableColumns.columnMinWidth(120),
	};

	const atsStageStaffCol = {
		title: 'Stage',
		dataIndex: 'atsStage',
		align: 'center',
		removeInColumnFilter: true,
		render: (value, row) => (
			<Popover
				title="Stages"
				content={
					row.atsStage
				}
			>
				<Space direction='vertical' align='center' size={0}>
					<SourcingATSStageTag
						sourcing={row}
						// onClick={() => { handleOpenStageEditModal(row) }}
					/>
					{(row?.candidatePipeline || row?.candidateStage)
				&& <span style={{ fontSize: 8, color: theme.color.grey, lineHeight: 1 }}>
					<i>{row?.candidatePipeline}/{row?.candidateStage}</i>
				</span>}
				</Space>
			</Popover>
		),
		...TableColumns.columnMinWidth(120),
	};

	const sourcingStubCol = {
		title: 'Candidate',
		sorter: false,
		fixed: 'left',
		dataIndex: 'user',
		isFilter: false,
		customOptions: {
			searchFields: [
				{ label: 'Candidate', value: 'user' },
				{ label: 'Job', value: 'job' },
			],
		},
		render: (value, row) => (
			<Space size={10}>
				{TableColumns.activityAtColumn(theme).render(value, row)}
				{TableColumns.sourcingStubLiteColumn(theme, {
					withUserPreview: false,
					// removeInColumnFilter: true,
					onRefresh: handleRefresh,
					onSelectJob: handleJobSelection,
					withUserPreviewButtons: false,
				}).render(value, row)}
			</Space>
		),
		...TableColumns.columnMinWidth(360),
	};

	const sourcingStubCol2 = TableColumns.sourcingStubLiteColumn2(theme, {
		title: 'Candidate',
		sorter: false,
		withUserPreview: false,
		// removeInColumnFilter: true,
		onRefresh: handleRefresh,
		onSelectJob: handleJobSelection,
		withUserPreviewButtons: false,
	});

	const renderSourceTag = (value, row) => {
		const { label, icon } = (((sourcingSources.filter((s) => s.value === value) || [])[0]) || {});
		return <Space direction='horizontal' size={4}
					  style={{ padding: '3px 6px', background: '#eeeeff', borderRadius: 4 }} align='center'>
			<div style={{ fontSize: 16, color: '#0000ff', marginBottom: -4 }}>{icon}</div>
			<div style={{ whiteSpace: 'nowrap', fontWeight: 'bold' }}>{label}</div>
			{/* <Tag color={color} icon={icon} style={{fontWeight: 'bold'}}>{label}</Tag> */}
		</Space>;
	};

	const renderSourceTag2 = (value, row) => {
		const { atsLabel, atsIcon } = (((sourcingSources.filter((s) => s.value === value) || [])[0]) || {});
		return <Space direction='horizontal'
					  size={4}
					  style={{
						  // padding: '2px 6px',
						  // background: '#eeeeff',
						  // borderRadius: 4
					  }}
					  align='center'
		>
			<div style={{ fontSize: 16, color: '#0000ff', marginBottom: -0 }}>{atsIcon}</div>
			<div style={{ whiteSpace: 'nowrap', fontWeight: 'bold' }}>{atsLabel}</div>
			{/* <Tag color={color} icon={icon} style={{fontWeight: 'bold'}}>{label}</Tag> */}
		</Space>;
	};

	const sourcingSourceCol = {
		title: 'Origin',
		dataIndex: 'source',
		isFilter: false,
		sorter: true,
		removeInColumnFilter: true,
		align: 'center',
		render: renderSourceTag,
		...TableColumns.columnMinWidth(150),
	};

	const sourcingSourceCol2 = {
		title: 'Origin',
		dataIndex: 'source',
		isFilter: false,
		// sorter: true,
		removeInColumnFilter: true,
		align: 'center',
		render: renderSourceTag2,
		...TableColumns.columnMinWidth(150),
	};

	const sortOptions = [
		{ value: JSON.stringify({ sortField: 'createdAt', sortOrder: 'desc' }), label: 'Creation (newest first)' },
		{ value: JSON.stringify({ sortField: 'createdAt', sortOrder: 'asc' }), label: 'Creation (oldest first)' },
		{ value: JSON.stringify({ sortField: 'updatedAt', sortOrder: 'desc' }), label: 'Activity (latest first)' },
		{ value: JSON.stringify({ sortField: 'updatedAt', sortOrder: 'asc' }), label: 'Activity (oldest first)' },
		{ value: JSON.stringify({ sortField: 'atsStage', sortOrder: 'asc' }), label: 'Stage (A-Z)' },
		{ value: JSON.stringify({ sortField: 'atsStage', sortOrder: 'desc' }), label: 'Stage (Z-A)' },
		// { value: JSON.stringify({sortField: 'source', sortOrder: 'asc'}), label: 'Origin (A-Z)' },
		// { value: JSON.stringify({sortField: 'source', sortOrder: 'desc'}), label: 'Origin (Z-A)' },
	];

	const columns = isStaffView
		? [
			sourcingStubCol,
			TableColumns.jsonColumn(theme),
			// companyLogoCol,
			...isArchived || showArchived
				? [archiveReasonsCol]
				: [atsStageStaffCol],
			sourcingSourceCol2,
			actionsCol,
			...isAtsSyncable
				? [atsManagerCol]
				: [],
			// createdAtCol,
			// updatedAtCol,
			managerCol,
			creatorCol,
			// messagesToCol,
			removeBtnCol,
		]
		: [
			sourcingStubCol,
			...isArchived || showArchived
				? [archiveReasonsCol]
				: [atsStageCol],
			// sourcingSourceCol2,
			actionsCol,
			...isAtsSyncable
				? [atsManagerCol]
				: [],
			// createdAtCol,
			// updatedAtCol,
			managerCol,
			// removeBtnCol,
		];

	return (
		<>
			<Table
				path={path}
				onDataLoaded={handleDataLoaded}
				loadOnInit={false}
				columns={columns}
				bordered={false}
				filterOptions={filterOptions}
				onSearchDataColumn={onSearchDataColumn}
				isRefresh={isRefresh + isRefreshDataLayout + dataRefresh}
				defaultDataSource={dataSource}
				withClientSidePagination={withClientSidePagination}
				withTableHeader={withTableHeader}
				limit={10}
				withDynamicSearch={withDynamicSearch}
				withSort={withSort}
				sortOptions={sortOptions}
				sortStyle={{ select: { width: 180 } }}
				// expandable={{
				//     expandedRowRender: sourcing => <SourcingHistory sourcing={sourcing} />,
				//     rowExpandable: record => record.name !== 'Not Expandable',
				// }}
			/>
			<SourcingActionPopover
				open={openActionPopover}
				initialValues={initialValuesActionForm}
				onSubmit={handleSubmitAction}
				onReset={() => setOpenActionPopover(false)}
				title='Add action'
			/>
			<SourcingCommentPopover
				open={openCommentPopover}
				initialValues={initialValuesCommentForm}
				onSubmit={handleSubmitComment}
				onReset={() => setOpenCommentPopover(false)}
				title='Add a comment'
			/>
			<ModalConfirmAction
				open={openConfirmAction}
				onClose={() => setOpenConfirmAction(false)}
				action={currentAction}
				initialValues={actionConfirmInitialValues}
				onConfirm={handleSubmitAction}
			/>
			<SourcingEditModal
				open={openEditModal}
				onClose={() => setOpenEditModal(false)}
				initialValues={editInitialValues}
				onConfirm={handleSubmitEdit}
			/>
			<SourcingStageEditModal
				open={openStageEditModal}
				onClose={() => setOpenStageEditModal(false)}
				action={currentAction}
				initialValues={stageEditInitialValues}
				onConfirm={handleSubmitStage}
			/>
			<SourcingArchiveModal
				open={openArchiveModal}
				onClose={() => setOpenArchiveModal(false)}
				action={currentAction}
				initialValues={archiveInitialValues}
				onConfirm={handleSubmitArchive}
			/>
			<SourcingJobPopover
				open={openSourcingEditJob}
				onReset={() => setOpenSourcingEditJob(false)}
				initialValues={sourcingEditJobInitialValues}
				onSubmit={handleSubmitSelectJob}
			/>
			<MessagePopover
				open={openMessagePopover}
				initialValues={initialValuesMessageForm}
				onClose={() => setOpenMessagePopover(false)}
				onSubmit={handleMessageSubmit}
				onReset={() => setOpenMessagePopover(false)}
			/>
			<UserDrawerProfile
				open={openProfile}
				user={userSelected}
				activeTab={activeTab}
				onClose={handleCloseProfile}
			/>
		</>
	);
};

export default CandidateTable;
