import { useTheme } from '@emotion/react';
import { GENDERSCORE_PILLAR_VALUES, getPillarColorMain } from '../../../../constants/genderscore-pillars';
import { useMemo, useState } from 'react';
import { Space } from 'antd';
import GenderScorePillarLogoLabel from '../pillars/GenderScorePillarLogoLabel';
import CompanyGenderScoreRingScore from '../CompanyGenderScoreRingScore';
import GenderScoreLayoutBox from '../layout/GenderScoreLayoutBox';
import GenderScoreActionGrid from './GenderScoreActionGrid';
import { writeGenderScoreFilledLog } from '../../../../constants/genderscore-logs';
import { COMPANY_RECRUITER_LOG } from '../../../../constants/company-recruiter-log';
import { getPillarActions } from '../genderscore.utils';

const GenderScoreActionPlanSection = ({
	company,
	forceValue,
	title,
	pillar,
	withActionSelectButton,
	collapsed = 3,
	index,
	...props
}) => {
	const className = 'GenderScoreActionPlanSection';

	const theme = useTheme();

	const [selectedPillar, setSelectedPillar] = useState();

	const handleSelectPillar = (p) => {
		writeGenderScoreFilledLog({
			type: {
				element: p,
				action: COMPANY_RECRUITER_LOG.CLICK,
			},
			context: `actions-pillar-${p === selectedPillar ? 'un' : ''}select`,
		});

		setSelectedPillar(p === selectedPillar ? null : p);
	};
	const { pillarActions } = useMemo(() => (
		getPillarActions({ company, forceValue, pillar })
	), [company, forceValue, pillar]);

	if (!pillarActions?.length > 0) return null;

	return (
		<GenderScoreLayoutBox
			withShadow={false}
			{...props}
			style={{
				padding: 24,
				background: theme.color.bgGrey,
				...props?.style,
			}}
		>
			{title && (
				<div
					style={{
						textAlign: 'center',
						margin: '12px auto',
						fontSize: 16,
						margin: '12px auto',
					}}>
					{title}
				</div>
			)}

			{!pillar && (
				<Space
					wrap={true}
					style={{
						width: '100%',
						justifyContent: 'center',
						alignItems: 'center',
						textAlign: 'center',
						marginBottom: 36,
					}}
				>
					{GENDERSCORE_PILLAR_VALUES
						.map((pillarValue) => {
							const isSelected = pillarValue === selectedPillar;
							const styles = isSelected
								? {
									background: theme.color.fitBlueElectric,
									color: 'white',
									borderColor: 'transparent',
									boxShadow: 'none',
								}
								: {
									background: 'white',
									borderColor: '#eee',
									boxShadow: '0px 2px 10px -2px rgba(0,0,0,.3)',
								};
							const labelStyle = isSelected
								? {
									color: 'white',
								}
								: {};
							const ringColor = isSelected
								? 'white'
								: getPillarColorMain(pillarValue);

							return (
								<div
									key={pillarValue}
									style={{
										padding: '2px 6px 2px 15px',
										alignItems: 'center',
										border: '1px solid #eee',
										borderRadius: 30,
										cursor: 'pointer',
										userSelect: 'none',
										...styles,
									}}
								>
									<Space
										wrap={false}
										onClick={() => handleSelectPillar(pillarValue)}
										size={12}
										style={{
											alignItems: 'center',
										}}
									>
										<div
											style={{
												marginTop: 4,
											}}
										>
											<GenderScorePillarLogoLabel
												pillar={pillarValue}
												iconSize={20}
												labelSize={14}
												style={{
													label: {
														marginTop: 1,
														fontWeight: 'bold',
														...labelStyle,
													},
												}}
											/>
										</div>
										<CompanyGenderScoreRingScore
											company={company}
											pillar={pillarValue}
											ringColor={ringColor}
											size={24}
											style={{
												score: labelStyle,
											}}
										/>
									</Space>
								</div>
							);
						})
					}
				</Space>
			)}

			<GenderScoreActionGrid
				company={company}
				forceValue={forceValue}
				pillar={pillar || selectedPillar}
				withSelectButton={withActionSelectButton}
				collapsed={collapsed || 3}
				style={{
					grid: props?.style?.grid,
				}}
			/>

		</GenderScoreLayoutBox>
	);
};

export default GenderScoreActionPlanSection;
