import { onEnterBuilder } from '../../../actions/app';
import DBChartsPage from '../../../containers/staff/data/DBChartsPage';
import { PERMS_GRANT_STAFF } from '../../menu.utils';

const menu = {
	name: 'staff-data-talent-chart',
	path: '/staff/data/talent-chart',
	label: 'DB Charts',
	icon: <span>🥧</span>,
	component: DBChartsPage,
	onEnter: onEnterBuilder({
		origin: DBChartsPage.name,
		shouldGoToPreviousHistory: true,
	}),
	perms: PERMS_GRANT_STAFF,
};

export default menu;
