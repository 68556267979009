import { useState } from 'react';
import { useSelector } from 'react-redux';
import PageLayout from '../../components/app/layout/PageLayout';
import { useTheme } from '@emotion/react';
import { getCompanySelected } from '../../reducers/app';
import CompanyGridPicker from '../../components/company/CompanyGridPicker';
import GenderScoreDashboardEmpty from '../../components/company/genderscore/dashboard/GenderScoreDashboardEmpty';
import GenderScoreDashboardWithLayout from '../../components/company/genderscore/dashboard/GenderScoreDashboardWithLayout';
import { Switch } from 'antd';
import CompanyModel from '../../models/CompanyModel';

const GenderScoreDashboardPage = () => {
	const className = 'GenderScoreDashboardPage';

	const theme = useTheme();

	const companySelected = useSelector(getCompanySelected);

	if (!companySelected) return <CompanyGridPicker />;

	const isGenderScoreFilled = new CompanyModel(companySelected)?.isGenderScoreFilled() || false;

	return (
		<PageLayout
			withHeader={false}
			withPadding={false}
		>
			{isGenderScoreFilled
				? <GenderScoreDashboardWithLayout company={companySelected} />
				: <GenderScoreDashboardEmpty company={companySelected} />
			}
		</PageLayout>
	);
};

export default GenderScoreDashboardPage;
