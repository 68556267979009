import { optionalizeQuestion } from '../../shared';
import QUESTIONS from '../../questions';
import { GENDERSCORE_PILLAR_GENDERDATA_OPTION } from '../../../../../constants/genderscore-pillars';

const section = {
	...GENDERSCORE_PILLAR_GENDERDATA_OPTION,
	details: `
### Let's start by taking stock of the gender situation

Inclusive Tech companies are tracking the percentage of women in their workforce and specifically in Engineering, Data and Product departments.`,
	questions: [
		QUESTIONS.GENDERDATA_OVERALLPCT,
		optionalizeQuestion({
			...QUESTIONS.GENDERDATA_TECHPCT,
			defaultSkippedValue: 27,
		}),
		optionalizeQuestion(QUESTIONS.GENDERDATA_ENGPCT),
		optionalizeQuestion(QUESTIONS.GENDERDATA_PRODUCTPCT),
		optionalizeQuestion(QUESTIONS.GENDERDATA_DATAPCT),
		QUESTIONS.GENDERDATA_MGTPCT,
		QUESTIONS.GENDERDATA_CLEVELPCT,
		QUESTIONS.GENDERDATA_BOARDPCT,
		QUESTIONS.GENDERDATA_ISRATED,
		{
			...QUESTIONS.GENDERDATA_RATING,
			clause: (answers) => answers?.[QUESTIONS.GENDERDATA_ISRATED.questionId] === 'yes',
		},
		QUESTIONS.GENDERDATA_ISTURNOVER,
		optionalizeQuestion({
			...QUESTIONS.GENDERDATA_TURNOVERPCT,
			clause: (answers) => answers?.[QUESTIONS.GENDERDATA_ISTURNOVER.questionId] === 'yes',
		}),
		optionalizeQuestion(QUESTIONS.GENDERDATA_PROMOTEDPCT),
	],
};

export default section;
