import {
	Avatar, Space, Tooltip, Typography,
} from 'antd';
import { Image, Transformation } from 'cloudinary-react';
import { useTheme } from '@emotion/react';
import UserLinkDropdown from './UserLinkDropdown';
import CompanyLogo from '../../company/CompanyLogo';
import { useAuthContext } from '../../../hooks/useAuthContext';
import { LocalDebug } from '../../../utils/LocalDebug';
import UserAvatarTooltipTitle from './UserAvatarTooltipTitle';

const UserAvatar = (
	{
		user,
		forceShow = false,
		forceHide = false,
		radius,
		size = 36,
		withTooltip = true,
		withTooltipEmail = false,
		withQuickLook = false,
		withIsNew = true,
		withIsNewlyOnboarded = true,
		withIsUnviewed = true,
		withCompanyLogo = true,
		withYou = false,
		tooltipPlacement,
		...props
	},
) => {
	const className = 'UserAvatar';
	const theme = useTheme();

	const { currentUserModel } = useAuthContext();

	const firstName = user?.firstName || '';
	const lastName = user?.lastName || '';
	const initials = ((firstName || '').slice(0, 1) + (lastName || '').slice(0, 1)).toUpperCase();

	const borderRadius = radius >= 0 ? radius : size;

	const getAvatarUrl = (user) => {
		try {
			const { information: { avatar } } = user;
			if (avatar === 'assets/avatars/default-avatar.jpg') return null;
			const parts = avatar.split('/upload/').reverse()[0].split('/');
			parts.shift();
			return parts.join('/');
		} catch (e) {}
		return null;
	};
	let avatarUrl = getAvatarUrl(user);

	if (!currentUserModel?.canDisplayTalentAvatars?.(user) && !forceShow) {
		avatarUrl = null;
	}

	if (forceHide) {
		avatarUrl = null;
	}

	const colors = theme.avatarColors;
	const index1 = (firstName.length * [...firstName].reduce((prev, cur) => prev + cur.charCodeAt(0), 0)) % colors.length;
	const index2 = (lastName.length * [...lastName].reduce((prev, cur) => prev + cur.charCodeAt(0), 0)) % colors.length;
	const deg = [...firstName + lastName + firstName + lastName + firstName + lastName + firstName + lastName].reduce((prev, cur) => prev + cur.charCodeAt(0), 0) % 180;

	const backgroundColors = initials.slice(0, 1) === '+'
		? [theme.color.black, theme.color.black]
		: [
			colors[index1],
			colors[index2],
		];

	const textColor = 'white';

	const dotSize = Math.round(Math.max(10, 10 + (size - 36) * 7 / 44));
	const dotBorder = Math.round(Math.max(1, 1 + (size - 36) * 1 / 44));
	const circleBorder = Math.ceil(size ** 0.16);

	const dot = (dSize, dBorder, circleSize, backgroundColor, borderColor, children) => <div
		style={{
			position: 'absolute',
			top: '50%',
			left: '50%',
			marginTop: Math.round(Math.sqrt(2) * circleSize / 4) - ((dSize + dBorder) / 2),
			marginLeft: Math.round(Math.sqrt(2) * circleSize / 4) - ((dSize + dBorder) / 2),
			background: backgroundColor,
			width: dSize,
			height: dSize,
			borderRadius: dSize,
			border: `${dBorder}px solid ${borderColor}`,
			// boxSizing: 'border-box',
		}}>
		{children}
	</div>;

	const circle = (size, border, color) => <div
		style={{
			position: 'absolute',
			top: '50%',
			left: '50%',
			marginTop: -Math.round(size / 2),
			marginLeft: -Math.round(size / 2),
			width: size,
			height: size,
			borderRadius,
			border: `${border}px solid ${color}`,
			transition: 'all 1s ease-out',
			boxSizing: 'border-box',
		}}/>;

	const emojiFontSize = Math.min(24, Math.round(size / 36 * 14));
	const component = <div>
		<div style={{ position: 'relative' }}>
			{/* Do not remove the following Avatar, needed to make async loading of Drawer components work (not sure why???) */}
			<Avatar size={0} style={{ position: 'absolute', top: 0, left: 0 }}/>
			{avatarUrl
				? <Avatar
					shape='square'
					{...props}
					style={{
						width: size,
						height: size,
						position: 'relative',
						borderRadius,
						...props?.style,
					}}
					src={
						<Image width={size} height={size} publicId={avatarUrl} secure="true">
							<Transformation width={2 * size} height={2 * size} gravity="face" crop="thumb"/>
							<Transformation radius={borderRadius}/>
						</Image>
					}
				/>
				: <Avatar
					shape='square'
					{...props}
					size={size}
					style={{
						position: 'relative',
						// backgroundColor,
						background: `linear-gradient(${deg}deg,${backgroundColors.join(',')})`,
						color: textColor,
						fontWeight: 'bold',
						fontSize: `${Math.round(size / 2.2)}px`,
						borderRadius,
						...props?.style,
					}}
				>
					<span style={{ userSelect: 'none' }}>
						{initials}
					</span>
				</Avatar>
			}

			{!withIsNewlyOnboarded && withIsUnviewed && !withTooltip && !user?.company && !user?.isViewed
				&& <Tooltip title="Profile not opened yet">
					{circle(size, circleBorder, theme.color.darkturquoise)}
				</Tooltip>}
			{!withIsNewlyOnboarded && withIsNew && !withTooltip && !user?.company && user?.isNew
				&& <Tooltip title="New profile on 50inTech!">
					{dot(
						dotSize * 2.2,
						dotBorder,
						size,
						'transparent',
						'transparent',
						<div style={{
							fontSize: emojiFontSize, display: 'flex', justifyContent: 'center', alignItems: 'center',
						}}>🆕</div>,
					)}
				</Tooltip>}
			{!withIsNewlyOnboarded && user?.company && withCompanyLogo && <CompanyLogo
				company={user?.company}
				size={12}
				style={{
					position: 'absolute',
					top: '50%',
					left: '50%',
					marginTop: Math.round(size / 2 - 10),
					marginLeft: Math.round(size / 2 - 10),
					border: '1px solid rgba(255, 255, 255, 1)',
				}}
			/>}
			{withIsNewlyOnboarded && !withTooltip && user?.isNewlyOnboarded
				&& <Tooltip color={'white'}
					title={<div style={{ padding: 5 }}>
						<Typography.Paragraph>🌟 <b>Enhanced Profile!</b></Typography.Paragraph>
						<Typography.Paragraph style={{ marginBottom: 0 }}>
						This candidate has enriched their profile to provide you with more reliable information
						</Typography.Paragraph>
					</div>}
				>
					{/* {circle(size, circleBorder, theme.color.orange)} */}
					{dot(
						dotSize * 2.2,
						dotBorder,
						size,
						'transparent',
						'transparent',
						<div style={{
							fontSize: emojiFontSize,
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
							userSelect: 'none',
						}}>🌟</div>,
					)}
				</Tooltip>}
			{withYou && size > 30 && circle(size, circleBorder, theme.color.fitBlueElectric)}

		</div>
	</div>;

	const ComponentWithQuickLook = user && withQuickLook
		? <UserLinkDropdown user={user}>{component}</UserLinkDropdown> : component;

	return user
		? (<>
			{withTooltip
				? <Tooltip placement={tooltipPlacement || 'top'}
					title={<UserAvatarTooltipTitle
						user={user}
						withEmail={withTooltipEmail}
						withYou={withYou}
					/>}
				>
					{ComponentWithQuickLook}
				</Tooltip>
				: ComponentWithQuickLook
			}
		</>)
		: null;
};

export default UserAvatar;
