import { useTheme } from '@emotion/react';
import { useState } from 'react';
import { Button, Popover, Space } from 'antd';
import Table from '../../../app/table/Table';
import TableColumns from '../../../app/table/TableColumns';
import { displayHtml } from '../../../../utils/common';
import { localDebug } from '../../../../utils/LocalDebug';
import UserDrawerProfile from '../../../user/UserDrawerProfile';
import { useAuthContext } from '../../../../hooks/useAuthContext';
import { FaCommentDots } from 'react-icons/fa';

const FeedbackTable = (
	{
		onDataLoaded,
		path = '/feedback',
		filterOptions = {},
		isRefresh = 0,
		onSearchDataColumn,
		onToggleIsActive,
		onEditArticle,
		isEditor,
		...props
	},
) => {
	const theme = useTheme();

	const { isStaffView } = useAuthContext();

	const [isRefreshDataLayout, setIsRefreshDataLayout] = useState(0);

	const [openProfile, setOpenProfile] = useState(false);
	const [activeTab, setActiveTab] = useState(null);
	const [userSelected, setUserSelected] = useState(null);

	const handleCloseProfile = () => {
		setUserSelected(null);
		setActiveTab(null);
		setOpenProfile(false);
	};

	const handleDrawerRefresh = () => {
		localDebug('FeedbackTable.handleDrawerRefresh');
		setIsRefreshDataLayout((prev) => prev + 1);
	};

	const userStubCol = TableColumns.userStubColumn(theme, { withDropdown: true, title: 'Sender' });
	const companyLogoCol = TableColumns.companyLogoColumn(theme);
	const categoryCol = {
		title: 'Category',
		dataIndex: ['payload', 'category'],
		removeInColumnFilter: true,
		sorter: false,
		...TableColumns.columnFixedWidth(100),
	};
	const messageCol = {
		title: 'Message',
		dataIndex: 'message',
		removeInColumnFilter: true,
		sorter: false,
		render: (value, row) => <Popover title={<b>Message</b>}
					 content={
						 <Space style={{ maxWidth: '400px', fontSize: 14 }}>
							 {displayHtml(value)}
						 </Space>}>
			<Button type='link' icon={<FaCommentDots style={{ marginBottom: -2 }}/>}/>
		</Popover>,
		...TableColumns.columnFixedWidth(80),
	};
	const createdAtCol = TableColumns.createdAtColumn(theme);

	const columns = isStaffView
		? [
			userStubCol,
			companyLogoCol,
			categoryCol,
			messageCol,
			createdAtCol,
		]
		: [
			userStubCol,
			companyLogoCol,
			categoryCol,
			messageCol,
			createdAtCol,
		];

	return (
		<>
			<Table
				path={path}
				onDataLoaded={onDataLoaded}
				columns={columns}
				filterOptions={filterOptions}
				isRefresh={isRefresh + isRefreshDataLayout}
				onSearchDataColumn={onSearchDataColumn}
				showTotal={(total) => `Total ${total} items`}
				limit={10}
				rowClassName={(row, index) => (row.is50Content === false ? 'rowDisabled' : '')}
				withDynamicSearch
				{...props}
			/>

			<UserDrawerProfile
				user={userSelected}
				activeTab={activeTab}
				open={openProfile}
				onClose={handleCloseProfile}
				onRefresh={handleDrawerRefresh}
			/>
		</>
	);
};

export default FeedbackTable;
