import { useTheme } from '@emotion/react';
import {
	Form, InputNumber, Select,
} from 'antd';
import FormSubmitButton from '../../app/form/FormSubmitButton';
import FormItem from '../../company/form/FormItem';
import { FaArrowRight } from 'react-icons/fa';
import { requiredRule } from '../../../utils/common';

const SECTOR_OPTIONS = require('../../../constants/sector.json');

const SurveyInfoForm = ({
	onSubmit,
}) => {
	const theme = useTheme();

	const [form] = Form.useForm();

	return (<>
		<Form
			form={form}
			name='survey-info-form'
			labelCol={{ span: 6 }}
			wrapperCol={{ span: 18 }}
			labelAlign='left'
			layout='horizontal'
			onFinish={onSubmit}
		>
			<FormItem
				name='headcount'
				label='Headcount'
				defaultContainer='div'
				rules={[requiredRule]}
			>
				<InputNumber min={0} />
			</FormItem>

			<FormItem
				name='sector'
				label='Sector'
				defaultContainer='div'
				rules={[requiredRule]}
			>
				<Select
					options={SECTOR_OPTIONS.map((label) => ({ value: label, label }))}
					mode='single'
				/>
			</FormItem>

			<FormItem
				label={<>&nbsp;</>}
				style={{ marginBottom: 0 }}
			>
				<FormSubmitButton
					disabled={false}
					style={{ width: '100%', marginTop: 12 }}
					size={'large'}
					icon={null}
				>
					Take me to my results
					<FaArrowRight
						style={{
							marginLeft: 6,
							marginBottom: -1,
							fontSize: 14,
						}}
					/>
				</FormSubmitButton>
			</FormItem>
		</Form>
	</>
	);
};

export default SurveyInfoForm;
