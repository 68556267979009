import { localDebug } from '../utils/LocalDebug';
import { documentId } from '../utils/common';
import { request } from './api';

const findAll = async (options) => {
	const dataParams = { params: { ...options } };
	return request.get('/company', dataParams);
};

const getById = (id, params) => {
	const dataParams = { params };
	return request.get(`/company/${id}`, dataParams);
};

const findAllAccess = async (companyId) => {
	// localDebug('findAllAccess', { companyId });
	const dataParams = { params: { companyId } };
	return request.get('/company/access', dataParams);
};

const findAllAtsArchiveReasons = async () => {
	localDebug('services.company.findAllAtsArchiveReasons');
	return request.get('/company/ats-archive-reasons');
};

const findAllAtsPipelines = async () => {
	// localDebug('services.company.findAllAtsPipelines');
	return request.get('/company/ats-pipelines');
};

const findAllAtsStages = async () => {
	// localDebug('services.company.findAllAtsStages');
	return request.get('/company/ats-stages');
};

const findAllRecruiters = async () => {
	// localDebug('services.company.findAllAtsStages');
	return request.get('/company/recruiters');
};

const setAtsStageMapping = async (atsStageId, remoteStageId) => {
	// localDebug('services.company.setAtsStageMapping');
	return request.put(`/access/ats-stage/${atsStageId}/map`, { remoteStageId });
};

const setAtsArchiveReasonMapping = async (atsArchiveReasonId, remoteArchiveReasonId) => {
	// localDebug('services.company.setAtsStageMapping');
	return request.put(`/access/ats-archive-reason/${atsArchiveReasonId}/map`, { remoteArchiveReasonId });
};

const getLastBatch = async (companyId) => {
	const dataParams = { params: { companyId } };
	return request.get('/company/batch', dataParams);
};

const createAccess = (data) => {
	return request.post('/company/access', data);
};

const updateAccess = (accessId, data) => {
	return request.put(`/company/access/${accessId}`, data);
};

const removeAccess = (access) => {
	return request.delete(`/company/access/${documentId(access)}`);
};

const testAccessPermission = (companyAccessId, permissionLabel) => {
	return request.get(`/access/${companyAccessId}/ats-permissions/${permissionLabel}/test`);
};

const fetchJobs = (data) => {
	return request.post('/company/access/fetchJobs', data);
};

const fetchAccessJobs = (id) => {
	return request.get(`/company/access/${id}/fetchJobs`);
};

const updateCompanyTags = (data) => {
	return request.post('/company/tag', data);
};

const updateOrCreateCompany = (data) => {
	return request.post('/company', data);
};

const getValuesByField = (field) => {
	return request.get(`/company/all/${field}`);
};

const getIndustryOptions = () => {
	return request.get('/company/industries');
};

const saveCompany = (data) => {
	return request.post('/company', data);
};

const inviteUser = (companyId, data) => {
	return request.post(`/company/${companyId}/invite`, data);
};

const getStories = (companyId) => {
	return request.get(`/company/${companyId}/storiesLegacy`);
};

const getJobPriorities = (companyId) => {
	return request.get(`/company/${companyId}/jobPriorities`);
};

const getAcls = (companyId) => {
	return request.get(`/company/${companyId}/acls`);
};

const getManagers = (companyId, options = {}) => {
	const dataParams = { params: { ...options } };
	return request.get(`/company/${companyId}/managers`, dataParams);
};

const syncAtsSourcings = (companyId) => {
	return request.get(`/company/${companyId}/syncAtsSourcings`);
};

const createOrUpdateCompanyMember = (data) => {
	return request.post('/company/member', data);
};

const recomputeTags = (companyId) => {
	return request.get(`/company/${companyId}/computeTags`);
};

const getAllCompanyPlansExtraTags = () => {
	return request.get('/company/all/companyPlansExtraTags');
};

const postUpgradeRequest = (companyId) => {
	return request.post('/company/upgrade-request', { companyId });
};

const postGenderScoreAction = (data) => {
	return request.post('/genderscore/action', data);
};
const postCompanyActionPlan = (data) => {
	return request.post('/company/actionplan', data);
};

const getGenderScoreSurvey = (id, params) => {
	return request.get(`/genderscore/survey/${id}`, { params });
};

const listGenderScoreSurveys = (params) => {
	return request.get('/genderscore/survey', { params });
};

const createOrUpdateGenderScoreSurveyDraft = (data) => {
	return request.post('/genderscore/survey/draft', data);
};

const saveGenderScoreSurveyDraftAnswers = (data) => {
	return request.post('/genderscore/survey/draft/answers', data);
};

const submitGenderScoreSurveyDraft = (data) => {
	return request.post('/genderscore/survey/draft/submit', data);
};
const updateExpirationDate = (data) => {
	return request.post('/genderscore/setup', data);
};
const createFakeSurvey = (data) => {
	return request.post('/genderscore/survey/fake', data);
};
const deleteSurveys = (data) => {
	return request.delete(`/genderscore/survey/${data?.companyId}?status=${data?.status}`);
};

/* *** UMS *** */

const getUMSSurvey = (id, params) => {
	return request.get(`/ums/survey/${id}`, { params });
};

const listUMSSurveys = (params) => {
	return request.get('/ums/survey', { params });
};

const createOrUpdateUMSSurveyDraft = (data) => {
	return request.post('/ums/survey/draft', data);
};

const saveUMSSurveyDraftAnswers = (data) => {
	return request.post('/ums/survey/draft/answers', data);
};

const submitUMSSurveyDraft = (data) => {
	return request.post('/ums/survey/draft/submit', data);
};
const updateUMSExpirationDate = (data) => {
	return request.post('/ums/setup', data);
};
const createFakeUMSSurvey = (data) => {
	return request.post('/ums/survey/fake', data);
};
const deleteUMSSurveys = (data) => {
	return request.delete(`/ums/survey/${data?.companyId}?status=${data?.status}`);
};

const CompanyService = {
	findAll,
	getById,
	getLastBatch,
	findAllAccess,
	findAllAtsArchiveReasons,
	findAllAtsPipelines,
	findAllAtsStages,
	findAllRecruiters,
	createAccess,
	updateAccess,
	removeAccess,
	fetchJobs,
	fetchAccessJobs,
	updateCompanyTags,
	getAllCompanyPlansExtraTags,
	updateOrCreateCompany,
	getValuesByField,
	saveCompany,
	inviteUser,
	getStories,
	getJobPriorities,
	getAcls,
	getManagers,
	testAccessPermission,
	setAtsArchiveReasonMapping,
	setAtsStageMapping,
	syncAtsSourcings,
	createOrUpdateCompanyMember,
	getIndustryOptions,
	recomputeTags,
	postUpgradeRequest,

	// GenderScore Action
	postGenderScoreAction,
	postCompanyActionPlan,

	// GenderScore Survey
	getGenderScoreSurvey,
	listGenderScoreSurveys,
	createOrUpdateGenderScoreSurveyDraft,
	saveGenderScoreSurveyDraftAnswers,
	submitGenderScoreSurveyDraft,

	// GenderScore setup
	updateExpirationDate,
	deleteSurveys,
	createFakeSurvey,

	// GenderScore Survey
	getUMSSurvey,
	listUMSSurveys,
	createOrUpdateUMSSurveyDraft,
	saveUMSSurveyDraftAnswers,
	submitUMSSurveyDraft,

	// GenderScore setup
	updateUMSExpirationDate,
	deleteUMSSurveys,
	createFakeUMSSurvey,
};

export default CompanyService;
