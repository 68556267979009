import { mixColors } from '../../utils/common';
import { UMS_LABEL } from '../constant';

const UMS_STATUS_KEY = 'ums-status';

const UMS_STATUS_MULTI = false;
const UMS_STATUS_FREE = false;

export const UMS_SURVEY_STATUS_DRAFT_VALUE = 'DRAFT';
export const UMS_SURVEY_STATUS_CURRENT_VALUE = 'CURRENT';
export const UMS_SURVEY_STATUS_PREVIOUS_VALUE = 'PREVIOUS';
export const UMS_SURVEY_STATUS_LEAD_VALUE = 'LEAD';
export const UMS_SURVEY_STATUS_REVIEW_VALUE = 'REVIEW';

export const UMS_SURVEY_STATUS_DRAFT_OPTION = {
	value: UMS_SURVEY_STATUS_DRAFT_VALUE,
	label: UMS_SURVEY_STATUS_DRAFT_VALUE,
	color: '#FF635B',
	tagStyle: { border: '1px dashed #FF635B', color: '#FF635B' },
};

export const UMS_SURVEY_STATUS_CURRENT_OPTION = {
	value: UMS_SURVEY_STATUS_CURRENT_VALUE,
	label: UMS_SURVEY_STATUS_CURRENT_VALUE,
	color: '#00ced1',
	tagStyle: { background: '#00ced1', color: 'white' },
};

export const UMS_SURVEY_STATUS_PREVIOUS_OPTION = {
	value: UMS_SURVEY_STATUS_PREVIOUS_VALUE,
	label: UMS_SURVEY_STATUS_PREVIOUS_VALUE,
	color: mixColors('#00ced1', '#ffffff', 50),
	tagStyle: { border: '1px solid #00ced1', color: '#00ced1' },
};

export const UMS_SURVEY_STATUS_LEAD_OPTION = {
	value: UMS_SURVEY_STATUS_LEAD_VALUE,
	label: UMS_SURVEY_STATUS_LEAD_VALUE,
	color: '#881cff',
	tagStyle: { background: mixColors('#881cff', '#ffffff', 60), color: 'white' },
};

export const UMS_SURVEY_STATUS_REVIEW_OPTION = {
	value: UMS_SURVEY_STATUS_REVIEW_VALUE,
	label: UMS_SURVEY_STATUS_REVIEW_VALUE,
	color: '#9D9D9D',
	tagStyle: { border: '1px dashed #9D9D9D', color: '#9D9D9D' },
};

export const UMS_STATUS_OPTIONS = [
	UMS_SURVEY_STATUS_DRAFT_OPTION,
	UMS_SURVEY_STATUS_CURRENT_OPTION,
	UMS_SURVEY_STATUS_PREVIOUS_OPTION,
	UMS_SURVEY_STATUS_LEAD_OPTION,
	UMS_SURVEY_STATUS_REVIEW_OPTION,
];

export const getUMSStatusOption = (value) => (
	UMS_STATUS_OPTIONS?.find((o) => o?.value === value) || {}
);

export const getUMSStatusLabel = (value) => (
	getUMSStatusOption(value)?.label
);

export const getUMSStatusColor = (value) => (
	getUMSStatusOption(value)?.color
);

export const getUMSStatusTag = (value, style) => (
	<span
		style={{
			fontSize: 12,
			width: 'auto',
			fontWeight: 'bold',
			padding: '2px 4px',
			borderRadius: 3,
			lineHeight: 1.2,
			...getUMSStatusOption(value)?.tagStyle,
			...style || {},
		}}
	>
		{getUMSStatusLabel(value)}
	</span>
);

export const UMS_STATUS_VALUES = UMS_STATUS_OPTIONS.map((o) => o.value);

const exporter = {
	key: UMS_STATUS_KEY,
	UMS_STATUS_KEY,
	UMS_SURVEY_STATUS_DRAFT_OPTION,
	UMS_SURVEY_STATUS_CURRENT_OPTION,
	UMS_SURVEY_STATUS_PREVIOUS_OPTION,
	UMS_SURVEY_STATUS_LEAD_OPTION,
	UMS_SURVEY_STATUS_REVIEW_OPTION,
	multi: UMS_STATUS_MULTI,
	UMS_STATUS_MULTI,
	options: UMS_STATUS_OPTIONS,
	UMS_STATUS_OPTIONS,
	free: UMS_STATUS_FREE,
	UMS_STATUS_FREE,
	values: UMS_STATUS_VALUES,
	UMS_STATUS_VALUES,
	getUMSStatusOption,
	getUMSStatusLabel,
	getUMSStatusColor,
	getUMSStatusTag,
};

export default exporter;
