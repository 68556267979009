import CompanyActionPlanPage from '../../containers/genderscore/CompanyActionPlanPage';
import { PERM_GRANT_TYPE } from '../menu.utils';
import roles from '../../config/roles';
import MenuBase from '../MenuBase';
import { FireOutlined } from '@ant-design/icons';
import { useTheme } from '@emotion/react';
import { useSelector } from 'react-redux';
import { getCompanySelected } from '../../reducers/app';
import { useEffect, useState } from 'react';
import MenuBadge from '../MenuBadge';

export const CompanyActionPlanIcon = FireOutlined;

export const CompanyActionPlanLabel = ({
	withBadge = false,
}) => {
	const theme = useTheme();
	const companySelected = useSelector(getCompanySelected);

	const [actionPlanCount, setActionPlanCount] = useState(0);

	useEffect(() => {
		setActionPlanCount(companySelected?.actionPlan?.actions
			?.filter((a) => a?.isActive !== false)?.length || 0);
	}, [companySelected]);

	const label = 'Action plan v2';

	if (!withBadge || !(actionPlanCount > 0)) {
		return label;
	}

	return (
		<>
			<span>{label}</span>
			{theme && actionPlanCount > 0 && (
				<MenuBadge
					value={actionPlanCount}
					// overflowCount={10}
				/>
			)}
		</>
	);
};

class CompanyActionPlanMenu extends MenuBase {
	static props = {
		name: 'company-action-plan',
		path: '/action-plan',
		label: () => (args) => <CompanyActionPlanLabel {...args} />,
		icon: <CompanyActionPlanIcon />,
		iconLegacy: '🎯',
		Component: CompanyActionPlanPage,
		perms: [
			{
				type: PERM_GRANT_TYPE,
				roles: [
					roles.STAFF,
				],
				genderScoreEnabled: true,
			},
		],
	};

	constructor() {
		super(CompanyActionPlanMenu.props);
	}
}

export default CompanyActionPlanMenu;
