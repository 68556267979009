import {
	Collapse, Descriptions, Divider, Row, Space, Tag,
} from 'antd';
import { useContext, useEffect, useState } from 'react';
import { useTheme } from '@emotion/react';
import { FaExternalLinkAlt, FaGithub, FaLinkedin } from 'react-icons/fa';
import Link from '../../app/Link';
import UserService from '../../../services/user';
import { useAuthContext } from '../../../hooks/useAuthContext';
import TableColumns from '../../app/table/TableColumns';
import {
	BOOLEAN_NO_LABEL, BOOLEAN_YES_LABEL, getCareerSeekingOption,
	getJobRemoteLabel, getJobRoleLabel,
	groupHardSkills, NOT_PROVIDED_YET_LABEL, TAG_ADMIN, TAG_STAFF, userTag50Style,
} from '../../../constants/constant';
import UserLevel from '../widgets/UserJobLevel';
import UserCareerSeeking from '../widgets/UserCareerSeeking';
import GridValueCard from '../../app/card/GridValueCard';
import Callout from '../../app/callout/Callout';
import FeedbackBanner from '../../staff/product/feedback/FeedbackBanner';
import UserModel from '../../../models/UserModel';
import UserResumeTopSkillsCard from '../card/resume/UserResumeTopSkillsCard';
import UserResumeCertificationsCard from '../card/resume/UserResumeCertificationsCard';
import JSONPretty from 'react-json-pretty';
import UserResumeManagerCard from '../card/resume/UserResumeManagerCard';
import UserResumeSummaryCard from '../card/resume/UserResumeSummaryCard';
import UserResumeExperienceCard from '../card/resume/UserResumeExperienceCard';
import UserResumeEducationCard from '../card/resume/UserResumeEducationCard';
import UserResumeContactCard from '../card/resume/UserResumeContactCard';
import UserProfileDEIFitCard from '../card/profile/UserProfileDEIFitCard';
import UserProfileLanguagesCard from '../card/profile/UserProfileLanguagesCard';
import UserProfileEmailCard from '../card/profile/UserProfileEmailCard';
import UserProfileCareerSpecificsCard from '../card/profile/UserProfileCareerSpecificsCard';
import { useSelector } from 'react-redux';
import { getCompanySelected } from '../../../reducers/app';
import { LocalDebug } from '../../../utils/LocalDebug';
import UserProfileJobSearchJobLocationsCard from '../card/profile/UserProfileJobSearchJobLocationsCard';
import UserLinks from './UserLinks';
import { arrayWrap, documentId } from '../../../utils/common';
import TAG_JOB_SEARCH_JOB_REMOTE from '../../../constants/tag/constants/job-search/job-search-job-remote';
import { UserStoreContext } from '../../../contexts/UserStoreProvider';

const UserInfoTab = (
	{
		user,
		onRefresh,
	},
) => {
	const className = 'UserInfoTab';
	const theme = useTheme();

	const companySelected = useSelector(getCompanySelected);

	const { fetchUser: dataFetchUser } = useContext(UserStoreContext);
	const { isAdmin, isStaffView } = useAuthContext();

	const [isLoading, setIsLoading] = useState(true);
	const [userModel, setUserModel] = useState(null);

	useEffect(() => {
		if (!user) {
			setUserModel(null);
			setIsLoading(false);
			return;
		}
		setUserModel(new UserModel(user));
		setIsLoading(false);
	}, [user]);

	const userJobRoles = () => {
		const roles = userModel?.jobSearch?.jobRole;
		if (!roles || roles.length === 0) return null;
		return <Space wrap={true} size={4}>
			{roles.map((role, index) => <Tag
				key={index}
				// color='#f5f5f5'
				color='blue'
				style={{
					fontSize: 14,
					margin: 0,
					marginRight: 0,
				}}
			>
				{getJobRoleLabel(role)}
			</Tag>)}
		</Space>;
	};

	const userHardSkill = () => {
		const hardSkills = userModel.career?.hardSkill;
		if (!hardSkills || hardSkills.length === 0) return null;
		const groups = groupHardSkills(hardSkills);
		return Object
			.entries(groups)
			.map(([group, labels], index) => <span key={index}>
				{index > 0 && <Divider style={{ margin: '10px 0 0 0' }}/>}
				<Space direction='horizontal'
					   align='start'
					   style={{ width: '100%', ...index > 0 && { marginTop: 10 } }}
					   key={index}
				>
					<div style={{
						width: 160,
						fontWeight: 'bold',
						// marginTop: 3
					}}>
						{group}
					</div>
					<Space
						wrap={true}
						size={4}
					>
						{labels.map((label) => <Tag
							key={label}
							color="blue"
							style={{
								fontSize: 14,
								// padding: '2px 8px',
								margin: 0,
							}}>{label}</Tag>)}
					</Space>
				</Space>
			</span>);
	};

	const userRemoteCriteria = () => {
		const labels = arrayWrap(TAG_JOB_SEARCH_JOB_REMOTE.extractor?.(userModel) || [])
			?.map((item) => getJobRemoteLabel(item));
		return labels?.length > 0
			? (
				<Space wrap={true} size={[4, 4]}>{(
					labels.map((label, index) => (
						<Tag
							key={index}
							color="blue"
							style={{ fontSize: 14, margin: 0 }}
						>
							{label}
						</Tag>
					))
				)}</Space>
			)
			: NOT_PROVIDED_YET_LABEL;
	};

	const renderDispatchCardsRow = (cards) => {
		const cardsWithSpans = cards.filter((c) => c.span);
		const totalFixedSpans = cardsWithSpans.reduce((p, c) => p + (c.span || 0), 0);
		return <>{cards.map(({ Component, span, ...props }, index, arr) => {
			return <Component
				key={index}
				span={span || Math.floor((24 - totalFixedSpans) / (arr.length - cardsWithSpans.length))}
				user={userModel}
				{...props}
			/>;
		})}</>;
	};

	return (userModel && !isLoading && <>

		<Row gutter={[0, 20]} style={{ marginBottom: 20 }}>

			<Callout
				style={{ background: theme.background.gradientBluePink(315) }}
			>
				<span style={{
					fontStyle: 'italic', fontSize: 13, fontWeight: 'bold', color: 'rgba(0,0,0,.7)',
				}}>
                What the talent has declared about herself in her 50inTech profile
				</span>
			</Callout>

			{isStaffView && <UserResumeManagerCard span={24} user={user} onRefresh={onRefresh}/>}

			{renderDispatchCardsRow([
				...(
					userModel?.hasResumeSummary()
					|| userModel?.hasResumeContact()
					|| userModel?.hasResumeFiles()
				)
					? [{ Component: UserResumeSummaryCard, hideIfEmpty: false }]
					: [],
				...(
					userModel?.hasResumeContact()
					|| userModel?.hasResumeFiles()
				)
					? [{ Component: UserResumeContactCard, span: 7 }]
					: [],
			])}

			<GridValueCard span={7} title="Searching status">
				<UserCareerSeeking user={user}/>
			</GridValueCard>

			<UserProfileDEIFitCard user={userModel} span={9} hideIfEmpty={false}/>

			<UserProfileEmailCard user={userModel} span={8} hideIfEmpty={false}/>

			{renderDispatchCardsRow([
				...userModel.hasCareerLanguage() ? [{ Component: UserProfileLanguagesCard }] : [],
				...userModel.hasJobSearchJobLocations() ? [{ Component: UserProfileJobSearchJobLocationsCard }] : [],
				...userModel?.hasResumeTopSkills() ? [{ Component: UserResumeTopSkillsCard }] : [],
			])}

			{!user?.isCoachingVisible({ isStaffView }) && <GridValueCard span={8} title="Desired roles">
				{userJobRoles()}
			</GridValueCard>}
			<GridValueCard span={16} title="Hard Skills">{userHardSkill()}</GridValueCard>
			{!user?.isCoachingVisible({ isStaffView }) && <GridValueCard span={8} title="Seniority" details="">
				<UserLevel user={user} style={{ ...userTag50Style }}/>
			</GridValueCard>}

			{<GridValueCard span={8} title="Remote criteria"
				details='What the talent wants for her next job in terms of remote work'>
				{userRemoteCriteria()}
			</GridValueCard>}

			<UserProfileCareerSpecificsCard user={userModel} span={8} hideIfEmpty={true}/>

			{!userModel?.hasResume() && <GridValueCard span={8} title="More info">
				<UserLinks userModel={userModel}/>
			</GridValueCard>}

			<UserResumeExperienceCard span={24} user={userModel}/>
			{renderDispatchCardsRow([
				...userModel?.hasResumeEducation() ? [{ Component: UserResumeEducationCard }] : [],
				...userModel?.hasResumeCertifications() ? [{ Component: UserResumeCertificationsCard, span: 8 }] : [],
			])}
		</Row>

		<Divider/>

		<FeedbackBanner/>

		{isStaffView
			&& <Collapse ghost style={{ maxWidth: '100%', marginBottom: 20 }}>
				<Collapse.Panel header={<b>More details {TAG_STAFF}</b>} key="1">
					<GridValueCard title="Department" span={8}>{userModel.career?.department}</GridValueCard>

					<Descriptions layout="horizontal" bordered>
						<Descriptions.Item label="First name" span={3}>{userModel.firstName}</Descriptions.Item>
						<Descriptions.Item label="Last name" span={3}>{userModel.lastName}</Descriptions.Item>
						<Descriptions.Item label="Email" span={3}>{userModel.email}</Descriptions.Item>
						<Descriptions.Item label="Personal link"
										   span={3}>{userModel.information?.website}</Descriptions.Item>
						<Descriptions.Item label="Registered at" span={1}>
							{TableColumns.createdAtColumn(theme).render(userModel.createdAt, userModel)}
						</Descriptions.Item>
						<Descriptions.Item label="Updated at" span={1}>
							{TableColumns.updatedAtColumn(theme).render(userModel.updatedAt, userModel)}
						</Descriptions.Item>
						<Descriptions.Item label="Last seen" span={1}>
							{TableColumns.dateColumn(theme).render(userModel.loggedAt, userModel)}
						</Descriptions.Item>
						<Descriptions.Item label="ID" span={3}><code
							className="slug">{documentId(userModel)}</code></Descriptions.Item>
						<Descriptions.Item label="UID" span={3}><code
							className="slug">{userModel.uid}</code></Descriptions.Item>
						<Descriptions.Item label="authState" span={3}>{userModel.auth?.authState}</Descriptions.Item>
						<Descriptions.Item label="Is onboarded"
										   span={3}>{userModel.auth?.isOnboarded ? BOOLEAN_YES_LABEL : BOOLEAN_NO_LABEL}</Descriptions.Item>
						<Descriptions.Item label="Email verified"
										   span={3}>{userModel.auth?.emailVerified ? BOOLEAN_YES_LABEL : BOOLEAN_NO_LABEL}</Descriptions.Item>
						<Descriptions.Item label="Community consent"
										   span={3}>{userModel.confidential?.communityConsent ? BOOLEAN_YES_LABEL : BOOLEAN_NO_LABEL}</Descriptions.Item>
						<Descriptions.Item label="Hunting consent"
										   span={3}>{userModel.jobSearch?.huntingConsent ? BOOLEAN_YES_LABEL : BOOLEAN_NO_LABEL}</Descriptions.Item>
						<Descriptions.Item label="Profile step"
										   span={3}>{userModel.auth?.profileStep || userModel?.profileStep || userModel?.information?.profileStep}</Descriptions.Item>
					</Descriptions>
				</Collapse.Panel>
			</Collapse>
		}

		{isAdmin && isStaffView
			&& <Collapse ghost style={{ maxWidth: '100%', marginBottom: 20 }}>
				<Collapse.Panel header={<b>Rest {TAG_ADMIN}</b>} key="1">
					{/* <Descriptions layout="horizontal" bordered> */}
					{(() => {
						const {
							_id, id, email, firstName, lastName, slug, createdAt, updatedAt, loggedAt,
							coachId, coach, sourcings, actions, tagTree,
							information, jobSearch, matches, auth, career,
							coaching,
							...rest
						} = userModel;
						return <JSONPretty data={userModel}/>;
					})()}
				</Collapse.Panel>
			</Collapse>
		}
	</>);
};

export default UserInfoTab;
