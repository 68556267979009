import PageLayout from '../../components/app/layout/PageLayout';
import StaffGSRankingsMenu from '../../menu/staff/genderscore/StaffGSRankingsMenu';
import GenderScoreRankings from './GenderScoreRankings';

const StaffGenderScoreRankingsPage = () => {
	const className = 'StaffGenderScoreRankingsPage';

	return (
		<PageLayout
			MenuClass={StaffGSRankingsMenu}
		>

			<GenderScoreRankings />

		</PageLayout>
	);
};

export default StaffGenderScoreRankingsPage;
