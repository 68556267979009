import { Space } from 'antd';
import { useContext, useEffect, useState } from 'react';
import { useTheme } from '@emotion/react';
import { FaArrowRight } from 'react-icons/fa';
import Link from '../../../app/Link';
import { CompanyPageContext } from './CompanyPageProvider';
import { getNextIncompleteMandatoryStep } from './CompanyPageSteps';
import { documentId } from '../../../../utils/common';

export const CompanyPageNextStepLink = (
	{
		company,
		step,
		...props
	},
) => {
	const className = 'CompanyPageNextStepLink';

	const theme = useTheme();

	const {
		lang,
		setCurrentlyEditingStep,
	} = useContext(CompanyPageContext);

	const [nextStep, setNextStep] = useState(step);

	const handleClickBanner = () => {
		setCurrentlyEditingStep(documentId(nextStep));
	};

	useEffect(() => {
		if (!company || !lang || step) return;
		setNextStep(getNextIncompleteMandatoryStep({ company, lang }));
	}, [company, lang]);

	if (!nextStep || !nextStep?.title) return null;

	return (
		<Space onClick={() => handleClickBanner()}>
			<Link
				style={{
					textDecoration: 'underline',
					fontWeight: 'bold',
					color: theme.color.accent,
					...props?.style,
				}}
			>
				Next step: {nextStep?.title} <FaArrowRight style={{ marginBottom: -2 }} />
			</Link>
		</Space>
	);
};

export default CompanyPageNextStepLink;
