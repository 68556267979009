import { useTheme } from '@emotion/react';
import moment from 'moment';
import { Col, Row } from 'antd';
import { CalendarOutlined, ReadOutlined } from '@ant-design/icons';

const UserResumeEducationEntryRow = (
	{
		entry,
		colSpans = [14, 10],
		...props
	},
) => {
	const theme = useTheme();

	const {
		academy, degree, startDate, endDate,
	} = entry;
	return (
		<Row
			gutter={[20, 20]}
			{...props}
		>
			<Col span={colSpans?.[0] || 14}>
				<div
					style={{
						fontSize: 18,
						fontWeight: 'bold',
						lineHeight: 1.2,
						marginBottom: 8,
						...props?.style?.degree,
					}}>
					{degree}
				</div>
			</Col>
			<Col span={colSpans?.[1] || 10}>
				<div
					style={{
						fontSize: 14,
						fontWeight: 'bold',
						lineHeight: 1.2,
						color: theme.color.darkgrey,
						marginBottom: 4,
						...props?.style?.academy,
					}}
				>
					<ReadOutlined/>&nbsp;{academy}
				</div>
				<p
					style={{
						fontSize: 13,
						color: theme.color.darkgrey,
						...props?.style?.dates,
					}}
				>
					<CalendarOutlined style={{ color: theme.color.grey }}/>&nbsp;
					{[
						startDate,
						...endDate !== startDate ? [endDate] : [],
					].map((d) => moment(d).format('YYYY')).join(' - ')}
				</p>
			</Col>
		</Row>
	);
};

export default UserResumeEducationEntryRow;
