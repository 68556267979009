import { Select } from 'antd';
import {
	BUSINESS_PLAN_GROUP_OPTIONS,
	BUSINESS_PLAN_OPTIONS,
} from '../../../constants/property/business-plan';
import CompanyPlanTag from '../CompanyPlanTag';

const CompanyPlanSelect = ({
	...props
}) => {
	const className = 'CompanyPlanSelect';

	return (
		<Select
			{...props}
		>
			{BUSINESS_PLAN_GROUP_OPTIONS
				.map((planGroup) => (
					<Select.OptGroup
						key={planGroup.value}
						value={planGroup.value}
						label={planGroup.label}
					>
						{BUSINESS_PLAN_OPTIONS
							.filter((plan) => plan.group === planGroup.value)
							.map((plan) => (
								<Select.Option
									key={plan.value}
									value={plan.value}
									label={plan.label}
								>
									<CompanyPlanTag company={{ companyPlan: { planValue: plan.value } }} />
								</Select.Option>
							))}
					</Select.OptGroup>
				))}
		</Select>
	);
};

export default CompanyPlanSelect;
