import { UMS_SURVEY_EMPLOYEE_V1_OPTION } from '../../../../../constants/property/ums-survey';
import shared from '../../shared';
import sectionQuestions from './section-questions';
import QUESTIONS from '../../questions';

const scorify = (questions) => questions.map((question) => ({
	question,
	weight: 1,
	computer: shared.COMPUTE_VALUE,
	ranges: 100 / 5,
}));

export const UMS_SURVEY_EMPLOYEE_V1_DATA_OPTION = {
	...UMS_SURVEY_EMPLOYEE_V1_OPTION,
	details: `Une série d'affirmations va vous être présentée. 
	Vous devrez spécifier dans quelle mesure chacune d'elle reflète votre opinion, en indiquant à quel point vous êtes en accord avec celle-ci sur une échelle de 1 à 6.
	
	**Il n'y a pas de bonnes ou mauvaises réponses, mais elles doivent être basées sur vos propres opinions. 
	Veuillez noter que vos réponses resteront anonymes.**`,
	sections: [
		sectionQuestions,
	],
	scoring: {
		sffp: scorify([
			QUESTIONS.EMP_SFFP_ALLOCATE_MORE_TIME,
			QUESTIONS.EMP_SFFP_BEHAVE_DIFFERENTLY,
			QUESTIONS.EMP_SFFP_EXPECTATIONS_BEHAVIOR,
			QUESTIONS.EMP_SFFP_EXPECTATIONS_RESPONSIBILITIES,
			QUESTIONS.EMP_SFFP_PERSONAL_INFORM_PERFORMANCE,
			QUESTIONS.EMP_SFFP_PREDICTIONS_CONFIRMED,
			QUESTIONS.EMP_SFFP_REMEMBER_IF_CONFIRM,
		]),
		ble: scorify([
			QUESTIONS.EMP_BLE_IDEAS_CERTAIN_EMPS,
			QUESTIONS.EMP_BLE_NOT_CONSIDER_IF_OBLIGATIONS,
			QUESTIONS.EMP_BLE_SAME_TEAM_NOT_DISCUSSIONS,
			QUESTIONS.EMP_BLE_SAME_XP_NOT_SAME_SALARY,
			QUESTIONS.EMP_BLE_SOCIAL_SKILLS_EVAL,
			QUESTIONS.EMP_BLE_TRAITS_LIMIT_PROMOTION,
			QUESTIONS.EMP_BLE_UNASSERTIVE_NO_RESP,
		]),
		cte: scorify([
			QUESTIONS.EMP_CTE_ADJUST_COLLEAGUES,
			QUESTIONS.EMP_CTE_ASSESS_PERF_COMPARISON_MOST,
			QUESTIONS.EMP_CTE_COMPARE_PERF_TENDENCY,
			QUESTIONS.EMP_CTE_COMPARING_IMPORTANT_ASPECT,
			QUESTIONS.EMP_CTE_EMP_SHOULD_COMPARE,
			QUESTIONS.EMP_CTE_EVALUATATION_RELATIVE_MOST,
			QUESTIONS.EMP_CTE_UNABLE_ASSESS_WITHOUT_COMPARE,
		]),
		hhe: scorify([
			QUESTIONS.EMP_HHE_FIRST_IMP_EVALUATION,
			QUESTIONS.EMP_HHE_FIRST_MEETING_GOOD_WORK,
			QUESTIONS.EMP_HHE_NEGATIVE_ATTRIBUTE_MORE,
			QUESTIONS.EMP_HHE_NEGATIVE_IMP_NEGATIVE_EVAL,
			QUESTIONS.EMP_HHE_POSITIVE_ATTRIBUTE_MORE,
			QUESTIONS.EMP_HHE_POSITIVE_FEEDBACK_GOOD_IMP,
			QUESTIONS.EMP_HHE_TRUST_INITIAL_IMP_EVAL,
		]),
		ovc: scorify([
			QUESTIONS.EMP_OVC_CRITICAL,
			QUESTIONS.EMP_OVC_EXPECTATIONS_UNMET_NEGATIVE,
			QUESTIONS.EMP_OVC_GENERAL_UNMET_EXPECTATIONS,
			QUESTIONS.EMP_OVC_HIGHLIGHT_NEGATIVE,
			QUESTIONS.EMP_OVC_NEGATIVE_MOTIVATIONAL,
			QUESTIONS.EMP_OVC_SKILLS_BEYOND_REQ,
			QUESTIONS.EMP_OVC_WEIGH_MORE_FAILURES,
		]),
		igf: scorify([
			QUESTIONS.EMP_IGF_CLOSER_RESEMBLE,
			QUESTIONS.EMP_IGF_COMMON_CHARS_IMPORTANT_WORK,
			QUESTIONS.EMP_IGF_RELY_IF_COMMON_CHARS,
			QUESTIONS.EMP_IGF_SIMILARITIES_ENJOY,
			QUESTIONS.EMP_IGF_SIMILAR_PEOPLE_WORK_BETTER,
			QUESTIONS.EMP_IGF_TEAM_LOOK_LIKE_MGR,
			QUESTIONS.EMP_IGF_TRAITS_SHARED_EVALUATION,
		]),
		rec: scorify([
			QUESTIONS.EMP_REC_LATEST_ACCOMPS_EVALUATION,
			QUESTIONS.EMP_REC_LATEST_ACCOMPS_INFORM_FUTURE,
			QUESTIONS.EMP_REC_LATEST_PERF_REFLECT_OVERALL,
			QUESTIONS.EMP_REC_MOST_RECENT_EVALUATION,
			QUESTIONS.EMP_REC_MOST_RECENT_INFORM_CAPABILITIES,
			QUESTIONS.EMP_REC_REMEMBER_MOST_RECENT,
			QUESTIONS.EMP_REC_UNNECESSARY_CONSIDER_BEGINNING,
		]),
	},
};

export default UMS_SURVEY_EMPLOYEE_V1_DATA_OPTION;
