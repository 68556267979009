import { getTags } from '../../../../reducers/app';
import TagTable from '../../../../components/staff/tags/TagTable';
import { useSelector } from 'react-redux';
import PageLayout from '../../../../components/app/layout/PageLayout';
import { useAuthContext } from '../../../../hooks/useAuthContext';

const TagsPage = () => {
	const tags = useSelector(getTags);
	const { isEditor } = useAuthContext();

	return (
		<PageLayout
			withBackground={false}
			title='Tag list'
		>
			<TagTable
				dataSource={tags}
				isEditor={isEditor}
			/>
		</PageLayout>
	);
};

export default TagsPage;
