import { Space, Tag, message } from 'antd';
import { writeRecruiterLog } from '../actions/logging';
import CompanyModel from '../models/CompanyModel';
import { COMPANY_RECRUITER_LOG } from './company-recruiter-log';
import ReactJson from 'react-json-view';
import { LocalDebug } from '../utils/LocalDebug';

const className = 'genderscore-logs';

export const GENDERSCORE_LOG_SCOPE = 'genderscore';
export const GENDERSCORE_LOG_SUBSCOPE_FILLED = 'filled';
export const GENDERSCORE_LOG_SUBSCOPE_NOT_FILLED = 'not-filled';

export const GENDERSCORE_LOG_FROM_ELEMENT_RESOURCE_CARD_LINK = {
	element: 'resource-card-link-btn',
	action: COMPANY_RECRUITER_LOG.EXTERNAL_LINK,
	subScope: GENDERSCORE_LOG_SUBSCOPE_FILLED,
};
export const GENDERSCORE_LOG_FROM_ELEMENT_JOB_STANDARDS_WORKSHEET_BTN = {
	element: 'job-standards-worksheet-btn',
	action: COMPANY_RECRUITER_LOG.EXTERNAL_LINK,
	subScope: GENDERSCORE_LOG_SUBSCOPE_FILLED,
};
export const GENDERSCORE_LOG_FROM_ELEMENT_ANNUAL_REVIEW_GUIDE_BTN = {
	element: 'annual-review-guide-btn',
	action: COMPANY_RECRUITER_LOG.EXTERNAL_LINK,
	subScope: GENDERSCORE_LOG_SUBSCOPE_FILLED,
};
export const GENDERSCORE_LOG_FROM_ELEMENT_BREAKDOWN_PILLARS_LINK = {
	element: 'breakdown-pillars-link',
	action: COMPANY_RECRUITER_LOG.INTERNAL_LINK,
	subScope: GENDERSCORE_LOG_SUBSCOPE_FILLED,
};
export const GENDERSCORE_LOG_FROM_ELEMENT_YOUR_LEVEL_LINK = {
	element: 'your-level-link',
	action: COMPANY_RECRUITER_LOG.INTERNAL_LINK,
	subScope: GENDERSCORE_LOG_SUBSCOPE_FILLED,
};
export const GENDERSCORE_LOG_FROM_ELEMENT_RECOMMENDATIONS_LINK = {
	element: 'recommendations-link',
	action: COMPANY_RECRUITER_LOG.INTERNAL_LINK,
	subScope: GENDERSCORE_LOG_SUBSCOPE_FILLED,
};
export const GENDERSCORE_LOG_FROM_ELEMENT_SEE_PILLAR_LINK = {
	element: 'see-pillar-details-link',
	action: COMPANY_RECRUITER_LOG.INTERNAL_LINK,
	subScope: GENDERSCORE_LOG_SUBSCOPE_FILLED,
};
export const GENDERSCORE_LOG_FROM_ELEMENT_NAVIGATION_BACK_BTN = {
	element: 'navigation-back-btn',
	action: COMPANY_RECRUITER_LOG.INTERNAL_LINK,
	subScope: GENDERSCORE_LOG_SUBSCOPE_FILLED,
};
export const GENDERSCORE_LOG_FROM_ELEMENT_NAVIGATION_NEXT_BTN = {
	element: 'navigation-next-btn',
	action: COMPANY_RECRUITER_LOG.INTERNAL_LINK,
	subScope: GENDERSCORE_LOG_SUBSCOPE_FILLED,
};
export const GENDERSCORE_LOG_FROM_ELEMENT_LEVEL_LINK_HOVER = {
	element: 'level-link',
	action: COMPANY_RECRUITER_LOG.HOVER,
	subScope: GENDERSCORE_LOG_SUBSCOPE_FILLED,
};
export const GENDERSCORE_LOG_FROM_ELEMENT_BIAS_BTN_HOVER = {
	element: 'bias-btn',
	action: COMPANY_RECRUITER_LOG.HOVER,
	subScope: GENDERSCORE_LOG_SUBSCOPE_FILLED,
};
export const GENDERSCORE_LOG_FROM_ELEMENT_LEARN_LEVELS_LINK = {
	element: 'learn-about-levels-link',
	action: COMPANY_RECRUITER_LOG.OPEN_DRAWER,
	subScope: GENDERSCORE_LOG_SUBSCOPE_FILLED,
};
export const GENDERSCORE_LOG_FROM_ELEMENT_ACTION_PLAN_LINK = {
	element: 'action-plan-link',
	action: COMPANY_RECRUITER_LOG.OPEN_DRAWER,
	subScope: GENDERSCORE_LOG_SUBSCOPE_FILLED,
};
export const GENDERSCORE_LOG_FROM_ELEMENT_FILTER_PILLAR_LINK = {
	element: 'filter-pillar',
	action: COMPANY_RECRUITER_LOG.CLICK,
	subScope: GENDERSCORE_LOG_SUBSCOPE_FILLED,
};
export const GENDERSCORE_LOG_FROM_ELEMENT_FILTER_TYPE_LINK = {
	element: 'filter-type',
	action: COMPANY_RECRUITER_LOG.CLICK,
	subScope: GENDERSCORE_LOG_SUBSCOPE_FILLED,
};
export const GENDERSCORE_LOG_FROM_ELEMENT_OPEN_SURVEY_BTN = {
	element: 'open-survey-btn',
	action: COMPANY_RECRUITER_LOG.CLICK,
	subScope: GENDERSCORE_LOG_SUBSCOPE_NOT_FILLED,
};

let key = 0;
export const writeGenderScoreLog = ({
	type, context, company, debug,
}) => {
	const actionLog = type?.action;
	LocalDebug.logInfo({ className, method: 'writeGenderScoreLog' }, { actionLog });
	if (!actionLog) {
		return;
	}
	let subScope = type?.subScope;
	if (company) {
		const companyModel = new CompanyModel(company);
		subScope = companyModel?.isGenderScoreFilled()
			? GENDERSCORE_LOG_SUBSCOPE_FILLED
			: GENDERSCORE_LOG_SUBSCOPE_NOT_FILLED;
	}
	const logData = [
		actionLog,
		{},
		{
			scope: GENDERSCORE_LOG_SCOPE,
			subScope,
			from: {
				element: type?.element,
				context,
			},
		},
	];
	LocalDebug.logInfo({ className, method: 'writeGenderScoreLog' }, { logData });
	key = (key + 1) % 2;
	if (debug) {
		message.info({
			key,
			// duration: 2,
			content: (
				<>
					<Space>
						<Space direction='vertical' size={0}>
							<span>action</span>
							<Tag><b>{actionLog}</b></Tag>
						</Space>
						{/* <Space direction='vertical' size={0}>
						<span>scope</span>
						<Tag><b>{logData[2]?.scope}</b></Tag>
					</Space> */}
						<Space direction='vertical' size={0}>
							<span>subScope</span>
							<Tag><b>{logData[2]?.subScope}</b></Tag>
						</Space>
						<Space direction='vertical' size={0}>
							<span>from.element</span>
							<Tag><b>{logData[2]?.from?.element}</b></Tag>
						</Space>
						<Space direction='vertical' size={0}>
							<span>from.context</span>
							<Tag><b>{logData[2]?.from?.context}</b></Tag>
						</Space>
						<ReactJson
							src={logData}
							collapsed={0}
							// sortKeys={true}
							style={{ fontSize: 10, textAlign: 'left' }}
						/>
					</Space>
				</>
			),
		});
	}
	writeRecruiterLog(...logData);
};

export const writeGenderScoreFilledLog = ({
	type, context, company,
}) => {
	writeGenderScoreLog({
		type: {
			...type,
			subScope: GENDERSCORE_LOG_SUBSCOPE_FILLED,
		},
		context,
		company,
	});
};
