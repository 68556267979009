import { ENGLISH_LANG, FRENCH_LANG } from '../constants/constant';
import { LocalDebug } from '../utils/LocalDebug';
import { mergeReducer } from '../utils/common';

const className = 'ResourceModel';

const fields = ['title', 'summary', 'image'];
const translationFieldName = 'translations';

const translationsOptions = {
	title: {
		[ENGLISH_LANG]: {
			label: 'Resource title',

		},
		[FRENCH_LANG]: {

			label: 'Titre de la resource',
		},
	},
	summary: {
		[ENGLISH_LANG]: {
			label: 'Summary of the resource',

		},
		[FRENCH_LANG]: {

			label: 'Résumé de la resource',
		},
	},
	image: {
		[ENGLISH_LANG]: {
			label: 'Image (jpg/png, 10MB max)',

		},
		[FRENCH_LANG]: {

			label: 'Image (jpg/png, 10Mo max)',
		},
	},
};

const translatableFields = [
	'title',
	'summary',
	'image',
];

export default class ResourceModel {
	id;

	userId;

	type;

	title;

	image;

	summary;

	link;

	slug;

	group;

	is50Content;

	translations;

	constructor(resource) {
		Object.assign(this, resource);
	}

	translate(lang) {
		const translatedValue = {
			...this,
			...this.translateFields(lang),
		};
		return translatedValue;
	}

	getTranslatedFieldValues(lang = ENGLISH_LANG) {
		if (lang === ENGLISH_LANG) {
			return translatableFields.map((f) => ({ [f]: this[f] })).reduce(mergeReducer, {});
		}
		return this.translations?.[lang] || {};
	}

	translateFields(lang = ENGLISH_LANG) {
		return fields.reduce((prev, cur) => {
			const defaultValue = this[cur];
			return {
				...prev,
				[`${cur}_${lang}`]: this.translateField(cur, lang, defaultValue),
			};
		}, {});
	}

	translateField(field, lang, defaultValue = '') {
		// other language
		const { translations } = this;
		return translations?.hasOwnProperty(lang) && translations[lang][field] ? translations[lang][field] : defaultValue;
	}

	setField(field, value, lang = ENGLISH_LANG) {
		LocalDebug.logInfo({ className, method: 'setField' }, { field, value, lang });
		if (lang === ENGLISH_LANG || !field.includes(translationFieldName)) {
			this[field] = value;
			return;
		}

		const [t, l, name] = field.split('-');
		if (!this.translations) {
			this[translationFieldName] = {
				[l]: {
					[name]: value,
				},
			};
		}
		this[t][l][name] = value;
	}

	getField(field, lang = ENGLISH_LANG, defaultValue) {
		if (lang === ENGLISH_LANG) {
			return this[field];
		}
		return this?.translations?.[lang]?.[field] || defaultValue;
	}

	getFieldName(field, lang) {
		if (lang === ENGLISH_LANG || !translatableFields.includes(field)) {
			return field;
		}
		return `${translationFieldName}-${lang}-${field}`;
	}

	getLabel(field, lang) {
		return translationsOptions?.[field]?.[lang]?.label || 'Default label';
	}

	getTranslatedValues() {
		const translations = this[translationFieldName] || {};
		return Object.keys(translations).reduce((prev, lang) => {
			const valuesLang = this[translationFieldName][lang];
			const toDb = Object.keys(valuesLang).reduce((prev, field) => {
				return {
					...prev,
					[`${translationFieldName}-${lang}-${field}`]: this.getField(field, lang),
				};
			}, {});

			return ({
				...prev,
				...toDb,
			});
		}, {});
	}

	isNotPublished() {
		return !this.getField('title') || !this.getField('content');
	}
}
