import LOCATION_COUNTRY from '../../property/location-country';
import { innerObjPropExtractor } from '../../property/property-extractor';
import { innerObjPropMapper } from '../../property/property-mapper';

const USER_PROPERTY_PROFILE_LOCATION_COUNTRY_KEY = 'profile-location-country';

const USER_PROPERTY_PROFILE_LOCATION_COUNTRY_FREE = false;

const USER_PROPERTY_PROFILE_LOCATION_COUNTRY_FIELD = 'information.location.country';
const USER_PROPERTY_PROFILE_LOCATION_COUNTRY_MAPPER = innerObjPropMapper(USER_PROPERTY_PROFILE_LOCATION_COUNTRY_FIELD);
const USER_PROPERTY_PROFILE_LOCATION_COUNTRY_EXTRACTOR = innerObjPropExtractor(USER_PROPERTY_PROFILE_LOCATION_COUNTRY_FIELD);

export default {
	...LOCATION_COUNTRY,
	key: USER_PROPERTY_PROFILE_LOCATION_COUNTRY_KEY,
	USER_PROPERTY_PROFILE_LOCATION_COUNTRY_KEY,
	free: USER_PROPERTY_PROFILE_LOCATION_COUNTRY_FREE,
	USER_PROPERTY_PROFILE_LOCATION_COUNTRY_FREE,
	mapper: USER_PROPERTY_PROFILE_LOCATION_COUNTRY_MAPPER,
	USER_PROPERTY_PROFILE_LOCATION_COUNTRY_MAPPER,
	extractor: USER_PROPERTY_PROFILE_LOCATION_COUNTRY_EXTRACTOR,
	USER_PROPERTY_PROFILE_LOCATION_COUNTRY_EXTRACTOR,
	field: USER_PROPERTY_PROFILE_LOCATION_COUNTRY_FIELD,
	USER_PROPERTY_PROFILE_LOCATION_COUNTRY_FIELD,
};
