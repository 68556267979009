import { useTheme } from '@emotion/react';
import PageLayout from '../../../components/app/layout/PageLayout';
import { useEffect, useState } from 'react';
import { request } from '../../../services/api';
import { LocalDebug } from '../../../utils/LocalDebug';
import StaffGSAnswersToActionsMenu from '../../../menu/staff/genderscore/StaffGSAnswersToActionsMenu';
import SurveyNavigationProvider from '../../../components/genderscore/survey/SurveyNavigationProvider';
import AdminCommandGetLink from '../../../components/staff/tools/AdminCommandGetLink';
import SurveyQuestionAnswer from '../../../components/genderscore/survey/SurveyQuestionAnswer';
import { getCompanySelected } from '../../../reducers/app';
import { useDispatch, useSelector } from 'react-redux';
import CompanyGridPicker from '../../../components/company/CompanyGridPicker';
import CompanyModel from '../../../models/CompanyModel';
import {
	Col, Row, Space,
} from 'antd';
import ReactJsonDebug from '../../../components/app/debug/ReactJsonDebug';
import {
	booleanOrReducer, documentId,
} from '../../../utils/common';
import { BOOLEAN_NO_ICON, BOOLEAN_YES_ICON } from '../../../constants/constant';
import QUESTIONS from '../../../components/genderscore/data/questions';
import SCORING from '../../../components/genderscore/data/scoring';
import { loadCompany } from '../../../actions/company';
import CompanyActionCard from '../../../components/company/actionPlan/CompanyActionCard';
import CompanyActionModal from '../../../components/company/actionPlan/action/CompanyActionModal';
import CompanyActionModel from '../../../models/CompanyActionModel';
import CompanyActionCompletionStatusTag from '../../../components/company/actionPlan/CompanyActionCompletionStatusTag';
import SURVEYS from '../../../components/genderscore/data/surveys';
import GENDERSCORE_SURVEY_50PLUS_V3_DATA_OPTION from '../../../components/genderscore/data/surveys/genderscore-survey-50-plus-v3';
import { round } from 'lodash';
import {
	getActionCompletionStatusFromAnswers,
	getActionQuestionCompletionStatusFromAnswers,
	isActionQuestionMatchingAnswer,
} from '../../../components/genderscore/data/action-plan.utils';
import SurveyScoringDetailsQuestion from '../../../components/genderscore/survey/SurveyScoringDetailsQuestion';

const NoValue = () => {
	const theme = useTheme();
	return (
		<span
			style={{
				fontSize: 10,
				fontStyle: 'italic',
				color: theme.color.grey,
			}}
		>
			N/A
		</span>
	);
};

const StaffGenderScoreAnswersToActionsPage = () => {
	const className = 'StaffGenderScoreAnswersToActionsPage';

	const theme = useTheme();
	const dispatch = useDispatch();

	const companySelected = useSelector(getCompanySelected);

	const [actions, setActions] = useState([]);
	const [openActionModal, setOpenActionModal] = useState(false);
	const [selectedAction, setSelectedAction] = useState();

	useEffect(() => {
		const launch = async () => {
			try {
				const result = await request.get('/admin/genderscore/actions');
				LocalDebug.logInfo({ className, method: 'launch' }, { result });
				setActions(result?.data?.results);
			} catch (e) {

			}
		};
		launch();
	}, []);

	if (!companySelected) return <CompanyGridPicker />;

	const survey = new CompanyModel(companySelected).getGenderScoreCurrentSurvey();
	// const surveyConf = surveys.getSurveyDataOption(survey.survey);
	const surveyConf = SURVEYS.getSurveyDataOption(GENDERSCORE_SURVEY_50PLUS_V3_DATA_OPTION.value);
	const answers = survey.answersV3;
	const questionScores = SCORING.computeSurveyQuestionScores({ surveyConf, answers, origin: className });

	const cellStyle = {
		fontSize: 10,
	};

	const handleOpenAction = (newSelectedAction) => {
		LocalDebug.logInfo({ className, method: 'handleOpenAction' }, { newSelectedAction });
		setSelectedAction(newSelectedAction);
		if (newSelectedAction) setOpenActionModal(true);
	};

	return (
		<PageLayout
			MenuClass={StaffGSAnswersToActionsMenu}
			rightActions={(
				<Space>
					<AdminCommandGetLink
						title={'Remap answers to V3'}
						path='/admin/company/remap-genderscore-survey-answers-v3'
						params={{ companyId: documentId(companySelected) }}
						callback={() => dispatch(loadCompany(documentId(companySelected)))}
					/>
					<AdminCommandGetLink
						title={'Compute cards from answers'}
						path='/admin/company/compute-genderscore-survey-answers-to-action-plan'
						params={{ companyId: documentId(companySelected) }}
						callback={() => dispatch(loadCompany(documentId(companySelected)))}
					/>
				</Space>
			)}
		>
			<SurveyNavigationProvider
				withLastVisitedPath={false}
			>
				<ReactJsonDebug
					src={{
						answers: survey?.answers || {},
						answersV3: survey?.answersV3 || {},
						answersV3Max: survey?.answersV3Max || {},
						surveyConf,
						questionScores,
					}}
					collapsed={0}
					name={'Answers'}
				/>

				{actions?.map?.((action, index) => {
					const shouldShowAction = action?.questions
						?.map((question) => isActionQuestionMatchingAnswer({
							questionId: question.questionId,
							operator: question.operator,
							clauses: question.clauses,
							answers,
							questionScores,
						}))
						?.reduce(booleanOrReducer, false)
						|| !(action?.questions?.length > 0);
					const actionCompletionStatus = action?.questions
						?.map((question) => isActionQuestionMatchingAnswer({
							questionId: question.questionId,
							operator: question.operator,
							clauses: question.clauses,
							answers,
							questionScores,
						}))
						?.reduce(booleanOrReducer, false)
						|| !(action?.questions?.length > 0);
					return (
						<div
							key={index}
							style={{
								display: 'grid',
								gridTemplateColumns: '340px 1fr',
								columnGap: 20,
								rowGap: 20,
							}}
						>
							<CompanyActionCard
								action={new CompanyActionModel({
									...action,
									completionStatus: getActionCompletionStatusFromAnswers({
										action,
										answers,
										questionScores,
									}),
								})}
								draggable={false}
								viaModal={false}
								withAddButton={false}
								onOpenAction={handleOpenAction}
							/>

							<Row
								key={index}
								gutter={10}
								style={{
									boxSizing: 'border-box',
									border: '1px solid #ccc',
									borderRadius: 10,
									marginBottom: 5,
									padding: '6px 10px',
									background: '#f9f9f9',
									background: shouldShowAction ? 'darkturquoise' : 'red',
								}}
							>

								<Col span={24} style={cellStyle}>
									{action?.questions?.map((question, questionIndex) => (
										<Row
											key={questionIndex}
											gutter={[10, 10]}
											style={{
												fontSize: 8,
												borderBottom: '1px solid #eee',
												border: '1px solid #eee',
												background: 'white',
												borderRadius: 7,
												marginBottom: 3,
												padding: '5px 10px',
											}}
										>
											{question.questionId
												? (
													<>
														<Col
															span={24}
															style={cellStyle}
														>
															<SurveyScoringDetailsQuestion
																questionId={question.questionId}
																surveyConf={surveyConf}
																answers={answers}
																withExpectedAnswer={false}
															/>
															{/* <b style={{ fontSize: 12 }}>{QUESTIONS[question.questionId].label?.replaceAll('**', '')}</b> */}
														</Col>
														<Col
															span={1}
															style={cellStyle}
														>
															{question.operator}
														</Col>
														<Col
															span={9}
															style={cellStyle}
														>
															{JSON.stringify(question.clauses)}
														</Col>
														<Col
															span={9}
															style={cellStyle}
														>
															<SurveyQuestionAnswer
																question={question}
																answer={survey.answersV3?.[question?.questionId]}
																style={{
																	tag: {
																		fontSize: 10,
																		padding: '0px 5px',
																	},
																}}
															/>
															<Space
																wrap={true}
																size={[4, 2]}
																style={{
																	width: '100%',
																	fontSize: 10,
																	color: '#999',
																	marginTop: 2,
																}}
															>
																{QUESTIONS?.[question.questionId]
																	?.options
																// ?.options?.map((o) => o.value)
																	?.filter?.((o) => !survey.answersV3?.[question?.questionId]?.includes(o.value))
																// ?.map((o) => o.value)
																	?.map((remainingOption, oIndex) => (
																		<div key={oIndex} style={{ border: '1px solid #ccc', borderRadius: 4, padding: '1px 4px' }}>{remainingOption.value}</div>
																	// <Tag key={oIndex} color='magenta'>{remainingOption.value}</Tag>
																	))}
															</Space>
															{/* {JSON.stringify(QUESTIONS?.[question.questionId]?.options || [])} */}
															{/* <SurveyQuestionAnswer
															question={question}
															answer={QUESTIONS?.[question.questionId]
																?.options
																// ?.options?.map((o) => o.value)
																?.filter?.((o) => !survey.answersV3?.[question?.questionId].includes(o.value))
																?.map((o) => o.value)
															}
															style={{
																tag: {
																	fontSize: 8,
																	color: 'red',
																	padding: '0',
																},
															}}
														/> */}
														</Col>
														<Col
															span={2}
															style={cellStyle}
														>
															Score<br />
															{[null, undefined].includes(surveyConf?.scoring?.[question.questionId])
																? <NoValue />
																: <b>{round(questionScores[question.questionId], 1)}</b>
															}
														</Col>
														<Col
															span={3}
															style={cellStyle}
														>
															{/* <div>
														Show action? {isActionQuestionMatchingAnswer({
																	questionId: question.questionId,
																	operator: question.operator,
																	clauses: question.clauses,
																	answers,
																	questionScores,
																})
																	? BOOLEAN_YES_ICON
																	: BOOLEAN_NO_ICON}
															</div> */}
															Card Status: <CompanyActionCompletionStatusTag
																completionStatus={getActionQuestionCompletionStatusFromAnswers({
																	...question,
																	answers,
																	questionScores,
																})}
															/>
														</Col>
													</>
												)
												: (
													<Col
														span={24}
														style={cellStyle}
													>
														{question}
													</Col>
												)
											}
										</Row>
									))}
								</Col>
							</Row>
						</div>
					);
				})}
			</SurveyNavigationProvider>

			<CompanyActionModal
				open={openActionModal}
				setOpen={setOpenActionModal}
				action={selectedAction}
				withAddButton={false}
			/>

		</PageLayout>
	);
};

export default StaffGenderScoreAnswersToActionsPage;
