import { Button, Col, Row } from 'antd';
import { FaCheck } from 'react-icons/fa';

const FormSubmitButton = (
	{
		children,
		icon = <FaCheck style={{ marginBottom: -2 }}/>,
		disabled = false,
		type = 'primary',
		...props
	},
) => {
	return (
		<Button
			type={type}
			disabled={disabled}
			htmlType="submit"
			icon={icon}
			{...props}
			style={{
				margin: 0,
				fontWeight: 'bold',
				borderRadius: 5,
				// width: '100%',
				...props?.style,
			}}
		>
			&nbsp;{children}
		</Button>
	);
};

export default FormSubmitButton;
