import FormBoxTitle from '../../../app/form/FormBoxTitle';
import FeedbackForm from './FeedbackForm';
import { FaEnvelope } from 'react-icons/fa';
import { Space } from 'antd';
import ModalContainerBase from '../../../../contexts/modals/ModalContainerBase';
import { MESSAGES_ICON } from '../../../../constants/constant';

const FeedbackPopover = (
	{
		open,
		initialValues = {},
		onSubmit,
		onReset,
		...props
	},
) => {
	return (
		<ModalContainerBase
			open={open}
			onCancel={onReset}
		>
			<FormBoxTitle
				title={(<>
					{MESSAGES_ICON} {props?.title || 'Send a feedback'}
				</>)}
			/>
			<FeedbackForm
				initialValues={initialValues}
				onSubmit={onSubmit}
				onReset={onReset}
			/>
		</ModalContainerBase>
	);
};

export default FeedbackPopover;
