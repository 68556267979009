import { Button, Tag, Tooltip } from 'antd';
import { useContext } from 'react';
import { CandidateContext } from '../../contexts/CandidateProvider';
import { FaCheck, FaTimes } from 'react-icons/fa';
import theme from '../../theme';
import { isFunction } from '../../utils/common';

const PipelineTag = (
	{
		candidatePipeline,
		label,
		error = false,
		onClick,
	},
) => {
	const className = 'PipelineTag';

	const { findPipeline, findStage } = useContext(CandidateContext);

	const pipeline = findPipeline(candidatePipeline);

	// LocalDebug.logInfo({ className, method: 'init' }, { pipeline });
	let tagIcon = <FaCheck color={theme.color.darkturquoise} style={{ marginBottom: -2, marginRight: 4 }}/>;
	const tagLabel = label || pipeline?.label || pipeline;
	let tagColor = 'green';

	if (pipeline) {
		tagIcon = <span>{pipeline?.icon}</span>;
	}

	let tooltiper = (children) => children;

	if (error) {
		tagColor = 'volcano';
		tagIcon = <FaTimes color={theme.color.red} style={{ marginBottom: -2, marginRight: 4 }}/>;
		tooltiper = (children) => <Tooltip title={error?.data?.message || error?.message}>{children}</Tooltip>;
	}

	const tagStyles = {
		borderRadius: 20,
		fontWeight: 'bold',
		margin: 0,
		paddingTop: 1,
		paddingBottom: 1,
	};

	const tag = tooltiper(
		tagLabel
			? <Tag
				icon={tagIcon}
				color={tagColor}
				style={tagStyles}
			>
                &nbsp;{tagLabel}
			</Tag>
			: 'No tag found',
	);

	const buttonWrapper = (children) => (isFunction(onClick)
		? <Button
			style={{
				borderRadius: 20,
				padding: 0,
				boxShadow: 'none',
				lineHeight: 'unset',
				height: 'unset',
				margin: 0,
				border: 'none',
				background: 'none',
				outline: 'none',
			}}
			onClick={onClick}>
			{children}
		</Button>
		: children);

	return buttonWrapper(tag);
};

export default PipelineTag;
