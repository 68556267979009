/** @jsxImportSource @emotion/react */
const ArticleDivider = ({ isEnVersion }) => {
	return (
		<p css={{
			display: 'flex', alignItems: 'center', gap: 10, background: '#f5f5f5', padding: 5, boarderRadius: 5,
		}}>
			{
				isEnVersion ? (
					<>
						<span>🇬🇧</span>
						<span>
              The content below is <strong>translatable</strong>. Now you are
              translating the <strong>English version</strong>.
						</span>
					</>
				) : (
					<>
						<span>🇫🇷</span>
						<span>
                Le contenu ci-dessous est <strong>traduisible</strong>, vous éditez
                actuellement sa <strong>version française</strong>. Si vous le laissez
                vide, le contenu de la <strong>version anglaise</strong> sera utilisé à
                sa place.
						</span>
					</>
				)
			}
		</p>
	);
};
export default ArticleDivider;
