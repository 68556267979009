import {
	ENGLISH_LANG, FRENCH_LANG, GENDERSCORE_LABEL, UMS_LABEL,
} from '../../../../constants/constant';
import { BUSINESS_PLAN_GS_DISCOUNT_VALUE, BUSINESS_PLAN_GS_SCALEUP_VALUE, BUSINESS_PLAN_GS_STARTUP_VALUE } from '../../../../constants/property/business-plan';
import CompanyModel from '../../../../models/CompanyModel';
import { documentId } from '../../../../utils/common';

const className = 'CompanyPageSteps';

export const COMPANY_PAGE_STEP_NAME_ID = 'name';
export const COMPANY_PAGE_STEP_PATH_ID = 'path';
export const COMPANY_PAGE_STEP_LOGO_ID = 'logo';
export const COMPANY_PAGE_STEP_ONELINER_ID = 'oneliner';
export const COMPANY_PAGE_STEP_WEBSITE_ID = 'website';
export const COMPANY_PAGE_STEP_HEADQUARTERS_ID = 'headquarters';
export const COMPANY_PAGE_STEP_TEAMSIZE_ID = 'teamsize';
export const COMPANY_PAGE_STEP_INDUSTRY_ID = 'industry';
export const COMPANY_PAGE_STEP_GENDERSCORE_ID = 'genderscore';
export const COMPANY_PAGE_STEP_UMS_ID = 'ums';
export const COMPANY_PAGE_STEP_WELIKE_ID = 'weLike';
export const COMPANY_PAGE_STEP_MISSION_ID = 'mission';
export const COMPANY_PAGE_STEP_VALUES_ID = 'values';
export const COMPANY_PAGE_STEP_RECRUITMENT_ID = 'recruitment';
export const COMPANY_PAGE_STEP_BENEFITS_ID = 'benefits';
export const COMPANY_PAGE_STEP_QUOTE_ID = 'quote';
export const COMPANY_PAGE_STEP_DEI_ACTIONS_ID = 'diversityActions';
export const COMPANY_PAGE_STEP_COMMUNITIES_ID = 'communities';
export const COMPANY_PAGE_STEP_TEAM_ID = 'team';
export const COMPANY_PAGE_STEP_STORIES_ID = 'stories';
export const COMPANY_PAGE_STEP_RELEVANT_DATA_ID = 'relevantData';
export const COMPANY_PAGE_STEP_GLASSDOOR_ID = 'glassdoor';

const getCompanySource = ({ company, lang = ENGLISH_LANG }) => (lang === ENGLISH_LANG
	? company
	: company?.translations?.[lang]
);

export const COMPANY_PAGE_STEP_NAME = {
	id: COMPANY_PAGE_STEP_NAME_ID,
	title: 'Name',
	description: 'The name of your company',
	isTranslatable: false,
	isComplete: ({ company, lang = ENGLISH_LANG }) => !!company?.name?.trim?.(),
};

export const COMPANY_PAGE_STEP_PATH = {
	id: COMPANY_PAGE_STEP_PATH_ID,
	title: 'Path',
	description: 'The path of your company page on 50inTech',
	isTranslatable: false,
	isComplete: ({ company, lang = ENGLISH_LANG }) => !!company?.path?.trim?.(),
};

export const COMPANY_PAGE_STEP_LOGO = {
	id: COMPANY_PAGE_STEP_LOGO_ID,
	title: 'Logo',
	description: 'Upload your company\'s logo',
	isTranslatable: false,
	isComplete: ({ company, lang = ENGLISH_LANG }) => !!company?.logo?.trim?.(),
};

export const COMPANY_PAGE_STEP_ONELINER = {
	id: COMPANY_PAGE_STEP_ONELINER_ID,
	title: 'One-liner',
	description: 'Provide a one-liner',
	isTranslatable: true,
	isComplete: ({ company, lang = ENGLISH_LANG }) => !!getCompanySource({ company, lang })
		?.oneliner?.trim?.(),
};

export const COMPANY_PAGE_STEP_WEBSITE = {
	id: COMPANY_PAGE_STEP_WEBSITE_ID,
	title: 'Website',
	description: 'Provide a URL',
	isTranslatable: true,
	isComplete: ({ company, lang = ENGLISH_LANG }) => !!getCompanySource({ company, lang })
		?.website?.trim?.(),
};

export const COMPANY_PAGE_STEP_HEADQUARTERS = {
	id: COMPANY_PAGE_STEP_HEADQUARTERS_ID,
	title: 'Headquarters',
	description: 'Provide locations for your offices',
	isTranslatable: true,
	isComplete: ({ company, lang = ENGLISH_LANG }) => !!getCompanySource({ company, lang })
		?.headquarters?.trim?.(),
};

export const COMPANY_PAGE_STEP_TEAMSIZE = {
	id: COMPANY_PAGE_STEP_TEAMSIZE_ID,
	title: 'Team size',
	description: 'Provide the number of employees',
	isTranslatable: false,
	isComplete: ({ company, lang = ENGLISH_LANG }) => !!company?.teamsize?.trim?.(),
};

export const COMPANY_PAGE_STEP_INDUSTRY = {
	id: COMPANY_PAGE_STEP_INDUSTRY_ID,
	title: 'Industry',
	description: 'Provide your industry sectors',
	isTranslatable: false,
	isComplete: ({ company, lang = ENGLISH_LANG }) => !!company?.industry?.length,
};

export const COMPANY_PAGE_STEP_GENDERSCORE = {
	id: COMPANY_PAGE_STEP_GENDERSCORE_ID,
	title: GENDERSCORE_LABEL,
	description: `Obtain your ${GENDERSCORE_LABEL}`,
	isTranslatable: false,
	isComplete: ({ company, lang = ENGLISH_LANG }) => (
		!!(company?.genderScore?.globalScore > 0)
	),
};

export const COMPANY_PAGE_STEP_UMS = {
	id: COMPANY_PAGE_STEP_UMS_ID,
	title: UMS_LABEL,
	description: `Obtain your ${UMS_LABEL}`,
	isTranslatable: false,
	isComplete: ({ company, lang = ENGLISH_LANG }) => (
		!!(company?.ums?.globalScore > 0)
	),
};

export const COMPANY_PAGE_STEP_WELIKE = {
	id: COMPANY_PAGE_STEP_WELIKE_ID,
	title: 'What we like about',
	description: 'How 50inTech can make you shine',
	isTranslatable: true,
	isComplete: ({ company, lang = ENGLISH_LANG }) => (
		!!getCompanySource({ company, lang })?.weLike?.trim?.()
	),
};

export const COMPANY_PAGE_STEP_MISSION = {
	id: COMPANY_PAGE_STEP_MISSION_ID,
	title: 'Mission',
	description: 'Fill your company\'s mission',
	isTranslatable: true,
	isComplete: ({ company, lang = ENGLISH_LANG }) => (
		!!getCompanySource({ company, lang })?.mission?.trim?.()
	),
};

export const COMPANY_PAGE_STEP_VALUES = {
	id: COMPANY_PAGE_STEP_VALUES_ID,
	title: 'Values',
	description: 'Fill your company\'s values',
	isTranslatable: true,
	isComplete: ({ company, lang = ENGLISH_LANG }) => (
		!!getCompanySource({ company, lang })?.values?.trim?.()
	),
};

export const COMPANY_PAGE_STEP_RECRUITMENT = {
	id: COMPANY_PAGE_STEP_RECRUITMENT_ID,
	title: 'Recruitment process',
	description: 'Detail your company\'s recruitment process',
	isTranslatable: true,
	isComplete: ({ company, lang = ENGLISH_LANG }) => (
		!!getCompanySource({ company, lang })?.recruitment?.trim?.()
	),
};

export const COMPANY_PAGE_STEP_BENEFITS = {
	id: COMPANY_PAGE_STEP_BENEFITS_ID,
	title: 'Benefits & perks',
	description: 'Detail your company\'s benefits & perks',
	isTranslatable: true,
	isComplete: ({ company, lang = ENGLISH_LANG }) => (
		!!getCompanySource({ company, lang })?.benefits?.trim?.()
	),
};

export const COMPANY_PAGE_STEP_QUOTE = {
	id: COMPANY_PAGE_STEP_QUOTE_ID,
	title: 'Quote',
	description: 'A quote from one of your main people, embodying your DE&I initiatives',
	isTranslatable: true,
	isComplete: ({ company, lang = ENGLISH_LANG }) => (
		!!getCompanySource({ company, lang })?.quote?.trim?.()
	),
};

export const COMPANY_PAGE_STEP_DEI_ACTIONS = {
	id: COMPANY_PAGE_STEP_DEI_ACTIONS_ID,
	title: 'DE&I actions',
	description: 'Detail your DE&I actions',
	isTranslatable: true,
	isComplete: ({ company, lang = ENGLISH_LANG }) => (
		!!getCompanySource({ company, lang })?.deiActions?.trim?.()
	),
};

export const COMPANY_PAGE_STEP_COMMUNITIES = {
	id: COMPANY_PAGE_STEP_COMMUNITIES_ID,
	title: 'Supported communities',
	description: 'List the communities that you support around DE&I',
	isTranslatable: true,
	isComplete: ({ company, lang = ENGLISH_LANG }) => (
		!!getCompanySource({ company, lang })?.communities?.trim?.()
	),
};

export const COMPANY_PAGE_STEP_TEAM = {
	id: COMPANY_PAGE_STEP_TEAM_ID,
	title: 'Team',
	description: 'List of your recruiters registered on 50inTech',
	isTranslatable: false,
	isComplete: ({ company, lang = ENGLISH_LANG }) => !!company?.team?.length,
};

export const COMPANY_PAGE_STEP_STORIES = {
	id: COMPANY_PAGE_STEP_STORIES_ID,
	title: 'Diversity Stories',
	description: 'List of interviews of your employees realized with 50inTech',
	isTranslatable: false,
	isComplete: ({ company, lang = ENGLISH_LANG }) => !!company?.stories?.length,
};

export const COMPANY_PAGE_STEP_RELEVANT_DATA = {
	id: COMPANY_PAGE_STEP_RELEVANT_DATA_ID,
	title: 'Relevant Data',
	description: 'List of your data',
	isTranslatable: true,
	isComplete: ({ company, lang = ENGLISH_LANG }) => (
		!!getCompanySource({ company, lang })?.relevantData?.length
	),
};

export const COMPANY_PAGE_STEP_GLASSDOOR = {
	id: COMPANY_PAGE_STEP_GLASSDOOR_ID,
	title: 'Glassdoor rating',
	description: 'Your Glassdoor rating',
	isTranslatable: false,
	isComplete: ({ company, lang = ENGLISH_LANG }) => company?.glassdoor > 0,
};

export const getPlanSteps = ({ company }) => {
	const steps = {
		base: {
			[ENGLISH_LANG]: [
				COMPANY_PAGE_STEP_NAME,
				COMPANY_PAGE_STEP_PATH,
			],
			[FRENCH_LANG]: [
				COMPANY_PAGE_STEP_NAME,
				COMPANY_PAGE_STEP_PATH,
			],
		},
		mandatory: {
			[ENGLISH_LANG]: [
				COMPANY_PAGE_STEP_LOGO,
				COMPANY_PAGE_STEP_ONELINER,
				COMPANY_PAGE_STEP_WEBSITE,
				COMPANY_PAGE_STEP_HEADQUARTERS,
				COMPANY_PAGE_STEP_TEAMSIZE,
				COMPANY_PAGE_STEP_INDUSTRY,
				COMPANY_PAGE_STEP_MISSION,
				COMPANY_PAGE_STEP_VALUES,
				COMPANY_PAGE_STEP_BENEFITS,
				COMPANY_PAGE_STEP_RECRUITMENT,
				...new CompanyModel(company).isGenderScoreEnabled() && new CompanyModel(company).isGenderScoreBadgeStaffPublished()
					? [COMPANY_PAGE_STEP_GENDERSCORE]
					: [],
			],
			[FRENCH_LANG]: [
				COMPANY_PAGE_STEP_ONELINER,
				COMPANY_PAGE_STEP_MISSION,
				COMPANY_PAGE_STEP_VALUES,
				COMPANY_PAGE_STEP_BENEFITS,
				COMPANY_PAGE_STEP_RECRUITMENT,
			],
		},
		optional: {
			[ENGLISH_LANG]: [
				COMPANY_PAGE_STEP_COMMUNITIES,
				COMPANY_PAGE_STEP_QUOTE,
				COMPANY_PAGE_STEP_GLASSDOOR,
			],
			[FRENCH_LANG]: [
				COMPANY_PAGE_STEP_WEBSITE,
				COMPANY_PAGE_STEP_HEADQUARTERS,
				COMPANY_PAGE_STEP_COMMUNITIES,
				COMPANY_PAGE_STEP_QUOTE,
			],
		},
		staff: {
			[ENGLISH_LANG]: [
				COMPANY_PAGE_STEP_WELIKE,
				COMPANY_PAGE_STEP_DEI_ACTIONS,
				COMPANY_PAGE_STEP_STORIES,
				COMPANY_PAGE_STEP_TEAM,
			],
			[FRENCH_LANG]: [
				COMPANY_PAGE_STEP_WELIKE,
				COMPANY_PAGE_STEP_DEI_ACTIONS,
				COMPANY_PAGE_STEP_STORIES,
				COMPANY_PAGE_STEP_TEAM,
			],
		},
	};

	return {
		...steps,
		all: {
			[ENGLISH_LANG]: Object
				.values(steps)
				.map((langList) => langList[ENGLISH_LANG])
				.flat(),
			[FRENCH_LANG]: Object
				.values(steps)
				.map((langList) => langList[ENGLISH_LANG])
				.flat(),
		},
	};
};

export const getAllSteps = ({ company, lang = ENGLISH_LANG }) => (
	getPlanSteps({ company })?.all?.[lang]
);
export const countAllSteps = ({ company, lang = ENGLISH_LANG }) => (
	getAllSteps({ company, lang })?.length
);
export const getMandatorySteps = ({ company, lang = ENGLISH_LANG }) => (
	getPlanSteps({ company })?.mandatory?.[lang]
);
export const countMandatorySteps = ({ company, lang = ENGLISH_LANG }) => (
	getMandatorySteps({ company, lang })?.length
);
export const getOptionalSteps = ({ company, lang = ENGLISH_LANG }) => (
	getPlanSteps({ company })?.optional?.[lang]
);
export const countOptionalSteps = ({ company, lang = ENGLISH_LANG }) => (
	getOptionalSteps({ company, lang })?.length
);
export const getStaffSteps = ({ company, lang = ENGLISH_LANG }) => (
	getPlanSteps({ company })?.staff?.[lang]
);
export const countStaffSteps = ({ company, lang = ENGLISH_LANG }) => (
	getStaffSteps({ company, lang })?.length
);

export const areAllMandatoryStepsCompleted = ({ company, lang = ENGLISH_LANG }) => (
	getMandatorySteps({ company, lang })
		.map((step) => step.isComplete({ company, lang }))
		.reduce((prev, cur) => (prev == undefined ? true : prev) && cur)
);

export const areSomeMandatoryStepsComplete = ({ company, lang = ENGLISH_LANG }) => (
	getMandatorySteps({ company, lang })
		.some((step) => step.isComplete({ company, lang }))
);

export const areAllMandatoryStepsIncomplete = ({ company, lang = ENGLISH_LANG }) => (
	getMandatorySteps({ company, lang })
		.map((step) => !step.isComplete({ company, lang }))
		.reduce((prev, cur) => (prev == undefined ? true : prev) && cur)
);

export const findCurrentMandatoryStepIndex = ({ company, lang = ENGLISH_LANG }) => {
	let stepIndex = 0;
	getMandatorySteps({ company, lang })
		.forEach((step, index) => {
			if (step.isComplete({ company, lang })) stepIndex = index + 1;
		});
	return stepIndex;
};

export const getNextMandatoryStep = ({ company, lang = ENGLISH_LANG }) => {
	const currentStepIndex = findCurrentMandatoryStepIndex({ company, lang });
	const [nextStep] = getMandatorySteps({ company, lang })
		?.slice?.(currentStepIndex, countMandatorySteps({ company, lang }))
			|| [];
	return nextStep;
};

export const getNextIncompleteMandatoryStep = ({ company, lang = ENGLISH_LANG }) => {
	const [nextStep] = getMandatorySteps({ company, lang })
		?.filter?.((step) => !isMandatoryStepCompleted(documentId(step), { company, lang }))
			|| [];
	return nextStep;
};

export const getCompleteMandatoryStepList = ({ company, lang = ENGLISH_LANG }) => {
	return getMandatorySteps({ company, lang })
		?.filter?.((step) => !!isMandatoryStepCompleted(documentId(step), { company, lang }))
			|| [];
};

export const getIncompleteMandatoryStepList = ({ company, lang = ENGLISH_LANG }) => {
	return getMandatorySteps({ company, lang })
		?.filter?.((step) => !isMandatoryStepCompleted(documentId(step), { company, lang }))
			|| [];
};

export const hasNextIncompleteMandatoryStep = ({ company, lang = ENGLISH_LANG }) => {
	return !!getNextIncompleteMandatoryStep({ company, lang });
};

export const isMandatoryStepCompleted = (stepId, { company, lang = ENGLISH_LANG }) => {
	const step = getMandatorySteps({ company, lang })
		?.find((item) => documentId(item) === stepId);
	return step?.isComplete?.({ company, lang });
};

export const areAllOptionalStepsCompleted = ({ company, lang = ENGLISH_LANG }) => (
	getOptionalSteps({ company, lang })
		.map((step) => step.isComplete({ company, lang }))
		.reduce((prev, cur) => (prev == undefined ? true : prev) && cur)
);
