import ReactJson from 'react-json-view';
import { useAuthContext } from '../../../hooks/useAuthContext';
import Box from '../box/Box';
import config from '../../../config/config';

const ReactJsonDebug = (
	{
		name,
		src,
		sortKeys = true,
		collapsed = 2,
		withBox = true,
		...props
	},
) => {
	const { isStaff, isStaffView } = useAuthContext();

	if (!isStaff && !config.isDevEnv) return null;

	const content = (
		<ReactJson
			name={name}
			src={src}
			sortKeys={true}
			collapsed={collapsed}
			{...props}
			style={{ fontSize: 10, textAlign: 'left', ...props?.style }}
		/>
	);

	if (withBox) return <Box>{content}</Box>;

	return content;
};

export default ReactJsonDebug;
