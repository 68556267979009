import { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTheme } from '@emotion/react';
import { BiChevronRight } from 'react-icons/bi';
import { Button, Space } from 'antd';
import { getCompanySelected } from '../../reducers/app';
import { useAuthContext } from '../../hooks/useAuthContext';
import CompanyModel from '../../models/CompanyModel';
import { documentId } from '../../utils/common';
import { GENDERSCORE_EMPTY_ICON_IMG, GENDERSCORE_LABEL } from '../../constants/constant';
import SURVEYS from './data/surveys';
import { getSurveyAnsweredAndUnansweredQuestions } from './data/shared';
import { SurveyContext } from './survey/SurveyProvider';
import Box from '../app/box/Box';
import GenderScoreResultsExpirationBanners from '../company/genderscore/results/GenderScoreResultsExpirationBanners';

const GenderScoreSurveyResume = ({
	...props
}) => {
	const className = 'GenderScoreSurveyResume';
	const theme = useTheme();

	const { currentUser } = useAuthContext();
	const companySelected = useSelector(getCompanySelected);
	const {
		createOrUpdateSurveyDraft,
		dispatchSelectSurveyDraft,
	} = useContext(SurveyContext);

	const [currentUserDraft, setCurrentUserDraft] = useState(null);
	const [buttonSublabel, setButtonSublabel] = useState(null);

	const handleClick = async () => {
		if (currentUserDraft) {
			dispatchSelectSurveyDraft(currentUserDraft);
		} else {
			await createOrUpdateSurveyDraft({ withDispatch: true });
		}
	};

	useEffect(() => {
		setCurrentUserDraft(new CompanyModel(companySelected)
			.getGenderScoreSurveyDraftByUser({ userId: documentId(currentUser) }));
	}, [companySelected, currentUser]);

	useEffect(() => {
		const surveyConf = SURVEYS?.getSurveyDataOption?.(currentUserDraft?.survey);
		const { unanswered } = getSurveyAnsweredAndUnansweredQuestions({
			surveyConf,
			answers: currentUserDraft?.answers,
		});

		if (unanswered?.length > 0) {
			setButtonSublabel(
				<div>
					{unanswered?.length < 10 ? 'Only' : 'You still have'}
					&nbsp;{unanswered?.length}
					&nbsp;question{unanswered?.length > 1 ? 's' : ''} to answer
				</div>,
			);
		} else {
			setButtonSublabel('You answered everything, just review and submit!');
		}
	}, [currentUserDraft]);

	return (
		<>
			<GenderScoreResultsExpirationBanners
				company={companySelected}
				withNavigateToSurvey={false}
			/>
			<div
				style={{
					maxWidth: 860,
					width: '100%',
					margin: '0 auto',
					fontSize: 16,
					display: 'grid',
					height: '100vh',
					gridTemplateColumns: '1fr',
					justifyContent: 'center',
					alignItems: 'center',
				}}
			>
				<div
					style={{
						boxShadow: '0px 15px 60px -12px rgba(0,0,0,.3)',
						padding: 30,
						borderRadius: 12,
					}}
				>

					<Space
						wrap={false}
						align={'top'}
						size={30}
						style={{
							width: '100%',
							marginBottom: 30,
						}}
					>
						{GENDERSCORE_EMPTY_ICON_IMG({ width: 120 })}

						<div style={{ fontSize: 16 }}>
							<h1>
							Your {GENDERSCORE_LABEL} Survey
								<br/>is waiting for you! 🌟
							</h1>
							<p>
						Your progress was saved, you can pick up where you left off.
							</p>
						</div>
					</Space>

					<div>
						<Button
							type='primary'
							size={'large'}
							style={{
								width: '100%',
								borderRadius: 10,
								height: 110,
							}}
							iconPlacement={'right'}
							onClick={handleClick}
						>
							<Space
								direction='horizontal'
								style={{
									width: '100%',
									justifyContent: 'space-between',
									alignItems: 'center',
								}}
							>
								<div />
								<div>
									<div
										style={{
											fontSize: 24,
											lineHeight: 1.3,
											fontWeight: 'bold',
											marginBottom: 8,
										}}
									>
									Resume my {GENDERSCORE_LABEL} Survey
									</div>
									{buttonSublabel}
								</div>
								<BiChevronRight
									style={{
										fontSize: 42,
										marginBottom: -6,
									}}
								/>
							</Space>
						</Button>
					</div>

				</div>
			</div>
		</>
	);
};

export default GenderScoreSurveyResume;
