import { useTheme } from '@emotion/react';
import { FaInfoCircle } from 'react-icons/fa';
import { Space } from 'antd';

const HelpInfo = (
	{
		icon = <FaInfoCircle style={{ marginBottom: -2 }}/>,
		children,
		...props
	},
) => {
	const className = 'HelpInfo';
	const theme = useTheme();
	return (
		<Space
			size={6}
			align={'top'}
			style={{
				width: '100%',
				...props?.style,
			}}
		>
			<div style={{ color: theme.color.darkgrey }}>
				{icon}
			</div>
			<div
				style={{
					fontSize: 12,
					color: theme.color.grey,
					...props?.style?.label,
				}}
			>
				{children}
			</div>
		</Space>
	);
};

export default HelpInfo;
