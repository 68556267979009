import { useTheme } from '@emotion/react';
import { Space } from 'antd';
import FeedbackBtnBuilder from '../../staff/product/feedback/FeedbackBtnBuilder';

const Callout = (
	{
		icon = <span style={{ fontSize: 14 }}>💡</span>,
		withFeedback = true,
		withFullWidth = true,
		children,
		...props
	},
) => {
	const theme = useTheme();
	return (
		<>
			<Space
				direction='horizontal'
				size={10}
				style={{
					...withFullWidth ? { width: '100%' } : {},
					justifyContent: 'space-between',
					padding: '10px 20px',
					background: '#eee',
					borderRadius: 10,
					marginBottom: 20,
					...props?.style,
				}}>
				<Space
					direction='horizontal'
					size={10}
					align='start'
					style={{
						width: '100%',
						// padding: '10px 20px',
						// background: '#eee',
						// borderRadius: 10,
						// marginBottom: 20,
						// ...props?.style
					}}>
					{icon}
					{children}
				</Space>
				{withFeedback && <FeedbackBtnBuilder/>}
			</Space>
		</>
	);
};

export default Callout;
