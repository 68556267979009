import { useTheme } from '@emotion/react';
import { Space } from 'antd';
import Link from '../Link';
import Logo50inTech from '../Logo50inTech';
import { LOGO_50INTECH_FULL_BLUE_SVG } from '../../../constants/logos';

const AppLogo = ({
	label = <>For&nbsp;Companies</>,
	...props
}) => {
	const theme = useTheme();

	const logo = (
		<Logo50inTech
			src={LOGO_50INTECH_FULL_BLUE_SVG}
			width={72}
			height={29}
			className='logo'
		/>
	);

	return (
		<Space
			direction='horizontal'
			size={6}
			style={{
				...props?.style,
				position: 'relative',
			}}>

			<Link to='/'>
				{logo}
			</Link>

			{label && (
				<div
					style={{
						position: 'relative',
						top: 13,
						left: -44,
						color: theme.color.blue,
						lineHeight: 1.4,
						fontSize: 12,
						fontWeight: 600,
						userSelect: 'none',
					}}>
					{label}
				</div>
			)}
		</Space>
	);
};

export default AppLogo;
