import ListSeparator from './ListSeparator';
import { useTheme } from '@emotion/react';
import UserGridCardListSeparator from './UserGridCardListSeparator';

const UserGridCardListWithSeparator = (
	{
		defaultContainer = 'span',
		placement = 'right',
		withSeparator = true,
		children,
		...props
	},
) => {
	const className = 'UserGridCardListWithSeparator';
	const theme = useTheme();

	// const separator = <span style={{ opacity: .2 }}>&nbsp;\ </span>;
	// const separator = placement === 'right'
	// 	? <span style={{ opacity: 0.7, color: theme.color.fitBlueElectric }}>&nbsp;• </span>
	// 	: <span style={{ opacity: 0.7, color: theme.color.fitBlueElectric }}> •&nbsp;</span>;

	return (
		<ListSeparator
			defaultContainer={defaultContainer}
			placement={placement}
			withSeparator={
				(withSeparator
					? withSeparator === true
						? < UserGridCardListSeparator placement={placement} />
						: withSeparator
					: null
				)
			}
			{...props}
		>
			{children}
		</ListSeparator>
	);
};

export default UserGridCardListWithSeparator;
