/** @jsxImportSource @emotion/react */
import FormItem from '../../../../app/form/FormItem';
import { Switch } from 'antd';

function FieldItemAnonymous({ value, onChange }) {
	return (
		<FormItem
			defaultContainer="div"
			name="isAnonymous"
			styles={{
				rootContainer: {
					display: 'flex',
					flexDirection: 'row',
					alignItems: 'center!important',
					width: '100%',
					gap: 10,
				},
				label: { marginTop: 6, width: 140 },
				container: { width: '100%' },
			}}
		>
			<Switch
				css={{ marginRight: 15 }}
				defaultChecked={value}
				onChange={(e) => {
					onChange('isAnonymous', e);
				}}
			/>
			<span className="form-label" css={{ padding: 0 }}>
        publier anonymement
			</span>
		</FormItem>
	);
}

export default FieldItemAnonymous;
