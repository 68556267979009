import { Button, Space } from 'antd';
import { useState } from 'react';
import { useTheme } from '@emotion/react';
import { FaBan } from 'react-icons/fa';
import Table from '../app/table/Table';
import TableColumns from '../app/table/TableColumns';
import { displayHtml } from '../../utils/common';
import UserDrawerProfile from '../user/UserDrawerProfile';
import { useAuthContext } from '../../hooks/useAuthContext';
import { LocalDebug } from '../../utils/LocalDebug';
import MessageTemplateCategoryTag from './templates/MessageTemplateCategoryTag';
import MessageService from '../../services/message';
import {
	CATEGORY_APPLICATION_ACCEPTED_VALUE, CATEGORY_APPLICATION_REJECTED_VALUE,
	CATEGORY_FIRST_APPROACH_VALUE, CATEGORY_FOLLOWUP_VALUE,
	CATEGORY_LIST, CATEGORY_OTHER_VALUE,
} from '../../constants/email-templates';

const MessageTable2 = (
	{
		onDataLoaded,
		path = '/message',
		filterOptions = {},
		isRefresh = 0,
		onSearchDataColumn,
		onToggleIsActive,
		onEdit,
		isEditor,
		...props
	},
) => {
	const className = 'MessageTable2';

	const theme = useTheme();

	const { isAdmin, isStaffView } = useAuthContext();

	const [isRefreshDataLayout, setIsRefreshDataLayout] = useState(0);
	const [openProfile, setOpenProfile] = useState(false);
	const [activeTab, setActiveTab] = useState(null);
	const [userSelected, setUserSelected] = useState(null);

	const handleOpenUserProfile = (user, tab) => {
		setUserSelected(user);
		setActiveTab(tab);
		setOpenProfile(true);
	};

	const handleCloseProfile = () => {
		setUserSelected(null);
		setActiveTab(null);
		setOpenProfile(false);
	};

	const userAvatarCol = TableColumns.userAvatarColumn(theme, { title: 'Sender', dataIndex: 'user' });
	const userToStubCol = TableColumns.userAvatarColumn(theme, {
		title: 'Talent contacted',
		dataIndex: 'userTo',
		withDropdown: true,
		removeInColumnFilter: true,
		sorter: false,
	});
	const companyLogoCol = TableColumns.companyLogoColumn(theme, { title: 'Company', dataIndex: 'company' });

	const handleUpdateCategory = async (message, category) => {
		message.category = category;
		setIsRefreshDataLayout((p) => p + 1);
		LocalDebug.logInfo({ className, method: 'handleUpdateCategory' }, { message, category });
		const { id, userToId, companyId } = message;
		await MessageService.update(id, {
			id,
			userToId,
			companyId,
			category,
		});
	};

	const categoryCol = {
		title: 'Category',
		dataIndex: 'category',
		render: (value, row) => (
			<>
				<Space direction='vertical'>

					<MessageTemplateCategoryTag category={value}/>

					<Space size={6}>
						{[
							...CATEGORY_LIST,
							{ value: null },
						].map((c) => {
							return (
								<Button
									type='default'
									onClick={() => { handleUpdateCategory(row, c.value); }}
									style={{
										// background: mixColors('#ffffff', c.color, 20),
										color: 'black',
										// border: 'none',
										padding: '1px 4px',
										fontSize: 10,
										fontWeight: 'bold',
										lineHeight: 1.2,
										height: 22,
									}}
								>
									{{
										[CATEGORY_FIRST_APPROACH_VALUE]: '1st',
										[CATEGORY_APPLICATION_ACCEPTED_VALUE]: 'aA',
										[CATEGORY_APPLICATION_REJECTED_VALUE]: 'aR',
										[CATEGORY_FOLLOWUP_VALUE]: 'Fu',
										[CATEGORY_OTHER_VALUE]: 'Ot',
										[null]: <><FaBan style={{ marginBottom: -2 }}/></>,
									}[c.value]}
								</Button>
							);
						})}
					</Space>
				</Space>
			</>
		),
		...TableColumns.columnFixedWidth(200),
	};
	const messageCol = {
		title: 'Message',
		dataIndex: ['content', 'body'],
		// removeInColumnFilter: true,
		sorter: false,
		customOptions: {
			searchFields: [
				{ label: 'Subject', value: 'content.subject' },
				{ label: 'Body', value: 'content.body' },
			],
		},
		render: (value, row) => (
			// <Popover
			// 	title={<b>Message</b>}
			// 	content={(
			// 		<>
			// 			<div style={{
			// 				maxWidth: '600px', maxHeight: '60vh', overflow: 'auto', fontSize: 14, fontWeight: 'bold'
			// 			}}>
			// 				{displayHtml(row?.content?.subject)}
			// 			</div>
			// 			<div style={{ maxWidth: '600px', maxHeight: '60vh', overflow: 'auto', fontSize: 14 }}>
			// 				{displayHtml(value)}
			// 			</div>
			// 		</>
			// 	)}
			// >
			<Space direction='vertical' style={{ fontSize: 10, lineHeight: 1.3 }}>
				<div style={{
					fontWeight: 'bold',
				}}>
					{displayHtml(row?.content?.subject)}
				</div>
				<div style={{}}>
					{displayHtml(row?.content?.body)}
				</div>
			</Space>
			// </Popover>
		),
		...TableColumns.columnMinWidth(600),
	};
	const createdAtCol = TableColumns.createdAtColumn(theme);
	const updatedAtCol = TableColumns.updatedAtColumn(theme);

	const columns = [
		TableColumns.jsonColumn(theme, { dataIndex: '' }),
		userAvatarCol,
		// companyLogoCol,
		userToStubCol,
		categoryCol,
		messageCol,
		createdAtCol,
		updatedAtCol,
	];

	return (
		<>
			<Table
				path={path}
				onDataLoaded={onDataLoaded}
				columns={columns}
				filterOptions={filterOptions}
				isRefresh={isRefresh + isRefreshDataLayout}
				onSearchDataColumn={onSearchDataColumn}
				showTotal={(total) => `Total ${total} items`}
				limit={10}
				rowClassName={(row, index) => (row.is50Content === false ? 'rowDisabled' : '')}
				withDynamicSearch
				{...props}
			/>

			<UserDrawerProfile
				user={userSelected}
				activeTab={activeTab}
				open={openProfile}
				onClose={handleCloseProfile}
			/>
		</>
	);
};

export default MessageTable2;
