import { useEffect, useState } from 'react';
import { GENDERSCORE_LABEL } from '../../../../constants/constant';
import FormItem from '../../form/FormItem';
import CompanyPlanGenderScoreSurveyIsEnabledSwitch from './CompanyPlanGenderScoreSurveyIsEnabledSwitch';

export const companyPlanGenderScoreSurveyIsEnabledPath = ['companyPlan', 'genderScore', 'isSurveyEnabled'];

const CompanyPlanGenderScoreSurveyIsEnabledFormItem = ({
	initialValue,
	daysToExpDate,
}) => {
	const className = 'CompanyPlanGenderScoreSurveyIsEnabledFormItem';

	const [genderScoreSurveyIsEnabled, setGenderScoreSurveyIsEnabled] = useState(initialValue);

	const onGenderScoreSurveyIsEnabledChange = (surveyIsEnabledNewValue) => {
		setGenderScoreSurveyIsEnabled(surveyIsEnabledNewValue);
	};

	let extra;
	if (daysToExpDate < 45) {
		extra = <>
			<span style={{ color: 'black' }}>⚠️ </span>
			<span>The {GENDERSCORE_LABEL} is {
				daysToExpDate <= 0 ? 'expired' : 'about to expire'}. Renew the {GENDERSCORE_LABEL} to enable the survey.</span>
		</>;
	} else {
		extra = genderScoreSurveyIsEnabled
			? <span>The company has access to the survey. <br/>
			This option will automatically be unchecked once they submit the survey</span>
			: <span>The company won't have access to the survey until this option is checked.</span>;
	}
	return (
		<FormItem
			label={
				<span>
					{GENDERSCORE_LABEL} Survey enabled
				</span>
			}
			style={{ marginBottom: 5 }}
			name={companyPlanGenderScoreSurveyIsEnabledPath}
			extra={extra}
			valuePropName='checked'
		>
			<CompanyPlanGenderScoreSurveyIsEnabledSwitch
				onChange={onGenderScoreSurveyIsEnabledChange}
				disabled={daysToExpDate < 45}
			/>
		</FormItem>
	);
};

export default CompanyPlanGenderScoreSurveyIsEnabledFormItem;
