import UserModel from '../../../../../models/UserModel';
import UserGridCardLabelValue from './UserGridCardLabelValue';
import UserGridCardValueList from './UserGridCardValueList';
import { getCareerSeekingOption } from '../../../../../constants/constant';

const UserGridCardCareerSeeking = (
	{
		user,
		label = <>Job search status</>,
		placement = 'left',
		defaultContainer = 'div',
		withSeparator = false,
		itemStyle,
		...props
	},
) => {
	const className = 'UserGridCardCareerSeeking';

	const userModel = new UserModel(user);

	const option = getCareerSeekingOption(user?.career?.seeking);

	const items = [option?.labelShort];

	return (
		<UserGridCardLabelValue
			label={label}
			valueStyle={{ fontWeight: 'bold', color: option?.color }}
			{...props}
		>
			<UserGridCardValueList
				items={items}
				placement={placement}
				defaultContainer={defaultContainer}
				withSeparator={withSeparator}
				itemStyle={itemStyle}
			/>
		</UserGridCardLabelValue>
	);
};

export default UserGridCardCareerSeeking;
