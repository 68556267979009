/** @jsxImportSource @emotion/react */

import { Button, Space } from 'antd';
import { FaEraser, FaGlasses, FaPaperPlane } from 'react-icons/fa';
import SelectLocalContent from './SelectLocalContent';
import { useTheme } from '@emotion/react';

const classes = (theme) => ({
	button: {
		'margin': 5,
		'background': theme.color.white,
		'borderColor': theme.color.blue,
		'color': theme.color.blue,
		'fontWeight': 'bold',
		'borderRadius': 5,
		':hover': {
			background: theme.color.white,
			borderColor: theme.color.blue,
			color: theme.color.blue,
			opacity: 0.7,
		},
	},
	activeButton: {
		'margin': 5,
		'background': `${theme.color.blue}!important`,
		'borderColor': `${theme.color.blue}!important`,
		'color': `${theme.color.white}!important`,
		':hover': {
			background: `${theme.color.blue}!important`,
			borderColor: `${theme.color.blue}!important`,
			color: `${theme.color.white}!important`,
			opacity: 0.7,
		},
	},
	previewButton: {
		'backgroundColor': theme.color.accent,
		'borderColor': theme.color.accent,
		'color': theme.color.white,
		'textTransform': 'uppercase',
		'fontSize': 18,
		'display': 'flex',
		'alignItems': 'center',
		'justifyContent': 'center',
		'margin': 'auto',
		'gap': 5,
		'borderRadius': '25px',
		'padding': '20px',
		':hover': {
			backgroundColor: theme.color.accent,
			borderColor: theme.color.accent,
			color: theme.color.white,
			opacity: 0.7,
		},
	},
	updateButton: {
		'color': theme.color.blue,
		'textTransform': 'uppercase',
		'fontSize': 18,
		'display': 'flex',
		'alignItems': 'center',
		'justifyContent': 'center',
		'margin': 'auto',
		'gap': 5,
		'borderRadius': '25px',
		'padding': '20px',
		'borderColor': theme.color.white,
		':hover': {
			color: theme.color.blue,
			borderColor: theme.color.white,
			opacity: 0.7,
		},
	},
});
function FooterAction(
	{
		lang,
		setLang,
		isPreview,
		setIsPreview,
		onPublish,
		disabled,
		isNotPublished,
	},
) {
	const theme = useTheme();
	const styles = classes(theme);

	return (
		<>
			<SelectLocalContent
				lang={lang}
				setLang={setLang}
				styles={styles}
			/>
			<div
				css={{
					boxShadow: 'rgb(0 0 0 / 10%) 0px -4px 12px 0px',
					padding: '10px 0',
				}}
			>
				{isPreview ? (
					<Space
						direction="horizontal"
						css={{ justifyContent: 'center', width: '100%' }}
					>
						<Button
							onClick={() => setIsPreview(false)}
							css={{
								...styles.button,
								...styles.updateButton,
							}}
						>
							<FaEraser />
                  Modifier
						</Button>
						<Button
							disabled={isNotPublished}
							onClick={() => onPublish()}
							css={{
								...styles.button,
								...styles.previewButton,
							}}
						>
                  Publish
							<FaPaperPlane />
						</Button>
					</Space>
				) : (
					<Button
						disabled={disabled}
						htmlType="submit"
						css={{
							...styles.button,
							...styles.previewButton,
						}}
					>
                Preview
						<FaGlasses />
					</Button>
				)}
			</div>
		</>
	);
}

export default FooterAction;
