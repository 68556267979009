import FormItem from '../form/FormItem';
import CompanyPlanExtraTagsSelect from './CompanyPlanExtraTagsSelect';

export const companyPlanExtraTagsPath = ['companyPlan', 'extraTags'];

const CompanyPlanExtraTagsFormItem = () => {
	const className = 'CompanyPlanExtraTagsFormItem';

	return (
		<FormItem
			label='Business Plan extra tags'
			defaultContainer='div'
			name={companyPlanExtraTagsPath}
			rules={[{ type: 'array' }]}
		>
			<CompanyPlanExtraTagsSelect />
		</FormItem>
	);
};

export default CompanyPlanExtraTagsFormItem;
