import { BUSINESS_PLAN_LEGACY_VALUE } from '../constants/property/business-plan';

/*
* These are the placeholder roles you can replace these with yours
*/
const roles = {
	ADMIN: { isAdmin: true },
	STAFF: { isStaff: true },
	STAFF_BACKUP: { isStaffBackup: true },
	TAGGER: { isTagger: true },
	TESTER: { isTester: true },
	TALENT: { isTalent: true },
	DEMO: { isDemo: true },
	EDITOR: { isEditor: true },
	COMPANY_RECRUITER: { isCompanyRecruiter: true },
	DOCUMENT_EDITOR: { isDocumentEditor: true },
	DOCUMENT_EDITOR_ONLY: { isDocumentEditorOnly: true },
	DOCUMENT_ADMIN: { isDocumentAdmin: true },
	DOCUMENT_READER: { isDocumentReader: true },
	ATS_SYNCABLE: { isAtsSyncable: true },
	ATS_NOT_SYNCABLE: { isAtsSyncable: false },
	GENDERSCORE_ENABLED: { isGenderScoreEnabled: true },
	GENDERSCORE_NOT_ENABLED: { isGenderScoreEnabled: false },
	GENDERSCORE_FILLED: { isGenderScoreFilled: true },
	GENDERSCORE_NOT_FILLED: { isGenderScoreFilled: false },
	UMS_ADMIN: { isUMSAdmin: true },
	UMS_MANAGER: { isUMSManager: true },
	UMS_EMPLOYEE: { isUMSEmployee: true },
	UMS_ENABLED: { isUMSEnabled: true },
	UMS_NOT_ENABLED: { isUMSEnabled: false },
	UMS_MANAGER_SURVEY_FILLED: { isUMSManagerSurveyFilled: true },
	UMS_MANAGER_SURVEY_NOT_FILLED: { isUMSManagerSurveyFilled: false },
	UMS_EMPLOYEE_SURVEY_FILLED: { isUMSEmployeeSurveyFilled: true },
	UMS_EMPLOYEE_SURVEY_NOT_FILLED: { isUMSEmployeeSurveyFilled: false },
	// TODO: [BUSINESS PLANS]
	COMPANY_PLAN: { planValue: BUSINESS_PLAN_LEGACY_VALUE },
};

export default roles;
