export const LOADING_START = 'LOADING_START';
export const LOADING_END = 'LOADING_END';
export const MESSAGE = 'MESSAGE';
export const CLOSE_MESSAGE = 'CLOSE_MESSAGE';
export const DISPLAY_SCREEN_UPDATED = 'DISPLAY_SCREEN_UPDATED';
export const SHOULD_REFRESH_DATA = 'SHOULD_REFRESH_DATA';
export const COMPANIES_LOADED = 'COMPANIES_LOADED';
export const RECRUITERS_LOADED = 'RECRUITERS_LOADED';
export const COMPANY_LOADED = 'COMPANY_LOADED';
export const COMPANY_GENDERSCORE_SURVEY_DRAFT_SELECTED = 'COMPANY_GENDERSCORE_SURVEY_DRAFT_SELECTED';
export const COMPANY_UMS_SURVEY_DRAFT_SELECTED = 'COMPANY_UMS_SURVEY_DRAFT_SELECTED';
export const ALL_ACCESS_LOADED = 'ALL_ACCESS_LOADED';
export const LAST_BATCH_LOADED = 'LAST_BATCH_LOADED';
export const SET_COMPANY_SELECTED = 'SET_COMPANY_SELECTED';
export const SET_COMPANY_EDITOR = 'SET_COMPANY_EDITOR';
export const ATS_ARCHIVE_REASONS_LOADED = 'ATS_ARCHIVE_REASONS_LOADED';
export const ATS_PIPELINES_LOADED = 'ATS_PIPELINES_LOADED';
export const COMPANY_ACCESSES_LOADED = 'COMPANY_ACCESSES_LOADED';
export const SET_COMPANY_ACCESS_SELECTED = 'SET_COMPANY_ACCESS_SELECTED';
export const JOBS_LOADED = 'JOBS_LOADED';
export const TAGS_JOBS_LOADED = 'TAGS_JOBS_LOADED';
export const SET_JOB_SELECTED = 'SET_JOB_SELECTED';
export const COMPANY_PLAN_EXTRA_TAGS_LOADED = 'COMPANY_PLAN_EXTRA_TAGS_LOADED';
export const SET_HISTORY = 'SET_HISTORY';
export const ITEMS_LOADED = 'ITEMS_LOADED';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const TAGS_LOADED = 'TAGS_LOADED';
export const TAG_RULES_LOADED = 'TAG_RULES_LOADED';
export const TAGS_CATEGORY_LOADED = 'TAGS_CATEGORY_LOADED';
export const SET_TAG_RULE_SELECTED = 'SET_TAG_RULE_SELECTED';
export const USERS_LOADED = 'USERS_LOADED';
export const TAGS_USERS_LOADED = 'TAGS_USERS_LOADED';
export const COACHING_USERS_LOADED = 'COACHING_USERS_LOADED';
export const CAMPAIGN_EMAIL_FILTERS_LOADED = 'CAMPAIGN_EMAIL_FILTERS_LOADED';
export const SET_USER_SELECTED = 'SET_USER_SELECTED';
export const SHORTLISTS_LOADED = 'SHORTLISTS_LOADED';
export const SHORTLIST_USERS_LOADED = 'SHORTLISTS_USERS_LOADED';
export const SET_SHORTLIST_SELECTED = 'SET_SHORTLIST_SELECTED';
export const SET_SHORTLISTS_USER_SELECTED = 'SET_SHORTLISTS_USER_SELECTED';
export const SET_SHORTLISTS_COMPANY_SELECTED = 'SET_SHORTLISTS_COMPANY_SELECTED';
export const SOURCINGS_LOADED = 'SOURCINGS_LOADED';
export const FEEDBACKS_LOADED = 'FEEDBACKS_LOADED';
export const SET_SOURCING_SELECTED = 'SET_SOURCING_SELECTED';
export const SET_SOURCINGS_USER_SELECTED = 'SET_SOURCINGS_USER_SELECTED';
export const SET_SOURCINGS_COMPANY_SELECTED = 'SET_SOURCINGS_COMPANY_SELECTED';
export const SET_SOURCINGS_STATUS_SELECTED = 'SET_SOURCINGS_STATUS_SELECTED';
export const SET_SOURCINGS_SOURCE_SELECTED = 'SET_SOURCINGS_SOURCE_SELECTED';
export const MESSAGES_LOADED = 'MESSAGES_LOADED';
export const SET_MESSAGE_SELECTED = 'SET_MESSAGE_SELECTED';
export const ACLS_LOADED = 'ACLS_LOADED';
export const SET_ACL_SELECTED = 'SET_ACL_SELECTED';
export const SET_ACLS_USER_SELECTED = 'SET_ACLS_USER_SELECTED';
export const SET_ACLS_COMPANY_SELECTED = 'SET_ACLS_COMPANY_SELECTED';
export const SET_ACLS_STATUS_SELECTED = 'SET_ACLS_STATUS_SELECTED';
export const SET_ACLS_SOURCE_SELECTED = 'SET_ACLS_SOURCE_SELECTED';
export const SET_ACTION_HISTORY = 'SET_ACTION_HISTORY';
export const READ_ACTION_HISTORY = 'READ_ACTION_HISTORY';
export const SET_JOBS_ID = 'SET_JOBS_ID';
