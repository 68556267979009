const slugify = require('@sindresorhus/slugify');
const { isStringTypeValidator } = require('../../utils/isStringTypeValidator');
const CONTINENTS = require('../continents.json');

const LOCATION_CONTINENT_KEY = 'location-continent';

const LOCATION_CONTINENT_OPTIONS = CONTINENTS;

const LOCATION_CONTINENT_VALUES = LOCATION_CONTINENT_OPTIONS.map((o) => o.value);

const customValidator = (value) => LOCATION_CONTINENT_VALUES.includes(value);

const LOCATION_CONTINENT_VALIDATORS = [
	isStringTypeValidator,
	customValidator,
];

export default {
	key: LOCATION_CONTINENT_KEY,
	LOCATION_CONTINENT_KEY,
	validators: LOCATION_CONTINENT_VALIDATORS,
	LOCATION_CONTINENT_VALIDATORS,
	options: LOCATION_CONTINENT_OPTIONS,
	LOCATION_CONTINENT_OPTIONS,
};
