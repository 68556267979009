import { request } from './api';
import { LocalDebug } from '../utils/LocalDebug';

const className = 'CoachingService';

const editCoach = (userId, data) => {
	LocalDebug.logInfo({ className, method: 'editCoach' }, { userId, data });
	return request.post(`/coaching/${userId}/edit-coach`, data);
};

const getCoaches = () => {
	return request.get('/coaching/coaches');
};

const sendUserContactEmail = (userId, data) => {
	LocalDebug.logInfo({ className, method: 'sendUserContactEmail' }, { userId, data });
	return request.post(`/coaching/${userId}/send-contact-email`, data);
};

const sendUserExclusionEmail = (userId, data) => {
	LocalDebug.logInfo({ className, method: 'sendUserExclusionEmail' }, { userId, data });
	return request.post(`/coaching/${userId}/send-exclusion-email`, data);
};

const CoachingService = {
	editCoach,
	getCoaches,
	sendUserContactEmail,
	sendUserExclusionEmail,
};

export default CoachingService;
