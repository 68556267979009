import { useTheme } from '@emotion/react';
import {
	getLevelDetails,
	getLevelFromScore,
} from '../../../../constants/genderscore-levels';
import GenderScoreLayoutBox from '../layout/GenderScoreLayoutBox';
import GenderScoreLevelLabel from '../levels/GenderScoreLevelLabel';
import GenderScoreLevelDetailsLink from './GenderScoreLevelDetailsLink';
import GenderScoreResultsPillarsLink from './GenderScoreResultsPillarsLink';

const GenderScoreResultsLevelBox = ({
	company,
	forceValue,
}) => {
	const className = 'GenderScoreResultsLevelBox';

	const theme = useTheme();

	const genderScore = forceValue || company?.genderScore;

	const levelValue = getLevelFromScore(genderScore?.globalScore);

	return (
		<GenderScoreLayoutBox
			style={{
				paddingBottom: 48,
			}}
		>
			<h5>
				Your score of <strong>{company?.genderScore?.globalScore}</strong> makes you a&nbsp;
				<span style={{
					fontSize: 20,
					fontWeight: 'bold',
					textTransform: 'uppercase',
					color: theme.color.fitBlueElectric,
				}}>
					<GenderScoreLevelLabel level={levelValue} withUnderline={false} withPopover={false} />
				</span>
			</h5>
			<p style={{
				fontSize: 14,
				color: '#999',
				fontStyle: 'italic',
			}}>
				{getLevelDetails(levelValue).range}
			</p>

			<p>
				<strong>What does this score mean?</strong>
			</p>

			{getLevelDetails(levelValue)?.analysis}

			<p>
				To get a more detailed analysis on which areas need improvement, please take a look at
				the <GenderScoreResultsPillarsLink
					context='level-box'
				>
					breakdown by pillars
				</GenderScoreResultsPillarsLink> below.
			</p>

			<div style={{
				textAlign: 'right', position: 'absolute', right: 24, bottom: 24,
			}}>
				<GenderScoreLevelDetailsLink
					company={company}
					forceValue={forceValue}
					context={'level-box'}
				/>
			</div>

		</GenderScoreLayoutBox>
	);
};

export default GenderScoreResultsLevelBox;
