import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { useTheme } from '@emotion/react';
import { useSelector } from 'react-redux';
import { getCompanySelected } from '../../../../reducers/app';
import { Button, Space, Statistic } from 'antd';
import { GENDERSCORE_LABEL, PATHS } from '../../../../constants/constant';
import CompanyModel from '../../../../models/CompanyModel';
import { useNavigate } from 'react-router';
import CSM_DETAILS from '../../../../constants/csmDetails';
import { openExternalUrl } from '../../../../utils/common';

const GenderScoreCountdownValueBlock = ({
	label,
	value,
	format,
	...props
}) => {
	const theme = useTheme();

	return (
		<div
			style={{
				width: 60,
				borderRadius: 10,
				background: 'rgba(255, 255, 255, .8)',
				display: 'flex',
				flexDirection: 'column',
				justifyContent: 'center',
				alignItems: 'center',
				padding: '0 6px 2px',
				userSelect: 'none',
				...props?.style,
			}}
		>
			<Statistic
				value={value}
				valueStyle={{
					padding: 0,
					...props?.style?.value,
				}}/>
			<span
				style={{
					fontSize: 11,
					marginTop: -8,
					...props?.style?.label,
				}}
			>
				{value > 1 ? label[1] : label[0]}
			</span>
		</div>
	);
};

const GenderScoreExpiredBanner = ({
	company,
	withNavigateToSurvey = true,
}) => {
	const className = 'GenderScoreExpiredBanner';

	const theme = useTheme();
	const navigate = useNavigate();

	const companySelected = useSelector(getCompanySelected);

	// const [companyModel, setCompanyModel] = useState(null);
	const [content, setContent] = useState(null);

	useEffect(() => {
		const companyModel = new CompanyModel(companySelected);
		setContent(null);
		if (companyModel.isGenderScoreExpired()) {
			const csmDetails = companyModel?.isGenderScoreCompanyPlan()
				? CSM_DETAILS.defaultCsm
				: CSM_DETAILS.defaultCsm;

			setContent(
				<>
					<span>Your plan has expired, don't lose your progress</span>
					<Button
						ghost
						onClick={() => openExternalUrl(`mailto:${csmDetails?.user?.email}`)}
					>
						Talk to Customer Success Team
					</Button>
				</>,
			);
		} else if (companyModel.isCurrentGenderScoreBadgeExpired()) {
			setContent(
				<>
					<span>Your {GENDERSCORE_LABEL} has expired, don't lose your progress</span>
					{withNavigateToSurvey && <Button
						ghost
						onClick={() => navigate(PATHS.GENDERSCORE_SURVEY())}
					>
						Update my {GENDERSCORE_LABEL}
					</Button>}
				</>,
			);
		}
	}, [companySelected]);
	const mainColor = '#e26f6c';

	return (
		<div
			id='genderscore-expired-banner'
			style={{
				width: '100%',
				height: 100,
				background: mainColor,
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
			}}
		>
			<Space
				direction='vertical'
				style={{
					fontWeight: 'bold',
					fontSize: 16,
					color: 'white',
					justifyContent: 'center',
					alignItems: 'center',
				}}
			>
				{content}
			</Space>
		</div>
	);
};

export default GenderScoreExpiredBanner;
