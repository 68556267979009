/** @jsxImportSource @emotion/react */
import { Select } from 'antd';
import { LocalDebug } from '../../../utils/LocalDebug';

const FormSelect = (
	{
		options,
		showSearch = true,
		onChange,
		defaultValue,
		value,
		placeholder = 'Select a value',
		styles = {},
		isSorted = false,
		render,
		children,
		mode,
		listHeight = 256,
		blockScroll = false,
		getPopupContainer = (trigger) => trigger.parentNode,
		...props
	},
) => {
	const className = 'FormSelect';

	const _handleChange = (value, option) => {
		if (onChange) onChange(value, option);
	};

	const handleClearValue = () => {
		if (onChange) onChange(null);
	};

	return (
		<Select
			showSearch={showSearch}
			options={!children && !render && options}
			optionFilterProp="children"
			placeholder={placeholder}
			onChange={_handleChange}
			defaultValue={defaultValue}
			value={value}
			allowClear
			onClear={handleClearValue}
			style={{ ...styles, position: 'relative' }}
			mode={mode}
			listHeight={listHeight}
			getPopupContainer={blockScroll ? getPopupContainer : null}

			{...(isSorted
				? { filterSort: (a, b) => (a?.label || '').toString().toLowerCase().localeCompare((b?.label || '').toString().toLowerCase()) }
				: {}
			)}
			filterOption={(input, option) => {
				// LocalDebug.logInfo({ className, method: 'filterOption' }, { input, option });
				return (option?.label || '').toString().toLowerCase().indexOf((input || '').toString().toLowerCase()) >= 0;
			}}
			{...props}
		>
			{render && options?.map((option, index, options) => render(option, index, options))}
			{children}
		</Select>
	);
};

export default FormSelect;
