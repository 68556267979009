import { Space } from 'antd';
import { useTheme } from '@emotion/react';
import {
	FaBed, FaCog,
	FaGhost,
	FaPaperPlane, FaTemperatureHigh, FaTrashAlt,
} from 'react-icons/fa';
import { request } from '../../../services/api';
import AdminToolsSection from './AdminToolsSectionContainer';
import AdminConfirmRequestBtn from './AdminConfirmRequestBtn';

const AdminToolsWelcomeFlowSection = () => {
	const theme = useTheme();

	return (
		<AdminToolsSection title='Welcome Flow' Icon={FaPaperPlane}>
			<Space direction='vertical' size={4}>
				<Space direction='horizontal' size={4}>
					<AdminConfirmRequestBtn
						title='Get status'
						Icon={FaTemperatureHigh}
						request={async () => request.get('/admin/welcome-flow/status')}
					/>
					<AdminConfirmRequestBtn
						title='Fake data'
						Icon={FaGhost}
						// styles={{ color: 'red', textColor: 'white', style: { border: 'none' } }}
						request={async () => request.get('/admin/welcome-flow/fake-users')}
					/>
					<AdminConfirmRequestBtn
						title='Skip day'
						Icon={FaBed}
						request={async () => request.get('/admin/welcome-flow/skip-day')}
					/>
					<AdminConfirmRequestBtn
						title='Send (no mail)'
						Icon={FaCog}
						request={async () => request.get('/admin/welcome-flow/send-nomail')}
					/>
					<AdminConfirmRequestBtn
						title='Delete data'
						Icon={FaTrashAlt}
						styles={{ color: 'red', textColor: 'white', style: { border: 'none' } }}
						request={async () => request.get('/admin/welcome-flow/delete')}
					/>
				</Space>
			</Space>
		</AdminToolsSection>
	);
};

export default AdminToolsWelcomeFlowSection;
