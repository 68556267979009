import {
	Col, Divider, Row, Space,
} from 'antd';
import { useTheme } from '@emotion/react';
import { extractTokensFromHtml } from '../../../utils/common';
import UserCareerSeeking from '../widgets/UserCareerSeeking';
import UserHider from '../buttons/UserHider';
import UserShortlister from '../buttons/UserShortlister';
import UserContacter from '../buttons/UserContacter';
import UserSourcer from '../buttons/UserSourcer';
import UserLevel from '../widgets/UserLevel';
import UserHardSkills from '../widgets/UserHardSkills';
import UserJobRoles from '../widgets/UserJobRoles';
import { useAuthContext } from '../../../hooks/useAuthContext';
import UserDEICriteria from '../widgets/UserDEICriteria';
import { getCoachingExperienceLabel } from '../../../constants/constant';
import UserSoftSkills from '../widgets/UserSoftSkills';
import UserAtsSender from '../buttons/UserAtsSender';
import UserSaver from '../buttons/UserSaver';
import UserJobLevel from '../widgets/UserJobLevel';

const UserPreviewCard = (
	{
		user,
		withButtons = true,
		withCover = true,
		withName = true,
		withTargetRoles = true,
		withProfession = true,
		withLocation = true,
		withExperience = false,
		withLevel = true,
		withHardSkills = true,
		withSoftSkills = true,
		withDEICriteria = true,
		withIsNew = true,
		withIsUnviewed = true,
		onHideUser = null,
		onSavedUser = null,
		onContactUser = null,
		onSourceUser = null,
		onAtsPushUser = null,
		onRefresh = null,
		children,
		...props
	},
) => {
	const theme = useTheme();

	const { isAtsSyncable, isCompanyRecruiter, isDocumentAdmin } = useAuthContext();

	const handleUserHidden = (e) => {
		if (onHideUser) onHideUser(user);
		if (onRefresh) onRefresh();
		e?.stopPropagation();
	};

	const handleUserSavedForLater = (e) => {
		if (onSavedUser) onSavedUser(user);
		if (onRefresh) onRefresh();
		e?.stopPropagation();
	};

	const handleUserContacted = (e) => {
		if (onContactUser) onContactUser(user);
		if (onRefresh) onRefresh();
		e?.stopPropagation();
	};

	const handleUserSourced = (e) => {
		if (onSourceUser) onSourceUser(user);
		if (onRefresh) onRefresh();
		e?.stopPropagation();
	};

	const handleUserAtsPushed = (e) => {
		if (onAtsPushUser) onAtsPushUser(user);
		if (onRefresh) onRefresh();
		e?.stopPropagation();
	};

	// localDebug('hard skills: ', extractTokensFromHtml(user.coaching?.coachingHardSkills))

	const tagStyle = {
		margin: 2,
		fontSize: 12,
		padding: '1px 5px',
		fontWeight: 'bold',
		border: 'none',
	};

	const buildTitleContentBlock = (title, content) => <>
		{/* // <Row gutter={[10, 20]} span={24} style={{fontSize: 14}}> */}
		<Col span={7} style={{ color: '#333', textAlign: 'right' }}><b>{title}</b></Col>
		<Col span={17}>{content}</Col>
		{/* // </Row> */}
	</>;

	return (
		<div style={{ maxWidth: 420 }}>
			<Row gutter={[14, 14]}>
				{buildTitleContentBlock(
					'Status',
					<UserCareerSeeking
						user={user}
						style={{
							whiteSpace: 'normal', textAlign: 'center', margin: 0, maxWidth: '100%',
						}}
					/>,
				)}

				{withTargetRoles
					&& buildTitleContentBlock(
						'Desired roles',
						<UserJobRoles
							user={user}
							forceValue={extractTokensFromHtml(user.coaching?.coachingTargetPositions, null)}
						/>,
					)
				}

				{withExperience
					&& buildTitleContentBlock(
						'Years of XP',
						<UserJobLevel
							span={17}
							user={user}
							forceValue={getCoachingExperienceLabel(user)}
						/>,
					)
				}

				{withLevel
					&& buildTitleContentBlock(
						'Experience',
						<UserJobLevel
							span={17}
							user={user}
						/>,
					)
				}

				{withHardSkills
					&& buildTitleContentBlock(
						'Hard Skills',
						<UserHardSkills
							user={user}
							forceValue={extractTokensFromHtml(user.coaching?.coachingHardSkills, null)}
						/>,
					)
				}

				{withSoftSkills
					&& user?.coaching?.coachingSoftSkills?.length > 0
					&& buildTitleContentBlock(
						'Soft Skills',
						<UserSoftSkills
							user={user}
							forceValue={extractTokensFromHtml(user.coaching?.coachingSoftSkills, null)}
						/>,
					)
				}

				{withDEICriteria
					&& buildTitleContentBlock(
						'DE&I Fit',
						<UserDEICriteria user={user}/>,
					)
				}
			</Row>
			{withButtons
				&& (isCompanyRecruiter || isDocumentAdmin)
				&& (
					<>
						<Divider style={{ margin: '10px 0 0' }}/>
						<Space
							direction='horizontal'
							wrap={false}
							style={{ width: '100%', justifyContent: 'space-between' }}
						>
							<Space
								direction='horizontal'
								wrap={false}
							>
								<UserSaver
									user={user}
									onComplete={handleUserSavedForLater}
									mode={isAtsSyncable ? 'saved' : 'shortlists'}
								/>
								<UserContacter
									user={user}
									onComplete={handleUserContacted}
								/>
								{isAtsSyncable
									? (
										<UserAtsSender
											user={user}
											onComplete={handleUserAtsPushed}
										/>
									)
									: (
										<UserSourcer
											user={user}
											onComplete={handleUserSourced}
										/>
									)
								}
							</Space>

							<Divider orientation='vertical'/>

							<UserHider
								user={user}
								onComplete={handleUserHidden}
							/>
						</Space>
					</>
				)
			}
		</div>
	);
};

export default UserPreviewCard;
