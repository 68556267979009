import { useTheme } from '@emotion/react';

const UserGridCardAlert = (
	{
		user,
		withIsNew = true,
		...props
	},
) => {
	const className = 'UserGridCardAlert';

	const theme = useTheme();

	if (!user.isNewId) {
		return null;
	}

	return (
		<div
			style={{
				position: 'absolute',
				fontSize: 14,
				fontWeight: 'bold',
				background: theme.color.accent,
				// background: theme.color.bgGrey,
				color: theme.color.white,
				padding: '2px 8px',
				borderRadius: 20,
				top: 14,
				left: 14,
				userSelect: 'none',
				...props?.style,
			}}>
			NEW
		</div>
	);
};

export default UserGridCardAlert;
