import { Avatar, Tooltip } from 'antd';
import { Image, Transformation } from 'cloudinary-react';
import { useTheme } from '@emotion/react';
import { FaBuilding } from 'react-icons/fa';
import { getMetaAltImage } from '../../utils/seo/MetaValues';
import { ENGLISH_LANG } from '../../constants/constant';
import CompanyModel from '../../models/CompanyModel';
import { useMemo, useState } from 'react';
import { documentId } from '../../utils/common';

const CompanyLogo = (
	{
		company,
		size = 32,
		withTooltip = true,
		withPlaceholder = true,
		...props
	},
) => {
	const theme = useTheme();

	const [logo, setLogo] = useState('https://res.cloudinary.com/dkatmtbbc/image/upload/v1678920859/company/company-logo-none.jpg');

	useMemo(() => {
		if (company) {
			setLogo(company?.logo
				|| 'https://res.cloudinary.com/dkatmtbbc/image/upload/v1678920859/company/company-logo-placeholder.jpg');
		} else {
			setLogo('https://res.cloudinary.com/dkatmtbbc/image/upload/v1678920859/company/company-logo-none.jpg');
		}
	}, [documentId(company)]);

	// const Placeholder = <Avatar style={{
	// 	display: 'flex',
	// 	justifyContent: 'center',
	// 	alignItems: 'center',
	// 	background: 'linear-gradient(' + theme.color.lightgrey + ', ' + theme.color.lightestFitBlueElectric + ')',
	// 	outline: '1px solid rgba(0,0,0,.05)',
	// 	outlineOffset: -1,
	// 	width: size,
	// 	height: size,
	// 	borderRadius: Math.round(size / 8),
	// 	color: theme.color.white,
	// 	fontSize: size / 2,
	// }}>
	// 	<FaBuilding />
	// </Avatar>;

	const tooltiper = (children) => <Tooltip placement='top' title={company?.name || 'No company'}>{children}</Tooltip>;

	// if (!company) {
	// 	if (withPlaceholder) {
	// 		return withTooltip
	// 			? tooltiper(Placeholder)
	// 			: Placeholder;
	// 	}
	// 	return (<span style={{ color: theme.color.lightgrey }}>N/A</span>);
	// }
	//
	// if (!company.logo) {
	// 	if (withPlaceholder) {
	// 		return withTooltip
	// 			? tooltiper(Placeholder)
	// 			: Placeholder;
	// 	}
	// 	return null;
	// }

	const parts = logo.split('/upload/').reverse()[0].split('/');
	parts.shift();
	const logoCloudinaryId = parts.join('/');
	const borderRadius = Math.round(size / 8);
	const avatar = <Avatar
		shape='square'
		{...props}
		style={{
			outline: '1px solid rgba(0,0,0,.05)',
			outlineOffset: -1,
			// border: '1px solid rgba(0,0,0,.2)',
			borderRadius,
			width: size,
			height: size,
			marginBottom: 1,
			// boxShadow: '0 2px 10px -2px rgba(0,0,0,.3)',
			...props?.style,
		}}
		src={
			<Image width={size} publicId={logoCloudinaryId} secure='true' alt={getMetaAltImage(new CompanyModel(company), ENGLISH_LANG)}>
				<Transformation width={size * 2} height={size * 2} crop='fill' />
				<Transformation width={Math.round(size * 2.1)} height={Math.round(size * 2.1)} crop='thumb'
					pad='fill' />
				{/* <Transformation radius={borderRadius} /> */}
			</Image>
		} />;

	return (withTooltip
		? tooltiper(avatar)
		: avatar
	);
};

export default CompanyLogo;
