import { Button, Space } from 'antd';
import { useTheme } from '@emotion/react';
import { FaPlusSquare } from 'react-icons/fa';
import { writeLog } from '../../../utils/common';

const AddButton = (
	{
		title,
		// icon = <BiAddToQueue style={{marginBottom: -1}}/>,
		icon = <FaPlusSquare style={{ marginBottom: -2 }}/>,
		iconPosition = 'left',
		color,
		textColor,
		border,
		onClick,
		disabled,
		...props
	},
) => {
	const theme = useTheme();
	const { style, ...propsRest } = props;

	const handleClick = () => {
		writeLog(`Clic button: ${title}`);

		if (onClick) onClick();
	};

	return <Button
		className="add-button"
		type='primary'
		style={{
			// background: color || theme.color.blue,
			background: color || theme.color.lilac,
			border: border || 'none',
			fontWeight: 'bold',
			borderRadius: 4,
			margin: 0,
			color: (disabled ? theme.color.grey : textColor) || 'white',
			...style,
		}}
		onClick={handleClick}
		disabled={disabled}
		{...propsRest}
	>
		<Space direction='horizontal' size={5}>
			{icon && iconPosition === 'left' && icon}
			{title}
			{icon && iconPosition !== 'left' && icon}
		</Space>
	</Button>;
};

export default AddButton;
