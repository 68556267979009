import { SearchOutlined } from '@ant-design/icons';
import {
	Button, Divider, Input, Menu, Space,
} from 'antd';
import Checkbox from 'antd/es/checkbox/Checkbox';
import { useState } from 'react';
import { useTheme } from '@emotion/react';
import { localDebug } from '../../../utils/LocalDebug';

const FilterDropDownSelect = ({
	values = [], placeholder = '', onChange, onFilter, onReset, filters,
}) => {
	const theme = useTheme();

	const [search, setSearch] = useState('');

	localDebug('FilterDropDownSelect', { filters });
	const handleCheckboxChanged = (e, filter) => {
		// localDebug('handleCheckboxChanged', filter, filter.value + ' => ' + e.target.checked, values);

		values = [
			...values
				.filter((v) => v !== filter.value)
				.filter((v) => !(search && search !== '') || (new RegExp(search, 'i')).test(v)),
			...(e.target.checked ? [filter.value] : []),
		];
		// localDebug('handleCheckboxChanged', values);
		onChange(values);
	};

	const handleReset = () => {
		onChange([]);
		onReset();
	};

	const handleSearch = (e) => {
		localDebug('handleSearch', { value: e.target.value });
		setSearch(e.target.value);
	};

	// return (
	//     <Dropdown trigger='hover' overlay={<LayoutMenu style={{border: 'none', maxHeight: '200px', overflow: 'scroll', marginBottom: '8px'}}>
	//             {filters.map((filter, index) => (
	//                 <LayoutMenu.Item key={index}
	//                            style={{background: "transparent !important", height: '28px', lineHeight: '28px', margin: 0, padding: '0 4px'}}>
	//                     <Checkbox checked={values.includes(filter.value)}
	//                               onChange={(e) => handleCheckboxChanged(e, filter)}>
	//                         {filter.text}
	//                     </Checkbox>
	//                 </LayoutMenu.Item>
	//             ))}
	//         </LayoutMenu>}
	//     >
	//         <a className="ant-dropdown-link" onClick={e => e.preventDefault()} style={{marginTop: '-30px'}}>
	//             Hover
	//         </a>
	//     </Dropdown>
	// );
	return (
		<div style={{ padding: 8 }}>
			<Input
				value={search}
				prefix={<SearchOutlined fill={theme.color.lightgrey}/>}
				placeholder={placeholder}
				onChange={handleSearch}
				style={{ marginBottom: 8 }}
			/>
			<Divider style={{ margin: '0 -10px 6px -10px', width: 'calc(100% + 20px)' }}/>
			<Menu style={{
				border: 'none', maxHeight: '200px', overflow: 'scroll', marginBottom: '6px',
			}}>
				{filters
					.filter((f) => !(search && search !== '') || new RegExp(search, 'i').test(f.text))
					.map((filter) => {
						// localDebug(filter);
						return (<Menu.Item key={filter.value} style={{
							background: 'transparent !important',
							height: '28px',
							lineHeight: '28px',
							margin: 0,
							padding: '0 4px',
						}}>
							<Checkbox checked={values.includes(filter.value)}
									  onChange={(e) => handleCheckboxChanged(e, filter)}>
								{filter.text}
							</Checkbox>
						</Menu.Item>);
					})}
			</Menu>
			<Divider style={{ margin: '0 -10px 8px -10px', width: 'calc(100% + 20px)' }}/>
			<Space direction="horizontal" style={{ width: '100%', justifyContent: 'space-between' }}>
				<a disabled={values.length === 0}
				   style={{ padding: '0 4px' }}
				   onClick={handleReset}>
					Reset
				</a>
				<Button
					type="primary"
					onClick={onFilter}
					size="small"
				>
					OK
				</Button>
			</Space>
		</div>
	);
};

export default FilterDropDownSelect;
