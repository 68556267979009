import { GENDERSCORE_LABEL } from '../../../../constants/constant';
import FormItem from '../../form/FormItem';
import CompanyPlanGenderScoreSurveySelect from './CompanyPlanGenderScoreSurveySelect';

export const companyPlanGenderScoreSurveyPath = ['companyPlan', 'genderScore', 'survey'];

const CompanyPlanGenderScoreSurveyFormItem = ({
	required = false,
}) => {
	const className = 'CompanyPlanGenderScoreSurveyFormItem';

	return (
		<FormItem
			label={`${GENDERSCORE_LABEL} Survey`}
			name={companyPlanGenderScoreSurveyPath}
			rules={[
				{
					required,
					message: 'This field is required',
				},
			]}>
			<CompanyPlanGenderScoreSurveySelect />
		</FormItem>
	);
};

export default CompanyPlanGenderScoreSurveyFormItem;
