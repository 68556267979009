import MenuBase from '../MenuBase';
import { FormOutlined } from '@ant-design/icons';
import { PERMS_GENDERSCORE_FILLED } from './genderscore-utils';
import GenderScoreAnswersPage from '../../containers/staff/genderscore/GenderScoreAnswersPage';

export const GenderScoreSurveyAnswersIcon = FormOutlined;

class GenderScoreSurveyAnswersMenu extends MenuBase {
	static props = {
		name: 'genderscore-answers',
		path: '/genderscore/answers',
		label: 'Survey answers',
		icon: <GenderScoreSurveyAnswersIcon />,
		iconLegacy: '📝',
		Component: GenderScoreAnswersPage,
		perms: PERMS_GENDERSCORE_FILLED,
	};

	constructor() {
		super(GenderScoreSurveyAnswersMenu.props);
	}
}

export default GenderScoreSurveyAnswersMenu;
