import {
	Button, Tooltip,
} from 'antd';
import { pull } from 'lodash';
import { useTheme } from '@emotion/react';
import { useEffect, useState } from 'react';
import { FaPen } from 'react-icons/fa';
import { useDispatch } from 'react-redux';
import { updateOrCreateJob } from '../../../actions/jobs';
import { useAuthContext } from '../../../hooks/useAuthContext';
import { documentId, inArray, writeLog } from '../../../utils/common';
import JobEditManagersPopover from './JobEditManagersPopover';
import JobModel from '../../../models/JobModel';

const JobEditManagersButton = (
	{
		job,
		onRefresh,
	},
) => {
	const className = 'JobEditManagersButton';
	const { currentUser } = useAuthContext();

	// dipatch
	const dispatch = useDispatch();
	const theme = useTheme();

	const [managerIds, setManagerIds] = useState([]);
	const [othersManager, setOthersManager] = useState([]);

	// popover state
	const [openManagerPopover, setOpenManagerPopover] = useState(false);
	const [initialValuesManagerForm, setInitialValuesManagerForm] = useState({});

	useEffect(() => {
		if (job) {
			setManagerIds(job?.managerIds || []);
			const othersJobManagers = (job?.managers || []).filter((manager) => documentId(manager)?.toString?.() !== documentId(currentUser)?.toString());
			setOthersManager(othersJobManagers);
		}
	}, [currentUser?.id, job]);

	const addCurrentUserToJobManager = (managerId) => {
		const newManagerId = managerId || documentId(currentUser);
		const managerIds = [newManagerId, ...(job?.managerIds || [])];
		writeLog('Add currentUser for job manager ', { jobId: documentId(job), managerId: newManagerId });
		saveJob({ id: documentId(job), managerIds });
	};

	const handleEditManager = (managers) => {
		writeLog('click edit manager ', { jobId: documentId(job) });
		setInitialValuesManagerForm({ job, managers });
		setOpenManagerPopover(true);
	};

	const handleRemoveManager = (managerId) => {
		const newManagerId = managerId || documentId(currentUser);
		writeLog('Remove currentUser for job manager ', { jobId: documentId(job), managerId: newManagerId });
		const managerIds = pull((job?.managerIds || []), newManagerId);
		saveJob({ id: documentId(job), managerIds });
	};

	const handleCloseManagerPopover = () => {
		writeLog('close edit manager ');
		setOpenManagerPopover(false);
	};

	const handleSaveOtherManagers = async (data) => {
		writeLog('save managers for a job ', { jobId: documentId(data), managers: data?.managerIds });
		await saveJob(data);
		setOpenManagerPopover(false);
	};

	const saveJob = async (data) => {
		await dispatch(updateOrCreateJob(data));
		if (onRefresh) onRefresh();
	};

	const disabled = !new JobModel(job).isJobPublished;

	const button = (
		<Button
			disabled={disabled}
			type='default'
			style={{ borderRadius: 100, width: 32, height: 32 }}
			icon={<FaPen style={{ marginBottom: 0, fontSize: 11 }} />}
			onClick={() => handleEditManager(job?.managers)}
		/>
	);

	return (
		<>
			{disabled
				? button
				: <>
					<Tooltip title={<>Edit recipients list</>} placement='top' destroyTooltipOnHide={true}>
						{button}
					</Tooltip>
					<JobEditManagersPopover
						job={job}
						open={openManagerPopover}
						initialValues={initialValuesManagerForm}
						onReset={handleCloseManagerPopover}
						onSubmit={handleSaveOtherManagers}
					/>
				</>
			}
		</>
	);
};

export default JobEditManagersButton;
