import { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
	getCompanies,
	getCompanySelected,
	getDocumentReadersIds,
} from '../../reducers/app';
import SourcingTable from '../../components/sourcings/SourcingTable';
import { addSourcing, deleteStep, removeSourcing } from '../../actions/sourcings';
import { SocketContext } from '../../contexts/SocketProvider';
import {
	addMultipleQueryParams,
	documentId,
	getFilterOptionsWithoutForced,
	getQueryParams,
	removeQueryParams,
	writeLog,
} from '../../utils/common';
import { localDebug } from '../../utils/LocalDebug';
import {
	SOURCING_DOCUMENT_REF,
	APPLICATIONS_DETAILS,
	SOURCED_DETAILS,
	ARCHIVED_DETAILS,
	SHORTLISTS_ICON, SHORTLISTS_TITLE, SHORTLISTS_DETAILS,
	SUGGESTED_BY_50_TITLE, SUGGESTED_BY_50_DETAILS,
	STAGE_SHORTLISTS_LIST,
	SOURCE_APPLICATIONS_LIST,
	SOURCE_SOURCED_LIST,
	STAGE_SOURCED_LIST,
	STAGE_APPLICATIONS_LIST,
	sourcingStages,
	SOURCE_SHORTLISTS_LIST,
} from '../../constants/constant';
import SourcingDrawer from '../../components/sourcings/SourcingDrawer';
import PageLayout from '../../components/app/layout/PageLayout';
import SourcingService from '../../services/sourcing';
import UserStub from '../../components/user/stub/UserStub';
import CompanyStub from '../../components/company/CompanyStub';
import { useAuthContext } from '../../hooks/useAuthContext';
import FilterButton from '../../components/app/filters/FilterButton';
import SourcingFilterModal from '../../components/sourcings/SourcingFilterModal';
import Logo50inTech from '../../components/app/Logo50inTech';
import { SOURCE_50INTECH_VALUE } from '../../constants/sourcing';

const queryParams = getQueryParams();

const SourcingsBasePage = ({
	mode = 'sourcings',
	MenuClass = () => <></>,
}) => {
	// localDebug('Sourcings', { mode });
	const dispatch = useDispatch();
	// query params

	const companySelected = useSelector(getCompanySelected);
	localDebug('Sourcings', { mode, companySelected: documentId(companySelected) });
	const {
		isStaff, isStaffView, isEditor, companyIdsUser,
	} = useAuthContext();

	let baseOptionsFilterTable = {};
	let modalOptions = null;
	let subtitle;

	switch (mode) {
		case 'sourcings':
			subtitle = SOURCED_DETAILS;
			modalOptions = {
				sourcingStages: sourcingStages.filter((s) => STAGE_SOURCED_LIST.includes(s.value)),
			};
			baseOptionsFilterTable = {
				source: SOURCE_SOURCED_LIST,
				stage: STAGE_SOURCED_LIST,
				isArchived: false,
			};
			break;
		case 'applications':
			subtitle = APPLICATIONS_DETAILS;
			modalOptions = {
				sourcingStages: sourcingStages.filter((s) => STAGE_APPLICATIONS_LIST.includes(s.value)),
			};
			baseOptionsFilterTable = {
				source: SOURCE_APPLICATIONS_LIST,
				stage: STAGE_APPLICATIONS_LIST,
				isArchived: false,
			};
			break;
		case 'archived':
			subtitle = ARCHIVED_DETAILS;
			modalOptions = {
				sourcingStages: sourcingStages.filter((s) => STAGE_APPLICATIONS_LIST.includes(s.value)),
			};
			baseOptionsFilterTable = {
				isArchived: true,
			};
			break;
		case 'shortlists':
			MenuClass = { props: { icon: SHORTLISTS_ICON, label: SHORTLISTS_TITLE } };
			subtitle = SHORTLISTS_DETAILS;
			modalOptions = {
				sourcingStages: sourcingStages.filter((s) => STAGE_APPLICATIONS_LIST.includes(s.value)),
			};
			baseOptionsFilterTable = {
				source: SOURCE_SHORTLISTS_LIST,
				stage: STAGE_SHORTLISTS_LIST,
				isArchived: false,
			};
			break;
		case 'suggestedBy50':
			MenuClass = { props: { icon: <Logo50inTech size={32} />, label: SUGGESTED_BY_50_TITLE } };
			subtitle = SUGGESTED_BY_50_DETAILS;
			baseOptionsFilterTable = {
				source: SOURCE_50INTECH_VALUE,
				isArchived: false,
			};
			break;
		default:
			break;
	}

	const { createAction } = useContext(SocketContext);

	const [optionsFilterTable, setOptionsFilterTable] = useState({
		...queryParams || {},
		...baseOptionsFilterTable,
		companyId: documentId(companySelected),
	});

	// sourcings data and sourcing status //
	const [sourcings, setSourcings] = useState([]);
	const [isRefreshDataLayout, setIsRefreshDataLayout] = useState(0);

	const companies = useSelector(getCompanies);
	const documentReadersIds = useSelector(getDocumentReadersIds);

	const [openSourcingDrawer, setOpenSourcingDrawer] = useState(false);
	const [initSourcingValuesForm, setInitSourcingValuesForm] = useState({});

	const refreshData = () => {
		setIsRefreshDataLayout((prev) => prev + 1);
	};

	const handleSubmit = async (values, title = 'create a sourcing') => {
		// write log
		writeLog('handleSubmit sourcing', { data: values });

		localDebug('handleSubmit', values);
		const valuesToDb = { ...values };
		const result = await addSourcing(valuesToDb);
		setOpenSourcingDrawer(false);
		refreshData();
		// create action
		if (result) {
			const payload = {
				documentRef: SOURCING_DOCUMENT_REF,
				documentId: documentId(result),
				companyId: result.companyId,
				action: {
					title,
					...(values?.history && { history: values.history }),
				},
			};
			createAction(payload);
		}
	};
	// ----------------------------------------------------- //
	// --------- after loading data sourcings -------------- //
	// ----------------------------------------------------- //
	const handleLoadedData = ({ items } = {}) => {
		setSourcings(items || []);
	};

	const handleAddSourcing = () => {
		setInitSourcingValuesForm({});
		setOpenSourcingDrawer(true);
	};

	const handleDeleteStep = (id, step, sourcing) => {
		dispatch(deleteStep(id, step, sourcing));
		refreshData();
	};

	const handleDeleteSourcing = (sourcing) => {
		dispatch(removeSourcing(sourcing));
		refreshData();
	};

	// ------------------------------------------------- //
	// ---------- synchro auto complete data ----------- //
	// ------------------------------------------------- //
	const handleSearchAutoComplete = async (options) => {
		const { data: { sourcings } } = await SourcingService.findAll(options, '/groupBy');
		return (sourcings || []).map((item) => {
			localDebug('log', item);
			let option = {};
			switch (options.searchField) {
				case 'user':
					option = {
						value: `${documentId(item).firstName} ${documentId(item).lastName}`,
						label: <UserStub user={documentId(item)} withLink={false} />,
					};
					break;
				case 'company':
					option = {
						value: documentId(item).name,
						label: <CompanyStub company={documentId(item)} withLink={false} />,
					};
					break;
				case 'job':
					option = {
						value: documentId(item).title,
						label: documentId(item).title,
					};
					break;
				default:
					option = {
						value: documentId(item),
						label: documentId(item),
					};
			}
			return option;
		});
	};

	// --------------------------------------- //
	// ------------ handle filter ------------ //
	// --------------------------------------- //
	const handleFilter = (values) => {
		const { tags, ...rest } = values;
		addMultipleQueryParams(rest);
		setOptionsFilterTable((prev) => {
			return ({
				...baseOptionsFilterTable,
				...prev,
				...rest,
				companyId: documentId(companySelected),
				// isStaffView,
				tags,
			});
		});
	};

	const handleClearFilter = () => {
		setOptionsFilterTable({
			...baseOptionsFilterTable,
			companyId: documentId(companySelected),
			// isStaffView,
		});
		// refreshData();
		removeQueryParams();
	};

	useEffect(() => {
		localDebug('Sourcings.useEffect[companySelected]', documentId(companySelected));
		setOptionsFilterTable((prev) => ({
			...baseOptionsFilterTable,
			companyId: documentId(companySelected),
		}));
	}, [companySelected]);

	return (
		<PageLayout
			MenuClass={MenuClass}
			subtitle={subtitle}
			withBackground={false}
			filter={(
				modalOptions && (
					<FilterButton
						title="Filter list"
						modal={SourcingFilterModal}
						modalOptions={modalOptions}
						onFilter={handleFilter}
						onClearFilter={handleClearFilter}
						initialValues={getFilterOptionsWithoutForced(
							optionsFilterTable,
							baseOptionsFilterTable,
						)}
						forcedValues={baseOptionsFilterTable}
					/>
				)
			)}
		>
			<SourcingTable
				onDataLoaded={handleLoadedData}
				dataSource={sourcings}
				filterOptions={optionsFilterTable}
				isEditor={isEditor}
				onEditSourcing={handleSubmit}
				isRefresh={isRefreshDataLayout}
				onDeleteSourcing={handleDeleteSourcing}
				onDeleteStep={handleDeleteStep}
				onSearchDataColumn={handleSearchAutoComplete}
				documentReadersIds={documentReadersIds}
				mode={mode}
			/>
			<SourcingDrawer
				open={openSourcingDrawer}
				initialValues={initSourcingValuesForm}
				onClose={() => setOpenSourcingDrawer(false)}
				onSubmit={handleSubmit}
				onReset={() => setOpenSourcingDrawer(false)}
			/>
		</PageLayout>
	);
};

export default SourcingsBasePage;
