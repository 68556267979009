import {
	regular,
	PDF_PAGE_TEMPLATE_COVER,
	PDF_TEXT_STYLES,
	PDF_TYPE_DIVIDER,
	PDF_TYPE_TEXT,
	PDF_TYPE_TITLE,
	PDF_TYPE_IMAGE,
	PDF_PAGE_TEMPLATE_CONTENT,
} from '../base/PDFBuilder';

const lines = [
	'Arnold, V., Collier, P. A., Leech, S. A., & Sutton, S. G. (2000). The effect of experience and complexity on order and recency bias in decision making by professional accountants. Accounting & Finance, 40(2), 109-134.',
	'Charles, M., Harr, B., Cech, E., & Hendley, A. (2014).Who likes math where ? Gender differences in eighth - graders’ attitudes around the world.International Studies in Sociology of Education, 24(1), 85–112. doi: 10.1080 /09620214.2014.895140',
	'Henagan, S. C., & Bedeian, A. G. (2009). The Perils of Success in the Workplace: Comparison Target Responses to Coworkers\' Upward Comparison Threat 1. Journal of Applied Social Psychology, 39(10), 2438-2468.',
	'Korteling, J. E., & Toet, A. (2022). Cognitive biases. Encyclopedia of Behavioral Neuroscience, 610-619.',
	'Lagesen, V. A. (2008). A cyberfeminist utopia? Perceptions of gender and computer science among Malaysian women computer science students and faculty. Science, technology, & human values, 33(1), 5-27.',
	'Madon, S., Jussim, L., Guyll, M., Nofziger, H., Salib, E. R., Willard, J., & Scherr, K. C. (2018). The accumulation of stereotype-based self-fulfilling prophecies. Journal of Personality and Social Psychology, 115(5), 825.',
	'Noor, N., Beram, S., Yuet, F. K. C., Gengatharan, K., & Rasidi, M. S. M. (2023). Bias, Halo Effect and Horn Effect: A Systematic Literature.',
	'Pontarotti, G. (2020). La distinction entre l’inné et l’acquis a-t-elle encore un sens ?. Dans : Patrick Pion éd., Apprendre: Archéologie de la transmission des savoirs (pp. 35-48). Paris: La Découverte. https://doi.org/10.3917/dec.pion.2020.01.0035',
	'Richman, L. S., Vandellen, M., & Wood, W. (2011). How women cope: Being a numerical minority in a male‐dominated profession. Journal of social issues, 67(3), 492-509.',
	'Rudman, L. A., & Phelan, J. E. (2008). Backlash effects for disconfirming gender stereotypes in organizations. Research in organizational behavior, 28, 61-79.',
	'Schmader, T. (2023). Gender inclusion and fit in STEM. Annual Review of Psychology, 74, 219-243.',
	'Soylu Yalcinkaya, N., & Adams, G. (2020). A cultural psychological model of cross-national variation in gender gaps in STEM participation. Personality and Social Psychology Review, 24(4), 345-370.',
];

const PageBibliography = async ({
	doc,
	context,
}) => {
	const color = '#000000';

	return [
		{
			template: PDF_PAGE_TEMPLATE_CONTENT,
			title: {
				en: 'Bibliography',
				fr: 'Bibliographie',
			},
			footer: true,
			pageCount: true,
			items: [
				// ...await doc.getPageTemplateCover({ context }),
				// {
				// 	type: PDF_TYPE_DIVIDER,
				// 	marginBottom: 8,
				// 	color,
				// },
				// {
				// 	type: PDF_TYPE_TITLE,
				// 	text: 'Bibliography',
				// 	marginBottom: 12,
				// 	color,
				// },
				...lines.map((line) => ({
					type: PDF_TYPE_TEXT,
					...regular(PDF_TEXT_STYLES.normal),
					fontSize: 12,
					text: line,
					marginBottom: 6,
					color,
				})),
			],
		},
	];
};

export default PageBibliography;
