import { useTheme } from '@emotion/react';
import {
	Button, Col, Form, Input, message, Row, Typography,
} from 'antd';
import { useState } from 'react';
import { FiSend } from 'react-icons/fi';
import Modal from '../../../app/modal/Modal';
import UserSelect from '../../../user/UserSelect';
import FormItem from '../../form/FormItem';
import { LocalDebug } from '../../../../utils/LocalDebug';

const CompanyTeamAddModal = ({
	open,
	onClose,
	onInviteUser,
	teams,
	onSave,
	initialValues = {},
}) => {
	const className = 'CompanyTeamAddModal';

	const [form] = Form.useForm();
	const theme = useTheme();

	const [inviteEmail, setInviteEmail] = useState(null);

	const _onInviteUser = () => {
		if (!inviteEmail) {
			message.error('Please add an email ');
			return;
		}
		form
			.validateFields()
			.then((values) => {
				onInviteUser({
					...values,
					email: inviteEmail,
				});
				form.resetFields();
			});
	};

	const handleClose = () => {
		form.resetFields();
		onClose();
	};

	return (
		<Modal
			title='Add a team member'
			open={open}
			okText="OK"
			cancelText="NO, CANCEL"
			onCancel={handleClose}
			onOk={() => {
				form
					.validateFields()
					.then((values) => {
						const { userId } = values;
						if (!userId) {
							message.error('Please select an user ');
							return;
						}
						onSave(values);
						form.resetFields();
					})
					.catch((info) => {
						LocalDebug.logInfo({ className, method: 'onOk (catch)' }, 'Validate Failed:', info);
					});
			}}
		>
			<Form
				form={form}
				name="add-team"
				labelCol={{ span: 4 }}
				labelAlign='left'
				// wrapperCol={{ span: 1 }}
			>
				<FormItem
					label='User'
					name="userId"
				>
					<UserSelect
						placeholder="Select a user"
						style={{ width: '100%' }}
						defaultOptions={[]}
						notUserIds={initialValues.notUserIds || []}
					/>
				</FormItem>
			</Form>
		</Modal>
	);
};

export default CompanyTeamAddModal;
