import { useSelector } from 'react-redux';
import { useTheme } from '@emotion/react';
import { getCompanySelected } from '../../reducers/app';
import { Form } from 'antd';
import FormSubmitButton from '../app/form/FormSubmitButton';
import { useAuthContext } from '../../hooks/useAuthContext';
import {
	GENDERSCORE_LABEL,
	PrivacyPolicyLink,
} from '../../constants/constant';
import { useContext, useEffect, useState } from 'react';
import { SurveyContext } from './survey/SurveyProvider';
import CompanyModel from '../../models/CompanyModel';
import { documentId } from '../../utils/common';

const GenderScoreSurveyStartForm = ({
	...props
}) => {
	const className = 'GenderScoreSurveyStartForm';

	const theme = useTheme();
	const { currentUser } = useAuthContext();
	const companySelected = useSelector(getCompanySelected);
	const { createOrUpdateSurveyDraft, dispatchSelectSurveyDraft } = useContext(SurveyContext);

	const [currentUserDraft, setCurrentUserDraft] = useState();
	const [form] = Form.useForm();

	useEffect(() => {
		setCurrentUserDraft(
			new CompanyModel(companySelected)
				.getGenderScoreSurveyDraftByUser({ userId: documentId(currentUser) }),
		);
	}, [currentUser, companySelected]);

	const handleSubmit = async () => {
		if (currentUserDraft) {
			dispatchSelectSurveyDraft(currentUserDraft);
		} else {
			await createOrUpdateSurveyDraft({ withDispatch: true });
		}
	};

	return (
		<div>
			<Form
				form={form}
				name='company-genderscore-survey-start'
				company={companySelected}
				onFinish={handleSubmit}
			>

				<FormSubmitButton
					size={'large'}
					style={{
						paddingTop: 30,
						paddingBottom: 55,
						fontSize: 24,
						lineHeight: 1.3,
						width: '100%',
						borderRadius: 10,
					}}
				>
					Start your {GENDERSCORE_LABEL} Survey
				</FormSubmitButton>

			</Form>

			<div
				style={{
					color: theme.color.grey,
					fontSize: 14,
				}}>
				<p>
					Personal information shared in this questionnaire is strictly confidential in
					compliance with General Data Protection Regulation (GDPR). Any collective insights
					from all questionnaires will be shared anonymously. Please read
					our <PrivacyPolicyLink />.
				</p>
			</div>
		</div>
	);
};

export default GenderScoreSurveyStartForm;
