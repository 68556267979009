import GenderScorePage from '../../containers/genderscore/GenderScorePage';
import { GENDERSCORE_LABEL } from '../../constants/constant';
import { PERMS_GENDERSCORE_FILLED } from './genderscore-utils';
import {
	GENDERSCORE_PILLAR_DEIACTIONS_OPTION,
	GENDERSCORE_PILLAR_EQUALPAY_OPTION,
	GENDERSCORE_PILLAR_FAIRCAREERPATH_OPTION,
	GENDERSCORE_PILLAR_GENDERDATA_OPTION,
	GENDERSCORE_PILLAR_WORKLIFEBALANCE_OPTION,
} from '../../constants/genderscore-pillars';
import GenderScorePillarHrDeiInitiativesPage from '../../containers/genderscore/GenderScorePillarHrDeiInitiativesPage';
import GenderScorePillarWorkLifeBalancePage from '../../containers/genderscore/GenderScorePillarWorkLifeBalancePage';
import GenderScorePillarCareerPathPage from '../../containers/genderscore/GenderScorePillarCareerPathPage';
import GenderScorePillarEqualPayPage from '../../containers/genderscore/GenderScorePillarEqualPayPage';
import GenderScorePillarGenderDataPage from '../../containers/genderscore/GenderScorePillarGenderDataPage';
import GenderScoreSurveyAnswersMenu from './GenderscoreSurveyAnswersMenu';
import GenderscoreResultsMenu from './GenderscoreResultsMenu';
import CompanyGenderScoreRingScore from '../../components/company/genderscore/CompanyGenderScoreRingScore';
import GenderscoreResultsPillarMenu from './GenderscoreResultsPillarMenu';
import MenuBase from '../MenuBase';
import { menuGroupBuilder } from '../menu.utils';

export const GenderscoreFilledIcon = ({
	size,
	background = 'white',
	color,
	genderScore,
	ringColor,
}) => {
	return (
		<span>
			<div
				style={{
					margin: `0 -${size * 10 / 100}px`,
					background: 'none',
					borderRadius: size * 20 / 100,
					// padding: size * 6 / 100,
					padding: 0,
				}}
			>
				<CompanyGenderScoreRingScore
					size={size}
					scoreColor={color}
					ringColor={ringColor}
					{...genderScore?.globalScore > 0
						? {
							forceScore: genderScore?.globalScore,
							value: genderScore?.globalScore,
						}
						: {
							ringColor: '#eee',
							forceScore: <span style={{ color: '#aaa' }}>?</span>,
							value: 100,
						}
					}
				/>
			</div>
		</span>
	);
};

class GenderscoreFilledMenu extends MenuBase {
	static props = {
		name: 'genderscore-filled-menu',
		label: <>Your {GENDERSCORE_LABEL}</>,
		// icon: () => (args) => <GenderscoreFilledIcon {...args} />,
		// icon: () => ({
		// 	size,
		// 	background = 'white',
		// 	color,
		// 	genderScore,
		// 	ringColor,
		// }) => <GenderscoreFilledIcon
		// 	size={size}
		// 	background={background}
		// 	color={color}
		// 	genderScore={genderScore}
		// 	ringColor={ringColor}
		// />,
		icon: () => ({
			size,
			background = 'white',
			color,
			genderScore,
			ringColor,
		}) => {
			return (
				<span>
					<div
						style={{
							margin: `0 -${size * 10 / 100}px`,
							background: 'none',
							borderRadius: size * 20 / 100,
							// padding: size * 6 / 100,
							padding: 0,
						}}
					>
						<CompanyGenderScoreRingScore
							size={size}
							scoreColor={color}
							ringColor={ringColor}
							{...genderScore?.globalScore > 0
								? {
									forceScore: genderScore?.globalScore,
									value: genderScore?.globalScore,
								}
								: {
									ringColor: '#eee',
									forceScore: <span style={{ color: '#aaa' }}>?</span>,
									value: 100,
								}
							}
						/>
					</div>
				</span>
			);
		},
		iconLegacy: () => ({
			size,
			background = 'white',
			color,
			genderScore,
		}) => (
			<div
				style={{
					margin: `0 -${size * 10 / 100}px`,
					background,
					borderRadius: size * 20 / 100,
					padding: size * 6 / 100,
				}}
			>
				<CompanyGenderScoreRingScore
					size={size}
					scoreColor={color}
					{...genderScore?.globalScore > 0
						? {
							forceScore: genderScore?.globalScore,
							value: genderScore?.globalScore,
						}
						: {
							ringColor: '#eee',
							forceScore: <span style={{ color: '#aaa' }}>?</span>,
							value: 100,
						}
					}
				/>
			</div>
		),
		Component: GenderScorePage,
		children: (...args) => [
			(new GenderscoreResultsMenu()).build(...args),
			(new GenderScoreSurveyAnswersMenu()).build(...args),
			menuGroupBuilder({ label: <>{GENDERSCORE_LABEL} Pillars</>, offset: 2 }),
			(new GenderscoreResultsPillarMenu({
				pillar: GENDERSCORE_PILLAR_GENDERDATA_OPTION,
				Component: GenderScorePillarGenderDataPage,
			})).build(),
			(new GenderscoreResultsPillarMenu({
				pillar: GENDERSCORE_PILLAR_EQUALPAY_OPTION,
				Component: GenderScorePillarEqualPayPage,
			})).build(),
			(new GenderscoreResultsPillarMenu({
				pillar: GENDERSCORE_PILLAR_FAIRCAREERPATH_OPTION,
				Component: GenderScorePillarCareerPathPage,
			})).build(),
			(new GenderscoreResultsPillarMenu({
				pillar: GENDERSCORE_PILLAR_WORKLIFEBALANCE_OPTION,
				Component: GenderScorePillarWorkLifeBalancePage,
			})).build(),
			(new GenderscoreResultsPillarMenu({
				pillar: GENDERSCORE_PILLAR_DEIACTIONS_OPTION,
				Component: GenderScorePillarHrDeiInitiativesPage,
			})).build(),
		],
		perms: PERMS_GENDERSCORE_FILLED,
	};

	constructor() {
		super(GenderscoreFilledMenu.props);
	}
}

export default GenderscoreFilledMenu;
