import { useTheme } from '@emotion/react';
import { Space } from 'antd';
import { FaExclamationCircle } from 'react-icons/fa';

const AccountBrowsingSettingOverrideDisabled = ({}) => {
	const className = 'AccountBrowsingSettingOverrideDisabled';

	const theme = useTheme();

	return (
		<Space style={{ fontSize: 12, color: theme.color.accent }} align='top'>
			<FaExclamationCircle style={{ marginBottom: -2 }}/>
			Modification of this setting has been disabled by your company admin
		</Space>
	);
};

export default AccountBrowsingSettingOverrideDisabled;
