import { useState } from 'react';
import { Button } from 'antd';
import { request } from '../../../services/api';
import { documentId } from '../../../utils/common';
import { LocalDebug } from '../../../utils/LocalDebug';
import { BulbOutlined, LoadingOutlined } from '@ant-design/icons';

const UMSStaffSurveyComputeResultButton = ({
	survey,
	onSuccess,
}) => {
	const className = 'UMSStaffSurveyComputeResultButton';
	const [isLoading, setIsLoading] = useState(false);

	const handleClick = async () => {
		setIsLoading(true);
		try {
			const result = await request.get(`/ums/survey/${documentId(survey)}/compute`);
			LocalDebug.logInfo({ className, method: 'handleClick' }, { result });
			setIsLoading(false);
			onSuccess?.();
		} catch (e) {
			setIsLoading(false);
		}
	};

	const disabled = isLoading || !survey?.isComplete;

	return (
		<Button
			disabled={disabled}
			onClick={handleClick}
		>
			Compute result&nbsp;{isLoading ? <LoadingOutlined /> : <BulbOutlined />}
		</Button>
	);
};

export default UMSStaffSurveyComputeResultButton;
