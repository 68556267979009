import { useContext } from 'react';
import { useSelector } from 'react-redux';
import PageLayout from '../../components/app/layout/PageLayout';
import { useTheme } from '@emotion/react';
import { getCompanySelected } from '../../reducers/app';
import CompanyGridPicker from '../../components/company/CompanyGridPicker';
import CompanyModel from '../../models/CompanyModel';
import { GENDERSCORE_PILLAR_DEIACTIONS_VALUE } from '../../constants/genderscore-pillars';
import GenderScorePillar from '../../components/company/genderscore/pillars/GenderScorePillar';
import { SurveyContext } from '../../components/genderscore/survey/SurveyProvider';
import SurveySubmissionPending from '../../components/genderscore/survey/SurveySubmissionPending';

const GenderScorePillarHrDeiInitiativesPage = () => {
	const className = 'GenderScorePillarHrDeiInitiativesPage';

	const theme = useTheme();

	const { isSurveySubmissionPending } = useContext(SurveyContext);
	const companySelected = useSelector(getCompanySelected);

	if (!companySelected) return <CompanyGridPicker />;

	if (isSurveySubmissionPending) return <SurveySubmissionPending />;

	return (
		<PageLayout
			withBackground={false}
			withPadding={false}
			withHeader={false}
		>
			{new CompanyModel(companySelected).isGenderScoreFilled()
				? (
					<GenderScorePillar
						company={companySelected}
						pillar={GENDERSCORE_PILLAR_DEIACTIONS_VALUE}
					/>
				)
				: 'GenderScore not filled yet'
			}

		</PageLayout>
	);
};

export default GenderScorePillarHrDeiInitiativesPage;
