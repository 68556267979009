import { useEffect, useState } from 'react';
import { useTheme } from '@emotion/react';
import { useLocation, useNavigate } from 'react-router';
import {
	ArrowRightIcon, scrollToTop,
} from '../../../../utils/common';
import EmptyData from '../../../app/EmptyData';
import GenderScoreActionGrid from './GenderScoreActionGrid';
import { LocalDebug } from '../../../../utils/LocalDebug';
import { GENDERSCORE_ACTIONS_MAP } from '../../../../constants/genderscore-actions';
import { Button, Space } from 'antd';
import GenderScoreLayoutPageHeader from '../layout/GenderScoreLayoutPageHeader';
import GenderScoreLayoutGrid from '../layout/GenderScoreLayoutGrid';
import CompanyGenderScoreBadge from '../CompanyGenderScoreBadge';
import { useAuthContext } from '../../../../hooks/useAuthContext';
import { GENDERSCORE_LABEL, PATHS } from '../../../../constants/constant';
import GenderscoreActionPlanMenu from '../../../../menu/genderscore/GenderscoreActionPlanMenu';
import { PageLayoutHeader } from '../../../app/layout/PageLayout';
import CompanyActionPlanGrid from '../../actionPlan/CompanyActionPlanGrid';

const ActionPlanGridNoData = ({
	description,
}) => {
	return (
		<div
			style={{
				width: '100%',
				height: 320,
				border: '1px dashed #ccc',
				borderRadius: 6,
				display: 'flex',
				flexDirection: 'column',
				justifyContent: 'center',
				alignItems: 'center',
			}}
		>
			<EmptyData
				description={description}
				style={{
					border: 'none',
					marginTop: 0,
					marginBottom: 0,
				}}
			/>
		</div>

	);
};

const ActionPlanGridNoDataGSFilledAdmin = () => {
	const navigate = useNavigate();

	return (
		<ActionPlanGridNoData
			description={(
				<Space
					direction='vertical'
					style={{ maxWidth: 600 }}
					size={20}
				>
					<span
						style={{
							color: '#333',
							fontSize: 14,
							lineHeight: 1.2,
						}}
					>
						Nothing yet!<br/>Start planning your next actions:
					</span>

					<Button
						type='primary'
						size='large'
						onClick={() => navigate('/genderscore')}
						style={{ marginBottom: 32 }}
					>
						<strong>Browse our recommended actions</strong>&nbsp;<ArrowRightIcon />
					</Button>
				</Space>
			)}
		/>
	);
};

const ActionPlanGridNoDataGSEmptyAdmin = () => {
	const navigate = useNavigate();

	return (
		<ActionPlanGridNoData
			description={(
				<Space
					direction='vertical'
					style={{ maxWidth: 600 }}
					size={20}
				>
					<span
						style={{
							color: '#333',
							fontSize: 14,
							lineHeight: 1.2,
						}}
					>
						Embark on your GenderScore journey with our game-changing action plans – your
						secret weapon for shaping a workplace that rocks with diversity, equity, and inclusion.
					</span>

					<Button
						type='primary'
						size='large'
						onClick={() => {
							navigate(PATHS.GENDERSCORE_SURVEY());
							scrollToTop();
						}}
						style={{ marginBottom: 32 }}
					>
						<strong>Complete your {GENDERSCORE_LABEL}</strong>&nbsp;<ArrowRightIcon />
					</Button>
				</Space>
			)}
		/>
	);
};

const ActionPlanGridNonAdmin = () => {
	const navigate = useNavigate();

	return (
		<ActionPlanGridNoData
			description={(
				<Space
					direction='vertical'
					style={{ maxWidth: 800 }}
					size={20}
				>
					<span
						style={{
							color: '#333',
							fontSize: 14,
							lineHeight: 1.2,
						}}
					>
						Equip yourself with insights to champion diversity, equity, and inclusion in your workspace
					</span>

					<Button
						type='primary'
						size='large'
						onClick={() => navigate('/resources')}
						style={{ marginBottom: 32 }}
					>
						<strong>Explore DEI resources</strong>&nbsp;<ArrowRightIcon />
					</Button>
				</Space>
			)}
		/>
	);
};

const GenderScoreActionPlan = ({
	company,
	forceValue,
	pillar,
	index,
	...props
}) => {
	const className = 'GenderScoreActionPlan';

	const theme = useTheme();

	const { isStaffView, isDocumentAdmin, isGenderScoreFilled } = useAuthContext();

	const location = useLocation();

	const [headerSubtitle, setHeaderSubtitle] = useState(null);
	const [data, setData] = useState();
	const [noData, setNoData] = useState(null);

	const genderScore = forceValue || company?.genderScore;

	const onAddSuccess = ({ action }) => {
		LocalDebug.logInfo({ className, method: 'onAddSuccess' }, { action, data });
	};

	const onRemoveSuccess = ({ action }) => {
		LocalDebug.logInfo({ className, method: 'onRemoveSuccess' }, { action, data });
	};

	useEffect(() => {
		setData(
			(company.genderScoreActions?.actions || [])
				.filter((item) => item.isActive !== false)
				.map((item) => {
					LocalDebug.logInfo({ className }, { item });
					const { actionId, action, pillar } = item;
					const dataAction = GENDERSCORE_ACTIONS_MAP[actionId];
					if (!dataAction) return;
					LocalDebug.logInfo({ className }, {
						dataAction, actionId, action, pillar, GENDERSCORE_ACTIONS_MAP,
					});
					return ({
						...dataAction,
						value: actionId,
						pillar: action?.pillar || pillar,
						isSelected: true,
					});
				})
				.filter((item) => item),
		);
	}, [company.genderScoreActions]);

	useEffect(() => {
		scrollToTop();
	}, [location.pathname]);

	useEffect(() => {
		if (isGenderScoreFilled) {
			setHeaderSubtitle(
				<>
					<p style={{ fontSize: 16 }}>
						<strong>Ready to kick things up?</strong>
					</p>
					<p>
						Here is your game-changing actions list – your secret weapon
						for shaping a workplace that rocks with diversity, equity, and inclusion.
					</p>
					<p>
						You’ll find here the ones you selected to fit your team and company!
					</p>
				</>,
			);
			setNoData(
				<ActionPlanGridNoDataGSFilledAdmin />,
			);
		} else {
			setHeaderSubtitle(
				<>
					<p style={{ fontSize: 16 }}>
						<strong>This is where you plan your transformative steps.</strong>
					</p>
					<p>
						Our action plans, available upon completing your GenderScore,
						are custom-tailored to elevate your company's DEI impact.
					</p>
				</>,
			);
			setNoData(
				<ActionPlanGridNoDataGSEmptyAdmin />,
			);
		}
	}, [isGenderScoreFilled]);

	return (
		<>
			<GenderScoreLayoutPageHeader
				color={['#cfd5f4', '#fae1e6']}
				height={250}
				style={{ marginBottom: 32 }}
			>
				<GenderScoreLayoutGrid
					columns={'5fr 3fr 1fr'}
					style={{
						minHeight: 170,
					}}
				>
					<PageLayoutHeader
						icon={GenderscoreActionPlanMenu.props.icon}
						title={GenderscoreActionPlanMenu.props.label()({ withCount: false })}
						subtitle={headerSubtitle}
					/>

					<div/>

					<CompanyGenderScoreBadge
						company={company}
						version='white'
						withPopover={false}
					/>

				</GenderScoreLayoutGrid>
			</GenderScoreLayoutPageHeader>

			<div
				id={'body'}
				style={{
					// padding: 32,
					paddingTop: 0,
				}}
			>
				<div
					style={{
						padding: 32,
						paddingTop: 0,
						background: theme.color.bgGrey,
					}}
				>
					{isDocumentAdmin
						? (data?.length > 0
							? (
								<GenderScoreActionGrid
									company={company}
									dataSource={data}
									withSelectButton={true}
									withExpandLimit={false}
									onAddSuccess={onAddSuccess}
									onRemoveSuccess={onRemoveSuccess}
									style={{ grid: { marginBottom: 0 } }}
								/>
							)
							: noData
						)
						: <ActionPlanGridNonAdmin />
					}
				</div>
			</div>
		</>
	);
};

export default GenderScoreActionPlan;
