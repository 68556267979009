import { UMS_LABEL_SHORT } from '../../../../constants/constant';
import FormItem from '../../form/FormItem';
import CompanyPlanUMSSurveyEmployeeSelect from './CompanyPlanUMSSurveyEmployeeSelect';

export const companyPlanUMSSurveyEmployeePath = ['companyPlan', 'ums', 'surveyEmployee'];

const CompanyPlanUMSSurveyEmployeeFormItem = ({
	required = false,
}) => {
	const className = 'CompanyPlanUMSSurveyEmployeeFormItem';

	return (
		<FormItem
			label={`${UMS_LABEL_SHORT} Survey for Employees`}
			name={companyPlanUMSSurveyEmployeePath}
			rules={[
				{
					required,
					message: 'This field is required',
				},
			]}>
			<CompanyPlanUMSSurveyEmployeeSelect />
		</FormItem>
	);
};

export default CompanyPlanUMSSurveyEmployeeFormItem;
