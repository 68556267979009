import StaffHiddenCompaniesPage from '../../../containers/staff/users/StaffHiddenCompaniesPage';
import { PERMS_GRANT_STAFF } from '../../menu.utils';
import MenuBase from '../../MenuBase';
import { EyeInvisibleOutlined } from '@ant-design/icons';

export const StaffHiddenCompaniesIcon = EyeInvisibleOutlined;

class StaffHiddenCompaniesMenu extends MenuBase {
	static props = {
		name: 'staff-hidden-companies',
		path: '/staff/user/hidden-companies',
		label: 'Hidden Companies',
		icon: <StaffHiddenCompaniesIcon />,
		iconLegacy: '🦸🙈',
		Component: StaffHiddenCompaniesPage,
		perms: PERMS_GRANT_STAFF,
	};

	constructor() {
		super(StaffHiddenCompaniesMenu.props);
	}
}

export default StaffHiddenCompaniesMenu;
