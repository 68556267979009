import { Button, Form } from 'antd';
import { useState } from 'react';
import { FaMinus, FaPlus } from 'react-icons/fa';
import { getTagsByCategory } from '../../../../../actions/tagRules';
import FormItem from '../../../../app/form/FormItem';
import FormSelect from '../../../../app/form/FormSelect';
import { requiredRule } from '../../../../../utils/common';

const TagInput = ({ tagCategoryOptions = [] }) => {
	const [tagValuesOptions, setTagValuesOptions] = useState({});

	const handleChangeCategory = async (value, index) => {
		const tags = await getTagsByCategory({ category: value });
		const optionsValues = tags.map((tag) => ({ label: tag.value, value: tag.slug }));
		setTagValuesOptions((prev) => ({ ...prev, [index]: optionsValues }));
	};

	return (
		<div className="flexRow" style={{ marginBottom: 20 }}>
			<div style={{
				width: 130, paddingRight: 10, marginBottom: 10, fontSize: 16, fontWeight: 600,
			}}>
				Tags
			</div>

			<Form.List name="tagSlugs">
				{(fields, { add, remove }) => {
					return (
						<div className="flex1">
							{fields.map((field, index) => {
								return (
									<div key={field.key} className="flexRow alignCenter spaceBetween ">
										<div className="flexRow alignCenter flex1">
											<FormItem
												name={[index, 'slugCategory']}
												defaultContainer="div"
												styles={{
													rootContainer: {
														display: 'flex',
														alignItems: 'center',
														justifyContent: 'space-between',
														marginBottom: 20,
														flex: 1,
														marginRight: 20,
													},
													label: { marginBottom: 0, paddingRight: 10, width: 130 },
													itemContainer: { marginBottom: 0, width: 200 },
													container: { flex: 1 },
													input: { height: 46 },
												}}
												rules={[{ ...requiredRule, message: 'Please select a category' }]}
											>
												<FormSelect options={tagCategoryOptions} placeholder="Category"
													onChange={(value) => handleChangeCategory(value, index)}/>
											</FormItem>
											<FormItem
												name={[index, 'slugValue']}
												defaultContainer="div"
												rules={[{ ...requiredRule, message: 'Please select a value' }]}
												styles={{
													rootContainer: {
														display: 'flex',
														alignItems: 'center',
														justifyContent: 'space-between',
														marginBottom: 20,
														flex: 1,
													},
													label: { marginBottom: 0, paddingRight: 10, width: 130 },
													itemContainer: { marginBottom: 0 },
													container: { flex: 1 },
													input: { height: 40 },
												}}
											>
												<FormSelect options={tagValuesOptions[index] || []}
													placeholder="value"/>
											</FormItem>
										</div>
										{fields.length > 1 ? (
											<Button
												type="text"
												className="dynamic-delete-button"
												onClick={() => remove(field.name)}
												icon={<FaMinus/>}
												style={{ marginBottom: 10 }}
											/>

										) : null}
									</div>
								);
							})}
							<Button
								type="dashed"
								onClick={() => add()}
								icon={<FaPlus/>}
							>
								Add
							</Button>
						</div>
					);
				}}
			</Form.List>
		</div>
	);
};

export default TagInput;
