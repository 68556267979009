import { useTheme } from '@emotion/react';
import { Space } from 'antd';

const GenderScoreNotProvidedBlock = ({
	label,
	width = 180,
	forceHeight,
	fontSize = 12,
	...props
}) => {
	const className = 'GenderScoreNotProvidedBlock';

	const theme = useTheme();
	const height = forceHeight || width * 0.5;
	return <Space
		style={{
			width,
			height,
			marginTop: 5,
			border: `1px dashed ${theme.dashboardColors.darkGray}`,
			...props?.style,
		}}>
		<p style={{
			width,
			fontSize,
			fontWeight: 400,
			textAlign: 'center',
			color: theme.color.fitBlack,
			opacity: 0.55,
			padding: '10%',
		}}>
			{label || 'This data was not provided'}
		</p>
	</Space>;
};
export default GenderScoreNotProvidedBlock;
