import Popin from '../../app/modal/Popin';
import JobOpenAIForm from './JobOpenAIForm';
import useBodyScrollBlocker from '../../../hooks/useBodyScrollBlocker';

const JobOpenAIPopover = (
	{
		open,
		title,
		initialValues = {},
		onSubmit,
		onReset,
	},
) => {
	const className = 'JobOpenAIPopover';

	useBodyScrollBlocker(open, className);

	return (
		<Popin
			open={open}
			onClose={onReset}
			title={title}
			variant='popover'
			styles={{
				container: {
					borderRadius: 0,
					position: 'relative',
					display: 'block',
					minWidth: '100vw',
					width: '100vw',
					height: '100vh',
					// height: '100vh',
					// overflowY: 'hidden',
				},
				title: { paddingBottom: 10 },
				content: { paddingTop: 0 },
			}}>

			<div style={{
				display: 'block',
				// height: 'calc(100vh - 120px)',
				height: 'calc(100vh - 120px)',
				// overflowY: 'scroll',
			}}>
				<JobOpenAIForm
					initialValues={initialValues}
					onSubmit={onSubmit}
					onReset={onReset}
				/>
			</div>
		</Popin>
	);
};

export default JobOpenAIPopover;
