import { Button, Form, Space } from 'antd';
import { useEffect, useState } from 'react';
import moment from 'moment';
import FormItem from '../app/form/FormItem';
import FormSelect from '../app/form/FormSelect';
import {
	documentId, getNextSourcingValueByAction, getSourcingActionOptions, requiredRule,
} from '../../utils/common';
import { localDebug } from '../../utils/LocalDebug';
import { treatSourcingActionToDb } from '../../actions/sourcings';
import { SOURCING_ACTION_STAGE, sourcingArchiveReasons, STOP_STATUS } from '../../constants/constant';
import { useAuthContext } from '../../hooks/useAuthContext';
import { STAGE_NEW } from '../../constants/sourcing';
import ModalContainerBase from '../../contexts/modals/ModalContainerBase';
import FormBoxTitle from '../app/form/FormBoxTitle';

const SourcingStageEditModal = ({
	initialValues,
	open,
	onClose,
	action,
	onConfirm,
}) => {
	const [form] = Form.useForm();
	const { currentUser } = useAuthContext();

	const [options, setOptions] = useState([]);
	const [nextValueSelected, setNextValueSelected] = useState({});
	const [isStop, setIsStop] = useState(false);

	useEffect(() => {
		// localDebug('SourcingEditModal useEffect')
		if (action?.type === SOURCING_ACTION_STAGE) {
			const nextValue = getNextSourcingValueByAction(action.value, action.type);
			const options = getSourcingActionOptions(action.value, action.type);
			setNextValueSelected(nextValue);
			/* Stage New cannot be manually selected by users.
			It's an artificial stage assigned when a user is shortlisted */
			setOptions(options.filter((o) => o.value !== STAGE_NEW));
			form.setFieldsValue({ 'payload.value': nextValue.value });
		}
	}, [action, form]);

	const handleChangeAction = (value, option) => {
		setNextValueSelected(option);
		setIsStop(value === STOP_STATUS);
	};

	const saveAction = (values) => {
		const history = {
			action: action?.type,
			creator: documentId(currentUser),
			postedAt: moment(),
			author: documentId(currentUser),
			...values,
		};

		const toDb = treatSourcingActionToDb({
			id: documentId(initialValues),
			status: initialValues?.status,
			...history,
		}, currentUser);

		onConfirm(toDb, action?.type);
	};

	return (
		<ModalContainerBase
			open={open}
			onCancel={onClose}
			footer={(
				<Space
					style={{ width: '100%', justifyContent: 'space-between' }}
				>
					<Button
						type='primary'
						ghost
						// danger
						onClick={onClose}
					>
						<b>No, cancel</b>
					</Button>
					<Button
						type='primary'
						onClick={() => {
							form
								.validateFields()
								.then((values) => {
									saveAction(values);
									form.resetFields();
								})
								.catch((info) => {
									localDebug('Validation failed:', info);
								});
						}}
					>
						<b>Yes, confirm</b>
					</Button>
				</Space>
			)}
		>
			<FormBoxTitle
				title={'Edit stage'}
			/>
			<Form
				form={form}
				layout="vertical"
				name="form_in_modal"
				initialValues={{ ...initialValues }}
			>
				<FormItem
					label='New stage'
					defaultContainer="div"
					name="payload.value"
					rules={[requiredRule]}
					styles={{
						rootContainer: {
							padding: '0 10px',
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'flex-start',
						},
						label: { marginBottom: 0, paddingRight: 20, width: 100 },
						itemContainer: { marginBottom: 10 },
						container: { flex: 1 },
					}}
				>
					<FormSelect
						options={options}
						placeholder={`Select a ${action?.type}`}
						onChange={handleChangeAction}
					/>
				</FormItem>
				{
					isStop && <FormItem
						label="Raisons"
						defaultContainer="div"
						name="payload.raison"
						rules={[requiredRule]}
						styles={{
							rootContainer: {
								padding: '0 10px',
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'flex-start',
							},
							label: { marginBottom: 0, paddingRight: 20, width: 100 },
							itemContainer: { marginBottom: 10 },
							container: { flex: 1 },
						}}
					>
						<FormSelect
							options={sourcingArchiveReasons}
							placeholder="Raison"
							defaultValue={initialValues?.payload?.raison}
							mode="tags"
						/>
					</FormItem>
				}
			</Form>
		</ModalContainerBase>
	);
};

export default SourcingStageEditModal;
