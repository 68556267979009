import QUESTIONS from '../../questions';
import { GENDERSCORE_PILLAR_DEIACTIONS_OPTION } from '../../../../../constants/genderscore-pillars';

const section = {
	...GENDERSCORE_PILLAR_DEIACTIONS_OPTION,
	details: `
### One out of two women is leaving tech due to **discrimination**.

DE&I policies help reduce unconscious bias in recruitment, promotion... and even among employees.`,
	questions: [
		QUESTIONS.DEI_ANTIHARASSMENT,
		QUESTIONS.DEI_ANTIDISCRIMINATION,
		QUESTIONS.DEI_COMPLAINTSPROCEDURE,
		QUESTIONS.DEI_RESPONSIBILITY,
		QUESTIONS.DEI_SUPPORTDIALOGUE,
		QUESTIONS.DEI_UNBIASEDRECRUITMENT,
		QUESTIONS.DEI_BIASTRAINING,
		QUESTIONS.DEI_LEARNINGCOMMITMENT,
		QUESTIONS.DEI_APPOINTEDINDIVIDUAL,
		QUESTIONS.DEI_ERGS,
		QUESTIONS.DEI_MENTALHEALTH,
		QUESTIONS.DEI_RECRUITMENTPRIOS,
		QUESTIONS.DEI_JUNIORHIRES,
		QUESTIONS.DEI_CAREERCHANGEHIRES,
		QUESTIONS.DEI_INTENTIONSNEXTYEAR,
	],
};

export default section;
