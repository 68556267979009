import UMSSurveyContainer from '../../components/ums/survey/layout/UMSSurveyContainer';
import { PERMS_UMS_ENABLED_MANAGER_OR_EMPLOYEE } from './ums-utils';
import MenuBase from '../MenuBase';
import { ExperimentOutlined } from '@ant-design/icons';
import { useAuthContext } from '../../hooks/useAuthContext';
import MenuBadge from '../MenuBadge';

export const UMSSurveyMenuIcon = ExperimentOutlined;

export const UMSSurveyMenuLabel = () => {
	const context = useAuthContext();

	const value = (context.isUMSManager && !context.isUMSManagerSurveyFilled ? 1 : 0)
			+ (context.isUMSEmployee && !context.isUMSEmployeeSurveyFilled ? 1 : 0);

	return (
		<>
			<span>Survey</span>
			<MenuBadge
				value={value > 0 ? ' ' : 0}
			/>
		</>
	);
};

class UMSSurveyMenu extends MenuBase {
	static props = {
		name: 'ums-survey',
		path: '/ums/survey',
		aliases: ['^/ums/survey'],
		label: () => () => <UMSSurveyMenuLabel />,
		icon: <UMSSurveyMenuIcon />,
		iconLegacy: '🔬',
		Component: UMSSurveyContainer,
		perms: PERMS_UMS_ENABLED_MANAGER_OR_EMPLOYEE,
	};

	constructor() {
		super(UMSSurveyMenu.props);
	}
}

export default UMSSurveyMenu;
