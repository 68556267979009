import {
	AutoComplete, Button, Form, Input,
} from 'antd';
import { FaMinus, FaPlus } from 'react-icons/fa';
import FormItem from '../../../../app/form/FormItem';
import FormSelect from '../../../../app/form/FormSelect';
import { requiredRule, requiredTrimRule } from '../../../../../utils/common';

const filterOptions = [
	{
		label: 'equals',
		value: 'equals',
	},
	{
		label: 'includes',
		value: 'includes',
	},
	{
		label: 'matches_i',
		value: 'matches_i',
	},
	{
		label: 'matches_word_i',
		value: 'matches_word_i',
	},
];
const Conditions = ({ defaultSchemaOptions = [] }) => {
	return (
		<div className="flexRow" style={{ marginBottom: 20 }}>
			<div style={{
				width: 130, paddingRight: 10, marginBottom: 10, fontSize: 16, fontWeight: 600,
			}}>
				Conditions
			</div>
			<Form.List name="conditions">
				{(fields, { add, remove }) => {
					return (
						<div className="flex1">
							{fields.map((field, index) => {
								return (
									<div key={field.key} className="flexRow alignCenter spaceBetween ">
										<div className="flexRow alignCenter spaceBetween flex1">
											<FormItem
												name={[index, 'documentField']}
												rules={[{ ...requiredRule, message: 'Please input your documentField!' }]}
												defaultContainer='div'
											>
												<AutoComplete
													style={{ width: 150 }}
													options={defaultSchemaOptions}
													filterOption={(inputValue, option) => option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1}
												/>
											</FormItem>

											<FormItem
												styles={{ itemContainer: { width: 200 } }}
												name={[index, 'filterType']}
												rules={[{ ...requiredRule, message: 'Please select your filterType!' }]}
												defaultContainer='div'
											>
												<FormSelect options={filterOptions} placeholder="filterType"/>
											</FormItem>
											<FormItem
												styles={{ input: { height: 40 } }}
												name={[index, 'value']}
												rules={[{ ...requiredTrimRule, message: 'Please input your value!' }]}
												defaultContainer='div'
											>
												<Input placeholder="Value" style={{ height: 40 }}/>
											</FormItem>
										</div>
										{fields.length > 1 ? (
											<Button
												type="text"
												className="dynamic-delete-button"
												onClick={() => remove(field.name)}
												icon={<FaMinus/>}
												style={{ marginBottom: 10 }}
											/>

										) : null}
									</div>
								);
							})}
							<Button
								type="dashed"
								onClick={() => add()}
								icon={<FaPlus/>}
							>
								Add
							</Button>
						</div>
					);
				}}
			</Form.List>
		</div>
	);
};

export default Conditions;
