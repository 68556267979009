import { Space, Tooltip } from 'antd';
import { findActionPillar, findActionPillarCategory } from './action-plan';
import GenderScorePillarLogoLabel from '../genderscore/pillars/GenderScorePillarLogoLabel';
import { arrayWrap } from '../../../utils/common';

const CompanyActionPillarTag = ({
	action,
	pillar,
	pillarCategory,
	...props
}) => {
	const className = 'CompanyActionPillarTag';

	const categories = arrayWrap(action?.pillarCategory || pillarCategory);

	if (categories?.length > 1) {
		return (
			<>
				{categories
					.map((category, index) => (
						<div key={index}>
							<CompanyActionPillarTag
								pillarCategory={category}
							/>
						</div>
					))
				}
			</>
		);
	}

	const category = categories?.[0];
	const categoryOption = findActionPillarCategory(category);
	const pillarOption = findActionPillar(categoryOption?.pillar || action?.pillar || pillar);

	if (!category) return null;

	if (!categoryOption) return null;

	if (!pillarOption) return null;

	// return categoryOption?.label || 'no category';
	// return <ReactJsonDebug src={{
	// 	categoryOption,
	// }}/>;
	// return <ReactJsonDebug src={{
	// 	categories, category,
	// }}/>;
	// return JSON.parse({ categories, 'action?.pillarCategory': action?.pillarCategory });
	return (
		<Tooltip title={categoryOption?.label || pillarOption?.label}>
			<Space
				size={6}
				style={{
					justifyContent: 'flex-start',
					alignItems: 'center',
				}}
			>
				<GenderScorePillarLogoLabel
					pillar={pillarOption?.value}
					withLabel={false}
				/>
				<div
					style={{
						fontSize: 12,
						color: pillarOption?.colors?.main || '#666',
						marginTop: -4,
					}}
				>
					{categoryOption?.tagLabel || pillarOption?.tagLabel || pillarOption?.label}
				</div>
			</Space>
		</Tooltip>
	);
};

export default CompanyActionPillarTag;
