import { Space } from 'antd';
import { useTheme } from '@emotion/react';
import { useAuthContext } from '../../../../hooks/useAuthContext';
import UserModel from '../../../../models/UserModel';
import UserName from '../../widgets/UserName';
import UserResumeTag from '../../stub/UserResumeTag';
import UserStubProfessional from '../../stub/UserStubProfessional';
import UserStubLocation from '../../stub/UserStubLocation';
import JsonAdminPopoverLink from '../../../app/debug/JsonAdminPopoverLink';

const UserGridCardName = (
	{
		user,
		withResumeTag = true,
		withJsonPopover = true,
		withUserProfessional = true,
		withUserLocation = true,
		...props
	},
) => {
	const className = 'UserGridCardName';

	const debugUser = (item) => {
		const {
			resume, coaching, jobSearch, career, ...rest
		} = item || {};
		return {
			resume, coaching, jobSearch, career, rest,
		};
	};

	return (
		<>
			<Space
				direction='vertical'
				size={6}
				style={{
					marginBottom: 30,
					width: '100%',
				}}
			>
				<div
					style={{
						fontSize: 24,
						fontWeight: 'bold',
					}}
				>
					<UserName
						user={user}
						height={32}
					/>

					{withResumeTag && (
						<UserResumeTag
							user={user}
							style={{
								marginLeft: 2,
							}}
						/>
					)}

					{withJsonPopover && (
						<JsonAdminPopoverLink
							data={debugUser(user)}
							style={{
								fontSize: 16,
								marginLeft: 4,
								verticalAlign: 'middle',
							}}
						/>
					)}
				</div>

				{withUserProfessional && (
					<span>
						<UserStubProfessional
							user={user}
							style={{
								display: 'inline',
								fontSize: 14,
							}}
						/>
					</span>
				)}

				{withUserLocation && (
					<UserStubLocation
						user={user}
						style={{
							display: 'inline',
							fontSize: 14,
						}}
					/>
				)}

			</Space>
		</>
	);
};

export default UserGridCardName;
