import {
	Divider, Space,
} from 'antd';
import { useTheme } from '@emotion/react';
import { isFunction } from 'lodash';
import { useEffect, useMemo } from 'react';
import { scrollToTop } from '../../../utils/common';
import { useLocation } from 'react-router';
import { useSelector } from 'react-redux';
import { getCompanySelected } from '../../../reducers/app';
import CompanyGridPicker from '../../company/CompanyGridPicker';
import { useAuthContext } from '../../../hooks/useAuthContext';

export const PageLayoutHeader = ({
	icon,
	title,
	subtitle,
}) => (
	<Space
		direction='vertical'
		style={{
			width: '100%',
			color: 'rgba(0,0,0,.88)',
			fontFamily: 'DM Sans',
			lineHeight: 1.3,
			marginTop: -8,
		}}
	>
		<h1
			style={{
			}}
		>
			<span
				style={{
					fontWeight: 400,
					fontSize: 28,
					fontFamily: 'DM Sans',
				}}
			>
				<span
					style={{
						fontSize: 26,
						marginRight: 10,
					}}
				>
					{icon}
				</span>
				{title}
			</span>
		</h1>

		{subtitle && (
			<div
				style={{
					fontSize: 14,
					fontWeight: 400,
					color: 'rgba(0,0,0,.8)',
				}}
			>
				{subtitle}
			</div>
		)}

	</Space>
);

const PageLayout = (
	{
		MenuClass,
		icon,
		title,
		subtitle,
		rightActions,
		withHeader = true,
		withHeaderDivider = true,
		withBackground = false,
		withPadding = true,
		filter,
		children,
		needsCurrentUser = false,
		needsCompanySelection = false,
		...props
	},
) => {
	const theme = useTheme();

	const location = useLocation();
	const { currentUser } = useAuthContext();
	const companySelected = useSelector(getCompanySelected);

	useEffect(() => {
		scrollToTop();
	}, [location.pathname]);

	const { displayIcon, displayTitle } = useMemo(() => {
		let computedTitle = title || MenuClass?.props?.label;

		if (isFunction(computedTitle)) {
			computedTitle = computedTitle?.();
		}
		if (isFunction(computedTitle)) {
			computedTitle = computedTitle?.();
		}

		return {
			displayIcon: icon || MenuClass?.props?.icon,
			displayTitle: computedTitle,
		};
	}, [icon, title, MenuClass?.props?.icon, MenuClass?.props?.label]);

	if (needsCompanySelection && !companySelected) return <CompanyGridPicker />;
	if (needsCurrentUser && !currentUser) return null;

	return (
		<div
			style={{
				background: theme.color.bgPage,
				minHeight: '100vh',
				...withPadding ? { padding: 24 } : {},
				...props?.style,
			}}
		>
			{withHeader && (
				<>
					<Space
						style={{
							width: '100%',
							justifyContent: 'space-between',
							alignItems: 'flex-start',
						}}
					>
						<PageLayoutHeader
							icon={displayIcon}
							title={displayTitle}
							subtitle={subtitle}
						/>

						{rightActions}

					</Space>

					{withHeaderDivider && (
						<Divider style={{ marginTop: 18 }} />
					)}
				</>
			)}

			{filter}

			<div
				className="container"
				style={{
					overflow: 'visible',
				}}
			>
				{children}
			</div>
		</div>
	);
};

export default PageLayout;
