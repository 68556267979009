import {
	Checkbox, Col, Divider, Form, Input, message, Row, Select, Space, Spin, Tag, Tooltip,
} from 'antd';
import {
	useContext, useEffect, useReducer, useState,
} from 'react';
import FormBoxTitle from '../app/form/FormBoxTitle';
import FormSubmitButton from '../app/form/FormSubmitButton';
import { useAuthContext } from '../../hooks/useAuthContext';
import {
	JOB_TAG_JOB_LEVEL,
	JOB_TAG_JOB_REMOTE,
	JOB_TAG_JOB_ROLE,
	JOB_TAG_LOCATION_CITY,
	JOB_TAG_LOCATION_COUNTRY,
	USER_TAG_CAREER_EXPERIENCE,
	USER_TAG_CAREER_HARD_SKILL,
	USER_TAG_CAREER_LANGUAGE,
	USER_TAG_CATEGORIES_FILTERS_SEARCH_RECRUITER,
	USER_TAG_JOB_SEARCH_EMPLOYMENT_VISA_FILTER,
	USER_TAG_JOB_SEARCH_JOB_LOCATION,
	USER_TAG_JOB_SEARCH_JOB_ROLE,
	USER_TAG_JOB_SEARCH_JOB_ROLE_GROUP,
	USER_TAG_LOCATION_COUNTRY,
} from '../../constants/constant';
import {
	documentId, requiredTrimRule, sortOn,
} from '../../utils/common';
import { useDispatch, useSelector } from 'react-redux';
import { getCompanySelected, getTagsUsers } from '../../reducers/app';
import { useTheme } from '@emotion/react';
import { LocalDebug } from '../../utils/LocalDebug';
import FormItem from '../company/form/FormItem';
import JobService from '../../services/jobs';
import RecruiterService from '../../services/recruiter';
import JobSelect from '../jobs/JobSelect';
import EXPERIENCE_LEVEL from '../../constants/property/experience-level';
import CAREER_EXPERIENCE from '../../constants/property/career-experience';
import { getPrettifiedLanguageLabel } from '../../constants/languages';
import JobStub from '../jobs/JobStub';
import Box from '../app/box/Box';
import { UserSavedSearchContext } from './UserSavedSearchProvider';
import UserService from '../../services/user';
import UserStub from '../user/stub/UserStub';
import { useDebouncedEffect } from '../../hooks/useDebounceEffect';
import { NotificationContext } from '../app/notification/NotificationProvider';
import { FaInfoCircle, FaTimes } from 'react-icons/fa';
import Logo50inTech from '../app/Logo50inTech';
import FormSelect from '../app/form/FormSelect';
import LocationFilter from '../app/filters/LocationFilter';
import VisaFilter from '../app/filters/VisaFilter';
import { getAllTagUsers } from '../../actions/users';
import { StaffToolsContext } from '../../contexts/StaffToolsProvider';
import { AppDataContext } from '../../contexts/AppDataProvider';

export const CLONE_SUFFIX = ' (copy)';
export const TITLE_MAX_LENGTH = 32;

export const cloneSavedSearchData = (savedSearch) => {
	const {
		title, id, _id, ...rest
	} = savedSearch;
	return {
		title: title.slice(0, TITLE_MAX_LENGTH - CLONE_SUFFIX.length) + CLONE_SUFFIX,
		...rest,
	};
};

const UserSavedSearchForm = (
	{
		initialValues = {},
		defaultTitle,
		title,
		onSuccess,
		labelCol = { span: 7 },
		wrapperCol = { span: 17 },
		parentFormItem = ['filters', 'tags'],
		...props
	},
) => {
	const className = 'UserSavedSearchForm';
	const theme = useTheme();
	const dispatch = useDispatch();
	const { savedSearchWithJobEnabled } = useContext(StaffToolsContext);
	const {
		addSelectedSavedSearchQueryParams,
		updateSavedSearches,
	} = useContext(UserSavedSearchContext);
	const { notifySuccess, notifyLoading } = useContext(NotificationContext);
	const { dataFetcherIndicatorTotalLimit } = useContext(AppDataContext);

	const tags = useSelector(getTagsUsers);
	const companySelected = useSelector(getCompanySelected);

	const [form] = Form.useForm();
	const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);

	const [isFormChanged, setIsFormChanged] = useState(false);
	const [job, setJob] = useState(null);
	const [previewLoading, setPreviewLoading] = useState(false);
	const [previewResults, setPreviewResults] = useState([]);
	const [previewCount, setPreviewCount] = useState(null);
	const [isCoachedChecked, setIsCoachedChecked] = useState(initialValues?.filters?.isCoached);
	const [refetch, setRefetch] = useState(0);
	const [forceFetch, setForceFetch] = useState(false);
	const [toggleLocationSelector, setToggleLocationSelector] = useState(false);

	const hasFormErrors = () => form.getFieldsError().some(({ errors }) => errors.length);

	const clearPreview = () => {
		setPreviewCount(0);
		setPreviewResults([]);
	};

	const fetchPreview = async () => {
		LocalDebug.logInfo({ className, method: 'fetchPreview' }, { previewCount });
		setPreviewLoading(true);
		clearPreview();
		try {
			const options = {
				...form.getFieldsValue()?.filters,
				limit: 5,
				fromSavedSearch: true,
				isPreview: true,
			};
			const { data: { count, items } } = await UserService.searchTalents(options);
			LocalDebug.logInfo({ className, method: 'fetchPreview' }, { options, count, items });
			setPreviewCount(count);
			setPreviewResults(items);
		} catch (e) {
		} finally {
			setPreviewLoading(false);
		}
	};

	useDebouncedEffect(async () => {
		fetchPreview();
	}, [refetch], 800);

	/**
	 * Enables/Disables the Submit button
	 * If required, fetches the preview
	 */
	const [state, stateDispatch] = useReducer(async (state, action) => {
		const canRefetch = forceFetch || !hasFormErrors();
		const disabled = !isFormChanged || hasFormErrors();
		switch (action) {
			case 'forceDisabled':
				return setIsSubmitDisabled?.(true);
			case 'updateDisabled':
				return setIsSubmitDisabled?.(disabled);
			case 'refetch':
				if (canRefetch) {
					setRefetch((p) => p + 1);
					setForceFetch(false);
				}
				break;
			default:
		}
	}, null);

	const hasActiveFilters = ({ withTitle } = { withTitle: false }) => {
		const filters = form.getFieldsValue()?.filters;
		const anyActiveFilter = !!filters?.isCoached
		|| Object.keys(filters?.tags).reduce(
			(acc, tag) => filters.tags?.[tag]?.length > 0 || acc,
			false,
		);
		return withTitle
			? !!form.getFieldsValue()?.title || anyActiveFilter
			: anyActiveFilter;
	};

	const cleanFields = (fields) => {
		if (!fields) {
			return {};
		}
		if (!fields.tags) {
			return fields;
		}
		return {
			...fields,
			tags: Object.keys(fields.tags)?.sort().reduce((filter, key) => {
				const value = fields.tags[key];
				if (value?.length > 0) {
					return {
						...filter,
						[key]: value,
					};
				}
				return filter;
			}, {}),
		};
	};
	const checkFormChanged = () => {
		// This is a manual equivalent to set form.isTouched
		let formChanged = false;
		if (hasActiveFilters({ withTitle: true })) {
			const fieldsToCompare = ['filters', 'title'];
			formChanged = fieldsToCompare.reduce((acc, field) => {
				let cleanedFilter = form.getFieldsValue()?.[field];
				if (cleanedFilter?.tags) {
					cleanedFilter = cleanFields(cleanedFilter);
				}
				if (!initialValues?.[field]?.isCoached
					&& cleanedFilter?.isCoached === false) {
					const { isCoached, ...rest } = cleanedFilter;
					cleanedFilter = { ...rest };
				}

				if (JSON.stringify(cleanedFilter)
					!== JSON.stringify(cleanFields(initialValues?.[field]))) {
					return true;
				}
				return acc;
			}, false);
			// If it's a new search and the only change is the title,
			// prevent the search to be saved
			if (formChanged && Object.keys(initialValues).length === 0) {
				if (!hasActiveFilters({ withTitle: false })) {
					formChanged = false;
				}
			}
		}
		setIsFormChanged(formChanged);
	};

	// Refetch preview and enable/disable save-btn when filters are modified
	const onFormValuesChange = (item) => {
		if (item?.title || item?.filters) {
			checkFormChanged();
			if (item?.filters) {
				// Do not refetch when the title changes
				stateDispatch('refetch');
			}
			stateDispatch(
				hasActiveFilters({ withTitle: false })
					? 'updateDisabled'
					: 'forceDisabled',
			);
		}
	};

	useEffect(() => {
		const apply = async () => {
			if (savedSearchWithJobEnabled) {
				if (initialValues?.jobId) {
					const { data: { job } } = await JobService.getJob(initialValues?.jobId);
					setJob(job);
				}
			}
			// Enable fetch
			setForceFetch(true);
		};
		apply();
	}, [initialValues, savedSearchWithJobEnabled]);

	useEffect(() => {
		if (!tags || tags.length === 0) {
			dispatch(getAllTagUsers());
		}
	}, [tags, dispatch]);

	//
	const onFormFinish = async (values) => {
		LocalDebug.logInfo({ className, method: 'onFormFinish' }, { values });
		stateDispatch('forceDisabled');
		const data = {
			id: documentId(initialValues),
			...values,
		};

		notifyLoading({ key: className, description: 'Saving...' });

		const { data: { result } } = await RecruiterService.createOrUpdateSavedSearch(data);

		notifySuccess({
			key: className,
			duration: 2,
			description: <span>Your search has been {documentId(data) ? 'edited' : 'saved'}</span>,
		});

		// writeLog(`Company settings saved (${formName})`, { data: values });
		updateSavedSearches();
		addSelectedSavedSearchQueryParams(result);

		onSuccess?.(result);
	};

	const onFormFinishFailed = ({ values, errorFields, outOfDate }) => {
		message.error(<div>
			Error: {(errorFields?.map((e) => e.errors) || []).slice(0, 1).join(',')}
		</div>);
	};

	// When the selected job changes, reload the rest of the filters
	const handleJobChanged = async (value) => {
		const method = 'handleJobChanged';
		LocalDebug.logInfo({ className, method }, { value });
		if (!value) return;
		try {
			const { data: { job } } = await JobService.getJob(value);

			const jobLevelsToUserLevels = {
				[EXPERIENCE_LEVEL.EXPERIENCE_LEVEL_ANSWER_ENTRY]:
					CAREER_EXPERIENCE.CAREER_EXPERIENCE_ANSWER_LESS1,
				[EXPERIENCE_LEVEL.EXPERIENCE_LEVEL_ANSWER_JUNIOR]:
					CAREER_EXPERIENCE.CAREER_EXPERIENCE_ANSWER_1TO2,
				[EXPERIENCE_LEVEL.EXPERIENCE_LEVEL_ANSWER_MID]:
					CAREER_EXPERIENCE.CAREER_EXPERIENCE_ANSWER_3TO4,
				[EXPERIENCE_LEVEL.EXPERIENCE_LEVEL_ANSWER_SENIOR]:
					CAREER_EXPERIENCE.CAREER_EXPERIENCE_ANSWER_5TO8,
				[EXPERIENCE_LEVEL.EXPERIENCE_LEVEL_ANSWER_LEADERSHIP]:
					CAREER_EXPERIENCE.CAREER_EXPERIENCE_ANSWER_9PLUS,
			};

			const jobLevels = job?.tagTree?.[JOB_TAG_JOB_LEVEL.value]?.map((s) => s.replace(`${JOB_TAG_JOB_LEVEL.value}:`, '')).map((s) => jobLevelsToUserLevels[s]);
			const jobRemotes = job?.tagTree?.[JOB_TAG_JOB_REMOTE.value]?.map((s) => s.replace(`${JOB_TAG_JOB_REMOTE.value}:`, ''));
			const jobRoles = job?.tagTree?.[JOB_TAG_JOB_ROLE.value]?.map((s) => s.replace(`${JOB_TAG_JOB_ROLE.value}:`, ''));
			const countries = job?.tagTree?.[JOB_TAG_LOCATION_COUNTRY.value]?.map((s) => s.replace(`${JOB_TAG_LOCATION_COUNTRY.value}:`, ''));
			const cities = job?.tagTree?.[JOB_TAG_LOCATION_CITY.value]?.map((s) => s.replace(`${JOB_TAG_LOCATION_CITY.value}:`, ''));

			const jobRoleValues = getTagsOptions(tags, USER_TAG_JOB_SEARCH_JOB_ROLE.value)
				.filter((o) => jobRoles.includes(o.value))
				.map((o) => o.value);

			const jobLevelValues = getTagsOptions(tags, USER_TAG_CAREER_EXPERIENCE.value)
				.filter((o) => jobLevels.includes(o.value))
				.map((o) => o.value);

			const jobCountryValues = getTagsOptions(tags, USER_TAG_LOCATION_COUNTRY.value)
				.filter((o) => countries.includes(o.value))
				.map((o) => o.value);

			LocalDebug.logInfo({ className, method }, {
				jobRoleValues,
				jobLevelValues,
				jobCountryValues,
				levels: getTagsOptions(tags, USER_TAG_CAREER_EXPERIENCE.value),
				values: form.getFieldsValue(),
			});

			const ellipsis = '(...)';
			const titleLimit = TITLE_MAX_LENGTH - ellipsis.length;
			form.setFieldsValue({
				title: [
					job?.title?.substring(0, titleLimit),
					job?.title?.length > titleLimit ? [ellipsis] : [],
				].join(''),
				filters: {
					tags: {
						[USER_TAG_JOB_SEARCH_JOB_ROLE.value]: jobRoleValues,
						[USER_TAG_CAREER_EXPERIENCE.value]: jobLevelValues,
						[USER_TAG_LOCATION_COUNTRY.value]: jobCountryValues,
					},
				},
			});
			stateDispatch('updateDisabled');
			stateDispatch('refetch');
		} catch (e) {
			LocalDebug.logError({ className, method, error: e });
		}
	};

	/**
	 * This function should not be required as we use the method onValuesChange of the Form
	 * But some fields are updated manually (form.setFieldValue), and this event does not trigger the method
	 * More details: https://ant.design/components/form#setfieldsvalue-do-not-trigger-onfieldschange-or-onvalueschange
	 * Thus, we use this function to handle manually the changes for those form items
	 */
	// https://ant.design/components/form#setfieldsvalue-do-not-trigger-onfieldschange-or-onvalueschange
	const handleFormItemChange = () => {
		onFormValuesChange({ filters: [] });
		stateDispatch('updateDisabled');
	};
	const closeLocationFilter = () => {
		setToggleLocationSelector(false);
	};

	const handleOpenLocationFilter = () => {
		stateDispatch('forceDisabled');
		setTimeout(() => {
			// Scroll to bottom the form content
			const formContent = document.getElementById('form-content');
			if (formContent) {
				formContent.scrollTop = formContent.scrollHeight;
			}
		}, 150);
	};

	const getTagsOptions = (category, noneValue = '-1', sortOnKey = null) => {
		let options = [];
		const categoryTags = (tags.find((tag) => tag.category === category));
		if (categoryTags) {
			options = (categoryTags?.items || []);
			if (sortOnKey) options.sort(sortOn({ key: sortOnKey }));
			options = [
				...noneValue ? [{
					value: noneValue, label: <span style={{ fontStyle: 'italic' }}>Not provided</span>,
				}] : [],
				...options,
			];
		}
		return options;
	};

	const tagSelectOption = ({ label, value }, index) => {
		return <Select.Option key={value + index} value={value} label={label}>
			{label}
		</Select.Option>;
	};

	const tagSelect = (category) => {
		const [noneOption, ...options] = getTagsOptions(category.value) || [];
		// LocalDebug.logInfo({ className, method: 'tagSelect' }, category, options);
		const groupedTags = {};

		if ([
			USER_TAG_CAREER_HARD_SKILL.value,
			USER_TAG_LOCATION_COUNTRY.value,
		].includes(category.value)) {
			options.sort(sortOn({ key: 'label', string: true }));
		}
		options.forEach(({ group, ...rest }, index, arr) => {
			if (!group || [USER_TAG_CAREER_HARD_SKILL.value].includes(category.value)) return;
			groupedTags[group] = [
				...groupedTags[group] || [],
				rest,
			];
		});

		let content;
		if (category.value === USER_TAG_CAREER_LANGUAGE.value) {
			content = options.map(({ label, value }, index) => {
				return <Select.Option key={value + index} value={value} label={label}>
					{getPrettifiedLanguageLabel(value) || label}
				</Select.Option>;
			});
		} else {
			content = <>
				{Object.keys(groupedTags).length > 0
					? Object.entries(groupedTags).map(([group, tags], index) => <Select.OptGroup
						key={index}
						label={getTagsOptions(USER_TAG_JOB_SEARCH_JOB_ROLE_GROUP.value)
							.find((o) => o.value === group)?.label}
					>
						{tags.map(tagSelectOption)}
					</Select.OptGroup>)
					: options.map(tagSelectOption)
				}
			</>;
		}

		return (
			<FormSelect
				options={options}
				mode="multiple"
				blockScroll={true}
				listHeight={200}
				tagRender={(props) => {
					const {
						label, value, closable, onClose,
					} = props || {};
					const onPreventMouseDown = (event) => {
						event.preventDefault();
						event.stopPropagation();
					};
					return (
						<span style={{
							color: theme.color.fitBlueElectric,
						}}>
							<Tag
								color={value}
								onMouseDown={onPreventMouseDown}
								closable={false}
								style={{
									padding: '2px 8px',
									border: `1px solid ${theme.color.fitBlueElectric}`,
									borderRadius: 4,
									background: 'white',
									color: theme.color.fitBlueElectric,
									fontSize: 13,
									margin: '1px 2px 1px 0',
								}}
							>
								{label}
								&nbsp;
								<FaTimes
									onClick={onClose}
									style={{
										marginBottom: -2,
										color: theme.color.fitBlueElectric,
										cursor: 'pointer',
									}}
								/>
							</Tag>
						</span>
					);
				}}
			>
				{content}
			</FormSelect>
		);
	};

	const tagFormItem = (category, key) => (
		<Col
			span={24}
			key={key}
			onClick={closeLocationFilter}>
			<FormItem
				key={category.value}
				labelCol={labelCol}
				label={category.label}
				labelAlign='right'
				name={[...parentFormItem || [], category?.value]}

			>
				{tagSelect(category)}
			</FormItem>
		</Col>
	);

	return (
		<>
			<FormBoxTitle
				title={title || defaultTitle}
			/>

			<Form
				form={form}
				formName='user-saved-search-form'
				initialValues={{ ...initialValues }}
				labelCol={labelCol}
				wrapperCol={wrapperCol}
				labelAlign='right'
				onFinish={onFormFinish}
				onFinishFailed={onFormFinishFailed}
				onValuesChange={onFormValuesChange}
			>
				<Row span={24} gutter={[60, 0]}>
					{/* FILTERS */}
					<Col
						span={16}
					>

						{savedSearchWithJobEnabled && (
							!documentId(initialValues)
								? (
									<Row>
										<Col span={24}>
											<FormItem
												label='Job'
												name='jobId'
											>
												<JobSelect
													placeholder='Select a job'
													companyIds={documentId(companySelected) ? [documentId(companySelected)] : []}
													onChange={(value) => handleJobChanged(value)}
												/>
											</FormItem>
										</Col>
									</Row>
								)
								: (
									job && <Row>
										<Col span={24}>
											<FormItem
												label='Job linked'
											>
												<Box style={{ width: '100%' }}>
													<JobStub job={job} />
												</Box>
											</FormItem>
										</Col>
									</Row>
								)
						)}
						<div
							id="form-content"
							className='saved-search-form'
							style={{
								maxHeight: 560,
								overflowY: 'auto',
								paddingRight: 5,
							}}
						>

							<Row>
								<Col span={24}>
									<FormItem
										name='title'
										label='Name your search'
										rules={[
											{
												max: TITLE_MAX_LENGTH,
												message: `This field is limited to ${TITLE_MAX_LENGTH} characters`,
											},
											{
												...requiredTrimRule,
												message: 'Name is required',
											},
										]}
									>
										<Input
											maxLength={TITLE_MAX_LENGTH}
											placeholder='Name your search'
										/>
									</FormItem>
								</Col>
							</Row>

							<Row>
								{USER_TAG_CATEGORIES_FILTERS_SEARCH_RECRUITER.map(tagFormItem)}
							</Row>

							<LocationFilter
								form={form}
								parent={parentFormItem}
								labelColSpan={labelCol?.span}
								initialValues={initialValues?.filters?.tags
									?.[USER_TAG_JOB_SEARCH_JOB_LOCATION?.value]}
								withDesiredLocation={true}
								toggleLocationSelector={toggleLocationSelector}
								setToggleLocationSelector={setToggleLocationSelector}
								onChange={handleFormItemChange}
								onOpen={handleOpenLocationFilter}
								onClose={handleFormItemChange}
							/>

							<VisaFilter
								form={form}
								parent={parentFormItem}
								labelColSpan={labelCol?.span}
								initialValues={initialValues?.filters?.tags
									?.[USER_TAG_JOB_SEARCH_EMPLOYMENT_VISA_FILTER?.value]}
								styles={{ marginTop: 25 }}
								onChange={() => { closeLocationFilter(); handleFormItemChange(); }}
							/>
							<Row style={{ marginTop: 20 }}>
								<Col span={24}>
									<FormItem
										label='Pre-screening'
										name={['filters', 'isCoached']}
										valuePropName='checked'
									>
										<Checkbox
											size={'large'}
											style={{ marginLeft: 5, marginTop: 4 }}
											onChange={(e) => { setIsCoachedChecked(e?.target?.checked); }}
										>
											<span
												style={{
													userSelect: 'none',
													opacity: isCoachedChecked ? 1 : 0.5,
												}}
											>
											Only show profiles pre-screened by&nbsp;
												<Logo50inTech size={24} style={{ marginBottom: -6 }} />
											&nbsp;
												<Tooltip title={'Every week, 50inTech\'s Talent Team calls the most relevant profiles. By selecting this filter you\'ll see talents pre-screened in the last 6 months.'}>
													<FaInfoCircle style={{ marginBottom: -2 }} />
												</Tooltip>
											</span>
										</Checkbox>

									</FormItem>
								</Col>
							</Row>
						</div>
						<Divider
							style={{ marginTop: 10 }}
						/>
						<FormItem
							label='&nbsp;'
							labelCol={{ span: 4 }}
							style={{ marginBottom: 10 }}
						>
							<FormSubmitButton
								disabled={isSubmitDisabled}
								size={'large'}
								style={{
									width: '100%',
								}}
							>
								{documentId(initialValues) ? 'Edit' : 'Create'}
							</FormSubmitButton>
						</FormItem>

					</Col>

					{/*
					PREVIEW
				*/}
					<Col
						span={8}
						style={{
							margin: '-20px -30px -24px -0px',
							borderLeft: '1px solid #eee',
							background: theme.color.bgGrey,
						}}
					>

						<div
							style={{
								paddingTop: 20,
								height: '100%',
								maxHeight: 420,
								overflow: 'scroll',
								opacity: isSubmitDisabled ? 0.5 : 1,
							}}
						>
							<Space align='middle'
								style={{ justifyContent: 'space-between', width: '100%', marginBottom: 12 }}>
								<h4>Search preview</h4>
								<span style={{ fontSize: 12, color: theme.color.grey }}>{
									!previewLoading && !!previewCount && (
										<>
											{previewCount >= dataFetcherIndicatorTotalLimit
												? `${dataFetcherIndicatorTotalLimit}+`
												: previewCount} result{previewCount !== 1 ? 's' : ''}
										</>
									)
								}</span>
							</Space>

							{!previewLoading
								? (
									<Space direction='vertical'>
										{previewResults?.map((user, index, arr) => (
											<UserStub key={index} user={user}
												style={{ opacity: 1 - index / arr.length }} />
										))}
									</Space>
								)
								: <Space
									direction='vertical'
									align='center'
									style={{
										width: '100%',
									}}
								>
									<Spin size='large'/>
								</Space>

							}
						</div>

					</Col>
				</Row>
			</Form>
		</>
	);
};

export default UserSavedSearchForm;
