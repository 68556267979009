import feedbacks from './product/feedbacks';
import productJobs from './product/product-jobs';
import productUsers from './product/product-users';
import { PERMS_GRANT_STAFF } from '../menu.utils';
import MenuBase from '../MenuBase';
import { BulbOutlined } from '@ant-design/icons';

class StaffProductMenu extends MenuBase {
	static props = {
		name: 'staff-product-menu',
		label: 'Product',
		icon: <BulbOutlined />,
		iconLegacy: <span>🧙‍♀️</span>,
		children: () => [
			productUsers,
			productJobs,
			feedbacks,
		],
		perms: PERMS_GRANT_STAFF,
	};

	constructor() {
		super(StaffProductMenu.props);
	}
}

export default StaffProductMenu;
