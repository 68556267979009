import { useTheme } from '@emotion/react';
import { useDispatch, useSelector } from 'react-redux';
import { getCompanySelected } from '../../../reducers/app';
import PageLayout from '../../../components/app/layout/PageLayout';
import SurveyScoringDetails from '../../../components/genderscore/survey/SurveyScoringDetails';
import SurveySelect from '../../../components/survey/SurveySelect';
import { LocalDebug } from '../../../utils/LocalDebug';
import {
	documentId, sortOn,
} from '../../../utils/common';
import {
	useContext, useMemo, useState,
} from 'react';
import SurveyStatusSelect from '../../../components/genderscore/survey/SurveyStatusSelect';
import { Space } from 'antd';
import StaffGSSurveyDetailsMenu from '../../../menu/staff/genderscore/StaffGSSurveyDetailsMenu';
import StaffCompaniesCompanyListMenu from '../../../menu/staff/companies/StaffCompaniesCompanyListMenu';
import StaffGSSurveyListMenu from '../../../menu/staff/genderscore/StaffGSSurveyListMenu';
import SURVEYS from '../../../components/genderscore/data/surveys';
import SurveyConfSelect from '../../../components/survey/SurveyConfSelect';
import { QueryParamsContext } from '../../../contexts/QueryParamsProvider';
import CompanyService from '../../../services/company';
import AdminCommandGetLink from '../../../components/staff/tools/AdminCommandGetLink';
import { loadCompany } from '../../../actions/company';

const StaffGenderScoreSurveyDetailsPage = () => {
	const className = 'StaffGenderScoreSurveyDetailsPage';
	const theme = useTheme();
	const dispatch = useDispatch();

	const {
		queryParams, setQueryParam,
	} = useContext(QueryParamsContext);

	const companySelected = useSelector(getCompanySelected);
	const status = queryParams?.status ? queryParams?.status?.split?.(',') : null;
	const surveyId = queryParams?.surveyId;
	const [survey, setSurvey] = useState();
	const surveyConf = SURVEYS?.getSurveyDataOption?.(queryParams?.surveyConf);

	useMemo(() => {
		console.log('useMemo', { surveyId });
		const findSurvey = async () => {
			if (!surveyId) setSurvey(null);
			setSurvey((await CompanyService.getGenderScoreSurvey(surveyId))?.data?.survey);
		};
		findSurvey();
	}, [surveyId]);

	LocalDebug.logInfo({ className }, { status, survey });

	return (
		<PageLayout
			MenuClass={StaffGSSurveyDetailsMenu}
			rightActions={(
				<Space>
					{(new StaffCompaniesCompanyListMenu()).getInlineNavLink()}
					{(new StaffGSSurveyListMenu()).getInlineNavLink()}
					{survey?.status === 'CURRENT' && (
						<AdminCommandGetLink
							title={<b>Remap answers to V3</b>}
							path='/admin/company/remap-genderscore-survey-answers-v3'
							params={{ companyId: documentId(companySelected) }}
							callback={() => dispatch(loadCompany(documentId(companySelected)))}
						/>
					)}
				</Space>
			)}
		>
			<Space
				style={{
					marginBottom: 12,
					width: '100%',
				}}
			>

				<Space direction='vertical' size={4}>
					<b>Status</b>
					<SurveyStatusSelect
						value={status}
						onChange={(value) => {
							setQueryParam({ name: 'status', value });
						}}
					/>
				</Space>

				<Space direction='vertical' size={4}>
					<b>Survey Conf</b>
					<SurveyConfSelect
						value={surveyConf?.value}
						onChange={async (value) => {
							LocalDebug.logInfo({ className, method: 'onChange' }, { value });
							setQueryParam({ name: 'surveyConf', value });
						}}
					/>
				</Space>

				<Space direction='vertical' size={4}>
					<b>Survey</b>
					<SurveySelect
						value={documentId(survey)}
						searchOptions={{
							...companySelected ? { companyId: documentId(companySelected) } : {},
							...status ? { status } : {},
						}}
						onChange={async (value) => {
							LocalDebug.logInfo({ className, method: 'onChange' }, { value });
							setQueryParam({ name: 'surveyId', value: documentId(value) });
							// setSurvey(value);
						}}
						sorter={(items) => items
							?.sort?.(sortOn({ key: (s) => s?.startedAt }))
							?.sort?.(sortOn({ key: (s) => s?.companyInfo?.name }))
						}
						style={{
							width: 500,
						}}
					/>
				</Space>
			</Space>

			<SurveyScoringDetails
				survey={survey}
				surveyConf={surveyConf}
			/>

		</PageLayout>
	);
};

export default StaffGenderScoreSurveyDetailsPage;
