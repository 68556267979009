const slugify = require('@sindresorhus/slugify');
const { isStringTypeValidator } = require('../../utils/isStringTypeValidator');
const CITIES = require('../cities.json');

const LOCATION_CITY_KEY = 'location-city';

const LOCATION_CITY_FREE = true;

const LOCATION_CITY_VALIDATORS = [
	isStringTypeValidator,
];

const LOCATION_CITY_OPTIONS = CITIES;

export default {
	key: LOCATION_CITY_KEY,
	LOCATION_CITY_KEY,
	validators: LOCATION_CITY_VALIDATORS,
	LOCATION_CITY_VALIDATORS,
	options: LOCATION_CITY_OPTIONS,
	LOCATION_CITY_OPTIONS,
	free: LOCATION_CITY_FREE,
	LOCATION_CITY_FREE,
};
