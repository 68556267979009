import { Button, Dropdown, Menu } from 'antd';
import { useState } from 'react';
import { useTheme } from '@emotion/react';
import { FaUserEdit } from 'react-icons/fa';
import Link from '../../app/Link';
import { documentId, writeLog } from '../../../utils/common';
import { COACHING_STATUS } from '../../../constants/constant';
import UserCoachingStatusOption from './UserCoachingStatusOption';

const UserCoachingStatusDropdown = (
	{
		user = null,
		onSelect,
	},
) => {
	const theme = useTheme();

	const currentStatus = user?.coaching?.status;
	const currentStatusOption = COACHING_STATUS.COACHING_STATUS_OPTIONS.find((o) => o.value === currentStatus);
	const [userSelected, setUserSelected] = useState(null);
	const [isLoading, setIsLoading] = useState(false);

	const handleSubmit = (e) => {
		const status = e.key;
		writeLog('confirm user coaching status', { user: documentId(user), status });
		if (onSelect) onSelect(user, status, currentStatus);
	};

	const openDropdown = () => {
		writeLog('open edit user coaching status', { user: documentId(user) });
		setUserSelected(user);
	};

	const menu = (
		<Menu
			onClick={handleSubmit}
			// mode="horizontal"
			style={{ maxHeight: 300, overflow: 'auto' }}
		>
			{COACHING_STATUS.COACHING_STATUS_OPTIONS.map((option) => (
				<Menu.Item key={option.value}>
					<UserCoachingStatusOption option={option} />
				</Menu.Item>
			))}
		</Menu>
	);

	return (
		<Dropdown overlay={menu} trigger={['click']}>
			{currentStatusOption
				? <Button onClick={openDropdown} style={{ paddingLeft: 12, paddingRight: 12, fontSize: 12 }}>
					<UserCoachingStatusOption option={currentStatusOption} />
				</Button>
				: <Link onClick={openDropdown} type="link"><FaUserEdit style={{ marginBottom: -2 }} /> Edit status</Link>
			}
		</Dropdown>
	);
};

export default UserCoachingStatusDropdown;
