import { useTheme } from '@emotion/react';
import { List, Space } from 'antd';
import { useContext } from 'react';
import { UnbiasedScoreContext } from '../../../contexts/UnbiasedScoreProvider';
import { ArrowRightOutlined } from '@ant-design/icons';
import Link from '../../app/Link';
import { UMSInviteContext } from '../UMSInviteProvider';
import UMSUserStub from '../users/UMSUserStub';

const UMSStaffManagerSelector = ({
	...props
}) => {
	const className = 'UMSStaffManagerSelector';

	const theme = useTheme();

	const { setUMSResult } = useContext(UnbiasedScoreContext) || {};

	const { managerResults } = useContext(UMSInviteContext);

	return (
		<List
			itemLayout="horizontal"
			dataSource={managerResults}
			renderItem={(item, index) => (
				<List.Item
					key={index}
					style={{
						padding: '6px 12px',
						...props?.style?.listItem,
					}}
				>
					<Link
						onClick={() => setUMSResult(item)}
						style={{
							width: '100%',
						}}
					>
						<Space
							style={{
								justifyContent: 'space-between',
								alignItems: 'center',
								width: '100%',
							}}
						>
							<UMSUserStub user={item?.user} />
							<ArrowRightOutlined />
						</Space>
					</Link>
				</List.Item>
			)}
			style={{
				border: '1px solid #eee',
				borderRadius: 5,
				...props?.style,
			}}
		/>
	);
};

export default UMSStaffManagerSelector;
