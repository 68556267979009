import { round } from 'lodash';
import SurveyQuestionModel from './SurveyQuestionModel';

const buildNumberImporter = (decimals = 0) => (value) => round(Number(value), decimals);

export const INPUT_TEXT = 'text';
export const INPUT_PCT = 'pct';
export const INPUT_NUMBER = 'number';
export const INPUT_SINGLE = 'single';
export const INPUT_MULTIPLE = 'multiple';

const textInput = { input: INPUT_TEXT };
const pctInput = { input: INPUT_PCT };
const numberInput = { input: INPUT_NUMBER };
const singleInput = { input: INPUT_SINGLE };
const multipleInput = { input: INPUT_MULTIPLE };

const pctOptions = {
	...pctInput,
	min: -999999999,
	max: 999999999,
	suffix: '%',
};

const pct100Options = {
	...pctInput,
	suffix: '%',
	min: 0,
	max: 100,
	step: 1,
	import: buildNumberImporter(),
};

export const ANSWER_YES_LABEL = 'Yes';
export const ANSWER_YES_VALUE = 'yes';
export const ANSWER_YES_SCORE = 1;
export const ANSWER_YES_OPTION = {
	label: ANSWER_YES_LABEL,
	value: ANSWER_YES_VALUE,
	score: ANSWER_YES_SCORE,
};
export const ANSWER_INPROGRESS_LABEL = 'In progress';
export const ANSWER_INPROGRESS_VALUE = 'in-progress';
export const ANSWER_INPROGRESS_SCORE = 0.5;
export const ANSWER_INPROGRESS_OPTION = {
	label: ANSWER_INPROGRESS_LABEL,
	value: ANSWER_INPROGRESS_VALUE,
	score: ANSWER_INPROGRESS_SCORE,
};
export const ANSWER_NO_LABEL = 'No';
export const ANSWER_NO_VALUE = 'no';
export const ANSWER_NO_SCORE = 0;
export const ANSWER_NO_OPTION = {
	label: ANSWER_NO_LABEL,
	value: ANSWER_NO_VALUE,
	score: ANSWER_NO_SCORE,
};

const yesNoOptions = {
	...singleInput,
	align: 'horizontal',
	options: [
		ANSWER_YES_OPTION,
		ANSWER_NO_OPTION,
	],
};

const yesNoInProgressOptions = {
	...singleInput,
	align: 'horizontal',
	options: [
		ANSWER_YES_OPTION,
		ANSWER_INPROGRESS_OPTION,
		ANSWER_NO_OPTION,
	],
};

const yesNoGenderOptions = {
	...singleInput,
	align: 'vertical',
	options: [
		{
			label: 'Yes, and we distinguish between men and women',
			value: 'yes-gender-breakdown',
			score: 1,
		},
		{
			label: 'Yes, but we don\'t distinguish between men and women',
			value: 'yes-no-gender-breakdown',
			score: 0.3,
		},
		{
			label: 'No, we don\'t track it',
			value: 'no',
			score: 0,
		},
	],
};

const QUESTIONS = Object
	.entries({
		GENDERDATA_OVERALLPCT: {
			questionId: 'GENDERDATA_OVERALLPCT',
			label: 'What is your company\'s ratio of **women in the overall workforce?**',
			shortLabel: 'Women in the workforce',
			sublabel: 'Do not include **interns or apprentices** in your answer.',
			...pct100Options,
		},
		GENDERDATA_TECHPCT: {
			questionId: 'GENDERDATA_TECHPCT',
			label: 'What is your company\'s ratio of **women in Tech roles?**',
			shortLabel: 'Women in Tech',
			sublabel: `Do not include **interns or apprentices** in your answer.

**"Tech roles"** are roles such as Engineering, Data, Product, etc.`,
			skipLabel: 'If you\'re unable to fill',
			...pct100Options,
		},
		GENDERDATA_ENGPCT: {
			questionId: 'GENDERDATA_ENGPCT',
			label: 'What is your company\'s ratio of **women in Engineering roles?**',
			shortLabel: 'Women in Engineering',
			sublabel: 'Do not include **interns or apprentices** in your answer.',
			...pct100Options,
		},
		GENDERDATA_PRODUCTPCT: {
			questionId: 'GENDERDATA_PRODUCTPCT',
			label: 'What is your company\'s ratio of **women in Product roles?**',
			shortLabel: 'Women in Product',
			sublabel: 'Do not include **interns or apprentices** in your answer.',
			...pct100Options,
		},
		GENDERDATA_DATAPCT: {
			questionId: 'GENDERDATA_DATAPCT',
			label: 'What is your company\'s ratio of **women in Data roles?**',
			shortLabel: 'Women in Data',
			sublabel: 'Do not include **interns or apprentices** in your answer.',
			...pct100Options,
		},
		GENDERDATA_MGTPCT: {
			questionId: 'GENDERDATA_MGTPCT',
			label: 'What is your company\'s ratio of **women in managerial roles?**',
			shortLabel: 'Women in managerial roles',
			sublabel: 'Not limited to Tech roles, but in the **overall workforce.**',
			...pct100Options,
		},
		GENDERDATA_CLEVELPCT: {
			questionId: 'GENDERDATA_CLEVELPCT',
			label: 'What is your company\'s ratio of **women in C-Level positions**/Executive Committee?',
			shortLabel: 'Women in C-level positions',
			sublabel: `Not limited to Tech roles, but in the **overall workforce.**
		
**Executive Committee:** A subset/sub-committee of the board of directors that acts as a steering committee with the ability to make high-level strategic decisions, and act on the board\'s behalf.`,
			...pct100Options,
		},
		GENDERDATA_BOARDPCT: {
			questionId: 'GENDERDATA_BOARDPCT',
			label: 'What is your company\'s ratio of **women in the Board of Directors?**',
			shortLabel: 'Women in board',
			sublabel: '**Board of Directors:** the governing body of an organization that can set corporate management and oversight policies, and represent shareholders\' interests.',
			...pct100Options,
		},
		GENDERDATA_ISRATED: {
			questionId: 'GENDERDATA_ISRATED',
			label: 'Is your company currently rated on **Glassdoor or InHerSight?**',
			shortLabel: 'Presence on Glassdoor/InHerSight',
			...yesNoOptions,
		},
		GENDERDATA_RATING: {
			questionId: 'GENDERDATA_RATING',
			label: 'What is your company\'s rating on **Glassdoor or InHerSight?**',
			shortLabel: 'Glassdoor/InHerSight rating',
			// sublabel: 'If your company is not rated yet on one of those services, you can **skip** this question.',
			...numberInput,
			min: 0,
			max: 5,
			step: 0.1,
			import: buildNumberImporter(1),
		},
		GENDERDATA_GLASSDOOR_RATING: {
			questionId: 'GENDERDATA_GLASSDOOR_RATING',
			label: 'What is your company\'s rating on **Glassdoor?**',
			shortLabel: 'Glassdoor rating',
			...numberInput,
			min: 0,
			max: 5,
			step: 0.1,
			import: buildNumberImporter(1),
		},
		GENDERDATA_INHERSIGHT_RATING: {
			questionId: 'GENDERDATA_INHERSIGHT_RATING',
			label: 'What is your company\'s rating on **InHerSight?**',
			shortLabel: 'InHerSight rating',
			...numberInput,
			min: 0,
			max: 5,
			step: 0.1,
			import: buildNumberImporter(1),
		},
		GENDERDATA_ISEGAPRO: {
			questionId: 'GENDERDATA_ISEGAPRO',
			label: 'Have you measured your **Egapro Index** (in France)?',
			shortLabel: 'Egapro Index measurement',
			sublabel: `**Egapro Index:** a professional equality Index in France, designed to help companies
		measure their gender pay gap, and implement measures to promote equal pay.
		Applicable to companies with > 50 employees in France.

Answer **No** if you are eligible but haven't measured it.
		
Answer **Not relevant** if your company is not concerned by this Index.`,
			...singleInput,
			align: 'horizontal',
			options: [
				{
					label: 'Yes',
					value: 'yes',
					score: 1,
				},
				{
					label: 'No',
					value: 'no',
					score: 0,
				},
				{
					label: 'Not relevant',
					value: 'not-applicable',
					score: -1,
				},
			],
		},
		GENDERDATA_EGAPRO_SCORE: {
			questionId: 'GENDERDATA_EGAPRO_SCORE',
			label: 'What was your company\'s last year **Egapro Index score?**',
			shortLabel: 'Egapro Score',
			...numberInput,
			min: 0,
			max: 100,
			step: 1,
			import: buildNumberImporter(0),
		},
		GENDERDATA_ISTURNOVER: {
			questionId: 'GENDERDATA_ISTURNOVER',
			label: 'Do you keep track of the **turnover rate for women** in your company and implement ways to reduce it and improve their retention?',
			shortLabel: 'Turnover tracking',
			sublabel: `**Employee turnover rate:** the percentage of employees who leave an organization during a specified time period.

Not to be confused with **retention rate**: the percentage of employees that stay within organization during a specified time period.`,
			...yesNoOptions,
		},
		GENDERDATA_TURNOVERPCT: {
			questionId: 'GENDERDATA_TURNOVERPCT',
			label: 'What was your company\'s **turnover rate for women** in the last 3 years?',
			shortLabel: 'Turnover rate',
			sublink: {
				label: 'Here\'s a simple guide for the calculation.',
				url: 'https://drive.google.com/file/d/1Nuzyq66WrYPgLDMzUA6AE4lVMN2Lm5qP/view',
			},
			skipLabel: 'If your company is not yet established enough to calculate this turnover rate',
			...pctOptions,
		},
		GENDERDATA_TENUREOPTIONS: {
			questionId: 'GENDERDATA_TENUREOPTIONS',
			label: 'What is the **median tenure** of employees in your company?',
			shortLabel: 'Median tenure',
			sublabel: `**Employee Tenure:** the length of time an employee works for an employer. 
Some workers prefer to change environments periodically to avoid boredom, stress and unfavorable working conditions -&nbsp;Neil Kokemuller`,
			...singleInput,
			columns: 5,
			align: 'vertical',
			// width: '300px',
			options: [
				{
					label: '< 1 year',
					value: '<1y',
					score: 0,
				},
				{
					label: '< 2 years',
					value: '<2y',
					score: 0,
				},
				{
					label: '< 3 years',
					value: '<3y',
					score: 1,
				},
				{
					label: '< 4 years',
					value: '<4y',
					score: 2,
				},
				{
					label: '>= 4 years',
					value: '>4y',
					score: 3,
				},
			],
		},
		GENDERDATA_PROMOTEDPCT: {
			questionId: 'GENDERDATA_PROMOTEDPCT',
			label: 'What was the percentage of **women promoted** last year?',
			shortLabel: 'Women promotion',
			...pct100Options,
		},
		CAREERPATH_GRIDS: {
			questionId: 'CAREERPATH_GRIDS',
			label: 'Does your company use **Career Grids** to formalize Career Paths and give functional feedback and clear metrics for promotion and recognition?',
			shortLabel: 'Career Grids',
			...yesNoOptions,
		},
		CAREERPATH_SKILLSMATRIX: {
			questionId: 'CAREERPATH_SKILLSMATRIX',
			label: 'Has your company put in place a **competency matrix** to assess skills available and skills needed in the team?',
			shortLabel: 'Competency matrix',
			...yesNoOptions,
		},
		CAREERPATH_CAREERPATHING: {
			questionId: 'CAREERPATH_CAREERPATHING',
			label: 'Are managers or supervisors actively involved in **Career Pathing** with employees, aligning their career goals with opportunities within the organisation?',
			shortLabel: 'Career pathing',
			sublabel: `**Career Pathing:** the process of aligning opportunities for employee career growth with organizational talent priorities.
This process may include mapping their career direction based on vertical, lateral and crossfunctional roles.
Career pathing is driven by the individual's skills, interests and career objectives. -&nbsp;Gartner`,
			...yesNoOptions,
		},
		CAREERPATH_BUDDY: {
			questionId: 'CAREERPATH_BUDDY',
			label: 'Does your company have a **buddy system?**',
			shortLabel: 'Buddy system',
			sublabel: `**Buddy system:** a knowledge-sharing method used to onboard new employees. 
Each new employee is assigned to an existing employee who guides them through their first weeks or months in the new position.
It makes a substantial difference in early workplace performance and long-term retention.`,
			...yesNoOptions,
		},
		CAREERPATH_MENTORING: {
			questionId: 'CAREERPATH_MENTORING',
			label: 'Does your company have a **mentoring system?**',
			shortLabel: 'Mentoring system',
			sublabel: '**Mentoring:** a process to provide advice and support to ensure a smooth transition -&nbsp;often someone at a higher level in the organisation or with previous experience.',
			...yesNoOptions,
		},
		CAREERPATH_SPONSORSHIP: {
			questionId: 'CAREERPATH_SPONSORSHIP',
			label: 'Does your company have a **sponsorship system?**',
			shortLabel: 'Sponsorship system',
			sublabel: '**Sponsorship:** a person who has authority or influence can use it to help another in their career development or advancement.',
			...yesNoOptions,
		},
		CAREERPATH_SPONSOREDPCT: {
			questionId: 'CAREERPATH_SPONSOREDPCT',
			label: 'What was the percentage of **women sponsored** in the past 12 months?',
			shortLabel: 'Women sponsored',
			...pct100Options,
		},
		EQUALPAY_EQUALPAY: {
			questionId: 'EQUALPAY_EQUALPAY',
			label: 'Do you have **equal pay** between men and women?',
			shortLabel: 'Equal pay',
			sublabel: 'You have **zero % pay gap** between individuals within the same role, meeting the same criteria.',
			...yesNoOptions,
		},
		EQUALPAY_SALARYGRID: {
			questionId: 'EQUALPAY_SALARYGRID',
			label: 'Does your company have a **salary grid** or pay scale?',
			shortLabel: 'Salary grid',
			...singleInput,
			align: 'vertical',
			width: '320px',
			options: [
				{
					label: 'Yes, and it is transparent',
					value: 'yes-transparent',
					score: 1,
				},
				{
					label: 'Yes, but it is not transparent',
					value: 'yes-not-transparent',
					score: 0.5,
				},
				{
					label: 'No, we do not have one',
					value: 'no',
					score: 0,
				},
			],
		},
		EQUALPAY_STOCKOPTIONS: {
			questionId: 'EQUALPAY_STOCKOPTIONS',
			label: 'Does your company provide **Employee Stock Options** as part of the compensation plan?',
			shortLabel: 'Employee Stock Option',
			...yesNoOptions,
		},
		EQUALPAY_SALARYREVIEW: {
			questionId: 'EQUALPAY_SALARYREVIEW',
			label: 'Does your company have an **annual salary review** process to support pay equity?',
			shortLabel: 'Annual salary review',
			...yesNoOptions,
		},
		EQUALPAY_MATERNITYRAISE: {
			questionId: 'EQUALPAY_MATERNITYRAISE',
			label: 'Does your company offer a **salary increase after maternity leave?**',
			shortLabel: 'Salary increase after maternity',
			sublabel: 'Women returning from maternity leave are evaluated fairly regardless of time on leave, and awarded a salary increase based on merit.',
			...yesNoOptions,
		},
		EQUALPAY_SALARYINCREASEPCT: {
			questionId: 'EQUALPAY_SALARYINCREASEPCT',
			label: 'What is your company\'s percentage of **women\'s salary increase** in the last year?',
			shortLabel: 'Women salary increase',
			skipLabel: 'If your company is not yet established enough to have any salary increase for anyone',
			...pctInput,
			min: -500,
			max: 500,
		},
		EQUALPAY_PAYGAPDATA: {
			questionId: 'EQUALPAY_PAYGAPDATA',
			label: 'Does your company **publish data internally** on the gender pay gap?',
			shortLabel: 'Gender pay gap publication',
			...yesNoOptions,
		},
		EQUALPAY_INEQUITYMETHOD: {
			questionId: 'EQUALPAY_INEQUITYMETHOD',
			label: 'Does your company have implemented a method for **determining compensation** and addressing pay differences?',
			shortLabel: 'Inequity method',
			...yesNoOptions,
		},
		WORKLIFE_REMOTE: {
			questionId: 'WORKLIFE_REMOTE',
			label: 'Has your company incorporated the **remote or hybrid work model** to accommodate different needs?',
			shortLabel: 'Hybrid/remote',
			sublabel: '**Hybrid work model:** employees are offered the flexibility to work wherever they\'re most productive, either on-site or remotely.',
			...yesNoOptions,
		},
		WORKLIFE_FLEXIBLEHOURS: {
			questionId: 'WORKLIFE_FLEXIBLEHOURS',
			shortLabel: 'Flexible hours',
			label: 'Does your company have **flexible work schedule** structure in place?',
			sublabel: 'Employees have the freedom to choose a work schedule that suits their personal needs, e.g. Flextime.',
			...yesNoOptions,
		},
		WORKLIFE_FLEXIBLEHOLIDAYS: {
			questionId: 'WORKLIFE_FLEXIBLEHOLIDAYS',
			shortLabel: 'Flexible holidays',
			label: 'Does your company give employees the **flexibility to schedule their holidays** at a time of their convenience?',
			sublabel: `Employees are not limited to specific dates to set their holidays.

This question does not refer to an unlimited number of holiday days.`,
			...yesNoOptions,
		},
		WORKLIFE_UNLIMITEDHOLIDAYS: {
			questionId: 'WORKLIFE_UNLIMITEDHOLIDAYS',
			shortLabel: 'Unlimited holidays',
			label: 'Does your company offer **unlimited holiday days?**',
			sublabel: 'Employees are not limited to a fixed number of holiday days per year.',
			...yesNoOptions,
		},
		WORKLIFE_PARENTALPAIDLEAVE: {
			questionId: 'WORKLIFE_PARENTALPAIDLEAVE',
			shortLabel: 'Paid leave for both parents',
			label: 'Does your company have a culture that supports **paid maternity and paternity leave** for each parent?',
			sublabel: 'This can be a **legal requirement** in some countries, such as France.',
			...yesNoOptions,
		},
		WORKLIFE_EXTMATERNITYLEAVE: {
			questionId: 'WORKLIFE_EXTMATERNITYLEAVE',
			shortLabel: 'Extended paid maternity leave',
			label: 'Does your company have **extended paid maternity leave?**',
			sublabel: 'Your company goes beyond the legal requirements - employees still get paid for additional maternity leave.',
			...yesNoOptions,
		},
		WORKLIFE_PARENTSREENTRY: {
			questionId: 'WORKLIFE_PARENTSREENTRY',
			shortLabel: 'Return-to-work programme',
			label: 'Does your company have a formal **return-to-work programme** to support the re-entry of parents into the workforce?',
			...yesNoOptions,
		},
		WORKLIFE_EXTRABENEFITS: {
			questionId: 'WORKLIFE_EXTRABENEFITS',
			label: 'Has your company implemented any of the following **extra&nbsp;benefits?**',
			shortLabel: 'Extra benefits',
			...multipleInput,
			columns: 2,
			options: [
				{
					label: 'Maintenance of salary during maternity leave',
					value: 'salary-maintenance-maternity-leave',
					score: 3,
				},
				{
					label: 'Daycare / nursery support',
					value: 'daycare-nursery-support',
					score: 1,
				},
				{
					label: 'Fertility treatment reimbursements',
					value: 'fertility-treatment-reimbursments',
					score: 1,
				},
				{
					label: 'Paid leave to care for a sick relative',
					value: 'sick-relative-paid-leave',
					score: 2,
				},
				{
					label: 'Paid sabbatical leave',
					value: 'sabbatical-paid-leave',
					score: 1,
				},
				{
					label: 'Loss compassionate leave',
					value: 'loss-compassionate-paid-leave',
					score: 2,
				},
				{
					label: 'Paid leave for senior care',
					value: 'senior-care-paid-leave',
					score: 1,
				},
				{
					label: 'Insurance policies',
					value: 'insurance-policies',
					score: 1,
				},
				{
					label: 'Gym memberships',
					value: 'gym-membership',
					score: 1,
				},
				{
					label: 'None of the above',
					value: 'none',
					score: 0,
				},
			],
		},
		DEI_ANTIHARASSMENT: {
			questionId: 'DEI_ANTIHARASSMENT',
			shortLabel: 'Anti-harassment policy',
			label: 'Does your company have an **anti-harassment policy** in place?',
			...yesNoOptions,
		},
		DEI_ANTIDISCRIMINATION: {
			questionId: 'DEI_ANTIDISCRIMINATION',
			shortLabel: 'Anti-discrimination policy',
			label: 'Does your company have an **anti-discrimination policy** in place? ',
			...yesNoOptions,
		},
		DEI_COMPLAINTSPROCEDURE: {
			questionId: 'DEI_COMPLAINTSPROCEDURE',
			label: 'Does your company have a bullying, discrimination and harassment **complaints&nbsp;procedure** that is clear to all employees?',
			shortLabel: 'Complaints procedure',
			sublabel: 'You provide a step-by-step guideline on how to approach this situation and details of consequences of such behaviors.',
			...yesNoOptions,
		},
		DEI_RESPONSIBILITY: {
			questionId: 'DEI_RESPONSIBILITY',
			label: 'In your company, who is responsible for addressing **workplace&nbsp;discrimination?**',
			shortLabel: 'Discrimination responsibility',
			sublabel: 'Select what\'s most true for your company.',
			...singleInput,
			align: 'vertical',
			options: [
				{
					label: 'We\'ve never considered dealing with this situation',
					value: 'never-considered',
					score: 0,
				},
				{
					label: 'We encourage employees to consult their colleagues or a buddy for advice',
					value: 'encourage-employees-consult',
					score: 1,
				},
				{
					label: 'Employees report to their manager or submit an anonymous report',
					value: 'employees-report-manager-or-anonymous',
					score: 2,
				},
				{
					label: 'We have a dedicated individual within the HR (or other) team',
					value: 'dedicated-individual',
					score: 3,
				},
			],
		},
		DEI_SUPPORTDIALOGUE: {
			questionId: 'DEI_SUPPORTDIALOGUE',
			label: 'Does your company provide **accessible&nbsp;support** and have open dialogues about DEI related subjects?',
			shortLabel: 'DEI dialogue',
			...yesNoOptions,
		},
		DEI_UNBIASEDRECRUITMENT: {
			questionId: 'DEI_UNBIASEDRECRUITMENT',
			label: 'Has your company implemented any of the following strategies for **unbiased&nbsp;recruitment?**',
			shortLabel: 'Unbiased recruitment strategies',
			sublabel: 'Select all that apply.',
			...multipleInput,
			columns: 2,
			options: [
				{
					label: 'Non-gendered job description',
					value: 'non-gendered-job-desc',
					score: 1,
				},
				{
					label: 'Structured interviews',
					value: 'structured-interviews',
					score: 3,
				},
				{
					label: 'Anonymous candidate screening',
					value: 'anonymous-candidate-screening',
					score: 1,
				},
				{
					label: 'Interview scoring/rating system',
					value: 'interview-scoring-rating',
					score: 2,
				},
				{
					label: 'Diverse recruitment team',
					value: 'diverse-recruitment-team',
					score: 3,
				},
				{
					label: 'Inclusive recruitment training',
					value: 'inclusive-recruitment-training',
					score: 1,
				},
				{
					label: 'Building a diverse pipeline',
					value: 'building-diverse-pipeline',
					score: 2,
				},
				{
					label: 'None of the above',
					value: 'none',
					score: 0,
				},
			],
		},
		DEI_BIASTRAINING: {
			questionId: 'DEI_BIASTRAINING',
			label: 'Does your company invest in **unconscious bias training** and education starting from the top management?',
			shortLabel: 'Unconscious bias training',
			sublabel: 'This can be internal or outsourced.',
			...yesNoOptions,
		},
		DEI_LEARNINGCOMMITMENT: {
			questionId: 'DEI_LEARNINGCOMMITMENT',
			label: 'What does your company do in terms of **DEI learning and commitment?**',
			shortLabel: 'DEI learning and commitment',
			sublabel: 'Select all that apply.',
			...multipleInput,
			columns: 2,
			options: [
				{
					label: 'Continuous follow-up after DEI training',
					value: 'continuous-followup-after-dei-training',
					score: 1,
				},
				{
					label: 'Internal events or meetings on DEI topics',
					value: 'internal-events-meetings-dei-topics',
					score: 2,
				},
				{
					label: 'Diversity, Equity and Inclusion Policy',
					value: 'dei-policy',
					score: 1,
				},
				{
					label: 'Encourage using preferred pronouns',
					value: 'encourage-pronouns',
					score: 2,
				},
				{
					label: 'Prioritize intersectional inclusion',
					value: 'prioritize-intersectional-inclusion',
					score: 2,
				},
				{
					label: 'Gender inclusive facilities',
					value: 'gender-inclusive-facilities',
					score: 2,
				},
				{
					label: 'Inclusive language',
					value: 'inclusive-language',
					score: 3,
				},
				{
					label: 'Inclusive leadership',
					value: 'inclusive-leadership',
					score: 1,
				},
				{
					label: 'None of the above',
					value: 'none',
					score: 0,
				},
			],
		},
		DEI_APPOINTEDINDIVIDUAL: {
			questionId: 'DEI_APPOINTEDINDIVIDUAL',
			label: 'Does your company have an **appointed individual (or team)** to work on their DEI strategy?',
			shortLabel: 'Appointed team for DEI',
			...yesNoOptions,
		},
		DEI_ERGS: {
			questionId: 'DEI_ERGS',
			label: 'Does your company have **Employee Resource Groups** (ERGs)?',
			shortLabel: 'ERGs',
			...yesNoOptions,
		},
		DEI_MENTALHEALTH: {
			questionId: 'DEI_MENTALHEALTH',
			label: 'Does your company have implemented initiatives on **mental health?**',
			shortLabel: 'Mental health initiatives',
			sublabel: 'Select all that apply.',
			...multipleInput,
			columns: 2,
			options: [
				{
					label: 'Free or subsidized access to mental health experts',
					value: 'free-subsidized-access-mental-health-experts',
					score: 3,
				},
				{
					label: 'Workplace wellness surveys/check-in\'s',
					value: 'workplace-wellness-survey',
					score: 1,
				},
				{
					label: 'Free subscription to a wellness app',
					value: 'free-susbcription-wellness-app',
					score: 1,
				},
				{
					label: 'Internal events for mental health',
					value: 'internal-events-mental-health',
					score: 2,
				},
				{
					label: 'Stress management programs',
					value: 'stress-management-programs',
					score: 3,
				},
				{
					label: 'None of the above',
					value: 'none',
					score: 0,
				},
			],
		},
		DEI_RECRUITMENTPRIOS: {
			questionId: 'DEI_RECRUITMENTPRIOS',
			label: 'What does your company **prioritize when recruiting?**',
			shortLabel: 'Recruitment criteria priority',
			sublabel: 'Select the most highly considered variable.',
			...singleInput,
			align: 'vertical',
			width: '320px',
			options: [
				{
					label: 'Educational background',
					value: 'educational-background',
					score: 0,
				},
				{
					label: 'Technical skills',
					value: 'technical-skills',
					score: 1,
				},
				{
					label: 'Work experience',
					value: 'work-experience',
					score: 2,
				},
				{
					label: 'Soft skills',
					value: 'soft-skills',
					score: 3,
				},
			],
		},
		DEI_JUNIORHIRES: {
			questionId: 'DEI_JUNIORHIRES',
			label: 'Does your company hire candidates at **junior/entry-level?**',
			shortLabel: 'Junior candidates',
			...yesNoOptions,
		},
		DEI_CAREERCHANGEHIRES: {
			questionId: 'DEI_CAREERCHANGEHIRES',
			label: 'Does your company hire candidates embarking on a **career change?**',
			shortLabel: 'Career change candidates',
			...yesNoOptions,
		},
		DEI_INTENTIONSNEXTYEAR: {
			questionId: 'DEI_INTENTIONSNEXTYEAR',
			label: 'What are your **intentions** to support diversity, equity and inclusion (DEI) over the next year?',
			shortLabel: 'Next year\'s DEI intentions',
			sublabel: 'Select all that apply.',
			...multipleInput,
			columns: 2,
			options: [
				{
					label: 'Dedicate a budget and resources',
					value: 'dedicate-budget-resources',
					score: 0,
				},
				{
					label: 'Formalize a gender inclusion strategy',
					value: 'formalize-gender-inclusion-strategy',
					score: 0,
				},
				{
					label: 'DEI training with an expert',
					value: 'dei-training-with-expert',
					score: 0,
				},
				{
					label: 'Assign an individual/team to take ownership of the strategy',
					value: 'assign-team-ownership-strategy',
					score: 0,
				},
				{
					label: 'Set targets to achieve gender parity in tech roles',
					value: 'set-targets-for-gender-parity-tech-roles',
					score: 0,
				},
				{
					label: 'Precisely monitor our DEI metrics',
					value: 'monitor-dei-metrics',
					score: 0,
				},
				{
					label: 'None of the above',
					value: 'none',
					score: 0,
				},
			],
		},
		V3_GD_WORKFORCE_WOMEN_RATIO: {
			questionId: 'V3_GD_WORKFORCE_WOMEN_RATIO',
			label: "What is your company's **percentage of women** in the **overall workforce?**",
			sublabel: 'Do not include **interns or apprentices** in your answer.',
			shortLabel: 'Women in the workforce',
			...pct100Options,
		},
		V3_GD_WORKFORCE_WOMEN_BOARD_RATIO: {
			questionId: 'V3_GD_WORKFORCE_WOMEN_BOARD_RATIO',
			label: "What is your company's **percentage of women** in **your board?**",
			// sublabel: '**Top-level management roles:** President, Vice-President, member of the board of directors, C-Level',
			shortLabel: 'Women in board',
			...pct100Options,
		},
		V3_GD_WORKFORCE_WOMEN_TOPMGT_RATIO: {
			questionId: 'V3_GD_WORKFORCE_WOMEN_TOPMGT_RATIO',
			label: "What is your company's **percentage of women** in **top-level management roles?**",
			sublabel: '**Top-level management roles:** President, Vice-President, member of the board of directors, C-Level',
			shortLabel: 'Women top-level managers',
			...pct100Options,
		},
		V3_GD_WORKFORCE_WOMEN_OTHERMGT_RATIO: {
			questionId: 'V3_GD_WORKFORCE_WOMEN_OTHERMGT_RATIO',
			label: "What is your company's **percentage of women** in **other management roles?**",
			sublabel: '**Other management roles:** Team managers, first-line managers, middle managers',
			shortLabel: 'Women other managers',
			...pct100Options,
		},
		V3_GD_TECH_EMPLOYEES: {
			questionId: 'V3_GD_TECH_EMPLOYEES',
			label: "What is your company's **overall number of employees** in your **IT/tech department?**",
			sublabel: '**IT/Tech dept:** Engineering, Data, Product, IT roles',
			shortLabel: 'IT/tech headcount',
			...numberInput,
			min: 0,
			width: 400,
		},
		V3_GD_TECH_WOMEN_RATIO: {
			questionId: 'V3_GD_TECH_WOMEN_RATIO',
			label: "What is your company's **percentage of women** in your **IT/tech department?**",
			sublabel: '**IT/Tech dept:** Engineering, Data, Product, IT roles',
			shortLabel: 'Women in tech',
			...pct100Options,
		},
		V3_GD_TECH_WOMEN_TOPMGT_RATIO: {
			questionId: 'V3_GD_TECH_WOMEN_TOPMGT_RATIO',
			label: "What is your company's **percentage of women** in **top-level management roles** in your **IT/tech department?**",
			sublabel: '**IT/Tech dept:** Engineering, Data, Product, IT roles\n\n**Top-level management roles:** President, Vice-President, member of the board of directors, C-Level',
			shortLabel: 'Women top mgt in tech',
			...pct100Options,
		},
		V3_GD_TECH_WOMEN_OTHERMGT_RATIO: {
			questionId: 'V3_GD_TECH_WOMEN_OTHERMGT_RATIO',
			label: "What is your company's **percentage of women** in **other management roles** in your **IT/tech department?**",
			sublabel: '**IT/Tech dept:** Engineering, Data, Product, IT roles\n\n**Other management roles:** Team managers, first-line managers, middle managers\n\nDo not include **interns or apprentices** in your answer.',
			shortLabel: 'Women other mgt in tech',
			...pct100Options,
		},
		V3_GD_TECH_WOMEN_ENGINEERING_RATIO: {
			questionId: 'V3_GD_TECH_WOMEN_ENGINEERING_RATIO',
			label: "What is your company's **percentage of women** in **Engineering roles?**",
			sublabel: 'Do not include **interns or apprentices** in your answer.',
			shortLabel: 'Women in Engineering',
			...pct100Options,
		},
		V3_GD_TECH_WOMEN_PRODUCT_RATIO: {
			questionId: 'V3_GD_TECH_WOMEN_PRODUCT_RATIO',
			label: "What is your company's **percentage of women** in **Product roles?**",
			sublabel: 'Do not include **interns or apprentices** in your answer.',
			shortLabel: 'Women in Product',
			...pct100Options,
		},
		V3_GD_TECH_WOMEN_DATA_RATIO: {
			questionId: 'V3_GD_TECH_WOMEN_DATA_RATIO',
			label: "What is your company's **percentage of women** in **Data roles?**",
			sublabel: 'Do not include **interns or apprentices** in your answer.',
			shortLabel: 'Women in Data',
			...pct100Options,
		},
		V3_GD_LABELS_GLASSDOOR_PRESENCE: {
			questionId: 'V3_GD_LABELS_GLASSDOOR_PRESENCE',
			label: 'Does your company have a **Glassdoor page?**',
			shortLabel: 'Glassdoor presence',
			...yesNoOptions,
		},
		V3_GD_LABELS_GLASSDOOR_SCORE: {
			questionId: 'V3_GD_LABELS_GLASSDOOR_SCORE',
			label: "What is your company's current **Glassdoor rating?**",
			shortLabel: 'Glassdoor score',
			...numberInput,
			min: 0,
			max: 5,
			step: 0.1,
		},
		V3_GD_LABELS_EGAPRO_MEASUREMENT: {
			questionId: 'V3_GD_LABELS_EGAPRO_MEASUREMENT',
			label: 'Have you measured your **Egapro Index** (in France)?',
			sublabel: "**Egapro Index:** a professional equality Index in France, applicable to all companies with more than 50 employees, designed to help companies measure their gender pay gap, and implement measures to promote equal pay.\n\nAnswer **No** if you are eligible but haven't yet measured it.\n\nAnswer **Not relevant** if your company is not concerned by this Index.",
			shortLabel: 'Egapro measurement',
			...singleInput,
			align: 'horizontal',
			options: [
				{
					label: 'Yes',
					value: 'yes',
					score: 1,
				},
				{
					label: 'No',
					value: 'no',
					score: 0,
				},
				{
					label: 'Not relevant',
					value: 'not-applicable',
					score: -1,
				},
			],
		},
		V3_GD_LABELS_EGAPRO_SCORE: {
			questionId: 'V3_GD_LABELS_EGAPRO_SCORE',
			label: "What is your company's current **Egapro Index score?**",
			shortLabel: 'Egapro score',
			...numberInput,
			min: 0,
			max: 100,
		},
		V3_GD_LABELS_OTHER_CERTS: {
			questionId: 'V3_GD_LABELS_OTHER_CERTS',
			label: 'What **other certifications** have you received? Please provide the name of the label, your score, and the year.',
			shortLabel: 'Other certifications',
			input: 'text',
		},
		V3_GD_RETENTION_MEDIAN_TENURE: {
			questionId: 'V3_GD_RETENTION_MEDIAN_TENURE',
			label: 'What was the **median tenure** of employees throughout the past year at your company?',
			sublabel: '**Tenure:** duration of employment (from start to termination) of an employee. \n\n**Median tenure:** obtained by dividing the amount of time all past and present employees worked at your organization by the total number of past and present employee.\n\nExample: if since its inception, 30 employees have worked at your company, and the cumulative amount of time they spent is 150 years, the median tenure will be 150/30 = 5 years.\n\nSource: https://www.hrreportingtools.com/hr-metrics/median-employee-tenure#:~:text=What%20(Definition),to%20today)%20of%20an%20employee.',
			shortLabel: 'Median tenure',
			...singleInput,
			columns: 5,
			align: 'vertical',
			options: [
				{
					label: '< 1 year',
					value: '<1y',
					score: 0,
				},
				{
					label: '< 2 years',
					value: '<2y',
					score: 0,
				},
				{
					label: '< 3 years',
					value: '<3y',
					score: 0.25,
				},
				{
					label: '< 4 years',
					value: '<4y',
					score: 0.5,
				},
				{
					label: '>= 4 years',
					value: '>4y',
					score: 1,
				},
			],
		},
		V3_GD_RETENTION_PROMOTION_RATE: {
			questionId: 'V3_GD_RETENTION_PROMOTION_RATE',
			label: 'What was the **promotion rate for all employes** throughout the past year at your company?',
			sublabel: '**Promotion rate:** use the formula: (Total number of promotions / Total number of employees) x 100. \n\nLet\'s say your organisation has 200 employees, and 10 were promoted within a year. The calculation would be: 10 / 200 x 100 = 5%',
			shortLabel: 'Global promotion rate',
			...pctOptions,
		},
		V3_GD_RETENTION_WOMEN_PROMOTION_RATE: {
			questionId: 'V3_GD_RETENTION_WOMEN_PROMOTION_RATE',
			label: 'What was the **promotion rate for women** throughout the past year at your company?',
			sublabel: '**Promotion rate:** use the formula: (Total number of promoted women / Total number of promotions) x 100. \n\nExample: Let\'s say your organisation promoted 5 women, and 30 employees in total within a year. The calculation would be: 5 / 30 x 100 = 16%',
			shortLabel: 'Women promotion rate',
			...pctOptions,
		},
		V3_GD_RETENTION_TURNOVER_TRACKING: {
			questionId: 'V3_GD_RETENTION_TURNOVER_TRACKING',
			label: 'Do you track the **turnover rate** at your company?',
			shortLabel: 'Turnover rate tracking',
			...yesNoGenderOptions,
		},
		V3_GD_RETENTION_TURNOVER_RATE: {
			questionId: 'V3_GD_RETENTION_TURNOVER_RATE',
			label: 'What was the **turnover rate for all employees** at your company throughout the past year?',
			sublabel: '**Turnover rate:** Use the formula: (100 x (Number of departures during last year + Number of arrivals during last year) / 2) / (Number of employees on Jan 1st of the current year)\n\nExample: if a company has 250 employees on January 1st, recorded 15 departures and recruited 20 people during the previous year, the turnover rate is (100x (20+15)/2) / 250 = 7%',
			shortLabel: 'Global turnover rate',
			...pctOptions,
		},
		V3_GD_RETENTION_WOMEN_TURNOVER_RATE: {
			questionId: 'V3_GD_RETENTION_WOMEN_TURNOVER_RATE',
			label: 'What was the **turnover rate for women** at your company throughout the past year?',
			shortLabel: 'Women turnover rate',
			...pctOptions,
		},
		V3_FCP_PRACTICES_COMPETENCY_MATRIX: {
			questionId: 'V3_FCP_PRACTICES_COMPETENCY_MATRIX',
			label: 'Does your company have a **competency matrix** which highlights employee skills?',
			shortLabel: 'Competency matrix',
			...yesNoInProgressOptions,
		},
		V3_FCP_PRACTICES_CAREER_DEVELOPMENT_GRID: {
			questionId: 'V3_FCP_PRACTICES_CAREER_DEVELOPMENT_GRID',
			label: 'Does your company have a **career development grid?**',
			shortLabel: 'Career development grid',
			...singleInput,
			options: [
				{
					label: 'Yes, and it is transparent for all employees',
					value: 'yes-transparent',
					score: 1,
				},
				{
					label: 'Yes, but it is not transparent',
					value: 'yes-not-transparent',
					score: 0.5,
				},
				{
					label: 'In progress',
					value: 'in-progress',
					score: 0.25,
				},
				{
					label: 'No, we don\'t have one',
					value: 'no',
					score: 0,
				},
			],
		},
		V3_FCP_PRACTICES_UNBIASED_LEADERSHIP_ROLES: {
			questionId: 'V3_FCP_PRACTICES_UNBIASED_LEADERSHIP_ROLES',
			label: 'Have you verified that there are no biases in the descriptions of leadership roles?',
			shortLabel: 'Unbiased leadership roles',
			...yesNoOptions,
		},
		V3_FCP_PRACTICES_SUCCESSION_PLAN: {
			questionId: 'V3_FCP_PRACTICES_SUCCESSION_PLAN',
			label: 'Does your company have plans of succession?',
			sublabel: '**Succession plan:** an HR management tool that enables the implementation of a strategy for identifying and replacing key roles within a company. Used extensively for all managerial positions today, it ensures business continuity while anticipating future team movements.',
			shortLabel: 'Succession plan',
			...yesNoInProgressOptions,
		},
		V3_FCP_PRACTICES_INTERNAL_MOBILITY_PROGRAM: {
			questionId: 'V3_FCP_PRACTICES_INTERNAL_MOBILITY_PROGRAM',
			label: 'Does your company have an **internal mobility program?**',
			shortLabel: 'Internal mobility program',
			...yesNoInProgressOptions,
		},
		V3_FCP_PRACTICES_MANAGERS_CAREER_PATHING: {
			questionId: 'V3_FCP_PRACTICES_MANAGERS_CAREER_PATHING',
			label: 'Are managers and/or supervisors actively involved in helping employees with **career pathing?**',
			sublabel: 'i.e. matching their career goals to internal opportunities',
			shortLabel: 'Managers & career pathing',
			...yesNoOptions,
		},
		V3_FCP_PRACTICES_FORMALIZED_FEEDBACK_PROCESS: {
			questionId: 'V3_FCP_PRACTICES_FORMALIZED_FEEDBACK_PROCESS',
			label: 'Have you formalised a mandatory process for employees to ask their managers or supervisors for **feedback?**',
			shortLabel: 'Formalized feedback process',
			...yesNoInProgressOptions,
		},
		V3_FCP_PRACTICES_NONBIASED_ANNUAL_REVIEW: {
			questionId: 'V3_FCP_PRACTICES_NONBIASED_ANNUAL_REVIEW',
			label: 'Is your company using a **non-biased framework** for your **annual performance review?**',
			shortLabel: 'Non-biased annual review',
			...yesNoInProgressOptions,
		},
		V3_FCP_DEVELOPMENT_MENTORSHIP_PROGRAM: {
			questionId: 'V3_FCP_DEVELOPMENT_MENTORSHIP_PROGRAM',
			label: 'Does your company have a **mentorship program?**',
			sublabel: '**Mentorship programs:** a type of professional development strategy organisations use to connect more experienced team members into developmental relationships with team members who want to expand their skills and/or experiences. \nSource: https://www.mentorcliq.com/blog/what-are-mentorship-programs\n',
			shortLabel: 'Mentorship program',
			...yesNoInProgressOptions,
		},
		V3_FCP_DEVELOPMENT_MENTORSHIP_PROGRAM_SATISFACTION: {
			questionId: 'V3_FCP_DEVELOPMENT_MENTORSHIP_PROGRAM_SATISFACTION',
			label: 'Do you track the **satisfaction** of your **mentorship program?**',
			sublabel: 'i.e. NPS, surveys, etc.',
			shortLabel: 'Mentorship program statisfaction',
			...yesNoGenderOptions,
		},
		V3_FCP_DEVELOPMENT_SPONSORSHIP_PROGRAM: {
			questionId: 'V3_FCP_DEVELOPMENT_SPONSORSHIP_PROGRAM',
			label: 'Does your company have a **sponsorship program?**',
			sublabel: 'Sponsorship program: a relationship between a protégé and a person who has the authority or influence to help someone with career development or advancement. A sponsor is not only more experienced than the sponsee, but they can open up opportunities for them within the company or in a particular field. \nSource: https://www.togetherplatform.com/blog/mentorship-sponsorship-differences\n',
			shortLabel: 'Sponsorship program',
			...yesNoInProgressOptions,
		},
		V3_FCP_DEVELOPMENT_SPONSORSHIP_PROGRAM_SATISFACTION: {
			questionId: 'V3_FCP_DEVELOPMENT_SPONSORSHIP_PROGRAM_SATISFACTION',
			label: 'Do you track the **satisfaction** of your **sponsorship program?**',
			sublabel: 'i.e. NPS, surveys, etc.',
			shortLabel: 'Sponsorship program statisfaction',
			...yesNoGenderOptions,
		},
		V3_FCP_DEVELOPMENT_CAREER_COACHING_PROGRAM: {
			questionId: 'V3_FCP_DEVELOPMENT_CAREER_COACHING_PROGRAM',
			label: 'Does your company offer **career coaching programs?**',
			shortLabel: 'Career coaching programs',
			...yesNoInProgressOptions,
		},
		V3_FCP_DEVELOPMENT_CAREER_COACHING_PROGRAM_SATISFACTION: {
			questionId: 'V3_FCP_DEVELOPMENT_CAREER_COACHING_PROGRAM_SATISFACTION',
			label: 'Do you track the **satisfaction** of your **career coaching programs?**',
			sublabel: 'i.e. NPS, surveys, etc.',
			shortLabel: 'Career coaching programs satisfaction',
			...yesNoGenderOptions,
		},
		V3_EP_REVIEW_EQUALPAY_REVIEW: {
			questionId: 'V3_EP_REVIEW_EQUALPAY_REVIEW',
			label: 'Has your company carried out an **equal pay review or audit** over the past year?',
			shortLabel: 'Equal pay review',
			...yesNoInProgressOptions,
		},
		V3_EP_REVIEW_EQUALPAY_REVIEW_METHOD: {
			questionId: 'V3_EP_REVIEW_EQUALPAY_REVIEW_METHOD',
			label: 'How do you carry out your **equal pay review/audit?**',
			shortLabel: 'Equal pay review method',
			...multipleInput,
			options: [
				{
					label: 'Third-party tool',
					value: 'third-party-tool',
					score: 1,
				},
				{
					label: 'Consultants',
					value: 'consultants',
					score: 1,
				},
				{
					label: 'Manually by an internal team',
					value: 'manually-internal-team',
					score: 1,
				},
				{
					label: 'Other',
					value: 'other',
					score: 1,
				},
			],
		},
		V3_EP_REVIEW_ADJUSTED_PAYGAP: {
			questionId: 'V3_EP_REVIEW_ADJUSTED_PAYGAP',
			label: 'What is the **adjusted gender pay gap** at your company?',
			sublabel: '**Adjusted pay gap:** (also called the controlled pay gap or equal pay gap) difference in pay between women and men after accounting for factors that determine pay, such as job role, education, and experience.\nSource: https://www.payanalytics.com/newsroom/the-unadjusted-pay-gap-vs-the-adjusted-pay-gap',
			shortLabel: 'Adjusted pay gap',
			...pctOptions,
		},
		V3_EP_REVIEW_UNADJUSTED_PAYGAP: {
			questionId: 'V3_EP_REVIEW_UNADJUSTED_PAYGAP',
			label: 'What is the **unadjusted gender pay gap** at your company?',
			sublabel: '**Unadjusted pay gap:** difference between the average gross hourly earnings of men and women expressed as a percentage of the average gross hourly earnings of men. It is calculated for enterprises with 10 or more employees.\nSource: https://ec.europa.eu/eurostat/statistics-explained\n',
			shortLabel: 'Unadjusted pay gap',
			...pctOptions,
		},
		V3_EP_REVIEW_PAY_DISPARITY_METHODS: {
			questionId: 'V3_EP_REVIEW_PAY_DISPARITY_METHODS',
			label: 'How does your company address **pay disparity?**',
			sublabel: 'Select all that apply.',
			shortLabel: 'Pay disparity methods',
			...multipleInput,
			options: [
				{
					label: 'Job descriptions are aligned with the proposed salary',
					value: 'job-desc-aligned-with-salary',
					score: 4,
				},
				{
					label: 'Unbiased salary reviews',
					value: 'unbiased-salary-reviews',
					score: 3,
				},
				{
					label: 'Provide managers with guidelines on equitable pay practices',
					value: 'manager-guidelines',
					score: 2,
				},
				{
					label: 'Using compensation budgetary envelopes',
					value: 'compensation-budgetary-envelopes',
					score: 1,
				},
				{
					label: 'Other method',
					value: 'other',
					score: 2,
				},
				{
					label: 'We don\'t have any method yet',
					value: 'none',
					score: 0,
				},
			],
		},
		V3_EP_TRANSPARENCY_PAYGAP_SHARING: {
			questionId: 'V3_EP_TRANSPARENCY_PAYGAP_SHARING',
			label: 'Are you **sharing the gender pay gap data** with all employees?',
			shortLabel: 'Gender pay gap sharing',
			...yesNoInProgressOptions,
		},
		V3_EP_TRANSPARENCY_SALARY_GRID: {
			questionId: 'V3_EP_TRANSPARENCY_SALARY_GRID',
			label: 'Does your company have a **salary grid?**',
			sublabel: '**Salary grid:** to complete',
			shortLabel: 'Salary grid',
			...yesNoInProgressOptions,
		},
		V3_EP_TRANSPARENCY_SALARY_POLICY: {
			questionId: 'V3_EP_TRANSPARENCY_SALARY_POLICY',
			label: 'Do you have a **transparent salary policy?**',
			sublabel: 'Select all that apply.',
			shortLabel: 'Transparent salary policy',
			...multipleInput,
			options: [
				{
					label: 'Including salary ranges on job descriptions',
					value: 'including-salary-job-desc',
					score: 3,
				},
				{
					label: 'External access to salary grid',
					value: 'external-salary-grid',
					score: 2,
				},
				{
					label: 'Salary calculator for all applying candidates',
					value: 'salary-calculator',
					score: 1,
				},
				{
					label: 'Transparent salary grid for all employees',
					value: 'transparent-salary-grid',
					score: 5,
				},
				{
					label: 'Internal access to all individual salaries',
					value: 'internal-access-salaries',
					score: 3,
				},
				{
					label: 'External access to all individual salaries',
					value: 'external-access-salaries',
					score: 4,
				},
				{
					label: 'None of the above',
					value: 'none',
					score: 0,
				},
			],
		},
		V3_EP_COMPENSATION_EQUITY: {
			questionId: 'V3_EP_COMPENSATION_EQUITY',
			label: 'Are **stock options (ESOs) or equity shares** part of your Employee Compensation Plan?',
			shortLabel: 'Stock/Equity',
			...yesNoInProgressOptions,
		},
		V3_EP_COMPENSATION_MATERNITY_RAISE: {
			questionId: 'V3_EP_COMPENSATION_MATERNITY_RAISE',
			label: 'Does your company offer a **salary increase after maternity leave?**',
			shortLabel: 'Raise after maternity',
			...singleInput,
			options: [
				{
					label: 'Yes, at the same level as those that would have been granted to other employees belonging to the same professional category',
					value: 'yes-same-category',
					score: 1,
				},
				{
					label: 'Yes, aligned with the general increase applied in the company',
					value: 'yes-aligned-company',
					score: 0.5,
				},
				{
					label: 'Yes, other',
					value: 'yes-other',
					score: 0.25,
				},
				{
					label: 'In progress',
					value: 'in-progress',
					score: 0.15,
				},
				{
					label: 'No',
					value: 'no',
					score: 0,
				},
			],
		},
		// V3_WLB_FLEXIBILITY_FLEXIBLE_SCHEDULE: {
		// 	questionId: 'V3_WLB_FLEXIBILITY_FLEXIBLE_SCHEDULE',
		// 	label: 'Does your company have a **flexible work schedule** structure in place?',
		// 	shortLabel: 'Flexible work schedule',
		// 	sublabel: 'Employees have the freedom to choose a work schedule that suits their personal needs, e.g. Flextime.',
		// 	...yesNoInProgressOptions,
		// },
		V3_WLB_FLEXIBILITY_FLEXIBLE_SCHEDULE_OFFERING: {
			questionId: 'V3_WLB_FLEXIBILITY_FLEXIBLE_SCHEDULE_OFFERING',
			label: 'What does your company offer in terms of **flexible work schedule?**',
			sublabel: 'Select all that apply.',
			shortLabel: 'Flexible work schedule offering',
			...multipleInput,
			columns: 2,
			options: [
				{
					label: 'Hybrid work',
					value: 'hybrid-work',
					score: 7,
				},
				{
					label: 'Full remote work',
					value: 'full-remote-work',
					score: 5,
				},
				{
					label: '4-day / condensed workweek',
					value: 'four-day-condensed-workweek',
					score: 4,
				},
				{
					label: 'Flexible hours',
					value: 'flexible-hours',
					score: 7,
				},
				{
					label: 'Asynchronous work',
					value: 'asynchronous-work',
					score: 2,
				},
				{
					label: 'Other',
					value: 'other',
					score: 1,
				},
				{
					label: 'None of the above',
					value: 'none',
					score: 0,
				},
			],
		},
		V3_WLB_FLEXIBILITY_TIMEOFF_SCHEDULING_METHODS: {
			questionId: 'V3_WLB_FLEXIBILITY_TIMEOFF_SCHEDULING_METHODS',
			label: 'How does your company let employees **schedule their time off?**',
			sublabel: 'For instance for personal obligations.',
			shortLabel: 'Time off scheduling methods',
			...singleInput,
			options: [
				{
					label: 'Employees independently arrange their time off',
					value: 'independent-scheduling',
					score: 10,
				},
				{
					label: 'Employees arrange their time off through a supervisor',
					value: 'supervisor-scheduling',
					score: 5,
				},
				{
					label: 'Other',
					value: 'other',
					score: 2,
				},
				{
					label: 'Employees cannot schedule their time off',
					value: 'none',
					score: 0,
				},
			],
		},
		V3_WLB_FLEXIBILITY_TIMEOFF_ENCOURAGEMENT: {
			questionId: 'V3_WLB_FLEXIBILITY_TIMEOFF_ENCOURAGEMENT',
			label: 'Does the company **encourage** employees to take **holidays/time off?**',
			sublabel: 'Select all that apply.',
			shortLabel: 'Time off encouragement',
			...multipleInput,
			columns: 2,
			options: [
				{
					label: 'Ability to take holidays during the first year',
					value: 'holidays-first-year',
					score: 10,
				},
				{
					label: 'Bonus for long break holidays',
					value: 'bonus-long-holidays',
					score: 7,
				},
				{
					label: 'Unlimited holidays',
					value: 'unlimited-holidays',
					score: 1,
				},
				{
					label: 'Paid sabbatical leave',
					value: 'paid-sabbatical-leave',
					score: 4,
				},
				{
					label: 'Unpaid leave',
					value: 'unpaid-leave',
					score: 3,
				},
				{
					label: 'Other',
					value: 'other',
					score: 2,
				},
				{
					label: 'None of the above',
					value: 'none',
					score: 0,
				},
			],
		},
		V3_WLB_PARENTALITY_BENEFITS_DURING: {
			questionId: 'V3_WLB_PARENTALITY_BENEFITS_DURING',
			label: 'Which **benefits** does your company offer **during maternity and/or parental leave?**',
			sublabel: 'Select all that apply.',
			shortLabel: 'Benefits during parental leave',
			...multipleInput,
			columns: 2,
			options: [
				{
					label: 'Fully paid maternity leave',
					value: 'fully-paid-maternity-leave',
					score: 3,
				},
				{
					label: 'Extended maternity leave',
					value: 'extended-maternity-leave',
					score: 3,
				},
				{
					label: 'Fully paid parental leave',
					value: 'fully-paid-parental-leave',
					score: 3,
				},
				{
					label: 'Extended parental leave',
					value: 'extended-parental-leave',
					score: 3,
				},
				{
					label: 'Parental leave equal to maternity leave',
					value: 'parental-leave-equal-maternity',
					score: 2,
				},
				{
					label: 'Other',
					value: 'other',
					score: 1,
				},
				{
					label: 'None of the above',
					value: 'none',
					score: 0,
				},
			],
		},
		V3_WLB_PARENTALITY_BENEFITS_RETURN: {
			questionId: 'V3_WLB_PARENTALITY_BENEFITS_RETURN',
			label: 'Which **benefits** does your company offer to employees when **returning from maternity and/or parental leave?**',
			sublabel: 'Select all that apply.',
			shortLabel: 'Benefits after parental leave',
			...multipleInput,
			options: [
				{
					label: 'Maternity come-back career coaching (balancing career and motherhood)',
					value: 'maternity-career-coaching',
					score: 4,
				},
				{
					label: 'Workplace parenting programs (events/support groups...)',
					value: 'parenting-programs',
					score: 3,
				},
				{
					label: 'Events or meetings encouraging fathers to talk about parentality',
					value: 'events-fathers-talk-parentality',
					score: 2,
				},
				{
					label: 'Other benefits',
					value: 'other',
					score: 1,
				},
				{
					label: 'None of the above',
					value: 'none',
					score: 0,
				},
			],
		},
		V3_WLB_BENEFITS_LIST: {
			questionId: 'V3_WLB_BENEFITS_LIST',
			label: 'Which **other benefits** does your company offer employees?',
			sublabel: 'Select all that apply.',
			shortLabel: 'Other employee benefits',
			...multipleInput,
			columns: 2,
			options: [
				{
					label: 'Daycare/Creche support',
					value: 'daycare-creche',
					score: 5,
				},
				{
					label: 'Paid sick leave for family care',
					value: 'paid-leave-family-care',
					score: 3,
				},
				{
					label: 'Compassionate/Bereavement leave',
					value: 'compassionate-leave',
					score: 3,
				},
				{
					label: 'Paid caregiver leave',
					value: 'paid-caregiver-leave',
					score: 3,
				},
				{
					label: 'Gym membership',
					value: 'gym-membership',
					score: 1,
				},
				{
					label: 'Other benefits',
					value: 'other',
					score: 1,
				},
				{
					label: 'None of the above',
					value: 'none',
					score: 0,
				},
			],
		},
		V3_DEI_SAFETY_PSR_MEASUREMENTS: {
			questionId: 'V3_DEI_SAFETY_PSR_MEASUREMENTS',
			label: 'What does your company measure in terms of **psychosocial risks** at work?',
			sublabel: 'Select all that apply.',
			shortLabel: 'PSR measurements',
			...multipleInput,
			options: [
				{
					label: 'Stress level at work (workload constraints, lack of resources, lack of autonomy...)',
					value: 'stress-level',
					score: 5,
				},
				{
					label: 'Internal violence (harassment, conflicts, sexism...)',
					value: 'internal-violence',
					score: 5,
				},
				{
					label: 'External violence (insults, threats, aggressions…)',
					value: 'external-violence',
					score: 3,
				},
				{
					label: 'Other',
					value: 'other',
					score: 1,
				},
				{
					label: 'None of the above',
					value: 'none',
					score: 0,
				},
			],
		},
		V3_DEI_SAFETY_ANTI_HARASSMENT_POLICY: {
			questionId: 'V3_DEI_SAFETY_ANTI_HARASSMENT_POLICY',
			label: 'Does your company have an **anti-harassment policy** in place?',
			shortLabel: 'Anti-harassment policy',
			...yesNoInProgressOptions,
		},
		V3_DEI_SAFETY_ANTI_DISCRIMINATION_POLICY: {
			questionId: 'V3_DEI_SAFETY_ANTI_DISCRIMINATION_POLICY',
			label: 'Does your company have an **anti-discrimination policy** in place?',
			shortLabel: 'Anti-discrimination policy',
			...yesNoInProgressOptions,
		},
		V3_DEI_SAFETY_REPORTING_PROCEDURES: {
			questionId: 'V3_DEI_SAFETY_REPORTING_PROCEDURES',
			label: 'What are the **procedures** for employees to report cases of **workplace bullying, discrimination and harassment?**',
			shortLabel: 'Reporting procedures',
			...singleInput,
			options: [
				{
					label: 'Employees have access to a preventive third party tool for prevention, reporting and resolution',
					value: 'third-party-tool',
					score: 6,
				},
				{
					label: 'Employees can refer to an external designated individual',
					value: 'external-referral',
					score: 5,
				},
				{
					label: 'Employees can refer to an internal designated individual within the team',
					value: 'internal-referral',
					score: 4,
				},
				{
					label: 'Employees are able to submit an anonymous report',
					value: 'anonymous-report',
					score: 3,
				},
				{
					label: 'Employees are asked to report to their manager',
					value: 'report-manager',
					score: 2,
				},
				{
					label: 'Employees are encouraged to consult their colleagues or a buddy for advice',
					value: 'consult-colleague',
					score: 1,
				},
				{
					label: 'Other procedure',
					value: 'other',
					score: 1,
				},
				{
					label: 'None of the above',
					value: 'none',
					score: 0,
				},
			],
		},
		V3_DEI_HEALTH_MENTAL_HEALTH_SERVICES: {
			questionId: 'V3_DEI_HEALTH_MENTAL_HEALTH_SERVICES',
			label: 'Which **mental health services** does your company offer?',
			sublabel: 'Select all that apply.',
			shortLabel: 'Mental health services',
			...multipleInput,
			options: [
				{
					label: 'Free or subsidized access to mental health experts',
					value: 'paid-mental-health-experts',
					score: 3,
				},
				{
					label: 'Workplace wellness surveys/check-ins',
					value: 'workplace-wellness-survey',
					score: 2,
				},
				{
					label: 'Free access to a wellness app',
					value: 'free-wellness-app',
					score: 2,
				},
				{
					label: 'Internal events for mental health',
					value: 'internal-events-mental-health',
					score: 1,
				},
				{
					label: 'Stress management programs',
					value: 'stress-management-programs',
					score: 3,
				},
				{
					label: 'Other services',
					value: 'other',
					score: 1,
				},
				{
					label: 'None of the above',
					value: 'none',
					score: 0,
				},
			],
		},
		V3_DEI_HEALTH_WOMEN_MENTAL_HEALTH_SERVICES: {
			questionId: 'V3_DEI_HEALTH_WOMEN_MENTAL_HEALTH_SERVICES',
			label: 'What type of women-focused mental health services do you offer?',
			sublabel: 'Select all that apply.',
			shortLabel: 'Women mental health services',
			...multipleInput,
			options: [
				{
					label: 'Day off for menstrual leave',
					value: 'menstrual-leave',
					score: 2,
				},
				{
					label: 'Insurance including more coverage for women health affection',
					value: 'insurance-women-health',
					score: 1,
				},
				{
					label: 'Endometriosis awareness program',
					value: 'endometriosis-awareness-program',
					score: 1,
				},
				{
					label: 'Menopause awareness program',
					value: 'menopause-awareness-program',
					score: 2,
				},
				{
					label: 'Reimbursement of fertility treatments',
					value: 'fertility-treatment-reimbursments',
					score: 1,
				},
				{
					label: 'Other services',
					value: 'other',
					score: 1,
				},
				{
					label: 'None of the above',
					value: 'none',
					score: 0,
				},
			],
		},
		V3_DEI_POLICIES_DESIGNATED_DEI: {
			questionId: 'V3_DEI_POLICIES_DESIGNATED_DEI',
			label: 'Is there a **designated DEI lead or team** at your company?',
			shortLabel: 'Designated DEI',
			...yesNoInProgressOptions,
		},
		V3_DEI_POLICIES_PUBLIC_DEI_ENGAGEMENTS: {
			questionId: 'V3_DEI_POLICIES_PUBLIC_DEI_ENGAGEMENTS',
			label: 'Do you **share publicly your engagements** in terms of DEI?',
			shortLabel: 'Public DEI engagements',
			...yesNoInProgressOptions,
		},
		V3_DEI_POLICIES_ALLOCATED_BUDGET: {
			questionId: 'V3_DEI_POLICIES_ALLOCATED_BUDGET',
			label: 'Do you have a **budget allocated** for DEI?',
			shortLabel: 'Allocated budget for DEI',
			...yesNoInProgressOptions,
		},
		V3_DEI_POLICIES_BIAS_EDUCATION: {
			questionId: 'V3_DEI_POLICIES_BIAS_EDUCATION',
			label: 'Do you regularly undergo **unconscious bias education and training?**',
			sublabel: 'Select all that apply.',
			shortLabel: 'Bias education/training',
			...multipleInput,
			options: [
				{
					label: 'All employees',
					value: 'all-employees',
					score: 1,
				},
				{
					label: 'Managers',
					value: 'managers',
					score: 1,
				},
				{
					label: 'C-level executives',
					value: 'c-level-executives',
					score: 1,
				},
				{
					label: 'Recruiters/HR',
					value: 'recruiters-hr',
					score: 1,
				},
				{
					label: 'None of the above',
					value: 'none',
					score: 0,
				},
			],
		},
		V3_DEI_POLICIES_BIAS_EDUCATION_EFFICIENCY_MEASURE: {
			questionId: 'V3_DEI_POLICIES_BIAS_EDUCATION_EFFICIENCY_MEASURE',
			label: 'Do you measure the **efficiency** of your **unconscious bias education/training?**',
			shortLabel: 'Bias education efficiency measure',
			...yesNoOptions,
		},
		V3_DEI_POLICIES_DEI_LEARNING_COMMITMENT: {
			questionId: 'V3_DEI_POLICIES_DEI_LEARNING_COMMITMENT',
			label: 'What does your company\'s **DEI learning and commitment** include?',
			sublabel: 'Select all that apply.',
			shortLabel: 'DEI learning and commitment',
			...multipleInput,
			options: [
				{
					label: 'Regular follow-up sessions after DEI training, such as Lunch and Learns, DEI workshops, and DEI strategy reviews',
					value: 'regular-followup-after-dei-training',
					score: 2,
				},
				{
					label: 'Internal events or meetings focused on DEI-related topics',
					value: 'internal-events-meetings-dei-topics',
					score: 2,
				},
				{
					label: 'Clear DEI policy',
					value: 'dei-policy',
					score: 2,
				},
				{
					label: 'Encourage using preferred pronouns',
					value: 'encourage-pronouns',
					score: 1,
				},
				{
					label: 'Gender inclusive facilities, such as gender-neutral bathrooms',
					value: 'gender-inclusive-facilities',
					score: 1,
				},
				{
					label: 'Use of inclusive language in company emails, internal communications, etc.',
					value: 'inclusive-language-internal',
					score: 1,
				},
				{
					label: 'Inclusive leadership training',
					value: 'inclusive-leadership-training',
					score: 1,
				},
				{
					label: 'Women Employee Resource Groups',
					value: 'women-ergs',
					score: 1,
				},
				{
					label: 'Inclusive onboarding (buddy system, ...)',
					value: 'inclusive-onboarding',
					score: 1,
				},
				{
					label: 'LGBTQ+ inclusion policy',
					value: 'lgbtq-inclusion-policy',
					score: 1,
				},
				{
					label: 'Disability inclusion policy',
					value: 'disability-inclusion-policy',
					score: 1,
				},
				{
					label: 'Junior inclusion policy',
					value: 'junior-inclusion-policy',
					score: 1,
				},
				{
					label: 'Senior inclusion policy',
					value: 'senior-inclusion-policy',
					score: 1,
				},
				{
					label: 'Cultural & social background inclusion policy',
					value: 'cultural-social-policy',
					score: 1,
				},
				{
					label: 'Other commitments',
					value: 'other',
					score: 1,
				},
				{
					label: 'None of the above',
					value: 'none',
					score: 0,
				},
			],
		},
		V3_DEI_RECRUITMENT_UNBIASED_RECRUITMENT_STRATEGIES: {
			questionId: 'V3_DEI_RECRUITMENT_UNBIASED_RECRUITMENT_STRATEGIES',
			label: 'Which **unbiased recruitment strategies** are in place at your company?',
			sublabel: 'Select all that apply.\n\n**Structured interviews**: questions are fact-driven and quantitative, so that the interviewer is able to objectively identify whether a candidate truly has the right skills for the role they are hiring for. An example of structured interview questions is “What is your average sales cycle in your current role?”. \nSource: https://www.pillar.hr/unbiased-interview-questions',
			shortLabel: 'Unbiased recruitment strategies',
			...multipleInput,
			options: [
				{
					label: 'Inclusive language in job descriptions',
					value: 'inclusive-language-job-descs',
					score: 1,
				},
				{
					label: 'Structured interviews',
					value: 'structured-interviews',
					score: 1,
				},
				{
					label: 'Anonymous candidate screening',
					value: 'anonymous-candidate-screening',
					score: 1,
				},
				{
					label: 'Diverse recruitment team',
					value: 'diverse-recruitment-team',
					score: 1,
				},
				{
					label: 'Non-biased recruitment training',
					value: 'non-biased-recruitment-training',
					score: 1,
				},
				{
					label: 'Diverse pipeline',
					value: 'diverse-pipeline',
					score: 1,
				},
				{
					label: 'Inclusive and authentic employer brand',
					value: 'inclusive-employer-brand',
					score: 1,
				},
				{
					label: 'Increasing the representation of women in leadership positions',
					value: 'increasing-women-leadership',
					score: 1,
				},
				{
					label: 'Providing feedback to rejected candidates',
					value: 'providing-feedback-rejected-candidates',
					score: 1,
				},
				{
					label: 'Inclusive assessment',
					value: 'inclusive-assessment',
					score: 1,
				},
				{
					label: 'Other strategies',
					value: 'other',
					score: 1,
				},
				{
					label: 'None of the above',
					value: 'none',
					score: 0,
				},
			],
		},
		V3_DEI_RECRUITMENT_PRIORITIES: {
			questionId: 'V3_DEI_RECRUITMENT_PRIORITIES',
			label: 'What does your company **prioritise when recruiting?**',
			shortLabel: 'Recruitment priorities',
			...singleInput,
			align: 'vertical',
			width: '320px',
			options: [
				{
					label: 'Educational background',
					value: 'educational-background',
					score: 0,
				},
				{
					label: 'Technical skills',
					value: 'technical-skills',
					score: 1,
				},
				{
					label: 'Work experience',
					value: 'work-experience',
					score: 2,
				},
				{
					label: 'Soft skills',
					value: 'soft-skills',
					score: 3,
				},
				{
					label: 'Transferable skills',
					value: 'transferable-skills',
					score: 4,
				},
				{
					label: 'Other priority',
					value: 'other',
					score: 1,
				},
			],
		},
		V3_DEI_RECRUITMENT_ATS_PRESENCE: {
			questionId: 'V3_DEI_RECRUITMENT_ATS_PRESENCE',
			label: 'Do you have an **ATS?**',
			sublabel: '**ATS**: Applicant Tracking System',
			shortLabel: 'ATS presence',
			...yesNoInProgressOptions,
		},
		V3_DEI_RECRUITMENT_ATS_DEI_FEATURES: {
			questionId: 'V3_DEI_RECRUITMENT_ATS_DEI_FEATURES',
			label: 'Does your ATS offer **DEI data tracking features?**',
			shortLabel: 'ATS DEI features',
			...yesNoInProgressOptions,
		},
		V3_DEI_RECRUITMENT_JUNIOR_HIRING: {
			questionId: 'V3_DEI_RECRUITMENT_JUNIOR_HIRING',
			label: 'Does your company hire **junior and/or entry-level** candidates?',
			shortLabel: 'Junior hiring',
			...yesNoOptions,
		},
		V3_DEI_RECRUITMENT_SENIOR_HIRING: {
			questionId: 'V3_DEI_RECRUITMENT_SENIOR_HIRING',
			label: 'Does your company hire **senior (+50)** candidates?',
			shortLabel: 'Senior hiring',
			...yesNoOptions,
		},
		V3_DEI_RECRUITMENT_CAREER_CHANGE_HIRING: {
			questionId: 'V3_DEI_RECRUITMENT_CAREER_CHANGE_HIRING',
			label: 'Does your company hire candidates that have recently operated a **career change?**',
			shortLabel: 'Career change hiring',
			...yesNoOptions,
		},
	})
	.map(([questionId, question]) => ({
		[questionId]: new SurveyQuestionModel(question),
	}))
	.reduce((prev, cur) => ({ ...(prev || {}), ...(cur || {}) }), {});

export default QUESTIONS;
