import { isStringTypeValidator } from '../../../utils/isStringTypeValidator';
import { innerObjPropExtractor } from '../../property/property-extractor';
import { innerObjPropMapper } from '../../property/property-mapper';

const USER_PROPERTY_AUTH_STATE_KEY = 'auth-state';

const USER_PROPERTY_AUTH_STATE_FREE = false;

const USER_PROPERTY_AUTH_STATE_ANSWER_REGISTERED = 'AUTH_REGISTERED';
const USER_PROPERTY_AUTH_STATE_ANSWER_COMPLETE = 'AUTH_COMPLETE';

const USER_PROPERTY_AUTH_STATE_OPTIONS = [
	{ value: USER_PROPERTY_AUTH_STATE_ANSWER_REGISTERED },
	{ value: USER_PROPERTY_AUTH_STATE_ANSWER_COMPLETE },
];

const customValidator = (value) => USER_PROPERTY_AUTH_STATE_OPTIONS.map((o) => o.value).includes(value);

const USER_PROPERTY_AUTH_STATE_VALIDATORS = [
	isStringTypeValidator,
	customValidator,
];

const USER_PROPERTY_AUTH_STATE_FIELD = 'auth.authState';
const USER_PROPERTY_AUTH_STATE_MAPPER = innerObjPropMapper(USER_PROPERTY_AUTH_STATE_FIELD);
const USER_PROPERTY_AUTH_STATE_EXTRACTOR = innerObjPropExtractor(USER_PROPERTY_AUTH_STATE_FIELD);

export default {
	USER_PROPERTY_AUTH_STATE_ANSWER_REGISTERED,
	USER_PROPERTY_AUTH_STATE_ANSWER_COMPLETE,
	key: USER_PROPERTY_AUTH_STATE_KEY,
	USER_PROPERTY_AUTH_STATE_KEY,
	validators: USER_PROPERTY_AUTH_STATE_VALIDATORS,
	USER_PROPERTY_AUTH_STATE_VALIDATORS,
	options: USER_PROPERTY_AUTH_STATE_OPTIONS,
	USER_PROPERTY_AUTH_STATE_OPTIONS,
	free: USER_PROPERTY_AUTH_STATE_FREE,
	USER_PROPERTY_AUTH_STATE_FREE,
	mapper: USER_PROPERTY_AUTH_STATE_MAPPER,
	USER_PROPERTY_AUTH_STATE_MAPPER,
	extractor: USER_PROPERTY_AUTH_STATE_EXTRACTOR,
	USER_PROPERTY_AUTH_STATE_EXTRACTOR,
	field: USER_PROPERTY_AUTH_STATE_FIELD,
	USER_PROPERTY_AUTH_STATE_FIELD,
};
