import { Input } from 'antd';
import FormItem from '../../../app/form/FormItem';

const { TextArea } = Input;

const InputMeta = ({
					   label, name, extra = '~60 characters max', isTextarea = false, maxLength = 60, style = {},
					   ...otherProps
				   }) => {
	const Component = isTextarea ? TextArea : Input;
	return (
		<FormItem
			extra={extra}
			label={label}
			name={name}
			defaultContainer="div"
			{...otherProps}
			styles={{
				rootContainer: { display: 'flex', flexDirection: 'row', width: '100%' },
				label: { marginTop: 6, width: 140 },
				container: { width: '100%' },
			}}
		>
			<Component
				showCount={
					{
						formatter: ({ count }) => {
							return `${count} / ${maxLength}`;
						},
					}
				}
				style={{ ...style }}/>
		</FormItem>
	);
};

export default InputMeta;
