import { PERMS_GRANT_ADMIN } from '../menu.utils';
import StaffAtsListMenu from './ats/StaffAtsListMenu';
import StaffAtsBatchesMenu from './ats/StaffAtsBatchesMenu';
import StaffAtsPipelinesMenu from './ats/StaffAtsPipelinesMenu';
import StaffAtsArchiveReasonsMenu from './ats/StaffAtsArchiveReasonsMenu';
import MenuBase from '../MenuBase';
import { ApiOutlined } from '@ant-design/icons';

const StaffAtsIcon = ApiOutlined;

class StaffAtsMenu extends MenuBase {
	static props = {
		name: 'staff-ats',
		label: 'ATS',
		icon: <StaffAtsIcon />,
		iconLegacy: '🔐',
		children: [
			(new StaffAtsListMenu()).build(),
			(new StaffAtsBatchesMenu()).build(),
			(new StaffAtsArchiveReasonsMenu()).build(),
			(new StaffAtsPipelinesMenu()).build(),
		],
		perms: PERMS_GRANT_ADMIN,
	};

	constructor() {
		super(StaffAtsMenu.props);
	}
}

export default StaffAtsMenu;
