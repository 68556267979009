import tagService from '../services/tag';
import { TAGS_LOADED } from './types';
import { actionWithLoader } from './utils';
import { localDebug } from '../utils/LocalDebug';

export const getAllTags = () => {
	return actionWithLoader(async (dispatch) => {
		try {
			const { data } = await tagService.findAll();
			dispatch({
				type: TAGS_LOADED,
				tags: data.tags,
			});
		} catch (error) {
			localDebug(__filename.split('/').shift(), error);
		}
	});
};

export const add = (data) => {
	return async (dispatch) => {
		try {
			await tagService.create(data);
			dispatch(getAllTags());
		} catch (error) {
			localDebug(__filename.split('/').shift(), error);
		}
	};
};

export const update = (tagId, data) => {
	return async (dispatch) => {
		try {
			await tagService.update(tagId, data);
			dispatch(getAllTags());
		} catch (error) {
			localDebug(__filename.split('/').shift(), error);
		}
	};
};

export const remove = (tag) => {
	return async (dispatch) => {
		try {
			await tagService.remove(tag);
			dispatch(getAllTags());
		} catch (error) {
			localDebug(__filename.split('/').shift(), error);
		}
	};
};
