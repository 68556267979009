import { useTheme } from '@emotion/react';
import { useState } from 'react';
import Table from '../app/table/Table';
import { useAuthContext } from '../../hooks/useAuthContext';
import TableColumns from '../app/table/TableColumns';
import { Button, Space, Tag } from 'antd';
import { useSelector } from 'react-redux';
import { localDebug } from '../../utils/LocalDebug';
import { getAtsPipelines } from '../../reducers/app';
import CompanyService from '../../services/company';
import AtsArchiveReasonMappingEditPopover from './AtsArchiveReasonMappingEditPopover';
import { documentId } from '../../utils/common';

const AtsArchiveReasonTable = (
	{
		companyAccess,
		isRefresh = 0,
		...props
	},
) => {
	const theme = useTheme();

	const { isStaffView } = useAuthContext();
	const atsPipelines = useSelector(getAtsPipelines);
	const [openAtsStageEdit, setOpenAtsStageEdit] = useState(false);
	const [atsStageEditInitialValues, setAtsStageEditInitialValues] = useState({});
	const [isRefreshDataLayout, setIsRefreshDataLayout] = useState(0);

	const refreshTable = () => {
		setIsRefreshDataLayout((prev) => prev + 1);
	};

	const handleEditStage = (row) => {
		localDebug('handleEditStage', row, atsPipelines);
		setOpenAtsStageEdit(true);
		setAtsStageEditInitialValues(row);
	};

	const onAtsArchiveReasonMappingEditCloseWithRefresh = (data) => {
		localDebug('onAtsArchiveReasonMappingEditCloseWithRefresh', { data });
		setOpenAtsStageEdit(false);
		refreshTable();
	};
	const onAtsArchiveReasonMappingEditSubmit = async (data) => {
		localDebug('onAtsArchiveReasonMappingEditSubmit', { data });
		const result = await CompanyService.setAtsArchiveReasonMapping(data.archiveReason, documentId(data));
		localDebug('onAtsArchiveReasonMappingEditSubmit', { result });
		setOpenAtsStageEdit(false);
		refreshTable();
	};
	const onAtsArchiveReasonMappingEditReset = (data) => {
		localDebug('onAtsArchiveReasonMappingEditReset', { data });
		setOpenAtsStageEdit(false);
	};

	const labelCol = {
		title: 'Stage',
		dataIndex: 'text',
		render: (value, row) => <Space direction='vertical' size={6}>
			<b>{row.text}</b>
			<code style={{ fontSize: 10 }}>{documentId(row)}</code>
		</Space>,
		...TableColumns.columnMinWidth(120),
	};

	const textCol = {
		title: 'Text',
		dataIndex: 'text',
		render: (value, row) => <b>{value}</b>,
		...TableColumns.columnMinWidth(140),
	};

	const idCol = {
		title: 'ID',
		dataIndex: 'id',
		render: (value, row) => <code style={{ fontSize: 10 }}>{value}</code>,
		...TableColumns.columnMinWidth(200),
	};

	const statusCol = {
		title: 'Status',
		dataIndex: 'status',
		...TableColumns.columnMinWidth(220),
	};

	const mappingCol = {
		title: 'Reason mapping',
		dataIndex: 'archiveReason',
		render: (value, row) => row.archiveReason
			&& <Tag color='cyan' icon={row.archiveReason?.icon}>&nbsp;<b>{row.archiveReason?.label}</b></Tag>,
		...TableColumns.columnMinWidth(140),
	};

	const editCol = {
		title: 'Edit mapping',
		dataIndex: 'actions',
		render: (value, row) => <Button onClick={() => { handleEditStage(row); }}>Edit</Button>,
		...TableColumns.columnMinWidth(80),
	};
	const jsonCol = TableColumns.jsonColumn(theme);

	const columns = isStaffView
		? [
			jsonCol,
			// labelCol,
			textCol,
			idCol,
			mappingCol,
			editCol,
		]
		: [
			labelCol,
			mappingCol,
		];

	return (companyAccess && <>
		<Table
			path={`access/${documentId(companyAccess)}/ats-archive-reasons`}
			columns={columns}
			isRefresh={isRefresh + isRefreshDataLayout}
			withPagination={false}
			{...props}
		/>

		<AtsArchiveReasonMappingEditPopover
			open={openAtsStageEdit}
			initialValues={atsStageEditInitialValues}
			onCloseWithRefresh={onAtsArchiveReasonMappingEditCloseWithRefresh}
			onSubmit={onAtsArchiveReasonMappingEditSubmit}
			onReset={onAtsArchiveReasonMappingEditReset}
		/>
	</>
	);
};

export default AtsArchiveReasonTable;
