/* eslint-disable react-hooks/exhaustive-deps */
import { Tag as AntTag } from 'antd';
import { FaTags } from 'react-icons/fa';

const Tag = ({ slug, ...props }) => {
	const { color, label } = slug && slug !== ''
		? { color: '#0000ff', label: slug.split(':').pop() }
		: { color: '#999999', label: 'none' };
	return (
		<AntTag
			icon={<FaTags style={{ opacity: 0.6, margin: '0 2px -2px 0' }}/>}
			style={{
				flex: 1,
				justifyContent: 'center',
				alignItems: 'center',
				background: 'transparent',
				border: 'none',
				padding: '0 5px 1px',
				fontSize: '11px',
				lineHeight: '17px',
				color,
				margin: '0 1px 1px 0',
			}}
			{...props}
		>
			{label}
		</AntTag>
	);
};

export default Tag;
