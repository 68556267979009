import {
	Button, Dropdown, Menu, Popover, Space,
} from 'antd';
import { useState } from 'react';
import {
	FaCommentDots, FaEdit, FaEllipsisH, FaFolder, FaTrash,
} from 'react-icons/fa';
import moment from 'moment';
import Link from '../app/Link';
import Table from '../app/table/Table';
import UserAvatar from '../user/widgets/UserAvatar';
import theme from '../../theme';
import SourcingActionPopover from './SourcingActionPopover';
import {
	sourcingSources,
	SOURCING_ACTION_STATUS,
	SOURCING_ACTION_STEP,
	SOURCING_ACTION_COMMENT,
	STANDBY_STATUS,
	SOURCING_ACTION_STAGE,
	SOURCING_ACTION_CONTACT,
	SOURCING_ACTION_ARCHIVE,
	SOURCING_ACTION_MANAGER,
} from '../../constants/constant';
import { COMPANY_RECRUITER_LOG } from '../../constants/company-recruiter-log';
import {
	isCurrentStep, getPreviousStep, writeLog, documentId,
} from '../../utils/common';
import { localDebug } from '../../utils/LocalDebug';
import ActionButton from '../app/table/ActionButton';
import TableColumns from '../app/table/TableColumns';
import ModalConfirmAction from './ModalConfirmAction';
import { useAuthContext } from '../../hooks/useAuthContext';
import MessagePopover from '../messages/MessagePopover';
import { addMessage } from '../../actions/messages';
import UserAvatarList from '../user/widgets/UserAvatarList';
import SourcingStageEditModal from './SourcingStageEditModal';
import UserDrawerProfile from '../user/UserDrawerProfile';
import { submitMessage, treatSourcingActionToDb } from '../../actions/sourcings';
import UserContacter from '../user/buttons/UserContacter';
import SourcingJobPopover from './SourcingJobPopover';
import SourcingArchiveModal from './SourcingArchiveModal';
import SourcingCommentPopover from './SourcingCommentPopover';
import SourcingManagerDropdown from './SourcingManagerDropdown';
import { writeRecruiterLog } from '../../actions/logging';
import SourcingHistory from './SourcingHistory';

const SourcingTable = (
	{
		dataSource,
		path = '/sourcing',
		filterOptions = {},
		isRefresh = 0,
		onEditSourcing,
		onDeleteSourcing,
		onDeleteStep,
		onSearchDataColumn,
		isEditor,
		withClientSidePagination,
		viaProfile = false,
		viaJob = false,
		withHistory = false,
		withDynamicSearch = true,
		withTableHeader = true,
		documentReadersIds,
		mode = 'sourcings',
		onStageSelected,
		initialStage,
	},
) => {
	const { isStaffView, isAdmin, currentUser } = useAuthContext();

	const [tableFilterOptions, setTableFilterOptions] = useState(filterOptions);
	const [openCommentPopover, setOpenCommentPopover] = useState(false);
	const [initialValuesCommentForm, setInitialValuesCommentForm] = useState({ action: SOURCING_ACTION_COMMENT });
	const [openActionPopover, setOpenActionPopover] = useState(false);
	const [openMessagePopover, setOpenMessagePopover] = useState(false);
	const [initialValuesActionForm, setInitialValuesActionForm] = useState({ action: SOURCING_ACTION_STATUS });
	const [initialValuesMessageForm, setInitialValuesMessageForm] = useState({});
	const [openConfirmAction, setOpenConfirmAction] = useState(false);
	const [openArchiveModal, setOpenArchiveModal] = useState(false);
	const [archiveInitialValues, setArchiveInitialValues] = useState(false);
	const [openStageEditModal, setOpenStageEditModal] = useState(false);
	const [stageEditInitialValues, setStageEditInitialValues] = useState(false);
	const [openSourcingEditJob, setOpenSourcingEditJob] = useState(false);
	const [sourcingEditJobInitialValues, setSourcingEditJobInitialValues] = useState(false);
	const [currentAction, setCurrentAction] = useState(null);
	const [actionConfirmInitialValues, setActionConfirmInitialValue] = useState({});
	const [openProfile, setOpenProfile] = useState(false);
	const [activeTab, setActiveTab] = useState(null);
	const [userSelected, setUserSelected] = useState(null);
	const [isRefreshDataLayout, setIsRefreshDataLayout] = useState(0);

	const handleAddAction = (row, isReadOnly = false) => {
		writeLog('Sourcing: open modal add action (handleAddAction)', { sourcingId: documentId(row), isReadOnly });
		setInitialValuesActionForm((prev) => ({
			...prev,
			...row,
			isReadOnly,
			author: documentId(currentUser), // add current user
		}));
		setOpenActionPopover(true);
	};

	const handleAddComment = (row, isReadOnly = false) => {
		writeLog('Sourcing: open modal add comment (handleAddComment)', { sourcingId: documentId(row), isReadOnly });
		setInitialValuesCommentForm((prev) => ({
			...prev,
			...row,
			isReadOnly,
			author: documentId(currentUser), // add current user
			creator: documentId(currentUser), // add current user
		}));
		setOpenCommentPopover(true);
	};

	const handleMessageAction = (row, isReadOnly = false) => {
		setInitialValuesMessageForm({
			sourcingId: documentId(row),
			userToId: documentId(row.user),
			userTo: row.user,
			viaProfile: true,
			companyIds: !isStaffView ? documentId(row.company) : null,
		});
		setOpenMessagePopover(true);
	};

	const handleMessageSubmitted = async (row, values) => {
		localDebug('handleMessageSubmitted', { row, values });
		setIsRefreshDataLayout((prev) => prev + 1);
	};

	const handleMessageSubmitTest = async (values, title = 'Send a message') => {
		const sourcing = await submitMessage(values, currentUser);
		if (sourcing) onEditSourcing(sourcing, 'add message');
		setOpenMessagePopover(false);
	};

	const handleMessageSubmit = async (values, title = 'Send a message') => {
		const { sourcingId, ...valuesToDb } = values || {};
		const message = await addMessage(valuesToDb);
		localDebug('handleMessageSubmit', { mode, sourcingId, message });
		if (sourcingId) {
			const action = SOURCING_ACTION_CONTACT;
			const history = {
				action,
				'creator': documentId(currentUser),
				'postedAt': moment(),
				'author': documentId(currentUser),
				'payload.userMessageId': documentId(message),
			};

			const toDb = treatSourcingActionToDb({
				id: sourcingId,
				managerId: documentId(currentUser),
				...history,
			}, currentUser);

			const actionTitle = `add ${action}`;
			onEditSourcing(toDb, actionTitle);
		}
		setOpenMessagePopover(false);
	};

	// ----------------------------------- //
	// --------- handle select job ------- //
	// ----------------------------------- //
	const handleSubmitSelectJob = (values) => {
		onEditSourcing(values);
		setOpenSourcingEditJob(false);
	};

	// ----------------------------------- //
	// --------- handle add action ------- //
	// ----------------------------------- //
	const handleSubmitAction = (values, action) => {
		const actionTitle = `add ${action}`;
		onEditSourcing(values, actionTitle);
		setOpenActionPopover(false);
		setOpenConfirmAction(false);
	};

	const handleSubmitComment = (values, action) => {
		const actionTitle = `add ${action}`;
		onEditSourcing(values, actionTitle);
		setOpenCommentPopover(false);
	};

	const handleSubmitStage = (values, action) => {
		localDebug('handleSubmitStage', { values, action });
		const actionTitle = `add ${action}`;
		onEditSourcing(values, actionTitle);
		setOpenStageEditModal(false);
	};

	const handleSubmitArchive = (values, action) => {
		const actionTitle = `add ${action}`;
		onEditSourcing(values, actionTitle);
		// company recruiter log
		writeRecruiterLog(COMPANY_RECRUITER_LOG.ARCHIVE, {
			userTargetId: archiveInitialValues?.userId,
			sourcingTargetId: documentId(archiveInitialValues),
		});
		setOpenArchiveModal(false);
	};

	const getDisabledStyle = (row) => {
		return {
			// ...(row?.status === STOP_STATUS && { opacity: 0.5 })
		};
	};

	const handleDeleteStep = (id, step, history) => {
		const isCurrent = isCurrentStep(history, step);
		if (isCurrent) {
			// ----------------------------- //
			// ------- previous step ------- //
			// ----------------------------- //
			const previousStep = getPreviousStep(history, step);
			let sourcing = {
				step: previousStep ? (previousStep?.body || previousStep?.payload?.value) : null,
				updatedStepAt: previousStep ? previousStep.createdAt : null,
			};

			// set status to standby when delete all recruitment step
			if (!previousStep) {
				const postedAt = new Date();
				const history = {
					action: SOURCING_ACTION_STATUS,
					payload: {
						value: STANDBY_STATUS,
					},
					postedAt: new Date(),
					author: documentId(currentUser),
				};
				sourcing = {
					...sourcing,
					history: [history],
					status: STANDBY_STATUS,
					updatedStatusAt: postedAt,
				};
			}
			// also update the step and stepUpdateAt field in sourcingtable with previous state
			onDeleteStep(id, step, sourcing);
			return;
		}
		// delete only step
		onDeleteStep(id, step);
	};

	const handleDeleteSourcing = (sourcing) => {
		onDeleteSourcing(sourcing);
	};

	const renderSourceTag = (value, row) => {
		const { label, icon } = (((sourcingSources.filter((s) => s.value === value) || [])[0]) || {});
		return <Space direction="horizontal" size={4}
					  style={{
				padding: '3px 6px', background: '#eeeeff', borderRadius: 4, ...getDisabledStyle(row),
			}}
					  align='center'>
			<div style={{ fontSize: 16, color: '#0000ff', marginBottom: -4 }}>{icon}</div>
			<div style={{ whiteSpace: 'nowrap', fontWeight: 'bold' }}>{label}</div>
			{/* <Tag color={color} icon={icon} style={{fontWeight: 'bold'}}>{label}</Tag> */}
		</Space>;
	};

	const handleOpenConfirmAction = (row, action) => {
		writeLog('Sourcing: handleOpenConfirmAction ', { sourcingId: documentId(row), action });
		// current value
		const currentValue = row[action];
		setOpenConfirmAction(true);
		setCurrentAction({ value: currentValue, type: action });
		setActionConfirmInitialValue(row);
	};

	const handleOpenStageEditModal = (row, action) => {
		writeLog('Sourcing: handleOpenStageEditModal ', { sourcingId: documentId(row), action });
		const currentValue = row[action];
		setOpenStageEditModal(true);
		setCurrentAction({ value: currentValue, type: action });
		setStageEditInitialValues(row);
	};

	const handleOpenArchiveModal = (row, action) => {
		writeLog('Sourcing: handleOpenArchiveModal ', { sourcing: documentId(row), action });
		const currentValue = !!row.isArchived;
		setOpenArchiveModal(true);
		setCurrentAction({ value: currentValue, type: action });
		setArchiveInitialValues(row);
	};

	const handleOpenUserProfile = (user, tab) => {
		writeLog(`Sourcing: open user profile - tab:  ${tab}`, { userId: documentId(user), slug: user?.slug, tab });
		setUserSelected(user);
		setActiveTab(tab);
		setOpenProfile(true);
	};

	const handleCloseProfile = () => {
		setUserSelected(null);
		setActiveTab(null);
		setOpenProfile(false);
	};

	const handleJobSelection = (row) => {
		writeLog('Sourcing: handleJobSelection ', { sourcingId: documentId(row), jobId: documentId(row?.job) });
		setSourcingEditJobInitialValues(row);
		setOpenSourcingEditJob(true);
	};

	const handleSubmitManager = (sourcing, managerId, previousManagerId) => {
		if (documentId(sourcing) && managerId) {
			const action = SOURCING_ACTION_MANAGER;
			const history = {
				action,
				'creator': documentId(currentUser),
				'author': documentId(currentUser),
				'payload.managerId': managerId,
				'payload.previousManagerId': sourcing.managerId,
			};

			const toDb = treatSourcingActionToDb({
				id: documentId(sourcing),
				managerId,
				...history,
			}, currentUser);

			const actionTitle = `add ${action}`;
			onEditSourcing(toDb, actionTitle);
		}
	};

	const handleRefresh = () => {
		setIsRefreshDataLayout((prev) => prev + 1);
	};

	const userStubCol = TableColumns.userStubColumn(theme, {
		title: 'Talent',
		fixed: 'left',
		withDropdown: true,
		withPreview: false,
		onRefresh: handleRefresh,
	});

	const jobStubCol = TableColumns.jobStubColumn(theme, {
		dataIndex: 'job',
		render: (value, row) => <Space direction='vertical'>
			{TableColumns.jobStubColumn(theme, {
				notProvided: (value, row) => <span style={{
					fontStyle: 'italic', color: theme.color.darkgrey,
				}}>No job linked yet.&nbsp;</span>,
				value: (value, row) => (isStaffView && row.job ? { ...row.job, company: row.company } : row.job),
			}).render(value, row)}
			<Link onClick={() => handleJobSelection(row)}>Edit job</Link>
		</Space>,
		...TableColumns.columnFixedWidth(240),
	});

	const creatorCol = TableColumns.userAvatarColumn(theme, {
		title: 'Creator', dataIndex: 'creator', removeInColumnFilter: true,
	});
	const managerCol = TableColumns.userAvatarColumn(theme, {
		title: 'Manager',
		dataIndex: 'manager',
		removeInColumnFilter: true,
		render: (value, row) => <SourcingManagerDropdown
			sourcing={row}
			onSelect={handleSubmitManager}
		>
			{row.manager && TableColumns.userAvatarColumn(theme).render(value, row)}
		</SourcingManagerDropdown>,
	});
	const companyLogoCol = TableColumns.companyLogoColumn(theme, {
		title: 'Client', removeInColumnFilter: true,
	});
	const sourcingSourceCol = {
		title: 'Origin',
		dataIndex: 'source',
		isFilter: false,
		sorter: true,
		removeInColumnFilter: true,
		align: 'center',
		render: renderSourceTag,
		...TableColumns.columnMinWidth(150),
	};
	const sourcingStageCol = TableColumns.sourcingStageColumn(theme, {
		renderStyle: (row) => ({ ...getDisabledStyle(row) }),
		withButton: isEditor,
		type: 'text',
		removeInColumnFilter: true,
		onClick: (row) => handleOpenStageEditModal(row, SOURCING_ACTION_STAGE),
	});
	const sourcingStatusCol = TableColumns.sourcingStatusColumn(theme, {
		renderStyle: (row) => ({ ...getDisabledStyle(row) }),
		withButton: isEditor,
		type: 'text',
		removeInColumnFilter: true,
		onClick: (row) => handleOpenConfirmAction(row, SOURCING_ACTION_STATUS),
	});
	const sourcingStepCol = TableColumns.sourcingStepColumn(theme, {
		renderStyle: (row) => ({ ...getDisabledStyle(row) }),
		withButton: isEditor,
		type: 'text',
		removeInColumnFilter: true,
		onClick: (row) => handleOpenConfirmAction(row, SOURCING_ACTION_STEP),
	});
	const authorsCol = {
		title: 'Authors',
		dataIndex: 'history.author',
		removeInColumnFilter: true,
		render: (value, row) => {
			const { history } = row;
			const authors = (history || [])
				.map((action) => action.author)
				.filter((h) => h)
				.reduce((prev, cur) => [
					...prev,
					...!prev.find(({ id }) => id?.toString?.() === documentId(cur)?.toString?.())
						? [cur]
						: [],
				], []);
			return <UserAvatarList users={authors} overflow={-12}/>;
		},
		...TableColumns.columnMinWidth(100),
	};
	const recruitersCol = {
		title: 'Recruiters',
		dataIndex: 'recruiters',
		removeInColumnFilter: true,
		render: (value, row) => (value && <Space direction="horizontal">
			{value.map((recruiter, index) => (<UserAvatar
				key={index}
				user={recruiter}
				title={`${recruiter?.firstName} ${recruiter?.lastName}`}
				style={index > 0 ? { marginLeft: '-14px' } : null}/>
			))}
		</Space>),
		...TableColumns.columnMinWidth(100),
	};
	const commentCol = {
		title: 'Comment',
		dataIndex: 'comment',
		removeInColumnFilter: true,
		align: 'center',
		render: (value, row) => (value?.author
			&& <Popover
				content={<Space
					style={{ maxWidth: '400px', alignItems: 'flex-start' }}
					direction='horizontal'
				>
					<UserAvatar
						user={value.author}
						title={`${value.author.firstName} ${value.author.lastName}`}
					/>
					<span>{value.payload?.text}</span>
				</Space>}
				title={<strong>Latest comment</strong>}
			>
				<Button type='link' icon={<FaCommentDots style={{ marginBottom: -2 }}/>}/>
			</Popover>
		),
		...TableColumns.columnMinWidth(90),
	};
	const detailsCol = {
		title: 'Details',
		dataIndex: 'details',
		removeInColumnFilter: true,
		...TableColumns.columnMinWidth(100),
	};
	const dateProps = {
		dateFormat: 'DD/MM/YY',
		withFromNow: false,
		withToolip: true,
		align: 'center',
		styles: { date: { fontSize: 12, color: theme.color.darkgrey } },
		...TableColumns.columnFixedWidth(80),
	};
	const createdAtCol = TableColumns.createdAtColumn(theme, dateProps);
	const updatedAtCol = TableColumns.updatedAtColumn(theme, dateProps);

	const actionsCol = {
		title: 'Actions',
		dataIndex: ['actions'],
		// fixed: 'right',
		render: (_, row) => {
			const menuComment =				<Menu.Item key='comment' icon={<FaCommentDots/>} onClick={() => handleAddComment(row)}>
					Add a comment
			</Menu.Item>;
			const menuStage =				<Menu.Item key='stage' icon={<FaEdit/>}
						   onClick={() => handleOpenStageEditModal(row, SOURCING_ACTION_STAGE)}>
					Edit stage
			</Menu.Item>;
			const menuArchive = !row.isArchived
				&& <Menu.Item key='archive' icon={<FaFolder/>}
						   onClick={() => handleOpenArchiveModal(row, SOURCING_ACTION_ARCHIVE)}>
					Archive
				</Menu.Item>;
			const menuDelete =				<Menu.Item icon={null}>
				<ActionButton
					icon={<><FaTrash style={{ marginBottom: -2 }}/> <strong>Delete</strong></>}
					styles={{ color: theme.color.orange, width: '100%' }}
					title="Are you sure you want to delete this row？"
					okText="Yes" cancelText="No"
					onConfirm={() => handleDeleteSourcing(row)}
					withConfirm
				/>
			</Menu.Item>;

			const dropdownMenu =				<Menu style={{ fontSize: 12, padding: 0, borderRadius: 0 }}>
				{menuComment}
				{menuStage}
				{menuArchive}
				{menuDelete}
			</Menu>;

			return <Space direction='horizontal' size={4}>
				<UserContacter
					title="" user={row?.user}
					withTooltip={true}
					onComplete={(values) => handleMessageSubmitted(row, values)}
				/>
				{/* <SourcingArchiver */}
				{/*    title="" */}
				{/*    sourcing={row} */}
				{/*    withTooltip={true} */}
				{/*    // onStart={(values) => handleOpenArchiveModal(row, SOURCING_ACTION_ARCHIVE)} */}
				{/*    onComplete={handleRefresh} */}
				{/* /> */}
				<Dropdown
					overlay={dropdownMenu}
					arrow={true}
					placement="topLeft"
					overlayStyle={{ minWidth: 'unset' }}
					trigger={['click']}
				>
					<div>
						<ActionButton icon={<FaEllipsisH/>} tooltipTitle=""/>
					</div>
				</Dropdown>
			</Space>;
		},
		...TableColumns.columnMinWidth(60),
	};
	const messagesToCol = TableColumns.messagesToCountColumn(theme, {
		value: (value, row) => row?.user?.messagesToCount,
		// onClick: (value, row) => handleOpenUserProfile(row?.user, 'messagesTo'),
	});
	const archiveReasonsCol = TableColumns.sourcingArchiveReasonsColumn(theme);

	const hasSourcingStageCol = !['archived', 'suggestedBy50'].includes(mode);

	const sourcingStubCol = TableColumns.sourcingStubLiteColumn(theme, {
		title: 'Talent',
		sorter: false,
		fixed: 'left',
		// removeInColumnFilter: true,
		...isStaffView ? { fixed: 'left' } : {},
		onRefresh: handleRefresh,
		onSelectJob: handleJobSelection,
		withUserPreviewButtons: false,
	});

	const jsonCol = TableColumns.jsonColumn(theme);

	const columns = isStaffView
		? [
			...isAdmin ? [
				...!viaJob && !viaProfile ? [sourcingStubCol] : [],
				...viaJob ? [userStubCol] : [],
				...viaProfile ? [jobStubCol] : [],
				companyLogoCol,
				// ...!viaJob ? [jobStubCol] : [],
			] : [
				...!viaProfile ? [userStubCol] : [],
				companyLogoCol,
				...!viaJob ? [jobStubCol] : [],
			],
			jsonCol,
			// ...mode === 'sourcings' ? [sourcingSourceCol]: [],
			sourcingSourceCol,
			sourcingStageCol,
			// sourcingStepCol,
			// sourcingStatusCol,
			// ...mode === 'archived' ? [sourcingSourceCol]: [],
			...mode === 'archived' ? [archiveReasonsCol] : [],
			creatorCol,
			managerCol,
			// authorCol,
			// authorsCol,
			// recruitersCol,
			commentCol,
			// detailsCol,
			actionsCol,
			messagesToCol,
			createdAtCol,
			updatedAtCol,
		]
		: [
			...!viaProfile ? [userStubCol] : [],
			...!viaJob ? [jobStubCol] : [],
			// ...!viaJob && !viaProfile ? [sourcingStubCol]: [],
			// ...viaJob ? [userStubCol]: [],
			// ...viaProfile ? [jobStubCol]: [],
			// ...mode === 'sourcings' ? [sourcingSourceCol]: [],
			...hasSourcingStageCol ? [sourcingStageCol] : [],
			...mode === 'archived' ? [archiveReasonsCol] : [],
			// sourcingStepCol,
			// sourcingStatusCol,
			// creatorCol,
			managerCol,
			// authorCol,
			// authorsCol,
			// recruitersCol,
			// commentCol,
			actionsCol,
			// messagesToCol,
			createdAtCol,
			updatedAtCol,
		];

	return (
		<>
			<Table
				path={path}
				columns={columns}
				bordered={false}
				filterOptions={filterOptions}
				onSearchDataColumn={onSearchDataColumn}
				isRefresh={isRefresh + isRefreshDataLayout}
				defaultDataSource={dataSource}
				withClientSidePagination={withClientSidePagination}
				withTableHeader={withTableHeader}
				limit={10}
				withDynamicSearch={withDynamicSearch}
				{...withHistory && {
					expandable: {
						expandedRowRender: (sourcing) => <SourcingHistory sourcing={sourcing}/>,
						rowExpandable: (record) => record.name !== 'Not Expandable',
					},
				}}
			/>
			<SourcingActionPopover
				open={openActionPopover}
				initialValues={initialValuesActionForm}
				onSubmit={handleSubmitAction}
				onReset={() => setOpenActionPopover(false)}
				title="Add action"
			/>
			<SourcingCommentPopover
				open={openCommentPopover}
				initialValues={initialValuesCommentForm}
				onSubmit={handleSubmitComment}
				onReset={() => setOpenCommentPopover(false)}
				title="Add a comment"
			/>
			<ModalConfirmAction
				open={openConfirmAction}
				onClose={() => setOpenConfirmAction(false)}
				action={currentAction}
				initialValues={actionConfirmInitialValues}
				onConfirm={handleSubmitAction}
			/>
			<SourcingStageEditModal
				open={openStageEditModal}
				onClose={() => setOpenStageEditModal(false)}
				action={currentAction}
				initialValues={stageEditInitialValues}
				onConfirm={handleSubmitStage}
			/>
			<SourcingArchiveModal
				open={openArchiveModal}
				onClose={() => setOpenArchiveModal(false)}
				action={currentAction}
				initialValues={archiveInitialValues}
				onConfirm={handleSubmitArchive}
			/>
			<SourcingJobPopover
				open={openSourcingEditJob}
				onReset={() => setOpenSourcingEditJob(false)}
				initialValues={sourcingEditJobInitialValues}
				onSubmit={handleSubmitSelectJob}
			/>
			<MessagePopover
				open={openMessagePopover}
				initialValues={initialValuesMessageForm}
				onClose={() => setOpenMessagePopover(false)}
				onSubmit={handleMessageSubmit}
				onReset={() => setOpenMessagePopover(false)}
			/>
			<UserDrawerProfile
				user={userSelected}
				activeTab={activeTab}
				open={openProfile}
				onClose={handleCloseProfile}
			/>
		</>
	);
};

export default SourcingTable;
