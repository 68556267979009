import { Tag, Tooltip } from 'antd';
import { useTheme } from '@emotion/react';
import { useState } from 'react';
import config from '../../../../config/config';
import Link from '../../../app/Link';
import Table from '../../../app/table/Table';
import TableColumns from '../../../app/table/TableColumns';
import JobStub from '../../../jobs/JobStub';
import { COMPANY_RECRUITER_LOG_ACTIONS } from '../../../../constants/company-recruiter-log';
import { openExternalUrl } from '../../../../utils/common';

const LogTable = (
	{
		onDataLoaded,
		path = '/logging',
		filterOptions = {},
		isRefresh = 0,
		...props
	},
) => {
	const theme = useTheme();

	const [isRefreshDataLayout] = useState(0);

	const userCreatorAvatar = TableColumns.userAvatarColumn(theme, { title: 'Recruiter', dataIndex: 'user' });
	const companyLogoCol = TableColumns.companyLogoColumn(theme, {
		title: 'Company',
		dataIndex: 'company',
		removeInColumnFilter: true,
	});
	const logActionCol = {
		title: 'Action',
		dataIndex: 'action',
		removeInColumnFilter: true,
		sorter: false,
		render: (value) => {
			const { label, color } = (COMPANY_RECRUITER_LOG_ACTIONS.find((log) => log.value === value) || {});
			return (
				<Tag color={color}>{label}</Tag>
			);
		},
		...TableColumns.columnMinWidth(100),
	};

	const userTargetCol = TableColumns.userStubColumn(theme, {
		dataIndex: 'userTarget',
		title: 'Talent',
		activeTab: 'info',
		withDropdown: true,
		removeInColumnFilter: true,
		sorter: true,
		customOptions: {
			searchFields: [
				{ label: 'First name', value: 'firstName' },
				{ label: 'Last name', value: 'lastName' },
				{ label: 'Position', value: 'information.position' },
				{ label: 'Company', value: 'information.company' },
			],
		},
	});

	const sourcingCol = {
		title: 'Sourcing',
		removeInColumnFilter: true,
		render: (_, row) => (
			<JobStub
				job={row?.sourcingTarget?.job}
				jobActiveTab="sourcings"
			/>
		),
		...TableColumns.columnMinWidth(150),
	};

	const createdAtCol = TableColumns.createdAtColumn(theme);
	const updatedAtCol = TableColumns.updatedAtColumn(theme);

	const columns = [
		TableColumns.jsonColumn(theme),
		userCreatorAvatar,
		companyLogoCol,
		logActionCol,
		userTargetCol,
		sourcingCol,
		{
			title: 'Version',
			dataIndex: 'version',
			align: 'center',
			sorter: false,
			removeInColumnFilter: true,
			render: (v) => <code style={{ fontSize: 10 }}>{v}</code>,
			...TableColumns.columnFixedWidth(70),
		},
		// {
		//     title: 'Host',
		//     dataIndex: 'host',
		//     align: 'center',
		//     sorter: false,
		//     removeInColumnFilter: true,
		//     // render: (v) => <code style={{fontSize: 10}}>{v}</code>,
		//     ...TableColumns.columnFixedWidth(70),
		// },
		// TableColumns.jsonColumn(theme, { title: 'Location', value: (row) => row?.location, style: { height: 'unset' } }),
		{
			title: 'Path',
			dataIndex: 'pathname',
			sorter: false,
			removeInColumnFilter: true,
			render: (value, row) => {
				const url = `${row?.host || config.adminRoot}${row?.location?.pathname || ''}${row?.location?.search || ''}`;
				return <div
					style={{
						width: '100%',
						color: theme.color.darkgrey,
						fontSize: 10,
						wordBreak: 'break-all',
						// whiteSpace: 'wrap',
						overflow: 'hidden',
						textOverflow: 'ellipsis',
						display: '-webkit-box',
						WebkitLineClamp: 4,
						lineHeight: 1.2,
						WebkitBoxOrient: 'vertical',
					}}>
					<Tooltip title={<><b>Click to open</b><br/><Link style={{ lineHeight: 1.2 }} onClick={() => {
						openExternalUrl(url);
					}}>{url}</Link></>}>
						<span style={{ color: theme.color.darkgrey }}>{row?.host}</span>
						<span style={{ color: theme.color.black }}><b>{row?.location?.pathname}</b></span>
						<span style={{ color: theme.color.darkgrey }}>{row?.location?.search}</span>
					</Tooltip>
				</div>;
			},
			...TableColumns.columnFixedWidth(220),
		},
		createdAtCol,
		// updatedAtCol,
	];

	return (
		<>
			<Table
				path={path}
				onDataLoaded={onDataLoaded}
				columns={columns}
				filterOptions={filterOptions}
				isRefresh={isRefresh + isRefreshDataLayout}
				showTotal={(total) => `Total ${total} items`}
				limit={10}
				rowClassName={(row, index) => (row.is50Content === false ? 'rowDisabled' : '')}
				withDynamicSearch
				{...props}
			/>
		</>
	);
};

export default LogTable;
