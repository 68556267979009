import { useTheme } from '@emotion/react';
import { useContext, useEffect, useState } from 'react';
import { CompanyPageContext } from './CompanyPageProvider';
import { Space, Tooltip } from 'antd';
import {
	FaTimes, FaCheck, FaPen,
} from 'react-icons/fa';
import { documentId, hexToRgb, isFunction } from '../../../../utils/common';
import { ENGLISH_LANG } from '../../../../constants/constant';

const CompanyPageStepCompletionTag = (
	{
		company,
		step,
		withFilled = true,
		withEmpty = true,
		onClick,
		...props
	},
) => {
	const className = 'CompanyPageStepCompletionTag';

	const theme = useTheme();

	const { lang, currentlyEditingStep, setCurrentlyEditingStep } = useContext(CompanyPageContext);

	const [isStepCompleted, setIsStepCompleted] = useState(false);

	const handleClick = () => {
		setCurrentlyEditingStep(documentId(step));
		if (isFunction(onClick)) onClick?.();
	};

	useEffect(() => {
		setIsStepCompleted(step?.isComplete?.({ company, lang }));
	}, [step, company, lang, currentlyEditingStep]);

	let containerStyle = { border: '1px dashed rgba(255, 0, 0, 0.3)' };
	let color = null;
	let icon = <FaTimes style={{ color: theme.color.accent, marginBottom: -2 }} />;
	let tooltipTitle = `Not provided yet, click to ${lang === ENGLISH_LANG ? 'fill' : 'translate'}`;
	let withChevron = true;
	if (isStepCompleted) {
		if (!withFilled) return null;
		// containerStyle = { border: '1px solid darkturquoise' };
		// containerStyle = { border: '1px solid rgba(0, 0, 0, 0.2)' };
		containerStyle = { border: `1px solid rgba(${hexToRgb('#00CED1').join(',')}, .5)` };
		// containerStyle = { border: 'none', background: `rgba(${hexToRgb('#00CED1').join(',')}, .2)` };
		color = 'darkturquoise';
		color = 'rgba(0,0,0,.6)';
		// color = 'white';
		icon = <FaCheck style={{ color: theme.color.darkturquoise, marginBottom: -2 }} />;
		tooltipTitle = 'Step filled!';
		withChevron = false;
	} else if (!withEmpty) {
		return null;
	}

	return (
		<Tooltip title={tooltipTitle}>
			<Space
				size={4}
				onClick={handleClick}
				style={{
					userSelect: 'none',
					cursor: 'pointer',
					padding: '2px 10px',
					borderRadius: 30,
					...containerStyle,
					color,
				}}
				{...props}
			>
				{icon}
				<b><span style={{}}>{step?.title}</span></b>
				{withChevron && <FaPen style={{ fontSize: 9, marginBottom: 0, opacity: 0.9 }} />}
			</Space>
		</Tooltip>
	);
};

export default CompanyPageStepCompletionTag;
