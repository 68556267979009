import CONTENT_INTEREST from '../../property/content-interest';
import { innerObjPropExtractor } from '../../property/property-extractor';
import { innerObjPropMapper } from '../../property/property-mapper';

const USER_PROPERTY_MENTOR_SUBJECT_CUSTOM_KEY = 'mentor-subject-custom';

const USER_PROPERTY_MENTOR_SUBJECT_CUSTOM_MULTI = true;
const USER_PROPERTY_MENTOR_SUBJECT_CUSTOM_FREE = false;

const USER_PROPERTY_MENTOR_SUBJECT_CUSTOM_FIELD = 'mentor.mentorSubjectCustom';
const USER_PROPERTY_MENTOR_SUBJECT_CUSTOM_MAPPER = innerObjPropMapper(USER_PROPERTY_MENTOR_SUBJECT_CUSTOM_FIELD);
const USER_PROPERTY_MENTOR_SUBJECT_CUSTOM_EXTRACTOR = innerObjPropExtractor(USER_PROPERTY_MENTOR_SUBJECT_CUSTOM_FIELD);

export default {
	...CONTENT_INTEREST,
	key: USER_PROPERTY_MENTOR_SUBJECT_CUSTOM_KEY,
	USER_PROPERTY_MENTOR_SUBJECT_CUSTOM_KEY,
	multi: USER_PROPERTY_MENTOR_SUBJECT_CUSTOM_MULTI,
	USER_PROPERTY_MENTOR_SUBJECT_CUSTOM_MULTI,
	free: USER_PROPERTY_MENTOR_SUBJECT_CUSTOM_FREE,
	USER_PROPERTY_MENTOR_SUBJECT_CUSTOM_FREE,
	mapper: USER_PROPERTY_MENTOR_SUBJECT_CUSTOM_MAPPER,
	USER_PROPERTY_MENTOR_SUBJECT_CUSTOM_MAPPER,
	extractor: USER_PROPERTY_MENTOR_SUBJECT_CUSTOM_EXTRACTOR,
	USER_PROPERTY_MENTOR_SUBJECT_CUSTOM_EXTRACTOR,
	field: USER_PROPERTY_MENTOR_SUBJECT_CUSTOM_FIELD,
	USER_PROPERTY_MENTOR_SUBJECT_CUSTOM_FIELD,
};
