import { useAuthContext } from '../../../hooks/useAuthContext';
import { Tooltip } from 'antd';
import { FaEyeSlash } from 'react-icons/fa';

const UserNameHider = ({ name, height = 21 }) => {
	// <Tooltip title='Talent name hidden'>
	return <span
		style={{
			display: 'inline-block',
			// width: Math.min(150, name.length * height / 2),
			width: Math.round(name.length * height / 2.5),
			height,
			background: 'linear-gradient(to left, #eee, #ccc)',
			borderRadius: 4,
			verticalAlign: 'middle',
			textAlign: 'center',
			color: 'white',
		}}>
		{/* <FaEyeSlash style={{ marginBottom: -1, fontSize: 15, lineHeight: 1 }}/> */}
	</span>;
	// </Tooltip>
};
const UserName = (
	{
		user,
		forceShow = false,
		forceHide = false,
		withLastName = true,
		height = 15,
		...props
	},
) => {
	const { currentUserModel } = useAuthContext();

	if (!user) return null;

	const {
		firstName, lastName, slug, id, _id, email,
	} = user || {};

	const splitEmail = email ? email.split('@') : [];
	const fullName = `${firstName} ${lastName}`;

	if (splitEmail.length > 0 && splitEmail[0] === lastName && splitEmail[1] === firstName) {
		return email;
	}

	if (forceHide) {
		return <UserNameHider name={fullName} height={height}/>;
	}

	// fullName = fullName + JSON.stringify({ canDisplayTalentNames: currentUserModel?.canDisplayTalentNames?.() });
	if (!forceShow && currentUserModel?.canDisplayTalentNames?.(user) === false) {
		return <UserNameHider name={fullName} height={height} />;
	}

	return (
		<span
			style={{
				...props.style,
			}}
		>
			{fullName}
		</span>
	);
};

export default UserName;
