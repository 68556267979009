import { mixColors } from '../../../../utils/common';
import {
	getPillarColorMain,
	getPillarOption,
} from '../../../../constants/genderscore-pillars';

const GenderScorePillarCommunityStat = ({
	company,
	forceValue,
	pillar,
	index,
	...props
}) => {
	const className = 'GenderScorePillarCommunityStat';

	const communityStat = getPillarOption(pillar)?.communityStat;

	if (!communityStat) return null;

	return (
		<span
			style={{
				display: 'inline-flex',
				justifyContent: 'start',
				alignItems: 'center',
				// background: mixColors(getPillarColorLight(pillar), '#ffffff', 50),
				background: 'transparent',
				border: `1px dashed ${mixColors(getPillarColorMain(pillar), '#ffffff', 70)}`,
				borderRadius: 200,
				padding: '10px 16px',
				width: 'auto',
				gap: 10,
				marginBottom: 12,
			}}
		>
			<span style={{ fontSize: 32 }}>👩‍💻</span>
			<span style={{ opacity: 0.7 }}>
				{communityStat.ranking}
			</span>
		</span>
	);
};

export default GenderScorePillarCommunityStat;
