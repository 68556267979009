import {
	bold,
	regular,
	PDF_TEXT_STYLES,
	PDF_TYPE_TEXT,
	PDF_TYPE_GRID,
	PDF_PAGE_TEMPLATE_CONTENT,
} from '../base/PDFBuilder';

const PageYourProfile = ({ doc, context }) => [
	{
		template: PDF_PAGE_TEMPLATE_CONTENT,
		title: 'Your profile',
		footer: true,
		pageCount: true,
		items: [
			{
				type: PDF_TYPE_GRID,
				gridTemplateColumns: [4, 1],
				columnGap: 10,
				columns: [
					[
						{
							type: PDF_TYPE_TEXT,
							text: 'Bias level, according to your own assessment',
							...bold(PDF_TEXT_STYLES.normal),
							fontSize: 18,
							marginBottom: 2,
						},
						{
							type: PDF_TYPE_TEXT,
							text: 'Your overall scores show that you have a high level for 2 out of 7 biases, specifically the in-group effect and the recency bias. In other words, it seems that you particularly express these two specific types of biases in your management. Therefore, it\'s important for you to focus on the consequences of these particular biases and how to address them. Keep up the vigilance and engagement for an unbiased management!',
							...regular(PDF_TEXT_STYLES.normal),
							marginBottom: 10,
						},
					],
					[
						doc.getProfileBiasLevelTagBlock({
							// results, data,
						}),
					],
				],
			},
			{
				type: PDF_TYPE_GRID,
				gridTemplateColumns: [4, 1],
				columnGap: 10,
				columns: [
					[
						{
							type: PDF_TYPE_TEXT,
							text: 'Alignment, according to employee’s perception',
							...bold(PDF_TEXT_STYLES.normal),
							fontSize: 18,
							marginBottom: 2,
						},
						{
							type: PDF_TYPE_TEXT,
							text: `Your overall scores indicate a significant lack of alignment between your perception and that of your employees for 2 out of 7 biases, specifically the in-group effect and the recency bias. 
In other words, it seems that your employees perceive you as more biased than you report yourself to be. This can manifest in interactions, behaviors, tasks, and rewards assigned. Biases are mostly unconscious, so it's normal not to always be aware of them. This highlights the importance of gathering your employees' perceptions to improve your management.
Therefore, it's crucial that you focus on the consequences of these particular biases on your employees and how to improve communication and decision-making to allow for fair and objective management with everyone.

However, the perception of these biases does not seem to differ between your female and male employees, indicating that your employees do not believe their gender plays a role in the expression of your biases.`,
							...regular(PDF_TEXT_STYLES.normal),
							marginBottom: 10,
						},
					],
					[
						doc.getProfileAlignmentLevelTagBlock({
							// results, data,
						}),
					],
				],
			},
		],
	},
];

export default PageYourProfile;
