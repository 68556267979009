import {
	Button, Form, Select, Space, Switch,
} from 'antd';
import { useTheme } from '@emotion/react';
import { getBusinessPlanRoles } from '../../../constants/property/business-plan';
import CompanyPlanSelect from '../../company/companyPlan/CompanyPlanSelect';
import { LocalDebug } from '../../../utils/LocalDebug';
import { useState } from 'react';
import FormItem from 'antd/es/form/FormItem';
import Box from '../../app/box/Box';
import { requiredRule } from '../../../utils/common';

const AdminToolsUseCasePicker = () => {
	const className = 'AdminToolsUseCasePicker';

	const theme = useTheme();

	const [form] = Form.useForm();
	const [formValues, setFormValues] = useState({});
	const [planRoles, setPlanRoles] = useState([]);

	const onFormFieldsChange = () => {
		LocalDebug.logInfo({ className, method: 'onFormFieldsChange' }, form.getFieldsValue());
	};
	const handlePlanChanged = (planValue) => {
		LocalDebug.logInfo({ className, method: 'handlePlanChanged' }, planValue);
		setPlanRoles(getBusinessPlanRoles(planValue)?.map((value) => ({ value, label: value })));
	};
	const handleRoleChanged = (value) => {
		LocalDebug.logInfo({ className, method: 'handleRoleChanged' }, value);
	};

	return (
		<Box>
			<Form
				form={form}
				initialValues={formValues}
				onFieldsChange={onFormFieldsChange}
				labelCol={{ span: 8 }}
			>
				<Space
					direction='vertical'
					style={{ width: 400 }}
					size={0}
				>
					<FormItem
						label='Business plan'
						name={['companyPlan']}
						rules={[requiredRule]}
					>
						<CompanyPlanSelect
							style={{ minWidth: 150 }}
							allowClear={true}
							placeholder={'Company plan'}
							onChange={handlePlanChanged}
						/>
					</FormItem>

					<FormItem
						label='Role'
						name={['role']}
					>
						<Select
							style={{ minWidth: 150 }}
							allowClear={true}
							placeholder={'Company plan'}
							onChange={handleRoleChanged}
							options={planRoles}
						/>
					</FormItem>

					<FormItem
						label='ATS linked'
						name={['atsLinked']}
					>
						<Switch />
					</FormItem>

					<FormItem
						label='GS filled'
						name={['gsFilled']}
					>
						<Switch />
					</FormItem>

					<FormItem
						label={<>&nbsp;</>}
					>
						<Button type="primary"
							style={{
								fontWeight: 'bold',
								borderRadius: 5,
							}}
							htmlType="submit"
						>
						Log
						</Button>
					</FormItem>

				</Space>
			</Form>
		</Box>

	);
};

export default AdminToolsUseCasePicker;
