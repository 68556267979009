const { isStringTypeValidator } = require('../../utils/isStringTypeValidator');

const CAREER_EXPERIENCE_KEY = 'career-experience';

const CAREER_EXPERIENCE_ANSWER_NONE = 'none';
const CAREER_EXPERIENCE_ANSWER_LESS1 = 'less-than-one';
const CAREER_EXPERIENCE_ANSWER_1TO2 = 'one-to-two';
const CAREER_EXPERIENCE_ANSWER_1TO3 = 'one-to-three';
const CAREER_EXPERIENCE_ANSWER_3TO4 = 'three-to-four';
const CAREER_EXPERIENCE_ANSWER_3TO5 = 'three-to-five';
const CAREER_EXPERIENCE_ANSWER_5TO7 = 'five-to-seven';
const CAREER_EXPERIENCE_ANSWER_5TO8 = 'five-to-eight';
const CAREER_EXPERIENCE_ANSWER_7PLUS = 'more-than-seven';
const CAREER_EXPERIENCE_ANSWER_9PLUS = 'more-than-nine';

const CAREER_EXPERIENCE_OPTIONS = [
	{ value: CAREER_EXPERIENCE_ANSWER_LESS1, label: '<1 year' },
	{ value: CAREER_EXPERIENCE_ANSWER_1TO2, label: '1-2 years' },
	{ value: CAREER_EXPERIENCE_ANSWER_3TO4, label: '3-4 years' },
	{ value: CAREER_EXPERIENCE_ANSWER_5TO8, label: '5-8 years' },
	{ value: CAREER_EXPERIENCE_ANSWER_9PLUS, label: '9+ years' },
];

const customValidator = (value) => CAREER_EXPERIENCE_OPTIONS.map((o) => o.value).includes(value);

const CAREER_EXPERIENCE_VALIDATORS = [
	isStringTypeValidator,
	customValidator,
];

export default {
	CAREER_EXPERIENCE_ANSWER_LESS1,
	CAREER_EXPERIENCE_ANSWER_1TO2,
	CAREER_EXPERIENCE_ANSWER_3TO4,
	CAREER_EXPERIENCE_ANSWER_5TO8,
	CAREER_EXPERIENCE_ANSWER_9PLUS,

	key: CAREER_EXPERIENCE_KEY,
	CAREER_EXPERIENCE_KEY,
	validators: CAREER_EXPERIENCE_VALIDATORS,
	CAREER_EXPERIENCE_VALIDATORS,
	options: CAREER_EXPERIENCE_OPTIONS,
	CAREER_EXPERIENCE_OPTIONS,
};
