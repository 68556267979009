import ModalContainerBase from '../../contexts/modals/ModalContainerBase';
import { documentId } from '../../utils/common';
import FormBoxTitle from '../app/form/FormBoxTitle';
import AtsStageMappingEditForm from './AtsStageMappingEditForm';

const AtsStageMappingEditPopover = (
	{
		initialValues = {},
		onCloseWithRefresh,
		open,
		onSubmit,
		onReset,
	},
) => {
	const handleSubmit = (values) => {
		if (onSubmit) {
			onSubmit({ ...values, id: documentId(initialValues) });
		}
	};

	return (
		<ModalContainerBase
			open={open}
			onCancel={onReset}
		>
			<FormBoxTitle
				title='Edit a stage mapping'
			/>
			<AtsStageMappingEditForm
				initialValues={{ ...initialValues }}
				onReset={onReset}
				onSubmit={handleSubmit}
				onCloseWithRefresh={onCloseWithRefresh}
				withSubmitButton
			/>
		</ModalContainerBase>
	);
};

export default AtsStageMappingEditPopover;
