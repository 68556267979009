import { PERMS_GRANT_STAFF } from '../menu.utils';
import MenuBase from '../MenuBase';
import { ShoppingOutlined } from '@ant-design/icons';
import StaffMarketingCampaignEmailsMenu from './marketing/campaign-emails';

const StaffMarketingIcon = ShoppingOutlined;

class StaffMarketingMenu extends MenuBase {
	static props = {
		name: 'staff-marketing-menu',
		label: 'Marketing',
		icon: <StaffMarketingIcon />,
		iconLegacy: '🛍️',
		children: () => [
			(new StaffMarketingCampaignEmailsMenu()).build(),
		],
		perms: PERMS_GRANT_STAFF,
	};

	constructor() {
		super(StaffMarketingMenu.props);
	}
}

export default StaffMarketingMenu;
