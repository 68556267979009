import { ENGLISH_LANG } from '../../../../constants/constant';
import { displayHtml } from '../../../../utils/common';
import CompanyFieldNotProvided from '../shared/CompanyFieldNotProvided';
import { LocalDebug } from '../../../../utils/LocalDebug';

const CompanyMissionDisplay = (
	{
		company,
		lang = ENGLISH_LANG,
	},
) => {
	const className = 'CompanyMissionDisplay';

	const field = 'mission';
	const value = company?.getTranslationFieldFallback(field, lang);

	const content = value
		? displayHtml(value)
		: <CompanyFieldNotProvided/>;

	return (
		<>
			<h2>Mission</h2>
			{content}
		</>
	);
};

export default CompanyMissionDisplay;
