/** @jsxImportSource @emotion/react */

import { Button, Form } from 'antd';
import { useEffect, useState } from 'react';

import ArticleDivider from './components/ArticleDivider';
import FieldItemAnonymous from './components/FieldItemAnonymous';
import FieldItemMetas from '../../../seo/FieldItemMetas';
import FieldItemUpload from './components/FieldItemUpload';
import FooterAction from './components/FooterAction';
import FormItemEvent from './components/FormItemEvent';
import FormItemTitle from './components/FormItemTitle';
import FormInput from '../../../form/FormInput';
import { useTheme } from '@emotion/react';
import { LocalDebug } from '../../../../utils/LocalDebug';
import { ENGLISH_LANG } from '../../../../constants/constant';
import FormItemContent from './components/FormItemContent';
import ArticleModel from '../../../../models/ArticleModel';
import { FaLock, FaLockOpen } from 'react-icons/fa';
import { requiredRule, requiredTrimRule } from '../../../../utils/common';

const classes = (theme) => ({
	container: {
		'display': 'flex',
		'flexDirection': 'column',
		'maxHeight': 'calc(100vh - 200px)',
		'gap': 5,
		'.form-label': {
			fontSize: 16,
			lineHeight: 1.3,
			color: `${theme.color.blue}!important`,
			fontWeight: 500,
			padding: '0',
		},
	},
	content: {
		border: '1px solid #eee',
		borderRadius: 4,
		padding: '8px 12px',
		marginBottom: 20,
	},
	titleContent: {
		borderRadius: 4,
		padding: '8px 12px',
		marginBottom: 20,
		position: 'relative',
	},
	button: {
		'margin': 5,
		'background': theme.color.white,
		'borderColor': theme.color.blue,
		'color': theme.color.blue,
		'fontWeight': 'bold',
		'borderRadius': 5,
		':hover': {
			background: theme.color.white,
			borderColor: theme.color.blue,
			color: theme.color.blue,
			opacity: 0.7,
		},
	},
	activeButton: {
		'margin': 5,
		'background': `${theme.color.blue}!important`,
		'borderColor': `${theme.color.blue}!important`,
		'color': `${theme.color.white}!important`,
		':hover': {
			background: `${theme.color.blue}!important`,
			borderColor: `${theme.color.blue}!important`,
			color: `${theme.color.white}!important`,
			opacity: 0.7,
		},
	},
	hiddenContent: {
		'height': 0,
		'width': 0,
		'opacity': 0,
		'pointerEvents': 'none',
		'minHeight': 0,
		'minWidth': 0,
		'position': 'absolute',
		'.root-container': {
			height: '0px!important',
			width: '0px!important',
			opacity: '0px!important',
			pointerEvents: 'none!important',
			minHeight: '0px!important',
			minWidth: '0px!important',
		},
	},
	showContent: {
		height: 'auto',
		opacity: 1,
		pointerEvents: 'all',
	},
});

const ArticleForm = ({
						 article,
						 onSubmit,
						 lang,
						 isPreview,
						 setLang,
					 }) => {
	const className = 'ArticleForm';

	const [form] = Form.useForm();
	const theme = useTheme();
	const styles = classes(theme);
	const isEnVersion = lang === ENGLISH_LANG;

	const [topic, setTopic] = useState('event');
	const [formValues, setFormValues] = useState({});
	const [isDisabled, setIsDisabled] = useState(true);

	const handleChange = (name, value) => {
		article.setField(name, value, lang);
		hasDisabled();
	};

	const handleSubmit = (values) => {
		const { meta } = values;
		const method = 'handleSubmit';
		LocalDebug.logInfo({ className, method }, { values });
		onSubmit(new ArticleModel({
			...article, meta, topic, ...article.getTranslatedValues(),
		}));
	};

	const hasDisabled = () => {
		setIsDisabled(!article.getField('title', lang) || !article.getField('content', lang));
	};

	useEffect(() => {
		form.resetFields();
		form.setFieldsValue(article);
		setTopic(article.topic);
		hasDisabled();
	}, [article]);

	useEffect(() => {
		hasDisabled();
	}, [lang]);

	return (
		<Form
			form={form}
			name='article-meta-form'
			initialValues={formValues}
			onFinish={handleSubmit}

		>
			<div css={styles.container}>
				{/* <SelectArticleType setTopic={setTopic} topic={topic} styles={styles} /> */}
				<div
					css={{ maxHeight: '75vh', overflowY: 'auto', overflowX: 'hidden' }}
				>
					<div css={styles.content}>
						<FieldItemAnonymous
							initialValues={formValues}
							value={article.getField('isAnonymous', lang)}
							onChange={handleChange}
						/>
						<div style={styles.titleContent}>
							<ArticleDivider isEnVersion={isEnVersion} />

							<FormItemTitle
								onChange={handleChange}
								styles={styles}
								name={article?.getFieldName('title', lang)}
								label={article?.getLabel('title', lang)}
							/>
						</div>

						{topic === 'event' && (
							<>
								<div
									style={{
										borderRadius: 4,
										padding: '8px 12px',
										marginBottom: 20,
									}}
								>
									<ArticleDivider isEnVersion={isEnVersion} />
									<FormInput
										label="Lien d'inscription"
										placeholder='Zoom, Hopin, EventBrite...'
										name='link'
										extra=''
										maxLength={300}

									/>
								</div>
								<FormItemEvent
									initialValue={formValues} />
							</>
						)}

						<FieldItemUpload
							name={article.getFieldName('illustration', lang)}
							label={article.getLabel('illustration', lang)}
							defaultValue={article.getField('illustration', lang)}
							lang={lang}
							onChange={handleChange}
							styles={styles}
						/>

						<FormItemContent
							styles={styles}
							name={article.getFieldName('content', lang)}
							onChange={handleChange}
							isEnVersion={isEnVersion}
							rules={[{ ...requiredTrimRule, message: '' }]}
							label={(
								<label>
									<FaLockOpen /> Contenu public*
								</label>)
							}
						/>

						<FormItemContent
							styles={styles}
							name={article.getFieldName('contentLogged', lang)}
							onChange={handleChange}
							isEnVersion={isEnVersion}
							label={<label><FaLock /> Contenu loggé </label>}
						/>

					</div>
					<FieldItemMetas metaHolder={new ArticleModel(article)} isEnVersion={isEnVersion}/>
				</div>
			</div>
			<FooterAction

				isPreview={isPreview}
				lang={lang}
				setLang={setLang}
				disabled={isDisabled}
			/>

		</Form>
	);
};

export default ArticleForm;
