import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getCompanySelected } from '../../../../reducers/app';
import CompanyModel from '../../../../models/CompanyModel';
import { useAuthContext } from '../../../../hooks/useAuthContext';
import { useTheme } from '@emotion/react';
import UMSSurveyDraftStart from './UMSSurveyDraftStart';
import UMSSurveyDraftFilled from './UMSSurveyDraftFilled';
import ReactJsonDebug from '../../../app/debug/ReactJsonDebug';

const UMSSurveyDraftSelector = () => {
	const className = 'UMSSurveyDraftSelector';
	const theme = useTheme();

	// const { setSurveyDraft } = useContext(SurveyContext);
	const { currentUser } = useAuthContext();
	const companySelected = useSelector(getCompanySelected);
	// const draftSelected = useSelector(getCompanyUMSSurveyDraftSelected);
	const companyModel = new CompanyModel(companySelected);
	const [surveys, setSurveys] = useState([]);

	// useEffect(() => {
	// 	LocalDebug.logNull({ className, effects: 'draftSelected, setSurveyDraft' }, { draftSelected });
	// 	setSurveyDraft(draftSelected);
	// }, [draftSelected, setSurveyDraft]);

	useEffect(() => {
		if (companySelected && currentUser) {
			setSurveys([
				companyModel?.getUMSManagerSurvey?.({ user: currentUser }),
				companyModel?.getUMSEmployeeSurvey?.({ user: currentUser }),
			].filter((i) => i));
		}
	}, [companySelected, currentUser]);

	if (!(surveys?.length > 0)) {
		return 'No surveys detected. Please contact 50inTech';
	}

	return (
		<>
			{/* <ReactJsonDebug
				name='Surveys'
				src={surveys}
				collapsed={0}
			/> */}
			<div
				style={{
					width: '100%',
					height: '100vh',
					justifyContent: 'center',
					alignItems: 'center',
					display: 'grid',
					gridTemplateColumns: '1fr',
					padding: 40,
				}}
			>
				{surveys?.length > 1 && (
					<h1>You have 2 surveys to answer</h1>
				)}
				<div
					style={{
						display: 'grid',
						gridTemplateColumns: `repeat(${surveys.length}, 1fr)`,
						columnGap: 20,
						width: '100%',
						margin: '0 auto',
					}}
				>
					{surveys.map((survey, index) => {
						if (survey.isComplete) {
							return (
								<div key={index}>
									<UMSSurveyDraftFilled survey={survey} />
								</div>
							);
						}

						return (
							<div key={index}>
								<UMSSurveyDraftStart survey={survey} />
							</div>
						);
					})}
				</div>
			</div>
		</>
	);
	// const currentUserDraft = version === 'manager'
	// 	? companyModel.getUMSManagerSurvey({ user: currentUser })
	// 	: companyModel.getUMSEmployeeSurvey({ user: currentUser });

	// if (!currentUserDraft) {
	// 	return <UMSSurveyStart />;
	// }

	// if (currentUserDraft?.isComplete) {
	// 	return <UMSSurveyFilled />;
	// }

	// return <UMSSurveyResume />;
	// }
};

export default UMSSurveyDraftSelector;
