import moment from 'moment';

export default class JobModel {
	isPublished;

	publishedAt;

	areCompanyJobsPublished;

	companyJobsPublishedAt;

	atsProvider;

	constructor(job) {
		Object.assign(this, job);
	}

	get isJobPublished() {
		if (this.isPublished === false) return false;
		if (this.publishedAt && (moment(this.publishedAt).isAfter(moment()))) return false;
		return this.areJobsCompanyJobsPublished;
	}

	get areJobsCompanyJobsPublished() {
		if (this.areCompanyJobsPublished === false) return false;
		if (this.companyJobsPublishedAt && new Date(this.companyJobsPublishedAt) > new Date()) return false;
		return true;
	}
}
