/** @jsxImportSource @emotion/react */
import {
	Button, Form, Select, Space, Spin, message, Row, Col,
} from 'antd';
import { FaArrowCircleRight, FaPaperPlane } from 'react-icons/fa';
import { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTheme } from '@emotion/react';
import Link from '../../components/app/Link';
import COACHING_STATUS from '../../constants/coachingStatus';
import CoachingService from '../../services/coaching';
import FormItem from '../../components/company/form/FormItem';
import Popin from '../../components/app/modal/Popin';
import { listCoaches } from '../../actions/coaching';
import UserStub from '../../components/user/stub/UserStub';
import { documentId, openExternalUrl, requiredRule } from '../../utils/common';
import UserCoachingStatusOption from '../../components/user/widgets/UserCoachingStatusOption';
import LangFlagColorTag from '../../components/app/tag/LangFlagColorTag';
import { getMailjetTemplateBuildLink } from '../../constants/constant';
import { AppDataContext } from '../../contexts/AppDataProvider';
import ModalContainerBase from '../../contexts/modals/ModalContainerBase';
import FormBoxTitle from '../../components/app/form/FormBoxTitle';

function StatusTag({ status }) {
	const statusOption = COACHING_STATUS.COACHING_STATUS_OPTIONS.find((o) => o.value === status);
	return (
		<Space
			style={{
				fontSize: 10,
				borderRadius: 5,
				padding: '1px 6px',
				// border: '1px solid ' + statusOption?.color,
				// background: statusOption?.color,
			}}
		>
			{statusOption?.icon}
			<b>{statusOption?.label || status?.toUpperCase()}</b>
		</Space>
	);
}

function StatusDetails({ status }) {
	const theme = useTheme();
	const statusOption = COACHING_STATUS.COACHING_STATUS_OPTIONS.find((o) => o.value === status);
	return (
		<Space size={4}>
			{statusOption
				? (
					<>
						<span style={{ fontSize: 10, fontStyle: 'italic', color: theme.color.darkgrey }}>
							Coaching status will be set to
						</span>
						<div style={{ background: theme.color.bgGrey, padding: '0 10px 3px 5px', borderRadius: 20 }}>
							<UserCoachingStatusOption option={statusOption} style={{ label: { fontSize: 10 }, icon: { width: 16, height: 16 } }} />
						</div>
					</>
				)
				: (
					<span style={{ fontSize: 10, fontStyle: 'italic', color: theme.color.darkgrey }}>
					Coaching status will not be changed
					</span>
				)
			}
		</Space>
	);
}

function PopinContact(
	{
		open,
		setOpen,
		selectedUser,
		onRefresh,
	},
) {
	const [form] = Form.useForm();
	const theme = useTheme();
	const [selectedCoach, setSelectedCoach] = useState(null);
	const dispatch = useDispatch();
	const [selectedTemplate, setSelectedTemplate] = useState(null);
	const [loading, setLoading] = useState(false);
	const [disabledSave, setDisabledSave] = useState(true);
	const coaches = useSelector((state) => state.app.coaches);
	const { appSettings	} = useContext(AppDataContext);
	const [coachingMailingTemplates, setCoachingMailingTemplates] = useState(COACHING_STATUS.COACHING_MAILING_TEMPLATES);

	useEffect(() => {
		let value = COACHING_STATUS.COACHING_MAILING_TEMPLATES;
		try {
			value = JSON.parse(appSettings?.coachingMailingTemplates);
		} catch (e) {}
		setCoachingMailingTemplates(value || COACHING_STATUS.COACHING_MAILING_TEMPLATES);
	}, [appSettings]);

	// --------------------------------------- //
	// ------------ send email coaching ------------ //
	// --------------------------------------- //
	const handleSubmit = async (values) => {
		setLoading(true);
		CoachingService.sendUserContactEmail(documentId(selectedUser), values)
			.then((res) => {
				message.success('Email sent successfully!');
				if (onRefresh) onRefresh();
				form.resetFields();
				setLoading(false);
				setOpen(false);
			})
			.catch((err) => {
				message.error('Failed to send email!');
				setLoading(false);
			});
	};

	const handleFormChange = () => {
		const hasErrors = form.getFieldsError().some(({ errors }) => errors.length);
		setDisabledSave(hasErrors);
	};

	const onChangeCoach = (coachId) => {
		const coach = coaches?.find((c) => documentId(c) === coachId);
		setSelectedCoach(coach);
		handleFormChange();
		return coach;
	};

	const onChangeTemplate = (status) => {
		const template = coachingMailingTemplates.find(
			(temp) => temp.status === status,
		);
		setSelectedTemplate(template?.value || '');
		return template;
	};

	useEffect(() => {
		if (!coaches?.length) dispatch(listCoaches());
	}, []);

	useEffect(() => {
		const currentCoach = onChangeCoach(selectedUser?.coachId || null);
		const currentTemplate = onChangeTemplate(
			selectedUser?.coaching?.status || null,
		);
		form.setFieldsValue({
			coachId: documentId(currentCoach),
			templateId: currentTemplate?.value,
		});
	}, [form, selectedUser, coaches]);

	return (
		<ModalContainerBase
			open={open}
			setOpen={setOpen}
			width={900}
		>
			<FormBoxTitle
				title='Send a coaching email'
			/>
			<Form
				form={form}
				labelCol={{ span: 6 }}
				name="contact-form"
				onFinish={handleSubmit}
				onFieldsChange={handleFormChange}
				style={{ width: '100%', padding: '20px 20px 50px' }}
			>
				{/* <Space direction='vertical' size={0} style={{ width: '100%', padding: '20px 20px 50px' }}> */}

				<Row>
					<Col span={24}>

						<FormItem label='Talent'>
							<UserStub user={selectedUser}
								style={{ padding: 10, border: '1px solid #eee', borderRadius: 4 }}>
								<span style={{ fontSize: 10 }}><b>Interface language: </b><LangFlagColorTag
									lang={selectedUser?.settings?.lang} /></span>
							</UserStub>
						</FormItem>

					</Col>
				</Row>

				<Row>
					<Col span={24}
						css={{
							'.ant-select-selector, .ant-select-focus': {
								width: '100%!important',
							},
						}}
					>

						<FormItem label='Email template' required={true}>
							<FormItem
								name="templateId"
								rules={[{ ...requiredRule, message: 'Please select a template!' }]}
								noStyle
							>
								<Select
									options={coachingMailingTemplates?.map?.((o) => ({
										...o,
										label: (
											<Space direction='vertical' size={0}>
												<Space>
													<b>{o.label}</b>
													<LangFlagColorTag lang={o.lang} />
												</Space>
												<StatusDetails status={o.status} />
											</Space>
										),
									})) || []}
									onChange={setSelectedTemplate}
									showArrow={true}
									allowClear={true}
									placeholder="Select a template"
									mode='single'
									style={{ display: 'inline-block', width: '100%' }}
								/>
							</FormItem>

							{selectedTemplate && (
								<Link
									style={{ margin: '5px 0 0 12px', whiteSpace: 'nowrap', width: 260 }}
									onClick={() => { openExternalUrl(getMailjetTemplateBuildLink(selectedTemplate)); }}
								>
									Check in Mailjet <FaArrowCircleRight style={{ marginBottom: -2 }} />
								</Link>
							)}
						</FormItem>

					</Col>
				</Row>

				<Row>
					<Col span={24}
						css={{
							'.ant-select-selector, .ant-select-focus': {
								width: '100%!important',
								height: '50px!important',
								paddingTop: 10,
							},
							'.ant-select-selection-placeholder': {
								marginTop: 8,
							},
						}}
					>

						<FormItem label='Coach' required={true} colSpan={4}>
							<FormItem
								name="coachId"
								rules={[{ ...requiredRule, message: 'Please select a coach!' }]}
								noStyle
							>
								<Select
									onChange={(e) => onChangeCoach(e)}
									showArrow={true}
									allowClear={true}
									placeholder="Select a coach"
									mode='single'
									style={{ display: 'inline-block', width: '100%', height: 50 }}
								>
									{coaches?.map((user) => (
										<Select.Option
											value={documentId(user)}
											key={documentId(user)}
											style={{ height: 60 }}
										>
											<UserStub
												user={user}
												withProfession={false}
												withLocation={false}
												withPreview={false}
												style={{ padding: '5px 0' }}
											/>
										</Select.Option>
									))}
								</Select>

							</FormItem>

							{selectedCoach && (
								<Link
									style={{ margin: '12px 0 0 12px', whiteSpace: 'nowrap', width: 260 }}
									onClick={() => openExternalUrl(selectedCoach?.coachDetails?.calendly)}
								>
									Open coach Calendly <FaArrowCircleRight style={{ marginBottom: -2 }} />
								</Link>
							)}
						</FormItem>

					</Col>
				</Row>

				<Button
					htmlType="submit"
					disabled={disabledSave}
					css={{
						'position': 'absolute',
						'bottom': 0,
						'left': 0,
						'width': '100%',
						'height': 50,
						'background': theme.color.accent,
						'fontWeight': 700,
						'fontSize': 16,
						'color': theme.color.white,
						'display': 'flex',
						'justifyContent': 'center',
						'borderColor': theme.color.accent,
						'alignItems': 'center',
						'borderRadius': 0,
						'gap': 5,
						':hover, :visited': {
							background: theme.color.accent,
							color: theme.color.white,
							borderColor: theme.color.accent,
						},
					}}
				>
					{loading ? (
						<Spin />
					) : (
						<span css={{ display: 'flex', alignItems: 'center', gap: 10 }}>
							Send coaching email
							<FaPaperPlane css={{ marginBottom: -4, marginLeft: 5 }} />
						</span>
					)}
				</Button>
			</Form>
		</ModalContainerBase>
	);
}

export default PopinContact;
