import { Link as ReactRouterLink, useNavigate } from 'react-router-dom';
import { shouldReload } from '../../services/api';
import { LocalDebug } from '../../utils/LocalDebug';

const Link = (
	{
		to,
		disabled,
		children,
		...props
	},
) => {
	const className = 'Link';

	const navigate = useNavigate();

	return (
		<ReactRouterLink
			to={to}
			{...props}
			onClick={(e) => {
				if (e.metaKey || e.ctrlKey) return;
				e.preventDefault();
				// LocalDebug.logInfo({ className, method: 'onClick' }, e, { shouldReload });
				if (to) {
					if (shouldReload) {
						return (window.location = to);
					}
					navigate(to);
				}
				props?.onClick?.(e);
			}}
			style={{
				...props?.style,
				...disabled
					? {
						opacity: 0.5,
						cursor: 'not-allowed',
					}
					: {},
			}}
		>
			{children}
		</ReactRouterLink>
	);
};

export default Link;
