import { useContext, useMemo } from 'react';
import { arrayWrap, hexToRgb } from '../../../../utils/common';
import { AppDataContext } from '../../../../contexts/AppDataProvider';

const GenderScoreLayoutPageHeader = ({
	color,
	height = 400,
	children,
	...props
}) => {
	const className = 'GenderScoreLayoutPageHeader';

	const {
		isAppSiderVisible,
		appSiderWidth,
	} = useContext(AppDataContext);

	const svg = useMemo(() => {
		const colors = arrayWrap(color);

		if (arrayWrap(color).length > 1) {
			return (
				<svg
					width='2500'
					height={height}
				>
					<defs>
						<linearGradient id='grad' x1='0%' y1='0%' x2='50%' y2='0%'>
							{colors
								.map((c, index) => (
									<stop
										key={index}
										offset={`${Math.round(100 * index / (colors.length - 1))}%`}
										style={{
											stopColor: `rgb(${hexToRgb(c).join(',')})`,
											stopOpacity: 1,
										}}
									/>
								))}
						</linearGradient>
					</defs>
					<polygon
						points={`0,0 2500,0 2500,100 0,${height}`}
						fill='url(#grad)'
					/>
				</svg>
			);
		}
		return (
			<svg
				width='2500'
				height={height}
				fill={color}
			>
				<polygon points={`0,0 2500,0 0,${height}`} />
			</svg>
		);
	}, [color]);

	return (
		<>
			<div
				style={{
					position: 'relative',
					width: `calc(100vw - ${isAppSiderVisible ? appSiderWidth : 0}px)`,
					overflow: 'hidden',
					...props?.style,
				}}
			>
				<div
					style={{
						position: 'relative',
						zIndex: 2,
						padding: 32,
					}}
				>
					{children}
				</div>

				<div
					style={{
						position: 'absolute',
						top: 0,
						left: 0,
						zIndex: 1,
					}}
				>
					{svg}
				</div>

			</div>

		</>
	);
};

export default GenderScoreLayoutPageHeader;
