import { useTheme } from '@emotion/react';
import { FaChevronDown, FaDownload } from 'react-icons/fa';
import domtoimage from 'dom-to-image';
import { jsPDF } from 'jspdf';
import { Dropdown, Spin } from 'antd';
import React, { useState } from 'react';
import { arrayWrap, replaceAll } from '../../../../../utils/common';
import CompanyModel from '../../../../../models/CompanyModel';
import moment from 'moment';
import { LoadingOutlined } from '@ant-design/icons';
import fonts from '../../../../fonts';
import CompanyGenderScoreBadge from '../../CompanyGenderScoreBadge';
import HiddenContent from '../../../../app/layout/HiddenContent';

export const saveAsPNG = ({ uri, filename, setIsLoading }) => {
	const link = document.createElement('a');
	if (typeof link.download === 'string') {
		link.href = uri;
		link.download = filename;
		// Firefox requires the link to be in the body
		document.body.appendChild(link);
		link.click();
		document.body.removeChild(link);
	} else {
		window.open(uri);
	}
	setIsLoading?.(false);
};

export const saveAsPDF = async ({
	uri, filename, node, pdfDecorator, setIsLoading,
}) => {
	const pdf = new jsPDF('p', 'mm', 'a4');
	const margin = { x: 10, y: 20 };
	const datesMargin = 52;
	const titleSeparation = 6;
	const datesSeparation = 4;
	const beforeImgHeight = margin.y + titleSeparation + 3 * datesSeparation;

	const imgHeight = node.offsetHeight;
	const imgWidth = node.offsetWidth;
	const pdfWidth = pdf.internal.pageSize.getWidth();
	const pdfHeight = pdf.internal.pageSize.getHeight();
	const imgWidthOnPdf = pdfWidth - 15;
	const imgHeightOnPdf = imgWidthOnPdf * imgHeight / imgWidth;
	const isImgTooBig = imgHeightOnPdf + beforeImgHeight > pdfHeight;

	let images = [];
	let imgHeightsOnPdf = [];
	if (isImgTooBig) {
		const dashboardRows = [...Array(5).keys()];
		for (const row of dashboardRows) {
			const rowHTML = document.getElementById(`gs-dashboard-row-${row}`);
			try {
				const rowImgUri = await domtoimage.toPng(
					rowHTML,
					{ bgcolor: '#FFFFFF' },
				);
				images = [
					...images,
					rowImgUri,
				];
				const rowHeight = rowHTML.offsetHeight;
				const rowHeightOnPdf = imgWidthOnPdf * rowHeight / imgWidth;
				imgHeightsOnPdf = [...imgHeightsOnPdf, rowHeightOnPdf];
			} catch (error) {
				console.error('oops, something went wrong!', error);
				setIsLoading?.(false);
			}
		}
	} else {
		images = arrayWrap(uri);
		imgHeightsOnPdf = arrayWrap(imgHeightOnPdf);
	}

	pdfDecorator?.({
		pdf, margin, datesMargin, datesSeparation, titleSeparation,
	});

	// Write image
	if (isImgTooBig) {
		const imagesSeparation = 8;
		pdf.addImage(images[0], 'JPEG', margin.x, beforeImgHeight, imgWidthOnPdf, imgHeightsOnPdf[0]);
		pdf.addImage(images[1], 'JPEG', margin.x, beforeImgHeight + imgHeightsOnPdf[0] + imagesSeparation, imgWidthOnPdf, imgHeightsOnPdf[1]);
		pdf.addImage(images[2], 'JPEG', margin.x, beforeImgHeight + imgHeightsOnPdf[0] + imgHeightsOnPdf[1] + 2 * imagesSeparation, imgWidthOnPdf, imgHeightsOnPdf[2]);
		pdf.setFont(fonts.SpaceGrotesk.regular.fontName);
		pdf.text('Page 1 of 2', pdfWidth - margin.x - 5, pdfHeight - margin.y - 5, 'right');
		pdf.addPage();
		pdf.addImage(images[3], 'JPEG', margin.x, margin.y, imgWidthOnPdf, imgHeightsOnPdf[3]);
		pdf.addImage(images[4], 'JPEG', margin.x, margin.y + imgHeightsOnPdf[3] + imagesSeparation, imgWidthOnPdf, imgHeightsOnPdf[4]);
		pdf.text('Page 2 of 2', pdfWidth - margin.x - 5, pdfHeight - margin.y - 5, 'right');
	} else {
		pdf.addImage(images[0], 'JPEG', margin.x, beforeImgHeight, imgWidthOnPdf, imgHeightsOnPdf[0]);
	}

	pdf.save(filename);
	setIsLoading?.(false);
};

const GenderScoreDashboardDownloadButton = ({
	company,
	...props
}) => {
	const className = 'GenderScoreDashboardDownloadButton';
	const theme = useTheme();
	const companyModel = new CompanyModel(company);
	const submissionDate = companyModel?.genderScore?.submittedAt;
	const expirationDate = companyModel?.getGenderScoreExpirationDate();
	const datesFormat = 'YYYY-MM-DD';
	const [isLoading, setIsLoading] = useState(false);

	const downloadBadge = ({ id, bgcolor }) => {
		setIsLoading(true);
		const node = document.getElementById(id || 'gs-badge-download');
		domtoimage.toPng(node, {
			bgcolor,
		})
			.then((dataUrl) => {
				const filename = [
					'50inTech-GenderScore-Badge',
					`-For-${replaceAll(companyModel?.name, ' ', '')}` || '',
					expirationDate ? `-Valid-Until-${moment(expirationDate).format(datesFormat)}` : '',
					'.png',
				].join('');

				saveAsPNG({
					uri: dataUrl,
					filename,
					setIsLoading,
				});
			})
			.catch((error) => {
				console.error('oops, something went wrong!', error);
				setIsLoading(false);
			});
	};
	const downloadDashboard = ({ id, bgcolor = '#FFFFFF' }) => {
		setIsLoading(true);
		const node = document.getElementById(id || 'gs-dashboard');
		domtoimage.toPng(node, {
			bgcolor,
		})
			.then((dataUrl) => {
				const filename = [
					'50inTech-GenderScore-Report',
					`-For-${replaceAll(companyModel?.name, ' ', '')}` || '',
					expirationDate ? `-Valid-Until-${moment(expirationDate).format(datesFormat)}` : '',
					'.pdf',
				].join('');

				saveAsPDF({
					uri: dataUrl,
					filename,
					node,
					setIsLoading,
					pdfDecorator: ({
						pdf, margin, datesMargin, datesSeparation, titleSeparation,
					}) => {
						Object.values(fonts).forEach((fontFamily) => {
							Object.values(fontFamily).forEach((font) => {
								pdf.addFileToVFS(font.fontFileName, font.font);
								pdf.addFont(font.fontFileName, font.fontName, font.fontStyle, font.fontWeight);
							});
						});
						// Write title
						pdf.setFont(fonts.SpaceGrotesk.bold.fontName);
						pdf.text(`GenderScore Report for ${companyModel?.name}`, margin.x, margin.y, 'left');
						// Write dates
						pdf.setFontSize(8);
						pdf.text('GenderScore Report downloaded on:', margin.x, margin.y + titleSeparation, 'left');
						pdf.setFont(fonts.SpaceGrotesk.regular.fontName);
						pdf.text(moment().format(datesFormat), margin.x + datesMargin, margin.y + titleSeparation, 'left');
						if (submissionDate) {
							pdf.setFont(fonts.SpaceGrotesk.bold.fontName);
							pdf.text('GenderScore Survey submitted on:', margin.x, margin.y + titleSeparation + datesSeparation, 'left');
							pdf.setFont(fonts.SpaceGrotesk.regular.fontName);
							pdf.text(moment(companyModel?.genderScore?.submittedAt).format(datesFormat), margin.x + datesMargin, margin.y + titleSeparation + datesSeparation, 'left');
						}
						if (expirationDate) {
							pdf.setFont(fonts.SpaceGrotesk.bold.fontName);
							pdf.text('GenderScore Badge valid until: ', margin.x, margin.y + titleSeparation + 2 * datesSeparation, 'left');
							pdf.setFont(fonts.SpaceGrotesk.regular.fontName);
							pdf.text(moment(companyModel?.getGenderScoreExpirationDate()).format(datesFormat), margin.x + datesMargin, margin.y + titleSeparation + 2 * datesSeparation, 'left');
						}
					},
				});
				setIsLoading(false);
			})
			.catch((error) => {
				console.error('oops, something went wrong!', error);
				setIsLoading(false);
			});
	};

	const itemStyle = {
		color: theme.color.darkerturquoise,
		fontSize: 14,
		fontWeight: 500,
		padding: 10,
	};
	const downloadItems = [
		{
			label: (
				<div style={itemStyle}
					onClick={downloadDashboard}>
					Download GenderScore Report
				</div>
			),
			key: '1',
		},
		{
			label: (
				<div style={itemStyle}
					onClick={downloadBadge}>
					Download GenderScore Badge
				</div>
			),
			key: '2',
		},
	];
	return <Dropdown menu={{ items: downloadItems }}
		dropdownRender={(menu) => (
			<div style={{
				borderRadius: 4,
			}}>
				{React.cloneElement(menu, {
					style: {
						fontWeight: 400,
					},
				})}

			</div>
		)}
	>
		<div
			style={{
				padding: '0px 16px',
				color: theme.color.darkerturquoise,
				border: `1px solid ${theme.color.darkturquoise}`,
				borderRadius: 8,
				background: theme.color.lightturquoise,
				fontSize: 16,
				fontWeight: 700,
				height: 40,
				display: 'flex',
				alignItems: 'center',
				cursor: 'pointer',
				gap: 4,
			}}
			onClick={(e) => e.preventDefault()}
		>
			{isLoading ? <Spin
				indicator={
					<LoadingOutlined
						style={{
							fontSize: 14,
						}}
						spin
					/>
				}
			/>
				: <FaDownload/>
			}
			<span>&nbsp;Download</span><FaChevronDown />

			<HiddenContent>
				<div
					id='gs-badge-download'
				>
					<CompanyGenderScoreBadge
						company={company}
						version='white'
						size={900}
						withShadow={false}
					/>
				</div>
			</HiddenContent>
		</div>
	</Dropdown>;
};
export default GenderScoreDashboardDownloadButton;
