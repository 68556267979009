import React, { useEffect, useState } from 'react';
import { Col, Form, Row } from 'antd';
import { getPermissionOptionsByUser, getQueryParams } from '../../utils/common';
import { localDebug } from '../../utils/LocalDebug';
import { aclRoles, BOOLEAN_OPTIONS } from '../../constants/constant';
import { useAuthContext } from '../../hooks/useAuthContext';
import FormItem from '../company/form/FormItem';
import FormAclRoleSelect from '../app/form/FormAclRoleSelect';
import FilterModal from '../app/filters/FilterModal';
import FormSelect from '../app/form/FormSelect';

const AclFilterModal = (
	{
		title,
		open,
		initialValues = {},
		onClose,
		onFilter,
	},
) => {
	const { currentUser } = useAuthContext();

	const [form] = Form.useForm();
	const queryParams = getQueryParams();
	const [optionsRole, setOptionsRole] = useState([]);

	const getDefaultSelected = (fieldName) => {
		return queryParams[fieldName] || [];
	};

	useEffect(() => {
		setOptionsRole(getPermissionOptionsByUser(aclRoles, currentUser));
	}, [currentUser]);

	const handleFilter = () => {
		form
			.validateFields()
			.then((values) => {
				const filtersValues = values;
				// const filtersValues = objectFilterUndefinedValues(values);
				if (onFilter) onFilter(filtersValues);
			})
			.catch((errors) => {
				localDebug('Validate Failed:', errors);
			});
	};

	useEffect(() => {
		form.resetFields();
		form.setFieldsValue(initialValues);
	}, [form, initialValues]);

	return (
		<FilterModal
			title={title}
			width={500}
			open={open}
			onClose={onClose}
			onFilter={handleFilter}
		>

			<Form
				form={form}
				name="add-team"
				labelCol={{ span: 6 }}
				labelAlign='right'
				className="user-filter-form"
			>
				<Row gutter={24}>
					<Col span={24}>
						<FormItem label="Role" name="role">
							<FormAclRoleSelect
								options={optionsRole}
								defaultValue={getDefaultSelected('role')}
								placeholder="Select one or more roles"
								mode="multiple"
							/>
						</FormItem>
					</Col>
					<Col span={24}>
						<FormItem label="Active" name="isActive">
							<FormSelect options={BOOLEAN_OPTIONS}/>
						</FormItem>
					</Col>
				</Row>
			</Form>

		</FilterModal>
	);
};

export default AclFilterModal;
