import { useTheme } from '@emotion/react';
import { useDispatch } from 'react-redux';
import { useState } from 'react';
import { saveCompany } from '../../../actions/company';
import { useAuthContext } from '../../../hooks/useAuthContext';
import AddButton from '../../app/button/AddButton';
import CompanyAddModal from './CompanyAddModal';
import useBodyScrollBlocker from '../../../hooks/useBodyScrollBlocker';
import { FaSpinner } from 'react-icons/fa';

const CompanyAddButton = (
	{
		onSuccess,
		...props
	},
) => {
	const className = 'CompanyAddButton';

	const theme = useTheme();
	const dispatch = useDispatch();

	const { isEditor } = useAuthContext();

	const [disabled, setDisabled] = useState(false);
	const [icon, setIcon] = useState(null);
	const [modalOpen, setModalOpen] = useState();

	useBodyScrollBlocker(modalOpen, className);

	const handleAddCompany = async (values) => {
		setDisabled(false);
		setModalOpen(false);
		const result = await dispatch(saveCompany(values, true));
		await onSuccess?.(result);
	};

	if (!isEditor) return null;

	return (
		<>
			<AddButton
				onClick={() => setModalOpen(true)}
				size='large'
				title="Add a new company"
				disabled={disabled}
				{...icon ? { icon } : {}}
				style={{ ...props?.style }}
			/>

			<CompanyAddModal
				open={modalOpen}
				onClose={() => setModalOpen(false)}
				onSave={handleAddCompany}
			/>
		</>
	);
};

export default CompanyAddButton;
