import { Space, Typography } from 'antd';
import { useTheme } from '@emotion/react';
import CompanyLogo from '../company/CompanyLogo';
import { EnvironmentOutlined } from '@ant-design/icons';
import ActionButton from '../app/table/ActionButton';
import { RiEdit2Fill } from 'react-icons/ri';
import { useAuthContext } from '../../hooks/useAuthContext';
import { localDebug } from '../../utils/LocalDebug';

const JobHeader = (
	{
		job,
		onEditJob,
	},
) => {
	const theme = useTheme();

	const { isStaffView } = useAuthContext();

	return (job
		&& <Space direction='vertical' size={2}>
			<Space direction='horizontal' size={4} align="center">
				<CompanyLogo size={22} company={job.company} withTooltip={false}/>
				<span style={{ fontSize: 14, fontWeight: 700, color: theme.color.blue }}>
					{job.company?.name}
				</span>
			</Space>
			<Typography.Title level={3} style={{ marginBottom: 0, color: theme.color.blue }}>
				{job.title}
			</Typography.Title>
			{job.location
				&& <Typography style={{ color: theme.color.gray, fontSize: 16 }}>
					<EnvironmentOutlined/> {job.location}
				</Typography>}
			{isStaffView && <Space>
				<code className="slug" style={{ marginTop: 6 }}><span style={{ fontSize: 10 }}>{job.slug}</span></code>
			</Space>}
			{job?.isCustomize && <ActionButton
				tooltipTitle="Edit job"
				onClick={() => {
					localDebug('JobHeader onEditJob');
					onEditJob(job);
				}}
				icon={<RiEdit2Fill/>}
			/>}
		</Space>);
};

export default JobHeader;
