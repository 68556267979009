import {
	Popover, Space,
} from 'antd';
import { InfoCircleFilled } from '@ant-design/icons';
import JsonPopoverLink from '../../app/debug/JsonPopoverLink';
import SurveyQuestionExpectedAnswerDetails from './SurveyQuestionExpectedAnswerDetails';
import { getPillarColorMain, getPillarIcon } from '../../../constants/genderscore-pillars';
import SurveyQuestionContent from './SurveyQuestionContent';
import QUESTIONS from '../data/questions';
import { useTheme } from '@emotion/react';

const SurveyScoringDetailsQuestion = ({
	index,
	questionId,
	surveyConf,
	answers,
	withQuestionId = false,
	withQuestionJson = false,
	withQuestionPopover = false,
	withExpectedAnswer = true,
}) => {
	const theme = useTheme();

	const question = QUESTIONS[questionId] || questionId || {};
	const shortLabel = question?.shortLabel;
	const section = surveyConf?.sections
		?.find?.((s) => s.questions?.find((q) => q.questionId === questionId));

	return (
		<div
			style={{
				borderLeft: `4px solid ${getPillarColorMain(section?.value)}`,
				paddingLeft: 8,
			}}
		>
			<Space direction='horizontal' align='start'>
				<Space
					direction="vertical"
					size={4}
					style={{
						fontSize: 12,
						lineHeight: 1.3,
					}}
				>
					<Space
						direction='horizontal'
						style={{
							justifyContent: 'flex-start',
							alignItems: 'center',
							height: 18,
							lineHeight: 1,
						}}
					>
						{getPillarIcon(section?.value, { width: 18, height: 18 })}

						{index >= 0 && (
							<div
								style={{
									fontSize: 11,
									fontWeight: 'bold',
									color: theme.color.grey,
								}}
							>
								{`#${index}`}
							</div>
						)}

						{withQuestionId && (
							<code className='tiny'>
								{questionId}
							</code>
						)}

						{withQuestionPopover && (
							<JsonPopoverLink
								data={question}
								style={{
									height: 8,
									fontSize: 10,
									content: {
										maxWidth: 400,
									},
								}}
							/>
						)}

					</Space>

					<Popover
						content={(
							<div
								className={`survey ${section?.value}`}
								style={{
									padding: 20,
									maxWidth: 640,
									maxHeight: '70vh',
									overflow: 'scroll',
								}}
							>
								<div
									style={{
										zoom: 0.7,
									}}
								>
									<SurveyQuestionContent
										question={question}
										pillar={section?.value}
										answers={answers || {}}
									/>
								</div>
							</div>
						)}
					>
						<span
							style={{
								fontSize: 16,
								fontWeight: 'bold',
							}}
						>
							{shortLabel} <InfoCircleFilled />
						</span>
					</Popover>

					{withExpectedAnswer && (
						<SurveyQuestionExpectedAnswerDetails
							question={{
								...question,
								ranges: surveyConf?.scoring[question?.questionId]?.ranges,
							}}
						/>
					)}
				</Space>
			</Space>
		</div>
	);
};

export default SurveyScoringDetailsQuestion;
