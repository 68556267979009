import { Badge } from 'antd';

const MenuBadge = ({
	withContainer = true,
	value = 0,
	...props
}) => {
	const badge = (
		<Badge
			count={value}
			showZero={props.showZero || false}
			size={'small'}
			style={{
				fontFamily: 'DM Sans',
				border: 'none',
				fontSize: 10,
				fontWeight: 700,
				boxShadow: 'none',
				padding: '0 4px',
				...props?.style,
			}}
			overflowCount={props?.overflowCount}
		/>
	);

	if (withContainer) {
		return (
			<div
				style={{
					position: 'absolute',
					top: -1,
					right: 16,
				}}
			>
				{badge}
			</div>
		);
	}

	return badge;
};

export default MenuBadge;
