import {
	RISK_OPTIONS,
} from '../../../data/biases';
import {
	PDF_PAGE_TEMPLATE_CONTENT,
} from '../base/PDFBuilder';

const PageRiskDetails = ({ doc }) => [
	{
		template: PDF_PAGE_TEMPLATE_CONTENT,
		title: {
			en: 'Possible risks for managers',
			fr: 'Risques managériaux potentiels',
		},
		titleStyle: {
			fontSize: 40,
		},
		footer: true,
		pageCount: true,
		items: RISK_OPTIONS.map(doc.walkRiskBlock),
	},
];

export default PageRiskDetails;
