import { useSelector } from 'react-redux';
import PageLayout from '../../components/app/layout/PageLayout';
import { useTheme } from '@emotion/react';
import { getCompanySelected } from '../../reducers/app';
import CompanyGridPicker from '../../components/company/CompanyGridPicker';
import UMSManagerResults from '../../components/ums/pages/UMSManagerResults';
import UMSManagerResultsMenu from '../../menu/ums/UMSManagerResultsMenu';

const UMSManagerResultsPage = () => {
	const className = 'UMSManagerResultsPage';

	const theme = useTheme();

	const companySelected = useSelector(getCompanySelected);

	if (!companySelected) return <CompanyGridPicker />;

	const menu = (new UMSManagerResultsMenu()).build();

	return (
		<PageLayout
			icon={menu.icon}
			title={menu.label}
		>
			<UMSManagerResults />
		</PageLayout>
	);
};

export default UMSManagerResultsPage;
