import { Button, message } from 'antd';
import axios from 'axios';
import { goToLogin } from '../actions/app';
import { API_URL } from '../utils/common';
import { LocalDebug, localDebug } from '../utils/LocalDebug';
import authFirebaseService from './authFirebase';
import { FaTimes } from 'react-icons/fa';
import version from '../autoBuildVersion';
import config from '../config/config';

const className = 'api';

export let shouldReload = false;

export const instance = axios.create({
	baseURL: API_URL,
	headers: { 'App-Version': version },
});

export const apiCandidateInstance = axios.create({
	baseURL: config.apiRoot,
});

export const adminProdInstance = axios.create({
	baseURL: `${config.adminRootProd}/api`,
});

export const adminProdStaffInstance = axios.create({
	baseURL: 'https://admin-staff.50intech.com/api',
});

export const request = {
	get: async (url, config) => instance.get(url, config),
	post: async (url, body, config) => instance.post(url, body, config),
	put: async (url, body, config) => instance.put(url, body, config),
	delete: async (url, data) => instance.delete(url, data),
};

export const apiCandidateRequest = {
	get: async (url, config) => apiCandidateInstance.get(url, config),
	post: async (url, body, config) => apiCandidateInstance.post(url, body, config),
	put: async (url, body, config) => apiCandidateInstance.put(url, body, config),
	delete: async (url, data) => apiCandidateInstance.delete(url, data),
};

export const adminProdRequest = {
	get: async (url, config) => adminProdInstance.get(url, config),
	post: async (url, body, config) => adminProdInstance.post(url, body, config),
	put: async (url, body, config) => adminProdInstance.put(url, body, config),
	delete: async (url, data) => adminProdInstance.delete(url, data),
};

export const adminProdStaffRequest = {
	get: async (url, config) => adminProdStaffInstance.get(url, config),
	post: async (url, body, config) => adminProdStaffInstance.post(url, body, config),
	put: async (url, body, config) => adminProdStaffInstance.put(url, body, config),
	delete: async (url, data) => adminProdStaffInstance.delete(url, data),
};

apiCandidateInstance.interceptors.request.use(
	async (req) => {
		const token = await authFirebaseService.getToken();
		// LocalDebug.logInfo({ className, method: 'apiCandidateInstance.interceptors.request.use' }, { token });
		req.headers.common.Authorization = `Bearer ${token}`;
		// req.headers['Authorization'] = `Bearer ${token}`;
		return req;
	},
	(err) => {
		return Promise.reject(err);
	},
);

// ------------------------------------------------------- //
// --------------- request inteceptor -------------------- //
// ------------------------------------------------------- //
instance.interceptors.request.use(
	async (req) => {
		const token = await authFirebaseService.getToken();
		// LocalDebug.logInfo({ className, method: 'instance.interceptors.request.use' }, { token });
		req.headers.common.Authorization = `Bearer ${token}`;
		return req;
	},
	(err) => {
		return Promise.reject(err);
	},
);

// ---------------------------------------------------------- //
// ------------------ response interceptor  ----------------- //
// ---------------------------------------------------------- //

instance.interceptors.response.use(
	(res) => {
		if (res.config.method !== 'options') {
			// LocalDebug.logInfo({
			// 	className, method: 'instance.interceptors.response'
			// }, { appShouldReload: res.headers['app-should-reload'], res });
			if ([true, 'true'].includes(res.headers['app-should-reload'])) {
				shouldReload = true;
			}
		}
		if (res.status === 401) {
			goToLogin();
		}
		return res;
	},
	(error) => {
		const method = 'instance.interceptors.response.use';

		// localDebug(__filename.split('/').shift(), error.response?.data?.message || error.message, error);
		localDebug(__filename.split('/').shift(), JSON.stringify(error, Object.getOwnPropertyNames(error)));

		if (axios.isCancel(error)) {
			localDebug('axios request cancelled', error.message);
			return Promise.reject(error);
		}
		if (error?.response?.status === 401) {
			// localDebug(__filename.split('/').shift(), error.response.data.error);
			// message.error(
			//     <Space direction='vertical'>
			//         {error.response?.data?.error}
			//         {error.message}
			//     </Space>
			// );
			goToLogin();
		} else {
			// if (error?.response?.status === 500) {
			//     localDebug(__filename.split('/').shift(), error.response?.data?.message || error.message, error);
			const messageKey = new Date().toString();
			LocalDebug.logError({ className, method, error }, { error });
			message.error(
				{
					key: messageKey,
					content: <div style={{ position: 'relative' }}>
						<div dangerouslySetInnerHTML={{
							__html:
									[
										`${error?.response?.status ? `<b>[${error?.response?.status}]</b> ` : ''}${error.response?.data?.error?.message || error?.message}`,
										// error.message,
										// error.response?.data?.error?.message,
										// 'error.response?.data?.error: ' + JSON.stringify(error.response?.data?.error),
										// 'error.response?.data?.message: ' + error.response?.data?.message,
										// JSON.stringify(error.response),
									].filter((i) => i).join('<br/>'),
						}} />
						<div style={{ position: 'absolute', top: -24, right: -10 }}>
							<Button
								icon={<FaTimes style={{ marginBottom: -2 }} />}
								style={{ border: 'none', background: 'none' }}
								onClick={() => message.destroy(messageKey)} />
						</div>
					</div>,
					duration: 0,
					// onClick: () => {
					//     localDebug('onClick', { messageKey });
					//     message.destroy(messageKey);
					// },
				},
			);
		}

		return Promise.reject(error);
	},
);
