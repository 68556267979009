import { Space } from 'antd';
import { Image } from 'cloudinary-react';
import { useTheme } from '@emotion/react';
import {
	GENDERSCORE_LABEL,
} from '../../../constants/constant';
import {
	LOGO_50INTECH_FULL_BLUE_SVG,
	LOGO_50INTECH_FULL_WHITE_SVG,
} from '../../../constants/logos';

export const Logo50 = ({ width = 200, color = 'blue' }) => (
	<Image
		width={width}
		publicId={color === 'blue' ? LOGO_50INTECH_FULL_BLUE_SVG : LOGO_50INTECH_FULL_WHITE_SVG}
		secure={true}
	/>
);

const GenderScoreName = ({ size = 200, style = {} }) => (
	<span
		style={{
			fontFamily: 'DM Sans',
			fontSize: size / 7,
			lineHeight: 1.2,
			fontWeight: 900,
			...(style || {}),
		}}
	>
		{GENDERSCORE_LABEL}
	</span>
);

const CompanyGenderScoreLogo = (
	{
		size = 200,
		color = 'blue',
		direction = 'vertical',
		with50Logo = true,
		style = {},
	},
) => {
	const className = 'CompanyGenderScoreLogo';

	const resizer = (v) => v * size / 200;

	const theme = useTheme();

	let background = theme.color.blue;
	let fontColor = theme.color.blue;

	if (color === 'white') {
		background = theme.color.white;
		fontColor = theme.color.white;
	}

	if (direction === 'vertical') {
		return (
			<div
				direction='vertical'
				style={{
					display: 'flex',
					flexDirection: 'column',
					justifyContent: 'center',
					alignItems: 'center',
					...(style || {}),
					userSelect: 'none',
				}}
			>
				{with50Logo && (
					<Logo50
						width={size / 2.5}
						color={color}
					/>
				)}

				<GenderScoreName
					size={size}
					style={{
						color: fontColor,
						...style?.name,
						display: 'inline-block',
						marginTop: size / 20,
					}}
				/>
			</div>
		);
	}

	return (
		<Space
			size={size / 60}
			style={{
				justifyContent: 'center',
				alignItems: 'center',
				...(style || {}),
			}}
		>
			<Logo50 width={size / 2.3} color={color} />
			<div
				style={{
					padding: `${size / 50}px ${size / 25}px`,
					color: fontColor,
					marginBottom: 4,
				}}
			>
				<GenderScoreName size={size} style={{ color: fontColor }} />
			</div>
		</Space>
	);
};

export default CompanyGenderScoreLogo;
