import { Skeleton, Space } from 'antd';
import GridCardBase from './GridCardBase';

const GridCardSkeleton = (
	{
		children = <>No results found</>,
		...props
	},
) => {
	const className = 'GridCardSkeleton';

	return (
		<GridCardBase style={{ padding: 30 }}>
			<Skeleton active style={{ width: '100%', height: 400, borderRadius: 20 }}/>
		</GridCardBase>
	);
};

export default GridCardSkeleton;
