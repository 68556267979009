import { useContext } from 'react';
import { useTheme } from '@emotion/react';
import { getIncompleteMandatoryStepList } from './CompanyPageSteps';
import { CompanyPageContext } from './CompanyPageProvider';
import CompanyPageNextStepLink from './CompanyPageNextStepLink';
import { ENGLISH_LANG } from '../../../../constants/constant';

const CompanyPageUnpublishBtnPopoverContent = (
	{
		company,
		...props
	},
) => {
	const className = 'CompanyPageUnpublishBtnPopoverContent';

	const theme = useTheme();

	const { lang } = useContext(CompanyPageContext);

	return (
		<div
			style={{
				position: 'relative',
				overflow: 'auto',
				flex: '0 1 260px',
				maxWidth: 260,
				borderRadius: 10,
			}}
		>
			Only <b>Admin roles</b> can unpublish the company page
			{
				company.isTranslable && lang === ENGLISH_LANG
					? ' in its English version'
					: ''
			}
		</div>
	);
};

export default CompanyPageUnpublishBtnPopoverContent;
