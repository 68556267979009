import { useTheme } from '@emotion/react';
import {
	getLevelFromScore,
	getNextLevel,
	getNextLevelRanges,
	hasNextLevel,
} from '../../../../constants/genderscore-levels';
import GenderScoreLayoutBox from '../layout/GenderScoreLayoutBox';
import GenderScoreLevelLabel from '../levels/GenderScoreLevelLabel';
import { ArrowRightIcon } from '../../../../utils/common';
import GenderScoreResultsNavLink from './GenderScoreResultsNavLink';
import { ACTIONS_ANCHOR_ID } from './GenderScoreResults';

const GenderScoreResultsLevelNextBox = ({
	company,
	forceValue,
}) => {
	const className = 'GenderScoreResultsLevelNextBox';

	const theme = useTheme();

	const genderScore = forceValue || company?.genderScore;

	const levelValue = getLevelFromScore(genderScore?.globalScore);

	if (!hasNextLevel(levelValue)) return null;

	const levelDiff = (
		(getNextLevelRanges(levelValue)?.[0] || 0)
		- (company?.genderScore?.globalScore || 0)
	) || 0;

	return (
		<GenderScoreLayoutBox
			withShadow={false}
			style={{
				background: theme.color.bgGrey,
				border: '1px dashed #ddd',
				paddingBottom: 48,
				color: '#333',
			}}
		>
			<div
				style={{
					fontSize: 36,
					marginBottom: 2,
					color: 'black',
				}}
			>
				{levelDiff}&nbsp;
				<span style={{ fontSize: 24 }}>point{levelDiff > 1 ? 's' : ''}</span>
			</div>
			<p>
				<span>before the next level:</span>
				&nbsp;
				<GenderScoreLevelLabel level={getNextLevel(levelValue)} withPill={false} />
			</p>
			<p>
				Want to know how to reach this level?
			</p>
			<p>
				Follow our actions personalized
				from your responses to our questionnaire.
			</p>
			<div style={{
				textAlign: 'right', position: 'absolute', right: 24, bottom: 24,
			}}>
				<GenderScoreResultsNavLink
					path={`#${ACTIONS_ANCHOR_ID}`}
					context={'next-level-box'}
				>
						See our recommended actions&nbsp;
					<ArrowRightIcon />
				</GenderScoreResultsNavLink>
			</div>
		</GenderScoreLayoutBox>
	);
};

export default GenderScoreResultsLevelNextBox;
