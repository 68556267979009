import ModalContainerBase from '../../../contexts/modals/ModalContainerBase';
import { documentId } from '../../../utils/common';
import FormBoxTitle from '../../app/form/FormBoxTitle';
import AccessForm from './CompanyAccessForm';

const CompanyAccessPopover = ({
	open, initialValues = {}, onSubmit, onReset,
}) => {
	return (
		<ModalContainerBase
			open={open}
			width={600}
			onCancel={onReset}
		>
			<FormBoxTitle
				title={documentId(initialValues) ? 'Edit an ATS connection' : 'Connect an ATS'}
			/>
			<AccessForm
				initialValues={initialValues}
				onSubmit={onSubmit}
				onReset={onReset}
			/>
		</ModalContainerBase>
	);
};

export default CompanyAccessPopover;
