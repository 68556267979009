import { TAG_STAFF_ICON } from '../../constants/constant';
import Logo50inTech from '../../components/app/Logo50inTech';
import { PERMS_GRANT_STAFF } from '../menu.utils';
import MenuBase from '../MenuBase';
import StaffAtsMenu from './StaffAtsMenu';
import StaffContentMenu from './StaffContentMenu';
import StaffToolsMenu from './StaffToolsMenu';
import StaffCompaniesMenu from './StaffCompaniesMenu';
import StaffMarketingMenu from './StaffMarketingMenu';
import StaffDataMenu from './StaffDataMenu';
import StaffUsersMenu from './StaffUsersMenu';
import StaffProductMenu from './StaffProductMenu';
import StaffTechMenu from './StaffTechMenu';
import StaffGenderscoreMenu from './StaffGenderscoreMenu';
import StaffHealthMenu from './StaffHealthMenu';

export const StaffIcon = () => <Logo50inTech color={'rgba(0,0,0,.88)'} size={14} style={{ marginRight: 0 }} />;

class StaffMenu extends MenuBase {
	static props = {
		name: 'staff',
		icon: <StaffIcon />,
		label: <>Staff {TAG_STAFF_ICON}</>,
		children: () => [
			(new StaffToolsMenu()).build(),
			(new StaffHealthMenu()).build(),
			(new StaffAtsMenu()).build(),
			(new StaffCompaniesMenu()).build(),
			(new StaffGenderscoreMenu()).build(),
			(new StaffContentMenu()).build(),
			(new StaffDataMenu()).build(),
			(new StaffMarketingMenu()).build(),
			(new StaffProductMenu()).build(),
			(new StaffTechMenu()).build(),
			(new StaffUsersMenu()).build(),
		],
		perms: PERMS_GRANT_STAFF,
	};

	constructor() {
		super(StaffMenu.props);
	}
}

export default StaffMenu;
