import {
	Button, Row, Col, Divider, Form, message,
} from 'antd';
import { useTheme } from '@emotion/react';
import FormItem, { FormItemContainer } from '../../../app/form/FormItem';
import { useAuthContext } from '../../../../hooks/useAuthContext';
import CKEditor from '../../../app/form/CkEditor';
import { FaPaperPlane } from 'react-icons/fa';
import FormSelect from '../../../app/form/FormSelect';
import { documentId, requiredRule, requiredTrimRule } from '../../../../utils/common';

const FeedbackForm = (
	{
		initialValues = {},
		onSubmit,
		onReset,
	},
) => {
	const theme = useTheme();

	const { companyIdsUser, currentUser } = useAuthContext();
	initialValues = {
		...initialValues || {},
		message: localStorage.getItem('draft_feedback_message') || initialValues?.message || '',
		payload: {
			category: localStorage.getItem('draft_feedback_category') || initialValues?.payload?.category,
		},
	};

	const [form] = Form.useForm();

	const handleSubmit = (values) => {
		const [companyId] = companyIdsUser || [];
		const data = {
			companyId,
			userId: documentId(currentUser),
			...values,
			id: documentId(initialValues) || null,
		};
		onSubmit(data);

		updateLocalItem('draft_feedback_message', '');

		message.success('Feedback posted! We\'ll get back to you asap, thanks for your help!');
		form.resetFields();
	};

	const onMessageChanged = (value) => {
		updateLocalItem('draft_feedback_message', value);
	};
	const onCategoryChanged = (value) => {
		updateLocalItem('draft_feedback_category', value);
	};
	const updateLocalItem = (item, value) => {
		if (!value) {
			localStorage.removeItem(item);
		} else {
			localStorage.setItem(item, value);
		}
	};
	const newLabel = <span className='logoNew' style={{ fontSize: 24 }}>NEW</span>;
	return (
		<div style={{ textAlign: 'left' }}>
			<Form
				form={form}
				name="alert_form"
				initialValues={{ ...initialValues }}
				onFinish={handleSubmit}
			>

				<FormItemContainer>
					<FormItem
						label='Category'
						defaultContainer="div"
						name={['payload', 'category']}
						rules={[requiredRule]}
					>
						<FormSelect
							options={[
								{ value: 'general', label: 'General' },
								{ value: 'talent-search', label: <span>Search Talents {newLabel}</span> },
								{ value: 'talent-card', label: <span>Talents' Cards {newLabel}</span> },
								{ value: 'sourcing', label: 'Sourcing' },
								{ value: 'matching', label: 'Matching' },
								{ value: 'bug', label: 'Bug' },
								{ value: 'other', label: 'Other' },
							]}
							style={{ width: '100%' }}
							onChange={onCategoryChanged}
						/>
					</FormItem>
					<FormItem
						label='Your message'
						defaultContainer="div"
						name='message'
						rules={[requiredTrimRule]}
					>
						<CKEditor
							config={{
								toolbar: ['bold', 'italic', 'link', 'bulletedList', 'numberedList', 'blockQuote'],
							}}
							style={{ width: '100%' }}
							onChange={onMessageChanged}
						/>
					</FormItem>
				</FormItemContainer>

				<Divider/>

				<Row>
					<Col span={24}>
						<div style={{
							display: 'flex',
							justifyContent: 'space-between',
							margin: -5,
							width: 'calc(100% + 10px)',
						}}>
							<Button type="default" style={{
								margin: 5,
								borderColor: theme.color.orange,
								fontWeight: 'bold',
								borderRadius: 5,
								color: theme.color.orange,
							}}
							htmlType="reset"
							onClick={onReset}
							>
								Cancel
							</Button>
							<Button type="primary"
								style={{
									margin: 5,
									fontWeight: 'bold',
									borderRadius: 5,
								}}
								htmlType="submit"
							>
								Send &nbsp;<FaPaperPlane style={{ marginBottom: -2 }}/>
							</Button>
						</div>
					</Col>
				</Row>

			</Form>

		</div>
	);
};

export default FeedbackForm;
