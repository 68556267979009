import { GENDERSCORE_LABEL } from '../../../../constants/constant';
import FormItem from '../../form/FormItem';
import CompanyPlanGenderScoreIsEnabledSwitch from './CompanyPlanGenderScoreIsEnabledSwitch';

export const companyPlanGenderScoreIsEnabledPath = ['companyPlan', 'genderScore', 'isEnabled'];

const CompanyPlanGenderScoreIsEnabledFormItem = ({
	disabled = false,
}) => {
	const className = 'CompanyPlanGenderScoreIsEnabledFormItem';

	return (
		<FormItem
			label={
				<span
					style={{
						...disabled ? { color: '#ccc' } : {},
					}}
				>
					{GENDERSCORE_LABEL} enabled
				</span>
			}
			name={companyPlanGenderScoreIsEnabledPath}
			valuePropName='checked'
		>
			<CompanyPlanGenderScoreIsEnabledSwitch disabled={disabled} />
		</FormItem>
	);
};

export default CompanyPlanGenderScoreIsEnabledFormItem;
