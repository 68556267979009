import { changeObjValue } from '../../utils/common';

export const innerObjPropMapper = (innerObjKey) => (
	obj,
	value,
) => changeObjValue(obj, innerObjKey, value);

export const defaultMapper = (obj, key, value) => ({ ...obj, [key]: value });

export default {
	defaultMapper,
	innerObjPropMapper,
};
