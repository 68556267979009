import { useState } from 'react';
import { ENGLISH_LANG } from '../../../../constants/constant';
import CKEditor from '../../../app/form/CkEditor';
import CompanyFieldEditor from './CompanyFieldEditor';
import { Input, Select } from 'antd';

const CompanySelectEditor = (
	{
		company,
		lang = ENGLISH_LANG,
		title,
		details,
		field,
		options = [],
		isTranslatable = true,
		setEditorOpen,
		mode,
	},
) => {
	const className = 'CompanySelectEditor';

	const [editedValue, setEditedValue] = useState(company.getTranslationField(field, lang));

	return (
		<CompanyFieldEditor
			company={company}
			lang={lang}
			setEditorOpen={setEditorOpen}
			title={title}
			details={details}
			field={field}
			isTranslatable={isTranslatable}
			editedValue={editedValue}
		>
			<Select
				options={options}
				value={editedValue}
				onChange={setEditedValue}
				style={{ width: '100%' }}
				mode={mode}
			/>

		</CompanyFieldEditor>
	);
};

export default CompanySelectEditor;
