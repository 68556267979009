import { Space } from 'antd';
import CompanyGenderScoreLogo from './CompanyGenderScoreLogo';
import CompanyGenderScoreRingScore from './CompanyGenderScoreRingScore';
import { mixColors } from '../../../utils/common';
import CompanyGenderScoreLabel from './CompanyGenderScoreLabel';
import { useTheme } from '@emotion/react';

const CompanyGenderScoreBadgeInline = (
	{
		company,
		size = 150,
		year,
		version = 'blue',
		withLogo = true,
		withLabel = true,
		withPopover = true,
		withShadow = true,
		isUnpublished = false,
		...props
	},
) => {
	const className = 'CompanyGenderScoreBadgeInline';

	const theme = useTheme();

	const field = 'genderScore.globalScore';
	const value = company.genderScore?.globalScore || 0;

	if (!value) return null;

	let logoVersion = 'blue';
	let background = `linear-gradient(135deg, ${theme.color.white}, ${mixColors(theme.color.white, theme.color.fitBlueElectric, 5)})`;
	let badgeColor = theme.color.blue;

	if (version === 'blue') {
		logoVersion = 'white';
		background = `linear-gradient(135deg, ${theme.color.blue}, ${mixColors(theme.color.blue, theme.color.fitBlueElectric, 50)})`;
		badgeColor = theme.color.white;
	}

	const resizer = (target) => (target * size) / 150;

	const content = (
		<Space
			size={resizer(20)}
			style={{
				fontFamily: 'DM Sans',
				position: 'relative',
				alignItems: 'center',
				justifyContent: 'flex-start',
				width: '100%',
				height: size,
				padding: `${resizer(15)}px ${resizer(50)}px ${resizer(
					15,
				)}px ${resizer(15)}px `,
				// paddingRight: resizer(40),
				borderRadius: size,
				background,
				...withShadow && { boxShadow: `0 ${resizer(2)}px ${resizer(20)}px -${resizer(2)}px rgba(0,0,0,.2)` },
				cursor: 'pointer',
				...props?.style,
			}}
		>
			<div
				style={{
					display: 'block',
					width: resizer(130),
					height: resizer(130),
					borderRadius: resizer(130),
					marginTop: resizer(8),
					...isUnpublished
						? {
							opacity: 0.3,
							filter: 'grayscale(100%)',
						}
						: {},
				}}
			>
				<CompanyGenderScoreRingScore
					size={resizer(120)}
					company={company}
					withLabel={false}
					style={{
						score: { color: badgeColor },
						label: { color: badgeColor },
						...props?.style?.ring,
					}}
				/>
			</div>
			<Space direction='vertical' size={0}>
				{withLogo && <CompanyGenderScoreLogo
					size={resizer(200)}
					color={logoVersion}
					// direction="vertical"
					direction='horizontal'
					style={
						{
							// alignItems: 'start',
							// name: { display: 'block' },
							...props?.style?.logo,
						}
					}
				/>}
				<CompanyGenderScoreLabel
					company={company}
					style={{
						color: badgeColor,
						textTransform: 'uppercase',
						fontWeight: 'bold',
						...props?.style?.label,
						fontSize: resizer(props?.style?.label?.fontSize || 40),
					}}
				/>
			</Space>
		</Space>
	);

	if (!withPopover) return content;

	return content;
};

export default CompanyGenderScoreBadgeInline;
