import {
	mixColors, numToArray, sortOn, sumReducer,
} from '../../../../../utils/common';
import {
	RISK_OPTIONS,
	findBias,
} from '../../../data/biases';
import { LocalDebug } from '../../../../../utils/LocalDebug';
import {
	bold,
	italic,
	PDF_PAGE_TEMPLATE_CONTENT,
	PDF_TEXT_STYLES,
	PDF_TYPE_ARROW,
	PDF_TYPE_DIVIDER,
	PDF_TYPE_GRID,
	PDF_TYPE_RECT,
	PDF_TYPE_TEXT,
} from '../base/PDFBuilder';

const PageRiskProfile = ({
	doc,
	context,
}) => {
	const className = 'PageRiskProfile';

	const riskLevels = RISK_OPTIONS
		.map((risk) => {
			const riskBiasesScore = risk.biases
				.map(({ bias, weight }) => {
					const score = context?.umsResult?.scores?.bias?.[findBias(bias)?.shortId]?.avgScore || 0;
					LocalDebug.logInfo({ className }, {
						risk: risk.label, bias, weight, score,
					});
					return score * weight;
				})
				.reduce(sumReducer, 0);
			const riskBiasesWeights = risk.biases
				.map(({ weight }) => weight)
				.reduce(sumReducer, 0);
			// console.log('riskLevels', {
			// 	risk,
			// 	umsResult: context?.umsResult,
			// 	riskBiasesScore,
			// 	riskBiasesWeights,
			// });
			return ({ risk, level: Math.round(riskBiasesScore / riskBiasesWeights) });
			// const level = Math.round(1 + 4 * Math.random());
			const level = Math.round(100 * Math.random());
			return ({ risk, level });
		}).sort(sortOn({ key: 'level' }, true));

	const percentRect = ({
		value,
		width,
		height,
	}) => {
		return [
			{
				type: PDF_TYPE_RECT,
				fill: mixColors('#f7506e', '#ffffff', 50),
				width,
				height,
				position: 'absolute',
			},
			{
				type: PDF_TYPE_RECT,
				fill: '#e6346b',
				width: width * value / 100,
				height,
				position: 'absolute',
			},
			{
				type: PDF_TYPE_TEXT,
				text: `${value}%`,
				color: '#ffffff',
				padding: { top: 3, left: 5 },
				...bold(PDF_TEXT_STYLES.title2),
			},
		];
	};

	const riskItems = riskLevels
		.map(({ risk, level }, riskIndex, arr) => [
			{
				type: PDF_TYPE_GRID,
				gridTemplateColumns: [0.9, 1.1],
				columnGap: 5,
				// ...riskIndex === 0 ? { marginTop: 12 } : {},
				// marginTop: riskIndex === 0 ? 12 : 0,
				columns: [
					[
						{
							type: PDF_TYPE_ARROW,
							size: 32,
							orientation: 'right',
							position: 'absolute',
						},
						{
							type: PDF_TYPE_TEXT,
							text: risk.label,
							...bold(PDF_TEXT_STYLES.title2),
							fontSize: 28,
							dx: 12,
							// fontSize: 20,
							// withBorder: true,
						},
						// {
						// 	type: PDF_TYPE_TEXT,
						// 	text: risk.details,
						// 	...italic(PDF_TEXT_STYLES.small),
						// 	fontSize: 11,
						// 	color: '#cccccc',
						// 	withBorder: true,
						// },
					],
					[
						...percentRect({
							value: level,
							width: 100,
							height: 18,
						}),
					// 	{
					// 		type: PDF_TYPE_GRID,
					// 		gridTemplateColumns: numToArray(5).map((i) => 1),
					// 		gridWidth: 36,
					// 		columnGap: 0,
					// 		columns: numToArray(5)
					// 			.map((_, index) => [{
					// 				type: PDF_TYPE_TEXT,
					// 				text: level >= (index + 1) ? '*' : '',
					// 				...bold(PDF_TEXT_STYLES.title2),
					// 				fontSize: 20,
					// 				withBorder: true,
					// 			}]),
					// 	},
					],
				],
			},
			...(riskIndex < ((arr?.length || 0) - 1))
				? [{
					type: PDF_TYPE_DIVIDER,
					color: '#cccccc',
					marginTop: 6,
					marginBottom: 8,
				}]
				: [],
		])
		.flat();

	return [
		{
			template: PDF_PAGE_TEMPLATE_CONTENT,
			title: 'Your risk profile',
			subtitle: 'According to your bias levels',
			footer: true,
			pageCount: true,
			items: riskItems,
		},
	];
};

export default PageRiskProfile;
