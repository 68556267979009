import { ENGLISH_LANG } from '../../../../constants/constant';
import CompanyFieldNotProvided from '../shared/CompanyFieldNotProvided';
import { useTheme } from '@emotion/react';

const CompanyWebsiteDisplay = (
	{
		company,
		lang = ENGLISH_LANG,
	},
) => {
	const className = 'CompanyWebsiteDisplay';

	const theme = useTheme();

	const field = 'website';
	const value = company?.getTranslationFieldFallback(field, lang);

	if (!value) return <CompanyFieldNotProvided>Website</CompanyFieldNotProvided>;

	return (
		<div
			style={{
				fontSize: 12,
				color: theme.color.fitBlueElectric,
			}}
		>
			{value}
		</div>
	);
};

export default CompanyWebsiteDisplay;
