import { useAuthContext } from '../../../hooks/useAuthContext';
import { SocketContext } from '../../../contexts/SocketProvider';
import { useContext, useState } from 'react';
import { useDispatch } from 'react-redux';
import { addSourcing } from '../../../actions/sourcings';
import {
	ADD_TO_TALENT_POOL_LABEL,
	SOURCING_DOCUMENT_REF,
} from '../../../constants/constant';
import AddButton from '../../app/button/AddButton';
import SourcingPopover from '../../sourcings/SourcingPopover';
import UserAvatarList from '../widgets/UserAvatarList';
import { useTheme } from '@emotion/react';
import { Space } from 'antd';
import { localDebug } from '../../../utils/LocalDebug';
import { documentId } from '../../../utils/common';
import { SOURCE_DIRECT_APPLY_VALUE } from '../../../constants/sourcing';
import { GenderhireCandidatesSourcedIcon } from '../../../menu/genderhire/GenderhireCandidatesSourcedMenu';

const UserSourcingAddBtn = (
	{
		title,
		user,
		sourcings,
		initialValues = {},
		...props
	},
) => {
	const theme = useTheme();
	// context
	const { isEditor, isStaff, isStaffView } = useAuthContext();
	const { createAction } = useContext(SocketContext);

	// state
	const [openAddSourcing, setOpenAddSourcing] = useState(false);

	// dispatch
	const dispatch = useDispatch();

	const sourcers = [];
	let sourcedCount = 0;
	sourcings?.forEach((item) => {
		const { manager, source } = item;
		// localDebug({ source });
		sourcedCount += documentId(manager)?.toString?.() === documentId(user)?.toString?.() || source === SOURCE_DIRECT_APPLY_VALUE ? 0 : 1;
		// localDebug({ source, sourcedCount, manager: documentId(manager) });
		sourcers.push(...manager && !sourcers.some((s) => documentId(s)?.toString?.() === documentId(manager)?.toString?.()) ? [manager] : []);
	});
	// localDebug({ sourcers });

	const saveSourcing = async (values, title) => {
		const valuesToDb = { ...values };
		const result = await addSourcing(valuesToDb);
		localDebug('valuesToDB', valuesToDb);

		// websocket
		if (result) {
			const playload = {
				documentRef: SOURCING_DOCUMENT_REF,
				documentId: documentId(result),
				companyId: result.companyId,
				action: {
					title,
					...(values?.history && { history: values.history }),
				},
			};
			createAction(playload);
		}
	};

	const handleAddSourcing = async (values, title = 'create a sourcing') => {
		await saveSourcing(values, title);
		setOpenAddSourcing(false);
	};

	const addBtn = <AddButton
		icon={<GenderhireCandidatesSourcedIcon />}
		onClick={() => setOpenAddSourcing(true)}
		title={title || ADD_TO_TALENT_POOL_LABEL}
		{...props}
		style={{ ...props?.style, ...sourcings?.length > 0 && { marginRight: 0, borderRadius: '4px 0 0 4px' } }}
	/>;

	const sourcingsDone = sourcings?.length > 0
		? <div style={{
			position: 'relative',
			fontSize: 12,
			fontWeight: 'bold',
			padding: '5px 10px 5px 10px',
			background: theme.color.paleblueLight,
			height: 32,
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
			// borderRadius: '0 4px 4px 0',
			borderRadius: 4,
			marginLeft: -4,
			border: `2px solid ${theme.color.blue}`,
		}}>
			<>{sourcings.length !== sourcedCount
				? <>{sourcings.length - sourcedCount} apply{(sourcings.length - sourcedCount) > 1 ? 's' : ''}</>
				: null
			}</>
			<>{sourcings.length !== sourcedCount && sourcedCount ? <>&nbsp;•&nbsp;</> : null}</>
			<>{sourcedCount
				? <>
					{sourcedCount} sourcing{sourcedCount > 1 ? 's' : ''}
					{sourcers?.length
						? <>&nbsp;by &nbsp;<UserAvatarList size={22} overflow={-5} users={sourcers}/></>
						: null
					}</>
				: null
			}</>
		</div>
		: null;

	return (
		<>
			<Space direction='horizontal' size={0}>
				{addBtn}
				{sourcingsDone}
			</Space>
			<SourcingPopover
				withFormHistoryItems={false}
				open={openAddSourcing}
				initialValues={initialValues}
				onClose={() => setOpenAddSourcing(false)}
				onSubmit={handleAddSourcing}
				onReset={() => setOpenAddSourcing(false)}
			/>
		</>
	);
};

export default UserSourcingAddBtn;
