import { Group } from '@visx/group';
import { Pie } from '@visx/shape';
import { useTheme } from '@emotion/react';
import { LinearGradient } from '@visx/gradient';
import { Space } from 'antd';
import { normalizePct } from '../../../../../utils/common';
import GenderScoreLegendBlock from '../blocks/GenderScoreLegendBlock';

/**
 * @param {*} values - Values to render. Format: [{value, label}]
 * @param {*} [graphColors] - Optional. Format: [from, to, bg]. If not set, use theme.chart.radial
 * @param {number} [width] - Optional. Width of the chart.
 * It sets the width of the outest circumference.
 * Default: 200
 * @param {number} [lineWidth] - Optional. Width of each circumference. Default: 10
 * @param {number} [gap] - Optional. Distance between circumferences. Default: 22
 * @param {number} [fontSize] - Optional. Font size of the legend. Default: 16
 * @param {boolean} [withLegend] - Optional. Whether to show the legend or not. Default: true
 * @param {string} [legendMargin] - Optional. Left margin of the legend. Default: 70%
 * @param {number} [graphLegendRatio] - Optional. Number between 0 and 1.
 * Ratio between the graph and legend sizes.
 * It defines the size of the legend with regards the graph. Default: 0.7
 * @param {*} [margin] - Optional. Internal margins of the chart.
 * Format: {top, bottom, left, right}.
 * Default: {
		top: 20, bottom: 0, left: 0, right: 0,
	}
 * @param {boolean} [withBgPie] - Optional. Whether to show the background in a different pie.
 * It affects to the round corners effect. Default: true
 * @returns RadialGraph component
 */
const RadialGraph = ({
	values,
	graphColors,
	width = 200,
	lineWidth = 10,
	gap = 22,
	fontSize = 16,
	withLegend = true,
	legendMargin = '60%',
	graphLegendRatio = 0.7,
	margin = {
		top: 20, bottom: 0, left: 0, right: 0,
	},
	withBgPie = true,
	...props
}) => {
	if (!values?.length) {
		return null;
	}
	const theme = useTheme();
	const colors = graphColors || theme.chart.radial;
	const gradientId = 'radial_fill';
	const half = width / 2;
	const height = width + margin?.top;

	const normalizedValues = values.map((v) => normalizePct({ value: v.value }));

	// Type affects the sort of the pie values.
	// We want the main percentage to go first --> use A-Z sort
	const ratios = normalizedValues.map((v, i) => [
		{
			type: 'A',
			value: v,
			color: colors[i] ? `url(#${gradientId}-${i})` : theme.chart.ratio.from,
		},
		{
			type: 'B',
			value: 100 - v,
			color: colors[i]?.bg || theme.chart.ratio.bg,
		},
	]);
	const bgRatio = [
		{
			type: 'B',
			value: 100,
			color: theme.chart.ratio.bg,
		},
	];
	const valueMapper = (data) => data.value;

	const legendItems = values.map((v, i) => {
		return {
			value: normalizePct({ value: v?.value }),
			color: colors[i]?.from || theme.chart.ratio.from,
			label: v?.label,
			isPct: true,
		};
	});

	const RadialPie = ({ data, key, i }) => <Pie
		key={key}
		data={data}
		pieValue={valueMapper}
		outerRadius={half - i * gap}
		innerRadius={half - i * gap - lineWidth}
		padAngle={0.01}
		cornerRadius={15}
		pieSort={(a, b) => a.type - b.type}
	>
		{(pie) => {
			return pie.arcs.map((arc, index) => {
				return <g
					key={`${arc.data.type}-${index}`}
				>
					<path
						d={pie.path(arc)}
						fill={arc.data.color} />
				</g>;
			});
		}}
	</Pie>;

	// Note: Point (0,0) is the center of the chart
	return <Space style={{ ...props?.style }} direction='vertical'>
		<svg width={width} height={height}>
			<defs>
				{colors.map(((color, i) => <LinearGradient
					key={ `${gradientId}-${i}`}
					from={color?.from} to={color?.to}
					id={ `${gradientId}-${i}`}
				/>))}
			</defs>
			<Group top={half + margin?.top} left={half + margin?.left}>

				{ratios.map((ratio, i) => <>
					{withBgPie && <RadialPie
						i={i}
						key={`ratio-bg-${i}`}
						data={bgRatio}
					/>}
					<RadialPie
						i={i}
						key={`ratio-${i}`}
						data={ratio}
					/>
				</>)}
			</Group>
		</svg>
		{withLegend && <GenderScoreLegendBlock
			items={legendItems}
			width={width * graphLegendRatio}
			style={{
				marginLeft: legendMargin,
			}}
			fontSize={fontSize}
		/>
		}
	</Space>;
};

export default RadialGraph;
