import { useAuthContext } from '../../../hooks/useAuthContext';
import UserStub from '../../user/stub/UserStub';

const UMSUserStub = ({
	user,
	...props
}) => {
	const className = 'UMSUserStub';

	const { isStaffView } = useAuthContext();

	return (
		<>
			<div
				{...props}
				style={{
					fontWeight: 'bold',
					whiteSpace: 'nowrap',
					...props?.style,
				}}
			>
				{user?.firstName} {user?.lastName}
			</div>
			{/* {isStaffView && <span style={{ fontSize: 9, color: '#ccc' }} >{user?.slug}</span>} */}
		</>
	);
	return (
		<UserStub
			user={user}
			avatarSize={20}
			withLocation={false}
			withProfession={false}
			withCompanyLogo={false}
			withIsUnviewed={false}
			withCoachedTag={false}
			withResumeTag={false}
			withDropdown={isStaffView}
			style={{ avatarSpacing: 6 }}
			{...props}
		/>
	);
};

export default UMSUserStub;
