import { Space } from 'antd';
import { useTheme } from '@emotion/react';
import { useAuthContext } from '../../../hooks/useAuthContext';
import { FaMedal } from 'react-icons/fa';
import UserModel from '../../../models/UserModel';
import UserGridCardLabelValue from '../grid/card/blocks/UserGridCardLabelValue';
import UserGridCardCareerHardSkill from '../grid/card/blocks/UserGridCardCareerHardSkill';
import UserGridCardCareerSpecific from '../grid/card/blocks/UserGridCardCareerSpecific';
import UserGridCardJobSearchJobContract from '../grid/card/blocks/UserGridCardJobSearchJobContract';
import UserGridCardJobSearchJobLevel from '../grid/card/blocks/UserGridCardJobSearchJobLevel';
import UserGridCardJobSearchJobRoles from '../grid/card/blocks/UserGridCardJobSearchJobRoles';
import UserGridCardCoachingOpinion from '../grid/card/blocks/UserGridCardCoachingOpinion';
import UserResumeContactCardBody from '../card/resume/UserResumeContactCardBody';
import UserGridCardResumeCertification from '../grid/card/blocks/UserGridCardResumeCertification';
import UserGridCardJobSearchJobLocation from '../grid/card/blocks/UserGridCardJobSearchJobLocation';
import UserGridCardSuggested from '../grid/card/UserGridCardSuggested';
import UserGridCardJobSearchJobRemote from '../grid/card/blocks/UserGridCardJobSearchJobRemote';
import UserGridCardCareerLanguage from '../grid/card/blocks/UserGridCardCareerLanguage';
import UserGridCardJobSearchEmploymentVisa from '../grid/card/blocks/UserGridCardJobSearchEmploymentVisa';
import { rowSpanner } from '../grid/card/UserGridCardUtils';
import UserGridCardName from '../grid/card/UserGridCardName';
import UserGridCardTopButtons from '../grid/card/UserGridCardTopButtons';
import UserGridCardResumeExperienceList from '../grid/card/blocks/UserGridCardResumeExperienceList';
import UserGridCardResumeEducationList from '../grid/card/blocks/UserGridCardResumeEducationList';
import UserGridCardExpander from '../grid/card/blocks/UserGridCardExpander';
import UserProfileEmailCardBody from '../card/profile/UserProfileEmailCardBody';

const UserProfileRightColumn = (
	{
		user,
		onRefresh,
		...props
	},
) => {
	const className = 'UserProfileRightColumn';

	const theme = useTheme();

	const { isStaffView } = useAuthContext();

	const userModel = new UserModel(user);

	const linksBlock = (
		<UserGridCardLabelValue
			label={<>Links</>}
			style={{ display: 'block', width: '100%' }}
		>
			<UserResumeContactCardBody
				user={userModel}
				vertical={false}
			/>
		</UserGridCardLabelValue>
	);

	return (
		<>
			<div style={{
				position: 'relative',
				padding: '30px 40px 20px 40px',
				width: '100%',
			}}>

				<Space
					align='top'
					style={{
						width: '100%',
						justifyContent: 'space-between',
					}}
				>

					<UserGridCardName
						user={user}
					/>

					<Space size={10}>

						<UserGridCardTopButtons
							user={user}
						/>

					</Space>
				</Space>

				<Space
					direction='vertical'
					size={20}
					style={{
						width: '100%',
					}}
				>
					{rowSpanner([
						[
							[
								{
									content: (
										<UserGridCardLabelValue
											label={'Biography'}
										>
											<UserGridCardExpander
												maxCollapsedHeight={140}
											>
												{userModel.getProfileBiography()}
											</UserGridCardExpander>
										</UserGridCardLabelValue>
									),
									span: 16,
									check: userModel.hasProfileBiography(),
								},
								{
									content: (
										<UserGridCardLabelValue
											label={'Email'}
										>
											<UserProfileEmailCardBody user={user} />
										</UserGridCardLabelValue>
									),
									span: userModel.hasProfileBiography() ? 8 : 24,
								},
								{
									content: (
										<UserGridCardCoachingOpinion
											user={user}
											opened={true}
											style={{
												width: '100%',
												body: { fontSize: 14 },
											}}
										/>
									),
									span: 24,
									check: userModel.isCoachingVisible({ isStaffView }),
								},
							],
						],
						[
							[
								{
									content: <>
										<UserGridCardJobSearchJobRoles user={user} />
										<UserGridCardJobSearchJobLevel
											user={user}
											style={{ marginTop: 12 }}
										/>
									</>,
									span: 9,
									check: userModel.hasJobSearchJobRoles(),
								},
								{
									content: <UserGridCardJobSearchJobLocation user={user} />,
									span: 9,
									check: userModel.hasJobSearchJobLocations(),
								},
								{
									content: <>
										<UserGridCardJobSearchEmploymentVisa
											user={user}
										/>
										<UserGridCardJobSearchJobRemote
											user={user}
											style={{ marginTop: 12 }}
										/>
									</>,
									span: 6,
								},
							],
						],
						[[
							{
								content:
										rowSpanner(
											[[[
												{
													content: (
														<UserGridCardCareerHardSkill
															user={user}
															grouped={true}
														/>
													),
													span: 24,
												},
												{
													content: <UserGridCardResumeCertification
														user={user}
														placement='left'
														withSeparator={(
															<>
																<FaMedal
																	style={{
																		color: theme.color.grey,
																		marginBottom: -2,
																	}} />
																&nbsp;
															</>
														)}
														defaultContainer={'div'}
													/>,
													span: 24,
													check: userModel.hasResumeCertifications(),
												},
											]]],
											[0, 24],
											0,
										),
								span: userModel.hasCareerLanguages() ? 18 : 24,
							},
							{
								content: <UserGridCardCareerLanguage user={user} />,
								span: 6,
								check: userModel.hasCareerLanguages(),
							},
						]],
						[
							// [
							// 	{
							// 		content: <UserGridCardResumeLatestExperienceExpandable
							// 			user={user}
							// 			style={{ display: 'block', width: '100%' }}
							// 		/>,
							// 		span: 24,
							// 		check: userModel.hasResumeExperience(),
							// 	},
							// ],
							[
								{
									content: (
										<UserGridCardLabelValue
											label={<>Experiences</>}
											style={{ display: 'block', width: '100%' }}
										>
											<UserGridCardResumeExperienceList
												items={user?.getResumeExperienceFlattened() || []}
											/>
										</UserGridCardLabelValue>
									),
									span: 24,
									check: userModel.hasResumeExperience(),
								},
							],
							[
								{
									content: (
										<UserGridCardLabelValue
											label={<>Education</>}
											style={{ display: 'block', width: '100%' }}
										>
											<UserGridCardResumeEducationList
												items={user?.getResumeEducation()?.degrees || []}
											/>
										</UserGridCardLabelValue>
									),
									span: 24,
									check: userModel.hasResumeExperience(),
								},
							],
							[
								{
									content: <UserGridCardCareerSpecific user={user} />,
									span: 14,
									check: userModel.hasCareerSpecifics(),
								},
								{
									content: <UserGridCardJobSearchJobContract user={user} />,
									span: 10,
									check: userModel.hasJobSearchJobContracts(),
								},
							],
						],
						[
							[
								{
									content: linksBlock,
									span: 24,
									check: user?.information?.website || userModel.hasResumeContact(),
								},
							],
							[],
						],
					])}
				</Space>

				<UserGridCardSuggested user={user} />

			</div>

		</>
	);
};

export default UserProfileRightColumn;
