import { useTheme } from '@emotion/react';
import {
	Space, Tooltip,
} from 'antd';
import { getPillarLabel } from '../../../../constants/genderscore-pillars';
import GenderScoreActionDetailsLink from './GenderScoreActionDetailsLink';
import GenderScoreActionSelectButton from './GenderScoreActionSelectButton';
import GenderScoreLayoutBox from '../layout/GenderScoreLayoutBox';
import GenderScorePillarLogoLabel from '../pillars/GenderScorePillarLogoLabel';

const GenderScoreActionBox = ({
	company,
	forceValue,
	action,
	pillar,
	level,
	withSelectButton,
	pillarIndex,
	levelIndex,
	actionIndex,
	index,
	withBackground = true,
	onAddSuccess,
	onRemoveSuccess,
	...props
}) => {
	const className = 'GenderScoreActionBox';

	const theme = useTheme();

	const genderScore = forceValue || company?.genderScore;

	return (
		<GenderScoreLayoutBox
			key={index}
			style={{
				marginBottom: 6,
				paddingBottom: 48,
				minHeight: 240,
				...withBackground && {
					background: action?.isSelected
						? theme.color.fitBlueElectric
						: 'white',
					color: action?.isSelected
						? 'white'
						: 'black',
				},
			}}
		>
			<Space
				direction='vertical'
				size={2}
				style={{ width: '100%' }}
			>
				<Space
					wrap={false}
					style={{
						width: '100%',
						justifyContent: 'space-between',
						alignItems: 'center',
					}}
				>

					<Tooltip
						title={
							<>
								This action is useful to improve your performance
								for the <strong>{getPillarLabel(pillar)}</strong> pillar
							</>
						}
					>
						<div style={{ userSelect: 'none' }}>
							<GenderScorePillarLogoLabel
								pillar={pillar}
								style={{
									...action.isSelected
									&& {
										label: { color: 'white' },
									},
								}}
							/>
						</div>
					</Tooltip>

				</Space>

				<div
					style={{
						fontSize: 14,
						fontWeight: 'bold',
						marginTop: 12,
						marginBottom: 24,
					}}
				>
					{action?.title}
				</div>

			</Space>

			<div
				style={{
					position: 'absolute',
					bottom: 24,
					right: 24,
					width: 'calc(100% - 48px)',
				}}
			>
				<Space
					style={{
						width: '100%',
						justifyContent: 'space-between',
						alignItems: 'center',
					}}
				>
					<GenderScoreActionDetailsLink
						company={company}
						forceValue={forceValue}
						action={action}
						label={'Details'}
						onAddSuccess={onAddSuccess}
						onRemoveSuccess={onRemoveSuccess}
						style={{
							...withBackground
							&& action?.isSelected
							&& {
								color: 'white',
							},
						}}
					/>

					{withSelectButton && (
						<GenderScoreActionSelectButton
							company={company}
							forceValue={forceValue}
							action={action}
							isSelected={action?.isSelected}
							onAddSuccess={onAddSuccess}
							onRemoveSuccess={onRemoveSuccess}
						/>
					)}

				</Space>
			</div>
		</GenderScoreLayoutBox>
	);
};

export default GenderScoreActionBox;
