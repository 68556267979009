import {
	Button, Card, Checkbox, Dropdown, Menu, Space, Typography,
} from 'antd';
import { SyncOutlined } from '@ant-design/icons';
import TextSearch from './TextSearch';
import { useTheme } from '@emotion/react';
import { FaColumns } from 'react-icons/fa';
import { useContext, useEffect, useState } from 'react';
import { LocalDebug, localDebug } from '../../../utils/LocalDebug';
import TableSorter from './TableSorter';
import { useAuthContext } from '../../../hooks/useAuthContext';
import { AppDataContext } from '../../../contexts/AppDataProvider';

const TableHeader = (
	{
		total,
		showTotal,
		rowType = 'row',
		totalLimit,
		isTotalLimitEnabled = false,
		onRefresh,
		onSearchChange,
		columnsFilterOptions,
		withRefresh = true,
		withDynamicSearch,
		withSort,
		sortOptions,
		sortStyle,
		onSortChange,
		defaultColumnsFilterSelected,
		onChangeColumnsFilterOptions,
		columnsDisplayOptions = [],
		defaultColumnsDisplaySelected,
		onChangeColumnsDisplayOptions,
		onSearch,
	},
) => {
	const className = 'TableHeader';

	const theme = useTheme();

	const { dataFetcherIndicatorTotalLimit } = useContext(AppDataContext);
	const { isStaffView } = useAuthContext();

	const [disabled, setDisabled] = useState(true);

	const toggleCheckedAll = (status) => {
		const selected = status ? columnsDisplayOptions.map((c) => c.value) : [];
		localDebug('toggleCheckedAll', { status, selected });
		onChangeColumnsDisplayOptions(selected);
	};

	const createColumnsSelectorMenu = () => columnsDisplayOptions?.length > 0
		&& <Menu>
			<Space direction="vertical">
				<Card style={{ maxHeight: 200, overflow: 'auto' }}>
					<Checkbox.Group style={{ width: '100%' }} onChange={onChangeColumnsDisplayOptions}>
						{columnsDisplayOptions.map((option, index) => {
							return (
								<div key={index}>
									<Checkbox value={option.value} key={index}>
										{`${option.label.charAt(0).toUpperCase()}${option.label.slice(1)}`}
									</Checkbox>
								</div>
							);
						})}
					</Checkbox.Group>
				</Card>
				<Space direction="horizontal">
					<Button type="link" disabled={disabled} onClick={() => toggleCheckedAll(true)}>Select All</Button>
					<Button type="link" disabled={!disabled} onClick={() => toggleCheckedAll(false)}>Deselect
						All</Button>
				</Space>
			</Space>
		</Menu>;

	let columnsSelectorMenu = createColumnsSelectorMenu();

	useEffect(() => {
		// localDebug('useEffect', { columnsDisplayOptions });
		setDisabled(columnsDisplayOptions?.length === defaultColumnsDisplaySelected?.length);
		columnsSelectorMenu = createColumnsSelectorMenu();
	}, [columnsDisplayOptions, defaultColumnsDisplaySelected]);

	let totalLabel = <>{total} {rowType}{total > 1 && 's'}</>;
	LocalDebug.logNull({ className }, { isTotalLimitEnabled });
	if (
		!isStaffView
		&& isTotalLimitEnabled
	) {
		const totalLimitComputed = totalLimit || dataFetcherIndicatorTotalLimit;
		LocalDebug.logNull({ className }, { totalLimitComputed });
		if (
			totalLimitComputed > 0
			&& total > totalLimitComputed
		) {
			totalLabel = <>{`${totalLimitComputed}+ ${rowType}s`}</>;
			LocalDebug.logNull({ className }, { totalLabel });
		}
	}
	return (
		<>
			<div className='flexRow alignCenter spaceBetween'>
				{withRefresh && <Space>
					{onRefresh
						&& <Button onClick={onRefresh} type="link" color={theme.color.blue} icon={<SyncOutlined/>}/>}
					{/* showTotal: {showTotal? 'true': 'false'}, total: {total} */}
					{showTotal && <Typography>
						<b>{
							total > 0
								? <>{totalLabel}</>
								: `No ${rowType}s`
						}</b>
					</Typography>}
				</Space>}
				<Space>
					{withDynamicSearch && <TextSearch
						onSearch={onSearch}
						onChange={onSearchChange}
						columnsFilterOptions={columnsFilterOptions}
						onChangeColumnsFilterOptions={onChangeColumnsFilterOptions}
						defaultColumnsFilterSelected={defaultColumnsFilterSelected}
					/>}
					{columnsDisplayOptions?.length > 0
						&& <Dropdown className="filter" overlay={columnsSelectorMenu}>
							<Button
								icon={<FaColumns style={{ marginBottom: -2 }}/>}
							/>
						</Dropdown>
					}
					{withSort && sortOptions?.length > 0 && <TableSorter
						onChange={onSortChange}
						sortOptions={sortOptions}
						sortStyle={sortStyle}
					/>}
				</Space>
			</div>
		</>
	);
};
export default TableHeader;
