import { useTheme } from '@emotion/react';
import { useEffect, useState } from 'react';
import { Space, Tooltip } from 'antd';
import Table from '../../../app/table/Table';
import TableColumns from '../../../app/table/TableColumns';
import moment from 'moment';
import CompanyLogo from '../../../company/CompanyLogo';
import { useSelector } from 'react-redux';
import { getCompanies, getRecruiters } from '../../../../reducers/app';
import { documentId, pct } from '../../../../utils/common';
import UserAvatar from '../../../user/widgets/UserAvatar';
import { LocalDebug } from '../../../../utils/LocalDebug';
import UserAvatarTooltipTitle from '../../../user/widgets/UserAvatarTooltipTitle';
import { FaBatteryEmpty, FaBatteryFull, FaBatteryHalf } from 'react-icons/fa';
import { upperFirst } from 'lodash';

const AppStatsTable = (
	{
		onDataLoaded,
		path = '/stats',
		filterOptions = {},
		isRefresh = 0,
		onDocumentIdSelected,
		onSearchDataColumn,
		...props
	},
) => {
	const className = 'AppStatsTable';

	const theme = useTheme();

	const [isRefreshDataLayout] = useState(0);
	const [statColumns, setStatColumns] = useState([]);
	const companies = useSelector(getCompanies);
	const recruiters = useSelector(getRecruiters);

	const createdAtCol = TableColumns.createdAtColumn(theme);
	const updatedAtCol = TableColumns.updatedAtColumn(theme);

	const jsonCol = TableColumns.jsonColumn(theme);

	const statusCol = {
		title: '',
		dataIndex: '_id',
		align: 'center',
		render: (value, row) => {
			if (row?.isComputed === false) {
				return (
					<Tooltip title={<>Stat not yet computed</>}>
						<FaBatteryEmpty style={{
							fontSize: 22, marginBottom: -2, color: `${theme.color.grey}!important`, opacity: 0.5,
						}}/>
					</Tooltip>
				);
			}
			if (row?.isComplete) {
				return (
					<Tooltip title={<>Stat computed & complete!</>}>
						<FaBatteryFull style={{ fontSize: 22, marginBottom: -2, color: theme.color.darkturquoise }}/>
					</Tooltip>
				);
			}
			if (!row?.isComplete) {
				return (
					<Tooltip title={<>Stat computed, but incomplete...</>}>
						<FaBatteryHalf style={{ fontSize: 22, marginBottom: -2, color: theme.color.fitBlueElectric }}/>
					</Tooltip>
				);
			}
		},
		...TableColumns.columnFixedWidth(40),
	};

	const labelCol = {
		title: 'Label',
		dataIndex: '_id',
		render: (value, row) => {
			const {
				period, date, documentId, extra,
			} = row;
			// const label = `${period} starting ${moment(date).format('MMM Do YYYY')}`;
			const label = moment(date).format(
				{
					// day: 'ddd MMM Do YYYY',
					day: 'YYYY-MM-DD (dd)',
					isoWeek: '[Week] W YYYY [(]MMM Do[)]',
					month: 'MMM YYYY',
					quarter: '[Q]Q YYYY',
					year: 'YYYY',
				}[period] || 'YYYY-MM-DD HH:mm:ss',
			);
			return <b>{upperFirst(label)}</b>;
		},
		...TableColumns.columnMinWidth(150),
	};

	const valueDocCol = TableColumns.docsCountColumn(
		theme,
		{
			title: 'Value',
			dataIndex: 'value',
			icon: null,
			align: 'center',
			sorter: false,
			removeInColumnFilter: true,
			render: (value) => (value
				? TableColumns.docsCountColumn(theme, { icon: null }).render(value)
				: TableColumns.docsCountColumn(theme, {
					icon: null, background: 'none', color: theme.color.lightgrey,
				}).render('0')
			),
			// value: (value) => value || '0',
			...TableColumns.columnFixedWidth(80),
		},
	);

	const valueCol = {
		title: 'Value',
		dataIndex: 'value',
		icon: null,
		align: 'center',
		...TableColumns.columnFixedWidth(80),
	};

	const companiesCol = {
		title: 'Companies',
		dataIndex: ['extra', 'companyIds'],
		render: (values) => {
			if (!values?.length) return null;
			return (
				<Space size={2} wrap={true}>
					{values
						.map((id, i) => {
							const company = companies?.find((c) => documentId(c) === id);
							if (!company) return null;
							return (
								<CompanyLogo
									key={i}
									company={company}
									size={24}
									onClick={() => onDocumentIdSelected?.(id)}
								/>
							);
						})
						.filter((i) => i)
					}
				</Space>
			);
		},
		...TableColumns.columnMinWidth(300),
	};

	const companyCountsCol = {
		title: 'Companies',
		dataIndex: ['extra', 'companyIdCounts'],
		render: (values) => {
			if (!values?.length) return null;
			return (
				<Space size={2} wrap={true}>
					{values
						.map((o, i) => {
							const company = companies?.find((c) => documentId(c)?.toString?.() === documentId(o)?.toString?.());
							if (!company) return null;
							return (
								<Tooltip
									key={i}
									title={(
										<Space direction='vertical' size={2} align='center'>
											{company?.name}
											<b>{o?.count}</b>
										</Space>
									)}
								>
									<CompanyLogo
										company={company} size={24}
										withTooltip={false}
										onClick={() => onDocumentIdSelected?.(documentId(o))}
									/>
								</Tooltip>
							);
						})
						.filter((i) => i)
					}
				</Space>
			);
		},
		...TableColumns.columnMinWidth(300),
	};

	const recruitersCol = {
		title: 'Recruiters',
		dataIndex: ['extra', 'userIds'],
		render: (values) => {
			if (!values?.length) return null;
			return (
				<Space size={2} wrap={true}>
					{values
						.map((id, i) => {
							const recruiter = recruiters?.find((item) => (documentId(item))?.toString?.() === id?.toString?.());
							if (!recruiter) return null;
							return (
								<UserAvatar
									key={i}
									user={recruiter}
									size={24}
									withIsUnviewed={false}
									withCompanyLogo={false}
									withIsNew={false}
									onClick={() => onDocumentIdSelected?.(id)}
								/>
							);
						})
						.filter((i) => i)
					}
				</Space>
			);
		},
		...TableColumns.columnMinWidth(300),
	};

	const recruiterCountsCol = {
		title: 'Recruiters',
		dataIndex: ['extra', 'userIdCounts'],
		render: (values) => {
			if (!values?.length) return null;
			return (
				<Space size={2} wrap={true}>
					{values
						.map((o, i) => {
							const recruiter = recruiters?.find((item) => documentId(item)?.toString?.() === documentId(o)?.toString?.());
							if (!recruiter) return null;
							return (
								<Tooltip
									key={i}
									title={(
										<Space direction='vertical' size={2} align='center'>
											<UserAvatarTooltipTitle user={recruiter}/>
											<b>{o?.count}</b>
										</Space>
									)}
								>
									<UserAvatar
										user={recruiter}
										size={24}
										withTooltip={false}
										withIsUnviewed={false}
										withCompanyLogo={false}
										withIsNew={false}
										onClick={() => onDocumentIdSelected?.(documentId(recruiter))}
									/>
								</Tooltip>
							);
						})
						.filter((i) => i)
					}
				</Space>
			);
		},
		// render: (value) => JSON.stringify(value),
		...TableColumns.columnMinWidth(300),
	};

	const userCountriesCountCol = {
		title: 'Countries',
		dataIndex: ['extra', 'countries'],
		render: (values, row) => (
			<Space size={[2, 2]} wrap={true}>
				{values?.slice(0, 10).map(({
					value, label, flag, count,
				}, i) => (
					<Tooltip
						key={i}
						title={<>{label} ({pct(count, row?.value)}%)</>}
					>
						<div style={{
							padding: '1px 6px', border: '1px solid #ddd', borderRadius: 30,
						}}>{flag} <b>{count}</b></div>
					</Tooltip>
				))}
				{values?.slice(10)?.length > 0
					&& (
						<Tooltip
							key={-1}
							title={
								<Space size={8} wrap={true} style={{ fontSize: 10 }}>
									{values?.slice(10)
										.map(({
											value, label, flag, count,
										}, index) => (
											<span key={index}>{flag} {label}: <b>{count}</b></span>
										))
									}</Space>}
						>
							<div
								style={{
									background: '#eee',
									color: '#999',
									padding: '1px 6px',
									border: '1px solid #eee',
									borderRadius: 30,
								}}
							>
								+{values?.slice(10)?.length || 0}
							</div>
						</Tooltip>
					)
				}
			</Space>
		),
		...TableColumns.columnFixedWidth(300),
	};

	const userPositionsCountCol = {
		title: 'Positions',
		dataIndex: ['extra', 'positions'],
		render: (values) => (
			<Space size={[2, 2]} wrap={true}>
				{values?.slice(0, 5)
					.map(({ value, count }, index) => (
						<div key={index} style={{
							padding: '1px 6px', border: '1px solid #ddd', borderRadius: 30,
						}}>{value} <b>{count}</b></div>
					))}
				{values?.slice(5)?.length > 0
					&& (
						<Tooltip
							key={-1}
							title={
								<Space
									size={8}
									wrap={true}
									style={{ fontSize: 10 }}
								>
									{values?.slice(10)
										.map(({ value, count }, i) => (
											<span key={i}>{value}: <b>{count}</b></span>
										))
									}</Space>}
						>
							<div
								style={{
									background: '#eee',
									color: '#999',
									padding: '1px 6px',
									border: '1px solid #eee',
									borderRadius: 30,
								}}
							>
								+{values?.slice(10)?.length || 0}
							</div>
						</Tooltip>
					)
				}
			</Space>
		),
		...TableColumns.columnFixedWidth(300),
	};

	const userAuthCompletePctCol = {
		title: 'Auth complete',
		dataIndex: ['extra', 'authComplete'],
		render: (value, row) => `${pct(value || 0, row?.value || 0)}%`,
		...TableColumns.columnFixedWidth(60),
	};

	const userOnboardedPctCol = {
		title: 'Onboarded',
		dataIndex: ['extra', 'onboardedCount'],
		render: (value, row) => `${pct(value || 0, row?.value || 0)}%`,
		...TableColumns.columnFixedWidth(60),
	};

	const userHuntablePctCol = {
		title: 'Huntable',
		dataIndex: ['extra', 'huntable'],
		render: (value, row) => `${pct(value || 0, row?.value || 0)}%`,
		...TableColumns.columnFixedWidth(60),
	};

	const userCareerActivePctCol = {
		title: 'Active',
		dataIndex: ['extra', 'activeCount'],
		render: (value, row) => `${pct(value || 0, row?.value || 0)}%`,
		...TableColumns.columnFixedWidth(60),
	};

	const fillerCol = {
		title: '',
		...TableColumns.columnMinWidth(100),
	};

	useEffect(() => {
		const { stat, documentId } = filterOptions || {};
		LocalDebug.logUseEffect({ className, effects: 'filterOptions' }, { stat, documentId });
		setStatColumns([]);
		switch (true) {
			case stat?.indexOf('user-registered') === 0:
				setStatColumns([
					userAuthCompletePctCol,
					userCareerActivePctCol,
					userOnboardedPctCol,
					userHuntablePctCol,
					userCountriesCountCol,
					userPositionsCountCol,
				]);
				break;
			case stat?.indexOf('company-page') === 0:
			case stat?.indexOf('company-jobs') === 0:
				setStatColumns([...documentId ? [fillerCol] : [companyCountsCol]]);
				break;
			case stat?.indexOf('company-logs') === 0:
			case stat?.indexOf('company-messages') === 0:
			case stat?.indexOf('company-talents') === 0:
			case stat?.indexOf('company-templates') === 0:
			case stat?.indexOf('company-users') === 0:
			case stat?.indexOf('recruiter-logs') === 0:
			case stat?.indexOf('recruiter-messages') === 0:
				setStatColumns([...documentId ? [recruiterCountsCol] : [companyCountsCol, recruiterCountsCol]]);
				break;
			default:
				// case stat?.indexOf('company-messages') === 0:
				// case stat?.indexOf('company-talents') === 0:
				// // setStatColumns([...documentId ? [] : [companyCountsCol, recruiterCountsCol]]);
				// // break;
				// case stat?.indexOf('company-logs') === 0:
				// 	setStatColumns([...documentId ? [recruiterCountsCol] : [companyCountsCol, recruiterCountsCol]]);
				// 	break;
				// case stat?.indexOf('recruiter-logs') === 0:
				// case stat?.indexOf('recruiter-messages') === 0:
				// setStatColumns([...documentId ? [] : [companyCountsCol, recruiterCountsCol]]);
				setStatColumns([companyCountsCol, recruiterCountsCol]);
				break;
		}
	}, [filterOptions]);

	return (
		<>
			<Table
				path={path}
				onDataLoaded={onDataLoaded}
				columns={[
					jsonCol,
					statusCol,
					labelCol,
					valueDocCol,
					// valueCol,
					...statColumns,
				]}
				filterOptions={filterOptions}
				isRefresh={isRefresh + isRefreshDataLayout}
				onSearchDataColumn={onSearchDataColumn}
				showTotal={(total) => `Total ${total} items`}
				limit={50}
				withDynamicSearch={false}
				rowClassName={(row, index) => (row.isWeekend ? 'rowWeekend' : '')}
				{...props}
			/>
		</>
	);
};

export default AppStatsTable;
