import { ENGLISH_LANG } from '../../../../constants/constant';
import { displayHtml } from '../../../../utils/common';
import BoxRainbow from '../../../app/box/BoxRainbow';
import CompanyFieldNotProvided from '../shared/CompanyFieldNotProvided';
import CompanyLogo from '../../CompanyLogo';

const CompanyLogoDisplay = (
	{
		company,
		lang = ENGLISH_LANG,
	},
) => {
	const className = 'CompanyLogoDisplay';

	return (
		<CompanyLogo
			company={company}
			size={140}
			style={{
				boxShadow: '0 2px 20px -12px rgba(0,0,0,.25)',
			}}
		/>
	);
};

export default CompanyLogoDisplay;
