import {
	getAtsArchiveReasons,
	getAtsPipelines,
	getCompanies,
	getCompanyAccesses,
} from '../reducers/app';
import CompanyService from '../services/company';
import { loadJobs } from './jobs';
import {
	ALL_ACCESS_LOADED, ATS_ARCHIVE_REASONS_LOADED,
	ATS_PIPELINES_LOADED,
	COMPANIES_LOADED,
	COMPANY_ACCESSES_LOADED,
	COMPANY_LOADED,
	COMPANY_GENDERSCORE_SURVEY_DRAFT_SELECTED,
	COMPANY_UMS_SURVEY_DRAFT_SELECTED,
	COMPANY_PLAN_EXTRA_TAGS_LOADED,
	LAST_BATCH_LOADED, RECRUITERS_LOADED,
	SET_COMPANY_ACCESS_SELECTED,
	SET_COMPANY_SELECTED,
} from './types';
import {
	action, actionWithLoader, basicActionBuilder, push,
} from './utils';
import { LocalDebug, localDebug } from '../utils/LocalDebug';
import { message } from 'antd';
import { documentId } from '../utils/common';
import { PATHS } from '../constants/constant';
import { COMPANY_SELECTED_QUERY_PARAM } from '../contexts/CompanyProvider';
// import ConfigureCompanyPageMenu from '../menu/configure/ConfigureCompanyPageMenu';

const className = 'actions.company';

// Storing the last origin (=page) from whence loadCompanies
// was called, to avoid multiple unnecessary calls
let loadCompaniesLastOrigin;

/**
 * load all companies into redux
 * @param {*} atsProvider
 * @returns
 */
export const loadCompanies = ({
	options = {},
	defaultCompanyIdSelected,
	origin,
} = {
	options: {},
}) => {
	const method = 'loadCompanies';
	LocalDebug.logInfo({ className, method }, {
		origin,
		loadCompaniesLastOrigin,
		options,
		defaultCompanyIdSelected,
	});
	return actionWithLoader(async (dispatch) => {
		try {
			if (loadCompaniesLastOrigin === origin) {
				LocalDebug.logInfo({ className, method }, 'exiting because same origin', { loadCompaniesLastOrigin, origin });
				return;
			}
			const { data } = await CompanyService
				.findAll({
					withAccess: true,
					withGenderScoreSurveys: false,
					withGenderScoreActions: true,
					withCompanyActionPlan: true,
					withUMSResults: false,
					withUMSSurveys: false,
					defaultCompanyIdSelected,
					...options || {},
				});
			loadCompaniesLastOrigin = origin;
			LocalDebug.logInfo({ className, method }, COMPANIES_LOADED, { origin, data });
			LocalDebug.logInfo({ className, method }, COMPANIES_LOADED, {
				genderScoreSurveys: data?.companies?.[0]?.genderScoreSurveys,
			});
			dispatch({
				type: COMPANIES_LOADED,
				companies: data.companies,
			});

			const companyIdSelected = data.companies.length === 1 ? documentId(data.companies[0]) : null;
			if (defaultCompanyIdSelected || companyIdSelected) {
				dispatch(setCompanySelected(defaultCompanyIdSelected || companyIdSelected));
			}
		} catch (error) {
			if (error.response.status) {

			}
		}
	});
};

export const loadRecruiters = (options = {}) => {
	const method = 'loadRecruiters';
	// LocalDebug.logInfo({ className, method }, { options, defaultCompanyIdSelected });
	return actionWithLoader(async (dispatch) => {
		try {
			const { data } = await CompanyService.findAllRecruiters({ ...options || {} });
			dispatch({
				type: RECRUITERS_LOADED,
				recruiters: data.recruiters,
			});
		} catch (error) {
			if (error.response.status) {}
		}
	});
};

/**
 * load company into redux
 * @param {*} companyId
 * @returns
 */
export const loadCompany = (companyId, options = {}) => {
	LocalDebug.logInfo({ className, method: 'loadCompany' }, { companyId, options });
	return async (dispatch) => {
		try {
			const { data: { company } } = await CompanyService.getById(companyId, options);
			LocalDebug.logInfo({ className, method: 'loadCompany' }, 'fetched', { company });
			dispatch({
				type: COMPANY_LOADED,
				company,
			});
		} catch (error) {
			LocalDebug.logError({ className, method: 'loadCompany', error });
		}
	};
	// });
};

/**
 * load lastBatch into redux
 * @param {*} companyId
 * @returns
 */
export const loadLastBatch = (companyId) => {
	return async (dispatch) => {
		try {
			const { data } = await CompanyService.getLastBatch(companyId);
			dispatch({
				type: LAST_BATCH_LOADED,
				batch: data.batch,
			});
		} catch (error) {
			localDebug(__filename.split('/').shift(), error);
		}
	};
};

export const getAllAccessByCompany = (companyId) => {
	return actionWithLoader(async (dispatch) => {
		try {
			const { data } = await CompanyService.getAllAccess(companyId);
			dispatch({
				type: ALL_ACCESS_LOADED,
				access: data.access,
			});
		} catch (error) {
			localDebug(__filename.split('/').shift(), error);
		}
	});
};

export const loadCompanyAccesses = (companyId) => {
	// localDebug('actions.company.loadCompanyAccesses');
	return actionWithLoader(async (dispatch) => {
		let companyAccesses = [];
		try {
			const { data } = await CompanyService.findAllAccess(companyId);
			companyAccesses = data?.access || [];
		} catch (error) {
			localDebug(__filename.split('/').shift(), error);
		} finally {
			dispatch({
				type: COMPANY_ACCESSES_LOADED,
				companyAccesses,
			});
		}
	});
};

export const loadAtsPipelines = () => {
	const method = 'loadAtsPipelines';
	return actionWithLoader(async (dispatch, getState) => {
		try {
			if (getAtsPipelines(getState())?.length > 0) {
				dispatch({ type: ATS_PIPELINES_LOADED });
				return;
			}
			const { data } = await CompanyService.findAllAtsPipelines();
			LocalDebug.logAPIResult({ className, method }, { data });
			const atsPipelines = data.items;
			// const atsShortlistPipelines = atsPipelines.filter(p => ['shortlist', 'applicant'].includes(p.value));
			const atsShortlistPipelines = atsPipelines.filter((p) => ['shortlist'].includes(p.value));
			const atsArchivedPipelines = atsPipelines.filter((p) => ['archived'].includes(p.value));
			// const atsCandidatePipelines = atsPipelines.filter(p => !['shortlist', 'applicant', 'archived'].includes(p.value));
			const atsCandidatePipelines = atsPipelines.filter((p) => !['shortlist', 'archived'].includes(p.value));
			// localDebug('actions.company.loadAtsPipelines', {
			//     atsPipelines,
			//     atsShortlistPipelines,
			//     atsCandidatePipelines,
			//     atsArchivedPipelines}
			// );
			dispatch({
				type: ATS_PIPELINES_LOADED,
				atsPipelines,
				atsShortlistPipelines,
				atsCandidatePipelines,
				atsArchivedPipelines,
			});
		} catch (error) {
			localDebug(__filename.split('/').shift(), error);
		}
	});
};

export const loadAtsArchiveReasons = () => {
	const method = 'loadAtsArchiveReasons';
	// localDebug('actions.company.loadAtsArchiveReasons');
	return actionWithLoader(async (dispatch, getState) => {
		try {
			if (getAtsArchiveReasons(getState())?.length > 0) {
				dispatch({ type: ATS_ARCHIVE_REASONS_LOADED });
				return;
			}
			const { data } = await CompanyService.findAllAtsArchiveReasons();
			LocalDebug.logAPIResult({ className, method }, { data });
			dispatch({
				type: ATS_ARCHIVE_REASONS_LOADED,
				atsArchiveReasons: data.items,
			});
		} catch (error) {
			localDebug(__filename.split('/').shift(), error);
		}
	});
};

export const saveCompany = (data, isNew = false, setAsSelected = true) => {
	// return actionWithLoader(async (dispatch) => {
	return async (dispatch) => {
		try {
			// localDebug('saveCompany', data, { isNew, setAsSelected });
			await CompanyService.saveCompany(data);
			const companyId = documentId(data);
			// if new, reloading all companies,
			if (isNew) {
				await dispatch(loadCompanies({
					defaultCompanyIdSelected: companyId,
					origin: 'saveCompany',
				}));
			} else {
				await dispatch(loadCompany(companyId));
			}
			if (setAsSelected) dispatch(setCompanySelected(companyId));
		} catch (error) {
			localDebug(__filename.split('/').shift(), error);
		}
	};
};

export const inviteUser = (companyId, data) => {
	return actionWithLoader(async (dispatch) => {
		try {
			await CompanyService.inviteUser(companyId, data);
		} catch (error) {
			localDebug(__filename.split('/').shift(), error);
		}
	});
};

export const setCompanySelected = (companyId) => {
	LocalDebug.logInfo({ className, method: 'setCompanySelected' }, companyId);
	return async (dispatch, getState) => {
		const allCompanies = getCompanies(getState());
		const company = allCompanies
			.find((c) => (companyId && (documentId(c))?.toString() === companyId?.toString()));

		LocalDebug.logNull({ className, method: 'setCompanySelected' }, { company });
		if (company?.path) {
			localStorage.setItem(COMPANY_SELECTED_QUERY_PARAM, company.path);
		} else {
			localStorage.removeItem(COMPANY_SELECTED_QUERY_PARAM);
		}
		dispatch({
			type: SET_COMPANY_SELECTED,
			company, // can be null
		});
		dispatch(loadJobs({ companyId }));
		dispatch(loadCompanyAccesses(companyId));
	};
};

export const setCompanyGenderScoreSurveyDraftSelected = (companyGenderScoreSurveyDraftSelected) => {
	return async (dispatch) => {
		dispatch({
			type: COMPANY_GENDERSCORE_SURVEY_DRAFT_SELECTED,
			companyGenderScoreSurveyDraftSelected,
		});
	};
};

export const setCompanyUMSSurveyDraftSelected = (companyUMSSurveyDraftSelected) => {
	return async (dispatch) => {
		dispatch({
			type: COMPANY_UMS_SURVEY_DRAFT_SELECTED,
			companyUMSSurveyDraftSelected,
		});
	};
};

export const setCompanyAccessSelected = (companyAccessId) => {
	return async (dispatch, getState) => {
		const allCompanyAccesses = getCompanyAccesses(getState());

		const companyAccess = allCompanyAccesses
			.find((c) => companyAccessId && (documentId(c)?.toString() === companyAccessId?.toString()));

		dispatch({
			type: SET_COMPANY_ACCESS_SELECTED,
			companyAccess, // can be null
		});
	};
};

export const addAccess = async (data) => {
	try {
		const { data: { access } } = await CompanyService.createAccess(data);
		return access;
	} catch (error) {
		localDebug(__filename.split('/').shift(), error);
	}
};

export const updateAccess = async (accessId, data) => {
	try {
		await CompanyService.updateAccess(accessId, data);
	} catch (error) {
		localDebug(__filename.split('/').shift(), error);
	}
};

export const removeAccess = async (access) => {
	try {
		await CompanyService.removeAccess(access);
	} catch (error) {
		localDebug(__filename.split('/').shift(), error);
	}
};

export const updateOrCreateCompany = (data) => {
	const key = JSON.stringify(data);
	message.loading({ content: 'Updating company', key, duration: 10 });
	return async () => {
		try {
			await CompanyService.updateOrCreateCompany(data);
			message.success({ content: 'Company updated', key, duration: 1 });
		} catch (error) {
			message.error({ content: error.message, key });
		}
	};
};

export const updateCompanyTags = (company) => {
	const key = company.slug;
	message.loading({ content: 'Updating company tags', key, duration: 10 });
	return action(async () => {
		try {
			await CompanyService.updateCompanyTags(company);
			message.success({ content: 'Tags updated', key, duration: 1 });
		} catch (error) {
			message.error({ content: error.message, key });
		}
	});
};

export const showCompanyEditor = (companyId) => {
	return actionWithLoader(async (dispatch) => {
		try {
			// await dispatch(setCompanySelected(companyId));
			push(PATHS.COMPANY_PAGE);
			// push(new ConfigureCompanyPageMenu().path);
		} catch (error) {
			message.error({ content: error.message });
		}
	});
};

export const getAllCompanyPlansExtraTags = () => {
	return async (dispatch) => {
		const { data } = await CompanyService.getAllCompanyPlansExtraTags();
		dispatch({
			type: COMPANY_PLAN_EXTRA_TAGS_LOADED,
			companyPlansExtraTags: data.companyPlansExtraTags,
		});
	};
};

export const postGenderScoreAction = async (data) => {
	try {
		LocalDebug.logInfo({ className, method: 'postGenderScoreAction' }, { data });
		const { data: { result } } = await CompanyService.postGenderScoreAction(data);
		return result;
	} catch (error) {
		localDebug(__filename.split('/').shift(), error);
	}
};

export const postCompanyActionPlan = async (data) => {
	try {
		LocalDebug.logInfo({ className, method: 'postCompanyActionPlan' }, { data });
		const { data: { result } } = await CompanyService.postCompanyActionPlan(data);
		return result;
	} catch (error) {
		localDebug(__filename.split('/').shift(), error);
	}
};

export const syncCompanyAtsSourcings = (companyId) => (
	basicActionBuilder({ command: async () => CompanyService.syncAtsSourcings(companyId) })
);
