import UserModalProvider from './UserModalProvider';
import UserSuggestModalProvider from './UserSuggestModalProvider';
import UserMessageModalProvider from './UserMessageModalProvider';
import UserModalContainer from './UserModalContainer';
import UserMessageModalContainer from './UserMessageModalContainer';
import UserSuggestModalContainer from './UserSuggestModalContainer';
import UserSourceModalProvider from './UserSourceModalProvider';
import UserSourceModalContainer from './UserSourceModalContainer';
import UserAtsSendModalProvider from './UserAtsSendModalProvider';
import UserAtsSendModalContainer from './UserAtsSendModalContainer';

const ModalProviders = ({
	children,
}) => {
	const className = 'ModalProviders';

	return (
		<UserMessageModalProvider>
			<UserSuggestModalProvider>
				<UserSourceModalProvider>
					<UserAtsSendModalProvider>
						<UserModalProvider>
							{children}
							<UserModalContainer />
							<UserMessageModalContainer />
							<UserSuggestModalContainer />
							<UserSourceModalContainer />
							<UserAtsSendModalContainer />
						</UserModalProvider>
					</UserAtsSendModalProvider>
				</UserSourceModalProvider>
			</UserSuggestModalProvider>
		</UserMessageModalProvider>
	);
};

export default ModalProviders;
