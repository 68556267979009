import { useDispatch } from 'react-redux';
import { useContext, useEffect, useState } from 'react';
import { useTheme } from '@emotion/react';
import {
	ENGLISH_LANG, FRENCH_LANG, GENDERSCORE_LABEL, UMS_LABEL, authState, teamSizeOptions,
} from '../../../../constants/constant';
import { saveCompany } from '../../../../actions/company';
import { CompanyPageContext } from '../../../company/companyPage/shared/CompanyPageProvider';
import {
	documentId, mergeReducer, shuffleArr, sumReducer,
} from '../../../../utils/common';
import { LocalDebug } from '../../../../utils/LocalDebug';
import {
	Space, message,
} from 'antd';
import {
	getMandatorySteps,
	countMandatorySteps,
	getOptionalSteps,
	countOptionalSteps,
	getStaffSteps,
	COMPANY_PAGE_STEP_GENDERSCORE,
	COMPANY_PAGE_STEP_GENDERSCORE_ID,
	COMPANY_PAGE_STEP_UMS,
	COMPANY_PAGE_STEP_UMS_ID,
} from '../../../company/companyPage/shared/CompanyPageSteps';
import { LOGIN_SUCCESS } from '../../../../actions/types';
import { useAuthContext } from '../../../../hooks/useAuthContext';
import CompanyModel from '../../../../models/CompanyModel';
import { CompanyContext } from '../../../../contexts/CompanyProvider';
import { GENDERSCORE_LEVEL_VALUES, getLevelLabel, getLevelRanges } from '../../../../constants/genderscore-levels';
import {
	UMS_LEVEL_VALUES,
	getLevelLabel as getUMSLevelLabel,
	getLevelRanges as getUMSLevelRanges,
} from '../../../../constants/ums-levels';
import { StaffManagerPanelDivider, StaffManagerPanelLink } from '../StaffManagerPanel';

const className = 'StaffManagerCompanyPageTab';

const FAKE_COMPANY_DATA = {
	logo: 'https://res.cloudinary.com/dkatmtbbc/image/upload/v1/company/company-logo-placeholder.jpg',
	industry: ['saas'],
	oneliner: 'NeoGreen: Nurturing the future with diversity, equity, and inclusion',
	website: 'https://www.neogreen.com',
	headquarters: 'London, United Kingdom - Paris, France',
	mission: `<p>At NeoGreen Solutions, we are committed to revolutionizing traditional farming and waste management practices to build a cleaner, more sustainable future. Our mission is threefold:</p>
		<ul><li><b>Ecological Innovation:</b> Offering innovative and cost-effective technological alternatives that empower farmers to produce more with less, while reducing their carbon footprint. We are continually seeking the latest advancements in sustainable agriculture to assist our clients in responsibly feeding the world.</li>
		<li><b>Zero Waste Management:</b> Transforming the way waste is perceived and handled. At NeoGreen Solutions, we firmly believe that every piece of waste holds value. Through our state-of-the-art facilities, we turn waste into energy, new products, and job creation opportunities, thereby reducing our reliance on landfills and incineration.</li>
		<li><b>Education & Partnership:</b> Actively engaging with communities to educate about the importance of sustainable farming and waste management. We forge partnerships with schools, universities, and local organizations to raise awareness and train future generations in adopting more eco-friendly behaviors.</li></ul>
		<p>By merging technology, awareness, and passion, NeoGreen Solutions aspires to create a world where agricultural production and waste management harmoniously coexist with our planet.</p>`,
	values: `<ul>
			<li><strong>Ecological Innovation</strong>: Constantly seeking technological solutions for a sustainable future.</li>
			<li><strong>Environmental Responsibility</strong>: Reducing ecological footprint through concrete actions.</li>
			<li><strong>Integrity</strong>: Being transparent and honest in all our actions and relationships.</li>
			<li><strong>Collaboration</strong>: Working hand-in-hand with communities, partners, and clients.</li>
			<li><strong>Education</strong>: Raising awareness and training for a greener future.</li>
			<li><strong>Excellence</strong>: Continually aiming for the best in our products, services, and relationships.</li>
		</ul>`,
	benefits: `<ul>
		<li><strong>Increased Innovation</strong>: A diverse team brings varied perspectives, fueling creativity and innovation.</li>
		<li><strong>Enhanced Reputation</strong>: Companies committed to DE&I are more attractive to potential clients and talents.</li>
		<li><strong>Optimized Productivity</strong>: An inclusive environment boosts employee well-being and motivation, enhancing productivity.</li>
		<li><strong>Better Retention</strong>: Employees feel valued and are more likely to stay longer in an inclusive company.</li>
		<li><strong>Informed Decision Making</strong>: A variety of viewpoints lead to better strategic decisions.</li>
	</ul>`,
	weLike: 'Having reviewed NeoGreen\'s DE&I policy, we are impressed by their dedication to diversity and inclusion. Their holistic approach fosters a culture where everyone feels valued and included. By collaborating with various underrepresented groups and implementing ongoing training programs, NeoGreen is well on its way to becoming a leader in diversity in the tech sector. Kudos for their efforts!</p>',
	diversityActions: `<ul>
		<li><strong>Mentorship Program</strong>: Implementation of a mentorship program for young talents from underrepresented groups.</li>
		<li><strong>Mandatory Training</strong>: Regular training sessions to sensitize all collaborators to diversity and inclusion challenges.</li>
		<li><strong>Diverse Recruitment</strong>: Collaboration with specialized entities to diversify recruitment sources.</li>
		<li><strong>DE&I Events</strong>: Hosting internal and external events to promote diversity, equity, and inclusion within the industry.</li>
		<li><strong>Anonymous Evaluation</strong>: Introduction of anonymous evaluations to ensure fairness in performance reviews.</li>
		<li><strong>DE&I Committee</strong>: Establishment of a dedicated committee to monitor and continuously improve the company's DE&I initiatives.</li>
	</ul>`,
	recruitment: `<ol>
		<li><strong>Job Posting</strong>: Drafting and posting the job offer on relevant platforms.</li>
		<li><strong>Candidate Preselection</strong>: Analyzing CVs and cover letters to identify the most relevant candidates.</li>
		<li><strong>Initial Interviews</strong>: Conducting phone or video interviews to assess skills and motivation.</li>
		<li><strong>Technical Tests or Assessments</strong>: Administering tests or simulations to evaluate candidates' technical skills.</li>
		<li><strong>In-person Interviews</strong>: Meeting with shortlisted candidates to evaluate cultural fit and interpersonal skills.</li>
		<li><strong>Job Offer</strong>: Sending a formal offer to the selected candidates.</li>
		<li><strong>Onboarding</strong>: Organizing an onboarding training to familiarize the new collaborator with the company's culture and processes.</li>
	</ol>`,
	communities: `<p>
	NeoGreen strongly believes in corporate social responsibility and actively commits to supporting both local and global communities. Here's how we make a difference:
	</p>
	<ul>
		<li><strong>Educational Initiatives</strong>: We fund educational programs focusing on sustainability and ecology, partnering with local schools.</li>
		<li><strong>Partnerships with NGOs</strong>: We collaborate with non-governmental organizations to plant trees, clean beaches, and undertake other eco-actions.</li>
		<li><strong>Local Hiring</strong>: Wherever we operate, we prioritize hiring from the local community to boost the economy and create opportunities.</li>
		<li><strong>Volunteer Programs</strong>: Our employees are encouraged to spend time on community projects, and the company provides dedicated days for this purpose.</li>
	</ul>`,
	quote: `<blockquote>
		<p>"In an ever-changing world, NeoGreen remains true to its mission: melding technology and ecology for a sustainable future. Our vision isn't just to succeed as a company, but to succeed as a catalyst for change."</p>
		<footer>— John Hussletuna, CEO of NeoGreen</footer>
	</blockquote>`,
	team: [],
	[FRENCH_LANG]: {
		oneliner: 'NeoGreen : Cultiver l\'avenir avec diversité, équité et inclusion',
		website: 'https://www.neogreen.fr',
		headquarters: 'Londres, Royaume-Uni - Paris, France',
		mission: `	<p>Chez NeoGreen Solutions, nous nous engageons à révolutionner les méthodes traditionnelles d'agriculture et de gestion des déchets pour bâtir un avenir plus propre et durable. Notre mission est triple :</p>
		<ul><li><b>Innovation Écologique :</b> Proposer des alternatives technologiques innovantes et rentables pour permettre aux agriculteurs de produire plus avec moins, tout en réduisant leur empreinte carbone. Nous sommes constamment à la recherche des dernières innovations en matière d'agriculture durable pour aider nos clients à nourrir le monde de manière responsable.</li>
		<li><b>Gestion Zéro Déchet :</b> Transformer la manière dont les déchets sont perçus et gérés. Chez NeoGreen Solutions, nous croyons fermement que chaque déchet a une valeur. Grâce à nos installations de pointe, nous transformons les déchets en énergie, en nouveaux produits et en opportunités de création d'emplois, réduisant ainsi notre dépendance aux décharges et à l'incinération.</li>
		<li><b>Éducation & Partenariat :</b> S'engager activement auprès des communautés pour éduquer sur l'importance de l'agriculture durable et de la gestion des déchets. Nous établissons des partenariats avec des écoles, des universités et des organisations locales pour sensibiliser et former les futures générations à adopter des comportements plus écologiques.</li></ul>
		<p>En conjuguant technologie, sensibilisation et passion, NeoGreen Solutions ambitionne de créer un monde où la production agricole et la gestion des déchets se font en harmonie avec notre planète.</p>
		`,
		values: `	<ul>
			<li><strong>Innovation Écologique</strong> : Recherche constante de solutions technologiques pour un avenir durable.</li>
			<li><strong>Responsabilité Environnementale</strong> : Réduction de l'empreinte écologique à travers des actions concrètes.</li>
			<li><strong>Intégrité</strong> : Être transparent et honnête dans toutes nos actions et relations.</li>
			<li><strong>Collaboration</strong> : Travailler main dans la main avec les communautés, partenaires et clients.</li>
			<li><strong>Éducation</strong> : Sensibiliser et former pour un futur plus vert.</li>
			<li><strong>Excellence</strong> : Viser constamment le meilleur dans nos produits, services et relations.</li>
		</ul>
		`,
		benefits: `	<ul>
			<li><strong>Innovation Accrue</strong> : Une équipe diverse apporte des perspectives variées, alimentant la créativité et l'innovation.</li>
			<li><strong>Amélioration de la Réputation</strong> : Les entreprises engagées en DE&I sont plus attractives pour les clients et les talents potentiels.</li>
			<li><strong>Productivité Optimisée</strong> : Un environnement inclusif augmente le bien-être et la motivation des employés, boostant leur productivité.</li>
			<li><strong>Meilleure Retention</strong> : Les employés se sentent valorisés et ont tendance à rester plus longtemps dans une entreprise inclusive.</li>
			<li><strong>Prise de Décision Éclairée</strong> : Une diversité de points de vue conduit à de meilleures décisions stratégiques.</li>
		</ul>
		`,
		weLike: `	<p>Après avoir étudié la politique de DE&I de NeoGreen, nous sommes impressionnés par leur engagement envers la diversité et l'inclusion. Leur approche holistique favorise une culture où chacun se sent valorisé et inclus. En collaborant avec divers groupes sous-représentés et en mettant en œuvre des programmes de formation continus, NeoGreen est en bonne voie pour devenir un leader en matière de diversité dans le secteur technologique. Bravo pour leurs efforts !</p>
		`,
		diversityActions: `	<ul>
			<li><strong>Programme de Mentorat</strong> : Mise en place d'un programme de mentorat pour les jeunes talents issus de groupes sous-représentés.</li>
			<li><strong>Formation Obligatoire</strong> : Organisation de formations régulières pour sensibiliser l'ensemble des collaborateurs aux enjeux de la diversité et de l'inclusion.</li>
			<li><strong>Recrutement Diversifié</strong> : Collaboration avec des organismes spécialisés pour diversifier les sources de recrutement.</li>
			<li><strong>Événements DE&I</strong> : Organisation d'événements internes et externes pour promouvoir la diversité, l'équité et l'inclusion au sein de l'industrie.</li>
			<li><strong>Évaluation Anonyme</strong> : Introduction d'évaluations anonymes pour garantir l'équité dans les revues de performance.</li>
			<li><strong>Comité DE&I</strong> : Création d'un comité dédié pour surveiller et améliorer en continu les initiatives DE&I de l'entreprise.</li>
		</ul>
		`,
		recruitment: `	<ol>
			<li><strong>Publication de l'offre</strong> : Rédaction et publication de l'offre d'emploi sur les plateformes pertinentes.</li>
			<li><strong>Présélection des candidats</strong> : Analyse des CVs et lettres de motivation pour identifier les candidats les plus pertinents.</li>
			<li><strong>Entretiens initiaux</strong> : Réalisation d'entretiens téléphoniques ou vidéo pour évaluer les compétences et la motivation.</li>
			<li><strong>Tests techniques ou évaluations</strong> : Mise en place de tests ou de simulations pour évaluer les compétences techniques des candidats.</li>
			<li><strong>Entretiens en personne</strong> : Rencontres avec les candidats présélectionnés pour évaluer l'adéquation culturelle et les compétences interpersonnelles.</li>
			<li><strong>Offre d'emploi</strong> : Envoi d'une offre formelle aux candidats retenus.</li>
			<li><strong>Intégration</strong> : Organisation d'une formation d'intégration pour familiariser le nouveau collaborateur avec la culture et les processus de l'entreprise.</li>
		</ol>
		`,
		communities: `	<p>
		NeoGreen croit fermement à la responsabilité sociale des entreprises et s'engage activement à soutenir les communautés locales et mondiales. Voici comment nous faisons la différence :
		</p>
		<ul>
			<li><strong>Initiatives Éducatives</strong> : Nous finançons des programmes éducatifs axés sur la durabilité et l'écologie, en partenariat avec des écoles locales.</li>
			<li><strong>Partenariats avec des ONG</strong> : Nous collaborons avec des organisations non gouvernementales pour planter des arbres, nettoyer les plages et réaliser d'autres actions écologiques.</li>
			<li><strong>Emploi Local</strong> : Là où nous opérons, nous priorisons l'embauche locale pour stimuler l'économie et créer des opportunités.</li>
			<li><strong>Programmes de Volontariat</strong> : Nos employés sont encouragés à consacrer du temps à des projets communautaires, et l'entreprise leur offre des jours dédiés à cet effet.</li>
		</ul>
		`,
		quote: `	<blockquote>
			<p>"Dans un monde en constante évolution, NeoGreen reste fidèle à sa mission : allier technologie et écologie pour un avenir durable. Notre vision n'est pas simplement de réussir en tant qu'entreprise, mais de réussir en tant qu'acteur du changement."</p>
			<footer>— John Hussletuna, CEO de NeoGreen</footer>
		</blockquote>
		`,
		team: [],
	},
};

const getCompanyPageFakeGenderScore = ({
	level,
	score,
} = {}) => {
	let scores = {};
	if (score) {
		scores = {
			genderData: score,
			fairCareerPath: score,
			equalPay: score,
			worklifeBalance: score,
			deiActions: score,
		};
	} else if (level && GENDERSCORE_LEVEL_VALUES.includes(level)) {
		const [rangeMin, rangeMax] = getLevelRanges(level);
		scores = {
			genderData: rangeMax - Math.round(Math.random() * (rangeMax - rangeMin)),
			fairCareerPath: rangeMax - Math.round(Math.random() * (rangeMax - rangeMin)),
			equalPay: rangeMax - Math.round(Math.random() * (rangeMax - rangeMin)),
			worklifeBalance: rangeMax - Math.round(Math.random() * (rangeMax - rangeMin)),
			deiActions: rangeMax - Math.round(Math.random() * (rangeMax - rangeMin)),
		};
	} else {
		scores = {
			genderData: Math.round(Math.random() * 100),
			fairCareerPath: Math.round(Math.random() * 100),
			equalPay: Math.round(Math.random() * 100),
			worklifeBalance: Math.round(Math.random() * 100),
			deiActions: Math.round(Math.random() * 100),
		};
	}
	return {
		...Object.fromEntries(
			Object.entries(scores).map(([k, v]) => [`genderScore.${k}`, v]),
		),
		'genderScore.globalScore': Math.round(
			Object.values(scores).reduce(sumReducer, 0) / 5,
		),
	};
};

const getCompanyPageFakeContentFromSteps = (steps, lang) => {
	const content = steps
		?.map((step) => {
			const fakeContent = {
				...lang === ENGLISH_LANG
					? {
						[documentId(step)]:
							(FAKE_COMPANY_DATA?.[documentId(step)] || 'Random EN')
								?.replaceAll?.('\t', '')
								?.replaceAll?.('\n', ''),
					}
					: {
						...step.isTranslatable
							? {
								[`translations.${lang}.${documentId(step)}`]:
									(FAKE_COMPANY_DATA?.[lang]?.[documentId(step)] || 'Random FR')
										?.replaceAll?.('\t', '')
										?.replaceAll?.('\n', ''),
							}
							: {},
					},
			};
			let additionalContent = {};
			switch (documentId(step)) {
				case COMPANY_PAGE_STEP_GENDERSCORE_ID:
					additionalContent = getCompanyPageFakeGenderScore();
					delete fakeContent.genderscore;
					break;
				case 'industry':
					additionalContent = { industry: FAKE_COMPANY_DATA?.industry || ['saas'] };
					delete fakeContent.genderscore;
					break;
				case 'logo':
					additionalContent = {
						logo:
							FAKE_COMPANY_DATA?.logo
							|| 'https://res.cloudinary.com/dkatmtbbc/image/upload/v1/company/company-logo-placeholder.jpg',
					};
					break;
				case 'teamsize':
					additionalContent = {
						logo:
							FAKE_COMPANY_DATA?.logo
							|| 'https://res.cloudinary.com/dkatmtbbc/image/upload/v1/company/company-logo-placeholder.jpg',
					};
					break;
				case 'glassdoor':
					additionalContent = { glassdoor: Math.round(Math.random() * 50) / 10 };
					break;
				default:
					break;
			}

			return {
				...fakeContent,
				...additionalContent,
			};
		})
		.reduce(mergeReducer, {});

	return content;
};

export const COMPANY_PAGE_USER_RESET_BUTTON_ID = 'company-tools-company-page-user-reset-button';
export const COMPANY_PAGE_USER_TOGGLE_EDITOR_BUTTON_ID = 'company-tools-company-page-user-toggle-editor-button';
export const COMPANY_PAGE_TRANSLATION_TOGGLE_BUTTON_ID = 'company-tools-company-page-translation-toggle-button';
export const COMPANY_PAGE_RESET_BUTTON_ID = 'company-tools-company-page-reset-button';
export const COMPANY_PAGE_FILL_SOME_MANDATORY_FIELDS_BUTTON_ID = 'company-tools-fill-some-mandatory-fields-button';
export const COMPANY_PAGE_FILL_ALL_MANDATORY_FIELDS_BUTTON_ID = 'company-tools-fill-all-mandatory-fields-button';

export const StaffManagerCompanyPageUserResetButton = ({ company }) => {
	const dispatch = useDispatch();
	const { currentUser } = useAuthContext();

	const onClick = () => {
		dispatch({
			type: LOGIN_SUCCESS,
			user: {
				...currentUser,
				auth: {
					...currentUser?.auth || {},
					authState: authState.AUTH_REGISTERED,
				},
				acls: {
					...currentUser.acls,
					isInvitePending: true,
				},
			},
		});
	};

	return (
		<StaffManagerPanelLink
			label={<b>Reset user</b>}
			onClick={onClick}
			confirm={<>
				This will soft reset the current user ("reset" means the popups on first
				login will show again, and "soft" means the user is only modified in the
				client for the current session, not in database. So if you refresh the page,
				you will be back to your original user)
			</>}
		/>
	);
};

export const StaffManagerPanelCompanyPageUserSetAsOnlyEditorButton = ({ company }) => {
	const dispatch = useDispatch();
	const { currentUser } = useAuthContext();

	const onClick = () => {
		dispatch({
			type: LOGIN_SUCCESS,
			user: {
				...currentUser,
				acls: {
					...currentUser.acls,
					isAdmin: false,
					isStaff: false,
					isStaffBackup: false,
					isTalent: false,
					isTester: false,
					isDemo: false,
					isCompanyRecruiter: false,
					isDocumentAdmin: false,
					isDocumentEditor: true,
				},
			},
		});
	};

	return (
		<StaffManagerPanelLink
			label={<b>Set user as editor only</b>}
			onClick={onClick}
			confirm={<>
				This will "soft set" your user as only editor for the current company
				("soft" means the user is only modified in the client for the current session,
				not in database. So if you refresh the page, you will be back to your original user)
			</>}
		/>
	);
};

export const StaffManagerPanelCompanyPageUserToggleEditorButton = ({ company }) => {
	const {
		isDocumentEditorOnly,
		setIsDocumentEditorOnly,
	} = useAuthContext();

	const onClick = async () => {
		setIsDocumentEditorOnly(!isDocumentEditorOnly);
	};

	return (
		<StaffManagerPanelLink
			label={<b>Toggle to {isDocumentEditorOnly ? 'original' : 'editor only'} role</b>}
			onClick={onClick}
			confirm={<>
				For this session, current user will be considered
				only as an editor.
				<br />Click again or refresh page to revert to original roles.
			</>}
		/>
	);
};

export const StaffManagerPanelCompanyPageToggleTranslatableButton = ({ company }) => {
	const dispatch = useDispatch();

	const { setCurrentlyEditingStep } = useContext(CompanyPageContext);

	const onClick = async () => {
		await dispatch(saveCompany({
			id: documentId(company),
			isTranslatable: !company?.isTranslatable,
		}));

		message.success(<>French translation has been <b>{company?.isTranslatable ? 'de' : ''}activated</b></>);

		setCurrentlyEditingStep(null);
	};

	return (
		<StaffManagerPanelLink
			label={<b>Toggle translation (now: {company.isTranslatable ? 'true' : 'false'})</b>}
			onClick={onClick}
			confirm={<>
				This will <b>{company.isTranslatable ? 'de' : ''}activate</b> the ability
				to translate the company page in French
			</>}
		/>
	);
};

const handleResetCompany = async ({
	lang,
	company,
	dispatch,
	setCurrentlyEditingStep,
	forceLang,
}) => {
	const method = 'handleResetCompany';

	const appliedLang = forceLang || lang || ENGLISH_LANG;

	await dispatch(
		await saveCompany({
			id: documentId(company),
			...appliedLang === ENGLISH_LANG
				? {
					...[
						...getMandatorySteps({ company, lang: ENGLISH_LANG }),
						...getOptionalSteps({ company, lang: ENGLISH_LANG }),
						...getStaffSteps({ company, lang: ENGLISH_LANG }),
					]
						.map((step) => ({ [documentId(step)]: '' }))
						.reduce(mergeReducer, {}),
					industry: [],
					teamSize: '',
					team: [], // to override the string ''
					isPublished: false,
					publishedAt: '',
					isTranslationPublished: false,
					genderScore: {
						globalScore: '',
						genderData: '',
						fairCareerPath: '',
						equalPay: '',
						worklifeBalance: '',
						deiActions: '',
					},
				}
				: {
					...[
						...getMandatorySteps({ company, lang: ENGLISH_LANG }),
						...getOptionalSteps({ company, lang: ENGLISH_LANG }),
						...getStaffSteps({ company, lang: ENGLISH_LANG }),
					]
						.map((step) => ({
							[`translations.${lang}.${documentId(step)}`]: '',
						}))
						.reduce(mergeReducer, {}),
					isTranslationPublished: false,
				},

		}),
	);

	setCurrentlyEditingStep(null);
};

export const StaffManagerPanelCompanyPageResetButton = ({ company }) => {
	const dispatch = useDispatch();

	const { lang, setCurrentlyEditingStep } = useContext(CompanyPageContext);

	return (
		<StaffManagerPanelLink
			label={<b>Reset company page</b>}
			onClick={() => handleResetCompany({
				company,
				lang,
				dispatch,
				setCurrentlyEditingStep,
			})}
			confirm={<>
				This will reset the company page information in the database to the first step
				(all fields emptied, and the company page unpublished)
			</>}
		/>
	);
};

const handleFillSteps = async ({
	company,
	lang,
	dispatch,
	setCurrentlyEditingStep,
	before,
	steps,
	values = {},
	shouldUpdateTags = false,
}) => {
	await before?.({
		company,
		lang,
		dispatch,
		setCurrentlyEditingStep,
	});

	const data = {
		...getCompanyPageFakeContentFromSteps(steps, lang),
		...values,
	};

	LocalDebug.logInfo({ className, method: 'handleFillSteps' }, {
		steps, lang, data, values,
	});

	await dispatch(
		await saveCompany({
			id: documentId(company),
			shouldUpdateTags,
			...data,
		}),
	);

	setCurrentlyEditingStep(null);
};

export const StaffManagerPanelCompanyPageFillSomeMandatoryFieldsButton = ({ company }) => {
	const dispatch = useDispatch();

	const { lang, setCurrentlyEditingStep } = useContext(CompanyPageContext);

	const onClick = async () => handleFillSteps({
		company,
		lang,
		dispatch,
		setCurrentlyEditingStep,
		before: handleResetCompany,
		steps: shuffleArr(getMandatorySteps({ company, lang }).slice())
			.slice(0, 1 + Math.ceil(Math.random() * (countMandatorySteps({ company, lang }) - 2)) + 1),
	});

	return (
		<StaffManagerPanelLink
			label={<b>Fill some mandatory fields</b>}
			onClick={onClick}
			confirm={<>
				This will put the company page in a state where a random number of
				the mandatory fields are already filled
			</>}
		/>
	);
};

export const StaffManagerPanelCompanyPageFillAllMandatoryFieldsButton = ({ company }) => {
	const dispatch = useDispatch();

	const { lang, setCurrentlyEditingStep } = useContext(CompanyPageContext);

	const onClick = async () => handleFillSteps({
		company,
		lang,
		dispatch,
		setCurrentlyEditingStep,
		before: handleResetCompany,
		steps: getMandatorySteps({ company, lang }),
	});

	return (
		<StaffManagerPanelLink
			label={<b>Fill all mandatory fields</b>}
			onClick={onClick}
			confirm={<>
				This will put the company page in a "ready to publish" state,
				with ALL mandatory fields filled
			</>}
		/>
	);
};

export const StaffManagerPanelCompanyGenderScoreFillRandomButton = ({ company, level, score }) => {
	const dispatch = useDispatch();

	const { lang, setCurrentlyEditingStep } = useContext(CompanyPageContext);

	const onClick = async () => handleFillSteps({
		company,
		lang,
		dispatch,
		setCurrentlyEditingStep,
		...(level || score)
			? { values: getCompanyPageFakeGenderScore({ level, score }) }
			: { steps: [COMPANY_PAGE_STEP_GENDERSCORE] },
	});

	let extraLabel = 'random';
	let extraDetails = 'random numbers';
	if (score) {
		extraLabel = `score: ${score}`;
		extraDetails = `a score of ${score}`;
	} else if (level && GENDERSCORE_LEVEL_VALUES.includes(level)) {
		extraLabel = getLevelLabel(level)?.toUpperCase?.();
		extraDetails = `a random score corresponding to an ${getLevelLabel(level)?.toUpperCase?.()} level`;
	}

	return (
		<StaffManagerPanelLink
			// label={<b>Fill {GENDERSCORE_LABEL} ({extraLabel})</b>}
			label={<b>{extraLabel}</b>}
			onClick={onClick}
			confirm={<>
				This will fill the {GENDERSCORE_LABEL} with {extraDetails}
			</>}
		/>
	);
};

export const StaffManagerPanelCompanyGenderScoreResetButton = ({ company }) => {
	const dispatch = useDispatch();

	const onClick = async () => {
		await dispatch(
			await saveCompany({
				id: documentId(company),
				genderScore: {
					globalScore: '',
					genderData: '',
					fairCareerPath: '',
					equalPay: '',
					worklifeBalance: '',
					deiActions: '',
				},
			}),
		);
	};

	return (
		<StaffManagerPanelLink
			label={<b>Reset {GENDERSCORE_LABEL}</b>}
			onClick={onClick}
			confirm={<>
				This will reset the {GENDERSCORE_LABEL} (erase all scores)
			</>}
		/>
	);
};

export const StaffManagerPanelCompanyGenderScoreSurveyConsentResetButton = ({ company }) => {
	const dispatch = useDispatch();

	const onClick = async () => {
		await dispatch(
			await saveCompany({
				id: documentId(company),
				genderScore: {
					consentAt: null,
					consentBy: null,
				},
			}),
		);
	};

	return (
		<StaffManagerPanelLink
			label={<b>Reset consent</b>}
			onClick={onClick}
			confirm={<>
				This will reset the consent for the {GENDERSCORE_LABEL}
			</>}
		/>
	);
};

export const StaffManagerPanelCompanyUMSResetButton = ({ company }) => {
	const dispatch = useDispatch();

	const onClick = async () => {
		await dispatch(
			await saveCompany({
				id: documentId(company),
				ums: {
					globalScore: '',
					sffp: '',
					cte: '',
					rec: '',
					ovc: '',
					hhe: '',
					igf: '',
					ble: '',
				},
			}),
		);
	};

	return (
		<StaffManagerPanelLink
			label={<b>Reset {UMS_LABEL}</b>}
			onClick={onClick}
			confirm={<>
				This will reset the {UMS_LABEL} (erase all scores)
			</>}
		/>
	);
};

export const StaffManagerPanelCompanyUMSSurveyConsentResetButton = ({ company }) => {
	const dispatch = useDispatch();

	const onClick = async () => {
		await dispatch(
			await saveCompany({
				id: documentId(company),
				ums: {
					consentAt: null,
					consentBy: null,
				},
			}),
		);
	};

	return (
		<StaffManagerPanelLink
			label={<b>Reset consent</b>}
			onClick={onClick}
			confirm={<>
				This will reset the consent for the {UMS_LABEL}
			</>}
		/>
	);
};

const StaffManagerCompanyPageTab = (
	{
		company,
	},
) => {
	const className = 'StaffManagerCompanyPageTab';

	const theme = useTheme();

	const { currentUser } = useAuthContext();
	const { planValue } = useContext(CompanyContext);

	const dispatch = useDispatch();
	const [companyModel, setCompanyModel] = useState();
	const [emailAccounts, setEmailAccounts] = useState();
	const [openDetails, setOpenDetails] = useState(false);

	const { lang, setCurrentlyEditingStep } = useContext(CompanyPageContext);

	useEffect(() => {
		setCompanyModel(
			company
				? new CompanyModel(company)
				: null,
		);
	}, [company]);

	const handleFillSomeMandatoryStepsCompany = async () => handleFillSteps({
		before: handleResetCompany,
		steps: shuffleArr(getMandatorySteps({ company, lang }).slice())
			.slice(0, 1 + Math.ceil(Math.random() * (countMandatorySteps({ company, lang }) - 2)) + 1),
	});

	const handleFillAllMandatoryStepsCompany = async () => handleFillSteps({
		before: handleResetCompany,
		steps: getMandatorySteps({ company, lang }),
	});

	const handleFillGenderScoreStepCompany = async () => handleFillSteps({
		steps: [COMPANY_PAGE_STEP_GENDERSCORE],
	});

	const handleFillSomeOptionalStepsCompany = async () => {
		const steps = shuffleArr(getOptionalSteps({ company, lang }).slice())
			.slice(0, 1 + Math.ceil(Math.random() * (countOptionalSteps({ company, lang }) - 2) - 1));

		return handleFillSteps({
			before: async () => {
				await handleResetCompany();
				await handleFillAllMandatoryStepsCompany({ company, lang });
			},
			steps,
		});
	};

	const handleFillAllOptionalStepsCompany = async () => (
		handleFillSteps({
			before: async () => {
				await handleResetCompany();
				await handleFillAllMandatoryStepsCompany({ company, lang });
			},
			steps: getOptionalSteps({ company, lang }),
		})
	);

	const handleFill50inTechStaffStepsCompany = async () => (
		handleFillSteps({
			steps: getStaffSteps({ company, lang }),
		})
	);

	if (!companyModel) {
		return null;
	}

	const hasPlan = !(companyModel.isCompanyPlanLegacy());

	return (
		<Space
			direction='vertical'
			size={0}
			style={{
				width: '100%',
				padding: '0 15px 15px',
				fontSize: 12,
			}}>

			{hasPlan && (
				<>
					<StaffManagerPanelDivider>User tools</StaffManagerPanelDivider>

					<StaffManagerCompanyPageUserResetButton company={company} />

					<StaffManagerPanelDivider>Company Page tools</StaffManagerPanelDivider>

					<StaffManagerPanelCompanyPageResetButton company={company} />
					<StaffManagerPanelCompanyPageToggleTranslatableButton company={company} />

					<StaffManagerPanelCompanyPageFillSomeMandatoryFieldsButton company={company} />
					<StaffManagerPanelCompanyPageFillAllMandatoryFieldsButton company={company} />

					<StaffManagerPanelLink
						label={<b>Fill some optional fields</b>}
						onClick={handleFillSomeOptionalStepsCompany}
						confirm={<>
							This will put the company page in a "ready to publish" state,
							with ALL mandatory fields filled, and also a random number of optional fields
						</>}
					/>
					<StaffManagerPanelLink
						label={<b>Fill all optional fields</b>}
						onClick={handleFillAllOptionalStepsCompany}
						confirm={<>
							This will put the company page in a "ready to publish" state,
							with ALL mandatory AND ALL optional fields filled
						</>}
					/>

					<StaffManagerPanelLink
						label={<b>Fill 50inTech staff fields</b>}
						onClick={handleFill50inTechStaffStepsCompany}
						confirm={<>
							This will fill the fields written by 50inTech
							("What we like about", "Diversity actions", ...)
						</>}
					/>

				</>
			)}
		</Space>
	);
};

export default StaffManagerCompanyPageTab;
