import { PERMS_GRANT_STAFF } from '../../menu.utils';
import StaffGenderScoreSurveyListPage from '../../../containers/staff/genderscore/StaffGenderScoreSurveyListPage';
import { GENDERSCORE_EMPTY_ICON_IMG } from '../../../constants/constant';
import MenuBase from '../../MenuBase';
import { UnorderedListOutlined } from '@ant-design/icons';

export const StaffCompaniesGSSurveyListIcon = UnorderedListOutlined;

class StaffGSSurveyListMenu extends MenuBase {
	static props = {
		name: 'staff-genderscore-surveys',
		path: '/staff/genderscore/surveys',
		label: 'Survey List',
		icon: <StaffCompaniesGSSurveyListIcon />,
		iconLegacy: GENDERSCORE_EMPTY_ICON_IMG(),
		Component: StaffGenderScoreSurveyListPage,
		perms: PERMS_GRANT_STAFF,
	};

	constructor() {
		super(StaffGSSurveyListMenu.props);
	}
}

export default StaffGSSurveyListMenu;
