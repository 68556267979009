import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { remove } from '../../../actions/batch';
import CompanyBatchTable from '../../../components/company/batch/CompanyBatchTable';
import PageLayout from '../../../components/app/layout/PageLayout';
import { useTheme } from '@emotion/react';
import { useAuthContext } from '../../../hooks/useAuthContext';
import { localDebug } from '../../../utils/LocalDebug';
import StaffAtsBatchesMenu from '../../../menu/staff/ats/StaffAtsBatchesMenu';

const AtsBatchesPage = ({ filterOptions = {} }) => {
	const theme = useTheme();

	const { isEditor } = useAuthContext();
	const [isRefreshDataLayout, setIsRefreshDataLayout] = useState(0);

	const dispatch = useDispatch();

	const handleDelete = (id) => {
		localDebug('batch', id);
		dispatch(remove(id));

		setIsRefreshDataLayout((prev) => prev + 1);
	};
	return (
		<PageLayout
			MenuClass={StaffAtsBatchesMenu}
		>
			<CompanyBatchTable
				filterOptions={filterOptions}
				isEditor={isEditor}
				onDelete={handleDelete}
				isRefresh={isRefreshDataLayout}
			/>
		</PageLayout>
	);
};

export default AtsBatchesPage;
