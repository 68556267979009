import { request } from './api';

const write = (message, data = {}) => {
	return request.post('/logging/write', { message, data });
};

const writeRecruiter = (data = {}) => {
	return request.post('/logging/recruiter', data);
};

const LoggingService = {
	write,
	writeRecruiter,
};

export default LoggingService;
