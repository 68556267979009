import { ENGLISH_LANG } from '../../../../constants/constant';
import { displayHtml } from '../../../../utils/common';
import CompanyFieldNotProvided from '../shared/CompanyFieldNotProvided';

const CompanyValuesDisplay = (
	{
		company,
		lang = ENGLISH_LANG,
	},
) => {
	const className = 'CompanyValuesDisplay';

	const field = 'values';
	const value = company?.getTranslationFieldFallback(field, lang);

	const content = value
		? displayHtml(value)
		: <CompanyFieldNotProvided/>;

	return (
		<>
			<h2>Values</h2>
			{content}
		</>
	);
};

export default CompanyValuesDisplay;
