import { DatePicker, Form } from 'antd';
import { useReducer, useState } from 'react';
import FormBoxTitle from '../../app/form/FormBoxTitle';
import FormItem from '../form/FormItem';
import FormSubmitButton from '../../app/form/FormSubmitButton';
import { DATE_FORMAT } from '../../../constants/constant';
import { documentId, opacify } from '../../../utils/common';
import {
	formStateReducer, onFormFinish, onFormFinishFailed, onFormValuesChange,
} from './CompanySettingsFormHelper';
import { useDispatch } from 'react-redux';
import BlockStaff from '../../app/blocker/BlockStaff';
import Switch from '../../app/form/Switch';
import dayjs from 'dayjs';

const CompanySettingsJobsPublicationForm = (
	{
		company,
		onSuccess,
		labelCol = { span: 6 },
		wrapperCol = { span: 24 },
		...props
	},
) => {
	const className = 'CompanySettingsJobsPublicationForm';
	const formName = 'company-settings-jobs-publication-form';
	const dispatch = useDispatch();

	const initialValues = {
		...company,
		areJobsPublished: company?.areJobsPublished !== false,
		jobsPublishedAt: company?.jobsPublishedAt && dayjs(company?.jobsPublishedAt),
	};

	const [form] = Form.useForm();
	const [areJobsPublished, setAreJobsPublished] = useState(initialValues.areJobsPublished);
	const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
	const [companyEdited, setCompanyEdited] = useState({ ...company });

	const [state, stateDispatch] = useReducer(formStateReducer({ form, setIsSubmitDisabled }));

	return (
		<BlockStaff
			withBorder={false}
			tagStyle={{
				top: 5,
				left: 0,
				opacity: 1,
			}}
		>
			<FormBoxTitle
				title={<>💼 Jobs publication</>}
				details={<>
						Publication of the jobs can be done <b>independently</b> of the company page.<br/>It
						can be programmed in advance by setting jobs as <b>published</b> and picking a <b>publication
						date</b> in the future.</>}
			/>
			<Form
				form={form}
				name={formName}
				initialValues={initialValues}
				labelCol={labelCol}
				wrapperCol={wrapperCol}
				labelAlign='right'
				layout='horizontal'
				onFinish={async (values) => {
					const result = await onFormFinish({
						id: documentId(company),
						values: { ...values, forceRecompute: false },
						formName,
						dispatch,
						stateDispatch,
					});
					onSuccess?.();
					return result;
				}}
				onFinishFailed={onFormFinishFailed}
				onValuesChange={async (values) => {
					setIsSubmitDisabled(isSubmitDisabled);
					return onFormValuesChange(
						{ values, stateDispatch, setCompanyEdited },
					);
				}}
			>
				<FormItem
					name="areJobsPublished"
					valuePropName="checked"
				>
					<Switch
						style={{ width: 126 }}
						className='ant-switch-large'
						checkedChildren={<b>publish</b>}
						unCheckedChildren={<b>unpublish</b>}
						before={<>I want to</>}
						after={<>the jobs</>}
						onChange={(value) => setAreJobsPublished(value)}
					/>
				</FormItem>

				<FormItem
					label="Publication date"
					name="jobsPublishedAt"
					rules={[{
						type: 'object',
						required: false,
						message: 'Please input date',
						whitespace: true,
					}]}
					extra={<>
							If defined, publication will start <b>after this date</b>.<br/>
							If left blank, publication will start <b>immediately</b>.
					</>}
					// style={opacify(!form.getFieldValue('areJobsPublished'))}
					style={opacify(!areJobsPublished)}
				>
					<DatePicker showTime={true} format={DATE_FORMAT}/>
				</FormItem>

				<FormItem>
					<FormSubmitButton disabled={isSubmitDisabled}>
							Save jobs publication settings
					</FormSubmitButton>
				</FormItem>

			</Form>
		</BlockStaff>
	);
};

export default CompanySettingsJobsPublicationForm;
