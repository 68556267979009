import { isArray } from 'lodash';

const buildFieldReducer = (field) => (obj) => {
	let value = obj;
	const fieldPath = (isArray(field) ? field.slice() : field.split('.')) || [];
	while (fieldPath.length > 0) {
		if (!value) {
			return value;
		}
		value = value?.[fieldPath.shift()];
	}
	return value;
};

export const innerObjPropExtractor = (innerObjKey) => buildFieldReducer(innerObjKey);

export const defaultExtractor = (obj, key, value) => ({ ...obj, [key]: value });

const exporter = {
	defaultExtractor,
	innerObjPropExtractor,
};

// export exporter;

export default exporter;
