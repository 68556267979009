import { useEffect, useState } from 'react';
import AddButton from '../../../components/app/button/AddButton';
import ResourceFormModal from '../../../components/staff/content/resource/ResourceFormModal';
import ResourceTable from '../../../components/staff/content/resource/ResourceTable';
import PageLayout from '../../../components/app/layout/PageLayout';
import { Space } from 'antd';
import { getQueryParams } from '../../../utils/common';
import { localDebug } from '../../../utils/LocalDebug';
import { updateOrCreateResource } from '../../../actions/resource';
import { useDispatch } from 'react-redux';
import ResourceModel from '../../../models/ResourceModel';
import StaffResourceListMenu from '../../../menu/staff/content/StaffResourceListMenu';
import useBodyScrollBlocker from '../../../hooks/useBodyScrollBlocker';

const StaffResourceListPage = () => {
	const queryParams = getQueryParams();

	const [openResourceForm, setOpenResourceForm] = useState(false);
	const [isRefresh, setIsRefresh] = useState(0);
	const [optionsFilterTable, setOptionsFilterTable] = useState({
		sortField: 'publishedAt',
	});
	const [initialValues, setInitialValues] = useState({});
	const dispatch = useDispatch();

	useBodyScrollBlocker(openResourceForm);

	const handleStartAdd = (topic) => {
		localDebug('handleAdd');
		const resource = new ResourceModel({ topic, isAnonymous: false, userId: '5dd2a711d8ec89003819cc2f' });
		setInitialValues(resource);
		setOpenResourceForm(true);
	};

	const onEdit = (values) => {
		const { translations, ...rest } = values;
		const resource = new ResourceModel({
			...rest,
			translations,
			'translations-fr-title': translations?.fr?.title || '',
			'translations-fr-summary': translations?.fr?.summary || '',
			'translations-fr-image': translations?.fr?.image || '',
		});
		setInitialValues(resource);
		setOpenResourceForm(true);
	};

	const onSubmit = async () => {
		// await dispatch(updateOrCreateResource(initialValues));
		setOpenResourceForm(false);
		setIsRefresh((prev) => prev + 1);
	};

	useEffect(() => {
		setOptionsFilterTable((prev) => ({ ...prev, ...queryParams }));
	}, []);

	// useEffect(() => {
	// 	if (openResourceForm) {
	// 		document.body.style.overflow = 'hidden!important';
	// 	} else {
	// 		document.body.style.overflow = 'unset!important';
	// 	}
	// }, [openResourceForm]);

	return (
		<PageLayout
			MenuClass={StaffResourceListMenu}
			rightActions={(
				<Space direction='horizontal' css={{ gap: 10 }}>
					<AddButton
						style={{ marginTop: 1 }}
						onClick={() => openResourceForm()}
						title='Add new event'
					/>
					<AddButton
						style={{ marginTop: 1 }}
						onClick={() => openResourceForm('story')}
						title='Add new story'
					/>
				</Space>
			)}
		>

			<ResourceTable
				filterOptions={optionsFilterTable}
				onEditResource={onEdit}
				isRefresh={isRefresh}
			/>
			<ResourceFormModal
				open={openResourceForm}
				initialValues={initialValues}
				onSubmit={onSubmit}
				setValues={setInitialValues}
				onClose={() => setOpenResourceForm(false)}
			/>
		</PageLayout>
	);
};

export default StaffResourceListPage;
