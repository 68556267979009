import { Tooltip } from 'antd';
import UserModel from '../../../models/UserModel';
import { useTheme } from '@emotion/react';

const UserResumeTag = (
	{
		user,
		...props
	},
) => {
	const theme = useTheme();

	return new UserModel(user)?.hasResume()
		&& <Tooltip title='Profile updated from resume'>
			<span style={{
				userSelect: 'none',
				verticalAlign: 'middle',
				display: 'inline-block',
				fontSize: 10,
				padding: '1px 3px 0',
				color: 'white',
				background: theme.color.fitBlueElectric,
				borderRadius: 30,
				marginBottom: 4,
				fontWeight: 'bold',
				...props?.style,
			}}>CV</span>
		</Tooltip>;
};

export default UserResumeTag;
