import { useTheme } from '@emotion/react';
import CompanyGenderScoreBadge from '../CompanyGenderScoreBadge';
import { Divider, Space } from 'antd';
import Box from '../../../app/box/Box';
import {
	GENDERSCORE_LEVEL_VALUES,
	getLevelDetails,
	getLevelFromScore,
	getLevelRanges,
} from '../../../../constants/genderscore-levels';
import GenderScoreLayoutGrid from '../layout/GenderScoreLayoutGrid';
import BoxRainbow from '../../../app/box/BoxRainbow';
import { mixColors } from '../../../../utils/common';
import GenderScoreLevelLabel from './GenderScoreLevelLabel';

const GenderScoreLevelDetails = ({
	company,
	forceValue,
	...props
}) => {
	const className = 'GenderScoreLevelDetails';

	const theme = useTheme();

	const genderScore = forceValue || company?.genderScore;

	const levelValue = getLevelFromScore(genderScore?.globalScore);

	return (
		<div>
			<Space
				direction='vertical'
				style={{ width: '100%' }}
				size={20}
			>

				{GENDERSCORE_LEVEL_VALUES
					.map((level, index) => {
						const isCurrentLevel = levelValue === level;

						const [rangeMin, rangeMax] = getLevelRanges(level) || [0, 0];
						const fakeCompany = {
							genderScore: {
								globalScore: Math.max(
									24,
									rangeMin + Math.round((rangeMax - rangeMin) / 2),
								),
							},
						};

						const BoxComponent = isCurrentLevel ? BoxRainbow : Box;
						return (
							<div
								key={index}
								style={{
									position: 'relative',
								}}
							>
								<BoxComponent
									withShadow={true}
									style={{
										width: '100%',
										padding: 0,
										overflow: 'hidden',
									}}
								>
									<GenderScoreLayoutGrid
										columns={'1fr 300px'}
										marginBottom={0}
									>

										<div
											style={{
												padding: '20px 30px',
											}}
										>
											<p>
												<span style={{ color: theme.color.darkgrey }}>
													<b>GenderScore</b> between <b>{rangeMin}</b> and <b>{rangeMax}</b> points
												</span>
											</p>
											<div
												style={{
													fontSize: 24,
													color: theme.color.fitBlueElectric,
												}}
											>
												<GenderScoreLevelLabel
													level={level}
													withPill={true}
													withUnderline={false}
													withPopover={false}
													style={{
														fontSize: 22,
														fontWeight: 'bold',
														textTransform: 'uppercase',
														label: {
															padding: '3px 15px',
														},
													}}
												/>

												{/* <CompanyGenderScoreLabel
													company={fakeCompany}
													style={{
														color: theme.color.white,
														fontSize: 18,
														fontSize: 24,
														color: theme.color.fitBlueElectric,
														fontWeight: 700,
														width: 'unset',
														minWidth: 'unset',
														maxWidth: 'unset',
														textAlign: 'center',
														textTransform: 'uppercase',
													}}
												/> */}
											</div>
											<p>
												<span style={{ fontSize: 14 }}>
													{getLevelDetails(level).meaning}
												</span>
											</p>
										</div>

										<div
											style={{
												background: isCurrentLevel
													? `linear-gradient(45deg, ${mixColors('#8458ED', '#ffffff', 80)}, ${mixColors('#00E3DF', '#ffffff', 80)})`
													: theme.color.bgGrey,
												padding: '20px 0',
												textAlign: 'center',
											}}
										>
											{isCurrentLevel
												? (
													<CompanyGenderScoreBadge
														company={company}
														size={150}
														withPopover={true}
														style={{
															// margin: '0 auto',
														}}
													/>
												)
												: (
													<CompanyGenderScoreBadge
														company={fakeCompany}
														version='white'
														size={150}
														withPopover={false}
														style={{
															// margin: '0 auto',
														}}
													/>

												)}
										</div>
									</GenderScoreLayoutGrid>
								</BoxComponent>
								{isCurrentLevel && (
									<div
										style={{
											position: 'absolute',
											width: '100%',
											top: -9,
											left: 0,
										}}
									>
										<Space style={{ width: '100%', justifyContent: 'center' }}>
											<span
												style={{
													borderRadius: 100,
													padding: '2px 12px',
													// background: 'linear-gradient(45deg, #8458ED, #00E3DF)',
													background: '#333',
													color: 'white',
													fontSize: 16,
													fontWeight: 'bold',
													margin: '0 auto',
												}}
											>
											Your current level
											</span>
										</Space>
									</div>
								)}
							</div>
						);
					})}
			</Space>
		</div>
	);
};

export default GenderScoreLevelDetails;
