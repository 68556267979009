import { FaExternalLinkAlt } from 'react-icons/fa';
import Link from '../../app/Link';
import config from '../../../config/config';
import { Tooltip } from 'antd';
import { openExternalUrl } from '../../../utils/common';

const UserLinkAppCoachingProfile = (
	{
		user,
		children,
		...props
	},
) => {
	if (!user) {
		return null;
	}

	// const path = `${config.appRoot}/coaching/user/${user.slug}`;
	const path = `https://20221027t120118-dot-fit-prod-website.ew.r.appspot.com/coaching/user/${user.slug}`;

	return (
		<Tooltip title="Open this user's coaching profile in app.50intech.com">
			<Link
				onClick={() => { openExternalUrl(path); }}
				style={{
					fontSize: 11,
					...props?.style,
				}}
			>
				{children || <><FaExternalLinkAlt style={{ marginBottom: -2 }} /> Coaching profile</>}
			</Link>
		</Tooltip>
	);
};

export default UserLinkAppCoachingProfile;
