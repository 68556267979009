import ValueCard from './ValueCard';
import { Col } from 'antd';

const GridValueCard = (
	{
		title,
		details,
		span,
		children,
		...props
	},
) => {
	return !!children && !!children && <Col span={span}>
		<ValueCard
			title={title}
			details={details}
			{...props}
		>
			{children}
		</ValueCard>
	</Col>;
};

export default GridValueCard;
