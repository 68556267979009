import React, { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import QUESTIONS from '../../data/questions';
import { PATHS } from '../../../../constants/constant';
import { SurveyContext } from './SurveyProvider';
import { buildQuestionSubpath, buildSectionQuestionPath, getSectionQuestionsFromAnswers } from '../../data/shared';
import { LocalDebug } from '../../../../utils/LocalDebug';

export const SurveyNavigationContext = React.createContext();

const SurveyNavigationProvider = ({
	baseRoutePath = PATHS.UMS_SURVEY(),
	children,
}) => {
	const className = 'SurveyNavigationProvider';

	const navigate = useNavigate();
	const params = useParams();

	const {
		eventContext,
		surveyDraft,
		setSurveyDraft,
		surveyConf,
		answers,
		setAnswers,
		saveAnswers,
		unansweredQuestions,
		appLang, setAppLang, t,
	} = useContext(SurveyContext);

	const [isLoading, setIsLoading] = useState(false);
	const [questions, setQuestions] = useState(QUESTIONS);
	const [scores, setScores] = useState({});
	const [currentSection, setCurrentSection] = useState(null);
	const [currentQuestion, setCurrentQuestion] = useState(null);
	const [submitted, setSubmitted] = useState(false);

	useEffect(() => {
		if (!surveyDraft || !surveyConf) return;

		setAnswers(surveyDraft?.answers || {});

		const { lastVisitedPath } = surveyDraft;

		if (lastVisitedPath?.indexOf?.((`${PATHS.UMS_SURVEY()}/`)) === 0) {
			LocalDebug.logUseEffect({ className, effects: 'surveyDraft?._id, surveyConf' }, 'navigate (lastVisitedPath)', lastVisitedPath);
			navigate(lastVisitedPath);
		} else {
			LocalDebug.logUseEffect({ className, effects: 'surveyDraft?._id, surveyConf' }, 'navigate', buildSectionQuestionPath({
				baseRoutePath,
				section: surveyConf?.sections?.[0],
			}), { section: surveyConf?.sections?.[0], surveyConf });
			navigate(buildSectionQuestionPath({
				baseRoutePath,
				section: surveyConf?.sections?.[0],
			}));
		}
	}, [surveyDraft?._id, surveyConf]);

	useEffect(() => {
		if (!surveyConf) return;

		setIsLoading(true);
		let newSection = currentSection;
		let newQuestion = currentQuestion;
		if (!currentSection || currentSection?.slug !== params.section) {
			newSection = (
				surveyConf?.sections
					?.find?.((s) => s.slug === params.section)
				|| surveyConf?.sections
					?.find?.((s) => s?.questions
						?.map?.((q) => q?.questionId).includes?.(currentQuestion?.questionId))
			);
			setCurrentSection(newSection);
		}

		if (!newSection && params.section) {
			newSection = surveyConf?.sections?.[0];
		}

		if (!currentQuestion
			|| buildQuestionSubpath({ question: currentQuestion }) !== params.question
		) {
			newQuestion = newSection
				?.questions
				?.find((q) => params?.question === buildQuestionSubpath({ question: q }));
		}
		if (!newQuestion && params.question) {
			newQuestion = newSection?.questions?.[0];
		}

		setCurrentQuestion(newQuestion);
		setIsLoading(false);
	}, [surveyConf, params, currentQuestion, currentSection]);

	useEffect(() => {
		if (!surveyConf) return;

		if (currentQuestion) {
			setCurrentSection(
				surveyConf?.sections
					?.find?.((s) => s?.questions
						?.find?.((q) => q?.questionId === currentQuestion?.questionId)),
			);
		}
	}, [surveyConf, currentQuestion]);

	const findQuestionIndexInSection = ({ section, question }) => (
		getSectionQuestionsFromAnswers({ section, answers })
			?.findIndex?.((q) => q?.questionId === question?.questionId)
	);

	const findSectionIndex = ({ section }) => surveyConf?.sections
		?.findIndex?.((s) => s?.value === section?.value);

	const gotoPrevQuestion = ({ section: s, question: q }) => {
		const method = 'gotoPrevQuestion';

		let section = s || currentSection;
		let question = q || currentQuestion;

		const qIndex = findQuestionIndexInSection({ section, question });
		const sIndex = findSectionIndex({ section });

		let sectionQuestions = getSectionQuestionsFromAnswers({ section, answers });

		if (section && question) {
			if (qIndex === 0) {
				question = null;
			} else {
				question = sectionQuestions[qIndex - 1];
			}
		} else if (section) {
			if (sIndex === 0) {
				return;
			}
			section = surveyConf?.sections?.[sIndex - 1];
			sectionQuestions = getSectionQuestionsFromAnswers({ section, answers });
			question = sectionQuestions?.[(sectionQuestions?.length || 0) - 1];
		}

		setCurrentSection(section);
		setCurrentQuestion(question);
		LocalDebug.logInfo({ className, method }, 'navigate', buildSectionQuestionPath({
			baseRoutePath,
			section,
			question,
		}));
		LocalDebug.logInfo({ className, method }, 'navigate', buildSectionQuestionPath({
			baseRoutePath,
			section,
			question,
		}));
		navigate(
			buildSectionQuestionPath({
				baseRoutePath,
				section,
				question,
			}),
		);
	};

	const gotoNextQuestion = ({
		section: s,
		question: q,
		answers: newAnswers,
	}) => {
		const method = 'gotoNextQuestion';

		LocalDebug.logInfo({ className, method }, { s, q, newAnswers });

		let section = s || currentSection;
		let question = q || currentQuestion;

		const qIndex = findQuestionIndexInSection({ section, question });
		const sIndex = findSectionIndex({ section });

		const sectionQuestions = getSectionQuestionsFromAnswers({
			section, answers: newAnswers || answers,
		});

		const unansweredQuestionsFiltered = unansweredQuestions.filter((item) => {
			const questionId = item?.question?.questionId;
			return questionId !== question?.questionId;
		});

		if (section && question) {
			if (qIndex === (sectionQuestions?.length || 0) - 1) {
				if (sIndex === (surveyConf?.sections?.length || 0) - 1) {
					if (unansweredQuestionsFiltered?.length > 0) {
						LocalDebug.logInfo({ className, method }, 'navigate (unansweredQuestionsFiltered[0])', buildSectionQuestionPath({
							baseRoutePath,
							...unansweredQuestionsFiltered?.[0],
						}));
						return navigate(
							buildSectionQuestionPath({
								baseRoutePath,
								...unansweredQuestionsFiltered?.[0],
							}),
						);
					}
					setCurrentSection(null);
					setCurrentQuestion(null);
					LocalDebug.logInfo({ className, method }, 'navigate (/review)');
					return navigate(`${baseRoutePath}/review`);
				}
				section = surveyConf?.sections?.[sIndex + 1];
				question = null;
			} else {
				question = sectionQuestions[qIndex + 1];
			}
		} else if (section) {
			question = sectionQuestions?.[0];
		}
		setCurrentSection(section);
		setCurrentQuestion(question);
		LocalDebug.logInfo({ className, method }, 'navigate', buildSectionQuestionPath({
			baseRoutePath,
			section,
			question,
		}));
		navigate(
			buildSectionQuestionPath({
				baseRoutePath,
				section,
				question,
			}),
		);
	};

	const handleSubmit = async () => {
		try {
			setSubmitted(true);
		} catch (error) {
			console.error('Erreur lors de l\'envoi des données', error);
		}
	};

	const value = {
		baseRoutePath,
		eventContext,
		isLoading,
		surveyDraft,
		setSurveyDraft,
		surveyConf,
		// sections,
		// setSections,
		questions,
		// setQuestions,
		answers,
		setAnswers,
		saveAnswers,
		scores,
		currentSection,
		currentQuestion,
		gotoNextQuestion,
		gotoPrevQuestion,
		submitted,
		setSubmitted,
		handleSubmit,
		unansweredQuestions,
		appLang,
		setAppLang,
		t,
	};

	return (
		<SurveyNavigationContext.Provider
			value={value}
		>
			{children}
		</SurveyNavigationContext.Provider>
	);
};

export default SurveyNavigationProvider;
