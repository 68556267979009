import {
	Divider, Space,
} from 'antd';
import * as dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { useTheme } from '@emotion/react';
import CompanyLogo from '../CompanyLogo';
import CompanyGenderScoreLabel from './CompanyGenderScoreLabel';
import CompanyGenderScoreRingScore from './CompanyGenderScoreRingScore';
import { GENDERSCORE_LABEL } from '../../../constants/constant';
import GenderScorePillarLogoLabel from './pillars/GenderScorePillarLogoLabel';
import { GENDERSCORE_PILLAR_OPTIONS_LIST } from '../../../constants/genderscore-pillars';

dayjs.extend(relativeTime);

const CompanyGenderScoreDetailsPanel = (
	{
		company,
		size = 240,
		...props
	},
) => {
	const theme = useTheme();

	const score = company?.genderScore?.globalScore;

	if (!score) return null;

	return (
		<Space
			direction='vertical'
			style={{
				width: size,
				...props?.style,
				padding: '0 8px',
			}}
		>
			<div
				style={{
					height: 66,
					textAlign: 'center',
					marginBottom: 20,
				}}
			>
				<CompanyLogo
					size={50}
					company={company}
					style={{
						display: 'block',
						marginTop: '-40px',
						marginBottom: '6px',
						marginLeft: 'auto',
						marginRight: 'auto',
						// left: '50%', right: '50%',
						boxShadow: '0 2px 20px -2px rgba(0,0,0,.2)',
					}} />
				<CompanyGenderScoreLabel
					company={company}
					style={{
						background: 'linear-gradient(90deg, rgba(255, 0, 0, 1) 0%, rgba(255, 154, 0, 1) 10%, rgba(208, 222, 33, 1) 20%, rgba(79, 220, 74, 1) 30%, rgba(63, 218, 216, 1) 40%, rgba(47, 201, 226, 1) 50%, rgba(28, 127, 238, 1) 60%, rgba(95, 21, 242, 1) 70%, rgba(186, 12, 248, 1) 80%, rgba(251, 7, 217, 1) 90%, rgba(255, 0, 0, 1) 100%)',
						color: theme.color.white,
						padding: '2px 12px',
						borderRadius: 30,
						fontSize: 18,
						fontWeight: 700,
						width: 'unset',
						minWidth: 'unset',
						maxWidth: 'unset',
						textAlign: 'center',
						textTransform: 'uppercase',
					}}
				/>
			</div>
			<div
			>
				{GENDERSCORE_PILLAR_OPTIONS_LIST
					.map((option, index) => (
						<Space
							key={index}
							style={{
								width: '100%',
								justifyContent: 'space-between',
								marginBottom: 5,
							}}
						>
							<GenderScorePillarLogoLabel
								pillar={option?.value}
								labelSize={14}
								iconSize={16}
								style={{
									label: {
										fontWeight: 'bold',
										color: theme.color.darkgrey,
									},
								}}
							/>
							<CompanyGenderScoreRingScore
								company={company}
								pillar={option?.value}
								ringColor={option?.colors?.main}
								size={26}
								style={{ marginRight: 3 }}
							/>
						</Space>
					))
				}

				<Divider dashed={true} style={{ margin: '10px 0' }} />

				<Space
					style={{
						width: '100%',
						justifyContent: 'space-between',
						marginBottom: 5,
					}}
				>
					<span
						style={{
							color: theme.color.darkgrey,
							fontWeight: 700,
						}}
					>
						50inTech {GENDERSCORE_LABEL}
					</span>
					<CompanyGenderScoreRingScore company={company} withPopover={false} />
				</Space>
			</div>
		</Space>
	);
};

export default CompanyGenderScoreDetailsPanel;
