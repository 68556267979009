import { Switch } from 'antd';

const CompanyPlanGenderScoreSurveyIsEnabledSwitch = ({
	disabled = false,
	checked,
	onChange,
	...props
}) => {
	const className = 'CompanyPlanGenderScoreSurveyIsEnabledSwitch';

	return (
		<Switch
			disabled={disabled}
			checked={checked}
			onChange={onChange}
		 />
	);
};

export default CompanyPlanGenderScoreSurveyIsEnabledSwitch;
