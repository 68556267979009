import { useEffect, useState } from 'react';
import { ENGLISH_LANG, GENDERSCORE_LABEL } from '../../../../constants/constant';
import { useAuthContext } from '../../../../hooks/useAuthContext';
import { displayHtml } from '../../../../utils/common';
import CompanyFieldNotProvided from '../shared/CompanyFieldNotProvided';
import CompanyFieldNotProvidedLocked from '../shared/CompanyFieldNotProvidedLocked';

const CompanyDiversityActionsDisplay = (
	{
		company,
		lang = ENGLISH_LANG,
		isStaffLocked = false,
	},
) => {
	const className = 'CompanyDiversityActionsDisplay';

	const { isStaff, isStaffView } = useAuthContext();
	const [content, setContent] = useState(<CompanyFieldNotProvided />);

	const field = 'diversityActions';

	useEffect(() => {
		const value = company?.getTranslationFieldFallback?.(field, lang);
		if (value) {
			setContent(displayHtml(value));
			return;
		}
		if (isStaffLocked && !(isStaff && isStaffView)) {
			setContent(
				<CompanyFieldNotProvidedLocked>
					Our team will write the content for this section
					as soon as the {GENDERSCORE_LABEL} has been successfully passed.
				</CompanyFieldNotProvidedLocked>,
			);
			return;
		}
		setContent(<CompanyFieldNotProvided />);
	}, [company, lang, isStaffLocked, isStaff, isStaffView]);

	return (
		<>
			<h2>Diversity Actions</h2>
			{content}
		</>
	);
};

export default CompanyDiversityActionsDisplay;
