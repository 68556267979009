import { requiredTrimRule } from '../../../utils/common';
import FormItem from '../form/FormItem';

const CompanyAddFields = () => {
	const className = 'CompanyAddFields';

	return (
		<>
			<FormItem
				label="Company Name"
				name="name"
				rules={[requiredTrimRule]}
			/>
			<FormItem
				label="Company Path"
				name="path"
				rules={[
					requiredTrimRule,
					{
						pattern: new RegExp(/^\S*$/),
						message: 'White space is not allowed in this field',
					},
				]}
			/>
		</>
	);
};

export default CompanyAddFields;
