import UserActionsPage from '../../../containers/staff/data/UserActionsPage';
import { onEnterBuilder } from '../../../actions/app';
import { PERMS_GRANT_STAFF } from '../../menu.utils';

const menu = {
	name: 'staff-data-user-actions',
	path: '/staff/data/user-actions',
	label: 'User Actions',
	icon: <span>🖱️</span>,
	component: UserActionsPage,
	onEnter: onEnterBuilder({
		origin: UserActionsPage.name,
		shouldGoToPreviousHistory: true,
	}),
	perms: PERMS_GRANT_STAFF,
};

export default menu;
