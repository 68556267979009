import { useTheme } from '@emotion/react';
import { Button, Form, message } from 'antd';
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useAuthContext } from '../../hooks/useAuthContext';
import { getCompanies } from '../../reducers/app';
import UserService from '../../services/user';
import InviteUserForm from './inviteUser/InviteUserForm';
import InviteUserConfirmForm from './inviteUser/InviteUserConfirmForm';
import AclService from '../../services/acl';
import { sendInvitedUser } from '../../actions/acls';
import { localDebug } from '../../utils/LocalDebug';
import { documentId } from '../../utils/common';

const AclInviteForm = (
	{
		// form,
		initialValues = {},
		onInviteUser,
		autoValidateEmail = false,
		onCompanyChange,
		onSubmit,
		onReset,
		onClose,
		onCloseWithRefresh,
		withInviteUser = false,
		withSubmitButton = false,
	},
) => {
	const theme = useTheme();
	const dispatch = useDispatch();
	const companies = useSelector(getCompanies);

	const { isStaff, isStaffView, companyIdsUser } = useAuthContext();

	const [form] = Form.useForm();
	// local state
	const [companyId, setCompanyId] = useState(null);
	const [step, setStep] = useState(0);

	const [initialFormValues, setInitialFormValues] = useState({});

	const checkExistMail = async (values) => {
		const { email } = values;
		const { data: { isExist, isInvitePending, user } } = await UserService.getUserByMail(email);

		localDebug('checkExistMail', {
			values, isExist, isInvitePending, user,
		});
		setInitialFormValues({
			email,
			isExist,
			isInvitePending,
			userId: documentId(user),
			user,
			...isExist && { defaultUserOptions: user },
		});
		setStep(1);
	};

	useEffect(() => {
		if (autoValidateEmail) {
			checkExistMail(initialValues);
		}
	}, [autoValidateEmail]);

	const saveInvitedUser = async (values) => {
		const { roleId, userId } = values;

		localDebug('saveInvitedUser', { values });
		// check duplicate role user
		if (initialFormValues.isExist) {
			const { data: { isAclExist } } = await AclService.aclExists(roleId, { userId, companyId });
			if (isAclExist) {
				message.error('This access already exists for this user');
				return;
			}
		}
		const companyName = getCompanyName(companyId);
		const userData = {
			userId: initialFormValues.userId,
			...values,
			isExist: initialFormValues.isExist,
			isInvitePending: initialFormValues.isInvitePending,
			documentId: companyId,
			...!initialFormValues.isExist ? { 'information.company': companyName } : {},
		};
		dispatch(sendInvitedUser(userData, handleCloseDialog));
	};

	// company name
	const getCompanyName = (companyId) => {
		return companies.find((company) => documentId(company) === companyId)?.name;
	};

	const handleCloseDialog = () => {
		message.success(`Access granted to ${initialFormValues.email}`);
		localDebug('handleCloseDialog');
		// goToNextStep();
		// onClose();
		onCloseWithRefresh();
	};

	useEffect(() => {
		if (isStaffView) {
			// setIsShowCompanySelect(true);
			return;
		}
		// hide select company for company recruiter
		if (companyIdsUser.length === 1) {
			onCompanyChange(companyIdsUser[0]);
			// setIsShowCompanySelect(false);
		}
	}, [companyIdsUser, isStaff, isStaffView, onCompanyChange]);

	const buttonsComponents = <div style={{
		display: 'flex',
		flexDirection: 'row-reverse',
		margin: -5,
	}}>
		<Button type="primary"
			style={{
				margin: 5,
				background: theme.color.blue,
				borderColor: theme.color.blue,
				fontWeight: 'bold',
				borderRadius: 5,
			}}
			htmlType="submit"
		>
			Add
		</Button>
		<Button type="default"
			style={{
				margin: 5,
				borderColor: theme.color.orange,
				fontWeight: 'bold',
				borderRadius: 5,
				color: theme.color.orange,
			}}
			htmlType="reset"
			onClick={onReset}
		>
			Cancel
		</Button>
	</div>;

	return (<>
		{step === 0
				&& <>
					<InviteUserForm
						form={form}
						initialValues={{ ...initialValues }}
						onSubmit={checkExistMail}
					>
						{buttonsComponents}
					</InviteUserForm>
				</>
		}
		{step === 1
				&& <>
					<InviteUserConfirmForm
						form={form}
						onCompanyChange={setCompanyId}
						initialValues={{ ...initialFormValues }}
						onSubmit={saveInvitedUser}
					>
						{buttonsComponents}
					</InviteUserConfirmForm>
				</>
		}
	</>
	);
};

export default AclInviteForm;
