import { useTheme } from '@emotion/react';
import { Space, Tag, Tooltip } from 'antd';
import { NOT_PROVIDED_YET_LABEL, getHardSkillLabel, userTagStyle } from '../../../constants/constant';
import { useAuthContext } from '../../../hooks/useAuthContext';
import { FaFireAlt, FaInfoCircle } from 'react-icons/fa';

const UserHardSkills = (
	{
		user,
		mode = 'tags',
		withGroups = false,
		forceValue = null,
		size = 'medium',
		full = false,
		notProvided = NOT_PROVIDED_YET_LABEL,
		...props
	},
) => {
	const theme = useTheme();

	const { isAdmin, isStaffView } = useAuthContext();

	if (mode === 'inline') {
		let skills = forceValue?.map((value) => ({ value, label: getHardSkillLabel(value) }));

		if (!skills?.length) {
			skills = [
				// ...user?.getResumeTopSkills?.()?.map(value => ({ top: true, value: value?.trim() })) || [],
				...user?.career?.hardSkill?.map((value) => ({ value })) || [],
			].map((item) => ({ ...item, label: getHardSkillLabel(item.value) }));
		}

		skills = skills
			.filter((skill, i, a) => !skills
				.slice(0, i)
				.map((s) => s.label?.toLowerCase()).includes(skill.label.toLowerCase()));
		// ?.map(
		// (text, index) => {
		// 	return <div key={index}>
		// 		<FaFireAlt style={{color: "orange", marginBottom: -2}}/>&nbsp;{text?.trim()}
		// 	</div>
		// }) || notProvided;

		return (
			<>
				{/* <Space wrap={true} size={0}> */}
				{skills.map(({ top, label }, index) => (
					<span key={index}>
						{index > 0 && <span style={{ opacity: 0.2 }}>&nbsp;/ </span>}
						<Tooltip title='This is a top skill for this talent'>
							{top && <FaFireAlt style={{ color: theme.color.accent, marginBottom: -2, marginRight: 4 }}/>}
						</Tooltip>
						<span
							style={{
								...props?.itemStyle,
								// ...top && { color: theme.color.accent }
							}}
						>
							{label}
						</span>
						{/* <span style={{ opacity: .2 }}>&nbsp;•&nbsp;</span> */}
					</span>
				))}
				{/* </Space> */}
			</>
		);
	}

	const value = forceValue || user?.career?.hardSkill;
	if (!(value?.length > 0)) return notProvided;

	const tagStyle = {
		...userTagStyle(size),
		...props?.style,
	};
	// if (mode === 'tags') {
	const tags = value.map((tag, index) => <Tag color='blue' key={index}
		style={tagStyle}>{getHardSkillLabel(tag)}</Tag>);
	return tags?.length
		? <Space
			direction='horizontal'
			wrap={true}
			size={[2, 1]}
			style={{
				...props?.style?.justifyContent ? { justifyContent: props?.style?.justifyContent } : {},
			}}
		>
			{tags}
			{isAdmin && isStaffView
				&& <Tooltip title={JSON.stringify(user?.career?.hardSkill)}>
					<FaInfoCircle/>
				</Tooltip>
			}
		</Space>
		: notProvided;
	// }
	// return <>{tags}</>;

	// const groups = groupHardSkills(hardSkills);
	// return Object
	//     .entries(groups)
	//     .map(([group, labels], index) => <>
	//         {index > 0 && <Divider style={{margin: '10px 0 0 0'}}/>}
	//         <Space direction='horizontal'
	//                align='start'
	//                style={{width: '100%', ...index > 0 && { marginTop: 10} }}
	//                key={index}
	//         >
	//             <div style={{
	//                 width: 160,
	//                 fontWeight: 'bold',
	//                 // marginTop: 3
	//             }}>
	//                 {group}
	//             </div>
	//             <Space
	//                 wrap={true}
	//                 size={4}
	//             >
	//                 {labels.map(label => <Tag
	//                     color="blue"
	//                     style={{
	//                         fontSize: 14,
	//                         // padding: '2px 8px',
	//                         margin: 0
	//                     }} key={label}>{label}</Tag>)}
	//             </Space>
	//         </Space>
	//     </>);
};

export default UserHardSkills;
