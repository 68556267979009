import { SearchOutlined } from '@ant-design/icons';
import RecruiterSearchesPage from '../../../containers/staff/talent/RecruiterSearchesPage';
import MenuBase from '../../MenuBase';
import { PERMS_GRANT_STAFF } from '../../menu.utils';

export const StaffCompaniesRecruiterSearchesIcon = SearchOutlined;

class StaffCompaniesRecruiterSearchesMenu extends MenuBase {
	static props = {
		name: 'staff-talent-recruiter-searches',
		path: '/staff/talent/recruiter-searches',
		label: 'Recruiter searches',
		icon: <StaffCompaniesRecruiterSearchesIcon />,
		iconLegacy: '🔍',
		Component: RecruiterSearchesPage,
		onEnterProps: { shouldLoadUserTags: true },
		perms: PERMS_GRANT_STAFF,
	};

	constructor() {
		super(StaffCompaniesRecruiterSearchesMenu.props);
	}
}

export default StaffCompaniesRecruiterSearchesMenu;
