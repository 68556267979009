import { UMS_LABEL } from '../constant';

const UMS_SURVEY_KEY = 'ums-survey';

const UMS_SURVEY_MULTI = false;
const UMS_SURVEY_FREE = false;

export const UMS_SURVEY_MANAGER_GROUP_VALUE = 'ums-survey-manager-group';
export const UMS_SURVEY_EMPLOYEE_GROUP_VALUE = 'ums-survey-employee-group';

export const UMS_SURVEY_MANAGER_V1_VALUE = 'ums-survey-manager-v1';
export const UMS_SURVEY_EMPLOYEE_V1_VALUE = 'ums-survey-employee-v1';

export const UMS_SURVEY_MANAGER_V1_LABEL = `${UMS_LABEL} - Manager (v1)`;
export const UMS_SURVEY_EMPLOYEE_V1_LABEL = `${UMS_LABEL} - Employee (v1)`;

export const UMS_SURVEY_MANAGER_V1_OPTION = {
	group: UMS_SURVEY_MANAGER_GROUP_VALUE,
	value: UMS_SURVEY_MANAGER_V1_VALUE,
	label: UMS_SURVEY_MANAGER_V1_LABEL,
	shortLabel: 'UMS Mgr v1',
	tinyLabel: 'Mgr',
	color: '#f6fbf8',
};

export const UMS_SURVEY_EMPLOYEE_V1_OPTION = {
	group: UMS_SURVEY_EMPLOYEE_GROUP_VALUE,
	value: UMS_SURVEY_EMPLOYEE_V1_VALUE,
	label: UMS_SURVEY_EMPLOYEE_V1_LABEL,
	shortLabel: 'UMS Emp v1',
	tinyLabel: 'Emp',
	color: '#ff827c',
};

export const UMS_SURVEY_OPTIONS = [
	UMS_SURVEY_MANAGER_V1_OPTION,
	UMS_SURVEY_EMPLOYEE_V1_OPTION,
];

export const UMS_SURVEY_MANAGER_OPTIONS = [
	UMS_SURVEY_MANAGER_V1_OPTION,
];

export const UMS_SURVEY_EMPLOYEE_OPTIONS = [
	UMS_SURVEY_EMPLOYEE_V1_OPTION,
];

export const getUMSSurveyGroupOptions = (group) => (
	UMS_SURVEY_OPTIONS?.filter((o) => o?.group === group) || {}
);

export const getUMSSurveyOption = (value) => (
	UMS_SURVEY_OPTIONS?.find((o) => o?.value === value) || {}
);

export const getUMSSurveyLabel = (value) => (
	getUMSSurveyOption(value)?.label
);

export const getUMSSurveyShortLabel = (value) => (
	getUMSSurveyOption(value)?.shortLabel
);

export const getUMSSurveyTinyLabel = (value) => (
	getUMSSurveyOption(value)?.tinyLabel
);

export const getUMSSurveyColor = (value) => (
	getUMSSurveyOption(value)?.color
);

export const getUMSSurveyTag = (value, style) => (
	<span
		style={{
			fontSize: 12,
			fontWeight: 'bold',
			padding: '2px 4px',
			borderRadius: 3,
			lineHeight: 1.2,
			background: getUMSSurveyColor(value),
			color: 'white',
			...style || {},
		}}
	>
		{getUMSSurveyTinyLabel(value)}
	</span>
);

export const getUMSSurveyGroup = (value) => (
	getUMSSurveyOption(value)?.group
);

export const UMS_SURVEY_VALUES = UMS_SURVEY_OPTIONS.map((o) => o.value);

const exporter = {
	key: UMS_SURVEY_KEY,
	UMS_SURVEY_KEY,
	UMS_SURVEY_MANAGER_V1_VALUE,
	UMS_SURVEY_EMPLOYEE_V1_VALUE,
	multi: UMS_SURVEY_MULTI,
	UMS_SURVEY_MULTI,
	options: UMS_SURVEY_OPTIONS,
	UMS_SURVEY_OPTIONS,
	free: UMS_SURVEY_FREE,
	UMS_SURVEY_FREE,
	values: UMS_SURVEY_VALUES,
	UMS_SURVEY_VALUES,
	getUMSSurvey: getUMSSurveyOption,
	getUMSSurveyLabel,
	getUMSSurveyShortLabel,
	getUMSSurveyTinyLabel,
	getUMSSurveyColor,
	getUMSSurveyTag,
};

export default exporter;
