import FormSelect from './FormSelect';
import { Select } from 'antd';
import { useContext, useEffect } from 'react';
import { CandidateContext } from '../../../contexts/CandidateProvider';
import { LocalDebug } from '../../../utils/LocalDebug';
import StageTag from '../../sourcings/StageTag';
import { documentId } from '../../../utils/common';

const FormAtsStageSelect = (
	{
		showSearch = true,
		onChange,
		defaultValue,
		placeholder = 'Select a stage',
		styles = {},
		mode = 'single',
		...props
	},
) => {
	const className = 'FormAtsStageSelect';

	const { findStage, findPipeline } = useContext(CandidateContext);

	useEffect(() => {
		LocalDebug.logUseEffect({ className, effects: 'defaultValue' }, { defaultValue });
	}, [defaultValue]);

	const handleChange = (value, option) => {
		if (onChange) onChange(value, option);
	};

	return (
		<FormSelect
			showSearch={showSearch}
			optionFilterProp="children"
			optionLabelProp='label'
			placeholder={placeholder || (mode === 'multiple'
				? 'Select one or more stages'
				: 'Select a stage')
			}
			onChange={handleChange}
			defaultValue={documentId(findStage(defaultValue))}
			// value={documentId(findStage(defaultValue))}
			styles={{ minWidth: 180, ...styles }}
			mode={mode}
			filterOption={(input, option) => option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
			}
			render={(option, key) => (
				<Select.Option
					value={documentId(option)}
					label={
						<StageTag
							candidatePipeline={option.pipeline}
							candidateStage={option}
							withPipeline={true}
						/>
						// <Space direction='horizontal' size={0}>
						//     <Tag color='cyan' style={{ borderRadius: 20, fontWeight: 'bold' }}>{option.pipeline?.label}</Tag>
						//     <Tag color='green' icon={option.icon} style={{ borderRadius: 20, fontWeight: 'bold' }}>
						//         &nbsp;{option.label}
						//     </Tag>
						// </Space>
					}
					key={key}
				>
					<StageTag
						candidatePipeline={option.pipeline}
						candidateStage={option}
						withPipeline={true}
					/>
					{/* <Space direction='horizontal' size={0}> */}
					{/*    <Tag color='cyan'>{option.pipeline?.label}</Tag> */}
					{/*    <Tag color='green' icon={option.icon}>&nbsp;{option.label}</Tag> */}
					{/* </Space> */}
				</Select.Option>
			)}
			{...props}
		/>
	);
};

export default FormAtsStageSelect;
