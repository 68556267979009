import ModalContainerBase from '../../contexts/modals/ModalContainerBase';
import FormBoxTitle from '../app/form/FormBoxTitle';
import SourcingJobForm from './SourcingJobForm';

const SourcingJobPopover = (
	{
		open,
		initialValues = {},
		onSubmit,
		onReset,
	},
) => {
	return (
		<ModalContainerBase
			open={open}
			onCancel={onReset}
			width={750}
		>
			<FormBoxTitle
				title={'💼 Select a job'}
			/>
			<SourcingJobForm
				initialValues={initialValues}
				onSubmit={onSubmit}
				onReset={onReset}
			/>
		</ModalContainerBase>
	);
};

export default SourcingJobPopover;
