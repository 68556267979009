import { useState } from 'react';
import Popin from '../app/modal/Popin';
import AtsArchiveReasonMappingEditForm from './AtsArchiveReasonMappingEditForm';
import { documentId } from '../../utils/common';
import ModalContainerBase from '../../contexts/modals/ModalContainerBase';
import FormBoxTitle from '../app/form/FormBoxTitle';

const AtsArchiveReasonMappingEditPopover = (
	{
		initialValues = {},
		onCloseWithRefresh,
		open,
		onSubmit,
		onReset,
	},
) => {
	const [companyId, setCompanyId] = useState(initialValues?.companyId);

	const handleSubmit = (values) => {
		if (onSubmit) {
			onSubmit({ ...values, id: documentId(initialValues) });
		}
	};

	return (
		<ModalContainerBase
			open={open}
			onClose={onReset}
		>
			<FormBoxTitle
				title={'Edit an archive reason mapping'}
			/>
			<AtsArchiveReasonMappingEditForm
				initialValues={{ ...initialValues }}
				onReset={onReset}
				onSubmit={handleSubmit}
				onCloseWithRefresh={onCloseWithRefresh}
				withSubmitButton
			/>
		</ModalContainerBase>
	);
};

export default AtsArchiveReasonMappingEditPopover;
