import { useTheme } from '@emotion/react';
import {
	Col, Collapse, Descriptions, Popover, Space, Steps, Typography,
} from 'antd';
import { FaStar } from 'react-icons/fa';
import { displayHtml } from '../../../utils/common';
import TableColumns from '../../app/table/TableColumns';
import UserAvatar from '../widgets/UserAvatar';
import UserStub from '../stub/UserStub';
import { TAG_STAFF } from '../../../constants/constant';
import CoachingRating from './CoachingRating';

const CoachingDetails = (
	{
		user,
	},
) => {
	const theme = useTheme();

	// localDebug('user.coaching', user?.coaching);

	return (
		<Collapse ghost style={{ maxWidth: '100%', marginBottom: 20 }}>
			<Collapse.Panel header={<b>Coaching Details {TAG_STAFF}</b>} key="1">
				<Descriptions title="Coaching details" layout="horizontal" bordered>
					<Descriptions.Item label="Coach" span={3}>
						<UserStub user={user.coach}/>
					</Descriptions.Item>
					{user.coaching?.privateComment && <Descriptions.Item label="Private comment" span={3}>
						<Popover content={displayHtml(user.coaching?.privateComment)}>
							<Typography style={{ color: theme.color.eblue, textDecoration: 'underline' }}>Read
								the coach private comments</Typography>
						</Popover>
					</Descriptions.Item>
					}
					<Descriptions.Item label="Rating" span={3}>
						{user.coaching?.coachingRating >= 0
							&& <>
								<CoachingRating user={user}/>
								{user.coaching?.coachingRatingComment && displayHtml(user.coaching?.coachingRatingComment)}
							</>
						}
					</Descriptions.Item>
					<Descriptions.Item label="User feedback about 50inTech" span={3}>
						{displayHtml(user.coaching?.coachingFeedback)}
					</Descriptions.Item>
					<Descriptions.Item label="Status" span={3}>
						{user.coaching?.status}
					</Descriptions.Item>
					<Descriptions.Item label="Last status at" span={3}>
						{TableColumns.dateColumn(theme, {}).render(user.coaching?.coachedAt || user.coaching?.lastStatusAt)}
					</Descriptions.Item>
					<Descriptions.Item label="Telephone" span={3}>
						{user.coaching?.coachingPhone}
					</Descriptions.Item>
					<Descriptions.Item label="History" span={3}>

						<Steps direction="vertical">
							{user.coaching?.history
								.map((item, index) => <Steps.Step
									key={index}
									icon={item.coachId ? <div><UserAvatar user={item.coachId}
																			  title={`${item.coachId.firstName} ${item.coachId.lastName}`}
																			  withQuickLook/></div>
										: <UserAvatar user={user.coach} withQuickLook/>}
									title={<>
										<strong>action: </strong>{item.action || item.status} {item.value && <>/ <strong>value: </strong>{item.value}</>}</>}
									description={<Space>
										<Col>
											{TableColumns.dateColumn(theme, {}).render(item.createdAt)}
										</Col>
									</Space>}
								/>)
							}
						</Steps>
					</Descriptions.Item>
					{(() => {
						const {
							isActive,
							history,
							status,
							lastStatusAt,
							coachingFeedback,
							coachingCompanyCriteria,
							coachingRating,
							coachingRatingComment,
							coachingPhone,
							coachingOpinion,
							coachingTargetPositions,
							coachingExperience,
							coachingExperienceTech,
							coachingSoftSkills,
							coachingHardSkills,
							coachingAvailability,
							coachingMobility,
							coachingSalaryExpectations,
							coachingRecruitmentStatus,
							coachingLanguages,
							privateComment,
							publicComment,
							...rest
						} = user.coaching || {};
						return Object.entries(rest || {})
							.map(([category, value], index) => value
								&& <Descriptions.Item key={index} label={category} span={3}>
									{JSON.stringify(value)}
								</Descriptions.Item>);
					})()}
				</Descriptions>
			</Collapse.Panel>
		</Collapse>
	);
};

export default CoachingDetails;
