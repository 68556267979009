/** @jsxImportSource @emotion/react */

import { Space } from 'antd';
import { FaBell } from 'react-icons/fa';
import { useTheme } from '@emotion/react';
import CompanyMetaStatus from './CompanyMetaStatus';

/**
 * Renders a disclaimer indicating that there're meta tags pending to be customized
 */
const CompanyMetaDisclaimer = ({ company, isFullyCustomizedEN, isFullyCustomizedFR }) => {
	const theme = useTheme();

	return (
		<Space
			direction='horizontal'
			style={{
				display: 'flex',
				alignItems: 'center',
				rowGap: 10,
				columnGap: 10,
				background: '#f5f5f5',
				padding: 15,
				borderRadius: 5,
				marginBottom: 20,
			}}>
			<p>
				{/* Status for meta tags in English */}
				{ (!isFullyCustomizedEN)
                    && <CompanyMetaStatus company={company}
                    	isEnVersion={true}
                    	isTranslatable={company?.isTranslatable}
                    	isFullyCustomized={isFullyCustomizedEN}
                    />
				}
				{/* Status for meta tags in French, only if it's translatable */}
				{ (company?.isTranslatable && !isFullyCustomizedFR)
                    && <CompanyMetaStatus company={company}
                    	isEnVersion={false}
                    	isTranslatable={company?.isTranslatable}
                    	isFullyCustomized={isFullyCustomizedFR}
                    />
				}
				<Space direction='horizontal' style={{ marginTop: 20 }}>
					<FaBell style={{ marginBottom: -2, color: theme.color.mustard2 }}/>
					<span>
                    Please, refer to the <b>Growth Team</b> to fill in the SEO meta tags of the company.
					</span>
				</Space>
			</p>
		</Space>
	);
};
export default CompanyMetaDisclaimer;
