import { innerObjPropExtractor } from '../../property/property-extractor';
import { innerObjPropMapper } from '../../property/property-mapper';

const USER_PROPERTY_PROFILE_COMMUNITY_CONSENT_KEY = 'profile-community-consent';

const USER_PROPERTY_PROFILE_COMMUNITY_CONSENT_MULTI = false;
const USER_PROPERTY_PROFILE_COMMUNITY_CONSENT_FREE = false;

const USER_PROPERTY_PROFILE_COMMUNITY_CONSENT_VALIDATORS = [];

const USER_PROPERTY_PROFILE_COMMUNITY_CONSENT_FIELD = 'confidential.communityConsent';
const USER_PROPERTY_PROFILE_COMMUNITY_CONSENT_MAPPER = innerObjPropMapper(USER_PROPERTY_PROFILE_COMMUNITY_CONSENT_FIELD);
const USER_PROPERTY_PROFILE_COMMUNITY_CONSENT_EXTRACTOR = innerObjPropExtractor(USER_PROPERTY_PROFILE_COMMUNITY_CONSENT_FIELD);

export default {
	key: USER_PROPERTY_PROFILE_COMMUNITY_CONSENT_KEY,
	USER_PROPERTY_PROFILE_COMMUNITY_CONSENT_KEY,
	validators: USER_PROPERTY_PROFILE_COMMUNITY_CONSENT_VALIDATORS,
	USER_PROPERTY_PROFILE_COMMUNITY_CONSENT_VALIDATORS,
	free: USER_PROPERTY_PROFILE_COMMUNITY_CONSENT_FREE,
	USER_PROPERTY_PROFILE_COMMUNITY_CONSENT_FREE,
	multi: USER_PROPERTY_PROFILE_COMMUNITY_CONSENT_MULTI,
	USER_PROPERTY_PROFILE_COMMUNITY_CONSENT_MULTI,
	mapper: USER_PROPERTY_PROFILE_COMMUNITY_CONSENT_MAPPER,
	USER_PROPERTY_PROFILE_COMMUNITY_CONSENT_MAPPER,
	extractor: USER_PROPERTY_PROFILE_COMMUNITY_CONSENT_EXTRACTOR,
	USER_PROPERTY_PROFILE_COMMUNITY_CONSENT_EXTRACTOR,
	field: USER_PROPERTY_PROFILE_COMMUNITY_CONSENT_FIELD,
	USER_PROPERTY_PROFILE_COMMUNITY_CONSENT_FIELD,
};
