import { useSelector } from 'react-redux';
import PageLayout from '../../components/app/layout/PageLayout';
import { useTheme } from '@emotion/react';
import { getCompanySelected } from '../../reducers/app';
import CompanyGridPicker from '../../components/company/CompanyGridPicker';
import UMSInvite from '../../components/ums/UMSInvite';
import UMSInviteProvider from '../../components/ums/UMSInviteProvider';
import UMSTeamMenu from '../../menu/ums/UMSTeamMenu';

const UMSTeamPage = () => {
	const className = 'UMSTeamPage';

	const theme = useTheme();

	const companySelected = useSelector(getCompanySelected);

	if (!companySelected) return <CompanyGridPicker />;

	const menu = (new UMSTeamMenu()).build();

	return (
		<UMSInviteProvider>
			<PageLayout
				icon={menu.icon}
				title={menu.label}
			>
				<UMSInvite />
			</PageLayout>
		</UMSInviteProvider>
	);
};

export default UMSTeamPage;
