import { ENGLISH_LANG } from '../../../../constants/constant';
import { displayHtml } from '../../../../utils/common';
import CompanyFieldNotProvided from '../shared/CompanyFieldNotProvided';

const CompanyCommunitiesDisplay = (
	{
		company,
		lang = ENGLISH_LANG,
	},
) => {
	const className = 'CompanyCommunitiesDisplay';

	const field = 'communities';
	const value = company?.getTranslationFieldFallback(field, lang);

	const content = value
		? displayHtml(value)
		: <CompanyFieldNotProvided/>;

	return (
		<>
			<h2>Supported Communities</h2>
			{content}
		</>
	);
};

export default CompanyCommunitiesDisplay;
