import { useState } from 'react';
import { useTheme } from '@emotion/react';
import { NOT_PROVIDED_YET_LABEL } from '../../../../constants/constant';
import { useAuthContext } from '../../../../hooks/useAuthContext';

const UserCoachingOpinionCardBody = (
	{
		user,
		wordLimit = 60,
		notProvided = NOT_PROVIDED_YET_LABEL,
		...props
	},
) => {
	const { currentUser } = useAuthContext();

	const theme = useTheme();
	const [isFullDisplay, setIsFullDisplay] = useState(false);

	let content = user.getCoachingOpinion();
	if (currentUser?.canDisplayTalentNames?.(user) === false) {
		content = content.replaceAll(user?.firstName, '<i>[TALENT]</i>', '/i');
	}
	// const contentLength = user?.getCoachingOpinion().length;
	const fullContent = content
		? <p className="rawHtml" dangerouslySetInnerHTML={{ __html: content }}/>
		: notProvided;

	return fullContent;
	// localDebug('user?.getCoachingOpinion', user?.getCoachingOpinion()?.slice(0, wordLimit));
	// if (contentLength > wordLimit) {
	//     const limitedContent = <>
	//         <p>{user?.getCoachingOpinion()?.slice(0, wordLimit).join(' ')} [...]</p>
	//         <Link onClick={() => { setIsFullDisplay(true); }}>See more</Link>
	//     </>;
	//     const fullContentWithLink = <>
	//         {fullContent}
	//         <Link onClick={() => { setIsFullDisplay(false); }}>See less</Link>
	//     </>;
	//     return (isFullDisplay ? fullContentWithLink : limitedContent)
	// } else {
	//     return fullContent;
	// }
};

export default UserCoachingOpinionCardBody;
