import { useTheme } from '@emotion/react';
import { Space } from 'antd';
import Table from '../app/table/Table';
import { useState } from 'react';
import ReactJsonDebug from '../app/debug/ReactJsonDebug';

const UserHiddenCompanyMappingsTable = (
	{
		path = '/admin/user/hidden-company-mappings',
		filterOptions = {},
		isRefresh = 0,
	},
) => {
	const className = 'UserHiddenCompanyMappingsTable';

	const theme = useTheme();
	// selector
	const [isRefreshDataLayout] = useState(0);

	const columns = [
		{ title: 'Label', dataIndex: 'label' },
		{
			title: 'Search',
			dataIndex: 'search',
			render: (v) => (
				<Space direction='vertical'>
					<b>{v?.collection}</b>
					{Object.entries(v?.filters)
						.map(([key, rules], i) => (
							<div key={i}>
								<code style={{
									fontSize: 9, whiteSpace: 'nowrap', color: theme.color.black,
								}}>
									{key}
								</code>
								<span>
									{Object.entries(rules)
										.map(([rule, values], j) => (
											<span key={j}>
												&nbsp;
												<code style={{
													fontSize: 9, whiteSpace: 'nowrap', color: theme.color.fitBlueElectric,
												}}>
													{rule}
												</code>
												<span>
													{values?.map((value, k) => {
														const { regex, flag } = value;
														return (
															<>
																&nbsp;
																<code key={k}
																	  style={{ fontSize: 9, whiteSpace: 'nowrap' }}>
																	/{regex}/{flag}
																</code>
															</>
														);
													})}
												</span>
											</span>
										))
									}
								</span>
							</div>
						))
					}
				</Space>
			),
		},
		{
			title: 'Mappings',
			dataIndex: 'mappings',
			render: (v) => (
				<ReactJsonDebug src={v} collapsed={1}/>
			),
		},
	];

	return (
		<>
			<Table
				path={path}
				columns={columns}
				filterOptions={filterOptions}
				isRefresh={isRefresh + isRefreshDataLayout}
				// onSearchDataColumn={handleSearchAutoComplete}
				// withDynamicSearch
				// defaultColumnsOptions={defaultColumnsOptions}
			/>
		</>
	);
};

export default UserHiddenCompanyMappingsTable;
