const { isStringTypeValidator } = require('../../utils/isStringTypeValidator');

const DEI_CRITERIA_GROUP_KEY = 'dei-criteria-group';

const DEI_CRITERIA_GROUP_CAREER_CHANCES = 'career-chances';
const DEI_CRITERIA_GROUP_CAREER_CHANCES_LABEL = 'Career chances';
const DEI_CRITERIA_GROUP_SALARY_POLICIES = 'salary-policies';
const DEI_CRITERIA_GROUP_SALARY_POLICIES_LABEL = 'Salary policies';
const DEI_CRITERIA_GROUP_WORKLIFE_BALANCE = 'work-life-balance';
const DEI_CRITERIA_GROUP_WORKLIFE_BALANCE_LABEL = 'Work-life balance';
const DEI_CRITERIA_GROUP_DEI_ACTIONS = 'dei-actions';
const DEI_CRITERIA_GROUP_DEI_ACTIONS_LABEL = 'DE&I actions';

// const DEI_CRITERIA_GROUP_WORKLIFE_BALANCE = 'work-life-balance';
const DEI_CRITERIA_GROUP_EQUALPAY = 'equal-pay';
const DEI_CRITERIA_GROUP_FAIR_CAREER_PATH = 'fair-career-path';
const DEI_CRITERIA_GROUP_DEI_POLICIES = 'dei-policies';

const DEI_CRITERIA_GROUPS = [
	DEI_CRITERIA_GROUP_WORKLIFE_BALANCE,
	DEI_CRITERIA_GROUP_EQUALPAY,
	DEI_CRITERIA_GROUP_FAIR_CAREER_PATH,
	DEI_CRITERIA_GROUP_DEI_POLICIES,
];

const DEI_CRITERIA_GROUPS_V2 = [
	DEI_CRITERIA_GROUP_CAREER_CHANCES,
	DEI_CRITERIA_GROUP_SALARY_POLICIES,
	DEI_CRITERIA_GROUP_WORKLIFE_BALANCE,
	DEI_CRITERIA_GROUP_DEI_ACTIONS,
];

const DEI_CRITERIA_GROUP_OPTIONS = [
	{ value: DEI_CRITERIA_GROUP_WORKLIFE_BALANCE, label: 'Work-Life Balance' },
	{ value: DEI_CRITERIA_GROUP_EQUALPAY, label: 'Equal Pay' },
	{ value: DEI_CRITERIA_GROUP_FAIR_CAREER_PATH, label: 'Fair Career Path' },
	{ value: DEI_CRITERIA_GROUP_DEI_POLICIES, label: 'DE&I Policies' },
];

const DEI_CRITERIA_GROUP_OPTIONS_V2 = [
	{ value: DEI_CRITERIA_GROUP_CAREER_CHANCES, label: DEI_CRITERIA_GROUP_CAREER_CHANCES_LABEL },
	{ value: DEI_CRITERIA_GROUP_SALARY_POLICIES, label: DEI_CRITERIA_GROUP_SALARY_POLICIES_LABEL },
	{ value: DEI_CRITERIA_GROUP_WORKLIFE_BALANCE, label: DEI_CRITERIA_GROUP_WORKLIFE_BALANCE_LABEL },
	{ value: DEI_CRITERIA_GROUP_DEI_ACTIONS, label: DEI_CRITERIA_GROUP_DEI_ACTIONS_LABEL },
];

const customValidator = (value) => DEI_CRITERIA_GROUP_OPTIONS.map((o) => o.value).includes(value);

const DEI_CRITERIA_GROUP_VALIDATORS = [
	isStringTypeValidator,
	customValidator,
];

export default {
	DEI_CRITERIA_GROUP_CAREER_CHANCES,
	DEI_CRITERIA_GROUP_SALARY_POLICIES,
	DEI_CRITERIA_GROUP_DEI_ACTIONS,
	DEI_CRITERIA_GROUP_WORKLIFE_BALANCE,
	DEI_CRITERIA_GROUP_EQUALPAY,
	DEI_CRITERIA_GROUP_FAIR_CAREER_PATH,
	DEI_CRITERIA_GROUP_DEI_POLICIES,

	key: DEI_CRITERIA_GROUP_KEY,
	DEI_CRITERIA_GROUP_KEY,
	validators: DEI_CRITERIA_GROUP_VALIDATORS,
	DEI_CRITERIA_GROUP_VALIDATORS,
	groups: DEI_CRITERIA_GROUP_OPTIONS_V2,
	// DEI_CRITERIA_GROUPS,
	options: DEI_CRITERIA_GROUP_OPTIONS_V2,
	// DEI_CRITERIA_GROUP_OPTIONS,
};
