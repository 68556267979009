import { useTheme } from '@emotion/react';
import Link from '../Link';
import { openExternalUrl } from '../../../utils/common';
import HelpInfo from './HelpInfo';

const HelpInfoNotifications = (
	{
		children,
		...props
	},
) => {
	const className = 'HelpInfoNotifications';

	const theme = useTheme();

	return (
		<HelpInfo {...props}>
			<>
				Want to learn more about <b>notification management when a talent applies to a job
				offer? </b>
				{/* <br/> */}
				Read our guide:&nbsp;
				<Link
					onClick={() => openExternalUrl('https://www.notion.so/50intech/Guide-Gestion-des-notifications-lorsqu-une-candidate-postule-b52ee6b53a8c44e38724f4171881c52f')}
				>
					🇫🇷 French version
				</Link>
				&nbsp;
				<Link
					onClick={() => openExternalUrl('https://50intech.notion.site/Guide-Managing-notifications-when-a-candidate-applies-e6f832fdf6fb46288c59ace74570db38')}
				>
					🇬🇧 English version
				</Link>
			</>
			{children}
		</HelpInfo>
	);
};

export default HelpInfoNotifications;
