import EXPERIENCE_LEVEL from '../../property/experience-level';
import { innerObjPropExtractor } from '../../property/property-extractor';
import { innerObjPropMapper } from '../../property/property-mapper';

const USER_PROPERTY_JOB_SEARCH_JOB_LEVEL_KEY = 'job-search-job-level';

const USER_PROPERTY_JOB_SEARCH_JOB_LEVEL_MULTI = true;
const USER_PROPERTY_JOB_SEARCH_JOB_LEVEL_FREE = false;
const USER_PROPERTY_JOB_SEARCH_JOB_LEVEL_MAX = 2;

const USER_PROPERTY_JOB_SEARCH_JOB_LEVEL_FIELD = 'jobSearch.jobLevel';
const USER_PROPERTY_JOB_SEARCH_JOB_LEVEL_MAPPER = innerObjPropMapper(USER_PROPERTY_JOB_SEARCH_JOB_LEVEL_FIELD);
const USER_PROPERTY_JOB_SEARCH_JOB_LEVEL_EXTRACTOR = innerObjPropExtractor(USER_PROPERTY_JOB_SEARCH_JOB_LEVEL_FIELD);

export default {
	...EXPERIENCE_LEVEL,
	key: USER_PROPERTY_JOB_SEARCH_JOB_LEVEL_KEY,
	USER_PROPERTY_JOB_SEARCH_JOB_LEVEL_KEY,
	free: USER_PROPERTY_JOB_SEARCH_JOB_LEVEL_FREE,
	USER_PROPERTY_JOB_SEARCH_JOB_LEVEL_FREE,
	multi: USER_PROPERTY_JOB_SEARCH_JOB_LEVEL_MULTI,
	USER_PROPERTY_JOB_SEARCH_JOB_LEVEL_MULTI,
	max: USER_PROPERTY_JOB_SEARCH_JOB_LEVEL_MAX,
	USER_PROPERTY_JOB_SEARCH_JOB_LEVEL_MAX,
	mapper: USER_PROPERTY_JOB_SEARCH_JOB_LEVEL_MAPPER,
	USER_PROPERTY_JOB_SEARCH_JOB_LEVEL_MAPPER,
	extractor: USER_PROPERTY_JOB_SEARCH_JOB_LEVEL_EXTRACTOR,
	USER_PROPERTY_JOB_SEARCH_JOB_LEVEL_EXTRACTOR,
	field: USER_PROPERTY_JOB_SEARCH_JOB_LEVEL_FIELD,
	USER_PROPERTY_JOB_SEARCH_JOB_LEVEL_FIELD,
};
