import { request } from './api';

const findAll = (options = {}) => {
	const dataParams = {
		params: options,
	};
	return request.get('/tagRule', dataParams);
};

const getById = (id) => {
	return request.get(`/tagRule/${id}`);
};

const save = (data) => {
	return request.post('/tagRule', data);
};

const update = (tagId, data) => {
	return request.put(`/tagRule/${tagId}`, data);
};

const remove = (id) => {
	return request.delete(`/tagRule/${id}`);
};

const getTags = (options) => {
	const dataParams = {
		params: options,
	};
	return request.get('/tag', dataParams);
};

const tagRuleService = {
	findAll,
	getById,
	save,
	update,
	remove,
	getTags,
};

export default tagRuleService;
