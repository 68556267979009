import { useState } from 'react';
import { useTheme } from '@emotion/react';
import { FaMinusSquare, FaPlusSquare } from 'react-icons/fa';
import ReactMarkdown from 'react-markdown';
import { INPUT_MULTIPLE, INPUT_SINGLE } from '../../data/questions';
import { round, sumReducer } from '../../../../utils/common';

const buildQuestionRangeDetails = (question) => {
	const {
		ranges, input, options, suffix,
	} = question;
	let rangesDetails = '';
	if (Array.isArray(ranges) && ranges?.length > 1) {
		rangesDetails = [
			'Score depends on the answer:',
			...ranges
				.map((r) => {
					const [answer, score] = r;
					return `Answer is **${answer}${suffix || ''}** => you get **${score}%** of the points`;
				}),
		].join('\n - ');
	} else if (input === INPUT_SINGLE) {
		const max = options
			.map((option) => option.score)
			.reduce((prev, cur) => Math.max(prev, cur), 0);
		rangesDetails = [
			'Score depends on the answer:',
			...options
				.map((option) => {
					return `**${option.label}** => ${option.score > 0
						? `you get **${round(option.score * 100 / max)}%** of the points`
						: 'you get **0%** of the points'
					}`;
				}),
		].join('\n - ');
	} else if (input === INPUT_MULTIPLE) {
		const total = options
			.map((option) => option.score)
			.filter((score) => score > 0)
			.reduce(sumReducer, 0);
		rangesDetails = [
			'Score depends on the answer(s):',
			...options
				.map((option) => {
					return `**${option.label}** => ${option.score > 0
						? `you get **${round(option.score * 100 / total)}%** of the points`
						: 'you get **0%** of the points'
					}`;
				}),
		].join('\n - ');
	}
	return (
		<ReactMarkdown>{rangesDetails}</ReactMarkdown>
	);
};

const SurveyQuestionDetails = ({
	question,
}) => {
	const theme = useTheme();

	const [openDetails, setOpenDetails] = useState(false);

	const {
		questionId,
		label,
		shortLabel,
		sublabel,
		input,
		ranges,
		min,
		max,
		suffix,
		options,
		...rest
	} = question;

	let tagLabel = input;
	let content = `${input} ${JSON.stringify(rest)}`;
	let hasDetails = true;

	if (input === 'pct') {
		tagLabel = `${min + suffix} to ${max}${suffix}`;
		content = `**Percentage** (${min}${suffix} to ${max}${suffix})`;
		hasDetails = false;
	}
	if (input === 'number') {
		tagLabel = `Number (${min} to ${max})`;
		content = `**Number** (${min} to ${max})`;
		hasDetails = false;
	}
	if (input === 'single') {
		if (options?.length === 2 && options.map((o) => o.label).join('') === 'YesNo') {
			tagLabel = 'Yes/No';
			content = '**Yes** or **No**';
			hasDetails = false;
		} else {
			tagLabel = `Select 1 among ${options.length}`;
			content = `- ${options.map((o) => `**${o.label}** (${o.score} pts)`).join('\n - ')}`;
		}
	}
	if (input === 'multiple') {
		tagLabel = `Select any among ${options.length}`;
		content = `- ${options.map((o) => `**${o.label}** (+${o.score} pts)`).join('\n - ')}`;
	}

	hasDetails = hasDetails || (Array.isArray(ranges) && ranges?.length > 1);

	return (
		<>
			<div
				{...hasDetails && { onClick: () => setOpenDetails((p) => !p) }}
				style={{ color: theme.color.fitBlueElectric }}
			>
				<b>Expected answer:</b> {tagLabel} {hasDetails && (
					openDetails
						? <FaMinusSquare style={{ marginBottom: -2 }} />
						: <FaPlusSquare style={{ marginBottom: -2 }} />
				)}
			</div>

			{openDetails && (
				<div style={{ fontSize: 12, color: 'grey', marginTop: 4 }}>
					{buildQuestionRangeDetails(question)}
				</div>
			)}
		</>
	);
};

export default SurveyQuestionDetails;
