import {
	regular,
	PDF_PAGE_TEMPLATE_COVER,
	PDF_TEXT_STYLES,
	PDF_TYPE_DIVIDER,
	PDF_TYPE_TEXT,
	PDF_TYPE_TITLE,
	PDF_TYPE_IMAGE,
} from '../base/PDFBuilder';

const PageReachOut = async ({
	doc,
	context,
	skip,
}) => {
	const pageInnerWidth = doc.pdfWidth - (doc.pagePadding?.left || 0) - (doc.pagePadding?.right || 0);
	return [
		{
			items: [
				...await doc.getPageTemplateCover({ context, skip }),
				{
					type: PDF_TYPE_DIVIDER,
					color: '#ffffff',
					marginBottom: 8,
				},
				{
					type: PDF_TYPE_TITLE,
					text: {
						en: 'Reach out!',
						fr: 'Contactez-nous!',
					},
					color: '#ffffff',
					marginBottom: 100,
				},
				{
					type: PDF_TYPE_IMAGE,
					uri: await doc.makeImageUri({ id: context.logoId, context, skip }),
					width: 72 * 0.4,
					height: 29 * 0.4,
					x: (doc.pagePadding?.left || 0) + 2 / 3 * pageInnerWidth,
				},
				{
					type: PDF_TYPE_DIVIDER,
					color: '#ffffff',
					marginTop: 6,
					marginBottom: 8,
					x: (doc.pagePadding?.left || 0) + 2 / 3 * pageInnerWidth,
					width: pageInnerWidth / 3,
				},
				{
					type: PDF_TYPE_TEXT,
					...regular(PDF_TEXT_STYLES.normal),
					color: '#ffffff',
					text: 'support@50intech.com',
					marginBottom: 6,
					x: (doc.pagePadding?.left || 0) + 2 / 3 * pageInnerWidth,
				},
				{
					type: PDF_TYPE_TEXT,
					...regular(PDF_TEXT_STYLES.normal),
					color: '#ffffff',
					text: 'www.50intech.com',
					x: (doc.pagePadding?.left || 0) + 2 / 3 * pageInnerWidth,
				},
				{
					type: PDF_TYPE_IMAGE,
					uri: await doc.makeImageUri({ id: context.girlScalesId, context, skip }),
					width: 150,
					height: 150,
					x: 10,
					y: doc.pdfHeight - 150,
				},
			],
		},
	];
};

export default PageReachOut;
