const slugify = require('@sindresorhus/slugify');
const { isStringTypeValidator } = require('../../utils/isStringTypeValidator');
const COUNTRIES = require('../countries.json');

const LOCATION_COUNTRY_KEY = 'location-country';

const LOCATION_COUNTRY_OPTIONS = COUNTRIES;

const LOCATION_COUNTRY_VALUES = LOCATION_COUNTRY_OPTIONS.map((o) => o.value);

const customValidator = (value) => LOCATION_COUNTRY_VALUES.includes(value);

const LOCATION_COUNTRY_VALIDATORS = [
	isStringTypeValidator,
	customValidator,
];

export default {
	key: LOCATION_COUNTRY_KEY,
	LOCATION_COUNTRY_KEY,
	validators: LOCATION_COUNTRY_VALIDATORS,
	LOCATION_COUNTRY_VALIDATORS,
	options: LOCATION_COUNTRY_OPTIONS,
	LOCATION_COUNTRY_OPTIONS,
};
