import { Space, Tag } from 'antd';
import moment from 'moment/moment';
import {
	FaBan, FaCheck, FaClock, FaStop, FaTimes,
} from 'react-icons/fa';
import BlockStaff from '../../app/blocker/BlockStaff';
import CompanySettingsCard from './CompanySettingsCard';
import CompanySettingsCompanyPagePublicationForm from './CompanySettingsCompanyPagePublicationForm';
import { useTheme } from '@emotion/react';
import CompanySettingsChurnedAtForm from './CompanySettingsChurnedAtForm';

const ChurnStatus = ({ company }) => {
	const theme = useTheme();
	let icon = <FaClock style={{ marginBottom: -2, color: theme.color.fitBlueElectric }} />;
	let label = <span>
		<b>{company.name}</b> has not churned</span>;
	if (company.churnedAt) {
		icon = <FaBan style={{ marginBottom: -2, color: theme.color.red }} />;
		label = <span>
			<b>{company.name}</b> has <b>churned {moment(company.churnedAt).fromNow()}</b> ({moment(company.churnedAt).format('MMM Do, HH:mm')})</span>;
	}
	return <Space align='top'>
		{icon}
		{label}
	</Space>;
};

const CompanySettingsChurnedAtCard = (
	{
		company,
		onSuccess,
		labelCol = { span: 6 },
		wrapperCol = { span: 16 },
		...props
	},
) => {
	const className = 'CompanySettingsChurnedAtCard';

	return (
		<div style={{ position: 'relative' }}>
			<BlockStaff withBorder={false} style={{ display: 'block', width: '100%' }}
				tagStyle={{ top: 2, left: 24, opacity: 1 }}>
				<CompanySettingsCard
					title={<>👋 Churn date</>}
					details={<>
						At what date the contract ended and the company churned?
					</>}
					company={company}
					CompanySettingsForm={CompanySettingsChurnedAtForm}
				>
					<ChurnStatus company={company} />
				</CompanySettingsCard>
			</BlockStaff>
		</div>
	);
};

export default CompanySettingsChurnedAtCard;
