import CompanySettingsFormBase from './CompanySettingsFormBase';
import { useState } from 'react';
import FormBoxTitle from '../../app/form/FormBoxTitle';
import FormItem from '../form/FormItem';
import { Form } from 'antd';
import FormSubmitButton from '../../app/form/FormSubmitButton';
import BlockStaff from '../../app/blocker/BlockStaff';
import Switch from '../../app/form/Switch';

const CompanySettingsContractForm = (
	{
		company,
		onSuccess,
		labelCol = { span: 0 },
		wrapperCol = { span: 24 },
		...props
	},
) => {
	const className = 'CompanySettingsContractForm';

	const [form] = Form.useForm();
	const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);

	return (
		<BlockStaff
			withBorder={false}
			tagStyle={{
				top: 5,
				left: 0,
				opacity: 1,
			}}
		>
			<FormBoxTitle
				title={<>✍️ Contract</>}
				details={<>
					Defines whether the client has access to special paying
					features such as Diversity Stories or
					profile suggestions from 50inTech's Talent team.
				</>}
			/>

			<CompanySettingsFormBase
				form={form}
				formName='company-settings-notifications-form'
				company={company}
				labelCol={labelCol}
				wrapperCol={wrapperCol}
				layout='horizontal'
				setIsSubmitDisabled={setIsSubmitDisabled}
				onSuccess={onSuccess}
				hiddenValues={{ forceRecompute: false }}
			>

				<FormItem
					name="isClient"
					valuePropName="checked"
				>
					<Switch
						style={{ width: 110 }}
						className='ant-switch-large'
						checkedChildren={<b>is</b>}
						unCheckedChildren={<b>is not</b>}
						before={<b>{company.name}</b>}
						after={<>a paid client</>}
					/>
				</FormItem>

				<FormItem
					name="isSuggestion"
					valuePropName="checked"
				>
					<Switch
						style={{ width: 110 }}
						className='ant-switch-large'
						checkedChildren={<b>will</b>}
						unCheckedChildren={<b>will not</b>}
						before={<b>{company.name}</b>}
						after={<>receive suggestions from 50inTech's Talent team</>}
					/>
				</FormItem>

				<FormItem>
					<FormSubmitButton disabled={isSubmitDisabled}>
							Save contract settings
					</FormSubmitButton>
				</FormItem>

			</CompanySettingsFormBase>

		</BlockStaff>
	);
};

export default CompanySettingsContractForm;
