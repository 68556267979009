import { Badge } from 'antd';
import { useTheme } from '@emotion/react';

const TabBadge = (
	{
		count,
		children,
		...props
	},
) => {
	const theme = useTheme();

	return (<Badge
		size="small"
		showZero={false}
		status='default'
		count={props?.content || count}
		overflowCount={10000}
		// offset={[0, -7]}
		offset={[6, -7]}
		// color={theme.color.eblue}
		color={theme.color.grey}
		{...props}
		style={{
			fontSize: 9,
			fontWeight: 700,
			borderRadius: 3,
			borderColor: 'transparent',
			padding: '0px 3px',
			height: 13,
			...props?.style,
		}}
	>
		{children}
	</Badge>);
};

export default TabBadge;
