import GenderhireCandidatesAppliedMenu from '../../menu/genderhire/GenderhireCandidatesAppliedMenu';
import SourcingsBasePage from './SourcingsBasePage';

const ApplicationsPage = () => (
	<SourcingsBasePage
		mode='applications'
		MenuClass={GenderhireCandidatesAppliedMenu}
	/>
);

export default ApplicationsPage;
