/** @jsxImportSource @emotion/react */

import { Input, Space, Spin } from 'antd';
import ArticleDivider from './ArticleDivider';
import { FaUpload } from 'react-icons/fa';
import FormItem from '../../../../app/form/FormItem';
import { formatImage } from '../../../../../utils/common';
import { uploadFileArticle } from '../../../../../actions/article';
import { useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import { ENGLISH_LANG } from '../../../../../constants/constant';

function FieldItemUpload({
	label, lang, name, defaultValue, onChange, styles,
}) {
	const dispatch = useDispatch();

	const [loading, setLoading] = useState(false);
	const [preview, setPreview] = useState(defaultValue);

	const handleUploadImage = async (dataMeta) => {
		setLoading(true);
		const formData = new FormData();
		formData.append('image', dataMeta);
		const result = await dispatch(uploadFileArticle(formData));
		setLoading(false);
		onChange(name, result.secure_url);
		setPreview(result.secure_url);
	};

	useEffect(() => {
		setPreview(defaultValue);
	}, [defaultValue]);

	return (
		<>
			<div css={{ borderRadius: 4, padding: '8px 12px', marginBottom: 20 }}>
				<ArticleDivider isEnVersion={lang === ENGLISH_LANG}/>

				<Space direction="vertical" css={{ width: '100%' }}>
					<label className="form-label">{label}</label>
					<Space
						css={{
							borderRadius: 5,
							borderWidth: 0,
							backgroundColor: ' #FF635B',
							width: '100%',
							position: 'relative',
							height: 40,
							justifyContent: 'center',
							color: '#fff',
							textTransform: 'uppercase',
							fontWeight: 700,
						}}
					>
						{!loading ? (
							<>
								uploader votre image <FaUpload/>
							</>
						) : (
							<Spin/>
						)}

						<FormItem
							defaultContainer="div"
							styles={{
								rootContainer: {
									display: 'flex',
									flexDirection: 'row',
									width: '100%',
									gap: 10,
								},
								label: { marginTop: 6, width: 140 },
								container: {
									width: '100%',
									height: 40,
									position: 'absolute',
									top: 0,
									left: 0,
								},
							}}
						>
							<Input
								type="file"
								onChange={(e) => {
									handleUploadImage(e.target.files[0]);
								}}
								css={{
									borderRadius: 5,
									borderWidth: 0,
									backgroundColor: ' #FF635B',
									opacity: 0,
								}}
							/>
						</FormItem>

					</Space>
				</Space>
			</div>

			{preview && (
				<img
					css={{ width: '100%' }}
					src={formatImage(preview, {
						width: 1200,
						crop: 'fill',
						quality: 'auto:best',
					})}
				/>
			)}
		</>
	);
}

export default FieldItemUpload;
