import CONTENT_INTEREST from '../../property/content-interest'
import { innerObjPropExtractor } from '../../property/property-extractor';
import { innerObjPropMapper } from '../../property/property-mapper';

const USER_PROPERTY_PROFILE_CONTENT_INTEREST_KEY = 'profile-content-interest';

const USER_PROPERTY_PROFILE_CONTENT_INTEREST_MULTI = true;
const USER_PROPERTY_PROFILE_CONTENT_INTEREST_FREE = false;

const USER_PROPERTY_PROFILE_CONTENT_INTEREST_FIELD = 'information.company';
const USER_PROPERTY_PROFILE_CONTENT_INTEREST_MAPPER = innerObjPropMapper(USER_PROPERTY_PROFILE_CONTENT_INTEREST_FIELD);
const USER_PROPERTY_PROFILE_CONTENT_INTEREST_EXTRACTOR = innerObjPropExtractor(USER_PROPERTY_PROFILE_CONTENT_INTEREST_FIELD);

export default {
	...CONTENT_INTEREST,
	key: USER_PROPERTY_PROFILE_CONTENT_INTEREST_KEY,
	USER_PROPERTY_PROFILE_CONTENT_INTEREST_KEY,
	multi: USER_PROPERTY_PROFILE_CONTENT_INTEREST_MULTI,
	USER_PROPERTY_PROFILE_CONTENT_INTEREST_MULTI,
	free: USER_PROPERTY_PROFILE_CONTENT_INTEREST_FREE,
	USER_PROPERTY_PROFILE_CONTENT_INTEREST_FREE,
	mapper: USER_PROPERTY_PROFILE_CONTENT_INTEREST_MAPPER,
	USER_PROPERTY_PROFILE_CONTENT_INTEREST_MAPPER,
	extractor: USER_PROPERTY_PROFILE_CONTENT_INTEREST_EXTRACTOR,
	USER_PROPERTY_PROFILE_CONTENT_INTEREST_EXTRACTOR,
	field: USER_PROPERTY_PROFILE_CONTENT_INTEREST_FIELD,
	USER_PROPERTY_PROFILE_CONTENT_INTEREST_FIELD,
};
