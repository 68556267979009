import {
	useContext, useEffect, useRef, useState,
} from 'react';
import { useTheme } from '@emotion/react';
import { DataFetcherContext } from './DataFetcherProvider';
import { Skeleton, Spin } from 'antd';
import { AppDataContext } from '../../../contexts/AppDataProvider';
import { useAuthContext } from '../../../hooks/useAuthContext';

const DataFetcherTotal = (
	{
		empty = <>No results found</>,
		showTotal = true,
		...props
	},
) => {
	const className = 'DataFetcherTotal';

	const theme = useTheme();

	const { dataFetcherIndicatorTotalLimit } = useContext(AppDataContext);
	const { isStaffView } = useAuthContext();

	const {
		isLoading, dataTotal, dataSource,
	} = useContext(DataFetcherContext);

	const refContainer = useRef();
	const [width, setWidth] = useState();

	useEffect(() => {
		if (refContainer.current) {
			setWidth(refContainer.current.offsetWidth);
		}
	}, [dataTotal, isLoading]);

	let label = <>{dataTotal} result{dataTotal > 1 && 's'}</>;

	if (dataSource?.length === 0 && !isLoading) {
		label = empty;
	}
	if (!isStaffView && dataTotal > dataFetcherIndicatorTotalLimit) {
		label = <>{dataFetcherIndicatorTotalLimit}+ results</>;
	}

	if (isLoading) {
		return <Skeleton.Input active style={{
			minWidth: width || 70, maxWidth: width || 70, height: 32, borderRadius: 5, marginBottom: 0,
		}}/>;
	}

	return (
		<div
			ref={refContainer}
			style={{
				// border: '1px solid red',
				fontSize: 12,
				color: theme.color.grey,
				...props?.style,
			}}
		>
			{label}
		</div>
	);
};

export default DataFetcherTotal;
