import { useEffect, useContext, useState } from 'react';
import { Layout as AntLayout } from 'antd';
import { useAuthContext } from '../../../hooks/useAuthContext';
import { useTheme } from '@emotion/react';
import { useLocation } from 'react-router-dom';
import AppLayoutSider from './AppLayoutSider';
import { PATHS } from '../../../constants/constant';
import AppLayoutHeader from './AppLayoutHeader';
import { AppDataContext } from '../../../contexts/AppDataProvider';
import AppLayoutBanner from './AppLayoutBanner';
import { CompanyContext } from '../../../contexts/CompanyProvider';
import { isHiringPlan } from '../../../constants/property/business-plan';
import { useSelector } from 'react-redux';
import { getCompanyGenderScoreSurveyDraftSelected, getCompanyUMSSurveyDraftSelected } from '../../../reducers/app';

const { Sider, Content } = AntLayout;

const AppLayout = ({
	children,
}) => {
	const theme = useTheme();

	const { currentUser, isDocumentEditorOnly } = useAuthContext();

	const { planValue } = useContext(CompanyContext);
	const genderScoreDraftSelected = useSelector(getCompanyGenderScoreSurveyDraftSelected);
	const umsDraftSelected = useSelector(getCompanyUMSSurveyDraftSelected);

	const {
		isAppSiderVisible,
		appSiderWidth,
		setIsAppSiderVisible,
		isAppHeaderVisible,
		isAppBannerVisible,
		setIsAppBannerVisible,
		appTopOffset,
		appSettings,
		isAppSiderVisibleInSurvey,
	} = useContext(AppDataContext);

	const [manualCloseAppBanner, setManualCloseAppBanner] = useState(false);

	const location = useLocation();

	useEffect(() => {
		setIsAppSiderVisible(
			![
				PATHS.RESET_PASSWORD,
				PATHS.LOGIN,
				// Menu still shown if draft not present (to keepp it on the start page)
				...(isAppSiderVisibleInSurvey || !genderScoreDraftSelected) ? [] : [PATHS.GENDERSCORE_SURVEY()],
				...(isAppSiderVisibleInSurvey || !umsDraftSelected) ? [] : [PATHS.UMS_SURVEY()],
			]
				.some((p) => location?.pathname?.indexOf?.(p) === 0),
		);
	}, [setIsAppSiderVisible, isAppSiderVisibleInSurvey, genderScoreDraftSelected, umsDraftSelected, location]);

	useEffect(() => {
		const appBanner = JSON.parse(appSettings?.appBanner || '{}');
		if (
			// Banner was closed manually by the user
			manualCloseAppBanner
			// Banner hidden on the GS Survey
			|| [PATHS.GENDERSCORE_SURVEY()]
				.some((p) => location?.pathname?.indexOf?.(p) === 0)
			|| [PATHS.UMS_SURVEY()]
				.some((p) => location?.pathname?.indexOf?.(p) === 0)
			// Banner requires to be logged (i.e. to not display on login page)
			|| (appBanner?.shouldUserBeLogged && !currentUser)
			// Banner requires to not be only an editor
			|| (appBanner?.shouldDenyEditorOnlyRole && isDocumentEditorOnly)
			// Banner requires to be on a plan with hiring
			|| (appBanner?.shouldGrantOnlyHiringPlans && !isHiringPlan(planValue))
			// Banner doesn't have any text defined
			|| !appBanner?.text
		) {
			setIsAppBannerVisible(false);
		} else {
			setIsAppBannerVisible(true);
		}
	}, [
		currentUser,
		isDocumentEditorOnly,
		planValue,
		setIsAppBannerVisible,
		appSettings?.appBanner,
		manualCloseAppBanner,
		location,
	]);

	const onManualCloseAppBanner = () => {
		setManualCloseAppBanner(true);
	};

	return (
		<AntLayout>
			{isAppBannerVisible && <AppLayoutBanner onClose={onManualCloseAppBanner}/>}
			{isAppHeaderVisible && <AppLayoutHeader />}

			<AntLayout style={{
				marginTop: appTopOffset,
			}}>

				{isAppSiderVisible && <AppLayoutSider />}

				<AntLayout
					className="site-layout"
					style={{
						transition: 'width 0.5s ease-out',
						...(isAppSiderVisible && {
							paddingTop: 0,
							marginLeft: appSiderWidth,
						}),
						minHeight: '100vh',
					}}
				>
					<Content
						className="site-layout-background"
						style={{
							padding: 0,
						}}
					>
						{children}
					</Content>

				</AntLayout>
			</AntLayout>

		</AntLayout>
	);
};

export default AppLayout;
