import { useEffect, useState } from 'react';
import { Col, Form, Row } from 'antd';
import { getQueryParams } from '../../utils/common';
import { localDebug } from '../../utils/LocalDebug';
import { sourcingStages } from '../../constants/constant';
import FormSelect from '../app/form/FormSelect';
import FormItem from '../company/form/FormItem';
import FilterModal from '../app/filters/FilterModal';

const SourcingFilterModal = (
	{
		title,
		open,
		initialValues = {},
		onClose,
		onFilter,
		modalOptions = {},
	},
) => {
	const [form] = Form.useForm();
	const queryParams = getQueryParams();
	const [optionsStage, setOptionsStage] = useState([]);

	const getDefaultSelected = (fieldName) => {
		return queryParams[fieldName] || [];
	};

	useEffect(() => {
		setOptionsStage([...modalOptions?.sourcingStages || sourcingStages]);
	}, []);

	const handleFilter = () => {
		form
			.validateFields()
			.then((values) => {
				const filtersValues = {
					...values,
				};
				if (onFilter) onFilter(filtersValues);
			})
			.catch((errors) => {
				localDebug('Validate Failed:', errors);
			});
	};

	useEffect(() => {
		form.resetFields();
		form.setFieldsValue(initialValues);
	}, [form, initialValues]);

	return (
		<FilterModal
			title={title}
			width={500}
			open={open}
			onClose={onClose}
			onFilter={handleFilter}
		>
			<Form
				form={form}
				name="add-team"
				labelCol={{ span: 8 }}
				labelAlign='right'
				className="user-filter-form"
			>
				<Row gutter={24}>
					<Col span={24}>
						<FormItem
							label="Stage"
							name="stage"
							defaultContainer="div"
						>
							<FormSelect
								options={optionsStage}
								placeholder="Select a stage"
								defaultValue={getDefaultSelected('stage')}
								mode="multiple"
							/>
						</FormItem>
					</Col>
				</Row>
			</Form>
		</FilterModal>
	);
};

export default SourcingFilterModal;
