import { useAuthContext } from '../../hooks/useAuthContext';
import SourcingsBasePage from './SourcingsBasePage';
import { LocalDebug } from '../../utils/LocalDebug';
import { useContext, useEffect } from 'react';
import { CandidateContext } from '../../contexts/CandidateProvider';
import GenderhireCandidatesArchivedMenu from '../../menu/genderhire/GenderhireCandidatesArchivedMenu';

const ArchivedPage = () => {
	const className = 'Archived';

	const { isAtsSyncable } = useAuthContext();
	const { updateSelectedOptions } = useContext(CandidateContext);

	useEffect(() => {
		updateSelectedOptions({
			pipeline: null,
			stages: [],
			isArchived: true,
		});
	}, []);

	LocalDebug.logInfo({ className, method: 'Init' }, { isAtsSyncable });

	return (
		<SourcingsBasePage
			mode='archived'
			MenuClass={GenderhireCandidatesArchivedMenu}
		/>
	);
};

export default ArchivedPage;
