import { useState } from 'react';
import { UMS_LABEL_SHORT } from '../../../../constants/constant';
import FormItem from '../../form/FormItem';
import CompanyPlanUMSSurveyIsEnabledSwitch from './CompanyPlanUMSSurveyIsEnabledSwitch';

export const companyPlanUMSSurveyIsEnabledPath = ['companyPlan', 'ums', 'isSurveyEnabled'];

const CompanyPlanUMSSurveyIsEnabledFormItem = ({
	initialValue,
}) => {
	const className = 'CompanyPlanUMSSurveyIsEnabledFormItem';

	const [umsSurveyIsEnabled, setUMSSurveyIsEnabled] = useState(initialValue);

	const onUMSSurveyIsEnabledChange = (surveyIsEnabledNewValue) => {
		setUMSSurveyIsEnabled(surveyIsEnabledNewValue);
	};

	return (
		<FormItem
			label={
				<span>
					{UMS_LABEL_SHORT} Survey enabled
				</span>
			}
			style={{ marginBottom: 5 }}
			name={companyPlanUMSSurveyIsEnabledPath}
			valuePropName='checked'
		>
			<CompanyPlanUMSSurveyIsEnabledSwitch
				onChange={onUMSSurveyIsEnabledChange}
			/>
		</FormItem>
	);
};

export default CompanyPlanUMSSurveyIsEnabledFormItem;
