import { Tag } from 'antd';
import theme from '../../theme';

const CountTag = (
	{
		count,
		selected,
	},
) => {
	return <Tag style={{
		display: 'inline-block',
		fontSize: 11,
		fontWeight: 900,
		color: theme.color.fitBlueElectric,
		border: 'none',
		margin: '0px -4px 2px 8px',
		lineHeight: 1,
		padding: '5px 6px 4px',
		verticalAlign: 'middle',
		background: 'rgba(24, 144, 255, .2)',
		...selected
        && {
        	color: 'white',
        	background: 'rgba(255, 255, 255, .2)',
        },
	}}>{count}</Tag>;
};
export default CountTag;
