import { useContext } from 'react';
import { useTheme } from '@emotion/react';
import { Divider, Space } from 'antd';
import { useAuthContext } from '../../hooks/useAuthContext';
import { GENDERSCORE_PASSING_DISALLOWED_REASONS } from '../../models/CompanyModel';
import { GENDERSCORE_EMPTY_ICON_IMG, GENDERSCORE_LABEL } from '../../constants/constant';
import GenderScoreLevelDetails from '../company/genderscore/levels/GenderScoreLevelDetails';
import UserStub from '../user/stub/UserStub';
import CsmSignature from '../onboarding/shared/CsmSignature';
import { CompanyContext } from '../../contexts/CompanyProvider';
import { Link } from 'react-router-dom';
import GenderScoreResultsExpirationBanners from '../company/genderscore/results/GenderScoreResultsExpirationBanners';

const GenderScoreSurveyNotAllowed = ({
	companyModel,
}) => {
	const className = 'GenderScoreSurveyNotAllowed';
	const theme = useTheme();

	const { currentUser, isDocumentAdmin } = useAuthContext();
	const { csmDetails } = useContext(CompanyContext);

	const reasons = companyModel.getGenderScorePassingDisallowedReasons({
		isDocumentAdmin,
		currentUser,
	}) || [];

	if (!currentUser) return null;

	let title = <>Sorry {currentUser?.firstName}, but you are not
	allowed to take the {GENDERSCORE_LABEL} survey.</>;
	const defaultDetails = <>Please speak to your Customer Success Manager to make the {GENDERSCORE_LABEL} Survey accessible to you.</>;
	let details = defaultDetails;

	if (reasons.includes(GENDERSCORE_PASSING_DISALLOWED_REASONS.SURVEY_DISABLED)) {
		title = <>Sorry {currentUser?.firstName}, but the {GENDERSCORE_LABEL} Survey is not open yet.</>;
		if (!companyModel?.isGenderScoreFilled()) {
			// They don't have a badge yet and the survey is not enabled -> something is wrong, speak to CSM
			details = defaultDetails;
		} else if (companyModel?.isGenderScoreCloseToExpire()) {
			// GS plan is expired and they already have a badge: urge the client to renew
			details = <><b>Your {GENDERSCORE_LABEL} is {
				companyModel?.isGenderScoreExpired() ? '📆 expired' : 'about to 📆 expire'
			}</b>. Please speak to your Customer Success Manager to renew it.</>;
		} else if (companyModel?.isCurrentGenderScoreBadgeCloseToExpire()) {
			// They renewed but the current badge is about to expire -> speak to CSM to open the survey
			details = defaultDetails;
		} else {
			// They don't need to take the survey now
			details = <>It's too soon to retake the {GENDERSCORE_LABEL} Survey.
			Now it's a good moment to take a look to your <Link to='/genderscore/action-plan'>Action Plan</Link> </>;
		}
	} else if (reasons.includes(GENDERSCORE_PASSING_DISALLOWED_REASONS.USER_NOT_OWNER)) {
		details = <>
			<UserStub
				user={companyModel?.genderScoreSurveyOwner}
				withIsUnviewed={false}
				withLocation={false}
				avatarSize={28}
			/> is the selected person in your company to answer and submit the survey.</>;
	}

	return (
		<>
			<GenderScoreResultsExpirationBanners
				company={companyModel}
				withNavigateToSurvey={false}
			/>
			<div
				style={{
					maxWidth: 860,
					margin: '40px auto 0',
					fontSize: 16,
				}}
			>
				<Space
					wrap={false}
					align={'top'}
					size={30}
				>
					{GENDERSCORE_EMPTY_ICON_IMG({ width: 210 })}

					<div style={{ fontSize: 16 }}>
						<h1>
						Welcome to the<br />{GENDERSCORE_LABEL} Survey! 🌟
						</h1>
						<div style={{
							background: theme.background.gradientBluePink(315),
							padding: '14px 40px 24px',
							borderRadius: 10,
							marginTop: 20,
							width: 620,
						}}
						>
							<p style={{
								fontSize: 16,
								fontWeight: 500,
							}}>
								{title}
							</p>
							{
								details
							&& (
								<p style={{
									fontSize: 14,
								}}>
									{details}
								</p>
							)
							}
							<Divider style={{
								width: '100%',
								margin: '16px 0 12px',
							}} />

							<CsmSignature data={csmDetails} />
						</div>
					</div>
				</Space>

				<Divider />

				<div
					style={{
						background: theme.color.bgGrey,
						padding: '20px 30px',
						borderRadius: 10,
						marginTop: 60,
					}}
				>
					<Divider style={{ }}>
						<h2>The {GENDERSCORE_LABEL} Levels</h2>
					</Divider>

					<p style={{ fontSize: 16 }}>
					Once the {GENDERSCORE_LABEL} Survey is submitted,
					you can check out the {GENDERSCORE_LABEL} score on this page.
					Then, it's essential to understand the implications of the score.
						<br />Here's what each bracket signifies:
					</p>

					<GenderScoreLevelDetails />

				</div>
			</div >
		</>
	);
};

export default GenderScoreSurveyNotAllowed;
