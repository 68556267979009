import { useTheme } from '@emotion/react';
import { useContext } from 'react';
import { Button, Popover, Tooltip } from 'antd';
import { FaCheck, FaUpload } from 'react-icons/fa';
import { CompanyPageContext } from './CompanyPageProvider';
import CompanyPagePublishBtnPopoverContent from './CompanyPagePublishBtnPopoverContent';
import { ENGLISH_LANG, FRENCH_LANG } from '../../../../constants/constant';

const CompanyPagePublishButton = (
	{
		company,
		title,
		disabled,
		innerRef,
		...props
	},
) => {
	const className = 'CompanyPagePublishButton';

	const theme = useTheme();

	const {
		lang,
		langTexts,
		setOpenPublicationPopin,
	} = useContext(CompanyPageContext);

	let btn = (
		<Button
			type='primary'
			onClick={() => setOpenPublicationPopin(true)}
			disabled={disabled}
			ref={innerRef}
			{...props}
			style={{
				borderRadius: 5,
				minWidth: 200,
				...props.style,
			}}
		>
			<b>{title || <>Publish{langTexts.labelIn}</>}</b>
		&nbsp;
			<FaCheck
				style={{
					fontSize: 12,
					marginBottom: -1,
				}}
			/>
		</Button>
	);

	if (disabled) {
		btn = (
			<Popover
				content={<CompanyPagePublishBtnPopoverContent company={company} />}
				placement="bottomRight"
			>
				{btn}
			</Popover>
		);
	}

	return btn;
};

export default CompanyPagePublishButton;
