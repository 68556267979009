import { useTheme } from '@emotion/react';
import { message, Typography } from 'antd';
import { showLogin } from '../../actions/app';
import { useAuthContext } from '../../hooks/useAuthContext';
import ResetPasswordForm from './ResetPasswordForm';

const ResetPassword = () => {
	const theme = useTheme();
	const { resetPassword } = useAuthContext();

	const sendResetPassword = ({ email }) => {
		resetPassword(email);
		message.success('We sent you an email with a link to reset your password. Look in your inbox and your spam folder if necessary');
		showLogin();
	};

	return (
		<div className="flex1 flexCenter">
			<div className="flexColumn" style={{ marginBottom: 24 }}>
				<Typography.Title level={1} style={{ color: theme.color.blue }}>Forgotten password?</Typography.Title>
			</div>
			<ResetPasswordForm onSubmit={sendResetPassword} />
		</div>
	);
};

export default ResetPassword;
