import { useContext } from 'react';
import Drawer from '../components/app/drawer/Drawer';
import useBodyScrollBlocker from '../hooks/useBodyScrollBlocker';
import { DrawerContext } from './DrawerProvider';

const DrawerContainer = () => {
	const className = 'DrawerContainer';

	const {
		open,
		setOpen,
		content,
		title,
		width,
		style,
		onClose,
	} = useContext(DrawerContext);

	useBodyScrollBlocker(open, className);

	const handleClose = async () => {
		setOpen(false);

		await onClose?.callback?.();
	};

	return (
		<Drawer
			title={title}
			open={open}
			onClose={handleClose}
			content={content}
			width={width}
			style={style}
		/>
	);
};

export default DrawerContainer;
