import { useTheme } from '@emotion/react';
import { FaInfoCircle } from 'react-icons/fa';
import { getLevelDetails, getLevelLabel } from '../../../../constants/genderscore-levels';
import { Popover } from 'antd';
import { useMemo } from 'react';
import GenderScoreLevelDetailsLink from '../results/GenderScoreLevelDetailsLink';

const GenderScoreLevelLabel = ({
	company,
	level,
	withUnderline = true,
	withPopover = true,
	withInfo,
	withColor,
	withPill = true,
	...props
}) => {
	const theme = useTheme();

	const memoizedContent = useMemo(() => {
		let content = (
			<span
				style={{
					...props?.style,
					// userSelect: 'none',
				}}
			>
				<span style={{
					...withColor
						? { color: theme.color.fitBlueElectric }
						: {},
					textTransform: 'uppercase',
					fontWeight: 'bold',
					...withUnderline
						? { textDecoration: `underline dotted ${theme.color.fitBlueElectric}` }
						: {},
					...props?.style?.label,
					...withPill
						? {
							borderRadius: 100,
							background: theme.background.gradientPurpleGreen(),
							padding: '2px 10px',
							color: 'white',
							...withUnderline
								? { textDecorationColor: theme.color.white }
								: {},
						}
						: {},
				}}>
					{getLevelLabel(level)}
				</span>

				{withInfo && (
					<>
						&nbsp;
						<FaInfoCircle
							style={{
								marginBottom: -2,
								opacity: 0.5,
							}}
						/>
					</>
				)}

			</span>
		);

		if (withPopover) {
			const popoverContent = (
				<div
					style={{
						maxWidth: 400,
					}}
				>
					<p>{getLevelDetails(level)?.range}</p>
					<p>{getLevelDetails(level)?.meaning}</p>

					{company && <div style={{ textAlign: 'right' }}>

						<GenderScoreLevelDetailsLink
							company={company}
						/>
					</div>}
				</div>
			);

			content = (
				<Popover
					title={<>Level: {getLevelLabel(level)?.toUpperCase?.()}</>}
					content={popoverContent}>
					{content}
				</Popover>
			);
		}
		return content;
	}, [level, withPopover, withInfo, withColor, props]);

	return memoizedContent;
};

export default GenderScoreLevelLabel;
