import GridValueCard from '../../../app/card/GridValueCard';
import { NOT_PROVIDED_YET_LABEL } from '../../../../constants/constant';
import UserProfileEmailCardBody from './UserProfileEmailCardBody';

const UserProfileEmailCard = (
	{
		user,
		hideIfEmpty = true,
		notProvided = NOT_PROVIDED_YET_LABEL,
		children,
		...props
	},
) => {
	const title = 'Email';
	const details = '';

	return (user?.email || !hideIfEmpty
		? <GridValueCard
			title={title}
			details={details}
			{...props}
		>
			{children || <UserProfileEmailCardBody user={user} notProvided={notProvided}/>}
		</GridValueCard>
		: null
	);
};

export default UserProfileEmailCard;
