import { useTheme } from '@emotion/react';
import { useState } from 'react';
import {
	Button, Divider, Form, Menu, Space, Spin,
} from 'antd';
import {
	FaCheck, FaMinusCircle, FaRegMinusSquare, FaSave, FaTrashAlt,
} from 'react-icons/fa';
import { RiDeleteBinFill } from 'react-icons/ri';
import AddButton from '../../app/button/AddButton';
import UserStub from '../../user/stub/UserStub';
import JobEditManagersDropDown from './JobEditManagersDropDown';
import { useAuthContext } from '../../../hooks/useAuthContext';
import { documentId, inArray, writeLog } from '../../../utils/common';
import { LocalDebug, localDebug } from '../../../utils/LocalDebug';
import Box from '../../app/box/Box';
import FormItem from '../../company/form/FormItem';
import FormSubmitButton from '../../app/form/FormSubmitButton';

const JobEditManagersForm = (
	{
		initialValues = {},
		onSubmit,
	},
) => {
	const className = 'JobEditManagersForm';

	const [form] = Form.useForm();
	const [options, setOptions] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	const [isBtnDisabled, setIsBtnDisabled] = useState(true);
	const { currentUser } = useAuthContext();

	const handleSubmit = async (values) => {
		const managerIds = (values?.managers || []).map((m) => documentId(m));
		LocalDebug.logInfo({ className, method: 'handleSubmit' }, { managerIds });
		writeLog('edit managers of a job ', { jobId: documentId(initialValues?.job), managers: managerIds });

		// const previousManagers = (initialValues.job?.managerIds || []).map(i => i?.toString());
		// if (inArray(documentId(currentUser), previousManagers)) {
		// 	managerIds = [documentId(currentUser), ...managerIds];
		// }

		const toDb = {
			id: documentId(initialValues?.job),
			managerIds,
		};
		if (onSubmit) await onSubmit(toDb);
	};

	// const handleAddManager = (e) => {
	// 	const userId = e.key;
	// 	writeLog('select a manager for job', { job: documentId(job), managerId: userId });
	// 	const newManager = options.find((o) => documentId(o) === userId);
	// 	// if (onAddManager) onAddManager(newManager);
	// 	writeLog('select a manager for a job ', { job: documentId(job), manager: documentId(newManager) });
	// 	setOptions((prev) => prev.filter(o => documentId(o) !== userId));
	// }

	const onSelectManager = (manager) => {
		writeLog('select a manager for a job ', { job: documentId(initialValues?.job), manager: documentId(manager) });
	};

	const onRemoveManager = (manager) => {
		writeLog('delete a manager for a job ', { job: documentId(initialValues?.job), manager: documentId(manager) });
	};

	return (
		<div>
			<Form
				form={form}
				name="action_form"
				initialValues={{ ...initialValues }}
				onFinish={handleSubmit}
				onValuesChange={(values) => {
					setIsBtnDisabled(false);
				}}
			>
				<Form.List name="managers">
					{(fields, { add, remove }) => {
						return (
							<Space direction='vertical' style={{ width: '100%' }}>
								{/* <h3>Recipients</h3> */}
								{/* <div style={{ display: 'flex', justifyContent: 'flex-end' }}> */}
								{/*	<JobEditManagersDropDown */}
								{/*		job={initialValues?.job} */}
								{/*		onAddManager={(manager) => { */}
								{/*			add(manager); */}
								{/*			onSelectManager(manager) */}
								{/*		}} */}
								{/*		managerIds={(form.getFieldValue('managers') || []).map((m) => documentId(m))} */}
								{/*	/> */}
								{/* </div> */}
								{/* <Divider style={{ marginTop: 5, marginBottom: 5 }}/> */}
								{fields.map((field, index) => {
									const manager = form.getFieldValue('managers')[index];
									return (
										<Box style={{ padding: 14 }} key={index}>

											<Space style={{ display: 'flex', justifyContent: 'space-between' }}>
												<UserStub
													user={manager}
													style={{ textAlign: 'left' }}
													withLocation={false}
													withCompanyLogo={false}
													withIsUnviewed={false}
												/>
												<Button
													danger
													type="link"
													onClick={() => {
														remove(field.name);
														onRemoveManager(manager);
													}}
													icon={<FaRegMinusSquare style={{ marginBottom: -2 }}/>}
												>
													{/* <FaTrashAlt style={{ marginBottom: -2 }}/> */}
												</Button>
											</Space>
										</Box>
									);
								})}

								<JobEditManagersDropDown
									job={initialValues?.job}
									onAddManager={(manager) => {
										add(manager);
										onSelectManager(manager);
									}}
									managerIds={(form.getFieldValue('managers') || []).map((m) => documentId(m))}
								/>
							</Space>
						);
					}}
				</Form.List>

				<FormItem label='' style={{ marginTop: 20 }}>
					<FormSubmitButton size={'large'} style={{
						width: '100%', textAlign: 'center', borderRadius: 6, fontWeight: 'bold',
					}}>
						Save
					</FormSubmitButton>
				</FormItem>
				{/* <Divider style={{ marginTop: 15, marginBottom: 15 }}/> */}
				{/* <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 20 }}> */}
				{/*	<Button */}
				{/*		type='primary' */}
				{/*		style={{ marginTop: 1 }} */}
				{/*		onClick={() => {}} */}
				{/*		title="Save" */}
				{/*		// color={theme.color.blue} */}
				{/*		icon={<FaCheck style={{ marginBottom: -2 }}/>} */}
				{/*		htmlType="submit" */}
				{/*		disabled={isBtnDisabled} */}
				{/*	> */}
				{/*		&nbsp;Save */}
				{/*	</Button> */}
				{/* </div> */}

				{/* <Space> */}
				{/*	{isLoading */}
				{/*		? <Spin size="large"/> */}
				{/*		: options.map((u) => */}
				{/*			<Menu.Item key={documentId(u)}> */}
				{/*				<UserStub user={u} withLocation={false}/> */}
				{/*			</Menu.Item> */}
				{/*		) */}
				{/*	} */}
				{/* </Space> */}
			</Form>
		</div>
	);
};

export default JobEditManagersForm;
