import { useSelector } from 'react-redux';
import { useTheme } from '@emotion/react';
import { getCompanySelected } from '../../../reducers/app';
import CompanyGridPicker from '../../company/CompanyGridPicker';
import { useAuthContext } from '../../../hooks/useAuthContext';
import ReactJsonDebug from '../../app/debug/ReactJsonDebug';
import { useEffect, useMemo, useState } from 'react';
import Box from '../../app/box/Box';
import UMSService from '../../../services/ums';
import UMSUserStub from '../users/UMSUserStub';
import {
	Card, Col, Divider, Progress,
	Row, Space, Statistic, Table,
} from 'antd';
import { documentId, sortOn } from '../../../utils/common';
import { LocalDebug } from '../../../utils/LocalDebug';
import {
	BOOLEAN_NO_ICON, BOOLEAN_NO_LABEL, BOOLEAN_YES_ICON, BOOLEAN_YES_LABEL,
} from '../../../constants/constant';
import UMSAdminResultsStatusBanner from './UMSAdminResultsStatusBanner';

const UMSAdminResults = () => {
	const className = 'UMSAdminResults';

	const theme = useTheme();

	const companySelected = useSelector(getCompanySelected);
	const { isUMSAdmin, isUMSManager, isUMSEmployee } = useAuthContext();
	const [isLoading, setIsLoading] = useState(true);
	const [statusData, setStatusData] = useState(null);

	const loadData = async () => {
		setIsLoading(true);
		const { data } = await UMSService.getAdminStatus(
			{ params: { companyId: documentId(companySelected) } },
		);
		LocalDebug.logInfo({ className, method: 'loadData' }, { data });
		setStatusData(data?.results);
		setIsLoading(false);
	};

	useEffect(() => {
		LocalDebug.logUseEffect({ className, effects: 'documentId(companySelected)' }, { companySelected: documentId(companySelected) });
		if (documentId(companySelected)) {
			loadData();
		} else {
			setStatusData(null);
		}
	}, [documentId(companySelected)]);

	const memoizedContent = useMemo(() => {
		LocalDebug.logUseEffect({ className, effects: 'memo' }, { isLoading, statusData, companySelected: documentId(companySelected) });

		if (!companySelected) return <CompanyGridPicker withPageLayout={false} />;

		if (isLoading) return 'Loading...';

		if (!statusData) return null;

		return (
			<>
				<UMSAdminResultsStatusBanner
					isLoading={isLoading}
					statusData={statusData}
				/>

				<Divider style={{ marginTop: 40 }}>
					Global Statistics
				</Divider>

				<Row gutter={[20, 20]}>
					<Col span={8}>
						<Card bordered={true}>
							<Statistic
								title='Surveys'
								value={statusData?.surveysCount}
								// valueStyle={{ color: '#3f8600' }}
								// prefix={<ArrowUpOutlined />}
								// suffix="%"
							 />
							{statusData?.completeSurveysCount} complete
							<Progress
								// size={60}
								percent={Math.round(100 * statusData?.completeSurveysCount / statusData?.surveysCount)}
							/>
						</Card>
						{/* <Statistic
							title={statusData?.completeSurveysCount}
						/> */}
					</Col>
					<Col span={8}>
						<Card bordered={true}>
							<Statistic
								title='Managers'
								value={statusData?.managerSurveysCount}
								// valueStyle={{ color: '#3f8600' }}
								// prefix={<ArrowUpOutlined />}
								// suffix="%"
							 />
							{statusData?.managerResultReadyCount} ready
							<Progress
								// size={60}
								percent={Math.round(100 * statusData?.managerResultReadyCount / statusData?.managerSurveysCount)}
							/>
						</Card>
						{/* <Statistic
							title={statusData?.completeSurveysCount}
						/> */}
					</Col>
					<Col span={8}>
						<Card bordered={true}>
							<Statistic
								title='Employees'
								value={statusData?.employeeSurveysCount}
								// valueStyle={{ color: '#3f8600' }}
								// prefix={<ArrowUpOutlined />}
								// suffix="%"
							 />
							{statusData?.completeEmployeeSurveysCount} complete
							<Progress
								// size={60}
								percent={Math.round(100 * statusData?.completeEmployeeSurveysCount / statusData?.employeeSurveysCount)}
							/>
						</Card>
						{/* <Statistic
							title={statusData?.completeSurveysCount}
						/> */}
					</Col>
				</Row>

				<Divider style={{ marginTop: 40 }}>
					Status details by manager
				</Divider>

				<Table
					dataSource={statusData?.surveyCompletionByManager?.sort(sortOn({ key: (item) => item?.user?.slug }))}
					pagination={false}
					columns={[
						{
							dataIndex: 'user',
							title: 'Manager',
							render: (user) => <UMSUserStub user={user} />,
						},
						{
							dataIndex: 'employeesCount',
							title: 'Number of employees',
							render: (employeesCount) => (
								<span>
									{employeesCount} employee{employeesCount > 1 ? 's' : ''}
								</span>
							),
						},
						{
							dataIndex: 'isResultReady',
							title: 'Manager results',
							render: (isResultReady) => (
								isResultReady
									? (
										<div>{BOOLEAN_YES_ICON} Results ready</div>
									)
									: (
										<div>{BOOLEAN_NO_ICON} Results not ready yet</div>
									)
							),
						},
						{
							dataIndex: 'resultReadyPercent',
							title: 'Progress',
							render: (resultReadyPercent, row) => (
								<Progress
									size={[150, 5]}
									percent={resultReadyPercent}
									strokeColor={row.isResultReady
										? theme.color.darkturquoise
										: theme.color.accent
									}
								/>
							),
						},
					]}
				/>

				{false && (
					<>
						<Divider />

						<div
							style={{
								display: 'grid',
								gridTemplateColumns: 'repeat(1, 1fr)',
								columnGap: 20,
								rowGap: 20,
								margin: '20px 0',
							}}
						>
							{(statusData?.surveyCompletionByManager || [])
								?.map(({
									user,
									employeesCount,
									isResultReady,
									isManagerCompleteSurvey,
									resultReadyPercent: percent,
								}, index) => {
									return (
										<Box
											key={index}
											style={{
												padding: '10px 20px',
												background: '#ffffff',
												border: '1px solid #dddddd',
											}}
										>
											<Row style={{ width: '100%' }}>
												<Col span={6}>
													{/* Manager: <br /> */}
													<UMSUserStub user={user} />
												</Col>
												<Col span={4}>
													<div>Manager completed: {isManagerCompleteSurvey ? BOOLEAN_YES_LABEL : BOOLEAN_NO_LABEL}</div>
												</Col>
												<Col span={4}>
													<div>{employeesCount} employees</div>
												</Col>
												<Col span={10}>
													<div>
														<Progress
															// size={60}
															percent={percent}
															strokeColor={isResultReady
																? theme.color.darkturquoise
																: theme.color.accent
															}
														/>
													</div>
												</Col>
												<Col span={4}>
													<Space
														style={{
															justifyContent: 'center',
															width: '100%',
														}}
													>
														{isResultReady ? BOOLEAN_YES_LABEL : BOOLEAN_NO_LABEL}
													</Space>
												</Col>
											</Row>
										</Box>
									);
								})}
						</div>
					</>
				)}

			</>
		);
	}, [isLoading, statusData, companySelected]);

	return (
		<div>
			<h1
				style={{
					marginBottom: 20,
				}}
			>
				Your Results
			</h1>

			{memoizedContent}

			{false && (
				<>
					<div
						style={{
							display: 'grid',
							gridTemplateColumns: 'repeat(4, 1fr)',
							columnGap: 20,
							rowGap: 20,
							margin: '20px 0',
						}}
					>
						{(statusData?.surveyCompletionByManager || [])
							?.map(({ user, employeesCount, percent }, index) => {
								return (
									<Box
										key={index}
										style={{
											padding: 20,
											background: '#ffffff',
											border: '1px solid #dddddd',
										}}
									>
										<Space
											direction='vertical'
											style={{
												justifyContent: 'center',
												alignItems: 'center',
												textAlign: 'center',
												width: '100%',
											}}
										>
											<div>Manager: <br/><UMSUserStub user={user} /></div>
											<div>{employeesCount} employees</div>
											<div>
												<Progress
													type='circle'
													size={60}
													percent={percent}
												/>
											</div>
										</Space>
									</Box>
								);
							})}
					</div>
				</>
			)}

			<ReactJsonDebug
				src={statusData || {}}
			/>
			<ReactJsonDebug
				src={companySelected || {}}
			/>

		</div>
	);
};

export default UMSAdminResults;
