import { Button, Tooltip, message } from 'antd';
import { FaLink, FaRegCopy } from 'react-icons/fa';
import UserModel from '../../../models/UserModel';
import { useAuthContext } from '../../../hooks/useAuthContext';
import { useLocation } from 'react-router-dom';
import { useTheme } from '@emotion/react';
import config from '../../../config/config';

const UserCopyPathIconButton = (
	{
		user,
		size = 48,
		tooltip = 'Copy direct link to profile',
		tooltipPlacement='top',
		withHideOnSamePage = false,
		...props
	},
) => {
	const className = 'UserCopyPathIconButton';

	const { currentUser } = useAuthContext();
	const location = useLocation();

	if (!user) {
		return null;
	}

	const fullpath = new UserModel(user).getProfileUrl(currentUser);

	if (withHideOnSamePage && fullpath === `${config.adminRoot}${location?.pathname}`) {
		return null;
	}

	return (
		<Tooltip
			title={(
				<>
					{tooltip}
					<br />
					<code
						style={{
							fontSize: 10, color: 'white', background: '#333', wordBreak: 'break-all',
						}}
					>
						{fullpath}
					</code>
				</>
			)}
			placement={tooltipPlacement}
		>
			<Button
				className='icon-circle-button'
				onClick={() => {
					navigator.clipboard.writeText(fullpath);
					message.success(<>
						The direct link to this user's profile:
						<br/><code style={{ fontSize: 12, color: 'black' }}>{fullpath}</code>
						<br/>has been copied to your clipboard
					</>);
				}}
				type='default'
				style={{
					fontSize: 16,
					width: size,
					height: size,
					borderRadius: size,
					padding: 0,
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
					...props?.style,
				}}
			>
				<FaLink
					style={{
						...props?.style?.icon,
					}}
				/>
			</Button>
		</Tooltip>
	);
};

export default UserCopyPathIconButton;
