import {
	SHORTLISTS_TITLE,
	TAG_STAFF_ICON,
} from '../../../constants/constant';
import { COMPANY_RECRUITER_LOG } from '../../../constants/company-recruiter-log';
import {
	Affix, Descriptions, Space, Tabs,
} from 'antd';
import { useContext, useEffect, useState } from 'react';
import PageLayout from '../../app/layout/PageLayout';
import TabBadge from '../../app/TabBadge';
import Tag from '../../staff/tags/Tag';
import UserApplications from './UserApplications';
import UserArchived from './UserArchived';
import UserHeader from '../widgets/UserHeader';
import UserInfoTab from './UserInfoTab';
import UserJobActions from './UserJobActions';
import UserMessagesTo from './UserMessagesTo';
import UserModel from '../../../models/UserModel';
import UserShortlists from './UserShortlists';
import UserSourced from './UserSourced';
import UserSpotlight from './UserSpotlight';
import { LocalDebug, localDebug } from '../../../utils/LocalDebug';
import { useAuthContext } from '../../../hooks/useAuthContext';
import { useTheme } from '@emotion/react';
import { writeRecruiterLog } from '../../../actions/logging';
import UserProfilePropertyTable from '../UserProfilePropertyTable';
import { UserStoreContext } from '../../../contexts/UserStoreProvider';
import { StaffToolsContext } from '../../../contexts/StaffToolsProvider';
import { documentId, scrollToTop } from '../../../utils/common';
import UserProfilePageCard from './UserProfilePageCard';
import { AppDataContext } from '../../../contexts/AppDataProvider';
import GenderhireCandidatesSourcedMenu from '../../../menu/genderhire/GenderhireCandidatesSourcedMenu';
import GenderhireCandidatesAppliedMenu from '../../../menu/genderhire/GenderhireCandidatesAppliedMenu';
import GenderhireCandidatesArchivedMenu from '../../../menu/genderhire/GenderhireCandidatesArchivedMenu';
import GenderhireMessagesMenu from '../../../menu/genderhire/GenderhireMessagesMenu';

const UserProfile = ({
	user,
	scrollTarget = null,
	affixTop = 0,
	withinDrawer = false,
	withHeader = false,
	activeTab = null,
	isRefresh = 0,
	forceLegacyView = false,
}) => {
	const className = 'UserProfile';

	const theme = useTheme();

	const {
		isAdmin,
		isStaffView,
		isCompanyRecruiter,
		isDocumentAdmin,
		currentUser,
	} = useAuthContext();

	const { isAppBannerVisible, appBannerHeight } = useContext(AppDataContext);
	const { fetchUser: dataFetchUser } = useContext(UserStoreContext);
	const { userPageToggleCardView } = useContext(StaffToolsContext);

	const [isLoading, setIsLoading] = useState(true);
	const [userModel, setUserModel] = useState(null);
	const [isRefreshData, setIsRefreshData] = useState(0);

	const handleRefresh = () => {
		localDebug('UserProfile.handleRefresh');
		setIsRefreshData((prev) => prev + 1);
	};

	const fetchUser = async (userId) => {
		LocalDebug.logNull({ className, method: 'fetchUser' }, { userId });
		setUserModel(await dataFetchUser(userId));
		LocalDebug.logNull({ className, method: 'fetchUser' }, 'user fetched');
		setIsLoading(false);
	};
	const onUserChanged = async () => {
		setUserModel(await dataFetchUser(documentId(user)));
	};
	useEffect(() => {
		LocalDebug.logNull({ className, effects: 'user' }, { user: user?.slug });

		writeRecruiterLog(COMPANY_RECRUITER_LOG.TALENT_VIEW, {
			userTargetId: documentId(user),
		});

		if (withinDrawer) {
			setUserModel(new UserModel(user));
			setIsLoading(false);
			return;
		}
		if (!user) {
			setUserModel(null);
			setIsLoading(false);
			return;
		}
		setIsLoading(true);
		fetchUser(documentId(user, user?.slug));
	}, [user]);

	useEffect(() => {
		if (!(isRefresh + isRefreshData)) return;
		setIsLoading(true);
		fetchUser(documentId(user, user?.slug));
	}, [isRefresh, isRefreshData]);

	useEffect(() => {
		scrollToTop();
	}, []);

	return (
		userModel
		&& !isLoading
		&& (
			<>
				{userPageToggleCardView && !forceLegacyView
					? (
						<UserProfilePageCard
							user={userModel}
							onRefresh={handleRefresh}
							onUserChanged={onUserChanged}
						/>
					)
					: (
						<PageLayout
							withPadding={false}
						>
							{withHeader && (
								<Affix
									{...(scrollTarget
										? {
											target: () => (withinDrawer
												? scrollTarget?.current?.parentNode
												: scrollTarget),
										}
										: {})}
									offsetTop={affixTop + (isAppBannerVisible ? appBannerHeight : 0)}
								>
									<UserHeader
										user={userModel}
										isAffixedHead={true}
										onRefresh={handleRefresh}
									/>
								</Affix>
							)}

							<div style={{ padding: 24 }}>
								<Tabs defaultActiveKey={activeTab} style={{ marginTop: 5 }}>
									<Tabs.TabPane tab='Profile' key='info'>
										{
											userModel.isCoachingVisible({ isStaffView })
											&& <UserSpotlight user={userModel} />
										}
										<UserInfoTab user={userModel} onRefresh={handleRefresh} />
									</Tabs.TabPane>

									{(isCompanyRecruiter || isDocumentAdmin) && (
										<>
											<Tabs.TabPane
												tab={
													<TabBadge count={userModel.shortlistsCount}>
														{SHORTLISTS_TITLE}
													</TabBadge>
												}
												key='shortlists'
											>
												<UserShortlists user={userModel} mode='shortlists' />
											</Tabs.TabPane>

											<Tabs.TabPane
												tab={
													<TabBadge count={userModel.messagesToCount}>
														{new GenderhireMessagesMenu().label}
													</TabBadge>
												}
												key='messagesTo'
											>
												<UserMessagesTo user={userModel} />
											</Tabs.TabPane>

											<Tabs.TabPane
												tab={
													<TabBadge count={userModel.sourcedCount}>
														{new GenderhireCandidatesSourcedMenu().label}
													</TabBadge>
												}
												key='sourced'
											>
												<UserSourced user={userModel} mode='sourcings' />
											</Tabs.TabPane>

											<Tabs.TabPane
												tab={
													<TabBadge count={userModel.applicationsCount}>
														{new GenderhireCandidatesAppliedMenu().label}
													</TabBadge>
												}
												key='applications'
											>
												<UserApplications user={userModel} mode='applications' />
											</Tabs.TabPane>

											<Tabs.TabPane
												tab={
													<TabBadge count={userModel.archivedCount}>
														{new GenderhireCandidatesArchivedMenu().label}
													</TabBadge>
												}
												key='archived'
											>
												<UserArchived user={userModel} mode='archived' />
											</Tabs.TabPane>
										</>
									)}

									{isStaffView && (
										<Tabs.TabPane
											tab={
												<TabBadge count={userModel.jobActionsCount}>
													Job actions {TAG_STAFF_ICON}
												</TabBadge>
											}
											key='actions'
										>
											<UserJobActions user={userModel} />
										</Tabs.TabPane>
									)}

									{isStaffView && (
										<Tabs.TabPane tab={<>Job search {TAG_STAFF_ICON}</>} key='jobsearch'>
											<Descriptions title='Job search' layout='horizontal' bordered>
												<Descriptions.Item label='DE&I Criteria' span={3}>
													<ul>
														{userModel.jobSearch?.deiCriteria?.map(
															(item, index) => (
																<li key={index}>{item}</li>
															),
														)}
													</ul>
												</Descriptions.Item>
												<Descriptions.Item label='Level' span={3}>
													{userModel.jobSearch?.jobLevel}
												</Descriptions.Item>
												<Descriptions.Item label='Employment Visa' span={3}>
													{userModel.jobSearch?.employmentVisa}
												</Descriptions.Item>
												<Descriptions.Item label='Roles' span={3}>
													<ul>
														{userModel.jobSearch?.jobRole?.map((item, index) => (
															<li key={index}>{item}</li>
														))}
													</ul>
												</Descriptions.Item>
												<Descriptions.Item label='Role groups' span={3}>
													<ul>
														{userModel.jobSearch?.jobRoleGroup?.map((item, index) => (
															<li key={index}>{item}</li>
														))}
													</ul>
												</Descriptions.Item>
												<Descriptions.Item label='Locations' span={3}>
													<ul>
														{userModel.jobSearch?.jobLocation?.map((item, index) => (
															<li key={index}>{item}</li>
														))}
													</ul>
												</Descriptions.Item>
												<Descriptions.Item label='Remote' span={3}>
													{userModel.jobSearch?.jobRemote}
												</Descriptions.Item>
												<Descriptions.Item label='Email cadence' span={3}>
													{userModel.jobSearch?.emailCadence}
												</Descriptions.Item>
												<Descriptions.Item label='Community consent' span={3}>
													{userModel.confidential?.communityConsent && 'TRUE'}
												</Descriptions.Item>
												<Descriptions.Item label='Hunting consent' span={3}>
													{userModel.jobSearch?.huntingConsent && 'TRUE'}
												</Descriptions.Item>
											</Descriptions>
										</Tabs.TabPane>
									)}

									{isStaffView && (
										<Tabs.TabPane tab={<>Tags {TAG_STAFF_ICON}</>} key='tags'>
											<Descriptions title='Tags' layout='horizontal' bordered>
												{Object.entries(userModel.tagTree || {}).map(
													([category, slugs], index) => (
														<Descriptions.Item key={index} label={category} span={3}>
															{slugs.map((item, i) => (
																<Tag key={i} slug={item} />
															))}
														</Descriptions.Item>
													),
												)}
											</Descriptions>
										</Tabs.TabPane>
									)}

									{isStaffView && (isAdmin || currentUser.slug === user.slug) && (
										<Tabs.TabPane tab={<>Edit profile {TAG_STAFF_ICON}</>} key='edit'>
											<Space direction='vertical' size={4} style={{ width: '100%' }}>

												<h2>Reset user properties</h2>

												<UserProfilePropertyTable user={user} />

											</Space>

										</Tabs.TabPane>
									)}
								</Tabs>
							</div>
						</PageLayout>
					)
				}
			</>
		)
	);
};

export default UserProfile;
