import {
	Button, Popover, Space,
	Tag,
} from 'antd';
import {
	DatabaseFilled, DatabaseOutlined, DatabaseTwoTone, DeleteOutlined, MessageOutlined,
} from '@ant-design/icons';
import { useTheme } from '@emotion/react';
import ReactJson from 'react-json-view';
import { documentId } from '../../utils/common';
import ReactJsonDebug from '../app/debug/ReactJsonDebug';

const ChatbotTools = ({
	chatbot,
	messages,
	answerPending,
	onResetChatbot,
}) => {
	const className = 'ChatbotTools';
	const theme = useTheme();

	return (
		<>
			<Space
				size={0}
				wrap={true}
				style={{
					marginTop: 8,
					width: '100%',
				}}
			>
				<Popover
					title='JSON'
					content={
						<div style={{ maxWidth: 400, maxHeight: '50vh', overflowY: 'scroll' }}>
							<ReactJsonDebug src={chatbot || {}} />
						</div>
					}
				>
					<Tag>
						<DatabaseTwoTone /> Chatbot #{documentId(chatbot)?.slice?.(-4)}
					</Tag>
				</Popover>

				<Popover
					title='JSON'
					content={
						<div style={{ maxWidth: 400, maxHeight: '50vh', overflowY: 'scroll' }}>
							<ReactJsonDebug src={messages || []} />
						</div>
					}
				>
					<Tag>
						<MessageOutlined/> Session messages ({messages?.length})
					</Tag>
				</Popover>

				<Button
					disabled={answerPending}
					type='primary'
					size='small'
					ghost
					danger
					onClick={onResetChatbot}
				>
					Reset chatbot <DeleteOutlined />
				</Button>

			</Space>
		</>
	);
};

export default ChatbotTools;
