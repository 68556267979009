import { Space } from 'antd';
import { useTheme } from '@emotion/react';
import {
	PDF_PAGE_RATIO,
	PDF_PAGE_WIDTH,
} from './pdf/base/PDFPage';
import PDFManagerCover from './pdf/pages/PDFManagerCover';
import { buildBiasesPages } from './pdf/pages/PDFBiases';
import PDFResultsExplainer from './pdf/pages/PDFResultsExplainer';
import { buildImpactsPages } from './pdf/pages/PDFImpacts';
import PDFResults from './pdf/pages/PDFResults';
import PageReachOut from './pdf/pages/PageReachOut';
import PageBibliography from './pdf/pages/PageBibliography';
import PageKeyConsiderations from './pdf/pages/PageKeyConsiderations';
import PageCoverManager from './pdf/pages/PageCoverManager';
import PageBiasIntro from './pdf/pages/PageBiasIntro';
import PageBiasDetails from './pdf/pages/PageBiasDetails';
import PageResultsIntro from './pdf/pages/PageResultsIntro';
import PageRiskDetails from './pdf/pages/PageRiskDetails';
import PageRiskProfile from './pdf/pages/PageRiskProfile';
import { mapSeries } from 'async';
import { LocalDebug } from '../../../utils/LocalDebug';
import {
	PERSONA_EMPLOYEES_ALL, PERSONA_EMPLOYEES_GENDER, PERSONA_EMPLOYEES_MANAGER, PERSONA_MANAGER,
} from '../data/biases';
import PageResultsPersona from './pdf/pages/PageResultsPersona';
import PageYourProfile from './pdf/pages/PageYourProfile';
import PageResultsEmployeesGender from './pdf/pages/PageResultsEmployeesGender';
import PageRiskFocusList from './pdf/pages/PageRiskFocusList';

const PageResultsEmployee = (...args) => PageResultsPersona({
	persona: PERSONA_EMPLOYEES_ALL,
	title: 'Employee perception',
	subtitle: 'How your employees perceive bias in your management',
	footer: true,
	pageCount: { color: '#000000' },
})(...args);

const PageResultsEmployeeManager = (...args) => PageResultsPersona({
	persona: PERSONA_EMPLOYEES_MANAGER,
	title: 'Employees vs Manager',
	subtitle: 'How your employees perceive bias in your management compared to your own assessement',
	footer: true,
	pageCount: { color: '#000000' },
})(...args);

const PageResultsManager = (...args) => PageResultsPersona({
	persona: PERSONA_MANAGER,
	title: 'Your own assessment',
	subtitle: 'How you exhibit bias in your management style',
	footer: true,
	pageCount: { color: '#000000' },
})(...args);

const PageResultsGender = (...args) => PageResultsPersona({
	persona: PERSONA_EMPLOYEES_GENDER,
	title: 'Perception by gender',
	subtitle: 'Comparison of the bias perception between your female and male employees',
	footer: true,
	pageCount: { color: '#000000' },
})(...args);

export const getPDFManagerPageList = () => {
	const className = 'getPDFManagerPageList';

	return [
		PageCoverManager,
		PageBiasIntro,
		PageBiasDetails,
		PageResultsIntro,
		PageYourProfile,
		PageResultsEmployeeManager,
		// PageResultsManager,
		// PageResultsEmployee,
		// PageResultsGender,
		// PageResultsEmployees,
		// PageResultsEmployeesGender,
		PageRiskDetails,
		PageRiskProfile,
		PageRiskFocusList,
		PageKeyConsiderations,
		PageBibliography,
		PageReachOut,
		// PageResultsPersona({ persona: PERSONA_EMPLOYEES_ALL, version: 'legacy' }),
		// PageResultsPersona({ persona: PERSONA_MANAGER, version: 'legacy' }),
	];
};

export const getPDFManagerPages = async ({
	doc,
	company,
	manager,
	results,
	date,
	context,
}) => {
	const className = 'getPDFManagerPages';

	LocalDebug.logInfo({ className });

	const pages = getPDFManagerPageList()
		.map((page, index) => ({
			page,
			skip: !context.pageList[index],
		}));

	LocalDebug.logInfo({ className }, { pages: pages?.length });

	const pageCount = 1;
	return (await mapSeries(
		pages,
		async ({ page, skip }) => {
			LocalDebug.logInfo({ className }, 'build', page?.name);
			return (await page({
				doc,
				company,
				manager,
				results,
				date,
				context,
				skip,
			})).map((p) => ({ ...p, skip }));
		},
	)).flat();
	// return [
	// 	PageCoverManager({
	// 		doc, company, manager, date,
	// 	}),
	// 	...PageRiskProfile({ results }),
	// 	...PageBiasIntro(),
	// 	...PageBiasDetails({ doc }),
	// 	...PageResultsIntro(),
	// 	{
	// 		template: PDF_PAGE_TEMPLATE_CONTENT,
	// 		title: 'Result',
	// 		items: [],
	// 	},
	// 	...PageRiskDetails({ doc }),
	// 	{
	// 		template: PDF_PAGE_TEMPLATE_CONTENT,
	// 		title: 'Your risk profile',
	// 		items: [],
	// 	},
	// 	...PageRiskProfile({ results }),
	// 	...PageBibliography(),
	// 	...PageReachOut(),
	// ];
};

const PDFManager = ({
	width = PDF_PAGE_WIDTH,
}) => {
	const theme = useTheme();

	const r = (v) => PDF_PAGE_RATIO(v, width);

	let count = 1;

	return (
		<div
			className='pdf pdf-manager'
			style={{
				width: '100%',
				padding: 10,
				background: theme.color.bgGrey,
			}}
		>
			<Space
				wrap={true}
				style={{
					width: '100%',
				}}
			>

				<PDFManagerCover />

				{buildBiasesPages({ count: ++count, r }).map((page, i) => (
					<div key={`bias-${i}`}>
						{page}
					</div>
				))}

				<PDFResultsExplainer count={count += buildBiasesPages({ count, r }).length} />

				{buildImpactsPages({ count: ++count, r }).map((page, i) => (
					<div key={`impact-${i}`}>
						{page}
					</div>
				))}

				<PDFResults count={++count} />
			</Space>
		</div>
	);
};

export default PDFManager;
