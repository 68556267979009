const HiddenContent = ({
	hide = true,
	children,
	...props
}) => {
	return (
		<div
			style={{
				...props?.style,
				...hide
					? {
						overflow: 'hidden',
						width: 0,
						height: 0,
						opacity: 0,
					}
					: {},
			}}
		>
			{children}
		</div>
	);
};

export default HiddenContent;
