import { hexToRgb } from '../../../../utils/common';
import { useTheme } from '@emotion/react';
import { FaPen } from 'react-icons/fa';

const CompanyFieldDisplayOverlayIcon = (
	{
		size = 28,
		Icon = FaPen,
	},
) => {
	const className = 'CompanyFieldDisplayOverlayIcon';

	const theme = useTheme();

	return (
		<div style={{
			width: size,
			height: size,
			borderRadius: size,
			fontSize: size / 2,
			fontWeight: 'bold',
			color: theme.color.fitBlueElectric,
			background: theme.color.white,
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
			boxShadow: `0 0 30px 4px rgba(${hexToRgb(theme.color.fitBlueElectric).join(',')},.3)`,
		}}
		>
			<Icon/>
		</div>
	);
};

export default CompanyFieldDisplayOverlayIcon;
