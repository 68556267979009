import { useState } from 'react';
import { ENGLISH_LANG } from '../../../../constants/constant';
import CKEditor from '../../../app/form/CkEditor';
import CompanyFieldEditor from './CompanyFieldEditor';

const CompanyRichTextEditor = (
	{
		company,
		lang = ENGLISH_LANG,
		title,
		details, 
		field,
		setEditorOpen,
	},
) => {
	const className = 'CompanyRichTextEditor';

	const [editedValue, setEditedValue] = useState(company.getTranslationField(field, lang));

	return (
		<CompanyFieldEditor
			company={company}
			lang={lang}
			setEditorOpen={setEditorOpen}
			title={title}
			details={details}
			field={field}
			editedValue={editedValue}
		>
			<CKEditor
				value={editedValue}
				onChange={setEditedValue}
			/>

		</CompanyFieldEditor>
	);
};

export default CompanyRichTextEditor;
