/** @jsxImportSource @emotion/react */

import { Checkbox, DatePicker, TimePicker } from 'antd';
import { useEffect, useState } from 'react';
import FormItem from '../../../../app/form/FormItem';
import { useTheme } from '@emotion/react';
import dayjs from 'dayjs';

function FormItemEvent({
	onChange,
	initialValue,
}) {
	const [addDate, setAddDate] = useState(false);
	const [addTime, setAddTime] = useState(false);
	const [date, setDate] = useState(null);
	const onChangeDate = (e) => {
		onChange?.('eventAtDate', new Date(e).toISOString());
		setDate(e);
	};
	const onChangeTime = (e) => {
		const hour = new Date(e).getHours();
		const minute = new Date(e).getMinutes();
		const second = new Date(e).getSeconds();
		const newDate = new Date(date);
		newDate.setHours(hour);
		newDate.setMinutes(minute);
		newDate.setSeconds(second);
		onChange?.('eventAtHour', hour);
		onChange?.('eventAtMinute', minute);
		onChange?.('eventAtDate', new Date(newDate).toISOString());
	};
	useEffect(() => {
		if (initialValue.eventAtDate) {
			setAddDate(true);
			setDate(dayjs(initialValue.eventAtDate));
		}
		if (initialValue.eventAtHour) {
			setAddTime(true);
		}
	}, [initialValue]);

	const theme = useTheme();
	return (
		<div
			css={{
				borderRadius: 4,
				padding: '8px 12px',
				marginBottom: 20,
				display: 'grid',
				gridTemplateColumns: '1fr 1fr',
				gap: 20,
			}}
		>
			<div
				css={{
					'.ant-picker': {
						width: '100%!important',
					},
				}}
			>
				<Checkbox
					css={{
						fontSize: 16,
						lineHeight: 1.3,
						color: theme.color.blue,
						fontWeight: 500,
						padding: '10px 0',
					}}
					value={addDate}
					onChange={(e) => setAddDate(e.target.checked)}
				>
					Add Date
				</Checkbox>
				{addDate && (
					<FormItem defaultContainer="div">
						<DatePicker defaultValue={date} onChange={(e) => onChangeDate(e)}/>
					</FormItem>
				)}
			</div>
			{addDate && (
				<div
					css={{
						'.ant-picker': {
							width: '100%!important',
						},
					}}
				>
					<Checkbox
						css={{
							fontSize: 16,
							lineHeight: 1.3,
							color: theme.color.blue,
							fontWeight: 500,
							padding: '10px 0',
						}}
						checked={addTime}
						onChange={(e) => setAddTime(e.target.checked)}
					>
						Ajouter une heure de début
					</Checkbox>
					{addTime && (
						<FormItem defaultContainer="div">
							<TimePicker value={date} onChange={(e) => onChangeTime(e)}/>
						</FormItem>
					)}
				</div>
			)}
		</div>
	);
}

export default FormItemEvent;
