import { documentId } from '../../../utils/common';
import FormSelect from './FormSelect';
import { Select, Space, Tag } from 'antd';

const FormAtsArchiveReasonSelect = (
	{
		showSearch = true,
		onChange,
		defaultValue,
		placeholder = 'Select an archive reason',
		styles = {},
		mode = 'single',
		...props
	},
) => {
	const handleChange = (value, option) => {
		if (onChange) onChange(value, option);
	};

	return (
		<FormSelect
			showSearch={showSearch}
			optionFilterProp="children"
			placeholder={placeholder || (mode === 'multiple'
				? 'Select one or more archive reasons'
				: 'Select an archive reason')
			}
			onChange={handleChange}
			defaultValue={defaultValue}
			styles={{ minWidth: 180, ...styles }}
			mode={mode}
			filterOption={(input, option) => option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
			}
			render={(option, key) => (
				<Select.Option
					value={documentId(option)}
					label={
						<Space direction='horizontal' size={0}>
							<Tag color='green' icon={option.icon}>&nbsp;{option.label}</Tag>
						</Space>
					}
					key={key}
				>
					<Space direction='horizontal' size={0}>
						<Tag color='green' icon={option.icon}>&nbsp;{option.label}</Tag>
					</Space>
				</Select.Option>
			)}
			{...props}
		/>
	);
};

export default FormAtsArchiveReasonSelect;
