import { Divider, Dropdown, Menu } from 'antd';
import { useEffect, useState } from 'react';
import { useTheme } from '@emotion/react';
import {
	FaBan,
	FaCheck,
	FaCheckDouble,
	FaEllipsisH,
	FaEyeSlash,
	FaFolderMinus,
	FaFolderPlus,
	FaPaperPlane,
	FaRegClock,
	FaUserAstronaut,
} from 'react-icons/fa';
import { LocalDebug } from '../../../../utils/LocalDebug';
import UserModel from '../../../../models/UserModel';

const FunnelUserCardMenu = (
	{
		funnel,
		user,
		menu,
		onContactUser,
		onEditCoach,
		onUserFunnel,
		onSetUserIsReachable,
	},
) => {
	const className = 'FunnelUserCardMenu';

	const theme = useTheme();

	const [menuItems, setMenuItems] = useState([]);
	const [isReachable, setIsReachable] = useState(new UserModel(user).getCareerIsReachableByRecruiters());

	useEffect(() => {
		setIsReachable(new UserModel(user).getCareerIsReachableByRecruiters());
		const menuActions = [
			{
				value: 'contact',
				label: 'Contact',
				icon: FaPaperPlane,
				onClick: (user) => async () => onContactUser(user),
			},
			{
				value: 'coach',
				label: 'Coach',
				icon: FaUserAstronaut,
				onClick: (user) => async () => onEditCoach(user),
			},
			{
				value: 'scheduled',
				label: 'Scheduled',
				icon: FaRegClock,
				onClick: (user) => async () => onUserFunnel(user, funnel, 'scheduled'),
			},
			{
				value: 'qualified',
				label: 'Qualified',
				icon: FaCheck,
				onClick: (user) => async () => onUserFunnel(user, funnel, 'qualified'),
			},
			{
				value: 'reachable',
				...isReachable
					? { label: 'Set as unreachable', icon: FaEyeSlash }
					: { label: 'Set as reachable', icon: FaCheckDouble },
				onClick: (user) => async () => onSetUserIsReachable(user, !isReachable),
			},
			{
				value: 'exclude',
				...user?.funnel?.exclusion
					? { label: 'Manage exclusion', icon: FaBan }
					: { label: 'Exclude', icon: FaBan },
				onClick: (user) => async () => onUserFunnel(user, 'funnel.exclusion', 'exclude'),
			},
			{
				value: 'archive',
				label: 'Archive',
				icon: FaFolderPlus,
				onClick: (user) => async () => onUserFunnel(user, funnel, 'archived'),
			},
			{
				value: 'unarchive',
				label: 'Unarchive',
				icon: FaFolderMinus,
				onClick: (user) => async () => onUserFunnel(user, funnel, null),
			},
		];
		setMenuItems(menu?.map((value) => menuActions.find((o) => o.value === value)).filter((i) => i) || []);
	}, [
		menu,
		user,
		isReachable,
		user?.career?.isReachableByRecruiters,
	]);

	if (!user || !menuItems?.length) {
		return null;
	}

	return (
		<Dropdown
			overlay={
				<Menu style={{ fontSize: 12 }}>
					{menuItems
						.map((item, i) => {
							const {
								icon: IconComponent, label, value, onClick,
							} = item;
							switch (item.value) {
								case 'reachable':
									return (
										<div key={i}>
											<Divider style={{ margin: '3px 0' }} />
											<Menu.Item
												onClick={onClick(user)}
												style={{ color: isReachable ? 'violet' : 'darkturquoise' }}
											>
												<IconComponent
													style={{ marginBottom: -2 }} /> {label}
											</Menu.Item>
										</div>
									);
								case 'exclude':
									return (
										<div key={i}>
											<Divider style={{ margin: '3px 0' }} />
											<Menu.Item
												onClick={onClick(user)}
												style={{ color: 'red' }}
											>
												<IconComponent
													style={{ marginBottom: -2 }} /> {label}
											</Menu.Item>
										</div>
									);
								default:
									return (
										<Menu.Item
											key={i}
											onClick={onClick(user)}
										>
											<IconComponent style={{ marginBottom: -2 }} /> {label}
										</Menu.Item>
									);
							}
						})}
				</Menu>
			}>
			<div
				style={{
					width: 18,
					height: 18,
					background: theme.color.fitBlueElectric,
					borderRadius: 3,
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
					// color: theme.color.fitBlueElectric,
					color: theme.color.white,
				}}
			>
				<FaEllipsisH style={{ fontSize: 12, marginBottom: -1 }} />
			</div>
		</Dropdown>
	);
};

export default FunnelUserCardMenu;
