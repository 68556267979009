import PipelineTag from './PipelineTag';

const SourcingATSPipelineTag = (
	{
		sourcing,
		onClick,
	},
) => {
	const className = 'SourcingATSPipelineTag';

	return <PipelineTag
		candidatePipeline={sourcing?.candidatePipeline}
		onClick={onClick}
	/>;
};

export default SourcingATSPipelineTag;
