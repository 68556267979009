import { Space } from 'antd';
import { getAclRoleDetails, getAclRoleTag } from '../../constants/constant';
import CompanyLogo from '../company/CompanyLogo';
import { useTheme } from '@emotion/react';
import moment from 'moment';

const AclRoleStub = (
	{
		role,
		companyLogoSize,
		withDetails = true,
		withDate = true,
		style = {},
	},
) => {
	const theme = useTheme();

	return (
		<div style={{
			display: 'flex',
			flexDirection: 'row',
			justifyContent: 'flex-start',
			alignItems: 'top',
			width: '100%',
			...style,
		}}>
			{role?.company && (
				<div
					style={{ paddingRight: 10 }}
				>
					<CompanyLogo
						company={role?.company}
						size={companyLogoSize}
					/>
				</div>
			)}

			<Space
				direction='vertical'
				size={4}
				style={{
					width: '100%',
				}}
			>
				{getAclRoleTag(role?.acl?.label)}
				{withDetails && (
					getAclRoleDetails(role?.acl?.label)
				)}
				{withDate && (
					<div
						style={{
							fontSize: 12,
							fontStyle: 'italic',
							color: theme.color.grey,
						}}
					>
						Granted on {moment(role?.createdAt).format('MMM Do, YYYY')}
					</div>
				)}
			</Space>
		</div>
	);
};

export default AclRoleStub;
