import moment from 'moment';
import { documentId, momentShortenFrom } from '../utils/common';
import {
	Col, Popover, Row, Space, Tag, Typography,
} from 'antd';
import {
	MAILJET_STATUS_CLICKED_ID,
	MAILJET_STATUS_OPENED_ID,
	MAILJET_STATUS_SENT_ID,
} from '../constants/mailjet/mailjetStatus';
import UserModel from './UserModel';
import { DATE_FORMAT } from '../constants/constant';
import { MAILING_STATUS_LIST } from '../constants/mailing';

export default class UserMailingModel {
	// The actual recipient of the mailing
	userId;

	user;

	// The actual recipient of the mailing
	recipientId;

	recipient;

	// The actual sender of the mailing
	senderId;

	sender;

	userTriggerId;

	userTrigger;

	recipientType; // 'to', 'cc', 'bcc'

	templateId;

	messageUuid;

	messageId;

	messageHref;

	lastSyncedAt;

	dataRefreshedAt;

	sentAt;

	openedAt;

	clickedAt;

	extra;

	/*
	TODO: to remove after remapping to apiPost.payload and apiPost.payloadFull
	*/
	sendPayload;

	/*
	'apiPost' holds data specific to the third-party service used to send mailings
	(payload sent, response received)
	*/
	apiPost;

	/*
	{
		payload;
		payloadFull;
		response;
		responseFull;
	}
	*/

	linkedDocumentModel;

	linkedDocumentId;

	/*
		'linkedDocument' holds a reference to the document which triggered the sending of the mailing
		If a new UserMailing should be created to fix the sending, this document should be updated
		*/
	linkedDocument;
	/*
	{
		modelName;
		documentId;
		path;
	}
	*/

	history;

	retried;

	status;
	/*
	[{
		createdAt: { type: Date, index: true },
		eventType: { type: String, index: true },
	}],
	*/

	userMessage;

	constructor(data) {
		Object.assign(this, data);

		this.user = data?.user ? new UserModel(data?.user) : null;
		this.recipient = data?.recipient ? new UserModel(data?.recipient) : null;
		this.sender = data?.sender ? new UserModel(data?.sender) : null;
		this.userTrigger = data?.userTrigger ? new UserModel(data?.userTrigger) : null;
	}

	get ID() {
		return documentId(this);
	}

	get payload() {
		return this?.apiPost?.payload || this?.sendPayload;
	}

	get result() {
		return this?.apiPost?.result || this?.sendResult;
	}

	get messageBody() {
		return this?.payload?.Variables?.messageBody;
	}

	get refreshedAt() {
		return this.dataRefreshedAt || this.lastSyncedAt;
	}

	get lastActivityAt() {
		const { createdAt, eventType } = this?.history?.[0] || {};
		if ([MAILJET_STATUS_OPENED_ID, MAILJET_STATUS_CLICKED_ID].includes(eventType)) {
			return createdAt;
		}
		return null;
	}

	get historyOpenedList() {
		return this?.history
			?.slice?.()
			?.filter(({ eventType, createdAt }) => eventType === MAILJET_STATUS_OPENED_ID);
	}

	get historyOpenedCount() {
		return (this?.historyOpenedList || [])?.length;
	}

	get firstOpenedAt() {
		const [item] = this?.historyOpenedList?.reverse?.() || [];
		return item?.createdAt;
	}

	get lastOpenedAt() {
		const [item] = this?.historyOpenedList || [];
		return item?.createdAt;
	}

	get historyClickedList() {
		return this?.history
			?.slice?.()
			?.filter(({ eventType, createdAt }) => eventType === MAILJET_STATUS_CLICKED_ID);
	}

	get historyClickedCount() {
		return (this?.historyClickedList || [])?.length;
	}

	get firstClickedAt() {
		const [item] = this?.historyClickedList?.reverse?.() || [];
		return item?.createdAt;
	}

	get lastClickedAt() {
		const [item] = this?.historyClickedList || [];
		return item?.createdAt;
	}

	get historySentAt() {
		return this?.historyList
			?.find?.(({ eventType, createdAt }) => eventType === MAILJET_STATUS_SENT_ID)
			?.createdAt;
	}

	renderStatusTag(theme, { status, label } = {}) {
		const statusData = MAILING_STATUS_LIST.find((item) => documentId(item) === (status || this?.status));

		return statusData
			? <Tag
				color={statusData?.color || 'volcano'}
				style={{
					margin: 0,
					fontSize: 11,
					padding: '2px 4px',
					lineHeight: 1,
					textTransform: 'uppercase',
					fontWeight: 'bold',
				}}>
				{label || statusData?.label}
			</Tag>
			: <Typography.Text disabled italic>N/A</Typography.Text>;
	}

	renderHistoryCountTag(theme = {}) {
		return this?.history?.length > 1
			? <Tag
				color='gray'
				style={{
					margin: 0,
					fontSize: 11,
					padding: '2px 4px',
					lineHeight: 1,
				}}>
				+{(this?.history?.length || 0) - 1}
			</Tag>
			: null;
	}

	renderHistoryList(theme = {}) {
		if (!this?.history || !(this?.history?.length >= 0)) return null;
		return this?.history?.map(({ eventType, createdAt }, index) => {
			return (
				<Row key={index} style={{ width: 420, fontSize: 10, padding: 4 }}>
					<Col className="gutter-row" span={5} style={{ textAlign: 'center' }}>
						{this.renderStatusTag(theme, { status: eventType })}
					</Col>
					<Col className="gutter-row" span={6} style={{ textAlign: 'center' }}>
						<Typography.Text strong>
							{moment(createdAt).fromNow()}
						</Typography.Text>
					</Col>
					<Col className="gutter-row" span={6} style={{ textAlign: 'center' }}>
						<Typography.Text strong>
							{eventType !== MAILJET_STATUS_SENT_ID && `+${momentShortenFrom(moment(createdAt).from(this.historySentAt || this.sentAt))}`}
						</Typography.Text>
					</Col>
					<Col className="gutter-row" span={7} style={{ textAlign: 'left' }}>
						<Typography.Text type='secondary'>
							{moment(createdAt).format(`${DATE_FORMAT}:ss`)}
						</Typography.Text>
					</Col>
				</Row>
			);
		});
	}

	renderStatusTagAndHistoryHover(theme = {}) {
		const historyList = this.renderHistoryList?.(theme);

		const label = (
			<Space size={4}>
				{this?.renderStatusTag?.(theme)}
				{this?.renderHistoryCountTag?.(theme)}
			</Space>
		);

		return historyList?.length > 0
			? (
				<Popover
					title='Mailjet history'
					content={historyList}
					placement='right'
				>
					{label}
				</Popover>
			)
			: label;
	}

	renderLastActivityAtText(theme = {}) {
		return (
			this?.lastActivityAt
				? (
					<Typography.Text>
						<Typography.Text bold>Last activity</Typography.Text>
						{momentShortenFrom(moment(this?.lastActivityAt).fromNow())}
					</Typography.Text>
				)
				: null
		);
	}

	renderFirstOpenedAtText(theme = {}) {
		return (
			this?.firstOpenedAt
				? (
					<Typography.Text>
						<Typography.Text bold>Opened</Typography.Text>
						after {momentShortenFrom(moment(this?.firstOpenedAt).from(moment(this.sentAt)))}
					</Typography.Text>
				)
				: null
		);
	}

	renderFirstClickedAtText(theme = {}) {
		return (
			this?.firstClickedAt
				? (
					<Typography.Text>
						<Typography.Text bold>Clicked</Typography.Text>
						after {momentShortenFrom(moment(this?.firstClickedAt).from(moment(this.sentAt)))}
					</Typography.Text>
				)
				: null
		);
	}
}
