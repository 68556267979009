import { Tabs } from 'antd';
import Funnels from './Funnels';
import FunnelSourcingColumn from './FunnelSourcingColumn';
import SOURCING_CONSTANTS from '../../../../constants/sourcing';
import FUNNEL_EXCLUSION from '../../../../constants/funnel-exclusion';
import { LocalDebug } from '../../../../utils/LocalDebug';

const FunnelsTabs = (
	{
		filters,
	},
) => {
	const className = 'FunnelsTabs';

	const tabs = [
		{
			tab: '🧪 User Qualification',
			model: 'User',
			funnel: 'funnel.qualification',
			columns: [
				{
					title: 'Registered',
					value: 'registered',
					details: 'Talents who created their account but didn\'t finish the onboarding',
					menu: [
						'contact',
						'coach',
						'qualified',
						'reachable',
						'exclude',
					],
				},
				{
					title: 'Onboarded',
					value: 'onboarded',
					details: 'Talents who finished the onboarding',
					menu: [
						'contact',
						'coach',
						'qualified',
						'reachable',
						'exclude',
					],
				},
				{
					title: 'Contacted',
					value: 'contacted',
					details: 'Talents who were contacted by a Talent Coach',
					menu: [
						'contact',
						'scheduled',
						'coach',
						'qualified',
						'reachable',
						'exclude',
					],
				},
				{
					title: 'Contacted x2',
					value: 'contacted-x2',
					details: 'Talents who were contacted twice by a Talent Coach',
					menu: [
						'contact',
						'scheduled',
						'coach',
						'qualified',
						'reachable',
						'exclude',
					],
				},
				{
					title: 'Contacted x3',
					value: 'contacted-x3',
					details: 'Talents who were contacted three times by a Talent Coach',
					menu: [
						'contact',
						'scheduled',
						'coach',
						'qualified',
						'reachable',
						'exclude',
					],
				},
				{
					title: 'Scheduled',
					value: 'scheduled',
					details: 'Talents scheduled for a call with a Talent Coach',
					menu: [
						'coach',
						'qualified',
						'reachable',
						'exclude',
					],
				},
				{
					title: 'Qualified',
					value: 'qualified',
					details: <>
						Talents <b>qualified</b> by a Talent Coach.
						<br />Talents can be marked as "Qualified" either <b>manually</b> via this page, or later <b>via cron job</b> if their <b>coaching status</b> is set to "Coaching done", "Ready for NL", "Visible", or "Sent in NL
					</>,
					menu: [
						'coach',
						'reachable',
						'exclude',
					],
				},
			],
		},
		{
			tab: '⛔ Excluded users',
			model: 'User',
			funnel: 'funnel.exclusion',
			columns: FUNNEL_EXCLUSION.options,
		},
		{
			tab: '✅ Reachable talents',
			model: 'User',
			funnel: 'funnel.reachable',
			columns: [
				{
					title: 'Active talents',
					value: 'active',
					details: <>
						Talents <b>visible</b> by recruiters, and considered as <b>"active"</b>
						<ul style={{ paddingInlineStart: 15 }}>
							<li>Manually set as <b>"reachable"</b> by a coach</li>
							<li>with a job search status set as <b>"Actively looking"</b> or <b>"Open to opportunities"</b></li>
							<li>and the job search status updated <b>less than 6 months ago</b></li>
						</ul>
					</>,
					menu: [
						'reachable',
						'exclude',
					],
				},
				{
					title: 'Passive talents',
					value: 'passive',
					details: <>
						Talents <b>visible</b> by recruiters, and considered as <b>"passive"</b>
						<ul style={{ paddingInlineStart: 15 }}>
							<li>Manually set as <b>"reachable"</b> by a coach</li>
							<li>with a job search status set as <b>"Actively looking"</b> or <b>"Open to opportunities"</b></li>
							<li>and the job search status updated <b>more than 6 months ago</b></li>
						</ul>
					</>,
					menu: [
						'reachable',
						'exclude',
					],
				},
				{
					title: 'Hidden talents',
					value: 'hidden',
					details: <>
						Talents <b>hidden</b> to recruiters
						<ul style={{ paddingInlineStart: 15 }}>
							<li>Manually set as <b>"reachable"</b> by a coach</li>
							<li>with a job search status set as <b>"Not looking"</b></li>
						</ul>
					</>,
					menu: [
						'reachable',
						'exclude',
					],
				},
			],
		},
		{
			tab: '🏓 Talents responsiveness',
			model: 'User',
			funnel: 'funnel.messaged',
			columns: [
				{
					title: 'Never messaged',
					value: 'never-messaged',
					menu: ['archive'],
				},
				{
					title: 'Never answered',
					value: 'answered-no',
					menu: ['archive'],
				},
				{
					title: '1 message answered',
					value: 'answered-one',
					menu: ['archive'],
				},
				{
					title: '2-5 messages',
					value: 'answered-2to5',
					menu: ['archive'],
				},
				{
					title: '+5 messages answered',
					value: 'answered-5more',
					menu: ['archive'],
				},
				{
					title: 'Archived',
					value: 'archived',
					menu: ['unarchive'],
				},
			],
		},
		{
			tab: '☘️️ Talents in process',
			model: 'Sourcing',
			ColumnComponent: FunnelSourcingColumn,
			funnel: 'candidateStage',
			columns: [
				{
					title: `${SOURCING_CONSTANTS.CANDIDATE_STAGE_SHORTLIST_50INTECH_ICON} ${SOURCING_CONSTANTS.CANDIDATE_STAGE_SHORTLIST_50INTECH_LABEL}`,
					details: 'Suggested talents by 50inTech',
					value: [
						SOURCING_CONSTANTS.CANDIDATE_STAGE_SHORTLIST_50INTECH_VALUE,
					],
					cardOptions: { withSource: false },
				},
				{
					title: `${SOURCING_CONSTANTS.CANDIDATE_STAGE_SHORTLIST_RECRUITER_ICON} ${SOURCING_CONSTANTS.CANDIDATE_STAGE_SHORTLIST_RECRUITER_LABEL}`,
					details: 'Saved for later',
					value: [
						SOURCING_CONSTANTS.CANDIDATE_STAGE_SHORTLIST_RECRUITER_VALUE,
					],
				},
				{
					// title: 'Applied',
					title: `${SOURCING_CONSTANTS.CANDIDATE_STAGE_APPLICANT_NEW_ICON} ${SOURCING_CONSTANTS.CANDIDATE_STAGE_APPLICANT_NEW_LABEL}`,
					details: 'Sourced talents or applicants',
					value: [
						SOURCING_CONSTANTS.CANDIDATE_STAGE_APPLICANT_NEW_VALUE,
						SOURCING_CONSTANTS.CANDIDATE_STAGE_APPLICANT_ANSWERED_VALUE,
						SOURCING_CONSTANTS.CANDIDATE_STAGE_APPLICANT_OTHER_VALUE,
					],
				},
				{
					title: `${SOURCING_CONSTANTS.CANDIDATE_STAGE_LEAD_NEW_ICON} ${SOURCING_CONSTANTS.CANDIDATE_STAGE_LEAD_NEW_LABEL}`,
					// title: 'Sourced',
					details: 'Sourced talents or applicants',
					value: [
						SOURCING_CONSTANTS.CANDIDATE_STAGE_LEAD_NEW_VALUE,
						SOURCING_CONSTANTS.CANDIDATE_STAGE_LEAD_REACHED_OUT_VALUE,
						SOURCING_CONSTANTS.CANDIDATE_STAGE_LEAD_RESPONDED_VALUE,
					],
				},
				{
					title: `${SOURCING_CONSTANTS.CANDIDATE_STAGE_SCREENING_ICON} ${SOURCING_CONSTANTS.CANDIDATE_STAGE_SCREENING_LABEL}`,
					// title: 'Screening',
					details: '"Screening" stages',
					value: [SOURCING_CONSTANTS.CANDIDATE_STAGE_SCREENING_VALUE],
				},
				{
					title: `${SOURCING_CONSTANTS.CANDIDATE_STAGE_INTERVIEW_ICON} ${SOURCING_CONSTANTS.CANDIDATE_STAGE_INTERVIEW_LABEL}`,
					details: '"Interview" stages',
					value: [
						SOURCING_CONSTANTS.CANDIDATE_STAGE_INTERVIEW_VALUE,
						SOURCING_CONSTANTS.CANDIDATE_STAGE_INTERVIEW_OTHER_VALUE,
					],
				},
				{
					title: `${SOURCING_CONSTANTS.CANDIDATE_STAGE_ASSESSMENT_ICON} ${SOURCING_CONSTANTS.CANDIDATE_STAGE_ASSESSMENT_LABEL}`,
					details: '"Assessment" stages',
					value: [
						SOURCING_CONSTANTS.CANDIDATE_STAGE_ASSESSMENT_VALUE,
					],
				},
				{
					title: `${SOURCING_CONSTANTS.CANDIDATE_STAGE_REFERENCE_CHECK_ICON} ${SOURCING_CONSTANTS.CANDIDATE_STAGE_REFERENCE_CHECK_LABEL}`,
					details: '"Reference check" stages',
					value: [
						SOURCING_CONSTANTS.CANDIDATE_STAGE_REFERENCE_CHECK_VALUE,
					],
				},
				{
					title: `${SOURCING_CONSTANTS.CANDIDATE_STAGE_OFFER_ICON} ${SOURCING_CONSTANTS.CANDIDATE_STAGE_OFFER_LABEL}`,
					details: '"Offer" stages',
					value: [
						SOURCING_CONSTANTS.CANDIDATE_STAGE_OFFER_VALUE,
					],
				},
				{
					title: `${SOURCING_CONSTANTS.CANDIDATE_STAGE_HIRED_ICON} ${SOURCING_CONSTANTS.CANDIDATE_STAGE_HIRED_LABEL}`,
					details: '"Hired" stages',
					value: [
						SOURCING_CONSTANTS.CANDIDATE_STAGE_HIRED_VALUE,
					],
				},
			],
		},
		{
			tab: '🍩 Client engagement',
			model: 'Company',
			funnel: 'funnel.engagement',
			columns: [
				{ title: 'Sleeping', details: 'Not logged', value: 'sleeping' },
				{ title: 'Inactive', details: 'Logged, no actions', value: 'inactive' },
				{ title: 'Passive', details: 'Logged, some actions but no messages sent', value: 'passive' },
				{ title: 'Active', details: 'Less than 3 messages sent', value: 'active' },
				{ title: 'Engaged', details: 'More than 3 messages sent', value: 'engaged' },
			],
		},
	];

	const onDragStart = (event) => {
		const method = 'onDragStart';
		LocalDebug.logInfo({ className, method }, event);
	};

	const onDragEnd = (event) => {
		const method = 'onDragEnd';
		LocalDebug.logInfo({ className, method }, event);
	};

	const onDragMove = (event) => {
		const method = 'onDragMove';
		const draggableId = event?.active?.id;
		const droppableId = event?.over?.id;
		LocalDebug.logInfo({ className, method }, { event, draggableId, droppableId });
	};

	return (
		<>
			<Tabs>
				{tabs
					.map((tab, i) => (
						<Tabs.TabPane key={i} tab={tab.tab}>
							<Funnels
								funnel={tab?.funnel}
								columns={tab?.columns}
								ColumnComponent={tab?.ColumnComponent}
								filters={{
									...filters,
									model: tab.model,
								}}
							/>
							{/* </DndContext> */}
						</Tabs.TabPane>
					))}
			</Tabs>
		</>
	);
};

export default FunnelsTabs;
