import { BookOutlined } from '@ant-design/icons';
import GenderScoreResourcesPage from '../../containers/genderscore/GenderScoreResourcesPage';
import MenuBase from '../MenuBase';
import { PERM_GRANT_TYPE, ROLES_STAFF_CLIENT_NO_EDITOR } from '../menu.utils';

export const GenderscoreResourcesIcon = BookOutlined;

class GenderscoreResourcesMenu extends MenuBase {
	static props = {
		name: 'resources',
		path: '/resources',
		label: 'Resources',
		icon: <GenderscoreResourcesIcon />,
		iconLegacy: '📚',
		Component: GenderScoreResourcesPage,
		perms: [
			{
				type: PERM_GRANT_TYPE,
				roles: ROLES_STAFF_CLIENT_NO_EDITOR,
				genderScoreEnabled: true,
			},
		],
	};

	constructor() {
		super(GenderscoreResourcesMenu.props);
	}
}

export default GenderscoreResourcesMenu;
