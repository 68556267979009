import { Select } from 'antd';
import FormItem from '../../../company/form/FormItem';

const FunnelSelectFormItem = (
	{
		label,
		field,
		options,
		placeholder,
		mode = 'multiple',
		setFilters,
		style,
	},
) => {
	return (
		<FormItem
			label={label}
			layout='vertical'
			style={{ margin: 0 }}
		>
			<Select
				showSearch
				allowClear
				mode={mode}
				onChange={(value) => setFilters((p) => ({ ...p, [field]: value }))}
				filterOption={(input, option) => (option?.label ?? '').toLowerCase?.().includes(input?.toLowerCase?.())
				}
				filterSort={(a, b) => {
					return (a?.label || '')?.toLowerCase?.().localeCompare((b?.label || '')?.toLowerCase?.());
				}}
				placeholder={placeholder}
				style={{
					...style,
					fontSize: 12,
				}}
			>
				{options
					.map((option) => <Select.Option
						key={option.value}
						label={option.label}
						value={option.value}
					>
						<span style={{ fontSize: 12 }}>
							{option.label}
						</span>
					</Select.Option>)
				}
			</Select>
		</FormItem >
	);
};

export default FunnelSelectFormItem;
