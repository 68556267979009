import { fill, findIndex, orderBy } from 'lodash';
import { useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { addSourcing, removeSourcing, getSourcingWithActions } from '../../actions/sourcings';
import { SOURCING_DOCUMENT_REF } from '../../constants/constant';
import { SocketContext } from '../../contexts/SocketProvider';
import { useAuthContext } from '../../hooks/useAuthContext';
import SourcingDrawer from './SourcingDrawer';
import SourcingTable from './SourcingTable';
import { documentId } from '../../utils/common';

const SourcingsTab = ({
	sourcings,
	initialValues = {},
	viaProfile = false,
	viaJob = false,
}) => {
	// context
	const { isEditor, isStaff, isStaffView } = useAuthContext();
	const { createAction } = useContext(SocketContext);

	// state
	const [dataSource, setDataSource] = useState([]);
	const [openAddSourcing, setOpenAddSourcing] = useState(false);

	// dispatch
	const dispatch = useDispatch();

	const saveSourcing = async (values, title) => {
		const valuesToDb = { ...values };
		const result = await addSourcing(valuesToDb);

		// websocket
		if (result) {
			const playload = {
				documentRef: SOURCING_DOCUMENT_REF,
				documentId: documentId(result),
				companyId: result.companyId,
				action: {
					title,
					...(values?.history && { history: values.history }),
				},
			};
			createAction(playload);
		}
		return getSourcingWithActions(result, isStaffView);
	};

	// add sourcing
	const handleAddSourcing = async (values, title = 'create a sourcing') => {
		const newSourcing = await saveSourcing(values, title);
		setDataSource((prev) => ([newSourcing, ...prev]));
		setOpenAddSourcing(false);
	};

	// edit sourcing
	const handleEditSourcing = async (values) => {
		const newSourcing = await saveSourcing(values, 'edit a sourcing');
		const prevData = [...dataSource];
		const index = findIndex(prevData, (sourcing) => documentId(sourcing) === documentId(newSourcing));
		fill(prevData, newSourcing, index, index + 1);
		setDataSource(prevData);
	};

	// delete sourcing
	const handleDeleteSourcing = (sourcing) => {
		dispatch(removeSourcing(sourcing));
		setDataSource((prev) => prev.filter((item) => documentId(item) !== documentId(sourcing)));
	};

	useEffect(() => {
		let data = (sourcings || []).map((sourcing) => {
			return getSourcingWithActions(sourcing, isStaffView);
		});
		// localDebug('SourcingsTab.useEffect[isStaff, isStaffView, sourcings]', { sourcings, data });

		// sorting by data
		data = orderBy(data, ['updatedAt'], ['desc']);
		setDataSource(data);
	}, [isStaff, isStaffView, sourcings]);

	return (

		<>
			<SourcingTable
				dataSource={dataSource}
				isEditor={isEditor}
				onEditSourcing={handleEditSourcing}
				onDeleteSourcing={handleDeleteSourcing}
				withDynamicSearch={false}
				withTableHeader={false}
				withClientSidePagination
				viaProfile={viaProfile}
				viaJob={viaJob}
			/>
			<SourcingDrawer
				open={openAddSourcing}
				initialValues={initialValues}
				onClose={() => setOpenAddSourcing(false)}
				onSubmit={handleAddSourcing}
				onReset={() => setOpenAddSourcing(false)}
			/>
		</>
	);
};

export default SourcingsTab;
