import { useEffect, useState } from 'react';
import { ENGLISH_LANG } from '../../../../constants/constant';
import AddButton from '../../../app/button/AddButton';
import DraggableTable from '../../../app/table/DraggableTable';
import TableColumns from '../../../app/table/TableColumns';
import UserService from '../../../../services/user';
import { request } from '../../../../services/api';
import { useTheme } from '@emotion/react';
import CompanyFieldEditor from '../shared/CompanyFieldEditor';
import TeamAddModal from './CompanyTeamAddModal';
import { inviteUser } from '../../../../actions/company';
import { documentId, writeLog } from '../../../../utils/common';
import { useDispatch } from 'react-redux';

const CompanyTeamEditor = (
	{
		company,
		lang = ENGLISH_LANG,
		isTranslatable,
		setEditorOpen,
	},
) => {
	const className = 'CompanyTeamEditor';

	const theme = useTheme();
	const dispatch = useDispatch();

	const field = 'team';

	const [editedValue, setEditedValue] = useState(company?.[field]);
	const [dataSource, setDataSource] = useState([]);
	const [item, setItem] = useState({});
	const [modalOpen, setModalOpen] = useState(false);
	const [modalTitle, setModalTitle] = useState('Add a team member');

	useEffect(async () => {
		const { data: { items } } = await request.get(`/company/${documentId(company)}/team`);
		setDataSource((items || []).map((items) => ({ ...items, id: documentId(items) })));
	}, [company]);

	useEffect(() => {
		setEditedValue(dataSource.map((u) => documentId(u)));
	}, [dataSource]);

	const handleUp = (index) => {
		const startIndex = index;
		const endIndex = index === 0 ? editedValue.length - 1 : index - 1;
		handleOrderRow(startIndex, endIndex);
	};

	const handleDown = (index) => {
		const startIndex = index;
		const endIndex = index + 1 === editedValue.length ? 0 : index + 1;
		handleOrderRow(startIndex, endIndex);
	};

	const handleOrderRow = (startIndex, endIndex) => {
		const newDataSource = [...dataSource];
		const tempValue = newDataSource[startIndex];
		newDataSource[startIndex] = newDataSource[endIndex];
		newDataSource[endIndex] = tempValue;
		setDataSource(newDataSource);
	};

	const handleAdd = async (values) => {
		const { data: { users: [item] } } = await UserService.findAll({ userIds: [values.userId] });
		setDataSource((prev) => [...prev, item]);
		setModalOpen(false);
	};

	const handleRemove = (row) => {
		setDataSource(dataSource.filter((item) => documentId(item) !== documentId(row)));
	};

	const sendInviteUser = async (values) => {
		dispatch(inviteUser(documentId(company), values));
		setModalOpen(false);
		writeLog('sendInviteUser');
	};

	const columns = [
		TableColumns.userStubColumn(theme, {
			title: 'Team member',
			value: (value, row) => row,
			sorter: false,
		}),
	];

	return (
		<CompanyFieldEditor
			company={company}
			setEditorOpen={setEditorOpen}
			title='Team'
			field={field}
			editedValue={editedValue}
			isTranslatable={isTranslatable}
		>
			<AddButton
				style={{ margin: '10px 0' }}
				onClick={() => setModalOpen(true)}
				title="Add a team member"
			/>

			<DraggableTable
				dataSource={dataSource}
				columns={columns}
				onDragEnd={handleOrderRow}
				onMoveRowUp={handleUp}
				onMoveRowDown={handleDown}
				onDeleteRow={handleRemove}
			/>

			<TeamAddModal
				title={modalTitle}
				open={modalOpen}
				onSave={handleAdd}
				onInviteUser={sendInviteUser}
				onClose={() => setModalOpen(false)}
				teams={dataSource}
				initialValues={{
					notUserIds: dataSource.map((d) => documentId(d)),
				}}
			/>

		</CompanyFieldEditor>
	);
};

export default CompanyTeamEditor;
