import { Space, Tag } from 'antd';
import moment from 'moment/moment';
import {
	FaBan, FaCheck, FaClock, FaStop, FaTimes,
} from 'react-icons/fa';
import BlockStaff from '../../app/blocker/BlockStaff';
import CompanySettingsCard from './CompanySettingsCard';
import CompanySettingsCompanyPagePublicationForm from './CompanySettingsCompanyPagePublicationForm';
import { useTheme } from '@emotion/react';
import CompanySettingsJobsPublicationForm from './CompanySettingsJobsPublicationForm';

const JobsPublicationStatus = ({ company }) => {
	const theme = useTheme();
	let icon = <FaClock style={{ marginBottom: -2, color: theme.color.fitBlueElectric }}/>;
	let label = <span>
		<b>{company.name}</b>'s jobs publication
		is <b>programmed {moment(company.jobsPublishedAt).fromNow()}</b> ({moment(company.jobsPublishedAt).format('MMM Do, HH:mm')})</span>;
	if (!company.areJobsPublished) {
		icon = <FaBan style={{ marginBottom: -2, color: theme.color.red }}/>;
		label = <span><b>{company.name}</b>'s jobs are <b>not published</b></span>;
	} else if (!company.jobsPublishedAt || moment(company.jobsPublishedAt).isBefore(moment())) {
		icon = <FaCheck style={{ marginBottom: -2, color: theme.color.darkturquoise }}/>;
		label = <span><b>{company.name}</b>'s jobs are <b>published</b></span>;
	}
	return <Space align='top'>
		{icon}
		{label}
	</Space>;
};

const CompanySettingsJobsPublicationCard = (
	{
		company,
		onSuccess,
		labelCol = { span: 6 },
		wrapperCol = { span: 16 },
		...props
	},
) => {
	const className = 'CompanySettingsJobsPublicationCard';

	return (
		<div style={{ position: 'relative' }}>
			<BlockStaff withBorder={false} style={{ display: 'block', width: '100%' }}
				tagStyle={{ top: 2, left: 24, opacity: 1 }}>
				<CompanySettingsCard
					title={<>💼 Jobs publication</>}
					details={<>
						Defines whether the jobs are accessible to the talent pool
					</>}
					company={company}
					CompanySettingsForm={CompanySettingsJobsPublicationForm}
				>
					<JobsPublicationStatus company={company}/>
				</CompanySettingsCard>
			</BlockStaff>
		</div>
	);
};

export default CompanySettingsJobsPublicationCard;
