import CAREER_REMOTE from '../../property/career-remote';
import { innerObjPropExtractor } from '../../property/property-extractor';
import { innerObjPropMapper } from '../../property/property-mapper';

const USER_PROPERTY_JOB_SEARCH_JOB_REMOTE_KEY = 'job-search-job-remote';

const USER_PROPERTY_JOB_SEARCH_JOB_REMOTE_MULTI = true;
const USER_PROPERTY_JOB_SEARCH_JOB_REMOTE_FREE = false;

const USER_PROPERTY_JOB_SEARCH_JOB_REMOTE_FIELD = 'jobSearch.jobRemote';
const USER_PROPERTY_JOB_SEARCH_JOB_REMOTE_MAPPER = innerObjPropMapper(USER_PROPERTY_JOB_SEARCH_JOB_REMOTE_FIELD);
const USER_PROPERTY_JOB_SEARCH_JOB_REMOTE_EXTRACTOR = innerObjPropExtractor(USER_PROPERTY_JOB_SEARCH_JOB_REMOTE_FIELD);

export default {
	...CAREER_REMOTE,
	key: USER_PROPERTY_JOB_SEARCH_JOB_REMOTE_KEY,
	USER_PROPERTY_JOB_SEARCH_JOB_REMOTE_KEY,
	free: USER_PROPERTY_JOB_SEARCH_JOB_REMOTE_FREE,
	USER_PROPERTY_JOB_SEARCH_JOB_REMOTE_FREE,
	multi: USER_PROPERTY_JOB_SEARCH_JOB_REMOTE_MULTI,
	USER_PROPERTY_JOB_SEARCH_JOB_REMOTE_MULTI,
	mapper: USER_PROPERTY_JOB_SEARCH_JOB_REMOTE_MAPPER,
	USER_PROPERTY_JOB_SEARCH_JOB_REMOTE_MAPPER,
	extractor: USER_PROPERTY_JOB_SEARCH_JOB_REMOTE_EXTRACTOR,
	USER_PROPERTY_JOB_SEARCH_JOB_REMOTE_EXTRACTOR,
	field: USER_PROPERTY_JOB_SEARCH_JOB_REMOTE_FIELD,
	USER_PROPERTY_JOB_SEARCH_JOB_REMOTE_FIELD,
};
