import React, { useContext, useEffect, useState } from 'react';
import { AuthContext } from './AuthProvider';

export const SocketContext = React.createContext();

const SocketProvider = ({ children }) => {
	const { token } = useContext(AuthContext);
	const [socket, setSocket] = useState(null);

	// ---------------------------------- //
	// ------ handle create action ------ //
	// ---------------------------------- //
	const createAction = (data) => {
		// socket.emit('createAction', data, (res) => {
		//     localDebug('res', res);
		// });
	};

	// // -------------------------------------------- //
	// // ------ refresh count action history -------- //
	// // -------------------------------------------- //
	// const refreshHistoryActions = (socket) => {
	//     // socket.emit('initApp', (res) => {
	//     //     const { data } = res;
	//     //     dispatch(doActionHistory(data));
	//     // });
	// }

	// ----------------------------------- //
	// -------- read action -------------- //
	// ----------------------------------- //
	const readHistoryAction = (playload) => {
		// socket.emit('readAction', playload, (res) => {
		//     // remove in array
		//     dispatch(readAction(playload));
		// });
	};

	// useEffect(() => {

	//     // if (token) {
	// 	// 	const newSocket = socketIOClient(ENDPOINT, {
	// 	// 		auth: {
	// 	// 			token
	// 	// 		}
	// 	// 	});

	// 	// 	newSocket.emit('initApp', (res) => {
	//     //         const { data } = res;
	//     //         dispatch(doActionHistory(data));
	//     //     });

	// 	// 	newSocket.on('refreshMessage', data => {
	// 	// 		refreshHistoryActions(newSocket);
	// 	// 	});
	//     //     setSocket(newSocket);
	// 	// }
	// // eslint-disable-next-line react-hooks/exhaustive-deps
	// }, [token]);

	return (
		<SocketContext.Provider
			value={{
				socket,
				createAction,
				readHistoryAction,
			}}
		>
			{children}
		</SocketContext.Provider>
	);
};

export default SocketProvider;
