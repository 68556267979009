import { Space, Typography } from 'antd';
import { useEffect, useState } from 'react';
import LogFilterModal from '../../../components/staff/data/log/LogFilterModal';
import LogTable from '../../../components/staff/data/log/LogTable';
import PageLayout from '../../../components/app/layout/PageLayout';
import FilterButton from '../../../components/app/filters/FilterButton';
import SectionTitle from '../../../components/app/SectionTitle';
import {
	LOG_ICON, LOG_TITLE, RECRUITER_ACTIVITY_ICON, RECRUITER_ACTIVITY_TITLE,
} from '../../../constants/constant';
import { addMultipleQueryParams, getQueryParams, removeQueryParams } from '../../../utils/common';
import { useAuthContext } from '../../../hooks/useAuthContext';
import RecruiterActivityTable from '../../../components/staff/data/log/RecruiterActivityTable';
import RecruiterActivityFilterModal from '../../../components/staff/data/log/RecruiterActivityFilterModal';

const RecruiterActivityPage = () => {
	const queryParams = getQueryParams();
	// state
	const { isAdmin } = useAuthContext();
	const [optionsFilterTable, setOptionsFilterTable] = useState({});
	const [isRefreshDataLayout, setIsRefreshDataLayout] = useState(0);

	useEffect(() => {
		setOptionsFilterTable((prev) => ({ ...prev, ...queryParams }));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	// --------------------------------------- //
	// ------------ handle filter ------------ //
	// --------------------------------------- //
	const handleFilter = (values) => {
		const { tags, ...rest } = values;
		addMultipleQueryParams(rest);
		setOptionsFilterTable((prev) => {
			return ({ ...prev, ...rest, tags });
		});
	};

	const handleClearFilter = () => {
		setOptionsFilterTable({});
		setIsRefreshDataLayout((prev) => prev + 1);
		removeQueryParams();
	};
	return (
		<PageLayout
			withBackground={false}
			icon={RECRUITER_ACTIVITY_ICON}
			title={RECRUITER_ACTIVITY_TITLE}
			filter={<FilterButton
				title="Filter recruiters"
				modal={RecruiterActivityFilterModal}
				onFilter={handleFilter}
				onClearFilter={handleClearFilter}
				initialValues={optionsFilterTable}
			/>}
		>
			<RecruiterActivityTable
				isRefresh={isRefreshDataLayout}
				filterOptions={optionsFilterTable}
			/>
		</PageLayout>
	);
};

export default RecruiterActivityPage;
