import {
	useContext, useEffect, useRef, useState,
} from 'react';
import { useDispatch, useStore } from 'react-redux';
import {
	Navigate, Routes as ReactRoutes, Route, useLocation,
} from 'react-router-dom';
import App from './containers/App';
import LoginPage from './containers/login/LoginPage';
import { createOnEnterPermitted } from './utils/client/permission';
import { menuBuilder as genderHirePlansMenuBuilder } from './menu/menu-genderhire-plans';
import { menuBuilder as genderScorePlansMenuBuilder } from './menu/menu-genderscore-plans';
import { menuBuilder as umsPlansMenuBuilder } from './menu/menu-ums-plans';
import TagRuleCreationPage from './containers/staff/tech/tag/TagRuleCreationPage';
import {
	onEnterJob, onEnterTagRuleEdition, onEnterUser,
} from './actions/app';
import ResetPassword from './containers/tools/ResetPassword';
import UserPage from './containers/root/UserPage';
import JobPage from './containers/root/JobPage';
import UserCoachingPage from './containers/root/UserCoachingPage';
import EmptyPage from './containers/EmptyPage';
import RouteWrapper from './RouteWrapper';
import { LocalDebug } from './utils/LocalDebug';
import { useAuthContext } from './hooks/useAuthContext';
import { isGenderScorePlan, isUMSPlan } from './constants/property/business-plan';
import { CompanyContext } from './contexts/CompanyProvider';
import accountMenu from './menu/root/account';
import { SurveyContext } from './components/genderscore/survey/SurveyProvider';
import SurveyScoringDetails from './components/genderscore/survey/SurveyScoringDetails';
import SurveyQuestion from './components/genderscore/survey/SurveyQuestion';
import SurveySection from './components/genderscore/survey/SurveySection';
import SurveyReviewAnswers from './components/genderscore/survey/SurveyReviewAnswers';
import { AppDataContext } from './contexts/AppDataProvider';
import UMSSurveyRouter from './components/ums/survey/providers/SurveyRouter';
import AccountMenu from './menu/AccountMenu';

const Routes = (props) => {
	const className = 'Routes';

	const store = useStore();
	const dispatch = useDispatch();
	const location = useLocation();
	const lastHash = useRef('');

	const {
		routePermissions,
		isMenuItemAllowed,
		appDataReady,
	} = useAuthContext();

	const { planValue } = useContext(CompanyContext);

	const authContext = useAuthContext();
	const appDataContext = useContext(AppDataContext);

	const context = {
		...authContext || {},
		...appDataContext || {},
		planValue,
	};

	const { buildSurveyRoutes } = useContext(SurveyContext);

	const [routes, setRoutes] = useState();
	const [wildcardRoute, setWildcardRoute] = useState(
		<Route path='*' element={<></>} key={'*'} />,
	);

	useEffect(() => {
		// Managing the scroll to a hash anchor in the page, even after a route change
		if (location.hash) {
			lastHash.current = location.hash.slice(1); // safe hash for further use after navigation
		}

		if (lastHash.current && document.getElementById(lastHash.current)) {
			setTimeout(() => {
				document
					.getElementById(lastHash.current)
					?.scrollIntoView({ behavior: 'smooth', block: 'start' });
				lastHash.current = '';
			}, 100);
		}
	}, [location]);

	// ------------------------------------ //
	// --------- define a route ----------- //
	// ------------------------------------ //
	const createRoute = (item) => {
		const method = 'createRoute';
		const { isIndexRoute, path, component: Component } = item;

		const isMenuItemAllowedResult = isMenuItemAllowed(item);

		LocalDebug.logNull({ className, method }, 'allowed: ', { name: item.name, isMenuItemAllowedResult });

		return (
			<Route
				key={item.index}
				path={path}
				element={isMenuItemAllowedResult
					? (
						<RouteWrapper
							component={Component}
							onEnter={createOnEnterPermitted(item, store)}
						/>
					)
					: (
						<RouteWrapper
							component={EmptyPage}
						/>
					)
				}
				{...(
					isMenuItemAllowedResult
					&& isIndexRoute?.(context)
					&& { index: true }
				)}
			/>
		);
	};

	// ------------------------------------------------- //
	// ------ define routes with path and aliases ------ //
	// ------------------------------------------------- //
	const createRoutesWithAliases = (item) => {
		if (!item) {
			return [];
		}
		let {
			isIndexRoute,
			path,
			aliases,
		} = item;

		if (isIndexRoute?.(context)) {
			aliases = [...aliases || [], '/'];
		}
		return [path, ...(aliases || [])]
			.map((pathOrAlias, index) => createRoute({ ...item, path: pathOrAlias, index }));
	};

	// ------------------------------------- //
	// --------- walk routes items --------- //
	// ------------------------------------- //
	const walkRoutes = (routeList, callback, allRoutes = []) => {
		for (const item of routeList) {
			if (item) {
				const newLocations = callback(item);
				allRoutes.push(...newLocations);

				if (item.children && item.children.length) {
					walkRoutes(item.children, callback, allRoutes);
				}
			}
		}
	};

	const getRoutes = () => {
		// walk all items
		const routeResults = [];
		let menuItems = genderHirePlansMenuBuilder(context);
		// LocalDebug.logInfo({ className, method: 'getRoutes' }, { planValue });
		if (isUMSPlan(planValue)) {
			LocalDebug.logInfo({ className, method: 'getRoutes' }, { isUMSEmployee: context?.isUMSEmployee });
			menuItems = umsPlansMenuBuilder(context);
		}
		if (isGenderScorePlan(planValue)) {
			menuItems = genderScorePlansMenuBuilder(context);
		}
		walkRoutes(
			[
				...menuItems,
				accountMenu,
			],
			createRoutesWithAliases,
			routeResults,
		);
		return routeResults;
	};

	// --------------------------------------- //
	// --------- all private routes ---------- //
	// --------------------------------------- //
	useEffect(() => {
		LocalDebug.logNull({ className, effects: 'routePermissions, appDataReady' }, { routePermissions, appDataReady });
		if (!appDataReady) return;
		LocalDebug.logNull(
			{ className, effects: 'routePermissions' },
			{ routePermissions, planValue },
		);
		setRoutes(getRoutes?.() || []);
	}, [routePermissions, appDataReady]);

	useEffect(() => {
		LocalDebug.logNull({ className, effects: 'routes' }, { routes: routes?.length });
		if (routes?.length > 0) {
			setWildcardRoute(<Route path='*' element={<Navigate to='/' />} key={'*'} />);
		}
	}, [routes]);

	return (

		<ReactRoutes>
			<Route path='/' element={<App />}>
				{routes}
				<Route path='login' element={<LoginPage />} />
				<Route path='resetpassword' element={<ResetPassword />} />
				<Route path='tag-rule-:tagRuleId' element={<TagRuleCreationPage />}
					onEnter={onEnterTagRuleEdition(store)} />
				<Route path='user/:id' element={<RouteWrapper component={UserPage} onEnter={onEnterUser(store, dispatch)}/>} />
				<Route path='user/:id/coaching' element={<RouteWrapper component={UserCoachingPage} onEnter={onEnterUser(store, dispatch)} />} />
				<Route path='job/:id' element={<RouteWrapper component={JobPage} onEnter={onEnterJob(store, dispatch)} />} />
				{wildcardRoute}
				{buildSurveyRoutes?.({
					SurveySectionComponent: SurveySection,
					SurveyQuestionComponent: SurveyQuestion,
					SurveyScoringDetailsComponent: SurveyScoringDetails,
					SurveyReviewComponent: SurveyReviewAnswers,
				})}
				{UMSSurveyRouter({})}
			</Route>
		</ReactRoutes>

	);
};

export default Routes;
