/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from 'react';
import { LocalDebug } from '../../utils/LocalDebug';
import RecruiterService from '../../services/recruiter';
import {
	USER_TAG_CAREER_LANGUAGE,
	USER_TAG_CATEGORIES, USER_TAG_JOB_SEARCH_EMPLOYMENT_VISA, USER_TAG_JOB_SEARCH_JOB_LOCATION, USER_TAG_LOCATION, getJobLocationLabel, getLocationTagKey,
} from '../../constants/constant';
import { useSelector } from 'react-redux';
import { getCompanySelected, getTagsUsers } from '../../reducers/app';
import { useAuthContext } from '../../hooks/useAuthContext';
import {
	addMultipleQueryParams, arrayWrap, documentId, getQueryParams, scrollToTop,
} from '../../utils/common';
import { request } from '../../services/api';
import { EMPLOYMENT_VISA_FILTER_OPTIONS } from '../../constants/property/employment-visa';
import { getPrettifiedLanguageLabel } from '../../constants/languages';
import { StaffToolsContext } from '../../contexts/StaffToolsProvider';
import { AlertContext } from '../../contexts/AlertProvider';

export const UserSavedSearchContext = React.createContext();

export const SUGGESTED_TALENTS_PAGE = 'suggested-talents';
export const MY_SEARCHES_PAGE = 'my-searches';
export const HIDDEN_TALENTS_PAGE = 'hidden-talents';
export const PAGES_LIST = [
	MY_SEARCHES_PAGE,
	SUGGESTED_TALENTS_PAGE,
	HIDDEN_TALENTS_PAGE,
];
export const LAST_SELECTED_SAVED_SEARCH_ID_STORAGE = 'LAST_SELECTED_SAVED_SEARCH_ID_STORAGE';
export const LAST_SELECTED_PAGE = 'LAST_SELECTED_PAGE';

const UserSavedSearchProvider = ({
	children,
}) => {
	const className = 'UserSavedSearchProvider';
	const queryParams = getQueryParams();

	const { currentUser } = useAuthContext();
	const { disableAlert } = useContext(AlertContext);
	const {
		isSavedSearchTabsEnabled,
		isSavedSearchExcludeViewedEnabled,
	} = useContext(StaffToolsContext);

	const userTags = useSelector(getTagsUsers);
	const tagCategoryMap = new Map();
	USER_TAG_CATEGORIES.map((c) => tagCategoryMap.set(c.value, c));

	const [dummy, setDummy] = useState(0);
	const [isLoading, setIsLoading] = useState(true);
	const [isFirstLoad, setIsFirstLoad] = useState(true);
	const [selectedPage, setSelectedPage] = useState(null);
	const [savedSearches, setSavedSearches] = useState(null);
	const [mySavedSearches, setMySavedSearches] = useState(null);
	const [talentSelectionSearch, setTalentSelectionSearch] = useState(null);
	const [suggestionSearch, setSuggestionSearch] = useState(null);
	const [hiddenTalentsSearch, setHiddenTalentsSearch] = useState(null);
	const [selectedSavedSearch, setSelectedSavedSearch] = useState(null);
	const [shouldRefreshSavedSearches, setShouldRefreshSavedSearches] = useState(0);
	const [excludedViewed, setExcludedViewed] = useState(false);
	const companySelected = useSelector(getCompanySelected);

	const setStoredLastSelectedPage = (page) => localStorage.setItem(LAST_SELECTED_PAGE, page);
	const getStoredLastSelectedPage = () => localStorage.getItem(LAST_SELECTED_PAGE);

	const setStoredLastSelectedSearchId = (id) => localStorage.setItem(LAST_SELECTED_SAVED_SEARCH_ID_STORAGE, id);
	const getStoredLastSelectedSearchId = () => localStorage.getItem(LAST_SELECTED_SAVED_SEARCH_ID_STORAGE);

	const findSearchById = (id) => id && savedSearches?.find((s) => documentId(s)?.toString?.() === id);
	const findChildSearchById = (searchesArray, id) => id && searchesArray?.find(
		(s) => (s?.editedFrom?.parentSavedSearchId === id
		|| s?.editedFrom?.originalSavedSearchId === id),
	);

	const updateSavedSearches = () => setShouldRefreshSavedSearches((p) => p + 1);

	const getSavedSearchFilter = (key, values) => {
		const category = USER_TAG_CATEGORIES.find((o) => o.value === key);
		if (category && values?.length > 0) {
			return {
				category,
				values: (arrayWrap(values) || []).map((value) => {
					let tagKey = key;
					const tagOriginalKey = key;
					let tagValue = value;
					let tagLabel;
					// If the filter is a location tag
					// Extract the city, country, continent of the tag
					if ([USER_TAG_JOB_SEARCH_JOB_LOCATION.value,
						USER_TAG_LOCATION.value].includes(tagOriginalKey)) {
						[tagKey, tagValue] = value?.split(':') || [tagKey, tagValue];
						tagLabel = getJobLocationLabel({
							value: `${tagKey}:${tagValue}`,
							withCountryFlag: true,
							withCityCountry: true,
							withCityCountryCode: true,
							withCityCountryFlag: true,
						});
						tagKey = getLocationTagKey(tagOriginalKey, tagKey);
					}
					const tagCategory = tagCategoryMap.get(tagOriginalKey);
					const tag = (userTags.find((t) => t.category === tagKey)?.items || [])
						.find((t) => t.value === tagValue);
					if (!tagLabel) tagLabel = tag?.label;
					// For the Visa filters, use the labels from the filter
					if ([USER_TAG_JOB_SEARCH_EMPLOYMENT_VISA.value].includes(tagOriginalKey)) {
						tagLabel = EMPLOYMENT_VISA_FILTER_OPTIONS.find((v) => v.value === value)?.label;
					}
					// For the language filters, use the prettifed labels
					if ([USER_TAG_CAREER_LANGUAGE.value].includes(tagOriginalKey)) {
						tagLabel = getPrettifiedLanguageLabel(value);
					}

					const isBooleanQueryOr = tagCategory?.booleanQuery !== 'and';
					return {
						value: tagValue,
						label: tagLabel,
						isBooleanQueryOr,
					};
				}),
			};
		}
		return {};
	};
	const getSavedSearchFilters = (savedSearch) => {
		if (savedSearch?.is50) return [];
		const {
			tags, language, excludedViewed, sortField, sortOrder, isCoached, ...rest
		} = savedSearch?.filters || {};
		const filterTags = Object.entries(tags || {})
			.map(([key, values]) => {
				return getSavedSearchFilter(key, values);
			})
			.flat()
			.filter((i) => i);
		const savedSearchFilters = [
			...filterTags,
			...Object.entries(rest).map(([key, values]) => ({ category: { value: key }, values: arrayWrap(values) })),
		];
		if (isCoached) {
			savedSearchFilters.push({
				category: { value: 'Pre-screening' },
				values: arrayWrap('Only show pre-screened profiles'),
			});
		}

		return savedSearchFilters;
	};

	const addSelectedSavedSearchQueryParams = (search) => {
		const searchId = documentId(search, search);
		addMultipleQueryParams({ searchId });
	};

	const addSelectedPageQueryParams = (page) => {
		addMultipleQueryParams({ page: isSavedSearchTabsEnabled ? page : MY_SEARCHES_PAGE });
	};

	const onUserViewed = ({ user, savedSearch } = {}) => {
		const userId = user ? documentId(user) : null;
		savedSearch = savedSearch || selectedSavedSearch;
		const savedSearchId = documentId(savedSearch);

		LocalDebug.logInfo({ className, method: 'onUserViewed' }, { user, savedSearch });
		// LocalDebug.logError({
		// 	className,
		// 	method: 'onUserViewed',
		// 	error: { message: 'Beware that selectedSavedSearch is not null for first-time users' },
		// }, 'Beware that selectedSavedSearch is not null for first-time users');
		if (savedSearchId) {
			disableAlert({
				documentId: savedSearchId,
				alertDocumentId: userId,
			});
			request.delete(`/recruiter/saved-search/${savedSearchId}/alert${userId ? `/${userId}` : ''}`);
			if (savedSearch && savedSearch?.alerts?.length > 0) {
				savedSearch.alerts = savedSearch.alerts.filter((id) => userId && id !== userId);
				const index = savedSearches.findIndex((s) => documentId(s) == savedSearchId);
				if (documentId(selectedSavedSearch) == savedSearchId) setSelectedSavedSearch(savedSearch);
				setSavedSearches([
					...savedSearches.slice(0, index),
					savedSearch,
					...savedSearches.slice(index + 1, savedSearches.length),
				]);
				setDummy((p) => p + 1);
			}
		}
	};

	const resetQueryParams = () => {
		addMultipleQueryParams({}, true);
	};
	const initQueryParams = () => {
		let { page, searchId } = queryParams;
		if (!isSavedSearchTabsEnabled) {
			// Force redirect to my-searches
			page = MY_SEARCHES_PAGE;
		}
		addMultipleQueryParams(
			{
				page: page || getStoredLastSelectedPage() || PAGES_LIST[0],
			},
		);
	};

	useEffect(async () => {
		if (currentUser) {
			setIsLoading(true);
			const { data: { results } } = await RecruiterService.getMySavedSearches(
				documentId(currentUser),
				{
					companyId: documentId(companySelected),
					withAlerts: true,
				},
			);
			LocalDebug.logNull({ className, effects: 'shouldRefreshSavedSearches, currentUser, companySelected' }, { results });
			const userSavedSearches = results?.filter((s) => !s?.is50);
			// If the selectedSavedSearch is not part of the results anymore,
			// select the "Suggested for you" if it exists
			// or the first saved search if it does not
			if (selectedSavedSearch
				&& documentId(selectedSavedSearch) !== documentId(suggestionSearch)
				&& !userSavedSearches?.map(
					(r) => documentId(r),
				)?.includes(documentId(selectedSavedSearch))
			) {
				// Check whether the selectedSavedSearch is the parent of one of the searches (edition)
				const childSearch = findChildSearchById(userSavedSearches, documentId(selectedSavedSearch));
				// Try to select the edited search or the suggested by 50i search or the first search
				const callbackSearch = childSearch || suggestionSearch || userSavedSearches[0];
				if (!callbackSearch) {
					resetQueryParams();
				} else {
					addSelectedSavedSearchQueryParams(callbackSearch);
				}
				setSelectedSavedSearch(callbackSearch);
			}
			const coachedSearch = results?.find((s) => s?.is50 && s?.filters?.isCoached);
			const hiddenSearch = results?.find((s) => s?.is50 && s?.filters?.isHidden);
			const suggestedSearch = results?.find((s) => s?.is50 && s?.filters?.isSuggested);
			setSavedSearches(results);
			setMySavedSearches(userSavedSearches);
			setTalentSelectionSearch(coachedSearch);
			setSuggestionSearch(suggestedSearch);
			setHiddenTalentsSearch(hiddenSearch);
			setIsFirstLoad(false);
			setIsLoading(false);
		}
	}, [shouldRefreshSavedSearches, currentUser, companySelected]);

	useEffect(async () => {
		LocalDebug.logNull({ className, effects: 'selectedSavedSearch' }, { selectedSavedSearch });
		if (selectedSavedSearch) {
			if (!selectedSavedSearch.is50 || selectedSavedSearch.filters?.isSuggested) {
				setStoredLastSelectedSearchId(documentId(selectedSavedSearch));
			}
			scrollToTop();
		}
	}, [selectedSavedSearch]);

	useEffect(async () => {
		if (savedSearches || (isSavedSearchTabsEnabled && selectedPage)) {
			LocalDebug.logUseEffect({ className, effects: 'selectedPage' }, {
				selectedPage, savedSearches: savedSearches?.length,
			});
			setStoredLastSelectedPage(selectedPage);
			let search;
			if (selectedPage === SUGGESTED_TALENTS_PAGE) {
				search = suggestionSearch;
				// search = savedSearches?.find(s => s?.is50 && s?.filters?.isSuggested)
			} else if (selectedPage === HIDDEN_TALENTS_PAGE) {
				search = hiddenTalentsSearch;
				// search = savedSearches?.find(s => s?.is50 && s?.filters?.isHidden)
			} else {
				const lastSelectedSavedSearchId = getStoredLastSelectedSearchId();
				search = findSearchById(lastSelectedSavedSearchId)
				|| findChildSearchById(savedSearches, lastSelectedSavedSearchId)
					|| suggestionSearch
					|| savedSearches?.find((s) => s?.is50 && s?.filters?.isSuggested)
					|| mySavedSearches?.[0];
				if (search) setStoredLastSelectedSearchId(documentId(search));
			}
			LocalDebug.logUseEffect({ className, effects: 'selectedPage' }, { search, selectedSavedSearch });
			if (search) {
				LocalDebug.logUseEffect({ className, effects: 'selectedPage' }, 'setSelectedSavedSearch');
				addMultipleQueryParams({ searchId: documentId(search) });
			}
		}
	}, [selectedPage]);

	useEffect(() => {
		LocalDebug.logNull({ className, effects: 'savedSearches, queryParams' }, { savedSearches, queryParams });
		if (savedSearches) {
			const { page, searchId, excludedViewed } = {
				page: MY_SEARCHES_PAGE,
				...queryParams,
			};
			LocalDebug.logNull({ className, effects: 'savedSearches, queryParams' }, {
				testSearchId: searchId !== documentId(selectedSavedSearch),
				testPage: page !== selectedPage,
				searchId,
				selectedSavedSearchId: documentId(selectedSavedSearch),
				page,
				selectedPage,
			});

			if (page !== selectedPage) {
				LocalDebug.logNull({
					className, effects: 'savedSearches, queryParams',
				}, `setSelectedPage(${page})`);
				setSelectedPage(isSavedSearchTabsEnabled
					? page : MY_SEARCHES_PAGE);
			} else {
				// Refresh the selectedSavedSearch value
				// Even if it didn't change
				// To trigger the data fetcher
				const search = findSearchById(searchId) || findChildSearchById(savedSearches, searchId);
				if (search) {
					LocalDebug.logNull({
						className, effects: 'savedSearches, queryParams',
					}, `setSelectedSavedSearch(${documentId(search)})`);
					setSelectedSavedSearch(search);
				}
			}
		}
	}, [savedSearches, queryParams]);

	useEffect(() => {
		if (!isSavedSearchTabsEnabled) {
			initQueryParams();
		}
	}, [isSavedSearchTabsEnabled]);

	useEffect(() => {
		if (!isSavedSearchExcludeViewedEnabled) {
			setExcludedViewed(false);
		}
	}, [isSavedSearchExcludeViewedEnabled]);

	useEffect(async () => {
		initQueryParams();
		// If a searchId is present in the query on start
		// use it as the last stored one to make sure we open with it
		const { searchId } = queryParams || {};
		if (searchId) setStoredLastSelectedSearchId(searchId);
	}, []);

	const value = {
		isFirstLoad,
		isLoading,
		setIsLoading,
		savedSearches,
		setSavedSearches,
		mySavedSearches,
		talentSelectionSearch,
		suggestionSearch,
		hiddenTalentsSearch,
		selectedSavedSearch,
		addSelectedSavedSearchQueryParams,
		addSelectedPageQueryParams,
		shouldRefreshSavedSearches,
		updateSavedSearches,
		excludedViewed,
		setExcludedViewed,
		// refreshSourceData,
		getSavedSearchFilters,
		selectedPage,
		setSelectedPage,
		onUserViewed,
		SUGGESTED_TALENTS_PAGE,
		MY_SEARCHES_PAGE,
		HIDDEN_TALENTS_PAGE,
		PAGES_LIST,
	};
	return (
		<UserSavedSearchContext.Provider
			value={value}
		>
			{children}
		</UserSavedSearchContext.Provider>
	);
};

export default UserSavedSearchProvider;
