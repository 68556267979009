import { Space } from 'antd';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { COMPANY_RECRUITER_LOG } from '../../constants/company-recruiter-log';
import { TEMPLATE_MAIL_ICON, TEMPLATE_MAIL_TITLE } from '../../constants/constant';
import {
	documentId, writeLog,
} from '../../utils/common';
import { createTemplate, deleteTemplate } from '../../actions/template';
import AddButton from '../../components/app/button/AddButton';
import PageLayout from '../../components/app/layout/PageLayout';
import MessageTemplatePopover from '../../components/messages/templates/MessageTemplatePopover';
import MessageTemplateTable from '../../components/messages/templates/MessageTemplateTable';
import SectionTitle from '../../components/app/SectionTitle';
import TemplateService from '../../services/template';
import { getCompanySelected } from '../../reducers/app';
import { useAuthContext } from '../../hooks/useAuthContext';
import { writeRecruiterLog } from '../../actions/logging';
import { LocalDebug } from '../../utils/LocalDebug';
import DataFetcherProvider from '../../components/app/data/DataFetcherProvider';
import ConfigureEmailTemplatesMenu from '../../menu/configure/ConfigureEmailTemplatesMenu';

const ConfigureEmailTemplatesPage = () => {
	const className = 'ConfigureEmailTemplatesPage';

	const dispatch = useDispatch();
	const companySelected = useSelector(getCompanySelected);
	const { isStaffView } = useAuthContext();

	const [openMessageTemplatePopover, setOpenMessageTemplatePopover] = useState(
		false,
	);
	const [isRefreshDataLayout, setIsRefreshDataLayout] = useState(0);
	const [initialValuesForm, setInitialValuesForm] = useState({});
	const [modalTitle, setModalTitle] = useState('');
	const [step, setStep] = useState(0);
	const [isWithStartStep, setIsWithStartStep] = useState(false);
	const [templates, setTemplates] = useState([]);

	const { isAclEditor, isMasterTemplatesEnabled } = useAuthContext();

	useEffect(() => {
		fetchTemplate();
	}, [companySelected]);

	const handleAddMessageTemplate = (template = null, mode = 'create') => {
		LocalDebug.logInfo({ className, method: 'handleAddMessageTemplate' }, { template });
		setOpenMessageTemplatePopover(true);
		setIsWithStartStep(!template);
		setInitialValuesForm({ ...template || {}, mode });
	};

	const handleDuplicateMessageTemplate = (template) => {
		LocalDebug.logInfo({ className, method: 'handleDuplicateMessageTemplate' });
		setOpenMessageTemplatePopover(true);
		if (template) setInitialValuesForm({ masterTemplate: template });
	};

	const handleCloseMessageTemplatePopover = () => {
		setOpenMessageTemplatePopover(false);
		setIsWithStartStep(false);
		setStep(isMasterTemplatesEnabled ? 0 : 1);
	};

	const handleSaveMessageTemplate = async (values) => {
		writeLog('Template: create template mail', { name: values?.name });
		const { _id } = initialValuesForm;
		await createTemplate(
			isWithStartStep && !!_id ? { masterTemplateId: _id, ...values } : values,
		);
		// create log recruiter
		writeRecruiterLog(COMPANY_RECRUITER_LOG.TEMPLATE);
		setOpenMessageTemplatePopover(false);
		setIsRefreshDataLayout((prev) => prev + 1);
		setStep(isMasterTemplatesEnabled ? 0 : 1);
		setIsWithStartStep(false);
		fetchTemplate();
	};

	const fetchTemplate = async () => {
		const method = 'fetchTemplate';
		LocalDebug.logInfo({ className, method });
		const {
			data: { templates: newTemplates },
		} = await TemplateService.findAll({ companyId: documentId(companySelected) });
		setTemplates(newTemplates);
	};

	const onRemoveTemplate = async (template) => {
		dispatch(deleteTemplate(template));
		setIsRefreshDataLayout((prev) => prev + 1);
	};

	return (
		<PageLayout
			MenuClass={ConfigureEmailTemplatesMenu}
			subtitle={<>
				Send messages to candidates in a breeze by creating your personalized templates,<br />
				and share them with recruiters in your team.
			</>}
			rightActions={(
				<AddButton
					title='Create an email template'
					style={{ marginTop: 1 }}
					onClick={() => {
						setStep(isMasterTemplatesEnabled ? 0 : 1);
						setModalTitle('Create an email template');
						handleAddMessageTemplate();
					}}
				/>
			)}
		>
			<DataFetcherProvider
				limit={24}
				loadOnInit={false}
				path='/template/mail'
				withLoadMore={true}
			>
				<MessageTemplateTable
					// filterOptions={optionsFilterTable}
					isRefresh={isRefreshDataLayout}
					onEditTemplate={(template) => {
						setStep(1);
						setModalTitle('Edit an email template');
						handleAddMessageTemplate(template, 'edit');
					}}
					onCopyTemplate={(template) => {
						setStep(0);
						setModalTitle('Duplicate an email template');
						handleDuplicateMessageTemplate(template, 'duplicate');
					}}
					onCreateTemplate={(template) => {
						setStep(0);
						setModalTitle('Create an email template');
						handleAddMessageTemplate();
					}}
					onDeleteTemplate={onRemoveTemplate}
					isEditor={isAclEditor}
				/>
				<MessageTemplatePopover
					title={modalTitle}
					open={openMessageTemplatePopover}
					initialValues={initialValuesForm}
					onTemplateSelected={handleAddMessageTemplate}
					onReset={handleCloseMessageTemplatePopover}
					onSubmit={handleSaveMessageTemplate}
					templates={templates}
					setStep={setStep}
					setIsWithStartStep={setIsWithStartStep}
					isWithStartStep={isWithStartStep}
					step={step}
				/>
			</DataFetcherProvider>
		</PageLayout>
	);
};

export default ConfigureEmailTemplatesPage;
