import { ACL_ROLE_DOCUMENT_ADMIN_LABEL } from '../../../../constants/acls';
import { GENDERSCORE_LABEL, getAclRoleTag } from '../../../../constants/constant';
import FormItem from '../../form/FormItem';
import CompanyPlanGenderScoreOwnerIdSelect from './CompanyPlanGenderScoreOwnerIdSelect';

export const companyPlanGenderScoreOwnerIdPath = ['companyPlan', 'genderScore', 'ownerId'];

const CompanyPlanGenderScoreOwnerIdFormItem = ({
	required = false,
	disabled = false,
	company,
}) => {
	const className = 'CompanyPlanGenderScoreOwnerIdFormItem';

	return (
		<FormItem
			label={
				<span
					style={{
						...disabled ? { color: '#ccc' } : {},
					}}
				>
					{GENDERSCORE_LABEL} Survey owner
				</span>
			}
			style={{ marginBottom: 5 }}
			name={companyPlanGenderScoreOwnerIdPath}
			rules={[
				{
					required,
					message: 'This field is required',
				},
			]}
			extra={
				<>
					This user will be the <b>only one</b> allowed
					to fill the {GENDERSCORE_LABEL} survey.
					<br />The selection is restricted to {getAclRoleTag(ACL_ROLE_DOCUMENT_ADMIN_LABEL, { margin: 0 })} roles.
				</>
			}
		>
			<CompanyPlanGenderScoreOwnerIdSelect
				disabled={disabled}
				company={company}
				allowClear={true}
			/>
		</FormItem>
	);
};

export default CompanyPlanGenderScoreOwnerIdFormItem;
