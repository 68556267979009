import CONTENT_INTEREST from '../../property/content-interest';
import { innerObjPropExtractor } from '../../property/property-extractor';
import { innerObjPropMapper } from '../../property/property-mapper';

const USER_PROPERTY_MENTOR_SUBJECT_KEY = 'mentor-subject';

const USER_PROPERTY_MENTOR_SUBJECT_MULTI = true;
const USER_PROPERTY_MENTOR_SUBJECT_FREE = false;

const USER_PROPERTY_MENTOR_SUBJECT_FIELD = 'mentor.mentorSubject';
const USER_PROPERTY_MENTOR_SUBJECT_MAPPER = innerObjPropMapper(USER_PROPERTY_MENTOR_SUBJECT_FIELD);
const USER_PROPERTY_MENTOR_SUBJECT_EXTRACTOR = innerObjPropExtractor(USER_PROPERTY_MENTOR_SUBJECT_FIELD);

export default {
	...CONTENT_INTEREST,
	key: USER_PROPERTY_MENTOR_SUBJECT_KEY,
	USER_PROPERTY_MENTOR_SUBJECT_KEY,
	multi: USER_PROPERTY_MENTOR_SUBJECT_MULTI,
	USER_PROPERTY_MENTOR_SUBJECT_MULTI,
	free: USER_PROPERTY_MENTOR_SUBJECT_FREE,
	USER_PROPERTY_MENTOR_SUBJECT_FREE,
	mapper: USER_PROPERTY_MENTOR_SUBJECT_MAPPER,
	USER_PROPERTY_MENTOR_SUBJECT_MAPPER,
	extractor: USER_PROPERTY_MENTOR_SUBJECT_EXTRACTOR,
	USER_PROPERTY_MENTOR_SUBJECT_EXTRACTOR,
	field: USER_PROPERTY_MENTOR_SUBJECT_FIELD,
	USER_PROPERTY_MENTOR_SUBJECT_FIELD,
};
