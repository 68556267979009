import QUESTIONS from '../../questions';
import { GENDERSCORE_PILLAR_WORKLIFEBALANCE_OPTION } from '../../../../../constants/genderscore-pillars';

const section = {
	...GENDERSCORE_PILLAR_WORKLIFEBALANCE_OPTION,
	details: `
### For 50inTech's women community, flexibility at work is their main requirement when choosing a company.

The quality of your work-life balance policy is key to attracting and retaining women in Tech.`,
	questions: [
		QUESTIONS.WORKLIFE_REMOTE,
		QUESTIONS.WORKLIFE_FLEXIBLEHOURS,
		QUESTIONS.WORKLIFE_FLEXIBLEHOLIDAYS,
		QUESTIONS.WORKLIFE_UNLIMITEDHOLIDAYS,
		QUESTIONS.WORKLIFE_PARENTALPAIDLEAVE,
		QUESTIONS.WORKLIFE_EXTRABENEFITS,
	],
};

export default section;
