import { Modal as AntModal, Typography } from 'antd';
import { FaBan, FaCheck, FaRegTimesCircle } from 'react-icons/fa';
import { useTheme } from '@emotion/react';

const Modal = (
	{
		open,
		title = '',
		okText = 'Yes',
		cancelText = 'No',
		content = '',
		onConfirm,
		onCancel,
		children,
		...props
	},
) => {
	const theme = useTheme();

	const styleText = {
		display: 'flex', fontSize: '14px', fontWeight: 'bold', alignItems: 'center',
	};

	const okTextComponent = <Typography style={{ ...styleText, color: theme.color.darkturquoise }}>
		<FaCheck size={16} style={{ marginRight: 5 }}/> {okText}
	</Typography>;

	const cancelTextComponent = <Typography style={{ ...styleText, color: theme.color.orange }}>
		<FaBan size={16} style={{ marginRight: 5 }}/> {cancelText}
	</Typography>;

	return (
		<AntModal
			title={title}
			open={open}
			onOk={onConfirm}
			onCancel={onCancel}
			// bodyStyle={{ fontSize: 16 }}
			okText={okTextComponent}
			cancelText={cancelTextComponent}
			closeIcon={<FaRegTimesCircle size={24}/>}
			{...props}
		>
			{content}
			{children}
		</AntModal>
	);
};

export default Modal;
