import FormItem from '../../company/form/FormItem';
import {
	Col, Row, Tag,
} from 'antd';
import { useEffect, useState } from 'react';
import { LocalDebug } from '../../../utils/LocalDebug';
import LocationSelector from './LocationSelector';
import {
	LOCATION_CITY,
	LOCATION_CONTINENT,
	LOCATION_COUNTRY,
	USER_TAG_JOB_SEARCH_JOB_LOCATION,
	USER_TAG_LOCATION,
	getJobLocationLabel,
} from '../../../constants/constant';
import { useSelector } from 'react-redux';
import { getTagsUsers } from '../../../reducers/app';
import { FaTimes } from 'react-icons/fa';
import { useTheme } from '@emotion/react';

const LocationFilter = (
	{
		form,
		parent,
		labelColSpan = 6,
		initialValues,
		withDesiredLocation = false,
		onChange,
		onOpen,
		onClose,
		styles = {},
		toggleLocationSelector,
		setToggleLocationSelector,
	},
) => {
	const className = 'LocationFilter';
	const theme = useTheme();
	const [locationTags, setLocationTags] = useState([]);
	const formLocationField = withDesiredLocation
		? USER_TAG_JOB_SEARCH_JOB_LOCATION
		: USER_TAG_LOCATION;
	const fieldName = [...parent || [], formLocationField?.value];

	const tagCategoryContinent = LOCATION_CONTINENT;
	const tagCategoryCountry = LOCATION_COUNTRY;
	const tagCategoryCity = LOCATION_CITY;

	const tags = useSelector(getTagsUsers);
	const continentTags = tags.find(
		(tag) => tag.category === tagCategoryContinent.tagValue,
	)?.items?.filter((tag) => tag.value !== 'anywhere') || [];
	const countryTags = tags.find(
		(tag) => tag.category === tagCategoryCountry.tagValue,
	)?.items || [];
	const cityTags = tags.find(
		(tag) => tag.category === tagCategoryCity.tagValue,
	)?.items || [];

	const onLocationSelected = (tag) => {
		LocalDebug.logInfo({ className, method: 'onLocationSelected' }, { tag, formLocationField, locationTags });

		if (tag) {
			const newLocationTags = [
				...locationTags || [],
				{
					...tag,
					label: getJobLocationLabel({
						value: tag.value,
						withCountryFlag: true,
						withCityCountry: true,
						withCityCountryCode: true,
						withCityCountryFlag: true,
					}),
				},
			];
			form.setFieldValue(
				fieldName,
				newLocationTags.map((t) => t?.value),
			);
			setLocationTags(newLocationTags);
		}
		onChange?.();
	};

	const onRemoveLocationTag = (tag) => {
		LocalDebug.logInfo({ className, method: 'onRemoveLocationTag' }, { tag, locationTags });

		if (tag) {
			const newLocationTags = locationTags.filter((v) => v.value !== tag);
			form.setFieldValue(
				fieldName,
				newLocationTags.map((t) => t?.value),
			);
			setLocationTags(newLocationTags);
		}

		onChange?.();
	};

	useEffect(() => {
		form.setFieldValue(
			fieldName,
			initialValues,
		);
		const newLocationTags = initialValues
			?.map((value) => ({
				label: getJobLocationLabel({
					value,
					withCountryFlag: true,
					withCityCountry: true,
					withCityCountryCode: true,
					withCityCountryFlag: true,
				}),
				value,
			}));
		setLocationTags(newLocationTags);
	}, [form, initialValues]);

	return (
		<div style={{ ...styles }}>
			<Row style={{ marginBottom: -20 }}>
				<Col span={24}>

					<FormItem
						key={formLocationField.value}
						labelCol={{ span: labelColSpan }}
						label={formLocationField.label}
						labelAlign='right'
						name={fieldName}
						style={{ width: '100%' }}
						shouldUpdate
					>
						<div
							style={{
								border: '1px solid',
								padding: '3px',
								paddingBottom: 0,
								borderColor: 'rgb(217, 217, 217)',
								borderRadius: 2,
								width: '100%',
								minHeight: 30,
								maxHeight: 70,
								overflow: 'auto',
							}}
						>
							{locationTags
						&& locationTags?.map(
							(v) => (
								<Tag
									key={v?.value}
									closable={false}
									style={{
										color: theme.color.fitBlueElectric,
										fontSize: 13,
										margin: '0px 2px 4px 2px',
										padding: '2px 4px 2px 8px',
										border: `1px solid ${theme.color.fitBlueElectric}`,
										backgroundColor: 'white',
									}}
								>
									{v?.label}
									&nbsp;
									<FaTimes
										onClick={() => onRemoveLocationTag(v?.value)}
										style={{
											marginBottom: -2,
											color: theme.color.fitBlueElectric,
											cursor: 'pointer',
										}}
									/>
								</Tag>),
						)
							}
						</div>
					</FormItem>
				</Col>
			</Row>
			<Row justify={'end'}>
				<Col span={24 - labelColSpan}>
					<LocationSelector
						onSelect={onLocationSelected}
						form={form}
						toggle={toggleLocationSelector}
						setToggle={setToggleLocationSelector}
						prevValues={locationTags}
						withDesiredLocation={withDesiredLocation}
						continentTags={continentTags}
						countryTags={countryTags}
						cityTags={cityTags}
						parentCategory={formLocationField.value}
						tagCategoryContinent={tagCategoryContinent}
						tagCategoryCountry={tagCategoryCountry}
						tagCategoryCity={tagCategoryCity}
						onOpen={onOpen}
						onClose={onClose}
					/>
				</Col>
			</Row>
		</div>
	);
};

export default LocationFilter;
