import { useTheme } from '@emotion/react';
import PageLayout from '../../../components/app/layout/PageLayout';
import GenderScoreAnswers from './GenderScoreAnswers';
import SurveyNavigationProvider from '../../../components/genderscore/survey/SurveyNavigationProvider';
import CompanyGridPicker from '../../../components/company/CompanyGridPicker';
import { useSelector } from 'react-redux';
import { getCompanySelected } from '../../../reducers/app';
import GenderScoreSurveyAnswersMenu from '../../../menu/genderscore/GenderscoreSurveyAnswersMenu';

const GenderScoreAnswersPage = () => {
	const className = 'GenderScoreAnswersPage';
	const theme = useTheme();

	const companySelected = useSelector(getCompanySelected);

	if (!companySelected) return <CompanyGridPicker />;

	const menu = (new GenderScoreSurveyAnswersMenu()).build();

	return (
		<PageLayout
			icon={menu.icon}
			title={menu.label}
		>
			<SurveyNavigationProvider
				withLastVisitedPath={false}
			>
				<GenderScoreAnswers />
			</SurveyNavigationProvider>

		</PageLayout>
	);
};

export default GenderScoreAnswersPage;
