import CampaignEmails from '../../../containers/staff/CampaignEmails';
import { onEnterCampaignEmails } from '../../../actions/app';
import { PERMS_GRANT_STAFF } from '../../menu.utils';
import MenuBase from '../../MenuBase';
import { MailOutlined } from '@ant-design/icons';

export const StaffMarketingCampaignEmailsIcon = MailOutlined;

class StaffMarketingCampaignEmailsMenu extends MenuBase {
	static props = {
		name: 'staff-marketing-campaign-emails',
		path: '/staff/marketing/campaign-emails',
		label: 'Campaign emails',
		icon: <StaffMarketingCampaignEmailsIcon />,
		iconLegacy: '📨',
		Component: CampaignEmails,
		onEnter: onEnterCampaignEmails,
		perms: PERMS_GRANT_STAFF,
	};

	constructor() {
		super(StaffMarketingCampaignEmailsMenu.props);
	}
}

export default StaffMarketingCampaignEmailsMenu;
