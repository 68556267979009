import { isStringTypeValidator } from '../../../utils/isStringTypeValidator';
import { innerObjPropExtractor } from '../../property/property-extractor';
import { innerObjPropMapper } from '../../property/property-mapper';

const USER_PROPERTY_CAREER_IS_REACHABLE_BY_RECRUITERS_KEY = 'career-is-reachable-by-recruiters';

const USER_PROPERTY_CAREER_IS_REACHABLE_BY_RECRUITERS_FREE = false;
const USER_PROPERTY_CAREER_IS_REACHABLE_BY_RECRUITERS_ANSWER_YES = true;
const USER_PROPERTY_CAREER_IS_REACHABLE_BY_RECRUITERS_ANSWER_NO = false;

const USER_PROPERTY_CAREER_IS_REACHABLE_BY_RECRUITERS_OPTIONS = [
	{ value: USER_PROPERTY_CAREER_IS_REACHABLE_BY_RECRUITERS_ANSWER_YES, label: 'Yes' },
	{ value: USER_PROPERTY_CAREER_IS_REACHABLE_BY_RECRUITERS_ANSWER_NO, label: 'No' },
];

const USER_PROPERTY_CAREER_IS_REACHABLE_BY_RECRUITERS_VALUES = USER_PROPERTY_CAREER_IS_REACHABLE_BY_RECRUITERS_OPTIONS.map((o) => o.value);

const customValidator = (value) => USER_PROPERTY_CAREER_IS_REACHABLE_BY_RECRUITERS_VALUES.includes(value);

const USER_PROPERTY_CAREER_IS_REACHABLE_BY_RECRUITERS_VALIDATORS = [
	isStringTypeValidator,
	customValidator,
];

const USER_PROPERTY_CAREER_IS_REACHABLE_BY_RECRUITERS_FIELD = 'career.isReachableByRecruiters';
const USER_PROPERTY_CAREER_IS_REACHABLE_BY_RECRUITERS_MAPPER = innerObjPropMapper(USER_PROPERTY_CAREER_IS_REACHABLE_BY_RECRUITERS_FIELD);
const USER_PROPERTY_CAREER_IS_REACHABLE_BY_RECRUITERS_EXTRACTOR = innerObjPropExtractor(USER_PROPERTY_CAREER_IS_REACHABLE_BY_RECRUITERS_FIELD);

export default {
	key: USER_PROPERTY_CAREER_IS_REACHABLE_BY_RECRUITERS_KEY,
	USER_PROPERTY_CAREER_IS_REACHABLE_BY_RECRUITERS_KEY,
	validators: USER_PROPERTY_CAREER_IS_REACHABLE_BY_RECRUITERS_VALIDATORS,
	USER_PROPERTY_CAREER_IS_REACHABLE_BY_RECRUITERS_VALIDATORS,
	options: USER_PROPERTY_CAREER_IS_REACHABLE_BY_RECRUITERS_OPTIONS,
	USER_PROPERTY_CAREER_IS_REACHABLE_BY_RECRUITERS_OPTIONS,
	free: USER_PROPERTY_CAREER_IS_REACHABLE_BY_RECRUITERS_FREE,
	USER_PROPERTY_CAREER_IS_REACHABLE_BY_RECRUITERS_FREE,
	mapper: USER_PROPERTY_CAREER_IS_REACHABLE_BY_RECRUITERS_MAPPER,
	USER_PROPERTY_CAREER_IS_REACHABLE_BY_RECRUITERS_MAPPER,
	extractor: USER_PROPERTY_CAREER_IS_REACHABLE_BY_RECRUITERS_EXTRACTOR,
	USER_PROPERTY_CAREER_IS_REACHABLE_BY_RECRUITERS_EXTRACTOR,
	field: USER_PROPERTY_CAREER_IS_REACHABLE_BY_RECRUITERS_FIELD,
	USER_PROPERTY_CAREER_IS_REACHABLE_BY_RECRUITERS_FIELD,
};
