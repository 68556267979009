import { LocalDebug } from '../utils/LocalDebug';
import { request } from './api';

const className = 'UserMailingService';

const refreshMailjetData = ({ mailingId, force = false } = { force: false }) => {
	LocalDebug.logAPICall({ className, method: 'refreshMailjetData' }, { mailingId, force });
	return request.get(`/user-mailing/${mailingId}/refresh-mailjet-data?force=${force}`);
};

const retrySend = ({ mailingId }) => {
	LocalDebug.logAPICall({ className, method: 'retrySend' }, { mailingId });
	return request.get(`/user-mailing/${mailingId}/retry-send`);
};

const UserMailingService = {
	refreshMailjetData,
	retrySend,
};

export default UserMailingService;
