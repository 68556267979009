import dayjs from 'dayjs';
import CompanyModel from '../../../../../models/CompanyModel';
import { documentId } from '../../../../../utils/common';
import GenderScoreRankingBlock from '../blocks/GenderScoreRankingBlock';
import { LocalDebug } from '../../../../../utils/LocalDebug';

export const RANKING_GLOBALSCORE = 'RANKING_GLOBALSCORE';
export const RANKING_SECTOR = 'RANKING_SECTOR';
export const RANKING_HEADCOUNT = 'RANKING_HEADCOUNT';

const extractRank = ({ list, target }) => list
	?.find?.((item) => documentId(item)?.toString?.() === documentId(target)?.toString?.())
	?.rank;

const getGenderScoreRankings = ({ mapper, company }) => {
	const rankings = [
		...company?.genderScore?.monthlyStatistics
			?.map((stat) => ({
				month: dayjs(stat.date).format('MMM').toUpperCase(),
				rank: extractRank({ list: mapper(stat?.rankings), target: company }),
			})) || [],
	].reverse();
	return rankings.filter((r) => r.rank);
};

const CompanyGenderScoreRankingFromSurveyBox = ({
	company,
	version,
	title,
	width,
	...props
}) => {
	const className = 'CompanyGenderScoreRankingFromSurveyBox';

	const companyModel = new CompanyModel(company);
	if (!companyModel.genderScore) {
		return null;
	}

	let mapper;
	let versionTitle;
	const survey = companyModel.getGenderScoreCurrentSurvey();
	const sector = survey?.companyInfo?.sector;
	const headcountGroup = survey?.companyInfo?.headcountGroup;

	switch (version) {
		case RANKING_SECTOR:
			mapper = (item) => item?.sectors?.[sector];
			versionTitle = sector;
			break;
		case RANKING_HEADCOUNT:
			mapper = (item) => item?.headcounts?.[headcountGroup];
			versionTitle = `${headcountGroup} employees`;
			break;
		default:
			mapper = (item) => item?.globalScore;
			versionTitle = 'All GenderScore';
			break;
	}
	const value = extractRank({
		list: mapper(company?.genderScore?.currentStatistics?.rankings || []),
		target: company,
	});
	const progress = getGenderScoreRankings({ mapper, company });

	const compareValue = extractRank({
		list: mapper(survey?.statistics?.rankings),
		target: company,
	});

	return (
		<GenderScoreRankingBlock
			value={value}
			progress={progress}
			title={title || versionTitle}
			compareValue={compareValue}
			width={width}
			{...props}
			compareSecondaryLabel={'vs submission'}
		/>
	);
};

export default CompanyGenderScoreRankingFromSurveyBox;
