import moment from 'moment';
import {
	bold,
	regular,
	PDF_PAGE_TEMPLATE_COVER,
	PDF_TEXT_STYLES,
	PDF_TYPE_DIVIDER,
	PDF_TYPE_TEXT,
	PDF_TYPE_GRID,
	PDF_TYPE_IMAGE,
	PDF_TYPE_MOVE,
} from '../base/PDFBuilder';
import { LocalDebug } from '../../../../../utils/LocalDebug';

const PageCoverManager = async ({
	doc,
	company,
	manager,
	date,
	context,
	skip,
}) => {
	const className = 'PageCoverManager';

	LocalDebug.logInfo({ className });

	return [
		{
			items: [
				...await doc.getPageTemplateCover({ context, skip }),
				{
					type: PDF_TYPE_GRID,
					gridTemplateColumns: [1, 1],
					columnGap: 0,
					x: 10,
					y: 0,
					columns: [
						[
							{
								type: PDF_TYPE_TEXT,
								text: context?.umsResult?.company?.name || company.name,
								...bold(PDF_TEXT_STYLES.title2),
								fontSize: 20,
								color: '#ffffff',
							},
						],
						[
							{
								type: PDF_TYPE_IMAGE,
								uri: await doc.makeImageUri({ id: context.logoId, context, skip }),
								width: 72 * 0.4,
								height: 29 * 0.4,
								x: doc.pdfWidth - doc.pagePadding.right - 72 * 0.4,
							},
						],
					],
				},
				{
					type: PDF_TYPE_TEXT,
					text: {
						en: 'UNBIASED\nMANAGEMENT\nSCORE',
						fr: 'UNBIASED\nMANAGEMENT\nSCORE',
					},
					...bold(PDF_TEXT_STYLES.title),
					fontSize: 82,
					color: '#ffffff',
					marginTop: 70,
				},
				{
					type: PDF_TYPE_TEXT,
					text: {
						en: 'Manager results',
						fr: 'Résultats Manager',
					},
					...bold(PDF_TEXT_STYLES.normal),
					fontSize: 32,
					color: '#ffffff',
				},
				{
					type: PDF_TYPE_DIVIDER,
					color: '#ffffff',
					marginBottom: 8,
					y: doc.pdfHeight - doc.pagePadding.bottom - 22,
				},
				{
					type: PDF_TYPE_GRID,
					gridTemplateColumns: [1, 1],
					columnGap: 0,
					columns: [
						[
							{
								type: PDF_TYPE_TEXT,
								text: {
									en: 'Prepared for',
									fr: 'Preparé pour',
								},
								...regular(PDF_TEXT_STYLES.small),
								color: '#ffffff',
							},
							{
								type: PDF_TYPE_TEXT,
								text: (context?.umsResult?.user
									? [context?.umsResult?.user?.firstName, context?.umsResult?.user?.lastName]
									: [manager.firstName, manager.lastName]
								).join(' '),
								...bold(PDF_TEXT_STYLES.title2),
								fontSize: 20,
								color: '#ffffff',
								options: { baseline: 'bottom' },
								y: doc.pdfHeight - doc.pagePadding.bottom,
							},
						],
						[
							{
								type: PDF_TYPE_TEXT,
								text: (date ? moment(date) : moment()).format('DD/MM/YYYY'),
								...bold(PDF_TEXT_STYLES.title2),
								fontSize: 20,
								color: '#ffffff',
								options: {
									align: 'right',
									baseline: 'bottom',
								},
								x: doc.pdfWidth - doc.pagePadding.right,
								y: doc.pdfHeight - doc.pagePadding.bottom,
							},
						],
					],
				},
			],
		},
	];
};

export default PageCoverManager;
