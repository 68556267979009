import CompanySettingsFormBase from './CompanySettingsFormBase';
import { useState } from 'react';
import FormBoxTitle from '../../app/form/FormBoxTitle';
import FormItem from '../form/FormItem';
import { Divider, Form } from 'antd';
import FormSubmitButton from '../../app/form/FormSubmitButton';
import BlockStaff from '../../app/blocker/BlockStaff';
import CompanyModel from '../../../models/CompanyModel';
import { UMS_EMPTY_ICON_IMG, UMS_LABEL, UMS_LABEL_SHORT } from '../../../constants/constant';
import CompanyPlanUMSSurveyManagerFormItem from '../companyPlan/ums/CompanyPlanUMSSurveyManagerFormItem';
import CompanyPlanUMSSurveyEmployeeFormItem from '../companyPlan/ums/CompanyPlanUMSSurveyEmployeeFormItem';
import CompanyPlanUMSIsEnabledFormItem, { companyPlanUMSIsEnabledPath } from '../companyPlan/ums/CompanyPlanUMSIsEnabledFormItem';
import { innerObjPropExtractor } from '../../../constants/property/property-extractor';
import { innerObjPropMapper } from '../../../constants/property/property-mapper';
import CompanyPlanUMSPurchasedAtFormItem, {
	companyPlanUMSPurchasedAtPath,
} from '../companyPlan/ums/CompanyPlanUMSPurchasedAtFormItem';
import dayjs from 'dayjs';
import CompanyPlanUMSSurveyIsEnabledFormItem, { companyPlanUMSSurveyIsEnabledPath } from '../companyPlan/ums/CompanyPlanUMSSurveyIsEnabledFormItem';
import { useTheme } from '@emotion/react';

const CompanySettingsUMSFormDivider = ({
	title,
}) => {
	const theme = useTheme();

	return (
		<Divider style={{
			marginBottom: 15,
			marginTop: 5,
		}}>
			<span style={{
				fontSize: 13,
				color: theme.color.darkgrey,
				fontWeight: 600,
			}}>
				{title}
			</span>
		</Divider>
	);
};

const CompanySettingsUMSForm = (
	{
		company,
		onSuccess,
		labelCol = { span: 10 },
		wrapperCol = { span: 14 },
		children,
		...props
	},
) => {
	const className = 'CompanySettingsUMSForm';
	const theme = useTheme();

	const companyModel = new CompanyModel(company);

	const [form] = Form.useForm();
	const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
	const [isUMSEnabled, setIsUMSEnabled] = useState(companyModel.isUMSEnabled());

	let companyFormValues = innerObjPropMapper(companyPlanUMSIsEnabledPath)(
		company,
		companyModel.isUMSEnabled(),
	);
	// Format dates before passing them to the form items
	if (innerObjPropExtractor(companyPlanUMSPurchasedAtPath)(company)) {
		companyFormValues = innerObjPropMapper(companyPlanUMSPurchasedAtPath)(
			companyFormValues,
			dayjs(innerObjPropExtractor(companyPlanUMSPurchasedAtPath)(company)),
		);
	}

	const [initialValues, setInitialValues] = useState(companyFormValues);

	return (
		<BlockStaff
			withBorder={false}
			tagStyle={{
				top: 5,
				left: 0,
				opacity: 1,
			}}
			id='ums-settings'
		>
			<FormBoxTitle
				title={(
					<div
						style={{
							display: 'flex',
							alignItems: 'center',
						}}
					>
						{UMS_EMPTY_ICON_IMG()}&nbsp;{UMS_LABEL}
					</div>
				)}
				details={<>Select the {UMS_LABEL} settings for this company</>}
			/>

			{children}

			<CompanySettingsFormBase
				form={form}
				formName='company-settings-ums-form'
				company={initialValues}
				labelCol={labelCol}
				wrapperCol={wrapperCol}
				labelAlign='left'
				layout='horizontal'
				setIsSubmitDisabled={setIsSubmitDisabled}
				onSuccess={onSuccess}
				hiddenValues={{
					forceRecompute: false,
					shouldUpdateTags: false,
					shouldUpdateInducedTags: false,
				}}
				onFormValuesChanged={(values) => {
					const umsEnabled = innerObjPropExtractor(companyPlanUMSIsEnabledPath)(values);
					if ([true, false].includes(umsEnabled)) setIsUMSEnabled(umsEnabled);
				}}
				style={{
					overflow: 'auto',
				}}
			>

				<CompanyPlanUMSIsEnabledFormItem />

				{isUMSEnabled && (
					<>
						<CompanyPlanUMSPurchasedAtFormItem
							required={true}
							form={form}
						/>
						<CompanySettingsUMSFormDivider
							title={`${UMS_LABEL_SHORT} Survey`}
						/>
						<CompanyPlanUMSSurveyManagerFormItem
							required={true}
						/>
						<CompanyPlanUMSSurveyEmployeeFormItem
							required={true}
						/>
						<CompanyPlanUMSSurveyIsEnabledFormItem
							initialValue={
								innerObjPropExtractor(companyPlanUMSSurveyIsEnabledPath)(initialValues)
							}
						/>

						{/* <CompanySettingsUMSFormDivider
							title={`${UMS_LABEL} Results`}
						/> */}
					</>
				)}

				{/* <FormItem
					label={<>&nbsp;</>}
					labelCol={{ span: 10 }}
					style={{ marginBottom: 0, marginTop: 20 }}
				> */}
				<FormSubmitButton
					disabled={isSubmitDisabled}
					style={{ width: '100%', marginTop: 20 }}
					size={'large'}
				>
						Save {UMS_LABEL_SHORT} settings
				</FormSubmitButton>
				{/* </FormItem> */}

			</CompanySettingsFormBase>
		</BlockStaff>
	);
};

export default CompanySettingsUMSForm;
