import Drawer from '../app/drawer/Drawer';
import UserProfile from './profile/UserProfile';
import {
	useContext, useEffect, useRef, useState,
} from 'react';
import UserHeader from './widgets/UserHeader';
import UserService from '../../services/user';
import { FaRegTimesCircle } from 'react-icons/fa';
import UserModel from '../../models/UserModel';
import { useSelector } from 'react-redux';
import { getCompanySelected } from '../../reducers/app';
import { LocalDebug } from '../../utils/LocalDebug';
import { UserStoreContext } from '../../contexts/UserStoreProvider';
import { documentId } from '../../utils/common';

const UserDrawerProfile = (
	{
		open,
		title,
		width = 900,
		user,
		activeTab,
		onClose,
		onRefresh,
		...props
	},
) => {
	const className = 'UserDrawerProfile';

	const container = useRef();
	const companySelected = useSelector(getCompanySelected);
	const { fetchUser: dataFetchUser } = useContext(UserStoreContext);

	const [isLoading, setIsLoading] = useState(false);
	const [userData, setUserData] = useState(null);
	const [isRefresh, setIsRefresh] = useState(0);

	const fetchUser = async (userId) => {
		LocalDebug.logNull({ className, method: 'fetchUser' }, { userId });
		setUserData(await dataFetchUser(userId));
		LocalDebug.logNull({ className, method: 'fetchUser' }, 'user fetched');
		setIsLoading(false);
	};

	useEffect(() => {
		if (!open) {
			return;
		}

		if (!user) {
			setUserData(null);
			setIsLoading(false);
			return;
		}
		if (!isLoading) {
			setIsLoading(true);
			fetchUser(documentId(user, user?.slug));
		}
	}, [user]);

	const handleClose = () => {
		setUserData(null);
		if (onClose instanceof Function) onClose();
	};

	const handleRefresh = () => {
		fetchUser(documentId(user, user?.slug));
		setIsRefresh((prev) => prev + 1);
		onRefresh?.();
	};

	const contentAfterOpen = <>
		<div ref={container}>
			<UserProfile
				user={userData}
				withinDrawer={true}
				activeTab={activeTab}
				isRefresh={isRefresh}
			/>
		</div>
	</>;

	return (userData && !isLoading
		&& <Drawer
			title={title || <UserHeader user={userData} onRefresh={handleRefresh}/>}
			width={width}
			className="withAffix"
			onClose={handleClose}
			closeIcon={<FaRegTimesCircle size={24} style={{ marginTop: 10 }}/>}
			headerStyle={{ align: 'start', justifyContent: 'start', alignItems: 'start' }}
			open={open && userData && !isLoading}
			contentAfterOpen={contentAfterOpen}
			{...props}
		/>
	);
};

export default UserDrawerProfile;
