import { ADMIN_TOOLS_ICON, SEARCH_ICON } from '../../../../constants/constant';
import { Space, Switch, message } from 'antd';
import InlineNavLink from '../../../app/button/InlineNavLink';
import { FaChevronRight } from 'react-icons/fa';
import { StaffManagerPanelDivider, StaffManagerPanelLink } from '../StaffManagerPanel';
import { StaffToolsContext } from '../../../../contexts/StaffToolsProvider';
import { useContext } from 'react';
import config from '../../../../config/config';
import AdminService from '../../../../services/admin';
import { getCompanySelected } from '../../../../reducers/app';
import { documentId } from '../../../../utils/common';
import { useSelector } from 'react-redux';
import { useAuthContext } from '../../../../hooks/useAuthContext';
import { messageResultBuilder } from '../../../../actions/utils';
import { useNavigate } from 'react-router';
import StaffToolsMenu from '../../../../menu/staff/StaffToolsMenu';
import StaffCompaniesRecruiterSearchesMenu from '../../../../menu/staff/companies/StaffCompaniesRecruiterSearchesMenu';

const emailTo = config.isDevEnv ? config.mailjet.localhost_email : config.mailjet.test_email;
const currentEnv = config.isDevEnv ? 'your LOCAL environment' : 'STAGING';

const StaffManagerSearchTalentsTab = () => {
	const className = 'StaffManagerSearchTalentsTab';
	const companySelected = useSelector(getCompanySelected);
	const { currentUser } = useAuthContext();
	const {
		savedSearchWithJobEnabled,
		setSavedSearchWithJobEnabled,
		isSavedSearchTabsEnabled,
		setIsSavedSearchTabsEnabled,
		isSavedSearchExcludeViewedEnabled,
		setIsSavedSearchExcludeViewedEnabled,
		isUserGridCardSuggestedJobsInline,
		setIsUserGridCardSuggestedJobsInline,
	} = useContext(StaffToolsContext);
	const navigate = useNavigate();

	const handleClearAlerts = async (user) => {
		const { data } = await AdminService
			.clearSavedSearchesAlerts({ userIds: [documentId(user)] });
		if (data?.success) {
			message.success(
				<>
					Alerts were cleared. Please refresh the page.
					{messageResultBuilder(data)}
				</>,
			);
			// navigate('/'); // Refresh the page
		} else {
			message.error('An error occurred');
		}
	};
	const handleFakeAlerts = async (user) => {
		const { data } = await AdminService
			.fakeSavedSearchesAlerts({ userIds: [documentId(user)] });
		if (data?.success) {
			message.success(
				<>
					Alerts were faked. Please refresh the page.
					{messageResultBuilder(data)}
				</>,
			);
			// navigate('/'); // Refresh the page
		} else {
			message.error('An error occurred');
		}
	};

	const handleComputeAlerts = async (user) => {
		const { data } = await AdminService
			.computeSavedSearchesAlerts(user ? { userIds: [documentId(user)] } : {});
		if (data?.success) {
			message.success(
				<>
					Alerts were computed. Please refresh the page.
					{messageResultBuilder(data)}
				</>,
			);
			// navigate('/'); // Refresh the page
		} else {
			message.error('An error occurred');
		}
	};

	const handleSendDigest = async () => {
		const { data } = await AdminService.computeSavedSearchesDigests({
			filterRecruiterId: documentId(currentUser),
		});
		if (data?.success) {
			message.success(messageResultBuilder(data));
		} else {
			message.error('An error occurred');
		}
	};
	const handleSendAllFakeDigests = async () => {
		const { data } = await AdminService.sendFakeSavedSearchDigests({
			filterRecruiterId: documentId(currentUser),
		});
		if (data?.success) {
			message.success(messageResultBuilder(data));
		} else {
			message.error('An error occurred');
		}
	};
	return (
		<Space
			direction='vertical'
			size={4}
			style={{
				width: '100%',
				padding: '0 15px 15px',
				fontSize: 12,
			}}
		>

			<StaffManagerPanelDivider>
					UI & Features
			</StaffManagerPanelDivider>

			<div>
				<Switch
					size='small'
					checked={savedSearchWithJobEnabled}
					onChange={() => setSavedSearchWithJobEnabled((p) => !p)}
				/>
					&nbsp;&nbsp;Can create search from job
			</div>

			<div>
				<Switch
					size='small'
					checked={isSavedSearchTabsEnabled}
					onChange={() => setIsSavedSearchTabsEnabled((p) => !p)}
				/>
					&nbsp;&nbsp;Display tabs
			</div>

			<div>
				<Switch
					size='small'
					checked={isSavedSearchExcludeViewedEnabled}
					onChange={() => setIsSavedSearchExcludeViewedEnabled((p) => !p)}
				/>
					&nbsp;&nbsp;Can exclude viewed profiles
			</div>

			<div>
				<Switch
					size='small'
					checked={isUserGridCardSuggestedJobsInline}
					onChange={() => setIsUserGridCardSuggestedJobsInline((p) => !p)}
				/>
					&nbsp;&nbsp;Suggested jobs displayed inline
			</div>

			<StaffManagerPanelDivider>
					Digests
			</StaffManagerPanelDivider>
			<StaffManagerPanelLink
				label={<b>Fake alerts for current user</b>}
				onClick={() => handleFakeAlerts(currentUser)}
				confirm={<>
					This will create some fake alerts for
					one of the saved searches of the current logged user
				</>}
			/>
			<StaffManagerPanelLink
				label={<b>Clear alerts for current user</b>}
				onClick={() => handleClearAlerts(currentUser)}
				confirm={<>
					This will clear all the alerts for
					all the saved searches of the current logged user
				</>}
			/>
			<StaffManagerPanelLink
				label={<b>Clear alerts for all users</b>}
				onClick={() => handleClearAlerts()}
				confirm={<>
					This will clear all the alerts for
					all the saved searches of all users
				</>}
			/>

			<StaffManagerPanelLink
				label={<b>Recompute alerts for current user</b>}
				onClick={() => handleComputeAlerts(currentUser)}
				confirm={<>
					This will recalculate all the alerts for
					all the saved searches of the current userIdFilter
				</>}
			/>
			<StaffManagerPanelLink
				label={<b>Recompute alerts for all users</b>}
				onClick={() => handleComputeAlerts()}
				confirm={<>
					This will recalculate all the alerts for
					all the saved searches of all the users of {currentEnv}
				</>}
			/>
			<StaffManagerPanelLink
				label={<b>Send Saved Searches Digest for the current scenario</b>}
				onClick={() => handleSendDigest()}
				confirm={<>
					This will send a digest to {emailTo} for the
					current logged user ({currentUser?.firstName} {currentUser?.lastName})
					and the real scenario
				</>}
			/>
			<StaffManagerPanelLink
				label={<b>Send Fake Saved Searches Digests</b>}
				onClick={() => handleSendAllFakeDigests()}
				confirm={<>
					This will send 10 fake digests to {emailTo}: 2 per each category (with and without suggestions) and 2 more for the NO_SAVED_SEARCH, with no talents list
				</>}
			/>
			<StaffManagerPanelDivider>
					Links
			</StaffManagerPanelDivider>

			{(new StaffToolsMenu()).getInlineNavLink({
				withChevronRight: true,
				withUnderline: false,
			})}

			{(new StaffCompaniesRecruiterSearchesMenu()).getInlineNavLink({
				withChevronRight: true,
				withUnderline: false,
			})}

		</Space>
	);
};

export default StaffManagerSearchTalentsTab;
