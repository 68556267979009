import { Tooltip, Typography, message } from 'antd';

const ObjectIdDisplayer = (
	{
		id,
		prefixLength = 4,
		suffixLength = 4,
		separator = '...',
		withSlice = true,
		withFilter = false,
		withTooltip = false,
		onMouseEnter,
		onMouseLeave,
		onFocus,
		onClick,
		...props
	},
) => {
	const text = (
		<Typography.Text
			onMouseEnter={onMouseEnter}
			onMouseLeave={onMouseLeave}
			onFocus={onFocus}

			type='secondary'
			onClick={(e) => {
				navigator.clipboard.writeText(id);
				message.success(`ID '${id}' copied to clipboard`);
				onClick?.(withFilter ? id : e);
			}}
			style={{ cursor: withFilter ? 'pointer' : 'copy', ...props?.style }}
		>
			{withSlice
				? id?.toString().slice(0, prefixLength) + separator + id?.toString().slice(-suffixLength)
				: id}

		</Typography.Text>
	);

	if (withTooltip) {
		return (
			<Tooltip title={id}>
				{text}
			</Tooltip>
		);
	}

	return text;
};

export default ObjectIdDisplayer;
