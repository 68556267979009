import { useSelector } from 'react-redux';
import PageLayout from '../../components/app/layout/PageLayout';
import { useTheme } from '@emotion/react';
import { getCompanySelected } from '../../reducers/app';
import CompanyGridPicker from '../../components/company/CompanyGridPicker';
import UMSAdminResults from '../../components/ums/pages/UMSAdminResults';
import UMSAdminResultsMenu from '../../menu/ums/UMSAdminResultsMenu';

const UMSAdminResultsPage = () => {
	const className = 'UMSAdminResultsPage';

	const theme = useTheme();

	const companySelected = useSelector(getCompanySelected);

	if (!companySelected) return <CompanyGridPicker />;

	const menu = (new UMSAdminResultsMenu()).build();

	return (
		<PageLayout
			icon={menu.icon}
			title={menu.label}
		>
			<UMSAdminResults />
		</PageLayout>
	);
};

export default UMSAdminResultsPage;
