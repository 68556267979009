import { Space, Tabs } from 'antd';
import PageLayout from '../../../components/app/layout/PageLayout';
import SectionTitle from '../../../components/app/SectionTitle';
import { useState } from 'react';
import { ComputeFunnelsButton } from '../StaffToolsPage';
import FunnelUserFilters from '../../../components/staff/data/funnels/FunnelUserFilters';
import FunnelsTabs from '../../../components/staff/data/funnels/FunnelsTabs';
import { useSelector } from 'react-redux';
import { getCompanySelected } from '../../../reducers/app';
import { LocalDebug } from '../../../utils/LocalDebug';
import ReactJsonDebug from '../../../components/app/debug/ReactJsonDebug';
import { documentId } from '../../../utils/common';
import { FilterOutlined } from '@ant-design/icons';

const FunnelsPage = () => {
	const className = 'FunnelsPage';

	const companySelected = useSelector(getCompanySelected);
	const [filters, setFilters] = useState({});
	// const [filters, setFilters] = useState({ ...companySelected ? { companyId: documentId(companySelected) } : {} });

	LocalDebug.logInfo({ className }, { filters });
	return (
		<PageLayout
			withBackground={false}
			title='Funnels'
			icon={<FilterOutlined />}
			rightActions={<ComputeFunnelsButton />}
		>
			<FunnelUserFilters
				setFilters={setFilters}
			/>

			<FunnelsTabs
				filters={{
					...filters,
					...companySelected ? { companyId: documentId(companySelected) } : {},
				}}
			/>

		</PageLayout>
	);
};

export default FunnelsPage;
