export const COACHING_STATUS_NOT_CONTACTED_VALUE = 'not-contacted';
export const COACHING_STATUS_USER_EXCLUDED_VALUE = 'user-excluded';
export const COACHING_STATUS_USER_DISPATCHED_VALUE = 'user-dispatched';
export const COACHING_STATUS_USER_CONTACTED_VALUE = 'user-contacted';
export const COACHING_STATUS_USER_CONTACTED_X2_VALUE = 'user-contacted-x2';
export const COACHING_STATUS_USER_CONTACTED_X3_VALUE = 'user-contacted-x3';
export const COACHING_STATUS_USER_DECLINED_VALUE = 'user-declined';
export const COACHING_STATUS_USER_ACCEPTED_VALUE = 'user-accepted';
export const COACHING_STATUS_USER_SCHEDULED_VALUE = 'user-scheduled';
export const COACHING_STATUS_USER_COACHED_VALUE = 'user-coached';
export const COACHING_STATUS_USER_READY_VALUE = 'user-ready';
export const COACHING_STATUS_USER_DISPLAYED_VALUE = 'user-displayed';
export const COACHING_STATUS_USER_SENT_VALUE = 'user-sent';

export const COACHING_STATUS_NOT_CONTACTED_ICON = 'fas fa-hourglass-half';
export const COACHING_STATUS_USER_EXCLUDED_ICON = 'fas fa-eye-slash';
export const COACHING_STATUS_USER_DISPATCHED_ICON = 'fas fa-random';
export const COACHING_STATUS_USER_CONTACTED_ICON = 'fas fa-paper-plane';
export const COACHING_STATUS_USER_CONTACTED_X2_ICON = 'fas fa-paper-plane';
export const COACHING_STATUS_USER_CONTACTED_X3_ICON = 'fas fa-paper-plane';
export const COACHING_STATUS_USER_DECLINED_ICON = 'fas fa-times';
export const COACHING_STATUS_USER_ACCEPTED_ICON = 'fas fa-thumbs-up';
export const COACHING_STATUS_USER_SCHEDULED_ICON = 'fas fa-calendar-check';
export const COACHING_STATUS_USER_COACHED_ICON = 'fas fa-check';
export const COACHING_STATUS_USER_READY_ICON = 'fas fa-thumbs-up';
export const COACHING_STATUS_USER_DISPLAYED_ICON = 'far fa-eye';
export const COACHING_STATUS_USER_SENT_ICON = 'fas fa-envelope';

export const COACHING_STATUS_NOT_CONTACTED_COLOR = '#bbbbbb';
export const COACHING_STATUS_USER_EXCLUDED_COLOR = '#000000';
export const COACHING_STATUS_USER_DISPATCHED_COLOR = '#cccc99';
export const COACHING_STATUS_USER_CONTACTED_COLOR = '#cccc00';
export const COACHING_STATUS_USER_CONTACTED_X2_COLOR = '#bbbb00';
export const COACHING_STATUS_USER_CONTACTED_X3_COLOR = '#aaaa00';
export const COACHING_STATUS_USER_DECLINED_COLOR = '#cc0000';
export const COACHING_STATUS_USER_ACCEPTED_COLOR = '#9999ff';
export const COACHING_STATUS_USER_SCHEDULED_COLOR = '#9999ff';
export const COACHING_STATUS_USER_COACHED_COLOR = '#99ff99';
export const COACHING_STATUS_USER_READY_COLOR = '#00cc00';
export const COACHING_STATUS_USER_DISPLAYED_COLOR = '#00cc00';
export const COACHING_STATUS_USER_SENT_COLOR = '#00cc00';

export const COACHING_STATUS_CAN_BE_CONTACTED = [
	COACHING_STATUS_NOT_CONTACTED_VALUE,
	COACHING_STATUS_USER_CONTACTED_VALUE,
	COACHING_STATUS_USER_CONTACTED_X2_VALUE,
	COACHING_STATUS_USER_CONTACTED_X3_VALUE,
];

export const COACHING_STATUS_OPTIONS = [
	{
		label: 'Not contacted',
		icon: COACHING_STATUS_NOT_CONTACTED_ICON,
		color: COACHING_STATUS_NOT_CONTACTED_COLOR,
		value: COACHING_STATUS_NOT_CONTACTED_VALUE,
		tooltip: 'Default status, the candidate has never been contacted',
	},
	{
		label: 'Exclude user',
		icon: COACHING_STATUS_USER_EXCLUDED_ICON,
		color: COACHING_STATUS_USER_EXCLUDED_COLOR,
		value: COACHING_STATUS_USER_EXCLUDED_VALUE,
	},
	{
		label: 'User dispatched',
		icon: COACHING_STATUS_USER_DISPATCHED_ICON,
		color: COACHING_STATUS_USER_DISPATCHED_COLOR,
		value: COACHING_STATUS_USER_DISPATCHED_VALUE,
	},
	{
		label: 'User contacted',
		icon: COACHING_STATUS_USER_CONTACTED_ICON,
		color: COACHING_STATUS_USER_CONTACTED_COLOR,
		value: COACHING_STATUS_USER_CONTACTED_VALUE,
	},
	{
		label: 'User contacted (x2)',
		icon: COACHING_STATUS_USER_CONTACTED_X2_ICON,
		color: COACHING_STATUS_USER_CONTACTED_X2_COLOR,
		value: COACHING_STATUS_USER_CONTACTED_X2_VALUE,
	},
	{
		label: 'User contacted (x3)',
		icon: COACHING_STATUS_USER_CONTACTED_X3_ICON,
		color: COACHING_STATUS_USER_CONTACTED_X3_COLOR,
		value: COACHING_STATUS_USER_CONTACTED_X3_VALUE,
	},
	{
		label: 'User declined',
		icon: COACHING_STATUS_USER_DECLINED_ICON,
		color: COACHING_STATUS_USER_DECLINED_COLOR,
		value: COACHING_STATUS_USER_DECLINED_VALUE,
	},
	{
		label: 'User accepted',
		icon: COACHING_STATUS_USER_ACCEPTED_ICON,
		color: COACHING_STATUS_USER_ACCEPTED_COLOR,
		value: COACHING_STATUS_USER_ACCEPTED_VALUE,
	},
	{
		label: 'User scheduled',
		icon: COACHING_STATUS_USER_SCHEDULED_ICON,
		color: COACHING_STATUS_USER_SCHEDULED_COLOR,
		value: COACHING_STATUS_USER_SCHEDULED_VALUE,
	},
	{
		label: 'User coached',
		icon: COACHING_STATUS_USER_COACHED_ICON,
		color: COACHING_STATUS_USER_COACHED_COLOR,
		value: COACHING_STATUS_USER_COACHED_VALUE,
	},
	{
		label: 'Ready for NL',
		icon: COACHING_STATUS_USER_READY_ICON,
		color: COACHING_STATUS_USER_READY_COLOR,
		value: COACHING_STATUS_USER_READY_VALUE,
	},
	{
		label: 'User displayed',
		icon: COACHING_STATUS_USER_DISPLAYED_ICON,
		color: COACHING_STATUS_USER_DISPLAYED_COLOR,
		value: COACHING_STATUS_USER_DISPLAYED_VALUE,
		staff: true,
	},
	{
		label: 'User sent',
		icon: COACHING_STATUS_USER_SENT_ICON,
		color: COACHING_STATUS_USER_SENT_COLOR,
		value: COACHING_STATUS_USER_SENT_VALUE,
		staff: true,
	},
];
