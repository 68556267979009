import { useTheme } from '@emotion/react';
import BiasRadar from '../results/display/BiasRadar';
import {
	BIAS_OPTIONS,
	getRadarBiasData,
	PERSONA_EMPLOYEES_ALL_SHORT_ID,
	PERSONA_MANAGER_SHORT_ID,
	RadarBiasTag,
} from '../data/biases';
import { Space } from 'antd';
import { useContext } from 'react';
import { AppDataContext } from '../../../contexts/AppDataProvider';

const DEFAULT_SIZE = 800;

const UMSResultsBiasRadar = ({
	size = DEFAULT_SIZE,
	result,
	personas = [
		PERSONA_EMPLOYEES_ALL_SHORT_ID,
		PERSONA_MANAGER_SHORT_ID,
	],
	withBiasLevel = true,
	withAlignmentLevel = true,
}) => {
	const className = 'UMSResultsBiasRadar';
	const theme = useTheme();

	const context = useContext(AppDataContext);

	const radarSize = size / 1.9;
	const tagRatio = size / 420;

	const width = size;
	const height = size * 0.9;

	return (
		<div
			style={{
				position: 'relative',
				width,
				height,
				// border: '1px solid black',
			}}
		>
			<div
				style={{
					position: 'absolute',
					left: size / 2 - radarSize / 2,
					top: height / 2 - radarSize / 2,
				}}
			>
				<BiasRadar
					width={radarSize}
					height={radarSize}
					margin={{
						top: 0, left: 0, right: 0, bottom: 0,
					}}
					data={getRadarBiasData({
						personas,
						result,
					})}
				/>
			</div>

			<div>
				{BIAS_OPTIONS?.map((bias, index) => {
					const w = 140;
					const h = 48 + (withBiasLevel ? 11 : 0) + (withAlignmentLevel ? 11 : 0);
					const { dx, dy } = [
						{ dx: tagRatio * -w / 2, dy: tagRatio * (-h - 8) },
						{ dx: tagRatio * 20, dy: tagRatio * (-h / 2 - 10) },
						{ dx: tagRatio * 16, dy: tagRatio * (-h / 2 + 10) },
						{ dx: 0, dy: tagRatio * 6 },
						{ dx: tagRatio * -w, dy: tagRatio * 8 },
						{ dx: tagRatio * (-w - 16), dy: tagRatio * (-h / 2 + 10) },
						{ dx: tagRatio * (-w - 20), dy: tagRatio * (-h / 2 - 10) },
					][index];

					return (
						<>
							<div
								key={index}
								style={{
									position: 'absolute',
									left: dx + width / 2 + (radarSize / 2) * Math.cos(-Math.PI / 2 + (2 * Math.PI * index / 7)),
									top: dy + height / 2 + (radarSize / 2) * Math.sin(-Math.PI / 2 + (2 * Math.PI * index / 7)),
								}}
							>
								<RadarBiasTag
									ratio={tagRatio}
									bias={bias}
									data={result}
									personas={personas}
									context={context}
									withCaret={false}
									withBiasLevel={withBiasLevel}
									withAlignmentLevel={withAlignmentLevel}
								/>
							</div>
						</>
					);
				})}
			</div>

			{/* <RadarBiasTag
				ratio={1}
				bias={BIAS_OPTIONS[0]}
				data={result}
				personas={personas}
				context={context}
				withCaret={false}
				withBiasLevel={true}
				withAlignmentLevel={true}
			/> */}

		</div>
	);
};

export default UMSResultsBiasRadar;
