import moment from 'moment';
import {
	Button,
	Col, Form, Input, Row, Select, Tag,
} from 'antd';
import { useTheme } from '@emotion/react';
import { useEffect, useState } from 'react';
import Link from '../../app/Link';
import { FormItemContainer } from '../../app/form/FormItem';
import FormItem from '../../company/form/FormItem';
import { useDispatch, useSelector } from 'react-redux';
import {
	getCompanies, getCompanySelected, getItems, getTagsJobs,
} from '../../../reducers/app';
import GroupItems from './GroupItems';
import FormCompanySelect from '../../app/form/FormCompanySelect';
import { useAuthContext } from '../../../hooks/useAuthContext';
import TextareaEditor from '../../company/form/TextareaEditor';
import { LocalDebug, localDebug } from '../../../utils/LocalDebug';
import { getAllJobItems } from '../../../actions/jobs';
import CompanyStub from '../../company/CompanyStub';
import { FaExternalLinkAlt, FaSave, FaTimes } from 'react-icons/fa';
import {
	arrayWrap, documentId, openExternalUrl, requiredRule,
} from '../../../utils/common';
import SalaryRangeInput from './SalaryRangeInput';
import {
	JOB_TAG_CATEGORIES_JOB_FORM, JOB_TAG_JOB_LANGUAGE, JOB_TAG_JOB_LEVEL, JOB_TAG_JOB_REMOTE_LEGACY, TAG_STAFF_ICON, isJobFormFieldMandatory,
} from '../../../constants/constant';
import FormSelect from '../../app/form/FormSelect';
import { getPrettifiedLanguageLabel } from '../../../constants/languages';
import FormSubmitButton from '../../app/form/FormSubmitButton';
import FormBoxTitle from '../../app/form/FormBoxTitle';
import BlockStaff from '../../app/blocker/BlockStaff';
import { isBoolean } from 'lodash';
import JobModel from '../../../models/JobModel';

const JobFormItem = (
	{
		type = 'single',
		column = 'right',
		children,
		...props
	},
) => {
	const labelCol = props?.labelCol || column === 'right' ? { span: 5 } : { span: 3 };
	const wrapperCol = props?.wrapperCol || column === 'right' ? { span: 19 } : { span: 21 };
	let rules = props?.rules || [];
	if (props?.mandatory && (
		!rules.length || !rules.find((r) => r.required)
	)) {
		// If there's a validator, we don't want to display 2 messages
		const requiredMsg = rules.find((r) => r.validator) ? '' : props?.tooltip || 'This field is required';

		rules = [...rules, { ...requiredRule, message: requiredMsg }];
	}
	const cleanedProps = {
		...props,
		rules,
	};
	if (type === 'group') {
		return <GroupItems
			{...cleanedProps}
			labelCol={labelCol}
			wrapperCol={wrapperCol}
			placeholder={props?.placeholder}
		/>;
	}
	return <FormItem
		{...cleanedProps}
		labelCol={labelCol}
		wrapperCol={wrapperCol}
		defaultContainer='div'
		style={{
			marginTop: '30px !important',
		}}
	>
		{children}
	</FormItem>;
};

const JobForm = (
	{
		initialValues,
		title,
		onSubmit,
		onReset,
	},
) => {
	const className = 'JobForm';

	const dispatch = useDispatch();

	const [form] = Form.useForm();
	const theme = useTheme();
	const items = useSelector(getItems);
	const tags = useSelector(getTagsJobs);
	const companies = useSelector(getCompanies);
	const companySelected = useSelector(getCompanySelected);

	const { isStaffView, companyIdsUser } = useAuthContext();

	if (isBoolean(initialValues?.remote)) {
		initialValues.remote = arrayWrap(JOB_TAG_JOB_REMOTE_LEGACY(initialValues.remote));
	}
	if (initialValues?.publishedAt) {
		initialValues.publishedAt = moment(initialValues.publishedAt);
	}
	const [publishedAt, setPublishedAt] = useState(initialValues?.publishedAt);

	const [companyId, setCompanyId] = useState(initialValues?.companyId?.toString());
	const [companyOptions, setCompanyOptions] = useState([]);
	const [data, setData] = useState({});
	const [formChanged, setFormChanged] = useState(0);
	const { isJobPublished } = new JobModel(initialValues);
	const [draftMode, setDraftMode] = useState(!documentId(initialValues) || !isJobPublished);

	const getTagsOptions = (category, noneValue = '-1', sortOnKey = null) => {
		let options = [];
		const categoryTags = (tags.find((tag) => tag.category === category?.value));
		if (categoryTags) {
			options = (categoryTags?.items || []);

			if (sortOnKey) options.sort(sortOn({ key: sortOnKey }));

			if (category?.value === JOB_TAG_JOB_LEVEL.value) {
				const jobLevelOptions = [...options];
				const values = form.getFieldsValue()?.[category?.mappedFrom];

				if (values?.length) {
					const onlySelected = category?.maxOptionCount
					&& values?.length >= category?.maxOptionCount;

					options = jobLevelOptions
						?.slice()
						?.reduce((acc, option, index) => {
							const [value] = values;
							if (
								jobLevelOptions?.slice(
									Math.max(0, index - 1),
									index - 1 + 3,
								)?.map((o) => o?.value)?.includes(value)
							) {
								if (onlySelected && !values.includes(option?.value)) {
									return acc;
								}
								return [...acc, option];
							}
							return acc;
						}, []);
				}
			}
			if (category?.value === JOB_TAG_JOB_LANGUAGE.value) {
				options = options.map(({ label, value }) => {
					return {
						label: getPrettifiedLanguageLabel(value) || label,
						value,
					};
				});
			}
		}

		return options;
	};

	const tagFormItem = (category, key) => (

		<JobFormItem
			key={category.value}
			label={category.label}
			labelAlign='right'
			name={category?.mappedFrom || category?.value}
			mandatory={category?.mandatory}
			{...category?.tooltip && { tooltip: category.tooltip }}
		>
			{tagSelect(category)}
		</JobFormItem>
	);
	const tagSelectOption = ({ label, value }, index) => {
		return <Select.Option
			key={value + index}
			value={value}
			label={label}
		>
			{label}
		</Select.Option>;
	};

	const tagSelect = (category) => {
		const options = getTagsOptions(category) || [];

		return (
			<FormSelect
				options={options}
				{...category?.maxTagCount && { maxTagCount: category?.maxTagCount }}
				{...category?.details && { placeholder: category?.details }}
				mode="multiple"
				blockScroll={true}
				listHeight={200}
				tagRender={(props) => {
					const {
						label, value, closable, onClose,
					} = props || {};
					const onPreventMouseDown = (event) => {
						event.preventDefault();
						event.stopPropagation();
					};
					return (
						<span style={{
							color: theme.color.fitBlueElectric,
						}}>
							<Tag
								color={value}
								onMouseDown={onPreventMouseDown}
								closable={false}
								style={{
									padding: '2px 8px',
									border: `1px solid ${theme.color.fitBlueElectric}`,
									borderRadius: 4,
									background: 'white',
									color: theme.color.fitBlueElectric,
									fontSize: 13,
									margin: '1px 2px 1px 0',
								}}
							>
								{label}
								&nbsp;
								<FaTimes
									onClick={onClose}
									style={{
										marginBottom: -2,
										color: theme.color.fitBlueElectric,
										cursor: 'pointer',
									}}
								/>
							</Tag>
						</span>
					);
				}}
			>
				{options.map(tagSelectOption)}
			</FormSelect>
		);
	};

	useEffect(() => {
		LocalDebug.logUseEffect({ className, effects: 'companySelected' }, { companySelected, companyId });
		const cId = companyId || documentId(companySelected);
		LocalDebug.logUseEffect({ className, effects: 'companySelected' }, { cId });
		setCompanyId(cId);
		dispatch(getAllJobItems({ companyId: cId }));
	}, [companySelected, companyId]);
	const getOptionsByField = (field) => {
		return items ? items?.find((item) => item?.field === field)?.items : [];
	};

	const setFieldsValue = (fields) => {
		form.setFieldsValue(fields);
	};

	useEffect(() => {
		if (!companies || companies.length === 0) {
			return;
		}
		if (isStaffView) {
			setCompanyOptions(companies);
		} else if (companyIdsUser?.length > 0) {
			setCompanyOptions(companies.filter((c) => companyIdsUser.includes(documentId(c))));
		}
	}, [companies, companyIdsUser, isStaffView]);

	const onFormChanged = () => {
		setFormChanged((p) => p + 1);
	};

	const saveDraft = () => {
		// Send the form values forcing isPublished
		const values = form.getFieldsValue();

		// Validate only the title and the company
		const { title: jobTitle } = values;
		if (!companyId || !jobTitle) {
			form.validateFields(['companyId', 'title']);
		} else {
			handleSubmit({ values, isDraft: true });
			setDraftMode(true);
		}
	};
	const handleSubmit = ({ values, isDraft }) => {
		if (!onSubmit) {
			return;
		}
		const jobData = {
			...values,
			id: documentId(initialValues),
			isPublished: !isDraft,
			companyId,
			isActive: true,
			isCustomize: true,
		};
		setData(jobData);
		LocalDebug.logInfo({ className, method: 'handleSubmit' }, jobData);
		onSubmit({ values: jobData, isDraft });
	};

	const salaryNotEmpty = (value) => {
		const salaryRange = form.getFieldValue('salaryRange');
		if (!salaryRange?.min) {
			return Promise.reject(new Error('Give a minimum for the salary range'));
		}
		if (!salaryRange?.max) {
			return Promise.reject(new Error('Give a maximum for the salary range'));
		}
		if (salaryRange?.max < salaryRange?.min) {
			return Promise.reject(new Error('The maximum salary must be greater than the minimum'));
		}
		if (!salaryRange?.currency) {
			return Promise.reject(new Error('Add the currency of the salary'));
		}
		if (!salaryRange?.interval) {
			return Promise.reject(new Error('Add an interval for the salary'));
		}
		return Promise.resolve();
	};

	const publishStatusTag = documentId(initialValues)
		? <div
			style={{
				position: 'absolute',
				right: '17px',
				top: '90px',
				fontWeight: 500,
			}}>
			<Tag color={!draftMode ? 'green' : 'default'}>
				{!draftMode ? 'This job is PUBLISHED' : 'This job is NOT PUBLISHED'}
			</Tag>
		</div>
		: null;
	return (
		<>
			<FormBoxTitle
				title={title}
			/>
			<Form
				form={form}
				id="job-form"
				initialValues={{
					isPublished: true,
					...initialValues,
				}}
				labelCol={{ span: 5 }}
				wrapperCol={{ span: 19 }}
				onFinish={(values) => handleSubmit({ values, isDraft: false })}
				style={{ paddingTop: 20 }}
				onValuesChange={onFormChanged}
			>
				<div style={{
					height: '100%',
				}}>
					<FormItemContainer>
						{publishStatusTag}
						{/* Company Selector - Only for staff view */}
						{companySelected
							? <BlockStaff style={{
								marginBottom: 20,
								marginTop: 10,
								padding: '10px 0px 10px 50px',
								height: 30,
							}}>
								<Row>
									<Col span={14}>
										<JobFormItem
											label='Company'
											name='companyId'
											column='left'
										>
											<CompanyStub
												size={20}
												company={companySelected}
												withTooltip={false}
												withAtsAccess={true}
												withCompanyPlan={true}
												style={{ marginLeft: 20 }} />
										</JobFormItem>
									</Col>
								</Row>
							</BlockStaff>
							: <BlockStaff style={{
								marginBottom: 20,
								marginTop: 10,
								padding: '10px 20px 10px 50px',
								height: 50,
							}}>
								<JobFormItem
									label={<span>Company</span>}
									name='companyId'
									mandatory={true}
									column='left'
									wrapperCol={{ span: 18 }}
								>
									<FormCompanySelect
										options={companyOptions.map((c) => ({
											value: documentId(c), label: c.name, item: c,
										}))}
										onChange={(value) => setCompanyId(value)}
										mode='single'
									/>
								</JobFormItem>
							</BlockStaff>
						}
						<Row style={{ marginTop: 10 }}>
							{/* Left column */}
							<Col span={14} style={{ paddingRight: 10 }}>

								<JobFormItem
									label={'Job title'} name='title'
									mandatory={isJobFormFieldMandatory('title')}
									tooltip='Enter job offer title'
									column='left'
								>
									<Input placeholder='A catchy and self-explanatory name for the job'/>
								</JobFormItem>

								<JobFormItem
									shouldUpdate
									column='left'
									label='URL' name='uri'
									mandatory={isJobFormFieldMandatory('uri')}
									rules={[
										{
											pattern: /(https?:\/\/(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9])(:?\d*)\/?([a-z_/0-9\-#.]*)\??([a-z_/0-9\-#=&]*)/g,
											message: 'Please add a valid uri',
										},
									]}
									tooltip={<>
									Include a valid URL for the applicants. You can test it here:
										<Link
											onClick={() => openExternalUrl(form.getFieldValue('uri'))}>
										&nbsp;Test URL <FaExternalLinkAlt style={{ marginBottom: -2 }} />
										</Link>
									</>}
								>
									<Input
										placeholder='The page where the talent should be redirected after applying to the job in 50inTech'
									/>
								</JobFormItem>
								<JobFormItem
									label='Salary'
									name='salaryRange'
									mandatory={isJobFormFieldMandatory('salaryRange')}
									tooltip='Select a range'
									column='left'
									rules={[{
										validator: salaryNotEmpty,
									}]}
								>
									<SalaryRangeInput />
								</JobFormItem>
								<JobFormItem
									label='Content' name='content'
									column='left'
									mandatory={isJobFormFieldMandatory('content')}
									tooltip='Give the details of the job offer'
								>
									<TextareaEditor
										config={{ height: 300, placeholder: 'Describe the job to your potential candidates' }}
										style={{
											width: '100%', marginBottom: 0,
										}}
										internalContainerStyle={{
											maxHeight: 300,
											minHeight: '200px !important',
											overflow: 'auto',
										}}
									/>
								</JobFormItem>

							</Col>

							{/* Right column */}
							<Col span={10} style={{ paddingLeft: 20, paddingRight: 10 }}>

								{JOB_TAG_CATEGORIES_JOB_FORM.map(tagFormItem)}
								<JobFormItem
									label='Location'
									name='location'
									type='group'
									options={getOptionsByField('location', { companyId: form.getFieldValue('companyId') })}
									mandatory={isJobFormFieldMandatory('location')}
									tooltip='Give 1 or more locations (separated with "-" or ";")'
									placeholder='The main location for the job'
								/>
								<JobFormItem
									label='Contract'
									name='contract'
									type='group'
									options={getOptionsByField('contract')}
									mandatory={isJobFormFieldMandatory('contract')}
									onClickTag={setFieldsValue}
									tooltip='For example: Full-time, Intership, Freelance...'
									placeholder='Type of contract: Full-time, Intership, Freelance...'
								/>

								<JobFormItem
									label='Department'
									name='department'
									type='group'
									options={getOptionsByField('department')}
									mandatory={isJobFormFieldMandatory('department')}
									tooltip='For example: Engineering, Product, Sales...'
									placeholder='Job area: Engineering, Product, Sales...'
								/>
							</Col>
						</Row>
						{/* Publish button */}
						<Row>
							{draftMode && <Col span={4} push={16} style={{ paddingRight: 10 }}>
								<Button
									icon={<FaSave style={{ marginBottom: -2, marginRight: 2 }}/>}
									size={'large'}
									type='default'
									id='draft-button'
									onClick={saveDraft}
								>
								&nbsp;Save job as a draft
								</Button>
							</Col>
							}
							<Col span={4} push={!draftMode ? 20 : 16} style={{ paddingRight: 10 }}>
								<FormSubmitButton
									size={'large'}
									style={{
										width: '100%',
									}}
								>
								&nbsp;Publish job
								</FormSubmitButton>
							</Col>
						</Row>

					</FormItemContainer>
				</div>
			</Form>
		</>
	);
};

export default JobForm;
