import { useContext } from 'react';
import { useAuthContext } from '../../hooks/useAuthContext';
import { addSourcing } from '../../actions/sourcings';
import {
	documentId, openExternalUrl, relabelAtsProvider, writeLog,
} from '../../utils/common';
import {
	STAGE_STARTED,
	atsProviderOptions,
} from '../../constants/constant';
import { COMPANY_RECRUITER_LOG } from '../../constants/company-recruiter-log';
import { LocalDebug } from '../../utils/LocalDebug';
import { writeRecruiterLog } from '../../actions/logging';
import FormBoxTitle from '../../components/app/form/FormBoxTitle';
import { NotificationContext } from '../../components/app/notification/NotificationProvider';
import ModalContainerBase from './ModalContainerBase';
import UserAtsSenderForm from '../../components/sourcings/UserAtsSenderForm';
import AtsLogo from '../../components/ats/AtsLogo';
import SourcingModel from '../../models/SourcingModel';
import { Button } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { getCompanySelectedApiAccess } from '../../reducers/app';
import { shouldRefreshData } from '../../actions/app';
import { UserAtsSendModalContext } from './UserAtsSendModalProvider';
import GenderhireCandidatesAtsMenu from '../../menu/genderhire/GenderhireCandidatesAtsMenu';
import { SOURCE_50INTECH_VALUE, SOURCE_RECRUITER_VALUE } from '../../constants/sourcing';

const UserAtsSendModalContainer = () => {
	const className = 'UserAtsSendModalContainer';

	const dispatch = useDispatch();

	const {
		isStaffView, isTalent, companyIdsUser,
	} = useAuthContext();

	const {
		open,
		setOpen,
		user,
		onStart,
		onComplete,
	} = useContext(UserAtsSendModalContext);

	const { notifyLoading, notifySuccess } = useContext(NotificationContext);

	const companySelectedApiAccess = useSelector(getCompanySelectedApiAccess);

	const atsName = atsProviderOptions?.find((o) => o?.value === companySelectedApiAccess?.ats)?.label || 'your ATS';

	const handleSubmit = async (values) => {
		const method = 'handleSubmit';
		await onStart?.();
		setOpen(false);
		const valuesToDb = { ...values };
		writeLog(`${className} handleSubmit`, valuesToDb);

		notifyLoading({ key: className });

		const sourcing = new SourcingModel(await addSourcing(valuesToDb));
		LocalDebug.logInfo({ className, method }, { sourcing });

		writeRecruiterLog(
			COMPANY_RECRUITER_LOG.SOURCE,
			{
				userTargetId: documentId(user),
				sourcingTargetId: documentId(sourcing),
			},
		);

		const outerLink = (
			<Button
				type='link'
				style={{ margin: 0, padding: 0 }}
				onClick={() => { openExternalUrl(sourcing.openAtsUrl); }}
			>
				Open in {relabelAtsProvider(sourcing.atsProvider)}
			</Button>
		);
		const candidatesAtsMenu = new GenderhireCandidatesAtsMenu();
		const innerLink = <a href={candidatesAtsMenu.path}>
			{candidatesAtsMenu.icon}&nbsp;{candidatesAtsMenu.label}
		</a>;

		notifySuccess({
			key: className,
			description: <>
				<span>Sent to {atsName}!</span>
				<span>{outerLink} or find the talent in {innerLink}</span>
			</>,
		});

		dispatch(shouldRefreshData());

		await onComplete?.(sourcing);
		return sourcing;
	};

	return (
		<ModalContainerBase
			open={open}
			setOpen={setOpen}
			width={600}
			footer={false}
		>
			<FormBoxTitle
				title={<><span><AtsLogo/> Send a talent to your ATS</span></>}
			/>
			<UserAtsSenderForm
				initialValues={{
					userId: documentId(user),
					source: isTalent ? SOURCE_50INTECH_VALUE : SOURCE_RECRUITER_VALUE,
					stage: STAGE_STARTED,
					user,
					viaProfile: true,
					companyIds: !isStaffView ? companyIdsUser : null,
				}}
				withSource={false}
				withFormHistoryItems={false}
				onClose={() => setOpen(false)}
				onReset={() => setOpen(false)}
				onSubmit={handleSubmit}
			/>
		</ModalContainerBase>
	);
};

export default UserAtsSendModalContainer;
