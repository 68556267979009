import { ReadOutlined } from '@ant-design/icons';
import StaffResourceListPage from '../../../containers/staff/content/StaffResourceListPage';
import MenuBase from '../../MenuBase';
import { PERMS_GRANT_STAFF } from '../../menu.utils';

export const StaffResourceListIcon = ReadOutlined;

class StaffResourceListMenu extends MenuBase {
	static props = {
		name: 'staff-content-resources',
		path: '/staff/content/resources',
		label: 'Resources',
		icon: <StaffResourceListIcon />,
		iconLegacy: '📰',
		Component: StaffResourceListPage,
		perms: PERMS_GRANT_STAFF,
	};

	constructor() {
		super(StaffResourceListMenu.props);
	}
}

export default StaffResourceListMenu;
