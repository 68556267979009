import slugify from '@sindresorhus/slugify';
import FormItem from '../../company/form/FormItem';
import FormSelect from '../form/FormSelect';
import { Collapse } from 'antd';
import { useEffect, useState } from 'react';
import { LocalDebug } from '../../../utils/LocalDebug';
import CONTINENT_COUNTRIES from '../../../constants/continent-countries.json';
import COUNTRY_CITIES from '../../../constants/country-cities.json';
import AddButton from '../button/AddButton';
import { useTheme } from '@emotion/react';
import CollapsePanel from 'antd/es/collapse/CollapsePanel';
import { CloseCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { sortOn } from '../../../utils/common';
import { getJobLocationLabel } from '../../../constants/constant';

const LocationSelector = (
	{
		onSelect,
		form,
		toggle,
		setToggle,
		prevValues,
		withDesiredLocation,
		continentTags,
		countryTags,
		cityTags,
		parentCategory,
		tagCategoryContinent,
		tagCategoryCountry,
		tagCategoryCity,
		onOpen,
		onClose,
	},
) => {
	const className = 'LocationSelector';
	const theme = useTheme();

	const [activeKey, setActiveKey] = useState('0');
	const [buttonDisabled, setButtonDisabled] = useState(true);

	const [value, setValue] = useState();

	const [continentSelected, setContinentSelected] = useState();
	const [continentOptions, setContinentOptions] = useState(continentTags);

	const [countrySelected, setCountrySelected] = useState();
	const [countryDisabled, setCountryDisabled] = useState(true);
	const [countryOptions, setCountryOptions] = useState([]);

	const [citySelected, setCitySelected] = useState();
	const [cityDisabled, setCityDisabled] = useState(true);
	const [cityOptions, setCityOptions] = useState([]);

	const fieldNameContinent = `${parentCategory}-${tagCategoryContinent.value}`;
	const fieldNameCountry = `${parentCategory}-${tagCategoryCountry.value}`;
	const fieldNameCity = `${parentCategory}-${tagCategoryCity.value}`;

	const getContinentData = (continent) => {
		const label = getJobLocationLabel({
			value: `${tagCategoryContinent?.value}:${continent}`,
		});
		return {
			label, name: label,
		};
	};

	const getCountryData = (country) => {
		const label = getJobLocationLabel({
			value: `${tagCategoryCountry?.value}:${country}`,
			withCountryFlag: true,
		});
		const name = getJobLocationLabel({
			value: `${tagCategoryCountry?.value}:${country}`,
		});
		return { label, name };
	};

	const getCityData = (city, country) => {
		const label = getJobLocationLabel({
			value: `city:${city}|country:${country}`,
			withCityCountry: true,
			withCityCountryCode: true,
			withCityCountryFlag: true,
		});
		return { label };
	};

	/**
	 * Reset the component to its initial status
	 */
	const resetSelector = ({
		keepContinent,
		keepCountry,
	} = {
		keepContinent: false,
		keepCountry: false,
	}) => {
		// Collapse the selector
		setToggle(false);

		if (!keepCountry) {
			// Disable country and city fields
			setCountryDisabled(true);
			setCityDisabled(true);
		}
		// Reset the value of the dropdowns
		if (!keepContinent) {
			setContinentSelected(null);
			form.setFieldValue(fieldNameContinent, null);
		}
		if (!keepCountry) {
			setCountrySelected(null);
			form.setFieldValue(fieldNameCountry, null);
		}
		setCitySelected(null);
		form.setFieldValue(fieldNameCity, null);

		if (!keepContinent && !keepCountry) {
			// Disable the add button
			setButtonDisabled(true);
		}
	};

	/**
	 * The user clicked the Add location button
	 */
	const addLocation = () => {
		// Clean the fields
		resetSelector({ keepContinent: true, keepCountry: true });

		// Call the parent to handle the location value only if the value is not included yet
		if (!prevValues?.some((v) => v?.value === value?.value)) {
			onSelect(value);
		}
	};

	const onContinentChange = (val) => {
		setContinentSelected(val);
	};

	const onCountryChange = (val) => {
		setCountrySelected(val);
	};

	const onCityChange = (val) => {
		setCitySelected(val ? slugify(val) : null);
	};

	/**
	 * When the user modifies the selection of the Continent:
	 */
	useEffect(() => {
		LocalDebug.logUseEffect({ className, effects: 'continentSelected' }, { continentSelected });
		// Disable the city
		// --> it needs to be reloaded once a new country is selected
		setCityDisabled(true);
		setCitySelected(null);
		// Clean the country field:
		// If there's no Continent -> the country field needs to be empty
		// If there's a new Continent -> the country field will be reset with new values
		form.setFieldValue(fieldNameCountry, null);
		if (!continentSelected) {
			// No Continent selected: Disable and Reset the Country
			// --> it needs to be reloaded once a new Continent is selected
			setCountryDisabled(true);
			setCountrySelected(null);
			setValue(null);
			setButtonDisabled(true);
		} else {
			// Continent selected: Enable the Country
			// and load the countries of the selected continent
			setCountryDisabled(false);
			setCountrySelected(null);
			setCitySelected(null);
			setButtonDisabled(false);

			const {
				label: continentSelectedLabel,
				name: continentSelectedName,
			} = getContinentData(continentSelected);

			// Set the value for the location field based on the selected continent
			setValue({
				value: `${tagCategoryContinent?.value}:${continentSelected}`,
				label: continentSelectedLabel,
			});

			// Load the contry selector options based on the selected continent
			const countryNames = [...new Set(
				CONTINENT_COUNTRIES
					.filter((row) => row.continent_name === continentSelectedName)
					.map((row) => row.country_name),
			)];

			setCountryOptions(
				countryTags
					.filter((tag) => countryNames.includes(tag.label))
					.sort(sortOn({ key: 'label', string: true }))
					.map((tag) => ({
						...tag,
						label: getJobLocationLabel({
							value: `country:${tag.value}`,
							withCountryFlag: true,
						}),
					})),
			);
		}
	}, [continentSelected]);

	/**
	 * When the user modifies the selection of the Country:
	 */
	useEffect(() => {
		LocalDebug.logUseEffect({ className, effects: 'countrySelected' }, { countrySelected });
		// First, clean the city field:
		// If there's no country -> the city field needs to be empty
		// If there's a new country -> the city field will be reset with new values
		form.setFieldValue(fieldNameCity, null);
		if (!countrySelected) {
			// No Country selected: Disable and Reset the City
			// --> it needs to be reloaded once a new Country is selected
			setCityDisabled(true);
			setCitySelected(null);

			// Set the value for the location field based on the selected continent
			setValue({
				value: `${tagCategoryContinent?.value}:${continentSelected}`,
				...getContinentData(continentSelected),
			});
		} else {
			// Country selected: Enable the City
			// and load the cities of the selected continent
			setCityDisabled(false);
			setCitySelected(null);
			setButtonDisabled(false);

			// Get the label for the country from the user tags
			const {
				label: countrySelectedLabel,
				name: countrySelectedName,
			} = getCountryData(countrySelected);

			// Set the value for the location field based on the selected country
			setValue({
				value: `${tagCategoryCountry?.value}:${countrySelected}`,
				label: countrySelectedLabel,
			});

			// Load the city selector options based on the selected country
			setCityOptions(
				COUNTRY_CITIES
					?.[countrySelectedName]
					?.map?.((city) => (
						{ label: city?.label, value: city?.slug }
					))
					?.sort?.(sortOn({ key: 'label', string: true })),
			);
		}
	}, [countrySelected]);

	/**
	 * When the user modifies the selection of the City:
	 */
	useEffect(() => {
		LocalDebug.logUseEffect({ className, effects: 'countrySelected' }, { countrySelected });
		if (!citySelected) {
			// No city selected:
			// Set the value for the location field based on the selected country
			setValue({
				value: `${tagCategoryCountry?.value}:${countrySelected}`,
				...getCountryData(countrySelected),
			});
		} else {
			// City selected:
			setButtonDisabled(false);

			// Set the value for the location field based on the selected city
			setValue({
				value: `${tagCategoryCity?.value}:${citySelected}-${countrySelected}`,
				...getCityData(citySelected, countrySelected),
			});
		}
	}, [citySelected]);

	const toggleActiveKey = ({ forceValue }) => {
		if (forceValue !== undefined) {
			setActiveKey(forceValue ? '1' : '0');
		} else {
			setToggle(activeKey === '0');
		}
	};
	useEffect(() => {
		if (activeKey === '1') {
			onOpen?.();
		} else {
			onClose?.();
		}
	}, [activeKey]);

	useEffect(() => {
		setContinentOptions(continentTags);
		toggleActiveKey({ forceValue: toggle });
	}, [toggle]);

	useEffect(() => {
		resetSelector();
	}, []);

	return (
		<>
			<Collapse defaultActiveKey={['0']} activeKey={activeKey}
				onChange={() => setToggle(!toggle)}
				bordered={false}
				expandIcon={() => <PlusOutlined style={{ color: theme.color.fitBlueElectric }} />}
				style={{
					background: 'white',
				}}>
				<CollapsePanel
					key={'1'}
					header={<div style={{
						display: 'flex',
						justifyContent: 'space-between',
					}}>
						<b
							style={{
								color: theme.color.fitBlueElectric,
								textDecoration: 'underline',
							}}>
							Add a new location
						</b>
						{toggle && <CloseCircleOutlined
							style={{
								color: theme.color.fitBlueElectric,
							}}
						/>
						}
					</div>}
					style={{
						borderRadius: 1,
						border: `1px solid${theme.color.fitBgGreyDark}`,
					}}
				>
					<div style={{ paddingTop: 20 }}>
						<FormItem
							key={fieldNameContinent}
							label={tagCategoryContinent.filterLabel || tagCategoryContinent.label}
							name={fieldNameContinent}
							labelCol={{ span: 6 }}
							labelAlign='right'
						>
							<FormSelect
								options={continentOptions}
								onChange={onContinentChange}
								blockScroll={true}
							/>
						</FormItem>

						<FormItem
							key={fieldNameCountry}
							label={tagCategoryCountry.filterLabel || tagCategoryCountry.label}
							name={fieldNameCountry}
							labelCol={{ span: 6 }}
							labelAlign='right'
						>
							<FormSelect
								options={countryOptions}
								onChange={onCountryChange}
								disabled={countryDisabled}
								blockScroll={true}
							/>
						</FormItem>
						<FormItem
							key={fieldNameCity}
							label={tagCategoryCity.filterLabel || tagCategoryCity.label}
							name={fieldNameCity}
							labelCol={{ span: 6 }}
							labelAlign='right'
						>
							<FormSelect
								options={cityOptions}
								onChange={onCityChange}
								disabled={cityDisabled}
								blockScroll={true}
							/>
						</FormItem>
						<FormItem
							style={{ marginBottom: 0 }}
							label='&nbsp;'
							labelCol={{ span: 6 }}
						>
							<AddButton
								title="Add location"
								style={{ width: '100%' }}
								color={theme.color.fitBlueElectric}
								onClick={addLocation}
								disabled={buttonDisabled}
								ghost={true}
							/>
						</FormItem>
					</div>
				</CollapsePanel>
			</Collapse>
		</>
	);
};

export default LocationSelector;
