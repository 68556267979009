import CompanyEditionPage from '../../containers/configure/CompanyEditionPage';
import roles from '../../config/roles';
import { PERM_GRANT_TYPE } from '../menu.utils';
import MenuBase from '../MenuBase';
import { ShopOutlined } from '@ant-design/icons';

export const ConfigureCompanyPageIcon = ShopOutlined;

class ConfigureCompanyPageMenu extends MenuBase {
	static props = {
		name: 'configure-company-page',
		path: '/configure/company-page',
		aliases: ['^/configure/company-page', '^/company-page'],
		label: 'Company page',
		icon: <ConfigureCompanyPageIcon />,
		iconLegacy: '🥁',
		Component: CompanyEditionPage,
		perms: [
			{
				type: PERM_GRANT_TYPE,
				roles: [
					roles.STAFF,
					roles.DOCUMENT_ADMIN,
					roles.DOCUMENT_EDITOR,
				],
			},
		],
	};

	constructor() {
		super(ConfigureCompanyPageMenu.props);
	}
}

export default ConfigureCompanyPageMenu;
