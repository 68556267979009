import { ENGLISH_LANG } from '../../../../constants/constant';
import CompanyFieldNotProvided from '../shared/CompanyFieldNotProvided';
import CompanyGenderScoreRingScore from '../../genderscore/CompanyGenderScoreRingScore';
import { Space } from 'antd';
import CompanyModel from '../../../../models/CompanyModel';

const CompanyNameDisplay = (
	{
		company,
		lang = ENGLISH_LANG,
		...props
	},
) => {
	const className = 'CompanyNameDisplay';

	const field = 'name';
	const value = company?.getTranslationFieldFallback(field, lang);

	if (!value) return <CompanyFieldNotProvided/>;

	return (
		<Space
			style={{
				justifyContent: 'center',
				alignItems: 'center',
				verticalAlign: 'middle',
			}}
		>
			<span
				style={{
					fontSize: 32,
					lineHeight: 1,
					fontWeight: 'bold',
				}}
			>
				{value}
			</span>
			<CompanyGenderScoreRingScore
				size={28}
				company={company}
				style={{ display: 'block', marginTop: 2 }}
			/>
		</Space>
	);
};

export default CompanyNameDisplay;
