import { useTheme } from '@emotion/react';
import {
	Col, Divider, message, Row, Select, Space, Typography,
} from 'antd';
import { fill, findIndex } from 'lodash';
import { useContext, useEffect, useState } from 'react';
import { addAccess, removeAccess, updateAccess } from '../../../actions/company';
import CompanyAccessPopover from '../../../components/company/access/CompanyAccessPopover';
import CompanyAccessTable from '../../../components/company/access/CompanyAccessTable';
import PageLayout from '../../../components/app/layout/PageLayout';
import CompanyService from '../../../services/company';
import AddButton from '../../../components/app/button/AddButton';
import FormItem from '../../../components/app/form/FormItem';
import FormCompanySelect from '../../../components/app/form/FormCompanySelect';
import FormSelect from '../../../components/app/form/FormSelect';
import { accessTypeOptions, ACCESS_DOCUMENT_REF, atsProviderOptions } from '../../../constants/constant';
import {
	addToFilterOptions, documentId, getQueryParams, writeLog,
} from '../../../utils/common';
import { LocalDebug, localDebug } from '../../../utils/LocalDebug';
import { useSelector } from 'react-redux';
import { getCompanies, getCompanySelected, getDocumentReadersIds } from '../../../reducers/app';
import { FaCog } from 'react-icons/fa';
import { SocketContext } from '../../../contexts/SocketProvider';
import { useAuthContext } from '../../../hooks/useAuthContext';
import FormAtsSelect from '../../../components/app/form/FormAtsSelect';
import CompanyBatchCard from '../../../components/company/batch/CompanyBatchCard';
import StaffAtsListMenu from '../../../menu/staff/ats/StaffAtsListMenu';

const AtsAccessPage = () => {
	const className = 'AtsAccess';

	const theme = useTheme();

	// ---------------------------------------------------------------------- //
	// --------------- selector all company and company selected ------------ //
	// ---------------------------------------------------------------------- //
	const companies = useSelector(getCompanies);
	const companySelected = useSelector(getCompanySelected);
	const documentReadersIds = useSelector(getDocumentReadersIds);

	const {
		isStaffView, isCompanyRecruiter, companyRecruiterDocIds, isEditor,
	} = useAuthContext();
	const { createAction } = useContext(SocketContext);

	const [open, setOpen] = useState(false);
	const [initAccessValuesForm, setInitAccessValuesForm] = useState({});
	const [isRefreshDataLayout, setIsRefreshDataLayout] = useState(0);
	const [isShowFilterCompany, setIsShowFilterCompany] = useState(true);
	const [optionsCompany, setOptionsCompany] = useState([]);

	const [defaultData, setDefaultData] = useState(null);
	const [titleAction, setTitleAction] = useState('');

	// --------------------------------------------------------------------- //
	// ------------------- filter table ------------------------------------ //
	// --------------------------------------------------------------------- //
	const queryParams = getQueryParams();
	const [optionsFilterTable, setOptionsFilterTable] = useState({
		...queryParams || {},
		companyId: documentId(companySelected),
		documentId: documentId(companySelected),
	});

	const getDefaultSelected = (fieldName) => {
		return queryParams[fieldName] || [];
	};

	useEffect(() => {
		addToFilterOptions('companyId', documentId(companySelected), setOptionsFilterTable);
	}, [companySelected]);

	const handleChangeAts = (ats) => {
		addToFilterOptions('ats', ats, setOptionsFilterTable);
	};

	const handleChangeAccessType = (accessType) => {
		addToFilterOptions('accessType', accessType, setOptionsFilterTable);
	};

	// ----------------------------------------------- //
	// ------------- open add Access form ------------ //
	// ----------------------------------------------- //
	const handleAddAccess = () => {
		setInitAccessValuesForm({
			...(companies && { companyId: documentId(companies) }),
			permissions: ['fetchJobs'],
			isActive: true,
		});
		setOpen(true);
		setTitleAction('Add an ATS access');
	};

	// ------------------------------------------------ //
	// ----------- open Edit Access Form --------------------- //
	// ------------------------------------------------ //
	const handleEditAccess = (access) => {
		setInitAccessValuesForm(access);
		setOpen(true);
		setTitleAction('Edit an access');
	};

	const handleDeactivateJobs = (access) => {
	};

	// -------------------------------------------- //
	// ---------------------- add access ---------- //
	// -------------------------------------------- //
	const handleSubmit = async (values) => {
		const result = await addAccess(values);
		setOpen(false);
		setIsRefreshDataLayout((prev) => prev + 1);
		// create action
		if (result && result?.isActive) {
			const playload = {
				documentRef: ACCESS_DOCUMENT_REF,
				documentId: documentId(result),
				companyId: result.companyId,
				action: {
					title: titleAction,
				},
			};
			createAction(playload);
		}

		writeLog('handleSubmit ATS', { data: values });
	};

	// -------------------------------------------------- //
	// ------------------ delete access ----------------- //
	// -------------------------------------------------- //
	const handleDeletedAccess = async (access) => {
		await removeAccess(access);
		setIsRefreshDataLayout((prev) => prev + 1);
	};

	// ------------------------------------------------- //
	// ----------------- toggle active ----------------- //
	// ------------------------------------------------- //
	const toggleActive = async (accessId, isActive) => {
		await updateAccess(accessId, { isActive });
		setIsRefreshDataLayout((prev) => prev + 1);
	};

	const handleLoadedData = ({ items } = {}) => {
		setDefaultData(items || []);
	};

	// ----------------------------------- //
	// ------- fetch a job per access ---- //
	// ----------------------------------- //
	const handleFetchJob = async (access) => {
		localDebug('AtsAccess handleFetchJob', { access });
		CompanyService.fetchAccessJobs(documentId(access))
			.then(
				(response) => {
					// set row table
					const data = [...defaultData];
					const batch = response.data;

					const index = findIndex(data, (item) => documentId(item)?.toString?.() === documentId(access)?.toString?.());
					const newAccess = {
						...access,
						loading: false,
						...batch
							? {
								jobs: batch?.total,
								batch: {
									total: (access.batch.total + 1),
									lastBatch: batch,
								},
							}
							: {},
					};

					const {
						created, deleted, error, success, total, published,
					} = batch;

					fill(data, newAccess, index, index + 1);
					setDefaultData(data);
					message.success({
						content: <CompanyBatchCard batch={batch} />,
						duration: 5,
					});
				},
			)
			.catch(
				(error) => {
					const data = [...defaultData];
					const index = findIndex(data, (item) => documentId(item)?.toString?.() === documentId(access)?.toString?.());
					const newAccess = { ...access, loading: false };
					fill(data, newAccess, index, index + 1);
					setDefaultData(data);
					localDebug('error', error);
				},
			);
	};

	useEffect(() => {
		setIsShowFilterCompany(true);
		if (isCompanyRecruiter && companyRecruiterDocIds.length === 1) {
			setIsShowFilterCompany(false);
		}
		if (companies.length === 1) {
			setIsShowFilterCompany(false);
			addToFilterOptions('companyIds', [documentId(companies[0])]);
		}
	}, [isCompanyRecruiter, companyRecruiterDocIds, companies]);

	useEffect(() => {
		setOptionsCompany(companies);
		// dispatch(setCompanySelected(queryParams?.companyId));
	}, [companies]);

	useEffect(() => {
		setOptionsFilterTable((prev) => ({ ...prev, documentReadersIds }));
	}, [documentReadersIds]);

	return (
		<PageLayout
			MenuClass={StaffAtsListMenu}
			rightActions={isEditor && (
				<AddButton
					style={{ marginTop: 1 }}
					onClick={handleAddAccess}
					title='Connect an ATS'
				/>
			)}
		>
			{(isShowFilterCompany || isStaffView) && <>
				<Row>
					<Col span={12}>
						<FormItem
							label='ATS Provider'
							defaultContainer='div'
							styles={{
								rootContainer: {
									padding: '0 10px',
									display: 'flex',
									alignItems: 'center',
									justifyContent: 'flex-start',
								},
								label: { marginBottom: 0, paddingRight: 20 },
								itemContainer: { marginBottom: 0 },
							}}
						>
							<FormAtsSelect
								options={atsProviderOptions}
								mode='multiple'
								defaultValue={getDefaultSelected('ats')}
								onChange={handleChangeAts}
							/>
						</FormItem>
					</Col>
					<Col span={12}>
						<FormItem
							label='Access type'
							defaultContainer='div'
							styles={{
								rootContainer: {
									padding: '0 10px',
									display: 'flex',
									alignItems: 'center',
									justifyContent: 'flex-start',
								},
								label: { marginBottom: 0, paddingRight: 20 },
								itemContainer: { marginBottom: 0 },
							}}
						>
							<FormSelect
								options={accessTypeOptions}
								onChange={handleChangeAccessType}
								placeholder='Select an access type'
								defaultValue={getDefaultSelected('accessType')}
								styles={{ minWidth: 180 }}
								mode='multiple'
								render={(option) => <Select.Option label={option.label} value={option.value}>
									{option.icon(16)} <b>{option.label}</b>
								</Select.Option>}
							/>
						</FormItem>
					</Col>
				</Row>
				<Divider />
			</>
			}
			<CompanyAccessTable
				filterOptions={optionsFilterTable}
				onEditAccess={handleEditAccess}
				isEditor={isEditor}
				onDelete={handleDeletedAccess}
				onToggleActive={toggleActive}
				isRefresh={isRefreshDataLayout}
				onFetchJob={handleFetchJob}
				onDataLoaded={handleLoadedData}
				defaultData={defaultData}
			/>
			<CompanyAccessPopover
				open={open}
				initialValues={initAccessValuesForm}
				onSubmit={handleSubmit}
				onReset={() => setOpen(false)}
			/>
		</PageLayout>

	);
};

export default AtsAccessPage;
