import { Col, Divider, Row } from 'antd';
import { useTheme } from '@emotion/react';
import { displayHtml } from '../../../utils/common';
import { useAuthContext } from '../../../hooks/useAuthContext';
import CoachingDetails from './CoachingDetails';
import EmptyData from '../../app/EmptyData';
import GridValueCard from '../../app/card/GridValueCard';
import UserCoachingExperience from '../widgets/UserCoachingExperience';
import Callout from '../../app/callout/Callout';
import UserCoachingOpinionCard from '../card/coaching/UserCoachingOpinionCard';
import UserModel from '../../../models/UserModel';

const UserSpotlight = (
	{
		user,
	},
) => {
	const theme = useTheme();

	const { isStaffView } = useAuthContext();

	if (!user.coaching) {
		return null;
		// return <EmptyData description="No Spotlight information found"/>;
	}

	if (!isStaffView) {
		if (!new UserModel(user)?.isCoachingVisible({ isStaffView })) {
			return <EmptyData description="Spotlight information not ready yet!"/>;
		}
	}

	return <>
		<Callout
			// icon={<FaInfoCircle style={{marginBottom: -2, color: theme.color.darkgrey}}/>}
			style={{ background: theme.background.gradientBluePink(315) }}
		>
			<span style={{
				fontStyle: 'italic', fontSize: 13, fontWeight: 'bold', color: 'rgba(0,0,0,.7)',
			}}>
            Information gathered by a 50inTech's Talent Coach during a call with the talent
			</span>
		</Callout>

		<Row gutter={[0, 20]} style={{ marginBottom: 20 }}>

			<UserCoachingOpinionCard user={user}/>

			<GridValueCard span={8} title="Desired roles">
				{displayHtml(user.coaching?.coachingTargetPositions, true)}
			</GridValueCard>
			<GridValueCard span={8} title="Years of XP">
				<UserCoachingExperience user={user}/>
			</GridValueCard>
			<GridValueCard span={8} title="Company criteria">
				{displayHtml(user.coaching?.coachingCompanyCriteria, true)}
			</GridValueCard>

			<GridValueCard span={24} title="Skills">
				<Row gutter={[0, 20]}>
					<Col span={12}>
						<b>Hard skills</b>
						{displayHtml(user.coaching?.coachingHardSkills, true)}
					</Col>
					<Col span={12}>
						<b>Soft skills</b>
						{displayHtml(user.coaching?.coachingSoftSkills, true)}
					</Col>
					{/* <Col span={8}> */}
					{/*    <b>Languages</b> */}
					{/*    {displayHtml(user.coaching?.coachingLanguages, true)} */}
					{/* </Col> */}
				</Row>
			</GridValueCard>

			<GridValueCard span={6} title="Availability">
				{displayHtml(user.coaching?.coachingAvailability, true)}
			</GridValueCard>
			<GridValueCard span={6} title="Mobility">
				{displayHtml(user.coaching?.coachingMobility, true)}
			</GridValueCard>
			<GridValueCard span={6} title="Salary ask">
				{displayHtml(user.coaching?.coachingSalaryExpectations, true)}
			</GridValueCard>
			<GridValueCard span={6} title="Search status">
				{displayHtml(user.coaching?.coachingRecruitmentStatus, true)}
			</GridValueCard>

		</Row>

		{isStaffView && <>
			<Divider/>
			<CoachingDetails user={user}/>
		</>}
	</>;
};

export default UserSpotlight;
