import { useSelector } from 'react-redux';
import { getUserSelected } from '../../reducers/app';
import {
	useContext, useState,
} from 'react';
import FormItem from '../../components/company/form/FormItem';
import CKEditor from '../../components/app/form/CkEditor';
import {
	AutoComplete, Button, Form, Input, Select, Space,
} from 'antd';
import ReactJsonAdmin from '../../components/app/debug/ReactJsonAdmin';
import { LocalDebug } from '../../utils/LocalDebug';
import {
	LANGUAGE_PROFICIENCY_OPTIONS,
	LANGUAGE_PROFICIENCY_TRANSLATIONS,
	LANGUAGE_TRANSLATIONS,
} from '../../constants/languages';
import JOB_ROLES from '../../constants/job-role.json';
import slugify from '@sindresorhus/slugify';
import { documentId, pTagsAsList, requiredRule, sortOn } from '../../utils/common';
import {
	FaChevronDown, FaChevronUp, FaTrashAlt,
} from 'react-icons/fa';
import { useTheme } from '@emotion/react';
import FormSubmitButton from '../../components/app/form/FormSubmitButton';
import UserGridCardLeftColumn from '../../components/user/grid/card/UserGridCardLeftColumn';
import UserGridCardRightColumn from '../../components/user/grid/card/UserGridCardRightColumn';
import UserGridCardLayout from '../../components/user/grid/card/UserGridCardLayout';
import Box from '../../components/app/box/Box';
import UserService from '../../services/user';
import { NotificationContext } from '../../components/app/notification/NotificationProvider';
import { TECH_STACK_GROUP_OPTIONS } from '../../constants/hard-skills';

const FormLister = (
	{
		label = 'Items',
		name = '',
		listItemBuilder = ({ name, rest }) => (
			<FormItem
				{...rest}
				name={[name]}
				label='Item'
				layout='horizontal'
			 />
		),
		textAddBtn = 'Add an item',
	},
) => {
	const theme = useTheme();

	return (
		<FormItem
			label={label}
			layout='horizontal'
		>
			<Space direction='vertical'>
				<Form.List name={name}
						   layout='horizontal'
				>
					{(fields, { add, remove, move }) => (
						<Space direction='vertical'>
							{fields.map(({ key, name, ...rest }, i, arr) => (
								<Space
									key={key}
									size={20}
									align='top'
									style={{
										background: theme.color.bgGrey,
										width: '100%',
										padding: 10,
									}}
								>
									{listItemBuilder({ name, rest })}
									<Button
										danger
										onClick={() => remove(name)}
										style={{ margin: 0 }}
									>
										<FaTrashAlt style={{ marginBottom: -2 }} />
									</Button>
									<Button
										disabled={name === 0}
										onClick={() => move(name, name - 1)}
										style={{ margin: 0 }}
									>
										<FaChevronUp style={{ marginBottom: -2 }} />
									</Button>
									<Button
										disabled={name === arr?.length - 1}
										onClick={() => move(name, name + 1)}
										style={{ margin: 0 }}
									>
										<FaChevronDown style={{ marginBottom: -2 }} />
									</Button>
								</Space>
							))}
							<FormItem>
								<Button
									type='dashed'
									onClick={() => add()}
									icon='+'
									block
									style={{
										display: 'flex',
										justifyContent: 'center',
										alignItems: 'center',
									}}
								>
									{textAddBtn}
								</Button>
							</FormItem>
						</Space>
					)}
				</Form.List>
			</Space>
		</FormItem>
	);
};

const UserCoachingSalaryInput = (
	{
		value,
		onChange,
	},
) => {
	const className = 'UserCoachingSalaryInput';
	const onValuesChange = () => {
		LocalDebug.logInfo({ className });
	};

	return (
		<Form
			name='user-coaching-salary'
			onValuesChange={onValuesChange}
		>
			<FormItem label='Lower range' name={['range', 0]} />
			<FormItem label='Higher range' name={['range', 1]} />
		</Form>
	);
};
const UserCoachingPage = (
	{},
) => {
	const className = 'UserCoachingPage';
	const theme = useTheme();
	const user = useSelector(getUserSelected);
	const [form] = Form.useForm();
	// const [formValues, setFormValues] = useState()
	const [editedUser, setEditedUser] = useState({});

	const { notifyLoading, notifySuccess } = useContext(NotificationContext);

	const roleOptions = [...new Set(Object.values(JOB_ROLES))].map((value) => ({ value }));
	const skillOptions = [...new Set(TECH_STACK_GROUP_OPTIONS.map((t) => t.items).flat().map((t) => t.label))].map((value) => ({ value }));
	skillOptions.sort(sortOn({ string: 'value' }));

	const languageOptions = [];
	const proficiencyOptions = LANGUAGE_PROFICIENCY_OPTIONS;
	Object
		.values(LANGUAGE_TRANSLATIONS)
		.forEach(({ flag, languageLabel, level }) => {
			if (languageLabel) {
				const langSlug = slugify(languageLabel);
				const langOption = languageOptions.find((o) => o.value === langSlug);
				if (!langOption) {
					languageOptions.push({
						value: langSlug,
						flag,
						language: languageLabel,
						label: [flag, languageLabel].filter((i) => i).join(' '),
					});
				} else langOption.flag = flag || langOption.flag;
			}
		});

	languageOptions.sort(sortOn({ key: 'value' }));

	const onValuesChange = (values) => {
		LocalDebug.logInfo({ className, method: 'onValuesChange' }, editedUser);
	};

	const getEditedValues = () => {
		const fieldsValue = form.getFieldsValue();
		return {
			jobSearch: {
				salaryAsk: {
					...user?.jobSearch?.salaryAsk,
					range: fieldsValue?.jobSearch?.salaryAsk?.range,
					unit: fieldsValue?.jobSearch?.salaryAsk?.unit,
					period: fieldsValue?.jobSearch?.salaryAsk?.period,
					low: fieldsValue?.jobSearch?.salaryAsk?.range[0],
					high: fieldsValue?.jobSearch?.salaryAsk?.range[1],
				},
			},
			coaching: {
				...fieldsValue?.coaching,
				coachingCompanyCriteria: fieldsValue?.coaching?.coachingCompanyCriteria?.map((item) => `<p>${item}</p>`).join(''),
				coachingTargetPositions: fieldsValue?.coaching?.coachingTargetPositions?.map((item) => `<p>${item}</p>`).join(''),
				coachingHardSkills: fieldsValue?.coaching?.coachingHardSkills?.map((item) => `<p>${item}</p>`).join(''),
				coachingSoftSkills: fieldsValue?.coaching?.coachingSoftSkills?.map((item) => `<p>${item}</p>`).join(''),
				coachingLanguages: fieldsValue?.coaching?.coachingLanguages?.map((item) => `<p>${LANGUAGE_TRANSLATIONS[item.language]?.languageLabel || item.language}${item.proficiency ? `: ${LANGUAGE_PROFICIENCY_TRANSLATIONS[item.proficiency] || item.proficiency}` : ''}
				</p>`).join(''),
			},
		};
	};

	const updatePreview = () => {
		const fieldsValue = form.getFieldsValue();
		const editedValues = getEditedValues();
		setEditedUser({
			...user,
			// career: {
			// 	...user?.career,
			// },
			jobSearch: {
				...user?.jobSearch,
				...editedValues?.jobSearch,
			},
			coaching: {
				...user.coaching,
				...editedValues?.coaching,
			},
		});
	};

	if (!user) return null;

	const initialValues = {
		...user,
		career: {
			...user?.career,
		},
		coaching: {
			...user?.coaching,
			coachingTargetPositions: pTagsAsList(user?.coaching?.coachingTargetPositions),
			coachingCompanyCriteria: pTagsAsList(user?.coaching?.coachingCompanyCriteria),
			coachingHardSkills: pTagsAsList(user?.coaching?.coachingHardSkills),
			coachingSoftSkills: pTagsAsList(user?.coaching?.coachingSoftSkills),
			coachingLanguages: pTagsAsList(user?.coaching?.coachingLanguages).map((t) => ({ language: t })),
		},
	};

	const onFinish = async () => {
		// updatePreview();
		LocalDebug.logInfo({ className, method: 'onFinish' }, getEditedValues());
		notifyLoading({ key: className, description: <span>Updating profile...</span> });

		const data = {
			id: documentId(user),
			...getEditedValues(),
		};

		LocalDebug.logInfo({ className, method: 'onFormFinish' }, data);
		const result = await UserService.updateUser(data);

		LocalDebug.logInfo({ className, method: 'onFormFinish' }, result);

		// await UserService.updateProperty(documentId(currentUser), { key: 'profile-avatar', value: imageUrl });
		notifySuccess({
			key: className,
			description: <span>Coaching information updated for <b>{user.firstName}</b>!</span>,
			duration: 3,
		});
	};

	return (user
		&& <div style={{ padding: 32 }}>

			<h2>Coaching profile for {user.firstName} {user.lastName}</h2>
			<Form
				form={form}
				name='user-coaching'
				initialValues={initialValues}
				labelCol={{ span: 4 }}
				wrapperCol={{ span: 20 }}
				onValuesChange={onValuesChange}
				layout='vertical'
				onFinish={onFinish}
			>
				<FormItem
					label='Opinion'
					name={['coaching', 'coachingOpinion']}
				>
					<CKEditor />
				</FormItem>

				<FormItem
					label='Availability'
					name={['coaching', 'coachingAvailability']}
				 />

				<FormLister
					name={['coaching', 'coachingCompanyCriteria']}
					label={'Company criteria'}
					textAddBtn='Add a criteria'
					listItemBuilder={({ name, rest }) => (
						<FormItem
							{...rest}
							name={[name]}
							rules={[{ ...requiredRule, message: 'Criteria is required.' }]}
							label='Criteria'
						 />
					)}
				/>

				<FormItem
					label='Years of XP'
				>
					<Space size={20}>
						<FormItem
							label='YOE overall'
							name={['coaching', 'coachingExperience']}
						>
							<Input type='number' min={0} style={{ width: 100 }} />
						</FormItem>
						<FormItem
							label='YOE in tech'
							name={['coaching', 'coachingExperienceTech']}
						>
							<Input type='number' min={0} style={{ width: 100 }} />
						</FormItem>
					</Space>
				</FormItem>
				<FormItem
					label='Mobility'
					name={['coaching', 'coachingMobility']}
				 />

				<FormItem
					label='Salary'
					layout='vertical'
				>
					<Space
						direction='vertical'
						style={{
							background: theme.color.bgGrey,
							padding: 12,
						}}
					>
						<Space
							size={12}
						>

							<FormItem layout='vertical' label='Lower range'
									  name={['jobSearch', 'salaryAsk', 'range', 0]}>
								<Input type='number' min={0} style={{ width: 120 }} />
							</FormItem>

							<FormItem label='Higher range' name={['jobSearch', 'salaryAsk', 'range', 1]}>
								<Input type='number' min={0} style={{ width: 120 }} />
							</FormItem>

							<FormItem label='Unit' name={['jobSearch', 'salaryAsk', 'unit']}>
								<Select>
									<Select.Option value={'€'} label={'€'} />
									<Select.Option value={'$'} label={'$'} />
									<Select.Option value={'£'} label={'£'} />
									<Select.Option value={'CHF'} label={'CHF'} />
									<Select.Option value={'CA$'} label={'CA$'} />
								</Select>
							</FormItem>
							<FormItem label='Period' name={['jobSearch', 'salaryAsk', 'period']}>
								<Select>
									<Select.Option value={'year'} label={'Annual'} />
									<Select.Option value={'month'} label={'Monthly'} />
									<Select.Option value={'day'} label={'Daily'} />
								</Select>
							</FormItem>
						</Space>
						<FormItem
							label='Comment'
							name={['jobSearch', 'salaryAsk', 'comment']}
						 />
					</Space>
				</FormItem>

				<FormLister
					name={['coaching', 'coachingHardSkills']}
					label={'Hard Skills'}
					textAddBtn='Add a skill'
					listItemBuilder={({ name, rest }) => (
						<FormItem
							{...rest}
							name={[name]}
							rules={[{ ...requiredRule, message: 'Skill is required.' }]}
							label='Skill'
						>
							<AutoComplete
								style={{ width: 240 }}
								options={skillOptions}
								filterOption={true}
								// filterOption={(input, option) =>
								// 	(option?.label ?? '').toLowerCase().includes(input.toLowerCase())
								// }
							/>
						</FormItem>
					)}
				/>

				<FormLister
					name={['coaching', 'coachingSoftSkills']}
					label={'Soft Skills'}
					textAddBtn='Add a skill'
					listItemBuilder={({ name, rest }) => (
						<FormItem
							{...rest}
							name={[name]}
							rules={[{ ...requiredRule, message: 'Skill is required.' }]}
							label='Skill'
						>
							<AutoComplete
								style={{ width: 240 }}
								options={[]}
								filterOption={true}
							/>
						</FormItem>
					)}
				/>

				<FormLister
					name={['coaching', 'coachingTargetPositions']}
					label={'Target positions'}
					textAddBtn='Add a position'
					listItemBuilder={({ name, rest }) => (
						<FormItem
							{...rest}
							name={[name]}
							rules={[{ ...requiredRule, message: 'Position is required.' }]}
							label='Position'
						>
							<AutoComplete
								style={{ width: 240 }}
								options={roleOptions}
								filterOption={true}
							/>
						</FormItem>
					)}
				/>

				<FormLister
					name={['coaching', 'coachingLanguages']}
					label={'Languages'}
					textAddBtn='Add a language'
					listItemBuilder={({ name, rest }) => (
						<>
							<FormItem
								{...rest}
								name={[name, 'language']}
								rules={[{ ...requiredRule, message: 'Language is required.' }]}
								label='Language'
							>
								<AutoComplete
									style={{ width: 200 }}
									filterOption={true}
									options={languageOptions}
								/>
							</FormItem>
							<FormItem
								{...rest}
								name={[name, 'proficiency']}
								rules={[{ ...requiredRule, message: 'Proficiency is required.' }]}
								label='Proficiency'
							>
								<Select
									showSearch
									style={{ width: 200, margin: 0 }}
									filterOption={(input, option) => (option?.language ?? '').toLowerCase().includes(input.toLowerCase())
									}
									options={proficiencyOptions}
								/>
							</FormItem>
						</>
					)}
				/>

				<FormItem label='&nbsp;'>
					<FormSubmitButton size='large'>Submit</FormSubmitButton>
				</FormItem>
			</Form>

			<Box style={{ background: theme.color.bgGrey, padding: 20, borderRadius: 10 }}>
				<Space direction='vertical' size={20}>

					<h3>Preview</h3>
					<Button type='primary' size='large' onClick={updatePreview}>
						Update Preview
					</Button>
					{documentId(editedUser)
						&& <UserGridCardLayout
							leftColumn={<UserGridCardLeftColumn user={editedUser} />}
							rightColumn={<UserGridCardRightColumn user={editedUser} />}
							style={{ width: '100%' }}
						/>
					}
				</Space>
			</Box>

			<ReactJsonAdmin src={editedUser} />
		</div>
	);
};

export default UserCoachingPage;
