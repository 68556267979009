import { useDispatch, useSelector } from 'react-redux';
import { getCompanies, getCompanySelected } from '../../../reducers/app';
import ReactJsonDebug from '../../app/debug/ReactJsonDebug';
import {
	Col, Divider, Row, Spin,
} from 'antd';
import { useAuthContext } from '../../../hooks/useAuthContext';
import { useTheme } from '@emotion/react';
import { buildConfirmBtn, mergeReducer, sortOn } from '../../../utils/common';
import { useEffect, useState } from 'react';
import AdminService from '../../../services/admin';
import UserStub from '../../user/stub/UserStub';
import UserModel from '../../../models/UserModel';
import { FaSync } from 'react-icons/fa';
import * as adminActions from '../../../actions/admin';

const ProductUserSalaryExpectations = (props) => {
	const companies = useSelector(getCompanies);
	const companySelected = useSelector(getCompanySelected);

	const theme = useTheme();
	const dispatch = useDispatch();
	const { isStaff } = useAuthContext();
	const [isLoading, setIsLoading] = useState(true);
	const [users, setUsers] = useState([]);
	const [refreshData, setRefreshData] = useState(0);

	useEffect(async () => {
		setIsLoading(true);
		const { data: { results } } = await AdminService.getUserSalaryExpectations();
		setUsers(results);
		setIsLoading(false);
	}, [refreshData]);

	if (!isStaff) return null;

	return 'Blocked for performance reasons';
	
	return (
		<>
			<h1>User Salary Expectations</h1>
			{buildConfirmBtn({
				theme,
				title: 'Update salary expectations',
				Icon: FaSync,
				dispatch,
				dispatcher: adminActions.updateUserSalaryExpectations,
				callback: () => setRefreshData((p) => p + 1),
				styles: { style: { marginBottom: 10 } },
			})}

			<ReactJsonDebug src={{ users }} sortKeys={false} collapsed={0}/>

			{isLoading
				? <>
					<Spin/>
				</>
				: users?.map((user, index) => {
					const userModel = new UserModel(user);
					return (
						<>
							<Row style={{ width: '100%' }} key={index} wrap={false}>
								<Col span={8} style={{ padding: 10 }}>
									<UserStub user={user} withDropdown={true} withIsNew={false} withIsUnviewed={false}/>
								</Col>
								<Col span={8}
									 style={{
										padding: 10, fontSize: 20, fontWeight: 'bold', lineHeight: 1.2,
									}}>
									<div>
										{userModel?.getSalaryAskRangeLine()
											|| <span style={{ color: 'red' }}>???</span>}
									</div>
									<div style={{ opacity: 0.5 }}>
										{user?.coaching?.coachingSalaryExpectations}
									</div>
								</Col>
								<Col span={8} style={{ padding: 10 }}>
									<ReactJsonDebug src={user?.jobSearch?.salaryAsk} collapsed={0} name={''}/>
								</Col>
							</Row>
							<Divider style={{ margin: 5 }}/>
						</>
					);
				})}
		</>
	);
};

export default ProductUserSalaryExpectations;
