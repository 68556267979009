import { isStringTypeValidator } from '../../../utils/isStringTypeValidator';
import { innerObjPropExtractor } from '../../property/property-extractor';
import { innerObjPropMapper } from '../../property/property-mapper';

const USER_PROPERTY_JOB_SEARCH_EMAIL_CADENCE_KEY = 'job-search-email-cadence';

const USER_PROPERTY_JOB_SEARCH_EMAIL_CADENCE_MULTI = false;
const USER_PROPERTY_JOB_SEARCH_EMAIL_CADENCE_FREE = false;

const USER_PROPERTY_JOB_SEARCH_EMAIL_CADENCE_ANSWER_DAILY = 'daily';
const USER_PROPERTY_JOB_SEARCH_EMAIL_CADENCE_ANSWER_WEEKLY = 'weekly';
const USER_PROPERTY_JOB_SEARCH_EMAIL_CADENCE_ANSWER_NEVER = 'never';

const USER_PROPERTY_JOB_SEARCH_EMAIL_CADENCE_OPTIONS = [
	{ value: USER_PROPERTY_JOB_SEARCH_EMAIL_CADENCE_ANSWER_DAILY, label: 'Daily' },
	{ value: USER_PROPERTY_JOB_SEARCH_EMAIL_CADENCE_ANSWER_WEEKLY, label: 'Weekly' },
	{ value: USER_PROPERTY_JOB_SEARCH_EMAIL_CADENCE_ANSWER_NEVER, label: 'Never' },
];

const customValidator = (value) => USER_PROPERTY_JOB_SEARCH_EMAIL_CADENCE_OPTIONS.map((o) => o.value).includes(value);

const USER_PROPERTY_JOB_SEARCH_EMAIL_CADENCE_VALIDATORS = [
	isStringTypeValidator,
	customValidator,
];

const USER_PROPERTY_JOB_SEARCH_EMAIL_CADENCE_FIELD = 'jobSearch.emailCadence';
const USER_PROPERTY_JOB_SEARCH_EMAIL_CADENCE_MAPPER = innerObjPropMapper(USER_PROPERTY_JOB_SEARCH_EMAIL_CADENCE_FIELD);
const USER_PROPERTY_JOB_SEARCH_EMAIL_CADENCE_EXTRACTOR = innerObjPropExtractor(USER_PROPERTY_JOB_SEARCH_EMAIL_CADENCE_FIELD);

export default {
	USER_PROPERTY_JOB_SEARCH_EMAIL_CADENCE_ANSWER_DAILY,
	USER_PROPERTY_JOB_SEARCH_EMAIL_CADENCE_ANSWER_WEEKLY,
	USER_PROPERTY_JOB_SEARCH_EMAIL_CADENCE_ANSWER_NEVER,

	key: USER_PROPERTY_JOB_SEARCH_EMAIL_CADENCE_KEY,
	USER_PROPERTY_JOB_SEARCH_EMAIL_CADENCE_KEY,
	validators: USER_PROPERTY_JOB_SEARCH_EMAIL_CADENCE_VALIDATORS,
	USER_PROPERTY_JOB_SEARCH_EMAIL_CADENCE_VALIDATORS,
	free: USER_PROPERTY_JOB_SEARCH_EMAIL_CADENCE_FREE,
	USER_PROPERTY_JOB_SEARCH_EMAIL_CADENCE_FREE,
	multi: USER_PROPERTY_JOB_SEARCH_EMAIL_CADENCE_MULTI,
	USER_PROPERTY_JOB_SEARCH_EMAIL_CADENCE_MULTI,
	options: USER_PROPERTY_JOB_SEARCH_EMAIL_CADENCE_OPTIONS,
	USER_PROPERTY_JOB_SEARCH_EMAIL_CADENCE_OPTIONS,
	mapper: USER_PROPERTY_JOB_SEARCH_EMAIL_CADENCE_MAPPER,
	USER_PROPERTY_JOB_SEARCH_EMAIL_CADENCE_MAPPER,
	extractor: USER_PROPERTY_JOB_SEARCH_EMAIL_CADENCE_EXTRACTOR,
	USER_PROPERTY_JOB_SEARCH_EMAIL_CADENCE_EXTRACTOR,
	field: USER_PROPERTY_JOB_SEARCH_EMAIL_CADENCE_FIELD,
	USER_PROPERTY_JOB_SEARCH_EMAIL_CADENCE_FIELD,
};
