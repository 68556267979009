export const NATIVE_OR_BILINGUAL_VALUE = 'native-bilingual';
export const NATIVE_OR_BILINGUAL_LABEL = 'Native or Bilingual';
export const FULL_PROFESSIONAL_VALUE = 'full-professional';
export const FULL_PROFESSIONAL_LABEL = 'Full Professional';
export const PROFESSIONAL_WORKING_VALUE = 'professional-working';
export const PROFESSIONAL_WORKING_LABEL = 'Professional working';
export const LIMITED_WORKING_VALUE = 'limited-working';
export const LIMITED_WORKING_LABEL = 'Limited working';
export const ELEMENTARY_VALUE = 'elementary';
export const ELEMENTARY_LABEL = 'Elementary';

export const LANGUAGE_PROFICIENCY_OPTIONS = [
	{ value: NATIVE_OR_BILINGUAL_VALUE, label: NATIVE_OR_BILINGUAL_LABEL },
	{ value: FULL_PROFESSIONAL_VALUE, label: FULL_PROFESSIONAL_LABEL },
	{ value: PROFESSIONAL_WORKING_VALUE, label: PROFESSIONAL_WORKING_LABEL },
	{ value: LIMITED_WORKING_VALUE, label: LIMITED_WORKING_LABEL },
	{ value: ELEMENTARY_VALUE, label: ELEMENTARY_LABEL },
];

export const ALLOWED_LANGUAGE_PROFICIENCIES = [
	NATIVE_OR_BILINGUAL_LABEL,
	FULL_PROFESSIONAL_LABEL,
	PROFESSIONAL_WORKING_LABEL,
];

export const LANGUAGE_PROFICIENCY_TRANSLATIONS = {
	[NATIVE_OR_BILINGUAL_VALUE]: NATIVE_OR_BILINGUAL_LABEL,
	'native': NATIVE_OR_BILINGUAL_LABEL,
	'bilingual': NATIVE_OR_BILINGUAL_LABEL,
	'mother tongue': NATIVE_OR_BILINGUAL_LABEL,
	'mother\'s tongue': NATIVE_OR_BILINGUAL_LABEL,
	'native speaker': NATIVE_OR_BILINGUAL_LABEL,
	'native or bilingual proficiency': NATIVE_OR_BILINGUAL_LABEL,

	[FULL_PROFESSIONAL_VALUE]: FULL_PROFESSIONAL_LABEL,
	'fluent': FULL_PROFESSIONAL_LABEL,
	'fluent (c1)': FULL_PROFESSIONAL_LABEL,
	'courant': FULL_PROFESSIONAL_LABEL,
	'proficiency': FULL_PROFESSIONAL_LABEL,

	[PROFESSIONAL_WORKING_VALUE]: PROFESSIONAL_WORKING_LABEL,
	'upper intermediate': PROFESSIONAL_WORKING_LABEL,
	'professional working proficiency': PROFESSIONAL_WORKING_LABEL,

	[LIMITED_WORKING_VALUE]: LIMITED_WORKING_LABEL,
	'intermediate': LIMITED_WORKING_LABEL,
	'conversational profeciency': LIMITED_WORKING_LABEL,
	'essential working': LIMITED_WORKING_LABEL,
	'intermediate level': LIMITED_WORKING_LABEL,

	[ELEMENTARY_VALUE]: ELEMENTARY_LABEL,
	'basic': ELEMENTARY_LABEL,
	'basical': ELEMENTARY_LABEL,
	'basics': ELEMENTARY_LABEL,
	'begginer': ELEMENTARY_LABEL,
	'beginner': ELEMENTARY_LABEL,
	'beginer': ELEMENTARY_LABEL,
};

export const LANGUAGE_DEFAULT_FLAG = <span>🏳️</span>;

export const LANGUAGE_TRANSLATIONS = {
	'english': { languageLabel: 'English', flag: '🇬🇧' },
	'anglais': { languageLabel: 'English', flag: '🇬🇧' },
	'englisch': { languageLabel: 'English', flag: '🇬🇧' },
	'angielski': { languageLabel: 'English', flag: '🇬🇧' },
	'english fluent': { languageLabel: 'English', flag: '🇬🇧', level: FULL_PROFESSIONAL_VALUE },
	'english (fluent)': { languageLabel: 'English', flag: '🇬🇧', level: FULL_PROFESSIONAL_VALUE },
	'english (native)': { languageLabel: 'English', flag: '🇬🇧', level: NATIVE_OR_BILINGUAL_VALUE },
	'english (bilingual)': { languageLabel: 'English', flag: '🇬🇧', level: NATIVE_OR_BILINGUAL_VALUE },
	'english (c2)': { languageLabel: 'English', flag: '🇬🇧', level: NATIVE_OR_BILINGUAL_VALUE },
	'fluent english': { languageLabel: 'English', flag: '🇬🇧', level: FULL_PROFESSIONAL_VALUE },
	'bilingual english': { languageLabel: 'English', flag: '🇬🇧', level: NATIVE_OR_BILINGUAL_VALUE },
	'fluent in english': { languageLabel: 'English', flag: '🇬🇧', level: FULL_PROFESSIONAL_VALUE },
	'английский': { languageLabel: 'English', flag: '🇬🇧' },
	'eng': { languageLabel: 'English', flag: '🇬🇧' },
	'engels': { languageLabel: 'English', flag: '🇬🇧' },
	'engelska': { languageLabel: 'English', flag: '🇬🇧' },
	'engish': { languageLabel: 'English', flag: '🇬🇧' },
	'engleză': { languageLabel: 'English', flag: '🇬🇧' },
	'english ': { languageLabel: 'English', flag: '🇬🇧' },
	'english (proficient)': { languageLabel: 'English', flag: '🇬🇧' },
	'english (toeic 970)': { languageLabel: 'English', flag: '🇬🇧' },
	'english - toeic 890/990 points (2018)': { languageLabel: 'English', flag: '🇬🇧' },
	'english fluent ': { languageLabel: 'English', flag: '🇬🇧' },
	'english | toefl certified': { languageLabel: 'English', flag: '🇬🇧' },
	'english( toefl score- 100)': { languageLabel: 'English', flag: '🇬🇧' },
	'anglais ': { languageLabel: 'English', flag: '🇬🇧' },
	'anglais (courant)': { languageLabel: 'English', flag: '🇬🇧' },
	'anglais (toeic : 860)': { languageLabel: 'English', flag: '🇬🇧' },
	'anglais (toeic : 945)': { languageLabel: 'English', flag: '🇬🇧' },
	'anglais (toeic : 975/990)': { languageLabel: 'English', flag: '🇬🇧' },
	'anglais (toiec 935)': { languageLabel: 'English', flag: '🇬🇧' },
	'anglais - 985 toeic': { languageLabel: 'English', flag: '🇬🇧' },
	'anglais - c1': { languageLabel: 'English', flag: '🇬🇧', level: FULL_PROFESSIONAL_VALUE },
	'anglais - english': { languageLabel: 'English', flag: '🇬🇧' },
	'anglais c1': { languageLabel: 'English', flag: '🇬🇧', level: FULL_PROFESSIONAL_VALUE },
	'anglais c2': { languageLabel: 'English', flag: '🇬🇧', level: NATIVE_OR_BILINGUAL_VALUE },
	'anglais courant': { languageLabel: 'English', flag: '🇬🇧' },
	'anglais toeic 840': { languageLabel: 'English', flag: '🇬🇧' },
	'anglais,': { languageLabel: 'English', flag: '🇬🇧' },
	'anglais, bon niveau': { languageLabel: 'English', flag: '🇬🇧' },
	'angličtina': { languageLabel: 'English', flag: '🇬🇧' },
	'/ comes with a fluent level of english having worked full scope in international organisations for over 15 years.': {
		languageLabel: 'English', flag: '🇬🇧', level: FULL_PROFESSIONAL_VALUE,
	},
	'résultatstoeic avril 2015 : 960 sur 990': { languageLabel: 'English', flag: '🇬🇧' },
	'ingles ': { languageLabel: 'English', flag: '🇬🇧' },
	'inglese': { languageLabel: 'English', flag: '🇬🇧' },
	'inglés': { languageLabel: 'English', flag: '🇬🇧' },
	'inglês': { languageLabel: 'English', flag: '🇬🇧' },
	'ingles': { languageLabel: 'English', flag: '🇬🇧' },
	'i̇ngilizce': { languageLabel: 'English', flag: '🇬🇧' },

	'irish': { languageLabel: 'Irish', flag: '🇮🇪' },

	'fluent': { languageLabel: 'French', flag: '🇫🇷' },
	'french': { languageLabel: 'French', flag: '🇫🇷' },
	'français': { languageLabel: 'French', flag: '🇫🇷' },
	'francais': { languageLabel: 'French', flag: '🇫🇷' },
	'francaise': { languageLabel: 'French', flag: '🇫🇷' },
	'french mother tongue': { languageLabel: 'French', flag: '🇫🇷', level: NATIVE_OR_BILINGUAL_VALUE },
	'french (mother tongue)': { languageLabel: 'French', flag: '🇫🇷', level: NATIVE_OR_BILINGUAL_VALUE },
	'french native': { languageLabel: 'French', flag: '🇫🇷', level: NATIVE_OR_BILINGUAL_VALUE },
	'french fluent': { languageLabel: 'French', flag: '🇫🇷', level: FULL_PROFESSIONAL_VALUE },
	'french (fluent)': { languageLabel: 'French', flag: '🇫🇷', level: FULL_PROFESSIONAL_VALUE },
	'french (native)': { languageLabel: 'French', flag: '🇫🇷', level: NATIVE_OR_BILINGUAL_VALUE },
	'french (a2)': { languageLabel: 'French', flag: '🇫🇷', level: LIMITED_WORKING_VALUE },
	'french (c1)': { languageLabel: 'French', flag: '🇫🇷', level: FULL_PROFESSIONAL_VALUE },
	'intermediate in french': { languageLabel: 'French', flag: '🇫🇷', level: LIMITED_WORKING_VALUE },
	'beginner in french': { languageLabel: 'French', flag: '🇫🇷', level: ELEMENTARY_VALUE },
	'fra': { languageLabel: 'French', flag: '🇫🇷' },
	'frances': { languageLabel: 'French', flag: '🇫🇷' },
	'francese': { languageLabel: 'French', flag: '🇫🇷' },
	'franceza': { languageLabel: 'French', flag: '🇫🇷' },
	'francuski': { languageLabel: 'French', flag: '🇫🇷' },
	'francés': { languageLabel: 'French', flag: '🇫🇷' },
	'francês': { languageLabel: 'French', flag: '🇫🇷' },
	'frans': { languageLabel: 'French', flag: '🇫🇷' },
	'französisch': { languageLabel: 'French', flag: '🇫🇷' },
	'français ': { languageLabel: 'French', flag: '🇫🇷' },
	'français - french': { languageLabel: 'French', flag: '🇫🇷' },
	'français c2': { languageLabel: 'French', flag: '🇫🇷', level: NATIVE_OR_BILINGUAL_VALUE },
	'french ': { languageLabel: 'French', flag: '🇫🇷' },
	'french (b2)': { languageLabel: 'French', flag: '🇫🇷', level: PROFESSIONAL_WORKING_VALUE },
	'french basic': { languageLabel: 'French', flag: '🇫🇷', level: ELEMENTARY_VALUE },
	'fançais': { languageLabel: 'French', flag: '🇫🇷' },
	'french | dalf c1 certified': { languageLabel: 'French', flag: '🇫🇷', level: FULL_PROFESSIONAL_VALUE },
	'french(learning)': { languageLabel: 'French', flag: '🇫🇷', level: ELEMENTARY_VALUE },
	'emmanuelle is a french native speaker': { languageLabel: 'French', flag: '🇫🇷', level: NATIVE_OR_BILINGUAL_VALUE },
	'французский': { languageLabel: 'French', flag: '🇫🇷' },

	'espagnol': { languageLabel: 'Spanish', flag: '🇪🇸' },
	'espanol': { languageLabel: 'Spanish', flag: '🇪🇸' },
	'spanish': { languageLabel: 'Spanish', flag: '🇪🇸' },
	'spanish (native)': { languageLabel: 'Spanish', flag: '🇪🇸', level: NATIVE_OR_BILINGUAL_VALUE },
	'spanish (a2)': { languageLabel: 'Spanish', flag: '🇪🇸', level: LIMITED_WORKING_VALUE },
	'good level in spanish': { languageLabel: 'Spanish', flag: '🇪🇸', level: PROFESSIONAL_WORKING_VALUE },
	'hiszpański': { languageLabel: 'Spanish', flag: '🇪🇸' },
	'espagnol - b1': { languageLabel: 'Spanish', flag: '🇪🇸', level: LIMITED_WORKING_VALUE },
	'espagnol -spanish': { languageLabel: 'Spanish', flag: '🇪🇸' },
	'espagnol c1': { languageLabel: 'Spanish', flag: '🇪🇸', level: FULL_PROFESSIONAL_VALUE },
	'espanhol': { languageLabel: 'Spanish', flag: '🇪🇸' },
	'español': { languageLabel: 'Spanish', flag: '🇪🇸' },
	'castellano': { languageLabel: 'Spanish', flag: '🇪🇸' },
	'she speaks as well spanish level b2.': {
		languageLabel: 'Spanish', flag: '🇪🇸', level: PROFESSIONAL_WORKING_VALUE,
	},
	'i̇spanyolca': { languageLabel: 'Spanish', flag: '🇪🇸' },
	'spagnolo': { languageLabel: 'Spanish', flag: '🇪🇸' },
	'spanisch': { languageLabel: 'Spanish', flag: '🇪🇸' },
	'spanish - beginers': { languageLabel: 'Spanish', flag: '🇪🇸', level: ELEMENTARY_VALUE },
	'spanish | native': { languageLabel: 'Spanish', flag: '🇪🇸', level: NATIVE_OR_BILINGUAL_VALUE },

	'valencian': { languageLabel: 'Valencian' },
	'catalan': { languageLabel: 'Catalan' },
	'català': { languageLabel: 'Catalan' },
	'catalán': { languageLabel: 'Catalan' },
	'euskara': { languageLabel: 'Euskera' },
	'euskera': { languageLabel: 'Euskera' },
	'basque': { languageLabel: 'Euskera' },

	'german': { languageLabel: 'German', flag: '🇩🇪' },
	'germany': { languageLabel: 'German', flag: '🇩🇪' },
	'deutsch': { languageLabel: 'German', flag: '🇩🇪' },
	'allemand': { languageLabel: 'German', flag: '🇩🇪' },
	'german fluent': { languageLabel: 'German', flag: '🇩🇪', level: FULL_PROFESSIONAL_VALUE },
	'beginner in german': { languageLabel: 'German', flag: '🇩🇪', level: ELEMENTARY_VALUE },
	'geman': { languageLabel: 'German', flag: '🇩🇪' },
	'german ': { languageLabel: 'German', flag: '🇩🇪' },
	'german (currently in b2 class) (limited working)': {
		languageLabel: 'German', flag: '🇩🇪', level: LIMITED_WORKING_VALUE,
	},
	'german language': { languageLabel: 'German', flag: '🇩🇪' },
	'germany ': { languageLabel: 'German', flag: '🇩🇪' },
	'aleman': { languageLabel: 'German', flag: '🇩🇪' },
	'alemán': { languageLabel: 'German', flag: '🇩🇪' },
	'alemão': { languageLabel: 'German', flag: '🇩🇪' },
	'allemand - niveau c1': { languageLabel: 'German', flag: '🇩🇪', level: FULL_PROFESSIONAL_VALUE },
	'allemand b1': { languageLabel: 'German', flag: '🇩🇪', level: LIMITED_WORKING_VALUE },
	'allemend ': { languageLabel: 'German', flag: '🇩🇪' },
	'niemiecki': { languageLabel: 'German', flag: '🇩🇪' },
	'немецкий': { languageLabel: 'German', flag: '🇩🇪' },

	'italien': { languageLabel: 'Italian', flag: '🇮🇹' },
	'italian': { languageLabel: 'Italian', flag: '🇮🇹' },
	'italian (native)': { languageLabel: 'Italian', flag: '🇮🇹', level: NATIVE_OR_BILINGUAL_VALUE },
	'italian ': { languageLabel: 'Italian', flag: '🇮🇹' },
	'italian mother tongue': { languageLabel: 'Italian', flag: '🇮🇹', level: NATIVE_OR_BILINGUAL_VALUE },
	'italiano': { languageLabel: 'Italian', flag: '🇮🇹' },
	'italien (intermédiaire)': { languageLabel: 'Italian', flag: '🇮🇹', level: LIMITED_WORKING_VALUE },
	'italien c1': { languageLabel: 'Italian', flag: '🇮🇹', level: FULL_PROFESSIONAL_VALUE },
	'italienisch': { languageLabel: 'Italian', flag: '🇮🇹' },
	'włoski': { languageLabel: 'Italian', flag: '🇮🇹' },
	'итальянский': { languageLabel: 'Italian', flag: '🇮🇹' },

	'russian': { languageLabel: 'Russian', flag: '🇷🇺' },
	'russe': { languageLabel: 'Russian', flag: '🇷🇺' },
	'russian native': { languageLabel: 'Russian', flag: '🇷🇺', level: NATIVE_OR_BILINGUAL_VALUE },
	'русский': { languageLabel: 'Russian', flag: '🇷🇺', level: NATIVE_OR_BILINGUAL_VALUE },
	'ruso': { languageLabel: 'Russian', flag: '🇷🇺' },
	'russisch': { languageLabel: 'Russian', flag: '🇷🇺' },
	'russo': { languageLabel: 'Russian', flag: '🇷🇺' },
	'rusça': { languageLabel: 'Russian', flag: '🇷🇺' },
	'ruština': { languageLabel: 'Russian', flag: '🇷🇺' },

	'korean': { languageLabel: 'Korean', flag: '🇰🇷' },
	'coréen': { languageLabel: 'Korean', flag: '🇰🇷' },
	'koreanisch': { languageLabel: 'Korean', flag: '🇰🇷' },

	'japonais': { languageLabel: 'Japanese', flag: '🇯🇵' },
	'japanese': { languageLabel: 'Japanese', flag: '🇯🇵' },
	'japonese': { languageLabel: 'Japanese', flag: '🇯🇵' },
	'japanese (conversational)': { languageLabel: 'Japanese', flag: '🇯🇵', level: PROFESSIONAL_WORKING_VALUE },
	'japonais (débutante)': { languageLabel: 'Japanese', flag: '🇯🇵', level: ELEMENTARY_VALUE },
	'japoński': { languageLabel: 'Japanese', flag: '🇯🇵' },

	'turkish': { languageLabel: 'Turkish', flag: '🇹🇷' },
	'turc': { languageLabel: 'Turkish', flag: '🇹🇷' },

	'arabic': { languageLabel: 'Arabic' },
	'arabe': { languageLabel: 'Arabic' },
	'arabe ': { languageLabel: 'Arabic' },
	'arabe - a1': { languageLabel: 'Arabic', level: ELEMENTARY_VALUE },
	'arabe b2': { languageLabel: 'Arabic' },
	'arabic ': { languageLabel: 'Arabic' },
	'arabic (algerian dialect)': { languageLabel: 'Arabic' },
	'basic arabic': { languageLabel: 'Arabic', level: ELEMENTARY_VALUE },
	'árabe': { languageLabel: 'Arabic' },

	'hebreux': { languageLabel: 'Hebrew', flag: '🇮🇱' },
	'hebrew': { languageLabel: 'Hebrew', flag: '🇮🇱' },
	'hebräisch': { languageLabel: 'Hebrew', flag: '🇮🇱' },
	'hébreu': { languageLabel: 'Hebrew', flag: '🇮🇱' },

	'dutch': { languageLabel: 'Dutch', flag: '🇳🇱' },
	'néerlandais': { languageLabel: 'Dutch', flag: '🇳🇱' },
	'flemish (dutch)': { languageLabel: 'Dutch', flag: '🇳🇱' },
	'niederländisch': { languageLabel: 'Dutch', flag: '🇳🇱' },
	'nederlands': { languageLabel: 'Dutch', flag: '🇳🇱' },

	'flemish': { languageLabel: 'Flemish', flag: '🇧🇪' },
	'flamand': { languageLabel: 'Flemish', flag: '🇧🇪' },

	'chinese': { languageLabel: 'Chinese', flag: '🇨🇳' },
	'chinois': { languageLabel: 'Chinese', flag: '🇨🇳' },
	'chinese (a1)': { languageLabel: 'Chinese', flag: '🇨🇳', level: ELEMENTARY_VALUE },
	'chinese (b2)': { languageLabel: 'Chinese', flag: '🇨🇳', level: PROFESSIONAL_WORKING_VALUE },
	'chinese (c1)': { languageLabel: 'Chinese', flag: '🇨🇳', level: FULL_PROFESSIONAL_VALUE },
	'mandarin': { languageLabel: 'Chinese', flag: '🇨🇳' },
	'mandarin- chinese': { languageLabel: 'Chinese', flag: '🇨🇳' },
	'chinease': { languageLabel: 'Chinese', flag: '🇨🇳' },
	'chinese (simplified)': { languageLabel: 'Chinese', flag: '🇨🇳' },
	'chinese - hsk 5 level': { languageLabel: 'Chinese', flag: '🇨🇳' },
	'chinese mandarin': { languageLabel: 'Chinese', flag: '🇨🇳' },
	'chinesisch': { languageLabel: 'Chinese', flag: '🇨🇳' },
	'chino': { languageLabel: 'Chinese', flag: '🇨🇳' },
	'chino mandarín': { languageLabel: 'Chinese', flag: '🇨🇳' },
	'chinois (mandarin)': { languageLabel: 'Chinese', flag: '🇨🇳' },
	'chinois (simplifié)': { languageLabel: 'Chinese', flag: '🇨🇳' },
	'chinois (traditionnel)': { languageLabel: 'Chinese', flag: '🇨🇳' },
	'chinois mandarin courant': { languageLabel: 'Chinese', flag: '🇨🇳' },
	'chiński (uproszczony)': { languageLabel: 'Chinese', flag: '🇨🇳' },
	'cinese': { languageLabel: 'Chinese', flag: '🇨🇳' },
	'mandarin chinese': { languageLabel: 'Chinese', flag: '🇨🇳' },
	'китайский': { languageLabel: 'Chinese', flag: '🇨🇳' },

	'cantonese': { languageLabel: 'Cantonese' },
	'vietnamese': { languageLabel: 'Vietnamese', flag: '🇻🇳' },
	'vietnamien': { languageLabel: 'Vietnamese', flag: '🇻🇳' },
	'cambodian': { languageLabel: 'Cambodian', flag: '🇰🇭' },
	'central khmer': { languageLabel: 'Cambodian', flag: '🇰🇭' },
	'khmer': { languageLabel: 'Cambodian', flag: '🇰🇭' },
	'nepali': { languageLabel: 'Nepalese', flag: '🇳🇵' },
	'indonesian': { languageLabel: 'Indonesian', flag: '🇮🇩' },
	'indonesisch': { languageLabel: 'Indonesian', flag: '🇮🇩' },
	'myanmar': { languageLabel: 'Burmese', flag: '🇲🇲' },
	'taiwanese': { languageLabel: 'Taiwanese', flag: '🇹🇼' },
	'thai': { languageLabel: 'Thai', flag: '🇹🇭' },
	'thaï': { languageLabel: 'Thai', flag: '🇹🇭' },

	'portuguese': { languageLabel: 'Portuguese', flag: '🇵🇹' },
	'portugese': { languageLabel: 'Portuguese', flag: '🇵🇹' },
	'portuguese (c1)': { languageLabel: 'Portuguese', flag: '🇵🇹', level: FULL_PROFESSIONAL_VALUE },
	'portugais': { languageLabel: 'Portuguese', flag: '🇵🇹' },
	'portugiesisch': { languageLabel: 'Portuguese', flag: '🇵🇹' },
	'portuguese (native speaker)': { languageLabel: 'Portuguese', flag: '🇵🇹', level: NATIVE_OR_BILINGUAL_VALUE },
	'portoghese': { languageLabel: 'Portuguese', flag: '🇵🇹' },
	'portugais européen': { languageLabel: 'Portuguese', flag: '🇵🇹' },
	'portuguais': { languageLabel: 'Portuguese', flag: '🇵🇹' },
	'portugues': { languageLabel: 'Portuguese', flag: '🇵🇹' },
	'portugués': { languageLabel: 'Portuguese', flag: '🇵🇹' },
	'português': { languageLabel: 'Portuguese', flag: '🇵🇹' },

	'portugais du brésil': { languageLabel: 'Brazilian Portuguese', flag: '🇧🇷' },
	'portugais (brésil)': { languageLabel: 'Brazilian Portuguese', flag: '🇧🇷' },
	'portugais - portuguese': { languageLabel: 'Brazilian Portuguese', flag: '🇧🇷' },
	'portugais brésilien': { languageLabel: 'Brazilian Portuguese', flag: '🇧🇷' },
	'portuguese (brazilian)': { languageLabel: 'Brazilian Portuguese', flag: '🇧🇷' },
	'brazilian-portuguese': { languageLabel: 'Brazilian Portuguese', flag: '🇧🇷' },

	'afrikaans': { languageLabel: 'Afrikaans', flag: '🇿🇦' },
	'armenian': { languageLabel: 'Armenian', flag: '🇦🇲' },
	'belarusian': { languageLabel: 'Belarusian', flag: '🇧🇾' },
	'белорусский': { languageLabel: 'Belarusian', flag: '🇧🇾' },
	'albanais': { languageLabel: 'Albanian', flag: '🇦🇱' },
	'albanian': { languageLabel: 'Albanian', flag: '🇦🇱' },

	'hindi': { languageLabel: 'Hindi', flag: '🇮🇳' },

	'ukrainian': { languageLabel: 'Ukrainian', flag: '🇺🇦' },
	'ukrainien': { languageLabel: 'Ukrainian', flag: '🇺🇦' },
	'ukrainian ': { languageLabel: 'Ukrainian', flag: '🇺🇦' },
	'ukrainian, russian': { languageLabel: 'Ukrainian', flag: '🇺🇦' },
	'украинский': { languageLabel: 'Ukrainian', flag: '🇺🇦' },

	'greek': { languageLabel: 'Greek', flag: '🇬🇷' },
	'grec': { languageLabel: 'Greek', flag: '🇬🇷' },
	'greek, ancient (to 1453) (professional working)': { languageLabel: 'Greek', flag: '🇬🇷' },
	'griego moderno': { languageLabel: 'Greek', flag: '🇬🇷' },

	'romanian': { languageLabel: 'Romanian', flag: '🇷🇴' },
	'roumain': { languageLabel: 'Romanian', flag: '🇷🇴' },
	'română': { languageLabel: 'Romanian', flag: '🇷🇴' },
	'romana': { languageLabel: 'Romanian', flag: '🇷🇴' },
	'rumano': { languageLabel: 'Romanian', flag: '🇷🇴' },

	'hungarian': { languageLabel: 'Hungarian', flag: '🇭🇺' },
	'hongrois': { languageLabel: 'Hungarian', flag: '🇭🇺' },

	'bulgarian': { languageLabel: 'Bulgarian', flag: '🇧🇬' },
	'bulgare': { languageLabel: 'Bulgarian', flag: '🇧🇬' },
	'bulgarca': { languageLabel: 'Bulgarian', flag: '🇧🇬' },

	'polish': { languageLabel: 'Polish', flag: '🇵🇱' },
	'polski': { languageLabel: 'Polish', flag: '🇵🇱' },
	'polonais': { languageLabel: 'Polish', flag: '🇵🇱' },
	'polnisch': { languageLabel: 'Polish', flag: '🇵🇱' },
	'polonês': { languageLabel: 'Polish', flag: '🇵🇱' },
	'polština': { languageLabel: 'Polish', flag: '🇵🇱' },
	'польский': { languageLabel: 'Polish', flag: '🇵🇱' },

	'swedish': { languageLabel: 'Swedish', flag: '🇸🇪' },
	'suédois': { languageLabel: 'Swedish', flag: '🇸🇪' },
	'svenska': { languageLabel: 'Swedish', flag: '🇸🇪' },
	'švédština': { languageLabel: 'Swedish', flag: '🇸🇪' },

	'danish': { languageLabel: 'Danish', flag: '🇩🇰' },
	'dansk': { languageLabel: 'Danish', flag: '🇩🇰' },
	'dänisch': { languageLabel: 'Danish', flag: '🇩🇰' },

	'bokmål, norwegian': { languageLabel: 'Bokmål', flag: '🇳🇴' },
	'bokmål, norwegian - b1 / b2 (limited working)': {
		languageLabel: 'Bokmål', flag: '🇳🇴', level: LIMITED_WORKING_VALUE,
	},
	'norvégien': { languageLabel: 'Norwegian', flag: '🇳🇴' },
	'norwegian': { languageLabel: 'Norwegian', flag: '🇳🇴' },
	'nynorsk, norwegian': { languageLabel: 'Norwegian', flag: '🇳🇴' },

	'finnish': { languageLabel: 'Finnish', flag: '🇫🇮' },
	'finnois (débutant)': { languageLabel: 'Finnish', flag: '🇫🇮', level: ELEMENTARY_VALUE },

	'icelandic': { languageLabel: 'Icelandic', flag: '🇮🇸' },

	'kyrgyz': { languageLabel: 'Kyrgyz' },
	'maltese': { languageLabel: 'Maltese', flag: '🇲🇹' },

	'schwiizerdüütsch': { languageLabel: 'Swiss german', flag: '🇨🇭' },
	'swiss german': { languageLabel: 'Swiss german', flag: '🇨🇭' },

	'macedonian': { languageLabel: 'Macedonian', flag: '🇲🇰' },
	'macédonien': { languageLabel: 'Macedonian', flag: '🇲🇰' },
	'kazakh': { languageLabel: 'Kazakh', flag: '🇰🇿' },
	'latvian': { languageLabel: 'Latvian', flag: '🇱🇻' },
	'lithuanian': { languageLabel: 'Lithuanian', flag: '🇱🇹' },
	'luxembourgish': { languageLabel: 'Luxembourgish', flag: '🇱🇺' },

	'serbian': { languageLabel: 'Serbian', flag: '🇷🇸' },
	'serbe': { languageLabel: 'Serbian', flag: '🇷🇸' },
	'slovak': { languageLabel: 'Slovak', flag: '🇸🇰' },
	'slovene': { languageLabel: 'Slovenian', flag: '🇸🇮' },
	'slovenian': { languageLabel: 'Slovenian', flag: '🇸🇮' },
	'slovenština': { languageLabel: 'Slovenian', flag: '🇸🇮' },

	'türkçe': { languageLabel: 'Turkish', flag: '🇹🇷' },
	'türkçe ': { languageLabel: 'Turkish', flag: '🇹🇷' },

	'croate': { languageLabel: 'Croatian', flag: '🇭🇷' },
	'croatian': { languageLabel: 'Croatian', flag: '🇭🇷' },

	'czech': { languageLabel: 'Czech', flag: '🇨🇿' },
	'čeština': { languageLabel: 'Czech', flag: '🇨🇿' },
	'čeština ': { languageLabel: 'Czech', flag: '🇨🇿' },
	'tchèque': { languageLabel: 'Czech', flag: '🇨🇿' },

	'estonian': { languageLabel: 'Estonian', flag: '🇪🇪' },

	'filipino': { languageLabel: 'Philipinno', flag: '🇵🇭' },

	'igbo': { languageLabel: 'Igbo' },
	'julia': { languageLabel: 'Julia' },
	'kabyle': { languageLabel: 'Kabyle' },
	'kannada': { languageLabel: 'Kannada' },
	'kikuyu': { languageLabel: 'Kikuyu' },
	'kiswahili': { languageLabel: 'Kiswahili' },
	'kituba': { languageLabel: 'Kituba' },
	'kurdish': { languageLabel: 'Kurdish' },
	'langues berbères': { languageLabel: 'langues berbères' },
	'langues des signes française (lsf) (elementary)': { languageLabel: 'langues des signes française (lsf) (elementary)' },
	'lari': { languageLabel: 'Lari' },
	'latin': { languageLabel: 'Latin' },
	'level 1 british sign language (elementary)': {
		languageLabel: 'British sign language', level: ELEMENTARY_VALUE,
	},
	'libras': { languageLabel: 'Libras' },
	'lingala': { languageLabel: 'Lingala' },
	'maithili': { languageLabel: 'Maithili' },
	'malagasy': { languageLabel: 'Malagasy' },
	'malay': { languageLabel: 'Malay' },
	'malayalam': { languageLabel: 'Malayalam' },
	'malgache': { languageLabel: 'Malgache' },
	'mandingue': { languageLabel: 'mandingue' },
	'marathi': { languageLabel: 'marathi' },
	'moyen anglais (1100-1500)': { languageLabel: 'Middle English (1100-1500)' },
	'němčina': { languageLabel: 'Němčina' },
	'odia': { languageLabel: 'Odia' },
	'pashto': { languageLabel: 'Pashto' },
	'persian': { languageLabel: 'Persian' },
	'persian ': { languageLabel: 'Persian ' },
	'punjabi': { languageLabel: 'Punjabi' },
	'pushto': { languageLabel: 'Pushto' },
	'sanskrit': { languageLabel: 'Sanskrit' },
	'sariki': { languageLabel: 'Sariki' },
	'saxon, low': { languageLabel: 'Saxon' },
	'almanca': { languageLabel: 'Almanca' },
	'amharic': { languageLabel: 'Amharic' },
	'amharique': { languageLabel: 'Amharic' },
	'andalûh': { languageLabel: 'Andalou' },
	'american sign language (professional working)': {
		language: 'American Sign Language', level: FULL_PROFESSIONAL_VALUE,
	},
	'asl': { languageLabel: 'American Sign Language' },
	'azerbaijani': { languageLabel: 'Azerbaijanese' },
	'bambara': { languageLabel: 'Bambara' },
	'bengali': { languageLabel: 'Bengali' },
	'berbère': { languageLabel: 'Berberian' },
	'breton': { languageLabel: 'Breton' },
	'british sign language': { languageLabel: 'British Sign Language' },
	'creoles and pidgins, english-based (full professional)': { languageLabel: 'Creole' },
	'creoles and pidgins, french-based (native or bilingual)': { languageLabel: 'Creole' },
	'creoles and pidgins, portuguese-based': { languageLabel: 'Creole' },
	'crimean tatar': { languageLabel: 'Crimean tatar' },
	'créole': { languageLabel: 'Creole' },
	'créole et pidgin, basés sur le français': { languageLabel: 'Creole' },
	'créole haïtien': { languageLabel: 'Creole' },
	'dari': { languageLabel: 'Dari' },
	'duits': { languageLabel: 'Duits' },
	'efik': { languageLabel: 'Efik' },
	'gallego': { languageLabel: 'Gallego' },
	'garhwali': { languageLabel: 'Garhwali' },
	'gujarati': { languageLabel: 'Gujarati' },
	'haitian creole': { languageLabel: 'Creole' },
	'hausa': { languageLabel: 'Hausa', flag: '🇨🇫' },
	'hindko': { languageLabel: 'Hindko' },
	'setswana': { languageLabel: 'Setswana' },
	'sign languages': { languageLabel: 'Sign languages' },
	'sindhi': { languageLabel: 'Sindhi' },
	'sinhalese': { languageLabel: 'Sinhalese' },
	'somali': { languageLabel: 'Somali' },
	'sotho, southern': { languageLabel: 'Sotho, southern' },
	'swahili': { languageLabel: 'Swahili' },
	'tagalog': { languageLabel: 'Tagalog' },
	'tamil': { languageLabel: 'Tamil' },
	'tatar': { languageLabel: 'Tatar' },
	'tchétchène': { languageLabel: 'Tchétchène' },
	'tedesco': { languageLabel: 'Tedesco' },
	'telugu': { languageLabel: 'Telugu' },
	'tulu': { languageLabel: 'Tulu' },
	'twi': { languageLabel: 'Twi' },
	'tysk': { languageLabel: 'Tysk' },
	'urdu': { languageLabel: 'Urdu' },
	'uzbek': { languageLabel: 'Uzbek', flag: '🇺🇿' },
	'wolof': { languageLabel: 'Wolof' },
	'yiddish': { languageLabel: 'Yiddish' },
	'yoruba': { languageLabel: 'Yoruba' },
	'zulu': { languageLabel: 'Zulu' },

};

export const getPrettifiedLanguageLabel = (language) => {
	const languageMeta = LANGUAGE_TRANSLATIONS?.[language?.toLowerCase()];
	if (languageMeta) {
		return `${languageMeta?.flag || ''} ${languageMeta?.languageLabel}`;
	}
	return language;
};
