import {
	Button, Popover, Space,
} from 'antd';
import { useTheme } from '@emotion/react';
import { useAuthContext } from '../../hooks/useAuthContext';
import Logo50inTech from '../app/Logo50inTech';
import CompanyModel from '../../models/CompanyModel';
import { getCompanySelected } from '../../reducers/app';
import { useSelector } from 'react-redux';
import { LOGO_50INTECH_FULL_WHITE_SVG } from '../../constants/logos';

const SuggestedForYouButton = (
	{
		savedSearch,
		onSelectSavedSearch,
		active = true,
		...props
	},
) => {
	const className = 'UserSavedSearchStub';
	const { currentUser } = useAuthContext();
	const theme = useTheme();
	const companySelected = useSelector(getCompanySelected);

	const handleClick = () => {
		onSelectSavedSearch?.(savedSearch);
	};
	const company = new CompanyModel(companySelected);
	const popoverContent = (
		<Space wrap={true} direction='horizontal' style={{ fontSize: 12 }}>
			<span style={{ color: theme.color.grey, fontStyle: 'italic' }}>
				Searching for profiles that
			</span>
			<Logo50inTech
				size={58}
				height={32}
				style={{ marginBottom: 2 }}
				color={theme.color.grey}
				src={LOGO_50INTECH_FULL_WHITE_SVG}
			/>
			<span style={{ color: theme.color.grey, fontStyle: 'italic' }}>
				has selected for
			</span>
			<span style={{ color: theme.color.grey, fontSize: 13 }}>
				{
					companySelected
						? <b>{company?.name || 'your company'}</b>
						: <b>50inTech</b>
				}
			</span>
		</Space>
	);

	return (
		<Popover placement={'bottom'} content={popoverContent}>
			<Button
				id='suggested-for-you'
				type={active ? 'primary' : 'default'}
				size={'large'}
				onClick={handleClick}
				style={{
					fontWeight: 'bold',
					borderRadius: 8,
				}}
			>
				<Space wrap={false}>
					<Logo50inTech
						size={18}
						color= {active ? 'white' : theme.color.darkturquoise}
						style={{
							marginTop: 0,
						}}/>
					<span style={{ fontSize: 16 }}>Suggested for you</span>
				</Space>
			</Button>
		</Popover>
	);
};

export default SuggestedForYouButton;
