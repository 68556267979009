import { ReadOutlined } from '@ant-design/icons';
import StaffArticleListPage from '../../../containers/staff/content/StaffArticleListPage';
import MenuBase from '../../MenuBase';
import { PERMS_GRANT_STAFF } from '../../menu.utils';

export const StaffArticleListIcon = ReadOutlined;

class StaffArticleListMenu extends MenuBase {
	static props = {
		name: 'staff-content-articles',
		path: '/staff/content/articles',
		label: 'Articles',
		icon: <StaffArticleListIcon />,
		iconLegacy: '📰',
		Component: StaffArticleListPage,
		perms: PERMS_GRANT_STAFF,
	};

	constructor() {
		super(StaffArticleListMenu.props);
	}
}

export default StaffArticleListMenu;
