import { LocalDebug } from '../../../../utils/LocalDebug';
import {
	Form, message, Select, Space,
} from 'antd';
import {
	useContext, useEffect, useReducer, useState,
} from 'react';
import FormItem from '../../../company/form/FormItem';
import { useDispatch } from 'react-redux';
import { refreshCurrentUser } from '../../../../actions/app';
import FormBoxTitle from '../../../app/form/FormBoxTitle';
import FormSubmitButton from '../../../app/form/FormSubmitButton';
import { useAuthContext } from '../../../../hooks/useAuthContext';
import UserStub from '../../../user/stub/UserStub';
import UserService from '../../../../services/user';
import CoachingService from '../../../../services/coaching';
import { NotificationContext } from '../../../app/notification/NotificationProvider';
import FUNNEL_EXCLUSION from '../../../../constants/funnel-exclusion';
import LangFlagColorTag from '../../../app/tag/LangFlagColorTag';
import { FaArrowCircleRight } from 'react-icons/fa';
import { documentId, openExternalUrl } from '../../../../utils/common';
import { Link } from 'react-router-dom';
import { getMailjetTemplateBuildLink } from '../../../../constants/constant';

const FunnelUserExclusionForm = (
	{
		user,
		onSuccess,
		style = {},
	},
) => {
	const className = 'FunnelUserExclusionForm';

	const dispatch = useDispatch();
	const { currentUser } = useAuthContext();
	const { notifyLoading, notifySuccess } = useContext(NotificationContext);

	const [form] = Form.useForm();
	const [selectedReason, setSelectedReason] = useState(user?.funnel?.exclusion);
	const [availableTemplates, setAvailableTemplates] = useState([]);
	const [selectedTemplate, setSelectedTemplate] = useState(null);

	const [state, stateDispatch] = useReducer((state, action) => {
		switch (action) {
			case 'forceDisabled':
				LocalDebug.logInfo({ className, method: 'useReducer' }, { action });
				return { disabled: true };
			case 'updateDisabled':
				const hasErrors = form.getFieldsError().some(({ errors }) => errors.length);
				const isTouched = form.isFieldsTouched();
				LocalDebug.logInfo({ className, method: 'useReducer' }, {
					action,
					state,
					hasErrors,
					isTouched,
				});
				if (hasErrors) return { disabled: true };
				if (!isTouched) return { disabled: true };
				return { disabled: false };
		}
	}, { disabled: true });

	const onFormChanged = () => {
		stateDispatch('updateDisabled');
	};

	const onFormFinish = async () => {
		notifyLoading({ key: className });

		stateDispatch('forceDisabled');

		const value = form.getFieldsValue()?.funnel?.exclusion;
		const templateId = form.getFieldsValue()?.templateId;

		LocalDebug.logInfo({ className, method: 'onFormFinish' }, { value, templateId });

		const result = await UserService.updateFunnel(documentId(user), { funnel: 'funnel.exclusion', value });

		if (templateId) {
			await CoachingService.sendUserExclusionEmail(
				documentId(user),
				{
					coachId: documentId(currentUser),
					templateId,
				},
			);
		}

		LocalDebug.logInfo({ className, method: 'onFormFinish' }, result);

		notifySuccess({
			key: className,
			description: value
				? <span>Successfully excluded <b>{user.firstName}</b> ({value})</span>
				: <span>Successfully removed <b>{user.firstName}</b> from exclusion list</span>,
			duration: 3,
		});

		if (documentId(user) === documentId(currentUser)) refreshCurrentUser()(dispatch);

		onSuccess?.();
	};

	const onFormFinishFailed = ({ values, errorFields, outOfDate }) => {
		message.error(<div>
			Error: {(errorFields?.map((e) => e.errors) || []).slice(0, 1).join(',')}
		</div>);
	};

	useEffect(() => {
		const templates = FUNNEL_EXCLUSION.MAILING_TEMPLATES.filter((o) => o.exclusion === selectedReason);
		setAvailableTemplates(templates);
		if (!templates || !templates?.map((t) => t.value).includes(selectedTemplate)) {
			form.setFieldsValue({ templateId: null });
			setSelectedTemplate(null);
		}
	}, [selectedReason]);

	return (
		<Form
			form={form}
			name='funnel-user-exclusion'
			initialValues={{ ...user }}
			labelCol={{ span: 6 }}
			labelAlign='right'
			wrapperCol={{ span: 18 }}
			onFinish={onFormFinish}
			onFinishFailed={onFormFinishFailed}
			style={{ ...style }}
			layout='horizontal'
			onFieldsChange={onFormChanged}
		>
			<FormBoxTitle
				title={<>👋 Manage a talent's exclusion</>}
			>

				<UserStub
					user={user}
					style={{
						width: '100%',
						padding: 10,
						border: '1px solid #eee',
						borderRadius: 4,
						marginBottom: 20,
					}} />

				<FormItem
					name={['funnel', 'exclusion']}
					label='Exclusion reason'
					defaultContainer='div'
				>
					<Select
						showArrow={true}
						allowClear={true}
						mode='single'
						options={[
							...user?.funnel?.exclusion
								? [{ value: null, label: <i>Remove from excluded</i> }]
								: [],
							...FUNNEL_EXCLUSION.options,
						]}
						onChange={setSelectedReason}
						placeholder='Select an exclusion reason'
						style={{ display: 'inline-block', width: '100%' }}
					/>
				</FormItem>

				{availableTemplates?.length > 0 && (
					<FormItem label='Email template'>
						<FormItem
							name="templateId"
							noStyle
						>
							<Select
								options={availableTemplates?.map((o) => ({
									...o,
									label: <Space><b>{o.label}</b><LangFlagColorTag lang={o.lang} /></Space>,
								}))}
								onChange={setSelectedTemplate}
								showArrow={true}
								allowClear={true}
								placeholder="Select a template"
								mode='single'
								style={{ display: 'inline-block', width: '100%' }}
							/>
						</FormItem>

						{selectedTemplate && (
							<Link
								style={{ margin: '5px 0 0 12px', whiteSpace: 'nowrap', width: 200 }}
								onClick={() => { openExternalUrl(getMailjetTemplateBuildLink(selectedTemplate)); }}
							>
								Check in Mailjet <FaArrowCircleRight style={{ marginBottom: -2 }} />
							</Link>
						)}
					</FormItem>
				)}

				<FormItem label='&nbsp;'>
					<FormSubmitButton disabled={state?.disabled}>
						{selectedTemplate ? 'Save & send email' : 'Save'}
					</FormSubmitButton>
				</FormItem>

			</FormBoxTitle>
		</Form>
	);
};

export default FunnelUserExclusionForm;
