import Button from '../../app/button/Button';
import { useTheme } from '@emotion/react';

const Tags = ({ name = 'location', options = [], onClick }) => {
	const theme = useTheme();
	return (
		<div className="flexRow alignCenter spaceBetween" style={{ marginBottom: 20 }}>
			<div style={{ width: 140 }}/>
			<div style={{ flex: 1, marginLeft: -3, marginRight: -3 }} className="flexRow alignCenter">
				{options.map((option, key) => {
					return (
						<Button
							key={key}
							type="default"
							otherStyles={{
								padding: '3px 6px',
								border: 'none',
								borderRadius: 3,
								color: theme.color.eblue,
								background: '#eeeeff',
								marginLeft: 3,
								marginRight: 3,
								fontWeight: 'normal',
								fontSize: 11,
								lineHeight: 1.3,
								height: 'unset',
							}}
							onClick={() => onClick({ [name]: option })}
						>
							{option}
						</Button>
					);
				})}
			</div>
		</div>
	);
};

export default Tags;
