import { useContext } from 'react';
import { useTheme } from '@emotion/react';
import { getIncompleteMandatoryStepList } from './CompanyPageSteps';
import { CompanyPageContext } from './CompanyPageProvider';
import CompanyPageNextStepLink from './CompanyPageNextStepLink';

const CompanyPagePublishBtnPopoverContent = (
	{
		company,
		...props
	},
) => {
	const className = 'CompanyPagePublishBtnPopoverContent';

	const theme = useTheme();

	const { lang } = useContext(CompanyPageContext);

	const incompleteSteps = getIncompleteMandatoryStepList({ company, lang });

	return (
		<div
			style={{
				position: 'relative',
				overflow: 'auto',
				flex: '0 1 260px',
				maxWidth: 260,
				borderRadius: 10,
			}}
		>
			<h3 style={{ marginTop: 0 }}>Almost there!</h3>
			<p>To enable publishing, you first need to <b>complete your company page.</b></p>
			<p>Only <b>{incompleteSteps?.length} step{incompleteSteps?.length > 1 ? 's' : ''}</b> to go, keep it up!</p>
			<CompanyPageNextStepLink
				company={company}
			/>
		</div>
	);
};

export default CompanyPagePublishBtnPopoverContent;
