import PageLayout from '../app/layout/PageLayout';
import { useEffect, useState } from 'react';
import {
	Affix, Descriptions, Divider, Space, Tabs,
} from 'antd';
import { useTheme } from '@emotion/react';
import Tag from '../staff/tags/Tag';
import JobService from '../../services/jobs';
import { useAuthContext } from '../../hooks/useAuthContext';
import Sourcings from './jobDetails/Sourcings';
import JobActionTable from './jobDetails/JobActionTable';
import TableColumns from '../app/table/TableColumns';
import {
	JOB_TAG_JOB_LANGUAGE, JOB_TAG_JOB_LEVEL, JOB_TAG_JOB_REMOTE, JOB_TAG_JOB_REMOTE_LEGACY, SOURCINGS_TAB_LABEL,
} from '../../constants/constant';
import { arrayWrap, documentId } from '../../utils/common';
import { useSelector } from 'react-redux';
import { getTagsJobs } from '../../reducers/app';
import { getPrettifiedLanguageLabel } from '../../constants/languages';
import { isBoolean } from 'lodash';

const JobDetails = (
	{
		job,
		scrollTarget = null,
		affixTop = 0,
		withinDrawer = false,
		activeTab = null,
	},
) => {
	const className = 'JobDetails';
	const theme = useTheme();

	const { isStaffView } = useAuthContext();
	const [isLoading, setIsLoading] = useState(true);
	const [jobData, setJobData] = useState(null);
	const tags = useSelector(getTagsJobs);

	const fetchJob = async (jobId) => {
		const { data: { job: data } } = await JobService.getJob(jobId);
		setJobData(data);
		setIsLoading(false);
	};

	useEffect(() => {
		if (!job) {
			setJobData(null);
			setIsLoading(false);
			return;
		}
		if (job && !jobData) {
			setIsLoading(true);
			fetchJob(documentId(job, job?.slug));
		} else {
			setIsLoading(false);
		}
	}, [job]);

	const getLabelsList = (values, category) => {
		const categoryTags = (tags.find((tag) => tag.category === category?.value));
		let valuesList = [];
		if (values) {
			valuesList = category.value === JOB_TAG_JOB_REMOTE.value && isBoolean(values)
				? arrayWrap(JOB_TAG_JOB_REMOTE_LEGACY(values)) : [...values];
		}
		return valuesList?.map((value) => {
			const label = categoryTags?.items?.find((c) => c.value === value)?.label;
			return category.value === JOB_TAG_JOB_LANGUAGE.value ? getPrettifiedLanguageLabel(label) : label;
		})?.join(', ');
	};

	return (
		jobData && !isLoading
		&& <PageLayout>

			<>
				{/* <Divider/> */}

				<Tabs defaultActiveKey={activeTab} style={{ marginTop: 5 }}>

					<Tabs.TabPane tab="Info" key="Info">

						<Descriptions title="Tags" layout="horizontal" bordered>
							<Descriptions.Item label="Published?" span={3}>
								{jobData.isPublished?.toString()}
							</Descriptions.Item>
							<Descriptions.Item label="Company" span={3}>
								{TableColumns.companyStubColumn(jobData.company).render(jobData.company, jobData)}
							</Descriptions.Item>
							<Descriptions.Item label="Title" span={3}>
								{jobData.title}
							</Descriptions.Item>
							<Descriptions.Item label="Remote" span={3}>
								{getLabelsList(jobData.remote, JOB_TAG_JOB_REMOTE)}
							</Descriptions.Item>
							<Descriptions.Item label="Experience" span={3}>
								{getLabelsList(jobData.experienceLevel, JOB_TAG_JOB_LEVEL)}
							</Descriptions.Item>
							<Descriptions.Item label="Language" span={3}>
								{getLabelsList(jobData.language, JOB_TAG_JOB_LANGUAGE)}
							</Descriptions.Item>
							<Descriptions.Item label="Location" span={3}>
								{jobData.location}
							</Descriptions.Item>
							<Descriptions.Item label="Contract" span={3}>
								{jobData.contract}
							</Descriptions.Item>
							<Descriptions.Item label="Department" span={3}>
								{jobData.department}
							</Descriptions.Item>
							<Descriptions.Item label="Team" span={3}>
								{jobData.team}
							</Descriptions.Item>
							<Descriptions.Item label="URI" span={3}>
								{jobData.uri}
							</Descriptions.Item>
							<Descriptions.Item label="Apply URI" span={3}>
								{jobData.applyUri}
							</Descriptions.Item>
							<Descriptions.Item label="ATS Provider" span={3}>
								{jobData.atsProvider}
							</Descriptions.Item>
							<Descriptions.Item label="ATS ID" span={3}>
								{jobData.atsId}
							</Descriptions.Item>
							<Descriptions.Item label="Created" span={3}>
								{TableColumns.dateColumn(theme, {}).render(jobData.createdAt)}
							</Descriptions.Item>
							<Descriptions.Item label="Updated" span={3}>
								{TableColumns.dateColumn(theme, {}).render(jobData.updatedAt)}
							</Descriptions.Item>
							<Descriptions.Item label="ATS created" span={3}>
								{TableColumns.dateColumn(theme, {}).render(jobData.atsCreatedAt)}
							</Descriptions.Item>
							<Descriptions.Item label="ATS updated" span={3}>
								{TableColumns.dateColumn(theme, {}).render(jobData.atsUpdatedAt)}
							</Descriptions.Item>
						</Descriptions>

						<div style={{ background: '#f5f5f5', padding: 20 }}>
							<div style={{
								width: 700,
								minWidth: 700,
								maxWidth: 700,
								margin: '0 auto',
								border: '3px solid #eee',
								borderRadius: 3,
								background: 'white',
								padding: '40px 30px',
							}}>
								<p className="rawHtml" dangerouslySetInnerHTML={{ __html: jobData.content }} />
							</div>
						</div>

					</Tabs.TabPane>

					<Tabs.TabPane tab={SOURCINGS_TAB_LABEL} key='sourcings'>
						<Sourcings job={jobData}/>
					</Tabs.TabPane>

					{isStaffView
						&& <Tabs.TabPane tab="Job actions" key='actions'>
							<JobActionTable job={jobData}/>
						</Tabs.TabPane>
					}

					{isStaffView
						&& <Tabs.TabPane tab="Tags" key='tags'>

							<Descriptions title="Tags" layout="horizontal" bordered>
								{Object.entries(jobData.tagTree || {})
									.map(([category, slugs], index) => <Descriptions.Item key={index} label={category} span={3}>
										{slugs.map((item, sIndex) => <Tag slug={item} key={sIndex}/>)}
									</Descriptions.Item>)}
							</Descriptions>

						</Tabs.TabPane>
					}
				</Tabs>

				{isStaffView && <>
					<Divider/>
					<Descriptions title="Rest" layout="horizontal" bordered>
						{(() => {
							const {
								_id, id, slug, createdAt, updatedAt, atsCreatedAt, atsUpdatedAt,
								title, location, contract, department, team,
								uri, applyUri,
								content, hasContent,
								isPublished,
								companyId, company,
								atsProvider, atsId,
								matches,
								sourcings,
								tagTree, tagSlugs,
								actions,
								...rest
							} = jobData;
							return <Descriptions.Item label="rest" span={3}>{JSON.stringify(rest)}</Descriptions.Item>;
						})()}
					</Descriptions>
				</>
				}
			</>
		</PageLayout>);
};

export default JobDetails;
