import moment from 'moment';
import { useEffect, useState } from 'react';
import { useTheme } from '@emotion/react';
import { useDispatch, useSelector } from 'react-redux';
import {
	Button, Row, Col, Divider, Form, message, Card, Space,
} from 'antd';
import { STAGE_NEW } from '../../constants/constant';
// import FormItem, { FormItemContainer } from '../app/form/FormItem';
import FormItem from '../company/form/FormItem';
import { getCompanies, getCompanySelected } from '../../reducers/app';
import JobSelect from '../jobs/JobSelect';
import { useAuthContext } from '../../hooks/useAuthContext';
import UserStub from '../user/stub/UserStub';
import FormCompanySelect from '../app/form/FormCompanySelect';
import InlineNavLink from '../app/button/InlineNavLink';
import { shouldRefreshData } from '../../actions/app';
import CompanyStub from '../company/CompanyStub';

const FormCancelSubmitButtons = (
	{
		size = 'large',
		disabled = false,
		submitText = 'Submit',
		onSubmit,
		resetText = 'Cancel',
		onReset,
	},
) => {
	const theme = useTheme();

	return (
		<Row>
			<Col
				span={24}
				style={{
					display: 'flex',
					justifyContent: 'space-between',
					// margin: -5,
					// width: 'calc(100% + 10px)',
				}}>

				<Button
					type="default"
					size={size}
					style={{
						// margin: 5,
						// borderColor: theme.color.orange,
						// color: theme.color.orange,
						fontWeight: 'bold',
						borderRadius: 6,
					}}
					htmlType="reset"
					onClick={onReset}
				>
					{resetText}
				</Button>

				<Button
					type="primary"
					disabled={disabled}
					size={size}
					style={{
						// margin: 5,
						// background: theme.color.blue,
						// borderColor: theme.color.blue,
						fontWeight: 'bold',
						borderRadius: 6,
					}}
					htmlType="submit"
				>
					{submitText}
				</Button>
			</Col>
		</Row>
	);
};

export default FormCancelSubmitButtons;
