import React from 'react';
import { notification, Space } from 'antd';
import { useTheme } from '@emotion/react';
import { mixColors } from '../../../utils/common';
import {
	FaBan, FaCheck, FaSpinner, FaTimes,
} from 'react-icons/fa';
import { CloseOutlined, LoadingOutlined } from '@ant-design/icons';

export const NotificationContext = React.createContext();

const NotificationProvider = (
	{
		children,
	},
) => {
	const theme = useTheme();

	const notificationPropsDecorator = ({ message, description, color }) => ({
		message: <span style={{ fontWeight: 'bold' }}>{message}</span>,
		description: <Space direction='vertical' size={4}>{description}</Space>,
		closeIcon: <FaTimes />,
		style: {
			userSelect: 'none',
			borderBottom: `5px solid ${color}`,
			borderRadius: 10,
			background: `linear-gradient(to right, rgba(255,255,255,0) 56px, #ffffff 56px), linear-gradient(135deg, ${color}, ${mixColors(color, theme.color.purple, 70)} 40%)`,
		},
	});

	const notifyLoading = (
		{
			key,
			icon = <LoadingOutlined
				style={{
					marginLeft: -12,
					color: 'white',
				}}
				spin
			/>,
			// <FaSpinner style={{ color: theme.color.white, marginLeft: -10 }} spin/>,
			message = 'Hold tight...',
			description = <span>We're working on it!</span>,
			duration = 0,
			...props
		},
	) => {
		notification.success({
			key,
			icon,
			duration,
			...notificationPropsDecorator({
				message,
				description,
				color: theme.color.fitBlueElectric,
			}),
			...props,
		});
	};

	const notifySuccess = (
		{
			key,
			icon = <FaCheck style={{ color: theme.color.white, marginLeft: -10 }}/>,
			message = 'Success!',
			description = <span>Amazing. It worked.</span>,
			duration = 0,
			...props
		},
	) => {
		notification.success({
			key,
			icon,
			duration,
			...notificationPropsDecorator({
				message,
				description,
				color: theme.color.darkturquoise,
			}),
			...props,
		});
	};

	const notifyError = (
		{
			key,
			icon = <FaBan style={{ color: theme.color.white, marginLeft: -10 }}/>,
			message = 'Oops.',
			description = <span>It's not you, it's us.</span>,
			duration = 0,
			...props
		},
	) => {
		notification.success({
			key,
			icon,
			duration,
			...notificationPropsDecorator({
				message,
				description,
				color: theme.color.accent,
			}),
			...props,
		});
	};

	return (
		<NotificationContext.Provider
			value={{
				notifyLoading, notifySuccess, notifyError,
			}}
		>
			{children}
		</NotificationContext.Provider>
	);
};

export default NotificationProvider;
