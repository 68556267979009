import { useTheme } from '@emotion/react';
import version from '../../../autoBuildVersion';

const AppVersionTag = ({}) => {
	const className = 'AppVersionTag';

	const theme = useTheme();

	return (
		<div style={{
			fontSize: 9,
			textAlign: 'center',
			borderRadius: 30,
			padding: '1px 6px',
			background: theme.color.bgGrey,
			opacity: 0.8,
			color: theme.color.darkgrey,
			pointerEvents: 'none',
		}}>
			{version}
		</div>
	);
};

export default AppVersionTag;
