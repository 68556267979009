import { useSelector } from 'react-redux';
import PageLayout from '../../components/app/layout/PageLayout';
import { useEffect, useState } from 'react';
import { getCompanySelected } from '../../reducers/app';
import { useAuthContext } from '../../hooks/useAuthContext';
import { documentId } from '../../utils/common';
import {
	Divider, Space, Switch,
} from 'antd';
import SectionTitle from '../../components/app/SectionTitle';
import { REPORTS_ICON, REPORTS_TITLE } from '../../constants/constant';
import ReportsTable from '../../components/reports/ReportsTable';
import { LocalDebug } from '../../utils/LocalDebug';
import ReportsStatistics from './ReportsStatistics';
import Link from '../../components/app/Link';
import { ArrowRightOutlined } from '@ant-design/icons';
import GenderhireReportsMenu from '../../menu/genderhire/GenderhireReportsMenu';

const ReportsPage = () => {
	const className = 'ReportsPage';

	const { isStaffView } = useAuthContext();
	const companySelected = useSelector(getCompanySelected);

	const [excludeInactive, setExcludeInactive] = useState(false);
	const [exclude50Disabled, setExclude50Disabled] = useState(false);
	const [exclude50, setExclude50] = useState(true);

	const buildFilterOptions = (excludedParams = []) => ({
		companyId: documentId(companySelected),
		exclude50: exclude50Disabled ? false : exclude50,
		...excludedParams?.includes?.('excludeInactive') ? {} : { excludeInactive },
	});
	const [filterOptions, setFilterOptions] = useState(buildFilterOptions());
	const [statisticsFilterOptions, setStatisticsFilterOptions] = useState(buildFilterOptions(['excludeInactive']));
	const [isRefreshDataLayout, setIsRefreshDataLayout] = useState(0);

	LocalDebug.logInfo({ className });

	useEffect(() => {
		setExclude50Disabled(companySelected?.name === '50inTech');
		setFilterOptions(buildFilterOptions());
	}, [companySelected, excludeInactive, exclude50]);

	useEffect(() => {
		setStatisticsFilterOptions(buildFilterOptions(['excludeInactive']));
	}, [companySelected, exclude50]);

	return (
		<PageLayout
			MenuClass={GenderhireReportsMenu}
			withHeaderDivider={false}
		>

			<ReportsStatistics filterOptions={statisticsFilterOptions} />

			<Divider orientation='left'>Recruiter list</Divider>

			<Space
				style={{
					width: '100%',
					justifyContent: 'space-between',
					alignItems: 'center',
					marginBottom: 16,
				}}
			>
				<Space size={30}>
					<Space>
						<Switch checked={!excludeInactive} onChange={() => setExcludeInactive((p) => !p)} />
						<span>Show disabled recruiters</span>
					</Space>
					{isStaffView && (
						<Space>
							<Switch
								disabled={exclude50Disabled}
								checked={exclude50Disabled ? true : !exclude50}
								onChange={() => setExclude50((p) => !p)}
							/>
							<span>Show 50inTech</span>
						</Space>
					)}
				</Space>
				<Space>
					<Link to={'/configure/users'}>Manage recruiters <ArrowRightOutlined /></Link>
				</Space>
			</Space>

			<ReportsTable
				filterOptions={filterOptions}
				isRefresh={isRefreshDataLayout}
			/>
		</PageLayout>
	);
};

export default ReportsPage;
