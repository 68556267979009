import { ENGLISH_LANG } from '../../../../constants/constant';
import CompanyFieldEditor from '../shared/CompanyFieldEditor';
import CompanyLogoUploader from '../../form/CompanyLogoUploader';
import { useState } from 'react';

const CompanyLogoEditor = (
	{
		company,
		lang = ENGLISH_LANG,
		setEditorOpen,
	},
) => {
	const className = 'CompanyLogoEditor';

	const field = 'logo';

	const [editedValue, setEditedValue] = useState(company?.[field]);

	const handleLogoUpload = (url) => {
		setEditedValue(url);
	};

	return (
		<CompanyFieldEditor
			company={company}
			setEditorOpen={setEditorOpen}
			title='Logo'
			details={<span>
				Upload your company logo (JPG/PNG, square, minimum 500x500)
			</span>}
			field={field}
			editedValue={editedValue}
		>
			<div style={{
				display: 'flex',
				flexDirection: 'row',
				justifyContent: 'center',
			}}>
				<CompanyLogoUploader
					defaultValue={editedValue}
					onSuccess={handleLogoUpload}
				/>
			</div>

		</CompanyFieldEditor>
	);
};

export default CompanyLogoEditor;
