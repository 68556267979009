import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { useTheme } from '@emotion/react';
import { getCompanySelected } from '../reducers/app';
import { useAuthContext } from '../hooks/useAuthContext';
import { PATHS, authState } from '../constants/constant';
import CompanyModel from '../models/CompanyModel';
import UserInvitedActivationPopup from '../components/acls/userInvitedPending/UserInvitedActivationPopup';
import UserInvitedWelcomePopin from '../components/acls/userInvitedPending/UserInvitedWelcomePopin';
import UpgradePlanPopin from '../components/app/layout/UpgradePlanPopin';
// import ConfigureCompanyPageMenu from '../menu/configure/ConfigureCompanyPageMenu';

const Popins = () => {
	const className = 'Popins';

	const theme = useTheme();

	const navigate = useNavigate();
	const { currentUser, isStaff, isEditor } = useAuthContext();
	const companySelected = useSelector(getCompanySelected);

	const [openUserInvitedActivationPopup, setOpenUserInvitedActivationPopup] = useState(false);
	const [openUserInvitedWelcomePopin, setOpenUserInvitedWelcomePopin] = useState(false);

	useEffect(() => {
		if (currentUser?.auth?.authState === authState.AUTH_REGISTERED && currentUser?.acls?.isInvitePending) {
			setOpenUserInvitedActivationPopup(true);
		}
	}, [currentUser]);

	return (
		<>
			<UserInvitedActivationPopup
				open={openUserInvitedActivationPopup}
				onSuccess={() => {
					setOpenUserInvitedActivationPopup(false);
					if (!isStaff) {
						if (!companySelected) return;
						// TODO: if (!isEditor) return;
						const companyModel = new CompanyModel(companySelected);
						if (!companyModel?.isCompanyPlanFGS()) return;
						if (companyModel.isPublishedNow()) return;
					}
					navigate(PATHS.COMPANY_PAGE);
					// navigate(new ConfigureCompanyPageMenu().path);
					setOpenUserInvitedWelcomePopin(true);
				}}
			/>

			<UserInvitedWelcomePopin
				open={openUserInvitedWelcomePopin}
				onSuccess={() => {
					setOpenUserInvitedWelcomePopin(false);
				}}
			/>

			<UpgradePlanPopin />

		</>
	);
};

export default Popins;
