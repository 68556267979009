import { useTheme } from '@emotion/react';
import { Space } from 'antd';

const UserCoachingStatusOption = (
	{
		option = null,
		style = {},
	},
) => {
	const theme = useTheme();

	const iconify = (option) => {
		const Icon = option?.icon;
		return (
			<Icon
				style={{
					display: 'inline-flex',
					justifyContent: 'center',
					alignItems: 'center',
					background: option?.color,
					color: 'white',
					marginBottom: 0,
					padding: 4,
					borderRadius: 50,
					width: 20,
					height: 20,
					verticalAlign: 'middle',
					...style?.icon,
				}} />
		);
	};

	return <Space size={4}>
		{iconify(option)}
		<span style={{
			verticalAlign: 'middle',
			fontWeight: 'bold',
			...style?.label,
		}}>
			{option?.label}
		</span>
	</Space>;
};

export default UserCoachingStatusOption;
