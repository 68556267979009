import { PERMS_GRANT_STAFF } from '../../menu.utils';
import MenuBase from '../../MenuBase';
import { UnorderedListOutlined } from '@ant-design/icons';
import StaffGenderScoreActionsPage from '../../../containers/staff/genderscore/StaffGenderScoreActionsPage';

export const StaffGSActionsIcon = UnorderedListOutlined;

class StaffGSActionsMenu extends MenuBase {
	static props = {
		name: 'staff-genderscore-actions',
		path: '/staff/genderscore/actions',
		label: 'Actions',
		icon: <StaffGSActionsIcon />,
		iconLegacy: '',
		Component: StaffGenderScoreActionsPage,
		perms: PERMS_GRANT_STAFF,
	};

	constructor() {
		super(StaffGSActionsMenu.props);
	}
}

export default StaffGSActionsMenu;
