import FormSelect from './FormSelect';
import { Select } from 'antd';

const FormCompanyAccessSelect = (
	{
		showSearch = true,
		onChange,
		defaultValue,
		placeholder = 'Select a company access',
		styles = {},
		isSorted = true,
		mode = 'single',
		...props
	},
) => {
	const handleChange = (value, option) => {
		if (onChange) onChange(value, option);
	};
	return (
		<FormSelect
			showSearch={showSearch}
			optionFilterProp="children"
			placeholder={placeholder || (mode === 'multiple'
				? 'Select one or more company accesses'
				: 'Select a company access')
			}
			onChange={handleChange}
			defaultValue={defaultValue}
			styles={{ minWidth: 180, ...styles }}
			mode={mode}
			{...(isSorted
				? {
					filterSort: (a, b) => {
						return (a.label || '').toLowerCase().localeCompare((b.label || '').toLowerCase());
					},
				}
				: {}
			)}
			filterOption={(input, option) => option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
			}
			render={(option, key) => (
				<Select.Option value={option.value} label={option.label} key={key}>
					<div className="demo-option-label-item">
						{/* <div>{JSON.stringify(option.item)}</div> */}
						<div>{option.label}</div>
						{/* <CompanyStub size={20} company={option.item} withTooltip={false}></CompanyStub> */}
					</div>
				</Select.Option>
			)}
			{...props}
		/>
	);
};

export default FormCompanyAccessSelect;
