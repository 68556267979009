import AtsAccessPage from '../../../containers/staff/ats/AtsAccessPage';
import { PERMS_GRANT_ADMIN } from '../../menu.utils';
import MenuBase from '../../MenuBase';
import { ApiOutlined, UnorderedListOutlined } from '@ant-design/icons';

export const StaffAtsListIcon = UnorderedListOutlined;

class StaffAtsListMenu extends MenuBase {
	static props = {
		name: 'staff-ats-list',
		path: '/staff/ats',
		label: 'ATS List',
		icon: <StaffAtsListIcon />,
		iconLegacy: '⚙️',
		Component: AtsAccessPage,
		onEnterProps: { shouldLoadAtsData: true },
		perms: PERMS_GRANT_ADMIN,
	};

	constructor() {
		super(StaffAtsListMenu.props);
	}
}

export default StaffAtsListMenu;
