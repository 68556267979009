import { useTheme } from '@emotion/react';
import SurveyQuestionInputNumber from '../../survey/inputs/SurveyQuestionInputNumber';
import SurveyQuestionInputText from '../../survey/inputs/SurveyQuestionInputText';
import SurveyQuestionInputPct from '../../survey/inputs/SurveyQuestionInputPct';
import SurveyQuestionSingleChoice from '../../survey/inputs/SurveyQuestionInputSingleChoice';
import SurveyQuestionMultipleChoice from '../../survey/inputs/SurveyQuestionInputMultipleChoice';

const SurveyQuestionInput = ({
	question,
	value,
	onChange = () => { },
	align,
}) => {
	const className = 'SurveyQuestionInput';

	const theme = useTheme();

	const QuestionInputComponent = {
		text: SurveyQuestionInputText,
		number: SurveyQuestionInputNumber,
		pct: SurveyQuestionInputPct,
		single: SurveyQuestionSingleChoice,
		multiple: SurveyQuestionMultipleChoice,
	}[question?.input];

	return (
		QuestionInputComponent
			? <div style={{ marginTop: 12, width: '100%' }}>
				<QuestionInputComponent
					question={question}
					onChange={onChange}
					value={value}
					align={align || 'left'}
				/>
			</div>
			: null
	);
};

export default SurveyQuestionInput;
