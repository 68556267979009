import { Space, Tooltip } from 'antd';
import { useState } from 'react';
import Table from '../app/table/Table';
import UserAvatar from './widgets/UserAvatar';
import { useTheme } from '@emotion/react';
import TableColumns from '../app/table/TableColumns';
import UserResumeManager from './widgets/resume/UserResumeManager';

const UserMissingResumesTable = (
	{
		onDataLoaded,
		path = '/user',
		filterOptions = {},
		isRefresh = 0,
	},
) => {
	const theme = useTheme();

	const [isRefreshDataLayout, setIsRefreshDataLayout] = useState(0);

	const onResumeUploaded = () => {
		setIsRefreshDataLayout((prev) => prev + 1);
	};

	const handleRefresh = () => {
		setIsRefreshDataLayout((prev) => prev + 1);
	};

	const userStubCol = TableColumns.userStubColumn(theme, {
		fixed: 'left',
		withPreview: true,
		withDropdown: true,
		onRefresh: handleRefresh,
		customOptions: {
			searchFields: [
				{ label: 'First name', value: 'firstName' },
				{ label: 'Last name', value: 'lastName' },
				{ label: 'Slug', value: 'slug' },
				{ label: 'Position', value: 'information.position' },
				{ label: 'Company', value: 'information.company' },
				{ label: 'City', value: 'information.location.city' },
				{ label: 'Country', value: 'information.location.country' },
				{ label: 'Website link', value: 'information.website' },
			],
		},
		value: (value, row) => row,
	});

	const emailCol = TableColumns.userEmailColumn(theme);

	const websiteCol = TableColumns.userWebsiteColumn(theme);

	const registeredAtCol = TableColumns.createdAtColumn(theme, { title: 'Registered' });

	const loggedAtCol = TableColumns.dateColumn(theme, { title: 'Last seen', dataIndex: 'loggedAt', key: 'loggedAt' });

	const resumeManagerCol = TableColumns.booleanColumn(theme, {
		title: 'PDF Resume actions',
		dataIndex: ['resume', 'updatedAt'],
		isFilter: false,
		isAutoComplete: false,
		sorter: false,
		align: 'center',
		removeInColumnFilter: true,
		value: (value, row) => value,
		render: (value, row) => {
			return <Space direction='horizontal'>
				<UserResumeManager
					user={row}
					withLatest={false}
					// withLatestDate={false}
					// openLabel='Open'
					// uploadLabel='Upload'
					openLabel=''
					uploadLabel=''
					onUpload={onResumeUploaded}
				>
					{row?.resume?.uploader && <UserAvatar user={row?.resume?.uploader} size={24} />}
				</UserResumeManager>
			</Space>;
		},
		...TableColumns.columnFixedWidth(160),
	});

	const careerSeekingCol = TableColumns.userCareerSeekingColumn(theme);

	const columns = [
		userStubCol,
		TableColumns.jsonColumn(theme),
		careerSeekingCol,
		resumeManagerCol,
		emailCol,
		websiteCol,
		registeredAtCol,
		loggedAtCol,
	];

	return (
		<>
			<Table
				path={path}
				onDataLoaded={onDataLoaded}
				columns={columns}
				filterOptions={filterOptions}
				isRefresh={isRefresh + isRefreshDataLayout}
				showTotal={(total) => `Total ${total} items`}
				limit={10}
				withDynamicSearch
			/>
		</>
	);
};

export default UserMissingResumesTable;
