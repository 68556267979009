import { useTheme } from '@emotion/react';
import FunnelUserExclusionForm from './FunnelUserExclusionForm';
import useBodyScrollBlocker from '../../../../hooks/useBodyScrollBlocker';
import ModalContainerBase from '../../../../contexts/modals/ModalContainerBase';

const FunnelUserExclusionModal = (
	{
		user,
		open,
		setOpen,
		onSuccess,
	},
) => {
	const className = 'FunnelUserExclusionModal';
	const theme = useTheme();

	useBodyScrollBlocker(open, className);

	return (
		<ModalContainerBase
			open={open}
			setOpen={setOpen}
			width={600}
		>

			<FunnelUserExclusionForm
				user={user}
				onSuccess={() => {
					setOpen(false);
					onSuccess?.();
				}}
			/>
		</ModalContainerBase>
	);
};
export default FunnelUserExclusionModal;
