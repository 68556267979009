import { useContext, useEffect } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router';
import SurveyNavigationProvider from '../providers/SurveyNavigationProvider';
import { useSelector } from 'react-redux';
import { getCompanyUMSSurveyDraftSelected, getCompanySelected } from '../../../../reducers/app';
import CompanyGridPicker from '../../../company/CompanyGridPicker';
import CompanyModel from '../../../../models/CompanyModel';
import { useAuthContext } from '../../../../hooks/useAuthContext';
import { SurveyContext } from '../providers/SurveyProvider';
import { useTheme } from '@emotion/react';
import SurveyLeaveModal from '../leave/SurveyLeaveModal';
import UMSSurveyStart from '../UMSSurveyStart';
import UMSSurveyResume from '../UMSSurveyResume';
import SurveySubmissionPending from '../SurveySubmissionPending';
import { LocalDebug } from '../../../../utils/LocalDebug';
import { PATHS } from '../../../../constants/constant';
import { Space } from 'antd';
import AppLogo from '../../../app/layout/AppLogo';
import SurveyLangSwitcher from '../SurveyLangSwitcher';
import { AppDataContext } from '../../../../contexts/AppDataProvider';
import UMSSurveyFilled from '../UMSSurveyFilled';
import ReactJson from 'react-json-view';
import UMSSurveyDraftSelector from './UMSSurveyDraftSelector';

const UMSSurveyContainer = () => {
	const className = 'UMSSurveyContainer';
	const theme = useTheme();
	const location = useLocation();
	const navigate = useNavigate();

	const {
		isAppSiderVisibleInSurvey,
	} = useContext(AppDataContext);
	const {
		surveyDraft,
		setSurveyDraft,
		createOrUpdateSurveyDraft,
		isSurveySubmissionPending,
	} = useContext(SurveyContext);
	const {
		isStaff, isStaffView, isDocumentAdmin, isDocumentEditorOnly, currentUser,
	} = useAuthContext();
	const companySelected = useSelector(getCompanySelected);
	const draftSelected = useSelector(getCompanyUMSSurveyDraftSelected);
	const companyModel = new CompanyModel(companySelected);

	useEffect(() => {
		LocalDebug.logNull({ className, effects: 'draftSelected, setSurveyDraft' }, { draftSelected });
		setSurveyDraft(draftSelected);
	}, [draftSelected, setSurveyDraft]);

	useEffect(() => {
		LocalDebug.logNull({ className, effects: 'surveyDraft, location' }, { surveyDraft, location });
		const lastVisitedPath = location.pathname;

		if (lastVisitedPath.indexOf(PATHS.UMS_SURVEY()) !== 0) return;

		if (surveyDraft && surveyDraft?.lastVisitedPath !== lastVisitedPath) {
			createOrUpdateSurveyDraft({
				data: { lastVisitedPath },
				withDispatch: true,
			});
		}
	}, [surveyDraft, location]);

	if (!currentUser) return null;

	if (!companySelected) return <CompanyGridPicker withPageLayout={false} />;

	if (isSurveySubmissionPending) return <SurveySubmissionPending />;

	if (!isStaff && !companyModel.isUMSEnabled() && location.pathname !== '/') {
		navigate('/');
	}

	if (!draftSelected) {
		return <UMSSurveyDraftSelector />;
	}

	return (
		<div
			className='survey ums'
		>
			<div
				style={{
					position: 'fixed',
					left: 0,
					top: 0,
					zIndex: 0,
					background: 'url("https://res.cloudinary.com/dkatmtbbc/image/upload/v1714145192/app/ums-bg.jpg")',
					backgroundRepeat: 'no-repeat',
					backgroundSize: '100% 100%',
					width: '100vw',
					height: '100vh',
				}}
			/>
			<div
				style={{
					position: 'relative',
					zIndex: 1,
				}}
			>
				<SurveyNavigationProvider>

					<Outlet />

					<SurveyLeaveModal />

					<Space
						direction='vertical'
						style={{
							position: 'fixed',
							left: 16,
							top: 16,
						}}
					>
						{!isAppSiderVisibleInSurvey && (
							<AppLogo
								label={''}
								style={{ pointerEvents: 'none' }}
							/>
						)}
						<SurveyLangSwitcher />
					</Space>

				</SurveyNavigationProvider>
			</div>
		</div>
	);
};

export default UMSSurveyContainer;
