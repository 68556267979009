import analytics from './data/analytics';
import miscData from './data/misc';
import appStats from './data/app-stats';
import funnels from './data/funnels';
import userActions from './data/user-actions';
import recruiterActivity from './data/recruiter-activity';
import messages from './data/messages';
import logs from './data/logs';
import impactKPIs from './data/impact-kpis';
import boardKPIs from './data/board-kpis';
import talentChart from './data/talent-chart';
import { PERMS_GRANT_STAFF } from '../menu.utils';
import MenuBase from '../MenuBase';
import { BarChartOutlined } from '@ant-design/icons';

class StaffDataMenu extends MenuBase {
	static props = {
		name: 'staff-data-menu',
		label: 'Data',
		icon: <BarChartOutlined />,
		iconLegacy: <span>📊</span>,
		children: () => [
			appStats,
			funnels,
			recruiterActivity,
			miscData,
			logs,
			userActions,
			messages,
			analytics,
			impactKPIs,
			boardKPIs,
			talentChart,
		],
		perms: PERMS_GRANT_STAFF,
	};

	constructor() {
		super(StaffDataMenu.props);
	}
}

export default StaffDataMenu;
