import { PERMS_GRANT_STAFF } from '../menu.utils';
import MenuBase from '../MenuBase';
import StaffArticleListMenu from './content/StaffArticleListMenu';
import StaffResourceListMenu from './content/StaffResourceListMenu';
import { ContainerOutlined } from '@ant-design/icons';

export const StaffContentIcon = ContainerOutlined;

class StaffContentMenu extends MenuBase {
	static props = {
		name: 'staff-content-menu',
		label: 'Content',
		icon: <StaffContentIcon />,
		iconLegacy: '📚️',
		children: () => [
			(new StaffArticleListMenu()).build(),
			(new StaffResourceListMenu()).build(),
		],
		perms: PERMS_GRANT_STAFF,
	};

	constructor() {
		super(StaffContentMenu.props);
	}
}

export default StaffContentMenu;
