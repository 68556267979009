import {
	FaExternalLinkAlt, FaFileDownload, FaGithub, FaLinkedin,
} from 'react-icons/fa';
import Link from '../../app/Link';
import { downloadResume, openExternalUserLink } from '../../../actions/users';
import { Col, Row } from 'antd';

const UserLinks = (
	{
		userModel,
		vertical = true,
	},
) => {
	let contacts = [];
	/*
		Get the links to the user linkedin, github, website...
	 */
	if (userModel?.hasResume()) {
		// If the user uploaded the Linkedin PDF, get the links from there
		contacts = userModel?.hasResumeContact()
			? userModel?.getResumeContact()
			: [];

		contacts = contacts?.map((contact) => {
			return {
				...contact,
				callback: (url) => openExternalUserLink(userModel, url),
			};
		});
	} else {
		// There's not Linkedin PDF, try to get the personal URL of the user
		const uri = userModel.information?.website;
		if (uri) {
			const isLinkedin = uri.toLowerCase().includes('linkedin');
			const isGithub = uri.toLowerCase().includes('github');
			let label = 'Personal link';
			if (isLinkedin) {
				label = 'LinkedIn profile';
			} else if (isGithub) {
				label = 'GitHub';
			}
			contacts.push({
				label,
				value: uri,
				callback: (url) => openExternalUserLink(userModel, url),
			});
		}
	}
	// Get the filenames of the uploaded resumes
	const personalCVFileName = userModel.getPersonalResumeFileName();
	const linkedinCVFileName = userModel.getLinkedInResumeFileName();
	const cvs = [];
	personalCVFileName && cvs.push({
		label: 'Download personal CV',
		value: personalCVFileName,
		type: 'CV',
		callback: (filename) => downloadResume(userModel, filename),
	});
	linkedinCVFileName && cvs.push({
		label: 'Download Linkedin PDF',
		value: linkedinCVFileName,
		type: 'CV',
		callback: (filename) => downloadResume(userModel, filename),
	});

	const links = [...contacts, ...cvs]?.map(
		(item, index) => {
			const {
				label, value, type, callback,
			} = item;
			if (!value) return null;
			const isLinkedin = value.toLowerCase().includes('linkedin');
			const isGithub = value.toLowerCase().includes('github');
			let icon = <FaExternalLinkAlt style={{ fontSize: 20, marginBottom: -2 }}/>;
			if (type === 'CV') {
				icon = <FaFileDownload style={{ fontSize: 18, verticalAlign: 'middle', marginTop: -2 }}/>;
			} else if (isLinkedin) {
				icon = <FaLinkedin style={{ fontSize: 20, verticalAlign: 'middle', marginTop: -2 }}/>;
			} else if (isGithub) {
				icon = <FaGithub style={{ fontSize: 20, verticalAlign: 'middle', marginTop: -2 }}/>;
			}
			return <div
				key={index}
				style={vertical ? { marginTop: 5 } : { marginTop: 5, marginRight: 20 }}>
				<Link key={index} onClick={() => { callback(value); }}>
					{icon} <span style={{ verticalAlign: 'middle' }}>{label || value}</span>
				</Link>
			</div>;
		},
	);
	if (vertical) {
		return <Col>{links}</Col>;
	}
	return <Row>{links}</Row>;
};

export default UserLinks;
