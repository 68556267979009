import { isStringTypeValidator } from '../../../utils/isStringTypeValidator';
import { innerObjPropExtractor } from '../../property/property-extractor';
import { innerObjPropMapper } from '../../property/property-mapper';

const USER_PROPERTY_PROFILE_DECLARED_WOMAN_KEY = 'profile-declared-woman';

const USER_PROPERTY_PROFILE_DECLARED_WOMAN_FREE = false;
const USER_PROPERTY_PROFILE_DECLARED_WOMAN_ANSWER_YES = 'yes';
const USER_PROPERTY_PROFILE_DECLARED_WOMAN_ANSWER_NO = 'no';
const USER_PROPERTY_PROFILE_DECLARED_WOMAN_ANSWER_UNDISCLOSED = 'undisclosed';

const USER_PROPERTY_PROFILE_DECLARED_WOMAN_OPTIONS = [
	{ value: USER_PROPERTY_PROFILE_DECLARED_WOMAN_ANSWER_YES, label: 'Yes' },
	{ value: USER_PROPERTY_PROFILE_DECLARED_WOMAN_ANSWER_NO, label: 'No' },
	{ value: USER_PROPERTY_PROFILE_DECLARED_WOMAN_ANSWER_UNDISCLOSED, label: 'Undisclosed' },
];

const USER_PROPERTY_PROFILE_DECLARED_WOMAN_VALUES = USER_PROPERTY_PROFILE_DECLARED_WOMAN_OPTIONS.map((o) => o.value);

const customValidator = (value) => USER_PROPERTY_PROFILE_DECLARED_WOMAN_VALUES.includes(value);

const USER_PROPERTY_PROFILE_DECLARED_WOMAN_VALIDATORS = [
	isStringTypeValidator,
	customValidator,
];

const USER_PROPERTY_PROFILE_DECLARED_WOMAN_FIELD = 'confidential.declaredWoman';
const USER_PROPERTY_PROFILE_DECLARED_WOMAN_MAPPER = innerObjPropMapper(USER_PROPERTY_PROFILE_DECLARED_WOMAN_FIELD);
const USER_PROPERTY_PROFILE_DECLARED_WOMAN_EXTRACTOR = innerObjPropExtractor(USER_PROPERTY_PROFILE_DECLARED_WOMAN_FIELD);

export default {
	key: USER_PROPERTY_PROFILE_DECLARED_WOMAN_KEY,
	USER_PROPERTY_PROFILE_DECLARED_WOMAN_KEY,
	validators: USER_PROPERTY_PROFILE_DECLARED_WOMAN_VALIDATORS,
	USER_PROPERTY_PROFILE_DECLARED_WOMAN_VALIDATORS,
	options: USER_PROPERTY_PROFILE_DECLARED_WOMAN_OPTIONS,
	USER_PROPERTY_PROFILE_DECLARED_WOMAN_OPTIONS,
	free: USER_PROPERTY_PROFILE_DECLARED_WOMAN_FREE,
	USER_PROPERTY_PROFILE_DECLARED_WOMAN_FREE,
	mapper: USER_PROPERTY_PROFILE_DECLARED_WOMAN_MAPPER,
	USER_PROPERTY_PROFILE_DECLARED_WOMAN_MAPPER,
	extractor: USER_PROPERTY_PROFILE_DECLARED_WOMAN_EXTRACTOR,
	USER_PROPERTY_PROFILE_DECLARED_WOMAN_EXTRACTOR,
	field: USER_PROPERTY_PROFILE_DECLARED_WOMAN_FIELD,
	USER_PROPERTY_PROFILE_DECLARED_WOMAN_FIELD,
};
