import { useSelector } from 'react-redux';
import { useTheme } from '@emotion/react';
import { Space } from 'antd';
import { getCompanySelected } from '../../../reducers/app';
import CompanyGridPicker from '../../company/CompanyGridPicker';
import { useAuthContext } from '../../../hooks/useAuthContext';
import { ArrowLeftOutlined } from '@ant-design/icons';
import Box from '../../app/box/Box';

export const UMS_ADMIN_ROLE = {
	isMe: (context) => context.isUMSAdmin,
	label: 'Admin role',
	description: {
		title: 'Admin Roles',
		content: [
			<><b>Admin</b> roles are able to <b>manage all roles</b> for the company's teammates.</>,
			<>They are not supposed to fill a survey.</>,
			<>When a sufficient number of teammates have filled their survey, <b>Admins</b> can access <b>company-wide results.</b></>,
			<>Admins <b>cannot access</b> Managers and Employees surveys or results.</>,
		],
	},
};

export const UMS_MANAGER_ROLE = {
	isMe: (context) => context.isUMSManager,
	label: 'Manager role',
	description: {
		title: 'Manager roles',
		content: [
			<><b>Manager</b> roles are able to <b>invite their employees.</b></>,
			<>They are <b>required to fill a survey.</b></>,
			<>When a sufficient number of a manager's <b>Employees</b> have filled their own survey, their <b>Manager</b> can access their <b>personal results,</b> which anonymously aggregate their assessment and those of their employees.</>,
			<>They <b>cannot access</b> other Managers and Employees surveys or results.</>,
			<>A <b>Manager</b>'s survey or results <b>cannot be accessed</b> by <b>Admins</b></>,
		],
	},
};

export const UMS_EMPLOYEE_ROLE = {
	isMe: (context) => context.isUMSEmployee,
	label: 'Employee role',
	description: {
		title: 'Employee roles',
		content: [
			<><b>Employee</b> roles are <b>required to fill a survey,</b> but will not receive <b>any results.</b></>,
			<>They <b>cannot access</b> other Managers and Employees surveys or results.</>,
			<>An <b>Employee</b>'s answers to their survey <b>cannot be accessed</b> by <b>Managers</b> or <b>Admins</b></>,
		],
	},
};

const UMSPresentation = () => {
	const className = 'UMSPresentation';

	const theme = useTheme();

	const companySelected = useSelector(getCompanySelected);
	const { isUMSAdmin, isUMSManager, isUMSEmployee } = useAuthContext();
	const authContext = useAuthContext();

	if (!companySelected) return <CompanyGridPicker withPageLayout={false} />;

	return (
		<div>
			{
				[UMS_ADMIN_ROLE, UMS_MANAGER_ROLE, UMS_EMPLOYEE_ROLE]
					.map((role, index) => {
						const isMe = role?.isMe(authContext);
						const style = isMe
							? {
								background: theme.background.gradientPurpleGreen(),
								color: theme.color.white,
							}
							: {
								background: theme.background.gradientBluePink(),
								color: theme.color.black,
							};
						const meBanner = isMe
							? (
								<span
									style={{
										fontWeight: 'bold',
										fontSize: 14,
										background: theme.color.fitBlueElectric,
										color: theme.color.white,
										padding: '2px 8px',
										borderRadius: 5,
										border: `1px solid ${theme.color.white}`,
									}}
								>
									<ArrowLeftOutlined /> YOUR ROLE
								</span>
							)
							: null;
						return (
							<Space
								key={index}
								direction='vertical'
								style={{
									width: '100%',
									padding: '30px 40px',
									marginBottom: 20,
									borderRadius: 10,
									background: theme.color.fitBlueElectric,
									color: theme.color.white,
									...style,
								}}
							>
								<h2>{role.description.title} {meBanner}</h2>
								<ul>{role.description.content.map((line, lineIndex) => (
									<li key={lineIndex}>{line}</li>
								))}</ul>
							</Space>
						);
					})
			}

			{false && (
				<>
					{(isUMSAdmin && isUMSManager && isUMSEmployee) && (
						<Box>
							<b>UMS Admin + UMS Manager + UMS Employee</b> version
						</Box>
					)}
					{(isUMSAdmin && isUMSManager) && (
						<Box>
							<b>UMS Admin + UMS Manager</b> version
						</Box>
					)}
					{isUMSAdmin && (
						<Box>
							<b>UMS Admin</b> version
						</Box>
					)}
					{(isUMSManager && isUMSEmployee) && (
						<Box>
							<b>UMS Manager + UMS Employee</b> version
						</Box>
					)}
					{isUMSManager && (
						<Box>
							<b>UMS Manager</b> version
						</Box>
					)}
					{isUMSEmployee && (
						<Box>
							<b>UMS Employee</b> version
						</Box>
					)}
				</>
			)}
		</div>
	);
};

export default UMSPresentation;
