import { isStringTypeValidator } from '../../../utils/isStringTypeValidator';
import { innerObjPropExtractor } from '../../property/property-extractor';
import { innerObjPropMapper } from '../../property/property-mapper';

const USER_PROPERTY_JOB_SEARCH_HUNTING_CONSENT_KEY = 'job-search-hunting-consent';

const USER_PROPERTY_JOB_SEARCH_HUNTING_CONSENT_FREE = false;
const USER_PROPERTY_JOB_SEARCH_HUNTING_CONSENT_ANSWER_YES = true;
const USER_PROPERTY_JOB_SEARCH_HUNTING_CONSENT_ANSWER_NO = false;

const USER_PROPERTY_JOB_SEARCH_HUNTING_CONSENT_OPTIONS = [
	{ value: USER_PROPERTY_JOB_SEARCH_HUNTING_CONSENT_ANSWER_YES, label: 'Yes' },
	{ value: USER_PROPERTY_JOB_SEARCH_HUNTING_CONSENT_ANSWER_NO, label: 'No' },
];

const USER_PROPERTY_JOB_SEARCH_HUNTING_CONSENT_VALUES = USER_PROPERTY_JOB_SEARCH_HUNTING_CONSENT_OPTIONS.map((o) => o.value);

const customValidator = (value) => USER_PROPERTY_JOB_SEARCH_HUNTING_CONSENT_VALUES.includes(value);

const USER_PROPERTY_JOB_SEARCH_HUNTING_CONSENT_VALIDATORS = [
	isStringTypeValidator,
	customValidator,
];

const USER_PROPERTY_JOB_SEARCH_HUNTING_CONSENT_FIELD = 'jobSearch.huntingConsent';
const USER_PROPERTY_JOB_SEARCH_HUNTING_CONSENT_MAPPER = innerObjPropMapper(USER_PROPERTY_JOB_SEARCH_HUNTING_CONSENT_FIELD);
const USER_PROPERTY_JOB_SEARCH_HUNTING_CONSENT_EXTRACTOR = innerObjPropExtractor(USER_PROPERTY_JOB_SEARCH_HUNTING_CONSENT_FIELD);

export default {
	key: USER_PROPERTY_JOB_SEARCH_HUNTING_CONSENT_KEY,
	USER_PROPERTY_JOB_SEARCH_HUNTING_CONSENT_KEY,
	validators: USER_PROPERTY_JOB_SEARCH_HUNTING_CONSENT_VALIDATORS,
	USER_PROPERTY_JOB_SEARCH_HUNTING_CONSENT_VALIDATORS,
	options: USER_PROPERTY_JOB_SEARCH_HUNTING_CONSENT_OPTIONS,
	USER_PROPERTY_JOB_SEARCH_HUNTING_CONSENT_OPTIONS,
	free: USER_PROPERTY_JOB_SEARCH_HUNTING_CONSENT_FREE,
	USER_PROPERTY_JOB_SEARCH_HUNTING_CONSENT_FREE,
	mapper: USER_PROPERTY_JOB_SEARCH_HUNTING_CONSENT_MAPPER,
	USER_PROPERTY_JOB_SEARCH_HUNTING_CONSENT_MAPPER,
	extractor: USER_PROPERTY_JOB_SEARCH_HUNTING_CONSENT_EXTRACTOR,
	USER_PROPERTY_JOB_SEARCH_HUNTING_CONSENT_EXTRACTOR,
	field: USER_PROPERTY_JOB_SEARCH_HUNTING_CONSENT_FIELD,
	USER_PROPERTY_JOB_SEARCH_HUNTING_CONSENT_FIELD,
};
