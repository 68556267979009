import { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getCompanySelected } from '../../reducers/app';
import { useAuthContext } from '../../hooks/useAuthContext';
import { writeRecruiterLog } from '../../actions/logging';
import CompanyModel from '../../models/CompanyModel';
import { documentId, writeLog } from '../../utils/common';
import { COMPANY_RECRUITER_LOG } from '../../constants/company-recruiter-log';
import { LocalDebug } from '../../utils/LocalDebug';
import { addMessage } from '../../actions/messages';
import { UserMessageModalContext } from './UserMessageModalProvider';
import UserMessageForm from '../../components/messages/UserMessageForm';
import ModalContainerBase from './ModalContainerBase';

const UserMessageModalContainer = () => {
	const className = 'UserMessageModalContainer';

	const {
		isStaffView, companyIdsUser,
	} = useAuthContext();

	const {
		open,
		setOpen,
		user,
		onStart,
		onComplete,
	} = useContext(UserMessageModalContext);

	const companySelected = useSelector(getCompanySelected);

	const [company, setCompany] = useState();

	useEffect(() => {
		if (companySelected) {
			setCompany(new CompanyModel(companySelected));
		} else {
			setCompany(null);
		}
	}, [companySelected]);

	const handleSubmit = async (values) => {
		LocalDebug.logInfo({ className, method: 'handleSubmit' }, { values });
		await onStart?.();
		setOpen(false);
		const valuesToDb = { ...values };
		const message = await addMessage(valuesToDb);
		writeLog(`${className} handleSubmit`, {
			data: valuesToDb,
		});

		const { userToId: userTargetId } = values;
		writeRecruiterLog(COMPANY_RECRUITER_LOG.CONTACT, { userTargetId });

		await onComplete?.({ message });
	};

	return (
		<ModalContainerBase
			open={open}
			setOpen={setOpen}
			width={1200}
			footer={false}
			style={{
				top: 20,
			}}
		>
			<UserMessageForm
				initialValues={{
					userToId: documentId(user),
					userTo: user,
					viaProfile: true,
					companyIds: !isStaffView ? companyIdsUser : null,
				}}
				onSubmit={handleSubmit}
				// onReset={onReset}
			/>
		</ModalContainerBase>
	);
};

export default UserMessageModalContainer;
