import { useAuthContext } from '../../../hooks/useAuthContext';
import { TAG_TESTER } from '../../../constants/constant';
import BlockBase from './BlockBase';

const BlockTester = ({
	withStaffView = true,
	withTags = true,
	withBorder = true,
	tagStyle,
	children,
	...props
}) => {
	const { isTester } = useAuthContext();

	return (
		<BlockBase
			value={isTester}
			tags={TAG_TESTER}
			color='mustard'
			withStaffView={withStaffView}
			withTags={withTags}
			withBorder={withBorder}
			tagStyle={tagStyle}
			{...props}
		>
			{children}
		</BlockBase>
	);
};

export default BlockTester;
