import { Switch } from 'antd';
import { UMS_LABEL } from '../../../../constants/constant';

const CompanyPlanUMSIsEnabledSwitch = ({
	disabled = false,
	checked,
	onChange,
	...props
}) => {
	const className = 'CompanyPlanUMSIsEnabledSwitch';

	return (
		<Switch
			disabled={disabled}
			checked={checked}
			onChange={onChange}
		 />
	);
};

export default CompanyPlanUMSIsEnabledSwitch;
