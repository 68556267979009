import { Button, Select, Space } from 'antd';
import { CATEGORY_LIST } from '../../../constants/email-templates';
import { useContext, useEffect, useReducer } from 'react';
import { useAuthContext } from '../../../hooks/useAuthContext';
import { useTheme } from '@emotion/react';
import { LocalDebug } from '../../../utils/LocalDebug';
import { DATA_FETCHER_UPDATE_FILTERS, DataFetcherContext } from '../../app/data/DataFetcherProvider';
import { useSelector } from 'react-redux';
import { getCompanySelected } from '../../../reducers/app';
import ReactJsonDebug from '../../app/debug/ReactJsonDebug';
import { documentId } from '../../../utils/common';

const MessageTemplateFilters = (
	{
		...props
	},
) => {
	const className = 'MessageTemplateFilters';

	const theme = useTheme();

	const { params, paramsDispatch } = useContext(DataFetcherContext);
	const {
		currentUser, isAdmin, isStaff, isStaffView, isMasterTemplatesEnabled,
	} = useAuthContext();
	const companySelected = useSelector(getCompanySelected);

	const [state, stateDispatch] = useReducer((state, action) => {
		LocalDebug.logInfo({ className, method: 'useReducer' }, { state, action });
		switch (action?.filter) {
			case 'myMessages':
				return { ...state, myMessages: !state.myMessages };
			case 'isMasterTemplate':
				return { ...state, isMasterTemplate: !state.isMasterTemplate };
			case 'category':
				return { ...state, category: action?.value };
		}
	}, {});

	useEffect(() => {
		const newFilters = {
			...state.myMessages ? { userId: documentId(currentUser) } : { userId: null },
			...state.isMasterTemplate ? { isMasterTemplate: true } : { isMasterTemplate: null },
			...state.category ? { category: state.category } : { category: null },
		};
		LocalDebug.logUseEffect({ className, effects: 'state' }, { state, newFilters });
		paramsDispatch({
			action: DATA_FETCHER_UPDATE_FILTERS,
			filters: {
				...newFilters,
				companyId: documentId(companySelected),
			},
		});
	}, [state, companySelected]);

	return (
		<Space>
			{isStaffView
				&& <Button
					type='primary'
					onClick={() => { stateDispatch({ filter: 'isMasterTemplate' }); }}
					// disabled={state.myMessages}
					{...!state.isMasterTemplate ? { ghost: true } : {}}
					style={{ borderRadius: 20, fontSize: 14 }}
				>
					Master templates
				</Button>
			}
			<Button
				type='primary'
				onClick={() => { stateDispatch({ filter: 'myMessages' }); }}
				// disabled={state.myMessages}
				{...!state.myMessages ? { ghost: true } : {}}
				style={{ borderRadius: 20, fontSize: 14 }}
			>
				My templates
			</Button>
			<Select
				placeholder={<>Category</>}
				onChange={(value) => {
					// LocalDebug.logInfo({ className, method: 'onChange' }, { value });
					stateDispatch({ filter: 'category', value });
				}}
				allowClear={true}
				style={{ borderRadius: 20, fontSize: 14, width: 200 }}
				options={CATEGORY_LIST}
			/>
		</Space>
	);
};

export default MessageTemplateFilters;
