import { useContext } from 'react';
import { useNavigate } from 'react-router';
import { useTheme } from '@emotion/react';
import { Tooltip } from 'antd';
import { PlusCircleOutlined } from '@ant-design/icons';
import { BiPencil } from 'react-icons/bi';
import Link from '../../app/Link';
import { SurveyContext } from './providers/SurveyProvider';
import { buildSectionQuestionPath, getQuestionAnsweredState, isQuestionAnswered } from '../data/shared';
import {
	EVENT, buildEventLabel, buildEventSection, buildEventValue, pushClick,
} from '../../../contexts/EventPushProvider';
import ReactMarkdown from 'react-markdown';

const SurveyQuestionEditButton = ({
	section,
	question,
	answer,
	...props
}) => {
	const className = 'SurveyQuestionEditButton';

	const theme = useTheme();
	const navigate = useNavigate();
	const {
		baseRoutePath, eventContext, answers, t,
	} = useContext(SurveyContext);

	const eventProps = {
		...eventContext,
		...buildEventSection(EVENT.SECTION.SURVEY_REVIEW),
	};

	if (!question) return null;

	const answerState = getQuestionAnsweredState({ question, answers });

	const onClick = () => {
		const path = buildSectionQuestionPath({
			baseRoutePath,
			section,
			question,
		});
		pushClick({
			...eventProps,
			...buildEventLabel(EVENT.LABEL.SURVEY_QUESTION),
			...buildEventValue({ path, answerState }),
		});
		navigate(path);
	};

	if (!isQuestionAnswered({
		question,
		answers: { [question?.questionId]: answer },
	})) {
		return (
			<Tooltip
				title={
					<ReactMarkdown>
						{t(`question-edit-btn.tooltip.unanswered.${question.optional ? 'optional' : 'mandatory'}`)}
					</ReactMarkdown>
				}
			>
				<Link
					onClick={onClick}
					style={{
						verticalAlign: 'middle',
						fontSize: 22,
						color: question?.optional
							? theme.color.grey
							: theme.color.accent,
					}}
				>
					<PlusCircleOutlined />
				</Link>
			</Tooltip>
		);
	}

	return (
		<Tooltip
			title={<ReactMarkdown>{t('question-edit-btn.tooltip.answered')}</ReactMarkdown>}
		>
			<Link
				onClick={onClick}
				style={{
					verticalAlign: 'middle',
					fontSize: 22,
				}}
			>
				<BiPencil style={{ marginBottom: -2 }} />
			</Link>
		</Tooltip>
	);
};

export default SurveyQuestionEditButton;
