import { useTheme } from '@emotion/react';
import { useContext } from 'react';
import { CompanyPageContext } from './CompanyPageProvider';
import { Space } from 'antd';
import {
	areAllMandatoryStepsCompleted,
	areAllMandatoryStepsIncomplete,
	getMandatorySteps,
} from './CompanyPageSteps';
import CompanyPageStepCompletionTag from './CompanyPageStepCompletionTag';
import { ENGLISH_LANG } from '../../../../constants/constant';

const CompanyPageStepTagList = (
	{
		steps,
		company,
		withFilled = true,
		withEmpty = true,
		onClick,
		...props
	},
) => {
	const className = 'CompanyPageStepTagList';

	const theme = useTheme();

	const { lang } = useContext(CompanyPageContext);

	if (steps === getMandatorySteps({ company, lang })
		&& !withFilled
		&& withEmpty
		&& areAllMandatoryStepsCompleted({ company, lang })
	) return null;

	if (steps === getMandatorySteps({ company, lang })
		&& withFilled
		&& !withEmpty
		&& areAllMandatoryStepsIncomplete({ company, lang })
	) {
		return null;
	}

	return (
		<Space
			direction='horizontal'
			wrap={true}
			size={4}
			style={{ ...props?.style }}
		>
			{(steps || [])
				.filter((step) => {
					if (!withFilled) return !step.isComplete({ company, lang });
					if (!withEmpty) return step.isComplete({ company, lang });
					return true;
				})
				.map((step, index) => (
					<CompanyPageStepCompletionTag
						key={index}
						step={step}
						company={company}
						withFilled={withFilled}
						withEmpty={withEmpty}
						onClick={onClick}
					/>
				))}
		</Space>
	);
};

export default CompanyPageStepTagList;
