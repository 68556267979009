import { useEffect, useState } from 'react';
import {
	Col, Form, Input, Row,
} from 'antd';
import { RiEdit2Fill } from 'react-icons/ri';
import { parseInt } from 'lodash';
import { RELEVANT_DATA_SHARED_FIELDS } from '../../../../constants/company-relevant-data';
import { ENGLISH_LANG } from '../../../../constants/constant';
import FormItem from '../../form/FormItem';
import TableColumns from '../../../app/table/TableColumns';
import { LocalDebug } from '../../../../utils/LocalDebug';
import CompanyFieldEditor from '../shared/CompanyFieldEditor';
import AddButton from '../../../app/button/AddButton';
import DraggableTable from '../../../app/table/DraggableTable';
import ActionButton from '../../../app/table/ActionButton';
import CompanyRelevantDataLine from './CompanyRelevantDataLine';
import CompanyRelevantDataModal from './CompanyRelevantDataModal';

const CompanyRelevantDataEditor = (
	{
		company,
		lang = ENGLISH_LANG,
		setEditorOpen,
	},
) => {
	const className = 'CompanyRelevantDataEditor';

	const field = 'relevantData';

	const [customData, setCustomData] = useState(company.getTranslationField(field, lang) || []);
	const [sharedData, setSharedData] = useState(
		Object.fromEntries(
			(RELEVANT_DATA_SHARED_FIELDS || [])
				.map((item) => [item.key, company?.[item?.key]]),
		),
	);

	const [editedValue, setEditedValue] = useState([]);
	const [extraValues, setExtraValues] = useState({});

	const [item, setItem] = useState({});
	const [modalOpen, setModalOpen] = useState(false);
	const [modalTitle, setModalTitle] = useState('Add a custom relevant data');

	useEffect(() => {
		setEditedValue(customData || []);
	}, [customData]);

	const handleUp = (index) => {
		const startIndex = index;
		const endIndex = index === 0 ? editedValue.length - 1 : index - 1;
		handleOrderRow(startIndex, endIndex);
	};

	const handleDown = (index) => {
		const startIndex = index;
		const endIndex = index + 1 === editedValue.length ? 0 : index + 1;
		handleOrderRow(startIndex, endIndex);
	};

	const handleOrderRow = (startIndex, endIndex) => {
		const newEditedValue = [...editedValue];
		const tempValue = newEditedValue[startIndex];
		newEditedValue[startIndex] = newEditedValue[endIndex];
		newEditedValue[endIndex] = tempValue;
		setEditedValue(newEditedValue);
	};

	const openAddModal = () => {
		setItem({ data: [{ title: '', value: '', subTitle: '' }] });
		setModalOpen(true);
		setModalTitle('Add a relevant data');
	};

	const handleAdd = (values) => {
		const { id, ...item } = values;
		LocalDebug.logInfo({ className, method: 'handleAdd' }, { id, item });
		const newEditedValue = [...editedValue || []];
		if (id >= 0) {
			newEditedValue[id] = item;
		} else {
			newEditedValue.push(item);
		}
		setEditedValue(newEditedValue);
		setModalOpen(false);
	};

	const handleEdit = (row) => {
		setItem({ ...row });
		setModalTitle('Edit a custom data');
		setModalOpen(true);
	};

	const handleRemove = (_, index) => {
		// LocalDebug.logInfo({ className, method: 'handleRemove' }, { index });
		const newEditedValue = [...editedValue];
		newEditedValue.splice(index, 1);
		setEditedValue(newEditedValue);
	};

	// --------------------------------------------- //
	// --------------- columns --------------------- //
	// --------------------------------------------- //

	const columns = [
		{
			title: 'Data',
			dataIndex: 'label',
			key: 'label',
			render: (value, row) => <CompanyRelevantDataLine data={row} />,
		},
		{
			title: <div className='flexColumn'><RiEdit2Fill /></div>,
			key: 'id',
			render: (_, row, index) => {
				return (
					<ActionButton
						tooltipTitle='Edit relevant data'
						onClick={() => handleEdit(row, index)}
						icon={<RiEdit2Fill />}
					/>
				);
			},
			...TableColumns.columnFixedWidth(70),
		},
	];

	const onSharedDataChanged = (data, value) => {
		const newValue = (isNaN(value) || value?.trim?.() === '')
			? null
			: parseInt(value || 0, 10);
		LocalDebug.logInfo({ className, method: 'onSharedDataChanged' }, { data, value, newValue });
		setExtraValues((p) => ({ ...p, [data.key]: newValue }));
	};

	return (
		<CompanyFieldEditor
			company={company}
			lang={lang}
			setEditorOpen={setEditorOpen}
			title='Relevant Data'
			field={field}
			extraValues={extraValues}
			editedValue={editedValue}
		>
			<Row gutter={[20, 20]}>
				<Col span={10}>
					<h3>Common data</h3>

					<Form
						initialValues={sharedData}
					>
						{(RELEVANT_DATA_SHARED_FIELDS || []).map((data) => (
							<div key={data.key}>
								<FormItem
									labelCol={{ span: 18 }}
									wrapperCol={{ span: 6 }}
									label={data[lang]}
									name={data.key}
									onChange={(e) => { onSharedDataChanged(data, e.target.value); }}
									style={{ height: 10 }}
									// value={company[data.key]}
								>
									<Input
										type='number'
										min={0}
										max={100}
										step={1}
										align='right'
										suffix='%'
										style={{
											textAlign: 'right',
											borderRadius: 4,
										}}
									/>
								</FormItem>
							</div>
						))}
					</Form>

				</Col>
				<Col span={14}>
					<h3>Custom data</h3>
					<AddButton
						style={{ margin: '10px 0' }}
						onClick={openAddModal}
						title='Add relevant data'
					/>

					<DraggableTable
						dataSource={
							(editedValue || [])
								?.map((value, key) => ({ ...value, id: key }))
						}
						columns={columns}
						onDragEnd={handleOrderRow}
						onMoveRowUp={handleUp}
						onMoveRowDown={handleDown}
						onDeleteRow={handleRemove}
					/>
				</Col>
			</Row>

			<CompanyRelevantDataModal
				open={modalOpen}
				onSave={handleAdd}
				handleClose={() => setModalOpen(false)}
				initialValues={item}
				title={modalTitle}
			/>

		</CompanyFieldEditor>
	);
};

export default CompanyRelevantDataEditor;
