import { useSelector } from 'react-redux';
import { useTheme } from '@emotion/react';
import { getCompanySelected } from '../../../reducers/app';
import CompanyGridPicker from '../../company/CompanyGridPicker';
import { useAuthContext } from '../../../hooks/useAuthContext';
import ReactJsonDebug from '../../app/debug/ReactJsonDebug';
import { useEffect, useMemo, useState } from 'react';
import UMSService from '../../../services/ums';
import { Space, Tabs } from 'antd';
import { documentId } from '../../../utils/common';
import { LocalDebug } from '../../../utils/LocalDebug';
import UMSManagerResultsStatusBanner from './UMSManagerResultsStatusBanner';
import UMSResultsBiasRadar from '../staff/UMSResultsBiasRadar';
import Box from '../../app/box/Box';
import {
	PERSONA_EMPLOYEES_MALE_SHORT_ID,
	PERSONA_EMPLOYEES_FEMALE_SHORT_ID,
	getBiasAnalysisText,
	BIAS_SELF_FULLFILLING_PROPHECY_DESC_FR,
	BIAS_SELF_FULLFILLING_PROPHECY_OPTION,
	BIAS_BACKLASH_EFFECT_OPTION,
	TestAnalysisTexts,
	getAlignmentAnalysisText,
} from '../data/biases';
import UMSManagerResultsStatisticsTab from './UMSManagerResultsStatisticsTab';
import ReactMarkdown from 'react-markdown';
import config from '../../../config/config';
import UMSStaffSurveyComputeAnalysisButton from '../staff/UMSStaffSurveyComputeAnalysisButton';
import UMSStaffSurveyComputeResultButton from '../staff/UMSStaffSurveyComputeResultButton';
import UMSManagerResultsAnalysisTab from './UMSManagerResultsAnalysisTab';

export const UMSBox = ({
	children,
	...props
}) => (
	<Box
		{...props}
		style={{
			padding: 20,
			background: 'white',
			display: 'flex',
			flexDirection: 'column',
			justifyContent: 'flex-start',
			alignItems: 'flex-start',
			...props?.style,
		}}
	>
		{children}
	</Box>
);

const UMSManagerResults = () => {
	const className = 'UMSManagerResults';

	const theme = useTheme();

	const companySelected = useSelector(getCompanySelected);
	const { currentUser, isStaffView } = useAuthContext();
	const [isLoading, setIsLoading] = useState(!true);
	const [statusData, setStatusData] = useState(null);

	LocalDebug.logInfo({ className });

	const loadData = async () => {
		setIsLoading(true);
		const { data } = await UMSService.getManagerStatus(
			{
				params: {
					companyId: documentId(companySelected),
					userId: documentId(currentUser),
				},
			},
		);
		LocalDebug.logInfo({ className, method: 'loadData' }, { data });
		setStatusData(data?.results);
		setIsLoading(false);
	};

	useEffect(() => {
		LocalDebug.logUseEffect({ className, effects: 'documentId(companySelected)' }, { companySelected: documentId(companySelected) });
		if (documentId(companySelected)) {
			loadData();
		} else {
			setStatusData(null);
		}
	}, [documentId(companySelected)]);

	const memoizedContent = useMemo(() => {
		LocalDebug.logUseEffect({ className, effects: 'memo' }, { isLoading, statusData, companySelected: documentId(companySelected) });

		if (!companySelected) return <CompanyGridPicker withPageLayout={false} />;

		if (isLoading) return 'Loading...';

		if (!statusData) return null;

		const biasEmployeeData = {
			strong: [], moderate: [], low: [],
		};
		const biasManagerData = {
			strong: [], moderate: [], low: [],
		};
		const biasAlignmentData = {
			strong: [], moderate: [], low: [],
		};
		Object
			.entries(statusData?.umsResult?.scores?.bias || {})
			.map(([bias, value]) => {
				console.log(className, bias, value);
				switch (value.employeesLevel) {
					case 'high':
					case 'strong':
						biasEmployeeData.strong = [...biasEmployeeData?.strong || [], bias];
						break;
					case 'medium':
					case 'moderate':
						biasEmployeeData.moderate = [...biasEmployeeData?.moderate || [], bias];
						break;
					case 'low':
					case 'weak':
						biasEmployeeData.low = [...biasEmployeeData?.low || [], bias];
						break;
					default:
						break;
				}
				switch (value.managerLevel) {
					case 'high':
					case 'strong':
						biasManagerData.strong = [...biasManagerData?.strong || [], bias];
						break;
					case 'medium':
					case 'moderate':
						biasManagerData.moderate = [...biasManagerData?.moderate || [], bias];
						break;
					case 'low':
					case 'weak':
						biasManagerData.low = [...biasManagerData?.low || [], bias];
						break;
					default:
						break;
				}
				switch (value.alignment) {
					case 'high':
					case 'strong':
						biasAlignmentData.strong = [...biasAlignmentData?.strong || [], bias];
						break;
					case 'medium':
					case 'moderate':
						biasAlignmentData.moderate = [...biasAlignmentData?.moderate || [], bias];
						break;
					case 'low':
					case 'weak':
						biasAlignmentData.low = [...biasAlignmentData?.low || [], bias];
						break;
					default:
						break;
				}
			});

		return (
			<>
				<div
					style={{
						position: 'absolute',
						top: 10,
						right: 10,
					}}
				>
					<Space>
						<UMSStaffSurveyComputeResultButton
							survey={statusData?.umsResult?.managerSurvey}
							onSuccess={loadData}
						/>
						<UMSStaffSurveyComputeAnalysisButton
							survey={statusData?.umsResult?.managerSurvey}
							onSuccess={loadData}
						/>
					</Space>
				</div>

				<UMSManagerResultsStatusBanner
					isLoading={isLoading}
					statusData={statusData}
				/>

				<Tabs>

					{statusData?.isResultReady && statusData?.umsResult && (
						<>
							<Tabs.TabPane
								tab={'Analysis'}
								key={'analysis'}
							>
								<UMSManagerResultsAnalysisTab
									umsResult={statusData?.umsResult}
									onRefresh={loadData}
								/>
							</Tabs.TabPane>

							<Tabs.TabPane
								tab={'Biases'}
								key={'biases'}
							>
								<Space
									direction='vertical'
								>
									<h2>Comparison: your own assessment vs. your employees' perception</h2>

									<div
										style={{
											display: 'grid',
											gridTemplateColumns: '660px 1fr',
											columnGap: 20,
											fontSize: 12,
										}}
									>
										<UMSBox>
											<UMSResultsBiasRadar
												size={600}
												result={statusData?.umsResult}
											/>
										</UMSBox>
										<UMSBox>
											{getBiasAnalysisText({ version: 'employees', data: biasEmployeeData })}
											{getBiasAnalysisText({ version: 'manager', data: biasManagerData })}
											{getAlignmentAnalysisText({ data: biasAlignmentData })}
											<ReactJsonDebug src={biasEmployeeData || {}} collapsed={0}/>
											<ReactJsonDebug src={statusData?.umsResult?.scores?.bias || {}} collapsed={0}/>
										</UMSBox>
									</div>

									{/* <UMSBox>
											<TestAnalysisTexts />
										</UMSBox> */}

									<h2>Your employee's perception: breakdown by gender</h2>

									<UMSBox>
										<UMSResultsBiasRadar
											size={600}
											result={statusData?.umsResult}
											personas={[
												// PERSONA_EMPLOYEES_ALL_SHORT_ID,
												PERSONA_EMPLOYEES_MALE_SHORT_ID,
												PERSONA_EMPLOYEES_FEMALE_SHORT_ID,
											]}
											withBiasLevel={false}
											withAlignmentLevel={false}
										/>
									</UMSBox>
									<UMSBox>
											Analysis
									</UMSBox>

								</Space>
							</Tabs.TabPane>

							<Tabs.TabPane
								tab={'Gender breakdown'}
								key={'gender'}
							>
								<UMSResultsBiasRadar
									size={600}
									result={statusData?.umsResult}
									personas={[
										// PERSONA_EMPLOYEES_ALL_SHORT_ID,
										PERSONA_EMPLOYEES_MALE_SHORT_ID,
										PERSONA_EMPLOYEES_FEMALE_SHORT_ID,
									]}
									withBiasLevel={false}
									withAlignmentLevel={false}
								/>
							</Tabs.TabPane>
						</>
					)}

					<Tabs.TabPane
						tab={'Global statistics'}
						key={'stats'}
					>
						<UMSManagerResultsStatisticsTab statusData={statusData} />
					</Tabs.TabPane>

					{(config.isDevEnv || isStaffView) && (
						<Tabs.TabPane
							tab={'JSON'}
							key={'json'}
						>
							<UMSBox>
								<ReactJsonDebug
									name='statusData'
									src={statusData || {}}
									collapsed={1}
								/>
							</UMSBox>
							<UMSBox>
								<ReactJsonDebug
									name='companySelected'
									src={companySelected || {}}
									collapsed={1}
								/>
							</UMSBox>
							{/* <ReactJsonDebug
										name='companyModel'
										src={new CompanyModel(companySelected) || {}}
										collapsed={1}
									/>
									<ReactJsonDebug
										name='umsManagerSurvey'
										src={new CompanyModel(companySelected)?.getUMSManagerSurvey({ user: currentUser }) || {}}
										collapsed={1}
									/>
									<ReactJsonDebug
										name='umsEmployeeSurvey'
										src={new CompanyModel(companySelected)?.getUMSEmployeeSurvey({ user: currentUser }) || {}}
										collapsed={1}
									/> */}
						</Tabs.TabPane>
					)}
				</Tabs>
			</>
		);
	}, [isLoading, statusData, companySelected]);

	return (
		<div>
			{memoizedContent}

			{/* {statusData?.umsResult && (
				<Box
					style={{
						padding: 30,
						background: 'white',
						display: 'flex',
						flexDirection: 'row',
						justifyContent: 'center',
						alignItems: 'center',
						width: '100%',
					}}
				>
					<UMSResultsBiasRadar
						size={600}
						result={statusData?.umsResult}
					/>
				</Box>
			)}

			{statusData?.umsResult && (
				<Box
					style={{
						padding: 30,
						background: 'white',
						display: 'flex',
						flexDirection: 'row',
						justifyContent: 'center',
						alignItems: 'center',
						width: '100%',
					}}
				>
					<UMSResultsBiasRadar
						size={600}
						result={statusData?.umsResult}
						personas={[
							// PERSONA_EMPLOYEES_ALL_SHORT_ID,
							PERSONA_EMPLOYEES_MALE_SHORT_ID,
							PERSONA_EMPLOYEES_FEMALE_SHORT_ID,
						]}
						withBiasLevel={false}
						withAlignmentLevel={false}
					/>
				</Box>
			)} */}

		</div>
	);
};

export default UMSManagerResults;
