import MiscDataPage from '../../../containers/staff/data/MiscDataPage';
import { onEnterBuilder } from '../../../actions/app';
import { PERMS_GRANT_STAFF } from '../../menu.utils';

const menu = {
	name: 'staff-data-misc',
	path: '/staff/data/misc',
	label: 'Misc data',
	icon: <span>🎲</span>,
	component: MiscDataPage,
	onEnter: onEnterBuilder({
		origin: MiscDataPage.name,
		shouldGoToPreviousHistory: true,
	}),
	perms: PERMS_GRANT_STAFF,
};

export default menu;
