import AtsBatchesPage from '../../../containers/staff/ats/AtsBatchesPage';
import { PERMS_GRANT_ADMIN } from '../../menu.utils';
import { FileDoneOutlined } from '@ant-design/icons';
import MenuBase from '../../MenuBase';

export const StaffAtsBatchesIcon = FileDoneOutlined;

class StaffAtsBatchesMenu extends MenuBase {
	static props = {
		name: 'staff-ats-batches',
		path: '/staff/ats/batches',
		label: 'ATS Batches',
		icon: <StaffAtsBatchesIcon />,
		iconLegacy: '📦',
		Component: AtsBatchesPage,
		onEnterProps: { shouldLoadAtsData: true },
		perms: PERMS_GRANT_ADMIN,
	};

	constructor() {
		super(StaffAtsBatchesMenu.props);
	}
}

export default StaffAtsBatchesMenu;
