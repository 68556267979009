/** @jsxImportSource @emotion/react */
import {
	Badge, Button, message, Popconfirm, Popover, Space, Tag as AntTag, Tag, Tooltip,
} from 'antd';
import { useContext, useState } from 'react';
import Link from '../app/Link';
import Table from '../app/table/Table';
import UserAvatar from './widgets/UserAvatar';
import { useTheme } from '@emotion/react';
import CheckboxTable from '../app/table/CheckboxTable';
import TableColumns from '../app/table/TableColumns';
import { EXPERIENCE_LEVEL_OPTIONS, TAG_ADMIN_ICON } from '../../constants/constant';
import { documentId, getMaxStepSourcing, getSourcingStepByValue } from '../../utils/common';
import { localDebug } from '../../utils/LocalDebug';
import {
	FaCommentDots, FaCopy, FaExternalLinkAlt,
	FaPaperPlane, FaSyncAlt, FaTag, FaTrash, FaUserPlus,
} from 'react-icons/fa';
import { computeJobSlider, computeTags, openExternalUserLink } from '../../actions/users';
import { useDispatch } from 'react-redux';
import UserDrawerProfile from './UserDrawerProfile';
import { useAuthContext } from '../../hooks/useAuthContext';
import UserCoachingStatusDropdown from './widgets/UserCoachingStatusDropdown';
import UserService from '../../services/user';
import UserResumeManager from './widgets/resume/UserResumeManager';
import CompanyLogoList from '../company/CompanyLogoList';
import UserLinkAppPublicProfile from './widgets/UserLinkAppPublicProfile';
import UserLinkAppCoachingProfile from './widgets/UserLinkAppCoachingProfile';
import { request } from '../../services/api';
import { NotificationContext } from '../app/notification/NotificationProvider';
import { registerMailjetContact } from '../../actions/mailjet';
import { GenderhireCandidatesSourcedIcon } from '../../menu/genderhire/GenderhireCandidatesSourcedMenu';
import { ThunderboltOutlined } from '@ant-design/icons';

const UserTable = (
	{
		onDataLoaded,
		path = '/user',
		filterOptions = {},
		isRefresh = 0,
		onGoToSourcing,
		onSearchDataColumn,
		onGoToJob,
		onContactTalent,
	},
) => {
	const theme = useTheme();
	const dispatch = useDispatch();

	const {
		isAdmin, isTalent, isStaffView, currentUser,
	} = useAuthContext();
	const { notifyLoading, notifySuccess, notifyError } = useContext(NotificationContext);
	const [isRefreshDataLayout, setIsRefreshDataLayout] = useState(0);
	const [userSelected, setUserSelected] = useState(null);
	const [openProfile, setOpenProfile] = useState(false);
	const [activeTab, setActiveTab] = useState(null);

	const onResumeUploaded = () => {
		setIsRefreshDataLayout((prev) => prev + 1);
	};

	const handleComputeJobSlider = async (user) => {
		await dispatch(computeJobSlider(user));
		setIsRefreshDataLayout((prev) => prev + 1);
	};

	const handleComputeTags = async (user) => {
		await dispatch(computeTags(user));
		setIsRefreshDataLayout((prev) => prev + 1);
	};

	// ---------------------------------------- //
	// ---------- user profil handler  -------- //
	// ---------------------------------------- //

	const handleOpenUserProfile = (user, tab) => {
		localDebug('handleOpenUserProfile', { user: user.slug, tab });
		setOpenProfile(true);
		setUserSelected(user);
		setActiveTab(tab);
	};

	const handleCloseProfile = () => {
		setUserSelected(null);
		setActiveTab(null);
		setOpenProfile(false);
	};

	const handleSubmitCoachingStatus = async (user, status, prevStatus) => {
		user.coaching.status = status;
		await UserService.updateCoachingStatus(documentId(user), status);
	};

	const handleRefresh = () => {
		setIsRefreshDataLayout((prev) => prev + 1);
	};

	const userStubCol = TableColumns.userStubColumn(theme, {
		fixed: 'left',
		withPreview: true,
		withDropdown: true,
		onRefresh: handleRefresh,
		customOptions: {
			searchFields: [
				{ label: 'First name', value: 'firstName' },
				{ label: 'Last name', value: 'lastName' },
				{ label: 'Slug', value: 'slug' },
				{ label: 'Position', value: 'information.position' },
				{ label: 'Company', value: 'information.company' },
				{ label: 'City', value: 'information.location.city' },
				{ label: 'Country', value: 'information.location.country' },
				{ label: 'Website link', value: 'information.website' },
			],
		},
		value: (value, row) => row,
	});

	const copyTalentNLCol = {
		title: <Tooltip title='Click to copy the data to provide for the Talent NL'>Talent NL<br />Data</Tooltip>,
		dataIndex: '_id',
		isFilter: false,
		isAutoComplete: false,
		removeInColumnFilter: true,
		sorter: false,
		align: 'center',
		render: (value, row) => {
			const content = `'${row?.slug}', // ${row?.information?.position} @ ${row?.information?.company}, ${EXPERIENCE_LEVEL_OPTIONS.find((o) => o.value === row?.jobSearch?.jobLevel)?.label}, ${row?.information?.location?.city}, ${row?.information?.location?.country}`;
			return <Popover
				content={<Space style={{ maxWidth: '500px', maxHeight: '50vh' }}>
					{content}
				</Space>}
				title={<b>Talent NL</b>}>
				<Button
					type='link'
					onClick={() => {
						navigator.clipboard.writeText(content);
						message.success('Info for Talent NL copied to clipboard');
					}}
					icon={<FaCopy />}
				/>
			</Popover>;
		},
		...TableColumns.columnFixedWidth(64),
	};

	const firstNameCol = TableColumns.fieldColumn(theme, { title: 'First name', dataIndex: 'firstName' });
	const lastNameCol = TableColumns.fieldColumn(theme, { title: 'Last name', dataIndex: 'lastName' });
	const positionCol = TableColumns.fieldColumn(theme, {
		title: 'Position',
		dataIndex: ['information', 'position'],
		customOptions: { searchField: 'information.position' },
		...TableColumns.columnFixedWidth(180),
	});
	const companyCol = TableColumns.fieldColumn(theme, {
		title: 'Company',
		dataIndex: ['information', 'company'],
	});
	const cityCol = TableColumns.fieldColumn(theme, {
		title: 'City',
		dataIndex: ['information', 'location', 'city'],
	});
	const countryCol = TableColumns.fieldColumn(theme, {
		title: 'Country',
		dataIndex: ['information', 'location', 'country'],
	});
	const userJobLevelCol = TableColumns.userJobLevelColumn(theme);
	const emailCol = TableColumns.userEmailColumn(theme);
	const websiteCol = TableColumns.userWebsiteColumn(theme);
	const linkedinCol = {
		title: 'LinkedIn',
		removeInColumnFilter: true,
		dataIndex: ['information', 'website'],
		render: (value, row) => {
			return <Link onClick={() => openExternalUserLink(row, value)}>{value}</Link>;
		},
		...TableColumns.columnMinWidth(200),
	};
	const registeredAtCol = TableColumns.createdAtColumn(theme, { title: 'Registered' });
	const loggedAtCol = TableColumns.dateColumn(theme, { title: 'Last seen', dataIndex: 'loggedAt', key: 'loggedAt' });
	const tagUpdatedAtCol = TableColumns.dateColumn(theme, { title: 'Tagged at', dataIndex: 'tagUpdatedAt', key: 'tagUpdatedAt' });
	const isOnboardedCol = TableColumns.booleanColumn(theme, {
		title: <Tooltip
			title='Whether the talent has finished the onboarding and thus has filled all necessary info to know about her profile and job search'>Onboarded</Tooltip>,
		dataIndex: ['auth', 'isOnboarded'],
		isFilter: false,
		isAutoComplete: false,
		sorter: false,
		// render: (value, row) => <CheckboxTable isChecked={row.auth?.isOnboarded === true} />,
		...TableColumns.columnFixedWidth(80),
	});
	const isEmailVerified = TableColumns.booleanColumn(theme, {
		title: <Tooltip title='Whether the talent has confirmed her email address'>Email<br />verified</Tooltip>,
		dataIndex: ['auth', 'emailVerified'],
		isFilter: false,
		isAutoComplete: false,
		sorter: false,
		// render: (value, row) => <CheckboxTable isChecked={row.auth?.isOnboarded === true} />,
		...TableColumns.columnFixedWidth(80),
	});
	const isResumeCol = TableColumns.booleanColumn(theme, {
		title: <Tooltip title='Whether the talent has had her LinkedIn PDF uploaded'>LinkedIn<br />PDF upload</Tooltip>,
		dataIndex: ['resume', 'updatedAt'],
		isFilter: false,
		isAutoComplete: false,
		sorter: false,
		align: 'center',
		removeInColumnFilter: true,
		value: (value, row) => value,
		render: (value, row) => {
			return TableColumns
				.booleanColumn(theme, { dataIndex: ['resume', 'updatedAt'], value: () => !!value })
				.render(value, row);
		},
		...TableColumns.columnFixedWidth(80),
	});
	const desiredLocationCol = TableColumns.desiredLocationColumn(theme);

	const resumeManagerCol = TableColumns.booleanColumn(theme, {
		title: 'PDF Resume actions',
		dataIndex: ['resume', 'updatedAt'],
		isFilter: false,
		isAutoComplete: false,
		sorter: false,
		align: 'center',
		removeInColumnFilter: true,
		value: (value, row) => value,
		render: (value, row) => {
			return <Space direction='horizontal'>
				<UserResumeManager
					user={row}
					withLatest={false}
					// withLatestDate={false}
					// openLabel='Open'
					// uploadLabel='Upload'
					openLabel=''
					uploadLabel=''
					onUpload={onResumeUploaded}
				>
					{row?.resume?.uploader && <UserAvatar user={row?.resume?.uploader} size={24} />}
				</UserResumeManager>
			</Space>;
		},
		...TableColumns.columnFixedWidth(160),
	});
	const mailjetUserCol = TableColumns.booleanColumn(theme, {
		title: 'Mailjet User',
		dataIndex: 'email',
		key: 'email',
		sorter: true,
		render: (value, row) => {
			const userExistsInMailjet = row?.mailjet?.ContactID;
			return (
				<>
					{userExistsInMailjet

						? <CheckboxTable isChecked={true}/>
						: <Button
							style={{
								border: '1px solid transparent !important',
								backgroundColor: '#b6dbf8 !important',
							}}
							onClick={ async () => {
								notifyLoading({
									key: 'mailjetUserCol',
									description: 'Creating user in Mailjet',
								});
								registerMailjetContact(row)
									.then(() => {
										notifySuccess({
											key: 'mailjetUserCol',
											description: 'User created in Mailjet',
											duration: 3,
										});
										setIsRefreshDataLayout((p) => p + 1);
									})
									.catch((err) => {
										notifyError({
											key: 'mailjetUserCol',
											description: 'The user could not be created in Mailjet',
											duration: 3,
										});
									});
							}
							}
						>
							<FaUserPlus style={{ marginBottom: -2 }} />
						</Button>
					}
				</>
			);
		},
		...TableColumns.columnFixedWidth(120),
	});
	const careerSeekingCol = TableColumns.userCareerSeekingColumn(theme);
	const userTagsCols = TableColumns.userTagsColumns(theme);
	const contactCol = {
		title: 'Contact',
		align: 'center',
		sorter: false,
		removeInColumnFilter: true,
		render: (row) => {
			return (
				<Button
					style={{
						border: '1px solid transparent !important',
						backgroundColor: '#b6dbf8 !important',
					}}
					onClick={() => onContactTalent(row)}
				>
					Email&nbsp;<FaPaperPlane style={{ marginBottom: -2 }} />
				</Button>
			);
		},
		...TableColumns.columnFixedWidth(110),
	};
	const coachCol = TableColumns.userAvatarColumn(theme, {
		title: 'Coach',
		dataIndex: 'coach',
		removeInColumnFilter: true,
	});
	const coachingStatusCol = {
		title: 'Coaching status',
		dataIndex: ['coaching', 'status'],
		align: 'center',
		removeInColumnFilter: true,
		sorter: false,
		render: (value, row) => <UserCoachingStatusDropdown
			user={row}
			onSelect={handleSubmitCoachingStatus}
		/>,
		...TableColumns.columnFixedWidth(150),
	};
	const coachingNpsSentCol = {
		title: 'NPS Sent?',
		dataIndex: ['coaching', 'npsSentAt'],
		align: 'center',
		removeInColumnFilter: true,
		sorter: false,
		render: (value, row) => (value
			? <><b>NPS sent at:</b> {TableColumns.dateColumn(theme).render(value)}</>
			: null),
		...TableColumns.columnFixedWidth(90),
	};
	const coachingCol = {
		title: 'Coaching',
		dataIndex: ['coaching', 'status'],
		removeInColumnFilter: true,
		sorter: false,
		render: (value, row) => <Space direction='horizontal' align='center' style={{ cursor: 'pointer' }}
				   onClick={() => handleOpenUserProfile(row, 'coaching')}>
			{row.coach && <UserAvatar user={row.coach} />}
			{value && <AntTag color='purple'>{value}</AntTag>}
			{row.coaching?.coachingOpinion
					&& <Popover title={<b>Coaching summary</b>}
							 content={
								 <Space style={{ maxWidth: '600px', fontSize: 13 }}>
									 <span>{row.coaching?.coachingOpinion}</span>
								 </Space>}>
						<Button type='link' icon={<FaCommentDots style={{ marginBottom: -2 }} />} />
					</Popover>}
		</Space>,
		...TableColumns.columnFixedWidth(180),
	};
	const isCommunityConsentCol = TableColumns.booleanColumn(theme, {
		title: <Tooltip
			title="Whether the talent has given her consent to be visible in the 'Connect' section of app.50intech.com">Community<br />consent</Tooltip>,
		dataIndex: ['confidential', 'communityConsent'],
		isFilter: false,
		isAutoComplete: false,
		sorter: false,
		// render: (value, row) => <CheckboxTable isChecked={row.auth?.isOnboarded === true} />,
		...TableColumns.columnFixedWidth(80),
	});
	const isHuntingConsentCol = TableColumns.booleanColumn(theme, {
		title: <Tooltip
			title="Whether the talent has given her consent to be hunted by our client recruiters. We should NEVER share the profile of a talent who hasn't answered YES to that question">Hunting<br />consent</Tooltip>,
		dataIndex: ['jobSearch', 'huntingConsent'],
		isFilter: false,
		isAutoComplete: false,
		sorter: false,
		// render: (value, row) => <CheckboxTable isChecked={row.auth?.isOnboarded === true} />,
		...TableColumns.columnFixedWidth(80),
	});

	const jobActionsCol = {
		title: 'Jobs Actions',
		dataIndex: 'actions',
		align: 'center',
		removeInColumnFilter: true,
		render: (value, row) => (row.actions && <Space direction='vertical' size={1}>
			{(row.actions.read || []).length > 0
				&& <Button onClick={() => handleOpenUserProfile(row, 'actions')} type='text'>Reads
					: {row.actions.read.length}</Button>}
			{(row.actions['apply-submit'] || []).length > 0
				&& <Button onClick={() => handleOpenUserProfile(row, 'actions')} type='text'>Applys
					: {row.actions['apply-submit'].length}</Button>}
			{(row.actions['coaching-submit'] || []).length > 0
				&& <Button onClick={() => handleOpenUserProfile(row, 'actions')} type='text'>Coachings
					: {row.actions['coaching-submit'].length}</Button>}
		</Space>),
		...TableColumns.columnFixedWidth(120),
	};
	const sourcingsCol = {
		title: 'Sourcings',
		dataIndex: 'sourcings',
		removeInColumnFilter: true,
		render: (value, row) => {
			const { steps, count } = row?.sourcings || {};

			if (!count) return null;

			// getMax step
			const stepMax = getMaxStepSourcing(steps);
			const sourcingStep = getSourcingStepByValue(stepMax);

			return <Tooltip title='Go to sourcings'>
				<Button type='link' style={{ display: 'flex', width: '100%' }}
					onClick={() => handleOpenUserProfile(row, 'sourcings')}>

					<Badge count={count} size='small' color='orange'>
						<GenderhireCandidatesSourcedIcon />
					</Badge>
					{stepMax && <Tag color={sourcingStep.color} style={{
						fontWeight: 'bold', marginLeft: 15, marginTop: -7,
					}}>{sourcingStep.label}</Tag>}

				</Button>
			</Tooltip>;
		},
		...TableColumns.columnFixedWidth(190),
	};
	const actionsCol = {
		title: 'Actions',
		dataIndex: 'actions',
		fixed: 'right',
		render: (value, row) => <Space direction='vertical' size={2}>
			<Tooltip title={<>Compute tags</>}>
				<Button onClick={() => handleComputeTags(row)}>
					<Space direction='horizontal' size={2} align='center'>
						<FaTag style={{ marginBottom: -2 }} color={theme.color.eblue} />
						<FaSyncAlt style={{ fontSize: 12, marginBottom: -2 }} color={theme.color.eblue} />
					</Space>
				</Button>
			</Tooltip>
			<Tooltip title={<>Compute job slider</>}>
				<Button onClick={() => handleComputeJobSlider(row)}>
					<Space direction='horizontal' size={2} align='center'>
						<ThunderboltOutlined color={theme.color.eblue} />
						<FaSyncAlt style={{ fontSize: 12, marginBottom: -2 }} color={theme.color.eblue} />
					</Space>
				</Button>
			</Tooltip>
		</Space>,
		...TableColumns.columnMinWidth(70),
	};
	const hiddenCompaniesCol = {
		title: <span>Hidden<br />companies</span>,
		dataIndex: 'hiddenCompanies',
		align: 'center',
		render: (value, row) => <CompanyLogoList companies={value} overflow={5} />,
		...TableColumns.columnMinWidth(80),
	};

	const deleteUserCol = {
		title: <span>Delete<br />user {TAG_ADMIN_ICON}</span>,
		dataIndex: 'deleteUserCol',
		align: 'center',
		render: (value, row) => (
			<Popconfirm
				icon={<span role='img' className='anticon'>
					<FaTrash style={{ color: theme.color.accent, marginBottom: -2 }} />
				</span>}
				title={(
					<Space wrap={false} style={{ color: theme.color.accent, fontWeight: 'bold' }}>
						{/* <FaTrash style={{ color: theme.color.accent, marginBottom: -2 }} /> */}
						Delete this talent?
					</Space>
				)}
				okText='Yes'
				cancelText='No'
				onConfirm={async () => {
					notifyLoading({
						key: 'deleteUserCol',
						description: 'Deleting user',
					});
					await request.delete(`/user/${documentId(row)}`);
					notifySuccess({
						key: 'deleteUserCol',
						description: 'User deleted',
						duration: 3,
					});
				}}
			>
				<Button
					type='danger'
					ghost
					style={{
						padding: '0 8px',
						borderRadius: 5,
					}}
				>
					<FaTrash />
				</Button>
			</Popconfirm>
		),
		...TableColumns.columnMinWidth(80),
	};

	const columns = [
		userStubCol,
		{
			title: <>50inTech<br />profile</>,
			removeInColumnFilter: true,
			sorter: false,
			align: 'center',
			render: (v, r) => <UserLinkAppPublicProfile user={r}><FaExternalLinkAlt /></UserLinkAppPublicProfile>,
			...TableColumns.columnFixedWidth(70),
		},
		...isAdmin
			? [
				TableColumns.jsonColumn(theme),
				TableColumns.jsonTagTreeColumn(theme),
				tagUpdatedAtCol,
			]
			: [],
		copyTalentNLCol,
		// firstNameCol,
		// lastNameCol,
		// positionCol,
		// companyCol,
		// cityCol,
		// countryCol,
		// {
		//   render: (value, row) => <UserProfileLanguagesCardBody user={new UserModel(row)} />
		// },
		isOnboardedCol,
		isEmailVerified,
		isStaffView && TableColumns.staffColumnWrapper(mailjetUserCol),
		isCommunityConsentCol,
		isHuntingConsentCol,
		isResumeCol,
		resumeManagerCol,
		// {isStaffView && <UserLinkAppPublicProfile user={user} />}
		// {isStaffView && <UserLinkAppLeads user={user} />}
		// {isStaffView && <UserLinkAppCoachingProfile user={user} />}
		// {isStaffView && <UserLinkSlugCopy user={user} />}

		emailCol,
		websiteCol,
		...isAdmin && currentUser?.email?.indexOf('londeix') >= 0 ? [linkedinCol] : [],
		registeredAtCol,
		loggedAtCol,

		hiddenCompaniesCol,
		userJobLevelCol,
		careerSeekingCol,
		desiredLocationCol,
		...userTagsCols,
		coachCol,
		...isTalent ? [contactCol] : [],
		{
			title: 'Coaching',
			removeInColumnFilter: true,
			sorter: false,
			align: 'center',
			render: (v, r) => <UserLinkAppCoachingProfile user={r}><FaExternalLinkAlt /></UserLinkAppCoachingProfile>,
			...TableColumns.columnFixedWidth(70),
		},
		coachingStatusCol,
		coachingNpsSentCol,
		// coachingCol,

		jobActionsCol,
		sourcingsCol,
		...isAdmin ? [deleteUserCol] : [],
		actionsCol,
	];

	return (
		<>
			<Table
				path={path}
				onDataLoaded={onDataLoaded}
				columns={columns}
				filterOptions={filterOptions}
				isRefresh={isRefresh + isRefreshDataLayout}
				showTotal={(total) => `Total ${total} items`}
				limit={10}
				onSearchDataColumn={onSearchDataColumn}
				withDynamicSearch
			/>
			<UserDrawerProfile
				user={userSelected}
				activeTab={activeTab}
				open={openProfile}
				onClose={handleCloseProfile}
			/>
		</>
	);
};

export default UserTable;
