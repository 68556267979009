const { isStringTypeValidator } = require('../../utils/isStringTypeValidator');

const EXPRIENCE_LEVEL_KEY = 'experience-level';

const EXPERIENCE_LEVEL_ANSWER_ENTRY = 'entry';
const EXPERIENCE_LEVEL_ANSWER_JUNIOR = 'junior';
const EXPERIENCE_LEVEL_ANSWER_MID = 'mid-level';
const EXPERIENCE_LEVEL_ANSWER_SENIOR = 'senior';
const EXPERIENCE_LEVEL_ANSWER_LEAD = 'lead';
const EXPERIENCE_LEVEL_ANSWER_LEADERSHIP = 'leadership';

const EXPERIENCE_LEVEL_OPTIONS = [
	{ value: EXPERIENCE_LEVEL_ANSWER_ENTRY, label: 'Entry' },
	{ value: EXPERIENCE_LEVEL_ANSWER_JUNIOR, label: 'Junior' },
	{ value: EXPERIENCE_LEVEL_ANSWER_MID, label: 'Mid-level' },
	{ value: EXPERIENCE_LEVEL_ANSWER_SENIOR, label: 'Senior' },
	{ value: EXPERIENCE_LEVEL_ANSWER_LEAD, label: 'Lead' },
	{ value: EXPERIENCE_LEVEL_ANSWER_LEADERSHIP, label: 'Executive' },
];

const customValidator = (value) => EXPERIENCE_LEVEL_OPTIONS.map((o) => o.value).includes(value);

const EXPERIENCE_LEVEL_VALIDATORS = [
	isStringTypeValidator,
	customValidator,
];

export default {
	EXPERIENCE_LEVEL_ANSWER_ENTRY,
	EXPERIENCE_LEVEL_ANSWER_JUNIOR,
	EXPERIENCE_LEVEL_ANSWER_MID,
	EXPERIENCE_LEVEL_ANSWER_SENIOR,
	EXPERIENCE_LEVEL_ANSWER_LEAD,
	EXPERIENCE_LEVEL_ANSWER_LEADERSHIP,

	key: EXPRIENCE_LEVEL_KEY,
	EXPRIENCE_LEVEL_KEY,
	validators: EXPERIENCE_LEVEL_VALIDATORS,
	EXPERIENCE_LEVEL_VALIDATORS,
	options: EXPERIENCE_LEVEL_OPTIONS,
	EXPERIENCE_LEVEL_OPTIONS,
};
