import { Switch } from 'antd';
import { GENDERSCORE_LABEL } from '../../../../constants/constant';

const CompanyPlanGenderScoreIsEnabledSwitch = ({
	disabled = false,
	checked,
	onChange,
	...props
}) => {
	const className = 'CompanyPlanGenderScoreIsEnabledSwitch';

	return (
		<Switch
			disabled={disabled}
			checked={checked}
			onChange={onChange}
		 />
	);
};

export default CompanyPlanGenderScoreIsEnabledSwitch;
