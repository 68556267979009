import {
	isStagingDemoVersion,
	isNotProdVersion,
} from '../config/config';
import roles from '../config/roles';

export const PERM_GRANT_TYPE = 'grant';
export const PERM_DENY_TYPE = 'deny';
export const PERM_OVERRIDE_TYPE = 'override';

export const ROLES_STAFF_CLIENT_NO_EDITOR = [
	roles.STAFF,
	roles.DOCUMENT_ADMIN,
	roles.COMPANY_RECRUITER,
];

export const PERMS_GRANT_STAFF_CLIENT_NO_EDITOR = {
	type: PERM_GRANT_TYPE,
	roles: ROLES_STAFF_CLIENT_NO_EDITOR,
};

export const PERMS_GRANT_STAGING_DEMO = {
	type: PERM_GRANT_TYPE,
	value: isStagingDemoVersion,
};

export const PERMS_GRANT_NOT_PROD_VERSION = {
	type: PERM_GRANT_TYPE,
	value: isNotProdVersion,
};

export const PERMS_DENY_STAGING_DEMO = {
	type: PERM_DENY_TYPE,
	value: isStagingDemoVersion,
};

export const PERMS_GRANT_NO_EDITOR = [
	{
		type: PERM_GRANT_TYPE,
		roles: ROLES_STAFF_CLIENT_NO_EDITOR,
	},
];

export const PERMS_GRANT_STAFF = {
	type: PERM_GRANT_TYPE,
	roles: [roles.STAFF],
};

export const PERMS_GRANT_ADMIN = {
	type: PERM_GRANT_TYPE,
	roles: [roles.ADMIN],
};

export const PERMS_GRANT_ATS_SYNCABLE = {
	type: PERM_GRANT_TYPE,
	atsSyncable: true,
};

export const PERMS_OVERRIDE_STAFF = {
	type: PERM_OVERRIDE_TYPE,
	roles: [roles.STAFF],
};

export const PERMS_OVERRIDE_STAFF_BACKUP = {
	type: PERM_OVERRIDE_TYPE,
	roles: [roles.STAFF_BACKUP],
};

export const PERMS_GRANT_ATS_SYNCABLE_DENY_EDITOR = [
	{
		type: PERM_GRANT_TYPE,
		atsSyncable: true,
	},
	{
		type: PERM_DENY_TYPE,
		roles: [roles.DOCUMENT_EDITOR_ONLY],
	},
];

export const PERMS_GRANT_RECRUITER_DENY_ATS_SYNCABLE = [
	{
		type: PERM_GRANT_TYPE,
		roles: [
			roles.STAFF_BACKUP,
			roles.DOCUMENT_ADMIN,
			roles.COMPANY_RECRUITER,
		],
	},
	{
		type: PERM_DENY_TYPE,
		atsSyncable: true,
	},
];
export const PERMS_GRANT_DOCUMENT_ADMIN = {
	type: PERM_GRANT_TYPE,
	roles: [
		roles.STAFF,
		roles.STAFF_BACKUP,
		roles.DOCUMENT_ADMIN,
	],
};

export const menuGroupBuilder = ({ label, offset = 1, perms }) => ({
	type: 'group',
	label: (
		<div style={{ fontSize: 11, paddingLeft: offset * 8 }}>
			<span>{label}</span>
		</div>
	),
	perms,
});
