import { useMemo } from 'react';
import { useTheme } from '@emotion/react';
import {
	Card, Col, Progress, Row, Statistic,
} from 'antd';

const UMSManagerResultsStatisticsTab = ({
	statusData,
}) => {
	const className = 'UMSManagerResultsStatisticsTab';

	const theme = useTheme();

	const memoizedContent = useMemo(() => {
		if (!statusData) return null;

		return (
			<>
				<Row gutter={[20, 20]}>
					<Col span={8}>
						<Card bordered={true}>
							<Statistic
								title='Employee Surveys'
								value={statusData?.employeeSurveysCount}
							/>
							{statusData?.completeEmployeeSurveysCount} complete
							<Progress
								// size={60}
								percent={Math.round(100 * statusData?.completeEmployeeSurveysCount / statusData?.employeeSurveysCount)}
							/>
						</Card>
					</Col>
				</Row>

			</>
		);
	}, [statusData]);

	return (
		<div>
			<h2
				style={{
					marginBottom: 20,
				}}
			>
				<span>Global Statistics</span>
			</h2>

			{memoizedContent}

		</div>
	);
};

export default UMSManagerResultsStatisticsTab;
