import {
	AlertOutlined, BranchesOutlined, CodeOutlined, PlusSquareOutlined, TagsOutlined,
} from '@ant-design/icons';
import { PERMS_GRANT_STAFF } from '../menu.utils';
import { onEnterTagRuleCreation } from '../../actions/app';
import MenuBase from '../MenuBase';
import TagsPage from '../../containers/staff/tech/tag/TagsPage';
import TagRulesPage from '../../containers/staff/tech/tag/TagRulesPage';
import TagRuleCreationPage from '../../containers/staff/tech/tag/TagRuleCreationPage';
import TagsWarningsPage from '../../containers/staff/tech/tag/TagsWarningsPage';
import userMailings from './tech/user-mailings';
import unbiasedManagingScore from './tech/unbiased-managing-score';
import StaffSavedSearchesDigestsMenu from './tech/StaffSavedSearchesDigestsMenu';
import StaffChatbotMenu from './tech/StaffChatbotMenu';
import StaffChatbotViewerMenu from './tech/StaffChatbotViewerMenu';

class StaffTechMenu extends MenuBase {
	static props = {
		name: 'staff-tech-menu',
		label: 'Tech',
		icon: <CodeOutlined />,
		iconLegacy: <span>🤓</span>,
		children: () => [
			unbiasedManagingScore,
			userMailings,
			(new StaffSavedSearchesDigestsMenu()).build(),
			(new StaffChatbotMenu()).build(),
			(new StaffChatbotViewerMenu()).build(),
			{
				name: 'tags',
				path: '/tags',
				label: 'Tag list',
				icon: <TagsOutlined />,
				component: TagsPage,
				perms: PERMS_GRANT_STAFF,
			},
			{
				name: 'tagRules',
				path: '/tag-rules',
				label: 'Tag rules',
				icon: <BranchesOutlined />,
				// documentRef: TAG_RULE_DOCUMENT_REF,
				component: TagRulesPage,
				params: 'tagRuleId',
				perms: PERMS_GRANT_STAFF,
			},
			{
				name: 'createTagRule',
				path: '/tag-rule-create',
				label: 'Add a tag rule',
				icon: <PlusSquareOutlined />,
				component: TagRuleCreationPage,
				onEnter: onEnterTagRuleCreation,
				perms: PERMS_GRANT_STAFF,
			},
			{
				name: 'tagwarnings',
				path: '/tag-warnings',
				label: 'Tag Warnings',
				icon: <AlertOutlined/>,
				component: TagsWarningsPage,
				perms: PERMS_GRANT_STAFF,
			},
		],
		perms: PERMS_GRANT_STAFF,
	};

	constructor() {
		super(StaffTechMenu.props);
	}
}

export default StaffTechMenu;
