import { List } from 'antd';
import { useState } from 'react';
import Box from '../../../app/box/Box';
import GenderScoreActionSelectButton from './GenderScoreActionSelectButton';
import GenderScoreActionStepItem from './GenderScoreActionStepItem';
import GenderScoreActionCategoryIcon from './GenderScoreActionCategoryIcon';

const GenderScoreActionDrawer = ({
	company,
	forceValue,
	action,
	onAddSuccess,
	onRemoveSuccess,
}) => {
	const className = 'GenderScoreActionDrawer';

	const [refresh, setRefresh] = useState(0);

	const data = (action?.details?.categories || [])
		.map((category, categoryIndex) => {
			const { title, actions } = category;
			return ({
				title,
				avatar: (
					<GenderScoreActionCategoryIcon
						size={24}
						isCompleted={Math.random() > 0.5}
					>
						{categoryIndex + 1}
					</GenderScoreActionCategoryIcon>
				),
				description: (
					<>
						{(actions || [])
							.map((action) => {
								const { id: actionId, title } = action;
								return (
									<GenderScoreActionStepItem
										title={title}
										key={actionId}
									/>
								);
							})
						}
					</>
				),
			});
		});

	return (
		<div style={{ padding: 8 }}>
			<Box
				style={{ marginBottom: 24 }}
			>
				<List
					itemLayout='horizontal'
					dataSource={data}
					renderItem={(item) => (
						<List.Item>
							<List.Item.Meta
								avatar={item.avatar}
								title={item.title}
								description={item.description}
							/>
						</List.Item>
					)}
				/>
			</Box>

			<GenderScoreActionSelectButton
				company={company}
				forceValue={forceValue}
				action={action}
				isSelected={action?.isSelected}
				selectedLabel={'Remove from Action Plan'}
				unselectedLabel ={'Add to Action Plan'}
				withoutGhost={true}
				size={'large'}
				style={{ width: '100%', marginBottom: 80 }}
				onAddSuccess={onAddSuccess}
				onRemoveSuccess={onRemoveSuccess}
			/>

		</div>
	);
};

export default GenderScoreActionDrawer;
