import { useTheme } from '@emotion/react';
import { Space } from 'antd';
import CompanyGenderScoreLogo, { Logo50 } from './CompanyGenderScoreLogo';
import CompanyGenderScoreRingScore from './CompanyGenderScoreRingScore';
import { mixColors } from '../../../utils/common';
import { getPillarColorMain, getPillarLabel } from '../../../constants/genderscore-pillars';
import dayjs from 'dayjs';
import CompanyModel from '../../../models/CompanyModel';
import DiffDisplay from './DiffDisplay';
import CompanyGenderScoreBadgeDownloader from './CompanyGenderScoreBadgeDownloader';
import CompanyLogo from '../CompanyLogo';
import { round } from 'lodash';

const PreviousScoreBadge = ({
	resizer,
	previousMainColor,
	previousTextColor,
	previousScore,
	withModalContainer = false,
}) => {
	const size = 28 + (withModalContainer ? -3 : 0);
	return (
		<div
			style={{
				width: resizer(size),
				height: resizer(size),
				borderRadius: resizer(size),
				border: `${resizer(1.7)}px solid ${previousMainColor}`,
			}}
		>
			<div
				style={{
					textAlign: 'center',
					width: `calc(100% + 2 * ${resizer(4)}px)`,
					fontWeight: 'bold',
					fontSize: resizer(5.5),
					background: previousMainColor,
					borderRadius: resizer(5),
					color: previousTextColor,
					paddingTop: resizer(0.5),
					marginLeft: resizer(-4),
					marginTop: resizer(-3),
					lineHeight: 1.5,
				}}
			>
				PREVIOUS
			</div>
			<div
				style={{
					fontFamily: 'Space Grotesk',
					fontSize: resizer(14),
					fontWeight: 'bold',
					textAlign: 'center',
					width: '100%',
					color: previousMainColor,
					marginTop: resizer(-2.4 + (withModalContainer ? -0 : 0)),
					lineHeight: 1.5,
				}}
			>
				{previousScore}
			</div>
		</div>
	);
};

const CompanyGenderScoreBadge = (
	{
		id = 'gs-badge',
		company,
		pillar,
		value,
		size = 150,
		headerHeight,
		year,
		withProgress = false,
		withPreviousScore = false,
		version = 'blue',
		withLabel = true,
		withPopover = true,
		withShadow = true,
		withExpiration = true,
		isUnpublished = false,
		withDownloader = false,
		withCompanyLogo = false,
		withModalContainer = false,
		header,
		...props
	},
) => {
	const className = 'CompanyGenderScoreBadge';

	const theme = useTheme();

	const companyModel = new CompanyModel(company);

	let genderScore = company?.genderScore;
	if (value) {
		if (isNaN(parseInt(value, 10))) {
			genderScore = value;
		} else {
			genderScore = { globalScore: value };
			withPopover = false;
		}
	}
	const score = genderScore?.[pillar || 'globalScore'] || 0;

	if (!score) return null;

	let previousScore;
	let scoreProgression;
	if (company && (withPreviousScore || withProgress)) {
		const [previousSurvey] = companyModel.getGenderScorePreviousSurveys() || [];
		if (previousSurvey) {
			previousScore = previousSurvey?.genderScore?.globalScore;
			if (previousScore && withProgress) {
				scoreProgression = score - previousScore;
			}
		}
	}

	const hasExpirationData = withExpiration && !!companyModel.getGenderScoreExpirationDate();
	const hasPreviousData = withPreviousScore && previousScore;

	let logoColor = 'blue';
	let background = theme.background.gradientWhiteBlueish();
	let badgeColor = theme.color.blue;
	let expirationDateColor = theme.color.black;
	let expirationDateColorMix = theme.color.white;
	let backgroundRingColor = mixColors(theme.color.white, theme.color.black, 10);
	let previousMainColor = '#666';
	let previousTextColor = '#fff';

	if (version === 'blue') {
		logoColor = 'white';
		background = `linear-gradient(135deg, ${theme.color.blue}, ${mixColors(theme.color.blue, theme.color.fitBlueElectric, 50)})`;
		badgeColor = theme.color.white;
		expirationDateColor = theme.color.white;
		expirationDateColorMix = theme.color.fitBlue;
		backgroundRingColor = mixColors(theme.color.blue, theme.color.black, 10);
		previousMainColor = mixColors(theme.color.fitBlue, theme.color.white, 70);
		previousTextColor = theme.color.fitBlue;
	}

	const resizer = (target) => (target * badgeWidth) / 150;

	const badgeWidth = size;
	const ringSize = resizer(120);
	const badgeHeaderHeight = resizer(headerHeight || 55 + (hasExpirationData ? 14 : 0));
	const badgeHeight = badgeHeaderHeight + ringSize + resizer(15 + 10);
	// const badgeHeight = badgeWidth * ((2 / 1.5) + (hasExpirationData ? 0.1 : 0));

	const ScoreRing = () => (
		<CompanyGenderScoreRingScore
			size={ringSize}
			company={company}
			pillar={pillar}
			value={value}
			withLabel={withLabel}
			withPopover={withPopover}
			backgroundRingColor={backgroundRingColor}
			style={{
				score: { color: props?.style?.score?.color || badgeColor },
				label: { color: badgeColor },
			}}
			{...pillar
				? { ringColor: getPillarColorMain(pillar) }
				: {}
			}
		/>
	);

	const content = (
		<div
			style={{
				fontFamily: 'DM Sans',
				position: 'relative',
				alignItems: 'center',
				justifyContent: 'center',
				width: badgeWidth,
				height: badgeHeight,
				padding: resizer(15),
				paddingTop: resizer(10),
				borderRadius: `${resizer(14)}px ${resizer(14)}px ${badgeWidth}px ${badgeWidth}px`,
				background,
				...withShadow && { boxShadow: `0 ${resizer(2)}px ${resizer(20)}px -${resizer(2)}px rgba(0,0,0,.2)` },
				boxSizing: 'border-box',
				...props?.style,
			}}
		>
			<div
				style={{
					position: 'relative',
					height: badgeHeight - resizer(25),
					...isUnpublished
						? {
							opacity: 0.3,
							filter: 'grayscale(100%)',
						}
						: {},
				}}
			>
				<div
					style={{
						height: badgeHeaderHeight,
					}}
				>
					{header || (
						<>
							{pillar
								? (
									<Space
										size={-resizer(4) * 0}
										direction='vertical'
										style={{
											width: '100%',
											justifyContent: 'center',
											alignItems: 'center',
										}}
									>
										<Logo50 width={resizer(48)} />
										<div
											style={{
												fontWeight: 'bold',
												fontSize: resizer(14.5),
												textAlign: 'center',
												whiteSpace: 'nowrap',
												lineHeight: 1.2,
												marginBottom: resizer(0),
												color: getPillarColorMain(pillar),
											}}
										>
											{getPillarLabel(pillar)}
										</div>
									</Space>
								)
								: (
									withCompanyLogo && company
										? (
											<Space
												direction='vertical'
												size={0}
												style={{
													width: '100%',
												}}
											>
												<Space
													style={{
														justifyContent: 'space-between',
														// alignItems: 'flex-start',
														alignItems: 'center',
														width: '100%',
													}}
												>
													<Logo50
														width={ringSize / 2.4}
														color={logoColor}
													/>
													<CompanyLogo
														company={company}
														size={round(ringSize / 5.3)}
													/>
												</Space>
												<CompanyGenderScoreLogo
													size={ringSize}
													color={logoColor}
													direction='vertical'
													with50Logo={false}
													style={{
														width: '100%',
														marginTop: -resizer(3.7),
													}}

												/>
											</Space>
										)
										: <CompanyGenderScoreLogo
											size={ringSize}
											color={logoColor}
											direction='vertical'
											style={{ width: '100%' }}
										/>
								)
							}
							{hasExpirationData && (
								<div
									style={{
										fontSize: resizer(10),
										textAlign: 'center',
										margin: `${resizer(2)}px 0 ${resizer(8)}px`,
										userSelect: 'none',
									}}
								>
									<span
										style={{
											color: mixColors(expirationDateColor, expirationDateColorMix, 30),
											fontWeight: 400,
											lineHeight: 1.4,
										}}
									>
										Valid until
									</span>
									&nbsp;
									<span
										style={{
											color: mixColors(expirationDateColor, expirationDateColorMix, 15),
											fontWeight: 600,
										}}
									>
										{dayjs(companyModel.getGenderScoreExpirationDate()).format('DD/MM/YYYY')}
									</span>
								</div>
							)}
						</>
					)}
				</div>

				{withPreviousScore && previousScore && (
					<div
						style={{
							position: 'absolute',
							left: -resizer(6.5),
							top: badgeHeaderHeight - resizer(1),
						}}
					>
						<PreviousScoreBadge
							resizer={resizer}
							previousMainColor={previousMainColor}
							previousTextColor={previousTextColor}
							previousScore={previousScore}
							withModalContainer={withModalContainer}
						/>
					</div>
				)}

				<div
					style={{
						position: 'absolute',
						left: 0,
						bottom: 0,
					}}
				>
					<ScoreRing />
				</div>

				{scoreProgression && (
					<Space
						direction='vertical'
						size={0}
						style={{
							position: 'absolute',
							bottom: resizer(3),
							right: resizer(3),
							alignItems: 'center',
							justifyContent: 'center',
							width: resizer(38),
							height: resizer(38),
							borderRadius: resizer(38),
							fontSize: resizer(12),
							background: theme.color.accent,
							// color: theme.color.blue,
							color: theme.color.white,
							fontWeight: 'bold',
							lineHeight: 1,
						}}
					>
						<DiffDisplay
							previous={previousScore}
							current={score}
							withBackground={true}
							style={{
								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center',
								width: resizer(38),
								height: resizer(38),
								borderRadius: resizer(38),
								fontSize: resizer(10),
								icon: { fontSize: resizer(11) },
							}}
						/>
					</Space>
				)}

			</div>

			{withDownloader && (
				<div
					style={{
						position: 'absolute',
						right: resizer(7),
						bottom: resizer(7),
					}}
				>
					<CompanyGenderScoreBadgeDownloader
						company={company}
					/>
				</div>
			)}
		</div>
	);

	return (
		<div id={id}>
			{content}
		</div>
	);
};

export default CompanyGenderScoreBadge;
