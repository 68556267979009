import { useEffect, useState } from 'react';

import CompanyStub from './CompanyStub';
import DrawerContainer from '../app/drawer/DrawerContainer';
import { Table } from 'antd';
import TableColumns from '../app/table/TableColumns';
import UserService from '../../services/user';
import { useTheme } from '@emotion/react';

const CompanyTeamDrawerContent = ({ company }) => {
	const theme = useTheme();

	const [dataSource, setDataSource] = useState([]);
	const [isLoading, setIsLoading] = useState(false);

	const columns = [
		TableColumns.userStubColumn(theme, {
			sorter: false,
			value: (value, row) => row,
		}),
	];

	const fetchData = async (ids) => {
		const { data: { users } } = await UserService.findAll({ userIds: ids });
		setDataSource(users);
		setIsLoading(false);
	};

	useEffect(() => {
		if (company) {
			setIsLoading(true);
			fetchData(company.team);
		}
	}, [company]);

	return (
		<DrawerContainer
			header={<CompanyStub company={company}/>}
		>
			<Table
				dataSource={dataSource}
				loading={isLoading}
				columns={columns}
				size="middle"
				bordered
			/>
		</DrawerContainer>
	);
};

export default CompanyTeamDrawerContent;
