import Popin from '../../app/modal/Popin';
import JobForm from './JobForm';
import useBodyScrollBlocker from '../../../hooks/useBodyScrollBlocker';
import ModalContainerBase from '../../../contexts/modals/ModalContainerBase';

const JobPopover = (
	{
		open,
		setOpen,
		title,
		initialValues = {},
		onSubmit,
		onReset,
	},
) => {
	const className = 'JobPopover';

	useBodyScrollBlocker(open, className);
	return <ModalContainerBase
		open={open}
		setOpen={setOpen}
		onClose={onReset}
		width={1400}
	>
		<JobForm
			initialValues={initialValues}
			onSubmit={onSubmit}
			onReset={onReset}
			title={title}
		/>

	</ModalContainerBase>;
};

export default JobPopover;
