import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getCompanySelected } from '../reducers/app';
import { LocalDebug } from '../utils/LocalDebug';
import Logo50inTech from '../components/app/Logo50inTech';
import { LOGO_50INTECH_FULL_WHITE_SVG } from '../constants/logos';
import { useTheme } from '@emotion/react';
import { Divider, Space } from 'antd';
import { arrayWrap } from '../utils/common';
import {
	BIAS_ALIGNMENT_OPTIONS,
	BIAS_LEVEL_OPTIONS,
	BIAS_OPTIONS,
	BiasAlignmentTag,
	BiasLevelTag,
	PERSONA_EMPLOYEES_ALL,
	PERSONA_EMPLOYEES_ALL_ID,
	PERSONA_EMPLOYEES_ALL_SHORT_ID,
	PERSONA_EMPLOYEES_FEMALE_SHORT_ID,
	PERSONA_EMPLOYEES_GENDER_ID,
	PERSONA_EMPLOYEES_MALE_SHORT_ID,
	PERSONA_EMPLOYEES_MANAGER_ID,
	PERSONA_MANAGER,
	PERSONA_MANAGER_ID,
	PERSONA_MANAGER_SHORT_ID,
	RadarBiasTag,
	TestAnalysisTextsAsMarkdown,
	findPersona,
	getScoreBiasPersonaId,
	getBiasLevelId,
	getBiasAlignmentId,
	getRadarBiasData,
	RadarBiasTagPdf,
} from '../components/ums/data/biases';
import BiasRadar from '../components/ums/results/display/BiasRadar';
import CompanyModel from '../models/CompanyModel';
import Callout from '../components/app/callout/Callout';
import AdminToolsUMSSection from '../components/staff/tools/AdminToolsUMSSection';
import { UMSInviteContext } from '../components/ums/UMSInviteProvider';
import { deburr } from 'lodash';
import CompanyGridPicker from '../components/company/CompanyGridPicker';
import HiddenContent from '../components/app/layout/HiddenContent';

export const UnbiasedScoreContext = React.createContext();

const UnbiasedScoreProvider = ({
	children,
}) => {
	const className = 'UnbiasedScoreProvider';

	const theme = useTheme();
	const dispatch = useDispatch();
	// const location = useLocation();
	const companySelected = useSelector(getCompanySelected);
	const {
		loadResults, isResultsLoading, companyResults, managerResults,
		makeManagerPDFResultFilename: umsMakeManagerPDFResultFilename,
		makeCompanyPDFResultFilename: umsMakeCompanyPDFResultFilename,
		appLang, setAppLang,
	} = useContext(UMSInviteContext);

	// const [logoId, setLogoId] = useState(`${moment().format('YYYYMMDDHHmmss')}-${Math.round(100 * Math.random())}`);
	const [imageUris, setImageUris] = useState(new Map());
	const [logoId, setLogoId] = useState('ums-logo50');
	const [girlScalesId, setGirlScalesId] = useState('ums-girl-scales');
	const [gradientId, setGradientId] = useState('ums-gradient');
	const [radarEmployeeId, setRadarEmployeeId] = useState('ums-radar-employee');
	const [radarEmployeeManagerId, setRadarEmployeeManagerId] = useState('ums-radar-employees-manager');
	const [radarGenderId, setRadarGenderId] = useState('ums-radar-gender');
	const [radarManagerId, setRadarManagerId] = useState('ums-radar-manager');
	const [genderChartId, setGenderChartId] = useState('ums-gender-chart');
	// const [radarSize, setRadarSize] = useState([2000 * 0.6, 1000 * 0.6]);
	const [radarSize, setRadarSize] = useState([2000 * 0.6, 2000 * 0.6]);
	const [refresh, setRefresh] = useState(0);
	const [umsResult, setUMSResult] = useState();
	const [pageList, setPageList] = useState(JSON.parse(localStorage.getItem('UMSPageList') || '[]'));
	// const [data, setData] = useState();

	LocalDebug.logInfo({ className });

	const biasLevelTagSizes = [82, 13];
	const biasLevelTagRatio = 5;

	const getRadarId = (persona) => {
		return {
			[PERSONA_EMPLOYEES_MANAGER_ID]: radarEmployeeManagerId,
			[PERSONA_EMPLOYEES_ALL_ID]: radarEmployeeId,
			[PERSONA_MANAGER_ID]: radarManagerId,
			[PERSONA_EMPLOYEES_GENDER_ID]: radarGenderId,
		}[persona?.id || persona] || radarEmployeeId;
	};

	const setPageListStorage = (p) => {
		setPageList(p);
		localStorage.setItem('UMSPageList', JSON.stringify(p));
	};
	const getPageListStorage = () => JSON.parse(localStorage.getItem('UMSPageList') || '[]');

	const getBiasData = ({ personas = [] }) => getRadarBiasData({
		result: umsResult,
		personas,
	});

	const makeManagerPDFResultFilename = (options) => umsMakeManagerPDFResultFilename({
		user: umsResult?.user,
		company: companySelected,
		lang: appLang,
		...options,
	});

	const makeCompanyPDFResultFilename = (options) => umsMakeCompanyPDFResultFilename({
		company: companySelected,
		lang: appLang,
		...options,
	});

	useEffect(() => {
		LocalDebug.logInfo({ className }, 'db', companySelected?.umsResults?.[0]);
		if (new CompanyModel(companySelected)?.isUMSEnabled()) {
			loadResults();
		}
	}, [companySelected]);

	useEffect(() => {
		LocalDebug.logInfo({ className }, { companyResults, managerResults });
		if (managerResults?.[0]) {
			setUMSResult(managerResults?.[0]);
			setImageUris(new Map());
		}
	}, [companyResults, managerResults]);

	// if (location.pathname !== '/unbiased-management-score') {
	// 	return children;
	// }
	if (!companySelected) return <CompanyGridPicker withPageLayout={false} />;

	let errorMessage;
	if (!companySelected) {
		errorMessage = 'No company selected';
	} else if (!new CompanyModel(companySelected).isUMSEnabled()) {
		errorMessage = `UMS not enabled for company ${companySelected?.name}`;
	// } else if (!umsResult) {
	// 	errorMessage = `No CompanyUMSResult detected for ${companySelected?.name}`;
	}

	if (errorMessage) {
		return (
			<>
				<Callout
					withFeedback={false}
					icon={false}
					style={{
						background: theme.color.accent,
						color: 'white',
						fontWeight: 'bold',
					}}
				>
					{errorMessage}
				</Callout>
				<Divider />

				<AdminToolsUMSSection />
			</>
		);
	}

	if (isResultsLoading) {
		return 'Loading results...';
	}

	return (
		<UnbiasedScoreContext.Provider
			value={{
				appLang,
				setAppLang,
				logoId,
				girlScalesId,
				gradientId,
				radarEmployeeId,
				radarManagerId,
				radarGenderId,
				getRadarId,
				getScoreBiasPersonaId,
				getBiasLevelId,
				getBiasAlignmentId,
				getBiasData,
				radarSize,
				genderChartId,
				imageUris,
				setImageUris,
				refresh,
				setRefresh,
				umsResult,
				setUMSResult,
				biasLevelTagSizes,
				biasLevelTagRatio,
				pageList,
				setPageList,
				getPageListStorage,
				setPageListStorage,
				makeManagerPDFResultFilename,
				makeCompanyPDFResultFilename,
				// data,
				// setData,
			}}
		>
			{children}
			<div
				style={{
					// marginLeft: 290,
					marginTop: 0,
				}}
			>
				{/* <HiddenContent hide={!true} style={{ fontSize: 10 }}>
							<ReactJson src={umsResult} name='results' collapsed={2} sortKeys={true} />
						</HiddenContent> */}
				<HiddenContent hide={true}>
					<TestAnalysisTextsAsMarkdown />
				</HiddenContent>
				<HiddenContent hide={true}>
					<Space size={2}>
						{BIAS_OPTIONS?.map((bias) => (
							<>
								<div key={`${bias?.id}-en`}>
									<RadarBiasTagPdf
										ratio={5}
										bias={bias}
										data={umsResult}
										personas={[PERSONA_MANAGER, PERSONA_EMPLOYEES_ALL]}
										context={{ appLang: 'en' }}
									/>
								</div>
								<div key={`${bias?.id}-fr`}>
									<RadarBiasTagPdf
										ratio={5}
										bias={bias}
										data={umsResult}
										personas={[PERSONA_MANAGER, PERSONA_EMPLOYEES_ALL]}
										context={{ appLang: 'fr' }}
									/>
								</div>
							</>
						))}
					</Space>
				</HiddenContent>
				<HiddenContent hide={true}>
					{BIAS_ALIGNMENT_OPTIONS?.map((alignment) => (
						<>
							<div
								id={getBiasAlignmentId({ alignment, context: { appLang: 'en' } })}
								key={getBiasAlignmentId({ alignment, context: { appLang: 'en' } })}
								style={{
									width: biasLevelTagSizes[0] * biasLevelTagRatio,
									height: biasLevelTagSizes[1] * biasLevelTagRatio,
									// background: 'rgba(255,0,0,0.2)',
									overflow: 'hidden',
								}}
							>
								<BiasAlignmentTag
									alignment={alignment}
									ratio={biasLevelTagRatio}
									context={{ appLang: 'en' }}
								/>
							</div>
							<div
								id={getBiasAlignmentId({ alignment, context: { appLang: 'fr' } })}
								key={getBiasAlignmentId({ alignment, context: { appLang: 'fr' } })}
								style={{
									width: biasLevelTagSizes[0] * biasLevelTagRatio,
									height: biasLevelTagSizes[1] * biasLevelTagRatio,
									// background: 'rgba(255,0,0,0.2)',
									overflow: 'hidden',
								}}
							>
								<BiasAlignmentTag
									alignment={alignment}
									ratio={biasLevelTagRatio}
									context={{ appLang: 'fr' }}
								/>
							</div>
						</>
					))}
					{BIAS_LEVEL_OPTIONS?.map((level) => (
						<>
							<div
								id={getBiasLevelId({ level, context: { appLang: 'en' } })}
								key={getBiasLevelId({ level, context: { appLang: 'en' } })}
								style={{
									width: biasLevelTagSizes[0] * biasLevelTagRatio,
									height: biasLevelTagSizes[1] * biasLevelTagRatio,
									// background: 'rgba(255,0,0,0.2)',
									overflow: 'hidden',
								}}
							>
								<BiasLevelTag level={level} ratio={biasLevelTagRatio} />
							</div>
							<div
								id={getBiasLevelId({ level, context: { appLang: 'fr' } })}
								key={getBiasLevelId({ level, context: { appLang: 'fr' } })}
								style={{
									width: biasLevelTagSizes[0] * biasLevelTagRatio,
									height: biasLevelTagSizes[1] * biasLevelTagRatio,
									// background: 'rgba(255,0,0,0.2)',
									overflow: 'hidden',
								}}
							>
								<BiasLevelTag
									level={level}
									ratio={biasLevelTagRatio}
									context={{ appLang: 'fr' }}
								/>
							</div>
						</>
					))}
				</HiddenContent>
				{/* <HiddenContent hide={true}>
					<div
						id={genderChartId}
					>
						<BarChart
							width={2000}
							height={1000}
						/>
					</div>
				</HiddenContent> */}
				<HiddenContent hide={true} style={{ background: 'black' }}>
					<Logo50inTech
						src={LOGO_50INTECH_FULL_WHITE_SVG}
						width={72 * 5}
						height={29 * 5}
						id={logoId}
						loading='unset'
					/>
				</HiddenContent>
				<HiddenContent hide={true}>
					<img
						id={girlScalesId}
						// loading='lazy'
						width='1200' height='1200'
						src='https://res.cloudinary.com/dkatmtbbc/image/upload/v1712767529/app/unbiased-score/girl-scales.png'
						alt={girlScalesId}
					/>
				</HiddenContent>
				<HiddenContent hide={true}>
					<div
						id={gradientId}
						style={{
							width: 1600,
							height: 1600,
							background: theme.background.gradientPdf(),
						}}
					>
							&nbsp;
					</div>
				</HiddenContent>
				<HiddenContent hide={true}>
					<div
						id={radarEmployeeId}
						style={{
							width: radarSize[0],
							height: radarSize[1],
						}}
					>
						<BiasRadar
							width={radarSize[0]}
							height={radarSize[1]}
							margin={{
								top: 0, left: 0, right: 0, bottom: 140,
							}}
							levels={5}
							data={getBiasData({ personas: [PERSONA_EMPLOYEES_ALL_SHORT_ID] })}
						/>
					</div>
				</HiddenContent>

				<HiddenContent hide={true} style={{ zoom: 0.4 }}>
					<div
						id={radarEmployeeManagerId}
						style={{
							width: radarSize[0],
							height: radarSize[1],
						}}
					>
						<BiasRadar
							width={radarSize[0]}
							height={radarSize[1]}
							margin={{
								top: 0, left: 0, right: 0, bottom: 140,
							}}
							levels={5}
							data={getBiasData({
								personas: [
									PERSONA_EMPLOYEES_ALL_SHORT_ID,
									PERSONA_MANAGER_SHORT_ID,
								],
							})}
						/>
					</div>
				</HiddenContent>

				<HiddenContent hide={true}>
					<div
						id={radarManagerId}
						style={{
							width: radarSize[0],
							height: radarSize[1],
						}}
					>
						<BiasRadar
							width={radarSize[0]}
							height={radarSize[1]}
							margin={{
								top: 0, left: 0, right: 0, bottom: 140,
							}}
							levels={5}
							data={getBiasData({
								personas: [PERSONA_MANAGER_SHORT_ID],
							})}
						/>
					</div>
				</HiddenContent>

				<HiddenContent hide={true}>
					<div
						id={radarGenderId}
						style={{
							width: radarSize[0],
							height: radarSize[1],
						}}
					>
						<BiasRadar
							width={radarSize[0]}
							height={radarSize[1]}
							margin={{
								top: 0, left: 0, right: 0, bottom: 140,
							}}
							levels={5}
							data={getBiasData({
								personas: [
									PERSONA_EMPLOYEES_FEMALE_SHORT_ID,
									PERSONA_EMPLOYEES_MALE_SHORT_ID,
								],
							})}
						/>
					</div>
				</HiddenContent>
			</div>
		</UnbiasedScoreContext.Provider>
	);
};

export default UnbiasedScoreProvider;
