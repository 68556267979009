import { useEffect } from 'react';
import { useLocation } from 'react-router';
import { useSelector } from 'react-redux';
import { useTheme } from '@emotion/react';
import { Divider, Space } from 'antd';
import { scrollToTop } from '../../../utils/common';
import Box from '../../app/box/Box';
import CompanyGenderScoreNotFilledContent from '../companyPage/fields/CompanyGenderScoreNotFilledContent';
import { getCompanySelected } from '../../../reducers/app';
import GenderScoreLevelDetails from './levels/GenderScoreLevelDetails';
import { GENDERSCORE_LABEL } from '../../../constants/constant';
import GenderScoreSolutionsSection from './results/GenderScoreSolutionsSection';

const CompanyGenderScorePresentation = ({
	...props
}) => {
	const className = 'CompanyGenderScorePresentation';

	const theme = useTheme();

	const location = useLocation();

	const companySelected = useSelector(getCompanySelected);

	useEffect(() => {
		scrollToTop();
	}, [location.pathname]);

	return (
		<div
			style={{
				width: '100%',
				maxWidth: 860,
				margin: '40px auto 0',
			}}
		>
			<Space
				direction='horizontal'
				wrap={false}
				justifyContent='center'
				style={{
					width: '100%',
				}}
			>
				<Box
					withShadow={true}
					style={{
						background: theme.background.gradientBluePink(135),
						padding: '12px 32px',
						borderRadius: 12,
						border: 'none',
						width: 860,
					}}
				>
					<Space
						direction='horizontal'
						wrap={false}
						justifyContent='center'
						size={32}
						style={{
							margin: '0 auto',
						}}
					>
						<CompanyGenderScoreNotFilledContent
							company={companySelected}
							withCloseMessage={false}
						/>
					</Space>
				</Box>
			</Space>

			{/* <GenderScoreSolutionsSection
				id={'solutions'}
				company={companySelected}
				imgRatio={0.6}
			/> */}

			<Divider style={{ marginTop: 60 }}>
				<h2>The {GENDERSCORE_LABEL} Levels</h2>
			</Divider>

			<p style={{ fontSize: 16 }}>
				Once you've taken the test, it's essential to understand
				the implications of your score.
				<br />Here's what each bracket signifies:
			</p>

			<GenderScoreLevelDetails />
		</div>
	);
};

export default CompanyGenderScorePresentation;
