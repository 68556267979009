import {
	Divider,
	Modal, Space, Typography,
} from 'antd';
import { useDispatch } from 'react-redux';
import { useTheme } from '@emotion/react';
import {
	FaBuilding, FaPlusCircle, FaSync,
} from 'react-icons/fa';
import * as adminActions from '../../../actions/admin';
import AddButton from '../../app/button/AddButton';
import { useAuthContext } from '../../../hooks/useAuthContext';
import AdminToolsSection from './AdminToolsSectionContainer';
import { BUSINESS_PLAN_OPTIONS } from '../../../constants/property/business-plan';
import { loadCompanies } from '../../../actions/company';
import CompanyPlanTag from '../../company/CompanyPlanTag';
import AdminToolsUseCasePicker from './AdminToolsUseCasePicker';

const AdminToolsPlansSection = () => {
	const className = 'AdminToolsPlansSection';

	const theme = useTheme();
	const dispatch = useDispatch();
	const { isAdmin, currentUser } = useAuthContext();

	const confirmDispatch = (label, callback) => {
		Modal.confirm({
			title: <>
				<div><b>{label}</b></div>
				Are you sure you want to proceed?</>,
			okText: 'Yes',
			cancelText: 'No',
			onOk() {
				callback();
			},
		});
	};

	const btnProps = { icon: null, color: theme.color.darkturquoise };

	const buildConfirmBtn = ({
		title, Icon, confirmText, dispatcher, styles,
	}) => <AddButton
		{...btnProps}
		style={{ ...styles }}
		title={title}
		icon={<Icon style={{ marginBottom: -2 }}/>}
		onClick={
			() => confirmDispatch(
				confirmText || title,
				() => { dispatch(dispatcher()); },
			)
		}
	/>;

	return (
		<AdminToolsSection
			title='Test Companies for Business Plans'
			Icon={FaBuilding}
		>
			<Space direction='horizontal' size={10} wrap={true} style={{ marginBottom: 24 }}>
				{
					BUSINESS_PLAN_OPTIONS
						.map((planOption) => ({
							title: (
								<>
									<CompanyPlanTag planValue={planOption.value} style={{ marginBottom: 4 }} />
									<br/>
									Test: {planOption.label}
								</>
							),
							companyName: `Test: ${planOption.shortLabel}`,
							createCompany: () => { return adminActions.addTestPlanCompany(planOption); },
							createRoles: () => { return adminActions.addTestPlanRoles(planOption); },
						}))
						.map((item, index) => (
							<Space key={index} direction='vertical' size={0} style={{
								padding: '10px 20px',
								borderRadius: 10,
								marginTop: 10,
								border: '1px solid #eee',
								// background: mixColors(theme.color.darkturquoise, theme.color.white, 90),
							}}>
								<Typography.Title level={4} style={{ width: '100%' }}>
									{item.logo} {item.title}
								</Typography.Title>
								<Space direction='horizontal'>
									{item.createCompany && buildConfirmBtn({
										theme,
										title: 'Add company',
										confirmText: `Create '${item.companyName}' company`,
										Icon: FaPlusCircle,
										dispatcher: item.createCompany,
									})}
									{item.createRoles && buildConfirmBtn({
										theme,
										title: 'Add roles',
										confirmText: `Create roles for '${item.companyName}' company`,
										Icon: FaPlusCircle,
										dispatcher: item.createRoles,
									})}
								</Space>
							</Space>
						))
				}
			</Space>

			<Space direction='horizontal'>
				{buildConfirmBtn({
					theme,
					title: 'Reload companies',
					Icon: FaSync,
					dispatcher: loadCompanies,
					styles: {
						background: theme.color.fitBlueElectric,
					},
				})}
			</Space>

			{isAdmin && (
				<>
					<Divider />

					<AdminToolsUseCasePicker />
				</>
			)}
		</AdminToolsSection>
	);
};

export default AdminToolsPlansSection;
