/** @jsxImportSource @emotion/react */
import {
	Button, Form, Select, Spin, message, Row, Col,
} from 'antd';
import { FaArrowCircleRight, FaPaperPlane } from 'react-icons/fa';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTheme } from '@emotion/react';
import Link from '../../components/app/Link';
import CoachingService from '../../services/coaching';
import FormItem from '../../components/company/form/FormItem';
import { listCoaches } from '../../actions/coaching';
import UserStub from '../../components/user/stub/UserStub';
import { documentId, openExternalUrl, requiredRule } from '../../utils/common';
import LangFlagColorTag from '../../components/app/tag/LangFlagColorTag';
import ModalContainerBase from '../../contexts/modals/ModalContainerBase';
import FormBoxTitle from '../../components/app/form/FormBoxTitle';
import { LoadingOutlined } from '@ant-design/icons';

function CoachEditModal(
	{
		open,
		setOpen,
		selectedUser,
		onRefresh,
	},
) {
	const [form] = Form.useForm();
	const theme = useTheme();
	const [selectedCoach, setSelectedCoach] = useState(null);
	const dispatch = useDispatch();
	const [loading, setLoading] = useState(false);
	const [disabledSave, setDisabledSave] = useState(true);
	const coaches = useSelector((state) => state.app.coaches);

	const handleSubmit = async (values) => {
		setLoading(true);
		CoachingService.editCoach(documentId(selectedUser), values)
			.then((res) => {
				message.success('Coach edited successfully!');
				if (onRefresh) onRefresh();
				form.resetFields();
				setOpen(false);
				setLoading(false);
			})
			.catch((err) => {
				message.error('Failed to edit coach!');
				setLoading(false);
			});
	};

	const handleFormChange = () => {
		const hasErrors = form.getFieldsError().some(({ errors }) => errors.length);
		setDisabledSave(hasErrors);
	};

	const onChangeCoach = (coachId) => {
		const coach = coaches?.find((c) => documentId(c) === coachId);
		setSelectedCoach(coach);
		return coach;
	};

	useEffect(() => {
		if (!coaches?.length) dispatch(listCoaches());
	}, []);

	useEffect(() => {
		const currentCoach = onChangeCoach(selectedUser?.coachId || null);
		form.setFieldsValue({
			coachId: documentId(currentCoach),
		});
	}, [form, selectedUser, coaches]);

	return (
		<ModalContainerBase
			open={open}
			setOpen={setOpen}
			width={700}
		>
			<FormBoxTitle
				title={'Edit a talent\'s coach'}
			/>
			<Form
				form={form}
				labelCol={{ span: 6 }}
				name="edit-coach-form"
				onFinish={handleSubmit}
				onFieldsChange={handleFormChange}
				style={{
					width: '100%',
				}}
			>
				<Row>
					<Col span={24}>

						<FormItem label='Talent'>
							<UserStub user={selectedUser}
								style={{ padding: 10, border: '1px solid #eee', borderRadius: 4 }}>
								<span style={{ fontSize: 10 }}><b>Interface language: </b><LangFlagColorTag
									lang={selectedUser?.settings?.lang} /></span>
							</UserStub>
						</FormItem>

					</Col>
				</Row>

				<Row>
					<Col span={24}
						css={{
							'.ant-select-selector, .ant-select-focus': {
								width: '100%!important',
								height: '50px!important',
								paddingTop: 10,
							},
							'.ant-select-selection-placeholder': {
								marginTop: 8,
							},
						}}
					>

						<FormItem label='Coach' required={true} colSpan={4}>
							<FormItem
								name="coachId"
								rules={[{ ...requiredRule, message: 'Please select a coach!' }]}
								noStyle
							>
								<Select
									onChange={(e) => onChangeCoach(e)}
									showArrow={true}
									allowClear={true}
									placeholder="Select a coach"
									mode='single'
									style={{ display: 'inline-block', width: '100%', height: 50 }}
								>
									{coaches?.map((user) => (
										<Select.Option
											value={documentId(user)}
											key={documentId(user)}
											style={{ height: 60 }}
										>
											<UserStub user={user} withProfession={false} withLocation={false}
												withPreview={false} style={{ padding: '5px 0' }} />
										</Select.Option>
									))}
								</Select>

							</FormItem>

						</FormItem>
					</Col>
				</Row>

				{selectedCoach && (
					<Row>
						<Col span={24}>
							<FormItem label='&nbsp;'>
								<Link
									onClick={() => openExternalUrl(selectedCoach?.coachDetails?.calendly)}
								>
									Open coach Calendly <FaArrowCircleRight style={{ marginBottom: -2 }} />
								</Link>
							</FormItem>
						</Col>
					</Row>
				)}

				<FormItem
					label={<>&nbsp;</>}
				>
					<Button
						htmlType="submit"
						type='primary'
						// size='large'
						disabled={!open || loading || disabledSave}
					>
						Edit coach
						&nbsp;
						{loading ? (
							<Spin
								indicator={
									<LoadingOutlined
										style={{
											marginBottom: -2,
											color: 'white',
										}}
										spin
									/>}
							/>
						) : (
							<FaPaperPlane
								style={{
									marginBottom: -2,
									// marginLeft: 5,
								}}
							/>
						)}
					</Button>
				</FormItem>

			</Form>
		</ModalContainerBase>
	);
}

export default CoachEditModal;
