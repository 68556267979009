import { useTheme } from '@emotion/react';
import {
	Affix, Button, Space,
} from 'antd';
import PageLayout from '../../app/layout/PageLayout';
import UserProfilePageHeader from './UserProfilePageHeader';
import UserGridCard from '../grid/card/UserGridCard';
import UserGridCardLayout from '../grid/card/UserGridCardLayout';
import UserGridCardLeftColumn from '../grid/card/UserGridCardLeftColumn';
import UserProfileRightColumn from './UserProfileRightColumn';
import { rowSpanner } from '../grid/card/UserGridCardUtils';
import UserProfileBoxValue from './UserProfileBoxValue';
import BlockStaff from '../../app/blocker/BlockStaff';
import UserResumeManager from '../widgets/resume/UserResumeManager';
import { useAuthContext } from '../../../hooks/useAuthContext';
import { useContext } from 'react';
import { UserModalContext } from '../../../contexts/modals/UserModalProvider';
import { AppDataContext } from '../../../contexts/AppDataProvider';

const UserProfilePageCard = ({
	user,
	affixTop = 0,
	onRefresh,
	onUserChanged,
}) => {
	const className = 'UserProfilePageCard';

	const theme = useTheme();

	const { isStaffView } = useAuthContext();
	const { isAppBannerVisible, appBannerHeight } = useContext(AppDataContext);
	const { setOpen, setUser } = useContext(UserModalContext);

	return (
		<div
			className='user-profile'
		>
			<PageLayout
				withHeader={false}
				withPadding={false}
			>
				<Affix
					offsetTop={affixTop + (isAppBannerVisible ? appBannerHeight : 0)}
				>
					<UserProfilePageHeader
						user={user}
						isAffixedHead={true}
						onRefresh={onRefresh}
						onUserChanged={onUserChanged}
					/>
				</Affix>

				<UserGridCardLayout
					leftColumn={(
						<UserGridCardLeftColumn
							user={user}
							deiBgColor={'white'}
						/>
					)}
					rightColumn={(
						<>
							<UserProfileRightColumn
								user={user}
								onRefresh={onRefresh}
							/>
						</>
					)}
					hoverable={false}
					style={{
						border: 'none',
						borderRadius: 'unset',
						leftColumn: {
							background: 'none',
						},
					}}
				>
					<div style={{ padding: 42 }}>

						<Space
							direction='vertical'
							size={20}
							style={{
								width: '100%',
							}}
						>
							{isStaffView && (
								rowSpanner([
									[[
										{
											content: (
												<BlockStaff>
													<UserProfileBoxValue
														label='LinkedIn PDF Upload'
													>
														<UserResumeManager
															user={user}
															onUpload={onRefresh}
															onRefresh={onRefresh}
														/>
													</UserProfileBoxValue>
												</BlockStaff>
											),
											span: 24,
										},
										{
											content: (
												<BlockStaff>
													<h3>Card display for this talent</h3>
													<UserGridCard user={user} />
												</BlockStaff>
											),
											span: 24,
										},
										{
											content: (
												<BlockStaff>
													<Button
														type='primary'
														size='large'
														onClick={() => {
															setOpen(true);
															setUser(user);
														}}
													>
														<b>Open talent profile in modal</b>
													</Button>
												</BlockStaff>
											),
											span: 24,
										},
									]],
								])
							)}
						</Space>
					</div>
				</UserGridCardLayout>
			</PageLayout>
		</div>
	);
};

export default UserProfilePageCard;
