import { useContext, useEffect, useState } from 'react';
import { useTheme } from '@emotion/react';
import { useNavigate } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { Space, Switch, message } from 'antd';
import UserModel from '../../../../models/UserModel';
import { StaffManagerPanelDivider, StaffManagerPanelLink } from '../StaffManagerPanel';
import { StaffToolsContext } from '../../../../contexts/StaffToolsProvider';
import { getUserSelected } from '../../../../reducers/app';
import AdminService from '../../../../services/admin';

const StaffManagerUserPageTab = () => {
	const className = 'StaffManagerUserPageTab';

	const theme = useTheme();
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const {
		userPageToggleCardView,
		setUserPageToggleCardView,
		userPageToggleForceNotContactedYet,
		setUserPageToggleForceNotContactedYet,
		isUserGridCardSuggestedJobsInline,
		setIsUserGridCardSuggestedJobsInline,
	} = useContext(StaffToolsContext);

	const user = useSelector(getUserSelected);

	const [userModel, setUserModel] = useState();

	useEffect(() => {
		setUserModel(
			user
				? new UserModel(user)
				: null,
		);
	}, [user]);

	const handleRandomizeUser = async (query) => {
		const { data } = await AdminService.getRandomUserSlug({ query });
		if (!data?.slug) {
			message.error('No random user found');
		} else {
			navigate(`/user/${data?.slug}`);
		}
	};
	return (
		<Space
			direction='vertical'
			size={1}
			style={{
				width: '100%',
				padding: '0 15px 15px 15px',
				fontSize: 12,
			}}
		>

			<StaffManagerPanelDivider>
					UI & Display
			</StaffManagerPanelDivider>

			<div>
					Full-page display&nbsp;&nbsp;
				<Switch
					checked={userPageToggleCardView}
					onChange={() => setUserPageToggleCardView((p) => !p)}
					checkedChildren={<b>New UI</b>}
					unCheckedChildren={<b>Legacy UI</b>}
				/>
			</div>

			<div>
					Show email&nbsp;&nbsp;
				<Switch
					checked={userPageToggleForceNotContactedYet}
					onChange={() => setUserPageToggleForceNotContactedYet((p) => !p)}
					checkedChildren={<b>Force hide email</b>}
					unCheckedChildren={<b>Show email</b>}
				/>
			</div>

			<div>
				<Switch
					size='small'
					checked={isUserGridCardSuggestedJobsInline}
					onChange={() => setIsUserGridCardSuggestedJobsInline((p) => !p)}
				/>
					&nbsp;&nbsp;Suggested jobs displayed inline
			</div>

			<StaffManagerPanelDivider>
					Randomize talent
			</StaffManagerPanelDivider>

			<StaffManagerPanelLink
				label={<b>Talent never onboarded</b>}
				onClick={() => handleRandomizeUser({
					'status': 'incomplete',
					'funnel.onboarding.v1.status': 'incomplete',
				})}
				confirm={false}
				tooltip={<>
						This will open the profile of another random talent (never onboarded)
				</>}
			/>

			<StaffManagerPanelLink
				label={<b>Talent onboarded on v1 only</b>}
				onClick={() => handleRandomizeUser({
					'funnel.onboarding.v1.status': 'complete',
					'funnel.onboarding.v2.status': 'incomplete',
				})}
				confirm={false}
				tooltip={<>
						This will open the profile of another random talent (onboarded on v1 only)
				</>}
			/>

			<StaffManagerPanelLink
				label={<b>Talent fully onboarded (v2)</b>}
				onClick={() => handleRandomizeUser({
					'funnel.onboarding.v2.status': 'complete',
				})}
				confirm={false}
				tooltip={<>
						This will open the profile of another random talent (onboarded on v2)
				</>}
			/>

			<StaffManagerPanelLink
				label={<b>Talent pre-screened</b>}
				onClick={() => handleRandomizeUser({
					'coaching.status': 'user-sent',
				})}
				confirm={false}
				tooltip={<>
						This will open the profile of another random talent (pre-screened)
				</>}
			/>

			<StaffManagerPanelLink
				label={<b>Talent with resume</b>}
				onClick={() => handleRandomizeUser({ 'resume.createdAt': { $exists: true } })}
				confirm={false}
				tooltip={<>
						This will open the profile of another random talent (with resume)
				</>}
			/>

			<StaffManagerPanelLink
				label={<b>Talent with biography</b>}
				onClick={() => handleRandomizeUser({ 'information.biography': { $nin: [null, '', ' '] } })}
				confirm={false}
				tooltip={<>
						This will open the profile of another random talent (with biography)
				</>}
			/>

			<StaffManagerPanelLink
				label={<b>Talent suggested</b>}
				onClick={() => handleRandomizeUser({ suggested: true })}
				confirm={false}
				tooltip={<>
						This will open the profile of another random talent (suggested)
				</>}
			/>
		</Space>
	);
};

export default StaffManagerUserPageTab;
