import {
	Button, Space, Tabs,
} from 'antd';
import { useContext } from 'react';
import Link from '../app/Link';
import { UserSavedSearchContext } from './UserSavedSearchProvider';
import {
	SEARCH_ICON, SUGGESTED_BY_50_ICON,
} from '../../constants/constant';
import { useSelector } from 'react-redux';
import { getCompanySelected } from '../../reducers/app';
import UserSavedSearchBadge from './UserSavedSearchBadge';
import GenderhireSearchTalentsMenu from '../../menu/genderhire/GenderhireSearchTalentsMenu';

const UserSavedSearchesPageSelectorButton = (
	{
		children,
		page,
		...props
	},
) => {
	const className = 'UserSavedSearchesPageSelectorButton';

	const { selectedPage } = useContext(UserSavedSearchContext);

	return (
		<Button
			{...props}
			type={selectedPage === page ? 'primary' : 'default'}
			size='large'
			style={{
				borderRadius: 40,
				fontWeight: 'bold',
				...props?.style,
			}}
		>
			{children}
		</Button>
	);
};

const SearchTalentsTabs = (
	{
		user,
		layoutMode = 'tabs',
		...props
	},
) => {
	const className = 'SearchTalentsTabs';
	const companySelected = useSelector(getCompanySelected);

	const {
		mySavedSearches, suggestionSearch,
		selectedPage, addSelectedPageQueryParams,
		SUGGESTED_TALENTS_PAGE, MY_SEARCHES_PAGE, HIDDEN_TALENTS_PAGE,
	} = useContext(UserSavedSearchContext);

	const onSelectPage = (page) => {
		addSelectedPageQueryParams(page);
	};

	const searchTalentsMenu = new GenderhireSearchTalentsMenu();

	if (layoutMode === 'tabs') {
		return (<>
			<Tabs
				activeKey={selectedPage}
				className='search-talents-tabs'
				onTabClick={(key) => onSelectPage(key)}
				style={{ margin: '0 0 0 -24px' }}
				tabBarExtraContent={(
					<Link
						onClick={() => onSelectPage(HIDDEN_TALENTS_PAGE)}
						style={{ marginRight: 48 }}
					>
						Hidden talents
					</Link>
				)}
			>
				<Tabs.TabPane
					tab={<Space>
						{searchTalentsMenu.icon}
						My saved searches
						<UserSavedSearchBadge
							count={mySavedSearches?.map((s) => s.alerts?.length || 0).reduce((p, c) => p + c, 0)}
						 />
					</Space>}
					key={MY_SEARCHES_PAGE}
					style={{ padding: 0, margin: 0 }}
				/>
				{companySelected?.isSuggestion !== false && (
					<Tabs.TabPane
						tab={<Space>
							{SUGGESTED_BY_50_ICON}
							Suggested for you
							<UserSavedSearchBadge count={suggestionSearch?.alerts?.length}/>
						</Space>}
						key={SUGGESTED_TALENTS_PAGE}
						style={{ padding: 0, margin: 0 }}
					/>
				)}
			</Tabs>
		</>);
	}

	return (
		<>
			<Space>
				<UserSavedSearchesPageSelectorButton
					page={MY_SEARCHES_PAGE}
					onClick={() => onSelectPage(MY_SEARCHES_PAGE)}
				>
					{searchTalentsMenu.icon}&nbsp;My saved searches
				</UserSavedSearchesPageSelectorButton>

				<UserSavedSearchesPageSelectorButton
					page={SUGGESTED_TALENTS_PAGE}
					onClick={() => onSelectPage(SUGGESTED_TALENTS_PAGE)}
				>
					{SUGGESTED_BY_50_ICON}&nbsp;Suggested for you
				</UserSavedSearchesPageSelectorButton>
			</Space>
		</>
	);
};

export default SearchTalentsTabs;
