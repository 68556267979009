import { useTheme } from '@emotion/react';

const CompanyFieldNotProvided = (
	{
		children,
		...props
	},
) => {
	const className = 'CompanyFieldNotProvided';

	const theme = useTheme();

	return (
		<div
			style={{
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
				textAlign: 'center',
				width: '100%',
				height: '100%',
				// background: theme.color.bgGrey,
				fontStyle: 'italic',
				fontWeight: 'bold',
				padding: '5px 12px',
				borderRadius: 5,
				border: '2px dashed #eee',
				color: theme.color.grey,
				...props?.style,
			}}
		>
			{children || 'Not provided'}
		</div>
	);
};

export default CompanyFieldNotProvided;
