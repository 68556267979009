import axios from 'axios';
import { request } from './api';
import { localDebug } from '../utils/LocalDebug';

// -------------------------------- //
// ------- add cancel request ----- //
// -------------------------------- //
let cancelToken;
const getData = async (path, params) => {
	// cancle previous request
	if (cancelToken && path === cancelToken.path) {
		cancelToken.source.cancel('Operation canceled due to new request.');
	}

	// Save the cancel token for the current request
	cancelToken = { path, source: axios.CancelToken.source() };
	// localDebug({ path, source: cancelToken });
	const dataParams = {
		cancelToken: cancelToken.token,
		params,
	};
	return request.get(path, dataParams);
};

const AppService = {
	getData,
};

export default AppService;
