import { Modal } from 'antd';
import { FaTimes } from 'react-icons/fa';

const ModalContainerBase = ({
	open,
	setOpen,
	width = 500,
	cssClassName = 'modal-large',
	closeIcon = <FaTimes style={{ marginBottom: 0 }} />,
	footer = false,
	children,
	...props
}) => {
	const className = 'ModalContainerBase';

	return (
		<Modal
			className={`user-profile ${footer === false ? 'no-footer' : ''} ${cssClassName}`}
			open={open}
			width={width}
			closeIcon={closeIcon}
			destroyOnClose={true} // to reset scroll between 2 openings
			footer={footer}
			{...props}
			onCancel={() => {
				setOpen?.(false);
				props?.onCancel?.();
			}}
			style={{
				padding: 0,
				borderRadius: 20,
				overflow: 'hidden',
				...props?.style,
			}}
			centered
		>
			<div
				className='modal-container-body'
				style={{ ...props?.style?.body }}
			>
				{children}
			</div>

		</Modal>
	);
};

export default ModalContainerBase;
