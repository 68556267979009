import { useAuthContext } from '../../hooks/useAuthContext';
import StaffClientSwitch from './StaffClientSwitch';

const StaffViewSwitch = (
	{
		label = '👁️ Display',
		tooltip = <div style={{ lineHeight: 1.3 }}>
			<b>Use STAFF or CLIENT display</b><br/>
			<span style={{ fontSize: 12 }}>
				<ul style={{ paddingInlineStart: 12, marginTop: 8 }}>
					<li style={{ marginBottom: 4 }}><b>CLIENT > </b>Same interface (menu, table columns...) that <b>what recruiters see</b></li>
					<li style={{ marginBottom: 4 }}><b>STAFF > Augmented interface</b> with aditional menu items and table columns</li>
				</ul>
			</span>
		</div>,
		// tooltip = <>
		//     <b>Activate STAFF or CLIENT display</b><br/>
		//     <span style={{fontSize: 12}}>
		//         Select 'CLIENT' to see the same <b>interface</b> than recruiters (menu, table columns...)
		//     </span>
		// </>,
		...props
	},
) => {
	const { isStaffView, setIsStaffView } = useAuthContext();

	return <StaffClientSwitch
		label={''}
		tooltip={tooltip}
		initialValue={isStaffView}
		setter={setIsStaffView}
		{...props}
	/>;
};

export default StaffViewSwitch;
