import {
	Form, Input,
} from 'antd';
import FormSubmitButton from '../app/form/FormSubmitButton';
import { useTheme } from '@emotion/react';
import ChatbotPromptHelpers from './ChatbotPromptHelpers';
import { LocalDebug } from '../../utils/LocalDebug';
import { useRef, useState } from 'react';

const ChatbotPrompt = ({
	messages,
	answerPending,
	onSubmit,
	onClear,
}) => {
	const className = 'ChatbotPrompt';
	const theme = useTheme();

	const [form] = Form.useForm();
	const inputRef = useRef();

	const onFinish = async (values) => {
		LocalDebug.logInfo({ className, method: 'onFinish' }, { values });
		const { userPrompt } = values;
		form.setFieldValue('userPrompt', '');
		onSubmit?.({ userPrompt });
	};

	const handleSelectPrompt = async ({ userPrompt, preventStorage }) => {
		LocalDebug.logInfo({ className, method: 'handleSelectPrompt' }, { userPrompt, preventStorage });
		onSubmit?.({ userPrompt, preventStorage });
		inputRef?.current?.focus?.();
	};

	return (
		<div>
			<Form form={form}
				name='chatbot-form'
				onFinish={onFinish}
				style={{
					width: '100%',
				}}
			>
				<div
					style={{
						display: 'grid',
						gridTemplateColumns: '1fr 120px',
						columnGap: 10,
						width: '100%',
					}}
				>
					<Form.Item
						name='userPrompt'
						style={{
							width: '100%',
							margin: 0,
						}}
					>
						<Input
							ref={inputRef}
							style={{
								borderRadius: 24,
								height: 41,
								fontSize: 15,
							}}
						/>
					</Form.Item>

					<Form.Item
						style={{
							width: '100%',
							margin: 0,
						}}
					>
						<FormSubmitButton
							disabled={answerPending}
							size={'large'}
							style={{
								width: '100%',
								margin: 0,
							}}
						>
							Submit
						</FormSubmitButton>
					</Form.Item>

				</div>

			</Form>

			<ChatbotPromptHelpers
				messages={messages}
				onPromptSelected={handleSelectPrompt}
				onClearAnswers={onClear}
			/>
		</div>
	);
};

export default ChatbotPrompt;
