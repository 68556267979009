import { useTheme } from '@emotion/react';
import ModalContainerBase from '../../../contexts/modals/ModalContainerBase';
import FormBoxTitle from '../../app/form/FormBoxTitle';
import SurveyInfoForm from './SurveyInfoForm';
import { LocalDebug } from '../../../utils/LocalDebug';
import { UMS_LABEL } from '../../../constants/constant';

const SurveyInfoModal = ({
	title,
	width = 500,
	open,
	onClose,
	onSubmit,
	children,
	...props
}) => {
	const className = 'SurveyInfoModal';

	const theme = useTheme();

	const handleSubmit = async (values) => {
		LocalDebug.logInfo({ className, method: 'handleSubmit' }, { values });
		await onSubmit(values);
	};

	return (
		<ModalContainerBase
			open={open}
			onCancel={onClose}
		>
			<FormBoxTitle title={'Almost there!'} />

			<p>To compute your {UMS_LABEL}, additional information about your company is needed:
				<br />
			</p>
			<SurveyInfoForm
				onSubmit={handleSubmit}
			/>

		</ModalContainerBase>
	);
};

export default SurveyInfoModal;
