import PageLayout from '../../../components/app/layout/PageLayout';
import { useEffect, useState } from 'react';
import { getQueryParams } from '../../../utils/common';
import { useAuthContext } from '../../../hooks/useAuthContext';
import AnalyticsLogTable from '../../../components/staff/data/analytics/AnalyticsLogTable';

const AnalyticsPage = () => {
	const queryParams = getQueryParams();

	const { isEditor } = useAuthContext();

	const [optionsFilterTable, setOptionsFilterTable] = useState({});

	useEffect(() => {
		setOptionsFilterTable((prev) => ({ ...prev, ...queryParams }));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<PageLayout
			withBackground={false}
			title='Analytics: logs'
		>
			<AnalyticsLogTable
				filterOptions={optionsFilterTable}
			/>
		</PageLayout>
	);
};

export default AnalyticsPage;
