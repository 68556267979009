import { request } from './api';

const getAllEmails = () => {
	return request.get('/mailjet/get-all-emails');
};

const registerContact = (data) => {
	return request.post('/mailjet/register', data);
};

const MailjetService = {
	getAllEmails,
	registerContact,
};

export default MailjetService;
