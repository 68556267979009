import { Empty, Space, Table } from 'antd';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import ActionButton from './ActionButton';
import { BiChevronDown, BiChevronUp } from 'react-icons/bi';
import { RiDeleteBinFill, RiDeleteBinLine } from 'react-icons/ri';
import TableColumns from './TableColumns';
import { useTheme } from '@emotion/react';

const DraggableTable = (
	{
		onDragEnd,
		droppableId = 'team-list',
		pagination = false,
		dataSource,
		columns,
		onMoveRowUp,
		onMoveRowDown,
		onDeleteRow,
		...props
	},
) => {
	const theme = useTheme();

	const _onDragEnd = (result) => {
		const { destination } = result;
		const { source } = result;

		if (!destination) {
			return;
		}

		if (onDragEnd) onDragEnd(source.index, destination.index);
	};

	const orderColumn = {
		title: <div className="flexColumn">#</div>,
		dataIndex: 'id',
		key: 'id',
		render: (_, row, index, len) => <>
			<div className="flexColumn">
				<ActionButton
					tooltipTitle="Move row up"
					placement="top"
					onClick={() => onMoveRowUp(index)}
					icon={<BiChevronUp />}
					type="text"
					styles={{ height: 20, padding: 0, width: 20 }}
					disabled={index === 0}
				/>
				<span style={{ fontSize: 10, color: theme.color.grey }}>#{index + 1}</span>
				<ActionButton
					tooltipTitle="Move row down"
					onClick={() => onMoveRowDown(index)}
					icon={<BiChevronDown />}
					type="text"
					styles={{ height: 20, padding: 0, width: 20 }}
					disabled={index === dataSource.length - 1}
				/>
			</div>
		</>,
		...TableColumns.columnFixedWidth(60),
	};

	const deleteColumn = {
		title: <div className="flexColumn"><RiDeleteBinLine /></div>,
		key: 'deleteColumn',
		render: (_, row, index) => {
			return (
				<div className="flexColumn">
					<ActionButton
						tooltipTitle="Delete row"
						icon={<RiDeleteBinFill />}
						styles={{ color: theme.color.orange }}
						title="Are you sure you want to delete this row?"
						okText="Yes"
						cancelText="No"
						onConfirm={() => onDeleteRow(row, index)}
						withConfirm
					/>
				</div>
			);
		},
		...TableColumns.columnFixedWidth(70),
	};

	return (
		<DragDropContext
			onDragEnd={_onDragEnd}
		>
			<Table
				dataSource={dataSource}
				columns={[
					orderColumn,
					...columns,
					deleteColumn,
				]}
				rowKey="id"
				pagination={pagination}
				components={{
					body: {
						wrapper: (val) => DroppableTableBody({
							droppableId,
							...val,
						}),
						row: (val) => DraggableTableRow({
							data: dataSource,
							columns,
							...val,
						}),
					},
				}}
				onRow={(record, index) => ({
					index,
					record,
				})}
				size="middle"
				bordered
				{...props}
			/>
		</DragDropContext>
	);
};

const DroppableTableBody = ({ droppableId, ...props }) => {
	return (
		<Droppable
			droppableId={droppableId}
		>
			{(provided, snapshot) => (
				<>
					{snapshot.placeholder}
					<tbody
						ref={provided.innerRef}
						{...props}
						{...provided.droppableProps}
						className={`${props.className} ${
							snapshot.isDraggingOver
								? 'is-dragging-over'
								: ''
						}`}
					 />
				</>
			)}
		</Droppable>
	);
};

const DraggableTableRow = ({
	index, record, columnId, data, columns, ...props
}) => {
	if (!data.length) {
		return (
			<tr className="ant-table-placeholder row-item" {...props}>
				<td colSpan={columns.length} className="ant-table-cell">
					<div className="ant-empty ant-empty-normal">
						<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
					</div>
				</td>
			</tr>
		);
	}
	return (
		<Draggable
			key={props?.['data-row-key']?.toString?.()}
			draggableId={props?.['data-row-key']?.toString?.()}
			index={index}
		>
			{(provided, snapshot) => {
				return (
					<tr
						ref={provided.innerRef}
						{...props}
						{...provided.draggableProps}
						{...provided.dragHandleProps}
						className={snapshot.isDragging ? 'row-dragging' : ''}
					/>
				);
			}}
		</Draggable>
	);
};

export default DraggableTable;
