import { useTheme } from '@emotion/react';
import { Space, Tag } from 'antd';
import { NOT_PROVIDED_YET_LABEL, getHardSkillLabel, userTagStyle } from '../../../constants/constant';

const UserSoftSkills = (
	{
		user,
		mode = 'tags',
		size = 'medium',
		withGroups = false,
		forceValue = null,
		full = false,
		notProvided = NOT_PROVIDED_YET_LABEL,
		...props
	},
) => {
	const theme = useTheme();

	const value = forceValue || user?.career?.softSkill;
	if (!(value?.length > 0)) return notProvided;

	// if (mode === 'tags') {
	const tagStyle = {
		...userTagStyle(size),
		...props?.style,
	};
	const tags = value.map((tag, index) => <Tag color='blue' key={index} style={tagStyle}>{getHardSkillLabel(tag)}</Tag>);
	return tags?.length
		? <Space direction='horizontal' wrap={true} size={2}>{tags}</Space>
		: notProvided;
};

export default UserSoftSkills;
