import { arrayWrap, booleanAndReducer, booleanOrReducer } from '../../../utils/common';
import CONSTANTS from './constants';
import {
	GS_ACTION_COMPLETION_STATUS_DONE,
	GS_ACTION_COMPLETION_STATUS_INPROGRESS,
	GS_ACTION_COMPLETION_STATUS_TODO,
} from '../../company/actionPlan/action-plan';
import { LocalDebug } from '../../../utils/LocalDebug';

const className = 'genderscore/action-plan.utils';

const UNANSWERED_VALUE_LIST = [
	null,
	undefined,
	CONSTANTS.ANSWER_VALUE_SKIPPED,
	CONSTANTS.ANSWER_VALUE_LATER,
];

export const isActionQuestionMatchingAnswer = ({
	questionId,
	operator,
	clauses,
	answers,
	questionScores,
}) => {
	const answer = answers?.[questionId];
	if (operator === '!=') {
		return !arrayWrap(clauses)
			.map((clause) => arrayWrap(answer || []).includes(clause))
			.reduce(booleanAndReducer, true);
		// if (arrayWrap(answer || []).some((item) => arrayWrap(clauses).includes(item))) {
		// 	return false;
		// }
	}
	if (operator === '=') {
		if (UNANSWERED_VALUE_LIST.includes(answer)) return true;
		return arrayWrap(clauses)
			.map((clause) => arrayWrap(answer || []).includes(clause))
			.reduce(booleanOrReducer, false);
		// if (arrayWrap(answer || []).some((item) => arrayWrap(clauses).includes(item))) {
		// 	return false;
		// }
	}
	if (operator === '<') {
		if (UNANSWERED_VALUE_LIST.includes(answer)) return true;
		return (
			arrayWrap(clauses)
				.map((clause) => questionScores[questionId] < Number(clause))
				.reduce(booleanOrReducer, false));
	}
	if (operator === '>') {
		if (UNANSWERED_VALUE_LIST.includes(answer)) return true;
		return (
			arrayWrap(clauses)
				.map((clause) => questionScores[questionId] > Number(clause))
				.reduce(booleanOrReducer, false));
	}
	return true;
};

export const getActionQuestionCompletionStatusFromAnswers = ({
	questionId,
	operator,
	clauses,
	answers,
	questionScores,
}) => {
	const answer = arrayWrap(answers?.[questionId] || [])
		.filter((item) => !UNANSWERED_VALUE_LIST.includes(item));
	LocalDebug.logInfo({ className, method: 'getQuestionCompletionStatus' }, {
		questionId,
		operator,
		clauses,
		answers: Object.keys(answers || {})?.length,
		answer,
	});
	if (answer?.length === 0) {
		return GS_ACTION_COMPLETION_STATUS_TODO;
	}
	if (answer?.length === 1 && answer?.[0] === 'in-progress') {
		return GS_ACTION_COMPLETION_STATUS_INPROGRESS;
	}
	if (operator === '!=') {
		return (
			!arrayWrap(clauses)
				.map((clause) => answer.includes(clause))
				.reduce(booleanAndReducer, true))
			? GS_ACTION_COMPLETION_STATUS_TODO
			: GS_ACTION_COMPLETION_STATUS_DONE;
	}
	if (operator === '=') {
		return (
			arrayWrap(clauses)
				.map((clause) => answer.includes(clause))
				.reduce(booleanOrReducer, false))
			? GS_ACTION_COMPLETION_STATUS_TODO
			: GS_ACTION_COMPLETION_STATUS_DONE;
	}
	if (operator === '<') {
		return (
			arrayWrap(clauses)
				.map((clause) => questionScores[questionId] < Number(clause))
				.reduce(booleanOrReducer, false))
			? GS_ACTION_COMPLETION_STATUS_TODO
			: GS_ACTION_COMPLETION_STATUS_DONE;
	}
	if (operator === '>') {
		return (
			arrayWrap(clauses)
				.map((clause) => questionScores[questionId] > Number(clause))
				.reduce(booleanOrReducer, false))
			? GS_ACTION_COMPLETION_STATUS_TODO
			: GS_ACTION_COMPLETION_STATUS_DONE;
	}
	return GS_ACTION_COMPLETION_STATUS_TODO;
};

export const getActionCompletionStatusFromAnswers = ({
	action,
	answers,
	questionScores,
}) => {
	const completionStatusList = action.questions
		.map((question) => getActionQuestionCompletionStatusFromAnswers({
			...question,
			answers,
			questionScores,
		}));
	if (completionStatusList.includes(GS_ACTION_COMPLETION_STATUS_TODO)) {
		return GS_ACTION_COMPLETION_STATUS_TODO;
	}
	if (completionStatusList.includes(GS_ACTION_COMPLETION_STATUS_INPROGRESS)) {
		return GS_ACTION_COMPLETION_STATUS_INPROGRESS;
	}
	if (completionStatusList.includes(GS_ACTION_COMPLETION_STATUS_DONE)) {
		return GS_ACTION_COMPLETION_STATUS_DONE;
	}
	return GS_ACTION_COMPLETION_STATUS_TODO;
};
