import { useTheme } from '@emotion/react';
import {
	Button, Checkbox, DatePicker, Form,
} from 'antd';
import TextArea from 'antd/es/input/TextArea';
import { useState } from 'react';

import FormItem from '../app/form/FormItem';
import FormSelect from '../app/form/FormSelect';
import {
	actionOptions,
	sourcingStatus,
	sourcingSteps,
	SOURCING_ACTION_STATUS,
	SOURCING_ACTION_STEP,
	SOURCING_ACTION_COMMENT,
	DATE_FORMAT,
	NEW_STATUS,
	STOP_STATUS,
	sourcingArchiveReasons,
} from '../../constants/constant';
import UserSelect from '../user/UserSelect';
import { treatSourcingActionToDb } from '../../actions/sourcings';
import { useAuthContext } from '../../hooks/useAuthContext';
import dayjs from 'dayjs';
import { documentId, requiredRule } from '../../utils/common';

const SourcingActionForm = ({ initialValues = {}, onSubmit, onReset }) => {
	const theme = useTheme();
	const [form] = Form.useForm();
	const { currentUser, isStaff, isStaffView } = useAuthContext();

	const [isReadOnly] = useState(initialValues?.isReadOnly || false);

	const [isStop, setIsStop] = useState(false);

	initialValues = {
		'creator': documentId(currentUser),
		...initialValues,
		'postedAt': initialValues?.postedAt ? dayjs(initialValues.postedAt) : dayjs(),
		'payload.text': initialValues?.payload?.text || '',
	};
	// ------------------------------------- //
	// ---------------- state -------------- //
	// ------------------------------------- //
	const [actionSelected, setActionSelected] = useState(initialValues?.action || SOURCING_ACTION_STEP);
	const [isStaffPrivate, setIsStaffPrivate] = useState(initialValues?.isStaffPrivate || false);
	const sourcingId = documentId(initialValues);

	// ---------------------------------- //
	// -------- handle submit ----------- //
	// ---------------------------------- //
	const handleSubmit = (values) => {
		const id = documentId(initialValues);
		const history = {
			...values,
			managerId: documentId(currentUser),
			creator: initialValues?.creator,
			action: actionSelected,
			...(values.action === SOURCING_ACTION_COMMENT && { isStaffPrivate, 'payload.isStaffPrivate': true }),
			id,
			status: initialValues?.status || NEW_STATUS,
		};

		const toSubmit = treatSourcingActionToDb(history, currentUser, isStaffPrivate);

		onSubmit({
			...toSubmit,
			add: initialValues?.add || (() => {}),
		}, values.action);
	};

	// -------------------------------------- //
	// --------- handle change Action ------- //
	// -------------------------------------- //
	const handleChangeAction = (value, option) => {
		setActionSelected(value);
	};

	const handleChangeStatus = (value) => {
		setIsStop(value === STOP_STATUS);
	};

	// --------------------------------------- //
	// ----------- handle private ------------ //
	// --------------------------------------- //
	const handleChangePrivate = (event) => {
		setIsStaffPrivate(event.target.checked);
	};

	return (
		<Form
			form={form}
			name="action_form"
			initialValues={{ ...initialValues }}
			onFinish={handleSubmit}
		>
			{sourcingId && <FormItem
				label="Action"
				defaultContainer="div"
				name="action"
				rules={[requiredRule]}
			>
				<FormSelect
					options={actionOptions.filter((a) => (initialValues?.status === 'stop' ? a.value !== SOURCING_ACTION_STEP : a))}
					placeholder="Select an action"
					defaultValue={initialValues?.action}
					onChange={handleChangeAction}
				/>
			</FormItem>
			}
			<FormItem
				name="author"
				label="Author"
				style={{ width: '300px', marginBottom: '15px' }}
				defaultContainer="div"
				rules={[requiredRule]}
			>
				<UserSelect
					defaultOptions={initialValues?.defaultUserOptions ? [initialValues?.defaultUserOptions] : []}
					placeholder="Author"
					style={{ width: '100%' }}
				/>
			</FormItem>

			{actionSelected === SOURCING_ACTION_STATUS && <FormItem
				label="Status"
				defaultContainer="div"
				name="payload.value"
				rules={[requiredRule]}
			>
				<FormSelect
					options={sourcingStatus}
					placeholder="Status"
					defaultValue={initialValues?.payload?.value}
					onChange={handleChangeStatus}
				/>
			</FormItem>
			}

			{actionSelected === SOURCING_ACTION_STEP && <FormItem
				label="Step"
				defaultContainer="div"
				name="payload.value"
				rules={[requiredRule]}
			>
				<FormSelect
					options={sourcingSteps}
					placeholder="Step"
					defaultValue={initialValues?.payload?.value}
					onChange={handleChangeStatus}
				/>
			</FormItem>
			}

			{
				isStop && <FormItem
					label="Archive reasons"
					defaultContainer="div"
					name="payload.archiveReasons"
					rules={[requiredRule]}
				>
					<FormSelect
						options={sourcingArchiveReasons.map((r) => ({ label: r, value: r }))}
						placeholder="Archive reason"
						defaultValue={initialValues?.payload?.archiveReasons}
						mode="tags"
					/>
				</FormItem>
			}

			<FormItem
				label="Comment"
				name="payload.text"
				defaultContainer="div"
				// value={initialValues?.payload?.text}
				rules={[
					{
						validator: async (_, value) => {
							if (actionSelected === SOURCING_ACTION_COMMENT && !value) throw new Error('This field is required');
							return true;
						},
					},
				]}
				// styles={{rootContainer: {display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: 20 }, label: {marginBottom: 0, paddingRight: 10,  width: 130}, itemContainer: {marginBottom: 0}, container: {flex: 1}, input: {height: 46}}}
			>
				<TextArea rows={6} />
				{/* <TinymceEditor initialValue={initialValues?.payload?.text} /> */}
			</FormItem>
			{isStaffView
				&& <FormItem
					label="Private"
					defaultContainer="div"
					name="isStaffPrivate"
				>
					<Checkbox value={true} checked={isStaffPrivate} onChange={handleChangePrivate}/>
				</FormItem>
			}

			<FormItem
				label="Posting date"
				defaultContainer="div"
				name="postedAt"
				rules={[{
					type: 'object',
					required: false,
					message: 'Please input date',
					whitespace: true,
				}]}
			>
				<DatePicker format={DATE_FORMAT}/>
			</FormItem>
			<div style={{
				display: 'flex',
				flexDirection: 'row-reverse',
				margin: -5,
			}}>
				{!isReadOnly && <Button type="primary"
					style={{
						margin: 5,
						background: theme.color.blue,
						borderColor: theme.color.blue,
						fontWeight: 'bold',
						borderRadius: 5,
					}}
					htmlType="submit"
				>
					Save
				</Button>
				}
			</div>
		</Form>
	);
};

export default SourcingActionForm;
