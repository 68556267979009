import { GENDERSCORE_ACTIONS } from '../../../constants/genderscore-actions';
import {
	GENDERSCORE_LEVEL_VALUES,
	getLevelIndex,
} from '../../../constants/genderscore-levels';
import {
	GENDERSCORE_PILLAR_VALUES,
	getPillarIndex,
} from '../../../constants/genderscore-pillars';
import { LocalDebug } from '../../../utils/LocalDebug';
import { booleanAndReducer } from '../../../utils/common';

const className = 'genderscore.utils';

export const ACTION_GRID_COLLAPSE = 3;

export const isActionAllowed = ({ action, company }) => {
	let isAllowed = true;
	const { clauses } = action || {};
	if (clauses?.length > 0) {
		// LocalDebug.logInfo({ className, method: 'getPillarActions' }, {
		// 	genderScoreCurrentSurvey: company?.genderScoreCurrentSurvey,
		// 	clauses,
		// });
		isAllowed = clauses
			.map((clause) => {
				try {
					const result = clause(company?.genderScoreCurrentSurvey?.answers || {});
					// LocalDebug.logInfo({ className, method: 'getPillarActions' }, { result });
					return result;
				} catch (e) {
					return false;
				}
			})
			.reduce(booleanAndReducer, true);

		// LocalDebug.logInfo({ className, method: 'getPillarActions' }, { isAllowed });
	}
	return isAllowed;
};

export const getPillarActions = ({
	company,
	forceValue,
	pillar,
}) => {
	// LocalDebug.logInfo({ className, method: 'getPillarActions' });
	const genderScore = forceValue || company?.genderScore;

	const mainLevelIndex = getLevelIndex(genderScore?.level);

	let pillarActionsByLevels = {};
	let pillarActions = [];

	const nextLevels = GENDERSCORE_LEVEL_VALUES
		.filter((item, index) => index >= mainLevelIndex);

	if (GENDERSCORE_PILLAR_VALUES.includes(pillar)) {
		pillarActionsByLevels = GENDERSCORE_ACTIONS?.[pillar];
		Object
			.entries(GENDERSCORE_ACTIONS?.[pillar])
			.forEach(([level, actions]) => {
				const levelIndex = getLevelIndex(level);
				// LocalDebug.logInfo({ className, method: 'getPillarActions' }, { pillar, levelIndex, actions });
				if (nextLevels.includes(level)) {
					pillarActions.push(
						...actions
							.filter((action) => isActionAllowed({ action, company }))
							.map((action) => ({
								...action,
								level,
								levelIndex,
								pillar,
								isSelected: !!company?.genderScoreActions?.actions
									?.find((item) => (
										item.actionId === action.value
										&& item.isActive !== false
									)),
							})),
					);
				}
			});
	} else {
		Object
			.entries(GENDERSCORE_ACTIONS)
			.forEach(([pillarValue, pillarLevels]) => {
				const pillarIndex = getPillarIndex(pillarValue);
				Object
					.entries(pillarLevels)
					.forEach(([level, actions]) => {
						const levelIndex = getLevelIndex(level);
						if (nextLevels.includes(level)) {
							const allowedActions = actions
								.filter((action) => isActionAllowed({ action, company }));

							pillarActions.push(
								...allowedActions
									.map((action, actionIndex) => ({
										...action,
										level,
										levelIndex,
										pillar: pillarValue,
										pillarIndex,
										actionIndex,
										isSelected: !!company?.genderScoreActions?.actions
											?.find((item) => (
												item.actionId === action.value
											&& item.isActive !== false
											)),

									})),
							);

							pillarActionsByLevels[level] = [
								...pillarActionsByLevels[level] || [],
								...allowedActions
									.map((action, actionIndex) => ({
										...action,
										level,
										levelIndex,
										pillar: pillarValue,
										pillarIndex,
										actionIndex,
										isSelected: !!company?.genderScoreActions?.actions
											?.find((item) => (
												item.actionId === action.value
											&& item.isActive !== false
											)),
									})),
							];
						}
					});
			});

		pillarActions = GENDERSCORE_LEVEL_VALUES
			.map((level) => pillarActionsByLevels[level])
			.flat()
			.filter((item) => item);
	}

	return {
		pillarActions,
		pillarActionsByLevels,
	};
};
