import { Group } from '@visx/group';
import { Bar, Polygon } from '@visx/shape';
import { useTheme } from '@emotion/react';
import { LinearGradient } from '@visx/gradient';
import { normalizePct } from '../../../../../utils/common';
import { Space } from 'antd';

/**
 * @param {number} value - Number (0-100) that represents the percentage to render
 * @param {*} [graphColors] - Optional. Format: [from, to, bg]. If not set, use theme.chart.rate
 * @param {number} [width] - Optional. Width of the chart. Default: 160
 * @param {number} [lineWidth] - Optional. Width of the vertical lines. Default: 3
 * @param {number} [fontSize] - Optional. Font size of the label. Default: 40
 * @param {number} [margin] - Optional. Internal margins of the chart.
 * Format: {top, bottom, left, right}.
 * Default: {
		top: 10, bottom: 0, left: 0, right: 40,
	}
 * @returns RatioGraph component
 */
const RateGraph = ({
	value,
	graphColors,
	width = 120,
	margin = {
		top: 10, right: 0, bottom: 0, left: 40,
	},
	lineWidth = 3,
	fontSize = 40,
	...props
}) => {
	const theme = useTheme();
	const colors = graphColors || theme.chart.rate;
	const gradientId = 'rate_fill';

	// Ensure that the main percentage is an integer between 0 an 100
	const normalizedValue = normalizePct({ value });

	const height = width * 0.38;
	// Background triangle
	const bgWidth = width - margin.left - margin.right;
	const bgHeight = height - margin.top - margin.bottom;
	const bgTriangle = {
		topRight: [bgWidth, 0],
		bottomRight: [bgWidth, bgHeight],
		bottomLeft: [0, bgHeight],
	};
	const bgHypotenuse = Math.sqrt(bgWidth ** 2 + bgHeight ** 2);
	const bgQuarter = bgWidth / 4;

	// Rate triangle
	const rateWidth = normalizedValue * bgWidth / 100;
	const rateHypotenuse = normalizedValue * bgHypotenuse / 100;
	const rateHeight = Math.sqrt(rateHypotenuse ** 2 - rateWidth ** 2);
	const rateTriangle = {
		topRight: [bgTriangle.bottomLeft[0] + rateWidth, bgTriangle.bottomRight[1] - rateHeight],
		bottomRight: [bgTriangle.bottomLeft[0] + rateWidth, bgTriangle.bottomRight[1]],
		bottomLeft: bgTriangle.bottomLeft,
	};

	// Quarter dividers

	const dividers = [1, 2, 3].map((i) => {
		return {
			x: bgTriangle.bottomLeft[0] + i * bgQuarter,
			y: bgTriangle.topRight[1],
			width: lineWidth,
			height: bgHeight,

		};
	});

	const labelFontSize = {
		value: fontSize,
		percentage: Math.floor(fontSize * 0.66),
	};
	const labelOffset = {
		value: 0,
		percentage: normalizedValue === 100 ? '2.8em' : `${normalizedValue.toString().length}em`,
	};
	return <Space style={{ ...props?.style }}>
		<svg width={width} height={height}>
			<defs>
				<LinearGradient vertical={false} from={colors.from} to={colors.to} id={gradientId}/>
			</defs>
			<Group
				left={margin.left} top={margin.top}
			>
				<Polygon
					fill={colors.bg}
					rotate={180}
					points={[
						bgTriangle.topRight,
						bgTriangle.bottomRight,
						bgTriangle.bottomLeft,
					]}
				/>

				<Polygon
					fill={`url(#${gradientId})`}
					rotate={180}
					points={[
						rateTriangle.topRight,
						rateTriangle.bottomRight,
						rateTriangle.bottomLeft,
					]}
				/>
				{dividers.map((d, i) => <Bar
					key={`divider${i}`}
					x={d.x}
					y={d.y}
					width={d.width}
					height={d.height}
					fill={'white'}
				/>)}
				<text
					x={-margin.left}
					y={height / 2}
					fill={'black'}
					fontSize={labelFontSize.value}
					fontWeight={700}
					dx={labelOffset.value}
				>
					{`${normalizedValue}`}
				</text>
				<text
					x={-margin.left}
					y={height / 2}
					fill={'black'}
					fontSize={labelFontSize.percentage}
					fontWeight={700}
					dx={labelOffset.percentage}
				>
					{'%'}
				</text>
			</Group>
		</svg>
	</Space>;
};

export default RateGraph;
