import MailjetService from '../services/mailjet';
import { localDebug } from '../utils/LocalDebug';
import { isFunction } from '../utils/common';

export const getMailjetEmailList = async () => {
	try {
		const res = await MailjetService.getAllEmails();
		const { data: mailjetContactsList } = res;
		return Object.values(mailjetContactsList);
	} catch (error) {
		localDebug(__filename.split('/').shift(), error);
		return null;
	}
};

export const registerMailjetContact = async (data, callback) => {
	try {
		await MailjetService.registerContact(data);
		if (isFunction(callback)) callback();
	} catch (error) {
		localDebug(__filename.split('/').shift(), error);
	}
};
