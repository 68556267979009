import { Typography } from 'antd';
import { getTranslationOptions } from '../../../../utils/common';
import { useSelector } from 'react-redux';
import { getCompanySelected } from '../../../../reducers/app';
import { useContext, useEffect, useState } from 'react';
import { CompanyPageContext } from './CompanyPageProvider';

const CompanyPageNoticeTranslateLegacy = () => {
	const company = useSelector(getCompanySelected);

	const { lang } = useContext(CompanyPageContext);
	const [text, setText] = useState(null);

	useEffect(() => {
		setText(getTranslationOptions(lang)?.notice);
	}, [lang]);

	return (
		<>
			<Typography
				style={{
					marginBottom: 10,
				}}>
				{text}
			</Typography>
		</>
	);
};
export default CompanyPageNoticeTranslateLegacy;
