import ModalContainerBase from '../../contexts/modals/ModalContainerBase';
import FormBoxTitle from '../app/form/FormBoxTitle';
import SourcingActionForm from './SourcingActionForm';

const SourcingActionPopover = ({
	open,
	initialValues = {},
	title = 'Change status',
	onSubmit,
	onReset,
}) => {
	return (
		<ModalContainerBase
			open={open}
			onCancel={onReset}
		>
			<FormBoxTitle
				title={title}
			/>
			<SourcingActionForm
				initialValues={initialValues}
				onSubmit={onSubmit}
				onReset={onReset}
			/>
		</ModalContainerBase>
	);
};

export default SourcingActionPopover;
