import ArticleService from '../services/article';
import UserService from '../services/user';
import { action } from './utils';
import { message } from 'antd';

export const updateOrCreateArticle = (data) => {
	const key = JSON.stringify(data);
	message.loading({ content: 'Updating article', key, duration: 10 });
	return action(async () => {
		try {
			await ArticleService.updateOrCreateArticle(data);
			message.success({ content: 'Article updated', key, duration: 1 });
		} catch (error) {
			message.error({ content: error.message, key });
		}
	});
};
export const uploadFileArticle = (data) => {
	const key = JSON.stringify(data);
	message.loading({ content: 'Updating article', key, duration: 10 });
	return action(async () => {
		try {
			const result = await ArticleService.uploadFile(data);
			message.success({ content: 'File uploaded', key, duration: 1 });
			return result.data;
		} catch (error) {
			message.error({ content: error.message, key });
		}
	});
};

export const deleteArticle = (articleId) => {
	return action(async () => {
		try {
			await ArticleService.deleteArticle(articleId);
			message.success({ content: 'Article deleted', articleId, duration: 1 });
		} catch (error) {
			message.error({ content: error.message, articleId });
		}
	});
};
export const searchUser = (params) => {
	return action(async () => {
		try {
			const result = await UserService.findAll(params, '/search');
			return result.data;
		} catch (error) {
			message.error({ content: error.message });
		}
	});
};
