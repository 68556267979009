import { isStringTypeValidator } from '../../../utils/isStringTypeValidator';
import { innerObjPropExtractor } from '../../property/property-extractor';
import { innerObjPropMapper } from '../../property/property-mapper';

const USER_PROPERTY_JOB_SEARCH_JOB_LOCATION_KEY = 'job-search-job-location';

const USER_PROPERTY_JOB_SEARCH_JOB_LOCATION_MULTI = true;
const USER_PROPERTY_JOB_SEARCH_JOB_LOCATION_FREE = true;

const USER_PROPERTY_JOB_SEARCH_JOB_LOCATION_VALIDATORS = [
	isStringTypeValidator,
];

const USER_PROPERTY_JOB_SEARCH_JOB_LOCATION_FIELD = 'jobSearch.jobLocation';
const USER_PROPERTY_JOB_SEARCH_JOB_LOCATION_MAPPER = innerObjPropMapper(USER_PROPERTY_JOB_SEARCH_JOB_LOCATION_FIELD);
const USER_PROPERTY_JOB_SEARCH_JOB_LOCATION_EXTRACTOR = innerObjPropExtractor(USER_PROPERTY_JOB_SEARCH_JOB_LOCATION_FIELD);

export default {
	key: USER_PROPERTY_JOB_SEARCH_JOB_LOCATION_KEY,
	USER_PROPERTY_JOB_SEARCH_JOB_LOCATION_KEY,
	validators: USER_PROPERTY_JOB_SEARCH_JOB_LOCATION_VALIDATORS,
	USER_PROPERTY_JOB_SEARCH_JOB_LOCATION_VALIDATORS,
	free: USER_PROPERTY_JOB_SEARCH_JOB_LOCATION_FREE,
	USER_PROPERTY_JOB_SEARCH_JOB_LOCATION_FREE,
	multi: USER_PROPERTY_JOB_SEARCH_JOB_LOCATION_MULTI,
	USER_PROPERTY_JOB_SEARCH_JOB_LOCATION_MULTI,
	mapper: USER_PROPERTY_JOB_SEARCH_JOB_LOCATION_MAPPER,
	USER_PROPERTY_JOB_SEARCH_JOB_LOCATION_MAPPER,
	extractor: USER_PROPERTY_JOB_SEARCH_JOB_LOCATION_EXTRACTOR,
	USER_PROPERTY_JOB_SEARCH_JOB_LOCATION_EXTRACTOR,
	field: USER_PROPERTY_JOB_SEARCH_JOB_LOCATION_FIELD,
	USER_PROPERTY_JOB_SEARCH_JOB_LOCATION_FIELD,
};
