import { Button, Popconfirm, Tooltip } from 'antd';
import { FaRegTrashAlt } from 'react-icons/fa';
import { useAuthContext } from '../../../hooks/useAuthContext';
import { localDebug } from '../../../utils/LocalDebug';
import { removeSourcing } from '../../../actions/sourcings';
import { useDispatch } from 'react-redux';
import { shouldRefreshData } from '../../../actions/app';
import { documentId } from '../../../utils/common';

const SourcingDeleter = (
	{
		sourcing,
		Component,
		onConfirmComplete,
		withTooltip = false,
		tooltipTitle,
		children,
		...props
	},
) => {
	const dispatch = useDispatch();
	const { isStaffView } = useAuthContext();

	const archivers = [];
	sourcing?.user?.sourced?.forEach((item) => {
		const { manager } = item;
		if (item.isArchived) {
			archivers.push(...manager && !archivers.some((s) => documentId(s)?.toString?.() === documentId(manager)?.toString?.()) ? [manager] : []);
		}
	});

	const handleDeleteSourcing = async () => {
		localDebug('handleDeleteSourcing', { sourcing, onConfirmComplete });
		await dispatch(removeSourcing(sourcing));
		dispatch(shouldRefreshData());
		if (onConfirmComplete) onConfirmComplete();
	};

	let button = <Popconfirm
		title="Are you sure you want to delete this row?"
		okText="Yes" cancelText="No"
		onConfirm={handleDeleteSourcing}
	>
		<Button danger type="text" icon={<FaRegTrashAlt style={{ marginBottom: -2 }}/>} size={10}
		>
			{props?.title || props?.title === '' ? props?.title : <>&nbsp;Delete</>}
		</Button>
	</Popconfirm>;

	if (withTooltip) {
		button = <Tooltip title={tooltipTitle || <>Delete {sourcing?.user?.firstName}</>}>{button}</Tooltip>;
	}

	const actionCount = sourcing?.user?.archivedCount;
	return <div style={{ textAlign: 'left' }}>
		{button}
	</div>;
};

export default SourcingDeleter;
