import { useTheme } from '@emotion/react';
import UserModel from '../../models/UserModel';
import UserService from '../../services/user';
import { LocalDebug } from '../../utils/LocalDebug';
import { useAuthContext } from '../../hooks/useAuthContext';
import { useContext, useEffect, useState } from 'react';
import {
	Button, Divider, Form, Popconfirm, Space, message,
} from 'antd';
import USER_PROPERTIES from '../../constants/user/property';
import { FaPen, FaTrash } from 'react-icons/fa';
import Table from '../app/table/Table';
import UserStub from './stub/UserStub';
import FormItem from '../company/form/FormItem';
import { UserStoreContext } from '../../contexts/UserStoreProvider';
import { documentId } from '../../utils/common';
import ModalContainerBase from '../../contexts/modals/ModalContainerBase';
import FormBoxTitle from '../app/form/FormBoxTitle';

const UserProfilePropertyTable = (
	{
		user,
	},
) => {
	const className = 'UserProfilePropertyTable';

	const theme = useTheme();

	const { isAdmin, isStaff } = useAuthContext();
	const { fetchUser: dataFetchUser } = useContext(UserStoreContext);

	const [isLoading, setIsLoading] = useState(false);
	const [userModel, setUserModel] = useState();
	const [userData, setUserData] = useState([]);
	const [isEditModalOpen, setIsEditModalOpen] = useState(false);
	const [selectedProperty, setSelectedProperty] = useState();
	const [isRefreshData, setIsRefreshData] = useState(0);

	const fetchUser = async (userId) => {
		LocalDebug.logNull({ className, method: 'fetchUser' }, { userId });
		setUserModel(await dataFetchUser(userId));
		LocalDebug.logNull({ className, method: 'fetchUser' }, 'user fetched');
		setIsRefreshData((p) => p + 1);
		setIsLoading(false);
	};

	const propertyKeyCol = {
		title: 'Property key',
		dataIndex: 'key',
	};
	const userFieldCol = {
		title: 'User field',
		dataIndex: 'field',
		render: (value) => ([null, undefined].includes(value)
			? <span style={{ color: '#faa' }}><i>No field set</i></span>
			: value),
	};
	const userValueCol = {
		title: 'Value',
		dataIndex: 'value',
		render: (value, row) => (!row?.extractor
			? <span style={{ color: '#faa' }}><i>No extractor set</i></span>
			: [null, undefined].includes(row.value)
				? <span style={{ color: '#bbb' }}><i>Value not set</i></span>
				: JSON.stringify(row.value)),
	};

	const actionsCol = {
		title: 'Actions',
		dataIndex: 'actions',
		render: (value, row) => (
			<Space>
				<Button
					type="primary"
					style={{ borderRadius: 6 }}
					onClick={() => {
						setIsEditModalOpen(true);
						setSelectedProperty(row);
					}}
				>
					<FaPen style={{ marginBottom: -2 }}/>
				</Button>
				<Popconfirm
					title={<>Reset value for property <b>{row.key}</b>?</>}
					onConfirm={async () => {
						await UserService.updateProperty(
							documentId(user),
							{
								key: row.key,
								value: null,
							},
						);

						onSuccess?.();
					}}
				>
					<Button
						type="danger"
						style={{ borderRadius: 6 }}
					>
						<FaTrash style={{ marginBottom: -2 }}/>
					</Button>
				</Popconfirm>
			</Space>
		),
	};
	const columns = [
		propertyKeyCol,
		userFieldCol,
		userValueCol,
		actionsCol,
	];

	useEffect(() => {
		setUserModel(new UserModel(user));
	}, [user]);

	useEffect(() => {
		setUserData(
			USER_PROPERTIES
				.keys
				.map((key) => {
					const property = USER_PROPERTIES?.[key];
					if (!property) return null;
					const field = property?.field;
					const value = property?.extractor?.(userModel);
					return ({
						...property, key, field, value, extractor: property.extractor,
					});
				}),
		);
	}, [userModel]);

	const onSuccess = () => {
		setIsEditModalOpen(false);
		fetchUser();
	};

	return (
		<>
			<Table
				dataSource={userData}
				columns={columns}
				withPagination={false}
			/>
			<ModalContainerBase
				open={isEditModalOpen}
				setOpen={setIsEditModalOpen}
			>
				<FormBoxTitle
					title="Edit a user's property"
				/>
				<UserStub user={user} />

				<Divider />

				<UserProfilePropertyEditorForm
					user={user}
					property={selectedProperty}
					initialValues={{
						key: selectedProperty?.key,
						value: [null, undefined].includes(selectedProperty?.value)
							? null
							: JSON.stringify(selectedProperty?.value),
					}}
					onSuccess={onSuccess}
				/>
			</ModalContainerBase>
		</>
	);
};

export const UserProfilePropertyEditorForm = (
	{
		user,
		property,
		initialValues = {},
		onSuccess,
	},
) => {
	const [form] = Form.useForm();

	const handleSubmit = async (values) => {
		let value = values?.value;
		if ([null, undefined].includes(value)
			|| value?.trim?.() === '') {
			value = null;
		} else {
			try {
				value = JSON.parse(value);
			} catch (e) {
				message.error('Value is not a properly formatted JSON');
				return;
			}
		}

		const result = await UserService.updateProperty(
			documentId(user),
			{
				key: values?.key,
				value,
			},
		);
		message.success('Property updated');
		onSuccess?.();
	};

	useEffect(() => {
		form.resetFields();
	}, [initialValues]);

	return (
		<>
			<Form
				form={form}
				labelCol={{ span: 6 }}
				initialValues={{ ...initialValues }}
				onFinish={handleSubmit}
			>
				<FormItem
					label="Property"
					name='key'
					disabled={true}
				/>

				<FormItem
					label="Value"
					name='value'
				/>

				<FormItem
					label={<>&nbsp;</>}
				>
					<Button
						type='primary'
						size='large'
						htmlType="submit"
					>
						Submit
					</Button>
				</FormItem>
			</Form>
		</>
	);
};

export default UserProfilePropertyTable;
