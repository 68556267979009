import { NOT_PROVIDED_YET_LABEL } from '../../../../../constants/constant';
import UserModel from '../../../../../models/UserModel';
import UserGridCardLabelValue from './UserGridCardLabelValue';
import UserGridCardValueList from './UserGridCardValueList';

const UserGridCardJobSearchJobLevel = (
	{
		user,
		label = <>Experience in desired role</>,
		placement = 'right',
		defaultContainer = 'span',
		withSeparator = true,
		itemStyle,
		notProvided = NOT_PROVIDED_YET_LABEL,
		...props
	},
) => {
	const className = 'UserGridCardJobSearchJobLevel';

	const userModel = new UserModel(user);

	const items = userModel?.hasJobSearchJobLevels()
		? userModel?.getJobSearchJobLevels({ sorted: true })
		: [notProvided];

	return (
		<UserGridCardLabelValue
			label={label}
			style={{ ...props?.style }}
		>
			<UserGridCardValueList
				items={items}
				placement={placement}
				defaultContainer={defaultContainer}
				withSeparator={withSeparator}
			/>
		</UserGridCardLabelValue>
	);
};

export default UserGridCardJobSearchJobLevel;
