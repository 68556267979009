import { message } from 'antd';
import Link from '../components/app/Link';
import { actionWithLoader, onEnter, push } from './utils';
import {
	loadAtsArchiveReasons, loadAtsPipelines, loadCompanies, loadRecruiters,
} from './company';
import { getAllJobItems, loadJob } from './jobs';
import authFirebaseService from '../services/authFirebase';
import authService from '../services/auth';
import { getAllCategoryTags, loadTagRule } from './tagRules';
import {
	DISPLAY_SCREEN_UPDATED,
	SHOULD_REFRESH_DATA,
	LOGIN_SUCCESS,
	READ_ACTION_HISTORY,
	SET_ACTION_HISTORY,
	SET_COMPANY_EDITOR,
} from './types';
import { getAllTagUsers, loadUser } from './users';
import { documentId, getQueryParams, retrieveUserFromLocalStorage } from '../utils/common';
import CompanyService from '../services/company';
import { getCompanies, getCompanySelected } from '../reducers/app';
import { getCampaignEmailFilters } from './admin';
import { writeRecruiterLog } from './logging';
import { PATHS } from '../constants/constant';
import { COMPANY_RECRUITER_LOG } from '../constants/company-recruiter-log';
import { LocalDebug } from '../utils/LocalDebug';
import config from '../config/config';
import browserHistory from '../browserHistory';
import { pushLogout } from '../contexts/EventPushProvider';

const { history } = browserHistory;

const className = 'app';

const pathUser = 'firebase/user';
const CONNECTION_FAILED = 'Your network seems to have issues, check and reload the page';

function handleErrorMessage(error) {
	switch (error.code) {
		case 100:
			return CONNECTION_FAILED;
		default:
			return error.message;
	}
}

export function showError(errorOrMessage) {
	return (dispatch) => {
		console.error(errorOrMessage);
		dispatch({
			type: 'ERROR',
			message: typeof errorOrMessage === 'string' ? errorOrMessage : handleErrorMessage(errorOrMessage),
		});
	};
}

export function updateScreen(displayScreen) {
	return async (dispatch) => {
		dispatch({
			type: DISPLAY_SCREEN_UPDATED,
			displayScreen,
		});
	};
}

export function shouldRefreshData() {
	return async (dispatch) => {
		dispatch({ type: SHOULD_REFRESH_DATA });
	};
}

export function onEnterCampaignEmails() {
	LocalDebug.logNull({ className, method: 'onEnterCampaignEmails' });
	return async (dispatch) => {
		dispatch(getCampaignEmailFilters());
	};
}

export function goToPreviousHistoryIfExistQuery() {
	const { location } = history;
	const query = retrieveCurrentQueryParams(location.pathname);

	LocalDebug.logNull({ className, method: 'goToPreviousHistoryIfExistQuery' }, { query, 'location.pathname': location.pathname });

	if (query) {
		clearUserIntoLocalStorage(location.pathname);

		// const { isAdmin, isStaff, isTalent } = useAuthContext();
		// localDebug('goToPreviousHistoryIfExistQuery', { location, query })
		history.push({
			...location,
			// with new custom history, we pass query string, query object instead
			search: browserHistory.toSearchParams(query),
			...query,
		});
		LocalDebug.logInfo({ className, method: 'goToPreviousHistoryIfExistQuery' }, { history });
	}
}

export function onEnterTagRuleCreation(state) {
	LocalDebug.logNull({ className, method: 'onEnterTagRuleCreation' });
	return async (dispatch) => {
		const { tagRuleId } = state.params;
		dispatch(getAllCategoryTags());
		dispatch(loadTagRule(tagRuleId));
	};
}

export function onEnterTagRuleEdition(store) {
	LocalDebug.logNull({ className, method: 'onEnterTagRuleEdition' });
	return onEnter({
		store,
		actionThunk: (params) => {
			return async (dispatch) => {
				const { tagRuleId } = params;
				dispatch(getAllCategoryTags());
				dispatch(loadTagRule(tagRuleId));
			};
		},
	});
}

export function onEnterBuilder({
	origin = 'onEnterLoadCompanies',
	shouldLoadCompanies = true,
	shouldLoadRecruiters = false,
	shouldLoadUserTags = false,
	shouldLoadAtsData = false,
	shouldLoadCategoryTags = false,
	shouldGoToPreviousHistory = false,
} = {
	origin: 'onEnterLoadCompanies',
	shouldLoadCompanies: true,
	shouldLoadRecruiters: false,
	shouldLoadUserTags: false,
	shouldLoadAtsData: false,
	shouldLoadCategoryTags: false,
	shouldGoToPreviousHistory: false,
}) {
	return () => {
		LocalDebug.logNull({ className, method: 'onEnterLoadCompanies' }, { origin });
		return async (dispatch, getState) => {
			// check when company exist in store
			const companySelected = getCompanySelected(getState());

			// find all company with access
			if (shouldLoadCompanies) {
				dispatch(loadCompanies({
					defaultCompanyIdSelected: documentId(companySelected),
					origin,
				}));
			}

			if (shouldLoadRecruiters) {
				// should we await?
				await dispatch(loadRecruiters());
			}

			if (shouldLoadUserTags) {
				// should we await?
				dispatch(getAllTagUsers());
			}

			if (shouldLoadAtsData) {
				// should we await?
				dispatch(loadAtsPipelines());
				dispatch(loadAtsArchiveReasons());
			}

			if (shouldLoadCategoryTags) {
				// should we await?
				dispatch(getAllCategoryTags());
			}

			if (shouldGoToPreviousHistory) {
				goToPreviousHistoryIfExistQuery();
			}
		};
	};
}

export function onEnterUser(store, dispatch) {
	LocalDebug.logNull({ className, method: 'onEnterUser' });
	return onEnter({
		store,
		actionThunk: async (params) => {
			const { id } = params;
			LocalDebug.logNull({ className, method: 'onEnterUser: actionThunk' }, { id });
			await dispatch(loadUser(id));
		},
	});
}

export function onEnterJob(store, dispatch) {
	LocalDebug.logNull({ className, method: 'onEnterJob' });
	return onEnter({
		store,
		actionThunk: async (params) => {
			const { id } = params;
			await dispatch(loadJob(id));
		},
	});
}

export function doLogin(data) {
	return (async () => {
		try {
			await authFirebaseService.login(data.email, data.password);
		} catch (error) {
			message.error(error.message);
		}
	});
}

export function refreshCurrentUser() {
	return async (dispatch, getState) => {
		const { data } = await authService.getCurrentUser();
		// LocalDebug.logNull({ className, method: 'refreshCurrentUser' }, { data });
		if (data.user) {
			// check roles
			const aclRoleLabels = data?.user?.acls?.userAcls?.map((a) => a?.acl?.label);
			const hasStaffOrTesterRole = aclRoleLabels.some((r) => ['admin', 'staff', 'talent', 'tester'].includes(r));
			const is50Email = data.user.email.indexOf('@50intech.com') >= 0;
			LocalDebug.logInfo({ className, method: 'refreshCurrentUser' }, {
				aclRoleLabels,
				hasStaffOrTesterRole,
				is50Email,
				'data.user': data?.user,
				'userAcls': data?.user?.acls?.userAcls,
			}, { isNotProdVersion: config?.isNotProdVersion });

			if (config.isNotProdVersion && config.isNotDevEnv && !hasStaffOrTesterRole) {
				message.error(<>
					Your credentials are not compatible with this version of the app.<br />
					Need help? Please reach out to <Link>contact@50intech.com</Link>
				</>);
				doLogout()(dispatch, getState);
				window.open(`${config.adminRootProd}`);
				return;
			}
			localStorage.setItem(pathUser, JSON.stringify(data.user));

			dispatch({
				type: LOGIN_SUCCESS,
				user: data.user,
			});
		} else {
			message.error('No user found with these credentials');
			doLogout()(dispatch, getState);
		}
	};
}

export function loginSuccess() {
	return actionWithLoader(async (dispatch, getState) => {
		const currentUser = getCurrentUser();

		if (!currentUser) {
			await doLogout()(dispatch, getState);
			return;
		}

		// refresh current user
		await refreshCurrentUser()(dispatch, getState);

		// check firebase and user in storage
		const userIntoStorage = retrieveUserFromLocalStorage();

		// after login - refresh page
		// write recruiter session log //
		writeRecruiterLog(COMPANY_RECRUITER_LOG.SESSION);
		if (userIntoStorage && userIntoStorage.uid !== currentUser.uid) {
			pushLogout();
			doLogout()(dispatch, getState);
		}

		if (window.location.pathname === (PATHS.LOGIN)) {
			showHome();
		}
	});
}

export function doLogout() {
	return actionWithLoader(async (dispatch) => {
		await authFirebaseService.logout();
		dispatch({
			type: LOGIN_SUCCESS,
			user: null,
		});
		clearUserIntoLocalStorage();
		showLogin();
	});
}

export function doActionHistory(data) {
	return async (dispatch) => {
		dispatch({
			type: SET_ACTION_HISTORY,
			data,
		});
	};
}

export function readAction(action) {
	return async (dispatch) => {
		dispatch({
			type: READ_ACTION_HISTORY,
			playload: action,
		});
	};
}

export function goToLogin() {
	const { pathname } = history.location;

	if ([PATHS.RESET_PASSWORD].some((p) => pathname.indexOf(p) === 0)) {
		return;
	}

	authFirebaseService.logout();

	// redirect to login for other page when  unauthorized error
	if (pathname !== PATHS.LOGIN) {
		showLogin();
	}
}

export function getCurrentUser() {
	return authFirebaseService.currentUser();
}

export function retrieveCurrentQueryParams(path) {
	const queryParams = localStorage.getItem(path);
	return queryParams ? JSON.parse(queryParams) : null;
}

export function clearUserIntoLocalStorage(path = pathUser) {
	localStorage.removeItem(path);
}

// --------------------------------------------------------//
// ---------------------- Routing -------------------------//
// --------------------------------------------------------//
export function showHome() {
	return push('/');
}

export function showLogin() {
	return push(PATHS.LOGIN);
}

export function showResetPassword() {
	return push(PATHS.RESET_PASSWORD);
}

export function showEmailTemplates() {
	return push(PATHS.EMAIL_TEMPLATES);
}
