/* eslint-disable react-hooks/exhaustive-deps */
import React, {
	useState, useReducer, useEffect, useRef, useContext,
} from 'react';
import { LocalDebug } from '../../../utils/LocalDebug';
import AppService from '../../../services/app';
import { hashValue, lastChars, sortObj } from '../../../utils/common';
import { COMPANY_RECRUITER_LOG } from '../../../constants/company-recruiter-log';
import { writeRecruiterLog } from '../../../actions/logging';

export const DataFetcherContext = React.createContext();

export const DATA_FETCHER_UPDATE_FILTERS = 'DATA_FETCHER_UPDATE_FILTERS';
export const DATA_FETCHER_NEXT_PAGE = 'DATA_FETCHER_NEXT_PAGE';
export const DATA_FETCHER_LOAD_MORE = 'DATA_FETCHER_LOAD_MORE';

const DataFetcherProvider = (
	{
		path = '',
		limit = 24,

		filterOptions,
		defaultDataSource,
		loadOnInit = true,
		loadOnNoFilter = true,

		withLoadMore = false,
		withDynamicSearch,
		withPagination = true,
		withClientSidePagination = false,

		isRefresh = 0,
		mandatoryFilters = [],

		children,
		...props
	},
) => {
	const className = 'DataFetcherProvider';

	const lastFiltersHash = useRef('');
	const lastPaginationHash = useRef('');

	const [isFirstLoad, setIsFirstLoad] = useState(true);
	const [isLoadMore, setIsLoadMore] = useState(withLoadMore);
	const [isLoading, setIsLoading] = useState(false);
	const [dataTotal, setDataTotal] = useState(0);
	const [dataSource, setDataSource] = useState(defaultDataSource || []);
	const [dataSourceComplete, setDataSourceComplete] = useState(false);

	// LocalDebug.logInfo({ className }, { path, filterOptions });
	const [params, paramsDispatch] = useReducer((state, command) => {
		const { action } = command;
		// LocalDebug.logInfo({ className, method: 'useReducer(params)' }, { state, command, action });
		const { skip } = { skip: 0, ...state };
		switch (action) {
			case DATA_FETCHER_UPDATE_FILTERS:
				return {
					skip: 0,
					limit,
					...filterOptions || {},
					...command?.filters || {},
				};
				break;
			case DATA_FETCHER_LOAD_MORE:
				return {
					...state,
					skip: state.skip + limit,
				};
				break;
			default:
				return state;
		}
		return {
			...state,
			skip,
			limit,
		};
	}, {
		...filterOptions || {},
		skip: 0,
		limit,
	});

	// useEffect(() => {
	// 	LocalDebug.logUseEffect({ className, effects: 'isRefresh, filterOptions, path' }, {
	// 		isRefresh, filterOptions, path,
	// 	});
	// 	if (isRefresh) refresh({ force: true });
	// }, [isRefresh, filterOptions, path]);
	//
	useEffect(() => {
		LocalDebug.logUseEffect({ className, effects: 'isRefresh, filterOptions, path' }, {
			isRefresh, filterOptions, path,
		});
		refresh({ force: true });
	}, [isRefresh, filterOptions, path]);

	const fetchData = async () => {
		const method = 'fetchData';
		LocalDebug.logInfo({ className, method }, { path, params });

		const { skip, limit, ...rest } = params;

		const paginationHash = await hashValue({ skip, limit });
		const filtersHash = await hashValue(sortObj(rest));

		const hasPaginationChanged = paginationHash !== lastPaginationHash.current;
		const hasFiltersChanged = filtersHash !== lastFiltersHash.current;

		// LocalDebug.logInfo({ className, method }, {
		// 	paginationHash: lastChars(paginationHash) + ' vs ' + lastChars(lastPaginationHash.current || '') + ' => ' + hasPaginationChanged.toString(),
		// 	filtersHash: lastChars(filtersHash) + ' vs ' + lastChars(lastFiltersHash.current || '') + ' => ' + hasFiltersChanged.toString(),
		// });

		if (!(hasPaginationChanged || hasFiltersChanged)) return;

		lastFiltersHash.current = filtersHash;
		lastPaginationHash.current = paginationHash;

		let shouldReset = false;
		if (hasFiltersChanged || (hasPaginationChanged && (skip == 0))) {
			// LocalDebug.logInfo({ className, method }, 'reset dataSource');
			shouldReset = true;
			setDataSource([]);
		}
		// LocalDebug.logAPICall({ className, method }, { paginationHash, filtersHash });
		setIsLoading(true);

		try {
			// await sleep(3000);
			const { data: { count, items } } = await AppService.getData(path, params);
			LocalDebug.logAPIResult({ className, method }, {
				skip, limit, count, items: items?.length,
			});
			writeRecruiterLog(
				COMPANY_RECRUITER_LOG.API_RESULT,
				{},
				{
					query: params,
					count,
					items: items?.length,
				},
			);

			setDataTotal(count);
			setDataSource((p) => (isLoadMore && !shouldReset
				? [...p, ...items]
				: items));
			setDataSourceComplete((skip + (limit || 1)) > count);
			setIsLoading(false);
		} catch (error) {
			// if (!error?.message?.includes('canceled due to new request')) {
			// 	setIsLoading(false);
			// }
			LocalDebug.logError({
				className, method, __filename, error,
			});
		} finally {
			setIsLoading(false);
		}
	};

	const fetchMore = () => {
		const method = 'fetchMore';
		// LocalDebug.logInfo({ className, method });
		paramsDispatch({ action: DATA_FETCHER_LOAD_MORE });
	};

	const isEmptyQuery = () => (
		JSON.stringify(Object.keys(params).sort()) === JSON.stringify(['limit', 'skip'].sort())
	);
	useEffect(async () => {
		// LocalDebug.logUseEffect({ className, effects: 'params' }, {
		// 	params: JSON.stringify(params), loadOnInit, isFirstLoad,
		// });

		if (!loadOnNoFilter && isEmptyQuery()) {
			// Don't fetch data if there are no filters
			return;
		}
		if (loadOnInit || !isFirstLoad) {
			await fetchData();
		}
		setIsFirstLoad(false);
	}, [params]);

	useEffect(async () => {
		// LocalDebug.logUseEffect({ className, effects: '' }, { loadOnInit });
		if (loadOnInit && isFirstLoad) {
			await fetchData();
		}
		setIsFirstLoad(false);
	}, []);

	const refresh = ({ force = false } = { force: false }) => {
		// LocalDebug.logInfo({ className, method: 'refresh' }, { force });
		if (force) lastPaginationHash.current = '';
		paramsDispatch({ action: DATA_FETCHER_UPDATE_FILTERS });
	};

	return (
		<DataFetcherContext.Provider
			value={{
				path,
				dataTotal,
				setDataTotal,
				dataSource,
				setDataSource,
				params,
				paramsDispatch,
				isLoading,
				setIsLoading,
				refresh,
				isLoadMore,
				setIsLoadMore,
				fetchMore,
				dataSourceComplete,
				DATA_FETCHER_UPDATE_FILTERS,
				DATA_FETCHER_NEXT_PAGE,
				DATA_FETCHER_LOAD_MORE,
			}}
		>
			{children}
		</DataFetcherContext.Provider>
	);
};

export default DataFetcherProvider;
