import CompanyFieldNotProvided from './CompanyFieldNotProvided';

const CompanyFieldNotProvidedLocked = (
	{
		children,
		...props
	},
) => {
	const className = 'CompanyFieldNotProvidedLocked';

	return (
		<CompanyFieldNotProvided
			style={{
				display: 'block',
				textAlign: 'left',
				width: '100%',
				height: '100%',
				padding: 0,
				borderRadius: 'unset',
				border: 'unset',
				...props?.style,
			}}
		>
			{children}
		</CompanyFieldNotProvided>
	);
};

export default CompanyFieldNotProvidedLocked;
