import PageLayout from '../../../components/app/layout/PageLayout';
import { useEffect, useState } from 'react';
import { addMultipleQueryParams, getQueryParams, removeQueryParams } from '../../../utils/common';
import SectionTitle from '../../../components/app/SectionTitle';
import { FaEyeSlash, FaMousePointer } from 'react-icons/fa';
import UserActionTable from '../../../components/staff/data/userActions/UserActionTable';
import FilterButton from '../../../components/app/filters/FilterButton';
import UserActionFilterModal from '../../../components/staff/data/userActions/UserActionFilterModal';

const UserActionsPage = () => {
	const queryParams = getQueryParams();

	const [optionsFilterTable, setOptionsFilterTable] = useState({ notCompanyIds: ['5ee967fd6d5f600038bda91c'] });
	const [isRefreshDataLayout, setIsRefreshDataLayout] = useState(0);

	useEffect(() => {
		setOptionsFilterTable((prev) => ({ ...prev, ...queryParams }));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	// --------------------------------------- //
	// ------------ handle filter ------------ //
	// --------------------------------------- //
	const handleFilter = (values) => {
		const { tags, ...rest } = values;
		addMultipleQueryParams(rest);
		setOptionsFilterTable((prev) => {
			return ({ ...prev, ...rest, tags });
		});
	};

	const handleClearFilter = () => {
		setOptionsFilterTable({});
		setIsRefreshDataLayout((prev) => prev + 1);
		removeQueryParams();
	};

	return (
		<PageLayout
			title={<SectionTitle icon={<FaMousePointer/>} title="User Actions" renderAlone={false}/>}
			filter={<FilterButton
				onFilter={handleFilter}
				onClearFilter={handleClearFilter}
				initialValues={optionsFilterTable}
				modal={UserActionFilterModal}
			/>}
		>
			<UserActionTable
				filterOptions={optionsFilterTable}
				isRefresh={isRefreshDataLayout}
			/>
		</PageLayout>
	);
};

export default UserActionsPage;
