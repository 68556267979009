import { useEffect, useState } from 'react';
import { ENGLISH_LANG } from '../../../../constants/constant';
import CompanyFieldEditor from './CompanyFieldEditor';
import { Input, Space } from 'antd';

const CompanyNumberEditor = (
	{
		company,
		lang = ENGLISH_LANG,
		title,
		details,
		field,
		isTranslatable = true,
		setEditorOpen,
		min,
		max,
		step,
		onChange,
		children,
		...props
	},
) => {
	const className = 'CompanyNumberEditor';

	const [editedValue, setEditedValue] = useState(
		company.getTranslationField(field, lang),
	);

	useEffect(() => {
		onChange?.(editedValue);
	}, [editedValue]);

	return (
		<CompanyFieldEditor
			company={company}
			lang={lang}
			setEditorOpen={setEditorOpen}
			title={title}
			details={details}
			field={field}
			isTranslatable={isTranslatable}
			editedValue={editedValue}
		>
			<Space
				direction='vertical'
				style={{
					justifyContent: 'center',
					alignItems: 'center',
					width: '100%',
					...props?.style,
				}}
			>

				<Input
					value={editedValue}
					type='number'
					min={min}
					max={max}
					step={step}
					onChange={(e) => setEditedValue(e.target.value)}
					style={{ ...props?.style?.input }}
				/>

				{children}

			</Space>

		</CompanyFieldEditor>
	);
};

export default CompanyNumberEditor;
