import LogsPage from '../../../containers/staff/data/LogsPage';
import { onEnterBuilder } from '../../../actions/app';
import { PERMS_GRANT_STAFF } from '../../menu.utils';

const menu = {
	name: 'staff-data-logs',
	path: '/staff/data/logs',
	label: 'Logs',
	icon: <span>📖</span>,
	component: LogsPage,
	onEnter: onEnterBuilder({
		origin: LogsPage.name,
		shouldGoToPreviousHistory: true,
	}),
	perms: PERMS_GRANT_STAFF,
};

export default menu;
