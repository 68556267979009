import { css } from '@emotion/css';
import {
	Space, Table, Tooltip,
} from 'antd';
import { isFunction } from 'lodash';
import {
	arrayWrap, round, sumReducer,
} from '../../../utils/common';
import ReactMarkdown from 'react-markdown';
import { useContext, useMemo } from 'react';
import { useTheme } from '@emotion/react';
import { isQuestionAnswered } from '../data/shared';
import SurveyQuestionAnswer from './SurveyQuestionAnswer';
import { SurveyContext } from './SurveyProvider';
import { getPillarColorMain, getPillarIcon } from '../../../constants/genderscore-pillars';
import CompanyGenderScoreRingScore from '../../company/genderscore/CompanyGenderScoreRingScore';
import { GENDERSCORE_LABEL } from '../../../constants/constant';
import SCORING from '../data/scoring';
import { LocalDebug } from '../../../utils/LocalDebug';
import SurveyScoringDetailsQuestion from './SurveyScoringDetailsQuestion';

const columnFixedWidth = (size) => ({
	className: css({
		width: size,
		minWidth: size,
		maxWidth: size,
		// background: 'red',
	}),
});
const columnMinWidth = (size) => ({
	className: css({
		width: size,
		minWidth: size,
	}),
});

export const questionStubCol = (theme, { surveyConf, answers }) => ({
	title: 'Question',
	dataIndex: 'index',
	render: (value, row) => (
		<SurveyScoringDetailsQuestion
			index={value}
			questionId={row?.questionId}
			surveyConf={surveyConf}
			answers={answers}
		/>
	),
	...columnFixedWidth(400),
});

const NAText = () => <div
	style={{
		fontSize: 10,
		color: '#999',
	}}
>
	<ReactMarkdown>*N/A*</ReactMarkdown>
</div>;

const SurveyScoringDetails = ({
	survey,
	surveyConf,
}) => {
	const className = 'SurveyScoringDetails';

	const theme = useTheme();

	const {
		answers: contextAnswers,
		surveyDraft: contextSurvey,
		surveyConf: contextSurveyConf,
	} = useContext(SurveyContext);

	surveyConf = surveyConf || contextSurveyConf;
	const answers = survey?.answers || contextAnswers;
	survey = survey || contextSurvey;

	LocalDebug.logInfo({ className }, { surveyConf, survey, answers });

	const questionScores = useMemo(() => SCORING.computeSurveyQuestionScores({
		surveyConf,
		answers,
		origin: className,
	}), [surveyConf, answers]);

	if (!surveyConf && !contextSurveyConf) return 'No survey conf defined';

	const columns = [
		questionStubCol(theme, { surveyConf, answers }),
		{
			title: <>Answer</>,
			align: 'center',
			render: (value, row) => (
				<SurveyQuestionAnswer
					question={row}
					answer={answers?.[row?.questionId]}
					notAnswered={<i>Not answered</i>}
					style={{
						tag: {
							fontSize: 11,
							lineHeight: 1.3,
							padding: '2px 5px',
						},
					}}
				/>
			),
		},
		{
			title: <>Score<br/>/100</>,
			align: 'center',
			render: (question) => (
				(
					isQuestionAnswered({ question, answers })
					&& questionScores?.[question?.questionId] >= 0
				)
					? (
						<CompanyGenderScoreRingScore
							value={round(questionScores?.[question?.questionId], 1)}
							size={48}
							style={{
								score: { fontSize: 14 },
							}}
						/>
					)
					: (
						<NAText />
					)
			),
			...columnFixedWidth(60),
		},
		{
			title: (
				<Space direction='vertical' align='center' size={4}>
					{GENDERSCORE_LABEL}
					<CompanyGenderScoreRingScore
						value={SCORING.computeSurveyScores({ surveyConf, answers, origin: className })}
						size={32}
					/>
				</Space>
			),
			align: 'center',
			render: (question) => {
				const { questionId } = question || {};
				let points = surveyConf?.sections
					?.map((section) => {
						const sectionWeight = Object
							.values(section?.weighings)
							.map((weighing) => {
								const weight = isFunction(weighing)
									? weighing(answers)
									: weighing;
								return weight;
							})
							.reduce(sumReducer, 0);

						let sectionQuestionWeight = section?.weighings?.[questionId] || 0;
						if (isFunction(sectionQuestionWeight)) {
							sectionQuestionWeight = sectionQuestionWeight(answers);
						}

						if (!(
							isQuestionAnswered({ question, answers })
							&& surveyConf?.scoring?.[questionId]
							&& sectionQuestionWeight > 0
						)) {
							return null;
						}
						return [
							(questionScores?.[questionId] || 0) * sectionQuestionWeight / sectionWeight,
							100 * sectionQuestionWeight / sectionWeight,
						];
					});

				points = points.filter((i) => i?.[0] >= 0 && i?.[1] >= 0);
				if (points?.length === 0) {
					return null;
				}
				const totalScore = points.map((i) => i?.[0]).reduce(sumReducer, 0);
				return (
					<div
						style={{
							width: '100%',
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
						}}
					>
						<span style={{ fontSize: 14, fontWeight: 'bold' }}>
							{round(totalScore / 5, 2)}
						</span>
					</div>
				);
			},
			...columnFixedWidth(100),
		},
		...surveyConf?.sections
			?.map((section) => ({
				key: section.value,
				title: (
					<Space direction='vertical' align='center' size={0}>
						<Space
							size={4}
							style={{
								width: '100%',
								justifyContent: 'center',
								alignItems: 'center',
							}}
						>
							<span>{getPillarIcon(section?.value)}</span>
							<span>{section?.initials}</span>
						</Space>

						<CompanyGenderScoreRingScore
							value={round(SCORING.computeSectionWeightedScore({
								questionScores,
								...section,
								answers,
							}))}
							ringColor={getPillarColorMain(section?.value)}
							size={26}
						/>
					</Space>
				),
				align: 'center',
				render: (value) => {
					const { questionId } = value || {};
					const sectionWeight = Object
						.values(section?.weighings)
						.map((weighing) => {
							const weight = isFunction(weighing)
								? weighing(answers)
								: weighing;
							return weight;
						})
						.reduce(sumReducer, 0);
					let sectionQuestionWeight = section?.weighings?.[questionId] || 0;
					if (isFunction(sectionQuestionWeight)) {
						sectionQuestionWeight = sectionQuestionWeight(answers);
					}

					const sectionQuestion = surveyConf?.scoring[questionId];
					// console.log({ sectionWeight, sectionQuestionWeight });
					if (sectionQuestion && sectionQuestionWeight > 0) {
						const pct = Math.round(100 * sectionQuestionWeight / sectionWeight);
						let score;

						if (sectionQuestion?.computer?.computer
							&& isQuestionAnswered({ question: value, answers })
						) {
							let questionAnswers = arrayWrap(answers?.[questionId]);
							let options = sectionQuestion?.options;
							if (options?.length >= 1) {
								options = options
									?.filter((o) => (
										questionAnswers.includes(o.value)
											|| questionAnswers.includes(o.label)));
								questionAnswers = options
									?.map((o) => o.score)
									?.reduce(sumReducer, 0);
							}
							score = sectionQuestion?.computer?.computer?.({
								value: questionAnswers,
								ranges: sectionQuestion?.ranges,
							});
						}
						return (
							<>
								<Space
									direction="vertical"
									size={4}
									style={{
										fontSize: 10,
										color: '#999',
									}}
								>
									<Tooltip
										title={
											<span>
												The answer to this question will account
												for <b>{pct}%</b> in the total score of
												the <b>{section.label}</b> pillar.<br />
												<i style={{ opacity: 0.7, fontSize: 12 }}>
													(so from this pillar, <b>{pct / 5}%</b> of
													the final <b>GenderScore</b>)
												</i>
											</span>
										}
									>
										<div
											style={{
												background: getPillarColorMain(section.value),
												color: 'white',
												margin: 0,
												fontWeight: 'bold',
												fontSize: 14,
												padding: '1px 5px',
												borderRadius: 3,
											}}
										>
											{pct}%
										</div>
									</Tooltip>
								</Space>
							</>
						);
					}
					return <NAText />;
				},
				...columnFixedWidth(80),
			})) || [],
	];

	return (
		<Table
			columns={columns}
			pagination={false}
			dataSource={
				surveyConf?.sections
					.map((s) => s.questions)
					.flat()
					.map((q, i) => ({
						...q,
						index: i + 1,
						id: q.questionId,
					}))}
			style={{
				border: '1px solid #eee',
				borderRadius: 5,
			}}
			size="middle"
			scroll={{
				x: true,
				y: '86vh',
			}}
		/>
	);
};

export default SurveyScoringDetails;
