import UserService from '../../../../services/user';
import { documentId } from '../../../../utils/common';
import UserSelect from '../../../user/UserSelect';
import UserStub from '../../../user/stub/UserStub';

const CompanyPlanGenderScoreOwnerIdSelect = ({
	disabled = false,
	company,
	...props
}) => {
	const className = 'CompanyPlanGenderScoreOwnerIdSelect';

	const handleFetchManagers = async (options) => {
		const { data: { items } } = await UserService.findAll({
			...options,
			disableHiddenCompanyIds: true,
			companyId: documentId(company),
			isAdmin: true,
		});
		return items;
	};

	return (
		<UserSelect
			disabled={disabled}
			onFindUsers={handleFetchManagers}
			placeholder='Select a user'
			defaultOptions={[]}
			labelizer={(user) => (
				<UserStub
					user={user}
					avatarSize={20}
					withLocation={false}
					withProfession={false}
					withCompanyLogo={false}
					withIsUnviewed={false}
					withCoachedTag={false}
					withResumeTag={false}
				/>
			)}
			{...props}
		/>
	);
};

export default CompanyPlanGenderScoreOwnerIdSelect;
