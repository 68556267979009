import { LocalDebug } from '../../../../../utils/LocalDebug';
import {
	italic,
	regular,
	PDF_TEXT_STYLES,
	PDF_TYPE_TEXT,
	PDF_TYPE_TITLE,
	PDF_TYPE_GRID,
	PDF_PAGE_TEMPLATE_CONTENT,
	PDF_TYPE_RECT,
} from '../base/PDFBuilder';

const PageBiasIntro = () => {
	const className = 'PageBiasIntro';

	LocalDebug.logInfo({ className });

	return [
		{
			template: PDF_PAGE_TEMPLATE_CONTENT,
			title: {
				en: 'What is a bias?',
				fr: 'Qu\'est-ce qu\'un biais ?',
			},
			footer: true,
			pageCount: true,
			items: [
				{
					type: PDF_TYPE_GRID,
					gridTemplateColumns: [1.7, 1],
					columnGap: 10,
					columns: [
						[
							{
								type: PDF_TYPE_TEXT,
								text: {
									en: '• Unconscious mental shortcuts that aim to process information, make a decision, and make judgments quickly',
									fr: '• Un biais est un raccourci mental inconscient qui vise à traiter l\'information, à prendre une décision et à porter des jugements rapidement.',
								},
								...regular(PDF_TEXT_STYLES.normal),
								marginTop: 6,
								marginBottom: 3,
							},
							{
								type: PDF_TYPE_TEXT,
								text: {
									en: '• Can lead to erroneous judgments',
									fr: '• Les biais peuvent conduire à des jugements erronés',
								},
								...regular(PDF_TEXT_STYLES.normal),
								marginBottom: 3,
							},
							{
								type: PDF_TYPE_TEXT,
								text: {
									en: '• Often based on stereotypes and social roles',
									fr: '• Ils sont souvent basés sur des stéréotypes et des rôles sociaux',
								},
								...regular(PDF_TEXT_STYLES.normal),
								marginBottom: 10,
							},
							{
								type: PDF_TYPE_TEXT,
								text: {
									en: 'Stereotypes are culturally constructed and internalized over time and according to culture, through education, institutions, and interactions (Schmader, 2023). As biases toward women in tech are based on gender stereotypes, it appears to be more acquired than innate.',
									fr: 'Les stéréotypes sont construits culturellement et internalisés au fil du temps et selon la culture, à travers l\'éducation, les institutions et les interactions (Schmader, 2023). Comme les préjugés envers les femmes dans la technologie sont basés sur des stéréotypes de genre, il semble qu\'ils soient plus acquis qu\'innés.',
								},
								...regular(PDF_TEXT_STYLES.normal),
							},
						],
						[
							{
								type: PDF_TYPE_TEXT,
								text: {
									en: 'Managers may favor quick yet unconsciously biased evaluations, particularly to minimize time and effort in the evaluation process.\nIt\'s normal to have bias.',
									fr: 'Les managers peuvent favoriser des évaluations rapides mais inconsciemment biaisées, notamment pour minimiser le temps et l\'effort dans le processus d\'évaluation.\nIl est normal d\'avoir des biais.',
								},
								...italic(PDF_TEXT_STYLES.normal),
								fontSize: 12,
								padding: {
									left: 7, right: 7, top: 7, bottom: 7,
								},
								// fill: '#101582',
								fill: '#e6346b',
								color: '#ffffff',
							},
						],
					],
				},
				{
					type: PDF_TYPE_GRID,
					gridTemplateColumns: [1, 1],
					columnGap: 10,
					marginTop: 20,
					columns: [
						[
							{
								type: PDF_TYPE_TITLE,
								text: {
									en: 'Innate',
									fr: 'Inné',
								},
								marginBottom: 4,
								withArrow: true,
							},
							{
								type: PDF_TYPE_TEXT,
								text: {
									en: 'Something you have from birth, like it\'s natural or essential (Pontarotti, 2020).',
									fr: 'Quelque chose que vous avez dès la naissance, comme si c\'était naturel ou essentiel (Pontarotti, 2020).',
								},
								...regular(PDF_TEXT_STYLES.normal),
								marginBottom: 2,
							},
						],
						[
							{
								type: PDF_TYPE_TITLE,
								text: {
									en: 'Acquired',
									fr: 'Aquis',
								},
								marginBottom: 5,
								withArrow: true,
							},
							{
								type: PDF_TYPE_TEXT,
								text: {
									en: 'Something you develop after birth, influenced by your culture or circumstances (Pontarotti, 2020).',
									fr: 'Quelque chose que vous développez après la naissance, influencé par votre culture ou vos circonstances (Pontarotti, 2020).',
								},
								...regular(PDF_TEXT_STYLES.normal),
								marginBottom: 2,
							},
						],
					],
				},
			],
		},
	];
};

export default PageBiasIntro;
