import { useTheme } from '@emotion/react';
import { Button, Form, Space } from 'antd';
import { useSelector } from 'react-redux';
import { getAtsArchiveReasons } from '../../reducers/app';
import FormItem, { FormItemContainer } from '../app/form/FormItem';
import FormAtsArchiveReasonSelect from '../app/form/FormAtsArchiveReasonSelect';
import { documentId } from '../../utils/common';

const AtsArchiveReasonMappingEditForm = (
	{
		initialValues = {},
		onSubmit,
		onReset,
		withSubmitButton = false,
	},
) => {
	const theme = useTheme();
	const atsArchiveReasons = useSelector(getAtsArchiveReasons);

	const [form] = Form.useForm();

	return (<>
		<div style={{
			marginBottom: 10,
			fontSize: 14,
			fontWeight: 600,
		}}>Remote Stage
		</div>
		<Space direction='vertical' size={0} style={{
			marginBottom: 10,
			background: theme.color.bgGrey,
			border: '1px solid #eee',
			borderRadius: 5,
			padding: '5px 12px',
		}}>
			<span><b>Text: </b>{initialValues.text}</span>
			<span><b>ID: </b><code style={{ fontSize: 10 }}>{documentId(initialValues)}</code></span>
		</Space>
		{/* <JSONPretty data={initialValues} /> */}
		<Form
			form={form}
			name="ats_stage_form"
			initialValues={{ ...initialValues }}
			onFinish={onSubmit}
		>
			<FormItemContainer>

				<FormItem
					name="archiveReason"
					label="Archive reason"
					defaultContainer="div"
				>
					<FormAtsArchiveReasonSelect
						options={atsArchiveReasons}
						style={{ width: '100%' }}
					/>
				</FormItem>

			</FormItemContainer>

			{withSubmitButton && <div style={{
				display: 'flex',
				flexDirection: 'row-reverse',
				margin: -5,
			}}>
				<Button type="primary"
					style={{
						margin: 5,
						background: theme.color.blue,
						borderColor: theme.color.blue,
						fontWeight: 'bold',
						borderRadius: 5,
					}}
					htmlType="submit"
				>
						Edit mapping
				</Button>
				<Button type="default"
					style={{
						margin: 5,
						borderColor: theme.color.orange,
						fontWeight: 'bold',
						borderRadius: 5,
						color: theme.color.orange,
					}}
					htmlType="reset"
					onClick={onReset}
				>
						Cancel
				</Button>
			</div>
			}
		</Form>
	</>
	);
};

export default AtsArchiveReasonMappingEditForm;
