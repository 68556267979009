import { isStringTypeValidator } from '../../../utils/isStringTypeValidator';
import { innerObjPropExtractor } from '../../property/property-extractor';
import { innerObjPropMapper } from '../../property/property-mapper';

const USER_PROPERTY_CAREER_SEEKING_KEY = 'career-seeking';

const USER_PROPERTY_CAREER_SEEKING_ANSWER_ACTIVE = 'active';
const USER_PROPERTY_CAREER_SEEKING_ANSWER_OPEN = 'open';
const USER_PROPERTY_CAREER_SEEKING_ANSWER_NO = 'no';
const USER_PROPERTY_CAREER_SEEKING_OPTIONS = [
	{ value: USER_PROPERTY_CAREER_SEEKING_ANSWER_ACTIVE, label: 'Active', emoji: '🔥' },
	{ value: USER_PROPERTY_CAREER_SEEKING_ANSWER_OPEN, label: 'Open', emoji: '👀' },
	{ value: USER_PROPERTY_CAREER_SEEKING_ANSWER_NO, label: 'Not looking', emoji: '🚫' },
];
const USER_PROPERTY_CAREER_SEEKING_FREE = false;

const customValidator = (value) => USER_PROPERTY_CAREER_SEEKING_OPTIONS.map((o) => o.value).includes(value);

const USER_PROPERTY_CAREER_SEEKING_VALIDATORS = [
	isStringTypeValidator,
	customValidator,
];

const USER_PROPERTY_CAREER_SEEKING_FIELD = 'career.seeking';
const USER_PROPERTY_CAREER_SEEKING_MAPPER = innerObjPropMapper(USER_PROPERTY_CAREER_SEEKING_FIELD);
const USER_PROPERTY_CAREER_SEEKING_EXTRACTOR = innerObjPropExtractor(USER_PROPERTY_CAREER_SEEKING_FIELD);

export default {
	USER_PROPERTY_CAREER_SEEKING_ANSWER_ACTIVE,
	USER_PROPERTY_CAREER_SEEKING_ANSWER_OPEN,
	USER_PROPERTY_CAREER_SEEKING_ANSWER_NO,
	key: USER_PROPERTY_CAREER_SEEKING_KEY,
	USER_PROPERTY_CAREER_SEEKING_KEY,
	validators: USER_PROPERTY_CAREER_SEEKING_VALIDATORS,
	USER_PROPERTY_CAREER_SEEKING_VALIDATORS,
	options: USER_PROPERTY_CAREER_SEEKING_OPTIONS,
	USER_PROPERTY_CAREER_SEEKING_OPTIONS,
	free: USER_PROPERTY_CAREER_SEEKING_FREE,
	USER_PROPERTY_CAREER_SEEKING_FREE,
	mapper: USER_PROPERTY_CAREER_SEEKING_MAPPER,
	USER_PROPERTY_CAREER_SEEKING_MAPPER,
	extractor: USER_PROPERTY_CAREER_SEEKING_EXTRACTOR,
	USER_PROPERTY_CAREER_SEEKING_EXTRACTOR,
	field: USER_PROPERTY_CAREER_SEEKING_FIELD,
	USER_PROPERTY_CAREER_SEEKING_FIELD,
};
