import { Col, Row } from 'antd';
import moment from 'moment';

const CompanyBatchCard = ({ batch }) => {
	return [
		['Last update', moment(batch.endDate).fromNow()],
		// ['Started at', moment(batch.startDate).format('DD/MM/YY HH:mm:ss')],
		// ['Ended at', moment(batch.endDate).format('DD/MM/YY HH:mm:ss')],
		['Duration', `${Math.round(moment.duration(moment(batch.endDate).diff(batch.startDate)).asMinutes())} min`],
		['Processed', batch.total],
		['Success', batch.success],
		['Errors', batch.error],
		['Created', batch.created],
		['Deleted', batch.deleted],
		['Published', batch.published],
	].map(([label, value]) => <Row>
		<Col style={{ width: 120, fontWeight: 700 }}>{label}</Col>
		<Col>{value}</Col></Row>,

		// <Space direction="vertical" size={0}>
		//         <Typography>
		//             <Typography.Text style={{fontWeight: 700}}>Last update: </Typography.Text>
		//             {moment(batch.endDate).fromNow()}
		//         </Typography>
		//         <Typography>
		//             <Typography.Text style={{fontWeight: 700}}>Time: </Typography.Text>
		//             {Math.round(moment.duration(moment(batch.endDate).diff( batch.startDate)).asMinutes())} min
		//         </Typography>
		//         <Typography>
		//             <Typography.Text style={{fontWeight: 700}}>Total: </Typography.Text>
		//             {batch.total}
		//         </Typography>
		//         <Typography>
		//             <Typography.Text style={{fontWeight: 700}}>Success: </Typography.Text>
		//             {batch.success}
		//         </Typography>
		//         <Typography>
		//             <Typography.Text style={{fontWeight: 700}}>Created: </Typography.Text>
		//             {batch.created}
		//         </Typography>
		//         <Typography>
		//             <Typography.Text style={{fontWeight: 700}}>Deleted: </Typography.Text>
		//             {batch.deleted}
		//         </Typography>
		//         <Typography>
		//             <Typography.Text style={{fontWeight: 700}}>Published: </Typography.Text>
		//             {batch.published}
		//         </Typography>
		//         <Typography>
		//             <Typography.Text style={{fontWeight: 700}}>Errors: </Typography.Text>
		//             {batch.error}
		//         </Typography>
		//     </Space>
	);
};

export default CompanyBatchCard;
