import { Button, Space } from 'antd';
import { FaEyeSlash } from 'react-icons/fa';
import { useTheme } from '@emotion/react';
import { useIntersectionObserver } from 'react-intersection-observer-hook';
import { useContext, useEffect } from 'react';
import UserHider from '../../buttons/UserHider';
import { DataFetcherContext } from '../../../app/data/DataFetcherProvider';
import { StaffToolsContext } from '../../../../contexts/StaffToolsProvider';
import UserGridCardEngageButtons from './UserGridCardEngageButtons';

const UserModalCardFooter = (
	{
		user,
		setUserViewed,
		onUserChanged,
		onShortlistClick,
		onMessageClick,
		onSourceClick,
		onRefresh,
		...props
	},
) => {
	const className = 'UserModalCardFooter';

	const theme = useTheme();

	const { isSavedSearchTabsEnabled } = useContext(StaffToolsContext);
	const [ref, { entry }] = useIntersectionObserver();
	const isVisible = entry && entry.isIntersecting;

	useEffect(() => {
		// LocalDebug.logUseEffect({ className, effects: 'entry' }, { user: user?.slug, isVisible });
		if (isVisible) setUserViewed?.();
	}, [isVisible]);

	return (
		<Space
			direction='vertical'
			size={0}
			style={{ width: '100%' }}
		>
			<div
				ref={ref}
				style={{
					width: '100%',
					display: 'flex',
					justifyContent: 'space-between',
					padding: 24,
				}}
			>
				<Space>

					{isSavedSearchTabsEnabled && <UserHider
						user={user}
						show={true}
						Component={Button}
						size='large'
						icon={<FaEyeSlash style={{ marginBottom: -2, marginRight: 8 }} />}
						onComplete={onRefresh}
					>
						<b>Hide</b>
					</UserHider>
					}

				</Space>

				<Space size={12}>
					<UserGridCardEngageButtons
						user={user}
						onShortlistClick={onShortlistClick}
						onMessageClick={onMessageClick}
						onSourceClick={onSourceClick}
						onUserChanged={onUserChanged}
					/>
				</Space>
			</div>
		</Space>
	);
};

export default UserModalCardFooter;
