import { BOOLEAN_YES_ICON, NOT_PROVIDED_YET_LABEL } from '../../../../constants/constant';

const UserProfileCareerSpecificsCardBody = (
	{
		user,
		notProvided = NOT_PROVIDED_YET_LABEL,
		icon = BOOLEAN_YES_ICON,
		size = 'medium',
		...props
	},
) => {
	return (user?.career?.careerSpecific?.length > 0
		? user?.career?.careerSpecific?.map((s) => {
			if (s === 'startup-experience') {
				return <span>{icon} has an experience working in a startup</span>;
			}
			if (s === 'management') {
				return <span>{icon} has an experience in management</span>;
			}
			if (s === 'professional-transition') {
				return <span>{icon} in a professional transition</span>;
			}
			if (s === 'freelance') {
				return <span>{icon} is a freelancer</span>;
			}
			return s;
		}).map((label, index) => <p key={index}>{label}</p>)
		: notProvided
	);
};

export default UserProfileCareerSpecificsCardBody;
