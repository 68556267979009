import { Space } from 'antd';
import { useTheme } from '@emotion/react';
import { FaPlusSquare } from 'react-icons/fa';
import Link from '../../app/Link';
import Grid from '../../app/grid/Grid';
import DataFetcherTotal from '../../app/data/DataFetcherTotal';
import MessageTemplateFilters from './MessageTemplateFilters';
import MessageTemplateGridCard from './MessageTemplateGridCard';

const MessageTemplateTable = (
	{
		onDataLoaded,
		filterOptions = {},
		isRefresh = 0,
		onSearchDataColumn,
		onToggleIsActive,
		onEditArticle,
		onCreateTemplate,
		onEditTemplate,
		onCopyTemplate,
		onDeleteTemplate,
		isEditor,
		...props
	},
) => {
	const className = 'MessageTemplateTable';

	const theme = useTheme();

	return (
		<>
			<Space
				wrap={true}
				align='center'
				size={20}
				style={{
					width: '100%',
					padding: '0 0px',
					justifyContent: 'space-between',
					marginTop: 40,
					marginBottom: 20,
				}}
			>
				<MessageTemplateFilters />
				<DataFetcherTotal />
			</Space>

			<Grid
				columns={3}
				// limit={24}
				showSizeChanger={false}
				cardRender={(row, isLoading) => (
					<MessageTemplateGridCard
						template={row}
						onEditTemplate={onEditTemplate}
						onCopyTemplate={onCopyTemplate}
						onDeleteTemplate={onDeleteTemplate}
					/>
				)}
				empty={<Space
					direction='vertical'
					size={12}
					style={{
						border: '3px dashed #eee',
						borderRadius: 12,
						width: '100%',
						height: 200,
						justifyContent: 'center',
						alignItems: 'center',
					}}>
					<span style={{ fontSize: 16, fontWeight: 'bold', color: theme.color.grey }}>
						Nothing to see here :(
					</span>
					<Link onClick={() => onCreateTemplate()}>
						<FaPlusSquare style={{ marginBottom: -2 }} />&nbsp;Create an email template
					</Link>
				</Space>
				}
				defaultColumnsFilterOptions={[
					{ label: 'Template name', value: 'name' },
					{ label: 'Category', value: 'category' },
					{ label: 'Email Subject', value: 'subject' },
					{ label: 'Email Content', value: 'content' },
				]}

				onDataLoaded={onDataLoaded}
				isRefresh={isRefresh}
				onSearchDataColumn={onSearchDataColumn}
				showTotal={(total) => `Total ${total} items`}
				rowClassName={(row, index) => (row.is50Content === false ? 'rowDisabled' : '')}
				withDynamicSearch
				{...props}
			/>
		</>
	);
};

export default MessageTemplateTable;
