import { Group } from '@visx/group';
import { Pie, Polygon } from '@visx/shape';
import { useTheme } from '@emotion/react';
import { LinearGradient } from '@visx/gradient';
import { Space } from 'antd';
import { normalizePct } from '../../../../../utils/common';

const closeToGoal = ({ value, goal, threshold = 5 }) => {
	return Math.abs(goal - value) <= threshold;
};
/**
 * @param {number} value - Number (0-100) that represents the main percentage to render
 * @param {*} [graphColors] - Optional. [from, to, bg, goal:{reached,notReached}].
 * If not set, use theme.chart.ratio
 * @param {number} [width] - Optional. Width of the chart. Default: 100
 * @param {number} [lineWidth] - Optional. Width of the circumference. Default: 10
 * @param {number} [fontSize] - Optional. Font size of the label. Default: 36
 * @param {number} [goal] - Optional. Position to show goal triangles. Default: 50 (50%)
 * @param {number} [startAngle] - Optional. Position from where to draw the circumference.
 * Default:  -(Math.PI / 2)
 * @param {number} [endAngle] - Optional. Position where to finish the circumference.
 * Default:  (Math.PI / 2)
 * @param {boolean} [withBgPie] - Optional. Whether to show the background in a different pie.
 * It affects to the round corners effect. Default: true
 * @returns RatioGraph component
 */
const RatioGraph = ({
	value,
	graphColors,
	width = 100,
	lineWidth = 10,
	fontSize = 36,
	goal = 50,
	startAngle = -(Math.PI / 2),
	endAngle = Math.PI / 2,
	withBgPie = true,
	...props
}) => {
	const theme = useTheme();
	const colors = graphColors || theme.chart.ratio;
	const gradientId = 'ratio_fill';

	const half = width / 2;
	const height = width;
	// Ensure that the main percentage is an integer between 0 an 100
	const normalizedValue = normalizePct({ value });

	// Type affects the sort of the pie values.
	// We want the main percentage to go first --> use A-Z sort
	const ratio = [
		{
			type: 'A',
			value: normalizedValue,
			color: `url(#${gradientId})`,
		},
		{
			type: 'B',
			value: 100 - normalizedValue,
			color: colors.bg,
		},
	];
	const bgRatio = [
		{
			type: 'B',
			value: 100,
			color: colors.bg,
		},
	];
	const valueMapper = (data) => data.value;
	let labelOffset = {
		xOffset: normalizedValue < 10 ? '-0.5em' : '-0.85em',
		xOffsetPercentage: normalizedValue < 10 ? '0.2em' : '0.6em',
		yOffset: 14,
	};
	let labelFontSize = {
		value: fontSize,
		percentage: Math.floor(fontSize * 0.66),
	};
	if (normalizedValue === 100) {
		labelOffset = {
			...labelOffset,
			xOffset: '-1.1em',
			xOffsetPercentage: '1em',
		};
		labelFontSize = {
			value: fontSize - 4,
			percentage: Math.floor((fontSize - 4) * 0.66),
		};
	}
	const triangles = {
		width: 8,
		height: 6,
		offset: 7,
		color: closeToGoal({ value: normalizedValue, goal }) ? colors?.goal?.reached : colors?.goal?.notReached,
	};

	const RatioPie = ({ data }) => <Pie
		data={data}
		pieValue={valueMapper}
		outerRadius={half}
		innerRadius={half - lineWidth}
		padAngle={0.01}
		startAngle={startAngle}
		endAngle={endAngle}
		cornerRadius={15}
		pieSort={(a, b) => a.type - b.type}
	>
		{(pie) => {
			return pie.arcs.map((arc) => {
				return <g
					key={arc.data.type}
				>
					<path
						d={pie.path(arc)}
						fill={arc.data.color} />
				</g>;
			});
		}}
	</Pie>;

	// Note: Point (0,0) is the center of the chart
	return <Space style={{ ...props?.style }}>
		<svg width={width} height={height}>
			<defs>
				<LinearGradient from={colors?.from} to={colors?.to} id={gradientId}/>
			</defs>
			<Group top={half + 10} left={half}>
				{withBgPie && <RatioPie
					data={bgRatio}
				/>}
				<RatioPie
					data={ratio}
				/>
				<text
					x={0}
					y={0}
					fill={'black'}
					fontSize={labelFontSize.value}
					fontWeight={700}
					dx={labelOffset.xOffset}
					dy={labelOffset.yOffset}
				>
					{`${normalizedValue}`}
				</text>
				<text
					x={0}
					y={0}
					fill={'black'}
					fontSize={labelFontSize.percentage}
					fontWeight={700}
					dx={labelOffset.xOffsetPercentage}
					dy={labelOffset.yOffset}
				>
					{'%'}
				</text>
				<Polygon
					fill={triangles.color}
					points={[
						[-triangles.width / 2, -(half) - triangles.offset],
						[triangles.width / 2, -half - triangles.offset],
						[0, -half - triangles.offset + triangles.height],
					]}
				/>
				<Polygon
					fill={triangles.color}
					points={[
						[-triangles.width / 2, -half - triangles.offset + 2 * triangles.height + lineWidth],
						[triangles.width / 2, -half - triangles.offset + 2 * triangles.height + lineWidth],
						[0, -half - triangles.offset + triangles.height + lineWidth],
					]}
				/>
			</Group>
		</svg>
	</Space>;
};

export default RatioGraph;
