import UserAvatar from '../../widgets/UserAvatar';
import {
	Col, Divider, Popover, Row, Space, Typography,
} from 'antd';
import { useTheme } from '@emotion/react';
import { useAuthContext } from '../../../../hooks/useAuthContext';
import JobStub from '../../../jobs/JobStub';
import Box from '../../../app/box/Box';
import Logo50inTech from '../../../app/Logo50inTech';
import { documentId } from '../../../../utils/common';
import Callout from '../../../app/callout/Callout';
import UserAvatarList from '../../widgets/UserAvatarList';
import { LOGO_50INTECH_FULL_WHITE_SVG } from '../../../../constants/logos';
import { useContext, useEffect, useState } from 'react';
import CompanyModel from '../../../../models/CompanyModel';
import { useSelector } from 'react-redux';
import { getCompanySelected } from '../../../../reducers/app';
import CompanyLogoList from '../../../company/CompanyLogoList';
import CompanyLogo from '../../../company/CompanyLogo';
import UserGridCardListWithSeparator from './blocks/UserGridCardListWithSeparator';
import UserGridCardLabelValue from './blocks/UserGridCardLabelValue';
import { EnvironmentOutlined } from '@ant-design/icons';
import { FaChevronRight } from 'react-icons/fa';
import { StaffToolsContext } from '../../../../contexts/StaffToolsProvider';
import CompanyCaption from '../../../company/CompanyCaption';

const UserGridCardSuggested = (
	{
		user,
		...props
	},
) => {
	const className = 'UserGridCardSuggested';

	const theme = useTheme();

	const companySelected = useSelector(getCompanySelected);

	const { isStaffView } = useAuthContext();

	const {
		isUserGridCardSuggestedJobsInline,
	} = useContext(StaffToolsContext);

	const [company, setCompany] = useState();
	const [suggestions, setSuggestions] = useState({
		jobs: [],
		creators: [],
		companies: [],
	});
	useEffect(() => {
		if (companySelected) {
			setCompany(new CompanyModel(companySelected));
		}
	}, [companySelected]);
	useEffect(() => {
		const suggestedSourcings = user?.suggestedSourcings?.reduce((acc, sourcing) => {
			if (sourcing?.jobId
				&& !acc.jobs.find((e) => documentId(e) === sourcing?.jobId)) {
				acc.jobs.push(sourcing?.job);
				const companyId = documentId(sourcing?.job?.company);
				acc.jobsByCompany[companyId] = [...(acc.jobsByCompany?.[companyId] || []), sourcing?.job];
			}
			if (sourcing?.creatorId
				&& !acc.creators.find((e) => documentId(e) === sourcing?.creatorId)) {
				acc.creators.push(sourcing?.creator);
			}
			if (sourcing?.companyId
				&& !acc.companies.find((e) => documentId(e) === sourcing?.companyId)) {
				acc.companies.push(sourcing?.company);
			}
			return acc;
		}, {
			jobs: [],
			jobsByCompany: {},
			creators: [],
			companies: [],
		});
		setSuggestions(suggestedSourcings);
	}, [user]);
	if (!user?.suggestedSourcings?.length) return null;

	return (
		<>
			<Divider
				className='suggestions-divider'
				orientation='left'
				style={{
					marginTop: 0,
				}}
			>

				<Space
					style={{
						color: theme.color.darkturquoise,
						fontSize: 16,
						fontWeight: 'bold',
						marginTop: 5,
					}}
				>
					<Logo50inTech
						size={26}
						color={theme.color.darkturquoise}
					/>
					<span>Suggestions for you</span>
				</Space>
			</Divider>

			<Space
				style={{
					padding: '10px 40px 20px',
					fontSize: 14,
					rowGap: '2px !important',
					columnGap: '2px !important',
				}}
				wrap
			>
				<span>
					<UserAvatarList size={28} overflow={-9} users={suggestions?.creators} withCurrentUser={true}/>
				</span>
				{suggestions?.creators?.length === 1
					? <span>
						<b>
							{suggestions.creators[0]?.firstName}&nbsp;{suggestions.creators[0]?.lastName}
						</b> from
					</span>
					: <span>&nbsp;Some team members of </span>
				}
				<Logo50inTech
					size={58}
					height={22}
					color={theme.color.fitBlue}
					src={LOGO_50INTECH_FULL_WHITE_SVG}
					style={{
						marginBottom: -1,
						marginLeft: 2,
						marginRight: 2,
					}}
				/>

				<span>
					{suggestions?.creators.length === 1 ? 'suggests' : 'suggest'} <b style={{ marginLeft: 2 }}>{user?.firstName}</b>
				</span>

				{isStaffView && !companySelected
					? <>
						<span style={{ marginLeft: 2 }}>to</span>

						<Popover
							title={<span>{suggestions?.companies?.length} companies</span>}
							content={<>
								{suggestions?.companies
									?.map((c, i) => (
										<CompanyLogo
											key={i}
											size={22}
											company={c}
										/>
									))
								}
							</>}
						>
							<span style={{ marginLeft: 6 }}>
								<CompanyLogoList
									size={24}
									overflow={-9}
									companies={suggestions?.companies} />
							</span>
						</Popover>
					</>
					: <>
						<span style={{ marginLeft: 2 }}> as a 🔝 top candidate for {company?.name ? <b>{company?.name}</b> : 'your company'}</span>
					</>
				}
			</Space>

			{suggestions?.jobs?.length > 0 && <>
				<Row
					justify="start"
					style={{
						padding: '10px 40px 20px',
						width: '100%',
					}}
				>
					<div
						style={{
							width: '100%',
							fontSize: 12,
							color: theme.color.grey,
							marginBottom: 4,
						}}
					>
						Suggested jobs
					</div>

					{isUserGridCardSuggestedJobsInline && (
						<Space direction='vertical'>
							{Object
								.entries(suggestions?.jobsByCompany)
								.map(([companyId, jobs], index) => (
									jobs?.length > 0 && (
										<Space
											key={index}
											direction='horizontal'
											style={{
												padding: 2,
												fontSize: 12,
												marginRight: 25,
												rowGap: 14,
												columnGap: 14,
											}}
										>
											{isStaffView && <>

												<CompanyCaption
													company={jobs[0]?.company}
													size={16}
												/>
												<FaChevronRight style={{
													color: theme.color.lightgrey,
												}}/>
											</>
											}
											<Space>
												<UserGridCardListWithSeparator
													placement='right'
													defaultContainer='span'
													withSeparator={true}
												>
													{jobs?.map((job, i) => (
														<div key={i}>
															<Space

																direction='horizontal'
																size={3}
																style={{
																	fontWeight: 400,
																	fontSize: 12,
																	lineHeight: 1.2,
																	padding: 4,
																}}
															>
																<JobStub
																	job={job}
																	withCompany={false}
																	withLocation={true}
																	locationDisplay='inline'
																	withDropdown={false}
																	atsLogoPlacement='left'
																	style={{
																		title: {
																			fontWeight: 400,
																			fontSize: 12,
																			lineHeight: 1,
																		},
																	}}
																/>

															</Space>
														</div>
													))}
												</UserGridCardListWithSeparator>
											</Space>
										</Space>
									)
								))}
						</Space>
					)}

					{!isUserGridCardSuggestedJobsInline && (
						<Space wrap={true}>
							{suggestions?.jobs
								?.map((job, i) => (
									<Box
										key={i}
										style={{ paddingBottom: 10 }}
									>
										<JobStub
											job={job}
											withCompany={isStaffView}
											withLocation={true}
											atsLogoPlacement='right'
											style={{
												maxWidth: 300,
												company: {
													marginBottom: -10,
												},
												title: {
													maxWidth: 280,
													textOverflow: 'ellipsis',
													overflow: 'hidden',
													whiteSpace: 'nowrap',
												},
												location: {
													marginTop: 4,
													maxWidth: 280,
													textOverflow: 'ellipsis',
													overflow: 'hidden',
													whiteSpace: 'nowrap',
												},
											}}
										/>
									</Box>
								))}
						</Space>
					)}

				</Row>
			</>
			}

		</>
	);
};

export default UserGridCardSuggested;
