import { onEnterBuilder } from '../../../actions/app';
import ProductJobsPage from '../../../containers/staff/product/ProductJobsPage';
import { PERMS_GRANT_STAFF } from '../../menu.utils';

const menu = {
	name: 'staff-product-jobs',
	path: '/staff/product/jobs',
	label: 'Jobs',
	icon: <span>💼</span>,
	component: ProductJobsPage,
	onEnter: onEnterBuilder({
		origin: ProductJobsPage.name,
	}),
	perms: PERMS_GRANT_STAFF,
};

export default menu;
