import PageLayout from '../../components/app/layout/PageLayout';
import { useEffect, useState } from 'react';
import { addMultipleQueryParams, getQueryParams, removeQueryParams } from '../../utils/common';
import SectionTitle from '../../components/app/SectionTitle';
import { FaEnvelope } from 'react-icons/fa';
import CampaignEmailTable from '../../components/staff/marketing/campaignEmails/CampaignEmailTable';
import FilterButton from '../../components/app/filters/FilterButton';
import CampaignEmailFilterModal from '../../components/staff/marketing/campaignEmails/CampaignEmailFilterModal';

const CampaignEmails = () => {
	const queryParams = getQueryParams();

	const [optionsFilterTable, setOptionsFilterTable] = useState({});
	const [isRefreshDataLayout, setIsRefreshDataLayout] = useState(0);

	useEffect(() => {
		setOptionsFilterTable((prev) => ({ ...prev, ...queryParams }));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	// --------------------------------------- //
	// ------------ handle filter ------------ //
	// --------------------------------------- //
	const handleFilter = (values) => {
		const { tags, ...rest } = values;
		addMultipleQueryParams(rest);
		setOptionsFilterTable((prev) => {
			return ({ ...prev, ...rest, tags });
		});
	};

	const handleClearFilter = () => {
		setOptionsFilterTable({});
		setIsRefreshDataLayout((prev) => prev + 1);
		removeQueryParams();
	};

	return (
		<PageLayout
			title={<SectionTitle icon={<FaEnvelope/>} title="Campaign Emails" renderAlone={false}/>}
			filter={<FilterButton
				title="Filter campaign emails"
				modal={CampaignEmailFilterModal}
				onFilter={handleFilter}
				onClearFilter={handleClearFilter}
				initialValues={optionsFilterTable}
			/>}
		>
			<CampaignEmailTable
				filterOptions={optionsFilterTable}
				isRefresh={isRefreshDataLayout}
			/>
		</PageLayout>
	);
};

export default CampaignEmails;
