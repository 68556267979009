import GridValueCard from '../../../app/card/GridValueCard';
import UserResumeContactCardBody from './UserResumeContactCardBody';
import { NOT_PROVIDED_YET_LABEL } from '../../../../constants/constant';

const UserResumeContactCard = (
	{
		user,
		hideIfEmpty = true,
		notProvided = NOT_PROVIDED_YET_LABEL,
		children,
		...props
	},
) => {
	const title = 'Links';

	return ((
		user?.information?.website
		|| user?.hasResumeContact()
		|| user?.hasResumeFiles()
		|| !hideIfEmpty
	)
		? <GridValueCard
			title={title}
			{...props}
		>
			{children || <UserResumeContactCardBody user={user} notProvided={notProvided}/>}
		</GridValueCard>
		: null
	);
};

export default UserResumeContactCard;
