import { Button, Empty, Space } from 'antd';
import { FaCloud } from 'react-icons/fa';
import { useTheme } from '@emotion/react';
import Logo50inTech from './Logo50inTech';
import { LOGO_50INTECH_WHITE_SVG } from '../../constants/logos';

const EmptyData = ({
	description = '',
	button,
	...props
}) => {
	const theme = useTheme();
	return (
		<Empty
			image={(
				<Space direction="vertical" size={10} style={{ position: 'relative' }}>
					<FaCloud size={100} color='#ddd' style={{ position: 'relative' }}/>
					<Logo50inTech
						size={32}
						src={LOGO_50INTECH_WHITE_SVG}
						style={{
							position: 'absolute',
							top: 40,
							left: 'calc(50% - 16px)',
							height: 32,
						}}
					/>
				</Space>
			)}
			style={{
				border: '1px solid #eee',
				padding: '30px 20px',
				marginTop: 0,
				color: theme.color.grey,
				...props?.style,
			}}
			{...(description ? { description } : {})}

		>
			{button && (
				<Button
					type="primary"
					style={{
						marginTop: 10,
					}}
					onClick={button?.onClick}>
					{button?.text}
				</Button>
			)
			}
		</Empty>
	);
};

export default EmptyData;
