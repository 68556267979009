/** @jsxImportSource @emotion/react */

import FormInput from '../../../../form/FormInput';
import { useTheme } from '@emotion/react';

function FormItemSummary({
	label,
	name,
	onChange,
	...props
}) {
	const theme = useTheme();

	return (
		<div
			css={{
				textarea: {
					fontSize: 28,
					fontWeight: 600,
					lineHeight: 1.2,
					color: theme.color.blue,
					transition: ' all 0.2s ease-out',
				},
			}}
		>
			<FormInput
				label={label}
				name={name}
				style={{ minHeight: 60 }}
				extra = '~300 characters max'
				maxLength={300}
				onChange={(e) => onChange({ [name]: e?.target?.value || e })}
				isTextarea
			/>
		</div>
	);
}

export default FormItemSummary;
