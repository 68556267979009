import { Space } from 'antd';
import UserModel from '../../../../models/UserModel';
import UserFullPageIconButton from '../../widgets/UserFullPageIconButton';
import UserCopyPathIconButton from '../../widgets/UserCopyPathIconButton';
import BlockStaff from '../../../app/blocker/BlockStaff';
import UserStaffMenuButton from '../../widgets/UserStaffMenuBtn';

const UserGridCardTopButtons = (
	{
		user,
		onFullPageButtonClick,
		tooltipPlacement = 'topRight',
		...props
	},
) => {
	const className = 'UserGridCardTopButtons';

	const userModel = new UserModel(user);

	return (
		<>
			<Space
				size={10}
				style={{
					...props?.style,
				}}
			>

				<BlockStaff
					withBorder={false}
					withTagAsIcon={true}
				>
					<UserStaffMenuButton
						user={userModel}
						size={36}
						tooltipPlacement={tooltipPlacement}
					/>
				</BlockStaff>

				<UserCopyPathIconButton
					user={userModel}
					size={36}
					tooltipPlacement={tooltipPlacement}
				/>

				<UserFullPageIconButton
					user={userModel}
					size={36}
					tooltipPlacement={tooltipPlacement}
					onClick={onFullPageButtonClick}
				/>

			</Space>
		</>
	);
};

export default UserGridCardTopButtons;
