import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getCompanySelected } from '../reducers/app';
import moment from 'moment';
import UserModel from '../models/UserModel';
import UserService from '../services/user';
import { LocalDebug } from '../utils/LocalDebug';
import { documentId } from '../utils/common';
import { USERS_LOADED } from '../actions/types';

export const UserStoreContext = React.createContext();

const UserStoreProvider = ({
	children,
}) => {
	const className = 'UserStoreProvider';

	const dispatch = useDispatch();
	const companySelected = useSelector(getCompanySelected);

	const [loading, setLoading] = useState(true);
	const [users, setUsers] = useState(new Map());

	const fetchUser = async (userId) => {
		if (!userId) return null;
		LocalDebug.logNull({ className, method: 'fetchUser' }, { userId });
		let user = users.get(userId);
		LocalDebug.logNull({ className, method: 'fetchUser' }, { user });
		if (user?.isLoading) {
			LocalDebug.logNull({ className, method: 'fetchUser' }, 'user is loading, returning it');
			return user;
		}
		if (false && user?.fetchedAt && moment(user?.fetchedAt).isAfter(moment().add(-1, 'minute'))) {
			LocalDebug.logNull({ className, method: 'fetchUser' }, `user was fetched ${moment(user?.fetchedAt).fromNow()}, returning cached value`);
			return user;
		}
		user = new UserModel({
			...user,
			isLoading: true,
		});
		users.set(userId, user);

		const {
			data: { user: data },
		} = await UserService.getUserDigest(userId, {
			companyId: documentId(companySelected),
		});
		user = new UserModel({
			...data,
			isLoading: false,
			fetchedAt: new Date(),
		});
		users.set(
			userId,
			user,
		);
		setUsers(users);
		LocalDebug.logNull({ className, method: 'fetchUser' }, 'user fetched, returning it', { user });
		return user;
	};

	return (
		<UserStoreContext.Provider
			value={{
				users,
				fetchUser,
			}}
		>
			{children}
		</UserStoreContext.Provider>
	);
};

export default UserStoreProvider;
