import { ENGLISH_LANG } from '../../../../constants/constant';
import CompanyTextEditor from '../shared/CompanyTextEditor';

const CompanyOneLinerEditor = (
	{
		company,
		lang = ENGLISH_LANG,
		setEditorOpen,
	},
) => {
	const className = 'CompanyOneLinerEditor';

	const title = 'One-liner';
	const field = 'oneliner';

	return (
		<CompanyTextEditor
			company={company}
			lang={lang}
			title={<b>{title}</b>}
			details={<span>
				When writing your company’s one-liner, it's important to
				capture attention, create interest, and convey the core
				message succinctly
			</span>}
			field={field}
			setEditorOpen={setEditorOpen}
		/>
	);
};

export default CompanyOneLinerEditor;
