import { useTheme } from '@emotion/react';
import {
	Space, Tag, Tooltip, Typography,
} from 'antd';
import moment from 'moment';
import { Fragment } from 'react';
import { DATE_FORMAT } from '../../../constants/constant';

const DatePreview = (
	{
		date,
		withDate = true,
		withFromNow = true,
		withToolip = true,
		display = 'default',
		dateFormat = DATE_FORMAT,
		size = 3,
		direction = 'vertical',
		styles = {},
		noValue = 'N/A',
		tagProps,
	},
) => {
	const theme = useTheme();

	const Component = display === 'tag' ? Tag : Fragment;
	const TypographyComponent = display === 'default' ? Typography : 'span';

	const colors = moment(date).isAfter(moment())
		? { fromNow: '#dd5555', date: '#ff9999' }
		: { fromNow: theme.color.darkgrey, date: theme.color.grey };

	// return JSON.stringify(colors)
	const fromNowLabel = moment(date).fromNow();
	const dateLabel = moment(date).format(dateFormat);
	const dateFullLabel = moment(date).format(DATE_FORMAT);
	const content = (
		<Space direction={direction} size={size}>
			{date
				? (
					<Component {...tagProps}>
						{withFromNow && <Typography style={{
							fontSize: '11px',
							color: colors.fromNow,
							...styles?.fromNow,
						}}>
							{fromNowLabel}
						</Typography>}
						{withDate && <TypographyComponent style={{
							fontSize: '10px',
							color: colors.date,
							...styles?.date,
						}}>
							{dateLabel}
						</TypographyComponent>}
					</Component>
				)
				: <TypographyComponent style={{
					fontSize: '10px',
					color: theme.color.lightgrey,
					...styles?.noValue,
				}}>{noValue}</TypographyComponent>
			}
		</Space>
	);

	// return <Typography>{dateLabel}</Typography>;
	return withToolip
		? <Tooltip title={<><div><b>{dateFullLabel}</b></div><div>{fromNowLabel}</div></>}>{content}</Tooltip>
		: content;
};

export default DatePreview;
