import { Col, Row } from 'antd';
import AccountCard from './AccountCard';
import UserStub from '../user/stub/UserStub';
import Box from '../app/box/Box';
import AccountCredentialsForm from './AccountCredentialsForm';

const AccountCredentialsCard = (
	{
		user,
	},
) => {
	const className = 'AccountCredentialsCard';

	if (!user) return null;

	return (
		<AccountCard
			title={<>🔑 Your credentials</>}
			user={user}
			AccountForm={AccountCredentialsForm}
		>
			<Box style={{ marginTop: 8, padding: '12px 20px' }}>
				<Row gutter={[10, 6]}>
					<Col span={6}><b>Email</b></Col>
					<Col span={18}>{user.email}</Col>
					<Col span={6}><b>Password</b></Col>
					<Col span={18}>**********</Col>
				</Row>
			</Box>

		</AccountCard>
	);
};

export default AccountCredentialsCard;
