import {
	Button, List, Popover, Space, Tag,
} from 'antd';
import { useTheme } from '@emotion/react';
import { stringSorter } from '../../../../utils/common';
import Table from '../../../app/table/Table';
import ActionButton from '../../../app/table/ActionButton';
import { RiDeleteBinFill, RiEdit2Fill } from 'react-icons/ri';
import UserAvatar from '../../../user/widgets/UserAvatar';

import {
	CheckCircleOutlined,
	DatabaseOutlined,
	FilterOutlined,
} from '@ant-design/icons';
import TableColumns from '../../../app/table/TableColumns';

const TagRuleTable = ({
	onPublished, onEditTagRule, onDelete, isRefresh, filterOptions, isEditor,
}) => {
	const theme = useTheme();

	const columns = [
		{
			title: 'Nom de la règle',
			dataIndex: 'name',
			key: 'name',
			sorter: stringSorter('name'),
			fixed: 'left',
		},
		{
			title: 'Collection',
			dataIndex: 'documentRef',
			key: 'documentRef',
			sorter: stringSorter('name'),
		},
		{
			title: 'Règles',
			dataIndex: 'conditions',
			key: 'conditions',
			render: (value) => {
				const content = <List
					size="small"
					bordered
					dataSource={value}
					renderItem={(item) => <List.Item>
						<Tag icon={<DatabaseOutlined/>} color="success">
							{item.documentField}
						</Tag>
						<Tag icon={<FilterOutlined/>} color="warning">
							{item.filterType}
						</Tag>
						<Tag icon={<CheckCircleOutlined/>} color="processing">
							{item.value}
						</Tag>
					</List.Item>}
				/>;
				return (
					<Popover content={content} title="Conditions">
						<Button
							type="primary"
							shape="circle"
							style={{ background: theme.color.red, borderColor: theme.color.red }}
						>
							{value.length}
						</Button>
					</Popover>
				);
			},
		},
		TableColumns.tagListColumn(theme, { dataIndex: 'tagSlugs' }),
		TableColumns.createdAtColumn(theme),
		{
			title: 'Created by',
			dataIndex: 'createdUser',
			key: 'createdUser',
			render: (value) => <Space style={{ display: 'flex', justifyContent: 'center' }}><UserAvatar user={value}
				title={`${value?.firstName} ${value?.lastName}`}/></Space>,

		},
		TableColumns.updatedAtColumn(theme),
		{
			title: 'Updated by',
			dataIndex: 'updatedUser',
			key: 'updatedUser',
			render: (value) => <Space style={{ display: 'flex', justifyContent: 'center' }}><UserAvatar user={value}
				title={`${value?.firstName} ${value?.lastName}`}/></Space>,
		},
		TableColumns.booleanColumn(theme, { title: 'Published', dataIndex: 'isPublished' }),
		{
			title: 'actions',
			dataIndex: 'id',
			key: 'id',
			render: (value, row) => {
				return (
					isEditor && <Space direction="horizontal" size={4}>
						<ActionButton
							tooltipTitle="Edit tagrule"
							onClick={() => onEditTagRule(row)}
							icon={<RiEdit2Fill/>}
						/>
						<ActionButton
							tooltipTitle="Delete row"
							icon={<RiDeleteBinFill/>}
							styles={{ color: theme.color.orange }}
							title="Are you sure you want to delete this row？"
							okText="Yes" cancelText="No"
							onConfirm={() => onDelete(value)}
							withConfirm
						/>
					</Space>
				);
			},
		},
	];
	return (
		<>
			<Table
				path="/tagRule"
				columns={columns}
				isRefresh={isRefresh}
				filterOptions={filterOptions}
				withDynamicSearch
			/>
		</>
	);
};

export default TagRuleTable;
