import { Space } from 'antd';
import { FaBan, FaCheck } from 'react-icons/fa';
import BlockStaff from '../../app/blocker/BlockStaff';
import CompanySettingsCard, { CompanySettingsCardLineOk } from './CompanySettingsCard';
import CompanySettingsCompanyPlanForm from './CompanySettingsCompanyPlanForm';
import { useTheme } from '@emotion/react';
import CompanyModel from '../../../models/CompanyModel';
import CompanyPlanTag from '../CompanyPlanTag';

const CompanySettingsCompanyPlanCard = (
	{
		company,
		...props
	},
) => {
	const className = 'CompanySettingsCompanyPlanCard';
	const theme = useTheme();

	const companyModel = new CompanyModel(company);

	return (
		<div style={{ position: 'relative' }}>
			<BlockStaff withBorder={false} tagStyle={{ top: 2, left: 24, opacity: 1 }}>
				<CompanySettingsCard
					title={<>✍️ Plan</>}
					details={<>
						Which paid plan is set for this company
					</>}
					company={company}
					CompanySettingsForm={CompanySettingsCompanyPlanForm}
				>
					<Space direction='vertical'>

						<CompanySettingsCardLineOk
							ok={companyModel?.getCompanyPlanValue({ withLegacyDefault: false })}
							okText={<>
								<b>{company?.name}</b> is under the plan
								&nbsp;<CompanyPlanTag company={companyModel} />
							</>}
							koText={<>
								<b>No plan</b> set for <b>{company?.name}</b>,
								&nbsp;it will be considered on <CompanyPlanTag />plan
							</>}
						/>

					</Space>

				</CompanySettingsCard>

			</BlockStaff>
		</div>
	);
};

export default CompanySettingsCompanyPlanCard;
