import { useAuthContext } from '../../../hooks/useAuthContext';
import PageLayout from '../../../components/app/layout/PageLayout';
import StaffGSBadgeMakerMenu from '../../../menu/staff/genderscore/StaffGSBadgeMakerMenu';
import StaffGenderScoreBadgeMaker from './StaffGenderScoreBadgeMaker';

const StaffGenderScoreBadgeMakerPage = () => {
	const className = 'StaffGenderScoreBadgeMakerPage';

	const { isStaff } = useAuthContext();

	if (!isStaff) return null;

	return (
		<PageLayout
			MenuClass={StaffGSBadgeMakerMenu}
		>

			<StaffGenderScoreBadgeMaker />

		</PageLayout>
	);
};

export default StaffGenderScoreBadgeMakerPage;
