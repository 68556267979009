import {
	cssLinearGradient, documentId,
} from '../../../../utils/common';
import { useTheme } from '@emotion/react';
import UserService from '../../../../services/user';
import UserGridCardLayout from './UserGridCardLayout';
import UserGridCardLeftColumn from './UserGridCardLeftColumn';
import UserGridCardAlert from './UserGridCardAlert';
import { StaffToolsContext } from '../../../../contexts/StaffToolsProvider';
import { useContext, useEffect, useState } from 'react';
import { UserStoreContext } from '../../../../contexts/UserStoreProvider';
import UserModel from '../../../../models/UserModel';
import UserModalCardRightColumn from './UserModalCardRightColumn';

const UserModalCard = (
	{
		user,
		onUserViewed,
		loading,
		onRefresh,
		children,
		withSuggested,
		...props
	},
) => {
	const className = 'UserModalCard';

	const theme = useTheme();
	const { isSavedSearchExcludeViewedEnabled } = useContext(StaffToolsContext);
	const { fetchUser } = useContext(UserStoreContext);
	const [userModel, setUserModel] = useState(new UserModel(user));

	useEffect(() => {
		if (user) {
			setUserModel(new UserModel(user));
		} else {
			setUserModel(null);
		}
	}, [user]);

	if (!user) return null;

	return (
		<UserGridCardLayout
			leftColumn={(
				<UserGridCardLeftColumn
					user={userModel}
				/>
			)}
			rightColumn={(
				<UserModalCardRightColumn
					user={userModel}
					style={{
						topButtons: {
							display: 'flex',
							marginTop: -10,
							marginRight: 80,
						},
					}}
					withSuggested={withSuggested}
				/>
			)}
			{...props}
		>
			{isSavedSearchExcludeViewedEnabled && (
				<UserGridCardAlert user={userModel} />
			)}
		</UserGridCardLayout>
	);
};

export default UserModalCard;
