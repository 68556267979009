import { useTheme } from '@emotion/react';
import { FaBan, FaCheck, FaClock } from 'react-icons/fa';
import moment from 'moment';
import { Space } from 'antd';
import CompanyModel from '../../models/CompanyModel';
import { useContext } from 'react';
import { CompanyPageContext, LANG_TEXTS } from './companyPage/shared/CompanyPageProvider';
import { ENGLISH_LANG } from '../../constants/constant';

const CompanyPublicationWithTranslationStatus = (
	{
		company,
	},
) => {
	const theme = useTheme();

	const { lang, langTexts } = useContext(CompanyPageContext);

	const companyModel = new CompanyModel(company);

	const companyPagePrefix = 'Your company page ';
	let icon = <FaBan style={{ marginBottom: -2, color: theme.color.red }} />;
	let label = <span>{companyPagePrefix} is <b>not published</b></span>;

	let translationLabel = '';
	if (company.isTranslatable) {
		translationLabel = LANG_TEXTS[ENGLISH_LANG].labelIn;
		if (company.isTranslationPublished) {
			translationLabel = LANG_TEXTS[ENGLISH_LANG].labelInBoth;
		}
	}

	if (companyModel.isPublishedNow()) {
		icon = <FaCheck style={{ marginBottom: -2, color: theme.color.darkturquoise }} />;
		label = <span>{companyPagePrefix} is <b>published{translationLabel}</b></span>;
	} else if (companyModel.isPublishedFuture()) {
		icon = <FaClock style={{ marginBottom: -2, color: theme.color.fitBlueElectric }} />;
		label = <span>
			{companyPagePrefix} publication{translationLabel}
			is <b>programmed {moment(company.publishedAt).fromNow()}</b> ({moment(company.publishedAt).format('MMM Do, HH:mm')})</span>;
	}

	return (
		<Space align='top'>
			{icon}
			{label}
		</Space>
	);
};
export default CompanyPublicationWithTranslationStatus;
