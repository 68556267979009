import { Space, Tag, Tooltip } from 'antd';
import { useTheme } from '@emotion/react';
import { useState } from 'react';
import moment from 'moment';
import Table from '../../../app/table/Table';
import TableColumns from '../../../app/table/TableColumns';
import JobStub from '../../../jobs/JobStub';
import { COMPANY_RECRUITER_LOG_ACTIONS } from '../../../../constants/company-recruiter-log';
import { mixColors } from '../../../../utils/common';
import JSONPretty from 'react-json-pretty';
import { useAuthContext } from '../../../../hooks/useAuthContext';

const RecruiterActivityTable = (
	{
		onDataLoaded,
		path = '/logging/recruiter',
		filterOptions = {},
		isRefresh = 0,
		...props
	},
) => {
	const className = 'RecruiterActivityTable';

	const theme = useTheme();

	const { isAdmin, isStaffView } = useAuthContext();

	const [isRefreshDataLayout] = useState(0);

	const recruiterCol = TableColumns.userStubColumn(theme, {
		title: 'Recruiter',
		sorter: false,
		withLocation: false,
		withPreview: false,
		withIsUnviewed: false,
		withIsNew: false,
	});
	const companyLogoCol = TableColumns.companyLogoColumn(theme, { title: 'Company', dataIndex: 'company' });
	const logActionCol = {
		title: 'Action',
		dataIndex: 'action',
		render: (value) => {
			const { label, color } = (COMPANY_RECRUITER_LOG_ACTIONS.find((log) => log.value === value) || {});
			return (
				<Tag color={color}>{label}</Tag>
			);
		},
		...TableColumns.columnMinWidth(100),
	};

	const userTargetCol = TableColumns.userStubColumn(theme, {
		// removeInColumnFilter: true,
		dataIndex: 'userTarget',
		title: 'Talent',
		activeTab: 'info',
		withDropdown: true,
		customOptions: {
			searchFields: [
				{ label: 'First name', value: 'firstName' },
				{ label: 'Last name', value: 'lastName' },
				{ label: 'Position', value: 'information.position' },
				{ label: 'Company', value: 'information.company' },
			],
		},
	});

	const sourcingCol = {
		title: 'Sourcing',
		removeInColumnFilter: true,
		render: (_, row) => (
			<JobStub
				job={row?.sourcingTarget?.job}
				jobActiveTab="sourcings"
			/>
		),
		...TableColumns.columnMinWidth(150),
	};

	const createdAtCol = TableColumns.createdAtColumn(theme);
	const updatedAtCol = TableColumns.updatedAtColumn(theme);

	const versionCol = {
		title: 'Version',
		dataIndex: 'version',
		align: 'center',
		sorter: false,
		removeInColumnFilter: true,
		render: (v) => <code style={{ fontSize: 10 }}>{v}</code>,
		...TableColumns.columnFixedWidth(70),
	};

	const actionsCol = {
		title: 'Actions',
		dataIndex: 'actions',
		render: (v) => <JSONPretty data={v} style={{ fontSize: 8 }}/>,
		...TableColumns.columnFixedWidth(120),
	};

	const logsCol = {
		title: 'Logs',
		render: (value, row) => {
			const logDates = row.logDates.length;
			const log30Days = row.log30Days.length;
			const { fromFirstLog } = row;
			const firstLogAt = moment(row.firstLogAt);
			const lastLogAt = moment(row.lastLogAt);
			const first30DaysLog = moment(Math.max(row.firstLogAt, moment().add(-30, 'days').toDate()));
			const from30DaysLog = Math.round(moment.duration(moment().startOf('day').diff(first30DaysLog.clone().startOf('day'))).asDays());
			return (
				<Space direction='vertical' size={0}>
					<span>Global presence: <b>{Math.round(100 * logDates / fromFirstLog)}%</b> ({logDates}/{fromFirstLog})</span>
					<span>Last 30 days: <b>{Math.round(100 * log30Days / from30DaysLog)}%</b> ({log30Days}/{from30DaysLog})</span>
					<span>Last seen: {lastLogAt.format('D MMM \'YY')} ({lastLogAt.fromNow()})</span>
					<span>First seen: {firstLogAt.format('D MMM \'YY')} ({firstLogAt.fromNow()})</span>
				</Space>
			);
		},
		...TableColumns.columnFixedWidth(240),
	};

	const buildGradientBox = (label, pctValue, color1 = theme.color.red, color2 = theme.color.darkturquoise) => {
		const background = mixColors(color1, color2, pctValue);
		return (
			<span style={{
				fontSize: 18,
				fontWeight: 'bold',
				padding: '2px 6px',
				borderRadius: 5,
				color: 'white',
				background,
			}}>
				{label}
			</span>
		);
	};

	const buildRateCell = (value, total, decimals = 0) => {
		const rate = 100 * Math.round(100 * 10 ** decimals * value / total) / 100;
		return (
			<Space direction='vertical' size={0}>
				{buildGradientBox(`${rate}%`, rate)}
				<span style={{ fontSize: 10, fontStyle: 'italic', color: theme.color.grey }}>{value} / {total}</span>
			</Space>
		);
	};

	const presenceGlobalCol = {
		title: <>Global<br/>Presence</>,
		align: 'center',
		removeInColumnFilter: true,
		sorter: false,
		render: (value, row) => {
			const logDates = row.logDates.length;
			const { fromFirstLog } = row;
			const firstLogAt = moment(row.firstLogAt);
			const lastLogAt = moment(row.lastLogAt);
			return buildRateCell(logDates, fromFirstLog);
		},
		...TableColumns.columnFixedWidth(90),
	};

	const presence30DaysCol = {
		title: <>Last 30d<br/>Presence</>,
		align: 'center',
		removeInColumnFilter: true,
		sorter: false,
		render: (value, row) => {
			const log30Days = row.log30Days.length;
			const first30DaysLog = moment(Math.max(row.firstLogAt, moment().add(-30, 'days').toDate()));
			const from30DaysLog = Math.round(moment.duration(moment().startOf('day').diff(first30DaysLog.clone().startOf('day'))).asDays());
			// const rate = Math.round(100 * log30Days / from30DaysLog);
			return buildRateCell(log30Days, from30DaysLog);
		},
		...TableColumns.columnFixedWidth(90),
	};

	const actionsCols = COMPANY_RECRUITER_LOG_ACTIONS.map(
		({
			value: action, label, title, icon, color,
		}) => (
			TableColumns.docsCountColumn(theme, {
				title: <Tooltip title={title}><span style={{ whiteSpace: 'wrap' }}>{label}</span></Tooltip>,
				dataIndex: ['actions', action],
				sorter: false,
				removeInColumnFilter: true,
				// style: { background: color },
				style: { background: 'none', color: 'black' },
				icon,
				// ...TableColumns.columnFixedWidth(50),
				// render: (value, row) => row.
			})
		),
	);

	const lastSeenCol = {
		title: 'Last visit',
		align: 'center',
		sorter: false,
		removeInColumnFilter: true,
		render: (value, row) => {
			const display = row?.fromLastLog === 0
				? 'Today'
				: row?.fromLastLog < 30
					? `${row?.fromLastLog} day${row?.fromLastLog > 0 ? 's' : ''}`
					: '+30 days';

			return (
				<Space direction='vertical' size={0}>
					{buildGradientBox(<span
						style={{ whiteSpace: 'nowrap' }}>{display}</span>, 100 * (30 - Math.min(30, row?.fromLastLog)) / 30)}
					<span style={{
						fontSize: 10, color: theme.color.grey,
					}}>{moment(row.lastLogAt).format('D MMM \'YY, HH:mm')}</span>
				</Space>
			);
		},
		...TableColumns.columnMinWidth(100),
	};

	const columns = [
		TableColumns.jsonColumn(theme),
		companyLogoCol,
		recruiterCol,
		lastSeenCol,
		// actionsCol,
		presenceGlobalCol,
		presence30DaysCol,
		versionCol,
		...isAdmin && isStaffView ? [logsCol] : [],
		...actionsCols,
	];

	return (
		<>
			<Table
				path={path}
				onDataLoaded={onDataLoaded}
				columns={columns}
				filterOptions={filterOptions}
				isRefresh={isRefresh + isRefreshDataLayout}
				showTotal={(total) => `Total ${total} items`}
				limit={10}
				rowClassName={(row, index) => (row.is50Content === false ? 'rowDisabled' : '')}
				withDynamicSearch
				{...props}
			/>
		</>
	);
};

export default RecruiterActivityTable;
