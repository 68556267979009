import {
	InputNumber, Space, Switch,
} from 'antd';
import { useDispatch } from 'react-redux';
import { FaSearch, FaSync } from 'react-icons/fa';
import { useAuthContext } from '../../../hooks/useAuthContext';
import * as adminActions from '../../../actions/admin';
import { round } from '../../../utils/common';
import StaffCommandButton from './StaffCommandButton';
import AdminToolsSectionContainer from './AdminToolsSectionContainer';
import { useContext, useState } from 'react';
import { StaffToolsContext } from '../../../contexts/StaffToolsProvider';
import AdminCommandGetButton from './AdminCommandGetButton';
import Box from '../../app/box/Box';
import { CloseOutlined, DeleteOutlined } from '@ant-design/icons';

export const Add50inTechSavedSearchesButton = (
	{
		title = 'Add 50inTech saved searches',
		Icon = FaSearch,
	},
) => (
	<StaffCommandButton
		title={title}
		Icon={Icon}
		dispatcher={adminActions.add50inTechSavedSearches}
	/>
);

export const SavedSearchesTabsSwitch = (
	{
		title = 'Display Suggested for you and Hidden tabs',
		checkedChildren = <b>Enabled</b>,
		unCheckedChildren = <b>Disabled</b>,
	},
) => {
	const {
		isSavedSearchTabsEnabled, setIsSavedSearchTabsEnabled,
	} = useContext(StaffToolsContext);

	return (
		<Space>
			{title}
			<Switch
				className='ant-switch-large'
				checked={!!isSavedSearchTabsEnabled}
				checkedChildren={checkedChildren}
				unCheckedChildren={unCheckedChildren}
				onChange={() => setIsSavedSearchTabsEnabled(!isSavedSearchTabsEnabled)}
			/>
		</Space>
	);
};

export const SavedSearchesViewedSwitch = (
	{
		title = 'Toggle Exclude viewed profiles',
		checkedChildren = <b>Enabled</b>,
		unCheckedChildren = <b>Disabled</b>,
	},
) => {
	const {
		isSavedSearchExcludeViewedEnabled, setIsSavedSearchExcludeViewedEnabled,
	} = useContext(StaffToolsContext);

	return (
		<Space>
			{title}
			<Switch
				className='ant-switch-large'
				checked={!!isSavedSearchExcludeViewedEnabled}
				checkedChildren={checkedChildren}
				unCheckedChildren={unCheckedChildren}
				onChange={() => setIsSavedSearchExcludeViewedEnabled(!isSavedSearchExcludeViewedEnabled)}
			/>
		</Space>
	);
};

export const SavedSearchesWithJobSwitch = (
	{
		title = 'Saved search from job',
		checkedChildren = <b>Enabled</b>,
		unCheckedChildren = <b>Disabled</b>,
	},
) => {
	const {
		savedSearchWithJobEnabled, setSavedSearchWithJobEnabled,
	} = useContext(StaffToolsContext);

	return (
		<Space>
			{title}
			<Switch
				className='ant-switch-large'
				checked={!!savedSearchWithJobEnabled}
				checkedChildren={checkedChildren}
				unCheckedChildren={unCheckedChildren}
				onChange={() => setSavedSearchWithJobEnabled(!savedSearchWithJobEnabled)}
			/>
		</Space>
	);
};

export const ComputeSavedSearchesAlertsButton = (
	{
		title = 'Update all alerts',
		Icon = FaSync,
		style,
	},
) => (
	<StaffCommandButton
		title={title}
		Icon={Icon}
		dispatcher={adminActions.computeSavedSearchesAlerts}
		style={style}
	/>
);

const AdminToolsUMSSection = () => {
	const dispatch = useDispatch();

	const {
		isAdmin, currentUser,
	} = useAuthContext();

	const [params, setParams] = useState({
		completionRate: 85,
		withResetUsers: false,
		withResetSurveys: true,
		withCreateUsers: true,
		withCreateSurveys: true,
		minAdmins: 1,
		maxAdmins: 1,
		minManagers: 8,
		maxManagers: 16,
		minSubManagers: 1,
		maxSubManagers: 2,
		minEmployees: 5,
		maxEmployees: 20,
	});

	if (!currentUser) return null;
	if (!isAdmin) return null;

	return (
		<Space direction='vertical' size={20}>
			<AdminToolsSectionContainer title='DemoUMS Company'>

				<Space size={30} align='top'>

					<AdminCommandGetButton
						title={'Refresh DemoUMS company'}
						path={`/ums/demo?${Object.entries(params).map(([k, v]) => `${k}=${v}`).join('&')}`}
					/>

					<Space direction='vertical'>
						<Box>
							<Space direction='vertical' size={4}>
								<Space>
									<span style={{ color: 'red' }}>
										<b><DeleteOutlined /> Reset surveys/results</b>
									</span>
									<Switch
										className='ant-switch-orange'
										checked={params?.withResetSurveys}
										onChange={(value) => setParams((p) => ({ ...p, withResetSurveys: value }))}
									/>
								</Space>
								<Space>
									<span><b>Create surveys/results</b></span>
									<Switch
										checked={params?.withCreateSurveys}
										onChange={(value) => setParams((p) => ({ ...p, withCreateSurveys: value }))}
									/>
								</Space>
								<Space style={{ opacity: params.withCreateSurveys ? 1 : 0.6 }}>
									<span><b>Completion</b> rate:</span>
									<InputNumber
										value={params?.completionRate}
										min={0}
										disabled={!params?.withCreateSurveys}
										max={100}
										step={1}
										onChange={(value) => setParams((p) => ({ ...p, completionRate: value }))}
									/>
								</Space>

							</Space>
						</Box>

						<Box>
							<Space direction='vertical' size={4}>
								<Space>
									<span style={{ color: 'red' }}>
										<b><DeleteOutlined /> Reset users</b>
									</span>
									<Switch
										className='ant-switch-orange'
										checked={params?.withResetUsers}
										onChange={(value) => setParams((p) => ({ ...p, withResetUsers: value }))}
									/>
								</Space>
								<Space>
									<span><b>Create users</b></span>
									<Switch
										checked={params?.withCreateUsers}
										onChange={(value) => setParams((p) => ({ ...p, withCreateUsers: value }))}
									/>
								</Space>
								<Space style={{ opacity: params.withCreateUsers ? 1 : 0.6 }}>
									<span><b>Admins</b> between</span>
									<InputNumber
										value={params?.minAdmins}
										min={1}
										step={1}
										disabled={!params.withCreateUsers}
										onChange={(value) => setParams((p) => ({
											...p,
											minAdmins: value,
											maxAdmins: Math.max(params?.maxAdmins, value),
										}))}
									/>
									<span>and</span>
									<InputNumber
										value={params?.maxAdmins}
										min={params?.minAdmins}
										step={1}
										disabled={!params.withCreateUsers}
										onChange={(value) => setParams((p) => ({
											...p,
											maxAdmins: Math.max(params?.minAdmins, value),
										}))}
									/>
								</Space>
								<Space style={{ opacity: params.withCreateUsers ? 1 : 0.6 }}>
									<span><b>Managers</b> between</span>
									<InputNumber
										value={params?.minManagers}
										min={1}
										step={1}
										disabled={!params.withCreateUsers}
										onChange={(value) => setParams((p) => ({
											...p,
											minManagers: value,
											maxManagers: Math.max(params?.maxManagers, value),
										}))}
									/>
									<span>and</span>
									<InputNumber
										value={params?.maxManagers}
										min={params?.minManagers}
										step={1}
										disabled={!params.withCreateUsers}
										onChange={(value) => setParams((p) => ({
											...p,
											maxManagers: Math.max(params?.minManagers, value),
										}))}
									/>
								</Space>
								<Space style={{ opacity: params.withCreateUsers ? 1 : 0.6 }}>
									<span><b>Sub Managers</b> between</span>
									<InputNumber
										value={params?.minSubManagers}
										min={1}
										step={1}
										disabled={!params.withCreateUsers}
										onChange={(value) => setParams((p) => ({
											...p,
											minSubManagers: value,
											maxSubManagers: Math.max(params?.maxSubManagers, value),
										}))}
									/>
									<span>and</span>
									<InputNumber
										value={params?.maxSubManagers}
										min={params?.minSubManagers}
										step={1}
										disabled={!params.withCreateUsers}
										onChange={(value) => setParams((p) => ({
											...p,
											maxSubManagers: Math.max(params?.minSubManagers, value),
										}))}
									/>
								</Space>
								<Space style={{ opacity: params.withCreateUsers ? 1 : 0.6 }}>
									<span><b>Employees</b> between</span>
									<InputNumber
										value={params?.minEmployees}
										min={1}
										step={1}
										disabled={!params.withCreateUsers}
										onChange={(value) => setParams((p) => ({
											...p,
											minEmployees: value,
											maxEmployees: Math.max(params?.maxEmployees, value),
										}))}
									/>
									<span>and</span>
									<InputNumber
										value={params?.maxEmployees}
										min={params?.minEmployees}
										step={1}
										disabled={!params.withCreateUsers}
										onChange={(value) => setParams((p) => ({
											...p,
											maxEmployees: Math.max(params?.minEmployees, value),
										}))}
									/>
								</Space>
							</Space>
						</Box>
					</Space>
				</Space>
			</AdminToolsSectionContainer>
		</Space>
	);
};

export default AdminToolsUMSSection;
