import { useContext, useEffect, useState } from 'react';
import CsmSignature from '../../../onboarding/shared/CsmSignature';
import CompanyFieldEditor from './CompanyFieldEditor';
import { CompanyContext } from '../../../../contexts/CompanyProvider';

const CompanyFieldEditorLocked = (
	{
		company,
		setEditorOpen,
		title = <><span>🔒</span> Content locked</>,
		children,
	},
) => {
	const { csmDetails } = useContext(CompanyContext);

	return (
		<CompanyFieldEditor
			company={company}
			setEditorOpen={setEditorOpen}
			title={title}
			isTranslatable={false}
		>
			<h4>Sorry, you're not allowed to edit this field!</h4>

			<CsmSignature data={csmDetails} />
			{children}
		</CompanyFieldEditor>
	);
};

export default CompanyFieldEditorLocked;
