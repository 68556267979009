import { Skeleton } from 'antd';
import UserGridCardLayout from './UserGridCardLayout';

const UserGridCardSkeleton = () => {
	const className = 'UserGridCardSkeleton';

	return (
		<UserGridCardLayout

			leftColumn={
				<>
					<Skeleton.Avatar
						active
						size={120}
						style={{ marginTop: 10 }}
					/>

					<Skeleton.Input
						active
						style={{ width: 130, borderRadius: 6, marginTop: 32 }}
					/>
				</>
			}

			rightColumn={
				<div style={{ padding: 40 }}>
					<Skeleton.Input
						active
						style={{ width: 220, borderRadius: 6 }}
						// paragraph={{ rows: 2 }}
					/>

					<Skeleton
						active
						paragraph={{ rows: 6 }}
						style={{ width: '80%', marginTop: 32 }}
					/>
				</div>
			}
		/>
	);
};

export default UserGridCardSkeleton;
