import { Space } from 'antd';
import { useTheme } from '@emotion/react';
import AtsLogo from '../../ats/AtsLogo';
import { atsProviderOptions } from '../../../constants/constant';
import CompanyCaption from '../CompanyCaption';
import CompanyAccessAccessTypeTag from './CompanyAccessAccessTypeTag';

const CompanyAccessStub = (
	{
		companyAccess,
		size = 24,
		withCompany = false,
		withLink = true,
		withTooltip = true,
		withValue = true,
		...props
	},
) => {
	const theme = useTheme();

	if (!companyAccess) {
		return (
			<span
				style={{
					color: theme.color.lightgrey,
				}}>
			N/A
			</span>
		);
	}

	return (
		<div
			style={{
				padding: '8px 14px',
				borderRadius: 5,
				border: `1px solid ${theme.color.lightgrey}`,
				background: theme.color.bgGrey,
				...props?.style,
			}}
		>
			<Space
				direction='vertical'
				size={4}
			>
				{withCompany
					&& companyAccess.company
					&& (
						<CompanyCaption company={companyAccess.company} />
					)
				}
				<Space
					direction='horizontal'
					size={4}
					align='center'
				>
					<AtsLogo
						style={{}}
						size={size}
						ats={companyAccess.ats}
					/>
					<div
						style={{ fontWeight: 700 }}
					>
						{atsProviderOptions.find((i) => i.value === companyAccess.ats)?.label}
					</div>
					<CompanyAccessAccessTypeTag
						companyAccess={companyAccess}
					/>
					{withValue && (
						<code style={{ fontSize: 12 }}>
							{companyAccess.value}
						</code>
					)}
				</Space>
			</Space>
		</div>
	);
};

export default CompanyAccessStub;
