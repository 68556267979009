import { getCompanySelected } from '../../reducers/app';
import { useSelector } from 'react-redux';
import PageLayout from '../../components/app/layout/PageLayout';
import CompanyGridPicker from '../../components/company/CompanyGridPicker';
import ReactJsonAdmin from '../../components/app/debug/ReactJsonAdmin';
import {
	Col, Divider, Row, Space,
} from 'antd';
import SectionTitle from '../../components/app/SectionTitle';
import { COMPANY_SETTINGS_DETAILS, COMPANY_SETTINGS_ICON, COMPANY_SETTINGS_TITLE } from '../../constants/constant';
import CompanySettingsContractCard from '../../components/company/settings/CompanySettingsContractCard';
import CompanySettingsCompanyPagePublicationCard
	from '../../components/company/settings/CompanySettingsCompanyPagePublicationCard';
import CompanySettingsJobsPublicationCard from '../../components/company/settings/CompanySettingsJobsPublicationCard';
import CompanySettingsTranslationCard
	from '../../components/company/settings/CompanySettingsTranslationCard';
import CompanySettingsNotificationsCard from '../../components/company/settings/CompanySettingsNotificationsCard';
import CompanySettingsBrowsingCard from '../../components/company/settings/CompanySettingsBrowsingCard';
import CompanySettingsChurnedAtCard from '../../components/company/settings/CompanySettingsChurnedAtCard';
import CompanySettingsSEOCard from '../../components/company/settings/CompanySettingsSEOCard';
import CompanySettingsCompanyPlanCard from '../../components/company/settings/CompanySettingsCompanyPlanCard';
import CompanySettingsGenderScoreCard from '../../components/company/settings/CompanySettingsGenderScoreCard';
import CompanySettingsUMSCard from '../../components/company/settings/CompanySettingsUMSCard';
import { useEffect, useState } from 'react';
import CompanyModel from '../../models/CompanyModel';
import ConfigureCompanySettingsMenu from '../../menu/configure/ConfigureCompanySettingsMenu';

const ConfigureCompanySettingsPage = () => {
	const className = 'ConfigureCompanySettingsPage';

	const companySelected = useSelector(getCompanySelected);

	const [leftColCards, setLeftColCards] = useState([]);
	const [rightColCards, setRightColCards] = useState([]);

	useEffect(() => {
		const companyModel = new CompanyModel(companySelected);
		if (companyModel?.isGenderScoreCompanyPlan()) {
			setLeftColCards([
				CompanySettingsChurnedAtCard,
				CompanySettingsCompanyPagePublicationCard,
				CompanySettingsTranslationCard,
				CompanySettingsSEOCard,
			]);
			setRightColCards([
				CompanySettingsCompanyPlanCard,
				CompanySettingsGenderScoreCard,
				CompanySettingsUMSCard,
			]);
		} else {
			setLeftColCards([
				CompanySettingsNotificationsCard,
				CompanySettingsCompanyPagePublicationCard,
				CompanySettingsJobsPublicationCard,
				CompanySettingsSEOCard,
				CompanySettingsChurnedAtCard,
			]);
			setRightColCards([
				CompanySettingsContractCard,
				CompanySettingsCompanyPlanCard,
				CompanySettingsGenderScoreCard,
				CompanySettingsUMSCard,
				CompanySettingsTranslationCard,
				CompanySettingsBrowsingCard,
			]);
		}
	}, [companySelected]);

	if (!companySelected) return <CompanyGridPicker />;

	const gap = 20;

	return (
		<PageLayout
			MenuClass={ConfigureCompanySettingsMenu}
			subtitle={COMPANY_SETTINGS_DETAILS}
		>
			<Row gutter={[gap, gap]} style={{ marginBottom: 20 }}>
				<Col span={12} gutter={[gap, gap]}>
					<Row gutter={[gap, gap]} >
						{leftColCards.map((CardComponent, index) => (
							<Col key={index} span={24}>
								<CardComponent key={index} company={companySelected} />
							</Col>
						))}
					</Row>
				</Col>
				<Col span={12}>
					<Row gutter={[gap, gap]} >
						{rightColCards.map((CardComponent, index) => (
							<Col key={index} span={24}>
								<CardComponent company={companySelected} />
							</Col>
						))}
					</Row>
				</Col>
			</Row>
		</PageLayout>
	);
};

export default ConfigureCompanySettingsPage;
