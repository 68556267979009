/* eslint-disable react-hooks/exhaustive-deps */
import { Button } from 'antd';
import { useTheme } from '@emotion/react';

const GridLoadMoreButton = (
	{
		title = 'Load more',
		disabled = false,
		onClick,
		...props
	},
) => {
	const className = 'GridLoadMoreButton';

	const theme = useTheme();

	return (
		<Button
			type='primary'
			onClick={onClick}
			size={'large'}
			disabled={disabled}
			style={{
				fontWeight: 'bold',
				// width: '100%',
				// height: 80,
				height: 60,
				borderRadius: 100,
				margin: '10px auto',
				padding: '10px 30px',
			}}
		>
			{title}
		</Button>
	);
};

export default GridLoadMoreButton;
