import { useTheme } from '@emotion/react';
import { Space, Typography } from 'antd';
import ArticleImage from './ArticleImage';
import config from '../../../../config/config';
import { FaExternalLinkAlt } from 'react-icons/fa';

const ArticleStub = ({ article, ...props }) => {
	const theme = useTheme();
	const { translations } = article;
	const flags = { en: '🇬🇧', fr: '🇫🇷' };

	const link = (lang) => <a
		href={`${config.appRoot}/${lang}/article/${article?.slug}`}
		target='_blank'
		rel='noreferrer'
		className='link'
		style={{ fontSize: 12, lineHeight: 1.3 }}
	>
		<FaExternalLinkAlt/>
	</a>;

	const titleTranslationWithLink = (title, lang = 'en') => {
		return <Typography style={{
			color: theme.color.black,
			lineHeight: 1.3,
			fontWeight: 'bold',
			fontSize: '14px',
			...props?.style,
		}}>
			{link(lang)} {flags?.[lang]} {title}
		</Typography>;
	};

	return (
		<Space direction="horizontal" size={12} style={{ alignItems: 'flex-start' }}>
			<ArticleImage article={article} size={200}/>
			<Space direction="vertical">
				{titleTranslationWithLink(article.title)}
				{/* {JSONPretty(article?.translations)} */}
				{/* {article?.translations && */}
				{/* Object.entries(article?.translations) */}
				{/*    .map(([lang, values], index) => <div key={lang}>{lang}</div>) */}
				{/*    .filter(i => i) */}
				{/* } */}
				{article?.translations && Object.entries(article?.translations)
					.map(([lang, values], index) => values?.title
						&& <div key={lang}>{titleTranslationWithLink(values?.title, lang)}</div>)
					.filter((i) => i)
				}
				{/* <Typography style={{ */}
				{/*        color: theme.color.black, */}
				{/*        lineHeight: 1.3, */}
				{/*        fontWeight: 'bold', */}
				{/*        fontSize: '14px', */}
				{/*        ...props?.style, */}
				{/*    }}> */}
				{/*    {link('en')} 🇬🇧 {article.title} */}
				{/* </Typography> */}
				{/* { translations?.hasOwnProperty(langFr) && translations[langFr]['title'] && <Typography style={{ */}
				{/*        color: theme.color.black, */}
				{/*        lineHeight: 1.3, */}
				{/*        fontWeight: 'bold', */}
				{/*        fontSize: '14px', */}
				{/*        ...props?.style, */}
				{/*    }}> */}
				{/*    {link('fr')} 🇫🇷 {translations[langFr]['title']} */}
				{/* </Typography> */}
				{/* } */}
			</Space>
		</Space>
	);
};

export default ArticleStub;
