import * as dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import {
	Col, Popover, Row, Space, Spin, Tooltip,
} from 'antd';
import { css } from '@emotion/css';
import { useTheme } from '@emotion/react';
import { atsProviderOptions } from '../../../constants/constant';
import ActionButton from '../../app/table/ActionButton';
import { RiDeleteBinFill, RiEdit2Fill } from 'react-icons/ri';
import CompanyBatchCard from '../batch/CompanyBatchCard';
import TableColumns from '../../app/table/TableColumns';
import {
	FaBan,
	FaCog,
	FaDownload,
	FaHourglassHalf, FaSyncAlt,
	FaTags,
	FaWifi,
} from 'react-icons/fa';
import Table from '../../app/table/Table';
import CompanyAccessAccessTypeTag from './CompanyAccessAccessTypeTag';
import CompanyAccessAtsProviderTag from './CompanyAccessAtsProviderTag';
import AtsLogo from '../../ats/AtsLogo';
import { documentId, findAtsProvider, relabelAtsProvider } from '../../../utils/common';
import AdminCommandGetButton from '../../staff/tools/AdminCommandGetButton';

dayjs.extend(duration);

const atsByValue = new Map();
atsProviderOptions.forEach((ats) => atsByValue.set(ats.value, ats.label));

const CompanyAccessTable = (
	{
		onEditAccess,
		onToggleActive,
		onFetchJob,
		onDelete,
		isEditor,
		filterOptions,
		onDataLoaded,
		isRefresh = 0,
		defaultData,
	},
) => {
	const theme = useTheme();

	const toggleIsActive = (row) => {
		onToggleActive(documentId(row), !row.isActive);
	};

	const companyStubCol = TableColumns.companyStubColumn(theme, {
		fixed: 'left',
		render: (value, row) => <Row>
			<Col span={20}>
				{TableColumns.companyStubColumn(theme).render(value, row)}
			</Col>
			<Col span={4} align='center'
				style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
				{isEditor && row.loading
					? <Spin />
					: <ActionButton
						tooltipTitle='Fetch Jobs from ATS'
						placement='top'
						styles={{ borderRadius: 4, color: theme.color.blue }}
						onClick={() => {
							row.loading = true;
							onFetchJob(row);
						}}
						icon={<FaDownload />}
					/>}
			</Col>
		</Row>,
		colWidth: 260,
	});

	const publishedJobsCountCol = TableColumns.docsCountColumn(theme, {
		title: 'Jobs',
		dataIndex: ['company', 'publishedJobsCount'],
		color: '#129c8f',
		background: '#b4f7f1',
		icon: <FaWifi style={{ marginBottom: -2 }} />,
		value: (value, row) => value || row.company?.publishedJobsCount,
	});

	const jsonCol = TableColumns.jsonColumn(theme);

	const lastBatchCol = TableColumns.companyLastBatchColumn(theme);

	const lastBatchCol2 = TableColumns.dateColumn(theme, {
		title: 'Last batch',
		dataIndex: ['batch', 'lastBatch', 'startDate'],
		removeInColumnFilter: true,
		render: (value, row) => {
			if (!row.batch?.lastBatch?.startDate) return;
			let durationDisplay = <><FaCog style={{ color: theme.color.orange, marginBottom: -2 }} /><FaHourglassHalf
				style={{ color: theme.color.orange, marginBottom: -2 }} /></>;
			let tagDurationDisplay = null;
			if (row.batch.lastBatch?.endDate) {
				const duration = dayjs.duration(dayjs(row.batch.lastBatch.endDate).diff(dayjs(row.batch.lastBatch.startDate)));
				durationDisplay = `${Math.round(duration.asHours())} h`;
				if (duration.asHours() < 1) durationDisplay = `${Math.round(duration.asMinutes())} min`;
				if (duration.asMinutes() < 1) durationDisplay = `${Math.round(duration.asSeconds())} s`;
				durationDisplay = <><FaCog
					style={{ color: theme.color.gray, marginBottom: -2 }} />{durationDisplay}</>;
				tagDurationDisplay = <><FaTags
					style={{ color: theme.color.orange, marginBottom: -2 }} /><FaHourglassHalf
					style={{ color: theme.color.orange, marginBottom: -2 }} /></>;
				if (row.batch.lastBatch?.taggedDate && row.batch.lastBatch?.endDate) {
					const tagDuration = dayjs.duration(dayjs(row.batch.lastBatch.taggedDate).diff(dayjs(row.batch.lastBatch.endDate)));
					tagDurationDisplay = `${Math.round(tagDuration.asHours())} h`;
					if (tagDuration.asHours() < 1) tagDurationDisplay = `${Math.round(tagDuration.asMinutes())} min`;
					if (tagDuration.asMinutes() < 1) tagDurationDisplay = `${Math.round(tagDuration.asSeconds())} s`;
					tagDurationDisplay = <><FaTags
						style={{ color: theme.color.gray, marginBottom: -2 }} />{tagDurationDisplay}</>;
				}
			}
			// durationDisplay = String(durationDisplay) + ' (' + duration.asMinutes() + ' asM)';
			// durationDisplay = String(durationDisplay) + ' (' + duration.asSeconds() + ' asS)';
			return <Space direction='vertical' size={5}>
				<span>{Math.round(dayjs.duration(dayjs(new Date()).diff(dayjs(row.batch.lastBatch?.startDate))).asHours())}h ago</span>
				{/* <span>{dayjs.duration(dayjs(new Date()).diff(dayjs(row.batch.lastBatch?.startDate))).asHours()}</span> */}
				{/* <span>{row.batch.lastBatch?.startDate}</span> */}
				{TableColumns.dateColumn(theme).render(row.batch?.lastBatch?.startDate)}
				<Space direction='horizontal' size={10} style={{ color: '#444' }}>
					<Space direction='horizontal' size={2} style={{ color: '#444' }}>{durationDisplay}</Space>
					<Space direction='horizontal' size={2} style={{ color: '#444' }}>{tagDurationDisplay}</Space>
				</Space>
			</Space>;
		},
		...TableColumns.columnFixedWidth(120),
	});

	const batchCountCol = {
		title: 'Batches',
		dataIndex: 'batch',
		key: 'batch',
		removeInColumnFilter: true,
		align: 'center',
		render: (value, row) => {
			const { total, lastBatch } = value;
			if (!lastBatch || !total) return '';
			const content = <CompanyBatchCard batch={lastBatch} />;
			// const content = <List
			//         size="small"
			//         dataSource={[lastBatch]}
			//         renderItem={item => <List.Item>
			//             <CompanyBatchCard batch={item}/>
			//         </List.Item>}
			//     />
			return (
				<Popover content={content} title='Last Batch'>
					{/* {JSON.stringify(value)} */}
					{TableColumns.docsCountColumn(theme, {}).render(total, row)}
					{/* <Button type="primary" shape="circle"style={{background: theme.color.red, borderColor: theme.color.red}}>{total}</Button> */}
				</Popover>
			);
		},
		...TableColumns.columnFixedWidth(80),
	};

	const accessAtsCol = TableColumns.companyAccessColumn(theme, { title: 'ATS', value: (row) => row });

	const accessTypeCol = {
		title: 'Access Type',
		dataIndex: 'accessType',
		key: 'accessType',
		sorter: false,
		render: (value, row) => <CompanyAccessAccessTypeTag companyAccess={row} />,
		...TableColumns.columnFixedWidth(150),
	};

	const accessValueCol = {
		title: 'Value',
		dataIndex: 'value',
		key: 'value',
		removeInColumnFilter: true,
		sorter: false,
		render: (value) => <code>{value}</code>,
		...TableColumns.columnFixedWidth(160),
	};

	const accessPermissionsCol = {
		title: 'Permissions',
		dataIndex: 'permissions',
		sorter: false,
		removeInColumnFilter: true,
		render: (value) => {
			const icons = {
				atsSyncable: <FaSyncAlt style={{ marginBottom: -2 }} />,
				fetchJobs: <FaDownload style={{ marginBottom: -2 }} />,
			};
			return <Space direction='vertical' size={0}>
				{value?.map((v) => <div key={v}>{icons[v]}&nbsp;{v}</div>)}
			</Space>;
		},
		...TableColumns.columnFixedWidth(120),
	};

	const accessStagesCol = {
		title: 'Stages',
		dataIndex: 'stages',
		sorter: false,
		removeInColumnFilter: true,
		align: 'center',
		render: (value, row) => {
			if (!value?.length) return null;
			const content = <>
				{/* <JSONPretty data={value} /> */}
				<Table
					loadOnInit={false}
					columns={[
						{ title: 'text', dataIndex: 'text' },
						{ title: 'id', dataIndex: 'id' },
					]}
					withPagination={false}
					withTableHeader={false}
					defaultDataSource={value}
					data={value}
				/>
			</>;
			return <Popover content={content} title={<b>ATS Stages</b>}>
				{TableColumns.docsCountColumn(theme, {}).render(value.length)}
			</Popover>;
		},
		...TableColumns.columnFixedWidth(90),
	};
	const accessArchiveReasonsCol = {
		title: <>Archive<br />reasons</>,
		dataIndex: 'archiveReasons',
		sorter: false,
		removeInColumnFilter: true,
		align: 'center',
		render: (value, row) => {
			if (!value?.length) return null;
			const content = <>
				{/* <JSONPretty data={value} /> */}
				<Table
					loadOnInit={false}
					columns={[
						{ title: 'text', dataIndex: 'text' },
						{ title: 'id', dataIndex: 'id' },
						{
							title: 'active',
							dataIndex: 'status',
							render: (value) => TableColumns.booleanColumn(theme).render(value === 'active'),
						},
					]}
					withPagination={false}
					withTableHeader={false}
					defaultDataSource={value}
					data={value}
				/>
			</>;
			return <Popover content={content} title={<b>ATS Archive reasons</b>}>
				{TableColumns.docsCountColumn(theme, {}).render(value.length)}
			</Popover>;
		},
		...TableColumns.columnFixedWidth(90),
	};

	const accessUsersCol = {
		title: 'Users',
		dataIndex: 'users',
		sorter: false,
		removeInColumnFilter: true,
		align: 'center',
		render: (value, row) => {
			if (!value?.length) return null;
			const content = <>
				{/* <JSONPretty data={value} /> */}
				<Table
					loadOnInit={false}
					columns={[
						{ title: 'name', dataIndex: 'name' },
						{ title: 'username', dataIndex: 'username' },
						{ title: 'email', dataIndex: 'email' },
						{ title: 'accessRole', dataIndex: 'accessRole' },
						{ title: 'id', dataIndex: 'id' },
						{
							title: 'active',
							dataIndex: 'deactivatedAt',
							render: (value) => TableColumns.booleanColumn(theme).render(!value),
						},
					]}
					withPagination={false}
					withTableHeader={false}
					defaultDataSource={value}
					data={value}
				/>
			</>;
			return <Popover content={content} title={<b>ATS Members</b>}>
				{TableColumns.docsCountColumn(theme, {}).render(value.length)}
			</Popover>;
		},
		...TableColumns.columnFixedWidth(90),
	};

	// const isActiveCol = TableColumns.booleanColumn(theme, {
	//     title: 'Active',
	//     dataIndex: 'isActive',
	//     sorter: false,
	//     // ...TableColumns.columnFixedWidth(160),
	// });

	const isActiveCol = TableColumns.toggleIsActiveColumn(theme, toggleIsActive);

	const createdAtCol = TableColumns.createdAtColumn(theme);
	const updatedAtCol = TableColumns.updatedAtColumn(theme);

	const actionsCol = {
		title: 'Actions',
		dataIndex: 'isActive',
		key: 'isActive',
		removeInColumnFilter: true,
		render: (_, row) => {
			return (
				isEditor
				&& <Space direction='horizontal' size={4}>
					{/* <ActionButton */}
					{/*    tooltipTitle="Fetch Job" */}
					{/*    styles={{color: theme.color.blue}} */}
					{/*    onClick={() => onFetchJob(row)} */}
					{/*    icon={<FaSyncAlt />} */}
					{/* /> */}
					<ActionButton
						tooltipTitle='Edit access'
						onClick={() => onEditAccess(row)}
						icon={<RiEdit2Fill />}
					/>
					<AdminCommandGetButton
						title='Deactivate jobs'
						icon={<FaBan />}
						path={`/access/${documentId(row)}/deactivate-jobs`}
						withPath={false}
						style={{ padding: '0px 5px' }}
					 />
					{/* <ActionButton */}
					{/*	tooltipTitle='Deactivate jobs' */}
					{/*	onClick={() => onEditAccess(row)} */}
					{/*	icon={<RiEdit2Fill />} */}
					{/* /> */}
					<ActionButton
						tooltipTitle='Delete access'
						icon={<RiDeleteBinFill />}
						styles={{ color: theme.color.orange }}
						title='Are you sure you want to delete this access？'
						okText='Yes' cancelText='No'
						onConfirm={() => onDelete(row)}
						withConfirm
					/>
				</Space>
			);
		},
		className: css({ width: 150 }),
	};

	const columns = [
		companyStubCol,
		publishedJobsCountCol,
		jsonCol,
		lastBatchCol,
		batchCountCol,
		accessAtsCol,
		// accessTypeCol,
		// accessValueCol,
		accessPermissionsCol,
		accessStagesCol,
		accessArchiveReasonsCol,
		accessUsersCol,
		isActiveCol,
		createdAtCol,
		updatedAtCol,
		actionsCol,
	];

	return (
		<Table columns={columns}
			path='/company/access'
			filterOptions={filterOptions}
			isRefresh={isRefresh}
			onDataLoaded={onDataLoaded}
			data={defaultData}
			rowClassName={(row, index) => (row.isActive !== false ? '' : 'rowDisabled')}
			withDynamicSearch
		/>
	);
};

export default CompanyAccessTable;
