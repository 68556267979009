/** @jsxImportSource @emotion/react */

import {
	ENGLISH_LANG,
	EXTRA_META_ALT,
	EXTRA_META_DESCRIPTION,
	EXTRA_META_TITLE,
	EXTRA_META_PUBLISHER,
	FRENCH_LANG,
	META_ALT_MAX_LENGTH,
	META_DESCRIPTION_MAX_LENGTH,
	META_TITLE_MAX_LENGTH,
	META_PUBLISHER_MAX_LENGTH,
} from '../../constants/constant';

import ArticleDivider from '../staff/content/articles/components/ArticleDivider';
import MetaDivider from './MetaDivider';
import FormInput from '../form/FormInput';
import {
	getMetaAltImage, getMetaDescription, getMetaPublisher, getMetaTitle,
} from '../../utils/seo/MetaValues';
import {
	getDefaultArticleMetaPublisher, getDefaultMetaAltImage, getDefaultMetaDescription, getDefaultMetaTitle,
} from '../../utils/seo/DefaultMetas';
import { META_CURRENT_VALUE_TITLE, META_CURRENT_VALUE_TOOLTIP_CUSTOM, META_CURRENT_VALUE_TOOLTIP_DEFAULT } from '../../utils/seo/MetaConstants';
import CompanyModel from '../../models/CompanyModel';
import ArticleModel from '../../models/ArticleModel';

/**
 * Form to customize the meta tags of different components (company, articles)
 * Default meta tags are in English.
 * The object to persist the meta tags in the DB has the following structure:
 * - For Articles:
 * 		meta
 * 			publisher (not translatable)
 * 			isCustom (not translatable)
 * 			title
 * 			description
 * 			altImage
 * 			fr
 * 				title
 * 				description
 * 				altImage
 * - For Companies:
 * 		meta
 * 			title
 * 			description
 * 			altImage
 * 			fr
 * 				title
 * 				description
 * 				altImage
 */

function FieldItemMetas({ isEnVersion, metaHolder, isTranslatable = true }) {
	const locale = isEnVersion ? ENGLISH_LANG : FRENCH_LANG;

	const metaPublisher = metaHolder instanceof ArticleModel && getMetaPublisher(metaHolder);
	const metaPublisherDefault = metaHolder instanceof ArticleModel && getDefaultArticleMetaPublisher();
	const metaPublisherIsDefault = metaHolder instanceof ArticleModel && metaPublisher === metaPublisherDefault;
	const metaPublisherCurrentValue = metaHolder instanceof ArticleModel && {
		text: metaPublisher,
		title: META_CURRENT_VALUE_TITLE[locale],
		tooltip: metaPublisherIsDefault ? META_CURRENT_VALUE_TOOLTIP_DEFAULT[locale] : META_CURRENT_VALUE_TOOLTIP_CUSTOM[locale],
		isDefault: metaPublisherIsDefault,
	};
	const metaPublisherName = ['meta', 'publisher'];

	const metaTitle = getMetaTitle(metaHolder, isEnVersion ? ENGLISH_LANG : FRENCH_LANG);
	const metaTitleDefault = getDefaultMetaTitle(metaHolder, isEnVersion ? ENGLISH_LANG : FRENCH_LANG);
	const metaTitleIsDefault = metaTitle === metaTitleDefault;
	const metaTitleCurrentValue = {
		text: metaTitle,
		title: META_CURRENT_VALUE_TITLE[locale],
		tooltip: metaTitleIsDefault ? META_CURRENT_VALUE_TOOLTIP_DEFAULT[locale] : META_CURRENT_VALUE_TOOLTIP_CUSTOM[locale],
		isDefault: metaTitleIsDefault,
	};
	const metaTitleName = isEnVersion ? ['meta', 'title'] : ['meta', 'fr', 'title'];

	const metaDescription = getMetaDescription(metaHolder, isEnVersion ? ENGLISH_LANG : FRENCH_LANG);
	const metaDescriptionDefault = getDefaultMetaDescription(metaHolder, isEnVersion ? ENGLISH_LANG : FRENCH_LANG);
	const metaDescriptionIsDefault = metaDescription === metaDescriptionDefault;
	const metaDescriptionCurrentValue = {
		text: metaDescription,
		title: META_CURRENT_VALUE_TITLE[locale],
		tooltip: metaDescriptionIsDefault ? META_CURRENT_VALUE_TOOLTIP_DEFAULT[locale] : META_CURRENT_VALUE_TOOLTIP_CUSTOM[locale],
		isDefault: metaDescriptionIsDefault,
	};
	const metaDescriptionName = isEnVersion ? ['meta', 'description'] : ['meta', 'fr', 'description'];

	const metaAltImage = getMetaAltImage(metaHolder, isEnVersion ? ENGLISH_LANG : FRENCH_LANG);
	const metaAltImageDefault = getDefaultMetaAltImage(metaHolder, isEnVersion ? ENGLISH_LANG : FRENCH_LANG);
	const metaAltImageIsDefault = metaAltImage === metaAltImageDefault;
	const metaAltImageCurrentValue = {
		text: metaAltImage,
		title: META_CURRENT_VALUE_TITLE[locale],
		tooltip: metaAltImageIsDefault ? META_CURRENT_VALUE_TOOLTIP_DEFAULT[locale] : META_CURRENT_VALUE_TOOLTIP_CUSTOM[locale],
		isDefault: metaAltImageIsDefault,
	};
	const metaAltImageName = isEnVersion ? ['meta', 'altImage'] : ['meta', 'fr', 'altImage'];
	const metaAltLabel = metaHolder instanceof CompanyModel ? 'Logo Alternative Text' : 'Illustration Alternative Text';

	const placeholderPrefix = isEnVersion ? 'Default value: ' : 'Valeur par défaut: ';

	return (
		<div
			css={{
				border: '1px solid #eee',
				borderRadius: 4,
				padding: '8px 12px',
				marginBottom: 20,
			}}
		>
			<h2>Add Metas</h2>
			<div css={{ borderRadius: 4, padding: '8px 12px', marginBottom: 20 }}>

				{/* Disclaimer explaining that the content is translatable */}
				{
					metaHolder instanceof ArticleModel
						? isTranslatable && <ArticleDivider isEnVersion={isEnVersion}/>
						:						<MetaDivider
							isEnVersion={isEnVersion}
							isTranslatable={isTranslatable}
						/>
				}

				{/* Publisher is a meta tag only for Articles and it's not translatable content */}
				{
					metaHolder instanceof ArticleModel
					 	&& <FormInput
					 		label="Publisher"
					 		name={metaPublisherName}
					 		extra={EXTRA_META_PUBLISHER}
					 		maxLength={META_PUBLISHER_MAX_LENGTH}
					 		currentValue={metaPublisherCurrentValue}
					 		placeholder={placeholderPrefix + metaPublisherDefault}
					 	/>
				}
				{/* Translatable meta tags: */}
				{
					<>
						<FormInput
							label="Title"
							name={metaTitleName}
							extra={EXTRA_META_TITLE}
							maxLength={META_TITLE_MAX_LENGTH}
							placeholder={placeholderPrefix + metaTitleDefault}
							currentValue={metaTitleCurrentValue}
						/>
						<FormInput
							label="Description"
							name={metaDescriptionName}
							extra={EXTRA_META_DESCRIPTION}
							maxLength={META_DESCRIPTION_MAX_LENGTH}
							style={{ minHeight: 100 }}
							isTextarea
							placeholder={placeholderPrefix + metaDescriptionDefault}
							currentValue={metaDescriptionCurrentValue}
						/>
						<FormInput
							label={metaAltLabel}
							name={metaAltImageName}
							maxLength={META_ALT_MAX_LENGTH}
							extra={EXTRA_META_ALT}
							placeholder={placeholderPrefix + metaAltImageDefault}
							currentValue={metaAltImageCurrentValue}
						/>
					</>
				}
			</div>
		</div>
	);
}

export default FieldItemMetas;
