import { Space } from 'antd';
import CompanyLogo from '../../company/CompanyLogo';

const UserAvatarTooltipTitle = (
	{
		user,
		withEmail = false,
		withYou = false,
	},
) => {
	const className = 'UserAvatarTooltipBody';

	if (!user) return null;

	return (<Space direction='vertical' size={0} align='center'>
		<span><b>{`${user?.firstName} ${user?.lastName}`}</b>
			{withYou && <i style={{ fontSize: 12 }}>&nbsp;(You)</i>}</span>
		<span style={{ fontSize: 12 }}>{
			user?.company
				? <><CompanyLogo size={18} company={user.company}/> {user?.company?.name}</>
				: user?.information?.company
		}</span>
		{user?.email && withEmail
				&& <span style={{ fontSize: 12 }}>{user?.email}</span>}
	</Space>
	);
};

export default UserAvatarTooltipTitle;
