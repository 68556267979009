import { useContext } from 'react';
import { useTheme } from '@emotion/react';
import {
	Col, Row, Space,
} from 'antd';
import { arrayWrap } from '../../utils/common';
import { UserSavedSearchContext } from './UserSavedSearchProvider';
import ReactJsonDebug from '../app/debug/ReactJsonDebug';

const UserSavedSearchSummary = (
	{
		savedSearch,
	},
) => {
	const className = 'UserSavedSearchSummary';

	const theme = useTheme();
	const { getSavedSearchFilters } = useContext(UserSavedSearchContext);

	return (
		<Space wrap={true} size={[4, 4]} direction='vertical' style={{ fontSize: 12 }}>
			<span style={{ color: theme.color.grey, fontStyle: 'italic' }}>
				Searching for...
			</span>
			{/* <ReactJsonDebug src={getSavedSearchFilters(savedSearch)} /> */}
			{getSavedSearchFilters(savedSearch)?.map(({ category, values }, i) => (
				category && values?.length > 0
					? (
						<Row key={i} gutter={4} >
							<Col key={`${i}1`}>
								<span style={{ fontWeight: 'bold' }}>{category?.label || category?.value}: </span>
							</Col>
							<Col key={`${i}2`} style={{ maxWidth: 400 }}>
								{
									arrayWrap(values)?.map((value, j) => (
										<Space key={j} style={{ marginLeft: 4 }}>
											{j > 0
												? <span style={{ color: theme.color.grey }}>
													{value?.isBooleanQueryOr ? 'or' : 'and'}
												</span>
												: null
											}
											<span>{value?.label || value?.toString()}</span>
										</Space>
									))
								}
							</Col>
						</Row>
					)
					: null
			))}
		</Space>
	);
};

export default UserSavedSearchSummary;
