import { ENGLISH_LANG } from '../../../../constants/constant';
import CompanyRichTextEditor from '../shared/CompanyRichTextEditor';

const CompanyCommunitiesEditor = (
	{
		company,
		lang = ENGLISH_LANG,
		setEditorOpen,
	},
) => {
	const className = 'CompanyCommunitiesEditor';

	return (
		<CompanyRichTextEditor
			company={company}
			lang={lang}
			title={<b>Supported Communities</b>}
			details={<span>
				Describe the communities your company
				supports to promote diversity and equality
			</span>}
			field={'communities'}
			setEditorOpen={setEditorOpen}
		/>
	);
};

export default CompanyCommunitiesEditor;
