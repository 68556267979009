import { createBrowserHistory } from 'history';
import { convertCommaSeparatedStringsToArrays } from './utils/common';

/**
 * custom browser history and use it outside react router context like in action, utils
 */
export const history = createBrowserHistory({ window });

/**
 * create function to parse query search to object
 * @object queryParams
 */
const parseQueryParamsToObject = ({
	withCommaSeparatedStringsToArraysConversion = false,
} = {
	withCommaSeparatedStringsToArraysConversion: false,
}) => {
	const currentLocation = history.location;
	let queryParamsAsObject = Object
		.fromEntries(
			new URLSearchParams(currentLocation.search).entries(),
		);
	if (withCommaSeparatedStringsToArraysConversion) {
		queryParamsAsObject = convertCommaSeparatedStringsToArrays(queryParamsAsObject);
	}
	return queryParamsAsObject;
};

/**
 * create function de parse query params to object
 * @param { URLSearchParams || Object } params: search params object
 * @return string
 */
const toSearchParams = (params) => {
	const search = new URLSearchParams(params).toString();
	return search;
};

const browserHistory = {
	history,
	parseQueryParamsToObject,
	toSearchParams,
};

export default browserHistory;
