import { Space } from 'antd';
import UserAvatar from '../widgets/UserAvatar';
import UserStubProfessional from '../stub/UserStubProfessional';
import { useTheme } from '@emotion/react';
import { useAuthContext } from '../../../hooks/useAuthContext';
import UserGridCardEngageButtons from '../grid/card/UserGridCardEngageButtons';
import UserStubName from '../stub/UserStubName';

const UserProfilePageHeader = (
	{
		user,
		isAffixedHead = false,
		onRefresh,
		onUserChanged,
	},
) => {
	const className = 'UserProfilePageHeader';

	const theme = useTheme();

	const {
		isAtsSyncable,
		isCompanyRecruiter,
		isDocumentAdmin,
	} = useAuthContext();

	if (!user) return null;

	return (
		<>
			<Space
				size={10}
				wrap={false}
				{...isAffixedHead ? { className: 'affixedHead' } : {}}
				style={{
					width: '100%',
					justifyContent: 'space-between',
				}}
			>
				<Space
					direction='horizontal'
					size={10}
					align='start'
				>
					<UserAvatar user={user} size={32} withTooltip={false} />
					<Space direction='vertical' size={0}>
						<UserStubName
							user={user}
							withJSONPopover={false}
							withResumeTag={false}
							withCoachedTag={false}
							withTooltip={false}
							style={{ fontSize: 16 }}
						/>
						<UserStubProfessional user={user} />
					</Space>
				</Space>

				<Space direction='horizontal' size={10} wrap={false}>
					{(isCompanyRecruiter || isDocumentAdmin)
						&& (
							<UserGridCardEngageButtons
								user={user}
								popoverPlacement='bottom'
								onUserChanged={onUserChanged}
							/>
						)}
				</Space>

			</Space>
		</>
	);
};

export default UserProfilePageHeader;
