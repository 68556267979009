import { Space, Spin } from 'antd';
import { useContext, useEffect, useState } from 'react';
import RecruiterService from '../../services/recruiter';
import UserSavedSearchButton from './UserSavedSearchButton';
import { LocalDebug } from '../../utils/LocalDebug';
import { useAuthContext } from '../../hooks/useAuthContext';
import { UserSavedSearchContext } from './UserSavedSearchProvider';
import { NotificationContext } from '../app/notification/NotificationProvider';
import UserSavedSearchStartNewButton from './UserSavedSearchCreateNewButton';
import { documentId, getQueryParams } from '../../utils/common';
import UserSavedSearchBadge from './UserSavedSearchBadge';
import SuggestedForYouButton from './SuggestedForYouButton';
import { AlertContext } from '../../contexts/AlertProvider';
import JsonPopoverLink from '../app/debug/JsonPopoverLink';

const UserSavedSearchesButtonList = (
	{
		user,
		// isAffixed = false,
		refresh = 0,
		onEdit,
		onClone,
		onCreate,
		onSelect,
		...props
	},
) => {
	const className = 'UserSavedSearches';
	const queryParams = getQueryParams();

	const { isStaffView } = useAuthContext();
	const { alerts } = useContext(AlertContext);
	const {
		isLoading,
		savedSearches,
		selectedSavedSearch,
		addSelectedSavedSearchQueryParams,
		updateSavedSearches,
		suggestionSearch,
		onUserViewed,
	} = useContext(UserSavedSearchContext);

	const { notifySuccess, notifyLoading, notifyError } = useContext(NotificationContext);
	const [savedSearches50, setSavedSearches50] = useState([]);
	const [savedSearchesUser, setSavedSearchesUser] = useState(null);
	// const [badgeCount, setBadgeCount] = useState(0);

	// const [isAffixed, setIsAffixed] = useState(false);

	useEffect(() => {
		setSavedSearches50(savedSearches?.filter((s) => s?.is50 === true));
		setSavedSearchesUser(savedSearches?.filter((s) => s?.is50 !== true));
	}, [savedSearches]);

	const handleSelectSavedSearch = async (savedSearch) => {
		const method = 'handleSelectSavedSearch';
		// LocalDebug.logInfo({ className, method }, { savedSearch });
		addSelectedSavedSearchQueryParams(savedSearch);
	};

	const handleEditSavedSearch = async (savedSearch) => {
		const method = 'handleEditSavedSearch';
		LocalDebug.logInfo({ className, method }, { savedSearch });
		onEdit?.(savedSearch);
	};

	const handleCloneSavedSearch = async (savedSearch) => {
		const method = 'handleCloneSavedSearch';
		LocalDebug.logInfo({ className, method }, { savedSearch });
		onClone?.(savedSearch);
	};

	const handleDiscardSavedSearchAlerts = async (savedSearch) => {
		const method = 'handleDiscardSavedSearchAlerts';
		LocalDebug.logInfo({ className, method }, { savedSearch });
		// const result = await RecruiterService.discardSavedSearchAlerts(documentId(savedSearch));
		await onUserViewed({ savedSearch });
		notifySuccess({
			duration: 3,
			description: 'Alerts for this saved search have been discarded.',
		});
	};

	const handleDeleteSavedSearch = async (savedSearch) => {
		const method = 'handleDeleteSavedSearch';
		LocalDebug.logInfo({ className, method }, { savedSearch });
		const result = await RecruiterService.deleteSavedSearch(documentId(savedSearch));
		notifySuccess({
			duration: 3,
			description: 'Your saved search was properly deleted.',
		});
		updateSavedSearches();
	};

	// const onAffixChanged = (value) => {
	// 	setIsAffixed(value);
	// }

	if (isLoading) return <Spin/>;

	if (!savedSearchesUser) return null;
	return (
		<Space
			direction='vertical'
			size={6}
			wrap={true}
			style={{
				width: '100%',
			}}
		>
			<Space wrap={true}>

				{suggestionSearch && <SuggestedForYouButton
					active={!selectedSavedSearch
						|| (documentId(suggestionSearch) === documentId(selectedSavedSearch))}
					savedSearch={suggestionSearch}
					onSelectSavedSearch={handleSelectSavedSearch}
				/>}

				{savedSearchesUser?.map((savedSearch, index) => (
					<UserSavedSearchBadge
						key={index}
						count={savedSearch?.alerts?.length}
						offset={[-39, 2]}
					>
						<UserSavedSearchButton
							active={selectedSavedSearch
									&& (documentId(savedSearch) === documentId(selectedSavedSearch))}
							savedSearch={savedSearch}
							onSelectSavedSearch={handleSelectSavedSearch}
							onEditSavedSearch={handleEditSavedSearch}
							onCloneSavedSearch={handleCloneSavedSearch}
							onDiscardSavedSearchAlerts={handleDiscardSavedSearchAlerts}
							onDeleteSavedSearch={handleDeleteSavedSearch}
						/>
					</UserSavedSearchBadge>
				))}
				<UserSavedSearchStartNewButton onCreate={onCreate} />
				{isStaffView && (
					<JsonPopoverLink data={{
						savedSearchesUser,
						alerts,
					}} />
				)}
			</Space>
		</Space>
	);
};

export default UserSavedSearchesButtonList;
