import PageLayout from '../../../components/app/layout/PageLayout';
import { useEffect, useState } from 'react';
import { addMultipleQueryParams, getQueryParams, removeQueryParams } from '../../../utils/common';
import SectionTitle from '../../../components/app/SectionTitle';
import { FEEDBACKS_ICON, FEEDBACKS_MENU } from '../../../constants/constant';
import { useAuthContext } from '../../../hooks/useAuthContext';
import FeedbackBanner from '../../../components/staff/product/feedback/FeedbackBanner';
import { Space } from 'antd';
import FeedbackTable from '../../../components/staff/product/feedback/FeedbackTable';

const FeedbacksPage = () => {
	const queryParams = getQueryParams();

	const { isStaffView } = useAuthContext();

	const [optionsFilterTable, setOptionsFilterTable] = useState({});
	const [isRefreshDataLayout, setIsRefreshDataLayout] = useState(0);

	useEffect(() => {
		setOptionsFilterTable((prev) => ({ ...prev, ...queryParams }));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	// --------------------------------------- //
	// ------------ handle filter ------------ //
	// --------------------------------------- //
	const handleFilter = (values) => {
		const { tags, ...rest } = values;
		addMultipleQueryParams(rest);
		setOptionsFilterTable((prev) => {
			return ({ ...prev, ...rest, tags });
		});
	};

	const handleClearFilter = () => {
		setOptionsFilterTable({});
		setIsRefreshDataLayout((prev) => prev + 1);
		removeQueryParams();
	};

	return (
		<PageLayout
			icon={FEEDBACKS_ICON} title={FEEDBACKS_MENU}
		>
			<FeedbackTable
				filterOptions={optionsFilterTable}
				isRefresh={isRefreshDataLayout}
			/>
		</PageLayout>
	);
};

export default FeedbacksPage;
