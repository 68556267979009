import { ENGLISH_LANG, FRENCH_LANG } from '../constants/constant';
import { mergeReducer } from '../utils/common';

const fields = ['title', 'content', 'illustration'];
const translationFieldName = 'translations';

const translationsOptions = {
	title: {
		[ENGLISH_LANG]: {
			label: 'What is your story title?',

		},
		[FRENCH_LANG]: {

			label: 'Quel est le titre de votre story ?',
		},
	},
	illustration: {
		[ENGLISH_LANG]: {
			label: 'Your image (jpg/png, 10MB max)',

		},
		[FRENCH_LANG]: {

			label: 'Votre image (jpg/png, 10Mo max)',
		},
	},
};

const translatableFields = [
	'title',
	'content',
	'contentLogged',
	'illustration',
];

export default class ArticleModel {
	id;

	userId;

	topic;

	title;

	illustration;

	content;

	contentLogged;

	isAnonymous;

	deadline;

	answerOn;

	logo;

	type;

	link;

	city;

	country;

	activityAt;

	publishedAt;

	demo;

	eventAt;

	eventAtDate;

	eventAtHour;

	eventAtMinute;

	eventLink;

	hasReplay;

	extra;

	slug;

	prevArticleId;

	nextArticleId;

	group;

	is50Content;

	translations;

	constructor(article) {
		Object.assign(this, article);
	}

	translate(lang) {
		const translatedValue = {
			...this,
			...this.translateFields(lang),
		};
		return translatedValue;
	}

	getTranslatedFieldValues(lang = ENGLISH_LANG) {
		if (lang === ENGLISH_LANG) {
			return translatableFields.map((f) => ({ [f]: this[f] })).reduce(mergeReducer, {});
		}
		return this.translations?.[lang] || {};
	}

	translateFields(lang = ENGLISH_LANG) {
		return fields.reduce((prev, cur) => {
			const defaultValue = this[cur];
			return {
				...prev,
				[`${cur}_${lang}`]: this.translateField(cur, lang, defaultValue),
			};
		}, {});
	}

	translateField(field, lang, defaultValue = '') {
		// other language
		const { translations } = this;
		return translations?.hasOwnProperty(lang) && translations[lang][field] ? translations[lang][field] : defaultValue;
	}

	setField(field, value, lang = ENGLISH_LANG) {
		if (lang === ENGLISH_LANG || !field.includes(translationFieldName)) {
			this[field] = value;
			return;
		}

		const [t, l, name] = field.split('-');
		if (!this.translations) {
			this[translationFieldName] = {
				[l]: {
					[name]: value,
				},
			};
		}
		this[t][l][name] = value;
	}

	getField(field, lang = ENGLISH_LANG, defaultValue) {
		if (lang === ENGLISH_LANG) {
			return this[field];
		}
		return (
			(
				this?.translations?.hasOwnProperty(lang)
				&& this?.translations[lang]?.hasOwnProperty(field)
			)
				? this?.translations[lang][field]
				: defaultValue
		);
	}

	getFieldName(field, lang) {
		if (lang === ENGLISH_LANG || !translatableFields.includes(field)) {
			return field;
		}
		return `${translationFieldName}-${lang}-${field}`;
	}

	getLabel(field, lang) {
		return translationsOptions[field][lang]?.label;
	}

	getTranslatedValues() {
		const translations = this[translationFieldName] || {};
		return Object.keys(translations).reduce((prev, lang) => {
			const valuesLang = this[translationFieldName][lang];
			const toDb = Object.keys(valuesLang).reduce((prev, field) => {
				return {
					...prev,
					[`${translationFieldName}-${lang}-${field}`]: this.getField(field, lang),
				};
			}, {});

			return ({
				...prev,
				...toDb,
			});
		}, {});
	}

	isNotPublished() {
		return !this.getField('title') || !this.getField('content');
	}
}
