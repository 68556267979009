import { useContext, useState } from 'react';
import ModalContainerBase from '../../../contexts/modals/ModalContainerBase';
import { documentId } from '../../../utils/common';
import FormBoxTitle from '../../app/form/FormBoxTitle';
import UMSInviteForm from './UMSInviteForm';
import { UMSInviteContext } from '../UMSInviteProvider';

const UMSInviteModal = (
	{
		title = 'Invite a teammate',
		initialValues = {},
		onCloseWithRefresh,
		open,
		onSubmit,
		onReset,
	},
) => {
	const className = 'UMSInviteModal';
	const [companyId, setCompanyId] = useState(initialValues?.companyId);
	const { closeInviteModal } = useContext(UMSInviteContext);

	const handleSubmit = (values) => {
		if (onSubmit) {
			onSubmit({
				...values,
				documentId: companyId,
				id: documentId(initialValues),
			});
		}
	};

	return (
		<ModalContainerBase
			open={open}
			onCancel={onReset}
			footer={false}
		>
			<FormBoxTitle
				title={documentId(initialValues)
					? 'Edit an access'
					: title}
			/>
			<UMSInviteForm
				initialValues={{
					...initialValues,
					isExist: true,
				}}
				onReset={onReset}
				onSubmit={handleSubmit}
				onCloseWithRefresh={onCloseWithRefresh}
				onCompanyChange={setCompanyId}
				autoValidateEmail={initialValues?.autoValidateEmail}
				withInviteUser
				withSubmitButton
			/>
		</ModalContainerBase>
	);
};

export default UMSInviteModal;
