import { saveCompany } from '../../../actions/company';
import { refreshCurrentUser } from '../../../actions/app';
import { writeLog } from '../../../utils/common';
import { message } from 'antd';
import { LocalDebug } from '../../../utils/LocalDebug';

const className = 'CompanySettingsFormHelper';
export const onFormFinish = async ({
	id, values, stateDispatch, formName, dispatch,
}) => {
	LocalDebug.logInfo({ className, method: 'onFormFinish' }, { values });

	stateDispatch('forceDisabled');

	const data = { id, ...values };

	message.loading({ key: className, content: 'Saving company settings...' });

	await dispatch(saveCompany(data));

	refreshCurrentUser()(dispatch);

	message.success({ key: className, content: 'Saved company settings successfully!' });

	writeLog(`Company settings saved (${formName})`, { data: values });
};

export const onFormFinishFailed = ({ values, errorFields, outOfDate }) => {
	message.error(<div>
		Error: {(errorFields?.map((e) => e.errors) || []).slice(0, 1).join(',')}
	</div>);
};

export const onFormValuesChange = ({ values, stateDispatch, setCompanyEdited }) => {
	// setIsFormChanged(true);
	LocalDebug.logInfo({ className, method: 'onFormValuesChange' }, values);
	setCompanyEdited?.((prev) => ({ ...prev, ...values }));
	stateDispatch?.('updateDisabled');
};

export const hasFormErrors = (form) => form.getFieldsError().some(({ errors }) => errors.length);

export const formStateReducer = ({ form, setIsSubmitDisabled }) => (state, action) => {
	switch (action) {
		case 'forceDisabled':
			return setIsSubmitDisabled?.(true);
		case 'updateDisabled':
			const isTouched = form.isFieldsTouched();
			return setIsSubmitDisabled?.(hasFormErrors(form) || !isTouched);
	}
};

// const CompanySettingsFormHelper = (
// 	{
// 		form,
// 		formName = 'CompanySettingsFormHelper',
// 		company,
// 		onFormValuesChanged,
// 		labelCol = { span: 6 },
// 		wrapperCol = { span: 16 },
// 		setIsSubmitDisabled,
// 		children,
// 		...props
// 	}) => {
//
// 	const className = 'CompanySettingsFormBase';
// 	const theme = useTheme();
// 	const dispatch = useDispatch();
// 	const { isStaffView } = useAuthContext();
//
// 	const [initialValues, setInitialValues] = useState({ ...company });
// 	const [companyEdited, setCompanyEdited] = useState({ ...company });
// 	const [isFormChanged, setIsFormChanged] = useState(false);
//
// 	const hasFormErrors = () => form.getFieldsError().some(({ errors }) => errors.length);
//
// 	const [state, stateDispatch] = useReducer((state, action) => {
// 		switch (action) {
// 			case 'forceDisabled':
// 				return setIsSubmitDisabled?.(true);
// 			case 'updateDisabled':
// 				const isTouched = form.isFieldsTouched();
// 				return setIsSubmitDisabled?.(hasFormErrors() || !isTouched);
// 		}
// 	}, null);
//
// 	const onFormValuesChange = (values) => {
// 		setCompanyEdited({ ...companyEdited, ...values });
// 		setIsFormChanged(true);
// 		stateDispatch('updateDisabled');
// 		onFormValuesChanged?.(values);
// 	}
//
// 	useEffect(() => {
// 		LocalDebug.logUseEffect({ className, effects: 'company' }, { company });
// 		form.resetFields();
// 		setIsFormChanged(false);
// 		form.setFieldsValue({ ...company });
// 		setCompanyEdited({ ...company });
// 	}, [company]);
//
// 	const onFormFinish = async (values) => {
// 		LocalDebug.logInfo({ className, method: 'onFormFinish' }, { values });
//
// 		const data = {
// 			id: documentId(initialValues),
// 			...values,
// 		};
//
// 		message.loading({ key: className, content: 'Saving company settings...' });
//
// 		await dispatch(saveCompany(data));
//
// 		refreshCurrentUser()(dispatch);
//
// 		message.success({ key: className, content: 'Saved company settings successfully!' });
//
// 		stateDispatch('forceDisabled');
//
// 		writeLog(`Company settings saved (${formName})`, { data: values });
// 	}
//
// 	const onFormFinishFailed = ({ values, errorFields, outOfDate }) => {
// 		message.error(<div>
// 			Error: {(errorFields?.map(e => e.errors) || []).slice(0, 1).join(',')}
// 		</div>);
// 	}
//
// 	return (
// 		<>
// 			<Form
// 				form={form}
// 				name={formName}
// 				initialValues={initialValues}
// 				labelCol={labelCol}
// 				wrapperCol={wrapperCol}
// 				labelAlign='right'
// 				onFinish={onFormFinish}
// 				onFinishFailed={onFormFinishFailed}
// 				onValuesChange={onFormValuesChange}
// 			>
//
// 				{children}
//
// 			</Form>
// 		</>
// 	);
// }
//
// export default CompanySettingsFormHelper;
