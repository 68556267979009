import { Button, message, Popconfirm } from 'antd';
import {
	FaBan, FaCheck, FaEyeSlash, FaSpinner,
} from 'react-icons/fa';
import { writeRecruiterLog } from '../../../actions/logging';
import { COMPANY_RECRUITER_LOG } from '../../../constants/company-recruiter-log';
import UserService from '../../../services/user';
import { documentId, writeLog } from '../../../utils/common';
import { useContext } from 'react';
import { NotificationContext } from '../../app/notification/NotificationProvider';
import { useTheme } from '@emotion/react';

const UserHider = (
	{
		user,
		Component,
		onStart,
		onComplete,
		show = false,
		children,
		...props
	},
) => {
	const theme = useTheme();

	const { notifySuccess, notifyError } = useContext(NotificationContext);

	const handleSubmit = async (e) => {
		e?.stopPropagation();
		if (onStart) await onStart();
		writeLog('UserHider handleSubmit', { user: user?.slug });
		await UserService.hideUser(documentId(user));

		// company recruiter log
		writeRecruiterLog(COMPANY_RECRUITER_LOG.HIDE, { userTargetId: documentId(user) });

		notifyError({
			icon: <FaEyeSlash style={{ color: theme.color.white, marginLeft: -10 }}/>,
			duration: 3,
			message: 'Talent hidden',
			description: 'This profile won\'t show up in any of your searches',
		});
		// message.success('Talent hidden: you won\'t see her profile in the search sections anymore.');
		if (onComplete) await onComplete();
	};

	if (!show) {
		return null;
	}
	// return null;
	return <Popconfirm
		title={<div style={{ maxWidth: 300, whiteSpace: 'normal' }}>
			{<><strong>Hide this profile?</strong><br/>It won't show up in any of your search results anymore.</>}
		</div>}
		icon={<span role="img" className="anticon">
	        <FaEyeSlash style={{ marginBottom: -2, verticalAlign: 'middle' }}/>
	    </span>}
		showCancel={false}
		okButtonProps={{ style: { height: 32 } }}
		cancelButtonProps={{ style: { height: 32 } }}
		okText={<span style={{ padding: '0 4px' }}><FaCheck style={{ marginBottom: -2 }}/> Confirm</span>}
		cancelText={<span style={{ padding: '0 4px' }}><FaBan style={{ marginBottom: -2 }}/> Cancel</span>}
		onConfirm={handleSubmit}
	>
		{Component
			? <Component {...props}>{children}</Component>
			: <Button
				onClick={(e) => { e?.stopPropagation(); }}
				icon={<FaEyeSlash style={{ marginBottom: -2 }}/>}
				size={10}
			>
				&nbsp;Hide
			</Button>
		}
	</Popconfirm>;
};

export default UserHider;
