import moment from 'moment';
import {
	DEI_CRITERIA_LABELS,
	getCareerSpecificsLabel,
	getJobRoleLabel,
	getJobLocationLabel,
	getJobRemoteLabel,
	getJobLevelLabel,
	getSortedJobLevels,
	getSortedJobContracts,
	getJobContractLabel,
	getEmploymentVisaLabel,
	getSortedJobRemotes,
	getEmploymentEligibilityLabel,
	getHardSkillLabel,
} from '../constants/constant';
import { Space, Tag } from 'antd';
import {
	arrayWrap, capitalize, documentId, pTagsAsList, sortOn,
} from '../utils/common';
import config from '../config/config';
import { LocalDebug } from '../utils/LocalDebug';
import CompanyModel from './CompanyModel';
import {
	ALLOWED_LANGUAGE_PROFICIENCIES,
	LANGUAGE_PROFICIENCY_TRANSLATIONS,
	LANGUAGE_TRANSLATIONS,
} from '../constants/languages';
import { isEmpty } from 'lodash';
import { EMPLOYMENT_VISA_FILTER_OPTIONS } from '../constants/property/employment-visa';
import {
	COACHING_STATUS_USER_DISPLAYED_VALUE,
	COACHING_STATUS_USER_SENT_VALUE,
} from '../constants/coaching-status.constant';

const className = 'UserModel';

export default class UserModel {
	_id;

	isLoading;

	fetchedAt;

	id;

	isLoading;

	fetchedAt;

	uid;

	firstName;

	lastName;

	career;

	coach;

	coaching;

	jobSearch;

	resume;

	personalResume;

	acls;

	company;

	companyModel;

	companyMember;

	mailjet;

	isNewlyOnboarded;

	constructor(user) {
		// LocalDebug.logInfo({ className, method: 'constructor' }, user, user instanceof UserModel);
		// if (user instanceof UserModel) return this;
		Object.assign(this, user);
		if (this?.company) this.companyModel = new CompanyModel(this?.company);
	}

	get fullName() {
		return [this.firstName, this.lastName].filter((i) => i).join(' ');
	}

	getProfileUrl(currentUser) {
		let adminPath = config.adminRoot;
		if (config.isProdEnv && config.isNotProdVersion) {
			adminPath = config.adminRootProd;
		}
		return `${adminPath}/user/${this.getPublicSlug(currentUser)}`;
	}

	getPublicSlug(currentUser) {
		let { slug, id, _id } = this;
		if (currentUser && new UserModel(currentUser)?.canDisplayTalentNames?.(this) === false) {
			slug = id || _id;
		}
		return slug;
	}

	getUserJobRoles() {
		const roles = this.tagTree?.['job-search-job-role'];
		if (!roles || roles.length === 0) return null;
		return <Space wrap={true} size={4}>
			{roles.map((role, index) => <Tag
				key={index}
				// color='#f5f5f5'
				color='blue'
				style={{
					fontSize: 14,
					// padding: '2px 8px',
					margin: 0,
					marginRight: 4,
					// color: '#333',
					// borderColor: '#ddd'
				}}
			>
				{getJobRoleLabel(role.split(':')[1])}
			</Tag>)}
		</Space>;
	}

	/**
	 * Gets the object with the parsed data from the Linkedin CV
	 * @returns
	 */
	getResume() { return this.resume; }

	/**
	 * whether is the Linkedin CV parsed in the `resume` object or not
	 * @returns
	 */
	hasResume() { return !isEmpty(this.getResume()?.createdAt); }

	/**
	 * whether there is a personal resume uploaded or not
	 * @returns
	 */
	hasPersonalResume() { return !isEmpty(this.personalResume?.createdAt); }

	/**
	 * Get the filename where the CV is stored
	 * The CV can be a Linkedin PDF or a personal resume
	 * @param {*} isLinkedinCV
	 * @returns
	 */
	getResumeFileName(isLinkedinCV) {
		return isLinkedinCV
			? this?.resume?.file
			: this?.personalResume?.file;
	}

	getPersonalResumeFileName() { return this.getResumeFileName(false); }

	getLinkedInResumeFileName() { return this.getResumeFileName(true); }

	hasResumeFiles() {
		return !!(this?.getPersonalResumeFileName() || this?.getLinkedInResumeFileName());
	}

	getCoaching() { return this.coaching; }

	getCoachingDate() {
		if (!this.hasBeenCoached()) return null;
		return (this?.getCoaching()?.coachedAt || this?.getCoaching()?.lastStatusAt);
	}

	hasBeenCoached() {
		return (
			![false, 'false'].includes(this.getCoaching()?.isActive)
			&& [
				COACHING_STATUS_USER_DISPLAYED_VALUE,
				COACHING_STATUS_USER_SENT_VALUE,
			].includes(this.getCoachingStatus())
		);
	}

	hasCoachingOpinion() {
		return !!this.getCoachingOpinion();
	}

	/*
	Coaching info should only be displayed to recruiters
	if done in the last 6 months.
	We always show it for staff users.
	*/
	isCoachingVisible({
		isStaffView = false,
	} = {
		isStaffView: false,
	}) {
		if (!this.hasBeenCoached() || !this.hasCoachingOpinion()) return false;
		const coachedAt = this.getCoachingDate();
		return (
			coachedAt
			&& (
				isStaffView
				|| moment(coachedAt).isAfter(moment().add(-6, 'month'))
			)
		);
	}

	/* Coaching status */

	getCoachingStatus() { return this.getCoaching()?.status; }

	/* Coaching opinion */
	getCoachingOpinion() { return this.getCoaching()?.coachingOpinion; }

	hasCoachingOpinion() { return !isEmpty(this.getCoachingOpinion()); }

	/* Availability */
	getAvailability() { return this.hasBeenCoached() ? this.getCoachingAvailability() : null; }

	hasAvailability() { return !isEmpty(this.getAvailability()); }

	getCoachingAvailability() { return this.getCoaching()?.coachingAvailability; }

	hasCoachingAvailability() { return !isEmpty(this.getCoachingAvailability()); }

	/* Mobility */
	getMobility() { return this.hasBeenCoached() ? this.getCoachingMobility() : null; }

	hasMobility() { return !isEmpty(this.getMobility()); }

	getCoachingMobility() { return this.getCoaching()?.coachingMobility; }

	hasCoachingMobility() { return !isEmpty(this.getCoachingMobility()); }

	/* Hard skills */
	getHardSkills() { return this.hasBeenCoached() ? this.getCoachingHardSkills() : this.career?.hardSkill; }

	hasHardSkills() { return !isEmpty(this.getHardSkills()); }

	getCoachingHardSkills() { return pTagsAsList(this.getCoaching()?.coachingHardSkills); }

	hasCoachingHardSkills() { return !isEmpty(this.getCoachingHardSkills()); }

	/* Soft skills */
	getSoftSkills() { return this.hasBeenCoached() ? this.getCoachingSoftSkills() : this.getCareerSoftSkills(); }

	hasSoftSkills() { return !isEmpty(this.getSoftSkills()); }

	getCoachingSoftSkills() { return pTagsAsList(this.getCoaching()?.coachingSoftSkills); }

	hasCoachingSoftSkills() { return !isEmpty(this.getCoachingSoftSkills()); }

	getCareerSoftSkills() { return this.career?.softSkill; }

	hasCareerSoftSkills() { return !isEmpty(this.getCareerSoftSkills()); }

	/* Languages */
	getCareerLanguages({
		sorted = false,
		withFlag = false,
	} = {
		sorted: false,
		withFlag: false,
	}) {
		let values = this?.career?.language;
		if (!values) return values;
		values = arrayWrap(values);
		if (sorted) {
			const normalizedValues = Object
				.keys(LANGUAGE_TRANSLATIONS)
				.filter(
					(item) => values
						.map((value) => value?.toLowerCase?.()?.trim?.())
						.includes(item),
				);
			values = [
				...normalizedValues,
				...values.filter((value) => !normalizedValues.includes(value?.toLowerCase?.()?.trim?.())),
			];
		}
		values = values.map((value) => {
			const { languageLabel: label, flag } = LANGUAGE_TRANSLATIONS
				?.[value?.toLowerCase?.()?.trim?.()]
				|| { languageLabel: value };
			return [withFlag && flag, label]
				.filter((item) => item)
				.join(' ');
		});
		return values;
	}

	getLanguages() {
		let languages = [
			...this.getCoachingLanguages() || [],
			...this.getResumeLanguages() || [],
		];
		// LocalDebug.logInfo({ className, method: 'getLanguages' }, { coachingLanguages, resumeLanguages });

		// Remapping language and level with translations
		languages = languages.map((item) => {
			const { language } = item;
			const tLang = LANGUAGE_TRANSLATIONS[language?.toLowerCase?.()?.trim?.()] || { languageLabel: language };
			// const tLang = { languageLabel: language };
			const { languageLabel, level } = { ...item, ...tLang };
			const levelLabel = LANGUAGE_PROFICIENCY_TRANSLATIONS[level?.toLowerCase()] || level;
			return {
				...tLang,
				levelLabel,
			};
		});

		// Reordering by level
		languages = [
			...Object.values(LANGUAGE_PROFICIENCY_TRANSLATIONS)
				.map((level) => ([languages?.filter(({ levelLabel }) => level === levelLabel)]))
				.flat(2),
			...languages?.filter(({ levelLabel }) => !Object.values(LANGUAGE_PROFICIENCY_TRANSLATIONS).includes(levelLabel)),
		];

		// Removing duplicates
		// localDebug('before set', languages?.length);
		languages = [...new Set(languages.map(JSON.stringify))].map(JSON.parse);
		// localDebug('after set', languages?.length);

		languages = [...new Set(languages.map((item) => item.languageLabel))].map((item) => {
			const [lang] = languages.filter((l) => l.languageLabel === item);
			return lang;
		});

		return languages;
	}

	hasCareerLanguages() { return !isEmpty(this.getCareerLanguages()); }

	hasLanguages() { return !isEmpty(this.getLanguages()); }

	getProficientLanguages() {
		const languages = this.getLanguages();
		return languages.filter((item) => {
			const { levelLabel } = item;
			return !levelLabel || (ALLOWED_LANGUAGE_PROFICIENCIES.includes(levelLabel));
		});
	}

	hasProficientLanguages() { return !isEmpty(this.getProficientLanguages()); }

	getCoachingLanguages() {
		return (
			pTagsAsList(this.getCoaching()?.coachingLanguages)
				.map((value) => {
					const [language, level] = [
						...value?.replaceAll(' - ', ':').split(':').map((t) => t.trim().replaceAll('&nbsp;', ''))];
					// localDebug('UserModel.getCoachingLanguages', { language, level });
					return language
						.replaceAll(' &amp; ', ' / ')
						.replaceAll(', ', ' / ')
						.replaceAll(' and ', ' / ')
						.split(' / ')
						.map((lang) => ({ language: lang, level }));
				}).flat()
		);
	}

	hasCoachingLanguages() { return !isEmpty(this.getCoachingLanguages()); }

	hasCareerLanguage() { return !isEmpty(this.getCareerLanguage()); }

	getCareerLanguage() { return this.career?.language; }

	getResumeLanguages() { return this.getResume()?.languages; }

	hasResumeLanguages() { return !isEmpty(this.getResumeLanguages()); }

	getProfileDEICriteria() {
		return this.jobSearch?.deiCriteria?.map(
			(value) => DEI_CRITERIA_LABELS[value] || value,
		);
	}

	hasProfileDEICriteria() { return this.getProfileDEICriteria()?.length > 0; }

	getCareerHardSkills() {
		let skills = [
			...this?.career?.hardSkill?.map((value) => ({ value })) || [],
			// ...userModel?.getCoachingSoftSkills()?.map(value => ({ value })) || [],
		].map((item) => ({ ...item, label: getHardSkillLabel(item.value) }));

		skills = skills
			.filter((skill, i, a) => !skills
				.slice(0, i)
				.map((s) => s.label?.toLowerCase())
				.includes(skill.label.toLowerCase()));
		return skills.map((s) => s.label);
	}

	getCareerSpecifics() {
		return this?.career?.careerSpecific?.map(getCareerSpecificsLabel);
	}

	hasCareerSpecifics() { return this.getCareerSpecifics()?.length > 0; }

	getJobSearchJobRoles() {
		return this?.jobSearch?.jobRole?.map(getJobRoleLabel);
	}

	hasJobSearchJobRoles() { return !isEmpty(this.getJobSearchJobRoles()); }

	getJobSearchJobContracts({ sorted = false } = { sorted: false }) {
		let values = this?.jobSearch?.jobContract;
		if (!values) return values;
		values = arrayWrap(values).filter((item) => item);
		if (sorted) {
			values = getSortedJobContracts()
				.filter((item) => values.includes(item));
		}
		return values?.map(getJobContractLabel);
	}

	hasJobSearchJobContracts() { return !isEmpty(this.getJobSearchJobContracts()); }

	getJobSearchJobLevels({ sorted = false } = { sorted: false }) {
		if (!this?.isNewlyOnboarded) {
			return [];
		}
		let values = this?.jobSearch?.jobLevel;
		if (!values) return values;
		values = arrayWrap(values).filter((item) => item);
		if (sorted) {
			values = getSortedJobLevels()
				.filter((item) => values.includes(item));
		}
		return values?.map(getJobLevelLabel);
	}

	hasJobSearchJobLevels() { return !isEmpty(this.getJobSearchJobLevels()); }

	getJobSearchJobLocations({
		sorted = false,
		withCountryCode = false,
		withCountryFlag = false,
		withCityCountry = false,
		withCityCountryCode = false,
		withCityCountryFlag = false,
		withFlagNbsp = false,
	} = {
		sorted: false,
		withCountryCode: false,
		withCountryFlag: false,
		withCityCountry: false,
		withCityCountryCode: false,
		withCityCountryFlag: false,
		withFlagNbsp: false,
	}) {
		let values = this?.jobSearch?.jobLocation;
		if (!values) return values;

		values = arrayWrap(values);
		if (sorted) {
			values = values
				.filter((item) => item)
				.map((item) => {
					if (item.indexOf('continent:anywhere') === 0) return ({ value: item, sort: 0 });
					if (item.indexOf('continent') === 0) return ({ value: item, sort: 1 });
					if (item.indexOf('country:') === 0) return ({ value: item, sort: 2 });
					return ({ value: item, sort: 3 });
				});
			values.sort(sortOn({ key: 'sort' }));
			values = values.map((item) => item.value);
		}
		return values
			?.map((item) => getJobLocationLabel({
				value: item,
				withCountryCode,
				withCountryFlag,
				withCityCountry,
				withCityCountryCode,
				withCityCountryFlag,
				withFlagNbsp,
			}))
			?.filter((item) => item);
	}

	hasJobSearchJobLocations() { return !isEmpty(this.getJobSearchJobLocations()); }

	getJobSearchEmploymentVisas({
		withFlag = false,
		withFlagNbsp = false,
	} = {
		withFlag: false,
		withFlagNbsp: false,
	}) {
		let values = this?.jobSearch?.employmentVisa;
		if (!values) return values;

		values = arrayWrap(values);

		return values
			?.map((item) => getEmploymentVisaLabel({
				value: item,
				withFlag,
				withFlagNbsp,
			}))
			?.filter((item) => item);
	}

	hasJobSearchEmploymentVisas() { return !isEmpty(this.getJobSearchEmploymentVisas()); }

	getJobSearchEmploymentEligibilities({
		withFlag = false,
		withFlagNbsp = false,
	} = {
		withFlag: false,
		withFlagNbsp: false,
	}) {
		let values = this?.jobSearch?.employmentVisa;
		if (!values) return values;

		values = arrayWrap(values);

		return EMPLOYMENT_VISA_FILTER_OPTIONS
			?.map((employmentVisaOption) => getEmploymentEligibilityLabel({
				values,
				employmentVisaOption,
				withFlag,
				withFlagNbsp,
			}))
			?.filter((item) => item);
	}

	hasJobSearchEmploymentEligibilities() { return !isEmpty(this.getJobSearchEmploymentEligibilities()); }

	getJobSearchJobRemotes({
		sorted = false,
	} = {
		sorted: false,
	}) {
		let values = this?.jobSearch?.jobRemote;
		if (!values) return values;

		values = arrayWrap(values);

		if (sorted) {
			values = getSortedJobRemotes()
				.filter((item) => values.includes(item));
		}

		return values
			?.map(getJobRemoteLabel)
			?.filter((item) => item);
	}

	hasJobSearchJobRemotes() { return !isEmpty(this.getJobSearchJobRemotes()); }

	getProfileBiography() { return this.information?.biography; }

	hasProfileBiography() { return this.getProfileBiography()?.trim?.()?.length > 0; }

	getResumeSummary() { return this.getResume()?.summary; }

	hasResumeSummary() { return this.getResumeSummary()?.length > 0; }

	getResumeContact(includeLinkedIn = true) {
		const { linkedin, links, ...rest } = this.getResume()?.contact || {};
		return [
			...linkedin && includeLinkedIn
				? [{ label: 'LinkedIn', value: linkedin }]
				: [],
			...Object.entries(rest || {}).map(([label, value]) => ({
				label: (label?.charAt(0) || '')?.toUpperCase() + (label || '')?.slice(1),
				value,
			})),
			...links?.map((link, index) => ({
				label: `Link #${index}`,
				value: link,
			})) || [],
		];
	}

	hasResumeContact() { return this.getResumeContact()?.length > 0; }

	getResumeTopSkills() { return this.getResume()?.topskills; }

	countResumeTopSkills() { return this.getResumeTopSkills()?.length; }

	hasResumeTopSkills() { return this.countResumeTopSkills() > 0; }

	getResumeCertifications() { return this.getResume()?.certifications; }

	countResumeCertifications() { return this.getResumeCertifications()?.length; }

	hasResumeCertifications() { return this.countResumeCertifications() > 0; }

	getResumeExperience() { return this.getResume()?.experience; }

	getResumeExperienceFlattened() {
		return this.getResumeExperience()?.organizations?.map(
			(organization) => {
				const { name, positions } = organization;
				return positions?.map(
					(position) => ({ ...position, organization: name }),
				);
			},
		).flat();
	}

	countResumeExperience() { return this.getResumeExperienceFlattened()?.length; }

	hasResumeExperience() { return this.countResumeExperience() > 0; }

	getResumeEducation() { return this.getResume()?.education; }

	countResumeEducation() { return this.getResumeEducation()?.degrees?.length; }

	hasResumeEducation() { return this.countResumeEducation() > 0; }

	canDisplayTalentAvatars(user) {
		if (user?.information?.companyId) return true;

		let result = !this?.companyShouldAnonTalentAvatars();

		if (this?.companyCanRecruiterOverrideAnonTalentAvatars()) {
			result = !this?.companyMemberShouldAnonTalentAvatars();
		}

		// const canDisplayAvatarsFromCompany = !this?.companyShouldAnonTalentAvatars();
		// const canDisplayAvatarsFromUser = this?.companyCanRecruiterOverrideAnonTalentAvatars() && !this?.companyMemberShouldAnonTalentAvatars();
		//
		// let result = canDisplayAvatarsFromCompany || ;

		// LocalDebug.logInfo({ className, method: 'canDisplayTalentAvatars' }, {
		// 	// log,
		// 	'this?.companyShouldAnonTalentAvatars()': this?.companyShouldAnonTalentAvatars(),
		// 	'this?.companyCanRecruiterOverrideAnonTalentAvatars()': this?.companyCanRecruiterOverrideAnonTalentAvatars(),
		// 	'this?.companyMemberShouldAnonTalentAvatars()': this?.companyMemberShouldAnonTalentAvatars(),
		// 	// canDisplayAvatarsFromCompany,
		// 	// canDisplayAvatarsFromUser,
		// 	result,
		// });

		return result;
	}

	canDisplayTalentNames(user = null) {
		if (user?.information?.companyId) return true;

		let result = !this?.companyShouldAnonTalentAvatars();

		if (this?.companyCanRecruiterOverrideAnonTalentNames()) {
			result = !this?.companyMemberShouldAnonTalentNames();
		}

		// LocalDebug.logInfo({ className, method: 'canDisplayTalentNames' }, {
		// 	// log,
		// 	'this?.companyShouldAnonTalentNames()': this?.companyShouldAnonTalentNames(),
		// 	'this?.companyCanRecruiterOverrideAnonTalentNames()': this?.companyCanRecruiterOverrideAnonTalentNames(),
		// 	'this?.companyMemberShouldAnonTalentNames()': this?.companyMemberShouldAnonTalentNames(),
		// 	// canDisplayNamesFromCompany,
		// 	// canDisplayNamesFromUser,
		// 	result,
		// });

		return result;
	}

	isDefaultRecipient() {
		return this?.companyModel?.defaultRecipientForApplysIfNoRecipients?.toString?.() === (documentId(this))?.toString?.();
	}

	companyShouldNotifyAllOnApplysIfNoRecipients() {
		return this?.companyModel?.shouldNotifyAllOnApplysIfNoRecipients;
	}

	companyMemberShouldBeNotifiedForApplysIfNoRecipient() {
		return this?.companyMember?.shouldBeNotifiedForApplysIfNoRecipient !== false;
	}

	companyShouldAnonTalentAvatars() {
		return this?.companyModel?.shouldAnonTalentAvatars;
	}

	companyShouldAnonTalentNames() {
		return this?.companyModel?.shouldAnonTalentNames;
	}

	companyMemberShouldAnonTalentAvatars() {
		return this?.companyMember?.shouldAnonTalentAvatars !== false;
	}

	companyMemberShouldAnonTalentNames() {
		return this?.companyMember?.shouldAnonTalentNames === true;
	}

	companyCanRecruiterOverrideAnonTalentAvatars() {
		return this?.companyModel?.canRecruiterOverrideAnonTalentAvatars !== false;
	}

	companyCanRecruiterOverrideAnonTalentNames() {
		return this?.companyModel?.canRecruiterOverrideAnonTalentNames !== false;
	}

	isMe(user) {
		const id = (documentId(this))?.toString?.();
		if (!id) return false;
		const uid = (documentId(user))?.toString?.();
		return id === uid;
	}

	getSalaryAskRangeLine() {
		const salaryAsk = this?.jobSearch?.salaryAsk;
		let [low, high] = salaryAsk?.range || [];
		if (low) low = Math.round(low / 1000);
		if (high) high = Math.round(high / 1000);
		if (!low && !high) return '';
		return [
			...salaryAsk?.unit ? [salaryAsk?.unit] : [],
			[
				...low ? [low] : [],
				...high ? [high] : [],
			].join('-'),
			'k',
		].join('');
	}

	hasProfileExpectedSalary() { return this?.jobSearch?.salaryAsk?.range?.length > 0; }

	getCareerIsReachableByRecruiters() {
		/*
		We test user?.career?.isReachableByRecruiters with "!== false" to consider all the "old" registered
		users as reachable by default (because they don't have anything set for "career.isReachableByRecruiters")
		Only newly registered users will have "career.isReachableByRecruiters: false" by default upon registration,
		and thus will be considered unreachable by default.
		*/
		return this.career?.isReachableByRecruiters !== false;
	}
}
