import CAREER_EXPERIENCE from '../../property/career-experience';
import { innerObjPropExtractor } from '../../property/property-extractor';
import { innerObjPropMapper } from '../../property/property-mapper';

const USER_PROPERTY_CAREER_EXPERIENCE_KEY = CAREER_EXPERIENCE.key;

const USER_PROPERTY_CAREER_EXPERIENCE_MULTI = false;
const USER_PROPERTY_CAREER_EXPERIENCE_FREE = false;
const USER_PROPERTY_CAREER_EXPERIENCE_MAX = 1;

const USER_PROPERTY_CAREER_EXPERIENCE_FIELD = 'career.experience';
const USER_PROPERTY_CAREER_EXPERIENCE_MAPPER = innerObjPropMapper(USER_PROPERTY_CAREER_EXPERIENCE_FIELD);
const USER_PROPERTY_CAREER_EXPERIENCE_EXTRACTOR = innerObjPropExtractor(USER_PROPERTY_CAREER_EXPERIENCE_FIELD);

export default {
	...CAREER_EXPERIENCE,
	key: USER_PROPERTY_CAREER_EXPERIENCE_KEY,
	USER_PROPERTY_CAREER_EXPERIENCE_KEY,
	free: USER_PROPERTY_CAREER_EXPERIENCE_FREE,
	USER_PROPERTY_CAREER_EXPERIENCE_FREE,
	multi: USER_PROPERTY_CAREER_EXPERIENCE_MULTI,
	USER_PROPERTY_CAREER_EXPERIENCE_MULTI,
	max: USER_PROPERTY_CAREER_EXPERIENCE_MAX,
	USER_PROPERTY_CAREER_EXPERIENCE_MAX,
	mapper: USER_PROPERTY_CAREER_EXPERIENCE_MAPPER,
	USER_PROPERTY_CAREER_EXPERIENCE_MAPPER,
	extractor: USER_PROPERTY_CAREER_EXPERIENCE_EXTRACTOR,
	USER_PROPERTY_CAREER_EXPERIENCE_EXTRACTOR,
	field: USER_PROPERTY_CAREER_EXPERIENCE_FIELD,
	USER_PROPERTY_CAREER_EXPERIENCE_FIELD,
};
