import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Divider, Form } from 'antd';
import { getAllCompanyPlansExtraTags } from '../../../actions/company';
import FormBoxTitle from '../../app/form/FormBoxTitle';
import FormSubmitButton from '../../app/form/FormSubmitButton';
import CompanyAddFields from './CompanyAddFields';
import CompanyPlanFormItem, { companyPlanValuePath } from '../companyPlan/CompanyPlanFormItem';
import CompanyPlanExtraTagsFormItem from '../companyPlan/CompanyPlanExtraTagsFormItem';
import ModalContainerBase from '../../../contexts/modals/ModalContainerBase';
import FormItem from '../form/FormItem';
import CompanyPlanGenderScoreSurveyFormItem, { companyPlanGenderScoreSurveyPath } from '../companyPlan/genderscore/CompanyPlanGenderScoreSurveyFormItem';
import CompanyPlanGenderScoreIsEnabledFormItem, { companyPlanGenderScoreIsEnabledPath } from '../companyPlan/genderscore/CompanyPlanGenderScoreIsEnabledFormItem';
import CompanyPlanGenderScoreDatesFormItem from '../companyPlan/genderscore/CompanyPlanGenderScoreDatesFormItem';
import CompanyPlanUMSSurveyManagerFormItem, { companyPlanUMSSurveyManagerPath } from '../companyPlan/ums/CompanyPlanUMSSurveyManagerFormItem';
import CompanyPlanUMSSurveyEmployeeFormItem, { companyPlanUMSSurveyEmployeePath } from '../companyPlan/ums/CompanyPlanUMSSurveyEmployeeFormItem';
import CompanyPlanUMSIsEnabledFormItem, { companyPlanUMSIsEnabledPath } from '../companyPlan/ums/CompanyPlanUMSIsEnabledFormItem';
import CompanyPlanUMSPurchasedAtFormItem from '../companyPlan/ums/CompanyPlanUMSPurchasedAtFormItem';
import CompanyModel from '../../../models/CompanyModel';
import { GENDERSCORE_LABEL, UMS_LABEL } from '../../../constants/constant';
import { sanitizeCompanyPath } from '../../../utils/common';
import { innerObjPropExtractor } from '../../../constants/property/property-extractor';

const CompanyAddModal = ({
	open,
	onClose,
	onSave,
}) => {
	const className = 'CompanyAddModal';

	const [form] = Form.useForm();
	const dispatch = useDispatch();
	const [
		isGenderScoreIsEnabledToggleDisabled,
		setIsGenderScoreIsEnabledToggleDisabled,
	] = useState(false);
	const [isGenderScoreSurveyFieldActivated, setIsGenderScoreSurveyFieldActivated] = useState(false);
	const [isUMSIsEnabledToggleDisabled, setIsUMSIsEnabledToggleDisabled] = useState(false);
	const [isUMSSurveyFieldActivated, setIsUMSSurveyFieldActivated] = useState(false);

	const handleClose = () => {
		form.resetFields();
		onClose();
	};

	useEffect(() => {
		if (open) {
			dispatch(getAllCompanyPlansExtraTags());
		}
	}, [open]);

	return (
		<ModalContainerBase
			open={open}
			onCancel={handleClose}
			width={700}
		>
			<FormBoxTitle
				title={<>Add a company</>}
				details={<>
					Include the information of the company you want to create
				</>}
			/>
			<Form
				form={form}
				name='add-company'
				labelCol={{ span: 10 }}
				wrapperCol={{ span: 14 }}
				labelAlign='left'
				layout='horizontal'
				onFinish={() => {
					form
						.validateFields()
						.then((values) => {
							onSave(values);
							form.resetFields();
						})
						.catch((info) => {});
				}}
				onValuesChange={(values) => {
					const companyModel = new CompanyModel(form.getFieldsValue());

					// Force visibility of survey field, only if genderScore.isEnabled toggled by user
					const gsEnabled = innerObjPropExtractor(companyPlanGenderScoreIsEnabledPath)(values);
					if ([true, false].includes(gsEnabled)) setIsGenderScoreSurveyFieldActivated(gsEnabled);

					// Force visibility of UMS survey field, only if ums.isEnabled toggled by user
					const umsEnabled = innerObjPropExtractor(companyPlanUMSIsEnabledPath)(values);
					if ([true, false].includes(umsEnabled)) setIsUMSSurveyFieldActivated(umsEnabled);

					if (Object.keys(values).includes('name')) {
						const name = form.getFieldValue('name');
						if (name || name === '') {
							form.setFieldValue('path', sanitizeCompanyPath(form.getFieldValue('name')));
						}
					}

					const companyPlan = innerObjPropExtractor(companyPlanValuePath)(values);
					if (companyPlan) {
						/* **** GENDER SCORE **** */

						if (companyModel.shouldGenderScoreBeEnabledFromPlan()) {
							// Force genderScore.isEnabled to true
							form.setFieldsValue(companyModel);
							form.setFieldValue(companyPlanGenderScoreIsEnabledPath, true);
							setIsGenderScoreSurveyFieldActivated(true);
						} else {
							// Force genderScore.isEnabled to false
							form.setFieldValue(companyPlanGenderScoreIsEnabledPath, false);
							form.setFieldValue(companyPlanGenderScoreSurveyPath, undefined);
							setIsGenderScoreSurveyFieldActivated(false);
						}

						// Disable genderScore.isEnabled field
						// only for GS plans (which require it by definition)
						setIsGenderScoreIsEnabledToggleDisabled(
							companyModel.isGenderScoreCompanyPlan(),
						);

						/* **** UMS **** */

						if (companyModel.shouldUMSBeEnabledFromPlan()) {
							// Force ums.isEnabled to true
							form.setFieldsValue(companyModel);
							form.setFieldValue(companyPlanUMSIsEnabledPath, true);
							setIsUMSSurveyFieldActivated(true);
						} else {
							// Force ums.isEnabled to false
							form.setFieldValue(companyPlanUMSIsEnabledPath, false);
							form.setFieldValue(companyPlanUMSSurveyManagerPath, undefined);
							form.setFieldValue(companyPlanUMSSurveyEmployeePath, undefined);
							setIsUMSSurveyFieldActivated(false);
						}

						// Disable ums.isEnabled field
						// only for UMS plans (which require it by definition)
						setIsUMSIsEnabledToggleDisabled(
							companyModel.isUMSCompanyPlan(),
						);
					}
				}}
			>
				<CompanyAddFields/>

				<Divider orientation='left'>Plan</Divider>

				<CompanyPlanFormItem />

				<CompanyPlanExtraTagsFormItem />

				<Divider orientation='left'>{GENDERSCORE_LABEL}</Divider>

				<CompanyPlanGenderScoreIsEnabledFormItem
					disabled={isGenderScoreIsEnabledToggleDisabled}
				/>

				{isGenderScoreSurveyFieldActivated && (
					<>
						<CompanyPlanGenderScoreSurveyFormItem
							required={isGenderScoreSurveyFieldActivated}
						/>
						<CompanyPlanGenderScoreDatesFormItem
							form={form}
							required={isGenderScoreSurveyFieldActivated}
							withExpirationDate={false}
							withPurchaseDateWarning={false}
							placement='topLeft'
						/>
					</>
				)}

				<Divider orientation='left'>{UMS_LABEL}</Divider>

				<CompanyPlanUMSIsEnabledFormItem
					disabled={isUMSIsEnabledToggleDisabled}
				/>

				{isUMSSurveyFieldActivated && (
					<>
						<CompanyPlanUMSPurchasedAtFormItem
							form={form}
							required={isUMSSurveyFieldActivated}
							withExpirationDate={false}
							withPurchaseDateWarning={false}
							placement='topLeft'
						/>
						<CompanyPlanUMSSurveyManagerFormItem
							required={isUMSSurveyFieldActivated}
						/>
						<CompanyPlanUMSSurveyEmployeeFormItem
							required={isUMSSurveyFieldActivated}
						/>
					</>
				)}
				<FormItem
					label={<>&nbsp;</>}
					style={{ marginBottom: 0 }}
				>
					<FormSubmitButton
						disabled={false}
						style={{ width: '100%' }}
					>
						Save company
					</FormSubmitButton>
				</FormItem>
			</Form>
		</ModalContainerBase>
	);
};

export default CompanyAddModal;
