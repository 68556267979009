import { useTheme } from '@emotion/react';
import { FaChevronDown, FaDownload } from 'react-icons/fa';
import domtoimage from 'dom-to-image';
import { jsPDF } from 'jspdf';
import {
	Button, Dropdown, Spin, message,
} from 'antd';
import React, { useContext, useState } from 'react';
import { mapSeries, map } from 'async';
import { LoadingOutlined } from '@ant-design/icons';
import { arrayWrap } from '../../utils/common';
import PDFBuilder from './results/pdf/base/PDFBuilder';
import fonts from '../fonts';
import { getPDFManagerPages } from './results/PDFManager';
import { UnbiasedScoreContext } from '../../contexts/UnbiasedScoreProvider';
import { request } from '../../services/api';
import { LocalDebug } from '../../utils/LocalDebug';

const className = 'DownloadButton';

export const saveAsPNG = ({ uri, filename, setIsLoading }) => {
	const link = document.createElement('a');
	if (typeof link.download === 'string') {
		link.href = uri;
		link.download = filename;
		// Firefox requires the link to be in the body
		document.body.appendChild(link);
		link.click();
		document.body.removeChild(link);
	} else {
		window.open(uri);
	}
	setIsLoading?.(false);
};

export const savePDFTest = async ({
	pageNodes,
	context,
	filename,
	setIsLoading,
}) => {
	try {
		const pdf = new PDFBuilder({ filename, context });
		LocalDebug.logInfo({ className, method: 'savePDFTest' }, { pdf });
		const pages = await getPDFManagerPages({
			doc: pdf,
			company: { name: 'Company' },
			manager: { firstName: 'Julien', lastName: `Londeix ${Math.round(Math.random() * 100)}` },
			date: new Date(),
			context,
		});
		LocalDebug.logInfo({ className, method: 'savePDFTest' }, { pages });

		pdf.savePages(pages, context);
		const output = pdf.pdf.output('blob');
		LocalDebug.logInfo({ className, method: 'savePDFTest' }, { output });

		const formData = new FormData();
		formData.append('file', output, 'filename');
		formData.append('filename', 'file');
		const result = await request.post(
			'/ums/pdf',
			formData,
			{ headers: { 'content-type': 'multipart/form-data' } },
		);
		message.success('PDF uploaded to bucket');
		context.setRefresh((p) => p + 1);
		LocalDebug.logInfo({ className, method: 'savePDFTest' }, { result });
	} catch (e) {
		console.error(e);
	}
	setIsLoading?.(false);
};
export const savePDFPages = async ({
	pageNodes,
	filename,
	setIsLoading,
}) => {
	console.log('savePDFPages');

	const pdf = new jsPDF('p', 'mm', 'a4');

	// pageNodes = [pageNodes[0]];
	pageNodes = [pageNodes[1]];
	const firstNode = pageNodes?.[0];
	const imgHeight = firstNode.offsetHeight;
	const imgWidth = firstNode.offsetWidth;
	const pdfWidth = Math.round(pdf.internal.pageSize.getWidth());
	const pdfHeight = Math.round(pdf.internal.pageSize.getHeight());
	const imgWidthOnPdf = pdfWidth;
	const imgHeightOnPdf = imgWidthOnPdf * imgHeight / imgWidth;

	[fonts.SpaceGroteskBold, fonts.SpaceGroteskRegular].forEach((font) => {
		pdf.addFileToVFS(font.fontFileName, font.font);
		pdf.addFont(font.fontFileName, font.fontName, font.fontType);
	});
	pdf.setFontSize(18);
	pdf.setFont(fonts.SpaceGrotesk.regular.fontName);

	const pageImages = await map(
		pageNodes,
		async (pageNode) => {
			const uri = await domtoimage.toPng(pageNode, { bgcolor: '#FFFFFF' });
			console.log('savePDFPages', 'image done');
			return uri;
		},
	);

	console.log('savePDFPages', 'images done');

	pageImages.map((pageImage, index) => {
		if (index !== 0) pdf.addPage();
		// const {
		// 	fontName: normalFontName,
		// } = SPACE_GROTESK_FONT_NORMAL_DATA;
		// pdf.setFont(normalFontName);
		pdf.addImage(pageImage, 'JPEG', 0, 0, imgWidthOnPdf, imgHeightOnPdf);
		pdf.text('Page 1 of 2', 0, 0, 'right');
	});
	let xo = 0;
	let yo = 0;

	let lastItem;
	[
		{
			text: `What is a bias? ${pdfWidth}x${pdfHeight}`,
			fontFamily: fonts.SpaceGroteskBold.fontName,
			fontSize: 45,
			x: 10,
			y: 32,
			color: 'red',
			options: {
				charSpace: -1,
			},
		},
		{
			text: '• Unconscious mental shortcuts that aim to process information, make a decision, and make judgments quickly',
			fontSize: 13.5,
			dy: 8,
			color: 'red',
			options: {
				maxWidth: 129,
			},
		},
		{
			text: '• Can lead to erroneous judgments',
			fontSize: 13.5,
			dy: 2,
			color: 'red',
			options: {
				maxWidth: 129,
			},
		},
		{
			text: '• Often be based on stereotypes and social roles',
			fontSize: 13.5,
			x: 10,
			dy: 2,
			color: 'red',
			options: {
				maxWidth: 129,
			},
		},
		{
			text: 'Stereotypes are culturally constructed and internalized over time and according to culture, through education, institutions, and interactions (Schmader, 2023). As biases toward women in tech are based on gender stereotypes, it appears to be more acquired than innate.',
			fontSize: 13.5,
			x: 10,
			dy: 8,
			color: 'red',
			options: {
				maxWidth: 129,
			},
		},
	].map(({
		text, fontFamily, fontSize, x, y, dx, dy, options, color,
	}, index) => {
		pdf.setLineHeightFactor(1.3);
		pdf.setFont(fontFamily || fonts.SpaceGrotesk.regular.fontName);
		pdf.setFontSize(fontSize || 8);
		pdf.setTextColor(color || '#000000');
		const { w, h } = pdf.getTextDimensions(
			text || 'Hello world',
			{
				maxWidth: options?.maxWidth || pdfWidth,
			},
		);
		xo = x || (xo + (dx || 0));
		yo = y || (yo + (dy || 0));
		console.log('pdf.text', {
			index,
			text,
			w,
			h,
			xo,
			yo,
			// fontSize,
			// x,
			// y,
			// dx,
			// dy,
			// options,
			// color,
		});
		pdf.text(text || `#${index} > ${JSON.stringify({
			fontSize, x, y, dx, dy, xo, yo, options,
		})}`, x || xo, y || yo, options);
		yo += h;
	});

	console.log('savePDFPages', 'pdf created');

	setIsLoading?.(false);
	pdf.save(filename);
};

const UMSStaffDownloadButton = ({
	...props
}) => {
	const className = 'UMSStaffDownloadButton';
	const theme = useTheme();
	const [isLoading, setIsLoading] = useState(false);

	const unbiasedScoreContext = useContext(UnbiasedScoreContext);

	const datesFormat = 'YYYY-MM-DD';

	const downloadPNG = ({ id, bgcolor }) => {
		setIsLoading(true);
		// const node = document.getElementById(id || 'ums');
		// const node = document.getElementById('pdf-page');
		const node = document.getElementsByClassName('pdf-page')?.[0];
		console.log('downloadPNG', {
			node,
			umspage: document.getElementById('umspage'),
			page0: document.getElementById('page-0'),
			class: document.getElementsByClassName('pdf-page'),
		});
		domtoimage.toPng(node, {
			bgcolor,
		})
			.then((dataUrl) => {
				const filename = [
					'50inTech-Unbiased-Managing-Score',
					'.png',
				].join('');

				saveAsPNG({
					uri: dataUrl,
					filename,
					setIsLoading,
				});
			})
			.catch((error) => {
				console.error('oops, something went wrong!', error);
				setIsLoading(false);
			});
	};

	const downloadPDF = async ({ id, bgcolor = '#FFFFFF' }) => {
		setIsLoading(true);
		// const node = document.getElementsByClassName('pdf-page')?.[0];
		// console.log('downloadPDF', {
		// 	node,
		// 	umspage: document.getElementById('umspage'),
		// 	page0: document.getElementById('page-0'),
		// 	class: document.getElementsByClassName('pdf-page'),
		// });
		const filename = [
			'50inTech-GenderScore-Report',
			'.pdf',
		].join('');

		await savePDFTest({
			filename,
			context: unbiasedScoreContext,
		});
		// await savePDFPages({
		// 	pageNodes: document.getElementsByClassName('pdf-page'),
		// 	filename,
		// });

		setIsLoading(false);
	};

	const itemStyle = {
		color: theme.color.darkerturquoise,
		fontSize: 14,
		fontWeight: 500,
		padding: 10,
	};
	const downloadItems = [
		{
			label: (
				<div style={itemStyle}
					onClick={downloadPDF}>
					Download as PDF
				</div>
			),
			key: '1',
		},
		{
			label: (
				<div style={itemStyle}
					onClick={downloadPNG}>
					Download as PNG
				</div>
			),
			key: '2',
		},
	];

	return (
		<Button
			style={{
				background: theme.color.lightturquoise,
				color: theme.color.darkerturquoise,
				border: `1px solid ${theme.color.darkturquoise}`,
				borderRadius: 8,
				...props?.style,
				// position: 'fixed',
				// zIndex: 100,
			}}
			onClick={downloadPDF}
		>
			{
				isLoading
					? <Spin indicator={<LoadingOutlined spin style={{ fontSize: 14 }} />} />
					: <FaDownload/>
			}
			&nbsp;Download
		</Button>
	);

	return <Dropdown menu={{ items: downloadItems }}
		dropdownRender={(menu) => (
			<div style={{
				borderRadius: 4,
			}}>
				{React.cloneElement(menu, {
					style: {
						fontWeight: 400,
					},
				})}

			</div>
		)}
	>
		<div style={{
			padding: '0px 16px',
			color: theme.color.darkerturquoise,
			border: `1px solid ${theme.color.darkturquoise}`,
			borderRadius: 8,
			background: theme.color.lightturquoise,
			fontSize: 16,
			fontWeight: 700,
			height: 40,
			display: 'flex',
			alignItems: 'center',
			cursor: 'pointer',
			gap: 4,
			...props?.style,
		}}
		onClick={(e) => e.preventDefault()}>
			{isLoading ? <Spin
				indicator={
					<LoadingOutlined
						style={{
							fontSize: 14,
						}}
						spin
					/>
				}
			/>
				: <FaDownload/>
			}
			<span>&nbsp;Download</span><FaChevronDown/>
		</div>
	</Dropdown>;
};
export default UMSStaffDownloadButton;
