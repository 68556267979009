import { Steps } from 'antd';

const UserResumeManagerDetails = ({
	user,
	onRefresh,
	...props
}) => {
	return (
		<>
			<h3>How to upload a LinkedIn resume PDF into a talent's profile</h3>
			<Steps
				direction="vertical"
				size="small"
				current={5}
				style={{ maxWidth: 600 }}
			>
				<Steps.Step
					icon={<>1.</>}
					title={
						<>
							<b>Open</b> the LinkedIn profile by clicking the '
							<b>Open Profile</b>' button
						</>
					}
					description={
						<>
							If the talent didn't provide her LinkedIn profile during
							onboarding, a search will be launched.
						</>
					}
				/>
				<Steps.Step
					icon={<>2.</>}
					title={
						<>
							<b>Download</b> the LinkedIn resume in PDF by selecting&nbsp;
							<div
								style={{
									display: 'inline-block',
									border: '1px solid black',
									padding: '2px 12px 1px',
									borderRadius: 100,
								}}
							>
								<b>More</b>
							</div>
							&nbsp; > &nbsp;
							<div
								style={{
									display: 'inline-block',
									border: '1px solid black',
									padding: '2px 12px 1px',
									borderRadius: 100,
								}}
							>
								<b>Save to PDF</b>
							</div>
						</>
					}
					description={
						<>
							Don't be too hasty: read the next step before actually saving the
							file!
						</>
					}
				/>
				<Steps.Step
					icon={<>3.</>}
					title={
						<>
							<b>Rename and save</b> the file on your computer
						</>
					}
					description={
						<>
							To rename the file, just paste (<b>Ctrl+V</b> or <b>Command+V</b>)
							when the save window appears: the content of your clipboard should
							magically contains the expected name!
						</>
					}
				/>
				<Steps.Step
					icon={<>4.</>}
					title={
						<>
							<b>Upload</b> the PDF by clicking the '<b>Upload LinkedIn PDF</b>'
							button
						</>
					}
					description={
						<>
							Select the file you just saved. Some tips will indicate if
							everything is okay.
						</>
					}
				/>
				<Steps.Step
					icon={<>5.</>}
					title={
						<>
							<b>Check</b> the profile of the talent
						</>
					}
					description={
						<>
							Verify that the new content is consistent with LinkedIn (and
							report any potential bugs)
						</>
					}
				/>
			</Steps>
		</>
	);
};

export default UserResumeManagerDetails;
