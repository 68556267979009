import { useState } from 'react';
import { Select } from 'antd';
import { useTheme } from '@emotion/react';
import { documentId } from '../../utils/common';
import SURVEYS from '../genderscore/data/surveys';
import { getGenderScoreSurveyTag } from '../../constants/property/genderscore-survey';

const SurveyConfSelect = (
	{
		value,
		onChange,
		disabled = false,
		...props
	},
) => {
	const className = 'SurveyConfSelect';
	const theme = useTheme();

	const options = SURVEYS.options.filter((item) => item);

	return (
		<Select
			className={'survey-conf-select'}
			disabled={disabled}
			allowClear={true}
			{...props}
			value={value}
			style={{
				width: 250,
				...props?.style,
			}}
			onChange={onChange}
		>
			{options.map((option) => (
				<Select.Option
					key={option.value}
					value={option.value}
				>
					{getGenderScoreSurveyTag(option.value)} {option.label}
				</Select.Option>
			))}
		</Select>
	);
};

export default SurveyConfSelect;
