import { Button, Space } from 'antd';
import { useEffect, useState } from 'react';
import { FaArrowRight } from 'react-icons/fa';
import { getNextIncompleteMandatoryStep } from './CompanyPageSteps';
import { documentId } from '../../../../utils/common';

export const CompanyPageNextStepNoContextLink = (
	{
		company,
		step,
		lang,
		setCurrentlyEditingStep,
		theme,
		...props
	},
) => {
	const className = 'CompanyPageNextStepNoContextLink';

	const [nextStep, setNextStep] = useState(step);

	const handleClickBanner = () => {
		setCurrentlyEditingStep?.(documentId(nextStep));
	};

	useEffect(() => {
		if (!company || !lang || step) return;
		setNextStep(getNextIncompleteMandatoryStep({ company, lang }));
	}, [company, lang]);

	return (
		<Space onClick={() => handleClickBanner()}>
			<Button
				type='link'
				style={{
					margin: 0,
					padding: 0,
					...props?.style,
				}}
			>
				<span
					style={{
						textDecoration: 'underline',
						fontWeight: 'bold',
						color: theme?.color?.accent,
					}}
				>
					Next step: {nextStep?.title} <FaArrowRight style={{ marginBottom: -2 }} />
				</span>

			</Button>
		</Space>
	);
};

export default CompanyPageNextStepNoContextLink;
