import { useTheme } from '@emotion/react';

const UserGridCardListSeparator = ({
	placement = 'right',
}) => {
	const theme = useTheme();

	return placement === 'right'
		? <span style={{ opacity: 0.5, color: theme.color.fitBlueElectric }}>&nbsp;• </span>
		: <span style={{ opacity: 0.5, color: theme.color.fitBlueElectric }}> •&nbsp;</span>;
};

export default UserGridCardListSeparator;
