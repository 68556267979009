import { Space } from 'antd';
import { useTheme } from '@emotion/react';
import moment from 'moment';
import UserGridCardLabelValue from './UserGridCardLabelValue';

const UserGridCardLastSeen = (
	{
		user,
		...props
	},
) => {
	const className = 'UserGridCardLastSeen';

	const theme = useTheme();

	const mLimit = moment().add(-3, 'month');

	return (
		<UserGridCardLabelValue
			label={<>Last visit</>}
			valueStyle={{ fontWeight: 'bold', color: theme.color.darkturquoise }}
			{...props}
		>
			{(
				moment(user?.loggedAt).isBefore(mLimit)
					? `more than ${moment(mLimit).fromNow()}`
					: moment(user?.loggedAt).fromNow()
			)}
		</UserGridCardLabelValue>
	);
};

export default UserGridCardLastSeen;
