import ConfigureCompanySettingsPage from '../../containers/configure/CompanySettingsPage';
import roles from '../../config/roles';
import { PERMS_OVERRIDE_STAFF_BACKUP, PERM_GRANT_TYPE } from '../menu.utils';
import MenuBase from '../MenuBase';
import { CheckSquareOutlined } from '@ant-design/icons';

export const ConfigureCompanySettingsIcon = CheckSquareOutlined;

class ConfigureCompanySettingsMenu extends MenuBase {
	static props = {
		name: 'configure-settings',
		path: '/configure/settings',
		aliases: ['^/company-settings$'],
		label: 'Settings',
		icon: <ConfigureCompanySettingsIcon />,
		iconLegacy: '🧰',
		Component: ConfigureCompanySettingsPage,
		perms: [
			{
				type: PERM_GRANT_TYPE,
				roles: [
					roles.STAFF,
					roles.DOCUMENT_ADMIN,
				],
			},
			PERMS_OVERRIDE_STAFF_BACKUP,
		],
	};

	constructor() {
		super(ConfigureCompanySettingsMenu.props);
	}
}

export default ConfigureCompanySettingsMenu;
