import { ENGLISH_LANG } from '../../../../constants/constant';
import CompanyFieldNotProvided from '../shared/CompanyFieldNotProvided';
import { useTheme } from '@emotion/react';

const CompanyPathDisplay = (
	{
		company,
		lang = ENGLISH_LANG,
	},
) => {
	const className = 'CompanyPathDisplay';

	const theme = useTheme();

	const field = 'path';
	const value = company?.getTranslationFieldFallback(field, lang);

	if (!value) return <CompanyFieldNotProvided>Path</CompanyFieldNotProvided>;

	return (
		<div
			style={{
				fontSize: 12,
				color: theme.color.fitBlueElectric,
			}}
		>
			<b>{company.getAppUri()}</b>
		</div>
	);
};

export default CompanyPathDisplay;
