import React, { useContext, useEffect, useState } from 'react';
import { useAuthContext } from '../hooks/useAuthContext';
import { useSelector } from 'react-redux';
import version from '../autoBuildVersion';
import { getCompanySelected } from '../reducers/app';
import { CompanyContext } from './CompanyProvider';
import { LocalDebug } from '../utils/LocalDebug';
import CompanyModel from '../models/CompanyModel';
import config from '../config/config';

export const EventPushContext = React.createContext();

export const EVENT = {
	CATEGORY: {
		GENDERSCORE_SURVEY: 'GenderScore Survey',
		UMS_SURVEY: 'UMS Survey',
	},
	TYPE: {
		// Amplitude
		SETUSERID: 'setUserId',
		IDENTIFY: 'identify',
		LOGOUT: 'logout',

		// App
		DISPLAY: 'display',
		MODAL: 'modal',
		CLICK: 'click',
		HOVER: 'hover',
		// Talent
		TALENT: {
			VIEW: 'talent-view',
			LINK: 'talent-link',
			RESUME: 'talent-resume',
			HIDE: 'talent-hide',
			SUGGEST: 'suggest',
			CONTACT: 'talent-contact',
			SOURCE: 'talent-source',
		},
		SOURCING: {
			ARCHIVE: 'sourcing-archive',
		},
		API: {
			RESULT: 'api-result',
		},
		LINK: {
			INTERNAL: 'link-internal',
			EXTERNAL: 'link-external',
		},
	},
	SECTION: {
		SURVEY_START: 'survey-start',
		SURVEY_SECTION: 'survey-question',
		SURVEY_QUESTION: 'survey-question',
		SURVEY_FOOTER: 'survey-footer',
		SURVEY_LEAVE_MODAL: 'survey-leave-modal',
		SURVEY_PROGRESS_NAV: 'survey-progress-nav',
		SURVEY_REVIEW: 'survey-review',
	},
	LABEL: {
		SECTION_START: 'section-start',
		ANSWER_SAVED: 'answer-saved',
		ANSWER_SKIPPED: 'answer-skipped',
		ANSWER_LATER: 'answer-later',
		ANSWER_PREVIOUS: 'answer-previous',
		SURVEY_LEAVE_MODAL: 'survey-leave-modal',
		SURVEY_LEAVE: 'survey-leave',
		SURVEY_QUESTION: 'survey-question',
		SURVEY_NEXT_REQUIRED: 'survey-next-required',
		SURVEY_REVIEW: 'survey-review',
		SURVEY_SUBMIT: 'survey-submit',
	},
};
export const EVENTS_TO_SPRIG_ACTIONS = {
	[EVENT.TYPE.SETUSERID]: {
		action: 'setUserId',
		payload: (data) => {
			if (!data?.userId) {
				return null;
			}
			return data?.userId;
		},
	},
	[EVENT.TYPE.IDENTIFY]: {
		action: 'setAttributes',
		payload: (data) => { return { ...data }; },
	},
	[EVENT.TYPE.LOGOUT]: {
		action: 'logoutUser',
		payload: (data) => { return { ...data }; },
	},
	[EVENT.TYPE.DISPLAY]: {
		action: 'identifyAndTrack',
		payload: (data) => {
			if (!data?.location?.pathname) {
				return {};
			}
			return {
				eventName: `Display ${data?.location?.pathname}`,
				properties: { ...data },
				...data?.userId ? { userId: data?.userId } : {},
			};
		},
	},
	[EVENT.TYPE.CLICK]: {
		action: 'identifyAndTrack',
		payload: (data) => {
			if (!data?.label) {
				return {};
			}
			return {
				eventName: `Click ${data?.category}|${data?.label}|${data?.section}`,
				properties: { ...data },
				...data?.userIdData ? { userId: data?.userIdData } : {},
			};
		},
	},
};
export const pushEvent = (data) => {
	LocalDebug.logNull({ className: EventPushProvider.name, method: 'pushEvent' }, data);
	const { event, ...eventData } = data;
	const sprigAction = EVENTS_TO_SPRIG_ACTIONS[event];
	if (sprigAction) {
		Sprig(sprigAction.action, sprigAction.payload(eventData));
	}
	return window?.dataLayer?.push?.({
		...data,
		env: config?.env,
		version,
	});
};
export const pushIdentify = (data) => pushEvent(
	{ event: EVENT.TYPE.IDENTIFY, ...data || {} },
);
export const pushLogout = (data) => pushEvent(
	{ event: EVENT.TYPE.LOGOUT, ...data || {} },
);
export const pushSetUserId = (userId) => pushEvent(
	{ event: EVENT.TYPE.SETUSERID, userId },
);
export const pushClick = ({
	category, label, section, value, ...data
} = {}) => pushEvent(
	{
		event: EVENT.TYPE.CLICK, category, label, section, value, ...data || {},
	},
);

export const pushDisplay = ({
	category, label, section, value, ...data
} = {}) => pushEvent(
	{
		event: EVENT.TYPE.DISPLAY, category, label, section, value, ...data || {},
	},
);

export const buildEventCategory = (category) => ({ category });

export const buildEventLabel = (label) => ({ label });

export const buildEventSection = (section) => ({ section });

export const buildEventValue = (value) => ({ value });

export const buildEventContext = (context) => ({ context });
export const addContextToEvent = (event, data) => ({
	...event || {},
	context: {
		...event?.context || {},
		...data || {},
	},
});

const EventPushProvider = ({
	children,
}) => {
	const className = 'EventPushProvider';

	const authProps = useAuthContext();
	const companyProps = useContext(CompanyContext);
	const companySelected = useSelector(getCompanySelected);

	const [identityData, setIdentityData] = useState(null);
	const [userIdData, setUserIdData] = useState(null);

	useEffect(() => {
		const newUserIdData = authProps?.currentUser?.slug;
		if (userIdData === newUserIdData) {
			return;
		}
		if (authProps?.currentUser?.slug) {
			setUserIdData(newUserIdData);
			pushSetUserId(authProps?.currentUser?.slug);
		}
	}, [authProps?.currentUser?.slug]);

	useEffect(() => {
		if (authProps?.currentUser?.acls) {
			const {
				aclLabels,
				companyId,
				isAdmin,
				isStaff,
				isEditor,
				isTalent,
				isTester,
				isDemo,
				isCompanyRecruiter,
				isDocumentAdmin,
				isDocumentEditor,
				isDocumentReader,
				companyRecruiterDocIds,
				isAtsSyncable,
				// isGenderScoreEnabled,
				// isGenderScoreFilled,
				// genderScore,
				// isUMSEnabled,
				// isUMSManagerSurveyFilled,
				// isUMSEmployeeSurveyFilled,
				// planValue,
			} = authProps?.currentUser?.acls || {};

			const {
				// isAtsSyncable,
				isGenderScoreEnabled,
				isGenderScoreFilled,
				genderScore,
				isUMSEnabled,
				isUMSManagerSurveyFilled,
				isUMSEmployeeSurveyFilled,
				planValue,
			} = authProps;

			const newData = {
				roleLabels: aclLabels,
				companyId,
				company: companySelected?.name,
				isGenderHireEnabled: new CompanyModel(companySelected)?.isGenderHireCompanyPlan(),
				isGenderScorePassingAllowed: new CompanyModel(companySelected)
					?.isGenderScorePassingAllowed(authProps?.currentUser?.acls),
				isUMSPassingAllowed: new CompanyModel(companySelected)
					?.isUMSPassingAllowed(authProps?.currentUser?.acls),
				isAdmin,
				isStaff,
				isEditor,
				isCoach: isTalent,
				isTester,
				isDemo,
				isRoleAdmin: isDocumentAdmin,
				isRoleRecruiter: isCompanyRecruiter,
				isRoleEditor: isDocumentEditor,
				isRoleReader: isDocumentReader,
				isAtsSyncable,
				isGenderScoreEnabled,
				isGenderScoreFilled,
				genderScore: genderScore?.globalScore,
				isUMSEnabled,
				isUMSManagerSurveyFilled,
				isUMSEmployeeSurveyFilled,
				plan: planValue,
				isProdEnv: config?.isProdEnv,
				isNotProdVersion: config?.isNotProdVersion,
				env: config?.env,
			};

			if (JSON.stringify(newData) !== identityData) {
				pushIdentify(newData);
				setIdentityData(JSON.stringify(newData));
			}
		}
	}, [authProps, companyProps, companySelected]);

	return (
		<EventPushContext.Provider
			value={{
				userIdData,
			}}
		>
			{children}
		</EventPushContext.Provider>
	);
};

export default EventPushProvider;
