import { innerObjPropExtractor } from '../../property/property-extractor';
import { innerObjPropMapper } from '../../property/property-mapper';

const USER_PROPERTY_CAREER_SEEKING_UPDATED_AT_KEY = 'career-seeking-updated-at';

const USER_PROPERTY_CAREER_SEEKING_UPDATED_AT_FREE = true;

const USER_PROPERTY_CAREER_SEEKING_UPDATED_AT_FIELD = 'careerSeekingUpdatedAt';
const USER_PROPERTY_CAREER_SEEKING_UPDATED_AT_MAPPER = innerObjPropMapper(USER_PROPERTY_CAREER_SEEKING_UPDATED_AT_FIELD);
const USER_PROPERTY_CAREER_SEEKING_UPDATED_AT_EXTRACTOR = innerObjPropExtractor(USER_PROPERTY_CAREER_SEEKING_UPDATED_AT_FIELD);

export default {
	key: USER_PROPERTY_CAREER_SEEKING_UPDATED_AT_KEY,
	USER_PROPERTY_CAREER_SEEKING_UPDATED_AT_KEY,
	free: USER_PROPERTY_CAREER_SEEKING_UPDATED_AT_FREE,
	USER_PROPERTY_CAREER_SEEKING_UPDATED_AT_FREE,
	mapper: USER_PROPERTY_CAREER_SEEKING_UPDATED_AT_MAPPER,
	USER_PROPERTY_CAREER_SEEKING_UPDATED_AT_MAPPER,
	extractor: USER_PROPERTY_CAREER_SEEKING_UPDATED_AT_EXTRACTOR,
	USER_PROPERTY_CAREER_SEEKING_UPDATED_AT_EXTRACTOR,
	field: USER_PROPERTY_CAREER_SEEKING_UPDATED_AT_FIELD,
	USER_PROPERTY_CAREER_SEEKING_UPDATED_AT_FIELD,
};
