import { useTheme } from '@emotion/react';
import {
	Button, Form, message, Typography,
} from 'antd';
import { useState } from 'react';
import { FaBan } from 'react-icons/fa';
import { FcNext } from 'react-icons/fc';
import { useDispatch, useSelector } from 'react-redux';
import { sendInvitedUser } from '../../../actions/acls';
import { getCompanies } from '../../../reducers/app';
import AclService from '../../../services/acl';
import UserService from '../../../services/user';
import Modal from '../../app/modal/Modal';
import InviteUserConfirmForm from './InviteUserConfirmForm';
import InviteUserForm from './InviteUserForm';
import { localDebug } from '../../../utils/LocalDebug';
import { documentId } from '../../../utils/common';
import ModalContainerBase from '../../../contexts/modals/ModalContainerBase';
import FormBoxTitle from '../../app/form/FormBoxTitle';

const InviteUserModal = ({
	open,
	onClose,
	onCloseWithRefresh,
}) => {
	const theme = useTheme();
	const [form] = Form.useForm();

	const [currentStep, setCurrentStep] = useState(1);
	const [initialFormValues, setInitialFormValues] = useState(null);

	const [companyId, setCompanyId] = useState(null);
	const companies = useSelector(getCompanies);

	// dipatch
	const dispatch = useDispatch();

	// company name
	const getCompanyName = (companyId) => {
		return companies.find((company) => documentId(company) === companyId)?.name;
	};

	// close dialog
	const handleClose = () => {
		setCurrentStep(1);
		form.resetFields();
		onClose?.();
	};

	// ----------------------------------------------- //
	// ------------- steps handler ------------------- //
	// ----------------------------------------------- //
	const checkExistMail = async (values) => {
		const { email } = values;
		const { data: { isExist, isInvitePending, user } } = await UserService.getUserByMail(email);

		setInitialFormValues({
			email,
			isExist,
			isInvitePending,
			userId: documentId(user),
			...isExist && { defaultUserOptions: user },
		});
		goToNextStep();
	};

	const handleCloseDialog = () => {
		goToNextStep();
		onClose?.();
		onCloseWithRefresh?.();
	};

	// save invited user
	const saveInvitedUser = async (values) => {
		const { isExist, isInvitePending } = initialFormValues;
		const { roleId, userId } = values;

		// check duplicate role user
		if (isExist) {
			const { data: { isAclExist } } = await AclService.aclExists(roleId, { userId, companyId });
			if (isAclExist) {
				message.error('This access already exists for this user');
				return;
			}
		}
		const companyName = getCompanyName(companyId);
		const userData = {
			...values,
			isExist,
			isInvitePending,
			documentId: companyId,
			information: {
				company: companyName,
			},
		};

		dispatch(sendInvitedUser(userData, handleCloseDialog));
	};

	// ------------------------------------ //
	// ---------- steps ------------------- //
	// ------------------------------------ //
	const steps = [
		{
			key: 1,
			component: InviteUserForm,
			onSubmit: checkExistMail,
			labelButton: {
				mail_true: 'Suivant',
				mail_false: 'Suivant',
			},
		},
		{
			key: 2,
			component: InviteUserConfirmForm,
			onSubmit: saveInvitedUser,
			labelButton: {
				mail_false: 'Envoyer une invitation',
				mail_true: 'Valider',
			},
		},
	];

	const goToNextStep = () => {
		setCurrentStep((prev) => {
			const nextStep = prev + 1 > steps.length ? 1 : prev + 1;
			return nextStep;
		});
		// reset form
		form.resetFields();
	};

	const getStep = (stepId) => {
		return steps.find((step) => step.key === stepId);
	};

	// ------------------------------ //
	// --------- on confirm step ---- //
	// ------------------------------ //
	const handleNextState = () => {
		form
			.validateFields()
			.then((values) => {
				const step = getStep(currentStep);

				if (step?.onSubmit) step.onSubmit(values);
			})
			.catch((info) => {
				localDebug('Validate Failed:', info);
			});
	};

	const renderStep = () => {
		const currentPage = getStep(currentStep);

		if (currentPage) {
			const { component: Component } = currentPage;
			return <Component form={form} initialValues={initialFormValues} onCompanyChange={setCompanyId}/>;
		}
	};

	return (
		<ModalContainerBase
			open={open}
			onCancel={handleClose}
			footer={[
				<Button key="back" onClick={handleClose}>
					<Typography
						style={{
							display: 'flex',
							alignItems: 'center',
							color: theme.color.orange,
							fontWeight: 'bold',
							fontSize: '14px',
						}}
					>
						<FaBan size={16} style={{ marginRight: 5 }}/> Cancel
					</Typography>
				</Button>,
				<Button
					key="link"
					onClick={handleNextState}
				>
					<Typography
						style={{
							display: 'flex',
							alignItems: 'center',
							color: theme.color.darkturquoise,
							fontWeight: 'bold',
							fontSize: '14px',
						}}
					>
						{getStep(currentStep).labelButton[`mail_${initialFormValues?.isExist || false}`]} <FcNext
							size={16} style={{ marginRight: 5 }}/>
					</Typography>

				</Button>,
			]}
		>
			<FormBoxTitle
				title='Invite by mail'
			/>
			{renderStep()}
		</ModalContainerBase>
	);
};

export default InviteUserModal;
