import { useDispatch, useSelector } from 'react-redux';
import { getCompanies, getCompanySelected } from '../../../reducers/app';
import { useAuthContext } from '../../../hooks/useAuthContext';
import FormCompanySelect from '../form/FormCompanySelect';
import { Checkbox, Space, Tooltip } from 'antd';
import JsonPopoverLink from '../debug/JsonPopoverLink';
import { useContext, useEffect, useState } from 'react';
import config from '../../../config/config';
import { LocalDebug } from '../../../utils/LocalDebug';
import { CompanyContext } from '../../../contexts/CompanyProvider';
import useBodyScrollBlocker from '../../../hooks/useBodyScrollBlocker';
import { documentId } from '../../../utils/common';
import {
	CiCircleFilled, PlusCircleFilled, PlusCircleOutlined, ReloadOutlined,
} from '@ant-design/icons';
import Link from '../Link';
import CompanyAddModal from '../../company/addCompany/CompanyAddModal';
import { loadCompanies, loadCompany, saveCompany } from '../../../actions/company';
import { BiCircle } from 'react-icons/bi';

const HeaderCompanySelector = ({
	withStaffView = true,
	...props
}) => {
	const className = 'HeaderCompanySelector';

	const dispatch = useDispatch();

	const companySelected = useSelector(getCompanySelected);
	const companies = useSelector(getCompanies);

	const { isStaff, isStaffView } = useAuthContext();
	const { setCompanySelectedAsQueryParam } = useContext(CompanyContext);
	const [hideChurnedCompanies, setHideChurnedCompanies] = useState(false);
	const [addCompanyModalOpen, setAddCompanyModalOpen] = useState(false);

	const handleAddCompany = async (values) => {
		setAddCompanyModalOpen(false);
		await dispatch(saveCompany(values, true));
		await dispatch(loadCompanies({
			defaultCompanyIdSelected: documentId(companySelected),
			origin: className,
		}));
	};

	const onCompanyChange = (value) => {
		LocalDebug.logInfo({ className, method: 'onCompanyChange' }, { value });
		setCompanySelectedAsQueryParam(value);
	};

	if (withStaffView && !isStaffView) return null;

	if (withStaffView && !isStaff && !companySelected) return null;

	return (
		<>
			<Space
				direction='vertical'
				size={4}
				style={{
					width: '100%',
					...props.style,
				}}
			>
				<Space
					direction='horizontal'
					style={{ width: '100%', justifyContent: 'flex-start', alignItems: 'flex-start' }}
				>
					<div style={{ height: 42 }}>
						<FormCompanySelect
							options={companies?.map((c) => ({ value: documentId(c), label: c.name, item: c }))}
							mode='single'
							className='staff-company-select'
							popupClassName='staff-company-select'
							defaultValue={documentId(companySelected)}
							value={documentId(companySelected)}
							placeholder='Select a company'
							onChange={(value) => onCompanyChange(value)}
							with50inTechSorting={config.isDevEnv ? 1 : -1}
							withPublicationDot={true}
							withJobsPublicationDot={true}
							withAtsDot={true}
							withGenderScoreDot={true}
							withCompanyPlan={true}
							withPublicationOpacity={true}
							hideChurnedCompanies={hideChurnedCompanies}
							withCompanyPlanExtraTags={true}
							withAtsAccess={true}
							styles={{ marginLeft: 0, minWidth: 350 }}
						/>
					</div>
					{documentId(companySelected) && (
						<Space direction='vertical' style={{ paddingTop: 0 }}>
							<JsonPopoverLink
								data={companySelected}
								popover={{ placement: 'right' }}
								style={{ marginLeft: 0 }}
							/>
							<Link onClick={() => {
								dispatch(loadCompany(documentId(companySelected)));
							}}>
								<ReloadOutlined />
							</Link>
						</Space>
					)}
				</Space>
				<Space
					size={0}
					style={{
						width: 350,
						justifyContent: 'space-between',
					}}
				>
					<Checkbox
						onChange={(e) => setHideChurnedCompanies(e?.target?.checked)}
					>
						<div style={{ fontSize: 12 }}>
						Hide churned
						</div>
					</Checkbox>
					{config.isNotProdEnv
						? (
							<Link
								onClick={() => setAddCompanyModalOpen(true)}
								style={{ fontSize: 12 }}
							>
								Add a company <PlusCircleOutlined />
							</Link>
						)
						: <></>
					}
				</Space>
			</Space>

			<CompanyAddModal
				open={addCompanyModalOpen}
				onClose={() => setAddCompanyModalOpen(false)}
				onSave={handleAddCompany}
			/>

		</>
	);
};

export default HeaderCompanySelector;
