import StaffChatbotPage from '../../../containers/staff/tech/StaffChatbotPage';
import { PERMS_GRANT_STAFF } from '../../menu.utils';
import MenuBase from '../../MenuBase';
import { RobotOutlined } from '@ant-design/icons';

export const StaffChatbotIcon = RobotOutlined;

class StaffChatbotMenu extends MenuBase {
	static props = {
		name: 'staff-chatbot',
		path: '/staff/tech/chatbot',
		label: 'Chatbot',
		icon: <StaffChatbotIcon />,
		iconLegacy: '',
		Component: StaffChatbotPage,
		perms: PERMS_GRANT_STAFF,
	};

	constructor() {
		super(StaffChatbotMenu.props);
	}
}

export default StaffChatbotMenu;
