import { useTheme } from '@emotion/react';
import {
	Popover, Space,
} from 'antd';
import { ArrowRightIcon } from '../../../../utils/common';
import {
	GENDERSCORE_PILLAR_VALUES, getPillarColorMain, getPillarLabel,
} from '../../../../constants/genderscore-pillars';
import { getLevelFromScore } from '../../../../constants/genderscore-levels';
import CompanyGenderScoreRingScore from '../CompanyGenderScoreRingScore';
import GenderScoreLayoutBox from '../layout/GenderScoreLayoutBox';
import { writeGenderScoreFilledLog } from '../../../../constants/genderscore-logs';
import { COMPANY_RECRUITER_LOG } from '../../../../constants/company-recruiter-log';
import GenderScoreResultsPillarsLink from './GenderScoreResultsPillarsLink';

const GenderScoreResultsPillarScoresBox = ({
	company,
	forceValue,
}) => {
	const className = 'GenderScoreResultsPillarScoresBox';

	const theme = useTheme();

	const genderScore = forceValue || company?.genderScore;

	return (
		<GenderScoreLayoutBox
			title={<>Your scores by pillar</>}
		>
			<Space
				wrap={false}
				size={8}
				style={{
					width: '100%',
					marginTop: 0,
					marginBottom: 8,
				}}
			>
				{GENDERSCORE_PILLAR_VALUES
					.map((pillar) => (
						<Popover
							key={pillar}
							content={
								<>
									<div>
										You scored <strong>{genderScore?.[pillar]}</strong> for
										the <strong>{getPillarLabel(pillar)}</strong> pillar
									</div>
								</>
							}
							style={{
								maxWidth: 300,
							}}
							onOpenChange={() => {
								writeGenderScoreFilledLog({
									type: {
										element: `${pillar}-score`,
										action: COMPANY_RECRUITER_LOG.HOVER,
									},
									context: 'header-pillar-scores',
								});
							}}
						>
							<div style={{ cursor: 'pointer' }}>
								<CompanyGenderScoreRingScore
									company={company}
									forceValue={forceValue}
									pillar={pillar}
									ringColor={getPillarColorMain(pillar)}
									withPopover={false}
								/>
							</div>
						</Popover>
					))}
			</Space>

			<GenderScoreResultsPillarsLink
				style={{
					display: 'block',
					marginTop: 6,
					marginBottom: -2,
				}}
			>
				<span style={{ fontSize: 12 }}>
					See breakdown details <ArrowRightIcon />
				</span>
			</GenderScoreResultsPillarsLink>

		</GenderScoreLayoutBox>
	);
};

export default GenderScoreResultsPillarScoresBox;
