/* eslint-disable react/jsx-key */
import { useTheme } from '@emotion/react';
import { Space } from 'antd';
import {
	BiBarChart, BiCompass, BiPieChartAlt, BiPulse, BiPyramid, BiRepost, BiSlider, BiStar, BiUserCheck,
} from 'react-icons/bi';
import {
	arrayWrap, numToArray, scrollToTop,
} from '../../../../utils/common';
import CompanyGenderScoreBadge from '../CompanyGenderScoreBadge';
import Box from '../../../app/box/Box';
import CompanyGenderScoreGlobalComparisonLineBox from './boxes/CompanyGenderScoreGlobalCompareLastYearBox';
import CompanyGenderScoreProgressBox from './boxes/CompanyGenderScoreProgressBox';
import CompanyGenderScoreLevelNextBox from './boxes/CompanyGenderScoreLevelNextBox';
import GenderScoreResultsLevelStatsBox from '../results/GenderScoreResultsLevelStatsBox';
import { GENDERSCORE_PILLAR_VALUES } from '../../../../constants/genderscore-pillars';
import CompanyGenderScorePillarPerformanceBox from './boxes/CompanyGenderScorePillarPerformanceBox';
import GenderScoreScalarBlock from './blocks/GenderScoreScalarBlock';
import CompanyGenderScorePerformanceBox from './boxes/CompanyGenderScorePerformanceBox';
import { useAuthContext } from '../../../../hooks/useAuthContext';
import { useContext, useEffect } from 'react';
import { AppDataContext } from '../../../../contexts/AppDataProvider';
import CompanyGenderScoreWomenPctByJobBox from './boxes/CompanyGenderScoreWomenPctByJobBox';
import CompanyGenderScoreChartFromSurveyBox from './boxes/CompanyGenderScoreChartFromSurveyBox';
import GenderScoreDashboardDownloadButton from './download/GenderScoreDashboardDownloadButton';
import CompanyModel from '../../../../models/CompanyModel';
import QUESTIONS from '../../../genderscore/data/questions';
import CompanyGenderScoreRankingFromSurveyBox, { RANKING_GLOBALSCORE, RANKING_HEADCOUNT, RANKING_SECTOR } from './boxes/CompanyGenderScoreRankingFromSurveyBox';
import GenderScoreResultsExpirationBanners from '../results/GenderScoreResultsExpirationBanners';
import { getLevelFromScore } from '../../../../constants/genderscore-levels';
import GenderScoreLevelLabel from '../levels/GenderScoreLevelLabel';
import { useLocation } from 'react-router';

const DEFAULT_GAP = 24;
const DEFAULT_PADDING = 24;
const DashboardLayoutRow = ({
	columns = '1fr',
	gap = DEFAULT_GAP,
	id,
	children,
}) => {
	return (
		<div
			id={id}
			style={{
				display: 'grid',
				gridTemplateColumns: columns,
				gap,
			}}
		>
			{children}
		</div>
	);
};

const DashboardLayoutGridDivider = ({
	direction = 'vertical',
	girth = 40,
	withLine = true,
}) => {
	return (
		<div
			style={{
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
				...(
					direction === 'horizontal'
						? {
							width: '100%',
							height: girth,
						}
						: {
							width: girth,
							height: '100%',
						}
				),
			}}
		>
			<div
				style={{
					...withLine && { background: 'rgba(0,0,0,.1)' },
					...(
						direction === 'horizontal'
							? {
								width: '100%',
								height: 1,
							}
							: {
								width: 1,
								height: '100%',
							}
					),
				}}
			/>
		</div>
	);
};

const DashboardLayoutGrid = ({
	columns = '1fr',
	rows = '1fr',
	gaps = [20, 20],
	children,
	...props
}) => {
	return (
		<>
			<div
				style={{
					display: 'grid',
					gridTemplateColumns: columns,
					columnGap: arrayWrap(arrayWrap(gaps), arrayWrap(gaps)[0])[0],
					gridTemplateRows: rows,
					rowGap: arrayWrap(arrayWrap(gaps), arrayWrap(gaps)[0])[1],
					...props?.style,
				}}
			>
				{children}
			</div>
		</>
	);
};

const DashboardLayoutPanel = ({
	Icon,
	title,
	rightTitle,
	children,
}) => {
	return (
		<Box
			withShadow={true}
			style={{
				padding: DEFAULT_PADDING,
			}}
		>
			<Space
				style={{
					justifyContent: 'space-between',
					alignItems: 'center',
					marginBottom: 20,
					width: '100%',
				}}
			>
				<div
					style={{
						fontSize: 16,
					}}
				>
					{Icon && (
						<><Icon style={{ marginBottom: -2, color: '#999' }} />&nbsp;</>
					)}
					<span style={{ color: '#222' }} >
						{title || 'No title'}
					</span>
				</div>
				<div>
					{rightTitle}
				</div>
			</Space>
			{children}
		</Box>
	);
};

const GenderScoreDashboardWithLayout = ({
	company,
}) => {
	const className = 'GenderScoreDashboardWithLayout';

	const theme = useTheme();
	const location = useLocation();

	const { appSiderWidth } = useContext(AppDataContext);

	const { currentUser } = useAuthContext();

	const companyModel = new CompanyModel(company);

	const screenWidth = Math.max(1180, window.outerWidth - appSiderWidth);

	const c = ({
		colRatios, colGaps, cols = 1, gap = 40,
	}) => {
		return numToArray(cols)
			.map((_, i) => {
				const colRatio = colRatios?.[i] || 1 / cols;
				const colGap = colGaps?.[i] !== undefined ? colGaps?.[i] : gap;
				return `calc((100% - (${cols - 1} * ${colGap}px)) *${colRatio})`;
			})
			.join(` ${gap}px `);
	};

	const w = ({
		baseWidth = screenWidth,
		ratio = 1,
		gaps = 0,
		padding = DEFAULT_PADDING,
		suffix = 0,
	}) => {
		const width = Math.floor(
			((baseWidth || 1180) - (padding * 2) - gaps) * ratio,
		);
		return width + suffix;
	};

	const headcount = companyModel.getGenderScoreCurrentSurvey()?.companyInfo?.headcount;
	const previousHeadcount = companyModel.getGenderScorePreviousSurvey()?.companyInfo?.headcount;

	useEffect(() => {
		scrollToTop();
	}, [location.pathname]);

	return (<>
		<GenderScoreResultsExpirationBanners
			company={company}
		/>
		<div
			style={{
				padding: DEFAULT_PADDING,
				width: screenWidth,
				margin: '0 auto',
			}}
		>
			<div
				style={{
					display: 'flex',
					justifyContent: 'space-between',
					alignItems: 'center',
				}}
			>
				<div
					style={{
						fontSize: 32,
						color: 'black',
						fontWeight: 'bold',
						marginBottom: 30,
					}}
				>
				🌟 Welcome back {currentUser?.firstName}!
					{/* <br/>What do you want to achieve today? */}
				</div>
				<GenderScoreDashboardDownloadButton
					company={company}/>
			</div>

			<div
				id='gs-dashboard'
				style={{
					display: 'grid',
					rowGap: DEFAULT_GAP,
				}}
			>
				<DashboardLayoutRow
					id='gs-dashboard-row-0'
					columns={[
						w({
							ratio: 2 / 5,
							gaps: 1 * DEFAULT_GAP,
							padding: DEFAULT_PADDING,
							suffix: 'px',
						}),
						w({
							ratio: 3 / 5,
							gaps: 1 * DEFAULT_GAP,
							padding: DEFAULT_PADDING,
							suffix: 'px',
						}),
					].join(' ')}
					gap={DEFAULT_GAP}
				>
					<DashboardLayoutPanel
						Icon={BiBarChart}
						title={<>Your GenderScore</>}
					>
						<DashboardLayoutGrid
							columns={c({ cols: 2 })}
							gaps={0}
						>
							<CompanyGenderScoreBadge
								company={company}
								version={'white'}
								withDownloader={true}
								size={
									w({
										baseWidth: w({
											ratio: 2 / 5,
											gaps: 1 * DEFAULT_GAP,
											padding: DEFAULT_PADDING,
										}),
										ratio: 1 / 2,
										gaps: 40,
										padding: DEFAULT_PADDING,
									})
								}
							/>
							<DashboardLayoutGridDivider
								withLine={false}
							/>
							<Space direction='vertical'>
								<Space style={{ marginTop: '10px' }}>
									<CompanyGenderScoreGlobalComparisonLineBox
										company={company}
										width={80}
										isRanking={false}
									/>
									<CompanyGenderScoreGlobalComparisonLineBox
										company={company}
										width={80}
										isRanking={true}
									/>
								</Space>
								<DashboardLayoutGridDivider
									withLine={false}
									direction='horizontal'
								/>
								<CompanyGenderScoreProgressBox
									company={company}
									height={100}
								/>
							</Space>
						</DashboardLayoutGrid>

					</DashboardLayoutPanel>

					<DashboardLayoutPanel
						Icon={BiSlider}
						title={<>
							Your Level&nbsp;
							<GenderScoreLevelLabel
								level={getLevelFromScore(company?.genderScore?.globalScore)}
								withPill={true}
								withPopover={true}
								withUnderline={false}
							/>
						</>}
					>

						<DashboardLayoutGrid
							columns={c({ cols: 2, colGaps: [80, 0] })}
							gaps={[0, 60]}
						>
							<CompanyGenderScoreLevelNextBox
								company={company}
							/>

							<DashboardLayoutGridDivider />

							<GenderScoreResultsLevelStatsBox
								company={company}
								fontSize={40}
								withRanking={true}
								style={{
									box: {
										border: 'none',
										background: 'none',
										padding: 0,
									},
									pct: {
										fontSize: 40,
										fontWeight: 700,
										margin: 0,
									},
									subtitle: {
										marginTop: 0,
									},
									company: {
										border: `1px solid ${theme.dashboardColors.gray}`,
										padding: 5,
										paddingRight: 25,
									},
								}}
							/>
						</DashboardLayoutGrid>

					</DashboardLayoutPanel>

				</DashboardLayoutRow>

				<DashboardLayoutRow
					id='gs-dashboard-row-1'
					columns={w({
						ratio: 1,
						padding: DEFAULT_PADDING,
						suffix: 'px',
					})}
				>

					<DashboardLayoutPanel
						Icon={BiPieChartAlt}
						title={<>Your Pillars</>}
					>
						<DashboardLayoutGrid
							columns={c({ cols: GENDERSCORE_PILLAR_VALUES.length })}
							gaps={0}
						>
							{GENDERSCORE_PILLAR_VALUES
								.map((pillar, index) => (
									<>
										{index !== 0 ? <DashboardLayoutGridDivider key={`divider${index}`} /> : null}
										<CompanyGenderScorePillarPerformanceBox
											key={`pillar${index}`}
											company={company}
											pillar={pillar}
											index={index}
											withLink={true}
										/>
									</>
								))}

						</DashboardLayoutGrid>
					</DashboardLayoutPanel>

				</DashboardLayoutRow>

				<DashboardLayoutRow
					id='gs-dashboard-row-2'
					columns={[
						w({
							ratio: 3 / 5,
							gaps: 1 * DEFAULT_GAP,
							padding: DEFAULT_PADDING,
							suffix: 'px',
						}),
						w({
							ratio: 2 / 5,
							gaps: 1 * DEFAULT_GAP,
							padding: DEFAULT_PADDING,
							suffix: 'px',
						}),
					].join(' ')}
					gap={DEFAULT_GAP}
				>

					<DashboardLayoutPanel
						Icon={BiStar}
						title={<>Your Ranking</>}
						rightTitle={(
							<div style={{
								background: theme.color.lightgrey,
								color: 'white',
								padding: '2px 6px',
								borderRadius: 5,
								fontWeight: 'bold',
								fontSize: 11,
							}}>
								LIVE DATA
							</div>
						)}
					>
						<DashboardLayoutGrid
							columns={c({ cols: 3 })}
							gaps={0}
						>

							<CompanyGenderScoreRankingFromSurveyBox
								company={company}
								version={RANKING_GLOBALSCORE}
								width={180}
							/>

							<DashboardLayoutGridDivider />

							<CompanyGenderScoreRankingFromSurveyBox
								company={company}
								version={RANKING_SECTOR}
								width={180}
							/>

							<DashboardLayoutGridDivider />

							<CompanyGenderScoreRankingFromSurveyBox
								company={company}
								version={RANKING_HEADCOUNT}
								width={180}
							/>

						</DashboardLayoutGrid>

					</DashboardLayoutPanel>

					<DashboardLayoutPanel
						Icon={BiPulse}
						title={<>Your Performance</>}
					>
						<DashboardLayoutGrid
							columns={c({ cols: 2 })}
							gaps={0}
						>
							<CompanyGenderScorePerformanceBox
								company={company}
								isGlobal={true}
							/>
							<DashboardLayoutGridDivider girth={50}/>

							<CompanyGenderScorePerformanceBox
								company={company}
								isGlobal={false}
							/>

						</DashboardLayoutGrid>

					</DashboardLayoutPanel>

				</DashboardLayoutRow>

				<DashboardLayoutRow
					id='gs-dashboard-row-3'
					columns={[
						w({
							ratio: 3 / 5,
							gaps: 1 * DEFAULT_GAP,
							padding: DEFAULT_PADDING,
							suffix: 'px',
						}),
						w({
							ratio: 2 / 5,
							gaps: 1 * DEFAULT_GAP,
							padding: DEFAULT_PADDING,
							suffix: 'px',
						}),
					].join(' ')}
					gap={DEFAULT_GAP}
				>

					<DashboardLayoutPanel
						Icon={BiUserCheck}
						title={<>Your Workforce</>}
					>
						<DashboardLayoutGrid
							columns={'calc(55% - 40px) 40px calc(45% - 40px)'}
							gaps={0}
						>
							<DashboardLayoutGrid
								rows={'calc(45% - 40px) 40px calc(55%)'}
								columns={c({ cols: 1 })}
								gaps={0}
							>
								<GenderScoreScalarBlock
									current={headcount}
									previous={previousHeadcount}
									title="Number of employees"
									isPct={false}
									suffix=''
								/>

								<DashboardLayoutGridDivider
									direction='horizontal'
								/>

								<DashboardLayoutGrid
									columns={c({ cols: 2 })}
									gaps={0}
								>
									<CompanyGenderScoreChartFromSurveyBox
										company={company}
										question={QUESTIONS.GENDERDATA_OVERALLPCT.questionId}
										width={135}
									/>
									<DashboardLayoutGridDivider />

									<CompanyGenderScoreChartFromSurveyBox
										company={company}
										question={QUESTIONS.GENDERDATA_TECHPCT.questionId}
										width={135}
									/>

								</DashboardLayoutGrid>
							</DashboardLayoutGrid>

							<DashboardLayoutGridDivider />

							<CompanyGenderScoreWomenPctByJobBox
								company={company}
							/>

						</DashboardLayoutGrid>

					</DashboardLayoutPanel>

					<DashboardLayoutPanel
						Icon={BiPyramid}
						title={<>Your Management</>}
					>

						<DashboardLayoutGrid
							columns={c({ cols: 2 })}
							gaps={0}
						>
							<CompanyGenderScoreChartFromSurveyBox
								company={company}
								question={QUESTIONS.GENDERDATA_MGTPCT.questionId}
								width={135}
							/>

							<DashboardLayoutGridDivider />

							<CompanyGenderScoreChartFromSurveyBox
								company={company}
								question={QUESTIONS.GENDERDATA_BOARDPCT.questionId}
								width={135}
							/>
						</DashboardLayoutGrid>

						<DashboardLayoutGridDivider direction='horizontal' />

						<CompanyGenderScoreChartFromSurveyBox
							company={company}
							question={QUESTIONS.GENDERDATA_CLEVELPCT.questionId}
							width={135}
						/>
					</DashboardLayoutPanel>
				</DashboardLayoutRow>

				<DashboardLayoutRow
					id='gs-dashboard-row-4'
					columns={[
						w({
							ratio: 3 / 5,
							gaps: 1 * DEFAULT_GAP,
							padding: DEFAULT_PADDING,
							suffix: 'px',
						}),
						w({
							ratio: 2 / 5,
							gaps: 1 * DEFAULT_GAP,
							padding: DEFAULT_PADDING,
							suffix: 'px',
						}),
					].join(' ')}
					gap={DEFAULT_GAP}
				>

					<DashboardLayoutPanel
						Icon={BiCompass}
						title={<>Last Year Career</>}
					>
						<DashboardLayoutGrid
							columns={c({
								cols: 3,
								colRatios: [1 / 4, 3 / 8, 3 / 8],
								colGaps: [0, 60, 60],
							})}
							gaps={0}
						>
							<CompanyGenderScoreChartFromSurveyBox
								company={company}
								question={QUESTIONS.GENDERDATA_TENUREOPTIONS.questionId}
							/>
							<DashboardLayoutGridDivider />

							<CompanyGenderScoreChartFromSurveyBox
								company={company}
								question={QUESTIONS.GENDERDATA_PROMOTEDPCT.questionId}

							/>

							<DashboardLayoutGridDivider />

							<CompanyGenderScoreChartFromSurveyBox
								company={company}
								question={QUESTIONS.EQUALPAY_SALARYINCREASEPCT.questionId}
							/>

						</DashboardLayoutGrid>

					</DashboardLayoutPanel>

					<DashboardLayoutPanel
						Icon={BiRepost}
						title={<>Your Turnover</>}
					>
						<DashboardLayoutGrid
							columns={c({ cols: 1 })}
							gaps={0}
						>
							<CompanyGenderScoreChartFromSurveyBox
								company={company}
								question={QUESTIONS.GENDERDATA_TURNOVERPCT.questionId}
							/>
						</DashboardLayoutGrid>

					</DashboardLayoutPanel>
				</DashboardLayoutRow>
			</div>
		</div>

	</>
	);
};

export default GenderScoreDashboardWithLayout;
