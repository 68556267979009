import { useTheme } from '@emotion/react';
import { Table, Tooltip } from 'antd';
import { NOT_APPLICABLE } from '../../../../genderscore/data/dashboard';

/**
 *
 * @param {*} items - Legend items. Format: [{value, label, color, isPct}]
 * @param {number} [width] - Optional. Width of the legend. Default: 130
 * @param {number} fontSize - Optional. Main font size of the legend.
 * Each text element can have a different size, proportional to this value
 * @returns
 */
const GenderScoreLegendBlock = ({
	items,
	width = 130,
	fontSize = 16,
	...props
}) => {
	const className = 'GenderScoreLegendBlock';

	const theme = useTheme();
	if (!items?.length) {
		return null;
	}

	return <Table
		id="genderscore-legend"
		style={{ width, ...props.style }}
		showHeader={false}
		dataSource={items?.map?.((item, index) => ({ ...item, key: index })) || []}
		bordered={false}
		pagination={false}
		rowHoverBg={'#FFFFFF'}
		columns={[{
			key: 'value',
			dataIndex: 'value',
			align: 'right',
			render: (v, r) => {
				const content = <><span style={{
					fontSize,
					fontWeight: 700,
					color: theme.color.fitBlack,
					opacity: 0.88,
				}}>
					{v}
				</span>
				{r.isPct && v !== NOT_APPLICABLE && <span style={{
					fontSize: Math.floor(fontSize * 0.7),
					fontWeight: 700,
					color: theme.color.fitBlack,
					opacity: 0.88,
				}}>%</span>}
				</>;
				if (v === NOT_APPLICABLE) {
					return <Tooltip title="This question was not answered on the GenderScore survey">
						{content}
					</Tooltip>;
				}
				return content;
			},
		},
		{
			dataIndex: 'color',
			key: 'color',
			render: (v, r) => <span style={{
				height: 12,
				width: 12,
				border: `2px solid ${v}`,
				borderRadius: '50%',
				display: 'inline-block',
				marginLeft: 8,
			}} />,
		},
		{
			dataIndex: 'label',
			key: 'label',
			render: (v, r) => <span style={{
				fontSize: Math.floor(fontSize * 0.95),
				fontWeight: 400,
				color: theme.color.fitBlack,
				opacity: 0.88,
				marginLeft: 5,
			}}>
				{v}
			</span>,
		},
		]}
	/>;
};
export default GenderScoreLegendBlock;
