import chalk from 'chalk';
import { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Space } from 'antd';
import * as companyActions from '../../actions/company';
import * as adminActions from '../../actions/admin';
import {
	getAtsArchiveReasons,
	getAtsPipelines,
	getCompanySelected,
} from '../../reducers/app';
import { addSourcing, removeSourcing } from '../../actions/sourcings';
import { SocketContext } from '../../contexts/SocketProvider';
import {
	addMultipleQueryParams,
	documentId,
	getQueryParams, writeLog,
} from '../../utils/common';
import { LocalDebug } from '../../utils/LocalDebug';
import {
	SOURCING_DOCUMENT_REF,
	CANDIDATES_DETAILS,
} from '../../constants/constant';
import SourcingDrawer from '../../components/sourcings/SourcingDrawer';
import PageLayout from '../../components/app/layout/PageLayout';
import { useAuthContext } from '../../hooks/useAuthContext';
import { CandidateContext } from '../../contexts/CandidateProvider';
import CandidateTable from '../../components/sourcings/CandidateTable';
import CandidateStageSelector from '../../components/sourcings/CandidateStageSelector';
import AddButton from '../../components/app/button/AddButton';
import { FaSync } from 'react-icons/fa';
import { shouldRefreshData } from '../../actions/app';
import CandidateFilterSelector from '../../components/sourcings/CandidateFilterSelector';
import CandidatePipelineSelector from '../../components/sourcings/CandidatePipelineSelector';
import ContactedCandidateTable from '../../components/sourcings/ContactedCandidateTable';
import SourcingService from '../../services/sourcing';
import GenderhireCandidatesAtsMenu from '../../menu/genderhire/GenderhireCandidatesAtsMenu';

const queryParams = getQueryParams();

const CandidatesPage = (
	{
		baseOptionsFilterTable = {
			notCandidatePipeline: ['shortlist'],
		},
		allowedPipelines,
		allowedQueryParams = [
			'candidatePipeline', 'candidateStage',
			'isArchived', 'showArchived', 'atsArchiveReason',
			'source', 'atsStage',
			'sortField', 'sortOrder',
		],
		withPipelineSelector = true,
		withStageSelector = true,
		withArchivedSelector = true,
		subtitle = CANDIDATES_DETAILS,
		withATSSyncButton = true,
		mode = 'candidates',
	},
) => {
	const className = 'Candidates';

	const dispatch = useDispatch();

	const companySelected = useSelector(getCompanySelected);
	const atsArchiveReasons = useSelector(getAtsArchiveReasons);
	const defaultPipelines = useSelector(getAtsPipelines).filter((p) => p.value !== 'shortlist');
	// const defaultPipelines = useSelector(getAtsCandidateWithoutApplicationPipelines);

	// LocalDebug.logInfo({ className, method: 'init' }, { allowedPipelines });
	const atsPipelines = allowedPipelines || defaultPipelines;
	// LocalDebug.logInfo({ className, method: 'init' }, { allowedPipelines });

	const { isAdmin, isStaffView, isEditor } = useAuthContext();
	const {
		candidatePipeline, candidatePipelines,
		candidateStages, isArchived, showArchived, archiveReasons, sources, atsStages,
		sortField, sortOrder, setCandidateCounts,
		shouldRefresh, applyQueryParams, contactedSelected,
	} = useContext(CandidateContext);
	const { createAction } = useContext(SocketContext);

	const [optionsFilterTable, setOptionsFilterTable] = useState({
		...queryParams || {},
		...baseOptionsFilterTable,
		companyId: documentId(companySelected),
	});
	const [sourcings, setSourcings] = useState([]);
	const [isRefreshDataLayout, setIsRefreshDataLayout] = useState(0);

	const [open, setOpen] = useState(false);
	const [initSourcingValuesForm, setInitSourcingValuesForm] = useState({});

	const handleSubmit = async (values, title = 'Create a sourcing') => {
		writeLog('handleSubmit sourcing', { data: values });

		const valuesToDb = { ...values };
		const result = await addSourcing(valuesToDb);
		setOpen(false);
		setIsRefreshDataLayout((prev) => prev + 1);
		// create action
		if (result) {
			const payload = {
				documentRef: SOURCING_DOCUMENT_REF,
				documentId: documentId(result),
				companyId: result.companyId,
				action: {
					title,
					...(values?.history && { history: values.history }),
				},
			};
			createAction(payload);
		}
	};

	const handleDeleteSourcing = (sourcing) => {
		dispatch(removeSourcing(sourcing));
		setIsRefreshDataLayout((prev) => prev + 1);
	};

	const handleSyncAllSourcings = async () => {
		LocalDebug.logInfo({ className, method: 'handleSyncAllSourcings' }, { isAdmin, companySelected });
		if (documentId(companySelected)) {
			await dispatch(companyActions.syncCompanyAtsSourcings(documentId(companySelected)));
		} else if (isAdmin) {
			await dispatch(adminActions.syncAllSourcings());
		}
		await dispatch(shouldRefreshData());
	};

	const loadCandidatePipelines = async (params) => {
		const result = await SourcingService.findAll({
			...params,
			limit: '10',
			skip: '0',
		});

		setCandidateCounts({
			...result?.data?.groupFields,
		});
	};

	useEffect(() => {
		const effects = 'shouldRefresh, companySelected';
		LocalDebug.logUseEffect({ className, effects }, {
			queryParams,
			atsPipelines,
			candidatePipeline,
			candidatePipelines,
			candidateStages,
			isArchived,
			showArchived,
			archiveReasons,
			sources,
			atsStages,
			sortField,
			sortOrder,
		});
		if (applyQueryParams(queryParams, atsPipelines, allowedQueryParams)) return;
		const {
			candidatePipeline: bcp, candidateStage: bcs, isArchived: bia, showArchived: bsa, atsArchiveReason: baar,
			source: bs, atsStage: bas, sortField: bsf, sortOrder: bso, ...resetBaseOptionsFilterTable
		} = baseOptionsFilterTable;
		LocalDebug.logUseEffect({ className, effects }, { optionsFilterTable });
		const candidateStagesFilters = {};
		candidateStages.forEach((s) => {
			Object.entries(s.filters || {}).forEach(([key, value]) => {
				candidateStagesFilters[key] = [...new Set([...candidateStagesFilters[key] || [], value])];
			});
		});
		const archiveReasonFilters = {};
		archiveReasons.forEach((o) => {
			Object.entries(o.filters || {}).forEach(([key, value]) => {
				archiveReasonFilters[key] = [...new Set([...archiveReasonFilters[key] || [], value])];
			});
		});
		const pipelineFilters = mode === 'candidates' && candidatePipelines?.length ? { candidatePipeline: candidatePipelines } : {};
		const sourceFilters = sources?.length ? { source: sources } : {};
		const atsStageFilters = atsStages?.length ? { atsStage: atsStages } : {};
		const filters = {
			...resetBaseOptionsFilterTable,
			...pipelineFilters,
			...sourceFilters,
			...atsStageFilters,
			...sortField ? { sortField } : {},
			...sortOrder ? { sortOrder } : {},
			...[true, 'true'].includes(showArchived) ? { showArchived } : {},
			...isArchived
				? {
					isArchived: true,
				}
				: {
					...candidatePipeline?.filters || {},
					...candidateStagesFilters,
				},
			...mode === 'shortlists' ? { candidatePipeline: ['shortlist'] } : {},
		};
		const newQueryParams = {};
		allowedQueryParams.forEach((key) => {
			newQueryParams[key] = filters[key];
		});

		addMultipleQueryParams(newQueryParams, true);
		LocalDebug.logUseEffect({ className, effects, styles: [chalk.red, chalk.bold] }, {
			candidatePipeline,
			candidatePipelines,
			candidateStages,
			isArchived,
			archiveReasons,
			candidateStagesFilters,
			filters,
			newQueryParams,
		});
		const newOptionsFilter = {
			isArchived: false,
			...filters,
			companyId: documentId(companySelected),
		};
		setOptionsFilterTable((prev) => (newOptionsFilter));
		if (mode === 'candidates') {
			loadCandidatePipelines(newOptionsFilter);
		}
	}, [
		shouldRefresh,
		companySelected,
	]);

	return (
		<PageLayout
			MenuClass={GenderhireCandidatesAtsMenu}
			subtitle={subtitle}
			rightActions={(
				withATSSyncButton && (isAdmin || companySelected) && (
					<AddButton
						icon={<FaSync style={{ marginBottom: -2 }}/>}
						style={{ marginTop: 1 }}
						onClick={handleSyncAllSourcings}
						title="Sync with ATS"
					/>
				)
			)}
		>
			{isAdmin && isStaffView && false
				? <div style={{ overflow: 'scroll' }}>
					<div style={{
						display: 'inline-block', width: 260, verticalAlign: 'top', marginRight: 10,
					}}>
						<CandidateFilterSelector style={{ marginBottom: 10 }}/>
					</div>
					<div style={{ display: 'inline-block', width: 'calc(100% - 270px)', verticalAlign: 'top' }}>
						<Space direction='vertical' style={{ width: '100%', verticalAlign: 'top' }}>
							<CandidateTable
								dataSource={sourcings}
								filterOptions={optionsFilterTable}
								isEditor={isEditor}
								onEditSourcing={handleSubmit}
								isRefresh={isRefreshDataLayout}
								onDeleteSourcing={handleDeleteSourcing}
							/>
						</Space>
					</div>
				</div>
				: <>
					{mode === 'candidates'
						? <CandidatePipelineSelector
							atsPipelines={atsPipelines}
							withArchivedSelector={withArchivedSelector}
							withContactedSelector={true}
							atsArchiveReasons={atsArchiveReasons}
							style={{ marginBottom: 10 }}
						/>
						: <CandidateStageSelector
							atsPipelines={atsPipelines}
							withArchivedSelector={withArchivedSelector}
							withPipelineSelector={withPipelineSelector}
							withStageSelector={withStageSelector}
							atsArchiveReasons={atsArchiveReasons}
							style={{ marginBottom: 10 }}
						/>
					}
					{contactedSelected
						? <ContactedCandidateTable
							filterOptions={{
								...optionsFilterTable,
								notCandidatePipeline: [],
							}}
							isRefresh={isRefreshDataLayout}
						/>
						: <CandidateTable
							dataSource={sourcings}
							filterOptions={optionsFilterTable}
							isEditor={isEditor}
							onEditSourcing={handleSubmit}
							isRefresh={isRefreshDataLayout}
							onDeleteSourcing={handleDeleteSourcing}
						/>
					}
				</>
			}

			<SourcingDrawer
				open={open}
				initialValues={initSourcingValuesForm}
				onClose={() => setOpen(false)}
				onSubmit={handleSubmit}
				onReset={() => setOpen(false)}
			/>
		</PageLayout>
	);
};

export default CandidatesPage;
