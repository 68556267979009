import { useEffect, useState } from 'react';
import { useTheme } from '@emotion/react';
import { Col, Row, Space } from 'antd';
import { ENGLISH_LANG } from '../../../../constants/constant';
import CompanyFieldEditor from '../shared/CompanyFieldEditor';
import CompanyGenderScoreForm from './CompanyGenderScoreForm';
import CompanyGenderScoreDetailsPanel from '../../genderscore/CompanyGenderScoreDetailsPanel';
import CompanyGenderScoreLogo from '../../genderscore/CompanyGenderScoreLogo';
import CompanyGenderScoreBadge from '../../genderscore/CompanyGenderScoreBadge';
import CompanyGenderScoreBadgeInline from '../../genderscore/CompanyGenderScoreBadgeInline';
import dayjs from 'dayjs';

const CompanyGenderScoreEditor = (
	{
		company,
		lang = ENGLISH_LANG,
		setEditorOpen,
	},
) => {
	const className = 'CompanyGenderScoreEditor';
	const theme = useTheme();

	const field = 'genderScore';

	const extractData = () => {
		if (company) {
			const {
				globalScore,
				genderData,
				fairCareerPath,
				equalPay,
				worklifeBalance,
				deiActions,
				createdAt,
			} = company?.[field] || {};
			const data = {
				globalScore,
				genderData,
				fairCareerPath,
				equalPay,
				worklifeBalance,
				deiActions,
				createdAt: createdAt && dayjs(createdAt),
			};
			return data;
		}
	};

	const [editedValue, setEditedValue] = useState(extractData());
	const [initialValues, setInitialValues] = useState(extractData());

	useEffect(() => {
		if (company) {
			setEditedValue(extractData());
			setInitialValues(extractData());
		}
	}, [company]);

	const onValuesChange = async (values) => {
		setEditedValue((p) => ({ ...p || {}, ...values }));
	};

	if (!company) return null;

	return (
		<CompanyFieldEditor
			company={company}
			lang={lang}
			title={<CompanyGenderScoreLogo direction='horizontal' size={200} />}
			field={'genderScore'}
			editedValue={editedValue}
			isTranslatable={false}
			setEditorOpen={setEditorOpen}
		>
			<Row span={24} gutter={[40, 40]}>
				<Col span={8}>
					<CompanyGenderScoreForm
						initialValues={{ ...initialValues }}
						onValuesChange={onValuesChange}
					/>
				</Col>
				<Col
					span={16}
				>
					<Space
						size={30}
						style={{
							padding: 20,
							background: theme.color.bgGrey,
							border: `3px dashed ${theme.color.fitBgGreyDark}`,
							borderRadius: 12,
						}}
						wrap={true}
					>
						<CompanyGenderScoreDetailsPanel
							company={{ ...company, [field]: editedValue }}
							style={{
								padding: 20,
								marginTop: 10,
								boxShadow: '0 2px 20px -2px rgba(0,0,0,.2)',
							}}
						/>
						<CompanyGenderScoreBadge
							version={'blue'}
							company={{ ...company, [field]: editedValue }}
							size={200}
							withPopover={false}
						/>
						<CompanyGenderScoreBadge
							version={'white'}
							company={{ ...company, [field]: editedValue }}
							size={200}
							withPopover={false}
						/>
						<Space>
							<CompanyGenderScoreBadgeInline
								version={'blue'}
								company={{ ...company, [field]: editedValue }}
								size={100}
								withPopover={false}
							/>
							<CompanyGenderScoreBadgeInline
								version={'white'}
								company={{ ...company, [field]: editedValue }}
								size={100}
								withPopover={false}
							/>
						</Space>
					</Space>
				</Col>
			</Row>
		</CompanyFieldEditor>
	);
};

export default CompanyGenderScoreEditor;
