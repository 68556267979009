import { SafetyCertificateOutlined } from '@ant-design/icons';
import { PERMS_GRANT_STAFF } from '../../menu.utils';
import StaffGenderScoreBadgeMakerPage from '../../../containers/staff/product/StaffGenderScoreBadgeMakerPage';
import MenuBase from '../../MenuBase';

class StaffGSBadgeMakerMenu extends MenuBase {
	name = 'staff-genderscore-badge-maker';

	path = '/staff/genderscore/badge-maker';

	label = 'Badge Maker';

	icon = <SafetyCertificateOutlined />;

	Component = StaffGenderScoreBadgeMakerPage;

	perms = PERMS_GRANT_STAFF;
}

export default StaffGSBadgeMakerMenu;
