import { onEnterBuilder } from '../../../actions/app';
import ImpactKPIsPage from '../../../containers/staff/data/ImpactKPIsPage';
import { PERMS_GRANT_STAFF } from '../../menu.utils';

const menu = {
	name: 'staff-data-impact-kpis',
	path: '/staff/data/impact-kpis',
	label: 'Impact KPIs',
	icon: <span>💯</span>,
	component: ImpactKPIsPage,
	onEnter: onEnterBuilder({
		origin: ImpactKPIsPage.name,
		shouldGoToPreviousHistory: true,
	}),
	perms: PERMS_GRANT_STAFF,
};

export default menu;
