import { useTheme } from '@emotion/react';
import { Form, message } from 'antd';
import { useState, useEffect, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useAuthContext } from '../../../hooks/useAuthContext';
import { getCompanies, getCompanySelected } from '../../../reducers/app';
import UserService from '../../../services/user';
import UMSInviteEmailForm from './UMSInviteEmailForm';
import UMSInviteConfirmForm from './UMSInviteConfirmForm';
import AclService from '../../../services/acl';
import { LocalDebug, localDebug } from '../../../utils/LocalDebug';
import { documentId } from '../../../utils/common';
import { UMSInviteContext } from '../UMSInviteProvider';
import UMSActions from '../../../actions/ums';

const UMSInviteForm = (
	{
		initialValues = {},
		autoValidateEmail = false,
		onCompanyChange,
		onCloseWithRefresh,
	},
) => {
	const className = 'UMSInviteForm';

	const theme = useTheme();

	const dispatch = useDispatch();

	const companySelected = useSelector(getCompanySelected);
	const companies = useSelector(getCompanies);

	const { isStaff, isStaffView, companyIdsUser } = useAuthContext();
	const {
		inviteFormStep,
		setInviteFormStep,
		setCheckEmailLoading,
		refreshLayout,
		shouldNotifyInvitee,
	} = useContext(UMSInviteContext);

	const [form] = Form.useForm();
	// local state
	const [formCompanyId, setFormCompanyId] = useState(null);

	const [initialFormValues, setInitialFormValues] = useState({});

	const checkExistMail = async (values) => {
		setCheckEmailLoading(true);
		const { email } = values;
		const {
			data: {
				isExist, isInvitePending, user, userRoles,
			},
		} = await UserService.getUserByMail(email);

		LocalDebug.logInfo(
			{ className, method: 'checkExistMail' },
			{
				values, isExist, isInvitePending, user, userRoles,
			},
		);

		setInitialFormValues({
			email,
			isExist,
			isInvitePending,
			userId: documentId(user),
			documentId: documentId(companySelected),
			user,
			userRoles,
			...isExist && { defaultUserOptions: user },
		});
		setCheckEmailLoading(false);
		setInviteFormStep(1);
	};

	useEffect(() => {
		if (autoValidateEmail) {
			checkExistMail(initialValues);
		}
	}, [autoValidateEmail]);

	// company name
	const getCompanyName = (id) => {
		return companies.find((company) => documentId(company) === id)?.name;
	};

	const handleSubmit = async (values) => {
		const { roleId, userId } = values;

		const companyId = formCompanyId || documentId(companySelected);
		LocalDebug.logInfo({ className, method: 'handleSubmit' }, { companyId, values });

		// check duplicate role user
		if (initialFormValues.isExist) {
			const { data: { isAclExist } } = await AclService.aclExists(
				roleId,
				{ userId, companyId },
			);
			if (isAclExist) {
				message.error('This user already has this role');
				return;
			}
		}
		const companyName = getCompanyName(companyId);
		const data = {
			userId: initialFormValues.userId,
			...values,
			isExist: initialFormValues.isExist,
			isInvitePending: initialFormValues.isInvitePending,
			documentId: companyId,
			...!initialFormValues.isExist
				? { 'information.company': companyName }
				: {},
		};

		dispatch(
			UMSActions.inviteRole({
				data,
				callback: async () => {
					message.success(
						shouldNotifyInvitee
							? 'User successfully invited and notified'
							: 'User successfully granted access, without notification',
					);
					refreshLayout();
					onCloseWithRefresh?.();
				},
			}),
		);
	};

	useEffect(() => {
		if (isStaffView) {
			// setIsShowCompanySelect(true);
			return;
		}
		// hide select company for company recruiter
		if (companyIdsUser.length === 1) {
			onCompanyChange(companyIdsUser[0]);
			// setIsShowCompanySelect(false);
		}
	}, [companyIdsUser, isStaff, isStaffView, onCompanyChange]);

	return (<>
		{inviteFormStep === 0
			&& <>
				<UMSInviteEmailForm
					form={form}
					initialValues={{ ...initialValues }}
					onSubmit={checkExistMail}
				/>
			</>
		}
		{inviteFormStep === 1
			&& <>
				<UMSInviteConfirmForm
					form={form}
					onCompanyChange={setFormCompanyId}
					initialValues={{ ...initialFormValues }}
					onSubmit={handleSubmit}
				/>
			</>
		}
	</>
	);
};

export default UMSInviteForm;
