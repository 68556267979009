import { useState } from 'react';
import { GENDERSCORE_LABEL } from '../../../../constants/constant';
import FormItem from '../../form/FormItem';
import CompanyPlanGenderScoreBadgeIsPublishedSwitch from './CompanyPlanGenderScoreBadgeIsPublishedSwitch';

export const companyPlanGenderScoreBadgeIsPublishedPath = ['companyPlan', 'genderScore', 'isStaffPublished'];

const CompanyPlanGenderScoreBadgeIsPublishedFormItem = ({
	initialValue,
	noResults,
	disabled = false,
}) => {
	const className = 'CompanyPlanGenderScoreBadgeIsPublishedFormItem';

	const [genderScoreBadgeIsPublished, setGenderScoreBadgeIsPublished] = useState(initialValue);
	const onGenderScoreBadgeIsPublishedChange = (badgeIsPublishedNewValue) => {
		setGenderScoreBadgeIsPublished(badgeIsPublishedNewValue);
	};
	let extra;
	if (noResults) {
		extra = genderScoreBadgeIsPublished
			? <span>This company's {GENDERSCORE_LABEL} Badge <b>will be visible to the Talents</b> once the Survey is submitted</span>
			: <span>This company's {GENDERSCORE_LABEL} Badge <b>will be hidden from the Talents</b> when the Survey is submitted</span>;
	} else {
		extra = genderScoreBadgeIsPublished
			? <span>This company's {GENDERSCORE_LABEL} Badge <b>is visible to the Talents</b></span>
			: <span>This company's {GENDERSCORE_LABEL} Badge <b>is hidden from the Talents</b> </span>;
	}
	return (
		<FormItem
			label={
				<span
					style={{
						...disabled ? { color: '#ccc' } : {},
					}}
				>
					{GENDERSCORE_LABEL} Badge published
				</span>
			}
			name={companyPlanGenderScoreBadgeIsPublishedPath}
			extra={extra}
			valuePropName='checked'
		>
			<CompanyPlanGenderScoreBadgeIsPublishedSwitch
				disabled={disabled}
				onChange={onGenderScoreBadgeIsPublishedChange}/>
		</FormItem>
	);
};

export default CompanyPlanGenderScoreBadgeIsPublishedFormItem;
