import { useDispatch } from 'react-redux';
import { useTheme } from '@emotion/react';
import {
	Divider, Form, message, Space,
} from 'antd';
import { useReducer } from 'react';
import { useAuthContext } from '../../hooks/useAuthContext';
import { LocalDebug } from '../../utils/LocalDebug';
import { refreshCurrentUser } from '../../actions/app';
import FormItem from '../company/form/FormItem';
import FormBoxTitle from '../app/form/FormBoxTitle';
import FormSubmitButton from '../app/form/FormSubmitButton';
import CompanyService from '../../services/company';
import InlineNavLink from '../app/button/InlineNavLink';
import { JOB_OFFERS_ICON, JOB_OFFERS_MENU } from '../../constants/constant';
import Switch from '../app/form/Switch';
import UserModel from '../../models/UserModel';
import { FaExclamationCircle } from 'react-icons/fa';
import HelpInfoNotifications from '../app/help/HelpInfoNotifications';
import { documentId } from '../../utils/common';
import GenderhireJobsMenu from '../../menu/genderhire/GenderhireJobsMenu';

const AccountNotificationForm = (
	{
		user,
		onSuccess,
		style = {},
		...props
	},
) => {
	const className = 'AccountNotificationForm';

	const dispatch = useDispatch();
	const theme = useTheme();
	const { currentUser } = useAuthContext();

	const [form] = Form.useForm();

	const [state, stateDispatch] = useReducer((state, action) => {
		const hasErrors = form.getFieldsError().some(({ errors }) => errors.length);
		const isTouched = form.isFieldsTouched();
		LocalDebug.logInfo({ className, method: 'useReducer' }, {
			state,
			action,
			hasErrors,
			isTouched,
		});
		switch (action) {
			case 'forceDisabled':
				return { disabled: true };
			case 'updateDisabled':
				return { disabled: hasErrors };
		}
	}, { disabled: true });

	if (!user) return null;
	const userModel = new UserModel(user);
	const isDefaultRecipient = userModel.isDefaultRecipient();
	const shouldNotifyAllOnApplysIfNoRecipients = userModel.companyShouldNotifyAllOnApplysIfNoRecipients();
	const shouldBeNotifiedForApplysIfNoRecipient = userModel.companyMemberShouldBeNotifiedForApplysIfNoRecipient();
	const initialValues = {
		shouldBeNotifiedForApplysIfNoRecipient: shouldBeNotifiedForApplysIfNoRecipient || (!shouldNotifyAllOnApplysIfNoRecipients && isDefaultRecipient),
	};

	const handleFormChange = () => {
		stateDispatch('updateDisabled');
	};

	const onFormFinish = async () => {
		message.loading({ content: 'Updating settings', key: className });

		stateDispatch('forceDisabled');

		const data = {
			userId: documentId(user),
			companyId: user?.information?.companyId,
			...form.getFieldsValue(),
		};

		LocalDebug.logInfo({ className, method: 'onFormFinish' }, data);
		const result = await CompanyService.createOrUpdateCompanyMember(data);

		LocalDebug.logInfo({ className, method: 'onFormFinish' }, result);
		// await UserService.updateProperty(documentId(currentUser), { key: 'profile-avatar', value: imageUrl });
		message.success({ content: 'Your settings have been updated', key: className });

		if (documentId(user) === documentId(currentUser)) refreshCurrentUser()(dispatch);

		onSuccess?.();
	};

	const jobsMenu = (new GenderhireJobsMenu()).build();

	return (
		<>
			<Form
				form={form}
				name="user-notification-settings"
				initialValues={initialValues}
				onFinish={onFormFinish}
				style={{ ...style }}
				labelCol={{ span: 6 }}
				labelAlign='right'
				wrapperCol={{ span: 24 }}
				layout='vertical'
				onFieldsChange={handleFormChange}
				{...props}
			>
				<FormBoxTitle
					title={<><span>📥</span> Your notifications settings</>}
					details={<>
						If you have <b>subscribed</b> to notifications for a specific job offer,
						you'll be notified.<br />Visit the {new GenderhireJobsMenu().getInlineNavLink()} section to further manage your
						notifications.
					</>}
				 />

				<FormItem
					name="shouldBeNotifiedForApplysIfNoRecipient"
					valuePropName="checked"
				>
					<Switch
						disabled={!shouldNotifyAllOnApplysIfNoRecipients && isDefaultRecipient}
						className='ant-switch-large'
						checkedChildren={<b>&nbsp;I should be notified</b>}
						unCheckedChildren={<b>I should not be notified</b>}
						before={
							<>
									When a candidate applies on a job,
									if <b>nobody has subscribed</b> to notifications
									for that job,
							</>
						}
						after={(!shouldNotifyAllOnApplysIfNoRecipients && isDefaultRecipient)
								&& <>
									<br/>
									<Space
										style={{
											fontSize: 12,
											color: theme.color.accent,
											lineHeight: 1.3,
										}}
										align='top'
									>
										<FaExclamationCircle style={{ marginBottom: -2 }}/>
										<span>
												Modification of this setting is disabled, because you are set
												as the <b>default recipient</b> for this particular case. Contact
												your company admin to change this.
										</span>
									</Space>
								</>
						}
					/>
				</FormItem>

				<FormItem
					// label='&nbsp;'
				>
					<FormSubmitButton disabled={state?.disabled}>
							Save notifications settings
					</FormSubmitButton>
				</FormItem>
				<Divider/>
				<HelpInfoNotifications/>
			</Form>
		</>
	);
};

export default AccountNotificationForm;
