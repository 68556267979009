import { Select, Spin } from 'antd';
import { useState } from 'react';
import { useDebouncedEffect } from '../../../hooks/useDebounceEffect';

const AutoComplete = (
	{
		onStartSearch,
		onSearch,
		onChange,
		onSelect,
		children,
		value,
		debounceTimeout = 800,
		filterOption = false,
		disabled = false,
		style,
		...props
	},
) => {
	const [fetching, setFetching] = useState(false);
	const [search, setSearch] = useState(null);
	const [refresh, setRefresh] = useState(0);

	// ---------------------------------------- //
	// ----------- handle search -------------- //
	// ---------------------------------------- //
	const handleSearch = (value) => {
		if (onStartSearch) onStartSearch(value || '');
		// setFetching(true);
		setRefresh((p) => p + 1);
		setSearch(value || '');
	};

	useDebouncedEffect(async () => {
		if (onSearch) {
			setFetching(true);
			await onSearch(search);
		}
		setFetching(false);
	}, [search, refresh], debounceTimeout);

	return (
		<>
			<Select
				filterOption={filterOption}
				showSearch
				onSearch={handleSearch}
				notFoundContent={fetching ? <Spin size="large"/> : null}
				onChange={onChange}
				onSelect={onSelect}
				onFocus={() => { handleSearch(); }}
				{...props}
				value={value}
				optionLabelProp="label"
				disabled={disabled}
				style={{ ...style }}
			>
				{children}
			</Select>
		</>
	);
};

export default AutoComplete;
