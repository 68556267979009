import { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTheme } from '@emotion/react';
import {
	Button,
	Modal, Select, Space, Switch, Tooltip, message,
} from 'antd';
import { getCompanySelected } from '../../../../reducers/app';
import CompanyModel from '../../../../models/CompanyModel';
import { CompanyContext } from '../../../../contexts/CompanyProvider';
import {
	loadCompany, saveCompany, setCompanySelected,
} from '../../../../actions/company';
import {
	documentId, relabelAtsProvider,
} from '../../../../utils/common';
import { LocalDebug } from '../../../../utils/LocalDebug';
import CompanyPlanSelect from '../../../company/companyPlan/CompanyPlanSelect';
import { getBusinessPlanLabel, getBusinessPlanShortLabel } from '../../../../constants/property/business-plan';
import { StaffManagerPanelDivider, StaffManagerPanelTabContentWrapper } from '../StaffManagerPanel';
import {
	ATS_GREENHOUSE, ATS_LEVER, ATS_SMARTRECRUITERS, ATS_TEAMTAILOR, ATS_WORKABLE,
} from '../../../../models/CompanyAccessModel';
import AtsLogo from '../../../ats/AtsLogo';
import BlockNotProdEnv from '../../../app/blocker/BlockNotProdEnv';
import * as adminActions from '../../../../actions/admin';
import CompanyService from '../../../../services/company';
import Link from '../../../app/Link';
import { DownloadOutlined, PlusOutlined } from '@ant-design/icons';
import { request } from '../../../../services/api';
import { getAclRoleTag } from '../../../../constants/constant';
import { ACL_ROLE_DOCUMENT_ADMIN_LABEL } from '../../../../constants/acls';

const StaffManagerCompanyTab = () => {
	const className = 'StaffManagerCompanyTab';

	const theme = useTheme();

	const companySelected = useSelector(getCompanySelected);

	const { planValue } = useContext(CompanyContext);

	const dispatch = useDispatch();
	const [companyModel, setCompanyModel] = useState();
	const [companyAccess, setCompanyAccess] = useState();
	const [openPlanChangeModal, setOpenPlanChangeModal] = useState(false);
	const [planChangeValue, setPlanChangeValue] = useState();
	const [isAtsChangeLoading, setIsAtsChangeLoading] = useState(false);
	const [isJobFetchLoading, setIsJobFetchLoading] = useState(false);
	const [shouldRefetch, setShouldRefetch] = useState(false);
	const [selectedTestRole, setSelectedTestRole] = useState(ACL_ROLE_DOCUMENT_ADMIN_LABEL);
	const [simplifiedAccount, setSimplifiedAccount] = useState(true);
	const [isLoading, setIsLoading] = useState(false);

	const atsOptions = [
		{
			value: 'classic',
			label: 'No ATS',
			render: 'No ATS',
		},
		{
			value: ATS_LEVER,
			label: <>Lever</>,
			render: <>Lever</>,
		},
		{
			value: ATS_GREENHOUSE,
			label: <>GreenHouse</>,
			render: <>GreenHouse</>,
		},
		{
			value: ATS_SMARTRECRUITERS,
			label: <>SmartRecruiters</>,
			render: <>SmartRecruiters</>,
		},
		{
			value: ATS_TEAMTAILOR,
			label: <>TeamTailor</>,
			render: <>TeamTailor</>,
		},
		{
			value: ATS_WORKABLE,
			label: <>Workable</>,
			render: <b>Workable</b>,
		},
	];
	useEffect(() => {
		setCompanyModel(
			companySelected?._id
				? new CompanyModel(companySelected)
				: null,
		);
	}, [companySelected]);

	useEffect(() => {
		const access = companyModel?.access?.[0];
		setCompanyAccess(access ? { ...access } : null);
	}, [companyModel]);

	useEffect(() => {
		if (shouldRefetch) handleFetchJobs();
		setShouldRefetch(false);
	}, [companyAccess]);

	const handleSetPlan = async (newPlanValue) => {
		await dispatch(saveCompany({
			'id': documentId(companySelected),
			'companyPlan.planValue': newPlanValue,
		}));

		message.success(
			`Switch paid plan from '${companyModel.getCompanyPlanLabel()}' to '${getBusinessPlanLabel(newPlanValue)}'`,
		);
	};

	const handlePlanChange = (value) => {
		LocalDebug.logInfo({ className, method: 'handlePlanChange' }, { value });
		setOpenPlanChangeModal(true);
		setPlanChangeValue(value);
	};

	const handleFetchJobs = async () => {
		if (!companyAccess) {
			return message.warning({
				content: 'Fetching jobs aborted: company access not found',
			});
		}
		setIsJobFetchLoading(true);
		message.loading({
			key: className,
			duration: 0,
			content: <>Fetching jobs from <b>{relabelAtsProvider(companyAccess?.ats)}</b></>,
		});
		const result = await CompanyService
			.fetchAccessJobs(documentId(companyAccess));
		message.success({
			key: className,
			content: (
				<span>
					Successful fetch from <b>{relabelAtsProvider(companyAccess?.ats)}</b>
					<br /><b>{result?.data?.published} jobs published</b>
					&nbsp;(out of {result?.data?.published} found.)
				</span>
			),
		});
		dispatch(loadCompany(documentId(companyModel)));
		dispatch(setCompanySelected(documentId(companyModel)));
		setIsJobFetchLoading(false);
	};

	const handleAtsChange = async (value) => {
		LocalDebug.logInfo({ className, method: 'handleAtsChange' }, { value });
		setIsAtsChangeLoading(true);
		if (companyAccess) {
			await adminActions.removeDemoAccess(companyAccess.ats, companyModel)(dispatch);
			const result = await request.get(`/access/${documentId(companyAccess)}/deactivate-jobs`);
			LocalDebug.logInfo({ className, method: 'handleAtsChange' }, { result }, 'deactivate-jobs');
		}
		if (value !== 'classic') {
			// LocalDebug.logInfo({ className, method: 'handleAtsChange' }, { value }, 'adding access');
			const result = await adminActions.addDemoAccess(value, companyModel)(dispatch);
			LocalDebug.logInfo({ className, method: 'handleAtsChange' }, { result });
		}
		await dispatch(loadCompany(documentId(companyModel)));
		await dispatch(setCompanySelected(documentId(companyModel)));
		setIsAtsChangeLoading(false);
		setShouldRefetch(true);
	};

	const handleCreateTestAccount = async () => {
		const method = 'handleCreateTestAccount';

		setIsLoading(true);
		LocalDebug.logInfo({ className, method }, {
			roleId: selectedTestRole,
			simplifiedAccount,
			withEmail: !simplifiedAccount,
			withLoginPopup: !simplifiedAccount,
		});

		const result = await adminActions
			.addCompanyTestRole({
				company: companyModel,
				roleId: selectedTestRole,
				withEmail: !simplifiedAccount,
				withLoginPopup: !simplifiedAccount,
			})(dispatch);

		setIsLoading(false);
	};

	if (!companyModel) {
		return (
			<StaffManagerPanelTabContentWrapper>
				<i>Select a company</i>
			</StaffManagerPanelTabContentWrapper>
		);
	}

	return (
		<StaffManagerPanelTabContentWrapper>

			<BlockNotProdEnv
				withStaffView={false}
				withBorder={false}
				withTags={false}
			>
				<StaffManagerPanelTabContentWrapper
					style={{ padding: 0 }}
				>
					<StaffManagerPanelDivider>Test accounts</StaffManagerPanelDivider>

					<Space
						size={12}
						style={{
							width: '100%',
							fontSize: 12,
							border: '1px solid #d9d9d9',
							borderRadius: 3,
							padding: '1px 5px 3px 12px',
							justifyContent: 'space-between',
						}}
					>
						<div>
								Create a &nbsp;
							<Tooltip
								title={(
									<div style={{ fontSize: 12, lineHeight: 1.3 }}>
											Compared to a "<b>simplified</b>" account, a "<b>regular</b>" account will:
										<ul>
											<li>Receive an email with a temporary password</li>
											<li>will see the popup to complete information on
										the profile upon first login</li>
										</ul>
									</div>
								)}
							>
								<Switch
									checked={simplifiedAccount}
									onChange={setSimplifiedAccount}
									checkedChildren={<b>simplified</b>}
									unCheckedChildren={<b>regular</b>}
									style={{ marginBottom: 3 }}
								/>
							</Tooltip>

							<Select
								value={selectedTestRole}
								onChange={setSelectedTestRole}
								bordered={false}
								options={(
									companyModel?.getCompanyRoles()
										.map((roleId) => ({ value: roleId, label: getAclRoleTag(roleId, {}, { withTooltip: false }) }))
								)}
							/>
								account
						</div>

						<Button
							type='primary'
							size='small'
							disabled={isLoading}
							onClick={handleCreateTestAccount}
							style={{
								marginBottom: -2,
							}}
						>
							<PlusOutlined />
						</Button>
					</Space>

					<StaffManagerPanelDivider>Business plan</StaffManagerPanelDivider>

					<CompanyPlanSelect
						value={planValue || companyModel?.getCompanyPlanValue()}
						onChange={handlePlanChange}
						style={{
							width: '100%',
							fontSize: 12,
						}}
					/>

					<Modal
						open={openPlanChangeModal}
						onCancel={() => setOpenPlanChangeModal(false)}
						onOk={() => {
							handleSetPlan(planChangeValue);
							setOpenPlanChangeModal(false);
						}}
						title='Change plan'
						okText='Confirm'
					>
							This will switch the company page's paid plan
							from <b>{companyModel.getCompanyPlanShortLabel()}
						</b> to <b>{getBusinessPlanShortLabel(planChangeValue)}</b>
					</Modal>

					<StaffManagerPanelDivider>ATS</StaffManagerPanelDivider>

					<Select
						value={companyAccess?.ats || 'classic'}
						onChange={handleAtsChange}
						optionLabelProp={'label'}
						placeholder='Select an ATS'
						style={{
							minWidth: '100%',
						}}
						disabled={isAtsChangeLoading || isJobFetchLoading}
					>
						{atsOptions.map((option) => (
							<div
								key={option.value}
								label={<>
									<AtsLogo ats={option.value} size={24} />
									&nbsp;<b>{option.label}</b>
								</>}>
								<AtsLogo ats={option.value} size={24} />&nbsp;<b>{option.label}</b>
							</div>
						))}
					</Select>

					<Space
						style={{
							width: '100%',
							justifyContent: 'space-between',
							fontSize: 12,
							margin: '6px 0px',
						}}
					>
						<span>Jobs published: <b>{companyModel?.publishedJobsCount}</b></span>

						{companyAccess && (
							<Link
								disabled={!companyAccess || isAtsChangeLoading || isJobFetchLoading}
								onClick={handleFetchJobs}
								style={{ fontSize: 12 }}
							>
								Fetch jobs <DownloadOutlined />
							</Link>
						)}
					</Space>
				</StaffManagerPanelTabContentWrapper>
			</BlockNotProdEnv>

		</StaffManagerPanelTabContentWrapper>
	);
};

export default StaffManagerCompanyTab;
