import { useTheme } from '@emotion/react';
import {
	Button, Form, Select, Space,
} from 'antd';
import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { aclRoles } from '../../constants/constant';
import { useAuthContext } from '../../hooks/useAuthContext';
import { getCompanies } from '../../reducers/app';
import { documentId, getPermissionOptionsByUser, inArray, requiredRule } from '../../utils/common';
import FormItem, { FormItemContainer } from '../app/form/FormItem';
import UserSelect from '../user/UserSelect';
import FormAclRoleSelect from '../app/form/FormAclRoleSelect';
import FormCompanySelect from '../app/form/FormCompanySelect';

const AclEditForm = (
	{
		initialValues = {},
		onCompanyChange,
		onSubmit,
		onReset,
		withSubmitButton = false,
	},
) => {
	const theme = useTheme();
	const companies = useSelector(getCompanies);

	const {
		currentUser, isStaff, isStaffView, companyIdsUser,
	} = useAuthContext();

	const [form] = Form.useForm();

	const [roleOptions, setRoleOptions] = useState([]);
	const [isShowCompanySelect, setIsShowCompanySelect] = useState(true);
	const [roleSelected, setRoleSelected] = useState(null);

	useEffect(() => {
		const options = getPermissionOptionsByUser(aclRoles, currentUser);
		setRoleOptions(options);
	}, [currentUser]);

	useEffect(() => {
		if (isStaffView) {
			setIsShowCompanySelect(true);
			return;
		}

		if (companyIdsUser.length === 1) {
			onCompanyChange(companyIdsUser[0]);
			setIsShowCompanySelect(false);
		}
	}, [companyIdsUser, isStaff, isStaffView, onCompanyChange]);

	return (<>
		<Form
			form={form}
			name="acl_form"
			initialValues={{ ...initialValues }}
			onFinish={onSubmit}
		>
			<FormItemContainer>

				<FormItem
					name="userId"
					label="User"
					defaultContainer="div"
				>
					<UserSelect
						defaultOptions={initialValues?.defaultUserOptions ? [initialValues?.defaultUserOptions] : []}
						{...isStaffView
							? {}
							: { searchOptions: { searchField: ['slug', 'firstName', 'lastName', 'email'] } }
						}
						disabled={!isStaffView}
						placeholder="User"
						style={{ width: '100%' }}
					/>
				</FormItem>

				<FormItem
					name="roleId"
					label="Role"
					defaultContainer="div"
					rules={[requiredRule]}
				>
					<FormAclRoleSelect
						options={roleOptions.map((role) => ({ value: role.value, label: role.label }))}
						onChange={(value) => setRoleSelected(value)}
						mode="single"
					/>
					{/* <FormSelect */}
					{/*	options={roleOptions.map((role) => ({value: role.value, label: role.label}))} */}
					{/*	render={(option) => <Select.Option key={option.value} label={option.label} value={option.value}> */}
					{/*		<Space direction='vertical' size={4}> */}
					{/*			{getAclRoleTag(option.value)} */}
					{/*			<span style={{width: '100%', whiteSpace: 'normal', fontSize: 12, color: theme.color.darkgrey, lineHeight: 1}}>{getAclRoleDetails(option.value)}</span> */}
					{/*		</Space> */}
					{/*	</Select.Option>} */}
					{/*	onChange={(value) => setRoleSelected(value)} */}
					{/*	placeholder="Select a role" */}
					{/*	styles={{minWidth: 150}} */}
					{/* /> */}
				</FormItem>

				{isShowCompanySelect
						&& <FormItem
							label="Company"
							name="companyId"
							defaultContainer="div"
							rules={[
								{
									validator: async (_, value) => {
										if (inArray(roleSelected, roleOptions.filter((role) => role?.isCompanyRequired).map((r) => r.value)) && !value) throw new Error('This field is required');
										return true;
									},
								},
							]}
						>
							<FormCompanySelect
								options={companies.map((c) => ({ value: documentId(c), label: c.name, item: c }))}
								mode="single"
								placeholder="Select a company"
								onChange={(value) => onCompanyChange(value)}
							/>
							{/* <FormSelect */}
							{/*	options={companies.map((c) => ({value: documentId(c), label: c.name}))} */}
							{/*	placeholder="Select a company" */}
							{/*	onChange={(value) => onCompanyChange(value)} */}
							{/* /> */}
						</FormItem>
				}

			</FormItemContainer>

			{withSubmitButton && <div style={{
				display: 'flex',
				flexDirection: 'row-reverse',
				margin: -5,
			}}>
				<Button type="primary"
					style={{
						margin: 5,
						background: theme.color.blue,
						borderColor: theme.color.blue,
						fontWeight: 'bold',
						borderRadius: 5,
					}}
					htmlType="submit"
				>
						Edit access
				</Button>
				<Button type="default"
					style={{
						margin: 5,
						borderColor: theme.color.orange,
						fontWeight: 'bold',
						borderRadius: 5,
						color: theme.color.orange,
					}}
					htmlType="reset"
					onClick={onReset}
				>
						Cancel
				</Button>
			</div>
			}
		</Form>
	</>
	);
};

export default AclEditForm;
