import copy from 'clipboard-copy';
import { openExternalUrl } from '../../../../utils/common';
import {
	Button as AntButton, message, Space, Tooltip, Upload,
} from 'antd';
import { useTheme } from '@emotion/react';
import { useAuthContext } from '../../../../hooks/useAuthContext';
import { FaFileUpload, FaLinkedinIn, FaUpload } from 'react-icons/fa';
import userService from '../../../../services/user';
import UserResumeLatestInfo from './UserResumeLatestInfo';
import UserResumePreviewer from './UserResumePreviewer';
import UserModel from '../../../../models/UserModel';

const UserResumeManager = (
	{
		user,
		onUpload,
		withLatest = true,
		withLatestDate = true,
		withLatestDelay = true,
		withLatestUploader = true,
		openLabel = 'Open Profile',
		uploadLabel = 'Upload LinkedIn PDF',
		onRefresh,
		children,
	},
) => {
	const { isAdmin, isStaff, isStaffView } = useAuthContext();
	const theme = useTheme();

	const handleLinkedIn = async () => {
		await copy(user.slug);
		const linkedinLink = (
			(user?.information?.website && user?.information?.website.indexOf('linkedin') >= 0)
				? user.information.website
				: `www.linkedin.com/search/results/all/?keywords=${encodeURIComponent(user.firstName)}%20${encodeURIComponent(user.lastName)}&origin=GLOBAL_SEARCH_HEADER`
		);
		openExternalUrl(linkedinLink);
	};

	const uploadProps = {
		beforeUpload: (file) => {
			const isPDF = file.type === 'application/pdf';
			if (!isPDF) {
				message.error(`${file.name} is not a pdf file`);
			}
			return isPDF || Upload.LIST_IGNORE;
		},
		customRequest: async (options) => {
			const {
				onSuccess, onError, file, onProgress,
			} = options;
			const fmData = new FormData();
			const config = {
				headers: { 'content-type': 'multipart/form-data' },
				onUploadProgress: (event) => {
					// debug((event.loaded / event.total) * 100);
					// onProgress({ percent: (event.loaded / event.total) * 100 },file);
				},
			};
			const result = await userService.uploadResume(file, user.slug);
			if (result) {
				message.success(`LinkedIn PDF successfully converted for ${result.data.slug}`);
				onUpload?.();
				// onSuccess(file);
			} else {
				message.error(`An error occurred : ${result.data.message}`);
				onError('Error');
			}
		},

		previewFile(file) {
			message.success(`File uploaded: ${file}`);
		},
	};

	const openButtonTooltip = <span>
		Open the LinkedIn profile (or a search) to download the resume in PDF ('More' > 'Save as PDF').<br />
💡 		When saving, rename the file to <b>{user.slug}.pdf</b> (this name will already be copied in your clipboard)
	</span>;

	const uploadButtonTooltip = <span>
		Upload the PDF resume downloaded from LinkedIn.<br/>
		The file should be named:<br /><b>{user.slug}.pdf</b>
	</span>;

	const openButton = <AntButton
		// icon={<FaLinkedinIn style={{margin: [0, 6, -2, 6], /*marginBottom:-2, marginRight: 6*/}}/>}
		icon={<FaLinkedinIn style={{ margin: '0 6px -2px 6px' /* marginBottom:-2, marginRight: 6 */ }}/>}
		type={!new UserModel(user)?.hasResume() && 'primary' || 'default'}
		onClick={handleLinkedIn}
	>
		{openLabel}
	</AntButton>;

	const uploadButton = <AntButton
		icon={<FaFileUpload style={{ margin: '0 6px -2px 6px' }}/>}
		type={!new UserModel(user)?.hasResume() && 'primary' || 'default'}
	>
		{uploadLabel}
	</AntButton>;

	if (!user) return null;

	return (
		<Space
			direction='horizontal'
			size={6}
			align='center'
			wrap={true}
		>
			<UserResumePreviewer user={user} />

			<Tooltip title={openButtonTooltip}>
				{openButton}
			</Tooltip>

			<Tooltip title={uploadButtonTooltip}>
				<Upload {...uploadProps} fileList={[]}>
					{uploadButton}
				</Upload>
			</Tooltip>

			{withLatest && <UserResumeLatestInfo
				user={user}
				withDate={withLatestDate}
				withDelay={withLatestDelay}
				withUploader={withLatestUploader}
			/>}

			{children}
		</Space>
	);
};

export default UserResumeManager;
