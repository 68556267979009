import AppStatsPage from '../../../containers/staff/data/AppStatsPage';
import { onEnterBuilder } from '../../../actions/app';
import { PERMS_GRANT_STAFF } from '../../menu.utils';

const menu = {
	name: 'staff-data-app-stats',
	path: '/staff/data/app-stats',
	label: 'App stats',
	icon: <span>📈</span>,
	component: AppStatsPage,
	onEnter: onEnterBuilder({
		origin: AppStatsPage.name,
		shouldLoadRecruiters: true,
		shouldGoToPreviousHistory: true,
	}),
	perms: PERMS_GRANT_STAFF,
};

export default menu;
