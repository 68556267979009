import { useTheme } from '@emotion/react';
import Link from '../../../app/Link';
import GenderScorePillarLogoLabel from '../pillars/GenderScorePillarLogoLabel';
import { useDrawer } from '../../../../hooks/useDrawer';
import { ArrowRightIcon } from '../../../../utils/common';
import { writeGenderScoreFilledLog } from '../../../../constants/genderscore-logs';
import { COMPANY_RECRUITER_LOG } from '../../../../constants/company-recruiter-log';
import GenderScoreActionDrawer from './GenderScoreActionDrawer';
import { useEffect } from 'react';

const GenderScoreActionDetailsLink = ({
	company,
	forceValue,
	action,
	pillar,
	label,
	icon,
	withIcon = true,
	onAddSuccess,
	onRemoveSuccess,
	...props
}) => {
	const className = 'GenderScoreActionDetailsLink';

	const theme = useTheme();

	const {
		open: openDrawer,
		setOpen: setOpenDrawer,
		setTitle: setDrawerTitle,
		setContent: setDrawerContent,
	} = useDrawer();

	const handleOpenActions = () => {
		writeGenderScoreFilledLog({
			type: {
				element: action?.value,
				action: COMPANY_RECRUITER_LOG.OPEN_DRAWER,
			},
			context: 'action-details',
		});
		setDrawerTitle(
			<div
				style={{
					margin: '-16px -24px',
					padding: 32,
					background: theme.background.gradientBluePink(),
				}}
			>
				<GenderScorePillarLogoLabel
					pillar={action?.pillar || pillar}
					iconSize={20}
					labelSize={14}
				/>
				<h2 style={{ lineHeight: 1.3 }}>
					{action?.title}
				</h2>
			</div>,
		);
		setDrawerContent(
			<GenderScoreActionDrawer
				company={company}
				forceValue={forceValue}
				action={action}
				onAddSuccess={onAddSuccess}
				onRemoveSuccess={onRemoveSuccess}
			/>,
		);
		setOpenDrawer(true);
	};

	useEffect(() => {
		if (openDrawer) {
			setDrawerContent(
				<GenderScoreActionDrawer
					company={company}
					forceValue={forceValue}
					action={action}
					onAddSuccess={onAddSuccess}
					onRemoveSuccess={onRemoveSuccess}
				/>,
			);
		}
	}, [action]);

	return (
		<Link
			onClick={handleOpenActions}
			style={{ ...props?.style }}
		>
			{label || 'Action plan'}{withIcon && (
				<>&nbsp;
					{icon || <ArrowRightIcon />}
				</>
			)}
		</Link>
	);
};

export default GenderScoreActionDetailsLink;
