import Table from '../app/table/Table';
import FilterButton from '../app/filters/FilterButton';
import UserFilterModal from '../user/UserFilterModal';
import { useAuthContext } from '../../hooks/useAuthContext';

const UserTable = (
	{
		dataSource,
		columns,
		onFilter,
		onClearFilter,
		initialValues = {},
		isRefresh = 0,
		...otherProps
	},
) => {
	const { isStaffView } = useAuthContext();

	return (
		<>
			{isStaffView && <FilterButton
				title="Filter talents"
				modal={UserFilterModal}
				onFilter={onFilter}
				onClearFilter={onClearFilter}
				initialValues={initialValues}
			/>}
			<Table
				defaultDataSource={dataSource}
				columns={columns}
				withClientSidePagination
				isRefresh={isRefresh}
				{...otherProps}
			/>
		</>
	);
};

export default UserTable;
