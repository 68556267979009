import { useTheme } from '@emotion/react';
import { Space, Tag } from 'antd';
import {
	NOT_PROVIDED_YET_LABEL, USER_TAG_JOB_SEARCH_EMPLOYMENT_VISA, getJobRemoteLabel, userTagStyle,
} from '../../../constants/constant';
import { arrayWrap } from '../../../utils/common';
import { useSelector } from 'react-redux';
import { getTagsUsers } from '../../../reducers/app';
import { EMPLOYMENT_VISA_OPTIONS } from '../../../constants/property/employment-visa';

const UserEmploymentVisa = (
	{
		user,
		mode = 'tags',
		withGroups = false,
		forceValue = null,
		size = 'medium',
		full = false,
		notProvided = NOT_PROVIDED_YET_LABEL,
		...props
	},
) => {
	const value = arrayWrap(forceValue || user?.jobSearch?.employmentVisa);
	if (!(value?.length > 0)) return notProvided;

	const tagStyle = {
		...userTagStyle(size),
		...props?.style,
	};
	const tags = value.map((tag, index) => <Tag
		color='blue'
		key={index}
		style={tagStyle}
	>
		{EMPLOYMENT_VISA_OPTIONS?.find((v) => v.value === tag)?.label}

	</Tag>);
	return tags?.length
		? <Space
			direction='horizontal'
			wrap={true}
			size={2}
		>
			{tags}
		</Space>
		: notProvided;
};

export default UserEmploymentVisa;
