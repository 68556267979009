import { Space } from 'antd';
import { useEffect, useState } from 'react';
import PageLayout from '../../../components/app/layout/PageLayout';
import SectionTitle from '../../../components/app/SectionTitle';
import AppStatsTable from '../../../components/staff/data/appStats/AppStatsTable';
import AppStatsFilters from '../../../components/staff/data/appStats/AppStatsFilters';
import StatService from '../../../services/stat';
import { LocalDebug } from '../../../utils/LocalDebug';
import { documentId } from '../../../utils/common';
import { BarChartOutlined } from '@ant-design/icons';

const AppStatsPage = () => {
	const className = 'AppStatsPage';

	const [filterOptions, setFilterOptions] = useState({});
	const [statsOptions, setStatsOptions] = useState([]);
	const [selectedStatOption, setSelectedStatOption] = useState(null);
	const [isFilters, setIsFilters] = useState(false);
	const [refreshStatsTable, setRefreshStatsTable] = useState(0);

	const handleRefresh = () => {
		setRefreshStatsTable((p) => p + 1);
	};

	useEffect(async () => {
		const results = await StatService.lists();
		LocalDebug.logUseEffect({ className, effects: '' }, { results });
		setStatsOptions(results?.data?.stats || []);
	}, []);

	useEffect(() => {
		const { stat, period } = filterOptions;
		setSelectedStatOption(statsOptions.find((o) => o.value === stat));
		setIsFilters(stat && period);
	}, [filterOptions]);

	const handleDocumentIdSelected = (id) => {
		setFilterOptions((p) => ({ ...p, documentId: id }));
	};

	return (
		statsOptions?.length > 0 && (
			<PageLayout
				withBackground={false}
				title='App Stats'
				icon={<BarChartOutlined />}
			>
				<AppStatsFilters
					statsOptions={statsOptions}
					selectedStatOption={selectedStatOption}
					onFilterChanged={setFilterOptions}
					onRefresh={handleRefresh}
					filterOptions={filterOptions}
				/>

				{isFilters && <AppStatsTable
					isRefresh={refreshStatsTable}
					filterOptions={{
						bustCache: true,
						// documentId: documentId(companySelected),
						...filterOptions,
					}}
					onDocumentIdSelected={handleDocumentIdSelected}
				/>}

			</PageLayout>
		)
	);
};

export default AppStatsPage;
