import { useTheme } from '@emotion/react';
import { Tooltip, Typography, message } from 'antd';
import copy from 'clipboard-copy';
import Link from '../Link';
import { FaCopy } from 'react-icons/fa';

const CopyClipboardLink = ({
	title,
	content,
	copyAsJSON = false,
	withIcon = true,
}) => {
	const theme = useTheme();

	const contentToCopy = copyAsJSON
		? JSON.stringify(content || title)
		: content || title;

	return (
		<Tooltip title="Click to copy to clipboard">
			<Link
				onClick={() => {
					copy(contentToCopy);
					message.success({ content: 'Copied to clipboard' });
				}}
			>
				{withIcon && <FaCopy style={{ marginBottom: -2 }} />} {title || contentToCopy}
			</Link>

		</Tooltip>
	);
};

export default CopyClipboardLink;
