import { useTheme } from '@emotion/react';
import { useState } from 'react';
import Table from '../app/table/Table';
import { useAuthContext } from '../../hooks/useAuthContext';
import TableColumns from '../app/table/TableColumns';
import { Button, Space, Tag } from 'antd';
import { useSelector } from 'react-redux';
import { localDebug } from '../../utils/LocalDebug';
import AtsStageMappingEditPopover from './AtsStageMappingEditPopover';
import { getAtsPipelines } from '../../reducers/app';
import CompanyService from '../../services/company';
import { documentId } from '../../utils/common';

const AtsStageTable = (
	{
		companyAccess,
		isRefresh = 0,
		...props
	},
) => {
	const theme = useTheme();

	const { isStaffView } = useAuthContext();
	const atsPipelines = useSelector(getAtsPipelines);
	const [openAtsStageMappingEdit, setOpenAtsStageMappingEdit] = useState(false);
	const [atsStageMappingEditInitialValues, setAtsStageMappingEditInitialValues] = useState({});
	const [isRefreshDataLayout, setIsRefreshDataLayout] = useState(0);

	const refreshTable = () => {
		setIsRefreshDataLayout((prev) => prev + 1);
	};

	const handleEditStageMapping = (row) => {
		localDebug('handleEditStageMapping', row, atsPipelines);
		setOpenAtsStageMappingEdit(true);
		setAtsStageMappingEditInitialValues(row);
	};

	const onAtsStageMappingEditCloseWithRefresh = (data) => {
		localDebug('onAtsStageMappingEditCloseWithRefresh', { data });
		setOpenAtsStageMappingEdit(false);
		refreshTable();
	};
	const onAtsStageMappingEditSubmit = async (data) => {
		localDebug('onAtsStageMappingEditSubmit', { data });
		const result = await CompanyService.setAtsStageMapping(data.stage, documentId(data));
		localDebug('onAtsStageMappingEditSubmit', { result });
		setOpenAtsStageMappingEdit(false);
		refreshTable();
	};
	const onAtsStageMappingEditReset = (data) => {
		localDebug('onAtsStageMappingEditReset', { data });
		setOpenAtsStageMappingEdit(false);
	};

	const labelCol = {
		title: 'Stage',
		dataIndex: 'text',
		render: (value, row) => <Space direction='vertical' size={6}>
			<b>{row.text}</b>
			<code style={{ fontSize: 10 }}>{documentId(row)}</code>
		</Space>,
		...TableColumns.columnMinWidth(120),
	};

	const textCol = {
		title: 'Text',
		dataIndex: 'text',
		render: (value, row) => <b>{value}</b>,
		...TableColumns.columnMinWidth(140),
	};

	const idCol = {
		title: 'ID',
		dataIndex: 'id',
		render: (value, row) => <code style={{ fontSize: 10 }}>{value}</code>,
		...TableColumns.columnMinWidth(200),
	};

	const pipelineStageCol = {
		title: 'Pipeline & Stage',
		dataIndex: 'pipeline',
		// render: (value, row) => <JSONPretty data={row} />,
		render: (value, row) => <Space direction='horizontal'>
			{row.stage?.pipeline
				&& <Tag color='green' icon={row.pipeline?.icon}>&nbsp;<b>{row.stage?.pipeline}</b></Tag>}
			{row.stage && <Tag color='cyan' icon={row.stage?.icon}>&nbsp;<b>{row.stage?.label}</b></Tag>}
			<Button onClick={() => { handleEditStageMapping(row); }}>Edit</Button>
		</Space>,
		...TableColumns.columnMinWidth(220),
	};

	const pipelineCol = {
		title: 'Pipeline mapping',
		dataIndex: 'pipeline',
		render: (value, row) => row.stage?.pipeline
			&& <Tag color='green' icon={row.pipeline?.icon}>&nbsp;<b>{row.stage?.pipeline?.label}</b></Tag>,
		...TableColumns.columnMinWidth(80),
	};

	const stageCol = {
		title: 'Stage mapping',
		dataIndex: 'stage',
		render: (value, row) => row.stage
			&& <Tag color='cyan' icon={row.stage?.icon}>&nbsp;<b>{row.stage?.label}</b></Tag>,
		...TableColumns.columnMinWidth(140),
	};

	const editCol = {
		title: 'Edit mapping',
		dataIndex: 'actions',
		render: (value, row) => <Button onClick={() => { handleEditStageMapping(row); }}>Edit</Button>,
		...TableColumns.columnMinWidth(80),
	};
	const jsonCol = TableColumns.jsonColumn(theme);

	const columns = isStaffView
		? [
			jsonCol,
			// labelCol,
			textCol,
			idCol,
			pipelineCol,
			stageCol,
			editCol,
			// pipelineStageCol,
			// textCol,
			// idCol,
		]
		: [
			labelCol,
			pipelineCol,
			stageCol,
			// pipelineStageCol,
			// textCol,
			// idCol,
		];

	return (companyAccess && <>
		<Table
			path={`access/${documentId(companyAccess)}/ats-stages`}
			columns={columns}
			isRefresh={isRefresh + isRefreshDataLayout}
			withPagination={false}
			{...props}
		/>

		<AtsStageMappingEditPopover
			open={openAtsStageMappingEdit}
			initialValues={atsStageMappingEditInitialValues}
			onCloseWithRefresh={onAtsStageMappingEditCloseWithRefresh}
			onSubmit={onAtsStageMappingEditSubmit}
			onReset={onAtsStageMappingEditReset}
		/>
	</>
	);
};

export default AtsStageTable;
