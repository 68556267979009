import FormItem from '../../company/form/FormItem';
import {
	Col, Row, Switch,
} from 'antd';
import { useEffect, useState } from 'react';
import { EMPLOYMENT_VISA_FILTER_OPTIONS } from '../../../constants/property/employment-visa';
import { USER_TAG_JOB_SEARCH_EMPLOYMENT_VISA_FILTER } from '../../../constants/constant';

const VisaFilter = (
	{
		form,
		parent,
		labelColSpan = 6,
		initialValues,
		onChange,
		styles = {},
	},
) => {
	const className = 'VisaFilter';
	const fieldName = [...parent || [], USER_TAG_JOB_SEARCH_EMPLOYMENT_VISA_FILTER.value];

	const [hideVisa, setHideVisa] = useState({});

	const onVisaToggleUpdate = (value, visaLocationKey) => {
		// Toggle the visa value for the visaLocationKey
		setHideVisa({
			...hideVisa,
			[visaLocationKey]: value,
		});

		// Add/Remove the visaLocationKey in the form value
		let newVisaValue = form.getFieldValue(fieldName)
			? [...form.getFieldValue(fieldName)] : [];
		if (value) {
			// Include visaLocation in list
			newVisaValue.push(visaLocationKey);
		} else {
			// Exclude visaLocation from the list
			newVisaValue = newVisaValue.filter((v) => v !== visaLocationKey);
		}

		form.setFieldValue(
			fieldName,
			newVisaValue,
		);

		onChange?.();
	};

	useEffect(() => {
		// Load default values from initial values

		setHideVisa(EMPLOYMENT_VISA_FILTER_OPTIONS.reduce((acc, visaLocation) => {
			if (!visaLocation?.value) {
				return {};
			}
			return {
				...acc,
				[visaLocation.value]: initialValues?.some((v) => v === visaLocation.value),
			};
		}, {}));

		form.setFieldValue(
			fieldName,
			initialValues,
		);
	}, []);

	return (
		<div style={{ ...styles }}>
			<Row style={{ marginBottom: -20 }}>
				<Col span={24}>
					<FormItem
						key={USER_TAG_JOB_SEARCH_EMPLOYMENT_VISA_FILTER.value}
						labelCol={{ span: labelColSpan }}
						label={USER_TAG_JOB_SEARCH_EMPLOYMENT_VISA_FILTER.label}
						labelAlign='right'
						name={[...parent || [],
							USER_TAG_JOB_SEARCH_EMPLOYMENT_VISA_FILTER?.value]}
						style={{ width: '100%' }}
						shouldUpdate
					>
						<>
							{EMPLOYMENT_VISA_FILTER_OPTIONS.map((visaLocation) => {
								return <div style={{ marginRight: 10, marginLeft: 10 }} key={visaLocation?.value}>
									<Switch
										key={`${visaLocation?.value}-switch`}
										onChange={(v) => onVisaToggleUpdate(v, visaLocation?.value)}
										checked={hideVisa?.[visaLocation?.value]}
									/> {visaLocation?.label}
								</div>;
							})}
						</>
					</FormItem>
				</Col>
			</Row>

		</div>
	);
};

export default VisaFilter;
