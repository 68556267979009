import { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTheme } from '@emotion/react';
import { HelmetProvider } from 'react-helmet-async';
import version from '../autoBuildVersion';
import { getCurrentScreen } from '../components/app/Responsive';
import AppLayout from '../components/app/layout/AppLayout';
import { updateScreen } from '../actions/app';
import { getCompanies, getLoading } from '../reducers/app';
import { CloudinaryContext } from 'cloudinary-react';
import DrawerProvider from '../contexts/DrawerProvider';
import config from '../config/config';
import { Helmet } from 'react-helmet';
import { Outlet, useNavigate } from 'react-router-dom';
import { COMPANY_SELECTED_QUERY_PARAM, CompanyContext } from '../contexts/CompanyProvider';
import { getQueryParams } from '../utils/common';
import LoadSpinner from '../components/app/LoadSpinner';
import Popins from './Popins';
import ModalProviders from '../contexts/modals/ModalProviders';
import DrawerContainer from '../contexts/DrawerContainer';
import StaffManagerPanel from '../components/staff/manager/StaffManagerPanel';

const App = (props) => {
	const className = 'App';

	const theme = useTheme();
	const dispatch = useDispatch();
	const loading = useSelector(getLoading);
	const _updateDisplayScreen = () => {
		dispatch(updateScreen(getCurrentScreen()));
	};

	const { dispatchCompanySelectedFromQueryParam } = useContext(CompanyContext);
	const companies = useSelector(getCompanies);

	// Do not remove for company selector to work
	const navigate = useNavigate();

	const queryParams = getQueryParams();

	useEffect(() => {
		if (!(companies?.length > 1)) return;
		const companySelected = queryParams?.[COMPANY_SELECTED_QUERY_PARAM]
			|| localStorage.getItem(COMPANY_SELECTED_QUERY_PARAM);
		if (companySelected) {
			dispatchCompanySelectedFromQueryParam(companySelected);
		}
	}, [queryParams, companies]);

	useEffect(() => {
		window.addEventListener('resize', _updateDisplayScreen);
		_updateDisplayScreen();

		return () => {
			window.removeEventListener('resize', _updateDisplayScreen);
		};
	});

	return (
		<>
			<HelmetProvider>
				<Helmet>
					<meta charSet='utf-8' />
					<title>{config.isNotProdVersion ? `[${config.env} v${version}] ` : ''}50inTech for Recruiters</title>
				</Helmet>
				<CloudinaryContext
					cloudName={config.cloudinary.cloud_name} secure='true'
					upload_preset={config.cloudinary.upload_preset}
				>
					<DrawerProvider>
						<ModalProviders>
							<AppLayout>
								<Outlet />
								<LoadSpinner
									open={loading}
								/>
							</AppLayout>
							<StaffManagerPanel />
							<DrawerContainer />
							<Popins />
						</ModalProviders>
					</DrawerProvider>
				</CloudinaryContext>
			</HelmetProvider>
		</>);
};

export default App;
