import {
	Button, Space, Tag, Tooltip,
} from 'antd';
import { useContext } from 'react';
import { CandidateContext } from '../../contexts/CandidateProvider';
import { FaCheck, FaTimes } from 'react-icons/fa';
import theme from '../../theme';
import { isFunction } from '../../utils/common';

const StageTag = (
	{
		candidatePipeline,
		candidateStage,
		label,
		withPipeline = false,
		error = false,
		onClick,
	},
) => {
	const { findPipeline, findStage } = useContext(CandidateContext);

	const stage = findStage(candidateStage) || candidateStage;
	const pipeline = findPipeline(candidateStage?.pipelineId || candidatePipeline) || candidatePipeline;

	let tagIcon = <FaCheck color={theme.color.darkturquoise} style={{ marginBottom: -2, marginRight: 4 }}/>;
	const tagLabel = label || stage?.label || stage;
	let tagColor = 'green';

	if (stage) {
		tagIcon = <span>{stage?.icon}</span>;
	}

	let tooltiper = (children) => children;

	if (error) {
		tagColor = 'volcano';
		tagIcon = <FaTimes color={theme.color.red} style={{ marginBottom: -2, marginRight: 4 }}/>;
		tooltiper = (children) => <Tooltip title={error?.data?.message || error?.message}>{children}</Tooltip>;
	}

	const tagStyles = {
		borderRadius: 20,
		fontWeight: 'bold',
		margin: 0,
		paddingTop: 1,
		paddingBottom: 1,
	};

	const pipelineTag = <Tag color='cyan' style={tagStyles}>{pipeline?.label}</Tag>;

	const stageTag = tooltiper(
		tagLabel
			? <Tag
				icon={tagIcon}
				color={tagColor}
				style={tagStyles}
			>
                &nbsp;{tagLabel}
			</Tag>
			: 'No tag found',
	);

	const content = withPipeline
		? <Space direction='horizontal' size={2}>
			{pipelineTag}
			{stageTag}
		</Space>
		: stageTag;

	const buttonWrapper = (children) => (isFunction(onClick)
		? <Button
			style={{
				borderRadius: 20,
				padding: 0,
				boxShadow: 'none',
				lineHeight: 'unset',
				height: 'unset',
				margin: 0,
				border: 'none',
				background: 'none',
				outline: 'none',
			}}
			onClick={onClick}>
			{children}
		</Button>
		: children);

	return <>
		{/* p:{candidatePipeline?.value} s: {candidateStage?.value} */}
		{buttonWrapper(content)}
	</>;
};

export default StageTag;
