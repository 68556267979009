import { useNavigate } from 'react-router';
import { useTheme } from '@emotion/react';
import CompanyModel from '../../../../models/CompanyModel';
import GenderScoreCountdownBanner from './GenderScoreCountdownBanner';
import GenderScoreExpiredBanner from './GenderScoreExpiredBanner';
import { useEffect, useState } from 'react';
import { LocalDebug } from '../../../../utils/LocalDebug';
import GenderScoreUnpublishedBanner from './GenderScoreUnpublishedBanner';

const GenderScoreResultsExpirationBanners = ({
	company,
	withNavigateToSurvey = true,
}) => {
	const className = 'GenderScoreResultsExpirationBanners';

	const theme = useTheme();

	const [refresh, setRefresh] = useState(0);
	const companyModel = new CompanyModel(company);

	useEffect(() => {
		const id = setInterval(() => {
			LocalDebug.logNull({ className, effects: 'setInterval' });
			setRefresh((p) => p + 1);
		}, 1000);
		return () => {
			LocalDebug.logNull({ className, effects: 'setInterval cleanup' }, { id });
			clearInterval(id);
		};
	}, []);

	if (!companyModel?.isGenderScoreBadgeStaffPublished()) {
		return <GenderScoreUnpublishedBanner withNavigateToSurvey={withNavigateToSurvey}/>;
	}
	if (
		companyModel?.isGenderScoreExpired()
		|| companyModel?.isCurrentGenderScoreBadgeExpired()
	) {
		return <GenderScoreExpiredBanner withNavigateToSurvey={withNavigateToSurvey}/>;
	}

	if (
		companyModel?.isGenderScoreCloseToExpire()
		|| companyModel?.isCurrentGenderScoreBadgeCloseToExpire()
	) {
		return <GenderScoreCountdownBanner
			withNavigateToSurvey={withNavigateToSurvey}
			isExpirationOfCurrentBadge={companyModel?.isCurrentGenderScoreBadgeCloseToExpire()}
		/>;
	}

	return null;
};

export default GenderScoreResultsExpirationBanners;
