import { useMemo } from 'react';
import { useTheme } from '@emotion/react';
import { Space } from 'antd';
import { shuffle } from 'lodash';
import { documentId } from '../../../../utils/common';
import GenderScoreLayoutBox from '../layout/GenderScoreLayoutBox';
import GenderScoreLevelLabel from '../levels/GenderScoreLevelLabel';
import { getLevelFromScore } from '../../../../constants/genderscore-levels';
import CompanyStub from '../../CompanyStub';
import CompanyGenderScoreRingScore from '../CompanyGenderScoreRingScore';
import GenderScoreMedalBlock from '../dashboard/blocks/GenderScoreMedalBlock';
import { LocalDebug } from '../../../../utils/LocalDebug';
import config from '../../../../config/config';

const GenderScoreResultsLevelStatsBox = ({
	company,
	forceValue,
	fontSize = 36,
	withRanking = false,
	...props
}) => {
	const className = 'GenderScoreResultsLevelStatsBox';

	const theme = useTheme();

	const genderScore = forceValue || company?.genderScore;

	const levelValue = getLevelFromScore(genderScore?.globalScore);

	const memoizedCompanies = useMemo(() => {
		const bracket = 5;

		let companyIndex = genderScore?.currentStatistics?.rankings?.globalScore
			?.findIndex((item) => documentId(item)?.toString?.() === documentId(company)?.toString?.());

		companyIndex = Math.max(0, companyIndex - bracket);

		LocalDebug.logInfo({ className, method: 'useMemo' }, genderScore?.currentStatistics);
		const companyList = Object.entries(genderScore?.currentStatistics?.levels || {})
			.map(([level, { companies }]) => companies)
			.flat();

		let similarCompanies = genderScore?.currentStatistics?.rankings?.globalScore
			?.filter((item) => documentId(item)?.toString?.() !== documentId(company)?.toString?.())
			?.filter((item) => (item.name !== '50inTech'))
			?.filter((item) => (config.isStagingEnv || item.name.indexOf('Test') !== 0))
			?.slice(companyIndex, companyIndex + bracket * 2);

		similarCompanies = shuffle(similarCompanies);
		similarCompanies = similarCompanies.slice(0, 3);
		similarCompanies = similarCompanies.map((item) => ({
			...item,
			...companyList.find((c) => documentId(c)?.toString?.() === documentId(item)?.toString?.()) || {},
			genderScore: { globalScore: item?.value },
		}));

		return similarCompanies;
	}, [genderScore?.globalScore, company?.genderScore?.currentStatistics]);

	if (!company?.genderScore?.currentStatistics) return 'No statistics available';

	return (
		<>
			<GenderScoreLayoutBox
				withShadow={false}
				style={{
					background: theme.color.bgGrey,
					border: '1px dashed #ddd',
					color: '#333',
					...props?.style?.box,
				}}
			>
				<div
					style={{
						fontSize,
						marginBottom: 2,
						color: theme.color.fitBlack,
						...props?.style?.pct,
					}}
				>
					{Math.round(company?.genderScore?.currentStatistics?.levels?.[levelValue]?.ratio)}
					<span style={{ fontSize: fontSize * 0.66 }} >%</span>
				</div>
				<p style={{ fontSize: 14, ...props?.style?.subtitle }}>
					of companies are <GenderScoreLevelLabel
						level={levelValue}
						withPopover={false}
						withUnderline={false}
						withPill={false}
					/>
				</p>

				{memoizedCompanies?.length > 0 && (
					<>
						<p>Similar companies:</p>

						<Space
							size={8}
							direction='vertical'
							style={{ width: '100%' }}
						>
							{
								memoizedCompanies
									?.map((c, index) => (
										<Space
											key={index}
											style={{
												width: '100%',
												justifyContent: 'space-between',
												alignItems: 'center',
												...props?.style?.company,
											}}
										>
											<CompanyStub
												company={c}
												withTooltip={false}
												style={{ width: '100%' }}
											/>
											<Space>
												{withRanking && <GenderScoreMedalBlock
													value={c?.rank || 0}
													fontSize={14}
													height={14}
													gap={4}
													color={theme.dashboardColors.pastelBlue}

												/>}
												<CompanyGenderScoreRingScore
													company={c}
													withPopover={false}
													size={28}
												/>
											</Space>
										</Space>
									))
							}
						</Space>
					</>
				)}
			</GenderScoreLayoutBox>
		</>
	);
};

export default GenderScoreResultsLevelStatsBox;
