import { Space, Tooltip } from 'antd';
import {
	ArrowDownIcon, ArrowRightIcon, ArrowUpIcon, addSignToNum,
} from '../../../../utils/common';
import { getPillarLabel } from '../../../../constants/genderscore-pillars';
import CompanyGenderScoreBadge from '../CompanyGenderScoreBadge';

const GenderScorePillarScoreBadge = ({
	company,
	forceValue,
	pillar,
	withStats = true,
	withLink = false,
	index,
	...props
}) => {
	const className = 'GenderScorePillarScoreBadge';

	const pillarScore = company.genderScore?.[pillar];
	const pillarAvgScore = company.genderScore?.currentStatistics?.averages?.[pillar];
	const avgDiff = pillarScore - pillarAvgScore;
	const avgDiffAbs = Math.abs(avgDiff);
	const avgDiffNorm = avgDiff / (Math.abs(avgDiff) || 1);
	const avgDiffSigned = avgDiff === 0 ? '=' : addSignToNum(avgDiff);
	const avgDiffColor = ['#d4380d', '#ccc', '#389e0d'][avgDiffNorm + 1];
	const avgDiffIcon = [
		<ArrowDownIcon key={0} style={{ marginBottom: -6 }} />,
		<ArrowRightIcon key={1} style={{ marginBottom: -6 }} />,
		<ArrowUpIcon key={2} style={{ marginBottom: -6 }} />,
	][avgDiffNorm + 1];

	const avgDiffSentence = [
		`underperform by ${avgDiffAbs} points`,
		'are on par with',
		`overperform by ${avgDiffAbs} points`,
	][avgDiffNorm + 1];

	const avgPill = (
		<Tooltip
			title={<>
				With your score of <strong>{pillarScore}</strong>,
				you <strong>{avgDiffSentence}</strong> the average
				score of <strong>{pillarAvgScore}</strong> for
				the <strong>{getPillarLabel(pillar)}</strong> pillar
			</>}
		>
			<span
				style={{
					color: avgDiffColor,
					borderRadius: 30,
					userSelect: 'none',
				}}
			>
				{avgDiffSigned}

				{avgDiffIcon}
			</span>
		</Tooltip>
	);

	return (
		<Space
			style={{
				width: '100%',
				justifyContent: 'end',
			}}
		>
			<div
				style={{
					position: 'relative',
					zIndex: 3,
					alignText: 'center',
				}}
			>
				<CompanyGenderScoreBadge
					company={company}
					pillar={pillar}
					version='white'
					withExpiration={false}
					style={{ score: { color: 'rgba(0,0,0,.8)' } }}
				/>
			</div>
			<div
				style={{
					position: 'relative',
					filter: 'saturate(80%) brightness(80%)',
					marginTop: -20,
					marginLeft: -20,
				}}>
				<CompanyGenderScoreBadge
					company={company}
					withPopover={false}
					withExpiration={false}
					size={110}
				/>
			</div>
		</Space>
	);
};

export default GenderScorePillarScoreBadge;
