import slugify from '@sindresorhus/slugify';
import { arrayWrap } from '../../../utils/common';
import { INPUT_MULTIPLE, INPUT_NUMBER, INPUT_PCT } from './questions';
import { QUESTIONS_SUBPATH } from '../../../constants/constant';
import CONSTANTS from './constants';

const className = 'genderscore/data/shared';

export const optionalizeQuestion = (question, ...args) => ({
	...question,
	optional: true,
	...args,
});

export const findQuestionSection = ({
	question,
	sections,
}) => sections?.find((s) => s.questions
	?.find((q) => q.questionId === question?.questionId));

export const getSectionQuestionsFromAnswers = ({
	section,
	answers,
}) => section?.questions
	?.filter((question) => !question?.clause || question?.clause?.(answers));

export const getSurveySectionsQuestionsFromAnswers = ({
	surveyConf,
	answers,
}) => surveyConf?.sections
	?.map((section) => ({
		...section,
		questions: getSectionQuestionsFromAnswers({ section, answers }),
	}));

export const getSurveyAnsweredAndUnansweredQuestions = ({ surveyConf, answers }) => {
	const answered = [];
	const unanswered = [];
	getSurveySectionsQuestionsFromAnswers({ surveyConf, answers })
		?.forEach((section) => {
			section.questions.forEach((question, index) => {
				const state = getQuestionAnsweredState({ question, answers });
				if (![CONSTANTS.ANSWER_STATE_ANSWERED, CONSTANTS.ANSWER_STATE_SKIPPED].includes(state)) {
					unanswered.push({ section, question });
				} else {
					answered.push({ section, question });
				}
			});
		});
	return { answered, unanswered };
};

export const buildSectionQuestionPath = ({
	baseRoutePath,
	section,
	question,
}) => {
	let sSlug;
	let qSlug;

	if (section) {
		sSlug = typeof section === 'string'
			? section
			: slugify(section?.label || section?.value);
	}

	if (question) {
		qSlug = typeof question === 'string'
			? question
			: slugify(question?.shortLabel || question?.label || question?.value);
	}

	const questionPath = [
		baseRoutePath,
		QUESTIONS_SUBPATH,
		sSlug,
		...qSlug
			? [qSlug]
			: [],
	]
		.filter((item) => item)
		.join('/');

	return questionPath;
};

export const isQuestionAnswered = ({
	question,
	answers,
}) => {
	const { questionId, input } = question;

	if (!answers || Object.keys(answers || {})?.length === 0
	) {
		return false;
	}

	const answer = answers?.[questionId];

	if (answer === CONSTANTS.ANSWER_VALUE_SKIPPED) return false;

	const label = convertQuestionAnswerValueToLabel({
		value: answer, question,
	});

	if ([null, undefined].includes(label)) {
		return false;
	}

	if (input === INPUT_MULTIPLE) {
		return arrayWrap(label, [])?.length > 0;
	}

	if ([INPUT_PCT, INPUT_NUMBER].includes(input)) {
		if (label === 0) return true;
	}

	return !!label;
};

export const getQuestionAnsweredState = ({
	question,
	answers,
}) => {
	const { questionId, input } = question;

	if (!answers || Object.keys(answers || {})?.length === 0
	) {
		return CONSTANTS.ANSWER_STATE_NOT_ANSWERED;
	}

	const answer = answers?.[questionId];

	if (answer === CONSTANTS.ANSWER_VALUE_SKIPPED) return CONSTANTS.ANSWER_STATE_SKIPPED;
	if (answer === CONSTANTS.ANSWER_VALUE_LATER) return CONSTANTS.ANSWER_STATE_LATER;

	const label = convertQuestionAnswerValueToLabel({
		value: answer, question,
	});

	if ([null, undefined].includes(label)) {
		return CONSTANTS.ANSWER_STATE_NOT_ANSWERED;
	}

	if (input === INPUT_MULTIPLE) {
		return arrayWrap(label, [])?.length > 0
			? CONSTANTS.ANSWER_STATE_ANSWERED
			: CONSTANTS.ANSWER_STATE_NOT_ANSWERED;
	}

	if ([INPUT_PCT, INPUT_NUMBER].includes(input)) {
		if (label === 0) return CONSTANTS.ANSWER_STATE_ANSWERED;
	}

	return label
		? CONSTANTS.ANSWER_STATE_ANSWERED
		: CONSTANTS.ANSWER_STATE_NOT_ANSWERED;
};

export const getSurveyQuestions = ({ surveyConf, answers }) => surveyConf
	?.sections
	?.map((section) => getSectionQuestionsFromAnswers({ section, answers }))
	?.flat(2) || [];

export const countSurveyQuestions = ({ surveyConf, answers }) => (
	getSurveyQuestions({ surveyConf, answers })?.length
);

export const getSurveyMandatoryQuestions = ({ surveyConf, answers }) => (
	getSurveyQuestions({ surveyConf, answers })?.filter((question) => !question?.optional)
);

export const getSurveyCompletedQuestions = ({ surveyConf, answers, withOptional }) => {
	const questions = withOptional
		? getSurveyQuestions({ surveyConf, answers })
		: getSurveyMandatoryQuestions({ surveyConf, answers });
	return questions
		?.filter((question) => isQuestionAnswered({ question, answers }));
};

export const countSurveyCompletedQuestions = ({ surveyConf, answers, withOptional }) => (
	getSurveyCompletedQuestions({ surveyConf, answers, withOptional })?.length
);

export const getSurveyQuestion = ({ surveyConf }) => surveyConf
	?.sections
	?.map((section) => section?.questions
		?.map((question) => {
			if (!question?.optional) return question;
		}))
	?.flat(2) || [];

export const countSurveyMandatoryQuestions = ({ surveyConf, answers }) => (
	getSurveyMandatoryQuestions({ surveyConf, answers })?.length || 0
);

export const isSurveyCompleted = ({
	surveyConf,
	answers,
}) => {
	const { unanswered } = getSurveyAnsweredAndUnansweredQuestions({ surveyConf, answers });
	return unanswered?.length === 0;
};

export const convertQuestionAnswerValueToLabel = ({
	value,
	question,
}) => {
	const { input, options } = question;

	if (!options || options.length === 0) return value;

	const labels = options
		.filter((o) => (
			arrayWrap(value || [])
				.map((s) => s?.toLowerCase?.())
				.includes(o?.value?.toLowerCase?.())
		))
		.map((o) => o?.label);

	if (input !== INPUT_MULTIPLE) {
		return labels?.[0];
	}

	return labels;
};

const shared = {
	convertQuestionAnswerValueToLabel,
	optionalizeQuestion,
};

export default shared;
