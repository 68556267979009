import GridValueCard from '../../../app/card/GridValueCard';
import { Steps } from 'antd';
import { TAG_STAFF } from '../../../../constants/constant';
import UserResumeManager from '../../widgets/resume/UserResumeManager';
import UserResumeManagerDetails from './UserResumeManagerDetails';

const UserResumeManagerCard = ({
	user,
	onRefresh,
	...props
}) => {
	const title = <>LinkedIn Resume PDF Upload {TAG_STAFF}</>;

	return user ? (
		<GridValueCard
			title={title}
			details={<UserResumeManagerDetails />}
			{...props}
		>
			<UserResumeManager
				user={user}
				onUpload={() => {
					if (onRefresh) onRefresh();
				}}
				onRefresh={() => {
					if (onRefresh) onRefresh();
				}}
			/>
		</GridValueCard>
	) : null;
};

export default UserResumeManagerCard;
