import { arrayToObjectListByProp, sortOn } from '../../utils/common';
import { ACL_ROLE_50_COACH_LABEL, ACL_ROLE_COMPANY_RECRUITER_LABEL } from '../acls';
import {
	WELCOME_FLOW_STEP_D1,
	WELCOME_FLOW_STEP_D2,
	WELCOME_FLOW_STEP_D3,
	WELCOME_FLOW_STEP_D4,
	WELCOME_FLOW_STEP_D5,
} from '../welcomeFlow';

const PLATFORM_RP = 'RP';
const PLATFORM_API = 'API';

export const TEMPLATES_MAP = {
	COACHING_PROPOSAL_V2_FR_DORMANT: {
		label: 'Coaching proposal v2 - for dormant talents',
		lang: 'fr',
		roles: [ACL_ROLE_50_COACH_LABEL],
		platforms: [PLATFORM_RP],
		value: 5092815,
	},
	COACHING_PROPOSAL_V2_FR: {
		label: 'Coaching proposal v2',
		lang: 'fr',
		roles: [ACL_ROLE_50_COACH_LABEL],
		platforms: [PLATFORM_RP],
		value: 4280975,
	},
	COACHING_PROPOSAL_V2_EN: {
		label: 'Coaching proposal v2',
		lang: 'en',
		roles: [ACL_ROLE_50_COACH_LABEL],
		platforms: [PLATFORM_RP],
		value: 4247756,
	},
	COACHING_PROPOSAL_V2_FOLLOWUP_FR: {
		label: 'Coaching proposal v2, Relance',
		lang: 'fr',
		roles: [ACL_ROLE_50_COACH_LABEL],
		platforms: [PLATFORM_RP],
		value: 4280993,
	},
	COACHING_PROPOSAL_V2_FOLLOWUP_EN: {
		label: 'Coaching proposal v2, Follow-up',
		lang: 'en',
		roles: [ACL_ROLE_50_COACH_LABEL],
		platforms: [PLATFORM_RP],
		value: 4248489,
	},
	COACHING_PROPOSAL_V1_FR: {
		label: 'Coaching proposal',
		lang: 'fr',
		roles: [ACL_ROLE_50_COACH_LABEL],
		platforms: [PLATFORM_RP],
		value: 3469839,
	},
	COACHING_PROPOSAL_V1_FOLLOWUP_1_FR: {
		label: 'Coaching proposal (relance #1)',
		lang: 'fr',
		roles: [ACL_ROLE_50_COACH_LABEL],
		platforms: [PLATFORM_RP],
		value: 3690368,
	},
	COACHING_PROPOSAL_V1_FOLLOWUP_2_FR: {
		label: 'Coaching proposal (relance #2)',
		lang: 'fr',
		roles: [ACL_ROLE_50_COACH_LABEL],
		platforms: [PLATFORM_RP],
		value: 3690369,
	},
	COACHING_PROPOSAL_V1_EN: {
		label: 'Coaching proposal',
		lang: 'en',
		roles: [ACL_ROLE_50_COACH_LABEL],
		platforms: [PLATFORM_RP],
		value: 3472560,
	},
	COACHING_PROPOSAL_V1_FOLLOWUP_1_EN: {
		label: 'Coaching proposal (relance #1)',
		lang: 'en',
		roles: [ACL_ROLE_50_COACH_LABEL],
		platforms: [PLATFORM_RP],
		value: 3690364,
	},
	COACHING_PROPOSAL_V1_FOLLOWUP_2_EN: {
		label: 'Coaching proposal (relance #2)',
		lang: 'en',
		roles: [ACL_ROLE_50_COACH_LABEL],
		platforms: [PLATFORM_RP],
		value: 3690365,
	},
	COACHING_CAREER_NEEDS_SENIOR_FR: {
		label: 'Career needs (senior)',
		lang: 'fr',
		roles: [ACL_ROLE_50_COACH_LABEL],
		platforms: [PLATFORM_RP],
		value: 3501371,
	},
	COACHING_CAREER_NEEDS_SENIOR_FOLLOWUP_1_FR: {
		label: 'Career needs (relance #1)',
		lang: 'fr',
		roles: [ACL_ROLE_50_COACH_LABEL],
		platforms: [PLATFORM_RP],
		value: 3690358,
	},
	COACHING_CAREER_NEEDS_SENIOR_FOLLOWUP_2_FR: {
		label: 'Career needs (relance #2)',
		lang: 'fr',
		roles: [ACL_ROLE_50_COACH_LABEL],
		platforms: [PLATFORM_RP],
		value: 3690359,
	},
	COACHING_CAREER_NEEDS_SENIOR_EN: {
		label: 'Career needs (senior)',
		lang: 'en',
		roles: [ACL_ROLE_50_COACH_LABEL],
		platforms: [PLATFORM_RP],
		value: 3501345,
	},
	COACHING_CAREER_NEEDS_SENIOR_FOLLOWUP_1_EN: {
		label: 'Career needs (relance #1)',
		lang: 'en',
		roles: [ACL_ROLE_50_COACH_LABEL],
		platforms: [PLATFORM_RP],
		value: 3690351,
	},
	COACHING_CAREER_NEEDS_SENIOR_FOLLOWUP_2_EN: {
		label: 'Career needs (relance #2)',
		lang: 'en',
		roles: [ACL_ROLE_50_COACH_LABEL],
		platforms: [PLATFORM_RP],
		value: 3690357,
	},
	COACHING_NPS_FR: {
		label: 'Post-coaching NPS',
		lang: 'fr',
		roles: [ACL_ROLE_50_COACH_LABEL],
		platforms: [PLATFORM_RP],
		value: 4238326,
	},
	COACHING_NPS_EN: {
		label: 'Post-coaching NPS',
		lang: 'en',
		roles: [ACL_ROLE_50_COACH_LABEL],
		platforms: [PLATFORM_RP],
		value: 4234136,
	},
	COACHING_NPS_GENDERLENS_FR: {
		label: 'Post-coaching NPS with GenderLens link',
		lang: 'fr',
		roles: [ACL_ROLE_50_COACH_LABEL],
		platforms: [PLATFORM_RP],
		value: 5292911,
	},
	COACHING_NPS_GENDERLENS_EN: {
		label: 'Post-coaching NPS with GenderLens link',
		lang: 'en',
		roles: [ACL_ROLE_50_COACH_LABEL],
		platforms: [PLATFORM_RP],
		value: 5236538,
	},

	ACL_USER_INVITED: {
		label: 'User invited (created)',
		lang: 'en',
		platforms: [PLATFORM_RP],
		value: 3799273,
	},
	ACL_USER_GRANTED: {
		label: 'User granted additional role',
		lang: 'en',
		platforms: [PLATFORM_RP],
		value: 3833858,
	},

	USER_ACCOUNT_COMPLETED_ROLE_COMPANY_RECRUITER: {
		label: 'User activated account',
		lang: 'en',
		platforms: [PLATFORM_RP],
		value: 4488948,
	},

	WELCOME_FLOW_STEP_D1_ROLE_COMPANY_RECRUITER: { value: 4491831, listAsOption: false },
	WELCOME_FLOW_STEP_D2_ROLE_COMPANY_RECRUITER: { value: 4491924, listAsOption: false },
	WELCOME_FLOW_STEP_D3_ROLE_COMPANY_RECRUITER: { value: 4491953, listAsOption: false },
	WELCOME_FLOW_STEP_D4_ROLE_COMPANY_RECRUITER: { value: 4491968, listAsOption: false },
	WELCOME_FLOW_STEP_D5_ROLE_COMPANY_RECRUITER: { value: 4491968, listAsOption: false },

	CONTACT_INVITE: { value: 2264953, listAsOption: false },
	CONTACT_INVITE_ACCEPTED: { value: 2418064, listAsOption: false },
	CONTACT_MESSAGE: { value: 2339283, listAsOption: false },
	CONTACT_UNREAD_CHANNELS: { value: 2418034, listAsOption: false },

	CHAT_NEW_MESSAGE: { value: 2339283, listAsOption: false },

	PUBLICATION_FIRST_PUBLICATION: { value: 1070822, listAsOption: false },
	PUBLICATION_COMMENT_NOTIFICATION: { value: 1070894, listAsOption: false },
	PUBLICATION_REPLY_NOTIFICATION: { value: 1243916, listAsOption: false },
	PUBLICATION_ADMIN_NOTIFICATION: { value: 1203277, listAsOption: false },
	PUBLICATION_ADMIN_FLAG_CONTENT: { value: 1070985, listAsOption: false },

	CONNECT_REQUEST_REQUEST: { value: 1169976, listAsOption: false },
	CONNECT_REQUEST_REQUEST_INVALIDATION: { value: 1300037, listAsOption: false },
	CONNECT_REQUEST_CONFIRMATION: { value: 1169971, listAsOption: false },
	CONNECT_REQUEST_CONFIRMATION_2EMAILS: { value: 3224624, listAsOption: false },
	CONNECT_REQUEST_ADMIN_NOTIFICATION: { value: 1152827, listAsOption: false },
	CONNECT_REQUEST_ADMIN_VALIDATION: { value: 1168900, listAsOption: false },
	CONNECT_REQUEST_REMINDER: { value: 1289810, listAsOption: false },
	CONNECT_REQUEST_INTRODUCTION: { value: 1114133, listAsOption: false },
	CONNECT_REQUEST_INTRODUCTION_2EMAILS: { value: 3224625, listAsOption: false },
	CONNECT_REQUEST_DIGEST: { value: 1175704, listAsOption: false },

	CONNECT_ANSWER_INVALIDATION: { value: 1340428, listAsOption: false },
	CONNECT_ANSWER_ADMIN_NOTIFICATION: { value: 1166813, listAsOption: false },

	RECRUITER_USER_MESSAGE_DEFAULT: {
		label: 'Default recruiter message (raw)',
		platforms: [PLATFORM_RP],
		value: 3802824,
	},
	RECRUITER_USER_MESSAGE_FIRST_APPROACH: {
		label: 'First approach (deprecated)',
		lang: 'en',
		platforms: [PLATFORM_RP],
		value: 4638602,
	},
	RECRUITER_USER_MESSAGE_FIRST_APPROACH_ANSWER: {
		label: 'First approach (with answer)',
		lang: 'en',
		platforms: [PLATFORM_RP],
		value: 4718988,
	},

	ANSWER_MESSAGE_RECRUITER_ACCEPTED_NOTIFICATION: {
		label: 'First approach accepted, recruiter notification',
		lang: 'en',
		platforms: [PLATFORM_API],
		value: 4769290,
	},
	ANSWER_MESSAGE_CANDIDATE_ACCEPTED_NOTIFICATION: {
		label: 'First approach accepted, talent notification',
		lang: 'en',
		platforms: [PLATFORM_API],
		value: 4774745,
	},
	ANSWER_MESSAGE_RECRUITER_DECLINED_NOTIFICATION: {
		label: 'First approach declined, recruiter notification',
		lang: 'en',
		platforms: [PLATFORM_API],
		value: 4769303,
	},

	USER_INVITE: { value: 1576860, listAsOption: false },
	USER_INVITE_JOIN_THE_MOVEMENT: { value: 1576860, listAsOption: false },
	USER_INVITE_DRIVE_THE_CHANGE: { value: 1587491, listAsOption: false },
	USER_INVITE_START_CONNECTING: { value: 1587531, listAsOption: false },
	USER_INVITE_BOOST_MY_CAREER: { value: 1587534, listAsOption: false },

	USER_REGISTER_ADMIN_NOTIFICATION: { value: 1031453, listAsOption: false },
	USER_REGISTER_WELCOME_LEGACY: { value: 1646335, listAsOption: false },
	USER_REGISTER_WELCOME: { value: 3247824, listAsOption: false },

	USER_PROFILE_ADMIN_NOTIFICATION: { value: 1070564, listAsOption: false },

	FIND_A_MATCH_CONFIRMATION: { value: 1112250, listAsOption: false },
	FIND_A_MATCH_ADMIN_NOTIFICATION: { value: 1070936, listAsOption: false },

	COMMUNITY_PUBLICATION_REQUEST: { value: 1541766, listAsOption: false },
	COMMUNITY_USER_INVITE: { value: 1507339, listAsOption: false },

	COMPANY_PUBLICATION_REQUEST: { value: 1541766, listAsOption: false },
	COMPANY_USER_INVITE: { value: 1507339, listAsOption: false },
	COMPANY_USER_MESSAGE: { value: 3802824, listAsOption: false },

	JOB_APPLY: {
		label: 'Job application notification',
		lang: 'en',
		platforms: [PLATFORM_API],
		value: 3078354,
	},
	JOB_APPLY_NOTIFY_ONLY_MANAGERS_LEGACY: {
		label: 'Job application notif, only to managers (legacy)',
		lang: 'en',
		platforms: [PLATFORM_API],
		value: 4063634,
		listAsOption: false,
	},
	JOB_APPLY_NOTIFY_DEFAULT_RECIPIENT: {
		label: 'Job application notif, only to default recipient',
		lang: 'en',
		platforms: [PLATFORM_API],
		value: 4063634,
	},
	JOB_APPLY_NOTIFY_ONLY_MANAGERS: {
		label: 'Job application notif, only to managers',
		lang: 'en',
		platforms: [PLATFORM_API],
		value: 10190236,
	},
	JOB_APPLY_NOTIFY_ALL_RECRUITERS: {
		label: 'Job application notif, to all recruiters (no recipient or default)',
		lang: 'en',
		platforms: [PLATFORM_API],
		value: 10636107,
	},
	JOB_APPLY_NOTIFY_CLIENT_ROLES: {
		label: 'Job application notif, client roles',
		lang: 'en',
		platforms: [PLATFORM_API],
		value: 4522574,
	},
	JOB_APPLY_CONFIRMATION: {
		label: 'Job application notif, confirmation to candidate',
		lang: 'en',
		platforms: [PLATFORM_API],
		value: 3202226,
	},
	JOB_COACHING_JOB_CONFIRMATION: { value: 3203043, listAsOption: false },

	WIRRW_BOOTCAMP_WELCOME: { value: 1876896, listAsOption: false },
	WIRRW_BOOTCAMP_PROGRAM_WEEK_1: { value: 1894546, listAsOption: false },
	WIRRW_BOOTCAMP_EVENT_REGISTER: { value: 1894846, listAsOption: false },
	WBCTO_BOOTCAMP_WELCOME: { value: 1876896, listAsOption: false },
	WBCTO_BOOTCAMP_PROGRAM_WEEK_1: { value: 1894546, listAsOption: false },
	WBCTO_BOOTCAMP_EVENT_REGISTER: { value: 1894846, listAsOption: false },
	BOOTCAMP_WIRRW_REGISTERED: { value: 3307980, listAsOption: false },
	BOOTCAMP_WIRRW_LOGGED: { value: 3310742, listAsOption: false },
	BOOTCAMP_WBCPO_REGISTERED: { value: 3482777, listAsOption: false },
	BOOTCAMP_WBCPO_LOGGED: { value: 3482778, listAsOption: false },
	BOOTCAMP_CYBER_REGISTERED: { value: 3757867, listAsOption: false },
	BOOTCAMP_CYBER_LOGGED: { value: 3757870, listAsOption: false },
	BOOTCAMP_PRIDE_REGISTERED: { value: 3962328, listAsOption: false },
	BOOTCAMP_PRIDE_LOGGED: { value: 3962326, listAsOption: false },
	BOOTCAMP_SUMMIT_REGISTERED: { value: 4009317, listAsOption: false },
	BOOTCAMP_SUMMIT_LOGGED: { value: 4009318, listAsOption: false },
	BOOTCAMP_DATA22_REGISTERED: { value: 4146085, listAsOption: false },
	BOOTCAMP_DATA22_LOGGED: { value: 4146088, listAsOption: false },
	BOOTCAMP_WBCTO_REGISTERED: { value: 2448245, listAsOption: false },
	BOOTCAMP_WBCTO_MENTORSHIP: { value: 2448281, listAsOption: false },
	BOOTCAMP_WBCTO_JOBFAIR: { value: 2448305, listAsOption: false },
};

export const TEMPLATES_USER_ACCOUNT_COMPLETED_ROLE_IDS = {
	[ACL_ROLE_COMPANY_RECRUITER_LABEL]:
		TEMPLATES_MAP.USER_ACCOUNT_COMPLETED_ROLE_COMPANY_RECRUITER.value,
};

export const TEMPLATES_WELCOME_FLOW_STEPS_BY_ROLE_IDS = {
	[WELCOME_FLOW_STEP_D1]: {
		[ACL_ROLE_COMPANY_RECRUITER_LABEL]:
			TEMPLATES_MAP.WELCOME_FLOW_STEP_D1_ROLE_COMPANY_RECRUITER.value,
	},
	[WELCOME_FLOW_STEP_D2]: {
		[ACL_ROLE_COMPANY_RECRUITER_LABEL]:
			TEMPLATES_MAP.WELCOME_FLOW_STEP_D2_ROLE_COMPANY_RECRUITER.value,
	},
	[WELCOME_FLOW_STEP_D3]: {
		[ACL_ROLE_COMPANY_RECRUITER_LABEL]:
			TEMPLATES_MAP.WELCOME_FLOW_STEP_D3_ROLE_COMPANY_RECRUITER.value,
	},
	[WELCOME_FLOW_STEP_D4]: {
		[ACL_ROLE_COMPANY_RECRUITER_LABEL]:
			TEMPLATES_MAP.WELCOME_FLOW_STEP_D4_ROLE_COMPANY_RECRUITER.value,
	},
	[WELCOME_FLOW_STEP_D5]: {
		[ACL_ROLE_COMPANY_RECRUITER_LABEL]:
			TEMPLATES_MAP.WELCOME_FLOW_STEP_D5_ROLE_COMPANY_RECRUITER.value,
	},
};

export const TEMPLATE_BOOTCAMP_IDS = {
	'really-really-want-bootcamp': {
		bootcamp: {
			registered: TEMPLATES_MAP.BOOTCAMP_WIRRW_REGISTERED.value,
			logged: TEMPLATES_MAP.BOOTCAMP_WIRRW_LOGGED.value,
		},
	},
	'wannabe-a-cpo-bootcamp': {
		bootcamp: {
			registered: TEMPLATES_MAP.BOOTCAMP_WBCPO_REGISTERED.value,
			logged: TEMPLATES_MAP.BOOTCAMP_WBCPO_LOGGED.value,
		},
	},
	'women-in-cyber-cloud-bootcamp': {
		bootcamp: {
			registered: TEMPLATES_MAP.BOOTCAMP_CYBER_REGISTERED.value,
			logged: TEMPLATES_MAP.BOOTCAMP_CYBER_LOGGED.value,
		},
	},
	'pride-bootcamp': {
		bootcamp: {
			registered: TEMPLATES_MAP.BOOTCAMP_PRIDE_REGISTERED.value,
			logged: TEMPLATES_MAP.BOOTCAMP_PRIDE_LOGGED.value,
		},
	},
	'summit-bootcamp': {
		bootcamp: {
			registered: TEMPLATES_MAP.BOOTCAMP_SUMMIT_REGISTERED.value,
			logged: TEMPLATES_MAP.BOOTCAMP_SUMMIT_LOGGED.value,
		},
	},
	'data22-bootcamp': {
		bootcamp: {
			registered: TEMPLATES_MAP.BOOTCAMP_DATA22_REGISTERED.value,
			logged: TEMPLATES_MAP.BOOTCAMP_DATA22_LOGGED.value,
		},
	},
	'wannabe-a-cto-bootcamp': {
		bootcamp: {
			registered: TEMPLATES_MAP.BOOTCAMP_WBCTO_REGISTERED.value,
		},
		mentorship: {
			registered: TEMPLATES_MAP.BOOTCAMP_WBCTO_MENTORSHIP.value,
		},
		jobfair: {
			registered: TEMPLATES_MAP.BOOTCAMP_WBCTO_JOBFAIR.value,
		},
	},
};

export const TEMPLATE_USER_INVITE_IDS = [
	TEMPLATES_MAP.USER_INVITE_JOIN_THE_MOVEMENT.value,
	TEMPLATES_MAP.USER_INVITE_DRIVE_THE_CHANGE.value,
	TEMPLATES_MAP.USER_INVITE_START_CONNECTING.value,
	TEMPLATES_MAP.USER_INVITE_BOOST_MY_CAREER.value,
];

export const TEMPLATES_LIST = Object
	.entries(
		arrayToObjectListByProp(
			Object
				.entries(TEMPLATES_MAP)
				.map(([key, value]) => ({ ...value, label: value?.label || key })),
			'value',
		),
	)
	.map(([key, [option]]) => option)
	.filter((option) => option.listAsOption !== false)
	.sort(sortOn({ key: 'label' }));

export const findTemplateDataByTemplateId = (mailjetTemplateId) => Object
	.entries(TEMPLATES_MAP)
	.map(([templateKey, templateData]) => ({ templateKey, label: templateKey, ...templateData }))
	.find((templateData) => parseInt(templateData.value, 10) === parseInt(mailjetTemplateId, 10));
