import { useTheme } from '@emotion/react';
import { useState } from 'react';
import Table from '../app/table/Table';
import { useAuthContext } from '../../hooks/useAuthContext';
import TableColumns from '../app/table/TableColumns';
import { Button, message, Space } from 'antd';
import AclAddPopover from '../acls/AclAddPopover';
import AclService from '../../services/acl';
import { add } from '../../actions/acls';
import { useDispatch } from 'react-redux';
import { documentId } from '../../utils/common';

const AtsMemberTable = (
	{
		companyAccess,
		isRefresh = 0,
		...props
	},
) => {
	const theme = useTheme();
	const dispatch = useDispatch();

	const { isStaffView } = useAuthContext();

	const [isRefreshDataLayout, setIsRefreshDataLayout] = useState(0);
	const [openAddAcl, setOpenAddAcl] = useState(false);
	const [initAclValuesForm, setInitAclValuesForm] = useState({});

	const handleInviteAtsMember = async (atsMember) => {
		setInitAclValuesForm({ email: atsMember.email, autoValidateEmail: true });
		setOpenAddAcl(true);
	};

	const handleSubmit = async (values) => {
		const { roleId, userId, documentId: companyId } = values;
		const { data: { isAclExist } } = await AclService.aclExists(roleId, { userId, companyId });

		if (isAclExist) {
			message.error('This access already exists for this user');
			return;
		}

		dispatch(add(values));
		setOpenAddAcl(false);
		// setOpenEditAcl(false);
	};

	const handleCloseWithChange = () => {
		setOpenAddAcl(false);
		refreshTable();
	};

	const handleResetForm = () => {
		setOpenAddAcl(false);
	};

	const refreshTable = () => {
		setIsRefreshDataLayout((prev) => prev + 1);
	};

	const atsMemberCol = {
		title: 'ATS Member',
		dataIndex: 'email',
		render: (value, row) => {
			return <Space direction='vertical' size={0}>
				<b>{row.name}</b>
				<div>{row.email}</div>
			</Space>;
		},
		...TableColumns.columnMinWidth(180),
	};
	const atsInfoCol = {
		title: 'ATS Role',
		dataIndex: 'accessRole',
		render: (value, row) => {
			return <Space direction='vertical' size={0}>
				{row.accessRole ? <b>{row.accessRole}</b> : <i>No role specified</i>}
				{row.createdAt && <span
					style={{ color: theme.color.darkgrey }}>Created on {TableColumns.dateColumn(theme).render(new Date(row.createdAt))}</span>}
			</Space>;
		},
		...TableColumns.columnMinWidth(160),
	};
	const userCol = TableColumns.userStubColumn(theme, {
		title: '50inTech user',
		sorter: false,
		...TableColumns.columnMinWidth(260),
	});

	const adminRoleCol = {
		title: 'Admin',
		dataIndex: 'acls',
		align: 'center',
		render: (value, row) => {
			const [role] = value?.filter((r) => r.label === 'document-admin') || [];
			if (role) {
				return TableColumns.userRoleLabelColumn(theme).render(role);
			}
			return <Button>Invite as ADMIN</Button>;
		},
	};

	const editorRoleCol = {
		title: 'Editor',
		dataIndex: 'acls',
		align: 'center',
		render: (value, row) => {
			const [role] = value?.filter((r) => r.label === 'document-editor') || [];
			if (role) {
				return TableColumns.userRoleLabelColumn(theme).render(role);
			}
			return <Button>Invite as EDITOR</Button>;
		},
	};

	const recruiterRoleCol = {
		title: 'Recruiter',
		dataIndex: 'acls',
		align: 'center',
		render: (value, row) => {
			const [role] = value?.filter((r) => r.label === 'company-recruiter') || [];
			if (role) {
				return TableColumns.userRoleLabelColumn(theme).render(role);
			}
			return <Button>Invite as RECRUITER</Button>;
		},
	};

	const rolesCol = {
		title: '50inTech Roles',
		dataIndex: 'acls',
		render: (value, row) => {
			const rr = TableColumns.userRoleLabelColumn(theme).render;
			const labels = ['document-admin', 'document-editor', 'company-recruiter'];
			const roleTags = labels.map((label) => {
				const [role] = value?.filter((r) => r.label === label) || [];
				return role && rr(role);
			}).filter((item) => item);

			if (!roleTags?.length) {
				return <i>No role defined yet</i>;
				// return <Button>Invite</Button>
			}
			return <Space direction='horizontal' size={0}>
				{roleTags.map((r, i) => <div key={i}>{r}</div>)}
			</Space>;
		},
		...TableColumns.columnMinWidth(260),
	};

	const actionsCol = {
		title: 'Actions',
		render: (value, row) => {
			const button = row?.acls?.length
				? <Button onClick={() => { handleInviteAtsMember(row); }}>Add a role</Button>
				: <Button type='primary' onClick={() => { handleInviteAtsMember(row); }}>Invite</Button>;

			return button;
		},
		...TableColumns.columnMinWidth(140),
	};

	const columns = isStaffView
		? [
			TableColumns.jsonColumn(theme),
			{ title: 'ID', dataIndex: 'id' },
			atsMemberCol,
			atsInfoCol,
			userCol,
			rolesCol,
			actionsCol,
		]
		: [
			atsMemberCol,
			atsInfoCol,
			userCol,
			rolesCol,
			actionsCol,
		];

	return (companyAccess
		&& <>
			<Table
				path={`access/${documentId(companyAccess)}/ats-users`}
				columns={columns}
				isRefresh={isRefresh + isRefreshDataLayout}
				withPagination={false}
				{...props}
			/>

			<AclAddPopover
				open={openAddAcl}
				initialValues={initAclValuesForm}
				onSubmit={handleSubmit}
				onReset={handleResetForm}
				onCloseWithRefresh={handleCloseWithChange}
			/>

		</>
	);
};

export default AtsMemberTable;
