import { mixColors } from '../../../../../utils/common';
import { RISK_OPTIONS, RISK_PERFORMANCE_EVALUATION } from '../../../data/biases';
import {
	bold,
	regular,
	PDF_PAGE_TEMPLATE_CONTENT,
	PDF_TYPE_TEXT,
	PDF_TEXT_STYLES,
	PDF_TYPE_RECT,
} from '../base/PDFBuilder';

const PageRiskFocus = ({ risk, doc, context }) => {
	return (
		{
			template: PDF_PAGE_TEMPLATE_CONTENT,
			title: {
				en: `Risk for\n${risk.label}`,
				fr: `Risque pour\n${risk.label}`,
			},
			footer: { color: mixColors('#ffffff', '#f7506e', 20) },
			pageCount: { color: '#ffffff' },
			items: [
				{
					type: PDF_TYPE_TEXT,
					text: risk?.impact || 'Impact',
					...regular(PDF_TEXT_STYLES.normal),
				},
				{
					type: PDF_TYPE_RECT,
					x: 0,
					// width: doc.pdfWidth - doc.n(doc.pagePadding?.left) - doc.n(doc.pagePadding?.right),
					width: doc.pdfWidth,
					height: doc.pdfHeight,
					marginTop: 10,
					fill: '#f7506e',
					position: 'absolute',
				},
				{
					type: PDF_TYPE_TEXT,
					text: 'Solutions',
					...bold(PDF_TEXT_STYLES.normal),
					fontSize: 18,
					marginTop: 10,
					// padding: { left: 10, right: 10 },
					color: '#ffffff',
				},
				...risk.solutions?.[context?.appLang || 'en']?.split?.('\n')?.map?.((line) => ({
					type: PDF_TYPE_TEXT,
					...regular(PDF_TEXT_STYLES.normal),
					text: line || 'line',
					// padding: { left: 10, right: 10 },
					color: '#ffffff',
				})) || [],
			],
		}
	);
};

const PageRiskFocusList = ({ doc, context }) => {
	const risks = RISK_OPTIONS;

	return risks.map((risk) => PageRiskFocus({ risk, doc, context }));
};

export default PageRiskFocusList;
