import {
	Button, Checkbox, Col, Divider, Form, Input, Row, Select, Switch,
} from 'antd';
// import FormItem from '../../app/form/FormItem'
import { FormItemContainer } from '../../app/form/FormItem';
import FormItem from '../../company/form/FormItem';
import { useEffect, useState } from 'react';

import CKEditor from '../../app/form/CkEditor';
import FormSelect from '../../app/form/FormSelect';
import { useAuthContext } from '../../../hooks/useAuthContext';
import { useTheme } from '@emotion/react';
import { EMAIL_TEMPLATES } from '../../../constants/constant';
import AddVariablesDropdown from './AddVariablesDropdown';
import CheckboxTable from '../../app/table/CheckboxTable';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { LocalDebug } from '../../../utils/LocalDebug';
import {
	FaBan, FaCheck, FaChevronLeft, FaStar,
} from 'react-icons/fa';
import { ckEditorReadyMinHeight, documentId, requiredRule, requiredTrimRule } from '../../../utils/common';

const MessageTemplateForm = (
	{
		initialValues = {},
		onSubmit,
		onReset,
		isWithStartStep,
		setStep,
	},
) => {
	const className = 'MessageTemplateForm';

	const theme = useTheme();

	const {
		isAdmin, isStaff, isStaffView, isMasterTemplatesEnabled,
	} = useAuthContext();

	const [form] = Form.useForm();
	const [isMasterTemplate, setIsMasterTemplate] = useState(false);
	const [cursorPositions, setCursorPositions] = useState({});

	LocalDebug.logInfo({ className, method: 'constructor' }, { initialValues });

	const handleSubmit = (values) => {
		const toDb = {
			...initialValues?.masterTemplateId ? { masterTemplateId: initialValues?.masterTemplateId } : {},
			companyId: initialValues?.companyId,
			userId: initialValues?.userId,
			...values,
			id: documentId(initialValues),
			isMasterTemplate,
		};
		if (onSubmit) onSubmit(toDb);
	};

	const addVariables = (variable, field) => {
		const currentValue = form.getFieldValue(field) || '';
		const cursor = cursorPositions?.[field]?.[0] === 0
			? 0
			: cursorPositions?.[field]?.[0] || currentValue.length;
		const newValue = `${
			currentValue.slice(0, cursor)
		}${
			variable
		}${
			currentValue.slice(cursor)
		}`;
		LocalDebug.logInfo({ className, method: 'addVariables' }, {
			variable, field, cursor, currentValue, newValue,
		});
		form.setFieldsValue({ [field]: newValue });
	};

	useEffect(() => {
		setIsMasterTemplate(initialValues.isMasterTemplate || false);
	}, [initialValues]);

	const handleKeyDown = (e, source) => {
		setCursorPositions((p) => ({ ...p, [source]: [e?.target?.selectionStart, e?.target?.selectionEnd] }));
	};

	return (
		<Form
			form={form}
			labelCol={{ span: 4 }}
			name="template-mail-form"
			initialValues={{ ...initialValues }}
			onFinish={handleSubmit}
			style={{
				position: 'relative',
				height: '100%',
				// overflow: 'auto',
			}}
		>
			<FormItemContainer
				style={{
					position: 'relative',
					height: 'calc(100% - 80px)',
					overflow: 'auto',
					paddingBottom: 32,
				}}
			>
				{isStaff && isStaffView && isMasterTemplatesEnabled && (
					<FormItem label="Master template" name="isMasterTemplate" defaultContainer="div">
						<Switch className='ant-switch-large'
							checkedChildren={<FaStar style={{ fontSize: 18, marginLeft: -4, marginBottom: -5 }}/>}
							unCheckedChildren={<FaBan style={{ fontSize: 18, marginRight: -4, marginBottom: -5 }}/>}
							onClick={() => setIsMasterTemplate((p) => !p)}
							checked={isMasterTemplate}/>
					</FormItem>
				)}

				{/* {isAdmin && <>isMasterTemplatesEnabled: {isMasterTemplatesEnabled? 'true': 'false'}</>} */}
				{isMasterTemplatesEnabled && <FormItem
					label="Category"
					name="category"
					defaultContainer="div"
					rules={[requiredRule]}
				>
					<FormSelect
						options={EMAIL_TEMPLATES.CATEGORY_LIST}
						styles={{ maxWidth: 300 }}
						render={(option) => (
							<Select.Option
								key={option.value}
								label={option.label}
								value={option.value}
							>
								{option.label}
							</Select.Option>
						)}
					/>
				</FormItem>}

				<FormItem
					label="Template name"
					name="name"
					rules={[requiredTrimRule]}
				>
					<Input
						style={{ maxWidth: 641 }}
					/>
				</FormItem>

				<FormItem label='Email subject' required={true}>
					<Form.Item
						name="subject"
						rules={[requiredTrimRule]}
						noStyle
					>
						<Input
							onKeyDown={(e) => setTimeout(() => handleKeyDown(e, 'subject'))}
						/>
					</Form.Item>
					<AddVariablesDropdown
						onSelected={(item) => addVariables(item, 'subject')}
						style={{ marginLeft: 6 }}
					/>
				</FormItem>

				<FormItem label='Email body' required={true} style={{ marginBottom: 40 }}>
					<FormItem
						name="content"
						rules={[requiredTrimRule]}
						style={{ width: '100%', maxWidth: '641px' }}
						// noStyle
					>
						<CKEditor
							config={{
								toolbar: ['bold', 'italic', 'link', 'bulletedList', 'numberedList', 'blockQuote'],
							}}
							style={{ width: '100%', maxWidth: 726 }}
							onReady={ckEditorReadyMinHeight(200)}
							onKeyDown={(e) => setTimeout(() => handleKeyDown(e, 'content'))}
						/>
					</FormItem>
					<AddVariablesDropdown
						onSelected={(item) => addVariables(item, 'content')}
						style={{ marginLeft: 6 }}
					/>
				</FormItem>
			</FormItemContainer>

			<Row style={{
				position: 'absolute',
				bottom: '0',
				margin: '48px -24px -24px',
				width: 'calc(100% + 48px)',
				borderTop: '1px solid #eee',
				padding: 24,
				background: theme.color.bgGrey,
				// border: '1px solid red',
			}}>
				<Col span={24}>
					<div
						style={{
							display: 'flex',
							justifyContent: 'space-between',
							margin: -5,
							width: 'calc(100% + 10px)',
						}}
					>
						<Button
							type="default"
							danger
							ghost
							style={{
								margin: 5,
								// borderColor: theme.color.orange,
								fontWeight: 'bold',
								borderRadius: 5,
								height: 42,
								// color: theme.color.orange,
								...!isWithStartStep ? { opacity: 0 } : {},
							}}
							htmlType="reset"
							onClick={() => (isWithStartStep && isMasterTemplatesEnabled ? setStep(0) : onReset())}
						>
							<FaChevronLeft style={{ marginBottom: -2 }}/>&nbsp;
							{/* Back to selection */}
							{isWithStartStep && isMasterTemplatesEnabled ? 'Back to selection' : 'Cancel'}
						</Button>
						<Button
							type="primary"
							style={{
								margin: 5,
								// background: theme.color.blue,
								// borderColor: theme.color.blue,
								fontWeight: 'bold',
								borderRadius: 5,
								height: 42,
							}}
							htmlType="submit"
						>
							<FaCheck style={{ marginBottom: -2 }}/>&nbsp;Save my template
						</Button>
					</div>
				</Col>
			</Row>
		</Form>
	);
};

export default MessageTemplateForm;
