import { MailOutlined } from '@ant-design/icons';
import MessagesPage from '../../containers/root/MessagesPage';
import { PERMS_GRANT_NO_EDITOR } from '../menu.utils';
import MenuBase from '../MenuBase';

export const GenderhireMessagesIcon = MailOutlined;

class GenderhireMessagesMenu extends MenuBase {
	static props = {
		name: 'messages',
		path: '/messages',
		label: 'Messages',
		icon: <GenderhireMessagesIcon />,
		iconLegacy: '💌',
		Component: MessagesPage,
		perms: PERMS_GRANT_NO_EDITOR,
	};

	constructor() {
		super(GenderhireMessagesMenu.props);
	}
}

export default GenderhireMessagesMenu;
