import QUESTIONS from '../../questions';
import RANGES from '../../ranges';
import SCORING from '../../scoring';
import { GENDERSCORE_SURVEY_1TO49_V2_OPTION } from '../../../../../constants/property/genderscore-survey';
import sectionGenderData from './section-genderdata';
import sectionCareerPath from './section-careerpath';
import sectionEqualPay from './section-equalpay';
import sectionWorklifeBalance from './section-worklifebalance';
import sectionDeiActions from './section-deiactions';

export const GENDERSCORE_SURVEY_1TO49_V2_DATA_OPTION = {
	...GENDERSCORE_SURVEY_1TO49_V2_OPTION || {},
	scoring: {
		[QUESTIONS.GENDERDATA_OVERALLPCT.questionId]: SCORING.buildScoringPct({
			ranges: RANGES.GENDERDATA_OVERALLPCT_RANGES,
		}),
		[QUESTIONS.GENDERDATA_TECHPCT.questionId]: SCORING.buildScoringPct({
			ranges: RANGES.GENDERDATA_TECHPCT_RANGES,
			defaultScore: 50,
		}),
		[QUESTIONS.GENDERDATA_MGTPCT.questionId]: SCORING.buildScoringPct({
			ranges: RANGES.GENDERDATA_MGTPCT_RANGES,
		}),
		[QUESTIONS.GENDERDATA_CLEVELPCT.questionId]: SCORING.buildScoringPct({
			ranges: RANGES.GENDERDATA_CLEVELPCT_RANGES,
		}),
		[QUESTIONS.GENDERDATA_BOARDPCT.questionId]: SCORING.buildScoringPct({
			ranges: RANGES.GENDERDATA_BOARDPCT_RANGES,
		}),
		[QUESTIONS.GENDERDATA_RATING.questionId]: SCORING.buildScoringPct({
			ranges: RANGES.GENDERDATA_RATING_RANGES,
		}),
		[QUESTIONS.GENDERDATA_ISTURNOVER.questionId]: SCORING.buildScoringValue(),
		//
		[QUESTIONS.CAREERPATH_GRIDS.questionId]: SCORING.buildScoringValue(),
		[QUESTIONS.CAREERPATH_SKILLSMATRIX.questionId]: SCORING.buildScoringValue(),
		[QUESTIONS.CAREERPATH_CAREERPATHING.questionId]: SCORING.buildScoringValue(),
		[QUESTIONS.CAREERPATH_BUDDY.questionId]: SCORING.buildScoringValue(),
		//
		[QUESTIONS.EQUALPAY_EQUALPAY.questionId]: SCORING.buildScoringValue(),
		[QUESTIONS.EQUALPAY_SALARYGRID.questionId]: SCORING.buildScoringValue(),
		[QUESTIONS.EQUALPAY_STOCKOPTIONS.questionId]: SCORING.buildScoringValue(),
		[QUESTIONS.EQUALPAY_SALARYREVIEW.questionId]: SCORING.buildScoringValue(),
		[QUESTIONS.EQUALPAY_MATERNITYRAISE.questionId]: SCORING.buildScoringValue(),
		[QUESTIONS.EQUALPAY_SALARYINCREASEPCT.questionId]: SCORING.buildScoringPct({ ranges: RANGES.SALARYINCREASEPCT_RANGES }),
		[QUESTIONS.EQUALPAY_PAYGAPDATA.questionId]: SCORING.buildScoringValue(),
		[QUESTIONS.EQUALPAY_INEQUITYMETHOD.questionId]: SCORING.buildScoringValue(),
		//
		[QUESTIONS.WORKLIFE_REMOTE.questionId]: SCORING.buildScoringValue(),
		[QUESTIONS.WORKLIFE_FLEXIBLEHOURS.questionId]: SCORING.buildScoringValue(),
		[QUESTIONS.WORKLIFE_FLEXIBLEHOLIDAYS.questionId]: SCORING.buildScoringValue(),
		[QUESTIONS.WORKLIFE_UNLIMITEDHOLIDAYS.questionId]: SCORING.buildScoringValue(),
		[QUESTIONS.WORKLIFE_PARENTALPAIDLEAVE.questionId]: SCORING.buildScoringValue(),
		[QUESTIONS.WORKLIFE_EXTRABENEFITS.questionId]: SCORING.buildScoringValue({ ranges: 100 / 13 }),
		//
		[QUESTIONS.DEI_ANTIHARASSMENT.questionId]: SCORING.buildScoringValue(),
		[QUESTIONS.DEI_ANTIDISCRIMINATION.questionId]: SCORING.buildScoringValue(),
		[QUESTIONS.DEI_COMPLAINTSPROCEDURE.questionId]: SCORING.buildScoringValue(),
		[QUESTIONS.DEI_RESPONSIBILITY.questionId]: SCORING.buildScoringValue({ ranges: 100 / 3 }),
		[QUESTIONS.DEI_SUPPORTDIALOGUE.questionId]: SCORING.buildScoringValue(),
		[QUESTIONS.DEI_UNBIASEDRECRUITMENT.questionId]: SCORING.buildScoringValue({ ranges: 100 / 13 }),
		[QUESTIONS.DEI_LEARNINGCOMMITMENT.questionId]: SCORING.buildScoringValue({ ranges: 100 / 14 }),
		[QUESTIONS.DEI_APPOINTEDINDIVIDUAL.questionId]: SCORING.buildScoringValue(),
		[QUESTIONS.DEI_MENTALHEALTH.questionId]: SCORING.buildScoringValue({ ranges: 100 / 10 }),
		[QUESTIONS.DEI_JUNIORHIRES.questionId]: SCORING.buildScoringValue(),
		[QUESTIONS.DEI_CAREERCHANGEHIRES.questionId]: SCORING.buildScoringValue(),
	},
	sections: [
		{
			index: 1,
			...sectionGenderData,
			weighings: {
				[QUESTIONS.GENDERDATA_OVERALLPCT.questionId]: 50,
				[QUESTIONS.GENDERDATA_TECHPCT.questionId]: 30,
				[QUESTIONS.GENDERDATA_MGTPCT.questionId]: 30,
				[QUESTIONS.GENDERDATA_CLEVELPCT.questionId]: 30,
				[QUESTIONS.GENDERDATA_BOARDPCT.questionId]: 10,
				[QUESTIONS.GENDERDATA_RATING.questionId]: 5,
				[QUESTIONS.GENDERDATA_ISTURNOVER.questionId]: 10,
			},
		},
		{
			index: 2,
			...sectionCareerPath,
			weighings: {
				[QUESTIONS.GENDERDATA_RATING.questionId]: 10,
				[QUESTIONS.CAREERPATH_GRIDS.questionId]: 16,
				[QUESTIONS.CAREERPATH_SKILLSMATRIX.questionId]: 16,
				[QUESTIONS.CAREERPATH_CAREERPATHING.questionId]: 5,
				[QUESTIONS.CAREERPATH_BUDDY.questionId]: 1,
				[QUESTIONS.DEI_JUNIORHIRES.questionId]: 2,
				[QUESTIONS.DEI_CAREERCHANGEHIRES.questionId]: 2,
			},
		},
		{
			index: 3,
			...sectionEqualPay,
			weighings: {
				[QUESTIONS.EQUALPAY_EQUALPAY.questionId]: 20,
				[QUESTIONS.EQUALPAY_SALARYGRID.questionId]: 10,
				[QUESTIONS.EQUALPAY_STOCKOPTIONS.questionId]: 5,
				[QUESTIONS.EQUALPAY_SALARYREVIEW.questionId]: 8,
				[QUESTIONS.EQUALPAY_MATERNITYRAISE.questionId]: 5,
				[QUESTIONS.EQUALPAY_SALARYINCREASEPCT.questionId]: 5,
				[QUESTIONS.EQUALPAY_PAYGAPDATA.questionId]: (answers) => {
					// this answer should be used in the score ONLY IF
					// EQUALPAY_EQUALPAY answer IS 'no'(or value 0)
					return [0, 'no'].includes(answers?.[QUESTIONS.EQUALPAY_EQUALPAY.questionId])
						? 5
						: 0;
				},
				[QUESTIONS.EQUALPAY_INEQUITYMETHOD.questionId]: 7,
			},
		},
		{
			index: 4,
			...sectionWorklifeBalance,
			weighings: {
				[QUESTIONS.WORKLIFE_REMOTE.questionId]: 10,
				[QUESTIONS.WORKLIFE_FLEXIBLEHOURS.questionId]: 8,
				[QUESTIONS.WORKLIFE_FLEXIBLEHOLIDAYS.questionId]: 5,
				[QUESTIONS.WORKLIFE_UNLIMITEDHOLIDAYS.questionId]: 1,
				[QUESTIONS.WORKLIFE_PARENTALPAIDLEAVE.questionId]: 6,
				[QUESTIONS.WORKLIFE_EXTRABENEFITS.questionId]: 10,
			},
		},
		{
			index: 5,
			...sectionDeiActions,
			weighings: {
				[QUESTIONS.DEI_ANTIHARASSMENT.questionId]: 20,
				[QUESTIONS.DEI_ANTIDISCRIMINATION.questionId]: 30,
				[QUESTIONS.DEI_COMPLAINTSPROCEDURE.questionId]: 10,
				[QUESTIONS.DEI_RESPONSIBILITY.questionId]: 5,
				[QUESTIONS.DEI_SUPPORTDIALOGUE.questionId]: 5,
				[QUESTIONS.DEI_UNBIASEDRECRUITMENT.questionId]: 10,
				[QUESTIONS.DEI_LEARNINGCOMMITMENT.questionId]: 10,
				[QUESTIONS.DEI_APPOINTEDINDIVIDUAL.questionId]: 5,
				[QUESTIONS.DEI_MENTALHEALTH.questionId]: 10,
				[QUESTIONS.DEI_JUNIORHIRES.questionId]: 10,
				[QUESTIONS.DEI_CAREERCHANGEHIRES.questionId]: 10,
			},
		},
	],
};

export default GENDERSCORE_SURVEY_1TO49_V2_DATA_OPTION;
