import { Popover, Space } from 'antd';
import { useTheme } from '@emotion/react';
import Link from '../Link';
import UserAvatar from '../../user/widgets/UserAvatar';
import { useAuthContext } from '../../../hooks/useAuthContext';
import { getAclRoleTag } from '../../../constants/constant';
import AccountMenu from '../../../menu/AccountMenu';

const HeaderUser = () => {
	const className = 'HeaderUser';

	const theme = useTheme();
	const { currentUser, aclLabels } = useAuthContext();

	if (!currentUser) {
		return null;
	}

	const aclRoleTags = (aclLabels || [])
		.map((roleValue, index) => getAclRoleTag(
			roleValue,
			{
				margin: '0 !important',
				marginTop: -10,
				marginLeft: 0,
				marginRight: 0,
				fontSize: 9,
				padding: '1px 3px',
				lineHeight: 1,
			},
			{ key: index },
		));

	const aclRoleTagsFull = aclRoleTags?.length > 0
		&& <Space size={3} style={{ marginTop: 0 }}>
			{aclRoleTags}
		</Space>;

	const aclRoleTagsExtract = aclRoleTags?.length > 0
		&& <div style={{ marginTop: 0 }}>
			<Space size={3} style={{ pointerEvents: 'none' }}>{aclRoleTags.slice(0, 2)}</Space>
		</div>;

	const aclRolesContainer = aclRoleTags?.length > 2
		? (
			<Popover title='Your roles' content={aclRoleTagsFull}>
				<Space size={3}>
					{aclRoleTagsExtract}
					<span style={{
						background: theme.color.lightgrey,
						color: theme.color.white,
						borderRadius: 3,
						fontSize: 9,
						padding: '1px 3px',
						lineHeight: 1,
					}}>
					+{aclRoleTags?.length - 2}
					</span>
				</Space>
			</Popover>
		)
		: aclRoleTagsFull;

	return (
		<Space
			direction='horizontal'
			size={8}
			align='center'
			style={{
				fontSize: 14,
				fontWeight: 600,
				padding: '0px 8px 2px 2px',
				marginTop: -3,
			}}
		>
			<Space direction='vertical' size={-12} align='end'>
				<div style={{ marginBottom: -6 }}>Hello, {currentUser.firstName}!</div>
				{aclRolesContainer}
			</Space>

			<Link to={AccountMenu?.props?.path}>
				<UserAvatar
					user={currentUser}
					withTooltipEmail={true}
					tooltipPlacement='bottom'
					style={{
						rootContainer: {},
						a: { width: 20 },
					}}
				/>
			</Link>
		</Space>
	);
};

export default HeaderUser;
